import { Plural, t } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { StatusChip, StatusChipDefaultContainer, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import {
    QuoteTrackingDTO,
    QuoteTrackingLight,
    QuoteTrackingState,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { OpenInNewRounded } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import React from 'react';
import { compareByQuoteTrackingState } from '../../comparators';
import { formatSupplierAndStockLocationDTO } from '../../formatters';
import { QuoteTrackingStateChip } from './QuoteTrackingStateChip';

type QuoteReceivedStatusInformation = {
    state: 'no-quotes-sent' | 'no-quotes-received' | 'partial-quotes-received' | 'all-quotes-received';
    numberOfQuotesSent: number;
    numberOfQuotesReceived: number;
};

export function formatQuoteReceivedStatus(state: QuoteReceivedStatusInformation['state']): string {
    switch (state) {
        case 'no-quotes-sent':
            return t`No quotes sent`;
        case 'no-quotes-received':
            return t`No quotes received`;
        case 'partial-quotes-received':
            return t`Partial quotes received`;
        case 'all-quotes-received':
            return t`All quotes received`;
    }
}

export function extractQuoteReceivedStatus(
    quotes: QuoteTrackingLight[] | QuoteTrackingDTO[],
): QuoteReceivedStatusInformation {
    const numberOfQuotesSent = quotes.filter(
        (q) => q.state !== QuoteTrackingState.FailedToSend && q.state !== QuoteTrackingState.Discarded,
    ).length;
    const numberOfQuotesReceived = quotes.filter((q) => q.state === QuoteTrackingState.Received).length;

    if (numberOfQuotesSent === 0) {
        return {
            state: 'no-quotes-sent',
            numberOfQuotesSent,
            numberOfQuotesReceived,
        };
    }

    if (numberOfQuotesReceived === 0) {
        return {
            state: 'no-quotes-received',
            numberOfQuotesSent,
            numberOfQuotesReceived,
        };
    }

    if (numberOfQuotesReceived !== numberOfQuotesSent) {
        return {
            state: 'partial-quotes-received',
            numberOfQuotesSent,
            numberOfQuotesReceived,
        };
    }

    return {
        state: 'all-quotes-received',
        numberOfQuotesSent,
        numberOfQuotesReceived,
    };
}

function QuoteRequestStatusTooltip({
    quotes,
    supplierAndStockLocations,
    onQuoteTrackingClick,
}: {
    quotes: QuoteTrackingLight[];
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    onQuoteTrackingClick: (quote: QuoteTrackingLight) => void;
}) {
    const { numberOfQuotesSent } = extractQuoteReceivedStatus(quotes);
    const sortedQuotes = quotes.sort(compareByQuoteTrackingState);
    if (numberOfQuotesSent === 0) {
        return null;
    }

    return (
        <List disablePadding>
            <ListItem style={{ background: 'white' }}>
                <ListItemText>
                    <Plural
                        value={numberOfQuotesSent}
                        one={t`One quote request`}
                        other={t`${numberOfQuotesSent} quote requests`}
                    />
                </ListItemText>
            </ListItem>
            {sortedQuotes.map((quote) => {
                const supplier = supplierAndStockLocations.find((s) => s.id === quote.supplier_and_stock_location);
                return (
                    <ListItemButton
                        key={quote.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onQuoteTrackingClick(quote);
                        }}
                        sx={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                        }}
                    >
                        <OpenInNewRounded fontSize="inherit" />
                        <Text>{formatSupplierAndStockLocationDTO(supplier)}</Text>
                        <div style={{ flexGrow: 1 }} />
                        <Text>{<QuoteTrackingStateChip quoteTracking={quote} />}</Text>
                    </ListItemButton>
                );
            })}
        </List>
    );
}

export const QuoteReceivedStatusChip: React.FunctionComponent<{
    quotes: QuoteTrackingLight[];
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    onQuoteTrackingClick: (quote: QuoteTrackingLight) => void;
}> = ({ quotes, supplierAndStockLocations, onQuoteTrackingClick }) => {
    const { state, numberOfQuotesReceived, numberOfQuotesSent } = extractQuoteReceivedStatus(quotes);

    return (
        <Tooltip
            variant="white"
            disableMaxWidth={true}
            title={
                state !== 'no-quotes-sent' ? (
                    <QuoteRequestStatusTooltip
                        quotes={quotes}
                        supplierAndStockLocations={supplierAndStockLocations}
                        onQuoteTrackingClick={onQuoteTrackingClick}
                    />
                ) : (
                    ''
                )
            }
        >
            <span>
                {state === 'no-quotes-sent' && (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>{t`No quotes sent`}</Text>
                )}
                {state === 'no-quotes-received' && (
                    <StatusChip
                        color="neutral"
                        label={t`Requested x${formatDecimal(numberOfQuotesSent)}`}
                        overrides={{ Container }}
                    />
                )}
                {(state === 'all-quotes-received' || state === 'partial-quotes-received') && (
                    <StatusChip
                        color="blue"
                        label={t`Quoted ${formatDecimal(numberOfQuotesReceived)}/${formatDecimal(numberOfQuotesSent)}`}
                        overrides={{ Container }}
                    />
                )}
            </span>
        </Tooltip>
    );
};

const Container = styled(StatusChipDefaultContainer)({
    cursor: 'inherit',
    '& span': {
        maxWidth: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});
