/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { CenteredLayout, Flexbox, FormSection, SecondaryButton, Text } from '@luminovo/design-system';
import { EmsRfqUpdateDTO, RfqDTO, VolumeEstimate } from '@luminovo/http-client';
import { Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormContainer, ValidationErrors } from '../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../components/formLayouts/SubmitButton';
import { PageLayout } from '../../components/PageLayout';
import { CurrencyFormItem } from '../../components/RfQ/CurrencyFormItem';
import { DueDateFormItem } from '../../components/RfQ/DueDateFormItem';
import { EmsInternalNumberFormItem } from '../../components/RfQ/EmsInternalNumberFormItem';
import { RfqNameFormItem } from '../../components/RfQ/RfqNameFormItem';
import { VolumeEstimateFormItem, VolumeEstimateFormState } from '../../components/RfQ/VolumeEstimateFormItem';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { useMutationUpdateRfq, useRfQ } from '../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { route } from '../../utils/routes';
import { undefinedToNull } from '../../utils/typingUtils';

interface RfqEditFormState {
    name: string;
    dueDate: string | null | undefined;
    emsInternalNumber: string | null | undefined;
    currency: Currency;
    volumeEstimate: VolumeEstimateFormState;
}

function RfqEditForm({ onCancel, currentRfqName, rfq }: { onCancel: () => void; currentRfqName: string; rfq: RfqDTO }) {
    const {
        control,
        formState: { isSubmitting },
    } = useFormContext<RfqEditFormState>();

    const { isRfqEditable } = useIsRfqEditable(rfq.status, rfq.is_archived, rfq.workflow_type);

    return (
        <Flexbox
            flexDirection={'column'}
            boxSizing="border-box"
            minWidth={'800px'}
            maxWidth={'1400px'}
            padding="32px 180px"
            gap={24}
        >
            <Text variant="h1" color="textSecondary">
                <Trans>Edit RfQ</Trans>
            </Text>

            <Divider />

            <FormSection title={t`General`} padding="0">
                <RfqNameFormItem control={control} name="name" currentRfqName={currentRfqName} />

                <EmsInternalNumberFormItem control={control} name="emsInternalNumber" />

                <DueDateFormItem control={control} name="dueDate" />

                {isRfqEditable && <CurrencyFormItem control={control} name="currency" />}

                <VolumeEstimateFormItem<RfqEditFormState, 'volumeEstimate'>
                    name="volumeEstimate"
                    currencyName="currency"
                />
            </FormSection>

            <Flexbox justifyContent={'flex-end'} gap={8}>
                <SecondaryButton disabled={isSubmitting} style={{ minWidth: 92 }} onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>

                <SubmitButton style={{ minWidth: 92 }}>
                    <Trans>Save</Trans>
                </SubmitButton>
            </Flexbox>
        </Flexbox>
    );
}

function convertToFormState(rfq: RfqDTO): RfqEditFormState {
    const volume_estimate = rfq.ems_rfq_details.volume_estimate;
    const volumeEstimate: VolumeEstimateFormState = {
        isEnabled: volume_estimate?.lower !== undefined || volume_estimate?.upper !== undefined,
        lower: volume_estimate?.lower ? parseFloat(volume_estimate.lower) : undefined,
        upper: volume_estimate?.upper ? parseFloat(volume_estimate.upper) : undefined,
    };

    return {
        dueDate: rfq.due_date ?? undefined,
        emsInternalNumber: rfq.ems_internal_number ?? undefined,
        volumeEstimate,
        name: rfq.name,
        currency: rfq.currency,
    };
}

function convertToDto(form: RfqEditFormState): {
    rfq: EmsRfqUpdateDTO;
} {
    const shouldHaveVolumeEstimate =
        form.volumeEstimate.isEnabled && (form.volumeEstimate.lower || form.volumeEstimate.upper);
    const volume_estimate: VolumeEstimate | undefined = shouldHaveVolumeEstimate
        ? {
              currency: form.currency,
              lower: form.volumeEstimate?.lower?.toString() ?? null,
              upper: form.volumeEstimate?.upper?.toString() ?? null,
          }
        : undefined;

    const rfq: EmsRfqUpdateDTO = {
        name: form.name,
        shipping_tracking_link: null,
        due_date: undefinedToNull(form.dueDate),
        internal_number: form.emsInternalNumber ?? null,
        volume_estimate,
        currency: form.currency,
    };

    return {
        rfq,
    };
}

const FormWrapper = ({ rfq }: { rfq: RfqDTO }) => {
    const history = useHistory();
    const { mutateAsync } = useMutationUpdateRfq(rfq.id);

    const defaultValues: RfqEditFormState = convertToFormState(rfq);

    const onCancel = () => history.goBack();
    const onSubmit = async (form: RfqEditFormState) => {
        await mutateAsync(convertToDto(form));
        history.push(route('/rfqs/:rfqId/dashboard', { rfqId: rfq.id }));
    };

    const validationErrors: ValidationErrors<RfqEditFormState> = {
        'rfq.invalid_name': {
            fieldPath: 'name',
        },
        'rfq.invalid_rfq_number': {
            fieldPath: 'emsInternalNumber',
        },
        'rfq.invalid_volume_estimate_bounds': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues} validationErrors={validationErrors}>
            <RfqEditForm onCancel={onCancel} currentRfqName={defaultValues.name} rfq={rfq} />
        </FormContainer>
    );
};

export const EmsRfqEditPage = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const { data: rfq, isLoading: isLoadingRfq } = useRfQ(rfqId);

    return (
        <PageLayout layout="fragment">
            {isLoadingRfq || !rfq ? (
                <CenteredLayout>
                    <SpinnerWithBackdrop noBackdrop />
                </CenteredLayout>
            ) : (
                <FormWrapper rfq={rfq} />
            )}
        </PageLayout>
    );
};
