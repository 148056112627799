import { t } from '@lingui/macro';
import { Column, NoResultsComponentProps } from '@luminovo/design-system';
import { IdWithIndex } from '@luminovo/http-client';
import { SortableDataTable, sortByOrderOfIds, useSortableDataTableState } from '@luminovo/manufacturing-core';
import { useSnackbar } from 'notistack';
import { useCanEditManufacturingDatabase } from '../../utils/useCanEditManufacturingDatabase';

interface Entity {
    id: string;
}

interface SortableEntityTableProps<T extends Entity> {
    entities: T[];
    order: IdWithIndex[];
    persistenceId: string;
    columns: Column<T>[];
    onOrderUpdate: (orderedItems: T[]) => Promise<void>;
    NoResultsComponent?: React.ComponentType<NoResultsComponentProps>;
}

export const SortableEntityTable = <T extends Entity>({
    entities,
    order,
    persistenceId,
    columns,
    onOrderUpdate,
    NoResultsComponent,
}: SortableEntityTableProps<T>): JSX.Element => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const sortedItems = sortByOrderOfIds(entities, order);
    const idExtractor = (item: T) => item.id.toString();

    const sortableTableState = useSortableDataTableState<T>({
        columns,
        items: sortedItems,
        persistenceId,
        selectionOptions: { idExtractor },
    });

    return (
        <SortableDataTable
            idExtractor={idExtractor}
            sortableTableState={sortableTableState}
            onDragEndCallback={async (orderedItems) => {
                if (!canEditManufacturingDatabase) {
                    enqueueSnackbar(t`The order cannot be updated with the lite version of manufacturing.`, {
                        variant: 'error',
                    });
                    return;
                }
                await onOrderUpdate(orderedItems);
            }}
            dataTableProps={{
                size: 'medium',
                overrides: {
                    NoResultsComponent,
                },
            }}
        />
    );
};
