import { throwErrorUnlessProduction } from '../errors';
import { isPresent } from './isPresent';

export function assertPresent<T>(x: T | null | undefined): Exclude<T, undefined | null> {
    if (!isPresent(x)) {
        throwErrorUnlessProduction(new Error(`Expected value to be present but got ${x}`));
    }
    // This case is safe as this code is only reachable if x isPresent
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return x as Exclude<T, undefined | null>;
}
