import { UniqueIdentifier } from '@dnd-kit/core';
import { sortBy } from '@luminovo/commons';
import {
    FileTypeUpdateRequest,
    PCBFileCategory,
    PCBFileTypes,
    PCBV2File,
    SingleFileTypeUpdate,
} from '@luminovo/http-client';
import { assertUnreachable, isKeyOf } from '../../../../../utils/typingUtils';

/* eslint-disable spellcheck/spell-checker */
export enum StateFileType {
    Copper = 'Copper',
    SilkscreenTop = 'SilkscreenTop',
    SilkscreenBottom = 'SilkscreenBottom',
    SoldermaskTop = 'SoldermaskTop',
    SoldermaskBottom = 'SoldermaskBottom',
    PasteTop = 'PasteTop',
    PasteBottom = 'PasteBottom',
    MechanicalFiles = 'MechanicalFiles',
    MiscellaneousFiles = 'MiscellaneousFiles',
    StackupFiles = 'StackupFiles',
}
/* eslint-enable spellcheck/spell-checker */

export type PCBFileWithId = PCBV2File & {
    id: UniqueIdentifier;
};

export type UpdateContainersWithFiles = (
    newValue: ContainersWithFiles | ((prev: ContainersWithFiles) => ContainersWithFiles),
) => void;

export type PcbStateFileTypeSection = {
    files: Array<PCBFileWithId>;
    dirty: boolean;
};

export type ContainersWithFiles = Record<StateFileType, PcbStateFileTypeSection>;

const copperLayerList = {
    [PCBFileTypes.COPPER_TOP]: 0,
    [PCBFileTypes.COPPER_MID]: 1,
    [PCBFileTypes.PLANE_MID]: 1,
    [PCBFileTypes.COPPER_BOTTOM]: 3,
};

const mechanicalLayerList = {
    [PCBFileTypes.OUTLINE]: 0,
    [PCBFileTypes.DRILL]: 1,
    [PCBFileTypes.PHDRILL]: 1,
    [PCBFileTypes.NPHDRILL]: 1,
    [PCBFileTypes.DRILLSETS]: 1,
    [PCBFileTypes.KEEP_OUT]: 1,
    [PCBFileTypes.MECHANICAL]: 2,
};

function sortLayers<K extends PCBFileTypes>(a: PCBFileTypes, b: PCBFileTypes, order: Record<K, number>): number {
    if (!isKeyOf(order, a)) {
        return 1;
    }

    if (!isKeyOf(order, b)) {
        return -1;
    }

    return order[a] - order[b];
}

export function sortCopperLayers(a: PCBFileTypes, b: PCBFileTypes): number {
    return sortLayers<keyof typeof copperLayerList>(a, b, copperLayerList);
}

function sortMechanicalLayers(a: PCBFileTypes, b: PCBFileTypes): number {
    return sortLayers<keyof typeof mechanicalLayerList>(a, b, mechanicalLayerList);
}

export function getFileStateType(file: PCBV2File): StateFileType {
    switch (file.fileType.fileType) {
        case PCBFileTypes.COPPER_TOP:
        case PCBFileTypes.COPPER_MID:
        case PCBFileTypes.COPPER_BOTTOM:
        case PCBFileTypes.PLANE_MID:
            return StateFileType.Copper;

        case PCBFileTypes.DRILL:
        case PCBFileTypes.PHDRILL:
        case PCBFileTypes.NPHDRILL:
        case PCBFileTypes.DRILLSETS:
        case PCBFileTypes.KEEP_OUT:
        case PCBFileTypes.OUTLINE:
        case PCBFileTypes.MECHANICAL:
        case PCBFileTypes.ADHESIVE_TOP:
        case PCBFileTypes.ADHESIVE_BOTTOM:
        case PCBFileTypes.PEELABLE_TOP:
        case PCBFileTypes.PEELABLE_BOTTOM:
            return StateFileType.MechanicalFiles;

        case PCBFileTypes.SILKSCREEN_TOP:
            return StateFileType.SilkscreenTop;

        case PCBFileTypes.SILKSCREEN_BOTTOM:
            return StateFileType.SilkscreenBottom;

        case PCBFileTypes.SOLDERMASK_TOP:
            return StateFileType.SoldermaskTop;

        case PCBFileTypes.SOLDERMASK_BOTTOM:
            return StateFileType.SoldermaskBottom;

        case PCBFileTypes.PASTE_TOP:
            return StateFileType.PasteTop;

        case PCBFileTypes.PASTE_BOTTOM:
            return StateFileType.PasteBottom;

        case PCBFileTypes.NATIVE_ALTIUM:
        case PCBFileTypes.NATIVE_KICAD:
        case PCBFileTypes.NATIVE_EAGLE:
        case PCBFileTypes.LEGACY_GERBER:
        case PCBFileTypes.UNMATCHED:
        case PCBFileTypes.UNKNOWN:
            return StateFileType.MiscellaneousFiles;

        case PCBFileTypes.STACK_UP:
            return StateFileType.StackupFiles;

        default:
            assertUnreachable(file.fileType.fileType);
    }
}

function copperFileType(newIndex: number, totalCopperFiles: number): PCBFileTypes {
    switch (newIndex) {
        case 0:
            return PCBFileTypes.COPPER_TOP;
        case totalCopperFiles - 1:
            return PCBFileTypes.COPPER_BOTTOM;
        default:
            return PCBFileTypes.COPPER_MID;
    }
}

export function convertToUiStateToPcbFileType(
    containerItems: Array<PCBFileWithId>,
    newContainer: StateFileType,
    newIndex: number,
): PCBFileTypes {
    switch (newContainer) {
        case StateFileType.Copper:
            return copperFileType(newIndex, containerItems.length);
        case StateFileType.MechanicalFiles:
            return PCBFileTypes.MECHANICAL;
        case StateFileType.SilkscreenTop:
            return PCBFileTypes.SILKSCREEN_TOP;
        case StateFileType.SilkscreenBottom:
            return PCBFileTypes.SILKSCREEN_BOTTOM;
        case StateFileType.SoldermaskTop:
            return PCBFileTypes.SOLDERMASK_TOP;
        case StateFileType.SoldermaskBottom:
            return PCBFileTypes.SOLDERMASK_BOTTOM;
        case StateFileType.PasteTop:
            return PCBFileTypes.PASTE_TOP;
        case StateFileType.PasteBottom:
            return PCBFileTypes.PASTE_BOTTOM;
        case StateFileType.MiscellaneousFiles:
            return PCBFileTypes.UNKNOWN;
        case StateFileType.StackupFiles:
            return PCBFileTypes.STACK_UP;
        default:
            assertUnreachable(newContainer);
    }
}

// TODOTK: Sync with backend logic to get the categories
export function getFileCategory(fileType: PCBFileTypes): PCBFileCategory {
    switch (fileType) {
        case PCBFileTypes.COPPER_TOP:
        case PCBFileTypes.COPPER_MID:
        case PCBFileTypes.PLANE_MID:
        case PCBFileTypes.COPPER_BOTTOM:
        case PCBFileTypes.SILKSCREEN_TOP:
        case PCBFileTypes.SILKSCREEN_BOTTOM:
        case PCBFileTypes.SOLDERMASK_TOP:
        case PCBFileTypes.SOLDERMASK_BOTTOM:
        case PCBFileTypes.PASTE_TOP:
        case PCBFileTypes.PASTE_BOTTOM:
            return PCBFileCategory.GERBER;

        case PCBFileTypes.DRILL:
        case PCBFileTypes.PHDRILL:
        case PCBFileTypes.NPHDRILL:
        case PCBFileTypes.DRILLSETS:
        case PCBFileTypes.KEEP_OUT:
        case PCBFileTypes.OUTLINE:
        case PCBFileTypes.MECHANICAL:
        case PCBFileTypes.ADHESIVE_TOP:
        case PCBFileTypes.ADHESIVE_BOTTOM:
        case PCBFileTypes.PEELABLE_TOP:
        case PCBFileTypes.PEELABLE_BOTTOM:
            return PCBFileCategory.MECHANICAL;

        case PCBFileTypes.NATIVE_ALTIUM:
        case PCBFileTypes.NATIVE_KICAD:
        case PCBFileTypes.NATIVE_EAGLE:
        case PCBFileTypes.LEGACY_GERBER:
        case PCBFileTypes.UNMATCHED:
        case PCBFileTypes.UNKNOWN:
        case PCBFileTypes.STACK_UP:
            return PCBFileCategory.UNKNOWN;

        default:
            assertUnreachable(fileType);
    }
}

// TODOTK: Write unit test
export function getInitialItemState(pcbFiles: PCBV2File[]): ContainersWithFiles {
    const initialState: ContainersWithFiles = {
        [StateFileType.SilkscreenTop]: { files: [], dirty: false },
        [StateFileType.SilkscreenBottom]: { files: [], dirty: false },
        [StateFileType.SoldermaskTop]: { files: [], dirty: false },
        [StateFileType.SoldermaskBottom]: { files: [], dirty: false },
        [StateFileType.Copper]: { files: [], dirty: false },
        [StateFileType.PasteTop]: { files: [], dirty: false },
        [StateFileType.PasteBottom]: { files: [], dirty: false },
        [StateFileType.MechanicalFiles]: { files: [], dirty: false },
        [StateFileType.MiscellaneousFiles]: { files: [], dirty: false },
        [StateFileType.StackupFiles]: { files: [], dirty: false },
    };

    if (pcbFiles) {
        // TODO: Move this inside the sorting (sortLayers) by passing the file
        sortBy(pcbFiles, 'fileType.index').forEach((file: PCBV2File) => {
            const fileType = getFileStateType(file);
            // id needed for UniqueIdentifier in dnd-kit
            initialState[fileType].files.push({
                ...file,
                id: file.name,
            });
        });
    }

    return sortFilesInContainers(initialState);
}

/**
 * This change the object by sorting certain containers
 * @param containers containers with files
 * @returns void
 */
export function sortFilesInContainers(containers: ContainersWithFiles) {
    // Create a copy of the containers object
    const containersCopy = { ...containers };

    // Create copies of the arrays and sort them
    containersCopy[StateFileType.Copper] = {
        ...containers[StateFileType.Copper],
        files: [...containers[StateFileType.Copper].files].sort((a, b) => {
            return sortCopperLayers(a.fileType.fileType, b.fileType.fileType);
        }),
    };

    containersCopy[StateFileType.MechanicalFiles] = {
        ...containers[StateFileType.MechanicalFiles],
        files: [...containers[StateFileType.MechanicalFiles].files].sort((a, b) => {
            return sortMechanicalLayers(a.fileType.fileType, b.fileType.fileType);
        }),
    };

    // Return the copy of the containers object
    return containersCopy;
}

/**
 * This function converts the files in the containers to the format that the backend expects
 */
export function createPcbFileUpdateRequest(items: ContainersWithFiles): FileTypeUpdateRequest {
    const initialState: SingleFileTypeUpdate[] = [];
    const updates = Object.values(StateFileType).reduce((acc, key) => {
        const section = items[key];
        let updates: SingleFileTypeUpdate[] = [];

        if (section.dirty) {
            updates = items[key].files.map((file, idx) => {
                let from = file.fileType.from;
                let to = file.fileType.to;

                return {
                    file: file.name,
                    function: {
                        ...file.fileType,
                        index: key === StateFileType.Copper ? idx + 1 : file.fileType.index,
                        from,
                        to,
                    },
                };
            });
        }

        return acc.concat(updates);
    }, initialState);

    return { updates };
}

export const drillFileFilter = (file: PCBFileWithId) =>
    file.fileType.fileType === PCBFileTypes.PHDRILL ||
    file.fileType.fileType === PCBFileTypes.NPHDRILL ||
    file.fileType.fileType === PCBFileTypes.DRILL;
