import * as r from 'runtypes';

export interface QuotePriceRequestBodyDTO extends r.Static<typeof QuotePriceRequestBodyDTORuntype> {}
export const QuotePriceRequestBodyDTORuntype = r.Record({
    rfq: r.String,
    part_ids: r.Array(r.String),
});

export interface QuotePriceBulkRequestBodyDTO extends r.Static<typeof QuotePriceBulkRequestBodyDTORuntype> {}
export const QuotePriceBulkRequestBodyDTORuntype = r.Record({
    rfq: r.String,
    part_ids: r.Array(r.String),
    should_filter_by_line_card: r.Boolean,
    generate_all_quantity_combinations: r.Boolean,
    supplier_ids: r.Array(r.String),
    sourcing_scenario_ids: r.Array(r.String),
    should_include_pcb_specification: r.Boolean,
    should_include_shipping_panel_specification: r.Boolean,
});

export interface SendQuotePriceRequestBodyDTO extends r.Static<typeof SendQuotePriceRequestBodyDTORuntype> {}
export const SendQuotePriceRequestBodyDTORuntype = r.Record({
    rfq: r.String,
    part_ids: r.Array(r.String),
    should_filter_by_line_card: r.Boolean,
    generate_all_quantity_combinations: r.Boolean,
    should_include_pcb_specification: r.Boolean,
    should_include_shipping_panel_specification: r.Boolean,
    notes: r.Optional(r.String),
    sourcing_scenario_ids: r.Array(r.String),
    due_date: r.Optional(r.String),
    mail_data: r.Array(
        r.Record({
            supplier_id: r.String,
            supplier_contacts: r.Array(r.String),
            cc: r.Array(r.String),
            email: r.Record({ subject: r.String, body: r.String }),
        }),
    ),
    should_include_mail_attachments: r.Boolean,
});
