import * as r from 'runtypes';
import { StepEnumRuntype } from './organizationSettingsBackendTypes';

export interface SupplierPortalSettingsDTO extends r.Static<typeof SupplierPortalSettingsRuntype> {}
export const SupplierPortalSettingsRuntype = r.Record({
    default_days_before_offer_invalid: r.Number.nullable(),
    show_supplier_portal_link: r.Boolean,
    upload_additional_supplier_files: StepEnumRuntype,
});

export interface PatchSupplierPortalSettingsDTO extends r.Static<typeof PatchSupplierPortalSettingsRuntype> {}
export const PatchSupplierPortalSettingsRuntype = r.Record({
    default_days_before_offer_invalid: r.Number.nullable().optional(),
    show_supplier_portal_link: r.Boolean.optional(),
    upload_additional_supplier_files: StepEnumRuntype.optional(),
});
