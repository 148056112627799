import { CustomerDTO, HOST_NAME, OrganizationDTO, UserDTO } from '@luminovo/http-client';
import { getTenantId } from '../../permissions/tenants';
import { isPresent } from '../isPresent';
import { ConsentType } from './ConsentType';
import { createMasterTracker } from './trackers/masterTracker';

export function initializeAllAnalytics({
    consentedCookies,
    user,
    organization,
    customer,
    hasFullManufacturingAccess,
}: {
    consentedCookies: ConsentType[];
    user: UserDTO;
    organization: OrganizationDTO;
    customer: CustomerDTO | null;
    hasFullManufacturingAccess: boolean;
}) {
    const isInternalUser = user.email.endsWith('@luminovo.ai') || user.email.endsWith('@luminovo.com');
    const masterTracker = createMasterTracker({ consentedCookies, isInternalUser });
    const tenant = getTenantId();

    masterTracker.initialize();

    masterTracker.identify(user.id, {
        email: user.email,
        language: user.user_language,
        /* eslint-disable-next-line camelcase */
        is_from_customer: isPresent(customer),
        /* eslint-disable-next-line camelcase */
        customer_name: isPresent(customer) ? customer.name : undefined,
        host: HOST_NAME,
        tenant: tenant,
        /* eslint-disable-next-line camelcase */
        has_access_to_customer_portal: organization.has_access_to_customer_portal,
        /* eslint-disable-next-line camelcase */
        has_full_manufacturing_access: hasFullManufacturingAccess,
        /* eslint-disable-next-line camelcase */
        has_customers: organization.has_customers,
        /* eslint-disable-next-line camelcase */
        client_type: organization.client_type ?? '',
    });

    masterTracker.group(tenant, {
        name: tenant,
        /* eslint-disable-next-line camelcase */
        has_access_to_customer_portal: organization.has_access_to_customer_portal,
        /* eslint-disable-next-line camelcase */
        has_full_manufacturing_access: hasFullManufacturingAccess,
        /* eslint-disable-next-line camelcase */
        has_customers: organization.has_customers,
        /* eslint-disable-next-line camelcase */
        client_type: organization.client_type ?? '',
    });
}
