/* eslint-disable camelcase */

import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ResourceType } from '@luminovo/http-client';

export const resourceTypeEnumPublicTranslations: Record<ResourceType, MessageDescriptor> = {
    [ResourceType.Machine]: defineMessage({ message: 'Machine' }),
    [ResourceType.Person]: defineMessage({ message: 'Person' }),
};
