import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const noKeywords = ['no', 'nein', 'non', 'none', 'ohne', 'without', 'sans', 'keine?', 'sense'];
export function parseNo(str: string): boolean {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(noKeywords);
    return regex.test(str);
}
const yesKeywords = ['yes', 'ja', 'oui', "si'?"];
export function parseYes(str: string): boolean {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(yesKeywords.concat([':selected:']));
    return regex.test(str);
}

const yesOnlyRegex = new RegExp(`^(:?${yesKeywords.join('|')})$`, 'i');
const noOnlyRegex = new RegExp(`^(:?${noKeywords.join('|')})$`, 'i');
export function parseExclusiveYesOrNo(str: string): PcbAttribute | undefined {
    const yes =
        yesOnlyRegex.test(str.replace(':selected:', '').replace(':unselected:', '').trim()) ||
        str.trim() === ':selected:';
    const no =
        noOnlyRegex.test(str.replace(':selected:', '').replace(':unselected:', '').trim()) ||
        str.trim() === ':unselected:';
    if (yes || no) {
        return {
            attr: 'yes',
            value: yes,
        } as PcbAttribute;
    }
    return undefined;
}
