export const en = {
    consentModal: {
        title: '🍪 We use cookies!',
        description:
            'Hi, this website uses essential cookies to ensure its proper operation, tracking cookies to understand how you interact with it and support cookies for customer support and issue tracking. The latter two will be set only after consent. If you give us your consent, your data may be processed outside the EEA.',
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Reject all',
        showPreferencesBtn: 'More options',
    },
    preferencesModal: {
        title: '🍪 We use cookies!',
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Reject all',
        savePreferencesBtn: 'Save settings',
        closeIconLabel: 'Close',
        sections: [
            {
                title: 'Cookie usage 📢',
                description:
                    'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://luminovo.notion.site/Data-Security-Privacy-at-Luminovo-61603a59d0864f108028be963e7ad312" class="cc-link">privacy policy</a>',
            },
            {
                title: 'Essential cookies',
                description:
                    'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=97025cec003c4f7b8c6a7dfb883a7906" rel="noopener noreferrer" target="_blank" class="cc-link">View cookie information</a>',
                linkedCategory: 'required',
            },
            {
                title: 'Performance and analytics cookies',
                description:
                    'These cookies allow us to track the performance and usage of the product. We use this information to improve the user experience and performance of the product. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=c2589aa5a8824a9785dc94561a3e0481" rel="noopener noreferrer" target="_blank" class="cc-link">View cookie information</a>',
                linkedCategory: 'analytics',
            },
            {
                title: 'Support cookies',
                description:
                    'These cookies allow us to offer support and guidance to our users. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=7366ed2f10264209893b54184b1595b3" rel="noopener noreferrer" target="_blank" class="cc-link">View cookie information</a>',
                linkedCategory: 'support',
            },
        ],
    },
};
