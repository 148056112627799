import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Solution } from '../../types';

export function extractTotalPrice(
    { solution }: { solution: Pick<Solution, 'totalPrice'> | undefined },
    options: {
        source: 'original' | 'effective';
        default?: number;
    },
): number {
    if (!isPresent(solution) || !isPresent(solution.totalPrice)) {
        return options.default ?? 0;
    }

    if (options.source === 'original') {
        return Number(solution.totalPrice.original_total_price.amount);
    }

    if (options.source === 'effective') {
        return Number(solution.totalPrice.effective_total_price.amount);
    }

    assertUnreachable(options.source);
}
