import { useToken } from '@luminovo/auth';
import { http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../../../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';

export const useMutationUpdateContributors = (rfqId: string) => {
    const queryClient = useQueryClient();
    const { token } = useToken();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async ({ contributorIds }: { contributorIds: string[] }) => {
            await http(
                'PATCH /contributors/rfqs/:rfqId',
                {
                    pathParams: { rfqId },
                    requestBody: contributorIds,
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries(httpQueryKey('GET /rfqs')),
                queryClient.invalidateQueries(httpQueryKey('GET /rfqs/:rfqId')),
                queryClient.invalidateQueries(httpQueryKey('GET /contributors/rfqs/:rfqId')),
            ]);
        },
        onError,
    });
};
