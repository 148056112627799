import { Trans } from '@lingui/macro';
import { Flexbox, Text } from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { AutocompleteOfIdOption } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormAutocompleteComponents';
import { AddActivityFormInputs } from '../AddActivityFormTypes';
import { ResourceField } from './ResourceField';
import { AddResourceButton } from './resourceButtons';

type MultipleResourceFormProps = UseFormReturn<AddActivityFormInputs> & {
    allResourceNameWithIds: AutocompleteOfIdOption[];
    allResources: ResourceDTO[];
};

const MAX_NUMBER_OF_RESOURCES = 3; //set to three because more is very unlikely and to ensure the design stays good.

export function MultipleResourceForm(props: MultipleResourceFormProps) {
    const { fields, append } = useFieldArray({
        control: props.control,
        name: 'resourcesDetails',
    });

    return (
        <Flexbox flexDirection="row" gap={24} style={{ padding: '16px' }}>
            {fields.map((x, i) => {
                return (
                    <ResourceField
                        key={`${x.resourceId}-${i}`}
                        i={i}
                        useFormReturn={props}
                        allResources={props.allResources}
                        allResourceNameWithIds={props.allResourceNameWithIds}
                    />
                );
            })}
            {fields.length < MAX_NUMBER_OF_RESOURCES && (
                <Flexbox flexDirection="column" gap={4} alignItems={'center'}>
                    <AddResourceButton onClick={() => append({ resourceId: '', multiplier: '1' })} />
                    {fields.length === 0 && (
                        <Text variant="caption">
                            <Trans>At least one resource is required.</Trans>
                        </Text>
                    )}
                </Flexbox>
            )}
        </Flexbox>
    );
}
