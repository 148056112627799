import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { LocationOff, LocationOn } from '@mui/icons-material';
import { Skeleton, Tooltip } from '@mui/material';
import { useSites } from '../../resources/organizationSettings/sitesHandler';

export function useSiteNames(): {
    siteNames: Record<string, string>;
    isLoading: boolean;
} {
    const { data: sites = [], isLoading } = useSites();
    const siteNames: Record<string, string> = sites.reduce((acc, site) => ({ ...acc, [site.id]: site.name }), {});

    return { siteNames, isLoading };
}

export const SitesWithIconFetching = ({
    sites,
    color,
    noWrap,
}: {
    sites: string[];
    color?: string;
    noWrap?: boolean;
}): JSX.Element => {
    const { siteNames: siteNamesMap, isLoading } = useSiteNames();

    if (isLoading) {
        return <Skeleton variant="rectangular" />;
    }

    return <SitesWithIcon sites={sites} siteNamesMap={siteNamesMap} color={color} noWrap={noWrap} />;
};

export const SitesWithIcon = ({
    sites,
    color,
    noWrap,
    siteNamesMap,
}: {
    sites: string[];
    siteNamesMap: Record<string, string>;
    color?: string;
    noWrap?: boolean;
}): JSX.Element => {
    const iconStyles = { fill: color ?? colorSystem.neutral[5], fontSize: 'inherit' };

    if (sites.length === 0) {
        return (
            <Flexbox flexWrap="nowrap" gap={4} alignItems="center">
                <LocationOff style={iconStyles} />
            </Flexbox>
        );
    }

    const sortedSites = sites
        .map((site) => siteNamesMap[site])
        .filter(isPresent)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');

    return (
        <Flexbox flexWrap="nowrap" gap={4} alignItems="center">
            <LocationOn style={iconStyles} />
            {noWrap ? (
                <Tooltip title={sortedSites}>
                    <Text color={color} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {sortedSites}
                    </Text>
                </Tooltip>
            ) : (
                <Text color={color}>{sortedSites}</Text>
            )}
        </Flexbox>
    );
};
