import { t, Trans } from '@lingui/macro';
import { Flexbox, FormItem, SecondaryButton } from '@luminovo/design-system';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldController } from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';

export interface UpdateUserFormState {
    firstName: string;
    lastName: string;
    phoneNumber: string | null;
    position: string | null;
    email: string;
}

export function UpdateUserForm({
    initialValues,
    onSubmit,
    onClose: onCancel,
}: {
    initialValues: UpdateUserFormState;
    onSubmit: (form: UpdateUserFormState) => void;
    onClose: () => void;
}) {
    const useFormReturn = useForm<UpdateUserFormState>({
        defaultValues: initialValues,
        reValidateMode: 'onBlur',
        mode: 'onBlur',
    });
    const { control } = useFormReturn;
    const handleSubmit = useFormReturn.handleSubmit((form) => onSubmit(form));
    return (
        <FormProvider {...useFormReturn}>
            <form onSubmit={handleSubmit}>
                <Flexbox flexDirection={'column'} gap={32} minWidth={300}>
                    <Flexbox flexDirection={'row'} gap={8} alignContent="center">
                        <FormItem label={t`Email`} required minWidth={300}>
                            <TextFieldController control={control} TextFieldProps={{ disabled: true }} name={'email'} />
                        </FormItem>
                        <FormItem label={t`First name`} required>
                            <TextFieldController
                                control={control}
                                rules={{ required: t`Required` }}
                                name={'firstName'}
                            />
                        </FormItem>
                        <FormItem label={t`Last name`} required>
                            <TextFieldController
                                control={control}
                                rules={{ required: t`Required` }}
                                name={'lastName'}
                            />
                        </FormItem>
                        <FormItem label={t`Phone number`}>
                            <TextFieldController control={control} name={'phoneNumber'} />
                        </FormItem>
                        <FormItem label={t`Position`}>
                            <TextFieldController control={control} name={'position'} />
                        </FormItem>
                    </Flexbox>
                    <Flexbox gap={8} justifyContent="space-between">
                        <SecondaryButton onClick={() => onCancel()}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <SubmitButton />
                    </Flexbox>
                </Flexbox>
            </form>
        </FormProvider>
    );
}
