import { BoundingBox } from '../boundingBox';
import { infer } from './infer';
import { Attribute, Attributes, Link, Region } from './types';

interface RegionQuery {
    /**
     * If present, matches the region with the given id.
     */
    id?: string;

    /**
     * If present, matches all regions with the given ids.
     */
    ids?: string[];

    /**
     * If present, matches all regions with the given attribute.
     */
    attribute?: Attributes;

    /**
     * If present, matches all regions that contain all of the given bounding boxes.
     */
    containsEvery?: BoundingBox[];

    /**
     * If present, matches all regions that intersect with all of the given bounding boxes.
     */
    intersectsEvery?: BoundingBox[];

    /**
     * If present, matches all regions with the given page number.
     */
    pageNumber?: number;
}

interface LinkQuery {
    type?: Link['type'];
    pageNumber?: number;
    from?: Region;

    /**
     * Matches links that start or end with the given region.
     */
    with?: Region;
    /**
     * Matches links that start or end with any of the given regions.
     */
    withSome?: Region[];
}

export interface RegionNetwork {
    findRegions(query: RegionQuery): Region[];
    removeRegions(query: RegionQuery): RegionNetwork;
    updateRegions(query: RegionQuery, transform: (region: Region) => Region): RegionNetwork;

    findLinks(query: LinkQuery): Array<{ from: Region; to: Region }>;
    removeLinks(query: LinkQuery): RegionNetwork;
    addLinks(links: Link[]): RegionNetwork;
    setLinks(links: Link[]): RegionNetwork;
    hasLinks(query: LinkQuery): boolean;

    mapRegions(transform: (region: Region) => Region): RegionNetwork;
    addRegion(region: Region): RegionNetwork;

    getTable(): Array<{ attrs: Attribute[] }>;
}

export class ImmutableRegionNetwork implements RegionNetwork {
    private indexById: Record<string, Region[]> = {};
    private linksIndexedByFrom: Record<string, Array<{ from: string; to: string; type: Link['type'] }>> = {};

    constructor(
        //
        private readonly regions: Region[],
        private readonly links: { from: string; to: string; type: Link['type'] }[] = [],
    ) {
        for (const region of regions) {
            this.indexById[region.id] = [region];
        }
        for (const link of links) {
            this.linksIndexedByFrom[link.from] = this.linksIndexedByFrom[link.from] ?? [];
            this.linksIndexedByFrom[link.from].push(link);
        }
    }

    addRegion(region: Region): RegionNetwork {
        return new ImmutableRegionNetwork(this.regions.concat(region), this.links);
    }

    getTable(): { attrs: Attribute[] }[] {
        const table = this.findRegions({ attribute: 'part' }).map((mpn) => {
            const relatedRegions = this.findLinks({ from: mpn }).map((link) => link.to);
            return {
                attrs: infer(mpn.attributes.concat(relatedRegions.flatMap((region) => region.attributes))),
            };
        });
        return table;
    }

    findRegions(query: RegionQuery): Region[] {
        if (Object.keys(query).length === 0) {
            return this.regions;
        }

        const predicate = regionPredicate(query);

        if (query.id) {
            return (this.indexById[query.id] ?? []).filter(predicate);
        }
        if (query.ids) {
            return query.ids.flatMap((id) => this.indexById[id] ?? []).filter(predicate);
        }

        return this.regions.filter(predicate);
    }

    updateRegions(query: RegionQuery, transform: (region: Region) => Region): RegionNetwork {
        const regions = this.findRegions(query).map((r) => r.id);
        const newRegions = this.regions.map((region) => {
            if (regions.includes(region.id)) {
                return transform(region);
            }
            return region;
        });
        return new ImmutableRegionNetwork(newRegions, this.links);
    }

    removeRegions(query: RegionQuery): RegionNetwork {
        const regions = this.findRegions(query);
        const links = this.findLinks({ withSome: regions });
        return new ImmutableRegionNetwork(
            this.regions.filter((region) => !regions.includes(region)),
            links.map((link) => ({ from: link.from.id, to: link.to.id, type: link.type })),
        );
    }

    mapRegions(transform: (region: Region) => Region): RegionNetwork {
        const newRegions = this.regions.map(transform);
        return new ImmutableRegionNetwork(newRegions, this.links);
    }

    findLinks(query: LinkQuery): { from: Region; to: Region; type: Link['type'] }[] {
        const predicate = linkPredicate(query);
        const links = query.from ? (this.linksIndexedByFrom[query.from.id] ?? []) : this.links;

        return links.filter(predicate).map((link) => {
            return {
                from: this.findRegions({ id: link.from })[0],
                to: this.findRegions({ id: link.to })[0],
                type: link.type,
            };
        });
    }
    removeLinks(query: LinkQuery): RegionNetwork {
        const links = this.findLinks(query);
        return new ImmutableRegionNetwork(
            this.regions,
            this.links.filter((link) => {
                return !links.some((l) => l.from.id === link.from && l.to.id === link.to);
            }),
        );
    }
    setLinks(links: Link[]): RegionNetwork {
        return new ImmutableRegionNetwork(
            this.regions,
            links.map((link) => {
                return { from: link.from.id, to: link.to.id, type: link.type };
            }),
        );
    }
    addLinks(links: Link[]): RegionNetwork {
        return new ImmutableRegionNetwork(
            this.regions,
            this.links.concat(
                links.map((link) => {
                    return { from: link.from.id, to: link.to.id, type: link.type };
                }),
            ),
        );
    }
    hasLinks(query: LinkQuery): boolean {
        return this.findLinks(query).length > 0;
    }
}

function linkPredicate(query: LinkQuery): (item: { from: string; to: string; type: Link['type'] }) => boolean {
    return (link) => {
        if (query.from && link.from !== query.from.id) {
            return false;
        }

        if (query.with && link.from !== query.with.id && link.to !== query.with.id) {
            return false;
        }
        if (query.type && link.type !== query.type) {
            return false;
        }
        if (query.pageNumber !== undefined) {
            throw new Error('Not implemented: findLinks with pageNumber');
        }
        if (query.withSome !== undefined) {
            throw new Error('Not implemented: findLinks with withSome');
        }
        return true;
    };
}

function regionPredicate(query: RegionQuery): (region: Region) => boolean {
    return (region): boolean => {
        if (query.id !== undefined && region.id !== query.id) {
            return false;
        }
        if (query.ids !== undefined && !query.ids.includes(region.id)) {
            return false;
        }
        if (query.attribute !== undefined && !region.attributes.some((attr) => attr.attr === query.attribute)) {
            return false;
        }
        if (query.containsEvery !== undefined && !query.containsEvery.every((box) => region.box.contains(box))) {
            return false;
        }
        if (query.intersectsEvery !== undefined && !query.intersectsEvery.every((box) => region.box.intersects(box))) {
            return false;
        }
        if (query.pageNumber !== undefined && region.pageNumber !== query.pageNumber) {
            return false;
        }
        return true;
    };
}
