import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const soldermaskKeywords = ['Solder\\s?(?:mask|stop|resist)', 'Lötstopp?lack', 'Stopp?lack'];

export function parseSoldermaskKeyword(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(soldermaskKeywords);
    if (regex.test(str)) {
        return {
            attr: 'soldermaskKeyword',
            value: undefined,
            // we are more confident about the match the shorter the string that matched
            // this helps us more accurately match the location of the soldermask keyword in the merging stage
            confidence: 1 / str.length,
        };
    }
    return undefined;
}
