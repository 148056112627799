import { colorSystem, Flexbox, FlexboxProps, Text } from '@luminovo/design-system';
import { TableCell } from '@mui/material';
import { TooltipText, TooltipTextProps } from '../../labels';

interface ReactElementRow {
    type: 'reactElement';
    id: string;
    element: React.ReactElement;
}

export type ManufacturingDatabaseCellRow = ReactElementRow;

export const TooltipRow = ({ TooltipTextProps }: { TooltipTextProps: TooltipTextProps }): JSX.Element => {
    return (
        <TooltipText
            text={TooltipTextProps.text}
            maxWidth={TooltipTextProps.maxWidth}
            // TODO: we could potentially calculate this from the maxWidth and the font size.
            // We could do this by assuming that we are using poppins. I think this could come with a later MR.
            minCharactersWithTooltip={TooltipTextProps.minCharactersWithTooltip}
            overrides={
                TooltipTextProps.overrides ?? {
                    textStyle: { color: colorSystem.neutral[9], whiteSpace: 'nowrap' },
                }
            }
        />
    );
};

/** The default TextRow row for the ManufacturingDatabaseTableCell. If you
 * want to use different styling, you can just use the Text component directly.
 */
export const TextRow = ({ text }: { text: string }): JSX.Element => {
    return <Text color={colorSystem.neutral[9]}>{text}</Text>;
};

export const ManufacturingDatabaseTableCell = ({
    children,
    gap = 16,
}: {
    children: React.ReactNode;
    gap?: FlexboxProps['gap'];
}): JSX.Element => {
    return (
        <TableCell
            style={{
                verticalAlign: 'center',
            }}
        >
            <Flexbox gap={gap} flexDirection={'column'} alignContent={'center'}>
                {children}
            </Flexbox>
        </TableCell>
    );
};
