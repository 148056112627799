/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TmeCredentialsDTO extends r.Static<typeof TmeCredentialsDTORuntype> {}
export const TmeCredentialsDTORuntype = r.Union(
    r.Record({
        app_token: r.String,
        app_secret: r.String,
    }),
);

export interface TmeCredentialsInputDTO extends r.Static<typeof TmeCredentialsInputDTORuntype> {}
export const TmeCredentialsInputDTORuntype = r.Record({
    app_token: r.String.optional(),
    app_secret: r.String.optional(),
});

export interface TmeResponseBodyDTO extends r.Static<typeof TmeResponseBodyDTORuntype> {}
export const TmeResponseBodyDTORuntype = r.Record({
    data: TmeCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
