import { t } from '@lingui/macro';
import { Attributes } from './types';

export function formatAttribute(
    attr: Attributes,
    opts: { variant: 'default' | 'long' } = { variant: 'default' },
): string {
    switch (attr) {
        case 'currency':
            return t`Currency`;
        case 'dueDate':
            return t`Valid until`;
        case 'offerDate':
            return t`Offer date`;
        case 'offerNumber':
            return t`Offer number`;
        case 'moq':
            return opts.variant === 'default' ? t`MOQ` : t`Minimum order quantity`;
        case 'mpq':
            return opts.variant === 'default' ? t`MPQ` : t`Minimum package quantity`;
        case 'number':
            return t`Number`;
        case 'packaging':
            return t`Packaging`;
        case 'quantity':
            return t`Quantity`;
        case 'standardFactoryLeadTime':
            return opts.variant === 'default' ? t`Lead time` : t`Standard factory lead time`;
        case 'stock':
            return t`Stock`;
        case 'totalPrice':
            return t`Total price`;
        case 'unit':
            return t`Unit`;
        case 'unitPrice':
            return t`Unit price`;
        case 'inStock':
            return t`In stock`;
        case 'part':
            return t`MPN`;
        case 'isRow':
            return t`Row`;
        case 'validFor':
            return t`Valid for`;
    }
}
