import { ComplianceStatus } from '@luminovo/http-client';

const complianceStatusKeyWithComplianceValue: Record<ComplianceStatus, boolean> = {
    [ComplianceStatus.Compliant]: false,
    [ComplianceStatus.CompliantWithExemption]: false,
    [ComplianceStatus.NonCompliant]: true,
    [ComplianceStatus.NotRequired]: false,
    [ComplianceStatus.Unknown]: false,
};
export function isComplianceError(complianceStatus: ComplianceStatus): boolean {
    return complianceStatusKeyWithComplianceValue[complianceStatus];
}
