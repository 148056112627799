import { Trans, t } from '@lingui/macro';
import {
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    Message,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    Currency,
    Packaging,
    TotalCostOfOwnershipRuleCategory,
    TotalCostOfOwnershipRuleConditionType,
    TotalCostOfOwnershipRuleDTO,
    TotalCostOfOwnershipScaling,
} from '@luminovo/http-client';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { TcoRuleForm, TcoRuleFormState, convertTcoRuleDto, convertTcoRuleFormState } from './TcoRuleForm';

export const tcoRuleDisabledText = () =>
    t`This rule has been disabled because a connected entity (e.g. a supplier) has been deleted. It won't be considered for additional costs in sourcing. To resolve this issue please check if the rule is correct and either save or delete it.`;

export function useAddTcoRuleDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation(`POST /total-cost-of-ownership-rules`, {
        snackbarMessage: t`Rule added`,
        onSuccess: closeDialog,
        disableOnError: true,
    });

    const onSubmit = async (form: TcoRuleFormState) => {
        await mutateAsync({ requestBody: convertTcoRuleFormState(form) });
    };

    const defaultValues: TcoRuleFormState = {
        name: '',
        description: '',
        category: TotalCostOfOwnershipRuleCategory.Packaging,
        action: {
            type: 'Absolute',
            value: {
                amount: '0',
                currency: Currency.EUR,
            },
        },
        scaling: TotalCostOfOwnershipScaling.Total,
        conditions: [
            {
                condition_type: TotalCostOfOwnershipRuleConditionType.Packaging,
                value: Packaging.ReReel,
            },
        ],
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Add total cost of ownership rule`} handleClose={() => closeDialog()} />

                    <DialogContent>
                        <TcoRuleForm onClose={closeDialog} onSubmit={onSubmit} defaultValues={defaultValues} />
                    </DialogContent>
                </Dialog>,
            ),
        closeDialog,
    };
}

export function useEditTcoRuleDialog(rule: TotalCostOfOwnershipRuleDTO) {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation(`PATCH /total-cost-of-ownership-rules/:id`, {
        snackbarMessage: t`Rule updated`,
        onSuccess: closeDialog,
        disableOnError: true,
    });

    const onSubmit = async (form: TcoRuleFormState) => {
        await mutateAsync({ requestBody: convertTcoRuleFormState(form), pathParams: { id: rule.id } });
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'sm'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Edit total cost of ownership rule`} handleClose={() => closeDialog()} />
                    <DialogContent>
                        <Flexbox flexDirection="column" gap={16}>
                            {!rule.is_valid && (
                                <Message
                                    variant="red"
                                    attention="high"
                                    size="large"
                                    title={t`This rule is disabled`}
                                    message={tcoRuleDisabledText()}
                                />
                            )}
                            <TcoRuleForm
                                onClose={closeDialog}
                                onSubmit={onSubmit}
                                defaultValues={convertTcoRuleDto(rule)}
                            />
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
        closeDialog,
    };
}

export function useDeleteTcoRuleDialog(rule: TotalCostOfOwnershipRuleDTO) {
    const { setDialog, closeDialog } = useDialogContext();
    const { mutateAsync, isLoading } = useHttpMutation(`DELETE /total-cost-of-ownership-rules/:id`, {
        snackbarMessage: t`Rule deleted`,
        onSuccess: closeDialog,
    });

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'xs'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Delete total cost of ownership rule`} handleClose={() => closeDialog()} />
                    <DialogContent>
                        <Text>
                            <Trans>Are you sure you want to delete this rule?</Trans>
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={() => closeDialog()}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <DestructivePrimaryButton
                            isLoading={isLoading}
                            onClick={() => mutateAsync({ pathParams: { id: rule.id } })}
                        >
                            <Trans>Yes, delete</Trans>
                        </DestructivePrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
        closeDialog,
    };
}
