import { t } from '@lingui/macro';
import { CenteredLayout, Column, NonIdealState } from '@luminovo/design-system';
import { ExpenseDTO, IdWithIndex } from '@luminovo/http-client';
import { PaidOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useMutationUpdateTemplate } from '../../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { levelColumn } from '../../../ExpensesView';
import { SortableEntityTable } from '../../../shared/Entities/SortableEntityTable';
import { categoryColumn, nameColumn, processColumn } from '../../../shared/sharedManufacturingColumns';
import { useExpenseSelectionDialog } from './ExpenseSelectionDialog';

export const SortableExpensesTable = ({
    manufacturingScenarioTemplateId,
    orderedExpenseIds,
}: {
    manufacturingScenarioTemplateId: string;
    orderedExpenseIds: IdWithIndex[];
}): JSX.Element => {
    const onOrderUpdate = useOrderUpdate(manufacturingScenarioTemplateId);
    const { data: expensesData, isLoading: isLoadingActivities } = useHttpQuery('GET /expenses', {
        queryParams: {},
    });

    const { openDialog: openExpensesDialog } = useExpenseSelectionDialog({
        orderedExpenseIds,
        manufacturingScenarioTemplateId,
    });

    if (isLoadingActivities || !expensesData) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />;
            </CenteredLayout>
        );
    }

    const expenses = expensesData.data;
    const columns: Column<ExpenseDTO>[] = [nameColumn, processColumn, categoryColumn, levelColumn];
    const persistenceId = `SortableActivitiesTable-${manufacturingScenarioTemplateId}`;

    return (
        <SortableEntityTable
            entities={expenses}
            order={orderedExpenseIds}
            persistenceId={persistenceId}
            columns={columns}
            onOrderUpdate={onOrderUpdate}
            NoResultsComponent={() => (
                <CenteredLayout>
                    <NonIdealState
                        Icon={PaidOutlined}
                        title={t`No expenses selected`}
                        description={t`There are currently no expenses selected for this template`}
                        action={{
                            onClick: () => openExpensesDialog(),
                            children: t`Select expenses`,
                        }}
                    />
                </CenteredLayout>
            )}
        />
    );
};

const useOrderUpdate = (templateId: string) => {
    const { mutateAsync } = useMutationUpdateTemplate({ templateId, disableSnackbar: true });

    return async (orderedItems: ExpenseDTO[]) => {
        await mutateAsync({
            // eslint-disable-next-line camelcase
            ordered_expenses: orderedItems.map((item, index) => ({ id: item.id, index })),
        });
    };
};
