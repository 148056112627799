import { t } from '@lingui/macro';
import { FormItem } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { RfqCreationFormState, useRfqCreationContext } from '../../RfqCreationContext';
import { CustomerInput } from './CustomerAutocomplete';
import { NavigationButtons } from './NavigationButtons';

export const CustomerForm = (): JSX.Element => {
    const { trigger, formState } = useFormContext<RfqCreationFormState>();
    const { hasCustomers } = useHasCustomers();
    const { setActiveStep } = useRfqCreationContext();

    const onNext = async () => {
        if (await trigger('customer')) {
            setActiveStep('assembly');
        }
    };

    return (
        <>
            <FormItem label={hasCustomers ? t`Customer` : t`Company`} required>
                <CustomerInput />
            </FormItem>

            <NavigationButtons
                showPrevious={false}
                nextLabel={t`Next`}
                onNext={onNext}
                isDisabled={Object.keys(formState.errors.customer ?? {}).length > 0}
            />
        </>
    );
};
