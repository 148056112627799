import { t } from '@lingui/macro';
import { FormItem, FormSection } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import {
    CheckboxFormControlController,
    RadioGroupController,
    SelectController,
    TextFieldController,
} from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { activityCreationLevelEnumPublicTranslations } from '../../../resources/activity/activityBackendTypes';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import {
    categorySelectionPublicTranslations,
    processPublicTranslations,
} from '../shared/sharedManufacturingBackendTypes';
import { AddActivityFormInputs } from './AddActivityFormTypes';

export function ActivitySection({ title }: { title: string }): JSX.Element {
    const { control, watch } = useFormContext<AddActivityFormInputs>();
    const selectedLevel = watch('activity.level');
    return (
        <FormSection
            title={title}
            style={{
                width: '50%',
            }}
        >
            <FormItem label={t`Name`} required>
                <TextFieldController
                    name={'activity.name'}
                    control={control}
                    TextFieldProps={{
                        placeholder: t`e.g. Pick and place`,
                    }}
                />
            </FormItem>
            <FormItem label={t`Internal activity number`}>
                <TextFieldController name={'activity.internalActivityNumber'} control={control} />
            </FormItem>
            <FormItem label={capitalizeFirstLetter(t`process`)} required>
                <SelectController
                    name={'activity.process'}
                    control={control}
                    translations={processPublicTranslations}
                />
            </FormItem>
            <FormItem label={capitalizeFirstLetter(t`category`)} required>
                <SelectController
                    name={'activity.category'}
                    control={control}
                    translations={categorySelectionPublicTranslations}
                />
            </FormItem>
            <FormItem label={t`Level`} required>
                <RadioGroupController
                    name={'activity.level'}
                    control={control}
                    options={activityCreationLevelEnumPublicTranslations}
                />
            </FormItem>
            {selectedLevel === 'Unit' && (
                <CheckboxFormControlController
                    name={'activity.isActivityPerPanel'}
                    control={control}
                    label={t`Activity is per panel (not individual assemblies)`}
                />
            )}
            <FormItem label={t`Description`}>
                <TextFieldController
                    name={'activity.description'}
                    TextFieldProps={{ multiline: true, rows: 4 }}
                    control={control}
                />
            </FormItem>
        </FormSection>
    );
}
