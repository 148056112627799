export interface Parser<T> {
    /**
     * Parse the input string.
     */
    parse(input: string): ParseResult<T>;

    /**
     * A helper function to check if the input matches the parser.
     */
    matches(input: string): boolean;

    map<U>(fn: (value: T) => U): Parser<U>;

    warnIf(predicate: (value: T) => string | undefined): Parser<T>;
}

export type ParseResult<T> =
    | {
          ok: true;
          value: T;
          rest: string;
          message?: string;
      }
    | {
          ok: false;
          expected: string;
      };

export class BaseParser<T> implements Parser<T> {
    public static of<T>(fn: (input: string) => ParseResult<T>): Parser<T> {
        return new BaseParser<T>(fn);
    }

    constructor(private fn: (input: string) => ParseResult<T>) {}

    parse(input: string): ParseResult<T> {
        return this.fn(input);
    }

    matches(input: string): boolean {
        return this.parse(input).ok;
    }

    map<U>(mapper: (value: T) => U): Parser<U> {
        return new BaseParser((input) => {
            const result = this.fn(input);
            if (result.ok) {
                try {
                    return { ok: true, value: mapper(result.value), rest: result.rest };
                } catch (e) {
                    return { ok: false, expected: String(e) };
                }
            }
            return result;
        });
    }

    warnIf(predicate: (value: T) => string): Parser<T> {
        return new BaseParser((input) => {
            const result = this.fn(input);
            if (result.ok) {
                const message = predicate(result.value);
                if (message) {
                    return {
                        ...result,
                        message,
                    };
                }
                return result;
            }
            return result;
        });
    }
}

export class ParseError extends Error {
    constructor(public expected: string) {
        super(`Expected ${expected}`);
    }
}
