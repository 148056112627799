import { formatDecimal } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import * as React from 'react';

export const FormulaValue = React.forwardRef<HTMLHeadingElement, { label: string | number | JSX.Element }>(
    ({ label }, ref) => {
        const formatted = () => {
            if (typeof label === 'number') {
                return formatDecimal(label);
            }
            return label;
        };
        return <Tag ref={ref} color="primary" attention="low" label={formatted()} />;
    },
);
