import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { SecondaryButton, Toolbar, Tooltip } from '@luminovo/design-system';
import { AssemblyDTO, AssemblyResponseDTO, isCustomFullPart } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import { ViewCommentsButton, useCommunicationsDrawer } from '../../../../../components/CommentsDrawer';
import { useDesignItems } from '../../../../../resources/designItem/designItemHandler';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useCalculationWithoutManufacturingEnabled } from '../../../../../resources/organizationSettings/calculationWithoutManufacturingHandler';
import { useApprovedParts } from '../../../../../resources/part/partHandler';
import { route } from '../../../../../utils/routes';
import { getBomItemsWithIndividualDesignItems } from '../../../../Bom/hooks/utils/designItemAggregation';

const AssemblySourcingCommentsButton = ({ assembly, rfqId }: { assembly: AssemblyDTO; rfqId: string }) => {
    const designItemIds = assembly.design_items.items;
    const { data: designItems = [] } = useDesignItems(designItemIds);
    const groupedDesignItems = getBomItemsWithIndividualDesignItems(designItems);

    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads: groupedDesignItems.flatMap((comp) => {
            const typeIds = comp.individualDesignItems.map((di) => di.id);

            return [
                {
                    commentType: 'DesignItem',
                    category: 'Internal',
                    typeIds,
                },
                {
                    commentType: 'DesignItem',
                    category: 'Public',
                    typeIds,
                },
            ];
        }),
    });

    return (
        <ViewCommentsButton eventEntity={{ type: 'DesignItem', data: designItemIds }} onClick={() => openDrawer()} />
    );
};

const AddOfferButton = ({ assembly, rfqId }: { assembly: AssemblyDTO; rfqId: string }) => {
    const { data: designItems } = useDesignItems(assembly.design_items.items);
    const { data: parts = [] } = useApprovedParts({
        partSpecifications: designItems?.map((item) => item.part_specification),
        rfqContext: { rfq_id: rfqId, type: 'WithinRfQ' },
    });
    const customPart = parts.find((p) => isCustomFullPart(p));

    return (
        <>
            {customPart && (
                <SecondaryButton
                    size="medium"
                    startIcon={<Add />}
                    href={route('/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId', {
                        partId: customPart.id,
                        rfqId: rfqId,
                    })}
                >
                    <Trans>Add offer</Trans>
                </SecondaryButton>
            )}
        </>
    );
};

const AssemblyCalculationButton = ({ assembly, rfqId }: { assembly: AssemblyResponseDTO; rfqId: string }) => {
    const { data: calculationWithoutManufacturingSettings } = useCalculationWithoutManufacturingEnabled();
    const isCalculationWithoutManufacturingEnabled =
        calculationWithoutManufacturingSettings?.calculation_without_manufacturing_enabled ?? true;
    const warningText = isCalculationWithoutManufacturingEnabled
        ? t`This assembly was not assigned a quantity in any sourcing scenario`
        : t`Manufacturing information not submitted`;

    const { data: calculationAssemblyDetailsData } = useHttpQuery(
        'GET /calculations/calculation-assembly-details',
        { queryParams: { assembly_id: assembly.id } },
        { suspense: true, useErrorBoundary: true },
    );

    const isCalculationBuilt =
        isPresent(calculationAssemblyDetailsData) && calculationAssemblyDetailsData.data[0] !== undefined;

    return (
        <Tooltip title={isCalculationBuilt === false ? warningText : ''}>
            <span>
                <SecondaryButton
                    disabled={isCalculationBuilt === false}
                    size="medium"
                    href={route('/rfqs/:rfqId/calculation/:assemblyId/calculation', {
                        rfqId,
                        assemblyId: assembly.id,
                    })}
                >
                    <Trans>Go to calculation</Trans>
                </SecondaryButton>
            </span>
        </Tooltip>
    );
};

export const AssemblySourcingHeader = ({ assembly, rfqId }: { assembly: AssemblyDTO; rfqId: string }) => {
    return (
        <Toolbar
            breadcrumbs={[
                { title: t`Sourcing`, href: route(`/rfqs/:rfqId/sourcing`, { rfqId }) },
                { title: assembly.designator },
            ]}
        >
            <AssemblyCalculationButton assembly={assembly} rfqId={rfqId} />
            <AddOfferButton assembly={assembly} rfqId={rfqId} />
            <AssemblySourcingCommentsButton assembly={assembly} rfqId={rfqId} />
        </Toolbar>
    );
};
