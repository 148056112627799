import { Trans, t } from '@lingui/macro';
import { groupBy, isPresent } from '@luminovo/commons';
import {
    BaseButtonProps,
    Column,
    CrossedOutLinkIcon,
    DataTable,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldCheckbox,
    FieldController,
    FieldNumericControlled,
    Flexbox,
    FormItem,
    LightningBoltIcon,
    SecondaryButton,
    TertiaryButton,
    TertiaryIconButton,
    Text,
    Tooltip,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { ConfigurationCount } from '@luminovo/http-client';
import { CombinedDriverType, DriverLabel } from '@luminovo/manufacturing-core';
import { Delete, Edit, Undo } from '@mui/icons-material';
import { DialogActions, FormControlLabel, InputAdornment, Table, TableCell, styled } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../../components/formLayouts/SubmitButton';
import { DriverNotes } from '../../../../shared/ManufacturingSharedComponents';
import { StyledLinkIcon } from '../CountIcons';

const validationErrors: ValidationErrors<EditDriverCountFormState> = {
    'manufacturing_assembly_details.panel_factor_less_than_one': {
        fieldPath: 'count',
    },
};

export interface EditDriverCountProps extends EditDriverCountFormProps {
    assemblyWideCount: string | undefined;
    onSubmit: SubmitHandler<EditDriverCountFormState>;
}

export const EditDriverCountButton = (props: BaseButtonProps): JSX.Element => {
    return (
        <TertiaryButton startIcon={<Edit fontSize="inherit" />} size="medium" {...props}>
            <Trans>Edit</Trans>
        </TertiaryButton>
    );
};

export const EditDriverCount = ({
    assemblyWideCount,
    driverName,
    driverType,
    driverNotes,
    automaticCount,
    driverCountInconsistencies,
    onSubmit,
}: EditDriverCountProps): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const defaultValues: EditDriverCountFormState = {
        count: isPresent(assemblyWideCount)
            ? Number(assemblyWideCount)
            : isPresent(automaticCount)
              ? Number(automaticCount)
              : undefined,
        shouldAutomaticDriverCountBeReset: false,
        isAppliedToAllActivitiesAndExpenses: false,
    };

    const wrappedOnSubmit = async (data: EditDriverCountFormState) => {
        await onSubmit(data);
        setIsDialogOpen(false);
    };

    return (
        <>
            <EditDriverCountButton onClick={() => setIsDialogOpen(true)} />

            <Dialog open={isDialogOpen} maxWidth="sm">
                <FormContainer
                    defaultValues={defaultValues}
                    onSubmit={wrappedOnSubmit}
                    validationErrors={validationErrors}
                >
                    <DialogTitle title={t`Edit driver count`} />
                    <DialogContent>
                        <EditDriverCountForm
                            driverName={driverName}
                            driverType={driverType}
                            driverNotes={driverNotes}
                            automaticCount={automaticCount}
                            driverCountInconsistencies={driverCountInconsistencies}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Flexbox gap="8px">
                            <SecondaryButton onClick={() => setIsDialogOpen(false)}>
                                <Trans>Cancel</Trans>
                            </SecondaryButton>
                            <SubmitButton />
                        </Flexbox>
                    </DialogActions>
                </FormContainer>
            </Dialog>
        </>
    );
};

const DriverCountTypeTooltip = ({ type }: { type: 'Automatic' | 'Overwritten' }): JSX.Element => {
    const title = type === 'Automatic' ? t`Automatic count` : t`Manual count`;

    const iconStyle: CSSProperties = {
        verticalAlign: 'middle',
    };

    return (
        <Tooltip title={title}>
            <Text style={{ cursor: 'pointer' }}>
                {type === 'Automatic' && <StyledLinkIcon color="primary" style={{ ...iconStyle, fontSize: '20px' }} />}
                {type === 'Overwritten' && <CrossedOutLinkIcon style={iconStyle} />}
            </Text>
        </Tooltip>
    );
};

export interface EditDriverCountFormState {
    count: number | undefined | '';
    shouldAutomaticDriverCountBeReset: boolean;
    isAppliedToAllActivitiesAndExpenses: boolean;
}

const useDriverCountForm = (automaticCount: string | undefined) => {
    const { control, setValue } = useFormContext<EditDriverCountFormState>();
    const count = useWatch({ name: 'count', control });
    const isAutomaticCount =
        isPresent(automaticCount) && (isPresent(count) ? automaticCount === count.toString() : true);

    const deleteOverwrittenCount = () => {
        const newCount = isPresent(automaticCount) ? Number(automaticCount) : '';

        setValue('count', newCount, { shouldDirty: true });
        setValue('shouldAutomaticDriverCountBeReset', true);
    };

    return {
        control,
        isAutomaticCount,
        deleteOverwrittenCount,
    };
};

interface EditDriverCountFormProps {
    driverName: string;
    driverType: CombinedDriverType;
    driverNotes: string | undefined;
    automaticCount: string | undefined;
    driverCountInconsistencies: ConfigurationCount[];
}

const EditDriverCountForm = ({
    driverName,
    driverType,
    driverNotes,
    automaticCount,
    driverCountInconsistencies,
}: EditDriverCountFormProps): JSX.Element => {
    const { control, isAutomaticCount, deleteOverwrittenCount } = useDriverCountForm(automaticCount);

    const { resetLabel, resetIcon } =
        automaticCount !== undefined
            ? { resetLabel: `${t`Reset to automatic count`}: ${automaticCount}`, resetIcon: <Undo /> }
            : {
                  resetLabel: t`Delete overwritten count`,
                  resetIcon: <Delete />,
              };

    return (
        <Flexbox flexDirection="column" gap="18px">
            <FormItem label={t`Driver name`}>
                <DriverLabel type={driverType} name={driverName} />
                {driverNotes && <DriverNotes notes={driverNotes} />}
            </FormItem>

            <FormItem required label={t`Driver count`}>
                <FieldNumericControlled
                    name="count"
                    control={control}
                    required
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DriverCountTypeTooltip type={isAutomaticCount ? 'Automatic' : 'Overwritten'} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={resetLabel} enterDelay={0} placement="top-end">
                                        <TertiaryIconButton onClick={deleteOverwrittenCount}>
                                            {resetIcon}
                                        </TertiaryIconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </FormItem>

            {driverCountInconsistencies.length > 0 && (
                <FormItem
                    label={t`Driver count inconsistencies`}
                    description={t`The driver count is different across these manufacturing scenarios`}
                >
                    <Flexbox flexDirection="column" gap="14px">
                        <InconsistentDriverCounts driverCountInconsistencies={driverCountInconsistencies} />
                        <FormControlLabel
                            control={
                                <FieldController
                                    name="isAppliedToAllActivitiesAndExpenses"
                                    control={control}
                                    Field={FieldCheckbox}
                                />
                            }
                            label={t`Apply to all activities and expenses`}
                            style={{ margin: 0, gap: '8px' }}
                        />
                    </Flexbox>
                </FormItem>
            )}
        </Flexbox>
    );
};

interface InconsistenDriverCountRow {
    scenarioName: string;
    configurationCounts: ConfigurationCount[];
}

const TableWithoutMinWidth = styled(Table)({
    overflow: 'visible',
});

const InconsistentDriverCounts = ({
    driverCountInconsistencies,
}: {
    driverCountInconsistencies: ConfigurationCount[];
}): JSX.Element => {
    const items = Object.entries(groupBy(driverCountInconsistencies, 'scenario_name')).map(
        ([scenarioName, configurationCounts]) => ({
            scenarioName,
            configurationCounts,
        }),
    );

    const columns: Column<InconsistenDriverCountRow>[] = [
        {
            id: 'scenarioName',
            label: t`Manufacturing scenario`,
            render: ({ data: rowData }) => (
                <TableCell>
                    <Text>{rowData.scenarioName}</Text>
                </TableCell>
            ),
        },
        {
            id: 'entityName',
            label: t`Activity/expense`,
            render: ({ data: rowData }) => (
                <TableCell>
                    <Flexbox flexDirection="column">
                        {rowData.configurationCounts.map((configurationCount) => (
                            <Text key={configurationCount.entity_name}>{configurationCount.entity_name}</Text>
                        ))}
                    </Flexbox>
                </TableCell>
            ),
        },
        {
            id: 'driverCount',
            label: t`Driver count`,
            render: ({ data: rowData }) => (
                <TableCell style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <Flexbox flexDirection="column">
                        {rowData.configurationCounts.map((configurationCount) => (
                            <Flexbox key={configurationCount.entity_name} gap="4px">
                                <LightningBoltIcon fill={colorSystem.yellow[7]} />
                                <Text>{configurationCount.driver_count}</Text>
                            </Flexbox>
                        ))}
                    </Flexbox>
                </TableCell>
            ),
        },
    ];

    const tableState = useDataTableState({
        persistenceId: 'inconsistent-driver-counts',
        columns,
        items,
        paginationOptions: {
            showPagination: false,
        },
    });

    return (
        <DataTable
            tableState={tableState}
            size="small"
            overrides={{
                Table: TableWithoutMinWidth,
            }}
        />
    );
};
