import { t } from '@lingui/macro';
import { Currency, formatDays, isPresent, typeSafeObjectKeys, uniqBy } from '@luminovo/commons';
import { ValidFor } from '@luminovo/http-client';
import { VisibleAttributes } from '../../../tables/PdfViewerTable/types';
import { BoundingBox } from '../boundingBox';
import { RegionNetwork } from './RegionNetwork';
import { compareByConfidence } from './compareByConfidence';
import { compareByVerticalDistance } from './compareByVerticalDistance';
import { findAttributeByType } from './findAttributeByType';
import { InferredAttribute, infer } from './infer';
import { isAttributeRequired } from './isAttributeRequired';
import { Attribute, AttributeOf, Attributes, Region } from './types';

export interface ExtractedValue<T> {
    value: T;
    extractedFrom: Region[];
    explanation?: string;
}

interface PdfExtractedRow {
    pageNumber: number;
    boundingBox: BoundingBox;
    fields: {
        [attr in VisibleAttributes]?: ExtractedValue<AttributeOf<attr>>;
    };
}
export interface PdfExtractionResults {
    currency: ExtractedValue<AttributeOf<'currency'>>;
    offerNumber?: ExtractedValue<AttributeOf<'offerNumber'>>;
    validUntil?: ExtractedValue<AttributeOf<'dueDate'> | AttributeOf<'offerDate'>>;
    validFor: ExtractedValue<AttributeOf<'validFor'>>;

    extractedRows: PdfExtractedRow[];

    extractionRateOfRequiredFields: number;
    numberOfFieldsAutomaticallyExtracted: number;
}

function calculateExtractionStatistics(
    extractionResults: Pick<PdfExtractionResults, 'currency' | 'offerNumber' | 'validUntil' | 'extractedRows'>,
): { extractionRateOfRequiredFields: number; numberOfFieldsAutomaticallyExtracted: number } {
    const keysRecord: Record<VisibleAttributes, true> = {
        part: true,
        stock: true,
        moq: true,
        mpq: true,
        unitPrice: true,
        packaging: true,
        standardFactoryLeadTime: true,
        unit: true,
    };
    const requiredAttributes = typeSafeObjectKeys(keysRecord).filter((attr) => isAttributeRequired({ attr }));
    const requiredFields = [
        extractionResults.currency?.value,
        extractionResults.offerNumber?.value,
        extractionResults.validUntil?.value,
        ...extractionResults.extractedRows.flatMap((row) => {
            return requiredAttributes.map((key) => {
                return row.fields[key]?.value.value;
            });
        }),
    ];
    const extractionRateOfRequiredFields = requiredFields.filter(isPresent).length / requiredFields.length;

    const allVisibleAttributes = typeSafeObjectKeys(keysRecord);
    const numberOfFieldsAutomaticallyExtracted = [
        { extractedFrom: extractionResults.currency?.extractedFrom, origin: extractionResults.currency?.value.origin },
        {
            extractedFrom: extractionResults.offerNumber?.extractedFrom,
            origin: extractionResults.offerNumber?.value.origin,
        },
        {
            extractedFrom: extractionResults.validUntil?.extractedFrom,
            origin: extractionResults.validUntil?.value.origin,
        },
        ...extractionResults.extractedRows.flatMap((row) => {
            return allVisibleAttributes.map((key) => {
                return { extractedFrom: row.fields[key]?.extractedFrom, origin: row.fields[key]?.value.origin };
            });
        }),
    ].filter(
        (field) => isPresent(field.extractedFrom) && field.extractedFrom.length > 0 && field.origin !== 'manual',
    ).length;

    return { extractionRateOfRequiredFields, numberOfFieldsAutomaticallyExtracted };
}

export function composeExtractionResults(rn: RegionNetwork): PdfExtractionResults {
    const currency = findCurrency(rn);
    const offerNumber = findOfferNumber(rn);
    const validUntil = findValidUntil(rn);
    const validFor = findValidFor(rn);
    const extractedRows = findExtractedRows(rn);

    const { extractionRateOfRequiredFields, numberOfFieldsAutomaticallyExtracted } = calculateExtractionStatistics({
        extractedRows,
        validUntil,
        offerNumber,
        currency,
    });

    return {
        currency,
        offerNumber,
        validUntil,
        validFor,
        extractedRows,
        extractionRateOfRequiredFields,
        numberOfFieldsAutomaticallyExtracted,
    };
}

function findCurrency(rn: RegionNetwork): ExtractedValue<AttributeOf<'currency'>> {
    return (
        findRegionsWithAttribute<'currency'>(rn, 'currency') ?? {
            extractedFrom: [],
            value: {
                attr: 'currency',
                value: Currency.EUR,
                confidence: 0,
            },
        }
    );
}

function findOfferNumber(rn: RegionNetwork): ExtractedValue<AttributeOf<'offerNumber'>> | undefined {
    return findRegionsWithAttribute<'offerNumber'>(rn, 'offerNumber');
}

function findValidFor(rn: RegionNetwork): ExtractedValue<AttributeOf<'validFor'>> {
    return (
        findRegionsWithAttribute<'validFor'>(rn, 'validFor') ?? {
            extractedFrom: [],
            value: {
                attr: 'validFor',
                value: ValidFor.EveryCustomer,
                confidence: 0,
            },
        }
    );
}

function findValidUntil(
    rn: RegionNetwork,
): ExtractedValue<AttributeOf<'dueDate'> | AttributeOf<'offerDate'>> | undefined {
    const dueDateRegions = rn.findRegions({ attribute: 'dueDate' });
    const dueDate = findAttributeByType(
        dueDateRegions.flatMap((a) => a.attributes),
        'dueDate',
    );

    if (dueDate?.value) {
        return {
            explanation: formatDaysDiff(new Date(), new Date(dueDate.value)),
            extractedFrom: dueDateRegions,
            value: dueDate,
        };
    }

    const offerDateRegions = rn.findRegions({ attribute: 'offerDate' });
    const offerDate = findAttributeByType(
        offerDateRegions.flatMap((a) => a.attributes),
        'offerDate',
    );

    if (offerDate) {
        const offerDatePlus30Days = offerDate?.value ? new Date(offerDate.value) : undefined;
        offerDatePlus30Days?.setDate(offerDatePlus30Days.getDate() + 30);

        return {
            explanation: t`We found the date of the offer. Assuming it is valid for 30 days.`,
            extractedFrom: offerDateRegions,
            value: {
                attr: 'offerDate',
                value: offerDatePlus30Days?.toISOString().split('T')[0] ?? '',
                confidence: 0,
            },
        };
    }

    const todayPlus30Days = new Date();
    todayPlus30Days.setDate(todayPlus30Days.getDate() + 30);

    return {
        explanation: t`No offer date found. Assuming offer is valid for today + 30 days`,
        extractedFrom: [],
        value: {
            attr: 'dueDate',
            value: todayPlus30Days.toISOString().split('T')[0],
            confidence: 0,
        },
    };
}

function findExtractedRows(rn: RegionNetwork): Array<PdfExtractedRow> {
    return rn
        .findRegions({ attribute: 'part' })
        .map((row): PdfExtractedRow => {
            const regions = [row, ...rn.findLinks({ from: row }).map((link) => link.to)].sort(
                compareByVerticalDistance(row),
            );
            const extractedAttrs = regions.flatMap((region) => region.attributes);
            const withInferredAttrs = infer(extractedAttrs);

            function findExtractedField<T extends Attributes>(type: T): ExtractedValue<AttributeOf<T>> | undefined {
                const attr = findAttributeByType(withInferredAttrs, type);
                if (!attr) {
                    return undefined;
                }

                return {
                    value: attr,
                    extractedFrom: uniqBy(
                        regions.filter((region) => {
                            return isAttributableToRegion(region, [attr]);
                        }),
                        (x) => x.id,
                    ),
                };
            }

            return {
                pageNumber: row.pageNumber,
                boundingBox: row.box,
                fields: {
                    part: findExtractedField('part'),
                    stock: findExtractedField('stock'),
                    moq: findExtractedField('moq'),
                    mpq: findExtractedField('mpq'),
                    unitPrice: findExtractedField('unitPrice'),
                    packaging: findExtractedField('packaging'),
                    standardFactoryLeadTime: findExtractedField('standardFactoryLeadTime'),
                    unit: findExtractedField('unit'),
                },
            };
        })
        .sort((a, b) => a.pageNumber - b.pageNumber || a.boundingBox.y - b.boundingBox.y);
}

function* iterateAttributes(inferredAttrs: InferredAttribute[]): Generator<Attribute> {
    for (const attr of inferredAttrs) {
        yield attr;
        if (attr.inferredFrom) {
            yield* iterateAttributes(attr.inferredFrom);
        }
    }
}

export function isAttributableToRegion(region: Region, inferredAttrs: InferredAttribute[]): boolean {
    for (const attr of iterateAttributes(inferredAttrs)) {
        if (region.attributes.some((regionAttr) => regionAttr.attr === attr.attr && regionAttr.value === attr.value)) {
            return true;
        }
    }
    return false;
}

function findRegionsWithAttribute<T extends Attributes>(
    rn: RegionNetwork,
    attribute: T,
): ExtractedValue<AttributeOf<T>> | undefined {
    const regions = rn.findRegions({ attribute });
    const attributes: InferredAttribute[] = regions.flatMap((a) => a.attributes).sort(compareByConfidence);
    const attr = findAttributeByType(attributes, attribute);
    if (!attr) {
        return undefined;
    }
    return {
        extractedFrom: regions.filter((r) => isAttributableToRegion(r, [attr])),
        value: attr,
    };
}

function formatDaysDiff(from: Date, to: Date) {
    return `Expires in ${formatDays(Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24)))}`;
}
