import { t } from '@lingui/macro';
import { CostTypeFormula, UnitCost } from '../utils/types';

export const defaultValuePercentage: UnitCost = {
    type: 'percentage',
    value: { percentage: '0' },
};

const limit = 30;
export const nameRule = () => ({
    required: t`Required`,
    maxLength: {
        value: limit,
        message: t`Must not be more than ${limit} characters`,
    },
});

function isDecimalWithThreeOrFewerPlaces(num: number): boolean {
    const parts = num.toString().split('.');
    if (parts.length === 1) {
        return true;
    }
    return parts[1].length <= 3;
}

export const validateAmount = (value: unknown): string | undefined => {
    const num = Number(value);
    if (!isDecimalWithThreeOrFewerPlaces(num)) {
        return t`Must be three or fewer decimal places`;
    }
    return undefined;
};

export const formulaRules = {
    validate: (x: UnitCost) => {
        const input = assertIsFormulaCost(x);
        return !input.value || !input.value.value || input.value.value?.length === 0 ? t`Required` : undefined;
    },
};

export function assertIsFormulaCost(unitCost: UnitCost): CostTypeFormula {
    if (unitCost.type === 'formula') {
        return unitCost;
    } else {
        throw new Error(`should have unit type formula but instead received ${unitCost.type}`);
    }
}
