import * as React from 'react';
import { TextField, TextFieldProps } from '../../TextField';
import { FieldConverter } from './FieldConverter';

export type BaseFieldProps<TValue> = Omit<TextFieldProps, 'onChange'> & {
    value: TValue;

    onChange: (value: TValue) => void;

    converter: FieldConverter<TValue>;
};

export function BaseField<TValue>(props: BaseFieldProps<TValue>): JSX.Element {
    const { onBlur, type, value, onChange, error, required, converter, ...rest } = props;
    const { formatValue, parseValue } = converter;
    const [text, setText] = React.useState<string>(formatValue(value));

    React.useEffect(() => {
        setText(formatValue(value));
    }, [value, formatValue]);

    return (
        <TextField
            type={type}
            value={text}
            required={required}
            onBlur={(e) => {
                setText(formatValue(value));
                onBlur && onBlur(e);
            }}
            onChange={(e) => {
                const newText = e.target.value;
                setText(newText);

                const parsed = parseValue(newText);
                if (parsed.ok) {
                    onChange(parsed.value);
                }
            }}
            error={parseValue(text).ok === false || error}
            {...rest}
        />
    );
}
