import { t } from '@lingui/macro';
import { ImportStatus } from '../types';

export function formatImportStatus(status: ImportStatus['success'] | undefined): string {
    switch (status) {
        case true:
            return t`Success`;
        case false:
            return t`Error`;
        case undefined:
            return t`Excluded`;
    }
}
