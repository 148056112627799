import { Trans, t } from '@lingui/macro';
import { transEnum, uniqueBy } from '@luminovo/commons';
import {
    CenteredLayout,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SecondaryButton,
    Tag,
    TertiaryButton,
    colorSystem,
} from '@luminovo/design-system';
import { PCBFileTypes, PCBV2, PCBV2File, SingleFileTypeUpdate } from '@luminovo/http-client';
import { ExpandMoreRounded } from '@mui/icons-material';
import { Checkbox, CircularProgress } from '@mui/material';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { PCBFileColors } from '../../components/LayerAssignment/utils/const';
import { pcbFileTypesTranslations } from '../../components/LayerAssignment/utils/i18n';
import { StateFileType, getFileStateType } from '../../components/LayerAssignment/utils/layerAssignmentsUtils';
import { useMutationUpdatePcbFileTypes } from '../../components/LayerAssignment/utils/useMutationUpdatePcbFileTypes';
import { PcbFileName } from '../../components/PcbFileName';

export const StackUpFileSelectionButton = ({ pcb, disabled }: { pcb: PCBV2; disabled: boolean }) => {
    const { setDialog, closeDialog } = useDialogContext();

    return (
        <SecondaryButton
            fullWidth
            size="medium"
            disabled={disabled}
            onClick={() => {
                setDialog(<StackUpFileSelectionDialog pcb={pcb} onClose={closeDialog} />);
            }}
        >
            <Trans>Or select from existing files</Trans>
        </SecondaryButton>
    );
};

const StackUpFileSelectionDialog = ({ pcb, onClose }: { pcb: PCBV2; onClose: () => void }) => {
    const files = pcb.files ?? [];
    const stackupFiles = files.filter((file) => {
        return getFileStateType(file) === StateFileType.StackupFiles;
    });
    const pdfFiles = files.filter((file) => file.name.toLowerCase().endsWith('.pdf'));
    const importantFiles = uniqueBy([...stackupFiles, ...pdfFiles], (file) => file.name);

    const [selectedFiles, setSelectedFiles] = React.useState<string[]>(stackupFiles.map((file) => file.name));
    const [showAllFiles, setShowAllFiles] = React.useState<boolean>(importantFiles.length === 0);

    const filesToDisplay = (showAllFiles === false ? importantFiles : files).sort((a, b) => {
        return importantFiles.includes(a) ? -1 : 1;
    });

    const { mutateAsync: updateFileType, isLoading: isUpdatingFiles } = useMutationUpdatePcbFileTypes({
        pcbId: pcb.id,
    });

    const handleSelectFile = (file: PCBV2File) => () => {
        if (selectedFiles.includes(file.name)) {
            setSelectedFiles((files) => {
                return files.filter((fileName) => fileName !== file.name);
            });
            return;
        }
        setSelectedFiles((files) => {
            return [...files, file.name];
        });
    };

    const handleSubmit = async () => {
        const updates: SingleFileTypeUpdate[] = files.map((file) => {
            return {
                file: file.name,
                function: {
                    ...file.fileType,
                    fileType: selectedFiles.includes(file.name)
                        ? PCBFileTypes.STACK_UP
                        : // if it was once a stackup, then it should be set to the Unknown fileType
                          stackupFiles.find((stackupFile) => stackupFile.name === file.name)
                          ? PCBFileTypes.UNKNOWN
                          : file.fileType.fileType,
                    productionFile: true,
                },
            };
        });
        await updateFileType({ updates });
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle title={t`Select stackup file`} handleClose={onClose} />
            <DialogContent style={{ paddingBottom: 24 }}>
                {filesToDisplay.length > 0 ? (
                    <>
                        {filesToDisplay.map((file) => (
                            <Flexbox
                                key={file.name}
                                gap={'8px'}
                                alignItems={'center'}
                                style={{
                                    padding: '8px',
                                    border: `1px solid ${colorSystem.neutral[1]}`,
                                    borderColor: selectedFiles.includes(file.name)
                                        ? colorSystem.primary[2]
                                        : colorSystem.neutral[1],
                                    cursor: 'pointer',
                                }}
                                onClick={handleSelectFile(file)}
                            >
                                <Checkbox
                                    checked={selectedFiles.includes(file.name)}
                                    value={true}
                                    size="small"
                                    style={{ padding: '4px' }}
                                />
                                <PcbFileName text={file.name} shouldShortenFileName={false} />
                                <Tag
                                    label={transEnum(file.fileType.fileType, pcbFileTypesTranslations)}
                                    color={
                                        PCBFileColors[file.fileType.fileType]
                                            ? PCBFileColors[file.fileType.fileType].color
                                            : 'neutral'
                                    }
                                    style={{
                                        marginInlineStart: 'auto',
                                    }}
                                />
                            </Flexbox>
                        ))}
                        {importantFiles.length > 0 && (
                            <TertiaryButton
                                variant="text"
                                size="small"
                                style={{
                                    marginBlockStart: '16px',
                                }}
                                startIcon={
                                    <ExpandMoreRounded
                                        style={{
                                            transform: showAllFiles ? 'rotate(180deg)' : 'rotate(0deg)',
                                        }}
                                    />
                                }
                                onClick={() => setShowAllFiles((showAllFiles) => !showAllFiles)}
                            >
                                {showAllFiles ? t`Show less` : t`Show all files`}
                            </TertiaryButton>
                        )}
                    </>
                ) : (
                    <CenteredLayout>
                        <NonIdealState
                            title={t`No files`}
                            description={t`To use this selection please upload your files on the file manager page`}
                        />
                    </CenteredLayout>
                )}
            </DialogContent>
            <DialogActions>
                <Flexbox gap={8} justifyContent="flex-end">
                    <SecondaryButton onClick={() => onClose()}>
                        <Trans>Cancel</Trans>
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={handleSubmit}
                        startIcon={isUpdatingFiles ? <CircularProgress size={'16px'} /> : undefined}
                        disabled={isUpdatingFiles || selectedFiles.length === 0}
                    >
                        <Trans>Save</Trans>
                    </PrimaryButton>
                </Flexbox>
            </DialogActions>
        </Dialog>
    );
};
