import { t } from '@lingui/macro';
import { positiveDecimalNumbersRegEx } from '@luminovo/commons';
import * as Yup from 'yup';

export const requiredErrorMessage = () => t`Required`;

export const notesMaxNumberOfCharacters = 10000;
export const guidanceTextMaxNumberOfCharacters = 1000;
export const maxCharacterErrorMessage = (limit: number) => t`Must not be more than ${limit} characters`;

export const mustBeANumberErrorMessage = () => t`Must be a number`;
export const mustBeAPositiveNumberErrorMessage = () => t`Must be a positive number`;

export const mustBePositiveErrorMessage = () => t`Must be greater than 0`;
export const mustBeMinErrorMessage = (min: number) => t`Must be greater than or equal to ${min}`;
export const maxArrayLengthErrorMessage = (limit: number) => t`Limited to ${limit} items`;

// for numeric values represented as strings
export const numberString = () => Yup.string().matches(/^\d+$/, mustBeANumberErrorMessage());

// for positive numeric values represented as strings
const positiveNumber = (isOptional: boolean) =>
    Yup.string()
        .matches(positiveDecimalNumbersRegEx, mustBeAPositiveNumberErrorMessage())
        .test('positive number', mustBeAPositiveNumberErrorMessage(), (t): boolean => {
            if (isOptional) {
                if (t === undefined || t === null || t === '') {
                    return true;
                }
            }
            return typeof t === 'string' && parseFloat(t) > 0;
        });

export const positiveNumberRequired = () => {
    return positiveNumber(false).required(requiredErrorMessage());
};

export const positiveNumberOptional = () => {
    return positiveNumber(true).optional();
};

export const mustBeAWholeNumberErrorMessage = () => t`Must be a whole number`;

const invalidURLErrorMessage = (): string =>
    t`This is not a valid URL. Please make sure it starts with "http:" or "https:"`;

Yup.addMethod(Yup.object, 'optional', function (isOptional = true, defaultValue = undefined) {
    return this.transform(function (value) {
        if (!isOptional) return value;
        if (value && Object.values(value).some((v) => !(v === null || v === undefined || v === ''))) {
            return value;
        }
        return defaultValue;
    }).default(defaultValue);
});

export const isValueAlreadyUsed = (
    value: string,
    existingValues: string[],
    currentValue: string | undefined,
): boolean => {
    if (existingValues.length === 0) {
        return false;
    }
    return existingValues.includes(value.trim()) && value.trim() !== currentValue;
};

export const nullableNumberTransform = (value: string, originalValue: string): string | null =>
    originalValue === '' ? null : value;

function isValidHttpUrl(urlString: string): boolean {
    let url;

    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:'; // required because since RFC 3886 'javascript:void(0)' would also be a valid url
}

export function validateUrl(x: unknown): string | undefined {
    const isPresent = typeof x === 'string' && x.length > 0;
    if (!isPresent) {
        return;
    }
    if (!isValidHttpUrl(x)) {
        return invalidURLErrorMessage();
    }
}

export const MAX_BATCH_SIZES = 25;
