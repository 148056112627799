import * as React from 'react';
import { getConsentedCookies } from './cookieConsent';

/**
 * Forces a re-render when the cookie-consent event is emmited, and re-runs the
 */
export function useOnCookieConset() {
    const [consentedCookies, setConsentedCookies] = React.useState(getConsentedCookies());

    React.useEffect(() => {
        const fn = () => {
            setConsentedCookies(getConsentedCookies());
        };

        document.addEventListener('cookie-consent', fn);
        return () => {
            document.removeEventListener('cookie-consent', fn);
        };
    }, [setConsentedCookies]);

    return consentedCookies;
}
