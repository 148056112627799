import { useUniversalImporter } from '../context';

export function useValidateGlobalFields(): boolean {
    const { state } = useUniversalImporter();

    // Option 1: re-run validation useValidateGlobalFields
    // Option 2: we set the result to undefined whenever validation fails on StringGlobalField

    for (const globalField of state.config.globalFields ?? []) {
        if (globalField.value === undefined) {
            return false;
        }
    }
    return true;
}
