import { t } from '@lingui/macro';
import { Currency, formatLongDateTime, formatRelativeTime, isPresent } from '@luminovo/commons';
import { Flexbox, FormItem, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO, SupplierPortalSettingsDTO } from '@luminovo/http-client';
import { QuoteTrackingStateChip } from '@luminovo/sourcing-core';
import { AutoAwesome } from '@mui/icons-material';
import { CommentsBox } from '../../../../components/CommentsDrawer/CommentsBox';
import { getSupplierPortalMode, isCustomPartQuote } from '../model/utils';
import { FormItemAdditionalFiles } from './FormItemAdditionalFiles';
import { FormItemCurrency } from './FormItemCurrency';
import { FormItemDueDate } from './FormItemDueDate';
import { FormItemLeadTimeUnit } from './FormItemLeadTimeUnit';
import { FormItemNotes } from './FormItemNotes';
import { FormItemOfferNumber } from './FormItemOfferNumber';
import { FormItemOfferNumbers } from './FormItemOfferNumbers';
import { FormItemParts } from './FormItemParts';
import { FormItemValidUntil } from './FormItemValidUntil';
import { PdfQuoteImportButton } from './PdfQuoteImportButton';

export function QuoteDetailsSidebar({
    quoteTracking,
}: {
    quoteTracking: QuoteTrackingDTO;
    preferredCurrency: Currency;
    supplierPortalSettings: SupplierPortalSettingsDTO;
}): JSX.Element {
    return (
        <Flexbox flexDirection={'column'} padding="20px" gap="32px">
            <Flexbox gap="16px" flexDirection={'column'}>
                {getSupplierPortalMode(quoteTracking) === 'edit' && (
                    <>
                        <FormItem
                            sx={{
                                padding: 2,
                                background: colorSystem.primary[1],
                                borderRadius: '4px',
                                border: `1px solid ${colorSystem.primary[3]}`,
                            }}
                            label={
                                <Flexbox alignItems={'center'} gap="4px">
                                    <AutoAwesome style={{ color: colorSystem.yellow[6] }} />
                                    {t`AI-powered PDF upload`}{' '}
                                </Flexbox>
                            }
                        >
                            <Text color={colorSystem.neutral[7]}>
                                Upload your PDF, and our AI will fill in the quotation form for you. Save time and
                                effort!
                            </Text>
                            <PdfQuoteImportButton variant="secondary" quoteTracking={quoteTracking} />
                        </FormItem>
                        <FormItemDueDate quoteTracking={quoteTracking} />
                        <FormItemNotes quoteTracking={quoteTracking} />
                        <FormItemOfferNumber />
                        <FormItemValidUntil />
                        <FormItemCurrency />
                        {!isCustomPartQuote(quoteTracking) && <FormItemLeadTimeUnit />}
                    </>
                )}

                {getSupplierPortalMode(quoteTracking) === 'view' && (
                    <>
                        <FormItem label={t`Status`}>
                            <QuoteTrackingStateChip user="supplier" quoteTracking={quoteTracking} />
                        </FormItem>
                        {isCustomPartQuote(quoteTracking) && <FormItemParts quoteTracking={quoteTracking} />}

                        <FormItemOfferNumbers quoteTracking={quoteTracking} />

                        <FormItem label={t`Submitted on`}>
                            {isPresent(quoteTracking.received_date) ? (
                                <Tooltip title={formatRelativeTime(quoteTracking.received_date)}>
                                    <Text>{formatLongDateTime(quoteTracking.received_date)}</Text>
                                </Tooltip>
                            ) : (
                                <Text>{t`Not submitted yet`}</Text>
                            )}
                        </FormItem>
                    </>
                )}
            </Flexbox>

            <FormItemAdditionalFiles quoteTracking={quoteTracking} />

            <FormItem label={t`Comments`}>
                <CommentsBox
                    rfqId={quoteTracking.rfq}
                    thread={{
                        category: 'Public',
                        commentType: 'QuoteTracking',
                        typeIds: quoteTracking.id,
                    }}
                />
            </FormItem>

            <div style={{ height: 200 }}></div>
        </Flexbox>
    );
}
