import { compareByString, formatLongDateTime, formatRelativeTime, transEnum } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, StatusChip, Text, Tooltip } from '@luminovo/design-system';
import {
    ActorResponseRuntypeDTO,
    ApprovalStatus,
    HistoryRecordBreadCrumb,
    IpnHistoryData,
} from '@luminovo/http-client';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import HighlightOffRounded from '@mui/icons-material/HighlightOffRounded';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, styled } from '@mui/material';

import { t, Trans } from '@lingui/macro';
import { Timeline } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import * as React from 'react';
import { approvalStatusTranslations } from '../../../components/i18n';
import { actorToString } from '../utils';

const StyledTimeLineItem = styled(TimelineItem)({
    '&:before': {
        content: 'none',
    },
});

const StyledTimelineContent = styled(TimelineContent)({
    marginTop: '10px',
});

const StyledTimelineNote = styled('div')({
    borderRadius: '0px 8px 8px 8px',
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: '8px',
    maxWidth: '300px',
    backgroundColor: colorSystem.neutral[0],
    marginTop: '18px',
    marginBottom: '18px',
});

export const StyledTimelineDot = styled(TimelineDot)({
    boxShadow: 'none',
});

export const TimelineNote = ({ text, color = colorSystem.neutral[7] }: { text: string; color?: string }) => {
    return (
        <StyledTimelineNote>
            <Text variant={'body-small'} color={color}>
                {text}
            </Text>
        </StyledTimelineNote>
    );
};

export const TimelineAddIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.blue[2],
            }}
        >
            <AddIcon style={{ width: '20px', height: '20px', color: colorSystem.blue[6] }} />
        </StyledTimelineDot>
    );
};

export const TimelineDeleteIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.red[2],
            }}
        >
            <DeleteIcon style={{ width: '20px', height: '20px', color: colorSystem.red[6] }} />
        </StyledTimelineDot>
    );
};

const TimelineApprovedIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.green[2],
            }}
        >
            <CheckIcon style={{ width: '20px', height: '20px', color: colorSystem.green[6] }} />
        </StyledTimelineDot>
    );
};

const TimelinePendingIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.neutral[2],
            }}
        >
            <HelpOutlineRoundedIcon style={{ width: '20px', height: '20px', color: colorSystem.neutral[7] }} />
        </StyledTimelineDot>
    );
};

const TimelineRejectedIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.red[2],
            }}
        >
            <HighlightOffRounded style={{ width: '20px', height: '20px', color: colorSystem.red[7] }} />
        </StyledTimelineDot>
    );
};

export const ApprovalStatusIcon = ({ approvalStatus }: { approvalStatus: ApprovalStatus }) => {
    const approvalStatusIconMapping: Record<ApprovalStatus, JSX.Element> = {
        [ApprovalStatus.Approved]: <TimelineApprovedIcon />,
        [ApprovalStatus.Pending]: <TimelinePendingIcon />,
        [ApprovalStatus.Rejected]: <TimelineRejectedIcon />,
    };
    return approvalStatusIconMapping[approvalStatus];
};

export const TimelineEditIcon = () => {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.neutral[2],
            }}
        >
            <EditIcon style={{ width: '20px', height: '20px', color: colorSystem.neutral[7] }} />
        </StyledTimelineDot>
    );
};

const approvalStatusColorMapping: Record<ApprovalStatus, keyof typeof colorSystem> = {
    [ApprovalStatus.Approved]: 'green',
    [ApprovalStatus.Rejected]: 'red',
    [ApprovalStatus.Pending]: 'neutral',
};
export const TimelineStatusChip = ({ approvalStatus }: { approvalStatus: ApprovalStatus }) => {
    return (
        <>
            <Text>status changed to</Text>{' '}
            <StatusChip
                label={transEnum(approvalStatus, approvalStatusTranslations)}
                color={approvalStatusColorMapping[approvalStatus]}
            />
        </>
    );
};

const TimelineDate = ({ date }: { date: string }) => {
    return (
        <Tooltip title={formatLongDateTime(date)}>
            <Text variant={'body-small'}>{formatRelativeTime(date)}</Text>
        </Tooltip>
    );
};

export const TimelineContentContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'center'}>
            {children}
        </Flexbox>
    );
};

const StyledContentWithBreadCrumbContainer = styled('div')({
    marginTop: '-10px',
});

export const TimelineItemWrapper = ({
    timelineIcon,
    children,
    isLastItem,
    breadCrumb = null,
    createdAt = null,
}: {
    timelineIcon: JSX.Element;
    children: JSX.Element | null;
    isLastItem: boolean;
    breadCrumb?: HistoryRecordBreadCrumb | null;
    createdAt?: string | null;
}) => {
    if (children == null) return null;
    return (
        <StyledTimeLineItem>
            <TimelineSeparator>
                {timelineIcon}
                {!isLastItem && <TimelineConnector style={{ width: '1px', backgroundColor: colorSystem.neutral[2] }} />}
            </TimelineSeparator>
            <StyledTimelineContent>
                {breadCrumb ? (
                    <StyledContentWithBreadCrumbContainer>
                        <TimelineContentContainer>
                            <BreadCrumb breadCrumb={breadCrumb} />
                            {createdAt && <TimelineDate date={createdAt} />}
                        </TimelineContentContainer>
                        {children}
                    </StyledContentWithBreadCrumbContainer>
                ) : (
                    <TimelineContentContainer>
                        {children}
                        {createdAt && <TimelineDate date={createdAt} />}
                    </TimelineContentContainer>
                )}
            </StyledTimelineContent>
        </StyledTimeLineItem>
    );
};

function BreadCrumb({ breadCrumb }: { breadCrumb: HistoryRecordBreadCrumb }) {
    const parentAssemblies = breadCrumb.parent_assemblies.map((parent) => {
        if (parent.parent === null) {
            return t`Design`;
        }
        return parent.name;
    });
    return (
        <StyledBreadCrumbText>
            {parentAssemblies.map((parent, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <StyledBreadCrumbText>{' / '}</StyledBreadCrumbText>}
                    <span>{parent}</span>
                </React.Fragment>
            ))}
            {breadCrumb.relation.type === 'DesignItems' && (
                <ListDesignators designItemData={breadCrumb.relation.data} />
            )}
        </StyledBreadCrumbText>
    );
}

function ListDesignators({ designItemData }: { designItemData: { id: string; designator: string }[] }) {
    const designators = designItemData.map((data) => data.designator);
    const { topDesignators, remainingDesignatorsCount } = sortAndReturnDesignators(designators, 8);

    return (
        <React.Fragment>
            <StyledBreadCrumbText>{' / '}</StyledBreadCrumbText>
            <StyledBreadCrumbText>
                {topDesignators.map((data) => data).join(', ')}
                {remainingDesignatorsCount ? `, +${remainingDesignatorsCount}` : null}
            </StyledBreadCrumbText>
        </React.Fragment>
    );
}

function StyledBreadCrumbText({ children }: { children: React.ReactNode }) {
    return (
        <Text variant={'body-small'} color={colorSystem.neutral['7']}>
            {children}
        </Text>
    );
}

const approvalWordPhrase: Record<ApprovalStatus, JSX.Element> = {
    [ApprovalStatus.Approved]: <Trans>approved</Trans>,
    [ApprovalStatus.Pending]: <Trans>set to pending</Trans>,
    [ApprovalStatus.Rejected]: <Trans>rejected</Trans>,
};
export const AutomaticApprovalStatusPhrase = ({ approvalStatus }: { approvalStatus: ApprovalStatus }) => {
    return (
        <>
            {approvalWordPhrase[approvalStatus]} <Trans>automatically</Trans>
        </>
    );
};

export const ActionPerformedBy = ({ actor }: { actor: ActorResponseRuntypeDTO }) => {
    return (
        <>
            <Trans>by</Trans> <strong>{actorToString(actor)}</strong>
        </>
    );
};

export const TimelineText = styled(Text)({
    color: colorSystem.neutral[9],
});

function sortAndReturnDesignators(designators: string[], designatorsToShow: number = 5) {
    const sortedDesignators = designators.sort(compareByString);
    const topDesignators = sortedDesignators.slice(0, designatorsToShow);
    const remainingDesignatorsCount = sortedDesignators.length - topDesignators.length;
    return {
        topDesignators,
        remainingDesignatorsCount,
    };
}

export function DesignatorsList({ designators }: { designators: string[] }) {
    const { topDesignators, remainingDesignatorsCount } = sortAndReturnDesignators(designators, 5);
    const additionalDesignators = remainingDesignatorsCount ? `+${remainingDesignatorsCount}` : undefined;

    return (
        <Tooltip title={additionalDesignators ?? ''} placement={'top'}>
            <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                {topDesignators.map((designator, idx) => (
                    <React.Fragment key={idx}>
                        <Chip key={idx} label={designator} color={'neutral'} />{' '}
                    </React.Fragment>
                ))}
                {additionalDesignators && <b>{additionalDesignators}</b>}
            </span>
        </Tooltip>
    );
}

export function IPNContent({ data, children }: React.PropsWithChildren<{ data: IpnHistoryData }>) {
    const revisionElementIfPresent = data.revision ? (
        <>
            <Trans>with revision</Trans> <strong>{data.revision}</strong>
        </>
    ) : null;

    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>
                    <strong>IPN {data.value} </strong>
                </Trans>
                {revisionElementIfPresent} {children}
            </TimelineText>
        </TimelineContentContainer>
    );
}

export const HistoryOfChangesContainer = styled(Box)({
    padding: '0px 24px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '840px',
});

export const StyledTimeLine = styled(Timeline)({
    padding: 0,
    marginTop: '30px',
    width: '100%',
});
