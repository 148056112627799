import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { BomExportTemplateInputRuntype, BomExportTemplateRuntype } from './bomExportTemplateBackendTypes';

export const bomExportTemplateEndpoints = {
    'GET /export/bom/templates': endpoint({
        description: 'List all BOM export templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(BomExportTemplateRuntype),
        }),
    }),
    'POST /export/bom/templates': endpoint({
        description: 'Create a BOM export template',
        pathParams: r.Undefined,
        invalidates: ['GET /export/bom/templates'],
        queryParams: r.Undefined,
        requestBody: BomExportTemplateInputRuntype,
        responseBody: BomExportTemplateRuntype,
    }),
    'DELETE /export/bom/templates/:id': endpoint({
        description: 'Delete a BOM export template',
        pathParams: r.Record({
            id: r.String,
        }),
        invalidates: ['GET /export/bom/templates'],
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
    'PUT /export/bom/templates/:id': endpoint({
        description: 'Update a BOM export template',
        pathParams: r.Record({
            id: r.String,
        }),
        invalidates: ['GET /export/bom/templates'],
        queryParams: r.Undefined,
        requestBody: BomExportTemplateInputRuntype,
        responseBody: BomExportTemplateRuntype,
    }),
};
