import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, PrimaryButton, SecondaryButton, TertiaryButton, Text } from '@luminovo/design-system';
import { BomItemIssue, DesignItemOriginTypes } from '@luminovo/http-client';
import { InfoRounded } from '@mui/icons-material';
import { styled } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { BomItemTableData } from '../../Bom/components/ModuleTableData';
import { EditBomItemHeaderState } from './BomItemFormHeader/reducer';
import { BomItemFormState } from './BomItemFormState';

export const FormItemHeader = ({
    bomItem,
    state,
    submitForm,
    setIsHeaderEditable,
}: {
    bomItem: BomItemTableData;
    state: EditBomItemHeaderState;
    submitForm: () => void;
    setIsHeaderEditable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { setValue } = useFormContext<BomItemFormState>();
    const { quantityValue, quantityUnit, designators } = state;

    const isDesignatorPresent = state.designators.length !== 0;
    const isDesignatorNotExtracted =
        !isDesignatorPresent &&
        bomItem.quantity &&
        bomItem.individualDesignItems.some((c): boolean => c.origin.type === DesignItemOriginTypes.ExcelFile) &&
        bomItem.issues.includes(BomItemIssue.DesignatorAndQuantityUnclear);

    const isQuantityPresent = state.quantityValue && state.quantityUnit;
    const isQuantityNotExtracted =
        !bomItem.quantity &&
        bomItem.individualDesignItems.some((c): boolean => c.origin.type === DesignItemOriginTypes.ExcelFile) &&
        bomItem.issues.includes(BomItemIssue.DesignatorAndQuantityUnclear);

    const onConfirm = useCallback(() => {
        if (!quantityUnit || !quantityValue || !(designators.length > 0)) {
            return;
        }
        setValue(
            'quantity',
            { quantity: quantityValue, unit: quantityUnit },
            {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            },
        );
        setValue(
            'designator',
            designators.map((d: string) => d.trim()),
            {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            },
        );
        submitForm();
        setIsHeaderEditable(false);
    }, [setIsHeaderEditable, setValue, designators, quantityUnit, quantityValue, submitForm]);

    const isValid =
        !state.errors.designators.isError && !state.errors.quantityUnit.isError && !state.errors.quantityValue.isError;

    if (isDesignatorNotExtracted) {
        return (
            <ErrorContainer>
                <InfoRounded style={{ color: colorSystem.red[7], fontSize: '16px' }} />
                <Text variant="body-small" color={colorSystem.neutral[8]}>
                    <Trans>
                        <b>Designator and quantity do not match.</b> Therefore we did not extract any designators.
                        Double check the BOM and add the missing designator(s) in the first highlighted field.
                    </Trans>
                </Text>
            </ErrorContainer>
        );
    }
    // Case where quantity is not extracted - we automatically set qty to 1 and unit to pieces and ask the user to confirm.
    if (isQuantityNotExtracted) {
        return (
            <ErrorContainer>
                <InfoRounded style={{ color: colorSystem.red[7], fontSize: '16px' }} />
                <Text variant="body-small" color={colorSystem.neutral[8]}>
                    <Trans>
                        <b>Designator and quantity do not match.</b> We have automatically set the number of units per
                        designator to 1 and the unit of measurement as Pieces. Please double check the BOM and confirm
                        the changes.
                    </Trans>
                </Text>
                <TertiaryButton
                    size="small"
                    disabled={!isDesignatorPresent || !isQuantityPresent}
                    style={{ padding: 0, height: 'unset' }}
                    onClick={onConfirm}
                >
                    <Trans>Confirm</Trans>
                </TertiaryButton>
            </ErrorContainer>
        );
    }
    return (
        <>
            <Text variant="h3">
                <Trans>Edit BOM item</Trans>
            </Text>
            <Flexbox gap="8px">
                <SecondaryButton size="small" onClick={() => setIsHeaderEditable(false)}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <PrimaryButton size="small" disabled={!isValid} onClick={onConfirm}>
                    <Trans>Save</Trans>
                </PrimaryButton>
            </Flexbox>
        </>
    );
};

const ErrorContainer = styled(Flexbox)({
    boxSizing: 'border-box',
    backgroundColor: colorSystem.red[1],
    border: `1px solid ${colorSystem.red[3]}`,
    borderRadius: '8px',
    padding: '8px 16px',
    alignItems: 'flex-start',
    gap: '8px',
});
