import { Trans } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import { AssemblyResponseDTO } from '@luminovo/http-client';
import React from 'react';
import { AssemblyMenuButton } from '../../../components/AssemblyMenuButton';
import { ProgressAwareTabs } from '../../../components/ProgressAwareTabs';
import { route } from '../../../utils/routes';
import { MonitoringButton } from '../../Bom/components/AssemblyMonitoring/MonitoringButton';

import { BreadcrumbItem } from '@luminovo/design-system';
import { ImportedAssemblyProgressTab } from '../../../components/ProgressAwareTabs/ProgressAwareTabs';
import { AssemblyTabsType } from '../../../resources/assembly/assemblyFrontendTypes';
import { ViewContext } from '../../Bom/components/ModuleTableData';

type Props = {
    assembly: AssemblyResponseDTO;
    tab?: AssemblyTabsType;
    additionalBreadcrumbsFromRoot: BreadcrumbItem[];
    isMonitoringOpen?: string | null;
};

const assemblyRootRouteBreadcrumb = {
    href: route('/assemblies'),
    title: <Trans>Assemblies</Trans>,
};

export default function AssemblyOverviewToolbar({
    tab,
    assembly,
    isMonitoringOpen,
    additionalBreadcrumbsFromRoot,
}: Props) {
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: assembly.rfq ?? undefined,
        };
    }, [assembly.rfq]);

    const center = React.useMemo(() => {
        if (assembly.rfq) {
            return (
                <ProgressAwareTabs
                    tab={tab}
                    assembly={assembly}
                    rfqId={assembly.rfq}
                    viewContext={viewContext}
                    currentParentAssemblyId={null}
                />
            );
        }

        return <ImportedAssemblyProgressTab assemblyId={assembly.id} />;
    }, [assembly, viewContext, tab]);

    return (
        <Toolbar breadcrumbs={[assemblyRootRouteBreadcrumb, ...additionalBreadcrumbsFromRoot]} center={center}>
            <MonitoringButton assemblyId={assembly.id} viewContext={viewContext} isMonitoringOpen={isMonitoringOpen} />
            <AssemblyMenuButton assemblyId={assembly.id} viewContext={viewContext} />
        </Toolbar>
    );
}
