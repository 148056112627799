import { format } from 'date-fns';

export const getDefaultDateFormat = (): DateFormat => {
    // Get user's locale
    const userLocale = navigator.language.toUpperCase();

    // Check if locale starts with common country codes
    if (userLocale.toLowerCase().startsWith('de')) {
        return dateFormats.find((format) => format.country === 'DE')!;
    }
    if (userLocale.toLowerCase().startsWith('en-us')) {
        return dateFormats.find((format) => format.country === 'US')!;
    }
    if (userLocale.toLowerCase().startsWith('en-gb') || userLocale.toLowerCase().startsWith('en-uk')) {
        return dateFormats.find((format) => format.country === 'UK')!;
    }

    // Default to ISO format if locale not matched
    return dateFormats[0]; // YYYY-MM-DD
};

export type DateFormat = {
    country: string;
    format: string;
    example: string;
};

export const formats = [
    { format: 'yyyy-MM-dd', country: 'DE' }, // International but common in Germany
    { format: 'dd/MM/yyyy', country: 'UK' },
    { format: 'dd.MM.yyyy', country: 'DE' },
    { format: 'MM/dd/yyyy', country: 'US' },
    { format: 'yyyy/MM/dd', country: 'DE' },
    { format: 'dd-MM-yyyy', country: 'UK' },
    { format: 'dd.MM.yy', country: 'DE' },
    { format: 'MM/dd/yy', country: 'US' },
    { format: 'yyyy.MM.dd', country: 'DE' },
    { format: 'dd-MM-yy', country: 'UK' },
];

export const dateFormats: DateFormat[] = formats.map(({ country, format: formatStr }) => ({
    country,
    format: formatStr,
    example: format(new Date('2026-05-31'), formatStr),
}));
