import { RfqStatus, UserType, WorkflowType } from '@luminovo/http-client';
import { isRfqEditable } from '@luminovo/manufacturing-core';
import { useUserType } from '../components/contexts/CurrentUserDetailsContext';
import { useHasAccessToCustomerPortal } from './featureFlags';

export const useIsRfqEditable = (
    status: RfqStatus | undefined,
    isArchived: boolean | undefined,
    orderWorkflow: WorkflowType | undefined,
): { isRfqEditable: boolean } => {
    const isCustomer = useUserType() === UserType.Customer;
    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();

    return {
        isRfqEditable: isRfqEditable({
            status,
            isCustomer: isCustomer,
            isArchived,
            orderWorkflow,
            hasAccessToCustomerPortal,
        }),
    };
};
