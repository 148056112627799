import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { MouserCredentialsInputDTORuntype, MouserResponseBodyDTORuntype } from './mouserBackendTypes';

export const mouserEndpoints = {
    'GET /3rdparty/mouser/credentials': endpoint({
        description: 'Returns credentials for mouser integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: MouserResponseBodyDTORuntype,
    }),
    'POST /3rdparty/mouser/credentials': endpoint({
        description: 'Uploads credentials for mouser API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MouserCredentialsInputDTORuntype,
        responseBody: MouserResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
    'PATCH /3rdparty/mouser/credentials': endpoint({
        description: 'Updates credentials for mouser API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MouserCredentialsInputDTORuntype,
        responseBody: MouserResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
    'DELETE /3rdparty/mouser/credentials': endpoint({
        description: 'Deletes existing credentials for mouser API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
};
