import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { PanelPreferencePostRuntype, PanelPreferenceRuntype } from './pcbPanelPreferenceBackendTypes';

export const pcbPanelPreferenceEndpoints = {
    'GET /pcb-panel-preferences': endpoint({
        description: 'Gets all PCB panel preferences',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(PanelPreferenceRuntype),
    }),

    'POST /pcb-panel-preferences': endpoint({
        description: 'Creates a new PCB panel preference',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: PanelPreferencePostRuntype,
        responseBody: PanelPreferenceRuntype,
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'PATCH /pcb-panel-preferences/:panelPreferenceId': endpoint({
        description: 'Updates a PCB panel preference',
        pathParams: r.Record({
            panelPreferenceId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: PanelPreferencePostRuntype,
        responseBody: PanelPreferenceRuntype,
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'DELETE /pcb-panel-preferences/:preferenceId': endpoint({
        description: 'Deletes a PCB panel preference',
        pathParams: r.Record({
            preferenceId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'GET /organization-settings/default-pcb-panel-preferences': endpoint({
        description: 'Gets the default PCB panel preference for an organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: PanelPreferenceRuntype.nullable(),
    }),

    'POST /organization-settings/default-pcb-panel-preferences': endpoint({
        description: 'Sets the default PCB panel preference for an organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            id: r.String.nullable(),
        }),
        responseBody: PanelPreferenceRuntype.nullable(),
        invalidates: ['GET /organization-settings/default-pcb-panel-preferences'],
    }),
};
