import { compareByString } from '@luminovo/commons';
import { Flexbox, colorSystem } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { Control, Controller } from 'react-hook-form';
import { LeftColumn } from './templateSelection/leftColumn';
import { RightColumn } from './templateSelection/rightColumn/RightColumn';
import { AddManufacturingScenarioFormState } from './types';

export const TemplateSelectionControlled = ({
    control,
    manufacturingScenarioTemplates,
}: {
    control: Control<AddManufacturingScenarioFormState>;
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
}): JSX.Element => {
    return (
        <Controller
            control={control}
            name="manufacturingScenarioTemplateIds"
            render={({ field: { onChange, value } }) => (
                <TemplateSelection
                    onChange={onChange}
                    selected={value}
                    manufacturingScenarioTemplates={manufacturingScenarioTemplates}
                />
            )}
        />
    );
};

const COLUMN_MAX_HEIGHT = '70vh';

const TemplateSelection = ({
    onChange,
    selected,
    manufacturingScenarioTemplates,
}: {
    onChange: (e: string[]) => void;
    selected: string[];
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
}): JSX.Element => {
    return (
        <Flexbox flexDirection="row" height="100%">
            <Flexbox
                width="400px"
                flexDirection="column"
                paddingRight={'16px'}
                gap={'16px'}
                maxHeight={COLUMN_MAX_HEIGHT}
                minHeight={COLUMN_MAX_HEIGHT}
                style={{ overflowY: 'auto' }}
            >
                <LeftColumn
                    selected={selected}
                    onChange={onChange}
                    manufacturingScenarioTemplates={manufacturingScenarioTemplates.sort((a, b) =>
                        compareByString(a.name, b.name),
                    )}
                />
            </Flexbox>
            <Flexbox
                style={{
                    flexGrow: 1,
                    background: colorSystem.neutral[1],
                    borderRadius: '8px',
                    maxHeight: COLUMN_MAX_HEIGHT,
                    minHeight: COLUMN_MAX_HEIGHT,
                    overflowY: 'auto',
                }}
                justifyContent="center"
            >
                <RightColumn
                    selected={selected}
                    onChange={onChange}
                    manufacturingScenarioTemplates={manufacturingScenarioTemplates}
                />
            </Flexbox>
        </Flexbox>
    );
};
