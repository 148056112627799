import { MessageDescriptor } from '@lingui/core';
import { defineMessage, t } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import { RegionsEnum, SupplierAndStockLocationDTO, SupplierDTO } from '@luminovo/http-client';

const regionTranslations: Record<RegionsEnum, MessageDescriptor> = {
    [RegionsEnum.Unknown]: defineMessage({ message: 'Unknown' }),
    [RegionsEnum.World]: defineMessage({ message: 'World' }),
    [RegionsEnum.Africa]: defineMessage({ message: 'Africa' }),
    [RegionsEnum.NorthernAfrica]: defineMessage({ message: 'Northern Africa' }),
    [RegionsEnum.SubSaharanAfrica]: defineMessage({ message: 'Sub-Saharan Africa' }),
    [RegionsEnum.WesternAfrica]: defineMessage({ message: 'Western Africa' }),
    [RegionsEnum.EasternAfrica]: defineMessage({ message: 'Eastern Africa' }),
    [RegionsEnum.MiddleAfrica]: defineMessage({ message: 'Middle Africa' }),
    [RegionsEnum.SouthernAfrica]: defineMessage({ message: 'Southern Africa' }),
    [RegionsEnum.Americas]: defineMessage({ message: 'Americas' }),
    [RegionsEnum.NorthernAmerica]: defineMessage({ message: 'Northern America' }),
    [RegionsEnum.LatinAmericaAndTheCaribbean]: defineMessage({ message: 'Latin America and the Caribbean' }),
    [RegionsEnum.SouthAmerica]: defineMessage({ message: 'South America' }),
    [RegionsEnum.CentralAmerica]: defineMessage({ message: 'Central America' }),
    [RegionsEnum.Caribbean]: defineMessage({ message: 'Caribbean' }),
    [RegionsEnum.Asia]: defineMessage({ message: 'Asia' }),
    [RegionsEnum.EasternAsia]: defineMessage({ message: 'Eastern Asia' }),
    [RegionsEnum.SouthernAsia]: defineMessage({ message: 'Southern Asia' }),
    [RegionsEnum.SouthEasternAsia]: defineMessage({ message: 'South-eastern Asia' }),
    [RegionsEnum.CentralAsia]: defineMessage({ message: 'Central Asia' }),
    [RegionsEnum.WesternAsia]: defineMessage({ message: 'Western Asia' }),
    [RegionsEnum.Europe]: defineMessage({ message: 'Europe' }),
    [RegionsEnum.SouthernEurope]: defineMessage({ message: 'Southern Europe' }),
    [RegionsEnum.EasternEurope]: defineMessage({ message: 'Eastern Europe' }),
    [RegionsEnum.NorthernEurope]: defineMessage({ message: 'Northern Europe' }),
    [RegionsEnum.ChannelIslands]: defineMessage({ message: 'Channel Islands' }),
    [RegionsEnum.WesternEurope]: defineMessage({ message: 'Western Europe' }),
    [RegionsEnum.Oceania]: defineMessage({ message: 'Oceania' }),
    [RegionsEnum.AustraliaAndNewZealand]: defineMessage({ message: 'Australia and New Zealand' }),
    [RegionsEnum.Melanesia]: defineMessage({ message: 'Melanesia' }),
    [RegionsEnum.Micronesia]: defineMessage({ message: 'Micronesia' }),
    [RegionsEnum.Polynesia]: defineMessage({ message: 'Polynesia' }),
    [RegionsEnum.Algeria]: defineMessage({ message: 'Algeria' }),
    [RegionsEnum.Egypt]: defineMessage({ message: 'Egypt' }),
    [RegionsEnum.Libya]: defineMessage({ message: 'Libya' }),
    [RegionsEnum.Morocco]: defineMessage({ message: 'Morocco' }),
    [RegionsEnum.Sudan]: defineMessage({ message: 'Sudan' }),
    [RegionsEnum.Tunisia]: defineMessage({ message: 'Tunisia' }),
    [RegionsEnum.WesternSahara]: defineMessage({ message: 'Western Sahara' }),
    [RegionsEnum.BritishIndianOceanTerritory]: defineMessage({ message: 'British Indian Ocean Territory' }),
    [RegionsEnum.Burundi]: defineMessage({ message: 'Burundi' }),
    [RegionsEnum.Comoros]: defineMessage({ message: 'Comoros' }),
    [RegionsEnum.Djibouti]: defineMessage({ message: 'Djibouti' }),
    [RegionsEnum.Eritrea]: defineMessage({ message: 'Eritrea' }),
    [RegionsEnum.Ethiopia]: defineMessage({ message: 'Ethiopia' }),
    [RegionsEnum.FrenchSouthernTerritories]: defineMessage({ message: 'French Southern Territories' }),
    [RegionsEnum.Kenya]: defineMessage({ message: 'Kenya' }),
    [RegionsEnum.Madagascar]: defineMessage({ message: 'Madagascar' }),
    [RegionsEnum.Malawi]: defineMessage({ message: 'Malawi' }),
    [RegionsEnum.Mauritius]: defineMessage({ message: 'Mauritius' }),
    [RegionsEnum.Mayotte]: defineMessage({ message: 'Mayotte' }),
    [RegionsEnum.Mozambique]: defineMessage({ message: 'Mozambique' }),
    [RegionsEnum.Reunion]: defineMessage({ message: 'Réunion' }),
    [RegionsEnum.Rwanda]: defineMessage({ message: 'Rwanda' }),
    [RegionsEnum.Seychelles]: defineMessage({ message: 'Seychelles' }),
    [RegionsEnum.Somalia]: defineMessage({ message: 'Somalia' }),
    [RegionsEnum.SouthSudan]: defineMessage({ message: 'South Sudan' }),
    [RegionsEnum.Uganda]: defineMessage({ message: 'Uganda' }),
    [RegionsEnum.UnitedRepublicOfTanzania]: defineMessage({ message: 'United Republic of Tanzania' }),
    [RegionsEnum.Zambia]: defineMessage({ message: 'Zambia' }),
    [RegionsEnum.Zimbabwe]: defineMessage({ message: 'Zimbabwe' }),
    [RegionsEnum.Angola]: defineMessage({ message: 'Angola' }),
    [RegionsEnum.Cameroon]: defineMessage({ message: 'Cameroon' }),
    [RegionsEnum.CentralAfricanRepublic]: defineMessage({ message: 'Central African Republic' }),
    [RegionsEnum.Chad]: defineMessage({ message: 'Chad' }),
    [RegionsEnum.Congo]: defineMessage({ message: 'Congo' }),
    [RegionsEnum.DemocraticRepublicOfTheCongo]: defineMessage({ message: 'Democratic Republic of the Congo' }),
    [RegionsEnum.EquatorialGuinea]: defineMessage({ message: 'Equatorial Guinea' }),
    [RegionsEnum.Gabon]: defineMessage({ message: 'Gabon' }),
    [RegionsEnum.SaoTomeAndPrincipe]: defineMessage({ message: 'Sao Tome and Principe' }),
    [RegionsEnum.Botswana]: defineMessage({ message: 'Botswana' }),
    [RegionsEnum.Eswatini]: defineMessage({ message: 'Eswatini' }),
    [RegionsEnum.Lesotho]: defineMessage({ message: 'Lesotho' }),
    [RegionsEnum.Namibia]: defineMessage({ message: 'Namibia' }),
    [RegionsEnum.SouthAfrica]: defineMessage({ message: 'South Africa' }),
    [RegionsEnum.Benin]: defineMessage({ message: 'Benin' }),
    [RegionsEnum.BurkinaFaso]: defineMessage({ message: 'Burkina Faso' }),
    [RegionsEnum.CaboVerde]: defineMessage({ message: 'Cabo Verde' }),
    [RegionsEnum.CoteDIvore]: defineMessage({ message: "Côte d'Ivoire" }),
    [RegionsEnum.Gambia]: defineMessage({ message: 'Gambia' }),
    [RegionsEnum.Ghana]: defineMessage({ message: 'Ghana' }),
    [RegionsEnum.Guinea]: defineMessage({ message: 'Guinea' }),
    [RegionsEnum.GuineaBissau]: defineMessage({ message: 'Guinea-Bissau' }),
    [RegionsEnum.Liberia]: defineMessage({ message: 'Liberia' }),
    [RegionsEnum.Mali]: defineMessage({ message: 'Mali' }),
    [RegionsEnum.Mauritania]: defineMessage({ message: 'Mauritania' }),
    [RegionsEnum.Niger]: defineMessage({ message: 'Niger' }),
    [RegionsEnum.Nigeria]: defineMessage({ message: 'Nigeria' }),
    [RegionsEnum.SaintHelena]: defineMessage({ message: 'Saint Helena' }),
    [RegionsEnum.Senegal]: defineMessage({ message: 'Senegal' }),
    [RegionsEnum.SierraLeone]: defineMessage({ message: 'Sierra Leone' }),
    [RegionsEnum.Togo]: defineMessage({ message: 'Togo' }),
    [RegionsEnum.Anguilla]: defineMessage({ message: 'Anguilla' }),
    [RegionsEnum.AntiguaAndBarbuda]: defineMessage({ message: 'Antigua and Barbuda' }),
    [RegionsEnum.Aruba]: defineMessage({ message: 'Aruba' }),
    [RegionsEnum.Bahamas]: defineMessage({ message: 'Bahamas' }),
    [RegionsEnum.Barbados]: defineMessage({ message: 'Barbados' }),
    [RegionsEnum.BonaireAndSintEustatiusAndSaba]: defineMessage({ message: 'Bonaire, Sint Eustatius and Saba' }),
    [RegionsEnum.BritishVirginIslands]: defineMessage({ message: 'British Virgin Islands' }),
    [RegionsEnum.CaymanIslands]: defineMessage({ message: 'Cayman Islands' }),
    [RegionsEnum.Cuba]: defineMessage({ message: 'Cuba' }),
    [RegionsEnum.Curacao]: defineMessage({ message: 'Curaçao' }),
    [RegionsEnum.Dominica]: defineMessage({ message: 'Dominica' }),
    [RegionsEnum.DominicanRepublic]: defineMessage({ message: 'Dominican Republic' }),
    [RegionsEnum.Grenada]: defineMessage({ message: 'Grenada' }),
    [RegionsEnum.Guadeloupe]: defineMessage({ message: 'Guadeloupe' }),
    [RegionsEnum.Haiti]: defineMessage({ message: 'Haiti' }),
    [RegionsEnum.Jamaica]: defineMessage({ message: 'Jamaica' }),
    [RegionsEnum.Martinique]: defineMessage({ message: 'Martinique' }),
    [RegionsEnum.Montserrat]: defineMessage({ message: 'Montserrat' }),
    [RegionsEnum.PuertoRico]: defineMessage({ message: 'Puerto Rico' }),
    [RegionsEnum.SaintBarthelemy]: defineMessage({ message: 'Saint Barthélemy' }),
    [RegionsEnum.SaintKittsAndNevis]: defineMessage({ message: 'Saint Kitts and Nevis' }),
    [RegionsEnum.SaintLucia]: defineMessage({ message: 'Saint Lucia' }),
    [RegionsEnum.SaintMartin]: defineMessage({ message: 'Saint Martin (French Part)' }),
    [RegionsEnum.SaintVincentAndTheGrenadines]: defineMessage({ message: 'Saint Vincent and the Grenadines' }),
    [RegionsEnum.SintMaarten]: defineMessage({ message: 'Sint Maarten (Dutch part)' }),
    [RegionsEnum.TrinidadAndTobago]: defineMessage({ message: 'Trinidad and Tobago' }),
    [RegionsEnum.TurksAndCaicosIslands]: defineMessage({ message: 'Turks and Caicos Islands' }),
    [RegionsEnum.UnitedStatesVirginIslands]: defineMessage({ message: 'United States Virgin Islands' }),
    [RegionsEnum.Belize]: defineMessage({ message: 'Belize' }),
    [RegionsEnum.CostaRica]: defineMessage({ message: 'Costa Rica' }),
    [RegionsEnum.ElSalvador]: defineMessage({ message: 'El Salvador' }),
    [RegionsEnum.Guatemala]: defineMessage({ message: 'Guatemala' }),
    [RegionsEnum.Honduras]: defineMessage({ message: 'Honduras' }),
    [RegionsEnum.Mexico]: defineMessage({ message: 'Mexico' }),
    [RegionsEnum.Nicaragua]: defineMessage({ message: 'Nicaragua' }),
    [RegionsEnum.Panama]: defineMessage({ message: 'Panama' }),
    [RegionsEnum.Argentina]: defineMessage({ message: 'Argentina' }),
    [RegionsEnum.Bolivia]: defineMessage({ message: 'Bolivia (Plurinational State of)' }),
    [RegionsEnum.BouvetIsland]: defineMessage({ message: 'Bouvet Island' }),
    [RegionsEnum.Brazil]: defineMessage({ message: 'Brazil' }),
    [RegionsEnum.Chile]: defineMessage({ message: 'Chile' }),
    [RegionsEnum.Colombia]: defineMessage({ message: 'Colombia' }),
    [RegionsEnum.Ecuador]: defineMessage({ message: 'Ecuador' }),
    [RegionsEnum.FalklandIslands]: defineMessage({ message: 'Falkland Islands (Malvinas)' }),
    [RegionsEnum.FrenchGuiana]: defineMessage({ message: 'French Guiana' }),
    [RegionsEnum.Guyana]: defineMessage({ message: 'Guyana' }),
    [RegionsEnum.Paraguay]: defineMessage({ message: 'Paraguay' }),
    [RegionsEnum.Peru]: defineMessage({ message: 'Peru' }),
    [RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands]: defineMessage({
        message: 'South Georgia and the South Sandwich Islands',
    }),
    [RegionsEnum.Suriname]: defineMessage({ message: 'Suriname' }),
    [RegionsEnum.Uruguay]: defineMessage({ message: 'Uruguay' }),
    [RegionsEnum.Venezuela]: defineMessage({ message: 'Venezuela (Bolivarian Republic of)' }),
    [RegionsEnum.Bermuda]: defineMessage({ message: 'Bermuda' }),
    [RegionsEnum.Canada]: defineMessage({ message: 'Canada' }),
    [RegionsEnum.Greenland]: defineMessage({ message: 'Greenland' }),
    [RegionsEnum.SaintPierreAndMiquelon]: defineMessage({ message: 'Saint Pierre and Miquelon' }),
    [RegionsEnum.UnitedStatesOfAmerica]: defineMessage({ message: 'United States of America' }),
    [RegionsEnum.Antarctica]: defineMessage({ message: 'Antarctica' }),
    [RegionsEnum.Kazakhstan]: defineMessage({ message: 'Kazakhstan' }),
    [RegionsEnum.Kyrgyzstan]: defineMessage({ message: 'Kyrgyzstan' }),
    [RegionsEnum.Tajikistan]: defineMessage({ message: 'Tajikistan' }),
    [RegionsEnum.Turkmenistan]: defineMessage({ message: 'Turkmenistan' }),
    [RegionsEnum.Uzbekistan]: defineMessage({ message: 'Uzbekistan' }),
    [RegionsEnum.China]: defineMessage({ message: 'China' }),
    [RegionsEnum.Taiwan]: defineMessage({ message: 'Taiwan' }),
    [RegionsEnum.HongKong]: defineMessage({ message: 'China, Hong Kong Special Administrative Region' }),
    [RegionsEnum.Macao]: defineMessage({ message: 'China, Macao Special Administrative Region' }),
    [RegionsEnum.DemocraticPeoplesRepublicOfKorea]: defineMessage({ message: "Democratic People's Republic of Korea" }),
    [RegionsEnum.Japan]: defineMessage({ message: 'Japan' }),
    [RegionsEnum.Mongolia]: defineMessage({ message: 'Mongolia' }),
    [RegionsEnum.RepublicOfKorea]: defineMessage({ message: 'Republic of Korea' }),
    [RegionsEnum.BruneiDarussalam]: defineMessage({ message: 'Brunei Darussalam' }),
    [RegionsEnum.Cambodia]: defineMessage({ message: 'Cambodia' }),
    [RegionsEnum.Indonesia]: defineMessage({ message: 'Indonesia' }),
    [RegionsEnum.LaoPeoplesDemocraticRepublic]: defineMessage({ message: "Lao People's Democratic Republic" }),
    [RegionsEnum.Malaysia]: defineMessage({ message: 'Malaysia' }),
    [RegionsEnum.Myanmar]: defineMessage({ message: 'Myanmar' }),
    [RegionsEnum.Philippines]: defineMessage({ message: 'Philippines' }),
    [RegionsEnum.Singapore]: defineMessage({ message: 'Singapore' }),
    [RegionsEnum.Thailand]: defineMessage({ message: 'Thailand' }),
    [RegionsEnum.TimorLeste]: defineMessage({ message: 'Timor-Leste' }),
    [RegionsEnum.VietNam]: defineMessage({ message: 'Viet Nam' }),
    [RegionsEnum.Afghanistan]: defineMessage({ message: 'Afghanistan' }),
    [RegionsEnum.Bangladesh]: defineMessage({ message: 'Bangladesh' }),
    [RegionsEnum.Bhutan]: defineMessage({ message: 'Bhutan' }),
    [RegionsEnum.India]: defineMessage({ message: 'India' }),
    [RegionsEnum.Iran]: defineMessage({ message: 'Iran (Islamic Republic of)' }),
    [RegionsEnum.Maldives]: defineMessage({ message: 'Maldives' }),
    [RegionsEnum.Nepal]: defineMessage({ message: 'Nepal' }),
    [RegionsEnum.Pakistan]: defineMessage({ message: 'Pakistan' }),
    [RegionsEnum.SriLanka]: defineMessage({ message: 'Sri Lanka' }),
    [RegionsEnum.Armenia]: defineMessage({ message: 'Armenia' }),
    [RegionsEnum.Azerbaijan]: defineMessage({ message: 'Azerbaijan' }),
    [RegionsEnum.Bahrain]: defineMessage({ message: 'Bahrain' }),
    [RegionsEnum.Cyprus]: defineMessage({ message: 'Cyprus' }),
    [RegionsEnum.Georgia]: defineMessage({ message: 'Georgia' }),
    [RegionsEnum.Iraq]: defineMessage({ message: 'Iraq' }),
    [RegionsEnum.Israel]: defineMessage({ message: 'Israel' }),
    [RegionsEnum.Jordan]: defineMessage({ message: 'Jordan' }),
    [RegionsEnum.Kuwait]: defineMessage({ message: 'Kuwait' }),
    [RegionsEnum.Lebanon]: defineMessage({ message: 'Lebanon' }),
    [RegionsEnum.Oman]: defineMessage({ message: 'Oman' }),
    [RegionsEnum.Qatar]: defineMessage({ message: 'Qatar' }),
    [RegionsEnum.SaudiArabia]: defineMessage({ message: 'Saudi Arabia' }),
    [RegionsEnum.StateOfPalestine]: defineMessage({ message: 'State of Palestine' }),
    [RegionsEnum.SyrianArabRepublic]: defineMessage({ message: 'Syrian Arab Republic' }),
    [RegionsEnum.Turkey]: defineMessage({ message: 'Turkey' }),
    [RegionsEnum.UnitedArabEmirates]: defineMessage({ message: 'United Arab Emirates' }),
    [RegionsEnum.Yemen]: defineMessage({ message: 'Yemen' }),
    [RegionsEnum.Belarus]: defineMessage({ message: 'Belarus' }),
    [RegionsEnum.Bulgaria]: defineMessage({ message: 'Bulgaria' }),
    [RegionsEnum.Czechia]: defineMessage({ message: 'Czechia' }),
    [RegionsEnum.Hungary]: defineMessage({ message: 'Hungary' }),
    [RegionsEnum.Poland]: defineMessage({ message: 'Poland' }),
    [RegionsEnum.RepublicOfMoldova]: defineMessage({ message: 'Republic of Moldova' }),
    [RegionsEnum.Romania]: defineMessage({ message: 'Romania' }),
    [RegionsEnum.RussianFederation]: defineMessage({ message: 'Russian Federation' }),
    [RegionsEnum.Slovakia]: defineMessage({ message: 'Slovakia' }),
    [RegionsEnum.Ukraine]: defineMessage({ message: 'Ukraine' }),
    [RegionsEnum.AlandIslands]: defineMessage({ message: 'Åland Islands' }),
    [RegionsEnum.Guernsey]: defineMessage({ message: 'Guernsey' }),
    [RegionsEnum.Jersey]: defineMessage({ message: 'Jersey' }),
    [RegionsEnum.Sark]: defineMessage({ message: 'Sark' }),
    [RegionsEnum.Denmark]: defineMessage({ message: 'Denmark' }),
    [RegionsEnum.Estonia]: defineMessage({ message: 'Estonia' }),
    [RegionsEnum.FaroeIslands]: defineMessage({ message: 'Faroe Islands' }),
    [RegionsEnum.Finland]: defineMessage({ message: 'Finland' }),
    [RegionsEnum.Iceland]: defineMessage({ message: 'Iceland' }),
    [RegionsEnum.Ireland]: defineMessage({ message: 'Ireland' }),
    [RegionsEnum.IsleOfMan]: defineMessage({ message: 'Isle of Man' }),
    [RegionsEnum.Latvia]: defineMessage({ message: 'Latvia' }),
    [RegionsEnum.Lithuania]: defineMessage({ message: 'Lithuania' }),
    [RegionsEnum.Norway]: defineMessage({ message: 'Norway' }),
    [RegionsEnum.SvalbardAndJanMayenIslands]: defineMessage({ message: 'Svalbard and Jan Mayen Islands' }),
    [RegionsEnum.Sweden]: defineMessage({ message: 'Sweden' }),
    [RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland]: defineMessage({
        message: 'United Kingdom of Great Britain and Northern Ireland',
    }),
    [RegionsEnum.Albania]: defineMessage({ message: 'Albania' }),
    [RegionsEnum.Andorra]: defineMessage({ message: 'Andorra' }),
    [RegionsEnum.BosniaAndHerzegovina]: defineMessage({ message: 'Bosnia and Herzegovina' }),
    [RegionsEnum.Croatia]: defineMessage({ message: 'Croatia' }),
    [RegionsEnum.Gibraltar]: defineMessage({ message: 'Gibraltar' }),
    [RegionsEnum.Greece]: defineMessage({ message: 'Greece' }),
    [RegionsEnum.HolySee]: defineMessage({ message: 'Holy See' }),
    [RegionsEnum.Italy]: defineMessage({ message: 'Italy' }),
    [RegionsEnum.Malta]: defineMessage({ message: 'Malta' }),
    [RegionsEnum.Montenegro]: defineMessage({ message: 'Montenegro' }),
    [RegionsEnum.NorthMacedonia]: defineMessage({ message: 'North Macedonia' }),
    [RegionsEnum.Portugal]: defineMessage({ message: 'Portugal' }),
    [RegionsEnum.SanMarino]: defineMessage({ message: 'San Marino' }),
    [RegionsEnum.Serbia]: defineMessage({ message: 'Serbia' }),
    [RegionsEnum.Slovenia]: defineMessage({ message: 'Slovenia' }),
    [RegionsEnum.Spain]: defineMessage({ message: 'Spain' }),
    [RegionsEnum.Austria]: defineMessage({ message: 'Austria' }),
    [RegionsEnum.Belgium]: defineMessage({ message: 'Belgium' }),
    [RegionsEnum.France]: defineMessage({ message: 'France' }),
    [RegionsEnum.Germany]: defineMessage({ message: 'Germany' }),
    [RegionsEnum.Liechtenstein]: defineMessage({ message: 'Liechtenstein' }),
    [RegionsEnum.Luxembourg]: defineMessage({ message: 'Luxembourg' }),
    [RegionsEnum.Monaco]: defineMessage({ message: 'Monaco' }),
    [RegionsEnum.Netherlands]: defineMessage({ message: 'Netherlands' }),
    [RegionsEnum.Switzerland]: defineMessage({ message: 'Switzerland' }),
    [RegionsEnum.Australia]: defineMessage({ message: 'Australia' }),
    [RegionsEnum.ChristmasIsland]: defineMessage({ message: 'Christmas Island' }),
    [RegionsEnum.CocosKeelingIslands]: defineMessage({ message: 'Cocos (Keeling) Islands' }),
    [RegionsEnum.HeardIslandAndMcDonaldIslands]: defineMessage({ message: 'Heard Island and McDonald Islands' }),
    [RegionsEnum.NewZealand]: defineMessage({ message: 'New Zealand' }),
    [RegionsEnum.NorfolkIsland]: defineMessage({ message: 'Norfolk Island' }),
    [RegionsEnum.Fiji]: defineMessage({ message: 'Fiji' }),
    [RegionsEnum.NewCaledonia]: defineMessage({ message: 'New Caledonia' }),
    [RegionsEnum.PapuaNewGuinea]: defineMessage({ message: 'Papua New Guinea' }),
    [RegionsEnum.SolomonIslands]: defineMessage({ message: 'Solomon Islands' }),
    [RegionsEnum.Vanuatu]: defineMessage({ message: 'Vanuatu' }),
    [RegionsEnum.Guam]: defineMessage({ message: 'Guam' }),
    [RegionsEnum.Kiribati]: defineMessage({ message: 'Kiribati' }),
    [RegionsEnum.MarshallIslands]: defineMessage({ message: 'Marshall Islands' }),
    [RegionsEnum.FederatedStatesOfMicronesia]: defineMessage({ message: 'Micronesia (Federated States of)' }),
    [RegionsEnum.Nauru]: defineMessage({ message: 'Nauru' }),
    [RegionsEnum.NorthernMarianaIslands]: defineMessage({ message: 'Northern Mariana Islands' }),
    [RegionsEnum.Palau]: defineMessage({ message: 'Palau' }),
    [RegionsEnum.UnitedStatesMinorOutlyingIslands]: defineMessage({ message: 'United States Minor Outlying Islands' }),
    [RegionsEnum.AmericanSamoa]: defineMessage({ message: 'American Samoa' }),
    [RegionsEnum.CookIslands]: defineMessage({ message: 'Cook Islands' }),
    [RegionsEnum.FrenchPolynesia]: defineMessage({ message: 'French Polynesia' }),
    [RegionsEnum.Niue]: defineMessage({ message: 'Niue' }),
    [RegionsEnum.Pitcairn]: defineMessage({ message: 'Pitcairn' }),
    [RegionsEnum.Samoa]: defineMessage({ message: 'Samoa' }),
    [RegionsEnum.Tokelau]: defineMessage({ message: 'Tokelau' }),
    [RegionsEnum.Tonga]: defineMessage({ message: 'Tonga' }),
    [RegionsEnum.Tuvalu]: defineMessage({ message: 'Tuvalu' }),
    [RegionsEnum.WallisAndFutunaIslands]: defineMessage({ message: 'Wallis and Futuna Islands' }),
};

const regionToISO3166Alpha3: Record<RegionsEnum, string | null> = {
    [RegionsEnum.Afghanistan]: 'AFG',
    [RegionsEnum.Africa]: 'AFR',
    [RegionsEnum.AlandIslands]: 'ALA',
    [RegionsEnum.Albania]: 'ALB',
    [RegionsEnum.Algeria]: 'DZA',
    [RegionsEnum.AmericanSamoa]: 'ASM',
    [RegionsEnum.Americas]: 'AME',
    [RegionsEnum.Andorra]: 'AND',
    [RegionsEnum.Angola]: 'AGO',
    [RegionsEnum.Anguilla]: 'AIA',
    [RegionsEnum.Antarctica]: 'ATA',
    [RegionsEnum.AntiguaAndBarbuda]: 'ATG',
    [RegionsEnum.Argentina]: 'ARG',
    [RegionsEnum.Armenia]: 'ARM',
    [RegionsEnum.Aruba]: 'ABW',
    [RegionsEnum.Asia]: 'ASI',
    [RegionsEnum.Australia]: 'AUS',
    [RegionsEnum.AustraliaAndNewZealand]: null,
    [RegionsEnum.Austria]: 'AUT',
    [RegionsEnum.Azerbaijan]: 'AZE',
    [RegionsEnum.Bahamas]: 'BHS',
    [RegionsEnum.Bahrain]: 'BHR',
    [RegionsEnum.Bangladesh]: 'BGD',
    [RegionsEnum.Barbados]: 'BRB',
    [RegionsEnum.Belarus]: 'BLR',
    [RegionsEnum.Belgium]: 'BEL',
    [RegionsEnum.Belize]: 'BLZ',
    [RegionsEnum.Benin]: 'BEN',
    [RegionsEnum.Bermuda]: 'BMU',
    [RegionsEnum.Bhutan]: 'BTN',
    [RegionsEnum.Bolivia]: 'BOL',
    [RegionsEnum.BonaireAndSintEustatiusAndSaba]: 'BES',
    [RegionsEnum.BosniaAndHerzegovina]: 'BIH',
    [RegionsEnum.Botswana]: 'BWA',
    [RegionsEnum.BouvetIsland]: 'BVT',
    [RegionsEnum.Brazil]: 'BRA',
    [RegionsEnum.BritishIndianOceanTerritory]: 'IOT',
    [RegionsEnum.BritishVirginIslands]: 'VGB',
    [RegionsEnum.BruneiDarussalam]: 'BRN',
    [RegionsEnum.Bulgaria]: 'BGR',
    [RegionsEnum.BurkinaFaso]: 'BFA',
    [RegionsEnum.Burundi]: 'BDI',
    [RegionsEnum.CaboVerde]: 'CPV',
    [RegionsEnum.Cambodia]: 'KHM',
    [RegionsEnum.Cameroon]: 'CMR',
    [RegionsEnum.Canada]: 'CAN',
    [RegionsEnum.Caribbean]: null,
    [RegionsEnum.CaymanIslands]: 'CYM',
    [RegionsEnum.CentralAfricanRepublic]: 'CAF',
    [RegionsEnum.CentralAmerica]: null,
    [RegionsEnum.CentralAsia]: null,
    [RegionsEnum.Chad]: 'TCD',
    [RegionsEnum.ChannelIslands]: null,
    [RegionsEnum.Chile]: 'CHL',
    [RegionsEnum.China]: 'CHN',
    [RegionsEnum.ChristmasIsland]: 'CXR',
    [RegionsEnum.CocosKeelingIslands]: 'CCK',
    [RegionsEnum.Colombia]: 'COL',
    [RegionsEnum.Comoros]: 'COM',
    [RegionsEnum.Congo]: 'COG',
    [RegionsEnum.CookIslands]: 'COK',
    [RegionsEnum.CostaRica]: 'CRI',
    [RegionsEnum.CoteDIvore]: 'CIV',
    [RegionsEnum.Croatia]: 'HRV',
    [RegionsEnum.Cuba]: 'CUB',
    [RegionsEnum.Curacao]: 'CUW',
    [RegionsEnum.Cyprus]: 'CYP',
    [RegionsEnum.Czechia]: 'CZE',
    [RegionsEnum.DemocraticPeoplesRepublicOfKorea]: 'PRK',
    [RegionsEnum.DemocraticRepublicOfTheCongo]: 'COD',
    [RegionsEnum.Denmark]: 'DNK',
    [RegionsEnum.Djibouti]: 'DJI',
    [RegionsEnum.Dominica]: 'DMA',
    [RegionsEnum.DominicanRepublic]: 'DOM',
    [RegionsEnum.EasternAfrica]: null,
    [RegionsEnum.EasternAsia]: null,
    [RegionsEnum.EasternEurope]: null,
    [RegionsEnum.Ecuador]: 'ECU',
    [RegionsEnum.Egypt]: 'EGY',
    [RegionsEnum.ElSalvador]: 'SLV',
    [RegionsEnum.EquatorialGuinea]: 'GNQ',
    [RegionsEnum.Eritrea]: 'ERI',
    [RegionsEnum.Estonia]: 'EST',
    [RegionsEnum.Eswatini]: 'SWZ',
    [RegionsEnum.Ethiopia]: 'ETH',
    [RegionsEnum.Europe]: 'EUR',
    [RegionsEnum.FalklandIslands]: 'FLK',
    [RegionsEnum.FaroeIslands]: 'FRO',
    [RegionsEnum.FederatedStatesOfMicronesia]: 'FSM',
    [RegionsEnum.Fiji]: 'FJI',
    [RegionsEnum.Finland]: 'FIN',
    [RegionsEnum.France]: 'FRA',
    [RegionsEnum.FrenchGuiana]: 'GUF',
    [RegionsEnum.FrenchPolynesia]: 'PYF',
    [RegionsEnum.FrenchSouthernTerritories]: 'ATF',
    [RegionsEnum.Gabon]: 'GAB',
    [RegionsEnum.Gambia]: 'GMB',
    [RegionsEnum.Georgia]: 'GEO',
    [RegionsEnum.Germany]: 'DEU',
    [RegionsEnum.Ghana]: 'GHA',
    [RegionsEnum.Gibraltar]: 'GIB',
    [RegionsEnum.Greece]: 'GRC',
    [RegionsEnum.Greenland]: 'GRL',
    [RegionsEnum.Grenada]: 'GRD',
    [RegionsEnum.Guadeloupe]: 'GLP',
    [RegionsEnum.Guam]: 'GUM',
    [RegionsEnum.Guatemala]: 'GTM',
    [RegionsEnum.Guernsey]: 'GGY',
    [RegionsEnum.Guinea]: 'GIN',
    [RegionsEnum.GuineaBissau]: 'GNB',
    [RegionsEnum.Guyana]: 'GUY',
    [RegionsEnum.Haiti]: 'HTI',
    [RegionsEnum.HeardIslandAndMcDonaldIslands]: 'HMD',
    [RegionsEnum.HolySee]: 'VAT',
    [RegionsEnum.Honduras]: 'HND',
    [RegionsEnum.HongKong]: 'HKG',
    [RegionsEnum.Hungary]: 'HUN',
    [RegionsEnum.Iceland]: 'ISL',
    [RegionsEnum.India]: 'IND',
    [RegionsEnum.Indonesia]: 'IDN',
    [RegionsEnum.Iran]: 'IRN',
    [RegionsEnum.Iraq]: 'IRQ',
    [RegionsEnum.Ireland]: 'IRL',
    [RegionsEnum.IsleOfMan]: 'IMN',
    [RegionsEnum.Israel]: 'ISR',
    [RegionsEnum.Italy]: 'ITA',
    [RegionsEnum.Jamaica]: 'JAM',
    [RegionsEnum.Japan]: 'JPN',
    [RegionsEnum.Jersey]: 'JEY',
    [RegionsEnum.Jordan]: 'JOR',
    [RegionsEnum.Kazakhstan]: 'KAZ',
    [RegionsEnum.Kenya]: 'KEN',
    [RegionsEnum.Kiribati]: 'KIR',
    [RegionsEnum.Kuwait]: 'KWT',
    [RegionsEnum.Kyrgyzstan]: 'KGZ',
    [RegionsEnum.LaoPeoplesDemocraticRepublic]: 'LAO',
    [RegionsEnum.LatinAmericaAndTheCaribbean]: null,
    [RegionsEnum.Latvia]: 'LVA',
    [RegionsEnum.Lebanon]: 'LBN',
    [RegionsEnum.Lesotho]: 'LSO',
    [RegionsEnum.Liberia]: 'LBR',
    [RegionsEnum.Libya]: 'LBY',
    [RegionsEnum.Liechtenstein]: 'LIE',
    [RegionsEnum.Lithuania]: 'LTU',
    [RegionsEnum.Luxembourg]: 'LUX',
    [RegionsEnum.Macao]: 'MAC',
    [RegionsEnum.Madagascar]: 'MDG',
    [RegionsEnum.Malawi]: 'MWI',
    [RegionsEnum.Malaysia]: 'MYS',
    [RegionsEnum.Maldives]: 'MDV',
    [RegionsEnum.Mali]: 'MLI',
    [RegionsEnum.Malta]: 'MLT',
    [RegionsEnum.MarshallIslands]: 'MHL',
    [RegionsEnum.Martinique]: 'MTQ',
    [RegionsEnum.Mauritania]: 'MRT',
    [RegionsEnum.Mauritius]: 'MUS',
    [RegionsEnum.Mayotte]: 'MYT',
    [RegionsEnum.Melanesia]: null,
    [RegionsEnum.Mexico]: 'MEX',
    [RegionsEnum.Micronesia]: null,
    [RegionsEnum.MiddleAfrica]: null,
    [RegionsEnum.Monaco]: 'MCO',
    [RegionsEnum.Mongolia]: 'MNG',
    [RegionsEnum.Montenegro]: 'MNE',
    [RegionsEnum.Montserrat]: 'MSR',
    [RegionsEnum.Morocco]: 'MAR',
    [RegionsEnum.Mozambique]: 'MOZ',
    [RegionsEnum.Myanmar]: 'MMR',
    [RegionsEnum.Namibia]: 'NAM',
    [RegionsEnum.Nauru]: 'NRU',
    [RegionsEnum.Nepal]: 'NPL',
    [RegionsEnum.Netherlands]: 'NLD',
    [RegionsEnum.NewCaledonia]: 'NCL',
    [RegionsEnum.NewZealand]: 'NZL',
    [RegionsEnum.Nicaragua]: 'NIC',
    [RegionsEnum.Niger]: 'NER',
    [RegionsEnum.Nigeria]: 'NGA',
    [RegionsEnum.Niue]: 'NIU',
    [RegionsEnum.NorfolkIsland]: 'NFK',
    [RegionsEnum.NorthernAfrica]: null,
    [RegionsEnum.NorthernAmerica]: null,
    [RegionsEnum.NorthernEurope]: null,
    [RegionsEnum.NorthernMarianaIslands]: 'MNP',
    [RegionsEnum.NorthMacedonia]: 'MKD',
    [RegionsEnum.Norway]: 'NOR',
    [RegionsEnum.Oceania]: 'OCE',
    [RegionsEnum.Oman]: 'OMN',
    [RegionsEnum.Pakistan]: 'PAK',
    [RegionsEnum.Palau]: 'PLW',
    [RegionsEnum.Panama]: 'PAN',
    [RegionsEnum.PapuaNewGuinea]: 'PNG',
    [RegionsEnum.Paraguay]: 'PRY',
    [RegionsEnum.Peru]: 'PER',
    [RegionsEnum.Philippines]: 'PHL',
    [RegionsEnum.Pitcairn]: 'PCN',
    [RegionsEnum.Poland]: 'POL',
    [RegionsEnum.Polynesia]: null,
    [RegionsEnum.Portugal]: 'PRT',
    [RegionsEnum.PuertoRico]: 'PRI',
    [RegionsEnum.Qatar]: 'QAT',
    [RegionsEnum.RepublicOfKorea]: 'KOR',
    [RegionsEnum.RepublicOfMoldova]: 'MDA',
    [RegionsEnum.Reunion]: 'REU',
    [RegionsEnum.Romania]: 'ROU',
    [RegionsEnum.RussianFederation]: 'RUS',
    [RegionsEnum.Rwanda]: 'RWA',
    [RegionsEnum.SaintBarthelemy]: 'BLM',
    [RegionsEnum.SaintHelena]: 'SHN',
    [RegionsEnum.SaintKittsAndNevis]: 'KNA',
    [RegionsEnum.SaintLucia]: 'LCA',
    [RegionsEnum.SaintMartin]: 'MAF',
    [RegionsEnum.SaintPierreAndMiquelon]: 'SPM',
    [RegionsEnum.SaintVincentAndTheGrenadines]: 'VCT',
    [RegionsEnum.Samoa]: 'WSM',
    [RegionsEnum.SanMarino]: 'SMR',
    [RegionsEnum.SaoTomeAndPrincipe]: 'STP',
    [RegionsEnum.Sark]: null,
    [RegionsEnum.SaudiArabia]: 'SAU',
    [RegionsEnum.Senegal]: 'SEN',
    [RegionsEnum.Serbia]: 'SRB',
    [RegionsEnum.Seychelles]: 'SYC',
    [RegionsEnum.SierraLeone]: 'SLE',
    [RegionsEnum.Singapore]: 'SGP',
    [RegionsEnum.SintMaarten]: 'SXM',
    [RegionsEnum.Slovakia]: 'SVK',
    [RegionsEnum.Slovenia]: 'SVN',
    [RegionsEnum.SolomonIslands]: 'SLB',
    [RegionsEnum.Somalia]: 'SOM',
    [RegionsEnum.SouthAfrica]: 'ZAF',
    [RegionsEnum.SouthAmerica]: null,
    [RegionsEnum.SouthEasternAsia]: null,
    [RegionsEnum.SouthernAfrica]: null,
    [RegionsEnum.SouthernAsia]: null,
    [RegionsEnum.SouthernEurope]: null,
    [RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands]: 'SGS',
    [RegionsEnum.SouthSudan]: 'SSD',
    [RegionsEnum.Spain]: 'ESP',
    [RegionsEnum.SriLanka]: 'LKA',
    [RegionsEnum.StateOfPalestine]: 'PSE',
    [RegionsEnum.SubSaharanAfrica]: null,
    [RegionsEnum.Sudan]: 'SDN',
    [RegionsEnum.Suriname]: 'SUR',
    [RegionsEnum.SvalbardAndJanMayenIslands]: 'SJM',
    [RegionsEnum.Sweden]: 'SWE',
    [RegionsEnum.Switzerland]: 'CHE',
    [RegionsEnum.SyrianArabRepublic]: 'SYR',
    [RegionsEnum.Taiwan]: 'TWN',
    [RegionsEnum.Tajikistan]: 'TJK',
    [RegionsEnum.Thailand]: 'THA',
    [RegionsEnum.TimorLeste]: 'TLS',
    [RegionsEnum.Togo]: 'TGO',
    [RegionsEnum.Tokelau]: 'TKL',
    [RegionsEnum.Tonga]: 'TON',
    [RegionsEnum.TrinidadAndTobago]: 'TTO',
    [RegionsEnum.Tunisia]: 'TUN',
    [RegionsEnum.Turkey]: 'TUR',
    [RegionsEnum.Turkmenistan]: 'TKM',
    [RegionsEnum.TurksAndCaicosIslands]: 'TCA',
    [RegionsEnum.Tuvalu]: 'TUV',
    [RegionsEnum.Uganda]: 'UGA',
    [RegionsEnum.Ukraine]: 'UKR',
    [RegionsEnum.UnitedArabEmirates]: 'ARE',
    [RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland]: 'GBR',
    [RegionsEnum.UnitedRepublicOfTanzania]: 'TZA',
    [RegionsEnum.UnitedStatesMinorOutlyingIslands]: 'UMI',
    [RegionsEnum.UnitedStatesOfAmerica]: 'USA',
    [RegionsEnum.UnitedStatesVirginIslands]: 'VIR',
    [RegionsEnum.Unknown]: null,
    [RegionsEnum.Uruguay]: 'URY',
    [RegionsEnum.Uzbekistan]: 'UZB',
    [RegionsEnum.Vanuatu]: 'VUT',
    [RegionsEnum.Venezuela]: 'VEN',
    [RegionsEnum.VietNam]: 'VNM',
    [RegionsEnum.WallisAndFutunaIslands]: 'WLF',
    [RegionsEnum.WesternAfrica]: null,
    [RegionsEnum.WesternAsia]: null,
    [RegionsEnum.WesternEurope]: null,
    [RegionsEnum.WesternSahara]: 'ESH',
    [RegionsEnum.World]: 'WLD',
    [RegionsEnum.Yemen]: 'YEM',
    [RegionsEnum.Zambia]: 'ZMB',
    [RegionsEnum.Zimbabwe]: 'ZWE',
};

export function formatRegionAsName(region: RegionsEnum): string {
    return transEnum(region, regionTranslations);
}

// Return 3 letter code for the region if one exists
export function formatRegionEnumAsISO3166Alpha3(region: RegionsEnum): string | null {
    return regionToISO3166Alpha3[region];
}

export function formatSupplier(supplierName: string, supplierNumber: string | null | undefined): string {
    if (isPresent(supplierNumber)) {
        return `${supplierName} (${supplierNumber})`;
    }

    return supplierName;
}

export function formatSupplierDTO(supplier?: SupplierDTO | null | undefined): string {
    if (!supplier) {
        return t`Unknown`;
    }
    return supplier.name;
}

export function formatSupplierAndStockLocationDTO(sasl?: SupplierAndStockLocationDTO): string {
    if (!sasl) {
        return t`Unknown`;
    }
    const { supplier, stock_location } = sasl;

    if (stock_location === RegionsEnum.Unknown) {
        return supplier.name;
    }

    // First try the short 3 letter code but if not available go for full name
    const stockLocationText = formatRegionEnumAsISO3166Alpha3(stock_location) || formatRegionAsName(stock_location);
    return `${supplier.name} · ${stockLocationText}`;
}
