import { isUuid } from '../utils';

export const HOST_NAME = window.location.host;

/*******************************************************************************************
 * IMPORTANT: Keep this in sync with the same function in `@luminovo/http`
 * Any changes made here should be reflected there as well.
 *******************************************************************************************/
export const isProductionEnvironment = (): boolean => {
    if (process.env.REACT_APP_PRETEND_TO_BE_IN_PRODUCTION === 'true') {
        return true;
    }
    if (HOST_NAME.endsWith('.dev.luminovo.ai')) {
        return false;
    }
    if (process.env.NODE_ENV === 'production') {
        return true;
    }
    if (process.env.NODE_ENV === 'development') {
        return false;
    }
    if (HOST_NAME.includes('chromatic.com')) {
        return false;
    }
    if (HOST_NAME.includes('localhost')) {
        return false;
    }

    // Default to true unless we know for any of the reasons above
    // that it's definitely not a production environment.
    return true;
};

const trailEnvironments = [
    'mint.luminovo',
    'ruby.luminovo',
    'peach.luminovo',
    'coral.luminovo',
    'bronze.luminovo',
    'olive.luminovo',
    'navy.luminovo',
    'gray.luminovo',
    'lime.luminovo',
    'silver.luminovo',
    'crystal.luminovo',
    'gold.luminovo',
    'green.luminovo',
    'white.luminovo',
    'pink.luminovo',
    'yellow.luminovo',
    'red.luminovo',
    'purple.luminovo',
    'orange.luminovo',
    'blue.luminovo',
];

/**
 * Trails are environments that are not production but are not development either.
 *
 * see https://www.notion.so/luminovo/61f4d1d942b144368b2988e3311acad5?v=1f4913b257d24f21bbeaaa95dd528afb
 */
export const isTrailEnvironment = (): boolean => {
    return trailEnvironments.some((host) => HOST_NAME.startsWith(host));
};

/**
 * Generates a fingerprint based on the current URL. This should be used to group errors in Sentry.
 *
 * Please do not use this function for any other purpose than grouping errors in Sentry.
 */
export const generateFingerprintBasedOnUrl = (): string => {
    return window.location.pathname
        .split('/')
        .map((part) => (isUuid(part) ? ':' : part))
        .join('/');
};
