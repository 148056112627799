import { t } from '@lingui/macro';
import { Tag, Tooltip } from '@luminovo/design-system';
import { CandidateCpn, PartSuggestionFull, PartSuggestionReason, formatColumnName } from '@luminovo/http-client';
import { getFormattedReasons } from '../../utils/partOptionPartSuggestionReasons';

export const PartSuggestionOriginView = ({ suggestion }: { suggestion: PartSuggestionFull }): JSX.Element => {
    if (suggestion.origin) {
        const candidate = 'candidate' in suggestion.origin ? suggestion.origin.candidate : undefined;
        const detailedReasons = getFormattedSuggestionReasons(suggestion.origin.reasons, candidate);
        const originColumn = formatColumnName(suggestion.origin.column);
        return (
            <Tooltip
                title={
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {detailedReasons ? detailedReasons : t`Suggestion based on ${originColumn} column`}
                    </div>
                }
                placement="top"
            >
                <span>
                    <Tag label={originColumn} color="neutral" attention="low" />
                </span>
            </Tooltip>
        );
    }
    return <>-</>;
};

function getFormattedSuggestionReasons(
    reasons: PartSuggestionReason[],
    candidate: String | CandidateCpn | undefined | null,
) {
    const formattedReasons = getFormattedReasons(reasons, candidate);
    if (formattedReasons.length === 0) {
        return undefined;
    }
    return t`Suggestion explanation: ${formattedReasons}`;
}
