import { Trans } from '@lingui/macro';
import { ButtonGroup, ButtonGroupItem, Flexbox } from '@luminovo/design-system';
import { AssemblyIndustry, RfqContext } from '@luminovo/http-client';
import { useMemo, useState } from 'react';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { id } from '../../../../utils/ids';
import { DesignItemParametricSearchErpData } from '../ErpDataSearch/DesignItemParametricSearchErpData';
import { OtsPartSearch } from '../OtsPartSearch/OTSPartSearch';
import { OtsPartSpecificationType } from '../SpecificationTypeForms/types';

type PartSearchType = 'MPN' | 'ERP';
interface PartSearchProps {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    otsPartSpecificationContext: OtsPartSpecificationType;
    onRemovePart: (partId: string) => void;
    existingPartIds: string[] | undefined;
    assemblyIndustry?: AssemblyIndustry;
}

export const PartSearch = ({
    rfqId,
    bomItem,
    assemblyId,
    otsPartSpecificationContext,
    existingPartIds,
    onRemovePart: removePart,
    assemblyIndustry,
}: PartSearchProps): JSX.Element => {
    const [selectedSearchField, setSelectedSearchField] = useState<PartSearchType>('MPN');

    const rfqContext: RfqContext = useMemo(() => ({ type: 'WithinRfQ', rfq_id: rfqId }), [rfqId]);

    return (
        <>
            <Flexbox gap={8}>
                <ButtonGroup size="large" style={{}}>
                    <ButtonGroupItem
                        selected={selectedSearchField === 'MPN'}
                        onClick={() => setSelectedSearchField('MPN')}
                        id={id('design/button_add_part_via_mpn')}
                    >
                        <Trans>Parts</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem
                        selected={selectedSearchField === 'ERP'}
                        onClick={() => setSelectedSearchField('ERP')}
                        id={id('design/button_add_part_via_ipn')}
                    >
                        <Trans>ERP data</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
            </Flexbox>
            {selectedSearchField === 'MPN' && (
                <OtsPartSearch
                    assemblyId={assemblyId}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    onAddPart={otsPartSpecificationContext.handleAddPart}
                    onRemovePart={removePart}
                    rfqContext={rfqContext}
                    existingPartIds={existingPartIds}
                    assemblyIndustry={assemblyIndustry}
                />
            )}
            {selectedSearchField === 'ERP' && (
                <DesignItemParametricSearchErpData
                    rfqContext={rfqContext}
                    onRemovePart={removePart}
                    existingPartIds={existingPartIds}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    assemblyId={assemblyId}
                    assemblyIndustry={assemblyIndustry}
                    displayOtsFilters={true}
                    partSpecificationContext={otsPartSpecificationContext}
                />
            )}
        </>
    );
};
