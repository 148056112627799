import { t, Trans } from '@lingui/macro';
import {
    CenteredLayout,
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    Flexbox,
    PrimaryButton,
    ReleaseTag,
    Text,
} from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useDialogContext } from '../../components/contexts/ModalContext';
import { TableDemands } from '../Negotiations/components/TableDemands';
import { useDemands } from '../Negotiations/hooks/negotiationHandlers';

const DemandFilterDialog = ({
    closeDialog,
    submit,
}: {
    closeDialog: () => void;
    submit: (demandIds: string[]) => void;
}) => {
    const { data: demands = [], isLoading } = useDemands();
    const [selectedDemandIds, setSelectedDemandIds] = useState<string[]>([]);

    return (
        <Dialog
            open
            onClose={closeDialog}
            title={
                <Flexbox gap={4} alignItems="center">
                    <Trans>Filter demand</Trans> <ReleaseTag color="violet" label={t`Alpha`} />
                </Flexbox>
            }
            maxWidth="xl"
        >
            {isLoading && (
                <CenteredLayout height="25vh">
                    <CircularProgress size={40} color="primary" />
                </CenteredLayout>
            )}
            {!isLoading && (
                <>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                        <Text color={colorSystem.neutral[8]}>
                            <Trans>
                                Fine-tune which demand you would like to be displayed. Please note that these filters
                                will only be applied to your account.
                            </Trans>
                        </Text>

                        <Flexbox flexDirection="column" gap={4}>
                            <Text variant="h4" color={colorSystem.neutral[8]}>
                                <Trans>Demands</Trans>
                            </Text>
                            <Text color={colorSystem.neutral[8]}>
                                <Trans>Which of the following demands should be taken into account?</Trans>
                            </Text>
                            <div style={{ height: '400px', width: '100%', marginTop: '8px' }}>
                                <TableDemands
                                    demands={demands}
                                    onSelectionChange={(demandIds) => {
                                        setSelectedDemandIds(demandIds);
                                    }}
                                    selectedDemandIds={selectedDemandIds}
                                    showImportButton={false}
                                />
                            </div>
                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <PrimaryButton
                            onClick={() => {
                                submit(selectedDemandIds);
                                alert('TODO: need to get this working with the backend too');
                                closeDialog();
                            }}
                        >
                            <Trans>Save filter changes</Trans>
                        </PrimaryButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export const useFilterDemandDialogV2 = ({ submit }: { submit: (demandIds: string[]) => void }) => {
    const { setDialog, closeDialog } = useDialogContext();

    return () => setDialog(<DemandFilterDialog closeDialog={closeDialog} submit={submit} />);
};
