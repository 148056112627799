interface HasFactoryLeadTime {
    factoryLeadTime: number | null;
}

export const findFastestFactoryLeadTime = <T extends HasFactoryLeadTime>(marketOfferData: T[]): T | undefined => {
    const validOffers = marketOfferData.filter((offer) => offer.factoryLeadTime !== 0);

    if (validOffers.length === 0) return undefined;
    if (validOffers.every((offer) => offer.factoryLeadTime === null)) return validOffers[0];

    return validOffers.reduce((fastest, current) => {
        if (fastest.factoryLeadTime === null) return current;
        if (current.factoryLeadTime === null) return fastest;
        return current.factoryLeadTime < fastest.factoryLeadTime ? current : fastest;
    });
};
