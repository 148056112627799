import { colorSystem } from '@luminovo/design-system';
import { PCBFileTypes } from '@luminovo/http-client';
import { StateFileType } from './layerAssignmentsUtils';

export const PCBFileColors: Record<string, { color: keyof typeof colorSystem }> = {
    [StateFileType.SilkscreenTop]: { color: 'red' },
    [StateFileType.SilkscreenBottom]: { color: 'red' },
    [StateFileType.SoldermaskBottom]: { color: 'green' },
    [StateFileType.SoldermaskTop]: { color: 'green' },
    [StateFileType.Copper]: { color: 'yellow' },
    [StateFileType.PasteTop]: { color: 'neutral' },
    [StateFileType.PasteBottom]: { color: 'neutral' },
    [StateFileType.MechanicalFiles]: { color: 'primary' },
    [StateFileType.MiscellaneousFiles]: { color: 'teal' },
    [PCBFileTypes.OUTLINE]: { color: 'violet' },
    [PCBFileTypes.DRILL]: { color: 'primary' },
    [StateFileType.StackupFiles]: { color: 'neutral' },
};
