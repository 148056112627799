import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../boundingBox';
import { generateId } from '../generateId';
import { Attribute, AttributeExtractionRule, ExtractionItem, Extractor } from '../types';

export type KeyValuePair = {
    key: { content: string; boundingRegions: { pageNumber: number; polygon: number[] }[] };
    value: { content: string; boundingRegions: { pageNumber: number; polygon: number[] }[] };
    confidence: number;
};

export function extractKeyValuePairs<TAttribute extends Attribute>({
    extractionRules,
}: {
    extractionRules: AttributeExtractionRule<TAttribute>[];
}): Extractor<TAttribute> {
    return {
        generator: async function* (azureExtractionResult: AzureExtractionResult): AsyncGenerator<ExtractionItem> {
            for (const pairs of azureExtractionResult.analyzeResult.keyValuePairs) {
                if (!pairs.value) {
                    continue;
                }
                yield {
                    context: pairs.key,
                    value: pairs.value,
                    confidence: pairs.confidence,
                };
            }
        },

        extractRegion: ({ value }) => {
            return [
                {
                    id: generateId(value),
                    content: value.content,
                    pageNumber: value.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(value.boundingRegions),
                    attributes: [],
                },
            ];
        },

        extractionRules,
    };
}
