import { formatDecimal, isPresent } from '@luminovo/commons';
import { Box } from '@mui/material';
import { colorSystem } from '../../theme';
import { Link } from '../Link';
import { parseOptionsValuesOrFunc } from '../TanStackTable/utils';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Tag, TagProps } from './Tag';

export interface TagGroupProps<T> {
    limit?: number;
    options: T[];
    getOptionLabel: (item: T) => TagProps['label'];
    onOptionClick?: (item: T) => void;
    color?: TagProps['color'] | ((item: T) => TagProps['color']);
    attention?: TagProps['attention'] | ((item: T) => TagProps['attention'] | undefined);
}

export function TagGroup<T>({
    options,
    getOptionLabel,
    color = 'neutral',
    attention = 'low',
    limit = 1,
    onOptionClick,
}: TagGroupProps<T>): JSX.Element {
    const visibleOptions = options.slice(0, limit);
    const hiddenOptions = options.slice(limit);
    const disableTooltip = options.length < 2 && !isPresent(onOptionClick);

    const tooltipContent = (
        <ul
            style={{
                margin: 0,
                paddingLeft: 18,
                maxHeight: 200,
                overflowY: 'auto',
            }}
        >
            {options.map((option, index) => (
                <li key={index}>
                    {onOptionClick ? (
                        <Link
                            attention="low"
                            variant="body-small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onOptionClick(option);
                            }}
                            sx={{ display: 'inline-block', width: '100%' }}
                        >
                            {getOptionLabel(option)}
                        </Link>
                    ) : (
                        getOptionLabel(option)
                    )}
                </li>
            ))}
        </ul>
    );

    return (
        <Tooltip variant={'white'} title={disableTooltip ? '' : tooltipContent}>
            <Box display={'flex'} gap={'4px'} alignItems="center" width={'fit-content'}>
                {visibleOptions.map((option, index) => (
                    <Tag
                        key={index}
                        label={getOptionLabel(option)}
                        color={parseOptionsValuesOrFunc(color, option)}
                        attention={parseOptionsValuesOrFunc(attention, option)}
                    />
                ))}
                {hiddenOptions.length > 0 && (
                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>{`+${formatDecimal(
                        hiddenOptions.length,
                    )}`}</Text>
                )}
            </Box>
        </Tooltip>
    );
}
