import { isPresent } from '@luminovo/commons';
import { compareByNumber } from '@luminovo/design-system';
import { compareByConfidence } from '../compareByConfidence';
import { formatAttributeValue } from '../formatAttributeValue';
import { maxBy } from '../maxBy';
import { minBy } from '../minBy';
import { Attribute, Attributes, Region } from '../types';

export function merge(regions: Region[]): Region[] {
    let result: Region[] = regions;
    for (const mergeFunction of [mergeById, mergeByIdenticalMpns]) {
        result = mergeFunction(result);
    }
    return result;
}

const isNumericAttribute = (attr: Attribute) => {
    const numericalAttributes: Attributes[] = ['number', 'totalPrice', 'unitPrice', 'quantity', 'moq', 'mpq'];
    return numericalAttributes.includes(attr.attr);
};

const mergeRedundantNumberAttributes = createMergeFunction<Attribute>({
    // filter numeric attributes
    filter: (attr) => isNumericAttribute(attr),
    groupBy: (attr) => [formatAttributeValue(attr)],
    merge: (attrs) => {
        return attrs.filter((attr) => attr.attr !== 'number');
    },
});

const mergeById = createMergeFunction<Region>({
    groupBy: (region) => [region.id],
    merge: (regions) => {
        const region = regions[0];
        const attributes = mergeIdenticalAttributes(regions.flatMap((r) => r.attributes));
        const withoutRedundantAttributes = mergeRedundantNumberAttributes(attributes);
        return [
            {
                ...region,
                attributes: withoutRedundantAttributes,
            },
        ];
    },
});

/**
 * Remove all regions that overlap too much with another region.
 */
const removeOverlappingRegions = (regions: Region[], overlapThreshold: number = 0.6): Region[] => {
    // start with leftmost region
    regions.sort((a, b) => a.box.x - b.box.x);

    const result: Region[] = [];
    while (regions.length > 0) {
        let currentRegion = regions[0];
        result.push(currentRegion);
        // delete overlapping regions
        regions = regions.filter(
            (r) =>
                currentRegion.box
                    .translateY(currentRegion.pageNumber)
                    .overlapInPercent(r.box.translateY(r.pageNumber)) <= overlapThreshold,
        );
    }

    return result;
};

/**
 * Merge regions with identical MPNs.
 */
const mergeByIdenticalMpns = createMergeFunction<Region>({
    filter: (r) => r.attributes.some((a) => a.attr === 'part'),
    groupBy: (r) => {
        return r.attributes
            .map((a) => {
                return a.attr === 'part' && a.value ? a.value.id : undefined;
            })
            .filter(isPresent);
    },
    merge: (regions) => {
        regions = removeOverlappingRegions(regions);
        const minX = minBy(regions, (r) => r.box.x)?.box.x ?? 0;
        const maxX = maxBy(regions, (r) => r.box.x)?.box.x ?? 0;
        if (maxX - minX < 0.01) {
            return regions;
        }
        return splitVisually(regions, (r) => r.box.x).left;
    },
});

const mergeIdenticalAttributes = createMergeFunction<Attribute>({
    groupBy: (attr) => [attr.attr, formatAttributeValue(attr)],
    merge: (attrs) => {
        const first = attrs.sort(compareByConfidence)[0];
        return [first];
    },
});

function indexBy<T>(elems: T[], getKey: (elem: T) => string): Map<string, T[]> {
    const map = new Map<string, T[]>();
    for (const elem of elems) {
        const key = getKey(elem);
        if (!map.has(key)) {
            map.set(key, []);
        }
        map.get(key)!.push(elem);
    }
    return map;
}

function createMergeFunction<T>({
    filter,
    groupBy,
    merge,
}: {
    filter?: (elem: T) => boolean;
    groupBy: (elem: T) => string[];
    merge: (elems: T[]) => T[];
}): (elems: T[]) => T[] {
    return (elems) => {
        const merged: T[] = [];

        const elementsToMerge = filter ? elems.filter(filter) : elems;
        const elementsToKeep = filter ? elems.filter((elem) => !filter(elem)) : [];

        for (const group of indexBy(elementsToMerge, (item) => JSON.stringify(groupBy(item))).values()) {
            if (group.length === 1) {
                merged.push(group[0]);
            } else if (group.length > 1) {
                merged.push(...merge(group));
            }
        }
        return elementsToKeep.concat(merged);
    };
}

function splitVisually<T>(items: T[], getPoint: (elem: T) => number): { left: T[]; right: T[]; loss: number } {
    const sorted = Array.from(items).sort(compareByNumber(getPoint));

    const dividers = sorted.map((item, index) => {
        const divider = getPoint(item);
        const left = sorted.filter((item) => getPoint(item) <= divider);
        const right = sorted.filter((item) => getPoint(item) > divider);
        if (left.length === 0 || right.length === 0) {
            return {
                left,
                right,
                loss: Infinity,
            };
        }

        return {
            left,
            right,
            // Calculate the loss as the sum of the squared widths of the two groups
            loss: left.length,
        };
    });

    return minBy(dividers, (d) => d.loss) ?? { left: [], right: [], loss: 0 };
}
