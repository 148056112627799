import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { DielectricTypes, GenericPartTypes } from '@luminovo/http-client';

export const dielectricTypesTranslations: Record<DielectricTypes, MessageDescriptor> = {
    [DielectricTypes.Ceramic]: defineMessage({ message: 'Ceramic' }),
};

export const genericPartTypeTranslations: Record<GenericPartTypes, MessageDescriptor> = {
    [GenericPartTypes.Resistor]: defineMessage({ message: 'Generic resistor' }),
    [GenericPartTypes.Capacitor]: defineMessage({ message: 'Generic capacitor' }),
};
