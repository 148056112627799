import { t } from '@lingui/macro';
import { FieldNumericController, Flexbox, FormItem } from '@luminovo/design-system';
import { CalculationTypeWithoutFormula, TimeCalculationTypes } from '@luminovo/http-client';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';
import { SelectController } from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { colorSystem } from '../../../design-system/themes';
import { durationUnitPublicTranslations } from '../../../resources/activity/activityBackendTypes';
import { OVERSCORE_CHARACTER, capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { assertUnreachable } from '../../../utils/typingUtils';
import { AddActivityFormInputs } from './AddActivityFormTypes';

function DurationController<T extends FieldValues>({
    control,
    amountName,
    unitName,
    required = false,
}: {
    amountName: Path<T>;
    unitName: Path<T>;
    control: Control<T>;
    required?: boolean;
}): JSX.Element {
    return (
        <Box display={'flex'}>
            <Box>
                <FieldNumericController
                    name={amountName}
                    control={control}
                    required={required}
                    representation="string"
                    FieldProps={{ style: { width: '120px' } }}
                />
            </Box>
            <Box ml={1}>
                <SelectController name={unitName} control={control} translations={durationUnitPublicTranslations} />
            </Box>
        </Box>
    );
}

type Field =
    | 'fixedUnitTime'
    | 'variableUnitTime'
    | 'fixedBatchTime'
    | 'variableBatchTime'
    | 'fixedProjectTime'
    | 'variableProjectTime';

const errorMessage = (field: Field) => {
    return `${field} should not be called in the function`;
};

const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[7],
});

/**
 * @returns the path names for amount and unit which are required for the unit controller.
 */
const getPathNames = (
    timeCalculationType: TimeCalculationTypes,
    selectedUnitCalculationType: CalculationTypeWithoutFormula,
    field: Field,
) => {
    const getUnitCalculationUnitName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableBatchTime' ||
            field === 'fixedBatchTime' ||
            field === 'variableProjectTime' ||
            field === 'fixedProjectTime'
        ) {
            throw new Error(errorMessage(field));
        }
        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearUnitCalculationType.${field}.duration.unit`;
            case 'Fixed':
                if (field === 'variableUnitTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedUnitCalculationType.${field}.duration.unit`;
                }

            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getBatchCalculationUnitName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableUnitTime' ||
            field === 'fixedUnitTime' ||
            field === 'variableProjectTime' ||
            field === 'fixedProjectTime'
        ) {
            throw new Error(errorMessage(field));
        }
        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearBatchCalculationType.${field}.duration.unit`;
            case 'Fixed':
                if (field === 'variableBatchTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedBatchCalculationType.${field}.duration.unit`;
                }

            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getProjectCalculationUnitName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableUnitTime' ||
            field === 'fixedUnitTime' ||
            field === 'variableBatchTime' ||
            field === 'fixedBatchTime'
        ) {
            throw new Error(errorMessage(field));
        }
        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearProjectCalculationType.${field}.duration.unit`;
            case 'Fixed':
                if (field === 'variableProjectTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedProjectCalculationType.${field}.duration.unit`;
                }
            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getUnitName = (): Path<AddActivityFormInputs> => {
        switch (timeCalculationType) {
            case 'Unit':
                return getUnitCalculationUnitName();

            case 'Batch':
                return getBatchCalculationUnitName();

            case 'Project':
                return getProjectCalculationUnitName();
            default:
                assertUnreachable(timeCalculationType);
        }
    };

    const getUnitCalculationAmountName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableBatchTime' ||
            field === 'fixedBatchTime' ||
            field === 'variableProjectTime' ||
            field === 'fixedProjectTime'
        ) {
            throw new Error(errorMessage(field));
        }

        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearUnitCalculationType.${field}.duration.amount`;
            case 'Fixed':
                if (field === 'variableUnitTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedUnitCalculationType.${field}.duration.amount`;
                }
            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getBatchCalculationAmountName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableUnitTime' ||
            field === 'fixedUnitTime' ||
            field === 'variableProjectTime' ||
            field === 'fixedProjectTime'
        ) {
            throw new Error(errorMessage(field));
        }
        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearBatchCalculationType.${field}.duration.amount`;
            case 'Fixed':
                if (field === 'variableBatchTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedBatchCalculationType.${field}.duration.amount`;
                }
            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getProjectCalculationAmountName = (): Path<AddActivityFormInputs> => {
        if (
            field === 'variableUnitTime' ||
            field === 'fixedUnitTime' ||
            field === 'variableBatchTime' ||
            field === 'fixedBatchTime'
        ) {
            throw new Error(errorMessage(field));
        }
        switch (selectedUnitCalculationType) {
            case 'Linear':
                return `linearProjectCalculationType.${field}.duration.amount`;
            case 'Fixed':
                if (field === 'variableProjectTime') {
                    throw new Error(errorMessage(field));
                } else {
                    return `fixedProjectCalculationType.${field}.duration.amount`;
                }

            default:
                assertUnreachable(selectedUnitCalculationType);
        }
    };

    const getAmountName = (): Path<AddActivityFormInputs> => {
        switch (timeCalculationType) {
            case 'Unit':
                return getUnitCalculationAmountName();

            case 'Batch':
                return getBatchCalculationAmountName();

            case 'Project':
                return getProjectCalculationAmountName();

            default:
                assertUnreachable(timeCalculationType);
        }
    };

    return { unitName: getUnitName(), amountName: getAmountName() };
};

const PanelFactorEquation = (): JSX.Element | null => {
    return (
        <Box textAlign={'center'}>
            <StyledTypography variant={'body1'}>1</StyledTypography>
            <StyledTypography variant={'body1'}>
                {OVERSCORE_CHARACTER.repeat(t`panel factor`.length + 6)}
            </StyledTypography>
            <StyledTypography variant={'body1'} style={{ marginTop: '-12px' }}>
                {capitalizeFirstLetter(t`panel factor`)}
            </StyledTypography>
        </Box>
    );
};

export function FixedTimeCalculationComponent({
    selectedCalculationType,
    timeCalculationType,
    isRequired = true,
}: {
    selectedCalculationType: CalculationTypeWithoutFormula;
    timeCalculationType: TimeCalculationTypes;
    isRequired?: boolean;
}): JSX.Element {
    const { control, watch } = useFormContext<AddActivityFormInputs>();
    const fieldName = (() => {
        switch (timeCalculationType) {
            case 'Unit':
                return 'fixedUnitTime';
            case 'Batch':
                return 'fixedBatchTime';
            case 'Project':
                return 'fixedProjectTime';
            default:
                assertUnreachable(timeCalculationType);
        }
    })();
    const fieldLabel = (() => {
        switch (timeCalculationType) {
            case 'Unit':
                return t`Fixed unit time`;
            case 'Batch':
                return t`Fixed batch time`;
            case 'Project':
                return t`Fixed project time`;
            default:
                assertUnreachable(timeCalculationType);
        }
    })();

    const { unitName, amountName } = getPathNames(timeCalculationType, selectedCalculationType, fieldName);
    const isPerPanel = watch('activity.isActivityPerPanel');

    return (
        <FormItem label={fieldLabel} required={isRequired}>
            <Grid item>
                <Box display={'flex'} gap={'8px'}>
                    <DurationController
                        control={control}
                        amountName={amountName}
                        unitName={unitName}
                        required={isRequired}
                    />
                    {isPerPanel && timeCalculationType !== 'Batch' && (
                        <Flexbox alignItems={'center'} gap={8}>
                            <StyledTypography variant={'h4'}>x</StyledTypography>
                            <PanelFactorEquation />
                        </Flexbox>
                    )}
                </Box>
            </Grid>
        </FormItem>
    );
}

export function VariableTimeCalculationComponent({
    selectedCalculationType,
    timeCalculationType,
    isRequired = true,
}: {
    selectedCalculationType: CalculationTypeWithoutFormula;
    timeCalculationType: TimeCalculationTypes;
    isRequired?: boolean;
}): JSX.Element {
    const { control } = useFormContext<AddActivityFormInputs>();
    const fieldName = (() => {
        switch (timeCalculationType) {
            case 'Unit':
                return 'variableUnitTime';
            case 'Batch':
                return 'variableBatchTime';
            case 'Project':
                return 'variableProjectTime';
            default:
                assertUnreachable(timeCalculationType);
        }
    })();
    const fieldLabel = (() => {
        switch (timeCalculationType) {
            case 'Unit':
                return t`Variable unit time`;
            case 'Batch':
                return t`Variable batch time`;
            case 'Project':
                return t`Variable project time`;
            default:
                assertUnreachable(timeCalculationType);
        }
    })();
    const { unitName, amountName } = getPathNames(timeCalculationType, selectedCalculationType, fieldName);

    return (
        <FormItem label={fieldLabel} required={isRequired}>
            <Grid item xs={4}>
                <DurationController
                    control={control}
                    amountName={amountName}
                    unitName={unitName}
                    required={isRequired}
                />
            </Grid>
        </FormItem>
    );
}
