import { t } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { FieldSelect, Row, Tag, Text, Tooltip } from '@luminovo/design-system';
import { PackageMountingEnum } from '@luminovo/http-client';
import { packageMountingEnumTranslations } from '@luminovo/sourcing-core';
import { TableCell } from '@mui/material';
import { FullPartWithApprovalStatus } from '../../../../../resources/part/partFrontendTypes';
import { ManufacturingDataContext } from '../ManufacturingDataTypes';
import { getManufacturingPartsFormValues } from '../getManufacturingPartsFormValues';

export const RenderMounting = (
    { data }: Row<FullPartWithApprovalStatus>,
    {
        isRfqEditable,
        packageData,
        editOtsPartTooltip,
        editComponentToolTip,
        canEditOtsParts,
        canEditOtsComponents,
        onChangeOtsPart,
        onChangeOtsComponent,
    }: ManufacturingDataContext,
): JSX.Element => {
    const value = getManufacturingPartsFormValues(data, packageData);
    const { mounting } = value;

    if (!isRfqEditable) {
        return <TableCell>{mounting ? <Tag color="neutral" label={mounting} /> : '-'}</TableCell>;
    }

    const partType = data.type;

    if (partType === 'Ipn') {
        return (
            <TableCell>
                <Tooltip title={editComponentToolTip}>
                    <div>
                        <FieldSelect
                            id={`component_mounting`}
                            value={mounting ?? null}
                            options={Object.values(PackageMountingEnum)}
                            getOptionLabel={(opt: string) => transEnum(opt, packageMountingEnumTranslations)}
                            disabled={!canEditOtsComponents}
                            placeholder={t`Unknown`}
                            noOptionsText={t`Mounting does not match any of the options.`}
                            renderOption={(mounting: PackageMountingEnum) => <span id={mounting}>{mounting}</span>}
                            onChange={(value) =>
                                onChangeOtsComponent({
                                    partId: data.part.id,
                                    mounting: value,
                                    name: null,
                                    number_of_pins: null,
                                })
                            }
                            size="small"
                        />
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'Generic') {
        return (
            <TableCell>
                <Text>{mounting ?? '-'}</Text>
            </TableCell>
        );
    }
    if (partType === 'OffTheShelf') {
        return (
            <TableCell>
                <Tooltip title={editOtsPartTooltip}>
                    <div>
                        <FieldSelect
                            id={`ots_part_mounting`}
                            value={mounting ?? null}
                            options={Object.values(PackageMountingEnum)}
                            getOptionLabel={(opt: string) => transEnum(opt, packageMountingEnumTranslations)}
                            disabled={!canEditOtsParts}
                            placeholder={t`Unknown`}
                            noOptionsText={t`Mounting does not match any of the options.`}
                            renderOption={(mounting: PackageMountingEnum) => <span id={mounting}>{mounting}</span>}
                            onChange={(value) =>
                                onChangeOtsPart({
                                    partId: data.part.id,
                                    mounting: value,
                                    name: null,
                                    number_of_pins: null,
                                })
                            }
                            size="small"
                        />
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'CustomComponent') {
        return <TableCell />;
    }
    if (partType === 'Custom') {
        return <TableCell />;
    }
    assertUnreachable(partType);
};
