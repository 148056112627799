import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../../../components/PageLayout';
import { AddCustomPartOfferForm } from '../../../../../../../../modules/CustomPartOfferForm';
import { UrlParams } from '../../../../../../../../utils/routes';

const AddCustomPartOfferPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId'>,
): JSX.Element => {
    const { rfqId, partId } = urlParams.pathParams;

    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox flexDirection={'column'} gap={32}>
                    <Text variant="h1">
                        <Trans>Add offer</Trans>
                    </Text>
                    <AddCustomPartOfferForm rfqId={rfqId} linkedPart={{ type: 'CustomPart', id: partId }} />
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default AddCustomPartOfferPage;
