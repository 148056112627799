/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import { http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { AUTH0_AUTHORIZATION_PARAMS } from '../../../../const';
import { SignupFormState, SignUpPayload } from './types';

export function useMutationSignupUser(signupPayload?: SignUpPayload) {
    const { loginWithRedirect } = useAuth0();
    return useMutation({
        mutationFn: (form: SignupFormState) =>
            http(
                'POST /auth/accept-invite',
                {
                    requestBody: {
                        first_name: form.first_name,
                        last_name: form.last_name,
                        token: form.clear_token,
                        password_confirmation: form.password_confirmation,
                        email: form.email,
                        language: form.language,
                        password: form.password,
                        phone_number: `+${form.country_code} ${form.phone_number}`,
                        customer_id: form.customer_id || null,
                        position: form.position,
                        tenant: signupPayload?.tenant ?? '',
                    },
                },
                // We send an empty token because this endpoint is not authenticated.
                '',
            ),
        onSuccess: () => {
            loginWithRedirect({
                appState: {
                    returnTo: signupPayload?.return_to ?? '/',
                },
                authorizationParams: { ...AUTH0_AUTHORIZATION_PARAMS, login_hint: signupPayload?.email },
            });
        },
    });
}
