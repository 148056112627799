import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    OnlineComponentsCredentialsInputDTORuntype,
    OnlineComponentsResponseBodyDTORuntype,
} from './onlineComponentsBackendTypes';

export const onlineComponentsEndpoints = {
    'GET /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Returns credentials for Online Components integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: OnlineComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Uploads credentials for Online Components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: OnlineComponentsCredentialsInputDTORuntype,
        responseBody: OnlineComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
    'PATCH /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Updates credentials for Online Components API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: OnlineComponentsCredentialsInputDTORuntype,
        responseBody: OnlineComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
    'DELETE /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Deletes existing credentials for Online Components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
};
