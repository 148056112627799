import { SearchBlock } from '@luminovo/design-system';
import { InfiniteData } from '@tanstack/react-query';

export function isEmptyQuery<TSearchBlock, T extends { state: { selectedBlocks: SearchBlock<TSearchBlock>[] } }>(
    searchState: T,
): boolean {
    return searchState.state.selectedBlocks.length === 0;
}

export function hasMoreResults<T extends { total_hits: { value: number } }>(
    result: InfiniteData<T> | undefined,
    getPageSize: (arg0: T) => number,
): boolean {
    if (!result) {
        return true;
    }
    const { pages } = result;
    const MAX_RESULTS = 400;
    const totalResults: number = pages.reduce((count, page) => count + getPageSize(page), 0) ?? 0;
    const totalHits: number = pages[0]?.total_hits.value ?? 0;
    return totalResults < MAX_RESULTS && totalResults < totalHits;
}
