import { t } from '@lingui/macro';
import { SecondaryIconButton } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { GetApp } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { useDownloadPCBFiles } from '../../../../../resources/export/exportHandler';
import { downloadPcbFilesAnalytic } from '../../../../../resources/pcb/analytics/analytic';

export function DownloadPCBFilesButton({
    pcb,
    assemblyId,
    rfqId,
}: {
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
}): JSX.Element {
    const { downloadPCBFiles, isLoading } = useDownloadPCBFiles({
        pcbId: pcb.id,
        options: {
            onSuccess: () => {
                downloadPcbFilesAnalytic({
                    pcbId: pcb.id,
                    rfqId,
                    assemblyId,
                });
            },
        },
    });

    return (
        <Tooltip title={t`Download PCB files`} placement="bottom" arrow>
            <SecondaryIconButton size="medium" onClick={() => downloadPCBFiles()}>
                {isLoading ? <CircularProgress size={'inherit'} /> : <GetApp fontSize="inherit" />}
            </SecondaryIconButton>
        </Tooltip>
    );
}
