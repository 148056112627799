import { t } from '@lingui/macro';
import { Validate } from './types';

export function validateQuantity({ value }: { value: number | string | null | undefined }): Validate {
    if (!value) {
        return { isError: true, message: t`Required` };
    }
    if (typeof value !== 'number') {
        return { isError: true, message: t`Invalid type` };
    }
    if (value <= 0) {
        return { isError: true, message: t`Number must be positive.` };
    }
    return { isError: false, message: '' };
}
