import { formatInteger } from '@luminovo/commons';
import { Tab as MuiTab, TabProps as MuiTabProps, styled } from '@mui/material';
import React from 'react';
import { colorSystem, h5 } from '../../theme';
import { Flexbox } from '../Flexbox';

type TabProps = Omit<MuiTabProps, 'icon'> & {
    icon?: JSX.Element;
    count?: number;
};

const DefaultTab = styled(MuiTab)({
    color: colorSystem.neutral[6],
    minWidth: 'fit-content',
    width: 'fit-content',
    minHeight: 'auto',
    '&:hover': { color: colorSystem.neutral[6] },
    '&.Mui-selected': {
        color: colorSystem.neutral[9],
    },
    '& .MuiTab-labelIcon': {
        minHeight: 0,
    },
});

export const Tab = React.forwardRef<HTMLDivElement, TabProps>(
    ({ icon, count, label, ...props }: TabProps, ref): JSX.Element => {
        const tabLabel = (
            <Flexbox gap={4} alignItems="center">
                {icon}
                {label}
                {count !== undefined ? (
                    <div
                        style={{
                            background: colorSystem.neutral[2],
                            borderRadius: '8px',
                            padding: '0px 4px',
                            height: '16px',
                            alignItems: 'center',
                            ...h5,
                        }}
                    >
                        {formatInteger(count)}
                    </div>
                ) : null}
            </Flexbox>
        );

        return <DefaultTab {...props} ref={ref} label={tabLabel} disableTouchRipple />;
    },
);
