import { Flexbox } from '@luminovo/design-system';
import { CalculationType, DriverStatusDetailsDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { usePageParams } from '../../../resources/hooks';
import { DriverStatusIcon } from './DriverStatusCellIcon';
import { CalculationTypeCell } from './manufacturingComponents';

export const CalculationCellLayout = ({
    calculationType,
    driverStatus,
    manufacturingAssemblyDetails,
}: {
    calculationType: CalculationType | undefined;
    driverStatus: DriverStatusDetailsDTO | undefined;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
}): JSX.Element => {
    const { rfqId } = usePageParams<'/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId'>();

    return (
        <Flexbox gap={'4px'} alignItems="center">
            <Box width={'80px'}>{calculationType && <CalculationTypeCell calculationType={calculationType} />}</Box>
            <DriverStatusIcon
                driverStatus={driverStatus}
                rfqId={rfqId}
                manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                iconType={'circle'}
            />
        </Flexbox>
    );
};
