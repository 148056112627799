import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { ComplianceStatus } from '@luminovo/http-client';
import React from 'react';

export const ComplianceStatusTranslations: Record<ComplianceStatus, MessageDescriptor> = {
    [ComplianceStatus.Compliant]: defineMessage({ message: 'Compliant' }),
    [ComplianceStatus.CompliantWithExemption]: defineMessage({ message: 'Compliant with exemption' }),
    [ComplianceStatus.NonCompliant]: defineMessage({ message: 'Non-compliant' }),
    [ComplianceStatus.NotRequired]: defineMessage({ message: 'Not required' }),
    [ComplianceStatus.Unknown]: defineMessage({ message: 'Unknown' }),
};

export const ComplianceStatusChip: React.FunctionComponent<{
    status: ComplianceStatus;
}> = ({ status }) => {
    const label = transEnum(status, ComplianceStatusTranslations);
    switch (status) {
        case ComplianceStatus.Compliant:
            return <StatusChip color="green" label={label} />;
        case ComplianceStatus.CompliantWithExemption:
            return <StatusChip color="green" label={label} />;
        case ComplianceStatus.NonCompliant:
            return <StatusChip color="red" label={label} />;
        case ComplianceStatus.NotRequired:
            return <StatusChip color="neutral" label={label} />;
        case ComplianceStatus.Unknown:
            return <StatusChip color="neutral" label={label} />;
        default:
            assertUnreachable(status);
    }
};
