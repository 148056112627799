/* eslint-disable spellcheck/spell-checker */
import Slide, { SlideProps } from '@mui/material/Slide';
import React from 'react';

/**
Taken from this example https://material-ui.com/components/dialogs/#full-screen-dialogs
 */
export const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
