import { t } from '@lingui/macro';
import { DestructiveTertiaryIconButton } from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';

export const DeleteDemandScenarioButton = ({
    demandScenarioId,
    onSuccess,
}: {
    demandScenarioId: string;
    onSuccess?: () => void;
}) => {
    const { mutateAsync } = useHttpMutation('DELETE /demand-scenarios/:demandScenarioId', {
        snackbarMessage: t`Demand scenario deleted`,
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    return (
        <DestructiveTertiaryIconButton
            onClick={() => {
                mutateAsync({ pathParams: { demandScenarioId } });
            }}
            children={<Delete fontSize="small" />}
            size="medium"
        />
    );
};
