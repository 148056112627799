export function groupBy<T, K>(
    items: T[],
    getKey: (item: T) => K,
): Array<{
    key: K;
    items: T[];
}> {
    const groups: Array<{
        key: K;
        items: T[];
    }> = [];

    for (const item of items) {
        const key = getKey(item);
        const group = groups.find((g) => g.key === key);
        if (group) {
            group.items.push(item);
        } else {
            groups.push({
                key,
                items: [item],
            });
        }
    }

    return groups;
}
