import { Trans, t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { Dialog, DialogContent, PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import { OtsFullPart, QuoteTrackingDTO, isOtsFullPart } from '@luminovo/http-client';
import {
    LeadTimeUnit,
    PdfDropzone,
    PdfExtractionResults,
    PdfViewer,
    PdfViewerContextProvider,
    PdfViewerForm,
    PdfViewerLayout,
    PdfViewerTable,
    PdfViewerToolbar,
    RegionNetwork,
    composeExtractionResults,
    leadTimeInDays,
    usePdfViewerState,
} from '@luminovo/sourcing-core';
import { Upload } from '@mui/icons-material';
import { withErrorBoundary } from '@sentry/react';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpFileUpload } from '../../../../resources/http/useHttpFileUpload';
import { useMutationAnalyzePdf } from '../../../../resources/offer/offerHandler';
import { createLineItems, getSupplierPortalMode } from '../model/utils';
import { QuotedLineItem, StandardPartQuoteFormValues } from '../types';
export const PdfQuoteImportButton = withErrorBoundary(
    function PdfQuoteImportButton({
        quoteTracking,
        variant,
    }: {
        quoteTracking: QuoteTrackingDTO;
        variant: 'primary' | 'secondary';
    }) {
        const { reset } = useFormContext<StandardPartQuoteFormValues>();

        const expectedParts = quoteTracking.request_standard_parts
            .map((p) => p.kind)
            .flatMap((part) => (isOtsFullPart(part) ? [part] : []));

        const { mutateAsync: analyzePdf, isLoading } = useMutationAnalyzePdf();

        const { setDialog, closeDialog } = useDialogContext();

        if (expectedParts.length === 0) {
            return null;
        }

        const ButtonComponent = variant === 'primary' ? PrimaryButton : SecondaryButton;

        return (
            <PdfDropzone
                onSuccess={async ({ file, pdf }) => {
                    const { regionNetwork, uploadedUrl } = await analyzePdf({
                        expectedParts,
                        file,
                    });

                    setDialog(
                        <PdfViewerContextProvider>
                            <PdfOfferExtractorDialog
                                closeDialog={closeDialog}
                                file={file}
                                expectedParts={expectedParts}
                                pdfDocumentProxy={pdf}
                                quoteTracking={quoteTracking}
                                regionNetwork={regionNetwork}
                                uploadedUrl={uploadedUrl}
                                onImport={({ currency, offerNumber, validUntil, extractedRows }) => {
                                    reset({
                                        leadTimeUnit: LeadTimeUnit.Days,
                                        supplierAndStockLocation: quoteTracking.supplier_and_stock_location.id,
                                        currency: currency.value.value,
                                        offerNumber: offerNumber?.value.value,
                                        validUntil: validUntil?.value.value,
                                        lineItems: linkExtractionResultsToLineItems(
                                            createLineItems(quoteTracking),
                                            extractedRows,
                                        ),
                                    });
                                }}
                            />
                        </PdfViewerContextProvider>,
                    );
                }}
            >
                <ButtonComponent
                    size="medium"
                    disabled={getSupplierPortalMode(quoteTracking) === 'view'}
                    id={id('sourcing/button_import_pdf')}
                    isLoading={isLoading}
                    startIcon={<Upload />}
                    fullWidth
                >
                    <Trans>Upload PDF quote</Trans>
                </ButtonComponent>
            </PdfDropzone>
        );
    },
    { fallback: <Text>Something went wrong</Text> },
);

function PdfOfferExtractorDialog({
    pdfDocumentProxy,
    closeDialog,
    regionNetwork,
    expectedParts,
    quoteTracking,
    uploadedUrl,
    file,
    onImport,
}: {
    closeDialog: () => void;
    pdfDocumentProxy: PDFDocumentProxy;
    file: File;
    regionNetwork: RegionNetwork;
    expectedParts: OtsFullPart[];
    quoteTracking: QuoteTrackingDTO;
    uploadedUrl?: string;
    onImport(offers: PdfExtractionResults): void;
}): JSX.Element {
    const [state, dispatch] = usePdfViewerState();
    React.useEffect(() => {
        dispatch({ type: 'setRegions', regions: regionNetwork });
    }, [dispatch, regionNetwork]);
    const extractionResults = composeExtractionResults(state.regs);

    const { mutateAsync: mutateAsyncUpload } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );

    return (
        <Dialog open={true} fullScreen={true} maxWidth={'lg'}>
            <DialogContent style={{ padding: 0, overflowY: 'hidden', height: '100vh' }}>
                <PdfViewerLayout
                    form={<PdfViewerForm extractionResults={extractionResults} />}
                    pdfViewer={<PdfViewer pdf={pdfDocumentProxy} />}
                    table={<PdfViewerTable expectedParts={expectedParts} extractionResults={extractionResults} />}
                    toolbar={
                        <PdfViewerToolbar
                            supplierAndStockLocation={quoteTracking.supplier_and_stock_location}
                            onCancel={closeDialog}
                            importLabel={<Trans>Save</Trans>}
                            onImport={async () => {
                                await mutateAsyncUpload({ pathParams: { id: quoteTracking.id }, files: file });
                                onImport(composeExtractionResults(state.regs));
                                closeDialog();
                            }}
                            extractionRateOfRequiredFields={extractionResults.extractionRateOfRequiredFields}
                        />
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

/**
 * Take the pdf extraction results and link them to the line items.
 */
function linkExtractionResultsToLineItems(
    lineItems: QuotedLineItem[],
    pdfExtractedRows: PdfExtractionResults['extractedRows'],
): QuotedLineItem[] {
    return lineItems.flatMap((lineItem) => {
        const filtered = pdfExtractedRows
            .filter((row) => row.fields.part?.value.value?.id === lineItem.part.id)
            .map((pdfRow): QuotedLineItem => {
                return {
                    ...lineItem,
                    included: true,
                    offer: convertPdfRowToQuotedLineItemOffer(pdfRow),
                };
            });
        if (filtered.length === 0) {
            return [lineItem];
        }
        return filtered;
    });
}

function convertPdfRowToQuotedLineItemOffer(pdfRow: PdfExtractionResults['extractedRows'][0]): QuotedLineItem['offer'] {
    const packaging = pdfRow.fields.packaging?.value?.value;

    return {
        unitPrice: pdfRow.fields.unitPrice?.value?.value,
        pricePer: 1,
        leadTime: leadTimeInDays(pdfRow.fields.standardFactoryLeadTime?.value?.value) ?? undefined,
        moq: pdfRow.fields.moq?.value?.value ?? 1,
        mpq: pdfRow.fields.mpq?.value?.value,
        notes: '',
        packaging: packaging === 'none' ? undefined : packaging,
        stock: pdfRow.fields.stock?.value?.value,
        supplierPartNumber: undefined,
        offeredPart: pdfRow.fields.part?.value.value ?? undefined,
    };
}
