import { Trans } from '@lingui/macro';
import { Dropzone, Flexbox, Text } from '@luminovo/design-system';
import { styled } from '@mui/material';
import { useMutationUploadMissingPcbFiles } from '../../../../../resources/pcb/pcbHandlers';

const MessageInfo = () => {
    return (
        <Text variant="body-small-semibold">
            <Trans>Save changes to upload files.</Trans>
        </Text>
    );
};

export default function LayersFileUpload({ pcbId, disabled }: { pcbId: string; disabled: boolean }): JSX.Element {
    const { mutateAsync, isLoading } = useMutationUploadMissingPcbFiles({ pcbId });

    const handleDropAccepted = async (files: File[]) => {
        mutateAsync(files);
    };

    return (
        <Dropzone
            multiple
            title={''}
            onDropAccepted={handleDropAccepted}
            isLoading={isLoading}
            disabled={disabled}
            accept={null}
            overrides={{
                Container: DropzoneContainer,
                MessageInfo: disabled ? MessageInfo : undefined,
            }}
        />
    );
}

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
    width: '100%',
});
