import { Trans } from '@lingui/macro';
import { Column, FieldSelectControlled } from '@luminovo/design-system';
import { QuantityUnit } from '@luminovo/http-client';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnQuantityUnit: Column<TableQuotationOfferItem> = selectableColumn({
    attribute: 'unit',
    label: <Trans>Unit</Trans>,
    render: ({ extracted }) => {
        return extracted;
    },
    InputComponent: ({ control }) => {
        return (
            <>
                <FieldSelectControlled
                    control={control}
                    name="unit"
                    FieldProps={{
                        autoFocus: true,
                        fullWidth: true,
                        options: Object.values(QuantityUnit),
                        isOptionEqualToValue(option, value) {
                            return option === value;
                        },
                    }}
                    required
                />
            </>
        );
    },
});
