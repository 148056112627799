import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { UserDriverDetailsDTO } from '@luminovo/http-client';
import { createWholeWordRegex } from '@luminovo/manufacturing-core';
import { Box, FormHelperText } from '@mui/material';
import React from 'react';
import { MonacoEditor, MonacoEditorRef } from '../../../../../components/MonacoEditor';
import {
    AdditionalCalculationInputControl,
    CalculationInputControl,
} from '../../../../../components/MonacoEditor/editorTypes';
import { LoadingText } from '../../../../../components/Spinners';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import {
    AdvancedFormulaDialog,
    DialogValue,
} from '../../../../Manufacturing/shared/AdvancedFormulaDialog/AdvancedFormulaDialog';
import { createPredefinedManufacturingInputControls } from '../../../../Manufacturing/utils/createPredefinedManufacturingInputControls';
import { CostTypeFormula, Formula, FormulaType, formulaTypePublicTranslations } from '../utils/types';
import { createAdditionalFormulaInputControls } from './additionalFormInputControls/additionalFormulaInputControls';

/* eslint-disable-next-line camelcase */
const totalOneTimeCostFormulaValue = 'total_one_time_cost';

const calculationInputControls = (
    formulaValue: string,
    automaticDrivers: UserDriverDetailsDTO[],
    additionalFormulaInputControls: AdditionalCalculationInputControl[],
) => {
    const userDriverInputControls: CalculationInputControl[] = automaticDrivers.map((d) => {
        const driverFormulaValue = d.name.replaceAll(' ', '_');
        return {
            name: d.name,
            type: 'Driver',
            automaticDetails: d.details.automatic_details ?? undefined,
            formulaValue: driverFormulaValue,
            isInFormula: formulaValue !== undefined && createWholeWordRegex(driverFormulaValue).test(formulaValue),
            notes: d.notes ?? undefined,
        };
    });

    const predefinedManufacturingInputControls: CalculationInputControl[] =
        createPredefinedManufacturingInputControls(formulaValue);

    const inputControls: CalculationInputControl[] = [
        {
            name: t`Total one time cost`,
            type: 'Total one time cost',
            automaticDetails: undefined,
            formulaValue: totalOneTimeCostFormulaValue,
            isInFormula:
                formulaValue !== undefined && createWholeWordRegex(totalOneTimeCostFormulaValue).test(formulaValue),
            notes: t`Sum of all project-level activities and expenses per unit`,
        },
        ...createAdditionalFormulaInputControls(additionalFormulaInputControls, formulaValue),
        ...userDriverInputControls,
        ...predefinedManufacturingInputControls,
    ];
    return { inputControls };
};

const defaultFormula: Formula = { type: 'percentage', value: '' };

interface Props {
    formulaCost: CostTypeFormula;
    onSubmit: (dialogValue: CostTypeFormula) => void;
    errorMessage: string | undefined;
    additionalFormulaInputControls?: AdditionalCalculationInputControl[];
}

export function UpsertFormula({ formulaCost, onSubmit, errorMessage, additionalFormulaInputControls }: Props) {
    const editorRef: MonacoEditorRef = React.useRef();
    const formula = formulaCost.value?.value?.length > 0 ? formulaCost.value : defaultFormula;
    const { data, isLoading } = useHttpQuery('GET /user-drivers', {});
    const allDrivers: UserDriverDetailsDTO[] = data?.data ?? [];
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);
    const { inputControls } = calculationInputControls(formula.value, allDrivers, additionalFormulaInputControls ?? []);

    const onSubmitClick = (dialogValue: DialogValue<FormulaType>) => {
        onSubmit({
            type: 'formula',
            value: { value: dialogValue.formula ?? '', type: dialogValue.unit },
        });
    };

    if (isLoading) return <LoadingText />;

    return (
        <>
            <AdvancedFormulaDialog
                onSubmitClick={onSubmitClick}
                initialFormValue={formula.value}
                isDialogOpen={isDialogOpen}
                onReject={closeDialog}
                initialUnit={formula.type}
                unitTranslations={formulaTypePublicTranslations}
                inputControls={inputControls}
                unitLabel={t`Formula type`}
                includeDocumentation={false}
            />

            {formula.value.length > 0 ? (
                <Flexbox flexDirection="column" justifyContent={'space-between'}>
                    <Box width={'360px'} onClick={openDialog} style={{ cursor: 'pointer' }}>
                        <MonacoEditor
                            value={formula.value}
                            readOnly
                            inputControls={inputControls}
                            editorRef={editorRef}
                            lineHeight={40}
                        />
                    </Box>
                </Flexbox>
            ) : (
                <Flexbox flexDirection="column">
                    <SecondaryButton
                        variant={'outlined'}
                        onClick={openDialog}
                        style={{ borderColor: !!errorMessage ? colorSystem.red[6] : 'default' }}
                    >
                        <Text>
                            + <Trans> Build formula </Trans>
                        </Text>
                    </SecondaryButton>

                    <FormHelperText error={true} style={{ marginLeft: '4px', marginTop: '4px' }}>
                        {errorMessage}
                    </FormHelperText>
                </Flexbox>
            )}
        </>
    );
}
