import { Currency } from '@luminovo/commons';
import {
    CostComponentFixedCost,
    CostComponentFormulaCost,
    CostComponentLinearCost,
    ExpenseDTO,
} from '@luminovo/http-client';
import { RecursivePartial } from '../../../../utils/typingUtils';
import {
    AddExpenseFormInputs,
    AddExpenseFormInputsFixedCostCalculation,
    AddExpenseFormInputsFormulaCostCalculation,
    AddExpenseFormInputsLinearCostCalculation,
} from './AddExpenseFormTypes';

export const createDefaultExpenseFormValues = (
    preferredCurrency: Currency,
): RecursivePartial<AddExpenseFormInputs> => ({
    expense: {
        level: 'Project',
    },
    selectedCostCalculationType: 'Fixed',
    linearCostCalculation: {
        fixedCost: {
            amount: undefined,
        },
        variableCost: {
            variableUnit: undefined,
        },
    },
    fixedCostCalculation: {
        fixedCost: {
            amount: undefined,
        },
    },
    formulaCostCalculation: {
        formula: '',
        currency: preferredCurrency ?? Currency.EUR,
    },
    expenseCurrency: preferredCurrency,
});

const getFixedCostCalculation = (costComponent: CostComponentFixedCost): AddExpenseFormInputsFixedCostCalculation => {
    return {
        fixedCost: {
            amount: costComponent.details.fixed_cost.amount,
        },
    };
};

const getFormulaCostCalculation = (
    costComponent: CostComponentFormulaCost,
): AddExpenseFormInputsFormulaCostCalculation => {
    return {
        formula: costComponent.details.formula,
        currency: costComponent.details.currency,
    };
};

const getLinearCostCalculation = (
    costComponent: CostComponentLinearCost,
): AddExpenseFormInputsLinearCostCalculation => {
    return {
        fixedCost: {
            amount: costComponent.details.fixed_cost?.amount,
        },
        variableCost: {
            driverId: costComponent.details.variable_cost.driver,
            variableUnit: {
                amount: costComponent.details.variable_cost.variable_unit.amount,
            },
        },
    };
};

export const convertExpenseDTOToFormValues = ({
    expense,
    preferredCurrency,
}: {
    expense: ExpenseDTO;
    preferredCurrency: Currency;
}): RecursivePartial<AddExpenseFormInputs> => {
    const selectedCostCalculationType = expense.cost_components.type;
    return {
        expense: {
            category: expense.category,
            description: expense.description ?? undefined,
            internalExpenseNumber: expense.internal_number ?? undefined,
            process: expense.process,
            name: expense.name,
            level: expense.level,
            siteId: expense.site_id ?? undefined,
        },
        selectedCostCalculationType,
        fixedCostCalculation:
            selectedCostCalculationType === 'Fixed'
                ? getFixedCostCalculation(expense.cost_components)
                : createDefaultExpenseFormValues(preferredCurrency).fixedCostCalculation,
        linearCostCalculation:
            selectedCostCalculationType === 'Linear'
                ? getLinearCostCalculation(expense.cost_components)
                : createDefaultExpenseFormValues(preferredCurrency).linearCostCalculation,
        formulaCostCalculation:
            selectedCostCalculationType === 'Formula'
                ? getFormulaCostCalculation(expense.cost_components)
                : createDefaultExpenseFormValues(preferredCurrency).formulaCostCalculation,
        expenseCurrency: preferredCurrency,
    };
};
