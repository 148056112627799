/* eslint-disable @typescript-eslint/consistent-type-assertions */

// This is a simplified version of LexoRank, a ranking system used by Atlassian in Jira.
// In constrast to the original implementation, we do not support buckets and only use
// numbers as rank values.
// For overview see: https://www.youtube.com/watch?v=OjQv9xMoFbg
// For implementation hints see: https://medium.com/whisperarts/lexorank-what-are-they-and-how-to-use-them-for-efficient-list-sorting-a48fc4e7849f

import { logToExternalErrorHandlers } from '../errors';

export type LexoRank = string;
export const MIN_DECIMAL = 1;
export const MIN: LexoRank = convertToLexoRank(MIN_DECIMAL);
export const MAX_DECIMAL = 999999999999999;
export const MAX: LexoRank = convertToLexoRank(MAX_DECIMAL);
const INITIAL_LEXORANK = between(MIN, MAX);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
function isValidLexoRank(lexoRank: any): lexoRank is LexoRank {
    if (typeof lexoRank !== 'string') {
        return false;
    }

    const regex = /^[1-9][0-9]*$/;
    if (regex.test(lexoRank)) {
        const value = convertToDecimal(lexoRank);
        if (value <= MAX_DECIMAL && value >= MIN_DECIMAL) {
            return true;
        }
    }

    logToExternalErrorHandlers(new Error(`Invalid lexoRank found: ${lexoRank}`));
    return false;
}

export function beforeFirstLexoRank(firstLexoRank: LexoRank): LexoRank | undefined {
    return between(MIN, firstLexoRank);
}

export function afterLastLexoRank(lastLexoRank: LexoRank): LexoRank | undefined {
    return between(lastLexoRank, MAX);
}

function between(lower: LexoRank, upper: LexoRank): LexoRank | undefined {
    if (!isValidLexoRank(lower) || !isValidLexoRank(upper)) {
        throw new Error(`Invalid lexoRanks: ${lower}, ${upper}`);
    }

    const lowerRankDecimalValue = convertToDecimal(lower);
    const upperRankDecimalValue = convertToDecimal(upper);
    const difference = upperRankDecimalValue - lowerRankDecimalValue;

    if (difference <= 1) {
        const error = new Error(`Not enough space to compute lexorank between ${lower} and ${upper}`);
        logToExternalErrorHandlers(error, { level: 'warning' });
        return undefined;
    }

    const newRankDecimalValue = lowerRankDecimalValue + Math.floor(difference / 2);

    return convertToLexoRank(newRankDecimalValue);
}

function convertToDecimal(lexoRank: LexoRank): number {
    const decimal = parseInt(lexoRank);

    if (isNaN(decimal)) {
        throw new Error(`Invalid lexoRank found: ${lexoRank}`);
    }

    return decimal;
}

function convertToLexoRank(decimalValue: number): LexoRank {
    return Math.floor(decimalValue).toString() as LexoRank;
}

export interface HasLexoRank {
    lexorank: LexoRank;
}

function balance<T extends HasLexoRank>(items: T[]): T[] {
    const lexoRankSpace = LexoRanking.convertToDecimal(MAX) - LexoRanking.convertToDecimal(MIN);
    const stepCount = items.length + 1;
    const stepSize = Math.floor(lexoRankSpace / stepCount);

    return items.map((item, index) => {
        const decimalValue = MIN_DECIMAL + stepSize * (index + 1);
        const lexoRank = LexoRanking.convertToLexoRank(decimalValue);
        return { ...item, lexorank: lexoRank };
    });
}

export const LexoRanking = {
    isValidLexoRank,
    between,
    convertToDecimal,
    convertToLexoRank,
    beforeFirstLexoRank,
    afterLastLexoRank,
    balance,
    MIN,
    MAX,
    INITIAL_LEXORANK,
};
