import { Parser } from '../parsers/Parser';
import { NumberProps, number } from '../parsers/number';

/**
 * A parser that parses a lead time such as how long it takes to deliver an item.
 */
export function leadTime({ decimalSeparator }: Pick<NumberProps, 'decimalSeparator'>): Parser<number> {
    return number({
        decimalSeparator,
        errorIf: {
            // a lead time can't be negative
            isNegative: true,
        },
        warnIf: {
            // a lead time can be a float, but it's unusual
            isFloat: true,
        },
    });
}
