import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { AssemblyTypePublic } from '@luminovo/http-client';

type AssemblyTypePublicTranslations = Record<AssemblyTypePublic, MessageDescriptor>;
type BomTypeTranslation = { ['BOM']: MessageDescriptor };
type AssemblyTypeTranslations = AssemblyTypePublicTranslations & BomTypeTranslation;

export const assemblyTypePublicTranslations: AssemblyTypePublicTranslations = {
    Pcba: defineMessage({ message: 'PCBA' }),
    Cable: defineMessage({ message: 'Cable' }),
    Box: defineMessage({ message: 'Box build' }),
    System: defineMessage({ message: 'System' }),
    PcbOnly: defineMessage({ message: 'PCB' }),
};

const bomTypeTranslation: BomTypeTranslation = { BOM: defineMessage({ message: 'BOM' }) };
// eslint-disable-next-line import/no-unused-modules
export const assemblyTypeTranslations: AssemblyTypeTranslations = {
    ...assemblyTypePublicTranslations,
    ...bomTypeTranslation,
};

export const rfqWarningTranslations = {
    CouldNotDuplicateAllPcbs: defineMessage({
        // eslint-disable-next-line spellcheck/spell-checker
        message: 'Some PCBs could not be duplicated. Please check the duplicated assembly for missing PCBs.',
    }),
};
