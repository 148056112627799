import { Flexbox } from '@luminovo/design-system';
import { BomItemIssue, QuantityUnit } from '@luminovo/http-client';
import { useReducer } from 'react';
import { useFormContext } from 'react-hook-form';
import { BomItemTableData } from '../../../Bom/components/ModuleTableData';
import { BomItemFormState } from '../BomItemFormState';
import { FormItemHeader } from '../FormItemHeader';
import { DesignatorsField } from './fields/DesignatorsField';
import { QuantityField } from './fields/QuantityField';
import { EditBomItemHeaderState, reducer } from './reducer';
import { validateDesignator } from './validators/validateDesignator';
import { validateQuantity } from './validators/validateQuantity';
import { validateQuantityUnit } from './validators/validateQuantityUnit';

function findInitialState({
    values,
    siblingDesignators,
    isDesignatorAndQuantityUnclear,
}: {
    values: BomItemFormState;
    siblingDesignators: string[];
    isDesignatorAndQuantityUnclear: boolean;
}): EditBomItemHeaderState {
    // Case where quantity is not extracted - we automatically set qty to 1 and unit to pieces and ask the user to confirm.
    if (isDesignatorAndQuantityUnclear && !values.quantity) {
        return {
            designators: values.designator,
            quantityValue: 1,
            quantityUnit: QuantityUnit.Pieces,
            errors: {
                designators: { isError: true, message: '' },
                designatorsCount: { isError: true, message: '' },
                quantityValue: { isError: false, message: '' },
                quantityUnit: { isError: false, message: '' },
            },
        };
    }
    const validatedDesignators = validateDesignator({ designators: values.designator, siblingDesignators });
    const validatedQty = validateQuantity({ value: values.quantity?.quantity });
    const validatedQtyUnit = validateQuantityUnit({ value: values.quantity?.unit });
    return {
        designators: values.designator,
        quantityValue: values.quantity?.quantity ?? null,
        quantityUnit: values.quantity?.unit ?? null,
        errors: {
            designators: validatedDesignators,
            designatorsCount: { isError: false, message: '' },
            quantityValue: validatedQty,
            quantityUnit: validatedQtyUnit,
        },
    };
}

export const DesignatorsAndQtyEditForm = ({
    bomItem,
    siblingDesignators,
    submitForm,
    setIsHeaderEditable,
}: {
    bomItem: BomItemTableData;
    siblingDesignators: string[];
    submitForm: () => void;
    setIsHeaderEditable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { watch } = useFormContext<BomItemFormState>();
    const values = watch();

    const initialState: EditBomItemHeaderState = findInitialState({
        values,
        siblingDesignators,
        isDesignatorAndQuantityUnclear: bomItem.issues.includes(BomItemIssue.DesignatorAndQuantityUnclear),
    });

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Flexbox flexDirection="column" gap="12px">
            <Flexbox justifyContent="space-between" alignItems="flex-start">
                <FormItemHeader
                    bomItem={bomItem}
                    state={state}
                    submitForm={submitForm}
                    setIsHeaderEditable={setIsHeaderEditable}
                />
            </Flexbox>
            <Flexbox gap="32px">
                <DesignatorsField state={state} dispatch={dispatch} siblingDesignators={siblingDesignators} />
                <QuantityField state={state} dispatch={dispatch} />
            </Flexbox>
        </Flexbox>
    );
};
