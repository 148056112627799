import { Flexbox, TertiaryIconButton } from '@luminovo/design-system';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export interface UpDownButtonsProps {
    isLoading: boolean;
    isDisabledFirstRow: boolean;
    isDisabledLastRow: boolean;
    onClickUp: () => void;
    onClickDown: () => void;
}

export const UpDownButtons = ({
    isLoading,
    isDisabledFirstRow,
    isDisabledLastRow,
    onClickUp,
    onClickDown,
}: UpDownButtonsProps): JSX.Element => {
    return (
        <Flexbox flexDirection="row" alignContent={'center'} justifyContent={'center'}>
            {isLoading && <CircularProgress size={12} />}
            {!isLoading && (
                <>
                    <TertiaryIconButton disabled={isDisabledFirstRow} size="medium" onClick={onClickUp}>
                        <ArrowDropUp fontSize="inherit" />
                    </TertiaryIconButton>
                    <TertiaryIconButton disabled={isDisabledLastRow} size="medium" onClick={onClickDown}>
                        <ArrowDropDown fontSize="inherit" />
                    </TertiaryIconButton>
                </>
            )}
        </Flexbox>
    );
};
