import { transEnum } from '@luminovo/commons';
import {
    PreferredTag,
    PreferredTagEnum,
    preferredTagTooltipTranslations,
    preferredTagTranslations,
} from '@luminovo/sourcing-core';

const findPreferences = (part: { is_manufacturer_preferred?: boolean; is_preferred?: boolean }) => {
    const arr = [];
    if (part.is_manufacturer_preferred) {
        arr.push(PreferredTagEnum.Manufacturer);
    }
    if (part.is_preferred) {
        arr.push(PreferredTagEnum.Ipn);
    }
    return arr;
};

export const PreferredTags = ({ part }: { part: { is_manufacturer_preferred?: boolean; is_preferred?: boolean } }) => {
    const preferences = findPreferences(part);
    if (preferences.length > 1) {
        const tooltip = preferences
            .map((preference) => transEnum(preference, preferredTagTooltipTranslations))
            .join(', ');
        return <PreferredTag label={String(preferences.length)} tooltip={tooltip} />;
    }
    if (preferences.length === 1) {
        return (
            <PreferredTag
                label={transEnum(preferences[0], preferredTagTranslations)}
                tooltip={transEnum(preferences[0], preferredTagTooltipTranslations)}
            />
        );
    }
    return null;
};
