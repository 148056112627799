import { colorSystem, Flexbox, FlipIcon, TertiaryIconButton } from '@luminovo/design-system';
import {
    FilterCenterFocusRounded as CenterIcon,
    StraightenRounded as MeasureIcon,
    AddRounded as ZoomInIcon,
    RemoveRounded as ZoomOutIcon,
} from '@mui/icons-material';
import React from 'react';
import { SvgImageCursor } from '../utils/useSVGImageEventHandler';

export type SVGControlOptions = 'center' | 'zoomIn' | 'zoomOut' | 'flip' | 'measure';

export const SvgImageControl: React.FunctionComponent<{
    controlOptions: SVGControlOptions[];
    onCenterClick: () => void;
    onZoomInClick: () => void;
    onZoomOutClick: () => void;
    onFlipClick: () => void;
    onMeasureClick: () => void;
    cursor: SvgImageCursor;
}> = ({ onCenterClick, onZoomInClick, onZoomOutClick, onFlipClick, controlOptions, cursor, onMeasureClick }) => {
    return (
        <Flexbox
            style={{
                backgroundColor: colorSystem.neutral.white,
                padding: '8px 16px',
                borderRadius: '8px',
                boxShadow: '0px 2px 4px 0px rgba(82, 129, 183, 0.08)',
                color: colorSystem.neutral[7],
                gap: '16px',
                fontSize: '16px',
            }}
        >
            {controlOptions.includes('measure') && (
                <TertiaryIconButton
                    onClick={onMeasureClick}
                    style={cursor === 'crosshair' ? { color: colorSystem.primary[7] } : undefined}
                    disableRipple
                >
                    <MeasureIcon fontSize="inherit" />
                </TertiaryIconButton>
            )}
            {controlOptions.includes('center') && (
                <TertiaryIconButton onClick={onCenterClick} disableRipple>
                    <CenterIcon fontSize="inherit" />
                </TertiaryIconButton>
            )}
            {controlOptions.includes('zoomIn') && (
                <TertiaryIconButton onClick={onZoomInClick} disableRipple>
                    <ZoomInIcon fontSize="inherit" />
                </TertiaryIconButton>
            )}
            {controlOptions.includes('zoomOut') && (
                <TertiaryIconButton onClick={onZoomOutClick} disableRipple>
                    <ZoomOutIcon fontSize="inherit" />
                </TertiaryIconButton>
            )}
            {controlOptions.includes('flip') && (
                <TertiaryIconButton onClick={onFlipClick} disableRipple>
                    <FlipIcon fontSize="inherit" />
                </TertiaryIconButton>
            )}
        </Flexbox>
    );
};
