import { t } from '@lingui/macro';
import { SpinningIcon } from '@luminovo/design-system';
import { Sync } from '@mui/icons-material';
import { Tooltip, TooltipProps } from '@mui/material';
import * as React from 'react';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useForcedOfferUpdate } from '../../resources/rfq/rfqHandler';
import { id } from '../../utils/ids';
import { sleep } from '../../utils/sleep';
import { NavbarTertiaryIconButton } from './NavbarTertiaryIconButton';

export function GlobalUpdateOffersButton({ rfqId }: { rfqId: string }) {
    const [isOffersUpToDate, setOffersUpToDate] = React.useState(false);
    const { mutateAsync, isLoading: isUpdatingOffers } = useForcedOfferUpdate(rfqId);

    const { data: progressResponse, isLoading: isLoadingRemainingOffers } = useHttpQuery(
        'GET /sourcing/progress/:rfqId',
        {
            pathParams: { rfqId },
        },
        {
            refetchInterval: (res) => {
                if (!res) {
                    return 5_000;
                }
                const { total } = res;
                // if there are offers, refetch at a faster rate
                if (total > 0) {
                    return 5_000;
                }
                return 60_000;
            },
            meta: { globalErrorHandler: false },
        },
    );

    const remainingOffers = progressResponse?.total;
    const isLoading = isUpdatingOffers || isLoadingRemainingOffers || (remainingOffers ?? 0) > 0;
    return (
        <InnerGlobalUpdateOffersButton
            isOffersUpToDate={isOffersUpToDate}
            remainingOffers={remainingOffers}
            isLoading={isLoading}
            onClick={async () => {
                setOffersUpToDate(true);
                await mutateAsync();
                // Let the offers be up to date for at least 2 seconds.
                await sleep(2000);
                setOffersUpToDate(false);
            }}
        />
    );
}

function InnerGlobalUpdateOffersButton({
    isOffersUpToDate,
    remainingOffers,
    isLoading,
    onClick,
}: {
    isOffersUpToDate: boolean;
    remainingOffers?: number;
    isLoading: boolean;
    onClick: () => void;
}) {
    const [isMouseOver, setMouseOver] = React.useState(false);

    const tooltipProps = (): Omit<TooltipProps, 'children'> => {
        if (isOffersUpToDate) {
            return { title: t`All offers for this request are up-to-date`, open: true };
        }
        if (remainingOffers !== undefined && remainingOffers > 0) {
            return { title: t`Checking ${remainingOffers} offers for updates`, open: isMouseOver };
        }
        if (isLoading) {
            return { title: t`Updating offers...`, open: isMouseOver };
        }

        return { title: t`Update API offers`, open: isMouseOver };
    };

    return (
        <Tooltip
            onMouseEnter={() => {
                setMouseOver(true);
            }}
            onMouseLeave={() => {
                setMouseOver(false);
            }}
            disableHoverListener
            {...tooltipProps()}
        >
            <span>
                <NavbarTertiaryIconButton
                    id={id('navbar/button_update_offers')}
                    variant={'light'}
                    disabled={isLoading}
                    style={{ margin: '12px', marginRight: '0', height: '20px', width: '20px' }}
                    onClick={() => {
                        if (!isLoading) {
                            onClick();
                        }
                    }}
                >
                    {isLoading && !isOffersUpToDate ? <SpinningIcon fontSize="inherit" /> : <Sync fontSize="inherit" />}
                </NavbarTertiaryIconButton>
            </span>
        </Tooltip>
    );
}
