import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    FormulaPostRuntype,
    FormulaResponseRuntype,
    FormulaTestPostRuntype,
    FormulaTestResponseRuntype,
} from './formulaBackendTypes';

export const formulaEndpoints = {
    'POST /formula/validate': endpoint({
        description: 'Validates a formula',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FormulaPostRuntype,
        responseBody: FormulaResponseRuntype,
    }),

    'POST /formula/test': endpoint({
        description: 'Runs a formula with provided values',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FormulaTestPostRuntype,
        responseBody: FormulaTestResponseRuntype,
    }),
};
