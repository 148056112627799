import { t } from '@lingui/macro';
import { FieldController, FieldText, FormItem } from '@luminovo/design-system';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>
    extends UseControllerProps<TFieldValues, TName> {
    currentRfqName?: string;
}

export function RfqNameFormItem<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    name,
    control,
    currentRfqName,
}: Props<TFieldValues, TName>): JSX.Element {
    const validate = (rfqName: unknown) => validateRfqName(rfqName, currentRfqName);

    return (
        <FormItem label={t`RfQ name`} required>
            <FieldController
                control={control}
                name={name}
                Field={FieldText}
                validate={validate}
                FieldProps={{ placeholder: t`RfQ name`, size: 'large' }}
            />
        </FormItem>
    );
}

const validateRfqName = (rfqName: unknown, currentRfqName: string | undefined): string | undefined => {
    if (!rfqName) {
        return t`Required`;
    }

    if (typeof rfqName !== 'string') {
        return t`Must be a string`;
    }

    if (rfqName.length < 2) {
        return t`Must have at least 2 characters`;
    }

    if (rfqName.length > 50) {
        return t`Must have at most 50 characters`;
    }

    if (rfqName === currentRfqName) {
        return;
    }
};
