import { Trans } from '@lingui/macro';
import { SecondaryButton, TertiaryIconButton } from '@luminovo/design-system';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';

/**
 * Note: this component doesn't navigate back, it just navigates to the specified href.
 */
export function BackButton({ href, variant = 'icon' }: { href: string; variant?: 'icon' | 'button' }): JSX.Element {
    const history = useHistory();
    const onClick = () => {
        //checks if history exists https://stackoverflow.com/a/66137051
        if (history.action !== 'POP') {
            history.goBack();
        } else {
            /* I would expect after this state that the user should click on foward to
             go to the previous page. But this is not the case. We can artificaly add
            the history to the history stack. But this could cause problems with analytics.
            history.push(history.location.pathname) */
            history.push(href);
        }
    };
    if (variant === 'icon') {
        return (
            <TertiaryIconButton size="medium" onClick={onClick}>
                <ArrowBack fontSize="inherit" />
            </TertiaryIconButton>
        );
    } else {
        return (
            <SecondaryButton size="medium" onClick={onClick} startIcon={<ArrowBack />} style={{ width: 'fit-content' }}>
                <Trans>Back</Trans>
            </SecondaryButton>
        );
    }
}
