import * as r from 'runtypes';
import { ActivityLevel } from './activity/activityBackendTypes';

export type TimeCalculationTypes = ActivityLevel;

const CalculationTypeRuntype = r.Union(r.Literal('Fixed'), r.Literal('Linear'), r.Literal('Formula'));

export type CalculationType = r.Static<typeof CalculationTypeRuntype>;

export type CalculationTypeWithoutFormula = Exclude<CalculationType, 'Formula'>;

const CalculationTypeWithNoneRuntype = r.Union(
    r.Literal('Fixed'),
    r.Literal('Linear'),
    r.Literal('None'),
    r.Literal('Formula'),
);

export type CalculationTypeWithNone = r.Static<typeof CalculationTypeWithNoneRuntype>;

export const CALCULATION_TYPE = CalculationTypeRuntype.alternatives.map((alternative) => alternative.value);
export const CALCULATION_TYPE_WITH_NULL = CalculationTypeWithNoneRuntype.alternatives.map(
    (alternative) => alternative.value,
);

//this order is used for sorting  so be careful when changing https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3074457359358058617&cot=10
export const ProcessRuntype = r.Union(
    r.Literal('ProcessPreparation'),
    r.Literal('NPI'),
    r.Literal('SMT'),
    r.Literal('THT'),
    r.Literal('HandSoldering'),
    r.Literal('PanelSeparation'),
    r.Literal('Potting'),
    r.Literal('Ruggedizing'),
    r.Literal('Testing'),
    r.Literal('Inspection'),
    r.Literal('CableAssembly'),
    r.Literal('MechanicalAssembly'),
    r.Literal('QualityControl'),
    r.Literal('Packaging'),
    r.Literal('Logistics'),
    r.Literal('Other'),
);
export const ALL_PROCESS_SELECTION_OPTIONS = ProcessRuntype.alternatives.map((alternative) => alternative.value);

export type ProcessSelection = r.Static<typeof ProcessRuntype>;

// this order is used for sorting  so be careful when changing https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3074457360925602799&cot=10
export const CategoryRuntype = r.Union(
    r.Literal('Preparation'),
    r.Literal('Execution'),
    r.Literal('Inspection'),
    r.Literal('PostProcessing'),
);

export const ALL_CATEGORY_SELECTION_OPTIONS = CategoryRuntype.alternatives.map((alternative) => alternative.value);
export type CategorySelection = r.Static<typeof CategoryRuntype>;

export const ManufacturingEntityStatusRuntype = r.Union(r.Literal('Active'), r.Literal('Inactive'));
const ManufacturingEntityStatusWithErrorRuntype = r.Union(ManufacturingEntityStatusRuntype, r.Literal('Error'));
export type ManufacturingEntityStatusWithError = r.Static<typeof ManufacturingEntityStatusWithErrorRuntype>;

export type ManufacturingEntityStatusSelection = r.Static<typeof ManufacturingEntityStatusRuntype>;
