/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type ArrowLocaleCurrencyDTO = r.Static<typeof ArrowLocaleCurrencyDTORuntype>;
export const ArrowLocaleCurrencyDTORuntype = r.Union(
    r.Literal('USD'),
    r.Literal('GBP'),
    r.Literal('EUR'),
    r.Literal('JPY'),
    r.Literal('SGD'),
    r.Literal('AUD'),
    r.Literal('CAD'),
    r.Literal('HKD'),
    r.Literal('MYR'),
    r.Literal('KRW'),
    r.Literal('BRL'),
    r.Literal('CNY'),
    r.Literal('INR'),
    r.Literal('MXN'),
    r.Literal('TWD'),
);

export interface ArrowCredentialsDTO extends r.Static<typeof ArrowCredentialsDTORuntype> {}
export const ArrowCredentialsDTORuntype = r.Union(
    r.Record({
        login: r.String,
        api_key: r.String,
        currency: ArrowLocaleCurrencyDTORuntype,
    }),
);

export interface ArrowCredentialsInputDTO extends r.Static<typeof ArrowCredentialsInputDTORuntype> {}
export const ArrowCredentialsInputDTORuntype = r.Record({
    login: r.String.optional(),
    api_key: r.String.optional(),
    currency: ArrowLocaleCurrencyDTORuntype.optional(),
});

export interface ArrowResponseBodyDTO extends r.Static<typeof ArrowResponseBodyDTORuntype> {}
export const ArrowResponseBodyDTORuntype = r.Record({
    data: ArrowCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
