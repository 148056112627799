import { t, Trans } from '@lingui/macro';
import {
    ButtonGroup,
    ButtonGroupItem,
    colorSystem,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SecondaryButton,
    Text,
    Toolbar,
    usePersistedState,
} from '@luminovo/design-system';
import { RfqStatus, WorkflowType } from '@luminovo/http-client';
import { Add, LocalOfferOutlined, Search } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { LayoutCard } from '../../../../components/LayoutCard';
import { PageLayout } from '../../../../components/PageLayout';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../../utils/rfqUtils';
import { route, UrlParams } from '../../../../utils/routes';
import { useWarnIfSuppliersNotConfigured } from '../../../../utils/useWarnIfSuppliersNotConfigured';
import { ExportCostedBomSplitButton } from '../ExportCostedBom';
import { GroupedSourcingScenarioTable } from './GroupedSourcingScenarioTable/GroupedSourcingScenarioTable';
import { SourcingScenarioTableSharedContext } from './GroupedSourcingScenarioTable/utils/types';
import { QuoteRequestCard } from './QuoteRequestCard';
import { VersionHistoryCard } from './VersionHistoryCard';

const NoAssembliesFound: React.FunctionComponent<{
    rfqId: string;
    rfqStatus: RfqStatus;
    rfqIsArchived: boolean;
    rfqWorkflowType: WorkflowType;
}> = ({ rfqId, rfqStatus, rfqIsArchived, rfqWorkflowType }) => {
    const { isRfqEditable } = useIsRfqEditable(rfqStatus, rfqIsArchived, rfqWorkflowType);
    const href = route(
        '/rfqs/:rfqId/bom/assembly/add-assembly',
        {
            rfqId,
        },
        { assemblyId: rfqId },
    );

    return (
        <NonIdealState
            Icon={Search}
            title={t`No assemblies`}
            description={t`No assemblies found. Please add assemblies to add sourcing scenarios.`}
            action={
                isRfqEditable
                    ? {
                          href,
                          startIcon: <Add />,
                          children: <Trans>Add assembly</Trans>,
                      }
                    : undefined
            }
        />
    );
};

export const NoSourcingScenario: React.FunctionComponent<{
    rfqId: string;
}> = ({ rfqId }) => {
    return (
        <NonIdealState
            Icon={LocalOfferOutlined}
            title={t`No sourcing scenarios`}
            description={
                <Trans>
                    Sourcing scenarios let you define order sizes and specify various solution preferences to source
                    your BOM.
                </Trans>
            }
            action={{
                startIcon: <Add />,
                href: route('/rfqs/:rfqId/sourcing/scenarios/new', { rfqId }),
                children: <Trans>Add sourcing scenario</Trans>,
            }}
            overrides={{ ActionButton: PrimaryButton }}
        />
    );
};

const AddSourcingScenarioButton: React.FunctionComponent<{
    sharedContext: SourcingScenarioTableSharedContext;
}> = ({ sharedContext }) => {
    return (
        <SecondaryButton
            size="medium"
            startIcon={<Add />}
            href={route(`/rfqs/:rfqId/sourcing/scenarios/new`, { rfqId: sharedContext.rfqId })}
        >
            <Trans>Add scenario</Trans>
        </SecondaryButton>
    );
};

const SourcingPage = ({ pathParams }: UrlParams<'/rfqs/:rfqId/sourcing'>): JSX.Element => {
    useWarnIfSuppliersNotConfigured();

    const rfqId = pathParams.rfqId;
    const { data: rfq, isLoading } = useRfQ(rfqId);

    const [sourcingScenarioTablePreference, setSourcingScenarioTablePreference] = usePersistedState<
        'scenario' | 'assembly'
    >('sourcing/sourcing-scenario-table-preference', 'scenario', localStorage);

    if (isLoading || !rfq) {
        return (
            <PageLayout layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    const numberOfAssemblies = rfq.assemblies_count;
    if (numberOfAssemblies === 0) {
        return (
            <PageLayout layout="centered">
                <NoAssembliesFound
                    rfqId={rfqId}
                    rfqStatus={rfq.status}
                    rfqIsArchived={rfq.is_archived}
                    rfqWorkflowType={rfq.workflow_type}
                />
            </PageLayout>
        );
    }

    const sourcingScenarioIds = rfq.sourcing_scenarios;
    if (sourcingScenarioIds.length === 0) {
        return (
            <PageLayout layout="centered">
                <NoSourcingScenario rfqId={rfqId} />
            </PageLayout>
        );
    }

    return (
        <PageLayout
            header={
                <Toolbar
                    breadcrumbs={[
                        {
                            title: t`Sourcing`,
                            href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                        },
                    ]}
                >
                    <ExportCostedBomSplitButton rfqId={rfqId} size={'medium'} />
                </Toolbar>
            }
            style={{ background: colorSystem.neutral[1] }}
            layout="max-width"
            removeHubspotPaddingFix={true}
        >
            <Flexbox flexDirection={'column'} gap={'20px'}>
                <LayoutCard
                    title={
                        <Flexbox gap={'16px'} alignItems={'center'}>
                            <Text variant="h2">
                                <Trans>Sourcing scenarios</Trans>
                            </Text>
                            <ButtonGroup size="small">
                                <ButtonGroupItem
                                    selected={sourcingScenarioTablePreference === 'scenario'}
                                    onClick={() => setSourcingScenarioTablePreference('scenario')}
                                >
                                    <Trans>Scenario view</Trans>
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    selected={sourcingScenarioTablePreference === 'assembly'}
                                    onClick={() => setSourcingScenarioTablePreference('assembly')}
                                >
                                    <Trans>Assembly view</Trans>
                                </ButtonGroupItem>
                            </ButtonGroup>
                        </Flexbox>
                    }
                >
                    <Box height={'38vh'}>
                        <GroupedSourcingScenarioTable
                            rfqId={rfqId}
                            sourcingScenarioIds={sourcingScenarioIds}
                            columnsKey={'sourcing-page'}
                            ActionButton={AddSourcingScenarioButton}
                            preferredView={sourcingScenarioTablePreference}
                        />
                    </Box>
                </LayoutCard>

                <Flexbox gap={'20px'} height={'40vh'}>
                    <QuoteRequestCard rfqId={rfqId} />
                    <VersionHistoryCard rfqId={rfqId} />
                </Flexbox>
            </Flexbox>
        </PageLayout>
    );
};

export default SourcingPage;
