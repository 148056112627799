import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { BlumeCredentialsInputDTORuntype, BlumeResponseBodyDTORuntype } from './blumeBackendTypes';

export const blumeEndpoints = {
    'GET /3rdparty/blume/credentials': endpoint({
        description: 'Returns credentials for blume integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: BlumeResponseBodyDTORuntype,
    }),
    'POST /3rdparty/blume/credentials': endpoint({
        description: 'Uploads credentials for blume API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: BlumeCredentialsInputDTORuntype,
        responseBody: BlumeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
    'PATCH /3rdparty/blume/credentials': endpoint({
        description: 'Updates credentials for blume API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: BlumeCredentialsInputDTORuntype,
        responseBody: BlumeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
    'DELETE /3rdparty/blume/credentials': endpoint({
        description: 'Deletes existing credentials for blume API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
};
