export const PdfFileImage = (props: { color: string }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.99992 1.66675C4.08325 1.66675 3.34159 2.41675 3.34159 3.33341L3.33325 16.6667C3.33325 17.5834 4.07492 18.3334 4.99158 18.3334H14.9999C15.9166 18.3334 16.6666 17.5834 16.6666 16.6667V7.35842C16.6666 6.91675 16.4916 6.49175 16.1749 6.18341L12.1499 2.15841C11.8416 1.84175 11.4166 1.66675 10.9749 1.66675H4.99992ZM10.8333 6.66675V2.91675L15.4166 7.50008H11.6666C11.2083 7.50008 10.8333 7.12508 10.8333 6.66675Z"
            fill={props.color}
        />
        <path
            d="M5 16V12H6.22817C6.42555 12 6.61014 12.0225 6.78194 12.0675C6.95374 12.1084 7.1036 12.18 7.23154 12.2822C7.35947 12.3804 7.45999 12.5133 7.5331 12.681C7.60986 12.8446 7.64824 13.047 7.64824 13.2883C7.64824 13.5215 7.60986 13.7239 7.5331 13.8957C7.45999 14.0675 7.35947 14.2086 7.23154 14.319C7.1036 14.4294 6.95557 14.5112 6.78742 14.5644C6.61928 14.6176 6.44017 14.6442 6.2501 14.6442H5.80599V16H5ZM5.80599 13.9264H6.20075C6.63939 13.9264 6.8587 13.7137 6.8587 13.2883C6.8587 13.0798 6.80022 12.9325 6.68325 12.8466C6.56628 12.7607 6.39814 12.7178 6.17882 12.7178H5.80599V13.9264Z"
            fill="white"
        />
        <path
            d="M8.26618 16V12H9.27503C9.54917 12 9.79591 12.0389 10.0152 12.1166C10.2345 12.1943 10.4228 12.3149 10.58 12.4785C10.7371 12.638 10.8578 12.8425 10.9418 13.092C11.0259 13.3415 11.0679 13.638 11.0679 13.9816C11.0679 14.3252 11.0259 14.6237 10.9418 14.8773C10.8578 15.1309 10.739 15.3415 10.5854 15.5092C10.4319 15.6728 10.2473 15.7955 10.0317 15.8773C9.81966 15.9591 9.58207 16 9.31889 16H8.26618ZM9.07216 15.2699H9.22568C9.3792 15.2699 9.51811 15.2474 9.64238 15.2025C9.76666 15.1575 9.87267 15.0859 9.96039 14.9877C10.0481 14.8855 10.1157 14.7526 10.1633 14.589C10.2144 14.4254 10.24 14.2229 10.24 13.9816C10.24 13.7444 10.2144 13.546 10.1633 13.3865C10.1157 13.2229 10.0481 13.0941 9.96039 13C9.87267 12.9018 9.76666 12.8323 9.64238 12.7914C9.51811 12.7505 9.3792 12.7301 9.22568 12.7301H9.07216V15.2699Z"
            fill="white"
        />
        <path d="M11.7465 16V12H14V12.7607H12.5525V13.6871H13.7916V14.4479H12.5525V16H11.7465Z" fill="white" />
    </svg>
);
