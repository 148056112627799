import { Timeline } from '@mui/lab';
import TimelineDot from '@mui/lab/TimelineDot';
import Skeleton from '@mui/material/Skeleton';
import { TimelineContentContainer, TimelineItemWrapper } from './Utils';

export default function LoadingTimeline() {
    return (
        <Timeline>
            <TimelineItemWrapper
                timelineIcon={
                    <TimelineDot>
                        <div style={{ width: '24px', height: '24px' }} />
                    </TimelineDot>
                }
                isLastItem={false}
            >
                <TimelineContentContainer>
                    <Skeleton variant="text" width={400} height={24} />
                    <Skeleton variant="text" width={50} height={24} />
                </TimelineContentContainer>
            </TimelineItemWrapper>
            <TimelineItemWrapper
                timelineIcon={
                    <TimelineDot>
                        <div style={{ width: '24px', height: '24px' }} />
                    </TimelineDot>
                }
                isLastItem={false}
            >
                <TimelineContentContainer>
                    <Skeleton variant="text" width={400} height={24} />
                    <Skeleton variant="text" width={50} height={24} />
                </TimelineContentContainer>
            </TimelineItemWrapper>
            <TimelineItemWrapper
                timelineIcon={
                    <TimelineDot>
                        <div style={{ width: '24px', height: '24px' }} />
                    </TimelineDot>
                }
                isLastItem={true}
            >
                <TimelineContentContainer>
                    <Skeleton variant="text" width={400} height={24} />
                    <Skeleton variant="text" width={50} height={24} />
                </TimelineContentContainer>
            </TimelineItemWrapper>
        </Timeline>
    );
}
