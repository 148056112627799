import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { ExpenseDTO } from '@luminovo/http-client';
import { PageLayout } from '../../../components/PageLayout';
import { ErrorText, SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { UrlParams } from '../../../utils/routes';
import { ExpenseForm, getExistingExpenseCurrency } from './ExpenseForm';
import { convertExpenseDTOToFormValues } from './utils/convertExpenseFormValues';
import { createExpenseSchema } from './utils/expenseFormValidationSchema';

const EditExpenseForm = ({ expenseId }: { expenseId: string }): JSX.Element => {
    const { data: drivers } = useHttpQuery('GET /user-drivers', {});
    const { data: expenses } = useHttpQuery('GET /expenses', { queryParams: {} });
    if (drivers && expenses) {
        const expense: ExpenseDTO | undefined = expenses.data.find((expense) => expense.id === expenseId);

        if (!expense) {
            return <ErrorText text={t`Expense not found`} />;
        }

        const existingExpensesCurrency: Currency = getExistingExpenseCurrency(expense.cost_components);

        const filteredExpenses = expenses.data.filter((e) => e.id !== expenseId);

        return (
            <ExpenseForm
                schema={createExpenseSchema(filteredExpenses, drivers.data)}
                defaultValues={convertExpenseDTOToFormValues({
                    expense,
                    preferredCurrency: existingExpensesCurrency,
                })}
                onSubmitType={'PATCH'}
                formTitle={t`Edit expense`}
            />
        );
    }
    return <SpinnerWithBackdrop />;
};

export const EditExpensePage = ({ pathParams }: UrlParams<'/manufacturing/expense/:expenseId/edit'>): JSX.Element => {
    return (
        <PageLayout layout="full-width">
            <EditExpenseForm expenseId={pathParams.expenseId} />
        </PageLayout>
    );
};
