import { t } from '@lingui/macro';
import { SecondaryIconButton } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { useDeletePCB } from '../utils/useDeletePCBFiles';

export const DeletePCBFilesButton: React.FunctionComponent<{ pcb: PCBV2; assemblyId: string; rfqId: string }> = ({
    pcb,
    assemblyId,
    rfqId,
}) => {
    const {
        dialog,
        onClick: onDeletePCB,
        isLoading: isDeleteInProgress,
    } = useDeletePCB({
        pcbId: pcb.id,
        assemblyId,
        rfqId,
    });

    return (
        <>
            <Tooltip title={t`Delete PCB specification`} placement="bottom" arrow>
                <SecondaryIconButton size="medium" onClick={() => onDeletePCB()} disabled={isDeleteInProgress}>
                    {isDeleteInProgress ? <CircularProgress size="inherit" /> : <Delete fontSize="inherit" />}
                </SecondaryIconButton>
            </Tooltip>
            {dialog}
        </>
    );
};
