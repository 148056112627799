import { t, Trans } from '@lingui/macro';
import { assertUnreachable, extractAmountFromMonetaryValue, formatCurrency } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { extractExcessMaterial, extractScrapCost, extractTotalPrice } from '@luminovo/sourcing-core';
import { HorizontalStackedBarChart, palette } from '@luminovo/viz';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { ChartSpec, defaultGroupingFunctions } from './ChartSpec';

type Keys = 'cost' | 'excessMaterial' | 'scrapCost' | 'consigned' | 'noOffers';
type Datum = {
    id: string[];
    label: string;
    scrapCost: number;
    cost: number;
    excessMaterial: number;
    totalPrice: number;
    consigned: number;
    noOffers: number;
};
export const chartSpecTotalPrice: ChartSpec<Keys, Datum> = {
    id: 'effectiveTotalPrice',
    title: <Trans>Total price by</Trans>,
    keys: ['cost', 'scrapCost', 'excessMaterial', 'consigned', 'noOffers'],
    aggregate(a, b) {
        return {
            id: a.id.concat(b.id),
            label: a.label,
            consigned: Math.max(a.consigned, b.consigned),
            cost: a.cost + b.cost,
            excessMaterial: a.excessMaterial + b.excessMaterial,
            noOffers: Math.max(a.noOffers, b.noOffers),
            scrapCost: a.scrapCost + b.scrapCost,
            totalPrice: a.totalPrice + b.totalPrice,
        };
    },
    groupBy: defaultGroupingFunctions,
    map(datum, extractLabel) {
        const id = [datum.solutionConfigurationSourcing.id];
        const label = extractLabel(datum);

        if (datum.solutionConfigurationSourcing.is_consigned) {
            return {
                id,
                label,
                scrapCost: 0,
                cost: 0,
                excessMaterial: 0,
                totalPrice: 0,
                consigned: 1,
                noOffers: 0,
            };
        }
        const solution = datum.solution;
        if (!solution) {
            return {
                id,
                label,
                scrapCost: 0,
                cost: 0,
                excessMaterial: 0,
                totalPrice: 0,
                consigned: 0,
                noOffers: 1,
            };
        }
        const scrapCost = extractScrapCost({ solution });
        const totalPrice = extractTotalPrice({ solution }, { source: 'effective' });
        const excessMaterial = extractAmountFromMonetaryValue(extractExcessMaterial({ solution }));

        return {
            id,
            label,
            scrapCost,
            cost: totalPrice - scrapCost - excessMaterial,
            excessMaterial,
            totalPrice,
            consigned: 0,
            noOffers: 0,
        };
    },
    orderBy: chainComparators(
        compareByNumber((a) => -a.totalPrice),
        compareByNumber((d) => d.noOffers),
        compareByNumber((a) => a.consigned),
    ),
    render: ChartTotalPrice,
};

export function ChartTotalPrice({
    rfqId,
    data,
    keys,
    width = 800,
    onSelectDatum,
}: {
    rfqId: string;
    data: Datum[];
    keys: Keys[];
    width?: number;
    onSelectDatum(datum: Datum): void;
}) {
    const { data: rfq } = useRfQ(rfqId);
    const currency = rfq?.currency;

    if (!currency) {
        return null;
    }

    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            isMissingData={(datum, key) => {
                if (key === 'noOffers' && datum.noOffers) {
                    return true;
                }
                if (key === 'consigned' && datum.consigned) {
                    return true;
                }
                return false;
            }}
            getColor={(key) => {
                if (key === 'noOffers') {
                    return palette.error.high;
                }
                if (key === 'consigned') {
                    return palette.ok.high;
                }
                if (key === 'cost') {
                    return palette.default[0];
                }
                if (key === 'excessMaterial') {
                    return palette.other[0];
                }
                if (key === 'scrapCost') {
                    return palette.default[2];
                }
                assertUnreachable(key);
            }}
            formatValue={(x) => formatCurrency(x, currency)}
            formatKey={formatKey}
            onBarClick={onSelectDatum}
            width={width}
        />
    );
}

function formatKey(key: Keys) {
    if (key === 'excessMaterial') {
        return t`Excess material`;
    }
    if (key === 'scrapCost') {
        return t`Scrap`;
    }
    if (key === 'cost') {
        return t`Cost`;
    }
    if (key === 'consigned') {
        return t`Consigned`;
    }
    if (key === 'noOffers') {
        return t`No offers`;
    }
    assertUnreachable(key);
}
