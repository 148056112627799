import * as r from 'runtypes';
import { UserDriverDetailsRuntype } from './driverDetailsBackendTypes';

export const UserDriverRuntype = r.Record({
    type: r.Literal('User'),
    data: UserDriverDetailsRuntype,
});

export type UserDriverDTO = r.Static<typeof UserDriverRuntype>;

export const SystemDriverNameRuntype = r.Union(
    r.Literal('pcb_width'),
    r.Literal('pcb_height'),
    r.Literal('pcb_thickness'),
    r.Literal('panel_factor'),
    r.Literal('panel_width'),
    r.Literal('panel_height'),
    r.Literal('Sides_of_placement'),
);

export type SystemDriverName = r.Static<typeof SystemDriverNameRuntype>;

export const SystemDriverRuntype = r.Record({
    type: r.Literal('System'),
    data: SystemDriverNameRuntype,
});

export type SystemDriverDTO = r.Static<typeof SystemDriverRuntype>;

export const DriverRuntype = r.Union(UserDriverRuntype, SystemDriverRuntype);
export type DriverDTO = r.Static<typeof DriverRuntype>;
