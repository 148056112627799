import { assertUnreachable, formatDecimal, isPresent } from '@luminovo/commons';
import { DriversOfManufacturingDetailsDTO, ManufacturingAssemblyDetailsDTO, StatusDTO } from '@luminovo/http-client';
import {
    CombinedDriverType,
    extractDriverCountStatus,
    selectDriverIdDto,
    selectDriverName,
    selectDriverNotes,
    selectDriverType,
} from '@luminovo/manufacturing-core';
import { useStatusInformationFromDriverStatus } from '../../../../shared/driverStatusInformation';
import { roundTo2DpWithoutZeros } from '../../../../utils/roundTo2DpWithoutZeros';
import { EditDriverCountProps } from '../EditDriverCount/EditDriverCount';
import { useCreateMutationUpdateBasicDriverCount } from '../EditDriverCount/editDriverCountFormFunctions';
import { InconsistentDriverPopupProps } from '../InconsistentDriverPopup';
import { LinkedDriversProps } from '../LinkedDrivers';

export interface DriverRowProps {
    driverType: CombinedDriverType;
    driverName: string;
    driverNotes: string | undefined;
    driverStatus: {
        type: StatusDTO;
        description: string;
    };
    isSourcingDriver: boolean;
    linkedDriversProps: LinkedDriversProps;
    inconsistentDriverPopupProps: InconsistentDriverPopupProps | undefined;
    editDriverCountProps: EditDriverCountProps | undefined;
}

export const useDriverRowProps = (
    rfqId: string,
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO,
    driverDetails: DriversOfManufacturingDetailsDTO,
): DriverRowProps => {
    const driver = driverDetails.driver;

    let status = extractDriverCountStatus(driverDetails.driver_count);

    const { type, description } = useStatusInformationFromDriverStatus(status, rfqId, manufacturingAssemblyDetails);

    const driverRowProps: DriverRowProps = {
        driverType: selectDriverType(driver),
        driverName: selectDriverName(driver),
        driverNotes: selectDriverNotes(driver),
        driverStatus: {
            type: type,
            description: description,
        },
        isSourcingDriver: driverDetails.is_sourcing_driver,
        linkedDriversProps: selectLinkedDriversProps(driverDetails),
        inconsistentDriverPopupProps: selectInconsistentDriverPopupProps(driverDetails),
        editDriverCountProps: useEditDriverCountProps(manufacturingAssemblyDetails.id, driverDetails),
    };

    return driverRowProps;
};

const selectAutomaticCount = (driverDetails: DriversOfManufacturingDetailsDTO) => {
    const { type, data } = driverDetails.driver_count;
    switch (type) {
        case 'Basic':
            return {
                automaticCount: data.automatic_count ?? undefined,
                overwrittenCount: data.assembly_wide_count ?? undefined,
                isLinkedToBom: data.is_linked_to_bom,
            };
        case 'PerScenario':
            return {
                automaticCount: undefined,
                overwrittenCount: data.assembly_wide_count ?? undefined,
                isLinkedToBom: false,
            };
        default:
            assertUnreachable(type);
    }
};

const selectLinkedDriversProps = (driverDetails: DriversOfManufacturingDetailsDTO): LinkedDriversProps => {
    const { type, data } = driverDetails.driver_count;
    switch (type) {
        case 'Basic':
            const driverCount = selectAutomaticCount(driverDetails);
            let value = driverCount.overwrittenCount ?? driverCount.automaticCount;
            value = value ? formatDecimal(roundTo2DpWithoutZeros(value)) : undefined;

            return {
                type: 'Single value',
                value,
                isOverwritten: driverCount.overwrittenCount !== undefined,
            };
        case 'PerScenario':
            const sourcingScenarioDriverCounts = Object.keys(data.sourcing_scenarios_response).map(
                (sourcingScenarioId) => {
                    const driverCount = data.sourcing_scenarios_response[sourcingScenarioId];
                    return driverCount.assembly_wide_count ?? driverCount.automatic_count;
                },
            );
            const fallbackCount = data.assembly_wide_count;
            sourcingScenarioDriverCounts.push(fallbackCount);
            const driverCounts = sourcingScenarioDriverCounts.filter(isPresent);

            let singleValue: string | undefined = undefined;
            for (const driverCount of driverCounts) {
                if (singleValue === undefined) {
                    singleValue = driverCount;
                } else if (singleValue !== driverCount) {
                    return {
                        type: 'Multiple values',
                    };
                }
            }

            const isOverwritten =
                data.assembly_wide_count !== null ||
                Object.values(data.sourcing_scenarios_response).some(
                    // at least one sourcing scenario has an overwritten count
                    (sourcingScenario) =>
                        sourcingScenario.assembly_wide_count !== null &&
                        sourcingScenario.assembly_wide_count !== sourcingScenario.automatic_count,
                );

            return {
                type: 'Single value',
                value: singleValue,
                isOverwritten,
            };
        default:
            assertUnreachable(type);
    }
};

export const selectInconsistentDriverPopupProps = (
    driverDetails: DriversOfManufacturingDetailsDTO,
): InconsistentDriverPopupProps | undefined => {
    const { type, data } = driverDetails.driver_count;
    switch (type) {
        case 'Basic':
            const basicConfigurationCounts = data.assembly_to_configuration_driver_count_mismatch;
            if (basicConfigurationCounts.length === 0) {
                return undefined;
            }

            return {
                assemblyWideDriverCount: data.assembly_wide_count ?? undefined,
                configurationCounts: basicConfigurationCounts,
            };
        case 'PerScenario':
            const scenarioConfigurationCounts = Object.values(data.sourcing_scenarios_response)
                .map((response) => response.assembly_to_configuration_driver_count_mismatch)
                .flat();
            if (scenarioConfigurationCounts.length === 0) {
                return undefined;
            }

            return {
                assemblyWideDriverCount: data.assembly_wide_count ?? undefined,
                configurationCounts: scenarioConfigurationCounts,
            };
        default:
            assertUnreachable(type);
    }
};

const useEditDriverCountProps = (
    assemblyDetailsId: string,
    driverDetails: DriversOfManufacturingDetailsDTO,
): EditDriverCountProps | undefined => {
    const { type, data } = driverDetails.driver_count;

    const createMutationUpdateSingleDriverCount = useCreateMutationUpdateBasicDriverCount();
    const onSubmit = createMutationUpdateSingleDriverCount(assemblyDetailsId, selectDriverIdDto(driverDetails.driver));

    switch (type) {
        case 'Basic':
            return {
                assemblyWideCount: data.assembly_wide_count ?? undefined,
                automaticCount: data.automatic_count ?? undefined,
                driverCountInconsistencies: data.assembly_to_configuration_driver_count_mismatch,
                driverName: selectDriverName(driverDetails.driver),
                driverType: selectDriverType(driverDetails.driver),
                driverNotes: selectDriverNotes(driverDetails.driver),
                onSubmit,
            };
        case 'PerScenario':
            // Must be overwritten per sourcing/manufacturing scenario
            return undefined;
        default:
            assertUnreachable(type);
    }
};
