import { t } from '@lingui/macro';
import { Flexbox } from '@luminovo/design-system';
import React from 'react';
import { ManufacturingDatabaseActions } from '../../../components/ManufacturingDatabase/ManufacturingDatabasePage';
import { ManufacturingDatabaseSidebar } from '../../../components/ManufacturingDatabase/ManufacturingDatabaseSidebar';
import { PageLayoutCollapsibleSidebar } from '../../../components/PageLayoutCollapsibleSidebar';
import { route } from '../../../utils/routes';
import DriversTable from './DriversTable';

export const DriversViewPage = (): JSX.Element => {
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    return (
        <PageLayoutCollapsibleSidebar sidebar={<ManufacturingDatabaseSidebar />} layoutVariant="fullWidth">
            <Flexbox flexDirection={'column'} paddingX={'32px'}>
                <ManufacturingDatabaseActions
                    buttonText={t`Add driver`}
                    titleText={t`Drivers`}
                    path={route('/manufacturing/add-driver')}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    descriptionText={t`Drivers are parameters that can be used to calculate Activities and Expenses (e.g. Number of SMT Parts or Number of Small Parts). `}
                />
                <Flexbox paddingBottom={'72px'}>
                    <DriversTable query={searchQuery} />
                </Flexbox>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
};
