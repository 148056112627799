import { t } from '@lingui/macro';
import { ParseStatus } from '../types';

export function formatParseStatus(status: ParseStatus): string {
    switch (status) {
        case 'done':
            return t`Success`;
        case 'warning':
            return t`Warning`;
        case 'error':
            return t`Error`;
        case 'duplicate-error':
            return t`Duplicate rows`;
        case 'pending':
            return t`Pending`;
        case 'skipped':
            return t`Excluded`;
    }
}
