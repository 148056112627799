import React, { createContext, useContext, useReducer } from 'react';
import { inferDecimalSeparatorOnConfig } from '../../model/inferDecimalSeparator';
import { GlobalField, ImporterConfig, ImporterTable, Table } from '../../types';
import { ColumnMappingStore } from './util/columnMappingStore';
import { DateFormat, getDefaultDateFormat } from './util/getDefaultDateFormat';
import { getDefaultDecimalSeparator } from './util/getDefaultDecimalSeparator';
export type UniversalImporterState = {
    /*
     * The "raw" tabled, uploaded by the user.
     */
    table?: Table;
    /**
     * The name of the file that was uploaded.
     */
    fileName?: string;
    /**
     * The table after it has been processed by the importer.
     *
     * @see {@link UniversalImporterState.table} - the raw table before it is processed by the importer.
     */
    importerTable?: ImporterTable;
    /**
     * The configuration of the importer.
     */
    config: ImporterConfig;

    decimalSeparator: '.' | ',';
    dateFormats: DateFormat[];
};

interface UniversalImporterContextProps {
    state: UniversalImporterState;
    dispatch: React.Dispatch<Actions>; // Define a more specific action type if necessary
}

type Actions =
    | { type: 'setTable'; table?: Table; fileName?: string }
    | { type: 'linkColumn'; column: number; field: string }
    | { type: 'restartImport' }
    | { type: 'unlinkColumn'; column: number }
    | { type: 'setGlobalFields'; newFields: Array<GlobalField> }
    | { type: 'setImporterTable'; table?: ImporterTable }
    | { type: 'setDecimalSeparator'; decimalSeparator: '.' | ',' }
    | { type: 'setDateFormats'; dateFormats: DateFormat[] };

type Action<T extends Actions['type']> = Actions & { type: T };

const universalImporterContext = createContext<UniversalImporterContextProps | undefined>(undefined);

const store = new ColumnMappingStore(localStorage);

export function reducer(state: UniversalImporterState, action: Actions): UniversalImporterState {
    // Use a specific action type
    switch (action.type) {
        case 'setTable':
            return setTable(state, action);
        case 'linkColumn':
            const res = linkColumn(state, action);
            if (state.table) {
                store.set(state.table, res.config);
            }
            return res;
        case 'unlinkColumn':
            return unlinkColumn(state, action);
        case 'setImporterTable':
            return { ...state, importerTable: action.table };
        case 'restartImport':
            return restartImport(state);
        case 'setGlobalFields':
            return setGlobalFields(state, action);
        case 'setDecimalSeparator':
            return { ...state, decimalSeparator: action.decimalSeparator };
        case 'setDateFormats':
            return { ...state, dateFormats: action.dateFormats };
    }
    // @ts-expect-error
    throw new Error('Unhandled action type: ' + action.type);
}

export const UniversalImporterProvider: React.FC<{ config: ImporterConfig; initialTable?: Table }> = ({
    children,
    config,
    initialTable,
}) => {
    const initialState: UniversalImporterState = {
        config,
        table: initialTable,
        dateFormats: [getDefaultDateFormat()],
        decimalSeparator: getDefaultDecimalSeparator(),
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <universalImporterContext.Provider value={{ state, dispatch }}>{children}</universalImporterContext.Provider>
    );
};

function resetState(state: UniversalImporterState): UniversalImporterState {
    return {
        ...state,
        table: undefined,
        importerTable: undefined,
        fileName: undefined,
        config: {
            ...state.config,
            fields: state.config.fields.map((field) => {
                return {
                    ...field,
                    columnIndices: [],
                };
            }),
        },
    };
}

function setTable(state: UniversalImporterState, action: Action<'setTable'>): UniversalImporterState {
    const newTable = action.table;
    // full-reset
    if (newTable === undefined) {
        return resetState(state);
    }
    const config = store.get(newTable, state.config);

    const newConfig = inferDecimalSeparatorOnConfig(newTable, config);

    return { ...resetState(state), config: newConfig, table: action.table, fileName: action.fileName };
}

function setGlobalFields(state: UniversalImporterState, action: Action<'setGlobalFields'>): UniversalImporterState {
    const newFields = action.newFields;
    return {
        ...state,
        config: {
            ...state.config,
            globalFields: newFields,
        },
    };
}

/**
 * This action is called when an import fails and the user wants to restart the import.
 * It should therefore not reset absolutely everything, but only the importerTable.
 * The column mappings should be kept.
 */
function restartImport(state: UniversalImporterState): UniversalImporterState {
    return {
        ...state,
        importerTable: undefined,
    };
}

// Custom hook to use the context
export const useUniversalImporter = (): UniversalImporterContextProps => {
    const context = useContext(universalImporterContext);
    if (context === undefined) {
        throw new Error('useUniversalImporter must be used within a UniversalImporterProvider');
    }
    return context;
};

function unlinkColumn(state: UniversalImporterState, action: Action<'unlinkColumn'>): UniversalImporterState {
    return {
        ...state,
        config: {
            ...state.config,
            fields: state.config.fields.map((field) => {
                return {
                    ...field,
                    columnIndices: field.columnIndices.filter((index) => index !== action.column),
                };
            }),
        },
    };
}

function linkColumn(state: UniversalImporterState, action: Action<'linkColumn'>): UniversalImporterState {
    const newConfig: ImporterConfig = {
        ...state.config,
        fields: state.config.fields.map((field) => {
            if (field.allowMultipleColumns && field.id === action.field) {
                return {
                    ...field,
                    columnIndices: field.columnIndices.includes(action.column)
                        ? field.columnIndices
                        : [...field.columnIndices, action.column],
                };
            }
            if (field.id !== action.field) {
                return {
                    ...field,
                    columnIndices: field.columnIndices.filter((index) => index !== action.column),
                };
            }
            return {
                ...field,
                columnIndices: [action.column],
            };
        }),
    };

    return {
        ...state,
        config: inferDecimalSeparatorOnConfig(state.table, newConfig),
    };
}
