import { colorSystem } from '@luminovo/design-system';
import { Box, styled } from '@mui/material';

export const STYLED_BOX_GRID_WIDTH = 384;

export const StyledBoxGrid = styled(Box)({
    display: 'grid',
    width: STYLED_BOX_GRID_WIDTH,
    maxWidth: STYLED_BOX_GRID_WIDTH,
    overflow: 'auto',
    height: 256,
    gridTemplateRows: 'auto auto 1fr auto',
    padding: '20px',
    boxShadow: '0px 4px 8px rgba(76, 83, 102, 0.08)',
    borderRadius: 4,
    backgroundColor: colorSystem.neutral.white,
});
