import { TableState } from '@luminovo/design-system';
import { AssemblyIndustry, GenericFullPart, OtsComponentFull, OtsFullPart, UserType } from '@luminovo/http-client';
import { OtsPartSearchTableContext } from '../../../modules/DesignItem/components/OtsPartSearch/otsPartSearchTypes';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { useUserType } from '../../contexts/CurrentUserDetailsContext';
import { CompareItemsDialog, useComparePartsDialogState } from '../CompareItemsDialog';
import { CustomerAttributes, EmsAttributes } from './attributes';
import { PartSearchComparisonContext } from './types';

export const OtsPartSearchComparisonDialog = ({
    isOpen,
    onClose,
    partOptions,
    searchResults,
    rfqId,
    assemblyId,
    bomItem,
    handleAddPartOption,
    tableState,
    assemblyIndustry,
}: {
    isOpen: boolean;
    onClose: () => void;
    partOptions: (OtsFullPart | OtsComponentFull)[];
    searchResults: OtsFullPart[];
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    handleAddPartOption: (
        newPart: OtsFullPart | GenericFullPart | OtsComponentFull,
        sectionOfScreen: SectionOfScreen,
    ) => void;
    tableState: TableState<
        {
            part: OtsFullPart;
            highlights: string[];
            mpnTerms: RegExp[];
        },
        OtsPartSearchTableContext
    >;
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const userType = useUserType();
    const attributes = userType === UserType.Internal ? EmsAttributes : CustomerAttributes;
    const dialogState = useComparePartsDialogState<
        OtsFullPart | OtsComponentFull,
        OtsFullPart,
        PartSearchComparisonContext
    >({
        comparableItems: searchResults,
        items: partOptions,
        rows: attributes,
        sharedContext: {
            rfqId,
            bomItem,
            assemblyId,
            handleAddPartOption,
            tableState,
            assemblyIndustry,
            partOptions,
        },
    });
    return <CompareItemsDialog isOpen={isOpen} onClose={onClose} dialogState={dialogState} />;
};
