import { colorSystem, Text } from '@luminovo/design-system';
import { Skeleton } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { withSuspense } from '../withSuspense';

const AssemblyLabel = withSuspense(
    function AssemblyLabel({ assembly }: { assembly: string | undefined }): JSX.Element {
        const { data: assemblyDto } = useAssembly(assembly ?? '', {
            with_aggregations: false,
            enabled: assembly !== undefined,
        });
        return <Text>{assemblyDto?.designator}</Text>;
    },
    {
        fallback: <Skeleton width="20px" />,
    },
);

export const AssemblyLabelWithErrorBoundary = withErrorBoundary(AssemblyLabel, {
    fallback: <Text color={colorSystem.neutral[6]}>Unknown assembly</Text>,
});
