import { isPresent } from '@luminovo/commons';
import { PCBFileTypes, PCBV2, PCBV2File, SpecificationStatusEnum } from '@luminovo/http-client';

function isLifecycleError(status: string): boolean {
    return status === 'error' || status === 'timeout';
}

function isLifecycleDone(status: string): boolean {
    return status === 'success';
}

export function isMainLifecycleError(pcb: PCBV2): boolean {
    const mainLifecycle = pcb.lifecycles.find((c) => c.name === 'main')?.status.name;

    return isLifecycleError(mainLifecycle ?? 'unknown');
}

export function isPcbSetupWithoutFiles(pcb: PCBV2) {
    return pcb.filesLocked && pcb.projectType === 'NoFiles';
}

export function calculatePercent(pcb: PCBV2): number {
    const mainLifecycle = pcb.lifecycles.find((c) => c.name === 'main');
    const status = mainLifecycle?.status.name ?? 'unknown';

    if (isLifecycleDone(status)) {
        return 100;
    }

    return mainLifecycle?.status.percent ?? 0;
}

export function isPcbAnalysisInProgress(pcb: PCBV2): boolean {
    const mainLifecycle = pcb.lifecycles.find((c) => c.name === 'main');
    const status = mainLifecycle?.status.name ?? 'unknown';

    if (isLifecycleDone(status)) {
        return false;
    }

    if (isPcbSetupWithoutFiles(pcb)) return false;

    return calculatePercent(pcb) < 100;
}

export function isPcbAnalysisSuccess(pcb: PCBV2): boolean {
    return pcb.lifecycles.find((c) => c.name === 'main')?.status.name === 'success';
}

export function isFilesUploaded(pcb: PCBV2): boolean {
    if ((pcb.files ?? []).length > 0) {
        return true;
    }
    const mainLifecycle = pcb.lifecycles.find((l) => l.name === 'main');

    if (!mainLifecycle) {
        return false;
    }

    return mainLifecycle.status.name === 'progress' || isPcbSetupWithoutFiles(pcb);
}

// Get the approval status for the pcb specification
// If there are no specifications, the pcb is changed
export function getPcbSpecificationStatus(pcb: PCBV2): SpecificationStatusEnum {
    return pcb.specifications[0]?.status ?? SpecificationStatusEnum.Changed;
}

export function isEditingPcbSpecification(pcb: PCBV2): boolean {
    return getPcbSpecificationStatus(pcb) === SpecificationStatusEnum.Changed;
}

export function isPcbApproved(pcb: PCBV2): boolean {
    return getPcbSpecificationStatus(pcb) === SpecificationStatusEnum.Active;
}

export function hasPcbBeenApprovedOnce(pcb: PCBV2): boolean {
    const specification = pcb.specifications[0];

    if (!isPresent(specification)) {
        return false;
    }

    const approvedOnce = (specification.changes ?? []).some(
        (change) => change.newValue.status === SpecificationStatusEnum.Active,
    );

    return approvedOnce;
}

export function isLegacyGerber(pcb: PCBV2): boolean {
    return pcb.files
        ? pcb.files?.filter((file: PCBV2File) => file.fileType.fileType === PCBFileTypes.LEGACY_GERBER).length > 0
        : false;
}

export function isDrillFileType(fileType: PCBFileTypes): boolean {
    return (
        fileType === PCBFileTypes.DRILL ||
        fileType === PCBFileTypes.PHDRILL ||
        fileType === PCBFileTypes.NPHDRILL ||
        fileType === PCBFileTypes.DRILLSETS
    );
}

/** Check if the PCB has any drill files */
export function hasDrillFiles(pcb: PCBV2): boolean {
    const drillFiles = pcb.files?.some((file: PCBV2File) => isDrillFileType(file.fileType.fileType));
    return Boolean(drillFiles);
}
