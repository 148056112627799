import { t } from '@lingui/macro';
import { isPresent, sortBy } from '@luminovo/commons';
import { Dielectric } from '@luminovo/http-client';
import { Decimal } from 'decimal.js';
import { transEnum } from '../components/localization/TransEnum';
import { dielectricTypesTranslations } from '../resources/part/partBackendTypes';

interface OrderOfMagnitude {
    magnitudeName: string;
    regexIdentifier: string;
    multiplicationFactor: number;
}

interface MatchedOrderOfMagnitude extends OrderOfMagnitude {
    matched: boolean;
}

/* eslint-disable spellcheck/spell-checker */
export enum ResistanceMagnitude {
    Ohms = 'Ohms',
    kiloOhms = 'kiloOhms',
    megaOhms = 'megaOhms',
    gigaOhms = 'gigaOhms',
}

export enum CapacitanceMagnitude {
    picoFarad = 'picoFarad',
    nanoFarad = 'nanoFarad',
    microFarad = 'microFarad',
    milliFarad = 'milliFarad',
    Farad = 'Farad',
}

enum VoltageMagnitude {
    Volt = 'Volt',
    milliVolt = 'milliVolt',
}

enum PowerMagnitude {
    Watt = 'Watt',
    milliWatt = 'milliWatt',
}

enum ToleranceMagnitude {
    Percentage = 'Percentage',
}

const ordersOfMagnitudeResistance: OrderOfMagnitude[] = [
    {
        magnitudeName: ResistanceMagnitude.Ohms,
        regexIdentifier: '[Rr]',
        multiplicationFactor: Math.pow(10, 0),
    },
    {
        magnitudeName: ResistanceMagnitude.kiloOhms,
        regexIdentifier: '[Kk]',
        multiplicationFactor: Math.pow(10, 3),
    },
    {
        magnitudeName: ResistanceMagnitude.megaOhms,
        regexIdentifier: '[Mm]',
        multiplicationFactor: Math.pow(10, 6),
    },
    {
        magnitudeName: ResistanceMagnitude.gigaOhms,
        regexIdentifier: '[Gg]',
        multiplicationFactor: Math.pow(10, 9),
    },
];

const ordersOfMagnitudeCapacitance: OrderOfMagnitude[] = [
    {
        magnitudeName: CapacitanceMagnitude.picoFarad,
        regexIdentifier: '[pP][fF]?',
        multiplicationFactor: Math.pow(10, -12),
    },
    {
        magnitudeName: CapacitanceMagnitude.nanoFarad,
        regexIdentifier: '[nN][fF]?',
        multiplicationFactor: Math.pow(10, -9),
    },
    {
        magnitudeName: CapacitanceMagnitude.microFarad,
        regexIdentifier: '[uUμµ][fF]?',
        multiplicationFactor: Math.pow(10, -6),
    },
    {
        magnitudeName: CapacitanceMagnitude.milliFarad,
        regexIdentifier: '[mM][fF]?',
        multiplicationFactor: Math.pow(10, -3),
    },
    {
        magnitudeName: CapacitanceMagnitude.Farad,
        regexIdentifier: '[fF]?',
        multiplicationFactor: Math.pow(10, 0),
    },
];

const ordersOfMagnitudeVoltage: OrderOfMagnitude[] = [
    {
        magnitudeName: VoltageMagnitude.milliVolt,
        regexIdentifier: '[mM][vV]?',
        multiplicationFactor: Math.pow(10, -3),
    },
    {
        magnitudeName: VoltageMagnitude.Volt,
        regexIdentifier: '[vV]?',
        multiplicationFactor: Math.pow(10, 0),
    },
];

const ordersOfMagnitudeTolerance: OrderOfMagnitude[] = [
    {
        magnitudeName: ToleranceMagnitude.Percentage,
        regexIdentifier: '[%]?',
        multiplicationFactor: Math.pow(10, 0),
    },
];

const ordersOfMagnitudePowerRating: OrderOfMagnitude[] = [
    {
        magnitudeName: PowerMagnitude.milliWatt,
        regexIdentifier: '[mM][wW]?',
        multiplicationFactor: Math.pow(10, -3),
    },
    {
        magnitudeName: PowerMagnitude.Watt,
        regexIdentifier: '[wW]?',
        multiplicationFactor: Math.pow(10, 0),
    },
];

const validResistanceInputRegex = new RegExp('(^[\\d]+([RGMKrgmk.][\\d]*)?$|^[Rr][\\d]+)$');
const validCapacitanceInputRegex = new RegExp(
    '^[\\d]+([mnupμµMNUP]?[\\d]*)?$|^[\\d]+([.]?[\\d]*[fF]?)?$|^[\\d]+([mnupμµMNUP]?[fF]?)?$',
);
const validToleranceInputRegex = new RegExp('^[\\d]+[.]?[\\d]*[%]?$');
const validPowerRatingInputRegex = new RegExp('^[\\d]+[.]?[\\d]*[mM]?[wW]?$');
const validVoltageRatingInputRegex = new RegExp('^[\\d]+[.]?[\\d]*[mM]?[vV]?$');

/* eslint-enable spellcheck/spell-checker */

export const resistanceConverter = (spec?: string): string | null => {
    if (!spec) {
        return null;
    }
    if (isValidResistanceConverterInput(spec)) {
        return convertTechnicalSpecificationInput(spec, ordersOfMagnitudeResistance)?.toString() ?? null;
    } else {
        return null;
    }
};

// eslint-disable-next-line import/no-unused-modules
export const capacitanceConverter = (spec: string): Decimal | undefined => {
    if (isValidCapacitanceConverterInput(spec)) {
        return convertTechnicalSpecificationInput(spec, ordersOfMagnitudeCapacitance);
    } else {
        return undefined;
    }
};

export const voltageConverter = (spec: string): Decimal | undefined => {
    if (isValidVoltageRatingConverterInput(spec)) {
        return convertTechnicalSpecificationInput(spec, ordersOfMagnitudeVoltage);
    } else {
        return undefined;
    }
};

export const toleranceConverter = (spec: string): Decimal | undefined => {
    if (isValidToleranceConverterInput(spec)) {
        return convertTechnicalSpecificationInput(spec, ordersOfMagnitudeTolerance);
    } else {
        return undefined;
    }
};

export const powerRatingConverter = (spec: string): Decimal | undefined => {
    if (isValidPowerRatingConverterInput(spec)) {
        return convertTechnicalSpecificationInput(spec, ordersOfMagnitudePowerRating);
    } else {
        return undefined;
    }
};

export const convertNumericInput = (spec: string): Decimal | undefined => {
    if (isNumericInput(spec)) {
        try {
            const val = new Decimal(spec);
            return val.isNaN() ? undefined : val;
        } catch {
            return undefined;
        }
    }
};

const convertTechnicalSpecificationInput = (
    spec: string,
    ordersOfMagnitude: OrderOfMagnitude[],
): Decimal | undefined => {
    if (isNumericInput(spec)) {
        try {
            const val = new Decimal(spec);
            return val.isNaN() ? undefined : val;
        } catch {
            return undefined;
        }
    }
    const magnitude = getOrderOfMagnitude(spec, ordersOfMagnitude);

    if (magnitude) {
        return convertToDecimal(magnitude, spec);
    } else {
        return undefined;
    }
};

export const isValidResistanceConverterInput = (spec: string): boolean => validResistanceInputRegex.test(spec);
export const isValidCapacitanceConverterInput = (spec: string): boolean => validCapacitanceInputRegex.test(spec);
export const isValidToleranceConverterInput = (spec: string): boolean => validToleranceInputRegex.test(spec);
export const isValidPowerRatingConverterInput = (spec: string): boolean => validPowerRatingInputRegex.test(spec);
export const isValidVoltageRatingConverterInput = (spec: string): boolean => validVoltageRatingInputRegex.test(spec);

const isNumericInput = (spec: string): boolean => {
    const regNumeric = new RegExp(`^[0-9]\\d*(\\.\\d*)?$`);
    return regNumeric.test(spec);
};
const getOrderOfMagnitude = (
    spec: string,
    ordersOfMagnitude: OrderOfMagnitude[],
): MatchedOrderOfMagnitude | OrderOfMagnitude | undefined => {
    const templates = ordersOfMagnitude.map((magnitude) => {
        const pattern = new RegExp(
            `^[\\d]+(${magnitude.regexIdentifier}[\\d]*)$|^([\\d]*)([.])([\\d]*)(${magnitude.regexIdentifier})$`,
        );
        return {
            ...magnitude,
            matched: pattern.test(spec),
        };
    });

    const isLessThanOneOhmPattern = new RegExp('^[Rr][\\d]+$');

    // either match one of the magnitude patterns or it has to be the lessThanOneOhm Pattern
    return (
        templates.find((element) => element.matched) ||
        (isLessThanOneOhmPattern.test(spec)
            ? {
                  magnitudeName: 'lessThanOne',
                  regexIdentifier: '',
                  multiplicationFactor: 0,
              }
            : undefined)
    );
};

const convertToDecimal = (magnitude: OrderOfMagnitude, spec: string): Decimal => {
    if (magnitude.magnitudeName === 'lessThanOne') {
        return new Decimal(`0.${spec.substring(1)}`);
    } else {
        const [preDecimalPlace, postDecimalPlace] = getPreAndPostDecimalPlaces(spec, magnitude.regexIdentifier);
        const preAndPostDecimals = `${preDecimalPlace}.${postDecimalPlace}`;

        return new Decimal(preAndPostDecimals).times(new Decimal(magnitude.multiplicationFactor));
    }
};

const getPreAndPostDecimalPlaces = (spec: string, regexIdentifier: string): [string, string] => {
    const pattern = new RegExp(`^([\\d]*)(${regexIdentifier})([\\d]*)$|^([\\d]*)([.])([\\d]*)(${regexIdentifier})$`);
    const matchedGroups: RegExpExecArray | null = pattern.exec(spec);

    if (matchedGroups && matchedGroups[4] === undefined) {
        return [matchedGroups[1], matchedGroups[3]];
    } else if (matchedGroups && matchedGroups[1] === undefined) {
        return [matchedGroups[4], matchedGroups[6]];
    } else {
        return ['0', '0'];
    }
};

interface SuitableMagnitude {
    magnitudeName: string;
    value: Decimal;
}

export const getSuitableResistanceMagnitude = (convertedResistance: Decimal): SuitableMagnitude => {
    return getSuitableMagnitude(convertedResistance, ordersOfMagnitudeResistance, ResistanceMagnitude.Ohms);
};

export const getSuitableCapacitanceMagnitude = (convertedCapacitance: Decimal): SuitableMagnitude => {
    return getSuitableMagnitude(convertedCapacitance, ordersOfMagnitudeCapacitance, CapacitanceMagnitude.Farad);
};

const getSuitableMagnitude = (
    convertedValue: Decimal,
    ordersOfMagnitude: OrderOfMagnitude[],
    defaultMagnitude: CapacitanceMagnitude | ResistanceMagnitude,
): SuitableMagnitude => {
    const preDecimalGreaterThanOne = ordersOfMagnitude
        .slice()
        .reverse()
        .find((magnitude) => Number(convertedValue.div(magnitude.multiplicationFactor)) >= 1.0);
    return {
        magnitudeName: preDecimalGreaterThanOne ? preDecimalGreaterThanOne.magnitudeName : defaultMagnitude,
        value: preDecimalGreaterThanOne
            ? convertedValue.div(preDecimalGreaterThanOne.multiplicationFactor)
            : convertedValue,
    };
};

type OhmUnit = 'k' | 'M' | 'G';
type OhmUnitOrEmptyString = OhmUnit | '';
const getOhmUnit = (magnitudeName: string): OhmUnitOrEmptyString => {
    switch (magnitudeName) {
        case ResistanceMagnitude.kiloOhms:
            return `k`;
        case ResistanceMagnitude.megaOhms:
            return `M`;
        case ResistanceMagnitude.gigaOhms:
            return `G`;
        default:
            return '';
    }
};

type FaradUnit = 'p' | 'n' | 'm' | 'u';
type FaradUnitOrEmptyString = '' | FaradUnit;
const getFaradUnit = (magnitudeName: string): FaradUnitOrEmptyString => {
    switch (magnitudeName) {
        case CapacitanceMagnitude.picoFarad:
            return `p`;
        case CapacitanceMagnitude.nanoFarad:
            return `n`;
        case CapacitanceMagnitude.microFarad:
            return `u`;
        case CapacitanceMagnitude.milliFarad:
            return `m`;
        default:
            return '';
    }
};

export const displayConvertedCapacitance = (convertedCapactiance: Decimal): string => {
    const display = getSuitableCapacitanceMagnitude(convertedCapactiance);
    return `${display.value} ${getFaradUnit(display.magnitudeName)}`;
};

export function formatResistance(resistance: string | number | null | undefined, options = { ifAbsent: '-' }): string {
    if (!isPresent(resistance)) {
        return options.ifAbsent;
    }
    const display = getSuitableResistanceMagnitude(new Decimal(resistance));
    return `${display.value} ${getOhmUnit(display.magnitudeName)}Ω`;
}

interface GeneralOrderOfMagnitude {
    displaySymbol: string;
    multiplicationFactor: number;
}

// Order these by multiplication factor
const generalOrdersOfMagnitude: GeneralOrderOfMagnitude[] = sortBy(
    [
        {
            displaySymbol: 'f',
            multiplicationFactor: Math.pow(10, -15),
        },
        {
            displaySymbol: 'p',
            multiplicationFactor: Math.pow(10, -12),
        },
        {
            displaySymbol: 'n',
            multiplicationFactor: Math.pow(10, -9),
        },
        {
            displaySymbol: 'μ',
            multiplicationFactor: Math.pow(10, -6),
        },
        {
            displaySymbol: 'm',
            multiplicationFactor: Math.pow(10, -3),
        },
        {
            displaySymbol: '',
            multiplicationFactor: 1,
        },

        {
            displaySymbol: 'k',
            multiplicationFactor: Math.pow(10, 3),
        },
        {
            displaySymbol: 'M',
            multiplicationFactor: Math.pow(10, 6),
        },
        {
            displaySymbol: 'G',
            multiplicationFactor: Math.pow(10, 9),
        },
    ],
    ({ multiplicationFactor }) => -multiplicationFactor,
);

export const roundToNDecimalPlaces = (val: number, n: number): number => {
    // see https://stackoverflow.com/a/12830454
    return Number(val.toFixed(n));
};

function getMatchingOrderOfMagnitude(val: number): GeneralOrderOfMagnitude {
    if (val === 0) {
        return {
            displaySymbol: '',
            multiplicationFactor: 1,
        };
    }
    const smallestmatchingOrderOfMagnitude = generalOrdersOfMagnitude.find(
        (magnitude) => val >= magnitude.multiplicationFactor,
    );

    // If the value is so small val < multiplication factor just take the smallest we have
    return smallestmatchingOrderOfMagnitude || generalOrdersOfMagnitude[generalOrdersOfMagnitude.length - 1];
}

export function formatValueWithOrderOfMagnitude(val: number, unitSymbol: string): string {
    const orderOfMagnitude = getMatchingOrderOfMagnitude(val);
    const rounded = roundToNDecimalPlaces(val / orderOfMagnitude.multiplicationFactor, 4);
    return `${rounded}${orderOfMagnitude.displaySymbol}${unitSymbol}`;
}

export function formatTolerance(tolerance: string | number | null | undefined, options = { ifAbsent: '-' }): string {
    if (!isPresent(tolerance)) {
        return options.ifAbsent;
    }

    return `${tolerance}%`;
}
export function formatCapacitance(
    capacitance: string | number | null | undefined,
    options = { ifAbsent: '-' },
): string {
    if (!isPresent(capacitance)) {
        return options.ifAbsent;
    }

    return `${formatValueWithOrderOfMagnitude(Number(capacitance), 'F')}`;
}

export function formatPowerRating(
    powerRating: string | number | null | undefined,
    options = { ifAbsent: '-' },
): string {
    if (!isPresent(powerRating)) {
        return options.ifAbsent;
    }

    return formatValueWithOrderOfMagnitude(Number(powerRating), 'W');
}
export function formatVoltageRating(
    voltageRating: string | number | null | undefined,
    options = { ifAbsent: '-' },
): string {
    if (!isPresent(voltageRating)) {
        return options.ifAbsent;
    }

    return formatValueWithOrderOfMagnitude(Number(voltageRating), 'V');
}
export function formatTemperatureCoefficient(
    temperatureCoefficient: string | number | null | undefined,
    options = { ifAbsent: '-' },
): string {
    if (!isPresent(temperatureCoefficient)) {
        return options.ifAbsent;
    }

    return `±${temperatureCoefficient}ppm/K`;
}

export function formatDielectricMaterial(dielectricMaterial: Dielectric): string {
    if (!dielectricMaterial) {
        return t`None`;
    }
    const { type: dielectricType, dielectric } = dielectricMaterial;
    return dielectric ? dielectric : dielectricType ? transEnum(dielectricType, dielectricTypesTranslations) : '';
}
