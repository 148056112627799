import { t } from '@lingui/macro';
import { Flexbox, Message, PrimaryButton, Text } from '@luminovo/design-system';
import { ManufacturingUpsellBanner } from '@luminovo/manufacturing-core';
import { Add } from '@mui/icons-material';
import React from 'react';
import { useManufacturingModuleVariant } from '../../featureFlags';
import { useCanEditManufacturingDatabase } from '../../modules/Manufacturing/utils/useCanEditManufacturingDatabase';
import { SearchInput } from '../SearchInput';
import { useIsAdminUser } from '../contexts/CurrentUserDetailsContext';

export const ReadOnlyAccessAlert = () => {
    const isAdmin = useIsAdminUser();

    if (!isAdmin) {
        return (
            <Message
                variant="red"
                attention={'high'}
                size={'small'}
                title={t`Read-only access`}
                message={t`You don't have permission to edit this database. Contact an admin to get access.`}
            />
        );
    }

    return <></>;
};

export const ManufacturingDatabaseActions = ({
    path,
    buttonText,
    titleText,
    searchQuery,
    setSearchQuery,
    descriptionText,
}: {
    path: string;
    buttonText: string;
    descriptionText: string;
    titleText: string;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    const manufacturingVariant = useManufacturingModuleVariant();
    return (
        <Flexbox flexDirection={'column'} paddingY={'18px'} gap={12}>
            <ReadOnlyAccessAlert />
            {manufacturingVariant === 'lite' && <ManufacturingUpsellBanner />}
            <Flexbox flexDirection={'column'} gap={4}>
                <Text variant={'h2'}>{titleText}</Text>
                <Text variant={'body'}>{descriptionText}</Text>
            </Flexbox>
            <Flexbox alignItems={'center'} justifyContent={'space-between'}>
                <SearchInput value={searchQuery} onChange={setSearchQuery} style={{ width: '300px' }} />
                {canEditManufacturingDatabase && (
                    <PrimaryButton startIcon={<Add fontSize={'inherit'} />} href={path} size="medium">
                        {buttonText}
                    </PrimaryButton>
                )}
            </Flexbox>
        </Flexbox>
    );
};
