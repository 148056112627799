import { plural } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Message } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { BaseManufacturingScenarioFormState } from '../types';

type BatchSizeEvalulationError = {
    type: 'error';
    batchSizes: number[];
    orderSize: number;
};

type BatchSizeEvalulation =
    | BatchSizeEvalulationError
    | {
          type: 'ok';
      };

export const useBatchSizeEvaluation = (
    sourcingScenarios: SourcingScenarioDTO[],
    assemblyQuantities: Record<string, number>,
): BatchSizeEvalulation => {
    const { control } = useFormContext<BaseManufacturingScenarioFormState>();
    const batchSizes = useWatch({ control, name: 'batchSizes' });

    const sourcingScenarioId = useWatch({ control, name: 'sourcingScenarioId' });
    const sourcingScenario = sourcingScenarios.find((scenario) => scenario.id === sourcingScenarioId);

    const orderSize =
        isPresent(sourcingScenarioId) && sourcingScenarioId in assemblyQuantities
            ? assemblyQuantities[sourcingScenarioId]
            : undefined;

    if (!isPresent(sourcingScenario) || !isPresent(orderSize)) {
        return { type: 'ok' };
    }

    const invalidBatchSizes = batchSizes.filter((batchSize) => (orderSize as number) % batchSize !== 0);
    return invalidBatchSizes.length === 0
        ? { type: 'ok' }
        : { type: 'error', batchSizes: invalidBatchSizes, orderSize };
};

export const BatchSizeNotAMultipleOfOrderSize = ({ error }: { error: BatchSizeEvalulationError }) => {
    return (
        <Box sx={{ alignSelf: 'center' }}>
            <Message
                message={plural(error.batchSizes.length, {
                    one: `Order size ${error.orderSize} is not a multiple of batch size ${error.batchSizes.join(
                        ', ',
                    )}.`,
                    other: `Order size ${error.orderSize} is not a multiple of batch sizes ${error.batchSizes.join(
                        ', ',
                    )}.`,
                })}
                variant="yellow"
                attention="high"
                size="small"
            />
        </Box>
    );
};
