import { useCommunicationsDrawer, ViewCommentsButton } from '../../../../../components/CommentsDrawer';
import { useIsCustomer } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { usePCBDesignItemFromAssembly } from '../../../../../resources/pcb/pcbHandlers';
import { id } from '../../../../../utils/ids';

export function PcbCommentsButton({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) {
    const isCustomer = useIsCustomer();
    const { pcbDesignItem } = usePCBDesignItemFromAssembly(assemblyId);
    const designItemIds = pcbDesignItem?.id ? [pcbDesignItem.id] : [];

    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads: [
            {
                commentType: 'DesignItem',
                category: 'Internal',
                typeIds: designItemIds,
            },
            {
                commentType: 'DesignItem',
                category: 'Public',
                typeIds: designItemIds,
            },
        ],
    });

    if (designItemIds.length === 0) {
        return null;
    }

    return (
        <span id={isCustomer ? id('design/button_contact_ems') : undefined}>
            <ViewCommentsButton
                eventEntity={{ type: 'DesignItem', data: designItemIds }}
                onClick={() => openDrawer()}
            />
        </span>
    );
}
