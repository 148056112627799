import { Trans } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import {
    ActorResponseRuntypeDTO,
    CommentCategory,
    HistoryCommentDataDTO,
    HistoryOperation,
} from '@luminovo/http-client';
import CheckIcon from '@mui/icons-material/Check';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    StyledTimelineDot,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineNote,
    TimelineText,
} from './Utils';

export function CommentIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    if (operation === HistoryOperation.ManualResolve)
        return (
            <StyledTimelineDot
                style={{
                    backgroundColor: colorSystem.blue[2],
                }}
            >
                <CheckIcon style={{ width: '20px', height: '20px', color: colorSystem.blue[6] }} />
            </StyledTimelineDot>
        );

    return <TimelineAddIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    data: HistoryCommentDataDTO;
    operation: HistoryOperation;
};

function useCommentActionAndColor(operation: HistoryOperation) {
    switch (operation) {
        case HistoryOperation.ManualRemove:
            return { action: <Trans>removed</Trans>, color: colorSystem.neutral[5] };
        case HistoryOperation.ManualAdd:
            return { action: <Trans>added</Trans> };
        case HistoryOperation.ManualResolve:
            return { action: <Trans>resolved</Trans>, color: colorSystem.neutral[5] };
        case HistoryOperation.ManualUnresolve:
            return { action: <Trans>unresolved</Trans> };
        default:
            return null;
    }
}

export default function Comment({ actor, data, operation }: Props) {
    const actionAndColor = useCommentActionAndColor(operation);
    if (!isActorDefined(actor)) return null;
    if (actionAndColor) {
        return (
            <CommentItemContent operation={operation} actor={actor} data={data} color={actionAndColor.color}>
                {actionAndColor.action}
            </CommentItemContent>
        );
    }

    return null;
}

type CommentActionProps = {
    data: HistoryCommentDataDTO;
    actor: ActorResponseRuntypeDTO;
    color?: string;
    operation: HistoryOperation;
};

function CommentItemContent({ data, actor, children, color, operation }: React.PropsWithChildren<CommentActionProps>) {
    return (
        <>
            <TimelineContentContainer>
                <TimelineText>
                    <CommentCategoryPhrase category={data.category} /> {children} <ActionPerformedBy actor={actor} />
                </TimelineText>
            </TimelineContentContainer>
            {operation === HistoryOperation.ManualRemove ? null : <TimelineNote text={data.content} color={color} />}
        </>
    );
}

function CommentCategoryPhrase({ category }: { category: CommentCategory }) {
    if (category === 'Public') return <Trans>Public comment</Trans>;
    return <Trans>Internal comment</Trans>;
}
