import * as r from 'runtypes';
import { BACKEND_HOST } from '../../const';
import { endpoint } from '../http/endpoint';
import { CustomerSignupPostRuntype } from './authBackendTypes';

export const authEndpoints = {
    'POST /auth/accept-invite': endpoint({
        description: 'Registers a previously invited user.',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Unknown,
        requestBody: r.Record({
            email: r.String,
            first_name: r.String,
            last_name: r.String,
            token: r.String,
            password: r.String,
            customer_id: r.String.nullable(),
            password_confirmation: r.String,
            phone_number: r.String,
            language: r.String,
            position: r.String,
            tenant: r.String,
        }),
        rootUrl: BACKEND_HOST + '/public',
    }),
    'POST /auth/customers/signup': endpoint({
        description: 'Signs up a customer',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Unknown,
        requestBody: CustomerSignupPostRuntype,
        rootUrl: BACKEND_HOST + '/public',
    }),
};
