import { Popper, styled } from '@mui/material';
import * as React from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { VirtualizeProps } from './types';

// Orignal source code: https://mui.com/material-ui/react-autocomplete/#virtualization  version 5.13.5

const LISTBOX_PADDING = 8; // px
const ITEM_HEIGHT = 48; // px
const MAX_ITEMS_COUNT = 8;

const OuterElementContext = React.createContext({});

// Handles the hover and focus states of the item
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;

    return React.cloneElement(data[index], {
        style: {
            ...style,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData: React.ReactChild[] = [];

        React.Children.forEach(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            children as React.ReactChild[],
            (item: React.ReactChild & { props?: VirualizedGroupContainerProps | unknown }) => {
                // Handles the case where the children are wrapped in a VirualizedGroupContainer
                if ('label' in item.props && 'innerChildren' in item.props) {
                    itemData.push(item.props.label);
                    itemData.push(...(item.props.innerChildren || []));
                } else {
                    itemData.push(item);
                }
            },
        );

        const itemCount = itemData.length;
        const innerHeight = Math.min(itemCount, MAX_ITEMS_COUNT) * ITEM_HEIGHT;

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <FixedSizeList
                        itemData={itemData}
                        height={innerHeight + 2 * LISTBOX_PADDING}
                        width="100%"
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={ITEM_HEIGHT}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </FixedSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    },
);

const StyledPopper = styled(Popper)({
    [`& .MuiAutocomplete-listbox`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

export function generateVirtualizeProps<TValue>(): VirtualizeProps<TValue> {
    return {
        ListboxComponent: ListboxComponent,
        PopperComponent: StyledPopper,
    };
}

type VirualizedGroupContainerProps = {
    label: React.ReactNode;
    innerChildren: React.ReactNode;
};

/**
 * This component should only be used in the renderGroup function of a virtualized FieldMultiSelect.
 */
export const VirualizedGroupContainer: React.FunctionComponent<VirualizedGroupContainerProps> = (): null => {
    // HACK: We intentionally return an empty component here. The ListboxComponent extracts the children from
    //       the props and passes them to the FixedSizeList. This is necessary to get the exact number of elements
    //       that will be displayed in the list without resolving the recursive structure of the children.
    return null;
};
