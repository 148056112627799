import { getTokenIfPresent } from '@luminovo/auth';
import { EndpointRegistry, http, HttpOptions, RegisteredHttpEndpoint } from '@luminovo/http-client';
import { QueryClient, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import * as r from 'runtypes';
import { httpQueryKey } from './httpQueryKey';
// eslint-disable-next-line camelcase
import { TEMPORARY_patchIsLoading } from './TEMPORARY_patchIsLoading';

/**
 * `useQuery` adapted for making type-safe HTTP requests
 */
export function useHttpQuery<T extends RegisteredHttpEndpoint, TData = r.Static<EndpointRegistry[T]['responseBody']>>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: Omit<
        UseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, unknown, TData>,
        'onSuccess' | 'onError'
    > = {},
): UseQueryResult<TData, unknown> {
    const result = useQuery({
        queryKey: httpQueryKey(endpoint, options),
        queryFn: async () => {
            return http(endpoint, options, getTokenIfPresent());
        },
        ...useQueryOptions,
    });
    // eslint-disable-next-line camelcase
    return TEMPORARY_patchIsLoading(result);
}

/**
 * A type-safe helper to invalidate the React Query cache for a given `endpoint`.
 * Will not compile when the `endpoint` argument is not a valid endpoint registered in `endpointRegistry`.
 *
 * @deprecated use httpQueryKey - Example `queryClient.invalidateQueries(httpQueryKey('GET / rfqs'))`
 */
export async function invalidateAllQueriesForEndpoint(
    endpoint: RegisteredHttpEndpoint,
    queryClient: QueryClient,
): Promise<void> {
    return queryClient.invalidateQueries([endpoint]);
}

/**
 * A type-safe helper to invalidate the React Query cache for a given pair of `endpoint` and `options`.
 * @deprecated use httpQueryKey - Example `queryClient.invalidateQueries(httpQueryKey('GET / rfqs'))`
 */
export function invalidateQuery<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    options: HttpOptions<T>,
    queryClient: QueryClient,
): Promise<void> {
    return queryClient.invalidateQueries([endpoint, options]);
}
