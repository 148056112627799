/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatToIso8601Date, isPresent } from '@luminovo/commons';
import { CustomerOrderSizeDTO, CustomerRfqUpdateDTO } from '@luminovo/http-client';
import React from 'react';
import { useHistory } from 'react-router';
import { PageLayout } from '../../components/PageLayout';
import { CustomerRfqForm, CustomerRfqFormState } from '../../components/RfQ/CustomerRfqForm';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { useTopLevelAssemblies } from '../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { getTotalQuantity } from '../../resources/sourcingScenario/getTotalQuantity';
import { useSourcingScenarios } from '../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertPresent } from '../../utils/assertPresent';
import { useDefaultAssemblyType } from '../RfqCreation/hooks/useDefaultAssemblyType';
import { useMutationUpdateRfqAsCustomer } from './hooks/useMutationUpdateRfq';

export const CustomerRfqEditPage: React.FunctionComponent<{ rfqId: string }> = ({ rfqId }) => {
    const history = useHistory();
    const { data: rfq, isLoading: isLoadingRfq } = useRfQ(rfqId);

    const { data: sourcingScenarios = [], isLoading: isLoadingSourcingScenarios } = useSourcingScenarios(
        rfq?.sourcing_scenarios ?? [],
    );

    const orderSizes: CustomerRfqFormState['orders'] = sourcingScenarios
        .map((s) => {
            const date = s.solution_preference.lead_time_preference.target;
            return {
                quantity: getTotalQuantity(s),
                date: date ? formatToIso8601Date(date) : undefined,
                id: s.id,
            };
        })
        .sort((a, b) => a.quantity - b.quantity);

    const { data: assemblies, isLoading: isLoadingAssemblies } = useTopLevelAssemblies(rfqId);

    const { data: additionalServiceIds } = useHttpQuery(
        'GET /rfqs/:rfqId/additional-services',
        { pathParams: { rfqId } },
        { select: (response) => response.data.map((service) => service.id) },
    );

    const { data: defaultAssembly, isLoading: isLoadingDefaultAssembly } = useDefaultAssemblyType();

    const handleCancel = () => {
        history.goBack();
    };

    const { mutateAsync: updateRfq } = useMutationUpdateRfqAsCustomer({ rfqId });

    const onSubmit = async (values: CustomerRfqFormState) => {
        let update = convertFormStateToDto(values);
        await updateRfq(update);
        history.goBack();
    };

    if (
        !rfq ||
        isLoadingRfq ||
        isLoadingSourcingScenarios ||
        isLoadingAssemblies ||
        !assemblies ||
        !additionalServiceIds ||
        !defaultAssembly ||
        isLoadingDefaultAssembly
    ) {
        return <SpinnerWithBackdrop />;
    }

    // A customer RfQ must always have a top-level assembly.
    const topLevelAssembly = assertPresent(assemblies[0]);

    const defaultValues: CustomerRfqFormState = {
        rfqName: rfq.name,
        industry: topLevelAssembly.industry,
        comment: '',
        orders: orderSizes,
        additionalServiceIds,
        assemblyType: defaultAssembly,
    };

    return (
        <PageLayout layout="full-width">
            <CustomerRfqForm
                title={t`Edit RfQ`}
                onSubmit={onSubmit}
                onCancel={handleCancel}
                defaultValues={defaultValues}
                variant="edit"
            />
        </PageLayout>
    );
};

const convertFormStateToDto = (form: CustomerRfqFormState): CustomerRfqUpdateDTO => {
    const order_sizes = form.orders
        .map((order): CustomerOrderSizeDTO | undefined => {
            if (!isPresent(order.quantity)) {
                return undefined;
            }

            const sourcing_scenario_id = order.id;
            const quantity = order.quantity;
            const desired_date = order.date ?? null;

            return isPresent(sourcing_scenario_id)
                ? { type: 'Existing', data: { sourcing_scenario_id, quantity, desired_date } }
                : { type: 'New', data: { quantity, desired_date } };
        })
        .filter(isPresent);

    return {
        name: form.rfqName,
        industry: form.industry !== '' ? form.industry : undefined,
        order_sizes,
        additional_services: form.additionalServiceIds,
    };
};
