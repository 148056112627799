import { Trans, t } from '@lingui/macro';
import { TertiaryButton } from '@luminovo/design-system';
import UpdateIcon from '@mui/icons-material/Update';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';

export const ManufacturingCacheRefreshButton = ({ assemblyId }: { assemblyId: string }): JSX.Element => {
    const { mutateAsync, isLoading } = useHttpMutation('POST /manufacturing-cache/sourcing/refresh/:assemblyId', {
        snackbarMessage: t`Sourcing info updated`,
    });

    return (
        <TertiaryButton
            onClick={() => mutateAsync({ pathParams: { assemblyId } })}
            size="medium"
            startIcon={<UpdateIcon />}
            isLoading={isLoading}
            style={{ whiteSpace: 'nowrap' }}
        >
            <Trans>Update sourcing info</Trans>
        </TertiaryButton>
    );
};
