import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import {
    ALL_CATEGORY_SELECTION_OPTIONS,
    ALL_EXPENSE_LEVELS,
    ALL_PROCESS_SELECTION_OPTIONS,
    ExpenseCostCalculationTypeRuntype,
    ExpenseDTO,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import * as Yup from 'yup';
import { isValueAlreadyUsed, requiredErrorMessage } from '../../../../utils/yupValidationUtils';
import { driverYupObject } from '../../manufacturingSchemas';

const EXPENSE_COST_CALCULATION_TYPES = ExpenseCostCalculationTypeRuntype.alternatives.map(
    (alternative) => alternative.value,
);

const createNonNegativeNumberTest = () =>
    Yup.string()
        .nullable()
        .optional()
        .test('is-non-negative-number', t`Must be greater than or equal to 0`, (value) => {
            if (!value) return true;
            const num = parseFloat(value);
            return !isNaN(num) && num >= 0;
        });

export const fixedCostCalculationValidationSchema: Yup.ObjectSchema = Yup.object().shape({
    fixedCost: Yup.object().shape({
        amount: createNonNegativeNumberTest().required(requiredErrorMessage).typeError(requiredErrorMessage),
    }),
});

const formulaCostCalculationValidationSchema: Yup.ObjectSchema = Yup.object().shape({
    formula: Yup.string().required(),
    currency: Yup.string().required().oneOf(Object.values(Currency)),
});

export const createLinearCostCalculationValidationSchema = (
    drivers: UserDriverDetailsDTO[],
    isUnitLevel: boolean,
): Yup.ObjectSchema => {
    return Yup.object().shape({
        fixedCost: Yup.object().shape({
            amount: createNonNegativeNumberTest(),
        }),
        variableCost: Yup.object().shape({
            driverId: driverYupObject(drivers, !isUnitLevel).required(requiredErrorMessage()),
            variableUnit: Yup.object().shape({
                amount: createNonNegativeNumberTest().required(requiredErrorMessage).typeError(requiredErrorMessage),
            }),
        }),
    });
};

const createExpenseInternalNumberValidationSchema = (expenses: ExpenseDTO[]): Yup.StringSchema =>
    Yup.string().test(
        'expenses-internalNumber-used',
        t`An expense with this internal number already exists`,
        (value) => {
            if ([null, undefined, ''].includes(value?.trim())) {
                return true;
            } else {
                return !isValueAlreadyUsed(
                    value ?? '',
                    expenses.flatMap((a) => a.internal_number ?? []),
                    undefined,
                );
            }
        },
    );

export const createExpenseSchema = (expenses: ExpenseDTO[], drivers: UserDriverDetailsDTO[]): Yup.ObjectSchema => {
    return Yup.object().shape({
        expense: Yup.object().shape({
            name: Yup.string()
                .required(requiredErrorMessage())
                .test(
                    'expense-name-used',
                    t`An expense with this name already exists`,
                    (value) =>
                        !isValueAlreadyUsed(
                            value || '',
                            expenses.map((a) => a.name),
                            undefined,
                        ),
                ),
            internalExpenseNumber: createExpenseInternalNumberValidationSchema(expenses),
            process: Yup.mixed()
                .required(requiredErrorMessage())
                .oneOf(ALL_PROCESS_SELECTION_OPTIONS, requiredErrorMessage()),
            category: Yup.mixed()
                .oneOf(ALL_CATEGORY_SELECTION_OPTIONS, requiredErrorMessage())
                .required(requiredErrorMessage()),
            level: Yup.string().oneOf(ALL_EXPENSE_LEVELS, requiredErrorMessage()).required(requiredErrorMessage()),
            description: Yup.string(),
        }),
        selectedCostCalculationType: Yup.mixed()
            .required(requiredErrorMessage())
            .oneOf(EXPENSE_COST_CALCULATION_TYPES, requiredErrorMessage()),
        linearCostCalculation: Yup.object().when('selectedCostCalculationType', {
            is: 'Linear',
            then: Yup.object().when('expense.level', {
                is: 'Unit',
                then: createLinearCostCalculationValidationSchema(drivers, true),
                otherwise: createLinearCostCalculationValidationSchema(drivers, false),
            }),
        }),
        fixedCostCalculation: Yup.object().when('selectedCostCalculationType', {
            is: 'Fixed',
            then: fixedCostCalculationValidationSchema,
        }),
        formulaCostCalculation: Yup.object().when('selectedCostCalculationType', {
            is: 'Formula',
            then: formulaCostCalculationValidationSchema,
        }),
        expenseCurrency: Yup.string().required().oneOf(Object.values(Currency)),
    });
};
