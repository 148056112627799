import { t } from '@lingui/macro';
import { FieldTextControlled, FormItem } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { CustomPartQuoteFormValues } from '../types';

export function FormItemOfferNumber() {
    const { control } = useFormContext<CustomPartQuoteFormValues>();

    return (
        <FormItem label={t`Offer number`} required>
            <FieldTextControlled control={control} required name="offerNumber" />
        </FormItem>
    );
}
