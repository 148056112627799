import { Text, colorSystem } from '@luminovo/design-system';
import { PartCategoryDTO } from '@luminovo/http-client';
import { Close } from '@mui/icons-material';
import { Divider, styled } from '@mui/material';

export const CategoryBlockView = ({
    block,
    onRemove,
    isDisabled,
}: {
    block: PartCategoryDTO;
    onRemove: (block: PartCategoryDTO) => void;
    isDisabled: boolean;
}): JSX.Element => {
    if (isDisabled) {
        return (
            <Container>
                <FilterSectionDisabled>
                    <Text variant="body-small" color={colorSystem.neutral[6]}>
                        {block.name}
                    </Text>
                </FilterSectionDisabled>

                <Separator orientation="vertical" />

                <Close style={{ fontSize: 14, overflow: 'hidden', padding: '4px', color: colorSystem.neutral[5] }} />
            </Container>
        );
    }
    return (
        <Container>
            <FilterSection onClick={() => onRemove(block)}>
                <Text variant="body-small">{block.name}</Text>
            </FilterSection>

            <Separator orientation="vertical" />

            <SectionClose>
                <Close style={{ fontSize: 14, overflow: 'hidden' }} onClick={() => onRemove(block)} />
            </SectionClose>
        </Container>
    );
};

const Container = styled('span')({
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    background: `linear-gradient(${colorSystem.neutral[1]},${colorSystem.neutral[1]})`,
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 4,
    height: 24,
});

const FilterSection = styled('span')({
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background 0.2s linear',
    '&:hover': {
        background: colorSystem.neutral[2],
    },
    overflow: 'hidden',
    cursor: 'pointer',
});

const SectionClose = styled(FilterSection)({
    color: colorSystem.neutral[6],
    padding: 4,
});

const Separator = styled(Divider)({
    margin: 0,
    height: 'calc(100% - 0px)',
});

const FilterSectionDisabled = styled('span')({
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background 0.2s linear',
    overflow: 'hidden',
});
