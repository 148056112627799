import { t } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { FieldValues, Path } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldText, FieldTextProps } from './FieldText';

interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<FieldControllerProps<FieldTextProps, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the input field is required.
     */
    required?: boolean;
    /**
     * The minimum (inclusive) number of characters.
     */
    min?: number;

    /**
     * The maximum (inclusive) number of characters.
     */
    max?: number;
}

export function FieldTextControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({ required, min, max, validate, ...props }: ControllerProps<TFieldValues, TName>): JSX.Element {
    return (
        <FieldController Field={FieldText} validate={createValidator({ required, min, max, validate })} {...props} />
    );
}

function createValidator({
    required,
    min,
    max,
    validate,
}: Pick<ControllerProps, 'required' | 'min' | 'max' | 'validate'>) {
    return (x: unknown): string | undefined => {
        if (required && !Boolean(x)) {
            return t`Required`;
        }

        if (typeof x === 'string' && isPresent(min) && x.length < min) {
            return t`Must have at least ${formatDecimal(min)} characters`;
        }

        if (typeof x === 'string' && isPresent(max) && x.length > max) {
            return t`Must have at most ${formatDecimal(max)} characters`;
        }

        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
