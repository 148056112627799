import { Trans } from '@lingui/macro';
import { formatCurrency } from '@luminovo/commons';
import { FieldNumericController } from '@luminovo/design-system';
import { InputAdornment } from '@mui/material';
import { selectableColumn } from './columnSelectable';

export const columnUnitPrice = selectableColumn({
    attribute: 'unitPrice',
    label: <Trans>Unit price</Trans>,
    align: 'right',
    render: ({ extracted, row }) => {
        return formatCurrency(extracted, row.data.unitPrice.currency, 'unit-price');
    },
    InputComponent: ({ control, row }) => {
        return (
            <>
                <FieldNumericController
                    FieldProps={{
                        autoFocus: true,
                        fullWidth: true,
                        InputProps: {
                            endAdornment: <InputAdornment position="end">{row.data.unitPrice.currency}</InputAdornment>,
                        },
                    }}
                    control={control}
                    name="unitPrice"
                    required
                    min={0}
                />
            </>
        );
    },
});
