import { SolutionDTO } from '@luminovo/http-client';
import { Solution } from '../types';

export const parseSolution = (solution: SolutionDTO): Solution => {
    const firstOption = solution.purchase_options[0];

    return {
        token: solution.token,
        solutionTags: solution.tags,
        firstPurchaseOption: firstOption,
        availability: firstOption.availability,
        unitPrice: firstOption.unit_price ?? null,
        additionalCost: firstOption.additional_cost ?? null,
        landedUnitPrice: firstOption.landed_unit_price ?? null,
        totalPrice: solution.total_price,
    };
};
