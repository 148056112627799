import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, StickyLayout } from '@luminovo/design-system';
import { PCBFileTypes } from '@luminovo/http-client';
import { Box, styled } from '@mui/material';
import React from 'react';
import { LayerAssignmentFileCard, LayerGroupHeader } from '../LayerAssignment';
import {
    ContainersWithFiles,
    drillFileFilter,
    PCBFileWithId,
    StateFileType,
    UpdateContainersWithFiles,
} from '../LayerAssignment/utils/layerAssignmentsUtils';
import { DrillFileHeader } from './components/DrillFileHeader';
import { DrillRow } from './components/DrillRow';

const StyledLayerGroupColumn = styled(Box)({
    boxSizing: 'border-box',
    backgroundColor: colorSystem.neutral.white,
    padding: '20px 0',
    borderRadius: '8px',
    flex: 1,
    minWidth: '380px',
    maxWidth: '380px',
    minHeight: 'calc(100vh - 240px)',
});

export const DrillManagement: React.FunctionComponent<{
    items: ContainersWithFiles;
    isEditable: boolean;
    handleOnChange: UpdateContainersWithFiles;
}> = ({ items, isEditable, handleOnChange }) => {
    const drillFiles = React.useMemo(() => items[StateFileType.MechanicalFiles].files.filter(drillFileFilter), [items]);

    const handleDrillSelect = (index: number, drillFile: PCBFileWithId) => {
        handleOnChange((files) => {
            const mechanicalFiles = files[StateFileType.MechanicalFiles];
            const fileIndex = mechanicalFiles.files.findIndex((file) => file.id === drillFile.id);

            const layerPosition: { from: number | undefined; to: number | undefined } = {
                from: undefined,
                to: undefined,
            };

            if (fileIndex !== undefined && fileIndex !== -1) {
                const file = mechanicalFiles.files[fileIndex];

                if (file.fileType.to !== undefined && file.fileType.from !== undefined) {
                    //editing mode
                } else {
                    // Adding 1 for index because drill indices are start at 1
                    if (isPresent(file.fileType.from)) {
                        layerPosition.from = file.fileType.from;
                        layerPosition.to = index + 1;
                    } else {
                        layerPosition.from = index + 1;
                    }
                }

                const updatedFile = {
                    ...mechanicalFiles.files[fileIndex],
                    fileType: {
                        ...mechanicalFiles.files[fileIndex].fileType,
                        ...layerPosition,
                    },
                };

                return {
                    ...files,
                    [StateFileType.MechanicalFiles]: {
                        dirty: true,
                        files: [
                            ...mechanicalFiles.files.slice(0, fileIndex),
                            updatedFile,
                            ...mechanicalFiles.files.slice(fileIndex + 1, mechanicalFiles.files.length),
                        ],
                    },
                };
            }

            return files;
        });
    };

    const handleDrillFileTypeChange = (fileType: PCBFileTypes, drillFile: PCBFileWithId) => {
        handleOnChange((files) => {
            const fileIndex = files[StateFileType.MechanicalFiles].files.findIndex((file) => file.id === drillFile.id);

            if (fileIndex !== undefined && fileIndex !== -1) {
                return {
                    ...items,
                    [StateFileType.MechanicalFiles]: {
                        files: [
                            ...items[StateFileType.MechanicalFiles].files.slice(0, fileIndex),
                            {
                                ...items[StateFileType.MechanicalFiles].files[fileIndex],
                                fileType: {
                                    ...items[StateFileType.MechanicalFiles].files[fileIndex].fileType,
                                    fileType: fileType,
                                },
                            },
                            ...items[StateFileType.MechanicalFiles].files.slice(
                                fileIndex + 1,
                                items[StateFileType.MechanicalFiles].files.length,
                            ),
                        ],
                        dirty: true,
                    },
                };
            }

            return files;
        });
    };

    return (
        <Flexbox
            style={{
                width: 'max-content',
                padding: '16px',
                flexDirection: 'row',
                gap: '16px',
            }}
        >
            <StickyLayout style={{ background: 'none' }}>
                <StyledLayerGroupColumn>
                    <LayerGroupHeader
                        name={t`Gerber files`}
                        style={{
                            paddingInline: '16px',
                            paddingBlockEnd: '40px',
                        }}
                    />
                    <Flexbox flexDirection={'column'}>
                        {items[StateFileType.Copper].files.map((file: PCBFileWithId, index: number) => (
                            <Box
                                key={file.name}
                                display={'flex'}
                                style={{
                                    boxSizing: 'border-box',
                                    height: '99px',
                                    padding: '12px 16px',
                                    borderWidth: '1px',
                                    borderColor: colorSystem.neutral[4],
                                    borderBottomStyle:
                                        index + 1 !== items[StateFileType.Copper].files.length ? 'dashed' : 'none',
                                }}
                            >
                                <LayerAssignmentFileCard
                                    key={file.name}
                                    file={file}
                                    style={{ cursor: 'default' }}
                                    fileName={`${index + 1}. ${file.name}`}
                                    isClicked={false}
                                    isActive={false}
                                    disabled={true}
                                />
                            </Box>
                        ))}
                    </Flexbox>
                </StyledLayerGroupColumn>
            </StickyLayout>

            {drillFiles?.map((drillFile: PCBFileWithId, drillIndex: number) => (
                <StyledLayerGroupColumn key={drillFile.id} style={{ paddingBlockStart: '12px' }}>
                    <DrillFileHeader
                        drillFile={drillFile}
                        disabled={!isEditable}
                        onChange={(fileType) => {
                            handleDrillFileTypeChange(fileType, drillFiles[drillIndex]);
                        }}
                        style={{ marginInline: '16px' }}
                    />
                    <Flexbox flexDirection={'column'}>
                        {items[StateFileType.Copper].files.map((file: PCBFileWithId, index: number) => (
                            <DrillRow
                                key={file.name}
                                onSelect={handleDrillSelect}
                                disabled={!isEditable}
                                index={index}
                                drillFile={drillFile}
                                style={{
                                    borderBottomStyle:
                                        index + 1 !== items[StateFileType.Copper].files.length ? 'dashed' : 'none',
                                }}
                            />
                        ))}
                    </Flexbox>
                </StyledLayerGroupColumn>
            ))}
        </Flexbox>
    );
};
