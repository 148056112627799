import { t } from '@lingui/macro';
import { colorSystem, DestructiveTertiaryIconButton, Dropzone, Flexbox, Text } from '@luminovo/design-system';
import { Delete, InsertDriveFileRounded } from '@mui/icons-material';
import { styled } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FileWithUrlAndName } from '../../../../../resources/assembly/assemblyFrontendTypes';
import { AddCustomPartAction, AddCustomPartState } from './reducer';

const forbiddenMIMETypes: string[] = ['application/x-ms-dos-executable', 'application/octet-stream'];

export const FieldItemFiles = ({
    state,
    dispatch,
}: {
    state: AddCustomPartState;
    dispatch: React.Dispatch<AddCustomPartAction>;
}) => {
    const handleChangeDropZone = (files: File[]) => {
        const filesWithUrl = files.map((file) => {
            if (forbiddenMIMETypes.includes(file.type)) {
                enqueueSnackbar(t`Cannot upload unsupported file type`, { variant: 'error' });
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            const url = URL.createObjectURL(file);

            const fileWithUrl: FileWithUrlAndName = {
                file: file,
                url,
                name: file.name,
            };
            return fileWithUrl;
        });
        dispatch({ type: 'setFilesToUpload', uploadFiles: filesWithUrl });
    };

    const onDelete = (file: FileWithUrlAndName) => {
        dispatch({ type: 'removeFile', uploadFiles: file });
    };

    return (
        <>
            {state.uploadFiles.map((file, i) => {
                return (
                    <Flexbox alignItems="center" justifyContent="space-between" key={i}>
                        <StyledLink target="_blank" rel="noopener noreferrer" href={file.url}>
                            <InsertDriveFileRounded style={{ color: colorSystem.neutral[7] }} />
                            <Text variant="body-semibold" color={colorSystem.neutral[7]}>
                                {file.name}
                            </Text>
                        </StyledLink>
                        <DestructiveTertiaryIconButton
                            onClick={() => onDelete(file)}
                            style={{ padding: 0, height: 'unset' }}
                        >
                            <Delete fontSize="inherit" />
                        </DestructiveTertiaryIconButton>
                    </Flexbox>
                );
            })}

            <Dropzone
                multiple
                title={''}
                onDropAccepted={handleChangeDropZone}
                accept={null}
                overrides={{
                    Container: DropzoneContainer,
                }}
            />
        </>
    );
};

const StyledLink = styled('a')({
    color: 'inherit',
    textDecoration: 'inherit',
    display: 'flex',
    alignItems: 'center',
});

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
});
