import { Dictionary, groupBy } from '@luminovo/commons';
import {
    BatchPostDTO,
    CostCellPostDTO,
    FixedCostCellPostDTO,
    FixedFormulaDTO,
    FractionFormulaDTO,
    ScenarioCostPostDTO,
    UnitCostPostDTO,
} from '@luminovo/http-client';
import { assertUnreachable } from '../../../../../utils/typingUtils';
import { CostSpecification, DynamicCostCell, FixedCostCell, FixedPercentageCell } from '../../types/cellTypes';
import { ExtraCost, TableColumn } from '../../types/tableColumnTypes';

/* eslint-disable camelcase */

const getCostSpecification = (costSpecification: CostSpecification): UnitCostPostDTO => {
    const type = costSpecification.type;
    switch (type) {
        case 'fixed':
            return {
                type: 'Fixed',
                data: {
                    amount: costSpecification.value.amount,
                    currency: costSpecification.value.currency,
                },
            };
        case 'fraction':
            return {
                type: 'Fraction',
                data: costSpecification.value.toString(),
            };
        case 'formula-fixed':
            const fixedCalculated: FixedFormulaDTO = {
                type: 'Fixed',
                data: {
                    script: costSpecification.script,
                    currency: costSpecification.currency,
                },
            };
            return {
                type: 'Formula',
                data: costSpecification.isOverwritten
                    ? {
                          type: 'Overridden',
                          data: { calculated: fixedCalculated, manually_overridden: costSpecification.value },
                      }
                    : {
                          type: 'Evaluated',
                          data: { calculated: fixedCalculated },
                      },
            };
        case 'formula-fraction':
            const fractionCalculated: FractionFormulaDTO = {
                type: 'Fraction',
                data: { script: costSpecification.script },
            };
            return {
                type: 'Formula',
                data: costSpecification.isOverwritten
                    ? {
                          type: 'Overridden',
                          data: { calculated: fractionCalculated, manually_overridden: costSpecification.value },
                      }
                    : {
                          type: 'Evaluated',
                          data: { calculated: fractionCalculated },
                      },
            };
        default:
            assertUnreachable(type);
    }
};

const convertDynamicCell = (dynamicCell: DynamicCostCell): CostCellPostDTO => {
    return {
        unit_cost: getCostSpecification(dynamicCell.costSpecification),
        is_locked: dynamicCell.isLocked,
    };
};

const convertExtraCosts = (otherCost: ExtraCost) => {
    return {
        name: otherCost.name,
        cost: convertDynamicCell(otherCost.cost),
    };
};

const convertFixedCostCell = (fixedCostCell: FixedCostCell | FixedPercentageCell): FixedCostCellPostDTO => {
    return {
        unit_cost: fixedCostCell.unitCost,
    };
};

const convertDynamicCostCellToDTO = (costCell: DynamicCostCell): CostCellPostDTO | null => {
    return costCell.shouldHideCell === true ? null : convertDynamicCell(costCell);
};

const convertTableColumnToBatchSizeCost = (tableColumn: TableColumn): BatchPostDTO => {
    return {
        batch_size: tableColumn.batchSize,
        cost: {
            material_cost: {
                custom_cost: {
                    profit: convertDynamicCostCellToDTO(tableColumn.sections[0].customCosts.profit),
                    discount: convertDynamicCostCellToDTO(tableColumn.sections[0].customCosts.discount),
                    extra_costs: tableColumn.sections[0].customCosts.extraCosts.map(convertExtraCosts),
                },
            },
            manufacturing_cost: {
                custom_cost: {
                    profit: convertDynamicCostCellToDTO(tableColumn.sections[1].customCosts.profit),
                    discount: convertDynamicCostCellToDTO(tableColumn.sections[1].customCosts.discount),
                    extra_costs: tableColumn.sections[1].customCosts.extraCosts.map(convertExtraCosts),
                },
            },
            additional_cost: {
                profit: convertDynamicCostCellToDTO(tableColumn.sections[3].profit),
                discount: convertDynamicCostCellToDTO(tableColumn.sections[3].discount),
                other_costs: tableColumn.sections[2].value.map(convertExtraCosts),
                post_profit_costs: tableColumn.sections[4].value.map(convertExtraCosts),
            },
            project_cost: {
                profit: convertDynamicCostCellToDTO(tableColumn.sections[5].profit),
                price: convertFixedCostCell(tableColumn.sections[5].price),
                cost: convertFixedCostCell(tableColumn.sections[5].cost.cost),
            },
        },
    };
};

const assertAllSourcingCombinationsHaveSameLeadTimeAndSourcingCombinationId = (
    tableColumnsGroupedBySourcingCombinationId: Dictionary<TableColumn[]>,
): void => {
    return Object.keys(tableColumnsGroupedBySourcingCombinationId).forEach((sourcingCombination) => {
        const leadTimes = tableColumnsGroupedBySourcingCombinationId[sourcingCombination].map((t) => t.leadTime);
        const sourcingCombinationIds = tableColumnsGroupedBySourcingCombinationId[sourcingCombination].map(
            (t) => t.sourcingCombinationId,
        );

        if (
            !leadTimes.every(
                (leadTime) =>
                    leadTime === leadTimes[0] ||
                    !sourcingCombinationIds.every(
                        (sourcingCombination) => sourcingCombination === sourcingCombination[0],
                    ),
            )
        ) {
            throw Error(`All sourcing combinations must have the same lead time. tableColumnsGroupedBySourcingCombinationId is below

            ${JSON.stringify(tableColumnsGroupedBySourcingCombinationId)}
            `);
        }
        return;
    });
};

export const convertTableColumnsToCosts = (tableColumns: TableColumn[]): ScenarioCostPostDTO[] => {
    const tableColumnsGroupedBySourcingCombinationId = groupBy(tableColumns, (t) => t.sourcingCombinationId);
    assertAllSourcingCombinationsHaveSameLeadTimeAndSourcingCombinationId(tableColumnsGroupedBySourcingCombinationId);
    return Object.keys(tableColumnsGroupedBySourcingCombinationId).map((sourcingCombination) => {
        return {
            sourcing_combination_id:
                tableColumnsGroupedBySourcingCombinationId[sourcingCombination][0].sourcingCombinationId,
            batch_size_costs: tableColumnsGroupedBySourcingCombinationId[sourcingCombination].map(
                convertTableColumnToBatchSizeCost,
            ),
        };
    });
};

/* eslint-enable camelcase */
