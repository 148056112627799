import { MessageDescriptor } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { PrimaryButton, Select, Text } from '@luminovo/design-system';
import { DurationUnit } from '@luminovo/http-client';
import { Divider, FormControl, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import React from 'react';
import DialogBox from '../../../../components/dialogBox/DialogBox';
import { TransEnum } from '../../../../components/localization/TransEnum';
import { InputControl } from '../../../../components/MonacoEditor/editorTypes';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { useMutationValidateFormula } from '../../../../resources/formula/formulaHandler';
import { FormulaType } from '../../../Calculation/Templates/CalculationTemplateForm/utils/types';
import { FormulaAndInputControls } from './FormulaAndInputControls/FormulaAndInputControls';

function UnitFormControl<U extends Currency | FormulaType | DurationUnit>({
    unit,
    unitLabel,
    onChange,
    unitTranslations,
}: {
    unit: U;
    unitLabel?: string;
    onChange: (e: SelectChangeEvent<unknown>) => void;
    unitTranslations: Record<U, MessageDescriptor>;
}): JSX.Element {
    return (
        <FormControl>
            <Text variant={'h4'}>{unitLabel ?? <Trans>Unit</Trans>}</Text>
            <Select
                value={unit}
                style={{ minWidth: '120px', marginTop: '4px', marginBottom: '24px' }}
                onChange={onChange}
            >
                {Object.keys(unitTranslations).map((k) => (
                    <MenuItem key={k} value={k}>
                        <TransEnum text={k} translations={unitTranslations} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

interface AdvancedFormulaDialogProps<U extends Currency | FormulaType | DurationUnit> {
    onSubmitClick: (dialogValue: DialogValue<U>) => void;
    initialFormValue: string;
    isDialogOpen: boolean;
    onReject: () => void;
    initialUnit: U;
    unitTranslations: Record<U, MessageDescriptor>;
    unitLabel?: string;
    inputControls: InputControl[];
    isLoading?: boolean;
    includeDocumentation?: boolean;
}

export interface DialogValue<U> {
    formula: string | undefined;
    unit: U;
}

export function AdvancedFormulaDialog<U extends Currency | FormulaType | DurationUnit>({
    onSubmitClick,
    initialFormValue,
    isDialogOpen,
    onReject,
    initialUnit,
    unitTranslations,
    unitLabel,
    inputControls,
    isLoading = false,
    includeDocumentation = true,
}: AdvancedFormulaDialogProps<U>): JSX.Element {
    const [unit, setUnit] = React.useState<U>(initialUnit);
    const [formula, setFormula] = React.useState<string>(initialFormValue);
    const {
        mutateAsync: validate,
        data: validationData,
        isLoading: validateIsLoading,
    } = useMutationValidateFormula(formula ?? '');

    return isLoading ? (
        <SpinnerWithBackdrop noBackdrop />
    ) : (
        <DialogBox
            title={t`Build formula`}
            isDialogOpen={isDialogOpen}
            onReject={onReject}
            /* eslint-disable-next-line spellcheck/spell-checker */
            maxWidth={'xl'}
        >
            <FormulaAndInputControls
                formula={formula}
                setFormula={setFormula}
                initialInputControls={inputControls}
                validate={validate}
                validationData={validationData}
                validateIsLoading={validateIsLoading}
                unitFormControl={
                    <UnitFormControl
                        unit={unit}
                        /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                        onChange={(e) => setUnit(e.target.value as U)}
                        unitTranslations={unitTranslations}
                        unitLabel={unitLabel}
                    />
                }
                includeDocumentation={includeDocumentation}
            />
            <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
            <Grid container direction="row-reverse">
                <Grid item>
                    <PrimaryButton
                        disabled={formula.length === 0}
                        onClick={async () => {
                            const result = await validate();
                            if (result.result === 'Error') {
                                return;
                            }
                            onSubmitClick({ formula, unit });
                            onReject();
                        }}
                    >
                        <Trans>Save</Trans>
                    </PrimaryButton>
                </Grid>
            </Grid>
        </DialogBox>
    );
}
