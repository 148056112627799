import { SolutionTag } from '@luminovo/http-client';
import { Solution, SolutionStatus } from '../../types';
import { extractSolutionStatus } from './extractSolutionStatus';

export function extractAvailabilityStatus(solution: Pick<Solution, 'solutionTags'>): SolutionStatus {
    const availabilitySolutionTags = solution.solutionTags.filter(
        (tag) =>
            tag.tag === SolutionTag.LongLeadTime ||
            tag.tag === SolutionTag.Unavailable ||
            tag.tag === SolutionTag.UnknownLeadTime,
    );
    return extractSolutionStatus({ solutionTags: availabilitySolutionTags }, 'original');
}
