import { ImporterField, ImporterRow, ParsedValue } from '../types';

export function getCellValue(row: ImporterRow, field: ImporterField): ParsedValue<unknown> | undefined {
    const cell = row.cells.find((cell) => cell.field.id === field.id);

    if (cell === undefined) {
        return field.defaultValue;
    }

    if ('value' in cell.status) {
        return cell.status.value;
    }
    return undefined;
}
