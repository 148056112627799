import { colorSystem, Text as DesignSystemText, Tooltip } from '@luminovo/design-system';
import { styled } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { Text } from '@visx/text';
import { TooltipDetails } from '../../internal/GraphTooltip';
import { theme } from '../../internal/theme';

interface Datum {}

type Props<T extends Datum> = {
    data: T[];
    title: string;
    onBarClick?: (datum: T) => void;
    getLabel: (datum: T) => string | JSX.Element;
    getValue: (datum: T) => number;
    getColor: (datum: T) => { unselected: string; selected: { border: string; background: string } };
    getTooltip?: (datum: T) => { title: JSX.Element; description: JSX.Element };
    isSelected?: (datum: T) => boolean;
};

export function AngularProgressBar<T extends Datum>(props: Props<T>): JSX.Element {
    return <ParentSize>{({ width }) => <AngularProgressBarInner width={width} {...props} />}</ParentSize>;
}

const notSelected = () => false;

function AngularProgressBarInner<T extends Datum>({
    data,
    title,
    onBarClick,
    width: parentWidth,
    getColor,
    getLabel,
    getValue,
    getTooltip,
    isSelected = notSelected,
}: Props<T> & { width: number }): JSX.Element {
    const width = parentWidth;
    const height = parentWidth / 2;

    const thickness = 20;
    const radius = width / 2 - thickness;
    const angleGap = 1;

    const startingAngle = 180;
    const totalAngularWidth = 180;

    const total = data.map((datum) => getValue(datum)).reduce((a, b) => a + b, 0);

    function getAngle(i: number): [number, number] {
        if (i < 0) {
            return [startingAngle, startingAngle];
        }
        if (i > data.length) {
            return [startingAngle + totalAngularWidth, startingAngle + totalAngularWidth];
        }
        const fraction = getValue(data[i]) / total;

        const [, to] = getAngle(i - 1);

        return [to, to + fraction * totalAngularWidth];
    }

    const segments = data.map((datum, i) => {
        const value = getValue(datum);
        const [d0, d1] = getAngle(i);
        if (value === 0) {
            return 0;
        }
        return (
            <Tooltip
                variant="white"
                disableMaxWidth={true}
                key={i}
                title={
                    <TooltipDetails
                        color={getColor(datum).unselected}
                        label={getLabel(datum)}
                        count={value}
                        tooltip={getTooltip ? getTooltip(datum) : undefined}
                    />
                }
                placement="top"
            >
                <g>
                    <SegmentPath
                        isSelected={isSelected(datum)}
                        onClick={() => onBarClick?.(datum)}
                        fill={getColor(datum)}
                        x={width / 2}
                        y={height}
                        radius={{ from: radius, to: radius + thickness }}
                        degree={{ from: d0 + angleGap, to: d1 - angleGap }}
                    />
                </g>
            </Tooltip>
        );
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-between' }}>
                {data.map((datum, i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }}>
                        <div
                            style={{
                                borderRadius: 2,
                                width: 8,
                                height: 8,
                                backgroundColor: getColor(datum).unselected,
                            }}
                        />
                        <DesignSystemText color={colorSystem.neutral[6]}>{getLabel(datum)}</DesignSystemText>
                        <DesignSystemText color={colorSystem.neutral[9]}>{getValue(datum)}</DesignSystemText>
                    </div>
                ))}{' '}
            </div>
            <svg width={width} height={height} overflow="visible">
                {segments}
                <Text
                    x={width / 2}
                    y={height / 2}
                    verticalAnchor="end"
                    fill={colorSystem.neutral[6]}
                    textAnchor="middle"
                    fontSize="14px"
                    fontFamily={theme.fontFamily}
                >
                    {title}
                </Text>
                <Text
                    x={width / 2}
                    y={height / 2 + 20}
                    verticalAnchor="start"
                    fill={colorSystem.neutral[8]}
                    textAnchor="middle"
                    fontWeight={'bold'}
                    fontSize="44px"
                    fontFamily={theme.fontFamily}
                >
                    {total}
                </Text>
            </svg>
        </div>
    );
}

const SegmentPath = ({
    x,
    y,
    radius,
    degree,
    fill,
    onClick,
    isSelected,
}: {
    x: number;
    y: number;
    radius: { from: number; to: number };
    degree: { from: number; to: number };
    fill: { unselected: string; selected: { border: string; background: string } };
    onClick?: () => void;
    isSelected?: boolean;
}): JSX.Element => {
    // shorthand for radius.from, radius.to, degree.from, degree.to
    const { from: r0, to: r1 } = radius;
    const { from: d0, to: d1 } = degree;

    const arc = Math.abs(d0 - d1) > 180 ? 1 : 0;
    const point = (radius: number, degree: number): string =>
        polarToCartesian({ x, y, r: radius, degrees: degree })
            .map((n) => n.toPrecision(5))
            .join(',');

    const path = [
        `M${point(r0, d0)}`,
        `A${r0},${r0},0,${arc},1,${point(r0, d1)}`,
        `L${point(r1, d1)}`,
        `A${r1},${r1},0,${arc},0,${point(r1, d0)}`,
        'Z',
    ].join('');

    return (
        <ClickablePath
            stroke={isSelected ? fill.selected.border : undefined}
            onClick={onClick}
            d={path}
            fill={isSelected ? fill.selected.background : fill.unselected}
            strokeWidth={isSelected ? 1 : 0}
            strokeLinejoin="round"
            cursor={'pointer'}
        />
    );
};

const ClickablePath = styled('path')({
    ...theme.hoverable,
});

function polarToCartesian({ x, y, r, degrees }: { x: number; y: number; r: number; degrees: number }) {
    const radians = (degrees * Math.PI) / 180.0;
    return [x + r * Math.cos(radians), y + r * Math.sin(radians)];
}
