import { Trans } from '@lingui/macro';
import { escapeRegExp } from '@luminovo/commons';
import { Flexbox, SecondaryButton, Text, colorSystem } from '@luminovo/design-system';
import { GenericFullPart, OtsFullPart } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { InfiniteData } from '@tanstack/react-query';
import React from 'react';
import { isEmptyQuery } from '../../../../components/SearchContainer';
import { SectionOfScreen } from '../../../../resources/part/partFrontendTypes';
import { useOtsPartAddDialog } from '../../PartDetailsPage/components/OtsPartDialogs';
import { PartSearchInput, usePartSearchState } from '../../PartSearchPage/PartSearchInput';
import { SearchResultsPage, useSearchParts } from '../../PartSearchPage/useSearchParts';
import { OtsPartSearchTable } from './OtsPartSearchTable';

export const OtsPartSearch = ({
    handleLinkPart,
    handleUnlinkPart,
    existingPartIds,
    ipnId,
    canUserEditComponent,
}: {
    handleLinkPart: (sectionOfScreen: SectionOfScreen) => (newPart: OtsFullPart | GenericFullPart) => void;
    handleUnlinkPart: (partId: string) => void;
    existingPartIds: string[];
    ipnId: string;
    canUserEditComponent: boolean;
}) => {
    const searchState = usePartSearchState({
        history: {
            enabled: true,
            // we render this component on the page where IPN search is also present
            // thus they will collide with each other, and we want to avoid that, thus this strange parameter name
            // eslint-disable-next-line spellcheck/spell-checker
            queryParameter: 'part-search',
        },
    });
    const { openDialog: openOtsPartAddDialog } = useOtsPartAddDialog();

    const {
        data: searchResults,
        isFetching,
        fetchNextPage,
        hasNextPage,
    } = useSearchParts({ searchState, rfqContext: { rfq_context: 'OutsideRfQ' } });

    const currentPage = searchResults && searchResults.pages[searchResults.pages.length - 1];
    const searchEntered = !isEmptyQuery(searchState);

    return (
        <>
            <Flexbox gap="8px" flexDirection="column" padding="2px">
                <PartSearchInput searchResultsPage={currentPage} searchState={searchState} />
            </Flexbox>
            {searchEntered && isFetching && !searchResults && (
                <div style={{ padding: '4px' }}>
                    <CircularProgress size={20} />
                </div>
            )}
            {searchEntered && searchResults && (
                <>
                    <PartResultsContainer
                        searchResults={searchResults}
                        handleLinkPart={handleLinkPart}
                        handleUnlinkPart={handleUnlinkPart}
                        existingPartIds={existingPartIds}
                        ipnId={ipnId}
                        openOtsPartAddDialog={openOtsPartAddDialog}
                        canUserEditComponent={canUserEditComponent}
                    />
                    <Box display="grid" gap="8px" style={{ placeItems: 'center' }}>
                        {hasNextPage ? (
                            <SecondaryButton
                                disabled={isFetching}
                                onClick={() => fetchNextPage()}
                                startIcon={isFetching && <CircularProgress size={20} />}
                            >
                                <Trans>Load more</Trans>
                            </SecondaryButton>
                        ) : (
                            <NoMoreResultsContainer
                                searchResults={searchResults}
                                openOtsPartAddDialog={openOtsPartAddDialog}
                                handleLinkPart={handleLinkPart}
                            />
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

const PartResultsContainer = React.memo(
    ({
        searchResults,
        handleLinkPart,
        handleUnlinkPart,
        existingPartIds,
        ipnId,
        canUserEditComponent,
        openOtsPartAddDialog,
    }: {
        searchResults: InfiniteData<SearchResultsPage>;
        handleLinkPart: (sectionOfScreen: SectionOfScreen) => (newPart: OtsFullPart | GenericFullPart) => void;
        handleUnlinkPart: (partId: string) => void;
        existingPartIds: string[];
        ipnId: string;
        canUserEditComponent: boolean;
        openOtsPartAddDialog: ({
            initialMpn,
            onSuccess,
        }: {
            initialMpn: string;
            onSuccess: (newPart: OtsFullPart) => void;
        }) => void;
    }) => {
        const parts = searchResults?.pages.flatMap((page) => page?.page ?? []) ?? [];

        const mpnTerms = getMpnTerms(searchResults);

        const highlights = Object.assign({}, ...searchResults.pages.map((page) => (page as any).highlights ?? {})); // TODO

        return (
            <OtsPartSearchTable
                parts={parts}
                handleLinkPart={handleLinkPart}
                handleUnlinkPart={handleUnlinkPart}
                existingPartIds={existingPartIds}
                openOtsPartAddDialog={openOtsPartAddDialog}
                highlights={highlights}
                mpnTerms={mpnTerms}
                ipnId={ipnId}
                canUserEditComponent={canUserEditComponent}
            />
        );
    },
);

const NoMoreResultsContainer = ({
    searchResults,
    openOtsPartAddDialog,
    handleLinkPart,
}: {
    searchResults: InfiniteData<SearchResultsPage>;
    openOtsPartAddDialog: ({
        initialMpn,
        onSuccess,
    }: {
        initialMpn: string;
        onSuccess: (newPart: OtsFullPart) => void;
    }) => void;
    handleLinkPart: (sectionOfScreen: SectionOfScreen) => (newPart: OtsFullPart | GenericFullPart) => void;
}) => {
    const parts = searchResults?.pages.flatMap((page) => page?.page ?? []) ?? [];

    if (parts.length === 0) {
        return null;
    }
    return (
        <>
            <Text variant="h5" color={colorSystem.neutral[7]}>
                <Trans> No more results for your search </Trans>
            </Text>
            <span>
                <SecondaryButton
                    onClick={() => {
                        openOtsPartAddDialog({
                            initialMpn: '',
                            onSuccess: handleLinkPart('otsPartForm'),
                        });
                    }}
                    size="medium"
                    disableTouchRipple
                >
                    <Trans>Add off-the-shelf part manually</Trans>
                </SecondaryButton>
            </span>
        </>
    );
};

/**
 * @return an array of regexes that can be used to highlight the mpn terms in the search results
 */
export function getMpnTerms(searchResults?: InfiniteData<SearchResultsPage>): RegExp[] {
    if (!searchResults) {
        return [];
    }
    const currentPage = searchResults.pages[searchResults.pages.length - 1];

    const mpnTerms = currentPage.mpnTerms.map((mpn) => {
        const query = mpn
            .split('*')
            .map((term) => {
                return escapeRegExp(term);
            })
            .join('.*');
        // eslint-disable-next-line spellcheck/spell-checker
        return new RegExp(query, 'i');
    });

    return mpnTerms;
}
