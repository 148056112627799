/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface RutronikCredentialsDTO extends r.Static<typeof RutronikCredentialsDTORuntype> {}
export const RutronikCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface RutronikCredentialsInputDTO extends r.Static<typeof RutronikCredentialsInputDTORuntype> {}
export const RutronikCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface RutronikResponseBodyDTO extends r.Static<typeof RutronikResponseBodyDTORuntype> {}
export const RutronikResponseBodyDTORuntype = r.Record({
    data: RutronikCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
