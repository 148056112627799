import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../boundingBox';
import { generateId } from '../generateId';
import { Attribute, AttributeExtractionRule, ExtractionItem, Extractor } from '../types';

export function extractLines<TAttribute extends Attribute>({
    extractionRules,
}: {
    extractionRules: AttributeExtractionRule<TAttribute>[];
}): Extractor<TAttribute> {
    return {
        generator: async function* (azureExtractionResult: AzureExtractionResult): AsyncGenerator<ExtractionItem> {
            for (const page of azureExtractionResult.analyzeResult.pages) {
                for (const line of page.lines) {
                    yield {
                        value: {
                            content: line.content,
                            boundingRegions: [
                                {
                                    pageNumber: page.pageNumber,
                                    polygon: line.polygon,
                                },
                            ],
                        },
                        context: undefined,
                        // Assuming 1 as lines have no confidence
                        confidence: 1,
                    };
                }
            }
        },

        extractRegion: ({ value }) => {
            const { content } = value;
            const { pageNumber, polygon } = value.boundingRegions[0];
            return [
                {
                    id: generateId({ content, boundingRegions: [{ pageNumber, polygon }] }),
                    content: content,
                    pageNumber,
                    box: BoundingBox.fromPolygon(polygon),
                    attributes: [],
                },
            ];
        },

        extractionRules,
    };
}
