import { Theme, useTheme } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox, FlexboxProps } from '../../Flexbox';

export interface FullWidthLayoutProps extends FlexboxProps {
    variant?: 'primary' | 'neutral';
}

function applyHeaderStylesToBackgroundContainer(
    theme: Theme,
    variant: FullWidthLayoutProps['variant'],
): React.CSSProperties {
    if (variant === 'primary') {
        return {
            background: theme.palette.primary.light,
        };
    } else if (variant === 'neutral') {
        return {
            background: colorSystem.neutral[1],
        };
    }
    return {};
}

export const FullWidthLayout: React.FunctionComponent<FullWidthLayoutProps> = ({ children, variant, ...rest }) => {
    const theme = useTheme();
    return (
        <Flexbox
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            paddingTop={'16px'}
            paddingLeft={'16px'}
            paddingRight={'16px'}
            paddingBottom={'16px'}
            boxSizing="border-box"
            style={applyHeaderStylesToBackgroundContainer(theme, variant)}
            {...rest}
        >
            {children}
        </Flexbox>
    );
};
