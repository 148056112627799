/* eslint-disable spellcheck/spell-checker */
export const es = {
    consentModal: {
        title: '🍪 ¡Usamos cookies!',
        description:
            'Hola, este sitio web utiliza cookies esenciales para garantizar su correcto funcionamiento, cookies de seguimiento para comprender cómo interactúa con él y cookies de soporte para atención al cliente y seguimiento de problemas. Estos dos últimos se fijarán sólo previo consentimiento. Si nos da su consentimiento, sus datos pueden procesarse fuera del EEE.',
        acceptAllBtn: 'Aceptar todo',
        acceptNecessaryBtn: 'Rechazar todo',
        showPreferencesBtn: 'Mas opciones',
    },
    preferencesModal: {
        title: '🍪 ¡Usamos cookies!',
        acceptAllBtn: 'Aceptar todo',
        acceptNecessaryBtn: 'Rechazar todo',
        savePreferencesBtn: 'Guardar ajustes',
        closeIconLabel: 'Cerrar',
        sections: [
            {
                title: 'Uso de cookies 📢',
                description:
                    'Utilizamos cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea. Puede elegir para cada categoría optar por participar o no participar cuando lo desee. Para obtener más detalles sobre las cookies y otros datos confidenciales, lea la <a href="https://luminovo.notion.site/Data-Security-Privacy-at-Luminovo-61603a59d0864f108028be963e7ad312" class="cc-link">política de privacidad</a> completa.',
            },
            {
                title: 'Cookies esenciales',
                description:
                    'Estas cookies son esenciales para el correcto funcionamiento de mi sitio web. Sin estas cookies, el sitio web no funcionaría correctamente. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=97025cec003c4f7b8c6a7dfb883a7906" rel="noopener noreferrer" target="_blank" class="cc-link">Ver información sobre cookies</a>',
                linkedCategory: 'required',
            },
            {
                title: 'Cookies de rendimiento y análisis',
                description:
                    'Estas cookies nos permiten realizar un seguimiento del rendimiento y el uso del producto. Utilizamos esta información para mejorar la experiencia del usuario y el rendimiento del producto. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=c2589aa5a8824a9785dc94561a3e0481" rel="noopener noreferrer" target="_blank" class="cc-link">Ver información sobre cookies</a>',
                linkedCategory: 'analytics',
            },
            {
                title: 'Cookies de soporte',
                description:
                    'Estas cookies nos permiten ofrecer apoyo y orientación a nuestros usuarios. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=7366ed2f10264209893b54184b1595b3" rel="noopener noreferrer" target="_blank" class="cc-link">Ver información sobre cookies</a>',
                linkedCategory: 'support',
            },
        ],
    },
};
