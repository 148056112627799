import { colorSystem } from '@luminovo/design-system';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { MarkerCircle } from '@visx/marker';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, LinePath } from '@visx/shape';
import { Tooltip, useTooltip } from '@visx/tooltip';
import { palette } from '../../color/palette';

export type TimeSeriesProps<T extends Datum> = {
    data: T[];
    Tooltip: ({ datum }: { datum: T }) => JSX.Element;
};

type Datum = {
    time: number;
    value: number;
};

export function TimeSeries<T extends Datum>(props: TimeSeriesProps<T>): JSX.Element {
    return (
        <ParentSize>
            {({ width, height }) => {
                return <TimeSeriesInner {...props} parentWidth={width} parentHeight={height} />;
            }}
        </ParentSize>
    );
}

function TimeSeriesInner<T extends Datum>({
    parentWidth = 0,
    parentHeight = 0,
    Tooltip: TooltipComponent,
    data,
}: TimeSeriesProps<T> & { parentWidth?: number; parentHeight?: number }): JSX.Element {
    const { tooltipOpen, tooltipTop, tooltipLeft, showTooltip, tooltipData, hideTooltip } = useTooltip();

    const getX = (d: T) => d.time;
    const getY = (d: T) => d.value;

    // bounds
    const xMax = parentWidth;
    const yMax = parentHeight;

    const xScale = scaleTime({
        domain: [
            data.reduce((max, d) => Math.min(max, getX(d)), Infinity),
            data.reduce((max, d) => Math.max(max, getX(d)), 0),
        ],
        range: [0, xMax],
        round: true,
    });

    // scales
    const yScale = scaleLinear({
        domain: [0, data.reduce((max, d) => Math.max(max, getY(d)), 0)],
        range: [yMax, 0],
    });

    const color = palette.default[0];

    return (
        <div style={{ position: 'relative', width: parentWidth, height: parentHeight }}>
            <svg
                width={parentWidth}
                height={parentHeight}
                style={{ overflow: 'visible' }}
                onMouseMove={(e) => {
                    const point = localPoint(e);
                    if (!point) {
                        // Do nothing if no point is found
                        return;
                    }
                    const inverseX = xScale.invert(point?.x ?? 0);
                    const nearest = data.reduce(
                        (nearest, d, i) => {
                            const x = getX(d);
                            const distance = Math.abs(x - inverseX.getTime());
                            if (distance < nearest.distance) {
                                return { distance, index: i };
                            }
                            return nearest;
                        },
                        { distance: Infinity, index: -1 },
                    ).index;
                    const dataPoint = data[nearest];
                    if (!dataPoint) {
                        // Do nothing if no dataPoint is found
                        return;
                    }
                    showTooltip({
                        tooltipLeft: xScale(dataPoint.time),
                        tooltipTop: 0,
                        tooltipData: <TooltipComponent datum={dataPoint} />,
                    });
                }}
                onMouseLeave={() => hideTooltip()}
            >
                <Group left={0} top={0}>
                    <GridRows
                        scale={yScale}
                        width={xMax}
                        stroke={palette.gridLines}
                        strokeOpacity={1}
                        strokeDasharray="2 8"
                        numTicks={Math.min(data.length, 5)}
                    />

                    <AxisBottom
                        top={yMax}
                        scale={xScale}
                        numTicks={Math.min(data.length, 5)}
                        stroke={colorSystem.neutral[2]}
                        tickStroke={colorSystem.neutral[2]}
                        tickLabelProps={() => ({
                            fill: colorSystem.neutral[8],
                            fontSize: 12,
                            textAnchor: 'middle',
                            fontFamily: "'Noto Sans', sans-serif",
                        })}
                    />
                    <AxisLeft
                        top={0}
                        scale={yScale}
                        numTicks={Math.min(data.length, 5)}
                        stroke={colorSystem.neutral[2]}
                        tickStroke={colorSystem.neutral[2]}
                        tickLabelProps={() => ({
                            fill: colorSystem.neutral[8],
                            fontSize: 12,
                            textAnchor: 'end',
                            fontFamily: "'Noto Sans', sans-serif",
                        })}
                    />

                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor={palette.default[1]} stopOpacity={1} />
                        <stop offset="80%" stopColor={palette.default[2]} stopOpacity={1} />
                        <stop offset="100%" stopColor={palette.default[3]} stopOpacity={1} />
                    </linearGradient>
                    <MarkerCircle id="marker-circle" fill={color} refX="4" size={4} />
                    <AreaClosed
                        data={data.sort((a, b) => a.time - b.time)}
                        x={(x) => xScale(getX(x))}
                        y={(y) => yScale(getY(y))}
                        yScale={yScale}
                        fill={'url(#gradient'}
                        curve={curveLinear}
                    />

                    <LinePath
                        data={data}
                        x={(x) => xScale(getX(x))}
                        y={(y) => yScale(getY(y))}
                        stroke={color}
                        strokeWidth={1}
                        curve={curveLinear}
                        markerMid={'url(#marker-circle)'}
                    />

                    {tooltipOpen && (
                        <>
                            <line
                                x1={tooltipLeft}
                                x2={tooltipLeft}
                                y1={0}
                                y2={parentHeight}
                                strokeDasharray="4,2"
                                stroke={color}
                                strokeWidth={1}
                            />
                        </>
                    )}
                </Group>
            </svg>
            {tooltipOpen && (
                <>
                    <Tooltip
                        // set this to random so it correctly updates with parent bounds
                        key={Math.random()}
                        top={tooltipTop}
                        left={tooltipLeft}
                    >
                        {tooltipData}
                    </Tooltip>
                </>
            )}
        </div>
    );
}
