import { t, Trans } from '@lingui/macro';
import {
    FieldSelectControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import {
    ActivityLevel,
    CalculationTypeWithoutFormula,
    DriverIdDTO,
    DurationUnit,
    DurationUnitOptions,
    TimeCalculationTypes,
} from '@luminovo/http-client';
import { formatDriverLabel } from '@luminovo/manufacturing-core';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Path, useFormContext, useWatch } from 'react-hook-form';
import { RadioGroupController } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { MonacoEditor } from '../../../../components/MonacoEditor';
import { MonacoEditorRef } from '../../../../components/MonacoEditor/MonacoEditor';
import { LoadingText, Skeleton, SpinnerWithBackdrop } from '../../../../components/Spinners';
import { colorSystem } from '../../../../design-system/themes';
import { durationUnitPublicTranslations } from '../../../../resources/activity/activityBackendTypes';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { throwErrorUnlessProduction } from '../../../../utils/customConsole';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { AdvancedFormulaDialog, DialogValue } from '../../shared/AdvancedFormulaDialog/AdvancedFormulaDialog';
import { filterActiveOrSelectedDriverDetails } from '../../shared/manufacturingUtils';
import {
    calculationTypePublicTranslations,
    calculationTypeWithNullPublicTranslation,
} from '../../shared/sharedManufacturingBackendTypes';
import { useManufacturingInputControls } from '../../utils/useInputControls';
import { AddActivityFormInputs } from '../AddActivityFormTypes';
import { FixedTimeCalculationComponent, VariableTimeCalculationComponent } from '../fixedAndVariableTimeComponents';

const StyledText = styled(Text)({
    color: colorSystem.neutral[7],
    marginBlockStart: '36px',
});

const useTimeCalculationSection = () => {
    const { control, watch } = useFormContext<AddActivityFormInputs>();
    const selectedUnitCalculationType = watch('selectedUnitCalculationType');
    const selectedBatchCalculationType = watch('selectedBatchCalculationType');
    const selectedProjectCalculationType = watch('selectedProjectCalculationType');
    const selectedLevel = watch('activity.level');

    return {
        control,
        selectedLevel,
        selectedUnitCalculationType,
        selectedBatchCalculationType,
        selectedProjectCalculationType,
    };
};

const useSelectedDriver = (driverId?: string) => {
    const { data, isLoading } = useHttpQuery(
        'GET /user-drivers/:driverId',
        { pathParams: { driverId: driverId ?? 'shouldNotBeCalled' } },
        { enabled: driverId !== undefined },
    );
    const selectedDriver = data?.data;
    return { selectedDriver, isLoading };
};

const useDriverSection = ({
    driverId,
    timeCalculationType,
}: {
    driverId?: string;
    timeCalculationType: TimeCalculationTypes;
}) => {
    const { control, watch } = useFormContext<AddActivityFormInputs>();
    const { selectedDriver, isLoading: isLoadingSelectedDriver } = useSelectedDriver(driverId);
    const { data, isLoading } = useHttpQuery('GET /user-drivers', {});
    const allDriverIds: DriverIdDTO[] = filterActiveOrSelectedDriverDetails(data?.data, selectedDriver);

    const selectedLevel = watch('activity.level');

    const isSelectedDriverPerPanel = selectedDriver?.is_per_panel;
    const isSelectedActivityPerPanel = useWatch({
        control: control,
        name: 'activity.isActivityPerPanel',
    });
    const shouldUnitPerPanelMismatchBeShown =
        selectedLevel === 'Unit' && !isSelectedActivityPerPanel && isSelectedDriverPerPanel;
    const isDriverPerPanelAndTimeCalculationBatch = timeCalculationType === 'Batch' && isSelectedDriverPerPanel;

    return {
        control,
        allDriverIds,
        allDrivers: data?.data,
        shouldUnitPerPanelMismatchBeShown,
        isDriverPerPanelAndTimeCalculationBatch,
        isLoading: isLoading || isLoadingSelectedDriver,
    };
};

const DriverComponent = ({ timeCalculationType }: { timeCalculationType: TimeCalculationTypes }): JSX.Element => {
    const getName = (): Path<AddActivityFormInputs> => {
        switch (timeCalculationType) {
            case 'Unit':
                return `linearUnitCalculationType.driverId`;
            case 'Batch':
                return `linearBatchCalculationType.driverId`;
            case 'Project':
                return `linearProjectCalculationType.driverId`;
            default:
                assertUnreachable(timeCalculationType);
        }
    };
    const { driverId } = useDriverId(timeCalculationType);

    const {
        control,
        allDriverIds,
        shouldUnitPerPanelMismatchBeShown,
        isDriverPerPanelAndTimeCalculationBatch,
        isLoading,
        allDrivers,
    } = useDriverSection({ driverId: driverId?.value, timeCalculationType });

    if (allDrivers === undefined) {
        return <LoadingText />;
    }
    return (
        <Flexbox flexDirection="column" gap="12px">
            <FormItem label={t`Driver`} required>
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <FieldSelectControlled
                        name={getName()}
                        control={control}
                        FieldProps={{
                            placeholder: t`Search for a driver`,
                            options: allDriverIds,
                            defaultValue: driverId,
                            getOptionLabel: (option) => formatDriverLabel(option, allDrivers),
                            renderOption: (option) => formatDriverLabel(option, allDrivers),
                        }}
                    />
                )}
            </FormItem>
            {shouldUnitPerPanelMismatchBeShown && !isDriverPerPanelAndTimeCalculationBatch && (
                <Message
                    variant="yellow"
                    size="large"
                    attention="high"
                    title={t`Invalid driver`}
                    message={t`The selected driver is per panel, while the activity is per unit. If you proceed,
                        the default panel factor for this activity will be automatically set to 1.`}
                />
            )}
            {isDriverPerPanelAndTimeCalculationBatch && (
                <Message
                    variant="red"
                    size="large"
                    attention="high"
                    title={t`Invalid driver`}
                    message={t`Batch time calculation cannot have a driver which is related to the panel.`}
                />
            )}
        </Flexbox>
    );
};

const FixedTimeCalculationSection = ({
    selectedCalculationType,
    timeCalculationType,
}: {
    selectedCalculationType: CalculationTypeWithoutFormula;
    timeCalculationType: TimeCalculationTypes;
}) => {
    return (
        <FixedTimeCalculationComponent
            selectedCalculationType={selectedCalculationType}
            timeCalculationType={timeCalculationType}
        />
    );
};

const useDriverId = (timeCalculationType: TimeCalculationTypes): { driverId: DriverIdDTO | undefined } => {
    let driverId;
    const { watch } = useFormContext<AddActivityFormInputs>();
    switch (timeCalculationType) {
        case 'Unit':
            driverId = watch(`linearUnitCalculationType.driverId`);
            break;
        case 'Batch':
            driverId = watch(`linearBatchCalculationType.driverId`);
            break;
        case 'Project':
            driverId = watch(`linearProjectCalculationType.driverId`);
            break;
        default:
            assertUnreachable(timeCalculationType);
    }
    return {
        driverId,
    };
};

const LinearTimeCalculationSection = ({
    selectedCalculationType,
    timeCalculationType,
}: {
    selectedCalculationType: CalculationTypeWithoutFormula;
    timeCalculationType: TimeCalculationTypes;
}): JSX.Element => {
    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item>
                        <VariableTimeCalculationComponent
                            timeCalculationType={timeCalculationType}
                            selectedCalculationType={selectedCalculationType}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container alignItems={'flex-start'} spacing={2}>
                            <Grid item xs={1}>
                                <StyledText variant={'h4'}>x</StyledText>
                            </Grid>
                            <Grid item xs={10}>
                                <DriverComponent timeCalculationType={timeCalculationType} />
                            </Grid>
                            <Grid item xs={1}>
                                <StyledText variant={'h4'}>+</StyledText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FixedTimeCalculationComponent
                            timeCalculationType={timeCalculationType}
                            selectedCalculationType={selectedCalculationType}
                            isRequired={false}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

type Field = 'formulaBatchCalculationType' | 'formulaUnitCalculationType' | 'formulaProjectCalculationType';
const getHeaderText = (field: Field) => {
    switch (field) {
        case 'formulaProjectCalculationType':
            return t`Project time formula`;
        case 'formulaBatchCalculationType':
            return t`Batch time formula`;
        case 'formulaUnitCalculationType':
            return t`Unit time formula`;
        default:
            assertUnreachable(field);
    }
};

function FormulaCalculationSection({ field }: { field: Field }): JSX.Element {
    const { setValue, watch } = useFormContext<AddActivityFormInputs>();
    const formulaValue = watch(`${field}.formula`);
    const unitValue = watch(`${field}.unit`);
    const editorRef: MonacoEditorRef = React.useRef();
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    function closeDialog() {
        setIsDialogOpen(false);
    }

    const { inputControls, isLoading } = useManufacturingInputControls(formulaValue);
    const deleteFormula = () => {
        setValue(`${field}.formula`, '', { shouldValidate: true });
        setValue(`${field}.unit`, 'Seconds', { shouldValidate: true });
    };

    const dialogSubmitOnClick = (dialogValue: DialogValue<DurationUnit>) => {
        const unit = dialogValue.unit;
        if (!DurationUnitOptions.includes(unit)) {
            throwErrorUnlessProduction('Unit is not one of the options');
        }

        setValue(`${field}.formula`, dialogValue.formula ?? '', { shouldValidate: true });
        setValue(`${field}.unit`, unit, { shouldValidate: true });
    };
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {formulaValue.length > 0 ? (
                        <>
                            <Flexbox alignItems={'center'} justifyContent={'space-between'} style={{ width: '477px' }}>
                                <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                                    {getHeaderText(field)}
                                </Text>
                                <Flexbox style={{ marginRight: '-8px' }} gap={'0px'}>
                                    <TertiaryButton
                                        startIcon={<Edit fontSize="inherit" />}
                                        size="small"
                                        onClick={() => setIsDialogOpen(true)}
                                    >
                                        <Trans>Edit formula</Trans>
                                    </TertiaryButton>
                                    <TertiaryButton
                                        startIcon={<Delete fontSize="inherit" />}
                                        style={{ marginLeft: '8px' }}
                                        size="small"
                                        onClick={deleteFormula}
                                    >
                                        <Trans> Delete formula</Trans>
                                    </TertiaryButton>
                                </Flexbox>
                            </Flexbox>
                            <AdvancedFormulaDialog
                                onSubmitClick={dialogSubmitOnClick}
                                initialFormValue={formulaValue}
                                isDialogOpen={isDialogOpen}
                                onReject={closeDialog}
                                initialUnit={unitValue}
                                unitTranslations={durationUnitPublicTranslations}
                                inputControls={inputControls}
                                isLoading={isLoading}
                            />
                            <Box width={'477px'}>
                                {isLoading ? (
                                    <SpinnerWithBackdrop noBackdrop />
                                ) : (
                                    <MonacoEditor
                                        value={formulaValue}
                                        readOnly
                                        inputControls={inputControls}
                                        editorRef={editorRef}
                                    />
                                )}
                            </Box>
                        </>
                    ) : (
                        <>
                            <SecondaryButton
                                style={{ marginBottom: '12px' }}
                                variant={'outlined'}
                                onClick={() => setIsDialogOpen(true)}
                            >
                                + <Trans> Build formula </Trans>
                            </SecondaryButton>
                            <AdvancedFormulaDialog
                                onSubmitClick={dialogSubmitOnClick}
                                onReject={closeDialog}
                                initialFormValue={formulaValue}
                                initialUnit={unitValue}
                                isDialogOpen={isDialogOpen}
                                unitTranslations={durationUnitPublicTranslations}
                                inputControls={inputControls}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

const UnitTimeCalculationSection = () => {
    const { control, selectedUnitCalculationType } = useTimeCalculationSection();
    return (
        <FormSection title={<Box maxWidth={'200px'}>{t`Unit time calculation`}</Box>}>
            <FormItem label={t`Calculation type`} required>
                <Grid item xs={4}>
                    <RadioGroupController
                        name={'selectedUnitCalculationType'}
                        control={control}
                        options={calculationTypePublicTranslations}
                    />
                </Grid>
            </FormItem>

            {selectedUnitCalculationType === 'Fixed' && (
                <FixedTimeCalculationSection
                    timeCalculationType="Unit"
                    selectedCalculationType={selectedUnitCalculationType}
                />
            )}
            {selectedUnitCalculationType === 'Linear' && (
                <LinearTimeCalculationSection
                    timeCalculationType="Unit"
                    selectedCalculationType={selectedUnitCalculationType}
                />
            )}
            {selectedUnitCalculationType === 'Formula' && (
                <FormulaCalculationSection field={'formulaUnitCalculationType'} />
            )}
        </FormSection>
    );
};

const BatchTimeCalculationSection = () => {
    const { control, selectedBatchCalculationType, selectedLevel } = useTimeCalculationSection();
    const getRadioOptions = (selectedLevel: ActivityLevel) => {
        switch (selectedLevel) {
            case 'Batch':
                return calculationTypePublicTranslations;
            case 'Unit':
                return calculationTypeWithNullPublicTranslation;
            case 'Project':
                throw new Error('Batch time calculation section should not be visible when selected level is project');
            default:
                assertUnreachable(selectedLevel);
        }
    };
    return (
        <>
            <FormItem label={t`Calculation type`} required>
                <Grid item xs={4}>
                    <RadioGroupController
                        name={'selectedBatchCalculationType'}
                        control={control}
                        options={getRadioOptions(selectedLevel)}
                    />
                </Grid>
            </FormItem>

            {selectedBatchCalculationType === 'Fixed' && (
                <FixedTimeCalculationSection
                    timeCalculationType="Batch"
                    selectedCalculationType={selectedBatchCalculationType}
                />
            )}

            {selectedBatchCalculationType === 'Linear' && (
                <LinearTimeCalculationSection
                    timeCalculationType="Batch"
                    selectedCalculationType={selectedBatchCalculationType}
                />
            )}
            {selectedBatchCalculationType === 'Formula' && (
                <FormulaCalculationSection field={'formulaBatchCalculationType'} />
            )}
        </>
    );
};

const ProjectTimeCalculationSection = () => {
    const { control, selectedProjectCalculationType, selectedLevel } = useTimeCalculationSection();

    return (
        <>
            <FormItem label={t`Calculation type`} required>
                <Grid item xs={4}>
                    <RadioGroupController
                        name={'selectedProjectCalculationType'}
                        control={control}
                        options={
                            selectedLevel === 'Project'
                                ? calculationTypePublicTranslations
                                : calculationTypeWithNullPublicTranslation
                        }
                    />
                </Grid>
            </FormItem>

            {selectedProjectCalculationType === 'Fixed' && (
                <FixedTimeCalculationSection
                    timeCalculationType="Project"
                    selectedCalculationType={selectedProjectCalculationType}
                />
            )}

            {selectedProjectCalculationType === 'Linear' && (
                <LinearTimeCalculationSection
                    timeCalculationType="Project"
                    selectedCalculationType={selectedProjectCalculationType}
                />
            )}
            {selectedProjectCalculationType === 'Formula' && (
                <FormulaCalculationSection field={'formulaProjectCalculationType'} />
            )}
        </>
    );
};

export function TimeCalculationSection(): JSX.Element {
    const { selectedLevel } = useTimeCalculationSection();
    switch (selectedLevel) {
        case 'Unit':
            return (
                <>
                    <UnitTimeCalculationSection />
                    <Divider />
                    <FormSection title={<Box maxWidth={'200px'}>{t`Batch time calculation`}</Box>}>
                        <BatchTimeCalculationSection />
                    </FormSection>
                </>
            );
        case 'Batch':
            return (
                <FormSection title={<Box maxWidth={'200px'}>{t`Batch time calculation`}</Box>}>
                    <BatchTimeCalculationSection />
                </FormSection>
            );
        case 'Project':
            return (
                <FormSection title={<Box maxWidth={'200px'}>{t`Project time calculation`}</Box>}>
                    <ProjectTimeCalculationSection />
                </FormSection>
            );
        default:
            assertUnreachable(selectedLevel);
    }
}
