import { t } from '@lingui/macro';
import { SwitchableChip } from '@luminovo/design-system';
export const ApprovalSwitchableChip = ({
    isChecked,
    isLoading,
    handleClick,
}: {
    isLoading: boolean;
    isChecked: boolean;
    handleClick: () => void;
}): JSX.Element => {
    return (
        <SwitchableChip
            checked={isChecked}
            label={t`Approve & freeze`}
            isLoading={isLoading}
            ContainerProps={{
                onClick: () => {
                    if (isLoading) {
                        return;
                    } else {
                        handleClick();
                    }
                },
            }}
        />
    );
};
