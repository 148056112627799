import { transEnum, uniqueBy } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { SolutionTag, Tag } from '@luminovo/http-client';
import { solutionTagTranslations } from '../../i18n';
import { Solution, SolutionErrorTags, SolutionNotificationTags, SolutionWarningTags } from '../../types';

export interface SolutionTagChip {
    color: keyof typeof colorSystem;
    label: string;
    solutionTag: SolutionTag;
}

const filterAndPrioritiseSolutionTags = (
    tags: Tag[],
    tagKind: readonly SolutionTag[],
    color: keyof typeof colorSystem,
): SolutionTagChip[] => {
    return tags
        .filter((t) => tagKind.includes(t.tag))
        .sort((a, b) => (tagKind.indexOf(a.tag) > tagKind.indexOf(b.tag) ? 1 : -1))
        .map((t) => {
            return {
                color,
                label: transEnum(t.tag, solutionTagTranslations),
                solutionTag: t.tag,
            };
        });
};

export const extractSolutionTagChips = ({ solutionTags }: Pick<Solution, 'solutionTags'>): SolutionTagChip[] => {
    const blueChips = filterAndPrioritiseSolutionTags(solutionTags, [SolutionTag.Inventory], 'blue');
    const primaryChips = filterAndPrioritiseSolutionTags(solutionTags, [SolutionTag.SupplierPreferred], 'primary');
    const greenChips = filterAndPrioritiseSolutionTags(solutionTags, [SolutionTag.SupplierApproved], 'green');
    const errorChips = filterAndPrioritiseSolutionTags(solutionTags, SolutionErrorTags, 'red');
    const warningChips = filterAndPrioritiseSolutionTags(solutionTags, SolutionWarningTags, 'yellow');
    const notificationChips = filterAndPrioritiseSolutionTags(solutionTags, SolutionNotificationTags, 'neutral');

    return uniqueBy(
        [...blueChips, ...primaryChips, ...greenChips, ...errorChips, ...warningChips, ...notificationChips],
        (x) => x.label,
    );
};

export const extractSolutionTagColor = ({ solutionTag }: { solutionTag: SolutionTag }): keyof typeof colorSystem => {
    switch (solutionTag) {
        case SolutionTag.Inventory:
            return 'blue';
        case SolutionTag.SupplierPreferred:
            return 'primary';
        case SolutionTag.SupplierApproved:
            return 'green';
        default:
            if (SolutionErrorTags.includes(solutionTag)) {
                return 'red';
            }
            if (SolutionWarningTags.includes(solutionTag)) {
                return 'yellow';
            }
            if (SolutionNotificationTags.includes(solutionTag)) {
                return 'neutral';
            }
            return 'neutral';
    }
};
