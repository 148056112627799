import { MonetaryValue } from '@luminovo/commons';
import { SolutionTag } from '@luminovo/http-client';
import { Solution } from '../../types';

export function extractExcessMaterial({ solution }: { solution: Solution }): MonetaryValue | null {
    const { unitPrice, solutionTags, firstPurchaseOption } = solution;
    if (!unitPrice) {
        return null;
    }
    const amount = solutionTags
        .map((sol) => {
            if (sol.tag === SolutionTag.Excess) {
                return (Number(unitPrice.amount) * sol.content.quantity) / firstPurchaseOption.unit.quantity;
            }
            return 0;
        })
        .reduce((a, b) => a + b, 0);

    return {
        amount: amount.toString(),
        currency: unitPrice.currency,
    };
}
