import { Currency, formatMonetaryValue } from '@luminovo/commons';
import { MonetaryValueBackend } from '@luminovo/http-client';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {
    decimalSeparator: '.' | ',';
    defaultCurrency: Currency;
}

export const parseMonetaryValue: Parser<MonetaryValueBackend, Opts> = async function (
    cells,
    opts,
    field,
): Promise<ParseResult<MonetaryValueBackend>> {
    return applyParser({
        cells,
        field,
        formatValue: (value) => formatMonetaryValue(value),
        parser: parse.monetaryValue(opts),
    });
};
