/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype } from '../offer';
import { StandardPartDTORuntype } from '../part';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export enum TotalCostOfOwnershipRuleCategory {
    Packaging = 'Packaging',
    Discount = 'Discount',
    Customs = 'Customs',
    Shipping = 'Shipping',
    Other = 'Other',
}
export const TotalCostOfOwnershipRuleCategoryRuntype = runtypeFromEnum(TotalCostOfOwnershipRuleCategory);

export enum TotalCostOfOwnershipRuleConditionType {
    Packaging = 'Packaging',
    Supplier = 'Supplier',
    PartNumber = 'PartNumber',
    CountryOfOrigin = 'CountryOfOrigin',
    CustomsCode = 'CustomsCode',
}

const PackagingConditionRuntype = r.Record({
    condition_type: r.Literal(TotalCostOfOwnershipRuleConditionType.Packaging),
    value: PackagingRuntype,
});

const SupplierConditionRuntype = r.Record({
    condition_type: r.Literal(TotalCostOfOwnershipRuleConditionType.Supplier),
    value: r.String,
});

const PartNumberConditionRuntype = r.Record({
    condition_type: r.Literal(TotalCostOfOwnershipRuleConditionType.PartNumber),
    value: StandardPartDTORuntype,
});

const CountryOfOriginConditionRuntype = r.Record({
    condition_type: r.Literal(TotalCostOfOwnershipRuleConditionType.CountryOfOrigin),
    value: RegionsEnumRuntype,
});

const CustomsCodeConditionRuntype = r.Record({
    condition_type: r.Literal(TotalCostOfOwnershipRuleConditionType.CustomsCode),
    value: r.Record({
        code_type: r.Union(r.Literal('Hts'), r.Literal('Taric')),
        code: r.String,
    }),
});

const ConditionRuntype = r.Union(
    SupplierConditionRuntype,
    PackagingConditionRuntype,
    PartNumberConditionRuntype,
    CountryOfOriginConditionRuntype,
    CustomsCodeConditionRuntype,
);

export enum TotalCostOfOwnershipScaling {
    Total = 'Total',
    PerUnit = 'PerUnit',
}

export const TotalCostOfOwnershipScalingRuntype = runtypeFromEnum(TotalCostOfOwnershipScaling);

const FixedAmountAction = r.Record({
    type: r.Literal('Absolute'),
    value: MonetaryValueBackendRuntype,
});

const PercentageBasedAction = r.Record({
    type: r.Literal('Relative'),
    value: r.String,
});

const ActionRuntype = r.Union(FixedAmountAction, PercentageBasedAction);
export type TotalCostOfOwnershipActionDTO = r.Static<typeof ActionRuntype>;

export interface TotalCostOfOwnershipRuleDTO extends r.Static<typeof TotalCostOfOwnershipRuleDTORuntype> {}
export const TotalCostOfOwnershipRuleDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    description: r.String.nullable(),
    category: TotalCostOfOwnershipRuleCategoryRuntype,
    action: ActionRuntype,
    scaling: TotalCostOfOwnershipScalingRuntype,
    conditions: r.Array(ConditionRuntype),
    is_valid: r.Boolean,
    updated_at: r.String,
});

export interface TotalCostOfOwnershipInputDTO extends r.Static<typeof TotalCostOfOwnershipInputDTORuntype> {}
export const TotalCostOfOwnershipInputDTORuntype = r.Record({
    name: r.String,
    description: r.String.nullable(),
    category: TotalCostOfOwnershipRuleCategoryRuntype,
    action: ActionRuntype,
    scaling: TotalCostOfOwnershipScalingRuntype,
    conditions: r.Array(ConditionRuntype),
});
