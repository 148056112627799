import { Skeleton } from '@mui/material';
import React from 'react';

export function withSuspense<T>(
    Comp: React.ComponentType<T>,
    suspenseProps?: { fallback?: JSX.Element },
): React.FunctionComponent<T> {
    const { fallback = <Skeleton style={{ cursor: 'progress' }} width={70} /> } = suspenseProps ?? {};

    return (props) => {
        return (
            <React.Suspense fallback={fallback}>
                <Comp {...props} />
            </React.Suspense>
        );
    };
}
