import { Box, BoxProps, Typography } from '@mui/material';
import * as React from 'react';
import { Flexbox } from '../Flexbox';
export interface FormSectionProps extends Omit<BoxProps, 'title'> {
    title: string | JSX.Element;
}

export const FormSection: React.FunctionComponent<FormSectionProps> = ({ title, children, ...props }) => {
    return (
        <Box padding="24px 0" display="grid" gridTemplateColumns="250px 1fr" {...props}>
            <Typography component={'div'} variant="h3" color="textSecondary">
                {title}
            </Typography>

            <Flexbox gap={24} flexDirection="column">
                {children}
            </Flexbox>
        </Box>
    );
};
