import { getToken } from '@luminovo/auth';
import { isPresent, throwErrorUnlessProduction, useMemoCompare } from '@luminovo/commons';
import { ExtractResponseBody, HttpOptions, RegisteredHttpEndpoint, http } from '@luminovo/http-client';
import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import DataLoader from 'dataloader';

type DataLoaderProps<T extends RegisteredHttpEndpoint, V> = {
    idExtractor: (item: V) => string;
    httpOptions: (ids: string[]) => HttpOptions<T>;
    select: (response: ExtractResponseBody<T>) => V[];
};

const dataloaderRegistry = new Map<string, DataLoader<unknown, unknown>>();

/**
 * Schedule batches to a maximum of Xms, meaning all items requested within a Xms range
 * will be batched into a single call.
 */
const batchScheduleFn = (cb: () => void) => setTimeout(cb, 10);

function createDataloader<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    { httpOptions, select, idExtractor }: DataLoaderProps<T, V>,
) {
    return new DataLoader<string, V | null>(
        async (keys) => {
            if (keys.length === 0) {
                // don't even hit the network when given an empty list of keys.
                return [];
            }

            const uniques = Array.from(new Set(keys));
            const result = await http(endpoint, httpOptions(uniques), getToken());

            // Group all the V's by their ID.
            const groupedById = new Map<string, V>();
            for (const item of select(result)) {
                groupedById.set(idExtractor(item), item);
            }

            // Then return the items in their original order.
            return keys.map((key) => {
                const result = groupedById.get(key);
                if (result === undefined) {
                    throwErrorUnlessProduction(new Error(`Could not find item at endpoint ${endpoint} and key ${key}`));
                }
                return result ?? null;
            });
        },
        { batchScheduleFn, cache: false },
    );
}

export function getOrCreateDataloader<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    { httpOptions, select, idExtractor }: DataLoaderProps<T, V>,
) {
    // Ensure that a dataloader is not shared when the httpOptions function is diffent.
    const registryKey = `${endpoint} | ${JSON.stringify(httpOptions([]))}`;

    const dataloader =
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (dataloaderRegistry.get(registryKey) as DataLoader<string, V | null>) ??
        createDataloader(endpoint, { httpOptions, select, idExtractor });

    if (!dataloaderRegistry.has(registryKey)) {
        dataloaderRegistry.set(registryKey, dataloader);
    }

    return dataloader;
}

type BulkQueryResult<V> = {
    data: V[] | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isFetched: boolean;
    isError: boolean;
    isStale: boolean;
};

export function useBulkQuery<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    ids: string[] | undefined,
    dataLoaderProps: DataLoaderProps<T, V>,
    queryOptions?: Pick<
        UseQueryOptions<V>,
        'enabled' | 'suspense' | 'refetchInterval' | 'useErrorBoundary' | 'refetchOnWindowFocus' | 'meta'
    > & { sortBy?: (a: V, b: V) => number },
): BulkQueryResult<V> {
    const { sortBy } = queryOptions ?? {};
    const dataloader = getOrCreateDataloader(endpoint, dataLoaderProps);

    const results = useQueries({
        queries: (ids ?? []).map((id) => {
            return {
                queryKey: [endpoint, dataLoaderProps.httpOptions([id]), 'bulk-query'],
                queryFn: () => dataloader.load(id),
                // Removes the generic type to resolve an TypeScript error
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                ...(queryOptions as UseQueryOptions),
            };
        }),
    });

    // FIXME: Consider using the `combine` function from react-query v5 to merge the results of multiple queries.
    // This would simplify the code and remove the need for memoization.
    return useMemoCompare(
        () => {
            // In the case `id` is undefined we fake an disabled query result.
            if (!isPresent(ids)) {
                return {
                    data: undefined,
                    isLoading: false,
                    isFetching: false,
                    isFetched: false,
                    isError: false,
                    isStale: false,
                };
            }

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const data = results
                .map((x) => x.data as V | null)
                .filter(isPresent)
                .sort(sortBy);

            // For safer backwards compatibility
            const isLoading = results.some((x) => x.isInitialLoading);
            const isFetching = results.some((x) => x.isFetching);
            const isFetched = results.some((x) => x.isFetched);
            const isError = results.some((x) => x.isError);
            const isStale = results.some((x) => x.isStale);

            // In the case the query is still loading, we want to return undefined and not an empty array.
            if (isLoading) {
                return { data: undefined, isLoading, isFetching, isFetched, isError, isStale };
            }

            return { data, isLoading, isFetching, isFetched, isError, isStale };
        },
        { ids, results },
    );
}

export function useBulkSingleQuery<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    id: string | undefined,
    dataLoaderProps: DataLoaderProps<T, V>,
    queryOptions?: Pick<
        UseQueryOptions<V>,
        'enabled' | 'suspense' | 'refetchInterval' | 'useErrorBoundary' | 'refetchOnWindowFocus' | 'meta'
    >,
) {
    const { data, ...result } = useBulkQuery(endpoint, id ? [id] : undefined, dataLoaderProps, queryOptions);

    return {
        ...result,
        data: data && data[0],
    };
}
