/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WuerthEisosCredentialsDTO extends r.Static<typeof WuerthEisosCredentialsDTORuntype> {}
export const WuerthEisosCredentialsDTORuntype = r.Union(
    r.Record({
        username: r.String,
        password: r.String,
    }),
);

export interface WuerthEisosCredentialsInputDTO extends r.Static<typeof WuerthEisosCredentialsInputDTORuntype> {}
export const WuerthEisosCredentialsInputDTORuntype = r.Record({
    username: r.String.optional(),
    password: r.String.optional(),
});

export interface WuerthEisosResponseBodyDTO extends r.Static<typeof WuerthEisosResponseBodyDTORuntype> {}
export const WuerthEisosResponseBodyDTORuntype = r.Record({
    data: WuerthEisosCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
