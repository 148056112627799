import { t } from '@lingui/macro';
import {
    FieldSelect,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { CheckCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Row, Table } from '../../../../types';
import { useUniversalImporter } from '../../context';

export function TableMapColumns({ table }: { table: Table }): JSX.Element {
    const { dispatch, state } = useUniversalImporter();
    const { config } = state;
    const columnHelper = createColumnHelper<Row>();
    const columns = Object.keys(table[0]).map((key, colIndex) => {
        const selected = config.fields.find((option) => option.columnIndices.includes(colIndex)) ?? null;
        const label = selected ? (selected.label ?? selected.id) : `Column #${colIndex + 1}`;
        return columnHelper.text((row) => row[colIndex] ?? '', {
            id: String(colIndex),
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: true,
            enableMultiSort: false,
            label: () => label,
            header: function Header() {
                const className = (colIndex: number) => `field-select-${colIndex}`;
                return (
                    <FieldSelect
                        className={className(colIndex)}
                        sx={{ minWidth: '200px', padding: '8px 0 ' }}
                        size="small"
                        openOnFocus
                        fullWidth
                        getOptionLabel={(field) => field.label ?? field.id}
                        placeholder={t`Click to map column`}
                        options={Array.from(config.fields)}
                        renderOption={(option) => {
                            const isOptionLinkedToAnotherColumn =
                                config.fields
                                    .filter((field) => field.id === option.id)
                                    .filter((field) => field.columnIndices.length > 0).length > 0;
                            return (
                                <Box width={'100%'} display={'grid'} gridTemplateColumns={'1fr auto'}>
                                    <Text variant="body-small">{option?.label}</Text>
                                    <CheckCircle
                                        style={{
                                            color: isOptionLinkedToAnotherColumn
                                                ? colorSystem.green[6]
                                                : colorSystem.neutral[5],
                                            height: 16,
                                            // align self to the right
                                            justifySelf: 'end',
                                        }}
                                    />
                                </Box>
                            );
                        }}
                        value={selected}
                        onChange={(value) => {
                            if (value) {
                                dispatch({ type: 'linkColumn', column: colIndex, field: value.id });
                                // hacky way to focus the next field select
                                // we need to wait for the popup to close.
                                // Even though its hacky, we don't really break anything in case the timer is too short.
                                setTimeout(() => {
                                    const element = document.querySelector(
                                        `.${className(colIndex + 1)} .MuiAutocomplete-popupIndicator`,
                                    );
                                    if (element && element instanceof HTMLElement) {
                                        element.click();
                                    }
                                }, 200);
                            } else if (value === null) {
                                dispatch({ type: 'unlinkColumn', column: colIndex });
                            }
                        }}
                    />
                );
            },
            size: 220,
        });
    });

    const tanstackTable = useTanStackTable({
        columns,
        data: table ?? [],
        enablePersistentGlobalFilter: true,
        enableColumnOrdering: false,
        enablePersistentColumnFilters: false,
    });

    return <TanStackTable size="small" enableMenuBar={false} table={tanstackTable.table} />;
}
