import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, colorSystem, compareByNumber } from '@luminovo/design-system';
import { PartAlternativeSimilarityEnum } from '@luminovo/http-client';
import { SimilarityTag } from '../tags';

export const similarityScoreDescriptionTranslation: Record<PartAlternativeSimilarityEnum, MessageDescriptor> = {
    [PartAlternativeSimilarityEnum.FormFitFunction]: defineMessage({
        message:
            'Alternative parts that have the same function, mounting technology and number of pins as a selected part option (same form, fit and function).',
    }),
    [PartAlternativeSimilarityEnum.Functional]: defineMessage({
        message:
            'Alternative parts that have the same function as a selected part option, but may have a different mounting technology or number of pins (different form and fit).',
    }),
    [PartAlternativeSimilarityEnum.Recommended]: defineMessage({
        message: 'Alternative parts that have been used in previous real-world PCBA designs.',
    }),
};

const priority: Record<PartAlternativeSimilarityEnum, number> = {
    [PartAlternativeSimilarityEnum.Recommended]: 1,
    [PartAlternativeSimilarityEnum.FormFitFunction]: 2,
    [PartAlternativeSimilarityEnum.Functional]: 3,
};

export function SimilarityTooltip({ children }: { children: React.ReactElement }): JSX.Element {
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection="column" gap={4}>
                    {typeSafeObjectKeys(PartAlternativeSimilarityEnum)
                        .sort(compareByNumber((score) => priority[score]))
                        .map((similarity, index) => {
                            return (
                                <Flexbox
                                    flexDirection="column"
                                    borderBottom={
                                        index + 1 === typeSafeObjectKeys(PartAlternativeSimilarityEnum).length
                                            ? 'unset'
                                            : `1px solid ${colorSystem.neutral[2]}`
                                    }
                                    padding="16px"
                                    gap="8px"
                                    key={index}
                                >
                                    <span>
                                        <SimilarityTag similarity={PartAlternativeSimilarityEnum[similarity]} />
                                    </span>
                                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                                        {transEnum(similarity, similarityScoreDescriptionTranslation)}
                                    </Text>
                                </Flexbox>
                            );
                        })}
                </Flexbox>
            }
        >
            {children}
        </Tooltip>
    );
}
