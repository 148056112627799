import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CalculationTemplateResponseDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import {
    SelectableListItem,
    SelectableListItemsContainer,
} from '../../../../../components/SelectableList/SelectableListItem';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { CIRCULAR_PROGRESS_BUTTON_SIZE } from '../../../../../themes';
import { route } from '../../../../../utils/routes';
import { alphaNumericalStringSort } from '../../../../../utils/stringFunctions';

export const CalculationTemplateSelectionInput = ({
    templateId,
    onSelectTemplateId,
}: {
    templateId: string | undefined | null;
    onSelectTemplateId: (templateId: string | undefined) => void;
}) => {
    const { data, isLoading } = useHttpQuery('GET /calculations/templates', {});

    const calculationTemplatesData: CalculationTemplateResponseDTO[] = data?.data ?? [];
    const templates: CalculationTemplateResponseDTO[] = calculationTemplatesData.sort((template1, template2) =>
        alphaNumericalStringSort(template1.name, template2.name),
    );

    if (isLoading) {
        return <CircularProgress size={CIRCULAR_PROGRESS_BUTTON_SIZE} color="primary" />;
    }

    if (templates.length === 0) {
        return (
            <Text>
                <Trans>
                    There are no active calculation templates. You can activate or add calculation templates{' '}
                    <Link
                        to={route('/calculation/templates')}
                        style={{
                            textDecoration: 'none',
                            color: colorSystem.blue[6],
                        }}
                    >
                        here
                    </Link>
                    .
                </Trans>
            </Text>
        );
    }

    return (
        <SelectableListItemsContainer
            flexDirection="row"
            alignItems="stretch"
            flexWrap="wrap"
            gap={8}
            id={id('settings/e2e_calculation_templates_container')}
        >
            {templates.map((template: CalculationTemplateResponseDTO) => (
                <SelectableListItem
                    key={`template-list-item-${template.id}`}
                    handleOnClick={() => {
                        onSelectTemplateId(template.id === templateId ? undefined : template.id);
                    }}
                    isSelected={template.id === templateId}
                >
                    <Flexbox>
                        <Text variant="body-semibold" color={colorSystem.neutral[8]} style={{ whiteSpace: 'nowrap' }}>
                            {template.name}
                        </Text>
                    </Flexbox>
                </SelectableListItem>
            ))}
        </SelectableListItemsContainer>
    );
};
