import { getToken } from '@luminovo/auth';
import { EndpointRegistry, http, HttpOptions, RegisteredHttpEndpoint } from '@luminovo/http-client';
import { UseQueryOptions } from '@tanstack/react-query';
import * as r from 'runtypes';

/**
 * `useQuery` adapted for making type-safe HTTP requests
 */

export function httpQuery<T extends RegisteredHttpEndpoint, TData = r.Static<EndpointRegistry[T]['responseBody']>>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: UseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, unknown, TData> = {},
): UseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, unknown, TData> {
    return {
        queryKey: [endpoint, options],
        queryFn: async () => {
            return http(endpoint, options, getToken());
        },
        ...useQueryOptions,
    };
}
