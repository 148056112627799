export const EmptyDrawerIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    return (
        <svg width="334" height="144" viewBox="0 0 334 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="327" height="24" transform="translate(0.207031)" fill="#F7F8FA" />
            <rect x="39.707" y="10" width="64" height="4" rx="2" fill="#D1D5E0" />
            <rect x="135.707" y="10" width="32" height="4" rx="2" fill="#D1D5E0" />
            <rect x="199.707" y="10" width="32" height="4" rx="2" fill="#D1D5E0" />
            <rect x="263.707" y="10" width="24" height="4" rx="2" fill="#D1D5E0" />
            <rect width="327" height="24" transform="translate(0.207031 24)" fill="white" />
            <rect x="39.707" y="34" width="64" height="4" rx="2" fill="#EAECF1" />
            <rect x="135.707" y="34" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="199.707" y="34" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="263.707" y="34" width="24" height="4" rx="2" fill="#EAECF1" />
            <rect x="0.707031" y="48.5" width="326" height="23" fill="white" />
            <rect x="39.707" y="58" width="64" height="4" rx="2" fill="#EAECF1" />
            <rect x="135.707" y="58" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="199.707" y="58" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="263.707" y="58" width="24" height="4" rx="2" fill="#EAECF1" />
            <rect x="0.707031" y="48.5" width="326" height="23" stroke="#E5EAFF" />
            <rect width="327" height="24" transform="translate(0.207031 72)" fill="white" />
            <rect x="39.707" y="82" width="64" height="4" rx="2" fill="#EAECF1" />
            <rect x="135.707" y="82" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="199.707" y="82" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="263.707" y="82" width="24" height="4" rx="2" fill="#EAECF1" />
            <rect width="327" height="24" transform="translate(0.207031 96)" fill="white" />
            <rect x="39.707" y="106" width="64" height="4" rx="2" fill="#EAECF1" />
            <rect x="135.707" y="106" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="199.707" y="106" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="263.707" y="106" width="24" height="4" rx="2" fill="#EAECF1" />
            <rect width="327" height="24" transform="translate(0.207031 120)" fill="white" />
            <rect x="39.707" y="130" width="64" height="4" rx="2" fill="#EAECF1" />
            <rect x="135.707" y="130" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="199.707" y="130" width="32" height="4" rx="2" fill="#EAECF1" />
            <rect x="263.707" y="130" width="24" height="4" rx="2" fill="#EAECF1" />
            <path
                d="M318.792 64.1705C318.659 63.5371 319.337 63.0444 319.899 63.3662L333.293 71.0427C333.862 71.369 333.768 72.2173 333.141 72.4103L326.81 74.3576C326.642 74.4093 326.498 74.5185 326.402 74.6661L323.298 79.4473C322.933 80.0087 322.072 79.8477 321.935 79.1925L318.792 64.1705Z"
                fill="#2A3147"
            />
        </svg>
    );
};
