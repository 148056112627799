import * as r from 'runtypes';
import { DurationRuntype } from '../activity/activityBackendTypes';
import { ActivityConfigurationSummaryRuntype, DriverStatusDetailsRuntype } from '../activityConfiguration';
import { ExchangedMonetaryValueBackendConvertedMVOnlyRuntype } from '../backendTypes';
import { AttributeReferenceForWarningsRuntype } from '../driver/driverCalculationBackendTypes';
import { ExpenseConfigurationSummaryRuntype } from '../expenses/expenseConfigurationBackendTypes';
import { ManufacturingAssemblyDetailsRuntype } from '../manufacturingAssemblyDetails/manufacturingAssemblyDetailsBackendTypes';

const ManufacturingScenarioDetailsRuntype = {
    id: r.String,
    manufacturing_assembly_details: r.String,
    name: r.String,
    notes: r.String.nullable().optional(),
    batch_sizes: r.Array(r.Number),
    sourcing_scenario: r.String.nullable(),
    statuses: r.Array(DriverStatusDetailsRuntype),
    sites: r.Array(r.String),
};
/* eslint-disable camelcase */
export const ManufacturingScenarioRuntype = r.Record(ManufacturingScenarioDetailsRuntype);

export const ManufacturingScenarioBulkPostRuntype = r.Record({
    manufacturing_assembly_details: r.String,
    name: r.String,
    notes: r.String.nullable(),
    batch_sizes: r.Array(r.Number),
    sourcing_scenario: r.String.nullable(),
    from_template: r.String.nullable(),
});

export const ManufacturingScenarioBulkPostResponseRuntype = ManufacturingScenarioBulkPostRuntype.omit(
    'manufacturing_assembly_details',
    'from_template',
);

export interface ManufacturingScenarioBulkPostResponseDTO
    extends r.Static<typeof ManufacturingScenarioBulkPostResponseRuntype> {}

export interface ManufacturingScenarioBulkPostDTO extends r.Static<typeof ManufacturingScenarioBulkPostRuntype> {}

export interface ManufacturingScenarioDTO extends r.Static<typeof ManufacturingScenarioRuntype> {}

export const ManufacturingScenarioPostRuntype = ManufacturingScenarioRuntype.omit(
    'id',
    'statuses',
    'manufacturing_assembly_details',
    'sites',
).extend({ assembly: r.String });
export interface ManufacturingScenarioPostDTO extends r.Static<typeof ManufacturingScenarioPostRuntype> {}

export const ManufacturingScenarioDuplicatePostRuntype = r.Record({
    name: r.String,
    batch_sizes: r.Array(r.Number),
    sourcing_scenario: r.String.nullable(),
});

export interface ManufacturingScenarioDuplicatePostDTO
    extends r.Static<typeof ManufacturingScenarioDuplicatePostRuntype> {}

export const ManufacturingScenarioPatchRuntype = r
    .Partial({
        ...ManufacturingScenarioDetailsRuntype,
        ordered_activity_configurations: r.Array(r.String),
        ordered_expense_configurations: r.Array(r.String),
    })
    .omit('sites')
    .And(r.Record({ id: r.String }));
export interface ManufacturingScenarioPatchDTO extends r.Static<typeof ManufacturingScenarioPatchRuntype> {}

const BatchSizeBreakdownRuntype = r.Record({
    total_batch_cost: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    total_batch_time: DurationRuntype,
    total_effective_cost_per_batch: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    total_effective_cost_per_unit: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    total_project_cost: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    total_unit_cost: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    total_unit_time: DurationRuntype,
    total_project_time: DurationRuntype,
});

export interface BatchSizeBreakdown extends r.Static<typeof BatchSizeBreakdownRuntype> {}

const ManufacturingScenarioBatchSizeBreakdownsRuntype = r.Dictionary(BatchSizeBreakdownRuntype, r.String);
export type ManufacturingScenarioBatchSizeBreakdownsDTO = r.Static<
    typeof ManufacturingScenarioBatchSizeBreakdownsRuntype
>;

export const TemplateInstanceSummaryRuntype = r.Record({
    id: r.String,
    template_name: r.String,
    rank: r.Number,
});

export type TemplateInstanceSummaryDTO = r.Static<typeof TemplateInstanceSummaryRuntype>;

export const ManufacturingScenarioFullSummaryRuntype = r.Record({
    activity_configuration_summaries: r.Record({
        items: r.Array(ActivityConfigurationSummaryRuntype),
    }),
    expense_configuration_summaries: r.Record({
        items: r.Array(ExpenseConfigurationSummaryRuntype),
    }),
    template_instances: r.Array(TemplateInstanceSummaryRuntype),
    breakdown_per_batch_size: ManufacturingScenarioBatchSizeBreakdownsRuntype,
    manufacturing_assembly_details: ManufacturingAssemblyDetailsRuntype,
    manufacturing_scenario: ManufacturingScenarioRuntype,
});

export interface ManufacturingScenarioFullSummaryDTO extends r.Static<typeof ManufacturingScenarioFullSummaryRuntype> {}

/* eslint-enable camelcase */
export const ManufacturingScenarioMissingFieldRuntype = r.Dictionary(
    r.Array(AttributeReferenceForWarningsRuntype),
    r.String,
);

/* the customer portal information is available to the customer users.
It needs to be different from the regular Template DTO so we do not 
expose sensistve EMS data to the customer user. 
*/
const TemplateDTOForCustomerPortalRuntype = r.Record({
    id: r.String,
    name: r.String,
});

export interface TemplateDTOForCustomerPortal extends r.Static<typeof TemplateDTOForCustomerPortalRuntype> {}

export const CustomerPortalManufacturingSettingsPatchRuntype = r.Record({
    templates: r.Array(r.String),
});

export interface CustomerPortalManufacturingSettingsPatchDTO
    extends r.Static<typeof CustomerPortalManufacturingSettingsPatchRuntype> {}

export const CustomerPortalManufacturingSettings = r.Record({
    templates: r.Array(TemplateDTOForCustomerPortalRuntype),
});

export interface CustomerPortalManufacturingSettingsDTO extends r.Static<typeof CustomerPortalManufacturingSettings> {}
