import * as r from 'runtypes';
import { QuantityUnitDTORuntype } from '../backendTypes';

const IPNRuntype = r.Record({
    value: r.String,
    revision: r.String.nullable().optional(),
});

const DemandTypeRuntype = r.Union(r.Literal('gross'), r.Literal('net'));

const PlannedOrderCreationRequestSingleRuntype = r.Record({
    type: DemandTypeRuntype,
    quantity: QuantityUnitDTORuntype,
    ship_to_site_number: r.String.optional(),
    ship_to_site_id: r.String.optional(),
    delivery_start_date: r.String,
    delivery_end_date: r.String,
    supplier_site_number: r.String.optional(),
    supplier_site_id: r.String.optional(),
    internal_part_number: IPNRuntype,
    production_start_date: r.String.optional(),
    end_customer_number: r.String.optional(),
    end_customer_id: r.String.optional(),
});

export type PlannedOrderCreationRequestSingleDTO = r.Static<typeof PlannedOrderCreationRequestSingleRuntype>;
export const PlannedOrderCreationRequestRuntype = r.Record({
    items: r.Array(PlannedOrderCreationRequestSingleRuntype),
    context: r.String,
});
export type PlannedOrdersCreationRequestDTO = r.Static<typeof PlannedOrderCreationRequestRuntype>;

// If you want to narrow the types, please ensure you also include the error variants too
// to allow better error handling for the demand importer.
export const MultiStatusRuntype = r.Record({
    description: r.String,
    internal_part_number: IPNRuntype,
    status: r.Number,
});

export const DemandKeyIdRuntype = r.Record({
    kind: r.Union(r.Literal('Component'), r.Literal('OTSPart')),
    content: r.String,
});
export type DemandKeyId = r.Static<typeof DemandKeyIdRuntype>;

export const InternalPartNumberIdentifierRuntype = r.Record({
    value: r.String,
    revision: r.String.nullable().optional(),
});

const DemandOriginExportRuntype = r.Union(r.Literal('imported'), r.Literal('calculated'));
export type DemandOriginExportDTO = r.Static<typeof DemandOriginExportRuntype>;

const isValidDate = (str: string) => {
    const date = new Date(str);
    return date instanceof Date && !isNaN(date.getTime());
};

const ISODateStringRuntype = r.String.withConstraint((str) => {
    const isoDatePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
    return (isoDatePattern.test(str) && isValidDate(str)) || `${str} is not a valid ISO date string`;
});

export const DemandResponseRuntype = r.Record({
    ipn: InternalPartNumberIdentifierRuntype,
    delivery_start_date: r.String,
    delivery_end_date: r.String,
    production_start_date: r.String.nullable().optional(),
    net_quantity: QuantityUnitDTORuntype,
    gross_quantity: QuantityUnitDTORuntype,
    inventory_quantity: QuantityUnitDTORuntype.nullable(),
    ship_to_site_number: r.String.nullable().optional(),
    ship_to_site_name: r.String,
    supplier_number: r.String.nullable().optional(),
    supplier_name: r.String,
    origin: DemandOriginExportRuntype,
});

export type ISODateStringDTO = r.Static<typeof ISODateStringRuntype>;

export type DemandResponseDTO = r.Static<typeof DemandResponseRuntype>;

export const DemandSupplierMissingSiteNumberWarningRuntype = r.Record({
    type: r.Literal('DemandSupplierMissingSiteNumber'),
    data: r.Record({
        site_name: r.String,
    }),
});

export const DemandRecipientMissingSiteNumberWarningRuntype = r.Record({
    type: r.Literal('DemandRecipientMissingSiteNumber'),
    data: r.Record({
        site_name: r.String,
    }),
});

export const DemandExportResponseWarningRuntype = r.Union(
    DemandSupplierMissingSiteNumberWarningRuntype,
    DemandRecipientMissingSiteNumberWarningRuntype,
);
export type DemandExportResponseWarningDTO = r.Static<typeof DemandExportResponseWarningRuntype>;

export const DemandsExportResponseRuntype = r.Array(DemandResponseRuntype);
export type DemandsExportResponseDTO = r.Static<typeof DemandsExportResponseRuntype>;

export const DemandsExportRequestBodyRuntype = r.Record({
    target_date: r.String.optional(),
    ipns: r.Array(r.String).optional(),
    supplier_numbers: r.Array(r.String).optional(),
    contexts: r.Array(r.String).optional(),
});

export const SourceListImportRuntype = r.Record({
    internal_part_number: IPNRuntype,
    site_number: r.String.optional(),
    start_date: r.String,
    end_date: r.String.optional(),
    supplier_number: r.String,
});

export const SourceListImportArrayRuntype = r.Array(SourceListImportRuntype);
export type SourceListImportDTO = r.Static<typeof SourceListImportRuntype>;
export type SourceListImportArrayDTO = r.Static<typeof SourceListImportArrayRuntype>;

// keep in sync with pub enum SourceListImportErrors in rust-workspace/routes-demand/src/source_list.rs
const SiteNotFoundErrorRuntype = r.Record({
    error: r.Literal('SiteNotFound'),
    details: r.String,
});
const IpnNotFoundErrorRuntype = r.Record({
    error: r.Literal('IpnNotFound'),
    details: IPNRuntype,
});
const SupplierNotFoundErrorRuntype = r.Record({
    error: r.Literal('SupplierNotFound'),
    details: r.String,
});
const AmbiguousSiteNumberErrorRuntype = r.Record({
    error: r.Literal('AmbiguousSiteNumber'),
    details: r.String,
});
const InvalidDatesErrorRuntype = r.Record({
    error: r.Literal('InvalidDates'),
    details: r.Record({ start_date: r.String, end_date: r.String }),
});

const SourceListImportResponseErrorRuntype = r.Union(
    SiteNotFoundErrorRuntype,
    IpnNotFoundErrorRuntype,
    SupplierNotFoundErrorRuntype,
    AmbiguousSiteNumberErrorRuntype,
    InvalidDatesErrorRuntype,
);
export const SourceListImportResponseRuntype = r.Record({
    errors: r.Array(SourceListImportResponseErrorRuntype),
    imported: r.Number,
});

export type SourceListImportResponseDTO = r.Static<typeof SourceListImportResponseRuntype>;
export type SourceListImportResponseErrorDTO = r.Static<typeof SourceListImportResponseErrorRuntype>;
