import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useCustomer } from '../../../../../resources/customer/customerHandler';

export const useEmsAndOemNames = ({ customerId }: { customerId?: string }): { emsName: string; oemName: string } => {
    const { organization, customer } = useCurrentUserDetailsContext();
    const isCustomer = isPresent(customer);
    const emsName = organization.name;
    const customerDetails = useCustomer(customerId);

    if (!customerDetails.data) {
        return { emsName, oemName: isCustomer ? t`Customer` : t`External` };
    }
    return { emsName, oemName: customerDetails.data.name };
};
