import * as r from 'runtypes';
import { SystemDriverNameRuntype } from './driverBackendTypes';

export const UserDriverIdRuntype = r.Record({
    type: r.Literal('User'),
    value: r.String,
});

export const SystemDriverIdRuntype = r.Record({
    type: r.Literal('System'),
    value: SystemDriverNameRuntype,
});

export type UserDriverIdDTO = r.Static<typeof UserDriverIdRuntype>;
export type SystemDriverIdDTO = r.Static<typeof SystemDriverIdRuntype>;

export const DriverIdRuntype = r.Union(UserDriverIdRuntype, SystemDriverIdRuntype);
export type DriverIdDTO = r.Static<typeof DriverIdRuntype>;

export const DriverIdParameterRuntype = r.Template('{', r.String, '}');
export type DriverIdParameter = r.Static<typeof DriverIdParameterRuntype>;

export const DriverIdPathRuntype = r.Template(r.Union(r.Literal('user|'), r.Literal('system|')), r.String);
export type DriverIdPath = r.Static<typeof DriverIdPathRuntype>;
