import { Column, columnWidth, Row } from '@luminovo/design-system';
import { ExpenseDTO } from '@luminovo/http-client';
import { RightAlignedStickyTableCell } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { useCanEditManufacturingDatabase } from '../../../utils/useCanEditManufacturingDatabase';
import { ExpenseMenuComponent } from '../expenseDatabaseTableComponents';

export const useExpenseMenuColumnContext = (): ExpenseMenuColumnContext => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    return { canEditManufacturingDatabase };
};

export interface ExpenseMenuColumnContext {
    canEditManufacturingDatabase: boolean;
}

export function getExpenseMenuColumn<
    T extends Pick<ExpenseDTO, 'id' | 'status'>,
    C extends ExpenseMenuColumnContext,
>(): Column<T, C> {
    return {
        label: '', // intentionally blank
        id: `activitiesActionsColumn`,
        renderHead: () => <RightAlignedStickyTableCell />,
        render: ({ data: rowData }: Row<T>, context) =>
            context.canEditManufacturingDatabase ? (
                <ExpenseMenuComponent expenseId={rowData.id} status={rowData.status} />
            ) : (
                <TableCell />
            ),
        width: columnWidth.small,
    };
}
