import { Trans, t } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Flexbox, Tag, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useUniversalImporter } from '../../context';

export function StepPreviewHelpDialog(): JSX.Element {
    const { state } = useUniversalImporter();

    const { skipped, insert, update } = state.importerTable?.getLinkStatusCount() ?? {
        insert: 0,
        skipped: 0,
        update: 0,
    };

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Text>
                <Trans>This import will result in the following changes:</Trans>
            </Text>
            <Box display={'grid'} gridTemplateColumns={'auto auto'} gap={1}>
                <Text variant="h5">
                    <Trans>Type</Trans>
                </Text>
                <Text variant="h5">
                    <Trans>Description</Trans>
                </Text>
                <Tag attention="low" color="violet" label={t`New`} />
                <Text>
                    <Trans>{formatDecimal(insert)} new records will be created</Trans>
                </Text>
                <Tag attention="low" color="blue" label={t`Update`} />
                <Text>
                    <Trans>{formatDecimal(update)} existing records will be updated</Trans>
                </Text>
                {/* <Tag attention='low' color='green' label={t`Identical`} /> <Text>{update} existing records are identical</Text> */}
                <Tag attention="low" color="neutral" label={t`Excluded`} />{' '}
                <Text>
                    <Trans>{formatDecimal(skipped)} rows are excluded from the import</Trans>
                </Text>
            </Box>
            <Text>
                <Trans>Please review your changes, and click on the import button to start the import process.</Trans>
            </Text>
        </Flexbox>
    );
}
