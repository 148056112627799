import { Trans, t } from '@lingui/macro';
import { formatCurrency, formatToLongDate, throwErrorUnlessProduction } from '@luminovo/commons';
import {
    CenteredLayout,
    Column,
    DataTable,
    Flexbox,
    NOTO_SANS,
    PrimaryButton,
    Row,
    TableState,
    Text,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { RfqExportCalculationPdfExportAssemblyDTO } from '@luminovo/http-client';
import { formatAvailabilityWithExtraDays } from '@luminovo/sourcing-core';
import { Box, CircularProgress, Divider, TableCell } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { translateCountry } from '../../../resources/organizationSettings/countryi18n';
import { UrlParams } from '../../../utils/routes';
import './index.css';
import { CostTableRow } from './printPageTypes';
import { useQuotationInfo } from './useQuotationInfo';

const InfoField = ({ label, children }: { label: string; children: React.ReactNode }): JSX.Element => {
    return (
        <Flexbox flexDirection="column">
            <Text variant="body-semibold">{label}</Text>
            {children}
        </Flexbox>
    );
};

export function useCostDatatable(
    assembly: RfqExportCalculationPdfExportAssemblyDTO,
    additionalLeadTimeDays: number | undefined,
): TableState<CostTableRow> {
    const items: CostTableRow[] = assembly.order_calculations
        .map((orderCalculation) => ({
            leadTime: formatAvailabilityWithExtraDays({
                availability: orderCalculation.sourcing_lead_time_availability ?? undefined,
                additionalLeadTimeDays: additionalLeadTimeDays ?? 0,
                manufacturingLeadTime: orderCalculation.manufacturing_lead_time ?? undefined,
            }),
            quantity: orderCalculation.quantity,
            unitPrice: orderCalculation.unit_price,
            totalPrice: orderCalculation.total_price,
            projectCost: orderCalculation.total_project_cost,
        }))
        .sort((a, b) => a.quantity - b.quantity);

    const columns: Column<CostTableRow>[] = [
        {
            id: 'quantity',
            label: t`Quantity`,
            render: ({ data: row }: Row<CostTableRow>) => <TableCell>{row.quantity}</TableCell>,
        },
        {
            id: 'leadTime',
            label: t`Lead time`,
            render: ({ data: row }: Row<CostTableRow>) => <TableCell>{row.leadTime}</TableCell>,
        },
        {
            id: 'unitPrice',
            label: t`Unit price`,
            render: ({ data: row }: Row<CostTableRow>) => (
                <TableCell>
                    {row.unitPrice ? formatCurrency(row.unitPrice.amount, row.unitPrice.currency, 'unit-price') : '-'}
                </TableCell>
            ),
        },
        {
            id: 'totalPrice',
            label: t`Total price`,
            render: ({ data: row }: Row<CostTableRow>) => (
                <TableCell>
                    {row.totalPrice ? formatCurrency(row.totalPrice.amount, row.totalPrice.currency) : '-'}
                </TableCell>
            ),
        },
        {
            id: 'NREs',
            label: t`NREs`,
            render: ({ data: row }: Row<CostTableRow>) => (
                <TableCell>
                    {row.projectCost ? formatCurrency(row.projectCost.amount, row.projectCost.currency) : '-'}
                </TableCell>
            ),
        },
    ];

    const dataTableState = useDataTableState({
        persistenceId: 'costTable',
        items,
        columns,
        paginationOptions: {
            showPagination: false,
        },
    });

    return dataTableState;
}
const CostDataTable = ({
    assembly,
    additionalLeadTimeDays,
}: {
    assembly: RfqExportCalculationPdfExportAssemblyDTO;
    additionalLeadTimeDays: number | undefined;
}): JSX.Element => {
    const dataTableState = useCostDatatable(assembly, additionalLeadTimeDays);
    return <DataTable tableState={dataTableState} size="medium" />;
};

const parseAdditionalLeadTimeDays = (additionalLeadTimeDays: string): number | undefined => {
    const parsed = parseInt(additionalLeadTimeDays);
    if (!Number.isNaN(parsed)) {
        return parsed;
    }
    throwErrorUnlessProduction(
        new Error(`Expected a number in parseAdditionalLeadTimeDays, got ${additionalLeadTimeDays}`),
    );
    return undefined;
};

export const CalculationPrintPage = (props: UrlParams<'/rfqs/:rfqId/calculation/print'>): JSX.Element => {
    const {
        pathParams: { rfqId },
        queryParams: {
            customText,
            validUntil,
            additionalLeadTimeDays,
            quotationNumber,
            footerLeft,
            footerRight,
            footerCenter,
        },
    } = props;
    const { info } = useQuotationInfo({ rfqId });
    const { data } = useHttpQuery('GET /calculations/calculation-assembly-costs/export-pdf-data', {
        queryParams: {
            rfq_id: props.pathParams.rfqId,
        },
    });

    if (!info || !data) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const { contactPerson, customer, ems } = info;

    return (
        <Flexbox flexDirection="column" height="100vh">
            <Flexbox
                sx={{ displayPrint: 'none', background: colorSystem.neutral[5], minHeight: '60px' }}
                justifyContent="center"
                alignItems="center"
            >
                <Flexbox width="210mm" justifyContent="end" sx={{ displayPrint: 'none' }}>
                    {/* eslint-disable-next-line no-restricted-globals */}
                    <PrimaryButton onClick={print} sx={{ displayPrint: 'none' }}>
                        <Trans>Print</Trans>
                    </PrimaryButton>
                </Flexbox>
            </Flexbox>

            <Flexbox justifyContent="center">
                <Flexbox id="wrapper" flexDirection="column">
                    <Flexbox justifyContent="end" marginBottom="50px">
                        {ems.logoUrl ? (
                            <img style={{ maxHeight: '80px', maxWidth: '250px' }} src={ems.logoUrl} alt={ems.name} />
                        ) : (
                            <Text variant="h1">{ems.name}</Text>
                        )}
                    </Flexbox>
                    <Flexbox justifyContent="space-between">
                        <Flexbox>
                            <InfoField label={t`Recipient`}>
                                <Text>{customer.name}</Text>
                                <Text>{customer.addressLine1}</Text>
                                <Text>{customer.addressLine2}</Text>
                                <Text>
                                    {customer.postCode} {customer.city}
                                </Text>
                                <Text>{customer.countryCode && translateCountry(customer.countryCode)}</Text>
                            </InfoField>
                        </Flexbox>

                        <Flexbox flexDirection="column" gap="10px">
                            <InfoField label={t`Date`}>
                                <Text>{formatToLongDate(new Date())}</Text>
                            </InfoField>
                            <InfoField label={t`Expiration date`}>
                                <Text>{formatToLongDate(validUntil)}</Text>
                            </InfoField>
                            <InfoField label={t`Contact person`}>
                                <Text>{contactPerson.fullName}</Text>
                                <Text>{contactPerson.email}</Text>
                                {contactPerson.phoneNumber && <Text>{contactPerson.phoneNumber}</Text>}
                            </InfoField>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} marginTop="50px">
                        <Text variant="h3">
                            <Trans>Quotation number:</Trans> {quotationNumber}
                        </Text>
                        <Text variant="body">
                            <pre
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: NOTO_SANS,
                                }}
                            >
                                {customText}
                            </pre>
                        </Text>
                        <Flexbox flexDirection={'column'} gap={12}>
                            <Flexbox flexDirection={'column'} gap={12}>
                                {data.assemblies.map((assembly, i) => (
                                    <Flexbox flexDirection={'column'} key={i} gap={12}>
                                        <Text variant="body-semibold">{assembly.name}</Text>
                                        <CostDataTable
                                            assembly={assembly}
                                            additionalLeadTimeDays={parseAdditionalLeadTimeDays(additionalLeadTimeDays)}
                                        />
                                    </Flexbox>
                                ))}
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                    <Divider
                        style={{
                            width: '100%',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '64px',
                        }}
                    >
                        <Text variant="body">
                            <pre
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: NOTO_SANS,
                                }}
                            >
                                {footerLeft}
                            </pre>
                        </Text>
                        <Text variant="body">
                            <pre
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: NOTO_SANS,
                                }}
                            >
                                {footerCenter}
                            </pre>
                        </Text>
                        <Text variant="body">
                            <pre
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: NOTO_SANS,
                                }}
                            >
                                {footerRight}
                            </pre>
                        </Text>
                    </Box>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};
