import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Column, FieldNumericController } from '@luminovo/design-system';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnStock: Column<TableQuotationOfferItem> = selectableColumn({
    attribute: 'stock',
    label: <Trans>Stock</Trans>,
    align: 'right',
    render: ({ extracted }) => {
        return formatDecimal(extracted);
    },
    InputComponent: ({ control }) => {
        return (
            <>
                <FieldNumericController
                    control={control}
                    FieldProps={{
                        autoFocus: true,
                        fullWidth: true,
                    }}
                    name="stock"
                    isInteger
                    required
                    min={0}
                />
            </>
        );
    },
});
