import { t } from '@lingui/macro';
import { isValidUrl, transEnum } from '@luminovo/commons';
import { FieldRadioControlled, FieldTextControlled, Flexbox, FormItem, Text } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { InfoLabel } from '@luminovo/manufacturing-core';
import { rfqStatusTranslations } from '@luminovo/sourcing-core';
import { CircularProgress, RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DialogBox from '../../../components/dialogBox/DialogBox';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { QuotationWarnings } from '../../Quotations/QuotationWarnings';

interface ChangeRfQStatusDialogProps {
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDialogOpen: boolean;
    rfqId: string;
    onSubmit: (form: ChangeRfQStatusFormValues) => void;
}

export const ChangeRfQStatusDialog = ({
    setIsDialogOpen,
    isDialogOpen,
    rfqId,
    onSubmit,
}: ChangeRfQStatusDialogProps): JSX.Element => {
    const { data: rfq, isLoading } = useRfQ(rfqId);
    if (isLoading) {
        return <CircularProgress color={'secondary'} size={16} />;
    }
    return (
        <DialogBox
            title={t`Change RfQ status`}
            isDialogOpen={isDialogOpen}
            onReject={() => {
                setIsDialogOpen(false);
            }}
            maxWidth={'xs'}
            style={{ overflow: 'hidden' }}
        >
            <ChangeRfQStatusForm
                rfqId={rfqId}
                onCloseCallback={async (form) => {
                    await onSubmit(form);
                    setIsDialogOpen(false);
                }}
                defaultValues={{
                    rfqStatus: rfq?.status || RfqStatus.QuotationInProgress,
                    shippingTrackingLink: rfq?.shipping_tracking_link ?? undefined,
                    internalNumber: rfq?.ems_internal_number ?? undefined,
                }}
            />
        </DialogBox>
    );
};

export interface ChangeRfQStatusFormValues {
    rfqStatus: RfqStatus;
    shippingTrackingLink?: string;
    internalNumber?: string;
}

function ChangeRfQStatusForm({
    rfqId,
    onCloseCallback,
    defaultValues,
}: {
    rfqId: string;
    onCloseCallback: (form: ChangeRfQStatusFormValues) => void;
    defaultValues: ChangeRfQStatusFormValues;
}) {
    const validationErrors: ValidationErrors<ChangeRfQStatusFormValues> = {
        'rfq.cannot_update_archived': {
            fieldPath: 'root.serverError',
        },
        'rfq.not_found': {
            fieldPath: 'root.serverError',
        },
        'rfq.unauthorized': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onCloseCallback} validationErrors={validationErrors}>
            <FormItemRfQStatus rfqId={rfqId} />
            <Flexbox justifyContent="flex-end" style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                <SubmitButton />
            </Flexbox>
        </FormContainer>
    );
}

function FormItemRfQStatus({ rfqId }: { rfqId: string }) {
    const { control } = useFormContext<ChangeRfQStatusFormValues>();
    const name = 'rfqStatus';

    const selectedRfQStatus = useWatch({ control, name });

    let quotationWarnings = <QuotationWarnings rfqId={rfqId} />;

    return (
        <Flexbox flexDirection="column" gap="20px">
            <FormItem label={t`Type`} required>
                <RadioGroup>
                    <Flexbox flexDirection={'column'} gap={12}>
                        {Object.keys(RfqStatus).map((status) => {
                            const typeCheckedStatus = status as RfqStatus;
                            return (
                                <Flexbox alignItems={'center'} gap={8} key={status}>
                                    <FieldRadioControlled
                                        control={control}
                                        name={name}
                                        FieldProps={{
                                            fieldValue: typeCheckedStatus,
                                        }}
                                    />
                                    <Text> {transEnum(typeCheckedStatus, rfqStatusTranslations)} </Text>
                                </Flexbox>
                            );
                        })}
                        {selectedRfQStatus === RfqStatus.OrderShipped && (
                            <FormItem
                                label={
                                    <InfoLabel
                                        info={t`Add a tracking link that is shown to your customer in the customer portal.`}
                                        label={t`Add tracking link`}
                                    />
                                }
                            >
                                <FieldTextControlled
                                    control={control}
                                    name={'shippingTrackingLink'}
                                    min={0}
                                    validate={(x: unknown) => {
                                        if (x === undefined) return;
                                        if (typeof x === 'string' && x.trim().length === 0) return;
                                        if (!isValidUrl(x)) {
                                            return t`Please ensure that this is a valid URL that contains "http(s)://"`;
                                        }
                                    }}
                                />
                            </FormItem>
                        )}
                        {selectedRfQStatus === RfqStatus.OrderConfirmed && (
                            <FormItem label={t`RfQ number`}>
                                <FieldTextControlled control={control} name={'internalNumber'} />
                            </FormItem>
                        )}
                    </Flexbox>
                </RadioGroup>
            </FormItem>
            {selectedRfQStatus === RfqStatus.QuotationAvailable && quotationWarnings}
        </Flexbox>
    );
}
