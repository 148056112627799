import { Duration as DurationType } from '@luminovo/http-client';
import { formatDuration } from '../../formatters';

/**
 * Renders a duration in a human readable format and wrappd in a <time> tag.
 * Note: please wrap this in a <Text> component if you want to style it.
 */
export const Duration = ({ duration }: { duration: DurationType | undefined }): JSX.Element => {
    if (duration === undefined) {
        return <time dateTime={undefined}>-</time>;
    }
    return <time dateTime={formatDuration(duration, 'html-spec-rounded')}>{formatDuration(duration)}</time>;
};
