import { t } from '@lingui/macro';
import { DepanelizationDTO, PanelDetailsPostDTO } from '@luminovo/http-client';

export const MIN_DISTANCE_FOR_MILLING = 2.4;
const MILLING_DEPANELIZATION_TYPES: DepanelizationDTO[] = ['Milling', 'MillingAndVCut'];

const convertToNumber = (value: string): number => {
    const val = parseFloat(value);
    if (isNaN(val)) {
        throw new Error('not a valid number');
    }
    return val;
};

const checkIfValuesAreEmpty = (panelDetails: PanelDetailsPostDTO): boolean => {
    try {
        convertToNumber(panelDetails.horizontal_spacing_in_mm);
        convertToNumber(panelDetails.vertical_spacing_in_mm);
        convertToNumber(panelDetails.padding.bottom_in_mm);
        convertToNumber(panelDetails.padding.top_in_mm);
        convertToNumber(panelDetails.padding.left_in_mm);
        convertToNumber(panelDetails.padding.right_in_mm);
        return false;
    } catch (e) {
        return true;
    }
};

export const isValidPanelDetails = (
    panelDetails: PanelDetailsPostDTO,
): {
    isValid: boolean;
    severity?: 'error' | 'warning';
    errorMessage?: string;
} => {
    if (panelDetails.row_count < 1 || panelDetails.column_count < 1) {
        return {
            isValid: false,
            severity: 'error',
            errorMessage: t`Panel must have at least one row and one column.`,
        };
    }

    if (checkIfValuesAreEmpty(panelDetails) === true) {
        return {
            isValid: false,
            severity: 'error',
        };
    }

    if (
        MILLING_DEPANELIZATION_TYPES.includes(panelDetails.depanelization) &&
        (parseFloat(panelDetails.horizontal_spacing_in_mm) < MIN_DISTANCE_FOR_MILLING ||
            parseFloat(panelDetails.vertical_spacing_in_mm) < MIN_DISTANCE_FOR_MILLING ||
            parseFloat(panelDetails.padding.bottom_in_mm) < MIN_DISTANCE_FOR_MILLING ||
            parseFloat(panelDetails.padding.top_in_mm) < MIN_DISTANCE_FOR_MILLING ||
            parseFloat(panelDetails.padding.left_in_mm) < MIN_DISTANCE_FOR_MILLING ||
            parseFloat(panelDetails.padding.right_in_mm) < MIN_DISTANCE_FOR_MILLING)
    ) {
        return {
            isValid: false,
            severity: 'warning',
            // eslint-disable-next-line spellcheck/spell-checker
            errorMessage: t`Depanelization that involves milling will usually require a minimum distance of 2.4 mm between the PCBs.`,
        };
    }

    return {
        isValid: true,
    };
};
