import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, PrimaryButton } from '@luminovo/design-system';
import { WorkflowType } from '@luminovo/http-client';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { assertPresent } from '../../../../../utils/assertPresent';
import { route } from '../../../../../utils/routes';
import { BannerCard } from './BannerCard';
import { GREEN_GRADIENT } from './gradients';

const MANUAL_AUTOMATIC_GRADIENT = 'linear-gradient(84.11deg, #57C9D4 -81.67%, #6BE98D 107.96%)';

export const BannerCardQuotationAvailable = ({
    rfqId,
    orderWorkflow,
}: {
    rfqId: string;
    orderWorkflow: WorkflowType;
}): JSX.Element => {
    const history = useHistory();

    if (orderWorkflow === 'Automatic') {
        return (
            <BannerCard
                background={MANUAL_AUTOMATIC_GRADIENT}
                title={t`Your price has been calculated!`}
                description={t`Continue with making your purchase.`}
                icon="📬"
                callToAction={
                    <PrimaryButton onClick={() => history.push(route('/rfqs/:rfqId/order', { rfqId: rfqId }))}>
                        <Trans>Checkout</Trans>
                    </PrimaryButton>
                }
            />
        );
    }

    if (orderWorkflow === 'Manual') {
        return (
            <BannerCard
                background={MANUAL_AUTOMATIC_GRADIENT}
                title={t`Your quotation is ready!`}
                description={t`Download the quotation and place your purchase in the next step.`}
                icon="📬"
                callToAction={
                    <PrimaryButton onClick={() => history.push(route('/rfqs/:rfqId/order', { rfqId: rfqId }))}>
                        <Trans>Continue</Trans>
                    </PrimaryButton>
                }
                secondaryAction={
                    <DownloadQuotationButton
                        rfqId={rfqId}
                        color={colorSystem.neutral.white}
                        background="rgba(255, 255, 255, .3)"
                        hideIfNoQuotation
                    />
                }
            />
        );
    }

    // eslint-disable-next-line spellcheck/spell-checker
    if (orderWorkflow === 'DontShow') {
        return (
            <BannerCard
                background={GREEN_GRADIENT}
                title={t`Your quotation is ready!`}
                description={t`Download the quotation and send your purchase order via mail.`}
                icon="📬"
                callToAction={
                    <DownloadQuotationButton rfqId={rfqId} background="white" color={colorSystem.neutral[7]} />
                }
            />
        );
    }

    assertUnreachable(orderWorkflow);
};

const DownloadQuotationButton = ({
    rfqId,
    color,
    background,
    hideIfNoQuotation,
}: {
    rfqId: string;
    color: string;
    background: string;
    hideIfNoQuotation?: boolean;
}): JSX.Element => {
    const { data: quotationUrl, isLoading: isLoadingQuotationUrl } = useHttpQuery(
        'GET /rfqs/:rfqId/quotation',
        { pathParams: { rfqId } },
        { select: (res) => res.data?.url },
    );

    if (hideIfNoQuotation && !quotationUrl) {
        return <></>;
    }

    const href = isLoadingQuotationUrl ? '' : assertPresent(quotationUrl);

    return (
        <Button href={href} style={{ background, color, border: 'none' }} download>
            <Trans>Download quotation</Trans>
        </Button>
    );
};
