import { isPresent } from '@luminovo/commons';
import { PCBV2, PCBV2Properties, PcbSpecificationProperties } from '@luminovo/http-client';
import { Capability } from './usePcbCapabilities';

export function createInitialPcbFormValues({
    pcb,
    fields,
}: {
    pcb: PCBV2;
    fields: Capability[];
}): PcbSpecificationProperties {
    const analysisValues: PCBV2Properties = pcb.properties;
    const userDefinedValues: PCBV2Properties = pcb.specifications[0]?.settings ?? {
        board: {},
        layerStack: {},
        mechanical: {},
        markings: {},
        reports: {},
        miscellaneous: {},
    };

    const initialFormValues: PcbSpecificationProperties = {
        board: {},
        layerStack: {},
        mechanical: {},
        markings: {},
        reports: {},
        miscellaneous: {},
    };

    function merge<T>(defaultValue?: T, analysis?: T, userDefined?: T, pdfSpecificationValue?: T): T | undefined {
        // user defined value → extracted → analysis → default

        if (isPresent(userDefined)) {
            return userDefined;
        }
        if (isPresent(pdfSpecificationValue)) {
            return pdfSpecificationValue;
        }
        if (isPresent(analysis)) {
            return analysis;
        }
        return defaultValue;
    }

    function fieldDefaultValue(field: Capability) {
        if ('disabled' in field.restrictions && field.restrictions.disabled) return undefined;

        if ('defaultUnit' in field.restrictions) {
            return {
                value: field.restrictions.default,
                unit: field.restrictions.defaultUnit,
            };
        }

        return field.restrictions.default;
    }

    for (const field of fields) {
        const defaultValue = fieldDefaultValue(field);

        // @ts-ignore
        const analysisValue: typeof defaultValue = analysisValues[field.section][field.capabilityName];
        // @ts-ignore
        const userDefinedValue: typeof defaultValue = userDefinedValues[field.section][field.capabilityName];
        // @ts-ignore
        const pdfSpecificationValue: typeof defaultValue = field.extractedFromPdf?.value;
        // @ts-ignore
        initialFormValues[field.section][field.capabilityName] = merge(
            defaultValue,
            analysisValue,
            userDefinedValue,
            pdfSpecificationValue,
        );
    }

    return initialFormValues;
}
