/* eslint-disable camelcase */
import { assertPresent, assertUnreachable } from '@luminovo/commons';
import { ExistingPanelPostDTO, PerPcbPanelPostDTO, PerSourcingScenarioPanelPostDTO } from '@luminovo/http-client';
import { DefaultPanelFormState, ExistingPanelFormState } from './types';

export const convertPanelDetailsFormValuesToDTO = ({
    values,
    pcbId,
}: {
    values: DefaultPanelFormState;
    pcbId: string;
}): PerSourcingScenarioPanelPostDTO[] | PerPcbPanelPostDTO => {
    switch (values.scope) {
        case 'PerSourcingScenario': {
            return values.data.map((data) => {
                const { sourcingScenarioId, ...panelDetails } = data;
                return {
                    type: 'PerSourcingScenario',
                    data: {
                        sourcing_scenario: assertPresent(sourcingScenarioId),
                        pcb: pcbId,
                        panel_details: panelDetails,
                    },
                };
            });
        }
        case 'PerPcb': {
            const { sourcingScenarioId, ...panelDetails } = values.data[0];
            return {
                type: 'PerPcb',
                data: {
                    pcb: pcbId,
                    panel_details: panelDetails,
                },
            };
        }
        default:
            assertUnreachable(values.scope);
    }
};

export const convertPanelExistingFormValuesToDTO = ({
    values,
    pcbId,
}: {
    values: ExistingPanelFormState;
    pcbId: string;
}): ExistingPanelPostDTO => {
    return {
        type: 'Existing',
        data: {
            pcb: pcbId,
            panel_height: values.data.panelHeight,
            panel_width: values.data.panelWidth,
            depanelization: values.data.depanelizationType,
            number_of_pcbs: values.data.numberOfPcbs,
            pcb_is_rotated: values.data.pcb_is_rotated,
        },
    };
};
