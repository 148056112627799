import { Trans } from '@lingui/macro';
import { colorSystem, Column, columnWidth, FieldSelect, Flexbox, Tag, Text } from '@luminovo/design-system';
import { ManufacturingScenarioDTO } from '@luminovo/http-client';
import { TableCell, Typography } from '@mui/material';
import { transEnum } from '../../../components/localization/TransEnum';
import { expenseLevelEnumPublicTranslations } from '../../../resources/expenses/expensesBackendTypes';
import { getValueOf } from '../../../utils/typingUtils';
import { renderCurrency, TextCell } from '../ActivitiesTable/activitiesTableComponents';
import { AppendExpensesButton } from '../ManufacturingScenario/components/AppendEntity';
import {
    createHeaderOrDataColumn,
    ManufacturingScenarioTemplateInstanceRow,
    renderEmptyHeaderRow,
    TemplateInstanceState,
} from '../shared/ActivityAndExpenseConfigurationTables';
import { CostCalculationCell } from './costCalculationCell';
import { DeleteExpenseConfiguration } from './deleteExpenseConfiguration';
import { ExpenseTableRow } from './types';
import { UpDownButtonsExpenseConfigurations } from './UpDownButtons';

const createRankColumn = (manufacturingScenarioId: string): Column<ExpenseTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'rank',
            label: <></>,
            width: columnWidth.extraSmall,
        },
        ({ data }): JSX.Element => (
            <TableCell>
                <UpDownButtonsExpenseConfigurations expenseConfiguration={data} />
            </TableCell>
        ),
        ({ data }, context) => (
            <ManufacturingScenarioTemplateInstanceRow
                data={data}
                context={context}
                AppendButton={
                    <AppendExpensesButton
                        manufacturingScenarioId={manufacturingScenarioId}
                        manufacturingScenarioTemplateInstanceId={
                            typeof data.templateInstanceId === 'string' ? data.templateInstanceId : undefined
                        }
                    />
                }
            />
        ),
    );
};

const createExpenseColumn = (): Column<ExpenseTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: `expense`,
            label: <Trans>Expense</Trans>,
            width: columnWidth.medium,
        },
        ({ data }): JSX.Element => (
            <TextCell firstRow={data.expenseConfigurationSummary.expense_configuration_details.expense_name} />
        ),
        renderEmptyHeaderRow,
    );
};

const createLevelColumn = (): Column<ExpenseTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: `level`,
            label: <Trans>Level</Trans>,
            width: columnWidth.medium,
        },
        ({ data }): JSX.Element => (
            <TextCell
                firstRow={
                    <Tag
                        color={'neutral'}
                        label={transEnum(
                            data.expenseConfigurationSummary.expense_configuration_details.level,
                            expenseLevelEnumPublicTranslations,
                        )}
                    />
                }
            />
        ),
        renderEmptyHeaderRow,
    );
};

const createCostCalculationColumn = (): Column<ExpenseTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'costCalculationColumn',
            label: <Trans>Cost calculation</Trans>,
            width: columnWidth.medium,
        },
        ({ data }) => {
            return (
                <CostCalculationCell
                    expenseConfigurationSummary={data.expenseConfigurationSummary}
                    manufacturingAssemblyDetails={data.manufacturingAssemblyDetails}
                />
            );
        },
        renderEmptyHeaderRow,
    );
};

const createDeleteExpenseConfigurationColumn = (
    selectedManufacturingScenarioId: string,
): Column<ExpenseTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: `deleteExpenseConfiguration`,
            label: '', // intentionally blank
            width: columnWidth.extraSmall,
        },
        ({ data }) => (
            <TextCell
                firstRow={
                    <DeleteExpenseConfiguration
                        expenseConfigurationId={
                            data.expenseConfigurationSummary.expense_configuration_details.expense_configuration_id
                        }
                        manufacturingScenarioId={selectedManufacturingScenarioId}
                    />
                }
            />
        ),
        renderEmptyHeaderRow,
    );
};

const createCostsColumn = ({
    batchSizes,
    selectedBatchSize,
    onSelectBatchSize,
}: {
    batchSizes: number[] | undefined;
    selectedBatchSize: number | undefined;
    onSelectBatchSize: (batchSize: number) => void;
}): Column<ExpenseTableRow, TemplateInstanceState> => {
    const handleChange = (value: number | null) => {
        if (typeof value !== 'number') {
            return;
        }
        onSelectBatchSize(value);
    };

    const showBatchSizes = batchSizes && selectedBatchSize;

    return createHeaderOrDataColumn(
        {
            id: 'costColumns',
            width: columnWidth.small,
            label: '', // intentionally blank,
            renderHead: (): JSX.Element => {
                return (
                    <TableCell style={{ verticalAlign: 'center' }}>
                        <Flexbox gap={'16px'} alignItems={'center'}>
                            <Text variant="h4" style={{ color: colorSystem.neutral[7], fontSize: '12px' }}>
                                <Trans>Cost</Trans>
                            </Text>

                            {showBatchSizes && (
                                <FieldSelect
                                    size="small"
                                    id="batch-size-select"
                                    value={selectedBatchSize}
                                    options={batchSizes.sort((a, b) => a - b)}
                                    getOptionLabel={(option) => option.toString()}
                                    onChange={handleChange}
                                    style={{ backgroundColor: colorSystem.neutral['white'] }}
                                />
                            )}
                        </Flexbox>
                    </TableCell>
                );
            },
        },
        ({ data }): JSX.Element => {
            const costComponent =
                data.expenseConfigurationSummary.expense_configuration_details.cost_components.costs_per_batch_size;
            const selectedBatchSizeCost = selectedBatchSize
                ? getValueOf(costComponent, selectedBatchSize.toString())
                : undefined;

            return (
                <>
                    {showBatchSizes ? (
                        <TextCell
                            firstRow={
                                <>
                                    {selectedBatchSizeCost && selectedBatchSizeCost.total_cost.result === 'Ok' && (
                                        <Typography>
                                            {renderCurrency({
                                                monetaryValue:
                                                    selectedBatchSizeCost.total_cost.data?.converted_mv ?? null,
                                                shouldAmountBePrecise: true,
                                                nullPlaceholder: '-',
                                            })}
                                        </Typography>
                                    )}
                                </>
                            }
                        />
                    ) : (
                        <></>
                    )}
                </>
            );
        },
        renderEmptyHeaderRow,
    );
};

export const generateColumns = ({
    manufacturingScenario,
    rows,
    manufacturingScenarioSummaryId,
    selectedBatchSize,
    onSelectBatchSize,
    manufacturingScenarioBatchSizes,
}: {
    manufacturingScenarioBatchSizes: number[];
    manufacturingScenario: ManufacturingScenarioDTO;
    rows: ExpenseTableRow[];
    manufacturingScenarioSummaryId: string;
    selectedBatchSize: number | undefined;
    onSelectBatchSize: (batchSize: number) => void;
}): Column<ExpenseTableRow, TemplateInstanceState>[] => {
    return [
        createRankColumn(manufacturingScenarioSummaryId),
        createExpenseColumn(),
        createLevelColumn(),
        createCostCalculationColumn(),
        createCostsColumn({ batchSizes: manufacturingScenarioBatchSizes, selectedBatchSize, onSelectBatchSize }),
        createDeleteExpenseConfigurationColumn(manufacturingScenarioSummaryId),
    ];
};
