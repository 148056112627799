import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import { AssemblyMonitoring } from '@luminovo/http-client';
import { useDrawerContext } from '../../../../components/contexts/ModalContext';
import { ViewContext } from '../ModuleTableData';
import { AssemblyMonitoringViewOnly } from './AssemblyMonitoringViewOnly';

export default function useMonitoringViewOnlyDrawer(viewContext: ViewContext) {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ assemblyMonitoring }: { assemblyMonitoring: AssemblyMonitoring }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox width="500px" flexDirection="column" boxSizing="border-box">
                        <AssemblyMonitoringViewOnly
                            onClose={closeDrawer}
                            viewContext={viewContext}
                            assemblyMonitoring={assemblyMonitoring}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
