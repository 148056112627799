import { PCBV2 } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { Capability } from '../../../../resources/pcb/pcbFunctions';

type DependencyRule = {
    fieldName: Capability['fieldName'];
    condition?: {
        value: boolean | string | number;
        type: 'equals' | 'truthy';
    };
};

//@ts-ignore
const CAPABILITY_DEPENDENCIES: Record<Capability['capabilityName'], DependencyRule[] | undefined> = {
    xRayMeasurementPoints: [
        {
            fieldName: 'reports.xRayMeasurement',
            condition: { type: 'truthy', value: true },
        },
    ],
} as const;

export const useTrackCapabilitiesDependency = (capability: Capability) => {
    const { watch } = useFormContext<PCBV2['properties']>();
    const dependencies = CAPABILITY_DEPENDENCIES[capability.capabilityName];

    if (!dependencies) {
        return undefined;
    }

    return dependencies.every((rule) => {
        const fieldValue = watch(rule.fieldName);

        if (!rule.condition) {
            return !!fieldValue;
        }

        switch (rule.condition.type) {
            case 'equals':
                return fieldValue === rule.condition.value;
            case 'truthy':
                return !!fieldValue;
            default:
                return true;
        }
    });
};
