import { compareByStringKey } from '@luminovo/commons';
import { chainComparators, Comparable, compareByNumber } from '@luminovo/design-system';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { compareByQuantity } from '@luminovo/sourcing-core';

export function compareQuoteRequestLineItems(): Comparable<QuoteRequestLineItemDTO> {
    return chainComparators(
        compareByStringKey((lineItem: QuoteRequestLineItemDTO) => lineItem.requested_part?.id),
        compareByQuantity((lineItem: QuoteRequestLineItemDTO) => lineItem.required_quantity),
        compareById((lineItem: QuoteRequestLineItemDTO) => lineItem.id),
    );
}

export function compareById<T>(extractId: (item: T) => number) {
    return compareByNumber(extractId);
}
