import { Trans } from '@lingui/macro';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { Box, CircularProgress } from '@mui/material';

export const OfferStateLoadingBox = () => {
    return (
        <Box
            style={{
                background: colorSystem.neutral.white,
                borderRadius: '8px',
                width: '100%',
                boxSizing: 'border-box',
            }}
        >
            <Flexbox
                style={{
                    padding: '16px',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                <CircularProgress size={'34px'} />
                <Text variant="h4">
                    <Trans>Inspecting the specification</Trans>
                </Text>
                <Text variant="body-small">
                    <Trans>Checking if you'll be able to get offers</Trans>
                </Text>
            </Flexbox>
        </Box>
    );
};
