import * as r from 'runtypes';
import { QuantityUnitRuntype } from '../backendTypes';
import { endpoint } from '../http/endpoint';
import { CustomOptionOfferDTORuntype, StandardPartOfferDTORuntype } from '../offer';
import { SupplierSolutionRuntype } from '../sourcingScenario';
import { SolutionDTORuntype } from './solutionBackendTypes';

/* eslint-disable camelcase */

export const solutionEndpoints = {
    'GET /solutions': endpoint({
        description: 'Returns an array of solutions for the query parameters specified in the request',
        pathParams: r.Undefined,
        queryParams: r.Record({
            solution_conf: r.String, // (sourcingConfigId)
            quantity: r.Number,
            unit: QuantityUnitRuntype,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(SolutionDTORuntype),
            standard_part_offers: r.Array(StandardPartOfferDTORuntype),
            custom_part_offers: r.Array(CustomOptionOfferDTORuntype),
        }),
    }),

    'POST /solutions/custom-part/bulk': endpoint({
        description: 'Fetches the solutions for a particular assembly',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            rfq_id: r.String,
            assembly_id: r.String,
        }),
        responseBody: r.Record({
            custom_part_offers: r.Array(CustomOptionOfferDTORuntype),
            supplier_solutions: r.Array(SupplierSolutionRuntype),
        }),
    }),
};
