import { assertUnreachable } from '@luminovo/commons';
import { Chip } from '@luminovo/design-system';
import { GenericPart } from '@luminovo/http-client';
import { PackageChip } from '@luminovo/sourcing-core';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { useGenericPartPackage } from './useGenericPartPackage';

export const TechParamsGenericPart = ({
    genericPart,
    hidePackage = false,
}: {
    genericPart: GenericPart;
    hidePackage?: boolean;
}) => {
    const packageData = useGenericPartPackage({
        packageId: genericPart.technical_parameters.package_id,
    });
    const isPackage = packageData?.mounting || packageData?.name;

    if (genericPart.type === 'Resistor') {
        const {
            resistance,
            tolerance,
            power_rating: powerRating,
            voltage_rating: voltageRating,
            temperature_coefficient: temperatureCoefficient,
        } = genericPart.technical_parameters;

        return (
            <>
                {resistance && (
                    <>
                        <Chip color={'neutral'} label={formatResistance(resistance)} />
                        &nbsp;
                    </>
                )}
                {tolerance && (
                    <>
                        <Chip color={'neutral'} label={formatTolerance(tolerance)} />
                        &nbsp;
                    </>
                )}
                {powerRating && (
                    <>
                        <Chip color={'neutral'} label={formatPowerRating(powerRating)} />
                        &nbsp;
                    </>
                )}
                {temperatureCoefficient && (
                    <>
                        <Chip color={'neutral'} label={formatTemperatureCoefficient(temperatureCoefficient)} />
                        &nbsp;
                    </>
                )}
                {voltageRating && (
                    <>
                        <Chip color={'neutral'} label={formatVoltageRating(voltageRating)} />
                        &nbsp;
                    </>
                )}
                {isPackage && !hidePackage && (
                    <>
                        <PackageChip formfit={packageData} />
                        &nbsp;
                    </>
                )}
            </>
        );
    }
    if (genericPart.type === 'Capacitor') {
        const {
            dielectric: dielectricMaterial,
            capacitance,
            tolerance,
            voltage_rating: voltageRating,
        } = genericPart.technical_parameters;
        return (
            <>
                {capacitance && (
                    <>
                        <Chip color={'neutral'} label={formatCapacitance(capacitance)} /> &nbsp;
                    </>
                )}
                {tolerance && (
                    <>
                        <Chip color={'neutral'} label={formatTolerance(tolerance)} />
                        &nbsp;
                    </>
                )}
                {dielectricMaterial && (
                    <>
                        <Chip color={'neutral'} label={formatDielectricMaterial(dielectricMaterial)} />
                        &nbsp;
                    </>
                )}

                {voltageRating && (
                    <>
                        <Chip color={'neutral'} label={formatVoltageRating(voltageRating)} />
                        &nbsp;
                    </>
                )}
                {isPackage && !hidePackage && (
                    <>
                        <PackageChip formfit={packageData} />
                        &nbsp;
                    </>
                )}
            </>
        );
    }
    assertUnreachable(genericPart);
};
