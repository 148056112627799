import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { CustomComponentFullRuntype, OtsComponentFullRuntype } from '../internalPartNumber';
import { CustomOptionOfferDTORuntype, StandardPartOfferDTORuntype } from '../offer';
import { CustomFullPartRuntype, GenericFullPartRuntype, OtsFullPartRuntype } from '../part';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { SupplierContactDTORuntype } from '../supplierContact';

export enum QuoteTrackingState {
    Pending = 'Pending',
    Received = 'Received',
    Overdue = 'Overdue',
    Discarded = 'Discarded',
    FailedToSend = 'FailedToSend',
}
export const QuoteTrackingStateRuntype = runtypeFromEnum(QuoteTrackingState);

export const RequestedStandardPartRuntype = r.Record({
    kind: r.Union(OtsFullPartRuntype, GenericFullPartRuntype, OtsComponentFullRuntype),
    required_quantity: r.Number.nullable(),
    solution_configurations: r.Array(r.String),
    offers: r.Array(r.String),
});

export const RequestedCustomPartRuntype = r.Record({
    kind: r.Union(CustomFullPartRuntype, CustomComponentFullRuntype),
    required_quantity: r.Number.nullable(),
    solution_configurations: r.Array(r.String),
});

export interface FileManagementPreferenceDTO extends r.Static<typeof FileManagementPreferenceRuntype> {}
export const FileManagementPreferenceRuntype = r.Record({
    should_include_pcb_specification: r.Boolean,
    should_include_shipping_panel_specification: r.Boolean,
});

export interface QuoteTrackingDTO extends r.Static<typeof QuoteTrackingDTORuntype> {}
export const QuoteTrackingDTORuntype = r.Record({
    id: r.String,
    rfq: r.String,
    supplier_and_stock_location: SupplierAndStockLocationDTORuntype,
    supplier_contacts: r.Array(SupplierContactDTORuntype),
    creation_date: r.String,
    due_date: r.String.nullable(),
    received_date: r.String.nullable(),
    state: QuoteTrackingStateRuntype,
    number: r.Number,
    request_standard_parts: r.Array(RequestedStandardPartRuntype),
    alternative_standard_parts: r.Array(RequestedStandardPartRuntype),
    standard_part_offers: r.Array(StandardPartOfferDTORuntype),
    request_custom_parts: r.Array(RequestedCustomPartRuntype),
    custom_part_offers: r.Array(CustomOptionOfferDTORuntype),
    files: r.Array(
        r.Record({
            name: r.String,
            created_at: r.String.nullable(),
            uploaded_by: r.String.nullable(),
            url: r.String,
        }),
    ),
    file_management_preference: FileManagementPreferenceRuntype,
    notes: r.String.nullable(),
});

/**
 * A light version of QuoteTrackingDTO suitable for /sourcing-full
 */
export interface QuoteTrackingLight extends r.Static<typeof QuoteTrackingLightRuntype> {}
export const QuoteTrackingLightRuntype = r.Record({
    id: r.String,
    rfq: r.String,
    supplier_and_stock_location: r.String,
    state: QuoteTrackingStateRuntype,
});
