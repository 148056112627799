import { t } from '@lingui/macro';
import { assertPresent, formatToIso8601Date, isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useCustomerFromRfq, useCustomers } from '../../../resources/customer/customerHandler';
import { useAttachmentFileMutation, useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useOTSOffer } from '../../../resources/offer/offerHandler';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useRfqListItem, useRfqs } from '../../../resources/rfq/rfqHandler';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { StandardPartOfferForm, StandardPartOfferFormValues } from './StandardPartOfferForm';
import {
    convertAzureFilesToUploadFiles,
    convertPriceBreaks,
    convertToStandardPartOfferUpdateDTO,
    convertValidForCustomer,
    convertValidForRfq,
    extractRfqIdFromContext,
} from './converters';

export function EditStandardPartOfferForm({ offerId, rfqContext }: { offerId: string; rfqContext: RfqContext }) {
    const history = useHistory();

    const rfqId = extractRfqIdFromContext(rfqContext);
    const { data: defaultRfq, isLoading: isRfqLoading } = useRfqListItem(rfqId);
    const { data: defaultCustomer, isLoading: isCustomerLoading } = useCustomerFromRfq(rfqId);

    const { data: offer } = useOTSOffer({ offerId, rfqContext });
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();

    const { data: rfqs } = useRfqs();
    const { data: customers } = useCustomers();

    const { preferredCurrency } = useGlobalCurrency();
    const { data: files } = useHttpQuery(
        'GET /offers/off-the-shelf/:id/additional-files',
        { pathParams: { id: offerId } },
        {
            select(data) {
                return data.items;
            },
        },
    );

    const { mutateAsync } = useHttpMutation('PATCH /offers/off-the-shelf/:id', {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateUpload } = useHttpFileUpload(
        `GET /offers/off-the-shelf/:id/additional-files/upload-link`,
        (response) => response.data.url,
        { snackbarMessage: null },
    );
    const { mutateAsync: mutateDelete } = useHttpMutation(`DELETE /offers/off-the-shelf/:id/additional-files`, {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateAttachmentFile } = useAttachmentFileMutation<{ offerId: string }>({
        mutateUpload: (file, { offerId }) => mutateUpload({ pathParams: { id: offerId }, files: file.file }),
        mutateDelete: (file, { offerId }) =>
            mutateDelete({ pathParams: { id: offerId }, requestBody: { file_name: file.name } }),
        onSuccess: () => history.goBack(),
        snackbarMessage: t`Manual offer updated`,
    });

    if (
        !offer ||
        !rfqs ||
        !customers ||
        !supplierAndStockLocations ||
        !isPresent(files) ||
        isRfqLoading ||
        isCustomerLoading
    ) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: StandardPartOfferFormValues) => {
        const requestBody = convertToStandardPartOfferUpdateDTO(values);
        await mutateAsync({ pathParams: { id: offerId }, requestBody });
        await mutateAttachmentFile({
            files: values.files,
            context: { offerId: offerId },
        });
    };

    const defaultValues: StandardPartOfferFormValues = {
        linkedPart: offer.linked_part,
        supplierAndStockLocation: assertPresent(
            supplierAndStockLocations.find((s) => s.id === offer.linked_location.id),
        ),
        currency: offer.available_prices.price_breaks[0].unit_price.currency ?? preferredCurrency,

        itemClass: offer.item_class,
        cancellationWindowInDays: offer.cancellation_window_in_days,
        supplierPartNumber: offer.supplier_part_number,
        offerNumber: offer.offer_number,
        quantityUnit: offer.unit_of_measurement.quantity_unit.unit,
        quantity: offer.unit_of_measurement.quantity_unit.quantity,
        priceType: offer.price_type,
        factoryLeadTime: offer.available_prices.factory_lead_time_days,
        factoryQuantity: offer.available_prices.factory_quantity,
        onOrder: offer.available_prices.on_order,
        packaging: offer.packaging,
        stock: offer.available_prices.stock,

        validFromDate: offer.valid_from ? formatToIso8601Date(offer.valid_from) : undefined,
        validUntilDate: offer.valid_until ? formatToIso8601Date(offer.valid_until) : undefined,
        validForRfq: convertValidForRfq(offer.rfq, rfqs, defaultRfq ?? null),
        validForCustomer: convertValidForCustomer(offer.customer, customers, defaultCustomer ?? null),

        priceBreaks: convertPriceBreaks(offer.available_prices.price_breaks),
        oneTimeCosts: offer.one_time_costs.map((c) => ({
            amount: c.price.amount,
            description: c.description ?? undefined,
        })),
        ncnr: offer.ncnr,

        notes: offer.notes,
        files: convertAzureFilesToUploadFiles(files),
    };

    return (
        <StandardPartOfferForm
            defaultValues={defaultValues}
            rfqContext={rfqContext}
            onSubmit={onSubmitCallback}
            disabledQuantityUnit={true}
        />
    );
}
