import {
    AvailableSpaceContainer,
    CenteredLayout,
    Column,
    DataTable,
    ScrollableTableContainer,
    useDataTableState,
} from '@luminovo/design-system';
import { ExpenseDTO } from '@luminovo/http-client';
import { sortByProcessThenCategoryThenName } from '@luminovo/manufacturing-core';
import { CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import {
    DriverColumnContext,
    SitesColumnContext,
    getCategoryColumn,
    getDescriptionColumn,
    getInternalNumberColumn,
    getNameColumn,
    getProcessColumn,
    getSitesColumn,
    useDriverColumnContext,
    useSitesColumnContext,
} from '../../shared/columns';
import {
    ExpenseFormulasContext,
    ExpenseMenuColumnContext,
    getCostCalculationColumn,
    getDriversColumn,
    getExpenseMenuColumn,
    getExpenseStatusColumn,
    getLevelColumn,
    useExpenseFormulasContext,
    useExpenseMenuColumnContext,
} from './columns';

type ExpenseItem = Omit<ExpenseDTO, 'site_id'> & { sites: string[] };
type ExpenseDatabaseTableContext = SitesColumnContext &
    DriverColumnContext &
    ExpenseFormulasContext &
    ExpenseMenuColumnContext;

// FIXME: Use 'combine': https://tanstack.com/query/v5/docs/react/reference/useQueries#combine
const useExpenseDatabaseTableContext = (expenses: ExpenseItem[]): ExpenseDatabaseTableContext => {
    const sitesContext = useSitesColumnContext();
    const driverColumnContext = useDriverColumnContext();
    const formulasContext = useExpenseFormulasContext(expenses);
    const menuContext = useExpenseMenuColumnContext();

    return useMemo(() => {
        const sharedContext: ExpenseDatabaseTableContext = {
            ...sitesContext,
            ...driverColumnContext,
            ...formulasContext,
            ...menuContext,
        };

        return sharedContext;
    }, [sitesContext, driverColumnContext, formulasContext, menuContext]);
};

const ExpensesDatabaseTable = ({ query }: { query: string }): React.ReactElement => {
    const { data: resources, isLoading } = useHttpQuery('GET /expenses', { queryParams: {} });
    const expenses: ExpenseDTO[] = resources?.data ?? [];

    const sortedExpenses: ExpenseItem[] = sortByProcessThenCategoryThenName(expenses).map((expense) => ({
        ...expense,
        sites: expense.site_id ? [expense.site_id] : [],
    }));

    const sharedContext = useExpenseDatabaseTableContext(sortedExpenses);

    const indexItemData = (expense: ExpenseItem, _: ExpenseDatabaseTableContext): string[] => {
        return [expense.name, expense.internal_number ?? '', expense.description ?? ''];
    };

    const columns: Column<ExpenseItem, ExpenseDatabaseTableContext>[] = [
        getExpenseStatusColumn(),
        getInternalNumberColumn(),
        getNameColumn(),
        getSitesColumn(sharedContext),
        getProcessColumn(),
        getCategoryColumn(),
        getCostCalculationColumn(),
        getLevelColumn(),
        getDriversColumn(sharedContext),
        getDescriptionColumn(),
        getExpenseMenuColumn(),
    ];

    const tableState = useDataTableState({
        persistenceId: 'expensesDatabaseTable',
        columns,
        items: sortedExpenses,
        sharedContext,
        paginationOptions: {
            showPagination: true,
            persistPagination: true,
        },
        query,
        searchOptions: {
            idExtractor: (expense): string => expense.id,
            contentSearchOptions: {
                indexingStrategy: 'data-content',
                indexItemData,
            },
        },
    });

    return isLoading ? (
        <CenteredLayout height={'30vh'}>
            <CircularProgress />
        </CenteredLayout>
    ) : (
        <DataTable
            tableState={tableState}
            key={'activitiesDatabaseTable'}
            size={'medium'}
            overrides={{ Container: AvailableSpaceContainer, TableContainer: ScrollableTableContainer }}
        />
    );
};

export default ExpensesDatabaseTable;
