import { Packaging } from '@luminovo/http-client';
import { Attribute } from '../types';

const cases = [
    { when: /^TR$/, then: Packaging.Reel },
    { when: /TAPE\/REEL/i, then: Packaging.Reel },
    { when: /(?<![^\s'"])Reel(?![^\s,$])/i, then: Packaging.Reel },
    { when: /(?<![^\s])Tubes?(?![^\s,$])/i, then: Packaging.Tube },
    { when: /(?<![^\s])Trays?(?![^\s,$])/i, then: Packaging.Tray },
    { when: /(?<![^\s])Bulks?(?![^\s,$])/i, then: Packaging.Bulk },
    { when: /(?<![^\s])Bags?(?![^\s,$])/i, then: Packaging.Bag },
    { when: /^BROKENTR$/, then: Packaging.Tape },
    { when: /^Tape$/, then: Packaging.Tape },
    { when: /Gurtabschnitt/, then: Packaging.Tape },
    { when: /BOX/, then: Packaging.Carton },
];

export function parsePackaging(str: string): undefined | Attribute {
    for (const c of cases) {
        if (c.when.test(str)) {
            return { attr: 'packaging', value: c.then };
        }
    }
    return undefined;
}
