import { assertUnreachable, isPresent } from '@luminovo/commons';
import { CustomerType, VATIdentificationTypeDTO } from '@luminovo/http-client';

export type BusinessCustomerData = {
    vatId: string | undefined;
    vatIdType: VATIdentificationTypeDTO | undefined;
    commercialRegisterNumber: string | undefined;
};

export const extractBusinessCustomerData = (customerType: CustomerType): BusinessCustomerData | undefined => {
    const type = customerType.type;
    switch (type) {
        case 'Private':
            return undefined;
        case 'Business':
            if (!isPresent(customerType.data)) {
                return undefined;
            }
            const commercialRegisterNumber = customerType.data.commercial_register_number ?? undefined;
            const vat_id = customerType.data.vat_id;
            if (!isPresent(vat_id)) {
                return { vatId: undefined, vatIdType: undefined, commercialRegisterNumber };
            }
            if ('Incomplete' in vat_id) {
                return { vatId: vat_id.Incomplete, vatIdType: undefined, commercialRegisterNumber };
            } else {
                return {
                    vatId: vat_id.Complete.id,
                    vatIdType: vat_id.Complete.id_type,
                    commercialRegisterNumber,
                };
            }
        default:
            /* c8 ignore next */
            assertUnreachable(type);
    }
};
