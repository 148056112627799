import { Trans, t } from '@lingui/macro';
import { DialogTitle, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { Dialog } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { ColumnMappingGuidanceTable } from './ColumnMappingGuidanceTable';

export const useColumnMappingGuidance = () => {
    const { closeDialog, setDialog } = useDialogContext();
    return {
        openDrawer: () => {
            setDialog(
                <Dialog open={true} onClose={closeDialog} PaperProps={{ style: { minWidth: '1300px' } }}>
                    <DialogTitle
                        title={t`Guidance on column mapping`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                    />
                    <Flexbox flexDirection="column" padding="16px 24px" gap="20px">
                        <Flexbox flexDirection="column" gap="12px">
                            <Text>
                                •{' '}
                                <Trans>
                                    We automatically tagged some columns in your BOM. Please make sure the column
                                    mapping is accurate.
                                </Trans>
                            </Text>
                            <Text>
                                • <Trans>Assign tags to classify information in the original BOM Excel.</Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    You can apply tags multiple times if the information is scattered across columns.
                                </Trans>
                            </Text>
                            <Text>
                                • <Trans>Keep in mind that once imported, incorrect tags cannot be reversed.</Trans>
                            </Text>
                            <Text>
                                • <Trans>The supported keywords mentioned below are not case sensitive.</Trans>
                            </Text>
                        </Flexbox>
                        <ColumnMappingGuidanceTable />
                    </Flexbox>
                </Dialog>,
            );
        },
    };
};
