import { PcbAttribute } from '../PcbAttribute';

// one to three places before the decimal separator, one to two after
// being inflexibile in the parsing is on purpose as this is how finalThickness in mm is usually written
const simpleMillimeterRegex = new RegExp('(?<![^\\s=])(\\d{1,3}(?:\\.\\d{1,2}))(?:\\s?mm)(?![^\\s,\\.$])', 'i');
const plusMinusMillimeterRegex = new RegExp('(?:\\+\\/?-?|-)(\\d{1,3}(?:\\.\\d{1,2}))(?:\\s?mm)', 'i');

export function parseMillimeter(str: string): PcbAttribute | undefined {
    const numberToParse = str.replace(',', '.').match(simpleMillimeterRegex)?.[0];
    if (!numberToParse) {
        return undefined;
    }
    const number = parseFloat(numberToParse);
    if (isNaN(number)) {
        return undefined;
    }
    return {
        attr: 'millimeter',
        value: number,
    };
}

export function parseMillimeterWithPlusMinus(str: string): PcbAttribute | undefined {
    const numberToParse = str.replace(',', '.').match(plusMinusMillimeterRegex)?.[0];
    if (!numberToParse) {
        return undefined;
    }
    const number = parseFloat(numberToParse);
    if (isNaN(number)) {
        return undefined;
    }
    return {
        attr: 'millimeter',
        value: number,
    };
}

export function parseMicrometer(str: string, allowWithoutUnit: boolean = false): PcbAttribute[] {
    // one to three places, no decimal separator
    // being inflexibile in the parsing is on purpose as this is how copper thickness in um is usually written
    const simpleMicrometerRegex = new RegExp(
        `(?<![^\\s=\\(])(\\d{1,3})(?:.?(?:u|µ)m?)${allowWithoutUnit ? '?' : ''}(?![^\\s,\\+\\.$]\\))`,
        'i',
    );
    const result: PcbAttribute[] = [];
    for (const part of str.split('/')) {
        const numberToParse = part.replace(',', '.').match(simpleMicrometerRegex)?.[0];
        if (!numberToParse) {
            continue;
        }
        const number = parseFloat(numberToParse);
        if (isNaN(number)) {
            continue;
        }
        result.push({
            attr: 'micrometer',
            value: number,
        });
    }
    return result;
}

export function parseCopperWeightAsMicrometer(str: string): PcbAttribute | undefined {
    // one to three places, no decimal separator
    // being inflexibile in the parsing is on purpose as this is how copper thickness in um is usually written
    const simpleOzRegex = new RegExp('(?<![^\\s=\\(])(1|2|3|4)(?:.?oz)(?![^\\s,\\+\\.$]\\))', 'i');
    const numberToParse = str.replace(',', '.').match(simpleOzRegex)?.[0];
    if (!numberToParse) {
        return undefined;
    }
    const number = parseFloat(numberToParse);
    if (isNaN(number)) {
        return undefined;
    }
    return {
        attr: 'micrometer',
        // 1oz of copper = 35um thickness
        value: number * 35,
    };
}
