import { isPresent } from '@luminovo/commons';

export const compareBreadCrumbs = (breadCrumbs1: string[], breadCrumbs2: string[]): number => {
    const maxLength = Math.max(breadCrumbs1.length, breadCrumbs2.length);

    for (let i = 0; i < maxLength; i++) {
        const assemblyName1 = i < breadCrumbs1.length ? breadCrumbs1[i] : undefined;
        const assemblyName2 = i < breadCrumbs2.length ? breadCrumbs2[i] : undefined;

        if (!isPresent(assemblyName1)) {
            return -1;
        } else if (!isPresent(assemblyName2)) {
            return 1;
        } else {
            const value = assemblyName1.localeCompare(assemblyName2);
            if (value === 0) {
                continue;
            }

            return value;
        }
    }

    return 0;
};
