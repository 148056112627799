import seedrandom from 'seedrandom';

var seededRandomNumber = seedrandom('1234');

// https://stackoverflow.com/a/2117523
export function getRandomUuidv4(): string {
    // eslint-disable-next-line spellcheck/spell-checker
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (seededRandomNumber() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
// https://stackoverflow.com/a/4550514
export const getRandomfromArray = <T>(array: T[]): T => array[Math.floor(seededRandomNumber() * array.length)];

// eslint-disable-next-line import/no-unused-modules
export const getRandomName = (): string => getRandomfromArray(['John', 'Mark', 'Lydia', 'Deborah']);
export const getRandomCompanyName = (): string =>
    getRandomfromArray(['NY Components', 'Special Components', 'Big EMS', 'Small EMS']);

// https://stackoverflow.com/a/1527820
export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(seededRandomNumber() * (max - min + 1)) + min;
}
//https://stackoverflow.com/a/9035732
export function getRandomDate(start: Date, end: Date): Date {
    return new Date(start.getTime() + seededRandomNumber() * (end.getTime() - start.getTime()));
}

// https://stackoverflow.com/a/17726997
export function getRandomFloat(min: number, max: number, precision = 15) {
    return Number((seededRandomNumber() * (max - min) + min).toFixed(precision));
}
