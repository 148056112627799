import { colorSystem } from '@luminovo/design-system';
import React from 'react';
import { usePdfViewerState } from '../hooks/usePdfViewerState';
import { BoundingBox } from '../model/boundingBox';
import { findSelectedRegions } from '../model/findSelectedRegions';
import { PolygonView } from './PolygonView';

export function LayerInspectMode({
    pageNumber,
    width,
    height,
}: {
    pageNumber: number;
    width: number;
    height: number;
}): JSX.Element {
    const [state] = usePdfViewerState();
    const svgRef = React.useRef<SVGSVGElement>(null);

    const { mode } = state;
    const viewbox = {
        width,
        height,
    };
    const highlightBox: BoundingBox | undefined =
        mode.type === 'inspect' && mode.highlightBox
            ? mode.highlightBox.applyViewbox(viewbox).addPadding(4)
            : undefined;

    if (mode.type !== 'inspect') {
        return <> </>;
    }

    const selectedRegions = findSelectedRegions(state).filter((region) => region.pageNumber === pageNumber);

    if (mode.pageNumber !== pageNumber) {
        return <> </>;
    }

    return (
        <div style={{ left: 0, top: 0, position: 'absolute' }}>
            <svg
                ref={svgRef}
                viewBox={`0 0 ${width} ${height}`}
                style={{
                    width,
                    height,
                }}
            >
                {highlightBox && (
                    <rect
                        key="highlight-box"
                        x={highlightBox.x}
                        y={highlightBox.y}
                        width={highlightBox.width}
                        height={highlightBox.height}
                        strokeDasharray="4 4"
                        stroke={colorSystem.violet[6]}
                        fill={colorSystem.violet[3]}
                        fillOpacity={0.1}
                        strokeWidth={1}
                        rx={8}
                        ry={8}
                    />
                )}

                {selectedRegions.map((region, i) => {
                    return <PolygonView key={i} viewbox={viewbox} region={region} />;
                })}
            </svg>
        </div>
    );
}
