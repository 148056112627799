/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { Chip, colorSystem, Column, Row, Text } from '@luminovo/design-system';
import { BatchSizeBreakdown } from '@luminovo/http-client';
import { Duration, InfoLabel } from '@luminovo/manufacturing-core';
import { TableCell, Typography } from '@mui/material';
import { renderCostContent } from '../../shared/ManufacturingSharedFunctions';

export interface BatchSizeBreakdownWithBatchSize extends BatchSizeBreakdown {
    batch_size: string;
}

const labelTextProps: React.ComponentProps<typeof Text> = {
    variant: 'h4',
    color: colorSystem.neutral[7],
};

export const generateManufacturingScenarioCostBreakdownColumn = (): Column<BatchSizeBreakdownWithBatchSize>[] => {
    return [
        {
            id: 'batchSize',
            label: <Trans>Batch size</Trans>,
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell padding="checkbox">
                        <Chip color={'neutral'} label={rowData.batch_size} />
                    </TableCell>
                );
            },
        },
        {
            id: 'unitCosts',
            label: (
                <InfoLabel
                    label={t`Unit costs`}
                    info={t`Sum of all costs from activities and expenses on unit level`}
                    textProps={labelTextProps}
                />
            ),
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>{renderCostContent(rowData.total_unit_cost)}</Typography>
                    </TableCell>
                );
            },
        },
        {
            id: 'batchCosts',
            label: (
                <InfoLabel
                    label={t`Batch costs`}
                    info={t`Sum of all costs from activities and expenses on batch level`}
                    textProps={labelTextProps}
                />
            ),
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>{renderCostContent(rowData.total_batch_cost)}</Typography>
                    </TableCell>
                );
            },
        },
        {
            id: 'projectCost',
            label: (
                <InfoLabel
                    label={t`Project costs`}
                    info={t`Sum of all costs from activities and expenses on project level`}
                    textProps={labelTextProps}
                />
            ),
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>{renderCostContent(rowData.total_project_cost)}</Typography>
                    </TableCell>
                );
            },
        },
        {
            id: 'effectiveCostPerUnit',
            label: (
                <InfoLabel
                    label={t`Effective costs per unit`}
                    info={t`Unit costs + (Batch costs / Batch size)`}
                    textProps={labelTextProps}
                />
            ),
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>{renderCostContent(rowData.total_effective_cost_per_unit)}</Typography>
                    </TableCell>
                );
            },
        },
        {
            id: 'effectiveCostPerBatch',
            label: (
                <InfoLabel
                    label={t`Effective costs per batch`}
                    info={t`Effective cost per unit * Batch size`}
                    textProps={labelTextProps}
                />
            ),
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>{renderCostContent(rowData.total_effective_cost_per_batch)}</Typography>
                    </TableCell>
                );
            },
        },
    ];
};

export const generateManufacturingScenarioTimeBreakdownColumn = (): Column<BatchSizeBreakdownWithBatchSize>[] => {
    return [
        {
            id: 'batchSize',
            label: <Trans>Batch size</Trans>,
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell padding="checkbox">
                        <Chip color={'neutral'} label={rowData.batch_size} />
                    </TableCell>
                );
            },
        },
        {
            id: 'unitTimeCalculation',
            label: <Trans>Unit time calculation</Trans>,
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>
                            <Duration duration={rowData.total_unit_time} />
                        </Typography>{' '}
                    </TableCell>
                );
            },
        },
        {
            id: 'batchTimeCalculation',
            label: <Trans>Batch time calculation</Trans>,
            render: ({ data: rowData }: Row<BatchSizeBreakdownWithBatchSize>): JSX.Element => {
                return (
                    <TableCell>
                        <Typography>
                            <Duration duration={rowData.total_batch_time} />
                        </Typography>{' '}
                    </TableCell>
                );
            },
        },
    ];
};
