import { Trans } from '@lingui/macro';
import { Divider, InputAdornment, Slider, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { SimpleHistogram } from '../../SimpleHistogram';
import { Text } from '../../Text';
import { TextField } from '../../TextField';
import { TertiaryButton } from '../../buttons';
import { OperatorNumeric, ParameterOption } from '../types';

type NumericOperator = OperatorNumeric<unknown, unknown>['op'];

function isHighlighted(op: NumericOperator, value: number) {
    return ({ from, to }: { from: number; to: number }): boolean => {
        switch (op) {
            case '>':
            case '≥':
                return value <= from;
            case '<':
            case '≤':
                return value >= from;
            case '=':
                return value >= from && value < to;
        }
    };
}

export function MenuOperatorNumber<T, TAutocompleteState>({
    onSelect,
    onCancel,
    operator,
    parameter,
    autocompleteState,
}: {
    onSelect: (value: string) => void;
    onCancel: () => void;
    operator: OperatorNumeric<T, TAutocompleteState>;
    parameter: ParameterOption<T, TAutocompleteState>;
    /**
     * The autocomplete state is an abstract type which the `operator.options` function can take as argument
     * to extract the options that are currently available given the user's context.
     */
    autocompleteState?: TAutocompleteState;
}): JSX.Element {
    const defaultNumber = (): number => {
        if (!operator.options || !autocompleteState) {
            return 0;
        }
        if (operator.binned) {
            return operator.options(autocompleteState)[0].value ?? 0;
        }
        return operator.options(autocompleteState)[0] ?? 0;
    };

    const [number, setNumber] = React.useState<number>(defaultNumber());
    return (
        <Container
            data-testid="MenuOperatorNumber"
            flexDirection={'column'}
            onKeyUp={(e) => {
                if (e.key === 'Enter' && number !== undefined) {
                    onSelect(String(number));
                }
                if (e.key === 'Escape') {
                    onCancel();
                }
            }}
        >
            {!operator.binned && operator.options && autocompleteState && (
                <HistogramContainer
                    setNumber={setNumber}
                    number={number}
                    op={operator.op}
                    values={operator.options(autocompleteState)}
                />
            )}
            <Flexbox justifyContent="space-between" alignItems="center">
                <Text variant="h5">{parameter.label ?? parameter.field}</Text>
                <TertiaryButton
                    onClick={() => onSelect(String(number))}
                    size="medium"
                    style={{ height: '16px', marginRight: '-12px' }}
                >
                    <Trans>Apply</Trans>
                </TertiaryButton>
            </Flexbox>
            <Divider style={{ margin: '4px -16px' }} />
            <TextField
                autoFocus
                size="small"
                fullWidth
                value={String(number)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment style={{ marginTop: 2 }} position="start">
                            {operator.op}
                        </InputAdornment>
                    ),
                    endAdornment: parameter.unit ? (
                        <InputAdornment position="end">{parameter.unit}</InputAdornment>
                    ) : undefined,
                }}
                onChange={(e) => setNumber(Number(e.target.value))}
            />
        </Container>
    );
}

const Container = styled(Flexbox)({
    gap: 8,
    padding: '12px 16px 16px 16px',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    width: '220px',
});

function HistogramContainer({
    values,
    setNumber,
    number,
    op,
}: {
    number: number;
    op: NumericOperator;
    values: number[];
    setNumber: (num: number) => void;
}): JSX.Element {
    if (values.length <= 1) {
        // it doesn't make sense to display a histogram when there is 0 or 1 values.
        return <></>;
    }
    return (
        <>
            <SimpleHistogram
                onSelect={({ from }) => setNumber(from)}
                isHighlighted={isHighlighted(op, number)}
                values={values}
            />
            <Slider
                value={number}
                min={values.reduce((a, b) => Math.min(a, b))}
                max={values.reduce((a, b) => Math.max(a, b))}
                valueLabelDisplay={'off'}
                onChange={(_, value) => {
                    setNumber(Array.isArray(value) ? value[0] : value);
                }}
            />
        </>
    );
}
