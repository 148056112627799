import { Attribute } from '../types';

function updateAttribute(attrs: Attribute[], newAttribute: Attribute): Attribute[] {
    let didUpdate = false;
    const newAttrs = attrs.map((current) => {
        const isManualUpdate = newAttribute.origin === 'manual' && current.attr === newAttribute.attr;
        const isValueUpdate = current.value === newAttribute.value && current.attr === newAttribute.attr;
        if (isManualUpdate || isValueUpdate) {
            didUpdate = true;
            return newAttribute;
        }
        return current;
    });
    if (!didUpdate) {
        return attrs.concat([newAttribute]);
    }
    return newAttrs;
}

export function updateAttributes(attrs: Attribute[], newAttributes: Attribute[]): Attribute[] {
    let result = attrs;
    for (const attr of newAttributes) {
        result = updateAttribute(result, attr);
    }
    return result;
}
