import ReactDOM from 'react-dom';
import App from './App';
import { getCustomerPortalTenant } from './permissions/tenants';
import { runCookieConsent } from './utils/analytics';

window.document.title = getCustomerPortalTenant()?.name ?? 'Luminovo';

runCookieConsent();

// @ts-ignore
window.hsConversationsSettings = {
    loadImmediately: false,
};
ReactDOM.render(<App />, document.getElementById('root'));
