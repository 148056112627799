import { useAssemblies } from '../../../resources/assembly/assemblyHandler';
import {
    useSourcingScenario,
    useSourcingScenarios,
} from '../../../resources/sourcingScenario/sourcingScenarioHandlers';

export const useIsSourcingScenarioPcbOnlyType = (
    sourcingScenarioId?: string,
): {
    data: boolean | undefined;
    isLoading: boolean;
} => {
    const { data: sourcingScenario, isLoading: isLoadingSourcingScenarios } = useSourcingScenario(sourcingScenarioId);
    const assemblyIds = sourcingScenario?.assembly_quantities.items.map((as) => as.assembly) ?? [];
    const { data: assemblies, isLoading: isLoadingAssemblies } = useAssemblies(assemblyIds);
    return {
        data: assemblies?.every((assembly) => assembly.type.type === 'PcbOnly'),
        isLoading: isLoadingAssemblies || isLoadingSourcingScenarios,
    };
};

export const useIsSourcingScenarioPcbOnlyTypeBulk = (
    sourcingScenarioIds: string[],
): {
    data: boolean | undefined;
    isLoading: boolean;
} => {
    const { data: sourcingScenarios, isLoading: isLoadingSourcingScenarios } =
        useSourcingScenarios(sourcingScenarioIds);
    const assemblyIds = sourcingScenarios?.flatMap((sourcingScenario) =>
        sourcingScenario.assembly_quantities.items.map((as) => as.assembly),
    );
    const { data: assemblies, isLoading: isLoadingAssemblies } = useAssemblies(assemblyIds);
    return {
        data: assemblies?.every((assembly) => assembly.type.type === 'PcbOnly'),
        isLoading: isLoadingAssemblies || isLoadingSourcingScenarios,
    };
};
