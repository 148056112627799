import { hasSiteTag } from '@luminovo/manufacturing-core';
import { useHttpQuery } from '../http/useHttpQuery';

export function useSites() {
    return useHttpQuery('GET /organization-settings/sites', {}, { select: (res) => res.data.sites });
}

export function useInventorySites() {
    return useHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            select: (res) => res.data.sites.filter((site) => hasSiteTag(site, 'Inventory')),
        },
    );
}

export function useInventorySite(siteId: string = '') {
    return useHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            select: (res) => res.data.sites.find((site) => site.id === siteId),
            enabled: Boolean(siteId),
        },
    );
}
