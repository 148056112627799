import { t } from '@lingui/macro';
import { FieldSelect, FieldSelectProps } from '@luminovo/design-system';
import { Packaging } from '@luminovo/http-client';
import React from 'react';
import { formatPackaging } from '../../../formatters';

export const FieldPackaging = React.memo(function FieldPackaging(
    props: Omit<FieldSelectProps<Packaging>, 'options'>,
): JSX.Element {
    return (
        <FieldSelect
            options={Object.values(Packaging)}
            getOptionLabel={formatPackaging}
            placeholder={t`Packaging`}
            {...props}
        />
    );
});
