import { colorSystem, Flexbox, FlexboxProps, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';

interface MultiProgressPieChartProps extends FlexboxProps {
    data: { label: string; value: number; color: string }[];
    overrides?: {
        offsetDegree?: number;
        padding?: number;
    };
}

// TODO(negotiations): Reimplement this component to fully fit the designs shown in figma
export const MultiProgressPieChart = ({
    data,
    overrides: { padding = 0, offsetDegree = 30 } = {},
    children,
    ...props
}: MultiProgressPieChartProps) => {
    const total = data.reduce((acc, item) => acc + item.value, 0);
    // This is the css style for the conic gradient for the Pie chart
    // We use useMemo to only calculate this once
    const boxStyle = React.useMemo(() => {
        // We get the percentage each item takes up
        const dataWithAngles = data.map((item) => ({
            ...item,
            percentage: (item.value / total) * 100,
        }));

        // We have a variable outside to keep track of the previous percentage
        let cumulativePercentage = 0;
        const conicGradientStops = dataWithAngles
            .map((item) => {
                // We get the start of the previous item and start from there
                const startPercentage = cumulativePercentage;
                cumulativePercentage += item.percentage;
                return `${item.color} ${startPercentage}% ${cumulativePercentage}%`;
            })
            // Combine all of them into a string
            .join(', ');
        return conicGradientStops;
    }, [data, total]);

    return (
        <Flexbox overflow="clip" gap={24} alignItems="center">
            <Flexbox
                justifyContent="center"
                alignItems="center"
                style={{
                    backgroundImage: `conic-gradient(from ${offsetDegree}deg, ${boxStyle})`,
                    borderRadius: '50%',
                }}
            >
                <Flexbox
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    borderRadius="50%"
                    bgcolor="white"
                    {...props}
                >
                    <Text variant="caption">Total</Text>
                    <Text variant="h3">{total}</Text>
                </Flexbox>
            </Flexbox>
            <Flexbox
                maxWidth={150}
                overflow="clip"
                textOverflow="ellipsis"
                justifyContent="center"
                flexDirection="column"
                rowGap={1}
            >
                {data.map((item, index) => (
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 6fr 2fr"
                        overflow="clip"
                        textOverflow="ellipsis"
                        alignItems="center"
                        key={index}
                    >
                        <Box width={12} height={12} borderRadius={'2px'} marginRight={'4px'} bgcolor={item.color} />
                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                            {item.label}
                        </Text>
                        <Text
                            variant="body-small"
                            color={colorSystem.neutral[7]}
                            style={{ textAlign: 'right', textOverflow: 'ellipsis' }}
                        >
                            {item.value}
                        </Text>
                    </Box>
                ))}
            </Flexbox>
        </Flexbox>
    );
};
