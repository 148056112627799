import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { createWholeWordRegex } from '@luminovo/manufacturing-core';
import {
    AdditionalCalculationInputControl,
    CalculationInputControl,
} from '../../../../../../components/MonacoEditor/editorTypes';

const totalMaterialCostFormulaValue = 'total_material_cost';
const totalOrderCostFormulaValue = 'total_order_cost';
const totalManufacturingCostFormulaValue = 'total_manufacturing_cost';

const generateTotalMaterialCost = (formulaValue: string): CalculationInputControl => ({
    name: t`Total material cost`,
    type: 'Total material cost',
    automaticDetails: undefined,
    formulaValue: totalMaterialCostFormulaValue,
    isInFormula: formulaValue !== undefined && createWholeWordRegex(totalMaterialCostFormulaValue).test(formulaValue),
    notes: t`Material costs including all material overhead costs per unit`,
});

const generateTotalOrderCost = (formulaValue: string): CalculationInputControl => ({
    name: t`Total order cost`,
    type: 'Total order cost',
    automaticDetails: undefined,
    formulaValue: totalOrderCostFormulaValue,
    isInFormula: formulaValue !== undefined && createWholeWordRegex(totalOrderCostFormulaValue).test(formulaValue),
    notes: t`All costs per unit (including project costs and excluding profits and post profit costs)`,
});

const generateTotalManufacturingCost = (formulaValue: string): CalculationInputControl => ({
    name: t`Total manufacturing cost`,
    type: 'Total manufacturing cost',
    automaticDetails: undefined,
    formulaValue: totalManufacturingCostFormulaValue,
    isInFormula:
        formulaValue !== undefined && createWholeWordRegex(totalManufacturingCostFormulaValue).test(formulaValue),
    notes: t`Manufacturing cost including all manufacturing overhead costs per unit`,
});

const generateCalculationInputControl = (
    inputControl: AdditionalCalculationInputControl,
    formula: string,
): CalculationInputControl => {
    const inputControlT = inputControl;
    switch (inputControlT) {
        case 'Total manufacturing cost':
            return generateTotalManufacturingCost(formula);
        case 'Total material cost':
            return generateTotalMaterialCost(formula);
        case 'Total order cost':
            return generateTotalOrderCost(formula);
        default:
            assertUnreachable(inputControlT);
    }
};

export const createAdditionalFormulaInputControls = (
    additionalInputControls: AdditionalCalculationInputControl[],
    formula: string,
): CalculationInputControl[] => {
    return additionalInputControls.map((inputControl) => generateCalculationInputControl(inputControl, formula));
};
