import { LanguageEnum } from '@luminovo/commons';
import { BritishFlagIcon, FrenchFlagIcon, GermanFlagIcon, SpanishFlagIcon } from '@luminovo/design-system';
import { assertUnreachable } from './typingUtils';

export function getIconForLanguage(language: LanguageEnum) {
    switch (language) {
        case 'en':
            return <BritishFlagIcon />;
        case 'de':
            return <GermanFlagIcon />;
        case 'fr':
            return <FrenchFlagIcon />;
        case 'es':
            return <SpanishFlagIcon />;
        default:
            assertUnreachable(language);
    }
}
