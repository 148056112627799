import { plural, t, Trans } from '@lingui/macro';
import { Card, colorSystem, compareByNumber, Flexbox, TanStackTable, Text } from '@luminovo/design-system';
import {
    AssemblyResponseDTO,
    AvailabilityStatusEnum,
    ComplianceStatus,
    DescendantsDTO,
    LifecycleEnum,
    ParentAssembly,
    PartCountEnum,
} from '@luminovo/http-client';
import { AngularProgressBar, AreaClosed, FractionalBarChart, palette } from '@luminovo/viz';
import { Box, Divider, styled } from '@mui/material';
import { Column } from '@tanstack/react-table';
import * as React from 'react';
import { useMemo } from 'react';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { LayoutCard } from '../../../../components/LayoutCard';
import { transEnum } from '../../../../components/localization/TransEnum';
import {
    useDescendantsSummary,
    useIsBomDropzoneVisible,
    useIsBomImportingAllowed,
} from '../../../../resources/assembly/assemblyHandler';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import {
    AvailabilityStatusPublicTranslations,
    ComplianceStatusTranslations,
    partCountTranslationsShort,
} from '../../../../resources/part/i18n';
import { lifecycleEnumLongNamePublicTranslations } from '../../../../resources/part/partFrontendTypes';
import { analytics, helpHero } from '../../../../utils/analytics';
import { BomImporter } from '../../../BomImporter/BomImporter';
import { useBomImporter } from '../../../BomImporter/BomImporterContext';
import { ModuleTableData, ViewContext } from '../ModuleTableData';
import { AssemblyEmissionsView } from './AssemblyEmissionsView';
import { dashboardWidth, useBomOverviewTable } from './BomOverviewTable/BomOverview';
import { bomOverviewColumnIds, BomOverviewTableData } from './BomOverviewTable/bomOverviewFrontendTypes';
import { BomSearchField } from './bomParametricSearchTypes';
import { normalizeEOLData, placeHolderEOLData } from './normalizeEOL';

const dashboardContainerId = 'dashboard-container';
const backToDashboardButtonId = 'back-to-dashboard';

type ComplianceLabel = 'RoHS' | 'REACH' | 'AECQ';

function trackAnalyticsEvents({
    rfqId,
    assemblyId,
    field,
    fieldStatus,
}: {
    rfqId?: string;
    assemblyId: string;
    field: 'part_options' | 'reach_compliance' | 'rohs_compliance' | 'availability' | 'lifecycle' | 'aecq_compliance';
    fieldStatus: string;
}) {
    /* eslint-disable camelcase */
    analytics.track('filter_via_graphs_in_dashboard', {
        rfq_id: rfqId,
        assembly_uuid: assemblyId,
        search_field: field,
        field_status: fieldStatus,
    });
    /* eslint-enable camelcase */
}

export const BomHealthDashboard = ({
    assembly,
    descendants,
    moduleData,
    isModuleDataLoading,
    isEditable,
    parents,
    isBomNestedTableShown,
    assemblyId,
    viewContext,
}: {
    assembly: AssemblyResponseDTO;
    descendants: DescendantsDTO;
    moduleData: ModuleTableData[];
    isModuleDataLoading: boolean;
    isEditable: boolean;
    parents: ParentAssembly[][];
    isBomNestedTableShown: boolean;
    assemblyId: string;
    viewContext: ViewContext;
}) => {
    const rfqId = viewContext.rfqId;
    const isBomImporterShown = useIsBomImportingAllowed({ rfqId, assemblyId });
    const importer = useBomImporter();
    const isBomImporterDropzoneShown = useIsBomDropzoneVisible({ assemblyId });
    const parentsFirstPath = parents[0] ?? [];
    const isBomMainPage = viewContext.type === 'WithinRfQ' ? parentsFirstPath.length < 2 : false;
    const { table } = useBomOverviewTable({
        items: isModuleDataLoading ? undefined : moduleData,
        isBomMainPage,
        assemblyId,
        viewContext,
    });

    // Ensures BOM details tour works as expected
    const hasBomBeenUploaded = !isBomImporterShown;
    helpHero?.update({ hasBomBeenUploaded });

    const isTopLevel = rfqId === assemblyId;

    const isAutomotiveAssembly = assembly.industry === 'Auto';

    const hasDesignItems =
        descendants.design_items.filter((designItem) => {
            return designItem.assembly === assemblyId;
        }).length > 0;

    return (
        <>
            {isBomNestedTableShown && (
                <Flexbox
                    id={dashboardContainerId}
                    padding="20px"
                    gap={20}
                    flexDirection={'column'}
                    flexWrap="wrap"
                    minWidth={'fit-content'}
                    style={{ background: colorSystem.neutral[1] }}
                >
                    {!isTopLevel && (
                        <Box
                            width="100%"
                            display="grid"
                            gridTemplateColumns={'400px 1fr'}
                            columnGap={'20px'}
                            rowGap={'20px'}
                            maxWidth={dashboardWidth}
                            margin={'auto'}
                        >
                            <StyledDashboardCard style={{ maxWidth: 'unset', gap: '24px' }}>
                                <Flexbox flexDirection="column" gap="16px">
                                    <Text variant="h3">
                                        <Trans>Part options</Trans>
                                    </Text>
                                    <Divider />
                                </Flexbox>
                                <Box display={'grid'} style={{ placeItems: 'center' }}>
                                    <ChartPartOptions
                                        rfqId={rfqId}
                                        assemblyId={assemblyId}
                                        viewContext={viewContext}
                                        column={table.getColumn(bomOverviewColumnIds.columnPartOptions)}
                                    />
                                </Box>
                            </StyledDashboardCard>
                            <StyledDashboardCard style={{ maxWidth: 'unset', gap: '24px' }}>
                                <Flexbox flexDirection="column" gap="16px">
                                    <Flexbox justifyContent="space-between">
                                        <Text variant="h3">
                                            <Trans>Compliance & sustainability</Trans>
                                        </Text>
                                        <AssemblyEmissionsView viewContext={viewContext} assemblyId={assemblyId} />
                                    </Flexbox>
                                    <Divider />
                                </Flexbox>
                                <Box
                                    flexGrow={1}
                                    display="grid"
                                    width="100%"
                                    gridTemplateColumns={isAutomotiveAssembly ? '1fr 1fr 1fr' : '1fr 1fr'}
                                    columnGap="20px"
                                >
                                    <Flexbox flexDirection="column" gap={8}>
                                        <Text variant="h4">RoHS</Text>
                                        <ChartCompliance
                                            rfqId={rfqId}
                                            assemblyId={assemblyId}
                                            variant="rohs_compliance"
                                            viewContext={viewContext}
                                            column={table.getColumn(bomOverviewColumnIds.columnRohs)}
                                        />
                                    </Flexbox>
                                    <Flexbox flexDirection="column" gap={8}>
                                        <Text variant="h4">REACH</Text>
                                        <ChartCompliance
                                            rfqId={rfqId}
                                            assemblyId={assemblyId}
                                            variant="reach_compliance"
                                            viewContext={viewContext}
                                            column={table.getColumn(bomOverviewColumnIds.columnReach)}
                                        />
                                    </Flexbox>
                                    {isAutomotiveAssembly && (
                                        <Flexbox flexDirection="column" gap={8}>
                                            <Text variant="h4">AECQ</Text>
                                            <ChartCompliance
                                                rfqId={rfqId}
                                                assemblyId={assemblyId}
                                                variant="aecq_compliance"
                                                viewContext={viewContext}
                                                column={table.getColumn(bomOverviewColumnIds.columnAecq)}
                                            />
                                        </Flexbox>
                                    )}
                                </Box>
                            </StyledDashboardCard>
                            <StyledDashboardCard style={{ maxWidth: 'unset' }}>
                                <Text variant="h3">
                                    <Trans>Availability</Trans>
                                </Text>
                                <ChartAvailability
                                    rfqId={rfqId}
                                    assemblyId={assemblyId}
                                    viewContext={viewContext}
                                    customerId={assembly.customer ?? undefined}
                                    column={table.getColumn(bomOverviewColumnIds.columnAvailability)}
                                />
                            </StyledDashboardCard>
                            <StyledDashboardCard style={{ maxWidth: 'unset' }}>
                                <Text variant="h3">
                                    <Trans>Lifecycle</Trans>
                                </Text>
                                <Box display="grid" width="100%" gridTemplateColumns={'50% 50%'} columnGap="20px">
                                    <ChartLifecycle
                                        rfqId={rfqId}
                                        assemblyId={assemblyId}
                                        viewContext={viewContext}
                                        column={table.getColumn(bomOverviewColumnIds.columnLifecycle)}
                                    />
                                    <ChartExpectedEol
                                        assemblyId={assemblyId}
                                        viewContext={viewContext}
                                        column={table.getColumn(bomOverviewColumnIds.columnYearsToEndOfLife)}
                                    />
                                </Box>
                            </StyledDashboardCard>
                        </Box>
                    )}
                    <Box maxWidth={dashboardWidth} margin="auto" width="100%">
                        <LayoutCard title={t`BOM overview`} style={{ height: '80vh', maxWidth: dashboardWidth }}>
                            <Box height="100%">
                                <TanStackTable table={table} size="medium" />
                            </Box>
                        </LayoutCard>
                    </Box>
                </Flexbox>
            )}
            {isEditable && isBomImporterShown && parentsFirstPath.length > 0 && rfqId && (
                <Flexbox
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    gap={16}
                    height={hasDesignItems ? 'unset' : '100%'}
                    style={{ background: colorSystem.neutral.white, boxSizing: 'border-box' }}
                >
                    {importer && (
                        <BomImporter
                            rfqId={rfqId}
                            importer={importer}
                            assemblyId={assemblyId}
                            viewContext={viewContext}
                            isBomImporterDropzoneShown={isBomImporterDropzoneShown}
                        />
                    )}
                </Flexbox>
            )}
        </>
    );
};

const StyledDashboardCard = styled(Card)({
    border: 0,
});

function ChartLifecycle({
    rfqId,
    assemblyId,
    viewContext,
    column,
}: {
    rfqId?: string;
    assemblyId: string;
    viewContext: ViewContext;
    column: Column<BomOverviewTableData, unknown> | undefined;
}) {
    const filterValues = column?.getFilterValue();
    const { data } = useDescendantsSummary(assemblyId, viewContext);
    const lifecycleColors: Record<LifecycleEnum, string> = {
        [LifecycleEnum.Acquired]: colorSystem.yellow[3],
        [LifecycleEnum.Active]: palette.default[2],
        [LifecycleEnum.Aftermarket]: colorSystem.red[5],
        [LifecycleEnum.EndOfLife]: colorSystem.red[4],
        [LifecycleEnum.NotRecommendedForNewDesigns]: colorSystem.yellow[5],
        [LifecycleEnum.Obsolete]: colorSystem.red[5],
        [LifecycleEnum.PreRelease]: palette.default[1],
        [LifecycleEnum.Unknown]: colorSystem.neutral[4],
    };

    const priority: Record<LifecycleEnum, number> = {
        [LifecycleEnum.Obsolete]: 1,
        [LifecycleEnum.EndOfLife]: 2,
        [LifecycleEnum.NotRecommendedForNewDesigns]: 3,
        [LifecycleEnum.Active]: 4,
        [LifecycleEnum.PreRelease]: 5,
        [LifecycleEnum.Acquired]: 6,
        [LifecycleEnum.Unknown]: 7,
        [LifecycleEnum.Aftermarket]: 8,
    };
    return (
        <FractionalBarChart
            getLabel={(item) => {
                if (item.status_type === LifecycleEnum.NotRecommendedForNewDesigns) {
                    return 'NRND';
                }
                return transEnum(item.status_type, lifecycleEnumLongNamePublicTranslations);
            }}
            getValue={'count'}
            getColor={(item) => lifecycleColors[item.status_type]}
            getTooltip={(item) => {
                return lifecycleTooltipLabel[item.status_type];
            }}
            data={(data?.lifecycle ?? []).sort(compareByNumber((item) => priority[item.status_type]))}
            isSelected={(item) => Array.isArray(filterValues) && filterValues.includes(item.status_type)}
            onBarClick={(item) => {
                trackAnalyticsEvents({ rfqId, assemblyId, field: 'lifecycle', fieldStatus: item.status_type });
                const isSelected = Array.isArray(filterValues) && filterValues.includes(item.status_type);
                if (isSelected) {
                    const newFilters = Array.from(filterValues).filter((status) => status !== item.status_type);
                    column?.setFilterValue(newFilters.length === 0 ? undefined : newFilters);
                } else {
                    scrollIntoView({ id: backToDashboardButtonId });
                    column?.setFilterValue([item.status_type]);
                }
            }}
        />
    );
}

function ChartAvailability({
    rfqId,
    assemblyId,
    customerId,
    viewContext,
    column,
}: {
    rfqId?: string;
    assemblyId: string;
    viewContext: ViewContext;
    customerId?: string;
    column: Column<BomOverviewTableData, unknown> | undefined;
}) {
    const filterValues = column?.getFilterValue();
    const tenantName = useCurrentUserDetailsContext().organization.name;
    const customerName = useCustomer(customerId).data?.name;
    const { data } = useDescendantsSummary(assemblyId, viewContext);
    const colors: Record<AvailabilityStatusEnum, string> = {
        [AvailabilityStatusEnum.ConsignedByOEM]: palette.default[3],
        [AvailabilityStatusEnum.InStock]: palette.default[1],
        [AvailabilityStatusEnum.InsufficientStock]: colorSystem.yellow[5],
        [AvailabilityStatusEnum.InventoryOfEMS]: palette.default[2],
        [AvailabilityStatusEnum.OutOfStock]: colorSystem.red[5],
    };

    const priority: Record<AvailabilityStatusEnum, number> = {
        [AvailabilityStatusEnum.OutOfStock]: 5,
        [AvailabilityStatusEnum.InsufficientStock]: 4,
        [AvailabilityStatusEnum.InStock]: 3,
        [AvailabilityStatusEnum.InventoryOfEMS]: 2,
        [AvailabilityStatusEnum.ConsignedByOEM]: 1,
    };

    return (
        <FractionalBarChart
            style={{ flexGrow: 1 }}
            data={(data?.availability ?? []).sort(compareByNumber((item) => -priority[item.status_type]))}
            getLabel={(item) => {
                if (item.status_type === AvailabilityStatusEnum.InventoryOfEMS) {
                    return (
                        <Trans>
                            {transEnum(item.status_type, AvailabilityStatusPublicTranslations)} of {tenantName}
                        </Trans>
                    );
                }
                if (item.status_type === AvailabilityStatusEnum.ConsignedByOEM && customerName) {
                    return (
                        <Trans>
                            {transEnum(item.status_type, AvailabilityStatusPublicTranslations)} by {customerName}
                        </Trans>
                    );
                }
                return transEnum(item.status_type, AvailabilityStatusPublicTranslations);
            }}
            isSelected={(item) => Array.isArray(filterValues) && filterValues.includes(item.status_type)}
            getValue={'count'}
            getColor={(item) => colors[item.status_type]}
            getTooltip={(item) => {
                return availabilityTooltipLabel[item.status_type];
            }}
            onBarClick={(item) => {
                trackAnalyticsEvents({ rfqId, assemblyId, field: 'availability', fieldStatus: item.status_type });
                const isSelected = Array.isArray(filterValues) && filterValues.includes(item.status_type);
                if (isSelected) {
                    const newFilters = Array.from(filterValues).filter((status) => status !== item.status_type);
                    column?.setFilterValue(newFilters.length === 0 ? undefined : newFilters);
                } else {
                    scrollIntoView({ id: backToDashboardButtonId });
                    column?.setFilterValue([item.status_type]);
                }
            }}
        />
    );
}

function convertVariant(variant: 'rohs_compliance' | 'reach_compliance' | 'aecq_compliance'): {
    bomSearchField: BomSearchField;
    label: ComplianceLabel;
} {
    switch (variant) {
        case 'rohs_compliance':
            return { bomSearchField: BomSearchField.rohs, label: 'RoHS' };
        case 'reach_compliance':
            return { bomSearchField: BomSearchField.reach, label: 'REACH' };
        case 'aecq_compliance':
            return { bomSearchField: BomSearchField.aecq, label: 'AECQ' };
    }
}

function ChartCompliance({
    rfqId,
    assemblyId,
    variant,
    viewContext,
    column,
}: {
    rfqId?: string;
    assemblyId: string;
    viewContext: ViewContext;
    variant: 'rohs_compliance' | 'reach_compliance' | 'aecq_compliance';
    column: Column<BomOverviewTableData, unknown> | undefined;
}) {
    const filterValues = column?.getFilterValue();
    const { data } = useDescendantsSummary(assemblyId, viewContext);
    const colors: Record<ComplianceStatus, string> = {
        [ComplianceStatus.Compliant]: palette.default[1],
        [ComplianceStatus.CompliantWithExemption]: palette.default[1],
        [ComplianceStatus.NonCompliant]: colorSystem.red[5],
        [ComplianceStatus.NotRequired]: colorSystem.neutral[4],
        [ComplianceStatus.Unknown]: colorSystem.neutral[4],
    };

    const priority: Record<ComplianceStatus, number> = {
        [ComplianceStatus.NonCompliant]: 1,
        [ComplianceStatus.Unknown]: 2,
        [ComplianceStatus.Compliant]: 3,
        [ComplianceStatus.CompliantWithExemption]: 4,
        [ComplianceStatus.NotRequired]: 5,
    };
    const { label } = convertVariant(variant);

    return (
        <FractionalBarChart
            style={{ height: '100%' }}
            data={((data && data[variant]) ?? [])
                // Ensure a stable order
                .sort(compareByNumber((x) => priority[x.status_type]))}
            getColor={(item) => colors[item.status_type]}
            getLabel={(item) => transEnum(item.status_type, ComplianceStatusTranslations)}
            getValue={'count'}
            getTooltip={(item) => {
                return complianceTooltipLabel({
                    status: item.status_type,
                    field: label,
                });
            }}
            isSelected={(item) => Array.isArray(filterValues) && filterValues.includes(item.status_type)}
            onBarClick={(item) => {
                trackAnalyticsEvents({ rfqId, assemblyId, field: variant, fieldStatus: item.status_type });
                const isSelected = Array.isArray(filterValues) && filterValues.includes(item.status_type);
                if (isSelected) {
                    const newFilters = Array.from(filterValues).filter((status) => status !== item.status_type);
                    column?.setFilterValue(newFilters.length === 0 ? undefined : newFilters);
                } else {
                    scrollIntoView({ id: backToDashboardButtonId });
                    column?.setFilterValue([item.status_type]);
                }
            }}
        />
    );
}

const xAxis = [0, 2, 4, 6, 8, 10];
const range = {
    from: 0,
    to: 10,
};

function ChartExpectedEol({
    assemblyId,
    viewContext,
    column,
}: {
    assemblyId: string;
    viewContext: ViewContext;
    column: Column<BomOverviewTableData, unknown> | undefined;
}) {
    const { data } = useDescendantsSummary(assemblyId, viewContext);

    const { yearsAndCount, maxYearsAndCount, isDataUnavailable, yAxisDomainRange } = useMemo(() => {
        const allYearsToEndOfLife = data?.years_to_end_of_life ?? [];
        // We want the yAxisRange to encompass the entire range of years to end of life, and not just the fixed set eg: 2yrs to 8 yrs that we will be rendering.
        // this is because we do not want to give an impression that all the bom-items have a years to end of life of 10 yrs or less.

        const { yearsToEndOfLifeUpTo10Yrs, maxYearsAndCount } = normalizeEOLData(allYearsToEndOfLife, range);

        const yAxisDomainRange: [number, number] = [
            0,
            yearsToEndOfLifeUpTo10Yrs.reduce((max, d) => Math.max(max, d.count), 0),
        ];

        if (yearsToEndOfLifeUpTo10Yrs.length > 0) {
            const xAndYAxis = yearsToEndOfLifeUpTo10Yrs.map((item) => ({
                xAxis: item.years_to_end_of_life,
                yAxis: item.count,
            }));

            return {
                yearsAndCount: xAndYAxis,
                maxYearsAndCount,
                isDataUnavailable: false,
                yAxisDomainRange,
            };
        }

        const placeHolderData = placeHolderEOLData(range.to - 1);

        return { yearsAndCount: placeHolderData, isDataUnavailable: true, yAxisDomainRange: undefined };
    }, [data?.years_to_end_of_life]);

    const textStyle: React.CSSProperties = isDataUnavailable
        ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
          }
        : {
              paddingBottom: 5,
          };
    return (
        <div style={{ width: '100%', position: 'relative', paddingBottom: '7px' }}>
            <div
                style={{
                    margin: 'auto',
                    width: '100%',
                    marginBottom: 20,
                }}
            >
                <Text variant="h5" style={textStyle}>
                    {isDataUnavailable ? (
                        <Trans>No expected end-of-life data available</Trans>
                    ) : (
                        <Trans>Expected years to end of life</Trans>
                    )}
                </Text>
            </div>

            <div
                style={{
                    margin: 'auto',
                    width: 'calc(100% - 55px)',
                    height: 'calc(100% - 55px)',
                    filter: isDataUnavailable ? 'blur(2px)' : 'none',
                }}
            >
                <AreaClosed
                    showYAxis={true}
                    xAxis={xAxis}
                    data={yearsAndCount}
                    yAxisDomainRange={yAxisDomainRange}
                    valueFormatter={(value, index, ticks) => {
                        if (value === range.to && maxYearsAndCount) {
                            const maxYTEOL = maxYearsAndCount.years_to_end_of_life;
                            return maxYTEOL <= range.to ? t`10 years` : t`10+ years`;
                        }
                        return index === 0
                            ? ''
                            : index === ticks[ticks.length - 1].index
                              ? ''
                              : `${value} ${plural(value, { one: 'year', other: 'years' })}`;
                    }}
                    color={colorSystem.red[4]}
                    dotCircleColor={colorSystem.red[5]}
                    onClick={(data) => {
                        if (isDataUnavailable) return;
                        const { xAxis: yearsToEOL } = data;
                        column?.setFilterValue([null, yearsToEOL]);
                    }}
                    Tooltip={({ datum }) => (
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            {`${datum.yAxis} ${plural(datum.yAxis, {
                                one: 'BOM item',
                                other: 'BOM items',
                            })}`}
                        </Text>
                    )}
                />
            </div>
        </div>
    );
}

function ChartPartOptions({
    rfqId,
    assemblyId,
    viewContext,
    column,
}: {
    rfqId?: string;
    assemblyId: string;
    viewContext: ViewContext;
    column: Column<BomOverviewTableData, unknown> | undefined;
}) {
    const filterValues = column?.getFilterValue();
    const { data: res } = useDescendantsSummary(assemblyId, viewContext);
    const colors: Record<PartCountEnum, { unselected: string; selected: { border: string; background: string } }> = {
        [PartCountEnum.DNP]: {
            unselected: colorSystem.neutral[4],
            selected: { border: colorSystem.neutral[6], background: colorSystem.neutral[4] },
        },
        [PartCountEnum.Multiple]: {
            unselected: palette.default[2],
            selected: { border: palette.default[1], background: palette.default[3] },
        },
        [PartCountEnum.None]: {
            unselected: colorSystem.red[5],
            selected: { border: colorSystem.red[5], background: colorSystem.red[4] },
        },
        [PartCountEnum.Single]: {
            unselected: palette.default[1],
            selected: { border: palette.default[1], background: palette.default[2] },
        },
    };

    const priority: Record<PartCountEnum, number> = {
        [PartCountEnum.DNP]: 1,
        [PartCountEnum.None]: 2,
        [PartCountEnum.Single]: 3,
        [PartCountEnum.Multiple]: 4,
    };

    const data: Array<PartCountDatum> = (res?.part_options ?? []).sort(compareByNumber((x) => priority[x.status_type]));

    type PartCountDatum = { status_type: PartCountEnum; count: number };

    return (
        <AngularProgressBar
            title={t`BOM items`}
            getLabel={(item: PartCountDatum) => transEnum(item.status_type, partCountTranslationsShort)}
            getValue={(item: PartCountDatum) => item.count}
            getColor={(item: PartCountDatum) => colors[item.status_type]}
            getTooltip={(item: PartCountDatum) => {
                return partCountTooltipLabel[item.status_type];
            }}
            data={data}
            isSelected={(item: PartCountDatum) =>
                Array.isArray(filterValues) && filterValues.includes(item.status_type)
            }
            onBarClick={(item: PartCountDatum) => {
                trackAnalyticsEvents({ rfqId, assemblyId, field: 'part_options', fieldStatus: item.status_type });
                const isSelected = Array.isArray(filterValues) && filterValues.includes(item.status_type);
                if (isSelected) {
                    const newFilters = Array.from(filterValues).filter((status) => status !== item.status_type);
                    column?.setFilterValue(newFilters.length === 0 ? undefined : newFilters);
                } else {
                    scrollIntoView({ id: backToDashboardButtonId });
                    column?.setFilterValue([item.status_type]);
                }
            }}
        />
    );
}

function scrollIntoView({ id }: { id: string }) {
    const element = document.getElementById(id);
    if (element) {
        return element.scrollIntoView({ behavior: 'smooth' });
    }
}

const lifecycleTooltipLabel: Record<LifecycleEnum, { description: JSX.Element }> = {
    [LifecycleEnum.Obsolete]: {
        description: <Trans>The part options in the BOM item are no longer available.</Trans>,
    },
    [LifecycleEnum.EndOfLife]: {
        description: (
            <Trans>
                A last time buy (LTB) has been announced for a part option in the BOM item; the plan for this part is to
                become obsolete.
            </Trans>
        ),
    },
    [LifecycleEnum.NotRecommendedForNewDesigns]: {
        description: (
            <Trans>
                A part option in the BOM item is in active production, but is not recommended for new design-in. In most
                cases, a newer generation of parts has been released and is recommended.
            </Trans>
        ),
    },
    [LifecycleEnum.PreRelease]: {
        description: (
            <Trans>
                A part option in the BOM item has not yet been released. It is in process of qualification for release
                and the datasheet is subject to change.
            </Trans>
        ),
    },
    [LifecycleEnum.Active]: {
        description: (
            <Trans>A part option in the BOM item is in active production and is recommended for design-in.</Trans>
        ),
    },
    [LifecycleEnum.Unknown]: {
        description: (
            <Trans>
                The lifecycle information of the part options in the BOM item is not available or has not been
                determined.
            </Trans>
        ),
    },
    [LifecycleEnum.Aftermarket]: {
        description: <Trans>The part options in the BOM item are no longer available.</Trans>,
    },
    [LifecycleEnum.Acquired]: {
        description: (
            <Trans>
                A part option in the BOM item has been acquired by another company or organization. This may occur when
                one company purchases another company and takes over its products and operations, or when a product line
                is discontinued by its original manufacturer and is taken over by another company.
            </Trans>
        ),
    },
};

const availabilityTooltipLabel: Record<AvailabilityStatusEnum, { description: JSX.Element }> = {
    [AvailabilityStatusEnum.OutOfStock]: {
        description: (
            <Trans>
                No offer from your preferred and approved suppliers has enough stock to satisfy any of your order sizes.
            </Trans>
        ),
    },
    [AvailabilityStatusEnum.InsufficientStock]: {
        description: (
            <Trans>
                The offer from your preferred and approved suppliers with the highest stock, has enough stock for at
                least one order size, but not enough for the highest order size.
            </Trans>
        ),
    },
    [AvailabilityStatusEnum.InStock]: {
        description: (
            <Trans>
                There is one offer of your preferred and approved suppliers which has enough stock to satisfy your
                highest order size.
            </Trans>
        ),
    },
    [AvailabilityStatusEnum.InventoryOfEMS]: {
        description: (
            <Trans>There is enough stock in your uploaded Inventory to satisfy your highest order size.</Trans>
        ),
    },
    [AvailabilityStatusEnum.ConsignedByOEM]: {
        description: <Trans>The BOM Item will be supplied by the customer.</Trans>,
    },
};

const complianceTooltipLabel = ({
    status,
    field,
}: {
    status: ComplianceStatus;
    field: ComplianceLabel;
}): { title: JSX.Element; description: JSX.Element } => {
    const label: Record<ComplianceStatus, { title: JSX.Element; description: JSX.Element }> = {
        [ComplianceStatus.NonCompliant]: {
            title: <Trans>{field} non-compliant</Trans>,
            description: <Trans>The BOM item does not comply with the {field} directive.</Trans>,
        },
        [ComplianceStatus.Unknown]: {
            title: <Trans>{field} unknown</Trans>,
            description: (
                <Trans>
                    The information about the presence of chemicals in the BOM item and the product's compliance with{' '}
                    {field} requirements is not available or has not been determined.
                </Trans>
            ),
        },
        [ComplianceStatus.Compliant]: {
            title: <Trans>{field} compliant</Trans>,
            description: <Trans>The BOM item does comply with the {field} directive.</Trans>,
        },
        [ComplianceStatus.CompliantWithExemption]: {
            title: <Trans>{field} with exemption</Trans>,
            description: (
                <Trans>
                    The BOM item is exempt from {field} restriction since its technically or scientifically
                    impracticable to prohibit the use of certain hazardous substances at present.
                </Trans>
            ),
        },
        [ComplianceStatus.NotRequired]: {
            title: <Trans>{field} not required</Trans>,
            description: (
                <Trans>
                    The BOM item falls outside the scope of the directive and is not subject to its requirements. This
                    could be because the product is not considered an electrical or electronic equipment, or because it
                    falls under one of the exemptions specified in the directive.
                </Trans>
            ),
        },
    };
    return label[status];
};

const partCountTooltipLabel: Record<PartCountEnum, { title: JSX.Element; description: JSX.Element }> = {
    [PartCountEnum.Multiple]: {
        title: <Trans>Multiple part options</Trans>,
        description: <Trans>BOM items with more than one approved part option.</Trans>,
    },
    [PartCountEnum.Single]: {
        title: <Trans>Single part options</Trans>,
        description: <Trans>BOM items with one approved part option.</Trans>,
    },
    [PartCountEnum.None]: {
        title: <Trans>No part options</Trans>,
        description: <Trans>BOM items with no approved part option.</Trans>,
    },
    [PartCountEnum.DNP]: {
        title: <Trans>DNP</Trans>,
        description: <Trans>BOM items that will not be assembled.</Trans>,
    },
};
