import { isPresent, transEnum } from '@luminovo/commons';
import { FieldController, FieldRadio, FieldRadioProps, Flexbox, FormItem, Text } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import { FormLabel, RadioGroup } from '@mui/material';
import { Control, FieldValues, Path, useWatch } from 'react-hook-form';
import { assemblyTypePublicTranslations } from '../../../resources/rfq/i18n';
import { useAssemblyTypeOptions } from '../utils/useAssemblyTypeOptions';

type FieldAssemblyTypeRadioProps = FieldRadioProps<AssemblyTypeEnum>;

const FieldAssemblyTypeRadio = (
    props: FieldAssemblyTypeRadioProps & { options: AssemblyTypeEnum[] },
): JSX.Element | null => {
    const { data: options, isLoading } = useAssemblyTypeOptions();
    if (isLoading || !isPresent(options)) {
        return null;
    }

    return (
        <RadioGroup>
            <Flexbox flexDirection={'column'} gap={16}>
                {options.map((assemblyType) => (
                    <FormLabel key={assemblyType}>
                        <Flexbox alignItems={'center'} gap={8}>
                            <FieldRadio {...props} fieldValue={assemblyType} checked={props.value === assemblyType} />
                            <Text>{transEnum(assemblyType, assemblyTypePublicTranslations)}</Text>
                        </Flexbox>
                    </FormLabel>
                ))}
            </Flexbox>
        </RadioGroup>
    );
};

export const FormItemAssemblyTypeRadio = <T extends FieldValues>({
    name,
    control,
    label,
    id,
    required,
}: {
    name: Path<T>;
    control: Control<T>;
    label: string;
    id?: string;
    required?: boolean;
}) => {
    const { data: options, isLoading } = useAssemblyTypeOptions();
    const assemblyType = useWatch({ control, name });

    if (isLoading || !isPresent(options)) {
        return null;
    }

    // If there is only one assembly type and we can affirm that the value is selected, we don't need to show the radio buttons
    if (options.length === 1 && isPresent(assemblyType)) {
        return null;
    }

    return (
        <FormItem label={label} id={id} required={required}>
            <FieldController control={control} name={name} Field={FieldAssemblyTypeRadio} />
        </FormItem>
    );
};
