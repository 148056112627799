import { throwErrorUnlessProduction } from './customConsole';

export function assertStringIsNotEmpty<T>(x: T | ''): T {
    if (x === '') {
        throwErrorUnlessProduction(new Error(`Expected value to not be an empty string but got ${x}`));
    }
    // This case is safe as this code is only reachable if x isPresent
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return x as T;
}
