import * as r from 'runtypes';
import { UserDTORuntype, UserInviteDTORuntype, UserInviteInsertDTORuntype } from '../user';

export const InviteExternalUserRuntype = UserInviteInsertDTORuntype;

export type ContributorsDTO = r.Static<typeof ContributorsRuntype>;
export const ContributorsRuntype = r.Record({
    external: r.Array(UserDTORuntype),
    internal: r.Array(UserDTORuntype),
    invited: r.Array(UserInviteDTORuntype),
});
