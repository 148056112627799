import { ObjectResponse } from '@luminovo/http-client';
import { AxiosResponse } from 'axios';
import * as r from 'runtypes';

export enum Status {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

export type UseQueryResponseObject<ResponseType> = AxiosResponse<ObjectResponse<ResponseType>>;

export interface BreadcrumbIdentifier extends r.Static<typeof BreadcrumbIdentifierRuntype> {}

const BreadcrumbIdentifierRuntype = r.Record({
    designator: r.String,
    id: r.String.optional(),
    isAssembly: r.Boolean,
});

export interface BomItemBreadcrumbAggregation extends r.Static<typeof BomItemBreadcrumbAggregationRuntype> {}
const BomItemBreadcrumbAggregationRuntype = r.Record({
    breadcrumb: r.Array(BreadcrumbIdentifierRuntype),
    designators: r.Array(r.String),
    ids: r.Array(r.String),
});
