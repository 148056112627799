import { compareByString } from '@luminovo/commons';
import { Flexbox, StickyLayout } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { Divider } from '@mui/material';
import React from 'react';
import { ManufacturingDatabaseSidebar } from '../../../components/ManufacturingDatabase/ManufacturingDatabaseSidebar';
import { PageLayoutCollapsibleSidebar } from '../../../components/PageLayoutCollapsibleSidebar';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { UrlParams } from '../../../utils/routes';
import { AddManufacturingScenarioTemplateDialog } from '../ManufacturingScenarioTemplateCreation';
import ManufacturingScenarioTemplateView from './ManufacturingScenarioTemplateView';
import ManufacturingScenarioTemplatesColumn from './ManufacturingScenarioTemplatesColumn';

export const ManufacturingScenarioTemplateManagementPage = (
    props: UrlParams<'/manufacturing/template/:templateId'> | UrlParams<'/manufacturing/template'>,
) => {
    const templateIdFromUrl = 'templateId' in props.pathParams ? props.pathParams.templateId : undefined;
    const { data: templates = [] } = useHttpQuery(
        'GET /manufacturing-scenario-templates',
        { queryParams: {} },
        {
            select: (res) => res.data.sort((template1, template2) => compareByString(template1.name, template2.name)),
        },
    );

    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    const selectedTemplate: ManufacturingScenarioTemplateDTO | undefined =
        templates.find((c) => c.id === templateIdFromUrl) ?? templates[0];

    return (
        <PageLayoutCollapsibleSidebar sidebar={<ManufacturingDatabaseSidebar />}>
            <Flexbox gap={'12px'}>
                <StickyLayout style={{ paddingTop: '16px' }}>
                    <ManufacturingScenarioTemplatesColumn
                        templates={templates}
                        handleOnAddClick={() => setIsDialogOpen(true)}
                        selectedTemplateId={selectedTemplate?.id}
                    />
                </StickyLayout>
                <Divider orientation="vertical" style={{ height: '100%' }} />

                <Flexbox paddingBottom={'72px'} paddingRight={'38px'} height={'fit-content'}>
                    {selectedTemplate && <ManufacturingScenarioTemplateView template={selectedTemplate} />}
                </Flexbox>

                <AddManufacturingScenarioTemplateDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
};
