import { Flexbox, SecondaryIconButton, Text, colorSystem } from '@luminovo/design-system';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import { DeleteManufacturingScenarioTemplateInstanceButton } from './DeleteManufacturingScenarioTemplateInstanceButton';
import { TemplateInstanceUpDownButtons } from './ManufacturinScenarioTempalteInstanceUpDownButton';
import { TemplateInstanceRowHeader, TemplateInstanceState } from './types';

export const ManufacturingScenarioTemplateInstanceRow = ({
    data,
    context,
    AppendButton,
}: {
    data: TemplateInstanceRowHeader;
    context: TemplateInstanceState;
    AppendButton: JSX.Element;
}): JSX.Element => {
    const isCollapsed = context.isCollapsed(data.templateInstanceId);
    const templateInstanceId = typeof data.templateInstanceId === 'string' ? data.templateInstanceId : undefined;

    return (
        <TableCell colSpan={100} sx={{ background: colorSystem.neutral[1] }}>
            <Flexbox justifyContent="space-between" alignItems="center" height="100%" gap="32px">
                {templateInstanceId && data.rank !== 'directly-added' && (
                    <TemplateInstanceUpDownButtons
                        templateInstanceId={templateInstanceId}
                        rank={data.rank}
                        position={data.position}
                    />
                )}
                <Text variant="h4" style={{ flexGrow: 1 }}>
                    {data.index + 1}
                    {'. '}
                    {data.templateName}
                </Text>
                <Flexbox gap="32px" alignItems="center" paddingRight="99px">
                    <Flexbox gap="8px">
                        {AppendButton}
                        {templateInstanceId && (
                            <DeleteManufacturingScenarioTemplateInstanceButton
                                templateInstanceId={templateInstanceId}
                            />
                        )}
                        <SecondaryIconButton
                            size="medium"
                            onClick={() => context.setCollapsed(data.templateInstanceId, !isCollapsed)}
                        >
                            {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                        </SecondaryIconButton>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </TableCell>
    );
};

export const renderEmptyHeaderRow = (): JSX.Element => <></>;
