import { Currency, typeSafeObjectKeys } from '@luminovo/commons';
import { AttributeExtractionRule } from '../types';

function getCurrencySymbol(locale: string, currency: string): string {
    return (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
}

function buildCurrencyTable(): Record<string, Currency> {
    const table: Record<string, Currency> = {};
    const locales = ['en-US', 'de-DE', 'fr-FR', 'es-ES', 'it-IT', 'ja-JP', 'ko-KR', 'zh-CN', 'zh-TW', 'ru-RU', 'hi-IN'];
    const currencies = typeSafeObjectKeys(Currency);
    for (const locale of locales) {
        for (const currency of currencies) {
            const symbol = getCurrencySymbol(locale, currency);
            if (symbol) {
                table[symbol] = Currency[currency];
            }
            table[currency] = Currency[currency];
        }
    }
    return table;
}

const currencyTable = buildCurrencyTable();

export const ruleExtractCurrency: AttributeExtractionRule<{ content: string }> = ({ content }) => {
    const match = currencyTable[content];
    if (match) {
        return [
            {
                attr: 'currency',
                value: match,
            },
        ];
    }
    return [];
};
