import { t } from '@lingui/macro';
import { Validate } from './types';

export function validateQuantityUnit({ value }: { value: number | string | null | undefined }): Validate {
    if (!value) {
        return { isError: true, message: t`Required` };
    }

    return { isError: false, message: '' };
}
