import { t, Trans } from '@lingui/macro';
import { formatDecimal, transEnum } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { rfqStatusTranslations } from '@luminovo/sourcing-core';
import { HorizontalStackedBarChart } from '@luminovo/viz';
import { ChartSpec } from './ChartRfq';

type Datum = {
    ids: string[];
    label: string;
    count: number;
    status: RfqStatus;
};

type Keys = Exclude<keyof Datum, 'label' | 'ids' | 'status'>;
const keys: Keys[] = ['count'];

const comparator = chainComparators<Datum>(
    (a, b) => Object.values(RfqStatus).indexOf(a.status) - Object.values(RfqStatus).indexOf(b.status),
    compareByNumber((d) => -(d.count ?? 0)),
);

export const chartSpecStatus: ChartSpec<Keys, Datum> = {
    id: 'rfqsByStatus',
    keys,
    title: <Trans>RfQs by</Trans>,
    orderBy: comparator,
    aggregate(a, b) {
        return {
            ids: a.ids.concat(b.ids),
            label: a.label,
            count: a.count + b.count,
            status: a.status,
        };
    },
    map(data) {
        return {
            ids: [data.id],
            label: transEnum(data.status, rfqStatusTranslations),
            count: 1,
            status: data.status,
        };
    },
    groupBy: [
        {
            extractor: (data) => data.status,
            label: <Trans>Status</Trans>,
        },
    ],
    render: Chart,
};

function Chart({ data, keys, onSelectDatum }: { data: Datum[]; keys: Keys[]; onSelectDatum(datum: Datum): void }) {
    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            formatKey={(x) => t`Count`}
            formatValue={(x) => formatDecimal(x)}
            onBarClick={onSelectDatum}
            width={800}
        />
    );
}
