import { formatDecimal } from '@luminovo/commons';
import { colorSystem, Flexbox, NOTO_SANS, Text, Tooltip } from '@luminovo/design-system';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/material';
import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';

import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';

import React from 'react';
import { palette } from '../../color/palette';
import { BarChartAxisTop } from '../HorizontalStackedBarChart/components/BarChartAxisTop';
import { BarContainers } from '../HorizontalStackedBarChart/components/StackedBarContainer';

type DiffDatum = {
    label: string;
    totalDifference: number;
    selected?: boolean;
    error?: string;
};

export type DiffChartProps = {
    width: number;
    title?: string;
    data: Array<DiffDatum>;
    formatValue?: (value: number) => string;
    onBarClick?: (data: DiffDatum) => void;
};

function coerceNonNumberTo0(x: number | undefined): number {
    if (x === undefined) {
        return 0;
    }
    if (isNaN(x) || !isFinite(x)) {
        return 0;
    }
    return x;
}
const noop = () => {};

/**
 * IMPORTANT: This component is still a work-in-progress. Please test carefully before using it in production.
 */
export function DiffChart({
    width,
    data,
    formatValue = formatDecimal,
    onBarClick = noop,
}: DiffChartProps): JSX.Element {
    data = [...data].sort((a, b) => (b.totalDifference ?? 0) - (a.totalDifference ?? 0));

    const barHeight = 24;
    const height = data.length * barHeight;
    // bounds
    const xMax = width;
    const yMax = height;

    // scales
    const y0Scale = scaleBand({
        domain: data.map((d) => d.label),
        range: [0, yMax],
        padding: 0,
    });

    const labelToError = new Map<string, string>();
    data.forEach((datum) => {
        if (datum.error) {
            labelToError.set(datum.label, datum.error);
        }
    });

    const domainMax = Math.max(
        ...data.map((d) => {
            return Math.abs(coerceNonNumberTo0(d.totalDifference));
        }),
    );

    const domainValue =
        domainMax === 0
            ? // If all values are 0, show the axis from -1 to +1

              1.23
            : // If there are values, show the axis from -1.5 * max to +1.5 * max
              // This way, the labels on the left Y axis don't overlap with the bars
              domainMax * 1.5;
    const xScale = scaleLinear<number>({
        domain: [-domainValue, 0, domainValue],
        range: [0, xMax / 2, xMax],
    });

    return (
        <Flexbox flexDirection={'column'} gap="0px" position={'relative'}>
            <svg
                key="x-axis"
                width={width}
                height={40}
                style={{ overflow: 'visible', position: 'sticky', top: 0, zIndex: 1 }}
            >
                <rect x={-8} width={width + 16} height={40} fill={colorSystem.neutral[1]} />
                <Group top={40} left={0}>
                    <BarChartAxisTop data={data} xScale={xScale} formatValue={formatValue} />
                </Group>
            </svg>
            <svg key="chart" width={width} height={height} style={{ overflow: 'visible' }}>
                <Group top={0} left={0}>
                    <GridColumns
                        scale={xScale}
                        width={width}
                        height={height}
                        stroke={palette.gridLines}
                        strokeDasharray="4 1 2"
                    />
                    <BarContainers<'totalDifference', DiffDatum>
                        barHeight={barHeight}
                        data={data}
                        onBarClick={onBarClick}
                        xScale={xScale}
                        yScale={y0Scale}
                    />
                    {data.map((datum, groupIndex) => {
                        const value = datum.totalDifference ?? 0;
                        const abs = Math.abs(value ?? 0);

                        const color = value < 0 ? palette.ok.high : palette.error.high;

                        return (
                            <React.Fragment key={`${groupIndex}`}>
                                <Tooltip
                                    arrow
                                    placement={value > 0 ? 'left' : 'right'}
                                    title={
                                        <Text color={colorSystem.neutral.white}>
                                            {formatValue(datum.totalDifference)}
                                        </Text>
                                    }
                                >
                                    <g>
                                        <StyledBar
                                            width={xScale(abs) - xMax / 2}
                                            x={value > 0 ? xMax / 2 : xScale(-abs)}
                                            fill={color}
                                            height={barHeight}
                                            y={y0Scale(datum.label) ?? 0}
                                            onClick={() => onBarClick(datum)}
                                        />
                                    </g>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}

                    <AxisLeft
                        scale={y0Scale}
                        stroke={palette.gridLines}
                        tickStroke={palette.gridLines}
                        hideTicks={false}
                        numTicks={data.length}
                        tickComponent={({ formattedValue, values, ...tickProps }) => {
                            const datum = data.find((d) => d.label === formattedValue);
                            return (
                                <Group left={tickProps.x} top={tickProps.y}>
                                    <foreignObject
                                        style={{ overflow: 'visible', pointerEvents: 'none' }}
                                        x={14}
                                        y={-10}
                                        width={200}
                                        height={24}
                                    >
                                        <Tooltip
                                            placement="right"
                                            title={
                                                <>
                                                    {formattedValue?.toString() ?? ''}
                                                    {datum?.error ? ': ' + datum.error : ''}
                                                </>
                                            }
                                        >
                                            <span
                                                style={{
                                                    pointerEvents: 'all',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => {
                                                    if (datum) {
                                                        e.stopPropagation();
                                                        onBarClick(datum);
                                                    }
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        display: 'inline-block',
                                                        maxWidth: '120px',
                                                        pointerEvents: 'all',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {formattedValue}
                                                </Text>
                                                {datum?.error ? (
                                                    <Info
                                                        style={{ color: colorSystem.yellow[5] }}
                                                        fontSize={'inherit'}
                                                    />
                                                ) : null}
                                            </span>
                                        </Tooltip>
                                    </foreignObject>
                                </Group>
                            );
                        }}
                        tickLabelProps={(label) => ({
                            fill: colorSystem.neutral[8],
                            fontSize: 14,
                            fontFamily: NOTO_SANS,
                            textAnchor: 'start',
                            verticalAnchor: 'middle',
                            dx: 12,
                            cursor: 'pointer',
                            width: 340,
                            onClick: (e) => {
                                const datum = data.find((d) => d.label === label);
                                if (datum) {
                                    e.stopPropagation();
                                    onBarClick(datum);
                                }
                            },
                        })}
                        tickTransform="translate(8,0)"
                    />
                </Group>
            </svg>
        </Flexbox>
    );
}

const StyledBar = styled(Bar)({
    cursor: 'pointer',
    transition: 'all 0.2s linear',
});
