import { Currency } from '@luminovo/commons';
import {
    CalculationTemplateResponseDTO,
    CostCellTemplateDTO,
    ExtraCostTemplateDTO,
    UnitCostTemplateDTO,
} from '@luminovo/http-client';
import { CalculationTemplateFormInput, CostCell, ExtraCost, UnitCost } from './types';

const convertCostType = (unitCost: UnitCostTemplateDTO): UnitCost => {
    if (unitCost.type === 'Fraction') {
        return {
            type: 'percentage',
            value: {
                percentage: unitCost.data,
            },
        };
    } else if (unitCost.type === 'Fixed') {
        return {
            type: 'fixed',
            value: {
                cost: unitCost.data,
            },
        };
    } else {
        return {
            type: 'formula',
            value: {
                type: unitCost.data.formula.type === 'Fraction' ? 'percentage' : 'fixed',
                value: unitCost.data.formula.data.script,
            },
        };
    }
};

const convertCostCellDTOtoCostCell = (costCell: CostCellTemplateDTO): CostCell => {
    return {
        value: {
            unitCost: convertCostType(costCell.unit_cost),
        },
        isLocked: costCell.is_locked,
    };
};

const convertExtraCostArray = (extraCosts: ExtraCostTemplateDTO[]): ExtraCost[] => {
    return extraCosts.map((extraCost) => ({
        name: extraCost.name,
        unitCost: convertCostType(extraCost.cost.unit_cost),
        isLocked: extraCost.cost.is_locked,
    }));
};

const convertNullableCostCellDTO = (value: CostCellTemplateDTO | null): CostCell | undefined => {
    if (value === null) return undefined;
    return convertCostCellDTOtoCostCell(value);
};

export const convertCalculationTemplatePostToTemplateForm = (
    data: CalculationTemplateResponseDTO,
    preferredCurrency: Currency,
): CalculationTemplateFormInput => {
    return {
        name: data.name,
        includeExcessMaterialInMaterialCost: data.include_excess_material_in_material_cost,
        additionalCosts: {
            otherCosts: convertExtraCostArray(data.additional_cost.other_costs),
            postProfitCosts: convertExtraCostArray(data.additional_cost.post_profit_costs),
            discount: convertNullableCostCellDTO(data.additional_cost.discount),
            profit: convertNullableCostCellDTO(data.additional_cost.profit),
        },
        manufacturingCost: {
            extraCosts: convertExtraCostArray(data.manufacturing_cost.extra_costs),
            discount: convertNullableCostCellDTO(data.manufacturing_cost.discount),
            profit: convertNullableCostCellDTO(data.manufacturing_cost.profit),
        },
        materialCost: {
            extraCosts: convertExtraCostArray(data.material_cost.extra_costs),
            discount: convertNullableCostCellDTO(data.material_cost.discount),
            profit: convertNullableCostCellDTO(data.material_cost.profit),
        },
        projectCost: {
            profit: data.project_cost ? convertNullableCostCellDTO(data.project_cost.profit) : undefined,
        },
        //TODO: assumes that all costs have the same currency. Would be better to assert this.
        currency: preferredCurrency,
        /* The variable is named here to match the text on the template form, and to add rather than subtract
        an element when the form checkbox is checked. */
        isSeparateProjectCostSelected: !data.include_project_cost_in_manufacturing_cost,
    };
};
