import { CustomPartTypeEnum } from '@luminovo/http-client';
import { FileWithUrlAndName } from '../../../../../resources/assembly/assemblyFrontendTypes';

export type AddCustomPartAction =
    | { type: 'setFilesToUpload'; uploadFiles: FileWithUrlAndName[] }
    | { type: 'removeFile'; uploadFiles: FileWithUrlAndName }
    | { type: 'setType'; partType: CustomPartTypeEnum | null }
    | { type: 'setDescription'; description: string | null }
    | { type: 'reset' };

export interface AddCustomPartState {
    partType: CustomPartTypeEnum | null;
    uploadFiles: FileWithUrlAndName[];
    description: string | null;
}

export const initialState = {
    uploadFiles: [],
    partType: CustomPartTypeEnum.Other,
    description: '',
};

export const reducer = (state: AddCustomPartState, action: AddCustomPartAction): AddCustomPartState => {
    switch (action.type) {
        case 'setFilesToUpload':
            return { ...state, uploadFiles: [...state.uploadFiles, ...action.uploadFiles] };
        case 'removeFile':
            return {
                ...state,
                uploadFiles: state.uploadFiles.filter(
                    (file) => file.file !== action.uploadFiles.file && file.name !== action.uploadFiles.name,
                ),
            };
        case 'setType':
            return { ...state, partType: action.partType };
        case 'setDescription':
            return { ...state, description: action.description ?? '' };
        case 'reset':
            return { ...state, description: '', uploadFiles: [] };
        default:
            return state;
    }
};
