import { formatMonetaryValue, isPresent, MonetaryValue } from '@luminovo/commons';
import { Flexbox, Text } from '@luminovo/design-system';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { extractAwaredOfferFromScenario } from '../model/extractors/extractorNegotionsLineItem';
import { monetaryValue } from '../model/monetaryValueMath';
import { QuoteRequestForm } from '../model/quoteRequestForm';
import { LabelPercentageChange } from './LabelPercentageChange';

export function QuoteRequestDetailsPropertyTable({
    quoteRequestLineItems,
}: {
    quoteRequestLineItems?: QuoteRequestLineItemDTO[];
}) {
    const { referenceScenario } = useNegotiationsContext();
    const { control } = useFormContext<QuoteRequestForm>();
    const showTargetPrice = useWatch({
        control,
        name: 'showTargetPrice',
        defaultValue: false,
    });

    // Calculate the reference volume
    const referenceVolume = useMemo(() => {
        if (!isPresent(referenceScenario) || !isPresent(quoteRequestLineItems)) {
            return null;
        }

        const referenceVolumes = quoteRequestLineItems.map((lineItem) => {
            const referenceUnitPrice =
                extractAwaredOfferFromScenario(lineItem.negotiation_line_item_id, referenceScenario)?.awarded_solution
                    .unit_price ?? monetaryValue.zero;
            return monetaryValue.scale(referenceUnitPrice, lineItem.required_quantity.quantity);
        });

        const totalReferenceVolume = monetaryValue.sum(referenceVolumes);
        if (totalReferenceVolume.amount === '0') {
            return null;
        }
        return totalReferenceVolume;
    }, [quoteRequestLineItems, referenceScenario]);

    // Calculate the target volume
    const targetVolume = useMemo(() => {
        // If we don't show the target price then don't show the target volume
        if (!isPresent(quoteRequestLineItems) || !showTargetPrice) {
            return null;
        }

        const targetVolumes = quoteRequestLineItems.map((lineItem) => {
            const targetPrice = lineItem.target_price ?? monetaryValue.zero;
            return monetaryValue.scale(targetPrice, lineItem.required_quantity.quantity);
        });

        const totalTargetVolume = monetaryValue.sum(targetVolumes);
        if (totalTargetVolume.amount === '0') {
            return null;
        }
        return totalTargetVolume;
    }, [quoteRequestLineItems, showTargetPrice]);

    // Calculate the quoted volume
    const quotedVolume = useMemo(() => {
        if (!isPresent(quoteRequestLineItems)) {
            return null;
        }

        const quotedUnitPrices = quoteRequestLineItems.map((lineItem) => {
            const quotedUnitPrice = lineItem.received_offer?.unit_price ?? monetaryValue.zero;
            return monetaryValue.scale(quotedUnitPrice, lineItem.required_quantity.quantity);
        });

        const totalQuotedVolume = monetaryValue.sum(quotedUnitPrices);
        if (totalQuotedVolume.amount === '0') {
            return null;
        }
        return totalQuotedVolume;
    }, [quoteRequestLineItems]);

    // Calculate the volume difference
    const volumeDifference = useMemo(() => {
        if (!isPresent(targetVolume) || !isPresent(quotedVolume)) {
            return null;
        }
        return monetaryValue.relativeDifference(quotedVolume, targetVolume);
    }, [targetVolume, quotedVolume]);

    return (
        <Box display="grid" alignItems="center" columnGap={8} gridTemplateColumns="1fr 1fr" rowGap={4}>
            <QuoteRequestDetailsPropertyItem title={`Reference volume`} value={referenceVolume} />
            <QuoteRequestDetailsPropertyItem title={`Target volume`} value={targetVolume} />
            <QuoteRequestDetailsPropertyItem
                title={`Quoted Volume`}
                value={quotedVolume}
                difference={volumeDifference}
            />
        </Box>
    );
}

function QuoteRequestDetailsPropertyItem({
    title,
    value,
    difference,
}: {
    title: string;
    value: MonetaryValue | null;
    difference?: number | null;
}) {
    return (
        <Flexbox flexDirection="column" rowGap={0.5} justifyContent="space-between">
            <Text variant="body-small">{title}</Text>
            <Flexbox alignItems="center" columnGap={1}>
                <Text variant="h4">{formatMonetaryValue(value, 'estimate', { ifAbsent: '-' })}</Text>
                {isPresent(difference) && (
                    <LabelPercentageChange
                        percent={difference}
                        formatPercentageOptions={{
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }}
                    />
                )}
            </Flexbox>
        </Flexbox>
    );
}
