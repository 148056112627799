import { isPresent } from '@luminovo/commons';
import { usePersistedState } from '@luminovo/design-system';
import { AwardScenarioDTO } from '@luminovo/http-client';
import * as React from 'react';
import { useAwardScenarios } from './negotiationHandlers';

export function useSelectedAwardScenario(
    negotiationId: number | undefined,
    type: 'referenceScenarioId' | 'selectedScenarioId',
): [AwardScenarioDTO | null, (value: Pick<AwardScenarioDTO, 'id'> | null) => void] {
    const { data: awardScenarios = [] } = useAwardScenarios(negotiationId);

    const [id, setId] = usePersistedState<string | undefined>(
        `useSelectedAwardScenario::${negotiationId}::${type}`,
        undefined,
    );

    const defaultScenario =
        type === 'referenceScenarioId'
            ? awardScenarios.find((a) => a.kind.tag === 'LastPurchasePrice')
            : awardScenarios.find((a) => a.kind.tag === 'BestPriceAcrossAllQuotes');

    const idOrDefault = isPresent(id) ? Number(id) : defaultScenario?.id;

    const value = awardScenarios.find((awardScenario) => awardScenario.id === idOrDefault) ?? null;

    const setValue = React.useCallback(
        (value: Pick<AwardScenarioDTO, 'id'> | null) => {
            setId(value?.id?.toString() ?? undefined);
        },
        [setId],
    );

    return [value, setValue];
}
