import React from 'react';
import { Argument, Operator, PartialBlock } from '../types';
import { formatOperator } from '../util/formatOperator';
import { InteractiveMenu } from './InteractiveMenu';

export function MenuOperators<T, TAutocompleteState>({
    partialFilter,
    query,
    onSelect,
    onSearch,
    inputRef,
}: {
    partialFilter: PartialBlock<T, TAutocompleteState>;
    query: string;
    onSelect: (operator: Operator<T, TAutocompleteState, Argument>) => void;
    onSearch: (query: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
}): JSX.Element {
    return (
        <InteractiveMenu
            showIcons={false}
            inputRef={inputRef}
            title={partialFilter.parameter.label ?? partialFilter.parameter.field}
            query={query}
            items={partialFilter.parameter.ops.map((op) => {
                return {
                    id: op.op,
                    label: op.label ? op.label : formatOperator(op.op),
                    value: op,
                };
            })}
            onSelect={onSelect}
            onSearch={onSearch}
        />
    );
}
