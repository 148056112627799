import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { CustomerBillingAddressRuntype, CustomerDTOPostRuntype, CustomerDTORuntype } from './customerBackendTypes';

export const customerEndpoints = {
    'GET /customers': endpoint({
        description: 'Returns all customer organizations of the tenant EMS organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(CustomerDTORuntype),
        }),
    }),

    'POST /customers': endpoint({
        description: 'Create a new customer for the tenant EMS organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CustomerDTOPostRuntype,
        responseBody: r.Record({
            data: CustomerDTORuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId'],
    }),

    'GET /customers/:customerId': endpoint({
        description: 'Fetch a particular customer',
        pathParams: r.Record({ customerId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: CustomerDTORuntype,
        }),
    }),

    'PATCH /customers/:customerId': endpoint({
        description: 'Update a customer as an EMS',
        pathParams: r.Record({ customerId: r.String }),
        queryParams: r.Undefined,
        requestBody: CustomerDTOPostRuntype,
        responseBody: r.Record({
            data: CustomerDTORuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId'],
    }),

    'PATCH /customers/:customerId/billing-address': endpoint({
        description: 'Update a customer billing address',
        pathParams: r.Record({ customerId: r.String }),
        queryParams: r.Undefined,
        requestBody: CustomerBillingAddressRuntype,
        responseBody: r.Record({
            data: CustomerBillingAddressRuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId', 'GET /my/details'],
    }),

    'DELETE /customers/:customerId': endpoint({
        description: 'Delete a customer as an EMS',
        pathParams: r.Record({ customerId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: [],
        removes: ['GET /customers/:customerId', 'GET /customers'],
    }),
};
