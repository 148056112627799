import { t } from '@lingui/macro';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

const parser = parse.boolean();

export const parseBoolean: Parser<boolean, Opts> = async function (cells, _, field): Promise<ParseResult<boolean>> {
    const alternatives = [
        { id: true, label: t`Yes` },
        { id: false, label: t`No` },
    ];

    return applyParser({
        cells,
        parser,
        field,
        alternatives,
        formatValue,
    });
};

const formatValue = (value: boolean) => (value ? t`Yes` : t`No`);
