import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, Message, Tag, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { PreferredIpnTag, TimeStamp } from '@luminovo/sourcing-core';
import { Launch, ListRounded, Numbers } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { CustomPartCard } from '../../../components/partSpecificationCards/CustomPart/CustomPartCard';
import { CpnsTable } from '../../../components/partSpecificationCards/Ipn/CpnsTable';
import { useCustomComponent } from '../../../resources/part/partHandler';
import { useHasCustomers } from '../../../utils/featureFlags';
import { route } from '../../../utils/routes';

const DetailsSection: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const LabelWithText = ({ label, text }: { label: string; text: string }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} gap={24}>
            <Text variant={'body-small-semibold'} color={colorSystem.neutral[7]}>
                {label}
            </Text>
            <Text variant={'body-small'} style={{ textAlign: 'right' }}>
                {text}
            </Text>
        </Flexbox>
    );
};

const OpenCustomComponentPageButton: React.FunctionComponent<{
    ipnId: string;
}> = ({ ipnId }) => {
    return (
        <Tooltip title={t`Open custom component details page`} placement="top" arrow>
            <TertiaryButton
                size="small"
                startIcon={<Launch />}
                onClick={() =>
                    window.open(
                        route('/parts/components/ipn', {}, { ipnId, q: undefined }),
                        '_blank',
                        'noopener noreferrer',
                    )
                }
            >
                <Trans>Open custom component page</Trans>
            </TertiaryButton>
        </Tooltip>
    );
};

export const CustomComponentPanel = ({
    customComponentId,
    rfqContext,
    BackButton,
    showOpenCustomCompPageButton,
    showCpnsTable = true,
}: {
    customComponentId: string;
    rfqContext: RfqContext;
    BackButton: React.ComponentType;
    showOpenCustomCompPageButton: boolean;
    showCpnsTable?: boolean;
}): JSX.Element => {
    const { hasCustomers } = useHasCustomers();
    const { data: component } = useCustomComponent(customComponentId, rfqContext, {
        refetchOnWindowFocus: true,
    });

    if (!isPresent(component)) {
        return (
            <Flexbox height="80vh" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Flexbox>
        );
    }

    const {
        cpns,
        description,
        matches,
        id,
        is_restricted_to_customers: isRestrictedToCustomers,
        is_preferred: isPreferred,
        last_imported_at: lastImported,
        updated_at: updatedAt,
    } = component;

    return (
        <Flexbox flexDirection="column" gap={24} style={{ backgroundColor: 'inherit' }}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <BackButton />
                <Flexbox gap={12}>
                    {showOpenCustomCompPageButton && <OpenCustomComponentPageButton ipnId={customComponentId} />}
                </Flexbox>
            </Flexbox>

            <Flexbox flexDirection="column" justifyContent="center" gap={16}>
                <Flexbox gap={4}>
                    <Text variant="h2" color={colorSystem.neutral[5]}>
                        IPN
                    </Text>
                    <Text variant="h2" color={colorSystem.neutral[8]}>
                        {id}
                    </Text>
                    {isPreferred && <PreferredIpnTag styles={{ height: '20px', alignSelf: 'center' }} />}
                </Flexbox>

                <Flexbox gap={8}>
                    <TimeStamp title={t`Last import`} time={lastImported} />
                    <TimeStamp title={t`Last change`} time={updatedAt} />
                </Flexbox>
            </Flexbox>

            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Specification</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <span>
                    <Chip color="neutral" label={t`Custom`} />
                </span>
            </DetailsSection>

            <DetailsSection
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        <ListRounded
                            fontSize="small"
                            style={{ color: component.matches.length > 0 ? colorSystem.green[5] : colorSystem.red[5] }}
                        />
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Linked parts</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'column'} gap={8}>
                    {matches.length === 0 && (
                        <Message size="small" attention="low" variant={'red'} title={t`No linked parts`} />
                    )}
                    {matches.map((part) => {
                        return <CustomPartCard key={part.id} partId={part.id} />;
                    })}
                </Flexbox>
            </DetailsSection>

            <DetailsSection
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        <ListRounded fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Synced ERP data</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'column'} gap={16}>
                    <Flexbox flexDirection="column" gap={8}>
                        <LabelWithText label={t`Description`} text={description ?? '-'} />
                    </Flexbox>
                </Flexbox>
            </DetailsSection>

            {cpns.length > 0 && showCpnsTable && (
                <DetailsSection
                    label={
                        <Flexbox gap={4} alignItems="center">
                            <Numbers fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                            <Text variant="h4" color={colorSystem.neutral[7]}>
                                {hasCustomers ? t`Customer part numbers` : t`Company part numbers`}
                            </Text>
                            <Flexbox flex={1} />
                            {isRestrictedToCustomers && <Tag color="violet" attention="low" label={'Restricted'} />}
                        </Flexbox>
                    }
                >
                    <CpnsTable cpns={cpns} isRestrictedToCustomers={isRestrictedToCustomers} />
                </DetailsSection>
            )}
        </Flexbox>
    );
};
