import React from 'react';

export function useHotKeyRequestFocus(ref: React.RefObject<HTMLElement>): void {
    React.useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'k') {
                ref.current?.focus();
            }
        };

        const event: keyof WindowEventMap = 'keyup';
        const options: AddEventListenerOptions = {};
        window.addEventListener(event, listener, options);

        return () => {
            window.removeEventListener(event, listener, options);
        };
    }, [ref]);
}
