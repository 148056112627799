import { getLocale } from '../i18n';
import { isPresent } from '../typingUtils';

const enLongDateFormatter = new Intl.DateTimeFormat('en-GB', { month: 'short', year: '2-digit', day: undefined });
const deLongDateFormatter = new Intl.DateTimeFormat('de', { month: 'short', year: '2-digit', day: undefined });
const esLongDateFormatter = new Intl.DateTimeFormat('es', { month: 'short', year: '2-digit', day: undefined });
const frLongDateFormatter = new Intl.DateTimeFormat('fr', { month: 'short', year: '2-digit', day: undefined });
function getMonthFormatter(): Intl.DateTimeFormat {
    switch (getLocale()) {
        case 'en':
            return enLongDateFormatter;
        case 'de':
            return deLongDateFormatter;
        case 'es':
            return esLongDateFormatter;
        case 'fr':
            return frLongDateFormatter;
    }
}

/**
 * Examples:
 * - en: May 21
 * - de: Mai 21
 */
export const formatToMonth = (date: string | Date | undefined | null, options?: { ifAbsent?: string }): string => {
    if (!isPresent(date) || date === '') {
        return options?.ifAbsent ?? '-';
    }

    return getMonthFormatter().format(new Date(date));
};
