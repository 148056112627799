import { t } from '@lingui/macro';
import { compareByString, isPresent } from '@luminovo/commons';
import { Flexbox, Message, Text, colorSystem } from '@luminovo/design-system';
import { LoadingOffersProgressDTO } from '@luminovo/http-client';
import React from 'react';

function formatApis(data: LoadingOffersProgressDTO) {
    const apis = data.apis
        .filter((api) => api.count > 0)
        .map((api) => api.name)
        .sort(compareByString);

    if (apis.length === 0) {
        return '';
    }
    if (apis.length === 1) {
        return apis[0];
    }
    if (apis.length === 2) {
        return apis.join(` ${t`and`} `);
    }
    return `${apis.slice(0, -1).join(', ')} ${t`and`} ${apis.slice(-1)}`;
}

export function getRemainingOfferCount(data: LoadingOffersProgressDTO, partIds: string[]) {
    const otsPartCount = data.parts.filter((p) => partIds.includes(p.id)).reduce((sum, p) => sum + p.count, 0);
    const customPartCount = partIds.reduce((sum, id) => {
        const count = data.custom_parts[id];
        return isPresent(count) ? sum + count.count : sum;
    }, 0);

    return otsPartCount + customPartCount;
}

const DefaultPlaceholder: React.FunctionComponent = () => (
    <Flexbox style={{ opacity: 0 }}>
        <Message size="small" attention="high" variant="green" title={''} message={''} />
    </Flexbox>
);

type AlertState =
    | { isLoading: true; alert: 'visible' }
    | { isLoading: false; alert: 'visible' | 'transitioning' | 'hidden' };

export const AlertPartialResults: React.FunctionComponent<{
    data: LoadingOffersProgressDTO | undefined;
    /**
     * Used to filter remaining offers by partIds. If null, loading progress is not part-specific and we use the total count.
     */
    partIds: string[] | null;
    Placeholder?: React.ComponentType;
}> = ({ data, partIds, Placeholder = DefaultPlaceholder }) => {
    const [state, setState] = React.useState<AlertState>({ isLoading: false, alert: 'hidden' });
    const remainingOffers = isPresent(data) && isPresent(partIds) ? getRemainingOfferCount(data, partIds) : data?.total;

    React.useEffect(() => {
        if (!isPresent(remainingOffers)) {
            return setState({ isLoading: false, alert: 'hidden' });
        }
        if (remainingOffers > 0) {
            return setState({ isLoading: true, alert: 'visible' });
        }
        if (remainingOffers === 0) {
            setState((state) => (state.isLoading ? { isLoading: false, alert: 'visible' } : state));
            const timeout = setTimeout(
                () =>
                    setState((state) =>
                        state.alert === 'visible' ? { isLoading: false, alert: 'transitioning' } : state,
                    ),
                5000,
            );
            return () => clearTimeout(timeout);
        }
    }, [remainingOffers, setState]);

    if (state.alert === 'hidden') {
        return <Placeholder />;
    }

    if (state.isLoading && isPresent(data)) {
        return (
            <Message
                size="small"
                attention="high"
                variant="yellow"
                title={t`Partial results`}
                message={t`We're still loading offers from ${formatApis(data)}.`}
                overrides={{
                    ActionButton: () => (
                        <Text
                            variant="caption"
                            style={{ color: colorSystem.neutral[9] }}
                        >{t`Checking ${remainingOffers} offers for updates`}</Text>
                    ),
                }}
            />
        );
    }

    return (
        <Flexbox
            onTransitionEnd={() => setState({ isLoading: false, alert: 'hidden' })}
            style={{ opacity: state.alert === 'visible' ? 1 : 0, transition: 'opacity 0.2s linear' }}
        >
            <span style={{ width: '100%' }}>
                <Message
                    size="small"
                    attention="high"
                    variant="green"
                    title={t`Success`}
                    message={t`All offers were successfully loaded and updated.`}
                />
            </span>
        </Flexbox>
    );
};
