import { Trans } from '@lingui/macro';
import { Column, columnWidth, Row } from '@luminovo/design-system';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';

interface RowWithName {
    name: string;
}

export function getNameColumn<C>(): Column<RowWithName, C> {
    return {
        label: <Trans>Name</Trans>,
        id: 'name',
        render: ({ data: rowData }: Row<RowWithName>) => <TextCell firstRow={rowData.name} minWidth={160} />,
        width: columnWidth.small,
        searchable: { searchBy: (rowData): string => rowData.name },
    };
}
