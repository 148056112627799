import { t, Trans } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import { colorSystem, FieldRadio, Flexbox, FormItem, PrimaryButton, Text } from '@luminovo/design-system';
import { ComplianceStatus, CustomFullPart, CustomPartType, CustomPartTypeEnum } from '@luminovo/http-client';
import { customPartTypeTranslations } from '@luminovo/sourcing-core';
import React from 'react';
import { useCustomPartTypes } from '../../../../../resources/part/partHandler';
import { useCreateCustomPartMutation } from '../useCreateCustomPartMutation';
import { FieldDescription } from './FieldDescription';
import { FieldItemFiles } from './FieldItemFiles';
import { initialState, reducer } from './reducer';

export const AddCustomPart = ({
    descriptionSuggestions,
    handleAppendCustomPart,
    selectedPartType,
    width = '568px',
}: {
    descriptionSuggestions: string[];
    handleAppendCustomPart: (newPart: CustomFullPart) => void;
    selectedPartType?: CustomPartType;
    width?: string;
}) => {
    const { mutateAsync, isLoading } = useCreateCustomPartMutation();
    const initialState0 = isPresent(selectedPartType)
        ? { ...initialState, partType: selectedPartType.name }
        : initialState;
    const [state, dispatch] = React.useReducer(reducer, initialState0);
    const { data: partTypesData } = useCustomPartTypes(true);
    const partTypes: CustomPartTypeEnum[] = partTypesData ?? [];

    async function onClick() {
        mutateAsync(state).then((id) => {
            const partType =
                state.partType === CustomPartTypeEnum.PCB
                    ? { name: state.partType, content: null }
                    : { name: state.partType ?? CustomPartTypeEnum.Other };

            /* eslint-disable camelcase */
            return handleAppendCustomPart({
                id,
                description: state.description,
                type: partType,
                reach_compliant: ComplianceStatus.Compliant,
                rohs_compliant: ComplianceStatus.Compliant,
            });
            /* eslint-enable camelcase */
        });
        dispatch({ type: 'reset' });
    }

    return (
        <Flexbox flexDirection="column" gap={16} width={width}>
            <FormItem label={t`Description`}>
                <FieldDescription state={state} dispatch={dispatch} descriptionSuggestions={descriptionSuggestions} />
            </FormItem>
            <FormItem label={t`Type`} required>
                {isPresent(selectedPartType) && (
                    <div>
                        <Text
                            variant="body-small"
                            style={{ marginLeft: 4, whiteSpace: 'pre' }}
                            color={colorSystem.neutral[6]}
                        >
                            {t`Must be the same in a BOM item`}
                        </Text>
                    </div>
                )}
                {partTypes.map((partType) => {
                    return (
                        <Flexbox gap={8} key={partType}>
                            <FieldRadio
                                value={state.partType}
                                fieldValue={partType}
                                disabled={isPresent(selectedPartType) && partType !== selectedPartType.name}
                                onChange={(v) => dispatch({ type: 'setType', partType: v })}
                                size="small"
                            />
                            <Text variant="body-small">{transEnum(partType, customPartTypeTranslations)}</Text>
                        </Flexbox>
                    );
                })}
            </FormItem>
            <FormItem label={t`Files`}>
                <FieldItemFiles state={state} dispatch={dispatch} />
            </FormItem>
            <Flexbox gap={8} justifyContent={'flex-start'}>
                <PrimaryButton size="small" onClick={onClick} isLoading={isLoading}>
                    <Trans>Add</Trans>
                </PrimaryButton>
            </Flexbox>
        </Flexbox>
    );
};
