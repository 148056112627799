import { Trans } from '@lingui/macro';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { SitesWithIcon, useSiteNames } from '../../../../components/Site';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';

function getSiteFilters<T extends RowWithSites, C extends SitesColumnContext>(context: C): Filter<T, C>[] {
    const siteFilters = Object.entries(context.siteNamesMap).map(([siteId, siteName]) => ({
        id: `site=${siteId}`,
        label: siteName,
        predicate: (row: T) => row.sites.includes(siteId),
    }));

    siteFilters.sort((a, b) => a.label.localeCompare(b.label));

    const emptyFilter = {
        id: 'site=empty',
        label: (
            <i>
                <Trans>No site</Trans>
            </i>
        ),
        predicate: (row: T) => row.sites.length === 0,
    };

    return [emptyFilter, ...siteFilters];
}

export const useSitesColumnContext = (): SitesColumnContext => {
    const { siteNames = {} } = useSiteNames();

    return { siteNamesMap: siteNames };
};

export interface SitesColumnContext {
    siteNamesMap: Record<string, string>;
}

interface RowWithSites {
    sites: string[];
}

type ColumnWidth = 'extraLarge' | 'large' | 'medium' | 'small' | 'extraSmall';

export function getSitesColumn<C extends SitesColumnContext>(context: C, width?: ColumnWidth): Column<RowWithSites, C> {
    const searchBy = (row: RowWithSites, context: C): string => {
        return row.sites
            .map((site) => context.siteNamesMap[site])
            .sort((a, b) => a.localeCompare(b))
            .join(', ');
    };

    return {
        label: <Trans>Site</Trans>,
        id: 'site',
        render: ({ data: rowData }: Row<RowWithSites>, context: C): JSX.Element => {
            return (
                <TextCell
                    firstRow={<SitesWithIcon sites={rowData.sites} siteNamesMap={context.siteNamesMap} />}
                    minWidth={160}
                />
            );
        },
        searchable: { searchBy },
        width: width ? columnWidth[width] : columnWidth.medium,
        filters: getSiteFilters(context),
    };
}
