/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { Currency, formatDecimal, formatMonetaryValue, formatPercentage } from '@luminovo/commons';
import { SidebarLayout, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO, SupplierPortalSettingsDTO } from '@luminovo/http-client';
import { LeadTimeUnit, formatLeadTimeUnit } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorFallback } from '../../../components/errorHandlers/ErrorBoundary';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { Indicator } from './components/Indicator';
import { QuoteDetailsSidebar } from './components/QuoteDetailsSidebar';
import { QuotedStandardPartsTable } from './components/QuotedStandardPartsTable';
import { ToolbarSupplierQuotePage } from './components/ToolbarSupplierQuotePage';
import { useSubmitStandardPartQuote } from './hooks/useSubmitStandardPartQuote';
import {
    createLineItems,
    getSupplierPortalMode,
    isLineItemQuoted,
    quoteLeadTimeDays,
    sumTotalValue,
} from './model/utils';
import { StandardPartQuoteFormValues } from './types';

export function StandardPartQuoteDetailsPage({
    quoteTracking,
    preferredCurrency,
    supplierPortalSettings,
}: {
    quoteTracking: QuoteTrackingDTO;
    preferredCurrency: Currency;
    supplierPortalSettings: SupplierPortalSettingsDTO;
}) {
    const defaultValues: StandardPartQuoteFormValues = {
        currency: preferredCurrency,
        lineItems: createLineItems(quoteTracking),
        offerNumber: '',
        leadTimeUnit: LeadTimeUnit.Days,
        supplierAndStockLocation: quoteTracking.supplier_and_stock_location.id,
    };

    const onSubmit = useSubmitStandardPartQuote({ quote: quoteTracking });

    return (
        <FormContainer key={getSupplierPortalMode(quoteTracking)} onSubmit={onSubmit} defaultValues={defaultValues}>
            <SidebarLayout
                navbar={<ToolbarSupplierQuotePage quoteTracking={quoteTracking} />}
                defaultSidebarWidth="400px"
                contentBackground={colorSystem.neutral[1]}
                content={<Content quoteTracking={quoteTracking} />}
                sidebar={
                    <QuoteDetailsSidebar
                        quoteTracking={quoteTracking}
                        preferredCurrency={preferredCurrency}
                        supplierPortalSettings={supplierPortalSettings}
                    />
                }
                fallback={ErrorFallback}
            />
        </FormContainer>
    );
}

function Content({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const lineItems = useWatch({ control, name: 'lineItems' });

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                padding: '20px',
                gap: '20px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Indicators />
            <Box sx={{ flexGrow: 1 }}>
                <QuotedStandardPartsTable lineItems={lineItems} mode={getSupplierPortalMode(quoteTracking)} />
            </Box>
        </Box>
    );
}

function Indicators() {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const lineItems = useWatch({ control, name: 'lineItems' });
    const currency = useWatch({ control, name: 'currency' });

    const countQuotedParts = lineItems.filter((item) => isLineItemQuoted(item)).length;
    const countTotalParts = lineItems.length;
    const percent = formatPercentage(countQuotedParts / countTotalParts, { minimumFractionDigits: 0 });
    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%', gap: 2 }}>
            <Indicator
                variant="table"
                label={t`Line items`}
                indicator={`${formatDecimal(countQuotedParts)} of ${formatDecimal(countTotalParts)}`}
                secondary={t`${percent} quoted`}
            />

            <Indicator
                variant="no-icon"
                label={t`Total value`}
                indicator={formatMonetaryValue(sumTotalValue({ lineItems, currency }))}
                secondary={''}
            />

            <IndicatorLeadTime />
        </Box>
    );
}

function IndicatorLeadTime() {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const lineItems = useWatch({ control, name: 'lineItems' });
    const leadTimeUnit = useWatch({ control, name: 'leadTimeUnit' });

    const { unit, value } = quoteLeadTimeDays({ lineItems, leadTimeUnit });

    return (
        <Indicator
            variant="no-icon"
            label={t`Lead time`}
            indicator={`${formatDecimal(value)} ${formatLeadTimeUnit(unit)}`}
            secondary={''}
        />
    );
}
