export class NetworkError extends Error {
    public readonly endpoint: string;

    constructor({ endpoint }: { endpoint: string }) {
        const lines = [`Network connection issue when fetching`, `    ${endpoint}`, ''];
        super(lines.join('\n\n'));

        this.endpoint = endpoint;
    }
}
