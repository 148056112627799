import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps<T> {
    children?: React.ReactNode;
    dir?: string;
    index: T;
    value: T;
}

export function TabPanel<T>(props: TabPanelProps<T>) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} {...other}>
            {value === index && <Box mt={2}>{children}</Box>}
        </div>
    );
}
