import { Trans } from '@lingui/macro';
import { Divider, InputAdornment, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';
import { TextField } from '../../TextField';
import { TertiaryButton } from '../../buttons';
import { Argument, Operator, ParameterOption, ParametricSearchState } from '../types';
import { formatOperator } from '../util/formatOperator';
import { MenuOperatorIn } from './MenuOperatorIn';
import { MenuOperatorIs } from './MenuOperatorIs';
import { MenuOperatorNumber } from './MenuOperatorNumber';
import { MenuOperatorNumberList } from './MenuOperatorNumberList';

export function MenuOperatorArgument<T, TAutocompleteState>({
    state,
    onSelect,
    onCancel,
    onSearch,
    autocompleteState,
}: {
    state: ParametricSearchState<T, TAutocompleteState>;
    onSearch: (query: string) => void;
    onSelect: (argument: Argument, label?: string | JSX.Element) => void;
    onCancel: () => void;
    autocompleteState?: TAutocompleteState;
}): JSX.Element | null {
    const operator = state.partialBlock?.selectedOp;
    const parameter = state.partialBlock?.parameter;

    if (!operator || !parameter) {
        return null;
    }

    if (operator.op === 'like' || operator.op === 'starts-with') {
        return <MenuOperatorLike onSelect={onSelect} parameter={parameter} operator={operator} onCancel={onCancel} />;
    }

    if (operator.op === 'in' || operator.op === 'not in') {
        return (
            <MenuOperatorIn
                autocompleteState={autocompleteState}
                onSelect={onSelect}
                onCancel={onCancel}
                state={state}
                operator={operator}
            />
        );
    }

    if (
        operator.op === '=' ||
        operator.op === '<' ||
        operator.op === '≤' ||
        operator.op === '>' ||
        operator.op === '≥'
    ) {
        if (operator.binned) {
            return (
                <MenuOperatorNumberList
                    state={state}
                    autocompleteState={autocompleteState}
                    operator={operator}
                    onSelect={onSelect}
                    onCancel={onCancel}
                    onSearch={onSearch}
                    parameter={parameter}
                />
            );
        }
        return (
            <MenuOperatorNumber
                autocompleteState={autocompleteState}
                operator={operator}
                onSelect={onSelect}
                onCancel={onCancel}
                parameter={parameter}
            />
        );
    }

    if (operator.op === 'is' || operator.op === 'is not') {
        return (
            <MenuOperatorIs
                autocompleteState={autocompleteState}
                inputRef={state.inputRef}
                onSelect={onSelect}
                onSearch={onSearch}
                state={state}
                operator={operator}
            />
        );
    }

    return null;
}

function MenuOperatorLike<T, TAutocompleteState>({
    onSelect,
    onCancel,
    parameter,
    operator,
}: {
    onSelect: (value: string) => void;
    onCancel: () => void;
    parameter: ParameterOption<T, TAutocompleteState>;
    operator: Operator<T, TAutocompleteState, string>;
}): JSX.Element {
    const [query, setQuery] = React.useState('');
    return (
        <Container
            data-testid="MenuOperatorLike"
            onKeyUp={(e) => {
                if (e.key === 'Enter' && query.length !== 0) {
                    onSelect(query);
                }
                if (e.key === 'Escape') {
                    onCancel();
                }
            }}
        >
            <Flexbox justifyContent={'space-between'} alignItems="center">
                <Text variant="h5">
                    {parameter.label ?? parameter.field} {formatOperator(operator.op)}
                </Text>
                <TertiaryButton
                    onClick={() => onSelect(query)}
                    size="medium"
                    disabled={query.length === 0}
                    style={{ height: '16px', marginRight: '-12px' }}
                >
                    <Trans>Apply</Trans>
                </TertiaryButton>
            </Flexbox>
            <Divider style={{ margin: '4px -16px' }} />
            <TextField
                autoFocus
                fullWidth
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                size="small"
                InputProps={{
                    endAdornment: parameter.unit ? (
                        <InputAdornment position="end">{parameter.unit}</InputAdornment>
                    ) : undefined,
                }}
            />
        </Container>
    );
}

const Container = styled(Flexbox)({
    gap: 8,
    padding: '12px 16px 16px 16px',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    width: '220px',
});
