import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { PrimaryButton } from '@luminovo/design-system';
import { PublishRounded } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useIsBomImportingAllowed } from '../../../resources/assembly/assemblyHandler';
import { useHasAccessToCustomerPortal } from '../../../utils/featureFlags';
import { useBomImporter } from '../../BomImporter/BomImporterContext';
import { ViewContext } from './ModuleTableData';

interface ImportBomButtonProps {
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
}

/**
 * Special ID used as a way of telling when the bom Item creation form
 * is creating a new BOM item vs updating an existing one.
 */
export const newDesignItemId: string = 'newDesignItemId';

export const ImportBomButton = ({ rfqId, assemblyId, viewContext }: ImportBomButtonProps) => {
    const importer = useBomImporter();
    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();
    const { customer } = useCurrentUserDetailsContext();
    const shouldDisableBomButtonForCustomer = isPresent(customer) && !hasAccessToCustomerPortal;
    const showImportBOMOption = useIsBomImportingAllowed({ rfqId, assemblyId }) && !shouldDisableBomButtonForCustomer;

    if (!showImportBOMOption || !importer) {
        return null;
    }

    return (
        <Dropzone
            multiple={false}
            maxFiles={1}
            accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
                'text/csv': ['.csv'],
                /* eslint-disable-next-line spellcheck/spell-checker */
                'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
            }}
            onDropAccepted={async (files) => {
                importer.handleBomImport({
                    files,
                    rfqId,
                    assemblyId,
                    viewContext,
                });
            }}
            children={({ getInputProps, getRootProps }) => {
                return (
                    <span {...getRootProps()}>
                        <PrimaryButton size="medium" startIcon={<PublishRounded />}>
                            <Trans>Import BOM</Trans>
                        </PrimaryButton>
                        <input {...getInputProps()} />
                    </span>
                );
            }}
        />
    );
};
