import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    DesignatorsList,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

type Props = {
    designators: string[];
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

const useDesignatorsAction = (operation: HistoryOperation, actor: ActorResponseRuntypeDTO) => {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <TimelineText>
                    <Trans>manually added</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualRemove:
            return (
                <TimelineText>
                    <Trans>manually removed</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
};

export default function Designators({ designators, operation, actor }: Props) {
    const action = useDesignatorsAction(operation, actor);
    if (!action) return null;

    return (
        <TimelineContentContainer>
            <TimelineText style={{ display: 'flex' }}>
                <Trans>Designators</Trans> <DesignatorsList designators={designators} /> {action}
            </TimelineText>
        </TimelineContentContainer>
    );
}

export function DesignatorsTimelineIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return <TimelineAddIcon />;
}
