import { colorSystem, SecondaryButton, Text, Toolbar, usePersistedVisibility } from '@luminovo/design-system';
import { UserType } from '@luminovo/http-client';
import { Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Avatar } from '../../../components/Navbar/Avatar';
import { PageLayout } from '../../../components/PageLayout';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { route } from '../../../utils/routes';
import { SupplierPortalQuoteStatusChip } from './SupplierPortalQuoteStatusChip';

export function LayoutSupplierPortal(props: {
    children: React.ReactNode;
    quoteRequestId?: number;
    actions?: React.ReactNode;
    breadcrumbLabel?: string;
}) {
    const { organization } = useCurrentUserDetailsContext();
    const { userType } = useCurrentUserDetailsContext();

    const breadcrumbs = [
        {
            title: `${organization.name} portal`,
            href: route('/supplier-portal'),
        },
    ];
    if (props.quoteRequestId) {
        breadcrumbs.push({
            title: `Quote request #${props.quoteRequestId}`,
            href: route(`/supplier-portal/quote-requests/:quoteRequestId`, { quoteRequestId: props.quoteRequestId }),
        });
    }
    if (props.breadcrumbLabel) {
        breadcrumbs.push({
            title: props.breadcrumbLabel,
            href: '',
        });
    }

    return (
        <PageLayout
            navbar={
                <Toolbar
                    breadcrumbs={breadcrumbs}
                    left={<SupplierPortalQuoteStatusChip quoteRequestId={props.quoteRequestId} />}
                >
                    {props.actions}
                    <div style={{ width: 2 }} />
                    <Avatar variant="light" style={{ margin: 0 }} />
                </Toolbar>
            }
            style={{ background: colorSystem.neutral[1] }}
            layout="fragment"
            removeHubspotPaddingFix={true}
        >
            {userType !== UserType.Supplier && <MessageNotSupplierUser />}

            {props.children}
        </PageLayout>
    );
}

function MessageNotSupplierUser() {
    const { isVisible, hide } = usePersistedVisibility({
        id: 'supplier-portal::message-supplier-user',
        maxDisplayCount: 5,
    });

    if (!isVisible) {
        return <></>;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                placeItems: 'center',
                background: colorSystem.blue[1],
                border: `1px solid ${colorSystem.blue[4]}`,
            }}
        >
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', padding: '8px' }}>
                <Info style={{ color: colorSystem.blue[7] }} />
                <Text color={colorSystem.blue[8]}>
                    You are viewing the supplier portal as a regular user. Suppliers can only access their own quote
                    requests.
                </Text>
                <SecondaryButton size="medium" onClick={hide}>
                    Hide
                </SecondaryButton>
            </Box>
        </Box>
    );
}
