import { Text, Tooltip } from '@luminovo/design-system';
import React from 'react';

const getShortenedFileName = (fileName: string) => {
    return `${fileName.slice(0, 13)}...${fileName.slice(-8)}`;
};

export const PcbFileName = ({
    text,
    TextProps,
    shouldShortenFileName: shouldShortenFileNameProps = true,
}: {
    text: string;
    TextProps?: React.ComponentProps<typeof Text>;
    shouldShortenFileName?: boolean;
}) => {
    const shouldShortenFileName = shouldShortenFileNameProps ? text.length > 25 : false;
    const shortenedFileName = getShortenedFileName(text);

    return (
        <Tooltip title={text} disableHoverListener={shouldShortenFileName === false}>
            <Text {...TextProps}>{shouldShortenFileName ? shortenedFileName : text}</Text>
        </Tooltip>
    );
};
