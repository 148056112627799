import { assertUnreachable } from '@luminovo/commons';
import * as React from 'react';
import { FormulaField } from './components/FormulaField';
import { FormulaText } from './components/FormulaText';
import { FormulaValue } from './components/FormulaValue';
import { NodeFormula } from './types';

function InlineNode({ node }: { node: NodeFormula }) {
    if (node.type === 'text') {
        return <>{node.value}</>;
    }
    if (node.type === 'value') {
        return <FormulaValue label={node.value} />;
    }
    if (node.type === 'field') {
        return <FormulaField label={node.value} />;
    }
    assertUnreachable(node);
}

export function Formula({ nodes }: { nodes: Array<[NodeFormula, ...NodeFormula[]]> }): JSX.Element {
    return (
        <FormulaText>
            {nodes.map(([node, ...nodes], i) => {
                return (
                    <React.Fragment key={i}>
                        <InlineNode node={node} />{' '}
                        {nodes.map((node, i) => (
                            <React.Fragment key={i}>
                                {' '}
                                <InlineNode node={node} />{' '}
                            </React.Fragment>
                        ))}
                        <br />
                    </React.Fragment>
                );
            })}
        </FormulaText>
    );
}
