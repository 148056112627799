import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { Attribute } from './types';

/**
 * Compares two attributes by their confidence.
 * If the confidence is not defined, it is assumed to be 1. This way we don't have to
 * add confidence for every kind of attribute.
 * Furthermore if the attribute is manually, it is assumed to be of the highest confidence.
 */

export const compareByConfidence = chainComparators(
    compareByNumber<Attribute>((attr) => {
        return attr.origin === 'manual' ? -1 : 1;
    }),
    compareByNumber<Attribute>((a) => -(a.confidence ?? 1)),
);
