import { Trans, t } from '@lingui/macro';
import React from 'react';
import { NonIdealState } from '../NonIdealState';
import { CenteredLayout } from '../layout/CenteredLayout';
import { NoResultsComponentProps } from './types';

/**
 * This view is shown when there are no results.
 *
 * Note that there can be no results for 2 reasons:
 * 1. The global query has no matches
 * 2. The selected filters have no matches
 */
export function DefaultNoResults({ query, state, dispatch }: NoResultsComponentProps): JSX.Element {
    const showClearFilters = state.selectedFilterIds.length > 0 || Object.keys(state.queries ?? {}).length > 0;
    const handleClearFilters = React.useCallback(() => {
        dispatch({ type: 'clear-filters' });
    }, [dispatch]);
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`No match`}
                description={
                    query ? <Trans>No exact matches for "{query}"</Trans> : <Trans>There are no matches</Trans>
                }
                action={
                    showClearFilters
                        ? {
                              onClick: () => handleClearFilters(),
                              children: t`Clear filters`,
                          }
                        : undefined
                }
            />
        </CenteredLayout>
    );
}
