import { i18n, MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import * as React from 'react';
import { throwErrorUnlessProduction } from '../errors';

export type Translations = Record<string | number, MessageDescriptor>;

type Props = {
    /**
     * The text to translate.
     *
     * Notice that this value can be both a string and a number since often it is an enum. e.g.
     *
     * ```
     * enum Step {
     *   A = 0,
     *   B = 1
     * }
     *
     * // should return something like "First Step"
     * transEnum(Step.A, stepTranslations)
     * ```
     */
    text: string | number;
    translations: Translations;
};

/**
 * Use in conjunction with `defineMessage` for cases when the `text` prop can be translated to a finite set of options.
 * Will output the `text` prop if no translation is found.
 *
 * Example:
 *
 * ```
 * const statusTranslations: Record<string, MessageDescriptor> = {
 *   "todo": defineMessage({ message: 'Todo' }),
 *   "doing": defineMessage({ message: 'Doing' }),
 *   "done": defineMessage({ message: 'Done' }),
 * };
 *
 * <TransEnum text={someStatus} translations={statusTranslations} />
 * ```
 */
export const TransEnum: React.FunctionComponent<Props> = ({ translations, text }) => {
    const id = translations[text]?.id;
    if (!id) {
        const knownTranslations = Object.keys(translations).join(', ');
        throwErrorUnlessProduction(
            new Error(
                [
                    `🚨 MISSING TRANSLATION: A translation is missing for text ${text}. `,
                    `There are already translations for ${knownTranslations}.\n`,
                    `🛠 How to fix: Add the missing translation.`,
                ].join(''),
            ),
        );
        return <>{text}</>;
    }
    return <Trans id={id} />;
};

/**
 * Use in conjunction with `defineMessage` for cases when the `text` prop can be translated to a finite set of options.
 * Will output the `text` argument if no translation is found.
 *
 * Example:
 *
 * ```
 * const statusTranslations: Record<string, MessageDescriptor> = {
 *   "todo": defineMessage({ message: 'Todo' }),
 *   "doing": defineMessage({ message: 'Doing' }),
 *   "done": defineMessage({ message: 'Done' }),
 * };
 *
 * console.log(transEnum("doing", statusTranslations))
 * // will output 'Doing', translated based on the current locale.
 * ```
 */
export const transEnum = (text: Props['text'], translations: Props['translations']): string => {
    const id = translations[text]?.id;
    if (!id) {
        const knownTranslations = Object.keys(translations).join(', ');
        throwErrorUnlessProduction(
            new Error(
                [
                    `🚨 MISSING TRANSLATION: A translation is missing for text ${JSON.stringify(text)}. `,
                    `There are already translations for ${knownTranslations}.\n`,
                    `🛠 How to fix: add the missing translation, then run "yarn intl" or `,
                    `follow the instructions on adding new translations in the README.md`,
                ].join(''),
            ),
        );
        return String(text);
    }
    return i18n.t({ id });
};
