import { t } from '@lingui/macro';
import { colorSystem, FieldSelectControlled } from '@luminovo/design-system';
import { ManufacturerDTO } from '@luminovo/http-client';
import { AutocompleteRenderOptionState } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useFormContext } from 'react-hook-form';
import { useManufacturers } from '../../../../../../../resources/manufacturer/manufacturerHandler';
import { OtsFormValues } from './formValues';

const filterManufacturerOptions = createFilterOptions<ManufacturerDTO>({
    stringify: (option: ManufacturerDTO): string => {
        return option.name + ' ' + option.alternative_names.join(' ');
    },
});

const filterAlternativeManufacturerNames = (
    manufacturerName: string,
    alternativeNames: string[],
    inputValue: string,
): string => {
    if (manufacturerName.toUpperCase().includes(inputValue.toUpperCase())) {
        // In this case there is no real benefit of displaying any (or even a truckload of) alternative names.
        return '';
    }
    const filteredNames = alternativeNames.filter((element) =>
        element.toUpperCase().includes(inputValue.toUpperCase()),
    );
    if (filteredNames.length > 0) {
        return filteredNames.join(', ');
    }
    return '';
};

const renderOptionManufacturerAutocomplete = (option: ManufacturerDTO, state: AutocompleteRenderOptionState) => {
    return (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
            {option.name}

            <span style={{ color: colorSystem.neutral[6] }}>
                {filterAlternativeManufacturerNames(option.name, option.alternative_names, state.inputValue)}
            </span>
        </span>
    );
};

export const ManufacturerAutocomplete = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();
    const { data: manufacturers = [] } = useManufacturers();

    return (
        <FieldSelectControlled
            control={control}
            name="manufacturerDto"
            required={true}
            FieldProps={{
                options: manufacturers,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (opt, value) => opt.id === value.id,
                filterOptions: filterManufacturerOptions,
                renderOption: (option, state) => renderOptionManufacturerAutocomplete(option, state),
                placeholder: t`Search for manufacturer`,
                noOptionsText: t`No results. Please contact support@luminovo.com if you want us to add a new manufacturer.`,
                size: 'large',
            }}
        />
    );
};
