import { t } from '@lingui/macro';
import { FieldTextControlled, FormItem } from '@luminovo/design-system';
import { Control, Path } from 'react-hook-form';
import { BaseManufacturingScenarioFormState } from '../types';

export const ManufacturingScenarioNameControlled = <T extends BaseManufacturingScenarioFormState>({
    control,
    name,
    width,
}: {
    name: Path<T>;
    control: Control<T>;
    width?: string;
}): JSX.Element => {
    return (
        <FormItem label={t`Manufacturing scenario name`} required width={width ?? '100%'}>
            <FieldTextControlled
                control={control}
                name={name}
                required
                FieldProps={{
                    placeholder: t`Enter manufacturing scenario name`,
                }}
            />
        </FormItem>
    );
};
