import { Flexbox, Switch } from '@luminovo/design-system';
import { Typography } from '@mui/material';
import React from 'react';

export const TextFieldInputProps = {
    style: {},
};

export const FormOverallWrapper = ({ children }: { children: React.ReactNode }) => (
    <Flexbox flexDirection={'column'} paddingX="32px" gap={24}>
        {children}
    </Flexbox>
);

export const EnabledLabel = ({
    isEnabled,
    handleChange,
    text,
}: {
    isEnabled: boolean;
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
    text: string;
}) => (
    <Flexbox alignItems={'center'} gap={'8px'} marginLeft={'4px'}>
        <Switch checked={isEnabled} onChange={handleChange} name="Notes" color="secondary" />
        <Typography variant="h4" color={'textSecondary'}>
            {text}
        </Typography>
    </Flexbox>
);
