import { Trans } from '@lingui/macro';
import { MaxWidthLayout, Text } from '@luminovo/design-system';
import { StandardPartOfferLinkedPartType } from '@luminovo/http-client';
import { PageLayout } from '../../../../../../../../components/PageLayout';
import { AddStandardPartOfferForm } from '../../../../../../../../modules/StandardPartOfferForm.tsx/components';
import { extractRfqContextFromRfqId } from '../../../../../../../../modules/StandardPartOfferForm.tsx/components/converters';
import { UrlParams } from '../../../../../../../../utils/routes';

const AddOffTheShelfOfferPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/offer/off-the-shelf/add/:partId'>,
): JSX.Element => {
    const { rfqId, partId } = urlParams.pathParams;
    const { solutionConfigurationId } = urlParams.queryParams;
    const rfqContext = extractRfqContextFromRfqId(rfqId);

    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Text variant="h1">
                    <Trans>Add off-the-shelf part offer</Trans>
                </Text>
                <AddStandardPartOfferForm
                    rfqContext={rfqContext}
                    linkedPart={{ type: StandardPartOfferLinkedPartType.OffTheShelf, id: partId }}
                    solutionConfigurationId={solutionConfigurationId ?? undefined}
                />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default AddOffTheShelfOfferPage;
