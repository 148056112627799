import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { ConflictMineralStatus } from '@luminovo/http-client';
import React from 'react';

export const ConflictMineralStatusChipTranslations: Record<ConflictMineralStatus, MessageDescriptor> = {
    [ConflictMineralStatus.Compliant]: defineMessage({ message: 'Compliant' }),
    [ConflictMineralStatus.Unknown]: defineMessage({ message: 'Unknown' }),
};

export const ConflictMineralStatusChip: React.FunctionComponent<{
    status: ConflictMineralStatus;
}> = ({ status }) => {
    const label = transEnum(status, ConflictMineralStatusChipTranslations);
    switch (status) {
        case ConflictMineralStatus.Compliant:
            return <StatusChip color="green" label={label} />;
        case ConflictMineralStatus.Unknown:
            return <StatusChip color="neutral" label={label} />;
        default:
            assertUnreachable(status);
    }
};
