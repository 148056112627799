import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    colorSystem,
    FieldNumericController,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    Tag,
} from '@luminovo/design-system';
import { DriverIdDTO, ExpenseCostCalculationType, ExpenseLevel, UserDriverDetailsDTO } from '@luminovo/http-client';
import { formatDriverLabel } from '@luminovo/manufacturing-core';
import { Box, Grid, GridSize, InputAdornment, styled, Typography } from '@mui/material';
import { Path, useFormContext } from 'react-hook-form';
import {
    RadioGroupController,
    SelectController,
} from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { transEnum } from '../../../../components/localization/TransEnum';
import { LoadingText, Skeleton } from '../../../../components/Spinners';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import {
    expenseCostCalculationTranslations,
    expenseLevelEnumPublicTranslations,
} from '../../../../resources/expenses/expensesBackendTypes';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { displayCurrencySymbol } from '../../../../utils/currencyUtils';
import { capitalizeFirstLetter, OVERSCORE_CHARACTER } from '../../../../utils/stringFunctions';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { expenseLevelCanBeLinkedToPanel, filterActiveOrSelectedDriverDetails } from '../../shared/manufacturingUtils';
import { AddExpenseFormInputs } from '../utils/AddExpenseFormTypes';
import { FormulaCalculationSection } from './FormulaCalculationSection';

type ViewType = 'withAdornment' | 'withCurrencySelection';

const getView = (
    viewType: ViewType,
    amountName: Path<AddExpenseFormInputs>,
    required: boolean = false,
): JSX.Element => {
    switch (viewType) {
        case 'withCurrencySelection':
            return (
                <CostWithCurrencySelection
                    amountName={amountName}
                    currencyName={'expenseCurrency'}
                    required={required}
                />
            );
        case 'withAdornment':
            return <CostWithInputAdornment amountName={amountName} required={required} />;

        default:
            assertUnreachable(viewType);
    }
};

function FixedCost({
    amountName,
    isRequired,
    gridSize,
    viewType,
}: {
    isRequired: boolean;
    gridSize: boolean | GridSize;
    amountName: Path<AddExpenseFormInputs>;
    viewType: ViewType;
}): JSX.Element {
    return (
        <>
            <FormItem label={t`Fixed cost`} required={isRequired}>
                <Grid item xs={gridSize}>
                    <Box display={'flex'}>{getView(viewType, amountName, isRequired)}</Box>
                </Grid>
            </FormItem>
        </>
    );
}

function CurrencySelection({ currencyName }: { currencyName: Path<AddExpenseFormInputs> }): JSX.Element {
    const { control } = useFormContext<AddExpenseFormInputs>();

    return (
        <Flexbox gap={4} alignSelf="start">
            <SelectController
                name={currencyName}
                control={control}
                translations={inputCurrenciesPublicTranslations}
                SelectProps={{ style: { width: 120 } }}
            />
        </Flexbox>
    );
}

function CostWithCurrencySelection({
    amountName,
    currencyName,
    required = false,
}: {
    currencyName: Path<AddExpenseFormInputs>;
    amountName: Path<AddExpenseFormInputs>;
    required: boolean;
}): JSX.Element {
    const { control } = useFormContext<AddExpenseFormInputs>();

    return (
        <Flexbox gap={4}>
            <CurrencySelection currencyName={currencyName} />
            <FieldNumericController
                name={amountName}
                control={control}
                required={required}
                representation="string"
                FieldProps={{ style: { width: 180 } }}
            />
        </Flexbox>
    );
}

function CostWithInputAdornment({
    amountName,
    required = false,
}: {
    amountName: Path<AddExpenseFormInputs>;
    required?: boolean;
}): JSX.Element {
    const { control, watch } = useFormContext<AddExpenseFormInputs>();
    const currency = watch('expenseCurrency');
    return (
        <Flexbox gap={4}>
            <FieldNumericController
                name={amountName}
                control={control}
                required={required}
                representation="string"
                FieldProps={{
                    style: { width: 180 },
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                {<Typography>{displayCurrencySymbol(currency)}</Typography>}
                            </InputAdornment>
                        ),
                    },
                }}
            />
        </Flexbox>
    );
}

const LinearCostBreakdownSection = ({ selectedExpenseLevel }: { selectedExpenseLevel: ExpenseLevel }): JSX.Element => {
    return (
        <>
            <Grid item>
                <FormItem label={t`Currency`} required>
                    <Grid item>
                        <CurrencySelection currencyName={'expenseCurrency'} />
                    </Grid>
                </FormItem>
            </Grid>

            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormItem label={t`Variable cost`} required>
                            <Grid item>
                                <CostWithInputAdornment
                                    amountName={`linearCostCalculation.variableCost.variableUnit.amount`}
                                    required
                                />
                            </Grid>
                        </FormItem>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'}>
                            <Typography variant={'body2'} style={{ marginTop: '28px', marginRight: '16px' }}>
                                x
                            </Typography>
                            <LinearCostDriverSection selectedExpenseLevel={selectedExpenseLevel} />
                            <Typography variant={'body2'} style={{ marginTop: '28px', marginLeft: '16px' }}>
                                +
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <FixedCost
                            gridSize={4}
                            isRequired={false}
                            viewType="withAdornment"
                            amountName={'linearCostCalculation.fixedCost.amount'}
                        />
                    </Grid>
                    <Grid item>
                        <Flexbox style={{ marginTop: '36px' }} alignItems={'center'} gap={'4px'}>
                            <Typography>
                                <Trans>Per</Trans>
                            </Typography>
                            <Box mt={'2px'}>
                                <Tag
                                    color={'neutral'}
                                    label={transEnum(selectedExpenseLevel, expenseLevelEnumPublicTranslations)}
                                />
                            </Box>
                        </Flexbox>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const LinearCost = ({ selectedExpenseLevel }: { selectedExpenseLevel: ExpenseLevel }): JSX.Element => (
    <>
        <LinearCostBreakdownSection selectedExpenseLevel={selectedExpenseLevel} />
    </>
);

function renderCostComponent(
    selectedCostCalculationType: ExpenseCostCalculationType,
    selectedLevel: ExpenseLevel,
): JSX.Element {
    switch (selectedCostCalculationType) {
        case 'Fixed':
            return (
                <Flexbox margin={2}>
                    <FixedCost
                        isRequired={true}
                        gridSize={4}
                        viewType="withCurrencySelection"
                        amountName={`fixedCostCalculation.fixedCost.amount`}
                    />
                </Flexbox>
            );
        case 'Linear':
            return <LinearCost selectedExpenseLevel={selectedLevel} />;
        case 'Formula':
            return <FormulaCalculationSection />;
        default:
            assertUnreachable(selectedCostCalculationType);
    }
}

const useCostCalculationSection = () => {
    const { control, watch } = useFormContext<AddExpenseFormInputs>();
    const selectedCalculationType = watch('selectedCostCalculationType');
    const selectedLevel: ExpenseLevel = watch('expense.level');
    return {
        control,
        selectedCalculationType,
        selectedLevel,
    };
};

export function CostCalculationSection(): JSX.Element {
    const { control, selectedLevel, selectedCalculationType } = useCostCalculationSection();

    return (
        <FormSection title={t`Cost calculation`}>
            <FormItem label={t`Calculation type`}>
                <Grid item xs={4}>
                    <RadioGroupController
                        name={'selectedCostCalculationType'}
                        control={control}
                        options={expenseCostCalculationTranslations}
                    />
                </Grid>
            </FormItem>
            {renderCostComponent(selectedCalculationType, selectedLevel)}
        </FormSection>
    );
}

const useDriverForLinearCalculation = (expenseLevel: ExpenseLevel) => {
    const { control, watch } = useFormContext<AddExpenseFormInputs>();
    const { data } = useHttpQuery('GET /user-drivers', {});
    const selectedDriverId: DriverIdDTO | undefined = watch(`linearCostCalculation.variableCost.driverId`);
    const selectedDriver = selectedDriverId
        ? data?.data.find((driver) => driver.id === selectedDriverId.value)
        : undefined;
    const allDriverIds: DriverIdDTO[] = filterActiveOrSelectedDriverDetails(data?.data, selectedDriver);
    const isSelectedDriverPerPanel = selectedDriver?.is_per_panel;
    const shouldPanelDriverErrorBeShown = !!isSelectedDriverPerPanel && !expenseLevelCanBeLinkedToPanel(expenseLevel);

    return {
        control,
        allDriverIds,
        allDrivers: data?.data,
        shouldPanelDriverErrorBeShown,
        selectedDriver,
    };
};

const useSelectedDriverForLinearCalculation = (): {
    selectedDriver: UserDriverDetailsDTO | undefined;
    isLoading: boolean;
} => {
    const { watch } = useFormContext<AddExpenseFormInputs>();
    const driver: DriverIdDTO | undefined = watch('linearCostCalculation.variableCost.driverId');

    const { data, isLoading } = useHttpQuery(
        'GET /user-drivers/:driverId',
        { pathParams: { driverId: driver?.value ?? 'shouldNotBeCalled' } },
        { enabled: isPresent(driver) },
    );

    const selectedDriver = data?.data;

    return { selectedDriver, isLoading };
};
const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[7],
});
const LinearCostDriverSection = ({ selectedExpenseLevel }: { selectedExpenseLevel: ExpenseLevel }): JSX.Element => {
    const { control, allDriverIds, allDrivers, shouldPanelDriverErrorBeShown } =
        useDriverForLinearCalculation(selectedExpenseLevel);
    const { selectedDriver, isLoading } = useSelectedDriverForLinearCalculation();
    const defaultValue: DriverIdDTO | undefined = selectedDriver
        ? {
              value: selectedDriver.id,
              // because selected Driver is of type UserDriverDetailsDTO, we can safely hard code the user type here
              type: 'User',
          }
        : undefined;

    if (allDrivers === undefined) return <LoadingText />;
    return (
        <Flexbox flexDirection="column" gap="12px">
            <FormItem label={t`Driver`} required>
                <Flexbox>
                    {isLoading ? (
                        <Skeleton />
                    ) : (
                        <Box width={'392px'}>
                            <FieldSelectControlled
                                name={'linearCostCalculation.variableCost.driverId'}
                                control={control}
                                FieldProps={{
                                    placeholder: t`Search for a driver`,
                                    defaultValue: defaultValue,
                                    options: allDriverIds,
                                    getOptionLabel: (option) => formatDriverLabel(option, allDrivers),
                                    renderOption: (option) => formatDriverLabel(option, allDrivers),
                                }}
                            />
                        </Box>
                    )}

                    {selectedDriver?.is_per_panel && (
                        <Flexbox marginLeft={'16px'} marginTop={'-16px'} alignItems={'center'}>
                            <StyledTypography> x</StyledTypography>
                            <Box textAlign={'center'} marginLeft={'16px'}>
                                <StyledTypography variant={'body1'}>1</StyledTypography>
                                <StyledTypography variant={'body1'}>
                                    {OVERSCORE_CHARACTER.repeat(t`panel factor`.length + 6)}
                                </StyledTypography>
                                <StyledTypography variant={'body1'} style={{ marginTop: '-12px' }}>
                                    {capitalizeFirstLetter(t`panel factor`)}
                                </StyledTypography>
                            </Box>
                        </Flexbox>
                    )}
                </Flexbox>
            </FormItem>
            {shouldPanelDriverErrorBeShown && (
                <>
                    {selectedExpenseLevel === 'Project' && (
                        <Message
                            variant="red"
                            attention="high"
                            size="large"
                            title={t`Invalid driver`}
                            message={t`Project expenses cannot have a driver which is related to the panel`}
                        />
                    )}
                    {selectedExpenseLevel === 'Batch' && (
                        <Message
                            variant="red"
                            attention="high"
                            size="large"
                            title={t`Invalid driver`}
                            message={t`Batch expenses cannot have a driver which is related to the panel`}
                        />
                    )}
                </>
            )}
        </Flexbox>
    );
};
