import { Trans } from '@lingui/macro';
import { Column, columnWidth, Row, Tag } from '@luminovo/design-system';
import { ExpenseDTO } from '@luminovo/http-client';
import { transEnum } from '../../../../components/localization/TransEnum';
import { expenseLevelEnumPublicTranslations } from '../../../../resources/expenses/expensesBackendTypes';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';

const levelColumn: Column<ExpenseDTO> = {
    label: <Trans>Level</Trans>,
    id: `level`,
    render: ({ data: rowData }: Row<ExpenseDTO>) => (
        <TextCell
            firstRow={<Tag color={'neutral'} label={transEnum(rowData.level, expenseLevelEnumPublicTranslations)} />}
        />
    ),

    width: columnWidth.medium,
    filters: [
        {
            id: `expense level=Project`,
            label: <Trans>Project</Trans>,
            predicate: (expense): boolean => expense.level === 'Project',
        },
        {
            id: `expense level=Unit`,
            label: <Trans id="expense.unit">Unit</Trans>,
            predicate: (expense): boolean => expense.level === 'Unit',
        },
        {
            id: `expense level=Batch`,
            label: <Trans>Batch</Trans>,
            predicate: (expense): boolean => expense.level === 'Batch',
        },
    ],
};

export { levelColumn };
