import { TEXT_SEARCH_FIELD_ID } from './components/constants';
import { Actions, Argument, AutocompleteOptions, Operator, ParametricSearchState, SearchBlock } from './types';

export const reducer = <T, TAutocompleteState>(
    state: ParametricSearchState<T, TAutocompleteState>,
    action: Actions<T, TAutocompleteState>,
): ParametricSearchState<T, TAutocompleteState> => {
    const { query, partialBlock, selectedBlocks, config } = state;

    switch (action.type) {
        case 'backspace': {
            if (query.length > 0) {
                // do nothing
                return state;
            }

            if (partialBlock) {
                return {
                    ...state,
                    partialBlock: undefined,
                };
            }

            if (selectedBlocks.length === 0) {
                return state;
            }

            const lastBlock = selectedBlocks[selectedBlocks.length - 1];
            if (lastBlock.field === TEXT_SEARCH_FIELD_ID) {
                const copy = Array.from(selectedBlocks);
                copy.pop();
                return {
                    ...state,
                    selectedBlocks: copy,
                    query: String(lastBlock.parameter),
                };
            }

            if (selectedBlocks.length > 0) {
                const copy = Array.from(selectedBlocks);
                copy.pop();
                return {
                    ...state,
                    selectedBlocks: copy,
                };
            }

            return state;
        }

        case 'addSearchBlock': {
            return {
                ...state,
                selectedBlocks: [...selectedBlocks, action.searchBlock],
                partialBlock: undefined,
            };
        }

        case 'removeSearchBlock': {
            return {
                ...state,
                selectedBlocks: selectedBlocks.filter((b) => {
                    return b !== action.searchBlock;
                }),
                partialBlock: undefined,
            };
        }

        case 'setActiveFilter': {
            return {
                ...state,
                selectedBlocks: [action.searchBlock],
            };
        }

        case 'removePartialFilter': {
            if (state.editedBlock) {
                // copy array
                const selectedBlocks = state.selectedBlocks.slice(0);
                selectedBlocks.splice(state.editedBlock.index, 0, state.editedBlock.block);
                const obj = {
                    ...state,
                    selectedBlocks,
                    partialBlock: undefined,
                    editedBlock: undefined,
                };
                return obj;
            } else {
                const obj = {
                    ...state,
                    partialBlock: undefined,
                    editedBlock: undefined,
                };
                return obj;
            }
        }

        case 'submitQuery': {
            if (query.length === 0) {
                return state;
            }
            const filter = config.textQueryParser(query);

            const selectedBlocks = filter ? [...state.selectedBlocks, filter] : state.selectedBlocks;

            return {
                ...state,
                query: '',
                partialBlock: undefined,
                selectedBlocks,
            };
        }

        case 'onQueryChange': {
            const { query, parsedFilters } = tryToParseQuery(config.parsers, action.query);

            return {
                ...state,
                query,
                selectedBlocks: [...selectedBlocks, ...parsedFilters],
            };
        }

        case 'selectField':
            return reduceSelectField(state, action);

        case 'selectParameter':
            if (!partialBlock) {
                return state;
            }
            if (!partialBlock.selectedOp) {
                return state;
            }

            state.inputRef.current?.focus();
            return {
                ...state,
                selectedBlocks: [
                    ...state.selectedBlocks,
                    {
                        field: partialBlock.parameter.field,
                        op: partialBlock.selectedOp?.op,
                        parameter: action.parameter,
                    },
                ],
                query: '',
                partialBlock: undefined,
                editedBlock: undefined,
            };

        case 'selectOperator': {
            if (!partialBlock) {
                // if no partial block, don't allow selection of operators
                // in practice this case shouldn't happen as the user must
                // first select a field before they can select an operator.
                return state;
            }

            state.inputRef.current?.focus();

            return {
                ...state,
                partialBlock: {
                    ...partialBlock,
                    selectedOp: action.operator,
                },
            };
        }

        case 'editParameter': {
            const found = state.config.parameters.find((r) => r.field === action.searchBlock.field);
            if (!found) {
                return state;
            }

            const blockIdx = state.selectedBlocks.findIndex((b) => {
                return b === action.searchBlock;
            });

            const editedBlock =
                blockIdx > -1
                    ? {
                          index: blockIdx,
                          block: state.selectedBlocks[blockIdx],
                      }
                    : undefined;

            return {
                ...state,
                selectedBlocks: state.selectedBlocks.filter((b) => {
                    return b !== action.searchBlock;
                }),
                partialBlock: {
                    parameter: found,
                    selectedOp: found.ops.find((op) => op.op === action.searchBlock.op),
                },
                editedBlock,
            };
        }

        case 'editOperator': {
            const found = state.config.parameters.find((r) => r.field === action.searchBlock.field);
            if (!found) {
                return state;
            }

            const blockIdx = state.selectedBlocks.findIndex((b) => {
                return b === action.searchBlock;
            });

            const editedBlock =
                blockIdx > -1
                    ? {
                          index: blockIdx,
                          block: state.selectedBlocks[blockIdx],
                      }
                    : undefined;

            return {
                ...state,
                selectedBlocks: state.selectedBlocks.filter((b) => {
                    return b !== action.searchBlock;
                }),
                partialBlock: {
                    parameter: found,
                    selectedOp: undefined,
                },
                editedBlock,
            };
        }

        case 'clearSearch': {
            return {
                ...state,
                selectedBlocks: [],
                partialBlock: undefined,
            };
        }

        case 'setSelectedBlocks': {
            return {
                ...state,
                selectedBlocks: action.searchBlocks,
                partialBlock: undefined,
            };
        }
    }
};

function tryToParseQuery<T>(
    parsers: AutocompleteOptions<T>['parsers'] = [],
    query: string,
): { query: string; parsedFilters: SearchBlock<T>[] } {
    let remainingQuery = query;
    const parsedFilters = parsers.flatMap(({ regex, onMatch }): SearchBlock<T>[] => {
        const parseResult = regex.exec(query);
        if (parseResult === null || !parseResult[0]) {
            return [];
        }

        remainingQuery = remainingQuery.replace(parseResult[0], '');
        return [onMatch(parseResult)];
    });

    return { query: remainingQuery, parsedFilters };
}

const reduceSelectField = <T, TAutocompleteState>(
    state: ParametricSearchState<T, TAutocompleteState>,
    action: Actions<T, TAutocompleteState> & { type: 'selectField' },
): ParametricSearchState<T, TAutocompleteState> => {
    // if there is a single Op, select it automatically
    const autoSelectedOp: Operator<T, TAutocompleteState, Argument> | undefined =
        action.selected.ops.length === 1 ? action.selected.ops[0] : undefined;

    // if there is a single option on the Op, select it automatically
    const selectParameter = () => {
        if (!autoSelectedOp) {
            return undefined;
        }
        if (autoSelectedOp.op !== 'is' && autoSelectedOp.op !== 'is not') {
            return undefined;
        }
        const opts = autoSelectedOp.options(action.autocompleteState);
        if (opts.length !== 1) {
            return undefined;
        }
        return opts[0];
    };

    const autoSelectedParameter = selectParameter();

    if (autoSelectedParameter && autoSelectedOp) {
        return {
            ...state,
            query: '',
            partialBlock: undefined,
            selectedBlocks: [
                ...state.selectedBlocks,
                {
                    field: action.selected.field,
                    op: autoSelectedOp?.op,
                    parameter: autoSelectedParameter.value,
                },
            ],
        };
    }

    state.inputRef.current?.focus();
    return {
        ...state,
        query: '',
        partialBlock: {
            parameter: action.selected,
            selectedOp: autoSelectedOp,
        },
    };
};
