import { t } from '@lingui/macro';
import { Tag } from '@luminovo/design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { BomItemFormState } from '../../BomItemFormState';
import { ConsignmentButton } from '../../ConsignmentButton';
import { useEmsAndOemNames } from './useEmsAndOemNames';

export const SourcedByController = ({
    submitForm,
    isEditable,
    customerId,
}: {
    submitForm: () => void;
    isEditable: boolean;
    customerId?: string;
}) => {
    const { control, setValue, getValues } = useFormContext<BomItemFormState>();

    return (
        <>
            {isEditable ? (
                <Controller
                    name={'isConsigned'}
                    control={control}
                    render={({ field }): JSX.Element => {
                        return (
                            <ConsignmentButton
                                isConsigned={field.value}
                                setValue={setValue}
                                submitForm={submitForm}
                                customerId={customerId}
                            />
                        );
                    }}
                />
            ) : (
                <SourcedByViewMode isConsigned={getValues('isConsigned')} customerId={customerId} />
            )}
        </>
    );
};

const SourcedByViewMode = ({ isConsigned, customerId }: { isConsigned: boolean; customerId?: string }) => {
    const { emsName, oemName } = useEmsAndOemNames({ customerId });

    return <Tag color="neutral" attention="low" label={t`Sourced by ${isConsigned ? oemName : emsName}`} />;
};
