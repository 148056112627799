import { Trans } from '@lingui/macro';
import { Button, DialogActions, DialogContent, DialogProps, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React from 'react';

interface ErrorDialogProps {
    message: string;
    isOpen: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    dialogProps?: DialogProps;
    onClose?: () => void;
    shouldRefreshPage?: boolean;
}

const refreshPage = () => {
    window.location.reload();
};

export const ErrorDialog = ({
    message,
    isOpen,
    setIsOpen,
    dialogProps,
    onClose,
    shouldRefreshPage = false,
}: ErrorDialogProps) => {
    const closeModal = () => {
        if (setIsOpen) {
            setIsOpen(false);
        }
        if (onClose) {
            onClose();
        }
        if (shouldRefreshPage) {
            refreshPage();
        }
    };

    return (
        <Dialog onClose={closeModal} open={isOpen} {...dialogProps}>
            <DialogContent dividers>
                <Typography gutterBottom>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    <Trans>OK</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
