import { ActorResponseRuntypeDTO } from '@luminovo/http-client';

export function actorToString(actor: ActorResponseRuntypeDTO): String {
    switch (actor.type) {
        case 'User':
            return actor.data ? actor.data.first_name + ' ' + actor.data.last_name : '';
        case 'Machine':
            return 'API';
    }
}

export function isActorDefined(actor: ActorResponseRuntypeDTO): boolean {
    switch (actor.type) {
        case 'User':
            return actor.data !== null;
        case 'Machine':
            return true;
    }
}
