import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    Link,
    SecondaryButton,
    SecondaryIconButton,
    Tag,
    TertiaryButton,
    TertiaryIconButton,
    Text,
    Thumbnail,
    Tooltip,
} from '@luminovo/design-system';
import { OtsFullPart, OTSPartOriginEnum, PartEmissionData, RfqContext, UserType } from '@luminovo/http-client';
import { formatQualification, LifecycleChip } from '@luminovo/sourcing-core';
import {
    DescriptionOutlined as DescriptionOutlinedIcon,
    Edit,
    Info,
    Launch,
    NotificationAddRounded,
    OpenInNewRounded as OpenInNewRoundedIcon,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { ComplianceStatusChip } from '../../../../components/ComplianceStatusChip';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import { ManufacturerStatusChip } from '../../../../components/ManufacturerStatusChip';
import { PartCategoryTag } from '../../../../components/PartCategory';
import { OtsPartComplianceStatusOriginsTooltip } from '../../../../components/PartComplianceView/OtsPartComplianceStatusOriginsTooltip';
import { EmissionsTooltip } from '../../../../components/PartEmissionsView/EmissionsTooltip';
import { formatEmission } from '../../../../components/PartEmissionsView/EmissionsView';
import { OtsPartLifecycleStatusOriginsTooltip } from '../../../../components/PartLifecycleView';
import { OtsPartYteolStatusOriginsTooltip } from '../../../../components/PartYteolView';
import { Protected } from '../../../../permissions/Protected';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useOtsFullPart } from '../../../../resources/part/partHandler';
import { route } from '../../../../utils/routes';
import { useCreateAlertDialog } from '../../Alerts/AlertsForms/AlertModal';
import { PART_CATEGORY_OFF_THE_SHELF } from './OtsPartDialogs/Forms/components/PartCategorySelectControlled';
import { useOtsPartEditDialog } from './OtsPartDialogs/OtsPartEditDialog';

const PartTags: React.FunctionComponent<{
    part: OtsFullPart;
}> = ({ part }) => {
    return (
        <Flexbox gap={8} flexWrap={'wrap'}>
            {part.origin.type === OTSPartOriginEnum.Manual && <Tag color="blue" label={t`Manual`} />}
            {part.origin.type === OTSPartOriginEnum.Import && <Tag color={'blue'} label={t`Imported`} />}
            <PartCategoryTag partCategory={part.part_category?.part_category ?? PART_CATEGORY_OFF_THE_SHELF} />
            {part.qualifications.map((q) => (
                <Tag key={q} color={'green'} label={formatQualification(q)} />
            ))}
        </Flexbox>
    );
};

const DatasheetLink: React.FunctionComponent<{
    datasheetUrl: string | null;
}> = ({ datasheetUrl }) => {
    if (!isPresent(datasheetUrl)) {
        return null;
    }

    return (
        <Link
            attention="high"
            href={datasheetUrl}
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<DescriptionOutlinedIcon />}
        >
            <Trans>Datasheet</Trans>
        </Link>
    );
};

const DetailsSection: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const DetailsLabel: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox justifyContent={'space-between'} gap={24}>
            <Text variant="body" color={colorSystem.neutral[7]} style={{ whiteSpace: 'nowrap' }}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const NoValue: React.FunctionComponent = () => (
    <Tooltip title={t`Unknown`}>
        <Text variant="body" color={colorSystem.neutral[6]}>
            -
        </Text>
    </Tooltip>
);

const ValueText: React.FunctionComponent<{
    value: string | number | null | undefined;
}> = ({ value }) => {
    return (
        <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'end' }}>
            {isPresent(value) ? value : <NoValue />}
        </Text>
    );
};

const DescriptionSection: React.FunctionComponent<{
    part: OtsFullPart;
}> = ({ part }) => {
    const formatedDescription = React.useMemo(() => {
        if (!isPresent(part.description) || !Boolean(part.description)) {
            return (
                <Text variant="body" color={colorSystem.neutral[6]}>
                    <Trans>No description available</Trans>
                </Text>
            );
        }

        const delimiter = '; ';
        const occurrenceCount = (part.description.match(new RegExp(delimiter, 'g')) || []).length;

        if (occurrenceCount < 5) {
            return part.description;
        }

        return part.description.replace(new RegExp(delimiter, 'g'), '\n');
    }, [part.description]);

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Description</Trans>
            </Text>
            <Text variant="body" color={colorSystem.neutral[9]} style={{ whiteSpace: 'pre-line' }}>
                {formatedDescription}
            </Text>
        </Flexbox>
    );
};

const ManufacturerProductLink: React.FunctionComponent<{
    url: string | null;
}> = ({ url }) => {
    if (!isPresent(url)) {
        return null;
    }

    return (
        <Tooltip title={t`Go to manufacturer page`}>
            <TertiaryIconButton
                size="medium"
                onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
                style={{ padding: 0 }}
            >
                <OpenInNewRoundedIcon fontSize="inherit" />
            </TertiaryIconButton>
        </Tooltip>
    );
};

const ComplianceAndLifecyleLink: React.FunctionComponent<{
    partId: string;
    userType: UserType;
}> = ({ partId, userType }) => {
    const history = useHistory();

    if (history.location.pathname.startsWith('/parts/details/') || userType !== UserType.Internal) {
        return <></>;
    }

    return (
        <Tooltip title={t`Go to compliance and lifecycle page`}>
            <TertiaryIconButton
                size="medium"
                onClick={() =>
                    window.open(route('/parts/details/:partId/compliance', { partId }), '_blank', 'noopener noreferrer')
                }
                style={{ padding: 0 }}
            >
                <OpenInNewRoundedIcon fontSize="inherit" />
            </TertiaryIconButton>
        </Tooltip>
    );
};

const MpnAliases: React.FunctionComponent<{
    mpnAliases: string[];
}> = ({ mpnAliases }) => {
    if (mpnAliases.length === 0) {
        return <NoValue />;
    }

    return <ValueText value={mpnAliases.join(', ')} />;
};

const PackageName: React.FunctionComponent<{
    name: string | undefined | null;
}> = ({ name }) => {
    if (!isPresent(name)) {
        return <NoValue />;
    }
    // Metric package names are always appended with 'mm' (e.g. 0201mm)
    const isMetric = name.endsWith('mm');
    return (
        <Flexbox gap={4}>
            <Text variant="body" color={colorSystem.neutral[9]}>
                {isMetric ? name.slice(0, -2) : name}
            </Text>
            <Text variant="body" color={colorSystem.neutral[6]}>
                {isMetric ? t`(metric)` : t`(imperial)`}
            </Text>
        </Flexbox>
    );
};

const IpnLinks: React.FunctionComponent<{
    ipns: string[];
    userType: UserType;
}> = ({ ipns, userType }) => {
    if (ipns.length === 0 || userType !== UserType.Internal) {
        return <NoValue />;
    }

    return (
        <Flexbox gap={8} flexWrap={'wrap'}>
            {ipns.map((ipnId, idx) => (
                <Link
                    attention="high"
                    key={idx}
                    href={route('/parts/components/ipn', {}, { ipnId, q: undefined })}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<OpenInNewRoundedIcon />}
                >
                    {ipnId}
                </Link>
            ))}
        </Flexbox>
    );
};

const OpenPartPageButton: React.FunctionComponent<{
    otsPartId: string;
    userType: UserType;
}> = ({ otsPartId, userType }) => {
    if (userType !== UserType.Internal) {
        return <></>;
    }

    return (
        <Tooltip title={t`Open part details page`} placement="top" arrow>
            <TertiaryButton
                size="small"
                startIcon={<Launch />}
                onClick={() =>
                    window.open(route('/parts/details/:partId', { partId: otsPartId }), '_blank', 'noopener noreferrer')
                }
            >
                <Trans>Open part page</Trans>
            </TertiaryButton>
        </Tooltip>
    );
};

const EditButton: React.FunctionComponent<{
    otsPartId: string;
    rfqContext: RfqContext;
}> = ({ otsPartId, rfqContext }) => {
    const { openDialog } = useOtsPartEditDialog();

    return (
        <Protected requiredPermissions={['edit:ots:part']}>
            <Tooltip title={t`Edit part`} placement="top" arrow>
                <SecondaryIconButton size="small" onClick={() => openDialog({ otsPartId, rfqContext })}>
                    <Edit fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
        </Protected>
    );
};

const CreateAlertButton: React.FunctionComponent<{
    otsPartId: string;
}> = ({ otsPartId }) => {
    const { openDialog } = useCreateAlertDialog();

    return (
        <Protected requiredPermissions={['edit:ots:part']}>
            <Tooltip title={t`Create alert`} placement="top" arrow>
                <SecondaryButton
                    size="small"
                    startIcon={<NotificationAddRounded />}
                    onClick={() => openDialog(otsPartId)}
                >
                    <Trans>Alert</Trans>
                </SecondaryButton>
            </Tooltip>
        </Protected>
    );
};

const EmissionsDetailsLabel: React.FunctionComponent = () => {
    return (
        <Flexbox alignItems="center">
            <Trans>kgCO2e</Trans>
            <EmissionsTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </EmissionsTooltip>
        </Flexbox>
    );
};

export const PartDetailsPanel = ({
    partId,
    rfqContext,
    BackButton,
    showOpenPartPageButton,
}: {
    partId: string;
    rfqContext: RfqContext;
    BackButton: React.ComponentType;
    showOpenPartPageButton: boolean;
}): JSX.Element => {
    const userType = useUserType();
    const { data: part, isLoading } = useOtsFullPart({ partOptionId: partId, rfqContext });

    if (isLoading || !part) {
        return (
            <Flexbox height={'80vh'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection={'column'} gap={24} style={{ backgroundColor: 'inherit' }}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <BackButton />
                <Flexbox gap={12}>
                    {showOpenPartPageButton && <OpenPartPageButton otsPartId={partId} userType={userType} />}
                    <CreateAlertButton otsPartId={partId} />
                    <EditButton otsPartId={partId} rfqContext={rfqContext} />
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={16}>
                <Flexbox gap={8} alignItems={'center'}>
                    {isPresent(part.image_url) && (
                        <Thumbnail
                            src={part.image_url}
                            height="50px"
                            style={{
                                borderRadius: 4,
                                border: `1px solid ${colorSystem.neutral[1]}`,
                                minWidth: '50px',
                                maxWidth: '100px',
                            }}
                        />
                    )}
                    <Flexbox flexDirection={'column'} gap={8}>
                        <Text variant="h2" color={colorSystem.neutral[8]}>
                            {part.mpn}
                        </Text>
                        <Flexbox gap="8px">
                            <ManufacturerStatusChip
                                manufacturerName={part.manufacturer.name}
                                preferenceStatus={part.preference_status}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                <Flexbox justifyContent={'space-between'}>
                    <PartTags part={part} />
                    <DatasheetLink datasheetUrl={part.datasheet_url} />
                </Flexbox>
            </Flexbox>

            <DescriptionSection part={part} />

            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Trans>Part information</Trans>
                        <ManufacturerProductLink url={part.manufacturer_product_url} />
                    </Flexbox>
                }
            >
                <DetailsLabel label={t`Mounting`}>
                    <ValueText value={part.package?.mounting} />
                </DetailsLabel>
                <DetailsLabel label={t`Package`}>
                    <PackageName name={part.package?.name} />
                </DetailsLabel>
                <DetailsLabel label={t`Number of pins`}>
                    <ValueText value={part.package?.number_of_pins} />
                </DetailsLabel>
                <DetailsLabel label={t`MPN aliases`}>
                    <MpnAliases mpnAliases={part.mpn_aliases} />
                </DetailsLabel>
            </DetailsSection>
            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Trans>Compliance & lifecycle</Trans>
                        <ComplianceAndLifecyleLink partId={part.id} userType={userType} />
                    </Flexbox>
                }
            >
                <DetailsLabel label={t`RoHS`}>
                    <OtsPartComplianceStatusOriginsTooltip
                        partId={part.id}
                        name="RoHS"
                        complianceStatus={part.rohs_compliant}
                    >
                        <ComplianceStatusChip status={part.rohs_compliant} />
                    </OtsPartComplianceStatusOriginsTooltip>
                </DetailsLabel>
                <DetailsLabel label={t`REACH`}>
                    <OtsPartComplianceStatusOriginsTooltip
                        partId={part.id}
                        name="REACH"
                        complianceStatus={part.reach_compliant}
                    >
                        <ComplianceStatusChip status={part.reach_compliant} />
                    </OtsPartComplianceStatusOriginsTooltip>
                </DetailsLabel>
                <DetailsLabel label={t`AECQ`}>
                    <OtsPartComplianceStatusOriginsTooltip
                        partId={part.id}
                        name="AECQ"
                        complianceStatus={part.aecq_compliant}
                    >
                        <ComplianceStatusChip status={part.aecq_compliant} />
                    </OtsPartComplianceStatusOriginsTooltip>
                </DetailsLabel>
                <DetailsLabel label={t`Lifecycle`}>
                    <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={part.lifecycle_status}>
                        <LifecycleChip lifecycle={part.lifecycle_status} />
                    </OtsPartLifecycleStatusOriginsTooltip>
                </DetailsLabel>
                <DetailsLabel label={t`Years to end of life`}>
                    <OtsPartYteolStatusOriginsTooltip partId={part.id} lifecycleYteol={part.lifecycle_yteol}>
                        <ValueText value={part.lifecycle_yteol} />
                    </OtsPartYteolStatusOriginsTooltip>
                </DetailsLabel>
            </DetailsSection>
            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Trans>Sustainability</Trans>
                    </Flexbox>
                }
            >
                <DetailsLabel label={<EmissionsDetailsLabel />}>
                    <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'end' }}>
                        <EmissionsData emissionData={part.emissions_data} otsPartId={partId} />
                    </Text>
                </DetailsLabel>
            </DetailsSection>
            <DetailsSection label={t`ERP`}>
                <DetailsLabel label={t`Linked IPNs`}>
                    <IpnLinks ipns={part.ipns} userType={userType} />
                </DetailsLabel>
            </DetailsSection>
        </Flexbox>
    );
};

const EmissionsData = ({ emissionData, otsPartId }: { emissionData: PartEmissionData; otsPartId: string }) => {
    const emissionDataType = emissionData.type;
    if (emissionDataType === 'Available') {
        return <>{formatEmission(emissionData.data.product_phase_gwp_in_kg_co2e)}</>;
    }
    if (emissionDataType === 'NotFetched') {
        return <Trans>Requested</Trans>;
    }
    if (emissionDataType === 'NotAvailable') {
        return <Trans>N/A</Trans>;
    }
    if (emissionDataType === 'NotEnabled') {
        return <RequestEmissionsData otsPartIds={[otsPartId]} />;
    }
    if (emissionDataType === 'Requested') {
        const lastEmissionDataRequestTimestamp = new Date(emissionData.data.requested_at);
        const now = Date.now();
        const diffInMillis = now - lastEmissionDataRequestTimestamp.getTime();
        const diffInHours = diffInMillis / (1000 * 60 * 60);

        if (diffInHours > 1) {
            return <RequestEmissionsData otsPartIds={[otsPartId]} />;
        } else {
            return <Trans>Requested</Trans>;
        }
    }
    assertUnreachable(emissionDataType);
};

export const RequestEmissionsData = ({
    otsPartIds = [],
    genericPartIds = [],
}: {
    otsPartIds?: string[];
    genericPartIds?: string[];
}) => {
    const { mutateAsync: requestEmissionsData } = useHttpMutation('POST /parts/emission-data-requests', {
        snackbarMessage: t`Emissions data requested`,
    });

    const userType = useUserType();
    if (userType === UserType.Customer) {
        return <NoValue />;
    }

    const requestBody = { ots_part_ids: otsPartIds, generic_part_ids: genericPartIds };

    return (
        <TertiaryButton
            size="small"
            disabled={!otsPartIds.length && !genericPartIds.length}
            onClick={() => {
                requestEmissionsData({ requestBody });
            }}
        >
            <Trans>Request</Trans>
        </TertiaryButton>
    );
};
