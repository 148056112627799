import { t } from '@lingui/macro';
import { assertUnreachable, id } from '@luminovo/commons';
import {
    Column,
    DataTable,
    MediumContainer,
    StickyLayout,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { ExpenseConfigurationSummaryDTO, ManufacturingScenarioDTO } from '@luminovo/http-client';
import { CircularProgress, Grid, styled } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import {
    DataRow,
    TemplateInstanceState,
    groupByTemplateInstances,
    useTemplateInstanceState,
} from '../shared/ActivityAndExpenseConfigurationTables';
import { ManufacturingNoResults } from '../shared/manufacturingComponents';
import { ExpenseConfigurationDrawer } from './expenseConfigurationDrawer';
import { generateColumns } from './expensesTableColumns';
import { ExpenseTableRow, ExpenseTableRowData } from './types';

const StyledDefaultContainer = styled(MediumContainer)({
    '& td': {
        borderBottomColor: colorSystem.neutral[2],
    },
    'margin-bottom': 0,
});

interface ExpensesTableProps {
    rows: ExpenseTableRow[];
    sharedContext: TemplateInstanceState;
    manufacturingScenario: ManufacturingScenarioDTO;
    selectedBatchSize?: number;
    setSelectedBatchSize: (batchSize: number) => void;
}

const idExtractor = (expense: ExpenseTableRow): string => {
    const { type, data } = expense;
    switch (type) {
        case 'data':
            return data.expenseConfigurationSummary.expense_configuration_details.expense_configuration_id;
        case 'header':
            return typeof data.templateInstanceId === 'string' ? data.templateInstanceId : data.templateInstanceId.type;
        default:
            assertUnreachable(type);
    }
};

const InnerTable: React.FunctionComponent<ExpensesTableProps> = ({
    rows,
    sharedContext,
    manufacturingScenario,
    selectedBatchSize,
    setSelectedBatchSize,
}: ExpensesTableProps): JSX.Element => {
    const columns: Column<ExpenseTableRow, TemplateInstanceState>[] = generateColumns({
        manufacturingScenarioSummaryId: manufacturingScenario.id,
        manufacturingScenarioBatchSizes: manufacturingScenario.batch_sizes,
        manufacturingScenario,
        rows,
        onSelectBatchSize: setSelectedBatchSize,
        selectedBatchSize,
    });

    const tableState = useDataTableState({
        columns,
        items: rows,
        sharedContext,
        persistenceId: 'expense-configurations-table',
        selectionOptions: { idExtractor },
        paginationOptions: {
            // Hack: We don't want pagination because we are using the index of the DataTable as formulaIndex for the actions button.
            // This is stupied and should be changed.
            showPagination: false,
            defaultRowsPerPage: Number.MAX_SAFE_INTEGER,
        },
    });

    const selectedExpenseConfigurationId = tableState.state.selectedIds[0];
    const isDataRow = (row: ExpenseTableRow): row is DataRow<ExpenseTableRowData> => row.type === 'data';
    const selectedExpenseConfiguration = rows
        .filter(isDataRow)
        .find(
            (row) =>
                row.data.expenseConfigurationSummary.expense_configuration_details.expense_configuration_id ===
                selectedExpenseConfigurationId,
        );

    const handleClearSelection = () => {
        tableState.dispatch({
            type: 'clear-selected-items',
        });
    };
    const handleItemClick = (item: ExpenseTableRow) => {
        const { type, data } = item;
        switch (type) {
            case 'header':
                return; // You cannot select a header
            case 'data':
                const itemsExpenseId =
                    data.expenseConfigurationSummary.expense_configuration_details.expense_configuration_id;
                if (itemsExpenseId === selectedExpenseConfigurationId) return;
                handleClearSelection();
                tableState.dispatch({
                    type: 'select-items',
                    ids: [itemsExpenseId],
                    selected: true,
                });
                break;
            default:
                assertUnreachable(type);
        }
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                height: '100%',
                overflow: 'auto',
            }}
        >
            <Grid
                item
                style={{ overflowX: 'auto' }}
                xs={8}
                id={id('manufacturing_scenario/expenses_table')}
                sx={{
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <DataTable
                    tableState={tableState}
                    onItemClick={handleItemClick}
                    overrides={{
                        Container: StyledDefaultContainer,
                        NoResultsComponent: () => ManufacturingNoResults(t`Please add activities`),
                    }}
                    size={'medium'}
                />
            </Grid>

            <Grid
                item
                xs={4}
                sx={{
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <StickyLayout>
                    <ExpenseConfigurationDrawer
                        expense={selectedExpenseConfiguration?.data.expenseConfigurationSummary}
                        batchSize={selectedBatchSize}
                    />
                </StickyLayout>
            </Grid>
        </Grid>
    );
};

const ExpensesTable: React.FunctionComponent<{ selectedManufacturingScenario: string }> = ({
    selectedManufacturingScenario,
}: {
    selectedManufacturingScenario: string;
}): JSX.Element => {
    const { data, isSuccess } = useHttpQuery('GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary', {
        pathParams: { manufacturingScenarioId: selectedManufacturingScenario },
    });
    const [selectedBatchSize, setSelectedBatchSize] = React.useState<number | undefined>(
        data?.data.manufacturing_scenario.batch_sizes[0],
    );

    const context = useTemplateInstanceState();

    if (isSuccess) {
        const rows = groupByTemplateInstances<ExpenseTableRowData, ExpenseConfigurationSummaryDTO>({
            summaries: data.data.expense_configuration_summaries.items,
            templateInstances: data.data.template_instances,
            getInstanceId: (summary) => summary.expense_configuration_details.template_instance_id ?? undefined,
            context,
            getRank: (summary) => summary.expense_configuration_details.rank,
            createRowData: (summary, position) => ({
                expenseConfigurationSummary: summary,
                manufacturingAssemblyDetails: data.data.manufacturing_assembly_details,
                position,
            }),
        });

        return (
            <InnerTable
                selectedBatchSize={selectedBatchSize}
                setSelectedBatchSize={setSelectedBatchSize}
                manufacturingScenario={data.data.manufacturing_scenario}
                rows={rows}
                sharedContext={context}
            />
        );
    }

    return <CircularProgress />;
};

export default ExpensesTable;
