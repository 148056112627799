import * as r from 'runtypes';

const IncompleteRuntype = r.Literal('Incomplete');
const ResultTypeMismatchRuntype = r.Record({ type: r.Literal('ResultTypeMismatch'), details: r.String });
const UnableToConvertInputToFloatRuntype = r.Record({
    type: r.Literal('UnableToConvertInputToFloat'),
    details: r.Record({ name: r.String }),
});

const LexerErrorKindRuntype = r.Record({
    type: r.Literal('InvalidCharacter'),
    details: r.String,
});

/* eslint-disable camelcase */
export type ErrorSpan = r.Static<typeof SpanRuntype>;
const SpanRuntype = r.Record({
    start_line: r.Number,
    start_column: r.Number,
    end_line: r.Number,
    end_column: r.Number,
});
/* eslint-enable camelcase */

export type LexerErrorDTO = r.Static<typeof LexerErrorRuntype>;
const LexerErrorRuntype = r.Record({
    type: r.Literal('LexerError'),
    details: r.Record({ kind: LexerErrorKindRuntype, span: SpanRuntype }),
});

export type TokenType = r.Static<typeof TokenRuntypes>;
const TokenRuntypes = r.Union(
    r.Literal('If'),
    r.Literal('ElseIf'),
    r.Literal('Else'),
    r.Literal('Plus'),
    r.Literal('Minus'),
    r.Literal('Divide'),
    r.Literal('Multiply'),
    r.Literal('And'),
    r.Literal('Or'),
    r.Literal('Equal'),
    r.Literal('NotEqual'),
    r.Literal('GreaterThanOrEqual'),
    r.Literal('LessThanOrEqual'),
    r.Literal('GreaterThan'),
    r.Literal('LessThan'),
    r.Literal('Comma'),
    r.Literal('LParen'),
    r.Literal('RParen'),
    r.Literal('LBrace'),
    r.Literal('RBrace'),
    r.Literal('SingleLineComment'),
    r.Literal('Eof'),
    r.Literal('Blank'),
);

export type SyntaxErrorTokenKind = r.Static<typeof TokenRuntype>;
const TokenRuntypeWithDetailsOfStringType = r.Record({
    token: r.Union(r.Literal('Ident'), r.Literal('String')),
    details: r.String,
});
const TokenRuntypeWithDetailsOfBooleanType = r.Record({
    token: r.Union(r.Literal('Boolean')),
    details: r.Boolean,
});

const TokenRuntypeWithDetailsOfNumberType = r.Record({
    token: r.Union(r.Literal('Float')),
    details: r.Number,
});

const TokenRuntype = r.Record({
    token: TokenRuntypes,
});

export type SyntaxErrorDetails = r.Static<typeof SyntaxErrorDataTypeRuntype>;
const SyntaxErrorDataTypeRuntype = r.Union(
    TokenRuntypeWithDetailsOfBooleanType,
    TokenRuntypeWithDetailsOfStringType,
    TokenRuntypeWithDetailsOfNumberType,
    TokenRuntype,
);

const ExpectedRuntype = r.Record({
    type: r.Literal('Expected'),
    details: r.Record({
        type: r.Literal('Token'),
        details: SyntaxErrorDataTypeRuntype,
    }),
});

const ExpectedAnyOfRuntype = r.Record({
    type: r.Literal('ExpectedAnyOf'),
    details: r.Array(
        r.Record({
            type: r.Literal('Token'),
            details: SyntaxErrorDataTypeRuntype,
        }),
    ),
});

const UnexpectedRuntype = r.Record({
    type: r.Literal('Unexpected'),
    details: r.Record({
        type: r.Literal('Token'),
        details: SyntaxErrorDataTypeRuntype,
    }),
});

export type SyntaxErrorKind = r.Static<typeof SyntaxErrorKindRuntype>;
const SyntaxErrorKindRuntype = r.Union(ExpectedRuntype, ExpectedAnyOfRuntype, UnexpectedRuntype);

export type SyntaxErrorDTO = r.Static<typeof SyntaxErrorRuntype>;
export const SyntaxErrorRuntype = r.Record({
    type: r.Literal('SyntaxError'),
    details: r.Record({
        kind: SyntaxErrorKindRuntype,
        span: SpanRuntype,
    }),
});

export type UndefinedIdentifierKind = r.Static<typeof UndefinedIdentifierRuntype>;
const UndefinedIdentifierRuntype = r.Record({
    type: r.Literal('UndefinedIdentifier'),
    details: r.String,
});
export type ReservedKeywordKind = r.Static<typeof ReservedKeywordRuntype>;

const ReservedKeywordRuntype = r.Record({
    type: r.Literal('ReservedKeyword'),
    details: r.String,
});

export type RuntimeErrorKind = r.Static<typeof RuntimeErrorKindRuntype>;
const RuntimeErrorKindRuntype = r.Union(
    r.Record({
        type: r.Union(
            r.Literal('ExpectedBoolean'),
            r.Literal('ExpectedFloat'),
            r.Literal('ExpectedCall'),
            r.Literal('ArgumentMissing'),
            r.Literal('DivideByZero'),
            r.Literal('TooManyArguments'),
            r.Literal('ExpectedUnsignedInteger'),
            r.Literal('OutOfI32Range'),
        ),
    }),
    UndefinedIdentifierRuntype,
    ReservedKeywordRuntype,
);

export type RuntimeErrorDTO = r.Static<typeof RuntimeErrorRuntype>;
const RuntimeErrorRuntype = r.Record({
    kind: RuntimeErrorKindRuntype,
    span: SpanRuntype,
});

export type ValidationErrorDTO = r.Static<typeof ValidationErrorRuntype>;
const ValidationErrorRuntype = r.Record({
    type: r.Literal('ValidationError'),
    details: RuntimeErrorKindRuntype,
});

export type RuntimeTestErrorDTO = r.Static<typeof RuntimeTestErrorRuntype>;
const RuntimeTestErrorRuntype = r.Record({
    type: r.Literal('RuntimeError'),
    details: RuntimeErrorRuntype,
});

export type FormulaErrorDTO = r.Static<typeof FormulaScriptErrorRuntype>;
export const FormulaScriptErrorRuntype = r.Union(
    ResultTypeMismatchRuntype,
    UnableToConvertInputToFloatRuntype,
    IncompleteRuntype,
    LexerErrorRuntype,
    SyntaxErrorRuntype,
    RuntimeErrorRuntype,
    ValidationErrorRuntype,
    RuntimeTestErrorRuntype,
);
