import { t } from '@lingui/macro';
import { BannerCard } from './BannerCard';
import { GREEN_GRADIENT } from './gradients';

export const BannerCardOrderInProcurement = (): JSX.Element => {
    return (
        <BannerCard
            background={GREEN_GRADIENT}
            icon="📨"
            title={t`Order in procurement`}
            description={t`Your order has been successfully placed, we are processing your request.`}
        />
    );
};
