import { t } from '@lingui/macro';
import { isPresent } from '../typingUtils';
import { Currency, FormattingType, formatCurrency, formattingOptions } from './formatCurrency';
import { getOrCreateNumberFormatter } from './getOrCreateNumberFormat';

export type MonetaryValue = {
    amount: string;
    currency: Currency;
};
type ExchangedMonetaryValueBackend = {
    converted_mv: MonetaryValue;
    original_mv: MonetaryValue;
};

export function formatMonetaryValue(
    monetaryValue: MonetaryValue | ExchangedMonetaryValueBackend | null | undefined,
    formattingType: FormattingType = 'default',
    options?: { ifAbsent?: string; alwaysShowSign?: boolean },
): string {
    if (!monetaryValue) {
        return options?.ifAbsent ?? t`Unknown`;
    }

    const { amount, currency } = 'converted_mv' in monetaryValue ? monetaryValue.converted_mv : monetaryValue;
    const signum = sign(amount, options?.alwaysShowSign);

    return `${signum}${formatCurrency(amount, currency, formattingType)}`;
}

function sign(amount: string, alwaysShowSign: boolean = false): string {
    if (!alwaysShowSign) {
        return '';
    }

    const num = Number(amount);
    if (num > 0) {
        return '+';
    }
    return '';
}

export function extractAmountFromMonetaryValue(
    monetaryValue: MonetaryValue | null | undefined,
    options?: { ifAbsent?: number },
): number {
    if (!isPresent(monetaryValue)) {
        return options?.ifAbsent ?? 0;
    }

    return Number(monetaryValue?.amount);
}

/**
 * Helper function to convert a record into a MonetaryValue. This shouldn't be used normally but sometime the backend returns a different structure.
 */
export function convertIntoMonetaryValue(value: Record<string, string> | null | undefined): MonetaryValue | null {
    if (!isPresent(value)) {
        return null;
    }

    const entries = Object.entries(value);
    if (entries.length === 0) {
        return null;
    }

    const [currency, amount] = entries[0];

    return {
        amount,
        currency: currency as Currency,
    };
}

export function isMonetaryValue(value: unknown): value is MonetaryValue {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    const { amount, currency } = value as MonetaryValue;

    if (typeof amount !== 'string' || amount.trim() === '') {
        return false;
    }

    if (!isValidNumber(amount)) {
        return false;
    }

    if (!Object.values(Currency).includes(currency)) {
        return false;
    }

    return true;
}

function isValidNumber(value: string): boolean {
    return !isNaN(parseFloat(value)) && isFinite(Number(value));
}

/**
 * Formats the monetary value by triming the decimals place.
 */
export function formatAmount(value: MonetaryValue | null, formattingType: FormattingType): MonetaryValue | null {
    if (!value) {
        return null;
    }

    const amount = extractAmountFromMonetaryValue(value);
    const currency = value.currency;

    const { minimumFractionDigits } = formattingOptions[formattingType];
    const formattedAmount = getOrCreateNumberFormatter('en', { minimumFractionDigits }).format(Number(amount));

    return {
        amount: formattedAmount,
        currency,
    };
}
