import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { SecondaryButton, SecondaryIconButton, colorSystem } from '@luminovo/design-system';
import { CommentEntity, UserType } from '@luminovo/http-client';
import { ChatRounded } from '@mui/icons-material';
import { Badge, Tooltip, styled } from '@mui/material';
import { useComments } from '../../resources/comments/commentsHandler';
import { useCurrentUserDetailsContext, useUserType } from '../contexts/CurrentUserDetailsContext';

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        color: colorSystem.neutral.white,
        backgroundColor: colorSystem.red[6],
    },
});

export function ViewCommentsButton({
    disabled = false,
    eventEntity,
    onClick,
    iconButtonOnly = false,
    size = 'medium',
}: {
    eventEntity: CommentEntity;
    onClick: () => void;
    disabled?: boolean;
    iconButtonOnly?: boolean;
    size?: 'small' | 'medium' | 'large';
}) {
    const orgName = useCurrentUserDetailsContext().organization.name;
    const userType = useUserType();

    const { data: comments = [], isLoading } = useComments(eventEntity);
    const hasComments = comments.length !== 0;
    const unresolvedCommentsCount = comments.filter((c) => !isPresent(c.resolved_at)).length;

    const getTitle = () => {
        switch (userType) {
            case UserType.Internal:
                return hasComments ? t`View comments` : t`Add comment`;
            case UserType.Customer:
            case UserType.Supplier:
                return t`Contact ${orgName}`;
        }
    };

    if (iconButtonOnly) {
        return (
            <Tooltip title={getTitle()}>
                <StyledBadge badgeContent={unresolvedCommentsCount} showZero={false}>
                    <SecondaryIconButton onClick={onClick} size={size} disabled={isLoading || disabled}>
                        <ChatRounded fontSize={'inherit'} />
                    </SecondaryIconButton>
                </StyledBadge>
            </Tooltip>
        );
    }

    return (
        <StyledBadge badgeContent={unresolvedCommentsCount} showZero={false}>
            <SecondaryButton onClick={onClick} size={size} disabled={isLoading || disabled} startIcon={<ChatRounded />}>
                {getTitle()}
            </SecondaryButton>
        </StyledBadge>
    );
}
