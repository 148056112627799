import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parseNo, parseYes } from './parseYesNo';

// general idea
// parse the keyword and yes / no
// if just the keyword is given... later in the merge rule
//      try linking to yes or no within short distance
//      if only keyword is given and no related value => then assume the keyword present means yes

const eTestKeywords = ['e-test', 'electrical checks', 'electrical test', 'elektrisch', 'Elektr.'];
export function parseETest(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(eTestKeywords);
    const yes = parseYes(str);
    const no = parseNo(str);
    if (regex.test(str)) {
        return {
            attr: 'eTest',
            value: no === true ? false : true,
            confidence: yes || no ? 1 : 0.1,
        } as PcbAttribute;
    }
    return undefined;
}

// Impedance controlled
// impedence control no
// impedance checked
// Impedanzkontrolle
const impedanceTestedKeyword = ['impedance', 'impedence', 'Impedanz', 'Impedanzkontrolle'];
export function parseImpedanceTested(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(impedanceTestedKeyword);
    const yes = parseYes(str);
    const no = parseNo(str);
    if (regex.test(str)) {
        return {
            attr: 'impedanceTested',
            value: no === true ? false : true,
            confidence: yes || no ? 1 : 0.1,
        } as PcbAttribute;
    }
    return undefined;
}

const ulMarkingKeywords = [
    'UL-?\\s?(:?sign|marking|kennzeichen|kennzeichnung|logo|marked|description|specified & marked)',
];
export function parseULMarking(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(ulMarkingKeywords);
    const yes = parseYes(str);
    const no = parseNo(str);
    if (regex.test(str)) {
        return {
            attr: 'ulMarkingType',
            value: no === true ? 'no-marking' : 'default-marking',
            confidence: yes || no ? 1 : 0.1,
        } as PcbAttribute;
    }
    return undefined;
}

// FR4 (ROHS, UL)
const ulLayerStackKeywords = ['UL-?\\s?(:?specified|zertifiziert|konform)'];
export function parseULLayerStack(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(ulLayerStackKeywords);
    const yes = parseYes(str);
    const no = parseNo(str);
    if (regex.test(str)) {
        return {
            attr: 'ulLayerStack',
            value: no === true ? false : true,
            confidence: yes || no ? 1 : 0.1,
        } as PcbAttribute;
    }
    return undefined;
}

// :selected: Herstellerkennzeichen
// :selected: Produktionsdatum
// date code
// manufacturer indentification
