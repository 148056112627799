import React from 'react';
import { ViewContext } from '../../../../modules/Bom/components/ModuleTableData';
import { PcbRedirectTab } from '../../../../modules/Pcb/PcbRedirectPage';
import { UrlParams } from '../../../../utils/routes';

export default function AssemblyOverviewPcbRedirectPage({
    queryParams,
    pathParams,
}: UrlParams<'/assemblies/:assemblyId/pcb'>) {
    const { assemblyId } = pathParams;
    const { rfqId } = queryParams;
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId,
        };
    }, [rfqId]);
    return (
        <PcbRedirectTab
            rfqId={rfqId}
            assemblyId={assemblyId}
            viewContext={viewContext}
            currentParentAssemblyId={undefined}
        />
    );
}
