import { UseComponentsSearchStateResult } from './ErpParametricSearchFunctions';

export function buildFiltersFromSearchState(searchState: UseComponentsSearchStateResult) {
    return searchState.state.selectedBlocks.map((block) => {
        if (typeof block.parameter === 'string') {
            block.parameter = block.parameter.trim();
        }
        if (block.op === '≥') {
            return { field: block.field, operator: 'inNumberRange', parameter: [Number(block.parameter), null] };
        }
        if (block.op === '≤') {
            return { field: block.field, operator: 'inNumberRange', parameter: [null, Number(block.parameter)] };
        }
        // TODO: inNumberRange is inclusive I think
        if (block.op === '>') {
            return { field: block.field, operator: 'inNumberRange', parameter: [Number(block.parameter), null] };
        }
        // TODO: inNumberRange is inclusive I think
        if (block.op === '<') {
            return { field: block.field, operator: 'inNumberRange', parameter: [null, Number(block.parameter)] };
        }
        if (block.op === '=') {
            return {
                field: block.field,
                operator: 'inNumberRange',
                parameter: [Number(block.parameter), Number(block.parameter)],
            };
        }
        if (block.op === 'is') {
            return { field: block.field, operator: 'arrIncludesSome', parameter: [block.parameter] };
        }
        // TODO: this is not currently supported on the backend
        if (block.op === 'is not') {
            return { field: block.field, operator: 'IsNot', parameter: block.parameter };
        }
        if (block.op === 'starts-with') {
            return { field: block.field, operator: 'includesString', parameter: [block.parameter] };
        }
        return { field: block.field, operator: block.op, parameter: block.parameter };
    });
}
