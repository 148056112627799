import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { Capability } from '../../../../../../resources/pcb/utils/usePcbCapabilities';
import { useExtractedPcbSpecification } from './useExtractedPcbSpecification';

/**
 * Returns the capabilities that have been extracted from the PDF specification
 */
export const useExtractedPcbCapabilities = ({
    pcb,
    pcbCapabilities,
}: {
    pcb: PCBV2;
    pcbCapabilities: Capability[];
}): Capability[] => {
    const { data: extracted } = useExtractedPcbSpecification({ pcbId: pcb.id });
    const extractedCapability = React.useMemo(() => {
        return extracted !== undefined ? extracted.extracted : {};
    }, [extracted]);
    const extractedFile = extracted?.file;

    // Not using the id because it was introduced more recently, and there might
    // be some files without an id
    const pcbFile = (pcb.files ?? []).find((file) => file.name === extractedFile?.name);

    return React.useMemo(() => {
        return pcbCapabilities
            .map((c): Capability => {
                const ec = extractedCapability[c.capabilityName];
                return {
                    ...c,
                    //@ts-ignore
                    extractedFromPdf:
                        ec === undefined || pcbFile === undefined
                            ? undefined
                            : {
                                  value:
                                      // If the capabilty has a default unit, we need to return the value with the unit
                                      'defaultUnit' in c.restrictions
                                          ? {
                                                value: ec.value,
                                                unit: c.restrictions.defaultUnit,
                                            }
                                          : ec.value,
                                  file: pcbFile,
                                  regions: ec.regions,
                              },
                };
            })
            .filter((c) => c.extractedFromPdf !== undefined);
    }, [extractedCapability, pcbFile, pcbCapabilities]);
};
