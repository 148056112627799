import { t } from '@lingui/macro';
import { ColumnMappingAttemptDTO } from '@luminovo/http-client';
import { CSVContents, CSVLineContent, CSVLineHeader, CSVLines } from './types';

type ConversionError = {
    type: 'error';
    message: string;
};

type ConversionSuccess = {
    type: 'success';
    result: CSVLines;
};

export type ConversionResult = ConversionError | ConversionSuccess;

export const convertCSVContentsToCSVLines = (
    csvContents: CSVContents,
    columnMappingAttempt: ColumnMappingAttemptDTO,
): ConversionResult => {
    if (csvContents.length === 0) {
        return {
            type: 'error',
            message: t`CSV file is empty`,
        };
    }

    if (columnMappingAttempt.type === 'WithHeader') {
        const firstLine: CSVLineHeader = {
            lineNumber: 0,
            rawOriginalLine: csvContents[0].join(','),
            type: 'header',
            content: csvContents[0],
        };

        const restLines: CSVLineContent[] = csvContents.slice(1).map((line, index) => {
            return {
                lineNumber: index + 1,
                rawOriginalLine: line.join(','),
                type: 'content',
                content: line,
            };
        });

        return {
            type: 'success',
            result: {
                type: 'with-header',
                lines: [firstLine, ...restLines],
            },
        };
    } else {
        const restLines: CSVLineContent[] = csvContents.map((line, index) => {
            return {
                lineNumber: index,
                rawOriginalLine: line.join(','),
                type: 'content',
                content: line,
            };
        });

        return {
            type: 'success',
            result: {
                type: 'without-header',
                lines: [...restLines],
            },
        };
    }
};

export const convertCSVLinesToCSVContent = (csvContents: CSVLines): CSVContents => {
    return csvContents.lines.map((line) => line.content);
};
