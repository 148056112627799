import { Trans } from '@lingui/macro';
import { formatDecimal, uniqueBy } from '@luminovo/commons';
import { Box, Divider, List, ListItem, ListItemText, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Checkbox } from '../../Checkbox';
import { Flexbox } from '../../Flexbox';
import { Highlight } from '../../Highlight';
import { Text } from '../../Text';
import { TertiaryButton } from '../../buttons';
import { Argument, OperatorIn, ParametricSearchState } from '../types';
import { formatOperator } from '../util/formatOperator';

function getTitle<T, TAutocompleteState>(state: ParametricSearchState<T, TAutocompleteState>) {
    const { partialBlock } = state;
    const selectedOp = partialBlock?.selectedOp;
    const parameter = partialBlock?.parameter;

    const formattedOperator = selectedOp ? formatOperator(selectedOp.op) : '';
    const operatorLabel = selectedOp?.label ?? formattedOperator;
    const parameterLabel = parameter?.label ?? parameter?.field;

    return `${parameterLabel} ${operatorLabel}`;
}

export function MenuOperatorIn<T, TAutocompleteState>({
    state,
    operator,
    onSelect,
    onCancel,
    autocompleteState,
}: {
    state: ParametricSearchState<T, TAutocompleteState>;
    operator: OperatorIn<T, TAutocompleteState>;
    onSelect: (argument: Argument, label?: string | JSX.Element) => void;
    onCancel: () => void;
    autocompleteState?: TAutocompleteState;
}): JSX.Element {
    const lowercasedQuery = state.query.toLowerCase();
    const [selection, setSelection] = React.useState<string[]>([]);

    const filtered = uniqueBy(operator.options(autocompleteState), 'value').filter((opt) =>
        (opt.label ?? opt.value).toLowerCase().includes(lowercasedQuery),
    );

    return (
        <Container
            data-testid="MenuOperatorNumber"
            flexDirection={'column'}
            onKeyUp={(e) => {
                if (e.key === 'Enter' && selection.length > 0) {
                    onSelect(selection);
                }
                if (e.key === 'Escape') {
                    onCancel();
                }
            }}
        >
            <Flexbox justifyContent="space-between" alignItems="center" paddingX={'12px'}>
                <Text variant="h5">{getTitle(state)}</Text>
                <TertiaryButton
                    disabled={selection.length === 0}
                    onClick={() => onSelect(selection)}
                    size="medium"
                    style={{ height: '16px', marginRight: '-12px' }}
                >
                    <Trans>Apply</Trans>
                </TertiaryButton>
            </Flexbox>
            <Divider style={{ margin: '4px' }} />
            <List style={{ maxHeight: '50vh', overflowY: 'auto' }} disablePadding dense>
                {filtered.map((opt) => {
                    const label = String(opt.label ?? opt.value);
                    const isSelected = selection.includes(opt.value);

                    return (
                        <ListItem
                            key={label}
                            style={{
                                background: isSelected ? colorSystem.neutral[2] : undefined,
                            }}
                            button
                            onClick={() =>
                                setSelection((prev) =>
                                    isSelected ? prev.filter((v) => v !== opt.value) : [...prev, opt.value],
                                )
                            }
                        >
                            <ListItemText
                                primaryTypographyProps={{
                                    style: { display: 'flex', gap: 8, paddingRight: 8, alignItems: 'baseline' },
                                }}
                            >
                                <Flexbox alignItems="center" gap={8}>
                                    <Checkbox checked={isSelected} size={'small'} />
                                    <Text variant="body-small" color={colorSystem.neutral[8]} style={{ flexGrow: 1 }}>
                                        <Highlight
                                            label={label}
                                            matcher={[state.query]}
                                            overrides={{ Highlighter: Bold }}
                                        />
                                    </Text>
                                    {(opt.count ?? 0) > 1 && (
                                        <Text variant="body-small">{formatDecimal(opt.count ?? 0)}</Text>
                                    )}
                                </Flexbox>
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </Container>
    );
}

function Bold(props: React.PropsWithChildren<{}>) {
    return <b {...props} />;
}

const Container = styled(Box)({
    gap: 8,
    padding: '12px 0px 16px 0px',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    width: '320px',
});
