import React from 'react';
import { isEqual } from '../utils';

/**
 * @deprecated it's broken in React 18
 */
export function useMemoCompare<TResult, TArgs>(
    fn: () => TResult,
    args: TArgs,
    equals: (a: TArgs, b: TArgs) => boolean = isEqual,
): TResult {
    const cachedValueRef = React.useRef<{ args: TArgs; result: TResult }>();
    const { current } = cachedValueRef;

    const isRecalculationNeeded = current === undefined || !equals(current.args, args);

    if (isRecalculationNeeded) {
        const newResult = fn();
        cachedValueRef.current = { args, result: newResult };
        return newResult;
    }

    return current.result;
}
