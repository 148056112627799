import { ExtractResponseBody, QuantityUnit, SolutionConfigurationDTO } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../http/useHttpQuery';

export function useSolutionsOfSolutionConfiguration(
    solutionConfiguration?: SolutionConfigurationDTO,
): UseQueryResult<ExtractResponseBody<'GET /solutions'>> {
    return useHttpQuery(
        'GET /solutions',
        {
            queryParams: solutionConfiguration
                ? {
                      /* eslint-disable-next-line camelcase */
                      solution_conf: solutionConfiguration.id,
                      quantity: solutionConfiguration.aggregated_quantity.quantity,
                      unit: solutionConfiguration.aggregated_quantity.unit,
                  }
                : // eslint-disable-next-line camelcase
                  { quantity: 0, solution_conf: '', unit: QuantityUnit.Pieces },
        },
        {
            enabled: Boolean(solutionConfiguration),
            staleTime: 10_000,
            suspense: true,
        },
    );
}
