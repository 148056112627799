import { t } from '@lingui/macro';
import { Flexbox } from '@luminovo/design-system';
import React from 'react';
import { ManufacturingDatabaseActions } from '../../../components/ManufacturingDatabase/ManufacturingDatabasePage';
import { ManufacturingDatabaseSidebar } from '../../../components/ManufacturingDatabase/ManufacturingDatabaseSidebar';
import { PageLayoutCollapsibleSidebar } from '../../../components/PageLayoutCollapsibleSidebar';
import { route } from '../../../utils/routes';
import ActivitiesDatabaseTable from './ActivitiesDatabaseTable';

const ActivitiesViewPage = (): JSX.Element => {
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    return (
        <PageLayoutCollapsibleSidebar sidebar={<ManufacturingDatabaseSidebar />} layoutVariant="fullWidth">
            <Flexbox flexDirection={'column'} paddingX={'32px'}>
                <ManufacturingDatabaseActions
                    buttonText={t`Add activity`}
                    path={route('/manufacturing/add-activity')}
                    titleText={t`Activities`}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    descriptionText={t`Activities are all production processes that involve internal resources (e.g. Pick and Place or AOI Inspection).`}
                />
                <Flexbox paddingBottom={'72px'}>
                    <ActivitiesDatabaseTable query={searchQuery} />
                </Flexbox>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
};

export default ActivitiesViewPage;
