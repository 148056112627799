import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation, HistoryRecordFlagDTO } from '@luminovo/http-client';
import useSourcedByLabel from '../../DesignItem/hooks/useSourcedByLabel';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, TimelineAddIcon, TimelineContentContainer, TimelineEditIcon, TimelineText } from './Utils';

type Props = {
    flag: HistoryRecordFlagDTO;
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

function useSourcedByAction(operation: HistoryOperation, actor: ActorResponseRuntypeDTO, flag: HistoryRecordFlagDTO) {
    const sourcedByLabel = useSourcedByLabel(flag.marked);
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <TimelineText>
                    <Trans>
                        Sourced by manually added as <strong>{sourcedByLabel}</strong>
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.AutoAdd:
            return (
                <TimelineText>
                    <Trans>
                        Sourced by automatically automatically added as <strong>{sourcedByLabel}</strong>
                    </Trans>
                </TimelineText>
            );
        case HistoryOperation.ManualUpdate:
            return (
                <TimelineText>
                    <Trans>
                        Sourced by changed to <strong>{sourcedByLabel}</strong>
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
}
export default function SourcedBy({ actor, flag, operation }: Props) {
    const action = useSourcedByAction(operation, actor, flag);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}

export function SourcedByTimelineIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualUpdate) return <TimelineEditIcon />;
    return <TimelineAddIcon />;
}
