import { t } from '@lingui/macro';
import { BomItemIssue } from '@luminovo/http-client';
import { assertUnreachable } from '../../../../utils/typingUtils';

export function bomItemIssueMessage(issue: BomItemIssue) {
    switch (issue) {
        case BomItemIssue.Compliance:
            return t`Compliance issues`;
        case BomItemIssue.DesignatorAndQuantityUnclear:
            return t`Designator and quantity unclear`;
        case BomItemIssue.DoNotPlace:
            return t`Do not place`;
        case BomItemIssue.InsufficientStock:
            return t`Insufficient stock`;
        case BomItemIssue.Lifecycle:
            return t`Lifecycle issue`;
        case BomItemIssue.MissingPartData:
            return t`Missing part data`;
        case BomItemIssue.MountingMissing:
            return t`Mounting missing`;
        case BomItemIssue.NoPartOptionsApproved:
            return t`No part options approved`;
        case BomItemIssue.PackageMismatch:
            return t`Inconsistent mounting, package or number of pins`;
        case BomItemIssue.PackageNameOnlyMismatch:
            return t`Inconsistent package name`;
        case BomItemIssue.PackageNameMissing:
            return t`Package missing`;
        case BomItemIssue.PinsMissing:
            return t`Pins missing`;
        default:
            assertUnreachable(issue);
    }
}

export const ManufacturingIssues = [
    BomItemIssue.MissingPartData,
    BomItemIssue.MountingMissing,
    BomItemIssue.PackageMismatch,
    BomItemIssue.PackageNameOnlyMismatch,
    BomItemIssue.PinsMissing,
];
