import { Trans, t } from '@lingui/macro';
import { Flexbox, Message, Text, colorSystem } from '@luminovo/design-system';
import { Link } from '@mui/material';
import { route } from '../../../../../../utils/routes';

export const EmptyTemplateSelection = (): JSX.Element => {
    return (
        <Flexbox flexDirection="column" padding="32px" gap="8px">
            <Message
                variant="primary"
                attention="high"
                size="large"
                message={
                    <Text>
                        <Trans>
                            You can only add active templates. Activate additional templates in{' '}
                            <Link style={{ cursor: 'pointer' }} href={route('/manufacturing/template')}>
                                settings
                            </Link>{' '}
                            if needed.
                        </Trans>
                    </Text>
                }
                title={t`Start by adding templates from the left column`}
            />
            <Flexbox
                justifyContent="center"
                alignItems="center"
                style={{
                    borderRadius: '8px',
                    border: `1px dashed ${colorSystem.neutral[6]}`,
                    height: '45px',
                }}
            >
                <Text variant={'h5'}>
                    <Trans>Add first template or proceed further by saving selection</Trans>
                </Text>
            </Flexbox>
        </Flexbox>
    );
};
