import * as r from 'runtypes';
import { BACKEND_BASE, isProductionEnvironment } from '../../const';
import { printFormattedRuntypeError } from '../../utils/runtypeUtils';
import { ErrorCode, ErrorCodeRuntype } from '../errorCodes';
import { HttpError } from './HttpError';
import { NetworkError } from './NetworkError';
import { TQueryParamValue } from './endpoint';
import { endpointRegistry } from './endpointRegistry';
import { logToExternalErrorHandlers } from './logToExternalErrorHandlers';

type Stringable = string | number | boolean;

export type EndpointRegistry = typeof endpointRegistry;

export type RegisteredHttpEndpoint = keyof typeof endpointRegistry;

/**
 * Utility function that returns the type of the requestBody for a given endpoint.
 */
export type ExtractRequestBody<T extends RegisteredHttpEndpoint> = r.Static<EndpointRegistry[T]['requestBody']>;

/**
 * Utility function that returns the type of the requestBody for a given endpoint.
 */
export type ExtractResponseBody<T extends RegisteredHttpEndpoint> = r.Static<EndpointRegistry[T]['responseBody']>;

/**
 * Returns the endpoints that should be **invalidated** by the given endpoint.
 */
export function extractEndpointsToBeInvalidated(endpoint: RegisteredHttpEndpoint): RegisteredHttpEndpoint[] {
    return endpointRegistry[endpoint].invalidates ?? [];
}

/** Fetch response including the HTTP method.
 *
 * By default, `fetch(...)` doesn't include the HTTP method in the response
 * it returns.
 */
export type ResponseWithMethod = Response & { method?: string };

/**
 * Returns the endpoints that should be **removed** by the given endpoint.
 */
export function extractEndpointsToBeRemoved(endpoint: RegisteredHttpEndpoint): RegisteredHttpEndpoint[] {
    return endpointRegistry[endpoint].removes ?? [];
}

export function parseEndpoint(endpoint: string | RegisteredHttpEndpoint): { method: string; pathTemplate: string } {
    const [method, pathTemplate, ...rest] = endpoint.split(' ');
    const httpMethods = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'CONNECT', 'OPTIONS', 'TRACE', 'PATCH'];
    if (rest.length > 0 || !httpMethods.includes(method)) {
        throw new Error(
            `Malformed endpoint: ${endpoint}. \nEndpoints must begin with an HTTP method followed by a single space and a path template. Example: 'GET /path/to/resource'`,
        );
    }

    return {
        method,
        pathTemplate,
    };
}

export function buildUrl({
    endpoint,
    rootUrl,
    pathParams,
    queryParams,
}: {
    endpoint: string | RegisteredHttpEndpoint;
    rootUrl: string;
    pathParams: Record<string, Stringable>;
    queryParams: Record<string, TQueryParamValue>;
}): { method: string; url: string } {
    const { method, pathTemplate } = parseEndpoint(endpoint);

    let pathPart: string = pathTemplate;
    for (const [k, val] of Object.entries(pathParams)) {
        const toBeReplaced = `:${k}`;
        if (!pathPart.includes(toBeReplaced)) {
            throw new Error(
                `Cannot inject ${JSON.stringify(pathParams)} into path template "${pathTemplate}", ` +
                    `which does not contain "${toBeReplaced}" as a substring.`,
            );
        }
        pathPart = pathPart.replace(toBeReplaced, encodeURIComponent(val + ''));
    }

    const searchParams = new URLSearchParams();
    for (const [k, val] of Object.entries(queryParams)) {
        searchParams.set(k, val + '');
    }
    const numberOfSearchParams = Array.from(searchParams.keys()).length;
    const searchPart = numberOfSearchParams === 0 ? '' : `?${searchParams.toString()}`;

    return {
        method: method,
        url: rootUrl + pathPart + searchPart,
    };
}

function assertResponseBodyShape({
    runtype,
    endpoint,
    url,
    method,
    requestBody,
    responseBody,
}: {
    runtype: r.Runtype;
    endpoint: string;
    url: string;
    method: string;
    requestBody: unknown;
    responseBody: unknown;
}) {
    try {
        if (!isProductionEnvironment()) {
            runtype.check(responseBody);
        }
    } catch (runtypeError) {
        if (!isProductionEnvironment()) {
            const msg = composeRuntypeAssertionErrorMessage({
                endpoint,
                url,
                method,
                requestBody,
                responseBody,
                runtypeError,
            });
            const ourError = new Error(msg);
            logToExternalErrorHandlers(ourError);
            throw ourError;
        }
    }
}

function composeRuntypeAssertionErrorMessage({
    endpoint,
    url,
    method,
    requestBody,
    responseBody,
    runtypeError,
}: {
    endpoint: string;
    url: string;
    method: string;
    requestBody: unknown;
    responseBody: unknown;
    runtypeError: unknown;
}) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const description = endpointRegistry[endpoint as RegisteredHttpEndpoint]?.description || '';

    return `Runtype validation failed!

Endpoint      : ${endpoint}
Description   : ${description}

Error message :
${printFormattedRuntypeError(runtypeError)}

Here's the relevant information:

URL           : ${url}
Method        : ${method}
Request Body  :
${requestBody === undefined ? 'None' : JSON.stringify(requestBody, null, 2)}
Response Body :
${responseBody === undefined ? 'None' : JSON.stringify(responseBody, null, 2)}
`;
}

export type ExtractArguments<T extends RegisteredHttpEndpoint> = {
    pathParams: r.Static<EndpointRegistry[T]['pathParams']>;
    queryParams: r.Static<EndpointRegistry[T]['queryParams']>;
    requestBody: r.Static<EndpointRegistry[T]['requestBody']>;
    requestHeaders?: {
        Authorization: string | null;
    };
};

/**
 * Removes all properties from type `TObject` whose values are of type `TPropertyValue`.
 *
 * Examples (see unit tests for more examples):
 *
 * ```ts
 * type X = RemoveByValue<{ a: number, b: string, c: string}, number>
 * // X will be { b: string, c: string }
 *
 * type Y = RemoveByValue<{ key1: boolean, key2: undefined }, undefined>
 * // Y will be { key1: boolean }
 * ```
 *
 * We use this construct in the signature of the `http` function so that
 *
 * ```ts
 * http(
 *    'GET /simple/endpoint/without/params/and/body',
 *    { pathParams: {}, queryParams: {}, requestBody: {} },
 *    token
 * )
 * ```
 * becomes
 * ```ts
 * http(
 *    'GET /simple/endpoint/without/params/and/body',
 *    {},
 *    token
 * )
 * ```
 */
export type RemoveByValue<TObject, TPropertyValue> = Pick<
    TObject,
    { [Key in keyof TObject]-?: TObject[Key] extends TPropertyValue ? never : Key }[keyof TObject]
>;

/**
 * Returns the received error code if it is known, otherwise returns 'unknown'.
 * If the received error code does not conform to the type specification and an runtype error is being logged.
 */
async function validateErrorCode(response: Response, endpoint: string): Promise<{ code: ErrorCode }> {
    const unchecked = await response
        .json()
        .then((body) => {
            if (typeof body.code === 'string') {
                return { code: body.code, context: body.data };
            }

            return { code: 'unknown' };
        })
        .catch(() => ({ code: 'unknown', context: undefined }));

    try {
        return { code: ErrorCodeRuntype.check(unchecked?.code) };
    } catch (runtypeError) {
        const url = response.url;
        const { method } = parseEndpoint(endpoint);
        const responseBody = await response.json().catch(() => undefined);
        const msg = composeRuntypeAssertionErrorMessage({
            endpoint,
            url,
            method,
            requestBody: undefined,
            responseBody,
            runtypeError,
        });
        const ourError = new Error(msg);
        // Throwing a runtype error is not necessary because the failed request will always throw an HttpError.
        logToExternalErrorHandlers(ourError, { extra: { endpoint }, fingerprint: ['RUNTYPE_ERROR', endpoint] });
        return { code: 'unknown' };
    }
}

export async function assertSuccessfulResponse(response: ResponseWithMethod, endpoint: string): Promise<Response> {
    if (!response.ok) {
        const { status, statusText, method } = response;
        const { code } = await validateErrorCode(response, endpoint);
        const sid = response.headers.get('x-epibator-sid') ?? undefined;
        const epibatorSha = response.headers.get('x-epibator-sha') ?? undefined;
        const luminovoVersion = response.headers.get('x-luminovo-version') ?? undefined;
        throw new HttpError({ code, status, statusText, endpoint, sid, epibatorSha, luminovoVersion, method });
    }
    return response;
}

function parseContent(response: Response) {
    if (response.headers.get('content-type')?.includes('application/json')) {
        return response.json();
    } else {
        return response.text();
    }
}

async function defaultResponseHandler(response: ResponseWithMethod, endpoint: string) {
    await assertSuccessfulResponse(response, endpoint);
    return await parseContent(response);
}

export function deriveHeadersAndBody<T extends RegisteredHttpEndpoint>(
    unsafeOptions: ExtractArguments<T>,
    token?: string,
): { headers: HeadersInit; body: BodyInit | undefined } {
    const { requestBody } = unsafeOptions;
    let body: BodyInit | undefined = undefined;
    let headers: HeadersInit = {};

    // Set the token header if a token is provided.
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    // The user can override the default headers, including the token.
    for (const [k, v] of Object.entries(unsafeOptions.requestHeaders ?? {})) {
        if (v === undefined || v === null) {
            delete headers[k];
        } else {
            headers[k] = v;
        }
    }

    if (requestBody instanceof FormData) {
        body = requestBody;
    } else if (requestBody) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(requestBody);
    }

    return { headers, body };
}

export type HttpOptions<T extends RegisteredHttpEndpoint> = RemoveByValue<ExtractArguments<T>, undefined>;

/**
 * A very light wrapper on top of `fetch` that makes it possible to
 * send HTTP requests.
 *
 * Example, make a simple GET request:
 *
 * ```ts
 * http('GET /rfqs/:rfqId/parts/mounting-types', { pathParams: { rfqId: 'xyz123' } }, token)
 * ```
 */
export async function http<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    options: HttpOptions<T>,
    token?: string,
): Promise<r.Static<EndpointRegistry[T]['responseBody']>> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const unsafeOptions = options as ExtractArguments<T>;

    const endpointDef = endpointRegistry[endpoint];

    const { method, url } = buildUrl({
        rootUrl: endpointDef.rootUrl ?? BACKEND_BASE,
        endpoint,
        pathParams: unsafeOptions.pathParams ?? {},
        queryParams: unsafeOptions.queryParams ?? {},
    });

    const requestBody = unsafeOptions.requestBody;

    const { headers, body } = deriveHeadersAndBody(unsafeOptions, token);
    const response = await fetch(url, {
        method,
        headers,
        body,
    }).catch((e) => {
        logToExternalErrorHandlers(e, { extra: { endpoint }, fingerprint: ['FETCH_ERROR'] });
        if (e instanceof TypeError) {
            throw new NetworkError({ endpoint });
        } else {
            throw e;
        }
    });

    const responseHandler = endpointDef.handleResponse ?? defaultResponseHandler;
    const responseBody = await responseHandler(Object.assign(response, { method }), endpoint);

    assertResponseBodyShape({
        // fhur: Performance hack to trick the TS type checker to avoid looking at the type of responseBody which is
        // gigantic and slows down the type checker.
        runtype: (endpointDef as any).responseBody,
        endpoint,
        url,
        method,
        requestBody,
        responseBody,
    });

    return responseBody;
}
