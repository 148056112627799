import { BomItemIssue } from '@luminovo/http-client';

export function getActiveWarnings({ issues }: { issues: BomItemIssue[] }) {
    return issues.filter(
        (issue) =>
            issue === BomItemIssue.Compliance ||
            issue === BomItemIssue.Lifecycle ||
            issue === BomItemIssue.InsufficientStock ||
            issue === BomItemIssue.MissingPartData,
    );
}
