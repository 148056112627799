import { colorSystem } from '@luminovo/design-system';
import { PersonAddOutlined as PersonAddIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

export const EmptyContributorsIcon = () => {
    return (
        <Box
            padding={'12px'}
            style={{
                backgroundColor: colorSystem.neutral[1],
                borderRadius: '50%',
                height: '48px',
                width: '48px',
                transform: 'scaleX(-1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <PersonAddIcon
                style={{
                    color: colorSystem.neutral[6],
                }}
                fontSize={'large'}
            />
        </Box>
    );
};
