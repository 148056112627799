import * as r from 'runtypes';

export const CalculationAssemblyPostRuntype = r.Record({
    assembly_id: r.String,
    notes: r.String.nullable(),
    include_excess_material_in_material_cost: r.Boolean,
});

export type CalculationAssemblyPostDTO = r.Static<typeof CalculationAssemblyPostRuntype>;

export const CalculationAssemblyResponseRuntype = r.Record({
    id: r.String,
    notes: r.String.nullable(),
    assembly_id: r.String,
    created_from_calculation_template_id: r.String.nullable(),
    include_excess_material_in_material_cost: r.Boolean,
    include_project_cost_in_manufacturing_cost: r.Boolean,
    last_template_reset_at: r.String.nullable(),
    created_at: r.String,
    updated_at: r.String.nullable(),
});

export type CalculationAssemblyDTO = r.Static<typeof CalculationAssemblyResponseRuntype>;
