import { t, Trans } from '@lingui/macro';
import { transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import { colorSystem, FieldMultiSelect, FieldRadio, Flexbox, Link, Text, Tooltip } from '@luminovo/design-system';
import { InfoRounded } from '@mui/icons-material';
import { RadioGroup } from '@mui/material';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
    matchingMethodTranslations,
} from '../../DesignItemDetails/components/AutocompleteColumnTags/types';
import { BomImporterSidebarContainer } from './BomImporterSidebarContainer';

export const ReusePartOptionsFromPreviousImport = ({
    tagsState,
    dispatch,
}: {
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}) => {
    return (
        <BomImporterSidebarContainer>
            <Flexbox gap="4px" alignItems="center">
                <Text variant="h5" color={colorSystem.neutral[9]}>
                    Reuse part options from previous import
                </Text>
                <Tooltip
                    variant="white"
                    title={
                        <Flexbox flexDirection="column" gap={4} style={{ padding: '12px' }}>
                            <Text variant="body-small">
                                <Trans>
                                    This feature finds identical line values from past BOM imports, allowing to
                                    automatically add parts that were previously matched manually. By default, all
                                    columns are used for the recognition, but you can also choose which ones to include.{' '}
                                    <Link
                                        href={
                                            'https://help.luminovo.com/en/articles/228326-reuse-part-options-from-previous-bom-imports'
                                        }
                                        attention="low"
                                        variant="body-small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Learn more
                                    </Link>
                                </Trans>
                            </Text>
                        </Flexbox>
                    }
                >
                    <InfoRounded style={{ fontSize: '12px', color: colorSystem.neutral[5] }} />
                </Tooltip>
            </Flexbox>
            <RadioGroup>
                <Flexbox flexDirection="column" gap="8px">
                    {typeSafeObjectKeys(matchingMethodTranslations).map((method) => {
                        return (
                            <Flexbox gap="8px" key={method}>
                                <FieldRadio
                                    value={tagsState.previousLinesSearch.variant}
                                    fieldValue={method}
                                    onChange={(v) =>
                                        dispatch({ type: 'set-previous-lines-search-variant', variant: v })
                                    }
                                    size="small"
                                />
                                <Text variant="body-small">{transEnum(method, matchingMethodTranslations)}</Text>
                            </Flexbox>
                        );
                    })}
                </Flexbox>
            </RadioGroup>
            {tagsState.previousLinesSearch.variant === 'SelectedColumns' && (
                <ColumnsAutocomplete tagsState={tagsState} dispatch={dispatch} />
            )}
        </BomImporterSidebarContainer>
    );
};

const ColumnsAutocomplete = ({
    tagsState,
    dispatch,
}: {
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}) => {
    const { previousLinesSearch, selectedSheetIndex, sheets } = tagsState;
    const idsWithAssociations = sheets[selectedSheetIndex].tags
        .filter((tag) => tag.associatedTo.length > 0)
        .map((tag) => tag.id);

    const isNoColumnSelected = previousLinesSearch.columns.length === 0;
    return (
        <>
            <FieldMultiSelect
                disableClearable
                disableCloseOnSelect
                value={previousLinesSearch.columns}
                onBlur={() => {}}
                options={idsWithAssociations}
                onChange={(value) => dispatch({ type: 'assign-previous-lines-search-columns', columns: value })}
                getOptionLabel={(option) => `${option}`}
                getOptionKey={(option) => option}
                error={isNoColumnSelected}
                helperText={isNoColumnSelected ? t`Please select at least one column` : ''}
                groupBy={() => t`Tagged columns`}
            />
            <WarningMessage columnsSelected={previousLinesSearch.columns} />
        </>
    );
};

const WarningMessage = ({ columnsSelected }: { columnsSelected: string[] }) => {
    if (
        columnsSelected.includes('cpn') ||
        columnsSelected.includes('mpn') ||
        columnsSelected.includes('supplier_part_number') ||
        columnsSelected.includes('ipn')
    ) {
        return null;
    }
    return (
        <Flexbox gap="4px" alignItems="flex-start">
            <InfoRounded style={{ color: colorSystem.yellow[6], fontSize: '16px', marginTop: '2px' }} />
            <Text>
                <Trans>
                    We recommend selecting one of the columns <b>CPN, MPN, SPN, IPN</b> to ensure the most accurate
                    results.
                </Trans>
            </Text>
        </Flexbox>
    );
};
