import * as r from 'runtypes';
import { AssemblyTypeRuntype } from '../assembly';

export interface AssemblySettingsDTO extends r.Static<typeof AssemblySettingsRuntype> {}
export const AssemblySettingsRuntype = r.Record({
    allowed_assembly_types: r.Array(AssemblyTypeRuntype),
});

export interface PatchAssemblySettingsDTO extends r.Static<typeof PatchAssemblySettingsRuntype> {}
export const PatchAssemblySettingsRuntype = r.Record({
    allowed_assembly_types: r.Array(AssemblyTypeRuntype).optional(),
});
