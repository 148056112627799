import { SolutionTag } from '@luminovo/http-client';
import { Solution } from '../../types';

export const extractScrapQuantity = ({ solution }: { solution: Solution }): number => {
    return solution.solutionTags
        .map((tag) => {
            if (tag.tag === SolutionTag.ScrapQuantity) {
                return tag.content.amount;
            }
            return 0;
        })
        .reduce((a, b) => a + b, 0);
};
