import { YearsToEndOfLifeWithCount } from '@luminovo/http-client';

type Range = {
    from: number;
    to: number;
};

export function normalizeEOLData(
    allYearsToEndOfLife: YearsToEndOfLifeWithCount[],
    range: Range,
): { yearsToEndOfLifeUpTo10Yrs: YearsToEndOfLifeWithCount[]; maxYearsAndCount?: YearsToEndOfLifeWithCount } {
    const sortedAllYearsToEndOfLife = allYearsToEndOfLife.sort(
        (a, b) => a.years_to_end_of_life - b.years_to_end_of_life,
    );
    const yearsToEndOfLifeUpTo10Yrs = sortedAllYearsToEndOfLife.filter(
        (data) => data.years_to_end_of_life >= range.from && data.years_to_end_of_life < range.to,
    );
    const yearsToEndOfLifeUpAbove10Yrs = sortedAllYearsToEndOfLife.filter(
        (data) => data.years_to_end_of_life >= range.to,
    );

    const maxYearsAndCount = sortedAllYearsToEndOfLife[sortedAllYearsToEndOfLife.length - 1];
    const minYearsAndCount = yearsToEndOfLifeUpTo10Yrs[0];

    if (yearsToEndOfLifeUpTo10Yrs.length === 0) {
        return yearsToEndOfLifeUpAbove10Yrs.length > 0
            ? {
                  yearsToEndOfLifeUpTo10Yrs: [
                      {
                          // eslint-disable-next-line camelcase
                          years_to_end_of_life: range.from,
                          count: 0,
                      },
                      {
                          // eslint-disable-next-line camelcase
                          years_to_end_of_life: range.to,
                          count: maxYearsAndCount.count,
                      },
                  ],
                  maxYearsAndCount,
              }
            : { yearsToEndOfLifeUpTo10Yrs: [] };
    }

    const gapBetweenPointsOnXAxis = 2;
    for (let years = range.from; years < minYearsAndCount.years_to_end_of_life; years += gapBetweenPointsOnXAxis) {
        yearsToEndOfLifeUpTo10Yrs.push({
            // eslint-disable-next-line camelcase
            years_to_end_of_life: years,
            count: 0,
        });
    }

    // we are adding 10 here, so that the graph is always filled to the end and so that the max value is always visible
    // if the maxYears was 8, with a count of 15, we would add 10 yrs, with a count of 15 to the graph
    yearsToEndOfLifeUpTo10Yrs.push({
        // eslint-disable-next-line camelcase
        years_to_end_of_life: range.to,
        count: maxYearsAndCount.count,
    });

    return {
        yearsToEndOfLifeUpTo10Yrs: yearsToEndOfLifeUpTo10Yrs.sort(
            (a, b) => a.years_to_end_of_life - b.years_to_end_of_life,
        ),
        maxYearsAndCount,
    };
}

export function placeHolderEOLData(itemsCount: number) {
    return Array(itemsCount)
        .fill(0)
        .map((_, i) => {
            return { xAxis: i + 2, yAxis: Math.floor(Math.max(0, i * Math.pow(10, i / 10))) };
        });
}
