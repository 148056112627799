import { DestructiveSecondaryButton } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useQuoteRequest } from '../hooks/negotiationHandlers';

export function ButtonDeleteQuoteRequest({ quoteRequestId }: { quoteRequestId: number }) {
    const history = useHistory();
    const { mutateAsync, isLoading } = useHttpMutation('DELETE /quote-request/:id', {
        snackbarMessage: 'Quote request deleted',
        onSuccess: () => history.goBack(),
    });

    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!quoteRequest || quoteRequest.status !== QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <DestructiveSecondaryButton
            size="medium"
            startIcon={<Delete />}
            isLoading={isLoading}
            onClick={() => mutateAsync({ pathParams: { id: quoteRequestId } })}
        >
            Delete
        </DestructiveSecondaryButton>
    );
}
