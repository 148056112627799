import { t, Trans } from '@lingui/macro';
import {
    FieldNumericController,
    Flexbox,
    FormItem,
    SecondaryButton,
    TertiaryIconButton,
    Text,
} from '@luminovo/design-system';
import { Add, Delete } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment, Tooltip } from '@mui/material';
import { Controller, FieldArrayPath, useFieldArray, useFormContext } from 'react-hook-form';
import {
    SelectController,
    TextFieldController,
} from '../../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { useGlobalCurrency } from '../../../../../resources/organizationSettings/currencySettingsHandler';
import { displayCurrencySymbol } from '../../../../../utils/currencyUtils';
import { CalculationTemplateFormInput, costTypePublicTranslations } from '../utils/types';
import { generateAdditionalFieldsFromFieldName } from './additionalFormInputControls';
import { assertIsFormulaCost, defaultValuePercentage, formulaRules, nameRule, validateAmount } from './defaults';
import { LockToggle } from './LockToggle';
import { UpsertFormula } from './UpsertFormula';

function validateExistingCostNames(value: unknown, existingCostNames: string[]): string | undefined {
    if (
        value &&
        (typeof value === 'string' || typeof value === 'number') &&
        existingCostNames.filter((costName) => costName === value.toString()).length > 1
    ) {
        return t`A cost with this name already exists`;
    }
    return;
}

export const CostsFieldArray = ({
    fieldName,
}: {
    fieldName: FieldArrayPath<CalculationTemplateFormInput>;
}): JSX.Element => {
    const { watch, control, trigger, unregister } = useFormContext<CalculationTemplateFormInput>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: fieldName,
    });
    const { preferredCurrency, isLoading: isCurrencyLoading } = useGlobalCurrency();

    const gapBetweenItems = 16;

    if (isCurrencyLoading) {
        return <CircularProgress />;
    }

    const existingCostNames: string[] = watch(fieldName).map((field) => field.name);

    return (
        <Box padding={'12px'}>
            {fields.length === 0 ? (
                <SecondaryButton
                    size="medium"
                    startIcon={<Add />}
                    onClick={() => append({ name: '', unitCost: defaultValuePercentage, isLocked: false })}
                >
                    <Trans>Add cost</Trans>
                </SecondaryButton>
            ) : (
                <Flexbox flexDirection={'column'} gap={gapBetweenItems}>
                    {fields.map((field, index) => (
                        <Box key={field.id}>
                            <Flexbox justifyContent={'space-between'} alignItems={'top'}>
                                <Flexbox gap={'8px'} alignItems={'top'}>
                                    <FormItem>
                                        <TextFieldController
                                            control={control}
                                            name={`${fieldName}.${index}.name`}
                                            TextFieldProps={{
                                                placeholder: t`Cost name e.g. Overhead`,
                                                style: { width: 320 },
                                            }}
                                            rules={{
                                                ...nameRule(),
                                                validate: (value) =>
                                                    validateExistingCostNames(value, existingCostNames),
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <SelectController
                                            control={control}
                                            name={`${fieldName}.${index}.unitCost.type`}
                                            translations={costTypePublicTranslations}
                                            SelectProps={{ style: { width: 180 } }}
                                            rules={{ required: t`Required` }}
                                            onChangeCallback={() => {
                                                unregister(`${fieldName}.${index}.unitCost.value`);
                                            }}
                                        />
                                    </FormItem>
                                    {watch(`${fieldName}.${index}.unitCost.type`) === 'fixed' && (
                                        <FieldNumericController
                                            name={`${fieldName}.${index}.unitCost.value.cost.amount`}
                                            control={control}
                                            representation="string"
                                            FieldProps={{
                                                style: { width: 180 },
                                                InputProps: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {<Text>{displayCurrencySymbol(preferredCurrency)}</Text>}
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                            required
                                            min={0}
                                            validate={validateAmount}
                                        />
                                    )}
                                    {watch(`${fieldName}.${index}.unitCost.type`) === 'percentage' && (
                                        <FieldNumericController
                                            name={`${fieldName}.${index}.unitCost.value.percentage`}
                                            control={control}
                                            representation="string"
                                            FieldProps={{
                                                style: { width: 180 },
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">{<Text>%</Text>}</InputAdornment>
                                                    ),
                                                },
                                            }}
                                            required
                                            min={0}
                                            validate={validateAmount}
                                        />
                                    )}
                                    {watch(`${fieldName}.${index}.unitCost.type`) === 'formula' && (
                                        <Controller
                                            name={`${fieldName}.${index}.unitCost`}
                                            control={control}
                                            rules={formulaRules}
                                            render={({ field, fieldState }): JSX.Element => {
                                                return (
                                                    <UpsertFormula
                                                        formulaCost={assertIsFormulaCost(
                                                            watch(`${fieldName}.${index}.unitCost`),
                                                        )}
                                                        errorMessage={fieldState.error?.message}
                                                        onSubmit={(dialogValue) => {
                                                            field.onChange(dialogValue);
                                                            // this is required for the form to revalidate on change.
                                                            trigger();
                                                        }}
                                                        additionalFormulaInputControls={generateAdditionalFieldsFromFieldName(
                                                            fieldName,
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                </Flexbox>
                                <Flexbox alignItems={'center'} gap={8}>
                                    <LockToggle name={`${fieldName}.${index}.isLocked`} />
                                    <Tooltip title={t`Delete`} placement="top" arrow>
                                        <TertiaryIconButton size="medium" onClick={() => remove(index)}>
                                            <Delete fontSize="inherit" />
                                        </TertiaryIconButton>
                                    </Tooltip>
                                </Flexbox>
                            </Flexbox>
                            {index === fields.length - 1 && (
                                <SecondaryButton
                                    style={{ marginTop: gapBetweenItems }}
                                    size="medium"
                                    startIcon={<Add />}
                                    onClick={() =>
                                        append({ name: '', unitCost: defaultValuePercentage, isLocked: false })
                                    }
                                >
                                    <Trans>Add cost</Trans>
                                </SecondaryButton>
                            )}
                        </Box>
                    ))}
                </Flexbox>
            )}
        </Box>
    );
};
