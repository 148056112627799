/* eslint-disable camelcase */
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Message,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { usePageParams } from '../../../resources/hooks';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';

interface FormState {
    notes: string;
    dueDate: string;
    showCustomerName: boolean;
}

function FormItemDueDate() {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem label="Due date">
            <FieldDateControlled
                control={control}
                name="dueDate"
                inFuture
                minDate={new Date().toISOString()}
                FieldProps={{
                    placeholder: 'Select due date...',
                }}
            />
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem label="Notes">
            <FieldTextControlled
                control={control}
                name="notes"
                FieldProps={{
                    placeholder: 'Start typing...',
                    multiline: true,
                    minRows: 2,
                }}
            />
        </FormItem>
    );
}

function FormItemOptionalColumns() {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem label="Optional columns">
            <Flexbox flexDirection="row" gap="8px" alignItems="center">
                <FieldCheckboxControlled control={control} name="showCustomerName" />
                <Text>Customer name</Text>
            </Flexbox>
        </FormItem>
    );
}

const useQuoteRequestConfigurationDialog = () => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: null,
    });

    const defaultValues: FormState = {
        dueDate: '',
        notes: '',
        showCustomerName: false,
    };

    return {
        openDialog: ({ quoteRequests }: { quoteRequests: QuoteRequestDTO[] }) => {
            const onSubmit = async (form: FormState) => {
                await Promise.all(
                    quoteRequests.map(async (quoteRequest) => {
                        await mutateAsync({
                            pathParams: { id: quoteRequest.id },
                            requestBody: {
                                due_date: form.dueDate,
                                notes: form.notes,
                                show_customer: form.showCustomerName,
                            },
                        });
                    }),
                );
                closeDialog();
            };

            setDialog(
                <Dialog open={true} maxWidth="md" fullWidth={true} onClose={closeDialog}>
                    <DialogTitle title={'Configure quote request'} handleClose={closeDialog} />
                    <DialogContent>
                        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues}>
                            <Flexbox flexDirection="column" gap="8px">
                                <Message
                                    size="small"
                                    attention="low"
                                    variant="blue"
                                    title=""
                                    message="We will overwrite the due date and notes for each quote request. Depending on the number of quote requests, this might take a while."
                                />
                                <FormItemDueDate />
                                <FormItemNotes />
                                <FormItemOptionalColumns />
                                <SubmitButton />
                            </Flexbox>
                        </FormContainer>
                    </DialogContent>
                </Dialog>,
            );
        },
    };
};

export function ButtonConfigureQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequestDTO[] }) {
    const { openDialog } = useQuoteRequestConfigurationDialog();

    const sendable = quoteRequests.filter((s) => s.status === QuoteRequestStatus.NotSent);

    return (
        <SecondaryButton
            size={'medium'}
            disabled={sendable.length === 0}
            onClick={() => openDialog({ quoteRequests: sendable })}
        >
            Configure quote requests
        </SecondaryButton>
    );
}

export function ButtonSendQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequestDTO[] }) {
    const { negotiationId } = usePageParams<'/negotiations/:negotiationId'>();

    const sendable = quoteRequests.filter((s) => s.status === QuoteRequestStatus.NotSent);

    // FIXME: Will break when to many quote requests are selected because we store it in the URL
    return (
        <PrimaryButton
            size="medium"
            disabled={sendable.length === 0}
            href={route(
                '/negotiations/:negotiationId/quote-requests/preview-email',
                {
                    negotiationId,
                },
                {
                    quoteRequestIds: quoteRequests.map((s) => s.id).join(','),
                },
            )}
        >
            {sendable.length > 1 ? `Send ${sendable.length} requests` : 'Send request'}
        </PrimaryButton>
    );
}
