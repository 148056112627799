import { t } from '@lingui/macro';
import { assertPresent } from '@luminovo/commons';
import { ComplianceStatus, HttpError, LifecycleEnum, OtsFormPostValues, OtsFullPart } from '@luminovo/http-client';
import React from 'react';
import { FormContainer, ValidationErrors } from '../../../../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { SectionOfScreen } from '../../../../../../resources/part/partFrontendTypes';
import { throwErrorUnlessProduction } from '../../../../../../utils/customConsole';
import { undefinedIfEmpty } from '../../../../../../utils/typingUtils';
import { OtsPartForm } from './OtsPartForm';
import { PART_CATEGORY_OFF_THE_SHELF } from './components/PartCategorySelectControlled';
import { OtsFormValues } from './components/formValues';

export type AddPartHandler = (part: OtsFullPart, sectionOfScreen: SectionOfScreen) => void;

interface AddOtsPartFormProps {
    onClose: () => void;
    onSuccess: AddPartHandler;
    initialMpn: string;
}

export const OtsPartAddForm: React.FunctionComponent<AddOtsPartFormProps> = ({
    onClose,
    onSuccess,
    initialMpn,
}: AddOtsPartFormProps): JSX.Element => {
    const { mutateAsync } = useHttpMutation('POST /parts/off-the-shelf', {
        snackbarMessage: t`New part created`,
        disableOnError: true,
    });
    /* eslint-disable camelcase */
    const defaultValues: OtsFormValues = {
        mpn: initialMpn,
        partCategory: PART_CATEGORY_OFF_THE_SHELF,
        manufacturerDto: undefined,
        reach_compliant: ComplianceStatus.Compliant,
        rohs_compliant: ComplianceStatus.Compliant,
        aecq_compliant: ComplianceStatus.Compliant,
        lifecycle_status: LifecycleEnum.Active,
        last_buy_date: undefined,
        description: undefined,
        datasheet_url: undefined,
        manufacturer_product_url: undefined,
        image_url: undefined,
        mpn_aliases: [],
        mounting: null,
        package_name: null,
        number_of_pins: null,
        qualifications: [],
    };

    const onSubmit = async (data: OtsFormValues): Promise<void | HttpError> => {
        const body: OtsFormPostValues = {
            mpn: data.mpn,
            reach_compliant: data.reach_compliant,
            rohs_compliant: data.rohs_compliant,
            aecq_compliant: data.aecq_compliant,
            manufacturer: assertPresent(data.manufacturerDto?.id),
            part_category: data.partCategory.id,
            lifecycle_status: data.lifecycle_status,
            description: data.description,
            datasheet_url: undefinedIfEmpty(data.datasheet_url),
            manufacturer_product_url: undefinedIfEmpty(data.manufacturer_product_url),
            image_url: undefinedIfEmpty(data.image_url),
            last_buy_date:
                data.last_buy_date && data.lifecycle_status === LifecycleEnum.EndOfLife
                    ? data.last_buy_date
                    : undefined,
            mpn_aliases: data.mpn_aliases,
            package: {
                mounting: data.mounting,
                name: data.package_name,
                number_of_pins: data.number_of_pins,
            },
            qualifications: data.qualifications,
        };
        /* eslint-enable camelcase */

        if (body.last_buy_date !== undefined && body.lifecycle_status !== LifecycleEnum.EndOfLife) {
            throwErrorUnlessProduction(new Error('Unexpected value in last_buy_date'));
        }

        const { data: newPart } = await mutateAsync({ requestBody: body });
        onSuccess(newPart, 'otsPartForm');
        onClose();
    };

    const validationErrors: ValidationErrors<OtsFormValues> = {
        'off_the_shelf.duplicated_mpn': {
            fieldPath: 'mpn',
        },
        'off_the_shelf.mpn_length_violation': {
            fieldPath: 'mpn',
        },
        'off_the_shelf.package_violation': {
            fieldPath: 'package_name',
        },
        'off_the_shelf.part_already_exists': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <OtsPartForm isEditPartForm={false} onCancel={() => onClose()} />
        </FormContainer>
    );
};
