import { FontVariant, Link, Text } from '@luminovo/design-system';
import { PartLite, PartLiteTypes } from '@luminovo/http-client';
import { useGenericPartDetailsDrawer } from '../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { formatPartLite } from '../model/formatPartLite';
export function PartLiteLabel({
    part,
    variant,
    color,
}: {
    part?: PartLite;
    variant?: FontVariant;
    color?: string;
}): JSX.Element {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnPartDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();

    if (!part) {
        return <Text variant={variant}>-</Text>;
    }

    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return (
                <Link
                    attention={color ? 'mui-default' : 'high'}
                    color={color}
                    variant={variant}
                    onClick={() => openOtsPartDrawer({ part: { id: part.id }, rfqContext: { type: 'OutsideRfQ' } })}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.Ipn:
            return (
                <Link
                    attention={color ? 'mui-default' : 'high'}
                    color={color}
                    variant={variant}
                    onClick={() => openIpnPartDrawer({ ipnId: part.id, rfqContext: { type: 'OutsideRfQ' } })}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.Generic:
            return (
                <Link
                    attention={color ? 'mui-default' : 'high'}
                    color={color}
                    variant={variant}
                    onClick={() =>
                        openGenericPartDrawer({
                            genericPartId: part.id,
                            isEditEnable: false,
                            rfqContext: { type: 'OutsideRfQ' },
                        })
                    }
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.RawSpecification:
            return (
                <Text variant={variant} color={color}>
                    {formatPartLite(part)}
                </Text>
            );
        case PartLiteTypes.Custom:
            return (
                <Text variant={variant} color={color}>
                    {formatPartLite(part)}
                </Text>
            );
        case PartLiteTypes.CustomComponent:
            return (
                <Text variant={variant} color={color}>
                    {formatPartLite(part)}
                </Text>
            );
    }
}
