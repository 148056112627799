import { Trans, t } from '@lingui/macro';
import { id, isPresent } from '@luminovo/commons';
import { Dialog, DialogContent, PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import { OtsFullPart, QuoteTrackingDTO, RfqDTO, isOtsFullPart } from '@luminovo/http-client';
import {
    PdfDropzone,
    PdfViewer,
    PdfViewerContextProvider,
    PdfViewerForm,
    PdfViewerLayout,
    PdfViewerTable,
    PdfViewerToolbar,
    RegionNetwork,
    composeExtractionResults,
    createOffersFromRegionNetwork,
    usePdfViewerState,
} from '@luminovo/sourcing-core';
import { PictureAsPdfRounded } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import { useHttpFileUpload } from '../../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useMutationAnalyzePdf } from '../../../../resources/offer/offerHandler';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
interface Props {
    file: File;
    pdfDocumentProxy: PDFDocumentProxy;
    regionNetwork: RegionNetwork;
    expectedParts: OtsFullPart[];
    quoteTracking: QuoteTrackingDTO;
    rfq: RfqDTO;
    uploadedUrl?: string;
}

function PdfOfferExtractorDialog({
    pdfDocumentProxy,
    closeDialog,
    regionNetwork,
    expectedParts,
    quoteTracking,
    rfq,
    uploadedUrl,
    file,
}: Props & { closeDialog: () => void }): JSX.Element {
    const [state, dispatch] = usePdfViewerState();
    React.useEffect(() => {
        dispatch({ type: 'setRegions', regions: regionNetwork });
    }, [dispatch, regionNetwork]);
    const extractionResults = composeExtractionResults(state.regs);
    const { data: customer } = useCustomer(rfq.customer);

    const { mutateAsync: mutateAsyncUpload } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );

    const { mutateAsync: createOffers } = useHttpMutation('POST /offers/off-the-shelf/bulk/import', {
        snackbarMessage: t`Offers successfully created`,
        onSuccess: async () => {
            await mutateAsyncUpload({ pathParams: { id: quoteTracking.id }, files: file });
            closeDialog();
        },
    });

    return (
        <PdfViewerLayout
            form={<PdfViewerForm extractionResults={extractionResults} customer={customer} rfq={rfq} />}
            pdfViewer={<PdfViewer pdf={pdfDocumentProxy} />}
            table={<PdfViewerTable expectedParts={expectedParts} extractionResults={extractionResults} />}
            toolbar={
                <PdfViewerToolbar
                    supplierAndStockLocation={quoteTracking.supplier_and_stock_location}
                    onCancel={closeDialog}
                    onImport={() => {
                        createOffers({
                            requestBody: {
                                rfq_id: rfq.id,
                                supplier_and_stock_location: quoteTracking.supplier_and_stock_location.id,
                                // eslint-disable-next-line camelcase
                                quote_tracking_id: quoteTracking.id,
                                inputs: createOffersFromRegionNetwork({
                                    regionNetwork: state.regs,
                                    quoteTrackingId: quoteTracking.id,
                                    supplierAndStockLocation: quoteTracking.supplier_and_stock_location.id,
                                    rfq: rfq,
                                    uploadedUrl,
                                }),
                                event_metadata: {
                                    type: 'Pdf',
                                },
                            },
                        });
                    }}
                    extractionRateOfRequiredFields={extractionResults.extractionRateOfRequiredFields}
                />
            }
        />
    );
}

export const PdfQuoteImportButton = withErrorBoundary(
    function PdfQuoteImportButton({
        quoteTracking,
        label = <Trans>Import PDF</Trans>,
        size = 'small',
        variant,
    }: {
        quoteTracking: QuoteTrackingDTO;
        label?: React.ReactNode;
        variant?: 'primary' | 'secondary';
        size?: ButtonProps['size'];
    }) {
        const { data: rfq } = useRfQ(quoteTracking.rfq);
        const expectedParts = quoteTracking.request_standard_parts
            .map((p) => p.kind)
            .flatMap((part) => (isOtsFullPart(part) ? [part] : []));

        const { mutateAsync, isLoading } = useMutationAnalyzePdf();
        const { open } = usePdfOfferExtractor();

        if (expectedParts.length === 0 || !isPresent(rfq)) {
            return null;
        }

        const ButtonComponent = variant === 'primary' ? PrimaryButton : SecondaryButton;

        return (
            <PdfDropzone
                onSuccess={async ({ file, pdf }) => {
                    const { regionNetwork, uploadedUrl } = await mutateAsync({
                        expectedParts,
                        file,
                    });

                    open({
                        file,
                        pdfDocumentProxy: pdf,
                        regionNetwork,
                        expectedParts,
                        quoteTracking,
                        rfq,
                        uploadedUrl,
                    });
                }}
            >
                <ButtonComponent
                    id={id('sourcing/button_import_pdf')}
                    size={size}
                    isLoading={isLoading}
                    startIcon={<PictureAsPdfRounded />}
                >
                    {label}
                </ButtonComponent>
            </PdfDropzone>
        );
    },
    { fallback: <Text>Something went wrong</Text> },
);

function usePdfOfferExtractor() {
    const { isOpen, setDialog, closeDialog } = useDialogContext();

    return {
        isOpen,
        closeDialog,
        open: (props: Props) => {
            return setDialog(
                <Dialog open={true} fullScreen={true} maxWidth={'lg'}>
                    <DialogContent style={{ padding: 0, overflowY: 'hidden', height: '100vh' }}>
                        <PdfViewerContextProvider>
                            <PdfOfferExtractorDialog closeDialog={closeDialog} {...props} />
                        </PdfViewerContextProvider>
                    </DialogContent>
                </Dialog>,
            );
        },
    };
}
