export type CSVLineHeader = { lineNumber: number; rawOriginalLine: string; type: 'header'; content: string[] };
export type CSVLineContent = { lineNumber: number; rawOriginalLine: string; type: 'content'; content: string[] };

type CSVLine = CSVLineHeader | CSVLineContent;

export function isContentLine(line: CSVLine): line is CSVLineContent {
    return line.type === 'content';
}

type CSVLinesHeader = {
    type: 'with-header';
    lines: CSVLinesWitHeader;
};

type CSVLinesNoHeader = {
    type: 'without-header';
    lines: CSVLinesWithNoHeader;
};

export type CSVLines = CSVLinesHeader | CSVLinesNoHeader;

type CSVLinesWitHeader = [CSVLineHeader, ...CSVLineContent[]];

type CSVLinesWithNoHeader = CSVLineContent[];

export type CSVColumn = {
    columnNumber: number;
    content: string;
};

export type CSVContents = Array<string[]>;

type ColumnId = string;
export type LineItem = Record<ColumnId, string>;
