import React from 'react';

export const LazyCreatePurchaseOrdersPage = React.lazy(async () => {
    const { CreatePurchaseOrdersPage } = await import('./CreatePurchaseOrdersPage');

    return { default: CreatePurchaseOrdersPage };
});

export const LazyUpdatePurchaseOrdersPage = React.lazy(async () => {
    const { UpdatePurchaseOrdersPage } = await import('./UpdatePurchaseOrdersPage');

    return { default: UpdatePurchaseOrdersPage };
});
