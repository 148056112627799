import { cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface LanguageProps {}

type Language = 'en' | 'de' | 'es' | 'fr';

export const language = (_?: LanguageProps): Parser<Language> =>
    cases({
        matches: [
            {
                when: [
                    'anglais',
                    'en',
                    'englisch',
                    'english',
                    'gb',
                    'inglés',
                    'inglese',
                    'uk',
                    'united kingdom',
                    'united states',
                    'usa',
                    'us',
                ],
                then: 'en',
            },
            {
                when: ['Spanish', 'es'],
                then: 'es',
            },
            {
                when: ['German', 'de'],
                then: 'de',
            },
            {
                when: ['French', 'fr'],
                then: 'fr',
            },
        ],
    });
