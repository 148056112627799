import { isPresent } from '@luminovo/commons';
import { SearchOutlined } from '@mui/icons-material';
import React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';
import { BaseButtonProps, SecondaryButton } from '../buttons';

export function NonIdealState({
    title,
    description,
    action,
    withBorder = false,
    Icon = SearchOutlined,
    variant = 'default',
    style,
    overrides = {},
}: {
    action?: BaseButtonProps;
    title: React.ReactNode;
    description: React.ReactNode;
    withBorder?: boolean;
    Icon?: React.ComponentType<{ style: React.CSSProperties }>;
    variant?: 'default' | 'warning' | 'success';
    style?: React.CSSProperties;
    overrides?: { ActionButton?: React.ComponentType };
}): JSX.Element {
    const { ActionButton = SecondaryButton } = overrides;

    const variants = {
        default: {
            iconColor: colorSystem.neutral[8],
            titleColor: undefined,
        },
        warning: {
            iconColor: colorSystem.yellow[7],
            titleColor: colorSystem.yellow[8],
        },
        success: {
            iconColor: colorSystem.green[7],
            titleColor: colorSystem.green[8],
        },
    };

    const selectedVariant = variants[variant];

    return (
        <Flexbox
            textAlign={'center'}
            flexDirection={'column'}
            maxWidth="280px"
            alignItems={'center'}
            gap={8}
            border={withBorder ? `1px solid ${colorSystem.neutral[2]}` : undefined}
            bgcolor={withBorder ? colorSystem.neutral.white : undefined}
            padding="60px 40px "
            borderRadius={'8px'}
            style={style}
        >
            <Icon style={{ height: 24, width: 24, color: selectedVariant.iconColor }} />
            <Flexbox flexDirection={'column'} gap={'4px'}>
                <Text color={selectedVariant.titleColor} variant="h4">
                    {title}
                </Text>
                <Text
                    style={{
                        // textWrap is a new CSS property, the type definition is not yet available
                        // See https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap
                        // @ts-ignore
                        textWrap: 'balance',
                    }}
                >
                    {description}
                </Text>
            </Flexbox>
            <span />
            {(action || isPresent(overrides.ActionButton)) && <ActionButton size="small" {...action} />}
        </Flexbox>
    );
}
