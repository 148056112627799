import * as r from 'runtypes';

export const AdditionalServiceTargetRuntype = r.Union(r.Literal('TopLevelAssembly'), r.Literal('AllAssemblies'));
export type AdditionalServiceTarget = r.Static<typeof AdditionalServiceTargetRuntype>;

export const AdditionalServicesPostRequestRuntype = r.Record({
    name_en: r.String,
    name_de: r.String,
    manufacturing_scenario_template_id: r.String.nullable(),
    applies_to: AdditionalServiceTargetRuntype,
});

export const AdditionalServicesPatchRequestRuntype = AdditionalServicesPostRequestRuntype;

export const AdditionalServicesGetResponseRuntype = r.Record({
    id: r.String,
    name_en: r.String,
    name_de: r.String,
    manufacturing_scenario_template_id: r.String.nullable(),
    applies_to: AdditionalServiceTargetRuntype,
    created_at: r.String,
    updated_at: r.String,
});

export const AdditionalServicesPostResponseRuntype = AdditionalServicesGetResponseRuntype;
export const AdditionalServicesPatchResponseRuntype = AdditionalServicesGetResponseRuntype;

export type AdditionalServicesPostRequestDTO = r.Static<typeof AdditionalServicesPostRequestRuntype>;
export type AdditionalServicesPostResponseDTO = r.Static<typeof AdditionalServicesPostResponseRuntype>;
export type AdditionalServicesGetResponseDTO = r.Static<typeof AdditionalServicesGetResponseRuntype>;
