import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { PrimaryButton } from '@luminovo/design-system';
import { Add } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHasAccessToCustomerPortal } from '../../../utils/featureFlags';
import { route } from '../../../utils/routes';

const RfqCreationButton: React.FunctionComponent = (): JSX.Element => {
    const history = useHistory();

    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();
    const { organization, customer } = useCurrentUserDetailsContext();
    const shouldDisableNewRequestButton = isPresent(customer) && !hasAccessToCustomerPortal;

    return (
        <Tooltip
            arrow
            placement="top"
            title={t`${organization.name} has not granted you access to add a new request`}
            disableHoverListener={!shouldDisableNewRequestButton}
        >
            <span>
                <PrimaryButton
                    id="rfq_overview_new_request"
                    onClick={() => history.push(route('/rfq_creation', {}))}
                    size="medium"
                    disabled={shouldDisableNewRequestButton}
                    startIcon={<Add />}
                >
                    <Trans>New request</Trans>
                </PrimaryButton>
            </span>
        </Tooltip>
    );
};

export default RfqCreationButton;
