import { NumberSeparator } from '../determineDecimalSeparator';
import { Parser } from '../types';
import { createParseNumber, getNumberWithCurrencyRegexString } from './parseNumber';
import { pieceKeywords } from './parseUnit';

// don't add general keywords like "Price" because they are too ambiguous
// and could be referring to a total price
const unitPriceKeywords = pieceKeywords
    .flatMap((keyword) => [`Price\\s?/\\s?${keyword}`, `Preis\\s?/\\s?${keyword}`])
    .concat(['Unit Price', 'Unit Price \\(OEM\\)', 'Preis\\s?/\\s?Eht', 'Preis\\s?/\\s?Einh\\.?', 'Einzelpreis']);

export const unitPriceRegex = new RegExp(`(?:\\s|^)(?:${unitPriceKeywords.join('|')})(?:\\s|:|=|$)`, 'i');

export const createParseUnitPrice = (decimalSeparator: NumberSeparator): Parser => {
    const parseNumber = createParseNumber(decimalSeparator);
    const numberWithCurrencyRegexString = getNumberWithCurrencyRegexString(decimalSeparator);
    // if we find something that looks like "1,23 € / 1000" or "1.000,00 USD per 10"
    // we can have very high confidence that it's a unit price and we don't need any type of
    // unit price keyword to match
    const pricePerRegex = new RegExp(
        `${numberWithCurrencyRegexString}\\s?(?:/|pro|per|je)\\s?(?:(10{1,3})|(?:(?:1\\s)?(${pieceKeywords.join(
            '|',
        )})))`,
        'i',
    );

    // if there is no "per" pr "/" then we need a keyword to match first
    const unitPriceKeywordThenPriceRegex = new RegExp(
        `${unitPriceRegex.source}\\s*${numberWithCurrencyRegexString}`,
        'i',
    );

    return (str: string) => {
        const matchesPricePer = pricePerRegex.exec(str);
        if (matchesPricePer) {
            const [, price, priceUnit, perPiece] = matchesPricePer;
            const priceNumber = parseNumber(price);
            const priceUnitNumber = priceUnit ? parseNumber(priceUnit) : perPiece ? 1 : undefined;
            if (priceNumber && priceUnitNumber) {
                return { attr: 'unitPrice', value: priceNumber / priceUnitNumber, confidence: 1 };
            }
        }
        const matchesKeywordThenPrice = unitPriceKeywordThenPriceRegex.exec(str);
        if (matchesKeywordThenPrice) {
            const [, price] = matchesKeywordThenPrice;
            const priceNumber = parseNumber(price);
            if (priceNumber) {
                return { attr: 'unitPrice', value: priceNumber };
            }
        }
        return undefined;
    };
};
