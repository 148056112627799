import * as React from 'react';

export type Direction = 'ArrowUp' | 'ArrowDown';

export function useKeyUpDown(callback: (direction: 'ArrowUp' | 'ArrowDown') => void) {
    React.useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            const key = e.key;
            if (e.shiftKey && (key === 'ArrowUp' || key === 'ArrowDown')) {
                e.preventDefault();
                e.stopImmediatePropagation();
                callback(key);
            }
        };
        // eslint-disable-next-line spellcheck/spell-checker
        const event = 'keyup';
        const options: AddEventListenerOptions = {};
        window.addEventListener(event, listener, options);
        return () => {
            window.removeEventListener(event, listener, options);
        };
    }, [callback]);
}
