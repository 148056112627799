import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseBaseMaterial } from '../parsers/parseBaseMaterial';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractBaseMaterial: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const attribute = parseBaseMaterial(stringToParse);
    return isPresent(attribute)
        ? [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute))]
        : [];
};
