import { MessageDescriptor } from '@lingui/core';
import { defineMessage, t } from '@lingui/macro';
import { debounce, transEnum } from '@luminovo/commons';
import { FieldSelect, Flexbox, FormItem, NonIdealState, TextField } from '@luminovo/design-system';
import { PriceType } from '@luminovo/http-client';
import { Refresh as RefreshIcon, Warning } from '@mui/icons-material';
import React from 'react';
import { instantiateParser } from '../../../../parsers/parsers';
import { GlobalField, ParseResult, ParsedValue } from '../../../../types';
import { UniversalImporterState, useUniversalImporter } from '../../context';

export const priceTypeTranslations: Record<PriceType, MessageDescriptor> = {
    [PriceType.QuotePrice]: defineMessage({ message: 'Quote price' }),
    [PriceType.ListPrice]: defineMessage({ message: 'List price' }),
    [PriceType.ContractPrice]: defineMessage({ message: 'Contract price' }),
    [PriceType.CustomerNegotiatedPrice]: defineMessage({ message: 'Customer negotiated price' }),
    [PriceType.PurchasePrice]: defineMessage({ message: 'Purchase price' }),
    [PriceType.StandardPrice]: defineMessage({ message: 'Standard price' }),
    [PriceType.TargetPrice]: defineMessage({ message: 'Target price' }),
};

export const GlobalFieldsForm = (): JSX.Element => {
    const { state } = useUniversalImporter();
    const {
        config: { globalFields },
    } = state;

    if (!globalFields || globalFields.length === 0)
        return (
            <NonIdealState
                Icon={Warning}
                title={t`No global fields defined.`}
                description={t`We were expecting global fields here but none have been defined. Try refreshing the page. If this problem persists, please contact our support team.`}
                action={{
                    onClick: () => window.location.replace(window.location.href),
                    variant: 'outlined',
                    color: 'primary',
                    startIcon: <RefreshIcon />,
                    children: t`Refresh`,
                }}
            />
        );
    return (
        <Flexbox flexDirection="column" gap={8}>
            {globalFields.map((field, index) => {
                if (field.parser.type === 'string') {
                    return <StringGlobalField key={index} field={field} />;
                }
                if (field.parser.type === 'priceType') {
                    return <SelectGlobalField key={index} field={field} />;
                }
                //@ts-expect-error
                throw new Error(`Unknown parser type: ${field.parser.type}`);
            })}
        </Flexbox>
    );
};

function useStateWithDebouncedEffect(
    field: GlobalField,
    onChange: (value: string) => Promise<ParseResult<string>>,
    debounceMillis: number,
): {
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
    result: ParseResult<string> | undefined;
} {
    const [text, setText] = React.useState(field.value?.id ?? '');
    const [result, setResult] = React.useState<ParseResult<string> | undefined>(undefined);

    // Create a ref for the debounced function to avoid recreating it on each render
    const debouncedOnChange = React.useRef(
        debounce(async (text: string) => {
            const response = await onChange(text);
            setResult(response);
        }, debounceMillis),
    ).current;

    // seEffect to call the debounced function whenever `text` changes
    React.useEffect(() => {
        debouncedOnChange(text);
    }, [text, debouncedOnChange]);

    return {
        text,
        setText,
        result,
    };
}

function StringGlobalField({ field }: { field: GlobalField }) {
    const { dispatch, state } = useUniversalImporter();
    const parseField = React.useMemo(() => {
        return instantiateParser(field.parser, field);
        // TODO: make the type of insantiateParser more specific so do not need to cast here
    }, [field]) as (cells: string[]) => Promise<ParseResult<string>>;

    const { text, setText, result } = useStateWithDebouncedEffect(
        field,
        async (newValue) => {
            // set the state to undefined to indicate that the field is being parsed
            dispatch(setGlobalField(state, field.id, undefined));

            const result = await parseField([newValue]);

            if (result.status === 'done' || result.status === 'warning') {
                if (field.required === true && result.value?.id === '') {
                    return {
                        status: 'error',
                        message: t`Required`,
                    };
                } else {
                    dispatch(setGlobalField(state, field.id, result.value));
                }
            }
            return result;
        },
        250,
    );

    return (
        <FormItem
            variant={'description-inlined'}
            label={field.label ?? ''}
            description={field.description}
            required={Boolean(field.required)}
        >
            <TextField
                size="large"
                value={text}
                error={Boolean(result?.status === 'error')}
                helperText={result?.message}
                onChange={(event) => {
                    setText(event.target.value);
                }}
            />
        </FormItem>
    );
}

const renderLabel = (priceType: PriceType | null) => {
    return priceType ? transEnum(priceType, priceTypeTranslations) : '';
};

function SelectGlobalField({ field }: { field: GlobalField }) {
    const { dispatch, state } = useUniversalImporter();
    const parseField = React.useMemo(() => {
        return instantiateParser(field.parser, field);
    }, [field]) as (cells: string[]) => Promise<ParseResult<string>>;

    const options = Object.values(PriceType);

    const value: PriceType | null = (field.value?.id as PriceType | null) ?? null;

    const handleChange = async (newValue: PriceType | null) => {
        dispatch(setGlobalField(state, field.id, undefined));

        if (newValue === null) {
            if (field.required) {
                return {
                    status: 'error',
                    message: t`Required`,
                };
            }
            dispatch(setGlobalField(state, field.id, undefined));
            return;
        }

        const result = await parseField([newValue]);

        if (result.status === 'done' || result.status === 'warning') {
            if (field.required === true && result.value?.id === '') {
                return {
                    status: 'error',
                    message: t`Required`,
                };
            } else {
                dispatch(setGlobalField(state, field.id, result.value));
            }
        }
        return result;
    };

    return (
        <FormItem
            variant={'description-inlined'}
            label={field.label ?? ''}
            description={field.description}
            required={Boolean(field.required)}
        >
            <FieldSelect
                value={value}
                options={options}
                onChange={handleChange}
                size="large"
                getOptionLabel={(v) => renderLabel(v)}
                renderOption={(v) => renderLabel(v)}
            />
        </FormItem>
    );
}

function setGlobalField(state: UniversalImporterState, fieldId: string, value?: ParsedValue<string>) {
    return {
        type: 'setGlobalFields' as const,
        newFields: (state.config.globalFields ?? []).map((f) => {
            if (f.id === fieldId) {
                return {
                    ...f,
                    value,
                };
            }
            return f;
        }),
    };
}
