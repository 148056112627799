/**
 * A generic highlighting utility. Takes a string and an array of regexes as input
 * and returns an array describing which sections of the string are highlighted(matched)
 * and which didn't.
 *
 * Note: every regex will produce at most a single match.
 */
export function highlight(
    str: string,
    ...sectionToHighlight: Array<string | RegExp>
): Array<{ content: string; isHighlighted: boolean }> {
    if (sectionToHighlight.length === 0) {
        return [{ content: str, isHighlighted: false }];
    }
    if (sectionToHighlight.length === 1) {
        return highlightSingle(str, sectionToHighlight[0]);
    }
    const [head, ...tail] = sectionToHighlight;
    const matches = highlightSingle(str, head);
    return matches.flatMap((match) => {
        if (match.isHighlighted) {
            return [match];
        }
        return highlight(match.content, ...tail);
    });
}

function highlightSingle(
    str: string,
    stringOrRegex: RegExp | string,
): Array<{ content: string; isHighlighted: boolean }> {
    if (str.length === 0) {
        return [{ isHighlighted: false, content: str }];
    }
    const regex = safeRegex(stringOrRegex);

    const match = regex.exec(str);
    if (!match) {
        return [{ isHighlighted: false, content: str }];
    }

    const index = match.index;

    const endIndex = index + match[0].length;

    return [
        { content: str.slice(0, index), isHighlighted: false },
        { content: str.slice(index, endIndex), isHighlighted: true },
        { content: str.slice(endIndex), isHighlighted: false },
    ].filter((s) => s.content.length > 0);
}

function escapeRegex(string: string): string {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

function safeRegex(string: string | RegExp): RegExp {
    if (typeof string === 'string') {
        return new RegExp(escapeRegex(string), 'gi');
    }
    return new RegExp(string, 'gi');
}

/**
 * Extracts all highlighted sections from backend response.
 *
 * Example:
 * ```typescript
 * const strings = [
 *   "This is a <em>highlighted</em> text",
 *   "This is another <em>highlighted</em> text"
 * ]
 *
 * const highlights = extractHighlights(strings);
 * console.log(highlights); // ["highlighted", "highlighted"]
 * ```
 */
export function extractHighlights(string: string[]): string[] {
    const highlights: string[] = [];
    for (const s of string) {
        highlights.push(...singleExtractHighlights(s));
    }
    return Array.from(new Set(highlights));
}

function singleExtractHighlights(string: string): string[] {
    const matches = string.matchAll(/<em>(.*?)<\/em>/g);
    const highlights: string[] = [];
    for (const match of matches) {
        highlights.push(match[1]);
    }
    return highlights;
}
