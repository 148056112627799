import { t } from '@lingui/macro';
import { Currency, formatPercentage } from '@luminovo/commons';
import {
    Flexbox,
    FlexboxProps,
    TertiaryIconButton,
    Text,
    TextField,
    TextFieldProps,
    colorSystem,
} from '@luminovo/design-system';
import { MonetaryValueBackend } from '@luminovo/http-client';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, InputAdornment, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { Path, UseFormReturn, get, useFormContext, useWatch } from 'react-hook-form';
import * as r from 'runtypes';
import { displayCurrencySymbol, formatCurrency } from '../../../../utils/currencyUtils';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { Cell, CellBlank, CellRule, CostSpecification, DynamicCostCell } from '../types/cellTypes';
import { CalculationTableForm } from '../types/formTypes';
import { CalculationFetchingText, CalculationFrozenText } from '../utils/calculationFrozenText';
import { formatCalculationPercentage } from '../utils/formatCalculationPercentage';
import { equivalentTextStyle, renderEquivalentValue } from '../utils/renderEquivalentValue';

export const blankCellStyle = {
    background: colorSystem.neutral[1],
    borderTop: `0px solid ${colorSystem.neutral[1]}`,
    borderBottom: `0px solid ${colorSystem.neutral[1]}`,
};

export const bufferCellStyle = {
    paddingTop: '20px',
    paddingBottom: '20px',
    borderTop: `10px solid ${colorSystem.neutral[1]}`,
    borderRight: `0px`,
    borderBottom: `0px`,
};

export const hiddenCellStyle = {
    padding: 0,
    border: 0,
};

const valueCellStyle = {
    borderTop: `0px solid ${colorSystem.neutral[1]}`,
    borderBottom: `1px solid ${colorSystem.neutral[1]}`,
};
export const CELL_HEIGHT = '36px';

const VALUE_OFFSET = 24;

export const FixedCell = ({
    cost,
    percentage,
    textStyle,
}: {
    cost: MonetaryValueBackend;
    percentage?: number;
    textStyle?: React.CSSProperties;
}): JSX.Element => {
    return (
        <TableCell style={valueCellStyle}>
            <Flexbox flexDirection={'row'} gap={VALUE_OFFSET}>
                <Text variant="body" style={textStyle}>
                    {formatCurrency(parseFloat(cost.amount), cost.currency)}
                </Text>
                {percentage !== undefined ? (
                    <Text style={equivalentTextStyle}> = {formatCalculationPercentage(percentage)}</Text>
                ) : undefined}
            </Flexbox>
        </TableCell>
    );
};

const textFieldProps = (error: Error): TextFieldProps => ({
    style: { width: 120 },
    error: error !== undefined,
    helperText: error?.message,
    size: 'large',
});

const textFieldOnChange = (
    valueString: string,
    field: Path<CalculationTableForm>,
    useFormReturn: UseFormReturn<CalculationTableForm>,
    rule: CellRule,
) => {
    const { setError, clearErrors, setValue } = useFormReturn;
    const error = rule(valueString);
    if (error.result === 'Error') {
        setError(field, { message: error.message });
    } else {
        clearErrors(field);
        setValue(field, valueString, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    }
};

const ErrorRuntype = r
    .Record({
        message: r.String,
        ref: r.Unknown,
    })
    .optional();
type Error = r.Static<typeof ErrorRuntype>;

const CostInput = ({ cellIndex, rowIndex }: { cellIndex: number; rowIndex: number }): JSX.Element => {
    const useFormReturn = useFormContext<CalculationTableForm>();
    const [fieldValue, setFieldValue] = React.useState(
        useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.costSpecification.value.amount`),
    );
    const isCalculationFrozen = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFrozen',
    });
    const isCalculationFetching = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFetching',
    });

    const error = ErrorRuntype.check(
        get(useFormReturn.formState.errors, `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value.amount`),
    );

    const generateInputProps = ({ textStyle }: { textStyle?: React.CSSProperties }): TextFieldProps['InputProps'] => ({
        startAdornment: (
            <InputAdornment position="start">
                <Text style={textStyle}>
                    {displayCurrencySymbol(
                        useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.costSpecification.value.currency`),
                    )}
                </Text>
            </InputAdornment>
        ),
    });

    if (isCalculationFrozen || isCalculationFetching) {
        return (
            <Tooltip title={isCalculationFrozen ? <CalculationFrozenText /> : <CalculationFetchingText />}>
                <TextField
                    value={fieldValue}
                    type="number"
                    disabled={true}
                    InputProps={generateInputProps({ textStyle: { color: colorSystem.neutral[5] } })}
                    {...textFieldProps(error)}
                />
            </Tooltip>
        );
    }

    return (
        <TextField
            value={fieldValue}
            type="number"
            onChange={(valueString) => {
                setFieldValue(valueString.currentTarget.value);
            }}
            onBlur={(valueString) => {
                textFieldOnChange(
                    Number(valueString.currentTarget.value).toString(),
                    `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value.amount`,
                    useFormReturn,
                    useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.rule`),
                );
                if (fieldValue === '') setFieldValue('0');
            }}
            {...textFieldProps(error)}
            InputProps={generateInputProps({})}
        />
    );
};

const FractionInput = ({ cellIndex, rowIndex }: { cellIndex: number; rowIndex: number }): JSX.Element => {
    const useFormReturn = useFormContext<CalculationTableForm>();
    const [fieldValue, setFieldValue] = React.useState(
        useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.costSpecification.value`),
    );

    const error = ErrorRuntype.check(
        get(useFormReturn.formState.errors, `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value`),
    );

    const isCalculationFrozen = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFrozen',
    });
    const isCalculationFetching = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFetching',
    });

    const generateInputProps = ({ textStyle }: { textStyle?: React.CSSProperties }): TextFieldProps['InputProps'] => ({
        endAdornment: (
            <InputAdornment position="end">
                <Text style={textStyle}>%</Text>
            </InputAdornment>
        ),
    });

    if (isCalculationFrozen || isCalculationFetching) {
        return (
            <Tooltip title={isCalculationFrozen ? <CalculationFrozenText /> : <CalculationFetchingText />}>
                <TextField
                    value={fieldValue}
                    type="number"
                    disabled={true}
                    InputProps={generateInputProps({ textStyle: { color: colorSystem.neutral[5] } })}
                    {...textFieldProps(error)}
                />
            </Tooltip>
        );
    }
    return (
        <TextField
            onChange={(valueString) => {
                setFieldValue(valueString.currentTarget.value);
            }}
            type="number"
            value={fieldValue}
            onBlur={(valueString) => {
                textFieldOnChange(
                    Number(valueString.currentTarget.value).toString(),
                    `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value`,
                    useFormReturn,
                    useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.rule`),
                );
                if (fieldValue === '') setFieldValue('0');
            }}
            {...textFieldProps(error)}
            InputProps={generateInputProps({})}
        />
    );
};

function assertIsFormula(cost: CostSpecification) {
    if (cost.type === 'formula-fixed' || cost.type === 'formula-fraction') {
        return cost;
    }
    throw new Error(`should have type formula but instead received ${cost.type}`);
}

const FormulaInput = ({ cellIndex, rowIndex }: { cellIndex: number; rowIndex: number }): JSX.Element => {
    const useFormReturn = useFormContext<CalculationTableForm>();
    const costSpecification = useWatch({
        control: useFormReturn.control,
        name: `rows.${rowIndex}.cells.${cellIndex}.costSpecification`,
    });
    const cost = assertIsFormula(costSpecification);
    const [fieldValue, setFieldValue] = React.useState(cost.value);
    const error = ErrorRuntype.check(
        get(useFormReturn.formState.errors, `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value`),
    );
    const resetToTemplateFormula = () => {
        useFormReturn.setValue(`rows.${rowIndex}.cells.${cellIndex}.costSpecification.isOverwritten`, false, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    const isCalculationFrozen = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFrozen',
    });
    const isCalculationFetching = useWatch({
        control: useFormReturn.control,
        name: 'isCalculationFetching',
    });

    const generateInputProps = ({ textStyle }: { textStyle?: React.CSSProperties }): TextFieldProps['InputProps'] => ({
        startAdornment:
            cost.type === 'formula-fixed' ? (
                <InputAdornment position={'start'}>
                    {<Text style={textStyle}>{displayCurrencySymbol(cost.currency)}</Text>}
                </InputAdornment>
            ) : undefined,
        endAdornment:
            cost.type === 'formula-fraction' ? (
                <InputAdornment position="end">{<Text style={textStyle}>%</Text>}</InputAdornment>
            ) : undefined,
    });

    if (isCalculationFrozen || isCalculationFetching) {
        return (
            <Tooltip title={isCalculationFetching ? <CalculationFrozenText /> : <CalculationFetchingText />}>
                <TextField
                    value={fieldValue}
                    type="number"
                    disabled={true}
                    InputProps={generateInputProps({ textStyle: { color: colorSystem.neutral[5] } })}
                    {...textFieldProps(error)}
                />
            </Tooltip>
        );
    }

    return (
        <Flexbox alignItems="center" gap={8}>
            <TextField
                onChange={(valueString) => {
                    setFieldValue(valueString.currentTarget.value);
                }}
                type="number"
                value={fieldValue}
                onBlur={(valueString) => {
                    textFieldOnChange(
                        Number(valueString.currentTarget.value).toString(),
                        `rows.${rowIndex}.cells.${cellIndex}.costSpecification.value`,
                        useFormReturn,
                        useFormReturn.watch(`rows.${rowIndex}.cells.${cellIndex}.rule`),
                    );
                    useFormReturn.setValue(`rows.${rowIndex}.cells.${cellIndex}.costSpecification.isOverwritten`, true);
                    if (fieldValue === '') setFieldValue('0');
                }}
                InputProps={generateInputProps({})}
                {...textFieldProps(error)}
            />

            <TertiaryIconButton onClick={resetToTemplateFormula}>
                <Tooltip title={t`Reset to template value`}>
                    <ReplayIcon fontSize="inherit" />
                </Tooltip>
            </TertiaryIconButton>
        </Flexbox>
    );
};

const FixedFormula = ({ value, currency }: { value: string; currency: Currency }): JSX.Element => {
    return <Text>{formatCurrency(value, currency)}</Text>;
};

const FractionFormula = ({ value }: { value: string }): JSX.Element => {
    return <Text style={textStyle}>{formatCalculationPercentage(value)}</Text>;
};

const DynamicCellUnlocked = ({
    cell,
    rowIndex,
    cellIndex,
}: {
    cell: DynamicCostCell;
    rowIndex: number;
    cellIndex: number;
}): JSX.Element => {
    const cost = cell.costSpecification;
    const costType = cost.type;
    switch (costType) {
        case 'fixed':
            return (
                <DynamicCellStructure
                    slotOne={<CostInput rowIndex={rowIndex} cellIndex={cellIndex} />}
                    slotTwo={<Box ml={'8px'}>{renderEquivalentValue(cost.type, cell)}</Box>}
                    FlexboxProps={{ alignItems: 'center' }}
                />
            );
        case 'fraction':
            return (
                <DynamicCellStructure
                    slotOne={<FractionInput rowIndex={rowIndex} cellIndex={cellIndex} />}
                    slotTwo={<Box ml={'8px'}>{renderEquivalentValue(cost.type, cell)}</Box>}
                    FlexboxProps={{ alignItems: 'center' }}
                />
            );
        case 'formula-fixed':
            return (
                <DynamicCellStructure
                    slotOne={<FormulaInput cellIndex={cellIndex} rowIndex={rowIndex} />}
                    slotTwo={<Box ml={'8px'}>{renderEquivalentValue(cost.type, cell)}</Box>}
                    FlexboxProps={{ alignItems: 'center' }}
                />
            );
        case 'formula-fraction':
            return (
                <DynamicCellStructure
                    slotOne={<FormulaInput cellIndex={cellIndex} rowIndex={rowIndex} />}
                    slotTwo={<Box ml={'8px'}>{renderEquivalentValue(cost.type, cell)}</Box>}
                    FlexboxProps={{ alignItems: 'center' }}
                />
            );
        default:
            assertUnreachable(costType);
    }
};

const textStyle = {
    color: colorSystem.neutral[8],
    variant: 'body-small',
    width: 80,
};

function renderCost(value: string, currency: Currency) {
    return <Text style={textStyle}> {formatCurrency(parseFloat(value), currency)}</Text>;
}

function renderFraction(value: string) {
    return <Text style={textStyle}> {formatPercentage(parseFloat(value) / 100)}</Text>;
}

const DynamicCellStructure = ({
    slotOne,
    slotTwo,
    FlexboxProps = { gap: 12 },
}: {
    slotOne: React.ReactNode;
    slotTwo: React.ReactNode;
    FlexboxProps?: FlexboxProps;
}) => {
    return (
        <TableCell style={valueCellStyle}>
            <Flexbox {...FlexboxProps}>
                {slotOne}
                {slotTwo}
            </Flexbox>
        </TableCell>
    );
};

const DynamicCellLocked = ({ cell }: { cell: DynamicCostCell }): JSX.Element => {
    const cost = cell.costSpecification;
    const costType = cost.type;
    switch (costType) {
        case 'fixed':
            return (
                <DynamicCellStructure
                    slotOne={renderCost(cell.unitCostValue.amount, cell.unitCostValue.currency)}
                    slotTwo={renderEquivalentValue(cost.type, cell)}
                />
            );
        case 'fraction':
            return (
                <DynamicCellStructure
                    slotOne={renderFraction(cell.costFraction)}
                    slotTwo={renderEquivalentValue(cost.type, cell)}
                />
            );
        case 'formula-fixed':
            return (
                <DynamicCellStructure
                    slotOne={<FixedFormula value={cost.value} currency={cell.unitCostValue.currency} />}
                    slotTwo={renderEquivalentValue(cost.type, cell)}
                />
            );
        case 'formula-fraction':
            return (
                <DynamicCellStructure
                    slotOne={<FractionFormula value={cost.value} />}
                    slotTwo={renderEquivalentValue(cost.type, cell)}
                />
            );
        default:
            assertUnreachable(costType);
    }
};

const DynamicCell = ({
    cell,
    rowIndex,
    cellIndex,
}: {
    cell: DynamicCostCell;
    rowIndex: number;
    cellIndex: number;
}): JSX.Element => {
    if (cell.isLocked) {
        return <DynamicCellLocked cell={cell} />;
    } else {
        return <DynamicCellUnlocked cell={cell} cellIndex={cellIndex} rowIndex={rowIndex} />;
    }
};

export const BlankCell = ({ cell }: { cell: CellBlank }): JSX.Element => {
    return <TableCell style={blankCellStyle} />;
};

const breakdownCellTextStyle: React.CSSProperties = {
    color: colorSystem.neutral[6],
    paddingLeft: '30px',
};

export const BreakdownCell = ({ cost }: { cost: MonetaryValueBackend | undefined }): JSX.Element => {
    if (cost) {
        return <FixedCell cost={cost} textStyle={breakdownCellTextStyle} />;
    }

    return (
        <TableCell
            style={{
                background: colorSystem.neutral.white,
                borderTop: `0px solid ${colorSystem.neutral[1]}`,
                borderBottom: `1px solid ${colorSystem.neutral[1]}`,
            }}
        >
            <Text style={breakdownCellTextStyle}>-</Text>
        </TableCell>
    );
};

export const BatchCell = ({
    cell,
    rowIndex,
    cellIndex,
    textStyle,
}: {
    cell: Cell;
    rowIndex: number;
    cellIndex: number;
    textStyle: React.CSSProperties;
}): JSX.Element => {
    const type = cell.type;
    switch (type) {
        case 'blank':
            return <BlankCell cell={cell} />;
        case 'buffer':
            return <TableCell style={bufferCellStyle} />;
        case 'dynamic':
            return (
                <DynamicCell
                    key={JSON.stringify(cell.costSpecification.value)}
                    cell={cell}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                />
            );
        case 'fixed':
            return <FixedCell cost={cell.unitCost} textStyle={textStyle} />;
        case 'fixed-percentage':
            return (
                <FixedCell
                    cost={cell.unitCost}
                    percentage={(parseFloat(cell.unitCost.amount) / parseFloat(cell.denominatorValue.amount)) * 100}
                    textStyle={textStyle}
                />
            );
        case 'breakdown':
            return <BreakdownCell cost={cell.cost?.unitCost} />;
        default:
            assertUnreachable(type);
    }
};
