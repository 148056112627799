import { Trans } from '@lingui/macro';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { RichTextEditor } from '@luminovo/rich-text-editor';
import { Box, Link } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { route } from '../../../../../utils/routes';
import { createVars } from '../createVars';
import { EmailManagerFormState } from '../types';

export function FormItemBody({ index }: { index: number }): JSX.Element {
    const { control } = useFormContext<EmailManagerFormState>();

    const templateId = useWatch({ control, name: `supplierEmails.${index}.email.template.id` });

    return (
        <Flexbox sx={{ flexDirection: 'column', gap: 1 }}>
            <Box sx={{ boxSizing: 'border-box', border: `1px solid ${colorSystem.neutral[3]}`, borderRadius: 1 }}>
                <Controller
                    control={control}
                    name={`supplierEmails.${index}.email.body`}
                    render={({ field }) => {
                        return (
                            <RichTextEditor
                                key={templateId}
                                namespace="quote-requests::subject"
                                vars={createVars()}
                                hideVariables
                                initialHtml={field.value}
                                onChange={field.onChange}
                                autosaveEveryMillis={2000}
                            />
                        );
                    }}
                />
            </Box>

            <Text>
                <Trans>
                    Configure this email template{' '}
                    <Link
                        target="_blank"
                        href={
                            templateId
                                ? route('/settings/organization/email-templates/:id/update', { id: templateId })
                                : route('/settings/organization/email-templates')
                        }
                    >
                        here.
                    </Link>
                </Trans>
            </Text>
        </Flexbox>
    );
}
