import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, styled, tooltipClasses } from '@mui/material';
import React from 'react';
import { bodySmall, colorSystem } from '../../theme';

export type TooltipProps = Omit<MuiTooltipProps, 'variant' | 'title'> & {
    variant?: 'dark' | 'white';
    title: NonNullable<React.ReactNode>;
    /**
     * Disables the default max-width of the tooltip.
     */
    disableMaxWidth?: boolean;
};

const defaultStyles = { ...bodySmall };

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        ...defaultStyles,
    },
});

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: colorSystem.neutral[7],
        backgroundColor: colorSystem.neutral.white,
        boxShadow: `0px 2px 8px rgba(59, 58, 66, 0.12)`,
        ...defaultStyles,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: colorSystem.neutral.white,
    },
});

const DarkToolTipWithNoMaxWidth = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        ...defaultStyles,
        maxWidth: 'none',
    },
});

const WhiteToolTipWithNoMaxWidth = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        ...defaultStyles,
        color: colorSystem.neutral[7],
        backgroundColor: colorSystem.neutral.white,
        boxShadow: `0px 2px 8px rgba(59, 58, 66, 0.12)`,
        maxWidth: 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: colorSystem.neutral.white,
    },
});

/**
 * Tooltip component customized with Material UI styling and additional variants.
 *
 * @param title The text or element to display inside the tooltip.
 *
 * @param variant The variant of the tooltip: 'dark' or 'white'.
 *                Defaults to 'dark'.
 *
 * @param disableMaxWidth Disables the default max-width of the tooltip.
 */
export const Tooltip = React.forwardRef<React.Ref<HTMLDivElement>, TooltipProps>(
    ({ children, title, variant = 'dark', disableMaxWidth, ...props }, ref) => {
        if (disableMaxWidth) {
            switch (variant) {
                case 'dark':
                    return (
                        <DarkToolTipWithNoMaxWidth ref={ref} title={title} {...props}>
                            {children}
                        </DarkToolTipWithNoMaxWidth>
                    );
                case 'white':
                    return (
                        <WhiteToolTipWithNoMaxWidth ref={ref} title={title} {...props}>
                            {children}
                        </WhiteToolTipWithNoMaxWidth>
                    );
            }
        } else {
            switch (variant) {
                case 'dark':
                    return (
                        <DarkTooltip ref={ref} title={title} {...props}>
                            {children}
                        </DarkTooltip>
                    );
                case 'white':
                    return (
                        <WhiteTooltip ref={ref} title={title} arrow={false} {...props}>
                            {children}
                        </WhiteTooltip>
                    );
            }
        }
    },
);
