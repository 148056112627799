export function compareByDate<T>(
    dateExtractor: (item: T) => string,
    opts?: { type: 'ascending' | 'descending' },
): (a: T, b: T) => number {
    return (a: T, b: T): number => {
        const aDate = dateExtractor(a);
        const bDate = dateExtractor(b);
        const result = new Date(aDate).getTime() - new Date(bDate).getTime();
        if (opts?.type === 'ascending') {
            return result;
        }
        return -result;
    };
}
