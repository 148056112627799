import { compareByString } from '@luminovo/commons';
import {
    ALL_CATEGORY_SELECTION_OPTIONS,
    ALL_PROCESS_SELECTION_OPTIONS,
    CategorySelection,
    ProcessSelection,
} from '@luminovo/http-client';
import { InternalNumberComparable } from '../comparators';

interface SelectionOption {
    process: ProcessSelection;
    category: CategorySelection;
}

interface SortableEntity extends SelectionOption, InternalNumberComparable {}

function compareByProcess(a: SelectionOption, b: SelectionOption) {
    return ALL_PROCESS_SELECTION_OPTIONS.indexOf(a.process) - ALL_PROCESS_SELECTION_OPTIONS.indexOf(b.process);
}

function compareByCategory(a: SelectionOption, b: SelectionOption) {
    return ALL_CATEGORY_SELECTION_OPTIONS.indexOf(a.category) - ALL_CATEGORY_SELECTION_OPTIONS.indexOf(b.category);
}

function compareByName(a: SortableEntity, b: SortableEntity) {
    return compareByString(a.name, b.name);
}

export function sortByProcessThenCategoryThenName<T extends SortableEntity>(array: T[]): T[] {
    return Array.from(array).sort((a, b) => compareByProcess(a, b) || compareByCategory(a, b) || compareByName(a, b));
}
