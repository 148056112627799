import { DataTable, useDataTableState } from '@luminovo/design-system';
import { ManufacturingAssemblyDetailsOverviewDTO, ManufacturingScenarioOverviewDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useIsOverflow } from '../../../../../components/TooltipOnOverflow';
import { route } from '../../../../../utils/routes';
import { useManufacturingAssemblyScenarioTableColumns } from './manufacturingAssemblyScenarioTableColumns';

const ManufacturingScenarioTable = ({
    manufacturingAssemblyDetailsOverview,
    setShowSourcingCacheText,
}: {
    manufacturingAssemblyDetailsOverview: ManufacturingAssemblyDetailsOverviewDTO;
    setShowSourcingCacheText: (val: boolean) => void;
}) => {
    const { rfqId } = useParams<{
        rfqId: string;
    }>();

    const {
        manufacturing_assembly_details: { id: manufacturingAssemblyDetailsId, assembly_id: assemblyId },
        manufacturing_scenarios: manufacturingScenarioOverviews,
    } = manufacturingAssemblyDetailsOverview;

    const history = useHistory();
    const handleRowClick = React.useCallback(
        (manufacturingScenario: ManufacturingScenarioOverviewDTO) => {
            history.push(
                route('/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity', {
                    rfqId,
                    manufacturingAssemblyDetailsId,
                    manufacturingScenarioId: manufacturingScenario.id,
                }),
            );
        },
        [history, manufacturingAssemblyDetailsId, rfqId],
    );

    const ref = React.useRef<HTMLDivElement>(null);
    const isTableOverflowing = useIsOverflow(ref);

    const columns = useManufacturingAssemblyScenarioTableColumns(
        assemblyId,
        manufacturingScenarioOverviews,
        isTableOverflowing ?? false,
    );
    const dataTableState = useDataTableState({
        columns: columns,
        items: manufacturingScenarioOverviews,
        persistenceId: 'manufacturingScenariosForAssemblyDetails' + manufacturingAssemblyDetailsId,
        paginationOptions: { showPagination: false },
    });

    setShowSourcingCacheText(manufacturingScenarioOverviews.some((overview) => overview.sourcing_scenario !== null));

    return (
        <Box ref={ref} style={{ marginTop: '20px' }}>
            <DataTable
                key="manufacturing-scenario-table"
                tableState={dataTableState}
                onItemClick={handleRowClick}
                size={'medium'}
            />
        </Box>
    );
};

export default ManufacturingScenarioTable;
