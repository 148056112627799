import { Trans } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { colorSystem, Flexbox, SecondaryButton, TertiaryButton, Text } from '@luminovo/design-system';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { MonacoEditor } from '../../../../components/MonacoEditor';
import { MonacoEditorRef } from '../../../../components/MonacoEditor/MonacoEditor';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import { useGlobalCurrency } from '../../../../resources/organizationSettings/currencySettingsHandler';
import { AdvancedFormulaDialog, DialogValue } from '../../shared/AdvancedFormulaDialog/AdvancedFormulaDialog';
import { useManufacturingInputControls } from '../../utils/useInputControls';
import { AddExpenseFormInputs } from '../utils/AddExpenseFormTypes';

export const FormulaCalculationSection = (): JSX.Element => {
    const field = 'formulaCostCalculation';
    const { setValue, watch } = useFormContext<AddExpenseFormInputs>();
    const formulaValue = watch(`${field}.formula`);
    const unitValue = watch(`${field}.currency`);
    const editorRef: MonacoEditorRef = React.useRef();
    const { preferredCurrency } = useGlobalCurrency();

    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    function closeDialog() {
        setIsDialogOpen(false);
    }

    const { inputControls, isLoading } = useManufacturingInputControls(formulaValue);
    const deleteFormula = () => {
        setValue(`${field}.formula`, '', { shouldValidate: true });
        setValue(`${field}.currency`, preferredCurrency ?? Currency.EUR, { shouldValidate: true });
    };

    const dialogSubmitOnClick = (dialogValue: DialogValue<Currency>) => {
        const currency = dialogValue.unit;

        setValue(`${field}.formula`, dialogValue.formula ?? '', { shouldValidate: true });
        setValue(`${field}.currency`, currency, { shouldValidate: true });
    };
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {formulaValue.length > 0 ? (
                        <Box marginLeft={'16px'}>
                            <Flexbox alignItems={'center'} justifyContent={'space-between'} style={{ width: '477px' }}>
                                <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                                    <Trans>Unit cost formula</Trans>
                                </Text>
                                <Flexbox style={{ marginRight: '-8px' }} gap={'0px'}>
                                    <TertiaryButton
                                        startIcon={<Edit fontSize="inherit" />}
                                        size="small"
                                        onClick={() => setIsDialogOpen(true)}
                                    >
                                        <Trans>Edit formula</Trans>
                                    </TertiaryButton>
                                    <TertiaryButton
                                        startIcon={<Delete fontSize="inherit" />}
                                        style={{ marginLeft: '8px' }}
                                        size="small"
                                        onClick={deleteFormula}
                                    >
                                        <Trans> Delete formula</Trans>
                                    </TertiaryButton>
                                </Flexbox>
                            </Flexbox>
                            <AdvancedFormulaDialog
                                onSubmitClick={dialogSubmitOnClick}
                                initialFormValue={formulaValue}
                                isDialogOpen={isDialogOpen}
                                onReject={closeDialog}
                                initialUnit={unitValue}
                                unitTranslations={inputCurrenciesPublicTranslations}
                                inputControls={inputControls}
                                isLoading={isLoading}
                            />
                            <Box width={'477px'}>
                                {isLoading ? (
                                    <SpinnerWithBackdrop noBackdrop />
                                ) : (
                                    <MonacoEditor
                                        value={formulaValue}
                                        readOnly
                                        inputControls={inputControls}
                                        editorRef={editorRef}
                                    />
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Box marginLeft={'16px'}>
                            <SecondaryButton
                                style={{ marginBottom: '12px' }}
                                variant={'outlined'}
                                onClick={() => setIsDialogOpen(true)}
                            >
                                + <Trans> Build formula </Trans>
                            </SecondaryButton>
                            <AdvancedFormulaDialog
                                onSubmitClick={dialogSubmitOnClick}
                                onReject={closeDialog}
                                initialFormValue={formulaValue}
                                initialUnit={unitValue}
                                isDialogOpen={isDialogOpen}
                                unitTranslations={inputCurrenciesPublicTranslations}
                                inputControls={inputControls}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
