import { t } from '@lingui/macro';
import { Flexbox, TanStackTable, colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayoutSidebar } from '../../components/PageLayoutSidebar';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { ErrorFallback } from '../../components/errorHandlers/ErrorBoundary';
import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { UrlParams } from '../../utils/routes';
import { TopLevelAssemblySidebar } from './components/AssemblySidebar/TopLevelAssemblySidebar';
import { dashboardWidth, useBomOverviewTable } from './components/BomHealthDashboard/BomOverviewTable/BomOverview';
import { ModuleTableData, ViewContext } from './components/ModuleTableData';
import { useBOMTableData } from './components/ModuleTableData/useBOMTableData';
import { ToolbarBom } from './components/ToolbarBom';
import { useChangeBomPageIfSingleAssembly } from './components/useChangeBomPageIfSingleAssembly';

const TopLevelAssembliesPage = ({ pathParams }: UrlParams<'/rfqs/:rfqId/bom'>): JSX.Element => {
    const { rfqId } = pathParams;
    const { data: rfq, isLoading: isRfqLoading } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    const { moduleData, isLoading: isModuleDataLoading } = useBOMTableData({ rfqId, assemblyId: rfqId });
    const { data: assembly, isLoading: isAssemblyLoading } = useAssembly(rfqId);

    const isLoading = isRfqLoading || isAssemblyLoading;

    useChangeBomPageIfSingleAssembly({
        rfqId,
        assemblyId: rfqId,
    });

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <PageLayoutSidebar
            defaultSidebarWidth="300px"
            sidebar={<TopLevelAssemblySidebar assemblyId={rfqId} rfqId={rfqId} />}
            header={
                <ToolbarBom
                    rfqId={rfqId}
                    assemblyId={rfqId}
                    isRfqEditable={isRfqEditable}
                    currentParentAssemblyId={rfqId}
                />
            }
            contentBackground={colorSystem.neutral[1]}
        >
            {isLoading || !assembly ? (
                <SpinnerWithBackdrop />
            ) : (
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <Flexbox padding="20px" gap={20} minWidth="fit-content" justifyContent="center">
                        <LayoutCard
                            title={t`Assemblies`}
                            style={{ maxWidth: dashboardWidth, minWidth: '1200px', height: '80vh' }}
                        >
                            <BomPageInner
                                viewContext={viewContext}
                                assemblyId={rfqId}
                                moduleData={moduleData}
                                isBomMainPage={true}
                                isModuleDataLoading={isModuleDataLoading}
                            />
                        </LayoutCard>
                    </Flexbox>
                </Sentry.ErrorBoundary>
            )}
        </PageLayoutSidebar>
    );
};

export default TopLevelAssembliesPage;

const BomPageInner = ({
    assemblyId,
    moduleData,
    isBomMainPage,
    viewContext,
    isModuleDataLoading,
}: {
    assemblyId: string;
    moduleData: ModuleTableData[];
    isBomMainPage: boolean;
    viewContext: ViewContext;
    isModuleDataLoading: boolean;
}) => {
    const { table } = useBomOverviewTable({
        items: isModuleDataLoading ? undefined : moduleData,
        isBomMainPage,
        assemblyId,
        viewContext,
    });
    return (
        <Box height="100%">
            <TanStackTable table={table} size="medium" />
        </Box>
    );
};
