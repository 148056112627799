import { t } from '@lingui/macro';
import { colorSystem, Link } from '@luminovo/design-system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { CreateAlertFormContainer } from './CreateAlertFormContainer';
import { EditAlertFormContainer } from './EditAlertFormContainer';

interface AlertModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
}

const AlertModal: React.FunctionComponent<AlertModalProps> = ({ title, isOpen, onClose, children }) => {
    return (
        <Dialog open={isOpen}>
            <DialogTitle
                style={{ padding: '24px 20px 14px 20px', boxShadow: `inset 0px -1px 0px ${colorSystem.neutral['3']}` }}
            >
                <Grid container>
                    <Grid item xs>
                        <Typography variant={'h1'} color={'textSecondary'} style={{ padding: 0 }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item style={{ justifyContent: 'flex-end' }}>
                        <Link onClick={onClose}>
                            <CloseRoundedIcon style={{ cursor: 'pointer', color: colorSystem.neutral[7] }} />
                        </Link>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '0px',
                    margin: '24px 14px 16px 14px',
                    paddingBottom: '16px',
                    minWidth: '500px',
                    minHeight: '500px',
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};

export function useCreateAlertDialog() {
    const { setDialog, closeDialog } = useDialogContext();
    return {
        closeDialog,
        openDialog: (partId: string) => {
            setDialog(
                <AlertModal title={t`Create alert`} isOpen={true} onClose={closeDialog}>
                    <CreateAlertFormContainer partId={partId} onClose={closeDialog} />
                </AlertModal>,
            );
        },
    };
}

export function useEditAlertDialog() {
    const { setDialog, closeDialog } = useDialogContext();
    return {
        closeDialog,
        openDialog: (alertId: string) => {
            setDialog(
                <AlertModal title={t`Edit alert`} isOpen={true} onClose={closeDialog}>
                    <EditAlertFormContainer alertId={alertId} onClose={closeDialog} />
                </AlertModal>,
            );
        },
    };
}

export function useDeleteAlertDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation('DELETE /alerts/stock-level/:id', {
        snackbarMessage: t`Alert successfully deleted`,
        onSuccess: () => closeDialog(),
    });

    return {
        closeDialog,
        openDialog: (alertId: string) => {
            setDialog(
                <ConfirmationDialogBox
                    title={t`Delete alert`}
                    text={t`Are you sure you want to delete this alert?`}
                    onConfirm={() =>
                        mutateAsync({
                            pathParams: { id: alertId },
                        })
                    }
                    isDialogOpen={true}
                    onReject={closeDialog}
                />,
            );
        },
    };
}
