import { Breadcrumbs, Divider, styled, Typography } from '@mui/material';
import { colorSystem } from '../../../themes';
export const DividerWithMargins = styled(Divider)({
    marginTop: 12,
    marginBottom: 12,
});

export const StyledBreadcrumbs = ({ breadcrumbs }: { breadcrumbs: string[] }): JSX.Element => {
    const allButLastBreadcrumbs = breadcrumbs.slice(0, -1);
    const [lastBreadcrumb] = breadcrumbs.slice(-1);
    return (
        <Breadcrumbs separator={'>'}>
            {allButLastBreadcrumbs.map((breadcrumb, i) => (
                <Typography
                    key={i}
                    variant={'h5'}
                    style={{
                        color: colorSystem.neutral[6],
                        maxWidth: 200,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {breadcrumb}
                </Typography>
            ))}
            <Typography
                variant={'h5'}
                style={{
                    color: colorSystem.neutral[8],
                    maxWidth: 200,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {lastBreadcrumb}
            </Typography>
        </Breadcrumbs>
    );
};
