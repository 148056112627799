import { t } from '@lingui/macro';
import { FieldMultiSelectControlled, FormItem } from '@luminovo/design-system';
import { FilterOptionsState } from '@mui/material';
import { Control, Path, useWatch } from 'react-hook-form';
import { AddManufacturingScenarioFormState, BaseManufacturingScenarioFormState } from '../types';

const createFieldMultiSelectControlledFieldProps = (existingBatchSizes: number[]) => {
    return {
        options: [],
        getOptionLabel: (value: number) => value.toString(),
        getOptionKey: (value: number) => String(value),
        filterOptions: (_: number[], params: FilterOptionsState<number>) => {
            const { inputValue } = params;
            const value = parseInt(inputValue);
            return isNaN(value) ? [] : [value];
        },
        renderOption: (value: number) => {
            const text = existingBatchSizes.includes(value) ? t`Remove` : t`Add`;
            return <span>{`${text} ${value}`}</span>;
        },
        placeholder: t`What batch sizes do you account for?`,
        noOptionsText: t`Please enter a batch size`,
    };
};

/**
 * IMPORTANT: Please keep in sync with BatchSizesMultiSelectControlled
 */
export const BatchSizesMultiSelectControlled = ({
    control,
    width,
}: {
    control: Control<BaseManufacturingScenarioFormState>;
    width?: string;
}) => {
    const name: Path<BaseManufacturingScenarioFormState> = 'batchSizes';
    const existingBatchSizes = useWatch({
        control,
        name,
    });
    return (
        <FormItem label={t`Batch sizes`} width={width ?? '100%'} required>
            <FieldMultiSelectControlled
                name={name}
                control={control}
                required
                FieldProps={createFieldMultiSelectControlledFieldProps(existingBatchSizes)}
            />
        </FormItem>
    );
};

/**
 * IMPORTANT: Please keep in sync with BatchSizesMultiSelectControlled
 */
export const BatchSizesMultiSelectControlledAdd = ({
    control,
    width,
}: {
    control: Control<AddManufacturingScenarioFormState>;
    width?: string;
}) => {
    const name: Path<AddManufacturingScenarioFormState> = 'batchSizes';
    const existingBatchSizes = useWatch({
        control,
        name,
    });
    return (
        <FormItem label={t`Batch sizes`} width={width ?? '100%'} required>
            <FieldMultiSelectControlled
                name={name}
                required
                control={control}
                FieldProps={createFieldMultiSelectControlledFieldProps(existingBatchSizes)}
            />
        </FormItem>
    );
};
