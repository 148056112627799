// eslint-disable-next-line no-restricted-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import * as Yup from 'yup';

// fhur: when we upgraded react-hook-form and @hookform/resolver the types got stricter.
// There was to possibilities to solve the compilation errors:
// 1. We could either patch all the Yup schemas to be compatible with the new types.
// 2. Or we could cast the resolver to any.
//
// Since we're getting rid of Yup in favor of inlining the validation logic, we're going with option 2.
export const deprecatedYupResolver = (schema: Yup.Schema<any>): Resolver<any> => yupResolver(schema) as Resolver<any>;
