/* eslint-disable spellcheck/spell-checker */
export const de = {
    consentModal: {
        title: '🍪 Wir setzen Cookies ein!',
        description:
            'Hallo, diese Website verwendet wesentliche Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren, und unterstützt Cookies für den Kundensupport und die Problemverfolgung. Die beiden letzteren werden erst nach Zustimmung gesetzt. Wenn Sie uns Ihre Einwilligung erteilen, können Ihre Daten außerhalb des EWR verarbeitet werden.',
        acceptAllBtn: 'Alle akzeptieren',
        acceptNecessaryBtn: 'Alle ablehnen',
        showPreferencesBtn: 'Mehr Optionen',
    },
    preferencesModal: {
        title: '🍪 Wir setzen Cookies ein!',
        acceptAllBtn: 'Alle akzeptieren',
        acceptNecessaryBtn: 'Alle ablehnen',
        savePreferencesBtn: 'Einstellungen speichern',
        closeIconLabel: 'Schließen',
        sections: [
            {
                title: 'Nutzung von Cookies 📢',
                description:
                    'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige Datenschutzerklärung.',
            },
            {
                title: 'Notwendige Cookies',
                description:
                    'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=97025cec003c4f7b8c6a7dfb883a7906" rel="noopener noreferrer" target="_blank" class="cc-link">Cookie-Informationen anzeigen</a>',
                linkedCategory: 'required',
            },
            {
                title: 'Performance-und-Analytics-Cookies',
                description:
                    'Diese Cookies ermöglichen es uns, die Performance und Nutzung des Produkts besser zu verstehen. Wir verwenden diese Informationen, um das Benutzererlebnis und die Performance des Produkts zu verbessern. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=c2589aa5a8824a9785dc94561a3e0481" rel="noopener noreferrer" target="_blank" class="cc-link">Cookie-Informationen anzeigen</a>',
                linkedCategory: 'analytics',
            },
            {
                title: 'Support-Cookies',
                description:
                    'Diese Cookies ermöglichen es uns, unseren Nutzern Unterstützung direkt auf der Website anzubieten. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=7366ed2f10264209893b54184b1595b3" rel="noopener noreferrer" target="_blank" class="cc-link">Cookie-Informationen anzeigen</a>',
                linkedCategory: 'support',
            },
        ],
    },
};
