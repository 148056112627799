import { t } from '@lingui/macro';
import { SupplierContactDTO } from '@luminovo/http-client';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { analytics } from '../../../../utils/analytics';

export function useDialogDeleteSupplierContact({ supplierContact }: { supplierContact: SupplierContactDTO }) {
    const { setDialog, closeDialog } = useDialogContext();

    const { id: supplierContactId } = supplierContact;

    const { mutateAsync } = useHttpMutation('DELETE /supplier-contacts/:supplierContactId', {
        snackbarMessage: t`Contact successfully deleted`,
        onSuccess: () => {
            analytics.track('delete_supplier_contact');
            closeDialog();
        },
    });

    const handeConfim = async () => {
        await mutateAsync({
            pathParams: { supplierContactId },
        });
    };

    return {
        openDialog: () => {
            setDialog(
                <ConfirmationDialogBox
                    title={t`Delete contact`}
                    text={t`Are you sure you would like to delete this contact?`}
                    isDialogOpen={true}
                    onReject={() => closeDialog()}
                    onConfirm={() => handeConfim()}
                    deleteText={t`Yes, delete`}
                />,
            );
        },
    };
}
