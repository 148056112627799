/* eslint-disable camelcase */
import { PartLiteTypes } from '@luminovo/http-client';
import { PartLiteLabel } from './PartLiteLabel';

export function NegotiationIpnLabel({ ipn }: { ipn?: string | null }): JSX.Element {
    return (
        <PartLiteLabel
            variant={'body-small'}
            part={
                ipn
                    ? {
                          kind: PartLiteTypes.Ipn,
                          id: ipn,
                          matches: [],
                      }
                    : undefined
            }
        />
    );
}
