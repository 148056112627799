import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseLayerstackThicknessTolerance } from '../parsers/parseTolerance';
import { applyConfidencePenalty } from '../utils';

export const extractTolerance: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const confidencePenaltyIfNotSelected = stringToParse.includes(':selected:') ? 1 : 0.5;
    const attribute = parseLayerstackThicknessTolerance(stringToParse);
    // if no confidence is given use 1
    return isPresent(attribute) ? [applyConfidencePenalty(attribute, confidencePenaltyIfNotSelected)] : [];
};
