import { t } from '@lingui/macro';
import { BomItemIssue } from '@luminovo/http-client';

export function getWarningsLabelForTooltip({ issues }: { issues: BomItemIssue[] }): string {
    const issuesNames = [];

    if (issues.includes(BomItemIssue.Compliance)) {
        issuesNames.push(t`compliance`);
    }
    if (issues.includes(BomItemIssue.InsufficientStock)) {
        issuesNames.push(t`availability`);
    }
    if (issues.includes(BomItemIssue.Lifecycle)) {
        issuesNames.push(t`lifecycle`);
    }
    if (issues.includes(BomItemIssue.MissingPartData)) {
        issuesNames.push(t`part data`);
    }

    if (issuesNames.length < 2) {
        return issuesNames.join(', ');
    }
    return issuesNames.slice(0, -1).join(', ') + ' & ' + issuesNames.slice(-1);
}
