import { Flexbox } from '@luminovo/design-system';
import { InfoOutlined } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import { colorSystem } from '../../design-system/themes';

const Container = styled(Flexbox)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    borderRadius: '4px',
});

export const NotificationBox = ({
    text,
    color = 'primary',
    style,
}: {
    text: string;
    color?: keyof typeof colorSystem;
    style?: React.CSSProperties;
}) => {
    return (
        <Container
            style={{
                backgroundColor: colorSystem[color]['1'],
                border: `1px solid ${colorSystem[color]['3']}`,
                ...style,
            }}
        >
            <InfoOutlined style={{ color: colorSystem[color][8] }} />
            <Typography variant="h4" style={{ color: colorSystem[color][8] }}>
                {text}
            </Typography>
        </Container>
    );
};
