import { Trans, plural, t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, HighlightMatcher, Tag, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import {
    EmsPartNumberPartMatchFullPart,
    FullPart,
    PartMatchTypeFullResponse,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { HighlightedMpn } from '../partSpecificationCards/OTSPart/OTSPartCard';
import { TableCell } from './TableCell';

const RenderOtsComponentMpnMatch = ({
    match,
    otsComponentState,
    candidateMpns,
}: {
    match: PartMatchTypeFullResponse;
    otsComponentState?: 'Active' | 'Removed';
    candidateMpns?: HighlightMatcher;
}) => {
    const { type, data: partData } = match;
    if (type === 'Generic') {
        const matchesCount = partData.matches.length;
        return (
            <Text
                variant="body-small"
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[9] }}
            >
                {plural(matchesCount, {
                    one: `Generic part with 1 match`,
                    other: `Generic part with ${matchesCount} matches`,
                })}
            </Text>
        );
    }
    if (type === 'OffTheShelf') {
        return <HighlightedMpn mpns={[partData.mpn, ...partData.mpn_aliases]} matcher={candidateMpns} />;
    }
    assertUnreachable(type);
};

const RenderOtsComponentMpnMatches = ({
    matches,
    candidateMpns,
    otsComponentState,
}: {
    matches: EmsPartNumberPartMatchFullPart[];
    otsComponentState: 'Active' | 'Removed';
    candidateMpns?: HighlightMatcher;
}) => {
    if (matches.length === 0) {
        return (
            <Tag
                color="yellow"
                label={t`No linked parts`}
                attention="low"
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.yellow[7] }}
            />
        );
    }
    if (matches.length === 1) {
        return (
            <RenderOtsComponentMpnMatch
                match={matches[0].part}
                candidateMpns={candidateMpns}
                otsComponentState={otsComponentState}
            />
        );
    }
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection="column" padding="4px" gap="4px">
                    {matches.map((match, i) => {
                        return (
                            <span key={i}>
                                <RenderOtsComponentMpnMatch match={match.part} candidateMpns={candidateMpns} />
                            </span>
                        );
                    })}
                </Flexbox>
            }
        >
            <span>
                <Tag
                    color="neutral"
                    attention="low"
                    label={t`${matches.length} MPNs linked`}
                    style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
                />
            </span>
        </Tooltip>
    );
};

export const RenderMpn = ({
    part,
    candidateMpns = [],
    onClick,
}: {
    part: FullPart;
    candidateMpns?: HighlightMatcher;
    onClick?: () => void;
}): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <HighlightedMpn mpns={[part.mpn, ...part.mpn_aliases]} matcher={candidateMpns} />
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        const matches = part.matches;
        if (matches.length === 0) {
            return (
                <TableCell onClick={onClick}>
                    <Tag color="red" label={t`No matches`} attention="low" />
                </TableCell>
            );
        }
        return (
            <TableCell onClick={onClick}>
                <Trans>
                    {plural(matches.length, { one: `${matches.length} match`, other: `${matches.length} matches` })}
                </Trans>
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                <RenderOtsComponentMpnMatches
                    matches={part.matches}
                    otsComponentState={part.state}
                    candidateMpns={candidateMpns}
                />
            </TableCell>
        );
    }
    if (isCustomFullPart(part)) {
        return <TableCell onClick={onClick} />;
    }
    if (isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    assertUnreachable(part);
};
