/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface ChipCartCredentialsDTO extends r.Static<typeof ChipCartCredentialsDTORuntype> {}
export const ChipCartCredentialsDTORuntype = r.Union(
    r.Record({
        enabled: r.Boolean,
    }),
);

export interface ChipCartCredentialsInputDTO extends r.Static<typeof ChipCartCredentialsInputDTORuntype> {}
export const ChipCartCredentialsInputDTORuntype = r.Record({
    enabled: r.Boolean.optional(),
});

export interface ChipCartResponseBodyDTO extends r.Static<typeof ChipCartResponseBodyDTORuntype> {}
export const ChipCartResponseBodyDTORuntype = r.Record({
    data: ChipCartCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
