import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, SplitButton, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import { CostedBomExportTemplate } from '@luminovo/http-client';
import { Menu, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import fileDownload from 'js-file-download';
import { useHistory } from 'react-router-dom';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';

export const ExportCostedBomSplitButton = ({
    rfqId,
    size,
}: {
    rfqId: string;
    size: 'small' | 'medium';
}): JSX.Element => {
    const history = useHistory();

    const { mutateAsync, isLoading } = useHttpMutation('GET /export/costed-bom-xlsx', {
        snackbarMessage: null,
        onSuccess: (response) => {
            fileDownload(response.blob, response.fileName);
        },
    });

    const { data: templates } = useHttpQuery(
        'GET /export/costed-bom/templates',
        {},
        {
            suspense: true,
            select: (data) => data.items,
        },
    );

    const templateOptions = (templates ?? []).map((template) => ({
        id: template.id,
        label: <TemplateContent template={template} />,
        onClick: () => mutateAsync({ queryParams: { rfq: rfqId, template_id: template.id } }),
        overrides: { MenuItem: TemplateNameMenuItem },
    }));

    const allTemplates = [
        ...templateOptions,
        {
            id: 'viewAllTemplates',
            label: <ViewTemplateLabel />,
            overrides: { MenuItem: ViewTemplatesMenuItem },
            onClick: () => {
                history.push(route('/settings/organization/export-templates'), {
                    previousUrl: history.location.pathname,
                });
            },
        },
    ];

    return (
        <SplitButton
            overrides={{
                button: {
                    Component: SecondaryButton,
                    size,
                },
                menu: {
                    Component: StyledMenu,
                },
            }}
            disabled={isLoading}
            dataTrackingId="exportCostedBOMButton"
            pinnedSelectedOption={{
                id: 'exportDefaultBom',
                onClick: () => mutateAsync({ queryParams: { rfq: rfqId } }),
                label: t`Export costed BOM`,
            }}
            options={allTemplates}
        />
    );
};

export const ExportSnapshotSplitButton = ({
    rfqId,
    snapshotId,
    size,
}: {
    rfqId: string;
    snapshotId: string;
    size: 'small' | 'medium';
}): JSX.Element => {
    const history = useHistory();

    const { mutateAsync, isLoading } = useHttpMutation('GET /rfqs/:rfqId/snapshot/:snapshotId/costed-bom-xlsx', {
        snackbarMessage: null,
        onSuccess: (response) => {
            fileDownload(response.blob, response.fileName);
        },
    });

    const { data: templates } = useHttpQuery(
        'GET /export/costed-bom/templates',
        {},
        {
            suspense: true,
            select: (data) => data.items,
        },
    );

    const templateOptions = (templates ?? []).map((template) => ({
        id: template.id,
        label: <TemplateContent template={template} />,
        onClick: () => mutateAsync({ pathParams: { rfqId, snapshotId }, queryParams: { template_id: template.id } }),
        overrides: { MenuItem: TemplateNameMenuItem },
    }));

    const allTemplates = [
        ...templateOptions,
        {
            id: 'viewAllTemplates',
            label: <ViewTemplateLabel />,
            overrides: { MenuItem: ViewTemplatesMenuItem },
            onClick: () => {
                history.push(route('/settings/organization/export-templates'), {
                    previousUrl: history.location.pathname,
                });
            },
        },
    ];

    return (
        <SplitButton
            overrides={{
                button: {
                    Component: SecondaryButton,
                    size,
                },
                menu: {
                    Component: StyledMenu,
                },
            }}
            disabled={isLoading}
            dataTrackingId="exportCostedBOMButton"
            pinnedSelectedOption={{
                id: 'exportDefaultBom',
                onClick: () => mutateAsync({ pathParams: { rfqId, snapshotId }, queryParams: {} }),
                label: t`Download`,
            }}
            options={allTemplates}
        />
    );
};

const StyledMenu = styled(Menu)({
    '& .MuiList-padding': {
        padding: 0,
    },
});

const TemplateNameMenuItem = styled(MenuItem)({
    height: '32px',
    minWidth: '250px',
});

const ViewTemplatesMenuItem = styled(TemplateNameMenuItem)({
    backgroundColor: colorSystem.neutral[1],
});

const ViewTemplateLabel = () => {
    return (
        <TertiaryButton size="small" style={{ paddingLeft: 0 }}>
            <Trans>View templates</Trans>
        </TertiaryButton>
    );
};

const TemplateContent = ({ template }: { template: CostedBomExportTemplate }) => {
    return (
        <Flexbox
            style={{
                width: '100%',
            }}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <div>
                <Text>{template.name}</Text>
            </div>
            <div>
                {template.is_default && (
                    <Tag
                        attention={'low'}
                        color={'primary'}
                        label={t`Default`}
                        style={{
                            marginLeft: '8px',
                        }}
                    />
                )}
            </div>
        </Flexbox>
    );
};
