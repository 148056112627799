import { Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { SubmitHandler } from 'react-hook-form';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { CustomerBillingAddressFormInner } from './CustomerBillingAddressFormInner';
import { CustomerBillingAddressFormState } from './types';

const defaultCustomerBillingAddressValues: CustomerBillingAddressFormState = {
    line1: null,
    line2: null,
    /* eslint-disable-next-line camelcase */
    postal_code: null,
    city: null,
    /* eslint-disable-next-line camelcase */
    country_code: null,
};

export function DialogUpsertCustomerBillingAddress({
    title,
    open,
    onClose,
    initialValues,
    onSubmit,
}: {
    title: string;
    open: boolean;
    onClose: () => void;
    initialValues: CustomerBillingAddressFormState | null;
    onSubmit: SubmitHandler<CustomerBillingAddressFormState>;
}) {
    return (
        <Dialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle title={title} />
            <DialogContent style={{ paddingBottom: 24 }}>
                <FormContainer onSubmit={onSubmit} defaultValues={initialValues ?? defaultCustomerBillingAddressValues}>
                    <CustomerBillingAddressFormInner onClose={() => onClose()} />
                </FormContainer>
            </DialogContent>
        </Dialog>
    );
}
