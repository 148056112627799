import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { AvailabilityStatusEnum, PartCountEnum } from '@luminovo/http-client';

export { ComplianceStatusTranslations } from '@luminovo/sourcing-core';

export const AvailabilityStatusPublicTranslations: Record<AvailabilityStatusEnum, MessageDescriptor> = {
    [AvailabilityStatusEnum.ConsignedByOEM]: defineMessage({ message: 'Consigned' }),
    [AvailabilityStatusEnum.InStock]: defineMessage({ message: 'In stock' }),
    [AvailabilityStatusEnum.InsufficientStock]: defineMessage({ message: 'Insufficient stock' }),
    [AvailabilityStatusEnum.InventoryOfEMS]: defineMessage({ message: 'Inventory' }),
    [AvailabilityStatusEnum.OutOfStock]: defineMessage({ message: 'Out of stock' }),
};

export const partCountTranslationsLong: Record<PartCountEnum, MessageDescriptor> = {
    [PartCountEnum.DNP]: defineMessage({ message: 'Do not place' }),
    [PartCountEnum.None]: defineMessage({ message: 'No part option' }),
    [PartCountEnum.Single]: defineMessage({ message: 'Single part option' }),
    [PartCountEnum.Multiple]: defineMessage({ message: 'Multiple part options' }),
};

export const partCountTranslationsShort: Record<PartCountEnum, MessageDescriptor> = {
    [PartCountEnum.DNP]: defineMessage({ message: 'DNP' }),
    [PartCountEnum.None]: defineMessage({ message: 'None' }),
    [PartCountEnum.Single]: defineMessage({ message: 'Single' }),
    [PartCountEnum.Multiple]: defineMessage({ message: 'Multiple' }),
};
