import { getToken } from '@luminovo/auth';
import { ExtractResponseBody, UploadedFileType, http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import {
    sanitizeFileName,
    uploadFileToBlobStorage,
    useAzureBlobMetadata,
} from '../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

type SuccessResponse = {
    type: 'success';
    fileName: string;
    uploadId: string;
} & ExtractResponseBody<'POST /pnp/parse'>;

export const useSuccessMutation = ({
    assemblyId,
    onSuccess,
}: {
    assemblyId: string;
    onSuccess?: (response: SuccessResponse) => void;
}) => {
    const metadata = useAzureBlobMetadata();
    const { mutateAsync } = useHttpMutation('POST /pnp/upload-url', {
        snackbarMessage: null,
    });

    const { mutateAsync: pnpParseMutateAsync } = useHttpMutation('POST /pnp/parse', {
        snackbarMessage: null,
    });

    const { mutateAsync: pnpUploadFailedUrlMutateAsync } = useHttpMutation('POST /pnp/upload-failed-url', {
        snackbarMessage: null,
    });

    const { mutateAsync: deleteFileMutateAsync } = useHttpMutation('DELETE /pnp/file', {
        snackbarMessage: null,
    });

    const mutateFn = async ({ pnpFile }: { pnpFile: File }): Promise<SuccessResponse> => {
        const response = await mutateAsync({
            requestBody: {
                /* eslint-disable camelcase */
                file_name: sanitizeFileName(pnpFile.name),
                assembly_id: assemblyId,
                /* eslint-enable camelcase */
            },
        });
        await uploadFileToBlobStorage(pnpFile, response.url, metadata);

        const pnpMutateResponse = await pnpParseMutateAsync({
            queryParams: {
                id: response.id,
            },
        }).catch(async (error) => {
            await pnpUploadFailedUrlMutateAsync({
                requestBody: {
                    assembly_id: assemblyId,
                    file_name: sanitizeFileName(pnpFile.name),
                },
            });
            await deleteFileMutateAsync({
                queryParams: {
                    assembly: assemblyId,
                },
            });
            throw new Error(error);
        });

        await http(
            'POST /assemblies/:id/history/file-uploaded',
            {
                pathParams: { id: assemblyId },
                /* eslint-disable-next-line camelcase */
                requestBody: { file_type: UploadedFileType.Pnp, file_name: pnpFile.name },
            },
            getToken(),
        );

        return {
            type: 'success',
            ...pnpMutateResponse,
            fileName: pnpFile.name,
            uploadId: response.id,
        };
    };

    return useMutation({
        mutationKey: ['useSuccessHandler', assemblyId],
        mutationFn: async ({ pnpFile }: { pnpFile: File }) => {
            return mutateFn({ pnpFile });
        },
        onSuccess,
    });
};
