/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type RsComponentsStoreIdDTO = r.Static<typeof RsComponentsStoreIdDTORuntype>;
export type RsComponentsCountryCodeDTO = r.Static<typeof RsComponentsCountryCodeDTORuntype>;

export const RsComponentsStoreIdDTORuntype = r.Union(
    r.Literal('AT_1'),
    r.Literal('AU_1'),
    r.Literal('BE_1'),
    r.Literal('CN_1'),
    r.Literal('CZ_1'),
    r.Literal('DE_1'),
    r.Literal('DK_1'),
    r.Literal('ES_1'),
    r.Literal('FR_1'),
    r.Literal('GB_1'),
    r.Literal('HK_1'),
    r.Literal('HU_1'),
    r.Literal('IE_1'),
    r.Literal('IT_1'),
    r.Literal('JP_1'),
    r.Literal('MY_1'),
    r.Literal('NL_1'),
    r.Literal('NO_1'),
    r.Literal('NZ_1'),
    r.Literal('PH_1'),
    r.Literal('PL_1'),
    r.Literal('SE_1'),
    r.Literal('SG_1'),
    r.Literal('TH_1'),
    r.Literal('TW_1'),
    r.Literal('ZA_1'),
);
export const RsComponentsCountryCodeDTORuntype = r.Union(
    r.Literal('AE'),
    r.Literal('AT'),
    r.Literal('AU'),
    r.Literal('AZ'),
    r.Literal('BA'),
    r.Literal('BE'),
    r.Literal('BR'),
    r.Literal('CL'),
    r.Literal('CN'),
    r.Literal('CY'),
    r.Literal('CZ'),
    r.Literal('DE'),
    r.Literal('DK'),
    r.Literal('EE'),
    r.Literal('ES'),
    r.Literal('FI'),
    r.Literal('FR'),
    r.Literal('GB'),
    r.Literal('GR'),
    r.Literal('HK'),
    r.Literal('HR'),
    r.Literal('HU'),
    r.Literal('ID'),
    r.Literal('IE'),
    r.Literal('IL'),
    r.Literal('IN'),
    r.Literal('IT'),
    r.Literal('JP'),
    r.Literal('KZ'),
    r.Literal('LT'),
    r.Literal('LV'),
    r.Literal('LY'),
    r.Literal('ME'),
    r.Literal('MT'),
    r.Literal('MX'),
    r.Literal('MY'),
    r.Literal('NL'),
    r.Literal('NO'),
    r.Literal('NZ'),
    r.Literal('PH'),
    r.Literal('PL'),
    r.Literal('PT'),
    r.Literal('RO'),
    r.Literal('RS'),
    r.Literal('RU'),
    r.Literal('SA'),
    r.Literal('SE'),
    r.Literal('SG'),
    r.Literal('SK'),
    r.Literal('TH'),
    r.Literal('TR'),
    r.Literal('TW'),
    r.Literal('UA'),
    r.Literal('ZA'),
);

export interface RsComponentsCredentialsDTO extends r.Static<typeof RsComponentsCredentialsDTORuntype> {}
export const RsComponentsCredentialsDTORuntype = r.Union(
    r.Record({
        client_id: r.String.optional(),
        client_secret: r.String.optional(),
        customer_number: r.String.nullable().optional(),
        store_id: RsComponentsStoreIdDTORuntype,
        country_code: RsComponentsCountryCodeDTORuntype,
    }),
);

export interface RsComponentsCredentialsInputDTO extends r.Static<typeof RsComponentsCredentialsInputDTORuntype> {}
export const RsComponentsCredentialsInputDTORuntype = r.Record({
    client_id: r.String,
    client_secret: r.String,
    customer_number: r.String.nullable(),
    store_id: RsComponentsStoreIdDTORuntype,
    country_code: RsComponentsCountryCodeDTORuntype,
});

export interface RsComponentsResponseBodyDTO extends r.Static<typeof RsComponentsResponseBodyDTORuntype> {}
export const RsComponentsResponseBodyDTORuntype = r.Record({
    data: RsComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
