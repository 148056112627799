/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface FutureCredentialsDTO extends r.Static<typeof FutureCredentialsDTORuntype> {}
export const FutureCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface FutureCredentialsInputDTO extends r.Static<typeof FutureCredentialsInputDTORuntype> {}
export const FutureCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface FutureResponseBodyDTO extends r.Static<typeof FutureResponseBodyDTORuntype> {}
export const FutureResponseBodyDTORuntype = r.Record({
    data: FutureCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
