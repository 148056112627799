import { Trans } from '@lingui/macro';
import { TimelineAddIcon, TimelineContentContainer, TimelineText } from './Utils';

export function DesignItemsExtractedIcon() {
    return <TimelineAddIcon />;
}

type Props = {
    numberOfDesignItems: number;
};

export default function DesignItemsExtracted({ numberOfDesignItems }: Props) {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>{numberOfDesignItems} design items extracted from BOM</Trans>
            </TimelineText>
        </TimelineContentContainer>
    );
}
