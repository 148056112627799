import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, PrimaryButton } from '@luminovo/design-system';
import { Breakpoint } from '@mui/material';

export function HelpDialog({
    title,
    body,
    width = 'xl',
    showDialog,
    onClose,
}: {
    title: string;
    body: JSX.Element;
    width?: Breakpoint;
    showDialog: boolean;
    onClose?: () => void;
}): JSX.Element {
    return (
        <>
            <Dialog maxWidth={width ?? false} onClose={onClose} title={title} open={showDialog}>
                <DialogContent>{body}</DialogContent>
                <DialogActions
                    sx={{
                        padding: '16px 24px',
                    }}
                >
                    <span style={{ flexGrow: 1 }} />
                    <PrimaryButton
                        onClick={() => {
                            onClose?.();
                        }}
                        size="small"
                        autoFocus
                        disableFocusRipple
                    >
                        <Trans>OK</Trans>
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
