import { Trans } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { Text } from '@luminovo/design-system';
import { CalculationTemplatePostDTO, http } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { PageLayout } from '../../../../components/PageLayout';
import { useToken } from '../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { invalidateQuery } from '../../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../../resources/organizationSettings/currencySettingsHandler';
import { analytics } from '../../../../utils/analytics';
import { route } from '../../../../utils/routes';
import CalculationTemplateForm from '../CalculationTemplateForm/CalculationTemplateForm';
import { convertTemplateFormToCalculationTemplatePost } from '../CalculationTemplateForm/utils/convertTemplateFormToCalculationTemplatePost';
import { CalculationTemplateFormInput } from '../CalculationTemplateForm/utils/types';

function useMutationCalculationTemplates() {
    const { token } = useToken();
    const onError = useDebugErrorHandler();
    const queryClient = useQueryClient();
    const { push } = useHistory();

    return useMutation({
        mutationFn: (requestBody: CalculationTemplatePostDTO) => {
            return http('POST /calculations/templates', { requestBody }, token);
        },
        onSuccess: async () => {
            await invalidateQuery('GET /calculations/templates', {}, queryClient);
            push(route('/calculation/templates'));
        },
        onError,
    });
}

const CalculationAddTemplatePage = (): JSX.Element => {
    const { preferredCurrency } = useGlobalCurrency();
    const defaultTemplateForm: CalculationTemplateFormInput = {
        name: '',
        materialCost: {
            extraCosts: [],
        },
        additionalCosts: { otherCosts: [], postProfitCosts: [] },
        manufacturingCost: {
            extraCosts: [],
        },
        projectCost: {
            profit: undefined,
        },
        currency: preferredCurrency ?? Currency.EUR,
        isSeparateProjectCostSelected: true,
        includeExcessMaterialInMaterialCost: false,
    };

    const { mutateAsync } = useMutationCalculationTemplates();

    const onSubmit = async (data: CalculationTemplateFormInput) => {
        const postDto = convertTemplateFormToCalculationTemplatePost(data);
        await mutateAsync(postDto);
        // eslint-disable-next-line no-unreachable
        analytics.track('save_calculation_template');
    };

    return (
        <PageLayout layout="full-width">
            <Box width={'1052px'} paddingLeft={'180px'} marginTop={'32px'}>
                <Text variant="h1">
                    <Trans>Build calculation template</Trans>
                </Text>
                <CalculationTemplateForm defaultValues={defaultTemplateForm} onSubmit={onSubmit} />
            </Box>
        </PageLayout>
    );
};

export default CalculationAddTemplatePage;
