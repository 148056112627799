import { t } from '@lingui/macro';
import { CircularProgress } from '@mui/material';
import { ErrorText } from '../../../../components/Spinners';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { AlertConditionEnum, AlertCreationForm } from '../../../../resources/alerts/alertTypes';
import { convertCreateAlertFormToBody } from '../../../../resources/alerts/utils';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useOtsFullPart } from '../../../../resources/part/partHandler';
import { AlertForm } from './AlertForm';

const createDefaultValues = (partId: string): AlertCreationForm => {
    return {
        condition: AlertConditionEnum.Available,
        notes: '',
        part: partId,
        useNotApprovedSuppliers: false,
    };
};

interface CreateAlertFormContainerProps {
    partId: string;
    onClose: () => void;
}

export const CreateAlertFormContainer = ({ partId, onClose }: CreateAlertFormContainerProps) => {
    const { mutateAsync } = useHttpMutation('POST /alerts/stock-level', {
        snackbarMessage: t`Alert created`,
        onSuccess: onClose,
    });

    const onSubmit = async (formValues: AlertCreationForm) => {
        return mutateAsync({
            requestBody: convertCreateAlertFormToBody(formValues),
        });
    };

    const {
        data: otsPart,
        isLoading: isPartLoading,
        isError: isErrorLoadingPart,
    } = useOtsFullPart({ partOptionId: partId, rfqContext: { type: 'OutsideRfQ' } });

    if (isPartLoading) {
        return <CircularProgress />;
    }
    if (isErrorLoadingPart || otsPart === undefined) {
        return <ErrorText />;
    }

    return (
        <FormContainer defaultValues={createDefaultValues(partId)} onSubmit={onSubmit}>
            <AlertForm part={otsPart} onClose={onClose} />
        </FormContainer>
    );
};
