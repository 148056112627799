import React from 'react';
import { ViewContext } from '../../../../../modules/Bom/components/ModuleTableData';
import { StackUpTab } from '../../../../../modules/Pcb/StackUpTab';
import { useIsPcbEditable } from '../../../../../modules/Pcb/utils/useIsPcbSectionEditable';
import { useRfQ } from '../../../../../resources/rfq/rfqHandler';
import { UrlParams } from '../../../../../utils/routes';
import AssemblyOverviewPcbLayout from '../layout';

export default function AssemblyOverviewStackupPage({
    pathParams,
    queryParams,
}: UrlParams<'/assemblies/:assemblyId/pcb/stackup'>) {
    const { assemblyId } = pathParams;

    const { rfqId } = queryParams;

    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsPcbEditable(rfq);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId,
        };
    }, [rfqId]);

    return (
        <AssemblyOverviewPcbLayout assemblyId={assemblyId}>
            <StackUpTab assemblyId={assemblyId} rfqId={rfqId} isEditable={isRfqEditable} viewContext={viewContext} />
        </AssemblyOverviewPcbLayout>
    );
}
