import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    DriverStatusDetailsDTO,
    DriverStatusWithDriverIdDTO,
    ManufacturingAssemblyDetailsDTO,
} from '@luminovo/http-client';
import { DriverStatusIconType, DriverStatusIconWithTooltip } from '../utils/status';
import { useStatusInformationFromDriverStatus } from './driverStatusInformation';

export const DriverStatusIcon = ({
    driverStatus,
    rfqId,
    manufacturingAssemblyDetails,
    iconType,
}: {
    driverStatus: DriverStatusDetailsDTO | undefined;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    iconType: DriverStatusIconType;
}) => {
    switch (driverStatus?.type) {
        case undefined:
            return <DriverStatusIconWithTooltip status={'Ok'} description={''} iconType={iconType} />;
        case 'InvalidFormula':
            return (
                <DriverStatusIconWithTooltip status={'Error'} description={t`Error in formula`} iconType={iconType} />
            );
        case 'Status':
            return (
                <DriverStatusWithTooltipIconForStatus
                    status={driverStatus.details}
                    rfqId={rfqId}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                    iconType={iconType}
                />
            );
        default:
            assertUnreachable(driverStatus);
    }
};

const DriverStatusWithTooltipIconForStatus = ({
    status,
    rfqId,
    manufacturingAssemblyDetails,
    iconType,
}: {
    status: DriverStatusWithDriverIdDTO;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    iconType: DriverStatusIconType;
}): JSX.Element => {
    const info = useStatusInformationFromDriverStatus(status.status, rfqId, manufacturingAssemblyDetails);
    return (
        <DriverStatusIconWithTooltip
            status={info.type}
            description={info.description}
            iconType={iconType}
            tooltipText={info.tooltipText}
        />
    );
};
