import * as r from 'runtypes';
import { TQueryParamValue } from '../http/endpoint';

interface WebsocketEndpointProps<
    TPathParams extends r.Runtype<Record<string, string | number | boolean>> | r.Runtype<undefined>,
    TQueryParams extends r.Runtype<Record<string, TQueryParamValue>> | r.Runtype<undefined>,
    TWebsocketResponseMessage extends r.Runtype,
> {
    /**
     * Provide a human-readable description for the endpoint.
     */
    description: string;

    /**
     * Describes the path parameters of the endpoint.
     */
    pathParams: TPathParams;

    /**
     * Describes the query parameters of the endpoint
     */
    queryParams: TQueryParams;

    responseMessage: TWebsocketResponseMessage;
}

/**
 * Utility function that defines a websocket endpoint. As you can see from the implementation,
 * this function doesn't do anything - it exists merely to enforce the definition of types.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function websocketEndpoint<
    TPathParams extends r.Runtype<Record<string, string | number | boolean>> | r.Runtype<undefined>,
    TQueryParams extends r.Runtype<Record<string, TQueryParamValue>> | r.Runtype<undefined>,
    TWebsocketResponseMessage extends r.Runtype,
>({
    description,
    pathParams,
    queryParams,
    responseMessage,
}: WebsocketEndpointProps<TPathParams, TQueryParams, TWebsocketResponseMessage>) {
    return {
        description,
        pathParams,
        queryParams,
        responseMessage,
    };
}
