import { isPresent } from '@luminovo/commons';
import { Tag } from '@luminovo/http-client';
import { Solution } from '../types';

export function findSolutionTag<T extends Tag['tag']>(
    solution: Pick<Solution, 'solutionTags'> | null | undefined,
    solutionTag: T,
): Extract<Tag, { tag: T }> | null {
    if (!isPresent(solution)) {
        return null;
    }

    const result = solution.solutionTags.find(({ tag }) => tag === solutionTag);

    if (result) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return result as Extract<Tag, { tag: T }>;
    }

    return null;
}
