import { Trans } from '@lingui/macro';
import { colorSystem, Text } from '@luminovo/design-system';
import { RichTextEditorInput } from '@luminovo/rich-text-editor';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createVars } from '../createVars';
import { EmailManagerFormState } from '../types';

export const FormItemSubject = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<EmailManagerFormState>();

    const templateId = useWatch({ control, name: `supplierEmails.${index}.email.template.id` });

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Subject</Trans>
            </Text>
            <Controller
                control={control}
                name={`supplierEmails.${index}.email.subject`}
                render={({ field }) => {
                    return (
                        <RichTextEditorInput
                            key={templateId}
                            namespace="quote-requests::subject"
                            vars={createVars()}
                            hideVariables
                            initialHtml={field.value}
                            onChange={field.onChange}
                            autosaveEveryMillis={2000}
                        />
                    );
                }}
            />
        </>
    );
};
