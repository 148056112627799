import { throwErrorUnlessProduction } from '@luminovo/commons';
import { IdWithIndex } from '@luminovo/http-client';

/**
 * Sorts an array of objects by the order of their ids.
 * @param array the array to sort. This can contain more ids than the orderedIds and only the
 * entitites in the orderedIds will be .
 * @param orderedIds the ids in the order they should be sorted. We check that all ids in the
 * `orderedIds` are also in `array`.
 * @returns the objects containing the ids of the `orderedIds` in the order of the `orderedIds`.
 * @throws if orderedId array contains ids that are not included in the array.
 */
export function sortByOrderOfIds<T extends { id: string }>(array: T[], orderedIds: IdWithIndex[]): T[] {
    const arraySet = new Set(array.map((idWithIndex) => idWithIndex.id));
    if (orderedIds.some((obj) => !arraySet.has(obj.id))) {
        throwErrorUnlessProduction(
            new Error(`Expected all ids in the orderedIds to be in the array, but some were missing`),
        );
    }

    const arrayIdMap = new Map<string, T>(array.map((obj) => [obj.id, obj]));

    return orderedIds.sort((a, b) => a.index - b.index).map((idWithIndex) => arrayIdMap.get(idWithIndex.id)!);
}
