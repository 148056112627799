import { colorSystem } from '@luminovo/design-system';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import AssemblyOverviewTable from './AssemblyOverviewTable';

export default function AssemblyOverviewPage() {
    return (
        <PageLayout style={{ background: colorSystem.neutral[1] }} layout="fragment">
            <LayoutCard
                title={undefined}
                style={{ marginTop: '24px', marginLeft: '32px', marginRight: '32px', height: '100%' }}
            >
                <AssemblyOverviewTable />
            </LayoutCard>
        </PageLayout>
    );
}
