import { sortBy } from '@luminovo/commons';
import {
    FullPart,
    GenericFullPart,
    OtsComponentFull,
    OtsFullPart,
    PartOptionDTO,
    StandardPartTypes,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { findBestHighlightMatch, highlightedFraction } from '../../utils/highlighting/mpnHighlighting';

type PartOptionWithFullOtsPart = {
    partOption: PartOptionDTO;
    fullPart: OtsFullPart;
};

type PartOptionWithFullGenericPart = {
    partOption: PartOptionDTO;
    fullPart: GenericFullPart;
};

type PartOptionWithFullIpns = {
    partOption: PartOptionDTO;
    fullPart: OtsComponentFull;
};

export type PartOptionWithFullPart = PartOptionWithFullIpns | PartOptionWithFullGenericPart | PartOptionWithFullOtsPart;

function mapPartOptionsToFullGenericParts(
    partOptions: PartOptionDTO[],
    allPartOptions: FullPart[],
): PartOptionWithFullGenericPart[] {
    const genericPartOptions = partOptions.filter((partOption) => partOption.part.type === StandardPartTypes.Generic);
    const fullGenericParts = allPartOptions.filter(isGenericFullPart);
    return genericPartOptions
        .map((partOption) => {
            const fullPart = fullGenericParts.find((part) => part.id === partOption.part.data);
            if (fullPart) {
                return [{ partOption, fullPart }];
            }
            return [];
        })
        .flat();
}

function mapPartOptionsToFullIpns(partOptions: PartOptionDTO[], allPartOptions: FullPart[]): PartOptionWithFullIpns[] {
    const ipnPartOptions = partOptions.filter((partOption) => partOption.part.type === StandardPartTypes.Ipn);
    const fullIpns = allPartOptions.filter(isOtsComponentFull);
    return ipnPartOptions
        .map((partOption) => {
            const fullPart = fullIpns.find((part) => part.id === partOption.part.data);
            if (fullPart) {
                return [{ partOption, fullPart }];
            }
            return [];
        })
        .flat();
}

function mapPartOptionsToFullOtsParts(
    partOptions: PartOptionDTO[],
    fullOtsParts: OtsFullPart[],
): PartOptionWithFullOtsPart[] {
    return partOptions
        .map((partOption) => {
            const fullPart = fullOtsParts.find((fullPart) => fullPart.id === partOption.part.data);
            if (fullPart) {
                return [{ partOption, fullPart }];
            }
            return [];
        })
        .flat();
}

function sortInDescendingOrderBasedOnMPNHighlighting(
    firstMPN: string,
    secondMPN: string,
    candidateMPNs: string[],
): number {
    const ratioOnFirstMpn = getHighlightedRatioOnMPN(firstMPN, candidateMPNs);
    const ratioOnSecondMpn = getHighlightedRatioOnMPN(secondMPN, candidateMPNs);
    // if ratios are the same, compare the length of the mpns.
    if (ratioOnFirstMpn === ratioOnSecondMpn) return secondMPN.length - firstMPN.length;
    return ratioOnSecondMpn - ratioOnFirstMpn;
}

function getHighlightedRatioOnMPN(mpn: string, candidateMpns: string[]): number {
    const fragments = findBestHighlightMatch(mpn, candidateMpns);
    return highlightedFraction(fragments);
}

function sortAndTransformPartOptions(
    partOptions: PartOptionDTO[],
    fullParts: FullPart[],
    candidateMpns: string[],
): PartOptionWithFullPart[] {
    const genericPartOptionsWithParts = mapPartOptionsToFullGenericParts(partOptions, fullParts);
    const ipnPartOptionsWithParts = mapPartOptionsToFullIpns(partOptions, fullParts);
    // Preferred components come first
    const sortedipnPartOptionsWithParts = sortBy(ipnPartOptionsWithParts, [
        (p) => {
            if (p.fullPart.is_preferred) {
                return 0;
            }
        },
    ]);
    const otsPartOptions = partOptions.filter((partOption) => partOption.part.type === StandardPartTypes.OffTheShelf);
    const fullOtsParts = fullParts.filter(isOtsFullPart);
    const mappingOfPartOptionToFullOtsParts = mapPartOptionsToFullOtsParts(otsPartOptions, fullOtsParts);
    const sortedMappingOfPartOptionsToFullOtsParts = mappingOfPartOptionToFullOtsParts.sort(
        (firstMapping, secondMapping) => {
            const firstMPN = firstMapping.fullPart.mpn;
            const secondMPN = secondMapping.fullPart.mpn;
            return sortInDescendingOrderBasedOnMPNHighlighting(firstMPN, secondMPN, candidateMpns);
        },
    );

    return [
        ...sortedipnPartOptionsWithParts,
        ...genericPartOptionsWithParts,
        ...sortedMappingOfPartOptionsToFullOtsParts,
    ];
}

export function getAndSortPartOptionsWithFullParts(
    partOptions: PartOptionDTO[],
    fullParts: FullPart[],
    candidateMpns: string[],
): PartOptionWithFullPart[] {
    return sortAndTransformPartOptions(partOptions, fullParts, candidateMpns);
}
