import React, { useCallback } from 'react';

export function useOnHover(onHover: () => void): { onMouseEnter: () => void; onMouseLeave: () => void } {
    const refTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const onMouseEnter = useCallback(() => {
        refTimeout.current = setTimeout(() => {
            onHover();
        }, 300);
    }, [onHover]);

    const onMouseLeave = useCallback(() => {
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
        }
    }, [refTimeout]);

    return { onMouseEnter, onMouseLeave };
}
