import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, TertiaryButton, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityOnIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { PcbPageLayout } from '../../components/PcbMainLayout';
import { CADPreviewer } from '../../components/SVGPreview';
import { useCadFileState } from '../utils/useCadFileData';
import { CadFileLoader } from './CadFileLoader';
import { CadFilesPanel } from './CadFilesPanel';

const MemoisedCADPreviewer = React.memo(CADPreviewer);

export const CadFilesViewer = ({ assemblyId, pcb }: { pcb: PCBV2; assemblyId: string }) => {
    const { files: pcbFiles = [] } = pcb;
    const { state, dispatch } = useCadFileState({ assemblyId, pcbFiles });

    const isAllLayerVisible = state.files.every((file) => file.isVisible);

    const handleToggleAllLayerVisibility = () => {
        dispatch({
            type: 'TOGGLE_ALL_LAYER_VISIBILITY',
        });
    };

    React.useEffect(() => {
        dispatch({
            type: 'LOAD_CAD_FILES',
            payload: { pcbFiles, assemblyId },
        });
    }, [dispatch, pcbFiles, assemblyId]);

    return (
        <PcbPageLayout>
            <Box
                style={{
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    background: colorSystem.neutral[1],
                }}
            >
                <Box style={{ padding: '24px 16px' }}>
                    {state.files.length < 1 ? (
                        <Flexbox style={{ gap: '8px', flexDirection: 'column' }}>
                            {Array(5)
                                .fill(undefined)
                                .map((_, index) => (
                                    <CadFileLoader key={index} />
                                ))}
                        </Flexbox>
                    ) : (
                        <>
                            <Box style={{ padding: '12px 18px', paddingBlockStart: 0 }}>
                                <TertiaryButton
                                    onClick={handleToggleAllLayerVisibility}
                                    size="small"
                                    startIcon={isAllLayerVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                >
                                    <Text
                                        variant={'h5'}
                                        style={{ paddingInlineStart: '10px', color: colorSystem.neutral[7] }}
                                    >
                                        <Trans>All layers</Trans>
                                    </Text>
                                </TertiaryButton>
                            </Box>
                            <CadFilesPanel assemblyId={assemblyId} pcbId={pcb.id} state={state} dispatch={dispatch} />
                        </>
                    )}
                </Box>
            </Box>

            <Box style={{ height: 'calc(100vh - 155px)' }} position={'relative'}>
                <MemoisedCADPreviewer assemblyId={assemblyId} pcbId={pcb.id} state={state} dispatch={dispatch} />
            </Box>
        </PcbPageLayout>
    );
};
