import { isPresent, uniqBy } from '@luminovo/commons';
import {
    ActivityConfigurationSummaryDTO,
    ActivityDTO,
    DriverDTO,
    DriverIdDTO,
    DriverStatusDetailsDTO,
    FormulaResponseDTO,
    TimeCalculation,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import {
    findStatusOfDriver,
    selectDriverIdDto,
    selectDriverIdString,
    selectDriverName,
} from '@luminovo/manufacturing-core';
import { DriverCountInformation, getDriverCountInformation } from '../ActivitiesTable/getDriverCountInformation';
import { useFormulaInformation } from './useDriversFormulaInformation';
import { useFormulaOfAnActivity } from './useFormulaOfActivity';

interface DriverWithCounts {
    driver: DriverDTO;
    driverStatus: DriverStatusDetailsDTO | undefined;
    countInformation: DriverCountInformation;
}

interface FormulaValidation {
    batchFormula: FormulaResponseDTO | undefined;
    unitFormula: FormulaResponseDTO | undefined;
    projectFormula: FormulaResponseDTO | undefined;
}

interface UseDriversOfActivityConfiguration {
    uniqueDrivers: DriverWithCounts[];
    formulaValidation: FormulaValidation;
    isLoading: boolean;
}

const useValidationOfFormulaActivity = (activity: ActivityConfigurationSummaryDTO) => {
    const formulaBatch = useFormulaOfAnActivity(activity.activity_configuration_details.activity_id, 'Batch');
    const formulaUnit = useFormulaOfAnActivity(activity.activity_configuration_details.activity_id, 'Unit');
    const formulaProject = useFormulaOfAnActivity(activity.activity_configuration_details.activity_id, 'Project');

    const { isLoading: isLoadingDriversInFormulaBatch, validationResponse: formulaInformationBatch } =
        useFormulaInformation(formulaBatch.formulaDetails?.formula);
    const { isLoading: isLoadingDriversInFormulaUnit, validationResponse: formulaInformationUnit } =
        useFormulaInformation(formulaUnit.formulaDetails?.formula);
    const { isLoading: isLoadingDriversInFormulaProject, validationResponse: formulaInformationProject } =
        useFormulaInformation(formulaProject.formulaDetails?.formula);

    return {
        formulaValidation: {
            unitFormula: formulaInformationUnit,
            batchFormula: formulaInformationBatch,
            projectFormula: formulaInformationProject,
        },
        isLoading:
            formulaBatch.isLoading ||
            formulaUnit.isLoading ||
            isLoadingDriversInFormulaBatch ||
            isLoadingDriversInFormulaProject ||
            isLoadingDriversInFormulaUnit,
    };
};

export const useDriversOfActivityConfiguration = (
    activity: ActivityConfigurationSummaryDTO,
): UseDriversOfActivityConfiguration => {
    const costComponents = activity.activity_configuration_details.cost_components;
    const batchDriversWithCounts: DriverWithCounts[] | undefined = [...activity.batch_drivers].map((driver) => ({
        driver,
        driverStatus: findStatusOfDriver(selectDriverIdDto(driver), costComponents.details.driver_statuses),
        countInformation: getDriverCountInformation(costComponents, driver),
    }));
    const unitDriversWithCounts: DriverWithCounts[] | undefined = [...activity.unit_drivers].map((driver) => ({
        driver,
        driverStatus: findStatusOfDriver(selectDriverIdDto(driver), costComponents.details.driver_statuses),
        countInformation: getDriverCountInformation(costComponents, driver),
    }));

    const projectDriversWithCounts: DriverWithCounts[] | undefined = [...activity.project_drivers].map((driver) => ({
        driver,
        driverStatus: findStatusOfDriver(selectDriverIdDto(driver), costComponents.details.driver_statuses),
        countInformation: getDriverCountInformation(costComponents, driver),
    }));

    const { formulaValidation, isLoading } = useValidationOfFormulaActivity(activity);

    return {
        uniqueDrivers: uniqBy(
            [
                ...(batchDriversWithCounts ?? []),
                ...(unitDriversWithCounts ?? []),
                ...(projectDriversWithCounts ?? []),
            ].filter(isPresent),
            (driver) => {
                return selectDriverIdString(driver.driver);
            },
        ).sort((driverA, driverB) => selectDriverName(driverA.driver).localeCompare(selectDriverName(driverB.driver))),
        formulaValidation,
        isLoading,
    };
};

interface ActivityDriverIds {
    unitDriverId: DriverIdDTO | undefined;
    batchDriverId: DriverIdDTO | undefined;
    projectDriverId: DriverIdDTO | undefined;
}

const getUnitDriverDetails = (timeCalculation: TimeCalculation): ActivityDriverIds => {
    if (timeCalculation.level !== 'Unit') {
        throw Error(`Unexpected level:  ${timeCalculation.level}`);
    }
    const unitDriverId =
        timeCalculation.details.unit_time_components?.type === 'Linear'
            ? timeCalculation.details.unit_time_components?.details.variable_time?.driver
            : undefined;
    const batchDriverId =
        timeCalculation.details.batch_time_components?.type === 'Linear'
            ? timeCalculation.details.batch_time_components?.details.variable_time?.driver
            : undefined;
    return {
        unitDriverId,
        batchDriverId,
        projectDriverId: undefined,
    };
};

const getBatchDriverDetails = (timeCalculation: TimeCalculation): ActivityDriverIds => {
    if (timeCalculation.level !== 'Batch') {
        throw Error(`Unexpected level:  ${timeCalculation.level}`);
    }

    const batchDriverId =
        timeCalculation.details.batch_time_components?.type === 'Linear'
            ? timeCalculation.details.batch_time_components.details.variable_time?.driver
            : undefined;

    return { unitDriverId: undefined, batchDriverId, projectDriverId: undefined };
};

const getProjectDriverDetails = (timeCalculation: TimeCalculation): ActivityDriverIds => {
    if (timeCalculation.level !== 'Project') {
        throw Error(`Unexpected level:  ${timeCalculation.level}`);
    }

    const projectDriverId =
        timeCalculation.details.project_time_components?.type === 'Linear'
            ? timeCalculation.details.project_time_components.details.variable_time?.driver
            : undefined;

    return { unitDriverId: undefined, batchDriverId: undefined, projectDriverId };
};

const getActivityDriverDetails = (timeCalculation: TimeCalculation): ActivityDriverIds => {
    switch (timeCalculation.level) {
        case 'Unit':
            return getUnitDriverDetails(timeCalculation);
        case 'Batch':
            return getBatchDriverDetails(timeCalculation);
        case 'Project':
            return getProjectDriverDetails(timeCalculation);
    }
};

interface UseDriversOfActivityDTO {
    unitDrivers: DriverIdDTO[];
    batchDrivers: DriverIdDTO[];
    projectDrivers: DriverIdDTO[];
    isLoading: boolean;
    formulaValidation: FormulaValidation;
}

export const useDriversOfActivityDTO = (activity: ActivityDTO): UseDriversOfActivityDTO => {
    const { unitDriverId, batchDriverId, projectDriverId } = getActivityDriverDetails(activity.time_calculation);

    const formulaBatch = useFormulaOfAnActivity(activity.id, 'Batch');
    const formulaUnit = useFormulaOfAnActivity(activity.id, 'Unit');
    const formulaProject = useFormulaOfAnActivity(activity.id, 'Project');
    const {
        drivers: driversInFormulaBatch,
        isLoading: isLoadingDriversInFormulaBatch,
        validationResponse: validationResponseBatch,
    } = useFormulaInformation(formulaBatch.formulaDetails?.formula);
    const {
        drivers: driversInFormulaUnit,
        isLoading: isLoadingDriversInFormulaUnit,
        validationResponse: validationResponseUnit,
    } = useFormulaInformation(formulaUnit.formulaDetails?.formula);
    const {
        drivers: driversInFormulaProject,
        isLoading: isLoadingDriversInFormulaProject,
        validationResponse: validationResponseProject,
    } = useFormulaInformation(formulaProject.formulaDetails?.formula);

    const driversBatchFormula: DriverIdDTO[] =
        driversInFormulaBatch?.map((driver: UserDriverDetailsDTO) => {
            return {
                //safe to hardcode to user because only user drivers can have UserDriverDetailsDTO
                type: 'User',
                value: driver.id,
            };
        }) ?? [];
    const driversUnitFormula: DriverIdDTO[] =
        driversInFormulaUnit?.map((driver: UserDriverDetailsDTO) => {
            return {
                //safe to hardcode to user because only user drivers can have UserDriverDetailsDTO
                type: 'User',
                value: driver.id,
            };
        }) ?? [];
    const driversProjectFormula: DriverIdDTO[] =
        driversInFormulaProject?.map((driver: UserDriverDetailsDTO) => {
            return {
                //safe to hardcode to user because only user drivers can have UserDriverDetailsDTO
                type: 'User',
                value: driver.id,
            };
        }) ?? [];

    return {
        unitDrivers: [unitDriverId, ...driversUnitFormula].filter(isPresent),
        batchDrivers: [batchDriverId, ...driversBatchFormula].filter(isPresent),
        projectDrivers: [projectDriverId, ...driversProjectFormula].filter(isPresent),
        formulaValidation: {
            unitFormula: validationResponseUnit,
            batchFormula: validationResponseBatch,
            projectFormula: validationResponseProject,
        },
        isLoading:
            formulaBatch.isLoading ||
            formulaUnit.isLoading ||
            isLoadingDriversInFormulaBatch ||
            isLoadingDriversInFormulaUnit ||
            isLoadingDriversInFormulaProject,
    };
};
