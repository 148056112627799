import { BoundingBox } from '../boundingBox';
import { Point } from '../Point';
import { minBy } from './minBy';
import { RegionNetwork } from './RegionNetwork';
import { Link, Region } from './types';

interface LinkingRuleDefinition {
    getAssociations: (_: { mpns: Region[]; regions: Region[]; db: RegionNetwork }) => Generator<Link>;
}

export function link({ db }: { db: RegionNetwork }): Array<Link> {
    const linkingRules: LinkingRuleDefinition[] = [
        linkUsingContainingRow,
        linkToNearestTopLeftMpn,
        linkToDirectlyLeftMpn,
    ];

    const links: Array<Link> = [];

    const regions = db
        .findRegions({})
        .filter((region) => region.attributes.length > 0 && !region.attributes.some((attr) => attr.attr === 'part'));
    const mpns = db.findRegions({
        attribute: 'part',
    });
    for (const linkingRule of linkingRules) {
        links.push(...linkingRule.getAssociations({ db, mpns, regions }));
    }

    return links;
}

const linkUsingContainingRow: LinkingRuleDefinition = {
    *getAssociations({ db, mpns, regions }) {
        for (const mpn of mpns) {
            for (const region of regions) {
                if (mpn.pageNumber !== region.pageNumber) {
                    continue;
                }
                const existingWrappingRow =
                    db.findRegions({
                        attribute: 'isRow',

                        containsEvery: [mpn.box, region.box],
                    }).length > 0;

                if (existingWrappingRow) {
                    yield {
                        from: mpn,
                        to: region,
                        type: 'link',
                    };
                }
            }
        }
    },
};

function virtualBox(region: Region): BoundingBox {
    return new BoundingBox(region.box.x, region.box.y + (region.pageNumber - 1), region.box.width, region.box.height);
}

function findNearestMpnAndDistance(
    region: Region,
    mpns: Region[],
): { mpn: Region | undefined; distance: number } | undefined {
    const virtualRegionBox = virtualBox(region);

    // Add distance and virtualBox to mpns
    const mpnsWithDistance = mpns
        .map((mpn) => {
            const virtualMpnBox = virtualBox(mpn);
            return {
                mpn,
                virtualMpnBox,
                distance: yDistance(virtualMpnBox.center(), virtualRegionBox.center()),
            };
        })
        // Filter out all mpns that are not top left of the region
        .filter((mpnWithDistance) => {
            return mpnWithDistance.virtualMpnBox.isTopLeftOf(virtualRegionBox);
        });

    return minBy(mpnsWithDistance, (mpnWithDistance) => mpnWithDistance.distance);
}

const linkToNearestTopLeftMpn: LinkingRuleDefinition = {
    *getAssociations({ db, regions, mpns }): Generator<Link> {
        for (const region of regions) {
            const nearestMpnAndDistance = findNearestMpnAndDistance(region, mpns);
            const maxDistance = 0.7;

            if (nearestMpnAndDistance && nearestMpnAndDistance.mpn && nearestMpnAndDistance.distance < maxDistance) {
                yield {
                    from: nearestMpnAndDistance.mpn,
                    to: region,
                    type: 'link',
                };
            }
        }
    },
};

const linkToDirectlyLeftMpn: LinkingRuleDefinition = {
    *getAssociations({ db, mpns, regions }): Generator<Link> {
        for (const region of regions) {
            for (const mpn of mpns) {
                if (
                    mpn.pageNumber === region.pageNumber &&
                    // add a tiny bit of padding to the mpn box to account for OCR errors
                    isXAxisIntersection(mpn.box.addPadding(Math.min(mpn.box.height / 2, 0.01)), region.box)
                ) {
                    yield { from: mpn, to: region, type: 'link' };
                }
            }
        }
    },
};

function isXAxisIntersection(a: { y: number; height: number }, b: { y: number; height: number }): boolean {
    return !(b.y >= a.y + a.height || b.y + b.height <= a.y);
}

function yDistance(pointA: Point, pointB: Point): number {
    return Math.abs(pointA.y - pointB.y);
}
