import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ExpenseCostCalculationType, ExpenseLevel } from '@luminovo/http-client';

export const expenseCostCalculationTranslations: Record<ExpenseCostCalculationType, MessageDescriptor> = {
    Fixed: defineMessage({ message: 'Fixed' }),
    Linear: defineMessage({ message: 'Linear' }),
    Formula: defineMessage({ message: 'Formula' }),
};

export const expenseLevelEnumPublicTranslations: Record<ExpenseLevel, MessageDescriptor> = {
    Unit: defineMessage({ id: 'expense.unit', message: 'Unit' }),
    Batch: defineMessage({ message: 'Batch' }),
    Project: defineMessage({ message: 'Project' }),
};
