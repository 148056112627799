import { t, Trans } from '@lingui/macro';
import { Chip, colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import { InterestsDTO } from '@luminovo/http-client';
import { InfoRounded } from '@mui/icons-material';

export const Title = ({ label, tooltip = '' }: { label: string; tooltip?: string }) => {
    return (
        <Flexbox gap="4px" alignItems="center">
            <Text variant="h4">{label}</Text>
            {!!tooltip && (
                <Tooltip title={tooltip}>
                    <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                </Tooltip>
            )}
        </Flexbox>
    );
};

export const RenderInterests = ({ interests }: { interests: InterestsDTO }) => {
    if (!interests.compliance && !interests.lifecycle) {
        return (
            <Text>
                <Trans>No options selected</Trans>
            </Text>
        );
    }
    return (
        <Flexbox gap={8}>
            {interests.lifecycle && <Chip color="neutral" label={t`Lifecycle`} />}
            {interests.compliance && <Chip color="neutral" label={t`Compliance`} />}
        </Flexbox>
    );
};
