import { t } from '@lingui/macro';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import React from 'react';
import DialogBox from '../../../components/dialogBox/DialogBox';
import {
    AddManufacturingScenarioTemplateForm,
    EditManufacturingScenarioTemplateForm,
} from './ManufacturingScenarioTemplateForm';

const ManufacturingScenarioTemplateDialog = ({
    title,
    setIsDialogOpen,
    isDialogOpen,
    children,
}: {
    title: string;
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <DialogBox
            title={title}
            isDialogOpen={isDialogOpen}
            onReject={() => setIsDialogOpen(false)}
            maxWidth={'xs'}
            closeIconIsShown={false}
        >
            {children}
        </DialogBox>
    );
};

export const AddManufacturingScenarioTemplateDialog = ({
    setIsDialogOpen,
    isDialogOpen,
}: {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    return (
        <ManufacturingScenarioTemplateDialog
            setIsDialogOpen={setIsDialogOpen}
            isDialogOpen={isDialogOpen}
            title={t`New template`}
        >
            <AddManufacturingScenarioTemplateForm onCloseCallback={() => setIsDialogOpen(false)} />
        </ManufacturingScenarioTemplateDialog>
    );
};

export const EditManufacturingScenarioTemplateDialog = ({
    setIsDialogOpen,
    isDialogOpen,
    template,
}: {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    template: ManufacturingScenarioTemplateDTO;
}): JSX.Element => {
    return (
        <ManufacturingScenarioTemplateDialog
            setIsDialogOpen={setIsDialogOpen}
            isDialogOpen={isDialogOpen}
            title={t`Edit template`}
        >
            <EditManufacturingScenarioTemplateForm onCloseCallback={() => setIsDialogOpen(false)} template={template} />
        </ManufacturingScenarioTemplateDialog>
    );
};
