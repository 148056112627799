import { t } from '@lingui/macro';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { ActivityForm, useActivityForm } from './ActivityForm';
import { defaultValues } from './addActivityFormFunctions';

export const AddActivityForm = (): JSX.Element => {
    const { isLoading, activities, drivers, resources } = useActivityForm();

    if (isLoading) {
        return <SpinnerWithBackdrop />;
    }
    return (
        <ActivityForm
            formTitle={t`Add activity`}
            useActivityInputs={{
                onSubmitType: 'POST',
                defaultValues: defaultValues,
                allResources: resources,
                allActivities: activities,
                allDrivers: drivers,
            }}
        />
    );
};
