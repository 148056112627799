import { t } from '@lingui/macro';
import { SecondaryIconButton, Tooltip } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { DialogUpsertCustomerBillingAddress } from './DialogUpsertCustomerBillingAddress';

export function ButtonEditBillingAddress({ customer }: { customer: CustomerDTO }) {
    const { closeDialog, setDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation('PATCH /customers/:customerId/billing-address', {
        snackbarMessage: t`Successfully updated billing address`,
    });

    return (
        <>
            <Tooltip title={t`Edit customer billing address`}>
                <SecondaryIconButton
                    size="medium"
                    disabled={!customer}
                    onClick={() => {
                        setDialog(
                            <DialogUpsertCustomerBillingAddress
                                title={t`Edit customer billing address`}
                                initialValues={customer.billing_address}
                                open={true}
                                onClose={closeDialog}
                                onSubmit={async (requestBody) => {
                                    await mutateAsync({
                                        pathParams: { customerId: customer.id },
                                        requestBody,
                                    });
                                    closeDialog();
                                }}
                            />,
                        );
                    }}
                >
                    <Edit fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
        </>
    );
}
