/**
 * Parses the given HTML string and replaces all variables with the given substitutions.
 *
 * Variables are defined as <span data-var="variableName">content</span>.
 * A Variable will be replaced by the text from the substitutions object with the key variableName e.g. <span>substitutions[variableName]</span>.
 *
 *
 * @param html
 * @param substitutions
 * @param opts.asText Returns plaintext instead of HTML if true.
 */
export function renderTemplate(
    html: string,
    substitutions: Record<string, string>,
    opts?: { asText: boolean },
): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const varNodes = doc.querySelectorAll('[data-var]');
    varNodes.forEach((varNode) => {
        const varName = varNode.getAttribute('data-var');

        if (varName) {
            const content = substitutions[varName];

            if (content === undefined) {
                varNode.outerHTML = `<b>missing variable</b>`;
                varNode.removeAttribute('data-var');
                varNode.removeAttribute('style');
            } else if (isUrl(content)) {
                const elem = document.createElement('a');
                elem.href = content;
                elem.textContent = content;
                varNode.removeAttribute('data-var');
                varNode.removeAttribute('style');
                varNode.innerHTML = elem.outerHTML;
                return;
            } else {
                varNode.outerHTML = content.trimEnd().replaceAll('\n', '<br>');
                varNode.removeAttribute('data-var');
                varNode.removeAttribute('style');
            }
        }
    });
    if (opts?.asText) {
        return doc.body.textContent || '';
    }
    return doc.body.innerHTML;
}

function isUrl(text: string): boolean {
    try {
        // New way to check if a string is a URL
        // See https://www.notion.so/luminovo/Fix-URL-check-in-rich-text-editor-777812951b454e9fb98470761f01f509?pvs=4
        // TLDR, the old method wrongly parsed anything that had a colon(:) as a URL
        // Which is why we are using the following combination to know if what we are parsing
        // is a valid URL instead of just `new URl()`
        const url = new URL(text);
        return url.protocol.length > 0 && url.host.length > 0 && url.pathname.length > 0 && url.hostname.length > 0;
    } catch {
        return false;
    }
}
