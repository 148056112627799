import { t } from '@lingui/macro';
import { Argument, Operator } from '../types';

const translations = (): Record<Operator<unknown, unknown, Argument>['op'], string> => {
    return {
        'is not': t`is not`,
        is: t`is`,
        like: t`contains`,
        in: t`is`,
        'not in': t`is not`,
        'starts-with': t`starts with`,
        '<': '<',
        '=': '=',
        '>': '>',
        '≤': '≤',
        '≥': '≥',
    };
};

export function formatOperator<T, TAutocompleteState>(op: Operator<T, TAutocompleteState, Argument>['op']): string {
    return translations()[op];
}
