import type { PDFDocumentProxy } from 'pdfjs-dist';
import Dropzone from 'react-dropzone';
import { getPdfDocument } from '../PdfViewer';

export function PdfDropzone({
    onSuccess,
    children,
}: React.PropsWithChildren<{ onSuccess: (_: { pdf: PDFDocumentProxy; file: File }) => void }>): JSX.Element {
    return (
        <Dropzone
            accept={{ 'application/pdf': ['.pdf'] }}
            onDrop={async (files) => {
                const url = URL.createObjectURL(files[0]);
                const pdf = await getPdfDocument(url);
                onSuccess({ pdf, file: files[0] });
            }}
            children={({ getInputProps, getRootProps }) => {
                return (
                    <span {...getRootProps()}>
                        {children}
                        <input {...getInputProps()} />
                    </span>
                );
            }}
        />
    );
}
