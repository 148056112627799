import { isPresent } from '@luminovo/commons';
import { PCBV2 } from '@luminovo/http-client';

export const extractPcbWidthAndHeight = (pcb: PCBV2): { width: number | undefined; height: number | undefined } => {
    const boardWidth = isPresent(pcb.specifications[0].settings.board.boardWidth?.value)
        ? pcb.specifications[0].settings.board.boardWidth?.value
        : pcb.properties.board.boardWidth?.value;
    const boardHeight = isPresent(pcb.specifications[0].settings.board.boardHeight?.value)
        ? pcb.specifications[0].settings.board.boardHeight?.value
        : pcb.properties.board.boardHeight?.value;

    return {
        width: boardWidth,
        height: boardHeight,
    };
};
