import * as r from 'runtypes';

export function printFormattedRuntypeError(err: unknown): string {
    console.log('err', err);
    if (err instanceof r.ValidationError) {
        const details = err.details ?? [];
        const paths = buildTreePaths(details);
        const slice = paths.slice(0, 50);

        if (paths.length === 1) {
            return `Validation error at path ${paths[0].path}: ${paths[0].message}`;
        }

        const lines = [
            `Validation error`,
            `There were ${paths.length} errors, showing the first ${slice.length}`,
            ...slice.map(({ path, message }) => `➡ ${path}: ${message}`),
        ];

        return lines.join('\n');
    } else {
        return JSON.stringify(err, null, 2);
    }
}

function buildTreePaths(details: r.Details): Array<{ path: string; message: string }> {
    const queue: { path: string; details: r.Details | string }[] = [{ path: '<root>', details }];
    const result: Array<{ path: string; message: string }> = [];
    while (queue.length > 0) {
        const { details: head, path } = queue.shift()!;
        if (typeof head === 'string') {
            result.push({ path, message: head });
        } else {
            Object.entries(head).forEach(([i, child]) =>
                queue.push({
                    path: `${path}.${i}`,
                    details: child,
                }),
            );
        }
    }
    return result;
}
