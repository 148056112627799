import { t, Trans } from '@lingui/macro';
import { formatPercentage } from '@luminovo/commons';
import { ScrapRulesDTO } from '@luminovo/http-client';
import { FormulaText } from './components/FormulaText';
import { FormulaValue } from './components/FormulaValue';

export function ScrapFormula({
    scrapFormula,
    isMinimal = false,
}: {
    scrapFormula: ScrapRulesDTO['default_formula'];
    isMinimal?: boolean;
}): JSX.Element {
    const { leader, attrition, minimum, maximum } = scrapFormula;

    if (isMinimal) {
        if (minimum !== undefined && maximum !== undefined)
            return (
                <FormulaText>
                    <Trans>
                        <FormulaValue label={leader} /> + <FormulaValue label={formatPercentage(attrition)} /> <br />{' '}
                        with a minimum of <FormulaValue label={minimum} /> <br /> and a maximum of{' '}
                        <FormulaValue label={maximum} />
                    </Trans>
                </FormulaText>
            );
        if (minimum !== undefined) {
            return (
                <FormulaText>
                    <Trans>
                        <FormulaValue label={leader} /> + <FormulaValue label={formatPercentage(attrition)} /> <br />{' '}
                        with a minimum of <FormulaValue label={minimum} />
                    </Trans>
                </FormulaText>
            );
        }
        if (maximum !== undefined) {
            return (
                <FormulaText>
                    <Trans>
                        <FormulaValue label={leader} /> + <FormulaValue label={formatPercentage(attrition)} /> <br />{' '}
                        with a maximum of <FormulaValue label={maximum} />
                    </Trans>
                </FormulaText>
            );
        }
        return (
            <FormulaText>
                <FormulaValue label={leader} /> + <FormulaValue label={formatPercentage(attrition)} />
            </FormulaText>
        );
    }

    return (
        <FormulaText>
            <Trans>
                Add a scrap quantity of <FormulaValue label={leader} /> +{' '}
                <FormulaValue label={formatPercentage(attrition)} /> <MinScrapQuantity value={minimum} />{' '}
                <MaxScrapQuantity value={maximum} />
            </Trans>
        </FormulaText>
    );
}

function MinScrapQuantity({ value }: { value?: number }) {
    if (value === undefined) {
        return (
            <Trans>
                with <FormulaValue label={t`no minimum`} />{' '}
            </Trans>
        );
    }
    return (
        <Trans>
            with a minimum of <FormulaValue label={value} />{' '}
        </Trans>
    );
}

function MaxScrapQuantity({ value }: { value?: number }) {
    if (value === undefined) {
        return (
            <Trans>
                and <FormulaValue label={t`no maximum`} /> to all solutions
            </Trans>
        );
    }
    return (
        <Trans>
            and a maximum of <FormulaValue label={value} /> to all solutions
        </Trans>
    );
}
