import {
    AvailableSpaceContainer,
    CenteredLayout,
    Column,
    DataTable,
    ScrollableTableContainer,
    useDataTableState,
} from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { compareByInternalNumber } from '@luminovo/manufacturing-core';
import { CircularProgress } from '@mui/material';
import { transEnum } from '../../../components/localization/TransEnum';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { manufacturingScenarioTemplateStatusEnumPublicTranslations } from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesFrontendTypes';
import { resourceTypeEnumPublicTranslations } from '../../../resources/resource/resourceBackendTypes';
import {
    descriptionColumn,
    internalNumberColumn,
    nameColumn,
    statusColumn,
    useSiteColumn,
} from '../shared/sharedManufacturingColumns';
import { useCanEditManufacturingDatabase } from '../utils/useCanEditManufacturingDatabase';
import { costPerHourColumn, resourceTableActions, resourceTypeColumn } from './resourceTableColumns';

const ResourcesTable = ({ query }: { query: string }): JSX.Element => {
    const { data: resources, isLoading } = useHttpQuery('GET /resources', {});
    const sortedResources = (resources?.data ?? []).sort(compareByInternalNumber);

    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();

    const siteColumn = useSiteColumn((resource: ResourceDTO) => ({
        siteIds: resource.site_id ? [resource.site_id] : [],
    }));

    const columns: Column<ResourceDTO>[] = [
        statusColumn,
        internalNumberColumn,
        nameColumn,
        siteColumn,
        resourceTypeColumn,
        costPerHourColumn,
        descriptionColumn,
        resourceTableActions(canEditManufacturingDatabase),
    ];

    const tableState = useDataTableState({
        columns,
        items: sortedResources,
        persistenceId: 'resourcesTable',
        paginationOptions: {
            showPagination: true,
            persistPagination: true,
        },
        query,
        searchOptions: {
            idExtractor: (resource: ResourceDTO): string => resource.id,
            contentSearchOptions: {
                indexingStrategy: 'data-content',
                indexItemData: (item) => [
                    item.cost_per_hour.converted_mv.amount,
                    item.cost_per_hour.converted_mv.currency,
                    item.description ?? '',
                    item.internal_number ?? '',
                    item.name,
                    item.site_id ?? '',
                    transEnum(item.status, manufacturingScenarioTemplateStatusEnumPublicTranslations),
                    transEnum(item.type, resourceTypeEnumPublicTranslations),
                ],
            },
        },
    });

    return isLoading ? (
        <CenteredLayout height={'30vh'}>
            <CircularProgress />
        </CenteredLayout>
    ) : (
        <DataTable
            tableState={tableState}
            key={'resourcesTable'}
            size={'medium'}
            overrides={{ Container: AvailableSpaceContainer, TableContainer: ScrollableTableContainer }}
        />
    );
};

export default ResourcesTable;
