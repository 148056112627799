import { NegotiationLineItem, PartLite, PartLiteTypes } from '@luminovo/http-client';
import { flattenPartLite } from '../components/NegotiationPartsLabel';

export function getNegotiationLineItemParts(lineItem: NegotiationLineItem): PartLite[] {
    return lineItem.parts.map((p) => p.part);
}

export function getNegotiationLineItemIpns(lineItem: NegotiationLineItem): PartLite[] {
    return getNegotiationLineItemParts(lineItem).filter((p) => p.kind === PartLiteTypes.Ipn);
}

export function getNegotiationLineItemFlattenedParts(lineItem: NegotiationLineItem): PartLite[] {
    return getNegotiationLineItemParts(lineItem).flatMap((part) => flattenPartLite(part));
}
