import { ExpenseConfigurationSummaryDTO, ExpensesCostComponent } from '@luminovo/http-client';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

export const getFormulaOfExpense = (costComponents: ExpensesCostComponent): string | undefined => {
    if (costComponents.type === 'Formula') {
        return costComponents.details.formula;
    }
    return undefined;
};

export const useFormulaOfExpenseCongfiguration = (expenseConfiguration: ExpenseConfigurationSummaryDTO) => {
    const { data, isLoading } = useHttpQuery('GET /expenses/:expenseId', {
        pathParams: { expenseId: expenseConfiguration.expense_configuration_details.expense_id },
    });
    const costComponents = data?.data.cost_components;
    let formula = undefined;

    if (costComponents !== undefined && costComponents.type === 'Formula') {
        formula = costComponents.details.formula;
    }
    return { formula, isLoading };
};
