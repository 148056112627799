import { assertUnreachable, maxBy, minBy } from '@luminovo/commons';
import {
    FullPart,
    OtsFullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';

export function findMinMaxEmissions({ parts }: { parts: OtsFullPart[] }): {
    min: number | undefined;
    max: number | undefined;
} {
    const minPart = minBy(
        parts.filter((p) => p.emissions_data.type === 'Available'),
        (p) => p.emissions_data.type === 'Available' && Number(p.emissions_data.data.product_phase_gwp_in_kg_co2e),
    );

    const min =
        minPart && minPart.emissions_data.type === 'Available'
            ? Number(minPart.emissions_data.data.product_phase_gwp_in_kg_co2e)
            : undefined;

    const maxPart = maxBy(
        parts,
        (p) => p.emissions_data.type === 'Available' && Number(p.emissions_data.data.product_phase_gwp_in_kg_co2e),
    );
    const max =
        maxPart && maxPart.emissions_data.type === 'Available'
            ? Number(maxPart.emissions_data.data.product_phase_gwp_in_kg_co2e)
            : undefined;

    return { min, max };
}

export const findPartEmissions = ({
    part,
}: {
    part: FullPart;
}): { min: number | undefined; max: number | undefined } => {
    if (isOtsFullPart(part)) {
        return findMinMaxEmissions({ parts: [part] });
    }
    if (isGenericFullPart(part)) {
        const matches = part.matches;
        if (matches.length === 0) {
            return { min: undefined, max: undefined };
        }
        return findMinMaxEmissions({ parts: matches });
    }
    if (isOtsComponentFull(part)) {
        const matches: OtsFullPart[] = Array.from(part.matches).flatMap((match) => {
            if (match.part.type === 'Generic') {
                return match.part.data.matches;
            }
            if (match.part.type === 'OffTheShelf') {
                return match.part.data;
            }
            return [];
        });
        if (matches.length === 0) {
            return { min: undefined, max: undefined };
        }
        return findMinMaxEmissions({ parts: matches });
    }
    if (isCustomFullPart(part)) {
        return { min: undefined, max: undefined };
    }
    if (isCustomComponentFull(part)) {
        return { min: undefined, max: undefined };
    }
    assertUnreachable(part);
};
