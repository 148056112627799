import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { useHistory } from 'react-router';
import { PageLayout } from '../../components/PageLayout';
import { CustomerRfqForm, CustomerRfqFormState } from '../../components/RfQ/CustomerRfqForm';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../resources/organizationSettings/currencySettingsHandler';
import { assertPresent } from '../../utils/assertPresent';
import { route } from '../../utils/routes';
import { useDefaultAssemblyType } from './hooks/useDefaultAssemblyType';
import { createRequestBodyForCustomer } from './util/utils';

export const CustomerRfqCreationPage = (): JSX.Element => {
    const userDetails = useCurrentUserDetailsContext();
    const customer = assertPresent(userDetails.customer);

    const history = useHistory();
    const onCancel = () => history.goBack();

    const { data: defaultAssembly, isLoading } = useDefaultAssemblyType();
    const { preferredCurrency } = useGlobalCurrency();

    const { mutateAsync } = useHttpMutation('POST /rfqs', {
        snackbarMessage: t`Request created`,
        onSuccess: async (res) => {
            history.push(route('/rfqs/:rfqId/bom', { rfqId: res.data.id }));
        },
    });

    const onSubmit = async (state: CustomerRfqFormState) => {
        const requestBody = createRequestBodyForCustomer(state, preferredCurrency, customer, userDetails);
        await mutateAsync({ requestBody });
    };

    if (isLoading || !isPresent(defaultAssembly)) {
        return <SpinnerWithBackdrop />;
    }

    return (
        <PageLayout layout="full-width">
            <CustomerRfqForm
                title={t`Create request`}
                defaultValues={{
                    rfqName: '',
                    industry: '',
                    comment: '',
                    orders: [
                        {
                            quantity: undefined,
                            date: undefined,
                        },
                    ],
                    additionalServiceIds: [],
                    assemblyType: defaultAssembly,
                }}
                onCancel={onCancel}
                onSubmit={onSubmit}
                variant="create"
            />
        </PageLayout>
    );
};
