import { isProductionEnvironment } from '@luminovo/commons';
import { ConsentType } from '../ConsentType';
import { Tracker } from '../Tracker';
import { helpHeroTracker } from './helpHeroTracker';
import { segmentTracker } from './segmentTracker';
import { sentryTracker } from './sentryTracker';

export function createMasterTracker({
    consentedCookies,
    isInternalUser,
    trackers = [sentryTracker, segmentTracker, helpHeroTracker],
}: {
    consentedCookies: ConsentType[];
    isInternalUser: boolean;
    trackers?: Tracker[];
}): Tracker {
    const consentedTrackers: Tracker[] = trackers
        .filter((tracker) => {
            return consentedCookies.includes(tracker.requiredConsent);
        })
        .filter((tracker) => {
            if (!isInternalUser) {
                return true;
            }
            return tracker.enabledForInternalUsers;
        })
        .filter((tracker) => {
            return isProductionEnvironment() || tracker.enabledForDevEnvironment;
        });

    return {
        requiredConsent: ConsentType.required,

        enabledForInternalUsers: true,

        enabledForDevEnvironment: true,

        initialize() {
            for (const tracker of consentedTrackers) {
                tracker.initialize();
            }
        },

        identify(userId, traits) {
            for (const tracker of consentedTrackers) {
                tracker.identify(userId, traits);
            }
        },

        group(orgId, traits) {
            for (const tracker of consentedTrackers) {
                tracker.group(orgId, traits);
            }
        },

        track(eventName: string, traits) {
            for (const tracker of consentedTrackers) {
                tracker.track(eventName, traits);
            }
        },

        page(eventName: string, traits) {
            for (const tracker of consentedTrackers) {
                tracker.page(eventName, traits);
            }
        },
    };
}
