import { id } from '@luminovo/commons';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { styled } from '@mui/material';

export const SelectableListItemsContainer = styled(Flexbox)({
    width: '100%',
    '& > *': {
        boxSizing: 'border-box',
        flex: '0 0 50%',
        maxWidth: 'calc(50% - 4px)',
    },
});

const commonBoxStyles = {
    borderRadius: '8px',
    width: '100%',
};

const SelectedBoxStyles = styled(Flexbox)({
    ...commonBoxStyles,
    border: '2px solid',
    borderColor: colorSystem.primary[5],
    padding: '15px',
    '&:hover': {
        cursor: 'pointer',
        borderColor: colorSystem.primary[5],
    },
});

const UnselectedBoxStyles = styled(Flexbox)({
    ...commonBoxStyles,
    border: '1px solid',
    borderColor: colorSystem.neutral[3],
    padding: '16px',
    '&:hover': {
        cursor: 'pointer',
        borderColor: colorSystem.primary[4],
    },
});

/**
 * @deprecated use Card from the design-system instead
 */
export const SelectableListItem = ({
    isSelected,
    handleOnClick,
    children,
}: React.PropsWithChildren<{
    handleOnClick: () => void;
    isSelected: boolean;
}>) => {
    switch (isSelected) {
        case true:
            return (
                <SelectedBoxStyles onClick={handleOnClick} data-testid={id('settings/e2e_selected_template')}>
                    {children}
                </SelectedBoxStyles>
            );
        case false:
            return <UnselectedBoxStyles onClick={handleOnClick}>{children}</UnselectedBoxStyles>;
    }
};
