import * as r from 'runtypes';

export const PnpFileInsertRuntype = r.Record({
    assembly_id: r.String,
    file_name: r.String,
});
export type PnpFileInsertDTO = r.Static<typeof PnpFileInsertRuntype>;

export const PnpUploadFailedURLResponseRuntype = r.Record({
    url: r.String,
});
export type PnpUploadFailedURLResponseDTO = r.Static<typeof PnpUploadFailedURLResponseRuntype>;

export const PnpFileResponseRuntype = r.Record({
    id: r.String,
    url: r.String,
});
export type PnpFileResponseDTO = r.Static<typeof PnpFileResponseRuntype>;

export const PnpColumnRuntype = r.Union(r.Literal('Side'), r.Literal('Designator'));
export type PnpColumnDTO = r.Static<typeof PnpColumnRuntype>;

export const PnpSideRuntype = r.Union(r.Literal('Top'), r.Literal('Bottom'));

export type PnpSideDTO = r.Static<typeof PnpSideRuntype>;

export const UnableToParseCellWarningRuntype = r.Record({
    type: r.Literal('UnableToParseCell'),
    data: r.Record({
        column_type: PnpColumnRuntype,
        //0th index.
        row: r.Number,
        //0th index.
        col: r.Number,
    }),
});
export type UnableToParseCellWarningDTO = r.Static<typeof UnableToParseCellWarningRuntype>;

export const DesignatorMissingWarningRuntype = r.Record({
    type: r.Literal('DesignatorMissing'),
    data: r.Number,
});
export type DesignatorMissingWarningDTO = r.Static<typeof DesignatorMissingWarningRuntype>;

export const ColumnMissingWarningRuntype = r.Record({
    type: r.Literal('ColumnMissing'),
    data: PnpColumnRuntype,
});
export type ColumnMissingWarningDTO = r.Static<typeof ColumnMissingWarningRuntype>;

export const DuplicateDesignatorDataWarningRuntype = r.Record({
    type: r.Literal('DuplicateDesignatorData'),
    data: r.String,
});
export type DuplicateDesignatorDataWarningDTO = r.Static<typeof DuplicateDesignatorDataWarningRuntype>;

export const VaryingColumnNumberWarningRuntype = r.Record({
    type: r.Literal('VaryingColumnNumber'),
    data: r.Record({
        row: r.Number,
        col_count: r.Number,
        expected_col_count: r.Number,
    }),
});
export type VaryingColumnNumberWarningDTO = r.Static<typeof VaryingColumnNumberWarningRuntype>;

export const WarningRuntype = r.Union(
    UnableToParseCellWarningRuntype,
    DesignatorMissingWarningRuntype,
    ColumnMissingWarningRuntype,
    DuplicateDesignatorDataWarningRuntype,
    VaryingColumnNumberWarningRuntype,
);
export type WarningDTO = r.Static<typeof WarningRuntype>;

export const ColumnMappingRuntype = r.Record({
    side: r.Number.nullable(),
    designator: r.Number.nullable(),
});
export type ColumnMappingDTO = r.Static<typeof ColumnMappingRuntype>;

export const ColumnMappingAttemptNoHeaderRuntype = r.Record({
    type: r.Literal('NoHeader'),
});

export const ColumnMappingAttemptWithHeaderRuntype = r.Record({
    type: r.Literal('WithHeader'),
    column_mapping: ColumnMappingRuntype,
});

export const ColumnMappingAttemptRuntype = r.Union(
    ColumnMappingAttemptNoHeaderRuntype,
    ColumnMappingAttemptWithHeaderRuntype,
);
export type ColumnMappingAttemptDTO = r.Static<typeof ColumnMappingAttemptRuntype>;

export const ParsedPnpResponseRuntype = r.Record({
    contents: r.Array(r.Array(r.String)),
    warnings: r.Array(WarningRuntype),
    column_mapping: ColumnMappingAttemptRuntype,
});
export type ParsedPnpResponseDTO = r.Static<typeof ParsedPnpResponseRuntype>;

export const ColumnMappingRequestRuntype = r.Record({
    contents: r.Array(r.Array(r.String)),
    column_mapping: ColumnMappingRuntype,
});
export type ColumnMappingRequestDTO = r.Static<typeof ColumnMappingRequestRuntype>;

export const PnpItemRuntype = r.Record({
    id: r.String,
    pnp_file: r.String,
    designator: r.String,
    side: PnpSideRuntype.nullable(),
    design_item: r.String.nullable(),
    mounting: r.String.nullable(),
});
export type PnpItemDTO = r.Static<typeof PnpItemRuntype>;

export const BulkPnpItemsResponseRuntype = r.Record({
    warnings: r.Array(WarningRuntype),
    items: r.Array(PnpItemRuntype),
});
export type BulkPnpItemsResponseDTO = r.Static<typeof BulkPnpItemsResponseRuntype>;

export const NoPnpUploadedResponseRuntype = r.Record({
    type: r.Literal('PnpNotUploaded'),
});
export type NoPnpUploadedResponseDTO = r.Static<typeof NoPnpUploadedResponseRuntype>;

export const ColumnMappingMissingResponseRuntype = r.Record({
    type: r.Literal('ColumnMappingNotSubmitted'),
});
export type ColumnMappingMissingResponseDTO = r.Static<typeof NoPnpUploadedResponseRuntype>;

export const PnpUploadedResponseRuntype = r.Record({
    type: r.Literal('PnpUploaded'),
    data: BulkPnpItemsResponseRuntype,
});
export type PnpUploadedResponseDTO = r.Static<typeof PnpUploadedResponseRuntype>;

export const CheckedBulkPnpItemsResponseRuntype = r.Union(
    NoPnpUploadedResponseRuntype,
    PnpUploadedResponseRuntype,
    ColumnMappingMissingResponseRuntype,
);
export type CheckedBulkPnpItemsResponseDTO = r.Static<typeof CheckedBulkPnpItemsResponseRuntype>;

// Created: /pnp/upload-url called
// Uploaded: /pnp/parse called successfully
// Parsed: /pnp/column-mapping called successfully
export const PnpFileStateRuntype = r.Union(r.Literal('Created'), r.Literal('Uploaded'), r.Literal('Parsed'));
export type PnpFileStateDTO = r.Static<typeof PnpFileStateRuntype>;

export const PnpFileRuntype = r.Record({
    id: r.String,
    assembly_id: r.String,
    file_name: r.String,
    state: PnpFileStateRuntype,
    column_mapping: ColumnMappingRuntype.nullable(),
    url: r.String.nullable(),
});
export type PnpFileDTO = r.Static<typeof PnpFileRuntype>;
