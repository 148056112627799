import { colorSystem } from '@luminovo/design-system';
import React, { forwardRef, Ref } from 'react';

export const SeparateContainer = forwardRef(({ children }: { children: React.ReactNode }, ref: Ref<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                background: colorSystem.neutral[1],
                margin: '0px -20px -64px -20px',
                paddingBottom: '64px',
                paddingTop: '12px',
            }}
        >
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    background: colorSystem.neutral.white,
                    borderRadius: 8,
                    padding: '20px',
                    position: 'relative',
                    gap: '20px',
                }}
            >
                {children}
            </div>
        </div>
    );
});
