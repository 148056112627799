import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import { ResourceDTORuntype, ResourcePostDTORuntype, ResourcesDTORuntype } from './resourceBackendTypes';

export const resourceEndpoints = {
    'GET /resources/:resourceId': endpoint({
        description: 'Returns a resource',
        pathParams: r.Record({ resourceId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ResourceDTORuntype,
        }),
    }),

    'GET /resources': endpoint({
        description: 'Returns an array of all resources',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ResourcesDTORuntype,
        }),
    }),

    'POST /resources': endpoint({
        description: 'Creates a new resource',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ResourcePostDTORuntype,
        responseBody: r.Record({
            data: ResourceDTORuntype,
        }),
    }),

    'PATCH /resources/:resourceId': endpoint({
        description: 'updates a resource',
        pathParams: r.Record({ resourceId: r.String }),
        queryParams: r.Undefined,
        requestBody: ResourcePostDTORuntype.Or(r.Record({ status: ManufacturingEntityStatusRuntype })),
        responseBody: r.Record({
            data: ResourceDTORuntype,
        }),
    }),

    'DELETE /resources/:resourceId': endpoint({
        description: 'Deletes a resource',
        pathParams: r.Record({ resourceId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
};
