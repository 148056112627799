import { colorSystem } from '@luminovo/design-system';
import { FormulaErrorDTO } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { extractMessageFromFormulaError } from './advancedFormulaErrorFunction';

export const AdvancedFormulaErrorBox = ({ validationMessage }: { validationMessage?: FormulaErrorDTO }) => {
    return (
        <Box color={colorSystem.red[8]}>
            {validationMessage ? (
                <Typography component={'pre'} style={{ lineHeight: '24px' }}>
                    {extractMessageFromFormulaError(validationMessage)}
                </Typography>
            ) : (
                <></>
            )}
        </Box>
    );
};
