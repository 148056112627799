import { t } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import { TaxBreakdownDTO } from '@luminovo/http-client';
import { taxTypeTranslations } from '../i18n';

export const formatTax = (taxBreakdown: TaxBreakdownDTO): string => {
    const { country_code, state, percentage, tax_type } = taxBreakdown;

    const tax: string[] = [];
    tax.push(`${percentage}% `);
    if (isPresent(tax_type)) {
        const taxType = transEnum(tax_type, taxTypeTranslations);
        tax.push(taxType);
    } else {
        tax.push(t`Tax`);
    }
    if (isPresent(country_code)) {
        tax.push(' (');
        isPresent(state) && tax.push(`${state}, `);
        tax.push(country_code);
        tax.push(')');
    }

    return tax.join('');
};
