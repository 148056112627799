import { styled, TableCell } from '@mui/material';
import { DefaultHeaderTableCell } from './TableHeadCell';
import { Column, Row } from './types';

const CustomHeaderTableCell = styled(DefaultHeaderTableCell)({
    textAlign: 'right',
});

/**
 * A default implementation of right aligned column.
 */
export function defaultNumericColumn<TRowData, TContext = undefined>(
    column: Omit<Column<TRowData, TContext>, 'render' | 'renderHead'>,
    renderNumeric: (data: Row<TRowData>, sharedContext: TContext) => string | JSX.Element,
): Column<TRowData, TContext> {
    return {
        overrides: { HeaderTableCell: CustomHeaderTableCell },
        render: (data, sharedContext) => {
            return <StyledCell align="right">{renderNumeric(data, sharedContext)}</StyledCell>;
        },
        ...column,
    };
}

const StyledCell = styled(TableCell)({
    textAlign: 'right',
    // no wrap
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90px',
});
