import { isEqual } from '@luminovo/commons';
import {
    DesignItemOriginDTO,
    DesignItemOriginTypes,
    DesignItemResponseDTO,
    ExtractRequestBody,
    http,
    PartSpecification,
} from '@luminovo/http-client';
import { deleteDesignItems } from '../../resources/designItem/deleteDesignItems';
import { convertPossibleUndefinedToNull } from '../../utils/nullConversionUtils';
import { BomItemFormState, getSpecification as getPartSpecificationFromForm } from './components/BomItemFormState';

export function resolveOnlyUpdatedDesignItemFields({
    bomItemFormState,
    initialDesignItem,
    designItemIdsToPatch,
    partSpecification,
}: {
    bomItemFormState: BomItemFormState;
    initialDesignItem?: DesignItemResponseDTO;
    partSpecification: PartSpecification | null;
    designItemIdsToPatch: string[];
}): ExtractRequestBody<'PATCH /design-items/bulk'> {
    if (!initialDesignItem)
        /* eslint-disable camelcase */
        return {
            ids: designItemIdsToPatch,
            quantity: bomItemFormState.quantity,
            notes: bomItemFormState.notes,
            do_not_place: bomItemFormState.doNotPlace,
            part_specification: partSpecification,
            is_consigned: bomItemFormState.isConsigned,
            ignore_package_name_mismatch: bomItemFormState.ignorePackageNameMismatch,
            add_part_events: bomItemFormState.addPartEvents,
        };
    const result: ExtractRequestBody<'PATCH /design-items/bulk'> = {
        ids: designItemIdsToPatch,
        part_specification: partSpecification,
        add_part_events: bomItemFormState.addPartEvents,
    };
    if (bomItemFormState.isConsigned !== initialDesignItem.is_consigned) {
        result['is_consigned'] = bomItemFormState.isConsigned;
    }
    if (bomItemFormState.ignorePackageNameMismatch !== initialDesignItem.ignore_package_name_mismatch) {
        result['ignore_package_name_mismatch'] = bomItemFormState.ignorePackageNameMismatch;
    }
    if (bomItemFormState.doNotPlace !== initialDesignItem.do_not_place) {
        result['do_not_place'] = bomItemFormState.doNotPlace;
    }
    if (bomItemFormState.notes !== initialDesignItem.notes) {
        result['notes'] = bomItemFormState.notes;
    }
    if (!isEqual(bomItemFormState.quantity, initialDesignItem.quantity)) {
        result['quantity'] = bomItemFormState.quantity;
    }
    return result;
}

async function patchDesignItemsData({
    bomItemFormState,
    partSpecification,
    designItemIdsToPatch,
    token,
    initialDesignItem,
}: {
    bomItemFormState: BomItemFormState;
    partSpecification: PartSpecification | null;
    designItemIdsToPatch: string[];
    token: string;
    initialDesignItem: DesignItemResponseDTO;
}): Promise<string[]> {
    const body = resolveOnlyUpdatedDesignItemFields({
        bomItemFormState,
        partSpecification,
        designItemIdsToPatch,
        initialDesignItem,
    });
    const result = await http(
        'PATCH /design-items/bulk',
        {
            requestBody: body,
        },
        token,
    );
    return result.items;
}

function calculateChanges({
    initialDesignItems,
    values,
}: {
    initialDesignItems: DesignItemResponseDTO[];
    values: BomItemFormState;
}) {
    const designItemIdsToPatch = initialDesignItems
        .filter((c) => values.designator.includes(c.designator ?? ''))
        .map((c) => c.id);

    const designItemIdsToDelete = initialDesignItems
        .filter((c) => !values.designator.includes(c.designator ?? ''))
        .map((c) => c.id);

    const designatorsToAdd = values.designator.filter((d) => !initialDesignItems.map((c) => c.designator).includes(d));

    return {
        designItemIdsToPatch,
        designatorsToAdd,
        designItemIdsToDelete,
    };
}

export async function editDesignItems({
    values,
    initialDesignItems,
    token,
}: {
    values: BomItemFormState;
    initialDesignItems: DesignItemResponseDTO[];
    token: string;
}): Promise<string[]> {
    const partSpecification = getPartSpecificationFromForm(values);
    const { designItemIdsToPatch, designatorsToAdd, designItemIdsToDelete } = calculateChanges({
        initialDesignItems,
        values,
    });

    const ids: string[] = [];
    if (designItemIdsToPatch.length > 0) {
        const initialDesignItem = initialDesignItems[0];
        const idsToUpdate = await patchDesignItemsData({
            bomItemFormState: values,
            partSpecification,
            designItemIdsToPatch,
            token,
            initialDesignItem,
        });
        ids.push(...idsToUpdate);
    }
    if (designatorsToAdd.length > 0) {
        const inheritedOrigin = initialDesignItems[0]?.origin ?? null;

        if (inheritedOrigin && inheritedOrigin.type === DesignItemOriginTypes.ExcelFile && inheritedOrigin.data) {
            // eslint-disable-next-line camelcase
            inheritedOrigin.data.design_item_edited_since_import = true;
        }
        const aggregationKey = initialDesignItems[0]?.aggregation_key ?? null;
        const idsToAdd = await postDesignItemData({
            values,
            designatorsToAdd,
            partSpecification,
            token,
            inheritedOrigin,
            aggregationKey,
        });
        ids.push(...idsToAdd);
    }

    if (designItemIdsToDelete.length > 0) {
        await deleteDesignItems(designItemIdsToDelete, token);
    }

    return ids;
}

async function postDesignItemData({
    values,
    designatorsToAdd,
    partSpecification,
    token,
    inheritedOrigin,
    aggregationKey,
}: {
    values: BomItemFormState;
    designatorsToAdd: string[];
    partSpecification: PartSpecification | null;
    token: string;
    inheritedOrigin: DesignItemOriginDTO | null;
    aggregationKey: string | null;
}): Promise<string[]> {
    /* eslint-disable camelcase */

    const createDesignItemData = {
        designator: values.designator,
        assembly: values.parentId,
        quantity: values.quantity,
        notes: values.notes,
        do_not_place: values.doNotPlace,
        part_specification: partSpecification,
        origin: inheritedOrigin,
        is_consigned: values.isConsigned,
        aggregation_key: aggregationKey,
        ignore_package_name_mismatch: false,
    };
    const postObjects = designatorsToAdd.map((designator: string) => {
        return {
            ...createDesignItemData,
            designator: designator,
        };
    });
    const data = await http(
        'POST /design-items/many',
        {
            requestBody: { inputs: postObjects },
        },
        token,
    );
    return data.items;
    /* eslint-enable camelcase */
}

export const trimOptionalString = (string: string | null | undefined): string | null => {
    return string?.trim().length === 0 ? null : convertPossibleUndefinedToNull(string?.trim());
};

export const convertEmptyStringToNull = (val: string | null): string | null => {
    if (val === null || val === '') {
        return null;
    }
    return val;
};
