import { colorSystem, Tooltip } from '@luminovo/design-system';
import MuiHelpIcon from '@mui/icons-material/Help';
import { SvgIconProps } from '@mui/material';

export interface HelpIconProps {
    tooltipTitle: string;
    MuiHelpIconProps?: SvgIconProps;
}

export const HelpIcon = ({ tooltipTitle, MuiHelpIconProps }: HelpIconProps): JSX.Element => {
    return (
        <Tooltip title={tooltipTitle}>
            <MuiHelpIcon
                fontSize={'small'}
                {...MuiHelpIconProps}
                style={{ color: colorSystem.neutral[5], ...MuiHelpIconProps?.style }}
            />
        </Tooltip>
    );
};
