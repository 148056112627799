import { ListItemButton, ListItemButtonProps, styled } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import { colorSystem } from '../../theme';

const StyledListItemButton = styled(ListItemButton)({
    color: colorSystem.neutral[7],
    background: colorSystem.neutral[0],
    '&.Mui-selected': {
        color: colorSystem.primary[7],
        borderLeft: 'transparent',
        background: colorSystem.primary[2],
    },
    '&:hover': {
        color: colorSystem.neutral[9],
        background: colorSystem.neutral.white,
    },
    '&.Mui-selected:hover': {
        color: colorSystem.primary[7],
        borderLeft: 'transparent',
        background: colorSystem.primary[2],
    },
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
});

function useIsCurrent(href: string) {
    const { pathname } = useLocation();
    return pathname === href;
}

export function NavListItem({
    href,
    label,
    ...listItemProps
}: { href: string; label: string | JSX.Element } & ListItemButtonProps): JSX.Element {
    const isCurrentPage = useIsCurrent(href);

    return (
        <StyledLink to={href}>
            <StyledListItemButton disableRipple selected={isCurrentPage} {...listItemProps}>
                <ListItemText
                    primaryTypographyProps={{
                        noWrap: true,
                    }}
                    primary={label}
                />
            </StyledListItemButton>
        </StyledLink>
    );
}
