export const SVG_RULER_BG_COLOR = '#97FF13';
export const SVG_RULER_TEXT_COLOR = '#000000';
const SCALE_FACTOR = 1 / 100; // 1px = 0.01mm

export const adjustPixelToZoomLevel = (value: number, zoomScale: number) => {
    return (100 / zoomScale) * value * 10;
};

export const formatPixelsToDistance = (value: number): string => {
    const scaledValue = SCALE_FACTOR * value;
    if (scaledValue < 1) {
        return (scaledValue * 1000).toFixed(2) + ' μm';
    } else {
        return scaledValue.toFixed(2) + ' mm';
    }
};

export const getTextSize = (svgElement: SVGSVGElement) => {
    const g = svgElement.querySelector('g');
    let contentArea = 1;
    if (g) {
        // This is a pretty hacky way to get relative size of the content area
        contentArea = (g.getBBox().width * g.getBBox().height) / 10000000;
    }

    return contentArea < 2 ? 4 : contentArea + 4;
};

export const calculateDistanceBetweenTwoPoints = (start: DOMPoint, end: DOMPoint) => {
    return Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
};

export const calculateMidPoint = (start: DOMPoint, end: DOMPoint) => {
    const midPoint = { x: 0, y: 0 };
    midPoint.x = (start.x + end.x) / 2;
    midPoint.y = (start.y + end.y) / 2;
    return midPoint;
};

export const calculateAngleBetweenPoints = (start: DOMPoint, end: DOMPoint) => {
    const angleRadians = Math.atan2(end.y - start.y, end.x - start.x);
    const angleDegrees = (angleRadians * 180) / Math.PI;
    return angleDegrees < 0 ? angleDegrees + 360 : angleDegrees;
};
