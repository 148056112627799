import { t } from '@lingui/macro';
import React from 'react';
import { ParameterOption } from '../types';
import { InteractiveMenu } from './InteractiveMenu';

export function MenuFields<T, TAutocompleteState>({
    parameters,
    query,
    onSelect,
    inputRef,
    onSearch,
    showSearch = true,
    showIcons = true,
}: {
    parameters: ParameterOption<T, TAutocompleteState>[];
    query: string;
    onSelect: (param: ParameterOption<T, TAutocompleteState>) => void;
    onSearch: (query: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    showSearch?: boolean;
    showIcons?: boolean;
}): JSX.Element {
    return (
        <>
            <InteractiveMenu
                showIcons={showIcons}
                onSearch={onSearch}
                inputRef={inputRef}
                items={parameters.map((param) => {
                    return { id: param.field, label: param.label ?? param.field, value: param, icon: param.icon };
                })}
                query={query}
                onSelect={onSelect}
                title={t`Filter by`}
                showSearch={showSearch}
            />
        </>
    );
}
