import { t } from '@lingui/macro';
import { Flexbox, InteractiveCard, Tooltip } from '@luminovo/design-system';
import { DriversOfManufacturingDetailsDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { DriverNotes } from '../../../../shared/ManufacturingSharedComponents';
import { DriverCellInformation } from '../../../../shared/manufacturingComponents';
import { DriverStatusIconWithTooltip } from '../../../../utils/status';
import { EditDriverCount, EditDriverCountButton } from '../EditDriverCount/EditDriverCount';
import { InconsistentDriverPopup } from '../InconsistentDriverPopup';
import { LinkedDrivers } from '../LinkedDrivers';
import { DriverRowProps, useDriverRowProps } from './useDriverRowProps';

export const SelectableDriverRow = ({
    driverDetails,
    rfqId,
    manufacturingAssemblyDetails,
    isSelected,
    onClick,
}: {
    driverDetails: DriversOfManufacturingDetailsDTO;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    isSelected: boolean;
    onClick: React.MouseEventHandler<HTMLElement>;
}): JSX.Element => {
    const driverRowProps = useDriverRowProps(rfqId, manufacturingAssemblyDetails, driverDetails);

    return (
        <InteractiveCard selected={isSelected} onClick={onClick}>
            <DriverRow {...driverRowProps} />
        </InteractiveCard>
    );
};

const DriverRow = ({
    driverType,
    driverName,
    driverNotes,
    driverStatus,
    linkedDriversProps,
    inconsistentDriverPopupProps,
    editDriverCountProps,
}: DriverRowProps): JSX.Element => {
    return (
        <Flexbox flexDirection="column" width="100%" gap="8px">
            <Flexbox alignItems="center">
                <Flexbox gap="8px">
                    <DriverCellInformation driverName={driverName} driverNotes={driverNotes} driverType={driverType} />

                    {inconsistentDriverPopupProps && <InconsistentDriverPopup {...inconsistentDriverPopupProps} />}
                </Flexbox>
                <Flexbox marginLeft={'auto'} gap={'8px'} alignItems={'center'}>
                    <LinkedDrivers {...linkedDriversProps} />
                    {editDriverCountProps ? (
                        <EditDriverCount {...editDriverCountProps} />
                    ) : (
                        <Tooltip title={t`Please edit the driver count for each sourcing scenario`}>
                            <span>
                                <EditDriverCountButton disabled />
                            </span>
                        </Tooltip>
                    )}
                    <DriverStatusIconWithTooltip
                        status={driverStatus.type}
                        description={driverStatus.description}
                        iconType="status"
                    />
                </Flexbox>
            </Flexbox>
            {driverNotes && <DriverNotes maxLength={60} notes={driverNotes} />}
        </Flexbox>
    );
};
