export const DeliveryBatchSizeInfoGraphic = (): JSX.Element => (
    <svg viewBox="0 0 508 282" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2521_1728)">
            <rect width="508" height="282" rx="16" fill="#F5F6FA" />
            <path d="M255 52V85C255 92 154 90.5 154 100" stroke="#D8DEEE" />
            <path d="M255 52V85C255 91 356 90.5 356 100" stroke="#D8DEEE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M153.5 252V128H154.5V252H153.5Z" fill="#D8DEEE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M254.5 252V128H255.5V252H254.5Z" fill="#D8DEEE" />
            <path d="M255 128V85" stroke="#D8DEEE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M351.5 252V128H352.5V252H351.5Z" fill="#D8DEEE" />
            <g opacity="0.9" filter="url(#filter0_f_2521_1728)">
                <rect width="9" height="145" rx="2" transform="matrix(1 0 0 -1 -3 306)" fill="#9789F4" />
            </g>
            <g opacity="0.9" filter="url(#filter1_f_2521_1728)">
                <rect width="80" height="29" rx="2" transform="matrix(1 0 0 -1 461 -6)" fill="#9789F4" />
            </g>
            <path
                d="M205 38C205 30.268 211.268 24 219 24H288C295.732 24 302 30.268 302 38C302 45.732 295.732 52 288 52H219C211.268 52 205 45.732 205 38Z"
                fill="white"
            />
            <path
                d="M209 38C209 32.4772 213.477 28 219 28H234C239.523 28 244 32.4772 244 38C244 43.5228 239.523 48 234 48H219C213.477 48 209 43.5228 209 38Z"
                fill="#9789F4"
            />
            <path
                d="M232.419 40.5798V39.4798L235.689 34.7998H237.319V39.3598H238.199V40.5798H237.319V41.9998H235.919V40.5798H232.419ZM236.009 36.2998L233.959 39.3598H236.009V36.2998Z"
                fill="white"
            />
            <path
                d="M227.177 40.4198C227.817 39.8865 228.327 39.4431 228.707 39.0898C229.087 38.7298 229.404 38.3565 229.657 37.9698C229.91 37.5831 230.037 37.2031 230.037 36.8298C230.037 36.4898 229.957 36.2231 229.797 36.0298C229.637 35.8365 229.39 35.7398 229.057 35.7398C228.724 35.7398 228.467 35.8531 228.287 36.0798C228.107 36.2998 228.014 36.6031 228.007 36.9898H226.647C226.674 36.1898 226.91 35.5831 227.357 35.1698C227.81 34.7565 228.384 34.5498 229.077 34.5498C229.837 34.5498 230.42 34.7531 230.827 35.1598C231.234 35.5598 231.437 36.0898 231.437 36.7498C231.437 37.2698 231.297 37.7665 231.017 38.2398C230.737 38.7131 230.417 39.1265 230.057 39.4798C229.697 39.8265 229.227 40.2465 228.647 40.7398H231.597V41.8998H226.657V40.8598L227.177 40.4198Z"
                fill="white"
            />
            <path
                d="M220.272 38.2598C220.272 37.1065 220.479 36.2032 220.892 35.5498C221.312 34.8965 222.006 34.5698 222.972 34.5698C223.939 34.5698 224.629 34.8965 225.042 35.5498C225.462 36.2032 225.672 37.1065 225.672 38.2598C225.672 39.4198 225.462 40.3298 225.042 40.9898C224.629 41.6498 223.939 41.9798 222.972 41.9798C222.006 41.9798 221.312 41.6498 220.892 40.9898C220.479 40.3298 220.272 39.4198 220.272 38.2598ZM224.292 38.2598C224.292 37.7665 224.259 37.3532 224.192 37.0198C224.132 36.6798 224.006 36.4032 223.812 36.1898C223.626 35.9765 223.346 35.8698 222.972 35.8698C222.599 35.8698 222.316 35.9765 222.122 36.1898C221.936 36.4032 221.809 36.6798 221.742 37.0198C221.682 37.3532 221.652 37.7665 221.652 38.2598C221.652 38.7665 221.682 39.1932 221.742 39.5398C221.802 39.8798 221.929 40.1565 222.122 40.3698C222.316 40.5765 222.599 40.6798 222.972 40.6798C223.346 40.6798 223.629 40.5765 223.822 40.3698C224.016 40.1565 224.142 39.8798 224.202 39.5398C224.262 39.1932 224.292 38.7665 224.292 38.2598Z"
                fill="white"
            />
            <path
                d="M214.97 40.4198C215.61 39.8865 216.12 39.4431 216.5 39.0898C216.88 38.7298 217.197 38.3565 217.45 37.9698C217.703 37.5831 217.83 37.2031 217.83 36.8298C217.83 36.4898 217.75 36.2231 217.59 36.0298C217.43 35.8365 217.183 35.7398 216.85 35.7398C216.517 35.7398 216.26 35.8531 216.08 36.0798C215.9 36.2998 215.807 36.6031 215.8 36.9898H214.44C214.467 36.1898 214.703 35.5831 215.15 35.1698C215.603 34.7565 216.177 34.5498 216.87 34.5498C217.63 34.5498 218.213 34.7531 218.62 35.1598C219.027 35.5598 219.23 36.0898 219.23 36.7498C219.23 37.2698 219.09 37.7665 218.81 38.2398C218.53 38.7131 218.21 39.1265 217.85 39.4798C217.49 39.8265 217.02 40.2465 216.44 40.7398H219.39V41.8998H214.45V40.8598L214.97 40.4198Z"
                fill="white"
            />
            <path
                d="M284.702 37.512C284.702 36.128 284.95 35.044 285.446 34.26C285.95 33.476 286.782 33.084 287.942 33.084C289.102 33.084 289.93 33.476 290.426 34.26C290.93 35.044 291.182 36.128 291.182 37.512C291.182 38.904 290.93 39.996 290.426 40.788C289.93 41.58 289.102 41.976 287.942 41.976C286.782 41.976 285.95 41.58 285.446 40.788C284.95 39.996 284.702 38.904 284.702 37.512ZM289.526 37.512C289.526 36.92 289.486 36.424 289.406 36.024C289.334 35.616 289.182 35.284 288.95 35.028C288.726 34.772 288.39 34.644 287.942 34.644C287.494 34.644 287.154 34.772 286.922 35.028C286.698 35.284 286.546 35.616 286.466 36.024C286.394 36.424 286.358 36.92 286.358 37.512C286.358 38.12 286.394 38.632 286.466 39.048C286.538 39.456 286.69 39.788 286.922 40.044C287.154 40.292 287.494 40.416 287.942 40.416C288.39 40.416 288.73 40.292 288.962 40.044C289.194 39.788 289.346 39.456 289.418 39.048C289.49 38.632 289.526 38.12 289.526 37.512Z"
                fill="#2A3147"
            />
            <path
                d="M276.945 37.512C276.945 36.128 277.193 35.044 277.689 34.26C278.193 33.476 279.025 33.084 280.185 33.084C281.345 33.084 282.173 33.476 282.669 34.26C283.173 35.044 283.425 36.128 283.425 37.512C283.425 38.904 283.173 39.996 282.669 40.788C282.173 41.58 281.345 41.976 280.185 41.976C279.025 41.976 278.193 41.58 277.689 40.788C277.193 39.996 276.945 38.904 276.945 37.512ZM281.769 37.512C281.769 36.92 281.729 36.424 281.649 36.024C281.577 35.616 281.425 35.284 281.193 35.028C280.969 34.772 280.633 34.644 280.185 34.644C279.737 34.644 279.397 34.772 279.165 35.028C278.941 35.284 278.789 35.616 278.709 36.024C278.637 36.424 278.601 36.92 278.601 37.512C278.601 38.12 278.637 38.632 278.709 39.048C278.781 39.456 278.933 39.788 279.165 40.044C279.397 40.292 279.737 40.416 280.185 40.416C280.633 40.416 280.973 40.292 281.205 40.044C281.437 39.788 281.589 39.456 281.661 39.048C281.733 38.632 281.769 38.12 281.769 37.512Z"
                fill="#2A3147"
            />
            <path
                d="M269.187 37.512C269.187 36.128 269.435 35.044 269.931 34.26C270.435 33.476 271.267 33.084 272.427 33.084C273.587 33.084 274.415 33.476 274.911 34.26C275.415 35.044 275.667 36.128 275.667 37.512C275.667 38.904 275.415 39.996 274.911 40.788C274.415 41.58 273.587 41.976 272.427 41.976C271.267 41.976 270.435 41.58 269.931 40.788C269.435 39.996 269.187 38.904 269.187 37.512ZM274.011 37.512C274.011 36.92 273.971 36.424 273.891 36.024C273.819 35.616 273.667 35.284 273.435 35.028C273.211 34.772 272.875 34.644 272.427 34.644C271.979 34.644 271.639 34.772 271.407 35.028C271.183 35.284 271.031 35.616 270.951 36.024C270.879 36.424 270.843 36.92 270.843 37.512C270.843 38.12 270.879 38.632 270.951 39.048C271.023 39.456 271.175 39.788 271.407 40.044C271.639 40.292 271.979 40.416 272.427 40.416C272.875 40.416 273.215 40.292 273.447 40.044C273.679 39.788 273.831 39.456 273.903 39.048C273.975 38.632 274.011 38.12 274.011 37.512Z"
                fill="#2A3147"
            />
            <path
                d="M267.005 42.0837C266.701 42.0837 266.449 41.9917 266.249 41.8077C266.057 41.6157 265.961 41.3797 265.961 41.0997C265.961 40.8197 266.057 40.5877 266.249 40.4037C266.449 40.2117 266.701 40.1157 267.005 40.1157C267.301 40.1157 267.545 40.2117 267.737 40.4037C267.929 40.5877 268.025 40.8197 268.025 41.0997C268.025 41.3797 267.929 41.6157 267.737 41.8077C267.545 41.9917 267.301 42.0837 267.005 42.0837Z"
                fill="#2A3147"
            />
            <path
                d="M258.312 37.512C258.312 36.128 258.56 35.044 259.056 34.26C259.56 33.476 260.392 33.084 261.552 33.084C262.712 33.084 263.54 33.476 264.036 34.26C264.54 35.044 264.792 36.128 264.792 37.512C264.792 38.904 264.54 39.996 264.036 40.788C263.54 41.58 262.712 41.976 261.552 41.976C260.392 41.976 259.56 41.58 259.056 40.788C258.56 39.996 258.312 38.904 258.312 37.512ZM263.136 37.512C263.136 36.92 263.096 36.424 263.016 36.024C262.944 35.616 262.792 35.284 262.56 35.028C262.336 34.772 262 34.644 261.552 34.644C261.104 34.644 260.764 34.772 260.532 35.028C260.308 35.284 260.156 35.616 260.076 36.024C260.004 36.424 259.968 36.92 259.968 37.512C259.968 38.12 260.004 38.632 260.076 39.048C260.148 39.456 260.3 39.788 260.532 40.044C260.764 40.292 261.104 40.416 261.552 40.416C262 40.416 262.34 40.292 262.572 40.044C262.804 39.788 262.956 39.456 263.028 39.048C263.1 38.632 263.136 38.12 263.136 37.512Z"
                fill="#2A3147"
            />
            <path
                d="M255.172 35.4359C255.092 35.0999 254.952 34.8479 254.752 34.6799C254.56 34.5119 254.28 34.4279 253.912 34.4279C253.36 34.4279 252.952 34.6519 252.688 35.0999C252.432 35.5399 252.3 36.2599 252.292 37.2599C252.484 36.9399 252.764 36.6919 253.132 36.5159C253.5 36.3319 253.9 36.2399 254.332 36.2399C254.852 36.2399 255.312 36.3519 255.712 36.5759C256.112 36.7999 256.424 37.1279 256.648 37.5599C256.872 37.9839 256.984 38.4959 256.984 39.0959C256.984 39.6639 256.868 40.1719 256.636 40.6199C256.412 41.0599 256.08 41.4039 255.64 41.6519C255.2 41.8999 254.676 42.0239 254.068 42.0239C253.236 42.0239 252.58 41.8399 252.1 41.4719C251.628 41.1039 251.296 40.5919 251.104 39.9359C250.92 39.2719 250.828 38.4559 250.828 37.4879C250.828 36.0239 251.08 34.9199 251.584 34.1759C252.088 33.4239 252.884 33.0479 253.972 33.0479C254.812 33.0479 255.464 33.2759 255.928 33.7319C256.392 34.1879 256.66 34.7559 256.732 35.4359H255.172ZM253.948 37.6199C253.524 37.6199 253.168 37.7439 252.88 37.9919C252.592 38.2399 252.448 38.5999 252.448 39.0719C252.448 39.5439 252.58 39.9159 252.844 40.1879C253.116 40.4599 253.496 40.5959 253.984 40.5959C254.416 40.5959 254.756 40.4639 255.004 40.1999C255.26 39.9359 255.388 39.5799 255.388 39.1319C255.388 38.6679 255.264 38.2999 255.016 38.0279C254.776 37.7559 254.42 37.6199 253.948 37.6199Z"
                fill="#2A3147"
            />
            <path
                d="M125 108C125 103.582 128.582 100 133 100H176C180.418 100 184 103.582 184 108V120C184 124.418 180.418 128 176 128H133C128.582 128 125 124.418 125 120V108Z"
                fill="white"
            />
            <rect x="133.5" y="140.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="133.5" y="180.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <path
                d="M234.5 204C234.5 202.067 236.067 200.5 238 200.5H272C273.933 200.5 275.5 202.067 275.5 204V212C275.5 213.933 273.933 215.5 272 215.5H238C236.067 215.5 234.5 213.933 234.5 212V204Z"
                fill="#E9ECF4"
                stroke="#D8DEEE"
            />
            <rect x="331.5" y="200.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="331.5" y="180.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="331.5" y="160.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="331.5" y="140.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="331.5" y="240.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="331.5" y="220.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="234.5" y="140.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <rect x="133.5" y="220.5" width="41" height="15" rx="3.5" fill="#E9ECF4" stroke="#D8DEEE" />
            <path
                d="M323 108C323 103.582 326.582 100 331 100H374C378.418 100 382 103.582 382 108V120C382 124.418 378.418 128 374 128H331C326.582 128 323 124.418 323 120V108Z"
                fill="white"
            />
            <path
                d="M224 108C224 103.582 227.582 100 232 100H275C279.418 100 283 103.582 283 108V120C283 124.418 279.418 128 275 128H232C227.582 128 224 124.418 224 120V108Z"
                fill="white"
            />
            <rect width="4" height="4" rx="2" transform="matrix(-1 0 0 1 137 112)" fill="#D8DEEE" />
            <rect width="4" height="4" rx="2" transform="matrix(-1 0 0 1 335 112)" fill="#D8DEEE" />
            <rect width="4" height="4" rx="2" transform="matrix(-1 0 0 1 236 112)" fill="#D8DEEE" />
            <path
                d="M169.264 114.26C169.264 113.106 169.471 112.203 169.884 111.55C170.304 110.896 170.998 110.57 171.964 110.57C172.931 110.57 173.621 110.896 174.034 111.55C174.454 112.203 174.664 113.106 174.664 114.26C174.664 115.42 174.454 116.33 174.034 116.99C173.621 117.65 172.931 117.98 171.964 117.98C170.998 117.98 170.304 117.65 169.884 116.99C169.471 116.33 169.264 115.42 169.264 114.26ZM173.284 114.26C173.284 113.766 173.251 113.353 173.184 113.02C173.124 112.68 172.998 112.403 172.804 112.19C172.618 111.976 172.338 111.87 171.964 111.87C171.591 111.87 171.308 111.976 171.114 112.19C170.928 112.403 170.801 112.68 170.734 113.02C170.674 113.353 170.644 113.766 170.644 114.26C170.644 114.766 170.674 115.193 170.734 115.54C170.794 115.88 170.921 116.156 171.114 116.37C171.308 116.576 171.591 116.68 171.964 116.68C172.338 116.68 172.621 116.576 172.814 116.37C173.008 116.156 173.134 115.88 173.194 115.54C173.254 115.193 173.284 114.766 173.284 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M162.8 114.26C162.8 113.106 163.006 112.203 163.42 111.55C163.84 110.896 164.533 110.57 165.5 110.57C166.466 110.57 167.156 110.896 167.57 111.55C167.99 112.203 168.2 113.106 168.2 114.26C168.2 115.42 167.99 116.33 167.57 116.99C167.156 117.65 166.466 117.98 165.5 117.98C164.533 117.98 163.84 117.65 163.42 116.99C163.006 116.33 162.8 115.42 162.8 114.26ZM166.82 114.26C166.82 113.766 166.786 113.353 166.72 113.02C166.66 112.68 166.533 112.403 166.34 112.19C166.153 111.976 165.873 111.87 165.5 111.87C165.126 111.87 164.843 111.976 164.65 112.19C164.463 112.403 164.336 112.68 164.27 113.02C164.21 113.353 164.18 113.766 164.18 114.26C164.18 114.766 164.21 115.193 164.27 115.54C164.33 115.88 164.456 116.156 164.65 116.37C164.843 116.576 165.126 116.68 165.5 116.68C165.873 116.68 166.156 116.576 166.35 116.37C166.543 116.156 166.67 115.88 166.73 115.54C166.79 115.193 166.82 114.766 166.82 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M156.335 114.26C156.335 113.106 156.541 112.203 156.955 111.55C157.375 110.896 158.068 110.57 159.035 110.57C160.001 110.57 160.691 110.896 161.105 111.55C161.525 112.203 161.735 113.106 161.735 114.26C161.735 115.42 161.525 116.33 161.105 116.99C160.691 117.65 160.001 117.98 159.035 117.98C158.068 117.98 157.375 117.65 156.955 116.99C156.541 116.33 156.335 115.42 156.335 114.26ZM160.355 114.26C160.355 113.766 160.321 113.353 160.255 113.02C160.195 112.68 160.068 112.403 159.875 112.19C159.688 111.976 159.408 111.87 159.035 111.87C158.661 111.87 158.378 111.976 158.185 112.19C157.998 112.403 157.871 112.68 157.805 113.02C157.745 113.353 157.715 113.766 157.715 114.26C157.715 114.766 157.745 115.193 157.805 115.54C157.865 115.88 157.991 116.156 158.185 116.37C158.378 116.576 158.661 116.68 159.035 116.68C159.408 116.68 159.691 116.576 159.885 116.37C160.078 116.156 160.205 115.88 160.265 115.54C160.325 115.193 160.355 114.766 160.355 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M154.517 118.07C154.264 118.07 154.054 117.993 153.887 117.84C153.727 117.68 153.647 117.483 153.647 117.25C153.647 117.016 153.727 116.823 153.887 116.67C154.054 116.51 154.264 116.43 154.517 116.43C154.764 116.43 154.967 116.51 155.127 116.67C155.287 116.823 155.367 117.016 155.367 117.25C155.367 117.483 155.287 117.68 155.127 117.84C154.967 117.993 154.764 118.07 154.517 118.07Z"
                fill="#2A3147"
            />
            <path
                d="M147.272 114.26C147.272 113.106 147.479 112.203 147.892 111.55C148.312 110.896 149.006 110.57 149.972 110.57C150.939 110.57 151.629 110.896 152.042 111.55C152.462 112.203 152.672 113.106 152.672 114.26C152.672 115.42 152.462 116.33 152.042 116.99C151.629 117.65 150.939 117.98 149.972 117.98C149.006 117.98 148.312 117.65 147.892 116.99C147.479 116.33 147.272 115.42 147.272 114.26ZM151.292 114.26C151.292 113.766 151.259 113.353 151.192 113.02C151.132 112.68 151.006 112.403 150.812 112.19C150.626 111.976 150.346 111.87 149.972 111.87C149.599 111.87 149.316 111.976 149.122 112.19C148.936 112.403 148.809 112.68 148.742 113.02C148.682 113.353 148.652 113.766 148.652 114.26C148.652 114.766 148.682 115.193 148.742 115.54C148.802 115.88 148.929 116.156 149.122 116.37C149.316 116.576 149.599 116.68 149.972 116.68C150.346 116.68 150.629 116.576 150.822 116.37C151.016 116.156 151.142 115.88 151.202 115.54C151.262 115.193 151.292 114.766 151.292 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M141.97 116.42C142.61 115.886 143.12 115.443 143.5 115.09C143.88 114.73 144.197 114.356 144.45 113.97C144.703 113.583 144.83 113.203 144.83 112.83C144.83 112.49 144.75 112.223 144.59 112.03C144.43 111.836 144.183 111.74 143.85 111.74C143.517 111.74 143.26 111.853 143.08 112.08C142.9 112.3 142.807 112.603 142.8 112.99H141.44C141.467 112.19 141.703 111.583 142.15 111.17C142.603 110.756 143.177 110.55 143.87 110.55C144.63 110.55 145.213 110.753 145.62 111.16C146.027 111.56 146.23 112.09 146.23 112.75C146.23 113.27 146.09 113.766 145.81 114.24C145.53 114.713 145.21 115.126 144.85 115.48C144.49 115.826 144.02 116.246 143.44 116.74H146.39V117.9H141.45V116.86L141.97 116.42Z"
                fill="#2A3147"
            />
            <path
                d="M365.145 114.26C365.145 113.106 365.352 112.203 365.765 111.55C366.185 110.896 366.878 110.57 367.845 110.57C368.812 110.57 369.502 110.896 369.915 111.55C370.335 112.203 370.545 113.106 370.545 114.26C370.545 115.42 370.335 116.33 369.915 116.99C369.502 117.65 368.812 117.98 367.845 117.98C366.878 117.98 366.185 117.65 365.765 116.99C365.352 116.33 365.145 115.42 365.145 114.26ZM369.165 114.26C369.165 113.766 369.132 113.353 369.065 113.02C369.005 112.68 368.878 112.403 368.685 112.19C368.498 111.976 368.218 111.87 367.845 111.87C367.472 111.87 367.188 111.976 366.995 112.19C366.808 112.403 366.682 112.68 366.615 113.02C366.555 113.353 366.525 113.766 366.525 114.26C366.525 114.766 366.555 115.193 366.615 115.54C366.675 115.88 366.802 116.156 366.995 116.37C367.188 116.576 367.472 116.68 367.845 116.68C368.218 116.68 368.502 116.576 368.695 116.37C368.888 116.156 369.015 115.88 369.075 115.54C369.135 115.193 369.165 114.766 369.165 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M358.68 114.26C358.68 113.106 358.887 112.203 359.3 111.55C359.72 110.896 360.414 110.57 361.38 110.57C362.347 110.57 363.037 110.896 363.45 111.55C363.87 112.203 364.08 113.106 364.08 114.26C364.08 115.42 363.87 116.33 363.45 116.99C363.037 117.65 362.347 117.98 361.38 117.98C360.414 117.98 359.72 117.65 359.3 116.99C358.887 116.33 358.68 115.42 358.68 114.26ZM362.7 114.26C362.7 113.766 362.667 113.353 362.6 113.02C362.54 112.68 362.414 112.403 362.22 112.19C362.034 111.976 361.754 111.87 361.38 111.87C361.007 111.87 360.724 111.976 360.53 112.19C360.344 112.403 360.217 112.68 360.15 113.02C360.09 113.353 360.06 113.766 360.06 114.26C360.06 114.766 360.09 115.193 360.15 115.54C360.21 115.88 360.337 116.156 360.53 116.37C360.724 116.576 361.007 116.68 361.38 116.68C361.754 116.68 362.037 116.576 362.23 116.37C362.424 116.156 362.55 115.88 362.61 115.54C362.67 115.193 362.7 114.766 362.7 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M352.215 114.26C352.215 113.106 352.422 112.203 352.835 111.55C353.255 110.896 353.949 110.57 354.915 110.57C355.882 110.57 356.572 110.896 356.985 111.55C357.405 112.203 357.615 113.106 357.615 114.26C357.615 115.42 357.405 116.33 356.985 116.99C356.572 117.65 355.882 117.98 354.915 117.98C353.949 117.98 353.255 117.65 352.835 116.99C352.422 116.33 352.215 115.42 352.215 114.26ZM356.235 114.26C356.235 113.766 356.202 113.353 356.135 113.02C356.075 112.68 355.949 112.403 355.755 112.19C355.569 111.976 355.289 111.87 354.915 111.87C354.542 111.87 354.259 111.976 354.065 112.19C353.879 112.403 353.752 112.68 353.685 113.02C353.625 113.353 353.595 113.766 353.595 114.26C353.595 114.766 353.625 115.193 353.685 115.54C353.745 115.88 353.872 116.156 354.065 116.37C354.259 116.576 354.542 116.68 354.915 116.68C355.289 116.68 355.572 116.576 355.765 116.37C355.959 116.156 356.085 115.88 356.145 115.54C356.205 115.193 356.235 114.766 356.235 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M350.398 118.07C350.144 118.07 349.934 117.993 349.768 117.84C349.608 117.68 349.528 117.483 349.528 117.25C349.528 117.016 349.608 116.823 349.768 116.67C349.934 116.51 350.144 116.43 350.398 116.43C350.644 116.43 350.848 116.51 351.008 116.67C351.168 116.823 351.248 117.016 351.248 117.25C351.248 117.483 351.168 117.68 351.008 117.84C350.848 117.993 350.644 118.07 350.398 118.07Z"
                fill="#2A3147"
            />
            <path
                d="M343.153 114.26C343.153 113.106 343.359 112.203 343.773 111.55C344.193 110.896 344.886 110.57 345.853 110.57C346.819 110.57 347.509 110.896 347.923 111.55C348.343 112.203 348.553 113.106 348.553 114.26C348.553 115.42 348.343 116.33 347.923 116.99C347.509 117.65 346.819 117.98 345.853 117.98C344.886 117.98 344.193 117.65 343.773 116.99C343.359 116.33 343.153 115.42 343.153 114.26ZM347.173 114.26C347.173 113.766 347.139 113.353 347.073 113.02C347.013 112.68 346.886 112.403 346.693 112.19C346.506 111.976 346.226 111.87 345.853 111.87C345.479 111.87 345.196 111.976 345.003 112.19C344.816 112.403 344.689 112.68 344.623 113.02C344.563 113.353 344.533 113.766 344.533 114.26C344.533 114.766 344.563 115.193 344.623 115.54C344.683 115.88 344.809 116.156 345.003 116.37C345.196 116.576 345.479 116.68 345.853 116.68C346.226 116.68 346.509 116.576 346.703 116.37C346.896 116.156 347.023 115.88 347.083 115.54C347.143 115.193 347.173 114.766 347.173 114.26Z"
                fill="#2A3147"
            />
            <path d="M339.36 112V110.71H341.77V118H340.33V112H339.36Z" fill="#2A3147" />
            <path
                d="M268.509 114.26C268.509 113.106 268.715 112.203 269.129 111.55C269.549 110.896 270.242 110.57 271.209 110.57C272.175 110.57 272.865 110.896 273.279 111.55C273.699 112.203 273.909 113.106 273.909 114.26C273.909 115.42 273.699 116.33 273.279 116.99C272.865 117.65 272.175 117.98 271.209 117.98C270.242 117.98 269.549 117.65 269.129 116.99C268.715 116.33 268.509 115.42 268.509 114.26ZM272.529 114.26C272.529 113.766 272.495 113.353 272.429 113.02C272.369 112.68 272.242 112.403 272.049 112.19C271.862 111.976 271.582 111.87 271.209 111.87C270.835 111.87 270.552 111.976 270.359 112.19C270.172 112.403 270.045 112.68 269.979 113.02C269.919 113.353 269.889 113.766 269.889 114.26C269.889 114.766 269.919 115.193 269.979 115.54C270.039 115.88 270.165 116.156 270.359 116.37C270.552 116.576 270.835 116.68 271.209 116.68C271.582 116.68 271.865 116.576 272.059 116.37C272.252 116.156 272.379 115.88 272.439 115.54C272.499 115.193 272.529 114.766 272.529 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M262.044 114.26C262.044 113.106 262.25 112.203 262.664 111.55C263.084 110.896 263.777 110.57 264.744 110.57C265.71 110.57 266.4 110.896 266.814 111.55C267.234 112.203 267.444 113.106 267.444 114.26C267.444 115.42 267.234 116.33 266.814 116.99C266.4 117.65 265.71 117.98 264.744 117.98C263.777 117.98 263.084 117.65 262.664 116.99C262.25 116.33 262.044 115.42 262.044 114.26ZM266.064 114.26C266.064 113.766 266.03 113.353 265.964 113.02C265.904 112.68 265.777 112.403 265.584 112.19C265.397 111.976 265.117 111.87 264.744 111.87C264.37 111.87 264.087 111.976 263.894 112.19C263.707 112.403 263.58 112.68 263.514 113.02C263.454 113.353 263.424 113.766 263.424 114.26C263.424 114.766 263.454 115.193 263.514 115.54C263.574 115.88 263.7 116.156 263.894 116.37C264.087 116.576 264.37 116.68 264.744 116.68C265.117 116.68 265.4 116.576 265.594 116.37C265.787 116.156 265.914 115.88 265.974 115.54C266.034 115.193 266.064 114.766 266.064 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M255.579 114.26C255.579 113.106 255.786 112.203 256.199 111.55C256.619 110.896 257.312 110.57 258.279 110.57C259.246 110.57 259.936 110.896 260.349 111.55C260.769 112.203 260.979 113.106 260.979 114.26C260.979 115.42 260.769 116.33 260.349 116.99C259.936 117.65 259.246 117.98 258.279 117.98C257.312 117.98 256.619 117.65 256.199 116.99C255.786 116.33 255.579 115.42 255.579 114.26ZM259.599 114.26C259.599 113.766 259.566 113.353 259.499 113.02C259.439 112.68 259.312 112.403 259.119 112.19C258.932 111.976 258.652 111.87 258.279 111.87C257.906 111.87 257.622 111.976 257.429 112.19C257.242 112.403 257.116 112.68 257.049 113.02C256.989 113.353 256.959 113.766 256.959 114.26C256.959 114.766 256.989 115.193 257.049 115.54C257.109 115.88 257.236 116.156 257.429 116.37C257.622 116.576 257.906 116.68 258.279 116.68C258.652 116.68 258.936 116.576 259.129 116.37C259.322 116.156 259.449 115.88 259.509 115.54C259.569 115.193 259.599 114.766 259.599 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M253.761 118.07C253.508 118.07 253.298 117.994 253.131 117.84C252.971 117.68 252.891 117.484 252.891 117.25C252.891 117.017 252.971 116.824 253.131 116.67C253.298 116.51 253.508 116.43 253.761 116.43C254.008 116.43 254.211 116.51 254.371 116.67C254.531 116.824 254.611 117.017 254.611 117.25C254.611 117.484 254.531 117.68 254.371 117.84C254.211 117.994 254.008 118.07 253.761 118.07Z"
                fill="#2A3147"
            />
            <path
                d="M246.516 114.26C246.516 113.106 246.723 112.203 247.136 111.55C247.556 110.896 248.25 110.57 249.216 110.57C250.183 110.57 250.873 110.896 251.286 111.55C251.706 112.203 251.916 113.106 251.916 114.26C251.916 115.42 251.706 116.33 251.286 116.99C250.873 117.65 250.183 117.98 249.216 117.98C248.25 117.98 247.556 117.65 247.136 116.99C246.723 116.33 246.516 115.42 246.516 114.26ZM250.536 114.26C250.536 113.766 250.503 113.353 250.436 113.02C250.376 112.68 250.25 112.403 250.056 112.19C249.87 111.976 249.59 111.87 249.216 111.87C248.843 111.87 248.56 111.976 248.366 112.19C248.18 112.403 248.053 112.68 247.986 113.02C247.926 113.353 247.896 113.766 247.896 114.26C247.896 114.766 247.926 115.193 247.986 115.54C248.046 115.88 248.173 116.156 248.366 116.37C248.56 116.576 248.843 116.68 249.216 116.68C249.59 116.68 249.873 116.576 250.066 116.37C250.26 116.156 250.386 115.88 250.446 115.54C250.506 115.193 250.536 114.766 250.536 114.26Z"
                fill="#2A3147"
            />
            <path
                d="M240.55 112.63C240.583 111.963 240.817 111.45 241.25 111.09C241.69 110.723 242.267 110.54 242.98 110.54C243.467 110.54 243.883 110.627 244.23 110.8C244.577 110.967 244.837 111.197 245.01 111.49C245.19 111.777 245.28 112.103 245.28 112.47C245.28 112.89 245.17 113.247 244.95 113.54C244.737 113.827 244.48 114.02 244.18 114.12V114.16C244.567 114.28 244.867 114.493 245.08 114.8C245.3 115.107 245.41 115.5 245.41 115.98C245.41 116.38 245.317 116.737 245.13 117.05C244.95 117.363 244.68 117.61 244.32 117.79C243.967 117.963 243.54 118.05 243.04 118.05C242.287 118.05 241.673 117.86 241.2 117.48C240.727 117.1 240.477 116.54 240.45 115.8H241.81C241.823 116.127 241.933 116.39 242.14 116.59C242.353 116.783 242.643 116.88 243.01 116.88C243.35 116.88 243.61 116.787 243.79 116.6C243.977 116.407 244.07 116.16 244.07 115.86C244.07 115.46 243.943 115.173 243.69 115C243.437 114.827 243.043 114.74 242.51 114.74H242.22V113.59H242.51C243.457 113.59 243.93 113.273 243.93 112.64C243.93 112.353 243.843 112.13 243.67 111.97C243.503 111.81 243.26 111.73 242.94 111.73C242.627 111.73 242.383 111.817 242.21 111.99C242.043 112.157 241.947 112.37 241.92 112.63H240.55Z"
                fill="#2A3147"
            />
            <path
                d="M165.002 148.26C165.002 147.107 165.196 146.207 165.582 145.56C165.976 144.907 166.649 144.58 167.602 144.58C168.556 144.58 169.226 144.907 169.612 145.56C170.006 146.207 170.202 147.107 170.202 148.26C170.202 149.427 170.006 150.34 169.612 151C169.226 151.653 168.556 151.98 167.602 151.98C166.649 151.98 165.976 151.653 165.582 151C165.196 150.34 165.002 149.427 165.002 148.26ZM169.082 148.26C169.082 147.72 169.046 147.263 168.972 146.89C168.906 146.517 168.766 146.213 168.552 145.98C168.339 145.74 168.022 145.62 167.602 145.62C167.182 145.62 166.866 145.74 166.652 145.98C166.439 146.213 166.296 146.517 166.222 146.89C166.156 147.263 166.122 147.72 166.122 148.26C166.122 148.82 166.156 149.29 166.222 149.67C166.289 150.05 166.429 150.357 166.642 150.59C166.862 150.823 167.182 150.94 167.602 150.94C168.022 150.94 168.339 150.823 168.552 150.59C168.772 150.357 168.916 150.05 168.982 149.67C169.049 149.29 169.082 148.82 169.082 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M158.596 148.26C158.596 147.107 158.79 146.207 159.176 145.56C159.57 144.907 160.243 144.58 161.196 144.58C162.15 144.58 162.82 144.907 163.206 145.56C163.6 146.207 163.796 147.107 163.796 148.26C163.796 149.427 163.6 150.34 163.206 151C162.82 151.653 162.15 151.98 161.196 151.98C160.243 151.98 159.57 151.653 159.176 151C158.79 150.34 158.596 149.427 158.596 148.26ZM162.676 148.26C162.676 147.72 162.64 147.263 162.566 146.89C162.5 146.517 162.36 146.213 162.146 145.98C161.933 145.74 161.616 145.62 161.196 145.62C160.776 145.62 160.46 145.74 160.246 145.98C160.033 146.213 159.89 146.517 159.816 146.89C159.75 147.263 159.716 147.72 159.716 148.26C159.716 148.82 159.75 149.29 159.816 149.67C159.883 150.05 160.023 150.357 160.236 150.59C160.456 150.823 160.776 150.94 161.196 150.94C161.616 150.94 161.933 150.823 162.146 150.59C162.366 150.357 162.51 150.05 162.576 149.67C162.643 149.29 162.676 148.82 162.676 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M152.19 148.26C152.19 147.107 152.383 146.207 152.77 145.56C153.163 144.907 153.837 144.58 154.79 144.58C155.743 144.58 156.413 144.907 156.8 145.56C157.193 146.207 157.39 147.107 157.39 148.26C157.39 149.427 157.193 150.34 156.8 151C156.413 151.653 155.743 151.98 154.79 151.98C153.837 151.98 153.163 151.653 152.77 151C152.383 150.34 152.19 149.427 152.19 148.26ZM156.27 148.26C156.27 147.72 156.233 147.263 156.16 146.89C156.093 146.517 155.953 146.213 155.74 145.98C155.527 145.74 155.21 145.62 154.79 145.62C154.37 145.62 154.053 145.74 153.84 145.98C153.627 146.213 153.483 146.517 153.41 146.89C153.343 147.263 153.31 147.72 153.31 148.26C153.31 148.82 153.343 149.29 153.41 149.67C153.477 150.05 153.617 150.357 153.83 150.59C154.05 150.823 154.37 150.94 154.79 150.94C155.21 150.94 155.527 150.823 155.74 150.59C155.96 150.357 156.103 150.05 156.17 149.67C156.237 149.29 156.27 148.82 156.27 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M150.388 152.07C150.181 152.07 150.008 152 149.868 151.86C149.728 151.72 149.658 151.547 149.658 151.34C149.658 151.133 149.728 150.96 149.868 150.82C150.008 150.68 150.181 150.61 150.388 150.61C150.588 150.61 150.758 150.68 150.898 150.82C151.038 150.96 151.108 151.133 151.108 151.34C151.108 151.547 151.038 151.72 150.898 151.86C150.758 152 150.588 152.07 150.388 152.07Z"
                fill="#676D7E"
            />
            <path
                d="M143.372 148.26C143.372 147.107 143.565 146.207 143.952 145.56C144.345 144.907 145.018 144.58 145.972 144.58C146.925 144.58 147.595 144.907 147.982 145.56C148.375 146.207 148.572 147.107 148.572 148.26C148.572 149.427 148.375 150.34 147.982 151C147.595 151.653 146.925 151.98 145.972 151.98C145.018 151.98 144.345 151.653 143.952 151C143.565 150.34 143.372 149.427 143.372 148.26ZM147.452 148.26C147.452 147.72 147.415 147.263 147.342 146.89C147.275 146.517 147.135 146.213 146.922 145.98C146.708 145.74 146.392 145.62 145.972 145.62C145.552 145.62 145.235 145.74 145.022 145.98C144.808 146.213 144.665 146.517 144.592 146.89C144.525 147.263 144.492 147.72 144.492 148.26C144.492 148.82 144.525 149.29 144.592 149.67C144.658 150.05 144.798 150.357 145.012 150.59C145.232 150.823 145.552 150.94 145.972 150.94C146.392 150.94 146.708 150.823 146.922 150.59C147.142 150.357 147.285 150.05 147.352 149.67C147.418 149.29 147.452 148.82 147.452 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M138.23 150.45C138.87 149.897 139.373 149.443 139.74 149.09C140.113 148.73 140.423 148.357 140.67 147.97C140.917 147.583 141.04 147.197 141.04 146.81C141.04 146.41 140.943 146.097 140.75 145.87C140.563 145.643 140.267 145.53 139.86 145.53C139.467 145.53 139.16 145.657 138.94 145.91C138.727 146.157 138.613 146.49 138.6 146.91H137.5C137.52 146.15 137.747 145.57 138.18 145.17C138.62 144.763 139.177 144.56 139.85 144.56C140.577 144.56 141.143 144.76 141.55 145.16C141.963 145.56 142.17 146.093 142.17 146.76C142.17 147.24 142.047 147.703 141.8 148.15C141.56 148.59 141.27 148.987 140.93 149.34C140.597 149.687 140.17 150.09 139.65 150.55L139.2 150.95H142.37V151.9H137.51V151.07L138.23 150.45Z"
                fill="#676D7E"
            />
            <path
                d="M361.727 148.26C361.727 147.107 361.92 146.207 362.307 145.56C362.7 144.907 363.373 144.58 364.327 144.58C365.28 144.58 365.95 144.907 366.337 145.56C366.73 146.207 366.927 147.107 366.927 148.26C366.927 149.427 366.73 150.34 366.337 151C365.95 151.653 365.28 151.98 364.327 151.98C363.373 151.98 362.7 151.653 362.307 151C361.92 150.34 361.727 149.427 361.727 148.26ZM365.807 148.26C365.807 147.72 365.77 147.263 365.697 146.89C365.63 146.517 365.49 146.213 365.277 145.98C365.063 145.74 364.747 145.62 364.327 145.62C363.907 145.62 363.59 145.74 363.377 145.98C363.163 146.213 363.02 146.517 362.947 146.89C362.88 147.263 362.847 147.72 362.847 148.26C362.847 148.82 362.88 149.29 362.947 149.67C363.013 150.05 363.153 150.357 363.367 150.59C363.587 150.823 363.907 150.94 364.327 150.94C364.747 150.94 365.063 150.823 365.277 150.59C365.497 150.357 365.64 150.05 365.707 149.67C365.773 149.29 365.807 148.82 365.807 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 148.26C355.321 147.107 355.514 146.207 355.901 145.56C356.294 144.907 356.967 144.58 357.921 144.58C358.874 144.58 359.544 144.907 359.931 145.56C360.324 146.207 360.521 147.107 360.521 148.26C360.521 149.427 360.324 150.34 359.931 151C359.544 151.653 358.874 151.98 357.921 151.98C356.967 151.98 356.294 151.653 355.901 151C355.514 150.34 355.321 149.427 355.321 148.26ZM359.401 148.26C359.401 147.72 359.364 147.263 359.291 146.89C359.224 146.517 359.084 146.213 358.871 145.98C358.657 145.74 358.341 145.62 357.921 145.62C357.501 145.62 357.184 145.74 356.971 145.98C356.757 146.213 356.614 146.517 356.541 146.89C356.474 147.263 356.441 147.72 356.441 148.26C356.441 148.82 356.474 149.29 356.541 149.67C356.607 150.05 356.747 150.357 356.961 150.59C357.181 150.823 357.501 150.94 357.921 150.94C358.341 150.94 358.657 150.823 358.871 150.59C359.091 150.357 359.234 150.05 359.301 149.67C359.367 149.29 359.401 148.82 359.401 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 148.26C348.914 147.107 349.108 146.207 349.494 145.56C349.888 144.907 350.561 144.58 351.514 144.58C352.468 144.58 353.138 144.907 353.524 145.56C353.918 146.207 354.114 147.107 354.114 148.26C354.114 149.427 353.918 150.34 353.524 151C353.138 151.653 352.468 151.98 351.514 151.98C350.561 151.98 349.888 151.653 349.494 151C349.108 150.34 348.914 149.427 348.914 148.26ZM352.994 148.26C352.994 147.72 352.958 147.263 352.884 146.89C352.818 146.517 352.678 146.213 352.464 145.98C352.251 145.74 351.934 145.62 351.514 145.62C351.094 145.62 350.778 145.74 350.564 145.98C350.351 146.213 350.208 146.517 350.134 146.89C350.068 147.263 350.034 147.72 350.034 148.26C350.034 148.82 350.068 149.29 350.134 149.67C350.201 150.05 350.341 150.357 350.554 150.59C350.774 150.823 351.094 150.94 351.514 150.94C351.934 150.94 352.251 150.823 352.464 150.59C352.684 150.357 352.828 150.05 352.894 149.67C352.961 149.29 352.994 148.82 352.994 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 152.07C346.906 152.07 346.732 152 346.592 151.86C346.452 151.72 346.382 151.547 346.382 151.34C346.382 151.133 346.452 150.96 346.592 150.82C346.732 150.68 346.906 150.61 347.112 150.61C347.312 150.61 347.482 150.68 347.622 150.82C347.762 150.96 347.832 151.133 347.832 151.34C347.832 151.547 347.762 151.72 347.622 151.86C347.482 152 347.312 152.07 347.112 152.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 148.26C340.096 147.107 340.289 146.207 340.676 145.56C341.069 144.907 341.743 144.58 342.696 144.58C343.649 144.58 344.319 144.907 344.706 145.56C345.099 146.207 345.296 147.107 345.296 148.26C345.296 149.427 345.099 150.34 344.706 151C344.319 151.653 343.649 151.98 342.696 151.98C341.743 151.98 341.069 151.653 340.676 151C340.289 150.34 340.096 149.427 340.096 148.26ZM344.176 148.26C344.176 147.72 344.139 147.263 344.066 146.89C343.999 146.517 343.859 146.213 343.646 145.98C343.433 145.74 343.116 145.62 342.696 145.62C342.276 145.62 341.959 145.74 341.746 145.98C341.533 146.213 341.389 146.517 341.316 146.89C341.249 147.263 341.216 147.72 341.216 148.26C341.216 148.82 341.249 149.29 341.316 149.67C341.383 150.05 341.523 150.357 341.736 150.59C341.956 150.823 342.276 150.94 342.696 150.94C343.116 150.94 343.433 150.823 343.646 150.59C343.866 150.357 344.009 150.05 344.076 149.67C344.143 149.29 344.176 148.82 344.176 148.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 145.75V144.71H338.53V152H337.38V145.75H336.45Z" fill="#676D7E" />
            <path
                d="M266.168 148.26C266.168 147.107 266.362 146.207 266.748 145.56C267.142 144.907 267.815 144.58 268.768 144.58C269.722 144.58 270.392 144.907 270.778 145.56C271.172 146.207 271.368 147.107 271.368 148.26C271.368 149.427 271.172 150.34 270.778 151C270.392 151.653 269.722 151.98 268.768 151.98C267.815 151.98 267.142 151.653 266.748 151C266.362 150.34 266.168 149.427 266.168 148.26ZM270.248 148.26C270.248 147.72 270.212 147.263 270.138 146.89C270.072 146.517 269.932 146.213 269.718 145.98C269.505 145.74 269.188 145.62 268.768 145.62C268.348 145.62 268.032 145.74 267.818 145.98C267.605 146.213 267.462 146.517 267.388 146.89C267.322 147.263 267.288 147.72 267.288 148.26C267.288 148.82 267.322 149.29 267.388 149.67C267.455 150.05 267.595 150.357 267.808 150.59C268.028 150.823 268.348 150.94 268.768 150.94C269.188 150.94 269.505 150.823 269.718 150.59C269.938 150.357 270.082 150.05 270.148 149.67C270.215 149.29 270.248 148.82 270.248 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M259.762 148.26C259.762 147.107 259.956 146.207 260.342 145.56C260.736 144.907 261.409 144.58 262.362 144.58C263.316 144.58 263.986 144.907 264.372 145.56C264.766 146.207 264.962 147.107 264.962 148.26C264.962 149.427 264.766 150.34 264.372 151C263.986 151.653 263.316 151.98 262.362 151.98C261.409 151.98 260.736 151.653 260.342 151C259.956 150.34 259.762 149.427 259.762 148.26ZM263.842 148.26C263.842 147.72 263.806 147.263 263.732 146.89C263.666 146.517 263.526 146.213 263.312 145.98C263.099 145.74 262.782 145.62 262.362 145.62C261.942 145.62 261.626 145.74 261.412 145.98C261.199 146.213 261.056 146.517 260.982 146.89C260.916 147.263 260.882 147.72 260.882 148.26C260.882 148.82 260.916 149.29 260.982 149.67C261.049 150.05 261.189 150.357 261.402 150.59C261.622 150.823 261.942 150.94 262.362 150.94C262.782 150.94 263.099 150.823 263.312 150.59C263.532 150.357 263.676 150.05 263.742 149.67C263.809 149.29 263.842 148.82 263.842 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M253.356 148.26C253.356 147.107 253.549 146.207 253.936 145.56C254.329 144.907 255.003 144.58 255.956 144.58C256.909 144.58 257.579 144.907 257.966 145.56C258.359 146.207 258.556 147.107 258.556 148.26C258.556 149.427 258.359 150.34 257.966 151C257.579 151.653 256.909 151.98 255.956 151.98C255.003 151.98 254.329 151.653 253.936 151C253.549 150.34 253.356 149.427 253.356 148.26ZM257.436 148.26C257.436 147.72 257.399 147.263 257.326 146.89C257.259 146.517 257.119 146.213 256.906 145.98C256.693 145.74 256.376 145.62 255.956 145.62C255.536 145.62 255.219 145.74 255.006 145.98C254.793 146.213 254.649 146.517 254.576 146.89C254.509 147.263 254.476 147.72 254.476 148.26C254.476 148.82 254.509 149.29 254.576 149.67C254.643 150.05 254.783 150.357 254.996 150.59C255.216 150.823 255.536 150.94 255.956 150.94C256.376 150.94 256.693 150.823 256.906 150.59C257.126 150.357 257.269 150.05 257.336 149.67C257.403 149.29 257.436 148.82 257.436 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M251.554 152.07C251.347 152.07 251.174 152 251.034 151.86C250.894 151.72 250.824 151.547 250.824 151.34C250.824 151.133 250.894 150.96 251.034 150.82C251.174 150.68 251.347 150.61 251.554 150.61C251.754 150.61 251.924 150.68 252.064 150.82C252.204 150.96 252.274 151.133 252.274 151.34C252.274 151.547 252.204 151.72 252.064 151.86C251.924 152 251.754 152.07 251.554 152.07Z"
                fill="#676D7E"
            />
            <path
                d="M244.538 148.26C244.538 147.107 244.731 146.207 245.118 145.56C245.511 144.907 246.184 144.58 247.138 144.58C248.091 144.58 248.761 144.907 249.148 145.56C249.541 146.207 249.738 147.107 249.738 148.26C249.738 149.427 249.541 150.34 249.148 151C248.761 151.653 248.091 151.98 247.138 151.98C246.184 151.98 245.511 151.653 245.118 151C244.731 150.34 244.538 149.427 244.538 148.26ZM248.618 148.26C248.618 147.72 248.581 147.263 248.508 146.89C248.441 146.517 248.301 146.213 248.088 145.98C247.874 145.74 247.558 145.62 247.138 145.62C246.718 145.62 246.401 145.74 246.188 145.98C245.974 146.213 245.831 146.517 245.758 146.89C245.691 147.263 245.658 147.72 245.658 148.26C245.658 148.82 245.691 149.29 245.758 149.67C245.824 150.05 245.964 150.357 246.178 150.59C246.398 150.823 246.718 150.94 247.138 150.94C247.558 150.94 247.874 150.823 248.088 150.59C248.308 150.357 248.451 150.05 248.518 149.67C248.584 149.29 248.618 148.82 248.618 148.26Z"
                fill="#676D7E"
            />
            <path
                d="M238.58 146.51C238.62 145.89 238.857 145.407 239.29 145.06C239.73 144.713 240.29 144.54 240.97 144.54C241.437 144.54 241.84 144.623 242.18 144.79C242.52 144.957 242.777 145.183 242.95 145.47C243.123 145.757 243.21 146.08 243.21 146.44C243.21 146.853 243.1 147.207 242.88 147.5C242.66 147.793 242.397 147.99 242.09 148.09V148.13C242.483 148.25 242.79 148.47 243.01 148.79C243.23 149.103 243.34 149.507 243.34 150C243.34 150.393 243.25 150.743 243.07 151.05C242.89 151.357 242.623 151.6 242.27 151.78C241.917 151.953 241.493 152.04 241 152.04C240.28 152.04 239.687 151.857 239.22 151.49C238.76 151.117 238.513 150.583 238.48 149.89H239.58C239.607 150.243 239.743 150.533 239.99 150.76C240.237 150.98 240.57 151.09 240.99 151.09C241.397 151.09 241.71 150.98 241.93 150.76C242.15 150.533 242.26 150.243 242.26 149.89C242.26 149.423 242.11 149.093 241.81 148.9C241.517 148.7 241.063 148.6 240.45 148.6H240.19V147.66H240.46C241 147.653 241.41 147.563 241.69 147.39C241.977 147.217 242.12 146.943 242.12 146.57C242.12 146.25 242.017 145.997 241.81 145.81C241.603 145.617 241.31 145.52 240.93 145.52C240.557 145.52 240.267 145.617 240.06 145.81C239.853 145.997 239.73 146.23 239.69 146.51H238.58Z"
                fill="#676D7E"
            />
            <path
                d="M165.002 228.26C165.002 227.107 165.196 226.207 165.582 225.56C165.976 224.907 166.649 224.58 167.602 224.58C168.556 224.58 169.226 224.907 169.612 225.56C170.006 226.207 170.202 227.107 170.202 228.26C170.202 229.427 170.006 230.34 169.612 231C169.226 231.653 168.556 231.98 167.602 231.98C166.649 231.98 165.976 231.653 165.582 231C165.196 230.34 165.002 229.427 165.002 228.26ZM169.082 228.26C169.082 227.72 169.046 227.263 168.972 226.89C168.906 226.517 168.766 226.213 168.552 225.98C168.339 225.74 168.022 225.62 167.602 225.62C167.182 225.62 166.866 225.74 166.652 225.98C166.439 226.213 166.296 226.517 166.222 226.89C166.156 227.263 166.122 227.72 166.122 228.26C166.122 228.82 166.156 229.29 166.222 229.67C166.289 230.05 166.429 230.357 166.642 230.59C166.862 230.823 167.182 230.94 167.602 230.94C168.022 230.94 168.339 230.823 168.552 230.59C168.772 230.357 168.916 230.05 168.982 229.67C169.049 229.29 169.082 228.82 169.082 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M158.596 228.26C158.596 227.107 158.79 226.207 159.176 225.56C159.57 224.907 160.243 224.58 161.196 224.58C162.15 224.58 162.82 224.907 163.206 225.56C163.6 226.207 163.796 227.107 163.796 228.26C163.796 229.427 163.6 230.34 163.206 231C162.82 231.653 162.15 231.98 161.196 231.98C160.243 231.98 159.57 231.653 159.176 231C158.79 230.34 158.596 229.427 158.596 228.26ZM162.676 228.26C162.676 227.72 162.64 227.263 162.566 226.89C162.5 226.517 162.36 226.213 162.146 225.98C161.933 225.74 161.616 225.62 161.196 225.62C160.776 225.62 160.46 225.74 160.246 225.98C160.033 226.213 159.89 226.517 159.816 226.89C159.75 227.263 159.716 227.72 159.716 228.26C159.716 228.82 159.75 229.29 159.816 229.67C159.883 230.05 160.023 230.357 160.236 230.59C160.456 230.823 160.776 230.94 161.196 230.94C161.616 230.94 161.933 230.823 162.146 230.59C162.366 230.357 162.51 230.05 162.576 229.67C162.643 229.29 162.676 228.82 162.676 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M152.19 228.26C152.19 227.107 152.383 226.207 152.77 225.56C153.163 224.907 153.837 224.58 154.79 224.58C155.743 224.58 156.413 224.907 156.8 225.56C157.193 226.207 157.39 227.107 157.39 228.26C157.39 229.427 157.193 230.34 156.8 231C156.413 231.653 155.743 231.98 154.79 231.98C153.837 231.98 153.163 231.653 152.77 231C152.383 230.34 152.19 229.427 152.19 228.26ZM156.27 228.26C156.27 227.72 156.233 227.263 156.16 226.89C156.093 226.517 155.953 226.213 155.74 225.98C155.527 225.74 155.21 225.62 154.79 225.62C154.37 225.62 154.053 225.74 153.84 225.98C153.627 226.213 153.483 226.517 153.41 226.89C153.343 227.263 153.31 227.72 153.31 228.26C153.31 228.82 153.343 229.29 153.41 229.67C153.477 230.05 153.617 230.357 153.83 230.59C154.05 230.823 154.37 230.94 154.79 230.94C155.21 230.94 155.527 230.823 155.74 230.59C155.96 230.357 156.103 230.05 156.17 229.67C156.237 229.29 156.27 228.82 156.27 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M150.388 232.07C150.181 232.07 150.008 232 149.868 231.86C149.728 231.72 149.658 231.547 149.658 231.34C149.658 231.133 149.728 230.96 149.868 230.82C150.008 230.68 150.181 230.61 150.388 230.61C150.588 230.61 150.758 230.68 150.898 230.82C151.038 230.96 151.108 231.133 151.108 231.34C151.108 231.547 151.038 231.72 150.898 231.86C150.758 232 150.588 232.07 150.388 232.07Z"
                fill="#676D7E"
            />
            <path
                d="M143.372 228.26C143.372 227.107 143.565 226.207 143.952 225.56C144.345 224.907 145.018 224.58 145.972 224.58C146.925 224.58 147.595 224.907 147.982 225.56C148.375 226.207 148.572 227.107 148.572 228.26C148.572 229.427 148.375 230.34 147.982 231C147.595 231.653 146.925 231.98 145.972 231.98C145.018 231.98 144.345 231.653 143.952 231C143.565 230.34 143.372 229.427 143.372 228.26ZM147.452 228.26C147.452 227.72 147.415 227.263 147.342 226.89C147.275 226.517 147.135 226.213 146.922 225.98C146.708 225.74 146.392 225.62 145.972 225.62C145.552 225.62 145.235 225.74 145.022 225.98C144.808 226.213 144.665 226.517 144.592 226.89C144.525 227.263 144.492 227.72 144.492 228.26C144.492 228.82 144.525 229.29 144.592 229.67C144.658 230.05 144.798 230.357 145.012 230.59C145.232 230.823 145.552 230.94 145.972 230.94C146.392 230.94 146.708 230.823 146.922 230.59C147.142 230.357 147.285 230.05 147.352 229.67C147.418 229.29 147.452 228.82 147.452 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M138.23 230.45C138.87 229.897 139.373 229.443 139.74 229.09C140.113 228.73 140.423 228.357 140.67 227.97C140.917 227.583 141.04 227.197 141.04 226.81C141.04 226.41 140.943 226.097 140.75 225.87C140.563 225.643 140.267 225.53 139.86 225.53C139.467 225.53 139.16 225.657 138.94 225.91C138.727 226.157 138.613 226.49 138.6 226.91H137.5C137.52 226.15 137.747 225.57 138.18 225.17C138.62 224.763 139.177 224.56 139.85 224.56C140.577 224.56 141.143 224.76 141.55 225.16C141.963 225.56 142.17 226.093 142.17 226.76C142.17 227.24 142.047 227.703 141.8 228.15C141.56 228.59 141.27 228.987 140.93 229.34C140.597 229.687 140.17 230.09 139.65 230.55L139.2 230.95H142.37V231.9H137.51V231.07L138.23 230.45Z"
                fill="#676D7E"
            />
            <path
                d="M361.727 228.26C361.727 227.107 361.92 226.207 362.307 225.56C362.7 224.907 363.373 224.58 364.327 224.58C365.28 224.58 365.95 224.907 366.337 225.56C366.73 226.207 366.927 227.107 366.927 228.26C366.927 229.427 366.73 230.34 366.337 231C365.95 231.653 365.28 231.98 364.327 231.98C363.373 231.98 362.7 231.653 362.307 231C361.92 230.34 361.727 229.427 361.727 228.26ZM365.807 228.26C365.807 227.72 365.77 227.263 365.697 226.89C365.63 226.517 365.49 226.213 365.277 225.98C365.063 225.74 364.747 225.62 364.327 225.62C363.907 225.62 363.59 225.74 363.377 225.98C363.163 226.213 363.02 226.517 362.947 226.89C362.88 227.263 362.847 227.72 362.847 228.26C362.847 228.82 362.88 229.29 362.947 229.67C363.013 230.05 363.153 230.357 363.367 230.59C363.587 230.823 363.907 230.94 364.327 230.94C364.747 230.94 365.063 230.823 365.277 230.59C365.497 230.357 365.64 230.05 365.707 229.67C365.773 229.29 365.807 228.82 365.807 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 228.26C355.321 227.107 355.514 226.207 355.901 225.56C356.294 224.907 356.967 224.58 357.921 224.58C358.874 224.58 359.544 224.907 359.931 225.56C360.324 226.207 360.521 227.107 360.521 228.26C360.521 229.427 360.324 230.34 359.931 231C359.544 231.653 358.874 231.98 357.921 231.98C356.967 231.98 356.294 231.653 355.901 231C355.514 230.34 355.321 229.427 355.321 228.26ZM359.401 228.26C359.401 227.72 359.364 227.263 359.291 226.89C359.224 226.517 359.084 226.213 358.871 225.98C358.657 225.74 358.341 225.62 357.921 225.62C357.501 225.62 357.184 225.74 356.971 225.98C356.757 226.213 356.614 226.517 356.541 226.89C356.474 227.263 356.441 227.72 356.441 228.26C356.441 228.82 356.474 229.29 356.541 229.67C356.607 230.05 356.747 230.357 356.961 230.59C357.181 230.823 357.501 230.94 357.921 230.94C358.341 230.94 358.657 230.823 358.871 230.59C359.091 230.357 359.234 230.05 359.301 229.67C359.367 229.29 359.401 228.82 359.401 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 228.26C348.914 227.107 349.108 226.207 349.494 225.56C349.888 224.907 350.561 224.58 351.514 224.58C352.468 224.58 353.138 224.907 353.524 225.56C353.918 226.207 354.114 227.107 354.114 228.26C354.114 229.427 353.918 230.34 353.524 231C353.138 231.653 352.468 231.98 351.514 231.98C350.561 231.98 349.888 231.653 349.494 231C349.108 230.34 348.914 229.427 348.914 228.26ZM352.994 228.26C352.994 227.72 352.958 227.263 352.884 226.89C352.818 226.517 352.678 226.213 352.464 225.98C352.251 225.74 351.934 225.62 351.514 225.62C351.094 225.62 350.778 225.74 350.564 225.98C350.351 226.213 350.208 226.517 350.134 226.89C350.068 227.263 350.034 227.72 350.034 228.26C350.034 228.82 350.068 229.29 350.134 229.67C350.201 230.05 350.341 230.357 350.554 230.59C350.774 230.823 351.094 230.94 351.514 230.94C351.934 230.94 352.251 230.823 352.464 230.59C352.684 230.357 352.828 230.05 352.894 229.67C352.961 229.29 352.994 228.82 352.994 228.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 232.07C346.906 232.07 346.732 232 346.592 231.86C346.452 231.72 346.382 231.547 346.382 231.34C346.382 231.133 346.452 230.96 346.592 230.82C346.732 230.68 346.906 230.61 347.112 230.61C347.312 230.61 347.482 230.68 347.622 230.82C347.762 230.96 347.832 231.133 347.832 231.34C347.832 231.547 347.762 231.72 347.622 231.86C347.482 232 347.312 232.07 347.112 232.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 228.26C340.096 227.107 340.289 226.207 340.676 225.56C341.069 224.907 341.743 224.58 342.696 224.58C343.649 224.58 344.319 224.907 344.706 225.56C345.099 226.207 345.296 227.107 345.296 228.26C345.296 229.427 345.099 230.34 344.706 231C344.319 231.653 343.649 231.98 342.696 231.98C341.743 231.98 341.069 231.653 340.676 231C340.289 230.34 340.096 229.427 340.096 228.26ZM344.176 228.26C344.176 227.72 344.139 227.263 344.066 226.89C343.999 226.517 343.859 226.213 343.646 225.98C343.433 225.74 343.116 225.62 342.696 225.62C342.276 225.62 341.959 225.74 341.746 225.98C341.533 226.213 341.389 226.517 341.316 226.89C341.249 227.263 341.216 227.72 341.216 228.26C341.216 228.82 341.249 229.29 341.316 229.67C341.383 230.05 341.523 230.357 341.736 230.59C341.956 230.823 342.276 230.94 342.696 230.94C343.116 230.94 343.433 230.823 343.646 230.59C343.866 230.357 344.009 230.05 344.076 229.67C344.143 229.29 344.176 228.82 344.176 228.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 225.75V224.71H338.53V232H337.38V225.75H336.45Z" fill="#676D7E" />
            <path
                d="M361.727 168.26C361.727 167.107 361.92 166.207 362.307 165.56C362.7 164.907 363.373 164.58 364.327 164.58C365.28 164.58 365.95 164.907 366.337 165.56C366.73 166.207 366.927 167.107 366.927 168.26C366.927 169.427 366.73 170.34 366.337 171C365.95 171.653 365.28 171.98 364.327 171.98C363.373 171.98 362.7 171.653 362.307 171C361.92 170.34 361.727 169.427 361.727 168.26ZM365.807 168.26C365.807 167.72 365.77 167.263 365.697 166.89C365.63 166.517 365.49 166.213 365.277 165.98C365.063 165.74 364.747 165.62 364.327 165.62C363.907 165.62 363.59 165.74 363.377 165.98C363.163 166.213 363.02 166.517 362.947 166.89C362.88 167.263 362.847 167.72 362.847 168.26C362.847 168.82 362.88 169.29 362.947 169.67C363.013 170.05 363.153 170.357 363.367 170.59C363.587 170.823 363.907 170.94 364.327 170.94C364.747 170.94 365.063 170.823 365.277 170.59C365.497 170.357 365.64 170.05 365.707 169.67C365.773 169.29 365.807 168.82 365.807 168.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 168.26C355.321 167.107 355.514 166.207 355.901 165.56C356.294 164.907 356.967 164.58 357.921 164.58C358.874 164.58 359.544 164.907 359.931 165.56C360.324 166.207 360.521 167.107 360.521 168.26C360.521 169.427 360.324 170.34 359.931 171C359.544 171.653 358.874 171.98 357.921 171.98C356.967 171.98 356.294 171.653 355.901 171C355.514 170.34 355.321 169.427 355.321 168.26ZM359.401 168.26C359.401 167.72 359.364 167.263 359.291 166.89C359.224 166.517 359.084 166.213 358.871 165.98C358.657 165.74 358.341 165.62 357.921 165.62C357.501 165.62 357.184 165.74 356.971 165.98C356.757 166.213 356.614 166.517 356.541 166.89C356.474 167.263 356.441 167.72 356.441 168.26C356.441 168.82 356.474 169.29 356.541 169.67C356.607 170.05 356.747 170.357 356.961 170.59C357.181 170.823 357.501 170.94 357.921 170.94C358.341 170.94 358.657 170.823 358.871 170.59C359.091 170.357 359.234 170.05 359.301 169.67C359.367 169.29 359.401 168.82 359.401 168.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 168.26C348.914 167.107 349.108 166.207 349.494 165.56C349.888 164.907 350.561 164.58 351.514 164.58C352.468 164.58 353.138 164.907 353.524 165.56C353.918 166.207 354.114 167.107 354.114 168.26C354.114 169.427 353.918 170.34 353.524 171C353.138 171.653 352.468 171.98 351.514 171.98C350.561 171.98 349.888 171.653 349.494 171C349.108 170.34 348.914 169.427 348.914 168.26ZM352.994 168.26C352.994 167.72 352.958 167.263 352.884 166.89C352.818 166.517 352.678 166.213 352.464 165.98C352.251 165.74 351.934 165.62 351.514 165.62C351.094 165.62 350.778 165.74 350.564 165.98C350.351 166.213 350.208 166.517 350.134 166.89C350.068 167.263 350.034 167.72 350.034 168.26C350.034 168.82 350.068 169.29 350.134 169.67C350.201 170.05 350.341 170.357 350.554 170.59C350.774 170.823 351.094 170.94 351.514 170.94C351.934 170.94 352.251 170.823 352.464 170.59C352.684 170.357 352.828 170.05 352.894 169.67C352.961 169.29 352.994 168.82 352.994 168.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 172.07C346.906 172.07 346.732 172 346.592 171.86C346.452 171.72 346.382 171.547 346.382 171.34C346.382 171.133 346.452 170.96 346.592 170.82C346.732 170.68 346.906 170.61 347.112 170.61C347.312 170.61 347.482 170.68 347.622 170.82C347.762 170.96 347.832 171.133 347.832 171.34C347.832 171.547 347.762 171.72 347.622 171.86C347.482 172 347.312 172.07 347.112 172.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 168.26C340.096 167.107 340.289 166.207 340.676 165.56C341.069 164.907 341.743 164.58 342.696 164.58C343.649 164.58 344.319 164.907 344.706 165.56C345.099 166.207 345.296 167.107 345.296 168.26C345.296 169.427 345.099 170.34 344.706 171C344.319 171.653 343.649 171.98 342.696 171.98C341.743 171.98 341.069 171.653 340.676 171C340.289 170.34 340.096 169.427 340.096 168.26ZM344.176 168.26C344.176 167.72 344.139 167.263 344.066 166.89C343.999 166.517 343.859 166.213 343.646 165.98C343.433 165.74 343.116 165.62 342.696 165.62C342.276 165.62 341.959 165.74 341.746 165.98C341.533 166.213 341.389 166.517 341.316 166.89C341.249 167.263 341.216 167.72 341.216 168.26C341.216 168.82 341.249 169.29 341.316 169.67C341.383 170.05 341.523 170.357 341.736 170.59C341.956 170.823 342.276 170.94 342.696 170.94C343.116 170.94 343.433 170.823 343.646 170.59C343.866 170.357 344.009 170.05 344.076 169.67C344.143 169.29 344.176 168.82 344.176 168.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 165.75V164.71H338.53V172H337.38V165.75H336.45Z" fill="#676D7E" />
            <path
                d="M361.727 248.26C361.727 247.107 361.92 246.207 362.307 245.56C362.7 244.907 363.373 244.58 364.327 244.58C365.28 244.58 365.95 244.907 366.337 245.56C366.73 246.207 366.927 247.107 366.927 248.26C366.927 249.427 366.73 250.34 366.337 251C365.95 251.653 365.28 251.98 364.327 251.98C363.373 251.98 362.7 251.653 362.307 251C361.92 250.34 361.727 249.427 361.727 248.26ZM365.807 248.26C365.807 247.72 365.77 247.263 365.697 246.89C365.63 246.517 365.49 246.213 365.277 245.98C365.063 245.74 364.747 245.62 364.327 245.62C363.907 245.62 363.59 245.74 363.377 245.98C363.163 246.213 363.02 246.517 362.947 246.89C362.88 247.263 362.847 247.72 362.847 248.26C362.847 248.82 362.88 249.29 362.947 249.67C363.013 250.05 363.153 250.357 363.367 250.59C363.587 250.823 363.907 250.94 364.327 250.94C364.747 250.94 365.063 250.823 365.277 250.59C365.497 250.357 365.64 250.05 365.707 249.67C365.773 249.29 365.807 248.82 365.807 248.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 248.26C355.321 247.107 355.514 246.207 355.901 245.56C356.294 244.907 356.967 244.58 357.921 244.58C358.874 244.58 359.544 244.907 359.931 245.56C360.324 246.207 360.521 247.107 360.521 248.26C360.521 249.427 360.324 250.34 359.931 251C359.544 251.653 358.874 251.98 357.921 251.98C356.967 251.98 356.294 251.653 355.901 251C355.514 250.34 355.321 249.427 355.321 248.26ZM359.401 248.26C359.401 247.72 359.364 247.263 359.291 246.89C359.224 246.517 359.084 246.213 358.871 245.98C358.657 245.74 358.341 245.62 357.921 245.62C357.501 245.62 357.184 245.74 356.971 245.98C356.757 246.213 356.614 246.517 356.541 246.89C356.474 247.263 356.441 247.72 356.441 248.26C356.441 248.82 356.474 249.29 356.541 249.67C356.607 250.05 356.747 250.357 356.961 250.59C357.181 250.823 357.501 250.94 357.921 250.94C358.341 250.94 358.657 250.823 358.871 250.59C359.091 250.357 359.234 250.05 359.301 249.67C359.367 249.29 359.401 248.82 359.401 248.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 248.26C348.914 247.107 349.108 246.207 349.494 245.56C349.888 244.907 350.561 244.58 351.514 244.58C352.468 244.58 353.138 244.907 353.524 245.56C353.918 246.207 354.114 247.107 354.114 248.26C354.114 249.427 353.918 250.34 353.524 251C353.138 251.653 352.468 251.98 351.514 251.98C350.561 251.98 349.888 251.653 349.494 251C349.108 250.34 348.914 249.427 348.914 248.26ZM352.994 248.26C352.994 247.72 352.958 247.263 352.884 246.89C352.818 246.517 352.678 246.213 352.464 245.98C352.251 245.74 351.934 245.62 351.514 245.62C351.094 245.62 350.778 245.74 350.564 245.98C350.351 246.213 350.208 246.517 350.134 246.89C350.068 247.263 350.034 247.72 350.034 248.26C350.034 248.82 350.068 249.29 350.134 249.67C350.201 250.05 350.341 250.357 350.554 250.59C350.774 250.823 351.094 250.94 351.514 250.94C351.934 250.94 352.251 250.823 352.464 250.59C352.684 250.357 352.828 250.05 352.894 249.67C352.961 249.29 352.994 248.82 352.994 248.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 252.07C346.906 252.07 346.732 252 346.592 251.86C346.452 251.72 346.382 251.547 346.382 251.34C346.382 251.133 346.452 250.96 346.592 250.82C346.732 250.68 346.906 250.61 347.112 250.61C347.312 250.61 347.482 250.68 347.622 250.82C347.762 250.96 347.832 251.133 347.832 251.34C347.832 251.547 347.762 251.72 347.622 251.86C347.482 252 347.312 252.07 347.112 252.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 248.26C340.096 247.107 340.289 246.207 340.676 245.56C341.069 244.907 341.743 244.58 342.696 244.58C343.649 244.58 344.319 244.907 344.706 245.56C345.099 246.207 345.296 247.107 345.296 248.26C345.296 249.427 345.099 250.34 344.706 251C344.319 251.653 343.649 251.98 342.696 251.98C341.743 251.98 341.069 251.653 340.676 251C340.289 250.34 340.096 249.427 340.096 248.26ZM344.176 248.26C344.176 247.72 344.139 247.263 344.066 246.89C343.999 246.517 343.859 246.213 343.646 245.98C343.433 245.74 343.116 245.62 342.696 245.62C342.276 245.62 341.959 245.74 341.746 245.98C341.533 246.213 341.389 246.517 341.316 246.89C341.249 247.263 341.216 247.72 341.216 248.26C341.216 248.82 341.249 249.29 341.316 249.67C341.383 250.05 341.523 250.357 341.736 250.59C341.956 250.823 342.276 250.94 342.696 250.94C343.116 250.94 343.433 250.823 343.646 250.59C343.866 250.357 344.009 250.05 344.076 249.67C344.143 249.29 344.176 248.82 344.176 248.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 245.75V244.71H338.53V252H337.38V245.75H336.45Z" fill="#676D7E" />
            <path
                d="M165.002 188.26C165.002 187.107 165.196 186.207 165.582 185.56C165.976 184.907 166.649 184.58 167.602 184.58C168.556 184.58 169.226 184.907 169.612 185.56C170.006 186.207 170.202 187.107 170.202 188.26C170.202 189.427 170.006 190.34 169.612 191C169.226 191.653 168.556 191.98 167.602 191.98C166.649 191.98 165.976 191.653 165.582 191C165.196 190.34 165.002 189.427 165.002 188.26ZM169.082 188.26C169.082 187.72 169.046 187.263 168.972 186.89C168.906 186.517 168.766 186.213 168.552 185.98C168.339 185.74 168.022 185.62 167.602 185.62C167.182 185.62 166.866 185.74 166.652 185.98C166.439 186.213 166.296 186.517 166.222 186.89C166.156 187.263 166.122 187.72 166.122 188.26C166.122 188.82 166.156 189.29 166.222 189.67C166.289 190.05 166.429 190.357 166.642 190.59C166.862 190.823 167.182 190.94 167.602 190.94C168.022 190.94 168.339 190.823 168.552 190.59C168.772 190.357 168.916 190.05 168.982 189.67C169.049 189.29 169.082 188.82 169.082 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M158.596 188.26C158.596 187.107 158.79 186.207 159.176 185.56C159.57 184.907 160.243 184.58 161.196 184.58C162.15 184.58 162.82 184.907 163.206 185.56C163.6 186.207 163.796 187.107 163.796 188.26C163.796 189.427 163.6 190.34 163.206 191C162.82 191.653 162.15 191.98 161.196 191.98C160.243 191.98 159.57 191.653 159.176 191C158.79 190.34 158.596 189.427 158.596 188.26ZM162.676 188.26C162.676 187.72 162.64 187.263 162.566 186.89C162.5 186.517 162.36 186.213 162.146 185.98C161.933 185.74 161.616 185.62 161.196 185.62C160.776 185.62 160.46 185.74 160.246 185.98C160.033 186.213 159.89 186.517 159.816 186.89C159.75 187.263 159.716 187.72 159.716 188.26C159.716 188.82 159.75 189.29 159.816 189.67C159.883 190.05 160.023 190.357 160.236 190.59C160.456 190.823 160.776 190.94 161.196 190.94C161.616 190.94 161.933 190.823 162.146 190.59C162.366 190.357 162.51 190.05 162.576 189.67C162.643 189.29 162.676 188.82 162.676 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M152.19 188.26C152.19 187.107 152.383 186.207 152.77 185.56C153.163 184.907 153.837 184.58 154.79 184.58C155.743 184.58 156.413 184.907 156.8 185.56C157.193 186.207 157.39 187.107 157.39 188.26C157.39 189.427 157.193 190.34 156.8 191C156.413 191.653 155.743 191.98 154.79 191.98C153.837 191.98 153.163 191.653 152.77 191C152.383 190.34 152.19 189.427 152.19 188.26ZM156.27 188.26C156.27 187.72 156.233 187.263 156.16 186.89C156.093 186.517 155.953 186.213 155.74 185.98C155.527 185.74 155.21 185.62 154.79 185.62C154.37 185.62 154.053 185.74 153.84 185.98C153.627 186.213 153.483 186.517 153.41 186.89C153.343 187.263 153.31 187.72 153.31 188.26C153.31 188.82 153.343 189.29 153.41 189.67C153.477 190.05 153.617 190.357 153.83 190.59C154.05 190.823 154.37 190.94 154.79 190.94C155.21 190.94 155.527 190.823 155.74 190.59C155.96 190.357 156.103 190.05 156.17 189.67C156.237 189.29 156.27 188.82 156.27 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M150.388 192.07C150.181 192.07 150.008 192 149.868 191.86C149.728 191.72 149.658 191.547 149.658 191.34C149.658 191.133 149.728 190.96 149.868 190.82C150.008 190.68 150.181 190.61 150.388 190.61C150.588 190.61 150.758 190.68 150.898 190.82C151.038 190.96 151.108 191.133 151.108 191.34C151.108 191.547 151.038 191.72 150.898 191.86C150.758 192 150.588 192.07 150.388 192.07Z"
                fill="#676D7E"
            />
            <path
                d="M143.372 188.26C143.372 187.107 143.565 186.207 143.952 185.56C144.345 184.907 145.018 184.58 145.972 184.58C146.925 184.58 147.595 184.907 147.982 185.56C148.375 186.207 148.572 187.107 148.572 188.26C148.572 189.427 148.375 190.34 147.982 191C147.595 191.653 146.925 191.98 145.972 191.98C145.018 191.98 144.345 191.653 143.952 191C143.565 190.34 143.372 189.427 143.372 188.26ZM147.452 188.26C147.452 187.72 147.415 187.263 147.342 186.89C147.275 186.517 147.135 186.213 146.922 185.98C146.708 185.74 146.392 185.62 145.972 185.62C145.552 185.62 145.235 185.74 145.022 185.98C144.808 186.213 144.665 186.517 144.592 186.89C144.525 187.263 144.492 187.72 144.492 188.26C144.492 188.82 144.525 189.29 144.592 189.67C144.658 190.05 144.798 190.357 145.012 190.59C145.232 190.823 145.552 190.94 145.972 190.94C146.392 190.94 146.708 190.823 146.922 190.59C147.142 190.357 147.285 190.05 147.352 189.67C147.418 189.29 147.452 188.82 147.452 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M138.23 190.45C138.87 189.897 139.373 189.443 139.74 189.09C140.113 188.73 140.423 188.357 140.67 187.97C140.917 187.583 141.04 187.197 141.04 186.81C141.04 186.41 140.943 186.097 140.75 185.87C140.563 185.643 140.267 185.53 139.86 185.53C139.467 185.53 139.16 185.657 138.94 185.91C138.727 186.157 138.613 186.49 138.6 186.91H137.5C137.52 186.15 137.747 185.57 138.18 185.17C138.62 184.763 139.177 184.56 139.85 184.56C140.577 184.56 141.143 184.76 141.55 185.16C141.963 185.56 142.17 186.093 142.17 186.76C142.17 187.24 142.047 187.703 141.8 188.15C141.56 188.59 141.27 188.987 140.93 189.34C140.597 189.687 140.17 190.09 139.65 190.55L139.2 190.95H142.37V191.9H137.51V191.07L138.23 190.45Z"
                fill="#676D7E"
            />
            <path
                d="M361.727 188.26C361.727 187.107 361.92 186.207 362.307 185.56C362.7 184.907 363.373 184.58 364.327 184.58C365.28 184.58 365.95 184.907 366.337 185.56C366.73 186.207 366.927 187.107 366.927 188.26C366.927 189.427 366.73 190.34 366.337 191C365.95 191.653 365.28 191.98 364.327 191.98C363.373 191.98 362.7 191.653 362.307 191C361.92 190.34 361.727 189.427 361.727 188.26ZM365.807 188.26C365.807 187.72 365.77 187.263 365.697 186.89C365.63 186.517 365.49 186.213 365.277 185.98C365.063 185.74 364.747 185.62 364.327 185.62C363.907 185.62 363.59 185.74 363.377 185.98C363.163 186.213 363.02 186.517 362.947 186.89C362.88 187.263 362.847 187.72 362.847 188.26C362.847 188.82 362.88 189.29 362.947 189.67C363.013 190.05 363.153 190.357 363.367 190.59C363.587 190.823 363.907 190.94 364.327 190.94C364.747 190.94 365.063 190.823 365.277 190.59C365.497 190.357 365.64 190.05 365.707 189.67C365.773 189.29 365.807 188.82 365.807 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 188.26C355.321 187.107 355.514 186.207 355.901 185.56C356.294 184.907 356.967 184.58 357.921 184.58C358.874 184.58 359.544 184.907 359.931 185.56C360.324 186.207 360.521 187.107 360.521 188.26C360.521 189.427 360.324 190.34 359.931 191C359.544 191.653 358.874 191.98 357.921 191.98C356.967 191.98 356.294 191.653 355.901 191C355.514 190.34 355.321 189.427 355.321 188.26ZM359.401 188.26C359.401 187.72 359.364 187.263 359.291 186.89C359.224 186.517 359.084 186.213 358.871 185.98C358.657 185.74 358.341 185.62 357.921 185.62C357.501 185.62 357.184 185.74 356.971 185.98C356.757 186.213 356.614 186.517 356.541 186.89C356.474 187.263 356.441 187.72 356.441 188.26C356.441 188.82 356.474 189.29 356.541 189.67C356.607 190.05 356.747 190.357 356.961 190.59C357.181 190.823 357.501 190.94 357.921 190.94C358.341 190.94 358.657 190.823 358.871 190.59C359.091 190.357 359.234 190.05 359.301 189.67C359.367 189.29 359.401 188.82 359.401 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 188.26C348.914 187.107 349.108 186.207 349.494 185.56C349.888 184.907 350.561 184.58 351.514 184.58C352.468 184.58 353.138 184.907 353.524 185.56C353.918 186.207 354.114 187.107 354.114 188.26C354.114 189.427 353.918 190.34 353.524 191C353.138 191.653 352.468 191.98 351.514 191.98C350.561 191.98 349.888 191.653 349.494 191C349.108 190.34 348.914 189.427 348.914 188.26ZM352.994 188.26C352.994 187.72 352.958 187.263 352.884 186.89C352.818 186.517 352.678 186.213 352.464 185.98C352.251 185.74 351.934 185.62 351.514 185.62C351.094 185.62 350.778 185.74 350.564 185.98C350.351 186.213 350.208 186.517 350.134 186.89C350.068 187.263 350.034 187.72 350.034 188.26C350.034 188.82 350.068 189.29 350.134 189.67C350.201 190.05 350.341 190.357 350.554 190.59C350.774 190.823 351.094 190.94 351.514 190.94C351.934 190.94 352.251 190.823 352.464 190.59C352.684 190.357 352.828 190.05 352.894 189.67C352.961 189.29 352.994 188.82 352.994 188.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 192.07C346.906 192.07 346.732 192 346.592 191.86C346.452 191.72 346.382 191.547 346.382 191.34C346.382 191.133 346.452 190.96 346.592 190.82C346.732 190.68 346.906 190.61 347.112 190.61C347.312 190.61 347.482 190.68 347.622 190.82C347.762 190.96 347.832 191.133 347.832 191.34C347.832 191.547 347.762 191.72 347.622 191.86C347.482 192 347.312 192.07 347.112 192.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 188.26C340.096 187.107 340.289 186.207 340.676 185.56C341.069 184.907 341.743 184.58 342.696 184.58C343.649 184.58 344.319 184.907 344.706 185.56C345.099 186.207 345.296 187.107 345.296 188.26C345.296 189.427 345.099 190.34 344.706 191C344.319 191.653 343.649 191.98 342.696 191.98C341.743 191.98 341.069 191.653 340.676 191C340.289 190.34 340.096 189.427 340.096 188.26ZM344.176 188.26C344.176 187.72 344.139 187.263 344.066 186.89C343.999 186.517 343.859 186.213 343.646 185.98C343.433 185.74 343.116 185.62 342.696 185.62C342.276 185.62 341.959 185.74 341.746 185.98C341.533 186.213 341.389 186.517 341.316 186.89C341.249 187.263 341.216 187.72 341.216 188.26C341.216 188.82 341.249 189.29 341.316 189.67C341.383 190.05 341.523 190.357 341.736 190.59C341.956 190.823 342.276 190.94 342.696 190.94C343.116 190.94 343.433 190.823 343.646 190.59C343.866 190.357 344.009 190.05 344.076 189.67C344.143 189.29 344.176 188.82 344.176 188.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 185.75V184.71H338.53V192H337.38V185.75H336.45Z" fill="#676D7E" />
            <path
                d="M361.727 208.26C361.727 207.107 361.92 206.207 362.307 205.56C362.7 204.907 363.373 204.58 364.327 204.58C365.28 204.58 365.95 204.907 366.337 205.56C366.73 206.207 366.927 207.107 366.927 208.26C366.927 209.427 366.73 210.34 366.337 211C365.95 211.653 365.28 211.98 364.327 211.98C363.373 211.98 362.7 211.653 362.307 211C361.92 210.34 361.727 209.427 361.727 208.26ZM365.807 208.26C365.807 207.72 365.77 207.263 365.697 206.89C365.63 206.517 365.49 206.213 365.277 205.98C365.063 205.74 364.747 205.62 364.327 205.62C363.907 205.62 363.59 205.74 363.377 205.98C363.163 206.213 363.02 206.517 362.947 206.89C362.88 207.263 362.847 207.72 362.847 208.26C362.847 208.82 362.88 209.29 362.947 209.67C363.013 210.05 363.153 210.357 363.367 210.59C363.587 210.823 363.907 210.94 364.327 210.94C364.747 210.94 365.063 210.823 365.277 210.59C365.497 210.357 365.64 210.05 365.707 209.67C365.773 209.29 365.807 208.82 365.807 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M355.321 208.26C355.321 207.107 355.514 206.207 355.901 205.56C356.294 204.907 356.967 204.58 357.921 204.58C358.874 204.58 359.544 204.907 359.931 205.56C360.324 206.207 360.521 207.107 360.521 208.26C360.521 209.427 360.324 210.34 359.931 211C359.544 211.653 358.874 211.98 357.921 211.98C356.967 211.98 356.294 211.653 355.901 211C355.514 210.34 355.321 209.427 355.321 208.26ZM359.401 208.26C359.401 207.72 359.364 207.263 359.291 206.89C359.224 206.517 359.084 206.213 358.871 205.98C358.657 205.74 358.341 205.62 357.921 205.62C357.501 205.62 357.184 205.74 356.971 205.98C356.757 206.213 356.614 206.517 356.541 206.89C356.474 207.263 356.441 207.72 356.441 208.26C356.441 208.82 356.474 209.29 356.541 209.67C356.607 210.05 356.747 210.357 356.961 210.59C357.181 210.823 357.501 210.94 357.921 210.94C358.341 210.94 358.657 210.823 358.871 210.59C359.091 210.357 359.234 210.05 359.301 209.67C359.367 209.29 359.401 208.82 359.401 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M348.914 208.26C348.914 207.107 349.108 206.207 349.494 205.56C349.888 204.907 350.561 204.58 351.514 204.58C352.468 204.58 353.138 204.907 353.524 205.56C353.918 206.207 354.114 207.107 354.114 208.26C354.114 209.427 353.918 210.34 353.524 211C353.138 211.653 352.468 211.98 351.514 211.98C350.561 211.98 349.888 211.653 349.494 211C349.108 210.34 348.914 209.427 348.914 208.26ZM352.994 208.26C352.994 207.72 352.958 207.263 352.884 206.89C352.818 206.517 352.678 206.213 352.464 205.98C352.251 205.74 351.934 205.62 351.514 205.62C351.094 205.62 350.778 205.74 350.564 205.98C350.351 206.213 350.208 206.517 350.134 206.89C350.068 207.263 350.034 207.72 350.034 208.26C350.034 208.82 350.068 209.29 350.134 209.67C350.201 210.05 350.341 210.357 350.554 210.59C350.774 210.823 351.094 210.94 351.514 210.94C351.934 210.94 352.251 210.823 352.464 210.59C352.684 210.357 352.828 210.05 352.894 209.67C352.961 209.29 352.994 208.82 352.994 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M347.112 212.07C346.906 212.07 346.732 212 346.592 211.86C346.452 211.72 346.382 211.547 346.382 211.34C346.382 211.133 346.452 210.96 346.592 210.82C346.732 210.68 346.906 210.61 347.112 210.61C347.312 210.61 347.482 210.68 347.622 210.82C347.762 210.96 347.832 211.133 347.832 211.34C347.832 211.547 347.762 211.72 347.622 211.86C347.482 212 347.312 212.07 347.112 212.07Z"
                fill="#676D7E"
            />
            <path
                d="M340.096 208.26C340.096 207.107 340.289 206.207 340.676 205.56C341.069 204.907 341.743 204.58 342.696 204.58C343.649 204.58 344.319 204.907 344.706 205.56C345.099 206.207 345.296 207.107 345.296 208.26C345.296 209.427 345.099 210.34 344.706 211C344.319 211.653 343.649 211.98 342.696 211.98C341.743 211.98 341.069 211.653 340.676 211C340.289 210.34 340.096 209.427 340.096 208.26ZM344.176 208.26C344.176 207.72 344.139 207.263 344.066 206.89C343.999 206.517 343.859 206.213 343.646 205.98C343.433 205.74 343.116 205.62 342.696 205.62C342.276 205.62 341.959 205.74 341.746 205.98C341.533 206.213 341.389 206.517 341.316 206.89C341.249 207.263 341.216 207.72 341.216 208.26C341.216 208.82 341.249 209.29 341.316 209.67C341.383 210.05 341.523 210.357 341.736 210.59C341.956 210.823 342.276 210.94 342.696 210.94C343.116 210.94 343.433 210.823 343.646 210.59C343.866 210.357 344.009 210.05 344.076 209.67C344.143 209.29 344.176 208.82 344.176 208.26Z"
                fill="#676D7E"
            />
            <path d="M336.45 205.75V204.71H338.53V212H337.38V205.75H336.45Z" fill="#676D7E" />
            <path
                d="M301.035 76.5901C300.581 76.5901 300.174 76.5101 299.814 76.3501C299.454 76.1835 299.168 75.9601 298.955 75.6801C298.748 75.4001 298.635 75.0901 298.615 74.7501H300.025C300.051 74.9635 300.155 75.1401 300.335 75.2801C300.521 75.4201 300.751 75.4901 301.025 75.4901C301.291 75.4901 301.498 75.4368 301.645 75.3301C301.798 75.2235 301.875 75.0868 301.875 74.9201C301.875 74.7401 301.781 74.6068 301.595 74.5201C301.415 74.4268 301.125 74.3268 300.725 74.2201C300.311 74.1201 299.971 74.0168 299.705 73.9101C299.445 73.8035 299.218 73.6401 299.025 73.4201C298.838 73.2001 298.744 72.9035 298.744 72.5301C298.744 72.2235 298.831 71.9435 299.005 71.6901C299.185 71.4368 299.438 71.2368 299.765 71.0901C300.098 70.9435 300.488 70.8701 300.935 70.8701C301.595 70.8701 302.121 71.0368 302.515 71.3701C302.908 71.6968 303.125 72.1401 303.165 72.7001H301.825C301.805 72.4801 301.711 72.3068 301.545 72.1801C301.385 72.0468 301.168 71.9801 300.895 71.9801C300.641 71.9801 300.445 72.0268 300.305 72.1201C300.171 72.2135 300.104 72.3435 300.104 72.5101C300.104 72.6968 300.198 72.8401 300.385 72.9401C300.571 73.0335 300.861 73.1301 301.255 73.2301C301.655 73.3301 301.984 73.4335 302.244 73.5401C302.504 73.6468 302.728 73.8135 302.915 74.0401C303.108 74.2601 303.208 74.5535 303.215 74.9201C303.215 75.2401 303.125 75.5268 302.945 75.7801C302.771 76.0335 302.518 76.2335 302.185 76.3801C301.858 76.5201 301.474 76.5901 301.035 76.5901Z"
                fill="#2A3147"
            />
            <path
                d="M297.893 73.6101C297.893 73.8101 297.879 73.9901 297.853 74.1501H293.803C293.836 74.5501 293.976 74.8635 294.223 75.0901C294.469 75.3168 294.773 75.4301 295.133 75.4301C295.653 75.4301 296.023 75.2068 296.243 74.7601H297.753C297.593 75.2935 297.286 75.7335 296.833 76.0801C296.379 76.4201 295.823 76.5901 295.163 76.5901C294.629 76.5901 294.149 76.4735 293.723 76.2401C293.303 76.0001 292.973 75.6635 292.733 75.2301C292.499 74.7968 292.383 74.2968 292.383 73.7301C292.383 73.1568 292.499 72.6535 292.733 72.2201C292.966 71.7868 293.293 71.4535 293.713 71.2201C294.133 70.9868 294.616 70.8701 295.163 70.8701C295.689 70.8701 296.159 70.9835 296.573 71.2101C296.993 71.4368 297.316 71.7601 297.543 72.1801C297.776 72.5935 297.893 73.0701 297.893 73.6101ZM296.443 73.2101C296.436 72.8501 296.306 72.5635 296.053 72.3501C295.799 72.1301 295.489 72.0201 295.123 72.0201C294.776 72.0201 294.483 72.1268 294.243 72.3401C294.009 72.5468 293.866 72.8368 293.813 73.2101H296.443Z"
                fill="#2A3147"
            />
            <path
                d="M289.189 75.35H291.659V76.5H287.599V75.37L290.019 72.11H287.609V70.96H291.629V72.09L289.189 75.35Z"
                fill="#2A3147"
            />
            <path
                d="M285.836 70.3002C285.589 70.3002 285.382 70.2235 285.216 70.0702C285.056 69.9102 284.976 69.7135 284.976 69.4802C284.976 69.2468 285.056 69.0535 285.216 68.9002C285.382 68.7402 285.589 68.6602 285.836 68.6602C286.082 68.6602 286.286 68.7402 286.446 68.9002C286.612 69.0535 286.696 69.2468 286.696 69.4802C286.696 69.7135 286.612 69.9102 286.446 70.0702C286.286 70.2235 286.082 70.3002 285.836 70.3002ZM286.526 70.9602V76.5002H285.126V70.9602H286.526Z"
                fill="#2A3147"
            />
            <path
                d="M281.796 76.5901C281.343 76.5901 280.936 76.5101 280.576 76.3501C280.216 76.1835 279.93 75.9601 279.716 75.6801C279.51 75.4001 279.396 75.0901 279.376 74.7501H280.786C280.813 74.9635 280.916 75.1401 281.096 75.2801C281.283 75.4201 281.513 75.4901 281.786 75.4901C282.053 75.4901 282.26 75.4368 282.406 75.3301C282.56 75.2235 282.636 75.0868 282.636 74.9201C282.636 74.7401 282.543 74.6068 282.356 74.5201C282.176 74.4268 281.886 74.3268 281.486 74.2201C281.073 74.1201 280.733 74.0168 280.466 73.9101C280.206 73.8035 279.98 73.6401 279.786 73.4201C279.6 73.2001 279.506 72.9035 279.506 72.5301C279.506 72.2235 279.593 71.9435 279.766 71.6901C279.946 71.4368 280.2 71.2368 280.526 71.0901C280.86 70.9435 281.25 70.8701 281.696 70.8701C282.356 70.8701 282.883 71.0368 283.276 71.3701C283.67 71.6968 283.886 72.1401 283.926 72.7001H282.586C282.566 72.4801 282.473 72.3068 282.306 72.1801C282.146 72.0468 281.93 71.9801 281.656 71.9801C281.403 71.9801 281.206 72.0268 281.066 72.1201C280.933 72.2135 280.866 72.3435 280.866 72.5101C280.866 72.6968 280.96 72.8401 281.146 72.9401C281.333 73.0335 281.623 73.1301 282.016 73.2301C282.416 73.3301 282.746 73.4335 283.006 73.5401C283.266 73.6468 283.49 73.8135 283.676 74.0401C283.87 74.2601 283.97 74.5535 283.976 74.9201C283.976 75.2401 283.886 75.5268 283.706 75.7801C283.533 76.0335 283.28 76.2335 282.946 76.3801C282.62 76.5201 282.236 76.5901 281.796 76.5901Z"
                fill="#2A3147"
            />
            <path
                d="M273.802 70.8801C274.222 70.8801 274.595 70.9734 274.922 71.1601C275.249 71.3401 275.502 71.6101 275.682 71.9701C275.869 72.3234 275.962 72.7501 275.962 73.2501V76.5001H274.562V73.4401C274.562 73.0001 274.452 72.6634 274.232 72.4301C274.012 72.1901 273.712 72.0701 273.332 72.0701C272.945 72.0701 272.639 72.1901 272.412 72.4301C272.192 72.6634 272.082 73.0001 272.082 73.4401V76.5001H270.682V69.1001H272.082V71.6501C272.262 71.4101 272.502 71.2234 272.802 71.0901C273.102 70.9501 273.435 70.8801 273.802 70.8801Z"
                fill="#2A3147"
            />
            <path
                d="M264.307 73.7301C264.307 73.1568 264.423 72.6568 264.657 72.2301C264.89 71.7968 265.213 71.4635 265.627 71.2301C266.04 70.9901 266.513 70.8701 267.047 70.8701C267.733 70.8701 268.3 71.0435 268.747 71.3901C269.2 71.7301 269.503 72.2101 269.657 72.8301H268.147C268.067 72.5901 267.93 72.4035 267.737 72.2701C267.55 72.1301 267.317 72.0601 267.037 72.0601C266.637 72.0601 266.32 72.2068 266.087 72.5001C265.853 72.7868 265.737 73.1968 265.737 73.7301C265.737 74.2568 265.853 74.6668 266.087 74.9601C266.32 75.2468 266.637 75.3901 267.037 75.3901C267.603 75.3901 267.973 75.1368 268.147 74.6301H269.657C269.503 75.2301 269.2 75.7068 268.747 76.0601C268.293 76.4135 267.727 76.5901 267.047 76.5901C266.513 76.5901 266.04 76.4735 265.627 76.2401C265.213 76.0001 264.89 75.6668 264.657 75.2401C264.423 74.8068 264.307 74.3035 264.307 73.7301Z"
                fill="#2A3147"
            />
            <path
                d="M262.42 72.1103V74.7903C262.42 74.977 262.463 75.1137 262.55 75.2003C262.643 75.2803 262.796 75.3203 263.01 75.3203H263.66V76.5003H262.78C261.6 76.5003 261.01 75.927 261.01 74.7803V72.1103H260.35V70.9603H261.01V69.5903H262.42V70.9603H263.66V72.1103H262.42Z"
                fill="#2A3147"
            />
            <path
                d="M253.652 73.7101C253.652 73.1501 253.762 72.6535 253.982 72.2201C254.209 71.7868 254.512 71.4535 254.892 71.2201C255.279 70.9868 255.709 70.8701 256.182 70.8701C256.595 70.8701 256.955 70.9535 257.262 71.1201C257.575 71.2868 257.825 71.4968 258.012 71.7501V70.9601H259.422V76.5001H258.012V75.6901C257.832 75.9501 257.582 76.1668 257.262 76.3401C256.949 76.5068 256.585 76.5901 256.172 76.5901C255.705 76.5901 255.279 76.4701 254.892 76.2301C254.512 75.9901 254.209 75.6535 253.982 75.2201C253.762 74.7801 253.652 74.2768 253.652 73.7101ZM258.012 73.7301C258.012 73.3901 257.945 73.1001 257.812 72.8601C257.679 72.6135 257.499 72.4268 257.272 72.3001C257.045 72.1668 256.802 72.1001 256.542 72.1001C256.282 72.1001 256.042 72.1635 255.822 72.2901C255.602 72.4168 255.422 72.6035 255.282 72.8501C255.149 73.0901 255.082 73.3768 255.082 73.7101C255.082 74.0435 255.149 74.3368 255.282 74.5901C255.422 74.8368 255.602 75.0268 255.822 75.1601C256.049 75.2935 256.289 75.3601 256.542 75.3601C256.802 75.3601 257.045 75.2968 257.272 75.1701C257.499 75.0368 257.679 74.8501 257.812 74.6101C257.945 74.3635 258.012 74.0701 258.012 73.7301Z"
                fill="#2A3147"
            />
            <path
                d="M248.635 71.7701C248.815 71.5034 249.062 71.2868 249.375 71.1201C249.695 70.9534 250.058 70.8701 250.465 70.8701C250.938 70.8701 251.365 70.9868 251.745 71.2201C252.132 71.4534 252.435 71.7868 252.655 72.2201C252.882 72.6468 252.995 73.1434 252.995 73.7101C252.995 74.2768 252.882 74.7801 252.655 75.2201C252.435 75.6534 252.132 75.9901 251.745 76.2301C251.365 76.4701 250.938 76.5901 250.465 76.5901C250.052 76.5901 249.688 76.5101 249.375 76.3501C249.068 76.1834 248.822 75.9701 248.635 75.7101V76.5001H247.235V69.1001H248.635V71.7701ZM251.565 73.7101C251.565 73.3768 251.495 73.0901 251.355 72.8501C251.222 72.6034 251.042 72.4168 250.815 72.2901C250.595 72.1634 250.355 72.1001 250.095 72.1001C249.842 72.1001 249.602 72.1668 249.375 72.3001C249.155 72.4268 248.975 72.6134 248.835 72.8601C248.702 73.1068 248.635 73.3968 248.635 73.7301C248.635 74.0634 248.702 74.3534 248.835 74.6001C248.975 74.8468 249.155 75.0368 249.375 75.1701C249.602 75.2968 249.842 75.3601 250.095 75.3601C250.355 75.3601 250.595 75.2934 250.815 75.1601C251.042 75.0268 251.222 74.8368 251.355 74.5901C251.495 74.3434 251.565 74.0501 251.565 73.7101Z"
                fill="#2A3147"
            />
            <path
                d="M244.097 70.96L240.667 79.12H239.177L240.377 76.36L238.157 70.96H239.727L241.157 74.83L242.607 70.96H244.097Z"
                fill="#2A3147"
            />
            <path
                d="M236.154 71.8204C236.334 71.527 236.568 71.297 236.854 71.1304C237.148 70.9637 237.481 70.8804 237.854 70.8804V72.3504H237.484C237.044 72.3504 236.711 72.4537 236.484 72.6604C236.264 72.867 236.154 73.227 236.154 73.7404V76.5004H234.754V70.9604H236.154V71.8204Z"
                fill="#2A3147"
            />
            <path
                d="M233.732 73.6101C233.732 73.8101 233.719 73.9901 233.692 74.1501H229.642C229.676 74.5501 229.816 74.8635 230.062 75.0901C230.309 75.3168 230.612 75.4301 230.972 75.4301C231.492 75.4301 231.862 75.2068 232.082 74.7601H233.592C233.432 75.2935 233.126 75.7335 232.672 76.0801C232.219 76.4201 231.662 76.5901 231.002 76.5901C230.469 76.5901 229.989 76.4735 229.562 76.2401C229.142 76.0001 228.812 75.6635 228.572 75.2301C228.339 74.7968 228.222 74.2968 228.222 73.7301C228.222 73.1568 228.339 72.6535 228.572 72.2201C228.806 71.7868 229.132 71.4535 229.552 71.2201C229.972 70.9868 230.456 70.8701 231.002 70.8701C231.529 70.8701 231.999 70.9835 232.412 71.2101C232.832 71.4368 233.156 71.7601 233.382 72.1801C233.616 72.5935 233.732 73.0701 233.732 73.6101ZM232.282 73.2101C232.276 72.8501 232.146 72.5635 231.892 72.3501C231.639 72.1301 231.329 72.0201 230.962 72.0201C230.616 72.0201 230.322 72.1268 230.082 72.3401C229.849 72.5468 229.706 72.8368 229.652 73.2101H232.282Z"
                fill="#2A3147"
            />
            <path
                d="M224.906 75.21L226.306 70.96H227.796L225.746 76.5H224.046L222.006 70.96H223.506L224.906 75.21Z"
                fill="#2A3147"
            />
            <path
                d="M220.523 70.3002C220.276 70.3002 220.07 70.2235 219.903 70.0702C219.743 69.9102 219.663 69.7135 219.663 69.4802C219.663 69.2468 219.743 69.0535 219.903 68.9002C220.07 68.7402 220.276 68.6602 220.523 68.6602C220.77 68.6602 220.973 68.7402 221.133 68.9002C221.3 69.0535 221.383 69.2468 221.383 69.4802C221.383 69.7135 221.3 69.9102 221.133 70.0702C220.973 70.2235 220.77 70.3002 220.523 70.3002ZM221.213 70.9602V76.5002H219.813V70.9602H221.213Z"
                fill="#2A3147"
            />
            <path d="M218.43 69.1001V76.5001H217.03V69.1001H218.43Z" fill="#2A3147" />
            <path
                d="M216.008 73.6101C216.008 73.8101 215.994 73.9901 215.968 74.1501H211.918C211.951 74.5501 212.091 74.8635 212.338 75.0901C212.584 75.3168 212.888 75.4301 213.248 75.4301C213.768 75.4301 214.138 75.2068 214.358 74.7601H215.868C215.708 75.2935 215.401 75.7335 214.948 76.0801C214.494 76.4201 213.938 76.5901 213.278 76.5901C212.744 76.5901 212.264 76.4735 211.838 76.2401C211.418 76.0001 211.088 75.6635 210.848 75.2301C210.614 74.7968 210.498 74.2968 210.498 73.7301C210.498 73.1568 210.614 72.6535 210.848 72.2201C211.081 71.7868 211.408 71.4535 211.828 71.2201C212.248 70.9868 212.731 70.8701 213.278 70.8701C213.804 70.8701 214.274 70.9835 214.688 71.2101C215.108 71.4368 215.431 71.7601 215.658 72.1801C215.891 72.5935 216.008 73.0701 216.008 73.6101ZM214.558 73.2101C214.551 72.8501 214.421 72.5635 214.168 72.3501C213.914 72.1301 213.604 72.0201 213.238 72.0201C212.891 72.0201 212.598 72.1268 212.358 72.3401C212.124 72.5468 211.981 72.8368 211.928 73.2101H214.558Z"
                fill="#2A3147"
            />
            <path
                d="M206.13 69.52C206.863 69.52 207.507 69.6634 208.06 69.95C208.62 70.2367 209.05 70.6467 209.35 71.18C209.657 71.7067 209.81 72.32 209.81 73.02C209.81 73.72 209.657 74.3334 209.35 74.86C209.05 75.38 208.62 75.7834 208.06 76.07C207.507 76.3567 206.863 76.5 206.13 76.5H203.69V69.52H206.13ZM206.08 75.31C206.813 75.31 207.38 75.11 207.78 74.71C208.18 74.31 208.38 73.7467 208.38 73.02C208.38 72.2934 208.18 71.7267 207.78 71.32C207.38 70.9067 206.813 70.7 206.08 70.7H205.09V75.31H206.08Z"
                fill="#2A3147"
            />
            <path
                d="M266.168 208.26C266.168 207.107 266.362 206.207 266.748 205.56C267.142 204.907 267.815 204.58 268.768 204.58C269.722 204.58 270.392 204.907 270.778 205.56C271.172 206.207 271.368 207.107 271.368 208.26C271.368 209.427 271.172 210.34 270.778 211C270.392 211.653 269.722 211.98 268.768 211.98C267.815 211.98 267.142 211.653 266.748 211C266.362 210.34 266.168 209.427 266.168 208.26ZM270.248 208.26C270.248 207.72 270.212 207.263 270.138 206.89C270.072 206.517 269.932 206.213 269.718 205.98C269.505 205.74 269.188 205.62 268.768 205.62C268.348 205.62 268.032 205.74 267.818 205.98C267.605 206.213 267.462 206.517 267.388 206.89C267.322 207.263 267.288 207.72 267.288 208.26C267.288 208.82 267.322 209.29 267.388 209.67C267.455 210.05 267.595 210.357 267.808 210.59C268.028 210.823 268.348 210.94 268.768 210.94C269.188 210.94 269.505 210.823 269.718 210.59C269.938 210.357 270.082 210.05 270.148 209.67C270.215 209.29 270.248 208.82 270.248 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M259.762 208.26C259.762 207.107 259.956 206.207 260.342 205.56C260.736 204.907 261.409 204.58 262.362 204.58C263.316 204.58 263.986 204.907 264.372 205.56C264.766 206.207 264.962 207.107 264.962 208.26C264.962 209.427 264.766 210.34 264.372 211C263.986 211.653 263.316 211.98 262.362 211.98C261.409 211.98 260.736 211.653 260.342 211C259.956 210.34 259.762 209.427 259.762 208.26ZM263.842 208.26C263.842 207.72 263.806 207.263 263.732 206.89C263.666 206.517 263.526 206.213 263.312 205.98C263.099 205.74 262.782 205.62 262.362 205.62C261.942 205.62 261.626 205.74 261.412 205.98C261.199 206.213 261.056 206.517 260.982 206.89C260.916 207.263 260.882 207.72 260.882 208.26C260.882 208.82 260.916 209.29 260.982 209.67C261.049 210.05 261.189 210.357 261.402 210.59C261.622 210.823 261.942 210.94 262.362 210.94C262.782 210.94 263.099 210.823 263.312 210.59C263.532 210.357 263.676 210.05 263.742 209.67C263.809 209.29 263.842 208.82 263.842 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M253.356 208.26C253.356 207.107 253.549 206.207 253.936 205.56C254.329 204.907 255.003 204.58 255.956 204.58C256.909 204.58 257.579 204.907 257.966 205.56C258.359 206.207 258.556 207.107 258.556 208.26C258.556 209.427 258.359 210.34 257.966 211C257.579 211.653 256.909 211.98 255.956 211.98C255.003 211.98 254.329 211.653 253.936 211C253.549 210.34 253.356 209.427 253.356 208.26ZM257.436 208.26C257.436 207.72 257.399 207.263 257.326 206.89C257.259 206.517 257.119 206.213 256.906 205.98C256.693 205.74 256.376 205.62 255.956 205.62C255.536 205.62 255.219 205.74 255.006 205.98C254.793 206.213 254.649 206.517 254.576 206.89C254.509 207.263 254.476 207.72 254.476 208.26C254.476 208.82 254.509 209.29 254.576 209.67C254.643 210.05 254.783 210.357 254.996 210.59C255.216 210.823 255.536 210.94 255.956 210.94C256.376 210.94 256.693 210.823 256.906 210.59C257.126 210.357 257.269 210.05 257.336 209.67C257.403 209.29 257.436 208.82 257.436 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M251.554 212.07C251.347 212.07 251.174 212 251.034 211.86C250.894 211.72 250.824 211.547 250.824 211.34C250.824 211.133 250.894 210.96 251.034 210.82C251.174 210.68 251.347 210.61 251.554 210.61C251.754 210.61 251.924 210.68 252.064 210.82C252.204 210.96 252.274 211.133 252.274 211.34C252.274 211.547 252.204 211.72 252.064 211.86C251.924 212 251.754 212.07 251.554 212.07Z"
                fill="#676D7E"
            />
            <path
                d="M244.538 208.26C244.538 207.107 244.731 206.207 245.118 205.56C245.511 204.907 246.184 204.58 247.138 204.58C248.091 204.58 248.761 204.907 249.148 205.56C249.541 206.207 249.738 207.107 249.738 208.26C249.738 209.427 249.541 210.34 249.148 211C248.761 211.653 248.091 211.98 247.138 211.98C246.184 211.98 245.511 211.653 245.118 211C244.731 210.34 244.538 209.427 244.538 208.26ZM248.618 208.26C248.618 207.72 248.581 207.263 248.508 206.89C248.441 206.517 248.301 206.213 248.088 205.98C247.874 205.74 247.558 205.62 247.138 205.62C246.718 205.62 246.401 205.74 246.188 205.98C245.974 206.213 245.831 206.517 245.758 206.89C245.691 207.263 245.658 207.72 245.658 208.26C245.658 208.82 245.691 209.29 245.758 209.67C245.824 210.05 245.964 210.357 246.178 210.59C246.398 210.823 246.718 210.94 247.138 210.94C247.558 210.94 247.874 210.823 248.088 210.59C248.308 210.357 248.451 210.05 248.518 209.67C248.584 209.29 248.618 208.82 248.618 208.26Z"
                fill="#676D7E"
            />
            <path
                d="M238.58 206.51C238.62 205.89 238.857 205.407 239.29 205.06C239.73 204.713 240.29 204.54 240.97 204.54C241.437 204.54 241.84 204.623 242.18 204.79C242.52 204.957 242.777 205.183 242.95 205.47C243.123 205.757 243.21 206.08 243.21 206.44C243.21 206.853 243.1 207.207 242.88 207.5C242.66 207.793 242.397 207.99 242.09 208.09V208.13C242.483 208.25 242.79 208.47 243.01 208.79C243.23 209.103 243.34 209.507 243.34 210C243.34 210.393 243.25 210.743 243.07 211.05C242.89 211.357 242.623 211.6 242.27 211.78C241.917 211.953 241.493 212.04 241 212.04C240.28 212.04 239.687 211.857 239.22 211.49C238.76 211.117 238.513 210.583 238.48 209.89H239.58C239.607 210.243 239.743 210.533 239.99 210.76C240.237 210.98 240.57 211.09 240.99 211.09C241.397 211.09 241.71 210.98 241.93 210.76C242.15 210.533 242.26 210.243 242.26 209.89C242.26 209.423 242.11 209.093 241.81 208.9C241.517 208.7 241.063 208.6 240.45 208.6H240.19V207.66H240.46C241 207.653 241.41 207.563 241.69 207.39C241.977 207.217 242.12 206.943 242.12 206.57C242.12 206.25 242.017 205.997 241.81 205.81C241.603 205.617 241.31 205.52 240.93 205.52C240.557 205.52 240.267 205.617 240.06 205.81C239.853 205.997 239.73 206.23 239.69 206.51H238.58Z"
                fill="#676D7E"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_2521_1728"
                x="-63"
                y="101"
                width="129"
                height="265"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="30" result="effect1_foregroundBlur_2521_1728" />
            </filter>
            <filter
                id="filter1_f_2521_1728"
                x="411"
                y="-85"
                width="180"
                height="129"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_2521_1728" />
            </filter>
            <clipPath id="clip0_2521_1728">
                <rect width="508" height="282" rx="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
