import { ActivitiesDTO, ActivityDTO, DriverIdDTO, ExpenseDTO } from '@luminovo/http-client';
import { formatDriverIdParameter } from '@luminovo/manufacturing-core';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

export const useIsDriverUsedInActivitiesOrExpenses = (driverId: DriverIdDTO) => {
    const {
        data: activitiesData,
        isLoading: isActivitiesLoading,
        isError: isActivitiesError,
    } = useHttpQuery('GET /activities', {
        queryParams: {
            /* eslint-disable-next-line camelcase */
            driver_id: formatDriverIdParameter(driverId),
        },
    });
    const {
        data: expensesData,
        isLoading: isExpensesLoading,
        isError: isExpensesError,
    } = useHttpQuery('GET /expenses', {
        queryParams: {
            /* eslint-disable-next-line camelcase */
            driver_id: formatDriverIdParameter(driverId),
        },
    });

    const activitiesOfDriver: ActivitiesDTO | undefined = activitiesData?.data;
    const expensesOfDriver: ExpenseDTO[] | undefined = expensesData?.data;
    const isAnythingLoading = isActivitiesLoading || isExpensesLoading;
    const isAnythingError = isActivitiesError || isExpensesError;

    const batchActivitiesOfDriver =
        activitiesOfDriver?.filter((a: ActivityDTO) => a.time_calculation.level === 'Batch').map((a) => a.name) ?? [];
    const projectExpensesOfDriver =
        expensesOfDriver?.filter((a: ExpenseDTO) => a.level === 'Project').map((e) => e.name) ?? [];
    const bathExpensesOfDriver =
        expensesOfDriver?.filter((a: ExpenseDTO) => a.level === 'Batch').map((e) => e.name) ?? [];
    const allNames = [...batchActivitiesOfDriver, ...bathExpensesOfDriver, ...projectExpensesOfDriver];
    const isDriverUsedInBatchActivityOrBatchExpenseOrProjectExpense = !isAnythingLoading && allNames.length !== 0;

    return {
        isDriverUsedInBatchActivityOrBatchExpenseOrProjectExpense,
        isAnythingLoading,
        isAnythingError,
        allNamesOfActivities: batchActivitiesOfDriver,
        allNamesOfExpenses: [...bathExpensesOfDriver, ...projectExpensesOfDriver],
    };
};
