import { Trans, t } from '@lingui/macro';
import { assertUnreachable, isPresent, typeSafeObjectKeys } from '@luminovo/commons';
import { Flexbox, Message, Text, colorSystem } from '@luminovo/design-system';
import {
    BomIssues,
    BomItemApprovalStatus,
    BomItemIssue,
    BomState,
    CustomerPortalRequirement,
} from '@luminovo/http-client';
import { useHistory } from 'react-router';
import { route } from '../../../../../utils/routes';
import { FilterId } from '../../../../Bom/components/ModuleTableData/filters';
import { bomItemIssueMessage } from '../../../../Bom/hooks/utils/bomIssues';
import { StepContainer } from './StepContainer';

export const BomStep = ({
    bomState,
    rfqId,
    assemblyId,
    isRfqEditable,
    requirement,
}: {
    bomState: BomState;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    requirement: CustomerPortalRequirement;
}): JSX.Element => {
    const status = bomState.status.type;

    return (
        <StepContainer
            status={status}
            label={t`BOM`}
            type="bom"
            rfqId={rfqId}
            assemblyId={assemblyId}
            isRfqEditable={isRfqEditable}
            requirement={requirement}
        >
            {bomState.status.type === 'InProgress' && (
                <Flexbox flexDirection="column" width="100%" gap="8px">
                    <Text variant="body" color={colorSystem.neutral[7]}>
                        <Trans>There are open issues in your BOM. Try to resolve them...</Trans>
                    </Text>
                    {convertToIssueLine({ issues: bomState.status.issues, rfqId, assemblyId }).map((issueLineProps) => {
                        return <IssueLine {...issueLineProps} key={issueLineProps.message} />;
                    })}
                </Flexbox>
            )}
        </StepContainer>
    );
};

interface IssueLineProps {
    message: string;
    status: BomItemApprovalStatus;
    count: number;
    link: string;
    isEditable: boolean;
}

// This returns FilterId | null because we have BomItemIssue.DoNotPlace
// which isn't necessarily an issue that we want to show.
function convertIssueToFilter(issue: BomItemIssue): FilterId | null {
    switch (issue) {
        case BomItemIssue.Compliance:
            return FilterId.ComplianceIssue;
        case BomItemIssue.DesignatorAndQuantityUnclear:
            return FilterId.DesignatorAndQuantityUnclear;
        case BomItemIssue.DoNotPlace:
            return null;
        case BomItemIssue.InsufficientStock:
            return FilterId.InsufficientStock;
        case BomItemIssue.Lifecycle:
            return FilterId.LifecycleIssue;
        case BomItemIssue.MissingPartData:
            return FilterId.MissingPartData;
        case BomItemIssue.MountingMissing:
            return FilterId.MountingMissing;
        case BomItemIssue.NoPartOptionsApproved:
            return FilterId.NoPartOptionsApproved;
        case BomItemIssue.PackageNameOnlyMismatch:
            return FilterId.PackageMismatch;
        case BomItemIssue.PackageMismatch:
            return FilterId.PackageMismatch;
        case BomItemIssue.PackageNameMissing:
            return FilterId.PackageNameMissing;
        case BomItemIssue.PinsMissing:
            return FilterId.PinsMissing;
        default:
            assertUnreachable(issue);
    }
}

export const convertToIssueLine = ({
    issues,
    rfqId,
    assemblyId,
}: {
    issues: BomIssues;
    rfqId: string;
    assemblyId: string;
}): IssueLineProps[] => {
    return typeSafeObjectKeys(issues)
        .map((issueType) => {
            const issueInfo = issues[issueType];
            const filter = convertIssueToFilter(issueType);
            if (!isPresent(filter)) {
                return undefined;
            }

            return {
                isEditable: true,
                count: issueInfo.count,
                status: issueInfo.approval_status,
                message: bomItemIssueMessage(issueType),
                link: route(
                    '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                    { rfqId, assemblyId },
                    {
                        isReadonly: null,
                        designItemId: issueInfo.design_item_ids.join(','),
                        filters: filter.toString(),
                        bomTab: null,
                        dashboardFilters: null,
                        search: null,
                        onlyShowItemsWithManufacturingWarnings: 'optional',
                        currentParentAssemblyId: rfqId,
                    },
                ),
            };
        })
        .filter(isPresent)
        .filter((issue) => issue.count > 0 && issue.status !== BomItemApprovalStatus.DNP);
};

const EmptyIcon = (): JSX.Element => <></>;

const IssueLine = ({ message, count, link, isEditable }: IssueLineProps): JSX.Element => {
    const history = useHistory();

    return (
        <Message
            variant="yellow"
            title={`${count}x ${message}`}
            size="small"
            attention="low"
            action={isEditable ? { label: t`Solve in BOM`, onClick: () => history.push(link) } : undefined}
            onClose={undefined}
            overrides={{ MessageIcon: EmptyIcon }}
        />
    );
};
