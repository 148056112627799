import { assertUnreachable } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import { CombinedDriverType } from '../../selectors';

const renderDriverTypeLabel = (driverType: CombinedDriverType) => {
    switch (driverType) {
        case 'User+Manual':
            return 'M';
        case 'User+Automatic':
            return 'A';
        case 'System+Automatic':
            return 'A';
        default:
            /* c8 ignore next */
            assertUnreachable(driverType);
    }
};

const renderDriverTypeColor = (driverType: CombinedDriverType) => {
    switch (driverType) {
        case 'User+Manual':
            return 'neutral';
        case 'User+Automatic':
            return 'primary';
        case 'System+Automatic':
            return 'primary';
        default:
            /* c8 ignore next */
            assertUnreachable(driverType);
    }
};

export const DriverTypeTag = ({
    driverType,
    variant = 'default',
}: {
    driverType: CombinedDriverType;
    variant?: 'default' | 'large';
}): JSX.Element => {
    const style: React.CSSProperties | undefined =
        variant === 'large'
            ? {
                  width: '64px',
                  height: '64px',
                  fontSize: '48px',
                  lineHeight: '66px',
                  fontWeight: 'bold',
                  textAlign: 'center',
              }
            : undefined;

    return <Tag style={style} label={renderDriverTypeLabel(driverType)} color={renderDriverTypeColor(driverType)} />;
};
