import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import React from 'react';

export const HeadingWithValue: React.FunctionComponent<{ heading: string; value?: string }> = ({
    heading,
    value,
    children,
}) => {
    const content = value ?? children;
    return content ? (
        <Flexbox justifyContent={'space-between'}>
            <Text variant="body" color={colorSystem.neutral[7]} style={{ whiteSpace: 'nowrap' }}>
                {heading}
            </Text>

            <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'end' }}>
                {content}
            </Text>
        </Flexbox>
    ) : (
        <></>
    );
};
