/* eslint-disable camelcase */

import * as r from 'runtypes';
import { DurationRuntype, ResourceDetailsDTORuntype, TimeComponentsRuntype } from '../activity/activityBackendTypes';
import { ExchangedMonetaryValueBackendRuntype } from '../backendTypes';
import { DriverIdRuntype, DriverRuntype } from '../driver';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';
import { CalculationResult } from './calculationErrorBackendTypes';
import { DriverStatusDetailsRuntype } from './configurationDriverStatusRuntypes';

export const ActivityConfigurationRuntype = r.Record({
    id: r.String,
    activity_id: r.String,
});

export type ActivityConfigurationDTO = r.Static<typeof ActivityConfigurationRuntype>;

export const ActivityConfigurationsPostRuntype = r.Record({
    manufacturing_scenario_id: r.String,
    manufacturing_scenario_template_instance_id: r.String.nullable(),
    activities: r.Array(r.String),
});

export type ActivityConfigurationsPostDTO = r.Static<typeof ActivityConfigurationsPostRuntype>;

export const ConfigurationsPatchRuntype = r.Record({
    driver_id: DriverIdRuntype,
    manual_driver_count: r.Number.nullable().optional(),
});
export type ConfigurationsPatchDTO = r.Static<typeof ConfigurationsPatchRuntype>;

export const ConfigurationsNotesPatchRuntype = r.Record({
    notes: r.String.nullable().optional(),
});
export type ConfigurationsNotesPatchDTO = r.Static<typeof ConfigurationsPatchRuntype>;

const DriverCountTuple = r.Tuple(DriverIdRuntype, r.Null.Or(r.String));

export const DriverCountsRuntype = r.Record({
    counts: r.Array(DriverCountTuple),
});

const CostUnitRuntype = r.Record({
    batch_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
    effective_batch_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
    effective_unit_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
    unit_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
});

const CostBatchRuntype = r.Record({
    batch_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
    effective_batch_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
});

const CostProjectRuntype = r.Record({
    project_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
});

const UnitTimeRuntype = r.Record({
    batch_time: CalculationResult(DurationRuntype.nullable()),
    unit_time: CalculationResult(DurationRuntype.nullable()).optional(),
});

const BatchTimeRuntype = r.Record({
    batch_time: CalculationResult(DurationRuntype.nullable()),
});

const ProjectTimeRuntype = r.Record({
    project_time: CalculationResult(DurationRuntype.nullable()),
});

export type CostComponentUnit = r.Static<typeof CostComponentUnitRuntype>;
const CostComponentUnitRuntype = r.Record({
    level: r.Literal('Unit'),
    details: r.Record({
        automatic_driver_count: DriverCountsRuntype,
        batch_time_components: TimeComponentsRuntype.nullable(),
        costs_per_batch_size: r.Dictionary(CostUnitRuntype, r.String),
        driver_count: DriverCountsRuntype,
        times_per_batch_size: r.Dictionary(UnitTimeRuntype, r.String),
        unit_time_components: TimeComponentsRuntype,
        driver_statuses: r.Array(DriverStatusDetailsRuntype),
    }),
});

export type CostComponentBatch = r.Static<typeof CostComponentBatchRuntype>;
const CostComponentBatchRuntype = r.Record({
    level: r.Literal('Batch'),
    details: r.Record({
        automatic_driver_count: DriverCountsRuntype,
        costs_per_batch_size: r.Dictionary(CostBatchRuntype, r.String),
        driver_count: DriverCountsRuntype,
        time_components: TimeComponentsRuntype,
        times_per_batch_size: r.Dictionary(BatchTimeRuntype, r.String),
        driver_statuses: r.Array(DriverStatusDetailsRuntype),
    }),
});

export type CostComponentProject = r.Static<typeof CostComponentProjectRuntype>;
const CostComponentProjectRuntype = r.Record({
    level: r.Literal('Project'),
    details: r.Record({
        automatic_driver_count: DriverCountsRuntype,
        costs_per_batch_size: r.Dictionary(CostProjectRuntype, r.String),
        driver_count: DriverCountsRuntype,
        time_components: TimeComponentsRuntype,
        times_per_batch_size: r.Dictionary(ProjectTimeRuntype, r.String),
        driver_statuses: r.Array(DriverStatusDetailsRuntype),
    }),
});

const CostComponentsRuntype = CostComponentUnitRuntype.Or(CostComponentBatchRuntype).Or(CostComponentProjectRuntype);

export type CostComponentsActivities = r.Static<typeof CostComponentsRuntype>;

export const ActivityConfigurationSummaryRuntype = r.Record({
    activity_configuration_details: r.Record({
        activity_configuration_id: r.String,
        activity_configuration_notes: r.String.nullable(),
        activity_id: r.String,
        activity_name: r.String,
        activity_process: ProcessRuntype,
        activity_internal_number: r.String.nullable(),
        activity_category: CategoryRuntype,
        activity_is_per_panel: r.Boolean,
        cost_components: CostComponentsRuntype,
        activity_description: r.String.nullable(),
        activity_status: ManufacturingEntityStatusRuntype,
        template_instance_id: r.String.nullable(),
        rank: r.Number,
    }),
    resources_details: r.Array(ResourceDetailsDTORuntype),
    unit_drivers: r.Array(DriverRuntype),
    batch_drivers: r.Array(DriverRuntype),
    project_drivers: r.Array(DriverRuntype),
});

export type ActivityConfigurationSummaryDTO = r.Static<typeof ActivityConfigurationSummaryRuntype>;
