import { Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { useEditManufacturingScenarioTemplatesDialog } from '../ManufacturingScenarioForm/useEditTemplateDialog';

export const AppendManufacturingScenarioTemplateButton = ({
    manufacturingScenarioId,
}: {
    manufacturingScenarioId: string;
}): JSX.Element => {
    const { openDialog } = useEditManufacturingScenarioTemplatesDialog({ manufacturingScenarioId });

    return (
        <>
            <SecondaryButton onClick={() => openDialog()} size="medium">
                <Trans>Append templates</Trans>
            </SecondaryButton>
        </>
    );
};
