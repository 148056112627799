import { Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';

interface CancelButtonProps {
    disabled?: boolean;
    /** to override cancel button on click. Default will reset the form context */
    onClick?: () => void;
}

export function CancelButton({ disabled = false, onClick }: CancelButtonProps) {
    const { formState, reset } = useFormContext();

    return (
        <SecondaryButton
            disabled={formState.isSubmitting || disabled}
            type={'reset'}
            onClick={() => {
                onClick ? onClick() : reset();
            }}
            style={{ minWidth: 80 }}
        >
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}
