import { formatCurrencyWithoutSymbol } from '@luminovo/commons';
import { Tooltip } from '@luminovo/design-system';
import { styled } from '@mui/material';
import { Bar } from '@visx/shape';
import { BarStack } from '@visx/shape/lib/types';
import { noop } from '../noop';
import { Datum, HorizontalStackedBarChartProps } from '../types';

export function StackedBar<TKeys extends string, T extends Datum<TKeys>>({
    bar,
    isMissingData = () => false,
    formatKey,
    formatValue = formatCurrencyWithoutSymbol,
    onBarClick = noop,
    stackOnTop = false,
}: HorizontalStackedBarChartProps<TKeys, T> & {
    bar: BarStack<T, TKeys>['bars'][number];
    barStack: BarStack<T, TKeys>;
}): JSX.Element {
    const datum = bar.bar.data;
    const showMissingData = isMissingData(datum, bar.key);
    const value: number | undefined = datum[bar.key];

    const dx = 8;
    return (
        <Tooltip
            variant="white"
            arrow
            title={showMissingData ? formatKey(bar.key) : `${formatKey(bar.key)}: ${formatValue(value ?? 0)}`}
            placement="right"
        >
            <g>
                <StyledBar
                    fill={bar.color}
                    onClick={() => onBarClick(datum)}
                    {...(showMissingData
                        ? {
                              x: stackOnTop ? 0 : bar.x + 4,
                              y: bar.y + dx / 2,
                              rx: 2,
                              width: bar.height - dx,
                              height: bar.height - dx,
                          }
                        : {
                              x: stackOnTop ? 0 : bar.x,
                              y: bar.y,
                              rx: 0,
                              width: bar.width,
                              height: bar.height,
                          })}
                />
            </g>
        </Tooltip>
    );
}

const StyledBar = styled(Bar)({
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
});
