import * as React from 'react';
import { BaseField, BaseFieldProps, FieldConverter } from '../BaseField';

export interface FieldNumericProps extends Omit<BaseFieldProps<number | null>, 'converter'> {}

export const FieldNumeric = React.forwardRef<HTMLDivElement, FieldNumericProps>((props, ref) => {
    return <BaseField ref={ref} converter={fieldConverterNumber} {...props} />;
});

export const fieldConverterNumber: FieldConverter<number | null> = {
    parseValue: (text: string) => {
        const value = text.trim();
        if (value === '') {
            return { ok: true, value: null };
        }
        const parsed = Number(value.replace(',', '.'));
        if (isNaN(parsed)) {
            return { ok: false };
        }
        return { ok: true, value: parsed };
    },
    formatValue: (value) => {
        return String(value ?? '');
    },
};

export const fieldConverterPercent: FieldConverter<number | null> = {
    parseValue: (text: string) => {
        const result = fieldConverterNumber.parseValue(text);
        if (!result.ok || result.value === null) {
            return result;
        }
        return { ok: true, value: result.value / 100 };
    },
    formatValue: (value) => {
        if (value === null) {
            return '';
        }
        return String(value * 100);
    },
};

export const fieldConverterString: FieldConverter<string | null> = {
    parseValue: (text: string) => {
        const value = text.trim().replace(',', '.').replace(/[.]*$/, '');
        if (value === '') {
            return { ok: true, value: null };
        }
        const parsed = Number(value);
        if (isNaN(parsed)) {
            return { ok: false };
        }
        return { ok: true, value };
    },
    formatValue: (value) => {
        return String(value ?? '');
    },
};
