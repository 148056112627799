import { colorSystem } from '@luminovo/design-system';
import { styled } from '@mui/material';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import { Datum } from '../types';

const StackedBarContainer = styled('rect')({
    fill: 'transparent',
    opacity: 0.5,
    mixBlendMode: 'multiply',
});

export function BarContainers<TKeys extends string, T extends Datum<TKeys>>({
    xScale,
    yScale,
    data,
    barHeight,
}: {
    yScale: ScaleBand<string>;
    xScale: ScaleLinear<number, number, never>;
    data: (T & { selected?: boolean })[];
    barHeight: number;
}): JSX.Element {
    const domain = xScale.range();
    const domainMin = domain[0] ?? 0;
    const domainMax = domain[domain.length - 1] ?? 0;

    const out = yScale.domain().map((y) => {
        const datum = data.find((d) => d.label === y);
        return (
            <StackedBarContainer
                style={{ fill: datum && 'selected' in datum && datum.selected ? colorSystem.primary[3] : undefined }}
                key={y}
                x={domainMin}
                width={domainMax}
                y={(yScale(y) ?? 0) - 1}
                height={barHeight}
            />
        );
    });
    return <>{out}</>;
}
