import { assertUnreachable } from '@luminovo/commons';
import { HumanIcon, RobotIcon } from '@luminovo/design-system';
import { ResourceType } from '@luminovo/http-client';

export interface ResourceIconProps {
    resourceType: ResourceType;
    heightAndWidth?: string;
}

export const ResourceIcon = ({ resourceType, heightAndWidth }: ResourceIconProps): JSX.Element => {
    return <> {renderResourceTypeIcon(resourceType, heightAndWidth)}</>;
};

const renderResourceTypeIcon = (resourceType: ResourceType, heightAndWidth?: string) => {
    switch (resourceType) {
        case ResourceType.Machine:
            return <RobotIcon heightAndWidth={heightAndWidth} />;
        case ResourceType.Person:
            return <HumanIcon heightAndWidth={heightAndWidth} />;
        default:
            /* c8 ignore next */
            assertUnreachable(resourceType);
    }
};
