import { Trans, t } from '@lingui/macro';
import { FieldSelectControlled, FormItem, Text, colorSystem } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { compareByTotalOrderSize } from '../../../../../resources/sourcingScenario/compareByTotalOrderSize';
import { AddManufacturingScenarioFormState, BaseManufacturingScenarioFormState } from '../types';

const getOptionLabel = ({
    sourcingScenarioId,
    sourcingScenarios,
    assemblyQuantities,
}: {
    sourcingScenarioId: string;
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyQuantities: Record<string, number>;
}) => {
    const orderSize = sourcingScenarioId in assemblyQuantities ? assemblyQuantities[sourcingScenarioId] : undefined;
    let scenario = sourcingScenarios.find((sourcingScenario) => sourcingScenario.id === sourcingScenarioId);

    if (scenario === undefined || orderSize === undefined) {
        return '';
    }

    return `${scenario.name}, ${t`Order size`} = ${orderSize}`;
};

export const SourcingScenarioControlled = ({
    sourcingScenarios,
    assemblyQuantities,
    width,
}: {
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyQuantities: Record<string, number>;
    width?: string;
}) => {
    const { control } = useFormContext<AddManufacturingScenarioFormState | BaseManufacturingScenarioFormState>();

    return (
        <FormItem label={t`Sourcing scenario`} width={width ?? '100%'}>
            <FieldSelectControlled
                control={control}
                name="sourcingScenarioId"
                FieldProps={{
                    options: sourcingScenarios.sort(compareByTotalOrderSize).map((scenario) => scenario.id),
                    getOptionLabel: (sourcingScenarioId) =>
                        getOptionLabel({ sourcingScenarioId, sourcingScenarios, assemblyQuantities }),
                    placeholder: t`Select sourcing scenario`,
                }}
            />
            <Text color={colorSystem.neutral[7]} style={{ marginTop: '-4px' }} variant="caption">
                <Trans>Used for sourcing drivers</Trans>
            </Text>
        </FormItem>
    );
};
