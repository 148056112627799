import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { PartSuggestionFullRuntype } from './bomItemBackendTypes';

export const bomItemEndpoints = {
    'GET /bom-items/:aggregationKey/suggestions': endpoint({
        description: 'Fetches a list of suggestions for a given bomItem',
        pathParams: r.Record({ aggregationKey: r.String }),
        queryParams: r.Record({ assembly_id: r.String, max_components: r.Number.optional() }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(PartSuggestionFullRuntype),
        }),
    }),
};
