import { assertUnreachable } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { ManufacturingEntityStatusWithError } from '@luminovo/http-client';

export const extractStatusColor = ({
    status,
}: {
    status: ManufacturingEntityStatusWithError;
}): keyof typeof colorSystem => {
    switch (status) {
        case 'Error':
            return 'red';
        case 'Active':
            return 'green';
        case 'Inactive':
            return 'neutral';
        default:
            assertUnreachable(status);
    }
};
