import {
    http,
    ManualAdditionalSolutionCostDTO,
    OneTimeCostDTO,
    SolutionConfigurationDTO,
    SolutionStatus,
} from '@luminovo/http-client';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useToken } from '../hooks';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';
import { useSourcingFull } from '../sourcingScenario/sourcingScenarioHandlers';

export const QUERY_KEY_MUTATION_PATCH_SOLUTION_CONFIGURATION = ['solution-configuration-patch-mutation-key'];

export function useSolutionConfiguration(id: string | undefined): UseQueryResult<SolutionConfigurationDTO> {
    return useHttpQuery(
        'GET /solution-configurations/:solutionConfigurationId',
        { pathParams: { solutionConfigurationId: id ?? '' } },
        {
            enabled: Boolean(id),
            select: (res) => res.data,
            staleTime: 10_000,
            useErrorBoundary: true,
        },
    );
}

export function useSolutionConfigurationSourcing(solutionConfigurationId: string | undefined) {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);
    const { data: fullSourcingDTO } = useSourcingFull(solutionConfiguration?.sourcing_scenario);

    if (!solutionConfiguration) {
        return { data: undefined, isLoading: true };
    }

    return {
        data: fullSourcingDTO?.solution_configurations_sourcing.items.find(
            (item) => item.id === solutionConfigurationId,
        ),
        isLoading: false,
    };
}

export function useMutationUpdateSolutionConfiguration(solutionConfigurationId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationKey: QUERY_KEY_MUTATION_PATCH_SOLUTION_CONFIGURATION,
        mutationFn: (body: {
            solutionToken?: string | null;
            notes?: string | null;
            manualSolutionStatus?: SolutionStatus | null;
            manualOneTimeCosts?: OneTimeCostDTO[];
            manualTotalCostsOfOwnership?: ManualAdditionalSolutionCostDTO | null;
        }) => {
            return http(
                'PATCH /solution-configurations/:solutionConfigurationId',
                {
                    pathParams: { solutionConfigurationId },
                    requestBody: {
                        manually_selected_solution_token: body.solutionToken,
                        notes: body.notes,
                        /* eslint-disable-next-line camelcase */
                        manual_solution_status: body.manualSolutionStatus,
                        /* eslint-disable-next-line camelcase */
                        manual_one_time_costs: body.manualOneTimeCosts,
                        /* eslint-disable-next-line camelcase */
                        manual_total_cost_of_ownership: body.manualTotalCostsOfOwnership,
                    },
                },
                token,
            );
        },
        onSuccess: async () => {
            return Promise.all([
                queryClient.invalidateQueries(httpQueryKey('GET /solutions')),
                queryClient.invalidateQueries(httpQueryKey('GET /solution-configurations')),
                queryClient.invalidateQueries(httpQueryKey('GET /solution-configurations/:solutionConfigurationId')),
                queryClient.invalidateQueries(httpQueryKey('POST /solutions/custom-part/bulk')),
            ]);
        },
        onError,
    });
}
