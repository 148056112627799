import { t, Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { ActivitiesDTO, ActivityDTO } from '@luminovo/http-client';
import { sortByProcessThenCategoryThenName } from '@luminovo/manufacturing-core';
import { Add } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useActivityColumns } from '../../../shared/Entities/columns';
import { EntityFormState, EntitySelectionDialogForm } from '../../../shared/Entities/EntitySelectionDialogForm';
import { useMutationAppendActivities } from './addActivitiesDialogFunctions';

export const AppendActivitiesButton = ({
    manufacturingScenarioId,
    manufacturingScenarioTemplateInstanceId,
}: {
    manufacturingScenarioId: string;
    manufacturingScenarioTemplateInstanceId: string | undefined;
}): JSX.Element => {
    const { openDialog } = useActivitySelectionDialogForManufacturingScenario();

    return (
        <SecondaryButton
            startIcon={<Add fontSize="inherit" />}
            onClick={() => openDialog({ manufacturingScenarioId, manufacturingScenarioTemplateInstanceId })}
            size="medium"
        >
            <Trans>Append activities</Trans>
        </SecondaryButton>
    );
};

interface ActivitySelectionDialogForManufacturingScenarioDialogProps {
    closeDialog: () => void;
    selectedManufacturingScenarioId: string;
    manufacturingScenarioTemplateInstanceId: string | undefined;
}

const useActivitySelectionDialogForManufacturingScenario = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            manufacturingScenarioId,
            manufacturingScenarioTemplateInstanceId,
        }: {
            manufacturingScenarioId: string;
            manufacturingScenarioTemplateInstanceId: string | undefined;
        }) =>
            setDialog(
                <ActivitySelectionDialogForManufacturingScenario
                    closeDialog={closeDialog}
                    selectedManufacturingScenarioId={manufacturingScenarioId}
                    manufacturingScenarioTemplateInstanceId={manufacturingScenarioTemplateInstanceId}
                />,
            ),
    };
};

const ActivitySelectionDialogForManufacturingScenario = ({
    closeDialog,
    selectedManufacturingScenarioId,
    manufacturingScenarioTemplateInstanceId,
}: ActivitySelectionDialogForManufacturingScenarioDialogProps): JSX.Element => {
    const { data } = useHttpQuery('GET /activities', { queryParams: { active: true } });

    const columns = useActivityColumns();

    const { mutateAsync } = useMutationAppendActivities({
        manufacturingScenarioId: selectedManufacturingScenarioId,
        onSuccesCallback: closeDialog,
    });
    if (data === undefined) return <CircularProgress />;

    const activities: ActivitiesDTO = data.data;

    const selectionBoxActivities: ActivityDTO[] = sortByProcessThenCategoryThenName(activities);
    const defaultValues: EntityFormState = {
        selectedIds: [],
    };

    const onSubmit: SubmitHandler<EntityFormState> = async (values: EntityFormState) => {
        await mutateAsync({
            values: {
                activities: values.selectedIds,
                // eslint-disable-next-line camelcase
                manufacturing_scenario_id: selectedManufacturingScenarioId,
                manufacturing_scenario_template_instance_id: manufacturingScenarioTemplateInstanceId ?? null,
            },
        });
        closeDialog();
    };
    return (
        <EntitySelectionDialogForm
            title={t`Select activities`}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            closeDialog={closeDialog}
            entities={selectionBoxActivities}
            columns={columns}
            persistenceId={`activity-selection-dialog-${selectedManufacturingScenarioId}`}
            defaultSelectedRowIds={[]}
        />
    );
};
