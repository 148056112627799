import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    ActivityConfigurationRuntype,
    ActivityConfigurationsPostRuntype,
    ConfigurationsNotesPatchRuntype,
    ConfigurationsPatchRuntype,
} from './activityConfigurationBackendTypes';

export const activityConfigurationEndpoints = {
    'PATCH /activity-configurations/:activityConfigurationId': endpoint({
        description: 'Edit activity configuration. If driver_count is null the count is reset.',
        pathParams: r.Record({ activityConfigurationId: r.String }),
        queryParams: r.Record({
            /* eslint-disable-next-line camelcase */
            apply_to_all_activities_and_expenses: r.Boolean,
        }),
        requestBody: ConfigurationsPatchRuntype,
        responseBody: r.Record({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'PATCH /activity-configurations/:activityConfigurationId/notes': endpoint({
        description: 'Edits the notes only of a specific activity configuration',
        pathParams: r.Record({ activityConfigurationId: r.String }),
        queryParams: r.Record({}),
        requestBody: ConfigurationsNotesPatchRuntype,
        responseBody: r.Record({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'POST /activity-configurations/bulk': endpoint({
        description: 'Add activities to a manufacturing scenario',
        pathParams: r.Record({}),
        queryParams: r.Record({}),
        requestBody: ActivityConfigurationsPostRuntype,
        responseBody: r.Record({
            data: r.Array(ActivityConfigurationRuntype),
        }),
        invalidates: ['GET /manufacturing-scenarios'],
    }),
    'DELETE /activity-configurations/bulk': endpoint({
        description: 'Deletes activity configurations from a manufacturing scenario',
        pathParams: r.Undefined,
        queryParams: r.Record({
            ids: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),

    'PATCH /activity-configurations/:activityConfigurationId/rank': endpoint({
        description: 'Updates the rank of an activity configuration',
        pathParams: r.Record({ activityConfigurationId: r.String }),
        queryParams: r.Record({}),
        requestBody: r.Record({ rank: r.Number }),
        responseBody: r.Record({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
