import { assertUnreachable } from '@luminovo/commons';
import {
    DriverDetailsPostBaseDTO,
    DriverDetailsType,
    DriverFilterDTO,
    FilterFormulaPropertiesDTO,
    UserDriverDetailsPostDTO,
} from '@luminovo/http-client';
import * as Sentry from '@sentry/react';
import { AddDriverFormInputs, DriverFilter, PackageFilter, isDriverFilterValid } from './AddDriverFormTypes';

/* eslint-disable camelcase */
export const createParsedData = (data: AddDriverFormInputs): UserDriverDetailsPostDTO => {
    const base: DriverDetailsPostBaseDTO = {
        name: data.name,
        is_per_panel: data.isPerPanel,
        notes: data.notes ?? null,
        lexorank: data.lexoRank,
    };
    switch (data.driverDetails.type) {
        case DriverDetailsType.Manual:
            return {
                ...base,
                details: { type: DriverDetailsType.Manual, automatic_details: null },
            };

        case DriverDetailsType.Automatic:
            if (data.driverDetails.aggregation === undefined || data.driverDetails.filterFormula === undefined) {
                Sentry.captureMessage(`should not be undefined here`, 'error');
                throw new Error('should not be undefined here');
            }

            if (!isDriverFilterValid(data.driverDetails.filterFormula)) {
                throw new Error('driver filter should not be undefined here');
            }

            return {
                ...base,
                details: {
                    type: DriverDetailsType.Automatic,
                    automatic_details: {
                        aggregation: data.driverDetails.aggregation,
                        filter_formula: convertFilterFormValuesToDriverDTO(data.driverDetails.filterFormula),
                    },
                },
            };
    }
};

const getPackageFilterProperties = (data: PackageFilter): FilterFormulaPropertiesDTO => {
    const packages = data.value.packages;
    const mounting = data.value.mounting;
    if (packages.length === 0) {
        return {
            type: 'Enum',
            filter: {
                operator: data.operator.value,
                rhs: {
                    attribute_reference: 'Package',
                    value: {
                        type: 'MountingOnly',
                        value: mounting,
                    },
                },
            },
        };
    }

    if (packages.length === 1) {
        return {
            type: 'Enum',
            filter: {
                operator: data.operator.value,
                rhs: {
                    attribute_reference: 'Package',
                    value: {
                        type: 'PackageFamily',
                        value: {
                            mounting,
                            name: packages[0],
                        },
                    },
                },
            },
        };
    }

    return {
        type: 'AnyOf',
        filter: {
            operator: data.operator.value,
            rhs: packages.map((packageName) => ({
                attribute_reference: 'Package',
                value: {
                    type: 'PackageFamily',
                    value: {
                        mounting,
                        name: packageName,
                    },
                },
            })),
        },
    };
};

const convertFilterPropertiesToDTO = (data: DriverFilter): FilterFormulaPropertiesDTO => {
    const attribute = data.filter.type;
    switch (attribute) {
        case 'CustomPartType':
            return {
                type: 'Enum',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: {
                        value: data.filter.value,
                        attribute_reference: attribute,
                    },
                },
            };
        case 'MaterialPrice':
            return {
                type: 'Monetary',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: {
                        amount: data.filter.value.amount.toString(),
                        currency: data.filter.value.currency,
                    },
                    attribute,
                },
            };
        case 'OfferType':
            return {
                type: 'Enum',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: {
                        value: data.filter.value,
                        attribute_reference: attribute,
                    },
                },
            };
        case 'Package':
            return getPackageFilterProperties(data.filter);
        case 'PartNumberOfPins':
            return {
                type: 'Number',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: data.filter.value.toString(),
                    attribute,
                },
            };
        case 'NumberOfApprovedPartOptionsWithIpns':
            return {
                type: 'Number',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: data.filter.value.toString(),
                    attribute,
                },
            };
        case 'PnpSide':
            return {
                type: 'Enum',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: {
                        value: data.filter.value,
                        attribute_reference: attribute,
                    },
                },
            };
        case 'Packaging':
            return {
                type: 'Enum',
                filter: {
                    operator: data.filter.operator.value,
                    rhs: {
                        value: data.filter.value,
                        attribute_reference: attribute,
                    },
                },
            };
        default:
            assertUnreachable(attribute);
    }
};

const convertFilterFormValuesToDriverDTO = (filterFormula: DriverFilter[]): DriverFilterDTO[] => {
    return filterFormula.map((formula) => {
        return {
            join_with_previous_using: formula.joinWithPrevious,
            filter: convertFilterPropertiesToDTO(formula),
        };
    });
};
