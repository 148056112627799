import { t } from '@lingui/macro';
import { isPresent, logToExternalErrorHandlers, uniq } from '@luminovo/commons';
import {
    AnyOfDriverFilterProperties,
    DriverDetailsType,
    DriverFilterDTO,
    FilterFormulaPropertiesDTO,
    MountingPackageRhs,
    PackageMountingEnum,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { usePageParams } from '../../../resources/hooks';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';
import { RecursivePartial, assertUnreachable } from '../../../utils/typingUtils';
import {
    AddDriverFormInputs,
    DriverDetailsInput,
    DriverFilter,
    FilterInput,
    PackageFilter,
} from './AddDriverFormTypes';
import { createParsedData } from './addDriverFormFunctions';

const getFilterValueFromMountingPackage = (
    mountingPackage: MountingPackageRhs,
): { packages: string; mounting: PackageMountingEnum } => {
    switch (mountingPackage.type) {
        case 'MountingOnly':
            return {
                packages: mountingPackage.value,
                mounting: mountingPackage.value,
            };
        case 'PackageFamily':
            return {
                packages: mountingPackage.value.name,
                mounting: mountingPackage.value.mounting,
            };
        default:
            assertUnreachable(mountingPackage);
    }
};

const getAnyOfFilterInput = (anyOf: AnyOfDriverFilterProperties): PackageFilter | undefined => {
    const filterRhs = anyOf.filter.rhs;

    if (filterRhs.length === 0) {
        const error = new Error('filter right hand value for AnyOf should not be an empty list');
        logToExternalErrorHandlers(error, { extra: { error: error } });

        return undefined;
    }

    const attributeReferenceArray = uniq(filterRhs.map((p) => p.attribute_reference));
    const mountings = uniq(filterRhs.map(({ value }) => getFilterValueFromMountingPackage(value).mounting));

    if (attributeReferenceArray.length !== 1 || mountings.length !== 1) {
        const error = new Error('attribute reference and mounting value should be unique');
        logToExternalErrorHandlers(error, { extra: { error: error } });

        return undefined;
    }

    const [mounting] = mountings;
    const packages: string[] = filterRhs
        .map(({ value }) => {
            return getFilterValueFromMountingPackage(value).packages;
        })
        .filter(isPresent);

    return {
        type: 'Package',
        operator: {
            type: 'enum',
            value: anyOf.filter.operator,
        },
        value: {
            mounting,
            packages,
        },
    };
};

const convertFilterFormulaPropertiesToFormValues = (
    filterFormula: FilterFormulaPropertiesDTO,
): FilterInput | undefined => {
    switch (filterFormula.type) {
        case 'Enum':
            const { attribute_reference: type, value } = filterFormula.filter.rhs;
            switch (type) {
                case 'PnpSide':
                    return {
                        type,
                        operator: {
                            type: 'enum',
                            value: filterFormula.filter.operator,
                        },
                        value,
                    };
                case 'CustomPartType':
                    return {
                        type,
                        operator: {
                            type: 'enum',
                            value: filterFormula.filter.operator,
                        },
                        value,
                    };
                case 'OfferType':
                    return {
                        type,
                        operator: {
                            type: 'enum',
                            value: filterFormula.filter.operator,
                        },
                        value,
                    };
                case 'Packaging':
                    return {
                        type,
                        operator: {
                            type: 'enum',
                            value: filterFormula.filter.operator,
                        },
                        value,
                    };
                case 'Package':
                    const packageType = filterFormula.filter.rhs.value.type;
                    switch (packageType) {
                        case 'MountingOnly':
                            return {
                                type,
                                operator: {
                                    type: 'enum',
                                    value: filterFormula.filter.operator,
                                },
                                value: {
                                    mounting: filterFormula.filter.rhs.value.value,
                                    packages: [],
                                },
                            };
                        case 'PackageFamily':
                            return {
                                type,
                                operator: {
                                    type: 'enum',
                                    value: filterFormula.filter.operator,
                                },
                                value: {
                                    mounting: filterFormula.filter.rhs.value.value.mounting,
                                    packages: [filterFormula.filter.rhs.value.value.name],
                                },
                            };
                        default:
                            assertUnreachable(packageType);
                    }
                /* eslint-disable-next-line no-fallthrough */
                default:
                    assertUnreachable(type);
            }

        /* eslint-disable-next-line no-fallthrough */
        case 'Number':
            return {
                type: filterFormula.filter.attribute,
                operator: {
                    type: 'number',
                    value: filterFormula.filter.operator,
                },
                value: Number.parseFloat(filterFormula.filter.rhs),
            };

        case 'AnyOf':
            return getAnyOfFilterInput(filterFormula);

        case 'Monetary':
            return {
                type: 'MaterialPrice',
                operator: {
                    type: 'number',
                    value: filterFormula.filter.operator,
                },
                value: {
                    amount: Number.parseFloat(filterFormula.filter.rhs.amount),
                    currency: filterFormula.filter.rhs.currency,
                },
            };

        default:
            assertUnreachable(filterFormula);
    }
};

export const convertFilterFormulaDataToFormValues = (filterFormula: DriverFilterDTO[]): DriverFilter[] => {
    return filterFormula
        .map((formula) => {
            const filter = convertFilterFormulaPropertiesToFormValues(formula.filter);
            if (isPresent(filter)) {
                return {
                    joinWithPrevious: formula.join_with_previous_using,
                    filter,
                };
            }
            return undefined;
        })
        .filter(isPresent);
};

const getDriverDetailsInput = (driver: UserDriverDetailsDTO): DriverDetailsInput => {
    const { type: driverType, automatic_details: automaticDetails } = driver.details;
    switch (driverType) {
        case DriverDetailsType.Manual:
            return { type: DriverDetailsType.Manual };
        case DriverDetailsType.Automatic:
            return {
                type: DriverDetailsType.Automatic,
                aggregation: automaticDetails.aggregation,
                filterFormula: convertFilterFormulaDataToFormValues(automaticDetails.filter_formula),
            };
        default:
            assertUnreachable(driverType);
    }
};

export const convertDriverDTOToFormValues = (driver: UserDriverDetailsDTO): RecursivePartial<AddDriverFormInputs> => {
    return {
        name: driver.name,
        driverDetails: getDriverDetailsInput(driver),
        isPerPanel: driver.is_per_panel,
        notes: driver.notes ?? undefined,
        lexoRank: driver.lexorank,
    };
};

export type DriverFormProps = { useFormReturn: UseFormReturn<AddDriverFormInputs>; onSubmitType: 'POST' | 'PATCH' };

export const useDriverFormFunction = () => {
    const { driverId } = usePageParams<'/manufacturing/driver/:driverId/edit'>();
    const history = useHistory();

    const { mutateAsync } = useHttpMutation('POST /user-drivers', {
        snackbarMessage: t`Driver created`,
        onSuccess: (_) => history.push(route('/manufacturing/driver')),
        disableOnError: true,
    });

    const { mutateAsync: mutateAsyncPatch } = useHttpMutation('PATCH /user-drivers/:driverId', {
        snackbarMessage: t`Driver updated`,
        onSuccess: (_) => history.push(route('/manufacturing/driver')),
        disableOnError: true,
    });

    const onSubmitPost: SubmitHandler<AddDriverFormInputs> = (data) => {
        const parsedData = createParsedData(data);
        return mutateAsync({ requestBody: parsedData });
    };

    const onSubmitPatch: SubmitHandler<AddDriverFormInputs> = (data) => {
        const parsedData = createParsedData(data);
        return mutateAsyncPatch({
            requestBody: parsedData,
            pathParams: { driverId },
        });
    };
    return { onSubmitPost, onSubmitPatch };
};
