import { getThousandSeparator, NumberSeparator } from '../determineDecimalSeparator';
import { Parser } from '../types';
import { getIntegerRegexString, replaceThousandSeparator } from './parseNumber';

const mpqKeywords = [
    'MPQ',
    'Standard Pack Qty',
    'Standard Pack Quantity',
    'SPQ',
    'Package Qty',
    'Package Quantity',
    'Carton Quantity',
    'Material pack size',
    'Mult',
    // German
    'Verpackungseinheit',
    'VE',
    'VPE',
    'Verpackung s-einheit',
    // French
    'Cdt.',
    // Spanish
    'Unidad del embalaje',
    'Unidad embalaje',
    'Cant. por paquete',
    // Catalan
    'Embalatge',
];
export const mpqKeyRegex = new RegExp(`(?:\\s|^)(?:${mpqKeywords.join('|')})(?:\\s|:|=|\\*|$)`, 'i');

export const createParseMpq = (decimalSeparator: NumberSeparator): Parser => {
    const thousandSeparator = getThousandSeparator(decimalSeparator);
    const mpqKeyValueRegex = new RegExp(mpqKeyRegex.source + '.*?' + getIntegerRegexString(thousandSeparator), 'i');
    return (str: string) => {
        const matchWithKey = mpqKeyValueRegex.exec(str);
        if (matchWithKey) {
            const [, mpq, thousand] = matchWithKey;
            const mpqNumber = parseInt(replaceThousandSeparator(mpq, thousandSeparator));
            if (mpqNumber) {
                return { attr: 'mpq', value: thousand ? mpqNumber * 1000 : mpqNumber };
            }
        }
        return undefined;
    };
};
