import { Flexbox, Text } from '@luminovo/design-system';
import { CombinedDriverType } from '../../selectors';
import { DriverTypeTag } from './DriverTypeTag';

export const DriverLabel = ({ name, type }: { name: string; type: CombinedDriverType }): JSX.Element => {
    return (
        <Flexbox flexDirection="row" gap={8}>
            <DriverTypeTag driverType={type} />
            <Text style={{ whiteSpace: 'nowrap' }}>{name}</Text>
        </Flexbox>
    );
};
