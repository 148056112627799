import { t } from '@lingui/macro';
import { FieldSelect, FormItem } from '@luminovo/design-system';
import { useUniversalImporter } from '../../context';

export function FormItemDecimalSeparator(): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const { decimalSeparator } = state;

    return (
        <FormItem
            label={t`Decimal separator`}
            variant="description-inlined"
            description={[
                `Choose which decimal separator appears in your file`,
                `This affects how numbers like 1.5 or 1,5 are interpreted`,
                'This settings will only be used for cases where we cannot infer the decimal separator from the data.',
            ].join('. ')}
            style={{ maxWidth: 600 }}
        >
            <FieldSelect
                value={decimalSeparator}
                getOptionLabel={(option) => (option === '.' ? 'Period/Dot  "."' : 'Comma  ","')}
                onChange={(value) => dispatch({ type: 'setDecimalSeparator', decimalSeparator: value ?? '.' })}
                options={[',', '.'] as const}
                fullWidth
                disableClearable
            />
        </FormItem>
    );
}
