import { t } from '@lingui/macro';
import { useIsCustomer } from '../../../components/contexts/CurrentUserDetailsContext';

function getSourcedByLabel(isConsigned: boolean, isCustomer: boolean): string {
    if (isConsigned) {
        return isCustomer ? t`Customer` : t`External`;
    } else {
        return isCustomer ? t`EMS` : t`Internal`;
    }
}
export default function useSourcedByLabel(isConsigned: boolean) {
    const isCustomer = useIsCustomer();

    return getSourcedByLabel(isConsigned, isCustomer);
}
