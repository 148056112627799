import { AssemblyResponseDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { useAssembly } from '../assembly/assemblyHandler';
import { useHttpQuery } from '../http/useHttpQuery';

interface UseManufacturingAssemblyDetailsAndAssembly {
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO | undefined;
    assembly: AssemblyResponseDTO | undefined;
    isLoading: boolean;
    isError: boolean;
}

export function useManufacturingAssemblyDetailsAndAssembly(
    manufacturingAssemblyDetailsId: string | undefined,
    enabled: boolean = true,
): UseManufacturingAssemblyDetailsAndAssembly {
    const {
        data: manufacturingAssemblyDetailsData,
        isLoading: isLoadingDetails,
        isError: isErrorDetails,
    } = useHttpQuery(
        'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId',
        {
            pathParams: { manufacturingAssemblyDetailsId: manufacturingAssemblyDetailsId ?? 'should not be called' },
        },
        { enabled },
    );
    const manufacturingAssemblyDetails = manufacturingAssemblyDetailsData?.data;
    const {
        data: assemblyData,
        isLoading: isLoadingAssembly,
        isError: isErrorAssembly,
    } = useAssembly(manufacturingAssemblyDetails?.assembly_id ?? '', {
        enabled: enabled && manufacturingAssemblyDetails?.assembly_id !== undefined,
        with_aggregations: false,
    });

    return {
        manufacturingAssemblyDetails,
        assembly: assemblyData,
        isLoading: isLoadingDetails || isLoadingAssembly,
        isError: isErrorDetails || isErrorAssembly,
    };
}
