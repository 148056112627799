import { assertUnreachable } from '@luminovo/commons';
import { DriverDetailsType, DriverDTO, DriverOriginDTO, UserDriverDetailsDTO } from '@luminovo/http-client';

const selectOriginTagsFromDriverType = (driver: UserDriverDetailsDTO) => {
    const driverType = driver.details.type;
    switch (driverType) {
        case DriverDetailsType.Automatic:
            return driver.origin_tags;
        case DriverDetailsType.Manual:
            return undefined;
        default:
            assertUnreachable(driverType);
    }
};

export const selectDriverOriginTags = (driver: DriverDTO): DriverOriginDTO[] | undefined => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return selectOriginTagsFromDriverType(driver.data);
        case 'System':
            return undefined;
        default:
            assertUnreachable(type);
    }
};
