import { t } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { SubmitHandler } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { CalculationTemplateSelectionForm } from './CalculationTemplateSelectionForm';
import { CreateAssemblyDetailsFormInput } from './types';

export function useCalculationTemplateSelectionDialog({
    assemblyId,
    rfqId,
    onSubmit,
}: {
    assemblyId: string;
    rfqId: string;
    onSubmit: SubmitHandler<CreateAssemblyDetailsFormInput>;
}) {
    const { setDialog, closeDialog } = useDialogContext();

    const dialog = (
        <Dialog open={true} fullWidth maxWidth={'md'} onClose={() => closeDialog()}>
            <DialogTitle title={t`Build calculation`} handleClose={closeDialog} />
            <DialogContent style={{ overflow: 'visible' }}>
                <CalculationTemplateSelectionForm rfqId={rfqId} assemblyId={assemblyId} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
}
