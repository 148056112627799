import { t } from '@lingui/macro';
import { getLocale } from '../i18n';
import { isPresent } from '../typingUtils';
import { getOrCreateNumberFormatter } from './getOrCreateNumberFormat';

const intlOpts: Intl.NumberFormatOptions = {
    style: 'decimal',
};

export function formatDecimal(
    number?: number | null,
    options?: {
        ifAbsent?: string;
        ifNaN?: string;
        suffix?: string;
        maximumFractionDigits?: number;
        minimumFractionDigits?: number;
    },
): string {
    const { ifAbsent = t`Unknown`, ifNaN, suffix = '', maximumFractionDigits, minimumFractionDigits } = options || {};

    if (!isPresent(number)) {
        return ifAbsent;
    }

    if (isNaN(number)) {
        return ifNaN ?? ifAbsent;
    }

    if (!isPresent(number) || isNaN(number)) {
        return ifAbsent;
    }

    const intlOptions = { ...intlOpts, maximumFractionDigits, minimumFractionDigits };
    return `${getOrCreateNumberFormatter(getLocale(), intlOptions).format(number)}${suffix}`;
}
