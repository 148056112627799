/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import {
    formatAmount,
    formatRelativeTime,
    formatToLongDate,
    isPresent,
    isProductionEnvironment,
    throwErrorUnlessProduction,
    transEnum,
} from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    FieldCheckbox,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldNumericControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    AwardScenarioDTO,
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    QuoteRequestLineItemStatus,
    QuoteRequestStatus,
} from '@luminovo/http-client';
import { OpenInBrowser, Refresh } from '@mui/icons-material';
import { Box, CircularProgress, Divider, InputAdornment } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LayoutCard } from '../../../components/LayoutCard';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { getRandomFloat } from '../../../utils/randomizerFunctions';
import { route } from '../../../utils/routes';
import { UrlParams } from '../../../utils/routes/routes';
import { ButtonDeleteQuoteRequest } from '../components/ButtonDeleteQuoteRequest';
import { ButtonDiscardQuoteRequest } from '../components/ButtonDiscardQuoteRequest';
import { ButtonDownloadQuoteRequestExcel } from '../components/ButtonDownloadQuoteRequestExcel';
import { ButtonNegotiationQuoteImporter } from '../components/ButtonNegotiationQuoteImporter';
import { ButtonSendQuoteRequests } from '../components/ButtonSendQuoteRequests';
import { FieldGlobalAwardScenario } from '../components/FieldSelectAwardScenario';
import { MultiProgressPieChart } from '../components/MultiProgressPieChart';
import { NegotiationsLayout } from '../components/NegotiationsLayout';
import { QuoteRequestDetailsPropertyTable } from '../components/QuoteRequestDetailPropertyTable';
import { QuoteRequestStatusChip } from '../components/QuoteRequestStatusChip';
import { TableQuoteRequestLineItems } from '../components/TableQuoteRequestLineItems';
import { TableQuoteRequestLineItemsForm } from '../components/TableQuoteRequestLineItemsForm';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { useQuoteRequest, useQuoteRequestLineItemsByQuoteRequest } from '../hooks/negotiationHandlers';
import { quoteRequestLineItemStatusTranslations } from '../i18n';
import { extractAwaredOfferFromScenario, extractTargetPrice } from '../model/extractors/extractorNegotionsLineItem';
import { formatQuoteRequest } from '../model/formatQuoteRequest';
import { getUnitPriceDiscount } from '../model/getUnitPriceDiscount';
import { monetaryValue } from '../model/monetaryValueMath';
import { QuoteRequestForm, useHandleSubmit } from '../model/quoteRequestForm';

export function QuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = Number(pathParams.quoteRequestId);
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!isPresent(quoteRequest)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <NegotiationsLayout
            negotiationId={negotiationId}
            quoteRequestId={quoteRequestId}
            actions={
                <>
                    <ButtonViewSupplierPortal quoteRequestId={quoteRequestId} />
                    <ButtonDeleteQuoteRequest quoteRequestId={quoteRequestId} />
                    <ButtonDiscardQuoteRequest quoteRequestId={quoteRequestId} />
                    <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
                    <ButtonNegotiationQuoteImporter quoteRequestId={quoteRequestId} />
                    <ButtonSendQuoteRequests quoteRequests={[quoteRequest]} />
                </>
            }
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <Flexbox flexDirection="column" gap={12} sx={{ padding: '20px' }}>
                <Flexbox gap={4}>
                    <Text variant="h1">{formatQuoteRequest(quoteRequest)}</Text>
                    <QuoteRequestStatusChip status={quoteRequest.status} />
                </Flexbox>
                <QuoteRequestFormContainer negotiationId={negotiationId} quoteRequest={quoteRequest} />
            </Flexbox>
        </NegotiationsLayout>
    );
}

function QuoteRequestFormContainer({
    quoteRequest,
    negotiationId,
}: {
    negotiationId: number;
    quoteRequest: QuoteRequestDTO;
}) {
    const { referenceScenario } = useNegotiationsContext();
    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequest.id);

    const defaultValues: QuoteRequestForm | undefined = React.useMemo(() => {
        if (!isPresent(quoteRequestLineItems) || !isPresent(referenceScenario)) {
            return undefined;
        }
        return {
            quoteRequest,
            globalTargetSavings: {
                active: 'fixed',
                fixed: {
                    value: null,
                },
                random: {
                    from: null,
                    to: null,
                },
            },
            showCustomerName: !(quoteRequest.configuration?.hidden_fields.includes('Customer') ?? true),
            showTargetPrice: !(quoteRequest.configuration?.hidden_fields?.includes('TargetPrice') ?? true),
            lineItemTargetPrices: quoteRequestLineItems.map((lineItem) => ({
                quoteRequestLineItemId: lineItem.id,
                targetPrice: formatAmount(extractTargetPrice(lineItem, referenceScenario), 'unit-price'),
            })),
        };
    }, [quoteRequest, referenceScenario, quoteRequestLineItems]);

    if (!isPresent(defaultValues) || !isPresent(quoteRequestLineItems) || !isPresent(referenceScenario)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <FormContainer
            key={quoteRequest.status}
            defaultValues={defaultValues}
            onSubmit={() => throwErrorUnlessProduction(new Error('Should not be called'))}
            UNSAFE_disableStablePropCheck
        >
            <Box sx={{ display: 'grid', gridTemplateColumns: 'minmax(15vw, auto) 1fr', gap: 2, height: '100%' }}>
                <Flexbox flexDirection="column" gap="16px">
                    <LayoutCard>
                        {quoteRequest.status !== QuoteRequestStatus.NotSent &&
                        quoteRequest.status !== QuoteRequestStatus.Discarded ? (
                            <QuoteRequestLineItemsStatusPieChart quoteRequestLineItems={quoteRequestLineItems} />
                        ) : (
                            <QuoteRequestLineItemsNotSent quoteRequestLineItemsLength={quoteRequestLineItems.length} />
                        )}

                        <Divider />

                        <QuoteRequestDetailsPropertyTable quoteRequestLineItems={quoteRequestLineItems} />
                    </LayoutCard>

                    <LayoutCard>
                        <FormItemSentOn />

                        <FormItemSentBy />

                        <FormItemSentTo />

                        <FormItemDueDate />

                        <FormItemRecievedOn />

                        <FormItemNotes />

                        <FormItemOptionalColumns />

                        <FormItemReferenceScenario lineItems={quoteRequestLineItems} negotiationId={negotiationId} />

                        <FormItemExtraTargetSavings lineItems={quoteRequestLineItems} />
                    </LayoutCard>
                </Flexbox>

                <LayoutCard style={{ height: '80vh' }}>
                    {quoteRequest.status === QuoteRequestStatus.NotSent && (
                        <TableQuoteRequestLineItemsForm key={referenceScenario?.id} lineItems={quoteRequestLineItems} />
                    )}
                    {quoteRequest.status !== QuoteRequestStatus.NotSent && (
                        <TableQuoteRequestLineItems lineItems={quoteRequestLineItems} quoteRequest={quoteRequest} />
                    )}
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}

function QuoteRequestLineItemsNotSent({ quoteRequestLineItemsLength }: { quoteRequestLineItemsLength: number }) {
    return (
        <Flexbox flexDirection="column" rowGap={1}>
            <Text variant="body-small" color={colorSystem.neutral[8]}>{t`Line items to be sent`}</Text>
            <Text variant="h4">{quoteRequestLineItemsLength}</Text>
        </Flexbox>
    );
}

function QuoteRequestLineItemsStatusPieChart({
    quoteRequestLineItems,
}: {
    quoteRequestLineItems: QuoteRequestLineItemDTO[];
}) {
    const quoteRequestLineItemStatuses = quoteRequestLineItems.map((li) => li.status);

    const lineItemData = [
        {
            label: transEnum(QuoteRequestLineItemStatus.Sent, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Sent).length,
            color: colorSystem.blue[4],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.Received, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Received).length,
            color: colorSystem.green[3],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.NoBid, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.NoBid).length,
            color: colorSystem.violet[3],
        },
    ];

    if (quoteRequestLineItems.length === 0) {
        return null;
    }
    return <MultiProgressPieChart data={lineItemData} margin="30px" width={120} height={120} />;
}

function useIsReadonly() {
    const { control } = useFormContext<QuoteRequestForm>();
    const quoteRequest = useWatch({ control, name: 'quoteRequest' });
    return quoteRequest.status !== QuoteRequestStatus.NotSent;
}

function FormItemContainer({ label, read, edit }: { label: string; read: React.ReactNode; edit: React.ReactNode }) {
    const content = useIsReadonly() ? read : edit;
    if (!content) {
        return null;
    }
    return <FormItem label={label}>{content}</FormItem>;
}

function FormItemNotes() {
    const { control } = useFormContext<QuoteRequestForm>();
    const notes = useWatch({ control, name: 'quoteRequest.notes' });
    const { isLoading, mutateAsync: submit } = useHandleSubmit();

    return (
        <FormItemContainer
            label={t`Notes`}
            read={
                <Text
                    style={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-block',
                        overflow: 'hidden',
                        maxWidth: '200px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {notes || `-`}
                </Text>
            }
            edit={
                <FieldTextControlled
                    control={control}
                    name="quoteRequest.notes"
                    FieldProps={{
                        disabled: isLoading,
                        placeholder: 'Start typing...',
                        multiline: true,
                        minRows: 2,
                        InputProps: {
                            onBlur: () => submit(),
                        },
                    }}
                />
            }
        />
    );
}

function FormItemRecievedOn() {
    const { control } = useFormContext<QuoteRequestForm>();
    const recived_date = useWatch({ control, name: 'quoteRequest.received_date' });
    const isReadOnly = useIsReadonly();

    if (!isReadOnly) {
        return null;
    }

    return (
        <FormItem label={t`Submitted on`}>
            <Text>{isPresent(recived_date) ? formatToLongDate(recived_date) : '-'}</Text>
        </FormItem>
    );
}

function FormItemSentOn() {
    const { control } = useFormContext<QuoteRequestForm>();
    const sent_date = useWatch({ control, name: 'quoteRequest.sent_date' });

    if (!isPresent(sent_date)) {
        return null;
    }

    return (
        <FormItem label={t`Sent on`}>
            <Text>{formatToLongDate(sent_date)}</Text>
        </FormItem>
    );
}

function FormItemSentBy() {
    const { control } = useFormContext<QuoteRequestForm>();
    const sender = useWatch({ control, name: 'quoteRequest.sent_by' });

    if (!isPresent(sender)) {
        return null;
    }

    return (
        <FormItem label={t`Sent by`}>
            <Text>
                {sender.first_name} {sender.last_name}
            </Text>
        </FormItem>
    );
}

function FormItemSentTo() {
    const { control } = useFormContext<QuoteRequestForm>();
    const sentTo = useWatch({ control, name: 'quoteRequest.sent_to' });

    if (sentTo.length === 0) {
        return null;
    }

    return (
        <FormItem label={t`Sent to`}>
            <Flexbox maxHeight={200} overflow="auto" flexDirection="column" rowGap={1}>
                {sentTo.map((reciepient) => (
                    <Text key={reciepient.id} variant="body">
                        {reciepient.email}
                    </Text>
                ))}
            </Flexbox>
        </FormItem>
    );
}

function FormItemDueDate() {
    const { control, getFieldState } = useFormContext<QuoteRequestForm>();
    const dueDate = useWatch({ control, name: 'quoteRequest.due_date' });
    const relative = dueDate && formatRelativeTime(dueDate);
    const error = getFieldState('quoteRequest.due_date').error;
    const { isLoading, mutateAsync: submit } = useHandleSubmit();

    return (
        <FormItemContainer
            label={t`Due date`}
            read={<Text>{dueDate ? formatToLongDate(dueDate) : '-'}</Text>}
            edit={
                <>
                    <FieldDateControlled
                        control={control}
                        name="quoteRequest.due_date"
                        inFuture
                        minDate={new Date().toISOString()}
                        FieldProps={{
                            disabled: isLoading,
                            placeholder: 'Select due date...',
                            InputProps: {
                                onBlur: () => {
                                    submit();
                                },
                            },
                        }}
                    />
                    <Text>{!isPresent(error) && relative}</Text>
                </>
            }
        />
    );
}

function FormItemOptionalColumns() {
    const { control } = useFormContext<QuoteRequestForm>();
    const { isLoading, mutateAsync: submit } = useHandleSubmit();

    return (
        <FormItemContainer
            label={t`Optional columns`}
            read={undefined}
            edit={
                <>
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled
                            control={control}
                            name="showCustomerName"
                            FieldProps={{
                                disabled: isLoading,
                                onChangeCapture: () => submit(),
                            }}
                        />
                        <Text>Customer name</Text>
                        {isLoading && <CircularProgress size={12} />}
                    </Flexbox>
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled
                            control={control}
                            name="showTargetPrice"
                            FieldProps={{
                                disabled: isLoading,
                                onChangeCapture: () => submit(),
                            }}
                        />
                        <Text>Target price</Text>
                        {isLoading && <CircularProgress size={12} />}
                    </Flexbox>
                </>
            }
        />
    );
}

function FormItemReferenceScenario({
    negotiationId,
    lineItems,
}: {
    negotiationId: number;
    lineItems: QuoteRequestLineItemDTO[];
}) {
    const { referenceScenario } = useNegotiationsContext();
    const { control, setValue } = useFormContext<QuoteRequestForm>();
    const showTargetPrice = useWatch({ control, name: 'showTargetPrice' });
    const { isLoading, mutateAsync: submit } = useHandleSubmit();

    if (!showTargetPrice || !isPresent(referenceScenario)) {
        return null;
    }

    // We recaclucate the discountUnitPrice when we change award scenarios
    const handleScenarioChange = async (referenceScenario: AwardScenarioDTO) => {
        // Update the values
        setValue(
            'lineItemTargetPrices',
            lineItems.map((lineItem) => {
                const referenceUnitPrice = extractAwaredOfferFromScenario(
                    lineItem.negotiation_line_item_id,
                    referenceScenario,
                )?.awarded_solution.unit_price;

                return {
                    quoteRequestLineItemId: lineItem.id,
                    targetPrice: formatAmount(referenceUnitPrice ?? monetaryValue.zero, 'unit-price'),
                };
            }),
            { shouldTouch: true },
        );

        // Then submit the form
        await submit();
    };

    return (
        <FormItemContainer
            label="What should the target price refer to?"
            read={undefined}
            edit={
                <FieldGlobalAwardScenario
                    onChange={handleScenarioChange}
                    type="referenceScenario"
                    negotiationId={negotiationId}
                    disabled={isLoading}
                />
            }
        />
    );
}

function getGlobalTargetSavingsValue(globalTargetSavings: QuoteRequestForm['globalTargetSavings']): number {
    switch (globalTargetSavings.active) {
        case 'fixed':
            return globalTargetSavings.fixed.value ?? 0;
        case 'random':
            return getRandomFloat(globalTargetSavings.random.from ?? 0, globalTargetSavings.random.to ?? 0, 2);
    }
}

function FormItemExtraTargetSavings({ lineItems }: { lineItems: QuoteRequestLineItemDTO[] }) {
    const { control, setValue } = useFormContext<QuoteRequestForm>();
    const { referenceScenario } = useNegotiationsContext();
    const isReadOnly = useIsReadonly();
    const { mutateAsync: handleSubmit } = useHandleSubmit();

    const globalTargetSavings = useWatch({ control, name: 'globalTargetSavings' });
    const showTargetPrice = useWatch({ control, name: 'showTargetPrice' });

    const isFixedAndNoValue = globalTargetSavings.active === 'fixed' && !isPresent(globalTargetSavings.fixed.value);
    const isRandomAndNoValues =
        globalTargetSavings.active === 'random' &&
        (!isPresent(globalTargetSavings.random.from) || !isPresent(globalTargetSavings.random.to));

    const { isLoading, mutateAsync: handleApply } = useMutation({
        mutationFn: async () => {
            if (!isPresent(referenceScenario)) {
                return;
            }

            // FIXME: this is very inefficient and crashes the app sometimes
            setValue(
                'lineItemTargetPrices',
                lineItems.map((lineItem) => {
                    const referenceUnitPrice = extractAwaredOfferFromScenario(
                        lineItem.negotiation_line_item_id,
                        referenceScenario,
                    )?.awarded_solution.unit_price;

                    // Get the discounted unit price
                    const discountedUnitPrice = getUnitPriceDiscount(
                        referenceUnitPrice ?? null,
                        getGlobalTargetSavingsValue(globalTargetSavings),
                    );

                    return {
                        quoteRequestLineItemId: lineItem.id,
                        targetPrice: discountedUnitPrice ?? monetaryValue.zero,
                    };
                }),
            );

            await handleSubmit();

            setValue('globalTargetSavings', {
                active: globalTargetSavings.active,
                fixed: {
                    value: null,
                },
                random: {
                    from: null,
                    to: null,
                },
            });
        },
    });

    if (!showTargetPrice || !isPresent(referenceScenario) || isReadOnly) {
        return null;
    }

    return (
        <FormItem
            label={t`Extra target savings`}
            actions={
                <Flexbox gap="6px">
                    <FieldCheckbox
                        size="small"
                        disabled={isLoading}
                        value={globalTargetSavings.active === 'random'}
                        onChange={(value) => {
                            if (value) {
                                setValue('globalTargetSavings.active', 'random');
                            } else {
                                setValue('globalTargetSavings.active', 'fixed');
                            }
                        }}
                    />
                    <Text variant="body-small">Random</Text>
                </Flexbox>
            }
        >
            {globalTargetSavings.active === 'fixed' && (
                <FieldNumericControlled
                    control={control}
                    name="globalTargetSavings.fixed.value"
                    min={0}
                    max={100}
                    FieldProps={{
                        size: 'small',
                        disabled: isLoading,
                        InputProps: {
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                    }}
                />
            )}
            {globalTargetSavings.active === 'random' && (
                <Flexbox flexDirection="row" gap="8px" alignItems="center">
                    <FieldNumericControlled
                        control={control}
                        name="globalTargetSavings.random.from"
                        min={0}
                        max={100}
                        FieldProps={{
                            size: 'small',
                            disabled: isLoading,
                            style: { width: '130px' },
                            InputProps: {
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                    />
                    <Text>-</Text>
                    <FieldNumericControlled
                        control={control}
                        name="globalTargetSavings.random.to"
                        min={0}
                        max={100}
                        FieldProps={{
                            size: 'small',
                            disabled: isLoading,
                            style: { width: '130px' },
                            InputProps: {
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                    />
                </Flexbox>
            )}

            <Flexbox flexDirection="row" gap="8px" justifyContent="center">
                <PrimaryButton
                    onClick={() => handleApply()}
                    isLoading={isLoading}
                    disabled={isFixedAndNoValue || isRandomAndNoValues}
                    startIcon={<Refresh />}
                >
                    Apply
                </PrimaryButton>
            </Flexbox>
        </FormItem>
    );
}

function ButtonViewSupplierPortal({ quoteRequestId }: { quoteRequestId: number }) {
    if (isProductionEnvironment()) {
        return <></>;
    }

    return (
        <SecondaryButton
            href={route('/supplier-portal/quote-requests/:quoteRequestId', { quoteRequestId })}
            size="medium"
            startIcon={<OpenInBrowser />}
        >
            View supplier portal
        </SecondaryButton>
    );
}
