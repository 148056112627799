import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { CustomComponentPanel } from '../../../modules/PartLibrary/CustomComponentDetails/CustomComponentPanel';
import { CloseDrawerButton, useDrawerContext } from '../../contexts/ModalContext';

export function useCustomComponentDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ componentId, rfqContext }: { componentId: string; rfqContext: RfqContext }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} padding={'12px 24px 12px 16px'} width={'480px'}>
                        <CustomComponentPanel
                            customComponentId={componentId}
                            rfqContext={rfqContext}
                            BackButton={() => <CloseDrawerButton disableMargin={true} />}
                            showOpenCustomCompPageButton={true}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
