import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Message } from '@luminovo/design-system';
import { CustomOptionOfferDTO, SolutionConfigurationDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import React from 'react';

export const UnitOfMeasurementMismatchInfo: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
    solutionConfiguration?: SolutionConfigurationDTO;
}> = ({ offer, solutionConfiguration }): JSX.Element => {
    if (!isPresent(solutionConfiguration)) {
        return <></>;
    }

    const offerUnitOfMeasurement = offer.unit_of_measurement.quantity_unit;
    const designItemUnitOfMeasurement = solutionConfiguration.aggregated_quantity.unit;

    if (offerUnitOfMeasurement.unit === designItemUnitOfMeasurement && offerUnitOfMeasurement.quantity === 1) {
        return <></>;
    }

    return (
        <Message
            variant={'blue'}
            attention={'high'}
            size={'large'}
            title={t`Unit of measurement mismatch`}
            message={t`The unit of measure for this offer is different from the specified unit of measure in the BOM element.`}
        />
    );
};
