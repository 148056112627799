import { MediumContainer } from '@luminovo/design-system';
import { styled } from '@mui/material';

export const TableContainer = styled(MediumContainer)({
    '& .MuiTableCell-head': {
        height: '32px',
    },
    background: 'white',
    minWidth: 0,
    maxWidth: `calc(100vw - 80px)`,
    overflowX: 'auto',
});
