import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { CostResponseDTO } from '@luminovo/http-client';

export const CalculationTypeChip = ({ calculationType }: { calculationType: CostResponseDTO['type'] }): JSX.Element => {
    const type = calculationType;
    switch (type) {
        case 'Calculated':
            return <StatusChip label={t`In progress`} color={'neutral'} />;
        case 'Frozen':
            return <StatusChip label={t`Approved & frozen`} color={'green'} />;
        default:
            assertUnreachable(type);
    }
};
