import { getToken } from '@luminovo/auth';
import { assertPresent } from '@luminovo/commons';
import type { AppRouter } from '@luminovo/gateway';
import { useQueryClient } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import React from 'react';

export const trpc = createTRPCReact<AppRouter>();

export const TrpcProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const queryClient = useQueryClient();
    const trpcClient = trpc.createClient({
        links: [
            httpBatchLink({
                url: getTrpcUrl(),
                headers() {
                    return {
                        Authorization: `Bearer ${getToken()}`,
                    };
                },
            }),
        ],
    });
    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            {children}
        </trpc.Provider>
    );
};

function getTrpcUrl() {
    return assertPresent(process.env.TRPC_URL);
}
