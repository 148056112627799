import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { AssemblyIndustry, SiteType, TaxType } from '@luminovo/http-client';

export const siteTypeTranslations: Record<SiteType, MessageDescriptor> = {
    Manufacturing: defineMessage({ message: 'Manufacturing' }),
    Inventory: defineMessage({ message: 'Inventory' }),
    Sourcing: defineMessage({ message: 'Sourcing' }),
};

export const assemblyIndustryTranslations: Record<AssemblyIndustry, MessageDescriptor> = {
    Aero: defineMessage({ message: 'Aero/Astro' }),
    Auto: defineMessage({ message: 'Automotive' }),
    Construction: defineMessage({ message: 'Construction' }),
    Consumer: defineMessage({ message: 'Consumer' }),
    Defense: defineMessage({ message: 'Defense' }),
    Industrial: defineMessage({ message: 'Industrial' }),
    Medical: defineMessage({ message: 'Medical' }),
    Rail: defineMessage({ message: 'Railway' }),
    Other: defineMessage({ message: 'Other' }),
};

export const taxTypeTranslations: Record<TaxType, MessageDescriptor> = {
    AmusementTax: defineMessage({ message: 'Amusement tax' }),
    CommunicationsTax: defineMessage({ message: 'Communications tax' }),
    GoodsAndServicesTax: defineMessage({ message: 'Goods and services tax' }),
    HarmonizedSalesTax: defineMessage({ message: 'Harmonized sales tax' }),
    IntegratedGoodsAndServicesTax: defineMessage({ message: 'Integrated goods and services tax' }),
    JapaneseConsumptionTax: defineMessage({ message: 'Japanese consumption tax' }),
    ChicagoLeaseTax: defineMessage({ message: 'Chicago lease tax' }),
    ProvincialSalesTax: defineMessage({ message: 'Provincial sales tax' }),
    QuebecSalesTax: defineMessage({ message: 'Quebec sales tax' }),
    RetailSalesTax: defineMessage({ message: 'Retail sales tax' }),
    SalesTax: defineMessage({ message: 'Sales tax' }),
    ValueAddedTax: defineMessage({ message: 'Value-added tax' }),
};
