import { Trans, t } from '@lingui/macro';
import { uniqueBy } from '@luminovo/commons';
import { MenuButton, SecondaryButton } from '@luminovo/design-system';
import { isCustomComponentFull, isOtsComponentFull } from '@luminovo/http-client';
import { LabelPart } from '@luminovo/sourcing-core';
import { Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    usePartOptionsFromSolutionConfigurationId,
    useResolveAssociatedCustomParts,
    useResolveAssociatedOtsParts,
} from '../../../../resources/part/partHandler';
import { route } from '../../../../utils/routes';

export const ButtonAddManualOffer = React.memo(function AddManualOfferButton({
    solutionConfigurationId,
    rfqId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}): JSX.Element {
    const { data: fullParts = [] } = usePartOptionsFromSolutionConfigurationId({ rfqId, solutionConfigurationId });

    const { data: otsParts = [] } = useResolveAssociatedOtsParts({ rfq_id: rfqId, type: 'WithinRfQ' }, fullParts);
    const { data: customParts = [] } = useResolveAssociatedCustomParts({ rfq_id: rfqId, type: 'WithinRfQ' }, fullParts);

    const ipnParts = fullParts.filter((p) => isOtsComponentFull(p));
    const componentCustomParts = fullParts.filter((p) => isCustomComponentFull(p));

    const noOffers =
        otsParts.length === 0 && ipnParts.length === 0 && customParts.length === 0 && componentCustomParts.length === 0;

    if (customParts.length === 1 && componentCustomParts.length === 0) {
        const customPart = customParts[0];
        return (
            <SecondaryButton
                size="medium"
                startIcon={<Add />}
                href={route('/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId', {
                    partId: customPart.id,
                    rfqId,
                })}
            >
                <Trans>Add offer</Trans>
            </SecondaryButton>
        );
    }

    return (
        <MenuButton disabled={noOffers} appearance="primary" size="medium" icon={<Add />} label={t`Add offer`}>
            {uniqueBy(ipnParts, (x) => x.id).map((part) => {
                const { id: partId } = part;
                return (
                    <MenuItem
                        key={part.id}
                        component={Link}
                        to={route(
                            '/rfqs/:rfqId/sourcing/offer/internal-part-number/add/:partId',
                            {
                                rfqId,
                                partId,
                            },
                            { solutionConfigurationId },
                        )}
                    >
                        <LabelPart part={part} />
                    </MenuItem>
                );
            })}
            {uniqueBy(otsParts, (x) => x.id).map((part) => {
                const { id: partId } = part;
                return (
                    <MenuItem
                        key={part.id}
                        component={Link}
                        to={route(
                            '/rfqs/:rfqId/sourcing/offer/off-the-shelf/add/:partId',
                            {
                                rfqId,
                                partId,
                            },
                            { solutionConfigurationId },
                        )}
                    >
                        <LabelPart part={part} />
                    </MenuItem>
                );
            })}
            {uniqueBy(componentCustomParts, (x) => x.id).map((part) => {
                const { id: partId } = part;
                return (
                    <MenuItem
                        key={part.id}
                        component={Link}
                        to={route('/rfqs/:rfqId/sourcing/offer/custom-component/add/:partId', {
                            partId,
                            rfqId,
                        })}
                    >
                        <LabelPart part={part} />
                    </MenuItem>
                );
            })}
            {uniqueBy(customParts, (x) => x.id).map((part) => {
                const { id: partId } = part;
                return (
                    <MenuItem
                        key={part.id}
                        component={Link}
                        to={route('/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId', {
                            partId,
                            rfqId,
                        })}
                    >
                        <LabelPart part={part} />
                    </MenuItem>
                );
            })}
        </MenuButton>
    );
});
