import { colorSystem, Text } from '@luminovo/design-system';
import { MenuItem } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { setAnchorElementAsNullAndStopPropagation } from '../utils/anchor';
import NestedTableMenu from './NestedTableMenu';

interface Item {
    onClick: MouseEventHandler<HTMLElement>;
    type: ItemType;
    label: string | JSX.Element;
    disabled?: boolean;
}

export enum ItemType {
    Normal,
    Delete,
}

interface NestedTableMenuWithItemsProps {
    items: Item[];
    button?: React.ReactNode;
}

//TODO: replace all instances of NestedTableMenu with this more flexible component. https://www.notion.so/luminovo/Refactor-out-the-NestedTableComponent-8b96e6dcac5249baa39c804d38bd9faf
export const NestedTableMenuWithItems: React.FunctionComponent<NestedTableMenuWithItemsProps> = ({
    items,
    button,
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementAsNullAndStopPropagation(event, setAnchorEl);
    }, []);

    const buttonHandleClick = button && <div onClick={handleClick}>{button}</div>;

    return (
        <NestedTableMenu
            open={open}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            button={buttonHandleClick}
        >
            {items.map((item, index) => {
                return (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            item.onClick(event);
                            handleClose(event);
                        }}
                        key={index}
                        style={{ whiteSpace: 'pre-wrap' }}
                        disabled={item.disabled}
                    >
                        <Text
                            variant="h4"
                            style={{
                                color: item.type === ItemType.Delete ? colorSystem.red[6] : colorSystem.neutral[6],
                            }}
                        >
                            {item.label}
                        </Text>
                    </MenuItem>
                );
            })}
        </NestedTableMenu>
    );
};
