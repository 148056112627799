import { assertUnreachable } from '@luminovo/commons';
import { Duration } from '@luminovo/http-client';

//https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#durations
type DurationType = 'human-readable' | 'html-spec-rounded';

export const formatDuration = (duration: Duration, durationType: DurationType = 'human-readable'): string => {
    const totalSeconds = (() => {
        switch (duration.unit) {
            case 'Hours':
                return parseFloat(duration.amount) * 60 * 60;
            case 'Minutes':
                return parseFloat(duration.amount) * 60;
            case 'Seconds':
                return parseFloat(duration.amount);
            default:
                assertUnreachable(duration.unit);
        }
    })();

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);
    //because we are using Math.round we might get 60 seconds, which should get converted to 1 minute.
    if (durationType === 'human-readable') {
        if (seconds === 60) {
            return `${hours}h ${minutes + 1}m 0s`;
        }
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    if (durationType === 'html-spec-rounded') {
        if (seconds === 60) {
            return `PT${hours}H${minutes + 1}M0S`;
        }
        return `PT${hours}H${minutes}M${seconds}S`;
    } else {
        assertUnreachable(durationType);
    }
};
