import { Trans } from '@lingui/macro';
import { Flexbox, SecondaryButton, TertiaryButton, Tooltip } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { Add as AddIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { colorSystem } from '../../../themes';
import { route } from '../../../utils/routes';
import { useCanEditManufacturingDatabase } from '../utils/useCanEditManufacturingDatabase';

interface TemplateListItemProps {
    template: ManufacturingScenarioTemplateDTO;
    handleOnClick: (templateId: string) => void;
    isHighlighted: boolean;
}
const MIN_NUMBER_OF_CHARACTERS_FOR_POPOVER = 10;

const TemplateListItem = ({ template, handleOnClick, isHighlighted }: TemplateListItemProps): JSX.Element => {
    const color = isHighlighted ? 'primary' : 'inherit';

    return (
        <Grid item onClick={() => handleOnClick(template.id)} xs={12} style={{ flexBasis: '0%' }}>
            <Tooltip
                variant={'white'}
                title={<Typography variant={'body1'}>{template.name}</Typography>}
                placement={'bottom'}
                disableHoverListener={template.name.length < MIN_NUMBER_OF_CHARACTERS_FOR_POPOVER}
            >
                <TertiaryButton style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <Typography variant={'h4'} color={color} noWrap>
                        {template.name}
                    </Typography>
                </TertiaryButton>
            </Tooltip>
        </Grid>
    );
};
interface TemplatesMenuProps {
    templates: ManufacturingScenarioTemplateDTO[];
    handleOnAddClick: () => void;
    selectedTemplateId: string | undefined;
}
const ManufacturingScenarioTemplatesColumn = ({
    templates,
    selectedTemplateId,
    handleOnAddClick,
}: TemplatesMenuProps): JSX.Element => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    const history = useHistory();
    return (
        <Flexbox flexDirection={'column'} gap={24} width={'200px'}>
            <Flexbox flexDirection={'column'} gap={8}>
                <Typography variant={'h2'} style={{ color: colorSystem.neutral[8] }}>
                    <Trans>Templates</Trans>
                </Typography>
                {canEditManufacturingDatabase && (
                    <SecondaryButton
                        size="medium"
                        variant="outlined"
                        onClick={handleOnAddClick}
                        startIcon={<AddIcon />}
                    >
                        <Trans>New template</Trans>
                    </SecondaryButton>
                )}
            </Flexbox>
            <Flexbox flexDirection={'column'}>
                {templates.map((template) => (
                    <TemplateListItem
                        key={template.id}
                        template={template}
                        handleOnClick={(templateId) => {
                            history.push(route('/manufacturing/template/:templateId', { templateId }));
                        }}
                        isHighlighted={selectedTemplateId === template.id}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    );
};

export default ManufacturingScenarioTemplatesColumn;
