import { formatDecimal } from '@luminovo/commons';
import { styled } from '@mui/material';
import { forwardRef } from 'react';
import { colorSystem, h5 } from '../../theme';
import { Flexbox } from '../Flexbox';
import { BaseButton, BaseButtonProps } from '../buttons';

type ButtonProps = Omit<BaseButtonProps, 'href'> & {
    children: React.ReactNode;
    selected: boolean;
    count?: number;
};

const DefaultButton = styled(BaseButton)({
    color: colorSystem.neutral[6],
    '&:hover': { backgroung: colorSystem.neutral[0], color: colorSystem.neutral[7] },
    '&:disabled': { color: colorSystem.neutral[4] },
});

export const ButtonGroupItem = forwardRef(function ButtonGroupItem(
    { children, selected, count, ...props }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element {
    const buttonColor = () => {
        if (selected) {
            return {
                background: colorSystem.neutral[1],
                border: colorSystem.neutral[3],
                color: colorSystem.neutral[9],
                countBackground: colorSystem.neutral[3],
            };
        }

        return {
            border: colorSystem.neutral[3],
            countBackground: colorSystem.neutral[2],
        };
    };

    const { background, border, color, countBackground } = buttonColor();

    const label = (
        <Flexbox gap={4} alignItems="center" style={{ color: color }}>
            {children}
            {count !== undefined ? (
                <div
                    style={{
                        background: countBackground,
                        borderRadius: '8px',
                        padding: '0px 4px',
                        height: '16px',
                        alignItems: 'center',
                        ...h5,
                    }}
                >
                    {formatDecimal(count)}
                </div>
            ) : null}
        </Flexbox>
    );

    return (
        <DefaultButton {...props} disableTouchRipple ref={ref} style={{ border: `1px solid ${border}`, background }}>
            {label}
        </DefaultButton>
    );
});
