import { Trans, t } from '@lingui/macro';
import { SecondaryIconButton, TertiaryButton } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { GetApp, PictureAsPdf } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import {
    useExportPanelSpecificationInPDF,
    useExportPcbSpecificationInPDF,
} from '../../../../../resources/export/exportHandler';
import { downloadPcbPDFAnalytic } from '../../../../../resources/pcb/analytics/analytic';

export function ExportSpecificationInPDFButton({
    pcb,
    assemblyId,
    rfqId,
}: {
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
}): JSX.Element {
    const { mutateAsync: exportSpecificationInPDF, isLoading } = useExportPcbSpecificationInPDF(pcb, {
        onSuccess: () => {
            downloadPcbPDFAnalytic({
                pcbId: pcb.id,
                rfqId,
                assemblyId,
            });
        },
    });

    return (
        <Tooltip title={t`Export specification in PDF`} placement="bottom" arrow>
            <SecondaryIconButton size="medium" onClick={() => exportSpecificationInPDF()}>
                {isLoading ? <CircularProgress size={'inherit'} /> : <PictureAsPdf fontSize="inherit" />}
            </SecondaryIconButton>
        </Tooltip>
    );
}

export function ExportPanelInPDFButton({
    pcbId,
    panelId,
    index,
}: {
    pcbId: string;
    panelId: string;
    index: number;
}): JSX.Element {
    const { mutateAsync: exportPanelInPDF, isLoading } = useExportPanelSpecificationInPDF({
        pcbId,
        panelId,
        fileName: `panel-${index}`,
    });

    return (
        <TertiaryButton
            size="small"
            onClick={() => exportPanelInPDF()}
            disabled={isLoading}
            isLoading={isLoading}
            startIcon={<GetApp />}
        >
            <Trans>Download panel specification PDF</Trans>
        </TertiaryButton>
    );
}
