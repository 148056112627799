import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DataTableRow, DataTableRowProps, TertiaryIconButton } from '@luminovo/design-system';
import { DragIndicatorRounded as DragIcon } from '@mui/icons-material';
import { CircularProgress, TableCell } from '@mui/material';

export function SortableDataTableRow<T>(
    dataTableRowProps: DataTableRowProps<T> & {
        rowId: string;
        // Determines the state of the row
        // default - if it is draggable
        // updating - if it was dragged and is currently updated
        // disabled - if another item was dragged and is currently updating
        state: 'default' | 'updating' | 'disabled';
    },
): JSX.Element {
    const { rowId, state, tableState } = dataTableRowProps;
    const { attributes, listeners, transform, transition, isDragging, setNodeRef, setActivatorNodeRef } = useSortable({
        id: rowId,
    });
    const { queries, selectedFilterIds } = tableState;
    const isFilterActive = selectedFilterIds.length > 0 || Object.keys(queries ?? {}).length > 0;
    const columns = [
        {
            id: 'drag-handle',
            label: '',
            width: '10px',
            render: () => {
                return (
                    <TableCell size="small" align="center">
                        <TertiaryIconButton
                            size="medium"
                            ref={setActivatorNodeRef}
                            disabled={isFilterActive || state === 'disabled'}
                            style={{ cursor: 'grab', color: 'inherit', width: '10px' }}
                            {...listeners}
                            {...attributes}
                        >
                            {state === 'updating' ? (
                                <CircularProgress size={18} />
                            ) : (
                                <DragIcon
                                    style={{
                                        fontSize: 16,
                                    }}
                                    color={state === 'disabled' ? 'disabled' : 'inherit'}
                                />
                            )}
                        </TertiaryIconButton>
                    </TableCell>
                );
            },
        },
        ...dataTableRowProps.columns,
    ];

    return (
        <DataTableRow
            {...dataTableRowProps}
            columns={columns}
            style={{
                ...dataTableRowProps.style,
                transition,
                opacity: isDragging ? 0.5 : undefined,
                transform: CSS.Transform.toString(transform),
            }}
            ref={setNodeRef}
        />
    );
}
