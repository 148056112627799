import React from 'react';
import { useHttpQuery } from '../../../../../../resources/http/useHttpQuery';
import { convertResponseBodyToExtractedValues } from './converters';

export const useExtractedPcbSpecification = ({ pcbId }: { pcbId: string }) => {
    const { data, isLoading } = useHttpQuery(
        'GET /pcb-pdf-specification-extraction',
        // eslint-disable-next-line camelcase
        { queryParams: { pcb_id: pcbId } },
    );

    const result = React.useMemo(() => {
        return data ? convertResponseBodyToExtractedValues(data) : undefined;
    }, [data]);

    return {
        data: result,
        isLoading,
    };
};
