import { ViewBoxCoordinateType } from '@luminovo/http-client';
import React from 'react';
import { addPaddingToViewBox } from '../components/SVGPreview/SvgImageRenderer/utils/addPaddingToViewBox';

export const usePaddedViewBox = (viewBox: ViewBoxCoordinateType) => {
    const innerViewBox = addPaddingToViewBox(viewBox, 0.1);
    const { width, height } = innerViewBox;

    const outerViewBox = React.useMemo(
        () => ({
            x: 0,
            y: 0,
            width: width || 10000,
            height: height || 10000,
        }),
        [height, width],
    );

    return {
        outerViewBox,
        innerViewBox,
    };
};
