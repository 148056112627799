import { FontVariant } from '@luminovo/design-system';
import { GenericLabel } from './GenericLabel';

export const CustomerLabel = ({
    customerName,
    customerNumber,
    variant,
}: {
    customerName: string;
    customerNumber: string | undefined | null;
    variant?: FontVariant;
}): JSX.Element => <GenericLabel variant={variant} name={customerName} additionalInfo={customerNumber ?? undefined} />;
