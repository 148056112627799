import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Flexbox, Text } from '@luminovo/design-system';
import {
    ManufacturingScenarioDTO,
    ScenarioCombinationForCalculationDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../../../components/formLayouts/SubmitButton';
import { ScenarioCombination, ScenarioCombinationWithNoOptions } from './ScenarioCombination';
import { useScenarioCombinationForCalculationFormFunction } from './utils/scenarioCombinationForCalculationFormFunctions';
import {
    ScenarioCombinationForCalculationFormInputs,
    createDefaultValues,
} from './utils/scenarioCombinationForCalculationFormUtils';

export const ScenarioCombinationForCalculationForm = ({
    sourcingScenarios,
    manufacturingScenarios,
    existingScenarioCombinations,
    assemblyId,
    assemblyQuantities,
    onSubmitCallback,
}: {
    sourcingScenarios: SourcingScenarioDTO[];
    manufacturingScenarios: ManufacturingScenarioDTO[];
    existingScenarioCombinations: ScenarioCombinationForCalculationDTO[];
    assemblyId: string;
    assemblyQuantities: Record<string, number>;
    onSubmitCallback: () => void;
}) => {
    const isSourcingScenarioLinkedToAManufacturingScenario = (sourcingScenarioId: string) => {
        return manufacturingScenarios.map((ms) => ms.sourcing_scenario).includes(sourcingScenarioId);
    };
    const manufacturingScenariosWithNoSourcingScenario = manufacturingScenarios.filter(
        (ms) => ms.sourcing_scenario === null,
    );

    const { onSubmit } = useScenarioCombinationForCalculationFormFunction(assemblyId, onSubmitCallback);

    /* A sourcing scenario has no options when:
        1. The sourcing scenario is not linked to a manufacturing scenario AND
        2. There are no manufacturing scenarios which are not linked to a sourcing scenario 
    */
    const sourcingScenariosWithNoOptions =
        manufacturingScenariosWithNoSourcingScenario.length > 0
            ? []
            : sourcingScenarios.filter(
                  (sourcingScenario) => !isSourcingScenarioLinkedToAManufacturingScenario(sourcingScenario.id),
              );
    const sourcingScenariosWithOptions =
        manufacturingScenariosWithNoSourcingScenario.length === 0
            ? sourcingScenarios.filter((sourcingScenario) =>
                  isSourcingScenarioLinkedToAManufacturingScenario(sourcingScenario.id),
              )
            : sourcingScenarios;
    const defaultValues = createDefaultValues({
        existingScenarioCombinations,
        // we only want to show and submit sourcing scenarios which are linked
        // to a manufacturing scenario. Otherwise we show a warning, and do not
        // include them in the form values
        sourcingScenarios: sourcingScenariosWithOptions,
        manufacturingScenarios,
    });

    return (
        <FormContainer defaultValues={defaultValues} mode="onChange" onSubmit={onSubmit}>
            <Text>
                <Trans>Decide on manufacturing scenarios and batch sizes you want to include in the quotation</Trans>
            </Text>
            <ScenarioCombinationForCalculationFormInner
                sourcingScenariosWithOptions={sourcingScenariosWithOptions}
                sourcingScenariosWithNoOptions={sourcingScenariosWithNoOptions}
                manufacturingScenarios={manufacturingScenarios}
                assemblyId={assemblyId}
                assemblyQuantities={assemblyQuantities}
            />
        </FormContainer>
    );
};

const ScenarioCombinationForCalculationFormInner = ({
    sourcingScenariosWithOptions,
    sourcingScenariosWithNoOptions,
    manufacturingScenarios,
    assemblyId,
    assemblyQuantities,
}: {
    sourcingScenariosWithOptions: SourcingScenarioDTO[];
    sourcingScenariosWithNoOptions: SourcingScenarioDTO[];
    manufacturingScenarios: ManufacturingScenarioDTO[];
    assemblyId: string;
    assemblyQuantities: Record<string, number>;
}): JSX.Element => {
    const { control } = useFormContext<ScenarioCombinationForCalculationFormInputs>();

    const form = useWatch({ control, name: `form` });
    const isValid = form.filter((value) => isPresent(value.manufacturingScenarioId)).length > 0;

    const { fields } = useFieldArray({
        control,
        name: 'form',
    });

    return (
        <>
            {fields.map(({ id, sourcingScenarioId }, index) => {
                return (
                    <ScenarioCombination
                        key={id}
                        index={index}
                        sourcingScenarioId={sourcingScenarioId}
                        assemblyQuantities={assemblyQuantities}
                        sourcingScenarios={sourcingScenariosWithOptions}
                        manufacturingScenarios={manufacturingScenarios}
                    />
                );
            })}
            {sourcingScenariosWithNoOptions.map((s) => {
                return (
                    <ScenarioCombinationWithNoOptions
                        sourcingScenarioId={s.id}
                        assemblyId={assemblyId}
                        assemblyQuantities={assemblyQuantities}
                        sourcingScenarios={sourcingScenariosWithNoOptions}
                        key={s.id}
                    />
                );
            })}
            <Flexbox marginTop={'32px'} justifyContent="end">
                <SubmitButton disabled={!isValid} />
            </Flexbox>
        </>
    );
};
