import { isPresent } from '@luminovo/commons';
import {
    ManufacturingScenarioDTO,
    ScenarioCombinationForCalculationDTO,
    ScenarioCombinationForCalculationPatchDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { assertPresent } from '../../../../../../../../utils/assertPresent';

interface IndividualScenarioCombinationForCalculationInputs {
    sourcingScenarioId: string;
    manufacturingScenarioId: string | undefined;
    batchSizes: string[];
}
export interface ScenarioCombinationForCalculationFormInputs {
    form: IndividualScenarioCombinationForCalculationInputs[];
}

export const getManufacturingScenarioOptions = (
    sourcingScenarioId: string,
    manufacturingScenarios: ManufacturingScenarioDTO[],
): ManufacturingScenarioDTO[] => {
    const manufacturingScenariosLinkedToSourcingScenario = manufacturingScenarios.filter(
        (manufacturingScenario) => manufacturingScenario.sourcing_scenario === sourcingScenarioId,
    );

    const manufacturingScenariosNotLinkedToAnySourcingScenario = manufacturingScenarios.filter(
        (manufacturingScenario) => manufacturingScenario.sourcing_scenario === null,
    );

    if (manufacturingScenariosLinkedToSourcingScenario.length === 0) {
        return manufacturingScenariosNotLinkedToAnySourcingScenario;
    } else {
        return [
            ...manufacturingScenariosLinkedToSourcingScenario,
            ...manufacturingScenariosNotLinkedToAnySourcingScenario,
        ];
    }
};

const getAllManufacturingScenariosOfSourcingScenario = (
    manufacturingScenarios: ManufacturingScenarioDTO[],
    sourcingScenarioId: string,
) => {
    const manufacturingScenariosLinkedToSourcingScenario = manufacturingScenarios.filter(
        (manufacturingScenario) => sourcingScenarioId === manufacturingScenario.sourcing_scenario,
    );
    const manufacturingScenariosWithNoSourcingScenario = manufacturingScenarios.filter(
        (ms) => ms.sourcing_scenario === null,
    );
    return [...manufacturingScenariosLinkedToSourcingScenario, ...manufacturingScenariosWithNoSourcingScenario];
};

const getDefaultManufacturingScenario = (
    manufacturingScenarios: ManufacturingScenarioDTO[],
    sourcingScenarioId: string,
): ManufacturingScenarioDTO | undefined => {
    const allManufacturingScenarios = getAllManufacturingScenariosOfSourcingScenario(
        manufacturingScenarios,
        sourcingScenarioId,
    );
    if (allManufacturingScenarios.length === 1) {
        return allManufacturingScenarios[0];
    } else {
        return undefined;
    }
};

const getDefaultBatchSize = (defaultManufacturingScenario: ManufacturingScenarioDTO): string[] => {
    return defaultManufacturingScenario.batch_sizes.length === 1
        ? [defaultManufacturingScenario.batch_sizes.toString()]
        : [];
};

export const createDefaultValues = ({
    sourcingScenarios,
    existingScenarioCombinations,
    manufacturingScenarios,
}: {
    existingScenarioCombinations: ScenarioCombinationForCalculationDTO[];
    sourcingScenarios: SourcingScenarioDTO[];
    manufacturingScenarios: ManufacturingScenarioDTO[];
}): ScenarioCombinationForCalculationFormInputs => {
    return {
        form: sourcingScenarios.map((sourcingScenario) => {
            const existingCombination = existingScenarioCombinations.find(
                (sc) => sc.sourcing_scenario === sourcingScenario.id,
            );
            if (existingCombination !== undefined) {
                const manufacturingScenario = manufacturingScenarios.find(
                    (ms) => ms.id === existingCombination.manufacturing_scenario,
                );
                if (manufacturingScenario === undefined) {
                    throw new Error(
                        `Manufacturing scenario with id ${existingCombination.manufacturing_scenario} does not exist`,
                    );
                }

                return {
                    sourcingScenarioId: sourcingScenario.id,
                    manufacturingScenarioId: existingCombination.manufacturing_scenario,
                    batchSizes: existingCombination.batch_sizes
                        .filter((batchSize) => manufacturingScenario.batch_sizes.includes(batchSize))
                        .map((batchSize) => batchSize.toString()),
                };
            } else {
                // If there are already existing scenario combinations when the user is editing
                // their submission, we don't want to populate non-existing values with defaults.
                // That way the user sees which sourcing scenarios are already included in the calculation.
                if (existingScenarioCombinations.length > 0) {
                    return {
                        sourcingScenarioId: sourcingScenario.id,
                        manufacturingScenarioId: undefined,
                        batchSizes: [],
                    };
                }
                const defaultManufacturingScenario = getDefaultManufacturingScenario(
                    manufacturingScenarios,
                    sourcingScenario.id,
                );

                return {
                    sourcingScenarioId: sourcingScenario.id,
                    manufacturingScenarioId:
                        defaultManufacturingScenario !== undefined ? defaultManufacturingScenario.id : undefined,
                    batchSizes:
                        defaultManufacturingScenario !== undefined
                            ? getDefaultBatchSize(defaultManufacturingScenario)
                            : [],
                };
            }
        }),
    };
};

/* eslint-disable camelcase */
export const createParsedData = (
    formValues: ScenarioCombinationForCalculationFormInputs,
): ScenarioCombinationForCalculationPatchDTO[] => {
    return formValues.form
        .filter((value) => isPresent(value.manufacturingScenarioId))
        .map((data) => ({
            batch_sizes: data.batchSizes.map((batchSize) => parseInt(batchSize)),
            manufacturing_scenario_id: assertPresent(data.manufacturingScenarioId),
            sourcing_scenario_id: data.sourcingScenarioId,
        }));
};
/* eslint-enable camelcase */
