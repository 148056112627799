import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    http,
    ManufacturingScenarioTemplateDTO,
    ManufacturingScenarioTemplatePatchDTO,
    ManufacturingScenarioTemplatePostDTO,
} from '@luminovo/http-client';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { route } from '../../utils/routes';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';

export function useTemplateHasInactiveActivitiesOrExpenses(template: ManufacturingScenarioTemplateDTO) {
    const { data: activeActivities } = useHttpQuery('GET /activities', { queryParams: { active: true } });
    const { data: activeExpenses } = useHttpQuery('GET /expenses', { queryParams: { active: true } });

    const activeActivityIds = activeActivities?.data.map((activity) => activity.id) ?? [];
    const activeExpenseIds = activeExpenses?.data.map((activity) => activity.id) ?? [];
    const hasInactiveEntity =
        template.ordered_activities.some((id) => !activeActivityIds.includes(id.id)) ||
        template.ordered_expenses.some((id) => !activeExpenseIds.includes(id.id));

    return { hasInactiveEntity };
}

const invalidateTemplatesQueries = async (queryClient: QueryClient, templateId: string) => {
    await Promise.all([
        queryClient.invalidateQueries(httpQueryKey('GET /manufacturing-scenario-templates')),
        queryClient.invalidateQueries(
            httpQueryKey('GET /manufacturing-scenario-templates/:templateId', { pathParams: { templateId } }),
        ),
    ]);
};

interface GetQueryParams {
    activity_ids?: string[] | undefined;
    expense_ids?: string[] | undefined;
}

export const useManufacturingScenarioTemplates = (enabled: boolean = true, queryParams: GetQueryParams = {}) => {
    return useHttpQuery('GET /manufacturing-scenario-templates', { queryParams }, { enabled });
};

export const useManufacturingScenarioTemplatesOfExpenses = (expendsIds: string[], enabled: boolean = true) =>
    useManufacturingScenarioTemplates(enabled, { expense_ids: expendsIds }); // eslint-disable-line camelcase

export const useManufacturingScenarioTemplatesOfActivities = (activityIds: string[], enabled: boolean = true) =>
    useManufacturingScenarioTemplates(enabled, { activity_ids: activityIds }); // eslint-disable-line camelcase

export const useMutationCreateTemplate = (onSuccess?: () => void) => {
    const token = getToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (input: ManufacturingScenarioTemplatePostDTO) =>
            http('POST /manufacturing-scenario-templates', { requestBody: input }, token),
        onSuccess: async (newTemplate) => {
            await queryClient.invalidateQueries(httpQueryKey('GET /manufacturing-scenario-templates'));
            enqueueSnackbar(t`Template created`, { variant: 'success' });
            history.push(route(`/manufacturing/template/:templateId`, { templateId: newTemplate.data.id }));
            onSuccess?.();
        },
        onError: debugErrorHandler,
    });
};

export const useMutationUpdateTemplate = ({
    templateId,
    onSuccess,
    disableSnackbar = false,
}: {
    templateId: string;
    onSuccess?: () => void;
    disableSnackbar?: boolean;
}) => {
    const token = getToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (update: ManufacturingScenarioTemplatePatchDTO) =>
            http(
                'PATCH /manufacturing-scenario-templates/:templateId',
                { requestBody: update, pathParams: { templateId } },
                token,
            ),
        onSuccess: async () => {
            await invalidateTemplatesQueries(queryClient, templateId);
            if (!disableSnackbar) {
                enqueueSnackbar(t`Template updated`, { variant: 'info' });
            }

            onSuccess?.();
        },
        onError: debugErrorHandler,
    });
};

export const useMutationDeleteTemplate = (templateId: string) => {
    const token = getToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    return useMutation({
        mutationFn: () =>
            http('DELETE /manufacturing-scenario-templates/:templateId', { pathParams: { templateId } }, token),
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /manufacturing-scenario-templates'));
            history.push(route(`/manufacturing/template`));
            enqueueSnackbar(t`Template deleted`, { variant: 'info' });
        },
        onError: debugErrorHandler,
    });
};
