import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Column, DataTable, Row, useDataTableState } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { StyledEngineProvider, TableCell, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { LoadingText } from '../../../components/Spinners';
import { themeForBomTable } from '../../../components/table/tableStyles';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useSourcingScenariosOfRfq } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';

const RfqNumber = ({ rfqId }: { rfqId: string }) => {
    const { data: rfq, isLoading } = useRfQ(rfqId);
    return (
        <>
            {isLoading && <LoadingText />}
            {/* FIXME: is the ems internal number the same as the old rfq number.  */}
            {isPresent(rfq) && <Typography> {rfq.ems_internal_number} </Typography>}
        </>
    );
};

const renderRfqNumber = ({ data: row }: Row<SourcingScenarioTableData>) => {
    return (
        <TableCell>
            <RfqNumber rfqId={row.rfqId} />
        </TableCell>
    );
};

const renderSourcingScenario = ({ data: row }: Row<SourcingScenarioTableData>) => {
    return (
        <TableCell>
            <Typography>{row.sourcingScenarioName}</Typography>
        </TableCell>
    );
};

const renderQuantity = ({ data: row }: Row<SourcingScenarioTableData>) => {
    return (
        <TableCell>
            <Typography> {row.quantity} </Typography>
        </TableCell>
    );
};

interface AssemblySourcingScenariosTableProps {
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyId: string;
}

interface SourcingScenarioTableData {
    rfqId: string;
    sourcingScenarioName: string;
    quantity: string;
}

export const AssemblySourcingScenariosTable: React.FunctionComponent<AssemblySourcingScenariosTableProps> = ({
    sourcingScenarios,
    assemblyId,
}: AssemblySourcingScenariosTableProps): JSX.Element => {
    const data = sourcingScenarios.map((sourcingScenario) => ({
        rfqId: sourcingScenario.rfq,
        sourcingScenarioName: sourcingScenario.name,
        quantity:
            sourcingScenario.assembly_quantities.items.find((i) => i.assembly === assemblyId)?.quantity.toString() ||
            'Quantity not found',
    }));

    const columns: Column<SourcingScenarioTableData>[] = [
        { label: t`RFQ`, id: 'rfqNumber', width: 200, render: renderRfqNumber },
        {
            label: t`Sourcing scenario`.toLocaleUpperCase(),
            id: 'unitPrice',
            width: 400,
            render: renderSourcingScenario,
        },
        { label: t`ORDER SIZE`, id: 'sourcingScenarioName', width: 200, render: renderQuantity },
    ];

    const tableState = useDataTableState({
        columns,
        items: data,
        persistenceId: 'assembly-sourcing-scenarios',
        paginationOptions: { showPagination: false, rowsPerPageOptions: [25] },
    });
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeForBomTable}>
                <DataTable size={'medium'} tableState={tableState} key={'assembly-sourcing-scenarios'} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export function useIsAssemblyQuantityOfAssemblyValid({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) {
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const quantities = sourcingScenarios?.map((sourcingScenario) => sourcingScenario.assembly_quantities);

    if (!isPresent(quantities)) {
        return { hasNonZeroAssemblyQuantity: false, nonZeroSourcingScenarios: undefined };
    }

    const isAssemblyTopLevel = quantities?.flatMap((q) => q.items.map((q) => q.assembly)).includes(assemblyId) || false;

    if (!isAssemblyTopLevel) {
        return { hasNonZeroAssemblyQuantity: false, nonZeroSourcingScenarios: undefined };
    }

    const hasNonZeroAssemblyQuantity = quantities
        .flatMap((q) => q.items)
        .filter((aq) => aq.assembly === assemblyId)
        .some((q) => q.quantity !== 0);

    const nonZeroSourcingScenarios = sourcingScenarios?.filter((sourcingScenario) => {
        return sourcingScenario.assembly_quantities.items
            .filter((aq) => aq.assembly === assemblyId)
            .map((a) => a.quantity)
            .some((q) => q > 0);
    });

    return { hasNonZeroAssemblyQuantity, nonZeroSourcingScenarios };
}
