import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { RichTextEditorProps } from './RichTextEditorTypes';
import './main.css';

export function RichTextEditor(props: RichTextEditorProps): JSX.Element {
    return (
        <React.Suspense
            fallback={
                <CenteredLayout minHeight={'80px'}>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            <LazyEditor {...props} />
        </React.Suspense>
    );
}

const LazyEditor = React.lazy(async () => {
    const { Editor } = await import('./components/Editor');
    return { default: Editor };
});
