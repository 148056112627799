import { Chip, Flexbox } from '@luminovo/design-system';
import { SolutionTag } from '@luminovo/http-client';
import { extractSolutionTagChips } from '../../extractors';
import { Solution } from '../../types';

export function OfferChips({
    solution,
    fallbackSolutionTags = [],
}: {
    solution?: Pick<Solution, 'solutionTags'>;
    fallbackSolutionTags?: Solution['solutionTags'];
}): JSX.Element {
    const chips = extractSolutionTagChips(solution ?? { solutionTags: fallbackSolutionTags });

    return (
        <Flexbox gap={4} flexWrap={'wrap'}>
            {chips.map((c, idx) => {
                if (c.solutionTag === SolutionTag.ManualOffer) {
                    return null;
                }
                return <Chip key={idx} color={c.color} label={c.label} />;
            })}
        </Flexbox>
    );
}
