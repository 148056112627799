import { PartCategoryDTO } from '@luminovo/http-client';

export type CategorySelectActions =
    | { type: 'removeCategory'; categoriesToBeRemoved: Set<number> }
    | {
          type: 'selectPartCategory';
          categoryBlock: PartCategoryDTO;
      }
    | { type: 'onQueryChange'; query: string };

export interface CategorySelectState {
    query: string;
    inputRef: React.RefObject<HTMLInputElement>;
    categoryBlocks: PartCategoryDTO[];
    prevBlock?: PartCategoryDTO;
}

export const reducer = (state: CategorySelectState, action: CategorySelectActions): CategorySelectState => {
    const { query, categoryBlocks } = state;
    switch (action.type) {
        case 'removeCategory': {
            return {
                ...state,
                categoryBlocks: categoryBlocks.filter((b) => {
                    return !action.categoriesToBeRemoved.has(b.id);
                }),
            };
        }
        case 'selectPartCategory': {
            const categories = [...categoryBlocks, action.categoryBlock].sort((a, b) => a.id - b.id);
            return {
                ...state,
                categoryBlocks: categories,
            };
        }
        case 'onQueryChange': {
            return { ...state, query: query };
        }
    }
};
