import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, FieldRadioControlled, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { formatCurrency } from '../../../utils/currencyUtils';
import { OrderFormState, OrderSelectionOptionWithId } from '../types';

export const OrderCard = ({ order }: { order: OrderSelectionOptionWithId }): JSX.Element => {
    const { control } = useFormContext<OrderFormState>();

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateRows: 'auto auto',
                gridTemplateColumns: 'auto 1fr 1fr 1fr',
                columnGap: '12px',
                padding: '12px 0',
            }}
        >
            <Box
                sx={{
                    gridRowStart: 1,
                    gridColStart: 1,
                }}
            >
                <FieldRadioControlled
                    control={control}
                    name={'selectedOrderOptionId'}
                    FieldProps={{
                        fieldValue: order.id,
                        size: 'small',
                    }}
                />
            </Box>

            <Box
                sx={{
                    gridRowStart: 2,
                    gridColStart: 1,
                }}
            ></Box>

            <Box
                sx={{
                    gridRowStart: 1,
                    gridColStart: 2,
                }}
            >
                <Text variant="body-semibold">{order.order_size}</Text>
            </Box>
            <Box
                sx={{
                    gridRowStart: 2,
                    gridColStart: 2,
                }}
            >
                <Text variant="body-small" color={colorSystem.neutral[7]}>
                    <Trans>Order size</Trans>
                </Text>
            </Box>

            {isPresent(order.lead_time_in_days) && (
                <>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColStart: 3,
                        }}
                    >
                        <Text variant="body">
                            {order.lead_time_in_days} <Trans>calendar days</Trans>
                        </Text>
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 2,
                            gridColStart: 3,
                        }}
                    >
                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                            <Trans>Estimated lead time</Trans>
                        </Text>
                    </Box>
                </>
            )}

            <Box
                sx={{
                    gridRowStart: 1,
                    gridColStart: 4,
                    textAlign: 'right',
                }}
            >
                <Text variant="body-semibold">
                    {formatCurrency(
                        order.price.data.total_price_before_tax.total_cost.amount,
                        order.price.data.total_price_before_tax.total_cost.currency,
                    )}
                </Text>
            </Box>
            <Box
                sx={{
                    gridRowStart: 2,
                    gridColStart: 4,
                    textAlign: 'right',
                }}
            >
                <Text variant="body-small" color={colorSystem.neutral[7]}>
                    {formatCurrency(
                        order.price.data.total_price_before_tax.unit_cost.amount,
                        order.price.data.total_price_before_tax.unit_cost.currency,
                    )}{' '}
                    <Trans>per board</Trans>
                </Text>
            </Box>
        </Box>
    );
};
