/* eslint-disable spellcheck/spell-checker */
import { ViewBoxCoordinateType } from '@luminovo/http-client';
import React from 'react';

export function validateAndReturnSvgElement(svgString: string) {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(svgString, 'image/svg+xml');
    const errorNode = parsedDocument.querySelector('parsererror');

    // Run checks to make sure the SVG string is valid
    if (errorNode) {
        throw new Error(`Error parsing SVG string: ${svgString}`);
    }

    if (!(parsedDocument.documentElement instanceof SVGSVGElement)) {
        throw new Error(`SVG string is not a type of SVGSVGElement: ${svgString}`);
    }

    return parsedDocument.documentElement;
}

/**
 * This function parses a string representation of an SVG into a JSX element.
 *
 * @param svgString string representation of an SVG
 * @returns JSX element representation, width and height of the SVG string
 */
export function getSvgComponent(svgString: string) {
    const svgElement = validateAndReturnSvgElement(svgString);

    // Convert the SVG DOM element to JSX
    const SvgComponent = React.memo(
        ({ viewBox, svgProps }: { viewBox: ViewBoxCoordinateType; svgProps?: React.SVGProps<SVGSVGElement> }) => {
            const ref = React.useRef<SVGSVGElement>(null);
            const svgElementRef = React.useRef<SVGSVGElement>(svgElement);

            const { height, width } = viewBox;

            React.useEffect(() => {
                if (ref.current) {
                    ref.current.appendChild(svgElementRef.current);
                }
            }, []);

            return <svg ref={ref} width={width} preserveAspectRatio="xMinYMin meet" height={height} {...svgProps} />;
        },
    );

    return {
        SvgComponent,
    };
}

/**
 * This function gets the dimensions of an SVG element.
 * It checks if the SVG element has a viewBox attribute and if not, it uses the width, height, x and y attributes.
 * @param svgElement the SVG element to get the dimensions from
 * @returns the width, height, x and y of the SVG element
 */
const getElementDimensions = (
    svgElement: SVGSVGElement & HTMLElement,
): {
    width: number;
    height: number;
    x: number;
    y: number;
} => {
    if (
        svgElement.viewBox &&
        svgElement.viewBox.baseVal &&
        svgElement.viewBox.baseVal.x !== 0 &&
        svgElement.viewBox.baseVal.y !== 0
    ) {
        return {
            x: svgElement.viewBox.baseVal.x,
            y: svgElement.viewBox.baseVal.y,
            width: svgElement.viewBox.baseVal.width,
            height: svgElement.viewBox.baseVal.height,
        };
    }

    return {
        width: svgElement.width ? svgElement.width.baseVal.value : 1000,
        height: svgElement.height ? svgElement.height.baseVal.value : 1000,
        x: svgElement.x ? svgElement.x.baseVal.value : 0,
        y: svgElement.y ? svgElement.y.baseVal.value : 0,
    };
};

export function getSvgDimension(svgString: string) {
    const svgElement = validateAndReturnSvgElement(svgString);
    const componentDimensions = getElementDimensions(svgElement);
    return componentDimensions;
}
