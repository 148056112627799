import { colorSystem } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';
import React from 'react';
import { usePdfViewerState } from '../../hooks/usePdfViewerState';
import { Viewbox } from '../../model/Viewbox';
import { Arrow } from '../Arrow';

export function Suggestions({ pageNumber, viewbox }: { pageNumber: number; viewbox: Viewbox }): JSX.Element {
    const [state, dispatch] = usePdfViewerState();
    const suggestions = state.regs.findLinks({ pageNumber, type: 'suggestion' });

    const result = suggestions.map((suggestion, i) => {
        const originalBox = suggestion.to.box;
        const box = originalBox.applyViewbox(viewbox);
        return (
            <React.Fragment key={i}>
                <Arrow
                    onClick={() => dispatch({ type: 'removeSuggestion', regionIds: [suggestion.to.id] })}
                    viewbox={viewbox}
                    from={suggestion.from.box}
                    to={originalBox}
                    strokeDasharray="5, 5"
                    stroke={colorSystem.blue[6]}
                    strokeWidth={2}
                />
                <rect
                    stroke={colorSystem.primary[6]}
                    rx={2}
                    strokeDasharray="5, 5"
                    strokeWidth={2}
                    x={box.x}
                    y={box.y}
                    fill={'transparent'}
                    width={box.width}
                    height={box.height}
                />
                <Tooltip title="Remove">
                    <circle
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            dispatch({
                                type: 'removeSuggestion',
                                regionIds: [suggestion.to.id],
                            })
                        }
                        cx={box.x + box.width}
                        cy={box.y + box.height}
                        r={4}
                        fill={colorSystem.red[7]}
                    />
                </Tooltip>
            </React.Fragment>
        );
    });

    return <>{result}</>;
}
