import { t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { CustomerDTO, RfqDTO, ValidFor } from '@luminovo/http-client';

export function formatValidFor(
    option: ValidFor,
    additionalInformation?: { rfq: RfqDTO | undefined; customer: CustomerDTO | undefined },
): string {
    if (option === ValidFor.ThisCustomer) {
        return isPresent(additionalInformation?.customer)
            ? t`This customer (${additionalInformation?.customer.name})`
            : t`This customer`;
    }
    if (option === ValidFor.ThisRfQ) {
        return isPresent(additionalInformation?.rfq) ? t`This RfQ (${additionalInformation?.rfq.name})` : t`This RfQ`;
    }
    if (option === ValidFor.EveryCustomer) {
        return t`Every customer`;
    }
    assertUnreachable(option);
}
