/* eslint-disable camelcase */

import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    TotalCostOfOwnershipInputDTORuntype,
    TotalCostOfOwnershipRuleDTORuntype,
} from './totalCostOfOwnershipRulesBackendTypes';

export const totalCostOfOwnershipRulesEndpoints = {
    'GET /total-cost-of-ownership-rules': endpoint({
        description: 'Fetches all total cost of ownership rules',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(TotalCostOfOwnershipRuleDTORuntype),
    }),

    'POST /total-cost-of-ownership-rules': endpoint({
        description: 'Creates a new total cost of ownership rule',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TotalCostOfOwnershipInputDTORuntype,
        responseBody: r.Unknown,
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),

    'PATCH /total-cost-of-ownership-rules/:id': endpoint({
        description: 'Updates a single total cost of ownership rule by ID',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: TotalCostOfOwnershipInputDTORuntype,
        responseBody: r.Unknown,
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),

    'DELETE /total-cost-of-ownership-rules/:id': endpoint({
        description: 'Deletes a single total cost of ownership rule by ID',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),
};
