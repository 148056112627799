/* eslint-disable camelcase */
import * as r from 'runtypes';
import { DriverStatusRuntype } from '../activityConfiguration';
import { AssemblyTypeRuntype } from '../assembly';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { DriverRuntype } from '../driver';
import { SourcingScenarioDTORuntype } from '../sourcingScenario';

export const ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype = r.Record({
    assembly_id: r.String,
});

export const ManufacturingAssemblyDetailsNotesRuntype = r.Record({
    notes: r.String.nullable(),
});

export const ManufacturingAssemblyDetailsRuntype = ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype.extend({
    notes: r.String.nullable(),
    id: r.String,
});

const ConfigurationCountRuntype = r.Record({
    scenario_name: r.String,
    entity_name: r.String, // can be the activity or expense name
    driver_count: r.String,
});

export interface ConfigurationCount extends r.Static<typeof ConfigurationCountRuntype> {}

const StatusRuntypes = r.Union(r.Literal('Ok'), r.Literal('Warning'), r.Literal('Error'));
export type StatusDTO = r.Static<typeof StatusRuntypes>;

const DriverCountRuntype = r.Record({
    assembly_wide_count: r.String.nullable(),
    automatic_count: r.String.nullable(),
    is_linked_to_bom: r.Boolean,
    status: DriverStatusRuntype,
    assembly_to_configuration_driver_count_mismatch: r.Array(ConfigurationCountRuntype),
});
export type DriverCountDTO = r.Static<typeof DriverCountRuntype>;

const BasicManufacturingAssemblyDriverCountRuntype = r.Record({
    type: r.Literal('Basic'),
    data: DriverCountRuntype,
});
export type BasicManufacturingAssemblyDriverCountDTO = r.Static<typeof BasicManufacturingAssemblyDriverCountRuntype>;

const SourcingScenarioResponseRuntype = r.Dictionary(DriverCountRuntype, r.String);
export type SourcingScenarioResponseDTO = r.Static<typeof SourcingScenarioResponseRuntype>;

const PerScenarioManufacturingAssemblyDriverCountRuntype = r.Record({
    type: r.Literal('PerScenario'),
    data: r.Record({
        sourcing_scenarios_response: SourcingScenarioResponseRuntype,
        assembly_wide_count: r.String.nullable(),
        extra_statuses: r.Array(DriverStatusRuntype),
    }),
});
export type PerScenarioManufacturingAssemblyDriverCountDTO = r.Static<
    typeof PerScenarioManufacturingAssemblyDriverCountRuntype
>;

const ManufacturingAssemblyDriverCountRuntype = BasicManufacturingAssemblyDriverCountRuntype.Or(
    PerScenarioManufacturingAssemblyDriverCountRuntype,
);
export type ManufacturingAssemblyDriverCountDTO = r.Static<typeof ManufacturingAssemblyDriverCountRuntype>;

export const DriversOfManufacturingDetailsRuntype = r.Record({
    driver: DriverRuntype,
    driver_count: ManufacturingAssemblyDriverCountRuntype,
    is_sourcing_driver: r.Boolean,
});

const CostOverviewRuntype = r.Record({
    effective_cost: MonetaryValueBackendRuntype,
    project_cost: MonetaryValueBackendRuntype,
});

const ManufacturingScenarioOverviewRuntype = r.Record({
    id: r.String,
    name: r.String,
    notes: r.String.nullable(),
    sites: r.Array(r.String),
    sourcing_scenario: SourcingScenarioDTORuntype.nullable(),
    batch_sizes: r.Array(r.Number),
    activity_count: r.Number,
    expense_count: r.Number,
    costs_overview: r.Dictionary(CostOverviewRuntype, r.Number),
    driver_status_overview: r.Record({
        ok_count: r.Number,
        warning_count: r.Number,
        error_count: r.Number,
    }),
});

export type ManufacturingScenarioOverviewDTO = r.Static<typeof ManufacturingScenarioOverviewRuntype>;

const AssemblyOverviewRuntype = r.Record({
    id: r.String,
    designator: r.String,
    assembly_type: AssemblyTypeRuntype,
});

export const ManufacturingAssemblyDetailsOverviewRuntype = r.Record({
    manufacturing_assembly_details: ManufacturingAssemblyDetailsRuntype,
    assembly: AssemblyOverviewRuntype,
    manufacturing_scenarios: r.Array(ManufacturingScenarioOverviewRuntype),
    drivers: r.Array(DriversOfManufacturingDetailsRuntype),
});

export type ManufacturingAssemblyDetailsOverviewDTO = r.Static<typeof ManufacturingAssemblyDetailsOverviewRuntype>;

export type DriversOfManufacturingDetailsDTO = r.Static<typeof DriversOfManufacturingDetailsRuntype>;

export type ManufacturingAssemblyDetailsPostDTO = r.Static<typeof ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype>;

export type ManufacturingAssemblyDetailsPatchDTO = r.Static<typeof ManufacturingAssemblyDetailsNotesRuntype>;

export interface ManufacturingAssemblyDetailsDTO extends r.Static<typeof ManufacturingAssemblyDetailsRuntype> {}
