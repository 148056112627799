import { Flexbox, SecondaryIconButton, Text, colorSystem } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { Remove } from '@mui/icons-material';
import { SitesWithIconFetching } from '../../../../../../components/Site';
import { ActivityCountChipWrapper, ExpenseCountChipWrapper } from '../../CountChipWrappers';
import { NotesLabel } from '../Notes';

type SelectedTemplate = {
    id: string;
    name: string;
    sites: string[];
    notes: string | undefined;
    activityIds: string[];
    expenseIds: string[];
};

const ManufacturingScenarioTemplateSelected = ({
    template,
    onRemoveTemplate,
}: {
    template: SelectedTemplate;
    onRemoveTemplate: () => void;
}) => {
    return (
        <Flexbox
            flexDirection="row"
            justifyContent={'space-between'}
            padding="16px"
            gap="24px"
            alignItems={'center'}
            style={{
                borderRadius: '8px',
                border: `1px solid ${colorSystem.neutral[3]}`,
                background: colorSystem.neutral.white,
            }}
        >
            <SecondaryIconButton
                onClick={() => {
                    onRemoveTemplate();
                }}
                size="small"
                children={<Remove fontSize="inherit" />}
            />
            <Text
                style={{
                    minWidth: '200px',
                    maxWidth: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                variant="h4"
            >
                {template.name}
            </Text>
            <div style={{ minWidth: '200px', maxWidth: '200px' }}>
                <SitesWithIconFetching sites={template.sites} noWrap />
            </div>
            <div
                style={{
                    minWidth: '240px',
                    maxWidth: '240px',
                }}
            >
                {template.notes && template.notes.length > 0 && <NotesLabel notes={template.notes} />}
            </div>
            <Flexbox gap="8px">
                <ActivityCountChipWrapper activityIds={template.activityIds} />
                <ExpenseCountChipWrapper expenseIds={template.expenseIds} />
            </Flexbox>
        </Flexbox>
    );
};

export const TemplateSelectionPane = ({
    manufacturingScenarioTemplates,
    onRemoveTemplateIndex,
}: {
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
    onRemoveTemplateIndex: (index: number) => void;
}): JSX.Element => {
    return (
        <Flexbox flexDirection="column" padding="32px" gap="8px">
            {manufacturingScenarioTemplates.map((template, i) => {
                return (
                    <ManufacturingScenarioTemplateSelected
                        key={`${template.id}-${i}`}
                        template={{
                            id: template.id,
                            name: template.name,
                            sites: template.sites,
                            notes: template.notes ?? undefined,
                            activityIds: template.ordered_activities.map((a) => a.id),
                            expenseIds: template.ordered_expenses.map((e) => e.id),
                        }}
                        onRemoveTemplate={() => onRemoveTemplateIndex(i)}
                    />
                );
            })}
        </Flexbox>
    );
};
