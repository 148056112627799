export type HighlightableString = {
    fragment: string;
    isHighlighted: boolean;
}[];

/**
 * Joins fragments that are highlighted or not
 *
 * Example:
 *
 * Given the following input
 * ```
 * [
 *      {isHighlighted: true, fragment: "a"},
 *      {isHighlighted: true, fragment: "b"},
 *      {isHighlighted: false, fragment: "c"},
 *      {isHighlighted: false, fragment: "d"},
 * ]
 *
 * ```
 *
 * will return:
 *
 * ```
 * [
 *      {isHighlighted: true, fragment: "ab"},
 *      {isHighlighted: false, fragment: "cd"},
 * ]
 * ```
 */
export function joinFragments(fragments: HighlightableString, minFragmentLength: number = 3): HighlightableString {
    const result: HighlightableString = [];
    for (const fragment of fragments) {
        if (result.length === 0) {
            result.push(fragment);
        } else {
            const lastFragment = result[result.length - 1];
            if (lastFragment.isHighlighted !== fragment.isHighlighted) {
                result.push(fragment);
            } else {
                result.pop();
                result.push({
                    ...lastFragment,
                    fragment: lastFragment.fragment + fragment.fragment,
                });
            }
        }
    }
    return result.map((frag) => {
        // if the highlighted fragment is too little, then un-highlight it
        if (frag.fragment.length <= minFragmentLength) {
            return { ...frag, isHighlighted: false };
        } else {
            return frag;
        }
    });
}
