import { Currency } from '@luminovo/commons';
import { MonetaryValueBackend, OtsFullPart, Packaging, QuantityUnit } from '@luminovo/http-client';
import { LeadTime } from '../../PdfViewer/model/RegionNetwork/parsers/LeadTime';
import { AttributeOf, Region } from '../../PdfViewer/model/RegionNetwork/types';

export type TableQuotationOfferItem = {
    part: {
        attribute?: AttributeOf<'part'>;
        region?: Region;
        value: React.ReactNode;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    unitPrice: {
        attribute?: AttributeOf<'unitPrice'>;
        currency: Currency;
        value?: MonetaryValueBackend;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    moq: {
        attribute?: AttributeOf<'moq'>;
        value?: number;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    mpq: {
        attribute?: AttributeOf<'mpq'>;
        value?: number;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    stock: {
        attribute?: AttributeOf<'stock'>;
        value?: number;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    unit: {
        attribute?: AttributeOf<'unit'>;
        value?: QuantityUnit;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    standardFactoryLeadTime: {
        attribute?: AttributeOf<'standardFactoryLeadTime'>;
        value?: LeadTime;
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    packaging: {
        attribute?: AttributeOf<'packaging'>;
        value?: Packaging | 'none';
        onSelect: (rowDelta?: number) => void;
        isSelected?: boolean;
    };
    onRemoveRow: () => void;
    onAddEmptyRow: () => void;
    onDuplicateRow: () => void;
    expectedParts: OtsFullPart[];
};

export type VisibleAttributes =
    | 'part'
    | 'stock'
    | 'unitPrice'
    | 'unit'
    | 'standardFactoryLeadTime'
    | 'moq'
    | 'mpq'
    | 'packaging';

export const attributeOrder: Record<VisibleAttributes, number> = {
    part: 1,
    unitPrice: 2,
    moq: 3,
    mpq: 4,
    stock: 5,
    standardFactoryLeadTime: 6,
    packaging: 7,
    unit: 8,
};
