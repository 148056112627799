import { Packaging } from '@luminovo/http-client';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

const parser = parse.packaging();

export const parsePackaging: Parser<Packaging, Opts> = async function (
    cells,
    _,
    field,
): Promise<ParseResult<Packaging>> {
    const alternatives = Object.values(Packaging).map((value) => ({ id: value, label: value }));

    return applyParser({
        cells,
        parser,
        field,
        alternatives,
        formatValue: (x) => x.toString(),
    });
};
