import { BaseParser, Parser } from './Parser';

interface StringProps {
    minLength?: number;
    maxLength?: number;
}

class StringParser extends BaseParser<string> {
    constructor(props: StringProps) {
        super((input) => {
            if (props.minLength && input.length < props.minLength) {
                return { ok: false, expected: `A string with a minimum length of ${props.minLength}` };
            }

            if (props.maxLength && input.length > props.maxLength) {
                return { ok: true, value: input.slice(0, props.maxLength), rest: input.slice(props.maxLength) };
            }

            return { ok: true, value: input, rest: '' };
        });
    }
}

export function string(props: StringProps): Parser<string> {
    return new StringParser(props);
}
