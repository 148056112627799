import React from 'react';
import { IndexingFunction, SearchOptions } from './types';

/**
 * This document explains how search works in the DataTable
 * https://www.notion.so/luminovo/How-Search-works-in-the-DataTable-6eadb4e87df14fb783a212130462bc7b
 */

export function useGlobalSearch<TRowData, TContext>(
    searchOptions?: SearchOptions<TRowData, TContext>,
): {
    onIndexSearchText: IndexingFunction<TRowData>;
    search: (rows: TRowData[], query: string) => TRowData[];
} {
    const { idExtractor } = searchOptions ?? {};
    const searchIndexRef = React.useRef(new Map<string, string[]>());
    const onIndexSearchText: IndexingFunction<TRowData> = React.useCallback(
        (item: TRowData, contents: string[]) => {
            if (!idExtractor) {
                // search is only possible if an idExtractor has been specified.
                return;
            }
            if (!searchIndexRef.current) {
                return;
            }

            searchIndexRef.current.set(idExtractor(item), contents);
        },
        [searchIndexRef, idExtractor],
    );

    const search = React.useMemo(
        () =>
            (rows: TRowData[], query: string): TRowData[] => {
                const index = searchIndexRef.current;
                if (!index || !idExtractor || query.length === 0) {
                    return rows;
                }

                return rows.filter((item) => {
                    const texts = index.get(idExtractor(item)) ?? [];
                    return texts.some((text) => matchesSearch(text, query));
                });
            },
        [searchIndexRef, idExtractor],
    );

    return {
        onIndexSearchText,
        search,
    };
}

export function matchesSearch(text: string, query: string): boolean {
    return text.toLowerCase().includes(query.toLowerCase());
}

/**
 * Returns the TDs that are immediate children of the given row element (e.g. TR)
 */
function findDirectColumnElements(rowElement: HTMLTableRowElement): Element[] {
    // Note that we cannot do querySelectorAll('td') as that might
    // match nested TDs.

    return Array.from(rowElement.children).filter((child) => {
        return child.tagName === 'TD';
    });
}

export function indexTableRowElement(tableRowElement: HTMLTableRowElement): string[] {
    return findDirectColumnElements(tableRowElement)
        .map((tdElement) => tdElement.textContent ?? '')
        .filter((s) => s.length > 0);
}
