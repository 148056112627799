import { Currency, MonetaryValueBackend } from '@luminovo/http-client';
import { Parser } from '../parsers/Parser';

import { either } from '../combinators/either';
import { sequence } from '../combinators/sequence';
import { number } from '../parsers/number';
import { whitespaces } from '../parsers/regex';
import { currency } from './currency';

export interface MonetaryValueProps {
    /* The default currency to use if none is provided */
    defaultCurrency?: Currency;
    decimalSeparator: '.' | ',';
}

/**
 * Parses a monetary value in the form of `currency amount` or `amount currency`.
 * If no currency is provided, the default currency is used.
 */
export const monetaryValue = ({
    decimalSeparator,
    defaultCurrency,
}: MonetaryValueProps): Parser<MonetaryValueBackend> => {
    const numParser = number({ decimalSeparator });
    const currencyThenDecimal = sequence(currency(), whitespaces, numParser).map(([currency, _, amount]) => {
        return {
            currency,
            amount: String(amount),
        };
    });

    const decimalThenCurrency = sequence(numParser, whitespaces, currency()).map(([amount, _, currency]) => {
        return {
            currency,
            amount: String(amount),
        };
    });

    const decimalWithDefaultCurrency = numParser.map((amount) => {
        if (defaultCurrency === undefined) {
            throw new Error('No default currency provided');
        }

        return {
            currency: defaultCurrency,
            amount: String(amount),
        };
    });

    return either(currencyThenDecimal, decimalThenCurrency, decimalWithDefaultCurrency);
};
