import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import { PartAlternativeSimilarityEnum } from '@luminovo/http-client';

export const SimilarityTag = ({ similarity }: { similarity: PartAlternativeSimilarityEnum }): JSX.Element => {
    switch (similarity) {
        case PartAlternativeSimilarityEnum.FormFitFunction:
            return <Tag color="green" attention="low" label={<Trans>Form Fit Function</Trans>} />;
        case PartAlternativeSimilarityEnum.Functional:
            return <Tag color="violet" attention="low" label={<Trans>Functional</Trans>} />;
        case PartAlternativeSimilarityEnum.Recommended:
            return <Tag color="blue" attention="low" label={<Trans>Recommended</Trans>} />;
        default:
            assertUnreachable(similarity);
    }
};
