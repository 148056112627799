import { Trans, t } from '@lingui/macro';
import { formatToIso8601Date, isPresent } from '@luminovo/commons';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FieldDate,
    Flexbox,
    FormItem,
    PrimaryButton,
    Text,
} from '@luminovo/design-system';
import { useState } from 'react';
import { useDialogContext } from '../../components/contexts/ModalContext';

const DemandFilterDialog = ({
    initialStartDate,
    initialEndDate,
    closeDialog,
    submit,
}: {
    initialStartDate: Date;
    initialEndDate: Date;
    closeDialog: () => void;
    submit: (start: Date, end: Date) => void;
}) => {
    const [startDate, setStartDate] = useState(formatToIso8601Date(initialStartDate));
    const [endDate, setEndDate] = useState(formatToIso8601Date(initialEndDate));

    const onChangeStartDate = (newStartDate: string | null) => isPresent(newStartDate) && setStartDate(newStartDate);
    const onChangeEndDate = (newEndDate: string | null) => isPresent(newEndDate) && setEndDate(newEndDate);
    return (
        <Dialog open onClose={closeDialog} title={t`Filter demand`} maxWidth="xs">
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Text>
                    <Trans>Show the demand summary for the following timeframe:</Trans>
                </Text>
                <Flexbox gap={16}>
                    <FormItem label={t`Start date`}>
                        <FieldDate value={startDate} onChange={onChangeStartDate} />
                    </FormItem>
                    <FormItem label={t`End date`}>
                        <FieldDate value={endDate} onChange={onChangeEndDate} />
                    </FormItem>
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    onClick={() => {
                        submit(new Date(startDate), new Date(endDate));
                        closeDialog();
                    }}
                >
                    <Trans>Save</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export const useFilterDemandDialog = ({
    startDate,
    endDate,
    submit,
}: {
    startDate: Date;
    endDate: Date;
    submit: (start: Date, end: Date) => void;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    return () =>
        setDialog(
            <DemandFilterDialog
                initialStartDate={startDate}
                initialEndDate={endDate}
                closeDialog={closeDialog}
                submit={submit}
            />,
        );
};
