// fhur: copied from export { MarkerSeverity } from 'monaco-editor/esm/vs/editor/editor.api';
// We re-export these types as importing { MarkerSeverity } from 'monaco-editor/esm/vs/editor/editor.api'
// would otherwise bundle the entire monaco into the main bundle.
// The risk of doing this is that in a future release, monaco could change the shape and value of the enum
// and that would result in incorrect marker severity issues.
// If this does happen in the future, the fix is simple: just copy over the new MarkerSeverity definition.
export enum MarkerSeverity {
    Hint = 1,
    Info = 2,
    Warning = 4,
    Error = 8,
}
