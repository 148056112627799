import { t } from '@lingui/macro';
import { assertUnreachable, formatRelativeTime, isPresent } from '@luminovo/commons';
import { FieldDate, FieldSelect, FieldText, FormItem } from '@luminovo/design-system';
import { CustomerDTO, RfqDTO, ValidFor } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { formatValidFor } from '../../../formatters/formatValidFor';
import { FieldSelectCurrency } from '../../fields/FieldSelectCurrency';
import { useOnHover } from '../../tables/PdfViewerTable/hooks/useOnHover';
import { usePdfViewerState } from '../hooks/usePdfViewerState';
import { getAttributeColor } from '../model/RegionNetwork/attributes/getAttributeColor';
import { PdfExtractionResults } from '../model/RegionNetwork/composeExtractionResults';
import { AttributeOf } from '../model/RegionNetwork/types';
import { IntroMessage } from './IntroMessage';
import { scrollToRegions } from './PolygonView';

export function PdfViewerForm({
    extractionResults,
    rfq,
    customer,
}: {
    extractionResults: PdfExtractionResults;
    rfq?: RfqDTO;
    customer?: CustomerDTO;
}): JSX.Element {
    return (
        <>
            <IntroMessage />
            <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                columnGap="16px"
                rowGap={'16px'}
                padding="0 16px"
            >
                <FormItemOfferNumber extractionResults={extractionResults} />
                <FormItemCurrency extractionResults={extractionResults} />
                <FormItemValidUntil extractionResults={extractionResults} />
                {rfq && <FormItemValidFor rfq={rfq} customer={customer} extractionResults={extractionResults} />}
            </Box>
        </>
    );
}

function FormItemCurrency({ extractionResults }: { extractionResults: PdfExtractionResults }) {
    const currency = extractionResults.currency;
    const [state, dispatch] = usePdfViewerState();
    const onHoverHandlers = useOnHoverToExtractedValue(currency);
    const attribute = extractionResults.currency?.value;

    const updateCurrency = (newCurrency: AttributeOf<'currency'>['value']) => {
        dispatch({
            type: 'addAttributes',
            regionId: state.regs.findRegions({})[0].id,
            attrs: [
                {
                    attr: 'currency',
                    value: newCurrency,
                    confidence: Infinity,
                    origin: 'manual',
                },
            ],
        });
    };
    return (
        <FormItem label={t`Currency`}>
            <FieldSelectCurrency
                style={{
                    background: getAttributeColor({
                        attributeType: 'currency',
                        attribute,
                    }),
                }}
                value={attribute?.value ?? null}
                onChange={(newCurrency) => {
                    if (!newCurrency) {
                        return;
                    }
                    updateCurrency(newCurrency);
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' && attribute) {
                        e.preventDefault();
                        updateCurrency(attribute.value);
                    }
                }}
                {...onHoverHandlers}
            />
        </FormItem>
    );
}

function FormItemOfferNumber({ extractionResults }: { extractionResults: PdfExtractionResults }) {
    const [state, dispatch] = usePdfViewerState();
    const onHoverHandlers = useOnHoverToExtractedValue(extractionResults.offerNumber);
    const attribute = extractionResults.offerNumber?.value;

    const updateOfferNumber = (newOfferNumber: AttributeOf<'offerNumber'>['value']) => {
        dispatch({
            type: 'addAttributes',
            regionId: state.regs.findRegions({})[0].id,
            attrs: [
                {
                    attr: 'offerNumber',
                    value: newOfferNumber,
                    origin: 'manual',
                    confidence: Infinity,
                },
            ],
        });
    };

    return (
        <FormItem label={t`Offer number`}>
            <FieldText
                style={{
                    background: getAttributeColor({
                        attributeType: 'offerNumber',
                        attribute,
                    }),
                }}
                placeholder={t`Offer number`}
                value={attribute?.value ?? null}
                onChange={(newInvoiceId) => {
                    if (!newInvoiceId) {
                        return;
                    }
                    updateOfferNumber(newInvoiceId);
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' && attribute) {
                        e.preventDefault();
                        updateOfferNumber(attribute.value);
                    }
                }}
                {...onHoverHandlers}
            />
        </FormItem>
    );
}

function FormItemValidUntil({ extractionResults }: { extractionResults: PdfExtractionResults }) {
    const [state, dispatch] = usePdfViewerState();
    const attribute = extractionResults.validUntil?.value;

    const onHoverHandlers = useOnHoverToExtractedValue(extractionResults.validUntil);

    function updateValidFor(newValue: AttributeOf<'dueDate'>['value']) {
        dispatch({
            type: 'addAttributes',
            regionId: state.regs.findRegions({})[0].id,
            attrs: [
                {
                    attr: 'dueDate',
                    value: newValue,
                    confidence: Infinity,
                    origin: 'manual',
                },
            ],
        });
    }

    return (
        <FormItem label={t`Valid until`} description={extractionResults.validUntil?.explanation}>
            <FieldDate
                InputProps={{
                    style: {
                        background: getAttributeColor({ attributeType: 'dueDate', attribute }),
                    },
                }}
                placeholder={t`Valid until`}
                value={attribute?.value ?? null}
                onChange={(validUntil) => {
                    if (!validUntil) {
                        return;
                    }
                    updateValidFor(validUntil);
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' && attribute) {
                        e.preventDefault();
                        updateValidFor(attribute?.value);
                    }
                }}
                {...onHoverHandlers}
                helperText={attribute && formatRelativeTime(attribute.value)}
            />
        </FormItem>
    );
}

function FormItemValidFor({
    extractionResults,
    rfq,
    customer,
}: {
    extractionResults: PdfExtractionResults;
    rfq?: RfqDTO;
    customer?: CustomerDTO;
}) {
    const [state, dispatch] = usePdfViewerState();
    const attribute = extractionResults.validFor.value;

    function updateValidFor(newValue: AttributeOf<'validFor'>['value']) {
        dispatch({
            type: 'addAttributes',
            regionId: state.regs.findRegions({})[0].id,
            attrs: [
                {
                    attr: 'validFor',
                    value: newValue,
                    confidence: Infinity,
                    origin: 'manual',
                },
            ],
        });
    }

    const context = (validFor: ValidFor) => {
        if (validFor === ValidFor.EveryCustomer) {
            return undefined;
        }
        if (validFor === ValidFor.ThisRfQ) {
            return rfq?.ems_internal_number ?? rfq?.name;
        }
        if (validFor === ValidFor.ThisCustomer) {
            return customer?.name;
        }
        assertUnreachable(validFor);
    };

    return (
        <FormItem label={t`Valid for`}>
            <FieldSelect
                style={{
                    background: getAttributeColor({ attributeType: 'validFor', attribute }),
                }}
                placeholder={t`Valid for`}
                value={attribute.value}
                getOptionLabel={(value) => [formatValidFor(value), context(value)].filter(isPresent).join(': ')}
                options={Object.values(ValidFor)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        updateValidFor(attribute.value);
                    }
                }}
                onChange={(newValue) => {
                    if (!newValue) {
                        return;
                    }
                    updateValidFor(newValue);
                }}
            />
        </FormItem>
    );
}

function useOnHoverToExtractedValue(
    extracted:
        | PdfExtractionResults['validUntil']
        | PdfExtractionResults['offerNumber']
        | PdfExtractionResults['currency'],
) {
    const [, dispatch] = usePdfViewerState();

    return useOnHover(() => {
        if (!extracted) {
            return;
        }
        const region = scrollToRegions(extracted.extractedFrom);
        dispatch({
            type: 'setMode',
            mode: {
                type: 'inspect',
                pageNumber: region?.pageNumber ?? 1,
                selectedRegionIds: [region?.id].filter(isPresent),
                attribute: 'dueDate',
            },
        });
    });
}
