import { ApprovalStatus, PartSpecification } from '@luminovo/http-client';
import { PartSpecificationTypes } from '../resources/part/PartSpecificationTypes';
import { assertUnreachable } from './typingUtils';

export function getPartIds(
    spec: PartSpecification | null | undefined,
    options: { filterByStatus: ApprovalStatus | false },
): string[] {
    return Array.from(getPartIdsSet(spec, options)).sort();
}

export function getPartIdsSet(
    spec: PartSpecification | null | undefined,
    options: { filterByStatus: ApprovalStatus | false },
): Set<string> {
    const { filterByStatus } = options;

    const emptySet = new Set<string>();
    if (!spec) {
        return emptySet;
    }

    if (spec.type === PartSpecificationTypes.Custom) {
        const partOptions = spec?.data ?? [];
        return new Set(
            partOptions
                .filter((option) => filterByStatus === false || option.approval_status === filterByStatus)
                .map((option) => option.part.data),
        );
    }

    if (spec.type === PartSpecificationTypes.OffTheShelf) {
        return new Set(
            spec.data.part_options
                .filter((option) => filterByStatus === false || option.approval_status === filterByStatus)
                .map((option) => option.part.data),
        );
    }

    assertUnreachable(spec);
}
