import { t } from '@lingui/macro';
import { FieldController, FieldText, FormItem } from '@luminovo/design-system';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>
    extends UseControllerProps<TFieldValues, TName> {}

export function EmsInternalNumberFormItem<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    name,
    control,
}: Props<TFieldValues, TName>): JSX.Element {
    return (
        <FormItem label={t`RfQ number`}>
            <FieldController
                name={name}
                control={control}
                FieldProps={{ type: 'text' }}
                validate={validateEmsInternalNumber}
                Field={FieldText}
            />
        </FormItem>
    );
}

const validateEmsInternalNumber = (number: unknown): string | undefined => {
    if (number === undefined) {
        return;
    }
    if (typeof number === 'string') {
        if (number.length > 50) {
            return t`Must have at most 50 characters`;
        }
    }
};
