/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetSilicaLocaleCurrencyDTO = r.Static<typeof AvnetSilicaLocaleCurrencyDTORuntype>;
export const AvnetSilicaLocaleCurrencyDTORuntype = r.Union(
    r.Literal('EUR'),
    r.Literal('USD'),
    r.Literal('GBP'),
    r.Literal('JPY'),
);

export interface AvnetSilicaCredentialsDTO extends r.Static<typeof AvnetSilicaCredentialsDTORuntype> {}
export const AvnetSilicaCredentialsDTORuntype = r.Union(
    r.Record({
        username: r.String,
        password: r.String,
        currency: AvnetSilicaLocaleCurrencyDTORuntype,
    }),
);

export interface AvnetSilicaCredentialsInputDTO extends r.Static<typeof AvnetSilicaCredentialsInputDTORuntype> {}
export const AvnetSilicaCredentialsInputDTORuntype = r.Record({
    username: r.String.optional(),
    password: r.String.optional(),
    currency: AvnetSilicaLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetSilicaResponseBodyDTO extends r.Static<typeof AvnetSilicaResponseBodyDTORuntype> {}
export const AvnetSilicaResponseBodyDTORuntype = r.Record({
    data: AvnetSilicaCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
