import { t } from '@lingui/macro';
import { StatusChip, StatusChipDefaultContainer } from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierDTO } from '@luminovo/http-client';
import { Tooltip, styled } from '@mui/material';
import { formatRegionAsName, formatSupplierAndStockLocationDTO, formatSupplierDTO } from '../../formatters';

export function SupplierChip({
    isApproved,
    isPreferred,
    supplier,
}: {
    isApproved: boolean;
    isPreferred: boolean;
    supplier: SupplierDTO;
}): JSX.Element {
    const message = () => {
        if (isPreferred) {
            return t`Preferred supplier`;
        }
        if (isApproved) {
            return t`Approved supplier`;
        }
        return t`Supplier not approved`;
    };
    const color = isPreferred || isApproved ? 'green' : 'red';

    return (
        <Tooltip
            title={
                <>
                    {supplier.name}
                    <br />
                    {message()}
                </>
            }
        >
            <span>
                <StatusChip
                    overrides={{ Container }}
                    color={color}
                    label={formatSupplierDTO(supplier)}
                    enableHoverEffect={true}
                />
            </span>
        </Tooltip>
    );
}

export function SupplierAndStockLocationChip({
    isApproved,
    isPreferred,
    supplier,
}: {
    isApproved: boolean;
    isPreferred: boolean;
    supplier: SupplierAndStockLocationDTO;
}): JSX.Element {
    const message = () => {
        if (isPreferred) {
            return t`Preferred supplier`;
        }
        if (isApproved) {
            return t`Approved supplier`;
        }
        return t`Supplier not approved`;
    };
    const color = isPreferred || isApproved ? 'green' : 'red';

    return (
        <Tooltip
            title={
                <>
                    {supplier.supplier.name}
                    <br />
                    {formatRegionAsName(supplier.stock_location)}
                    <br />
                    {message()}
                </>
            }
        >
            <span>
                <StatusChip
                    overrides={{ Container }}
                    color={color}
                    label={formatSupplierAndStockLocationDTO(supplier)}
                    enableHoverEffect={true}
                />
            </span>
        </Tooltip>
    );
}

const Container = styled(StatusChipDefaultContainer)({
    cursor: 'inherit',
    '& span': {
        maxWidth: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});
