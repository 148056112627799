import { t } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { FieldValues, Path } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldNumeric, FieldNumericProps } from './FieldNumeric';

// eslint-disable-next-line import/no-unused-modules
export interface FieldNumericControlledProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<FieldControllerProps<FieldNumericProps, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the input field is required.
     */
    required?: boolean;
    /**
     * The minimum (inclusive) value that this input can hold.
     *
     * @default -Infinity
     */
    min?: number;

    /**
     * The maximum (inclusive) value that this input can hold.
     *
     * @default Infinity
     */
    max?: number;

    /**
     * The exclusive minimum value that this input can hold.
     */
    exclusiveMin?: number;

    /**
     * The exclusive maximum value that this input can hold.
     */
    exclusiveMax?: number;

    /**
     * When set to true, the input value must be a finite real number.
     *
     * @default false
     */
    isFinite?: boolean;

    /**
     * @default false
     */
    isInteger?: boolean;
}

export function FieldNumericControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
    required,
    min,
    max,
    exclusiveMin,
    exclusiveMax,
    isFinite,
    isInteger,
    validate,
    ...props
}: FieldNumericControlledProps<TFieldValues, TName>): JSX.Element {
    return (
        <FieldController
            Field={FieldNumeric}
            validate={createValidator({
                required,
                min,
                max,
                exclusiveMin,
                exclusiveMax,
                isFinite,
                isInteger,
                validate,
            })}
            {...props}
        />
    );
}

function createValidator({
    required,
    min,
    max,
    exclusiveMin,
    exclusiveMax,
    isFinite,
    isInteger,
    validate,
}: Pick<
    FieldNumericControlledProps,
    'required' | 'min' | 'max' | 'exclusiveMin' | 'exclusiveMax' | 'isFinite' | 'isInteger' | 'validate' | 'required'
>) {
    return (x: unknown): string | undefined => {
        if (required && !isPresent(x)) {
            return t`Required`;
        }

        if (typeof x === 'number' && isPresent(min) && x < min) {
            return t`Must be greater than or equal to ${formatDecimal(min)}`;
        }
        if (typeof x === 'number' && isPresent(max) && x > max) {
            return t`Must be less than or equal to ${formatDecimal(max)}`;
        }

        if (typeof x === 'number' && isPresent(exclusiveMin) && x <= exclusiveMin) {
            return t`Must be greater than ${formatDecimal(exclusiveMin)}`;
        }

        if (typeof x === 'number' && isPresent(exclusiveMax) && x >= exclusiveMax) {
            return t`Must be less than ${formatDecimal(exclusiveMax)}`;
        }

        if (isFinite && typeof x === 'number' && !Number.isFinite(x)) {
            return t`Must be a finite real number`;
        }

        if (isInteger && isPresent(x) && !Number.isInteger(x)) {
            return t`Must be a whole number`;
        }

        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
