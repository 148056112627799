import { t, Trans } from '@lingui/macro';
import {
    CenteredLayout,
    colorSystem,
    Dropzone,
    FieldSelect,
    Flexbox,
    FormItem,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { readFile } from '../../../../model/readFile';

import { Encoding, encoding } from '../../../../types';
import { StepperProps } from '../../../Stepper';
import { useUniversalImporter } from '../../context';
import { useRunParsersQueryKey } from '../../hooks/useRunParsers';

export const StepUploadDropzone = (props: Pick<StepperProps, 'onStepIndexChange'>): JSX.Element => {
    const { dispatch, state } = useUniversalImporter();
    const queryClient = useQueryClient();
    const [encodingFormValue, setEncodingFormValue] = useState<Encoding>('UTF-8');
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    const handleCollapseClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const StyledTertiaryButton = styled(TertiaryButton)({
        padding: 0,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    });

    return (
        <CenteredLayout height={'100%'}>
            <Dropzone
                overrides={{}}
                onDropAccepted={async (files) => {
                    const table = await readFile(files[0], encodingFormValue);
                    dispatch({ type: 'setTable', table: table.data, fileName: files[0].name });
                    queryClient.invalidateQueries(useRunParsersQueryKey);
                    props.onStepIndexChange('next');
                }}
                accept={{ 'text/csv': ['.csv'] }}
                multiple={false}
                title=""
                persistentFiles={
                    state.fileName
                        ? [
                              {
                                  name: state.fileName,
                                  onDelete: () => {
                                      dispatch({ type: 'setTable', table: undefined });
                                  },
                                  onClick() {
                                      // noop
                                  },
                              },
                          ]
                        : []
                }
                extraContent={
                    // height needed to ensure that the dropzone does not move when button opened.
                    <div style={{ height: 150 }}>
                        <StyledTertiaryButton
                            disableRipple
                            disableFocusRipple
                            variant="text"
                            onClick={handleCollapseClick}
                        >
                            <Flexbox gap={4} alignItems="center">
                                {showAdvancedOptions ? (
                                    <KeyboardArrowDownIcon style={{ color: colorSystem.neutral[7] }} />
                                ) : (
                                    <KeyboardArrowRightIcon style={{ color: colorSystem.neutral[7] }} />
                                )}
                                <Text style={{ color: colorSystem.neutral[7] }}>
                                    <Trans>Advanced options</Trans>
                                </Text>
                            </Flexbox>
                        </StyledTertiaryButton>
                        <Collapse in={showAdvancedOptions} timeout={150}>
                            <Flexbox flexDirection={'column'} gap={24}>
                                <FormItem label={t`Character encoding`}>
                                    <FieldSelect
                                        value={encodingFormValue}
                                        onChange={(value) => setEncodingFormValue(value as Encoding)}
                                        options={[...encoding]}
                                        fullWidth
                                        disableClearable
                                    />
                                </FormItem>
                            </Flexbox>
                        </Collapse>
                    </div>
                }
            />
        </CenteredLayout>
    );
};
