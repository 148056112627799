import { t } from '@lingui/macro';
import { FieldValues, Path } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldCheckbox, FieldCheckboxProps } from './FieldCheckbox';

interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<FieldControllerProps<FieldCheckboxProps, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the checkbox must be selected.
     */
    required?: boolean;
}

export function FieldCheckboxControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({ required, validate, ...props }: ControllerProps<TFieldValues, TName>): JSX.Element {
    return <FieldController Field={FieldCheckbox} validate={createValidator({ required, validate })} {...props} />;
}

function createValidator({
    required,
    validate,
}: {
    required?: boolean;
    validate?: (x: unknown) => string | undefined;
}) {
    return (x: unknown): string | undefined => {
        if (required && x === false) {
            return t`Required`;
        }
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
