import { Trans } from '@lingui/macro';
import { Column, columnWidth, Flexbox, Row, Tag } from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { transEnum } from '../../../components/localization/TransEnum';
import { levelEnumPublicTranslations } from '../../../resources/activity/activityBackendTypes';
import { ResourcesCell, TextCell } from '../ActivitiesTable/activitiesTableComponents';
import { NotPerPanelIconAndText, PerPanelIconAndText } from '../shared/icons';
import { doesUnitActivityHaveBatchTimeCalculation, renderDriverColumn } from './renderDriverColumn';

const perPanelActivityColumn: Column<ActivityDTO> = {
    label: <Trans>Per panel</Trans>,
    id: `perPanel`,
    render: ({ data: rowData }: Row<ActivityDTO>) => {
        if (rowData.time_calculation.level === 'Unit') {
            return (
                <TextCell
                    firstRow={
                        <>
                            {rowData.time_calculation.details.panel_default_settings === 'WithinWholePanel' ? (
                                <PerPanelIconAndText />
                            ) : (
                                <NotPerPanelIconAndText />
                            )}
                        </>
                    }
                />
            );
        } else {
            return <TextCell firstRow={''} />;
        }
    },
    width: columnWidth.small,
    filters: [
        {
            id: 'per panel=true',
            label: <Trans>Yes</Trans>,
            predicate: (activity): boolean =>
                activity.time_calculation.level === 'Unit' &&
                activity.time_calculation.details.panel_default_settings === 'WithinWholePanel',
        },
        {
            id: 'per panel=false',
            label: <Trans>No</Trans>,
            predicate: (activity): boolean =>
                activity.time_calculation.level === 'Unit' &&
                activity.time_calculation.details.panel_default_settings !== 'WithinWholePanel',
        },
    ],
};

const resourcesColumn: Column<ActivityDTO> = {
    label: <Trans>Resources</Trans>,
    id: `resource`,
    render: ({ data: rowData }: Row<ActivityDTO>) => {
        return <TextCell firstRow={<ResourcesCell resourcesDetails={rowData.resources_details} />} />;
    },
    width: columnWidth.large,
};

const levelColumnTagsOnOneRow: Column<ActivityDTO> = {
    label: <Trans>Level</Trans>,
    id: `level`,
    render: ({ data: rowData }: Row<ActivityDTO>) => (
        <TextCell
            firstRow={
                <Flexbox>
                    <Tag
                        color={'neutral'}
                        label={transEnum(rowData.time_calculation.level, levelEnumPublicTranslations)}
                    />
                    {doesUnitActivityHaveBatchTimeCalculation(rowData) && (
                        <Box ml={1}>
                            <Tag color={'neutral'} label={transEnum('Batch', levelEnumPublicTranslations)} />
                        </Box>
                    )}
                </Flexbox>
            }
        />
    ),

    width: columnWidth.medium,
};

const driverColumn: Column<ActivityDTO> = {
    label: <Trans>Drivers</Trans>,
    id: `driver`,
    render: ({ data: rowData }: Row<ActivityDTO>) => {
        return renderDriverColumn(rowData);
    },

    width: columnWidth.large,
    // TODO: filter for drivers in an activity https://www.notion.so/luminovo/Filter-drivers-for-Activity-and-Expenses-7c39470460a54c9aa38b511de4525e3f
};

export { driverColumn, levelColumnTagsOnOneRow, perPanelActivityColumn, resourcesColumn };
