import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { HttpError, NetworkError } from '@luminovo/http-client';
import { errorCodeTranslations } from './errorCodeTranslations';

export function formatError(err: Error): string {
    if (err instanceof NetworkError) {
        return t`Network error: please check your internet connection and try again.`;
    }
    if (err instanceof HttpError) {
        return transEnum(err.code, errorCodeTranslations);
    }
    return t`An unexpected error occurred.`;
}
