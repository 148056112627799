import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { OrbweaverLicenseDTORuntype } from './orbweaverBackendTypes';

export const orbweaverEndpoints = {
    'GET /3rdparty/orbweaver/is-configured': endpoint({
        description: 'Returns orbweaver license information',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: OrbweaverLicenseDTORuntype }),
    }),
};
