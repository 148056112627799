import { BaseParser, ParseResult, Parser } from './Parser';

export interface NumberProps {
    decimalSeparator: '.' | ',';

    warnIf?: {
        isFloat?: boolean;
        isNegative?: boolean;
    };

    errorIf?: {
        isNegative?: boolean;
    };
}

export function number(props: NumberProps): Parser<number> {
    return BaseParser.of((input): ParseResult<number> => {
        return parseNumber(input, props);
    });
}

// When changing this regex, I suggest testing it out here: https://myregexp.com/
const regexCommaSeparator = /^[+-]?\d{1,3}((\.\d{3})|\d+)*(,\d*)?([eE][+-]?\d+)?/;
const regexDotSeparator = /^[+-]?\d{1,3}(([,]\d{3})|\d+)*(\.\d*)?([eE][+-]?\d+)?/;

export function parseNumber(input: string, { decimalSeparator, warnIf, errorIf }: NumberProps): ParseResult<number> {
    if (input.length === 0) {
        return {
            ok: false,
            expected: 'a non empty string',
        };
    }

    const chosenSeparator = decimalSeparator ?? inferDecimalSeparator(input);

    const regexPattern = chosenSeparator === '.' ? regexDotSeparator : regexCommaSeparator;

    const match = input.match(regexPattern);

    if (!match) {
        return {
            ok: false,
            expected: 'a number',
        };
    }

    const matchedNumber = match[0];

    const thousandsSeparator = chosenSeparator === '.' ? ',' : '.';

    const value = parseFloat(matchedNumber.replaceAll(thousandsSeparator, '').replaceAll(chosenSeparator, '.'));

    let message: string | undefined = undefined;
    if (warnIf?.isFloat && !Number.isInteger(value)) {
        message = `The number ${value} is not an integer.`;
    }

    if (warnIf?.isNegative && value < 0) {
        message = `The number ${value} is negative.`;
    }

    if (errorIf?.isNegative && value < 0) {
        return {
            ok: false,
            expected: 'a positive number',
        };
    }

    return {
        ok: true,
        value,
        rest: input.slice(matchedNumber.length),
        message,
    };
}

/**
 * Infers the decimal separator used in a number string.
 * Returns undefined if ambiguous or cannot be determined.
 */
function inferDecimalSeparator(input: string): '.' | ',' | undefined {
    // Remove whitespace
    const trimmed = input.trim();

    // Count occurrences of each separator
    const commas = (trimmed.match(/,/g) || []).length;
    const periods = (trimmed.match(/\./g) || []).length;

    // If no separators or multiple of same type, can't determine
    if ((commas === 0 && periods === 0) || (commas > 1 && periods > 1)) {
        return undefined;
    }

    // If only one type of separator is present, that's the decimal
    if (commas === 0 && periods === 1) return '.';
    if (periods === 0 && commas === 1) return ',';

    // If we have one of each, rightmost is decimal separator
    if (commas === 1 && periods === 1) {
        const lastCommaIndex = trimmed.lastIndexOf(',');
        const lastPeriodIndex = trimmed.lastIndexOf('.');

        return lastCommaIndex > lastPeriodIndex ? ',' : '.';
    }

    // Multiple separators of different types - ambiguous
    return undefined;
}
