import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    FarnellCredentialsDTORuntype,
    FarnellCredentialsInputDTORuntype,
    FarnellSupportedSettingDTORuntype,
} from './farnellBackendTypes';

export const farnellEndpoints = {
    'GET /3rdparty/farnell/credentials': endpoint({
        description: 'Returns credentials for Farnell integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: FarnellCredentialsDTORuntype }),
    }),
    'POST /3rdparty/farnell/credentials': endpoint({
        description: 'Uploads credentials for Farnell API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FarnellCredentialsInputDTORuntype,
        responseBody: r.Record({ data: FarnellCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/farnell/credentials'],
    }),
    'PATCH /3rdparty/farnell/credentials': endpoint({
        description: 'Updates credentials for Farnell API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FarnellCredentialsInputDTORuntype,
        responseBody: r.Record({ data: FarnellCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/farnell/credentials'],
    }),
    'DELETE /3rdparty/farnell/credentials': endpoint({
        description: 'Deletes existing credentials for Farnell API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        removes: ['GET /3rdparty/farnell/credentials'],
    }),
    'GET /3rdparty/farnell/supported-store-location-settings': endpoint({
        description: 'Returns supported store locations and currencies for Farnell API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(FarnellSupportedSettingDTORuntype),
    }),
};
