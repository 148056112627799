import { Trans } from '@lingui/macro';
import { SecondaryButton, TertiaryIconButton } from '@luminovo/design-system';
import { Add, Delete as DeleteIcon } from '@mui/icons-material';
import { UseFormReturn } from 'react-hook-form';
import { colorSystem } from '../../../../themes';
import { AddActivityFormInputs } from '../AddActivityFormTypes';

export function RemoveResourceButton(
    props: {
        index: number;
        style?: React.CSSProperties;
    } & UseFormReturn<AddActivityFormInputs>,
) {
    const resources = props.watch('resourcesDetails');
    function handleClick() {
        const newArgs = resources.filter((_, i) => i !== props.index);
        props.setValue('resourcesDetails', newArgs);
    }
    return (
        <TertiaryIconButton onClick={handleClick} size="medium" style={props.style}>
            <DeleteIcon fontSize="inherit" />
        </TertiaryIconButton>
    );
}

export function AddResourceButton({ onClick }: { onClick: () => void }) {
    return (
        <SecondaryButton
            startIcon={<Add />}
            onClick={onClick}
            style={{ maxHeight: '84px', width: '372px', border: `1px solid ${colorSystem.neutral[2]}` }}
        >
            <Trans>Add resource</Trans>
        </SecondaryButton>
    );
}
