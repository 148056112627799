import { t } from '@lingui/macro';
import { sortBy } from '@luminovo/commons';
import { FieldSelectControlled, FieldSelectControlledProps } from '@luminovo/design-system';
import { PackageDTO } from '@luminovo/http-client';
import { FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form';
import { formatPackage } from '../../../formatters';

export enum UnknownPackageEnum {
    unknown = 'Unknown',
}

/**
 * Decides if `packageDto` should be included as a selectable option, given `formValues`.
 * 1. Filters out packages where there is NO `.name` but THERE IS `.mounting`.
 * 2. Filters out packages where there is NO `.name` but THERE ARE `.pins`.
 *    Note: We display the `Unknown` option for the case when there is NO name, NO mounting and NO pins,
 *    in which case the user can edit the mounting and number of pins and the BE takes care of creating it.
 *    It would be redundant to display multiple
 *      - Unknown - Through Hole
 *      - Unknown - Surface Mount
 *      - Unknown - 2 pins
 *      - Unknown - 5 pins, etc.
 */
function displayAsPackageOption(packageDto: PackageDTO): boolean {
    if (!packageDto.name && packageDto.mounting !== null) {
        return false;
    }
    if (!packageDto.name && packageDto.number_of_pins !== null) {
        return false;
    }

    return true;
}

function isUnknownOption(packageDto: PackageDTO) {
    return packageDto.name === null && packageDto.number_of_pins === null && packageDto.mounting === null;
}

export function getPackageOptions(packageDto: PackageDTO[]): PackageDTO[] {
    const filteredPackages = packageDto.filter((p) => displayAsPackageOption(p));

    return sortBy(filteredPackages, [
        (p) => {
            if (isUnknownOption(p)) {
                return 0; // forces the `Unknown` option to be first in the array
            } else {
                return 1;
            }
        },
        (p) => [p.name],
        (p) => {
            return Number(p.number_of_pins);
        },
    ]);
}

/**
 * Usually used in combination with `usePartPackages`.
 */
export function FieldPackageControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> & PackageDTO = PathValue<TFieldValues, TName>,
>({
    name,
    control,
    required,
    options,
}: FieldSelectControlledProps<TFieldValues, TName, TValue> & { options: PackageDTO[] }): JSX.Element {
    return (
        <FieldSelectControlled
            name={name}
            control={control}
            required={required}
            FieldProps={{
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                options: getPackageOptions(options) as PathValue<TFieldValues, TName>,
                getOptionLabel: formatPackage,
                placeholder: t`Packaging`,
            }}
        />
    );
}
