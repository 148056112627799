import { t, Trans } from '@lingui/macro';
import { AvatarIcon, colorSystem, Flexbox, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import { UserDTO, UserInviteDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { MaxWidthText } from '../../../../MaxWidthText';

const isUser = (user: UserDTO | UserInviteDTO): user is UserDTO => {
    return 'phone_number' in user;
};

export const ContributorItem = ({
    contributor,
    onRemove,
    disabled,
}: {
    contributor: UserDTO | UserInviteDTO;
    disabled?: boolean;
    onRemove?: (id: string) => Promise<void>;
}) => {
    const isInternalOrExternalContributor = isUser(contributor);
    const subTitleText =
        isInternalOrExternalContributor && contributor.phone_number !== null
            ? `${contributor.email} · ${contributor.phone_number}`
            : contributor.email;

    return (
        <Box display={'grid'} gap={8} alignItems={'center'} gridTemplateColumns={'32px 1fr auto'}>
            <AvatarIcon
                size="large"
                user={{
                    firstName: contributor.first_name,
                    lastName: contributor.last_name,
                    email: contributor.last_name,
                }}
            />

            <Flexbox flexDirection={'column'}>
                <Flexbox gap={'4px'} alignItems={'center'}>
                    <Text variant="h5">{`${contributor.first_name} ${contributor.last_name}`}</Text>
                    {isInternalOrExternalContributor && contributor.position !== null && (
                        <Text variant="body-small">{contributor.position}</Text>
                    )}
                </Flexbox>
                <MaxWidthText
                    maxWidthSmallScreen={'100%'}
                    maxWidthLargeScreen={'100%'}
                    text={subTitleText}
                    variant={'subtitle1'}
                    typographyStyle={{
                        color: colorSystem.neutral[6],
                    }}
                />
            </Flexbox>

            {isUser(contributor) ? (
                <>
                    {onRemove && (
                        <TertiaryButton
                            disabled={disabled}
                            onClick={() => onRemove(contributor.id)}
                            size="medium"
                            style={{ padding: 0, height: 'unset', width: 'unset', marginRight: '-6px' }}
                        >
                            <Trans>Remove</Trans>
                        </TertiaryButton>
                    )}
                </>
            ) : (
                <Tag color="blue" label={t`Invited`} />
            )}
        </Box>
    );
};
