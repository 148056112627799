import { Trans } from '@lingui/macro';
import {
    AutocompleteOptions,
    Flexbox,
    Link,
    ParameterOption,
    ParametricSearchInput,
    SecondaryIconButton,
    Text,
    colorSystem,
    createSearchFilter,
    useParametricSearchInputState,
} from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { SitesWithIconFetching, useSiteNames } from '../../../../../../components/Site';
import { route } from '../../../../../../utils/routes';
import { ActivityCountChipWrapper, ExpenseCountChipWrapper } from '../../CountChipWrappers';
import { NotesLabel } from '../Notes';

const ManufacturingScenarioTemplateOption = ({
    manufacturingScenarioTemplate,
    onAddTemplate,
    isSelected,
}: {
    manufacturingScenarioTemplate: ManufacturingScenarioTemplateDTO;
    onAddTemplate: (templateId: string) => void;
    isSelected: boolean;
}) => {
    return (
        <Flexbox
            flexDirection="column"
            padding="16px"
            gap="19px"
            style={{
                borderRadius: '8px',
                border: `1px solid ${isSelected ? colorSystem.primary[5] : colorSystem.neutral[3]}`,
            }}
        >
            <Flexbox gap="8px" justifyContent={'space-between'} alignItems={'center'}>
                <SecondaryIconButton
                    size="small"
                    children={<Add fontSize="inherit" />}
                    onClick={() => onAddTemplate(manufacturingScenarioTemplate.id)}
                />
                <Text style={{ flexGrow: 1 }} variant="h4">
                    {manufacturingScenarioTemplate.name}
                </Text>
                <Flexbox gap="8px">
                    <ActivityCountChipWrapper
                        activityIds={manufacturingScenarioTemplate.ordered_activities.map((a) => a.id)}
                    />
                    <ExpenseCountChipWrapper
                        expenseIds={manufacturingScenarioTemplate.ordered_expenses.map((e) => e.id)}
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox alignItems="center" gap="19px" justifyContent={'space-between'}>
                <div style={{ minWidth: '100px' }}>
                    <SitesWithIconFetching sites={manufacturingScenarioTemplate.sites} noWrap />
                </div>

                <Flexbox gap={'4px'} alignItems="center" style={{ minWidth: '260px', maxWidth: '260px' }}>
                    {manufacturingScenarioTemplate.notes && manufacturingScenarioTemplate.notes.length > 0 && (
                        <NotesLabel notes={manufacturingScenarioTemplate.notes} />
                    )}
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

const parameterName: ParameterOption<ManufacturingScenarioTemplateDTO> = {
    field: 'Name',
    ops: [
        {
            op: 'like',

            filter: (item, arg) => {
                return item.name.toLowerCase().includes(String(arg).toLowerCase());
            },
        },
    ],
};

const getParameterSites = (siteNameMap: Record<string, string>): ParameterOption<ManufacturingScenarioTemplateDTO> => {
    return {
        field: 'Sites',
        ops: [
            {
                op: 'like',
                filter: (item, arg) => {
                    const allSiteIdsOfItem = item.sites;
                    const allSitesContainingTheArg = Object.entries(siteNameMap).filter(([, siteName]) =>
                        siteName.toLowerCase().includes(String(arg).toLowerCase()),
                    );
                    return allSitesContainingTheArg.some(([siteId]) => allSiteIdsOfItem.includes(siteId));
                },
            },
        ],
    };
};

const useTemplateSearchConfig = (): AutocompleteOptions<ManufacturingScenarioTemplateDTO> => {
    const { siteNames } = useSiteNames();

    const config: AutocompleteOptions<ManufacturingScenarioTemplateDTO> = {
        parameters: [parameterName, getParameterSites(siteNames)],
        textQueryParser: (query) => {
            return {
                field: 'Name',
                op: 'like',
                parameter: query,
                label: query,
            };
        },
    };
    return config;
};

export const LeftColumn = ({
    onChange,
    selected,
    manufacturingScenarioTemplates,
}: {
    onChange: (e: string[]) => void;
    selected: string[];
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
}) => {
    const config = useTemplateSearchConfig();
    const state = useParametricSearchInputState(config);
    const filtered = manufacturingScenarioTemplates.filter(createSearchFilter(state.state));

    return (
        <>
            <Text variant="h3">
                <Trans>Select templates </Trans>
            </Text>
            <ParametricSearchInput autoFocus={false} placeholder="Type to search" {...state} />
            <Text>
                <Trans>Only active templates are displayed</Trans>.{' '}
                <Link attention="low" href={route('/manufacturing/template')} target="_blank" rel="noopener noreferrer">
                    <Trans>Edit templates</Trans>
                </Link>
                .
            </Text>

            <Divider />
            <Flexbox flexDirection={'column'} gap={'8px'} style={{ overflowY: 'scroll' }}>
                {filtered.map((template) => {
                    return (
                        <ManufacturingScenarioTemplateOption
                            key={template.id}
                            manufacturingScenarioTemplate={template}
                            onAddTemplate={(templateId) => {
                                onChange([...selected, templateId]);
                            }}
                            isSelected={selected.includes(template.id)}
                        />
                    );
                })}
            </Flexbox>
        </>
    );
};
