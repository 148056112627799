import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { CurrentUserUpdateDTORuntype, UserDTORuntype } from '../user/userBackendTypes';
import { CurrentUserDetailsDTORuntype, VersionInformationDTORuntype } from './userBasedBackendTypes';

export const userBasedEndpoints = {
    'GET /my/details': endpoint({
        description: 'Fetches the details of the logged-in user',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: CurrentUserDetailsDTORuntype }),
    }),

    'GET /my/settings/account-security': endpoint({
        description: 'Fetches the account security settings of the logged-in user',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Record({ use_mfa: r.Boolean }) }),
    }),

    'GET /my/version': endpoint({
        description: 'Fetches the current version of the backend',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: VersionInformationDTORuntype }),
    }),

    'GET /my/magicbell': endpoint({
        description: '',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                external_id: r.String,
                hmac: r.String,
            }),
        }),
    }),

    'GET /my/intercom': endpoint({
        description: '',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                hmac: r.String,
                user_id: r.String,
            }),
        }),
    }),

    'PATCH /my/user': endpoint({
        description: 'Updates the details of the logged-in user',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CurrentUserUpdateDTORuntype,
        responseBody: r.Record({ data: UserDTORuntype }),
    }),

    'PATCH /my/settings/account-security': endpoint({
        description: 'Updates the account security settings of the logged-in user',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ use_mfa: r.Boolean }),
        responseBody: r.Record({ data: r.Record({ use_mfa: r.Boolean }) }),
        invalidates: ['GET /my/settings/account-security'],
    }),
};
