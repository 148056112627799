import { ActivityLevel, FormulaDetails, TimeCalculation } from '@luminovo/http-client';
import { assertUnreachable } from '../../../utils/typingUtils';

export const getFormulaDetailsFromTimeCalculation = (
    timeCalculation: TimeCalculation,
    timeLevel: ActivityLevel,
): FormulaDetails | undefined => {
    var formulaDetails = undefined;
    const calculationLevel = timeCalculation.level;

    switch (calculationLevel) {
        case 'Batch':
            switch (timeLevel) {
                case 'Unit':
                    // because this cannot happen the formula can be left as undefined
                    break;
                case 'Batch':
                    if (timeCalculation.details.batch_time_components?.type === 'Formula') {
                        formulaDetails = timeCalculation.details.batch_time_components?.details;
                    }
                    break;
                case 'Project':
                    break;
                default:
                    assertUnreachable(timeLevel);
            }

            break;
        case 'Project':
            switch (timeLevel) {
                case 'Unit':
                    // because this cannot happen the formula can be left as undefined
                    break;
                case 'Batch':
                case 'Project':
                    if (timeCalculation.details.project_time_components?.type === 'Formula') {
                        formulaDetails = timeCalculation.details.project_time_components?.details;
                    }
                    break;

                default:
                    assertUnreachable(timeLevel);
            }

            break;
        case 'Unit':
            switch (timeLevel) {
                case 'Unit':
                    if (timeCalculation.details.unit_time_components?.type === 'Formula') {
                        formulaDetails = timeCalculation.details.unit_time_components?.details;
                    }
                    break;
                case 'Batch':
                case 'Project':
                    if (timeCalculation.details.batch_time_components?.type === 'Formula') {
                        formulaDetails = timeCalculation.details.batch_time_components?.details;
                    }
                    break;
                default:
                    assertUnreachable(timeLevel);
            }
            break;
        default:
            assertUnreachable(calculationLevel);
    }
    return formulaDetails;
};
