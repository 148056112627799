import { Trans } from '@lingui/macro';
import { Flexbox, Text } from '@luminovo/design-system';
import {
    FullPart,
    GenericFullPart,
    GenericPartTypes,
    OtsFullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { TechParamsGenericPart } from '../../../../../../components/partSpecificationCards/GenericPart/TechParamsGenericPart';
import { assertUnreachable } from '../../../../../../utils/typingUtils';

const MAX_WIDTH_PART_OPTION = '300px';

export const RenderPartLabel = ({ partOption }: { partOption: FullPart }) => {
    if (isCustomFullPart(partOption)) {
        return (
            <Text variant="inherit">
                <Trans>Custom {partOption.type.name}</Trans>
            </Text>
        );
    }
    if (isCustomComponentFull(partOption)) {
        return <Text variant="inherit">IPN {partOption.id}</Text>;
    }
    if (isOtsFullPart(partOption)) {
        return <RenderOffTheShelfPart part={partOption} />;
    }
    if (isOtsComponentFull(partOption)) {
        return <Text variant="inherit">IPN {partOption.id}</Text>;
    }
    return <RenderGenericPart part={partOption} />;
};

const RenderOffTheShelfPart = ({ part }: { part: OtsFullPart }): JSX.Element => {
    return (
        <Flexbox maxWidth={MAX_WIDTH_PART_OPTION}>
            <Text showEllipsis style={{ whiteSpace: 'nowrap' }} variant="inherit">
                {formatPart(part)}
            </Text>
        </Flexbox>
    );
};

const RenderGenericPart = ({ part }: { part: GenericFullPart }): JSX.Element => {
    if (part.content.type === GenericPartTypes.Capacitor) {
        return (
            <Flexbox alignItems={'center'} gap={4}>
                <Text variant="inherit" showEllipsis>
                    <Trans>Capacitor</Trans> <TechParamsGenericPart genericPart={part.content} />
                </Text>
            </Flexbox>
        );
    }
    if (part.content.type === GenericPartTypes.Resistor) {
        return (
            <Flexbox alignItems={'center'} gap={4}>
                <Text variant="inherit" showEllipsis>
                    <Trans>Resistor</Trans> <TechParamsGenericPart genericPart={part.content} />
                </Text>
            </Flexbox>
        );
    }
    assertUnreachable(part.content);
};
