import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../../boundingBox';
import { generateId } from '../generateId';
import { Extractor, Region } from '../types';

type InvoiceDoc = AzureExtractionResult['analyzeResult']['documents'][number];

export function extractInvoiceAttributes(): Extractor<InvoiceDoc> {
    return {
        generator: function* (azureExtractionResult: AzureExtractionResult) {
            const rows: InvoiceDoc[] = azureExtractionResult.analyzeResult.documents.filter(
                (doc) => doc.docType === 'invoice',
            );

            for (const row of rows) {
                yield row;
            }
        },

        extractRegion: (obj) => {
            const result: Region[] = [];

            if (obj.fields.InvoiceId) {
                const invoiceId = obj.fields.InvoiceId;
                result.push({
                    id: generateId(invoiceId),
                    content: invoiceId.content,
                    pageNumber: invoiceId.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(invoiceId.boundingRegions),
                    attributes: [
                        {
                            attr: 'offerNumber',
                            value: invoiceId.valueString,
                            confidence: invoiceId.confidence,
                        },
                    ],
                });
            }

            if (obj.fields.InvoiceDate) {
                const offerDate = obj.fields.InvoiceDate;
                result.push({
                    id: generateId(offerDate),
                    content: offerDate.content,
                    pageNumber: offerDate.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(offerDate.boundingRegions),
                    attributes: [
                        {
                            attr: 'offerDate',
                            value: offerDate.valueDate,
                            confidence: offerDate.confidence,
                        },
                    ],
                });
            }
            if (obj.fields.DueDate) {
                const dueDate = obj.fields.DueDate;
                result.push({
                    id: generateId(dueDate),
                    content: dueDate.content,
                    pageNumber: dueDate.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(dueDate.boundingRegions),
                    attributes: [
                        {
                            attr: 'dueDate',
                            value: dueDate.valueDate,
                            confidence: dueDate.confidence,
                        },
                    ],
                });
            }
            if (obj.fields.ServiceEndDate) {
                const serviceEndDate = obj.fields.ServiceEndDate;

                result.push({
                    id: generateId(serviceEndDate),
                    content: serviceEndDate.content,
                    pageNumber: serviceEndDate.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(serviceEndDate.boundingRegions),
                    attributes: [
                        {
                            attr: 'dueDate',
                            value: serviceEndDate.valueDate,
                            confidence: serviceEndDate.confidence,
                        },
                    ],
                });
            }

            return result;
        },

        extractionRules: [() => []],
    };
}
