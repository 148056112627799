import { colorSystem, Flexbox, FlexboxProps, SecondaryIconButton, Text } from '@luminovo/design-system';
import { Close, Menu } from '@mui/icons-material';
import { Box, BoxProps, Collapse } from '@mui/material';
import React from 'react';

interface Props extends Omit<BoxProps, 'display' | 'gridTemplateColumn' | 'title'> {
    sidebar: JSX.Element;
    title: string | JSX.Element;

    ContentProps?: FlexboxProps;
}

export function SourcingListDetailsLayout({ sidebar, title, children }: Props) {
    const [isOpen, setOpen] = React.useState(false);
    const width = isOpen ? '360px' : '46px';
    return (
        <Box position={'relative'} minHeight={`calc(100vh - 98px)`} display={'grid'} gridTemplateColumns={`auto 1fr`}>
            <Flexbox
                position={'sticky'}
                top="0px"
                flexDirection={'column'}
                width={width}
                style={{ transition: 'width 0.1s linear' }}
                borderRight={`1px solid ${colorSystem.neutral[2]}`}
                bgcolor={colorSystem.neutral[1]}
                paddingTop="8px"
            >
                <Collapse in={!isOpen}>
                    <Flexbox justifyContent={'space-around'}>
                        <SecondaryIconButton size={'small'} onClick={() => setOpen((x) => !x)}>
                            <Menu fontSize="inherit" />
                        </SecondaryIconButton>
                    </Flexbox>
                </Collapse>
                <Collapse in={isOpen}>
                    <Flexbox
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        paddingX={'16px'}
                        paddingBottom={'12px'}
                        onClick={() => setOpen((x) => !x)}
                    >
                        <Text variant="h3" style={{ color: colorSystem.neutral[8] }}>
                            {title}
                        </Text>
                        <SecondaryIconButton size={'small'}>
                            <Close fontSize="inherit" />
                        </SecondaryIconButton>
                    </Flexbox>
                </Collapse>
                <Collapse in={isOpen}>{sidebar}</Collapse>
            </Flexbox>
            <Flexbox
                flexDirection={'column'}
                gap={'12px'}
                padding="16px"
                paddingTop="12px"
                // the hubspot padding fix
                paddingBottom={'80px'}
            >
                {children}
            </Flexbox>
        </Box>
    );
}
