import { BomScreeningSheet, ScreenerHandlerOutput } from '@luminovo/http-client';
import { useCallback } from 'react';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
} from '../../DesignItemDetails/components/AutocompleteColumnTags/types';
import { ValidationError } from '../../DesignItemDetails/components/AutocompleteColumnTags/validation';
import { BomImporterDetails } from './BomImporterDetails';
import { BomSheetSelect } from './BomSheetSelect';
import { ColumnMappingGuidanceBanner } from './ColumnMappingGuidance/ColumnMappingGuidanceBanner';
import { ReusePartOptionsFromPreviousImport } from './ReusePartOptionsFromPreviousImport';

export function BomImporterSidebar({
    bomScreeningOutput,
    sheetIndex,
    validationErrors,
    tagsState,
    dispatch,
}: {
    bomScreeningOutput: ScreenerHandlerOutput;
    sheetIndex: number;
    validationErrors: ValidationError[];
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}) {
    const bomScreeningSheet: BomScreeningSheet = bomScreeningOutput.sheets[sheetIndex];

    const switchSheet = useCallback(
        (sheetIndex: number) => {
            dispatch({ type: 'set-current-sheet', index: sheetIndex });
        },
        [dispatch],
    );

    return (
        <>
            <ColumnMappingGuidanceBanner />
            <BomSheetSelect sheetIndex={sheetIndex} bomScreeningOutput={bomScreeningOutput} switchSheet={switchSheet} />
            <ReusePartOptionsFromPreviousImport tagsState={tagsState} dispatch={dispatch} />
            <BomImporterDetails bomScreeningSheet={bomScreeningSheet} validationErrors={validationErrors} />
        </>
    );
}
