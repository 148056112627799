import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseColor, parseSilkscreenColor, parseSoldermaskColor } from '../parsers/parseColor';
import { parseSilkscreenKeyword } from '../parsers/parseSilkscreen';
import { parseSoldermaskKeyword } from '../parsers/parseSoldermask';
import { applyConfidencePenalty, defaultToConfidenceOfOne } from '../utils';

export const extractColor: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const confidencePenaltyIfNotSelected = stringToParse.includes(':selected:') ? 1 : 0.5;
    const specificParsers = [parseSoldermaskColor, parseSilkscreenColor];
    const specificAttributes = specificParsers.map((parser) => parser(stringToParse)).filter(isPresent);
    if (specificAttributes.length > 0) {
        return specificAttributes.map((attribute) => applyConfidencePenalty(attribute, confidencePenaltyIfNotSelected));
    }
    // no confidence penalty for keywords (they don't need to be "selected")
    const keywordParsers = [parseSilkscreenKeyword, parseSoldermaskKeyword];
    const keywords = keywordParsers
        .map((parser) => parser(stringToParse))
        .filter(isPresent)
        .map((attribute) => defaultToConfidenceOfOne(attribute));

    const value = parseColor(stringToParse);
    return keywords.concat(isPresent(value) ? [applyConfidencePenalty(value, confidencePenaltyIfNotSelected)] : []);
};
