import { Trans } from '@lingui/macro';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { ActivityDTO, CalculationType } from '@luminovo/http-client';
import { CalculationTypeWithIcon } from '../../shared/ManufacturingSharedComponents/CalculationTypeWithIcon';
import { LevelCell } from './LevelCell';
import { getTimeCalculationSummary } from './utils';

const isTimeCalculationType = (calculationType: CalculationType, activity: ActivityDTO): boolean => {
    const timeCalculation = getTimeCalculationSummary(activity);

    return Object.entries(timeCalculation)
        .map(([_, calculation]) => calculation?.type === calculationType)
        .reduce((acc, isCalculationType) => acc || isCalculationType, false);
};

function getTimeCalculationFilters<C>(): Filter<ActivityDTO, C>[] {
    return [
        {
            id: 'time calculation=Fixed',
            label: <Trans>Fixed</Trans>,
            predicate: (activity) => isTimeCalculationType('Fixed', activity),
        },
        {
            id: 'time calculation=Linear',
            label: <Trans>Linear</Trans>,
            predicate: (activity) => isTimeCalculationType('Linear', activity),
        },
        {
            id: 'time calculation=Formula',
            label: <Trans>Formula</Trans>,
            predicate: (activity) => isTimeCalculationType('Formula', activity),
        },
    ];
}

const TimeCalculationColumn = ({ activity }: { activity: ActivityDTO }): JSX.Element => {
    const calculation = getTimeCalculationSummary(activity);

    const unit = calculation['Unit'] && <CalculationTypeWithIcon calculationType={calculation['Unit'].type} />;
    const batch = calculation['Batch'] && <CalculationTypeWithIcon calculationType={calculation['Batch'].type} />;
    const project = calculation['Project'] && <CalculationTypeWithIcon calculationType={calculation['Project'].type} />;

    return <LevelCell unit={unit} batch={batch} project={project} />;
};

export function getTimeCalculationColumn<C>(): Column<ActivityDTO, C> {
    return {
        label: <Trans>Time calc.</Trans>,
        id: 'timeCalculationColumn',
        render: ({ data: rowData }: Row<ActivityDTO>) => <TimeCalculationColumn activity={rowData} />,
        width: columnWidth.medium,
        filters: getTimeCalculationFilters(),
    };
}
