import { isPresent } from '@luminovo/commons';
import { AutoAwesomeRounded, CheckCircle, Close as CloseIcon, Info, WarningRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';
import { BaseButtonProps, SecondaryButton, TertiaryButton, TertiaryIconButton } from '../buttons';

type MessageProps = {
    variant: 'primary' | 'blue' | 'green' | 'red' | 'yellow';
    attention: 'high' | 'low';
    size: 'small' | 'large';
    title?: string;
    message?: string | JSX.Element;
    action?: {
        label: string;
        onClick: () => void;
        disabled?: BaseButtonProps['disabled'];
    };
    onClose?: () => void;
    overrides?: { ActionButton?: React.ComponentType; MessageIcon?: React.ComponentType };
};

const DefaultMessageIcon: React.FunctionComponent<Pick<MessageProps, 'variant' | 'attention'>> = ({
    variant,
    attention,
}): JSX.Element => {
    const colorShade = attention === 'high' ? 7 : 6;

    switch (variant) {
        case 'primary':
            return (
                <AutoAwesomeRounded
                    style={{ color: colorSystem.primary[colorShade], alignSelf: 'start' }}
                    fontSize="small"
                />
            );
        case 'blue':
            return <Info style={{ color: colorSystem.blue[colorShade], alignSelf: 'start' }} fontSize="small" />;
        case 'green':
            return (
                <CheckCircle style={{ color: colorSystem.green[colorShade], alignSelf: 'start' }} fontSize="small" />
            );
        case 'red':
            return <Info style={{ color: colorSystem.red[colorShade], alignSelf: 'start' }} fontSize="small" />;
        case 'yellow':
            return (
                <WarningRounded
                    style={{ color: colorSystem.yellow[colorShade], alignSelf: 'start' }}
                    fontSize="small"
                />
            );
    }
};

const MessageContainer: React.FunctionComponent<Pick<MessageProps, 'variant' | 'attention'>> = ({
    children,
    variant,
    attention,
}): JSX.Element => {
    const bgcolor = attention === 'high' ? colorSystem[variant][1] : colorSystem.neutral[1];
    const borderColor = attention === 'high' ? colorSystem[variant][3] : colorSystem.neutral[2];

    return (
        <Box padding={'14px'} bgcolor={bgcolor} border={`1px solid`} borderColor={borderColor} borderRadius="8px">
            {children}
        </Box>
    );
};

const DefaultActionButton: React.FunctionComponent<Pick<MessageProps, 'size' | 'action'>> = ({
    size,
    action,
}): JSX.Element => {
    if (!isPresent(action)) {
        return <></>;
    }
    const { label, onClick, ...props } = action;

    switch (size) {
        case 'small':
            return (
                <TertiaryButton size="small" onClick={onClick} style={{ padding: 0 }} {...props}>
                    {label}
                </TertiaryButton>
            );
        case 'large':
            return (
                <SecondaryButton size="medium" onClick={onClick} style={{ width: 'fit-content' }} {...props}>
                    {label}
                </SecondaryButton>
            );
    }
};

const CloseButton: React.FunctionComponent<Pick<MessageProps, 'onClose'>> = ({ onClose }): JSX.Element => {
    if (!isPresent(onClose)) {
        return <></>;
    }

    return (
        <TertiaryIconButton size={'small'} style={{ marginLeft: 'auto', padding: '0px' }} onClick={onClose}>
            <CloseIcon fontSize="inherit" />
        </TertiaryIconButton>
    );
};

const SmallMessage: React.FunctionComponent<MessageProps> = ({
    variant,
    attention,
    size,
    title,
    message,
    action,
    onClose,
    overrides = {},
}): JSX.Element => {
    const { ActionButton = DefaultActionButton, MessageIcon = DefaultMessageIcon } = overrides;

    return (
        <MessageContainer variant={variant} attention={attention}>
            <Flexbox justifyContent={'space-between'} alignItems={'baseline'} gap={8}>
                <Flexbox gap={8} alignItems={'baseline'}>
                    <MessageIcon variant={variant} attention={attention} />
                    <Text variant="h4" color={colorSystem.neutral[9]} style={{ whiteSpace: 'nowrap' }}>
                        {title}
                    </Text>
                    <Text variant="body"> {message}</Text>
                </Flexbox>
                <Flexbox gap={4}>
                    <ActionButton size={size} action={action} />
                    <CloseButton onClose={onClose} />
                </Flexbox>
            </Flexbox>
        </MessageContainer>
    );
};

const LargeMessage: React.FunctionComponent<MessageProps> = ({
    variant,
    attention,
    size,
    title,
    message,
    action,
    onClose,
    overrides = {},
}): JSX.Element => {
    const { ActionButton = DefaultActionButton, MessageIcon = DefaultMessageIcon } = overrides;

    return (
        <MessageContainer variant={variant} attention={attention}>
            <Flexbox alignItems={'center'} gap={8}>
                <MessageIcon variant={variant} attention={attention} />
                <Text variant="h4" color={colorSystem.neutral[9]}>
                    {title}
                </Text>
                <CloseButton onClose={onClose} />
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={24} paddingLeft={'28px'} paddingTop={'6px'}>
                <Text variant="body" style={{ paddingRight: '24px' }}>
                    {message}
                </Text>
                <ActionButton size={size} action={action} />
            </Flexbox>
        </MessageContainer>
    );
};

export const Message: React.FunctionComponent<MessageProps> = ({ ...props }): JSX.Element => {
    switch (props.size) {
        case 'small':
            return <SmallMessage {...props} />;
        case 'large':
            return <LargeMessage {...props} />;
    }
};
