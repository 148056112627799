import { Plural, t, Trans } from '@lingui/macro';
import { formatDays, formatDecimal, formatMonetaryValue, formatToLongDate, isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { PriceBreakDTO, PriceBreaksDTO, SolutionConfigurationDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import { InfoRounded } from '@mui/icons-material';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { isStandardPartInventoryOffer } from '../../../../model';
import { Solution } from '../../../../types';
import { UnknownValue } from '../../../utils';
import { AvailabilityInfoBox } from './AvailabilityInfoBox';
import { UnitOfMeasurementMismatchInfo } from './UnitOfMeasurementMismatchInfo';

const AvailabilityInfos: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
}> = ({ offer }): JSX.Element => {
    const { available_prices: availablePrices } = offer;
    const formatedStock = isPresent(availablePrices.stock) ? formatDecimal(availablePrices.stock) : undefined;

    const fromatedFactoryQuantity = isPresent(availablePrices.factory_quantity)
        ? formatDecimal(availablePrices.factory_quantity)
        : undefined;

    const formatedFactoryLeadTimeDays = isPresent(availablePrices.factory_lead_time_days)
        ? formatDays(availablePrices.factory_lead_time_days)
        : undefined;

    const formattedTotalStock = isPresent(availablePrices.total_stock)
        ? formatDecimal(availablePrices.total_stock)
        : undefined;

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Text variant="h4" color={colorSystem.neutral[8]}>
                <Trans>Availability</Trans>
            </Text>
            <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr'} columnGap="8px">
                <AvailabilityInfoBox
                    label={isStandardPartInventoryOffer(offer) ? t`Available inventory` : t`Available stock`}
                    formatedValue={formatedStock}
                />
                {isStandardPartInventoryOffer(offer) ? (
                    <AvailabilityInfoBox
                        label={isStandardPartInventoryOffer(offer) ? t`Total inventory` : t`Total stock`}
                        formatedValue={formattedTotalStock}
                    />
                ) : (
                    <AvailabilityInfoBox label={t`Factory quantity`} formatedValue={fromatedFactoryQuantity} />
                )}
                <AvailabilityInfoBox
                    label={isStandardPartInventoryOffer(offer) ? t`Std. lead time` : t`Std. factory lead time`}
                    formatedValue={formatedFactoryLeadTimeDays}
                />
            </Box>
        </Flexbox>
    );
};

const StyledTableCell = styled(TableCell)({
    paddingTop: 4,
    paddingBottom: 4,
    height: '100%',
    borderBottom: 'none',
    verticalAlign: 'top',
});

const OnOrderQuantityInfos: React.FunctionComponent<{
    priceBreaksDTO: PriceBreaksDTO;
}> = ({ priceBreaksDTO }): JSX.Element => {
    if (priceBreaksDTO.on_order.length === 0) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Text variant="h4" color={colorSystem.neutral[8]}>
                <Trans>On order</Trans>
            </Text>
            <Box style={{ border: `1px solid ${colorSystem.neutral[2]}`, borderRadius: '8px' }}>
                <Table>
                    <TableHead style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}>
                        <TableRow>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Quantity</Trans>
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Date</Trans>
                                </Text>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {priceBreaksDTO.on_order.map((onOrderLine, idx) => {
                            return (
                                <TableRow key={idx}>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={colorSystem.neutral[9]}>
                                            {formatDecimal(onOrderLine.quantity)}
                                        </Text>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={colorSystem.neutral[9]}>
                                            {isPresent(onOrderLine.date) ? (
                                                formatToLongDate(onOrderLine.date)
                                            ) : (
                                                <UnknownValue />
                                            )}
                                        </Text>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Flexbox>
    );
};

const PricingInfos: React.FunctionComponent<{
    priceBreaks: PriceBreakDTO[];
    solution?: Solution;
}> = ({ priceBreaks, solution }): JSX.Element => {
    const sortedPriceBreaks = priceBreaks.sort((a, b) => a.moq - b.moq);
    const signature = solution?.firstPurchaseOption.signature;

    const shippingTimeInDays = solution?.availability?.shipping_time_in_days;

    const isHighlighted = (priceBreak: PriceBreakDTO, idx: number) => {
        if (!isPresent(solution)) {
            return idx === sortedPriceBreaks.length - 1;
        }

        if (isPresent(signature) && 'moq' in signature && 'mpq' in signature) {
            return priceBreak.moq === signature.moq && priceBreak.mpq === signature.mpq;
        }

        return false;
    };

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Text variant="h4" color={colorSystem.neutral[8]}>
                <Trans>Pricing</Trans>
            </Text>
            {shippingTimeInDays && (
                <Text variant="body-small" color={colorSystem.neutral[7]}>
                    <Flexbox alignItems={'center'} gap={'4px'}>
                        <InfoRounded fontSize="inherit" />
                        <Trans>This supplier has a shipping time of </Trans>{' '}
                        <Plural value={shippingTimeInDays} one="# day" other="# days" />
                    </Flexbox>
                </Text>
            )}

            <Box style={{ border: `1px solid ${colorSystem.neutral[2]}`, borderRadius: '8px' }}>
                <Table>
                    <TableHead style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}>
                        <TableRow>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>MOQ</Trans>
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Unit price</Trans>
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>MPQ</Trans>
                                </Text>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPriceBreaks.map((priceBreak, idx) => {
                            const color = isHighlighted(priceBreak, idx)
                                ? colorSystem.primary[7]
                                : colorSystem.neutral[9];

                            return (
                                <TableRow key={idx}>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {formatDecimal(priceBreak.moq)}
                                        </Text>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {formatMonetaryValue(priceBreak.unit_price, 'unit-price')}
                                        </Text>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {formatDecimal(priceBreak.mpq)}
                                        </Text>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Flexbox>
    );
};

export const StandardPartOfferInfos: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
    solution?: Solution;
    solutionConfiguration?: SolutionConfigurationDTO;
}> = ({ offer, solution, solutionConfiguration }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={24} paddingX={'16px'} paddingY={'24px'}>
            <AvailabilityInfos offer={offer} />
            <OnOrderQuantityInfos priceBreaksDTO={offer.available_prices} />
            <PricingInfos priceBreaks={offer.available_prices.price_breaks} solution={solution} />
            <UnitOfMeasurementMismatchInfo offer={offer} solutionConfiguration={solutionConfiguration} />
        </Flexbox>
    );
};
