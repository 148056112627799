export const getPcbAnalysisId = (pcbId: string) => {
    return `pcb/pcb_analysis_message_id_${pcbId}`;
};

export const getPcbPdfAnalysisId = (pcbId: string) => {
    return `pcb/pcb_pdf_analysis_message_id_${pcbId}`;
};

export const getPcbPdfExtractionId = (pcbId: string) => {
    return `pcb/pcb_pdf_extraction_id_${pcbId}`;
};
