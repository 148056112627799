import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { Tag, TagProps } from '@luminovo/design-system';
import { CostType } from '../../extractors';

const getColor = (costType: CostType): TagProps['color'] => {
    switch (costType) {
        case 'packaging':
            return 'violet';
        case 'discount':
            return 'yellow';
        case 'shipping':
            return 'blue';
        case 'customs':
            return 'green';
        case 'other':
            return 'neutral';
        default:
            assertUnreachable(costType);
    }
};

const labelTranslations: Record<string, MessageDescriptor> = {
    packaging: defineMessage({ message: 'Packaging' }),
    discount: defineMessage({ message: 'Discount' }),
    shipping: defineMessage({ message: 'Shipping' }),
    customs: defineMessage({ message: 'Customs' }),
    other: defineMessage({ message: 'Other' }),
};

export const CostTag = ({ costType }: { costType: CostType }): JSX.Element => {
    const color = getColor(costType);
    const label = transEnum(costType, labelTranslations);

    return <Tag color={color} label={label} attention="low" />;
};
