import { InputAdornment, styled } from '@mui/material';
import React from 'react';
import { body, colorSystem } from '../../theme';
import { TextField, TextFieldProps } from '../TextField';
import { SearchIcon } from '../icons';

export const SearchField = React.forwardRef(function SearchField(
    { disabled, ...props }: TextFieldProps,
    ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element {
    return (
        <DefaultSearchField
            ref={ref}
            {...props}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon
                            fontSize="inherit"
                            fill={disabled ? colorSystem.neutral[5] : colorSystem.neutral[7]}
                        />
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    );
});

const DefaultSearchField = styled(TextField)({
    // Style the start adornment
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '8px',
    },
    '& .MuiOutlinedInput-input': {
        paddingLeft: '4px',
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
        margin: '0 0 0 8px',
        padding: 0,
        '& .MuiTypography-colorTextSecondary': {
            ...body,
            color: colorSystem.neutral[7],
            padding: 0,
        },
        '& > *': {
            ...body,
            color: colorSystem.neutral[8],
            background: 'unset',
            padding: '0px',
            borderRadius: 'unset',
            border: `unset`,
            fontSize: '16px',
        },
    },
});
