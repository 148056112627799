import { t } from '@lingui/macro';
import { UserInviteInsertDTO } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../utils/routes';

function extractUserRecords(users: Array<{ id: string; email: string }>) {
    return users.map((u) => {
        return {
            id: u.id,
            email: u.email,
        };
    });
}

export function UsersImporterPage() {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { data: users = [] } = useHttpQuery(
        'GET /users/organization',
        {},
        {
            select: (res) => extractUserRecords(res.data),
            suspense: true,
        },
    );
    const { data: invites = [] } = useHttpQuery(
        'GET /users/organization/invites',
        {},
        {
            select: (res) => extractUserRecords(res.data),
            suspense: true,
        },
    );

    const { mutateAsync } = useHttpMutation('POST /users/organization/invites', {
        snackbarMessage: null,
    });

    return (
        <UniversalImporter
            hrefBack={route('/settings/organization/users')}
            title={t`Import users`}
            onImportDone={() => {
                enqueueSnackbar(t`Invitations sent`, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                history.push(route('/settings/organization/users'));
            }}
            onImportBatch={async (records) => {
                return mutateAsync({
                    requestBody: records.flatMap((r): UserInviteInsertDTO[] => {
                        // eslint-disable-next-line camelcase
                        const record: UserInviteInsertDTO = { ...r.data, user_role: 'Member' };
                        if (r.action === 'insert') {
                            return [record];
                        }
                        return [];
                    }),
                })
                    .then((_) => {
                        return records.map((_) => {
                            return { success: true as const };
                        });
                    })
                    .catch((_) => {
                        return records.map((_) => {
                            return { success: false as const, message: t`Failed to import` };
                        });
                    });
            }}
            config={{
                fields: [
                    {
                        id: 'email' as const,
                        label: t`Email`,
                        required: true,
                        parser: {
                            type: 'email',
                            options: {
                                records: users.concat(invites).map((u) => {
                                    return {
                                        id: u.id,
                                        email: u.email,
                                    };
                                }),
                                errorOnExisting: true,
                            },
                        },
                        description: t`The email address of the user. Note that this importer currently only supports inviting users, you cannot use it to update existing users`,
                        columnIndices: [],
                    },
                    {
                        id: 'first_name' as const,
                        label: t`First name`,
                        required: true,
                        parser: {
                            type: 'string',
                            options: { trim: true, minLength: 1, maxLength: 50 },
                        },
                        description: t`The first name of the user`,
                        columnIndices: [],
                    },
                    {
                        id: 'last_name' as const,
                        label: t`Last name`,
                        required: true,
                        parser: {
                            type: 'string',
                            options: { trim: true, minLength: 1, maxLength: 50 },
                        },
                        description: t`The last name of the user`,
                        columnIndices: [],
                    },
                    {
                        id: 'language' as const,
                        label: t`Language`,
                        required: false,
                        defaultValue: {
                            id: 'en',
                            label: t`English`,
                        },
                        parser: {
                            type: 'language',
                            options: {},
                        },
                        description: t`The language the user prefers`,
                        columnIndices: [],
                    },
                ],
                skipRows: [],
            }}
        />
    );
}
