import { formatLongDateTime, formatRelativeTime } from '@luminovo/commons';
import { Flexbox, FontVariant, Text, colorSystem } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';

export function TimeStamp({
    title,
    time,
    variant = 'h5',
}: {
    title: string;
    time: string;
    variant?: FontVariant;
}): JSX.Element {
    return (
        <Flexbox gap={4}>
            <Text variant={variant} style={{ color: colorSystem.neutral[5] }}>
                {title}
            </Text>
            <Tooltip title={formatLongDateTime(time)}>
                <Text variant={variant} color={colorSystem.neutral[8]}>
                    {formatRelativeTime(time)}
                </Text>
            </Tooltip>
        </Flexbox>
    );
}
