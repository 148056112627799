import { t, Trans } from '@lingui/macro';
import { PrimaryButton, Toolbar } from '@luminovo/design-system';
import { UserType } from '@luminovo/http-client';
import { AddRounded } from '@mui/icons-material';
import React from 'react';
import { AssemblyMenuButton } from '../../../components/AssemblyMenuButton';
import { useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import { ExportBomSplitButton } from '../../../components/ExportBomButton';
import { ProgressAwareTabs, useSelectedTab } from '../../../components/ProgressAwareTabs';
import { Protected } from '../../../permissions/Protected';
import { useAssembly, useBomBreadcrumbs, useIsBomImportingAllowed } from '../../../resources/assembly/assemblyHandler';
import { route } from '../../../utils/routes';
import { MonitoringButton } from './AssemblyMonitoring/MonitoringButton';
import { ImportBomButton } from './ImportBomButton';
import { ViewContext } from './ModuleTableData';

export function ToolbarBom({
    rfqId,
    assemblyId,
    isRfqEditable,
    isMonitoringOpen,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    isMonitoringOpen?: string | null;
    currentParentAssemblyId: string | null | undefined;
}) {
    const { data: assembly } = useAssembly(assemblyId);
    const isCustomer = useUserType() === UserType.Customer;
    const breadcrumbs: Array<{ href?: string; title: string | JSX.Element }> = useBomBreadcrumbs({
        rfqId,
        assemblyId,
        assembly,
        currentParentAssemblyId,
    });
    const tab = useSelectedTab();
    const currentBreadcrumb = () => {
        if (tab === 'Manufacturing') {
            return [{ title: t`Files` }];
        }
        if (tab === 'Cad') {
            return [{ title: t`CAD` }];
        }

        return [];
    };

    const isTopLevel = assembly?.id === rfqId;

    const isPcbOnlyAssembly = assembly?.type.type === 'PcbOnly';

    const isBomImportAllowed = useIsBomImportingAllowed({ rfqId, assemblyId });

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <Toolbar
            breadcrumbs={[...breadcrumbs, ...currentBreadcrumb()]}
            center={
                assembly &&
                !isTopLevel && (
                    <ProgressAwareTabs
                        tab={tab}
                        rfqId={rfqId}
                        assembly={assembly}
                        viewContext={viewContext}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )
            }
        >
            {tab === 'Bom' && !isTopLevel && !isCustomer && (
                <MonitoringButton
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    isMonitoringOpen={isMonitoringOpen}
                />
            )}
            {tab === 'Bom' && !isTopLevel && (
                <AssemblyMenuButton
                    viewContext={viewContext}
                    assemblyId={assemblyId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
            {tab === 'Bom' && !isTopLevel && !isPcbOnlyAssembly && !isBomImportAllowed && (
                <ExportBomSplitButton assemblyId={assemblyId} />
            )}
            {tab === 'Bom' && isRfqEditable && (
                <ImportBomButton rfqId={rfqId} assemblyId={assemblyId} viewContext={viewContext} />
            )}
            {isTopLevel && !isCustomer && (
                <Protected requiredPermissions={['create:assembly']}>
                    <PrimaryButton
                        disabled={!isRfqEditable}
                        size="medium"
                        startIcon={<AddRounded />}
                        href={route('/rfqs/:rfqId/bom/assembly/add-assembly', { rfqId }, { assemblyId: assemblyId })}
                    >
                        <Trans>Add assembly</Trans>
                    </PrimaryButton>
                </Protected>
            )}
        </Toolbar>
    );
}
