import { t } from '@lingui/macro';
import {
    ErrorSpan,
    FormulaErrorDTO,
    LexerErrorDTO,
    ReservedKeywordKind,
    RuntimeErrorDTO,
    RuntimeErrorKind,
    RuntimeTestErrorDTO,
    SyntaxErrorDetails,
    SyntaxErrorDTO,
    SyntaxErrorKind,
    SyntaxErrorTokenKind,
    TokenType,
    UndefinedIdentifierKind,
    ValidationErrorDTO,
} from '@luminovo/http-client';
import { assertUnreachable, isKeyOf } from '../../../../../utils/typingUtils';

export function isSyntaxError(formulaError: FormulaErrorDTO): formulaError is SyntaxErrorDTO {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (formulaError as SyntaxErrorDTO).type === 'SyntaxError';
}

export function isValidationError(formulaError: FormulaErrorDTO): formulaError is ValidationErrorDTO {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (formulaError as ValidationErrorDTO).type === 'ValidationError';
}

export function isLexerError(formulaError: FormulaErrorDTO): formulaError is LexerErrorDTO {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (formulaError as LexerErrorDTO).type === 'LexerError';
}

export function isRuntimeTestError(formulaError: FormulaErrorDTO): formulaError is RuntimeTestErrorDTO {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (formulaError as RuntimeTestErrorDTO).type === 'RuntimeError';
}

export function isRuntimeError(formulaError: FormulaErrorDTO): formulaError is RuntimeErrorDTO {
    return isKeyOf(formulaError, 'kind');
}

function isSyntaxErrorTokenKind(detail: SyntaxErrorDetails): detail is SyntaxErrorTokenKind {
    return isKeyOf(detail, 'token');
}

function isUndefinedRuntimeErrorKind(detail: RuntimeErrorKind): detail is UndefinedIdentifierKind {
    if (typeof detail === 'object') {
        return detail.type === 'UndefinedIdentifier';
    }
    return false;
}

function isReservedKeywordKind(detail: RuntimeErrorKind): detail is ReservedKeywordKind {
    if (typeof detail === 'object') {
        return detail.type === 'ReservedKeyword';
    }

    return false;
}

type ErrorLine = {
    column: number;
    line: number;
    text: string;
};

export type ErrorDetails = {
    line: string;
    kind: string | undefined;
};

const generateTokenCharacterMap = (): Record<TokenType, string> => ({
    If: 'if',
    ElseIf: 'else if',
    Else: 'else',
    Plus: '+',
    Minus: '-',
    Divide: '/',
    Multiply: '*',
    And: 'and',
    Or: 'or',
    Equal: '=',
    NotEqual: '!=',
    GreaterThanOrEqual: '>=',
    LessThanOrEqual: '<=',
    GreaterThan: '>',
    LessThan: '<',
    Comma: ',',
    LParen: '(',
    RParen: ')',
    LBrace: '{',
    RBrace: '}',
    SingleLineComment: '//',
    // eslint-disable-next-line lingui/t-call-in-function
    Eof: t`end of file`,
    Blank: '""',
});

function extractSyntaxErrorDetailMessage(detail: SyntaxErrorDetails): string | undefined {
    if (isSyntaxErrorTokenKind(detail)) {
        return `'${generateTokenCharacterMap()[detail.token]}'`;
    }

    return detail.toString();
}

function extractSyntaxErrorKindMessage(kind: SyntaxErrorKind) {
    switch (kind.type) {
        case 'Expected':
            if (!extractSyntaxErrorDetailMessage(kind.details.details)) {
                return;
            }
            return t`Expected ${extractSyntaxErrorDetailMessage(kind.details.details)}`;

        case 'ExpectedAnyOf':
            const characters = kind.details.map(({ details }) => extractSyntaxErrorDetailMessage(details));
            return t`Expected one of ${characters.toString()}`;

        case 'Unexpected':
            if (!extractSyntaxErrorDetailMessage(kind.details.details)) {
                return;
            }
            return t`Unexpected ${extractSyntaxErrorDetailMessage(kind.details.details)}`;

        default:
            assertUnreachable(kind);
    }
}

function extractRuntimeErrorKindMessage(detail: RuntimeErrorKind) {
    if (isUndefinedRuntimeErrorKind(detail)) {
        return `${t`Undefined identifier`} '${detail.details.toString()}'`;
    }

    if (isReservedKeywordKind(detail)) {
        return `${t`Reserved keyword`} '${detail.details.toString()}'`;
    }

    switch (detail.type) {
        case 'ArgumentMissing':
            return t`Argument missing`;
        case 'DivideByZero':
            return t`Divide by zero`;
        case 'ExpectedBoolean':
            return t`Expected boolean`;
        case 'ExpectedCall':
            return t`Expected call`;
        case 'ExpectedFloat':
            return t`Expected float`;
        case 'TooManyArguments':
            return t`Too many arguments`;
        case 'ExpectedUnsignedInteger':
            return t`Expected positive integer`;
        case 'OutOfI32Range':
            return t`Number too high`;
        default:
            assertUnreachable(detail);
    }
}

export function extractErrorLineMessage(span: ErrorSpan): ErrorLine {
    return {
        column: span.start_column,
        line: span.start_line,
        text: t`line ${span.start_line}, column ${span.start_column}`,
    };
}

export function extractFormulaSyntaxError(formulaError: SyntaxErrorDTO): ErrorDetails {
    const errorLine = extractErrorLineMessage(formulaError.details.span);
    const errorKind = extractSyntaxErrorKindMessage(formulaError.details.kind);

    return {
        line: t`Syntax error (${errorLine.text})`,
        kind: errorKind,
    };
}

export function extractFormulaValidationError(formulaError: ValidationErrorDTO): ErrorDetails {
    const errorLine = t`Validation error`;
    const errorKind = extractRuntimeErrorKindMessage(formulaError.details);

    return {
        line: errorLine,
        kind: errorKind,
    };
}

export function extractFormulaRuntimeError(formulaError: RuntimeErrorDTO): ErrorDetails {
    const errorLine = extractErrorLineMessage(formulaError.span);
    const errorKind = extractRuntimeErrorKindMessage(formulaError.kind);

    return {
        line: t`Runtime error (${errorLine.text})`,
        kind: errorKind,
    };
}
