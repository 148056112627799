import { colorSystem, Text } from '@luminovo/design-system';
import { Box, BoxProps, Tooltip } from '@mui/material';

export const DriverNotes = ({
    notes,
    maxLength = Infinity,
    boxProps,
}: {
    notes: string;
    maxLength?: number;
    boxProps?: BoxProps;
}) => {
    return (
        <Box
            padding={'12px'}
            bgcolor={colorSystem.neutral[1]}
            border={`1px solid`}
            borderColor={colorSystem.neutral[2]}
            borderRadius="8px"
            {...boxProps}
        >
            {notes.length > maxLength ? (
                <Tooltip title={notes ?? ''}>
                    <Text>{notes?.slice(0, maxLength)}...</Text>
                </Tooltip>
            ) : (
                <Text> {notes}</Text>
            )}
        </Box>
    );
};
