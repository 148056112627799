import { transEnum } from '@luminovo/commons';
import { colorSystem, StatusChip } from '@luminovo/design-system';
import { QuoteRequestLineItemStatus } from '@luminovo/http-client';
import { quoteRequestLineItemStatusTranslations } from '../i18n';

export function QuoteRequestLineItemStatusChip({
    quoteRequestLineItemStatus,
}: {
    quoteRequestLineItemStatus: QuoteRequestLineItemStatus;
}) {
    return (
        <StatusChip
            color={getQuoteRequestLineItemStatusColor(quoteRequestLineItemStatus)}
            label={transEnum(quoteRequestLineItemStatus, quoteRequestLineItemStatusTranslations)}
        />
    );
}

export function getQuoteRequestLineItemStatusColor(
    quoteRequestLineItemStatus: QuoteRequestLineItemStatus,
): keyof typeof colorSystem {
    switch (quoteRequestLineItemStatus) {
        case QuoteRequestLineItemStatus.NotSent:
            return 'neutral';
        case QuoteRequestLineItemStatus.Sent:
            return 'blue';
        case QuoteRequestLineItemStatus.Overdue:
            return 'red';
        case QuoteRequestLineItemStatus.Received:
            return 'green';
        case QuoteRequestLineItemStatus.NoBid:
            return 'violet';
        case QuoteRequestLineItemStatus.Discarded:
            return 'yellow';
    }
}
