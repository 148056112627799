import { ToggleButton, ToggleButtonProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { bodySmall, colorSystem } from '../../theme';

const DefaultToggleItem = styled(ToggleButton)({
    ...bodySmall,
    color: colorSystem.neutral[7],
    padding: '6px',
    minWidth: '24px',
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: colorSystem.neutral.white,
        color: colorSystem.neutral[9],
        boxShadow: `0px 2px 4px 1px rgba(76,83,102,0.15)`,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButtonGroup-grouped': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },
});

export const ToggleButtonItem = forwardRef(function ToggleButtonItem(
    { ...props }: ToggleButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element {
    return (
        <DefaultToggleItem ref={ref} disableRipple {...props}>
            {props.children}
        </DefaultToggleItem>
    );
});
