import { colorSystem, h5 } from '@luminovo/design-system';
import { OverallRisk } from '@luminovo/http-client';
import { Avatar, styled } from '@mui/material';

export const RiskAvatar = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    const scoreLetterMapping: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: 'A',
        [OverallRisk.MediumLowRisk]: 'B',
        [OverallRisk.MediumRisk]: 'C',
        [OverallRisk.MediumHighRisk]: 'D',
        [OverallRisk.HighRisk]: 'E',
    };
    const scoreColorMapping: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['7'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['5'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['6'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['5'],
        [OverallRisk.HighRisk]: colorSystem.red['7'],
    };
    return (
        <StyledOverallRiskAvatar
            variant="rounded"
            style={{
                backgroundColor: scoreColorMapping[overallRisk],
            }}
        >
            {scoreLetterMapping[overallRisk]}
        </StyledOverallRiskAvatar>
    );
};

const StyledOverallRiskAvatar = styled(Avatar)({
    height: '22px',
    width: '22px',
    ...h5,
});
