import { ImporterConfig, Table } from '../types';

export function inferDecimalSeparator(rows: Table, colIndex: number): ',' | '.' {
    const columnValues = rows.map((row) => row[colIndex]);
    const separatorFrequency = countDecimalSeparatorFrequency(columnValues);
    return separatorFrequency.commaFrequency > separatorFrequency.dotFrequency ? ',' : '.';
}

export function inferDecimalSeparatorOnConfig(table: Table | undefined, config: ImporterConfig): ImporterConfig {
    if (!table) {
        return config;
    }

    const newConfig = { ...config };
    for (const field of newConfig.fields) {
        if (field.columnIndices.length === 1 && 'decimalSeparator' in field.parser.options) {
            const decimalSeparator = inferDecimalSeparator(table, field.columnIndices[0]);
            field.parser.options.decimalSeparator = decimalSeparator;
        }
    }
    return newConfig;
}

export function countDecimalSeparatorFrequency(values: string[]): { commaFrequency: number; dotFrequency: number } {
    let commaFrequency = 0;
    let dotFrequency = 0;
    for (const value of values) {
        // Example: ",0001"
        if (/^,\d+$/.test(value)) {
            commaFrequency++;
        }
        // Example: ".0001"
        if (/^\.\d+$/.test(value)) {
            dotFrequency++;
        }

        // Example: "0,0001"
        if (/^0\.\d+$/.test(value)) {
            dotFrequency++;
        }
        // Example: "0.0001"
        if (/^0,\d+$/.test(value)) {
            commaFrequency++;
        }

        if (/\d+\.\d+$/.test(value)) {
            dotFrequency++;
        }
        if (/\d+,\d+$/.test(value)) {
            commaFrequency++;
        }
    }
    return { commaFrequency, dotFrequency };
}
