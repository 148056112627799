import { Point } from './Point';
import { maxBy } from './RegionNetwork/maxBy';
import { minBy } from './RegionNetwork/minBy';
import { Viewbox } from './Viewbox';

export class BoundingBox {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    // eslint-disable-next-line max-params
    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    applyViewbox(viewbox: Viewbox): BoundingBox {
        return new BoundingBox(
            this.x * viewbox.width,
            this.y * viewbox.height,
            this.width * viewbox.width,
            this.height * viewbox.height,
        );
    }

    multiply(factor: number): BoundingBox {
        return new BoundingBox(this.x * factor, this.y * factor, this.width * factor, this.height * factor);
    }

    // Adds padding while keeping the center of the bounding box the same
    addPadding(padding: number): BoundingBox {
        return new BoundingBox(this.x - padding, this.y - padding, this.width + 2 * padding, this.height + 2 * padding);
    }

    rightCenter(): { x: number; y: number } {
        return { x: this.x + this.width, y: this.y + this.height / 2 };
    }

    bottomCenter(): { x: number; y: number } {
        return { x: this.x + this.width / 2, y: this.y + this.height };
    }

    leftCenter(): { x: number; y: number } {
        return { x: this.x, y: this.y + this.height / 2 };
    }

    topCenter(): { x: number; y: number } {
        return { x: this.x + this.width / 2, y: this.y };
    }

    topLeft(): { x: number; y: number } {
        return { x: this.x, y: this.y };
    }

    bottomRight(): { x: number; y: number } {
        return { x: this.x + this.width, y: this.y + this.height };
    }

    center(): { x: number; y: number } {
        return { x: this.x + this.width / 2, y: this.y + this.height / 2 };
    }

    area(): number {
        return this.width * this.height;
    }

    intersection(other: BoundingBox): BoundingBox {
        if (!this.intersects(other)) {
            return BoundingBox.fromPoint({ x: 0, y: 0 });
        }

        const x = Math.max(this.x, other.x);
        const y = Math.max(this.y, other.y);
        const width = Math.min(this.x + this.width, other.x + other.width) - x;
        const height = Math.min(this.y + this.height, other.y + other.height) - y;
        return new BoundingBox(x, y, width, height);
    }

    approximatelyEquals(other: BoundingBox): boolean {
        return this.intersection(other).area() > 0.9 * Math.max(this.area(), other.area());
    }

    overlapInPercent(other: BoundingBox): number {
        const overlapArea = this.intersection(other).area();
        return overlapArea / (this.area() + other.area() - overlapArea);
    }

    translateY(y: number): BoundingBox {
        return new BoundingBox(this.x, this.y + y, this.width, this.height);
    }

    static fromPolygon(polygon: Array<number>): BoundingBox {
        const x = Math.min(...polygon.filter((_, i) => i % 2 === 0));
        const y = Math.min(...polygon.filter((_, i) => i % 2 === 1));
        const width = Math.max(...polygon.filter((_, i) => i % 2 === 0)) - x;
        const height = Math.max(...polygon.filter((_, i) => i % 2 === 1)) - y;
        return new BoundingBox(x, y, width, height);
    }

    static fromPolygons(polygons: Array<{ polygon: number[] }>): BoundingBox {
        if (polygons.length === 0) {
            return BoundingBox.fromPoint({ x: 0, y: 0 });
        }
        if (polygons.length === 1) {
            return BoundingBox.fromPolygon(polygons[0].polygon);
        }
        return polygons.map((p) => BoundingBox.fromPolygon(p.polygon)).reduce((a, b) => a.merge(b));
    }

    static fromPoint(point: Point): BoundingBox {
        return new BoundingBox(point.x, point.y, 0, 0);
    }

    /**
     * Returns the smallest bounding box that contains all the given bounding boxes.
     */
    static fromMany(boxes: BoundingBox[]): BoundingBox {
        if (boxes.length === 0) {
            return BoundingBox.fromPoint({ x: 0, y: 0 });
        }
        const x = minBy(boxes, (b) => b.x)!.x;
        const y = minBy(boxes, (b) => b.y)!.y;
        const xf = maxBy(boxes, (b) => b.x + b.width)!;
        const yf = maxBy(boxes, (b) => b.y + b.height)!;

        return this.fromPoints({ x, y }, { x: xf.x + xf.width, y: yf.y + yf.height });
    }

    static fromPoints(a: Point, b: Point): BoundingBox {
        const minX = Math.min(a.x, b.x);
        const minY = Math.min(a.y, b.y);
        return new BoundingBox(minX, minY, Math.abs(a.x - b.x), Math.abs(a.y - b.y));
    }

    contains(other: BoundingBox): boolean {
        return (
            this.x <= other.x &&
            this.y <= other.y &&
            this.x + this.width >= other.x + other.width &&
            this.y + this.height >= other.y + other.height
        );
    }

    containsPoint(point: Point): boolean {
        return (
            this.x <= point.x && this.y <= point.y && this.x + this.width >= point.x && this.y + this.height >= point.y
        );
    }

    isTopLeftOf(other: BoundingBox): boolean {
        return this.x <= other.x + other.width && this.y <= other.y + other.height;
    }

    points(): Point[] {
        return [
            { x: this.x, y: this.y },
            { x: this.x + this.width, y: this.y },
            { x: this.x + this.width, y: this.y + this.height },
            { x: this.x, y: this.y + this.height },
        ];
    }

    intersects(other: BoundingBox): boolean {
        return (
            this.x <= other.x + other.width &&
            this.x + this.width >= other.x &&
            this.y <= other.y + other.height &&
            this.y + this.height >= other.y
        );
    }

    merge(other: BoundingBox): BoundingBox {
        const x = Math.min(this.x, other.x);
        const y = Math.min(this.y, other.y);
        const width = Math.max(this.x + this.width, other.x + other.width) - x;
        const height = Math.max(this.y + this.height, other.y + other.height) - y;
        return new BoundingBox(x, y, width, height);
    }

    directionTo(other: BoundingBox): 'left' | 'right' | 'up' | 'down' {
        const xDist = this.x - other.x;
        const yDist = this.y - other.y;

        if (Math.abs(xDist) > Math.abs(yDist)) {
            return xDist > 0 ? 'left' : 'right';
        } else {
            return yDist > 0 ? 'up' : 'down';
        }
    }
}
