import { Column, RowCheckbox } from '@luminovo/design-system';

export function selectionColumnWithDisabledRows<T>({
    column = {},
    onClick,
}: {
    column?: Partial<Omit<Column<T>, 'renderHead'>>;
    onClick?: ({ rowId }: { rowId: string }) => void;
}): Column<T> {
    const { label = '', id = 'selection', ...rest } = column;
    return {
        label,
        id,
        render: (data) => {
            return <RowCheckbox data={data} onClick={onClick} />;
        },
        ...rest,
    };
}
