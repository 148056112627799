import {
    CustomComponentFull,
    CustomFullPart,
    EmsPartNumberPartMatchFullPart,
    FullPart,
    GenericCapacitor,
    GenericFullPart,
    GenericPart,
    GenericPartTypes,
    GenericResistor,
    OtsComponentFull,
    OtsFullPart,
    StandardPartTypes,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { transEnum } from '../../components/localization/TransEnum';
import { formatCapacitance, formatPowerRating, formatVoltageRating } from '../../utils/converterUtils';
import { assertUnreachable } from '../../utils/typingUtils';
import { lifecycleEnumLongNamePublicTranslations } from './partFrontendTypes';

function indexOtsFullPart(part: OtsFullPart): string[] {
    const index: string[] = [];

    index.push(part.manufacturer.name);
    index.push(transEnum(part.lifecycle_status, lifecycleEnumLongNamePublicTranslations));
    index.push(part.mpn);
    index.push(part.description ?? '');
    index.push(...part.mpn_aliases);
    index.push(part.package?.name ?? '');
    index.push(part.type ?? '');

    return Array.from(new Set(index));
}

function indexCapacitor({ technical_parameters: params }: GenericCapacitor) {
    const index: string[] = [];

    index.push(params.voltage_rating ? formatVoltageRating(params.voltage_rating) : '');
    index.push(params.capacitance ? formatCapacitance(params.capacitance) : '');
    index.push(params.capacitance ? formatCapacitance(params.capacitance) : '');

    return Array.from(new Set(index));
}

function indexResistor({ technical_parameters: params }: GenericResistor) {
    const index: string[] = [];

    index.push(params.voltage_rating ? formatVoltageRating(params.voltage_rating) : '');
    index.push(params.power_rating ? formatPowerRating(params.power_rating) : '');
    index.push(params.resistance ? formatCapacitance(params.resistance) : '');

    return Array.from(new Set(index));
}

function indexTechnicalParamentes(part: GenericPart): string[] {
    if (part.type === GenericPartTypes.Capacitor) {
        return indexCapacitor(part);
    }
    if (part.type === GenericPartTypes.Resistor) {
        return indexResistor(part);
    }
    assertUnreachable(part);
}

function indexLinkedOtsPart(part: OtsFullPart): string[] {
    return [part.mpn];
}

function indexGenericFullPart(part: GenericFullPart): string[] {
    return [...indexTechnicalParamentes(part.content), ...part.matches.flatMap(indexLinkedOtsPart)];
}

function indexOtsPartsLinkedToIpns(match: EmsPartNumberPartMatchFullPart) {
    if (match.part.type === StandardPartTypes.OffTheShelf) {
        return indexLinkedOtsPart(match.part.data);
    }
    return [];
}

function indexOtsComponentFullPart(part: OtsComponentFull): string[] {
    return Array.from(new Set([part.id, ...part.matches.flatMap((match) => indexOtsPartsLinkedToIpns(match))]));
}

function indexCustomFullPart(part: CustomFullPart): string[] {
    const index: string[] = [];
    index.push(part.description ?? '');
    index.push(part.type.name);

    return Array.from(new Set(index));
}

function indexCustomComponentFull(part: CustomComponentFull): string[] {
    return Array.from(new Set([part.id, ...part.matches.flatMap((match) => indexCustomFullPart(match))]));
}

/**
 * Takes a full part as input and converts it to an array of strings.
 * The strings in this array are essentially the strings by which the user can
 * search for the part.
 *
 * This process is called indexing because this array of strings will eventually
 * be used for searching.
 */
export function indexFullPart(part: FullPart): string[] {
    if (isOtsFullPart(part)) {
        return indexOtsFullPart(part);
    }
    if (isGenericFullPart(part)) {
        return indexGenericFullPart(part);
    }
    if (isOtsComponentFull(part)) {
        return indexOtsComponentFullPart(part);
    }
    if (isCustomFullPart(part)) {
        return indexCustomFullPart(part);
    }
    if (isCustomComponentFull(part)) {
        return indexCustomComponentFull(part);
    }
    assertUnreachable(part);
}
