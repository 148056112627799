import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { MenuButton, MenuItem } from '@luminovo/design-system';
import { SourcingBreadcrumbItemsDTO } from '@luminovo/http-client';
import { extractAggregatedBreadcrumbs } from '@luminovo/sourcing-core';
import { ExitToApp } from '@mui/icons-material';
import React from 'react';
import { useSolutionConfigurationSourcing } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { route } from '../../../../utils/routes';

function routeToBomItem({
    rfqId,
    breadcrumbItems,
}: {
    rfqId: string;
    breadcrumbItems: SourcingBreadcrumbItemsDTO[];
}): string {
    const lastParentAssembly = breadcrumbItems[0].parent_assemblies.slice(-1)[0];
    const assemblyId = lastParentAssembly.id;

    return route(
        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
        { rfqId, assemblyId },
        {
            designItemId: breadcrumbItems.map((item) => item.design_item_id).join(','),
            isReadonly: 'null',
            filters: null,
            bomTab: null,
            dashboardFilters: null,
            search: null,
            onlyShowItemsWithManufacturingWarnings: null,
            currentParentAssemblyId: null,
        },
    );
}

export const ButtonGoToBom = React.memo(function AddPartAlternativesButton({
    rfqId,
    solutionConfigurationId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}): JSX.Element | null {
    const { data: solutionConfigurationSourcing } = useSolutionConfigurationSourcing(solutionConfigurationId);
    const groupedDesignators = extractAggregatedBreadcrumbs(solutionConfigurationSourcing);

    return (
        <MenuButton
            appearance="secondary"
            disabled={!isPresent(groupedDesignators)}
            size="medium"
            icon={<ExitToApp />}
            label={t`Go to BOM`}
        >
            {Object.entries(groupedDesignators ?? {}).map(([key, breadcrumbItems]) => (
                <MenuItem
                    key={key}
                    href={routeToBomItem({ rfqId, breadcrumbItems })}
                    label={breadcrumbItems.map((b) => b.designator).join(', ')}
                />
            ))}
        </MenuButton>
    );
});
