import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { Tooltip, bodySmall, colorSystem } from '@luminovo/design-system';
import { StarRounded } from '@mui/icons-material';
import { Box } from '@mui/material';

export enum PreferredTagEnum {
    Manufacturer = 'manufacturer',
    Ipn = 'ipn',
}

export const preferredTagTranslations: Record<PreferredTagEnum, MessageDescriptor> = {
    [PreferredTagEnum.Manufacturer]: defineMessage({ message: 'Manufacturer' }),
    [PreferredTagEnum.Ipn]: defineMessage({ message: 'IPN' }),
};

export const preferredTagTooltipTranslations: Record<PreferredTagEnum, MessageDescriptor> = {
    [PreferredTagEnum.Manufacturer]: defineMessage({ message: 'Preferred manufacturer' }),
    [PreferredTagEnum.Ipn]: defineMessage({ message: 'Preferred IPN' }),
};

export const PreferredTag = ({
    label,
    tooltip,
    styles,
}: {
    label?: string;
    tooltip?: string;
    styles?: React.CSSProperties;
}): JSX.Element => {
    return (
        <Tooltip title={tooltip ?? ''}>
            <Box
                display="inline-flex"
                alignItems="center"
                gap="2px"
                style={{
                    background: colorSystem.primary[1],
                    color: colorSystem.primary[8],
                    padding: !!label ? '1px 3px' : '2px',
                    border: `1px solid ${colorSystem.primary[2]}`,
                    width: 'fit-content',
                    height: 'fit-content',
                    cursor: 'inherit',
                    whiteSpace: 'nowrap',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    ...bodySmall,
                    ...styles,
                }}
            >
                <StarRounded fontSize="inherit" />
                {label}
            </Box>
        </Tooltip>
    );
};
