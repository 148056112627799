import { Trans, t } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import {
    DataTable,
    Flexbox,
    Link,
    Row,
    SecondaryIconButton,
    Text,
    Tooltip,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import {
    CustomComponentFull,
    CustomFullPart,
    CustomOptionDTO,
    CustomOptionTypes,
    RfqContext,
} from '@luminovo/http-client';
import { customPartTypeTranslations } from '@luminovo/sourcing-core';
import { Delete, DescriptionOutlined } from '@mui/icons-material';
import { useCallback } from 'react';
import { ApprovalStatusSelect } from '../../../../components/ApprovalStatusSelect';
import { iconForStatus } from '../../../../components/Icons/icons';
import { PartOptionOriginTag } from '../../../../components/PartOptionOriginTag';
import { Skeleton } from '../../../../components/Spinners';
import { CpnView, RenderDescription, RenderIpn } from '../../../../components/partColumns';
import { TableCell } from '../../../../components/partColumns/TableCell';
import { useCustomComponentDrawer } from '../../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useCustomPartCardDrawer } from '../../../../components/partSpecificationCards/CustomPart/CustomPartCard';
import { useCustomPartResources } from '../../../../resources/part/partHandler';
import { getFileNameFromResourcesString } from '../../../../utils/stringFunctions';
import { TableContainer } from '../AddParts/TableContainer';

type PartOptionsContext = {
    isRfqEditable: boolean;
    isSubmitting: boolean;
    rfqContext: RfqContext;
    customPartsMap: Map<string, CustomFullPart>;
    customComponentsMap: Map<string, CustomComponentFull>;
    handleUpdatePartOption: (newPart: CustomOptionDTO) => void;
    handleRemovePartOption: (partId: string) => void;
};

enum customPartOptColumnIds {
    columnIpn = `custom-spec-ipn`,
    columnCpn = `custom-spec-cpn-rev`,
    columnType = `custom-spec-type`,
    columnDescription = `custom-spec-description`,
    columnFiles = `custom-spec-files`,
    columnStatus = `custom-spec-status`,
}

export const CustomPartOptionsTable = ({
    customPartSpecs,
    customPartsMap,
    customComponentsMap,
    isRfqEditable,
    isSubmitting,
    handleUpdatePartOption,
    handleRemovePartOption,
    rfqContext,
    hasPartOptionsWithCpns,
}: {
    customPartSpecs: CustomOptionDTO[];
    customPartsMap: Map<string, CustomFullPart>;
    customComponentsMap: Map<string, CustomComponentFull>;
    isRfqEditable: boolean;
    isSubmitting: boolean;
    handleUpdatePartOption: (newPart: CustomOptionDTO) => void;
    handleRemovePartOption: (partId: string) => void;
    rfqContext: RfqContext;
    hasPartOptionsWithCpns: boolean;
}) => {
    const { openDrawer: openCustomPartDrawer } = useCustomPartCardDrawer();
    const { openDrawer: openCustomComponentDrawer } = useCustomComponentDrawer();

    const sharedContext = {
        isRfqEditable,
        isSubmitting,
        customPartsMap,
        customComponentsMap,
        handleUpdatePartOption,
        handleRemovePartOption,
        rfqContext,
    };

    const tableState = useDataTableState({
        columns: hasPartOptionsWithCpns ? columnsWithCpns : columnsWithoutCpns,
        items: customPartSpecs,
        persistenceId: `custom-part-options`,
        paginationOptions: { showPagination: false },
        sharedContext,
    });

    const handleRowClick = useCallback(
        (part: CustomOptionDTO) => {
            if (part.part.type === CustomOptionTypes.CustomPart) {
                const fullPart = customPartsMap.get(part.part.data);
                if (!fullPart) {
                    return;
                }
                return openCustomPartDrawer({ partId: fullPart.id });
            }
            if (part.part.type === CustomOptionTypes.CustomComponent) {
                return openCustomComponentDrawer({ componentId: part.part.data, rfqContext });
            }
        },
        [customPartsMap, openCustomComponentDrawer, openCustomPartDrawer, rfqContext],
    );

    return (
        <DataTable
            size="large"
            tableState={tableState}
            stickyHeader={false}
            onItemClick={handleRowClick}
            overrides={{ Container: TableContainer }}
        />
    );
};

const columnIpn = {
    id: customPartOptColumnIds.columnIpn,
    label: <Trans>IPN</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { customPartsMap, customComponentsMap }: PartOptionsContext,
    ): JSX.Element => {
        const part =
            data.part.type === CustomOptionTypes.CustomPart
                ? customPartsMap.get(data.part.data)
                : customComponentsMap.get(data.part.data);

        if (!part) {
            return <TableCell />;
        }
        return <RenderIpn part={part} />;
    },
};

const columnCpn = {
    id: customPartOptColumnIds.columnCpn,
    label: <Trans>CPN • Rev</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { customPartsMap, customComponentsMap }: PartOptionsContext,
    ): JSX.Element => {
        const part =
            data.part.type === CustomOptionTypes.CustomPart
                ? customPartsMap.get(data.part.data)
                : customComponentsMap.get(data.part.data);

        if (!part) {
            return <TableCell />;
        }
        return (
            <TableCell>
                <CpnView part={part} />
            </TableCell>
        );
    },
};

const columnType = {
    id: customPartOptColumnIds.columnType,
    label: <Trans>Type</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { customPartsMap, customComponentsMap }: PartOptionsContext,
    ): JSX.Element => {
        const customOptionType = data.part.type;
        if (customOptionType === CustomOptionTypes.CustomPart) {
            const fullCustomPart = customPartsMap.get(data.part.data);
            if (!fullCustomPart) {
                return <TableCell />;
            }
            return <TableCell>{transEnum(fullCustomPart.type.name, customPartTypeTranslations)}</TableCell>;
        }
        if (customOptionType === CustomOptionTypes.CustomComponent) {
            const fullCustomComponent = customComponentsMap.get(data.part.data);
            if (!fullCustomComponent) {
                return <TableCell />;
            }
            return (
                <TableCell isComponentRemoved={fullCustomComponent.state === 'Removed'}>
                    {fullCustomComponent.matches.length > 0 ? fullCustomComponent.matches[0].type.name : '-'}
                </TableCell>
            );
        }
        assertUnreachable(customOptionType);
    },
};

const columnDescription = {
    id: customPartOptColumnIds.columnDescription,
    label: <Trans>Description</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { customPartsMap, customComponentsMap }: PartOptionsContext,
    ): JSX.Element => {
        const part =
            data.part.type === CustomOptionTypes.CustomPart
                ? customPartsMap.get(data.part.data)
                : customComponentsMap.get(data.part.data);

        if (!part) {
            return <TableCell />;
        }
        return <RenderDescription part={part} />;
    },
};

const RenderFiles = ({ part }: { part: CustomFullPart }) => {
    const { data: resources = [], isLoading } = useCustomPartResources(part.id);
    if (resources.length === 0) {
        return <TableCell>-</TableCell>;
    }

    if (isLoading) {
        return (
            <TableCell>
                <Skeleton style={{ cursor: 'progress' }} width="100px" />
            </TableCell>
        );
    }

    return (
        <TableCell>
            <Flexbox gap="8px" alignItems="center">
                {resources.map((resource, index) => {
                    if (index < 1) {
                        return (
                            <Link
                                href={resource}
                                onClick={(e) => e.stopPropagation()}
                                download={resource}
                                attention="low"
                                startIcon={<DescriptionOutlined />}
                            >
                                {getFileNameFromResourcesString(resource)}
                            </Link>
                        );
                    }
                    if (index === 1) {
                        return (
                            <Tooltip
                                key={index}
                                variant={'white'}
                                title={
                                    <Flexbox flexDirection={'column'}>
                                        <ul
                                            style={{
                                                margin: 0,
                                                paddingLeft: 18,
                                                maxHeight: 200,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {resources.map((resource, i) => (
                                                <Link
                                                    href={resource}
                                                    key={`tooltip-${i}`}
                                                    download={resource}
                                                    attention="low"
                                                    startIcon={<DescriptionOutlined />}
                                                >
                                                    {getFileNameFromResourcesString(resource)}
                                                </Link>
                                            ))}
                                        </ul>
                                    </Flexbox>
                                }
                            >
                                <span>
                                    <Text variant="body-small" color={colorSystem.neutral[6]}>
                                        +{resources.length - 1}
                                    </Text>
                                </span>
                            </Tooltip>
                        );
                    }
                    return null;
                })}
            </Flexbox>
        </TableCell>
    );
};

const columnFiles = {
    id: customPartOptColumnIds.columnFiles,
    label: <Trans>Files</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { customPartsMap, customComponentsMap }: PartOptionsContext,
    ): JSX.Element => {
        const customOptionType = data.part.type;
        if (customOptionType === CustomOptionTypes.CustomPart) {
            const fullCustomPart = customPartsMap.get(data.part.data);
            if (!fullCustomPart) {
                return <TableCell />;
            }
            return <RenderFiles part={fullCustomPart} />;
        }
        if (customOptionType === CustomOptionTypes.CustomComponent) {
            const fullCustomComponent = customComponentsMap.get(data.part.data);
            if (!fullCustomComponent) {
                return <TableCell />;
            }
            return <TableCell isComponentRemoved={fullCustomComponent.state === 'Removed'}>-</TableCell>;
        }
        assertUnreachable(customOptionType);
    },
};

const columnStatus = {
    id: customPartOptColumnIds.columnStatus,
    label: <Trans>Status</Trans>,
    render: (
        { data }: Row<CustomOptionDTO>,
        { isRfqEditable, handleUpdatePartOption, isSubmitting, handleRemovePartOption }: PartOptionsContext,
    ): JSX.Element => {
        if (!isRfqEditable) {
            return (
                <TableCell>
                    <Flexbox gap="8px" alignItems="center">
                        {iconForStatus({ status: data.approval_status })}
                        {data.origin && <PartOptionOriginTag origin={data.origin} />}
                    </Flexbox>
                </TableCell>
            );
        }
        return (
            <TableCell>
                <Flexbox gap="8px" alignItems="center" justifyContent="space-between">
                    <Flexbox gap="8px" alignItems="center">
                        <ApprovalStatusSelect
                            value={data.approval_status}
                            onChange={(newApprovalStatus) => {
                                handleUpdatePartOption({
                                    ...data,
                                    // eslint-disable-next-line camelcase
                                    approval_status: newApprovalStatus,
                                });
                            }}
                        />
                        {data.origin && <PartOptionOriginTag origin={data.origin} />}
                    </Flexbox>
                    <Tooltip title={t`Remove part`}>
                        <span>
                            <SecondaryIconButton
                                disabled={isSubmitting}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemovePartOption(data.part.data);
                                }}
                                size="small"
                            >
                                <Delete fontSize="inherit" />
                            </SecondaryIconButton>
                        </span>
                    </Tooltip>
                </Flexbox>
            </TableCell>
        );
    },
};

const columnsWithCpns = [columnIpn, columnCpn, columnType, columnDescription, columnFiles, columnStatus];

const columnsWithoutCpns = columnsWithCpns.filter((col) => col.id !== customPartOptColumnIds.columnCpn);
