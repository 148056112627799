/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetAbacusLocaleCurrencyDTO = r.Static<typeof AvnetAbacusLocaleCurrencyDTORuntype>;
export const AvnetAbacusLocaleCurrencyDTORuntype = r.Union(
    r.Literal('EUR'),
    r.Literal('USD'),
    r.Literal('GBP'),
    r.Literal('JPY'),
);

export interface AvnetAbacusCredentialsDTO extends r.Static<typeof AvnetAbacusCredentialsDTORuntype> {}
export const AvnetAbacusCredentialsDTORuntype = r.Union(
    r.Record({
        username: r.String,
        password: r.String,
        currency: AvnetAbacusLocaleCurrencyDTORuntype,
    }),
);

export interface AvnetAbacusCredentialsInputDTO extends r.Static<typeof AvnetAbacusCredentialsInputDTORuntype> {}
export const AvnetAbacusCredentialsInputDTORuntype = r.Record({
    username: r.String.optional(),
    password: r.String.optional(),
    currency: AvnetAbacusLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetAbacusResponseBodyDTO extends r.Static<typeof AvnetAbacusResponseBodyDTORuntype> {}
export const AvnetAbacusResponseBodyDTORuntype = r.Record({
    data: AvnetAbacusCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
