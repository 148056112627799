import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { QuoteTrackingDTO, QuoteTrackingState } from '@luminovo/http-client';
import React from 'react';

export const QuoteTrackingStateChip: React.FunctionComponent<{
    quoteTracking: Pick<QuoteTrackingDTO, 'state'>;
    user?: 'supplier' | 'ems';
}> = ({ quoteTracking, user = 'ems' }) => {
    const label = formatQuoteTrackingState(quoteTracking.state, user);
    if (quoteTracking.state === 'Received') {
        return <StatusChip color="green" label={label} />;
    }
    if (quoteTracking.state === 'Pending') {
        return <StatusChip color="yellow" label={label} />;
    }
    if (quoteTracking.state === 'Overdue') {
        return <StatusChip color="red" label={label} />;
    }

    if (quoteTracking.state === 'Discarded') {
        return <StatusChip color="neutral" label={label} />;
    }

    if (quoteTracking.state === 'FailedToSend') {
        return <StatusChip color="red" label={label} />;
    }

    assertUnreachable(quoteTracking.state);
};

export function formatQuoteTrackingState(state: QuoteTrackingState, user?: 'supplier' | 'ems'): string {
    switch (state) {
        case QuoteTrackingState.Received:
            return user === 'supplier' ? t`Submitted` : t`Received`;
        case QuoteTrackingState.Pending:
            return t({ id: 'quotetracking.status.pending', message: 'Pending' });
        case QuoteTrackingState.Overdue:
            return t`Overdue`;
        case QuoteTrackingState.Discarded:
            return t`Discarded`;
        case QuoteTrackingState.FailedToSend:
            return t`Failed to send`;
    }
}
