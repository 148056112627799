import { Chip, Grid, StyledEngineProvider, ThemeProvider, Typography, createTheme } from '@mui/material';
import React from 'react';
import { themeLuminovo } from '../../../themes';
import { alphaNumericalStringSort } from '../../../utils/stringFunctions';

interface DesignatorSelectChipsProps {
    designators: string[];
    setDesignators: React.Dispatch<string[]>;
    options: string[];
    chipColor: 'error' | 'primary';
}

export const DesignatorSelectChips: React.FunctionComponent<DesignatorSelectChipsProps> = ({
    designators,
    setDesignators,
    options,
    chipColor,
}: DesignatorSelectChipsProps): JSX.Element => {
    const handleClick = (clickedValue: string) => {
        if (designators.find((d) => d === clickedValue)) {
            const index = designators.findIndex((d) => d === clickedValue);
            const designatorsCopy = [...designators];
            designatorsCopy.splice(index, 1);
            setDesignators(designatorsCopy);
        } else {
            setDesignators([...designators, clickedValue]);
        }
    };

    const themeForDelete = { ...themeLuminovo.palette.error, contrastText: '#ffffff' };

    const themeForEdit = themeLuminovo.palette.primary;

    const themeForChip = createTheme({
        palette: {
            primary: chipColor === 'error' ? themeForDelete : themeForEdit,
        },
    });

    const sortedOptions = options.sort((o1, o2) => alphaNumericalStringSort(o1, o2));
    return (
        <Grid container spacing={1}>
            {sortedOptions && sortedOptions.length
                ? sortedOptions.map((option, i) => (
                      <Grid item key={i}>
                          <StyledEngineProvider injectFirst>
                              <ThemeProvider theme={themeForChip}>
                                  <Chip
                                      color={'primary'}
                                      variant={designators.find((d) => d === option) ? 'filled' : 'outlined'}
                                      label={<Typography variant="body2">{`${option}`}</Typography>}
                                      clickable
                                      onClick={() => handleClick(option)}
                                      size={'medium'}
                                  />
                              </ThemeProvider>
                          </StyledEngineProvider>
                      </Grid>
                  ))
                : null}
        </Grid>
    );
};
