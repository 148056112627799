import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { ComplianceStatus, OffTheShelfPartVote } from '@luminovo/http-client';
import { ComplianceStatusChip } from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import { usePartVotes } from '../../resources/part/usePartVotes';
import { otsPartOriginKeyWithLabelValue } from '../PartLifecycleView/OtsPartLifecycleStatusOriginsTooltip';

export const OtsPartComplianceStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    name: 'RoHS' | 'REACH' | 'AECQ';
    complianceStatus: ComplianceStatus;
    children: TooltipProps['children'];
}> = ({ partId, name, complianceStatus, children }): JSX.Element => {
    const { votes, isLoading, triggerFetchVotes } = usePartVotes({
        partId,
        shouldFetchVotes: complianceStatus !== ComplianceStatus.Unknown,
    });

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>{name} origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip =
        votes &&
        votes.some((vote) => {
            if (name === 'REACH') {
                return !!vote.reach_compliant && vote.reach_compliant !== ComplianceStatus.Unknown;
            }
            if (name === 'RoHS') {
                return !!vote.rohs_compliant && vote.rohs_compliant !== ComplianceStatus.Unknown;
            }
            if (name === 'AECQ') {
                return !!vote.aecq_compliant && vote.aecq_compliant !== ComplianceStatus.Unknown;
            }
            return false;
        });

    return (
        <Tooltip
            arrow
            onOpen={triggerFetchVotes}
            title={
                showTooltip ? (
                    <Flexbox
                        flexDirection="column"
                        gap="16px"
                        width="220px"
                        maxHeight="308px"
                        overflow="auto"
                        padding="4px"
                    >
                        <Text variant="h5">
                            <Trans>{name} origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((vote, i) => {
                                const complianceStatus = getComplianceStatus({ name, vote });

                                if (!complianceStatus || complianceStatus === ComplianceStatus.Unknown) {
                                    return null;
                                }
                                const label = otsPartOriginKeyWithLabelValue[vote.origin.type]
                                    ? otsPartOriginKeyWithLabelValue[vote.origin.type].label
                                    : vote.origin.type;
                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between" gap="4px">
                                        <ComplianceStatusChip status={complianceStatus} />
                                        <Text variant="body-small" showEllipsis>
                                            {label}
                                        </Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};

const getComplianceStatus = ({ name, vote }: { name: 'RoHS' | 'REACH' | 'AECQ'; vote: OffTheShelfPartVote }) => {
    if (name === 'REACH') {
        return vote.reach_compliant;
    }
    if (name === 'RoHS') {
        return vote.rohs_compliant;
    }
    if (name === 'AECQ') {
        return vote.aecq_compliant;
    }
    assertUnreachable(name);
};
