import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

export function ParentAssemblyIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return <TimelineAddIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
    assemblyName: string;
};

function useParentAssemblyAction({ operation, actor, assemblyName }: Props) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualRemove:
            return (
                <TimelineText>
                    <Trans>
                        Assembly <b>{assemblyName}</b> removed as a parent assembly
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualUpdate:
            return (
                <TimelineText>
                    <Trans>
                        Assembly <b>{assemblyName}</b> added as a parent assembly
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
}

export default function ParentAssembly(props: Props) {
    const action = useParentAssemblyAction(props);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}
