import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { ComplianceStatus, ComplianceStatusRuntype, EmissionDataRuntype } from '../backendTypes';
import { BomImportedAssemblyIssueRuntype } from '../bomImporter';
import {
    DesignItemExcelOriginDTORuntype,
    DesignItemResponseDTONewRuntype,
    DesignItemResponseDTORuntype,
} from '../designItem';
import { LifecycleEnum } from '../part';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export const PcbAssemblyTypeEnumRuntype = r.Union(r.Literal('Pcba'), r.Literal('PcbOnly'));

export const NonPcbAssemblyTypeEnumRuntype = r.Union(
    r.Literal('BOM'),
    r.Literal('Box'),
    r.Literal('Cable'),
    r.Literal('System'),
);

export const AssemblyTypeEnumRuntype = PcbAssemblyTypeEnumRuntype.Or(NonPcbAssemblyTypeEnumRuntype);

export type PcbAssemblyTypeEnum = r.Static<typeof PcbAssemblyTypeEnumRuntype>;
export type AssemblyTypeEnum = r.Static<typeof AssemblyTypeEnumRuntype>;
export type AssemblyTypePublic = Exclude<AssemblyTypeEnum, 'BOM'>;

export interface AssemblyType extends r.Static<typeof AssemblyTypeRuntype> {}

export const AssemblyTypeRuntype = r.Record({
    type: AssemblyTypeEnumRuntype,
    //Pcba Type can hold an ID from a Stackrate Pcba
    data: r.String.nullable().optional(),
});

export enum OverallRisk {
    LowRisk = 'LowRisk',
    MediumLowRisk = 'MediumLowRisk',
    MediumRisk = 'MediumRisk',
    MediumHighRisk = 'MediumHighRisk',
    HighRisk = 'HighRisk',
}

const OverallRiskRuntype = runtypeFromEnum(OverallRisk);

export enum AvailabilityStatusEnum {
    InStock = 'InStock',
    InsufficientStock = 'InsufficientStock',
    OutOfStock = 'OutOfStock',
    InventoryOfEMS = 'InventoryOfEMS',
    ConsignedByOEM = 'ConsignedByOEM',
}

export const AvailabilityStatusEnumRuntype = runtypeFromEnum(AvailabilityStatusEnum);

export enum AssemblyIndustry {
    Aero = 'Aero',
    Auto = 'Auto',
    Construction = 'Construction',
    Consumer = 'Consumer',
    Defense = 'Defense',
    Industrial = 'Industrial',
    Medical = 'Medical',
    Other = 'Other',
    Rail = 'Rail',
}

export const AssemblyIndustryRuntype = runtypeFromEnum(AssemblyIndustry);

const UuidItemsRuntype = r.Record({
    items: r.Array(r.String),
});

const StockRuntype = r.Record({
    required_stock: r.Number,
    inventory_stock: r.Number,
    external_stock: r.Number,
});

export type AvailabilityDTO = r.Static<typeof AvailabilityRuntype>;
const AvailabilityRuntype = r.Union(
    r.Record({
        type: r.Literal(AvailabilityStatusEnum.OutOfStock),
        data: StockRuntype.nullable(),
    }),
    r.Record({
        type: r.Literal(AvailabilityStatusEnum.InsufficientStock),
        data: StockRuntype,
    }),
    r.Record({
        type: r.Literal(AvailabilityStatusEnum.InStock),
        data: StockRuntype.nullable(),
    }),
    r.Record({
        type: r.Literal(AvailabilityStatusEnum.InventoryOfEMS),
        data: StockRuntype,
    }),
    r.Record({
        type: r.Literal(AvailabilityStatusEnum.ConsignedByOEM),
    }),
);

export enum PartCountEnum {
    Multiple = 'Multiple',
    None = 'None',
    DNP = 'DNP',
    Single = 'Single',
}

const PartCountRuntype = runtypeFromEnum(PartCountEnum);

export enum BomItemApprovalStatus {
    DNP = 'DNP',
    Pending = 'Pending',
    Rejected = 'Rejected',
    Warning = 'Warning',
    Approved = 'Approved',
}

export enum BomItemIssue {
    InsufficientStock = 'insufficientStock',
    Lifecycle = 'lifecycle',
    Compliance = 'compliance',
    // MissingPartData will always be included whenever
    // we have PinsMissing | MountingMissing | PackageNameMissing | PackageMismatch
    MissingPartData = 'missingPartData',
    PinsMissing = 'pinsMissing',
    MountingMissing = 'mountingMissing',
    PackageNameMissing = 'packageNameMissing',
    PackageNameOnlyMismatch = 'packageNameOnlyMismatch',
    PackageMismatch = 'packageMismatch',
    NoPartOptionsApproved = 'noPartOptionsApproved',
    DesignatorAndQuantityUnclear = 'designatorAndQuantityUnclear',
    DoNotPlace = 'doNotPlace',
}

export enum BomItemPartDataIssues {
    PinsMissing = 'PinsMissing',
    MountingMissing = 'MountingMissing',
    PackageNameMissing = 'PackageNameMissing',
    PackageMismatch = 'PackageMismatch',
    PackageNameOnlyMismatch = 'PackageNameOnlyMismatch',
}

export enum BomItemGeneralProperties {
    MissingDataWithSuggestions = 'MissingDataWithSuggestions',
    ApprovedBomItemsWithPartialMatches = 'ApprovedBomItemsWithPartialMatches',
    HasChangesFromPreviousImport = 'HasChangesFromPreviousImport',
    FewerPartOptionsAddedThanAlternativesInOriginalBOM = 'FewerPartOptionsAddedThanAlternativesInOriginalBOM',
}

export interface BomItemDTO extends r.Static<typeof BomItemsRuntype> {}

const BomItemsRuntype = r.Record({
    design_items: r.Array(r.String),
    reach_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    rohs_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    aecq_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    lifecycle_status: runtypeFromEnum(LifecycleEnum).optional(),
    availability: AvailabilityRuntype.nullable(),
    years_to_end_of_life: r.Number.nullable(),
    part_option_cardinality: PartCountRuntype,
    approval_status: runtypeFromEnum(BomItemApprovalStatus),
    issues: r.Array(runtypeFromEnum(BomItemIssue)),
    part_data_issues: r.Array(runtypeFromEnum(BomItemPartDataIssues)),
    general_properties: r.Array(runtypeFromEnum(BomItemGeneralProperties)),
    emission_data: EmissionDataRuntype.nullable(),
    country_of_origin: r.Array(RegionsEnumRuntype),
});

export const EmissionsDataSummaryRuntype = r.Record({
    kind: r.Union(r.Literal('Equal'), r.Literal('Gte')), // Gte = greater than or equal
    product_phase_gwp_in_kg_co2e_min: r.String,
    product_phase_gwp_in_kg_co2e_max: r.String,
});

export interface EmissionsDataSummary extends r.Static<typeof EmissionsDataSummaryRuntype> {}

export interface AssemblyDataDTO extends r.Static<typeof AssemblyDataDTORuntype> {}

const SubAssemblies = r.Record({
    items: r.Array(
        r.Record({
            assembly_id: r.String,
            quantity: r.Number,
        }),
    ),
});

export const BareIpnIdentifierRuntype = r.Record({
    id: r.String,
    value: r.String,
    revision: r.String.nullable(),
});

// Manual origin means the assemblies were created in the UI
// BomImport -> They were extracted in the BOM Importer
// ApiImport -> Imported via the API
const AssemblyOriginTypeRuntype = r.Union(r.Literal('Manual'), r.Literal('ApiImport'), r.Literal('BomImport'));

const AssemblyOriginFilterRuntype = r.Record({
    type: AssemblyOriginTypeRuntype,
});

export const BomImportedAssemblyIdentifierRuntype = r.Record({
    ipns: r.Array(r.String),
    cpns: r.Array(r.String),
    cpn_revisions: r.Array(r.String),
});

const AssemblyExcelOriginRuntype = r.Record({
    excel_origin_id: r.String,
    identifier: BomImportedAssemblyIdentifierRuntype,
});

const AssemblyOriginValueRuntype = r.Record({
    excel_origin: AssemblyExcelOriginRuntype.nullable(),
    issues: r.Array(BomImportedAssemblyIssueRuntype),
});

export const AssemblyOriginDTORuntype = r.Union(
    r.Record({
        type: r.Union(r.Literal('Manual'), r.Literal('ApiImport')),
    }),
    r.Record({
        type: r.Literal('BomImport'),
        value: AssemblyOriginValueRuntype,
    }),
);

export type AssemblyOriginDTO = r.Static<typeof AssemblyOriginDTORuntype>;

export const AssemblyDataDTORuntype = r.Record({
    id: r.String,
    designator: r.String,
    type: AssemblyTypeRuntype,
    notes: r.Null.Or(r.String),
    parents: r.Dictionary(r.Number, r.String),
    subassemblies: SubAssemblies,
    rfq: r.String.nullable(),
    customer: r.Null.Or(r.String),
    industry: AssemblyIndustryRuntype,
    created_at: r.String,
    ipn: BareIpnIdentifierRuntype.nullable(),
    origin: AssemblyOriginDTORuntype,
});

export interface AssemblyDTO extends r.Static<typeof AssemblyDTORuntype> {}

export const AssemblyDTORuntype = r.Record({
    id: r.String,
    designator: r.String,
    type: AssemblyTypeRuntype,
    design_items: UuidItemsRuntype,
    bom_items: r.Array(BomItemsRuntype),
    subassemblies: SubAssemblies,
    notes: r.Null.Or(r.String),
    ipn: BareIpnIdentifierRuntype.nullable(),
    rfq: r.String.nullable(),
    customer: r.Null.Or(r.String),
    industry: AssemblyIndustryRuntype,
    reach_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    rohs_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    aecq_compliant: runtypeFromEnum(ComplianceStatus).optional(),
    lifecycle_status: runtypeFromEnum(LifecycleEnum).optional(),
    availability: AvailabilityStatusEnumRuntype.nullable(),
    overall_risk: OverallRiskRuntype,
    years_to_end_of_life: r.Number.nullable(),
    approval_status: runtypeFromEnum(BomItemApprovalStatus),
    parents: r.Dictionary(r.Number, r.String),
    origin: AssemblyOriginDTORuntype,
    emission_data: EmissionsDataSummaryRuntype.nullable(),
    depths: r.Array(r.Number),
});

export type AssemblyResponseDTO = r.Static<typeof AssemblyResponseRuntype>;

export const AssemblyResponseRuntype = r.Union(AssemblyDTORuntype, AssemblyDataDTORuntype);

export interface AssemblyFormDTO extends r.Static<typeof AssemblyFormRequest> {}

export const SubAssemblyQuantity = r.Record({
    assembly_id: r.String,
    quantity: r.Number,
});

const ResolvableBomImportedIssues = r.Literal('AutomaticallyGenerated');
export const AssemblyFormRequest = r.Record({
    designator: r.String.optional(),
    type: AssemblyTypeRuntype,
    rfq: r.String,
    customer: r.String,
    notes: r.String.optional(),
    industry: AssemblyIndustryRuntype,
    parents: r.Array(r.String),
    ipn_value: r.String.optional(),
    ipn_revision: r.String.optional(),
    sub_assembly_quantities: r.Array(SubAssemblyQuantity),
    bom_imported_issues_to_resolve: r.Array(ResolvableBomImportedIssues).optional(),
});

export interface PaginatedAssemblyDTO {
    data: AssemblyDTO;
    total_pages: number;
}

export interface YearsToEndOfLifeWithCount extends r.Static<typeof YearsToEndOfLifeWithCountRuntype> {}

const YearsToEndOfLifeWithCountRuntype = r.Record({
    years_to_end_of_life: r.Number,
    count: r.Number,
});

export interface DescendantsSummaryDTO extends r.Static<typeof DescendantsSummaryDTORuntype> {}

export const DescendantsSummaryDTORuntype = r.Record({
    top_level_assembly_types: r.Array(AssemblyTypeRuntype),
    assembly_types: r.Array(AssemblyTypeRuntype),
    assembly_count: r.Number,
    design_item_count: r.Number,
    emission_data: EmissionsDataSummaryRuntype.nullable(),
    lifecycle: r.Array(
        r.Record({
            status_type: runtypeFromEnum(LifecycleEnum),
            count: r.Number,
        }),
    ),
    availability: r.Array(
        r.Record({
            count: r.Number,
            status_type: runtypeFromEnum(AvailabilityStatusEnum),
        }),
    ),
    reach_compliance: r.Array(
        r.Record({
            count: r.Number,
            status_type: ComplianceStatusRuntype,
        }),
    ),
    rohs_compliance: r.Array(
        r.Record({
            count: r.Number,
            status_type: ComplianceStatusRuntype,
        }),
    ),
    aecq_compliance: r.Array(
        r.Record({
            count: r.Number,
            status_type: ComplianceStatusRuntype,
        }),
    ),
    part_options: r.Array(
        r.Record({
            count: r.Number,
            status_type: PartCountRuntype,
        }),
    ),
    overall_risk: OverallRiskRuntype,
    years_to_end_of_life: r.Array(YearsToEndOfLifeWithCountRuntype),
});

export interface DesignItemExcelOriginsDictionary extends r.Static<typeof DesignItemExcelOriginsDictionaryRuntype> {}

export const DesignItemExcelOriginsDictionaryRuntype = r.Dictionary(DesignItemExcelOriginDTORuntype, r.String);

export interface DescendantsDTO extends r.Static<typeof DescendantsDTORuntype> {}

export const DescendantsDTORuntype = r.Record({
    parent: AssemblyDTORuntype,
    assemblies: r.Array(AssemblyDTORuntype),
    design_items: r.Array(DesignItemResponseDTORuntype),
    excel_lines: DesignItemExcelOriginsDictionaryRuntype.optional(),
    can_see_part_alternatives: r.Boolean,
});

export interface DescendantsDTONew extends r.Static<typeof DescendantsDTONewRuntype> {}

export const DescendantsDTONewRuntype = r.Record({
    parent: AssemblyDTORuntype,
    assemblies: r.Array(AssemblyDTORuntype),
    design_items: r.Array(DesignItemResponseDTONewRuntype),
    excel_lines: DesignItemExcelOriginsDictionaryRuntype,
    can_see_part_alternatives: r.Boolean,
});

export const AssemblyResourcesRuntype = r.Record({
    other: r.Array(r.String),
    cad: r.String.nullable(),
});

export const GuidanceTextRuntype = r.Record({
    cad: r.String,
    other: r.String,
});

export enum UploadedFileType {
    Pnp = 'Pnp',
    Cad = 'Cad',
    Other = 'Other',
}

export interface InterestsDTO extends r.Static<typeof InterestsDTORuntype> {}

const InterestsDTORuntype = r.Record({
    lifecycle: r.Boolean,
    compliance: r.Boolean,
});

export interface AssemblyMonitoring extends r.Static<typeof AssemblyMonitoringRuntype> {}

export const AssemblyMonitoringRuntype = r.Record({
    top_level_assembly: r.String,
    frequency: r.Union(r.Literal('Daily'), r.Literal('Weekly'), r.Literal('Monthly'), r.Literal('Inactive')),
    interests: InterestsDTORuntype,
    is_active: r.Boolean,
    users: r.Array(
        r.Record({
            first_name: r.String,
            last_name: r.String,
            email: r.String,
            id: r.String,
        }),
    ),
});

const MinimalCustomerDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    customer_number: r.String.nullable(),
});

export interface BareIpnIdentifier extends r.Static<typeof BareIpnIdentifierRuntype> {}

export type AssemblyOverviewDTO = r.Static<typeof AssemblyOverviewDTORuntype>;
export const AssemblyOverviewDTORuntype = r.Record({
    id: r.String,
    designator: r.String,
    ipn: BareIpnIdentifierRuntype.nullable(),
    type: AssemblyTypeRuntype,
    notes: r.Null.Or(r.String),
    rfq: r.String.nullable(),
    customer: r.Null.Or(MinimalCustomerDTORuntype),
    industry: AssemblyIndustryRuntype,
    created_at: r.String,
    origin: AssemblyOriginDTORuntype,
});

const CustomAssemblyFilters = r.Union(
    r.Literal('TopLevelAssemblies'),
    r.Literal('AssembliesWithDemand'),
    r.Literal('ImportedAssemblies'),
);

export type AssemblyOverviewFilterRequest = r.Static<typeof AssemblyOverviewFilterRequestRuntype>;
export const AssemblyOverviewFilterRequestRuntype = r.Union(
    r.Record({
        field: r.Literal('Name'),
        operator: r.Literal('includesString'),
        parameter: r.String,
    }),
    r.Record({
        field: r.Literal('Ipn'),
        operator: r.Literal('includesString'),
        parameter: r.String,
    }),
    r.Record({
        field: r.Literal('Customer'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('Origin'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(AssemblyOriginFilterRuntype),
    }),
    r.Record({
        field: r.Literal('Industry'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(AssemblyIndustryRuntype),
    }),
    r.Record({
        field: r.Literal('Custom'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(CustomAssemblyFilters),
    }),
);

export type AssemblyOverviewAggregationDTO = r.Static<typeof AssemblyOverviewAggregationDTORuntype>;
export const AssemblyOverviewAggregationDTORuntype = r.Union(
    r.Record({
        field: r.Literal('Industry'),
        options: r.Array(
            r.Record({
                value: AssemblyIndustryRuntype,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('Origin'),
        options: r.Array(
            r.Record({
                value: AssemblyOriginFilterRuntype,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('Customer'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
);

export interface AssemblyOverviewResponse extends r.Static<typeof AssemblyOverviewResponseRuntype> {}

export const AssemblyOverviewResponseRuntype = r.Record({
    total_count: r.Number,
    page_params: r.Unknown.Or(r.Undefined),
    aggregations: r.Array(AssemblyOverviewAggregationDTORuntype),
    page: r.Array(AssemblyOverviewDTORuntype),
});

const InternalPartNumber = r.Record({
    value: r.String,
});

const PartOption = r.Record({
    internal_part_number: InternalPartNumber,
});

const BomItem = r.Record({
    designators: r.Array(r.String),
    quantity: r.Number,
    unit: r.Literal('Pieces'),
    part_options: r.Array(PartOption),
});

const Customer = r.Record({
    number: r.String,
});

const CustomerPartNumber = r.Record({
    customer: Customer,
});

const ImportAssemblyTypeRuntype = r.Union(
    r.Literal('Box build'),
    r.Literal('PCBA'),
    r.Literal('Cable'),
    r.Literal('System'),
);

const Assembly = r.Record({
    bom_items: r.Array(BomItem),
    customer_part_numbers: r.Array(CustomerPartNumber),
    internal_part_number: InternalPartNumber,
    type: ImportAssemblyTypeRuntype,
});

export const AssemblyImportRuntype = r.Array(Assembly);
export type SingleAssemblyImportDTO = r.Static<typeof Assembly>;
export type AssemblyImportDTO = r.Static<typeof AssemblyImportRuntype>;
