import { assertUnreachable } from '@luminovo/commons';
import { DriverDTO, DriverIdDTO } from '@luminovo/http-client';

export const selectDriverIdDto = (driver: DriverDTO): DriverIdDTO => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return {
                type: 'User',
                value: driver.data.id,
            };
        case 'System':
            return {
                type: 'System',
                value: driver.data,
            };
        default:
            assertUnreachable(type);
    }
};

/**
 * WARNING: This should only be used in cases where string comparison is required over an object (i.e a key in a component).
 * Otherwise, please use selectDriverIdDto instead.
 *
 */
export const selectDriverIdString = (driver: DriverDTO): string => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return driver.data.id;
        case 'System':
            return driver.data;
        default:
            assertUnreachable(type);
    }
};

/**
 * WARNING: This should only be used in cases where string comparison is required over an object (i.e a key in a component)
 *
 */
export const convertDriverIdDtoToDriverIdString = (driver: DriverIdDTO): string => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return driver.value;
        case 'System':
            return driver.value;
        default:
            assertUnreachable(type);
    }
};
