/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { ExchangedMonetaryValueBackendConvertedMVOnlyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';

export enum ResourceType {
    Machine = 'Machine',
    Person = 'Person',
}
const ResourceTypeRuntype = runtypeFromEnum(ResourceType);

export interface ResourceDTO extends r.Static<typeof ResourceDTORuntype> {}
export const ResourceDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    internal_number: r.String.nullable(),
    type: ResourceTypeRuntype,
    cost_per_hour: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    description: r.String.nullable(),
    status: ManufacturingEntityStatusRuntype,
    site_id: r.String.nullable(),
});

export const ResourcePostDTORuntype = ResourceDTORuntype.omit('id', 'status', 'cost_per_hour').extend({
    cost_per_hour: MonetaryValueBackendRuntype,
});

export interface ResourcePostDTO extends r.Static<typeof ResourcePostDTORuntype> {}

export interface ResourcesDTO extends r.Static<typeof ResourcesDTORuntype> {}
export const ResourcesDTORuntype = r.Array(ResourceDTORuntype);
