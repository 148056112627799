import { Box } from '@mui/material';

export const CONTAINER_DIMESION = 172;

export const IconContainer = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return (
        <Box
            display={'grid'}
            style={{ placeItems: 'center' }}
            position="relative"
            minWidth={CONTAINER_DIMESION}
            height={CONTAINER_DIMESION}
        >
            {children}
        </Box>
    );
};
