import { Trans, t } from '@lingui/macro';
import { compareByString } from '@luminovo/commons';
import { BaseFieldControllerProps, FieldSelectControlled, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSites } from '../resources/organizationSettings/sitesHandler';
import { route } from '../utils/routes';

const LinkToOrganizationSettings = (): JSX.Element => {
    return (
        <Text variant="body-small">
            <Trans>
                No site has yet been added to your{' '}
                <Link
                    to={route('/settings/organization/sites')}
                    style={{
                        textDecoration: 'none',
                        color: colorSystem.blue[6],
                    }}
                >
                    organization settings
                </Link>
            </Trans>
        </Text>
    );
};

export const SitesSelect = <T extends FieldValues>({ control, name }: BaseFieldControllerProps<T>): JSX.Element => {
    const { data = [], isLoading } = useSites();

    const options = Array.from(data)
        .sort((s1, s2) => compareByString(s1.name, s2.name))
        .map((site) => site.id);

    const disabled = options.length === 0;

    return (
        <Flexbox gap="8px" flexDirection="column">
            <FieldSelectControlled
                control={control}
                name={name}
                FieldProps={{
                    // FIXME: Remove the generic type from the SiteSelect component
                    options: options as PathValue<T, Path<T>>[],
                    getOptionLabel: (siteId) => data.find((site) => site.id === siteId)?.name ?? '',
                    isOptionEqualToValue: (siteId1, siteId2) => siteId1 === siteId2,
                    placeholder: isLoading ? t`Loading` : t`None`,
                    disabled,
                }}
            />
            {Boolean(data) && options.length === 0 && <LinkToOrganizationSettings />}
        </Flexbox>
    );
};
