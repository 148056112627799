import { Trans } from '@lingui/macro';
import { PrimaryButton } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { ContributorOption } from '../../ContributorsFormUtils';

export const ContributorSubmitButton = ({ isLoading }: { isLoading?: boolean }) => {
    const {
        formState: { isDirty },
    } = useFormContext<{ contributors: ContributorOption[] }>();

    return (
        <PrimaryButton type="submit" size="medium" disabled={isLoading || !isDirty} style={{ paddingBlock: '18px' }}>
            <Trans>Add</Trans>
        </PrimaryButton>
    );
};
