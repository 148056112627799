import { Currency } from '@luminovo/commons';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

const parser = parse.currency();

export const parseCurrency: Parser<Currency, Opts> = async function (cells, _, field): Promise<ParseResult<Currency>> {
    const alternatives = Object.values(Currency).map((value) => ({ id: value, label: value }));

    return applyParser({
        cells,
        parser,
        field,
        alternatives,
        formatValue: (x) => x.toString(),
    });
};
