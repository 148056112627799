import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { sortBy } from '@luminovo/commons';
import { ModuleIdentifier, ModuleIdentifierRuntype } from '@luminovo/http-client';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NOT_FOUND_TEXT } from '../../const';
import { assertAgainstRuntype, throwErrorUnlessProduction } from '../../utils/customConsole';
import { multipleGetRequestsAllInfo } from '../HttpService';
import { NotificationFunction } from '../NotificationFunction';
import { createSingleAssemblyParentsEndpoint, useBomTreeIds } from '../assembly/assemblyHandler';

const convertModuleIdentifierToBreadcrumb = (identifiers: ModuleIdentifier[] | undefined): string => {
    if (identifiers === undefined) {
        return t`Error`;
    } else {
        return identifiers.map((i) => i.designator).join(' > ');
    }
};

function useAssemblyBreadcrumbs(assemblyIds: string[], enqueueSnackbar: NotificationFunction) {
    const {
        data: solutionConfigurationsData,
        isLoading,
        isError,
    } = useQuery<PromiseFulfilledResult<AxiosResponse>[]>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['allAssemblyData', assemblyIds.toString()],
        queryFn: () => {
            return multipleGetRequestsAllInfo(
                assemblyIds,
                getToken(),
                createSingleAssemblyParentsEndpoint,
                enqueueSnackbar,
            );
        },
        enabled: true,
    });

    const assemblyBreadcrumbsWithIds: TextBreadcrumbWithAssemblyId[] | undefined = solutionConfigurationsData?.map(
        (d) => {
            const id = d.value.config.url?.split('/')[5] || NOT_FOUND_TEXT;
            if (id === NOT_FOUND_TEXT) {
                throwErrorUnlessProduction(new Error('id not found'));
            }

            const parentsPath = (d.value.data.multi_parent_items ?? [[]])[0];
            const breadcrumbs: string = convertModuleIdentifierToBreadcrumb(parentsPath);
            const assembliesInBreadCrumb: string[] = parentsPath.map((d: ModuleIdentifier) => {
                assertAgainstRuntype(d, ModuleIdentifierRuntype);
                return d.id;
            });
            return {
                id: id,
                breadcrumb: breadcrumbs,
                assemblies: assembliesInBreadCrumb,
            };
        },
    );
    return { isLoading, isError, assemblyBreadcrumbsWithIds };
}

export interface TextBreadcrumbWithAssemblyId {
    id: string;
    breadcrumb: string;
    assemblies: string[];
}

export const useAllBreadcrumbs = (
    assemblyId: string,
): { breadcrumbs: TextBreadcrumbWithAssemblyId[] | undefined; isLoading: boolean; isError: boolean } => {
    const { enqueueSnackbar } = useSnackbar();

    const [assemblyIds, setAssemblyIds] = useState<string[]>([]);
    const { data, isLoading: idsLoading } = useBomTreeIds(assemblyId);
    useEffect(() => {
        if (data && !idsLoading) {
            const treeIds = data.items;
            setAssemblyIds(treeIds);
        }
    }, [data, idsLoading]);

    const { isLoading, isError, assemblyBreadcrumbsWithIds } = useAssemblyBreadcrumbs(assemblyIds, enqueueSnackbar);

    return {
        breadcrumbs: sortBreadcrumbs(assemblyBreadcrumbsWithIds),
        isLoading,
        isError,
    };
};

const sortBreadcrumbs = (
    assemblyBreadcrumbsWithIds: TextBreadcrumbWithAssemblyId[] | undefined,
): TextBreadcrumbWithAssemblyId[] => {
    if (assemblyBreadcrumbsWithIds === undefined) {
        return [];
    }
    return sortBy([...assemblyBreadcrumbsWithIds], (s) => s.breadcrumb);
};
