import { throwErrorUnlessProduction } from '@luminovo/commons';

/**
 * Represents `Probability(from <= X < to)`
 */
export interface Bin {
    probability: number;
    /**
     * Inclusive
     */
    from: number;
    /**
     * Exclusive
     */
    to: number;
}

/**
 * Takes a list of numbers as inputs and will group them into `numberOfBins` different bins and assign a probability
 * to each bin based on the relative number of elements in each bin.
 */
export function binValues({ values, numberOfBins = 7 }: { values: number[]; numberOfBins?: number }): Array<Bin> {
    const finiteValues = values.filter((value) => !Number.isNaN(value) && Number.isFinite(value));

    if (finiteValues.length !== values.length) {
        throwErrorUnlessProduction(new Error('binValues: Some values are not finite numbers'));
    }

    const sortedValues = Array.from(finiteValues).sort((a, b) => a - b);
    const min = sortedValues[0];
    const max = sortedValues[sortedValues.length - 1];

    const binRange = (max - min) / numberOfBins;

    const bins: Array<{ from: number; to: number; count: number }> = Array(numberOfBins)
        .fill(undefined)
        .map((_, i) => {
            const from = min + binRange * i;
            const to = i === numberOfBins - 1 ? Infinity : from + binRange;
            return { count: 0, from, to };
        });

    for (const num of sortedValues) {
        const bin = bins.find((bin) => {
            return num >= bin.from && num < bin.to;
        });

        if (bin === undefined) {
            throw new Error(`Unreachable: ${num} ${JSON.stringify({ min, max, binRange, result: bins }, null, 2)}`);
        }
        bin!.count++;
    }

    return bins.map((bin) => {
        return { ...bin, probability: bin.count / finiteValues.length };
    });
}
