import { t } from '@lingui/macro';
import { BaseFieldControllerProps, Chip, colorSystem, Flexbox, Text, TextField } from '@luminovo/design-system';
import { PersonAddOutlined as PersonAddIcon } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { Controller, FieldValues } from 'react-hook-form';
import { AutocompleteMultipleControllerProps } from '../../../formLayouts/reactHookFormComponents/reactHookFormAutocompleteComponents';
import { ContributorOption } from '../ContributorsFormUtils';

const filter = createFilterOptions<ContributorOption>();
const getContributorOptionLabel = (option: ContributorOption | string) =>
    typeof option === 'string' ? option : option.userName;
const renderNewUserOption = () => t`Invite new external user`;
const NEW_EXTERNAL_USER: ContributorOption = {
    id: 'new-external-user',
    userName: `New external user`,
};

export type ContributorAutoCompleteProps<T extends FieldValues> = Omit<
    AutocompleteMultipleControllerProps<T>,
    'options'
> &
    BaseFieldControllerProps<T> & {
        options: ContributorOption[];
        onAddNewUser?: () => void;
    };

export function ContributorAutoComplete<T extends FieldValues>({
    name,
    control,
    options,
    TextFieldProps,
    AutocompleteProps,
    onAddNewUser,
}: ContributorAutoCompleteProps<T>): JSX.Element {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    {...AutocompleteProps}
                    multiple
                    selectOnFocus
                    value={field.value}
                    options={options}
                    getOptionLabel={getContributorOptionLabel}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    popupIcon={''}
                    noOptionsText={t`No options.`}
                    onChange={(_, newValue) => {
                        const newestAddition = newValue.at(-1);

                        if (typeof newestAddition === 'string') {
                            return onAddNewUser?.();
                        }

                        if (
                            newestAddition &&
                            typeof newestAddition !== 'string' &&
                            newestAddition.id === NEW_EXTERNAL_USER.id
                        ) {
                            return onAddNewUser?.();
                        }
                        field.onChange(newValue);
                    }}
                    renderOption={(props, option) => {
                        if (option.id === NEW_EXTERNAL_USER.id)
                            return (
                                <li {...props}>
                                    <Flexbox gap={8} alignItems={'center'}>
                                        <PersonAddIcon
                                            style={{ transform: 'scaleX(-1)', color: colorSystem.neutral[6] }}
                                        />
                                        <Text variant="h5">{renderNewUserOption()}</Text>
                                    </Flexbox>
                                </li>
                            );
                        return <li {...props}>{option.userName}</li>;
                    }}
                    renderTags={(tagValue) =>
                        tagValue.map((option, index) => (
                            <Chip
                                style={{ margin: '4px' }}
                                color={'neutral'}
                                key={index}
                                label={getContributorOptionLabel(option)}
                                onDelete={() =>
                                    field.onChange(field.value.filter((_: unknown, i: number) => i !== index))
                                }
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} {...TextFieldProps} size="small" fullWidth={true} />
                    )}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '' && filtered.length < 1 && AutocompleteProps?.freeSolo) {
                            filtered.push(NEW_EXTERNAL_USER);
                        }
                        return filtered;
                    }}
                />
            )}
        />
    );
}
