import * as r from 'runtypes';

function CalculationResultOk<T>(dataRuntype: r.Runtype<T>) {
    return r.Record({
        result: r.Literal('Ok'),
        data: dataRuntype,
    });
}

const CalculationResultScriptError = r.Record({
    result: r.Literal('ScriptError'),
    data: r.Record({}), //TODO: support full error types in the FE https://www.notion.so/luminovo/Support-full-error-types-in-the-FE-2de9777f465f4ab68cab55bc01729cd8
});

const CalculationResultUnknownIdentifier = r.Record({
    result: r.Literal('UnknownIdentifier'),
    data: r.String,
});

export const CalculationResultNullValue = r.Record({
    result: r.Literal('NullValue'),
    data: r.String,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CalculationResult<T>(dataRuntype: r.Runtype<T>) {
    return r.Union(
        CalculationResultOk(dataRuntype),
        CalculationResultScriptError,
        CalculationResultUnknownIdentifier,
        CalculationResultNullValue,
    );
}
