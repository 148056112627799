import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { WeltronCredentialsInputDTORuntype, WeltronResponseBodyDTORuntype } from './weltronBackendTypes';

export const weltronEndpoints = {
    'GET /3rdparty/weltron/credentials': endpoint({
        description: 'Returns credentials for weltron integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: WeltronResponseBodyDTORuntype,
    }),
    'POST /3rdparty/weltron/credentials': endpoint({
        description: 'Uploads credentials for weltron API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: WeltronCredentialsInputDTORuntype,
        responseBody: WeltronResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
    'PATCH /3rdparty/weltron/credentials': endpoint({
        description: 'Updates credentials for weltron API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: WeltronCredentialsInputDTORuntype,
        responseBody: WeltronResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
    'DELETE /3rdparty/weltron/credentials': endpoint({
        description: 'Deletes existing credentials for weltron API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
};
