import { Trans } from '@lingui/macro';
import { Column, columnWidth, Filter, Row, StatusChip } from '@luminovo/design-system';
import { ActivityDTO, ManufacturingEntityStatusWithError } from '@luminovo/http-client';
import { extractStatusColor } from '@luminovo/manufacturing-core';
import { transEnum } from '../../../../components/localization/TransEnum';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';
import { statusTranslationsWithError } from '../../shared/sharedManufacturingBackendTypes';
import { ActivityFormulasContext } from './utils';

type ActivityStatusColumnContext = ActivityFormulasContext;

const evaluateActivityStatus = (
    activity: ActivityDTO,
    context: ActivityStatusColumnContext,
): ManufacturingEntityStatusWithError => {
    const formulas = context.activityFormulasMap[activity.id];

    if (!formulas) {
        return activity.status;
    }

    const hasAnyFormulaError =
        formulas.reduce((isError, formula) => isError || context.formulaDriversMap[formula]?.isError, false) ??
        undefined;

    if (hasAnyFormulaError) {
        return 'Error';
    }

    return activity.status;
};

function getStatusFilters<C extends ActivityStatusColumnContext>(): Filter<ActivityDTO, C>[] {
    return [
        {
            id: 'status=Active',
            label: <Trans>Active</Trans>,
            predicate: (activity, context) => evaluateActivityStatus(activity, context) === 'Active',
        },
        {
            id: 'status=Inactive',
            label: <Trans>Inactive</Trans>,
            predicate: (activity, context) => evaluateActivityStatus(activity, context) === 'Inactive',
        },
        {
            id: 'status=Error',
            label: <Trans>Error</Trans>,
            predicate: (activity, context) => evaluateActivityStatus(activity, context) === 'Error',
        },
    ];
}

export function getActivityStatusColumn<C extends ActivityStatusColumnContext>(): Column<ActivityDTO, C> {
    return {
        label: <Trans>Status</Trans>,
        id: `status`,
        render: ({ data: rowData }: Row<ActivityDTO>, context): JSX.Element => {
            const status = evaluateActivityStatus(rowData, context);

            return (
                <TextCell
                    firstRow={
                        <StatusChip
                            color={extractStatusColor({ status })}
                            label={transEnum(status, statusTranslationsWithError)}
                        />
                    }
                />
            );
        },
        width: columnWidth.extraSmall,
        filters: getStatusFilters(),
    };
}
