import { Trans } from '@lingui/macro';
import { colorSystem, StatusChip } from '@luminovo/design-system';
import { AvailabilityStatusEnum, PartSpecificationTypes } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { isAssemblyTableData, isBomItemTableData, ModuleTableData } from '../../../ModuleTableData';
import { EmptyValue } from './EmptyValue';

interface AvailabilityLabel {
    color: keyof typeof colorSystem;
    label: JSX.Element;
}

type AvailabilityResult = 'isLoading' | null | { state: 'resolved'; data: AvailabilityLabel };

export const RenderAvailability = ({ moduleTableData }: { moduleTableData: ModuleTableData }): JSX.Element => {
    const availability = useAvailability(moduleTableData);
    if (!availability) {
        return <EmptyValue />;
    }
    if (availability === 'isLoading') {
        return <Skeleton width={'50px'} />;
    }

    const { label, color } = availability.data;
    return <StatusChip label={label} color={color} />;
};

function getAvailabilityFromModuleTableData(data: ModuleTableData): AvailabilityLabel | null {
    if (isAssemblyTableData(data) && data.availability) {
        return availabilityKeyWithLabelValue[data.availability];
    }

    if (isBomItemTableData(data) && data.availability) {
        return availabilityKeyWithLabelValue[data.availability.type];
    }

    return null;
}

function useAvailability(data: ModuleTableData): AvailabilityResult {
    const availability = getAvailabilityFromModuleTableData(data);
    if (availability) {
        return { state: 'resolved', data: availability };
    }
    // an assembly without availability data doesn't have bom-items or subassemblies
    // or, if it does, then the bom-items in the assembly do not have the
    // availability data as well
    if (isAssemblyTableData(data)) return null;

    const hasApprovedPartOptions = data.approvedPartOptions.length > 0;

    const isCustomPartSpecification =
        isBomItemTableData(data) && data.specification?.type === PartSpecificationTypes.Custom;

    if (data.doNotPlace || !hasApprovedPartOptions || isCustomPartSpecification) {
        return null;
    }

    return 'isLoading';
}

const availabilityKeyWithLabelValue: Record<AvailabilityStatusEnum, AvailabilityLabel> = {
    [AvailabilityStatusEnum.ConsignedByOEM]: { color: 'green', label: <Trans>Consigned</Trans> },
    [AvailabilityStatusEnum.InStock]: {
        color: 'green',
        label: <Trans>In stock</Trans>,
    },
    [AvailabilityStatusEnum.InsufficientStock]: { color: 'yellow', label: <Trans>Insufficient stock</Trans> },
    [AvailabilityStatusEnum.InventoryOfEMS]: {
        color: 'green',
        label: <Trans>Inventory</Trans>,
    },
    [AvailabilityStatusEnum.OutOfStock]: { color: 'red', label: <Trans>Out of stock</Trans> },
};
