import { ActivityConfigurationSummaryDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { selectDriverIdDto } from '@luminovo/manufacturing-core';
import { CalculationCellLayout } from '../shared/CalculationCellLayout';
import { getFirstErrorDriverStatusIfAnyConsideringDriverIds } from '../shared/driverStatusInformation';
import { TextCell, doesUnitActivityConfigurationHaveBatchTimeCalculation } from './activitiesTableComponents';

export const TimeCalculationCell = ({
    activity,
    manufacturingAssemblyDetails,
}: {
    activity: ActivityConfigurationSummaryDTO;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
}): JSX.Element => {
    const activityCostComponent = activity.activity_configuration_details.cost_components;
    const secondRow =
        activityCostComponent.level === 'Unit' && doesUnitActivityConfigurationHaveBatchTimeCalculation(activity) ? (
            <CalculationCellLayout
                calculationType={activityCostComponent.details.batch_time_components?.type}
                driverStatus={getFirstErrorDriverStatusIfAnyConsideringDriverIds({
                    driverIdsToConsider: activity.batch_drivers.map((driver) => selectDriverIdDto(driver)),
                    driverStatuses: activityCostComponent.details.driver_statuses,
                })}
                manufacturingAssemblyDetails={manufacturingAssemblyDetails}
            />
        ) : undefined;

    return (
        <TextCell
            firstRow={
                <>
                    {activityCostComponent.level === 'Unit' && (
                        <CalculationCellLayout
                            calculationType={activityCostComponent.details.unit_time_components?.type}
                            driverStatus={getFirstErrorDriverStatusIfAnyConsideringDriverIds({
                                driverIdsToConsider: activity.unit_drivers.map((driver) => selectDriverIdDto(driver)),
                                driverStatuses: activityCostComponent.details.driver_statuses,
                            })}
                            manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                        />
                    )}
                    {activityCostComponent.level === 'Batch' && (
                        <CalculationCellLayout
                            calculationType={activityCostComponent.details.time_components?.type}
                            driverStatus={getFirstErrorDriverStatusIfAnyConsideringDriverIds({
                                driverIdsToConsider: activity.batch_drivers.map((driver) => selectDriverIdDto(driver)),
                                driverStatuses: activityCostComponent.details.driver_statuses,
                            })}
                            manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                        />
                    )}
                    {activityCostComponent.level === 'Project' && (
                        <CalculationCellLayout
                            calculationType={activityCostComponent.details.time_components?.type}
                            driverStatus={getFirstErrorDriverStatusIfAnyConsideringDriverIds({
                                driverIdsToConsider: activity.project_drivers.map((driver) =>
                                    selectDriverIdDto(driver),
                                ),
                                driverStatuses: activityCostComponent.details.driver_statuses,
                            })}
                            manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                        />
                    )}
                </>
            }
            secondRow={secondRow}
        />
    );
};
