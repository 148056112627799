import { t } from '@lingui/macro';
import { colorSystem, Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { AddPartHandler, OtsPartAddForm } from './Forms/OtsPartAddForm';

export function useOtsPartAddDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ initialMpn, onSuccess }: { initialMpn: string; onSuccess: AddPartHandler }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle
                        title={t`Add off-the-shelf part`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                    />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <OtsPartAddForm initialMpn={initialMpn} onSuccess={onSuccess} onClose={closeDialog} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
