import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';

export const sustainabilityEndpoints = {
    'GET /organization/emission-request-quota': endpoint({
        description: 'Returns the total and already used quota to fetch emissions data for MPNs ',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            total_quota: r.Number,
            already_used_quota: r.Number,
        }),
    }),
};
