import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { runParsers } from '../../../model/runParsers';
import { ImporterConfig } from '../../../types';
import { useUniversalImporter } from '../context';
import { DateFormat } from '../util/getDefaultDateFormat';

export const useRunParsersQueryKey = ['step-check'];

export function useRunParsers(): UseQueryResult<null> {
    const { dispatch, state } = useUniversalImporter();
    const { config, table, dateFormats, decimalSeparator } = state;

    const withDecimalSeparator = applyDecimalSeparatorAndDateFormatter(config, decimalSeparator, dateFormats);

    return useQuery({
        queryKey: [...useRunParsersQueryKey, config, dateFormats],
        queryFn: async () => {
            for await (const intermediateState of runParsers(table ?? [], withDecimalSeparator)) {
                dispatch({ type: 'setImporterTable', table: intermediateState });
            }
            return null;
        },
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}

function applyDecimalSeparatorAndDateFormatter(
    config: ImporterConfig,
    decimalSeparator: '.' | ',',
    formats: DateFormat[],
) {
    return {
        ...config,
        fields: config.fields.map((field) => {
            if (field.parser.type === 'date') {
                return {
                    ...field,
                    parser: {
                        ...field.parser,
                        options: {
                            ...field.parser.options,
                            formats,
                        },
                    },
                };
            }
            if (field.parser.type === 'number') {
                return {
                    ...field,
                    parser: {
                        ...field.parser,
                        options: {
                            ...field.parser.options,
                            decimalSeparator,
                        },
                    },
                };
            }
            return field;
        }),
    };
}
