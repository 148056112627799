/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { getLocale, LanguageEnum, logToExternalErrorHandlers, throwErrorUnlessProduction } from '@luminovo/commons';
import { CenteredLayout, colorSystem, FieldNumericControlled, Flexbox, FormItem, Text } from '@luminovo/design-system';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import React, { useMemo } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FormContainer, ValidationErrors } from '../../../../components/formLayouts/FormContainer';
import {
    SelectController,
    TextFieldController,
} from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { AuthPage } from '../../../../modules/Auth/AuthPage';
import { getCustomerPortalTenant, getTenantId, Tenant } from '../../../../permissions/tenants';
import { useQueryParams } from '../../../../resources/hooks';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { languageTranslations, loadLocale } from '../../../../utils/internationalisation';
import { positionTranslations } from './i18n';
import { SignupFormState, SignUpPayload, SignUpPayloadRuntype } from './types';
import { useMutationSignupUser } from './useMutationSignupUser';

const useCustomerPortalTenant = (): Tenant | undefined => {
    return useMemo(() => {
        const tenant = getCustomerPortalTenant();
        if (!tenant) {
            logToExternalErrorHandlers(
                new Error(`Failed to get the tenant name for the customer portal. Host: ${window.location.host}`),
            );
        }
        return tenant;
    }, []);
};

export default function SignUpPage() {
    const { payload } = useQueryParams<'/auth/signup/invite'>();

    const signupPayload = parsePayload(payload);
    const isCustomerPortal = Boolean(signupPayload?.customer_id);
    const tenant = useCustomerPortalTenant();
    const tenantName = tenant?.name ?? 'Luminovo';

    const { data } = useHttpQuery(
        'GET /tenant/details',
        { queryParams: { tenant: getTenantId() }, requestHeaders: { Authorization: null } },
        {},
    );

    const defaultLanguage: LanguageEnum = getLocale();

    const defaultValues: SignupFormState = {
        clear_token: '',
        email: '',
        language: defaultLanguage,
        password_confirmation: '',
        password: '',
        first_name: '',
        country_code: 49,
        last_name: '',
        phone_number: undefined,
        customer_id: signupPayload?.customer_id ?? '',
        position: isCustomerPortal ? 'other' : '',
        ...(signupPayload ?? {}),
    };

    React.useEffect(() => {
        loadLocale(defaultValues.language);
    }, [defaultValues.language]);

    const { mutateAsync } = useMutationSignupUser(signupPayload);

    if (data) {
        const organizationLogoLink = data.organization_logo !== null ? data.organization_logo : undefined;
        const colors = data.colors;
        const backgroundProps = {
            primary: colors.primary_color,
            primaryDarker: colors.primary_dark_color,
            background: colors.background_color,
        };

        return (
            <AuthPage
                isCustomerPortal={isCustomerPortal}
                tenantName={tenantName}
                organizationLogoLink={organizationLogoLink}
                SignupBackgroundProps={backgroundProps}
            >
                <Text variant="h1" color={colorSystem.neutral[8]}>
                    {isCustomerPortal ? (
                        <Trans>Welcome to our Customer Portal</Trans>
                    ) : (
                        <Trans>Welcome to Luminovo</Trans>
                    )}
                </Text>
                <Text variant="h4" color={colorSystem.neutral[6]}>
                    <Trans>Please fill out this information to get started</Trans>
                </Text>

                <FormSignup
                    isCustomerPortal={isCustomerPortal}
                    defaultValues={defaultValues}
                    onSubmit={(form) => mutateAsync(form)}
                />
            </AuthPage>
        );
    }

    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    );
}

function parsePayload(payload: string | null | undefined): SignUpPayload | undefined {
    try {
        const parsed = JSON.parse(decodeURIComponent(escape(atob(payload ?? ''))));

        if (SignUpPayloadRuntype.guard(parsed)) {
            return parsed;
        }
        return undefined;
    } catch (e) {
        throwErrorUnlessProduction(new Error(`Failed to parse payload: ${e}`));
        return undefined;
    }
}

function FormSignup({
    isCustomerPortal,
    defaultValues,
    onSubmit,
}: {
    isCustomerPortal: boolean;
    defaultValues: SignupFormState;
    onSubmit: SubmitHandler<SignupFormState>;
}) {
    const validationErrors: ValidationErrors<SignupFormState> = {
        'invite.invalid_user_invite': {
            fieldPath: 'root.serverError',
        },
        'payload.email_cannot_be_empty': {
            fieldPath: 'email',
        },
        'payload.first_name_cannot_be_empty': {
            fieldPath: 'first_name',
        },
        'payload.last_name_cannot_be_empty': {
            fieldPath: 'last_name',
        },
        'payload.weak_password': {
            fieldPath: 'password',
        },
        'payload.password_mismatch': {
            fieldPath: 'password_confirmation',
        },
        'payload.password_cannot_contain_personal_data': {
            fieldPath: 'password',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection={'column'} gap={16}>
                <FormItemFirstName />
                <FormItemLastName />
                <FormItemPhoneNumber />
                <FormItemPosition isCustomerPortal={isCustomerPortal} />

                <FormItemPassword />
                <FormItemConfirmPassword />
                <FormItemLanguage />

                <SubmitButton label={t`Sign up`} />

                <Text>
                    <Trans>Already have an account?</Trans>{' '}
                    <Link to={'/'}>
                        <Trans>Sign-in instead</Trans>
                    </Link>
                    .
                </Text>
            </Flexbox>
        </FormContainer>
    );
}

function FormItemFirstName() {
    const { control } = useFormContext<SignupFormState>();
    return (
        <FormItem label={t`First name`} required={true}>
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="first_name"
            />
        </FormItem>
    );
}

function FormItemLastName() {
    const { control } = useFormContext<SignupFormState>();
    return (
        <FormItem label={t`Last name`} required={true}>
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="last_name"
            />
        </FormItem>
    );
}

function FormItemPhoneNumber() {
    const { control } = useFormContext<SignupFormState>();
    return (
        <FormItem label={t`Phone number`} required>
            <Box display={'grid'} gridTemplateColumns="80px 1fr" style={{ gap: 8 }}>
                <FieldNumericControlled
                    FieldProps={{
                        InputProps: { startAdornment: <InputAdornment position="start">+</InputAdornment> },
                        placeholder: t`Country code`,
                    }}
                    required={true}
                    isInteger={true}
                    control={control}
                    name="country_code"
                    min={1}
                    max={999}
                />
                <FieldNumericControlled required={true} isInteger={true} control={control} name="phone_number" />
            </Box>
        </FormItem>
    );
}

function FormItemPosition({ isCustomerPortal }: { isCustomerPortal: boolean }): JSX.Element {
    const { control } = useFormContext<SignupFormState>();

    return (
        <FormItem label={t`Position`}>
            {isCustomerPortal ? (
                <SelectController translations={positionTranslations} control={control} name="position" />
            ) : (
                <TextFieldController control={control} name="position" />
            )}
        </FormItem>
    );
}

function validatePassword(value: unknown) {
    if (typeof value !== 'string') {
        return t`Invalid type`;
    }
    const weakPassword = t`Password not strong enough. It must be least 8 characters long and contain lower case, upper case, numeric, and special characters.`;
    if (value.length < 8) {
        return weakPassword;
    }
    if (!value.match(/[0-9]/)) {
        return weakPassword;
    }
    if (!value.match(/[a-z]/)) {
        return weakPassword;
    }
    if (!value.match(/[A-Z]/)) {
        return weakPassword;
    }
    if (!value.match(/[^A-Za-z0-9]/)) {
        return weakPassword;
    }
}

function FormItemPassword() {
    const { control } = useFormContext<SignupFormState>();
    return (
        <FormItem label={t`Password`} required>
            <TextFieldController
                rules={{
                    validate: validatePassword,
                }}
                TextFieldProps={{ type: 'password' }}
                control={control}
                name="password"
            />
        </FormItem>
    );
}

function FormItemConfirmPassword() {
    const { control } = useFormContext<SignupFormState>();
    const password = useWatch({ control }).password ?? '';
    return (
        <FormItem label={t`Confirm password`} required>
            <TextFieldController
                rules={{
                    validate: (value) => {
                        if (value !== password) {
                            return t`Passwords don't match`;
                        }
                    },
                }}
                TextFieldProps={{ type: 'password' }}
                control={control}
                name="password_confirmation"
            />
        </FormItem>
    );
}

function FormItemLanguage() {
    const { control } = useFormContext<SignupFormState>();
    return (
        <FormItem label={t`Language`}>
            <SelectController translations={languageTranslations} control={control} name="language" />
        </FormItem>
    );
}
