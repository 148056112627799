import { Trans } from '@lingui/macro';
import { typeSafeObjectKeys } from '@luminovo/commons';
import { colorSystem, DefaultCloseButton, Flexbox, Text } from '@luminovo/design-system';
import { OverallRisk } from '@luminovo/http-client';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Collapse, Dialog, DialogContent, Paper, styled } from '@mui/material';
import React from 'react';
import { useDescendantsSummary } from '../../../../resources/assembly/assemblyHandler';
import { ViewContext } from '../ModuleTableData';
import { RiskAvatar } from './RiskAvatar';

export const BomOverallRisk = ({ assemblyId, viewContext }: { assemblyId: string; viewContext: ViewContext }) => {
    const { data } = useDescendantsSummary(assemblyId, viewContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleIsOpen = () => {
        setIsOpen((isOpen) => !isOpen);
    };
    if (!data) return null;

    return (
        <OverallRiskContainer overallRisk={data.overall_risk}>
            <div>
                <StyledOverallRiskHeader
                    onClick={toggleIsOpen}
                    justifyContent="space-between"
                    justifyItems={'center'}
                    alignItems={'center'}
                >
                    <Flexbox gap={8} alignItems={'center'}>
                        <RiskAvatar overallRisk={data.overall_risk} />
                        {overallRiskToTitleAndExplanation[data.overall_risk].title}
                    </Flexbox>
                    {isOpen ? (
                        <ExpandMore
                            style={{
                                color: colorSystem.neutral[7],
                            }}
                        />
                    ) : (
                        <ChevronRight
                            style={{
                                color: colorSystem.neutral[7],
                            }}
                        />
                    )}
                </StyledOverallRiskHeader>
                <Collapse collapsedSize={0} in={isOpen}>
                    <ExpandedExplanation overallRisk={data.overall_risk} />
                </Collapse>
            </div>
        </OverallRiskContainer>
    );
};

const OverallRiskContainer = ({ overallRisk, children }: React.PropsWithChildren<{ overallRisk: OverallRisk }>) => {
    const riskToBackgroundColor: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['1'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['1'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['1'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['1'],
        [OverallRisk.HighRisk]: colorSystem.red['2'],
    };

    const riskToBorderColor: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['2'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['2'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['2'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['2'],
        [OverallRisk.HighRisk]: colorSystem.red['3'],
    };

    return (
        <StyledOverallRiskContainer
            style={{
                backgroundColor: riskToBackgroundColor[overallRisk],
                borderColor: riskToBorderColor[overallRisk],
            }}
        >
            {children}
        </StyledOverallRiskContainer>
    );
};

const RiskModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    return (
        <Dialog open={isOpen} onClose={close} keepMounted PaperComponent={StyledDialogPaper}>
            <StyledDialogContainer>
                <Flexbox alignItems={'center'} justifyContent={'space-between'}>
                    <Text variant={'h3'}>
                        <Trans>Overall risk</Trans>
                    </Text>
                    <DefaultCloseButton onClick={close} />
                </Flexbox>
                <StyledDialogContent>
                    {typeSafeObjectKeys(OverallRisk).map((score) => {
                        const overallRisk = OverallRisk[score];
                        return <RiskTitleAndExplanation key={score} overallRisk={overallRisk} />;
                    })}
                </StyledDialogContent>
            </StyledDialogContainer>
        </Dialog>
    );
};

const StyledDialogPaper = styled(Paper)({
    borderRadius: '8px',
});

const RiskTitleAndExplanation = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    return (
        <StyledRiskTitleAndExplanationContainer className={'title_and_description'}>
            <StyledRiskTitle gap={8} alignItems={'center'}>
                <RiskAvatar overallRisk={overallRisk} />
                {overallRiskToTitleAndExplanation[overallRisk].title}
            </StyledRiskTitle>
            {overallRiskToTitleAndExplanation[overallRisk].description}
        </StyledRiskTitleAndExplanationContainer>
    );
};

const StyledRiskTitleAndExplanationContainer = styled('div')({
    padding: '16px',
});
const StyledRiskTitle = styled(Flexbox)({
    marginBottom: '8px',
});
const StyledDialogContainer = styled('div')({
    padding: '24px',
    backgroundColor: colorSystem.neutral['1'],
    maxWidth: '480px',
});

const StyledDialogContent = styled(DialogContent)({
    padding: 0,
    marginTop: '25px',
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
    '& .title_and_description:not(:last-child)': {
        borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    },
});

const ExpandedExplanation = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const handleClose = () => {
        setIsOpen(() => false);
    };

    const handleOpen = () => {
        setIsOpen(() => true);
    };
    return (
        <Explanation>
            {overallRiskToTitleAndExplanation[overallRisk].description}
            <StyledLearnMoreContainer>
                <Text variant={'h5'} color={colorSystem.neutral[7]} onClick={handleOpen}>
                    <Trans>Learn more</Trans>
                </Text>
            </StyledLearnMoreContainer>
            <RiskModal isOpen={isOpen} close={handleClose} />
        </Explanation>
    );
};

const Explanation = styled('div')({
    backgroundColor: 'white',
    padding: '8px 12px',
});

const StyledLearnMoreContainer = styled('div')({
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
});

const Container = styled('div')({
    display: 'flex',
    height: 'auto',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: 12,
    borderRadius: 8,
    gap: 12,
    overflow: 'clip',
});

const StyledOverallRiskHeader = styled(Flexbox)({
    padding: '12px 10px',
    cursor: 'pointer',
});

const StyledOverallRiskContainer = styled(Container)({
    padding: 0,
});

const ExplanationDescription = ({ children }: { children: JSX.Element }) => {
    return (
        <Text variant={'body-small'} color={colorSystem.neutral[8]}>
            {children}
        </Text>
    );
};

const overallRiskToTitleAndExplanation: Record<OverallRisk, { title: JSX.Element; description: JSX.Element }> = {
    [OverallRisk.LowRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Low risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly are REACH and RoHS compliant with an active lifecycle and have
                    approved part options that are available.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumLowRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium low risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly are REACH and RoHS compliant with an active lifecycle but may have
                    insufficient stock.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have to be reviewed for compliance & lifecycle but have approved
                    part options that are available.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumHighRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium high risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have to be reviewed for compliance & lifecycle and may have
                    insufficient stock or no approved part options.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.HighRisk]: {
        title: (
            <Text variant="h4">
                <Trans>High risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have lifecycles that are inactive or obsolete, may not be REACH
                    or RoHS compliant, and may have insufficient stock or no approved part options.
                </Trans>
            </ExplanationDescription>
        ),
    },
};
