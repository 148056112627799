import { ViewBoxCoordinateType } from '@luminovo/http-client';

export const addPaddingToViewBox = (viewBoxCoordinate: ViewBoxCoordinateType, padding: number = 0.25) => {
    const { width, height, y, x } = viewBoxCoordinate;

    const newWidth = width * (1 + padding * 2);
    const newHeight = height * (1 + padding * 2);
    const newX = x - width * padding;
    const newY = y - height * padding;

    return {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
    };
};
