import { isPresent } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { isAttributeRequired } from '../isAttributeRequired';
import { Attribute, Attributes } from '../types';

export function getAttributeColor({
    attribute,
    attributeType,
}: {
    attribute?: Attribute;
    attributeType: Attributes;
}): string {
    const isRequired = isAttributeRequired({ attr: attributeType });
    const confidence = attribute?.confidence ?? 1;
    if (!attribute && !isRequired) {
        return colorSystem.yellow[1];
    }
    if ((!attribute || !isPresent(attribute.value)) && isRequired) {
        return colorSystem.red[2];
    }

    if (attribute?.origin === 'manual') {
        return colorSystem.green[1];
    }

    if (confidence < 0.3) {
        return colorSystem.yellow[1];
    }

    if (confidence < 0.1) {
        return colorSystem.red[1];
    }

    return colorSystem.neutral.white;
}
