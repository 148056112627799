import { t } from '@lingui/macro';
import { FieldSelectControlled, Flexbox, Text } from '@luminovo/design-system';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { usePartCategories } from '../../../../../../../resources/part/partHandler';
import { OtsFormValues } from './formValues';

/**
 * Hardcoded part category for off-the-shelf parts
 * We need this to be able to set the default value of the part category select
 */
export const PART_CATEGORY_OFF_THE_SHELF = {
    id: 1,
    name: `Off-the-shelf part`,
    children: [2, 101, 334, 345],
    depth: 0,
};

export const PartCategorySelectControlled = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();
    const { data: partCategories = [], isLoading } = usePartCategories();

    const partCategoryMap = React.useMemo(() => {
        return new Map(
            partCategories.map((item) => {
                if (item.path.length <= 3) {
                    return [item.id, item.path.map((category) => category.name).join(' / ')];
                } else {
                    return [
                        item.id,
                        `... / ${item.path
                            .slice(-3)
                            .map((category) => category.name)
                            .join(' / ')}`,
                    ];
                }
            }),
        );
    }, [partCategories]);

    return (
        <FieldSelectControlled
            control={control}
            name="partCategory"
            required
            FieldProps={{
                disabled: isLoading,
                options: partCategories,
                getOptionLabel: (option) => option.name,
                renderOption: (option) => {
                    return (
                        <Flexbox flexDirection={'column'} gap={4} maxWidth={'100%'}>
                            <Text variant={'body-small'}>{option.name}</Text>
                            <Text variant="caption" showEllipsis>
                                {partCategoryMap.get(option.id) ?? ''}
                            </Text>
                        </Flexbox>
                    );
                },
                placeholder: t`Select part category`,
                disableClearable: true,
            }}
        />
    );
};
