import { Trans } from '@lingui/macro';
import { formatRelativeTime, isPresent } from '@luminovo/commons';
import { Flexbox, Tag, Text, colorSystem } from '@luminovo/design-system';
import { AllOrigins, CustomOptionOfferDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import { formatOfferOrigin } from '@luminovo/sourcing-core';
import React from 'react';

export const OfferOriginTag: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
    origin: AllOrigins | null;
}> = ({ offer, origin }): JSX.Element => {
    return (
        <Flexbox gap={4} alignItems={'center'}>
            {isPresent(origin) && <Tag color={'neutral'} label={formatOfferOrigin(origin)} />}
            <Text variant="body-small" color={colorSystem.neutral[6]}>
                <Trans>Updated {formatRelativeTime(offer.creation_date)}</Trans>
            </Text>
        </Flexbox>
    );
};
