import { CircularProgress, Drawer, DrawerProps } from '@mui/material';
import React from 'react';
import { CenteredLayout } from '../layout/CenteredLayout';

type RightBoxDrawerProps = Omit<DrawerProps, 'onClose' | 'open'> & { onClose: () => void };

const Fallback: React.FunctionComponent = () => {
    return (
        <CenteredLayout width={'400px'} height={'100%'}>
            <CircularProgress size={40} color="primary" />
        </CenteredLayout>
    );
};

export const RightBoxDrawer = React.forwardRef<unknown, RightBoxDrawerProps>(({ onClose, children, ...props }, _) => {
    return (
        <Drawer
            anchor={'right'}
            open={true}
            onClose={onClose}
            ModalProps={{ BackdropProps: { invisible: true } }}
            PaperProps={{ style: { boxShadow: '-4px 0px 20px 0px rgba(42, 49, 71, 0.1)' } }}
            // The stop propagation below is required => otherwise the onClick handler of component containing the drawer is triggered.
            onClick={(event): void => event.stopPropagation()}
            {...props}
        >
            <React.Suspense fallback={<Fallback />}>{children}</React.Suspense>
        </Drawer>
    );
});
