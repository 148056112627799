import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    CostedBomExportTemplateInputRuntype,
    CostedBomExportTemplateRuntype,
} from './costedBomExportTemplateBackendTypes';

export const costedBomExportTemplateEndpoints = {
    'GET /export/costed-bom/templates': endpoint({
        description: 'List all costed bom export templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(CostedBomExportTemplateRuntype),
        }),
    }),
    'DELETE /export/costed-bom/templates/:id': endpoint({
        description: 'Delete a costed bom export template',
        pathParams: r.Record({
            id: r.String,
        }),
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
    'POST /export/costed-bom/templates': endpoint({
        description: 'Create a costed bom export template',
        pathParams: r.Undefined,
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: r.Undefined,
        requestBody: CostedBomExportTemplateInputRuntype,
        responseBody: r.Record({
            data: CostedBomExportTemplateRuntype,
        }),
    }),
    'PUT /export/costed-bom/templates/:id': endpoint({
        description: 'Update a costed bom export template',
        pathParams: r.Record({
            id: r.String,
        }),
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: r.Undefined,
        requestBody: CostedBomExportTemplateInputRuntype,
        responseBody: r.Record({
            data: CostedBomExportTemplateRuntype,
        }),
    }),
};
