import { PCBLayerSides } from '@luminovo/http-client';
import { findNearestTopLeftRegion } from '../../framework/findNearestTopLeftRegion';
import { Region } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';

export const combineSides = (regions: Region<PcbAttribute>[]): Region<PcbAttribute>[] => {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );
    const regionsWithSide = regionsWithAttributes.filter(({ attribute }) => attribute.attr === 'side');
    const regionsWithKeywords = regionsWithAttributes.filter(
        ({ attribute }) =>
            attribute.attr === 'soldermaskKeyword' ||
            attribute.attr === 'silkscreenKeyword' ||
            attribute.attr === 'peelableMaskKeyword',
    );

    const maxDistanceToKeyword = 0.1;
    regionsWithSide.forEach(({ region, attribute }) => {
        // find nearest region with soldermask or silkscreen
        const nearestKeyword = findNearestTopLeftRegion(region, regionsWithKeywords, maxDistanceToKeyword);
        if (nearestKeyword?.attribute.attr === 'soldermaskKeyword') {
            region.attributes.push({
                attr: 'soldermaskSide',
                value: attribute.value as PCBLayerSides,
                selected: attribute.selected,
                // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                // about these links than about direct matches
                // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                confidence:
                    ((nearestKeyword.attribute.confidence ?? 0.5) * (attribute.confidence ?? 0.5)) /
                    (1 + nearestKeyword.distance),
            });
        }
        if (nearestKeyword?.attribute.attr === 'silkscreenKeyword') {
            region.attributes.push({
                attr: 'silkscreenSide',
                value: attribute.value as PCBLayerSides,
                selected: attribute.selected,
                // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                // about these links than about direct matches
                // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                confidence:
                    ((nearestKeyword.attribute.confidence ?? 0.5) * (attribute.confidence ?? 0.5)) /
                    (1 + nearestKeyword.distance),
            });
        }
        if (nearestKeyword?.attribute.attr === 'peelableMaskKeyword') {
            region.attributes.push({
                attr: 'peelableMask',
                value: attribute.value as PCBLayerSides,
                selected: attribute.selected,
                // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                // about these links than about direct matches
                // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                confidence:
                    ((nearestKeyword.attribute.confidence ?? 0.5) * (attribute.confidence ?? 0.5)) /
                    (1 + nearestKeyword.distance),
            });
        }
    });

    regions = mergeTopAndBottomSidesIntoBothIfBothArePresent(regions, 'soldermaskSide');
    regions = mergeTopAndBottomSidesIntoBothIfBothArePresent(regions, 'silkscreenSide');
    regions = mergeTopAndBottomSidesIntoBothIfBothArePresent(regions, 'peelableMask');

    return regions;
};

const mergeTopAndBottomSidesIntoBothIfBothArePresent = (
    regions: Region<PcbAttribute>[],
    attributeType: 'soldermaskSide' | 'silkscreenSide' | 'peelableMask',
): Region<PcbAttribute>[] => {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );
    const regionsWithSideTop = regionsWithAttributes.filter(
        ({ attribute }) =>
            attribute.attr === attributeType && attribute.value === PCBLayerSides.TOP && attribute.selected !== false,
    );
    const regionsWithSideBottom = regionsWithAttributes.filter(
        ({ attribute }) =>
            attribute.attr === attributeType &&
            attribute.value === PCBLayerSides.BOTTOM &&
            attribute.selected !== false,
    );

    if (regionsWithSideTop.length > 0 && regionsWithSideBottom.length > 0) {
        regionsWithAttributes.forEach(({ attribute }) => {
            if (
                attribute.attr === attributeType &&
                (attribute.value === PCBLayerSides.TOP || attribute.value === PCBLayerSides.BOTTOM) &&
                attribute.selected !== false
            ) {
                attribute.value = 'both';
            }
        });
        // instead of overwriting we might want to create a new region with merged bounding box and drop attributes from old regions
        // this would help with showing where the top and bottom come from in the PDF
    }
    const regionsWithSideTopUnselected = regionsWithAttributes.filter(
        ({ attribute }) =>
            attribute.attr === attributeType && attribute.value === PCBLayerSides.TOP && attribute.selected === false,
    );
    const regionsWithSideBottomUnselected = regionsWithAttributes.filter(
        ({ attribute }) =>
            attribute.attr === attributeType &&
            attribute.value === PCBLayerSides.BOTTOM &&
            attribute.selected === false,
    );

    if (regionsWithSideTopUnselected.length > 0 && regionsWithSideBottomUnselected.length > 0) {
        regionsWithAttributes.forEach(({ attribute }) => {
            if (
                attribute.attr === attributeType &&
                (attribute.value === PCBLayerSides.TOP || attribute.value === PCBLayerSides.BOTTOM) &&
                attribute.selected === false
            ) {
                attribute.value = 'none';
                attribute.selected = undefined;
            }
        });
        // instead of overwriting we might want to create a new region with merged bounding box and drop attributes from old regions
        // this would help with showing where the top and bottom come from in the PDF
    }
    return regions;
};
