/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import {
    AvatarIcon,
    colorSystem,
    FieldCheckboxControlled,
    FieldRadioControlled,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    SecondaryIconButton,
    StatusChip,
    TertiaryButton,
    Text,
    TextField,
} from '@luminovo/design-system';
import { AssemblyMonitoring } from '@luminovo/http-client';
import { Close } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useAllOrganizationUsers } from '../../../../resources/user/userHandler';
import { Title } from './AssemblyMonitoringUtils';

type AssemblyMonitoringFormState = {
    frequency: 'Daily' | 'Inactive' | 'Weekly' | 'Monthly';
    interests: { lifecycle: boolean; compliance: boolean };
    users: { id: string; email: string; first_name: string; last_name: string }[];
};

export const AssemblyMonitoringFormContainer = ({
    assemblyId,
    onClose,
    assemblyMonitoring,
}: {
    assemblyId: string;
    onClose: () => void;
    assemblyMonitoring: AssemblyMonitoring;
}) => {
    const defaultValues: AssemblyMonitoringFormState = {
        frequency: assemblyMonitoring.frequency,
        interests: {
            lifecycle: assemblyMonitoring.interests.lifecycle,
            compliance: assemblyMonitoring.interests.compliance,
        },
        users: assemblyMonitoring.users,
    };

    const { mutateAsync, isLoading } = useHttpMutation('POST /assemblies/:assemblyId/monitoring', {
        snackbarMessage: t`Monitoring settings updated`,
        onSuccess: onClose,
    });

    const onSubmit = async (form: AssemblyMonitoringFormState) => {
        await mutateAsync({
            pathParams: { assemblyId },
            requestBody: {
                frequency: form.frequency,
                interests: form.interests,
                users: form.users.map((user) => user.id),
            },
        });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <AssemblyMonitoringFormView
                onClose={onClose}
                isActive={assemblyMonitoring.is_active}
                isLoading={isLoading}
            />
        </FormContainer>
    );
};

const AssemblyMonitoringFormView = ({
    onClose,
    isActive,
    isLoading,
}: {
    onClose: () => void;
    isActive: boolean;
    isLoading: boolean;
}) => {
    const { control, watch, reset } = useFormContext<AssemblyMonitoringFormState>();

    const values = watch();

    return (
        <>
            <Flexbox
                flexDirection="column"
                padding="32px"
                gap={8}
                style={{ background: colorSystem.neutral[0], borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
            >
                <Flexbox justifyContent="space-between" marginBottom="16px">
                    <SecondaryIconButton size="small" onClick={() => onClose()}>
                        <Close fontSize="inherit" />
                    </SecondaryIconButton>
                    <StatusChip color={isActive ? 'green' : 'neutral'} label={isActive ? t`Active` : t`Inactive`} />
                </Flexbox>
                <Text variant="h3">
                    <Trans>Assembly monitoring</Trans>
                </Text>
                <Text>
                    <Trans>Set up automatic in-app and email alerts for this assembly and all its subassemblies.</Trans>
                </Text>
            </Flexbox>
            <Flexbox padding="32px">
                <Flexbox flexDirection="column" gap="24px" width="100%">
                    <Flexbox flexDirection="column" gap="12px">
                        <Title
                            label={t`Notify me of changes in`}
                            tooltip={t`Be informed if the state of lifecycle or compliance changed at a distributor or obsolescence provider like IHS, for approved part options of this assembly at all subassemblies. `}
                        />
                        <Flexbox flexDirection="column" gap="8px">
                            <Flexbox alignItems={'center'} gap={8}>
                                <FieldCheckboxControlled
                                    control={control}
                                    name="interests.lifecycle"
                                    FieldProps={{ size: 'small' }}
                                />
                                <Text>
                                    <Trans>Lifecycle</Trans>
                                </Text>
                            </Flexbox>
                            <Flexbox alignItems={'center'} gap={8}>
                                <FieldCheckboxControlled
                                    control={control}
                                    name="interests.compliance"
                                    FieldProps={{ size: 'small' }}
                                />
                                <Text>
                                    <Trans>Compliance</Trans>
                                </Text>
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexDirection="column" gap="12px">
                        <Title label={t`Who should be notified?`} />
                        <UsersAutocomplete />
                        {values.users.map((user) => {
                            return <UserLabel key={user.id} user={user} />;
                        })}
                    </Flexbox>
                    <Flexbox flexDirection="column" gap="12px">
                        <Title
                            label={t`Set the frequency`}
                            tooltip={t`Get weekly risk monitoring every Sunday or monthly notifications on the first Sunday`}
                        />
                        <Flexbox alignItems={'center'} gap={8}>
                            <FieldRadioControlled
                                control={control}
                                name="frequency"
                                FieldProps={{
                                    fieldValue: 'Inactive',
                                    size: 'small',
                                }}
                            />
                            <Text>
                                <Trans>Inactive</Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox alignItems={'center'} gap={8}>
                            <FieldRadioControlled
                                control={control}
                                name="frequency"
                                FieldProps={{
                                    fieldValue: 'Weekly',
                                    size: 'small',
                                }}
                            />
                            <Text>
                                <Trans>Weekly</Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox alignItems={'center'} gap={8}>
                            <FieldRadioControlled
                                control={control}
                                name="frequency"
                                FieldProps={{
                                    fieldValue: 'Monthly',
                                    size: 'small',
                                }}
                            />
                            <Text>
                                <Trans>Monthly</Trans>
                            </Text>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox gap="4px" justifyContent="flex-end">
                        <SecondaryButton
                            size="medium"
                            onClick={() => {
                                reset();
                                onClose();
                            }}
                        >
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <PrimaryButton size="medium" type="submit" isLoading={isLoading}>
                            <Trans>Save</Trans>
                        </PrimaryButton>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </>
    );
};

const useAllOrganizationUsersOptions = () => {
    const { isLoading, data: usersData = [] } = useAllOrganizationUsers();

    return {
        isLoading,
        data: usersData.map((user) => {
            return { id: user.id, email: user.email, first_name: user.first_name, last_name: user.last_name };
        }),
    };
};

const UsersAutocomplete = () => {
    const { control, setValue } = useFormContext<AssemblyMonitoringFormState>();

    const { data } = useAllOrganizationUsersOptions();
    return (
        <Controller
            control={control}
            name="users"
            render={({ field }) => (
                <Autocomplete
                    multiple
                    selectOnFocus
                    disableClearable
                    value={field.value}
                    options={data}
                    autoHighlight
                    renderInput={(params) => <TextField {...params} placeholder={t`Select users`} size="small" />}
                    noOptionsText={t`No options.`}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                        field.onChange(newValue);
                        setValue('users', newValue);
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} style={{ padding: '8px' }}>
                                <Text>
                                    {`${option.first_name} ${option.last_name} •`}{' '}
                                    <span style={{ color: colorSystem.neutral[6] }}>{`${option.email}`}</span>
                                </Text>
                            </li>
                        );
                    }}
                    renderTags={() => null}
                />
            )}
        />
    );
};

const UserLabel = ({
    user,
}: {
    user: {
        id: string;
        email: string;
        first_name: string;
        last_name: string;
    };
}) => {
    const { setValue, watch } = useFormContext<AssemblyMonitoringFormState>();
    const selectedUsers = watch('users');

    return (
        <Flexbox key={user.id} justifyContent="space-between">
            <Flexbox alignItems={'center'} gap={8}>
                <AvatarIcon
                    size="large"
                    user={{ firstName: user.first_name, lastName: user.last_name, email: user.email }}
                />
                <Flexbox flexDirection={'column'} gap={4}>
                    <Text
                        variant="body-small-semibold"
                        color={colorSystem.neutral[8]}
                    >{`${user.first_name} ${user.last_name}`}</Text>
                    <Text variant="caption" color={colorSystem.neutral[6]}>
                        {user.email}
                    </Text>
                </Flexbox>
            </Flexbox>
            <TertiaryButton
                size="small"
                onClick={() => {
                    setValue(
                        'users',
                        selectedUsers.filter((u) => u.id !== user.id),
                    );
                }}
                style={{ padding: 0, height: 'unset' }}
            >
                <Trans>Remove</Trans>
            </TertiaryButton>
        </Flexbox>
    );
};
