import { plural, t } from '@lingui/macro';
import { isPresent } from '../typingUtils';
import { formatDecimal } from './formatDecimal';

export function formatDays(days?: number | null, opts: { ifNotPresent?: string; ifNotFinite?: string } = {}): string {
    const { ifNotPresent = '', ifNotFinite = t`Unknown` } = opts;
    if (!isPresent(days)) {
        return ifNotPresent;
    }
    if (!isFinite(days) || isNaN(days)) {
        return ifNotFinite;
    }

    return `${formatDecimal(days)} ${plural(days, { one: 'day', zero: 'days', other: 'days' })}`;
}
