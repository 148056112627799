import { ButtonGroup as MuiButtonGroup, ButtonGroupProps as MuiButtonGroupProps, styled } from '@mui/material';
import React from 'react';
import { h5 } from '../../theme';

export type ButtonGroupProps = Omit<MuiButtonGroupProps, 'color' | 'variant'> & {
    size: 'large' | 'small';
};

export const ButtonGroup = React.forwardRef(function ButtonGroup(
    { size, ...props }: ButtonGroupProps,
    ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element {
    switch (size) {
        case 'small':
            return (
                <SmallButtonGroup ref={ref} {...props}>
                    {props.children}
                </SmallButtonGroup>
            );
        case 'large':
            return (
                <LargeButtonGroup ref={ref} {...props}>
                    {props.children}
                </LargeButtonGroup>
            );
    }
});

const DefaultButtonGroup = styled(MuiButtonGroup)({
    '& *': {
        ...h5,
    },
});

const SmallButtonGroup = styled(DefaultButtonGroup)({
    '& button': {
        padding: '4px 8px',
        height: '24px',
    },
});

const LargeButtonGroup = styled(DefaultButtonGroup)({
    '& button': {
        padding: '8px 12px',
        height: '32px',
    },
});
