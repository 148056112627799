import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { FormItem } from '@luminovo/design-system';
import { MenuItem } from '@mui/material';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { displayCurrencySymbol } from '../../utils/currencyUtils';
import { SelectController } from '../formLayouts/reactHookFormComponents/reactHookFormComponents';

interface Props<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>
    extends UseControllerProps<TFieldValues, TName> {}

export function CurrencyFormItem<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    name,
    control,
}: Props<TFieldValues, TName>): JSX.Element {
    return (
        <FormItem label={t`Currency`}>
            <SelectController name={name} control={control}>
                {Object.values(Currency).map((currency, i) => (
                    <MenuItem value={currency} key={currency}>
                        {`${currency} - ${displayCurrencySymbol(currency)}`}
                    </MenuItem>
                ))}
            </SelectController>
        </FormItem>
    );
}
