import * as r from 'runtypes';
import { PCBV2BoardPropertiesValuesRuntype } from './pcbBackendTypes';

const BoundingBoxRuntype = r.Record({
    x: r.String,
    y: r.String,
    width: r.String,
    height: r.String,
});

export type ExtractedAttributeValueDTO = r.Static<typeof ExtractedAttributeValueRuntype>;
const ExtractedAttributeValueRuntype = r.Union(
    r.Record({ type: r.Literal('Str'), data: PCBV2BoardPropertiesValuesRuntype }),
    r.Record({ type: r.Literal('Int'), data: PCBV2BoardPropertiesValuesRuntype }),
    r.Record({ type: r.Literal('Bool'), data: PCBV2BoardPropertiesValuesRuntype }),
    r.Record({ type: r.Literal('StrArray'), data: PCBV2BoardPropertiesValuesRuntype }),
    r.Record({ type: r.Literal('Float'), data: PCBV2BoardPropertiesValuesRuntype }),
);

export type ExtractedAttributeDTO = r.Static<typeof ExtractedAttributeRuntype>;
const ExtractedAttributeRuntype = r.Record({
    name: r.String,
    value: ExtractedAttributeValueRuntype,
    confidence: r.String.optional(),
});

export type MergedResultDTO = r.Static<typeof MergedResultRuntype>;
const MergedResultRuntype = r.Record({
    id: r.String,
    page_number: r.Number,
    location_on_page: BoundingBoxRuntype,
    attributes: r.Array(ExtractedAttributeRuntype),
});

export type ExtractionPCBV2File = r.Static<typeof ExtractionPCBV2FileRuntype>;
export const ExtractionPCBV2FileRuntype = r.Record({
    id: r.String.optional(),
    name: r.String,
});

export type ExtractionResultsDTO = r.Static<typeof ExtractionResultsRuntype>;
export const ExtractionResultsRuntype = r.Record({
    id: r.String,
    pcb: r.String,
    merged_results: r.Array(MergedResultRuntype),
    file: ExtractionPCBV2FileRuntype,
});

export type ExtractionResultsPostDTO = r.Static<typeof ExtractionResultsPostRuntype>;
export const ExtractionResultsPostRuntype = r.Record({
    pcb: r.String,
    merged_results: r.Array(MergedResultRuntype),
    file: ExtractionPCBV2FileRuntype,
});
