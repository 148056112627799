// eslint-disable-next-line no-restricted-imports
import { useLocation, useParams } from 'react-router-dom';
import { LumiQuoteRoute, PageParams, QueryParams } from '../utils/routes';
export { useToken } from '@luminovo/auth';

/**
 * @deprecated Please use ProtectedRoute and prop-drill the URL parameters instead.
 */
export function useQueryParams<T extends LumiQuoteRoute>(): QueryParams<T> {
    const pairs = Array.from(new URLSearchParams(useLocation().search));
    const result: Record<string, string> = {};
    for (const [key, val] of pairs) {
        result[key] = val;
    }
    // eslint-disable-next-line
    return result as QueryParams<T>;
}

/**
 * @deprecated Please use ProtectedRoute and prop-drill the URL parameters instead.
 */
export function usePageParams<T extends LumiQuoteRoute>() {
    return useParams<PageParams<T>>();
}
