import { QuantityUnit } from '@luminovo/http-client';
import { Attribute } from '../types';

export const pieceKeywords = ['Stk\\.?', 'Stück', 'St\\.?', 'Piece', 'PCE', 'Pcs', 'PCEs', 'E', 'EA', 'pc\\.?'];
const pieceRegex = new RegExp(`(?:\\s|^)(${pieceKeywords.join('|')})(?:\\s|$)`, 'i');

export function parseUnit(str: string): undefined | Attribute {
    if (pieceRegex.test(str)) {
        return { attr: 'unit', value: QuantityUnit.Pieces };
    }
    return undefined;
}
