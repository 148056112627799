import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type DigiKeyLocaleSiteDTO = r.Static<typeof DigiKeyLocaleSiteDTORuntype>;
export const DigiKeyLocaleSiteDTORuntype = r.Union(
    r.Literal('DE'),
    r.Literal('US'),
    r.Literal('CA'),
    r.Literal('JP'),
    r.Literal('UK'),
    r.Literal('AT'),
    r.Literal('BE'),
    r.Literal('DK'),
    r.Literal('FI'),
    r.Literal('GR'),
    r.Literal('IE'),
    r.Literal('IT'),
    r.Literal('LU'),
    r.Literal('NL'),
    r.Literal('NO'),
    r.Literal('PT'),
    r.Literal('ES'),
    r.Literal('KR'),
    r.Literal('HK'),
    r.Literal('SG'),
    r.Literal('CN'),
    r.Literal('TW'),
    r.Literal('AU'),
    r.Literal('FR'),
    r.Literal('IN'),
    r.Literal('NZ'),
    r.Literal('SE'),
    r.Literal('MX'),
    r.Literal('CH'),
    r.Literal('IL'),
    r.Literal('PL'),
    r.Literal('SK'),
    r.Literal('SI'),
    r.Literal('LV'),
    r.Literal('LT'),
    r.Literal('EE'),
    r.Literal('CZ'),
    r.Literal('HU'),
    r.Literal('BG'),
    r.Literal('MY'),
    r.Literal('ZA'),
    r.Literal('RO'),
    r.Literal('TH'),
    r.Literal('PH'),
);

export type DigiKeyLocaleCurrencyDTO = r.Static<typeof DigiKeyLocaleCurrencyDTORuntype>;
export const DigiKeyLocaleCurrencyDTORuntype = r.Union(
    r.Literal('EUR'),
    r.Literal('USD'),
    r.Literal('CAD'),
    r.Literal('JPY'),
    r.Literal('GBP'),
    r.Literal('HKD'),
    r.Literal('SGD'),
    r.Literal('TWD'),
    r.Literal('KRW'),
    r.Literal('AUD'),
    r.Literal('NZD'),
    r.Literal('INR'),
    r.Literal('DKK'),
    r.Literal('NOK'),
    r.Literal('SEK'),
    r.Literal('ILS'),
    r.Literal('CNY'),
    r.Literal('PLN'),
    r.Literal('CHF'),
    r.Literal('CZK'),
    r.Literal('HUF'),
    r.Literal('RON'),
    r.Literal('ZAR'),
    r.Literal('MYR'),
    r.Literal('THB'),
    r.Literal('PHP'),
);

export interface DigikeyRedirectUrlDTO extends r.Static<typeof DigikeyRedirectUrlDTORuntype> {}
export const DigikeyRedirectUrlDTORuntype = r.Record({
    redirect_url: r.String,
});

export interface DigikeyIsAuthenticatedDTO extends r.Static<typeof DigikeyIsAuthenticatedDTORuntype> {}
export const DigikeyIsAuthenticatedDTORuntype = r.Record({
    status: ApiIntegrationStatusRuntype,
});

export interface DigiKeySupportedSettingDTO extends r.Static<typeof DigiKeySupportedSettingDTORuntype> {}
export const DigiKeySupportedSettingDTORuntype = r.Record({
    store_location: DigiKeyLocaleSiteDTORuntype,
    currency: r.Array(DigiKeyLocaleCurrencyDTORuntype),
});

export interface DigiKeySettingDTO extends r.Static<typeof DigiKeySettingDTORuntype> {}
export const DigiKeySettingDTORuntype = r.Record({
    store_location: DigiKeyLocaleSiteDTORuntype,
    currency: DigiKeyLocaleCurrencyDTORuntype,
});
