import {
    generateWebSocket,
    makeWebSocketMessageHandler,
    RegisteredWebSocketEndpoint,
    WebSocketEndpointOptions,
    WebSocketMessageHandler,
} from '@luminovo/http-client';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { isPresent } from '../../utils/isPresent';

/**
 * A hook that handles the connection to a websocket endpoint.
 * Since sending messages is not yet needed, it is not implemented.
 */
export function useHttpWebSocket<T extends RegisteredWebSocketEndpoint>(
    endpoint: T,
    options: WebSocketEndpointOptions<T>,
    {
        enabled,
        onMessage,
        onClose,
    }: {
        enabled: boolean;
        onMessage: WebSocketMessageHandler<T>;
        onClose: (event: CloseEvent) => void;
    },
): void {
    const { data: websocket } = useQuery({
        queryKey: [endpoint, options],
        queryFn: () =>
            generateWebSocket({
                endpoint,
                options,
            }),
        enabled,
        staleTime: Infinity,
    });

    React.useEffect(() => {
        if (!isPresent(websocket)) {
            return;
        }
        const warppedOnMessage = makeWebSocketMessageHandler(endpoint, onMessage);

        if (websocket.readyState === WebSocket.OPEN) {
            websocket.addEventListener('message', warppedOnMessage);
            websocket.addEventListener('close', onClose);
        }

        return () => {
            websocket.removeEventListener('message', warppedOnMessage);
            websocket.removeEventListener('close', onClose);
        };
    }, [endpoint, websocket, websocket?.readyState, onMessage, onClose]);
}
