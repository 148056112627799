import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Column, Flexbox, Row } from '@luminovo/design-system';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Popover, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { usePdfViewerState } from '../../../PdfViewer';
import { attributeObjectToList } from '../../../PdfViewer/model/RegionNetwork/attributes/attributeObjectToList';
import { getAttributeColor } from '../../../PdfViewer/model/RegionNetwork/attributes/getAttributeColor';
import { formatAttribute } from '../../../PdfViewer/model/RegionNetwork/formatAttribute';
import { InferredAttribute } from '../../../PdfViewer/model/RegionNetwork/infer';
import { isAttributeRequired } from '../../../PdfViewer/model/RegionNetwork/isAttributeRequired';
import { AttributeObject, AttributeOf, AttributeValueOf } from '../../../PdfViewer/model/RegionNetwork/types';
import { useOnHover } from '../hooks/useOnHover';
import { attributeOrder, TableQuotationOfferItem, VisibleAttributes } from '../types';
import { InputComponentType, PopoverForm } from './PopoverForm';

enum Confidence {
    error = 0,
    low = 1,
    regular = 2,
    verified = 3,
}

type SelectableColumn<T extends VisibleAttributes> = Omit<Column<TableQuotationOfferItem>, 'render' | 'id'> & {
    attribute: T;
    InputComponent?: InputComponentType;
    align?: 'left' | 'right';
    required?: boolean;
    render: ({
        extracted,
        row,
    }: {
        extracted: AttributeValueOf<T>;
        row: Row<TableQuotationOfferItem>;
    }) => JSX.Element | string;
};

export const selectableColumn = <T extends VisibleAttributes>(
    column: SelectableColumn<T>,
): Column<TableQuotationOfferItem> => {
    const required = isAttributeRequired({ attr: column.attribute });
    return {
        id: column.attribute,
        label: (
            <Tooltip
                title={
                    formatAttribute(column.attribute, { variant: 'long' }) +
                    ': ' +
                    (required ? t`Required` : t`Optional`)
                }
            >
                <span>
                    {column.label} {required && <span style={{ color: colorSystem.neutral[5] }}>*</span>}
                </span>
            </Tooltip>
        ),
        render: (row) => {
            const attribute = getSafeAttributeValue(row.data, column.attribute);

            return <Cell attributeType={column.attribute} extractedAttribute={attribute} row={row} column={column} />;
        },
    };
};

function getSafeAttributeValue<T extends VisibleAttributes>(
    row: TableQuotationOfferItem,
    attribute: T,
): AttributeOf<T> | undefined {
    if (!row[attribute] || !row[attribute].attribute) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return row[attribute].attribute as AttributeOf<T>;
}

function Cell<T extends VisibleAttributes>({
    column,
    row,
    extractedAttribute,
    attributeType,
}: {
    column: SelectableColumn<T>;
    row: Row<TableQuotationOfferItem>;
    attributeType: T;
    extractedAttribute: AttributeOf<T> | undefined;
}): JSX.Element {
    const [, dispatch] = usePdfViewerState();
    const { onSelect } = row.data[attributeType];
    const isSelected = row.data[attributeType].isSelected ?? false;

    const columnIndex = attributeOrder[column.attribute];
    const rowIndex = row.index;

    const { popover, ref } = usePopoverForm({
        InputComponent: column.InputComponent,
        attribute: attributeType,
        row,
        showPopover: isSelected,
    });

    const rendered =
        extractedAttribute !== undefined && 'value' in extractedAttribute ? (
            column.render({
                // eslint-disable-next-line
                extracted: extractedAttribute.value as any,
                row,
            })
        ) : (
            <span />
        );

    const confidence = getConfidence({ extractedAttribute, attributeType });

    const defaultStyles: React.CSSProperties = {
        cursor: column.InputComponent ? 'pointer' : 'default',
        background: getAttributeColor({ attribute: extractedAttribute, attributeType }),
    };
    const popupStyles: React.CSSProperties = {
        ...defaultStyles,
        background: colorSystem.violet[1],
        outline: `1px solid ${colorSystem.violet[4]}`,
    };

    const handlers = useOnHover(onSelect);

    return (
        <>
            {popover}
            <TableCell
                onClick={() => {
                    onSelect();
                    dispatch({
                        type: 'setSelectedCell',
                        selectedCell: {
                            columnIndex,
                            rowIndex,
                        },
                    });
                }}
                ref={ref}
                sx={row.data[column.attribute].isSelected ? popupStyles : defaultStyles}
                {...handlers}
            >
                <Flexbox
                    alignItems="center"
                    gap={4}
                    style={{
                        justifyContent: column.align === 'right' ? 'flex-end' : 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {rendered}
                    <ExtractionQualityIcon confidence={confidence} />
                </Flexbox>
            </TableCell>
        </>
    );
}

function getConfidence({
    extractedAttribute,
    attributeType,
}: {
    extractedAttribute: InferredAttribute | undefined;
    attributeType: VisibleAttributes;
}) {
    if (!extractedAttribute || !isPresent(extractedAttribute.value)) {
        if (!isAttributeRequired({ attr: attributeType })) {
            return Confidence.low;
        }
        return Confidence.error;
    }
    if (extractedAttribute?.origin === 'manual') {
        return Confidence.verified;
    }
    if ((extractedAttribute?.confidence ?? 1) < 0.2) {
        return Confidence.low;
    }
    return Confidence.regular;
}

function ExtractionQualityIcon({ confidence }: { confidence: Confidence }) {
    if (confidence === Confidence.error) {
        return <ErrorOutlineOutlined style={{ color: colorSystem.red[6], height: 16 }} />;
    }
    return null;
}

function usePopoverForm<T extends VisibleAttributes>({
    InputComponent,
    attribute,
    row,
    showPopover,
}: {
    InputComponent?: InputComponentType;
    attribute: T;
    row: Row<TableQuotationOfferItem>;
    showPopover: boolean;
}) {
    const regionId = row.data.part.region?.id;
    const [, dispatch] = usePdfViewerState();

    const ref = React.useRef<HTMLTableCellElement>(null);

    const handleSubmit = (attributeObject: AttributeObject): void => {
        if (!regionId) {
            return;
        }
        const newAttributes = attributeObjectToList(attributeObject).map((attr) => {
            return { ...attr, origin: 'manual' as const, confidence: 1 };
        });
        dispatch({
            type: 'addAttributes',
            attrs: newAttributes,
            regionId,
        });
        dispatch({
            type: 'selectNextCell',
        });
        row.data[attribute].onSelect(1);
    };

    const popover =
        showPopover && InputComponent && regionId ? (
            <Popover
                onClose={() =>
                    dispatch({
                        type: 'setSelectedCell',
                        selectedCell: undefined,
                    })
                }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorEl={ref.current}
                open={Boolean(ref.current)}
            >
                <PopoverForm
                    row={row}
                    onSubmit={handleSubmit}
                    InputComponent={InputComponent}
                    attribute={row.data[attribute].attribute}
                    attributeType={attribute}
                />
            </Popover>
        ) : null;

    return {
        popover,
        ref,
    };
}
