import { assertUnreachable, isPresent } from '@luminovo/commons';
import { CenteredLayout, Flexbox } from '@luminovo/design-system';
import {
    DriverDTO,
    DriversOfManufacturingDetailsDTO,
    ManufacturingAssemblyDetailsDTO,
    ManufacturingAssemblyDetailsOverviewDTO,
} from '@luminovo/http-client';
import {
    ManufacturingUpsellBanner,
    selectDriverAutomaticDetails,
    selectDriverIdString,
    selectDriverName,
    selectDriverNotes,
    selectDriverOriginTags,
    selectDriverType,
} from '@luminovo/manufacturing-core';
import { Box, Grid, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { PageLayout } from '../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useManufacturingModuleVariant } from '../../../featureFlags';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { colorSystem } from '../../../themes';
import { ManufacturingBreadcrumbForAssemblyPage } from '../ManufacturingBreadcrumbs';
import { Notes } from '../shared/ManufacturingSharedComponents';
import { Details } from './ManufacturingAssemblyDetailsPageComponents';
import { MultipleDriversInformationCard } from './MultipleDriversInformationCard';
import { DriverInformation, DriverInformationCard } from './components/DriverInformationCard';
import { DriversCard } from './components/DriversCard';
import { EditNotes } from './components/EditNotes';
import ManufacturingAssemblyScenarioTableCard from './components/ManufacturingAssemblyScenarioTable/ManufacturingAssemblyScenarioTableCard';

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBlock: '24px',
});

const getDriverInformation = (unitDriver: DriverDTO): DriverInformation => {
    const dType = selectDriverType(unitDriver);
    const automaticDetails = selectDriverAutomaticDetails(unitDriver);
    const originTags = selectDriverOriginTags(unitDriver);
    switch (dType) {
        case 'User+Manual':
            if (automaticDetails !== undefined) {
                throw new Error('Automatic driver count cannot have undefined automatic details');
            }
            return {
                driverName: selectDriverName(unitDriver),
                driverType: dType,
                automaticDetails: undefined,
                driverOrigin: undefined,
                notes: selectDriverNotes(unitDriver),
            };
        case 'User+Automatic':
            if (automaticDetails === undefined) {
                throw new Error('Automatic driver count cannot have undefined automatic details');
            }
            return {
                driverName: selectDriverName(unitDriver),
                driverType: dType,
                automaticDetails: automaticDetails,
                driverOrigin: originTags,
                notes: selectDriverNotes(unitDriver),
            };
        case 'System+Automatic':
            if (automaticDetails !== undefined) {
                throw new Error('System drivers should have undefined automatic details');
            }
            return {
                driverName: selectDriverName(unitDriver),
                driverType: dType,
                automaticDetails: automaticDetails,
                driverOrigin: originTags,
                notes: selectDriverNotes(unitDriver),
            };

        default:
            assertUnreachable(dType);
    }
};

const getSelectedDriver = (
    drivers: DriversOfManufacturingDetailsDTO[],
): DriversOfManufacturingDetailsDTO | undefined => {
    return drivers.length > 0 ? drivers[0] : undefined;
};

const SelectedDriverInformationCard = ({
    manufacturingDriver,
    manufacturingAssemblyDetails,
    rfqId,
}: {
    manufacturingDriver: DriversOfManufacturingDetailsDTO;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    rfqId: string;
}) => {
    switch (manufacturingDriver.driver_count.type) {
        case 'PerScenario':
            return (
                <MultipleDriversInformationCard
                    driver={manufacturingDriver}
                    driverInformation={getDriverInformation(manufacturingDriver.driver)}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                    rfqId={rfqId}
                />
            );

        case 'Basic':
        default:
            return (
                <DriverInformationCard
                    driverStatus={manufacturingDriver.driver_count.data.status}
                    driverInformation={getDriverInformation(manufacturingDriver.driver)}
                    rfqId={rfqId}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                />
            );
    }
};

export const ManufacturingAssemblyDetailsPage = ({
    rfqId,
    manufacturingAssemblyDetailsId,
}: {
    rfqId: string;
    manufacturingAssemblyDetailsId: string;
}): JSX.Element => {
    const { data } = useHttpQuery('GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview', {
        pathParams: { manufacturingAssemblyDetailsId: manufacturingAssemblyDetailsId },
    });

    return (
        <PageLayout style={{ backgroundColor: colorSystem.neutral[1] }}>
            {!isPresent(data) ? (
                <CenteredLayout>
                    <SpinnerWithBackdrop noBackdrop={true} />
                </CenteredLayout>
            ) : (
                <ManufacturingAssemblyDetailsPageInner overview={data} rfqId={rfqId} />
            )}
        </PageLayout>
    );
};

const ManufacturingAssemblyDetailsPageInner = ({
    rfqId,
    overview,
}: {
    rfqId: string;
    overview: ManufacturingAssemblyDetailsOverviewDTO;
}): JSX.Element => {
    const { drivers, assembly, manufacturing_assembly_details: manufacturingAssemblyDetails } = overview;

    const { notes, id } = manufacturingAssemblyDetails;

    const [selectedDriver, setSelectedDriver] = React.useState<DriversOfManufacturingDetailsDTO | undefined>(
        getSelectedDriver(drivers),
    );

    const hasDriversFromSourcing = drivers.some(
        ({ driver }) => selectDriverOriginTags(driver)?.find((tag) => tag === 'Sourcing') !== undefined,
    );

    const isManufacturingLite = useManufacturingModuleVariant() === 'lite';

    useEffect(() => {
        if (selectedDriver !== undefined) {
            setSelectedDriver(
                drivers.find(
                    ({ driver }) => selectDriverIdString(driver) === selectDriverIdString(selectedDriver.driver),
                ),
            );
        } else setSelectedDriver(getSelectedDriver(drivers));
    }, [drivers, selectedDriver]);

    return (
        <Flexbox flexDirection="column">
            <ManufacturingBreadcrumbForAssemblyPage />

            <Box paddingX="24px">
                {isManufacturingLite && (
                    <Flexbox justifyContent="center" marginTop="32px">
                        <ManufacturingUpsellBanner />
                    </Flexbox>
                )}
                <StyledBox>
                    <Details designator={assembly.designator} type={assembly.assembly_type.type} />
                    <Notes notes={notes ?? undefined} />
                    <EditNotes manufacturingAssemblyDetailsId={id} notes={notes ?? undefined} />
                </StyledBox>
                <Box mb={'24px'}>
                    <ManufacturingAssemblyScenarioTableCard
                        hasDriversFromSourcing={hasDriversFromSourcing}
                        manufacturingAssemblyDetailsOverview={overview}
                        assemblyId={overview.assembly.id}
                    />
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={6} lg={4}>
                        <DriversCard
                            selectedDriverId={selectedDriver ? selectDriverIdString(selectedDriver.driver) : undefined}
                            setSelectedDriver={setSelectedDriver}
                            assemblyName={assembly.designator}
                            drivers={drivers}
                            rfqId={rfqId}
                            manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                        />
                    </Grid>
                    {selectedDriver && (
                        <Grid xs={8} item lg={8}>
                            <SelectedDriverInformationCard
                                manufacturingDriver={selectedDriver}
                                manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                                rfqId={rfqId}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Flexbox>
    );
};
