import * as r from 'runtypes';

export const ScenarioCombinationForCalculationRuntype = r.Record({
    batch_sizes: r.Array(r.Number),
    order_size: r.Number,
    manufacturing_scenario: r.String,
    sourcing_scenario: r.String,
    assembly_id: r.String,
});

export type ScenarioCombinationForCalculationDTO = r.Static<typeof ScenarioCombinationForCalculationRuntype>;

export const ScenarioCombinationForCalculationRuntypePatch = r.Record({
    batch_sizes: r.Array(r.Number),
    manufacturing_scenario_id: r.String,
    sourcing_scenario_id: r.String,
});

export const ScenarioCombinationForCalculationRuntypePatchResponse = r.Record({
    batch_sizes: r.Array(r.Number),
    manufacturing_scenario: r.String,
    sourcing_scenario: r.String,
    id: r.String,
});

export type ScenarioCombinationForCalculationPatchDTO = r.Static<typeof ScenarioCombinationForCalculationRuntypePatch>;
