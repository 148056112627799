import { t } from '@lingui/macro';
import { Flexbox, SecondaryButton, Text, colorSystem } from '@luminovo/design-system';
import { SimpleOffersDTO } from '@luminovo/http-client';
import { CrisisAlert } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { convertOfferErrorToText } from '../utils/convertOfferErrorToText';

export const OfferErrorBox = ({
    children,
    title,
    Icon = CrisisAlert,
}: {
    children?: React.ReactNode;
    title: string;
    Icon?: React.ComponentType<{ style: React.CSSProperties }>;
}) => {
    return (
        <Box
            style={{
                background: colorSystem.neutral.white,
                borderRadius: '8px',
                padding: '16px',
            }}
        >
            <Flexbox alignItems={'center'} flexDirection={'column'} gap={'8px'}>
                <Icon
                    style={{
                        width: '40px',
                        height: '40px',
                        color: colorSystem.neutral[5],
                    }}
                />
                <Text variant={'h4'}>{title}</Text>
                {children}
            </Flexbox>
        </Box>
    );
};

export const OffersError = ({ offers }: { offers: SimpleOffersDTO }) => {
    const errorText = convertOfferErrorToText(offers.data);
    const [showDetails, setShowDetails] = React.useState(false);

    if (errorText === undefined) return null;

    return (
        <>
            <SecondaryButton
                size="small"
                onClick={() => setShowDetails(!showDetails)}
                style={{
                    marginBlockStart: '8px',
                }}
            >
                {showDetails ? t`Hide details` : t`Show details`}
            </SecondaryButton>
            {showDetails === true && (
                <ul style={{ paddingInlineStart: '16px' }}>
                    {typeof errorText !== 'string' &&
                        errorText.map((error, index) => (
                            <li key={index} style={{ marginBlockEnd: '16px' }}>
                                <Text
                                    variant="body-small"
                                    style={{
                                        color: colorSystem.red[7],
                                        background: colorSystem.neutral[1],
                                        border: `1px solid ${colorSystem.neutral[2]}`,
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginInlineEnd: '1ch',
                                        marginBlockEnd: '16px',
                                    }}
                                >
                                    {error.label}
                                </Text>
                                <Text variant="body-small">{error.error}</Text>
                            </li>
                        ))}
                </ul>
            )}
        </>
    );
};
