import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { CustomPriceType, PriceType } from '@luminovo/http-client';

export { formatPackaging } from '@luminovo/sourcing-core';

export const priceTypeTranslations: Record<PriceType, MessageDescriptor> = {
    [PriceType.QuotePrice]: defineMessage({ message: 'Quote price' }),
    [PriceType.ListPrice]: defineMessage({ message: 'List price' }),
    [PriceType.ContractPrice]: defineMessage({ message: 'Contract price' }),
    [PriceType.CustomerNegotiatedPrice]: defineMessage({ message: 'Customer negotiated price' }),
    [PriceType.PurchasePrice]: defineMessage({ message: 'Purchase price' }),
    [PriceType.StandardPrice]: defineMessage({ message: 'Standard price' }),
    [PriceType.TargetPrice]: defineMessage({ message: 'Target price' }),
};

export const customPriceTypeTranslations: Record<CustomPriceType, MessageDescriptor> = {
    [CustomPriceType.QuotePrice]: defineMessage({ message: 'Quote price' }),
    [CustomPriceType.ListPrice]: defineMessage({ message: 'List price' }),
    [CustomPriceType.ContractPrice]: defineMessage({ message: 'Contract price' }),
    [CustomPriceType.CustomerNegotiatedPrice]: defineMessage({ message: 'Customer negotiated price' }),
};
