import * as rt from 'runtypes';
import { PCBV2FileRuntype, PCBV2Properties } from './pcbBackendTypes';

export const ViewBoxCoordinateRuntype = rt.Record({
    x: rt.Number,
    y: rt.Number,
    width: rt.Number,
    height: rt.Number,
});
export type ViewBoxCoordinateType = rt.Static<typeof ViewBoxCoordinateRuntype>;

const PCBV2CadFileRuntype = PCBV2FileRuntype.extend({
    id: rt.String,
    fileColor: rt.String,
    isVisible: rt.Boolean,
    boundingBox: ViewBoxCoordinateRuntype.optional(),
});

export type PCBV2CadFile = rt.Static<typeof PCBV2CadFileRuntype>;
export const PCBV2CadFilesRuntype = rt.Array(PCBV2CadFileRuntype);

export type PcbSpecificationProperties = PCBV2Properties;
