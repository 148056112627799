import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { OtsFullPart } from '@luminovo/http-client';

type AlertStatus =
    | {
          status: 'Active';
      }
    | {
          status: 'Triggered';
      };

export type AlertCondition = {
    condition: 'Gte';
    content: number;
};

export type AlertSupplierPreference = {
    usePreferred: boolean;
    useApproved: boolean;
    useNotApproved: boolean;
    additionalSupplier: string[];
};

export type AlertType = {
    id: string;
    part: string;
    owner: string | null;
    status: AlertStatus;
    condition: AlertCondition;
    supplierPreference: AlertSupplierPreference;
    notes: string;
    creationDate: string;
};

export type AlertWithFullPart = AlertType & { part: OtsFullPart };

export enum AlertConditionEnum {
    Available = 'Available',
    Gte = 'Gte',
}

export const alertConditionTranslations: Record<AlertConditionEnum, MessageDescriptor> = {
    [AlertConditionEnum.Available]: defineMessage({ message: 'Stock is available' }),
    [AlertConditionEnum.Gte]: defineMessage({ message: 'Stock is more than or equal to' }),
};

export type AlertCreationForm = {
    part: string;
    condition: AlertConditionEnum;
    conditionContent?: number;
    useNotApprovedSuppliers: boolean;
    notes: string;
};
