import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../boundingBox';
import { generateId } from '../generateId';
import { Attribute, AttributeExtractionRule, ExtractionItem, Extractor } from '../types';

export function extractCells<TAttribute extends Attribute>({
    extractionRules,
}: {
    extractionRules: AttributeExtractionRule<TAttribute>[];
}): Extractor<TAttribute> {
    return {
        generator: async function* (azureExtractionResult: AzureExtractionResult): AsyncGenerator<ExtractionItem> {
            for (const table of azureExtractionResult.analyzeResult.tables) {
                for (const cell of table.cells) {
                    const headerCell = table.cells.find(
                        (header) => header.kind === 'columnHeader' && header.columnIndex === cell.columnIndex,
                    );

                    if (!headerCell) {
                        continue;
                    }

                    yield {
                        value: {
                            content: cell.content,
                            boundingRegions: cell.boundingRegions,
                        },
                        context: {
                            content: headerCell.content,
                            boundingRegions: headerCell.boundingRegions,
                        },
                        // Assuming 1 as cells have no confidence
                        confidence: 1,
                    };
                }
            }
        },

        extractRegion: ({ value }) => {
            return [
                {
                    id: generateId(value),
                    content: value.content,
                    pageNumber: value.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(value.boundingRegions),
                    attributes: [],
                },
            ];
        },

        extractionRules,
    };
}
