import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import {
    BorderOuterRounded,
    DescriptionRounded,
    FolderRounded,
    HdrWeakRounded,
    LayersRounded,
    Memory,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import { PCBTabTypes } from '../../../../../resources/pcb/analytics/analytic';

export type PCBRouteSuffix = 'cad-viewer' | 'specification' | 'stackup' | 'files' | 'drills' | 'panelization';
const order: PCBRouteSuffix[] = ['files', 'drills', 'stackup', 'specification', 'cad-viewer', 'panelization'];

export type PcbSidebarLinkProps = {
    type: PCBTabTypes;
    Icon: React.ComponentType<SvgIconProps>;
    text: () => string;
    id: string;
    route: PCBRouteSuffix;
    // If the pcb is setup without files, then this links should be disabled
    enableForCustomers: boolean;
    isWorkflow: boolean;
};

const pcbWorkflowLinks: PcbSidebarLinkProps[] = [
    {
        Icon: DescriptionRounded,
        text: () => t`PCB specification`,
        route: 'specification',
        id: 'pcb-general-tab',
        type: PCBTabTypes.Specification,
        enableForCustomers: true,
        isWorkflow: true,
    },
    {
        Icon: LayersRounded,
        text: () => t`Stackup`,
        route: 'stackup',
        id: 'pcb-stack-up-tab',
        type: PCBTabTypes.StackUp,
        enableForCustomers: true,
        isWorkflow: true,
    },
    {
        Icon: FolderRounded,
        text: () => t`File manager`,
        route: 'files',
        id: 'pcb-layer-tab',
        type: PCBTabTypes.LayerStack,
        enableForCustomers: true,
        isWorkflow: true,
    },
    {
        Icon: HdrWeakRounded,
        text: () => t`Drill manager`,
        route: 'drills',
        id: id('design/pcb-drills-tab'),
        type: PCBTabTypes.Drills,
        enableForCustomers: true,
        isWorkflow: true,
    },
    {
        Icon: Memory,
        text: () => t`CAD viewer`,
        route: 'cad-viewer',
        id: 'pcb-cad-viewer-tab',
        type: PCBTabTypes.CADViewer,
        enableForCustomers: true,
        isWorkflow: false,
    },
    {
        Icon: BorderOuterRounded,
        text: () => t`Shipping panel`,
        route: 'panelization',
        id: id('design/pcb-panelization-tab'),
        type: PCBTabTypes.Panelization,
        enableForCustomers: false,
        isWorkflow: false,
    },
];
export const pcbSidebarLinks = pcbWorkflowLinks.sort((a, b) => order.indexOf(a.route) - order.indexOf(b.route));
