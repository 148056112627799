import { Trans } from '@lingui/macro';
import { FieldSelectControlled } from '@luminovo/design-system';
import { OtsFullPart } from '@luminovo/http-client';
import { formatPart } from '../../../../formatters';
import { LabelPart } from '../../../labels';
import { selectableColumn } from './columnSelectable';

export const columnPart = selectableColumn({
    attribute: 'part',
    label: <Trans>Part</Trans>,
    InputComponent: ({ control, row }) => {
        const options: OtsFullPart[] = uniqBy(row.data.expectedParts, (p) => p.id);
        return (
            <FieldSelectControlled
                control={control}
                name="part"
                required
                FieldProps={{
                    autoFocus: true,
                    options,
                    fullWidth: true,
                    getOptionLabel: (option) => {
                        return formatPart(option);
                    },
                    isOptionEqualToValue(option, value) {
                        return option.id === value.id;
                    },
                }}
            />
        );
    },
    render: ({ extracted }) => <LabelPart part={extracted ?? undefined} />,
});

function uniqBy<T>(arr: T[], fn: (item: T) => string): T[] {
    const map = new Map<string, T>();
    for (const item of arr) {
        map.set(fn(item), item);
    }
    return Array.from(map.values());
}
