import { Trans } from '@lingui/macro';
import { QuantityUnitDTO } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { BomItemFormState } from '../BomItemFormState';

function hasDesignatorOrQtyMismatch({
    designator,
    quantity,
}: {
    designator: string[];
    quantity: QuantityUnitDTO | null;
}) {
    return designator.length === 0 || !quantity || !quantity?.quantity || !quantity.unit;
}

export function designatorQtyMismatchTooltip() {
    return <Trans>Designator or quantity unclear. Please address the issue before continuing.</Trans>;
}

export const useHasDesignatorQuantityMismatch = () => {
    const { getValues } = useFormContext<BomItemFormState>();
    return hasDesignatorOrQtyMismatch({
        designator: getValues().designator,
        quantity: getValues().quantity,
    });
};
