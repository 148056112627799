import { CenteredLayout, Flexbox } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useAssemblyAggregateSourcingQuantity } from '../../../../resources/assembly/assemblyHandler';
import { useSourcingScenariosOfRfq } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import {
    BatchSizesMultiSelectControlled,
    ManufacturingScenarioNameControlled,
    SourcingScenarioControlled,
} from './formItems';
import { BatchSizeNotAMultipleOfOrderSize, useBatchSizeEvaluation } from './formItems/BatchSizeNotMultipleOfOrderSize';
import { ManufacturingScenarioNotesControlled } from './formItems/ManufacturingScenarioNotesControlled';
import { BaseManufacturingScenarioFormState } from './types';

const validationErrors: ValidationErrors<BaseManufacturingScenarioFormState> = {
    'manufacturing_scenario.name_already_exists': {
        fieldPath: 'manufacturingScenarioName',
    },
};

export const EditManufacturingScenarioForm = ({
    rfqId,
    assemblyId,
    defaultValues,
    onSubmit,
}: {
    rfqId: string;
    assemblyId: string;
    defaultValues: BaseManufacturingScenarioFormState;
    onSubmit: (form: BaseManufacturingScenarioFormState) => Promise<void>;
}): JSX.Element => {
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { assemblyQuantities } = useAssemblyAggregateSourcingQuantity({
        rfqId,
        assemblyId,
        enabled: true,
    });

    if (!sourcingScenarios || !assemblyQuantities) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection="column" height="100%" gap="24px">
                <Flexbox style={{ flexGrow: 1 }}>
                    <EditManufacturingScenarioFormInner
                        assemblyQuantities={assemblyQuantities}
                        sourcingScenarios={sourcingScenarios}
                    />
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent="end">
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const EditManufacturingScenarioFormInner = ({
    sourcingScenarios,
    assemblyQuantities,
}: {
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyQuantities: Record<string, number>;
}): JSX.Element => {
    const { control } = useFormContext<BaseManufacturingScenarioFormState>();

    const batchSizeEvaluation = useBatchSizeEvaluation(sourcingScenarios, assemblyQuantities);

    return (
        <Flexbox flexDirection="column" width="100%" gap="24px">
            <ManufacturingScenarioNameControlled control={control} name="manufacturingScenarioName" />

            <BatchSizesMultiSelectControlled control={control} />

            <SourcingScenarioControlled assemblyQuantities={assemblyQuantities} sourcingScenarios={sourcingScenarios} />
            <ManufacturingScenarioNotesControlled control={control} name={'notes'} />

            {batchSizeEvaluation.type === 'error' && <BatchSizeNotAMultipleOfOrderSize error={batchSizeEvaluation} />}
        </Flexbox>
    );
};
