import { Trans } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { EditNotesForm } from './EditNotesForm';
import { NotesFormState } from './editNotesFormFunctions';

const paperStyles = {
    style: { padding: '8px 0px' },
};

export const EditNotesDialog = ({
    open,
    notes,
    onSubmit,
    onClose,
}: {
    open: boolean;
    notes: string | undefined;
    onSubmit: (form: NotesFormState) => Promise<void>;
    onClose: () => void;
}): JSX.Element => {
    return (
        <Dialog
            keepMounted={false}
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'xs'}
            PaperProps={paperStyles}
        >
            <DialogTitle>
                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Typography variant="h2" color={'textSecondary'}>
                            <Trans>Edit notes</Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <EditNotesForm
                    notes={notes}
                    onClose={() => onClose()}
                    onSubmit={async (form) => {
                        await onSubmit(form);
                        onClose();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};
