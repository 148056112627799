export type Permission =
    | 'create:assembly'
    | 'create:customer'
    | 'create:custom:part'
    | 'create:driver'
    | 'create:inventory'
    | 'create:manufacturer'
    | 'create:ots:part'
    | 'create:resource'
    | 'create:rfq:ems'
    | 'create:rfq:customer'
    | 'create:supplier'
    | 'create:expense'
    | 'create:activity'
    | 'create:calculation_template'
    | 'delete:rfq:customer'
    | 'delete:rfq:ems'
    | 'delete:assembly'
    | 'delete:custom:part'
    | 'delete:manufacturing_scenario_template'
    | 'delete:calculation_template'
    | 'edit:assembly'
    | 'edit:customer'
    | 'edit:customer:billing_address'
    | 'edit:custom:part'
    | 'edit:inventory'
    | 'edit:ipn'
    | 'edit:component_spec'
    | 'edit:organization_settings'
    | 'edit:api_token'
    | 'edit:ots:part'
    | 'edit:own_settings'
    | 'edit:rfq:ems'
    | 'edit:rfq:customer'
    | 'edit:rfq'
    | 'edit:solution_configuration'
    | 'edit:user:organization'
    | 'edit:user:customer'
    | 'edit:resource'
    | 'edit:driver'
    | 'edit:expense'
    | 'edit:activity'
    | 'edit:manufacturing_scenario_template'
    | 'edit:calculation_template'
    | 'import:bom'
    | 'search:ots:part'
    | 'view:alert'
    | 'view:assembly'
    | 'view:customer:all'
    | 'view:design_item'
    | 'view:custom:part'
    | 'view:driver'
    | 'view:inventory'
    | 'view:ipn'
    | 'view:manufacturer'
    | 'view:organization_settings'
    | 'view:api_token'
    | 'view:ots:part'
    | 'view:part_library'
    | 'view:part_types'
    | 'view:pcb'
    | 'view:quotation'
    | 'view:calculation'
    | 'view:resource'
    | 'view:rfq:details'
    | 'view:rfq'
    | 'view:solution_configuration'
    | 'view:sourcing'
    | 'view:supplier'
    | 'view:activity'
    | 'create:custom_part_offer'
    | 'view:custom_part_offer'
    | 'edit:custom_part_offer'
    | 'view:wuerth_sourcing_api'
    | 'edit:wuerth_sourcing_api'
    | 'create:order'
    | 'view:order'
    | 'edit:order'
    | 'create:activity_configuration'
    | 'create:expense_configuration'
    | 'create:manually_overwritten_driver_count'
    | 'create:manufacturing_assembly_details'
    | 'create:manufacturing_scenario'
    | 'create:manufacturing_scenario_template'
    | 'view:manufacturing_scenario_template'
    | 'edit:activity_configuration'
    | 'edit:expense_configuration'
    | 'edit:manually_overwritten_driver_count'
    | 'edit:manufacturing_assembly_details'
    | 'edit:manufacturing_scenario'
    | 'view:activity_configuration'
    | 'view:expense'
    | 'view:expense_configuration'
    | 'view:manually_overwritten_driver_count'
    | 'view:manufacturing_assembly_details'
    | 'view:manufacturing_scenario'
    | 'create:scenario_combinations_for_calculation'
    | 'edit:scenario_combinations_for_calculation'
    | 'view:scenario_combinations_for_calculation'
    | 'create:calculation_assembly_details'
    | 'edit:calculation_assembly_details'
    | 'view:calculation_assembly_details'
    | 'create:calculation_assembly_cost'
    | 'edit:calculation_assembly_cost'
    | 'view:calculation_assembly_cost'
    | 'view:calculation_template'
    | 'create:frozen_scenario_cost'
    | 'edit:frozen_scenario_cost'
    | 'delete:frozen_scenario_cost'
    | 'view:frozen_scenario_cost'
    | 'view:purchase_order'
    | 'edit:purchase_order'
    | 'create:purchase_order'
    | 'delete:supplier';

export type RoleTest = Permission[] | ((roles: Permission[]) => boolean);

/**
 * @param test The list of required permissions or a predicate which validates the list of required permissions.
 *
 * @returns true if the user has all the required permissions.
 *
 * Example:
 *
 * ```ts
 * isAuthorized(['a','b','c'], ['a'])
 * // returns true because the user has permission 'a'
 *
 * isAuthorized(['x','y','z'], ['a'])
 * // returns false because the user doesn't have permission 'a'
 *
 * isAuthorized(['x'], ['a'])
 * // returns false because the user doesn't have permission 'a'
 *
 * isAuthorized(['x'], ['x','y'])
 * // returns false because the user doesn't have permission 'y'
 * ```
 */
export function isAuthorized(permissions: Permission[], test: RoleTest): boolean {
    if (typeof test === 'function') {
        return test(permissions);
    }
    return test.every((requiredPermission) => permissions.includes(requiredPermission));
}
