import { Trans } from '@lingui/macro';
import { logToExternalErrorHandlers } from '@luminovo/commons';
import { ErrorOutlineRounded } from '@mui/icons-material';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Flexbox } from '../Flexbox';
import { NonIdealState } from '../NonIdealState';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { CenteredLayout } from '../layout/CenteredLayout';

export const DefaultFallbackComponent: React.FunctionComponent<FallbackProps & { onReset: () => void }> = ({
    error,
    resetErrorBoundary,
    onReset,
}) => {
    logToExternalErrorHandlers(error);
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={ErrorOutlineRounded}
                title="Oops! Something went wrong."
                description=""
                overrides={{
                    ActionButton: () => (
                        <Flexbox gap={'8px'}>
                            <SecondaryButton
                                size="small"
                                onClick={(props) => {
                                    onReset();
                                    resetErrorBoundary(props);
                                }}
                            >
                                <Trans>Try again</Trans>
                            </SecondaryButton>
                            <PrimaryButton
                                size="small"
                                onClick={() => {
                                    onReset();
                                    window.location.reload();
                                }}
                            >
                                <Trans>Reload the page</Trans>
                            </PrimaryButton>
                        </Flexbox>
                    ),
                }}
            />
        </CenteredLayout>
    );
};
