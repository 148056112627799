import {
    FullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    OtsComponentFull,
} from '@luminovo/http-client';
import { LifecycleChip } from '@luminovo/sourcing-core';
import { LifecycleChipWithTooltip } from './LifecycleChipWithTooltip';
import { OtsPartLifecycleStatusOriginsTooltip } from './OtsPartLifecycleStatusOriginsTooltip';

export const PartLifecycleView = ({ part, disabled = false }: { part: FullPart; disabled?: boolean }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={part.lifecycle_status}>
                <LifecycleChipWithTooltip status={part.lifecycle_status} disabled={disabled} disabledTooltip={true} />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    }
    if (isOtsComponentFull(part)) {
        return <OtsComponentLifecycleView otsComponent={part} isRemoved={disabled} showShortName={true} />;
    }
    if (isGenericFullPart(part)) {
        return <LifecycleChipWithTooltip status={part.lifecycle_status} disabled={disabled} />;
    }
    return <></>;
};

export const OtsComponentLifecycleView = ({
    otsComponent,
    isRemoved,
    showShortName = false,
}: {
    otsComponent: OtsComponentFull;
    isRemoved: boolean;
    showShortName?: boolean;
}) => {
    const hasOnlyOneOtsPartLinked =
        otsComponent.matches.length === 1 && otsComponent.matches[0].part.type === 'OffTheShelf';

    if (hasOnlyOneOtsPartLinked) {
        const otsPart = otsComponent.matches[0].part.data;
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={otsPart.id} lifecycleStatus={otsPart.lifecycle_status}>
                <LifecycleChip
                    lifecycle={otsComponent.lifecycle_status}
                    disabled={isRemoved}
                    showShortName={showShortName}
                />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    }
    return (
        <LifecycleChip lifecycle={otsComponent.lifecycle_status} disabled={isRemoved} showShortName={showShortName} />
    );
};
