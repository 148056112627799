import { SolutionConfigurationSourcingDTO } from '@luminovo/http-client';

/**
 * Extracts the assembly name from the given solution configuration sourcing.
 *
 * The assembly name is the name of the direct parent assembly of the solution configuration.
 *
 * @param solutionConfigurationSourcing - The solution configuration sourcing to extract the assembly name from.
 * @returns The assembly name.
 */
export function extractAssemblyName(solutionConfigurationSourcing: SolutionConfigurationSourcingDTO): string {
    const parentAssemblies = solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items.flatMap(
        (item) => item.parent_assemblies,
    );

    // Parent assemblies are sorted by depth, so the last one is the direct parent
    const parentAssembly = parentAssemblies[parentAssemblies.length - 1];
    return parentAssembly?.name ?? '';
}
