import { FontVariant } from '@luminovo/design-system';
import { GenericLabel } from './GenericLabel';

export const ResourceLabel = ({
    resourceName,
    site,
    variant,
}: {
    resourceName: string;
    site: string | undefined;
    variant?: FontVariant;
}): JSX.Element => <GenericLabel variant={variant} name={resourceName} additionalInfo={site} />;
