export function SolutionStatusSuccessIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#02BE9E"
            />
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusWarningIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#F9B686"
            />
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusErrorIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#FA6B7E"
            />
            <rect x="0.5" y="0.5" width="25" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}
export function SolutionStatusSuccessToWarningIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#02BE9E"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#F9B686"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusSuccessToErrorIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#02BE9E"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#FA6B7E"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusWariningToSuccessIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#F9B686"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#02BE9E"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusWariningToErrorIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#F9B686"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#FA6B7E"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusErrorToSuccessIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#FA6B7E"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#02BE9E"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}

export function SolutionStatusErrorToWarningIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="46" height="22" viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" fill="none" />
            <path
                d="M16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
                fill="#FA6B7E"
            />
            <path
                d="M23.4999 12.8083C23.4499 12.7583 23.4249 12.6986 23.4249 12.6292C23.4249 12.5597 23.4471 12.5028 23.4916 12.4583L24.7166 11.2333H20.5833C20.511 11.2333 20.4513 11.2097 20.4041 11.1625C20.3569 11.1153 20.3333 11.0556 20.3333 10.9833C20.3333 10.9111 20.3569 10.8514 20.4041 10.8042C20.4513 10.7569 20.511 10.7333 20.5833 10.7333H24.7166L23.4833 9.49999C23.4333 9.44999 23.4096 9.39166 23.4124 9.32499C23.4152 9.25833 23.4416 9.19999 23.4916 9.14999C23.5416 9.10555 23.6013 9.08333 23.6708 9.08333C23.7402 9.08333 23.7971 9.10555 23.8416 9.14999L25.4999 10.8083C25.5277 10.8361 25.5471 10.8639 25.5583 10.8917C25.5694 10.9194 25.5749 10.95 25.5749 10.9833C25.5749 11.0167 25.5694 11.0472 25.5583 11.075C25.5471 11.1028 25.5277 11.1306 25.4999 11.1583L23.8499 12.8083C23.7999 12.8583 23.7416 12.8833 23.6749 12.8833C23.6083 12.8833 23.5499 12.8583 23.4999 12.8083Z"
                fill="#676D7E"
            />
            <path
                d="M36 11C36 12.6569 34.6569 14 33 14C31.3431 14 30 12.6569 30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11Z"
                fill="#F9B686"
            />
            <rect x="0.5" y="0.5" width="45" height="21" rx="10.5" stroke="#E9ECF4" />
        </svg>
    );
}
