import { t } from '@lingui/macro';
import { ActivityDTO, IdWithIndex } from '@luminovo/http-client';
import { SubmitHandler } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useMutationUpdateTemplate } from '../../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { EntityFormState, EntitySelectionDialogForm } from '../../../shared/Entities/EntitySelectionDialogForm';
import { useActivityColumns } from '../../../shared/Entities/columns';
import { createOrderedIdsFromSelectedIds } from '../../utils/createOrderedIds';

interface ActivitySelectionDialogProps {
    orderedActivityIds: IdWithIndex[];
    manufacturingScenarioTemplateId: string;
    closeDialog: () => void;
}

const ActivitySelectionDialog = ({
    orderedActivityIds,
    manufacturingScenarioTemplateId,
    closeDialog,
}: ActivitySelectionDialogProps): JSX.Element => {
    const { allActivities, inactiveActivities } = useSelectableActivities();
    const columns = useActivityColumns();
    const onSelectionUpdate = useSelectionUpdate(
        manufacturingScenarioTemplateId,
        orderedActivityIds,
        inactiveActivities,
    );

    const onSubmit: SubmitHandler<EntityFormState> = async (values: EntityFormState) => {
        await onSelectionUpdate(values.selectedIds);
        closeDialog();
    };
    const defaultValues: EntityFormState = {
        selectedIds: orderedActivityIds.map((activity) => activity.id),
    };

    return (
        <EntitySelectionDialogForm
            title={t`Select activities`}
            closeDialog={closeDialog}
            entities={allActivities.filter((expense) => expense.status === 'Active')}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            columns={columns}
            persistenceId={`activity-selection-dialog-${manufacturingScenarioTemplateId}`}
            defaultSelectedRowIds={defaultValues.selectedIds}
        />
    );
};

const useSelectableActivities = (): { allActivities: ActivityDTO[]; inactiveActivities: ActivityDTO[] } => {
    const { data, isLoading } = useHttpQuery('GET /activities', { queryParams: {} });

    if (isLoading || !data) {
        return { allActivities: [], inactiveActivities: [] };
    }

    const allActivities = data.data;
    const activeActivities = allActivities.filter((activity) => activity.status === 'Inactive');

    return { allActivities, inactiveActivities: activeActivities };
};

const useSelectionUpdate = (
    manufacturingScenarioTemplateId: string,
    orderedActivityIds: IdWithIndex[],
    inactiveActivities: ActivityDTO[],
) => {
    const { mutateAsync } = useMutationUpdateTemplate({ templateId: manufacturingScenarioTemplateId });
    const inactiveActivityIds = new Set(inactiveActivities.map((activity) => activity.id));

    return async (selectedIds: string[]) => {
        const updatedOrderedActivityIds = createOrderedIdsFromSelectedIds(orderedActivityIds, selectedIds).filter(
            (activityId) => !inactiveActivityIds.has(activityId.id),
        );

        await mutateAsync({
            // eslint-disable-next-line camelcase
            ordered_activities: updatedOrderedActivityIds,
        });
    };
};

export const useActivitySelectionDialog = ({
    orderedActivityIds,
    manufacturingScenarioTemplateId,
}: {
    orderedActivityIds: IdWithIndex[];
    manufacturingScenarioTemplateId: string;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () =>
            setDialog(
                <ActivitySelectionDialog
                    orderedActivityIds={orderedActivityIds}
                    manufacturingScenarioTemplateId={manufacturingScenarioTemplateId}
                    closeDialog={closeDialog}
                />,
            ),
    };
};
