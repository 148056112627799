import { Messages } from '@lingui/core';
import { messages as de } from './locales/de/messages';
import { messages as en } from './locales/en/messages';
import { messages as es } from './locales/es/messages';
import { messages as fr } from './locales/fr/messages';

export * from './comparators';
export * from './components';
export * from './extractors';
export * from './formatters';
export * from './i18n';
export * from './inputControls';
export * from './regex';
export * from './selectors';
export * from './sorters';

export const locales: {
    en: Messages;
    de: Messages;
    es: Messages;
    fr: Messages;
} = { de, en, es, fr };
