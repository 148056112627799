import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { FilterChip, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { BomItemApprovalStatus } from '@luminovo/http-client';
import { ErrorRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import { useState } from 'react';
import { useIsCustomer } from '../../../components/contexts/CurrentUserDetailsContext';
import { ModuleTableData } from '../../Bom/components/ModuleTableData';
import {
    CountWarningsProps,
    WarningSubCategoryBomItemIssues,
    countActiveWarningsAcrossBomItems,
} from '../countWarningsAcrossBomItems';

const INDENTATION = '28px';

export const WarningsAccordion = ({
    moduleTableData,
    setSelectedStatus,
    setWarningSubCategoryIssues,
    clearAppliedFilters,
    warningSubCategoryIssues,
    setSearchedText,
}: {
    moduleTableData: ModuleTableData[];
    setSelectedStatus: React.Dispatch<React.SetStateAction<BomItemApprovalStatus | undefined>>;
    setWarningSubCategoryIssues: React.Dispatch<React.SetStateAction<Set<WarningSubCategoryBomItemIssues>>>;
    clearAppliedFilters: () => void;
    warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
    setSearchedText: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const isCustomer = useIsCustomer();
    const [isExpanded, setExpanded] = useState<boolean>(false);

    const warningsCount: CountWarningsProps[] = countActiveWarningsAcrossBomItems(moduleTableData);

    const handleOnChange = (issueId: WarningSubCategoryBomItemIssues) => {
        setSearchedText('');
        clearAppliedFilters();
        setSelectedStatus(undefined);

        const newFilters = new Set(warningSubCategoryIssues);
        if (warningSubCategoryIssues.has(issueId)) {
            newFilters.delete(issueId);
        } else {
            newFilters.add(issueId);
        }
        setWarningSubCategoryIssues(newFilters);
    };

    if (warningsCount.length === 0) {
        return null;
    }

    return (
        <Flexbox id={id('design/warning_filters')}>
            <StyledAccordion expanded={isCustomer ? true : isExpanded} onChange={() => setExpanded((v) => !v)}>
                <AccordionSummary
                    style={{ width: '100%', boxSizing: 'border-box', cursor: isCustomer ? 'unset' : 'pointer' }}
                >
                    {isCustomer ? (
                        <Flexbox flexDirection="column" gap="8px">
                            <Flexbox gap={8} alignItems="center">
                                <ErrorRounded style={{ color: colorSystem.yellow[6], fontSize: '16px' }} />
                                <Text variant="h4">
                                    <Trans>Warnings</Trans>
                                </Text>
                            </Flexbox>
                            <Text style={{ marginLeft: INDENTATION }}>
                                <Trans>Please solve the issues below.</Trans>
                            </Text>
                        </Flexbox>
                    ) : (
                        <Flexbox justifyContent="space-between" width={'100%'}>
                            <Flexbox gap={8} alignItems="center">
                                <ErrorRounded
                                    fontSize="small"
                                    style={{ color: colorSystem.yellow[6], fontSize: '16px' }}
                                />
                                <Text variant="h4">
                                    <Trans>Warnings</Trans>
                                </Text>
                            </Flexbox>

                            <KeyboardArrowRightRounded
                                style={{
                                    color: colorSystem.neutral[7],
                                    transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                                }}
                            />
                        </Flexbox>
                    )}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <Flexbox gap={8} marginLeft={isCustomer ? INDENTATION : 0} flexWrap="wrap">
                        {warningsCount.map((warning) => {
                            return (
                                <FilterChip
                                    key={warning.issue}
                                    value={warning.issue}
                                    isSelected={warningSubCategoryIssues.has(warning.issue)}
                                    label={`${warning.label} • ${warning.count}`}
                                    onChange={handleOnChange}
                                />
                            );
                        })}
                    </Flexbox>
                </AccordionDetails>
            </StyledAccordion>
        </Flexbox>
    );
};

const StyledAccordion = styled(Accordion)({
    background: colorSystem.neutral[1],
    boxShadow: 'none',
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${colorSystem.neutral[2]}`,
});
