/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface OnlineComponentsCredentialsDTO extends r.Static<typeof OnlineComponentsCredentialsDTORuntype> {}
export const OnlineComponentsCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface OnlineComponentsCredentialsInputDTO
    extends r.Static<typeof OnlineComponentsCredentialsInputDTORuntype> {}
export const OnlineComponentsCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface OnlineComponentsResponseBodyDTO extends r.Static<typeof OnlineComponentsResponseBodyDTORuntype> {}
export const OnlineComponentsResponseBodyDTORuntype = r.Record({
    data: OnlineComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
