import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, colorSystem } from '@luminovo/design-system';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { PageLayout } from '../../../../components/PageLayout';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { UrlParams } from '../../../../utils/routes';
import { ExcelQuoteImportDropZone } from './ExcelQuoteImportDropZone';
import { ExcelQuoteImporterToolbar } from './ExcelQuoteImporterToolbar';
import { QuoteImporterFrom, useExcelQuoteImporterDefaultValues } from './QuoteImporterFrom';
import { QuoteImportResult, QuoteImporterFormState } from './types';

function InnerFormContainer({
    onSuccessfulImport,
    onReset,
}: {
    onSuccessfulImport: (value: QuoteImportResult) => void;
    onReset: () => void;
}) {
    const { control } = useFormContext<QuoteImporterFormState>();
    const state = useWatch({ control, name: 'state' });

    if (state === 'incomplete' || state === 'loading') {
        return (
            <Flexbox flexDirection={'column'} alignItems={'center'}>
                <ExcelQuoteImportDropZone onSuccess={onSuccessfulImport} isLoading={state === 'loading'} />
            </Flexbox>
        );
    }

    if (state === 'ready') {
        return (
            <Flexbox flexDirection={'column'} alignItems={'center'} height={'100%'}>
                <QuoteImporterFrom onReset={onReset} />
            </Flexbox>
        );
    }

    assertUnreachable(state);
}

export const ExcelQuoteImporterPage = (urlParams: UrlParams<'/rfqs/:rfqId/sourcing/quote-importer'>): JSX.Element => {
    const [importResult, setImportResult] = React.useState<QuoteImportResult | undefined>(undefined);
    const defaultValues = useExcelQuoteImporterDefaultValues(importResult, urlParams.pathParams.rfqId);
    const dummySubmit = React.useCallback(() => () => {}, []);

    return (
        <FormContainer key={defaultValues.state} defaultValues={defaultValues} onSubmit={dummySubmit}>
            <PageLayout
                layout="fragment"
                style={{ backgroundColor: colorSystem.neutral[1], height: '100%' }}
                header={<ExcelQuoteImporterToolbar />}
            >
                <InnerFormContainer onSuccessfulImport={setImportResult} onReset={() => setImportResult(undefined)} />
            </PageLayout>
        </FormContainer>
    );
};
