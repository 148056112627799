import { Trans } from '@lingui/macro';
import { Currency, formatMonetaryValue } from '@luminovo/commons';
import {
    Column,
    DataTable,
    DestructiveSecondaryIconButton,
    FieldNumericControlled,
    FieldTextControlled,
    useDataTableState,
} from '@luminovo/design-system';
import { OneTimeCostDTO } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { InputAdornment, TableCell } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CustomPartQuoteFormValues } from '../types';

type SharedContext = {
    offerIndex: number;
    currency: Currency;
    onRemove(index: number): void;
};

const columnWidth = (1024 - 80) / 3;

export function OneTimeCostsTable({
    oneTimeCosts,
    offerIndex,
    mode,
    onRemove,
}: {
    oneTimeCosts: OneTimeCostDTO[];
    offerIndex: number;
    mode: 'view' | 'edit';
    onRemove(index: number): void;
}) {
    const columns = mode === 'view' ? columnsViewMode : columnsEditMode;

    const { control } = useFormContext<CustomPartQuoteFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const tableState = useDataTableState<OneTimeCostDTO, SharedContext>({
        persistenceId: `supplierQuotePage::priceBreakTable:${mode}`,
        columns: columns,
        items: oneTimeCosts,
        sharedContext: {
            offerIndex,
            currency,
            onRemove,
        },
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 1000,
            rowsPerPageOptions: [1000],
        },
    });

    return (
        <DataTable
            key={`supplierQuotePage::priceBreakTable:${mode}:${oneTimeCosts.length}`}
            size="large"
            tableState={tableState}
        />
    );
}

const columnDescription: Column<OneTimeCostDTO, SharedContext> = {
    id: 'description',
    label: <Trans>Description</Trans>,
    width: columnWidth,
    render: ({ data }) => {
        return <TableCell>{data.description}</TableCell>;
    },
};

const columnPrice: Column<OneTimeCostDTO, SharedContext> = {
    id: 'price',
    label: <Trans>Price</Trans>,
    width: columnWidth,
    render: ({ data }) => <TableCell>{formatMonetaryValue(data.price)}</TableCell>,
};

const columnsViewMode = [columnDescription, columnPrice];

const columnEditDescription: Column<OneTimeCostDTO, SharedContext> = {
    ...columnDescription,
    render: function Render({ index }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();
        return (
            <TableCell>
                <FieldTextControlled
                    required
                    min={1}
                    max={40}
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                        placeholder: 'e.g. E-Test',
                    }}
                    control={control}
                    name={`offers.${offerIndex}.one_time_costs.${index}.description`}
                />
            </TableCell>
        );
    },
};

const columnEditPrice: Column<OneTimeCostDTO, SharedContext> = {
    ...columnPrice,
    render: function Render({ index }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();
        const currency = useWatch({ control, name: 'currency' });
        return (
            <TableCell>
                <FieldNumericControlled
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                        InputProps: {
                            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                        },
                    }}
                    required
                    max={1_000_000}
                    exclusiveMin={0}
                    control={control}
                    name={`offers.${offerIndex}.one_time_costs.${index}.price.amount`}
                />
            </TableCell>
        );
    },
};

const columnActions: Column<OneTimeCostDTO, SharedContext> = {
    id: 'actions',
    label: '',
    render: function Render({ index }, { onRemove }) {
        return (
            <TableCell>
                <DestructiveSecondaryIconButton
                    size="medium"
                    onClick={() => {
                        onRemove(index);
                    }}
                >
                    <Delete fontSize="inherit" />
                </DestructiveSecondaryIconButton>
            </TableCell>
        );
    },
};

const columnsEditMode = [columnEditDescription, columnEditPrice, columnActions];
