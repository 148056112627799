import { Currency, isPresent } from '@luminovo/commons';
import {
    CustomComponentFull,
    CustomFullPart,
    CustomLinkedPartDTO,
    CustomPriceType,
    CustomerDTO,
    OtsComponentFull,
    OtsFullPart,
    Packaging,
    PriceType,
    QuantityUnit,
    QuoteTrackingDTO,
    RfqDTO,
    StandardPartDTO,
    SupplierAndStockLocationDTO,
    ValidFor,
} from '@luminovo/http-client';
import { Control, FieldErrors } from 'react-hook-form';
import { ParsedObjectsResult } from 'read-excel-file/types';

type OffTheShelfLinkedPart = {
    type: 'OffTheShelf';
    part: OtsFullPart;
};

type IpnLinkedPart = {
    type: 'Ipn';
    part: OtsComponentFull;
};

export type CustomPartLinkedPart = {
    type: 'CustomPart';
    part: CustomFullPart;
};

export type CustomComponentLinkedPart = {
    type: 'CustomComponent';
    part: CustomComponentFull;
};

export type AlternativeLinkedPart = {
    type: 'AlternativePart';
    part: OtsFullPart | null;
    matches: OtsFullPart[];
    requestedOtsPart: OtsFullPart | undefined;
};

type NonMatchingLinkedPart = {
    type: 'NonMatching';
    part: null;
};

export type StandardPartQuoteLineTableData = {
    type: 'StandardPartLine';
    index: number;
    requestedPart: StandardPartDTO | undefined;
    linkedPart: OffTheShelfLinkedPart | IpnLinkedPart | AlternativeLinkedPart | NonMatchingLinkedPart;
    row: StandardPartQuoteLineRow['row'];
    warnings: StandardPartQuoteLineRow['warnings'];
    excelRowNumber: number;
};

export type CustomPartQuoteLineTableData = {
    type: 'CustomPartLine';
    index: number;
    requestedPart: CustomLinkedPartDTO | undefined;
    linkedPart: CustomPartLinkedPart | CustomComponentLinkedPart | NonMatchingLinkedPart;
    row: CustomPartQuoteLineRow['row'];
    warnings: CustomPartQuoteLineRow['warnings'];
    excelRowNumber: number;
};

export type QuoteLineTableData =
    | ({
          status: Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'>;
      } & StandardPartQuoteLineTableData)
    | ({
          status: Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'>;
      } & CustomPartQuoteLineTableData);

export type StandardPartQuoteImporterFormState = {
    file: File;
    rfq: RfqDTO;
    customer: CustomerDTO;
    quoteTracking: QuoteTrackingDTO | null;
    supplierAndStockLocation: SupplierAndStockLocationDTO | undefined;
    offerNumber: string | undefined;
    offerValidity: string | undefined;
    validFor: ValidFor;
    priceType: PriceType;
    selectedIndices: number[];
    lines: StandardPartQuoteLineTableData[];
};

export type CustomPartQuoteImporterFormState = {
    file: File;
    rfq: RfqDTO;
    customer: CustomerDTO;
    quoteTracking: QuoteTrackingDTO | null;
    supplierAndStockLocation: SupplierAndStockLocationDTO | undefined;
    offerNumber: string | undefined;
    offerValidity: string | undefined;
    validFor: ValidFor;
    priceType: CustomPriceType;
    selectedIndices: number[];
    lines: CustomPartQuoteLineTableData[];
};

export type QuoteImporterFormState = {
    state: 'incomplete' | 'loading' | 'ready';
    file: File;
    rfq: RfqDTO;
    customer: CustomerDTO;
    quoteTracking: QuoteTrackingDTO | null;
    supplierAndStockLocation: SupplierAndStockLocationDTO | undefined;
    offerNumber: string | undefined;
    offerValidity: string | undefined;
    validFor: ValidFor;
    priceType: PriceType;
    selectedIndices: number[];
    lines: StandardPartQuoteLineTableData[] | CustomPartQuoteLineTableData[];
};

export type SharedContext = {
    control: Control<QuoteImporterFormState, any>;
};

export type StandardPartSubmitProps = {
    values: StandardPartQuoteImporterFormState;
    errors: FieldErrors<StandardPartQuoteImporterFormState>;
};

export type CustomPartSubmitProps = {
    values: CustomPartQuoteImporterFormState;
    errors: FieldErrors<CustomPartQuoteImporterFormState>;
};

export type StandardPartQuoteImportResult = {
    file: File;
    quoteTrackingId: string | undefined;
    supplierAndStockLocationId: string | undefined;
    supplierName: string | undefined;
    offerNumber: string | undefined;
    offerValidity: string | undefined;
    quoteLineRow: StandardPartQuoteLineRow[];
};

export type CustomPartQuoteImportResult = {
    file: File;
    quoteTrackingId: string | undefined;
    supplierAndStockLocationId: string | undefined;
    supplierName: string | undefined;
    offerNumber: string | undefined;
    offerValidity: string | undefined;
    quoteLineRow: CustomPartQuoteLineRow[];
};

export type QuoteImportResult = StandardPartQuoteImportResult | CustomPartQuoteImportResult;

export function hasMissingUnitForLine(line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData): boolean {
    if (line.type === 'StandardPartLine') {
        return !isPresent(line.row.unit);
    }

    return false;
}

export function hasMissingLeadTimeUnitForLine(
    line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData,
): boolean {
    if (line.type === 'StandardPartLine') {
        return !isPresent(line.row.leadTimeUnit);
    }
    return false;
}

export function hasErrorsForLine(
    line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData,
    errors: FieldErrors<QuoteImporterFormState>,
): boolean {
    const index = line.index;
    if (!isPresent(line.linkedPart.part)) {
        return true;
    }

    if (hasMissingUnitForLine(line)) {
        return true;
    }
    if (hasMissingLeadTimeUnitForLine(line)) {
        return true;
    }

    if (isPresent(errors.lines) && isPresent(errors.lines[index])) {
        return true;
    }

    if (!isPresent(line.row.currency)) {
        return true;
    }

    if (line.type === 'StandardPartLine') {
        if (!isPresent(line.row.unitPriceQuantity)) {
            return true;
        }

        if (!isPresent(line.row.unitPrice) || line.row.unitPrice <= 0) {
            return true;
        }

        if (!isPresent(line.row.unitPriceQuantity) || line.row.unitPriceQuantity <= 0) {
            return true;
        }

        if (isPresent(line.row.stock) && line.row.stock < 0) {
            return true;
        }

        if (isPresent(line.row.minPackagingQuantity) && line.row.minPackagingQuantity <= 0) {
            return true;
        }

        if (isPresent(line.row.minOrderQuantity) && line.row.minOrderQuantity <= 0) {
            return true;
        }

        if (isPresent(line.row.leadTime) && line.row.leadTime <= 0) {
            return true;
        }
    }

    if (line.type === 'CustomPartLine') {
        if (!isPresent(line.row.unitPrice) || line.row.unitPrice <= 0) {
            return true;
        }

        if (isPresent(line.row.leadTimeInDays) && line.row.leadTimeInDays <= 0) {
            return true;
        }

        if (isPresent(line.row.requiredQuantity) && line.row.requiredQuantity <= 0) {
            return true;
        }

        if (isPresent(line.row.oneTimeCosts) && line.row.oneTimeCosts <= 0) {
            return true;
        }
    }

    return false;
}

export function hasWarningsForLine(line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData): boolean {
    return line.warnings.some((warning) => {
        // Casting is safe here because the schema guarantees that this is a QuoteHeader
        if (line.type === 'StandardPartLine') {
            const column = warning.column as StandardPartQuoteHeader;
            const propKey = standardPartQuoteHeaderToPropsMap[column];
            return !isPresent(line.row[propKey]);
        }

        if (line.type === 'CustomPartLine') {
            const column = warning.column as CustomPartQuoteHeader;
            const propKey = CustomPartQuoteHeaderToPropsMap[column];
            return isPresent(line.row[propKey]);
        }

        return false;
    });
}

export function extractQuoteLineStatus(
    line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData,
    errors: FieldErrors<QuoteImporterFormState>,
): Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'> {
    const status: Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'> = [];

    if (hasErrorsForLine(line, errors)) {
        status.push('errors');
    }

    if (hasWarningsForLine(line)) {
        status.push('warnings');
    }

    if (hasMissingUnitForLine(line)) {
        status.push('missingUnit');
    }

    if (hasMissingLeadTimeUnitForLine(line)) {
        status.push('missingLeadTimeUnit');
    }

    if (status.length === 0) {
        status.push('good');
    }

    return status;
}

export type StandardPartQuoteLineRow = {
    type: 'StandardPartLine';
    excelRowNumber: number;
    row: StandardPartQuoteLines;
    warnings: ParsedObjectsResult<StandardPartQuoteLines>['errors'];
};

export type CustomPartQuoteLineRow = {
    type: 'CustomPartLine';
    excelRowNumber: number;
    row: CustomPartQuoteLines;
    warnings: ParsedObjectsResult<CustomPartQuoteLines>['errors'];
};

export enum StandardPartQuoteHeader {
    LumiQuoteId = 'LumiQuote-ID',
    ManufacturerPartNumber = 'Manufacturer Part Number (MPN)*',
    Manufacturer = 'Manufacturer*',
    Ipn = 'IPN',
    RequiredQuantity = 'Required quantity',
    PartCategory = 'Part category',
    PartDescription = 'Part description',
    Unit = 'Unit',
    UnitPrice = 'Unit price*',
    UnitPriceQuantity = 'Unit Price quantity',
    Currency = 'Currency',
    SupplierPartNumber = 'Supplier part number (SKU)',
    Packaging = 'Packaging',
    MinOrderQuantity = 'Minimum Order Quantity (MOQ)',
    MinPackagingQuantity = 'Minimum Packaging Quantity (MPQ)',
    LeadTime = 'Lead time',
    LeadTimeUnit = 'Lead time unit',
    Stock = 'Stock',
    ValidUntil = 'Valid until (DD.MM.YYYY)',
    Ncnr = 'NCNR',
    AlternativeMPN = 'Alternative MPN',
    AlternativeManufacturer = 'Alternative manufacturer',
    AdditionalNotes = 'Additional notes',
}

export enum CustomPartQuoteHeader {
    LumiQuoteId = 'LumiQuote-ID',
    SourcingScenario = 'Sourcing scenario',
    Assembly = 'Assembly',
    Description = 'Description*',
    RequiredQuantity = 'Required quantity',
    Attachments = 'Attachments',
    UnitPrice = 'Unit price*',
    OneTimeCost = 'One-time cost',
    Currency = 'Currency',
    SupplierPartNumber = 'Supplier part number (SKU)',
    LeadTimeInDays = 'Lead time (in days)',
    ValidUntil = 'Valid until (DD.MM.YYYY)',
    AdditionalNotes = 'Additional notes',
}

export type StandardPartQuoteLines = Partial<{
    lumiQuoteId: string;
    mpn: string;
    manufacturer: string;
    ipn: string;
    requiredQuantity: number;
    partCategory: string;
    partDescription: string;
    unit: QuantityUnit;
    unitPrice: number;
    unitPriceQuantity: number;
    currency: Currency;
    supplierPartNumber: string;
    packaging: Packaging | null;
    minOrderQuantity: number;
    minPackagingQuantity: number;
    leadTime: number;
    leadTimeUnit: 'days' | 'weeks';
    stock: number;
    validUntil: string;
    ncnr: boolean;
    alternativeMpn: string;
    alternativeManufacturer: string;
    additionalNotes: string;
}>;

export type CustomPartQuoteLines = Partial<{
    lumiQuoteId: string;
    sourcingScenarioId: string;
    assembly: string;
    description: string;
    requiredQuantity: number;
    attachments: string;
    unitPrice: number;
    oneTimeCosts: number;
    currency: Currency;
    supplierPartNumber: string;
    leadTimeInDays: number;
    validUntil: string;
    additionalNotes: string;
}>;

export const standardPartQuoteHeaderToPropsMap: { [key in StandardPartQuoteHeader]: keyof StandardPartQuoteLines } = {
    [StandardPartQuoteHeader.LumiQuoteId]: 'lumiQuoteId',
    [StandardPartQuoteHeader.ManufacturerPartNumber]: 'mpn',
    [StandardPartQuoteHeader.Manufacturer]: 'manufacturer',
    [StandardPartQuoteHeader.Ipn]: 'ipn',
    [StandardPartQuoteHeader.RequiredQuantity]: 'requiredQuantity',
    [StandardPartQuoteHeader.PartCategory]: 'partCategory',
    [StandardPartQuoteHeader.PartDescription]: 'partDescription',
    [StandardPartQuoteHeader.Unit]: 'unit',
    [StandardPartQuoteHeader.UnitPrice]: 'unitPrice',
    [StandardPartQuoteHeader.UnitPriceQuantity]: 'unitPriceQuantity',
    [StandardPartQuoteHeader.Currency]: 'currency',
    [StandardPartQuoteHeader.SupplierPartNumber]: 'supplierPartNumber',
    [StandardPartQuoteHeader.Packaging]: 'packaging',
    [StandardPartQuoteHeader.MinOrderQuantity]: 'minOrderQuantity',
    [StandardPartQuoteHeader.MinPackagingQuantity]: 'minPackagingQuantity',
    [StandardPartQuoteHeader.LeadTime]: 'leadTime',
    [StandardPartQuoteHeader.LeadTimeUnit]: 'leadTimeUnit',
    [StandardPartQuoteHeader.Stock]: 'stock',
    [StandardPartQuoteHeader.ValidUntil]: 'validUntil',
    [StandardPartQuoteHeader.Ncnr]: 'ncnr',
    [StandardPartQuoteHeader.AlternativeMPN]: 'alternativeMpn',
    [StandardPartQuoteHeader.AlternativeManufacturer]: 'alternativeManufacturer',
    [StandardPartQuoteHeader.AdditionalNotes]: 'additionalNotes',
};

export const CustomPartQuoteHeaderToPropsMap: { [key in CustomPartQuoteHeader]: keyof CustomPartQuoteLines } = {
    [CustomPartQuoteHeader.LumiQuoteId]: 'lumiQuoteId',
    [CustomPartQuoteHeader.SourcingScenario]: 'sourcingScenarioId',
    [CustomPartQuoteHeader.Assembly]: 'assembly',
    [CustomPartQuoteHeader.Description]: 'description',
    [CustomPartQuoteHeader.RequiredQuantity]: 'requiredQuantity',
    [CustomPartQuoteHeader.Attachments]: 'attachments',
    [CustomPartQuoteHeader.UnitPrice]: 'unitPrice',
    [CustomPartQuoteHeader.OneTimeCost]: 'oneTimeCosts',
    [CustomPartQuoteHeader.Currency]: 'currency',
    [CustomPartQuoteHeader.SupplierPartNumber]: 'supplierPartNumber',
    [CustomPartQuoteHeader.LeadTimeInDays]: 'leadTimeInDays',
    [CustomPartQuoteHeader.ValidUntil]: 'validUntil',
    [CustomPartQuoteHeader.AdditionalNotes]: 'additionalNotes',
};
