import { plural, t } from '@lingui/macro';
import { intersection } from '@luminovo/commons';
import { QuantityUnitType } from '@luminovo/http-client';
import { validateQuantity } from './validators/validateQuantity';
import { validateQuantityUnit } from './validators/validateQuantityUnit';

export type EditBomItemHeaderAction =
    | { type: 'addDesignator'; designators: string[]; siblingDesignators: string[] }
    | { type: 'setDesignators'; designators: string[]; siblingDesignators: string[] }
    | { type: 'removeDesignator'; designator: string; siblingDesignators: string[] }
    | { type: 'setQuantityValue'; quantity: number | null }
    | { type: 'setQuantityUnit'; unit: QuantityUnitType | null };

export interface EditBomItemHeaderState {
    designators: string[];
    quantityValue: number | null;
    quantityUnit: QuantityUnitType | null;
    errors: {
        designators: {
            isError: boolean;
            message: string;
        };
        designatorsCount: {
            isError: boolean;
            message: string;
        };
        quantityValue: {
            isError: boolean;
            message: string;
        };
        quantityUnit: {
            isError: boolean;
            message: string;
        };
    };
}

const validateDesignators = ({
    designators,
    siblingDesignators,
}: {
    designators: string[];
    siblingDesignators: string[];
}): { isError: boolean; message: string } => {
    if (designators.length === 0) {
        return { isError: true, message: t`Required` };
    }
    const duplicatedDesignators = intersection(siblingDesignators, designators);
    const isDuplicated = duplicatedDesignators.length > 0;
    if (isDuplicated) {
        return {
            isError: isDuplicated,
            message: isDuplicated
                ? plural(duplicatedDesignators.length, {
                      one: `Designator ${duplicatedDesignators} already in use`,
                      other: `Designators ${duplicatedDesignators.join(', ')} already in use`,
                  })
                : '',
        };
    }
    return { isError: false, message: '' };
};

export const reducer = (state: EditBomItemHeaderState, action: EditBomItemHeaderAction): EditBomItemHeaderState => {
    switch (action.type) {
        case 'addDesignator':
            return {
                ...state,
                designators: [...state.designators, ...action.designators],
                errors: {
                    ...state.errors,
                    designators: validateDesignators({
                        designators: action.designators,
                        siblingDesignators: action.siblingDesignators,
                    }),
                },
            };
        case 'setDesignators':
            return {
                ...state,
                designators: [...action.designators],
                errors: {
                    ...state.errors,
                    designators: validateDesignators({
                        designators: action.designators,
                        siblingDesignators: action.siblingDesignators,
                    }),
                },
            };
        case 'removeDesignator':
            return {
                ...state,
                designators: state.designators.filter((designator) => designator !== action.designator),
                errors: {
                    ...state.errors,
                    designators: validateDesignators({
                        designators: state.designators.filter((designator) => designator !== action.designator),
                        siblingDesignators: action.siblingDesignators,
                    }),
                },
            };
        case 'setQuantityValue':
            return {
                ...state,
                quantityValue: action.quantity,
                errors: {
                    ...state.errors,
                    quantityValue: validateQuantity({ value: action.quantity }),
                },
            };
        case 'setQuantityUnit':
            return {
                ...state,
                quantityUnit: action.unit,
                errors: {
                    ...state.errors,
                    quantityUnit: validateQuantityUnit({ value: action.unit }),
                },
            };
        default:
            return state;
    }
};
