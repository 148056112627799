import { t } from '@lingui/macro';
import { compareByString, isPresent } from '@luminovo/commons';
import { CenteredLayout, Dialog, DialogContent, DialogTitle, colorSystem } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { SupplierLineCardForm, SupplierLineCardFormState } from './SupplierLineCardForm';

export function useDialogEditSupplierLineCard({ supplierId }: { supplierId: string }) {
    const { setDialog, closeDialog } = useDialogContext();

    const { data: supplierLineCard } = useHttpQuery('GET /suppliers/:id/line-card', {
        pathParams: { id: supplierId },
    });

    const defaultValues: SupplierLineCardFormState = {
        manufacturers: supplierLineCard?.manufacturers.sort((a, b) => compareByString(a.name, b.name)) ?? [],
    };

    const { mutateAsync } = useHttpMutation('PATCH /suppliers/:id/line-card', {
        snackbarMessage: t`Supplier line card updated`,
    });

    const onSubmit = async (formValues: SupplierLineCardFormState) => {
        await mutateAsync({
            pathParams: { id: supplierId },
            requestBody: { type: 'Update', value: { manufacturers: formValues.manufacturers.map((m) => m.id) } },
        });
        closeDialog();
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'md'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit supplier line card`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        {!isPresent(supplierLineCard) ? (
                            <CenteredLayout height={'30vh'}>
                                <CircularProgress />
                            </CenteredLayout>
                        ) : (
                            <SupplierLineCardForm
                                defaultValues={defaultValues}
                                onSubmit={onSubmit}
                                supplierLineCard={supplierLineCard}
                            />
                        )}
                    </DialogContent>
                </Dialog>,
            ),
    };
}
