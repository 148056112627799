/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SchukatCredentialsDTO extends r.Static<typeof SchukatCredentialsDTORuntype> {}
export const SchukatCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        username: r.String,
        password: r.String,
    }),
);

export interface SchukatCredentialsInputDTO extends r.Static<typeof SchukatCredentialsInputDTORuntype> {}
export const SchukatCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    username: r.String.optional(),
    password: r.String.optional(),
});

export interface SchukatResponseBodyDTO extends r.Static<typeof SchukatResponseBodyDTORuntype> {}
export const SchukatResponseBodyDTORuntype = r.Record({
    data: SchukatCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
