import { t } from '@lingui/macro';
import { CenteredLayout, Flexbox, MaxWidthLayout, Message, NonIdealState } from '@luminovo/design-system';
import { LinearProgress } from '@mui/material';
import { useUniversalImporter } from '../../context';
import { TablePreview } from './TablePreview';

export function StepPreview({ setStepIndex }: { setStepIndex: (index: number) => void }): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const { importerTable } = state;
    const countFailed = importerTable?.getRows().filter((r) => r.import?.success === false) ?? [];

    if (!importerTable) {
        return (
            <CenteredLayout>
                <NonIdealState title={t`Import failed`} description={t`Go back to the previous step and try again.`} />
            </CenteredLayout>
        );
    }

    const progressPercentage =
        (importerTable.getRows().filter((row) => row.import).length /
            importerTable.getRows().filter((row) => !!row.record).length) *
        100;

    const rows = importerTable.getRows();

    return (
        <MaxWidthLayout sx={{ position: 'relative', height: '100%' }}>
            <Flexbox flexDirection={'column'} height={'100%'} sx={{ gap: 4 }}>
                {progressPercentage > 0.01 && (
                    <LinearProgress
                        sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                        variant="determinate"
                        value={progressPercentage}
                    />
                )}

                {countFailed.length > 0 && (
                    <Message
                        attention="high"
                        size="large"
                        variant="yellow"
                        title={t`Import failed`}
                        message={t`Some rows could not be imported. Please check the data and try again.`}
                        action={{
                            label: t`Restart import`,
                            onClick: () => {
                                dispatch({ type: 'restartImport' });
                                setStepIndex(1);
                            },
                        }}
                    />
                )}

                <Flexbox flexDirection={'column'} gap="16px" height={'100%'}>
                    {rows.length > 0 && <TablePreview rows={rows} />}
                </Flexbox>
            </Flexbox>
        </MaxWidthLayout>
    );
}
