import { getToken } from '@luminovo/auth';
import {
    http,
    ManufacturingEntityStatusSelection,
    ManufacturingScenarioTemplateDTO,
    RfqListItemDTO,
} from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useToken } from '../../../resources/hooks';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { invalidateQuery } from '../../../resources/http/useHttpQuery';
import { useManufacturingScenarioTemplatesOfActivities } from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { useParsedRfqsOfActivity } from '../../../resources/rfq/parsedRfqsHandler';
import { filterActiveTemplates } from '../shared/manufacturingUtils';
import { DeleteDialog } from '../shared/sharedManufacturingTypes';
interface UseDeleteActivities {
    isLoading: boolean;
    isError: boolean;
    rfqsOfActivity: RfqListItemDTO[];
    templatesOfActivity: ManufacturingScenarioTemplateDTO[];
    deleteDialog: DeleteDialog;
}

export const useDeleteActivities = (activityId: string): UseDeleteActivities => {
    const [isDeletePressed, setIsDeletePressed] = React.useState<boolean>(false);
    const {
        data: rfqsData,
        isLoading: isLoadingRfqs,
        isError: isErrorRfqs,
    } = useParsedRfqsOfActivity(activityId, isDeletePressed);
    const rfqsOfActivity = rfqsData?.data ?? [];
    const {
        data,
        isLoading: isLoadingTemplate,
        isError: isErrorTemplate,
    } = useManufacturingScenarioTemplatesOfActivities([activityId], isDeletePressed);
    const { enqueueSnackbar } = useSnackbar();
    const templatesOfActivity = data?.data !== undefined ? filterActiveTemplates(data.data) : [];

    const isAnythingLoading = isLoadingRfqs || isLoadingTemplate;
    const isAnyError = isErrorRfqs || isErrorTemplate;

    const isInformationDialogDeleteOpen =
        isDeletePressed && !isLoadingRfqs && !isErrorRfqs && rfqsOfActivity.length > 0;

    const isConfirmationDialogDeleteOpen =
        isDeletePressed && !isAnythingLoading && !isAnyError && rfqsOfActivity.length === 0;

    const token = getToken();

    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationFn: () => http('DELETE /activities/:activityId', { pathParams: { activityId } }, token),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /activities', { queryParams: {} }));
            enqueueSnackbar('Activity deleted', { variant: 'info' });
        },
    });

    return {
        isLoading: isAnythingLoading,
        isError: isAnyError,
        rfqsOfActivity,
        templatesOfActivity,
        deleteDialog: {
            deleteOnClick: () => mutateAsync(),
            isInformationDialogDeleteOpen,
            isConfirmationDialogDeleteOpen,
            setIsDeletePressed,
        },
    };
};

export const useStatusChangeActivities = (activityId: string, status: string) => {
    const [isStatusChangePressed, setIsStatusChangePressed] = React.useState<boolean>(false);
    const { data, isLoading, isError } = useManufacturingScenarioTemplatesOfActivities(
        [activityId],
        isStatusChangePressed,
    );
    const templatesOfActivity = data?.data !== undefined ? filterActiveTemplates(data.data) : [];

    const isConfirmationDialogOpen = isStatusChangePressed && !isLoading && !isError && templatesOfActivity.length > 0;

    const { token } = useToken();
    const queryClient = useQueryClient();
    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; status: ManufacturingEntityStatusSelection }) => {
            return http(
                'PATCH /activities/:activityId',
                { requestBody: { status: info.status }, pathParams: { activityId } },
                token,
            );
        },
        onSuccess: async () => {
            await invalidateQuery('GET /activities', { queryParams: {} }, queryClient);

            setIsStatusChangePressed(false);
        },
    });

    const saveStatusChange = () => {
        mutateAsyncPatch({
            token,
            status: status === 'Active' ? 'Inactive' : 'Active',
        });
    };

    const statusChangeOnClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (status === 'Inactive') {
            saveStatusChange();
        } else {
            setIsStatusChangePressed(true);
        }
    };

    if (isStatusChangePressed && !isLoading && !isError && templatesOfActivity.length === 0) {
        setIsStatusChangePressed(false);
        saveStatusChange();
    }

    return {
        isLoading,
        isError,
        templatesOfActivity,
        statusChangeDialog: {
            setIsStatusChangePressed,
            statusChangeOnClick,
            saveStatusChange,
            isConfirmationDialogOpen,
        },
    };
};
