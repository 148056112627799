// this function is precise to 1 second
export function hasChangedSinceReset(
    templateReset: string | null,
    detailsUpdate: string | null,
    costsUpdate: string | null,
) {
    if (templateReset && detailsUpdate && costsUpdate) {
        const templateResetDate = new Date(templateReset).getTime();
        const costsUpdateDate = new Date(costsUpdate).getTime();
        const detailsUpdateDate = new Date(detailsUpdate).getTime();
        return costsUpdateDate - templateResetDate > 1000 || detailsUpdateDate - templateResetDate > 1000;
    }
    return false;
}
