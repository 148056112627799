import { Chip, colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Divider, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { iconForStatus } from '../../../../components/Icons/icons';
import { QueryParams } from '../../../../utils/routes';
import { BomItemTableData, ViewContext } from '../../../Bom/components/ModuleTableData';
import { BomItemFormState } from '../BomItemFormState';
import { BomItemCommentsButton } from './BomItemCommentsButton';
import { BomItemMenuButton } from './BomItemMenuButton';
import { DesignatorsAndQtyEditForm } from './DesignatorsAndQtyEditForm';
import { DnpController } from './fields/DnpController';
import { Notes } from './fields/Notes/Notes';
import { SourcedByController } from './fields/SourcedByController';
import { validateDesignator } from './validators/validateDesignator';
import { validateQuantity } from './validators/validateQuantity';

interface BomItemDetailsProps {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    assemblyId: string;
    bomItem: BomItemTableData;
    submitForm: () => void;
    isRfqEditable: boolean;
    siblingDesignators: string[];
    viewContext: ViewContext;
    isBomDetailsPinned: 'true' | 'false';
    setIsBomDetailsPinned: (value: 'true' | 'false') => void;
    customerId?: string;
}

function validateInitialState({
    values,
    siblingDesignators,
}: {
    values: BomItemFormState;
    siblingDesignators: string[];
}) {
    if (validateDesignator({ designators: values.designator, siblingDesignators }).isError) {
        return { isError: true };
    }
    if (validateQuantity({ value: values.quantity?.quantity }).isError) {
        return { isError: true };
    }
    if (!values.quantity?.unit) {
        return { isError: true };
    }
    return { isError: false };
}

export const BOM_DETAILS_BORDER_BOTTOM = 24;

export const BomItemDetails = ({
    queryParams,
    assemblyId,
    bomItem,
    submitForm,
    isRfqEditable,
    siblingDesignators,
    viewContext,
    isBomDetailsPinned,
    setIsBomDetailsPinned,
    customerId,
}: BomItemDetailsProps): JSX.Element => {
    const { watch } = useFormContext<BomItemFormState>();
    const values = watch();
    const [isHeaderEditable, setIsHeaderEditable] = useState(
        validateInitialState({ values, siblingDesignators }).isError,
    );

    const designators = values.designator.join(', ');

    const rfqId = viewContext.rfqId;

    if (isHeaderEditable && isRfqEditable) {
        return (
            <>
                <Flexbox
                    justifyContent="space-between"
                    gap="20px"
                    style={{
                        background: colorSystem.neutral.white,
                        borderRadius: '8px',
                        padding: '20px',
                        height: '85px',
                    }}
                >
                    <DesignatorsAndQtyEditForm
                        bomItem={bomItem}
                        siblingDesignators={siblingDesignators}
                        submitForm={submitForm}
                        setIsHeaderEditable={setIsHeaderEditable}
                    />
                    <BomItemMenuButton
                        queryParams={queryParams}
                        assemblyId={assemblyId}
                        bomItem={bomItem}
                        viewContext={viewContext}
                        isHeaderEditable={isHeaderEditable}
                        setIsHeaderEditable={setIsHeaderEditable}
                        isBomDetailsPinned={isBomDetailsPinned}
                        setIsBomDetailsPinned={setIsBomDetailsPinned}
                    />
                </Flexbox>
                <BorderBottomContainer />
            </>
        );
    }

    return (
        <>
            <Flexbox flexDirection="column" style={{ background: colorSystem.neutral.white, borderRadius: '8px' }}>
                <Flexbox justifyContent="space-between" alignItems="center" padding="20px 20px 12px 20px">
                    <Flexbox alignItems="center" gap="16px">
                        <Flexbox alignItems="center" gap="4px">
                            {iconForStatus({ status: bomItem.approvalStatus, size: 'large' })}
                            <Tooltip title={designators.length > 21 ? designators : ''}>
                                <Text
                                    variant="h3"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '300px',
                                    }}
                                >
                                    {designators}
                                </Text>
                            </Tooltip>
                            <Chip
                                color="neutral"
                                label={`${values.designator.length} x ${formatQuantity(values.quantity, {
                                    showPiecesUnit: true,
                                    maximumFractionDigits: 5,
                                })}`}
                            />
                        </Flexbox>
                    </Flexbox>

                    <Flexbox gap="8px" alignItems="center">
                        <DnpController submitForm={submitForm} isEditable={isRfqEditable} />
                        <SourcedByController
                            submitForm={submitForm}
                            isEditable={isRfqEditable}
                            customerId={customerId}
                        />
                        <BomItemCommentsButton rfqId={rfqId} bomItem={bomItem} />
                        <BomItemMenuButton
                            queryParams={queryParams}
                            assemblyId={assemblyId}
                            bomItem={bomItem}
                            viewContext={viewContext}
                            isHeaderEditable={isHeaderEditable}
                            setIsHeaderEditable={setIsHeaderEditable}
                            isBomDetailsPinned={isBomDetailsPinned}
                            setIsBomDetailsPinned={setIsBomDetailsPinned}
                        />
                    </Flexbox>
                </Flexbox>
                <Divider style={{ width: '100%' }} />
                <Flexbox padding="12px 20px 20px 20px">
                    <Notes isRfqEditable={isRfqEditable} submitForm={submitForm} viewContext={viewContext} />
                </Flexbox>
            </Flexbox>
            <BorderBottomContainer />
        </>
    );
};

const BorderBottomContainer = () => {
    return <Flexbox flexDirection="column" height="24px" style={{ background: colorSystem.neutral[1] }} />;
};
