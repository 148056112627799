import { logToExternalErrorHandlers } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';

export class PdfErrorBoundary extends React.Component<{ children: React.ReactNode }, { error: undefined | unknown }> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { error: undefined };
    }

    static getDerivedStateFromError(error: unknown): { error: unknown } {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error: unknown): void {
        logToExternalErrorHandlers(error instanceof Error ? error : new Error('Failed to render-pdf'));
    }

    render(): JSX.Element | React.ReactNode {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <Box
                    display={'grid'}
                    style={{
                        placeItems: 'center',
                        minHeight: 400,
                        background: colorSystem.red[2],
                        borderRadius: 8,
                        border: `1px solid ${colorSystem.red[6]}`,
                    }}
                >
                    <Flexbox flexDirection={'column'} alignItems="center">
                        <Text variant="body-semibold" color={colorSystem.red[8]}>
                            Failed to load PDF
                        </Text>
                        <Text color={colorSystem.red[8]}>Try reloading the page</Text>
                    </Flexbox>
                </Box>
            );
        }

        return this.props.children;
    }
}
