import * as React from 'react';
import { BaseField, BaseFieldProps, FieldConverter } from '../BaseField';

export interface FieldTextProps extends Omit<BaseFieldProps<string | null>, 'converter'> {}

export const FieldText = React.forwardRef<HTMLDivElement, FieldTextProps>((props, ref) => {
    return <BaseField ref={ref} converter={converter} {...props} />;
});

export const converter: FieldConverter<string | null> = {
    parseValue: (text) => {
        if (text === '') {
            return { ok: true, value: null };
        }

        return { ok: true, value: text };
    },
    formatValue: (value) => {
        if (value === null) {
            return '';
        }
        return value;
    },
};
