import { t } from '@lingui/macro';
import { colorSystem, Dialog, DialogTitle, Text } from '@luminovo/design-system';
import { ColumnMappingAttemptDTO } from '@luminovo/http-client';
import { useQueryClient } from '@tanstack/react-query';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { ConversionResult } from '../types/typeConversions';
import { PnpImporterDialogContent } from './PnpImporterDialogContent';

export function usePnpImporterDialog() {
    const { setDialog, closeDialog } = useDialogContext();
    const queryClient = useQueryClient();
    const handleClose = async () => {
        await queryClient.invalidateQueries(httpQueryKey('GET /pnp/bulk'));
        closeDialog();
    };
    return {
        openDialog: ({
            csvResult,
            fileName,
            uploadId,
            columnMappingAttempt,
        }: {
            csvResult: ConversionResult;
            fileName: string;
            uploadId: string;
            columnMappingAttempt: ColumnMappingAttemptDTO;
        }) =>
            setDialog(
                <Dialog open onClose={() => handleClose()} fullScreen={true} fullWidth maxWidth={'lg'}>
                    <DialogTitle
                        title={t`Column mapping`}
                        handleClose={() => handleClose()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                    />
                    <>
                        {csvResult.type === 'error' && (
                            <Text variant="h3" style={{ color: colorSystem.neutral[8] }}>
                                {csvResult.message}
                            </Text>
                        )}
                        {csvResult.type === 'success' && (
                            <PnpImporterDialogContent
                                lines={csvResult.result}
                                fileName={fileName}
                                uploadId={uploadId}
                                onImportSuccess={() => closeDialog()}
                                columnMappingAttempt={columnMappingAttempt}
                            />
                        )}
                    </>
                </Dialog>,
            ),
        closeDialog,
    };
}
