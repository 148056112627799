import { Trans, t } from '@lingui/macro';
import { Flexbox, PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import DialogBox from '../../../components/dialogBox/DialogBox';
import { ChangeRfQStatusFormValues } from './ChangeRfQStatusDialog';

export const useOrderShippedConfirmationDialog = (onSubmit: (data: ChangeRfQStatusFormValues) => Promise<{}>) => {
    const { setDialog, closeDialog } = useDialogContext();

    const openDialog = (shippingTrackingLink: string | undefined) => {
        const submitHandler = async () => {
            await onSubmit({ rfqStatus: RfqStatus.OrderShipped, shippingTrackingLink });
        };

        const dialog = <OrderShippedConfirmationDialog onSubmit={submitHandler} closeDialog={closeDialog} />;

        setDialog(dialog);
    };

    return { openDialog };
};

interface OrderShippedConfirmationDialogProps {
    closeDialog: () => void;
    onSubmit: () => Promise<void>;
}

const OrderShippedConfirmationDialog = ({
    closeDialog,
    onSubmit,
}: OrderShippedConfirmationDialogProps): JSX.Element => {
    return (
        <DialogBox
            title={t`Share status update`}
            isDialogOpen={true}
            onReject={closeDialog}
            maxWidth={'xs'}
            closeIconIsShown={false}
        >
            <Flexbox flexDirection={'column'} gap={32}>
                <Text>
                    <Trans>
                        Changing the status to “order shipped” will notify your customer about the status update and in
                        case you added a tracking link it will be shared with them as well.
                    </Trans>
                </Text>

                <Typography variant={'body2'}>
                    <Trans>Would you like to continue?</Trans>
                </Typography>

                <Flexbox justifyContent={'space-between'}>
                    <SecondaryButton onClick={closeDialog}>
                        <Trans>Cancel</Trans>
                    </SecondaryButton>

                    <PrimaryButton
                        onClick={async () => {
                            await onSubmit();
                            closeDialog();
                        }}
                        style={{ width: '80px' }}
                    >
                        <Trans>Send</Trans>
                    </PrimaryButton>
                </Flexbox>
            </Flexbox>
        </DialogBox>
    );
};

export default OrderShippedConfirmationDialog;
