import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { compareByDate, formatLongDateTime, formatRelativeTime, id, isPresent, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SecondaryButton,
    SecondaryIconButton,
    TagGroup,
    TanStackTable,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import { http, SnapshotMetadataItemDTO, SnapshotTagType } from '@luminovo/http-client';
import { snapshotTagTranslations } from '@luminovo/sourcing-core';
import { Add, EditNoteRounded, SwapHoriz, Timeline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { LayoutCard } from '../../../../components/LayoutCard';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../utils/routes';
import { sleep } from '../../../../utils/sleep';
import { ExportSnapshotSplitButton } from '../ExportCostedBom';

type VersionHistorySharedContext = {
    rfqId: string;
};

function CompareButton({ rfqId, disabled }: { rfqId: string; disabled: boolean }): JSX.Element {
    const currentLink = route(
        '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId',
        {
            fromRfqId: rfqId,
            fromSnapshotId: 'now',
            fromSourcingScenarioId: '-',
            toRfqId: rfqId,
            toSnapshotId: 'now',
            toSourcingScenarioId: '-',
        },
    );

    return (
        <SecondaryButton
            id={id('sourcing/button_compare')}
            href={currentLink}
            disabled={disabled}
            size="small"
            startIcon={<SwapHoriz />}
        >
            <Trans>Compare</Trans>
        </SecondaryButton>
    );
}

function CreateVersionButton({
    rfqId,
    overrides,
}: {
    rfqId: string;
    overrides?: {
        Button?: typeof PrimaryButton | typeof SecondaryButton;
    };
}): JSX.Element {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: saveSnapshot, isLoading } = useMutation({
        mutationFn: async () => {
            return Promise.all([
                http(
                    'POST /rfqs/:rfqId/snapshot/trigger',
                    {
                        pathParams: { rfqId },
                        requestBody: {
                            tags: [],
                        },
                    },
                    getToken(),
                ),
                sleep(5_000),
            ]);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /rfqs/:rfqId/snapshot'));
            enqueueSnackbar(t`Version saved`, { variant: 'success' });
        },
    });

    const ButtonComponent = overrides?.Button || SecondaryButton;

    return (
        <ButtonComponent
            id={id('sourcing/button_create_version')}
            startIcon={<Add />}
            isLoading={isLoading}
            onClick={() => saveSnapshot()}
            size="small"
        >
            <Trans>Create version</Trans>
        </ButtonComponent>
    );
}
type FormState = { notes: string };

const FormInner: React.FunctionComponent<{ onCancel: () => void }> = ({ onCancel }): JSX.Element => {
    const { control } = useFormContext<FormState>();

    return (
        <>
            <FieldTextControlled
                name={'notes'}
                control={control}
                max={1_000}
                FieldProps={{
                    placeholder: t`Add your notes here`,
                    rows: 3,
                    multiline: true,
                }}
            />

            <Flexbox gap={4} paddingTop={'12px'} justifyContent="flex-end">
                <SecondaryButton size={'small'} onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton size="small" style={{ width: 'fit-content' }} />
            </Flexbox>
        </>
    );
};

function useNoteDialog(rfqId: string, item: SnapshotMetadataItemDTO) {
    const { mutateAsync } = useHttpMutation('PATCH /rfqs/:rfqId/snapshot/:snapshotId', {
        snackbarMessage: t`Note saved`,
    });

    const defaultValues = {
        notes: item.tags.map((tag) => (tag.type === SnapshotTagType.Note ? tag.value : '')).join(''),
    };

    const onSubmit = (value: FormState) => {
        mutateAsync({
            pathParams: { rfqId: rfqId, snapshotId: item.id },
            requestBody: {
                tags: [
                    ...item.tags.filter((tag) => tag.type !== SnapshotTagType.Note),
                    { type: SnapshotTagType.Note, value: isPresent(value.notes) ? value.notes : '' },
                ],
            },
        });
        closeDialog();
    };

    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'xs'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Version notes`} handleClose={() => closeDialog()} />
                    <DialogContent>
                        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
                            <FormInner onCancel={() => closeDialog()} />
                        </FormContainer>
                    </DialogContent>
                </Dialog>,
            ),
    };
}

function EditNoteButton({ rfqId, item }: { rfqId: string; item: SnapshotMetadataItemDTO }): JSX.Element {
    const { openDialog } = useNoteDialog(rfqId, item);

    return (
        <Tooltip title={t`Edit version notes`}>
            <SecondaryIconButton size={'small'} onClick={() => openDialog()}>
                <EditNoteRounded fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
}

const EmptyPlaceholder: React.FunctionComponent<{ sharedContext: VersionHistorySharedContext }> = ({
    sharedContext,
}) => {
    const ActionButton = React.useMemo(
        () => () => <CreateVersionButton rfqId={sharedContext.rfqId} overrides={{ Button: PrimaryButton }} />,
        [sharedContext.rfqId],
    );

    return (
        <NonIdealState
            Icon={Timeline}
            title={t`Version history is empty`}
            description={t`Create your first version to start tracking changes of your sourcing scenario.`}
            overrides={{ ActionButton }}
        />
    );
};

const columnHelper = createColumnHelper<SnapshotMetadataItemDTO, VersionHistorySharedContext>();

const columns = [
    columnHelper.array(
        (row) =>
            row.tags.filter((tag) =>
                [
                    SnapshotTagType.Manual,
                    SnapshotTagType.CostedBomExported,
                    SnapshotTagType.RfqStatusChanged,
                    SnapshotTagType.RfqCreated,
                ].includes(tag.type),
            ),
        {
            id: 'Tags',
            size: 130,
            label: () => t`Tags`,
            getOptionLabel: (opt) => transEnum(opt.type, snapshotTagTranslations),
            getOptionKey: (opt) => opt.type,
            cell: (item) => {
                return (
                    <TagGroup
                        options={item.getValue()}
                        getOptionLabel={(opt) => transEnum(opt.type, snapshotTagTranslations)}
                    />
                );
            },
        },
    ),
    columnHelper.date('created_at', {
        size: 120,
        label: () => t`Creation date`,
        align: 'right',
        cell: (item) => {
            return (
                <Tooltip title={formatLongDateTime(item.getValue())}>
                    <Text variant="inherit">{formatRelativeTime(item.getValue())}</Text>
                </Tooltip>
            );
        },
    }),

    columnHelper.text((row) => row.tags.map((tag) => (tag.type === SnapshotTagType.Note ? tag.value : '')).join(''), {
        id: 'notes',
        size: 130,
        label: () => t`Notes`,

        cell: (item) => {
            if (!Boolean(item.getValue())) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        <Trans>No notes added</Trans>
                    </Text>
                );
            }
            return <>{item.getValue()}</>;
        },
    }),

    columnHelper.action({
        id: 'Actions',
        size: 120,
        align: 'right',
        cell: ({ row, sharedContext }) => (
            <span>
                <Flexbox gap={2} justifyContent={'flex-end'}>
                    <EditNoteButton rfqId={sharedContext.rfqId} item={row.original} />
                    <ExportSnapshotSplitButton rfqId={sharedContext.rfqId} snapshotId={row.original.id} size="small" />
                </Flexbox>
            </span>
        ),
    }),
];

export function VersionHistoryCard({ rfqId }: { rfqId: string }): JSX.Element {
    const history = useHistory();
    const { data } = useHttpQuery(
        'GET /rfqs/:rfqId/snapshot',
        { pathParams: { rfqId } },
        {
            select: (res) =>
                Array.from(res.items).sort(compareByDate((item) => item.created_at, { type: 'descending' })),
        },
    );

    const { table } = useTanStackTable({
        data,
        columns,
        sharedContext: {
            rfqId,
        },
        onRowClick: (row) => {
            history.push(
                route(
                    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId',
                    {
                        fromRfqId: rfqId,
                        fromSnapshotId: row.original.id,
                        fromSourcingScenarioId: '-',
                        toRfqId: rfqId,
                        toSnapshotId: 'now',
                        toSourcingScenarioId: '-',
                    },
                ),
            );
        },
    });

    return (
        <LayoutCard
            id={id('sourcing/card_version_history')}
            title={t`Version history`}
            style={{ width: '100%' }}
            actions={
                <Flexbox gap={4}>
                    <CompareButton rfqId={rfqId} disabled={(data ?? []).length === 0} />
                    <CreateVersionButton rfqId={rfqId} />
                </Flexbox>
            }
        >
            <Box height={'calc(100% - 48px)'}>
                <TanStackTable table={table} size={'small'} enableMenuBar={false} EmptyPlaceholder={EmptyPlaceholder} />
            </Box>
        </LayoutCard>
    );
}
