import { SecondaryButton } from '@luminovo/design-system';
import { Download } from '@mui/icons-material';
import { useDownloadQuoteRequestExcel } from '../../../resources/export/exportHandler';

export function ButtonDownloadQuoteRequestExcel({
    quoteRequestId,
    onDownload = () => {},
}: {
    quoteRequestId: number;
    onDownload?: () => void;
}) {
    const { mutateAsync, isLoading } = useDownloadQuoteRequestExcel(quoteRequestId);

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Download />}
            isLoading={isLoading}
            onClick={async () => {
                await mutateAsync();
                onDownload();
            }}
        >
            Download Excel
        </SecondaryButton>
    );
}
