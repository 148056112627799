import { isPresent } from '@luminovo/commons';
import { SelectionOptions } from './types';

/**
 * Extracts the ids from the given items using the given `idExtractor` or returns `[]` if the `idExtractor` is not defined.
 *
 * @param items - The rows items to extract the ids from
 * @param selectionOptions - The selection options containing the `idExtractor`
 * @returns The ids of the items or `null` if the `idExtractor` is not defined
 */
export const extractIdsFromItems = <TRowData>(
    items: TRowData[],
    selectionOptions: SelectionOptions<TRowData> | undefined,
): string[] => {
    return items
        .map((item) => (selectionOptions?.idExtractor ? selectionOptions.idExtractor(item) : null))
        .filter(isPresent);
};

export const extractDefaultSelectedIds = <TRowData>(
    items: TRowData[],
    selectionOptions: SelectionOptions<TRowData> | undefined,
): string[] | undefined => {
    if (selectionOptions?.defaultSelectedIds === undefined) {
        return undefined;
    }
    const itemIds = extractIdsFromItems(items, selectionOptions);

    // If a default selected id is not actually an id in the table we want to ignore it.
    return selectionOptions.defaultSelectedIds.filter((id) => itemIds.includes(id));
};

export const extractPersistentSelection = <TRowData>(
    items: TRowData[],
    selectionIds: string[],
    selectionOptions: SelectionOptions<TRowData> | undefined,
): string[] => {
    if (isPresent(selectionOptions?.defaultSelectedIds)) {
        return selectionIds;
    }

    if (selectionOptions?.persistentSelection === true) {
        const itemIds = extractIdsFromItems(items, selectionOptions);
        return selectionIds.filter((id) => itemIds.includes(id));
    }

    return [];
};
