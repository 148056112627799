import { Attribute } from './types';

const compareConfidence = (a: Attribute, b: Attribute) => {
    return (b.confidence ?? 1) - (a.confidence ?? 1);
};

const compareByOrigin = (a: Attribute, b: Attribute) => {
    if (a.origin === b.origin) {
        return 0;
    }
    if (a.origin === 'manual') {
        return -1;
    }
    if (b.origin === 'manual') {
        return 1;
    }
    return 0;
};

/**
 * Compares two attributes by their confidence.
 * If the confidence is not defined, it is assumed to be 1. This way we don't have to
 * add confidence for every kind of attribute.
 * Furthermore if the attribute is manually, it is assumed to be of the highest confidence.
 *
 * Array.from().sort(compareByConfidence) will sort the attributes in descending order of confidence.
 */
export const compareByConfidence = (a: Attribute, b: Attribute): number =>
    compareByOrigin(a, b) || compareConfidence(a, b);
