import { Trans, t } from '@lingui/macro';
import {
    CenteredLayout,
    Column,
    DataTable,
    Flexbox,
    Highlight,
    NonIdealState,
    Row,
    SecondaryIconButton,
    bodySmall,
    extractHighlights,
    useDataTableState,
} from '@luminovo/design-system';
import { GenericFullPart, OtsFullPart } from '@luminovo/http-client';
import { PreferredManufacturerTag } from '@luminovo/sourcing-core';
import { AddRounded, Delete } from '@mui/icons-material';
import { TableCell, Tooltip, styled } from '@mui/material';
import { useCallback } from 'react';
import { PartLifecycleView } from '../../../../components/PartLifecycleView';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { SectionOfScreen } from '../../../../resources/part/partFrontendTypes';
import { ActionsContainer } from '../components/ActionsContainer';

type OtsPartSearchTableContext = {
    handleLinkPart: (newPart: OtsFullPart | GenericFullPart) => void;
    handleUnlinkPart: (partId: string) => void;
    existingPartIds: string[];
};

interface PartTableData {
    part: OtsFullPart;
    highlights: string[];
    mpnTerms: RegExp[];
}

export const OtsPartSearchTable = ({
    parts,
    handleLinkPart,
    handleUnlinkPart,
    existingPartIds,
    openOtsPartAddDialog,
    highlights = {},
    canUserEditComponent,
    mpnTerms,
    ipnId,
}: {
    parts: OtsFullPart[];
    handleLinkPart: (sectionOfScreen: SectionOfScreen) => (newPart: OtsFullPart | GenericFullPart) => void;
    handleUnlinkPart: (partId: string) => void;
    existingPartIds: string[];
    canUserEditComponent: boolean;
    openOtsPartAddDialog: ({
        initialMpn,
        onSuccess,
    }: {
        initialMpn: string;
        onSuccess: (newPart: OtsFullPart | GenericFullPart) => void;
    }) => void;
    highlights?: Record<string, string[]>;
    mpnTerms: RegExp[];
    ipnId: string;
}) => {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const tableState = useDataTableState<PartTableData, OtsPartSearchTableContext>({
        columns: canUserEditComponent ? columnsWithActions : columnsWithoutActions,
        items: parts.map((part: OtsFullPart) => {
            return {
                part,
                highlights: extractHighlights(highlights[part.id] ?? []),
                mpnTerms,
            };
        }),
        persistenceId: `otsPartSearchTable-${ipnId}`,
        sharedContext: { handleLinkPart: handleLinkPart('mpnSearch'), handleUnlinkPart, existingPartIds },
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 5000,
            rowsPerPageOptions: [5000],
        },
    });

    const handleItemClick = useCallback(
        (data: PartTableData) => {
            return openOtsPartDrawer({ part: data.part, rfqContext: { type: 'OutsideRfQ' } });
        },
        [openOtsPartDrawer],
    );

    return (
        <DataTable
            tableState={tableState}
            size="medium"
            onItemClick={handleItemClick}
            overrides={{
                NoResultsComponent: () => {
                    return (
                        <CenteredLayout>
                            <NonIdealState
                                title={t`No match`}
                                description={t`No matching MPN was found`}
                                action={{
                                    onClick: () =>
                                        openOtsPartAddDialog({
                                            initialMpn: '',
                                            onSuccess: handleLinkPart('otsPartForm'),
                                        }),
                                    children: t`Add part manually instead`,
                                }}
                            />
                        </CenteredLayout>
                    );
                },
            }}
        />
    );
};

const columnMpn: Column<PartTableData, OtsPartSearchTableContext> = {
    id: 'columnMpn',
    label: <Trans>MPN</Trans>,
    render: ({ data }: Row<PartTableData>): JSX.Element => {
        const { part, mpnTerms } = data;
        const mpns = [part.mpn, ...part.mpn_aliases];
        const mpn = mpns.find((innerMpn) => {
            return mpnTerms.some((regex) => {
                return regex.test(innerMpn);
            });
        });

        return (
            <TableCell>
                <Highlight label={mpn ?? part.mpn} matcher={mpnTerms} overrides={{ Container: StyledContainer }} />
            </TableCell>
        );
    },
};

const columnManufacturer: Column<PartTableData, OtsPartSearchTableContext> = {
    id: 'columnManufacturer',
    label: <Trans>Manufacturer</Trans>,
    render: ({ data }: Row<PartTableData>) => {
        return (
            <TableCell>
                <Flexbox alignItems="center" whiteSpace="nowrap" gap="4px">
                    {data.part.manufacturer.name} {data.part.is_manufacturer_preferred && <PreferredManufacturerTag />}
                </Flexbox>
            </TableCell>
        );
    },
};

const columnDescription: Column<PartTableData, OtsPartSearchTableContext> = {
    id: 'columnDescription',
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<PartTableData>) => {
        return (
            <TableCell>
                <Highlight
                    label={data.part.description ?? ''}
                    matcher={data.highlights}
                    overrides={{ Container: StyledContainer }}
                />
            </TableCell>
        );
    },
};

const columnLifecycle: Column<PartTableData, OtsPartSearchTableContext> = {
    id: 'columnLifecycle',
    label: <Trans>Lifecycle</Trans>,
    render: ({ data }: Row<PartTableData>) => {
        return (
            <TableCell>
                <PartLifecycleView part={data.part} />
            </TableCell>
        );
    },
};

const columnActions: Column<PartTableData, OtsPartSearchTableContext> = {
    id: 'columnActions',
    label: '',
    render: ({ data }: Row<PartTableData>, { handleLinkPart, handleUnlinkPart, existingPartIds }): JSX.Element => {
        const { part } = data;
        if (existingPartIds.includes(part.id)) {
            return (
                <TableCell>
                    <ActionsContainer>
                        <SecondaryIconButton
                            size="small"
                            disableTouchRipple
                            onClick={(e) => {
                                e.stopPropagation();
                                handleUnlinkPart(part.id);
                            }}
                        >
                            <Tooltip title={<Trans>Unlink part from IPN</Trans>}>
                                <Delete fontSize="inherit" />
                            </Tooltip>
                        </SecondaryIconButton>
                    </ActionsContainer>
                </TableCell>
            );
        }

        return (
            <TableCell>
                <ActionsContainer>
                    <SecondaryIconButton
                        size="small"
                        disableTouchRipple
                        onClick={(e) => {
                            e.stopPropagation();
                            handleLinkPart(part);
                        }}
                    >
                        <Tooltip title={<Trans>Link part to IPN</Trans>}>
                            <AddRounded fontSize="inherit" />
                        </Tooltip>
                    </SecondaryIconButton>
                </ActionsContainer>
            </TableCell>
        );
    },
};

const columnsWithoutActions: Column<PartTableData, OtsPartSearchTableContext>[] = [
    columnMpn,
    columnManufacturer,
    columnDescription,
    columnLifecycle,
];

const columnsWithActions = [...columnsWithoutActions, columnActions];

const StyledContainer = styled('span')({
    ...bodySmall,
});
