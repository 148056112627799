import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Toolbar } from '@luminovo/design-system';
import { extractAggregatedBreadcrumbs } from '@luminovo/sourcing-core';
import { useSolutionConfigurationSourcing } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { route } from '../../../../utils/routes';
import SourcingScenarioActionMenu from '../../../Sourcing/components/SourcingScenarios/SourcingScenarioActionMenu';
import { useIsSourcingScenarioPcbOnlyType } from '../../../Sourcing/utils/useIsSourcingScenarioPcbOnlyType';
import { ButtonAddManualOffer } from './ButtonAddManualOffer';
import { ButtonGoToBom } from './ButtonGoToBom';
import { SolutionManagerCommentsButton } from './SolutionManagerCommentsButton';

export function ToolbarSolutionManager({
    rfqId,
    sourcingScenarioName,
    sourcingScenarioId,

    solutionConfigId,
}: {
    rfqId: string;
    sourcingScenarioName: string;
    sourcingScenarioId: string;
    solutionConfigId: string;
}) {
    const { data: IsPcbOnlyType } = useIsSourcingScenarioPcbOnlyType(sourcingScenarioId);
    const { data: solutionConfigurationSourcing } = useSolutionConfigurationSourcing(solutionConfigId);
    const groupedDesignators = extractAggregatedBreadcrumbs(solutionConfigurationSourcing);

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Sourcing`,
                    href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                },
                {
                    title: sourcingScenarioName,
                    href: route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`, {
                        rfqId,
                        sourcingScenarioId,
                    }),
                },
                {
                    title: Object.values(groupedDesignators ?? {})
                        .flatMap((val) => val.map((b) => b.designator))
                        .join(', '),
                },
            ]}
        >
            {isPresent(solutionConfigurationSourcing) && (
                <SolutionManagerCommentsButton
                    rfqId={rfqId}
                    solutionConfigurationSourcing={solutionConfigurationSourcing}
                />
            )}
            {IsPcbOnlyType === true ? (
                <SourcingScenarioActionMenu sourcingScenarioId={sourcingScenarioId} />
            ) : (
                <ButtonGoToBom rfqId={rfqId} solutionConfigurationId={solutionConfigId} />
            )}
            <ButtonAddManualOffer rfqId={rfqId} solutionConfigurationId={solutionConfigId} />
        </Toolbar>
    );
}
