/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { escapeRegExp, formatDecimal } from '@luminovo/commons';
import { Text } from '@luminovo/design-system';
import { InfiniteData } from '@tanstack/react-query';
import { SearchResultsPage } from './useSearchParts';

export function SearchResultCount<T extends { total_count: number }>({ searchResults }: { searchResults: T }) {
    const { total_count: hits } = searchResults;
    return <Text>{t`${formatDecimal(hits)} results`}</Text>;
}

/**
 * @return an array of regexes that can be used to highlight the mpn terms in the search results
 */
export function getMpnTerms(searchResults?: InfiniteData<SearchResultsPage>): RegExp[] {
    if (!searchResults) {
        return [];
    }
    const currentPage = searchResults.pages[searchResults.pages.length - 1];

    const mpnTerms = currentPage.mpnTerms.map((mpn) => {
        const query = mpn
            .split('*')
            .map((term) => {
                return escapeRegExp(term);
            })
            .join('.*');
        // eslint-disable-next-line spellcheck/spell-checker
        return new RegExp(query, 'i');
    });

    return mpnTerms;
}
