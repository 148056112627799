import { Trans } from '@lingui/macro';
import { CrossedOutLinkIcon, Flexbox, Text } from '@luminovo/design-system';
import { formatDriverValue } from '@luminovo/manufacturing-core';
import { StyledLinkIcon } from './CountIcons';

type MultipleValues = { type: 'Multiple values' };
type SingleValue = { type: 'Single value'; value: string | undefined; isOverwritten: boolean };

export type LinkedDriversProps = MultipleValues | SingleValue;

export const LinkedDrivers = (props: LinkedDriversProps): JSX.Element => {
    if (props.type === 'Multiple values') {
        return (
            <Text>
                <Trans>Multiple values</Trans>
            </Text>
        );
    }

    const { value, isOverwritten } = props;

    if (value === undefined) {
        return <Text>?</Text>;
    }

    return (
        <Flexbox flexDirection={'row'} gap={'4px'}>
            {isOverwritten ? <CrossedOutLinkIcon /> : <StyledLinkIcon color="primary" />}
            <Text>{formatDriverValue(value)}</Text>
        </Flexbox>
    );
};
