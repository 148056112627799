import * as r from 'runtypes';
import { LanguageEnumRuntype } from '../../utils/i18n';
import { CountryAlpha2CodeRuntype } from '../organizationSettings';

export const CustomerBillingAddressRuntype = r.Record({
    line1: r.String.nullable(),
    line2: r.String.nullable(),
    postal_code: r.String.nullable(),
    city: r.String.nullable(),
    /// Country code in ISO 3166-1 alpha-2 format.
    country_code: CountryAlpha2CodeRuntype.nullable(),
});

export const CustomerBillingAddressAllRequiredRuntype = r.Record({
    line1: r.String,
    line2: r.String,
    postal_code: r.String,
    city: r.String,
    /// Country code in ISO 3166-1 alpha-2 format.
    country_code: CountryAlpha2CodeRuntype,
});

export interface CustomerBillingAddressAllRequiredDTO
    extends r.Static<typeof CustomerBillingAddressAllRequiredRuntype> {}

export interface CustomerBillingAddressDTO extends r.Static<typeof CustomerBillingAddressRuntype> {}

// please keep in sync with the values found in rust-workspace/api-docs/src/openapi.yaml
export const VATIdentificationTypeRuntype = r.Union(
    r.Literal('ad_nrt'),
    r.Literal('ar_cuit'),
    r.Literal('au_abn'),
    r.Literal('au_arn'),
    r.Literal('eu_vat'),
    r.Literal('bo_tin'),
    r.Literal('br_cnpj'),
    r.Literal('br_cpf'),
    r.Literal('bg_uic'),
    r.Literal('ca_bn'),
    r.Literal('ca_gst_hst'),
    r.Literal('ca_pst_bc'),
    r.Literal('ca_pst_mb'),
    r.Literal('ca_pst_sk'),
    r.Literal('ca_qst'),
    r.Literal('cl_tin'),
    r.Literal('cn_tin'),
    r.Literal('co_nit'),
    r.Literal('cr_tin'),
    r.Literal('do_rcn'),
    r.Literal('ec_ruc'),
    r.Literal('eg_tin'),
    r.Literal('sv_nit'),
    r.Literal('eu_oss_vat'),
    r.Literal('ge_vat'),
    r.Literal('hk_br'),
    r.Literal('hu_tin'),
    r.Literal('is_vat'),
    r.Literal('in_gst'),
    r.Literal('id_npwp'),
    r.Literal('il_vat'),
    r.Literal('jp_cn'),
    r.Literal('jp_rn'),
    r.Literal('jp_trn'),
    r.Literal('ke_pin'),
    r.Literal('li_uid'),
    r.Literal('my_frp'),
    r.Literal('my_itn'),
    r.Literal('my_sst'),
    r.Literal('mx_rfc'),
    r.Literal('nz_gst'),
    r.Literal('no_vat'),
    r.Literal('pe_ruc'),
    r.Literal('ph_tin'),
    r.Literal('ro_tin'),
    r.Literal('ru_inn'),
    r.Literal('ru_kpp'),
    r.Literal('sa_vat'),
    r.Literal('rs_pib'),
    r.Literal('sg_gst'),
    r.Literal('sg_uen'),
    r.Literal('si_tin'),
    r.Literal('za_vat'),
    r.Literal('kr_brn'),
    r.Literal('es_cif'),
    r.Literal('ch_vat'),
    r.Literal('tw_vat'),
    r.Literal('th_vat'),
    r.Literal('tr_tin'),
    r.Literal('ua_vat'),
    r.Literal('ae_trn'),
    r.Literal('gb_vat'),
    r.Literal('us_ein'),
    r.Literal('uy_ruc'),
    r.Literal('ve_rif'),
    r.Literal('vn_tin'),
);
export type VATIdentificationTypeDTO = r.Static<typeof VATIdentificationTypeRuntype>;

export const allVATIdentificationTypes = VATIdentificationTypeRuntype.alternatives.map(
    (alternative) => alternative.value,
);

const IncompleteVatIdRuntype = r.Record({
    Incomplete: r.String,
});

const CompleteVatIdRuntype = r.Record({
    Complete: r.Record({
        id: r.String,
        id_type: VATIdentificationTypeRuntype,
    }),
});

const VatIdRuntype = IncompleteVatIdRuntype.Or(CompleteVatIdRuntype);

const BusinessCustomerDataRuntype = r.Record({
    vat_id: VatIdRuntype.nullable(),
    commercial_register_number: r.String.nullable(),
});

export type BusinessCustomerDataDTO = r.Static<typeof BusinessCustomerTypeRuntype>;

const PrivateCustomerTypeRuntype = r.Record({
    type: r.Literal('Private'),
});

const BusinessCustomerTypeRuntype = r.Record({
    type: r.Literal('Business'),
    data: BusinessCustomerDataRuntype,
});

const CustomerTypeRuntype = PrivateCustomerTypeRuntype.Or(BusinessCustomerTypeRuntype);

export type CustomerType = r.Static<typeof CustomerTypeRuntype>;

type Equals<X, Y> = X extends Y ? (Y extends X ? true : false) : false;
type Nullable<T> = { [K in keyof T]: T[K] | null };
// This following checks that the CustomerBillingAddressAllRequiredDTO is equivalent
// to CustomerBillingAddressDTO in all ways exxcept that CustomerBillingAddressDTO has everything nullable.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nullableCheck: Equals<Nullable<CustomerBillingAddressAllRequiredDTO>, CustomerBillingAddressDTO> = true;

export const CustomerDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    billing_address: CustomerBillingAddressRuntype.nullable(),
    default_contact_person: r.String.nullable(),
    customer_number: r.String.nullable(),
    customer_type: CustomerTypeRuntype,
    language: LanguageEnumRuntype,
});
export interface CustomerDTO extends r.Static<typeof CustomerDTORuntype> {}

const PostBusinessCustomerTypeRuntype = r.Record({
    type: r.Literal('Business'),
    data: r.Record({
        vat_id: r
            .Record({
                id: r.String,
                id_type: VATIdentificationTypeRuntype,
            })
            .nullable(),
        commercial_register_number: r.String.nullable(),
    }),
});

const PostCustomerTypeRuntype = PrivateCustomerTypeRuntype.Or(PostBusinessCustomerTypeRuntype);
export type PostCustomerTypeDTO = r.Static<typeof PostCustomerTypeRuntype>;

export const CustomerDTOPostRuntype = r.Record({
    name: r.String,
    default_contact_person: r.String,
    customer_number: r.String.nullable(),
    language: LanguageEnumRuntype,
    customer_type: PostCustomerTypeRuntype,
    billing_address: CustomerBillingAddressRuntype.nullable(),
});
export interface CustomerDTOPost extends r.Static<typeof CustomerDTOPostRuntype> {}
export type CustomerPatchDTO = CustomerDTOPost;
