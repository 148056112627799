import { PCBSilkscreenColors, PCBSoldermaskColors } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parseSilkscreenKeyword } from './parseSilkscreen';
import { parseSoldermaskKeyword } from './parseSoldermask';

// PCBSilkscreenColors are a subset of the PCBSoldermaskColors
const colorKeywords: Record<Exclude<PCBSoldermaskColors, 'green-matt' | 'green-glossy'>, string[]> = {
    [PCBSoldermaskColors.BLACK]: ['black', 'schwarz', 'noire?', 'negr(:?o|a|e)'],
    [PCBSoldermaskColors.BLUE]: ['blue', 'blau', 'bleue?', 'azul'],
    [PCBSoldermaskColors.GREEN]: ['green', 'grün', 'verte?', 'verde'],
    [PCBSoldermaskColors.PURPLE]: ['purple', 'lila', 'violet', 'morado'],
    [PCBSoldermaskColors.RED]: ['red', 'rot', 'rouge', 'rojo'],
    [PCBSoldermaskColors.WHITE]: ['white', 'weiß', 'blanc', 'blanco'],
    [PCBSoldermaskColors.YELLOW]: ['yellow', 'gelb', 'jaune', 'amarillo'],
};

const mattOrGlossy: Record<string, string[]> = {
    matt: ['mat?te?'],
    glossy: ['glossy', 'glänzend', 'brillant', 'brillante'],
};
const regexMatt = turnKeywordsIntoRegexRespectingWordBoundaries(mattOrGlossy.matt);
const regexGlossy = turnKeywordsIntoRegexRespectingWordBoundaries(mattOrGlossy.glossy);

export function parseColor(str: string): PcbAttribute | undefined {
    for (const [color, keywords] of Object.entries(colorKeywords)) {
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            if (color === 'green') {
                if (regexMatt.test(str)) {
                    return {
                        attr: 'color',
                        value: PCBSoldermaskColors.GREEN_MATT,
                    };
                }
                if (regexGlossy.test(str)) {
                    return {
                        attr: 'color',
                        value: PCBSoldermaskColors.GREEN_GLOSSY,
                    };
                }
            }
            return {
                attr: 'color',
                value: color as string,
                // we trust green less as it will also match when the more specific green-matt or glossy matches
                confidence: color === PCBSoldermaskColors.GREEN ? 0.25 : 1,
            };
        }
        if (regexGlossy.test(str)) {
            return {
                attr: 'color',
                value: PCBSoldermaskColors.GREEN_GLOSSY,
                confidence: 0.5,
            };
        }
        if (regexMatt.test(str)) {
            return {
                attr: 'color',
                value: PCBSoldermaskColors.GREEN_MATT,
                confidence: 0.5,
            };
        }
    }
    return undefined;
}

export function parseSoldermaskColor(str: string): PcbAttribute | undefined {
    const soldermask = parseSoldermaskKeyword(str);
    const color = parseColor(str);
    if (color && soldermask) {
        return {
            attr: 'soldermaskColor',
            value: color.value as PCBSoldermaskColors,
            confidence: 1,
        };
    }
}

export const isAllowedForSilkscreenColor = (value: PCBSilkscreenColors): boolean => {
    return Object.values(PCBSilkscreenColors).includes(value);
};

export function parseSilkscreenColor(str: string): PcbAttribute | undefined {
    const silkscreen = parseSilkscreenKeyword(str);
    const color = parseColor(str);
    // only add silkscreen color if it's in the list of supported silkscreen colors
    if (color && silkscreen && isAllowedForSilkscreenColor(color?.value as PCBSilkscreenColors)) {
        return {
            attr: 'silkscreenColor',
            value: color?.value as PCBSilkscreenColors,
            confidence: 1,
        };
    }
}
