import { t } from '@lingui/macro';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';

export const DeleteManufacturingScenarioConfirmationDialog = ({
    isDialogOpen,
    deleteOnClick,
    closeDialog,
}: {
    isDialogOpen: boolean;
    deleteOnClick: () => void;
    closeDialog: () => void;
}): JSX.Element => {
    return (
        <ConfirmationDialogBox
            text={t`Are you sure you want to delete this manufacturing scenario?`}
            title={t`Delete manufacturing scenario`}
            onConfirm={() => deleteOnClick()}
            onReject={closeDialog}
            isDialogOpen={isDialogOpen}
            deleteText={t`Yes, delete`}
        />
    );
};
