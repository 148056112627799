import { Currency } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { styled } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { displayCurrencySymbol } from '../../../../utils/currencyUtils';
import { DynamicCostCell } from '../types/cellTypes';
import { CalculationTableForm } from '../types/formTypes';

const StyledToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: colorSystem.neutral.white,
        color: colorSystem.neutral[7],
        boxShadow: `0px 2px 4px ${colorSystem.neutral[2]}`,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },
    minWidth: '34px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    height: 34,
    backgroundColor: colorSystem.neutral[1],
    padding: 4,
    gap: 4,
});

const getRowCostType = (cells: DynamicCostCell[]) => {
    const allCostTypes = cells.map((c) => c.costSpecification.type);
    const allUniqueCostTypes = [...new Set(allCostTypes)];
    if (allUniqueCostTypes.length === 1) {
        return allUniqueCostTypes[0];
    }
    return 'mixed';
};

export const ToggleRow = ({ cells, rowIndex }: { cells: DynamicCostCell[]; rowIndex: number }): JSX.Element => {
    const { setValue, watch } = useFormContext<CalculationTableForm>();

    const spec = getRowCostType(cells);
    if (spec === 'formula-fixed' || spec === 'formula-fraction') {
        throw new Error('Toggling not permitted with formula cells');
    }
    //this needs to be null and not undefined, because if nothing is selected on the toggle it is null.
    const [costType, setCostType] = React.useState<'fraction' | 'fixed' | null>(spec === 'mixed' ? null : spec);

    const handleCostType = (event: React.MouseEvent<HTMLElement>, newCostType: 'fraction' | 'fixed' | null) => {
        if (newCostType === null) {
            //this is to prevent the user from unselecting the toggle
            return;
        }
        if (newCostType === 'fixed') {
            const allCells = cells;
            const newRows: DynamicCostCell[] = allCells.map((cell, index) => {
                const newCell: DynamicCostCell = {
                    ...cell,
                    costSpecification: {
                        type: 'fixed',
                        value: {
                            amount: watch(`rows.${rowIndex}.cells.${index}.costFraction`),
                            currency: watch(`rows.${rowIndex}.cells.${index}.unitCostValue.currency`),
                        },
                    },
                    //Without this, an incorrect 'equivalent fraction' would display beside the fixed cost
                    //until the new BE response has been parsed.
                    costFraction: '',
                };
                return newCell;
            });
            setValue(`rows.${rowIndex}.cells`, newRows, {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
        if (newCostType === 'fraction') {
            const allCells = cells;
            const newRows: DynamicCostCell[] = allCells.map((cell, index) => {
                const newCell: DynamicCostCell = {
                    ...cell,
                    costSpecification: {
                        type: 'fraction',
                        value: watch(`rows.${rowIndex}.cells.${index}.unitCostValue.amount`),
                    },
                    unitCostValue: {
                        ...cell.unitCostValue,
                        amount: '',
                    },
                };
                return newCell;
            });
            setValue(`rows.${rowIndex}.cells`, newRows, {
                shouldDirty: true,
                shouldTouch: true,
            });
        }

        setCostType(newCostType);
    };
    const currency: Currency | undefined = watch(`rows.${rowIndex}.cells.${0}.unitCostValue.currency`);
    return (
        <Flexbox gap={12} alignItems={'center'}>
            <StyledToggleButtonGroup value={costType} exclusive onChange={handleCostType}>
                <StyledToggleButton value="fixed">
                    <Text variant="body" color="inherit">
                        {currency && displayCurrencySymbol(currency)}
                    </Text>
                </StyledToggleButton>
                <StyledToggleButton value="fraction">
                    <Text variant="body" color="inherit">
                        %
                    </Text>
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </Flexbox>
    );
};
