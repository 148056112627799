import React from 'react';
import { useBOMItems } from '../../hooks/useBOMItems';
import { getBomNestedTableItems } from './moduleTableDataUtils';

export function useBOMTableData({ assemblyId, rfqId }: { assemblyId: string; rfqId?: string }) {
    const {
        isFetched,
        isLoading,
        descendantsData: descendants,
        bomItems,
    } = useBOMItems({ parentId: assemblyId, rfqId });
    const moduleData = React.useMemo(
        () =>
            getBomNestedTableItems({
                assemblyId: assemblyId,
                bomItems: { bomItems, descendants },
                rfqId,
            }),
        [assemblyId, bomItems, descendants, rfqId],
    );

    const containsNestedAssembliesOrDesignItems = React.useMemo(() => {
        const numberOfDescendantAssembliesOrDesignItems =
            (descendants?.design_items ? descendants?.design_items.length : 0) +
            (descendants?.assemblies ? descendants?.assemblies.length : 0);
        return numberOfDescendantAssembliesOrDesignItems > 0;
    }, [descendants]);

    const showPartAlternatives = React.useMemo(() => {
        return descendants?.can_see_part_alternatives ?? false;
    }, [descendants]);

    return {
        bomItems,
        isFetched,
        isLoading,
        moduleData,
        descendants,
        containsNestedAssembliesOrDesignItems,
        showPartAlternatives,
    };
}
