import { Trans } from '@lingui/macro';
import { ActivityConfigurationSummaryDTO } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '../../../../../components/Spinners';
import { colorSystem } from '../../../../../themes';
import { DriverDetailsRow } from '../../../shared/DriverDetailsRow';
import { useDriversOfActivityConfiguration } from '../../../utils/useDriversOfActivity';
const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    marginBlockEnd: '8px',
});

export const ActivityConfigurationDriversDetails = ({
    activity,
}: {
    activity: ActivityConfigurationSummaryDTO;
}): JSX.Element => {
    const { uniqueDrivers, isLoading } = useDriversOfActivityConfiguration(activity);
    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <>
            {uniqueDrivers.length > 0 && (
                <Box padding={'24px'}>
                    <StyledTypography variant="h3">
                        <Trans>Used drivers</Trans>
                    </StyledTypography>
                    {uniqueDrivers.map((driver, i) => {
                        return (
                            <DriverDetailsRow
                                driverDetails={driver.driver}
                                driverStatus={driver.driverStatus}
                                countInformation={driver.countInformation}
                                activityConfigurationId={
                                    activity.activity_configuration_details.activity_configuration_id
                                }
                                key={i}
                            />
                        );
                    })}
                </Box>
            )}
        </>
    );
};
