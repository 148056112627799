import { colorSystem, FontVariant, Text } from '@luminovo/design-system';

export const GenericLabel = ({
    name,
    additionalInfo,
    variant,
}: {
    name: string;
    additionalInfo: string | undefined | null;
    variant?: FontVariant;
}): JSX.Element => {
    return (
        <Text
            variant={variant}
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            {name}
            {additionalInfo && <span style={{ color: colorSystem.neutral[6] }}> {additionalInfo}</span>}
        </Text>
    );
};
