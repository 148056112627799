import { Argument, ParameterOption } from '../types';

export function formatArgument<T, TAutocompleteState>(
    parameterOptions: ParameterOption<T, TAutocompleteState, Argument> | undefined,
    value: Argument,
    state: TAutocompleteState | undefined,
): JSX.Element | string {
    const { formatter, unit } = parameterOptions || {};

    const defaultFormatter = (value: Argument) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return (
            <>
                {String(value)} {unit ?? ''}
            </>
        );
    };

    const formattedValue = formatter ? formatter(value, state) : defaultFormatter(value);

    return <>{formattedValue}</>;
}
