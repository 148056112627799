import * as r from 'runtypes';

// Domain model types

export type TargetDateDTO = r.Static<typeof TargetDateRuntype>;
const TargetDateRuntype = r.Union(
    r.Record({
        type: r.Literal('Date'),
        value: r.String,
    }),
    r.Record({
        type: r.Literal('AsSoonAsPossible'),
    }),
);

export type DeliveryBatchSizeDTO = r.Static<typeof DeliveryBatchSizeRuntype>;
export const DeliveryBatchSizeRuntype = r.Record({
    quantity: r.Number,
});

export type DeliveryDateDTO = r.Static<typeof DeliveryDateRuntype>;
const DeliveryDateRuntype = r.Record({
    quantity: r.Number,
    target_date: TargetDateRuntype,
    delivery_batch_size: DeliveryBatchSizeRuntype,
});

export type PrototypeDemandDTO = r.Static<typeof PrototypeDemandRuntype>;
const PrototypeDemandRuntype = r.Record({
    assembly_id: r.String,
    delivery_date: DeliveryDateRuntype,
});

export type PrototypeDemandScenarioDTO = r.Static<typeof PrototypeDemandScenarioRuntype>;
export const PrototypeDemandScenarioRuntype = r.Record({
    id: r.String,
    type: r.Literal('Prototype'),
    start_year: r.Number,
    end_year: r.Number,
    rfq_id: r.String,
    demands: r.Array(PrototypeDemandRuntype),
});

export type AnnualDemandDTO = r.Static<typeof AnnualDemandRuntype>;
export const AnnualDemandRuntype = r.Record({
    year: r.Number,
    quantity: r.Number,
    delivery_batch_sizes: r.Array(DeliveryBatchSizeRuntype),
});

export type SeriesDemandDTO = r.Static<typeof SeriesDemandRuntype>;
const SeriesDemandRuntype = r.Record({
    assembly_id: r.String,
    annual_demands: r.Array(AnnualDemandRuntype),
});

export type SeriesDemandScenarioDTO = r.Static<typeof SeriesDemandScenarioRuntype>;
export const SeriesDemandScenarioRuntype = r.Record({
    id: r.String,
    type: r.Literal('Series'),
    start_year: r.Number,
    end_year: r.Number,
    rfq_id: r.String,
    demands: r.Array(SeriesDemandRuntype),
});

export type DemandScenarioDTO = r.Static<typeof DemandScenarioRuntype>;
export const DemandScenarioRuntype = r.Union(PrototypeDemandScenarioRuntype, SeriesDemandScenarioRuntype);

// Types for manual creation of demand scenarios

export type PrototypeDemandCreationDTO = r.Static<typeof PrototypeDemandCreationRuntype>;
export const PrototypeDemandCreationRuntype = r.Record({
    assembly_id: r.String,
    quantity: r.Number,
});

export type PrototypeDemandScenarioCreationDTO = r.Static<typeof PrototypeDemandScenarioCreationRuntype>;
export const PrototypeDemandScenarioCreationRuntype = r.Record({
    type: r.Literal('Prototype'),
    rfq_id: r.String,
    demands: r.Array(PrototypeDemandCreationRuntype),
    target_date: TargetDateRuntype,
});

export type SeriesDemandScenarioCreationDTO = r.Static<typeof SeriesDemandScenarioCreationRuntype>;
export const SeriesDemandScenarioCreationRuntype = r.Record({
    type: r.Literal('Series'),
    rfq_id: r.String,
    start_year: r.Number,
    end_year: r.Number,
    demands: r.Array(SeriesDemandRuntype),
});

export type DemandScenarioCreationDTO = r.Static<typeof DemandScenarioCreationRuntype>;
export const DemandScenarioCreationRuntype = r.Union(
    PrototypeDemandScenarioCreationRuntype,
    SeriesDemandScenarioCreationRuntype,
);

// Types for updating demand scenarios

export type PrototypeDemandScenarioUpdateDTO = r.Static<typeof PrototypeDemandScenarioUpdateRuntype>;
export const PrototypeDemandScenarioUpdateRuntype = r.Record({
    type: r.Literal('Prototype'),
    demands: r.Array(PrototypeDemandCreationRuntype),
    target_date: TargetDateRuntype,
});

export type SeriesDemandScenarioUpdateDTO = r.Static<typeof SeriesDemandScenarioUpdateRuntype>;
export const SeriesDemandScenarioUpdateRuntype = r.Record({
    type: r.Literal('Series'),
    start_year: r.Number,
    end_year: r.Number,
    demands: r.Array(SeriesDemandRuntype),
});

export type DemandScenarioUpdateDTO = r.Static<typeof DemandScenarioUpdateRuntype>;
export const DemandScenarioUpdateRuntype = r.Union(
    PrototypeDemandScenarioUpdateRuntype,
    SeriesDemandScenarioUpdateRuntype,
);

// Types for creating demand scenarios as part of RfQ creation

export type RfqCreationPrototypeDemandDTO = r.Static<typeof RfqCreationPrototypeDemandRuntype>;
export const RfqCreationPrototypeDemandRuntype = r.Record({
    assembly_designator: r.String,
    quantity: r.Number,
});

export type RfqCreationPrototypeDemandScenarioDTO = r.Static<typeof RfqCreationPrototypeDemandScenarioRuntype>;
export const RfqCreationPrototypeDemandScenarioRuntype = r.Record({
    type: r.Literal('Prototype'),
    demands: r.Array(RfqCreationPrototypeDemandRuntype),
    target_date: TargetDateRuntype,
});

export type RfqCreationSeriesDemandDTO = r.Static<typeof RfqCreationSeriesDemandRuntype>;
export const RfqCreationSeriesDemandRuntype = r.Record({
    assembly_designator: r.String,
    annual_demands: r.Array(AnnualDemandRuntype),
});

export type RfqCreationSeriesDemandScenarioDTO = r.Static<typeof RfqCreationSeriesDemandScenarioRuntype>;
export const RfqCreationSeriesDemandScenarioRuntype = r.Record({
    type: r.Literal('Series'),
    start_year: r.Number,
    end_year: r.Number,
    demands: r.Array(RfqCreationSeriesDemandRuntype),
});

export type RfqCreationDemandScenarioDTO = r.Static<typeof RfqCreationDemandScenarioRuntype>;
export const RfqCreationDemandScenarioRuntype = r.Union(
    RfqCreationPrototypeDemandScenarioRuntype,
    RfqCreationSeriesDemandScenarioRuntype,
);
