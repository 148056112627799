/* eslint-disable camelcase */
import { Currency } from '@luminovo/commons';
import {
    AssemblyResponseDTO,
    LimitType,
    OrganizationSolutionPreferenceDTO,
    SourcingPreferenceOptions,
    SourcingScenarioDTO,
    SourcingScenarioPostDTO,
    SupplierAndStockLocationDTO,
    SupplierTag,
} from '@luminovo/http-client';
import { hasSupplierTag } from '@luminovo/sourcing-core';

export type SourcingScenarioFormValues = SourcingScenarioPostDTO;

const filterSupplierIdsBy = ({
    supplierAndStockLocations,
    supplierIds,
    filterFn,
}: {
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    supplierIds: string[] | null;
    filterFn: (s: SupplierAndStockLocationDTO) => boolean;
}) => {
    if (supplierIds === null) return [];
    const filteredSupplierIds = supplierAndStockLocations.filter(filterFn).map((s) => s.id);
    return supplierIds.filter((sId) => filteredSupplierIds.includes(sId));
};

export function createSourcingScenarioFormDefaultValue({
    rfqId,
    topLevelAssemblies,
    organizationSolutionPreferences,
    supplierAndStockLocations,
}: {
    rfqId: string;
    topLevelAssemblies: AssemblyResponseDTO[];
    organizationSolutionPreferences: OrganizationSolutionPreferenceDTO;
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
}): SourcingScenarioFormValues {
    const areAllAssembliesPcbOnly = topLevelAssemblies.every((assembly) => assembly.type.type === 'PcbOnly');
    const filterFn = areAllAssembliesPcbOnly
        ? (supplierLocation: SupplierAndStockLocationDTO) => hasSupplierTag(supplierLocation, SupplierTag.PcbSupplier)
        : () => true;

    return {
        rfq: rfqId,
        name: '',
        solution_preference: {
            lead_time_preference: { type: SourcingPreferenceOptions.Fastest },
            use_unit_price: organizationSolutionPreferences.use_unit_price,
            preferred_suppliers_and_stock_locations: filterSupplierIdsBy({
                supplierIds: organizationSolutionPreferences.preferred_suppliers_and_stock_locations,
                supplierAndStockLocations,
                filterFn,
            }),
            approved_suppliers_and_stock_locations: filterSupplierIdsBy({
                supplierIds: organizationSolutionPreferences.approved_suppliers_and_stock_locations,
                supplierAndStockLocations,
                filterFn,
            }),
            preferred_inventory_sites: organizationSolutionPreferences.preferred_inventory_sites,
            approved_inventory_sites: organizationSolutionPreferences.approved_inventory_sites,
            excess_preference: {
                max_ratio_percent: 0,
                minimum_savings: {
                    amount: '0',
                    currency: Currency.EUR,
                },
            },
            offer_age_limit: {
                expiring_before_validity_date: { type: LimitType.Never },
                expired_after_validity_date: { type: LimitType.Never },
                old_after_update: { type: LimitType.Never },
                outdated_after_update: { type: LimitType.Never },
            },
            low_stock_warning_factor: 2.0,
            inventory_preference: organizationSolutionPreferences.inventory_preference,
            packaging_preference: organizationSolutionPreferences.packaging_preference,
            prefer_direct_api_integrations: organizationSolutionPreferences.prefer_direct_api_integrations,
            exclude_mismatched_price_points: organizationSolutionPreferences.exclude_mismatched_price_points,
            prioritize_preferred_manufacturers: organizationSolutionPreferences.prioritize_preferred_manufacturers,
            excluded_price_types: organizationSolutionPreferences.excluded_price_types,
            prefer_customer_negotiated_prices: organizationSolutionPreferences.prefer_customer_negotiated_prices,
        },
        assembly_quantities: topLevelAssemblies.map((assembly) => ({
            assembly: assembly.id,
            quantity: 0,
        })),
    };
}

export function convertToSourcingScenarioFormValue(
    input: SourcingScenarioDTO,
    topLevelAssemblies: AssemblyResponseDTO[],
) {
    const newAssemblyQuantities = topLevelAssemblies.map((assembly) => ({
        assembly: assembly.id,
        quantity: input.assembly_quantities.items.find((aq) => aq.assembly === assembly.id)?.quantity ?? 0,
    }));

    return {
        ...input,
        assembly_quantities: newAssemblyQuantities,
    };
}
