import { assertUnreachable } from '@luminovo/commons';
import { DriverDTO, DriverDetailsType } from '@luminovo/http-client';

/**
 * There are two entities which could have a driver type: a DriverDTO (with `System` and `User `)
 * and a DriverDetailsDTO (`Automatic` and `Manual`).
 * https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3458764561487414374&cot=14
 * Often, in the FE we want both of them to be represented as a single type.
 */
export type CombinedDriverType = 'User+Manual' | 'User+Automatic' | 'System+Automatic';

export const convertUserDriverTypeToUserAndSystemDriverType = (type: DriverDetailsType): CombinedDriverType => {
    switch (type) {
        case DriverDetailsType.Automatic:
            return 'User+Automatic';
        case DriverDetailsType.Manual:
            return 'User+Manual';
        default:
            assertUnreachable(type);
    }
};

export const selectDriverType = (driver: DriverDTO): CombinedDriverType => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return convertUserDriverTypeToUserAndSystemDriverType(driver.data.details.type);
        case 'System':
            return 'System+Automatic';
        default:
            assertUnreachable(type);
    }
};
