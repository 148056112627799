/* eslint-disable camelcase */
import { isPresent } from '@luminovo/commons';
import { PackageDTO } from '@luminovo/http-client';

export function formatPackage(
    pack: Pick<PackageDTO, 'name' | 'mounting' | 'number_of_pins'> | null | undefined,
): string {
    if (!pack) {
        return '';
    }
    return [pack.name, pack.mounting, pack.number_of_pins ? pack.number_of_pins + ' pins' : null]
        .filter(isPresent)
        .join(' ');
}
