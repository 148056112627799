import { Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { ExpenseLevel } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { expenseLevelEnumPublicTranslations } from '../../../../../resources/expenses/expensesBackendTypes';
import { ManufacturingLevelTag } from '../../../shared/ManufacturingSharedComponents/ManufacturingLevelTag';

type RowWithLevel = {
    level: ExpenseLevel;
};

const getExpenseLevelFilters = <T extends RowWithLevel, C>(): Filter<T, C>[] => {
    return Object.keys(expenseLevelEnumPublicTranslations).map((key) => ({
        id: `expense level=${key}`,
        label: transEnum(key, expenseLevelEnumPublicTranslations),
        predicate: (expense): boolean => expense.level === key,
    }));
};

export function getLevelColumn<T extends RowWithLevel, C>(): Column<T, C> {
    return {
        label: <Trans>Level</Trans>,
        id: `level`,
        render: ({ data: rowData }: Row<T>) => (
            <TableCell>
                <ManufacturingLevelTag level={rowData.level} />
            </TableCell>
        ),
        width: columnWidth.medium,
        filters: getExpenseLevelFilters(),
    };
}
