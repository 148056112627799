export function OrderConfirmedIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
            <path
                stroke="#fff"
                strokeOpacity="0.6"
                strokeWidth="8"
                d="M116 60c0-30.928-25.072-56-56-56S4 29.072 4 60s25.072 56 56 56 56-25.072 56-56z"
            />
            <path
                fill="#fff"
                fillOpacity="0.6"
                d="M88.9 43.129a5 5 0 00-7.8-6.258L52.677 72.306 38.7 56.93a5 5 0 10-7.4 6.727l17.915 19.705a5 5 0 007.6-.234l32.085-40z"
            />
        </svg>
    );
}
