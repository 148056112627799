import { ManufacturingDatabaseTableCell } from '@luminovo/manufacturing-core';

interface LevelCellProps {
    unit: React.ReactNode | undefined;
    batch: React.ReactNode | undefined;
    project: React.ReactNode | undefined;
}

export const LevelCell = ({ unit, batch, project }: LevelCellProps): JSX.Element => {
    return (
        <ManufacturingDatabaseTableCell>
            {unit}
            {batch}
            {project}
        </ManufacturingDatabaseTableCell>
    );
};
