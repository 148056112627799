import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { useHistory } from 'react-router-dom';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useTopLevelAssemblies } from '../../../resources/assembly/assemblyHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useSourcingScenario } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../../utils/routes';
import { SourcingScenarioForm } from './SourcingScenarioForm';
import { SourcingScenarioFormValues, convertToSourcingScenarioFormValue } from './converters';

export function EditSourcingScenarioForm({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit'>): JSX.Element {
    const { rfqId, sourcingScenarioId } = pathParams;
    const history = useHistory();
    const { data: topLevelAssemblies } = useTopLevelAssemblies(rfqId);
    const { data: sourcingScenario } = useSourcingScenario(sourcingScenarioId);

    const { mutateAsync } = useHttpMutation('PATCH /sourcing-scenarios/:sourcingScenarioId', {
        snackbarMessage: t`Sourcing scenario updated`,
        onSuccess: () => history.goBack(),
    });

    const submitHanlder = async (requestBody: SourcingScenarioFormValues) => {
        await mutateAsync({ pathParams: { sourcingScenarioId }, requestBody });
    };

    if (!isPresent(sourcingScenario) || !isPresent(topLevelAssemblies)) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    const defaultValues = convertToSourcingScenarioFormValue(sourcingScenario, topLevelAssemblies);

    return <SourcingScenarioForm onSubmit={submitHanlder} defaultValues={defaultValues} />;
}
