import { Tabs as MuiTabs, TabsProps as MuiTabsProps, styled } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { colorSystem, h4, h5 } from '../../theme';

export type TabsProps<T> = Omit<MuiTabsProps, 'onChange' | 'value'> & {
    size: 'large' | 'small';
    value: T;
    // Omitting onChange from MuiTabsProps to fix a bug in the MUI 4.11 types
    // https://github.com/mui/material-ui/issues/17454
    onChange?: (event: ChangeEvent<{}>, value: T) => void;
};

export const Tabs = React.forwardRef(function Tabs<T>(
    { size, ...props }: TabsProps<T>,
    ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element {
    switch (size) {
        case 'small':
            return (
                <SmallTabs ref={ref} {...props}>
                    {props.children}
                </SmallTabs>
            );
        case 'large':
            return (
                <LargeTabs ref={ref} {...props}>
                    {props.children}
                </LargeTabs>
            );
    }
});

const DefaultTabs = styled(MuiTabs)({
    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    '& button': {
        padding: '0px 0px 10px 0px',
    },
    '& button:hover': {
        paddingBottom: '8px',
        borderBottom: `2px solid ${colorSystem.neutral[6]}`,
    },
    '& .MuiTabs-indicator': {
        background: colorSystem.neutral[9],
    },
});

const SmallTabs = styled(DefaultTabs)({
    '& *': {
        ...h5,
    },
    '& * > svg': {
        width: '16px',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px',
    },
    '& .MuiTabs-flexContainer': {
        gap: '24px',
    },
});

const LargeTabs = styled(DefaultTabs)({
    '& *': {
        ...h4,
    },
    '& * > svg': {
        width: '20px',
        height: '20px',
        fontSize: '16px',
        lineHeight: '20px',
    },
    '& .MuiTabs-flexContainer': {
        gap: '32px',
    },
});
