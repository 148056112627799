import { PdfViewerState } from './PdfViewerState';
import { Region } from './RegionNetwork/types';

export function findSelectedRegions(state: PdfViewerState): Region[] {
    const { mode, regs } = state;
    if (mode.type === 'selected') {
        return regs.findRegions({ ids: mode.selectedRegionIds });
    }
    if (mode.type === 'inspect') {
        return regs.findRegions({ ids: mode.selectedRegionIds });
    }
    return [];
}
