import { SVGProps } from 'react';
import { Point } from '../model/Point';
import { Viewbox } from '../model/Viewbox';
import { BoundingBox } from '../model/boundingBox';

export function Arrow({
    from,
    to,
    viewbox,
    ...rest
}: { from: BoundingBox; to: BoundingBox; viewbox: Viewbox } & Pick<
    SVGProps<SVGLineElement>,
    'stroke' | 'strokeDasharray' | 'fill' | 'fillOpacity' | 'strokeOpacity' | 'strokeWidth' | 'onClick'
>): JSX.Element {
    const { from: fromPoint, to: toPoint } = findPoints(from.applyViewbox(viewbox), to.applyViewbox(viewbox));

    return (
        <>
            <line
                x1={fromPoint.x}
                y1={fromPoint.y}
                x2={toPoint.x}
                y2={toPoint.y}
                stroke="black"
                strokeWidth={2}
                {...rest}
            />
            <circle cx={toPoint.x} cy={toPoint.y} r={4} fill={rest.stroke} />
        </>
    );
}

function findPoints(from: BoundingBox, to: BoundingBox): { from: Point; to: Point } {
    switch (from.directionTo(to)) {
        case 'up':
            return { from: from.topCenter(), to: to.bottomCenter() };
        case 'down':
            return { from: from.bottomCenter(), to: to.topCenter() };
        case 'left':
            return { from: from.leftCenter(), to: to.rightCenter() };
        case 'right':
            return { from: from.rightCenter(), to: to.leftCenter() };
    }
}
