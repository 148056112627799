import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { BaseButton } from './BaseButton';

export const DestructiveTertiaryButton = styled(BaseButton)({
    color: colorSystem.neutral[7],
    '&:hover': {
        background: colorSystem.red[1],
        color: colorSystem.red[7],
    },
    '&:active': {
        background: colorSystem.red[2],
        color: colorSystem.red[8],
    },
    '&:disabled': {
        color: colorSystem.neutral[5],
    },
});
