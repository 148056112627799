import { AzureExtractionResult } from '@luminovo/http-client';

export enum NumberSeparator {
    Comma = ',',
    Dot = '.',
}

function getPossiblePriceStrings(azureExtractionResult: AzureExtractionResult): string[] {
    const possiblePriceStrings: string[] = [];
    const invoiceDocuments = azureExtractionResult.analyzeResult.documents.filter((doc) => doc.docType === 'invoice');
    for (const invoice of invoiceDocuments) {
        for (const item of invoice.fields.Items?.valueArray ?? []) {
            if (item.valueObject?.UnitPrice) {
                const unitPrice = item.valueObject.UnitPrice;
                possiblePriceStrings.push(unitPrice.content);
            }
            if (item.valueObject?.Amount) {
                const amount = item.valueObject.Amount;
                possiblePriceStrings.push(amount.content);
            }
        }
        if (invoice.fields.AmountDue) {
            const amountDue = invoice.fields.AmountDue;
            possiblePriceStrings.push(amountDue.content);
        }
        if (invoice.fields.SubTotal) {
            const subTotal = invoice.fields.SubTotal;
            possiblePriceStrings.push(subTotal.content);
        }
        if (invoice.fields.InvoiceTotal) {
            const invoiceTotal = invoice.fields.InvoiceTotal;
            possiblePriceStrings.push(invoiceTotal.content);
        }
    }
    return possiblePriceStrings;
}

function findPossibleNumberSeparator(input: string): NumberSeparator | undefined {
    const commaIndex = input.lastIndexOf(NumberSeparator.Comma);
    const dotIndex = input.lastIndexOf(NumberSeparator.Dot);

    // If there is no match, return undefined
    if (commaIndex === -1 && dotIndex === -1) {
        return undefined;
    }

    // Determine which separator was matched last
    if (commaIndex > dotIndex) {
        return NumberSeparator.Comma;
    } else {
        return NumberSeparator.Dot;
    }
}

export function getThousandSeparator(decimalSeparator: NumberSeparator): NumberSeparator {
    if (decimalSeparator === NumberSeparator.Comma) {
        return NumberSeparator.Dot;
    } else {
        return NumberSeparator.Comma;
    }
}

/**
 * Looks at all possible strings containing a price and checks whether a comma or a dot appears last.
 * Then takes the majority vote to determine the decimal separator.
 */
export function determineDecimalSeparator(azureExtractionResult: AzureExtractionResult): NumberSeparator {
    // by default choose comma
    let decimalSeparator = NumberSeparator.Comma;

    const possiblePriceStrings = getPossiblePriceStrings(azureExtractionResult);

    const possibleNumberSeparators = possiblePriceStrings.map(findPossibleNumberSeparator);
    const countCommas = possibleNumberSeparators.filter((separator) => separator === NumberSeparator.Comma).length;
    const countDots = possibleNumberSeparators.filter((separator) => separator === NumberSeparator.Dot).length;

    if (countDots > countCommas) {
        decimalSeparator = NumberSeparator.Dot;
    }

    return decimalSeparator;
}
