import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation, HistoryRecordFlagDTO } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

type Props = {
    flag: HistoryRecordFlagDTO;
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};
export default function ManufacturerFree({ actor, flag, operation }: Props) {
    if (!isActorDefined(actor)) return null;
    if (operation === HistoryOperation.AutoAdd) return <ManufacturerFreeAutoAdd />;
    if (flag.marked) return <MarkedAsManufactuerFree actor={actor} />;
    return <ManufacturerFreeRemoved actor={actor} />;
}

function ManufacturerFreeAutoAdd() {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>
                    Marked as <strong>Manufacturer free</strong> automatically
                </Trans>
            </TimelineText>
        </TimelineContentContainer>
    );
}

function ManufacturerFreeRemoved({ actor }: { actor: ActorResponseRuntypeDTO }) {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>
                    <strong>Manufacturer free</strong> marking removed
                </Trans>{' '}
                <ActionPerformedBy actor={actor} />
            </TimelineText>
        </TimelineContentContainer>
    );
}

function MarkedAsManufactuerFree({ actor }: { actor: ActorResponseRuntypeDTO }) {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>
                    Marked as <strong>Manufacturer free</strong>
                </Trans>{' '}
                <ActionPerformedBy actor={actor} />
            </TimelineText>
        </TimelineContentContainer>
    );
}

export function ManufactureFreeTimelineIcon({ marked }: { marked: boolean }) {
    if (marked) return <TimelineAddIcon />;
    return <TimelineDeleteIcon />;
}
