import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../components/PageLayout';
import { AddSourcingScenarioForm } from '../../../../../../modules/SourcingScenarioForm';
import { UrlParams } from '../../../../../../utils/routes';

const AddSourcingScenarioPage = (urlParams: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/new'>): JSX.Element => {
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox alignItems={'center'} gap={32}>
                    <Text variant="h1">
                        <Trans>Add sourcing scenario</Trans>
                    </Text>
                </Flexbox>
                <AddSourcingScenarioForm {...urlParams} />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default AddSourcingScenarioPage;
