import { Chip, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

interface FilterChipProps<Value> {
    label: string | JSX.Element;
    onChange: (value: Value) => void;
    isSelected: boolean;
    startIcon?: JSX.Element;
    value: Value;
}

export function FilterChip<Value>({
    label,
    onChange,
    isSelected,
    startIcon,
    value,
}: FilterChipProps<Value>): JSX.Element {
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onChange(value);
    };

    const chipStyles = React.useMemo(() => {
        if (isSelected) {
            return {
                background: colorSystem.primary[2],
                border: `1px solid ${colorSystem.primary[3]}`,
            };
        }
        return {};
    }, [isSelected]);

    return (
        <DefaultFilterChipContainer
            label={
                <Flexbox alignItems={'center'} gap={4}>
                    {startIcon && (
                        <Text style={{ fontSize: '16px', lineHeight: '0px', marginLeft: '-2px' }}>{startIcon}</Text>
                    )}
                    <Text variant="body-small">{label}</Text>
                </Flexbox>
            }
            onClick={onClick}
            style={chipStyles}
        />
    );
}

const DefaultFilterChipContainer = styled(Chip)({
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    '&:hover': {
        background: colorSystem.neutral[1],
        border: `1px solid ${colorSystem.neutral[2]}`,
    },
    '&:focus': {
        background: colorSystem.neutral.white,
    },
});
