import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    RsComponentsCountryCodeDTORuntype,
    RsComponentsCredentialsInputDTORuntype,
    RsComponentsResponseBodyDTORuntype,
    RsComponentsStoreIdDTORuntype,
} from './rsComponentsBackendTypes';

export const rsComponentsEndpoints = {
    'GET /3rdparty/rscomponents/credentials': endpoint({
        description: 'Returns credentials for RS Components integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: RsComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rscomponents/credentials': endpoint({
        description: 'Uploads credentials for RS Components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RsComponentsCredentialsInputDTORuntype,
        responseBody: RsComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'PATCH /3rdparty/rscomponents/credentials': endpoint({
        description: 'Updates credentials for RS Components API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RsComponentsCredentialsInputDTORuntype,
        responseBody: RsComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'DELETE /3rdparty/rscomponents/credentials': endpoint({
        description: 'Deletes existing credentials for RS Components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'GET /3rdparty/rscomponents/supported-store-id': endpoint({
        description: 'Returns possible store id for RsComponents',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ stores: r.Array(RsComponentsStoreIdDTORuntype) }),
    }),
    'GET /3rdparty/rscomponents/supported-country-code': endpoint({
        description: 'Returns possible country codes for RsComponents',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ country_codes: r.Array(RsComponentsCountryCodeDTORuntype) }),
    }),
};
