import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { ActivityDTO, ResourceDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { ResourcesCell, TextCell } from '../../ActivitiesTable/activitiesTableComponents';

function getResourceFilters<C extends ResourcesColumnContext>(
    activities: ActivityDTO[],
    context: C,
): Filter<ActivityDTO, C>[] {
    const resourceIds = activities.flatMap((activity) =>
        activity.resources_details.map((detail) => detail.resource_id),
    );
    const distinctResourceIds = new Set(resourceIds);
    const filters = [...distinctResourceIds]
        .map((resourceId) => {
            const resourceName = context.resourceNamesMap[resourceId];
            return resourceName !== undefined
                ? {
                      id: `resource=${resourceId}`,
                      label: resourceName,
                      predicate: (activity: ActivityDTO) =>
                          activity.resources_details.map((detail) => detail.resource_id).includes(resourceId),
                  }
                : undefined;
        })
        .filter(isPresent);

    filters.sort((a, b) => a.label.localeCompare(b.label));
    return filters;
}

const createResourceNamesMap = (resources: ResourceDTO[]): Record<string, string> => {
    const resourceNamesMap: Record<string, string> = {};
    resources.forEach((resource) => (resourceNamesMap[resource.id] = resource.name));
    return resourceNamesMap;
};

export const useResourcesContext = (): ResourcesColumnContext => {
    const { data: resourceNamesMap = {} } = useHttpQuery(
        'GET /resources',
        {},
        { select: (data) => createResourceNamesMap(data.data) },
    );

    return { resourceNamesMap };
};

export interface ResourcesColumnContext {
    resourceNamesMap: Record<string, string>;
}

export function getResourcesColumn<C extends ResourcesColumnContext>(
    activities: ActivityDTO[],
    context: C,
): Column<ActivityDTO, C> {
    return {
        label: <Trans>Resources</Trans>,
        id: `resource`,
        render: ({ data: rowData }: Row<ActivityDTO>) => {
            return <TextCell firstRow={<ResourcesCell resourcesDetails={rowData.resources_details} />} />;
        },
        width: columnWidth.large,
        filters: getResourceFilters(activities, context),
    };
}
