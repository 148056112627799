import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { BaseButton } from './BaseButton';

export const TertiaryButton = styled(BaseButton)({
    color: colorSystem.neutral[7],
    '&:hover': {
        background: colorSystem.primary[1],
        color: colorSystem.primary[7],
    },
    '&:active': {
        background: colorSystem.primary[2],
        color: colorSystem.primary[8],
    },
    '&:disabled': {
        color: colorSystem.neutral[5],
    },
});
