import React from 'react';

interface SnapshotComparisonPageContextState {
    sidebar?: JSX.Element;
    setSidebar: (sidebar?: JSX.Element) => void;
    close: () => void;
}

const Context = React.createContext<SnapshotComparisonPageContextState>({
    sidebar: undefined,
    setSidebar: () => {},
    close: () => {},
});

export function SnapshotComparisonPageContext(props: React.PropsWithChildren<{}>): JSX.Element {
    const [sidebar, setSidebar] = React.useState<JSX.Element>();
    const close = React.useCallback(() => setSidebar(undefined), []);
    return <Context.Provider value={{ sidebar, setSidebar, close }}>{props.children}</Context.Provider>;
}

export function useSnapshotComparisonPageContext(): SnapshotComparisonPageContextState {
    return React.useContext(Context);
}
