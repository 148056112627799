import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Text } from '@luminovo/design-system';
import { CircularProgress, Typography } from '@mui/material';
import { useIsCalculationModuleActive } from '../../../../featureFlags';
import { useAssemblies } from '../../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

const useAssembliesUsedInCalculationForSourcingScenario = (sourcingScenarioId: string) => {
    const { isCalculationModuleActive } = useIsCalculationModuleActive();
    const { data, isLoading } = useHttpQuery(
        'GET /calculations/scenario-combinations',
        /* eslint-disable-next-line camelcase */
        { queryParams: { sourcing_scenario_id: sourcingScenarioId } },
        { enabled: isCalculationModuleActive },
    );

    const { data: assemblies = [] } = useAssemblies([...new Set(data?.map((sc) => sc.assembly_id))]);

    return {
        isLoading: isLoading,
        assemblies: assemblies.map((a) => a.designator).filter(isPresent),
    };
};

export const AssembliesUsedInCalculationForTheSourcingScenario = ({
    sourcingScenarioId,
}: {
    sourcingScenarioId: string;
}): JSX.Element => {
    const { assemblies, isLoading } = useAssembliesUsedInCalculationForSourcingScenario(sourcingScenarioId);
    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            {assemblies.length > 0 && (
                <>
                    <Text>
                        <Trans>
                            This sourcing scenario is used for the calculations of the following assemblies. Deleting
                            this sourcing scenario will also delete the calculations which are associated with these
                            assemblies.
                        </Trans>
                    </Text>

                    <ul>
                        {assemblies.map((assembly, index) => (
                            <li key={index}>
                                <Typography>{assembly}</Typography>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};
