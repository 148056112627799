import { LanguageEnum, languageTranslations, transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

const parser = parse.language();

export const parseLanguage: Parser<LanguageEnum, Opts> = async function (
    cells,
    _,
    field,
): Promise<ParseResult<LanguageEnum>> {
    const keys = typeSafeObjectKeys(languageTranslations);
    const alternatives = keys.map((lang) => {
        return {
            id: lang,
            label: transEnum(lang, languageTranslations),
        };
    });

    return applyParser<LanguageEnum>({
        cells,
        field,
        formatValue: (value) => transEnum(value, languageTranslations),
        parser,
        alternatives,
    });
};
