import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { AssemblyFormDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAssembly } from '../../../resources/assembly/assemblyHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { route } from '../../../utils/routes';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { useDefaultAssemblyType } from '../../RfqCreation/hooks/useDefaultAssemblyType';
import { AssemblyForm } from './AssemblyForm';

export function AddAssemblyForm({
    rfqId,
    parentAssemblyId,
    viewContext,
}: {
    rfqId: string;
    parentAssemblyId: string;
    viewContext: ViewContext;
}): JSX.Element {
    const history = useHistory();
    const { data: rfq } = useRfQ(rfqId);
    const { data: parentAssembly } = useAssembly(parentAssemblyId);
    const { data: defaultAssemblyType } = useDefaultAssemblyType();

    const generateOnSuccessRoute = React.useCallback(
        (assemblyId: string, newParents: { [key: string]: number }) => {
            if (viewContext.type === 'WithinRfQ') {
                const parentId = parentAssemblyId in newParents ? parentAssemblyId : Object.keys(newParents)[0];
                return route(
                    '/rfqs/:rfqId/bom/assembly/:assemblyId',
                    { rfqId, assemblyId },
                    { currentParentAssemblyId: parentId, tab: null, monitoring: null },
                );
            }

            return route(
                '/assemblies/:assemblyId/dashboard',
                { assemblyId },
                { tab: undefined, rfqId, isMonitoringOpen: undefined },
            );
        },
        [rfqId, viewContext.type, parentAssemblyId],
    );

    const { mutateAsync } = useHttpMutation('POST /assemblies', {
        snackbarMessage: t`Assembly successfully created`,
        onSuccess: (response) => history.push(generateOnSuccessRoute(response.data.id, response.data.parents)),
    });

    if (!isPresent(parentAssembly) || !isPresent(rfq) || !isPresent(defaultAssemblyType)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (requestBody: AssemblyFormDTO) => {
        await mutateAsync({ requestBody });
    };

    /* eslint-disable camelcase */
    const defaultValues: AssemblyFormDTO = {
        rfq: rfqId,
        type: { type: defaultAssemblyType },
        notes: '',
        designator: '',
        customer: rfq.customer,
        industry: parentAssembly.industry,
        parents: [parentAssemblyId],
        sub_assembly_quantities: [],
        ipn_value: '',
        ipn_revision: '',
    };
    /* eslint-enable camelcase */

    return <AssemblyForm onSubmit={onSubmitCallback} defaultValues={defaultValues} />;
}
