import { assertUnreachable } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import { DemandScenarioDTO } from '@luminovo/http-client';

export const DemandScenarioTypeTag = ({ type }: { type: DemandScenarioDTO['type'] }): JSX.Element => {
    switch (type) {
        case 'Prototype':
            return <Tag color="primary" attention="high" label={type} />;
        case 'Series':
            return <Tag color="teal" attention="high" label={type} />;
        default:
            return assertUnreachable(type);
    }
};
