import { t } from '@lingui/macro';

export function getTooltipForAcronym(acronym: string) {
    switch (acronym.toUpperCase()) {
        case 'MPN':
            return t`Manufacturer part number`;
        case 'CPN':
            return t`Customer part number`;
        case 'DNP':
            return t`Do not place`;
        default:
            return '';
    }
}
