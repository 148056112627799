import { t } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { id } from '@luminovo/commons';
import { MenuButton } from '@luminovo/design-system';
import { GenericFullPart, OtsComponentFull, OtsFullPart, RfqContext } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../../resources/part/partFrontendTypes';
import { useOtsPartAddDialog } from '../../../PartLibrary/PartDetailsPage/components/OtsPartDialogs';
import { useAddGenericPartDrawer } from '../useAddGenericPartDrawer';

export const AddManualParts = ({
    rfqId,
    assemblyId,
    bomItem,
    addPart,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    addPart: (data: OtsFullPart | GenericFullPart | OtsComponentFull, sectionOfScreen: SectionOfScreen) => void;
}) => {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { openDialog } = useOtsPartAddDialog();
    const { openDrawer } = useAddGenericPartDrawer({ rfqContext, onGenericPartCreation: addPart });

    const hasCreateOtsPartPermission = useHasPermission(['create:ots:part']);

    const menuButtonOptions = [
        {
            id: id('design/button_add_generic_part'),
            label: t`Generic part`,
            onClick: () => {
                openDrawer();
            },
            enabled: true,
            hidden: false,
        },

        {
            id: id('design/button_add_ots_part_manually'),
            label: t`Off-the-shelf part`,
            onClick: () => {
                openDialog({ initialMpn: '', onSuccess: addPart });
            },
            enabled: hasCreateOtsPartPermission,
            hidden: !hasCreateOtsPartPermission,
        },
    ];

    const visibleMenuButtonOptions = menuButtonOptions.filter((menu) => !menu.hidden);

    return (
        <>
            <MenuButton
                id={id('design/button_add_part_manually')}
                icon={<Add />}
                label={t`Add part manually`}
                size="medium"
                appearance="tertiary"
            >
                {visibleMenuButtonOptions.map((option, i) => (
                    <MenuItem
                        id={option.id}
                        key={i}
                        onClick={() => {
                            option.onClick();
                        }}
                        disabled={!option.enabled}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuButton>
        </>
    );
};
