import { LumiQuoteRoute } from '../../../utils/routes';
import { typed } from '../../../utils/typingUtils';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { PCBRouteSuffix } from '../components/PcbSidebar/utils/pcbSidebarLinks';

export const formatRouteBasedOnViewContext = (route: PCBRouteSuffix, viewContext: ViewContext): LumiQuoteRoute => {
    if (viewContext.type === 'WithinRfQ') {
        return typed<LumiQuoteRoute>(`/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/${route}` as LumiQuoteRoute);
    } else return typed<LumiQuoteRoute>(`/assemblies/:assemblyId/pcb/${route}` as LumiQuoteRoute);
};
