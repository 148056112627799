import { EndpointRegistry, ExtractResponseBody, HttpOptions, RegisteredHttpEndpoint } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import * as r from 'runtypes';
import { useHttpQuery } from '../resources/http/useHttpQuery';

interface PollingOptions<T extends RegisteredHttpEndpoint> {
    /**
     * @return true to indicate that polling should stop.
     */
    isDone(response: ExtractResponseBody<T>): boolean;
    /**
     * This function is executed after every polling request completes.
     */
    onPoll?(response: ExtractResponseBody<T>): void;
    /**
     * This function is executed after polling is finished, i.e. `isDone` returns true.
     */
    onPollingDone?(response: ExtractResponseBody<T>): void;

    /**
     * Return a number between 0 and 1 to indicate the progress of the polling.
     *
     * - 0 means no progress has been made.
     * - 1 means the polling is done.
     */
    estimateProgress?: (initialData: ExtractResponseBody<T>, currentData: ExtractResponseBody<T>) => number;

    /**
     * The amount of milliseconds to wait before polling again.
     */
    refetchInterval?: number;

    enabled?: boolean;
}

interface UsePollHttpQueryResult<
    T extends RegisteredHttpEndpoint,
    TData extends r.Static<EndpointRegistry[T]['responseBody']> = r.Static<EndpointRegistry[T]['responseBody']>,
> {
    /**
     * True while the query is being polled.
     */
    isPolling: boolean;
    data: TData | undefined;
    initialData: TData | undefined;

    estimatedProgress: () => number;
}

export function usePollHttpQuery<
    T extends RegisteredHttpEndpoint,
    TData extends r.Static<EndpointRegistry[T]['responseBody']> = r.Static<EndpointRegistry[T]['responseBody']>,
>(endpoint: T, options: HttpOptions<T>, pollingOptions: PollingOptions<T>): UsePollHttpQueryResult<T, TData> {
    const {
        isDone,
        refetchInterval = 2000,
        enabled = true,
        onPollingDone = () => {},
        onPoll = () => {},
        estimateProgress,
    } = pollingOptions;

    const [initialData, setInitialData] = React.useState<TData | undefined>(undefined);

    // sync the polling handlers with the refs.
    const refOnPollingDone = React.useRef(onPollingDone);
    React.useEffect(() => {
        refOnPollingDone.current = onPollingDone;
    }, [onPollingDone]);

    const refIsDone = React.useRef(isDone);
    React.useEffect(() => {
        refIsDone.current = isDone;
    }, [isDone]);

    const refOnPoll = React.useRef(onPoll);
    React.useEffect(() => {
        refOnPoll.current = isDone;
    }, [isDone]);

    const response: UseQueryResult<TData> = useHttpQuery(endpoint, options, {
        refetchInterval: (response) => {
            if (response === undefined) {
                return refetchInterval;
            }
            if (!isDone(response)) {
                return refetchInterval;
            }
            return Infinity;
        },
        enabled,
        // Don't use the default error handler, otherwise in case the endpoint is buggy this
        // will overload the screen with errors every time the response fails.
        meta: { globalErrorHandler: false },
        cacheTime: refetchInterval * 2,
        staleTime: refetchInterval,
    });

    const data = response.data;
    const isDoneFetching = response.data && isDone(data);

    React.useEffect(() => {
        setInitialData((current) => {
            if (current === undefined) {
                return data;
            }
            return current;
        });
    }, [data, setInitialData]);

    React.useEffect(() => {
        const isDone = refIsDone.current;
        const onPollingDone = refOnPollingDone.current;
        const onPoll = refOnPoll.current;
        if (data === undefined || !isDone || !onPollingDone || !onPoll) {
            // This will be executed the first time this component is rendered.
            // At this stage there is nothing to report as it's unclear if polling
            // will happen or not.
            return;
        }
        const continuePolling = !isDone(data);
        if (continuePolling) {
            onPoll(data);
        } else {
            onPollingDone(data);
            setInitialData(undefined);
        }

        // Depend on the refs as opposed to the polling handlers directly. As
        // refs will never change (only ref.current changes), we're telling this
        // useEffect to only run when the `data` changes.
    }, [refOnPollingDone, refIsDone, refOnPoll, data]);

    return {
        isPolling: enabled && !isDoneFetching,
        data: response.data,
        initialData,
        estimatedProgress: () => {
            if (!estimateProgress) {
                throw new Error('progress can only be estimated if an estimateProgress function is provided.');
            }
            if (isDoneFetching) {
                return 1;
            }
            if (!initialData || !data) {
                return 0;
            }

            return estimateProgress(initialData!, data!);
        },
    };
}
