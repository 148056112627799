import { KeyValuePair } from '../extract/extractKeyValuePairs';
import { AttributeExtractionRule, Parser } from '../types';

export function kvRule({ value, key }: { key: RegExp; value: Parser }): AttributeExtractionRule<KeyValuePair> {
    return (item) => {
        if (key.test(item.key.content)) {
            const parsed = value(item.key.content + ' ' + item.value.content);
            if (!parsed) {
                return [];
            }
            return [{ ...parsed, confidence: item.confidence * (parsed.confidence ?? 1) }];
        }
        return [];
    };
}
