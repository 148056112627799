import { formatAmount, MonetaryValue } from '@luminovo/commons';
import { monetaryValue } from './monetaryValueMath';

export function getUnitPriceDiscount(unitPrice: MonetaryValue | null, discount: number) {
    if (unitPrice === null) {
        return null;
    }

    return formatAmount(monetaryValue.scale(unitPrice, 1 - discount / 100), 'unit-price');
}
