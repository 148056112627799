/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { Flexbox } from '@luminovo/design-system';
import {
    AssemblyResponseDTO,
    BACKEND_BASE,
    DescendantsDTO,
    DescendantsDTONew,
    DesignItemExcelOriginsDictionary,
    DesignItemOriginDTO,
    DesignItemOriginTypes,
    DesignItemOriginTypesNew,
    DesignItemResponseDTO,
    DesignItemResponseDTONew,
    DesignItemResponseDTORuntype,
    http,
} from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ViewContext } from '../../modules/Bom/components/ModuleTableData';
import { throwErrorUnlessProduction } from '../../utils/customConsole';
import { route } from '../../utils/routes';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';

export const ASSEMBLIES = BACKEND_BASE + '/assemblies';
export const createSingleAssemblyEndpoint = (assemblyId: string) => ASSEMBLIES + `/${assemblyId}`;

export const createSingleAssemblyParentsEndpoint = (assemblyId: string) => ASSEMBLIES + `/${assemblyId}/parents`;

export const getSpecificAssemblyPath = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
}): string =>
    route(
        '/rfqs/:rfqId/bom/assembly/:assemblyId',
        { rfqId, assemblyId },
        { tab: null, monitoring: null, currentParentAssemblyId },
    );

export function useAssembly(
    assemblyId: string,
    options: { enabled: boolean; with_aggregations: boolean } | undefined = { enabled: true, with_aggregations: false },
) {
    const { enabled, with_aggregations } = options;
    return useBulkSingleQuery(
        'POST /assemblies/bulk',
        assemblyId,
        {
            idExtractor: (item: AssemblyResponseDTO) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids }, queryParams: { with_aggregations } };
            },
            select: (res) => res.items,
        },
        { enabled, useErrorBoundary: true, suspense: true },
    );
}

export function useAssemblies(
    assemblyIds: string[] | undefined,
    options: { with_aggregations: boolean } | undefined = { with_aggregations: false },
) {
    const { with_aggregations } = options;
    return useBulkQuery(
        'POST /assemblies/bulk',
        assemblyIds,
        {
            idExtractor: (item: AssemblyResponseDTO) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids }, queryParams: { with_aggregations } };
            },
            select: (res) => res.items,
        },
        {},
    );
}

export const useTopLevelAssemblies = (rfqId: string) => {
    const { data } = useAssembly(rfqId);
    const subAssemblyIds = React.useMemo(() => {
        return data?.subassemblies.items.map((data) => data.assembly_id);
    }, [data?.subassemblies.items]);

    return useAssemblies(subAssemblyIds);
};

export function useParentsIdentifiers(assemblyId: string = '', queryOptions: { suspense?: boolean } = {}) {
    return useHttpQuery(
        'GET /assemblies/:assemblyId/parents',
        { pathParams: { assemblyId } },
        { enabled: Boolean(assemblyId), ...queryOptions },
    );
}

const AssemblyNameAndType = ({
    parent,
    assembly,
}: {
    parent: { designator: string };
    assembly: AssemblyResponseDTO | undefined;
}) => {
    if (!assembly) {
        return (
            <Flexbox gap={16} alignItems="center">
                {parent.designator} <Skeleton width={'30px'} />
            </Flexbox>
        );
    }

    return (
        <Flexbox gap={8} alignItems="center">
            {parent.designator}
        </Flexbox>
    );
};

export function useBomBreadcrumbs({
    assemblyId,
    rfqId,
    assembly,
    currentParentAssemblyId,
}: {
    assemblyId: string;
    rfqId: string;
    assembly: AssemblyResponseDTO | undefined;
    currentParentAssemblyId: string | null | undefined;
}): { title: string | JSX.Element; href: string }[] {
    const { data: parentsData } = useParentsIdentifiers(assemblyId);

    const currentParentPath = React.useMemo(() => {
        if (!currentParentAssemblyId) {
            return parentsData?.multi_parent_items?.[0];
        }
        return parentsData?.multi_parent_items?.find((path) => {
            if (path.length === 1 && path[0].designator === 'BOM') {
                return true;
            }
            // a parentAssembly is always at the second last position in the path.
            // the last position is the assembly itself
            const parentPosition = path.length - 2;
            const currentParent = path[parentPosition];
            return currentParent?.id === currentParentAssemblyId;
        });
    }, [currentParentAssemblyId, parentsData?.multi_parent_items]);

    const breadcrumbs = React.useMemo(() => {
        const parents = currentParentPath ?? [];
        return parents.map((parent, index) => {
            if (parent.id === rfqId) {
                return {
                    title: t`Design`,
                    href: route('/rfqs/:rfqId/bom', { rfqId }, { hideTopLevelAssembly: 'true' }),
                };
            }
            // add assemblyType tag if is last assembly in the breadcrumbs chain
            if (index + 1 === parents.length) {
                return {
                    title: <AssemblyNameAndType parent={parent} assembly={assembly} />,
                    href: route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId',
                        { assemblyId: parent.id, rfqId },
                        { currentParentAssemblyId: parents[index - 1]?.id, tab: null, monitoring: null },
                    ),
                };
            }
            return {
                title: parent.designator,
                href: route(
                    '/rfqs/:rfqId/bom/assembly/:assemblyId',
                    { assemblyId: parent.id, rfqId },
                    { currentParentAssemblyId: parents[index - 1]?.id, tab: null, monitoring: null },
                ),
            };
        });
    }, [assembly, rfqId, currentParentPath]);
    return breadcrumbs;
}

export const useBomTreeIds = (assemblyId: string) => {
    return useHttpQuery('GET /assemblies/:assemblyId/tree', { pathParams: { assemblyId } });
};

export function useDescendantsSummary(assemblyId: string, viewContext: ViewContext, enabled: boolean = true) {
    const viewedInAssemblyOverview = viewContext.type === 'AssemblyOverview';
    return useHttpQuery(
        'GET /assemblies/:assemblyId/descendants-summary',
        {
            pathParams: { assemblyId },
            queryParams: { viewed_in_assembly_overview: viewedInAssemblyOverview },
        },
        { enabled, select: (res) => res.data, suspense: true },
    );
}

export function useDescendants(assemblyId?: string) {
    return useHttpQuery(
        'GET /assemblies/:assemblyId/descendants',
        { pathParams: { assemblyId: assemblyId ?? '' } },
        {
            enabled: Boolean(assemblyId),
            useErrorBoundary: true,
            select: (res) => {
                const data: DescendantsDTO = mapDescendatsDTOToOld(res.data);
                return { ...res, data };
            },
            structuralSharing: false,
        },
    );
}

export function mapDesignItemResponsesDTONewToOld(
    designItems: DesignItemResponseDTONew[],
    excel_lines: DesignItemExcelOriginsDictionary,
): DesignItemResponseDTO[] {
    return designItems.map((designItem: DesignItemResponseDTONew): DesignItemResponseDTO => {
        if (designItem.origin.type === DesignItemOriginTypesNew.ExcelFileV2) {
            const excel_lines_id = designItem.origin.data.excel_lines_id;
            const component_excel_lines = excel_lines[excel_lines_id]?.original_lines ?? [];
            const bom_file_id = excel_lines[excel_lines_id]?.bom_file_id;
            const origin: DesignItemOriginDTO = {
                type: DesignItemOriginTypes.ExcelFile,
                data: { ...designItem.origin.data, excel_lines: component_excel_lines, bom_file_id: bom_file_id },
            };
            return { ...designItem, origin };
        } else {
            // Note: this is fine
            // the only old vs new PlacedComp DTOs difference is ExcelFileV2 in origin
            return DesignItemResponseDTORuntype.check(designItem);
        }
    });
}

function mapDescendatsDTOToOld(data: DescendantsDTONew): DescendantsDTO {
    const { excel_lines, design_items: design_items0 } = data;
    const design_items: DesignItemResponseDTO[] = mapDesignItemResponsesDTONewToOld(design_items0, excel_lines);
    return { ...data, design_items };
}

export function useAssemblyResources(assemblyId: string): {
    cadFile: string | null | undefined;
    additionalFiles: string[] | undefined;
    isLoading: boolean;
} {
    const searchResult = useHttpQuery(
        /* eslint-disable-next-line spellcheck/spell-checker */
        'GET /assemblies/:assemblyId/resources',
        {
            pathParams: { assemblyId },
        },
    );

    return {
        cadFile: searchResult.data?.cad,
        additionalFiles: searchResult.data?.other,
        isLoading: searchResult.isLoading,
    };
}

export function useDeleteCadFile(assemblyId: string, rfqId: string) {
    const token = getToken();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (value: string) => {
            return http(
                'DELETE /assemblies/:assemblyId/delete/cad',
                { pathParams: { assemblyId }, queryParams: { blob_name: value } },
                token,
            );
        },

        onSuccess: async () => {
            return await Promise.allSettled([
                queryClient.invalidateQueries(
                    httpQueryKey('GET /assemblies/:assemblyId/resources', {
                        pathParams: { assemblyId },
                    }),
                ),
                queryClient.invalidateQueries(
                    httpQueryKey('POST /rfqs/:rfqId/customer-portal', { pathParams: { rfqId } }),
                ),
            ]);
        },
        onError: (error) => throwErrorUnlessProduction(error),
    });
}

export function useDeleteAdditionalFile(assemblyId: string, rfqId: string) {
    const token = getToken();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (value: string) => {
            return http(
                'DELETE /assemblies/:assemblyId/delete/other',
                { pathParams: { assemblyId }, queryParams: { blob_name: value } },
                token,
            );
        },

        onSuccess: async () => {
            return await Promise.allSettled([
                queryClient.invalidateQueries(
                    httpQueryKey('GET /assemblies/:assemblyId/resources', {
                        pathParams: { assemblyId },
                    }),
                ),
                queryClient.invalidateQueries(
                    httpQueryKey('POST /rfqs/:rfqId/customer-portal', { pathParams: { rfqId } }),
                ),
            ]);
        },
        onError: (error) => throwErrorUnlessProduction(error),
    });
}

export function useIsBomImportingAllowed({ assemblyId, rfqId }: { assemblyId?: string; rfqId?: string }): boolean {
    const { data: descendants } = useDescendants(assemblyId);

    if (!rfqId) {
        // you cannot import a BOM when rfqId is not defined
        return false;
    }
    if (rfqId === assemblyId) {
        // You cannot import a BOM on the top level assembly as the top level
        // assembly is actually the RFQ
        return false;
    }

    if (!descendants) {
        // Don't allow imports until the descendants have loaded to prevent
        // data races
        return false;
    }

    const hasImportedBom = descendants.data.design_items
        .filter((designItem) => {
            return designItem.assembly === assemblyId;
        })
        .some((designItem) => {
            return designItem.origin.type === DesignItemOriginTypes.ExcelFile;
        });

    if (hasImportedBom) {
        // Don't allow imports if there is already a BOM imported
        return false;
    }

    return true;
}

/**
 * @returns a boolean value indicating if the BOM dropzone should be shown
 */
export function useIsBomDropzoneVisible({ assemblyId }: { assemblyId?: string }): boolean {
    const { data: descendants } = useDescendants(assemblyId);

    if (!descendants) {
        // if still loading, don't show a dropzone
        return false;
    }
    // only show the dropzone if the assembly is totally empty and there are no subassemblies
    return descendants.data.design_items.length === 0 && descendants.data.assemblies.length === 0;
}

export function useBomFileResources(assemblyId: string | undefined = '') {
    return useHttpQuery(
        'GET /assemblies/:id/bom-file/resources',
        { pathParams: { id: assemblyId } },
        {
            enabled: Boolean(assemblyId),
            select: (res) => res.items,
            retry: false,
            meta: { globalErrorHandler: false },
        },
    );
}

export function useAssemblyAggregateSourcingQuantity({
    assemblyId,
    rfqId,
    enabled,
}: {
    assemblyId: string;
    rfqId: string;
    enabled: boolean;
}) {
    const { data, isLoading } = useHttpQuery(
        'GET /assemblies/:assemblyId/aggregate-quantity',
        {
            pathParams: { assemblyId },
            // eslint-disable-next-line camelcase
            queryParams: { rfq_id: rfqId },
        },
        {
            enabled,
        },
    );

    return { assemblyQuantities: data?.quantities, isLoading };
}
