import { FullPart } from '@luminovo/http-client';
import { ModuleTableData } from '../../ModuleTableData';

export type BomOverviewItem = {
    type: 'row';
    moduleTableData: ModuleTableData;
    approvedPartOptions: ApprovedPartOptionItem[];
};

export type ApprovedPartOptionItem = {
    type: 'subrow';
    fullPart: FullPart;
};

export type BomOverviewSharedContext = {
    assemblyId: string;
};

export type BomOverviewTableData = BomOverviewItem | ApprovedPartOptionItem;

export enum bomOverviewColumnIds {
    columnDesignators = 'bom-overview-designators',
    columnIpn = 'bom-overview-ipn',
    columnCpn = 'bom-overview-cpn',
    columnPartCategory = 'bom-overview-part-category',
    columnPartOptions = 'bom-overview-part-options',
    columnAvailability = 'bom-overview-availability',
    columnReach = 'bom-overview-reach',
    columnRohs = 'bom-overview-rohs',
    columnAecq = 'bom-overview-aecq',
    columnCompliance = 'bom-overview-compliance',
    columnLifecycle = 'bom-overview-lifecycle',
    columnYearsToEndOfLife = 'bom-overview-years-to-end-of-life',
    columnEmissions = 'bom-overview-emissions',
    columnComments = 'bom-overview-comments',
    columnCountryOfOrigin = 'bom-overview-country-of-origin',
}
