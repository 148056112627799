import { textDoesNotContainSpecialCharactersRegEx, throwErrorUnlessProduction } from '@luminovo/commons';

//source https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions#escaping
function escapeRegExp(word: string) {
    return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

//TODO: move the createWholeWordRegexp to commons. https://www.notion.so/luminovo/move-pcb-drivers-to-manufacturing-core-7361019ae273472f9d5f202163a66122
/**
 * Creates a regular expression to test if a string contains
 * a specific word. Source: https://stackoverflow.com/a/10093994
 * NOTE: This is designed to work with words that do not contain special characters (excluding underscores)
 * This is because the regex was designed to be used in the context of highlighting words in the formula editor.
 **/
export const createWholeWordRegex = (word: string): RegExp => {
    if (!textDoesNotContainSpecialCharactersRegEx.test(word)) {
        //The word can include an underscore since this has been specifically tested for
        if (!word.includes('_')) {
            throwErrorUnlessProduction(`The word ${word} contains special characters. This is not allowed.`);
        }
    }
    return new RegExp('(?:\\b|_)' + escapeRegExp(word) + '(?:\\b|_)');
};
