import { t } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {
    decimalSeparator: '.' | ',';
}

export const parseLeadTime: Parser<number, Opts> = async function (cells, opts, field): Promise<ParseResult<number>> {
    const parser = parse.leadTime(opts);

    return applyParser({
        cells,
        parser,
        field,
        formatValue: (num) => formatDecimal(num) + ' ' + t`week(s)`,
    });
};
