import { t } from '@lingui/macro';
import { formatCurrencyWithoutSymbol, formatDecimal, formatToLongDate } from '@luminovo/commons';
import { formatPackaging } from '../../../../formatters';
import { Attribute } from './types';

export function formatAttributeValue(attr: Attribute): string {
    switch (attr.attr) {
        case 'currency':
        case 'offerNumber':
            return attr.value;
        case 'dueDate':
        case 'offerDate':
            return formatToLongDate(attr.value);

        case 'validFor':
            return attr.value;

        case 'part':
            return attr.value ? `${attr.value.mpn}, ${attr.value.manufacturer.name}` : '';

        case 'moq':
        case 'mpq':
        case 'number':
        case 'quantity':
        case 'stock':
        case 'isRow':
            return formatDecimal(attr.value);

        case 'packaging':
            return attr.value === 'none' ? '' : formatPackaging(attr.value);

        case 'standardFactoryLeadTime':
            return formatDecimal(attr.value.value) + ' ' + attr.value.unit;

        case 'totalPrice':
            return formatCurrencyWithoutSymbol(attr.value);
        case 'unit':
            return attr.value;
        case 'unitPrice':
            return formatCurrencyWithoutSymbol(attr.value, 'unit-price');
        case 'inStock':
            return t`True`;
    }
}
