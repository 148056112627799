import { Protected } from '@luminovo/auth';
import { useIsCustomer } from '../../../../components/contexts/CurrentUserDetailsContext';
import { CustomerDashboardPage } from '../../../../modules/RfqDashboard/CustomerDashboard/CustomerDashboardPage';
import { TenantDashboardPage } from '../../../../modules/RfqDashboard/RfqDashboardPage';
import { UrlParams } from '../../../../utils/routes';

const DashboardPage = (props: UrlParams<'/rfqs/:rfqId/dashboard'>) => {
    const isCustomer = useIsCustomer();

    if (props.queryParams.showOemDashboard === 'true') {
        return <CustomerDashboardPage {...props} />;
    }

    return (
        <Protected requiredPermissions={() => !isCustomer} fallback={<CustomerDashboardPage {...props} />}>
            <TenantDashboardPage {...props} />
        </Protected>
    );
};

export default DashboardPage;
