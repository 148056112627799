import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    ActivityDTO,
    ActivityPostDTO,
    ActivityPostDTORuntype,
    http,
    ResourceDTO,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm, UseFormReturn, useWatch } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { useHistory, useParams } from 'react-router-dom';
import { AutocompleteOfIdOption } from '../../../components/formLayouts/reactHookFormComponents/reactHookFormAutocompleteComponents';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { assertAgainstRuntype } from '../../../utils/customConsole';
import { deprecatedYupResolver } from '../../../utils/deprecated_yupResolver';
import { route } from '../../../utils/routes';
import { nullIfEmptyOrUndefined, RecursivePartial } from '../../../utils/typingUtils';
import { createActivitySchema } from './activityValidationSchema';
import {
    AddActivityFormInputs,
    AddActivityFormInputsBatchTimeCalculationFixed,
    AddActivityFormInputsBatchTimeCalculationLinear,
    AddActivityFormInputsProjectTimeCalculationFixed,
    AddActivityFormInputsProjectTimeCalculationLinear,
    AddActivityFormInputsTimeCalculationFormula,
    AddActivityFormInputsUnitTimeCalculationFixed,
    AddActivityFormInputsUnitTimeCalculationLinear,
} from './AddActivityFormTypes';
import { generateTimeCalculationFromData } from './timeComponentsFunctions';
/* eslint-disable camelcase */

export const createParsedData = (data: AddActivityFormInputs): ActivityPostDTO => {
    const postBody: ActivityPostDTO = {
        name: data.activity.name,
        category: data.activity.category,
        internal_number: nullIfEmptyOrUndefined(data.activity.internalActivityNumber),
        process: data.activity.process,
        description: nullIfEmptyOrUndefined(data.activity.description),
        time_calculation: generateTimeCalculationFromData(data),
        resources_details: data.resourcesDetails.map((r) => ({
            resource_id: r.resourceId,
            multiplier: r.multiplier,
        })),
    };

    assertAgainstRuntype(postBody, ActivityPostDTORuntype);
    return postBody;
};

export const defaultFixedUnitTimeCalculation: AddActivityFormInputsUnitTimeCalculationFixed = {
    fixedUnitTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
};

export const defaultLinearUnitTimeCalculation: Omit<AddActivityFormInputsUnitTimeCalculationLinear, 'driverId'> = {
    fixedUnitTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
    variableUnitTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
};

export const defaultBatchFixedTimeCalculation: AddActivityFormInputsBatchTimeCalculationFixed = {
    fixedBatchTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
};

export const defaultProjectFixedTimeCalculation: AddActivityFormInputsProjectTimeCalculationFixed = {
    fixedProjectTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
};

export const defaultLinearBatchTimeCalculation: Omit<AddActivityFormInputsBatchTimeCalculationLinear, 'driverId'> = {
    fixedBatchTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
    variableBatchTime: {
        duration: {
            amount: undefined,
            unit: 'Seconds',
        },
    },
};

export const defaultLinearProjectTimeCalculation: Omit<AddActivityFormInputsProjectTimeCalculationLinear, 'driverId'> =
    {
        fixedProjectTime: {
            duration: {
                amount: undefined,
                unit: 'Seconds',
            },
        },
        variableProjectTime: {
            duration: {
                amount: undefined,
                unit: 'Seconds',
            },
        },
    };

export const defaultFormulaBatchTimeCalculation: AddActivityFormInputsTimeCalculationFormula = {
    formula: '',
    unit: 'Seconds',
};

export const defaultValues: RecursivePartial<AddActivityFormInputs> = {
    activity: {
        level: 'Unit',
        isActivityPerPanel: false,
    },
    resourcesDetails: [{ resourceId: undefined, multiplier: '1' }],
    fixedUnitCalculationType: defaultFixedUnitTimeCalculation,
    linearUnitCalculationType: defaultLinearUnitTimeCalculation,
    fixedBatchCalculationType: defaultBatchFixedTimeCalculation,
    fixedProjectCalculationType: defaultProjectFixedTimeCalculation,
    linearBatchCalculationType: defaultLinearBatchTimeCalculation,
    linearProjectCalculationType: defaultLinearProjectTimeCalculation,
    selectedUnitCalculationType: 'Fixed',
    selectedBatchCalculationType: 'None',
    formulaBatchCalculationType: defaultFormulaBatchTimeCalculation,
    formulaUnitCalculationType: defaultFormulaBatchTimeCalculation,
    formulaProjectCalculationType: defaultFormulaBatchTimeCalculation,
};

export interface UseActivityForm {
    onSubmitType: 'PATCH' | 'POST';
    defaultValues: RecursivePartial<AddActivityFormInputs>;
    // resources needs to be passed here rather than put into the form because otherwise the validation schema is created when the resources have not loaded
    allResources: ResourceDTO[];
    allDrivers: UserDriverDetailsDTO[];
    allActivities: ActivityDTO[];
}

export const useAddActivityForm = ({
    defaultValues,
    onSubmitType,
    allResources,
    allDrivers,
    allActivities,
}: UseActivityForm): {
    useFormResult: UseFormReturn<AddActivityFormInputs>;
    onSubmitPost: SubmitHandler<AddActivityFormInputs>;
    allResourceNameWithIds: AutocompleteOfIdOption[];
    onSubmitPatch: SubmitHandler<AddActivityFormInputs>;
} => {
    const token = getToken();
    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    // TODO Please avoid using useParams
    const { activityId } = useParams<{
        activityId: string;
    }>();
    const history = useHistory();

    const existingActivitiesFiltered =
        onSubmitType === 'POST' ? allActivities : allActivities.filter((activity) => activity.id !== activityId);
    const useFormResult = useForm<AddActivityFormInputs>({
        mode: 'onChange',
        defaultValues,
        resolver: deprecatedYupResolver(createActivitySchema(existingActivitiesFiltered, allDrivers, allResources)),
        reValidateMode: 'onChange',
    });
    const { control } = useFormResult;

    const { mutateAsync } = useMutation({
        mutationFn: (info: { values: ActivityPostDTO }) => {
            return http('POST /activities', { requestBody: info.values }, token);
        },
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /activities', { queryParams: {} }));
            enqueueSnackbar(t`Activity created`, { variant: 'success' });

            //checks if history exists https://stackoverflow.com/a/66137051
            if (history.action !== 'POP') {
                history.goBack();
            } else {
                history.push(route('/manufacturing/activity'));
            }
        },
    });

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; values: ActivityPostDTO }) => {
            return http(
                'PATCH /activities/:activityId',
                { requestBody: info.values, pathParams: { activityId } },
                token,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /activities', { queryParams: {} }));
            enqueueSnackbar(t`Activity updated`, { variant: 'info' });

            //checks if history exists https://stackoverflow.com/a/66137051
            if (history.action !== 'POP') {
                history.goBack();
            } else {
                history.push(route('/manufacturing/activity'));
            }
        },
    });

    const resourcesDetails = useWatch({
        control,
        name: 'resourcesDetails',
    });

    const selectedResourcesIds = resourcesDetails.map((d) => d.resourceId);
    const allResourceNameWithIds: AutocompleteOfIdOption[] =
        allResources
            .filter((resource) => resource.status === 'Active' || selectedResourcesIds.includes(resource.id))
            .map((resource) => {
                return { name: resource.name, id: resource.id };
            }) ?? [];

    const onSubmitPost: SubmitHandler<AddActivityFormInputs> = async (data: AddActivityFormInputs) => {
        const parsedData: ActivityPostDTO = createParsedData(data);

        return mutateAsync({
            values: parsedData,
        });
    };

    const onSubmitPatch: SubmitHandler<AddActivityFormInputs> = async (data: AddActivityFormInputs) => {
        const parsedData: ActivityPostDTO = createParsedData(data);

        return mutateAsyncPatch({
            token,
            values: parsedData,
        });
    };

    return {
        useFormResult,
        onSubmitPost: onSubmitPost,
        allResourceNameWithIds,
        onSubmitPatch,
    };
};
