import { Dictionary, groupBy, isPresent, uniqueBy } from '@luminovo/commons';
import {
    SolutionConfigurationFullPartSourcingInformationResponse,
    SolutionConfigurationSourcingDTO,
    SourcingBreadcrumbItemsDTO,
} from '@luminovo/http-client';

export function extractDesignators(
    solutionConfigurationSourcing:
        | SolutionConfigurationSourcingDTO
        | SolutionConfigurationFullPartSourcingInformationResponse,
): string[] {
    const uniqDesignators: string[] = uniqueBy(
        solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items.map((item) => item.designator),
        (x) => x,
    );

    return uniqDesignators.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
}

export function extractAggregatedBreadcrumbs(
    solutionConfigurationSourcing: SolutionConfigurationSourcingDTO | undefined,
): Dictionary<SourcingBreadcrumbItemsDTO[]> | undefined {
    if (!isPresent(solutionConfigurationSourcing)) {
        return undefined;
    }

    return groupBy(solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items, (breadcrumbs) =>
        breadcrumbs.parent_assemblies.map((assembly) => assembly.id).join(','),
    );
}
