import { Trans, t } from '@lingui/macro';
import { FormItem, Text } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';

export function FormItemParts({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const totalRequestedParts = quoteTracking.request_custom_parts.length + quoteTracking.request_standard_parts.length;
    return (
        <FormItem label={t`Parts`}>
            <Text>
                <Trans>{totalRequestedParts} requested</Trans>
            </Text>
        </FormItem>
    );
}
