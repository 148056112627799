let id = 0;
const ids: Record<string, number> = {};
function incrementalId(str: string): string {
    ids[str] = ids[str] || id++;
    return String(ids[str]);
}

export function generateId({
    content,
    boundingRegions,
}: {
    content: string;
    boundingRegions: Array<{ pageNumber: number; polygon: number[] }>;
}): string {
    const stable = JSON.stringify(boundingRegions.map((r) => r.pageNumber + ':' + r.polygon.join(',')));
    return incrementalId(JSON.stringify(stable));
}
