import * as r from 'runtypes';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { FixedCostCellRuntype } from '../calculation';
import { CountryAlpha2CodeRuntype } from '../organizationSettings';

const OrderSelectionOptionIdRuntype = r.String;

const TaxTypeRuntype = r.Union(
    r.Literal('AmusementTax'),
    r.Literal('CommunicationsTax'),
    r.Literal('GoodsAndServicesTax'),
    r.Literal('HarmonizedSalesTax'),
    r.Literal('IntegratedGoodsAndServicesTax'),
    r.Literal('JapaneseConsumptionTax'),
    r.Literal('ChicagoLeaseTax'),
    r.Literal('ProvincialSalesTax'),
    r.Literal('QuebecSalesTax'),
    r.Literal('RetailSalesTax'),
    r.Literal('SalesTax'),
    r.Literal('ValueAddedTax'),
);

export type TaxType = r.Static<typeof TaxTypeRuntype>;

const TaxBreakdownRuntype = r.Record({
    amount: MonetaryValueBackendRuntype,
    country_code: CountryAlpha2CodeRuntype.nullable(),
    percentage: r.String,
    state: r.String.nullable(),
    tax_type: TaxTypeRuntype.nullable(),
});

export type TaxBreakdownDTO = r.Static<typeof TaxBreakdownRuntype>;

const PriceIncludingTaxRuntype = r.Record({
    type: r.Literal('IncludingTax'),
    data: r.Record({
        total_price_before_tax: FixedCostCellRuntype,
        total_price_after_tax: MonetaryValueBackendRuntype,
        tax_breakdowns: r.Array(TaxBreakdownRuntype),
    }),
});

const PriceExcludingTaxRuntype = r.Record({
    type: r.Literal('ExcludingTax'),
    data: r.Record({
        total_price_before_tax: FixedCostCellRuntype,
    }),
});

const OrderSelectionPriceRuntype = PriceExcludingTaxRuntype.Or(PriceIncludingTaxRuntype);
export type OrderSelectionPriceDTO = r.Static<typeof OrderSelectionPriceRuntype>;

const OrderSelectionOptionRuntype = r.Record({
    order_size: r.Number,
    batch_size: r.Number,
    lead_time_in_days: r.Number.nullable(),
    price: OrderSelectionPriceRuntype,
});

export type OrderSelectionOptionDTO = r.Static<typeof OrderSelectionOptionRuntype>;

export const ManyOrderSelectionOptionsRuntype = r.Record({
    data: r.Dictionary(OrderSelectionOptionRuntype, OrderSelectionOptionIdRuntype),
    valid_until: r.String,
});

export type OrderSelectionOptionsDTO = r.Static<typeof ManyOrderSelectionOptionsRuntype>;

export const PaymentCollectionModeRuntype = r.Union(r.Literal('ChargeImmediately'), r.Literal('SendInvoice'));
export type PaymentCollectionModeDTO = r.Static<typeof PaymentCollectionModeRuntype>;
export const PaymentProcessorsRuntype = r.Union(r.Literal('Stripe'));
export type PaymentProcessorsDTO = r.Static<typeof PaymentProcessorsRuntype>;

const SalesOrderLineItemResponseRuntype = r.Record({
    id: r.String,
    sales_order_id: r.String,
    assembly_id: r.String,
    quantity: r.Number,
    total_price: MonetaryValueBackendRuntype,
});

export const SalesOrderResponseRuntype = r.Record({
    id: r.String,
    rfq_id: r.String,
    ordered_by_user_id: r.String,
    total_price: MonetaryValueBackendRuntype,
    estimated_lead_time_in_days: r.Number.nullable(),
    shipping_address: r.String.nullable(),
    payment_processor: PaymentProcessorsRuntype.nullable(),
    payment_id: r.String.nullable(),
    batch_size: r.Number,
    order_size: r.Number,
    sales_order_line_items: r.Array(SalesOrderLineItemResponseRuntype),
});

export type SalesOrderResponseDTO = r.Static<typeof SalesOrderResponseRuntype>;

export const OrderOverviewRuntype = r.Record({
    id: r.String,
    customer_id: r.String,
    customer_name: r.String,
    customer_number: r.String.nullable(),
    rfq_number: r.String.nullable(),
    estimated_lead_time_in_days: r.Number.nullable(),
    order_size: r.Number,
    total_price: MonetaryValueBackendRuntype,
});

export type GetOrderDTO = r.Static<typeof OrderOverviewRuntype>;

export const InitializePaymentInputRuntype = r.Record({
    rfq_id: r.String,
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    payment_collection_mode: PaymentCollectionModeRuntype.nullable(),
});

export type InitializePaymentInputDTO = r.Static<typeof InitializePaymentInputRuntype>;

export const CreateOrderInputRuntype = r.Record({
    rfq_id: r.String,
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    payment_processor: PaymentProcessorsRuntype.nullable(),
    payment_id: r.String.nullable(),
    payment_collection_mode: PaymentCollectionModeRuntype.nullable(),
});

export type CreateOrderInputDTO = r.Static<typeof CreateOrderInputRuntype>;

export type ChargeImmediatelyResponseDTO = r.Static<typeof ChargeImmediatelyResponseRuntype>;

const ChargeImmediatelyResponseRuntype = r.Record({
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    tax: MonetaryValueBackendRuntype.nullable(),
    payment_processor: PaymentProcessorsRuntype,
    payment_id: r.String,
    public_key: r.String,
    client_secret: r.String,
});

export const OrderDetailsChargeImmediatelyResponseRuntype = r.Record({
    type: r.Literal('ChargeImmediately'),
    data: ChargeImmediatelyResponseRuntype,
});

export const OrderDetailsSendInvoiceResponseRuntype = r.Record({
    type: r.Literal('SendInvoice'),
    data: r.Record({
        data: SalesOrderResponseRuntype,
    }),
});

export const OrderDetailsResponseRuntype = OrderDetailsChargeImmediatelyResponseRuntype.Or(
    OrderDetailsSendInvoiceResponseRuntype,
);

export type OrderDetailsChargeImmediatelyResponseDTO = r.Static<typeof OrderDetailsChargeImmediatelyResponseRuntype>;
export type OrderDetailsSendInvoiceResponseDTO = r.Static<typeof OrderDetailsSendInvoiceResponseRuntype>;

export const PaymentCredentialsRuntype = r.Record({
    payment_processor: PaymentProcessorsRuntype,
    public_key: r.String,
    private_key_hint: r.String,
});

export const PatchPaymentCredentialsRuntype = r.Record({
    payment_processor: PaymentProcessorsRuntype,
    public_key: r.String,
    private_key: r.String,
});
