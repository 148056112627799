import { Trans } from '@lingui/macro';
import { Column, FieldNumericController } from '@luminovo/design-system';
import { InputAdornment } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnStdFactoryLeadTime: Column<TableQuotationOfferItem> = selectableColumn({
    attribute: 'standardFactoryLeadTime',
    width: 100,
    label: <Trans>Lead time</Trans>,
    render: ({ extracted }) => {
        return `${extracted.value} ${extracted.unit}`;
    },
    InputComponent: ({ control }) => {
        const unit = useWatch({ control }).standardFactoryLeadTime?.unit;

        return (
            <>
                <FieldNumericController
                    control={control}
                    name="standardFactoryLeadTime.value"
                    FieldProps={{
                        autoFocus: true,
                        fullWidth: true,
                        placeholder: '0',
                        InputProps: {
                            endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
                        },
                    }}
                    required
                    min={0}
                />
            </>
        );
    },
});
