export const getFileName = (str: string): string => {
    // extract file name according to RFC 6266
    // https://datatracker.ietf.org/doc/html/rfc6266
    // > Therefore, when both
    // > "filename" and "filename*" are present in a single header field
    // > value, recipients SHOULD pick "filename*" and ignore "filename".
    const regFilename = new RegExp(`filename="(:?.*)"`);
    const regFilenameUtf8 = new RegExp(`filename\\*=UTF-8''(:?.*)`);
    const matchFilename = regFilename.exec(str);
    const matchFilenameUtf8 = regFilenameUtf8.exec(str);
    if (matchFilenameUtf8 !== null) {
        const filenameUtf8URIencoded = matchFilenameUtf8[1];
        const filenameUtf8 = decodeURIComponent(filenameUtf8URIencoded);
        return filenameUtf8;
    } else if (matchFilename !== null) {
        const filename = matchFilename[1];
        return filename;
    } else {
        return '';
    }
};

export const getNormalizedFileName = (response: Response): string => {
    const contentDisposition = response.headers.get('content-disposition');
    return contentDisposition ? getFileName(contentDisposition) : '';
};
