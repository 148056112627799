import { compareByString, isEqual, uniqWith } from '@luminovo/commons';
import { SingleOriginalExcelRow } from '@luminovo/http-client';
import {
    DuplicatedDesignatorsBomImporterIssue,
    IssueNameEnum,
} from '../../../resources/bomImporter/bomImporterIssuesEnum';

export const consolidateDuplicatedDesignators = (
    excelRows: SingleOriginalExcelRow[],
): DuplicatedDesignatorsBomImporterIssue | undefined => {
    const duplicatedDesignatorsIssues = excelRows
        .flatMap((row) => row.issues)
        .filter((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND);

    const duplicatedDesignators = duplicatedDesignatorsIssues
        .map((issue) => {
            if (issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND) {
                return [issue.content.duplicatedDesignators.sort(compareByString)];
            } else {
                return [];
            }
        })
        .flat(1);

    const duplicatedDesignatorsPerLine = uniqWith(duplicatedDesignators, isEqual);

    if (
        duplicatedDesignatorsIssues.length > 0 &&
        duplicatedDesignatorsIssues[0].name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND
    ) {
        return {
            level: duplicatedDesignatorsIssues[0].level,
            name: duplicatedDesignatorsIssues[0].name,
            content: {
                duplicatedDesignatorsPerLine,
            },
        };
    }

    return undefined;
};
