import * as r from 'runtypes';
import { BACKEND_HOST } from '../../const';
import { endpoint } from '../http/endpoint';
import { PublicTenantRuntype } from './tenantBackendTypes';

export const tenantEndpoints = {
    'GET /tenant/details': endpoint({
        description: 'Public endpoint to get details of a tenant',
        pathParams: r.Undefined,
        queryParams: r.Record({
            tenant: r.String,
        }),
        responseBody: PublicTenantRuntype,
        requestBody: r.Undefined,
        rootUrl: BACKEND_HOST + '/public',
    }),
};
