import { Parser } from '../parsers/Parser';
import { string } from '../parsers/string';

interface ManufacturerNameProps {
    manufacturerNames: Set<string>;
}

export function manufacturerName({ manufacturerNames }: ManufacturerNameProps): Parser<string> {
    return string({ minLength: 1, maxLength: 100 }).warnIf((name) => {
        if (!manufacturerNames.has(name.toLowerCase())) {
            return `Unknown manufacturer name`;
        }
    });
}
