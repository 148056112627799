import { i18n, MessageDescriptor, Messages } from '@lingui/core';
import { defineMessage } from '@lingui/macro';

/**
 * This enum should be keep in sync the the `LanguageEnumRuntype` from `@luminovo/http-client`
 */
export type LanguageEnum = 'en' | 'de' | 'es' | 'fr';

export type LocaleConfig = {
    locale: LanguageEnum;
    messages: Messages;
};

export const languageTranslations: Record<LanguageEnum, MessageDescriptor> = {
    en: defineMessage({ message: 'English' }),
    de: defineMessage({ message: 'German' }),
    fr: defineMessage({ message: 'French' }),
    es: defineMessage({ message: 'Spanish' }),
};

export function detectLocale(): string {
    // Option 1: if navigator.languages is available,
    // pick the first language, which should be the user's preferred language
    if (navigator.languages && Array.isArray(navigator.languages) && navigator.languages[0]) {
        return navigator.languages[0];
    }

    // Option 2: if navigator.languages is not present, use navigator.language
    // which is the language of the browser's UI
    if (typeof navigator.language === 'string') {
        return navigator.language;
    }
    // Finally, default to english.
    return 'en';
}

export function determineLanguageEnum(anyLocaleString: string): LanguageEnum {
    switch (anyLocaleString) {
        case 'en':
            return 'en';
        case 'de':
            return 'de';
        case 'es':
            return 'es';
        case 'fr':
            return 'fr';
        default:
            return 'en';
    }
}
/**
 * Updated the i18n object of the @luminovo/commons
 */
export const activateLocaleCommons = ({ locale, messages }: LocaleConfig): void => {
    i18n.load(locale, messages);
    i18n.activate(locale);
};

export const getLocale: () => LanguageEnum = () => determineLanguageEnum(i18n.locale);

export const isGermanLocale: () => boolean = () => getLocale() === 'de';

export const detectLanguageEnum: () => LanguageEnum = () => determineLanguageEnum(detectLocale());
