import * as r from 'runtypes';

const TemplateReferenceManufacturingScenarioTemplate = r.Record({
    type: r.Literal('ManufacturingScenarioTemplate'),
    data: r.String,
});

const TemplateReferenceAdditionService = r.Record({
    type: r.Literal('RfqAdditionalService'),
    data: r.String,
});

export const TemplateReferenceRuntype = r
    .Union(TemplateReferenceManufacturingScenarioTemplate, TemplateReferenceAdditionService)
    .nullable();

export type TemplateReferenceDTO = r.Static<typeof TemplateReferenceRuntype>;

export const ManufacturingScenarioTemplateInstanceRuntype = r.Record({
    id: r.String,
    manufacturing_scenario_id: r.String,
    template_reference: TemplateReferenceRuntype,
    activity_configuration_id: r.String.nullable(),
    expense_configuration_id: r.String.nullable(),
    rank: r.Number,
    created_at: r.String,
    updated_at: r.String,
});

export type ManufacturingScenarioTemplateInstanceDTO = r.Static<typeof ManufacturingScenarioTemplateInstanceRuntype>;
