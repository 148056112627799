import { Trans } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { ManufacturingScenarioOverviewDTO } from '@luminovo/http-client';
import { MenuItem, Typography } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import NestedTableMenu from '../../../../../../components/NestedTableMenu';
import { usePageParams } from '../../../../../../resources/hooks';
import { setAnchorElementAsNullAndStopPropagation } from '../../../../../../utils/anchor';
import {
    DeleteManufacturingScenarioConfirmationDialog,
    useDeleteManufacturingScenario,
} from '../../../../DeleteManufacturingScenario';
import { useDuplicateManufacturingScenarioDialog } from '../../../../ManufacturingScenario/ManufacturingScenarioForm/useDuplicateManufacturingScenarioDialog';
import { useEditManufacturingScenarioDialog } from '../../../../ManufacturingScenario/ManufacturingScenarioForm/useEditManufacturingScenarioDialog';

interface NestedTableProps {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickEdit: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
}

const NestedTable: React.FunctionComponent<NestedTableProps> = ({
    onClickDelete,
    onClickEdit,
    onClickDuplicate,
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementAsNullAndStopPropagation(event, setAnchorEl);
    }, []);

    return (
        <NestedTableMenu open={open} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose}>
            <MenuItem
                onClick={(event) => {
                    event.stopPropagation();
                    onClickEdit(event);
                }}
            >
                <Typography variant="h4" style={{ color: colorSystem.neutral[6] }}>
                    <Trans>Edit</Trans>
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={(event) => {
                    event.stopPropagation();
                    onClickDuplicate(event);
                }}
            >
                <Typography variant="h4" style={{ color: colorSystem.neutral[6] }}>
                    <Trans>Duplicate</Trans>
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={(event) => {
                    event.stopPropagation();
                    onClickDelete(event);
                }}
            >
                <Typography variant="h4" style={{ color: colorSystem.red[6] }}>
                    <Trans>Delete</Trans>
                </Typography>
            </MenuItem>
        </NestedTableMenu>
    );
};

export const ActionMenu = ({
    manufacturingScenario,
    assemblyId,
}: {
    manufacturingScenario: ManufacturingScenarioOverviewDTO;
    assemblyId: string;
}): JSX.Element => {
    /* eslint-disable-next-line camelcase */
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    const { mutateAsyncDeleteManufacturingScenario: deleteOnClick } = useDeleteManufacturingScenario(
        /* eslint-disable-next-line camelcase */
        manufacturingScenario.id,
    );
    const { rfqId } = usePageParams<'/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId'>();

    const { openDialog: openEditDialog } = useEditManufacturingScenarioDialog({
        rfqId,
        /* eslint-disable camelcase */
        assemblyId,
        manufacturingScenario: {
            id: manufacturingScenario.id,
            name: manufacturingScenario.name,
            notes: manufacturingScenario.notes ?? undefined,
            batchSizes: manufacturingScenario.batch_sizes,
            sourcingScenarioId: manufacturingScenario.sourcing_scenario?.id,
        },
        /* eslint-enable camelcase */
    });

    const { openDialog: openDuplicateDialog } = useDuplicateManufacturingScenarioDialog({
        rfqId,
        /* eslint-disable camelcase */
        assemblyId,
        manufacturingScenario,
        /* eslint-enable camelcase */
    });
    return (
        <>
            <NestedTable
                onClickDelete={() => setIsDialogOpen(true)}
                onClickEdit={openEditDialog}
                onClickDuplicate={openDuplicateDialog}
            />
            <DeleteManufacturingScenarioConfirmationDialog
                isDialogOpen={isDialogOpen}
                closeDialog={() => setIsDialogOpen(false)}
                deleteOnClick={() => deleteOnClick({ shouldInvalidateTableCache: true })}
            />
        </>
    );
};
