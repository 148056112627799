import { ActivityLevel, FormulaDetails } from '@luminovo/http-client';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { getFormulaDetailsFromTimeCalculation } from './getFormulaOfActivity';

export const useFormulaOfAnActivity = (
    activityId: string,
    timeLevel: ActivityLevel,
): { isLoading: boolean; formulaDetails: FormulaDetails | undefined } => {
    const { data, isLoading } = useHttpQuery('GET /activities/:activityId', { pathParams: { activityId } });

    return {
        isLoading,
        formulaDetails: data?.data.time_calculation
            ? getFormulaDetailsFromTimeCalculation(data.data.time_calculation, timeLevel)
            : undefined,
    };
};
