/* eslint-disable spellcheck/spell-checker */
/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { SupplierTermsCreationDTO } from '@luminovo/http-client/src/resources/supplierTerms';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import DialogBox from '../../../../components/dialogBox/DialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { SupplierTermForm, SupplierTermFormState } from './SupplierTermForm';

export const useDialogAddSupplierTerm = (supplierId: string) => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync: createSupplierTerms } = useHttpMutation('POST /supplier/:supplierId/terms', {
        snackbarMessage: t`Terms added successfully`,
        onSuccess: closeDialog,
    });

    const onSubmit = async (formValues: SupplierTermFormState) => {
        const requestBody: SupplierTermsCreationDTO = {
            site: formValues.site && formValues.site.id !== 'all-sites' ? formValues.site.id : null,
            incoterms: formValues.incoterms,
            incoterms_named_place:
                formValues.named_place && formValues.named_place !== '' ? formValues.named_place : null,
            net_payment_due_days:
                formValues.payment_terms.net_payment_due_days && formValues.payment_terms.net_payment_due_days !== 0
                    ? formValues.payment_terms.net_payment_due_days
                    : null,
            early_payment_due_days:
                formValues.payment_terms.early_payment_due_days && formValues.payment_terms.early_payment_due_days !== 0
                    ? formValues.payment_terms.early_payment_due_days
                    : null,
            early_payment_discount_percent:
                formValues.payment_terms.early_payment_discount && formValues.payment_terms.early_payment_discount !== 0
                    ? formValues.payment_terms.early_payment_discount
                    : null,
        };

        await createSupplierTerms({ requestBody, pathParams: { supplierId } });
    };

    const dialog = (
        <DialogBox maxWidth="sm" title={t`Add supplier terms`} isDialogOpen={true} onReject={closeDialog}>
            <SupplierTermForm onSubmit={onSubmit} handleClose={closeDialog} />
        </DialogBox>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
