import { CloseRounded } from '@mui/icons-material';
import { Chip as MuiChip, ChipProps as MuiChipProps, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

export interface ChipProps extends Omit<MuiChipProps, 'color'> {
    label: React.ReactNode;
    color: keyof typeof colorSystem;
    startIcon?: React.ReactElement;
    showEllipsis?: boolean;
}

const StyledChip = styled(MuiChip)({
    height: 'fit-content',
    minHeight: 20,
    borderRadius: 24,
    '& .MuiChip-deleteIconSmall': {
        paddingRight: '4px',
        marginRight: '0px',
        marginLeft: '-2px',
    },
});

const ChipContent: React.FC<Pick<ChipProps, 'label' | 'startIcon' | 'showEllipsis' | 'disabled'>> = ({
    label,
    startIcon,
    showEllipsis,
    disabled,
}) => (
    <Flexbox alignItems="center" gap={4}>
        {startIcon && (
            <Text
                style={{
                    fontSize: '16px',
                    lineHeight: '0px',
                    color: colorSystem.neutral[7],
                    marginLeft: '-2px',
                }}
            >
                {startIcon}
            </Text>
        )}
        <Text
            variant="body-small"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            color={disabled ? colorSystem.neutral[6] : colorSystem.neutral[9]}
            showEllipsis={showEllipsis}
        >
            {label}
        </Text>
    </Flexbox>
);

const getBackgroundColor = (color: ChipProps['color']) =>
    color === 'neutral' ? colorSystem.neutral[2] : colorSystem[color][3];

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
    ({ label, color, startIcon, onDelete, showEllipsis, deleteIcon, disabled, ...muiChipProps }, ref) => {
        const backgroundColor = getBackgroundColor(color);

        return (
            <StyledChip
                ref={ref}
                label={
                    <ChipContent label={label} startIcon={startIcon} showEllipsis={showEllipsis} disabled={disabled} />
                }
                onDelete={onDelete}
                deleteIcon={onDelete ? <CloseRounded style={{ color: colorSystem.neutral[7] }} /> : deleteIcon}
                disabled={disabled}
                {...muiChipProps}
                style={{
                    background: backgroundColor,
                    ...muiChipProps.style,
                }}
            />
        );
    },
);
