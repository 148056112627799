import { colorSystem, Column, DataTable, Row, useDataTableState } from '@luminovo/design-system';
import { styled, TableCell } from '@mui/material';
import { LineItem } from '../types/types';

const createColumns = <TRow extends LineItem>(items: TRow[]): Column<TRow>[] => {
    if (items.length === 0) return [];
    const keys = Object.keys(items[0]);

    return keys.map((key) => ({
        id: key,
        label: key,
        render: ({ data }: Row<TRow>) => <TableCell>{data[key]}</TableCell>,
    }));
};

export function ColumnMappingTable<T extends LineItem>({ items }: { items: T[] }): JSX.Element {
    const columns: Column<T>[] = createColumns(items);

    const dataTableState = useDataTableState({
        persistenceId: 'pnp-importer-column-mapping-table',
        items,
        columns,
        paginationOptions: {
            showPagination: true,
            defaultRowsPerPage: 200,
        },
    });
    return (
        <StyledTable>
            <DataTable stickyHeader={true} tableState={dataTableState} size={'medium'} />
        </StyledTable>
    );
}

const StyledTable = styled('table')({
    '& td': {
        borderRight: `1px solid ${colorSystem.neutral[2]}`,
    },
    '& th': {
        borderRight: `1px solid ${colorSystem.neutral[2]}`,
    },
});
