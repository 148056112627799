import { t, Trans } from '@lingui/macro';
import { Column, DataTable, Flexbox, Message, NonIdealState, Text, useDataTableState } from '@luminovo/design-system';
import { CustomerDTO, CustomerPartNumber } from '@luminovo/http-client';
import { Skeleton, styled, Table, TableCell, Tooltip } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHasCustomers } from '../../../utils/featureFlags';
import { getSortedCpns } from './getSortedCpns';

type CpnsTableContext = {
    customersData: CustomerDTO[] | undefined;
};

const ColumnCustomer: Column<CustomerPartNumber, CpnsTableContext> = {
    id: 'customer',
    label: <Trans>Customer</Trans>,
    render: ({ data: cpn }, { customersData }) => {
        const customer = customersData?.find((customer) => customer.id === cpn.customer);
        const label = `${customer?.name} ${customer?.customer_number && `• ${customer?.customer_number}`}`;
        if (!customer) {
            return (
                <TableCell>
                    <Skeleton />
                </TableCell>
            );
        }
        return (
            <Tooltip title={label.length > 85 ? label : ''}>
                <TableCell
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '400px', overflow: 'hidden' }}
                >
                    <Text variant="body-small">{label}</Text>
                </TableCell>
            </Tooltip>
        );
    },
};

const ColumnCompany: Column<CustomerPartNumber, CpnsTableContext> = {
    id: 'company',
    label: <Trans>Company</Trans>,
    render: ({ data: cpn }, { customersData }) => {
        const customer = customersData?.find((customer) => customer.id === cpn.customer);
        if (!customer) {
            return (
                <TableCell>
                    <Skeleton />
                </TableCell>
            );
        }
        return (
            <TableCell>
                <Text variant="body-small">
                    {customer?.name} {customer?.customer_number && `• ${customer?.customer_number}`}
                </Text>
            </TableCell>
        );
    },
};

const ColumnCpn: Column<CustomerPartNumber, CpnsTableContext> = {
    id: 'cpn',
    label: <Trans>CPN</Trans>,
    render: ({ data: cpn }) => {
        return (
            <TableCell>
                <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                    {cpn.value}
                </Text>
            </TableCell>
        );
    },
};

const ColumnRevision: Column<CustomerPartNumber, CpnsTableContext> = {
    id: 'revision',
    label: <Trans>Revision</Trans>,
    render: ({ data: cpn }) => {
        return (
            <TableCell>
                <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                    {cpn.revision}
                </Text>
            </TableCell>
        );
    },
};

const NoResultsComponent = () => {
    return (
        <Flexbox justifyContent="center">
            <NonIdealState
                title={<Trans>No CPNs found</Trans>}
                description={<Trans>We couldn't find any CPNs for this IPN.</Trans>}
            />
        </Flexbox>
    );
};

export const CpnsTable = ({
    cpns,
    isRestrictedToCustomers,
}: {
    cpns: CustomerPartNumber[];
    isRestrictedToCustomers: boolean;
}) => {
    const { data: customersData } = useHttpQuery('GET /customers', {}, { select: (data) => data.data });
    const { hasCustomers } = useHasCustomers();

    const sortedCpns = getSortedCpns({ cpns, customerData: customersData });
    const hasCpns = sortedCpns.length > 0;

    const sharedContext: CpnsTableContext = {
        customersData,
    };

    const cpnsTableState = useDataTableState({
        columns: hasCustomers
            ? [ColumnCustomer, ColumnCpn, ColumnRevision]
            : [ColumnCompany, ColumnCpn, ColumnRevision],
        items: sortedCpns,
        persistenceId: 'cpnsTableState',
        sharedContext,
    });

    return (
        <Flexbox flexDirection="column" gap="32px">
            <DataTable
                size="small"
                tableState={cpnsTableState}
                overrides={{ NoResultsComponent, Table: StyledTable }}
            />

            {!hasCpns && isRestrictedToCustomers && (
                <Message
                    size="large"
                    attention="low"
                    variant="yellow"
                    title={t`No customer part numbers linked to this IPN.`}
                    message={
                        <Flexbox gap={8} flexDirection="column">
                            <Text variant="body-small">
                                <Trans>
                                    This can happen when no CPN data was provided in the synchronised ERP data or
                                    customers of provided CPNs could not be found or created.
                                </Trans>
                            </Text>
                            <Text variant="body-small">
                                <Trans>
                                    Because the IPN is restricted but has no linked CPNs, this IPN cannot be used in any
                                    project.
                                </Trans>
                            </Text>
                        </Flexbox>
                    }
                />
            )}
        </Flexbox>
    );
};

const StyledTable = styled(Table)({
    overflow: 'visible',
    minWidth: '480px',
});
