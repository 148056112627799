import { matchPath } from 'react-router';
import { allRoutes } from './allRoutes';
import { LumiQuoteRoute } from './routes';

//returns undefined if the route is not found.
export const findRouteId = (pathname: string): LumiQuoteRoute | undefined => {
    const foundRoute = allRoutes().find((route) => {
        return matchPath(pathname, route.routeId)?.isExact === true;
    });
    return foundRoute?.routeId;
};
