import { minBy } from '@luminovo/commons';
import { BoundingBox } from './boundingBox';
import { Attribute, Region } from './types';

// TODO: It might be nice to include the distance in the return value so that it can be used to influence our confidence
export function findNearestTopLeftRegion<T extends Attribute>(
    regionToFindNearestFor: Region<T>,
    regionCandidates: { region: Region<T>; attribute: T }[],
    maxDistance: number,
): { region: Region<T>; attribute: T; distance: number } | undefined {
    const virtualBoxToFindNearestFor = getVirtualBox(regionToFindNearestFor);
    // Add distance and virtualBox to regions
    const regionsWithAttributeAndDistance = regionCandidates
        .map((regionWithAttribute) => {
            const virtualBox = getVirtualBox(regionWithAttribute.region);
            return {
                regionWithAttribute,
                virtualBox,
                distance: virtualBox.yDistanceOfCenters(virtualBoxToFindNearestFor),
            };
        })
        // Filter out all regions that are not to the top left
        // or further than maxDistance away
        .filter((item) => {
            return item.virtualBox.isTopLeftOf(virtualBoxToFindNearestFor) && item.distance < maxDistance;
        })
        .map((item) => ({
            region: item.regionWithAttribute.region,
            attribute: item.regionWithAttribute.attribute,
            distance: item.distance,
        }));

    return minBy(regionsWithAttributeAndDistance, (item) => item.distance);
}

/**
 * Returns the bounding box positiion adjusted based on which page it is on.
 */
export function getVirtualBox<T extends Attribute>(region: Region<T>): BoundingBox {
    return new BoundingBox(region.box.x, region.box.y + (region.pageNumber - 1), region.box.width, region.box.height);
}
