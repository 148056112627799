import { formatCurrency } from '@luminovo/commons';
import { VolumeEstimate } from '@luminovo/http-client';

export function formatVolumeEstimate(volumeEstimate: VolumeEstimate): string {
    const { lower, upper, currency } = volumeEstimate;

    const lowerFormatted = lower ? formatCurrency(lower, currency, 'estimate') : null;
    const upperFormatted = upper ? formatCurrency(upper, currency, 'estimate') : null;

    if (lowerFormatted !== null && upperFormatted !== null) {
        return `${lowerFormatted} - ${upperFormatted}`;
    } else if (lowerFormatted !== null && upperFormatted === null) {
        return `≥ ${lowerFormatted}`;
    } else if (lowerFormatted === null && upperFormatted !== null) {
        return `≤ ${upperFormatted}`;
    } else {
        return '';
    }
}
