/* eslint-disable camelcase */
import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { QuoteTrackingDTORuntype, QuoteTrackingStateRuntype } from './quoteTrackingBackendTypes';

export const quoteTrackingEndpoints = {
    'GET /quote-tracking': endpoint({
        description: 'Returns a list of quote tracking items',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(QuoteTrackingDTORuntype) }),
    }),

    'GET /rfqs/:rfqId/quote-tracking': endpoint({
        description: 'Returns a list of quote tracking items of the RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(QuoteTrackingDTORuntype) }),
    }),

    'GET /quote-tracking/:id': endpoint({
        description: 'Returns a single quote tracking item',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: QuoteTrackingDTORuntype }),
    }),

    'PATCH /quote-tracking/:quoteTrackingId': endpoint({
        description: 'Updates a quote tracking',
        pathParams: r.Record({ quoteTrackingId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ state: QuoteTrackingStateRuntype }),
        responseBody: r.Unknown,
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),

    'DELETE /quote-tracking/:quoteTrackingId/offers': endpoint({
        description: 'Deletes all offers connected to a quote tracking item.',
        pathParams: r.Record({ quoteTrackingId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),

    'GET /quote-tracking/:id/additional-files/upload-link': endpoint({
        description: 'Returns an Azure url to upload the additional files given an quote tracking',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),

    'DELETE /quote-tracking/:id/additional-files': endpoint({
        description: 'Deletes a additional file given its file name and quote tracking id',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ file_name: r.String }),
        responseBody: r.String,
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),
};
