import { typeSafeObjectKeys } from '@luminovo/commons';
import { Attribute, AttributeObject, Attributes } from '../types';

export function attributeObjectToList(attributeObject: AttributeObject): Attribute[] {
    const result: Attribute[] = [];

    const keys: Attributes[] = typeSafeObjectKeys(attributeObject);

    for (const key of keys) {
        const value = attributeObject[key];
        if (value !== undefined) {
            // Given that we are filtering by type, we can safely cast the attribute to the type we are looking for.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            result.push({ attr: key, value } as Attribute);
        }
    }
    return result;
}
