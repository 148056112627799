import { t } from '@lingui/macro';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Capability, createInitialPcbFormValues, usePcbCapabilities } from '../../../../resources/pcb/pcbFunctions';
import { useExtractedPcbCapabilities } from '../../components/PcbAnalysis/utils/usePcbPdfAnalysis/useExtractedPcbCapabilities';
import { useShowPcbPdfAnalysisMessageModal } from '../../components/PcbAnalysis/utils/useShowPcbPdfAnalysisMessageModal';

const mergePcbCapabilities = ({
    extractedPcbCapabilities,
    analyzedPcbCapabilities,
}: {
    extractedPcbCapabilities: Capability[];
    analyzedPcbCapabilities: Capability[];
}): Capability[] => {
    // If the capability is present in extracted capabilities, use the extracted value
    // Otherwise, use the analyzed value
    const extractedMap = new Map(extractedPcbCapabilities.map((cap) => [cap.capabilityName, cap]));
    return analyzedPcbCapabilities.map(
        (analyzedCapability) => extractedMap.get(analyzedCapability.capabilityName) ?? analyzedCapability,
    );
};

export const usePcbFormData = ({ pcb }: { pcb: PCBV2 }) => {
    const { userAgreesToExtraction } = useShowPcbPdfAnalysisMessageModal(pcb.id);
    // Capabilities analyzed by server
    const analyzedPcbCapabilities = usePcbCapabilities({ pcb });
    const analyzedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: analyzedPcbCapabilities,
            }),
        [pcb, analyzedPcbCapabilities],
    );

    // Capabilities extracted from specification
    const extractedPcbCapabilities = useExtractedPcbCapabilities({
        pcb,
        pcbCapabilities: analyzedPcbCapabilities,
    });
    const mergedPcbCapabilities = React.useMemo(
        () =>
            mergePcbCapabilities({
                extractedPcbCapabilities: extractedPcbCapabilities,
                analyzedPcbCapabilities: analyzedPcbCapabilities,
            }),
        [extractedPcbCapabilities, analyzedPcbCapabilities],
    );

    const extractedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: mergedPcbCapabilities,
            }),
        [pcb, mergedPcbCapabilities],
    );

    const defaultValues = userAgreesToExtraction ? extractedValues : analyzedValues;
    const defaultPcbCapabilities = mergedPcbCapabilities;

    // Set the default values
    const useFormReturn = useForm({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const { reset, setError } = useFormReturn;

    React.useEffect(() => {
        const values = createInitialPcbFormValues({
            pcb,
            fields: userAgreesToExtraction ? mergedPcbCapabilities : analyzedPcbCapabilities,
        });

        if (Object.keys(values.board).length !== 0) reset(values);

        if (values.board.placementSide === 'none') {
            setError('board.placementSide', { type: 'required', message: t`Required` });
        }
    }, [
        pcb,
        defaultPcbCapabilities,
        reset,
        setError,
        userAgreesToExtraction,
        mergedPcbCapabilities,
        analyzedPcbCapabilities,
    ]);

    return {
        useFormReturn,
        pcbCapabilities: defaultPcbCapabilities,
        defaultValues,
        extractedValues,
        extractedPcbCapabilities,
    };
};
