import { t } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Box, Typography } from '@mui/material';

export const DriverCountLabel = ({
    automaticDriverCount,
}: {
    automaticDriverCount: number | undefined | string | null;
}): JSX.Element => (
    <Box display={'flex'}>
        <Typography variant="h4" color={'textSecondary'}>
            {t`Driver count` + '*'}
        </Typography>
        {automaticDriverCount !== undefined && automaticDriverCount !== null && (
            <Box ml={1}>
                <Typography variant="body1" color={'textSecondary'}>
                    {t`(Automatic driver count = ${formatDecimal(Number(automaticDriverCount))})`}
                </Typography>
            </Box>
        )}
    </Box>
);
