import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Comparable } from '@luminovo/design-system';
import { isCustomComponentFull, isCustomFullPart, isOtsComponentFull, isOtsFullPart } from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import React from 'react';
import { SolutionConfigurationsTableData } from './SolutionConfigurations/solutionConfigurationTypes';

export type FieldId = 'supplierName' | 'leadTime' | 'unitPrice' | 'effectiveTotalPrice';

export interface ChartSpec<TKeys, TDatum> {
    id: FieldId;
    title: JSX.Element;
    keys: TKeys[];
    groupBy: Array<{
        extractor: (data: SolutionConfigurationsTableData) => string;
        label: React.ReactNode;
        tooltip?: string;
    }>;
    map: (
        data: SolutionConfigurationsTableData,
        extractLabel: (item: SolutionConfigurationsTableData) => string,
    ) => TDatum;
    aggregate: (a: TDatum, b: TDatum) => TDatum;
    orderBy: Comparable<TDatum>;
    render: (props: {
        rfqId: string;
        data: TDatum[];
        keys: TKeys[];
        onSelectDatum: (datum: TDatum) => void;
    }) => JSX.Element | null;
}

function extractLabelDesignator(data: SolutionConfigurationsTableData): string {
    return `${data.designators.join(', ')}`;
}

export function extractLabelSupplier(data: SolutionConfigurationsTableData) {
    if (data.itemType === 'consigned' || data.solutionConfigurationSourcing.is_consigned) {
        return t`Consigned`;
    }
    if (data.itemType === 'customPart' || data.itemType === 'inventoryOffer' || data.itemType === 'marketOffer') {
        return data.supplierName;
    }
    if (data.itemType === 'solutionOnly' || data.itemType === 'unavailable' || data.itemType === 'unknown') {
        return t`No offers`;
    }
    return assertUnreachable(data);
}

function extractLabelManufacturer(data: SolutionConfigurationsTableData): string {
    const { selectedPart } = data;
    if (selectedPart === undefined) {
        return t`Unknown`;
    }
    if (isOtsFullPart(selectedPart)) {
        return selectedPart.manufacturer.name;
    }
    if (isCustomFullPart(selectedPart)) {
        return t`Custom`;
    }
    if (isCustomComponentFull(selectedPart)) {
        return t`Custom component`;
    }
    if (isOtsComponentFull(selectedPart)) {
        return 'IPN';
    }
    assertUnreachable(selectedPart);
}

function extractLabelPart(data: SolutionConfigurationsTableData): string {
    const { selectedPart } = data;
    if (selectedPart === undefined) {
        return t`Unknown`;
    }
    return formatPart(selectedPart);
}

export const defaultGroupingFunctions: ChartSpec<unknown, unknown>['groupBy'] = [
    { label: 'Designator', extractor: extractLabelDesignator },
    { label: <Trans>Manufacturer</Trans>, extractor: extractLabelManufacturer },
    { label: <Trans>Part</Trans>, extractor: extractLabelPart },
    { label: <Trans>Supplier</Trans>, extractor: extractLabelSupplier },
];
