import * as r from 'runtypes';
import { SourcingCombinationCostsRuntype } from './calculationBackendTypes';

export const FrozenScenarioCostRuntype = r.Record({
    id: r.String,
    created_at: r.String,
    calculation_assembly_details: r.String,
    frozen_cost: SourcingCombinationCostsRuntype,
});

export const FrozenScenarioCostPostRuntype = FrozenScenarioCostRuntype.omit('id', 'created_at');

export type FrozenScenarioCostDTO = r.Static<typeof FrozenScenarioCostRuntype>;

export const FrozenSourcingCombinationCostsRuntype = r.Record({
    type: r.Literal('Frozen'),
    value: FrozenScenarioCostRuntype,
});

export const CalculatedResponseRuntype = r.Record({
    type: r.Literal('Calculated'),
    value: SourcingCombinationCostsRuntype,
});
export type CalculatedResponseDTO = r.Static<typeof CalculatedResponseRuntype>;

export const CostResponseRuntype = CalculatedResponseRuntype.Or(FrozenSourcingCombinationCostsRuntype);
export type CostResponseDTO = r.Static<typeof CostResponseRuntype>;
