import { BaseIconButtonProps, colorSystem, TertiaryIconButton } from '@luminovo/design-system';
import { styled } from '@mui/material';

const LightTertiaryIconButton = styled(TertiaryIconButton)({
    color: colorSystem.neutral[6],
    marginRight: 0,
});

const DarkTertiaryIconButton = styled(TertiaryIconButton)({
    color: colorSystem.neutral[6],
    marginRight: 0,
    '&:hover': {
        color: colorSystem.neutral[1],
        transition: 'all 0.5s ease',
    },
});

export function NavbarTertiaryIconButton({
    variant,
    children,
    ...props
}: React.PropsWithChildren<{ variant: 'light' | 'dark' } & BaseIconButtonProps>) {
    switch (variant) {
        case 'light':
            return <LightTertiaryIconButton {...props}>{children}</LightTertiaryIconButton>;
        case 'dark':
            return <DarkTertiaryIconButton {...props}>{children}</DarkTertiaryIconButton>;
    }
}
