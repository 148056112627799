import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { LocationOn } from '@mui/icons-material';

export function SupplierOrRecipientCell({ title }: { title: string }) {
    return (
        <Flexbox alignItems="center" gap={4}>
            <LocationOn style={{ fill: colorSystem.neutral[5], fontSize: '16px' }} />
            <Text variant="body-small">{title}</Text>
        </Flexbox>
    );
}
