import { MenuProps, Menu as MuiMenu, SxProps, Theme, styled } from '@mui/material';
import * as React from 'react';
import { BaseButtonProps } from './BaseButton';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';
import { TertiaryButton } from './TertiaryButton';

type ButtonAppearance = 'primary' | 'secondary' | 'tertiary' | 'destructivePrimary' | ' destructiveTertiary';
type Props = {
    id?: string;
    appearance?: ButtonAppearance;
    closeOnClick?: boolean;
    label?: string | JSX.Element;
    size?: BaseButtonProps['size'];
    icon?: JSX.Element;
    style?: BaseButtonProps['style'];
    disabled?: boolean;
    menuProps?: Partial<MenuProps>;
};

/**
 * A menu button is a button that opens up a menu when clicked on it.
 *
 * The children of the `MenuButton` are the `MenuItem`s.
 *
 * Example:
 *
 * ```tsx
 * <MenuButton label="click me">
 *      <MenuItem>option 1</MenuItem>
 *      <MenuItem>option 2</MenuItem>
 * </MenuButton>
 * ```
 */
export const MenuButton: React.FunctionComponent<Props> = ({ closeOnClick = true, ...props }): JSX.Element => {
    const { children, menuProps, ...buttonProps } = props;
    /**
     * A reference to the DOM element. This DOM element is used by the Menu's anchorEl property
     * to position the menu relative to the button.
     *
     * See https://reactjs.org/docs/hooks-reference.html#useref
     * See https://material-ui.com/api/menu/
     */
    const ref = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const handleClose = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    const handleClick = React.useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (closeOnClick) {
                setIsOpen(false);
            }
        },
        [closeOnClick],
    );
    const handleOpen = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
    }, []);

    const showAsIconButton = buttonProps.label === undefined && buttonProps.icon !== undefined;

    const iconButtonStyles: SxProps<Theme> = showAsIconButton
        ? {
              minWidth: 0,
              '& .MuiButton-startIcon': {
                  margin: '-4px',
              },
          }
        : {};

    function getButtonComponent(buttonProps: Props, ref: React.Ref<HTMLButtonElement>): JSX.Element {
        switch (buttonProps.appearance) {
            case 'primary': {
                return (
                    <PrimaryButton
                        ref={ref}
                        onClick={handleOpen}
                        disableTouchRipple
                        startIcon={buttonProps.icon}
                        sx={iconButtonStyles}
                        {...buttonProps}
                        style={{ whiteSpace: 'nowrap', ...buttonProps.style }}
                    >
                        {buttonProps.label}
                    </PrimaryButton>
                );
            }
            case 'secondary': {
                return (
                    <SecondaryButton
                        ref={ref}
                        onClick={handleOpen}
                        disableTouchRipple
                        startIcon={buttonProps.icon}
                        sx={iconButtonStyles}
                        {...buttonProps}
                        style={{ whiteSpace: 'nowrap', ...buttonProps.style }}
                    >
                        {buttonProps.label}
                    </SecondaryButton>
                );
            }
            case 'tertiary': {
                return (
                    <TertiaryButton
                        ref={ref}
                        onClick={handleOpen}
                        disableTouchRipple
                        startIcon={buttonProps.icon}
                        sx={iconButtonStyles}
                        {...buttonProps}
                        style={{ whiteSpace: 'nowrap', ...buttonProps.style }}
                    >
                        {buttonProps.label}
                    </TertiaryButton>
                );
            }
            default: {
                return (
                    <PrimaryButton
                        ref={ref}
                        onClick={handleOpen}
                        disableTouchRipple
                        startIcon={buttonProps.icon}
                        sx={iconButtonStyles}
                        {...buttonProps}
                        style={{ whiteSpace: 'nowrap', ...buttonProps.style }}
                    >
                        {buttonProps.label}
                    </PrimaryButton>
                );
            }
        }
    }

    return (
        <>
            <Menu
                anchorEl={ref.current}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 0, vertical: -8 }}
                elevation={1}
                onClick={handleClick}
                {...menuProps}
            >
                {children}
            </Menu>
            {getButtonComponent(buttonProps, ref)}
        </>
    );
};

const Menu = styled(MuiMenu)({
    '& .MuiList-padding': {
        padding: 0,
    },
});
