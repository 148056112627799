import * as r from 'runtypes';
import { DriverIdRuntype } from '../driver';

const BasicDriverCountRuntype = r.Record({
    type: r.Literal('Basic'),
    data: r.String,
});

export const PerScenarioDriverCountDataRuntype = r.Record({
    scenario_counts: r.Dictionary(r.String, r.String),
    count: r.String.nullable(),
});

const PerScenarioDriverCountRuntype = r.Record({
    type: r.Literal('PerScenario'),
    data: PerScenarioDriverCountDataRuntype,
});

/* eslint-disable camelcase */
export const AssemblyWideManuallyOverwrittenDriverCountRuntype = r.Record({
    id: r.String,
    manufacturing_assembly_details: r.String,
    driver: DriverIdRuntype,
    manual_driver_count: BasicDriverCountRuntype.Or(PerScenarioDriverCountRuntype),
});
/* eslint-enable camelcase */
