import { t } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CustomerPortalAssemblyState, CustomerPortalState } from '@luminovo/http-client';
import { compareBreadCrumbs } from '@luminovo/manufacturing-core';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Divider, Tooltip } from '@mui/material';
import React from 'react';
import { isEveryAssemblyStepDone } from '../../util/DashboardSteps';
import { BomStep } from './BomStep';
import { CadStep } from './CadStep';
import { ManufacturingStep } from './ManufacturingStep';
import { PcbStep } from './PcbStep';
import { PnpStep } from './PnpStep';

const AssemblyIconContainer = ({ color, children }: { color: string; children: JSX.Element }): JSX.Element => {
    return (
        <Flexbox
            width="35px"
            height="35px"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            bgcolor={color}
        >
            {children}
        </Flexbox>
    );
};

const AssemblyDoneIcon = (): JSX.Element => {
    return (
        <AssemblyIconContainer color={colorSystem.green[2]}>
            <CheckCircleOutlineRoundedIcon style={{ color: colorSystem.green[6] }} fontSize="medium" />
        </AssemblyIconContainer>
    );
};

const AssemblyNotDoneIcon = (): JSX.Element => {
    return (
        <AssemblyIconContainer color={colorSystem.neutral[2]}>
            <ErrorRoundedIcon style={{ color: colorSystem.neutral[5] }} fontSize="medium" />
        </AssemblyIconContainer>
    );
};

const CompletedStepCount = ({
    completedStepCount,
    countOfAllSteps,
}: {
    completedStepCount: number;
    countOfAllSteps: number;
}): JSX.Element => {
    return (
        <Flexbox>
            <Text variant="body-semibold" color={colorSystem.green[7]}>
                {completedStepCount}
            </Text>
            <Text variant="body" color={colorSystem.neutral[7]}>
                {`/${countOfAllSteps} ${t`completed`}`}
            </Text>
        </Flexbox>
    );
};

const BreadCrumbs = ({ breadCrumbs }: { breadCrumbs: string[] }): JSX.Element => {
    return (
        <Tooltip title={breadCrumbs.join(' / ')}>
            <Text
                variant="h2"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%' }}
            >
                {breadCrumbs.map((assemblyName, i) => (
                    <React.Fragment key={`${assemblyName}-${i}`}>
                        {assemblyName}
                        {i < breadCrumbs.length - 1 && (
                            <Text variant="h4" style={{ padding: '0 5px', verticalAlign: 'middle' }}>
                                /
                            </Text>
                        )}
                    </React.Fragment>
                ))}
            </Text>
        </Tooltip>
    );
};

const countSteps = (assemblyState: CustomerPortalAssemblyState) => {
    const completedStepCount = Object.values(assemblyState).filter((step) => {
        const isRequiredAndDone = step.requirement === 'Required' && step.status.type === 'Done';
        const isOptionalAndDone = step.requirement === 'Optional' && step.status.type === 'Done';

        return isRequiredAndDone || isOptionalAndDone;
    }).length;

    const countOfAllSteps = Object.values(assemblyState).filter((step) => {
        const isRequired = step.requirement === 'Required';
        const isOptionalAndInProgressOrDone =
            step.requirement === 'Optional' && (step.status.type === 'InProgress' || step.status.type === 'Done');
        return isRequired || isOptionalAndInProgressOrDone;
    }).length;

    return { completedStepCount, countOfAllSteps };
};

const CustomerDashboardAssemblySteps = ({
    assemblyState,
    rfqId,
    assemblyId,
    breadCrumbs,
    isRfqEditable,
}: {
    assemblyState: CustomerPortalAssemblyState;
    rfqId: string;
    assemblyId: string;
    breadCrumbs: string[];
    isRfqEditable: boolean;
}): JSX.Element => {
    /* eslint-disable spellcheck/spell-checker */
    const showBom = assemblyState.bom.requirement !== 'DontShow';
    const showPcb = assemblyState.pcb.requirement !== 'DontShow';
    const showCad = assemblyState.cad.requirement !== 'DontShow';
    const showManufacturing = assemblyState.manufacturing.requirement !== 'DontShow';
    const showPnp = assemblyState.pnp.requirement !== 'DontShow';

    const icon = isEveryAssemblyStepDone(assemblyState) ? <AssemblyDoneIcon /> : <AssemblyNotDoneIcon />;
    const { completedStepCount, countOfAllSteps } = countSteps(assemblyState);
    /* eslint-enable spellcheck/spell-checker */

    return (
        <Flexbox flexDirection="column" gap="12px" padding="24px">
            <Flexbox alignItems="center" gap="12px" whiteSpace="nowrap">
                <Flexbox flexShrink={0}>{icon}</Flexbox>
                <Flexbox overflow="hidden" width="100%">
                    <BreadCrumbs breadCrumbs={breadCrumbs} />
                </Flexbox>
                <Flexbox flexShrink={0}>
                    <CompletedStepCount completedStepCount={completedStepCount} countOfAllSteps={countOfAllSteps} />
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" paddingLeft="48px">
                <Divider />

                {showBom && (
                    <BomStep
                        bomState={assemblyState.bom}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        isRfqEditable={isRfqEditable}
                        requirement={assemblyState.bom.requirement}
                    />
                )}

                {showBom && (showPcb || showCad || showManufacturing || showPnp) && <Divider />}

                {showPcb && (
                    <PcbStep
                        pcbState={assemblyState.pcb}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        isRfqEditable={isRfqEditable}
                        requirement={assemblyState.pcb.requirement}
                    />
                )}

                {showPcb && (showCad || showManufacturing || showPnp) && <Divider />}

                {showCad && (
                    <CadStep
                        cadState={assemblyState.cad}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        isRfqEditable={isRfqEditable}
                        requirement={assemblyState.cad.requirement}
                    />
                )}

                {showCad && (showManufacturing || showPnp) && <Divider />}

                {showManufacturing && (
                    <ManufacturingStep
                        manufacturingState={assemblyState.manufacturing}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        isRfqEditable={isRfqEditable}
                        requirement={assemblyState.manufacturing.requirement}
                    />
                )}

                {showManufacturing && showPnp && <Divider />}

                {showPnp && (
                    <PnpStep
                        pnpState={assemblyState.pnp}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        isRfqEditable={isRfqEditable}
                        requirement={assemblyState.pnp.requirement}
                    />
                )}
            </Flexbox>
        </Flexbox>
    );
};

export const CustomerDashboardSteps = ({
    customerPortalState,
    rfqId,
    isRfqEditable,
}: {
    customerPortalState: CustomerPortalState;
    rfqId: string;
    isRfqEditable: boolean;
}): JSX.Element => {
    const sortedAssemblyStates = Object.entries(customerPortalState.assembly_states).sort(
        ([assemblyId1], [assemblyId2]) => {
            const breadCrumbs1 = customerPortalState.breadcrumbs[assemblyId1];
            const breadCrumbs2 = customerPortalState.breadcrumbs[assemblyId2];

            return compareBreadCrumbs(breadCrumbs1, breadCrumbs2);
        },
    );

    return (
        <Flexbox
            flexDirection="column"
            style={{ background: colorSystem.neutral.white }}
            borderRadius="8px"
            border={`1px solid ${colorSystem.neutral[3]}`}
        >
            {sortedAssemblyStates.map(([assemblyId, assemblyState], i) => (
                <React.Fragment key={assemblyId}>
                    {i > 0 && <Divider />}
                    <CustomerDashboardAssemblySteps
                        assemblyState={assemblyState}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        breadCrumbs={customerPortalState.breadcrumbs[assemblyId]}
                        isRfqEditable={isRfqEditable}
                    />
                </React.Fragment>
            ))}
        </Flexbox>
    );
};
