import { Box, BoxProps, styled } from '@mui/material';
import { colorSystem, focusedStyles, hoveredStyles } from '../../theme';

export interface InteractiveCardProps extends BoxProps {
    selected?: boolean;
    selectable?: boolean;
}

export function InteractiveCard({ selected, selectable = true, style, ...rest }: InteractiveCardProps): JSX.Element {
    const selectedStyles = selected ? focusedStyles : {};
    const notSelectableStyles = !selectable ? { cursor: 'default', border: `1px solid ${colorSystem.neutral[2]}` } : {};

    return (
        <Container
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.currentTarget.click();
                }
            }}
            tabIndex={selectable ? 0 : undefined}
            style={{
                ...selectedStyles,
                ...notSelectableStyles,
                ...style,
            }}
            {...rest}
        />
    );
}

const Container = styled(Box)({
    boxSizing: 'border-box',
    border: `1px solid ${colorSystem.neutral[2]}`,
    cursor: 'pointer',
    padding: '16px 20px',
    borderRadius: 8,
    background: colorSystem.neutral.white,
    '&:hover': {
        ...hoveredStyles,
    },
    '&:active': {
        ...focusedStyles,
    },
    '&:focus': {
        ...focusedStyles,
    },
    transition: 'background 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
});
