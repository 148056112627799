import { Trans, t } from '@lingui/macro';
import { DestructiveTertiaryButton, Flexbox, TertiaryButton, Text, TextField } from '@luminovo/design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { maxCharacterErrorMessage, notesMaxNumberOfCharacters } from '../../../../../../utils/yupValidationUtils';
import { BomItemFormState } from '../../../BomItemFormState';

export const EditNotes = ({
    setIsEditingNotes,
    submitForm,
}: {
    setIsEditingNotes: React.Dispatch<React.SetStateAction<boolean>>;
    submitForm: () => void;
}) => {
    const { control, resetField } = useFormContext<BomItemFormState>();

    return (
        <Flexbox flexDirection="column" gap="8px">
            <Flexbox gap="8px" justifyContent="space-between">
                <Text variant="h5">
                    <Trans>Notes</Trans>
                </Text>
                <Flexbox gap="8px">
                    <DestructiveTertiaryButton
                        size="medium"
                        onClick={() => {
                            setIsEditingNotes(false);
                            resetField('notes');
                        }}
                        style={{ padding: 0, height: 'unset', width: 'unset', minWidth: 'unset' }}
                    >
                        <Trans>Cancel</Trans>
                    </DestructiveTertiaryButton>
                    <TertiaryButton
                        size="medium"
                        onClick={() => {
                            submitForm();
                            setIsEditingNotes(false);
                        }}
                        style={{ padding: 0, height: 'unset', width: 'unset', minWidth: 'unset' }}
                    >
                        <Trans>Save</Trans>
                    </TertiaryButton>
                </Flexbox>
            </Flexbox>
            <Controller
                name="notes"
                control={control}
                rules={{
                    validate: (x: unknown): undefined | string => {
                        const isPresent = typeof x === 'string' && x.length > 0;
                        if (!isPresent) {
                            return;
                        }
                        if (x.length > notesMaxNumberOfCharacters)
                            return maxCharacterErrorMessage(notesMaxNumberOfCharacters);
                    },
                }}
                render={({ field }) => {
                    return (
                        <TextField
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            placeholder={t`Type to add notes`}
                            size="small"
                            multiline
                            style={{ width: '480px' }}
                        />
                    );
                }}
            />
        </Flexbox>
    );
};
