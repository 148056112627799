import { assertUnreachable, intersection, sortBy, uniq } from '@luminovo/commons';
import { BomItemApprovalStatus, DesignItemOriginDTO, DesignItemOriginTypes } from '@luminovo/http-client';
import { useMemo } from 'react';
import { indexFullPart } from '../../../../resources/part/indexFullPart';
import {
    AssemblyTableData,
    BomItemTableData,
    isAssemblyTableData,
    isBomItemTableData,
    ModuleTableData,
    useFilterModulesByAppliedFilters,
} from '../../../Bom/components/ModuleTableData';
import { FilterId } from '../../../Bom/components/ModuleTableData/filters';
import { ManufacturingIssues } from '../../../Bom/hooks/utils/bomIssues';
import { WarningSubCategoryBomItemIssues } from '../../countWarningsAcrossBomItems';

type FilterBomModulesProps = {
    modules?: ModuleTableData[];
    filters: {
        selectedStatus?: BomItemApprovalStatus;
        searchedText?: string;
        appliedFilters: Set<FilterId>;
        warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
        onlyShowItemsWithManufacturingWarnings: boolean;
    };
    filteredByDashboardFilters: ModuleTableData[];
};

const moduleTableDataOriginalRowNumber = (item: ModuleTableData): number => {
    // we always sort assemblies first
    if (item.moduleType === 'assembly') return 0;
    return (item.origin.originalRowNumbers ?? [])[0] ?? 0;
};

function indexAssemblyModule(module: AssemblyTableData): string[] {
    return [module.type, module.designator];
}

function indexDesignItemOrigin(designItemOrigin: DesignItemOriginDTO): string[] {
    if (designItemOrigin.type === DesignItemOriginTypes.ExcelFile) {
        return designItemOrigin.data.excel_lines.flatMap((line) =>
            Object.values(line.raw_original_line).flatMap((k) => (k ? k.split(' ') : '')),
        );
    }
    return [];
}

function indexBomItemModule(module: BomItemTableData): string[] {
    const data = [
        ...module.designator,
        module.notes,
        module.origin.type,
        ...module.individualDesignItems.flatMap((di) => indexDesignItemOrigin(di.origin)),
        ...module.parts.flatMap((p) => indexFullPart(p.part)),
    ];
    return data.filter((v) => v !== '');
}

function indexModuleTableData(module: ModuleTableData) {
    if (module.moduleType === 'bomItem') {
        return indexBomItemModule(module);
    } else if (module.moduleType === 'assembly') {
        return indexAssemblyModule(module);
    }
    assertUnreachable(module);
}

function isSearchMatch(module: ModuleTableData, searchText: string): boolean {
    const searchableText: string[] = indexModuleTableData(module);

    for (const text of searchableText) {
        if (text.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
    }
    return false;
}

function searchModules(modules: ModuleTableData[], searchText: string): ModuleTableData[] {
    return modules.filter((module) => isSearchMatch(module, searchText));
}

export const useFilterBomTableData = ({
    modules = [],
    filters: {
        selectedStatus,
        searchedText,
        appliedFilters,
        warningSubCategoryIssues,
        onlyShowItemsWithManufacturingWarnings,
    },
    filteredByDashboardFilters,
}: FilterBomModulesProps) => {
    const modulesFilteredByFilterId = useFilterModulesByAppliedFilters(modules, appliedFilters);

    const areDashboardFiltersSelected = appliedFilters.has(FilterId.HealthDashboardFilters);
    const result = useMemo(() => {
        return areDashboardFiltersSelected
            ? uniq([...modulesFilteredByFilterId, ...filteredByDashboardFilters])
            : modulesFilteredByFilterId;
    }, [modulesFilteredByFilterId, filteredByDashboardFilters, areDashboardFiltersSelected]);

    const filteredByStatus = useMemo(() => {
        return result.filter((module) => {
            if (!selectedStatus && warningSubCategoryIssues.size === 0) {
                return true;
            }
            if (isAssemblyTableData(module)) {
                return selectedStatus === module.approvalStatus;
            }
            if (isBomItemTableData(module)) {
                if (warningSubCategoryIssues.size === 0) return selectedStatus === module.approvalStatus;

                for (const issue of warningSubCategoryIssues) {
                    if (module.issues.includes(issue)) return true;
                }

                return false;
            }
            return assertUnreachable(module);
        });
    }, [result, selectedStatus, warningSubCategoryIssues]);

    const maybeFilterByManufacturingIssues = useMemo(() => {
        if (!onlyShowItemsWithManufacturingWarnings) return filteredByStatus;
        // This will only be active when a user is coming from the Manufacturing driver page
        return filteredByStatus.filter((module) => {
            if (isAssemblyTableData(module)) {
                return true;
            }
            const manufacturingIssuesInBomItem = intersection(module.issues, ManufacturingIssues);
            return manufacturingIssuesInBomItem.length > 0 || module.partDataIssues.length > 0;
        });
    }, [filteredByStatus, onlyShowItemsWithManufacturingWarnings]);

    return useMemo(() => {
        // indexing is expensive on slow cpus with large boms, so we avoid this when a user is adding a designator
        // which can slow down the UI.
        // As a permanent fix, we can either return these indexed keywords from the backend or perform the indexing in a worker thread in the FE.
        // For now, we only index when the user is searching for a bom-item.
        if (!searchedText) return sortBy(maybeFilterByManufacturingIssues, moduleTableDataOriginalRowNumber);
        const filteredBySearchedText = searchModules(maybeFilterByManufacturingIssues, searchedText ?? '');
        return sortBy(filteredBySearchedText, moduleTableDataOriginalRowNumber);
    }, [maybeFilterByManufacturingIssues, searchedText]);
};
