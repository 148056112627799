import { typeSafeObjectKeys } from '../typingUtils';
import { LumiQuoteRoute, RouteDefinition, routes } from './routes';

interface RouteInfo extends RouteDefinition {
    routeId: LumiQuoteRoute;
    page: string | undefined;
}

export interface PageInfo extends RouteInfo {
    page: string;
}

export function isPageInfo(routeInfo: RouteInfo): routeInfo is PageInfo {
    return routeInfo.page !== undefined;
}

export function allRoutes(): RouteInfo[] {
    return typeSafeObjectKeys(routes).map((routeId) => {
        const route: RouteDefinition = routes[routeId];
        return {
            page: route.page,
            routeId: routeId,
            ...routes[routeId],
        };
    });
}
