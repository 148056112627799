import { assertUnreachable } from '@luminovo/commons';
import { Availability, AvailabilityType } from '@luminovo/http-client';

/**
 * Returns the value of the lead time in days, or Infinity if the lead time is unknown.
 */
export function leadTimeDaysExtractor(availability: null | Availability): number {
    if (!availability) {
        return Infinity;
    }
    if (availability.type === AvailabilityType.Stock) {
        return 0;
    }
    if (availability.type === AvailabilityType.OnOrder || availability.type === AvailabilityType.LeadTime) {
        return availability.days;
    }
    if (availability.type === AvailabilityType.OnOrderWithUnknownLeadTime) {
        return Infinity;
    }
    assertUnreachable(availability);
}
