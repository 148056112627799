import { assertUnreachable } from '@luminovo/commons';
import { DemandScenarioDTO } from '@luminovo/http-client';

export const extractQuantityOfDemandScenario = (demandScenario: DemandScenarioDTO): number => {
    const demandScenarioType = demandScenario.type;
    switch (demandScenarioType) {
        case 'Prototype':
            return demandScenario.demands.reduce((acc, demand) => acc + demand.delivery_date.quantity, 0);
        case 'Series':
            return demandScenario.demands.reduce((acc, demand) => {
                return acc + demand.annual_demands.reduce((acc, demand) => acc + demand.quantity, 0);
            }, 0);
        default:
            assertUnreachable(demandScenarioType);
    }
};
