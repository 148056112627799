import { Close } from '@mui/icons-material';
import { Divider, styled, Tooltip } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Text } from '../../Text';
import { Actions, Argument, ParameterOption, SearchBlock } from '../types';
import { formatArgument } from '../util/formatArgument';
import { formatOperator } from '../util/formatOperator';
import { TEXT_SEARCH_FIELD_ID } from './constants';

export function SearchBlockView<T, TAutocompleteState>({
    block,
    dispatch = () => {},
    autocompleteState,
    parameter,
}: {
    block: SearchBlock<T>;
    autocompleteState?: TAutocompleteState;
    dispatch: React.Dispatch<Actions<T, TAutocompleteState>>;
    parameter?: ParameterOption<T, TAutocompleteState, Argument>;
}): JSX.Element {
    const operatorLabel = parameter?.ops.find((op) => op.op === block.op)?.label;

    if (block.field === TEXT_SEARCH_FIELD_ID) {
        return (
            <FilterContainer>
                <SectionParameter
                    onClick={() => {
                        dispatch({ type: 'removeSearchBlock', searchBlock: block });
                        dispatch({ type: 'onQueryChange', query: String(block.parameter) });
                    }}
                >
                    <Text variant="body-small">{block.parameter}</Text>
                </SectionParameter>

                <Separator orientation="vertical" />

                <SectionClose>
                    <Close
                        style={{ fontSize: 14, overflow: 'hidden' }}
                        onClick={() => {
                            dispatch({ type: 'removeSearchBlock', searchBlock: block });
                        }}
                    />
                </SectionClose>
            </FilterContainer>
        );
    }

    return (
        <FilterContainer>
            <SectionField>
                <Text variant="body-small">{parameter?.label ?? parameter?.field ?? block.field}</Text>
            </SectionField>

            <Separator orientation="vertical" />

            <SectionOperator
                onClick={() => {
                    dispatch({ type: 'editOperator', searchBlock: block });
                }}
            >
                <Text color={colorSystem.neutral[7]} variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                    {operatorLabel || formatOperator(block.op)}
                </Text>
            </SectionOperator>

            <Separator orientation="vertical" />

            <SectionParameter
                onClick={() => {
                    dispatch({ type: 'editParameter', searchBlock: block });
                }}
            >
                <Tooltip
                    title={
                        Array.isArray(block.parameter)
                            ? formatArgument(parameter, block.parameter, autocompleteState)
                            : ''
                    }
                >
                    <Text
                        variant="body-small"
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                        {formatArgument(parameter, block.parameter, autocompleteState)}
                    </Text>
                </Tooltip>
            </SectionParameter>

            <Separator orientation="vertical" />

            <SectionClose>
                <Close
                    style={{ fontSize: 14, overflow: 'hidden' }}
                    onClick={() => {
                        dispatch({ type: 'removeSearchBlock', searchBlock: block });
                    }}
                />
            </SectionClose>
        </FilterContainer>
    );
}

const FilterContainer = styled('span')({
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    background: `linear-gradient(${colorSystem.neutral[1]},${colorSystem.neutral[1]})`,
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 4,
    height: 24,
});

const FilterSection = styled('span')({
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background 0.2s linear',
    '&:hover': {
        background: colorSystem.neutral[2],
    },
    overflow: 'hidden',
    cursor: 'pointer',
});

const SectionField = styled('span')({
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    overflow: 'hidden',
});

const SectionOperator = styled(FilterSection)({
    color: colorSystem.neutral[7],
});

const SectionParameter = styled(FilterSection)({});

const SectionClose = styled(FilterSection)({
    color: colorSystem.neutral[6],
    padding: 4,
});

const Separator = styled(Divider)({
    margin: 0,
    height: 'calc(100% - 0px)',
});
