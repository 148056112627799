import { useFormContext, useWatch } from 'react-hook-form';
import { StandardPartQuoteFormValues } from '../types';

export function useConditionalValidation<T>(index: number, validation: T): T | undefined {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const isIncluded = useWatch({ control, name: `lineItems.${index}.included` });

    if (isIncluded) {
        return validation;
    }
    return undefined;
}
