import { t, Trans } from '@lingui/macro';
import { Message, SecondaryButton } from '@luminovo/design-system';

declare global {
    interface Window {
        // this is added by a script in the index.html
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Intercom: any;
    }
}

export const ManufacturingUpsellBanner = (): JSX.Element | null => {
    return (
        <Message
            attention="high"
            variant="primary"
            size="small"
            title={t`Unleash the full potential of Luminovo!`}
            message={t`To customise the manufacturing calculation upgrade your subscription by contacting your customer success manager.`}
            overrides={{
                ActionButton: () => (
                    <SecondaryButton onClick={showIntercom} size="medium">
                        <Trans>Upgrade</Trans>
                    </SecondaryButton>
                ),
            }}
        />
    );
};

export const showIntercom = (): void => {
    if (window.Intercom !== undefined) {
        window.Intercom('showNewMessage', t`Hi! I am interested in trying out the full version of manufacturing!`);
    }
};
