import { ErrorCode } from '../errorCodes';

export class HttpError extends Error {
    /**
     * The luminovo error code e.g.
     */
    public readonly code: ErrorCode;
    public readonly context: unknown | undefined;
    public readonly status: number;
    public readonly endpoint: string;
    public readonly sid: string | undefined;
    public readonly epibatorSha: string | undefined;
    public readonly luminovoVersion: string | undefined;
    public readonly method: string | undefined;

    constructor({
        code,
        status,
        statusText,
        endpoint,
        sid,
        epibatorSha,
        luminovoVersion,
        method,
    }: {
        code: ErrorCode;
        status: number;
        statusText: string;
        endpoint: string;
        sid?: string;
        epibatorSha?: string;
        luminovoVersion?: string;
        method?: string;
    }) {
        const lines = [`Got ${status} ${code !== 'unknown' ? code : statusText} when fetching`, `    ${endpoint}`];
        super(lines.join('\n\n'));
        this.code = code;
        this.status = status;
        this.sid = sid;
        this.endpoint = endpoint;
        this.epibatorSha = epibatorSha;
        this.luminovoVersion = luminovoVersion;
        this.method = method;
    }

    public getHoneycombLink(): string | undefined {
        return this.sid ? formatHoneycombLink(this.sid) : undefined;
    }
}

function formatHoneycombLink(sid: string): string {
    const filters = {
        time_range: 57600,
        granularity: 3600,
        breakdowns: [],
        calculations: [],
        filters: [{ column: 'sid', op: '=', value: sid }],
        filter_combination: 'AND',
        orders: [],
        havings: [],
        limit: 100,
    };

    const url = new URL(`https://ui.honeycomb.io/luminovo/environments/prod/datasets/backend-core`);
    url.searchParams.set('query', JSON.stringify(filters));

    return url.toString();
}
