import { isPresent } from '@luminovo/commons';
import { AwardScenarioDTO, NegotiationLineItem } from '@luminovo/http-client';
import { extractAwardedOffer } from './extractors/extractorNegotionsLineItem';

export function countNegotiationLineItemsWithoutOffers(
    lineItems: Array<NegotiationLineItem>,
    awardScenario: AwardScenarioDTO | null,
) {
    const awardScenarioOffers = lineItems
        .map((lineItem) => extractAwardedOffer(lineItem, awardScenario?.id))
        .filter((offer) => !isPresent(offer));
    return awardScenarioOffers.length;
}
