import { colorSystem, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';

export function Header({ title, subtitle = '' }: { title: string; subtitle?: string }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginY: 1,
                marginRight: 1,
                gap: 0.5,
                height: '36px',
                justifyContent: 'space-between',
            }}
        >
            <Text variant="body-small" color={colorSystem.neutral[8]}>
                {subtitle}
            </Text>
            <Text variant="h5" color={colorSystem.neutral[8]}>
                {title}
            </Text>
        </Box>
    );
}
