import { formatPercentage, FormatPercentageOptions } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { Box, BoxProps } from '@mui/material';

export function LabelPercentageChange({
    percent,
    formatPercentageOptions,
    ...props
}: { percent: number; formatPercentageOptions?: FormatPercentageOptions } & BoxProps) {
    if (isNaN(percent)) {
        return <>-</>;
    }

    const status = percent === 0 ? 'neutral' : percent > 0 ? 'positive' : 'negative';
    const { arrow, color } = {
        neutral: {
            arrow: '-',
            color: 'neutral' as const,
        },
        positive: {
            arrow: '↑',
            color: 'red' as const,
        },
        negative: {
            arrow: '↓',
            color: 'green' as const,
        },
    }[status];

    return (
        <Box
            {...props}
            sx={{
                whiteSpace: 'nowrap',
                display: 'inline-flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
                background: colorSystem[color][1],
                padding: '1px 2px',
                borderRadius: '4px',
                color: colorSystem[color][8],
                boxSizing: 'border-box',
                border: `1px solid ${colorSystem[color][4]}`,
                ...props.sx,
            }}
        >
            {arrow} {formatPercentage(Math.abs(percent), formatPercentageOptions)}
        </Box>
    );
}
