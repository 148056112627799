import { PCBGraphicOutline } from '@luminovo/http-client';

export const getInitialViewBoxCoordinates = (outline: PCBGraphicOutline) => {
    let scaleFactor: number = 1 / 100;

    if (outline && outline.graphic.format) {
        const f = outline.graphic.format;

        if (f.unit === 'in') {
            scaleFactor = 25.4 / f.scaling;
        } else {
            scaleFactor = 1 / f.scaling;
        }
    }

    const o = 1 / scaleFactor;

    const min = {
        x: outline.graphic.viewbox.min.x,
        y: outline.graphic.viewbox.min.y,
    };

    const max = {
        x: Math.max(outline.graphic.viewbox.min.x, outline.graphic.viewbox.max.x),
        y: Math.max(outline.graphic.viewbox.min.y, outline.graphic.viewbox.max.y),
    };

    return {
        x: min.x,
        y: min.y,
        width: max.x - min.x + o,
        height: max.y - min.y + o,
    };
};
