import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { AvnetUsaCredentialsInputDTORuntype, AvnetUsaResponseBodyDTORuntype } from './avnetUsaBackendTypes';

export const avnetUsaEndpoints = {
    'GET /3rdparty/avnetusa/credentials': endpoint({
        description: 'Returns credentials for Avnet USA integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AvnetUsaResponseBodyDTORuntype,
    }),
    'POST /3rdparty/avnetusa/credentials': endpoint({
        description: 'Uploads credentials for Avnet USA API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetUsaCredentialsInputDTORuntype,
        responseBody: AvnetUsaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetusa/credentials'],
    }),
    'PATCH /3rdparty/avnetusa/credentials': endpoint({
        description: 'Updates credentials for Avnet USA API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetUsaCredentialsInputDTORuntype,
        responseBody: AvnetUsaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetusa/credentials'],
    }),
    'DELETE /3rdparty/avnetusa/credentials': endpoint({
        description: 'Deletes existing credentials for Avnet USA API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/avnetusa/credentials'],
    }),
};
