import { Trans } from '@lingui/macro';
import { MaxWidthLayout, Text } from '@luminovo/design-system';
import React from 'react';
import { PageLayout } from '../../../../../../../components/PageLayout';
import { EditAssemblyForm } from '../../../../../../../modules/AssemblyForm';
import { ViewContext } from '../../../../../../../modules/Bom/components/ModuleTableData';
import { UrlParams } from '../../../../../../../utils/routes';

const EditAssemblyPage = (urlParams: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/edit'>): JSX.Element => {
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId: urlParams.pathParams.rfqId,
        };
    }, [urlParams.pathParams.rfqId]);
    return (
        <EditAssembly
            viewContext={viewContext}
            rfqId={urlParams.pathParams.rfqId}
            assemblyId={urlParams.pathParams.assemblyId}
            currentParentAssemblyId={urlParams.queryParams.currentParentAssemblyId}
        />
    );
};

export const EditAssembly = ({
    currentParentAssemblyId,
    rfqId,
    assemblyId,
    viewContext,
}: {
    currentParentAssemblyId: string | null | undefined;
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
}) => {
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Text variant="h1">
                    <Trans>Edit assembly</Trans>
                </Text>
                <EditAssemblyForm
                    viewContext={viewContext}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default EditAssemblyPage;
