import { t } from '@lingui/macro';
import { UserDriverDetailsDTO } from '@luminovo/http-client';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { usePageParams } from '../../../resources/hooks';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { makeDuplicatedDefaultName } from '../utils/makeDuplicatedDefaultName';
import { DriverForm, useDriverForm } from './DriverForm';
import { convertDriverDTOToFormValues } from './driverFormFunctions';
import { useNewLexoRank } from './utils';

export const DuplicateDriverForm = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { driverId } = usePageParams<'/manufacturing/driver/:driverId/duplicate'>();
    const { isLoading, drivers: driversData, packages } = useDriverForm();
    const drivers = driversData?.data;
    const driver = drivers?.find((driver) => driver.id === driverId);
    const existingDriverNames: string[] = drivers?.map((driver: UserDriverDetailsDTO) => driver.name) ?? [];

    const result = useNewLexoRank();
    const newDriverLexoRank = result?.lexoRank;

    if (isLoading || !drivers || !result) {
        return <SpinnerWithBackdrop />;
    }

    if (driver === undefined) {
        throw new Error('driver should not be undefined if isLoading is false');
    }

    if (newDriverLexoRank === undefined) {
        enqueueSnackbar({ message: t`Can't duplicate driver`, variant: 'error' });
        history.goBack();
    }

    return (
        <DriverForm
            defaultValues={{
                ...convertDriverDTOToFormValues(driver),
                name: makeDuplicatedDefaultName(driver.name, existingDriverNames),
                lexoRank: newDriverLexoRank,
            }}
            onSubmitType={'POST'}
            formTitle={capitalizeFirstLetter(t`Duplicate driver`)}
            packages={packages}
        />
    );
};
