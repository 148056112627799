import styled from '@emotion/styled';
import { TableContainer } from '@mui/material';
import React from 'react';
import { TableComponents } from 'react-virtuoso';
import { body, bodySmall, colorSystem, h4, h5 } from '../../theme';
import { TanStackTableContext } from './type';

export const DefaultScroller: TableComponents<unknown, TanStackTableContext<unknown>>['Scroller'] = React.forwardRef(
    ({ context, ...props }, ref) => {
        switch (context?.size) {
            case 'large':
                return <LargeContainer {...props} ref={ref} />;
            case 'medium':
                return <MediumContainer {...props} ref={ref} />;
            case 'small':
                return <SmallContainer {...props} ref={ref} />;
            default:
                return <MediumContainer {...props} ref={ref} />;
        }
    },
);

const DefaultContainer = styled(TableContainer)(() => ({
    minWidth: 'fit-content',
    width: 'inherit',
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 8,
    boxSizing: 'border-box',
    '& tr:last-child td': {
        border: 'none',
    },
    '& .MuiTableCell-root': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        '&:first-of-type': {
            paddingLeft: 16,
        },
        '&:last-child': {
            paddingRight: 16,
        },
    },
    '& .MuiTableRow-root': {
        borderBottom: `1px solid ${colorSystem.neutral[2]}`,
        '&:hover': {
            background: colorSystem.neutral[0],
        },
    },
    '& .MuiTableCell-head': {
        color: colorSystem.neutral[8],
        background: colorSystem.neutral[0],
        borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    },
    /**
     * NICE TO HAVE: Check why :horizontal is not working
     *               To enables individual styling of the horizontal and vertical scrollbars
     */
    '&::-webkit-scrollbar-track': {
        backgroundColor: colorSystem.neutral[1],
        borderRadius: '0 8px 0px 8px',
    },
    '&::-webkit-scrollbar-corner': {
        backgroundColor: colorSystem.neutral[1],
        borderRadius: '0 0 8px 0',
    },
    '&::-webkit-scrollbar': {
        right: 0,
        width: '10px',
        height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSystem.neutral[4],
        borderRadius: '8px',
        width: '10px',
        height: '10px',
    },
}));

const LargeContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '48px',
        ...h4,
    },
    '& .MuiTableCell-body': {
        height: '56px',
        ...body,
    },
}));

const MediumContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '48px',
        ...h5,
    },
    '& .MuiTableCell-body': {
        height: '48px',
        ...bodySmall,
    },
}));

const SmallContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '32px',
        ...h5,
    },
    '& .MuiTableCell-body': {
        height: '32px',
        ...bodySmall,
    },
}));
