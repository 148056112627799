import { sortBy } from '@luminovo/commons';
import { CustomerDTO, CustomerPartNumber } from '@luminovo/http-client';

/**
 *
 * @returns an array of cpns sorted by the customer name, the cpn and lastly the revision
 */
export function getSortedCpns({
    cpns,
    customerData,
}: {
    cpns: CustomerPartNumber[];
    customerData: CustomerDTO[] | undefined;
}): CustomerPartNumber[] {
    if (!customerData) {
        return cpns;
    }

    return sortBy(cpns, [
        (c) => {
            return customerData.find((customer) => customer.id === c.customer)?.name;
        },
        (c) => [c.value],
        (c) => [c.revision],
    ]);
}
