/* eslint-disable spellcheck/spell-checker */
/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, InteractiveCard, Text } from '@luminovo/design-system';
import { FullSourcingDTO } from '@luminovo/http-client';
import { formatAvailability } from '@luminovo/sourcing-core';
import { FiberManualRecord as CircleIcon, EqualizerRounded, TableChartOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { formatMonetaryValue } from '../../../../utils/formatMonetaryValue';
import { FieldId } from '../ChartSpec';
import { SolutionConfigurationsTableData } from '../SolutionConfigurations/solutionConfigurationTypes';

const SourcingScenarioSummaryBox: React.FunctionComponent<{
    selectedGraphId?: FieldId;
    setSelectedGraphId: (graphId: FieldId | undefined) => void;
    fullSourcingDTO?: FullSourcingDTO;
    tableData?: SolutionConfigurationsTableData[];
}> = ({ fullSourcingDTO, selectedGraphId, setSelectedGraphId, tableData }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'}>
            <Box display={'grid'} columnGap="16px" gridTemplateColumns="repeat(5,1fr)" width={'100%'}>
                <IndicatorBox
                    graphId={undefined}
                    title={t`Solution status`}
                    indicator={
                        fullSourcingDTO && (
                            <Flexbox alignItems={'center'} gap={4}>
                                <CircleIcon style={{ color: colorSystem.green[5], fontSize: 14 }} />
                                <Typography variant="h2">{fullSourcingDTO.status_count.number_of_ok}</Typography>

                                <span style={{ marginLeft: 20 }} />

                                <CircleIcon style={{ color: colorSystem.yellow[5], fontSize: 14 }} />
                                <Typography variant="h2">{fullSourcingDTO.status_count.number_of_warning}</Typography>

                                <span style={{ marginLeft: 20 }} />

                                <CircleIcon style={{ color: colorSystem.red[5], fontSize: 14 }} />
                                <Typography variant="h2">{fullSourcingDTO.status_count.number_of_error}</Typography>
                            </Flexbox>
                        )
                    }
                    setSelectedGraphId={setSelectedGraphId}
                    selectedGraphId={selectedGraphId}
                />

                <IndicatorBox
                    graphId="supplierName"
                    title={t`Suppliers`}
                    indicator={
                        tableData && formatDecimal(new Set(tableData.map((s) => s.supplierId).filter(isPresent)).size)
                    }
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="leadTime"
                    title={t`Lead time`}
                    indicator={fullSourcingDTO && formatAvailability(fullSourcingDTO.total_availability)}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="unitPrice"
                    title={t`Unit price`}
                    indicator={fullSourcingDTO && formatMonetaryValue(fullSourcingDTO.total_unit_price)}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="effectiveTotalPrice"
                    title={t`Total price`}
                    indicator={fullSourcingDTO && formatMonetaryValue(fullSourcingDTO.total_price)}
                    secondary={fullSourcingDTO && <LabelExcessMaterial fullSourcingDTO={fullSourcingDTO} />}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />
            </Box>
        </Flexbox>
    );
};

function LabelExcessMaterial({ fullSourcingDTO }: { fullSourcingDTO: FullSourcingDTO }) {
    if (!fullSourcingDTO.total_excess_material) {
        return <></>;
    }
    return (
        <Text>
            {formatMonetaryValue(fullSourcingDTO.total_excess_material)} <Trans>Excess</Trans>
        </Text>
    );
}

export default SourcingScenarioSummaryBox;

const IndicatorHeader: React.FunctionComponent<{
    title: string;
    graphId?: FieldId;
}> = ({ title, graphId }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'baseline'}>
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                {title}
            </Text>
            <Flexbox gap={4} alignItems={'center'}>
                <Text variant={'body'} color={colorSystem.neutral[6]}>
                    {isPresent(graphId) ? t`Graph` : t`Table`}
                </Text>
                {isPresent(graphId) ? (
                    <EqualizerRounded fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                ) : (
                    <TableChartOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                )}
            </Flexbox>
        </Flexbox>
    );
};

function IndicatorBox({
    title,
    indicator,
    secondary,
    graphId,
    setSelectedGraphId,
    selectedGraphId,
}: React.PropsWithChildren<{
    title: string;
    indicator?: string | JSX.Element;
    secondary?: JSX.Element;
    graphId?: FieldId;
    selectedGraphId?: FieldId;
    setSelectedGraphId: (graphId: FieldId | undefined) => void;
}>): JSX.Element {
    const isSelected = graphId === selectedGraphId;

    return (
        <InteractiveCard
            display={'flex'}
            flexDirection={'column'}
            style={{ gap: 8 }}
            selected={isSelected}
            onClick={() => setSelectedGraphId(selectedGraphId === graphId ? undefined : graphId)}
        >
            <IndicatorHeader title={title} graphId={graphId} />
            <Flexbox alignItems={'center'} gap={8}>
                {!isPresent(indicator) && <Skeleton width={'100%'} height={'30px'} />}
                {isPresent(indicator) && typeof indicator === 'string' ? (
                    <Typography variant="h2">
                        {indicator} {secondary}
                    </Typography>
                ) : (
                    indicator
                )}
            </Flexbox>
        </InteractiveCard>
    );
}
