import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { ActorResponseRuntypeDTO, SubAssemblyQuantityChangedDTO } from '@luminovo/http-client';
import { ActionPerformedBy, TimelineContentContainer, TimelineText } from './Utils';

type Props = {
    data: SubAssemblyQuantityChangedDTO;
    actor: ActorResponseRuntypeDTO;
};

export default function SubAssemblyQuantityChanged({ data, actor }: Props) {
    if (!isPresent(actor)) return null;

    return (
        <TimelineContentContainer>
            <TimelineText>
                <Trans>
                    Quantity of subassembly <strong>{data.designator}</strong> changed from{' '}
                    <strong>{data.old_quantity}</strong> to <strong>{data.new_quantity}</strong>
                </Trans>{' '}
                <ActionPerformedBy actor={actor} />
            </TimelineText>
        </TimelineContentContainer>
    );
}
