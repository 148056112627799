/* eslint-disable camelcase */
import * as r from 'runtypes';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type NewarkLocaleSiteDTO = r.Static<typeof NewarkLocaleSiteDTORuntype>;
const NewarkLocaleSiteDTORuntype = r.Union(r.Literal('US'), r.Literal('CA'), r.Literal('MX'));

export interface NewarkCredentialsDTO extends r.Static<typeof NewarkCredentialsDTORuntype> {}
export const NewarkCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String.optional(),
        customer_id: r.String.nullable().optional(),
        secret_key: r.String.nullable().optional(),
        store_location: NewarkLocaleSiteDTORuntype,
        list_price_currency: CurrencyRuntype,
        contract_price_currency: CurrencyRuntype,
        status: ApiIntegrationStatusRuntype,
    }),
);

export interface NewarkCredentialsInputDTO extends r.Static<typeof NewarkCredentialsInputDTORuntype> {}
export const NewarkCredentialsInputDTORuntype = r.Record({
    api_key: r.String,
    customer_id: r.String.nullable(),
    secret_key: r.String.nullable(),
    store_location: NewarkLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface NewarkSupportedSettingDTO extends r.Static<typeof NewarkSupportedSettingDTORuntype> {}
export const NewarkSupportedSettingDTORuntype = r.Record({
    store_location: NewarkLocaleSiteDTORuntype,
    list_price_currency: r.Array(CurrencyRuntype),
    contract_price_currency: r.Array(CurrencyRuntype),
});
