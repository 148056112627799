import { Trans } from '@lingui/macro';
import { compareByDate, formatToLongDate } from '@luminovo/commons';
import {
    CenteredLayout,
    Column,
    DataTable,
    Flexbox,
    NonIdealState,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { RfqStatus, UsedInAssemblyData } from '@luminovo/http-client';
import { CircularProgress, TableCell, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledRfqStatusBox } from '../../../../../components/StyledComponents';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useOtsFullPart } from '../../../../../resources/part/partHandler';
import { route } from '../../../../../utils/routes';

type UsedInTableContext = {
    mpn: string | undefined;
};

const NoResultsComponent = () => {
    return (
        <Flexbox justifyContent="center">
            <NonIdealState
                title={<Trans>No assemblies found</Trans>}
                description={<Trans>We couldn't find any assemblies where this part has been used.</Trans>}
            />
        </Flexbox>
    );
};

const columnAssembly: Column<UsedInAssemblyData, UsedInTableContext> = {
    id: `assembly`,
    label: <Trans>Assembly</Trans>,
    render: ({ data }, sharedContext) => (
        <TableCell>
            <StyledLink
                to={route(
                    '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                    { rfqId: data.rfq.id, assemblyId: data.id },
                    {
                        isReadonly: 'true',
                        designItemId: data.initial_matched_bom_item.ids.join(','),
                        filters: null,
                        bomTab: null,
                        dashboardFilters: null,
                        search: sharedContext.mpn ?? null,
                        onlyShowItemsWithManufacturingWarnings: null,
                        currentParentAssemblyId: null,
                    },
                )}
            >
                {data.designator}
            </StyledLink>
        </TableCell>
    ),
};

const columnCustomer: Column<UsedInAssemblyData, UsedInTableContext> = {
    id: `customer`,
    label: <Trans>Customer</Trans>,
    render: ({ data }) => {
        if (!data.customer) return <TableCellNoWrap>-</TableCellNoWrap>;
        return <TableCellNoWrap>{data.customer.name}</TableCellNoWrap>;
    },
};

const columnRfq: Column<UsedInAssemblyData, UsedInTableContext> = {
    id: `rfq`,
    label: <Trans>RfQ</Trans>,
    render: ({ data }) => {
        return <TableCellNoWrap>{data.rfq.name}</TableCellNoWrap>;
    },
};

const columnRfqStatus: Column<UsedInAssemblyData, UsedInTableContext> = {
    id: `rfqStatus`,
    label: <Trans>RfQ status</Trans>,
    render: ({ data }) => {
        return <TableCellNoWrap>{StyledRfqStatusBox({ rfqStatus: data.rfq.status })}</TableCellNoWrap>;
    },
    filters: [
        {
            id: 'status=request in draft',
            label: <Trans>Request in draft</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.RequestInDraft,
        },
        {
            id: 'status=quotation in progress',
            label: <Trans>Quotation in progress</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.QuotationInProgress,
        },
        {
            id: 'status=quotation available',
            label: <Trans>Quotation available</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.QuotationAvailable,
        },
        {
            id: 'status=no quotation',
            label: <Trans>No quotation</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.NoQuotation,
        },
        {
            id: 'status=no order',
            label: <Trans>No order</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.NoOrder,
        },
        {
            id: 'status=order confirmed',
            label: <Trans>Order confirmed</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.OrderConfirmed,
        },
        {
            id: 'status=order in production',
            label: <Trans>Order in production</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.OrderInProduction,
        },
        {
            id: 'status=order shipped',
            label: <Trans>Order shipped</Trans>,
            predicate: (data): boolean => data.rfq.status === RfqStatus.OrderShipped,
        },
    ],
};

const extractCreationTime = (data: UsedInAssemblyData): number => {
    return new Date(data.rfq.creation_date).getTime();
};
const columnCreationDate: Column<UsedInAssemblyData, UsedInTableContext> = {
    id: `creationData`,
    label: <Trans>Creation date</Trans>,
    render: ({ data }) => {
        return <TableCellNoWrap>{formatToLongDate(data.rfq.creation_date)}</TableCellNoWrap>;
    },
    comparators: [
        { id: 'creationDate.asc', label: <Trans>Ascending</Trans>, compareBy: extractCreationTime, order: 'asc' },
        {
            id: 'creationDate.desc',
            label: <Trans>Descending</Trans>,
            compareBy: extractCreationTime,
            order: 'desc',
        },
    ],
};

const columns = [columnAssembly, columnCustomer, columnRfq, columnRfqStatus, columnCreationDate];

export const UsedInTableData = ({ partId }: { partId: string }) => {
    const { data: otsFullPart } = useOtsFullPart({ partOptionId: partId, rfqContext: { type: 'OutsideRfQ' } });

    const { data: items = [], isLoading } = useHttpQuery(
        'POST /parts/off-the-shelf/assemblies/bulk',
        {
            requestBody: { ids: [partId], rfq_context: 'OutsideRfQ' },
        },
        { select: (res) => res.data[partId].sort(compareByDate((i) => i.rfq.creation_date, { type: 'descending' })) },
    );

    const tableState = useDataTableState({
        columns,
        items,
        persistenceId: `usedIn-${partId}`,
        sharedContext: { mpn: otsFullPart?.mpn },
    });

    if (isLoading) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return <DataTable tableState={tableState} overrides={{ NoResultsComponent }} size="medium" />;
};

const StyledLink = styled(Link)({
    color: colorSystem.blue[6],
    textDecoration: 'none',
    '&:hover': {
        color: colorSystem.blue[8],
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

const TableCellNoWrap = styled(TableCell)({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});
