import { FontVariant } from '@luminovo/design-system';
import { GenericLabel } from './GenericLabel';

export const RfqLabel = ({
    rfqName,
    emsInternalNumber,
    variant,
}: {
    rfqName: string;
    emsInternalNumber: string | undefined;
    variant?: FontVariant;
}): JSX.Element => <GenericLabel variant={variant} name={rfqName} additionalInfo={emsInternalNumber} />;
