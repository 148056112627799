import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { EmptyTemplateSelection } from './EmptyTemplateSelectionPane';
import { TemplateSelectionPane } from './TemplateSelectionPane';

export const RightColumn = ({
    onChange,
    selected,
    manufacturingScenarioTemplates,
}: {
    onChange: (e: string[]) => void;
    selected: string[];
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
}): JSX.Element => {
    if (selected.length === 0) {
        return <EmptyTemplateSelection />;
    }

    return (
        <TemplateSelectionPane
            manufacturingScenarioTemplates={selected.map(
                (id) => manufacturingScenarioTemplates.find((template) => template.id === id)!,
            )}
            onRemoveTemplateIndex={(i) => {
                let selectedCopy = [...selected];
                selectedCopy.splice(i, 1);
                onChange(selectedCopy);
            }}
        />
    );
};
