import { compareByString } from '@luminovo/commons';
import { CustomerDTO } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../http/useHttpQuery';
import { useRfQ } from '../rfq/rfqHandler';

export function useCustomers(): UseQueryResult<CustomerDTO[]> {
    return useHttpQuery(
        'GET /customers',
        {},
        { select: (data) => data.data.sort((a, b) => compareByString(a.name, b.name)) },
    );
}

export const useCustomer = (customerId: string | undefined) => {
    return useHttpQuery(
        'GET /customers/:customerId',
        { pathParams: { customerId: customerId ?? '' } },
        { select: (data) => data.data, enabled: Boolean(customerId) },
    );
};

export function useCustomerFromRfq(rfqId: string = '') {
    const { data: rfq } = useRfQ(rfqId, Boolean(rfqId));
    return useCustomer(rfq?.customer);
}
