import * as r from 'runtypes';
import { LanguageEnumRuntype } from '../../utils/i18n';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { AvailabilityRuntype, PackagingRuntype } from '../offer';

export type LineItemStatus = r.Static<typeof PurchaseOrderStatusRuntype>;
export const LineItemStatusRuntype = r.Union(
    r.Literal('waiting_for_approval'),
    r.Literal('rejected'),
    r.Literal('approved'),
    r.Literal('sent'),
    r.Literal('canceled'),
    r.Literal('received'),
);

export type PurchaseOrderStatus = r.Static<typeof PurchaseOrderStatusRuntype>;
export const PurchaseOrderStatusRuntype = r.Union(LineItemStatusRuntype, r.Literal('partially_received'));

const ConsignedPart = r.Union(
    r.Record({
        type: r.Literal('OffTheShelf'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Custom'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('CustomComponent'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Ipn'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Generic'),
        part: r.String,
    }),
);

const PurchaseOrderLinkedPartRuntype = r.Union(
    r.Record({
        type: r.Literal('OffTheShelf'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Custom'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('CustomComponent'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Ipn'),
        part: r.String,
    }),
    r.Record({
        type: r.Literal('Consigned'),
        part_options: r.Array(ConsignedPart),
    }),
);

const BaseLineItem = {
    linked_part: PurchaseOrderLinkedPartRuntype,
    quantity: QuantityUnitDTORuntype,
    expected_unit_price: MonetaryValueBackendRuntype,
    total_price: MonetaryValueBackendRuntype,
    availability: AvailabilityRuntype.nullable(),
    packaging: PackagingRuntype.nullable(),
    /**
     * If true, the line item will be excluded from the PO
     * This field is placed here for convenience, it's not part of the backend.
     */
    included: r.Boolean.optional(),
    offer_created_at: r.String.optional().nullable(),
    received_at: r.String.nullable().optional(),
    offer_number: r.String.nullable().optional(),
    ipns: r.Array(r.String).nullable().optional(),
    /**
     * The ID of the solution configuration that was used to create this line item.
     */
    solution_config_id: r.String,
};

export type LineItemDTO = r.Static<typeof LineItemRuntype>;
export const LineItemRuntype = r.Record({
    id: r.String,
    ...BaseLineItem,
});

export type PurchaseOrderRecipient = r.Static<typeof PurchaseOrderRecipientRuntype>;
export const PurchaseOrderRecipientRuntype = r.Union(
    r.Record({
        type: r.Literal('Supplier'),
        supplier_and_stock_location_id: r.String,
        supplier_contact: r.String.nullable(),
    }),
    r.Record({
        type: r.Literal('Internal'),
        inventory_id: r.String,
        internal_user: r.String.nullable(),
    }),
    r.Record({
        type: r.Literal('Customer'),
        user: r.String.nullable(),
    }),
    r.Record({
        type: r.Literal('Unknown'),
    }),
);

/**
 * A subset of the UserDTO, containing only the fields that are relevant for the purchase order.
 */
export type PurchaseOrderUserDTO = r.Static<typeof PurchaseOrderUserRuntype>;
export const PurchaseOrderUserRuntype = r.Record({
    id: r.String,
    email: r.String,
    first_name: r.String,
    last_name: r.String,
    user_language: LanguageEnumRuntype.optional(),
});

export interface PurchasingSupplierContactDTO extends r.Static<typeof PurchasingSupplierContactDTORuntype> {}
export const PurchasingSupplierContactDTORuntype = r.Record({
    id: r.String,
    supplier_and_stock_location: r.String,
    first_name: r.String,
    last_name: r.String,
    email: r.String,
    user_language: LanguageEnumRuntype,
    position: r.String.nullable(),
    is_main_contact: r.Boolean,
    is_public: r.Boolean,
    state: r.Union(
        r.Record({ state: r.Literal('Uninvited') }),
        r.Record({ state: r.Literal('Invited') }),
        r.Record({ state: r.Literal('User') }),
    ),
});

export type PendingPurchaseOrderRecipient = r.Static<typeof PurchaseOrderRecipientRuntype>;
export const PendingPurchaseOrderRecipientRuntype = r.Union(
    r.Record({
        type: r.Literal('Supplier'),
        supplier_and_stock_location_id: r.String,
        supplier_contact: PurchasingSupplierContactDTORuntype.nullable(),
    }),
    r.Record({
        type: r.Literal('Internal'),
        inventory_id: r.String,
        internal_user: PurchaseOrderUserRuntype.nullable(),
    }),
    r.Record({
        type: r.Literal('Customer'),
        user: PurchaseOrderUserRuntype.nullable(),
    }),
    r.Record({
        type: r.Literal('Unknown'),
    }),
);

const BasePurchaseOrderRuntype = {
    id: r.String,
    shipping_address: r.String,
    billing_address: r.String,
    supplier_purchase_order_number: r.String.nullable().optional(),
    notes: r.String.nullable().optional(),
    line_items: r.Array(LineItemRuntype),
    // Initially set to null, user can set it to a value
    shipping_costs: MonetaryValueBackendRuntype.nullable().optional(),
    purchase_order_number: r.String.nullable().optional(),
    sourcing_scenario_id: r.String,
    approved_at: r.String.nullable().optional().optional(),
    expected_delivery_at: r.String.nullable().optional(),
    rfq_id: r.String,
};

export type PurchaseOrderDTO = r.Static<typeof PurchaseOrderRuntype>;
export const PurchaseOrderRuntype = r.Record({
    ...BasePurchaseOrderRuntype,
    recipient: PurchaseOrderRecipientRuntype,
    created_at: r.String,
    sent_at: r.String.nullable(),
});

export type DispatchMethodDTO = r.Static<typeof DispatchMethodRuntype>;
export const DispatchMethodRuntype = r.Union(
    r.Record({
        type: r.Literal('Email'),
        subject: r.String,
        body: r.String,
    }),
    r.Record({
        type: r.Literal('None'),
    }),
);

export type PendingPurchaseOrderDTO = r.Static<typeof PendingPurchaseOrderRuntype>;
export const PendingPurchaseOrderRuntype = r.Record({
    ...BasePurchaseOrderRuntype,
    recipient: PendingPurchaseOrderRecipientRuntype,
    dispatch_method: DispatchMethodRuntype,
});

export type PendingPurchaseOrderDraft = r.Static<typeof PendingPurchaseOrderDraftRuntype>;
export const PendingPurchaseOrderDraftRuntype = r.Record({
    recipient: PendingPurchaseOrderRecipientRuntype,
    line_items: r.Array(r.Record(BaseLineItem)),
});
