import * as r from 'runtypes';
/**
 * This is needed for some requests made to the backend.
 * It needs to know where a request originates from to determine the visibility of some
 * information (CPN / IPN related).
 */
export type RfqContext = r.Static<typeof RfqContextRuntype>;
export const RfqContextRuntype = r.Union(
    r.Record({
        type: r.Literal('WithinRfQ'),
        rfq_id: r.String,
    }),
    r.Record({
        type: r.Literal('OutsideRfQ'),
    }),
);

export type RfQContextQueryParams = r.Static<typeof RfQContextQueryParamsRuntype>;
export const RfQContextQueryParamsRuntype = r.Union(
    r.Record({
        rfq_context: r.Literal('WithinRfQ'),
        rfq_id: r.String,
    }),
    r.Record({
        rfq_context: r.Literal('OutsideRfQ'),
    }),
);
