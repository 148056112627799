import { TableCell, Typography } from '@mui/material';
import React from 'react';

export const renderTextCell = (text: string | React.ReactNode): JSX.Element => {
    return (
        <TableCell>
            <Typography variant="body1" color={'textPrimary'}>
                {text}
            </Typography>
        </TableCell>
    );
};

export const columnWidth = {
    extraLarge: 1200,
    large: 600,
    medium: 400,
    small: 200,
    extraSmall: 100,
};
