import { t, Trans } from '@lingui/macro';
import { Column, FieldSelectControlled } from '@luminovo/design-system';
import { Packaging } from '@luminovo/http-client';
import { formatPackaging } from '../../../../formatters';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnPackaging: Column<TableQuotationOfferItem> = selectableColumn({
    attribute: 'packaging',
    label: <Trans>Packaging</Trans>,
    render: ({ extracted }) => {
        if (extracted === 'none') {
            return t`Unknown`;
        }
        return formatPackaging(extracted);
    },
    InputComponent: ({ control }) => {
        const options: Array<Packaging | 'none'> = Object.values<Packaging | 'none'>(Packaging).concat(['none']);
        return (
            <>
                <FieldSelectControlled
                    control={control}
                    name="packaging"
                    FieldProps={{
                        autoFocus: true,
                        fullWidth: true,
                        options,
                        getOptionLabel: (pkg) => (pkg === 'none' ? t`Unknown` : formatPackaging(pkg)),
                    }}
                />
            </>
        );
    },
});
