import { PanelPostDTO } from '@luminovo/http-client';
import React from 'react';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { isValidPanelDetails } from './isValidPanelDetails';

export const usePanelDetailsImage = ({
    panelDetails,
    pcbWidth,
    pcbHeight,
}: {
    panelDetails: PanelPostDTO;
    pcbWidth: number;
    pcbHeight: number;
}): string | null => {
    const [image, setImage] = React.useState<string | null>(null);
    const { mutateAsync: mutate } = useHttpMutation('POST /pcb/panel/render', {
        snackbarMessage: null,
        disableOnError: true,
    });

    const mutateAsync = React.useCallback(mutate, [mutate]); // memoize mutateAsync

    const { isValid, severity } =
        panelDetails.type === 'Existing'
            ? {
                  isValid: true,
                  severity: undefined,
              }
            : isValidPanelDetails(panelDetails.data.panel_details);

    /* eslint-disable camelcase */
    React.useEffect(() => {
        setImage(null);
        if (isValid === false && severity === 'error') {
            return;
        }

        const fetchImage = async () => {
            try {
                const result = await mutateAsync({
                    queryParams: {
                        pcbWidth,
                        pcbHeight,
                    },
                    requestBody: {
                        panel_details: panelDetails,
                    },
                });
                setImage(result);
            } catch (error) {
                setImage(null);
            }
        };

        fetchImage();
    }, [isValid, mutateAsync, panelDetails, pcbHeight, pcbWidth, severity]);
    /* eslint-enable camelcase */

    return image;
};
