import { PrimaryButton } from '@luminovo/design-system';
import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { CIRCULAR_PROGRESS_BUTTON_SIZE } from '../design-system/themes';

interface SaveButtonProps {
    submitDisabled: boolean;
    isSubmitting: boolean;
    submitButtonText: string;
    trackingId?: string;
}
/**
 * @deprecated use src/components/formLayouts/SubmitButton.tsx
 */
const SaveButton: React.FunctionComponent<SaveButtonProps> = ({
    submitDisabled,
    isSubmitting,
    submitButtonText,
    trackingId,
}: SaveButtonProps): JSX.Element => {
    return (
        <PrimaryButton type="submit" disabled={submitDisabled || isSubmitting} data-trackingid={trackingId}>
            {!isSubmitting ? (
                <Typography variant="h4">{submitButtonText}</Typography>
            ) : (
                <CircularProgress size={CIRCULAR_PROGRESS_BUTTON_SIZE} color="primary" />
            )}
        </PrimaryButton>
    );
};

export default SaveButton;
