import { Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row, StatusChip } from '@luminovo/design-system';
import { ExpenseDTO, ManufacturingEntityStatusWithError } from '@luminovo/http-client';
import { extractStatusColor } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { statusTranslationsWithError } from '../../../shared/sharedManufacturingBackendTypes';
import { ExpenseFormulasContext } from './utils';

type ExpenseStatusColumnContext = ExpenseFormulasContext;
type ExpenseItem = Pick<ExpenseDTO, 'id' | 'status'>;

const evaluateExpenseStatus = (
    expense: ExpenseItem,
    context: ExpenseStatusColumnContext,
): ManufacturingEntityStatusWithError => {
    const formulas = context.expenseFormulasMap[expense.id];

    if (!formulas) {
        return expense.status;
    }

    const hasAnyFormulaError =
        formulas.reduce((isError, formula) => isError || context.formulaDriversMap[formula]?.isError, false) ??
        undefined;

    if (hasAnyFormulaError) {
        return 'Error';
    }

    return expense.status;
};

function getStatusFilters<C extends ExpenseStatusColumnContext>(): Filter<ExpenseItem, C>[] {
    return [
        {
            id: 'status=Active',
            label: <Trans>Active</Trans>,
            predicate: (expense, context) => evaluateExpenseStatus(expense, context) === 'Active',
        },
        {
            id: 'status=Inactive',
            label: <Trans>Inactive</Trans>,
            predicate: (expense, context) => evaluateExpenseStatus(expense, context) === 'Inactive',
        },
        {
            id: 'status=Error',
            label: <Trans>Error</Trans>,
            predicate: (expense, context) => evaluateExpenseStatus(expense, context) === 'Error',
        },
    ];
}

export function getExpenseStatusColumn<T extends ExpenseItem, C extends ExpenseStatusColumnContext>(): Column<T, C> {
    return {
        label: <Trans>Status</Trans>,
        id: `status`,
        render: ({ data: rowData }: Row<T>, context): JSX.Element => {
            const status = evaluateExpenseStatus(rowData, context);

            return (
                <TableCell>
                    <StatusChip
                        color={extractStatusColor({ status })}
                        label={transEnum(status, statusTranslationsWithError)}
                    />
                </TableCell>
            );
        },
        width: columnWidth.extraSmall,
        filters: getStatusFilters(),
    };
}
