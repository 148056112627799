import { t } from '@lingui/macro';
import { FormItem, Text } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { getOfferNumbers } from '../model/utils';

export function FormItemOfferNumbers({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const offerNumbers = getOfferNumbers(quoteTracking);

    return (
        <FormItem label={t`Offer number`}>
            <Text>{offerNumbers.length === 0 ? t`No offers` : offerNumbers.join(', ')}</Text>
        </FormItem>
    );
}
