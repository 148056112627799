import { createWholeWordRegex } from '@luminovo/manufacturing-core';
import { ManufacturingInputControl } from '../../../components/MonacoEditor/editorTypes';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { createPredefinedManufacturingInputControls } from './createPredefinedManufacturingInputControls';

/**
 * @param {[string]} existingFormulaValue existing value of the formula
 */
export const useManufacturingInputControls = (existingFormulaValue?: string) => {
    const { data, isLoading: isDriversLoading } = useHttpQuery('GET /user-drivers', {});
    const drivers = data?.data ?? [];
    const predefinedManufacturingInputControls: ManufacturingInputControl[] =
        createPredefinedManufacturingInputControls(existingFormulaValue);
    const driverInputControls: ManufacturingInputControl[] = drivers.map((d) => {
        const formulaValue = d.name.replaceAll(' ', '_');
        return {
            name: d.name,
            type: 'Driver',
            automaticDetails: d.details.automatic_details ?? undefined,
            formulaValue,
            isInFormula:
                existingFormulaValue !== undefined && createWholeWordRegex(formulaValue).test(existingFormulaValue),
            notes: d.notes ?? undefined,
        };
    });

    return {
        isLoading: isDriversLoading,
        inputControls: [...driverInputControls, ...predefinedManufacturingInputControls],
    };
};
