import { Trans } from '@lingui/macro';
import { CenteredLayout, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Step, StepConnector, Stepper, Typography, stepConnectorClasses, useTheme } from '@mui/material';
import React from 'react';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { RfqCreationStep, getStepNumber, useRfqCreationContext } from '../../RfqCreationContext';
interface Progress {
    step: RfqCreationStep;
    label: JSX.Element;
}

const progressCustomer: Progress[] = [
    {
        step: 'customer',
        label: <Trans>Customer</Trans>,
    },
    {
        step: 'assembly',
        label: <Trans>Assembly</Trans>,
    },
    {
        step: 'demand',
        label: <Trans>Demand scenarios</Trans>,
    },
    {
        step: 'rfq',
        label: <Trans>RfQ</Trans>,
    },
];

const progressCompany: Progress[] = [
    {
        step: 'customer',
        label: <Trans>Company</Trans>,
    },
    {
        step: 'assembly',
        label: <Trans>Assembly</Trans>,
    },
    {
        step: 'demand',
        label: <Trans>Demand scenarios</Trans>,
    },
    {
        step: 'rfq',
        label: <Trans>RfQ</Trans>,
    },
];
const isCompleted = (currentStep: RfqCreationStep, lastCompletedStep: RfqCreationStep | undefined): boolean => {
    if (!lastCompletedStep) return false;

    return getStepNumber(lastCompletedStep) > getStepNumber(currentStep);
};

const isActive = (currentStep: RfqCreationStep, activeStep: RfqCreationStep): boolean => {
    return currentStep === activeStep;
};

export const RfqCreationStepper = (): JSX.Element => {
    const theme = useTheme();
    const { hasCustomers } = useHasCustomers();
    const { activeStep, lastCompletedStep } = useRfqCreationContext();

    const progress = hasCustomers ? progressCustomer : progressCompany;

    return (
        <Flexbox flexDirection="column" justifyContent="space-between" gap={theme.spacing(4)}>
            <Text
                variant="h1"
                style={{ fontSize: '40px', lineHeight: '40px', marginBottom: '18px' }}
                color={colorSystem.neutral.white}
            >
                <Trans>New request</Trans>
            </Text>

            <Stepper
                orientation="vertical"
                connector={
                    <StepConnector
                        sx={{
                            marginLeft: '19px',
                            [`& .${stepConnectorClasses.line}`]: {
                                height: theme.spacing(8),
                                borderColor: theme.palette.secondary.main,
                            },
                        }}
                    />
                }
                sx={{
                    background: 'transparent',
                    padding: 0,
                    marginTop: theme.spacing(6),
                }}
            >
                {progress.map((progress, i) => (
                    <Step key={i}>
                        <RfqStep
                            isActive={isActive(progress.step, activeStep)}
                            isCompleted={isCompleted(progress.step, lastCompletedStep)}
                            index={i}
                        >
                            {progress.label}
                        </RfqStep>
                    </Step>
                ))}
            </Stepper>
        </Flexbox>
    );
};

const CompletedIcon = () => {
    const theme = useTheme();

    return (
        <CenteredLayout
            style={{
                borderRadius: '50%',
                background: theme.palette.secondary.main,
                width: '100%',
                height: '100%',
            }}
        >
            <CheckRoundedIcon style={{ fill: theme.palette.background.default }} />
        </CenteredLayout>
    );
};

const UncompletedIcon = ({
    children,
    style,
}: {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}): JSX.Element => (
    <Typography variant="h1" style={style}>
        {children}
    </Typography>
);

interface RfqStepLabelProps {
    isActive: boolean;
    isCompleted: boolean;
    index: number;
    children: React.ReactNode;
}

const RfqStep = ({ isActive, isCompleted, index, children }: RfqStepLabelProps): JSX.Element => {
    const theme = useTheme();

    const activeColor = theme.palette.background.default;
    const defaultColor = theme.palette.secondary.main;

    return (
        <Flexbox flexDirection="row" alignItems="center" gap={theme.spacing(3)}>
            <RfqStepIcon isActive={isActive} isCompleted={isCompleted} index={index + 1} />
            <Typography variant="h1" style={{ color: isActive ? activeColor : defaultColor }}>
                {children}
            </Typography>
        </Flexbox>
    );
};

interface RfqStepIconProps extends Pick<RfqStepLabelProps, 'isActive' | 'isCompleted' | 'index'> {}

const RfqStepIcon = ({ isActive, isCompleted, index }: RfqStepIconProps): JSX.Element => {
    const theme = useTheme();
    const color = isActive ? theme.palette.background.default : theme.palette.secondary.main;

    const dimension = '30px';

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                borderRadius: '50%',
                width: dimension,
                height: dimension,
                minWidth: dimension,
                border: '1px solid',
                borderColor: color,
                padding: '4px',
                color: color,
            }}
        >
            {isCompleted ? <CompletedIcon /> : <UncompletedIcon style={{ color: color }}>{index}</UncompletedIcon>}
        </Box>
    );
};
