import { Currency, formatPercentage } from '@luminovo/commons';
import { Text, colorSystem } from '@luminovo/design-system';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { DynamicCostCell } from '../types/cellTypes';

export const equivalentTextStyle = {
    color: colorSystem.neutral[6],
    variant: 'body-small',
    width: 120,
};

function renderCost(value: string, currency: Currency) {
    return (
        <Text style={equivalentTextStyle}> = {value ? formatCurrency(parseFloat(value), currency) : undefined}</Text>
    );
}

function renderFraction(value: string) {
    return <Text style={equivalentTextStyle}> = {value ? formatPercentage(parseFloat(value) / 100) : undefined}</Text>;
}

export function renderEquivalentValue(
    costType: 'fixed' | 'fraction' | 'formula-fixed' | 'formula-fraction',
    cell: DynamicCostCell,
) {
    switch (costType) {
        case 'fraction':
        case 'formula-fraction':
            return renderCost(cell.unitCostValue.amount, cell.unitCostValue.currency);
        case 'fixed':
        case 'formula-fixed':
            return renderFraction(cell.costFraction);
        default:
            assertUnreachable(costType);
    }
}
