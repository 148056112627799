import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem, Flexbox, PrimaryButton, Tag, Text } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import React from 'react';
import { transEnum } from '../../../../components/localization/TransEnum';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { useAddManufacturingScenarioDialog } from '../../ManufacturingScenario/ManufacturingScenarioForm/useAddManufacturingScenarioDialog';
import { DividerWithMargins } from '../assemblyOverviewCardComponents';
import { AssemblyPath } from './components/AssemblyPath';
import { StyledBoxGrid } from './components/StyledBoxGrid';

export interface AssemblyOverviewBlankCardProps {
    rfqId: string;
    designator: string;
    assemblyType: AssemblyTypeEnum;
    assemblyId: string;
    parentAssemblies: string[];
    manufacturingAssemblyDetailsId: string | undefined;
}

export const AssemblyOverviewBlankCard: React.FunctionComponent<AssemblyOverviewBlankCardProps> = ({
    rfqId,
    designator,
    assemblyType,
    assemblyId,
}: AssemblyOverviewBlankCardProps): JSX.Element => {
    const { openDialog } = useAddManufacturingScenarioDialog({ rfqId, assemblyId });

    return (
        <StyledBoxGrid>
            <Flexbox flexDirection="column">
                <Flexbox
                    alignItems={'center'}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                >
                    <Text variant="h2" style={{ maxWidth: '300px' }} showEllipsis>
                        {designator}
                    </Text>
                    <Tag
                        color={'neutral'}
                        label={transEnum(assemblyType, assemblyTypePublicTranslations)}
                        style={{ marginLeft: '8px' }}
                    />
                </Flexbox>
                <AssemblyPath assemblyId={assemblyId} />
            </Flexbox>
            <DividerWithMargins />
            <Flexbox flexDirection={'column'}>
                <Text variant="body" style={{ color: colorSystem.neutral[6], maxWidth: '300px' }} showEllipsis>
                    <Trans>No manufacturing information.</Trans>
                </Text>
            </Flexbox>
            <Flexbox>
                <PrimaryButton
                    style={{ marginLeft: 'auto' }}
                    onClick={openDialog}
                    size="medium"
                    id={id('manufacturing_scenario/button_create_manufacturing_scenario')}
                >
                    <Trans>Create manufacturing scenario</Trans>
                </PrimaryButton>
            </Flexbox>
        </StyledBoxGrid>
    );
};
