import { ExpenseDTO } from '@luminovo/http-client';
import { FormulasContext, useFormulasContext } from '../../../shared/columns/formulasContext';

export interface ExpenseFormulasContext extends FormulasContext {
    expenseFormulasMap: Record<string, string[]>;
}

const getExpenseFormulasMap = (expenses: Pick<ExpenseDTO, 'id' | 'cost_components'>[]): Record<string, string[]> => {
    const expenseFormulasMap: Record<string, string[]> = {};

    for (const expense of expenses) {
        const expenseType = expense.cost_components.type;
        if (expenseType !== 'Formula') continue;

        expenseFormulasMap[expense.id] = [expense.cost_components.details.formula];
    }

    return expenseFormulasMap;
};

export const useExpenseFormulasContext = (
    expenses: Pick<ExpenseDTO, 'id' | 'cost_components'>[],
): ExpenseFormulasContext => {
    const expenseFormulasMap = getExpenseFormulasMap(expenses);
    const formulas = Object.values(expenseFormulasMap).flat();

    const { formulaDriversMap } = useFormulasContext(formulas);

    return {
        expenseFormulasMap,
        formulaDriversMap,
    };
};
