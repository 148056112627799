import { plural } from '@lingui/macro';
import { Chip } from '@luminovo/design-system';
import { PackageDTO } from '@luminovo/http-client';

export const PackageChip = ({
    formfit: { name, mounting, number_of_pins },
    conflicts,
    disabled,
}: {
    formfit: PackageDTO;
    conflicts?: boolean;
    disabled?: boolean;
}): JSX.Element | null => {
    if (!mounting && !name) return null;
    const numberOfPins = number_of_pins
        ? plural(number_of_pins, { one: `• ${number_of_pins} pin`, other: `• ${number_of_pins} pins` })
        : undefined;

    return (
        <Chip
            color={conflicts ? 'red' : 'neutral'}
            label={`${mounting ?? ''} ${name ?? ''} ${numberOfPins ?? ''}`}
            disabled={disabled}
        />
    );
};
