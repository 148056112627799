import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, styled } from '@mui/material';
import React from 'react';
import { body, bodySmall, colorSystem, hoveredStyles } from '../../theme';

export type TextFieldProps = Omit<MuiTextFieldProps, 'size'> & {
    size?: 'large' | 'small';
};

export const TextField = React.forwardRef(function TextField(
    { size = 'large', ...props }: TextFieldProps,
    ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element {
    switch (size) {
        case 'small':
            return (
                <SmallTextField ref={ref} {...props}>
                    {props.children}
                </SmallTextField>
            );
        case 'large':
            return (
                <LargeTextField ref={ref} {...props}>
                    {props.children}
                </LargeTextField>
            );
        default:
            return <MuiTextField ref={ref} {...props} />;
    }
});

const DefaultTextField = styled(MuiTextField)({
    boxSizing: 'border-box',

    '& .MuiOutlinedInput-root': {
        padding: '0px',
        background: colorSystem.neutral.white,
        '& fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.neutral[3]}`,
            },
        },
        '&:hover fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                ...hoveredStyles,
            },
        },

        '&.Mui-focused fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.primary[5]}`,
                boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
            },
        },

        '&.Mui-disabled': {
            background: colorSystem.neutral[1],
            '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                '& .MuiTypography-root': {
                    color: colorSystem.neutral[5],
                },
            },
        },
        '&.Mui-disabled fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.neutral[3]}`,
            },
        },
        '&.Mui-disabled:hover fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.neutral[3]}`,
            },
        },
        '&.Mui-error fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.red[5]}`,
            },
        },
        '&.Mui-error:hover fieldset': {
            '&.MuiOutlinedInput-notchedOutline ': {
                border: `1px solid ${colorSystem.red[5]}`,
            },
        },
    },

    '& .MuiOutlinedInput-multiline': {
        padding: 0,
    },

    '& .MuiInputBase-input': {
        color: colorSystem.neutral[9],
        '&::placeholder': {
            color: colorSystem.neutral[6],
            opacity: 1,
        },
        '&.Mui-disabled': {
            '&::placeholder': {
                color: colorSystem.neutral[5],
            },
        },
    },

    // Style the helper text
    '& .MuiFormHelperText-root': {
        color: colorSystem.neutral[7],
        margin: '4px 0 0 0',
    },
    '& .MuiFormHelperText-root.Mui-error': {
        color: colorSystem.red[7],
    },
    '& .MuiFormHelperText-root.Mui-disabled': {
        color: colorSystem.neutral[5],
    },

    // Style the start adornment
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
        margin: '0 0 0 8px',
        padding: 0,
        '& .MuiTypography-root': {
            ...bodySmall,
            color: colorSystem.neutral[8],
            background: colorSystem.neutral[1],
            padding: '2px 4px',
            borderRadius: '4px',
            border: `1px solid ${colorSystem.neutral[2]}`,
        },
    },

    // Style the end adornment
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
        margin: '0 8px 0 0',
        padding: 0,
        '& .MuiTypography-root': {
            ...bodySmall,
            color: colorSystem.neutral[8],
            background: colorSystem.neutral[1],
            padding: '2px 4px',
            borderRadius: '4px',
            border: `1px solid ${colorSystem.neutral[2]}`,
        },
    },
});

const SmallTextField = styled(DefaultTextField)({
    '& .MuiOutlinedInput-input': {
        padding: '8px',
        height: '16px',
    },

    '& .MuiInputBase-input': {
        ...bodySmall,
        '&::placeholder': {
            ...bodySmall,
        },
    },
});

const LargeTextField = styled(DefaultTextField)({
    '& .MuiOutlinedInput-input': {
        padding: '10px 8px',
        height: '20px',
    },
    '& .MuiInputBase-input': {
        ...body,
        '&::placeholder': {
            ...body,
        },
    },
});
