import { Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { ProcessSelection } from '@luminovo/http-client';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';
import { processPublicTranslations } from '../sharedManufacturingBackendTypes';

interface RowWithProcess {
    process: ProcessSelection;
}

function getProcessFilters<C>(): Filter<RowWithProcess, C>[] {
    return [
        {
            id: 'process=ProcessPreparation',
            label: <Trans>Process preparation</Trans>,
            predicate: (rowData) => rowData.process === 'ProcessPreparation',
        },
        {
            id: 'process=NPI',
            label: <Trans>NPI</Trans>,
            predicate: (rowData) => rowData.process === 'NPI',
        },
        {
            id: 'process=SMT',
            label: <Trans>SMT</Trans>,
            predicate: (rowData) => rowData.process === 'SMT',
        },
        {
            id: 'process=THT',
            label: <Trans>THT</Trans>,
            predicate: (rowData) => rowData.process === 'THT',
        },
        {
            id: 'process=Hand soldering',
            label: <Trans>Hand soldering</Trans>,
            predicate: (rowData) => rowData.process === 'HandSoldering',
        },
        {
            id: 'process=PanelSeparation',
            label: <Trans>Panel separation</Trans>,
            predicate: (rowData) => rowData.process === 'PanelSeparation',
        },
        {
            id: 'process=Potting',
            label: <Trans>Potting</Trans>,
            predicate: (rowData) => rowData.process === 'Potting',
        },
        {
            id: 'process=Ruggedizing',
            label: <Trans>Ruggedizing</Trans>,
            predicate: (rowData) => rowData.process === 'Ruggedizing',
        },
        {
            id: 'process=Testing',
            label: <Trans>Testing</Trans>,
            predicate: (rowData) => rowData.process === 'Testing',
        },
        {
            id: 'process=Inspection',
            label: <Trans>Inspection</Trans>,
            predicate: (rowData) => rowData.process === 'Inspection',
        },
        {
            id: 'process=CableAssembly',
            label: <Trans>Cable assembly</Trans>,
            predicate: (rowData) => rowData.process === 'CableAssembly',
        },
        {
            id: 'process=MechanicalAssembly',
            label: <Trans>Mechanical assembly</Trans>,
            predicate: (rowData) => rowData.process === 'MechanicalAssembly',
        },
        {
            id: 'process=QualityControl',
            label: <Trans>Quality control</Trans>,
            predicate: (rowData) => rowData.process === 'QualityControl',
        },
        {
            id: 'process=Packaging',
            label: <Trans>Packaging</Trans>,
            predicate: (rowData) => rowData.process === 'Packaging',
        },
        {
            id: 'process=Logistics',
            label: <Trans>Logistics</Trans>,
            predicate: (rowData) => rowData.process === 'Logistics',
        },
        {
            id: 'process=Other',
            label: <Trans>Other</Trans>,
            predicate: (rowData) => rowData.process === 'Other',
        },
    ];
}

export function getProcessColumn<C>(): Column<RowWithProcess, C> {
    return {
        label: <Trans>Process</Trans>,
        id: 'process',
        render: ({ data }: Row<RowWithProcess>) => (
            <TextCell firstRow={transEnum(data.process, processPublicTranslations)} minWidth={160} />
        ),
        width: columnWidth.small,
        filters: getProcessFilters(),
    };
}
