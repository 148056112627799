import { colorSystem, Message, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';

export function IntroMessage(): JSX.Element | null {
    const [isShown, setIsShown] = React.useState(false);
    if (!isShown) {
        return null;
    }
    return (
        <Box padding="16px">
            <Message
                attention="high"
                variant="blue"
                title={`Welcome to the Quote Extraction Tool!`}
                message={
                    <>
                        Please review and correct the data as needed. When done, click 'Import' at the top right.
                        <br />
                        <br />
                        Legend:
                        <Box display="grid" gridTemplateColumns={'auto 1fr'} style={{ gap: 8, marginTop: 16 }}>
                            <div
                                style={{
                                    borderRadius: 4,
                                    background: colorSystem.red[2],
                                    width: 20,
                                    border: `1px solid ${colorSystem.red[6]}`,
                                }}
                            />
                            <Text>Nothing extracted</Text>
                            <div
                                style={{
                                    borderRadius: 4,
                                    background: colorSystem.yellow[1],
                                    width: 20,
                                    border: `1px solid ${colorSystem.yellow[6]}`,
                                }}
                            />
                            <Text>Low confidence</Text>
                            <div
                                style={{
                                    borderRadius: 4,
                                    background: colorSystem.neutral.white,
                                    width: 20,
                                    border: `1px solid ${colorSystem.neutral[6]}`,
                                }}
                            />
                            <Text>Information found</Text>

                            <div
                                style={{
                                    borderRadius: 4,
                                    background: colorSystem.green[1],
                                    width: 20,
                                    border: `1px solid ${colorSystem.green[6]}`,
                                }}
                            />
                            <Text>Verified manually</Text>
                        </Box>
                    </>
                }
                size="large"
                action={{
                    label: 'OK',
                    onClick: () => setIsShown(false),
                }}
                onClose={() => setIsShown(false)}
            />
        </Box>
    );
}
