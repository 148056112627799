import { useToken } from '@luminovo/auth';
import { InitializePaymentInputDTO, http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';

export const useMutationInitializeOrder = () => {
    const { token } = useToken();
    const debugErrorHandler = useDebugErrorHandler();

    return useMutation({
        mutationFn: ({ values }: { values: InitializePaymentInputDTO }) => {
            return http('POST /order-management/orders/initialize-payment', { requestBody: values }, token);
        },
        onError: debugErrorHandler,
    });
};
