import { Trans } from '@lingui/macro';
import { Column, columnWidth, Row } from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { NotPerPanelIconAndText, PerPanelIconAndText } from '../../shared/icons';

export function getPerPanelActivityColumn<C>(): Column<ActivityDTO, C> {
    return {
        label: <Trans>Per panel</Trans>,
        id: `perPanel`,
        render: ({ data: activity }: Row<ActivityDTO>) => {
            if (activity.time_calculation.level !== 'Unit') {
                return <TableCell />;
            }

            const panelSettings = activity.time_calculation.details.panel_default_settings;
            const icon = panelSettings === 'WithinWholePanel' ? <PerPanelIconAndText /> : <NotPerPanelIconAndText />;

            return <TableCell>{icon}</TableCell>;
        },
        width: columnWidth.small,
        filters: [
            {
                id: 'per panel=true',
                label: <Trans>Yes</Trans>,
                predicate: (activity: ActivityDTO): boolean =>
                    activity.time_calculation.level === 'Unit' &&
                    activity.time_calculation.details.panel_default_settings === 'WithinWholePanel',
            },
            {
                id: 'per panel=false',
                label: <Trans>No</Trans>,
                predicate: (activity: ActivityDTO): boolean =>
                    activity.time_calculation.level === 'Unit' &&
                    activity.time_calculation.details.panel_default_settings !== 'WithinWholePanel',
            },
        ],
    };
}
