export function StackUpPreviewIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="300" height="448" viewBox="0 0 300 448" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="8" y="8" width="284" height="8" rx="4" fill="#E9ECF4" />
            <rect x="8" y="32" width="284" height="16" rx="8" fill="#E9ECF4" />
            <rect x="8" y="64" width="284" height="48" rx="8" fill="#E9ECF4" />
            <rect x="8" y="128" width="284" height="16" rx="8" fill="#E9ECF4" />
            <rect x="8" y="160" width="284" height="128" rx="8" fill="#F5F6FA" />
            <rect x="8" y="304" width="284" height="16" rx="8" fill="#E9ECF4" />
            <rect x="8" y="336" width="284" height="48" rx="8" fill="#E9ECF4" />
            <rect x="8" y="400" width="284" height="16" rx="8" fill="#E9ECF4" />
            <rect x="8" y="432" width="284" height="8" rx="4" fill="#E9ECF4" />
        </svg>
    );
}
