import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContributorsFormItem } from '../../../../components/RfQ/ContributorsFormItem';
import { CurrencyFormItem } from '../../../../components/RfQ/CurrencyFormItem';
import { DueDateFormItem } from '../../../../components/RfQ/DueDateFormItem';
import { EmsInternalNumberFormItem } from '../../../../components/RfQ/EmsInternalNumberFormItem';
import { RfqNameFormItem } from '../../../../components/RfQ/RfqNameFormItem';
import { VolumeEstimateFormItem } from '../../../../components/RfQ/VolumeEstimateFormItem';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { RfqCreationFormState, useRfqCreationContext } from '../../RfqCreationContext';
import { NavigationButtons } from './NavigationButtons';

interface Props {
    isSubmitting: boolean;
}

export const RfqForm = ({ isSubmitting }: Props): JSX.Element => {
    const useFormReturn = useFormContext<RfqCreationFormState>();
    const { control, setValue, formState } = useFormReturn;
    const { setActiveStep } = useRfqCreationContext();

    // RfQ number
    const { data: nextAutoRfqNumber, isLoading: nextEmsInternalNumberIsLoading } = useHttpQuery(
        'GET /organization-settings/auto-rfq-number/next',
        {},
    );

    useEffect(() => {
        if (isPresent(nextAutoRfqNumber)) {
            setValue('rfq.emsInternalNumber', nextAutoRfqNumber);
        }
    }, [setValue, nextAutoRfqNumber]);

    // Navigation
    const onPrevious = () => {
        setActiveStep('demand');
    };

    // RfQ name
    const customerId = useWatch({ name: 'customer.id', control });

    if (nextEmsInternalNumberIsLoading) {
        return <SpinnerWithBackdrop />;
    }

    return (
        <>
            <RfqNameFormItem control={control} name="rfq.name" />

            <EmsInternalNumberFormItem control={control} name="rfq.emsInternalNumber" />

            <DueDateFormItem control={control} name="rfq.dueDate" />

            <CurrencyFormItem control={control} name="rfq.currency" />

            <VolumeEstimateFormItem<RfqCreationFormState, 'rfq.volumeEstimate'>
                name="rfq.volumeEstimate"
                currencyName="rfq.currency"
            />

            <Divider />

            <ContributorsFormItem customerId={customerId} />

            <NavigationButtons
                nextLabel={t`Save and add RfQ`}
                showPrevious
                onPrevious={onPrevious}
                onNext="submit"
                isDisabled={isSubmitting || Object.keys(formState.errors).length > 0}
                isLoading={isSubmitting}
            />
        </>
    );
};
