import { t, Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { sortByProcessThenCategoryThenName } from '@luminovo/manufacturing-core';
import { Add } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useExpenseColumns } from '../../../shared/Entities/columns';
import { EntityFormState, EntitySelectionDialogForm } from '../../../shared/Entities/EntitySelectionDialogForm';
import { useMutationAppendExpenses } from './addExpensesDialogFunctions';

const ExpenseSelectionDialogForManufacturingScenario = ({
    closeDialog,
    selectedManufacturingScenarioId,
    manufacturingScenarioTemplateInstanceId,
}: ExpenseSelectionDialogForManufacturingScenarioDialogProps): JSX.Element => {
    const { data } = useHttpQuery('GET /expenses', { queryParams: { active: true } });
    const { mutateAsync } = useMutationAppendExpenses({
        manufacturingScenarioId: selectedManufacturingScenarioId,
        onSuccesCallback: () => closeDialog(),
    });
    const columns = useExpenseColumns();
    const onSubmit: SubmitHandler<EntityFormState> = async (values: EntityFormState) => {
        await mutateAsync({
            values: {
                expenses: values.selectedIds,
                //eslint-disable-next-line camelcase
                manufacturing_scenario_id: selectedManufacturingScenarioId,
                manufacturing_scenario_template_instance_id: manufacturingScenarioTemplateInstanceId ?? null,
            },
        });
    };

    if (data === undefined) return <CircularProgress />;

    return (
        <EntitySelectionDialogForm
            title={t`Select expenses`}
            closeDialog={closeDialog}
            entities={sortByProcessThenCategoryThenName(data.data)}
            columns={columns}
            defaultValues={{
                selectedIds: [],
            }}
            onSubmit={onSubmit}
            persistenceId={`expense-selection-dialog-${selectedManufacturingScenarioId}`}
            defaultSelectedRowIds={[]}
        />
    );
};

interface ExpenseSelectionDialogForManufacturingScenarioDialogProps {
    closeDialog: () => void;
    selectedManufacturingScenarioId: string;
    manufacturingScenarioTemplateInstanceId: string | undefined;
}
const useExpenseSelectionDialogForManufacturingScenario = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            manufacturingScenarioId,
            manufacturingScenarioTemplateInstanceId,
        }: {
            manufacturingScenarioId: string;
            manufacturingScenarioTemplateInstanceId: string | undefined;
        }) =>
            setDialog(
                <ExpenseSelectionDialogForManufacturingScenario
                    closeDialog={closeDialog}
                    selectedManufacturingScenarioId={manufacturingScenarioId}
                    manufacturingScenarioTemplateInstanceId={manufacturingScenarioTemplateInstanceId}
                />,
            ),
    };
};

export const AppendExpensesButton = ({
    manufacturingScenarioId,
    manufacturingScenarioTemplateInstanceId,
}: {
    manufacturingScenarioId: string;
    manufacturingScenarioTemplateInstanceId: string | undefined;
}): JSX.Element => {
    const { openDialog } = useExpenseSelectionDialogForManufacturingScenario();

    return (
        <SecondaryButton
            startIcon={<Add fontSize="inherit" />}
            onClick={() =>
                openDialog({
                    manufacturingScenarioId,
                    manufacturingScenarioTemplateInstanceId,
                })
            }
            size="medium"
        >
            <Trans>Append expenses</Trans>
        </SecondaryButton>
    );
};
