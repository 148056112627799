import * as r from 'runtypes';
import { SelfSignupColorsRuntype } from '../organizationSettings';

export const PublicTenantRuntype = r.Record({
    organization_name: r.String,
    terms_and_conditions_link: r.String,
    is_self_signup_enabled: r.Boolean,
    are_private_customers_allowed: r.Boolean,
    organization_logo: r.String.nullable(),
    colors: SelfSignupColorsRuntype,
});

export type PublicTenantDTO = r.Static<typeof PublicTenantRuntype>;
