import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { RochesterCredentialsInputDTORuntype, RochesterResponseBodyDTORuntype } from './rochesterBackendTypes';

export const rochesterEndpoints = {
    'GET /3rdparty/rochester/credentials': endpoint({
        description: 'Returns credentials for rochester integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: RochesterResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rochester/credentials': endpoint({
        description: 'Uploads credentials for rochester API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RochesterCredentialsInputDTORuntype,
        responseBody: RochesterResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rochester/credentials'],
    }),
    'PATCH /3rdparty/rochester/credentials': endpoint({
        description: 'Updates credentials for rochester API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RochesterCredentialsInputDTORuntype,
        responseBody: RochesterResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rochester/credentials'],
    }),
    'DELETE /3rdparty/rochester/credentials': endpoint({
        description: 'Deletes existing credentials for rochester API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/rochester/credentials'],
    }),
};
