import React from 'react';
import { TokenContext } from '../TokenContext';
/**
 * @deprecated use `getToken` from '@luminovo/auth';
 *
 * `useToken` in combination with `userQuery` can lead to 401 errors, because react-query ignores the new token in the `queryFn`.
 *  see here: https://gitlab.com/luminovo/product/epibator/-/merge_requests/6309#note_1358344544
 */
export const useToken = (): { token: string } => {
    const token = React.useContext(TokenContext)!;
    if (!token) {
        throw new Error(`Token not present, make sure you've set the token correctly in the TokenContext.`);
    }
    return { token };
};
