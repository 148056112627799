import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, DialogTitle, PrimaryButton, Text } from '@luminovo/design-system';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

export const OrderExpirationDialog = ({ rfqId, isOpen }: { rfqId: string; isOpen: boolean }): JSX.Element => {
    const { mutateAsync } = useHttpMutation('DELETE /order-management/order-selection-options', {
        snackbarMessage: null,
    });
    const closeAndReloadOrderSelection = () => {
        mutateAsync({ queryParams: { rfq_id: rfqId } });
    };

    return (
        <Dialog open={isOpen} maxWidth="xs">
            <DialogTitle title={`Order selection expired`} />
            <DialogContent>
                <p>
                    <Text variant="body">
                        <Trans>Your order options have expired, we're going to reload them.</Trans>
                    </Text>
                </p>
                <p>
                    <Text variant="body-semibold">
                        <Trans>Please check your selection and the prices!</Trans>
                    </Text>
                </p>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={closeAndReloadOrderSelection}>
                    <Trans>OK</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
