import { isPresent } from '@luminovo/commons';
import { Flexbox, Tag, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { PartCategoryDTO } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import React from 'react';
import { usePartCategory } from '../../resources/part/partHandler';

export const PartCategoryTag: React.FunctionComponent<{ partCategory: Pick<PartCategoryDTO, 'id'> }> = ({
    partCategory: { id },
}) => {
    const { data: partCategory } = usePartCategory(id);

    if (!isPresent(partCategory)) {
        return <Skeleton width={'100%'} height="38px" />;
    }

    return (
        <Tooltip
            variant="white"
            disableMaxWidth={true}
            title={
                <Flexbox gap={4}>
                    {partCategory.path.map((parent, index) => {
                        const isLastIndex = index === partCategory.path.length - 1;
                        return (
                            <React.Fragment key={parent.id}>
                                <Tag
                                    color={isLastIndex ? 'primary' : 'neutral'}
                                    attention={'low'}
                                    label={parent.name}
                                />
                                {!isLastIndex && (
                                    <Text variant="body" color={colorSystem.neutral[6]}>
                                        {'>'}
                                    </Text>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Flexbox>
            }
        >
            <span>
                <Tag
                    color={'neutral'}
                    attention={'low'}
                    label={partCategory.name}
                    style={{
                        display: 'block',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        wordBreak: 'break-all',
                    }}
                />
            </span>
        </Tooltip>
    );
};
