import { t } from '@lingui/macro';
import { FieldSelectControlled } from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { ResourceLabel } from '@luminovo/manufacturing-core';
import { Control, FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form';
import { useSiteNames } from '../../../../components/Site';

interface ResourceSelectProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
    name: TName;
    control: Control<TFieldValues>;
    allResources: ResourceDTO[];
}

export const ResourceSelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
>({
    name,
    control,
    allResources,
}: ResourceSelectProps<TFieldValues, TName>): JSX.Element => {
    const { siteNames } = useSiteNames();
    // FIXME: We need to find a type-safe way to pass the `options` prop to `FieldSelectControlled`.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const resourceIds = allResources.map((resource) => resource.id) as TValue;

    const findResource = (resourceId: string): ResourceDTO | undefined => {
        return allResources.find((resource) => resource.id === resourceId);
    };

    return (
        <FieldSelectControlled
            name={name}
            control={control}
            FieldProps={{
                placeholder: t`Search for a resource`,
                options: resourceIds,
                disableClearable: true,
                getOptionLabel: (resourceId) => findResource(resourceId)?.name ?? '',
                renderOption: (resourceId) => {
                    const resource = findResource(resourceId);

                    if (!resource) return null;

                    return (
                        <RenderedResourceOption
                            siteNames={siteNames}
                            siteId={resource?.site_id ?? undefined}
                            resource={resource?.name ?? ''}
                        />
                    );
                },
            }}
        />
    );
};

const RenderedResourceOption = ({
    siteNames,
    siteId,
    resource,
}: {
    siteNames: Record<string, string> | undefined;
    siteId: string | undefined;
    resource: string;
}): JSX.Element => {
    const site = siteNames && siteId ? siteNames[siteId] : undefined;

    return <ResourceLabel resourceName={resource} site={site} />;
};
