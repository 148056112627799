import { useSortable } from '@dnd-kit/sortable';
import { Transform } from '@dnd-kit/utilities';
import { Box, styled } from '@mui/material';
import React from 'react';

const Wrapper = styled(Box)(
    ({
        transform,
        transition,
        isDragging,
    }: {
        transform: Transform | null;
        transition: string | undefined;
        isDragging: boolean;
    }) => ({
        '--is-dragging-view': isDragging ? 'flex' : 'none',
        '--is-dragging-hide': isDragging ? 'none' : 'flex',
        '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
        '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
        '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
        '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
        transition: [transition].filter(Boolean).join(', '),
        display: 'flex',
        boxSizing: 'border-box',
        transform:
            'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
        transformOrigin: '0 0',
        touchAction: 'manipulation',
        animation: 'fadeIn 500ms ease',
        width: '100%',
        zIndex: 999,
    }),
);

export const LayerAssignmentDraggable = ({
    children,
    id,
    disabled = false,
}: {
    children: React.ReactNode;
    id: string;
    disabled?: boolean;
}): JSX.Element => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        disabled,
    });

    return (
        <Wrapper
            transform={transform}
            transition={transition}
            isDragging={isDragging}
            ref={setNodeRef}
            {...listeners}
            {...attributes}
        >
            {children}
        </Wrapper>
    );
};
