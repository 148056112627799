import { isPresent } from '@luminovo/commons';
import { SolutionConfigurationDTO } from '@luminovo/http-client';

export function hasSolutionConfigurationManualCost({
    manual_unit_costs,
    manual_one_time_costs,
}: Pick<SolutionConfigurationDTO, 'manual_unit_costs' | 'manual_one_time_costs'>): boolean {
    return (
        (isPresent(manual_unit_costs) && manual_unit_costs.length > 0) ||
        (isPresent(manual_one_time_costs) && manual_one_time_costs.length > 0)
    );
}
