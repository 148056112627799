import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Text } from '@luminovo/design-system';
import { Box, Tooltip } from '@mui/material';
import { UnknownValue } from '../../../utils';

export const AvailabilityInfoBox: React.FunctionComponent<{
    label: string;
    formatedValue: string | undefined;
}> = ({ label, formatedValue }): JSX.Element => (
    <Tooltip arrow title={isPresent(formatedValue) ? '' : t`Unknown`}>
        <Box
            display={'grid'}
            gridTemplateRows="auto auto"
            alignItems={'center'}
            justifyItems={'center'}
            padding="16px 4px"
            rowGap={'4px'}
            border={`1px solid ${colorSystem.neutral[2]}`}
            borderRadius="8px"
        >
            <Text variant="h5" color={colorSystem.neutral[9]}>
                {formatedValue ?? <UnknownValue />}
            </Text>
            <Text variant="body-small" color={colorSystem.neutral[7]} style={{ textAlign: 'center' }}>
                {label}
            </Text>
        </Box>
    </Tooltip>
);
