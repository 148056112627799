import * as React from 'react';

/**
 * Similar to React.useState but stores its state in a `storage` (e.g. sessionStorage, or localStorage).
 *
 * Note: Only store strings to avoid having to deal with (de)serialization issues.
 */
export function usePersistedState<T extends string | undefined>(
    storageKey: string,
    initialValue: T,
    storage: Storage = sessionStorage,
): [T, (newState: T) => void] {
    const [state, setState] = React.useState(() => {
        // eslint-disable-next-line
        const storedItem = storage.getItem(storageKey) as T;
        return storedItem ?? initialValue;
    });

    const handler = React.useCallback(
        (value: T) => {
            setState(value);
            if (value === undefined) {
                storage.removeItem(storageKey);
            } else {
                storage.setItem(storageKey, value);
            }
        },
        [storage, storageKey, setState],
    );

    return [state, handler];
}
