import { t } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import DialogBox, { DialogBoxBaseProps } from './DialogBox';

export const InformationDialogBoxContent = ({
    text,
    children,
}: {
    text: string;
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <Box>
            <Typography variant={'body1'}>{text}</Typography>
            <Box mt={2} overflow={'auto'} minHeight={'40vh'}>
                {children}
            </Box>
        </Box>
    );
};

interface InformationDialogBoxProps extends DialogBoxBaseProps {
    title: string | JSX.Element;
    children: JSX.Element;
    buttonText?: string;
}
const InformationDialogBox: React.FunctionComponent<InformationDialogBoxProps> = ({
    onReject,
    isDialogOpen,
    title,
    children,
    buttonText,
}: InformationDialogBoxProps): JSX.Element => {
    return (
        <DialogBox
            title={title}
            isDialogOpen={isDialogOpen}
            onReject={onReject}
            maxWidth={'sm'}
            closeIconIsShown={false}
        >
            <Box>
                <Box maxWidth={'100%'}>{children}</Box>
                <Box mt={2}>
                    <Grid container direction={'row-reverse'}>
                        <Grid item>
                            <SecondaryButton onClick={onReject}>{buttonText ? buttonText : t`Cancel`}</SecondaryButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </DialogBox>
    );
};

export default InformationDialogBox;
