import { t } from '@lingui/macro';
import { Message } from '@luminovo/design-system';
import { useHistory } from 'react-router';
import { BomItemTableData, isBomItemTableData, ModuleTableData } from '../../../Bom/components/ModuleTableData';
import { FilterId } from '../../../Bom/components/ModuleTableData/filters';

function findFirstBomItemWithFilterId({
    moduleTableData,
    filterId,
}: {
    moduleTableData: ModuleTableData[];
    filterId: FilterId;
}): BomItemTableData | undefined {
    const result = moduleTableData.find((item) => {
        if (isBomItemTableData(item)) {
            return item.filterIds.has(filterId);
        }
        return false;
    }) as BomItemTableData | undefined;

    return result;
}

export const MessageForPreviousBomImportChanges = ({
    moduleTableData,
    appliedFilters,
    setAppliedFilters,
}: {
    moduleTableData: ModuleTableData[];
    appliedFilters: Set<FilterId>;
    setAppliedFilters: (filters: Set<FilterId>) => void;
}) => {
    const history = useHistory();
    const firstBomItem = findFirstBomItemWithFilterId({
        moduleTableData,
        filterId: FilterId.HasChangesFromPreviousImport,
    });
    const handleOnClick = () => {
        const filterId = FilterId.HasChangesFromPreviousImport;
        const newFilters = new Set(appliedFilters);
        if (appliedFilters.has(filterId)) {
            return;
        } else {
            newFilters.add(filterId);
        }
        setAppliedFilters(newFilters);

        if (firstBomItem) {
            const search = new URLSearchParams(history.location.search);
            search.set('designItemId', firstBomItem.id.join(','));
            history.replace({ search: search.toString() });
        }
    };
    return (
        <Message
            attention="high"
            variant="blue"
            size="large"
            title={t`Part changes from previous import`}
            message={t`We recognised identical line values from a previous import. You can filter for these BOM items with potential changes.`}
            action={{
                label: t`Show BOM items`,
                onClick: handleOnClick,
            }}
        />
    );
};
