import { t, Trans } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { colorSystem, DataTable, Flexbox, SecondaryButton, Toolbar, useDataTableState } from '@luminovo/design-system';
import { AssemblyResponseDTO, CalculationAssemblyDTO, http, ScenarioCostDTO } from '@luminovo/http-client';
import { HelpIcon } from '@luminovo/manufacturing-core';
import { GetApp } from '@mui/icons-material';
import { Divider, styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useCommunicationsDrawer, ViewCommentsButton } from '../../../components/CommentsDrawer';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { PageLayout } from '../../../components/PageLayout';
import { useToken } from '../../../resources/hooks';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useCalculationWithoutManufacturingEnabled } from '../../../resources/organizationSettings/calculationWithoutManufacturingHandler';
import { route } from '../../../utils/routes';
import {
    CalculationAssemblyDetailsReset,
    CalculationAssemblyDetailsSwitchButton,
} from '../CalculationAssemblyHeader/CalculationAssemblyDetails';
import { CalculationApprovalSwitchableChip, CalculationStatus } from './CalculationApprovalSwitcher';
import { CalculationWarnings } from './CalculationWarnings';
import { CostOptionsMenu } from './costOptionsMenu/CostOptionsMenu';
import { IncludeExcessMaterialCheckbox } from './costOptionsMenu/IncludeMaterialCostCheckbox';
import { ProjectCostCheckbox } from './costOptionsMenu/ProjectCostCheckbox';
import { generateColumns } from './generateColumns';
import { ProjectCostBreakdownConfig } from './types/conversionTypes';
import { CalculationDetails, CalculationTableForm } from './types/formTypes';
import { Row } from './types/rowTypes';
import { CalculationWarning, collateCalculationWarnings } from './utils/calculationWarnings';
import { convertCostsToRows } from './utils/convertCostsToRows/convertCostsToRows';
import { convertRowsToCosts } from './utils/convertRowsToCosts/convertRowsToCosts';
import { hasChangedSinceReset } from './utils/hasChangedSinceReset';

const StyledContainer = styled('div')({
    //eslint-disable-next-line spellcheck/spell-checker
    '& th': {
        background: 'white',
        color: colorSystem.neutral.white,

        borderTop: `8px solid ${colorSystem.neutral[1]}`,
        borderBottom: `8px solid ${colorSystem.neutral[1]}`,
    },
    '& th:nth-of-type(1)': {
        borderTop: `0px`,
    },
    '& th:nth-of-type(odd)': {
        borderRight: `8px solid ${colorSystem.neutral[1]}`,
    },
    '& td:nth-of-type(odd)': {
        borderRight: `8px solid ${colorSystem.neutral[1]}`,
    },
});

function useMutationUpdateCosts() {
    return useHttpMutation('PATCH /calculations/calculation-assembly-costs/:calculationAssemblyCostId', {
        snackbarMessage: null,
    });
}

function useMutationUpdateCalculationAssemblyDetails() {
    return useHttpMutation('PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId', {
        snackbarMessage: null,
    });
}

const useProjectCostBreakdownConfig = (): ProjectCostBreakdownConfig => {
    const [isVisible, setIsVisible] = React.useState(true);
    return {
        isVisible,
        toggleVisibility: () => setIsVisible((prevVisibility) => !prevVisibility),
    };
};

export const CalculationTableFormWrapper = ({
    assemblyDetails,
    rfqId,
    calculationAssemblyDetails,
    setIsDialogOpen,
    scenarioCost,
    calculationAssemblyCostId,
    costsUpdatedAt,
    rfqCurrency,
    calculationStatus,
    isFetching,
}: {
    assemblyDetails: AssemblyResponseDTO;
    rfqId: string;
    calculationAssemblyCostId: string;
    calculationAssemblyDetails: CalculationAssemblyDTO;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scenarioCost: ScenarioCostDTO[];
    costsUpdatedAt: string | null;
    rfqCurrency: Currency;
    calculationStatus: CalculationStatus;
    isFetching: boolean;
}): JSX.Element => {
    const costs: ScenarioCostDTO[] = scenarioCost;
    const preferredCurrency = rfqCurrency;
    const { mutateAsync } = useMutationUpdateCosts();
    const { mutateAsync: calculationAssemblyDetailsUpdate } = useMutationUpdateCalculationAssemblyDetails();
    const defaultCalculationDetails: CalculationDetails = {
        includeExcessMaterialInMaterialCosts: calculationAssemblyDetails.include_excess_material_in_material_cost,
        isProjectCostsSeparateFromManufacturingCost:
            !calculationAssemblyDetails.include_project_cost_in_manufacturing_cost,
        isCalculationTemplateApplied: calculationAssemblyDetails.created_from_calculation_template_id !== null,
    };
    const { data: calculationWithoutManufacturingSettings } = useCalculationWithoutManufacturingEnabled();
    const shouldShowManufacturingRows =
        calculationWithoutManufacturingSettings?.calculation_without_manufacturing_enabled ?? true;

    const projectCostBreakdownConfig = useProjectCostBreakdownConfig();
    const [isDataStale, setIsDataStale] = React.useState(false);

    const rows: Row[] = convertCostsToRows({
        costs: costs,
        calculationAssemblyDetails: defaultCalculationDetails,
        preferredCurrency,
        isCalculationWithoutManufacturing: shouldShowManufacturingRows,
        projectCostBreakdownConfig,
    });
    const defaultFormValues: CalculationTableForm = {
        rows,
        calculationDetails: defaultCalculationDetails,
        updateMethod: 'details-then-costs',
        isCalculationFrozen: calculationStatus.type === 'Frozen',
        isProjectCostBreakdownVisible: projectCostBreakdownConfig.isVisible,
        isCalculationFetching: isFetching || isDataStale,
    };
    const onSubmit = async (data: CalculationTableForm) => {
        setIsDataStale(true);

        if (data.updateMethod === 'costs-only') {
            await mutateAsync({
                requestBody: {
                    costs: convertRowsToCosts(data.rows),
                },
                pathParams: { calculationAssemblyCostId },
            });
        } else {
            await calculationAssemblyDetailsUpdate({
                pathParams: { calculationAssemblyDetailsId: calculationAssemblyDetails.id },
                requestBody: {
                    ...calculationAssemblyDetails,
                    /* eslint-disable-next-line camelcase */
                    include_excess_material_in_material_cost:
                        data.calculationDetails.includeExcessMaterialInMaterialCosts,
                    /* eslint-disable-next-line camelcase */
                    include_project_cost_in_manufacturing_cost:
                        !data.calculationDetails.isProjectCostsSeparateFromManufacturingCost,
                },
            }).then((assemblyData) => {
                return {
                    includeExcessMaterialInMaterialCosts: assemblyData.data.include_excess_material_in_material_cost,
                    isProjectCostsSeparateFromManufacturingCost:
                        !assemblyData.data.include_project_cost_in_manufacturing_cost,
                    isCalculationTemplateApplied: assemblyData.data.created_from_calculation_template_id !== null,
                };
            });
            await mutateAsync({
                requestBody: {
                    costs: convertRowsToCosts(data.rows),
                },
                pathParams: { calculationAssemblyCostId },
            });
        }

        setIsDataStale(false);
    };

    return (
        //TODO: The prop check is disabled https://www.notion.so/luminovo/Make-default-values-of-the-CalculationTableFormWrapper-stable-3754136f57bf472a9b86e5b1bc154fa9
        // because as of 17-04-2024, this did not seem to be the source of any bugs and fixing it had too high complexity.
        <FormContainer defaultValues={defaultFormValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck={true}>
            <CalculationPageLayout
                assemblyDetails={assemblyDetails}
                calculationAssemblyDetails={calculationAssemblyDetails}
                onSubmit={onSubmit}
                rfqId={rfqId}
                setIsDialogOpen={setIsDialogOpen}
                costsUpdatedAt={costsUpdatedAt}
                calculationStatus={calculationStatus}
                bomWarnings={collateCalculationWarnings(scenarioCost)}
                // updating the defaultValues does not trigger a re-render, hence we are passing down as props
                // and using a useEffect in CalculationPageLayout to reset the form
                defaultValues={defaultFormValues}
                isCalculationFetching={isFetching}
                isCalculationStale={isDataStale}
            />
        </FormContainer>
    );
};

function useDownloadCalculationCost(calculationAssemblyDetailsId: string) {
    const { token } = useToken();
    const debugErrorHandler = useDebugErrorHandler();

    return useMutation({
        mutationFn: async () => {
            return http(
                'GET /export/calculation-cost-xlsx',
                /* eslint-disable-next-line camelcase */
                { queryParams: { calculation_assembly_details_id: calculationAssemblyDetailsId } },
                token,
            );
        },
        onSuccess: async (response) => {
            fileDownload(response.blob, response.fileName);
        },
        onError: debugErrorHandler,
    });
}

function CalculationCommentsButton({
    rfqId,
    calculationAssemblyDetailsId,
}: {
    rfqId: string;
    calculationAssemblyDetailsId: string;
}) {
    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads: [
            {
                commentType: 'Calculation',
                category: 'Internal',
                typeIds: calculationAssemblyDetailsId,
            },
        ],
    });

    return (
        <ViewCommentsButton
            eventEntity={{ type: 'Calculation', data: calculationAssemblyDetailsId }}
            onClick={() => openDrawer()}
        />
    );
}

const CalculationPageLayout = ({
    assemblyDetails,
    rfqId,
    calculationAssemblyDetails,
    setIsDialogOpen,
    onSubmit,
    costsUpdatedAt,
    bomWarnings,
    calculationStatus,
    defaultValues,
    isCalculationFetching,
    isCalculationStale,
}: {
    assemblyDetails: AssemblyResponseDTO;
    rfqId: string;
    calculationAssemblyDetails: CalculationAssemblyDTO;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: (data: CalculationTableForm) => Promise<void>;
    costsUpdatedAt: string | null;
    bomWarnings: CalculationWarning[];
    calculationStatus: CalculationStatus;
    defaultValues: CalculationTableForm;
    isCalculationFetching: boolean;
    isCalculationStale: boolean;
}): JSX.Element => {
    const { mutateAsync: downloadCalculationCost } = useDownloadCalculationCost(calculationAssemblyDetails.id);

    const [hasFormChanged, setFormChanged] = React.useState<boolean>(
        hasChangedSinceReset(
            calculationAssemblyDetails.last_template_reset_at,
            calculationAssemblyDetails.updated_at,
            costsUpdatedAt,
        ),
    );
    const { control, reset, setValue } = useFormContext<CalculationTableForm>();

    useEffect(() => {
        setValue('isCalculationFetching', isCalculationFetching || isCalculationStale);
    }, [isCalculationFetching, isCalculationStale, setValue]);

    //as at @21-08-2023, useEffect seemed to be the prefferred way to reset the form https://react-hook-form.com/docs/useform/reset
    useEffect(() => {
        if (!isCalculationFetching && !isCalculationStale) {
            reset(defaultValues);
        }
        // Notice that we don't include all default values as we only want the value to change when the calculation frozen state changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, isCalculationFetching, isCalculationStale]);
    const isCalculationFrozen = useWatch({ control, name: 'isCalculationFrozen' });

    return (
        <PageLayout
            layout="fragment"
            header={
                <>
                    {assemblyDetails && (
                        <div style={{ marginBottom: 1 }}>
                            <Toolbar
                                breadcrumbs={[
                                    {
                                        href: route(`/rfqs/:rfqId/calculation`, {
                                            rfqId,
                                        }),
                                        title: t`Calculation`,
                                    },
                                    {
                                        href: route(`/rfqs/:rfqId/calculation`, {
                                            rfqId,
                                        }),
                                        title: t`Assembly` + ' ' + assemblyDetails.designator,
                                    },
                                ]}
                                children={
                                    <Flexbox gap={8} alignItems={'center'} whiteSpace="pre">
                                        <CalculationAssemblyDetailsReset
                                            calculationAssemblyDetails={calculationAssemblyDetails}
                                            hasFormChanged={hasFormChanged}
                                        />
                                        <CalculationAssemblyDetailsSwitchButton
                                            setIsDialogOpen={setIsDialogOpen}
                                            calculationAssemblyDetails={calculationAssemblyDetails}
                                        />
                                        <SecondaryButton
                                            onClick={() => downloadCalculationCost()}
                                            startIcon={<GetApp />}
                                            size="medium"
                                        >
                                            <Trans>Export calculation</Trans>
                                        </SecondaryButton>
                                        <CalculationCommentsButton
                                            rfqId={rfqId}
                                            calculationAssemblyDetailsId={calculationAssemblyDetails.id}
                                        />
                                        <Divider orientation="vertical" flexItem />
                                        <CalculationApprovalSwitchableChip calculationStatus={calculationStatus} />
                                        <HelpIcon
                                            tooltipTitle={t`Approve and freeze this calculation to prevent any further changes to the calculation.`}
                                        />
                                    </Flexbox>
                                }
                                left={
                                    <Flexbox marginLeft={4}>
                                        {!isCalculationFrozen && (
                                            <CostOptionsMenu>
                                                <ProjectCostCheckbox />
                                                <IncludeExcessMaterialCheckbox />
                                            </CostOptionsMenu>
                                        )}
                                    </Flexbox>
                                }
                            />
                        </div>
                    )}
                </>
            }
        >
            <>
                <CalculationWarnings bomWarnings={bomWarnings} rfqId={rfqId} assemblyId={assemblyDetails.id} />
                <CalculationTable
                    onSubmit={onSubmit}
                    assemblyId={calculationAssemblyDetails.assembly_id}
                    setFormChanged={setFormChanged}
                />
            </>
        </PageLayout>
    );
};

const CalculationTable = ({
    onSubmit,
    assemblyId,
    setFormChanged,
}: {
    onSubmit: (data: CalculationTableForm) => Promise<void>;
    assemblyId: string;
    setFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const { control, watch, formState } = useFormContext<CalculationTableForm>();
    const isProjectCostsSeparateFromManufacturingCost: boolean = useWatch({
        control,
        name: 'calculationDetails.isProjectCostsSeparateFromManufacturingCost',
    });
    const numberOfTouches = Object.keys(formState.touchedFields).length;
    const numberOfTouchedRows = formState.touchedFields.rows ? formState.touchedFields.rows.length : 0;
    const numberOfTouchedFieldsCalculationDetails = formState.touchedFields.calculationDetails
        ? Object.keys(formState.touchedFields.calculationDetails).length
        : 0;
    const { insert, remove, update } = useFieldArray({
        control,
        name: 'rows',
    });

    const resetAsyncForm = React.useCallback(
        async (updateMethod: CalculationTableForm['updateMethod']) => {
            await onSubmit({ ...watch(), updateMethod });
            setFormChanged(true);
        },
        // Adding onSubmit and watch to the dependency array caused multiple PATCH requests to be sent
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    React.useEffect(() => {
        if (numberOfTouchedRows > 0 && numberOfTouchedFieldsCalculationDetails === 0) {
            resetAsyncForm('costs-only');
        } else if (numberOfTouchedRows === 0 && numberOfTouchedFieldsCalculationDetails > 0) {
            resetAsyncForm('details-then-costs');
        } else if (numberOfTouches > 0 || formState.isDirty) {
            resetAsyncForm('details-then-costs');
        }
    }, [
        numberOfTouches,
        formState.isDirty,
        resetAsyncForm,
        numberOfTouchedRows,
        numberOfTouchedFieldsCalculationDetails,
    ]);

    const rows = useWatch({ control, name: 'rows' });
    const isCalculationFrozen = useWatch({ control, name: 'isCalculationFrozen' });
    const columns = generateColumns({
        insert,
        remove,
        update,
        rows: rows,
        isProjectCostsSeparateFromManufacturingCost,
        sourcingCacheParams: { assemblyId },
        isCalculationFrozen,
    });
    const tableState = useDataTableState({
        persistenceId: 'calculationTableVersion2',
        items: rows,
        columns: columns,
        paginationOptions: { showPagination: false, defaultRowsPerPage: Number.MAX_SAFE_INTEGER }, //infinity would have been ideal but caused a bug that the table was not being rendered.
    });

    return <DataTable tableState={tableState} overrides={{ Container: StyledContainer }} size={'large'} />;
};
