import { Trans, t } from '@lingui/macro';
import { compareByString, formatToLongDate, getIntercomArticleURL, isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    DestructiveTertiaryIconButton,
    Flexbox,
    Link,
    NonIdealState,
    SearchField,
    SecondaryButton,
    Tag,
    Text,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import { ManufacturerDTO, SupplierAndStockLocationDTO, SupplierLineCardDTO, SupplierTag } from '@luminovo/http-client';
import { formatSupplierDTO, hasSupplierTag } from '@luminovo/sourcing-core';
import { Add, AllInclusive, AltRouteRounded, Close, Delete } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { FixedSizeList } from 'react-window';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { usePartCategory } from '../../../resources/part/partHandler';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../../utils/routes';
import { useDialogAddManufacturerRestriction } from './ManufacturerRestrictionDialog/AddManufacturerRestrictionDialog';
import { useDialogEditSupplierLineCard } from './SupplierLineCardDialog/EditSupplierLineCardDialog';
import { useSupplierLineCardExplanationDrawer } from './SupplierLineCardExplanationDrawer';

export const CopyToLineCardButton: React.FunctionComponent<{
    manufacturers: ManufacturerDTO[];
}> = ({ manufacturers }) => {
    const initialState = manufacturers.length > 1 ? 'idle' : 'disabled';
    const [state, setState] = React.useState<'idle' | 'copied' | 'failed' | 'disabled'>(initialState);

    const handleCopyClick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(manufacturers.map((m) => m.name).join('; \n'));
            setState('copied');
        } catch (error) {
            setState('failed');
        }
    };

    const title = {
        idle: t`Copy to Clipboard`,
        copied: t`Copied`,
        failed: t`Failed to Copy`,
        disabled: '',
    };

    return (
        <Tooltip title={title[state]}>
            <SecondaryButton
                size="medium"
                disabled={state === 'disabled'}
                onClick={handleCopyClick}
                onMouseLeave={() => setState(initialState)}
                style={{ whiteSpace: 'nowrap' }}
            >
                <Trans>Copy line card</Trans>
            </SecondaryButton>
        </Tooltip>
    );
};

const LineCardTable: React.FunctionComponent<{ supplierAndStockLocation: SupplierAndStockLocationDTO }> = ({
    supplierAndStockLocation,
}) => {
    const history = useHistory();
    const [query, setQuery] = React.useState<string | null>(null);

    const { data: supplierAndStockLocations = [] } = useSupplierAndStockLocations();

    const { openDialog: openEditSupplierLineCardDialog } = useDialogEditSupplierLineCard({
        supplierId: supplierAndStockLocation.supplier.id,
    });

    const { data: lineCard, isLoading } = useHttpQuery('GET /suppliers/:id/line-card', {
        pathParams: { id: supplierAndStockLocation.supplier.id },
    });
    const manufacturers = lineCard?.manufacturers.sort((a, b) => compareByString(a.name, b.name)) ?? [];

    const filteredManufacturers = manufacturers.filter(
        (m) => !isPresent(query) || m.name.toLowerCase().includes(query.toLowerCase().trim()),
    );

    const isManualyAdded = hasSupplierTag(supplierAndStockLocation, SupplierTag.ManuallyAdded);
    const hasNoModificationHistory = lineCard?.modification_history.length === 0;
    const isEmpty = manufacturers.length === 0;
    const hasNoMatch = filteredManufacturers.length === 0;

    const ManufacturerRow = ({
        index,
        style,
        data,
    }: {
        index: number;
        style: React.CSSProperties;
        data: ManufacturerDTO[];
    }) => {
        const manufacturer = data[index];

        const isRestrictedForThisSupplier =
            isPresent(lineCard) &&
            lineCard.manufacturers
                .filter((m) => m.id === manufacturer.id)
                .some((m) => m.restricted_to_suppliers.some((id) => id === supplierAndStockLocation.supplier.id));

        const restrictedForOtherSuppliers = (lineCard?.manufacturers ?? [])
            .filter((m) => m.id === manufacturer.id)
            .flatMap((m) => m.restricted_to_suppliers.filter((id) => id !== supplierAndStockLocation.supplier.id))
            .flatMap(
                (id) =>
                    supplierAndStockLocations.find(
                        (supplierAndStockLocation) => supplierAndStockLocation.supplier.id === id,
                    ) ?? [],
            );

        return (
            <Flexbox
                style={{ boxSizing: 'border-box', ...style }}
                alignItems={'center'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
                paddingX={'12px'}
                gap={'8px'}
                onClick={() => history.push(route('/manufacturer/:manufacturerId', { manufacturerId: data[index].id }))}
            >
                <Text variant={'body'}>{data[index].name}</Text>
                <Flexbox flexGrow={1} />
                {isRestrictedForThisSupplier && (
                    <Text variant={'caption'} color={colorSystem.primary[7]}>
                        Restricted for {formatSupplierDTO(supplierAndStockLocation.supplier)}
                    </Text>
                )}
                {restrictedForOtherSuppliers.length > 0 && !isRestrictedForThisSupplier && (
                    <Tooltip
                        variant="white"
                        title={
                            <Flexbox flexDirection={'column'} gap={4}>
                                <Text variant={'body-small-semibold'}>
                                    <Trans>Restricted for:</Trans>
                                </Text>
                                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                    {restrictedForOtherSuppliers.map((supplierAndStockLocation) => (
                                        <li key={supplierAndStockLocation.id}>
                                            <Text variant={'body-small'}>
                                                {formatSupplierDTO(supplierAndStockLocation.supplier)}
                                            </Text>
                                        </li>
                                    ))}
                                </ul>
                            </Flexbox>
                        }
                    >
                        <Text variant={'caption'} color={colorSystem.neutral[7]}>
                            Restricted for other suppliers
                        </Text>
                    </Tooltip>
                )}
            </Flexbox>
        );
    };

    return (
        <Flexbox flexDirection={'column'} gap={8} width={'100%'}>
            <Flexbox gap={12} alignItems={'center'}>
                <SearchField
                    size={'small'}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ width: '70%' }}
                />
                <CopyToLineCardButton key={manufacturers.length} manufacturers={manufacturers} />
                <SecondaryButton
                    size="medium"
                    onClick={() => openEditSupplierLineCardDialog()}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Trans>Edit line card</Trans>
                </SecondaryButton>
            </Flexbox>
            <Box
                style={{
                    border: `1px solid ${colorSystem.neutral[2]}`,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                <Flexbox
                    padding={'8px 12px'}
                    gap={'8px'}
                    bgcolor={colorSystem.neutral[0]}
                    alignItems={'center'}
                    borderBottom={`1px solid ${colorSystem.neutral[2]}`}
                >
                    <Text variant="h5">
                        <Trans>Manufacturer</Trans>
                    </Text>
                    <Flexbox flexGrow={1} />
                    {manufacturers.length > 0 && (
                        <Text variant={'body-small'}>{t`${manufacturers.length} manufacturers`}</Text>
                    )}
                </Flexbox>
                {isLoading ? (
                    <CenteredLayout height={600}>
                        <CircularProgress />
                    </CenteredLayout>
                ) : isManualyAdded && hasNoModificationHistory ? (
                    <CenteredLayout height={640}>
                        <NonIdealState
                            Icon={AllInclusive}
                            title={t`All manufacturers are supported`}
                            description={t`Manualy added suppliers don't have a line card therefore all manufacturers are supported.`}
                        />
                    </CenteredLayout>
                ) : isEmpty ? (
                    <CenteredLayout height={600}>
                        <NonIdealState
                            Icon={Close}
                            title={t`Empty line card`}
                            description={t`This supplier doesn't support any manufacturers.`}
                        />
                    </CenteredLayout>
                ) : hasNoMatch ? (
                    <CenteredLayout height={600}>
                        <NonIdealState
                            title={t`No match`}
                            description={<Trans>No exact matches for "{query}"</Trans>}
                        />
                    </CenteredLayout>
                ) : (
                    <FixedSizeList
                        itemData={filteredManufacturers}
                        itemCount={filteredManufacturers.length}
                        width="100%"
                        height={600}
                        itemSize={32}
                        overscanCount={5}
                    >
                        {ManufacturerRow}
                    </FixedSizeList>
                )}
            </Box>
        </Flexbox>
    );
};

const ManufacturerRestrictionItem: React.FunctionComponent<{
    supplierLineCard: SupplierLineCardDTO;
    manufacturer: ManufacturerDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}> = ({ supplierLineCard, manufacturer, supplierAndStockLocation }) => {
    const numberOfSupplierRestrictions = supplierLineCard?.manufacturers.find((m) => m.id === manufacturer.id)
        ?.restricted_to_suppliers.length;

    const numberOfPartCategoryRestrictions = supplierLineCard?.manufacturers.find((m) => m.id === manufacturer.id)
        ?.part_category_restriction.length;

    const partCategoryId = supplierLineCard?.manufacturers
        .find((m) => m.id === manufacturer.id)
        ?.part_category_restriction.find((p) => p.supplier === supplierAndStockLocation.supplier.id)?.restrict_to[0];

    const { data: partCategory } = usePartCategory(partCategoryId);

    if (isPresent(numberOfSupplierRestrictions) && numberOfSupplierRestrictions !== 0) {
        return (
            <Flexbox gap={4} alignItems={'center'} paddingX={'12px'}>
                <Text variant={'body'}>
                    <Trans>Requests</Trans>
                </Text>
                <Tag label={manufacturer.name} attention={'low'} color={'neutral'} />
                <Text variant={'body'} style={{ whiteSpace: 'nowrap' }}>
                    <Trans>parts only to</Trans>
                </Text>
                <Tag label={formatSupplierDTO(supplierAndStockLocation.supplier)} attention={'low'} color={'primary'} />
                {numberOfSupplierRestrictions > 1 && (
                    <Text variant={'body'} style={{ whiteSpace: 'nowrap' }}>
                        {t`and ${numberOfSupplierRestrictions - 1} other suppliers`}
                    </Text>
                )}
                <Flexbox flexGrow={1} />
            </Flexbox>
        );
    }

    if (isPresent(numberOfPartCategoryRestrictions) && numberOfPartCategoryRestrictions !== 0) {
        return (
            <Text
                variant={'body'}
                style={{ whiteSpace: 'nowrap', display: 'inline-flex', gap: '4px', padding: '0px 12px' }}
            >
                <Trans>
                    Requests for <Tag label={manufacturer.name} attention={'low'} color={'neutral'} /> parts to
                    <Tag
                        label={formatSupplierDTO(supplierAndStockLocation.supplier)}
                        attention={'low'}
                        color={'primary'}
                    />
                    {numberOfPartCategoryRestrictions > 1 ? (
                        <Text variant={'body'} style={{ whiteSpace: 'nowrap' }}>
                            {t`and ${numberOfPartCategoryRestrictions - 1} other suppliers`}
                        </Text>
                    ) : (
                        ''
                    )}
                    only for
                    <Tag label={partCategory?.name ?? t`Unknown`} attention={'low'} color={'primary'} />
                </Trans>
            </Text>
        );
    }

    return <></>;
};

const ManufacturerRestrictionsTable: React.FunctionComponent<{
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}> = ({ supplierAndStockLocation }) => {
    const { openDialog: openAddManufacturerRestriction } = useDialogAddManufacturerRestriction({
        supplierAndStockLocation: supplierAndStockLocation,
    });

    const { data: supplierLineCard } = useHttpQuery('GET /suppliers/:id/line-card', {
        pathParams: { id: supplierAndStockLocation.supplier.id },
    });

    const restrictedManufacturers =
        supplierLineCard?.manufacturers.filter((m) => {
            return (
                m.restricted_to_suppliers.some((id) => id === supplierAndStockLocation.supplier.id) ||
                m.part_category_restriction.some((p) => p.supplier === supplierAndStockLocation.supplier.id)
            );
        }) ?? [];

    const { mutateAsync } = useHttpMutation('PATCH /suppliers/manufacturer-restrictions/:id', {
        snackbarMessage: t`Manufacturer restriction removed`,
    });

    const { openDrawer } = useSupplierLineCardExplanationDrawer();

    return (
        <Flexbox flexDirection={'column'} gap={8} width={'100%'}>
            <Box
                style={{
                    border: `1px solid ${colorSystem.neutral[2]}`,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%',
                    height: 'fit-content',
                }}
            >
                <Flexbox
                    padding={'12px 12px'}
                    gap={'8px'}
                    bgcolor={colorSystem.neutral[0]}
                    alignItems={'center'}
                    borderBottom={`1px solid ${colorSystem.neutral[2]}`}
                >
                    <Text variant="h5">
                        <Trans>Manufacturer restrictions</Trans>
                    </Text>
                </Flexbox>
                {!isPresent(supplierLineCard) ? (
                    <CenteredLayout height={240}>
                        <CircularProgress />
                    </CenteredLayout>
                ) : restrictedManufacturers.length === 0 ? (
                    <CenteredLayout height={240}>
                        <NonIdealState
                            Icon={AltRouteRounded}
                            title={t`No restriction`}
                            description={t`No manufacturer restrictions for this supplier.`}
                        />
                    </CenteredLayout>
                ) : (
                    <Flexbox flexDirection={'column'}>
                        {restrictedManufacturers.map((manufacturer) => {
                            return (
                                <Flexbox
                                    key={manufacturer.id}
                                    boxSizing={'border-box'}
                                    borderBottom={`1px solid ${colorSystem.neutral[2]}`}
                                    height={'44px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <ManufacturerRestrictionItem
                                        supplierLineCard={supplierLineCard}
                                        manufacturer={manufacturer}
                                        supplierAndStockLocation={supplierAndStockLocation}
                                    />
                                    <DestructiveTertiaryIconButton
                                        size="small"
                                        onClick={() => {
                                            mutateAsync({
                                                pathParams: { id: manufacturer.id },
                                                requestBody: {
                                                    suppliers: manufacturer.restricted_to_suppliers.filter(
                                                        (id) => id !== supplierAndStockLocation.supplier.id,
                                                    ),
                                                    part_category_restriction: [],
                                                },
                                            });
                                        }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        <Delete fontSize="inherit" />
                                    </DestructiveTertiaryIconButton>
                                </Flexbox>
                            );
                        })}
                    </Flexbox>
                )}
                <Flexbox
                    padding={'12px 12px'}
                    gap={'8px'}
                    bgcolor={colorSystem.neutral[0]}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    borderTop={restrictedManufacturers.length === 0 ? `1px solid ${colorSystem.neutral[2]}` : undefined}
                >
                    <SecondaryButton size="small" onClick={() => openAddManufacturerRestriction()} startIcon={<Add />}>
                        <Trans>Add restriction</Trans>
                    </SecondaryButton>
                    <Link onClick={() => openDrawer()}>
                        <Trans>What are restrictions?</Trans>
                    </Link>
                </Flexbox>
            </Box>
            <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                <Trans>
                    If you would like to see the linecard for all the suppliers visit our{' '}
                    <Link
                        attention={'high'}
                        variant={'body-small'}
                        href={getIntercomArticleURL(84788)}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        product article in our help center.
                    </Link>
                </Trans>
            </Text>
        </Flexbox>
    );
};

export function SupplierLineCardTab({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}) {
    const { data: lineCard } = useHttpQuery('GET /suppliers/:id/line-card', {
        pathParams: { id: supplierAndStockLocation.supplier.id },
    });

    const updateBy = lineCard?.modification_history[0]?.user;
    const updateDate = lineCard?.modification_history[0]?.updated_at ?? new Date(new Date().setDate(1));

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <Flexbox flexDirection={'column'}>
                    <Text variant={'h4'}>
                        <Trans>Line card</Trans>
                    </Text>
                    <Text variant="body-small" color={colorSystem.neutral[6]}>
                        <Flexbox gap={4} alignItems={'center'} minHeight={'24px'}>
                            {t`Updated by `}
                            {isPresent(updateBy) ? (
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    {`${updateBy.first_name} ${updateBy.last_name}`}
                                </Text>
                            ) : (
                                ' Luminovo '
                            )}
                            {t` on ${formatToLongDate(updateDate)}`}
                        </Flexbox>
                    </Text>
                </Flexbox>
            </Flexbox>

            <Flexbox gap={24} width={'100%'}>
                <LineCardTable supplierAndStockLocation={supplierAndStockLocation} />
                <ManufacturerRestrictionsTable supplierAndStockLocation={supplierAndStockLocation} />
            </Flexbox>
        </Flexbox>
    );
}
