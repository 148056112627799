import {
    DriverDTO,
    DriverStatusDetailsDTO,
    ExpenseConfigurationSummaryDTO,
    FormulaResponseDTO,
} from '@luminovo/http-client';
import { findStatusOfDriver, selectDriverIdDto, selectDriverName } from '@luminovo/manufacturing-core';
import {
    DriverCountInformation,
    getDriverCountInformationExpenseConfiguration,
} from '../ActivitiesTable/getDriverCountInformation';
import { useFormulaOfExpenseCongfiguration } from '../utils/useDriverOfCostComponentsExpenses';
import { useFormulaInformation } from '../utils/useDriversFormulaInformation';

interface DriverWithCountsExpenses {
    driver: DriverDTO;
    driverStatus: DriverStatusDetailsDTO | undefined;
    countInformation: DriverCountInformation;
}

interface UseDriversOfExpenseConfiguration {
    //this interface should be the same as the activity UseDriversOfActivity/UseDriversOfActivityConfiguration once the backend has been refactored.
    uniqueDrivers: DriverWithCountsExpenses[];
    formulaValidation: FormulaResponseDTO | undefined;
    isLoading: boolean;
}

export const useDriversOfExpenseConfiguration = (
    expense: ExpenseConfigurationSummaryDTO,
): UseDriversOfExpenseConfiguration => {
    const drivers = expense.used_drivers;
    const { formula, isLoading } = useFormulaOfExpenseCongfiguration(expense);
    const { validationResponse } = useFormulaInformation(formula);
    const driversWithCounts: DriverWithCountsExpenses[] = [...drivers]
        .map((driver) => {
            return {
                driver: driver,
                driverStatus: findStatusOfDriver(
                    selectDriverIdDto(driver),
                    expense.expense_configuration_details.cost_components.driver_statuses,
                ),
                countInformation: getDriverCountInformationExpenseConfiguration(
                    expense.expense_configuration_details.cost_components,
                    driver,
                ),
            };
        })
        .sort((driverA, driverB) => selectDriverName(driverA.driver).localeCompare(selectDriverName(driverB.driver)));

    return { uniqueDrivers: driversWithCounts, isLoading, formulaValidation: validationResponse };
};
