import { formatDecimal } from '@luminovo/commons';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {
    decimalSeparator?: '.' | ',';
}

export const parseQuantity: Parser<number, Opts> = async function (
    cells,
    { decimalSeparator = '.' },
    field,
): Promise<ParseResult<number>> {
    const parser = parse.quantity({ decimalSeparator });

    return applyParser({
        cells,
        parser,
        field,
        formatValue: formatDecimal,
    });
};
