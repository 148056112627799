import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Text, chainComparators, compareByNumber } from '@luminovo/design-system';
import { TimeSeries } from '@luminovo/viz';
import { Box } from '@mui/material';
import { ChartSpec } from './ChartRfq';

type Datum = {
    ids: string[];
    label: string;
    time: number;
    value: number;
};

type Keys = Exclude<keyof Datum, 'label' | 'ids'>;
const keys: Keys[] = ['time'];

const comparator = chainComparators<Datum>(compareByNumber((d) => -(d.time ?? 0)));

export const chartSpecCreatedDate: ChartSpec<Keys, Datum> = {
    id: 'rfqsByCreatedDate',
    keys,
    title: <Trans>RfQs by</Trans>,
    orderBy: comparator,
    aggregate(a, b) {
        return {
            ids: a.ids.concat(b.ids),
            label: a.label,
            time: a.time,
            value: a.value + b.value,
        };
    },
    map(data) {
        return {
            ids: [data.id],
            label: new Date(firstDayOfMonth(data.creation_date)).toISOString(),
            time: firstDayOfMonth(data.creation_date),
            value: 1,
        };
    },
    groupBy: [
        {
            extractor: (data) => firstDayOfMonth(data.creation_date).toString(),
            label: <Trans>Month</Trans>,
        },
    ],
    render: Chart,
};

function Chart({ data }: { data: Datum[] }) {
    return (
        <Box height={'40vh'} width={'70vw'} paddingY={'20px'}>
            <TimeSeries Tooltip={Tooltip} data={data} />
        </Box>
    );
}
function Tooltip({ datum }: { datum: { time: number; value: number } }) {
    const date = new Date(datum.time);
    return (
        <div>
            <Text>
                {getMonthInLocale(date)}: {formatDecimal(datum.value)}
            </Text>
        </div>
    );
}

export function firstDayOfMonth(date: string) {
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), 1).getTime();
}

function getMonthInLocale(date: Date) {
    return date.toLocaleDateString(undefined, { month: 'long', year: 'numeric' });
}
