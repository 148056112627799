import { t } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import { ProgressAwareTabs } from '../../../components/ProgressAwareTabs';
import { useAssembly, useBomBreadcrumbs } from '../../../resources/assembly/assemblyHandler';

type ToolbarPcbProps = {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
};

export function ToolbarPnp({ assemblyId, rfqId, currentParentAssemblyId }: ToolbarPcbProps) {
    const { data: assembly } = useAssembly(assemblyId);
    const breadcrumbs = useBomBreadcrumbs({ assemblyId, rfqId, assembly, currentParentAssemblyId });
    return (
        <Toolbar
            breadcrumbs={[...breadcrumbs, { title: t`PnP` }]}
            center={
                assembly && (
                    <ProgressAwareTabs
                        rfqId={rfqId}
                        assembly={assembly}
                        viewContext={{
                            type: 'WithinRfQ',
                            rfqId,
                        }}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )
            }
        />
    );
}
