import { t } from '@lingui/macro';
import { Currency, isPresent } from '@luminovo/commons';
import { FieldNumericControlled, Flexbox, FormItem, Switch } from '@luminovo/design-system';
import { InputAdornment, Typography, useTheme } from '@mui/material';
import { Control, FieldValues, Path, PathValue, useFormContext, useWatch } from 'react-hook-form';
import { displayCurrencySymbol } from '../../utils/currencyUtils';

type VolumeEstimateName<T, K extends Path<T>> = VolumeEstimateFormState extends PathValue<T, K> ? K : never;

export interface VolumeEstimateFormState {
    isEnabled: boolean;
    upper: number | undefined;
    lower: number | undefined;
}

interface Props<T extends FieldValues, K extends Path<T>> {
    name: VolumeEstimateName<T, K>;
    currencyName: Path<T>;
}

export const VolumeEstimateFormItem = <T extends FieldValues, K extends Path<T>>({
    name,
    currencyName,
}: Props<T, K>): JSX.Element => {
    const UPPER_BOUNDS_ERROR = t`Upper volume estimate must be greater than lower estimate`;
    const LOWER_BOUNDS_ERROR = t`Lower volume estimate must be smaller than upper estimate`;
    const { control, getValues, setValue, getFieldState, clearErrors } = useFormContext<T>();
    const theme = useTheme();
    const volumeEstimateFormState = useWatch({ control, name });
    const currency = useWatch({ control, name: currencyName });

    const makeField = (fieldName: Path<VolumeEstimateFormState>): Path<T> => {
        // tsc cannot check that this concatenation results in a Path<T>. As our typings
        // guarantee `name` to be a valid path and `fieldName` to be a field of the object
        // that `name` points to, we can make this type assertion.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return `${name}.${fieldName}` as Path<T>;
    };

    const lower = makeField('lower');
    const upper = makeField('upper');
    const isEnabled = makeField('isEnabled');

    const lowerError = getFieldState(lower).error?.message;
    const upperError = getFieldState(upper).error?.message;

    const volumeEstimate = getValues(name);
    const toggleVolumeEstimate = () => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setValue(isEnabled, !volumeEstimateFormState.isEnabled as PathValue<T, typeof isEnabled>);
    };

    const validateUpperEstimate = (value: unknown): string | undefined => {
        if (typeof value !== 'number') return undefined;

        if (lowerError === LOWER_BOUNDS_ERROR) {
            clearErrors(lower);
        }

        if (isPresent(volumeEstimate.lower) && volumeEstimate.lower >= value) {
            return UPPER_BOUNDS_ERROR;
        }
    };

    const validateLowerEstimate = (value: unknown): string | undefined => {
        if (typeof value !== 'number') return undefined;

        if (upperError === UPPER_BOUNDS_ERROR) {
            clearErrors(upper);
        }

        if (isPresent(volumeEstimate.upper) && volumeEstimate.upper <= value) {
            return LOWER_BOUNDS_ERROR;
        }
    };

    return (
        <FormItem
            label={
                <ToggleItem
                    label={t`Volume estimate`}
                    isEnabled={volumeEstimateFormState.isEnabled}
                    handleChange={toggleVolumeEstimate}
                />
            }
        >
            {volumeEstimateFormState.isEnabled && (
                <Flexbox flexDirection="row" alignItems="start" gap={theme.spacing(2)}>
                    <VolumeTextField
                        control={control}
                        name={lower}
                        label={t`Between`}
                        currency={currency}
                        validate={validateLowerEstimate}
                    />

                    <VolumeTextField
                        control={control}
                        name={upper}
                        label={t`and`}
                        currency={currency}
                        validate={validateUpperEstimate}
                    />
                </Flexbox>
            )}
        </FormItem>
    );
};

interface ToggleItemProps {
    isEnabled: boolean;
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
    label: string;
}

const ToggleItem = ({ isEnabled, handleChange, label }: ToggleItemProps): JSX.Element => {
    return (
        <Flexbox alignItems={'center'} gap={8}>
            <Switch checked={isEnabled} onChange={handleChange} color="secondary" />
            <Typography variant="h4" color={'textSecondary'}>
                {label}
            </Typography>
        </Flexbox>
    );
};

interface VolumeTextFieldProps<T extends FieldValues> {
    control: Control<T>;
    name: Path<T>;
    label: string;
    validate?: (num: unknown) => string | undefined;
    currency: Currency;
}

const VolumeTextField = <T extends FieldValues>({
    control,
    name,
    label,
    validate,
    currency,
}: VolumeTextFieldProps<T>): JSX.Element => {
    return (
        <>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
                {label}
            </Typography>

            <FieldNumericControlled
                control={control}
                min={1}
                required={false}
                name={name}
                validate={validate}
                FieldProps={{
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">{displayCurrencySymbol(currency)}</InputAdornment>
                        ),
                    },
                }}
            />
        </>
    );
};
