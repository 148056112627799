import { LumiQuoteRoute, PathParams, QueryParams } from './routes';

/**
 * Function to generate typesafe routes.
 *
 * Example:
 * ```ts
 * route(
 *  '/rfqs/:rfqId/bom/assembly/add-assembly',
 *  { rfqId:123 },           // path params
 *  { assemblyId: 'abc' }    // query params
 * )
 * ```
 *
 * Will expand to `/rfqs/123/bom/assembly/add-assembly?assemblyId=abc`
 */

export function route<T extends LumiQuoteRoute, TPathParams extends PathParams<T>, TQueryParams extends QueryParams<T>>(
    routeId: T,
    pathParams?: TPathParams,
    queryParams?: TQueryParams,
): string {
    const searchParams = new URLSearchParams();

    let pathPart: string = routeId;
    for (const [k, val] of Object.entries(pathParams ?? {})) {
        pathPart = pathPart.replace(`:${k}`, encodeURIComponent(val + ''));
    }

    for (const [k, val] of Object.entries(queryParams ?? {})) {
        if (val !== null && val !== undefined) {
            searchParams.set(k, val + '');
        }
    }

    const numberOfSearchParams = Array.from(searchParams.keys()).length;
    const searchPart = numberOfSearchParams === 0 ? '' : `?${searchParams.toString()}`;

    return pathPart + searchPart;
}
