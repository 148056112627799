import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    CenteredLayout,
    Checkbox,
    colorSystem,
    Flexbox,
    SearchField,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { ManufacturerDTO, SupplierLineCardDTO } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useManufacturers } from '../../../../resources/manufacturer/manufacturerHandler';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';

export type SupplierLineCardFormState = {
    manufacturers: ManufacturerDTO[];
};

type DisplayMode = 'included' | 'excluded';
type SelectionStatus = 'none' | 'all' | 'indeterminate';

function useWatchManufacturers() {
    const { control } = useFormContext<SupplierLineCardFormState>();

    const { data: manufacturers = [], isLoading } = useManufacturers();

    const includedManufacturers = useWatch({ control, name: 'manufacturers' });
    const excludedManufacturers = manufacturers.filter((m1) => !includedManufacturers.some((m2) => m1.id === m2.id));

    return {
        includedManufacturers,
        excludedManufacturers,
        manufacturers,
        isLoading,
    };
}

const DisplayModeButtonGroup: React.FunctionComponent<{
    displayMode: DisplayMode;
    onClick: (value: DisplayMode) => void;
}> = ({ displayMode, onClick }) => {
    const { includedManufacturers, excludedManufacturers } = useWatchManufacturers();

    return (
        <ButtonGroup size={'large'}>
            <ButtonGroupItem
                selected={displayMode === 'included'}
                onClick={() => onClick('included')}
                count={includedManufacturers.length}
            >
                <Trans>In the line card</Trans>
            </ButtonGroupItem>
            <ButtonGroupItem
                selected={displayMode === 'excluded'}
                onClick={() => onClick('excluded')}
                count={excludedManufacturers.length}
            >
                <Trans>Not in the line card</Trans>
            </ButtonGroupItem>
        </ButtonGroup>
    );
};

const PrefernceTableHeader: React.FunctionComponent<{
    displayMode: DisplayMode;
    selectionIds: string[];
    selectionStatus: SelectionStatus;
    toggleSelection: () => void;
    onClick: (displayMode: DisplayMode) => void;
}> = ({ displayMode, selectionIds, selectionStatus, toggleSelection, onClick }) => {
    return (
        <Flexbox padding={'8px 12px'} gap={'8px'} bgcolor={colorSystem.neutral[0]} alignItems={'center'}>
            <Checkbox
                size="small"
                checked={selectionStatus === 'all'}
                indeterminate={selectionStatus === 'indeterminate'}
                onClick={toggleSelection}
            />
            <Text variant="h5">
                <Trans>Manufacturer</Trans>
            </Text>
            <Flexbox flex={1} />
            <Flexbox gap={6} alignItems={'center'}>
                <Text variant="body-small">{t`${selectionIds.length} selected`}</Text>
                {displayMode !== 'included' && (
                    <SecondaryButton
                        size={'small'}
                        disabled={selectionStatus === 'none'}
                        onClick={() => onClick('included')}
                    >{t`Add to line card`}</SecondaryButton>
                )}
                {displayMode !== 'excluded' && (
                    <SecondaryButton
                        size={'small'}
                        disabled={selectionStatus === 'none'}
                        onClick={() => onClick('excluded')}
                    >{t`Remove`}</SecondaryButton>
                )}
            </Flexbox>
        </Flexbox>
    );
};

function getItemDataAndSelectionStatus({
    tableDisplayMode,
    includedManufacturers,
    excludedManufacturers,
    selectionIds,
    manufacturers,
    query = '',
}: {
    tableDisplayMode: DisplayMode;
    includedManufacturers: ManufacturerDTO[];
    excludedManufacturers: ManufacturerDTO[];
    selectionIds: string[];
    manufacturers: ManufacturerDTO[];
    query: string | null;
}): { filteredItems: string[]; selectionStatus: SelectionStatus } {
    let itemData: ManufacturerDTO[] = [];
    let selectionStatus: SelectionStatus = 'none';

    switch (tableDisplayMode) {
        case 'included':
            itemData = includedManufacturers;
            break;
        case 'excluded':
            itemData = excludedManufacturers;
            break;
    }

    switch (selectionIds.length) {
        case 0:
            selectionStatus = 'none';
            break;
        case itemData.length:
            selectionStatus = 'all';
            break;
        default:
            selectionStatus = 'indeterminate';
            break;
    }

    const filteredIds = manufacturers
        .filter((m) => !isPresent(query) || m.name.toLowerCase().includes(query.toLowerCase().trim()))
        .map((m) => m.id);

    const filteredItems = itemData.map((m) => m.id).filter((id) => filteredIds.includes(id));

    return { filteredItems, selectionStatus };
}

const PreferenceTable: React.FunctionComponent<{
    displayMode: DisplayMode;
    tableDisplayMode: DisplayMode;
    query: string | null;
}> = ({ displayMode, tableDisplayMode, query }) => {
    const { setValue } = useFormContext<SupplierLineCardFormState>();
    const [selectionIds, setSelectionIds] = React.useState<string[]>([]);

    const { includedManufacturers, excludedManufacturers, manufacturers, isLoading } = useWatchManufacturers();

    const { filteredItems, selectionStatus } = getItemDataAndSelectionStatus({
        tableDisplayMode,
        selectionIds,
        query,
        includedManufacturers,
        excludedManufacturers,
        manufacturers,
    });

    const toggleSelection = React.useCallback(() => {
        if (selectionIds.length === 0) {
            setSelectionIds(filteredItems);
        } else {
            setSelectionIds([]);
        }
    }, [selectionIds, setSelectionIds, filteredItems]);

    const onSelect = React.useCallback(
        (value: string) => {
            setSelectionIds((state) => (state.includes(value) ? state.filter((v) => v !== value) : [...state, value]));
        },
        [setSelectionIds],
    );

    const handleMoveTo = (target: DisplayMode) => {
        function isNotSelected(m: ManufacturerDTO): boolean {
            return !selectionIds.includes(m.id);
        }

        const selectedIncluded: ManufacturerDTO[] =
            target === 'included' ? manufacturers.filter((m) => !isNotSelected(m)) : [];

        const newIncluded = includedManufacturers.filter(isNotSelected).concat(selectedIncluded);

        setValue('manufacturers', newIncluded);
        setSelectionIds([]);
    };

    const ManufacturerRow = ({ index, style, data }: { index: number; style: React.CSSProperties; data: string[] }) => {
        const value = data[index];
        const manufacturer = manufacturers.find((item) => item.id === value);

        if (!isPresent(manufacturer)) {
            return <></>;
        }

        return (
            <Flexbox
                style={{ boxSizing: 'border-box', ...style }}
                alignItems={'center'}
                borderTop={`1px solid ${colorSystem.neutral[2]}`}
                paddingX={'12px'}
                gap={'8px'}
            >
                <Checkbox size="small" onClick={() => onSelect(value)} checked={selectionIds.includes(value)} />

                <Text variant={'body-small'}>{manufacturer.name}</Text>
            </Flexbox>
        );
    };

    return (
        <Box
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '8px',
                // This ensure that the scroll position is persisted while switching between view modes
                display: displayMode === tableDisplayMode ? undefined : 'none',
                overflow: 'hidden',
            }}
        >
            <PrefernceTableHeader
                displayMode={displayMode}
                selectionIds={selectionIds}
                selectionStatus={selectionStatus}
                toggleSelection={toggleSelection}
                onClick={handleMoveTo}
            />
            {isLoading ? (
                <CenteredLayout height={180}>
                    <CircularProgress />
                </CenteredLayout>
            ) : filteredItems.length === 0 && !isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No manufacturer in this category</Trans>
                    </Text>
                </CenteredLayout>
            ) : filteredItems.length === 0 && isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>Sorry, your filter produced no results</Trans>
                    </Text>
                </CenteredLayout>
            ) : (
                <FixedSizeList
                    itemData={filteredItems}
                    itemCount={filteredItems.length}
                    width="100%"
                    height={280}
                    itemSize={32}
                    overscanCount={5}
                >
                    {ManufacturerRow}
                </FixedSizeList>
            )}
        </Box>
    );
};

const ResetLineCardButton: React.FunctionComponent<{ supplierLineCard: SupplierLineCardDTO }> = ({
    supplierLineCard,
}) => {
    const { closeDialog } = useDialogContext();
    const hasBeenModified = supplierLineCard.modification_history.length > 0;

    const { mutateAsync } = useHttpMutation('PATCH /suppliers/:id/line-card', {
        snackbarMessage: t`Supplier line card successfully reset`,
        onSuccess: () => closeDialog(),
    });

    return (
        <SecondaryButton
            size={'small'}
            disabled={!hasBeenModified}
            onClick={() => {
                mutateAsync({
                    pathParams: { id: supplierLineCard.supplier },
                    requestBody: { type: 'Reset' },
                });
            }}
        >{t`Reset to default line card`}</SecondaryButton>
    );
};

export function SupplierLineCardForm({
    supplierLineCard,
    defaultValues,
    onSubmit,
}: {
    supplierLineCard: SupplierLineCardDTO;
    defaultValues: SupplierLineCardFormState;
    onSubmit: (fromValues: SupplierLineCardFormState) => void;
}) {
    const [displayMode, setVieMode] = React.useState<DisplayMode>('included');
    const [query, setQuery] = React.useState<string | null>(null);

    const handleDisplayModeChange = React.useCallback(
        (displayMode: DisplayMode) => {
            setVieMode(displayMode);
        },
        [setVieMode],
    );

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <Flexbox flexDirection="column" gap={12}>
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <DisplayModeButtonGroup displayMode={displayMode} onClick={handleDisplayModeChange} />
                        <ResetLineCardButton supplierLineCard={supplierLineCard} />
                    </Flexbox>
                    <SearchField value={query} onChange={(e) => setQuery(e.target.value)} />
                    {/* We render all tables at once, but only one is visible at a time */}
                    <PreferenceTable displayMode={displayMode} tableDisplayMode={'included'} query={query} />
                    <PreferenceTable displayMode={displayMode} tableDisplayMode={'excluded'} query={query} />
                </Flexbox>
                <Flexbox width={'100%'} justifyContent={'flex-end'}>
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}
