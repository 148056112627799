import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { SecondaryButton, SnackbarText } from '@luminovo/design-system';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHttpQuery } from '../resources/http/useHttpQuery';

const SNACKBAR_KEY = 'version-updated-snack-bar';
const MINUTES_5 = 5 * 60 * 1000;
const MINUTES_30 = 30 * 60 * 1000;

export const VersionUpdatedNotifier: React.FunctionComponent = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { data } = useHttpQuery(
        'GET /my/version',
        {},
        {
            refetchOnWindowFocus: true,
            refetchInterval: MINUTES_30,
            staleTime: MINUTES_5,
            select: (res) => res.data,
            meta: { globalErrorHandler: false },
        },
    );

    const hasDifferentVersion =
        isPresent(data) && Boolean(window.LUMINOVO_VERSION) && data.luminovo_version > window.LUMINOVO_VERSION;

    React.useEffect(() => {
        if (hasDifferentVersion) {
            enqueueSnackbar(
                <SnackbarText title={t`A new version is available!`} message={t`Click refresh to update`} />,
                {
                    persist: true,
                    key: SNACKBAR_KEY,
                    preventDuplicate: true,
                    variant: 'info',
                    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                    action: () => (
                        <SecondaryButton
                            size="small"
                            onClick={() => {
                                closeSnackbar(SNACKBAR_KEY);
                                window.location.reload();
                            }}
                        >
                            <Trans>Refresh</Trans>
                        </SecondaryButton>
                    ),
                },
            );
        } else {
            closeSnackbar(SNACKBAR_KEY);
        }
    }, [hasDifferentVersion, enqueueSnackbar, closeSnackbar]);

    return <></>;
};
