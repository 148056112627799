import { OtsFullPart } from '@luminovo/http-client';
import stringSimilarity from 'string-similarity';

type BestMatchProps = {
    input: {
        mpn: string;
        manufacturer: string;
    };
    targetParts: OtsFullPart[];
    options?: {
        /**
         * The minimum similarity rating required for a part to be considered a match.
         * Should be a number between 0 and 1.
         */
        threadhold?: number;
        /**
         * When true, the comparison is case insensitive.
         */
        ignoreCase?: boolean;
    };
};

type BestMatchResult = {
    part: OtsFullPart | null;
    rating: number;
    targetString: string;
    mainString: string;
};

type TargetString = {
    targetString: string;
    part: OtsFullPart;
};

/**
 * Finds the best matching part from a list of parts.
 *
 * Compares the input part with the target parts by calculating the similarity of the mpn and the manufacturer names.
 */
export function findBestMatchingOtsPart({ input, targetParts, options }: BestMatchProps): BestMatchResult {
    const mainString = `${input.mpn}, ${input.manufacturer}`;
    const { threadhold = 1 } = options || {};

    const calculate = (targetStrings: TargetString[]) => {
        if (targetStrings.length === 0) {
            return { part: null, rating: 0, targetString: '', mainString };
        }
        const bestMatch = stringSimilarity.findBestMatch(
            transformString(mainString, options),
            targetStrings.map((x) => transformString(x.targetString, options)),
        );

        if (bestMatch.bestMatch.rating < threadhold) {
            return {
                part: null,
                rating: bestMatch.bestMatch.rating,
                targetString: bestMatch.bestMatch.target,
                mainString,
            };
        }

        return {
            part: targetStrings[bestMatch.bestMatchIndex].part,
            rating: bestMatch.bestMatch.rating,
            targetString: bestMatch.bestMatch.target,
            mainString,
        };
    };

    const resultWithoutAliases = calculate(
        generateTargetStrings(targetParts, { includeMpnAliases: false, includeManufacturerAlternativeNames: false }),
    );

    const resultWithManufacturerAliases = calculate(
        generateTargetStrings(targetParts, { includeMpnAliases: false, includeManufacturerAlternativeNames: true }),
    );

    const resultWithMpnAliases = calculate(
        generateTargetStrings(targetParts, { includeMpnAliases: true, includeManufacturerAlternativeNames: true }),
    );

    if (
        resultWithoutAliases.rating >= resultWithManufacturerAliases.rating &&
        resultWithoutAliases.rating >= resultWithMpnAliases.rating
    ) {
        return resultWithoutAliases;
    }

    if (resultWithManufacturerAliases.rating >= resultWithMpnAliases.rating) {
        return resultWithManufacturerAliases;
    }

    return resultWithMpnAliases;
}

function transformString(value: string, options: BestMatchProps['options']): string {
    const { ignoreCase = false } = options || {};
    if (ignoreCase) {
        return value.toLowerCase();
    }
    return value;
}

/**
 * Generates the permutations of the mpn and the manufacturer names of the parts, including the alternative names.
 */
function generateTargetStrings(
    otsParts: OtsFullPart[],
    option: { includeMpnAliases: boolean; includeManufacturerAlternativeNames: boolean },
): TargetString[] {
    const { includeMpnAliases, includeManufacturerAlternativeNames } = option;

    return otsParts.flatMap((part) => {
        const mpns = includeMpnAliases ? [part.mpn, ...part.mpn_aliases] : [part.mpn];
        const manufacturers = includeManufacturerAlternativeNames
            ? [part.manufacturer.name, ...part.manufacturer.alternative_names]
            : [part.manufacturer.name];

        return mpns.flatMap((mpn) =>
            manufacturers.map((name) => ({
                targetString: `${mpn}, ${name}`,
                part,
            })),
        );
    });
}
