/* eslint-disable camelcase */
import * as r from 'runtypes';

export interface StockConditionDTO extends r.Static<typeof StockConditionDTORuntype> {}
const StockConditionDTORuntype = r.Record({
    condition: r.Literal('Gte'),
    content: r.Number,
});

export interface AlertSupplierPreferenceDTO extends r.Static<typeof AlertSupplierPreferenceDTORuntype> {}
const AlertSupplierPreferenceDTORuntype = r.Record({
    use_preferred: r.Boolean,
    use_approved: r.Boolean,
    use_not_approved: r.Boolean,
    additional_suppliers: r.Array(r.String),
});

const AlertStatusDTORuntype = r.Union(
    r.Record({ status: r.Literal('Active') }),
    r.Record({ status: r.Literal('Triggered') }),
);

export interface AlertDTO extends r.Static<typeof AlertDTORuntype> {}
export const AlertDTORuntype = r.Record({
    id: r.String,
    part: r.String,
    owner: r.String.nullable(),
    status: AlertStatusDTORuntype,
    condition: StockConditionDTORuntype,
    supplier_preference: AlertSupplierPreferenceDTORuntype,
    notes: r.String,
    creation_date: r.String,
});

export interface CreateAlertRequestBody extends r.Static<typeof CreateAlertRequestBodyRuntype> {}
export const CreateAlertRequestBodyRuntype = r.Record({
    part: r.String,
    condition: StockConditionDTORuntype,
    supplier_preference: AlertSupplierPreferenceDTORuntype,
    notes: r.String,
});

export interface UpdateAlertRequestBody extends r.Static<typeof UpdateAlertRequestBodyRuntype> {}
export const UpdateAlertRequestBodyRuntype = r.Record({
    part: r.String.optional(),
    condition: StockConditionDTORuntype.optional(),
    supplier_preference: AlertSupplierPreferenceDTORuntype.optional(),
    notes: r.String.optional(),
});
