import { t } from '@lingui/macro';
import { colorSystem, FieldMultiSelect, FormItem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useUniversalImporter } from '../../context';
import { DateFormat, dateFormats } from '../../util/getDefaultDateFormat';

export function FormItemDateFormats(): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const { dateFormats: selectedDateFormats } = state;

    return (
        <FormItem
            label={t`Date format`}
            variant="description-inlined"
            description={[
                `Choose which date formats appear in your file`,
                'We will try these formats one by one to read any dates in your file',
            ].join('. ')}
            style={{ maxWidth: 800 }}
        >
            <FieldMultiSelect<DateFormat>
                value={selectedDateFormats}
                getOptionKey={(option) => option.format}
                renderOption={(option) => {
                    return (
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                alignItems: 'center',
                                width: '100%',
                                color: colorSystem.neutral[8],
                            }}
                        >
                            <div style={{ color: colorSystem.neutral[9] }}>{option.format}</div>
                            <div>e.g. {option.example} </div>
                            <div style={{ textAlign: 'right' }}>Common in {option.country}</div>
                        </Box>
                    );
                }}
                onChange={(value: DateFormat[] = []) =>
                    value && dispatch({ type: 'setDateFormats', dateFormats: value })
                }
                options={dateFormats}
                getOptionLabel={(option) => `${option.format} e.g. ${option.example}`}
                fullWidth
                disableClearable
            />
        </FormItem>
    );
}
