import { isPresent } from '@luminovo/commons';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parseCopperWeightAsMicrometer, parseMicrometer, parseMillimeter } from './parseLength';

const specificFinalThicknessKeywords = [
    '(:?pcb|material|total|board|final|sheet).?thickness',
    '(:?PCB|LP|Material|Leiterplatten?).?(:?Stärke|Dicke)',
    'spessore del laminato',
];
const unspecificThicknessKeywords = ['thickness', 'Stärke', 'Dicke', 'spessore'];
const specificCopperThicknessKeywords = [
    '(:?finished|final)?\\s?-?(:?copper|cu).?thickness',
    'Kupfer',
    'Copper',
    'Endkupfer(:?dicke)?',
    '(:?Cu|Kupfer|Leiterbahn).?(:?(:?end)?stärke|dicke|auflage)',
];

export function parseFinalThicknessKeyword(str: string): PcbAttribute | undefined {
    const specificFinalThicknessRegexMatches =
        turnKeywordsIntoRegexRespectingWordBoundaries(specificFinalThicknessKeywords).test(str);
    const unspecificThicknessRegexMatches =
        turnKeywordsIntoRegexRespectingWordBoundaries(unspecificThicknessKeywords).test(str);
    const specificCopperThicknessRegexMatches = turnKeywordsIntoRegexRespectingWordBoundaries(
        specificCopperThicknessKeywords,
    ).test(str);
    if (specificCopperThicknessRegexMatches) {
        return undefined;
    }
    if (specificFinalThicknessRegexMatches) {
        return {
            attr: 'finalThicknessKeyword',
            value: str,
            confidence: 1,
        };
    }
    if (unspecificThicknessRegexMatches) {
        return {
            attr: 'finalThicknessKeyword',
            value: str,
            confidence: 0.5,
        };
    }
    return undefined;
}

export const isAllowedForFinalThickness = (value: number): boolean => {
    const minFinalThicknessInMillimeter = 0.1;
    const maxFinalThicknessInMillimeter = 4;
    return value >= minFinalThicknessInMillimeter && value <= maxFinalThicknessInMillimeter;
};

export function parseFinalThickness(str: string): PcbAttribute | undefined {
    const finalThickness = parseFinalThicknessKeyword(str);
    const millimeter = parseMillimeter(str);
    if (finalThickness && millimeter && isAllowedForFinalThickness(millimeter.value as number)) {
        return {
            attr: 'finalThickness',
            value: { value: millimeter.value as number, unit: 'MilliMeter' },
            confidence: finalThickness.confidence ?? 1,
        };
    }
    return undefined;
}

export const isAllowedForCopperThickness = (value: number): boolean => {
    const minCopperThicknessInMicrometer = 5;
    const maxCopperThicknessInMicrometer = 600;
    return value >= minCopperThicknessInMicrometer && value <= maxCopperThicknessInMicrometer;
};

const innerKeywords = ['inner', 'Innen(:?lagen)?'];
const outerKeywords = ['outer', 'Aussen(:?lagen)?', 'Außen(:?lagen)?'];
export function parseCopperThicknessKeyword(str: string): PcbAttribute | undefined {
    const specificFinalThicknessRegexMatches =
        turnKeywordsIntoRegexRespectingWordBoundaries(specificFinalThicknessKeywords).test(str);
    const specificCopperThicknessRegexMatches = turnKeywordsIntoRegexRespectingWordBoundaries(
        specificCopperThicknessKeywords,
    ).test(str);
    if (specificFinalThicknessRegexMatches) {
        return undefined;
    }
    const innerLayerRegexMatches = turnKeywordsIntoRegexRespectingWordBoundaries(innerKeywords).test(str);
    const outerLayerRegexMatches = turnKeywordsIntoRegexRespectingWordBoundaries(outerKeywords).test(str);
    if (specificCopperThicknessRegexMatches) {
        if (innerLayerRegexMatches !== outerLayerRegexMatches) {
            return {
                attr: innerLayerRegexMatches ? 'innerCopperThicknessKeyword' : 'outerCopperThicknessKeyword',
                value: str,
                confidence: 1,
                // confidence: 1 / str.length,
            };
        }
        if (innerLayerRegexMatches && outerLayerRegexMatches) {
            return {
                attr: 'copperThicknessKeyword',
                value: str,
                confidence: 1,
            };
        }
        return {
            attr: 'copperThicknessKeyword',
            value: str,
            confidence: 1 / str.length,
        };
    }
    return undefined;
}

export function parseCopperThickness(str: string): PcbAttribute[] {
    const copperThicknessKeyword = parseCopperThicknessKeyword(str);
    const allowWithoutUnit = isPresent(copperThicknessKeyword);
    const micrometer = parseMicrometer(str, allowWithoutUnit).filter((attribute) =>
        isAllowedForCopperThickness(attribute.value as number),
    );
    const micrometerFromOz = parseCopperWeightAsMicrometer(str);
    micrometerFromOz && micrometer.push(micrometerFromOz);

    const result: PcbAttribute[] = [];
    if (micrometer.length > 0) {
        if (micrometer.length === 1 && isPresent(copperThicknessKeyword)) {
            // just one is present
            if (copperThicknessKeyword.attr === 'copperThicknessKeyword') {
                result.push({
                    attr: 'innerCopperThickness',
                    value: { value: micrometer[0].value as number, unit: 'MicroMeter' },
                    confidence: copperThicknessKeyword.confidence,
                });
                result.push({
                    attr: 'outerCopperThickness',
                    value: { value: micrometer[0].value as number, unit: 'MicroMeter' },
                    confidence: copperThicknessKeyword.confidence,
                });
            } else {
                result.push({
                    attr:
                        copperThicknessKeyword.attr === 'innerCopperThicknessKeyword'
                            ? 'innerCopperThickness'
                            : 'outerCopperThickness',
                    value: { value: micrometer[0].value as number, unit: 'MicroMeter' },
                    confidence: copperThicknessKeyword.confidence,
                });
            }
        }
        if (
            micrometer.length === 2 ||
            checkInnerAndOuterValuesAreConsistent(micrometer.map((attribute) => attribute.value as number))
        ) {
            result.push({
                attr: 'outerCopperThickness',
                value: { value: micrometer[0].value as number, unit: 'MicroMeter' },
                confidence: isPresent(copperThicknessKeyword) ? copperThicknessKeyword.confidence : 0.1,
            });
            result.push({
                attr: 'innerCopperThickness',
                value: { value: micrometer[1].value as number, unit: 'MicroMeter' },
                confidence: isPresent(copperThicknessKeyword) ? copperThicknessKeyword.confidence : 0.1,
            });
        }
    }
    return result;
}

const checkInnerAndOuterValuesAreConsistent = (numbers: number[]): boolean => {
    const length = numbers.length;
    if (length % 2 !== 0) {
        return false;
    }
    if (numbers[0] !== numbers[length - 1]) {
        return false;
    }
    return numbers.slice(1, length - 1).every((value) => value === numbers[1]);
};

// layer
// layers
// Lagenanzahl
// Lagen: Anzahl
// layer count
