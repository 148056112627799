import { MonetaryValue, extractAmountFromMonetaryValue, isPresent } from '@luminovo/commons';
import { StandardPartOfferDTO } from '@luminovo/http-client';
import { Solution } from '../../types';

export function extractMoq({
    solution,
}: {
    solution: Pick<Solution, 'unitPrice' | 'firstPurchaseOption'> | undefined;
}): number | undefined {
    if (!isPresent(solution)) {
        return undefined;
    }

    const signature = solution.firstPurchaseOption.signature;
    if (signature && 'moq' in signature) {
        return signature.moq * solution.firstPurchaseOption.unit.quantity;
    }
    return undefined;
}

export function extractMoqFromOffer(
    unitPriceOriginal: MonetaryValue | null,
    offer: StandardPartOfferDTO,
): number | undefined {
    const matchingPriceBreak = offer.available_prices.price_breaks.find(
        (priceBreak) =>
            extractAmountFromMonetaryValue(priceBreak.unit_price) ===
                extractAmountFromMonetaryValue(unitPriceOriginal) &&
            unitPriceOriginal?.currency === priceBreak.unit_price.currency,
    );

    return matchingPriceBreak?.moq;
}
