import { isPresent, transEnum } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, Text } from '@luminovo/design-system';
import { AssemblyDTO, AssemblyTypeEnum } from '@luminovo/http-client';
import { Circle } from '@mui/icons-material';
import { assemblyTypeTranslations } from '../../../../../../resources/rfq/i18n';

const getColor = (assemblyType: AssemblyTypeEnum) => {
    switch (assemblyType) {
        case 'BOM':
            return 'green' as keyof typeof colorSystem;
        case 'Box':
            return 'blue' as keyof typeof colorSystem;
        case 'Cable':
            return 'blue' as keyof typeof colorSystem;
        case 'Pcba':
            return 'blue' as keyof typeof colorSystem;
        case 'System':
            return 'blue' as keyof typeof colorSystem;
        default:
            return 'green' as keyof typeof colorSystem;
    }
};
export const AssemblyTitle = ({
    assembly,
    isSubRow = false,
}: {
    assembly: Partial<Pick<AssemblyDTO, 'designator' | 'type'>>;
    isSubRow?: boolean;
}) => {
    if (!isPresent(assembly.designator) || !isPresent(assembly.type)) {
        return null;
    }

    return (
        <Flexbox alignItems={'center'} gap={'8px'} style={{ paddingInlineStart: isSubRow ? '16px' : '0px' }}>
            {isSubRow && <Circle style={{ fontSize: '4px' }} />}
            <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block', color: 'inherit' }}>
                {assembly.designator}
            </Text>
            <Tag
                color={getColor(assembly.type.type)}
                attention="low"
                label={transEnum(assembly.type.type, assemblyTypeTranslations)}
            />
        </Flexbox>
    );
};
