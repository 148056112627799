import { ManufacturingScenarioDTO, ManufacturingScenarioFullSummaryDTO } from '@luminovo/http-client';
import { usePageParams } from '../../../../resources/hooks';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

type UseManufacturingPageReturnType = {
    isLoading: boolean;
    manufacturingScenarios: ManufacturingScenarioDTO[];
    manufacturingAssemblyDetailsId: string;
    manufacturingScenarioId: string;
    manufacturingScenario?: ManufacturingScenarioDTO;
    manufacturingScenarioFullSummary?: ManufacturingScenarioFullSummaryDTO;
    rfqId: string;
};

const getCurrentManufacturingScenario = (
    manufacturingScenarios: ManufacturingScenarioDTO[],
    manufacturingScenarioId: string,
) => {
    return manufacturingScenarios.find((scenario) => scenario.id === manufacturingScenarioId);
};

export const useManufacturingPage = (): UseManufacturingPageReturnType => {
    const { manufacturingAssemblyDetailsId, manufacturingScenarioId, rfqId } = usePageParams<
        | '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity'
        | '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense'
    >();
    const { data, isLoading: isScenarioLoading } = useHttpQuery('GET /manufacturing-scenarios', {
        /* eslint-disable-next-line camelcase */
        queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId },
    });
    const manufacturingScenarios = data?.data ?? [];
    const currentManufacturingScenario = getCurrentManufacturingScenario(
        manufacturingScenarios,
        manufacturingScenarioId,
    );
    const SHOULD_NOT_BE_CALLED = 'should not be called';
    const currentManufacturingScenarioId = currentManufacturingScenario?.id ?? SHOULD_NOT_BE_CALLED;
    const { data: manufacturingScenarioFullSummary, isLoading: isFullSummaryLoading } = useHttpQuery(
        'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary',
        {
            pathParams: { manufacturingScenarioId },
        },
        { enabled: currentManufacturingScenarioId !== SHOULD_NOT_BE_CALLED },
    );

    const manufacturingScenarioFullSummaryArray: ManufacturingScenarioFullSummaryDTO[] =
        manufacturingScenarioFullSummary?.data ? [manufacturingScenarioFullSummary?.data] : [];

    const currentManufacturingScenarioFullSummary =
        manufacturingScenarioFullSummaryArray.length > 0 ? manufacturingScenarioFullSummaryArray[0] : undefined;

    return {
        isLoading: isScenarioLoading || isFullSummaryLoading,
        manufacturingAssemblyDetailsId,
        manufacturingScenarioId,
        manufacturingScenario: currentManufacturingScenario,
        manufacturingScenarioFullSummary: currentManufacturingScenarioFullSummary,
        manufacturingScenarios,
        rfqId,
    };
};
