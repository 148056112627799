import {
    CandidateCpn,
    FullPart,
    GenericFullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    RfqContext,
} from '@luminovo/http-client';
import * as React from 'react';
import { assertUnreachable } from '../../utils/typingUtils';
import { CustomComponentCard } from './CustomComponent/CustomComponentCard';
import { CustomPartCard } from './CustomPart/CustomPartCard';
import { GenericPartCard } from './GenericPart/GenericPartCard';
import { IpnCard } from './Ipn/IpnCard';
import { OTSPartCard } from './OTSPart/OTSPartCard';

export const PartCard = React.memo(function PartCard({
    part,
    cardStyle,
    mpnSearchQuery,
    candidateCpns,
    isEditable,
    onAddAlsoRemove,
    collapsed,
    disableOnHover,
    rfqContext,
}: {
    part: FullPart | null | undefined;
    mpnSearchQuery?: string[];
    candidateCpns?: CandidateCpn[];
    cardStyle?: React.CSSProperties;
    isEditable?: boolean;
    onAddAlsoRemove?: (newPart: GenericFullPart, partToRemove?: string) => void;
    collapsed?: boolean;
    disableOnHover?: boolean;
    rfqContext: RfqContext;
}) {
    if (!part) {
        return null;
    }
    if (isOtsFullPart(part)) {
        return (
            <OTSPartCard part={part} cardStyle={cardStyle} rfqContext={rfqContext} mpnSearchQuery={mpnSearchQuery} />
        );
    }
    if (isCustomFullPart(part)) {
        return <CustomPartCard partId={part.id} cardStyle={cardStyle} />;
    }
    if (isGenericFullPart(part)) {
        return (
            <GenericPartCard
                part={part}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                isEditable={isEditable} // used to only allow editing of generic part cards if we're in the BOM unlocked
                onAddAlsoRemove={onAddAlsoRemove}
            />
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <IpnCard
                part={part}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                collapsed={collapsed} // used to only display linked parts in the solution manager
                disableOnHover={disableOnHover}
                candidateCpns={candidateCpns}
            />
        );
    }
    if (isCustomComponentFull(part)) {
        return <CustomComponentCard component={part} rfqContext={rfqContext} />;
    }
    assertUnreachable(part);
});
