import { assertUnreachable } from '@luminovo/commons';
import { ValidFor } from '@luminovo/http-client';

export const convertValidForToCustomer = (validFor: ValidFor, customerId: string): null | string => {
    if (validFor === ValidFor.EveryCustomer) {
        return null;
    }
    if (validFor === ValidFor.ThisRfQ) {
        return null;
    }
    if (validFor === ValidFor.ThisCustomer) {
        return customerId;
    }
    assertUnreachable(validFor);
};
