import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, Dialog, DialogContent, DialogTitle, colorSystem } from '@luminovo/design-system';
import { SupplierAndStockLocationDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { ManufacturerRestrictionForm, ManufacturerRestrictionFormState } from './ManufacturerRestrictionForm';

export function useDialogAddManufacturerRestriction({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}) {
    const { setDialog, closeDialog } = useDialogContext();

    const { data: supplierLineCard } = useHttpQuery('GET /suppliers/:id/line-card', {
        pathParams: { id: supplierAndStockLocation.supplier.id },
    });

    const defaultValues: ManufacturerRestrictionFormState = {
        restrictionType: 'supplier',
        manufacturer: null,
        suppliers: [supplierAndStockLocation.supplier],
        partCategoryId: 1,
    };

    const { mutateAsync } = useHttpMutation('PATCH /suppliers/manufacturer-restrictions/:id', {
        snackbarMessage: t`Manufacturer restriction created`,
        onSuccess: () => closeDialog(),
    });

    const onSubmit = async (formValues: ManufacturerRestrictionFormState) => {
        if (formValues.restrictionType === 'supplier') {
            await mutateAsync({
                pathParams: { id: formValues.manufacturer?.id ?? 'no-manufacturer' },
                requestBody: {
                    suppliers: formValues.suppliers.map((s) => s.id),
                    part_category_restriction: [],
                },
            });
        }
        if (formValues.restrictionType === 'partCategory') {
            await mutateAsync({
                pathParams: { id: formValues.manufacturer?.id ?? 'no-manufacturer' },
                requestBody: {
                    suppliers: [],
                    part_category_restriction: formValues.suppliers.map((s) => {
                        return {
                            restrict_to: [formValues.partCategoryId],
                            supplier: s.id,
                        };
                    }),
                },
            });
        }
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Add restriction`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        {!isPresent(supplierLineCard) ? (
                            <CenteredLayout height={'30vh'}>
                                <CircularProgress />
                            </CenteredLayout>
                        ) : (
                            <ManufacturerRestrictionForm
                                defaultValues={defaultValues}
                                onSubmit={onSubmit}
                                supplierLineCard={supplierLineCard}
                            />
                        )}
                    </DialogContent>
                </Dialog>,
            ),
    };
}
