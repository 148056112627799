import { t } from '@lingui/macro';
import { coerceUndefinedToNull, formatPercentage } from '@luminovo/commons';
import { InputAdornment } from '@mui/material';
import { Controller, FieldValues, Path, ControllerProps as ReactHookFormControllerProps } from 'react-hook-form';
import { BaseField } from '../BaseField';
import { FieldNumericProps, fieldConverterNumber, fieldConverterPercent, fieldConverterString } from './FieldNumeric';

export interface FieldNumericControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<ReactHookFormControllerProps<TFieldValues, TName>, 'render' | 'rules' | 'defaultValues'> {
    FieldProps?: Omit<FieldNumericProps, 'value' | 'onChange' | 'required'>;
    required?: boolean;

    /**
     * The minimum (inclusive) value that this input can hold.
     *
     * @default -Infinity
     */
    min?: number;

    /**
     * The maximum (inclusive) value that this input can hold.
     *
     * @default Infinity
     */
    max?: number;

    /**
     * The output type. The string representation is suitable
     * for values that are not numerically significant (e.g., an internal reference number)
     * or decimals with a higher level of precision.
     *
     * The percent representation shows values to the user as a percentage (i.e. 0 to 100), but stores them as a decimal (i.e. 0 to 1).
     *
     * @default 'number'
     */
    representation?: 'number' | 'string' | 'percent';

    /**
     * @default false
     */
    isInteger?: boolean;

    validate?: (num: number) => string | undefined;
}

/**
 * @deprecated use `FieldNumericControlled` if possible.
 */
export function FieldNumericController<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
    min,
    max = Number.MAX_SAFE_INTEGER,
    validate,
    isInteger,
    required,
    representation = 'number',
    name,
    control,
    FieldProps = {},
    ...rest
}: FieldNumericControllerProps<TFieldValues, TName>): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: convertersByRepresentation[representation].createValidator({
                    min,
                    max,
                    isInteger,
                    validate,
                    required,
                }),
            }}
            render={({ field, fieldState }) => {
                return (
                    <BaseField
                        // @ts-ignore
                        converter={convertersByRepresentation[representation].converter}
                        name={field.name}
                        inputRef={field.ref}
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        value={coerceUndefinedToNull(field.value as string | number | undefined)}
                        onChange={(value) => {
                            field.onChange(value);
                        }}
                        onBlur={() => field.onBlur()}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        {...FieldProps}
                        InputProps={{
                            autoComplete: 'off',
                            endAdornment:
                                representation === 'percent' ? (
                                    <InputAdornment position="end">%</InputAdornment>
                                ) : undefined,
                            ...FieldProps.InputProps,
                        }}
                    />
                );
            }}
            {...rest}
        />
    );
}

const convertersByRepresentation = {
    number: { converter: fieldConverterNumber, createValidator: createNumericValidator },
    string: { converter: fieldConverterString, createValidator: createStringValidator },
    percent: { converter: fieldConverterPercent, createValidator: createPercentValidator },
};

export function createNumericValidator({
    min = -Infinity,
    max = Infinity,
    isInteger = false,
    required,
    validate,
}: Pick<FieldNumericControllerProps, 'min' | 'max' | 'isInteger' | 'validate' | 'required'>) {
    return (x: unknown): string | undefined => {
        if (!required && (x === null || x === undefined)) {
            return undefined;
        }
        if (x === null || x === undefined || typeof x !== 'number') {
            return t`Required`;
        }
        if (x < min) {
            return t`Must be greater than or equal to ${min}`;
        }
        if (x > max) {
            return t`Must be less than or equal to ${max}`;
        }
        if (isInteger && !Number.isInteger(x)) {
            return t`Must be a whole number`;
        }
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}

function createPercentValidator({
    min = -Infinity,
    max = Infinity,
    required,
    validate,
}: Pick<FieldNumericControllerProps, 'min' | 'max' | 'validate' | 'required'>) {
    return (x: unknown): string | undefined => {
        if (!required && (x === null || x === undefined)) {
            return undefined;
        }
        if (x === null || x === undefined || typeof x !== 'number') {
            return t`Required`;
        }
        if (x < min) {
            return t`Must be greater than or equal to ${formatPercentage(min)}`;
        }
        if (x > max) {
            return t`Must be less than or equal to ${formatPercentage(max)}`;
        }
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}

export function createStringValidator({
    min = -Infinity,
    max = Infinity,
    isInteger = false,
    required,
    validate,
}: Pick<FieldNumericControllerProps, 'min' | 'max' | 'isInteger' | 'validate' | 'required'>) {
    return (x: unknown): string | undefined => {
        if (!required && (x === null || x === undefined || x === '')) {
            return undefined;
        }
        if (x === null || x === undefined || x === '' || typeof x !== 'string') {
            return t`Required`;
        }
        const parsed = Number(x);
        return createNumericValidator({ min, max, isInteger, validate, required })(parsed);
    };
}
