import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { Typography, TypographyVariant, useMediaQuery } from '@mui/material';
import React from 'react';
import { themeLuminovo } from '../themes';

type MaxWidthTextProp = {
    text: string;
    maxWidthLargeScreen: string | number;
    maxWidthSmallScreen: string | number;
    variant?: TypographyVariant;
    typographyStyle?: Omit<React.CSSProperties, 'textOverflow' | 'maxWidth'>;
};

// Sets a max width with a tooltip if the text is too long.
export const MaxWidthText = ({
    text,
    maxWidthLargeScreen,
    maxWidthSmallScreen,
    typographyStyle,
    variant = 'body1',
}: MaxWidthTextProp): JSX.Element => {
    /* eslint-disable-next-line spellcheck/spell-checker */
    const isXlScreen: boolean = useMediaQuery(themeLuminovo.breakpoints.up('xl'));

    const [showTooltip, setShowTooltip] = React.useState(false);

    const mouseEnterHandler = React.useCallback(
        (e) => {
            if (e.target.offsetWidth !== e.target.scrollWidth && !showTooltip) {
                setShowTooltip(true);
            } else if (e.target.offsetWidth === e.target.scrollWidth && showTooltip) {
                setShowTooltip(false);
            }
        },
        [showTooltip, setShowTooltip],
    );
    return (
        <Tooltip
            variant="white"
            title={<Typography variant={'body1'}>{text}</Typography>}
            placement={'bottom'}
            disableHoverListener={!showTooltip}
        >
            <Typography
                style={{
                    textOverflow: 'ellipsis',
                    maxWidth: isXlScreen ? maxWidthLargeScreen : maxWidthSmallScreen,
                    ...typographyStyle,
                }}
                color="textSecondary"
                variant={variant}
                noWrap={true}
                onMouseEnter={mouseEnterHandler}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};

// Sets a max width with a tooltip if the text is too long.
export const MaxWidthTextForCode = ({
    text,
    maxWidthLargeScreen,
    maxWidthSmallScreen,
}: MaxWidthTextProp): JSX.Element => {
    /* eslint-disable-next-line spellcheck/spell-checker */
    const isXlScreen: boolean = useMediaQuery(themeLuminovo.breakpoints.up('xl'));

    const [showTooltip, setShowTooltip] = React.useState(false);

    const mouseEnterHandler = React.useCallback(
        (e) => {
            if (e.target.offsetWidth !== e.target.scrollWidth && !showTooltip) {
                setShowTooltip(true);
            } else if (e.target.offsetWidth === e.target.scrollWidth && showTooltip) {
                setShowTooltip(false);
            }
        },
        [showTooltip, setShowTooltip],
    );
    return (
        <Tooltip
            variant="white"
            title={<Typography variant={'body1'}>{text}</Typography>}
            placement={'bottom'}
            disableHoverListener={!showTooltip}
        >
            <Text
                variant="code"
                style={{
                    boxSizing: 'border-box',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: isXlScreen ? maxWidthLargeScreen : maxWidthSmallScreen,
                    whiteSpace: 'nowrap',
                    backgroundColor: colorSystem.blue[1],
                    color: colorSystem.blue[6],
                    fontWeight: 'normal',
                    padding: '0px 4px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    border: `1px solid ${colorSystem.blue[3]}`,
                }}
                onMouseEnter={mouseEnterHandler}
            >
                {text}
            </Text>
        </Tooltip>
    );
};
