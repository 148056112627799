import { colorSystem } from '@luminovo/design-system';
import React from 'react';
import { CadViewerAction, CadViewerState } from '../../../CadViewerTab/utils/cadViewerTypes';
import { usePaddedViewBox } from '../../../utils/usePaddedViewBox';
import { SvgImageRenderer } from '../SvgImageRenderer';
import { CadPreviewerFile } from './CadPreviewerFile';

export const CadPreviewer: React.FunctionComponent<{
    assemblyId: string;
    pcbId: string;
    state: CadViewerState;
    dispatch: React.Dispatch<CadViewerAction>;
}> = ({ assemblyId, pcbId, state, dispatch }) => {
    const { files } = state;
    const reversedFiles = React.useMemo(() => {
        return [...files].reverse();
    }, [files]);

    const { outerViewBox, innerViewBox } = usePaddedViewBox(state.maxViewBox);

    return (
        <SvgImageRenderer
            svgProps={{
                style: {
                    boxSizing: 'border-box',
                    width: '100%',
                    minWidth: '570px',
                    height: '100%',
                    background: '#0F121A',
                    backgroundImage: `radial-gradient(${colorSystem.neutral[9]} 1px, transparent 0)`,
                },
            }}
            viewBox={outerViewBox}
            controlOptions={['measure', 'center', 'zoomIn', 'zoomOut']}
            resetViewBoxOnUpdate
        >
            {reversedFiles.map((file) => (
                <CadPreviewerFile
                    key={file.id}
                    file={file}
                    assemblyId={assemblyId}
                    pcbId={pcbId}
                    dispatch={dispatch}
                    viewBox={{
                        ...innerViewBox,
                        // If width or height is 0, set it to 10000 to allow render
                        width: innerViewBox.width || 10000,
                        height: innerViewBox.height || 10000,
                    }}
                />
            ))}
        </SvgImageRenderer>
    );
};
