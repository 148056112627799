import { t, Trans } from '@lingui/macro';
import { isPresent, logToExternalErrorHandlers } from '@luminovo/commons';
import {
    Chip,
    colorSystem,
    FieldMultiSelectControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    Message,
    SecondaryButton,
    TertiaryIconButton,
    Text,
} from '@luminovo/design-system';
import { ManufacturingScenarioDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { Add, Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    getManufacturingScenarioOptions,
    ScenarioCombinationForCalculationFormInputs,
} from './utils/scenarioCombinationForCalculationFormUtils';

const useBatchSizeOptions = (manufacturingScenarios: ManufacturingScenarioDTO[]): Record<string, string[]> => {
    return useMemo(() => {
        const batchSizeOptions: Record<string, string[]> = {};
        return manufacturingScenarios.reduce((acc, manufacturingScenario) => {
            acc[manufacturingScenario.id] = manufacturingScenario.batch_sizes.map((size) => size.toString());
            return acc;
        }, batchSizeOptions);
    }, [manufacturingScenarios]);
};

export const ScenarioCombination = ({
    sourcingScenarioId,
    assemblyQuantities,
    sourcingScenarios,
    manufacturingScenarios,
    index,
}: {
    index: number;
    sourcingScenarioId: string;
    assemblyQuantities: Record<string, number>;
    sourcingScenarios: SourcingScenarioDTO[];
    manufacturingScenarios: ManufacturingScenarioDTO[];
}) => {
    const sourcingScenario = sourcingScenarios.find((sourcingScenario) => sourcingScenario.id === sourcingScenarioId);
    const assemblyQuantity =
        sourcingScenarioId in assemblyQuantities ? assemblyQuantities[sourcingScenarioId] : undefined;

    if (!isPresent(sourcingScenario)) {
        const error = new Error('sourcing scenario should not be undefined if there is an id');
        logToExternalErrorHandlers(error);
        throw error;
    }
    if (!isPresent(assemblyQuantity)) {
        const error = new Error('sourcing scenario should also have an assembly quantity');
        logToExternalErrorHandlers(error);
        throw error;
    }

    const { control, setValue } = useFormContext<ScenarioCombinationForCalculationFormInputs>();
    const manufacturingScenarioOptions = getManufacturingScenarioOptions(sourcingScenarioId, manufacturingScenarios);
    const manufacturingScenarioNames: Record<string, string> = {};
    manufacturingScenarioOptions.reduce((acc, manufacturingScenario) => {
        acc[manufacturingScenario.id] = manufacturingScenario.name;
        return acc;
    }, manufacturingScenarioNames);

    const manufacturingScenarioId = useWatch({ control, name: `form.${index}.manufacturingScenarioId` });
    const batchSizeOptions = useBatchSizeOptions(manufacturingScenarios);
    if (manufacturingScenarioOptions.length === 0) {
        throw new Error('We assume in this component there 1 or more manufacturing scenario option');
    }

    return (
        <Grid container spacing={2} style={{ marginBlock: '16px' }} alignItems="center">
            <Grid item xs={3}>
                <Text variant="h4">{sourcingScenario.name}</Text>
                <Flexbox alignItems={'center'} gap={'8px'} marginTop={'8px'}>
                    <Text variant="h4" color={colorSystem.neutral[6]}>
                        <Trans>Order size</Trans>
                    </Text>
                    <Chip color="neutral" label={assemblyQuantity.toString() || ''} />
                </Flexbox>
            </Grid>
            {isPresent(manufacturingScenarioId) ? (
                <>
                    <Grid item xs={4}>
                        <FormItem label={t`Choose manufacturing scenario`}>
                            <FieldSelectControlled
                                control={control}
                                name={`form.${index}`}
                                FieldProps={{
                                    options: manufacturingScenarioOptions.map((manufacturingScenario) => ({
                                        manufacturingScenarioId: manufacturingScenario.id,
                                        batchSizes: [],
                                        sourcingScenarioId,
                                    })),
                                    getOptionLabel: (option) =>
                                        manufacturingScenarioNames[option.manufacturingScenarioId] ?? '',
                                    isOptionEqualToValue: (opt, value) =>
                                        opt.manufacturingScenarioId === value.manufacturingScenarioId,
                                    placeholder: t`Ignore sourcing scenario`,
                                    disableClearable: true,
                                }}
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={4}>
                        <FormItem label={t`Choose batch sizes`} required>
                            <FieldMultiSelectControlled
                                control={control}
                                name={`form.${index}.batchSizes`}
                                required
                                FieldProps={{
                                    options: batchSizeOptions[manufacturingScenarioId] ?? [],
                                    getOptionKey: (bs) => bs,
                                    getOptionLabel: (bs) => bs,
                                    isOptionEqualToValue: (opt, value) => opt === value,
                                    fullWidth: true,
                                    disableCloseOnSelect: true,
                                    placeholder: t`Batch sizes`,
                                }}
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={1}>
                        <FormItem label="&nbsp;">
                            <TertiaryIconButton
                                onClick={() =>
                                    setValue(`form.${index}`, {
                                        manufacturingScenarioId: undefined,
                                        sourcingScenarioId,
                                        batchSizes: [],
                                    })
                                }
                            >
                                <Delete />
                            </TertiaryIconButton>
                        </FormItem>
                    </Grid>
                </>
            ) : (
                <Grid item xs={4}>
                    <SecondaryButton
                        startIcon={<Add />}
                        onClick={() =>
                            setValue(`form.${index}`, {
                                sourcingScenarioId,
                                manufacturingScenarioId: manufacturingScenarioOptions[0].id,
                                batchSizes: batchSizeOptions[manufacturingScenarioOptions[0].id] ?? [],
                            })
                        }
                        fullWidth
                    >
                        <Trans>Add to calculation</Trans>
                    </SecondaryButton>
                </Grid>
            )}
        </Grid>
    );
};

export const ScenarioCombinationWithNoOptions = ({
    sourcingScenarioId,
    assemblyQuantities,
    sourcingScenarios,
}: {
    sourcingScenarioId: string;
    assemblyId: string;
    assemblyQuantities: Record<string, number>;
    sourcingScenarios: SourcingScenarioDTO[];
}) => {
    const sourcingScenario = sourcingScenarios.find((sourcingScenario) => sourcingScenario.id === sourcingScenarioId);
    const assemblyQuantity =
        sourcingScenarioId in assemblyQuantities ? assemblyQuantities[sourcingScenarioId] : undefined;

    if (!isPresent(sourcingScenario)) {
        const error = new Error('sourcing scenario should not be undefined if there is an id');
        logToExternalErrorHandlers(error);
        throw error;
    }
    if (!isPresent(assemblyQuantity)) {
        const error = new Error('sourcing scenario should also have an assembly quantity');
        logToExternalErrorHandlers(error);
        throw error;
    }

    return (
        <Grid container spacing={2} style={{ marginBlock: '16px' }}>
            <Grid item xs={3}>
                <Text variant="h4">{sourcingScenario.name}</Text>
                <Flexbox alignItems={'center'} gap={'8px'} marginTop={'8px'}>
                    <Text variant="h4" color={colorSystem.neutral[6]}>
                        <Trans>Order size</Trans>
                    </Text>
                    <Chip color="neutral" label={assemblyQuantity.toString() || ''} />
                </Flexbox>
            </Grid>
            <Grid item>
                <Message
                    size="small"
                    attention="low"
                    variant="yellow"
                    message="No manufacturing scenario created for this sourcing scenario"
                />
            </Grid>
        </Grid>
    );
};
