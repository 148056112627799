import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { FieldSelectControlled, Flexbox, FormItem, Message, Text, colorSystem } from '@luminovo/design-system';
import { DepanelizationDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { depanelizationTranslations } from '../../../Settings/OrganizationSettings/pages/PcbPanelPreferencesPage/utils/i18n';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PanelFormState } from '../utils/types';
import { PcbPanelNumberInput } from './PcbPanelNumberInput';

export const ExistingPanelForm = ({ isEditing }: { isEditing: boolean }) => {
    const { control } = useFormContext<PanelFormState>();
    const [showMessage, setShowMessage] = React.useState(false);

    const depanelizationType = useWatch({
        control,
        name: 'data.depanelizationType',
    });

    return (
        <Flexbox flexDirection="column" gap={16}>
            {showMessage && (
                <Message
                    variant="blue"
                    attention="high"
                    size="large"
                    title={t`What is existing panel?`}
                    message={t`The uploaded Gerber file is already configured as a shipping panel. Below you can specify its parameters.`}
                    onClose={() => setShowMessage(false)}
                />
            )}
            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection label={t`Parameters`} ContentStyle={{ width: '92%' }}>
                    <Box
                        display={'grid'}
                        flexDirection={'column'}
                        gridTemplateColumns={'1fr 1fr'}
                        gap={'24px'}
                        width={'100%'}
                    >
                        <FormItem
                            //  eslint-disable-next-line spellcheck/spell-checker
                            label={t`Number of PCBs on the panel`}
                            LabelProps={{ variant: 'h5' }}
                            style={{ gridColumn: 'span 2' }}
                        >
                            <PcbPanelNumberInput
                                control={control}
                                name="data.numberOfPcbs"
                                min={1}
                                endAdornmentText={t`boards`}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Panel width`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                name="data.panelWidth"
                                min={1}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Panel height`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                name="data.panelHeight"
                                min={1}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem
                            label={t`Depanelization type`}
                            LabelProps={{ variant: 'h5' }}
                            style={{ gridColumn: 'span 2' }}
                        >
                            {isEditing === false ? (
                                <Text>{transEnum(depanelizationType, depanelizationTranslations)}</Text>
                            ) : (
                                <FieldSelectControlled
                                    control={control}
                                    name="data.depanelizationType"
                                    FieldProps={{
                                        size: 'small',
                                        options: Object.keys(depanelizationTranslations) as DepanelizationDTO[],
                                        getOptionLabel: (x) => transEnum(x, depanelizationTranslations),
                                    }}
                                />
                            )}
                        </FormItem>
                    </Box>
                </CollapsibleSection>
            </Box>
        </Flexbox>
    );
};
