import { Trans, t } from '@lingui/macro';
import { formatDecimal, isPresent, uniq } from '@luminovo/commons';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FieldCheckbox,
    Flexbox,
    SecondaryButton,
    SecondaryIconButton,
    Tag,
    TagProps,
    Text,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import {
    CustomOptionOfferDTO,
    FullSourcingDTO,
    QuoteTrackingDTO,
    StandardPartInventoryOfferDTO,
    StandardPartMarketOfferDTO,
} from '@luminovo/http-client';
import { LocalOfferOutlined, SettingsRounded } from '@mui/icons-material';
import { Badge, Box, Skeleton, styled } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useQuoteTrackings } from '../../../../resources/quoteTracking/quoteTrackingHandler';
import {
    useSourcingFullBulk,
    useSourcingScenariosOfRfq,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';

export type QuoteTrackingMetricSettings = {
    sourcingFulls: FullSourcingDTO[] | undefined;
};

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 0,
        border: `2px solid ${colorSystem.neutral.white}`,
        padding: '0 4px',
    },
}));

type OfferType = StandardPartMarketOfferDTO | StandardPartInventoryOfferDTO | CustomOptionOfferDTO;

/**
 * Counts how often the given offers are used in the given sourcing scenarios.
 */
function countOffers(offers: OfferType | OfferType[], fullSourcingDTOs: FullSourcingDTO[]): number {
    const offersIds = new Set((Array.isArray(offers) ? offers : [offers]).map((offer) => offer.id));
    let uniqueMatchingOffers = new Set<string>();

    fullSourcingDTOs.forEach((sourcingFull) => {
        const allOffers = [
            ...sourcingFull.off_the_shelf_offers.items,
            ...sourcingFull.inventory_offers.items,
            ...sourcingFull.custom_part_offers.items,
        ];

        allOffers.forEach((offer) => {
            if (offersIds.has(offer.id)) {
                uniqueMatchingOffers.add(offer.id);
            }
        });
    });

    return uniqueMatchingOffers.size;
}

/**
 * Counts how often the given offers are used in the given sourcing scenarios.
 */
function countSolutions(offers: OfferType | OfferType[], fullSourcingDTOs: FullSourcingDTO[]): number {
    const offersIds = (Array.isArray(offers) ? offers : [offers]).map((offer) => offer.id);
    return fullSourcingDTOs.reduce((totalAcc, sourcingFull) => {
        const allPurchaseOptions = sourcingFull.solution_configurations_sourcing.items
            .flatMap((s) => (s.effective_solution ? s.effective_solution : []))
            .flatMap((s) => (s.purchase_options ? s.purchase_options : []));
        const matchingOffers = allPurchaseOptions.filter((p) => offersIds.includes(p.offer));
        return totalAcc + matchingOffers.length;
    }, 0);
}

function calculateTotalOffers(
    quoteTrackings: QuoteTrackingDTO | QuoteTrackingDTO[] | undefined,
    sourcingFulls: FullSourcingDTO[] | undefined,
    countOffers: (offers: OfferType[], sourcingFulls: FullSourcingDTO[]) => number,
): number | undefined {
    if (!isPresent(quoteTrackings) || !isPresent(sourcingFulls)) {
        return undefined;
    }

    const quoteTrackingsArray = Array.isArray(quoteTrackings) ? quoteTrackings : [quoteTrackings];

    return quoteTrackingsArray.reduce((total, quoteTracking) => {
        const combinedOffers = [...quoteTracking.standard_part_offers, ...quoteTracking.custom_part_offers];
        return total + countOffers(combinedOffers, sourcingFulls);
    }, 0);
}

function extractRequestedPartIds(quoteTracking: QuoteTrackingDTO): string[] {
    return [
        ...quoteTracking.request_standard_parts.map((p) => p.kind.id),
        ...quoteTracking.request_custom_parts.map((p) => p.kind.id),
    ];
}

function extractQuotedPartIds(quoteTracking: QuoteTrackingDTO): string[] {
    return [
        ...quoteTracking.standard_part_offers.map((offer) => offer.linked_part.id),
        ...quoteTracking.custom_part_offers.map((offer) => offer.linked_part.id),
    ];
}

function calculateTotalParts(
    quoteTrackings: QuoteTrackingDTO | QuoteTrackingDTO[] | undefined,
    partExtractor: (quoteTracking: QuoteTrackingDTO) => string[],
): number | undefined {
    if (!isPresent(quoteTrackings)) {
        return undefined;
    }
    const quoteTrackingsArray = Array.isArray(quoteTrackings) ? quoteTrackings : [quoteTrackings];

    const allPartIds = quoteTrackingsArray.flatMap(partExtractor);
    const uniquePartIds = uniq(allPartIds);
    return uniquePartIds.length;
}

const DataPointDisplay: React.FunctionComponent<{
    title: string;
    value: number | undefined;
    loading?: boolean;
}> = ({ title, value, loading }) => (
    <Flexbox flexDirection={'column'}>
        <Text variant="h5" color={colorSystem.neutral[6]}>
            {title}
        </Text>
        <Text variant="h3" color={colorSystem.neutral[9]} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {!loading ? formatDecimal(value, { ifAbsent: '-' }) : <Skeleton width="20px" />}
        </Text>
    </Flexbox>
);

const TagDisplay: React.FunctionComponent<{
    label: string;
    value: number | undefined;
    loading?: boolean;
    color: TagProps['color'];
}> = ({ label, value, loading, color }) =>
    !loading ? (
        <Tag color={value !== 0 ? color : 'neutral'} label={`${formatDecimal(value, { ifAbsent: '-' })} ${label}`} />
    ) : (
        <Skeleton width="60px" />
    );

export const OfferMetricTag: React.FunctionComponent<{
    offer: OfferType;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ offer, metricSettings }) => {
    if (!isPresent(metricSettings.sourcingFulls)) {
        return null;
    }

    const numberOfSolutionUsed = countSolutions(offer, metricSettings.sourcingFulls);

    if (numberOfSolutionUsed === 0) {
        return null;
    }

    return (
        <Flexbox flexDirection={'column'}>
            <Tooltip title={t`${formatDecimal(numberOfSolutionUsed, { ifAbsent: '-' })} best solutions`}>
                <StyledBadge
                    color={'secondary'}
                    badgeContent={formatDecimal(numberOfSolutionUsed, { ifAbsent: '-' })}
                    showZero={true}
                >
                    <LocalOfferOutlined fontSize={'small'} style={{ color: colorSystem.primary[5] }} />
                </StyledBadge>
            </Tooltip>
        </Flexbox>
    );
};

export const QuoteTrackingSingeMetricCard: React.FunctionComponent<{
    quoteTrackingDTO: QuoteTrackingDTO;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ quoteTrackingDTO, metricSettings }) => {
    const numberOfOffersUsed = calculateTotalOffers(quoteTrackingDTO, metricSettings.sourcingFulls, countOffers);
    const numberOfSolutionUsed = calculateTotalOffers(quoteTrackingDTO, metricSettings.sourcingFulls, countSolutions);
    const totalRequestedParts = calculateTotalParts(quoteTrackingDTO, extractRequestedPartIds);
    const totalQuotedParts = calculateTotalParts(quoteTrackingDTO, extractQuotedPartIds);

    return (
        <Flexbox flexDirection={'column'}>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'8px 8px 0px 0px'}
                padding={'20px'}
                gap={'28px'}
            >
                <DataPointDisplay title={t`Requested parts`} value={totalRequestedParts} />
                <DataPointDisplay title={t`Quoted parts`} value={totalQuotedParts} />
            </Box>
            <Flexbox
                alignItems={'center'}
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderTop={'none'}
                borderRadius={'0px 0px 8px 8px'}
                padding={'8px 20px'}
                gap={'4px'}
                bgcolor={colorSystem.neutral[0]}
            >
                <TagDisplay color={'primary'} label={t`best solutions`} value={numberOfSolutionUsed} />
                <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                    {t`based on ${formatDecimal(numberOfOffersUsed, { ifAbsent: '-' })} offers`}{' '}
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

export const QuoteTrackingTotalMetricCard: React.FunctionComponent<{
    rfqId: string;
    numberOfSourcingScenarios: number;
    metricSettings: QuoteTrackingMetricSettings;
    onChange: (newSettings: QuoteTrackingMetricSettings) => void;
}> = ({ rfqId, numberOfSourcingScenarios, metricSettings, onChange }) => {
    const { openDialog } = useQuoteTrackingMetricSettingDialog({
        rfqId,
        metricSettings,
        onChange,
    });
    const { data: quoteTrackingDTOs, isLoading } = useQuoteTrackings(rfqId);
    const numberOfOffersUsed = calculateTotalOffers(quoteTrackingDTOs, metricSettings.sourcingFulls, countOffers);
    const numberOfSolutionUsed = calculateTotalOffers(quoteTrackingDTOs, metricSettings.sourcingFulls, countSolutions);
    const totalRequestedParts = calculateTotalParts(quoteTrackingDTOs, extractRequestedPartIds);
    const totalQuotedParts = calculateTotalParts(quoteTrackingDTOs, extractQuotedPartIds);
    const badgeCount =
        numberOfSourcingScenarios === metricSettings.sourcingFulls?.length
            ? undefined
            : metricSettings.sourcingFulls?.length;

    return (
        <Flexbox flexDirection={'column'}>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 20px"
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'8px 8px 0px 0px'}
                padding={'20px'}
                gap={'32px'}
            >
                <DataPointDisplay title={t`Requested parts`} value={totalRequestedParts} loading={isLoading} />
                <DataPointDisplay title={t`Quoted parts`} value={totalQuotedParts} loading={isLoading} />
            </Box>
            <Box
                alignItems={'center'}
                display="grid"
                gridTemplateColumns="1fr 20px"
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderTop={'none'}
                borderRadius={'0px 0px 8px 8px'}
                padding={'8px 20px'}
                gap={'32px'}
                bgcolor={colorSystem.neutral[0]}
            >
                <Flexbox gap={4} alignItems={'center'}>
                    <TagDisplay
                        color={'primary'}
                        label={t`best solutions`}
                        value={numberOfSolutionUsed}
                        loading={isLoading}
                    />

                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                        {t`based on ${formatDecimal(numberOfOffersUsed, { ifAbsent: '-' })} offers`}{' '}
                    </Text>
                </Flexbox>
                <StyledBadge color={'secondary'} badgeContent={badgeCount} showZero={true}>
                    <SecondaryIconButton size={'small'} onClick={openDialog}>
                        <SettingsRounded fontSize={'inherit'} />
                    </SecondaryIconButton>
                </StyledBadge>
            </Box>
        </Flexbox>
    );
};

const useQuoteTrackingMetricSettingDialog = ({
    rfqId,
    metricSettings,
    onChange,
}: {
    rfqId: string;
    metricSettings: QuoteTrackingMetricSettings;
    onChange: (newSettings: QuoteTrackingMetricSettings) => void;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'sm'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Performance metrics configuration`} handleClose={() => closeDialog()} />
                    <FormContainer
                        defaultValues={metricSettings}
                        onSubmit={(value) => {
                            onChange(value);
                            closeDialog();
                        }}
                    >
                        <DialogContent>
                            <Flexbox flexDirection={'column'} gap={12}>
                                <Text>
                                    <Trans>
                                        The metric settings allow you to choose which sourcing scenarios to include in
                                        the best solution and best price offer metrics.
                                    </Trans>
                                </Text>
                                <FormItemSourcingScenarios rfqId={rfqId} />
                            </Flexbox>
                        </DialogContent>
                        <DialogActions>
                            <Flexbox gap="8px">
                                <SecondaryButton onClick={closeDialog} size={'medium'}>
                                    <Trans>Cancel</Trans>
                                </SecondaryButton>
                                <SubmitButton size={'medium'} />
                            </Flexbox>
                        </DialogActions>
                    </FormContainer>
                </Dialog>,
            ),
    };
};

const FormItemSourcingScenarios: React.FunctionComponent<{
    rfqId: string;
}> = ({ rfqId }) => {
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { data: quoteTrackingDTOs, isLoading } = useQuoteTrackings(rfqId);
    const { data: allSourcingFulls = [] } = useSourcingFullBulk(sourcingScenarios?.map((s) => s.id));
    const { control, setValue } = useFormContext<QuoteTrackingMetricSettings>();

    const sourcingFulls = useWatch({ control, name: 'sourcingFulls' }) ?? [];

    const handleToggle = (sourcingScenario: FullSourcingDTO, isSelected: boolean) => {
        const newSourcingFulls = isSelected
            ? sourcingFulls.filter((s) => s.sourcing_scenario_id !== sourcingScenario.sourcing_scenario_id)
            : [...sourcingFulls, sourcingScenario];

        setValue('sourcingFulls', newSourcingFulls);
    };

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Text variant="h4">
                <Trans>Sourcing scenarios</Trans>
            </Text>
            {allSourcingFulls.map((sourcingFull) => {
                const isSelected = sourcingFulls.some(
                    (s) => s.sourcing_scenario_id === sourcingFull.sourcing_scenario_id,
                );
                const numberOfSolutionUsed = calculateTotalOffers(quoteTrackingDTOs, [sourcingFull], countSolutions);

                return (
                    <Flexbox key={sourcingFull.sourcing_scenario_id} gap={8} alignItems={'center'}>
                        <FieldCheckbox value={isSelected} onChange={() => handleToggle(sourcingFull, isSelected)} />
                        <Text variant="body">
                            {sourcingScenarios?.find((s) => s.id === sourcingFull.sourcing_scenario_id)?.name}
                        </Text>
                        <Flexbox flex={1} />
                        <TagDisplay
                            color={'primary'}
                            label={t`best solutions`}
                            value={numberOfSolutionUsed}
                            loading={isLoading}
                        />
                    </Flexbox>
                );
            })}
        </Flexbox>
    );
};
