import { isPresent, omit, throwErrorUnlessProduction } from '@luminovo/commons';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { Autocomplete, AutocompleteProps, AutocompleteRenderOptionState } from '@mui/material';
import * as React from 'react';
import { generateVirtualizeProps } from '../FieldMultiSelect/VirtualizeComponents';
import { FieldText, FieldTextProps } from '../FieldText';

/**
 * `freeSolo` is not supported because it makes type inference difficult.
 */
export type FieldSelectProps<TValue> = Omit<
    AutocompleteProps<TValue, false, boolean | undefined, false>,
    'value' | 'options' | 'onChange' | 'renderInput' | 'size' | 'renderOption' | 'freeSolo'
> &
    Partial<Pick<FieldTextProps, 'error' | 'helperText' | 'size'>> & {
        value: TValue | null;

        options: TValue[];

        onChange: (value: TValue | null) => void;

        /**
         * Callback that is called after the value has changed.
         *
         * Do not use this to update the value, this is already done by `onChange`.
         */
        afterChange?: (value: TValue | null) => void;

        /**
         * Render the option, use `getOptionLabel` by default.
         *
         * Note: This is a wrapper function for the orignal `renderOption` in MUI Autocomplete.
         *       We avoid exposing `renderOption` directly because we cannot guarantee
         *       that all required props are set at all times. See here: https://mui.com/material-ui/migration/v5-component-changes/#update-renderoption
         */
        renderOption?: (option: TValue, state: AutocompleteRenderOptionState) => React.ReactNode;

        autoFocus?: boolean;

        virtualized?: boolean;

        inputProps?: Omit<FieldTextProps['inputProps'], 'value' | 'onChange'>;
    };

function FieldSelectInner<TValue>(
    {
        value,
        options,
        onChange,
        afterChange,
        renderOption,
        placeholder,
        error,
        helperText,
        autoFocus,
        virtualized = false,
        size = 'large',
        inputProps,
        ...rest
    }: FieldSelectProps<TValue>,
    outerRef: React.ForwardedRef<unknown>,
): JSX.Element {
    // assertUniqueOptionLables({ options, ...rest });

    // Virtualization is only supported for non-grouped options.
    const isVirtualized = rest.groupBy === undefined && virtualized;
    const virtualizedProps = isVirtualized ? generateVirtualizeProps() : {};

    return (
        <Autocomplete
            ref={outerRef}
            value={value}
            options={options}
            onChange={(_, value) => {
                onChange(value);
                afterChange?.(value);
            }}
            renderInput={(props) => (
                // Hack: `value` and `onChange` are required but have no effect because
                // the FieldText internal values are overridden by `props.inputProps.value`.
                <FieldText
                    {...props}
                    inputProps={{
                        style: {
                            height: '20px',
                            padding: size === 'large' ? '4px' : '0px 8px',
                        },
                        ...props.inputProps,
                    }}
                    type="text"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    value={null}
                    onChange={() => {}}
                    error={error}
                    helperText={helperText}
                    size={size}
                    InputProps={{ ...props.InputProps, ...inputProps }}
                />
            )}
            renderOption={
                isPresent(renderOption)
                    ? (props, option, state) => <li {...props}>{renderOption(option, state)}</li>
                    : undefined
            }
            autoHighlight={true}
            popupIcon={<ArrowDropDownRounded />}
            {...virtualizedProps}
            {...omit(rest, 'inputRef')}
        />
    );
}

// To restrict the type of props that can be passed into the FieldSelectInner, https://stackoverflow.com/a/58473012
declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export const FieldSelect = React.forwardRef(FieldSelectInner);

/**
 * Asserts that the labels of the options are unique. If a `getOptionKey` function is provided, it asserts that the keys are unique.
 * The label needs to be unique because the `getOptionLabel` function is used to generate the react key for the options.
 */
export function assertUniqueOptionLables<TValue>({
    options,
    getOptionLabel,
    getOptionKey,
}: {
    options: TValue[];
    getOptionLabel?: (option: TValue) => string;
    getOptionKey?: (option: TValue) => string | number;
}): void {
    const keySet = new Set<string | number>();
    const labelSet = new Set<string>();

    options.forEach((option) => {
        if (isPresent(getOptionKey)) {
            const key = getOptionKey(option);
            if (keySet.has(key)) {
                throwErrorUnlessProduction(`FieldSelect: Duplicate keys found in options. Keys must be unique`, {
                    extra: { key },
                });
            } else {
                keySet.add(key);
            }
        }

        if (!isPresent(getOptionKey) && isPresent(getOptionLabel)) {
            const label = getOptionLabel(option);
            if (labelSet.has(label)) {
                throwErrorUnlessProduction(
                    'FieldSelect: Duplicate lable found in options. Label must be unique or provide a getOptionKey function',
                    { extra: { label } },
                );
            } else {
                labelSet.add(label);
            }
        }
    });
}
