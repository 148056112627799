import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import { Notes } from '@mui/icons-material';
import { styled, TableCell } from '@mui/material';

const ClickableCell = styled(TableCell)({
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    verticalAlign: 'center',
});

export const NotesCell = ({ notes }: { notes?: string }): JSX.Element => {
    if (!Boolean(notes) || !isPresent(notes)) {
        return <ClickableCell />;
    }

    return (
        <ClickableCell>
            <Flexbox justifyContent={'center'}>
                <Tooltip
                    variant={'white'}
                    title={
                        <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                            <Text variant="h5" color={colorSystem.neutral[8]}>
                                <Trans>Notes</Trans>
                            </Text>
                            <Text variant="body-small" color={colorSystem.neutral[8]}>
                                {notes}
                            </Text>
                        </Flexbox>
                    }
                >
                    <Notes
                        style={{
                            color: colorSystem.neutral[6],
                            border: `1px solid ${colorSystem.neutral[2]}`,
                            borderRadius: '24px',
                            padding: '2px 8px',
                            fontSize: '16px',
                        }}
                    />
                </Tooltip>
            </Flexbox>
        </ClickableCell>
    );
};
