import { Trans, t } from '@lingui/macro';
import { formatCurrency, formatDecimal, formatToLongDate, isPresent, transEnum } from '@luminovo/commons';
import {
    AvatarIcon,
    CenteredLayout,
    FieldTextControlled,
    Flexbox,
    SecondaryButton,
    Tag,
    TertiaryButton,
    TertiaryIconButton,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import {
    ContributorsDTO,
    CustomerDTO,
    DemandScenarioDTO,
    EmsRfqUpdateDTO,
    PrototypeDemandScenarioDTO,
    RfqDTO,
    UserDTO,
} from '@luminovo/http-client';
import {
    DemandScenarioTypeTag,
    extractQuantityOfDemandScenarioForAssembly,
    formatAdditionalServiceTranslation,
} from '@luminovo/manufacturing-core';
import { Add, Delete, Edit, Notes as NotesIcon, ReadMore } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import { PropsWithChildren, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { WarningExclamationRoundedIcon } from '../../components/Icons/icons';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import { StyledRfqStatusBox } from '../../components/StyledComponents';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { ErrorFallback } from '../../components/errorHandlers/ErrorBoundary';
import { FormContainer } from '../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../components/formLayouts/SubmitButton';
import { Protected } from '../../permissions/Protected';
import { useCustomerFromRfq } from '../../resources/customer/customerHandler';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { assemblyTypePublicTranslations } from '../../resources/rfq/i18n';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { useContributors } from '../../resources/user/userHandler';
import { UrlParams } from '../../utils/routes';
import { ExportCostedBomSplitButton } from '../Sourcing/components/ExportCostedBom';
import { NoSourcingScenario } from '../Sourcing/components/SourcingPage/SourcingPage';
import { SourcingScenarioTable } from '../Sourcing/components/SourcingPage/SourcingScenarioTable';
import { AddDemandScenarioButton } from './AddDemandScenarioButton';
import { ToolbarRfqDashboard } from './components/ToolbarRfqDashboard';
import {
    AssemblyQuantitiesMap,
    DemandSourcingMismatch,
    checkDemandSourcingMismatch,
    useAssemblyQuantitiesMap,
} from './demandSourcingMismatch';
import { useDemandScenariosDrawer } from './useDemandScenariosDrawer';

interface RfqDashboardProps extends UrlParams<'/rfqs/:rfqId/dashboard'> {}

const NoneLabel: React.FunctionComponent = () => (
    <Text variant="body-small" style={{ color: colorSystem.neutral[5] }}>
        <Trans>None</Trans>
    </Text>
);

const HeaderLine: React.FunctionComponent<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                {label}
            </Text>
            <Text
                variant="body-small"
                color={colorSystem.neutral[9]}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
                {children}
            </Text>
        </Flexbox>
    );
};

const VolumeEstimateRange: React.FunctionComponent<{
    rfq: RfqDTO;
}> = ({ rfq }) => {
    const volumeEstimate = rfq.ems_rfq_details.volume_estimate;

    if (!volumeEstimate) {
        return <NoneLabel />;
    }

    const upperBound = volumeEstimate.upper
        ? formatCurrency(parseFloat(volumeEstimate.upper), volumeEstimate.currency, 'estimate')
        : undefined;
    const lowerBound = volumeEstimate.lower
        ? formatCurrency(parseFloat(volumeEstimate.lower), volumeEstimate.currency, 'estimate')
        : undefined;

    const hasUpperBound = isPresent(upperBound);
    const hasLowerBound = isPresent(lowerBound);

    if (hasUpperBound && hasLowerBound) {
        return (
            <Trans>
                <Text variant="body-small" color={colorSystem.neutral[9]}>
                    In between
                </Text>{' '}
                <Text variant="body-small" color={colorSystem.neutral[9]}>
                    {lowerBound}
                </Text>{' '}
                <Text variant="body-small" color={colorSystem.neutral[9]}>
                    and
                </Text>{' '}
                <Text variant="body-small" color={colorSystem.neutral[9]}>
                    {upperBound}
                </Text>
            </Trans>
        );
    } else if (hasLowerBound) {
        return (
            <Text variant="body-small" color={colorSystem.neutral[9]}>
                ≥ {lowerBound}
            </Text>
        );
    } else if (hasUpperBound) {
        return (
            <Text variant="body-small" color={colorSystem.neutral[9]}>
                ≤ {upperBound}
            </Text>
        );
    }

    return <></>;
};

const DemandScenario = ({
    demandScenario,
    indexNumber,
    rfq,
    assemblyQuantitiesMap,
    assemblyQuantitiesMapIsLoading,
}: {
    demandScenario: DemandScenarioDTO;
    indexNumber: number;
    rfq: RfqDTO;
    assemblyQuantitiesMap: AssemblyQuantitiesMap;
    assemblyQuantitiesMapIsLoading: boolean;
}) => {
    const demandSourcingMismatch: DemandSourcingMismatch = assemblyQuantitiesMapIsLoading
        ? { type: 'no-mismatch' }
        : checkDemandSourcingMismatch(demandScenario, assemblyQuantitiesMap);
    const { openDrawer } = useDemandScenariosDrawer({ demandSourcingMismatch });
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: colorSystem.neutral[0],
                    borderRadius: ' 8px 8px 0px 0px',
                    padding: '12px',
                    border: `1px solid ${colorSystem.neutral[2]}`,
                }}
            >
                <Flexbox justifyContent={'space-between'} gap={'8px'} alignItems={'center'}>
                    <Text variant="h5">
                        <Trans>Demand scenario</Trans> {indexNumber + 1}
                    </Text>
                    <DemandScenarioTypeTag type={demandScenario.type} />
                </Flexbox>
                {demandScenario.type === 'Series' && <TimeFrameInformation demandScenario={demandScenario} />}
                {demandScenario.type === 'Prototype' && <DesiredDateInformation demandScenario={demandScenario} />}
                {rfq.top_level_assemblies.map((topLevelAssembly) => (
                    <Flexbox key={topLevelAssembly.id} justifyContent={'space-between'} gap={1} mt={'8px'}>
                        <Text variant="h5" style={{ color: colorSystem.neutral[6] }}>
                            {topLevelAssembly.designator}
                        </Text>
                        <Text variant="h5">
                            {extractQuantityOfDemandScenarioForAssembly(demandScenario, topLevelAssembly.id)}
                        </Text>
                    </Flexbox>
                ))}
                {demandSourcingMismatch.type !== 'no-mismatch' && (
                    <Flexbox mt="8px" gap={4} alignItems="center">
                        <WarningExclamationRoundedIcon style={{ color: colorSystem.yellow[7], fontSize: '12px' }} />
                        <Text variant="body-small" color={colorSystem.yellow[7]}>
                            <Trans>Demand does not match the sourcing scenarios</Trans>
                        </Text>
                    </Flexbox>
                )}
            </Box>
            <Box
                sx={{
                    borderRadius: ' 0px 0px 8px 8px',
                    border: `1px solid ${colorSystem.neutral[2]}`,
                    borderTop: 'none',
                }}
            >
                <TertiaryButton
                    onClick={() => openDrawer({ demandScenario, indexNumber, rfq })}
                    startIcon={<ReadMore fontSize="inherit" />}
                    size={'small'}
                >
                    <Trans>View details</Trans>
                </TertiaryButton>
            </Box>
        </Box>
    );
};

const TimeFrameInformation = ({ demandScenario }: { demandScenario: DemandScenarioDTO }) => {
    return (
        <Flexbox mt={'12px'} gap={'4px'}>
            <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>
                <Trans> Time frame</Trans>
            </Text>
            <Text variant="body-small">
                {demandScenario.start_year} - {demandScenario.end_year}
            </Text>
        </Flexbox>
    );
};

const DesiredDateInformation = ({ demandScenario }: { demandScenario: PrototypeDemandScenarioDTO }) => {
    const firstTargetDate = demandScenario.demands[0].delivery_date.target_date;
    return (
        <Flexbox mt={'12px'} gap={'4px'}>
            <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>
                <Trans> Desired date</Trans>
            </Text>
            <Text variant="body-small">
                {firstTargetDate.type === 'Date' ? formatToLongDate(firstTargetDate.value) : t`As soon as possible`}
            </Text>
        </Flexbox>
    );
};

const DemandScenarios: React.FunctionComponent<{
    rfq: RfqDTO;
}> = ({ rfq }) => {
    const { isLoading: assemblyQuantitiesMapIsLoading, assemblyQuantitiesMap } = useAssemblyQuantitiesMap(
        rfq.sourcing_scenarios,
    );

    const demandScenarios = rfq.demand_scenarios;
    if (demandScenarios.length === 0) {
        return <NoneLabel />;
    }

    return (
        <Flexbox flexDirection={'column'} gap={'24px'}>
            {demandScenarios
                .filter((demandScenario) => demandScenario.demands.length)
                .map((demandScenario, i) => (
                    <DemandScenario
                        key={demandScenario.id}
                        demandScenario={demandScenario}
                        indexNumber={i}
                        rfq={rfq}
                        assemblyQuantitiesMap={assemblyQuantitiesMap}
                        assemblyQuantitiesMapIsLoading={assemblyQuantitiesMapIsLoading}
                    />
                ))}
        </Flexbox>
    );
};

const AdditionalServices: React.FunctionComponent<{
    rfq: RfqDTO;
}> = ({ rfq }) => {
    const { data } = useHttpQuery('GET /rfqs/:rfqId/additional-services', {
        pathParams: { rfqId: rfq.id },
    });
    const { user } = useCurrentUserDetailsContext();

    if (!data) {
        return <CircularProgress size={'small'} />;
    }

    if (data.data.length === 0) {
        return <NoneLabel />;
    }

    return (
        <Text>
            {data.data
                .map((additionalService) => formatAdditionalServiceTranslation(additionalService, user.user_language))
                .join(', ')}
        </Text>
    );
};

const ContributorsInfo: React.FunctionComponent<{
    users: UserDTO[];
}> = ({ users }) => {
    if (users.length === 0) {
        return <NoneLabel />;
    }

    return (
        <Flexbox gap={'4px'} flexWrap={'wrap'} paddingTop={'4px'}>
            {users.map((contributor) => (
                <Flexbox gap={'4px'} key={contributor.id} alignItems={'center'}>
                    <AvatarIcon
                        size="small"
                        user={{
                            firstName: contributor.first_name,
                            lastName: contributor.last_name,
                            email: contributor.email,
                        }}
                    />
                    <Tooltip title={contributor.email}>
                        <Text variant="body-small">
                            {contributor.first_name} {contributor.last_name}
                        </Text>
                    </Tooltip>
                </Flexbox>
            ))}
        </Flexbox>
    );
};

type EditNotesFormState = {
    notes: string | undefined;
};

const EditNotesFormInner = (): JSX.Element => {
    const { control } = useFormContext<EditNotesFormState>();
    return <FieldTextControlled required min={1} control={control} name="notes" FieldProps={{ multiline: true }} />;
};

const EditNotesForm = ({
    rfq,
    onSubmit,
    onCancel,
}: {
    rfq: RfqDTO;
    onSubmit: SubmitHandler<EditNotesFormState>;
    onCancel: () => void;
}): JSX.Element => {
    return (
        <FormContainer defaultValues={{ notes: rfq.notes ?? undefined }} onSubmit={onSubmit}>
            <Flexbox flexDirection="column" gap="8px">
                <EditNotesFormInner />
                <Flexbox justifyContent="end" gap="8px">
                    <SecondaryButton size="small" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </SecondaryButton>
                    <SubmitButton size="small" style={{ padding: '3px 6px' }} />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const NotesWithIcon = (): JSX.Element => {
    return (
        <Flexbox gap={4} alignItems="center" minHeight={'24px'}>
            <NotesIcon fontSize="small" style={{ color: colorSystem.neutral[7] }} />
            <Text variant="h5" color={colorSystem.neutral[8]}>
                <Trans>Notes</Trans>
            </Text>
        </Flexbox>
    );
};

const Notes = ({ rfq }: { rfq: RfqDTO }): JSX.Element => {
    const [isEditing, setIsEditing] = useState(false);

    const { mutateAsync } = useHttpMutation('PATCH /rfqs/:rfqId', {
        snackbarMessage: t`Notes updated successfully`,
        onSuccess: () => setIsEditing(false),
    });
    const onSubmit = async (form: EditNotesFormState) => {
        const data: EmsRfqUpdateDTO = {
            notes: form.notes ?? null,
        };
        await mutateAsync({ pathParams: { rfqId: rfq.id }, requestBody: { type: 'Ems', data } });
    };

    if (!isEditing) {
        return isPresent(rfq.notes) ? (
            <Flexbox flexDirection="column" gap="8px" width="100%">
                <Flexbox justifyContent="space-between">
                    <NotesWithIcon />
                    <TertiaryIconButton size="small" onClick={() => setIsEditing(true)}>
                        <Edit fontSize="inherit" />
                    </TertiaryIconButton>
                </Flexbox>
                <Flexbox>
                    <pre>
                        <Text style={{ whiteSpace: 'break-spaces' }}>{rfq.notes}</Text>
                    </pre>
                </Flexbox>
            </Flexbox>
        ) : (
            <TertiaryButton startIcon={<Add fontSize="inherit" />} size="small" onClick={() => setIsEditing(true)}>
                <Trans>Add notes</Trans>
            </TertiaryButton>
        );
    }

    return (
        <Flexbox flexDirection="column" gap="8px" width="100%">
            <Flexbox justifyContent="space-between">
                <NotesWithIcon />
                <TertiaryIconButton size="small" onClick={() => onSubmit({ notes: undefined })}>
                    <Delete fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>
            <EditNotesForm rfq={rfq} onSubmit={onSubmit} onCancel={() => setIsEditing(false)} />
        </Flexbox>
    );
};

const RfqCard: React.FunctionComponent<{
    rfq: RfqDTO;
    contributors: ContributorsDTO;
    customer: CustomerDTO;
}> = ({ rfq, contributors, customer }) => {
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Protected requiredPermissions={['view:rfq:details']}>
                <Flexbox
                    flexDirection={'column'}
                    gap={'32px'}
                    bgcolor={colorSystem.neutral.white}
                    borderRadius={'8px'}
                    padding="24px 0"
                    height={'fit-content'}
                >
                    <Flexbox flexDirection={'column'} gap={'8px'} padding="0 24px">
                        <StyledRfqStatusBox rfqStatus={rfq.status} />
                        <Flexbox flexDirection={'column'}>
                            <Text variant="h3">
                                {isPresent(rfq.ems_internal_number)
                                    ? `${rfq.name} • ${rfq.ems_internal_number}`
                                    : `${rfq.name}`}
                            </Text>
                            <Text variant="body">
                                {isPresent(customer.customer_number)
                                    ? `${customer.name} • ${customer.customer_number}`
                                    : `${customer.name}`}
                            </Text>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={'12px'} padding="0 24px">
                        <Text variant="h4">
                            <Trans>General information</Trans>
                        </Text>
                        <HeaderLine label={t`RfQ`}>{rfq.name}</HeaderLine>
                        <HeaderLine label={t`Due date`}>
                            {isPresent(rfq.due_date) ? formatToLongDate(rfq.due_date) : <NoneLabel />}
                        </HeaderLine>
                        <HeaderLine label={t`Currency`}>{rfq.currency}</HeaderLine>
                        <HeaderLine label={t`Volume estimate`}>
                            <VolumeEstimateRange rfq={rfq} />
                        </HeaderLine>
                        <HeaderLine label={t`Internal contributors`}>
                            <ContributorsInfo users={contributors.internal} />
                        </HeaderLine>
                        <HeaderLine label={t`External contributors`}>
                            <ContributorsInfo users={contributors.external} />
                        </HeaderLine>
                        <HeaderLine label={t`Services selected by customer`}>
                            <AdditionalServices rfq={rfq} />
                        </HeaderLine>
                    </Flexbox>
                    <Flexbox flexDirection="column" gap={12}>
                        <Divider style={{ margin: 0 }} />
                        <Flexbox padding="0 24px">
                            <Notes rfq={rfq} />
                        </Flexbox>
                        <Divider />
                    </Flexbox>
                    <Flexbox flexDirection="column" padding="0 24px">
                        <Flexbox gap={16} alignItems="center">
                            <Text variant="h4">
                                <Trans>Customer demand</Trans>
                            </Text>
                            <AddDemandScenarioButton rfq={rfq} />
                        </Flexbox>
                        <Box mt={'12px'}>
                            <DemandScenarios rfq={rfq} />
                        </Box>
                    </Flexbox>
                </Flexbox>
            </Protected>
        </Sentry.ErrorBoundary>
    );
};

const DesignCard: React.FunctionComponent<{
    rfq: RfqDTO;
}> = ({ rfq }) => {
    return (
        <LayoutCard title={t`Design`}>
            <Flexbox gap={'60px'}>
                <Flexbox flexDirection={'column'} gap={8}>
                    <Text variant="h5" color={colorSystem.neutral[6]}>
                        <Trans>Assembly type</Trans>
                    </Text>
                    <Flexbox gap={4}>
                        {rfq.assemblies_types.length > 0 ? (
                            rfq.assemblies_types.map(({ type }, i) => (
                                <Tag
                                    key={i}
                                    color="neutral"
                                    attention="low"
                                    label={transEnum(type, assemblyTypePublicTranslations)}
                                />
                            ))
                        ) : (
                            <Text variant="body" color={'textPrimary'}>
                                <Trans>No assemblies</Trans>
                            </Text>
                        )}
                    </Flexbox>
                </Flexbox>

                <Flexbox flexDirection={'column'} gap={8}>
                    <Text variant="h5" color={colorSystem.neutral[6]}>
                        <Trans>Assemblies</Trans>
                    </Text>
                    <Text variant="body" color={'textPrimary'}>
                        {formatDecimal(rfq.assemblies_count)}
                    </Text>
                </Flexbox>
                <Flexbox flexDirection={'column'} gap={8}>
                    <Text variant="h5" color={colorSystem.neutral[6]}>
                        <Trans>Design items</Trans>
                    </Text>
                    <Text variant="body" color={'textPrimary'}>
                        {formatDecimal(rfq.design_items_count)}
                    </Text>
                </Flexbox>
            </Flexbox>
        </LayoutCard>
    );
};

const SourcingCard: React.FunctionComponent<{
    rfq: RfqDTO;
}> = ({ rfq }) => {
    return (
        <LayoutCard title={t`Sourcing`} actions={<ExportCostedBomSplitButton rfqId={rfq.id} size={'small'} />}>
            <Protected requiredPermissions={['view:sourcing']}>
                {rfq.sourcing_scenarios.length === 0 ? (
                    <CenteredLayout width={'60vw'}>
                        <NoSourcingScenario rfqId={rfq.id} />
                    </CenteredLayout>
                ) : (
                    <Box height={'35vh'}>
                        <SourcingScenarioTable
                            rfqId={rfq.id}
                            sourcingScenarioIds={rfq.sourcing_scenarios}
                            columnsKey={'dashboard'}
                        />
                    </Box>
                )}
            </Protected>
        </LayoutCard>
    );
};

export const TenantDashboardPage = (props: RfqDashboardProps): JSX.Element => {
    const { data: rfq } = useRfQ(props.pathParams.rfqId);
    const { data: contributors } = useContributors(props.pathParams.rfqId);
    const { data: customer } = useCustomerFromRfq(props.pathParams.rfqId);

    if (!isPresent(rfq) || !isPresent(contributors) || !isPresent(customer)) {
        return (
            <PageLayout>
                <CenteredLayout height={'50vh'}>
                    <CircularProgress />
                </CenteredLayout>
            </PageLayout>
        );
    }

    return (
        <PageLayout layout="fragment" header={<ToolbarRfqDashboard rfqId={rfq.id} />} bgcolor={colorSystem.neutral[1]}>
            <Box display={'grid'} gridTemplateColumns="400px auto" gap={'24px'} padding={'24px'}>
                <RfqCard rfq={rfq} contributors={contributors} customer={customer} />
                <Flexbox flexDirection={'column'} gap={'24px'}>
                    <DesignCard rfq={rfq} />
                    <SourcingCard rfq={rfq} />
                </Flexbox>
            </Box>
        </PageLayout>
    );
};
