import { storageKey } from './reducer';
import { PersistentTableState } from './types';

/**
 * Saves the state of the table in the given storage.
 *
 * @see loadTableState
 */
export function saveTableState({
    tableId,
    storage,
    tableState,
}: {
    tableId: string;
    storage: Storage;
    tableState: PersistentTableState;
}): void {
    storage.setItem(storageKey(tableId), JSON.stringify(tableState));
}
