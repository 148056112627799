import { plural, t } from '@lingui/macro';
import { Flexbox, Message } from '@luminovo/design-system';
import React from 'react';
import { useHistory } from 'react-router';
import { useSourcingScenarios } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '../../../utils/routes';
import { nthValueInArray } from '../../../utils/typingUtils';
import { CalculationWarning, isMissingDesignItemInformation, isMissingOffer } from './utils/calculationWarnings';

interface WarningMessage {
    title: string;
    message: string;
    onClick: () => void;
    isVisible: boolean;
}

interface WarningMessagesListProps {
    messages: WarningMessage[];
}

const WarningMessagesList: React.FC<WarningMessagesListProps> = ({ messages }) => {
    return (
        <Flexbox flexDirection={'column'} padding={'24px'} gap={'16px'}>
            {messages.map(
                ({ title, message, onClick, isVisible }, index) =>
                    isVisible && (
                        <Message
                            key={index}
                            variant="yellow"
                            attention="high"
                            size="small"
                            message={message}
                            title={title}
                            action={{ label: t`Resolve`, onClick }}
                        />
                    ),
            )}
        </Flexbox>
    );
};

export const CalculationWarnings = ({
    bomWarnings,
    rfqId,
    assemblyId,
}: {
    bomWarnings: CalculationWarning[];
    rfqId: string;
    assemblyId: string;
}): JSX.Element => {
    const sourcingScenariosWithMissingOffers = [
        ...new Set(
            bomWarnings.filter(isMissingOffer).flatMap((warning) => warning.data.map((d) => d.sourcing_scenario_id)),
        ),
    ];
    const { data } = useSourcingScenarios(sourcingScenariosWithMissingOffers);
    const names = data?.map((d) => d.name) ?? [];
    const sourcingScenarioMessage =
        names.length === 0
            ? '-'
            : t`Some formula inputs require information in ${plural(names.length, {
                  one: '# sourcing scenario',
                  other: '# sourcing scenarios',
              })}: ${names.join(', ')}. Click resolve to add this information`;

    const history = useHistory();
    return (
        <WarningMessagesList
            messages={[
                {
                    title: t`BOM information missing`,
                    message: t`Some formula inputs require information in the BOM. Click resolve to add this information`,
                    onClick: () =>
                        history.push(
                            route(
                                '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                                { rfqId, assemblyId },
                                {
                                    isReadonly: null,
                                    designItemId: nthValueInArray(bomWarnings.filter(isMissingDesignItemInformation), 0)
                                        ?.data[0]?.id,
                                    filters: null,
                                    bomTab: null,
                                    dashboardFilters: null,
                                    search: null,
                                    onlyShowItemsWithManufacturingWarnings: null,
                                    currentParentAssemblyId: null,
                                },
                            ),
                        ),
                    isVisible:
                        bomWarnings.filter((warning) => warning.type === 'MissingDesignItemInformation').length > 0,
                },
                {
                    title: t`Sourcing information missing`,
                    message: sourcingScenarioMessage,
                    onClick: () =>
                        history.push(
                            route('/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId', {
                                rfqId,
                                sourcingScenarioId:
                                    bomWarnings.filter(isMissingOffer)[0]?.data[0]?.sourcing_scenario_id,
                            }),
                        ),
                    isVisible: bomWarnings.filter(isMissingOffer).length > 0,
                },
            ]}
        />
    );
};
