import { uniqBy } from '@luminovo/commons';
import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { PartLite, PartLiteTypes } from '@luminovo/http-client';
import { Box, Divider } from '@mui/material';
import { PartLiteLabel } from './PartLiteLabel';

export function NegotiationPartsLabel({ parts, hideIpns }: { parts: PartLite[]; hideIpns?: boolean }) {
    const flattenedParts = hideIpns ? parts.flatMap((part) => flattenPartLite(part)) : parts;
    const [first, ...rest] = flattenedParts;

    if (!first) {
        return (
            <Text variant="inherit" color={colorSystem.neutral[6]}>
                No part options
            </Text>
        );
    }
    return (
        <Tooltip
            variant="white"
            title={
                <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr', gap: 1, paddingY: 1 }}>
                    <Text color={colorSystem.neutral[6]} variant="h5">
                        {flattenedParts.length === 1 ? 'Part option' : 'Part options'}
                    </Text>
                    <Divider />
                    {flattenedParts.map((part, key) => (
                        <PartLiteLabel variant={'body-small'} key={key} part={part} />
                    ))}
                </Box>
            }
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    gap: 1,
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <PartLiteLabel variant={'body-small'} part={first} />
                </div>
                {rest.length > 0 && <div>+{rest.length}</div>}
            </Box>
        </Tooltip>
    );
}

/**
 * Flattens a PartLite object and its matches into an array of PartLite objects.
 * By default we only resolve the first level of matches.
 *
 * @param options.flattenAllLevels - If true, recursively flattens nested matches. Default is false.
 * @param options.unique - If true, removes duplicate parts based on their id. Default is true.
 */
export function flattenPartLite(
    part: PartLite,
    options?: { flattenAllLevels?: boolean; unique?: boolean },
): PartLite[] {
    const { flattenAllLevels = false, unique = true } = options ?? {};
    const flattenMatches = (matches: PartLite[]): PartLite[] =>
        flattenAllLevels ? matches.flatMap((match) => flattenPartLite(match, options)) : matches;

    let result: PartLite[];
    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            result = [part];
            break;
        case PartLiteTypes.Custom:
            result = [part];
            break;
        case PartLiteTypes.Generic:
            result = [...flattenMatches(part.matches)];
            break;
        case PartLiteTypes.RawSpecification:
            result = [part];
            break;
        case PartLiteTypes.Ipn:
            result = [...flattenMatches(part.matches)];
            break;
        case PartLiteTypes.CustomComponent:
            result = [...flattenMatches(part.matches)];
            break;
    }

    return unique ? uniqBy(result, 'id') : result;
}
