import { Trans } from '@lingui/macro';
import { PrimaryButton, SecondaryButton } from '@luminovo/design-system';
import { Box, useTheme } from '@mui/material';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';

interface NavigationButtonsProps {
    showPrevious: boolean;
    nextLabel: string;
    onNext: 'submit' | (() => Promise<void>);
    onPrevious?: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
}

export const NavigationButtons = ({
    nextLabel,
    showPrevious,
    onPrevious,
    onNext,
    isDisabled,
    isLoading,
}: NavigationButtonsProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: theme.spacing(6),
            }}
        >
            {showPrevious && (
                <SecondaryButton onClick={onPrevious} style={{ marginRight: theme.spacing(2) }}>
                    <Trans>Previous</Trans>
                </SecondaryButton>
            )}
            {onNext !== 'submit' ? (
                <PrimaryButton onClick={onNext} disabled={isDisabled} isLoading={isLoading}>
                    {nextLabel}
                </PrimaryButton>
            ) : (
                <SubmitButton label={nextLabel} />
            )}
        </Box>
    );
};
