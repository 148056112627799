import { Trans } from '@lingui/macro';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Typography } from '@mui/material';
import { colorSystem } from '../../../themes';

export const PerPanelIconAndText = (): JSX.Element => {
    return (
        <Box display={'flex'} color={colorSystem.neutral[9]}>
            <CheckIcon fontSize={'small'} />
            <Typography style={{ marginLeft: 4 }}>
                <Trans>Yes</Trans>
            </Typography>
        </Box>
    );
};

export const NotPerPanelIconAndText = (): JSX.Element => {
    return (
        <Box display={'flex'} color={colorSystem.neutral[6]}>
            <ClearIcon fontSize={'small'} />
            <Typography style={{ marginLeft: 4 }}>
                <Trans>No</Trans>
            </Typography>
        </Box>
    );
};
