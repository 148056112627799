import { CostComponentExpenseConfiguration, CostComponentsActivities, DriverDTO } from '@luminovo/http-client';
import { convertDriverIdDtoToDriverIdString, selectDriverIdString } from '@luminovo/manufacturing-core';
import { stringToNumber } from '../../../utils/typingUtils';

export interface DriverCountInformation {
    driverCount: number | undefined;
    automaticDriverCount: number | undefined;
}

export function getDriverCountInformation(
    costComponent: CostComponentsActivities,
    driver: DriverDTO,
): DriverCountInformation {
    const driverCounts = costComponent.details.driver_count.counts.find((driverCounts) => {
        return convertDriverIdDtoToDriverIdString(driverCounts[0]) === selectDriverIdString(driver);
    });

    //this is safe because driverCounts is a tuple.
    const driverCount = driverCounts ? driverCounts[1] : undefined;
    const automaticDriverCounts = costComponent.details.automatic_driver_count.counts.find(
        (driverCounts) => convertDriverIdDtoToDriverIdString(driverCounts[0]) === selectDriverIdString(driver),
    );

    //this is safe because driverCounts is a tuple.
    const automaticDriverCount = automaticDriverCounts ? automaticDriverCounts[1] : undefined;
    return {
        driverCount: stringToNumber(driverCount) ?? undefined,
        automaticDriverCount: stringToNumber(automaticDriverCount) ?? undefined,
    };
}

export function getDriverCountInformationExpenseConfiguration(
    costComponent: CostComponentExpenseConfiguration,
    driver: DriverDTO,
): DriverCountInformation {
    const driverCounts = costComponent.driver_count.counts.find(
        (x) => convertDriverIdDtoToDriverIdString(x[0]) === selectDriverIdString(driver),
    );
    //this is safe because driverCounts is a tuple.
    const driverCount = driverCounts ? driverCounts[1] : undefined;
    const automaticDriverCounts = costComponent.automatic_driver_count.counts.find(
        (driverCounts) => convertDriverIdDtoToDriverIdString(driverCounts[0]) === selectDriverIdString(driver),
    );
    //this is safe because driverCounts is a tuple.
    const automaticDriverCount = automaticDriverCounts ? automaticDriverCounts[1] : undefined;

    return {
        driverCount: stringToNumber(driverCount) ?? undefined,
        automaticDriverCount: stringToNumber(automaticDriverCount) ?? undefined,
    };
}
