import { colorSystem } from '@luminovo/design-system';
import { AxisLeft } from '@visx/axis';
import { AnyScaleBand } from '@visx/shape/lib/types';
import { palette } from '../../../color/palette';
import { Datum } from '../types';

export function BarChartAxisLeft<TKeys extends string, T extends Datum<TKeys>>({
    yScale,
    data,
    onBarClick,
    formatX,
}: {
    yScale: AnyScaleBand;
    data: T[];
    onBarClick: (datum: T) => void;
    formatX: (x: string) => string;
}): JSX.Element {
    return (
        <AxisLeft
            scale={yScale}
            stroke={palette.gridLines}
            tickStroke={palette.gridLines}
            tickFormat={(x) => {
                const maxChars = 30;
                if (x.length > maxChars) {
                    return formatX(x).slice(0, maxChars) + '...';
                }
                return x;
            }}
            hideTicks={false}
            numTicks={data.length}
            tickLabelProps={(label) => ({
                fill: colorSystem.neutral[8],
                fontSize: 14,
                fontFamily: "'Noto Sans', sans-serif",
                textAnchor: 'end',
                verticalAnchor: 'middle',

                dx: -4,
                cursor: 'pointer',
                width: 340,
                onClick: (e) => {
                    const datum = data.find((d) => d.label === label);
                    if (datum) {
                        e.stopPropagation();
                        onBarClick(datum);
                    }
                },
            })}
        />
    );
}
