import * as r from 'runtypes';

const PanelWidthAndHeightRuntype = r.Record({
    width_in_mm: r.String,
    height_in_mm: r.String,
});

export type DepanelizationDTO = r.Static<typeof DepanelizationRuntype>;
export const DepanelizationRuntype = r.Union(r.Literal('VCut'), r.Literal('Milling'), r.Literal('MillingAndVCut'));

export type PanelPreferenceDTO = r.Static<typeof PanelPreferenceRuntype>;
export const PanelPreferenceRuntype = r.Record({
    id: r.String,
    name: r.String,
    min_width: r.String,
    min_height: r.String,
    max_width: r.String,
    max_height: r.String,
    max_pcbs: r.Number,
    spacing: PanelWidthAndHeightRuntype,
    padding: PanelWidthAndHeightRuntype,
    depanelization: DepanelizationRuntype,
});

export type PanelPreferencePostDTO = r.Static<typeof PanelPreferencePostRuntype>;
export const PanelPreferencePostRuntype = PanelPreferenceRuntype.omit('id');
