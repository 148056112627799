import { isPresent } from '@luminovo/commons';
import { RfqListItemDTO } from '@luminovo/http-client';

export const extractLowerVolumeEstimate = ({ ems_rfq_details }: RfqListItemDTO): number => {
    const vol = ems_rfq_details.volume_estimate;
    if (!vol || !isPresent(vol.lower)) {
        // as this is only used by the comparators, an absent estimate is interpreted as Infinity, meaning
        // it is placed at the bottom of all results
        return Infinity;
    }
    return parseFloat(vol.lower);
};
