import { isPresent } from '@luminovo/commons';
import { CurrentUserDetailsDTO, UserType } from '@luminovo/http-client';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Permission } from '../../permissions/permissions';
import { useProtected } from '../../permissions/useProtected';
import { httpQuery } from '../../resources/http/httpQuery';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { loadLocale } from '../../utils/internationalisation';
import { CurrentUserDetailsContext } from './CreateContext';

/**
 * Returns the details of the currently logged-in user, including contact information, organization details, and customer information.
 */
export function useCurrentUserDetailsContext(): CurrentUserDetailsDTO & { userType: UserType } {
    const details = React.useContext(CurrentUserDetailsContext);
    if (!details) {
        throw new Error(`Details are not present, make sure you've setup the CurrentUserDetailsContext correctly.`);
    }

    const userType = React.useMemo(() => {
        if (isPresent(details.supplier)) {
            return UserType.Supplier;
        }

        if (isPresent(details.customer)) {
            return UserType.Customer;
        }

        return UserType.Internal;
    }, [details]);

    return { ...details, userType };
}

/**
 * Returns true if the logged-in user is a customer.
 *
 * @deprecated use `useCurrentUserDetailsContext` instead
 */
export function useIsCustomer(): boolean {
    const userTypes = useUserType();
    return userTypes === UserType.Customer;
}

export function useUserType(): UserType {
    const { userType } = useCurrentUserDetailsContext();
    return userType;
}

export const ADMIN_PERMISSIONS: Permission[] = ['edit:organization_settings', 'edit:user:organization'];

/**
 * Helper function to check if the logged-in user is an admin
 */
export function useIsAdminUser(): boolean {
    return useProtected({
        requiredPermissions: ADMIN_PERMISSIONS,
        fallback: false,
        value: true,
    });
}

export function CurrentUserDetailsContextProvider(props: React.PropsWithChildren<{}>) {
    const queryClient = useQueryClient();

    // PERFORMANCE GUIDELINE:
    // 1. Limit prefetching to a minimum to avoid degrading performance.
    // 2. Prefetch before any component suspension.
    // 3. Direct prefetching is safe; useEffect is not required.
    queryClient.prefetchQuery(
        httpQuery('GET /rfqs', { queryParams: { filter: 'all' } }, { meta: { globalErrorHandler: false } }),
    );
    queryClient.prefetchQuery(
        httpQuery('GET /organization-settings/assets', {}, { meta: { globalErrorHandler: false } }),
    );

    queryClient.prefetchQuery(httpQuery('GET /users/organization', {}, { meta: { globalErrorHandler: false } }));

    queryClient.prefetchQuery(httpQuery('GET /customers', {}, { meta: { globalErrorHandler: false } }));

    queryClient.prefetchQuery(
        httpQuery('GET /organization-settings/self-signup', {}, { meta: { globalErrorHandler: false } }),
    );
    queryClient.prefetchQuery(
        httpQuery(
            'GET /organization-settings/customer-portal',
            {},
            {
                meta: { globalErrorHandler: false },
            },
        ),
    );

    const { data: currentUserDetails, isLoading } = useHttpQuery(
        'GET /my/details',
        {},
        {
            useErrorBoundary: true,
            suspense: true,
            staleTime: Infinity,
        },
    );

    React.useEffect(() => {
        isPresent(currentUserDetails) && loadLocale(currentUserDetails.data.user.user_language);
    }, [currentUserDetails]);

    if (isLoading) {
        return null;
    }

    return (
        <CurrentUserDetailsContext.Provider value={currentUserDetails?.data}>
            {props.children}
        </CurrentUserDetailsContext.Provider>
    );
}
