import { t } from '@lingui/macro';
import { isPresent, omit } from '@luminovo/commons';
import { Add } from '@mui/icons-material';
import { Autocomplete, AutocompleteProps, AutocompleteRenderOptionState, InputAdornment } from '@mui/material';
import * as React from 'react';
import { TertiaryButton } from '../../buttons';
import { assertUniqueOptionLables } from '../FieldSelect/FieldSelect';
import { FieldText, FieldTextProps } from '../FieldText';

export type FieldSelectCreatableProps<TValue> = Omit<
    AutocompleteProps<TValue, false, false | undefined, false>,
    'value' | 'options' | 'onChange' | 'renderInput' | 'renderOption'
> &
    Partial<Pick<FieldTextProps, 'error' | 'helperText'>> & {
        value: TValue | null;
        options: TValue[];
        onChange: (value: TValue | null) => void;
        getOptionLabel: (option: TValue) => string;

        /**
         * Render the option, use `getOptionLabel` by default.
         *
         * Note: This is a wrapper function for the orignal `renderOption` in MUI Autocomplete.
         *       We avoid exposing `renderOption` directly because we cannot guarantee
         *       that all required props are set at all times. See here: https://mui.com/material-ui/migration/v5-component-changes/#update-renderoption
         */
        renderOption?: (option: TValue, state: AutocompleteRenderOptionState) => React.ReactNode;
        action: {
            label?: string;
            onClick: (value: string) => void;
            disabled?: boolean;
        };
        noOptionsText?: string;
    };

function FieldSelectInner<TValue>(
    {
        value,
        options,
        onChange,
        placeholder,
        error,
        helperText,
        getOptionLabel,
        renderOption,
        noOptionsText = t`Please select an existing item or create a new one`,
        action,
        ...rest
    }: FieldSelectCreatableProps<TValue>,
    outerRef: React.ForwardedRef<unknown>,
): JSX.Element {
    assertUniqueOptionLables({ options, getOptionLabel, ...rest });

    return (
        <Autocomplete
            ref={outerRef}
            value={value}
            options={options}
            noOptionsText={noOptionsText}
            onChange={(_, value) => {
                onChange(value);
            }}
            getOptionLabel={(option) => getOptionLabel(option)}
            onInputChange={(event, _, reason) => {
                /* this onInputChange is required. Otherwise, the input value 
                 does not clear when the user clicks away from the input.
                 The isPresent(event) means it is not cleared when the value is 
                 controlled from the outside (e.g. via a react hook form setValue event) 
                 */
                if (isPresent(event) && reason === 'reset') {
                    onChange(null);
                }
            }}
            renderOption={
                isPresent(renderOption)
                    ? (props, option, state) => <li {...props}>{renderOption(option, state)}</li>
                    : undefined
            }
            renderInput={(props) => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const inputValue = (props.inputProps as unknown as HTMLInputElement).value;
                const isNewValue =
                    typeof inputValue === 'string' &&
                    inputValue.length > 0 &&
                    options.every((s) => getOptionLabel(s).toLowerCase() !== inputValue.toLowerCase());

                // Hack: `value` and `onChange` are requried but have no effect because
                // the FieldText internal values are overridden by `props.inputProps.value`.
                return (
                    <FieldText
                        {...props}
                        type="text"
                        placeholder={placeholder}
                        value={null}
                        onChange={() => {}}
                        error={error}
                        helperText={helperText}
                        InputProps={{
                            ...props.InputProps,
                            endAdornment: isNewValue && (
                                <InputAdornment position="end">
                                    <TertiaryButton
                                        startIcon={<Add />}
                                        size="small"
                                        onClick={() => action.onClick(inputValue)}
                                        disabled={action.disabled}
                                    >
                                        {action.label ?? t`Add new value`}
                                    </TertiaryButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                );
            }}
            autoHighlight={true}
            {...omit(rest, 'inputRef')}
        />
    );
}

// To restrict the type of props that can be passed into the FieldSelectInner, https://stackoverflow.com/a/58473012
declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export const FieldSelectCreatable = React.forwardRef(FieldSelectInner);
