import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Packaging } from '@luminovo/http-client';
import { packagingTranslations } from '../i18n';

export function formatPackaging(
    packaging: Packaging | null | undefined,
    opts?: {
        ifUnknown?: string;
    },
): string {
    const { ifUnknown = t`Unknown` } = opts ?? {};
    if (!packaging || !packagingTranslations[packaging]) {
        return ifUnknown;
    }
    return transEnum(packaging, packagingTranslations);
}
