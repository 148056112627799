import { t, Trans } from '@lingui/macro';
import { formatMonetaryValue, isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, Link, TertiaryButton, Text } from '@luminovo/design-system';
import { GetApp } from '@mui/icons-material';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { assertPresent } from '../../../../../utils/assertPresent';

const useOrderAndQuotationUrl = (rfqId: string) => {
    const { data: order, isLoading: isLoadingOrder } = useHttpQuery(
        'GET /order-management/orders',
        { queryParams: { rfq_id: rfqId } }, // eslint-disable-line camelcase
        { select: (res) => res.data },
    );
    const {
        data: quotationUrl,
        isLoading: isLoadingQuotation,
        isError: isQuotationError,
    } = useHttpQuery('GET /rfqs/:rfqId/quotation', { pathParams: { rfqId } }, { select: (res) => res.data?.url });

    return {
        order: order,
        isLoadingOrder,
        quotationUrl,
        isLoadingQuotation,
        isQuotationError,
    };
};

export function SectionOrderOverview({ rfqId, divider }: { rfqId: string; divider: JSX.Element }): JSX.Element {
    const { order, isLoadingOrder, quotationUrl, isLoadingQuotation, isQuotationError } =
        useOrderAndQuotationUrl(rfqId);

    const isOrderPresent = !isLoadingOrder && order;
    const isQuotationUrlPresent = !isLoadingQuotation && quotationUrl && !isQuotationError;

    if (!isOrderPresent && !isQuotationUrlPresent) {
        return <></>;
    }

    return (
        <>
            {divider}

            <Flexbox justifyContent={'space-between'} alignItems="center">
                <Text variant="h2" style={{ color: colorSystem.neutral[8] }}>
                    <Trans>Order overview</Trans>
                </Text>
                {isQuotationUrlPresent && (
                    <Link href={assertPresent(quotationUrl)} download>
                        <TertiaryButton startIcon={<GetApp />}>
                            <Trans>Quotation download</Trans>
                        </TertiaryButton>
                    </Link>
                )}
            </Flexbox>

            {isOrderPresent && (
                <>
                    <Flexbox flexDirection={'column'} alignItems="flex-start" gap={8}>
                        <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                            <Trans>Selected order size</Trans>
                        </Text>
                        <Chip label={order.order_size.toString()} color="neutral" />
                    </Flexbox>

                    {isPresent(order.estimated_lead_time_in_days) && (
                        <Flexbox flexDirection={'column'} alignItems="flex-start" gap={8}>
                            <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                                <Trans>Estimated lead time</Trans>
                            </Text>
                            <Chip label={t`${order.estimated_lead_time_in_days} calendar days`} color="neutral" />
                        </Flexbox>
                    )}

                    <Flexbox flexDirection={'column'} alignItems="flex-start" gap={8}>
                        <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                            <Trans>Price</Trans>
                        </Text>
                        <Chip label={formatMonetaryValue(order.total_price)} color="blue" />
                    </Flexbox>
                </>
            )}
        </>
    );
}
