import { LifecycleEnum } from '@luminovo/http-client';

const lifecycleStatusKeyWithComplianceValue: Record<LifecycleEnum, boolean> = {
    [LifecycleEnum.Acquired]: false,
    [LifecycleEnum.Aftermarket]: true,
    [LifecycleEnum.Active]: false,
    [LifecycleEnum.EndOfLife]: true,
    [LifecycleEnum.NotRecommendedForNewDesigns]: true,
    [LifecycleEnum.Obsolete]: true,
    [LifecycleEnum.PreRelease]: false,
    [LifecycleEnum.Unknown]: false,
};
export function isLifecycleError(lifecycleStatus: LifecycleEnum): boolean {
    return lifecycleStatusKeyWithComplianceValue[lifecycleStatus];
}
