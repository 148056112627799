import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { useHistory } from 'react-router-dom';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useTopLevelAssemblies } from '../../../resources/assembly/assemblyHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useOrganizationSolutionPreferences } from '../../../resources/organizationSettings/organizationSolutionPreferenceHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { UrlParams, route } from '../../../utils/routes';
import { SourcingScenarioForm } from './SourcingScenarioForm';
import { SourcingScenarioFormValues, createSourcingScenarioFormDefaultValue } from './converters';

export function AddSourcingScenarioForm({ pathParams }: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/new'>): JSX.Element {
    const { rfqId } = pathParams;
    const history = useHistory();
    const { data: topLevelAssemblies } = useTopLevelAssemblies(rfqId);
    const { data: organizationSolutionPreferences } = useOrganizationSolutionPreferences();

    const { mutateAsync } = useHttpMutation('POST /sourcing-scenarios', {
        snackbarMessage: t`Sourcing scenario created`,
        onSuccess: () => history.push(route('/rfqs/:rfqId/sourcing', { rfqId })),
    });

    const submitHanlder = async (requestBody: SourcingScenarioFormValues) => {
        await mutateAsync({ requestBody });
    };
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    if (
        !isPresent(topLevelAssemblies) ||
        !isPresent(organizationSolutionPreferences) ||
        !isPresent(supplierAndStockLocations)
    ) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    const defaultValues = createSourcingScenarioFormDefaultValue({
        rfqId,
        topLevelAssemblies,
        organizationSolutionPreferences,
        supplierAndStockLocations,
    });

    return <SourcingScenarioForm onSubmit={submitHanlder} defaultValues={defaultValues} />;
}
