import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { TiCredentialsInputDTORuntype, TiLocaleCurrencyDTORuntype, TiResponseBodyDTORuntype } from './tiBackendTypes';

export const tiEndpoints = {
    'GET /3rdparty/tistore/credentials': endpoint({
        description: 'Returns credentials for ti integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: TiResponseBodyDTORuntype,
    }),
    'POST /3rdparty/tistore/credentials': endpoint({
        description: 'Uploads credentials for ti API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TiCredentialsInputDTORuntype,
        responseBody: TiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'PATCH /3rdparty/tistore/credentials': endpoint({
        description: 'Updates credentials for ti API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TiCredentialsInputDTORuntype,
        responseBody: TiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'DELETE /3rdparty/tistore/credentials': endpoint({
        description: 'Deletes existing credentials for ti API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'GET /3rdparty/tistore/supported-currencies': endpoint({
        description: 'Returns possible currencies for ti',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ currencies: r.Array(TiLocaleCurrencyDTORuntype) }),
    }),
};
