import { assertUnreachable } from '@luminovo/commons';
import { DemandScenarioDTO } from '@luminovo/http-client';

export const extractQuantityOfDemandScenarioForAssembly = (
    demandScenario: DemandScenarioDTO,
    assemblyId: string,
): number => {
    const demandScenarioType = demandScenario.type;
    switch (demandScenarioType) {
        case 'Prototype':
            return (
                demandScenario.demands.find((demand) => demand.assembly_id === assemblyId)?.delivery_date.quantity ?? 0
            );
        case 'Series':
            return (
                demandScenario.demands
                    .find((demand) => demand.assembly_id === assemblyId)
                    ?.annual_demands.reduce((acc, demand) => acc + demand.quantity, 0) ?? 0
            );
        default:
            assertUnreachable(demandScenarioType);
    }
};
