import { Trans } from '@lingui/macro';
import { compareByString } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row, StatusChip } from '@luminovo/design-system';
import { CategorySelection, ManufacturingEntityStatusSelection, ProcessSelection } from '@luminovo/http-client';
import { extractStatusColor, TooltipText } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { transEnum } from '../../../components/localization/TransEnum';
import { SitesWithIconFetching } from '../../../components/Site';
import { useSites } from '../../../resources/organizationSettings/sitesHandler';
import { TextCell } from '../ActivitiesTable/activitiesTableComponents';
import {
    categorySelectionPublicTranslations,
    processPublicTranslations,
    statusTranslationsWithError,
} from './sharedManufacturingBackendTypes';

interface RowWithStatus {
    status: ManufacturingEntityStatusSelection;
}

const statusColumn: Column<RowWithStatus> = {
    label: <Trans>Status</Trans>,
    id: 'status',
    render: ({ data: rowData }: Row<RowWithStatus>): JSX.Element => (
        <TextCell
            firstRow={
                <StatusChip
                    label={transEnum(rowData.status, statusTranslationsWithError)}
                    color={extractStatusColor({ status: rowData.status })}
                    key={rowData.status}
                />
            }
        />
    ),
    width: columnWidth.extraSmall,
    filters: [
        {
            id: 'status=active',
            label: <Trans>Active</Trans>,
            predicate: (rowData): boolean => rowData.status === 'Active',
        },
        {
            id: 'status=inactive',
            label: <Trans>Inactive</Trans>,
            predicate: (rowData): boolean => rowData.status === 'Inactive',
        },
    ],
};

interface RowWithDescriptionNullable {
    description: string | null;
}

const descriptionColumn: Column<RowWithDescriptionNullable> = {
    label: <Trans>Description</Trans>,
    id: 'description',
    render: ({ data: rowData }: Row<RowWithDescriptionNullable>) => {
        return (
            <TableCell>
                <TooltipText text={rowData.description ?? '-'} maxWidth={'220px'} minCharactersWithTooltip={3} />
            </TableCell>
        );
    },
    width: columnWidth.large,
    searchable: { searchBy: (rowData): string => rowData.description ?? '' },
};

interface RowWithInternalNumberNullable {
    /* eslint-disable-next-line camelcase */
    internal_number: string | null;
}

const internalNumberColumn: Column<RowWithInternalNumberNullable> = {
    label: <Trans>NR.</Trans>,
    id: 'internalNumber',
    render: ({ data }: Row<RowWithInternalNumberNullable>) => <TextCell firstRow={data.internal_number ?? '-'} />,
    width: columnWidth.medium,
    searchable: { searchBy: (rowData): string => rowData.internal_number ?? '' },
};

interface RowWithName {
    name: string;
}

const nameColumn: Column<RowWithName> = {
    label: <Trans>Name</Trans>,
    id: 'name',
    render: ({ data: rowData }: Row<RowWithName>) => <TextCell firstRow={rowData.name} />,
    width: columnWidth.medium,
    searchable: { searchBy: (rowData): string => rowData.name },
};

interface RowWithProcess {
    process: ProcessSelection;
}

const processFilters: Filter<RowWithProcess>[] = [
    {
        id: 'process=ProcessPreparation',
        label: <Trans>Process preparation</Trans>,
        predicate: (rowData) => rowData.process === 'ProcessPreparation',
    },
    {
        id: 'process=NPI',
        label: <Trans>NPI</Trans>,
        predicate: (rowData) => rowData.process === 'NPI',
    },
    {
        id: 'process=SMT',
        label: <Trans>SMT</Trans>,
        predicate: (rowData) => rowData.process === 'SMT',
    },
    {
        id: 'process=THT',
        label: <Trans>THT</Trans>,
        predicate: (rowData) => rowData.process === 'THT',
    },
    {
        id: 'process=Hand soldering',
        label: <Trans>Hand soldering</Trans>,
        predicate: (rowData) => rowData.process === 'HandSoldering',
    },
    {
        id: 'process=PanelSeparation',
        label: <Trans>Panel separation</Trans>,
        predicate: (rowData) => rowData.process === 'PanelSeparation',
    },
    {
        id: 'process=Potting',
        label: <Trans>Potting</Trans>,
        predicate: (rowData) => rowData.process === 'Potting',
    },
    {
        id: 'process=Ruggedizing',
        label: <Trans>Ruggedizing</Trans>,
        predicate: (rowData) => rowData.process === 'Ruggedizing',
    },
    {
        id: 'process=Testing',
        label: <Trans>Testing</Trans>,
        predicate: (rowData) => rowData.process === 'Testing',
    },
    {
        id: 'process=Inspection',
        label: <Trans>Inspection</Trans>,
        predicate: (rowData) => rowData.process === 'Inspection',
    },
    {
        id: 'process=CableAssembly',
        label: <Trans>Cable assembly</Trans>,
        predicate: (rowData) => rowData.process === 'CableAssembly',
    },
    {
        id: 'process=MechanicalAssembly',
        label: <Trans>Mechanical assembly</Trans>,
        predicate: (rowData) => rowData.process === 'MechanicalAssembly',
    },
    {
        id: 'process=QualityControl',
        label: <Trans>Quality control</Trans>,
        predicate: (rowData) => rowData.process === 'QualityControl',
    },
    {
        id: 'process=Packaging',
        label: <Trans>Packaging</Trans>,
        predicate: (rowData) => rowData.process === 'Packaging',
    },
    {
        id: 'process=Logistics',
        label: <Trans>Logistics</Trans>,
        predicate: (rowData) => rowData.process === 'Logistics',
    },
    {
        id: 'process=Other',
        label: <Trans>Other</Trans>,
        predicate: (rowData) => rowData.process === 'Other',
    },
];

const processColumn: Column<RowWithProcess> = {
    label: <Trans>Process</Trans>,
    id: 'process',
    render: ({ data }: Row<RowWithProcess>) => (
        <TextCell firstRow={transEnum(data.process, processPublicTranslations)} />
    ),
    width: columnWidth.small,
    filters: processFilters,
};

interface RowWithCategory {
    category: CategorySelection;
}

const categoryFilters: Filter<RowWithCategory>[] = [
    {
        id: 'category=Inspection',
        label: <Trans>Inspection</Trans>,
        predicate: (rowData) => rowData.category === 'Inspection',
    },
    {
        id: 'category=Preparation',
        label: <Trans>Preparation</Trans>,
        predicate: (rowData) => rowData.category === 'Preparation',
    },
    {
        id: 'category=Execution',
        label: <Trans>Execution</Trans>,
        predicate: (rowData) => rowData.category === 'Execution',
    },
    {
        id: 'category=PostProcessing',
        label: <Trans>Post processing</Trans>,
        predicate: (rowData) => rowData.category === 'PostProcessing',
    },
];

const categoryColumn: Column<RowWithCategory> = {
    label: <Trans>Category</Trans>,
    id: 'category',
    render: ({ data: rowData }: Row<RowWithCategory>) => (
        <TextCell firstRow={transEnum(rowData.category, categorySelectionPublicTranslations)} />
    ),
    width: columnWidth.small,
    filters: categoryFilters,
};

type ColumnWidth = 'extraLarge' | 'large' | 'medium' | 'small' | 'extraSmall';

export const useSiteColumn = <TRowData extends {}>(
    extractor: (rowData: TRowData) => { siteIds: string[] },
    width?: ColumnWidth,
): Column<TRowData> => {
    const { data: sites = [] } = useSites();

    const searchBy = (row: TRowData): string => {
        const { siteIds } = extractor(row);
        return siteIds
            .map((siteId) => sites.find((s) => s.id === siteId)?.name ?? '')
            .sort(compareByString)
            .join(', ');
    };

    return {
        label: <Trans>Site</Trans>,
        id: 'site',
        render: ({ data: rowData }: Row<TRowData>): JSX.Element => {
            const { siteIds } = extractor(rowData);
            return <TextCell firstRow={<SitesWithIconFetching sites={siteIds} />} />;
        },
        searchable: { searchBy },
        width: width ? columnWidth[width] : columnWidth.medium,
    };
};

export { categoryColumn, descriptionColumn, internalNumberColumn, nameColumn, processColumn, statusColumn };
