import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { Flexbox, FlexboxProps } from '../Flexbox';

const Container = styled(Flexbox)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const Card = (props: FlexboxProps): JSX.Element => (
    <Container
        flexDirection="column"
        border={`1px solid ${colorSystem.neutral[2]}`}
        padding={'32px 24px'}
        borderRadius={'8px'}
        gap={32}
        maxWidth={400}
        {...props}
    />
);
