import { Trans } from '@lingui/macro';
import { Chip, colorSystem, Flexbox, MenuButton, Text } from '@luminovo/design-system';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CalculationTableForm } from '../types/formTypes';

function costLabel(count: number) {
    return (
        <Flexbox gap="8px" justifyContent="center" alignItems="center">
            <Text variant="h5" color={colorSystem.neutral[7]}>
                <Trans>Cost options</Trans>
            </Text>
            <Chip label={count.toString()} color="neutral" />
        </Flexbox>
    );
}

export const CostOptionsMenu = ({ children }: { children?: React.ReactNode }): JSX.Element => {
    const { watch } = useFormContext<CalculationTableForm>();
    const count = [
        watch('calculationDetails.includeExcessMaterialInMaterialCosts'),
        watch('calculationDetails.isProjectCostsSeparateFromManufacturingCost'),
    ].filter((value) => value === true).length;
    return (
        <MenuButton label={costLabel(count)} appearance="secondary" size="medium" icon={<ExpandMore />}>
            {children}
        </MenuButton>
    );
};
