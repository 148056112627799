import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useInformationAndConfirmationDialogs } from '../../../Manufacturing/shared/manufacturingUtils';
import { CalculationTableForm } from '../../CalculationTable/types/formTypes';
import { CalculationFrozenText } from '../../CalculationTable/utils/calculationFrozenText';
import { CalculationAssemblyTemplateFormInput } from './SwitchOrApplyCalculationAssemblyTemplateDialog';

export const ResetCalculationAssemblyTemplateDialog = ({
    onSubmit,
    isTemplateEdited,
    hasFormChanged,
}: {
    onSubmit: (form: CalculationAssemblyTemplateFormInput) => Promise<void>;
    isTemplateEdited: boolean;
    hasFormChanged: boolean;
}) => {
    const { reset, control } = useFormContext<CalculationTableForm>();
    const isCalculationFrozen = useWatch({
        control,
        name: 'isCalculationFrozen',
    });

    const { openDialog, closeDialog, isConfirmationDialogOpen } = useInformationAndConfirmationDialogs(false);

    const handleReset = async () => {
        await onSubmit({ calculationTemplateId: undefined });
        reset();
    };

    if (isCalculationFrozen) {
        return (
            <Tooltip title={<CalculationFrozenText />}>
                {/* span required because button could be disabled 
                https://mui.com/material-ui/react-tooltip/#disabled-elements
                */}
                <span>
                    <SecondaryButton size="medium" disabled={true}>
                        <Trans>Reset to template</Trans>
                    </SecondaryButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <>
            <ConfirmationDialogBox
                text={t`Are you sure you want to reset results? This action cannot be undone.`}
                title={t`Reset to template`}
                onConfirm={handleReset}
                onReject={closeDialog}
                isDialogOpen={isConfirmationDialogOpen}
                deleteText={t`Yes, reset and reload`}
            >
                {isTemplateEdited && (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        bgcolor={colorSystem.red[1]}
                        border={`1px solid ${colorSystem.red[2]}`}
                        borderRadius="8px"
                        marginY={'8px'}
                        padding={'16px'}
                    >
                        <Flexbox gap={'4px'} alignItems={'center'}>
                            <InfoIcon color={'error'} fontSize={'small'} />
                            <Text color={colorSystem.neutral[8]} style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                <Trans>Changes were made to this template</Trans>
                            </Text>
                        </Flexbox>
                        <Text style={{ fontSize: '12px' }}>
                            <Trans>Resetting results will apply the updated template to this calculation.</Trans>
                        </Text>
                    </Box>
                )}
            </ConfirmationDialogBox>

            <SecondaryButton
                onClick={openDialog}
                size="medium"
                disabled={isTemplateEdited === false && hasFormChanged === false}
            >
                <Trans>Reset to template</Trans>
            </SecondaryButton>
        </>
    );
};
