import { isEqual } from '@luminovo/commons';
import { isAssemblyTableData, ModuleTableData } from '../../../Bom/components/ModuleTableData';
import { Direction } from '../../useKeyUpDown';

export function getNextSelectedBomItemId({
    direction,
    selectedModuleId,
    filteredModules,
}: {
    direction: Direction;
    selectedModuleId: string[] | undefined;
    filteredModules: ModuleTableData[];
}) {
    if (!selectedModuleId) {
        switch (direction) {
            case 'ArrowDown':
                return filteredModules[0].id;
            case 'ArrowUp':
                return filteredModules[filteredModules.length - 1].id;
            default:
                return undefined;
        }
    }

    const currentIndex = selectedModuleId
        ? filteredModules.findIndex((data) => {
              return isAssemblyTableData(data) ? data.id === selectedModuleId[0] : isEqual(data.id, selectedModuleId);
          })
        : 0;

    const positionDelta = direction === 'ArrowDown' ? 1 : direction === 'ArrowUp' ? -1 : 0;

    const upperBound = Math.min(currentIndex + positionDelta, filteredModules.length);

    const nextIndex = Math.max(0, upperBound);

    const nextBomItem = filteredModules[nextIndex];

    if (!nextBomItem) {
        return selectedModuleId;
    }

    return nextBomItem.id;
}
