import { BaseParser, ParseResult, Parser } from '../parsers/Parser';

// Overloads
export function sequence<T1>(p1: Parser<T1>): Parser<[T1]>;
export function sequence<T1, T2>(p1: Parser<T1>, p2: Parser<T2>): Parser<[T1, T2]>;
export function sequence<T1, T2, T3>(p1: Parser<T1>, p2: Parser<T2>, p3: Parser<T3>): Parser<[T1, T2, T3]>;
export function sequence<T1, T2, T3, T4>(
    p1: Parser<T1>,
    p2: Parser<T2>,
    p3: Parser<T3>,
    p4: Parser<T4>,
): Parser<[T1, T2, T3, T4]>;
// Implementation signature
/**
 * A parser that runs a sequence of parsers and returns an array of their results.
 *
 * If any of the parsers fails, the sequence parser will fail as well.
 */
export function sequence(...parsers: Parser<unknown>[]): Parser<unknown> {
    function parseSequence(input: string): ParseResult<unknown[]> {
        let result: unknown[] = [];
        let rest = input;
        for (const parser of parsers) {
            const parseResult = parser.parse(rest);
            if (!parseResult.ok) {
                return parseResult;
            }
            result.push(parseResult.value);
            rest = parseResult.rest;
        }
        return { ok: true, value: result, rest };
    }

    return BaseParser.of<unknown[]>(parseSequence);
}
