const INTERCOM_BASE_URL = 'https://help.luminovo.com';

/**
 * Intercom automatically determines the right language in the help center
 * based on the user's language, so we don't need to pass en or de in the URL
 *
 * @param articleId should be only an integer, so that it does not break when the article title changes
 */
export const getIntercomArticleURL = (articleId: number): string => {
    return INTERCOM_BASE_URL + `/articles/${articleId}`;
};
