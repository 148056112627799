import { t, Trans } from '@lingui/macro';
import { Protected, useHasPermission } from '@luminovo/auth';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { CustomerLabel, extractBusinessCustomerData } from '@luminovo/manufacturing-core';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { PageLayout } from '../../../components/PageLayout';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { translateCountry } from '../../../resources/organizationSettings/countryi18n';
import { route } from '../../../utils/routes';
import { CustomerUsersTableWrapper } from '../../Customers/CustomerUserManagement';
import { ButtonInviteUsers } from '../../Customers/CustomerUserManagement/ButtonInviteUsers';
import { ReadOnlyAccessAlert } from '../OrganizationSettings/common/SettingsContainer';
import { BackButton } from './BackButton';
import { ButtonEditBillingAddress } from './ButtonEditBillingAddress';

const AdditionalId = ({ label, value }: { label: string; value: string | undefined }): JSX.Element => {
    return (
        <Flexbox flexDirection="row">
            <Text variant="h4" color={colorSystem.neutral[6]}>
                {label}:&nbsp;
            </Text>
            <Text variant="body" color={colorSystem.neutral[9]}>
                {value ?? '-'}
            </Text>
        </Flexbox>
    );
};

const CompanySettingsManagement = ({ customer }: { customer: CustomerDTO }): JSX.Element => {
    const { data: selfSignupSettings, isLoading } = useHttpQuery('GET /organization-settings/self-signup', {});
    const isCustomerUserManagementEnabled = selfSignupSettings?.is_customer_user_management_enabled ?? false;
    const data = extractBusinessCustomerData(customer.customer_type);

    const isAllowedToEdit = useHasPermission(['edit:user:customer']);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Flexbox flexDirection="column">
                <Flexbox flexDirection="column" gap="12px">
                    <CustomerLabel
                        customerName={customer.name}
                        customerNumber={customer.customer_number ?? undefined}
                        variant="h3"
                    />

                    {customer.customer_type.type !== 'Private' && (
                        <Flexbox flexDirection="row" gap="16px">
                            <AdditionalId label={t`VAT`} value={data?.vatId} />
                            <AdditionalId
                                label={t`Commercial register number`}
                                value={data?.commercialRegisterNumber}
                            />
                        </Flexbox>
                    )}
                </Flexbox>

                <Divider style={{ margin: '32px 0' }} />

                <Flexbox alignItems="start" style={{ gap: 32 }}>
                    <Flexbox flexDirection={'column'} alignItems="baseline" style={{ gap: 8 }}>
                        <Typography display="inline" variant="h4" style={{ color: colorSystem.neutral[8] }}>
                            <Trans>Billing address</Trans>
                        </Typography>
                        {customer.billing_address?.line1 && (
                            <Typography display="inline" variant="body1" style={{ color: colorSystem.neutral[8] }}>
                                {customer.billing_address.line1}
                            </Typography>
                        )}
                        {customer.billing_address?.line2 && (
                            <Typography display="inline" variant="body1" style={{ color: colorSystem.neutral[8] }}>
                                {customer.billing_address.line2}
                            </Typography>
                        )}
                        {customer.billing_address?.city && (
                            <Typography display="inline" variant="body1" style={{ color: colorSystem.neutral[8] }}>
                                {customer.billing_address.postal_code !== null
                                    ? `${customer.billing_address.postal_code}, `
                                    : ''}
                                {customer.billing_address.city}
                            </Typography>
                        )}
                        {customer.billing_address?.country_code && (
                            <Typography display="inline" variant="body1" style={{ color: colorSystem.neutral[8] }}>
                                {translateCountry(customer.billing_address?.country_code) || ''}
                            </Typography>
                        )}
                    </Flexbox>

                    <Protected requiredPermissions={['edit:customer:billing_address']}>
                        <ButtonEditBillingAddress customer={customer} />
                    </Protected>
                </Flexbox>

                {isCustomerUserManagementEnabled && (
                    <>
                        <Divider style={{ margin: '32px 0' }} />

                        <Flexbox flexDirection="column" gap="20px">
                            {!isAllowedToEdit && <ReadOnlyAccessAlert />}

                            <Flexbox flexDirection="row" justifyContent="space-between">
                                <Flexbox flexDirection="column" gap="4px">
                                    <Text variant="h3" color={colorSystem.neutral[8]} style={{ flex: 1 }}>
                                        <Trans>Users</Trans>
                                    </Text>
                                    <Text variant="body-small" color={colorSystem.neutral[9]}>
                                        <Trans>
                                            Invite other colleagues in your company to the portal to collaborate
                                            together.
                                        </Trans>
                                    </Text>
                                </Flexbox>
                                <ButtonInviteUsers customer={customer} disabled={!isAllowedToEdit} />
                            </Flexbox>
                            <Box style={{ overflowX: 'auto' }}>
                                <CustomerUsersTableWrapper customer={customer} isAllowedToEdit={isAllowedToEdit} />
                            </Box>
                        </Flexbox>
                    </>
                )}
            </Flexbox>
        </>
    );
};

export const CompanySettingsPage = (): JSX.Element | null => {
    const context = useCurrentUserDetailsContext();

    const history = useHistory();

    if (context.customer === null) {
        history.goBack();
        return null;
    }

    return (
        <PageLayout layout="full-width" paddingTop="48px">
            <Flexbox justifyContent={'center'} gap="32px">
                <Flexbox flexGrow={1} justifyContent="end">
                    <BackButton href={route('/rfqs')} variant="button" />
                </Flexbox>
                <Flexbox gap="48px" maxWidth="1400px" width="100%" flexDirection={'column'}>
                    <Text variant="h1" style={{ paddingTop: '4px' }}>
                        <Trans> Company Settings</Trans>
                    </Text>

                    <CompanySettingsManagement customer={context.customer} />
                </Flexbox>
                <Flexbox flexGrow={1} />
            </Flexbox>
        </PageLayout>
    );
};
