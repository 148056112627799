import { t, Trans } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, isEqual, isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import { MonetaryValueBackend, SolutionTag } from '@luminovo/http-client';
import { ExpandMore, InfoRounded } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import React from 'react';
import {
    extractAdjustedLandedUnitPrice,
    extractAdjustedUnitPrice,
    extractOneTimeCosts,
    hasAdditionalCost,
} from '../../../../extractors';
import { Solution } from '../../../../types';

function extractRequiredQuantity(solution: Solution) {
    return solution.firstPurchaseOption.quantity - (extractExcessMaterialQuantity(solution) || 0);
}

function extractExcessMaterialQuantity(solution: Solution) {
    const ExcessTag = solution.solutionTags.find((sol) => sol.tag === SolutionTag.Excess);
    if (ExcessTag?.tag !== SolutionTag.Excess) {
        return undefined;
    }
    // the `ExcessTag.quanity` is not normalized to the unit of measurement of the offer
    return ExcessTag.content.quantity / solution.firstPurchaseOption.unit.quantity;
}

function extractSubtotal(solution: Solution): MonetaryValueBackend | undefined {
    if (!isPresent(solution.firstPurchaseOption.unit_price_original)) {
        return undefined;
    }

    const { amount: unitPriceAmount, currency } = solution.firstPurchaseOption.unit_price_original;
    const totalQuantity = solution.firstPurchaseOption.quantity;
    const subtotalAmount = Number(unitPriceAmount) * totalQuantity;

    return { amount: String(subtotalAmount), currency };
}

function extractExchangeRate(solution: Solution) {
    const convertedTag = solution.solutionTags.find((sol) => sol.tag === SolutionTag.Converted);
    if (convertedTag?.tag !== SolutionTag.Converted) {
        return undefined;
    }

    return {
        originalCurrency: convertedTag.content.original_currency,
        preferredCurrency: convertedTag.content.preferred_currency,
        exchangeRate: 1 / Number(convertedTag.content.exchange_rate),
    };
}

const CostItem: React.FunctionComponent<{
    label: string;
    value: string;
    valuePrefix?: 'x' | '+' | '(';
    valueSuffix?: ')';
    labelChip?: string;
    tooltip?: string;
}> = ({ label, value, valuePrefix, valueSuffix, labelChip, tooltip }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'}>
            <Flexbox gap={4}>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {label}
                </Text>
                {labelChip && <Chip color={'neutral'} label={labelChip} />}
                {isPresent(tooltip) ? (
                    <Tooltip title={tooltip}>
                        <InfoRounded
                            style={{
                                cursor: 'pointer',
                                color: colorSystem.neutral[5],
                                fontSize: '16px',
                            }}
                        />
                    </Tooltip>
                ) : null}
            </Flexbox>

            <Flexbox gap={4}>
                <Text variant="body" color={colorSystem.neutral[6]}>
                    {valuePrefix}
                </Text>
                <Text variant="body" color={colorSystem.neutral[9]}>
                    {value}
                </Text>
                <Text variant="body" color={colorSystem.neutral[6]}>
                    {valueSuffix}
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

export const OfferCostBreakdown: React.FunctionComponent<{
    solution: Solution;
    isAlwaysExpanded?: boolean;
    showEffectiveTotalPrice?: boolean;
    isTotalCostOfOwnershipEnabled: boolean;
}> = ({
    solution,
    isAlwaysExpanded = true,
    showEffectiveTotalPrice = false,
    isTotalCostOfOwnershipEnabled = false,
}): JSX.Element => {
    const requiredQuantity = extractRequiredQuantity(solution);
    const excessMaterial = extractExcessMaterialQuantity(solution);
    const exchangeRateContent = extractExchangeRate(solution);
    const subtotal = extractSubtotal(solution);
    const oneTimeCosts = extractOneTimeCosts({ solution });
    const manualOneTimeCosts = showEffectiveTotalPrice
        ? extractOneTimeCosts({ solution, includeManualCosts: true })
        : [];
    const adjustedUnitPrice = extractAdjustedUnitPrice({ solution });

    const totalPrice = showEffectiveTotalPrice
        ? solution.totalPrice?.effective_total_price
        : solution.totalPrice?.original_total_price;

    return (
        <Flexbox flexDirection={'column'}>
            <Expandable isAlwaysExpanded={isAlwaysExpanded}>
                <Flexbox
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    padding={'12px 16px 8px'}
                    gap={12}
                    bgcolor={colorSystem.neutral[0]}
                    style={isAlwaysExpanded ? { borderTop: `1px solid ${colorSystem.neutral[2]}` } : {}}
                >
                    <CostItem label={t`Required quantity`} value={formatDecimal(requiredQuantity)} />

                    {isPresent(excessMaterial) && (
                        <CostItem
                            label={t`Excess material quantity`}
                            valuePrefix={'+'}
                            value={formatDecimal(excessMaterial)}
                        />
                    )}

                    {isPresent(solution.firstPurchaseOption.unit_price_original) && (
                        <CostItem
                            label={t`Unit price`}
                            valuePrefix={'x'}
                            value={formatMonetaryValue(solution.firstPurchaseOption.unit_price_original, 'unit-price')}
                        />
                    )}
                    {isPresent(solution.firstPurchaseOption.unit_price_original) &&
                        !isEqual(adjustedUnitPrice, solution.firstPurchaseOption.unit_price_original) && (
                            <CostItem
                                label={t`Adjusted unit price`}
                                valuePrefix={'('}
                                valueSuffix={')'}
                                value={formatMonetaryValue(adjustedUnitPrice, 'unit-price')}
                                tooltip={t`The unit price is adjusted to account for the unit of measurement`}
                            />
                        )}
                    {isPresent(solution.additionalCost?.per_unit.cost) &&
                        hasAdditionalCost(solution) &&
                        isTotalCostOfOwnershipEnabled && (
                            <CostItem
                                label={t`Additional cost`}
                                valuePrefix={'+'}
                                value={formatMonetaryValue(solution.additionalCost.per_unit.cost, 'unit-price')}
                            />
                        )}
                    {isPresent(solution.landedUnitPrice) &&
                        hasAdditionalCost(solution) &&
                        isTotalCostOfOwnershipEnabled && (
                            <CostItem
                                label={t`Landed unit price`}
                                value={formatMonetaryValue(extractAdjustedLandedUnitPrice({ solution }), 'unit-price')}
                                tooltip={t`The landed unit price includes additional costs`}
                            />
                        )}
                </Flexbox>

                {(exchangeRateContent || oneTimeCosts.length > 0 || manualOneTimeCosts.length > 0) && (
                    <Flexbox
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        padding={'4px 16px 8px'}
                        gap={12}
                        bgcolor={colorSystem.neutral[0]}
                    >
                        <span style={{ borderTop: `1px solid ${colorSystem.neutral[2]}` }} />
                        {subtotal && <CostItem label={''} value={formatMonetaryValue(subtotal)} />}

                        {oneTimeCosts.map((oneTimeCost, idx) => (
                            <CostItem
                                key={idx}
                                label={t`One-time costs`}
                                labelChip={oneTimeCost.describe}
                                valuePrefix={'+'}
                                value={formatMonetaryValue(oneTimeCost.price, 'default')}
                            />
                        ))}

                        {manualOneTimeCosts.map((oneTimeCost, idx) => (
                            <CostItem
                                key={idx}
                                label={t`Manual one-time costs`}
                                labelChip={oneTimeCost.describe}
                                valuePrefix={'+'}
                                value={formatMonetaryValue(oneTimeCost.price, 'default')}
                            />
                        ))}

                        {exchangeRateContent && (
                            <CostItem
                                label={t`Currency`}
                                labelChip={`${exchangeRateContent.originalCurrency} > ${exchangeRateContent.preferredCurrency}`}
                                valuePrefix={'x'}
                                value={formatDecimal(exchangeRateContent.exchangeRate)}
                            />
                        )}
                    </Flexbox>
                )}
            </Expandable>
            <Flexbox
                justifyContent={'space-between'}
                padding={'16px'}
                paddingRight={'20px'}
                bgcolor={colorSystem.primary[1]}
                style={{ borderTop: `1px solid ${colorSystem.primary[2]}` }}
            >
                <Text variant="h4" color={colorSystem.primary[7]}>
                    <Trans>Total price</Trans>
                </Text>
                <Text variant="h3" color={colorSystem.primary[7]}>
                    {formatMonetaryValue(totalPrice)}
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

function Expandable({
    children,
    isAlwaysExpanded: alwaysExpanded,
}: {
    children: React.ReactNode;
    isAlwaysExpanded?: boolean;
}) {
    const [isExpanded, setExpanded] = React.useState(alwaysExpanded);

    if (alwaysExpanded) {
        return <>{children}</>;
    }

    return (
        <>
            <TertiaryButton
                onClick={() => setExpanded((x) => !x)}
                style={{
                    background: colorSystem.neutral[0],
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    paddingRight: 24,
                }}
                endIcon={
                    <ExpandMore
                        style={{
                            transform: isExpanded ? `rotate(-90deg)` : `rotate(0deg)`,
                            transition: 'transform 0.1s linear',
                        }}
                    />
                }
            >
                <Trans>View cost breakdown</Trans>
            </TertiaryButton>

            <Collapse
                style={{ background: colorSystem.neutral[0], paddingRight: 4 }}
                in={isExpanded}
                timeout="auto"
                unmountOnExit
            >
                {children}
            </Collapse>
        </>
    );
}
