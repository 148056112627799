import { Currency, LexoRank } from '@luminovo/commons';
import {
    AttributeReference,
    AutomaticDriverAggregationDTO,
    ConditionValues,
    DriverDetailsType,
    EnumFilterOperator,
    NumberFilterOperator,
    OfferTypePublic,
    PackageMountingEnum,
    Packaging,
    PartTypePublic,
    PnpSideDTO,
} from '@luminovo/http-client';

export function isDriverFilterValid(driverFilter: DriverFilter[]): driverFilter is DriverFilter[] {
    if (driverFilter !== undefined && driverFilter.length === 0) {
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (driverFilter as DriverFilter[]).map((f) => f.filter !== undefined).includes(true);
}

export type DriverDetailsInput =
    | {
          type: DriverDetailsType.Manual;
      }
    | {
          type: DriverDetailsType.Automatic;
          aggregation: AutomaticDriverAggregationDTO;
          filterFormula: DriverFilter[];
      };

export type AddDriverFormInputs = {
    name: string;
    isPerPanel: boolean;
    driverDetails: DriverDetailsInput;
    notes: string | undefined;
    lexoRank: LexoRank;
};

export type PackageValue = {
    mounting: PackageMountingEnum;
    packages: string[];
};

type Filter<TAttribute extends AttributeReference, TOperator, TValue> = {
    type: TAttribute;
    operator: TOperator;
    value: TValue;
};
type EnumFilter<TAttribute extends AttributeReference, TValue> = Filter<TAttribute, EnumOperator, TValue>;

export type NumberOperator = {
    type: 'number';
    value: NumberFilterOperator;
};

export type EnumOperator = {
    type: 'enum';
    value: EnumFilterOperator;
};

export type PackageFilter = EnumFilter<'Package', PackageValue>;
type CustomPartTypeFilter = EnumFilter<'CustomPartType', PartTypePublic>;
type OfferFilter = EnumFilter<'OfferType', OfferTypePublic>;
type MaterialPriceFilter = Filter<'MaterialPrice', NumberOperator, { currency: Currency; amount: number }>;
type SidesOfPlacementFilter = EnumFilter<'PnpSide', PnpSideDTO>;
type NumberOfPinsFilter = Filter<'PartNumberOfPins', NumberOperator, number>;
type NumberOfApprovedPartOptionsWithIpnsFilter = Filter<'NumberOfApprovedPartOptionsWithIpns', NumberOperator, number>;
type PackagingFilter = EnumFilter<'Packaging', Packaging>;

export type FilterInput =
    | PackageFilter
    | CustomPartTypeFilter
    | OfferFilter
    | MaterialPriceFilter
    | SidesOfPlacementFilter
    | NumberOfPinsFilter
    | PackagingFilter
    | NumberOfApprovedPartOptionsWithIpnsFilter;

export type DriverFilter = {
    joinWithPrevious: ConditionValues;
    filter: FilterInput;
};

export type PackageOptions = Record<PackageMountingEnum, string[]>;
