import jwtDecode, { JwtPayload } from 'jwt-decode';
import * as React from 'react';
import { Permission } from '../permissions';
import { getToken } from '../token';

interface JwtPayloadWithPermissions extends JwtPayload {
    permissions: Permission[];
}

const extractPermissionsFromToken = (token: string): Permission[] => {
    try {
        const decoded = jwtDecode<JwtPayloadWithPermissions>(token);
        return decoded.permissions;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to decode the token. Will assume the user has no permissions.', e);
        return [];
    }
};

export interface UsePermissionsResult {
    permissions: Permission[];
}

/**
 * Returns all the permissions extracted from the token.
 *
 * This is essentially a list of all the actions that the user is allowed to make
 * in Luminovo
 */
export function usePermissions(): UsePermissionsResult {
    const token = getToken();
    return React.useMemo((): UsePermissionsResult => {
        return { permissions: extractPermissionsFromToken(token) };
    }, [token]);
}
