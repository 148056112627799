import { http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { useToken } from '../hooks';
export const useMutationValidateFormula = (formula: string) => {
    const { token } = useToken();
    return useMutation({
        mutationFn: () => {
            return http('POST /formula/validate', { requestBody: { formula } }, token);
        },
        onSuccess: async (data) => {
            if (data.result === 'Ok') {
                return 'Ok';
            } else {
                return 'Error';
            }
        },
        onError: async (_) => {
            return 'Error';
        },
    });
};

export const useAdvancedFormulaTestRun = (formula: string) => {
    const { token } = useToken();
    return useMutation({
        mutationFn: (inputs: Record<string, string>) => {
            return http('POST /formula/test', { requestBody: { inputs, formula } }, token);
        },
    });
};
