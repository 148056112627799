import { Parser } from '../parsers/Parser';
import { NumberProps, number } from '../parsers/number';

/**
 * A parser that parses an amount in stock.
 */
export function stock({ decimalSeparator }: Pick<NumberProps, 'decimalSeparator'>): Parser<number> {
    return number({
        decimalSeparator,
        warnIf: {
            // stock can be negative, but it's unusual
            isNegative: true,
            // stock can be a float, but it's unusual
            isFloat: true,
        },
    });
}
