import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';

export enum PartSpecificationTypes {
    OffTheShelf = 'OffTheShelf',
    Custom = 'Custom',
}

export const partSpecificationTypesTranslations: Record<PartSpecificationTypes, MessageDescriptor> = {
    [PartSpecificationTypes.OffTheShelf]: defineMessage({ message: 'Off-the-shelf' }),
    [PartSpecificationTypes.Custom]: defineMessage({ message: 'Custom' }),
};
