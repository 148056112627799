import { compareByStringKey } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { FullSourcingDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { useRfQ } from '../rfq/rfqHandler';
import { getTotalQuantity } from './getTotalQuantity';

export function useSourcingScenario(sourcingScenarioId: string | undefined) {
    return useBulkSingleQuery('POST /sourcing-scenarios/bulk', sourcingScenarioId, {
        idExtractor: (item: SourcingScenarioDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
}

export function useSourcingScenarios(sourcingScenarioIds: string[] | undefined) {
    return useBulkQuery(
        'POST /sourcing-scenarios/bulk',
        sourcingScenarioIds,
        {
            idExtractor: (item: SourcingScenarioDTO) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids } };
            },
            select: (res) => res.items,
        },
        {
            sortBy: chainComparators(
                compareByNumber((x) => -getTotalQuantity(x)),
                compareByStringKey((item) => item.name),
            ),
        },
    );
}

export function useSourcingFull(sourcingScenarioId: string | undefined) {
    return useBulkSingleQuery('POST /sourcing-scenarios/sourcing-full/bulk', sourcingScenarioId, {
        idExtractor: (item: FullSourcingDTO) => item.sourcing_scenario_id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
}

export function useSourcingFullBulk(sourcingScenarioIds: string[] | undefined) {
    return useBulkQuery(
        'POST /sourcing-scenarios/sourcing-full/bulk',
        sourcingScenarioIds,
        {
            idExtractor: (item: FullSourcingDTO) => item.sourcing_scenario_id,
            httpOptions: (ids) => {
                return { requestBody: { ids } };
            },
            select: (res) => res.items,
        },
        {
            sortBy: compareByStringKey((s) => s.sourcing_scenario_id),
        },
    );
}

export const useSourcingScenariosOfRfq = (rfqId?: string) => {
    const { data: rfq } = useRfQ(rfqId ?? '', Boolean(rfqId));
    const sourcingScenarioIds = rfq?.sourcing_scenarios ?? [];
    return useSourcingScenarios(sourcingScenarioIds);
};
