import { ScopeContext } from '@sentry/types';
import { isProductionEnvironment } from './isProductionEnvironment';
import { logToExternalErrorHandlers } from './logToExternalErrorHandlers';

export const throwErrorUnlessProduction = (error: unknown, captureOptions?: Partial<ScopeContext>): void => {
    if (!isProductionEnvironment()) {
        /* eslint-disable-next-line no-console */
        console.error({ error, captureOptions });
        alert(error);
        throw error;
    }
    if (isProductionEnvironment()) {
        logToExternalErrorHandlers(new Error(String(error)), captureOptions);
    }
};
