import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { ErrorOutline, HighlightOffRounded, InfoOutlined } from '@mui/icons-material';

type Status = 'info' | 'warning' | 'error';

const StatusIcon = ({ variant }: { variant: Status }): JSX.Element => {
    const iconVariant = variant;
    switch (iconVariant) {
        case 'info':
            return <InfoOutlined style={{ color: colorSystem.neutral[6] }} />;
        case 'warning':
            return <ErrorOutline style={{ color: colorSystem.yellow[6] }} />;
        case 'error':
            return <HighlightOffRounded style={{ color: colorSystem.red[6] }} />;
        default:
            assertUnreachable(iconVariant);
    }
};

export const StatusMessage = ({ variant, text }: { variant: Status; text: string }): JSX.Element => {
    return (
        <Flexbox alignItems={'center'} gap={8}>
            <StatusIcon variant={variant} />
            <Text>{text}</Text>
        </Flexbox>
    );
};
