import { t } from '@lingui/macro';
import type { ParseResult, Parser } from '../types';

export interface ParseStringOptions {
    trim: boolean;
    mustMatch?: RegExp;
    minLength?: number;
}

export const parseString: Parser<string, ParseStringOptions> = async function (
    [value]: string[],
    opts: ParseStringOptions,
    field,
): Promise<ParseResult<string>> {
    if (opts.trim) {
        value = value.trim();
    }

    if (value.length === 0 && field.defaultValue) {
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Default value used`,
        };
    }

    if (opts.mustMatch && !opts.mustMatch.test(value)) {
        return {
            status: 'error',
            message: `Value does not match`,
        };
    }
    if (opts.minLength !== undefined && value.length < opts.minLength) {
        return {
            status: 'error',
            message: `Value is too short`,
        };
    }

    return {
        status: 'done',
        value: { id: value, label: value },
    };
};
