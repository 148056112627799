import { t } from '@lingui/macro';
import { AlternateEmail, ArrowUpward } from '@mui/icons-material';
import { Box, IconButtonProps, styled } from '@mui/material';
import React from 'react';
import { usePersistedState } from '../../hooks/usePersistedState';
import { colorSystem } from '../../theme';
import { AvatarIcon } from '../AvatarIcon';
import { Flexbox } from '../Flexbox';
import { Tooltip } from '../Tooltip';
import { PrimaryIconButton, TertiaryIconButton } from '../buttons';
import { CommentItem } from './components/CommentItem';
import { CommentsInput } from './components/CommentsInput';
import { Comment, CommentContent, MentionComponent } from './types';

const MAX_INPUT_LENGTH = 600 - 10;

const noop = () => {};

interface User {
    id: string;
}
export interface CommentsProps<TUser extends User> {
    /**
     * A unique key that will be used to persist and load the state of the
     * input so that users don't lose "drafts"
     */
    storageKey: string;
    comments: Comment[];
    onSubmitNewComment?: (comment: CommentContent) => void;
    onEdit?: (comment: Comment) => void;
    onDelete?: (comment: Comment) => void;
    users: TUser[];
    currentUser: TUser;
    formatMention: (user: TUser) => string;
    formatUser: (user: TUser) => { firstName: string; lastName: string; email: string };
    formatOrg: (user: TUser) => string;
    overrides?: {
        Container?: React.ComponentType;
        Mention?: React.ComponentType<{ id: string; label: string }>;
    };
}

export function Comments<TUser extends User>(props: CommentsProps<TUser>): JSX.Element {
    const {
        currentUser,
        comments,
        onSubmitNewComment = noop,
        onEdit = noop,
        onDelete = noop,
        users,
        formatMention,
        formatUser,
        formatOrg,
        storageKey,
        overrides = {},
    } = props;
    const { Container = DefaultContainer, Mention = DefaultMention } = overrides;
    const [value, onChange] = usePersistedState<string>(storageKey, '', sessionStorage);

    const handleSubmitComment = React.useCallback(() => {
        onSubmitNewComment({ type: 'string.v1', content: value.trim() });
        onChange('');
    }, [onChange, value, onSubmitNewComment]);

    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleKeyUp: React.KeyboardEventHandler = React.useCallback(
        (event) => {
            if (event.key === 'Enter' && event.ctrlKey && isValid(value)) {
                handleSubmitComment();
            }
        },
        [handleSubmitComment, value],
    );

    const handleReply = React.useCallback(
        (comment: Comment) => {
            onChange(
                comment.content.content
                    .split('\n')
                    .map((line) => '>' + line)
                    .join('\n') + '\n\n',
            );
            inputRef.current?.focus();
        },
        [onChange],
    );

    React.useEffect(() => {
        const element = scrollContainerRef.current;
        if (!element) {
            return;
        }
        // Hack: The Storybook snapshot tests don't have a scrollTo method
        'scrollTo' in element && element.scrollTo(0, element.scrollHeight);
    }, [comments]);

    return (
        <Container>
            {comments.length > 0 ? (
                <CommentsContainer ref={scrollContainerRef}>
                    {comments.map((comment) => {
                        return (
                            <CommentItem
                                overrides={{ MentionComponent: Mention }}
                                key={comment.id}
                                comment={comment}
                                onReply={handleReply}
                                onDelete={onDelete}
                                onEdit={onEdit}
                            />
                        );
                    })}
                </CommentsContainer>
            ) : null}
            <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '4px', padding: '4px 8px' }}
                className="input-container"
                style={{
                    boxShadow: comments.length === 0 ? 'none' : undefined,
                    background: getInputContainerBackgroundColor(value),
                }}
            >
                <AvatarIcon size="small" user={formatUser(currentUser)} />
                <CommentsInput
                    inputRef={inputRef}
                    placeholder={t`Add a comment...`}
                    value={value}
                    onChange={(x) => onChange(x.target.value)}
                    formatMention={formatMention}
                    formatOrg={formatOrg}
                    users={users}
                    onKeyUp={handleKeyUp}
                />
                <ButtonMention
                    style={{ visibility: users.length === 0 ? 'hidden' : 'visible' }}
                    onClick={() => {
                        inputRef.current?.focus();
                        onChange(value + ' @');
                    }}
                />
                <ButtonSend disabled={!isValid(value)} onClick={handleSubmitComment} />
            </Box>
        </Container>
    );
}

function isValid(comment: string) {
    return comment.trim().length > 0 && comment.length < MAX_INPUT_LENGTH;
}

function getInputContainerBackgroundColor(comment: string): string | undefined {
    if (comment.length > MAX_INPUT_LENGTH) {
        return colorSystem.red[3];
    }
    if (comment.length > MAX_INPUT_LENGTH - 50) {
        return colorSystem.yellow[2];
    }
    return undefined;
}

const DefaultContainer = styled(Flexbox)({
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 4,
});

const DefaultMention: MentionComponent = (props) => {
    return (
        <span
            style={{
                background: colorSystem.primary[1],
                color: colorSystem.primary[8],
                borderRadius: 4,
                padding: 2,
            }}
        >
            {props.label}
        </span>
    );
};

const ButtonSend = (props: IconButtonProps) => {
    return (
        <Tooltip title={t`Send (Ctrl + Enter)`}>
            <PrimaryIconButton size="medium" style={{ borderRadius: '100%', width: 20, height: 20 }} {...props}>
                <ArrowUpward style={{ fontSize: 14 }} />
            </PrimaryIconButton>
        </Tooltip>
    );
};

const ButtonMention = (props: IconButtonProps) => {
    return (
        <Tooltip title={t`Mention a person`}>
            <TertiaryIconButton size="medium" style={{ borderRadius: '100%', width: 22, height: 22 }} {...props}>
                <AlternateEmail fontSize="inherit" />
            </TertiaryIconButton>
        </Tooltip>
    );
};

const CommentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: 8,
    alignItems: 'flex-start',
    paddingRight: 12,
});
