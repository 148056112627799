import { assertUnreachable } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { HttpError, NetworkError } from '@luminovo/http-client';
import { ResourceNotFound } from '../../modules/Error/NotFoundCard';
import { NotAuthorizedPage } from '../../permissions/NotAuthorizedPage';
import { getTenantId } from '../../permissions/tenants';
import { sentryTracker, useOnCookieConset } from '../../utils/analytics';
import { logToExternalErrorHandlers } from '../../utils/customConsole';
import {
    AuthenticationProblem,
    ErrorCodeProblem,
    NetworkConnectionProblem,
    NewVersionAvailable,
} from './NonIdealStates';
import { SadBox } from './SadBox';

export function ErrorFallback({ error }: { error: unknown }) {
    // Ensure that Sentry is initialized before we call logToExternalErrorHandlers
    const consentedCookies = useOnCookieConset();
    if (consentedCookies.includes(sentryTracker.requiredConsent)) {
        sentryTracker.initialize();
    }

    if (error instanceof NetworkError) {
        return <NetworkConnectionProblem />;
    }

    if (!(error instanceof HttpError)) {
        if (error instanceof Error) {
            logToExternalErrorHandlers(error);
        } else {
            logToExternalErrorHandlers(new Error('Error without error message.'));
        }
        return <SadBox />;
    }

    if (error.code && error.code !== 'unknown') {
        return <ErrorCodeProblem code={error.code} />;
    }
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('error loading dynamically imported module')
    ) {
        return <NewVersionAvailable />;
    }

    if (error.status === 401) {
        return <AuthenticationProblem />;
    }

    if (error.status === 403) {
        return <NotAuthorizedPage />;
    }

    if (error.status === 404) {
        return (
            <CenteredLayout>
                <ResourceNotFound endpoint={error.endpoint} />
            </CenteredLayout>
        );
    }

    if (error instanceof HttpError) {
        const tenantId = getTenantId();
        const fingerprint = [error.method || '', `${error.status}`, error.endpoint, tenantId];
        const options = { tags: { 'tenant-id': tenantId }, fingerprint };
        logToExternalErrorHandlers(error, options);
        return <SadBox />;
    }

    assertUnreachable(error);
}
