import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragStartEvent,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { isPresent } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import React from 'react';
import { CadViewerAction, CadViewerState } from '../utils/cadViewerTypes';
import { CadFileCard, DraggedCadFileCard } from './CadFileCard';

export const CadFilesPanel = ({
    assemblyId,
    pcbId,
    state,
    dispatch,
}: {
    assemblyId: string;
    pcbId: string;
    state: CadViewerState;
    dispatch: React.Dispatch<CadViewerAction>;
}) => {
    const files = state.files;
    const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(KeyboardSensor, {}),
    );

    const currentlyDraggedFile = files.find((item) => {
        return item.id === activeId;
    });

    const getItemIndex = (id: UniqueIdentifier): number => {
        return files.findIndex((item) => {
            return item.id === id;
        });
    };

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (over === null) {
            return;
        }

        const activeId: number = getItemIndex(active.id);
        const overId: number = getItemIndex(over.id);

        if (activeId !== overId) {
            dispatch({ type: 'MOVE_CAD_FILE', payload: { activeId, overId } });
        }

        setActiveId(null);
    };

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    const handleDragCancel = () => {
        setActiveId(null);
    };

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
        >
            <SortableContext id="cad-files" items={files.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                <Flexbox flexDirection={'column'} gap={'8px'}>
                    {files.map((file) => (
                        <CadFileCard
                            isActive={file.id === activeId}
                            pcbId={pcbId}
                            assemblyId={assemblyId}
                            file={file}
                            key={file.id}
                            dispatch={dispatch}
                        />
                    ))}
                </Flexbox>
            </SortableContext>

            {isPresent(currentlyDraggedFile) && <DraggedCadFileCard file={currentlyDraggedFile} />}
        </DndContext>
    );
};
