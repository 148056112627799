import { Currency } from '@luminovo/commons';
import { FormSection } from '@luminovo/design-system';
import { ExpensesCostComponent } from '@luminovo/http-client';
import { Divider, Grid } from '@mui/material';
import React from 'react';
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form';
import Yup from 'yup';
import { FormOverallWrapper } from '../../../components/formLayouts/FormComponents';
import { RecursivePartial, assertUnreachable } from '../../../utils/typingUtils';
import { ManufacturingDatabaseBackAndSave } from '../shared/manufacturingComponents';
import { CostCalculationSection } from './components/CostCalculationSection';
import { ExpenseSection } from './components/ExpenseSection';
import { AddExpenseFormInputs } from './utils/AddExpenseFormTypes';
import { useExpenseFormFunction } from './utils/expenseFormFunctions';

type OnSubmitType = 'PATCH' | 'POST';

export const getExistingExpenseCurrency = (costComponent: ExpensesCostComponent): Currency => {
    const { type } = costComponent;
    switch (type) {
        case 'Fixed':
            return costComponent.details.fixed_cost.currency;
        case 'Linear':
            return costComponent.details.variable_cost.variable_unit.currency;
        case 'Formula':
            return costComponent.details.currency;
        default:
            assertUnreachable(type);
    }
};

function handleSubmitFunction<T extends FieldValues>({
    onSubmitType,
    onSubmitPatch,
    onSubmitPost,
}: {
    onSubmitType: OnSubmitType;
    onSubmitPatch: SubmitHandler<T>;
    onSubmitPost: SubmitHandler<T>;
}) {
    switch (onSubmitType) {
        case 'POST':
            return onSubmitPost;
        case 'PATCH':
            return onSubmitPatch;
        default:
            assertUnreachable(onSubmitType);
    }
}

export const ExpenseForm = ({
    schema,
    defaultValues,
    onSubmitType,
    formTitle,
}: {
    schema: Yup.ObjectSchema;
    defaultValues: RecursivePartial<AddExpenseFormInputs>;
    onSubmitType: OnSubmitType;
    formTitle: string;
}): JSX.Element => {
    const { useFormResult, onSubmitPost, onSubmitPatch } = useExpenseFormFunction(schema, defaultValues);
    const { handleSubmit, formState, trigger } = useFormResult;
    const { isValid, isSubmitting } = formState;

    React.useEffect(() => {
        // in the case of edit, trigger validation on the driverId field, so in the case of an inactive driver this is immediately shown
        if (onSubmitType === 'PATCH') {
            trigger('linearCostCalculation.variableCost.driverId');
        }
    }, [trigger, onSubmitType]);

    return (
        <FormProvider {...useFormResult}>
            <form onSubmit={handleSubmit(handleSubmitFunction({ onSubmitType, onSubmitPatch, onSubmitPost }))}>
                <FormOverallWrapper>
                    <ExpenseSection title={formTitle} />
                    <Divider />
                    <CostCalculationSection />
                    {/* title is blank as FormSection used for spacing */}
                    <FormSection title={''}>
                        <Grid item>
                            <ManufacturingDatabaseBackAndSave
                                isSubmitButtonDisabled={!isValid}
                                isSubmitting={isSubmitting}
                            />
                        </Grid>
                    </FormSection>
                </FormOverallWrapper>
            </form>
        </FormProvider>
    );
};
