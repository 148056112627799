import { plural, Trans } from '@lingui/macro';
import { id, uniq } from '@luminovo/commons';
import { Chip, colorSystem, Column, Flexbox, Row, Text } from '@luminovo/design-system';
import { ManufacturingScenarioOverviewDTO } from '@luminovo/http-client';
import { styled, TableCell } from '@mui/material';
import { iconForSolutionStatusWithTooltip } from '../../../../../components/Icons/icons';
import { LabelScenarioOrderSize } from '../../../../../components/LabelScenarioOrderSize';
import { Status } from '../../../../../resources/frontendTypes';
import { getValueOf } from '../../../../../utils/typingUtils';
import { renderCurrency, TextCell } from '../../../ActivitiesTable/activitiesTableComponents';
import { renderCostContent } from '../../../shared/ManufacturingSharedFunctions';
import { useSiteColumn } from '../../../shared/sharedManufacturingColumns';
import { ActionMenu } from './components/ActionMenu';
const getPluralizedActivity = (items: number) =>
    plural(items ?? 0, {
        one: `Activity`,
        other: `Activities`,
    });

const getPluralizedExpenses = (items: number) =>
    plural(items ?? 0, {
        one: `Expense`,
        other: `Expenses`,
    });

const scenarioColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'scenario',
    label: <Trans>Name</Trans>,
    render: ({ data: rowData }: Row<ManufacturingScenarioOverviewDTO>): JSX.Element => {
        return <TextCell firstRow={rowData.name} />;
    },
};

const batchSizesColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'batchSizes',
    label: <Trans>Batch sizes</Trans>,
    render: ({ data: rowData }) => {
        return (
            <TableCell>
                <Text>{rowData.batch_sizes.join(', ')}</Text>
            </TableCell>
        );
    },
};

const sourcingScenarioColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'sourcingScenario',
    label: <Trans>Sourcing scenario</Trans>,
    render: ({ data: rowData }) => {
        return (
            <TableCell>
                <Text>{rowData.sourcing_scenario ? rowData.sourcing_scenario.name : '-'}</Text>
            </TableCell>
        );
    },
};
const orderSizeColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'orderSize',
    label: <Trans>Order size</Trans>,
    render: ({ data: rowData, index }) => {
        return (
            <TableCell id={index === 0 ? id('manufacturing_scenario/order_size_column') : undefined}>
                <Text>
                    {rowData.sourcing_scenario ? (
                        <LabelScenarioOrderSize sourcingScenario={rowData.sourcing_scenario} />
                    ) : (
                        '-'
                    )}
                </Text>
            </TableCell>
        );
    },
};

const activitiesColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'activities',
    label: <Trans>Activities</Trans>,
    render: ({ data: rowData }: Row<ManufacturingScenarioOverviewDTO>): JSX.Element => {
        const numActivityConfigurations = rowData.activity_count;

        return (
            <TableCell>
                <Chip
                    color="neutral"
                    label={
                        <Text>
                            {numActivityConfigurations} {getPluralizedActivity(numActivityConfigurations)}
                        </Text>
                    }
                />
            </TableCell>
        );
    },
};

const expensesColumn: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'expenses',
    label: <Trans>Expenses</Trans>,
    render: ({ data: rowData }: Row<ManufacturingScenarioOverviewDTO>): JSX.Element => {
        const numExpenseConfigurations = rowData.expense_count;

        return (
            <TableCell>
                <Chip
                    color="neutral"
                    label={
                        <Text>
                            {numExpenseConfigurations} {getPluralizedExpenses(numExpenseConfigurations)}
                        </Text>
                    }
                />
            </TableCell>
        );
    },
};

const effectiveAndProjectCostLabel: Column<ManufacturingScenarioOverviewDTO> = {
    id: 'effectiveAndProjectCost',
    label: '', //intentionally blank
    render: (): JSX.Element => {
        return (
            <TextCell
                firstRow={
                    <Text
                        variant="body-semibold"
                        style={{
                            color: colorSystem.neutral[7],
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Trans>Eff. costs</Trans>
                    </Text>
                }
                secondRow={
                    <Text
                        variant="body-semibold"
                        style={{
                            color: colorSystem.neutral[7],
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Trans>Proj. costs</Trans>
                    </Text>
                }
                gap={0}
            />
        );
    },
};

/* eslint-disable camelcase */
const batchSizeBreakdownColumns = (batchSizes: number[]): Column<ManufacturingScenarioOverviewDTO>[] => {
    return batchSizes.map((batchSize) => ({
        id: `effectiveCostFor${batchSize}`,
        label: <Chip color="neutral" label={batchSize.toString()} />,
        render: ({ data: rowData }): JSX.Element => {
            const batchSizeBreakdown = getValueOf(rowData.costs_overview, batchSize.toString());
            if (batchSizeBreakdown === undefined) {
                return <TextCell firstRow={<Text>-</Text>} secondRow={<Text>-</Text>} gap={0} />;
            } else {
                return (
                    <TextCell
                        firstRow={
                            <Text>{renderCostContent({ converted_mv: batchSizeBreakdown?.effective_cost })}</Text>
                        }
                        secondRow={
                            <Text>
                                {renderCurrency({
                                    monetaryValue: batchSizeBreakdown?.project_cost ?? null,
                                    nullPlaceholder: '-',
                                    shouldAmountBePrecise: false,
                                })}
                            </Text>
                        }
                        gap={0}
                    />
                );
            }
        },
    }));
};

const generateBatchSizeBreakdownColumns = (overviews: ManufacturingScenarioOverviewDTO[]) => {
    if (overviews.length === 0) return [];
    const allBatchSizes: number[] = uniq(overviews.flatMap((overview) => overview.batch_sizes)).sort((a, b) => {
        return a > b ? 1 : -1;
    });
    return batchSizeBreakdownColumns(allBatchSizes);
};

const StickyTableHeadCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    zIndex: 2,
    right: 0,
    color: theme.palette.grey[700],
}));

const StickyTableCell = styled(TableCell)({
    position: 'sticky',
    zIndex: 2,
    right: 0,
    background: colorSystem.neutral.white,
});

const generateStickyStatusColumn = (isTableOverflowing: boolean) => ({
    id: 'statusSummaryColumn',
    label: '', // should be left blank
    renderHead: (): JSX.Element => {
        return (
            <StickyTableHeadCell
                style={{
                    right: '72px', // The width of the action menu component on the DOM
                    boxShadow: isTableOverflowing ? '-10px 0px 17px -5px rgba(0, 0, 0, 0.1)' : 'none',
                }}
            >
                <Text variant="h4">
                    <Trans>Status</Trans>
                </Text>
            </StickyTableHeadCell>
        );
    },
    render: ({ data: rowData }: Row<ManufacturingScenarioOverviewDTO>): JSX.Element => {
        const statusCounts: Array<{ status: Status; count: number }> = [
            {
                status: Status.success,
                count: rowData.driver_status_overview.ok_count,
            },
            {
                status: Status.warning,
                count: rowData.driver_status_overview.warning_count,
            },
            {
                status: Status.error,
                count: rowData.driver_status_overview.error_count,
            },
        ];

        return (
            <StickyTableCell
                style={{
                    right: '72px', // The width of the action menu component on the DOM
                    boxShadow: isTableOverflowing ? '-10px 0px 17px -5px rgba(0, 0, 0, 0.1)' : 'none',
                }}
            >
                <Flexbox gap={16}>
                    {statusCounts.map(({ status, count }) => (
                        <Flexbox key={status} alignItems="center" gap={4}>
                            {iconForSolutionStatusWithTooltip(status)}
                            <Text variant="body">{count}</Text>
                        </Flexbox>
                    ))}
                </Flexbox>
            </StickyTableCell>
        );
    },
});

const generateActionMenuColumn = ({ assemblyId }: { assemblyId: string }): Column<ManufacturingScenarioOverviewDTO> => {
    return {
        id: 'actionMenu',
        label: '', //intentionally blank
        renderHead: (): JSX.Element => {
            return <StickyTableHeadCell />;
        },
        render: ({ data: rowData }: Row<ManufacturingScenarioOverviewDTO>): JSX.Element => {
            return (
                <StickyTableCell>
                    {<ActionMenu manufacturingScenario={rowData} assemblyId={assemblyId} />}
                </StickyTableCell>
            );
        },
    };
};

/* eslint-disable camelcase */
export const useManufacturingAssemblyScenarioTableColumns = (
    assemblyId: string,
    manufacturingScenarios: ManufacturingScenarioOverviewDTO[],
    isTableOverflowing: boolean,
): Column<ManufacturingScenarioOverviewDTO>[] => {
    const batchSizeBreakdownColumns = generateBatchSizeBreakdownColumns(manufacturingScenarios);

    const siteColumn = useSiteColumn(
        (scenario: ManufacturingScenarioOverviewDTO) => ({
            siteIds: scenario.sites,
        }),
        'small',
    );

    return [
        scenarioColumn,
        siteColumn,
        batchSizesColumn,
        sourcingScenarioColumn,
        orderSizeColumn,
        activitiesColumn,
        expensesColumn,
        effectiveAndProjectCostLabel,
        ...batchSizeBreakdownColumns,
        generateStickyStatusColumn(isTableOverflowing),
        generateActionMenuColumn({ assemblyId }),
    ];
};
