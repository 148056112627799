import { FieldArrayPath } from 'react-hook-form';
import { AdditionalCalculationInputControl } from '../../../../../../components/MonacoEditor/editorTypes';
import { CalculationTemplateFormInput } from '../../utils/types';
import { ProfitAndDiscountFields } from '../LockToggle';

const totalMaterialCostFields: FieldArrayPath<CalculationTemplateFormInput>[] = [
    'additionalCosts.otherCosts',
    'additionalCosts.postProfitCosts',
];

const totalManufacturingCostFields: FieldArrayPath<CalculationTemplateFormInput>[] = [
    'additionalCosts.otherCosts',
    'additionalCosts.postProfitCosts',
];
export const generateAdditionalFieldsFromFieldName = (
    fieldName: FieldArrayPath<CalculationTemplateFormInput>,
): AdditionalCalculationInputControl[] => {
    const additionalFields: AdditionalCalculationInputControl[] = [];
    if (totalMaterialCostFields.includes(fieldName)) {
        additionalFields.push('Total material cost');
    }
    if (totalManufacturingCostFields.includes(fieldName)) {
        additionalFields.push('Total manufacturing cost');
    }
    return additionalFields;
};

export const generateAdditionalFieldsFromField = (
    field: ProfitAndDiscountFields,
): AdditionalCalculationInputControl[] => {
    const additionalFields: AdditionalCalculationInputControl[] = ['Total manufacturing cost', 'Total material cost'];
    if (field === 'additionalCosts.discount' || field === 'additionalCosts.profit') {
        additionalFields.push('Total order cost');
    }
    return additionalFields;
};
