import { Trans } from '@lingui/macro';
import { DeselectAllIcon, Flexbox, TertiaryButton } from '@luminovo/design-system';
import { ChecklistRtlRounded } from '@mui/icons-material';
import { Dispatch, SetStateAction } from 'react';

interface SelectAndDeselectButtonsProps {
    setSelectedDesignators: Dispatch<SetStateAction<string[]>>;
    allDesignators: string[];
    noDesignators: string[];
    selectedDesignators: string[];
}

export const SelectAndDeselectButtons = ({
    setSelectedDesignators,
    allDesignators,
    noDesignators,
    selectedDesignators,
}: SelectAndDeselectButtonsProps) => {
    return (
        <Flexbox gap="8px">
            <TertiaryButton
                startIcon={<ChecklistRtlRounded />}
                size="small"
                onClick={() => setSelectedDesignators(allDesignators)}
            >
                <Trans>Select all</Trans>
            </TertiaryButton>
            <TertiaryButton
                startIcon={<DeselectAllIcon />}
                size="small"
                onClick={() => setSelectedDesignators(noDesignators)}
            >
                <Trans>Deselect all</Trans>
            </TertiaryButton>
        </Flexbox>
    );
};
