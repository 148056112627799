import React from 'react';
import { ViewContext } from '../../../../modules/Bom/components/ModuleTableData';
import { UrlParams } from '../../../../utils/routes';
import { EditAssembly } from '../../../rfqs/[rfqId]/bom/assembly/[assemblyId]/edit';

export default function AssemblyOverviewEditPage({
    queryParams,
    pathParams,
}: UrlParams<'/assemblies/:assemblyId/edit'>) {
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: queryParams.rfqId,
        };
    }, [queryParams.rfqId]);
    return (
        <EditAssembly
            viewContext={viewContext}
            rfqId={queryParams.rfqId}
            assemblyId={pathParams.assemblyId}
            currentParentAssemblyId={undefined}
        />
    );
}
