import { t, Trans } from '@lingui/macro';
import {
    CenteredLayout,
    colorSystem,
    Column,
    DataTable,
    MediumContainer,
    NonIdealState,
    useDataTableState,
} from '@luminovo/design-system';
import { CheckCircle } from '@mui/icons-material';
import { styled, Table, TableCell } from '@mui/material';
import { PropertyComparisonTableItem } from './types';

const diff = {
    baseline: colorSystem.neutral[5],
    current: '#7061D1',
};

export interface PropertyComparisonTableProps {
    items: PropertyComparisonTableItem[];
    showOnlyChanges?: boolean;
}

const columnPropertyName: Column<PropertyComparisonTableItem> = {
    id: 'property',
    label: <Trans>Property</Trans>,
    width: '25%',
    render: ({ data }) => {
        return <TableCell>{data.property}</TableCell>;
    },
};

const columnLeft: Column<PropertyComparisonTableItem> = {
    id: 'left',
    width: '25%',
    label: (
        <VersionLabel color={diff.baseline}>
            <Trans>Baseline</Trans>{' '}
        </VersionLabel>
    ),

    render: ({ data }) => {
        return (
            <TableCell
                style={
                    data.left === undefined
                        ? {
                              color: colorSystem.neutral[5],
                          }
                        : undefined
                }
            >
                {data.formatter(data.left)}
            </TableCell>
        );
    },
};

const columnRight: Column<PropertyComparisonTableItem> = {
    id: 'right',
    width: '25%',
    label: (
        <VersionLabel color={diff.current}>
            <Trans>Current</Trans>
        </VersionLabel>
    ),
    render: ({ data }) => {
        return (
            <TableCell
                style={
                    data.right === undefined
                        ? {
                              color: colorSystem.neutral[5],
                          }
                        : undefined
                }
            >
                {data.formatter(data.right)}
            </TableCell>
        );
    },
};

const columnDiff: Column<PropertyComparisonTableItem> = {
    id: 'diff',
    label: '',
    width: '20%',
    render: ({ data }) => {
        const Diff = data.diff;

        return (
            <TableCell>
                <Diff left={data.left} right={data.right} />
            </TableCell>
        );
    },
};

function VersionLabel({ color, children }: { color: string; children: React.ReactNode }) {
    return (
        <span style={{ display: 'inline-flex', alignItems: 'center', gap: 4 }}>
            {children}
            <span
                style={{
                    display: 'inline-flex',
                    width: 8,
                    height: 8,
                    borderRadius: 2,
                    background: color,
                }}
            />
        </span>
    );
}

export function PropertyComparisonTable({ items, showOnlyChanges }: PropertyComparisonTableProps): JSX.Element {
    const tableState = useDataTableState({
        columns: [columnPropertyName, columnLeft, columnRight, columnDiff],
        items: items.filter((item) => {
            if (showOnlyChanges) {
                return !item.isEqual(item.left, item.right);
            }
            return true;
        }),

        persistenceId: 'property-comparison-table',
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 100,
        },
    });
    return (
        <DataTable
            tableState={tableState}
            size="small"
            overrides={{
                Container,
                NoResultsComponent: () => {
                    return (
                        <CenteredLayout>
                            <NonIdealState
                                Icon={() => {
                                    return <CheckCircle style={{ color: colorSystem.green[6] }} />;
                                }}
                                title={t`No changes`}
                                description={t`There are no changes between the two versions.`}
                            />
                        </CenteredLayout>
                    );
                },
                Table: Table,
            }}
        />
    );
}

const Container = styled(MediumContainer)({
    minWidth: 'auto',
    overflowX: 'auto',
    background: colorSystem.neutral.white,
});
