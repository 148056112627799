import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import { ActivityDTO, DriverIdDTO } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { TooltipOnOverflow } from '../../../components/TooltipOnOverflow';
import { logToExternalErrorHandlers } from '../../../utils/customConsole';
import { TextCell } from '../ActivitiesTable/activitiesTableComponents';
import { DriverCell } from '../shared/manufacturingComponents';
import { useDriversOfActivityDTO } from '../utils/useDriversOfActivity';

export const doesUnitActivityHaveBatchTimeCalculation = (activity: ActivityDTO): boolean => {
    if (activity.time_calculation.level !== 'Unit') return false;
    return activity.time_calculation.details.batch_time_components !== null;
};

const Drivers = ({ driverIds }: { driverIds: DriverIdDTO[] }): JSX.Element => {
    if (driverIds.length === 0) {
        return <Typography>-</Typography>;
    }
    return (
        <Flexbox>
            <TooltipOnOverflow
                component={
                    <Flexbox gap={'8px'}>
                        {driverIds.map((driverId) => (
                            <Flexbox key={driverId.value}>
                                <DriverCell driverId={driverId} />
                            </Flexbox>
                        ))}
                    </Flexbox>
                }
                tooltip={
                    <Flexbox gap={'4px'} overflow="auto" flexWrap={'wrap'}>
                        {driverIds.map((driverId) => (
                            <Flexbox key={driverId.value}>
                                <DriverCell driverId={driverId} />
                            </Flexbox>
                        ))}
                    </Flexbox>
                }
            />
        </Flexbox>
    );
};

const DriverError = (): JSX.Element => {
    return (
        <Typography>
            <Trans>n/a</Trans>
        </Typography>
    );
};

const DriverColumnCellUnit = ({ activity }: { activity: ActivityDTO }): JSX.Element => {
    const { unitDrivers, batchDrivers, formulaValidation } = useDriversOfActivityDTO(activity);
    const secondRow = doesUnitActivityHaveBatchTimeCalculation(activity) ? (
        formulaValidation.batchFormula?.result === 'Error' ? (
            <DriverError />
        ) : (
            <Drivers driverIds={batchDrivers} />
        )
    ) : undefined;
    return (
        <TextCell
            firstRow={
                <>
                    {formulaValidation.unitFormula?.result === 'Error' ? (
                        <DriverError />
                    ) : unitDrivers.length > 0 ? (
                        <Drivers driverIds={unitDrivers} />
                    ) : (
                        <Typography>-</Typography>
                    )}
                </>
            }
            secondRow={secondRow}
        />
    );
};

const DriverColumnCellBatch = ({ activity }: { activity: ActivityDTO }): JSX.Element => {
    // I tried to create narrower types above so this could be avoided, but couldn't. Sorry about that, but feel free to try.
    if (activity.time_calculation.level !== 'Batch') {
        const error = new Error(`Expected to be batch but got ${activity.time_calculation.level}`);
        logToExternalErrorHandlers(error);
        throw error;
    }
    const { batchDrivers } = useDriversOfActivityDTO(activity);

    return (
        <TextCell
            firstRow={
                <>
                    {activity.time_calculation.details.batch_time_components?.type !== 'Fixed' &&
                    batchDrivers.length > 0 ? (
                        <Drivers driverIds={batchDrivers} />
                    ) : undefined}
                </>
            }
        />
    );
};

const DriverColumnCellProject = ({ activity }: { activity: ActivityDTO }): JSX.Element => {
    // I tried to create narrower types above so this could be avoided, but couldn't. Sorry about that, but feel free to try.
    if (activity.time_calculation.level !== 'Project') {
        const error = new Error(`Expected to be project but got ${activity.time_calculation.level}`);
        logToExternalErrorHandlers(error);
        throw error;
    }
    const { batchDrivers } = useDriversOfActivityDTO(activity);

    return (
        <TextCell
            firstRow={
                <>
                    {activity.time_calculation.details.project_time_components?.type !== 'Fixed' &&
                    batchDrivers.length > 0 ? (
                        <Drivers driverIds={batchDrivers} />
                    ) : undefined}
                </>
            }
        />
    );
};

export const renderDriverColumn = (activity: ActivityDTO): JSX.Element => {
    const level = activity.time_calculation.level;
    switch (level) {
        case 'Unit':
            return <DriverColumnCellUnit activity={activity} />;
        case 'Batch':
            return <DriverColumnCellBatch activity={activity} />;
        case 'Project':
            return <DriverColumnCellProject activity={activity} />;
        default:
            assertUnreachable(level);
    }
};
