import { Trans, t } from '@lingui/macro';
import {
    compareByString,
    formatDecimal,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
    uniqBy,
} from '@luminovo/commons';
import { List, ListItem } from '@mui/material';
import { Column } from '@tanstack/react-table';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { colorSystem } from '../../../theme';
import { Checkbox } from '../../Checkbox';
import { Chip } from '../../Chip';
import { Flexbox } from '../../Flexbox';
import { MenuItem } from '../../MenuItem';
import { SearchInput } from '../../SearchField';
import { Text } from '../../Text';
import { SupportedFilterFn, getFilterConfig } from '../type';
import { arrayToggleItem, parseOptionsValuesOrFunc } from '../utils';

function countNestedFacetedValues<TData, TValue>({
    column,
    getOptionKey,
}: {
    column: Column<TData, TValue>;
    getOptionKey: (x: TValue extends Array<infer U> ? U : never) => any;
}) {
    const facetedValues = column.getFacetedUniqueValues();
    let uniqueValues = new Map<TValue extends Array<infer U> ? U : never, number>();
    let flatArray: Array<TValue extends Array<infer U> ? U : never> = [];

    facetedValues.forEach((count, array) => {
        flatArray = flatArray.concat(new Array(count).fill(array).flat());
    });

    flatArray.forEach((value) => {
        const key = getOptionKey(value);
        uniqueValues.set(key, (getCountBy(uniqueValues, key) ?? 0) + 1);
    });

    return uniqueValues;
}

function getCountBy<K>(map: Map<K, number>, key: K): number | undefined {
    for (const [mapKey, value] of map.entries()) {
        if (isEqual(mapKey, key)) {
            return value;
        }
    }
    return undefined;
}

function filterColumnOptions<TData, TValue, TShareContext>({
    column,
    query,
    sharedContext,
}: {
    column: Column<TData, TValue>;
    query: String;
    sharedContext: TShareContext;
}) {
    const emptyOptions = { options: [], totalCount: 0 };
    if (!isPresent(column.columnDef.meta)) {
        throwErrorUnlessProduction(new Error('Missing meta.equalsAny in column definition'));
        return emptyOptions;
    }

    const filterConfigEqualsAny = getFilterConfig(column, SupportedFilterFn.equalsAny);
    if (isPresent(filterConfigEqualsAny)) {
        const facedUniqueValues = column.getFacetedUniqueValues();
        const {
            options: rowOptions,
            getOptionLabel,
            renderOption = getOptionLabel,
            getOptionCount = (option) => getCountBy(facedUniqueValues, option),
            getOptionKey = (x) => x,
        } = filterConfigEqualsAny;

        const facetedValues = Array.from(column.getFacetedUniqueValues().keys()) as TValue[];
        const rawValues = parseOptionsValuesOrFunc(rowOptions ?? facetedValues, {
            column,
            facetedValues,
            sharedContext,
        });
        const uniqueValues = uniqBy(rawValues, getOptionKey);
        const sortedValues = isPresent(rowOptions)
            ? uniqueValues
            : uniqueValues.sort((a, b) => compareByString(getOptionLabel(a), getOptionLabel(b)));
        const filterOptions = sortedValues.filter((value) =>
            getOptionLabel(value).toLowerCase().includes(query.toLowerCase()),
        );

        const options = filterOptions.map((value) => ({
            value: value,
            label: getOptionLabel(value),
            Element: renderOption(value),
            count: getOptionCount(value, sharedContext),
        }));

        return { options, totalCount: uniqueValues.length };
    }

    const filterConfigArrIncludesSome = getFilterConfig(column, SupportedFilterFn.arrIncludesSome);
    if (filterConfigArrIncludesSome) {
        const { getOptionKey = (x) => x } = filterConfigArrIncludesSome;
        const facedUniqueValues = countNestedFacetedValues({ column, getOptionKey });

        const {
            options: rowOptions,
            getOptionLabel,
            renderOption = getOptionLabel,
            getOptionCount = (option) => getCountBy(facedUniqueValues, getOptionKey(option)),
        } = filterConfigArrIncludesSome;

        // This is type safe because we are sure that the column is of type Column<TData, TValue extends Array>
        const facetedValues = Array.from(column.getFacetedUniqueValues().keys()).flat() as TValue;
        const rawValues = parseOptionsValuesOrFunc(rowOptions ?? facetedValues, {
            column,
            facetedValues,
            sharedContext,
        }) as Array<TValue extends Array<infer U> ? U : never>;

        const uniqueValues = uniqBy(rawValues, getOptionKey);
        const sortedValues = isPresent(rowOptions)
            ? uniqueValues
            : uniqueValues.sort((a, b) => compareByString(getOptionLabel(a), getOptionLabel(b)));
        const filterOptions = sortedValues.filter((value) =>
            getOptionLabel(value).toLowerCase().includes(query.toLowerCase()),
        );

        const options = filterOptions.map((value) => ({
            value: value,
            label: getOptionLabel(value),
            Element: renderOption(value),
            count: getOptionCount(value, sharedContext),
        }));

        return { options, totalCount: uniqueValues.length };
    }

    throwErrorUnlessProduction(new Error('Invalid filter value for ArrayFilterList filter'));
    return emptyOptions;
}

function ArrayFilterList<TData, TValue, TSharedContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, TValue>;
    sharedContext: TSharedContext;
}): JSX.Element | null {
    const [query, setQuery] = React.useState<string>('');
    const unsafeValue = column.getFilterValue();

    const filterConfig =
        getFilterConfig(column, SupportedFilterFn.equalsAny) ||
        getFilterConfig(column, SupportedFilterFn.arrIncludesSome);

    if (!isPresent(filterConfig)) {
        return null;
    }

    const { options, totalCount } = filterColumnOptions({ column, query, sharedContext });

    if (unsafeValue === undefined || Array.isArray(unsafeValue)) {
        return (
            <Flexbox flexDirection={'column'} gap={'8px'}>
                {totalCount > 5 && (
                    <SearchInput
                        placeholder={t`Filter by...`}
                        debounceWait={50}
                        value={query}
                        onChange={(value) => setQuery(value)}
                        onClear={() => setQuery('')}
                        style={{ backgroundColor: colorSystem.neutral.white, width: '100%', minWidth: '300px' }}
                    />
                )}
                <List style={{ width: '100%', maxHeight: '330px', overflow: 'auto', borderRadius: 4 }} disablePadding>
                    <Virtuoso
                        style={{
                            height: 40 * Math.min(options.length, 6),
                            width: 330,
                            overflowX: 'hidden',
                            boxSizing: 'border-box',
                        }}
                        initialItemCount={
                            // This is a workaround for virtuoso not rendering the correct amount of items during testing
                            Math.min(options.length, 10)
                        }
                        overscan={200}
                        totalCount={options.length}
                        itemContent={(index) => {
                            const { value, count, Element } = options[index];
                            return (
                                <MenuItem
                                    style={{ marginLeft: '0px', marginRight: '0px' }}
                                    onClick={() => {
                                        column.setFilterValue((old: unknown[] | undefined) => {
                                            const newArray = arrayToggleItem(old ?? [], value);
                                            return newArray.length > 0 ? newArray : undefined;
                                        });
                                    }}
                                    label={''}
                                    overrides={{
                                        InnerItem: () => (
                                            <Flexbox alignItems={'center'} width={'100%'} gap={'8px'}>
                                                <Checkbox
                                                    key={unsafeValue?.length}
                                                    size={'small'}
                                                    checked={unsafeValue?.some((v) => isEqual(v, value))}
                                                />
                                                {Element === '' ? (
                                                    <Text variant={'body-small'} color={colorSystem.neutral[5]}>
                                                        <Trans>Empty</Trans>
                                                    </Text>
                                                ) : (
                                                    <Text variant={'body-small'}>{Element}</Text>
                                                )}

                                                <Flexbox flex={1} />
                                                <Text variant="body-small" color={colorSystem.neutral[6]}>
                                                    {formatDecimal(count, { ifAbsent: '-', ifNaN: '' })}
                                                </Text>
                                            </Flexbox>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />

                    {options.length === 0 && (
                        <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                            <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                                <Trans>No results</Trans>
                            </Text>
                        </ListItem>
                    )}
                </List>
            </Flexbox>
        );
    }

    throwErrorUnlessProduction(new Error('Invalid filter value for ArrayFilterList filter'));
    return null;
}

function ArrayFilterField<TData, TShareContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, unknown>;
    sharedContext: TShareContext;
}): JSX.Element | null {
    const unsafeValue = column.getFilterValue();

    const filterConfig =
        getFilterConfig(column, SupportedFilterFn.equalsAny) ||
        getFilterConfig(column, SupportedFilterFn.arrIncludesSome);

    if (!isPresent(filterConfig)) {
        return null;
    }

    const { options } = filterColumnOptions({ column, query: '', sharedContext });

    if (unsafeValue === undefined || Array.isArray(unsafeValue)) {
        return (
            <Flexbox gap={4} flexWrap={'wrap'}>
                {options
                    .filter(({ value }) => unsafeValue?.includes(value))
                    .map(({ value, label }, idx) => (
                        <Chip
                            key={idx}
                            label={label}
                            color={'neutral'}
                            onDelete={() =>
                                column.setFilterValue((old: unknown[] | undefined) => {
                                    if (!isPresent(old)) {
                                        return undefined;
                                    }
                                    const newValue = old.filter((v) => v !== value);
                                    if (newValue.length === 0) {
                                        return undefined;
                                    }
                                    return newValue;
                                })
                            }
                        />
                    ))}
            </Flexbox>
        );
    }

    throwErrorUnlessProduction(new Error('Invalid filter value for equalsAnyFilter filter'));
    return null;
}

export const FilterEnumEqualsAny = ArrayFilterList;
export const FilterEnumEqualsAnyField = ArrayFilterField;
export const FilterArrIncludesSome = ArrayFilterList;
export const FilterArrIncludesSomeField = ArrayFilterField;
