import { t } from '@lingui/macro';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { EmsPartNumberPartSpecification } from '@luminovo/http-client';
import { Box } from '@mui/material';

const TitleWithText = ({ label, text }: { label: string; text: string }): JSX.Element => {
    return (
        <Box display="grid" gridTemplateColumns={'120px auto'}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                {label}
            </Text>
            <Text variant="body-small" style={{ textAlign: 'right' }}>
                {text}
            </Text>
        </Box>
    );
};

export const SyncedErpDataDetails = ({
    partSpecifications,
}: {
    partSpecifications: EmsPartNumberPartSpecification;
}): JSX.Element => {
    const { mpn, manufacturer, description, part_type } = partSpecifications;
    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <TitleWithText label={t`MPN`} text={mpn ?? '-'} />
            <TitleWithText label={t`Manufacturer`} text={manufacturer ?? '-'} />
            <TitleWithText label={t`Description`} text={description ?? '-'} />
            <TitleWithText label={t`Package`} text={partSpecifications.package ?? '-'} />
            <TitleWithText label={t`Part type`} text={part_type ?? '-'} />
        </Flexbox>
    );
};
