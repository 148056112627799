import { plural, t } from '@lingui/macro';
import { Message } from '@luminovo/design-system';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { useShowPcbPdfAnalysisMessageModal } from '../PcbAnalysis/utils/useShowPcbPdfAnalysisMessageModal';
import { encodePcbFilePath, usePdfViewerDrawer } from '../PcbPdfViewer/usePdfViewerDrawer';

export function PdfExtractionMessage({ capabilities, pcbId }: { capabilities: Capability[]; pcbId: string }) {
    const extractedFromPdf = capabilities.filter((cap) => cap.extractedFromPdf);
    const { openDrawer } = usePdfViewerDrawer();
    const { userHasSeenModal, setUserAgreesToExtraction, userAgreesToExtraction } =
        useShowPcbPdfAnalysisMessageModal(pcbId);

    const handleClose = () => {
        setUserAgreesToExtraction(false);
    };

    if (extractedFromPdf.length === 0 || (userHasSeenModal && userAgreesToExtraction === false)) {
        return <></>;
    }

    const extractedValues = extractedFromPdf.length;
    const pluralizedFieldText = plural(extractedValues, {
        one: `field was`,
        other: `fields were`,
    });

    return (
        <Message
            attention="high"
            size="large"
            variant="primary"
            title={t`PDF extraction`}
            // eslint-disable-next-line spellcheck/spell-checker
            message={t`${extractedValues} ${pluralizedFieldText} automatically extracted from uploaded PDFs`}
            onClose={handleClose}
            action={{
                label: t`Open PDF`,
                onClick: () => {
                    openDrawer({
                        selectedPdf: {
                            fileName: encodePcbFilePath(extractedFromPdf[0].extractedFromPdf?.file?.path),
                            regions: extractedFromPdf.flatMap((cap) => cap.extractedFromPdf?.regions ?? []),
                        },
                    });
                },
            }}
        />
    );
}
