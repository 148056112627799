import { Box, BoxProps, CircularProgress, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react';
import React from 'react';
import { NAVBAR_HEIGHT, colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { CenteredLayout } from '../CenteredLayout';

interface SidebarLayoutProps extends BoxProps {
    navbar?: React.ReactNode;
    toolbar?: React.ReactNode;
    sidebar?: React.ReactNode;
    content: React.ReactNode;
    defaultSidebarWidth?: string;
    inlinedToolbar?: boolean;
    contentBackground?: string;
    fallback: FallbackRender;
}
const sidebarBackground = colorSystem.neutral.white;

export function SidebarLayout({
    sidebar,
    content,
    navbar,
    toolbar,
    defaultSidebarWidth = '240px',
    inlinedToolbar = false,
    contentBackground,
    fallback,
    ...props
}: SidebarLayoutProps): JSX.Element {
    const navbarHeight = Boolean(navbar) ? NAVBAR_HEIGHT : '0px';
    const toolbarHeight = toolbar ? NAVBAR_HEIGHT : '0px';
    const sidebarWidth = sidebar ? defaultSidebarWidth : '0px';
    const dividerWidth = sidebar ? '1px' : '0px';
    const contentHeight = `calc(100vh - ${toolbarHeight} - ${navbarHeight} )`;
    const contentWidth = `calc(100vw - ${sidebarWidth} - ${dividerWidth} )`;

    return (
        <Container {...props}>
            <SuspenseBoundary>
                <NavbarContainer style={{ height: navbarHeight }} id="layout-navbar">
                    {navbar}
                </NavbarContainer>
                {toolbar && !inlinedToolbar && (
                    <ToolbarContainer style={{ height: toolbarHeight }} id="layout-toolbar">
                        {toolbar}
                    </ToolbarContainer>
                )}
                <Box display={'grid'} gridTemplateColumns={`${sidebarWidth} auto 1fr`}>
                    <SidebarContainer style={{ width: sidebarWidth }}>
                        <Sentry.ErrorBoundary fallback={fallback}>
                            <React.Suspense
                                fallback={
                                    <Flexbox
                                        flexDirection={'column'}
                                        gap="8px"
                                        padding="20px"
                                        style={{ background: sidebarBackground }}
                                    >
                                        <Skeleton width={'100%'} height="40px" />
                                        <Skeleton width={'100%'} height="40px" />
                                        <Skeleton width={'100%'} height="40px" />
                                        <Skeleton width={'100%'} height="40px" />
                                    </Flexbox>
                                }
                            >
                                {sidebar}
                            </React.Suspense>
                        </Sentry.ErrorBoundary>
                    </SidebarContainer>

                    <div style={{ width: 1, height: '100%', background: colorSystem.neutral[2] }} />

                    <Sentry.ErrorBoundary fallback={fallback}>
                        <SuspenseBoundary>
                            {toolbar && inlinedToolbar && (
                                <ToolbarContainer
                                    style={{ height: toolbarHeight, width: contentWidth }}
                                    id="layout-toolbar"
                                >
                                    {toolbar}
                                </ToolbarContainer>
                            )}
                            <ContentContainer
                                style={{
                                    width: contentWidth,
                                    height: contentHeight,
                                    left: `calc(${sidebarWidth} + ${dividerWidth})`,
                                    top: `calc(${navbarHeight} + ${toolbarHeight})`,
                                    background: contentBackground,
                                }}
                                id="scrollContainer"
                            >
                                {content}
                            </ContentContainer>
                        </SuspenseBoundary>
                    </Sentry.ErrorBoundary>
                </Box>
            </SuspenseBoundary>
        </Container>
    );
}

const Container = styled(Box)({
    height: '100vh',
    position: 'relative',
    width: '100vw',
    overflow: 'hidden',
});

function SuspenseBoundary({ children, minHeight }: { children: React.ReactNode; minHeight?: string }): JSX.Element {
    return (
        <React.Suspense
            fallback={
                <CenteredLayout minHeight={minHeight}>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            {children}
        </React.Suspense>
    );
}

const NavbarContainer = styled('div')({
    width: '100vw',
    top: 0,
});

const ToolbarContainer = styled('div')({
    width: '100vw',
    overflow: 'hidden',
    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    boxSizing: 'border-box',
});

const SidebarContainer = styled('div')({
    height: '100vh',
    overflow: 'auto',
    background: sidebarBackground,
});

const ContentContainer = styled('div')({
    overflow: 'auto',
    minWidth: '724px',
    position: 'fixed',
    background: colorSystem.neutral.white,
});
