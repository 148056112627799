import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const peelableMaskKeywords = ['(?:Löt)abziehlack', 'abziehbar', 'peelable', 'strippable'];

export function parsePeelableMaskKeyword(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(peelableMaskKeywords);
    if (regex.test(str)) {
        return {
            attr: 'peelableMaskKeyword',
            value: undefined,
            // we are more confident about the match the shorter the string that matched
            // this helps us more accurately match the location of the soldermask keyword in the merging stage
            confidence: 1 / str.length,
        };
    }
    return undefined;
}
