import { FullWidthLayout, MaxWidthLayout, colorSystem } from '@luminovo/design-system';
import { BoxProps } from '@mui/material';
import { PageLayout } from './PageLayout';

type Props = {
    sidebar: JSX.Element;
    layoutVariant?: 'fullWidth' | 'maxWidth';
} & BoxProps;

export function PageLayoutCollapsibleSidebar({ children, sidebar, layoutVariant = 'maxWidth' }: Props) {
    return (
        <PageLayout layout={'fragment'} flexDirection={'row'} removeHubspotPaddingFix bgcolor={colorSystem.neutral[1]}>
            {sidebar}
            {layoutVariant === 'maxWidth' ? (
                <MaxWidthLayout>{children}</MaxWidthLayout>
            ) : (
                <FullWidthLayout>{children}</FullWidthLayout>
            )}
        </PageLayout>
    );
}
