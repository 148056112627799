/* eslint-disable camelcase */

import * as r from 'runtypes';

export interface ManufacturerDTO extends r.Static<typeof ManufacturerDTORuntype> {}
export const ManufacturerDTORuntype = r.Record({
    alternative_names: r.Array(r.String),
    id: r.String,
    name: r.String,
});

export interface ManufacturerLineCardDTO extends r.Static<typeof ManufacturerLineCardDTORuntype> {}

export const ManufacturerLineCardDTORuntype = r.Record({
    manufacturer_id: r.String,
    supported_by: r.Array(r.String),
    restricted_to_suppliers: r.Array(r.String),
    part_category_restriction: r.Array(
        r.Record({
            restrict_to: r.Array(r.Number),
            supplier: r.String,
        }),
    ),
});
