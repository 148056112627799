import { t } from '@lingui/macro';
import { OrderConfirmedIcon } from '../../../components/OrderConfirmedIcon';
import { BannerCard } from './BannerCard';
import { GREEN_GRADIENT } from './gradients';

export const BannerCardOrderConfirmed = ({ orgName }: { orgName: string | undefined }): JSX.Element => {
    const emsName = orgName || 'EMS';

    return (
        <BannerCard
            background={GREEN_GRADIENT}
            title={t`Your order has been confirmed.`}
            description={emsName + ' ' + t`has confirmed your purchase.`}
            icon={<OrderConfirmedIcon />}
        />
    );
};
