import { transEnum } from '@luminovo/commons';
import { Tooltip, colorSystem } from '@luminovo/design-system';
import { StarRounded } from '@mui/icons-material';
import { PreferredTagEnum, preferredTagTooltipTranslations } from './PreferredTag';

export const PreferredIpnTag = ({
    showTooltip = true,
    styles,
}: {
    showTooltip?: boolean;
    styles?: React.CSSProperties;
}): JSX.Element => {
    return (
        <Tooltip title={showTooltip ? transEnum(PreferredTagEnum.Ipn, preferredTagTooltipTranslations) : ''}>
            <StarRounded style={{ color: colorSystem.primary[7], fontSize: '16px', ...styles }} />
        </Tooltip>
    );
};
