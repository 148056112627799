import { plural, t } from '@lingui/macro';
import {
    ActivitiesDTO,
    ManufacturingEntityStatusSelection,
    ManufacturingScenarioTemplateDTO,
    http,
} from '@luminovo/http-client';
import { RightAlignedStickyTableCell } from '@luminovo/manufacturing-core';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorText } from '../../../components/Spinners';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox, { InformationDialogBoxContent } from '../../../components/dialogBox/InformationDialogBox';
import { useToken } from '../../../resources/hooks';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useManufacturingScenarioTemplatesOfActivities } from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { route } from '../../../utils/routes';
import { ActionMenuComponent } from '../shared/manufacturingComponents';
import { useInformationAndConfirmationDialogs } from '../shared/manufacturingUtils';

interface UseDeleteResource {
    deleteOnClick: () => Promise<unknown>;
    isResourceUsedInAnActivity: boolean;
    activitiesOfResource: ActivitiesDTO | undefined;
    isLoading: boolean;
    isError: boolean;
}

const useDeleteResource = (resourceId: string): UseDeleteResource => {
    // eslint-disable-next-line camelcase
    const { data, isLoading, isError } = useHttpQuery('GET /activities', { queryParams: { resource_id: resourceId } });
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync } = useMutation({
        mutationFn: () => http('DELETE /resources/:resourceId', { pathParams: { resourceId } }, token),
        onSuccess: async () => {
            await queryClient.invalidateQueries(httpQueryKey('GET /resources'));
            enqueueSnackbar(t`Resource deleted`, { variant: 'info' });
        },
        onError: debugErrorHandler,
    });

    const activitiesOfResource: ActivitiesDTO | undefined = data?.data;
    const isResourceUsedInAnActivity =
        !isLoading && !isError && activitiesOfResource !== undefined && activitiesOfResource?.length > 0;

    return {
        deleteOnClick: () => mutateAsync(),
        isResourceUsedInAnActivity,
        activitiesOfResource,
        isLoading,
        isError,
    };
};

interface ResourceMenuProps {
    resourceId: string;
    status: string;
}

function generateTranslatedString(
    activitiesOfResource: ActivitiesDTO,
    templatesOfActivities: ManufacturingScenarioTemplateDTO[],
) {
    const activitiesOfResourceStr: string = activitiesOfResource
        ? activitiesOfResource.map((activity) => activity.name).join(', ')
        : '';
    const templatesOfActivitiesStr: string = templatesOfActivities
        ? templatesOfActivities.map((template) => template.name).join(', ')
        : '';

    const pluralisedActivity = plural(activitiesOfResource?.length ?? 0, {
        one: `activity`,
        other: `activities`,
    });

    const activitiesLength = activitiesOfResource?.length ?? 0;

    var translatedString: string = t`This resource is used in ${pluralisedActivity} ${activitiesOfResourceStr}.`;

    if (activitiesLength === 1) {
        translatedString = ' ' + t`Deactivating it will also deactivate the respective activity.`;
    } else if (activitiesLength > 1) {
        translatedString = ' ' + t`Deactivating it will also deactivate the respective activities.`;
    }

    if (templatesOfActivities.length > 0) {
        translatedString += ' ';
        if (templatesOfActivities.length > 1) {
            translatedString += t`Since these are used in templates ${templatesOfActivitiesStr} they will also be deactivated.`;
        } else if (templatesOfActivities.length === 1) {
            translatedString += t`Since these are used in template ${templatesOfActivitiesStr} it will also be deactivated.`;
        }
    }

    translatedString += ' ' + t`Proceed?`;
    return translatedString;
}

export const ResourceMenuComponent: React.FunctionComponent<ResourceMenuProps> = ({
    resourceId,
    status,
}): JSX.Element => {
    const queryClient = useQueryClient();
    const { token } = useToken();
    const {
        deleteOnClick,
        isResourceUsedInAnActivity,
        activitiesOfResource,
        isError: isErrorDeleteResource,
    } = useDeleteResource(resourceId);
    const {
        openDialog: openDeleteDialog,
        closeDialog: closeDeleteDialog,
        isInformationDialogOpen: isInformationDialogDeleteOpen,
        isConfirmationDialogOpen: isConfirmationDialogDeleteOpen,
    } = useInformationAndConfirmationDialogs(isResourceUsedInAnActivity);
    const {
        openDialog: openSetInactiveDialog,
        closeDialog: closeSetInactiveDialog,
        isConfirmationDialogOpen: isConfirmationDialogSetInactiveOpen,
    } = useInformationAndConfirmationDialogs(false);

    const { data: templatesOfActivitiesData, isError: isTemplatesActivitiesError } =
        useManufacturingScenarioTemplatesOfActivities(
            activitiesOfResource?.map((activity) => activity.id) ?? [],
            activitiesOfResource !== undefined && activitiesOfResource.length > 0,
        );

    const isError = isErrorDeleteResource || isTemplatesActivitiesError;
    const templatesOfActivities = templatesOfActivitiesData?.data ?? [];

    const translatedString = generateTranslatedString(activitiesOfResource ?? [], templatesOfActivities);

    const history = useHistory();

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; status: ManufacturingEntityStatusSelection }) => {
            return http(
                'PATCH /resources/:resourceId',
                { requestBody: { status: info.status }, pathParams: { resourceId } },
                token,
            );
        },
        onSuccess: () => queryClient.invalidateQueries(httpQueryKey('GET /resources')),
    });

    return (
        <RightAlignedStickyTableCell>
            <ActionMenuComponent
                isActive={status === 'Active'}
                onClickActivate={function (event: React.MouseEvent<HTMLElement>): void {
                    if (status === 'Active' && isResourceUsedInAnActivity) {
                        openSetInactiveDialog();
                    } else {
                        mutateAsyncPatch({
                            token,
                            status: status === 'Active' ? 'Inactive' : 'Active',
                        });
                    }
                }}
                onClickEdit={function (event: React.MouseEvent<HTMLElement>): void {
                    history.push(route('/manufacturing/resource/:resourceId/edit', { resourceId }));
                }}
                onClickDuplicate={function (event: React.MouseEvent<HTMLElement>): void {
                    history.push(route('/manufacturing/resource/:resourceId/duplicate', { resourceId }));
                }}
                onClickDelete={openDeleteDialog}
            />
            {isError && <ErrorText />}
            {isResourceUsedInAnActivity && activitiesOfResource !== undefined && (
                <InformationDialogBox
                    title={t`Deleting not possible`}
                    onReject={closeDeleteDialog}
                    isDialogOpen={isInformationDialogDeleteOpen}
                >
                    <InformationDialogBoxContent
                        text={t`This resource is used in the following activities and cannot be deleted`}
                    >
                        <>
                            <ul>
                                {activitiesOfResource.map((activity) => (
                                    <li key={activity.id}>
                                        <Typography variant={'body1'}> {activity.name} </Typography>
                                    </li>
                                ))}
                            </ul>
                        </>
                    </InformationDialogBoxContent>
                </InformationDialogBox>
            )}
            <ConfirmationDialogBox
                text={t`Are you sure you want to delete this resource?`}
                title={t`Delete resource`}
                onConfirm={() => deleteOnClick()}
                onReject={closeDeleteDialog}
                isDialogOpen={isConfirmationDialogDeleteOpen}
                deleteText={t`Yes, delete`}
            />

            {isResourceUsedInAnActivity && (
                <ConfirmationDialogBox
                    text={t`Are you sure you want to set this resource as inactive?`}
                    title={t`Deactivate resource`}
                    onConfirm={() =>
                        mutateAsyncPatch({
                            token,
                            status: 'Inactive',
                        })
                    }
                    onReject={closeSetInactiveDialog}
                    isDialogOpen={isConfirmationDialogSetInactiveOpen}
                    deleteText={t`Yes, set inactive`}
                >
                    <Typography variant={'body1'}>{translatedString}</Typography>
                </ConfirmationDialogBox>
            )}
        </RightAlignedStickyTableCell>
    );
};
