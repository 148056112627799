import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { StatusChip, colorSystem } from '@luminovo/design-system';
import { LifecycleEnum } from '@luminovo/http-client';
import React from 'react';

export const lifecycleEnumLongNamePublicTranslations: Record<LifecycleEnum, MessageDescriptor> = {
    [LifecycleEnum.Acquired]: defineMessage({ message: 'Acquired' }),
    [LifecycleEnum.Aftermarket]: defineMessage({ message: 'Aftermarket' }),
    [LifecycleEnum.PreRelease]: defineMessage({ message: 'Pre-release' }),
    [LifecycleEnum.Active]: defineMessage({ message: 'Active' }),
    [LifecycleEnum.NotRecommendedForNewDesigns]: defineMessage({ message: 'Not recommended for new designs' }),
    [LifecycleEnum.EndOfLife]: defineMessage({ message: 'End of life' }),
    [LifecycleEnum.Obsolete]: defineMessage({ message: 'Obsolete' }),
    [LifecycleEnum.Unknown]: defineMessage({ message: 'Unknown' }),
};

export const lifecycleEnumShortNamePublicTranslations: Record<LifecycleEnum, MessageDescriptor> = {
    [LifecycleEnum.Acquired]: defineMessage({ message: 'Acquired' }),
    [LifecycleEnum.Aftermarket]: defineMessage({ message: 'OBS' }),
    [LifecycleEnum.PreRelease]: defineMessage({ message: 'PRE' }),
    [LifecycleEnum.Active]: defineMessage({ message: 'Active' }),
    [LifecycleEnum.NotRecommendedForNewDesigns]: defineMessage({ message: 'NRND' }),
    [LifecycleEnum.EndOfLife]: defineMessage({ message: 'EOL' }),
    [LifecycleEnum.Obsolete]: defineMessage({ message: 'OBS' }),
    [LifecycleEnum.Unknown]: defineMessage({ message: 'Unknown' }),
};

export const LifecycleChip: React.FunctionComponent<{
    lifecycle: LifecycleEnum;
    showShortName?: boolean;
    disabled?: boolean;
}> = ({ lifecycle, showShortName = false, disabled = false }) => {
    const label = showShortName
        ? transEnum(lifecycle, lifecycleEnumShortNamePublicTranslations)
        : transEnum(lifecycle, lifecycleEnumLongNamePublicTranslations);
    const color = getLifecycleColor(lifecycle);
    return <StatusChip color={color} label={label} disabled={disabled} />;
};

export const getLifecycleColor = (lifecycle: LifecycleEnum): keyof typeof colorSystem => {
    switch (lifecycle) {
        case LifecycleEnum.PreRelease:
        case LifecycleEnum.Active:
            return 'green';
        case LifecycleEnum.NotRecommendedForNewDesigns:
            return 'yellow';
        case LifecycleEnum.Aftermarket:
        case LifecycleEnum.EndOfLife:
        case LifecycleEnum.Obsolete:
            return 'red';
        case LifecycleEnum.Acquired:
        case LifecycleEnum.Unknown:
            return 'neutral';
        default:
            assertUnreachable(lifecycle);
    }
};
