import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDecimal, throwErrorUnlessProduction } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { SolutionTag } from '@luminovo/http-client';
import { hasSolutionTag } from '@luminovo/sourcing-core';
import { HorizontalStackedBarChart, palette } from '@luminovo/viz';
import { ChartSpec, extractLabelSupplier } from './ChartSpec';

type Datum = {
    id: string[];
    label: string;
    approvedSupplier?: number;
    preferredSupplier?: number;
    notApprovedSupplier?: number;
    noOffers?: number;
    unknown?: number;
};

type Keys = Exclude<keyof Datum, 'label' | 'id'>;
const keys: Keys[] = ['preferredSupplier', 'approvedSupplier', 'notApprovedSupplier', 'unknown', 'noOffers'];

const comparator = chainComparators<Datum>(
    compareByNumber((d) => -(d.preferredSupplier ?? 0)),
    compareByNumber((d) => -(d.approvedSupplier ?? 0)),
    compareByNumber((d) => -(d.unknown ?? 0)),
    compareByNumber((d) => -(d.notApprovedSupplier ?? 0)),
);

export const chartSpecSuppliers: ChartSpec<Keys, Datum> = {
    id: 'supplierName',
    title: <Trans>Solutions by</Trans>,
    orderBy: comparator,
    groupBy: [
        {
            extractor: extractLabelSupplier,
            label: <Trans>Supplier</Trans>,
        },
    ],
    aggregate(a, b) {
        return {
            id: a.id.concat(b.id),
            label: a.label,
            approvedSupplier: (a.approvedSupplier ?? 0) + (b.approvedSupplier ?? 0),
            noOffers: (a.noOffers ?? 0) + (b.noOffers ?? 0),
            notApprovedSupplier: (a.notApprovedSupplier ?? 0) + (b.notApprovedSupplier ?? 0),
            preferredSupplier: (a.preferredSupplier ?? 0) + (b.preferredSupplier ?? 0),
            unknown: (a.unknown ?? 0) + (b.unknown ?? 0),
        };
    },
    keys,
    map(data, extractLabel) {
        const id = [data.solutionConfigurationSourcing.id];
        const label = extractLabel(data);
        const count = 1;
        if (
            data.solutionConfigurationSourcing.is_consigned ||
            data.itemType === 'consigned' ||
            data.itemType === 'inventoryOffer'
        ) {
            return {
                id,
                label,
                unknown: count,
            };
        }
        if (data.itemType === 'unknown' || data.itemType === 'unavailable' || data.itemType === 'solutionOnly') {
            return {
                id,
                label,
                noOffers: count,
            };
        }
        if (data.itemType === 'customPart' || data.itemType === 'marketOffer') {
            const isApproved = hasSolutionTag(data.solution, SolutionTag.SupplierApproved);
            if (isApproved) {
                return {
                    id,
                    label,
                    approvedSupplier: count,
                };
            }
            const isPreferred = hasSolutionTag(data.solution, SolutionTag.SupplierPreferred);
            if (isPreferred) {
                return {
                    id,
                    label,
                    preferredSupplier: count,
                };
            }

            const isNotApproved = hasSolutionTag(data.solution, SolutionTag.SupplierNotApproved);
            if (isNotApproved) {
                return {
                    id,
                    label,
                    notApprovedSupplier: count,
                };
            }
            throwErrorUnlessProduction(new Error('IllegalState reached in ChartSuppliers'));
            return {
                id,
                label,
                unknown: count,
            };
        }
        assertUnreachable(data);
    },
    render: ChartSuppliers,
};

function ChartSuppliers({
    data,
    keys,
    onSelectDatum,
}: {
    rfqId: string;
    data: Datum[];
    keys: Keys[];
    onSelectDatum(datum: Datum): void;
}) {
    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            formatValue={(x) => formatDecimal(x)}
            formatKey={(key) => {
                if (key === 'approvedSupplier') {
                    return t`Approved`;
                }
                if (key === 'notApprovedSupplier') {
                    return t`Not approved`;
                }
                if (key === 'preferredSupplier') {
                    return t`Preferred`;
                }
                if (key === 'noOffers') {
                    return t`No offers`;
                }
                if (key === 'unknown') {
                    return t`Unknown`;
                }
                return key;
            }}
            getColor={(key) => {
                if (key === 'preferredSupplier') {
                    return palette.default[0];
                }
                if (key === 'approvedSupplier') {
                    return palette.default[2];
                }
                if (key === 'notApprovedSupplier') {
                    return palette.error.medium;
                }
                if (key === 'unknown') {
                    return palette.other[0];
                }
                if (key === 'noOffers') {
                    return palette.error.high;
                }

                assertUnreachable(key);
            }}
            onBarClick={onSelectDatum}
            width={800}
        />
    );
}
