import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Checkbox, Flexbox, Link, MenuItem, SearchInput, Text, colorSystem } from '@luminovo/design-system';
import { DoneAllRounded, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { Divider, List, ListItem, Menu } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { AddDriverFormInputs } from '../AddDriverFormTypes';

const normalizePackageName = (name: string): string => {
    return name.toLowerCase().replace(/[-\s]/g, '');
};

//TODO: This is very similar to the AdvancedPackageFilter component in the AdvancedPackageFilterMenuScrap.tsx
// We should refactor to share the same component, especially if we have another use case for it
function AdvancedPackageFilter({ options, index }: { options: string[]; index: number }): JSX.Element | null {
    const [query, setQuery] = React.useState<string>('');
    const [fuzzySearch, setFuzzySearch] = React.useState(false);

    const { setValue, control } = useFormContext<AddDriverFormInputs>();
    const selectedPackages = useWatch({ control, name: `driverDetails.filterFormula.${index}.filter.value.packages` });

    const filteredOptions = options.filter((option) =>
        fuzzySearch
            ? normalizePackageName(option).includes(normalizePackageName(query))
            : option.toLowerCase().includes(query.toLowerCase()),
    );

    const handleClick = (value: string) => {
        if (selectedPackages.includes(value)) {
            setValue(
                `driverDetails.filterFormula.${index}.filter.value.packages`,
                selectedPackages.filter((item) => item !== value),
            );
        } else {
            setValue(`driverDetails.filterFormula.${index}.filter.value.packages`, [...selectedPackages, value]);
        }
    };
    const handleSelectAllShown = () => {
        setValue(`driverDetails.filterFormula.${index}.filter.value.packages`, filteredOptions);
    };
    const handleDeselectAllShown = () => {
        setValue(
            `driverDetails.filterFormula.${index}.filter.value.packages`,
            selectedPackages.filter((item) => !filteredOptions.includes(item)),
        );
    };
    const totalCount = options.length;
    return (
        <Flexbox flexDirection={'column'} gap={'8px'} padding={'8px'}>
            {totalCount > 5 && (
                <Flexbox alignItems={'center'} gap={'8px'}>
                    <SearchInput
                        placeholder={t`Filter by...`}
                        debounceWait={200}
                        value={query}
                        onChange={(value) => setQuery(value)}
                        onClear={() => setQuery('')}
                        style={{ backgroundColor: colorSystem.neutral.white, width: '100%', minWidth: '240px' }}
                    />
                    <Flexbox alignItems={'center'} gap={'8px'} marginLeft={'8px'} minWidth={'200px'}>
                        <Checkbox
                            size={'small'}
                            checked={fuzzySearch}
                            onChange={(e) => setFuzzySearch(e.target.checked)}
                        />
                        <Text variant={'body-small'}>
                            <Trans>Enable fuzzy search</Trans>
                        </Text>
                    </Flexbox>
                </Flexbox>
            )}
            <List style={{ width: '100%', maxHeight: '330px', overflow: 'auto', borderRadius: 4 }} disablePadding>
                <Virtuoso
                    style={{
                        height: 40 * Math.min(filteredOptions.length, 6),
                        width: 330,
                        overflowX: 'hidden',
                        boxSizing: 'border-box',
                    }}
                    initialItemCount={
                        // This is a workaround for virtuoso not rendering the correct amount of items during testing
                        Math.min(options.length, 10)
                    }
                    overscan={200}
                    totalCount={filteredOptions.length}
                    itemContent={(index) => {
                        return (
                            <MenuItem
                                style={{ marginLeft: '0px', marginRight: '0px' }}
                                onClick={(i) => handleClick(filteredOptions[index])}
                                label={filteredOptions[index]}
                                overrides={{
                                    InnerItem: (i) => (
                                        <Flexbox alignItems={'center'} width={'100%'} gap={'8px'}>
                                            <Checkbox
                                                key={i.label.toString()}
                                                size={'small'}
                                                checked={selectedPackages.includes(filteredOptions[index])}
                                            />
                                            {i.label}
                                            <Flexbox flex={1} />
                                        </Flexbox>
                                    ),
                                }}
                            />
                        );
                    }}
                />

                {filteredOptions.length === 0 && (
                    <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                            <Trans>No results</Trans>
                        </Text>
                    </ListItem>
                )}
            </List>
            <Flexbox flexDirection={'column'} gap={4}>
                <Divider key="2" />
                <MenuItem
                    label={t`Select ${filteredOptions.length} shown`}
                    startIcon={<DoneAllRounded />}
                    onClick={() => {
                        handleSelectAllShown();
                    }}
                    disabled={selectedPackages.length === filteredOptions.length}
                />
                <MenuItem
                    label={t`Deselect ${filteredOptions.length} shown`}
                    startIcon={<RemoveCircleOutlineRounded />}
                    onClick={() => {
                        handleDeselectAllShown();
                    }}
                    disabled={selectedPackages.length === 0}
                />
            </Flexbox>
        </Flexbox>
    );
}

export const AdvancedPackageFilterMenu = ({ options, index }: { options: string[]; index: number }): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
    const handleOpen = React.useCallback(
        (e: React.MouseEvent<Element>) => {
            if (isPresent(anchorEl)) {
                return;
            }
            setAnchorEl(e.currentTarget);
        },
        [anchorEl],
    );
    const handleClose = () => {
        setAnchorEl(undefined);
    };
    const handleOnClick = (e: React.MouseEvent<Element>) => {
        if (isPresent(anchorEl)) {
            handleClose();
        } else {
            handleOpen(e);
        }
    };
    return (
        <>
            <Link variant="caption" onClick={(e) => handleOnClick(e)}>
                <Trans>Advanced package filter</Trans>
            </Link>
            <Menu anchorEl={anchorEl} open={isPresent(anchorEl)} onClose={handleClose} anchorReference="anchorEl">
                <AdvancedPackageFilter options={options} index={index} />
            </Menu>
        </>
    );
};
