import { PCBBaseMaterial } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const baseMaterialKeywords: Record<PCBBaseMaterial, string[]> = {
    [PCBBaseMaterial.FR2]: ['fr\\s*-?\\s*2'],
    [PCBBaseMaterial.FR3]: ['fr\\s*-?\\s*3'],
    [PCBBaseMaterial.FR4]: ['fr\\s*-?\\s*4'],
    [PCBBaseMaterial.CEM1]: ['cem\\s*-?\\s*1'],
    [PCBBaseMaterial.CEM2]: ['cem\\s*-?\\s*2'],
    [PCBBaseMaterial.CEM3]: ['cem3\\s*-?\\s*'],
    [PCBBaseMaterial.POLYIMIDE]: ['polyimide'],
};

export function parseBaseMaterial(str: string): PcbAttribute | undefined {
    for (const [baseMaterial, keywords] of Object.entries(baseMaterialKeywords)) {
        if (keywords.length === 0) {
            continue;
        }
        // this ensures that a keyword like 'HAL' isn't matched as part of a word like 'shall'
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            return {
                attr: 'baseMaterial',
                value: baseMaterial as PCBBaseMaterial,
            };
        }
    }
    return undefined;
}
