import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface ParseManufacturerNameOptions {
    /**
     * Note: because parsers are very much the in the hot path, we need to pass both of these seemingly redundant
     * values to avoid the need to map over the manufacturers array.
     */
    manufacturers: {
        id: string;
        label: string;
        description: string;
    }[];
    manufacturerNames: Set<string>;
}

export const parseManufacturerName: Parser<string, ParseManufacturerNameOptions> = async function (
    cells,
    opts,
    field,
): Promise<ParseResult<string>> {
    return applyParser({
        cells,
        field,
        formatValue: (x) => x,
        parser: parse.manufacturerName({ manufacturerNames: opts.manufacturerNames }),
        alternatives: opts.manufacturers,
    });
};
