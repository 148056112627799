import { formatCurrencyWithoutSymbol } from '@luminovo/commons';
import { Flexbox, Tooltip } from '@luminovo/design-system';
import { styled } from '@mui/material';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, BarGroupHorizontal } from '@visx/shape';
import React from 'react';
import { getDefaultKeyColor } from '../../color/getDefaultColor';
import { palette } from '../../color/palette';
import { BarChartAxisLeft } from '../HorizontalStackedBarChart/components/BarChartAxisLeft';
import { BarChartAxisTop } from '../HorizontalStackedBarChart/components/BarChartAxisTop';
import { Legend } from '../HorizontalStackedBarChart/components/Legend';
import { BarContainers } from '../HorizontalStackedBarChart/components/StackedBarContainer';
import { Datum } from '../HorizontalStackedBarChart/types';
import { clearUnselectedKeys } from '../HorizontalStackedBarChart/util/clearUnselectedKeys';

export type BarGroupHorizontalProps<TKeys extends string, T extends Datum<TKeys>> = {
    width: number;
    data: T[];
    keys: TKeys[];
    formatValue?: (num: number) => string;
    formatKey: (key: TKeys) => string;
    isMissingData?: (datum: T, key: TKeys) => boolean;
    getColor?: (key: TKeys) => string;
    onBarClick?: (data: T) => void;
};

const formatX = (date: string) => date;

function coerceNonNumberTo0(x: number | undefined): number {
    if (x === undefined) {
        return 0;
    }
    if (isNaN(x) || !isFinite(x)) {
        return 0;
    }
    return x;
}
const noop = () => {};

/**
 * IMPORTANT: This component is still a work-in-progress. Please test carefully before using it in production.
 */
export function HorizontalBarChart<TKeys extends string, T extends Datum<TKeys>>({
    width,
    formatKey,
    formatValue = formatCurrencyWithoutSymbol,
    keys,
    data,
    getColor = getDefaultKeyColor(keys),
    onBarClick = noop,
    isMissingData = () => false,
}: BarGroupHorizontalProps<TKeys, T>): JSX.Element {
    const [selectedKeys, setSelectedKeys] = React.useState<TKeys[]>(keys);
    const barHeight = 12;
    const height = data.length * keys.length * barHeight;
    // bounds
    const xMax = width;
    const yMax = height;

    // scales
    const y0Scale = scaleBand({
        domain: data.map((d) => d.label),
        range: [0, yMax],
        padding: 0.1,
    });
    const y1Scale = scaleBand({
        domain: keys,
        range: [0, y0Scale.bandwidth()],
        padding: 0,
    });
    const domainMax = Math.max(
        ...data.map((d) => {
            return selectedKeys.reduce((max, key) => Math.max(max, coerceNonNumberTo0(d[key])), 0);
        }),
    );
    const xScale = scaleLinear<number>({
        domain: [0, domainMax],
        range: [0, xMax],
    });

    const colorScale = scaleOrdinal<string, string>({
        domain: keys.map((x) => x),
        range: keys.map(getColor),
    });

    return (
        <Flexbox flexDirection={'column'} gap="40px" alignItems={'flex-end'} padding="20px">
            <Legend
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                keys={keys}
                formatKey={formatKey}
                getColor={getColor}
            />
            <svg width={width} height={height} style={{ overflow: 'visible' }}>
                <Group top={0} left={0}>
                    <GridColumns
                        scale={xScale}
                        width={width}
                        height={height}
                        stroke={palette.gridLines}
                        strokeDasharray="4 1 2"
                    />
                    <BarGroupHorizontal
                        data={clearUnselectedKeys(data, keys, selectedKeys)}
                        keys={keys}
                        width={xMax}
                        y0={(d) => d.label}
                        y0Scale={y0Scale}
                        y1Scale={y1Scale}
                        xScale={xScale}
                        color={colorScale}
                    >
                        {(barGroups) =>
                            barGroups.map((barGroup, i) => (
                                <Group key={i} top={barGroup.y0}>
                                    {barGroup.bars.map((bar) => {
                                        return (
                                            <Tooltip
                                                key={`${barGroup.index}-${bar.index}-${bar.key}`}
                                                variant="white"
                                                arrow
                                                title={`${formatKey(bar.key)}: ${formatValue(bar.value)}`}
                                                placement="right"
                                            >
                                                <g>
                                                    <StyledBar
                                                        style={{ cursor: 'pointer' }}
                                                        x={bar.x}
                                                        y={bar.y}
                                                        width={bar.width}
                                                        height={bar.height}
                                                        fill={bar.color}
                                                        onClick={() => {
                                                            onBarClick(data[bar.index]!);
                                                        }}
                                                    />
                                                </g>
                                            </Tooltip>
                                        );
                                    })}
                                </Group>
                            ))
                        }
                    </BarGroupHorizontal>
                    <BarContainers
                        barHeight={barHeight * keys.length - 1}
                        data={data}
                        onBarClick={onBarClick}
                        xScale={xScale}
                        yScale={y0Scale}
                    />
                    <BarChartAxisLeft data={data} formatX={formatX} yScale={y0Scale} onBarClick={onBarClick} />

                    <BarChartAxisTop data={data} xScale={xScale} formatValue={formatValue} />
                </Group>
            </svg>
        </Flexbox>
    );
}

const StyledBar = styled(Bar)({
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
});
