import { FormulaErrorDTO } from '@luminovo/http-client';
import type { monaco } from '../../../../../components/MonacoEditor';
import { MarkerSeverity } from '../../../../../components/MonacoEditor';
import { extractFormulaErrorDetails } from './advancedFormulaErrorFunction';
import { isLexerError, isRuntimeError, isSyntaxError } from './advancedFormulaErrorFunctionUtils';

type MarkerSpan = {
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
};

function convertFormulaErrorSpanToMarkerSpan(formulaError: FormulaErrorDTO): MarkerSpan | undefined {
    if (isSyntaxError(formulaError) || isLexerError(formulaError)) {
        const span = formulaError.details.span;
        return {
            startLineNumber: span.start_line,
            startColumn: span.start_column,
            endLineNumber: span.end_line,
            endColumn: span.end_column,
        };
    }

    if (isRuntimeError(formulaError)) {
        const span = formulaError.span;
        return {
            startLineNumber: span.start_line,
            startColumn: span.start_column,
            endLineNumber: span.end_line,
            endColumn: span.end_column,
        };
    }

    return undefined;
}

type MarkerData = monaco.editor.IMarkerData;
export const extractMarkerDataFromFormulaError = (formulaError: FormulaErrorDTO): MarkerData[] => {
    const markerSpan = convertFormulaErrorSpanToMarkerSpan(formulaError);
    const { line, kind } = extractFormulaErrorDetails(formulaError);

    if (!markerSpan) {
        return [];
    }

    const result: MarkerData = {
        ...markerSpan,
        message: kind ? kind : line,
        severity: MarkerSeverity.Error,
    };

    return [result];
};
