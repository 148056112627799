export enum BomSearchField {
    partNumber = 'partNumber',
    partOptions = 'partOptions',
    bomItems = 'bomItems',
    reach = 'reach',
    rohs = 'rohs',
    aecq = 'aecq',
    lifecycle = 'lifecycle',
    availability = 'availability',
    comments = 'comments',
    yearsToEOL = 'yearsToEOL',
}
