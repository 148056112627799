import { t } from '@lingui/macro';
import { FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldSelectCreatable, FieldSelectCreatableProps } from './FieldSelectCreatable';

interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
> extends Omit<FieldControllerProps<FieldSelectCreatableProps<TValue>, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the input field is required.
     */
    required?: boolean;
}

export function FieldSelectCreatableControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
>({ required, validate, ...props }: ControllerProps<TFieldValues, TName, TValue>): JSX.Element {
    return (
        <FieldController Field={FieldSelectCreatable} validate={createValidator({ required, validate })} {...props} />
    );
}

function createValidator({
    required,
    validate,
}: {
    required?: boolean;
    validate?: (x: unknown) => string | undefined;
}) {
    return (x: unknown): string | undefined => {
        if (required && !Boolean(x)) {
            return t`Required`;
        }
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
