import { Trans, t } from '@lingui/macro';
import {
    FieldRadioControlled,
    Flexbox,
    Message,
    SecondaryButton,
    Text,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import {
    ExistingPanelDTO,
    PCBV2,
    PanelPreferenceDTO,
    PerPcbPanelDTO,
    PerSourcingScenarioPanelDTO,
    SourcingScenarioDTO,
    UserType,
} from '@luminovo/http-client';
import { Add, CrisisAlert, InfoRounded } from '@mui/icons-material';
import { Box, FormLabel } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import { route } from '../../../../utils/routes';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { getPanelFormInitialValues } from '../utils/getPanelFormInitialValues';
import { DefaultPanelFormState, ScopeType } from '../utils/types';
import { PanelFormField } from './PanelFormField';

export const DefaultPanelForm = ({
    rfqId,
    assemblyId,
    isEditing,
    sourcingScenarios,
    panelPreferences,
    perScenarioSavedData,
    perPcbSavedData,
    existingPanelSavedData,
    currentlyDisplayedPanelField,
    setCurrentlyDisplayedPanelField,
    pcb,
}: {
    rfqId: string;
    assemblyId: string;
    isEditing: boolean;
    sourcingScenarios: SourcingScenarioDTO[];
    panelPreferences: PanelPreferenceDTO[];
    perScenarioSavedData: PerSourcingScenarioPanelDTO[];
    perPcbSavedData: PerPcbPanelDTO[];
    existingPanelSavedData: ExistingPanelDTO[];
    currentlyDisplayedPanelField: number;
    setCurrentlyDisplayedPanelField: (index: number) => void;
    pcb: PCBV2;
}) => {
    const isCustomer = useUserType() === UserType.Customer;
    const { control, setValue } = useFormContext<DefaultPanelFormState>();

    const { fields } = useFieldArray({
        control,
        name: 'data',
    });
    const scopeValue = useWatch({
        control,
        name: 'scope',
    });

    const handleScopeChange = (value: ScopeType) => {
        const formValue = getPanelFormInitialValues({
            sourcingScenarios,
            scope: value,
            perScenarioSavedData,
            perPcbSavedData,
            existingPanelSavedData,
        });

        if (formValue.scope === 'ExistingPanel') {
            return;
        }

        setValue('data', formValue.data);
        setValue('scope', formValue.scope);
        setCurrentlyDisplayedPanelField(0);
    };

    return (
        <>
            {perPcbSavedData.length === 0 && perScenarioSavedData.length === 0 ? (
                <Message
                    attention="low"
                    variant="yellow"
                    title={t`Warning`}
                    message={t`Panel configuration not found! Please configure a new panel. Go to Sourcing to see panel
                            information of current offer.`}
                    size={'large'}
                />
            ) : (
                <div />
            )}
            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection label={t`Scope`}>
                    {isEditing ? (
                        <Flexbox flexDirection={'column'} gap={'12px'}>
                            <FormLabel
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                }}
                            >
                                <FieldRadioControlled
                                    name="scope"
                                    control={control}
                                    FieldProps={{
                                        fieldValue: 'PerSourcingScenario',
                                        size: 'small',
                                        style: { paddingBlockEnd: '4px' },
                                        onClick: () => handleScopeChange('PerSourcingScenario'),
                                    }}
                                />
                                <Text variant="h5">
                                    <Trans>Per sourcing scenario</Trans>
                                </Text>
                                <Tooltip
                                    title={t`Configure a unique panel for each sourcing scenario`}
                                    placement="bottom-start"
                                >
                                    <InfoRounded
                                        style={{
                                            cursor: 'pointer',
                                            color: colorSystem.neutral[5],
                                            fontSize: '16px',
                                            marginLeft: '-4px',
                                        }}
                                    />
                                </Tooltip>
                            </FormLabel>

                            <FormLabel
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                }}
                            >
                                <FieldRadioControlled
                                    name="scope"
                                    control={control}
                                    FieldProps={{
                                        fieldValue: 'PerPcb',
                                        size: 'small',
                                        style: { paddingBlockEnd: '4px' },
                                        onClick: () => handleScopeChange('PerPcb'),
                                    }}
                                />
                                <Text variant="h5">
                                    <Trans>All sourcing scenarios</Trans>
                                </Text>
                                <Tooltip
                                    title={t`Single configuration will be applied to all sourcing scenarios`}
                                    placement="bottom-start"
                                >
                                    <InfoRounded
                                        style={{
                                            cursor: 'pointer',
                                            color: colorSystem.neutral[5],
                                            fontSize: '16px',
                                            marginLeft: '-4px',
                                        }}
                                    />
                                </Tooltip>
                            </FormLabel>
                        </Flexbox>
                    ) : (
                        <Text>{scopeValue === 'PerSourcingScenario' ? 'Per sourcing scenario' : 'Universal'}</Text>
                    )}
                </CollapsibleSection>
            </Box>

            {scopeValue === 'PerSourcingScenario' && (
                <>
                    {fields.length === 0 ? (
                        <Box
                            style={{
                                // background: colorSystem.neutral.white,
                                borderRadius: '8px',
                                padding: '16px',
                            }}
                        >
                            <Flexbox alignItems={'center'} flexDirection={'column'} gap={'8px'}>
                                <CrisisAlert
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        color: colorSystem.neutral[5],
                                    }}
                                />
                                <Text variant={'h4'}>
                                    <Trans>No sourcing scenario created</Trans>
                                </Text>
                                {isCustomer === false && (
                                    <SecondaryButton
                                        size="medium"
                                        startIcon={<Add />}
                                        href={route('/rfqs/:rfqId/sourcing/scenarios/new', { rfqId })}
                                        style={{ marginBlockStart: '8px' }}
                                    >
                                        <Trans>Add sourcing scenario</Trans>
                                    </SecondaryButton>
                                )}
                            </Flexbox>
                        </Box>
                    ) : (
                        <>
                            {fields.map((field, index) => (
                                <PanelFormField
                                    rfqId={rfqId}
                                    pcb={pcb}
                                    key={field.id}
                                    assemblyId={assemblyId}
                                    isEditing={isEditing}
                                    index={index}
                                    sourcingScenarios={sourcingScenarios}
                                    panelPreferences={panelPreferences}
                                    currentlyDisplayedPanelField={currentlyDisplayedPanelField}
                                    setCurrentlyDisplayedPanelField={setCurrentlyDisplayedPanelField}
                                />
                            ))}
                        </>
                    )}
                </>
            )}

            {scopeValue === 'PerPcb' && (
                <PanelFormField
                    rfqId={rfqId}
                    pcb={pcb}
                    assemblyId={assemblyId}
                    index={0}
                    isEditing={isEditing}
                    panelPreferences={panelPreferences}
                    sourcingScenarios={sourcingScenarios}
                    currentlyDisplayedPanelField={currentlyDisplayedPanelField}
                    setCurrentlyDisplayedPanelField={setCurrentlyDisplayedPanelField}
                />
            )}
        </>
    );
};
