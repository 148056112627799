import { colorSystem } from '@luminovo/design-system';

const colorDefault = {
    0: '#7061D1',
    1: '#8D7DF3',
    2: '#C7C1FA',
    3: '#E3DEFF',
};

const colorOther = {
    0: colorSystem.neutral[5],
};

/**
 * WARNING: these colors haven't stabilized yet. They can change at any moment in time, so use with caution.
 */
export const palette = {
    error: {
        high: colorSystem.red[5],
        medium: colorSystem.red[4],
    },
    ok: {
        high: colorSystem.green[5],
        medium: colorSystem.green[4],
    },
    default: colorDefault,
    other: colorOther,
    diff: {
        baseline: colorOther[0],
        current: colorDefault[0],
    },
    /**
     * The color of both horizontal and vertical grid lines
     */
    gridLines: colorSystem.neutral[3],
};
