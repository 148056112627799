import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    FieldNumericController,
    Flexbox,
    FormItem,
    TertiaryIconButton,
    Text,
} from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectController } from '../../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { useGlobalCurrency } from '../../../../../resources/organizationSettings/currencySettingsHandler';
import { displayCurrencySymbol } from '../../../../../utils/currencyUtils';
import { CalculationTemplateFormInput, costTypePublicTranslations } from '../utils/types';
import { generateAdditionalFieldsFromField } from './additionalFormInputControls';
import { assertIsFormulaCost, formulaRules, nameRule, validateAmount } from './defaults';
import { LockToggle, ProfitAndDiscountFields } from './LockToggle';
import { UpsertFormula } from './UpsertFormula';

export const SingleCost = ({
    field,
    removeField,
}: {
    field: ProfitAndDiscountFields;
    removeField: () => void;
}): JSX.Element => {
    const { control, watch, trigger } = useFormContext<CalculationTemplateFormInput>();
    const { preferredCurrency } = useGlobalCurrency();

    return (
        <Flexbox justifyContent={'space-between'} width={'100%'} alignItems={'flex-start'}>
            <Flexbox gap={'8px'} alignItems="top">
                <Text variant="h4" color={colorSystem.neutral[8]} style={{ width: 320, marginTop: 8 }}>
                    {field.includes('discount') ? <Trans>Discount</Trans> : <Trans>Profit</Trans>}
                </Text>
                <FormItem>
                    <SelectController
                        name={`${field}.value.unitCost.type`}
                        control={control}
                        translations={costTypePublicTranslations}
                        SelectProps={{ style: { width: 180 } }}
                        rules={nameRule()}
                    />
                </FormItem>
                {watch(`${field}.value.unitCost.type`) === 'fixed' && (
                    <FieldNumericController
                        name={`${field}.value.unitCost.value.cost.amount`}
                        control={control}
                        shouldUnregister
                        representation="string"
                        FieldProps={{
                            style: { width: 180 },
                            InputProps: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {<Text>{displayCurrencySymbol(preferredCurrency)}</Text>}
                                    </InputAdornment>
                                ),
                            },
                        }}
                        required
                        min={0}
                        validate={validateAmount}
                    />
                )}
                {watch(`${field}.value.unitCost.type`) === 'percentage' && (
                    <FieldNumericController
                        name={`${field}.value.unitCost.value.percentage`}
                        control={control}
                        shouldUnregister
                        representation="string"
                        FieldProps={{
                            style: { width: 180 },
                            InputProps: {
                                endAdornment: <InputAdornment position="end">{<Text>%</Text>}</InputAdornment>,
                            },
                        }}
                        required
                        min={0}
                        validate={validateAmount}
                    />
                )}
                {watch(`${field}.value.unitCost.type`) === 'formula' && (
                    <Controller
                        name={`${field}.value.unitCost`}
                        control={control}
                        shouldUnregister={true}
                        rules={formulaRules}
                        render={({ field: _field, fieldState }): JSX.Element => {
                            return (
                                <UpsertFormula
                                    formulaCost={assertIsFormulaCost(_field.value)}
                                    onSubmit={(dialogValue) => {
                                        _field.onChange(dialogValue);
                                        // this is required for the form to revalidate on change.
                                        trigger();
                                    }}
                                    errorMessage={fieldState.error?.message}
                                    additionalFormulaInputControls={generateAdditionalFieldsFromField(field)}
                                />
                            );
                        }}
                    />
                )}
            </Flexbox>
            <Flexbox alignItems="center" gap={8}>
                <LockToggle name={`${field}.isLocked`} />
                <Tooltip title={t`Delete`} placement="top" arrow>
                    <TertiaryIconButton size="medium" onClick={removeField}>
                        <Delete fontSize="inherit" />
                    </TertiaryIconButton>
                </Tooltip>
            </Flexbox>
        </Flexbox>
    );
};
