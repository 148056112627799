/* eslint-disable camelcase */
import { SiteDTO } from '@luminovo/http-client';
import { SupplierTermsDTO } from '@luminovo/http-client/src/resources/supplierTerms';
import { useHttpQuery } from '../http/useHttpQuery';
import { useSites } from '../organizationSettings/sitesHandler';

export function useSupplierTermsFromSupplier(supplierId: string, options: { refetchOnWindowFocus?: boolean } = {}) {
    return useHttpQuery(
        'GET /supplier/:supplierId/terms',
        {
            pathParams: { supplierId: supplierId },
        },
        {
            select: (res) => res.data,
            enabled: Boolean(supplierId),
            ...options,
        },
    );
}

export type SupplierTermsWithSite = Omit<SupplierTermsDTO, 'site'> & { site?: SiteDTO };

export function useSupplierTermsFromSupplierWithSite(supplierId: string): SupplierTermsWithSite[] | undefined {
    const { data: supplierTerms } = useSupplierTermsFromSupplier(supplierId);
    const { data: sites = [] } = useSites();
    return supplierTerms?.map((term) => {
        return {
            ...term,
            site: sites.find((site) => site.id === term.site),
        };
    });
}
