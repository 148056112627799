import { t, Trans } from '@lingui/macro';
import { FormItem, SecondaryButton } from '@luminovo/design-system';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldController } from '../../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { deprecatedYupResolver } from '../../../../../utils/deprecated_yupResolver';
import { createAssemblyDetailsNotesSchema } from '../../../manufacturingSchemas';
import { NotesFormState } from './editNotesFormFunctions';

export function EditNotesForm({
    notes,
    onClose,
    onSubmit,
}: {
    notes: string | undefined;
    onClose: () => void;
    onSubmit: (form: NotesFormState) => void;
}) {
    const useFormReturn = useForm<NotesFormState>({
        mode: 'onChange',
        defaultValues: { notes: notes },
        resolver: deprecatedYupResolver(createAssemblyDetailsNotesSchema()),
    });
    const { control } = useFormReturn;
    const handleSubmit = useFormReturn.handleSubmit((form) => onSubmit(form));
    return (
        <FormProvider {...useFormReturn}>
            <form onSubmit={handleSubmit}>
                <FormItem label={t`Notes`}>
                    <TextFieldController
                        name={'notes'}
                        control={control}
                        TextFieldProps={{
                            multiline: true,
                            minRows: 2,
                            inputProps: { style: { lineHeight: '20px' } },
                        }}
                    />
                </FormItem>
                <Box mt={4} mb={4}>
                    <Grid container justifyContent={'space-between'}>
                        <SecondaryButton onClick={onClose}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <SubmitButton />
                    </Grid>
                </Box>
            </form>
        </FormProvider>
    );
}
