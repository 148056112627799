type Tokens =
    | { type: 'mention'; label: string; id: string }
    | { type: 'text'; content: string }
    | { type: 'quote'; content: string }
    | { type: 'link'; content: string };

const tokenMatchers: Array<{ regex: RegExp; parser: (match: RegExpMatchArray) => Tokens }> = [
    {
        regex: /^>\s*(.*)(\n|$)/,
        parser: (match) => {
            return { type: 'quote', content: match[1] };
        },
    },
    {
        regex: /^@\[(?<name>[^\]]+)\]\((?<id>[^)]+)\)/,
        parser: (match) => {
            const { name, id } = match.groups ?? {};
            return { type: 'mention', label: name, id };
        },
    },
    {
        regex: /^\s+/,
        parser: (match) => {
            return { type: 'text', content: match[0] };
        },
    },
    {
        regex: /^\S/,
        parser: (match) => {
            return { type: 'text', content: match[0] };
        },
    },
];

function tokenize(str: string): Tokens[] {
    const tokens: Tokens[] = [];
    let i = 0;
    while (i < str.length) {
        let didMatch = false;
        const slice = str.slice(i);
        for (const matcher of tokenMatchers) {
            const match = slice.match(matcher.regex);

            if (match) {
                const token = matcher.parser(match);
                tokens.push(token);
                i += match[0].length;
                didMatch = true;
                break;
            }
        }

        if (!didMatch) {
            throw Error(`Unknown token at ${i}:\n  ${slice}\n  ^`);
        }
    }
    return tokens;
}

function mergeSequentialTextTokens(tokens: Tokens[]): Tokens[] {
    return tokens.reduce((reduction: Tokens[], token: Tokens) => {
        if (reduction.length === 0) {
            return [token];
        }
        const head = reduction[reduction.length - 1];
        if (head.type === 'text' && token.type === 'text') {
            reduction.pop();
            reduction.push({ type: 'text', content: head.content + token.content });
        } else {
            reduction.push(token);
        }
        return reduction;
    }, []);
}

/**
 * A small parser for a tiny subset of Markdown.
 *
 * Supported features:
 *
 * 1. Use `>` for quotes.
 * 2. Use `@[label](id)` for mentions.
 * 3. Everything else is text.
 */
export function parseCommentString(str: string): Tokens[] {
    const tokens = tokenize(str);

    return mergeSequentialTextTokens(tokens);
}
