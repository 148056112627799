import { t } from '@lingui/macro';
import { FormItem, FormSection } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { SitesSelect } from '../../../../components/SitesSelect';
import {
    RadioGroupController,
    SelectController,
    TextFieldController,
} from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { expenseLevelEnumPublicTranslations } from '../../../../resources/expenses/expensesBackendTypes';
import { capitalizeFirstLetter } from '../../../../utils/stringFunctions';
import {
    categorySelectionPublicTranslations,
    processPublicTranslations,
} from '../../shared/sharedManufacturingBackendTypes';
import { AddExpenseFormInputs } from '../utils/AddExpenseFormTypes';

export function ExpenseSection({ title }: { title: string }): JSX.Element {
    const { control } = useFormContext<AddExpenseFormInputs>();
    return (
        <FormSection title={title} style={{ width: '50%' }}>
            <FormItem label={t`Name` + '*'}>
                <TextFieldController
                    name={'expense.name'}
                    control={control}
                    TextFieldProps={{
                        placeholder: t`e.g. Shipping carton`,
                    }}
                />
            </FormItem>
            <FormItem label={t`Internal expense number`}>
                <TextFieldController name={'expense.internalExpenseNumber'} control={control} />
            </FormItem>
            <FormItem label={t`Site`}>
                <SitesSelect name={'expense.siteId'} control={control} />
            </FormItem>
            <FormItem label={capitalizeFirstLetter(t`process`) + '*'}>
                <SelectController name={'expense.process'} control={control} translations={processPublicTranslations} />
            </FormItem>
            <FormItem label={capitalizeFirstLetter(t`category`) + '*'}>
                <SelectController
                    name={'expense.category'}
                    control={control}
                    translations={categorySelectionPublicTranslations}
                />
            </FormItem>
            <FormItem label={t`Level` + '*'}>
                <RadioGroupController
                    name={'expense.level'}
                    control={control}
                    options={expenseLevelEnumPublicTranslations}
                />
            </FormItem>
            <FormItem label={t`Description`}>
                <TextFieldController
                    name={'expense.description'}
                    TextFieldProps={{ multiline: true, rows: 4 }}
                    control={control}
                />
            </FormItem>
        </FormSection>
    );
}
