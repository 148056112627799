import { DriverIdDTO, DriverStatusDetailsDTO } from '@luminovo/http-client';
import { convertDriverIdDtoToDriverIdString } from '../selectors';

export const findStatusOfDriver = (
    driverId: DriverIdDTO,
    driverStatuses: DriverStatusDetailsDTO[],
): DriverStatusDetailsDTO | undefined => {
    if (driverStatuses.length === 0) {
        return undefined;
    }

    // this one holds for every driver, as we are unable to parse the formula properly to even get the drivers!
    const invalidFormulaStatus = driverStatuses.find((status) => status.type === 'InvalidFormula');

    if (invalidFormulaStatus) {
        return invalidFormulaStatus;
    }

    const statusForDriver = driverStatuses.find(
        (status) =>
            status.type === 'Status' &&
            convertDriverIdDtoToDriverIdString(status.details.driver_id) ===
                convertDriverIdDtoToDriverIdString(driverId),
    );

    return statusForDriver ? statusForDriver : undefined;
};
