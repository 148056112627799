import { t } from '@lingui/macro';
import { assertUnreachable, formatDecimal, formatMonetaryValue, transEnum } from '@luminovo/commons';
import { Condition, Operator, OperatorField, OperatorId } from '@luminovo/http-client';
import { formatPackage, formatPackaging } from '../../../formatters';
import { operatorFieldTranslations, operatorIdTranslations } from '../../../i18n';
import { NodeFormula } from '../types';

export function convertConditionToFormulaNodes(condition: Condition): Array<[NodeFormula, ...NodeFormula[]]> {
    return condition.args.map((arg, index) => {
        return [
            {
                type: 'text',
                value: index === 0 ? t`Where` : transEnum(condition.op, operatorIdTranslations),
            },
            ...flattenOperator(arg),
        ];
    });
}

function flattenOperator(arg: Operator): NodeFormula[] {
    return [convertOperatorFieldToNode(arg.lhs), convertOperatorIdToNode(arg.op), ...convertOperatorValueToNode(arg)];
}

function convertOperatorIdToNode(arg: OperatorId): NodeFormula {
    return {
        type: 'text',
        value: transEnum(arg, operatorIdTranslations),
    };
}

function convertOperatorFieldToNode(field: OperatorField): NodeFormula {
    return { type: 'field', value: transEnum(field, operatorFieldTranslations) };
}

function convertOperatorValueToNode(arg: Operator): NodeFormula[] {
    if (arg.lhs === 'AggregatedQuantity') {
        return [{ type: 'value', value: formatDecimal(arg.rhs) }];
    }
    if (arg.lhs === 'OrderSize') {
        return [{ type: 'value', value: formatDecimal(arg.rhs) }];
    }
    if (arg.lhs === 'Package') {
        if (arg.rhs.length === 1) {
            return [{ type: 'value', value: formatPackage(arg.rhs[0]) }];
        }

        const values: NodeFormula[] = arg.rhs.flatMap((pkg, i) => {
            if (i === 0) {
                return [
                    {
                        type: 'value',
                        value: formatPackage(pkg),
                    },
                ];
            }
            return [
                {
                    type: 'text',
                    value: ', ',
                },
                {
                    type: 'value',
                    value: formatPackage(pkg),
                },
            ];
        });

        return [
            {
                type: 'text',
                value: '(',
            },
            ...values,
            {
                type: 'text',
                value: ')',
            },
        ];
    }
    if (arg.lhs === 'Packaging') {
        return [{ type: 'value', value: formatPackaging(arg.rhs) }];
    }
    if (arg.lhs === 'UnitPrice') {
        return [{ type: 'value', value: formatMonetaryValue(arg.rhs, 'unit-price') }];
    }
    if (arg.lhs === 'Mounting') {
        return [{ type: 'value', value: arg.rhs.name }];
    }
    if (arg.lhs === 'PartType') {
        return [{ type: 'value', value: arg.rhs }];
    }
    if (arg.lhs === 'PartCategory') {
        return [{ type: 'value', value: arg.rhs.name }];
    }
    assertUnreachable(arg);
}
