import { getThousandSeparator, NumberSeparator } from '../determineDecimalSeparator';
import { Parser } from '../types';
import { getIntegerRegexString, replaceThousandSeparator } from './parseNumber';
import { pieceKeywords } from './parseUnit';

const moqKeywords = [
    'MOQ',
    'Min.?',
    'Mindestbestellmenge',
    'Mindest- bestellmeng e',
    'Mindestauftragsmenge',
    'Preisstaff?el',
    'Minimum order quantity',
];
export const moqKeyRegex = new RegExp(`(?:\\s|^)(?:${moqKeywords.join('|')})(?:\\s|:|=|$)`, 'i');

const moqKeyValueWithRangeRegex = new RegExp(moqKeyRegex.source + '\\s?=?(\\d+)(?:\\s?-\\s?\\d+)', 'i');
const moqWithoutKeyRegex = new RegExp(`(?:\\s|^)\\s?(\\d+)(?:\\s?-\\s?\\d+)\\s?(?:${pieceKeywords.join('|')})`, 'i');

export const createParseMoq = (decimalSeparator: NumberSeparator): Parser => {
    const thousandSeparator = getThousandSeparator(decimalSeparator);
    const moqKeyValueRegex = new RegExp(moqKeyRegex.source + '.*?' + getIntegerRegexString(thousandSeparator), 'i');

    return (str: string) => {
        const matchWithKeyAndRange = moqKeyValueWithRangeRegex.exec(str);
        if (matchWithKeyAndRange) {
            const [, moq] = matchWithKeyAndRange;
            const moqNumber = parseInt(replaceThousandSeparator(moq, thousandSeparator));
            if (moqNumber) {
                return { attr: 'moq', value: moqNumber };
            }
        }
        const matchWithKey = moqKeyValueRegex.exec(str);
        if (matchWithKey) {
            const [, moq, thousand] = matchWithKey;
            const moqNumber = parseInt(replaceThousandSeparator(moq, thousandSeparator));
            if (moqNumber) {
                return { attr: 'moq', value: thousand ? moqNumber * 1000 : moqNumber };
            }
        }
        const matchWithoutKey = moqWithoutKeyRegex.exec(str);
        if (matchWithoutKey) {
            const [, moq] = matchWithoutKey;
            const moqNumber = parseInt(moq);
            if (moqNumber) {
                return { attr: 'moq', value: moqNumber };
            }
        }
        return undefined;
    };
};
