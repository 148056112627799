import { RfqStatus, WorkflowType } from '@luminovo/http-client';

export const rfqStatusesThatAreNonEditable = [
    RfqStatus.QuotationAvailable,
    RfqStatus.NoOrder,
    RfqStatus.NoQuotation,
    RfqStatus.OrderConfirmed,
    RfqStatus.OrderInProduction,
    RfqStatus.OrderShipped,
    RfqStatus.OrderInProcurement,
];

// NOTE: keep this in sync with domain_core::rfq::Rfq::is_editable
export const isRfqEditable = ({
    status,
    isCustomer,
    isArchived,
    orderWorkflow,
    hasAccessToCustomerPortal,
}: {
    status: RfqStatus | undefined;
    isCustomer: boolean;
    isArchived: boolean | undefined;
    orderWorkflow: WorkflowType | undefined;
    hasAccessToCustomerPortal: boolean;
}): boolean => {
    // Cannot edit an RfQ whose status is unknown
    if (status === undefined || isArchived === undefined) {
        return false;
    }
    // cannot edit an rfq if the user is a customer and does not have access to the customer portal
    if (isCustomer && !hasAccessToCustomerPortal) {
        return false;
    }
    // cannot edit an archive rfq
    if (isArchived) {
        return false;
    }

    // A customer edit an rfq if it is in draft or if it is in automatic workflow and the status is quotation available
    if (isCustomer) {
        return (
            status === RfqStatus.RequestInDraft ||
            (orderWorkflow === 'Automatic' && status === RfqStatus.QuotationAvailable)
        );
    } else {
        // An EMS user can edit an rfq if it is not in the list of non editable statuses
        return !rfqStatusesThatAreNonEditable.includes(status);
    }
};
