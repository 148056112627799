import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { FieldSelect, FieldSelectProps } from '@luminovo/design-system';
import { AssemblyIndustry } from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '../../i18n';

type FieldIndustrySelectProps = Omit<FieldSelectProps<AssemblyIndustry | ''>, 'options'>;

export const FieldIndustrySelect = (props: FieldIndustrySelectProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const industries = Object.keys(assemblyIndustryTranslations) as AssemblyIndustry[];

    return (
        <FieldSelect
            getOptionLabel={(option) => (Boolean(option) ? transEnum(option, assemblyIndustryTranslations) : '')}
            options={industries}
            placeholder={t`Please select an industry`}
            {...props}
        />
    );
};
