import { t } from '@lingui/macro';
import { TemplateInstanceSummaryDTO } from '@luminovo/http-client';
import { HeaderOrDataRow, Position, TemplateInstanceRowHeader, TemplateInstanceState } from './types';

export const getPosition = <T>(index: number, items: T[], getRank: (item: T) => number): Position => {
    if (index === 0 && items.length === 1) {
        return { type: 'first-and-last' };
    } else if (index === 0) {
        return { type: 'first', nextRank: getRank(items[index + 1]) };
    } else if (index === items.length - 1) {
        return {
            type: 'last',
            previousRank: getRank(items[index - 1]),
        };
    }

    return { type: 'middle', nextRank: getRank(items[index + 1]), previousRank: getRank(items[index - 1]) };
};

function createDataRows<TRowData, TSummary>({
    summaries,
    getRank,
    createRowData,
}: {
    summaries: TSummary[];
    getRank: (summary: TSummary) => number;
    createRowData: (summary: TSummary, position: Position) => TRowData;
}): HeaderOrDataRow<TemplateInstanceRowHeader, TRowData>[] {
    const rows: HeaderOrDataRow<TemplateInstanceRowHeader, TRowData>[] = [];
    const sortedSummaries = summaries.sort((a, b) => getRank(a) - getRank(b));

    sortedSummaries.forEach((summary, index) => {
        const position = getPosition(index, sortedSummaries, getRank);
        const dataRow: TRowData = createRowData(summary, position);

        rows.push({ type: 'data', data: dataRow });
    });

    return rows;
}

export function groupByTemplateInstances<TRowData, TSummary>({
    summaries,
    templateInstances,
    getInstanceId,
    getRank,
    createRowData,
    context,
}: {
    summaries: TSummary[];
    templateInstances: TemplateInstanceSummaryDTO[];
    getInstanceId: (summary: TSummary) => string | undefined;
    getRank: (summary: TSummary) => number;
    createRowData: (summary: TSummary, position: Position) => TRowData;
    context: TemplateInstanceState;
}): HeaderOrDataRow<TemplateInstanceRowHeader, TRowData>[] {
    // We create a map of template instance ids to their summaries (activitiy/expense configs)
    const templateInstanceGroups: Record<string, TSummary[]> = {};

    templateInstanceGroups['directlyAdded'] = [];
    for (const templateInstance of templateInstances) {
        templateInstanceGroups[templateInstance.id] = [];
    }

    for (const summary of summaries) {
        const templateInstanceId = getInstanceId(summary);
        let id = templateInstanceId ?? 'directlyAdded';
        templateInstanceGroups[id].push(summary);
    }

    let rows: HeaderOrDataRow<TemplateInstanceRowHeader, TRowData>[] = [];
    const sortedTemplateInstances: TemplateInstanceSummaryDTO[] = templateInstances.sort((a, b) => a.rank - b.rank);

    // We iterate over the template instances and create a header row for each one
    const templatesCount = sortedTemplateInstances.length;
    sortedTemplateInstances.forEach((templateInstance, index) => {
        const headerRow: TemplateInstanceRowHeader = {
            index,
            templateName: templateInstance.template_name,
            templateInstanceId: templateInstance.id,
            rank: templateInstance.rank,
            position: getPosition(index, sortedTemplateInstances, (instance) => instance.rank),
        };
        rows.push({ type: 'header', data: headerRow });

        if (context.isCollapsed(templateInstance.id)) {
            return;
        }

        // For each template instance, we iterate over its summaries and create a data row for each one
        const summaries = templateInstanceGroups[templateInstance.id];
        const dataRows = createDataRows({ summaries, getRank, createRowData });
        rows = rows.concat(dataRows);
    });

    // In the end we add header and data rows for all directly added summaries
    const directlyAddedSummaries = templateInstanceGroups['directlyAdded'];
    if (directlyAddedSummaries.length === 0) {
        return rows;
    }

    const headerRow: TemplateInstanceRowHeader = {
        index: templatesCount,
        templateName: t`Miscellaneous`,
        templateInstanceId: { type: 'directly-added' },
        rank: 'directly-added',
        position: { type: 'first-and-last' },
    };
    rows.push({ type: 'header', data: headerRow });

    if (context.isCollapsed({ type: 'directly-added' })) {
        return rows;
    }

    const dataRows = createDataRows({ summaries: directlyAddedSummaries, getRank, createRowData });
    return rows.concat(dataRows);
}
