import React from 'react';
import { useDescendants } from '../../../resources/assembly/assemblyHandler';
import { useAggregateDesignItemsToBomItems } from './utils/designItemAggregation';

// parentId can be both assemblyId or rfqId
export function useBOMItems({ parentId, rfqId }: { parentId: string; rfqId?: string }) {
    const {
        data: descendantsData,
        isLoading: isDescendantsLoading,
        isFetched: isDescendantsFetched,
    } = useDescendants(parentId);

    const assemblies = React.useMemo(() => {
        const data = descendantsData?.data;
        if (data) {
            return data.assemblies.concat(data.parent);
        }
    }, [descendantsData]);

    const showPartAlternatives = descendantsData?.data.can_see_part_alternatives ?? false;

    const {
        bomItems,
        isLoading: isAggregationDataLoading,
        isFetched: isAggregationDataFetched,
    } = useAggregateDesignItemsToBomItems({
        rfqId,
        assemblies,
        designItems: descendantsData?.data.design_items,
        showPartAlternatives,
        currentParentId: parentId,
    });

    const isLoading = isDescendantsLoading || isAggregationDataLoading;
    const isFetched = isDescendantsFetched || isAggregationDataFetched;

    return {
        bomItems,
        descendantsData: descendantsData?.data,
        isLoading,
        isFetched,
    };
}
