import { t } from '@lingui/macro';
import { VarDef } from '@luminovo/rich-text-editor';

export enum VarId {
    quoteNumber = 'quote_request.number',
    supplierPortalLink = 'quote_request.supplier_portal_link',
    quoteSendDate = 'quote_request.send_date',
    dueDate = 'quote_request.due_date',
    rfqName = 'rfq.name',
    rfqNumber = 'rfq.number',
    customerName = 'customer.name',
    senderOrgName = 'sender.organization.name',
    senderFirstName = 'sender.first_name',
    senderLastName = 'sender.last_name',
    recipientFirstName = 'recipient.first_name',
    recipientLastName = 'recipient.last_name',
    supplierName = 'supplier.name',
    notes = 'quote_request.notes',
}

export function createVars(): VarDef[] {
    return [
        {
            name: VarId.quoteNumber,
            description: t`A unique identifier for the quote request`,
            example: '12345',
        },
        {
            name: VarId.quoteSendDate,
            description: t`The date the quote request was sent`,
            example: '2024-01-03',
        },
        {
            name: VarId.supplierPortalLink,
            description: t`A link to the quote portal`,
            example: 'https://example.com',
        },
        {
            name: VarId.dueDate,
            description: t`The due date for the quote`,
            example: '2022-12-31',
        },
        {
            name: VarId.senderOrgName,
            description: t`The name of your organization`,
            example: 'ACME Corp',
        },
        {
            name: VarId.rfqName,
            description: t`The name of the RfQ`,
            example: 'Project X',
        },
        {
            name: VarId.rfqNumber,
            description: t`The number, or identifier of the RfQ`,
            example: '09876',
        },
        {
            name: VarId.customerName,
            description: t`The name of the customer's organization`,
            example: 'ACME Corp',
        },
        {
            name: VarId.senderFirstName,
            description: t`The first name of the sender`,
            example: 'John',
        },
        {
            name: VarId.senderLastName,
            description: t`The last name of the sender`,
            example: 'Doe',
        },
        {
            name: VarId.recipientFirstName,
            description: t`The first name of the recipient`,
            example: 'Jane',
        },
        {
            name: VarId.recipientLastName,
            description: t`The last name of the recipient`,
            example: 'Doe',
        },
        {
            name: VarId.supplierName,
            description: t`The name of the supplier`,
            example: 'Part Supplier Corp.',
        },
        {
            name: VarId.notes,
            description: t`Notes for a quote request`,
            example: `Application: Medical device\nStart of production: 2025\nEnd of production: 2028`,
        },
    ].sort((a, b) => a.name.localeCompare(b.name));
}
