/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface MouserCredentialsDTO extends r.Static<typeof MouserCredentialsDTORuntype> {}
export const MouserCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface MouserCredentialsInputDTO extends r.Static<typeof MouserCredentialsInputDTORuntype> {}
export const MouserCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface MouserResponseBodyDTO extends r.Static<typeof MouserResponseBodyDTORuntype> {}
export const MouserResponseBodyDTORuntype = r.Record({
    data: MouserCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
