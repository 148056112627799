import { t } from '@lingui/macro';
import { OtsFullPart } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { ErrorText } from '../../../../components/Spinners';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { useAlert } from '../../../../resources/alerts/alertHandlers';
import { AlertCreationForm, AlertType } from '../../../../resources/alerts/alertTypes';
import { convertAlertToFormValues, convertCreateAlertFormToBody } from '../../../../resources/alerts/utils';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useOtsFullPart } from '../../../../resources/part/partHandler';
import { AlertForm } from './AlertForm';
interface EditAlertDataContainerProps {
    alertId: string;
    onClose: () => void;
}

export const EditAlertFormContainer = ({ alertId, onClose }: EditAlertDataContainerProps) => {
    const { data: alertData, isLoading: isAlertLoading, isError: isErrorLoadingAlert } = useAlert(alertId);

    const {
        data: otsPart,
        isLoading: isPartLoading,
        isError: isErrorLoadingPart,
    } = useOtsFullPart({
        partOptionId: alertData?.part ?? '',
        rfqContext: { type: 'OutsideRfQ' },
    });

    if (isPartLoading || isAlertLoading) {
        return <CircularProgress />;
    }

    const partLoadingFailed = isErrorLoadingPart || otsPart === undefined;
    const alertLoadingFailed = isErrorLoadingAlert || alertData === undefined;
    if (partLoadingFailed || alertLoadingFailed) {
        return <ErrorText />;
    }

    return <EditAlertForm alert={alertData} part={otsPart} onClose={onClose} />;
};

interface EditAlertFormContainerProps {
    alert: AlertType;
    part: OtsFullPart;
    onClose: () => void;
}

const EditAlertForm = ({ alert, part: otsPart, onClose }: EditAlertFormContainerProps) => {
    const { mutateAsync } = useHttpMutation('PATCH /alerts/stock-level/:id', {
        snackbarMessage: t`Alert successfully updated`,
        onSuccess: onClose,
    });

    const onSubmit = async (formValues: AlertCreationForm) => {
        return mutateAsync({
            pathParams: { id: alert.id },
            requestBody: convertCreateAlertFormToBody(formValues),
        });
    };

    return (
        <FormContainer defaultValues={convertAlertToFormValues(alert)} onSubmit={onSubmit}>
            <AlertForm part={otsPart} onClose={onClose} />
        </FormContainer>
    );
};
