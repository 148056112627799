import { Currency, isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { AssemblyIndustry, AssemblyTypePublic, CustomerDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import React, { useContext, useState } from 'react';
import { VolumeEstimateFormState } from '../../components/RfQ/VolumeEstimateFormItem';
import { ContributorOption, convertToContributorOption } from '../../components/RfQ/components/ContributorsFormUtils';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { useGlobalCurrency } from '../../resources/organizationSettings/currencySettingsHandler';
import { assertUnreachable } from '../../utils/typingUtils';
import { useDefaultAssemblyType } from './hooks/useDefaultAssemblyType';

type PrototypeDemandFormState = {
    quantity: number | undefined;
};

export interface PrototypeDemandScenarioFormState {
    type: 'Prototype';
    demands: PrototypeDemandFormState[];
    target_date: string | undefined;
}

type DeliveryBatchSizeFormState = {
    quantity: number | undefined;
};

type AnnualDemandFormState = {
    year: number;
    quantity: number | undefined;
    delivery_batch_sizes: DeliveryBatchSizeFormState[];
};

type SeriesDemandFormState = {
    annual_demands: AnnualDemandFormState[];
};

export interface SeriesDemandScenarioFormState {
    type: 'Series';
    start_year: number;
    end_year: number;
    demands: SeriesDemandFormState[];
}

export interface RfqCreationFormState {
    customer: CustomerDTO | undefined;
    assembly: AssemblyInfo;
    demand_scenarios: (PrototypeDemandScenarioFormState | SeriesDemandScenarioFormState)[];
    rfq: RfqInfo;
    additionalServiceIds: string[];
}

interface RfqCreationState {
    formState: RfqCreationFormState;
    activeStep: RfqCreationStep;
    setActiveStep: (step: RfqCreationStep) => void;
    lastCompletedStep: RfqCreationStep | undefined;
}

export type RfqCreationStep = 'customer' | 'assembly' | 'rfq' | 'demand';

export const getStepNumber = (step: RfqCreationStep): number => {
    switch (step) {
        case 'customer':
            return 1;
        case 'assembly':
            return 2;
        case 'demand':
            return 3;
        case 'rfq':
            return 4;
        default:
            assertUnreachable(step);
    }
};

interface Assembly {
    name: string | undefined;
    assemblyType: AssemblyTypePublic;
}

interface AssemblyInfo {
    industry: AssemblyIndustry | '';
    assemblies: Assembly[];
}

interface RfqInfo {
    name: string;
    emsInternalNumber: string | undefined;
    internalContributors: ContributorOption[];
    externalContributors: ContributorOption[];
    dueDate: string | undefined;
    currency: Currency;
    volumeEstimate: VolumeEstimateFormState;
    comment: string | undefined;
}

const currentYear = new Date().getFullYear();

export const defaultDeliveryBatchSizes = [{ quantity: undefined }, { quantity: undefined }, { quantity: undefined }];

/* eslint-disable camelcase */
const defaultAnnualDemand: AnnualDemandFormState = {
    year: currentYear,
    quantity: 0,
    delivery_batch_sizes: defaultDeliveryBatchSizes,
};

export const defaultSeriesDemand: SeriesDemandFormState = {
    annual_demands: [defaultAnnualDemand],
};

export const createDefaultSeriesDemandScenario = (assemblyCount: number): SeriesDemandScenarioFormState => {
    const demands = [];
    for (let i = 0; i < assemblyCount; i++) {
        demands.push(defaultSeriesDemand);
    }
    return {
        type: 'Series',
        start_year: currentYear,
        end_year: currentYear + 1,
        demands,
    };
};

export const defaultPrototypeDemand: PrototypeDemandFormState = { quantity: undefined };

export const createDefaultPrototypeDemandScenario = (assemblyCount: number): PrototypeDemandScenarioFormState => {
    const demands = [];
    for (let i = 0; i < assemblyCount; i++) {
        demands.push(defaultPrototypeDemand);
    }
    return {
        type: 'Prototype',
        demands: demands,
        target_date: undefined,
    };
};
/* eslint-enable camelcase */

export const defaultAssembly: Assembly = {
    name: '',
    assemblyType: 'Pcba',
};

const useRfqCreationState = (): RfqCreationState | undefined => {
    const { data: defaultAssemblyType } = useDefaultAssemblyType();
    const { preferredCurrency } = useGlobalCurrency();
    const { user } = useCurrentUserDetailsContext();
    const [activeStep, setActiveStepInternal] = useState<RfqCreationStep>('customer');
    const [lastCompletedStep, setLastCompletedStep] = useState<RfqCreationStep | undefined>(undefined);

    if (!isPresent(defaultAssemblyType)) {
        return undefined;
    }

    const assembly: AssemblyInfo = {
        industry: '',
        assemblies: [
            {
                name: '',
                assemblyType: defaultAssemblyType,
            },
        ],
    };

    const demandScenarios: RfqCreationFormState['demand_scenarios'] = [createDefaultPrototypeDemandScenario(1)];

    const userContributor = convertToContributorOption(user);
    const rfq: RfqInfo = {
        name: '',
        emsInternalNumber: undefined,
        internalContributors: [userContributor],
        externalContributors: [],
        dueDate: undefined,
        currency: preferredCurrency,
        volumeEstimate: {
            isEnabled: false,
            lower: undefined,
            upper: undefined,
        },
        comment: undefined,
    };

    const formState: RfqCreationFormState = {
        customer: undefined,
        assembly,
        // eslint-disable-next-line camelcase
        demand_scenarios: demandScenarios,
        rfq,
        additionalServiceIds: [],
    };

    const setActiveStep = (step: RfqCreationStep) => {
        if (!lastCompletedStep) {
            setLastCompletedStep(step);
        } else if (getStepNumber(step) >= getStepNumber(lastCompletedStep)) {
            setLastCompletedStep(step);
        }

        setActiveStepInternal(step);
    };

    return {
        formState,
        activeStep,
        setActiveStep,
        lastCompletedStep,
    };
};

const RfqCreationContext = React.createContext<RfqCreationState | undefined>(undefined);

export const useRfqCreationContext = (): RfqCreationState => {
    const state = useContext(RfqCreationContext);
    if (!state) throw Error('RfqCreationContext is not provided');

    return state;
};

export function RfqCreationContextProvider({ children }: React.PropsWithChildren<{}>): JSX.Element {
    const rfqCreationState = useRfqCreationState();

    if (!isPresent(rfqCreationState)) {
        return (
            <CenteredLayout height={'100%'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return <RfqCreationContext.Provider value={rfqCreationState}>{children}</RfqCreationContext.Provider>;
}
