import { t, Trans } from '@lingui/macro';
import { SecondaryIconButton } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox from '../../../../components/dialogBox/InformationDialogBox';
import NestedTableMenu from '../../../../components/NestedTableMenu';
import { useMutationDeleteTemplate } from '../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { colorSystem } from '../../../../themes';
import { setAnchorElementAsNullAndStopPropagation } from '../../../../utils/anchor';
import { EditManufacturingScenarioTemplateDialog } from '../../ManufacturingScenarioTemplateCreation';
import { useIsTemplateUsedInCustomerPortalSettings } from '../utils/useIsTemplateUsedInCustomerPortalSettings';

interface ActionMenuProps {
    template: ManufacturingScenarioTemplateDTO;
}

export const ManufacturingScenarioTemplateActionMenu: React.FunctionComponent<ActionMenuProps> = ({
    template,
}): JSX.Element => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);
    const [isCannotBeDeletedDialogOpen, setIsCannotBeDeletedDialogOpen] = React.useState<boolean>(false);

    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState<boolean>(false);
    const { mutateAsync: deleteTemplate } = useMutationDeleteTemplate(template.id);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementAsNullAndStopPropagation(event, setAnchorEl);
    }, []);

    const { isTemplateUsedInCustomerPortalSettings } = useIsTemplateUsedInCustomerPortalSettings(template.id);

    return (
        <>
            <NestedTableMenu
                open={open}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                button={
                    <SecondaryIconButton
                        onClick={handleClick}
                        style={{
                            padding: '6px',
                        }}
                        size="medium"
                    >
                        <MoreHorizIcon fontSize="inherit" />
                    </SecondaryIconButton>
                }
            >
                <MenuItem
                    onClick={(e) => {
                        handleClose(e);
                        setIsEditDialogOpen(true);
                    }}
                >
                    <Typography variant="h4" style={{ color: colorSystem.neutral[7] }}>
                        <Trans>Edit</Trans>
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        handleClose(e);
                        if (isTemplateUsedInCustomerPortalSettings) {
                            setIsCannotBeDeletedDialogOpen(true);
                        } else {
                            setIsConfirmationDialogOpen(true);
                        }
                    }}
                >
                    <Typography variant="h4" style={{ color: colorSystem.red[6] }}>
                        <Trans>Delete</Trans>
                    </Typography>
                </MenuItem>
            </NestedTableMenu>
            <ConfirmationDialogBox
                text={t`Are you sure you want to remove this template?`}
                title={t`Remove template`}
                onConfirm={deleteTemplate}
                onReject={() => {
                    setIsConfirmationDialogOpen(false);
                }}
                isDialogOpen={isConfirmationDialogOpen}
                deleteText={t`Yes, remove`}
            />
            <InformationDialogBox
                title={t`Deleting not possible`}
                onReject={() => setIsCannotBeDeletedDialogOpen(false)}
                isDialogOpen={isCannotBeDeletedDialogOpen}
            >
                <Typography>
                    <Trans>
                        This template cannot be deleted because it is used for the automatic generation of manufacturing
                        scenarios of the Customer portal. You can change this in the organization settings.
                    </Trans>
                </Typography>
            </InformationDialogBox>
            <EditManufacturingScenarioTemplateDialog
                isDialogOpen={isEditDialogOpen}
                setIsDialogOpen={setIsEditDialogOpen}
                template={template}
            />
        </>
    );
};
