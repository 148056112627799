import { t, Trans } from '@lingui/macro';
import { colorSystem, SecondaryButton, Toolbar } from '@luminovo/design-system';
import { RfqStatus, UserType } from '@luminovo/http-client';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useCommunicationsDrawer, ViewCommentsButton } from '../../../components/CommentsDrawer';
import { useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { id } from '../../../utils/ids';
import { useIsRfqEditable } from '../../../utils/rfqUtils';
import { route } from '../../../utils/routes';
import { useDeleteRfQDialog } from './DeleteRfQDialog';

export function ToolbarOemDashboard({ rfqId }: { rfqId: string }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const { data: rfq } = useRfQ(rfqId);
    const isRfqDeletable = rfq?.status === RfqStatus.RequestInDraft;
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const history = useHistory();
    function handleEditClick() {
        history.push(route('/rfqs/:rfqId/edit', { rfqId }));
    }

    const { openDialog } = useDeleteRfQDialog();

    return (
        <>
            <Toolbar breadcrumbs={[{ title: t`Dashboard` }]}>
                <RfqCommentsButton rfqId={rfqId} />
                <SecondaryButton
                    onClick={handleOpen}
                    size="medium"
                    aria-haspopup="true"
                    startIcon={<MoreHoriz />}
                    id={id('oem_dashboard/button_rfq_menu')}
                >
                    <Trans>RfQ</Trans>
                </SecondaryButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem disabled={!isRfqEditable} onClick={handleEditClick}>
                        <RfqEditButton isRfqEditable={isRfqEditable} />
                    </MenuItem>
                    <MenuItem disabled={!isRfqDeletable} onClick={() => openDialog(rfqId)}>
                        <RfqDeleteButton rfqId={rfqId} isRfqDeletable={isRfqDeletable} />
                    </MenuItem>
                </Menu>
            </Toolbar>
        </>
    );
}

export function RfqCommentsButton({
    rfqId,
    iconButtonOnly = false,
    size,
}: {
    rfqId: string;
    iconButtonOnly?: boolean;
    size?: 'small' | 'medium' | 'large';
}) {
    const userType = useUserType();

    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads:
            userType === UserType.Internal
                ? [
                      {
                          commentType: 'Rfq',
                          category: 'Internal',
                          typeIds: rfqId,
                      },
                      {
                          commentType: 'Rfq',
                          category: 'Public',
                          typeIds: rfqId,
                      },
                  ]
                : [
                      {
                          commentType: 'Rfq',
                          category: 'Public',
                          typeIds: rfqId,
                      },
                  ],
    });

    return (
        <span id={id('oem_dashboard/button_contact_ems')}>
            <ViewCommentsButton
                eventEntity={{ type: 'Rfq', data: rfqId }}
                onClick={() => openDrawer()}
                iconButtonOnly={iconButtonOnly}
                size={size}
            />
        </span>
    );
}

function RfqEditButton({ isRfqEditable }: { isRfqEditable: boolean }) {
    return (
        <Tooltip title={isRfqEditable ? '' : t`Editing is only allowed for requests in draft.`}>
            <span>
                {' '}
                {/* needed to display tooltip even when button is disabled */}
                <span id={id('oem_dashboard/button_edit_rfq')}>
                    <Trans>Edit</Trans>
                </span>
            </span>
        </Tooltip>
    );
}

function RfqDeleteButton({ rfqId, isRfqDeletable }: { rfqId: string; isRfqDeletable: boolean }) {
    return (
        <>
            <Tooltip title={isRfqDeletable ? '' : t`Deleting is only allowed for requests in draft.`}>
                <span style={{ color: colorSystem.red[6] }}>
                    {' '}
                    {/* needed to display tooltip even when button is disabled */}
                    <span>
                        <Trans>Delete</Trans>
                    </span>
                </span>
            </Tooltip>
        </>
    );
}
