import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../../boundingBox';
import { generateId } from '../generateId';
import { AttributeExtractionRule, Extractor } from '../types';
import { KeyValuePair } from './extractKeyValuePairs';

export function extractCells({
    extractionRules,
}: {
    extractionRules: AttributeExtractionRule<KeyValuePair>[];
}): Extractor<KeyValuePair> {
    return {
        generator: function* (azureExtractionResult: AzureExtractionResult): Generator<KeyValuePair> {
            for (const table of azureExtractionResult.analyzeResult.tables) {
                for (const cell of table.cells) {
                    const headerCell = table.cells.find(
                        (header) => header.kind === 'columnHeader' && header.columnIndex === cell.columnIndex,
                    );

                    if (!headerCell) {
                        continue;
                    }

                    yield {
                        value: {
                            content: cell.content,
                            boundingRegions: cell.boundingRegions,
                        },
                        key: {
                            content: headerCell.content,
                            boundingRegions: headerCell.boundingRegions,
                        },
                        // Assuming 1 as cells have no confidence
                        confidence: 1,
                    };
                }
            }
        },

        extractRegion: ({ value }) => {
            return [
                {
                    id: generateId(value),
                    content: value.content,
                    pageNumber: value.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(value.boundingRegions),
                    attributes: [],
                },
            ];
        },

        extractionRules,
    };
}
