import { Trans, t } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { SimpleOffersDTO } from '@luminovo/http-client';
import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { apiNotSetupErrorTranslations } from '../../../../../resources/pcb/i18n';
import { OfferStateLoadingBox } from './OfferStateLoadingBox';
import { OfferErrorBox, OffersError } from './OffersError';

export const PcbSimpleOffers = ({
    offers,
    rfqId,
    assemblyId,
    pcbId,
}: {
    offers: SimpleOffersDTO;
    rfqId: string;
    assemblyId: string;
    pcbId: string;
}) => {
    switch (offers.data.type) {
        case 'OffersAvailable':
            return (
                <Box
                    style={{
                        background: colorSystem.neutral.white,
                        borderRadius: '8px',
                        padding: '16px',
                    }}
                >
                    <Flexbox alignItems={'center'} flexDirection={'column'} gap={'8px'}>
                        <CheckCircleRounded
                            style={{
                                width: '40px',
                                height: '40px',
                                color: colorSystem.primary[6],
                            }}
                        />
                        <Text variant="h4">
                            <Trans>Everything looks good</Trans>
                        </Text>
                        <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                            <Trans>
                                The specification supports instant online prices via digital interfaces to our PCB
                                suppliers
                            </Trans>
                        </Text>
                        <Divider style={{ width: '50%', marginBlock: '8px' }} />
                        <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                            <Trans>
                                Please note that instant prices are only valid as long as you stick to the default
                                Stackups of the connected suppliers
                            </Trans>
                        </Text>
                    </Flexbox>
                </Box>
            );
        case 'SupplierApiError':
            return (
                <OfferErrorBox title={t`API is not available`}>
                    <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                        <Trans>Unfortunately, you won’t get any instant offers with current APIs</Trans>
                    </Text>
                </OfferErrorBox>
            );
        case 'InvalidSpecification':
            return (
                <OfferErrorBox title={t`Specification is not supported`}>
                    <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                        <Trans>Unfortunately, you won’t get any instant offers with current APIs</Trans>
                    </Text>
                    <OffersError offers={offers} />
                </OfferErrorBox>
            );
        case 'PanelError':
            return (
                <OfferErrorBox title={t`Specification is not supported`}>
                    <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                        <Trans>Unfortunately, you won’t get any instant offers with current APIs</Trans>
                    </Text>
                    <OffersError offers={offers} />
                </OfferErrorBox>
            );
        case 'MissingCredentials':
            return <OfferErrorBox title={t`Missing credentials`} />;
        case 'InvalidCredentials':
            return <OfferErrorBox title={t`Invalid credentials`} />;
        case 'ApiNotSetUp':
            return <OfferErrorBox title={transEnum(offers.data.error.type, apiNotSetupErrorTranslations)} />;
        case 'NeedsRefreshForOffers':
        case 'NoOffersReceived':
            return (
                <OfferErrorBox title={t`No offers received`}>
                    <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                        <Trans>Please save the specification to fetch offers from the connected PCB suppliers</Trans>
                    </Text>
                </OfferErrorBox>
            );
        case 'CustomStackup':
            return (
                <OfferErrorBox title={t`No instant check possible`}>
                    <Text variant="body-small" style={{ textAlign: 'center', width: '85%' }}>
                        <Trans>PCBs with custom stackups will be manually handled by our team.</Trans>
                    </Text>
                </OfferErrorBox>
            );
        case 'Pending':
            return <OfferStateLoadingBox />;
        default:
            assertUnreachable(offers.data);
    }
};
