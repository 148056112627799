import * as Yup from 'yup';
import { mustBeANumberErrorMessage, requiredErrorMessage } from '../../../../../utils/yupValidationUtils';

export const createTestRunSchema = (identifiers: string[]): Yup.ObjectSchema => {
    const validationObject: { [key: string]: Yup.NumberSchema } = {};

    identifiers.forEach((identifier) => {
        validationObject[identifier] = Yup.number()
            .required(requiredErrorMessage())
            .typeError(mustBeANumberErrorMessage());
    });

    return Yup.object(validationObject);
};
