import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

export function PCBIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return <TimelineAddIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

function usePCBAction(actor: ActorResponseRuntypeDTO, operation: HistoryOperation) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <TimelineText>
                    <Trans>PCB uploaded</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualRemove:
            return (
                <TimelineText>
                    <Trans>PCB deleted</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
    }
}

export default function PCB({ actor, operation }: Props) {
    const action = usePCBAction(actor, operation);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}
