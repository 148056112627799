import { CircularProgress, Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material';
import React from 'react';
import { CenteredLayout } from '../layout/CenteredLayout';
import { DialogTitle } from './DialogTitle';

type DialogProps = Omit<MuiDialogProps, 'title'> & {
    title?: React.ReactNode;
};

const Fallback: React.FunctionComponent = () => {
    return (
        <CenteredLayout height={'25vh'}>
            <CircularProgress size={40} color="primary" />
        </CenteredLayout>
    );
};

export const Dialog = React.forwardRef<unknown, DialogProps>(({ children, title, ...props }, _) => {
    const onClose = props.onClose ?? (() => {});
    return (
        <MuiDialog fullWidth={true} {...props}>
            {title && <DialogTitle title={title} handleClose={(e) => onClose(e, 'escapeKeyDown')} />}
            <React.Suspense fallback={<Fallback />}>{children}</React.Suspense>
        </MuiDialog>
    );
});
