import { Trans } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Column, Filter, Flexbox, Row, columnWidth } from '@luminovo/design-system';
import { DriverDetailsType, DriverIdDTO, ExpenseDTO, ExpensesCostComponent } from '@luminovo/http-client';
import {
    CombinedDriverType,
    DriverList,
    DriverListEntry,
    convertUserDriverTypeToUserAndSystemDriverType,
} from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { DriverColumnContext } from '../../../shared/columns/driverColumnContext';
import { ExpenseFormulasContext } from './utils';

function getDriverFilters<C extends DriverColumnContext & ExpenseFormulasContext>(
    context: C,
): Filter<ExpenseItem, C>[] {
    const drivers = Object.entries(context.driverIdsToNameAndTypeMap).map(([driverId, { name: driverName }]) => ({
        driverId,
        driverName,
    }));
    drivers.sort((a, b) => a.driverName.localeCompare(b.driverName));

    return drivers.map((driver) => ({
        id: `driver=${driver.driverId}`,
        label: driver.driverName,
        predicate: (expense: ExpenseItem, context: C) => {
            const { type, details } = expense.cost_components;
            switch (type) {
                case 'Fixed':
                    return false;
                case 'Linear':
                    return driver.driverId === details.variable_cost.driver.value;
                case 'Formula':
                    const formulas = context.expenseFormulasMap[expense.id];
                    if (!formulas) {
                        return false;
                    }

                    const formulaDrivers = formulas
                        .flatMap((formula) => context.formulaDriversMap[formula]?.driverNames)
                        .map((driverName) => context.driverNamesToIdAndTypeMap[driverName]?.id)
                        .filter(isPresent);

                    return formulaDrivers.includes(driver.driverId);
                default:
                    assertUnreachable(type);
            }
        },
    }));
}

const getDriverType = (
    driverId: DriverIdDTO,
    driver: { name: string; type: DriverDetailsType },
): CombinedDriverType => {
    const driverIdType = driverId.type;
    switch (driverIdType) {
        case 'System':
            return 'System+Automatic';
        case 'User':
            return convertUserDriverTypeToUserAndSystemDriverType(driver.type);
        default:
            assertUnreachable(driverIdType);
    }
};

const getDrivers = (
    { type, details }: ExpensesCostComponent,
    context: DriverColumnContext & ExpenseFormulasContext,
): DriverListEntry[] => {
    switch (type) {
        case 'Fixed':
            return [];
        case 'Linear':
            const driverId = details.variable_cost.driver;
            const driverEntry = context.driverIdsToNameAndTypeMap[driverId.value];
            return driverEntry ? [{ type: getDriverType(driverId, driverEntry), name: driverEntry.name }] : [];
        case 'Formula':
            const formula = details.formula;
            const formulaEntry = context.formulaDriversMap[formula];
            if (!formulaEntry) {
                return [];
            }

            return formulaEntry.driverNames
                .map((driverName) => {
                    const driver = context.driverNamesToIdAndTypeMap[driverName];
                    return driver
                        ? {
                              type: convertUserDriverTypeToUserAndSystemDriverType(driver.type),
                              name: driverName,
                              key: driver.id,
                          }
                        : undefined;
                })
                .filter(isPresent);
        default:
            assertUnreachable(type);
    }
};

type ExpenseItem = Pick<ExpenseDTO, 'id' | 'cost_components'>;

export function getDriversColumn<T extends ExpenseItem, C extends DriverColumnContext & ExpenseFormulasContext>(
    context: C,
): Column<T, C> {
    return {
        label: <Trans>Drivers</Trans>,
        id: `driver`,
        render: ({ data: rowData }: Row<T>, context: C) => {
            const drivers = getDrivers(rowData.cost_components, context);

            return (
                <TableCell>
                    <Flexbox maxWidth={400} overflow="hidden">
                        <DriverList drivers={drivers} />
                    </Flexbox>
                </TableCell>
            );
        },

        width: columnWidth.large,
        filters: getDriverFilters(context),
    };
}
