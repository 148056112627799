import { t } from '@lingui/macro';
import { ActivityDTO, ResourceDetails } from '@luminovo/http-client';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { usePageParams } from '../../../resources/hooks';
import { ActivityForm, useActivityForm } from './ActivityForm';
import { UseActivityForm, defaultValues } from './addActivityFormFunctions';
import { convertActivityToForm } from './editActivityFormFunctions';

export const EditActivityForm = (): JSX.Element => {
    const { activityId } = usePageParams<'/manufacturing/activity/:activityId/edit'>();
    const { isLoading, activities, drivers, resources } = useActivityForm();

    const activity: ActivityDTO | undefined = activities.find((activity) => activity.id === activityId);
    const resourceDetails: ResourceDetails[] = activity?.resources_details ?? [];
    const existingValues = activity ? convertActivityToForm(activity, resourceDetails) : defaultValues;

    const useActivityInputs: UseActivityForm = {
        onSubmitType: 'PATCH',
        defaultValues: existingValues,
        allResources: resources,
        allActivities: activities,
        allDrivers: drivers,
    };

    if (isLoading) {
        return <SpinnerWithBackdrop />;
    }

    return <ActivityForm formTitle={t`Edit activity`} useActivityInputs={useActivityInputs} />;
};
