import { createTheme } from '@mui/material';
import { themeLuminovo } from '../../themes';

export const themeForBomTable = createTheme({
    ...themeLuminovo,
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '0',
                },
                body: {
                    borderBottom: '0',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation2: {
                    boxShadow: '0',
                },
            },
        },
    },
});
