import { plural, t } from '@lingui/macro';
import { assertPresent, compareByString, formatDecimal, uniq } from '@luminovo/commons';
import {
    DEFAULT_EXPAND_COLUMN_ID,
    Flexbox,
    ReleaseTag,
    StatusChip,
    Tag,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { ManufacturerDTO, PartCategoryDTO, PreferenceStatusEnum } from '@luminovo/http-client';
import { SupplierChip, formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import { usePartCategories } from '../../resources/part/partHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useSupplierAndStockLocations,
} from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../utils/routes';
import { ManufacturerLineCardTableItem, convertToManufacturerLineCardTableItems } from './ManufacturerLineCardTab';

type PartCategoryWithPreference = {
    partCategory: PartCategoryDTO;
    preferenceStatus: PreferenceStatusEnum;
};

type ManufacturerItem = {
    type: 'row';
    manufacturer: ManufacturerDTO;
    partCategoriesWithPreferences: PartCategoryWithPreference[];
    lineCardItems: ManufacturerLineCardTableItem[];
};

const columnHelper = createColumnHelper<ManufacturerItem | ManufacturerLineCardTableItem>();

const columns = [
    columnHelper.text((row) => row.manufacturer.name, {
        id: 'ManufacturerName',
        size: 140,
        label: () => t`Manufacturer name`,
        cell: (item) => {
            if (item.row.original.type === 'row') {
                return item.getValue();
            }
            return <></>;
        },
    }),

    columnHelper.text(
        (row) =>
            row.type === 'subRow'
                ? formatSupplierDTO(row.supplierAndStockLocation.supplier)
                : row.lineCardItems.map((item) => formatSupplierDTO(item.supplierAndStockLocation.supplier)).join(', '),
        {
            id: 'supplierAndStockLocation',
            size: 160,
            description: () => t`Suppliers that have the manufacturer in their line card.`,
            label: () => t`Line card suppliers`,
            cell: ({ row }) => {
                if (row.original.type === 'row') {
                    const numberOfSuppliers = row.getLeafRows().length;
                    return (
                        <Text variant="inherit" color={colorSystem.neutral[6]}>
                            {plural(numberOfSuppliers, {
                                one: `${formatDecimal(numberOfSuppliers)} supplier supports this manufacturer`,
                                other: `${formatDecimal(numberOfSuppliers)} suppliers support this manufacturer`,
                            })}
                        </Text>
                    );
                }

                row.getLeafRows();
                return (
                    <SupplierChip
                        isPreferred={row.original.isPreferred}
                        isApproved={row.original.isApproved}
                        supplier={row.original.supplierAndStockLocation.supplier}
                    />
                );
            },
        },
    ),
    columnHelper.array(
        (row) => (row.type === 'subRow' ? [row.quoteStatus] : row.lineCardItems.map((item) => item.quoteStatus)),
        {
            id: 'quoteStatus',
            size: 130,
            options: ['enabled', 'preferred', 'disabled'],
            getOptionLabel: (option) => {
                switch (option) {
                    case 'enabled':
                        return t`Enabled`;
                    case 'preferred':
                        return t`Preferred`;
                    case 'disabled':
                        return t`Disabled`;
                }
            },
            label: () => t`Allow quote requests`,
            description: () =>
                t`Indicates whether the supplier is included in the 'quote by line card' workflow for quoting parts from the manufacturer.`,
            cell: ({ row }) => {
                if (row.original.type === 'row') {
                    const subRowsValues = row
                        .getLeafRows()
                        .flatMap((r) => (r.original.type === 'subRow' ? [r.original.quoteStatus] : []));
                    const totalSuppliers = subRowsValues.length;
                    const enabledSuppliers = subRowsValues.filter((v) => v !== 'disabled').length;

                    if (enabledSuppliers === 0) {
                        return <Tag attention="low" color="red" label={t`No suppliers enabled`} />;
                    }

                    if (enabledSuppliers === totalSuppliers) {
                        return <Tag attention="low" color="neutral" label={t`All suppliers enabled`} />;
                    } else {
                        const label = plural(enabledSuppliers, {
                            one: `${formatDecimal(enabledSuppliers)}/${formatDecimal(totalSuppliers)} supplier enabled`,
                            other: `${formatDecimal(enabledSuppliers)}/${formatDecimal(
                                totalSuppliers,
                            )} suppliers enabled`,
                        });
                        return <Tag attention="low" color="blue" label={label} />;
                    }
                }

                switch (row.original.quoteStatus) {
                    case 'preferred':
                        return <Tag attention="low" color="green" label={t`Preferred`} />;
                    case 'enabled':
                        return <Tag attention="low" color="blue" label={t`Enabled`} />;
                    case 'disabled':
                        return <Tag attention="low" color="red" label={t`Disabled`} />;
                }
            },
        },
    ),
    columnHelper.array(
        (row) =>
            row.type === 'subRow'
                ? row.restrictedPartCategories
                : row.lineCardItems.flatMap((subRow) => subRow.restrictedPartCategories),
        {
            id: 'restrictedPartCategories',
            size: 150,
            label: () => t`Restrict to (part categories)`,
            description: () => t`Part categories allowed to be requested in the "quote by line card" workflow.`,
            getOptionLabel: (option) => option.name,
            cell: ({ getValue, row }) => {
                const values = getValue();
                if (values.length === 0) {
                    return (
                        <Text variant="inherit" color={colorSystem.neutral[6]}>
                            {t`No restriction`}
                        </Text>
                    );
                }
                if (row.original.type === 'row') {
                    const numberOfUniqueCategories = uniq(values.map((c) => c.name)).length;
                    return plural(numberOfUniqueCategories, {
                        one: `${formatDecimal(numberOfUniqueCategories)} category`,
                        other: `${formatDecimal(numberOfUniqueCategories)} categories`,
                    });
                }

                return values.map((c) => c.name).join(', ');
            },
        },
    ),
    columnHelper.array(
        (row) =>
            row.type === 'subRow'
                ? row.isPreferred
                    ? ['preferredSupplier']
                    : row.isApproved
                      ? ['approvedSupplier']
                      : ['notApprovedSupplier']
                : ['preferredSupplier', 'approvedSupplier', 'notApprovedSupplier'], // This is a hack to make the quick filter work
        {
            id: 'supplierPreference',
            size: 150,
            enableHiding: false,
            initialVisibility: false,
            label: () => t`Supplier preference`,
            options: ['preferredSupplier', 'approvedSupplier', 'notApprovedSupplier'],
            getOptionLabel: (option) => {
                switch (option) {
                    case 'preferredSupplier':
                        return t`Preferred`;
                    case 'approvedSupplier':
                        return t`Approved`;
                    case 'notApprovedSupplier':
                        return t`Not approved`;
                    default:
                        return t`Unknown`;
                }
            },
            cell: () => {
                throw new Error('Not implemented');
            },
            quickFilters: [
                {
                    label: () => t`Only preferred and approved suppliers`,
                    value: ['preferredSupplier', 'approvedSupplier'],
                },
            ],
        },
    ),
    columnHelper.array((row) => (row.type === 'row' ? row.partCategoriesWithPreferences : []), {
        id: 'partCategoriesWithPreferences',
        size: 160,
        label: () => t`Part category preferences`,
        getOptionLabel: (option) => option.partCategory.name,
        cell: (item) => {
            const items = item.getValue().map((c) => ({
                pc: c.partCategory.name,
                pref: c.preferenceStatus,
            }));

            return items.map((item, i) => (
                <StatusChip
                    key={i}
                    label={item.pc}
                    color={item.pref === PreferenceStatusEnum.Blocked ? 'red' : 'blue'}
                />
            ));
        },
    }),

    columnHelper.text((row) => row.manufacturer.alternative_names.join(', '), {
        id: 'alternativeNames',
        size: 160,
        label: () => t`Alternative names`,
        cell: (item) => {
            if (item.row.original.type === 'row') {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        {item.getValue()}
                    </Text>
                );
            }
            return <></>;
        },
    }),
];

function ManufacturerOverviewTable() {
    const history = useHistory();
    const { data: manufacturers } = useManufacturers();
    const { data: manufacturerPreferences } = useHttpQuery(
        'GET /organization-settings/manufacturer-preferences',
        {},
        { select: (res) => res.items },
    );
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { data: preferredSuppliersAndStockLocationsDTO } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocationsDTO } = useGlobalApprovedSupplierAndStockLocations();
    const { data: partCategories } = usePartCategories();
    const { data: manufacturerLineCards } = useHttpQuery(
        'GET /manufacturers/line-card',
        {},
        { select: (res) => res.data },
    );

    const data: Array<ManufacturerItem | ManufacturerLineCardTableItem> | undefined = React.useMemo(() => {
        const partCategoriesMap = new Map<string, PartCategoryWithPreference[]>();
        const lineCardMap = new Map<string, ManufacturerLineCardTableItem[]>();

        if (
            !manufacturers ||
            !manufacturerPreferences ||
            !partCategories ||
            !manufacturerLineCards ||
            !supplierAndStockLocations ||
            !preferredSuppliersAndStockLocationsDTO ||
            !approvedSuppliersAndStockLocationsDTO
        ) {
            return undefined;
        }

        manufacturerPreferences?.forEach((preference) => {
            preference.manufacturers.forEach((manufacturer) => {
                if (!partCategoriesMap.has(manufacturer.id)) {
                    partCategoriesMap.set(manufacturer.id, []);
                }
                assertPresent(partCategoriesMap.get(manufacturer.id)).push({
                    partCategory: assertPresent(partCategories.find((c) => c.id === preference.part_category.id)),
                    preferenceStatus: preference.preference_status,
                });
            });
        });

        manufacturerLineCards.forEach((lineCard) => {
            if (!lineCardMap.has(lineCard.manufacturer_id)) {
                lineCardMap.set(lineCard.manufacturer_id, []);
            }
            assertPresent(lineCardMap.get(lineCard.manufacturer_id)).push(
                ...convertToManufacturerLineCardTableItems(
                    manufacturers,
                    lineCard,
                    supplierAndStockLocations,
                    partCategories,
                    preferredSuppliersAndStockLocationsDTO,
                    approvedSuppliersAndStockLocationsDTO,
                ),
            );
        });

        return manufacturers
            .map((manufacturer) => ({
                type: 'row' as const,
                manufacturer,
                partCategoriesWithPreferences: partCategoriesMap.get(manufacturer.id) ?? [],
                lineCardItems: lineCardMap.get(manufacturer.id) ?? [],
            }))
            .sort((a, b) => compareByString(a.manufacturer.name, b.manufacturer.name));
    }, [
        manufacturers,
        manufacturerPreferences,
        manufacturerLineCards,
        partCategories,
        supplierAndStockLocations,
        preferredSuppliersAndStockLocationsDTO,
        approvedSuppliersAndStockLocationsDTO,
    ]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableExcelExport: true,
        initialState: {
            columnPinning: {
                left: [DEFAULT_EXPAND_COLUMN_ID],
            },
        },
        enablePersistentExpanded: 'manufacturer-management',
        getSubRows: (row) => (row.type === 'row' ? row.lineCardItems : []),
        onRowClick: (item) => {
            if (item.original.type === 'row') {
                history.push(route('/manufacturer/:manufacturerId', { manufacturerId: item.original.manufacturer.id }));
            }
            if (item.original.type === 'subRow') {
                history.push(
                    route(
                        '/supplier/:supplierAndStockLocationId',
                        {
                            supplierAndStockLocationId: item.original.supplierAndStockLocation.id,
                        },
                        { tab: 'lineCard' },
                    ),
                );
            }
        },
    });

    return <TanStackTable table={table} size={'medium'} />;
}

export function ManufacturerManagementPage() {
    return (
        <PageLayout style={{ background: colorSystem.neutral[1] }} layout={'fragment'}>
            <LayoutCard
                title={
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text variant={'h2'}>{t`Manufacturers`}</Text>
                        <ReleaseTag color="primary" label={t`BETA`} />
                    </Flexbox>
                }
                style={{ marginTop: '24px', marginLeft: '32px', marginRight: '32px', height: '100%' }}
            >
                <Box height={'calc(100% - 48px)'}>
                    <ManufacturerOverviewTable />
                </Box>
            </LayoutCard>
        </PageLayout>
    );
}
