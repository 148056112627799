import { AzureExtractionResult } from '@luminovo/http-client';
import { BoundingBox } from '../../boundingBox';
import { generateId } from '../generateId';
import { AttributeExtractionRule, Extractor } from '../types';

export function extractLines({
    extractionRules,
}: {
    extractionRules: AttributeExtractionRule<{ content: string }>[];
}): Extractor<{ content: string; pageNumber: number; polygon: number[] }> {
    return {
        generator: function* (azureExtractionResult: AzureExtractionResult): Generator<{
            content: string;
            pageNumber: number;
            polygon: number[];
        }> {
            for (const page of azureExtractionResult.analyzeResult.pages) {
                for (const line of page.lines) {
                    yield {
                        content: line.content,
                        pageNumber: page.pageNumber,
                        polygon: line.polygon,
                    };
                }
            }
        },

        extractRegion: ({ content, pageNumber, polygon }) => {
            return [
                {
                    id: generateId({ content, boundingRegions: [{ pageNumber, polygon }] }),
                    content: content,
                    pageNumber,
                    box: BoundingBox.fromPolygon(polygon),
                    attributes: [],
                },
            ];
        },

        extractionRules,
    };
}
