import { ViewBoxCoordinateType } from '@luminovo/http-client';
import React, { RefObject } from 'react';

/**
 * SvgImageCursor is used to set the cursor of the svg image and also determine the mode the user is in.
 * 'grabbing' is used when the user is dragging/panning the image around
 * 'pointer' is used when the user is not dragging/panning the image around
 * 'crosshair' is used when the user is measuring the image
 */
export type SvgImageCursor = 'grabbing' | 'pointer' | 'crosshair';

export type LineMeasureType = {
    start: DOMPoint | undefined;
    end: DOMPoint | undefined;
    currentCursorPoint: DOMPoint | undefined;
};

const ZOOM_FACTOR = 0.2;
// const MIN_ZOOM_PERCENTAGE = 5;
// const MAX_ZOOM_PERCENTAGE = 5000;

export const useSvgImageEventHandlers = ({
    initialViewBox,
    cursor,
    setCursor,
}: {
    initialViewBox: ViewBoxCoordinateType;
    cursor: SvgImageCursor;
    setCursor: React.Dispatch<SvgImageCursor>;
}) => {
    const svgRef: RefObject<SVGSVGElement> = React.useRef(null);
    const [viewBox, setViewBox] = React.useState<ViewBoxCoordinateType>(initialViewBox);
    const [dragStartPoint, setDragStartPoint] = React.useState<DOMPoint | undefined>();
    /**
     * The last known cursor action is used to hold the last action while we take temporarily make use of another cursor action
     * It should immediately be set back to undefined after the temporary cursor action is done
     */
    const [lastKnownCursorAction, setLastKnownCursorAction] = React.useState<SvgImageCursor | undefined>(undefined);

    const {
        handleMouseClick: handleMeasureMouseClick,
        handleMouseMove: handleMeasureMouseMove,
        linePoints,
        handleResetLinePoints,
    } = useSvgImageMeasureClickHandler({ viewBox });

    const handleReset = React.useCallback(() => {
        if (cursor !== 'crosshair') {
            setCursor('pointer');
        }
    }, [cursor, setCursor]);

    const handleZoomInClick = React.useCallback(() => {
        const newViewBox = {
            x: viewBox.x + (viewBox.width * ZOOM_FACTOR) / 2,
            y: viewBox.y + (viewBox.height * ZOOM_FACTOR) / 2,
            width: viewBox.width - viewBox.width * ZOOM_FACTOR,
            height: viewBox.height - viewBox.height * ZOOM_FACTOR,
        };
        setViewBox(newViewBox);
    }, [viewBox]);

    const handleZoomOutClick = React.useCallback(() => {
        const newViewBox = {
            x: viewBox.x - (viewBox.width * ZOOM_FACTOR * 2) / 2,
            y: viewBox.y - (viewBox.height * ZOOM_FACTOR * 2) / 2,
            width: viewBox.width + viewBox.width * ZOOM_FACTOR * 2,
            height: viewBox.height + viewBox.height * ZOOM_FACTOR * 2,
        };
        setViewBox(newViewBox);
    }, [viewBox]);

    const handleCenterClick = React.useCallback(() => {
        setViewBox(initialViewBox);
    }, [initialViewBox]);

    const handleMeasureClick = React.useCallback(() => {
        if (cursor === 'crosshair') {
            setCursor('pointer');
        } else {
            setCursor('crosshair');
        }
        handleResetLinePoints();
    }, [cursor, handleResetLinePoints, setCursor]);

    const handleMouseClick = React.useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (!svgRef.current) {
                return;
            }

            // If user is trying to pan the image around, don't measure
            if (lastKnownCursorAction === 'crosshair' && (event.metaKey || event.ctrlKey)) {
                return;
            }

            handleMeasureMouseClick();
            setLastKnownCursorAction(undefined);
        },
        [handleMeasureMouseClick, lastKnownCursorAction],
    );

    const handleMouseDown = React.useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (!svgRef.current) {
                return;
            }
            const point = svgRef.current.createSVGPoint();

            // Is user trying to drag the image around in a mode that supports dragging?
            if (cursor === 'pointer' || (cursor === 'crosshair' && (e.metaKey || e.ctrlKey))) {
                setLastKnownCursorAction(cursor);
                const screenCTM = svgRef.current.getScreenCTM();
                if (screenCTM) {
                    setCursor('grabbing');
                    point.x = e.clientX;
                    point.y = e.clientY;
                    setDragStartPoint(point.matrixTransform(screenCTM.inverse()));
                }
            }
        },
        [cursor, svgRef, setCursor],
    );

    const handleMouseMove = React.useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (!svgRef.current) {
                return;
            }

            if (cursor === 'grabbing' && dragStartPoint) {
                const screenCTM = svgRef.current.getScreenCTM();
                if (screenCTM) {
                    const point = svgRef.current.createSVGPoint();
                    point.x = e.clientX;
                    point.y = e.clientY;
                    const currentCursorPoint = point.matrixTransform(screenCTM.inverse());

                    const newViewBox = {
                        x: viewBox.x - (currentCursorPoint.x - dragStartPoint.x),
                        y: viewBox.y - (currentCursorPoint.y - dragStartPoint.y),
                        width: viewBox.width,
                        height: viewBox.height,
                    };
                    setCursor('grabbing');
                    setViewBox(newViewBox);
                }
            }

            if (cursor === 'crosshair') {
                handleMeasureMouseMove(e, svgRef.current);
            }
        },
        [dragStartPoint, cursor, setCursor, setViewBox, svgRef, viewBox, handleMeasureMouseMove],
    );

    const handleMouseUp = React.useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (!svgRef.current) {
                return;
            }

            if (cursor === 'grabbing' && dragStartPoint) {
                const screenCTM = svgRef.current.getScreenCTM();
                if (screenCTM) {
                    const point = svgRef.current.createSVGPoint();
                    point.x = e.clientX;
                    point.y = e.clientY;
                    const movePoint = point.matrixTransform(screenCTM.inverse());

                    const newViewBox = {
                        x: viewBox.x - (movePoint.x - dragStartPoint.x),
                        y: viewBox.y - (movePoint.y - dragStartPoint.y),
                        width: viewBox.width,
                        height: viewBox.height,
                    };
                    setCursor(lastKnownCursorAction || 'pointer');
                    setViewBox(newViewBox);
                }
            }
        },
        [cursor, dragStartPoint, lastKnownCursorAction, setCursor, viewBox],
    );

    const handleMouseWheelScroll = React.useCallback(
        (e: React.WheelEvent<SVGSVGElement>) => {
            if (!svgRef.current) {
                return;
            }

            // const svgElement = svgRef.current;

            // const scrollMode: 'in' | 'out' = e.deltaY > 0 ? 'out' : 'in';
            // const zoom = getCurrentZoom(svgElement, viewBox.width);

            // if (
            //     (scrollMode === 'out' && zoom < MIN_ZOOM_PERCENTAGE) ||
            //     (scrollMode === 'in' && zoom > MAX_ZOOM_PERCENTAGE)
            // ) {
            // return;
            // }

            const point = svgRef.current.createSVGPoint();

            const delta = e.deltaY || e.detail || 0;
            const normalized = -(delta % 3 ? delta * 10 : delta / 3);

            const scaleDelta = normalized > 0 ? 1 / 1.06 : 1.06;

            point.x = e.clientX;
            point.y = e.clientY;

            const screenCTM = svgRef.current.getScreenCTM();

            if (screenCTM) {
                const startPoint = point.matrixTransform(screenCTM.inverse());
                const newViewBox = {
                    x: viewBox.x - (startPoint.x - viewBox.x) * (scaleDelta - 1),
                    y: viewBox.y - (startPoint.y - viewBox.y) * (scaleDelta - 1),
                    width: viewBox.width * scaleDelta,
                    height: viewBox.height * scaleDelta,
                };
                setViewBox(newViewBox);
            }
        },
        [setViewBox, svgRef, viewBox],
    );

    return {
        svgRef,
        cursor,
        viewBox,
        handleReset,
        handleZoomInClick,
        handleZoomOutClick,
        handleCenterClick,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleMouseWheelScroll,
        handleMouseClick,
        linePoints,
        handleResetLinePoints,
        handleMeasureClick,
    };
};

export const getCurrentZoom = (svgElement: SVGSVGElement, viewBoxWidth: number) => {
    const innerSvg = svgElement.querySelector('svg[id$=".GBS" i]') ?? svgElement.querySelector('svg');
    if (innerSvg) {
        const percentageTakenByContents =
            (innerSvg.getBoundingClientRect().width / svgElement.getBoundingClientRect().width) * 100;
        return percentageTakenByContents;
    }
    return (svgElement.getBoundingClientRect().width / viewBoxWidth) * 100;
};

const useSvgImageMeasureClickHandler = ({ viewBox }: { viewBox: ViewBoxCoordinateType }) => {
    const [linePoints, setLinePoints] = React.useState<LineMeasureType>({
        start: undefined,
        end: undefined,
        currentCursorPoint: undefined,
    });

    const handleResetLinePoints = () => {
        setLinePoints({
            start: undefined,
            end: undefined,
            currentCursorPoint: undefined,
        });
    };

    const handleMouseMove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, svgElement: SVGSVGElement) => {
        const screenCTM = svgElement.getScreenCTM();
        if (screenCTM) {
            const point = svgElement.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const currentCursorPoint = point.matrixTransform(screenCTM.inverse());
            setLinePoints({ ...linePoints, currentCursorPoint });
        }
    };

    const handleMouseClick = () => {
        if (linePoints.end) {
            setLinePoints({
                ...linePoints,
                start: undefined,
                end: undefined,
            });
            return;
        }

        if (linePoints.start) {
            setLinePoints({
                ...linePoints,
                end: linePoints.currentCursorPoint,
            });
            return;
        }

        setLinePoints({
            ...linePoints,
            start: linePoints.currentCursorPoint,
        });
    };

    return {
        linePoints,
        handleMouseMove,
        handleMouseClick,
        handleResetLinePoints,
    };
};
