import { Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { CustomerFormInner } from './CustomerForm';
import { CustomerFormState } from './types';

export function DialogUpsertCustomer({
    title,
    open,
    onClose,
    initialValues,
    onSubmit,
    customerType,
}: {
    title: string;
    open: boolean;
    onClose: () => void;
    initialValues: CustomerFormState;
    onSubmit: (form: CustomerFormState) => Promise<void>;
    customerType: 'Private' | 'Business';
}) {
    return (
        <Dialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle title={title} />
            <DialogContent style={{ paddingBottom: 24 }}>
                <CustomerFormInner
                    initialValues={initialValues}
                    onClose={() => onClose()}
                    onSubmit={async (form) => {
                        await onSubmit(form);
                    }}
                    customerType={customerType}
                />
            </DialogContent>
        </Dialog>
    );
}
