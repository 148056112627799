/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface RochesterCredentialsDTO extends r.Static<typeof RochesterCredentialsDTORuntype> {}
export const RochesterCredentialsDTORuntype = r.Union(
    r.Record({
        enabled: r.Boolean,
    }),
);

export interface RochesterCredentialsInputDTO extends r.Static<typeof RochesterCredentialsInputDTORuntype> {}
export const RochesterCredentialsInputDTORuntype = r.Record({
    enabled: r.Boolean.optional(),
});

export interface RochesterResponseBodyDTO extends r.Static<typeof RochesterResponseBodyDTORuntype> {}
export const RochesterResponseBodyDTORuntype = r.Record({
    data: RochesterCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
