import { colorSystem } from '@luminovo/design-system';
import { TableCell as MuiTableCell, styled } from '@mui/material';

export const TableCell = styled(MuiTableCell, {
    shouldForwardProp: (propName) => {
        return propName !== 'isComponentRemoved';
    },
})(({ isComponentRemoved = false }: { isComponentRemoved?: boolean }) => ({
    whiteSpace: 'nowrap',
    color: isComponentRemoved ? colorSystem.neutral[6] : colorSystem.neutral[9],
}));
