import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { StackUpPreviewer } from '../../components/SVGPreview';
import { StackUpViewMode } from '../utils/types';
import { CustomStackupPreview } from './CustomStackupPreview';

export const StackUpRenderer = ({
    activeTab,
    pcb,
    assemblyId,
    rfqId,
    isEditing,
}: {
    activeTab: StackUpViewMode;
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
    isEditing: boolean;
}) => {
    return (
        <Box
            style={{
                height: '100%',
                position: 'relative',
            }}
            key={pcb.id}
        >
            {activeTab === 'custom' ? (
                <CustomStackupPreview pcb={pcb} isEditing={isEditing} assemblyId={assemblyId} rfqId={rfqId} />
            ) : (
                <StackUpPreviewer pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />
            )}
        </Box>
    );
};
