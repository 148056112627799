/* eslint-disable camelcase */
import { useHttpQuery } from '../http/useHttpQuery';

export const useParsedRfqsOfActivity = (activityId: string, enabled: boolean = true) => {
    return useHttpQuery('GET /rfqs', { queryParams: { activity_id: activityId } }, { enabled });
};

export const useParsedRfqsOfExpense = (expenseId: string, enabled: boolean = true) => {
    return useHttpQuery('GET /rfqs', { queryParams: { expense_id: expenseId } }, { enabled });
};
