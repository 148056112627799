import { t } from '@lingui/macro';
import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';

export const DeleteStackUpFileModal = ({
    fileNames,
    pcbId,
    isConfirmationDialogOpen,
    setIsConfirmationDialogOpen,
    onDeleteConfirmation,
}: {
    fileNames: string[];
    pcbId: string;
    isConfirmationDialogOpen: boolean;
    setIsConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmation?: () => void;
}) => {
    const { mutateAsync } = useHttpMutation('DELETE /pcb/:pcbId/files', {
        snackbarMessage: null,
        onSuccess: () => {
            onDeleteConfirmation?.();
        },
    });

    const handleDeleteStackUpFile = async () => {
        await mutateAsync({
            pathParams: { pcbId },
            requestBody: { files: fileNames },
        });
    };

    return (
        <ConfirmationDialogBox
            text={t`Are you sure you want to delete the uploaded file with the Stackup?`}
            closeIconIsShown={true}
            onConfirm={handleDeleteStackUpFile}
            title={t`Delete custom stackup?`}
            isDialogOpen={isConfirmationDialogOpen}
            onReject={() => setIsConfirmationDialogOpen(false)}
        />
    );
};
