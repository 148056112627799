import { PCBV2CadFile } from '@luminovo/http-client';
import { isPcbFilePreviewAvailable } from '../../utils/isPcbFilePreviewAvailable';

/**
 * Compare files and see if there has been no change, If there is no change, return the current state
 * To compare the files, we compare the id and the preview availability
 *
 * @param files incoming files
 * @param stateFiles current files existing in state
 * @returns boolean
 */
export const compareCadFiles = (stateFiles: PCBV2CadFile[], files: PCBV2CadFile[]) => {
    if (stateFiles.length !== files.length) return false;

    const stateFileMap = new Map(stateFiles.map((file) => [file.id, file]));

    for (const file of files) {
        const stateFile = stateFileMap.get(file.id);
        if (!stateFile || isPcbFilePreviewAvailable(stateFile) !== isPcbFilePreviewAvailable(file)) {
            return false;
        }

        stateFileMap.delete(file.id);
    }

    if (stateFileMap.size > 0) {
        return false;
    }

    return true;
};
