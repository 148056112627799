import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, ApprovalStatus, HistoryOperation, HistoryRecordIPNDTO } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    ApprovalStatusIcon,
    AutomaticApprovalStatusPhrase,
    IPNContent,
    TimelineAddIcon,
    TimelineDeleteIcon,
    TimelineStatusChip,
} from './Utils';

type Props = {
    actor: ActorResponseRuntypeDTO;
    data: HistoryRecordIPNDTO;
    operation: HistoryOperation;
};

function useIPNAction(actor: ActorResponseRuntypeDTO, operation: HistoryOperation, approvalStatus: ApprovalStatus) {
    switch (operation) {
        case HistoryOperation.AutoAdd:
            return <AutomaticApprovalStatusPhrase approvalStatus={approvalStatus} />;
        case HistoryOperation.AutoUpdate:
            return (
                <>
                    <Trans>was removed from IPN database and</Trans>{' '}
                    <AutomaticApprovalStatusPhrase approvalStatus={approvalStatus} />
                </>
            );
        case HistoryOperation.ManualAdd:
            return (
                <>
                    <Trans>added</Trans> {isActorDefined(actor) && <ActionPerformedBy actor={actor} />}
                </>
            );
        case HistoryOperation.ManualRemove:
            return (
                <>
                    <Trans>removed</Trans> {isActorDefined(actor) && <ActionPerformedBy actor={actor} />}
                </>
            );

        case HistoryOperation.ManualUpdateApproval:
            return (
                <>
                    <TimelineStatusChip approvalStatus={approvalStatus} />{' '}
                    {isActorDefined(actor) && <ActionPerformedBy actor={actor} />}
                </>
            );
        default:
            return null;
    }
}
export default function InternalPartNumber({ actor, data, operation }: Props) {
    const action = useIPNAction(actor, operation, data.approval);
    if (!action) return null;
    return <IPNContent data={data.ipn}>{action}</IPNContent>;
}

export function InternalPartNumberTimelineIcon({
    operation,
    data,
}: {
    operation: HistoryOperation;
    data: HistoryRecordIPNDTO;
}) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return <ApprovalStatusIcon approvalStatus={data.approval} />;
}
