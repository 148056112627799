import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDecimal, isPresent, TransEnum, uniqueBy } from '@luminovo/commons';
import { colorSystem, Flexbox, Link, Tag, Text } from '@luminovo/design-system';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    QuoteTrackingLight,
} from '@luminovo/http-client';
import { LabelPart, partSpecificationTypeTranslations } from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useCustomComponentDrawer } from '../../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { usePartOptionsFromSolutionConfigurationId } from '../../../../resources/part/partHandler';

export function SectionApprovedParts({
    rfqId,
    solutionConfigurationId,
    quoteTrackings,
}: {
    rfqId: string;
    solutionConfigurationId: string;
    quoteTrackings?: QuoteTrackingLight[];
}) {
    const { data: approvedPartOptions } = usePartOptionsFromSolutionConfigurationId({
        rfqId,
        solutionConfigurationId,
    });

    if (!isPresent(approvedPartOptions)) {
        return (
            <Flexbox flexDirection={'column'} gap={4} minWidth={'150px'}>
                <Skeleton height={20} />
                <Skeleton height={20} />
                <Skeleton height={20} />
                <Skeleton height={20} />
                <Skeleton height={20} />
            </Flexbox>
        );
    }

    const uniqueParts = uniqueBy(approvedPartOptions, (x) => x.id);

    return (
        <React.Suspense fallback={null}>
            <Flexbox flexDirection={'column'} gap={4}>
                <Flexbox justifyContent={'space-between'}>
                    <Text color={colorSystem.neutral[6]} variant="h4">
                        {t`Approved part options (${formatDecimal(uniqueParts.length)})`}
                    </Text>
                </Flexbox>
                {uniqueParts.length === 0 ? <Text>{t`No approved part options`}</Text> : null}
                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                    {uniqueParts.map((p) => (
                        <ApprovedPartOptionLabel key={p.id} rfqId={rfqId} part={p} />
                    ))}
                </ul>
            </Flexbox>
        </React.Suspense>
    );
}

export function ApprovedPartOptionLabel({ part, rfqId }: { part: FullPart; rfqId: string }) {
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openCustomComponentDrawer } = useCustomComponentDrawer();

    if (isOtsFullPart(part)) {
        return (
            <li>
                <Link
                    attention="high"
                    onClick={(e) => {
                        e.stopPropagation();
                        openOtsDrawer({ part, rfqContext: { rfq_id: rfqId, type: 'WithinRfQ' } });
                    }}
                >
                    <LabelPart part={part} />
                </Link>
            </li>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <li>
                <Flexbox gap={4}>
                    <Tag attention={'low'} color={'green'} label={t`Generic`} />
                    <Text color={colorSystem.neutral[6]}>
                        <TransEnum text={part.content.type} translations={partSpecificationTypeTranslations} />
                    </Text>
                </Flexbox>
                <ul style={{ margin: 0, paddingLeft: 18 }}>
                    {part.matches.map((p) => (
                        <ApprovedPartOptionLabel key={p.id} rfqId={rfqId} part={p} />
                    ))}
                    {part.matches.length === 0 ? (
                        <li>
                            <Text>
                                <Trans>No matches</Trans>
                            </Text>
                        </li>
                    ) : null}
                </ul>
            </li>
        );
    }
    if (isCustomFullPart(part)) {
        return (
            <li>
                <Text>
                    <LabelPart part={part} enableCopyToClipboard={true} />
                </Text>
            </li>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <li>
                <Link
                    attention="high"
                    onClick={(e) => {
                        e.stopPropagation();
                        openIpnDrawer({ ipnId: part.id, rfqContext: { rfq_id: rfqId, type: 'WithinRfQ' } });
                    }}
                >
                    <LabelPart part={part} />
                </Link>
                <ul style={{ margin: 0, paddingLeft: 18 }}>
                    {part.matches.map((p) => (
                        <ApprovedPartOptionLabel key={p.part.data.id} rfqId={rfqId} part={p.part.data} />
                    ))}
                    {part.matches.length === 0 ? (
                        <li>
                            <Text>
                                <Trans>No linked parts</Trans>
                            </Text>
                        </li>
                    ) : null}
                </ul>
            </li>
        );
    }

    if (isCustomComponentFull(part)) {
        return (
            <li>
                <Link
                    attention="high"
                    onClick={(e) => {
                        e.stopPropagation();
                        openCustomComponentDrawer({
                            componentId: part.id,
                            rfqContext: { rfq_id: rfqId, type: 'WithinRfQ' },
                        });
                    }}
                >
                    <LabelPart part={part} />
                </Link>
                <ul style={{ margin: 0, paddingLeft: 18 }}>
                    {part.matches.map((p) => (
                        <ApprovedPartOptionLabel key={p.id} rfqId={rfqId} part={p} />
                    ))}
                    {part.matches.length === 0 ? (
                        <li>
                            <Text>
                                <Trans>No linked parts</Trans>
                            </Text>
                        </li>
                    ) : null}
                </ul>
            </li>
        );
    }

    assertUnreachable(part);
}
