import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import React from 'react';

export function ImporterCard({
    heading,
    subheading,
    children,
    ActionButton,
    Tag,
}: {
    heading: string;
    subheading?: string;
    children: React.ReactNode;
    ActionButton?: React.ReactNode;
    Tag?: React.ReactNode;
}): JSX.Element {
    return (
        <Flexbox
            style={{
                flexDirection: 'column',
                background: 'white',
                padding: '24px 24px 42px 24px',
                borderRadius: '4px',
                minWidth: '600px',
                minHeight: '200px',
                gap: '32px',
            }}
        >
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox gap={8} justifyContent={'space-between'}>
                    <Flexbox alignItems={'center'} gap={'4px'}>
                        <Text variant="h1" style={{ color: colorSystem.neutral[8] }}>
                            {heading}
                        </Text>
                        {Tag}
                    </Flexbox>
                    {ActionButton}
                </Flexbox>
                {subheading && <Text style={{ color: colorSystem.neutral[6] }}>{subheading}</Text>}
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={8} alignItems={'center'}>
                {children}
            </Flexbox>
        </Flexbox>
    );
}
