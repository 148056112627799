import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ChipCartCredentialsInputDTORuntype, ChipCartResponseBodyDTORuntype } from './chipCartBackendTypes';

export const chipCartEndpoints = {
    'GET /3rdparty/chipcart/credentials': endpoint({
        description: 'Returns credentials for chipcart integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ChipCartResponseBodyDTORuntype,
    }),
    'POST /3rdparty/chipcart/credentials': endpoint({
        description: 'Uploads credentials for chipcart API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ChipCartCredentialsInputDTORuntype,
        responseBody: ChipCartResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/chipcart/credentials'],
    }),
    'PATCH /3rdparty/chipcart/credentials': endpoint({
        description: 'Updates credentials for chipcart API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ChipCartCredentialsInputDTORuntype,
        responseBody: ChipCartResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/chipcart/credentials'],
    }),
    'DELETE /3rdparty/chipcart/credentials': endpoint({
        description: 'Deletes existing credentials for chipcart API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/chipcart/credentials'],
    }),
};
