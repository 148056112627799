import { Trans, t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { Flexbox, SecondaryButton, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { CustomPartOfferResponse, PCBV2, UserType } from '@luminovo/http-client';
import { Add, LocalShipping as LocalShippingIcon, Radar } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import {
    useCurrentUserDetailsContext,
    useIsCustomer,
    useUserType,
} from '../../../../components/contexts/CurrentUserDetailsContext';
import { route } from '../../../../utils/routes';
import { OfferStateLoadingBox } from './components/OfferStateLoadingBox';
import { OfferErrorBox } from './components/OffersError';
import { PcbDetailedOffers } from './components/PcbDetailedOffers';
import { PcbSimpleOffers } from './components/PcbSimpleOffers';
import { checkIfDetailedOffersIsPending, usePcbOffersState } from './utils/usePcbOfferState';

const PcbOffers = ({
    offers,
    rfqId,
    pcbId,
    assemblyId,
    isRfqEditable,
}: {
    offers: CustomPartOfferResponse;
    rfqId: string;
    assemblyId: string;
    pcbId: string;
    isRfqEditable: boolean;
}) => {
    const isCustomer = useUserType() === UserType.Customer;
    const organizationName = useCurrentUserDetailsContext().organization.name;

    switch (offers.type) {
        case 'NoSourcingScenarios':
            if (isCustomer) {
                return (
                    <OfferErrorBox title={t`No order scenarios created`}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                We are unable to provide pricing information for this PCB at the moment, as no order
                                scenarios have been created
                            </Trans>
                        </Text>
                        {isRfqEditable && (
                            <SecondaryButton
                                size="medium"
                                startIcon={<Add />}
                                href={route('/rfqs/:rfqId/edit', { rfqId })}
                                style={{ marginBlockStart: '8px' }}
                            >
                                <Trans>Add order scenario</Trans>
                            </SecondaryButton>
                        )}
                    </OfferErrorBox>
                );
            } else {
                return (
                    <OfferErrorBox title={t`No sourcing scenarios created`}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                We are unable to provide pricing information for this PCB at the moment, as no sourcing
                                scenarios have been created
                            </Trans>
                        </Text>

                        <SecondaryButton
                            size="medium"
                            startIcon={<Add />}
                            href={route('/rfqs/:rfqId/sourcing/scenarios/new', { rfqId })}
                            style={{ marginBlockStart: '8px' }}
                        >
                            <Trans>Add sourcing scenario</Trans>
                        </SecondaryButton>
                    </OfferErrorBox>
                );
            }

        case 'NoSuppliersConfigured':
            if (isCustomer) {
                return (
                    <OfferErrorBox title={t`No suppliers configured`} Icon={LocalShippingIcon}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                Pricing information for this PCB is currently unavailable. This is due to the absence of
                                approved PCB suppliers as no PCB suppliers have been configured by {organizationName}.
                            </Trans>
                        </Text>
                    </OfferErrorBox>
                );
            }
            return (
                <OfferErrorBox title={t`No suppliers configured`} Icon={LocalShippingIcon}>
                    <Text
                        variant="body-small"
                        style={{
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <Trans>
                            There are no approved PCB suppliers in any of the current RfQ sourcing scenarios. Please,
                            adjust your solution preferences.
                        </Trans>
                    </Text>
                    <SecondaryButton
                        size="small"
                        href={route('/rfqs/:rfqId/sourcing', { rfqId })}
                        style={{ marginBlockStart: '8px' }}
                    >
                        <Trans>Go to sourcing</Trans>
                    </SecondaryButton>
                </OfferErrorBox>
            );

        case 'AnalysisInProgress':
            return <OfferStateLoadingBox />;

        case 'Detailed':
            return <PcbDetailedOffers offers={offers} rfqId={rfqId} assemblyId={assemblyId} pcbId={pcbId} />;

        case 'Simple':
            return <PcbSimpleOffers offers={offers} rfqId={rfqId} assemblyId={assemblyId} pcbId={pcbId} />;
    }
};

export const PcbPriceRadar = ({
    pcb,
    rfqId,
    assemblyId,
    isRfqEditable,
}: {
    pcb: PCBV2;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
}) => {
    const { data: offers, isLoading: isLoadingOfferState } = usePcbOffersState({ pcb, assemblyId });
    const isCustomer = useIsCustomer();

    if (!offers) return null;

    const isDetailedOffersType = offers.status.type === 'Detailed';
    const isOneOfDetailedOffersPending = checkIfDetailedOffersIsPending(offers.status);
    const numberOfPendingOffers =
        offers.status.type === 'Detailed'
            ? offers.status.data.filter((offer) => offer.status.type === 'Pending').length
            : 0;

    const isLoading = isLoadingOfferState || isOneOfDetailedOffersPending;
    const shouldRadarIconLightUp =
        isDetailedOffersType ||
        isLoadingOfferState ||
        (offers.status.type === 'Simple' && offers.status.data.type === 'OffersAvailable');

    return (
        <Box
            style={{
                background: colorSystem.neutral[1],
                borderRadius: '8px',
                padding: '16px',
                width: '350px',
                transition: 'all 0.2s ease-in',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}
            id={id('design/box_pcb_price_radar')}
        >
            <Flexbox justifyContent={'space-between'} flexDirection={'column'} gap={'8px'}>
                <Flexbox alignItems={'center'} gap={4}>
                    {isLoading ? (
                        <Tooltip title={t`Offer is pending`} placement="top">
                            <CircularProgress size={20} style={{ marginInlineEnd: '2px' }} />
                        </Tooltip>
                    ) : (
                        <Radar
                            style={{
                                color: shouldRadarIconLightUp ? colorSystem.primary[6] : colorSystem.neutral[6],

                                marginInlineStart: '-2px',
                            }}
                        />
                    )}
                    <Text variant="h3">{isCustomer ? t`PCB Radar` : t`PCB Price Radar`}</Text>
                </Flexbox>

                {isOneOfDetailedOffersPending && (
                    <Text variant="caption" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>
                            {numberOfPendingOffers} suppliers may not be visible as we validate their API. Please wait
                            until the loading process is complete to view the entire list
                        </Trans>
                    </Text>
                )}
            </Flexbox>

            {isLoadingOfferState ? (
                <OfferStateLoadingBox />
            ) : (
                <PcbOffers
                    offers={offers.status}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    pcbId={pcb.id}
                    isRfqEditable={isRfqEditable}
                />
            )}
        </Box>
    );
};
