import { t, Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import {
    colorSystem,
    Column,
    DataTable,
    Flexbox,
    FormulaIcon,
    Row,
    TertiaryButton,
    Text,
    Tooltip,
    useDataTableState,
} from '@luminovo/design-system';
import { AutomaticDetailsDTO } from '@luminovo/http-client';
import { Box, TableCell, Theme, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { MaxWidthTextForCode } from '../../../../../components/MaxWidthText';
import { InputControl } from '../../../../../components/MonacoEditor/editorTypes';
import { SearchInput } from '../../../../../components/SearchInput';
import {
    aggregationTypePublicTranslations,
    aggregationValuePublicTranslations,
} from '../../../../../resources/driver/driverBackendTypes';
import { alphaNumericalStringSort } from '../../../../../utils/stringFunctions';
import { CalculationTextWrapper, getFormulaDetails } from '../../../DriversView';

const textCellSelectionColor = colorSystem.primary[1];

const Description = ({ data }: { data: InputControl }): JSX.Element => {
    const isScreenExtraLarge = useMediaQuery((theme: Theme) => {
        /* eslint-disable spellcheck/spell-checker*/
        return theme.breakpoints.up('xl');
    });
    return (
        <>
            {typeof data.automaticDetails === 'undefined' ? (
                <Text variant="code">''</Text>
            ) : (
                <Box maxWidth={isScreenExtraLarge ? '600px' : '400px'}>
                    <CalculationTextWrapper automaticDetails={data.automaticDetails} />
                </Box>
            )}
        </>
    );
};

const getColumns = (onInsertClick: (text: string) => void): Column<InputControl>[] => {
    return [
        {
            label: <Trans>Name</Trans>,
            id: 'name',
            width: '30%',
            render: ({ data }: Row<InputControl>): JSX.Element => (
                <TableCell style={{ backgroundColor: data.isInFormula ? textCellSelectionColor : 'inherit' }}>
                    <Flexbox>
                        <MaxWidthTextForCode
                            text={data.formulaValue}
                            maxWidthLargeScreen={300}
                            maxWidthSmallScreen={300}
                        />
                    </Flexbox>
                </TableCell>
            ),
        },

        {
            label: <Trans>Description</Trans>,
            id: 'description',
            width: '30%',
            render: ({ data }: Row<InputControl>): JSX.Element => (
                <TableCell style={{ backgroundColor: data.isInFormula ? textCellSelectionColor : 'inherit' }}>
                    <Text>{data.notes}</Text>
                </TableCell>
            ),
        },
        {
            label: '', //intentionally empty
            id: 'formula',
            width: '5%',
            render: ({ data }: Row<InputControl>): JSX.Element => (
                <TableCell style={{ backgroundColor: data.isInFormula ? textCellSelectionColor : 'inherit' }}>
                    {data.automaticDetails && (
                        <Tooltip
                            variant={'white'}
                            title={
                                <FormulaIcon
                                    style={{
                                        border: `1px solid ${colorSystem.neutral[2]}`,
                                        padding: '4px',
                                        borderRadius: '20%',
                                    }}
                                    heightAndWidth="12"
                                    fill={colorSystem.neutral[6]}
                                />
                            }
                            placement={'right'}
                        >
                            <Flexbox gap={8} margin={2} flexDirection={'column'} width={'100%'}>
                                <Typography variant="h4">Calculation</Typography>
                                <Description data={data} />
                            </Flexbox>
                        </Tooltip>
                    )}
                </TableCell>
            ),
        },
        {
            label: '', //intentionally empty
            id: 'addButton',
            width: '5%',
            render: ({ data }: Row<InputControl>): JSX.Element => (
                <TableCell
                    style={{ backgroundColor: data.isInFormula ? textCellSelectionColor : 'inherit' }}
                    onClick={() => {
                        onInsertClick(data.formulaValue);
                    }}
                >
                    <Flexbox>
                        <TertiaryButton style={{ padding: '1px', width: '12px' }}>+</TertiaryButton>
                    </Flexbox>
                </TableCell>
            ),
        },
    ];
};

const getInputControlDetails = (inputControlDetails: undefined | AutomaticDetailsDTO): string[] => {
    return typeof inputControlDetails === 'undefined'
        ? []
        : [
              transEnum(inputControlDetails.aggregation.type, aggregationTypePublicTranslations),
              transEnum(inputControlDetails.aggregation.value, aggregationValuePublicTranslations),
              ...getFormulaDetails(inputControlDetails.filter_formula),
          ];
};

export const Inputs = ({
    inputControls,
    onInsertClick,
}: {
    inputControls: InputControl[];
    onInsertClick: (text: string) => void;
}): JSX.Element => {
    const [query, setQuery] = React.useState('');
    const dataTableState = useDataTableState({
        persistenceId: 'inputControlTable',
        storage: localStorage,
        columns: getColumns(onInsertClick),
        query,
        paginationOptions: { showPagination: true },
        searchOptions: {
            idExtractor: (inputControl) => inputControl.name,
            contentSearchOptions: {
                indexingStrategy: 'data-content',
                indexItemData: (item) => {
                    return [
                        item.name,
                        item.notes ?? '',
                        item.formulaValue,
                        ...getInputControlDetails(item.automaticDetails),
                    ];
                },
            },
        },
        items: inputControls
            .sort((i1, i2) => alphaNumericalStringSort(i1.name, i2.name)) //sort by name ...
            .sort((i1, i2) => alphaNumericalStringSort(i1.type, i2.type)) // then by type...
            .sort((i1, i2) => (i2.isInFormula && !i1.isInFormula ? 1 : -1)), // ...then by whether it is in the formula
    });

    return (
        <>
            <SearchInput
                value={query}
                onChange={setQuery}
                autoFocus
                placeholder={t`Search input controls`}
                style={{ marginBottom: '16px' }}
            />
            <DataTable tableState={dataTableState} key={'inputControlTable'} size={'medium'} />
        </>
    );
};
