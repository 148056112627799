import { DesignItemResponseDTO } from '@luminovo/http-client';
import { mapDesignItemResponsesDTONewToOld } from '../assembly/assemblyHandler';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { useHttpQuery } from '../http/useHttpQuery';

export function useDesignItem(designItem: string, enabled: boolean = true) {
    return useBulkSingleQuery(
        'POST /design-items/bulk',
        designItem,
        {
            idExtractor: (item: DesignItemResponseDTO) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids } };
            },
            select: (res) => mapDesignItemResponsesDTONewToOld(res.items, res.excel_lines),
        },
        {
            enabled,
        },
    );
}

export const useDesignItems = (designItems: string[] = []) => {
    return useBulkQuery('POST /design-items/bulk', designItems, {
        idExtractor: (item: DesignItemResponseDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => mapDesignItemResponsesDTONewToOld(res.items, res.excel_lines),
    });
};

export const useDesignItemPartAlternatives = (designItemId: string | undefined, canSeePartAlternatives: boolean) => {
    /// Currently not using useBulkQuery here since it keeps on creating a new object each time,eg, when a user moves from 1 bom-item to another
    /// which results in the re-computation of the expensive function `createBomItems` function

    const { data, isLoading } = useHttpQuery(
        'GET /design-items/:designItemId/part-alternatives',
        {
            pathParams: { designItemId: designItemId ?? '' },
        },
        { enabled: !!designItemId && canSeePartAlternatives, select: (response) => response },
    );

    return {
        data: data ?? [],
        isLoading,
    };
};
