/**
 * Initializes a new instance of the Intl.Collator class to perform language-sensitive string comparison.
 *
 *
 * The `undefined` argument in the constructor indicates that the runtime's default locale will be used for comparisons.
 * If a specific locale was desired (like 'en-US' or 'de-DE'), it could be specified instead.
 *
 * The options object passed in the second argument to the constructor configures the Collator's behavior:
 *
 * - `numeric: true`: This option enables numeric collation. This means that strings are sorted according to numeric
 *    value rather than Unicode value. For example, without numeric collation, '10' would come before '2'. But with
 *    numeric collation enabled, '2' comes before '10', as you would naturally expect.
 *
 * - `sensitivity: 'base'`: This option configures the Collator to consider only base characters (ignoring case and
 *    accents) when comparing strings. For example, in a base sensitive comparison, "a" = "A" and "a" = "á".
 */
const defaultCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

/**
 * Compare two strings based on their alphabetical order.
 *
 * This function takes two strings 'a' and 'b' and compares them based on their alphabetical order.
 *
 * @param a - The first string to compare.
 * @param b - The second string to compare.
 * @param collator - An optional collator object that defines the collation method to use. Please see
 * `defaultCollator` for more details about the default implementation.
 *
 * @returns A number indicating the result of the comparison:
 *   - If 'a' comes before 'b' in the alphabetical order, it returns a negative number.
 *   - If 'a' is the same as 'b' in the alphabetical order, it returns 0.
 *   - If 'a' comes after 'b' in the alphabetical order, it returns a positive number.
 *
 *
 *  */
export const compareByString = (a: string, b: string, collator: Intl.Collator = defaultCollator): number => {
    return collator.compare(a, b);
};

export function compareByStringKey<T>(extractString: (item: T) => string): (a: T, b: T) => number {
    return (a: T, b: T): number => {
        return defaultCollator.compare(extractString(a), extractString(b));
    };
}
