import { Box } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useCalculationWithoutManufacturingEnabled } from '../../../../resources/organizationSettings/calculationWithoutManufacturingHandler';
import {
    AdditionalProfitsAndDiscounts,
    GeneralSection,
    ManufacturingCosts,
    MaterialCosts,
    OtherCosts,
    PostProfitCosts,
    ProjectCosts,
} from './sections';
import { CalculationTemplateFormInput } from './utils/types';

const CalculationTemplateForm = ({
    defaultValues,
    onSubmit,
    templateId,
}: {
    defaultValues: CalculationTemplateFormInput;
    onSubmit: SubmitHandler<CalculationTemplateFormInput>;
    templateId?: string;
}): JSX.Element => {
    const { data: calculationWithoutManufacturingSettings } = useCalculationWithoutManufacturingEnabled();
    const shouldShowManufacturingCosts = !(
        calculationWithoutManufacturingSettings?.calculation_without_manufacturing_enabled ?? true
    );

    return (
        <FormContainer
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            // this key is required, otherwise the table will not be rerendered when the defaultValues change.
            // defaultValues are cached on the first render within the custom hook. https://react-hook-form.com/api/useform/#props
            key={JSON.stringify(defaultValues)}
        >
            <GeneralSection templateId={templateId} />
            <MaterialCosts />
            {shouldShowManufacturingCosts && <ManufacturingCosts />}
            <OtherCosts />
            <AdditionalProfitsAndDiscounts />
            <PostProfitCosts />
            <ProjectCosts />
            <Box marginTop={12}>
                <SubmitButton />
            </Box>
        </FormContainer>
    );
};

export default CalculationTemplateForm;
