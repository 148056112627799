import { Trans } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { ExpenseConfigurationSummaryDTO } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '../../../components/Spinners';
import { DriverDetailsRow } from '../shared/DriverDetailsRow';
import { useDriversOfExpenseConfiguration } from './useDriversOfExpenseConfiguration';
const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    marginBlockEnd: '8px',
});

export const ExpenseConfigurationDrivers = ({ expense }: { expense: ExpenseConfigurationSummaryDTO }): JSX.Element => {
    const { uniqueDrivers, isLoading } = useDriversOfExpenseConfiguration(expense);
    if (isLoading) {
        return <Skeleton />;
    }
    return (
        <>
            {uniqueDrivers.length > 0 && (
                <Box padding={'24px'}>
                    <StyledTypography variant="h3">
                        <Trans>Used drivers</Trans>
                    </StyledTypography>
                    {uniqueDrivers?.map((driver, i) => {
                        return (
                            <Box key={i} marginBottom={'4px'}>
                                <DriverDetailsRow
                                    driverDetails={driver.driver}
                                    driverStatus={driver.driverStatus}
                                    countInformation={driver.countInformation}
                                    expenseConfigurationId={
                                        expense.expense_configuration_details.expense_configuration_id
                                    }
                                />
                            </Box>
                        );
                    })}
                </Box>
            )}
        </>
    );
};
