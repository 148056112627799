import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseIPC600Class } from '../parsers/parseIpc600Class';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractIpc600Class: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const attribute = parseIPC600Class(stringToParse);
    return isPresent(attribute)
        ? [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute))]
        : [];
};
