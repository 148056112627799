import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';

export type SolderMaskColors =
    | 'green'
    // eslint-disable-next-line spellcheck/spell-checker
    | 'green-matt'
    | 'green-glossy'
    | 'white'
    | 'blue'
    | 'red'
    | 'black'
    | 'yellow'
    | 'purple';

export const solderMaskColorTranslations: Record<SolderMaskColors, MessageDescriptor> = {
    'green-glossy': defineMessage({ message: 'green-glossy' }),
    // eslint-disable-next-line spellcheck/spell-checker
    'green-matt': defineMessage({ message: 'green-matt' }),
    black: defineMessage({ message: 'black' }),
    blue: defineMessage({ message: 'blue' }),
    green: defineMessage({ message: 'green' }),
    purple: defineMessage({ message: 'purple' }),
    red: defineMessage({ message: 'red' }),
    white: defineMessage({ message: 'white' }),
    yellow: defineMessage({ message: 'yellow' }),
};
