import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { Typography } from '@mui/material';
import { MaxWidthText } from '../../../../components/MaxWidthText';

interface NotesProps {
    notes: string | undefined;
    tooltip?: boolean;
}

export const Notes: React.FunctionComponent<NotesProps> = ({ notes, tooltip = false }) => {
    return (
        <Flexbox alignItems="center">
            <Typography
                variant="h4"
                style={{
                    marginRight: '8px',
                    color: colorSystem.neutral[6],
                }}
            >
                <Trans>Notes</Trans>
            </Typography>
            {notes ? (
                tooltip ? (
                    <MaxWidthText maxWidthSmallScreen={'400px'} maxWidthLargeScreen={'750px'} text={notes} />
                ) : (
                    <Typography
                        variant="body1"
                        style={{
                            whiteSpace: 'pre-line',
                            maxHeight: 400,
                            overflow: 'auto',
                            paddingRight: '8px',
                        }}
                    >
                        {notes}
                    </Typography>
                )
            ) : (
                <Typography>
                    <Trans>No notes added yet</Trans>
                </Typography>
            )}
        </Flexbox>
    );
};
