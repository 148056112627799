import { PackageDTO, PartSpecificationTypes, StandardPartTypes } from '@luminovo/http-client';
import { FullPartWithApprovalStatus } from '../../../../resources/part/partFrontendTypes';

export const getManufacturingPartsFormValues = (
    partWithApprovalStatus: FullPartWithApprovalStatus,
    packageData: PackageDTO[],
) => {
    if (partWithApprovalStatus.type === PartSpecificationTypes.Custom)
        return {
            mounting: undefined,
            package_name: null,
            number_of_pins: 2,
        };

    switch (partWithApprovalStatus.type) {
        case StandardPartTypes.OffTheShelf:
            return {
                mounting: partWithApprovalStatus.part.package?.mounting ?? undefined,
                package_name: partWithApprovalStatus.part.package?.name ?? null,
                number_of_pins: partWithApprovalStatus.part.package?.number_of_pins ?? undefined,
            };

        case StandardPartTypes.Generic:
            const packageFullDetails = packageData.find(
                (p) => p.id === partWithApprovalStatus.part.content.technical_parameters.package_id,
            );
            return {
                mounting: packageFullDetails?.mounting ?? undefined,
                package_name: packageFullDetails?.name ?? null,
                number_of_pins: 2,
            };

        case StandardPartTypes.Ipn:
            const formAndFit = partWithApprovalStatus.part.component_specification?.form_and_fit;
            return {
                mounting: formAndFit?.mounting ?? null,
                package_name: formAndFit?.name ?? null,
                number_of_pins: formAndFit?.number_of_pins ?? null,
            };

        default:
            return {
                mounting: null,
                package_name: null,
                number_of_pins: 2,
            };
    }
};
