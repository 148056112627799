import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useDigikeyAcquireAccessToken } from '../../../resources/3rdParty/digikeyHandler';
import { useQueryParams } from '../../../resources/hooks';
import { route } from '../../../utils/routes';

const DigikeyRedirectPage = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useQueryParams<'/3rdparty/digikey/callback'>();
    const codeString = code ?? '';
    const { isLoading, isError } = useDigikeyAcquireAccessToken(codeString, codeString !== '');
    const history = useHistory();
    if (isError) {
        enqueueSnackbar(t`Error Authenticating`, { variant: 'error' });
    }
    if (!isLoading) {
        history.push(route('/settings/organization/supplier-api-integrations'));
    }
    return <>{isLoading && <SpinnerWithBackdrop />}</>;
};

export default DigikeyRedirectPage;
