import { t } from '@lingui/macro';
import { transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import { FieldSelect, FieldSelectProps } from '@luminovo/design-system';
import { OperatorField } from '@luminovo/http-client';
import { operatorFieldTranslations } from '../../../i18n';

const sortedOptions = typeSafeObjectKeys(operatorFieldTranslations).sort((a, b) => a.localeCompare(b));

export function FieldScrapOperatorField(props: Omit<FieldSelectProps<OperatorField>, 'options'>): JSX.Element {
    return (
        <FieldSelect
            options={sortedOptions}
            getOptionLabel={formatOperatorField}
            placeholder={t`Operator field`}
            disableClearable
            {...props}
        />
    );
}

function formatOperatorField(operatorField: OperatorField): string {
    return transEnum(operatorField, operatorFieldTranslations);
}
