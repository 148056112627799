/**
 * Returns an array of unique objects from the input array based on a specified key or getter function.
 * @param arr - The input array.
 * @param key - The key to determine uniqueness or a getter function that extracts the key.
 * @returns An array of unique objects.
 */
export function uniqueBy<T>(arr: T[], key: keyof T | ((item: T) => unknown)): T[] {
    const uniqueArray: T[] = [];
    const uniqueKeys = new Set<unknown>();

    for (const obj of arr) {
        const keyValue = typeof key === 'function' ? key(obj) : obj[key];
        if (!uniqueKeys.has(keyValue)) {
            uniqueKeys.add(keyValue);
            uniqueArray.push(obj);
        }
    }

    return uniqueArray;
}
