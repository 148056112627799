/* eslint-disable camelcase */
import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { EmailTemplateDTORuntype } from './emailTemplateTypes';

export const emailTemplateEndpoints = {
    'GET /email-template': endpoint({
        description: 'Get email templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(EmailTemplateDTORuntype),
        }),
    }),
    'POST /email-template': endpoint({
        description: 'Create email template',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: EmailTemplateDTORuntype.omit('id', 'updated_at'),
        responseBody: r.Unknown,
        invalidates: ['GET /email-template'],
    }),

    'DELETE /email-template/:id': endpoint({
        description: 'Delete an email template',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['GET /email-template'],
    }),

    'PATCH /email-template/:id': endpoint({
        description: 'Update email template',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: EmailTemplateDTORuntype.omit('id', 'updated_at').Or(r.Record({ is_default: r.Boolean })),
        responseBody: r.Unknown,
        invalidates: ['GET /email-template'],
    }),
};
