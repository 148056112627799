import { Trans } from '@lingui/macro';
import { Text } from '@luminovo/design-system';
import { CalculationTemplatePostDTO, http } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { PageLayout } from '../../../../components/PageLayout';
import { usePageParams, useToken } from '../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { invalidateQuery, useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../../resources/organizationSettings/currencySettingsHandler';
import { route } from '../../../../utils/routes';
import CalculationTemplateForm from '../CalculationTemplateForm/CalculationTemplateForm';
import { convertCalculationTemplatePostToTemplateForm } from '../CalculationTemplateForm/utils/convertCalculationTemplatePostToCalculationForm';
import { convertTemplateFormToCalculationTemplatePost } from '../CalculationTemplateForm/utils/convertTemplateFormToCalculationTemplatePost';
import { CalculationTemplateFormInput } from '../CalculationTemplateForm/utils/types';

function useMutationEditCalculationTemplates(templateId: string) {
    const { token } = useToken();
    const onError = useDebugErrorHandler();
    const queryClient = useQueryClient();
    const { push } = useHistory();

    return useMutation({
        mutationFn: (requestBody: CalculationTemplatePostDTO) => {
            return http(
                'PATCH /calculations/templates/:templateId',
                { requestBody, pathParams: { templateId } },
                token,
            );
        },
        onSuccess: async () => {
            await invalidateQuery('GET /calculations/templates', {}, queryClient);
            invalidateQuery('GET /calculations/templates/:templateId', { pathParams: { templateId } }, queryClient);
            push(route('/calculation/templates'));
        },
        onError,
    });
}

const CalculationEditTemplatePage = (): JSX.Element => {
    const { templateId } = usePageParams<'/calculation/templates/:templateId/edit'>();
    const { data: calculationTemplateData } = useHttpQuery('GET /calculations/templates/:templateId', {
        pathParams: { templateId },
    });
    const { preferredCurrency } = useGlobalCurrency();

    const { mutateAsync } = useMutationEditCalculationTemplates(templateId);

    const onSubmit = (data: CalculationTemplateFormInput) => {
        const patchDTO = convertTemplateFormToCalculationTemplatePost(data);
        return mutateAsync(patchDTO);
    };

    if (calculationTemplateData && preferredCurrency) {
        return (
            <PageLayout layout="full-width">
                <Box width={'1052px'} paddingLeft={'180px'} marginTop={'32px'}>
                    <Text variant="h1">
                        <Trans>Edit calculation template</Trans>
                    </Text>

                    <CalculationTemplateForm
                        defaultValues={convertCalculationTemplatePostToTemplateForm(
                            calculationTemplateData.data,
                            preferredCurrency,
                        )}
                        templateId={templateId}
                        onSubmit={onSubmit}
                    />
                </Box>
            </PageLayout>
        );
    }

    return <CircularProgress />;
};

export default CalculationEditTemplatePage;
