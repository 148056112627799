import { Trans } from '@lingui/macro';
import { Flexbox, Text } from '@luminovo/design-system';
import { useUniversalImporter } from '../../context';
import { FieldDescriptions } from '../link/StepLinkColumns';

export function StepUploadHelpDialog(): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    return (
        <Flexbox flexDirection={'column'} gap={20}>
            <Text>
                <Trans>Please upload a file with the following columns, in any order.</Trans>
            </Text>
            <FieldDescriptions fields={config.fields} showLinked={false} useColumnsLabel />
        </Flexbox>
    );
}
