import { t, Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import {
    colorSystem,
    FieldCheckboxControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    Text,
} from '@luminovo/design-system';
import { OtsFullPart } from '@luminovo/http-client';
import { InfoOutlined } from '@mui/icons-material';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CancelButton } from '../../../../components/formLayouts/CancelButton';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { NotificationBox } from '../../../../components/Notifications/NotificationBox';
import {
    AlertConditionEnum,
    alertConditionTranslations,
    AlertCreationForm,
} from '../../../../resources/alerts/alertTypes';

const VerticalFormSection = ({ titleText, children }: React.PropsWithChildren<{ titleText: string }>) => {
    return (
        <Flexbox flexDirection={'column'} gap={'4px'}>
            <Text variant="h4" style={{ color: colorSystem.neutral['7'] }}>
                {titleText}
            </Text>
            {children}
        </Flexbox>
    );
};

interface AlertFormProps {
    part: OtsFullPart;
    onClose: () => void;
}

export const AlertForm = ({ part, onClose }: AlertFormProps) => {
    const { control, resetField } = useFormContext<AlertCreationForm>();

    const condition = useWatch({ control, name: 'condition' });
    return (
        <Flexbox flexDirection={'column'} gap={'24px'}>
            <NotificationBox
                color={'neutral'}
                text={t`Alert notifications will only be sent to the user who created the alert.`}
            />
            <VerticalFormSection titleText={t`Part`}>
                <Flexbox flexDirection={'column'} gap={8}>
                    <Text variant="h2" color={colorSystem.neutral[8]}>
                        {part.mpn}
                    </Text>
                    <Text variant="h3" color={colorSystem.neutral[6]}>
                        {part.manufacturer.name}
                    </Text>
                </Flexbox>
            </VerticalFormSection>
            <VerticalFormSection titleText={t`Trigger alert when`}>
                <Flexbox gap="8px">
                    <FieldSelectControlled
                        name={'condition'}
                        control={control}
                        FieldProps={{
                            options: Object.values(AlertConditionEnum),
                            getOptionLabel: (option) => transEnum(option, alertConditionTranslations),
                            disableClearable: true,
                            afterChange: () => resetField('conditionContent'),
                            style: { width: '256px' },
                        }}
                    />
                    {condition === AlertConditionEnum.Gte && (
                        <FieldNumericControlled
                            control={control}
                            required={true}
                            name={'conditionContent'}
                            min={2}
                            isInteger={true}
                        />
                    )}
                </Flexbox>
            </VerticalFormSection>
            <VerticalFormSection titleText={t`Supplier preference`}>
                <Text>
                    <Trans>By default, we check offers only from your preferred and approved suppliers.</Trans>
                </Text>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="useNotApprovedSuppliers"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text>
                        <Trans>Also check offers from not approved suppliers</Trans>
                    </Text>
                </Flexbox>
            </VerticalFormSection>
            <VerticalFormSection titleText={t`Notes`}>
                <FieldTextControlled name={'notes'} control={control} FieldProps={{ multiline: true, rows: 3 }} />
            </VerticalFormSection>
            <Flexbox alignItems="center" gap={4} style={{ marginTop: 16 }}>
                <InfoOutlined fontSize="inherit" style={{ color: colorSystem.neutral[6] }} />
                <Text style={{ color: colorSystem.neutral[6] }}>
                    <Trans>
                        You can update the channel preferences using the bell icon in the navigation bar in the
                        top-right corner.
                    </Trans>
                </Text>
            </Flexbox>
            <Flexbox gap={'8px'} justifyContent={'flex-end'}>
                <CancelButton onClick={() => onClose()} />
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
};
