import { t } from '@lingui/macro';
import { BannerCard } from './BannerCard';

export const BannerCardOrderInProduction = (): JSX.Element => {
    return (
        <BannerCard
            background="linear-gradient(83.52deg, #6FCEFF -1.88%, #6F7FFF 120.44%)"
            title={t`Your order is now in production!`}
            description={t`You will receive a notification once the order is shipped.`}
            icon="🏭"
        />
    );
};
