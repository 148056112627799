import {
    CostCellDTO,
    CustomCostDTO,
    DriverStatusWithDriverIdDTO,
    MissingOfferDriverStatusDTO,
    ScenarioCostDTO,
} from '@luminovo/http-client';
import { CalculationWarning } from './types';

export function costCellMissingOfferWarnings(cost: CostCellDTO | null): MissingOfferDriverStatusDTO[] {
    if (cost?.cost_specification.type === 'Formula') {
        return cost.cost_specification.data.statuses.flatMap((status: DriverStatusWithDriverIdDTO) => {
            if (status.status.type === 'Warning' && status.status.data.type === 'MissingOffer') {
                return status.status.data;
            }
            return [];
        });
    }
    return [];
}

function customCostMissingOfferWarnings(customCost: CustomCostDTO): MissingOfferDriverStatusDTO[] {
    return [
        ...costCellMissingOfferWarnings(customCost.profit),
        ...costCellMissingOfferWarnings(customCost.discount),
        ...customCost.extra_costs.flatMap((extra) => costCellMissingOfferWarnings(extra.cost)),
    ];
}

function getSourcingScenariosStatusMissingOfferWarnings(
    scenarioCost: ScenarioCostDTO[],
): MissingOfferDriverStatusDTO[] {
    return scenarioCost.flatMap((cost) => {
        return cost.batch_size_costs.flatMap((bsCost) => {
            const missingOfferWarnings: MissingOfferDriverStatusDTO[] = [
                ...customCostMissingOfferWarnings(bsCost.cost.material_cost.custom_cost),
                ...customCostMissingOfferWarnings(bsCost.cost.manufacturing_cost.custom_cost),
                ...costCellMissingOfferWarnings(bsCost.cost.additional_cost.discount),
                ...costCellMissingOfferWarnings(bsCost.cost.additional_cost.profit),
                ...bsCost.cost.additional_cost.post_profit_costs.flatMap((extra) =>
                    costCellMissingOfferWarnings(extra.cost),
                ),
                ...bsCost.cost.additional_cost.other_costs.flatMap((extra) => costCellMissingOfferWarnings(extra.cost)),

                ...costCellMissingOfferWarnings(bsCost.cost.project_cost.profit),
            ];
            return missingOfferWarnings;
        });
    });
}

function bomWarningsInCostCell(cost: CostCellDTO | null): CalculationWarning[] {
    if (cost?.cost_specification.type === 'Formula') {
        const mapOfBooleans: CalculationWarning[] = cost.cost_specification.data.statuses.flatMap(
            (status: DriverStatusWithDriverIdDTO) => {
                return status.status.type === 'Warning' && status.status.data.type === 'MissingDesignItemInformation'
                    ? status.status.data
                    : { type: 'Ok' };
            },
        );

        return mapOfBooleans;
    }
    return [];
}

function warningsInCustomCost(customCost: CustomCostDTO): CalculationWarning[] {
    return [
        ...bomWarningsInCostCell(customCost.profit),
        ...bomWarningsInCostCell(customCost.discount),
        ...customCost.extra_costs.flatMap((extra) => bomWarningsInCostCell(extra.cost)),
    ];
}

export function collateCalculationWarnings(scenarioCost: ScenarioCostDTO[]): CalculationWarning[] {
    return scenarioCost.flatMap((cost) => {
        return cost.batch_size_costs.flatMap((bsCost) => {
            return [
                ...warningsInCustomCost(bsCost.cost.material_cost.custom_cost),
                ...warningsInCustomCost(bsCost.cost.manufacturing_cost.custom_cost),
                ...costCellMissingOfferWarnings(bsCost.cost.additional_cost.discount),
                ...costCellMissingOfferWarnings(bsCost.cost.additional_cost.profit),
                ...bsCost.cost.additional_cost.post_profit_costs.flatMap((extra) => bomWarningsInCostCell(extra.cost)),
                ...bsCost.cost.additional_cost.other_costs.flatMap((extra) => bomWarningsInCostCell(extra.cost)),
                ...costCellMissingOfferWarnings(bsCost.cost.project_cost.profit),
                ...getSourcingScenariosStatusMissingOfferWarnings(scenarioCost),
            ];
        });
    });
}
