import { DefaultHeaderTableCell, colorSystem } from '@luminovo/design-system';
import { styled } from '@mui/material';

export const StickyRightHeaderTableCell = styled(DefaultHeaderTableCell)({
    position: 'sticky',
    right: 0,
    background: 'white',
    boxShadow: `inset 1px 0px 0px 0px ${colorSystem.neutral[2]}`,
});

export const StickyLeftHeaderTableCell = styled(DefaultHeaderTableCell)({
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: `inset -1px 0px 0px 0px  ${colorSystem.neutral[2]}`,
});
