import { FieldValues, useFormContext } from 'react-hook-form';
import { ContributorAutoComplete, ContributorAutoCompleteProps } from '../../ContributorAutocomplete';
import { ContributorOption } from '../../ContributorsFormUtils';

type ContributorAutoCompleteFormInputProps<T extends FieldValues> = Omit<ContributorAutoCompleteProps<T>, 'name'>;
export function ContributorAutoCompleteFormInput<T extends FieldValues>({
    options,
    onAddNewUser,
    TextFieldProps,
    AutocompleteProps,
}: ContributorAutoCompleteFormInputProps<T>) {
    const { control } = useFormContext<{ contributors: ContributorOption[] }>();

    return (
        <ContributorAutoComplete
            name={'contributors'}
            control={control}
            options={options}
            onAddNewUser={onAddNewUser}
            TextFieldProps={TextFieldProps}
            AutocompleteProps={AutocompleteProps}
        />
    );
}
