import { colorSystem, Text as DesignSystemText, Flexbox, Tooltip } from '@luminovo/design-system';

export function Legend<TKeys extends string>({
    keys,
    getColor,
    formatKey,
    getTooltip = () => undefined,
    getKeyCount,
}: {
    keys: TKeys[];
    getColor?: (key: TKeys) => string;
    formatKey: (key: TKeys) => string;
    getTooltip?: (key: TKeys) => string | undefined;
    getKeyCount?: (key: TKeys) => number;
}): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 24,
                justifyContent: 'space-between',
            }}
        >
            {keys.map((key, i) => {
                return (
                    <Tooltip title={getTooltip(key) ?? ''} key={i} placement="top">
                        <Flexbox gap="4px" alignItems="center" paddingRight="4px">
                            <div
                                style={{
                                    borderRadius: 2,
                                    width: 8,
                                    height: 8,
                                    backgroundColor: getColor!(key),
                                }}
                            />

                            <DesignSystemText color={colorSystem.neutral[6]}>
                                {formatKey(key)}{' '}
                                {getKeyCount && (
                                    <span style={{ color: colorSystem.neutral[9] }}>{getKeyCount(key)}</span>
                                )}
                            </DesignSystemText>
                        </Flexbox>
                    </Tooltip>
                );
            })}
        </div>
    );
}
