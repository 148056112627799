import { Trans } from '@lingui/macro';
import { groupBy } from '@luminovo/commons';
import { Flexbox, LightningBoltIcon, Text, Tooltip } from '@luminovo/design-system';
import { ConfigurationCount } from '@luminovo/http-client';
import { Divider, List, ListItem } from '@mui/material';
import { colorSystem } from '../../../../../themes';
import { LinkedDrivers } from './LinkedDrivers';

export interface InconsistentDriverPopupProps {
    assemblyWideDriverCount: string | undefined;
    configurationCounts: ConfigurationCount[];
}

const InconsistentDriverList = ({
    assemblyWideDriverCount,
    configurationCounts,
}: InconsistentDriverPopupProps): JSX.Element => {
    const configurationCountsGroupedByManufacturingScenario = groupBy(configurationCounts, 'scenario_name');

    return (
        <List>
            <ListItem>
                <Text variant="h3" style={{ color: colorSystem.neutral[8] }}>
                    <Trans>Inconsistent driver counts</Trans>
                </Text>
            </ListItem>
            <Divider />
            <ListItem>
                <Flexbox width={'100%'} justifyContent={'space-between'}>
                    <Text variant="h4" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>Assembly wide count</Trans>
                    </Text>
                    <LinkedDrivers type="Single value" value={assemblyWideDriverCount} isOverwritten />
                </Flexbox>
            </ListItem>
            <Divider />
            {Object.entries(configurationCountsGroupedByManufacturingScenario).map(([scenarioName, entities]) => (
                <>
                    <ListItem key={scenarioName}>
                        <Text variant="h4" style={{ color: colorSystem.neutral[6] }}>
                            {scenarioName}
                        </Text>
                    </ListItem>
                    {entities.map((entity) => (
                        <ListItem key={entity.entity_name}>
                            <Flexbox width={'100%'} justifyContent={'space-between'}>
                                <Text>{entity.entity_name}</Text>
                                <Flexbox>
                                    <LightningBoltIcon fill={colorSystem.yellow[7]} />
                                    <Text>{entity.driver_count}</Text>
                                </Flexbox>
                            </Flexbox>
                        </ListItem>
                    ))}
                </>
            ))}
        </List>
    );
};

export const InconsistentDriverPopup = ({
    configurationCounts,
    assemblyWideDriverCount,
}: InconsistentDriverPopupProps): JSX.Element => {
    return (
        <Tooltip
            variant="white"
            arrow={false}
            title={
                <InconsistentDriverList
                    assemblyWideDriverCount={assemblyWideDriverCount}
                    configurationCounts={configurationCounts}
                />
            }
            style={{ backgroundColor: colorSystem.neutral[0] }}
        >
            <span>
                <Flexbox
                    width="20px"
                    height="20px"
                    justifyContent="center"
                    alignItems="center"
                    style={{ backgroundColor: colorSystem.yellow[2] }}
                    borderRadius="2px"
                >
                    <LightningBoltIcon size="15px" fill={colorSystem.yellow[8]} />
                </Flexbox>
            </span>
        </Tooltip>
    );
};
