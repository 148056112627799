import { Trans, plural, t } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import { Flexbox, Message, RightBoxDrawer, SummaryCard, Tag, Text, colorSystem } from '@luminovo/design-system';
import {
    DemandScenarioDTO,
    PrototypeDemandDTO,
    PrototypeDemandScenarioDTO,
    RfqDTO,
    SeriesDemandDTO,
    SeriesDemandScenarioDTO,
} from '@luminovo/http-client';
import { DemandScenarioTypeTag, extractQuantityOfDemandScenario } from '@luminovo/manufacturing-core';
import { TimelineConnector, TimelineDot, TimelineSeparator } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import { CloseDrawerButton, useDrawerContext } from '../../components/contexts/ModalContext';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { DeleteDemandScenarioButton } from './DeleteDemandScenarioButton';
import { EditDemandScenarioButton } from './EditDemandScenarioButton';
import { DemandSourcingMismatch } from './demandSourcingMismatch';

const TimeFrameCard = ({ demandScenario }: { demandScenario: SeriesDemandScenarioDTO }) => {
    return (
        <SummaryCard
            title={t`Time frame`}
            value={`${demandScenario.start_year} - ${demandScenario.end_year}`}
            description={plural(demandScenario.end_year - demandScenario.start_year + 1, {
                one: `${demandScenario.end_year - demandScenario.start_year + 1} year`,
                other: `${demandScenario.end_year - demandScenario.start_year + 1} years`,
            })}
            style={{
                background: colorSystem.neutral.white,
            }}
        />
    );
};

const DesiredDateCard = ({ demandScenario }: { demandScenario: PrototypeDemandScenarioDTO }) => {
    const firstTargetDate = demandScenario.demands[0].delivery_date.target_date;
    return (
        <SummaryCard
            title={t`Desired date`}
            value={firstTargetDate.type === 'Date' ? formatToLongDate(firstTargetDate.value) : t`As soon as possible`}
            //Needs to be a - and not blank otherwise the box has a background of grey.
            description="-"
            style={{
                background: colorSystem.neutral.white,
            }}
        />
    );
};

const TimeCard = ({ demandScenario }: { demandScenario: DemandScenarioDTO }) => {
    if (demandScenario.type === 'Prototype') {
        return <DesiredDateCard demandScenario={demandScenario} />;
    }
    return <TimeFrameCard demandScenario={demandScenario} />;
};

const SeriesDemandTimeline = ({ seriesDemand }: { seriesDemand: SeriesDemandDTO }) => {
    return (
        <Timeline
            sx={{
                marginLeft: '-40px',
                marginTop: '-8px',
            }}
        >
            {seriesDemand.annual_demands.map((annualDemand, index) => {
                return (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                            <TimelineDot />
                            {index !== seriesDemand.annual_demands.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            <Flexbox width={'600px'} alignItems={'top'}>
                                <Text
                                    variant="h4"
                                    style={{
                                        color: colorSystem.neutral[6],
                                    }}
                                >
                                    {annualDemand.year}
                                </Text>
                                <Flexbox flexDirection={'column'} gap={'8px'} marginLeft={'24px'}>
                                    <Flexbox>
                                        <Text
                                            style={{
                                                color: colorSystem.neutral[6],
                                                marginRight: 8,
                                            }}
                                        >
                                            <Trans>Annual demand:</Trans>
                                        </Text>
                                        <Text>{annualDemand.quantity}</Text>
                                    </Flexbox>
                                    {annualDemand.delivery_batch_sizes.length > 0 && (
                                        <Flexbox>
                                            <Text
                                                style={{
                                                    color: colorSystem.neutral[6],
                                                    marginRight: 8,
                                                }}
                                            >
                                                <Trans>Delivery batch sizes:</Trans>
                                            </Text>
                                            <Flexbox key={index} gap={12}>
                                                {annualDemand.delivery_batch_sizes.map((batchSize, index) => {
                                                    return (
                                                        <Tag
                                                            key={index}
                                                            color="neutral"
                                                            label={batchSize.quantity.toString()}
                                                        />
                                                    );
                                                })}
                                            </Flexbox>
                                        </Flexbox>
                                    )}
                                </Flexbox>
                            </Flexbox>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    );
};

const PrototypeDemandOrderSize = ({ prototypeDemand }: { prototypeDemand: PrototypeDemandDTO }) => {
    return (
        <Flexbox>
            <Text style={{ color: colorSystem.neutral[6] }}>Quantity:</Text>
            <Text> {prototypeDemand.delivery_date.quantity} </Text>
        </Flexbox>
    );
};

const AssemblyDesignator = ({ assemblyId }: { assemblyId: string }) => {
    const { data } = useHttpQuery('GET /assemblies/:assemblyId/data', {
        pathParams: {
            assemblyId,
        },
    });
    if (!data) return null;
    return <Text variant="h3">{data.designator}</Text>;
};

const getNumberOfAssembliesOfDemandScenario = (demandScenario: DemandScenarioDTO) => {
    return new Set(demandScenario.demands.map((ds) => ds.assembly_id)).size;
};

export const useDemandScenariosDrawer = ({
    demandSourcingMismatch,
}: {
    demandSourcingMismatch: DemandSourcingMismatch;
}) => {
    const { setDrawer, closeDrawer, isOpen } = useDrawerContext();

    const mismatchedDemand =
        demandSourcingMismatch.type === 'prototype'
            ? 'prototype'
            : demandSourcingMismatch.type === 'series'
              ? demandSourcingMismatch.year
              : undefined;

    return {
        isOpen,
        openDrawer: ({
            demandScenario,
            indexNumber,
            rfq,
        }: {
            demandScenario: DemandScenarioDTO;
            indexNumber: number;
            rfq: RfqDTO;
        }) => {
            return setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox
                        alignItems="center"
                        justifyContent="space-between"
                        paddingRight={'16px'}
                        marginTop={'12px'}
                    >
                        <CloseDrawerButton />
                        <Flexbox gap={8}>
                            <DeleteDemandScenarioButton demandScenarioId={demandScenario.id} onSuccess={closeDrawer} />
                            <EditDemandScenarioButton
                                demandScenario={demandScenario}
                                index={indexNumber}
                                rfq={rfq}
                                onSuccess={closeDrawer}
                            />
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} paddingBottom={'200px'} minWidth={'540px'} padding={'16px'}>
                        <Flexbox alignItems={'center'} gap={'8px'} marginBottom={'16px'} marginTop={'-8px'}>
                            <Text variant="h2">
                                <Trans> Demand scenario </Trans> {indexNumber + 1}
                            </Text>
                            <DemandScenarioTypeTag type={demandScenario.type} />
                        </Flexbox>
                        <Flexbox gap={'16px'} flexDirection={'row'}>
                            <SummaryCard
                                title={t`Total quantity`}
                                value={extractQuantityOfDemandScenario(demandScenario).toString()}
                                description={plural(getNumberOfAssembliesOfDemandScenario(demandScenario), {
                                    one: `${getNumberOfAssembliesOfDemandScenario(demandScenario)} top level assembly`,
                                    other: `${getNumberOfAssembliesOfDemandScenario(
                                        demandScenario,
                                    )} top level assemblies`,
                                })}
                                style={{
                                    background: colorSystem.neutral.white,
                                }}
                            />
                            <TimeCard demandScenario={demandScenario} />
                        </Flexbox>
                        <Flexbox flexDirection={'column'} alignItems={'flex-start'} marginTop={'16px'}>
                            {demandScenario.type === 'Series' && (
                                <Flexbox flexDirection={'column'} gap={'8px'} marginTop={'8px'}>
                                    {demandScenario.demands.map((seriesDemand, index) => (
                                        <Flexbox flexDirection={'column'} key={index}>
                                            <AssemblyDesignator assemblyId={seriesDemand.assembly_id} />
                                            <SeriesDemandTimeline seriesDemand={seriesDemand} />
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            )}
                            {demandScenario.type === 'Prototype' && (
                                <Flexbox flexDirection="column" gap={16}>
                                    {demandScenario.demands.map((prototypeDemand, index) => (
                                        <Flexbox key={index} flexDirection="column" gap={8}>
                                            <AssemblyDesignator assemblyId={prototypeDemand.assembly_id} />
                                            <PrototypeDemandOrderSize prototypeDemand={prototypeDemand} />
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            )}
                        </Flexbox>
                        {mismatchedDemand !== undefined && (
                            <Flexbox mt={2}>
                                <Message
                                    variant={'yellow'}
                                    attention={'high'}
                                    size={'large'}
                                    title={t`Demand does not match the sourcing scenarios`}
                                    message={t`There is no sourcing scenario matching the ${mismatchedDemand} demand.`}
                                />
                            </Flexbox>
                        )}
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
};
