/**
 * An alternative to Object.keys which offers typings
 * https://github.com/microsoft/TypeScript/pull/12253#issuecomment-353494273
 * Note that this has some limitations
 * https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
 */
export function typeSafeObjectKeys<O extends object>(o: O): (keyof O)[] {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.keys(o) as (keyof O)[];
}
