import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { Comment } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const NotesLabel = ({ notes }: { notes: string }) => {
    return (
        <Tooltip title={notes}>
            <span>
                <Flexbox
                    gap={'4px'}
                    alignItems="center"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Comment
                        fontSize="inherit"
                        style={{
                            color: colorSystem.neutral[7],
                        }}
                    />
                    <Text
                        style={{
                            minWidth: '240px',
                            maxWidth: '240px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        variant="body-small"
                    >
                        {notes}
                    </Text>
                </Flexbox>
            </span>
        </Tooltip>
    );
};
