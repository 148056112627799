import * as r from 'runtypes';
import { DepanelizationRuntype } from './pcbPanelPreferenceBackendTypes';

// POST
export type PanelDetailsPostDTO = r.Static<typeof PanelDetailsPostRuntype>;
export const PanelDetailsPostRuntype = r.Record({
    row_count: r.Number,
    column_count: r.Number,
    horizontal_spacing_in_mm: r.String,
    vertical_spacing_in_mm: r.String,
    min_milling_distance_in_mm: r.String,
    max_x_outs: r.Number.optional().nullable(),
    panel_preference: r.String.optional().nullable(),
    padding: r.Record({
        top_in_mm: r.String,
        bottom_in_mm: r.String,
        left_in_mm: r.String,
        right_in_mm: r.String,
    }),
    depanelization: DepanelizationRuntype,
    pcb_is_rotated: r.Boolean,
});

export type PerPcbPanelPostDTO = r.Static<typeof PerPcbPanelPostRuntype>;
export const PerPcbPanelPostRuntype = r.Record({
    type: r.Literal('PerPcb'),
    data: r.Record({
        pcb: r.String,
        panel_details: PanelDetailsPostRuntype,
    }),
});

export type PerSourcingScenarioPanelPostDTO = r.Static<typeof PerSourcingScenarioPanelPostRuntype>;
export const PerSourcingScenarioPanelPostRuntype = r.Record({
    type: r.Literal('PerSourcingScenario'),
    data: r.Record({
        sourcing_scenario: r.String,
        pcb: r.String,
        panel_details: PanelDetailsPostRuntype,
    }),
});

export type ExistingPanelPostDTO = r.Static<typeof ExistingPanelPostRuntype>;
export const ExistingPanelPostRuntype = r.Record({
    type: r.Literal('Existing'),
    data: r.Record({
        pcb: r.String,
        number_of_pcbs: r.Number,
        panel_width: r.String,
        panel_height: r.String,
        depanelization: DepanelizationRuntype,
        pcb_is_rotated: r.Boolean,
    }),
});

export type PanelPostDTO = r.Static<typeof PanelPostRuntype>;
export const PanelPostRuntype = PerPcbPanelPostRuntype.Or(PerSourcingScenarioPanelPostRuntype).Or(
    ExistingPanelPostRuntype,
);

// GET, differentiated because the ids are included in the response
export type PerPcbPanelDTO = r.Static<typeof PerPcbPanelRuntype>;
const PerPcbPanelRuntype = r.Record({
    type: r.Literal('PerPcb'),
    data: r.Record({
        pcb: r.String,
        panel_details: PanelDetailsPostRuntype.extend({
            id: r.String,
        }),
    }),
});

export type PerSourcingScenarioPanelDTO = r.Static<typeof PerSourcingScenarioPanelRuntype>;
const PerSourcingScenarioPanelRuntype = r.Record({
    type: r.Literal('PerSourcingScenario'),
    data: r.Record({
        sourcing_scenario: r.String,
        pcb: r.String,
        panel_details: PanelDetailsPostRuntype.extend({
            id: r.String,
        }),
    }),
});

export type ExistingPanelDTO = r.Static<typeof ExistingPanelRuntype>;
const ExistingPanelRuntype = r.Record({
    type: r.Literal('Existing'),
    data: r.Record({
        id: r.String,
        pcb: r.String,
        number_of_pcbs: r.Number,
        panel_width: r.String,
        panel_height: r.String,
        depanelization: DepanelizationRuntype,
        pcb_is_rotated: r.Boolean,
    }),
});

export type PanelDTO = r.Static<typeof PanelRuntype>;
export const PanelRuntype = PerPcbPanelRuntype.Or(PerSourcingScenarioPanelRuntype).Or(ExistingPanelRuntype);

export type PanelDetailsDTO = r.Static<typeof PanelDetailsRuntype>;
export const PanelDetailsRuntype = PanelDetailsPostRuntype.extend({ id: r.String });

// PATCH
export type PanelizationFormModeDTO = r.Static<typeof PanelizationFormModeRuntype>;
const PanelizationFormModeRuntype = r.Literal('Existing').Or(r.Literal('Details'));

export const PanelPatchRuntype = r
    .Record({
        type: r.Literal('Details'),
        data: PanelDetailsRuntype,
    })
    .Or(ExistingPanelRuntype);

// Offer Panel Specification
export type PcbPanelSpecificationDTO = r.Static<typeof PcbPanelSpecificationRuntype>;
export const PcbPanelSpecificationRuntype = r.Record({
    type: r.Literal('Pcb'),
    data: r.Record({
        pcb: r.String,
        panel: PanelDetailsPostRuntype,
    }),
});
