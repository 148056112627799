import { Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { Edit } from '@mui/icons-material';
import {
    ManufacturingScenarioInfo,
    useEditManufacturingScenarioDialog,
} from '../ManufacturingScenarioForm/useEditManufacturingScenarioDialog';

export const EditManufacturingScenarioButton = ({
    manufacturingScenario,
    assemblyId,
    rfqId,
}: {
    manufacturingScenario: ManufacturingScenarioInfo;
    assemblyId: string;
    rfqId: string;
}): JSX.Element => {
    const { openDialog: openEditDialog } = useEditManufacturingScenarioDialog({
        rfqId,
        /* eslint-disable camelcase */
        assemblyId,
        manufacturingScenario: manufacturingScenario,
        /* eslint-enable camelcase */
    });

    return (
        <>
            <SecondaryButton onClick={openEditDialog} startIcon={<Edit />} size="medium">
                <Trans>Edit</Trans>
            </SecondaryButton>
        </>
    );
};
