import * as React from 'react';
import { Flexbox, FlexboxProps } from '../../Flexbox';

export const CenteredLayout: React.FunctionComponent<FlexboxProps> = (props) => {
    return (
        <Flexbox
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            {...props}
        />
    );
};
