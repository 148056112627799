import { Trans } from '@lingui/macro';
import { Chip } from '@luminovo/design-system';
import {
    ActorResponseRuntypeDTO,
    ApprovalStatus,
    GenericCapacitor,
    GenericPart,
    GenericPartHistoryDataDTO,
    GenericPartTypes,
    GenericResistor,
    HistoryOperation,
    PackageDTO,
} from '@luminovo/http-client';
import { useGenericPartPackage } from '../../../components/partSpecificationCards/GenericPart/useGenericPartPackage';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    ApprovalStatusIcon,
    AutomaticApprovalStatusPhrase,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineStatusChip,
    TimelineText,
} from './Utils';

export function GenericPartTimelineIcon({
    operation,
    approvalStatus,
}: {
    operation: HistoryOperation;
    approvalStatus: ApprovalStatus;
}) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;

    return <ApprovalStatusIcon approvalStatus={approvalStatus} />;
}

type Props = {
    data: GenericPartHistoryDataDTO;
    operation: HistoryOperation;
    actor: ActorResponseRuntypeDTO;
};

function useGenericPartEventAction(
    operation: HistoryOperation,
    actor: ActorResponseRuntypeDTO,
    approvalStatus: ApprovalStatus,
) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <>
                    <Trans>added</Trans> <ActionPerformedBy actor={actor} />
                </>
            );
        case HistoryOperation.ManualRemove:
            return (
                <>
                    <Trans>removed</Trans> <ActionPerformedBy actor={actor} />
                </>
            );
        case HistoryOperation.AutoAdd:
            return <AutomaticApprovalStatusPhrase approvalStatus={approvalStatus} />;
        case HistoryOperation.ManualUpdateApproval:
            return (
                <>
                    <TimelineStatusChip approvalStatus={approvalStatus} /> <ActionPerformedBy actor={actor} />
                </>
            );
        default:
            return null;
    }
}

export default function GenericPartTimelineItem({ operation, data, actor }: Props) {
    const action = useGenericPartEventAction(operation, actor, data.approval);
    if (!action) return null;
    return (
        <TimelineContentContainer>
            <TimelineText>
                <GenericPartWrapper part={data.part} /> {action}
            </TimelineText>
        </TimelineContentContainer>
    );
}

function GenericPartWrapper({ part }: { part: GenericPart }) {
    const packageId = part.technical_parameters.package_id;
    const packageData = useGenericPartPackage({ packageId });
    if (part.type === GenericPartTypes.Capacitor)
        return <CapacitorSpecification capacitor={part} packageData={packageData} />;
    return <ResistorSpecification resistor={part} packageData={packageData} />;
}

function CapacitorSpecification({
    capacitor,
    packageData,
}: {
    capacitor: GenericCapacitor;
    packageData: PackageDTO | null;
}) {
    const technicalSpecification = capacitor.technical_parameters;
    const tolerance = technicalSpecification?.tolerance;
    const voltageRating = technicalSpecification?.voltage_rating;
    const capacitance = technicalSpecification?.capacitance;
    const dielectric = technicalSpecification?.dielectric;

    return (
        <>
            <Trans>Generic capacitor</Trans>
            {capacitance && (
                <>
                    {' '}
                    <Chip label={formatCapacitance(capacitance)} color={'neutral'} />
                </>
            )}
            {tolerance && (
                <>
                    {' '}
                    <Chip label={formatTolerance(tolerance)} color={'neutral'} />
                </>
            )}
            {voltageRating && (
                <>
                    {' '}
                    <Chip label={formatVoltageRating(voltageRating)} color={'neutral'} />
                </>
            )}
            {dielectric && (
                <>
                    {' '}
                    <Chip label={formatDielectricMaterial(dielectric)} color={'neutral'} />
                </>
            )}
            <PackageChip packageData={packageData} />
        </>
    );
}

function ResistorSpecification({
    resistor,
    packageData,
}: {
    resistor: GenericResistor;
    packageData: PackageDTO | null;
}) {
    const technicalSpecification = resistor.technical_parameters;

    const resistance = technicalSpecification?.resistance;
    const tolerance = technicalSpecification?.tolerance;
    const powerRating = technicalSpecification?.power_rating;
    const voltageRating = technicalSpecification?.voltage_rating;
    const temperatureCoefficient = technicalSpecification?.temperature_coefficient;

    return (
        <>
            <Trans>Generic resistor</Trans>
            {resistance && (
                <>
                    {' '}
                    <Chip label={formatResistance(resistance)} color={'neutral'} />
                </>
            )}
            {tolerance && (
                <>
                    {' '}
                    <Chip label={formatTolerance(tolerance)} color={'neutral'} />
                </>
            )}
            {powerRating && (
                <>
                    {' '}
                    <Chip label={formatPowerRating(powerRating)} color={'neutral'} />
                </>
            )}
            {voltageRating && (
                <>
                    {' '}
                    <Chip label={formatVoltageRating(voltageRating)} color={'neutral'} />
                </>
            )}
            {temperatureCoefficient && (
                <>
                    {' '}
                    <Chip label={formatTemperatureCoefficient(temperatureCoefficient)} color={'neutral'} />
                </>
            )}
            <PackageChip packageData={packageData} />
        </>
    );
}

function PackageChip({ packageData }: { packageData: PackageDTO | null }) {
    const formattedPackageData = packageData ? `${packageData.name} ${packageData.mounting}` : null;
    if (!formattedPackageData) return null;
    return (
        <>
            {' '}
            <Chip label={formattedPackageData} color={'neutral'} />
        </>
    );
}
