import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { ButtonInviteUsers } from './ButtonInviteUsers';
import { CustomerUsersTable } from './UsersTable';

export { DialogInviteUsers } from './DialogInviteUsers';

function NoResultsComponent({ customer }: { customer: CustomerDTO }) {
    return (
        <Flexbox flexDirection={'column'} gap={8} padding={4} alignItems="center" justifyContent="center">
            <Text variant="body" color={colorSystem.neutral[7]}>
                <Trans>This customer has no user yet.</Trans>
            </Text>
            <ButtonInviteUsers customer={customer} overrides={{ Button: SecondaryButton }} />
        </Flexbox>
    );
}

export function CustomerUsersTableWrapper({
    customer,
    isAllowedToEdit,
}: {
    customer: CustomerDTO;
    isAllowedToEdit?: boolean;
}) {
    const { data: invites = [] } = useHttpQuery(
        'GET /users/customers/:customerId/invites',
        {
            pathParams: { customerId: customer.id },
        },
        { select: (res) => res.data },
    );
    const { data: users = [] } = useHttpQuery(
        'GET /users/customers/:customerId',
        {
            pathParams: { customerId: customer.id },
        },
        { select: (res) => res.data },
    );
    return (
        <CustomerUsersTable
            customerId={customer.id}
            invites={invites}
            users={users}
            NoResultsComponent={() => <NoResultsComponent customer={customer} />}
            isAllowedToEdit={isAllowedToEdit}
        />
    );
}
