import { colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';

export function MainContainer({ children }: { children: React.ReactNode }) {
    return (
        <Box
            style={{
                background: colorSystem.neutral[1],
            }}
            paddingBottom="12px"
            marginLeft="-20px"
            marginRight="-20px"
        >
            <Box
                display="grid"
                gridTemplateColumns="1fr"
                style={{
                    background: colorSystem.neutral.white,
                }}
                borderRadius="0px 0px 8px 8px "
                padding="0px 20px 20px 20px"
                position="relative"
            >
                {children}
            </Box>
        </Box>
    );
}
