import { formatDecimal } from '@luminovo/commons';

const MIN_DISPLAYABLE_VALUE = 0.00001;

export const EmissionsView = ({
    min,
    max,
    hasIncompleteData = false, // used for the assembly data
}: {
    min: number;
    max: number;
    hasIncompleteData?: boolean;
}) => {
    const formattedMin = formatEmission(min);
    const formattedMax = formatEmission(max);

    if (formattedMin === formattedMax) {
        return <>{`${hasIncompleteData ? '≥ ' : ''}${formattedMax}`}</>;
    }

    return <>{`${hasIncompleteData ? '≥ ' : ''}${formattedMin} - ${formattedMax}`}</>;
};

export function formatEmission(val: number | string) {
    const emissionsNumber = typeof val === 'string' ? Number(val) : val;
    return emissionsNumber < MIN_DISPLAYABLE_VALUE
        ? `<${MIN_DISPLAYABLE_VALUE}`
        : formatDecimal(emissionsNumber, { maximumFractionDigits: 5 });
}
