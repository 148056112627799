import { Radio as MuiRadio, RadioProps as MuiRadioProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { colorSystem } from '../../theme';
import { RadioCheckedIcon, RadioEmptyIcon } from '../icons';

export type RadioProps = Omit<MuiRadioProps, 'size'> & { size?: 'large' | 'small' };

const DefaultRadio = styled(MuiRadio)({
    padding: 0,
    color: colorSystem.neutral[3],
    backgroundColor: colorSystem.neutral.white,
    borderRadius: '12px',
    '&:hover': {
        color: colorSystem.primary[3],
        backgroundColor: colorSystem.primary[1],
    },

    '&.Mui-checked': {
        color: colorSystem.primary[5],
        '&:hover': {
            color: colorSystem.primary[7],
            backgroundColor: colorSystem.neutral.white,
        },
    },

    '&.Mui-disabled': {
        color: colorSystem.neutral[3],
        backgroundColor: colorSystem.neutral[1],
    },

    '&.MuiIconButton-colorSecondary:hover': {
        padding: 0,
    },
    '&.MuiIconButton-root:hover': {
        padding: 0,
    },
});

export const Radio = forwardRef(({ size = 'large', ...props }: RadioProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
        <DefaultRadio
            ref={ref}
            checkedIcon={<RadioCheckedIcon size={size} />}
            icon={<RadioEmptyIcon size={size} />}
            disableRipple
            {...props}
        />
    );
});
