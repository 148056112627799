import { t } from '@lingui/macro';
import { isEqual } from '@luminovo/commons';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { FilterList } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { ModuleTableData } from '../../Bom/components/ModuleTableData';
import { isEmptyBomItem } from '../isEmptyBomItem';
import { BomLinePreview } from './BomLinePreview';

/**
 * This is the manually measured height of the BomLinePreview component.
 */
const itemHeight = 60;

interface Props {
    filteredModules: ModuleTableData[];
    selectedModule?: ModuleTableData;
    onClick: (module: ModuleTableData) => void;
    bomItemsCount: number;
}

export const BomLinePreviews = React.memo(function BomLinePreviews(props: Props) {
    const { filteredModules, selectedModule, bomItemsCount } = props;

    return (
        <Flexbox
            boxSizing="border-box"
            flexDirection="column"
            padding="12px 12px 0px 12px"
            style={{
                borderRadius: 4,
                outline: 'none',
                flexGrow: 1,
                pointerEvents: 'auto',
                background: colorSystem.neutral.white,
            }}
        >
            {selectedModule && isEmptyBomItem(selectedModule) && (
                <BomLinePreview
                    onClick={() => {}}
                    style={{ height: itemHeight }}
                    key={selectedModule.id.toString()}
                    module={selectedModule}
                    selectedModuleId={selectedModule?.id}
                />
            )}

            <WindowedBomLinePreviewList {...props} />

            {filteredModules.length === 0 && (
                <Flexbox padding="24px" flexDirection="column" alignItems="center" gap={8}>
                    <FilterList />
                    <Typography style={{ color: colorSystem.neutral[5], maxWidth: 160, textAlign: 'center' }}>
                        {bomItemsCount === 0
                            ? t`There are no BOM items in this assembly`
                            : t`No BOM Items match the selected filter`}
                    </Typography>
                </Flexbox>
            )}
        </Flexbox>
    );
});

function WindowedBomLinePreviewList({ filteredModules, selectedModule, onClick }: Props) {
    const listRef = React.useRef<FixedSizeList<unknown>>(null);

    const selectedModuleId = selectedModule?.id;
    React.useEffect(() => {
        const index = filteredModules.findIndex((mod) => isEqual(mod.id, selectedModuleId));
        if (index >= 0) {
            listRef.current?.scrollToItem(index, 'smart');
        }
    }, [selectedModuleId, listRef, filteredModules]);

    return (
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <FixedSizeList
                        ref={listRef}
                        height={height}
                        width={width}
                        layout="vertical"
                        itemCount={filteredModules.length}
                        itemSize={itemHeight}
                    >
                        {({ index, style }) => {
                            const module = filteredModules[index];
                            return (
                                <Box
                                    style={{
                                        ...style,
                                    }}
                                    borderBottom={`1px solid ${colorSystem.neutral[1]}`}
                                    height={itemHeight}
                                >
                                    <BomLinePreview
                                        onClick={onClick}
                                        module={module}
                                        selectedModuleId={selectedModule?.id}
                                    />
                                </Box>
                            );
                        }}
                    </FixedSizeList>
                );
            }}
        </AutoSizer>
    );
}
