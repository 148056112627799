import { Trans } from '@lingui/macro';
import { Column, columnWidth, Filter, Row, Text } from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { ManufacturingLevelTag } from '../../shared/ManufacturingSharedComponents/ManufacturingLevelTag';
import { LevelCell } from './LevelCell';
import { TimeCalculationSummaryContext } from './utils';

function getLevelFilters<C extends TimeCalculationSummaryContext>(): Filter<ActivityDTO, C>[] {
    return [
        {
            id: 'level=unit',
            label: <Trans>Unit</Trans>,
            predicate: (activity, context): boolean =>
                context.timeCalculationSummaryMap[activity.id].Unit !== undefined,
        },
        {
            id: 'level=batch',
            label: <Trans>Batch</Trans>,
            predicate: (activity, context): boolean =>
                context.timeCalculationSummaryMap[activity.id].Batch !== undefined,
        },
        {
            id: 'level=project',
            label: <Trans>Project</Trans>,
            predicate: (activity, context): boolean =>
                context.timeCalculationSummaryMap[activity.id].Project !== undefined,
        },
    ];
}

export function getLevelColumn<C extends TimeCalculationSummaryContext>(): Column<ActivityDTO, C> {
    return {
        label: <Trans>Level</Trans>,
        id: `level`,
        render: ({ data: activity }: Row<ActivityDTO>, context) => {
            const summary = context.timeCalculationSummaryMap[activity.id];

            if (!summary) {
                return (
                    <TableCell>
                        <Text>-</Text>
                    </TableCell>
                );
            }

            const unitSummary = summary['Unit'];
            const batchSummary = summary['Batch'];
            const projectSummary = summary['Project'];

            const unitLevel = unitSummary ? <ManufacturingLevelTag level={unitSummary.level} /> : undefined;
            const batchLevel = batchSummary ? <ManufacturingLevelTag level={batchSummary.level} /> : undefined;
            const projectLevel = projectSummary ? <ManufacturingLevelTag level={projectSummary.level} /> : undefined;

            return <LevelCell unit={unitLevel} batch={batchLevel} project={projectLevel} />;
        },
        width: columnWidth.medium,
        filters: getLevelFilters(),
    };
}
