import * as r from 'runtypes';
import { AssemblyWideDriverCountPatchRuntype } from '../assemblyWideDriverCount/assemblyWideDriverCountBackendTypes';
import { DriverIdPathRuntype } from '../driver';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import { AssemblyWideManuallyOverwrittenDriverCountRuntype } from './assemblyWideManuallyOverwrittenDriverCountBackendTypes';
import {
    ManufacturingAssemblyDetailsNotesRuntype,
    ManufacturingAssemblyDetailsOverviewRuntype,
    ManufacturingAssemblyDetailsRuntype,
    ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype,
} from './manufacturingAssemblyDetailsBackendTypes';

const invalidates: HttpEndpoint[] = [
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId',
];

export const manufacturingAssemblyDetailsEndpoints = {
    'GET /manufacturing-assembly-details/:duplicateRouteHackAskChrisAssemblyDetailsId': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: r.Record({ duplicateRouteHackAskChrisAssemblyDetailsId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: r.Record({ manufacturingAssemblyDetailsId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: r.Record({ manufacturingAssemblyDetailsId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ManufacturingAssemblyDetailsOverviewRuntype,
    }),
    'DELETE /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Delete a manufacturing assembly details',
        pathParams: r.Record({ manufacturingAssemblyDetailsId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates,
    }),
    'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId': endpoint({
        description: 'Delete manufacturing assembly details count from a basic driver', //TODO: change reference of basic driver to single-count https://www.notion.so/luminovo/Rename-basic-driver-to-single-count-a4786e2993a64d48bbb139874736730b
        pathParams: r.Record({ assemblyDetailsId: r.String, driverId: DriverIdPathRuntype }),
        /* eslint-disable-next-line camelcase */
        queryParams: r.Record({ apply_to_all_activities_and_expenses: r.Boolean.optional() }),
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates,
    }),
    'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId':
        endpoint({
            description: 'Delete manufacturing assembly details count from a sourcing driver',
            pathParams: r.Record({
                assemblyDetailsId: r.String,
                driverId: DriverIdPathRuntype,
                sourcingScenarioId: r.String,
            }),
            /* eslint-disable-next-line camelcase */
            queryParams: r.Record({ apply_to_all_activities_and_expenses: r.Boolean.optional() }),
            requestBody: r.Undefined,
            responseBody: r.Record({ deleted: r.Number }),
            invalidates,
        }),
    'POST /manufacturing-assembly-details': endpoint({
        description: 'Create a manufacturing assembly details',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype,
        responseBody: r.Record({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details': endpoint({
        description: 'Get manufacturing assembly details of an rfq',
        pathParams: r.Undefined,
        queryParams: r.Union(r.Record({ rfq_id: r.String }), r.Record({ assembly_id: r.String })),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(ManufacturingAssemblyDetailsRuntype),
        }),
    }),
    'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId': endpoint({
        description: 'edits the driver count of a manufacturing assembly driver',
        pathParams: r.Record({ assemblyDetailsId: r.String, driverId: r.String }),
        queryParams: r.Record({ apply_to_all_activities_and_expenses: r.Boolean.optional() }),
        requestBody: AssemblyWideDriverCountPatchRuntype,
        responseBody: r.Record({ data: AssemblyWideManuallyOverwrittenDriverCountRuntype }),
        invalidates,
    }),

    'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId':
        endpoint({
            description: 'edits the driver count of a manufacturing assembly driver',
            pathParams: r.Record({ assemblyDetailsId: r.String, driverId: r.String, sourcingScenarioId: r.String }),
            queryParams: r.Record({ apply_to_all_activities_and_expenses: r.Boolean.optional() }),
            requestBody: AssemblyWideDriverCountPatchRuntype,
            responseBody: r.Record({ data: AssemblyWideManuallyOverwrittenDriverCountRuntype }),
            invalidates,
        }),

    'PATCH /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Updates manufacturing assembly details',
        pathParams: r.Record({ manufacturingAssemblyDetailsId: r.String }),
        queryParams: r.Undefined,
        requestBody: ManufacturingAssemblyDetailsNotesRuntype,
        responseBody: r.Record({ data: ManufacturingAssemblyDetailsRuntype }),
        invalidates,
    }),
};
