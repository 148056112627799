import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation, QuantityUnitDTO } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, TimelineAddIcon, TimelineContentContainer, TimelineEditIcon, TimelineText } from './Utils';

type Props = {
    data: QuantityUnitDTO;
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

const useQuantityUnitAction = (operation: HistoryOperation, actor: ActorResponseRuntypeDTO, data: QuantityUnitDTO) => {
    switch (operation) {
        case HistoryOperation.AutoAdd:
            return (
                <TimelineText>
                    <Trans>
                        Unit of measurement automatically set to <strong>{data.unit}</strong> & quantity to{' '}
                        <strong>{data.quantity}</strong>
                    </Trans>{' '}
                </TimelineText>
            );
        case HistoryOperation.ManualAdd:
            if (!isActorDefined(actor)) return null;
            return (
                <TimelineText>
                    <Trans>
                        Unit of measurement manually set to <strong>{data.unit}</strong> & quantity to{' '}
                        <strong>{data.quantity}</strong>
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualUpdate:
            if (!isActorDefined(actor)) return null;
            return (
                <TimelineText>
                    <Trans>
                        Unit of measurement changed to <strong>{data.unit}</strong> & quantity to{' '}
                        <strong>{data.quantity}</strong>
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
};
export default function QuantityUnit({ data, actor, operation }: Props) {
    const action = useQuantityUnitAction(operation, actor, data);
    if (!action) return null;

    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}

export function QuantityUnitTimelineIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualAdd || operation === HistoryOperation.AutoAdd) return <TimelineAddIcon />;
    return <TimelineEditIcon />;
}
