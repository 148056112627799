import { t } from '@lingui/macro';
import { assertUnreachable, compareByString, uniq, uniqBy } from '@luminovo/commons';
import { FieldSelect, FieldSelectCreatable, Row, Tag, Tooltip } from '@luminovo/design-system';
import { PackageDTO, PackageMountingEnum } from '@luminovo/http-client';
import { TableCell, createFilterOptions } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHasPermission } from '../../../../../permissions/useHasPermission';
import { FullPartWithApprovalStatus } from '../../../../../resources/part/partFrontendTypes';
import { ManufacturingDataContext } from '../ManufacturingDataTypes';
import { getManufacturingPartsFormValues } from '../getManufacturingPartsFormValues';

const packageNameFilterOptions = createFilterOptions<string | null>({
    trim: true,
    matchFrom: 'start',
});

const MIN_WIDTH = '180px';

export const RenderPackageName = (
    { data }: Row<FullPartWithApprovalStatus>,
    {
        isRfqEditable,
        packageData,
        editOtsPartTooltip,
        editComponentToolTip,
        canEditOtsComponents,
        canEditOtsParts,
        onChangeOtsPart,
        onChangeOtsComponent,
    }: ManufacturingDataContext,
): JSX.Element => {
    const value = getManufacturingPartsFormValues(data, packageData);
    const { mounting, package_name: packageName, number_of_pins: numberOfPins } = value;
    const isAdmin = useHasPermission(['edit:organization_settings']);
    const sortedPackageOptions = packageData.sort((a, b) => compareByString(a.name ?? '', b.name ?? ''));
    const [filteredPackageNameOptions, setFilteredPackageNameOptions] = useState<(string | null)[]>(
        uniq(sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name)),
    );

    useEffect(() => {
        setFilteredPackageNameOptions(
            uniq(sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name)),
        );
    }, [mounting, sortedPackageOptions]);

    if (!isRfqEditable) {
        return <TableCell>{packageName ?? t`Unknown`}</TableCell>;
    }

    const partType = data.type;

    if (partType === 'Ipn') {
        return (
            <TableCell style={{ minWidth: MIN_WIDTH }}>
                <Tooltip title={editComponentToolTip}>
                    <div>
                        {mounting !== PackageMountingEnum.Other && (
                            <FieldSelect
                                id={`component_package_name`}
                                value={packageName ?? null}
                                options={uniq(
                                    sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name),
                                )}
                                getOptionLabel={(option: string | null) => option ?? t`Unknown`}
                                disabled={!canEditOtsComponents}
                                placeholder={t`Unknown`}
                                filterOptions={packageNameFilterOptions}
                                renderOption={(packageName: string | null) =>
                                    packageName ? <span data-testid={'package-name'}>{packageName}</span> : null
                                }
                                onChange={(value) =>
                                    onChangeOtsComponent({
                                        partId: data.part.id,
                                        mounting,
                                        name: value,
                                        number_of_pins: numberOfPins,
                                    })
                                }
                                size="small"
                            />
                        )}
                        {mounting === PackageMountingEnum.Other && (
                            <RenderPackageForOtherMountingOpt
                                packageName={packageName}
                                isEditable={canEditOtsComponents}
                                sortedPackageOptions={sortedPackageOptions}
                                onChange={(packageName: string | null) =>
                                    onChangeOtsComponent({
                                        partId: data.part.id,
                                        mounting,
                                        name: packageName,
                                        number_of_pins: numberOfPins,
                                    })
                                }
                            />
                        )}
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'Generic') {
        return (
            <TableCell>
                <Tag color="neutral" label={packageName ?? '-'} />
            </TableCell>
        );
    }
    if (partType === 'OffTheShelf') {
        if (isAdmin) {
            return (
                <TableCell style={{ minWidth: MIN_WIDTH }}>
                    <Tooltip title={editOtsPartTooltip}>
                        <div>
                            <RenderOTSPackageNameSelect
                                packageName={packageName}
                                isEditable={canEditOtsParts}
                                filteredPackageNameOptions={filteredPackageNameOptions}
                                setFilteredPackageNameOptions={setFilteredPackageNameOptions}
                                onChange={(packageName: string | null) =>
                                    onChangeOtsPart({
                                        partId: data.part.id,
                                        mounting,
                                        name: packageName,
                                        number_of_pins: numberOfPins,
                                    })
                                }
                            />
                        </div>
                    </Tooltip>
                </TableCell>
            );
        }

        return (
            <TableCell style={{ minWidth: MIN_WIDTH }}>
                <Tooltip title={editOtsPartTooltip}>
                    <div>
                        {mounting !== PackageMountingEnum.Other && (
                            <FieldSelect
                                value={packageName}
                                options={uniq(
                                    sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name),
                                )}
                                getOptionLabel={(option) => option ?? t`Unknown`}
                                placeholder={t`Unknown`}
                                disabled={!canEditOtsParts}
                                filterOptions={packageNameFilterOptions}
                                renderOption={(packageName: string | null) =>
                                    packageName ? <span data-testid={'package-name'}>{packageName}</span> : null
                                }
                                onChange={(value) =>
                                    onChangeOtsPart({
                                        partId: data.part.id,
                                        mounting,
                                        name: value,
                                        number_of_pins: numberOfPins,
                                    })
                                }
                                size="small"
                            />
                        )}
                        {mounting === PackageMountingEnum.Other && (
                            <RenderPackageForOtherMountingOpt
                                packageName={packageName}
                                isEditable={canEditOtsParts}
                                sortedPackageOptions={sortedPackageOptions}
                                onChange={(packageName: string | null) =>
                                    onChangeOtsPart({
                                        partId: data.part.id,
                                        mounting,
                                        name: packageName,
                                        number_of_pins: numberOfPins,
                                    })
                                }
                            />
                        )}
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'CustomComponent') {
        return <TableCell />;
    }
    if (partType === 'Custom') {
        return <TableCell />;
    }
    assertUnreachable(partType);
};

const RenderPackageForOtherMountingOpt = ({
    packageName,
    isEditable,
    sortedPackageOptions,
    onChange,
}: {
    packageName: string | null;
    isEditable: boolean;
    sortedPackageOptions: PackageDTO[];
    onChange: (packageName: string | null) => void;
}) => {
    // Special handling for the "Other" mounting option, because it allows creating new packages.
    const [packageNameOptionsForOthers, setPackageNameOptionsForOthers] = useState(() =>
        uniqBy(
            sortedPackageOptions.filter((p) => p.mounting === PackageMountingEnum.Other),
            (p) => p.name,
        ).map((p) => p.name),
    );
    const [val, setVal] = useState(packageName);

    return (
        <FieldSelectCreatable
            id={`component_package_name`}
            value={val ?? null}
            options={packageNameOptionsForOthers}
            getOptionLabel={(option: string | null) => option ?? t`Unknown`}
            placeholder={t`Unknown`}
            disabled={!isEditable}
            action={{
                label: t`Create`,
                onClick: (value) => {
                    setPackageNameOptionsForOthers((prev) => [...prev, value]);
                    setVal(value);
                },
            }}
            onChange={(value) => setVal(value)}
            onBlur={() => onChange(val)}
            size="small"
            style={{ minWidth: '120px' }}
        />
    );
};

function RenderOTSPackageNameSelect({
    packageName,
    isEditable,
    filteredPackageNameOptions,
    setFilteredPackageNameOptions,
    onChange,
}: {
    packageName: string | null;
    isEditable: boolean;
    filteredPackageNameOptions: (string | null)[];
    setFilteredPackageNameOptions: React.Dispatch<React.SetStateAction<(string | null)[]>>;
    onChange: (packageName: string | null) => void;
}) {
    const [val, setVal] = useState(packageName);

    return (
        <FieldSelectCreatable
            id={'ots_package_name'}
            value={val ?? null}
            options={filteredPackageNameOptions}
            getOptionLabel={(option: string | null) => option ?? t`Unknown`}
            placeholder={t`Unknown`}
            disabled={!isEditable}
            action={{
                label: t`Create`,
                onClick: (value) => {
                    setFilteredPackageNameOptions((prev) => [...prev, value]);
                    setVal(value);
                },
            }}
            onChange={(value) => setVal(value)}
            onBlur={() => onChange(val)}
            size="small"
            style={{ minWidth: '120px' }}
        />
    );
}
