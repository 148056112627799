import {
    DriverStatusDetailsDTO,
    ExpenseConfigurationSummaryDTO,
    ManufacturingAssemblyDetailsDTO,
} from '@luminovo/http-client';
import { Skeleton } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { TextCell } from '../ActivitiesTable/activitiesTableComponents';
import { CalculationCellLayout } from '../shared/CalculationCellLayout';
import { getFirstErrorDriverStatusIfAny } from '../shared/driverStatusInformation';

export const CostCalculationCell = ({
    expenseConfigurationSummary,
    manufacturingAssemblyDetails,
}: {
    expenseConfigurationSummary: ExpenseConfigurationSummaryDTO;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
}): JSX.Element => {
    const { data, isLoading } = useHttpQuery('GET /expenses/:expenseId', {
        pathParams: { expenseId: expenseConfigurationSummary.expense_configuration_details.expense_id },
    });
    if (isLoading) {
        return <Skeleton />;
    }

    const driverStatuses: DriverStatusDetailsDTO[] =
        expenseConfigurationSummary.expense_configuration_details.cost_components.driver_statuses;
    return (
        <TextCell
            firstRow={
                <CalculationCellLayout
                    driverStatus={getFirstErrorDriverStatusIfAny({ driverStatuses })}
                    calculationType={data?.data.cost_components.type}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                />
            }
        />
    );
};
