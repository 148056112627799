import { t, Trans } from '@lingui/macro';
import { isValidUrl } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { useDialogContext } from '../contexts/ModalContext';

/**
 * This hook is used to open a dialog that asks the user if he wants to open an external link.
 */
export const useExternalLinkDialog = () => {
    const { setDialog, closeDialog } = useDialogContext();
    return {
        openDialog: ({ url }: { url: string }) => {
            const isValid = isValidUrl(url);
            return setDialog(
                <Dialog open={true} maxWidth={'sm'} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Open external link`} handleClose={() => closeDialog()} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <Text
                            style={{
                                overflow: 'visible',
                                wordWrap: 'break-word',
                            }}
                        >
                            <Trans>Do you want to open the external website: {url}?</Trans>
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton
                            onClick={closeDialog}
                            style={{
                                marginRight: '12px',
                            }}
                        >{t`Cancel`}</SecondaryButton>
                        {!isValid ? (
                            <Text
                                style={{
                                    color: colorSystem.red[7],
                                }}
                            >
                                <Trans>URL is not valid. You can try copying and pasting the link in a new tab.</Trans>
                            </Text>
                        ) : (
                            <PrimaryButton
                                onClick={() => {
                                    window.open(url, '_blank', 'noopener noreferrer');
                                }}
                            >
                                <Trans>Open link in new tab</Trans>
                            </PrimaryButton>
                        )}
                    </DialogActions>
                </Dialog>,
            );
        },
    };
};
