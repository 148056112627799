import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { RutronikCredentialsInputDTORuntype, RutronikResponseBodyDTORuntype } from './rutronikBackendTypes';

export const rutronikEndpoints = {
    'GET /3rdparty/rutronik/credentials': endpoint({
        description: 'Returns credentials for rutronik integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: RutronikResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rutronik/credentials': endpoint({
        description: 'Uploads credentials for rutronik API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RutronikCredentialsInputDTORuntype,
        responseBody: RutronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
    'PATCH /3rdparty/rutronik/credentials': endpoint({
        description: 'Updates credentials for rutronik API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RutronikCredentialsInputDTORuntype,
        responseBody: RutronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
    'DELETE /3rdparty/rutronik/credentials': endpoint({
        description: 'Deletes existing credentials for rutronik API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
};
