import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseMillimeter } from '../parsers/parseLength';
import { parseFinalThickness, parseFinalThicknessKeyword } from '../parsers/parseThickness';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractFinalThickness: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const attribute = parseFinalThickness(stringToParse);
    if (isPresent(attribute)) {
        return [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute))];
    }

    // no confidence penalty for keywords (they don't need to be "selected")
    const keyword = parseFinalThicknessKeyword(stringToParse);
    const keywords = isPresent(keyword) ? [keyword] : [];
    const value = parseMillimeter(stringToParse);
    return keywords.concat(
        isPresent(value) ? [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, value))] : [],
    );
};
