import {
    AddPartEvents,
    CustomPartSpecification,
    OtsPartSpecification,
    PartSpecification,
    QuantityUnitDTO,
} from '@luminovo/http-client';
import { PartSpecificationTypes } from '../../../resources/part/PartSpecificationTypes';
import { assertUnreachable } from '../../../utils/typingUtils';

/**
 * The state of the BomItemForm
 *
 * Note: to facilitate implementation, the BomItemFormState stores information
 * about both the custom part AND OTS part, even though a design item can actually
 * only have one or the other.
 *
 * The reason it does this is because react-hook-form doesn't work nicely with
 * type unions (e.g. specification: OtsPart | CustomPart).
 *
 * The `specificationType` field indicates which of the two types of part specifications is actually selected.
 */
export interface BomItemFormState {
    id: string[];
    designator: string[];
    doNotPlace: boolean;
    notes: string;
    quantity: QuantityUnitDTO | null;
    parentId: string;
    specificationType: PartSpecificationTypes;
    customPart: CustomPartSpecification;
    otsPart: OtsPartSpecification;
    isConsigned: boolean;
    addPartEvents: AddPartEvents[];
    ignorePackageNameMismatch: boolean;
}

export function getSpecification(formState: BomItemFormState): PartSpecification | null {
    if (formState.specificationType === PartSpecificationTypes.Custom) {
        return formState.customPart;
    }
    if (formState.specificationType === PartSpecificationTypes.OffTheShelf) {
        return formState.otsPart;
    }
    assertUnreachable(formState.specificationType);
}
