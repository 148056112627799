import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { ExpenseDTO } from '@luminovo/http-client';
import { PageLayout } from '../../../components/PageLayout';
import { ErrorText, SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { UrlParams } from '../../../utils/routes';
import { RecursivePartial } from '../../../utils/typingUtils';
import { makeDuplicatedDefaultName } from '../utils/makeDuplicatedDefaultName';
import { ExpenseForm, getExistingExpenseCurrency } from './ExpenseForm';
import { AddExpenseFormInputs } from './utils/AddExpenseFormTypes';
import { convertExpenseDTOToFormValues } from './utils/convertExpenseFormValues';
import { createExpenseSchema } from './utils/expenseFormValidationSchema';

const createExpenseDefaultValue = (
    existingValues: RecursivePartial<AddExpenseFormInputs>,
    existingExpenseName: string[],
) => {
    return {
        ...existingValues,
        expense: {
            ...existingValues.expense,
            name: existingValues.expense?.name
                ? makeDuplicatedDefaultName(existingValues.expense.name, existingExpenseName)
                : '',
        },
    };
};

const DuplicateExpenseForm = ({ expenseId }: { expenseId: string }): JSX.Element => {
    const { data: expenses } = useHttpQuery('GET /expenses', { queryParams: {} });
    const { data: drivers } = useHttpQuery('GET /user-drivers', {});

    if (drivers && expenses) {
        const expense: ExpenseDTO | undefined = expenses.data.find((expense) => expense.id === expenseId);
        if (!expense) {
            return <ErrorText text={t`Expense not found`} />;
        }
        const existingExpensesCurrency: Currency = getExistingExpenseCurrency(expense.cost_components);
        const expenseFormValues = convertExpenseDTOToFormValues({
            expense,
            preferredCurrency: existingExpensesCurrency,
        });
        return (
            <ExpenseForm
                schema={createExpenseSchema(expenses.data, drivers.data)}
                defaultValues={createExpenseDefaultValue(
                    expenseFormValues,
                    expenses.data.map((e) => e.name),
                )}
                onSubmitType={'POST'}
                formTitle={t`Duplicate expense`}
            />
        );
    }
    return <SpinnerWithBackdrop />;
};

export const DuplicateExpensePage = ({
    pathParams,
}: UrlParams<'/manufacturing/expense/:expenseId/duplicate'>): JSX.Element => {
    return (
        <PageLayout layout="full-width">
            <DuplicateExpenseForm expenseId={pathParams.expenseId} />
        </PageLayout>
    );
};
