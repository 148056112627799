import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ContributorsRuntype, InviteExternalUserRuntype } from './contributorsBackendTypes';

export const contributorEndpoints = {
    'PATCH /contributors/rfqs/:rfqId': endpoint({
        description: 'Updates all the contributors that are part of a given RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Array(r.String),
        responseBody: r.Unknown,
    }),

    'GET /contributors/rfqs/:rfqId': endpoint({
        description: 'Returns all contributors associated to RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ContributorsRuntype,
    }),

    'POST /contributors/rfqs/bulk': endpoint({
        description:
            ' Returns all contributors with the given IDs. The request body is a list of RfQ IDs. The response body is a map from RfQ ID to a list of contributors in the given RfQ',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Array(r.String),
        responseBody: r.Dictionary(r.Array(r.String), r.String),
    }),

    'POST /contributors/rfqs/:rfqId/invite': endpoint({
        description: 'Sends an invite to an external user',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: InviteExternalUserRuntype,
        responseBody: r.Unknown,
    }),
};
