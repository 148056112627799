import { Trans } from '@lingui/macro';
import { colorSystem, Column, DataTable, Flexbox, Row, Tag, useDataTableState } from '@luminovo/design-system';
import { AssemblyOriginDTO, BomItemApprovalStatus } from '@luminovo/http-client';
import { Error as ErrorIcon } from '@mui/icons-material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { TableCell } from '../../../../components/partColumns/TableCell';
import { route } from '../../../../utils/routes';
import { AssemblyTableData, ViewContext } from '../../../Bom/components/ModuleTableData';
enum subassembliesColumnIds {
    columnDepth = 'depth',
    columnSubassembly = 'subassembly',
    columnCpnRevision = 'cpn-revision',
    columnQuantity = 'quantity',
    columnStatus = 'status',
}

export const SubassembliesTable = ({
    subassemblies,
    viewContext,
}: {
    subassemblies: AssemblyTableData[];
    viewContext: ViewContext;
}): JSX.Element => {
    const { push } = useHistory();
    const tableState = useDataTableState({
        items: subassemblies,
        columns: [columnDepth, columnSubassembly, columnCpnRevision, columnQuantity, columnStatus],
        persistenceId: `SubassembliesSummaryTable`,
        paginationOptions: { showPagination: false, defaultRowsPerPage: 5000 },
    });

    const navigateToSubAssembly = useCallback(
        (data: AssemblyTableData) => {
            const subAssemblyRoute =
                viewContext.type === 'WithinRfQ'
                    ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/details', {
                          rfqId: viewContext.rfqId,
                          assemblyId: data.id,
                      })
                    : route('/assemblies/:assemblyId/details', { assemblyId: data.id });
            push(subAssemblyRoute);
        },
        [push, viewContext],
    );
    return <DataTable size="small" tableState={tableState} onItemClick={navigateToSubAssembly} />;
};

const EmptyCell = () => {
    return <TableCell>-</TableCell>;
};

const columnDepth: Column<AssemblyTableData> = {
    id: subassembliesColumnIds.columnDepth,
    label: <Trans>Depth</Trans>,
    render: ({ data: assembly }: Row<AssemblyTableData>) => {
        if (assembly.depths.length === 0) {
            return <EmptyCell />;
        }
        return (
            <TableCell>
                <Flexbox alignItems="center" gap="4px">
                    {assembly.depths.map((depth, i) => (
                        <Tag key={i} color="neutral" attention="low" label={depth.toString()} />
                    ))}
                </Flexbox>
            </TableCell>
        );
    },
};

const columnSubassembly: Column<AssemblyTableData> = {
    id: subassembliesColumnIds.columnSubassembly,
    label: <Trans>Subassembly</Trans>,
    render: ({ data: assembly }: Row<AssemblyTableData>) => {
        const origin = assembly.origin;
        const needsAttention = origin.type === 'BomImport' && origin.value.issues.length > 0;
        return (
            <TableCell>
                <Flexbox alignItems="center" gap="8px">
                    {assembly.designator}
                    {needsAttention && <ErrorOutlineRoundedIcon fontSize={'small'} color={'warning'} />}
                </Flexbox>
            </TableCell>
        );
    },
};

const columnCpnRevision: Column<AssemblyTableData> = {
    id: subassembliesColumnIds.columnCpnRevision,
    label: (
        <Flexbox gap={4}>
            <Trans>CPN • Rev</Trans>
            <ErrorIcon style={{ width: '16px', height: '16px', color: colorSystem.neutral[5] }} />
        </Flexbox>
    ),
    render: ({ data: assembly }: Row<AssemblyTableData>) => {
        if (assembly.origin.type !== 'BomImport') {
            return <TableCell>-</TableCell>;
        }
        const excelOrigin = assembly.origin.value.excel_origin;
        if (!excelOrigin) return <TableCell>-</TableCell>;
        const identifier = excelOrigin.identifier;
        let formattedResult = identifier.cpns[0] ?? '-';
        const firstCpnRevisionIdentifier = identifier.cpn_revisions[0];
        if (firstCpnRevisionIdentifier) {
            formattedResult += ` • ${firstCpnRevisionIdentifier}`;
        }
        return <TableCell>{formattedResult}</TableCell>;
    },
};

const columnQuantity: Column<AssemblyTableData> = {
    id: subassembliesColumnIds.columnQuantity,
    label: <Trans>Qty</Trans>,
    render: ({ data: assembly }: Row<AssemblyTableData>) => {
        const allAssemblyQuantities = Object.values(assembly.parents).join(', ');
        return <TableCell>{allAssemblyQuantities}</TableCell>;
    },
};

const columnStatus: Column<AssemblyTableData> = {
    id: subassembliesColumnIds.columnStatus,
    label: <Trans>Status</Trans>,
    render: ({ data: assembly }: Row<AssemblyTableData>) => {
        const approvalStatus = assembly.approvalStatus;
        return (
            <TableCell>
                <ApprovalStatusOrNeedsAttentionTag origin={assembly.origin} approvalStatus={approvalStatus} />
            </TableCell>
        );
    },
};

const ApprovalStatusOrNeedsAttentionTag = ({
    approvalStatus,
    origin,
}: {
    approvalStatus: BomItemApprovalStatus;
    origin: AssemblyOriginDTO;
}) => {
    if (origin.type === 'BomImport' && origin.value.issues.length > 0) {
        return <Tag color={'yellow'} label={<Trans>Needs attention</Trans>} />;
    }
    if (approvalStatus === BomItemApprovalStatus.Approved) {
        return <Tag attention={'low'} color={'green'} label={<Trans>Approved</Trans>} />;
    }
    if (approvalStatus === BomItemApprovalStatus.DNP) {
        return <Tag attention={'low'} color={'neutral'} label={<Trans>Do not place</Trans>} />;
    }
    if (approvalStatus === BomItemApprovalStatus.Rejected) {
        return <Tag attention={'low'} color={'red'} label={<Trans>Rejected</Trans>} />;
    }
    if (approvalStatus === BomItemApprovalStatus.Pending) {
        return <Tag attention={'low'} color={'neutral'} label={<Trans>Pending</Trans>} />;
    }
    return <>-</>;
};
