import { t } from '@lingui/macro';
import { formatRelativeTime, formatToLongDate, isPresent } from '@luminovo/commons';
import { FieldDateControlled, FormItem, Text, Tooltip } from '@luminovo/design-system';
import { useFormContext, useWatch } from 'react-hook-form';
import { CustomPartQuoteFormValues } from '../types';

export function FormItemValidUntil() {
    const { control } = useFormContext<CustomPartQuoteFormValues>();
    const validUntil = useWatch({ control, name: 'validUntil' });

    return (
        <FormItem label={t`Valid until`} description={t`Determines until when the quote is valid.`}>
            <FieldDateControlled inFuture control={control} name="validUntil" />
            {isPresent(validUntil) && (
                <Tooltip title={formatToLongDate(validUntil)}>
                    <Text>{formatRelativeTime(validUntil)}</Text>
                </Tooltip>
            )}
        </FormItem>
    );
}
