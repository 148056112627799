import { t } from '@lingui/macro';
import { FieldController, FormItem } from '@luminovo/design-system';
import { FieldSelectCurrency } from '@luminovo/sourcing-core';
import { useFormContext } from 'react-hook-form';
import { CustomPartQuoteFormValues } from '../types';

export function FormItemCurrency() {
    const { control } = useFormContext<CustomPartQuoteFormValues>();
    return (
        <FormItem label={t`Currency`}>
            <FieldController
                control={control}
                Field={FieldSelectCurrency}
                validate={(value) => {
                    if (!value) {
                        return t`Required`;
                    }
                    return undefined;
                }}
                name="currency"
            />
        </FormItem>
    );
}
