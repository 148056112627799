import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    SupplierContactCreationDTORuntype,
    SupplierContactDTORuntype,
    SupplierContactUpdateDTORuntype,
} from './supplierContactBackendTypes';

export const supplierContactEndpoints = {
    'GET /supplier-contacts': endpoint({
        description: 'Get all supplier contacts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(SupplierContactDTORuntype) }),
    }),

    'GET /supplier-contacts/:supplierContactId': endpoint({
        description: 'Get supplier contact by id',
        pathParams: r.Record({
            supplierContactId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: SupplierContactDTORuntype }),
    }),

    'POST /supplier-contacts/bulk': endpoint({
        description: 'Get bulk of supplier contacts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.String),
        }),
        responseBody: r.Record({ items: r.Array(SupplierContactDTORuntype) }),
    }),

    'POST /supplier-contacts/:id/invite': endpoint({
        description: 'Invite a supplier contact by id',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['GET /supplier-contacts', 'GET /supplier-contacts/:supplierContactId'],
    }),

    'POST /supplier-contacts': endpoint({
        description: 'Insert a new contact for a supplier',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SupplierContactCreationDTORuntype,
        responseBody: r.Record({ data: SupplierContactDTORuntype }),
        invalidates: [
            'POST /supplier-contacts/bulk',
            'GET /supplier-contacts/:supplierContactId',
            'GET /supplier-contacts',
            'GET /suppliers/:supplierId/supplier-contacts',
            'POST /suppliers/supplier-contacts',
        ],
    }),

    'PATCH /supplier-contacts/:supplierContactId': endpoint({
        description: 'Patch a supplier contact with an id',
        pathParams: r.Record({
            supplierContactId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: SupplierContactUpdateDTORuntype,
        responseBody: r.Record({ data: SupplierContactDTORuntype }),
        invalidates: [
            'POST /supplier-contacts/bulk',
            'GET /supplier-contacts/:supplierContactId',
            'GET /supplier-contacts',
            'GET /suppliers/:supplierId/supplier-contacts',
            'POST /suppliers/supplier-contacts',
        ],
    }),

    'DELETE /supplier-contacts/:supplierContactId': endpoint({
        description: 'Delete supplier contact with an id',
        pathParams: r.Record({
            supplierContactId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: [
            'POST /supplier-contacts/bulk',
            'GET /supplier-contacts/:supplierContactId',
            'GET /supplier-contacts',
            'GET /suppliers/:supplierId/supplier-contacts',
            'POST /suppliers/supplier-contacts',
        ],
    }),

    'GET /suppliers/:supplierId/supplier-contacts': endpoint({
        description: 'Get all contacts for a given supplier',
        pathParams: r.Record({
            supplierId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(SupplierContactDTORuntype) }),
    }),

    'POST /suppliers/supplier-contacts': endpoint({
        description: 'Get all contacts for the given suppliers by id',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.String),
        }),
        responseBody: r.Record({ items: r.Array(SupplierContactDTORuntype) }),
    }),
};
