import { Trans } from '@lingui/macro';
import { Card, CenteredLayout, colorSystem, Flexbox, PrimaryButton } from '@luminovo/design-system';
import { Error as ErrorIcon } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';

export function PcbLoadingError() {
    return (
        <CenteredLayout>
            <Card maxWidth="300px">
                <Flexbox width="100%" flexDirection="column" alignItems="center" gap={8}>
                    <ErrorIcon fontSize="large" style={{ color: colorSystem.neutral[7] }} />
                    <Typography variant="h1">
                        <Trans>PCB failed to load</Trans>
                    </Typography>
                </Flexbox>

                <Typography>
                    <Trans>Try refreshing the page. If this problem persists, please contact our support team.</Trans>
                </Typography>

                <PrimaryButton onClick={() => window.location.reload()}>
                    <Trans>Refresh page</Trans>
                </PrimaryButton>
            </Card>
        </CenteredLayout>
    );
}

export function PcbLoadingProgress() {
    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    );
}
