import { t } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import { CurrentUserDetailsDTO, CustomerDTO, RfqDTO, SupplierDTO } from '@luminovo/http-client';
import { renderTemplate } from '@luminovo/rich-text-editor';
import { route } from '../../../../utils/routes';
import { VarId } from './createVars';

export interface RenderQuoteRequestTemplateProps {
    sender: CurrentUserDetailsDTO;
    dueDate: string;
    recipient?: { first_name: string; last_name: string };
    rfq: RfqDTO;
    customer: CustomerDTO;
    supplier: SupplierDTO;
    notes: string;
}

export function renderQuoteRequestTemplate(
    template: { body: string; subject: string } | undefined,
    renderContext: RenderQuoteRequestTemplateProps,
): { body: string; subject: string } {
    if (!template) {
        return {
            body: '',
            subject: '',
        };
    }
    const body = renderQuoteRequestTemplateHtml({ html: template.body, asText: false }, renderContext);
    const subject = renderQuoteRequestTemplateHtml({ html: template.subject, asText: true }, renderContext);

    return {
        subject,
        body,
    };
}

function renderQuoteRequestTemplateHtml(
    { html, asText }: { html: string; asText: boolean },
    { dueDate, sender, recipient, rfq, customer, supplier, notes }: RenderQuoteRequestTemplateProps,
): string {
    const supplierPortalLink = window.location.origin + route('/quote-portal/rfq/:rfqId', { rfqId: rfq.id });
    return renderTemplate(
        html,
        {
            [VarId.customerName]: customer.name,
            [VarId.dueDate]: dueDate,
            [VarId.quoteSendDate]: formatToLongDate(new Date()),
            [VarId.quoteNumber]: '{quote_number}', // this is resolved by the backend
            [VarId.recipientFirstName]: recipient ? recipient.first_name : '',
            [VarId.recipientLastName]: recipient ? recipient.last_name : '',
            [VarId.rfqName]: rfq.name,
            [VarId.rfqNumber]: rfq.ems_internal_number ?? `(${t`Not set`})`,
            [VarId.senderFirstName]: sender.user.first_name,
            [VarId.senderLastName]: sender.user.last_name,
            [VarId.senderOrgName]: sender.organization.name,
            [VarId.supplierName]: supplier.name,
            [VarId.supplierPortalLink]: supplierPortalLink,
            [VarId.notes]: notes ?? '',
        },
        {
            asText,
        },
    );
}
