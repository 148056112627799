import { DndContext } from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trans, t } from '@lingui/macro';
import { assertPresent, compareByString, isPresent } from '@luminovo/commons';
import { Add, Close, DragIndicatorRounded } from '@mui/icons-material';
import { Badge, Divider, ListItem, Menu } from '@mui/material';
import { ColumnSort, Table } from '@tanstack/react-table';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { MenuItem } from '../../MenuItem';
import { SearchInput } from '../../SearchField';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { DestructiveTertiaryIconButton, SecondaryButton, TertiaryButton } from '../../buttons';
import { SortIcon } from '../../icons';

type SortMenuState =
    | {
          type: 'None';
          anchorEl: undefined;
      }
    | {
          type: 'AddSort';
          anchorEl: Element;
      }
    | {
          type: 'SortManager';
          anchorEl: Element;
      };

export function SortMenu<TData>({ table }: { table: Table<TData> }): JSX.Element {
    const [menuState, setMenuState] = React.useState<SortMenuState>({ type: 'None', anchorEl: undefined });

    const sortCount = table.getAllLeafColumns().filter((col) => col.getIsSorted()).length;
    const hasSortingEnabled = table.getAllLeafColumns().some((col) => col.getCanSort());

    const handleOnClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (menuState.type !== 'None') {
                return;
            }
            if (sortCount === 0) {
                setMenuState({ type: 'AddSort', anchorEl: e.currentTarget });
            } else {
                setMenuState({ type: 'SortManager', anchorEl: e.currentTarget });
            }
        },
        [menuState, sortCount, setMenuState],
    );

    const handleOpenAddSortMenu = () => {
        if (menuState.type === 'None') {
            return;
        }
        setMenuState({ type: 'AddSort', anchorEl: menuState.anchorEl });
    };

    const handleClose = () => {
        setMenuState({ type: 'None', anchorEl: undefined });
    };

    if (!hasSortingEnabled) {
        return <></>;
    }

    return (
        <>
            <Badge badgeContent={sortCount} color={'primary'}>
                <SecondaryButton size={'medium'} onClick={handleOnClick} startIcon={<SortIcon />}>
                    <Trans>Sort</Trans>
                </SecondaryButton>
            </Badge>

            <Menu
                anchorEl={menuState.anchorEl}
                open={menuState.type === 'SortManager'}
                onClose={handleClose}
                variant="menu"
                keepMounted
            >
                <SortManager table={table} onClose={handleClose} />
                <Divider />
                <AddSortMenuItem
                    table={table}
                    menuState={menuState}
                    onClick={handleOpenAddSortMenu}
                    onClose={handleClose}
                />
                <DeleteSortMenuItem table={table} onClose={handleClose} />
            </Menu>
        </>
    );
}

function SortManager<TData>({ table, onClose }: { table: Table<TData>; onClose: () => void }): JSX.Element {
    const sorting = table.getState().sorting;

    const onDragEnd = ({ over, active }: DragEndEvent) => {
        if (!isPresent(over)) {
            return;
        }

        const overIndex = sorting.findIndex((sort) => sort.id === over.id);
        const activeIndex = sorting.findIndex((sort) => sort.id === active.id);

        table.setSorting(arrayMove(sorting, activeIndex, overIndex));
    };

    return (
        <ListItem style={{ backgroundColor: colorSystem.neutral.white, padding: '8px' }}>
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext items={sorting}>
                    <Flexbox flexDirection={'column'} gap={8}>
                        {sorting.map((columnSort) => (
                            <SortManagerItem
                                key={columnSort.id}
                                columnSort={columnSort}
                                table={table}
                                handleClose={onClose}
                            />
                        ))}
                    </Flexbox>
                </SortableContext>
            </DndContext>
        </ListItem>
    );
}

function SortManagerItem({
    columnSort,
    table,
    handleClose,
}: {
    columnSort: ColumnSort;
    table: Table<any>;
    handleClose: () => void;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: columnSort.id });
    const column = assertPresent(table.getColumn(columnSort.id));
    const onlyHasOneSort = table.getAllLeafColumns().filter((col) => col.getIsSorted()).length === 1;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleSwitchSort = () => {
        if (column.getIsSorted() === 'asc') {
            column.toggleSorting(true, true);
        }
        if (column.getIsSorted() === 'desc') {
            column.toggleSorting(false, true);
        }
    };

    const handleRemoveSort = () => {
        column.clearSorting();
        if (onlyHasOneSort) {
            handleClose();
        }
    };

    return (
        <div ref={setNodeRef} style={style}>
            <Flexbox gap={12} justifyContent={'space-between'} alignItems={'center'}>
                <Flexbox alignItems={'center'}>
                    <TertiaryButton
                        size="small"
                        ref={setNodeRef}
                        style={{ cursor: 'grab', color: colorSystem.neutral[5], width: '20px' }}
                        {...listeners}
                        {...attributes}
                        disabled={onlyHasOneSort}
                    >
                        <DragIndicatorRounded color={'inherit'} fontSize="inherit" />
                    </TertiaryButton>

                    <Tooltip
                        title={
                            column.getIsSorted() === 'asc'
                                ? t`Switch sorting to descending`
                                : t`Switch sorting to ascending`
                        }
                        placement="top"
                    >
                        <TertiaryButton
                            size={'small'}
                            onClick={handleSwitchSort}
                            endIcon={
                                column.getIsSorted() === 'asc' ? (
                                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                                        <Trans>(ascending)</Trans>
                                    </Text>
                                ) : (
                                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                                        <Trans>(descending)</Trans>
                                    </Text>
                                )
                            }
                        >
                            <Flexbox justifyContent={'space-between'} gap={24}>
                                <Text
                                    variant={'body-small'}
                                    showEllipsis={true}
                                    style={{ width: '200px', textAlign: 'left' }}
                                >
                                    {column.columnDef.meta?.label()}
                                </Text>
                            </Flexbox>
                        </TertiaryButton>
                    </Tooltip>
                </Flexbox>
                <Tooltip title={t`Remove sorting rule`} placement="top">
                    <DestructiveTertiaryIconButton size={'small'} onClick={handleRemoveSort}>
                        <Close fontSize="inherit" />
                    </DestructiveTertiaryIconButton>
                </Tooltip>
            </Flexbox>
        </div>
    );
}

function AddSortMenuItem<TData>({
    table,
    menuState,
    onClick,
    onClose,
}: {
    table: Table<TData>;
    menuState: SortMenuState;
    onClick: () => void;
    onClose: () => void;
}): JSX.Element {
    const [query, setQuery] = React.useState<string>('');

    const columns = table
        .getAllLeafColumns()
        .filter((col) => col.getCanSort())
        .filter((col) => !col.getIsSorted())
        .filter((col) => col.columnDef.meta?.label().toLocaleLowerCase().includes(query))
        .sort((a, b) => compareByString(a.columnDef.meta?.label() ?? '', b.columnDef.meta?.label() ?? ''));

    return (
        <>
            <MenuItem label={t`Add sort`} onClick={onClick} startIcon={<Add />} />

            <Menu
                anchorEl={menuState.anchorEl}
                open={menuState.type === 'AddSort'}
                onClose={onClose}
                variant="menu"
                keepMounted
            >
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }} onKeyDown={(e) => e.stopPropagation()}>
                    <SearchInput
                        placeholder={t`Sort by...`}
                        value={query}
                        onChange={(value) => setQuery(value)}
                        onClear={() => setQuery('')}
                        style={{ backgroundColor: colorSystem.neutral.white }}
                    />
                </ListItem>

                {columns.map((column) => (
                    <MenuItem
                        key={column.id}
                        onClick={() => {
                            column.toggleSorting(undefined, true);
                            onClose();
                        }}
                        label={column.columnDef.meta?.label() ?? t`Unknown`}
                    />
                ))}

                {columns.length === 0 && (
                    <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                            <Trans>No results</Trans>
                        </Text>
                    </ListItem>
                )}
            </Menu>
        </>
    );
}

function DeleteSortMenuItem<TData>({ table, onClose }: { table: Table<TData>; onClose: () => void }): JSX.Element {
    const hasSorted = table.getAllLeafColumns().some((col) => col.getIsSorted());

    const handleDeleteSorting = () => {
        table
            .getAllLeafColumns()
            .filter((col) => col.getCanSort())
            .forEach((col) => col.clearSorting());
        onClose();
    };

    return (
        <MenuItem
            label={t`Clear sort`}
            variant={'destructive'}
            startIcon={<Close />}
            onClick={handleDeleteSorting}
            disabled={!hasSorted}
        />
    );
}
