import { t } from '@lingui/macro';
import { isPresent, uniq } from '@luminovo/commons';
import { Flexbox, Tag, Text, Tooltip } from '@luminovo/design-system';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';

const MAX_WIDTH_PART_CATEGORY = '128px';

export const RenderPartCategory = ({ parts }: { parts: FullPart[] }) => {
    const uniqPartCategories: string[] = findUniquePartCategories({ parts });

    if (uniqPartCategories.length === 1) {
        return (
            <Flexbox maxWidth={MAX_WIDTH_PART_CATEGORY}>
                <Text showEllipsis variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                    {uniqPartCategories[0]}
                </Text>
            </Flexbox>
        );
    }
    if (uniqPartCategories.length > 1) {
        return (
            <Tooltip
                title={
                    <Flexbox flexDirection="column">
                        {uniqPartCategories.map((partCategory, i) => {
                            return <span key={i}>{partCategory}</span>;
                        })}
                    </Flexbox>
                }
            >
                <span>
                    <Tag color="neutral" label={t`Diverse`} attention="low" />
                </span>
            </Tooltip>
        );
    }
    return <>-</>;
};

export const findUniquePartCategories = ({ parts }: { parts: FullPart[] }) => {
    const partCategories = parts
        .flatMap((part): (string | undefined)[] => {
            if (isOtsFullPart(part)) {
                return [part.part_category?.part_category.name];
            }
            if (isGenericFullPart(part)) {
                return part.matches.map((match) => match.part_category?.part_category.name);
            }
            if (isOtsComponentFull(part)) {
                return part.matches.flatMap((match) => {
                    if (match.part.type === 'OffTheShelf') {
                        return match.part.data.part_category?.part_category.name;
                    }
                    if (match.part.type === 'Generic') {
                        return match.part.data.matches.map((match) => match.part_category?.part_category.name);
                    }
                    return undefined;
                });
            }
            if (isCustomFullPart(part)) {
                return [part.type.name];
            }
            if (isCustomComponentFull(part)) {
                return part.matches.map((match) => match.type.name);
            }
            return [];
        })
        .filter(isPresent);
    return uniq(partCategories);
};
