import React from 'react';
import { ViewContext } from '../../../modules/Bom/components/ModuleTableData';
import { UrlParams } from '../../../utils/routes';
import { AddAssembly } from '../../rfqs/[rfqId]/bom/assembly/add-assembly';

export default function AddAssemblyPage({ queryParams }: UrlParams<'/assemblies/add-assembly'>) {
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: queryParams.rfqId,
        };
    }, [queryParams.rfqId]);
    return <AddAssembly viewContext={viewContext} assemblyId={queryParams.assemblyId} rfqId={queryParams.rfqId} />;
}
