import { Trans } from '@lingui/macro';
import { colorSystem, DataTable, DefaultStyledTable, TableState, Text } from '@luminovo/design-system';
import { PartAlternative } from '@luminovo/http-client';
import { styled } from '@mui/material';
import { useCallback } from 'react';
import { useOtsPartDetailsDrawer } from '../../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { TableContainer } from '../TableContainer';
import { PartAlternativeContext } from './partAlternativesTypes';

export const PartAlternativesTable = ({
    tableState,
    partAlternatives,
}: {
    tableState: TableState<PartAlternative, PartAlternativeContext>;
    partAlternatives: PartAlternative[];
}) => {
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const rfqContext = tableState.sharedContext.rfqContext;
    const handleRowClick = useCallback(
        (partAlternative: PartAlternative) => {
            return openOtsDrawer({ part: partAlternative.off_the_shelf_part, rfqContext });
        },
        [openOtsDrawer, rfqContext],
    );

    if (partAlternatives.length === 0) {
        return (
            <Text variant="h5" color={colorSystem.neutral[6]}>
                <Trans>No alternative part matches found</Trans>
            </Text>
        );
    }
    return (
        <DataTable
            tableState={tableState}
            overrides={{ Container: StyledTableContainer, Table: StyledTable }}
            stickyHeader={false}
            onItemClick={handleRowClick}
            size="large"
        />
    );
};

// The table & its container are rotated by 180 degrees so that the scrollbar is moved to the top of the table.
const rotate180degrees = 'rotateX(180deg)';

const StyledTableContainer = styled(TableContainer)({
    overflow: 'invisible',
    transform: rotate180degrees,
});

const StyledTable = styled(DefaultStyledTable)({
    transform: rotate180degrees,
});
