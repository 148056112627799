import { Dialog, DialogContent, NOTO_SANS, colorSystem } from '@luminovo/design-system';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { ErrorFallback } from '../../../../components/errorHandlers/ErrorBoundary';

type Field<T> = (props: {
    autoFocus?: boolean;
    value: T | undefined;
    onChange: (value: T | undefined) => void;
}) => JSX.Element | null;

type EditableCellProps<TValue> = React.PropsWithChildren<{
    align?: 'left' | 'right';
    Field: Field<TValue>;
    value: TValue | undefined;
    onChange: (value: TValue | undefined) => void;
}>;

export const EditableCell = function EditableCell<TValue>({
    children,
    align,
    Field,
    value,
    onChange,
    ...rest
}: EditableCellProps<TValue>): JSX.Element {
    const { hovered, ...onMouseActions } = useOnHover();

    const editIconPosition = align === 'right' ? { left: 8 } : { right: 8 };

    const { setDialog, closeDialog } = useDialogContext();

    return (
        <StyledTableCell
            onClick={() => {
                setDialog(
                    <Dialog open={true} title={''} onClose={closeDialog}>
                        <DialogContent>
                            <ErrorBoundary fallback={ErrorFallback}>
                                <Field
                                    autoFocus
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        closeDialog();
                                    }}
                                    {...rest}
                                />
                            </ErrorBoundary>
                        </DialogContent>
                    </Dialog>,
                );
            }}
            sx={{
                textAlign: align,
            }}
            {...onMouseActions}
        >
            {children}
            {hovered && (
                <Edit
                    style={{
                        ...editIconPosition,
                        position: 'absolute',
                        backgroundColor: colorSystem.primary[1],
                        padding: 4,
                        borderRadius: '100%',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: 16,
                        width: 16,
                        color: colorSystem.neutral[6],
                    }}
                />
            )}
        </StyledTableCell>
    );
};

const selectedStyle = {
    backgroundColor: colorSystem.primary[1],
    outline: `1px solid ${colorSystem.primary[5]}`,
    borderRadius: '4px',
    zIndex: 10,
};

const StyledTableCell = styled('td')({
    fontFamily: NOTO_SANS,
    padding: '4px 8px',
    fontSize: 12,
    background: 'transparent',
    position: 'relative',
    cursor: 'pointer',
    outlineOffset: -1,
    '&:focus': {
        ...selectedStyle,
    },
    '&:hover': {
        ...selectedStyle,
    },
    '&:focus:hover': {
        ...selectedStyle,
    },
    // no overflow
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

function useOnHover() {
    const [hovered, setHovered] = React.useState(false);

    const onMouseEnter = React.useCallback(() => {
        setHovered(true);
    }, []);

    const onMouseLeave = React.useCallback(() => {
        setHovered(false);
    }, []);

    return {
        onMouseEnter,
        onMouseLeave,
        onMouseOut: onMouseLeave,
        hovered,
    };
}
