import { usePersistedState } from '@luminovo/design-system';
import React from 'react';
import { getPcbPdfAnalysisId, getPcbPdfExtractionId } from './getPcbAnalysisId';

export const useShowPcbPdfAnalysisMessageModal = (pcbId: string) => {
    const [hasUserSeenModal, setUserHasSeenModal] = usePersistedState<'true' | 'false'>(
        getPcbPdfAnalysisId(pcbId),
        'false',
        localStorage,
    );

    const [userAgreesToExtraction, setUserAgreesToExtraction] = usePersistedState<'true' | 'false'>(
        getPcbPdfExtractionId(pcbId),
        'false',
        localStorage,
    );

    const handleShowMessageModalClick = React.useCallback(
        (value: boolean) => {
            setUserHasSeenModal(value ? 'true' : 'false');
        },
        [setUserHasSeenModal],
    );

    const handleEnableExtractionClick = React.useCallback(
        (value: boolean) => {
            setUserAgreesToExtraction(value ? 'true' : 'false');
        },
        [setUserAgreesToExtraction],
    );

    return {
        userHasSeenModal: hasUserSeenModal === 'true',
        setUserHasSeenModal: handleShowMessageModalClick,
        userAgreesToExtraction: userAgreesToExtraction === 'true',
        setUserAgreesToExtraction: handleEnableExtractionClick,
    };
};
