import { PCBV2 } from '@luminovo/http-client';
import {
    calculatePercent as calculateCompletionPercent,
    isPcbAnalysisInProgress,
} from '../../../resources/pcb/pcbFunctions';

export const usePcbAnalysisProgress = (pcb: PCBV2) => {
    const percent = calculateCompletionPercent(pcb);
    const isPcbAnalysisDone = !isPcbAnalysisInProgress(pcb);

    return {
        isPcbAnalysisDone,
        percentageComplete: percent,
    };
};
