import { Text } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';
import { CSSProperties } from 'react';

export interface TooltipTextProps {
    text: string;
    minCharactersWithTooltip: number;
    maxWidth: CSSProperties['maxWidth'];
    overrides?: {
        textStyle?: CSSProperties;
    };
}

/**
 * A text that is truncated with an ellipsis and shows the full text on hover.
 * You need to specify the maximum width of the text and the minimum number of characters
 * that should be displayed before the tooltip is shown.
 */
export const TooltipText = ({ text, maxWidth, minCharactersWithTooltip, overrides }: TooltipTextProps): JSX.Element => {
    return (
        <Tooltip title={text.length >= minCharactersWithTooltip ? text : ''}>
            <Text
                style={{
                    maxWidth: maxWidth,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    ...overrides?.textStyle,
                }}
            >
                {text}
            </Text>
        </Tooltip>
    );
};
