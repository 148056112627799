import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ApiRelevanceRuntype, SourcesApiRuntype } from './relevanceBackendTypes';

export const sourcingApisEndpoints = {
    'GET /sourcing-apis/relevance': endpoint({
        description: 'Returns a list of all integrations',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(ApiRelevanceRuntype),
    }),
    'PUT /sourcing-apis/:sourcesApi/relevance': endpoint({
        description: 'Edits an integration id',
        pathParams: r.Record({ sourcesApi: SourcesApiRuntype }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['GET /sourcing-apis/relevance'],
    }),
    'DELETE /sourcing-apis/:sourcesApi/relevance': endpoint({
        description: 'Edits an integration id',
        pathParams: r.Record({ sourcesApi: SourcesApiRuntype }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['GET /sourcing-apis/relevance'],
    }),
};
