import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import { PageLayout } from '../../../../components/PageLayout';
import AssemblyOverviewToolbar from '../../../../modules/AssemblyOverview/Dashboard/Toolbar';
import { PcbModuleContextProvider } from '../../../../modules/Pcb/components/PcbModuleContextProvider';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { route } from '../../../../utils/routes';

export default function AssemblyOverviewPcbLayout({
    assemblyId,
    children,
}: React.PropsWithChildren<{ assemblyId: string }>) {
    const { data: currentAssembly } = useAssembly(assemblyId);

    if (!isPresent(currentAssembly)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const additionalBreadCrumbsFromRoot = [
        {
            title: currentAssembly.designator,
            href: route('/assemblies/:assemblyId/dashboard', { assemblyId }),
        },
        {
            title: t`PCB`,
        },
    ];

    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                <AssemblyOverviewToolbar
                    assembly={currentAssembly}
                    additionalBreadcrumbsFromRoot={additionalBreadCrumbsFromRoot}
                />
            }
            layout="fragment"
        >
            <PcbModuleContextProvider>{children}</PcbModuleContextProvider>
        </PageLayout>
    );
}
