import { Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { ExpensesCostComponent } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { expenseCostCalculationTranslations } from '../../../../../resources/expenses/expensesBackendTypes';
import { CalculationTypeWithIcon } from '../../../shared/ManufacturingSharedComponents/CalculationTypeWithIcon';

type RowWithCostCalculation = {
    cost_components: {
        type: ExpensesCostComponent['type'];
    };
};

const getCostCalculationFilters = <T extends RowWithCostCalculation, C>(): Filter<T, C>[] => {
    return Object.keys(expenseCostCalculationTranslations).map((key) => ({
        id: `cost calculation=${key}`,
        label: transEnum(key, expenseCostCalculationTranslations),
        predicate: (rowData) => rowData.cost_components.type === key,
    }));
};

export function getCostCalculationColumn<T extends RowWithCostCalculation, C>(): Column<T, C> {
    return {
        label: <Trans>Cost calc.</Trans>,
        id: 'costCalculationColumn',
        render: ({ data }: Row<RowWithCostCalculation>) => (
            <TableCell>
                <CalculationTypeWithIcon calculationType={data.cost_components.type} />
            </TableCell>
        ),
        width: columnWidth.small,
        filters: getCostCalculationFilters(),
    };
}
