import { InputAdornment } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { StandardPartQuoteFormValues } from '../types';

export function LeadTimeUnitAdornment() {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const leadTimeUnit = useWatch({
        control,
        name: 'leadTimeUnit',
    });
    return <InputAdornment position="end">{leadTimeUnit}</InputAdornment>;
}
