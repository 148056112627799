/* eslint-disable import/no-unused-modules */
import * as r from 'runtypes';

const PCBAnalysisStreamMessageRuntype = r.Record({
    t: r.Literal('analysis'),
});

const PCBLifecycleStreamMessageRuntype = r.Record({
    t: r.Literal('lifecycle'),
    m: r.Record({
        lifecycle: r.Array(
            r.Record({
                name: r.String,
                status: r.Record({
                    name: r.String,
                }),
            }),
        ),
    }),
});

const PCBFilesStreamMessageRuntype = r.Record({
    t: r.Literal('file'),
});

const PCBPingStreamMessageRuntype = r.Record({
    t: r.Literal('ping'),
});

export type PCBStreamMessage = r.Static<typeof PCBStreamMessageRuntype>;
export const PCBStreamMessageRuntype = r.Union(
    PCBAnalysisStreamMessageRuntype,
    PCBLifecycleStreamMessageRuntype,
    PCBFilesStreamMessageRuntype,
    PCBPingStreamMessageRuntype,
);
