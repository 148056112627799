/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TtiCredentialsDTO extends r.Static<typeof TtiCredentialsDTORuntype> {}
export const TtiCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        customer_account_number: r.String,
        region: r.Union(r.Literal('EU'), r.Literal('NA'), r.Literal('AS')),
    }),
);

export interface TtiCredentialsInputDTO extends r.Static<typeof TtiCredentialsInputDTORuntype> {}
export const TtiCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    customer_account_number: r.String.optional(),
    region: r.Union(r.Literal('EU'), r.Literal('NA'), r.Literal('AS')),
});

export interface TtiResponseBodyDTO extends r.Static<typeof TtiResponseBodyDTORuntype> {}
export const TtiResponseBodyDTORuntype = r.Record({
    data: TtiCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
