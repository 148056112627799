import { t } from '@lingui/macro';
import { SiteDTO } from '@luminovo/http-client';
import type { ParsedValue, Parser } from '../types';

const emptyParsedValue: ParsedValue<string> = {
    id: '',
    label: '',
    description: '',
    existing: false,
};

export interface Opts {
    /**
     * List of sites to compare the site number against.
     */
    sites: SiteDTO[];
}

export const parseSiteNumberOrName: Parser<string, Opts> = async ([cell], { sites }, field) => {
    cell = cell.trim();
    const alternatives = sites.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'done',
            value: emptyParsedValue,
            message: '',
            alternatives,
        };
    }

    const foundSite = sites.find((s) => s.name === cell || s.site_number === cell);

    if (foundSite) {
        return {
            status: 'done',
            value: toParsedValue(foundSite),
            alternatives,
        };
    }

    return {
        status: 'error',
        message: t`Site not found`,
        alternatives,
    };
};

function toParsedValue(site: SiteDTO): ParsedValue<string> {
    return {
        id: site.id,
        label: site.name,
        description: site.site_number ?? '',
    };
}
