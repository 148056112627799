import { formatInitials } from '@luminovo/commons';
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text';

type AvatarIconProps = MuiAvatarProps &
    (
        | {
              size: 'small';
              user: { firstName: string; lastName: string; email: string };
              extended?: boolean;
          }
        | {
              size: 'large';
              user: { firstName: string; lastName: string; email: string };
          }
    );

export function hashStringToColor(str: string): keyof typeof colorSystem {
    const colors: Array<keyof typeof colorSystem> = ['primary', 'red', 'yellow', 'green', 'neutral', 'violet', 'teal'];

    const index = str
        .split('')
        .map((s) => s.charCodeAt(0))
        .reduce((a, b) => a + b, 0);

    return colors[index % colors.length];
}

const SmallAvatar = forwardRef(
    (
        {
            styles,
            label,
            extended = false,
            ...props
        }: {
            styles: { background: string; color: string };
            label: { regular: string; extended: string };
            extended?: boolean;
        },
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        if (extended) {
            return (
                <MuiAvatar
                    ref={ref}
                    style={{ height: '24px', background: styles.background, borderRadius: '24px' }}
                    {...props}
                >
                    <Text variant="caption" color={styles.color}>
                        {label.extended}
                    </Text>
                </MuiAvatar>
            );
        }
        return (
            <MuiAvatar ref={ref} style={{ height: '24px', width: '24px', background: styles.background }} {...props}>
                <Text variant="caption" color={styles.color}>
                    {label.regular}
                </Text>
            </MuiAvatar>
        );
    },
);

export const AvatarIcon = forwardRef(
    ({ size, user, ...props }: AvatarIconProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
        const label = formatInitials(user);
        const color = hashStringToColor(label.extended);
        const styles = {
            background: colorSystem[color][2],
            color: colorSystem[color][8],
        };
        switch (size) {
            case 'small':
                return <SmallAvatar ref={ref} styles={styles} label={label} {...props} />;
            case 'large':
                return (
                    <LargeAvatar ref={ref} style={{ background: colorSystem[color][2] }} {...props}>
                        <Text variant="h5" color={styles.color}>
                            {label.regular}
                        </Text>
                    </LargeAvatar>
                );
        }
    },
);

const LargeAvatar = styled(MuiAvatar)({
    height: '32px',
    width: '32px',
});
