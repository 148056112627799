import { t } from '@lingui/macro';
import { formatMonetaryValue, isPresent } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import { AdditionalCostSummaryDTO } from '@luminovo/http-client';
import { CostTag, CostType, getCostName } from '@luminovo/sourcing-core';

const CostRow = ({
    costType,
    totalAdditionalCosts,
    unitAdditionalCosts,
}: {
    costType: CostType;
    totalAdditionalCosts: AdditionalCostSummaryDTO | undefined;
    unitAdditionalCosts: AdditionalCostSummaryDTO | undefined;
}) => {
    const totalBreakdown = totalAdditionalCosts?.breakdown[getCostName(costType)];
    const unitBreakdown = unitAdditionalCosts?.breakdown[getCostName(costType)];

    const formattedTotalBreakdown = isPresent(totalBreakdown)
        ? formatMonetaryValue(totalBreakdown, 'unit-price')
        : undefined;
    const formattedUnitBreakdown = isPresent(unitBreakdown)
        ? formatMonetaryValue(unitBreakdown, 'unit-price')
        : undefined;

    let formattedCost = '-';
    if (formattedTotalBreakdown) {
        formattedCost = formattedTotalBreakdown;
    }
    if (formattedUnitBreakdown) {
        formattedCost = `${formattedCost} (${formattedUnitBreakdown} / ${t`unit`})`;
    }

    return (
        <Flexbox justifyContent="space-between" minWidth={200} alignItems="center" gap={12}>
            <CostTag costType={costType} />
            {formattedCost}
        </Flexbox>
    );
};

export const AdditionalTotalAndUnitCostTooltip = ({
    totalAdditionalCosts,
    unitAdditionalCosts,
}: {
    totalAdditionalCosts: AdditionalCostSummaryDTO | undefined;
    unitAdditionalCosts: AdditionalCostSummaryDTO | undefined;
}): JSX.Element => {
    const costTypes: CostType[] = ['packaging', 'discount', 'customs', 'shipping', 'other'];
    return (
        <Flexbox flexDirection="column" gap={16} padding={1}>
            {costTypes.map((costType, index) => (
                <CostRow
                    key={index}
                    costType={costType}
                    totalAdditionalCosts={totalAdditionalCosts}
                    unitAdditionalCosts={unitAdditionalCosts}
                />
            ))}
        </Flexbox>
    );
};
