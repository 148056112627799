import { SidebarLayout } from '@luminovo/design-system';
import { BoxProps } from '@mui/material';
import { ErrorFallback } from './errorHandlers/ErrorBoundary';
import NavbarRouter from './routers/NavbarRouter';

type Props = {
    header?: JSX.Element;
    navbar?: JSX.Element;
    sidebar?: JSX.Element;
    contentBackground?: string;
    defaultSidebarWidth?: string;
} & BoxProps;

export function PageLayoutSidebar({
    header,
    navbar = <NavbarRouter />,
    children,
    sidebar,
    contentBackground,
    defaultSidebarWidth,
}: Props) {
    return (
        <SidebarLayout
            navbar={navbar}
            toolbar={header}
            sidebar={sidebar}
            defaultSidebarWidth={defaultSidebarWidth}
            content={children}
            contentBackground={contentBackground}
            fallback={ErrorFallback}
        />
    );
}
