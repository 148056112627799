import { t } from '@lingui/macro';
import { Validate } from './types';

export function validateDesignator({
    designators,
    siblingDesignators,
}: {
    designators: string[];
    siblingDesignators: string[];
}): Validate {
    if (designators.length === 0) {
        return { isError: true, message: t`Required` };
    }
    const duplicate = siblingDesignators.find((sibling) => designators.includes(sibling));
    if (duplicate) {
        return { isError: true, message: t`Designator ${duplicate} already in use` };
    }
    return { isError: false, message: '' };
}
