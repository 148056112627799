import { assertUnreachable } from '@luminovo/commons';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { PackageChip } from '@luminovo/sourcing-core';
import { useGenericPartPackage } from '../partSpecificationCards/GenericPart/useGenericPartPackage';
import { TableCell } from './TableCell';

export const RenderGenericPackageData = ({ packageId }: { packageId: string | null }) => {
    const packageData = useGenericPartPackage({
        packageId,
    });
    const isPackage = packageData?.mounting || packageData?.name;
    return <TableCell>{isPackage ? <PackageChip formfit={packageData} /> : '-'}</TableCell>;
};

export const RenderPackage = ({ part }: { part: FullPart }) => {
    if (isOtsFullPart(part)) {
        if (!part.package || (!part.package.mounting && !part.package.name)) {
            return <TableCell>-</TableCell>;
        }
        return <TableCell>{part.package ? <PackageChip formfit={part.package} /> : '-'}</TableCell>;
    }
    if (isGenericFullPart(part)) {
        const packageId = part.content.technical_parameters.package_id;
        return <RenderGenericPackageData packageId={packageId} />;
    }
    if (isOtsComponentFull(part)) {
        const formFit = part.component_specification?.form_and_fit;
        return (
            <TableCell isComponentRemoved={part.state === 'Removed'}>
                {formFit ? <PackageChip formfit={formFit} disabled={part.state === 'Removed'} /> : '-'}
            </TableCell>
        );
    }
    if (isCustomComponentFull(part)) {
        return <TableCell isComponentRemoved={part.state === 'Removed'}>-</TableCell>;
    }
    if (isCustomFullPart(part)) {
        return <TableCell />;
    }
    assertUnreachable(part);
};
