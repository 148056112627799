import jwtDecode, { JwtPayload } from 'jwt-decode';

export type AuthToken = string;
let token: AuthToken | null = null;

export function setToken(newToken: AuthToken): void {
    token = newToken;
}

/**
 * Gets the current token. If no token is present, an error is thrown.
 */
export function getToken(): AuthToken {
    if (!token) {
        throw new Error(`Token not present, make sure you've set the token before with setToken().`);
    }
    return token;
}

/**
 * Gets the current token if present. If no token is present, undefined is returned.
 */
export function getTokenIfPresent(): AuthToken | undefined {
    return token ?? undefined;
}

export function isExpired(token: AuthToken): boolean {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const expirationDate = decodedToken.exp;
    if (!expirationDate) {
        return false;
    }
    // Info: This might fail if the user's system time is incorrect.
    return expirationDate * 1000 < Date.now();
}
