import { CountryAlpha2Code, allCountryCodes } from '@luminovo/http-client';
import { ValueWithTranslation, sortValuesByTranslation } from '../../../utils/internationalisation';
import { translateCountry } from './i18n';

const translatedCountries: ValueWithTranslation<CountryAlpha2Code>[] = allCountryCodes.map((k) => {
    return {
        value: k,
        translation: translateCountry(k),
    };
});

export const sortedCountries = translatedCountries.sort(sortValuesByTranslation);
