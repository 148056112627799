import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import { Dialog, DialogContent, DialogTitle, Flexbox, Text } from '@luminovo/design-system';
import { http, SourcingScenarioDTO } from '@luminovo/http-client';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { AssemblySourcingScenariosTable, useIsAssemblyQuantityOfAssemblyValid } from '../modules/AssemblyForm';
import { ViewContext } from '../modules/Bom/components/ModuleTableData';
import { getAssemblyCadStorageKey } from '../modules/Pcb/CadViewerTab/utils/useCadFileData';
import { getSpecificAssemblyPath, useAssembly } from '../resources/assembly/assemblyHandler';
import { useDebugErrorHandler } from '../resources/http/debugErrorHandler';
import { useInvalidateAssemblyQueries } from '../resources/invalidateQueries';
import { route } from '../utils/routes';
import { useDialogContext } from './contexts/ModalContext';
import ConfirmationDialogBox from './dialogBox/ConfirmationDialogBox';

interface UseDeleteAssembly {
    deleteAssembly: (assemblyId: string) => Promise<void>;
}

const useDeleteAssembly = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
    viewContext,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}): UseDeleteAssembly => {
    const token = getToken();
    const { data: currentAssembly } = useAssembly(assemblyId, { with_aggregations: false, enabled: true });
    const history = useHistory();

    const { invalidateAssemblyDescendants, invalidateAssemblyDescendantsSummary } =
        useInvalidateAssemblyQueries(assemblyId);
    const {
        invalidateAssemblyDescendants: invalidateAssemblyDescendantsRfq,
        invalidateAssemblyDescendantsSummary: invalidateAssemblyDescendantsSummaryRfq,
    } = useInvalidateAssemblyQueries(rfqId);
    const {
        invalidateAssemblyDescendants: inValidateAssemblyDescendantsParent,
        invalidateAssemblyDescendantsSummary: invalidateAssemblyDescendantsSummaryParent,
    } = useInvalidateAssemblyQueries(Object.keys(currentAssembly?.parents ?? {})[0] ?? undefined);
    const onError = useDebugErrorHandler();
    function redirectAndInvalidateQueries() {
        localStorage.removeItem(getAssemblyCadStorageKey(assemblyId));
        if (
            currentAssembly === undefined ||
            (currentAssembly.rfq !== null && currentAssembly.rfq in currentAssembly.parents) ||
            Object.keys(currentAssembly.parents).length === 0
        ) {
            // Without the hideTopLevelAssembly flag, the user would be redirected to the (deleted) assembly path
            history.push(route('/rfqs/:rfqId/bom', { rfqId }, { hideTopLevelAssembly: 'true' }));
            invalidateAssemblyDescendantsRfq();
            invalidateAssemblyDescendantsSummaryRfq();
        } else {
            const parentAssemblyId = currentParentAssemblyId ?? Object.keys(currentAssembly.parents)?.[0];
            if (parentAssemblyId && viewContext.type === 'WithinRfQ') {
                history.push(
                    getSpecificAssemblyPath({
                        rfqId,
                        assemblyId: parentAssemblyId,
                        // at this point, we do not have access to the parent's parent, so
                        // we'll leave it as undefined.
                        currentParentAssemblyId: undefined,
                    }),
                );
            }

            if (viewContext.type === 'AssemblyOverview') {
                let parentAssemblyRoute = parentAssemblyId
                    ? route(
                          '/assemblies/:assemblyId/dashboard',
                          { assemblyId: parentAssemblyId },
                          { rfqId, tab: undefined, isMonitoringOpen: undefined },
                      )
                    : route('/assemblies');

                history.push(parentAssemblyRoute);
            }
            inValidateAssemblyDescendantsParent();
            invalidateAssemblyDescendantsSummaryParent();
            invalidateAssemblyDescendants();
            invalidateAssemblyDescendantsSummary();
        }
    }

    const { openDialog } = useAssemblyDeleteValidationDialog();
    const { hasNonZeroAssemblyQuantity, nonZeroSourcingScenarios } = useIsAssemblyQuantityOfAssemblyValid({
        rfqId,
        assemblyId,
    });

    const deleteAssembly = async (assemblyId: string) => {
        if (hasNonZeroAssemblyQuantity && isPresent(nonZeroSourcingScenarios)) {
            openDialog({ assemblyId, nonZeroSourcingScenarios });
        } else {
            await http('DELETE /assemblies/:assemblyId', { pathParams: { assemblyId } }, token)
                .then(() => {
                    redirectAndInvalidateQueries();
                })
                .catch((error) => {
                    onError(error);
                    redirectAndInvalidateQueries();
                });
        }
    };
    return { deleteAssembly };
};

const useAssemblyDeleteValidationDialog = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            assemblyId,
            nonZeroSourcingScenarios,
        }: {
            assemblyId: string;
            nonZeroSourcingScenarios: SourcingScenarioDTO[];
        }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Deleting not possible`} handleClose={() => closeDialog()} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text>
                                <Trans>The top-level assembly is in use in the following sourcing scenarios.</Trans>
                            </Text>
                            <AssemblySourcingScenariosTable
                                assemblyId={assemblyId}
                                sourcingScenarios={nonZeroSourcingScenarios}
                            />
                            <Text>
                                <Trans>Before deleting it you need to set all order sizes to zero.</Trans>
                            </Text>
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
};

export function useDeleteAssemblyButton({
    rfqId,
    assemblyId,
    viewContext,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}) {
    const { data: assemblyData } = useAssembly(assemblyId);

    const [isConfirmDeletionDialogOpen, setIsConfirmDeletionDialogOpen] = React.useState(false);

    const { deleteAssembly } = useDeleteAssembly({ rfqId, assemblyId, currentParentAssemblyId, viewContext });

    const handleDeleteAssembly = React.useCallback(() => {
        deleteAssembly(assemblyId);
    }, [assemblyId, deleteAssembly]);

    const assemblyDesignator = assemblyData?.designator;
    const title = assemblyDesignator ? t`Delete assembly` + `: ${assemblyDesignator}` : t`Delete assembly`;

    return {
        onClick: () => {
            // eslint-disable-next-line camelcase
            setIsConfirmDeletionDialogOpen(true);
        },
        dialogs: (
            <>
                <ConfirmationDialogBox
                    text={t`If you continue you will also delete all subassemblies, BOM Items and custom parts. Are you sure you want to delete it?`}
                    closeIconIsShown={true}
                    onConfirm={handleDeleteAssembly}
                    title={title}
                    isDialogOpen={isConfirmDeletionDialogOpen}
                    onReject={() => setIsConfirmDeletionDialogOpen(false)}
                />
            </>
        ),
    };
}
