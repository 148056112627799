import { styled } from '@mui/material';
import { colorSystem, SEMI_BOLD_WEIGHT } from '../../theme';
import { BaseButton } from './BaseButton';

export const SecondaryButton = styled(BaseButton)({
    background: colorSystem.neutral.white,
    color: colorSystem.neutral[7],
    border: `1px solid ${colorSystem.neutral[3]}`,
    fontWeight: SEMI_BOLD_WEIGHT,
    '&:hover': {
        background: colorSystem.primary[1],
        color: colorSystem.primary[7],
        border: `1px solid ${colorSystem.primary[3]}`,
    },
    '&:active': {
        background: colorSystem.primary[2],
        color: colorSystem.primary[7],
        border: `1px solid ${colorSystem.primary[3]}`,
    },
    '&:disabled': {
        background: colorSystem.neutral.white,
        color: colorSystem.neutral[5],
        border: `1px solid ${colorSystem.neutral[4]}`,
    },
});
