import { t, Trans } from '@lingui/macro';
import { DestructivePrimaryButton, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import Dialog from '@mui/material/Dialog';
import React, { useMemo, useState } from 'react';
import { ErrorText } from '../../components/Spinners';
import { BomItem } from '../../resources/designItem/bomItemFrontendTypes';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { DesignatorSelectChips } from './components/DesignatorSelectChips';
import { SelectAndDeselectButtons } from './SelectionDialog';

interface DeleteDesignItemsDialogProps {
    text: string;
    title: string;
    open: boolean;
    closeDialog: () => void;
    bomItem: BomItem;
    onDeleteNewBomItem: () => void;
    isNewBomItem: boolean;
}

// this const is outside the Component since its only read and never mutated.
const noDesignators: string[] = [];

const DeleteDesignItemsDialog = ({
    text,
    title,
    open,
    closeDialog,
    bomItem,
    onDeleteNewBomItem,
    isNewBomItem,
}: DeleteDesignItemsDialogProps) => {
    const [selectedDesignators, setSelectedDesignators] = useState<string[]>(bomItem.designator);
    const allDesignators: string[] = bomItem.designator;

    const designItemIdsToDelete = useMemo(() => {
        const individualDesignItems = bomItem.individualDesignItems;
        // empty BOM item with no designator
        if (bomItem.designator.length === 0) {
            return individualDesignItems.map((c) => c.id);
        }
        return individualDesignItems
            .filter((designItem) => selectedDesignators.includes(designItem.designator ?? ''))
            .map((c) => c.id);
    }, [bomItem.designator.length, bomItem.individualDesignItems, selectedDesignators]);

    const disableDeleteButton = !isNewBomItem && designItemIdsToDelete.length === 0;

    const { mutateAsync, isLoading, isError } = useHttpMutation('POST /design-items/bulk-delete', {
        snackbarMessage: t`BOM item deleted`,
        onSuccess: () => closeDialog(),
    });

    const deleteOnClick = async () => {
        if (isNewBomItem) {
            onDeleteNewBomItem();
            return closeDialog();
        }
        await mutateAsync({ requestBody: { ids: designItemIdsToDelete } });
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
            }}
        >
            <Flexbox flexDirection="column" padding="28px" gap={16}>
                <Text variant="h1" style={{ alignSelf: 'center' }}>
                    {title}
                </Text>

                <Text>{text}</Text>

                <SelectAndDeselectButtons
                    setSelectedDesignators={setSelectedDesignators}
                    allDesignators={allDesignators}
                    noDesignators={noDesignators}
                    selectedDesignators={selectedDesignators}
                />

                <DesignatorSelectChips
                    designators={selectedDesignators}
                    setDesignators={setSelectedDesignators}
                    options={bomItem.designator}
                    chipColor={'error'}
                />
                <Flexbox justifyContent="flex-end" gap="8px" alignItems="center">
                    {isError && <ErrorText />}
                    <SecondaryButton
                        isLoading={isLoading}
                        size="medium"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            closeDialog();
                        }}
                    >
                        <Trans>Cancel</Trans>
                    </SecondaryButton>

                    <DestructivePrimaryButton
                        disabled={disableDeleteButton}
                        isLoading={isLoading}
                        size="medium"
                        onClick={(_: React.MouseEvent<HTMLElement>) => {
                            deleteOnClick();
                        }}
                    >
                        <Trans>Delete</Trans>
                    </DestructivePrimaryButton>
                </Flexbox>
            </Flexbox>
        </Dialog>
    );
};
export default DeleteDesignItemsDialog;
