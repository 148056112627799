import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import { OtsFullPart, RfqContext } from '@luminovo/http-client';
import { PartDetailsPanel } from '../../../modules/PartLibrary/PartDetailsPage';
import { CloseDrawerButton, useDrawerContext } from '../../contexts/ModalContext';

export function useOtsPartDetailsDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();
    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ part, rfqContext }: { part: Pick<OtsFullPart, 'id'>; rfqContext: RfqContext }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} padding={'12px 24px 12px 16px'} width={'420px'}>
                        <PartDetailsPanel
                            partId={part.id}
                            rfqContext={rfqContext}
                            BackButton={() => <CloseDrawerButton disableMargin={true} />}
                            showOpenPartPageButton={true}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
