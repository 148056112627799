import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    ManufacturingScenarioTemplateDTORuntype,
    ManufacturingScenarioTemplatePatchDTORuntype,
    ManufacturingScenarioTemplatePostDTORuntype,
} from './manufacturingScenarioTemplatesBackendTypes';

export const manufacturingScenarioTemplatesEndpoints = {
    'GET /manufacturing-scenario-templates': endpoint({
        description: 'Fetches a list of templates',
        pathParams: r.Undefined,
        queryParams: r.Record({
            expense_ids: r.Array(r.String).optional(),
            activity_ids: r.Array(r.String).optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(ManufacturingScenarioTemplateDTORuntype) }),
    }),

    'POST /manufacturing-scenario-templates': endpoint({
        description: 'Creates a new template',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ManufacturingScenarioTemplatePostDTORuntype,
        responseBody: r.Record({ data: ManufacturingScenarioTemplateDTORuntype }),
        invalidates: ['GET /manufacturing-scenario-templates', 'GET /manufacturing-scenario-templates/:templateId'],
    }),

    'GET /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Fetches a single template by id',
        pathParams: r.Record({ templateId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: ManufacturingScenarioTemplateDTORuntype }),
    }),

    'PATCH /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Updates a template',
        pathParams: r.Record({ templateId: r.String }),
        queryParams: r.Undefined,
        requestBody: ManufacturingScenarioTemplatePatchDTORuntype,
        responseBody: r.Record({ data: ManufacturingScenarioTemplateDTORuntype }),
        invalidates: ['GET /manufacturing-scenario-templates', 'GET /manufacturing-scenario-templates/:templateId'],
    }),

    'DELETE /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Deletes a template',
        pathParams: r.Record({ templateId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
};
