import { Trans } from '@lingui/macro';
import { Flexbox, Text } from '@luminovo/design-system';

export function StepCheckHelpDialog(): JSX.Element {
    return (
        <Flexbox flexDirection={'column'} gap={20}>
            <Text>
                <Trans>
                    Now that you've finished mapping the columns, we need to ensure that every cell has data in the
                    right format.
                </Trans>
            </Text>

            <Text>
                <Trans>
                    To fix a cell, click on the cell and edit the value. You can also skip the row by ticking off the
                    include checkbox.
                </Trans>
            </Text>

            <Text>
                <Trans>Note: it's always safe to re-import your file.</Trans>
            </Text>
        </Flexbox>
    );
}
