import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { h4, h5 } from '../../theme';
import { XOR } from '../DataTable/types';

export type ButtonSize = 'small' | 'medium' | 'large';

export type BaseButtonProps = Omit<ButtonProps, 'onClick' | 'size'> &
    XOR<Pick<ButtonProps, 'onClick'>, { href: string }> & { size?: ButtonSize; isLoading?: boolean };

export const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
    ({ href, size = 'large', isLoading = false, ...props }, ref) => {
        const history = useHistory();
        const onClick = React.useCallback(() => {
            if (href) {
                history.push(href);
            }
        }, [href, history]);

        const startIcon = isLoading ? <CircularProgress size={'auto'} /> : props.startIcon;

        switch (size) {
            case 'small':
                return (
                    <SmallButton
                        {...props}
                        startIcon={startIcon}
                        onClick={href ? onClick : props.onClick}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
            case 'medium':
                return (
                    <MediumButton
                        {...props}
                        startIcon={startIcon}
                        onClick={href ? onClick : props.onClick}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
            case 'large':
                return (
                    <LargeButton
                        {...props}
                        startIcon={startIcon}
                        onClick={href ? onClick : props.onClick}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
        }
    },
);

const SmallButton = styled(Button)({
    ...h5,
    height: '24px',
    padding: '3px 6px',
    minWidth: 'unset',
    whiteSpace: 'nowrap',
    '& * > svg': {
        width: '16px',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px',
        marginLeft: '-2px',
    },
    '& .MuiButton-startIcon ': {
        margin: '0px 4px 0px 0px',
    },
});

const MediumButton = styled(Button)({
    ...h5,
    height: '32px',
    padding: '8px 12px',
    whiteSpace: 'nowrap',
    '& * > svg': {
        width: '16px',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px',
    },
    '& .MuiButton-startIcon ': {
        margin: '0px 4px 0px 0px',
    },
});

const LargeButton = styled(Button)({
    ...h4,
    height: '40px',
    padding: '10px 12px',
    whiteSpace: 'nowrap',
    '& * > svg': {
        width: '20px',
        height: '20px',
        fontSize: '14px',
        lineHeight: '20px',
    },
    '& .MuiButton-startIcon ': {
        margin: '0px 8px 0px 0px',
    },
});
