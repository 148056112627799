import { Trans } from '@lingui/macro';
import {
    ActorResponseRuntypeDTO,
    ApprovalStatus,
    HistoryCustomComponentDataDTO,
    HistoryOperation,
} from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    ApprovalStatusIcon,
    AutomaticApprovalStatusPhrase,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineStatusChip,
    TimelineText,
} from './Utils';

type Props = {
    data: HistoryCustomComponentDataDTO;
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

export function CustomComponentTimelineIcon({
    operation,
    approvalStatus,
}: {
    operation: HistoryOperation;
    approvalStatus: ApprovalStatus;
}) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;

    return <ApprovalStatusIcon approvalStatus={approvalStatus} />;
}

function useCustomComponentAction(operation: HistoryOperation, approvalStatus: ApprovalStatus) {
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return <Trans>added</Trans>;
        case HistoryOperation.ManualRemove:
            return <Trans>removed</Trans>;
        case HistoryOperation.AutoUpdate:
            return (
                <>
                    <Trans>was removed from IPN database and</Trans>{' '}
                    <AutomaticApprovalStatusPhrase approvalStatus={approvalStatus} />
                </>
            );
        case HistoryOperation.ManualUpdateApproval:
            return <TimelineStatusChip approvalStatus={approvalStatus} />;
        default:
            return null;
    }
}
export default function CustomComponent({ actor, operation, data }: Props) {
    const action = useCustomComponentAction(operation, data.approval);
    if (action) {
        return (
            <CustomComponentContent data={data} actor={actor}>
                {action}
            </CustomComponentContent>
        );
    }
    return null;
}

type CustomComponentActionProps = {
    data: HistoryCustomComponentDataDTO;
    actor: ActorResponseRuntypeDTO;
};

function CustomComponentContent({ data, actor, children }: React.PropsWithChildren<CustomComponentActionProps>) {
    const actorElement = isActorDefined(actor) ? <ActionPerformedBy actor={actor} /> : null;
    return (
        <TimelineContentContainer>
            <TimelineText>
                <CustomComponentDescription data={data} /> {children} {actorElement}
            </TimelineText>
        </TimelineContentContainer>
    );
}

function CustomComponentDescription({ data }: { data: HistoryCustomComponentDataDTO }) {
    const revisionElementIfPresent = data.ipn.revision ? (
        <>
            <Trans>with revision</Trans> <strong>{data.ipn.revision}</strong>
        </>
    ) : null;
    return (
        <>
            <Trans>Custom component</Trans> <strong>{data.ipn.value}</strong> {revisionElementIfPresent}
        </>
    );
}
