import React from 'react';
import { Argument, OperatorIs, ParametricSearchState } from '../types';
import { formatOperator } from '../util/formatOperator';
import { InteractiveMenu } from './InteractiveMenu';

export function MenuOperatorIs<T, TAutocompleteState>({
    state,
    operator,
    onSelect,
    inputRef,
    autocompleteState,
    onSearch,
}: {
    state: ParametricSearchState<T, TAutocompleteState>;
    operator: OperatorIs<T, TAutocompleteState, Argument>;
    onSelect: (argument: Argument, label?: string | JSX.Element) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    autocompleteState?: TAutocompleteState;
    onSearch: (query: string) => void;
}): JSX.Element {
    const { partialBlock } = state;
    const formattedOperator = partialBlock?.selectedOp ? formatOperator(partialBlock.selectedOp.op) : '';
    const operatorLabel = partialBlock?.selectedOp?.label ? partialBlock?.selectedOp?.label : formattedOperator;

    return (
        <InteractiveMenu
            showIcons={false}
            inputRef={inputRef}
            items={operator.options(autocompleteState).map((opt) => {
                const label: string = String(opt.label ?? opt.value);

                return {
                    id: label,
                    label: label,
                    value: opt.value,
                    count: opt.count,
                };
            })}
            query={state.query}
            onSelect={onSelect}
            onSearch={onSearch}
            title={`${partialBlock?.parameter.label ?? partialBlock?.parameter.field} ${operatorLabel}`}
        />
    );
}
