import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { DesignItemOriginTypes } from '@luminovo/http-client';

export const designItemOriginTypesTranslations: Record<DesignItemOriginTypes, MessageDescriptor> = {
    [DesignItemOriginTypes.Manual]: defineMessage({ message: 'Manual' }),
    [DesignItemOriginTypes.ExcelFile]: defineMessage({ message: 'Original Excel' }),
    [DesignItemOriginTypes.PCB]: defineMessage({ message: 'PCB' }),
    [DesignItemOriginTypes.AssemblyImport]: defineMessage({ message: 'Assembly import' }),
};

export enum BomItemIssuesEnum {
    MissingPartData = 'missingPartData',
    UnclearDesignatorQuantity = 'unclearDesignatorQuantity',
    IpnWithoutLinkedParts = 'ipnWithoutLinkedParts',
    GenericsWithoutMatches = 'genericsWithoutMatches',
    NoPartOptions = 'noPartOptions',
}

export const bomItemIssuesTranslations: Record<BomItemIssuesEnum, MessageDescriptor> = {
    [BomItemIssuesEnum.MissingPartData]: defineMessage({ message: 'Missing part data' }),
    [BomItemIssuesEnum.UnclearDesignatorQuantity]: defineMessage({ message: 'Unclear designator & quantity' }),
    [BomItemIssuesEnum.IpnWithoutLinkedParts]: defineMessage({ message: 'IPN without linked parts' }),
    [BomItemIssuesEnum.GenericsWithoutMatches]: defineMessage({ message: 'Generics without matches' }),
    [BomItemIssuesEnum.NoPartOptions]: defineMessage({ message: 'No part options' }),
};

export enum CommentsEnum {
    Resolved = 'Resolved',
    Unresolved = 'Unresolved',
}

export const CommentsTranslations: Record<CommentsEnum, MessageDescriptor> = {
    [CommentsEnum.Resolved]: defineMessage({ message: 'Resolved' }),
    [CommentsEnum.Unresolved]: defineMessage({ message: 'Unresolved' }),
};

export enum PartNumberEnum {
    IPN = 'IPN',
    CPN = 'CPN',
}

export const PartNumberTranslations: Record<PartNumberEnum, MessageDescriptor> = {
    [PartNumberEnum.IPN]: defineMessage({ message: 'Internal part number (IPN)' }),
    [PartNumberEnum.CPN]: defineMessage({ message: 'Customer part number (CPN)' }),
};
