import { assertUnreachable } from '@luminovo/commons';
import { ValidFor } from '@luminovo/http-client';

export const convertValidForToRfqId = (validFor: ValidFor, rfqId: string): string | null => {
    if (validFor === ValidFor.EveryCustomer) {
        return null;
    }
    if (validFor === ValidFor.ThisRfQ) {
        return rfqId;
    }
    if (validFor === ValidFor.ThisCustomer) {
        return null;
    }
    assertUnreachable(validFor);
};
