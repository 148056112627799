import { BaseParser, Parser } from './Parser';

class RegexParser extends BaseParser<string> {
    constructor(private regex: RegExp) {
        super((input) => {
            const match = input.match(this.regex);
            if (match) {
                return { ok: true, value: match[0], rest: input.slice(match[0].length) };
            }
            return { ok: false, expected: this.regex.toString() };
        });
    }
}

/**
 * A parser that matches a regular expression.
 */
export const regex = (regex: RegExp): Parser<string> => {
    return new RegexParser(regex);
};

/**
 * A regex parser that matches any whitespace character
 */
export const whitespaces = regex(/^\s*/);

/**
 * A regex parser that matches a string with the following format:
 *
 * ```
 * [sign] [integer part] [fractional part] [exponent part]
 * Example:
 * +      123            .456              e-789
 * ```
 */
export const decimalStr = regex(/^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?/);

/**
 * A regex parser that matches a natural number e.g. 0, 1, 2, 3, ...
 */
export const natural = regex(/^\d+/).map((str) => Number(str));

/**
 * A basic email regex parser. The goal of this parser is not to prevent all invalid email addresses but to catch the most common mistakes.
 */
export const email = regex(/^\S+@\S+\.\S+/);
