import { QuoteTrackingDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { AdditionalFiles } from '../../../Sourcing/components/QuoteTracking/QuoteTrackingSummary';

export function FormItemAdditionalFiles({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const { data: supplierPortalSettings } = useHttpQuery('GET /organization-settings/supplier-portal-settings', {});

    const isFileUploadRequired =
        supplierPortalSettings?.upload_additional_supplier_files && quoteTracking.files.length === 0;

    return <AdditionalFiles quoteTracking={quoteTracking} isFileUploadRequired={isFileUploadRequired} />;
}
