import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseETest, parseImpedanceTested, parseULMarking } from '../parsers/parseBooleanSpecs';
import { parseExclusiveYesOrNo } from '../parsers/parseYesNo';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractBooleanSpecs: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = isPresent(item.context?.content)
        ? `${item.context?.content} ${item.value.content}`
        : item.value.content;
    const valueParsers = [parseETest, parseULMarking, parseImpedanceTested, parseExclusiveYesOrNo];
    // right now "E-test :unselected:" would not necessarily get parsed cor
    return valueParsers
        .map((parser) => parser(stringToParse))
        .filter(isPresent)
        .map((attribute) => setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute)));
};
