import { AssemblyResponseDTO } from '@luminovo/http-client';
import { useHistory } from 'react-router';
import { route } from '../../../utils/routes';

export const useChangeToPcbPageIfPcbOnlyAssemblyType = ({
    rfqId,
    assembly,
}: {
    rfqId?: string;
    assembly: AssemblyResponseDTO | undefined;
}) => {
    const history = useHistory();
    if (assembly === undefined || !rfqId) {
        return;
    }

    if (assembly.type.type === 'PcbOnly') {
        history.replace(
            route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb', {
                rfqId,
                assemblyId: assembly.id,
            }),
        );
    }
};
