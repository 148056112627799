import { assertUnreachable } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import { DriverStatusDTO, ManufacturingAssemblyDetailsDTO, OKDriverStatusDTO, StatusDTO } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { colorSystem } from '../../../themes';
import { useStatusInformationFromDriverStatus } from '../shared/driverStatusInformation';

export const DriverStatusInformationCard = ({
    status,
    rfqId,
    manufacturingAssemblyDetails,
}: {
    status: Exclude<DriverStatusDTO, OKDriverStatusDTO>;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
}) => {
    const { description, subDescription, button } = useStatusInformationFromDriverStatus(
        status,
        rfqId,
        manufacturingAssemblyDetails,
    );

    return (
        <Flexbox
            style={{
                padding: '8px',
                alignItems: 'center',
                borderRadius: '2px',
                flexDirection: 'row',
                justifyContent: 'space-between',
                ...getColorStyles(status.type),
            }}
        >
            <Flexbox>
                <Typography style={{ marginRight: '4px', color: colorSystem.neutral[8] }}>{description}</Typography>
                {subDescription && <Typography style={{ fontWeight: 600 }}>{subDescription}</Typography>}
            </Flexbox>
            {button}
        </Flexbox>
    );
};

const getColorStyles = (status: Exclude<StatusDTO, 'Ok'>): React.CSSProperties => {
    switch (status) {
        case 'Error':
            return {
                backgroundColor: colorSystem.red[1],
                border: `1px ${colorSystem.red[2]} solid`,
            };
        case 'Warning':
            return {
                backgroundColor: colorSystem.red[1],
                border: `1px ${colorSystem.red[2]} solid`,
            };
        default:
            assertUnreachable(status);
    }
};
