import { assertPresent, isPresent } from '@luminovo/commons';
import { Info } from '@mui/icons-material';
import { TableRow as MuiTableRow, Popover, TableCell, TableCellProps, styled } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import React from 'react';
import { TableComponents } from 'react-virtuoso';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { TanStackTableContext } from './type';
import { getCommonPinningStyles } from './utils';

export const DefaultTableRow: TableComponents<unknown, TanStackTableContext>['TableRow'] = ({
    style,
    context,
    ...props
}) => {
    const { table, TableRow = MuiTableRow } = assertPresent(context);
    const { rows } = table.getRowModel();
    const handelRowClick = table.options.meta?.onRowClick;
    const row = rows[props['data-index']];

    const cells = row
        ?.getVisibleCells()
        .map((cell) => <InnerTableCell key={cell.id} cell={cell} sharedContext={table.options.meta?.sharedContext} />);

    return (
        <TableRow
            {...props}
            onClick={() => handelRowClick?.(row, table)}
            style={{ cursor: isPresent(handelRowClick) ? 'pointer' : 'default' }}
        >
            {cells}
        </TableRow>
    );
};

export function InnerTableCell<TData, TSharedContext>({
    cell,
    sharedContext,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
}): JSX.Element {
    const tableCellProps = {
        variant: 'body' as const,
        style: {
            ...getCommonPinningStyles(cell.column),
            textAlign: cell.column.columnDef.meta?.align,
        },
        onClick: (e: React.MouseEvent<HTMLDivElement>) => {
            if (cell.column.columnDef.meta?.enableOnRowClick === false) {
                e.stopPropagation();
            }
        },
    };

    if (cell.column.columnDef.meta?.disableTableCell) {
        return <ContentRenderer cell={cell} sharedContext={sharedContext} renderType="generic" />;
    }

    if (cell.column.columnDef.meta?.editable) {
        return (
            <EditableTableCell key={cell.id} cell={cell} sharedContext={sharedContext} tableCellProps={tableCellProps}>
                <ContentRenderer cell={cell} sharedContext={sharedContext} />
            </EditableTableCell>
        );
    }

    return (
        <TableCell key={cell.id} {...tableCellProps}>
            <ContentRenderer cell={cell} sharedContext={sharedContext} />
        </TableCell>
    );
}

export function ContentRenderer<TData, TSharedContext>({
    cell,
    sharedContext,
    renderType,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
    renderType?: 'generic';
}): JSX.Element {
    const type = isPresent(renderType)
        ? renderType
        : (cell.column.columnDef.meta?.renderType ?? cell.column.columnDef.meta?.dataType ?? 'generic');
    const context = Object.assign(cell.getContext(), { sharedContext });

    switch (type) {
        case 'text':
        case 'number':
        case 'date':
        case 'monetaryValue':
            return (
                <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                    {flexRender(cell.column.columnDef.cell, context)}
                </Text>
            );
        case 'enum':
        case 'array':
        case 'generic':
            return flexRender(cell.column.columnDef.cell, context) as JSX.Element;
    }
}

export function EditableTableCell<TData, TSharedContext>(
    props: React.PropsWithChildren<{
        cell: Cell<TData, unknown>;
        sharedContext: TSharedContext;
        tableCellProps: TableCellProps;
    }>,
): JSX.Element {
    const { cell, sharedContext, children } = props;
    const { openPopup, popup, anchorEl } = useEditableFieldPopup({ cell, sharedContext });

    return (
        <Tooltip
            variant={'white'}
            title={!isPresent(anchorEl) ? 'Click to edit cell' : ''}
            enterDelay={500}
            enterNextDelay={500}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            <EditTableCell
                variant={props.tableCellProps.variant}
                style={{
                    ...props.tableCellProps.style,
                    ...(isPresent(anchorEl)
                        ? {
                              background: colorSystem.neutral.white,
                              outline: `1px solid ${colorSystem.primary[5]}`,
                              borderRadius: '2px',
                              borderWidth: 0,
                              zIndex: 10,
                          }
                        : {}),
                }}
                onClick={(e) => {
                    const isPopupOpen = Boolean(popup);
                    if (!isPopupOpen) {
                        openPopup(e);
                    }
                }}
            >
                <ErrorIndicator cell={cell} sharedContext={sharedContext}>
                    {children}
                </ErrorIndicator>
                {popup}
            </EditTableCell>
        </Tooltip>
    );
}

const EditTableCell = styled(TableCell)({
    cursor: 'pointer',
    outlineOffset: -1,
    '&:hover': {
        background: colorSystem.neutral.white,
        outline: `1px solid ${colorSystem.primary[5]}`,
        borderRadius: '2px',
        borderWidth: 0,
        zIndex: 10,
    },
});

export function ErrorIndicator<TData, TSharedContext>({
    cell,
    sharedContext,
    children,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
    children?: React.ReactNode;
}): JSX.Element {
    const errorMessage = cell.column.columnDef.meta?.editable?.error?.({ ...cell.getContext(), sharedContext });
    const align = cell.column.columnDef.meta?.align;

    if (!isPresent(errorMessage)) {
        return <>{children}</>;
    }

    return (
        <Flexbox gap={4} alignItems={'center'} justifyContent={align === 'right' ? 'space-between' : undefined}>
            <Tooltip
                placement="left"
                variant="white"
                arrow
                title={
                    <Flexbox gap={4} alignItems={'center'}>
                        <Info style={{ color: colorSystem.red[6] }} fontSize="inherit" />
                        <Text variant="body-small">{errorMessage}</Text>
                    </Flexbox>
                }
            >
                <Info style={{ color: colorSystem.red[6], zIndex: 15 }} fontSize="inherit" />
            </Tooltip>
            {children}
        </Flexbox>
    );
}

export function useEditableFieldPopup<TData, TSharedContext>({
    cell,
    sharedContext,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
}): {
    anchorEl: HTMLElement | null;
    openPopup: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closePopup: () => void;
    popup: JSX.Element | null;
} {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const openPopup = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopup = () => {
        setAnchorEl(null);
    };

    const popup = Boolean(anchorEl) ? (
        <Popover
            open={true}
            anchorEl={anchorEl}
            onClose={closePopup}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            elevation={2}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: '8px',
                    },
                },
            }}
        >
            <Flexbox sx={{ position: 'relative', flexDirection: 'column', borderRadius: 8, padding: '4px 4px' }}>
                {cell.column.columnDef.meta?.editable?.editField({ ...cell.getContext(), sharedContext })}
            </Flexbox>
        </Popover>
    ) : null;

    return {
        anchorEl,
        openPopup,
        closePopup,
        popup,
    };
}
