import { Tag } from '@luminovo/design-system';
import { UnitOfMeasurementDTO } from '@luminovo/http-client';
import { formatUnitOfMeasurement } from '../../formatters/formatUnitOfMeasurement';

export const UnitOfMeasurementTag = ({
    unitOfMeasurement,
}: {
    unitOfMeasurement: UnitOfMeasurementDTO;
}): JSX.Element => {
    return (
        <Tag
            attention={'low'}
            color={unitOfMeasurement.quantity_unit.quantity === 1 ? 'neutral' : 'yellow'}
            label={formatUnitOfMeasurement(unitOfMeasurement)}
        />
    );
};
