import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row, Text } from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import {
    convertUserDriverTypeToUserAndSystemDriverType,
    DriverList,
    DriverListEntry,
} from '@luminovo/manufacturing-core';
import { DriverColumnContext } from '../../shared/columns/driverColumnContext';
import { LevelCell } from './LevelCell';
import { ActivityFormulasContext, TimeCalculationLevelSummary, TimeCalculationSummaryContext } from './utils';

function getDriverFilters<C extends DriverColumnContext & ActivityFormulasContext & TimeCalculationSummaryContext>(
    context: C,
): Filter<ActivityDTO, C>[] {
    const drivers = Object.entries(context.driverIdsToNameAndTypeMap).map(([driverId, { name: driverName }]) => ({
        driverId,
        driverName,
    }));
    drivers.sort((a, b) => a.driverName.localeCompare(b.driverName));

    return drivers.map((driver) => ({
        id: `driver=${driver.driverId}`,
        label: driver.driverName,
        predicate: (activity: ActivityDTO, context: C) => {
            const summary = context.timeCalculationSummaryMap[activity.id];
            if (!summary) {
                return false;
            }

            const activityDriverIds = [
                ...(summary['Unit']?.driverIdValues ?? []),
                ...(summary['Batch']?.driverIdValues ?? []),
                ...(summary['Project']?.driverIdValues ?? []),
            ];
            const hasLinearDriver = activityDriverIds.includes(driver.driverId);

            if (hasLinearDriver) {
                return true;
            }

            const formulas = context.activityFormulasMap[activity.id];
            if (!formulas) {
                return false;
            }

            const formulaDrivers = formulas
                .flatMap((formula) => context.formulaDriversMap[formula]?.driverNames)
                .map((driverName) => context.driverNamesToIdAndTypeMap[driverName]?.id)
                .filter(isPresent);

            return formulaDrivers.includes(driver.driverId);
        },
    }));
}

const getDriversForLevel = (
    summary: TimeCalculationLevelSummary | undefined,
    context: DriverColumnContext & ActivityFormulasContext & TimeCalculationSummaryContext,
): DriverListEntry[] => {
    if (!summary) {
        return [];
    }

    const driverIds = summary.driverIdValues ?? [];
    const formulas = summary.formulas ?? [];
    const formulaDriverNames = formulas.flatMap((formula) => context.formulaDriversMap[formula]?.driverNames ?? []);

    const formulaDrivers = formulaDriverNames.map((driverName) => {
        const driverInfo = context.driverNamesToIdAndTypeMap[driverName];

        if (!driverInfo) {
            return undefined;
        }

        const { id, type } = driverInfo;

        return { key: id, name: driverName, type: convertUserDriverTypeToUserAndSystemDriverType(type) };
    });

    const linearDrivers = driverIds.map((driverId): DriverListEntry | undefined => {
        const driverInfo = context.driverIdsToNameAndTypeMap[driverId];

        if (!driverInfo) {
            return undefined;
        }

        const { name, type } = driverInfo;

        return { key: driverId, name, type: convertUserDriverTypeToUserAndSystemDriverType(type) };
    });

    return [...linearDrivers, ...formulaDrivers].filter(isPresent);
};

const DriversCell = ({
    activity,
    context,
}: {
    activity: ActivityDTO;
    context: DriverColumnContext & ActivityFormulasContext & TimeCalculationSummaryContext;
}): JSX.Element => {
    const summary = context.timeCalculationSummaryMap[activity.id];

    if (!summary) {
        return (
            <Text>
                <Trans>Loading</Trans>...
            </Text>
        );
    }

    const unitDrivers = getDriversForLevel(summary['Unit'], context);
    const batchDrivers = getDriversForLevel(summary['Batch'], context);
    const projectDrivers = getDriversForLevel(summary['Project'], context);

    let unitDriverList = undefined;
    let batchDriverList = undefined;
    let projectDriverList = undefined;

    if (summary['Unit']) unitDriverList = <DriverList drivers={unitDrivers} />;
    if (summary['Batch']) batchDriverList = <DriverList drivers={batchDrivers} />;
    if (summary['Project']) projectDriverList = <DriverList drivers={projectDrivers} />;

    return <LevelCell unit={unitDriverList} batch={batchDriverList} project={projectDriverList} />;
};

export function getDriverColumn<
    C extends DriverColumnContext & ActivityFormulasContext & TimeCalculationSummaryContext,
>(context: C): Column<ActivityDTO, C> {
    return {
        label: <Trans>Drivers</Trans>,
        id: `driver`,
        render: ({ data: activity }: Row<ActivityDTO>, context) => {
            return <DriversCell activity={activity} context={context} />;
        },
        width: columnWidth.large,
        filters: getDriverFilters(context),
    };
}
