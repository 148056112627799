import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { BannerCard } from './BannerCard';

export const BannerCardNoOrderOrQuotation = (): JSX.Element => {
    return (
        <BannerCard
            background={colorSystem.neutral[5]}
            title={t`This RfQ has been closed without an order.`}
            description={''}
        />
    );
};
