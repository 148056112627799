import { isPresent } from '@luminovo/commons';
import { ParameterOption, ParametricSearchState } from '../types';

export function createSearchFilter<T, TAutocompleteState>({
    config,
    selectedBlocks,
}: ParametricSearchState<T, TAutocompleteState>): (item: T) => boolean {
    return (item: T) => {
        const isSelected = config.parameters
            .flatMap((param: ParameterOption<T, TAutocompleteState>) =>
                param.ops.map((op) => {
                    return { param, op };
                }),
            )
            .every(({ param, op }) => {
                const { filter } = op;
                if (!isPresent(filter)) {
                    return true;
                }

                const matches = selectedBlocks.filter((f) => {
                    return f.op === op.op && f.field === param.field;
                });

                return matches.every((f) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    return filter(item, f.parameter as never);
                });
            });

        return isSelected;
    };
}
