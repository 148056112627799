import { assertUnreachable } from '@luminovo/commons';
import {
    CustomComponentFull,
    CustomFullPart,
    GenericFullPart,
    OtsComponentFull,
    OtsFullPart,
    PartDTO,
    PartIdTypes,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';

export function extractPartDTO(
    part: GenericFullPart | OtsComponentFull | OtsFullPart | CustomFullPart | CustomComponentFull,
): PartDTO {
    if (isGenericFullPart(part)) {
        return {
            type: PartIdTypes.Generic,
            data: part.id,
        };
    }
    if (isOtsComponentFull(part)) {
        return {
            type: PartIdTypes.Ipn,
            data: part.id,
        };
    }

    if (isOtsFullPart(part)) {
        return {
            type: PartIdTypes.OffTheShelf,
            data: part.id,
        };
    }

    if (isCustomFullPart(part)) {
        return {
            type: PartIdTypes.Custom,
            data: part.id,
        };
    }

    if (isCustomComponentFull(part)) {
        return {
            type: PartIdTypes.CustomComponent,
            data: part.id,
        };
    }

    assertUnreachable(part);
}
