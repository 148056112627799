import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../../components/PageLayout';
import { EditSourcingScenarioForm } from '../../../../../../../modules/SourcingScenarioForm';
import { UrlParams } from '../../../../../../../utils/routes';

const EditSourcingScenarioPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit'>,
): JSX.Element => {
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox flexDirection={'column'} gap={32}>
                    <Text variant="h1">
                        <Trans>Edit sourcing scenario</Trans>
                    </Text>
                    <EditSourcingScenarioForm {...urlParams} />
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default EditSourcingScenarioPage;
