import { t, Trans } from '@lingui/macro';
import { colorSystem, Text } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';

export const UnknownValue: React.FunctionComponent = () => (
    <Tooltip title={t`Unknown`}>
        <Text variant="body" color={colorSystem.neutral[6]}>
            <Trans>-</Trans>
        </Text>
    </Tooltip>
);
