/* eslint-disable no-use-before-define */
import { t } from '@lingui/macro';
import { FieldSelectCreatableControlled } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { CustomerLabel } from '@luminovo/manufacturing-core';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useFormContext } from 'react-hook-form';
import { useCustomers } from '../../../../resources/customer/customerHandler';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { useAddCustomerDialog } from '../../../Customers/CustomerUserManagement/ButtonAddCustomer';
import { RfqCreationFormState } from '../../RfqCreationContext';

const filter = createFilterOptions<CustomerDTO>({
    trim: true,
    stringify: (option) => {
        // Append the customer_number to the searchable string so we
        // can search by customer_number as well
        return `${option.name} ${option.customer_number}`;
    },
});

export const CustomerInput = (): JSX.Element => {
    const { control, setValue } = useFormContext<RfqCreationFormState>();

    const { openDialog } = useAddCustomerDialog({
        onSuccess: (newCustomer: CustomerDTO) => {
            setValue('customer', newCustomer, { shouldValidate: true });
        },
    });

    const { hasCustomers } = useHasCustomers();

    const { data: customers = [], isLoading } = useCustomers();

    return (
        <FieldSelectCreatableControlled
            name="customer"
            control={control}
            required
            FieldProps={{
                action: {
                    label: hasCustomers ? t`Add new customer` : t`Add new company`,
                    onClick: (customerName: string) => openDialog(customerName),
                },
                options: customers,
                renderOption: (option) => (
                    <CustomerLabel customerName={option.name} customerNumber={option.customer_number ?? undefined} />
                ),
                getOptionKey: (option) => option.id,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (option, value) => option.id === value.id,
                placeholder: hasCustomers ? t`Search for or add a new customer` : t`Search for or add a new company`,
                filterOptions: filter,
                disabled: isLoading,
            }}
        />
    );
};
