import { FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldRadio, FieldRadioProps } from './FieldRadio';

interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
> extends Omit<FieldControllerProps<FieldRadioProps<TValue>, TFieldValues, TName>, 'Field'> {}

export function FieldRadioControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
>({ validate, ...props }: ControllerProps<TFieldValues, TName, TValue>): JSX.Element {
    return (
        <span>
            <FieldController Field={FieldRadio} validate={createValidator({ validate })} {...props} />
        </span>
    );
}

function createValidator({ validate }: { validate?: (x: unknown) => string | undefined }) {
    return (x: unknown): string | undefined => {
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
