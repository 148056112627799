import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, ApprovalStatus, HistoryOperation, HistoryRecordOtsDTO } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    ApprovalStatusIcon,
    AutomaticApprovalStatusPhrase,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineStatusChip,
    TimelineText,
} from './Utils';

type Props = {
    actor: ActorResponseRuntypeDTO;
    data: HistoryRecordOtsDTO;
    operation: HistoryOperation;
};

function useOTSPartAction(actor: ActorResponseRuntypeDTO, operation: HistoryOperation, approvalStatus: ApprovalStatus) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualRemove:
            return (
                <>
                    <Trans>removed</Trans> <ActionPerformedBy actor={actor} />
                </>
            );
        case HistoryOperation.ManualAdd:
            return (
                <>
                    <Trans>added</Trans> <ActionPerformedBy actor={actor} />
                </>
            );
        case HistoryOperation.AutoAdd:
            return <AutomaticApprovalStatusPhrase approvalStatus={approvalStatus} />;

        case HistoryOperation.ManualUpdateApproval:
            return (
                <>
                    <TimelineStatusChip approvalStatus={approvalStatus} /> <ActionPerformedBy actor={actor} />
                </>
            );
        case HistoryOperation.AutoAddViaQuoteImport:
            return (
                <>
                    <Trans>added based on supplier's suggestion</Trans>
                </>
            );
        default:
            return null;
    }
}

export default function OffTheShelfPart({ actor, data, operation }: Props) {
    const action = useOTSPartAction(actor, operation, data.approval);
    if (!action) return null;
    return <OffTheShelfPartContent data={data}>{action}</OffTheShelfPartContent>;
}

function OffTheShelfPartContent({
    data,
    children,
}: React.PropsWithChildren<{
    data: HistoryRecordOtsDTO;
}>) {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <strong>
                    {data.mpn}, {data.manufacturer}
                </strong>{' '}
                {children}
            </TimelineText>
        </TimelineContentContainer>
    );
}

export function OffTheShelfPartTimelineIcon({
    operation,
    approvalStatus,
}: {
    operation: HistoryOperation;
    approvalStatus: ApprovalStatus;
}) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    return <ApprovalStatusIcon approvalStatus={approvalStatus} />;
}
