import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { BACKEND_BASE, PCBV2, getNormalizedFileName } from '@luminovo/http-client';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import { useHttpMutation } from '../mutation/useHttpMutation';

export function useExportPcbSpecificationInPDF(pcb: PCBV2, options?: Omit<UseMutationOptions, 'mutationFn'>) {
    const projectName = pcb.name;
    const queryParams = projectName ? `?projectName=${encodeURIComponent(projectName)}` : '';
    const url = `${BACKEND_BASE}/pcb/${pcb.id}/download-pdf${queryParams}`;
    const { enqueueSnackbar } = useSnackbar();

    return useDownloadFile(url, {
        ...options,
        onError: options?.onError
            ? options.onError
            : () => {
                  enqueueSnackbar(t`The PDF creation caused an error. Please try again later.`, {
                      variant: 'error',
                  });
              },
    });
}

export function useExportPanelSpecificationInPDF({
    pcbId,
    panelId,
    fileName,
}: {
    pcbId: string;
    panelId: string;
    fileName?: string;
}) {
    const queryParams = `?panelId=${panelId}` + (fileName ? `&projectName=${fileName}` : '');
    const url = `${BACKEND_BASE}/pcb/${pcbId}/panel/download-pdf${queryParams}`;

    const { enqueueSnackbar } = useSnackbar();

    return useDownloadFile(url, {
        onError: () => {
            enqueueSnackbar(t`The PDF creation caused an error. Please try again later.`, {
                variant: 'error',
            });
        },
    });
}

export function useExportPanelSpecificationForSourcingScenarioInPDF({
    pcb,
    options,
    sourcingScenarioId,
    offerSize,
}: {
    pcb: PCBV2;
    options?: Omit<UseMutationOptions, 'mutationFn'>;
    sourcingScenarioId: string;
    offerSize: number;
}) {
    const projectName = pcb.name;
    const queryParams = projectName
        ? `?projectName=${encodeURIComponent(
              projectName,
          )}&sourcing_scenario_id=${sourcingScenarioId}&offerSize=${offerSize}`
        : '';
    const url = `${BACKEND_BASE}/panel/${pcb.id}/download-pdf${queryParams}`;
    const { enqueueSnackbar } = useSnackbar();

    return useDownloadFile(url, {
        ...options,
        onError: options?.onError
            ? options.onError
            : () => {
                  enqueueSnackbar(t`The PDF creation caused an error. Please try again later.`, {
                      variant: 'error',
                  });
              },
    });
}

export function useDownloadPCBFiles({
    pcbId,
    options,
    filePath = 'all',
}: {
    pcbId: string;
    options?: Omit<UseMutationOptions, 'mutationFn'>;
    filePath?: string;
}) {
    const url = `${BACKEND_BASE}/ems/pcb/v2/pcbs/${pcbId}/files/${filePath}`;
    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync, isLoading, isError } = useDownloadFile(url, {
        ...options,
        onError: options?.onError
            ? options.onError
            : () => {
                  enqueueSnackbar(t`Downloading PCB files caused an error. Please try again later.`, {
                      variant: 'error',
                  });
              },
    });

    return {
        downloadPCBFiles: mutateAsync,
        isLoading,
        isError,
    };
}

export function useDownloadPcbOrderFiles(offerId: string, sourcingScenarioId: string) {
    const url = `${BACKEND_BASE}/offers/custom-part/${offerId}/download-order-files-url?sourcing_scenario_id=${sourcingScenarioId}`;
    const { enqueueSnackbar } = useSnackbar();
    const token = getToken();

    return useMutation({
        mutationFn: () => {
            return getUrlAndDownloadFile(url, { token });
        },
        onError: () => {
            enqueueSnackbar(t`Downloading PCB files caused an error. Please try again later.`, {
                variant: 'error',
            });
        },
    });
}

export function useDownloadPurchaseOrderFiles(purchaseOrderId: string) {
    const url = `${BACKEND_BASE}/purchase-orders/excel/${encodeURIComponent(purchaseOrderId)}`;
    const { enqueueSnackbar } = useSnackbar();

    return useDownloadFile(url, {
        onError: () => {
            enqueueSnackbar(t`Error downloading purchase order attachments. Please try again later.`, {
                variant: 'error',
            });
        },
    });
}

export function useDownloadQuoteRequestExcel(quoteRequestId: number) {
    const url = `${BACKEND_BASE}/quote-request/${quoteRequestId}/download-excel`;
    const { enqueueSnackbar } = useSnackbar();

    return useDownloadFile(url, {
        onError: () => {
            enqueueSnackbar(`Error downloading quote request excel. Please try again later.`, {
                variant: 'error',
            });
        },
    });
}

const useDownloadFile = (url: string, options?: Omit<UseMutationOptions, 'mutationFn'>) => {
    const token = getToken();

    const { mutateAsync, isLoading, isError } = useMutation({
        mutationFn: () => {
            return downloadFile(url, { token });
        },
        ...options,
    });

    return {
        mutateAsync,
        isLoading,
        isError,
    };
};

const getUrlAndDownloadFile = async (url: string, config: { token: string }) => {
    const response = await fetch(url, {
        body: undefined,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${config.token}`,
        },
    });

    if (!response.ok) {
        throw new Error('File download failed');
    }
    const downloadUrl = (await response.json()).url;
    return downloadFile(`${BACKEND_BASE}${downloadUrl}`, config);
};

const downloadFile = async (url: string, config: { token: string }) => {
    const response = await fetch(url, {
        body: undefined,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${config.token}`,
        },
    });

    if (!response.ok) {
        throw new Error('File download failed');
    }

    const normalizedName = getNormalizedFileName(response);
    const fileBlob = await response.blob();
    fileDownload(fileBlob, normalizedName);
};

export function useDownloadQuotePriceTemplate() {
    return useHttpMutation('POST /export/quote-price-xlsx', {
        snackbarMessage: null,
        onSuccess: (response) => {
            fileDownload(response.blob, response.fileName);
        },
    });
}

export function useDownloadSupplierQuotePriceTemplate() {
    return useHttpMutation('POST /export/quote-price-xlsx-bulk', {
        snackbarMessage: null,
        onSuccess: (response) => {
            fileDownload(response.blob, response.fileName);
        },
    });
}
