import { Trans, t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    FieldDateControlled,
    FieldNumericControlled,
    Flexbox,
    FormItem,
    Message,
    RightBoxDrawer,
    SecondaryButton,
    Switch,
    Tag,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import { Box, Divider, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CloseDrawerButton, useDrawerContext } from '../../../../components/contexts/ModalContext';
import { validateSeriesDemandStartEndYears } from '../../../RfqDashboard/validateSeriesDemandStartEndYears';
import {
    RfqCreationFormState,
    createDefaultPrototypeDemandScenario,
    createDefaultSeriesDemandScenario,
    defaultDeliveryBatchSizes,
    useRfqCreationContext,
} from '../../RfqCreationContext';
import { DeliveryBatchSizeInfoGraphic } from './DeliveryBatchSizeInfoGraphic';
import { NavigationButtons } from './NavigationButtons';

const PrototypeDemandScenarioInput = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();
    const { fields } = useFieldArray({
        control,
        name: `demand_scenarios.${index}.demands`,
    });
    const assemblies = useWatch({ control, name: 'assembly.assemblies' });

    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
                display="flex"
                flexDirection="column"
                gap={3}
            >
                <FormItem label={t`Quantity`} required>
                    {fields.map((_demand, demandIndex) => (
                        <Flexbox key={demandIndex} alignItems="center" gap={8}>
                            <FieldNumericControlled
                                control={control}
                                name={`demand_scenarios.${index}.demands.${demandIndex}.quantity`}
                                min={0}
                                isInteger={true}
                                max={100_000_000}
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    style: {
                                        width: 120,
                                    },
                                }}
                            />
                            <Text variant="body-semibold" color={colorSystem.neutral[6]}>
                                {assemblies[demandIndex].name}
                            </Text>
                        </Flexbox>
                    ))}
                </FormItem>
                <FormItem label={t`Delivery date (ASAP if empty)`}>
                    <FieldDateControlled
                        control={control}
                        name={`demand_scenarios.${index}.target_date`}
                        inFuture={true}
                        FieldProps={{
                            style: {
                                width: 200,
                            },
                        }}
                    />
                </FormItem>
            </Box>
        </>
    );
};

function getYearsInBetween(startYear: number, endYear: number): number[] {
    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
}

const useSyncAnnualDemandsWithStartAndEndYears = ({
    demandScenarioIndex,
    demandIndex,
}: {
    demandScenarioIndex: number;
    demandIndex: number;
}) => {
    const { control, setValue } = useFormContext<RfqCreationFormState>();
    const startYear = useWatch({ control, name: `demand_scenarios.${demandScenarioIndex}.start_year` });
    const endYear = useWatch({ control, name: `demand_scenarios.${demandScenarioIndex}.end_year` });
    const annualDemands = useWatch({
        control,
        name: `demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands`,
    });

    useEffect(() => {
        setTimeout(() => {
            const error = validateSeriesDemandStartEndYears(startYear, endYear);

            if (error !== undefined) {
                return;
            }

            const yearsInBetweenStartAndEnd = getYearsInBetween(Number(startYear), Number(endYear));
            const newAnnualDemands = yearsInBetweenStartAndEnd.map((year) => {
                const oldAnnualDemand = annualDemands.find((annualDemand) => annualDemand.year === year);
                return {
                    year,
                    quantity: oldAnnualDemand?.quantity ?? 0,
                    // eslint-disable-next-line camelcase
                    delivery_batch_sizes: oldAnnualDemand?.delivery_batch_sizes ?? defaultDeliveryBatchSizes,
                };
            });
            setValue(`demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands`, newAnnualDemands);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startYear, endYear]);
};

const DeliveryBatchSizesMoreInfo = (): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap={12} padding={2}>
            <Text variant="h2">
                <Trans>What are delivery batch sizes?</Trans>
            </Text>
            <Text>
                <Trans>
                    To better document your customer's demand you can specify the wished size of delivery batches per
                    year. Please note that this step is for documentation purposes only and completely optional.
                </Trans>
            </Text>
            <Text>
                <Trans>You can add up to three different delivery batch sizes per year.</Trans>
            </Text>
            <DeliveryBatchSizeInfoGraphic />
        </Flexbox>
    );
};

const useDeliveryBatchSizesDrawer = () => {
    const { closeDrawer, setDrawer } = useDrawerContext();

    const drawer = (
        <RightBoxDrawer onClose={closeDrawer}>
            <Flexbox flexDirection="column" width="600px" minWidth="600px">
                <CloseDrawerButton />
                <DeliveryBatchSizesMoreInfo />
            </Flexbox>
        </RightBoxDrawer>
    );

    return () => setDrawer(drawer);
};

const DeliveryBatchSizeInputRow = ({
    demandScenarioIndex,
    demandIndex,
    annualDemandIndex,
}: {
    demandScenarioIndex: number;
    demandIndex: number;
    annualDemandIndex: number;
}): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();
    const fieldName =
        `demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands.${annualDemandIndex}.delivery_batch_sizes` as const;
    const { fields } = useFieldArray({
        control,
        name: fieldName,
    });

    return (
        <Flexbox gap={8}>
            {fields.map((item, index) => (
                <FieldNumericControlled
                    key={item.id}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            style: {
                                width: 90,
                            },
                        },
                    }}
                    name={`${fieldName}.${index}.quantity`}
                    isInteger
                    min={1}
                    max={100_000_000}
                    displayErrorAsTooltip={true}
                />
            ))}
        </Flexbox>
    );
};

const DeliveryBatchSizeInputs = ({
    demandScenarioIndex,
    demandIndex,
}: {
    demandScenarioIndex: number;
    demandIndex: number;
}): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();
    const annualDemandsFieldName =
        `demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands` as const;
    const { fields } = useFieldArray({
        control,
        name: annualDemandsFieldName,
    });

    const openDrawer = useDeliveryBatchSizesDrawer();

    return (
        <FormItem
            label={
                <Flexbox gap={16} justifyContent="space-between">
                    <Text variant="h4" color={colorSystem.neutral[6]}>
                        <Trans>Delivery batch size</Trans>
                    </Text>
                    <Text
                        variant="h4"
                        color={colorSystem.blue[5]}
                        onClick={() => {
                            openDrawer();
                        }}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <Trans>More info</Trans>
                    </Text>
                </Flexbox>
            }
            LabelProps={{
                width: '100%',
            }}
        >
            {fields.map((item, index) => (
                <DeliveryBatchSizeInputRow
                    key={item.id}
                    demandScenarioIndex={demandScenarioIndex}
                    demandIndex={demandIndex}
                    annualDemandIndex={index}
                />
            ))}
        </FormItem>
    );
};

const AnnualDemandInputs = ({
    demandScenarioIndex,
    demandIndex,
    addDeliveryBatchSizes,
}: {
    demandScenarioIndex: number;
    demandIndex: number;
    addDeliveryBatchSizes: boolean;
}): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();
    const annualDemandsFieldName =
        `demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands` as const;
    const { fields } = useFieldArray({
        control,
        name: annualDemandsFieldName,
    });
    const annualDemands = useWatch({ control, name: annualDemandsFieldName });
    useSyncAnnualDemandsWithStartAndEndYears({ demandScenarioIndex, demandIndex });

    return (
        <>
            <Flexbox gap={24}>
                <FormItem
                    label={t`Annual demand`}
                    required
                    LabelProps={{
                        style: {
                            color: colorSystem.neutral[6],
                        },
                    }}
                >
                    {fields.map((item, index) => (
                        <FieldNumericControlled
                            key={item.id}
                            control={control}
                            FieldProps={{
                                placeholder: t`Quantity`,
                                InputProps: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Tag color="neutral" label={`${item.year}`} attention="low" />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        width: 182,
                                    },
                                },
                            }}
                            name={`${annualDemandsFieldName}.${index}.quantity`}
                            isInteger
                            min={0}
                            max={100_000_000}
                        />
                    ))}
                </FormItem>
                {addDeliveryBatchSizes && (
                    <DeliveryBatchSizeInputs demandScenarioIndex={demandScenarioIndex} demandIndex={demandIndex} />
                )}
            </Flexbox>
            <Divider />
            <Flexbox gap={4}>
                <Text color={colorSystem.neutral[6]}>
                    <Trans>Total volume</Trans>:
                </Text>
                <Text>{annualDemands.reduce((acc, item) => acc + (item.quantity ?? 0), 0)}</Text>
            </Flexbox>
        </>
    );
};

const useResetDeliveryBatchSizes = () => {
    const { control, setValue } = useFormContext<RfqCreationFormState>();
    const demandScenarios = useWatch({ control, name: 'demand_scenarios' });

    return () => {
        demandScenarios.forEach((demandScenario, demandScenarioIndex) => {
            if (demandScenario.type === 'Prototype') {
                return;
            }

            demandScenario.demands.forEach((demand, demandIndex) => {
                demand.annual_demands.forEach((_annualDemand, annualDemandIndex) => {
                    setValue(
                        `demand_scenarios.${demandScenarioIndex}.demands.${demandIndex}.annual_demands.${annualDemandIndex}.delivery_batch_sizes`,
                        defaultDeliveryBatchSizes,
                    );
                });
            });
        });
    };
};

const useAddDeliveryBatchSizesState = (index: number) => {
    const { control } = useFormContext<RfqCreationFormState>();
    const demands = useWatch({ control, name: `demand_scenarios.${index}.demands` });

    const initValue = demands.some((demand) => {
        return (
            'annual_demands' in demand &&
            demand.annual_demands.some((annualDemand) => {
                return annualDemand.delivery_batch_sizes.some(
                    (deliveryBatchSize) => deliveryBatchSize.quantity !== undefined,
                );
            })
        );
    });

    return useState(initValue);
};

const SeriesDemandInputs = ({ demandScenarioIndex }: { demandScenarioIndex: number }): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();

    const [addDeliveryBatchSizes, setAddDeliveryBatchSizes] = useAddDeliveryBatchSizesState(demandScenarioIndex);

    const demandsFieldName = `demand_scenarios.${demandScenarioIndex}.demands` as const;
    const { fields } = useFieldArray({
        control,
        name: demandsFieldName,
    });
    const resetDeliveryBatchSizes = useResetDeliveryBatchSizes();
    const assemblies = useWatch({ control, name: 'assembly.assemblies' });

    return (
        <Flexbox flexDirection="column" gap={24}>
            {fields.map((demand, demandIndex) => (
                <FormItem
                    key={demand.id}
                    label={
                        <Flexbox alignItems="center" justifyContent="space-between" gap="12px">
                            <Text variant="h4">{assemblies[demandIndex].name}</Text>
                            {demandIndex === 0 && (
                                <Flexbox alignItems="center" gap={4}>
                                    <Switch
                                        checked={addDeliveryBatchSizes}
                                        onChange={() =>
                                            setAddDeliveryBatchSizes((oldValue) => {
                                                if (oldValue) {
                                                    resetDeliveryBatchSizes();
                                                }
                                                return !oldValue;
                                            })
                                        }
                                    />
                                    <Text variant="body-semibold" color={colorSystem.neutral[7]}>
                                        <Trans>Add delivery batch sizes</Trans>
                                    </Text>
                                </Flexbox>
                            )}
                        </Flexbox>
                    }
                    LabelProps={{
                        width: '100%',
                    }}
                >
                    <AnnualDemandInputs
                        demandIndex={demandIndex}
                        demandScenarioIndex={demandScenarioIndex}
                        addDeliveryBatchSizes={addDeliveryBatchSizes}
                    />
                </FormItem>
            ))}
        </Flexbox>
    );
};

const useStartYearValidation = ({ demandScenariosIndex }: { demandScenariosIndex: number }) => {
    const endYear = useWatch({
        control: useFormContext<RfqCreationFormState>().control,
        name: `demand_scenarios.${demandScenariosIndex}.end_year`,
    });
    return (startYear: unknown) => validateSeriesDemandStartEndYears(startYear, endYear);
};

const SeriesDemandScenarioInput = ({ index }: { index: number }): JSX.Element => {
    const { control, trigger } = useFormContext<RfqCreationFormState>();
    const startYearValidation = useStartYearValidation({ demandScenariosIndex: index });
    const endYear = useWatch({
        control: control,
        name: `demand_scenarios.${index}.end_year`,
    });

    useEffect(() => {
        trigger(`demand_scenarios.${index}.start_year`);
    }, [endYear, index, trigger]);

    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
            >
                <FormItem label={t`Time frame`} required>
                    <Flexbox gap={12} alignItems="center">
                        <FieldNumericControlled
                            control={control}
                            name={`demand_scenarios.${index}.start_year`}
                            FieldProps={{
                                placeholder: 'YYYY',
                                style: {
                                    width: 100,
                                },
                            }}
                            validate={startYearValidation}
                            displayErrorAsTooltip
                        />
                        {/* this is an en dash, because a normal hyphen is too short */}
                        <Text>–</Text>
                        <FieldNumericControlled
                            control={control}
                            name={`demand_scenarios.${index}.end_year`}
                            FieldProps={{
                                placeholder: 'YYYY',
                                style: {
                                    width: 100,
                                },
                            }}
                            displayErrorAsTooltip
                        />
                    </Flexbox>
                </FormItem>
            </Box>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
            >
                <SeriesDemandInputs demandScenarioIndex={index} />
            </Box>
        </>
    );
};

const DemandScenarioInput = ({ index }: { index: number }): JSX.Element => {
    const { setValue, control } = useFormContext<RfqCreationFormState>();
    const name = `demand_scenarios.${index}` as const;
    const demandScenarioType = useWatch({ control, name: `${name}.type` });
    const assemblies = useWatch({ control, name: 'assembly.assemblies' });
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: colorSystem.neutral[3],
                borderRadius: '8px',
                marginBottom: '16px',
            }}
        >
            <Box
                sx={{
                    padding: '16px',
                    background: colorSystem.neutral[0],
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
            >
                <Flexbox justifyContent={'space-between'}>
                    <Text variant="h3">
                        <Trans>Demand scenario</Trans> {index + 1}
                    </Text>
                    <ButtonGroup size="large">
                        <ButtonGroupItem
                            selected={demandScenarioType === 'Prototype'}
                            onClick={() => {
                                setValue(name, createDefaultPrototypeDemandScenario(assemblies.length));
                            }}
                        >
                            <Trans>Prototype</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            selected={demandScenarioType === 'Series'}
                            onClick={() => {
                                setValue(name, createDefaultSeriesDemandScenario(assemblies.length));
                            }}
                        >
                            <Trans>Series</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                </Flexbox>
            </Box>
            {demandScenarioType === 'Prototype' ? (
                <PrototypeDemandScenarioInput index={index} />
            ) : demandScenarioType === 'Series' ? (
                <SeriesDemandScenarioInput index={index} />
            ) : (
                assertUnreachable(demandScenarioType)
            )}
        </Box>
    );
};

const DemandScenarioInputs = (): JSX.Element => {
    const { control } = useFormContext<RfqCreationFormState>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'demand_scenarios',
    });
    const assemblies = useWatch({ control, name: 'assembly.assemblies' });

    return (
        <>
            {fields.map((item, index) => (
                <DemandScenarioInput key={item.id} index={index} />
            ))}
            <Flexbox gap={12}>
                <SecondaryButton onClick={() => append(createDefaultPrototypeDemandScenario(assemblies.length))}>
                    <Trans>Add demand scenario</Trans>
                </SecondaryButton>
                <SecondaryButton onClick={() => remove(fields.length - 1)}>
                    <Trans>Remove demand scenario</Trans>
                </SecondaryButton>
            </Flexbox>
        </>
    );
};

export const DemandScenarioForm = (): JSX.Element => {
    const { trigger, formState } = useFormContext<RfqCreationFormState>();
    const { setActiveStep } = useRfqCreationContext();
    const [showSeriesMessageBox, setShowSeriesMessageBox] = useState(true);

    const onNext = async () => {
        if (await trigger('demand_scenarios')) {
            setActiveStep('rfq');
        }
    };

    const onPrevious = () => {
        setActiveStep('assembly');
    };

    return (
        <>
            <Text>
                <Trans>
                    Define your demand scenarios. Choose series to have separate annual demands, and prototype to focus
                    on smaller, short-term projects. You can have both series and prototype demand scenarios in an RfQ.
                </Trans>
            </Text>
            {showSeriesMessageBox && (
                <Message
                    size="large"
                    variant="primary"
                    title={t`Document demand for multi-year-projects`}
                    attention="low"
                    onClose={() => {
                        setShowSeriesMessageBox(false);
                    }}
                    message={
                        <Trans>
                            Series demand scenarios allow to better capture demand that spans across multiple years. A
                            single sourcing scenario will be created per year.
                        </Trans>
                    }
                ></Message>
            )}
            <DemandScenarioInputs />

            <NavigationButtons
                showPrevious={true}
                nextLabel={t`Next`}
                onPrevious={onPrevious}
                onNext={onNext}
                isDisabled={Object.keys(formState.errors.demand_scenarios ?? {}).length > 0}
            />
        </>
    );
};
