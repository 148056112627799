import { Attribute } from '../types';
import { LeadTimeUnit } from './LeadTimeUnit';

const dayKeywords = [
    { string: 'Kalendertag', plural: 'e' },
    { string: 'day', plural: 's' },
    { string: 'tag', plural: 'e' },
    { string: 'jour', plural: 's' },
    { string: 'dia', plural: 's' },
    { string: 'día', plural: 's' },
    { string: 'die', plural: 's' },
].map(({ string, plural }) => `${string}(?:\\(${plural}\\)|${plural})?`);
const weekKeywords = [
    { string: 'w', plural: null },
    { string: 'wk', plural: 's' },
    { string: 'week', plural: 's' },
    { string: 'woche', plural: 'n' },
    { string: 'semaine', plural: 's' },
    { string: 'semana', plural: 's' },
    { string: 'setmane', plural: 's' },
    { string: 'setmana', plural: 's' },
    { string: 'Wo', plural: null },
].map(({ string, plural }) => (plural ? `${string}(?:\\(${plural}\\)|${plural})?` : `${string}`));
const monthKeywords = [
    { string: 'month', plural: 's' },
    { string: 'monat', plural: 'e' },
    { string: 'mois', plural: null },
    { string: 'mes', plural: 'es' },
    { string: 'mes', plural: 'os' },
].map(({ string, plural }) => (plural ? `${string}(?:\\(${plural}\\)|${plural})?` : `${string}`));
const monthOrWeekOrDayRegex = new RegExp(
    `(?<![^\\s\\d\\(])(?:\\s|\\()?(?:(${monthKeywords.join('|')})|(${weekKeywords.join('|')})|(${dayKeywords.join(
        '|',
    )}))`,
    'i',
);

const leadtimeStrings = [
    'L\\/T',
    'LT',
    'Lead time',
    'Lead-?time',
    'Delivery',
    'Delivery time',
    'std LT',
    'Std LeadTime',
    'Standard MFG Lead Time',
    // German
    'Lieferzeit',
    'Standardlieferzeit',
    'Lieferfrist',
    'LZ',
    'LZ/Datum',
    'Termin',
    'Liefertermin',
    'ETA',
    'WBZ',
    'Wiederbeschaffungszeit',
    'Regellieferzeit',
    // French
    'Délai',
    'Délai standard',
    'Délai de livraison standard',
    'Délai de livraison',
    // Spanish
    'Plazo estandar',
    'Plazo estimado',
    'Plazo',
    // Catalan
    'Termini estàndard',
    'Termini',
];
export const leadtimeRegex = new RegExp(`(?:\\s|^)(?:${leadtimeStrings.join('|')})(?:\\s|:|\\*|$)`, 'i');

const stdFactoryLeadTimeRegex = new RegExp(
    `${leadtimeRegex.source}(?:(?:.*?(?:\\d+\\s?(?:-|~)\\s?)?(\\d+))?|.*)${monthOrWeekOrDayRegex.source}(?:.*?:?\\s(?:\\d+\\s?(?:-|~)\\s?)?(\\d+))?(?:\\s|$|\\))`,
    'i',
);

export function parseStandardFactoryLeadTime(str: string): undefined | Attribute {
    const matches = stdFactoryLeadTimeRegex.exec(str);
    if (matches) {
        const [, leadTimeNumberStart, months, weeks, days, leadTimeNumberEnd] = matches;
        const leadTimeNumber = parseInt(leadTimeNumberStart || leadTimeNumberEnd);
        // match LeadTimeUnit based on which of months, weeks or days is not undefined
        const unit = months ? LeadTimeUnit.Months : weeks ? LeadTimeUnit.Weeks : days ? LeadTimeUnit.Days : undefined;

        if (leadTimeNumber && unit) {
            return { attr: 'standardFactoryLeadTime', value: { value: leadTimeNumber, unit } };
        }
    }
    return undefined;
}
