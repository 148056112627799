import { t } from '@lingui/macro';
import { TertiaryIconButton } from '@luminovo/design-system';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import React from 'react';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { EditNotesDialog } from './EditNotesDialog';
import { NotesFormState } from './editNotesFormFunctions';

export const EditNotes = ({
    manufacturingAssemblyDetailsId,
    notes,
}: {
    manufacturingAssemblyDetailsId: string;
    notes: string | undefined;
}): JSX.Element => {
    const [isDialogOpen, setShowDialog] = React.useState(false);
    function handleClose() {
        setShowDialog(false);
    }

    // const { mutateAsync } = useMutationUpdateAssemblyDetails(manufacturingAssemblyDetailsId);

    const { mutateAsync } = useHttpMutation('PATCH /manufacturing-assembly-details/:manufacturingAssemblyDetailsId', {
        snackbarMessage: t`Notes updated`,
    });

    const onSubmit = async (form: NotesFormState) => {
        await mutateAsync({
            requestBody: {
                notes: form.notes ?? null,
            },
            pathParams: { manufacturingAssemblyDetailsId },
        });
    };

    return (
        <>
            <TertiaryIconButton onClick={() => setShowDialog(true)}>
                {notes ? <EditIcon fontSize="inherit" /> : <AddIcon fontSize="inherit" />}
            </TertiaryIconButton>
            <EditNotesDialog
                notes={notes}
                open={isDialogOpen}
                onClose={handleClose}
                onSubmit={async (form) => {
                    await onSubmit(form);
                }}
            />
        </>
    );
};
