import * as rt from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export type InvalidSpecificationError = rt.Static<typeof InvalidSpecificationErrorRuntype>;
const InvalidSpecificationErrorRuntype = rt.Array(
    rt.Record({
        name: rt.String,
        label: rt.String,
        error: rt.String,
    }),
);

export type OfferWithInvalidSpecification = rt.Static<typeof InvalidSpecificationRuntype>;
const InvalidSpecificationRuntype = rt.Record({
    type: rt.Literal('InvalidSpecification'),
    errors: InvalidSpecificationErrorRuntype,
});

export type OfferWithApiError = rt.Static<typeof ApiErrorRuntype>;
const ApiErrorRuntype = rt.Record({
    type: rt.Literal('SupplierApiError'),
});

const PendingRuntype = rt.Record({
    type: rt.Literal('Pending'),
});

const NeedsRefreshForOffersRuntype = rt.Record({
    type: rt.Literal('NeedsRefreshForOffers'),
});

const SuccessWithOffersStatusRuntype = rt.Record({
    type: rt.Literal('OffersAvailable'),
});

const SuccessWithoutOffersStatusRuntype = rt.Record({
    type: rt.Literal('NoOffersReceived'),
});

export enum PanelErrorKind {
    WidthExceedsDeliveryPanel = 'PcbWidthExceedsDeliveryPanel',
    HeightExceedsDeliveryPanel = 'PcbHeightExceedsDeliveryPanel',
    WidthBelowDeliveryPanelMinimum = 'PcbWidthBelowDeliveryPanelMinimum',
    HeightBelowDeliveryPanelMinimum = 'PcbHeightBelowDeliveryPanelMinimum',
    UnknownError = 'UnknownError',
}

const PanelErrorKindRuntype = rt.Record({
    type: runtypeFromEnum(PanelErrorKind),
    message: rt.String.optional(),
});

const PanelErrorStatusRuntype = rt.Record({
    type: rt.Literal('PanelError'),
    error: PanelErrorKindRuntype,
});

const MissingCredentialsStatusRuntype = rt.Record({
    type: rt.Literal('MissingCredentials'),
});

const InvalidCredentialsStatusRuntype = rt.Record({
    type: rt.Literal('InvalidCredentials'),
});

export enum ApiNotSetupErrorKind {
    MissingGatemaCustomerId = 'MissingGatemaCustomerId',
}

const ApiNotSetUpRuntype = rt.Record({
    type: rt.Literal('ApiNotSetUp'),
    error: rt.Record({
        type: runtypeFromEnum(ApiNotSetupErrorKind),
    }),
});

const CustomStackupRuntype = rt.Record({
    type: rt.Literal('CustomStackup'),
});

export type CustomPartOfferStatuses = rt.Static<typeof CustomPartOfferManufacturerStatusRuntype>;
const CustomPartOfferManufacturerStatusRuntype = rt.Union(
    PendingRuntype,
    SuccessWithOffersStatusRuntype,
    SuccessWithoutOffersStatusRuntype,
    NeedsRefreshForOffersRuntype,
    InvalidSpecificationRuntype,
    PanelErrorStatusRuntype,
    MissingCredentialsStatusRuntype,
    InvalidCredentialsStatusRuntype,
    ApiNotSetUpRuntype,
    ApiErrorRuntype,
    CustomStackupRuntype,
);

export type DetailedCustomPartOfferData = rt.Static<typeof DetailedCustomPartOfferResultResponseRuntype>;
const DetailedCustomPartOfferResultResponseRuntype = rt.Record({
    supplier: rt.Record({
        id: rt.String,
        name: rt.String,
    }),
    stock_location: rt.Array(RegionsEnumRuntype),
    status: CustomPartOfferManufacturerStatusRuntype,
});

export type DetailedCustomPartOffers = rt.Static<typeof CustomPartOfferResultArrayResponseRuntype>;
const CustomPartOfferResultArrayResponseRuntype = rt.Record({
    type: rt.Literal('Detailed'),
    data: rt.Array(DetailedCustomPartOfferResultResponseRuntype),
    is_pending: rt.Boolean,
});

export type SimpleOffersDTO = rt.Static<typeof SimpleOfferResultResponseRuntype>;
const SimpleOfferResultResponseRuntype = rt.Record({
    type: rt.Literal('Simple'),
    data: CustomPartOfferManufacturerStatusRuntype,
});

const NoSourcingScenariosRuntype = rt.Record({
    type: rt.Literal('NoSourcingScenarios'),
});

const AnalysisInProgressRuntype = rt.Record({
    type: rt.Literal('AnalysisInProgress'),
});

const NoSuppliersConfiguredRuntype = rt.Record({
    type: rt.Literal('NoSuppliersConfigured'),
});

export type CustomPartOfferResponse = rt.Static<typeof CustomPartOfferResultResponseStatusRuntype>;
const CustomPartOfferResultResponseStatusRuntype = rt.Union(
    NoSourcingScenariosRuntype,
    AnalysisInProgressRuntype,
    NoSuppliersConfiguredRuntype,
    CustomPartOfferResultArrayResponseRuntype,
    SimpleOfferResultResponseRuntype,
);

export const CustomPartOfferResultResponseRuntype = rt.Record({
    pcb_id: rt.String,
    status: CustomPartOfferResultResponseStatusRuntype,
});
