import { t } from '@lingui/macro';
import { isProductionEnvironment, throwErrorUnlessProduction } from '@luminovo/commons';
import { AxiosError } from 'axios';
import * as r from 'runtypes';
import { FRONTEND_APP_FRONTEND_FRIENDLY_ERROR_VERSION } from '../const';
import { NotificationFunction } from '../resources/NotificationFunction';

// eslint-disable-next-line lingui/t-call-in-function
export const nonSpecificErrorMessage = t`Sorry, there was a problem. Please try again later or contact our customer support.`;

export { logToExternalErrorHandlers, throwErrorUnlessProduction } from '@luminovo/commons';

const customConsoleError = (message: unknown): void => {
    if (!isProductionEnvironment()) {
        /* eslint-disable no-console */
        console.error(message);
        /* eslint-enable no-console */
    }
};

function buildTreePaths(details: r.Details): Array<{ path: string; message: string }> {
    const queue: { path: string; details: r.Details | string }[] = [{ path: '', details }];
    const result: Array<{ path: string; message: string }> = [];
    while (queue.length > 0) {
        const { details: head, path } = queue.shift()!;
        if (typeof head === 'string') {
            result.push({ path, message: head });
        } else {
            Object.entries(head).forEach(([i, child]) =>
                queue.push({
                    path: `${path}.${i}`,
                    details: child,
                }),
            );
        }
    }
    return result;
}

function printFormattedRuntypeError(err: unknown, data: unknown): void {
    /* eslint-disable no-console */
    if (err instanceof r.ValidationError) {
        const details = err.details ?? [];
        const paths = buildTreePaths(details);
        const slice = paths.slice(0, 3);

        const lines = [
            `Validation error`,
            `There were ${paths.length} errors, showing the first ${slice.length}`,
            ...slice.map(({ path, message }) => `➡ ${path}:\n   ${message}\n\n`),
        ];

        console.error(lines.join('\n'), data);
    } else {
        console.error('Validation error. Actual data:\n', data);
    }
    /* eslint-enable no-console */
}

export const assertAgainstRuntype = (data: unknown, runtype: r.Runtype): void => {
    try {
        if (!isProductionEnvironment()) {
            runtype.check(data);
        }
    } catch (error) {
        if (!isProductionEnvironment()) {
            printFormattedRuntypeError(error, data);
        }
        throwErrorUnlessProduction(error);
    }
};

export const responseErrorHandler = (error: AxiosError | Error, notificationFunction: NotificationFunction): void => {
    if (isAxiosError(error)) {
        return handleAxiosError(error, notificationFunction);
    }
    return handleStandardError(error, notificationFunction);
};

const isAxiosError = (error: Error | AxiosError): error is AxiosError => {
    return 'config' in error;
};

const handleAxiosError = (error: AxiosError, notificationFunction: NotificationFunction): void => {
    const isGetOrOptions =
        error.config.method !== undefined && ['get', 'GET', 'options', 'OPTIONS'].includes(error.config.method);

    if (!isGetOrOptions) {
        somethingWentWrongNotification(notificationFunction);
    }

    if (!isProductionEnvironment()) {
        if (error.response) {
            if (error.response.status === 408) {
                return;
            }
            const errorDevMessage = ` ${error.response.status}  ${error.config.url}  ${error.response.data.detail}`;
            if (FRONTEND_APP_FRONTEND_FRIENDLY_ERROR_VERSION !== 'true') {
                notificationFunction(errorDevMessage, { variant: 'error' });
            } else {
                const getErrorComponent = (error: AxiosError): string | undefined => {
                    const errorComponent = error.config.url?.split('/')[4];
                    if (errorComponent === 'my') {
                        const errorComponent1 = error.config.url?.split('/')[4];
                        const errorComponent2 = error.config.url?.split('/')[5];
                        return `${errorComponent1}/${errorComponent2}`;
                    }
                    return errorComponent;
                };

                const errorComponent = getErrorComponent(error);
                // no translation needed since this only runs in non-production environments
                notificationFunction('Sorry, looks like something went wrong in ' + errorComponent, {
                    variant: 'error',
                });
            }
        }
    } else if (error.request) {
        customConsoleError(error.request);
    } else {
        customConsoleError(error.message);
    }
    /* eslint-disable no-console */
    console.error(JSON.stringify(error.config));
    /* eslint-enable no-console */
};

const handleStandardError = (error: Error, notificationFunction: NotificationFunction): void => {
    // given that these errors come from third party integrations, like azure (when uploading a bom file) we may not want to show
    // the exact error message to the user
    somethingWentWrongNotification(notificationFunction);
    customConsoleError(error.message);
};

const somethingWentWrongNotification = (notificationFunction: NotificationFunction): void => {
    notificationFunction('Sorry, something went wrong with that request', {
        variant: 'error',
    });
};
