import { t } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import { Protected } from '../../../permissions/Protected';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { RfqActionsButton } from './RfqActionsButton';
import { RfqCommentsButton } from './ToolbarOemDashboard';

export function ToolbarRfqDashboard({ rfqId }: { rfqId: string }) {
    const { data: rfq } = useRfQ(rfqId);

    return (
        <Toolbar breadcrumbs={[{ title: t`Dashboard` }]}>
            <RfqCommentsButton rfqId={rfqId} />
            {rfq && (
                <Protected requiredPermissions={['edit:rfq:ems']}>
                    <RfqActionsButton
                        rfqStatus={rfq.status}
                        rfqId={rfqId}
                        isRfqArchived={rfq.is_archived}
                        rfqName={rfq.name}
                    />
                </Protected>
            )}
        </Toolbar>
    );
}
