import { MessageDescriptor } from '@lingui/core';
import { Trans, defineMessage } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Flexbox, TertiaryButton, Text, colorSystem } from '@luminovo/design-system';
import { Info, Replay } from '@mui/icons-material';
import { Box, Divider, LinearProgress, RadioGroup, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { OrderFormState, OrderSelectionOptionWithId } from '../types';
import { OrderCard } from './OrderCard';

export const OrderSelectionCard = ({
    orders,
    validUntil,
    rfqId,
}: {
    orders: OrderSelectionOptionWithId[];
    validUntil: string;
    rfqId: string;
}) => {
    const { control } = useFormContext<OrderFormState>();

    if (orders.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                borderRadius: '8px',
                backgroundColor: colorSystem.neutral.white,
                padding: '24px',
            }}
        >
            <Flexbox flexDirection="column" overflow="hidden" gap={32}>
                <Flexbox flexDirection="column" gap={8}>
                    <Text
                        variant="h3"
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <Trans>Select your order</Trans>
                    </Text>
                    <OrderSelectionOptionsTimer validUntil={validUntil} rfqId={rfqId} />
                </Flexbox>
                <Controller
                    name={`selectedOrderOptionId`}
                    control={control}
                    render={({ field, fieldState }): JSX.Element => (
                        <>
                            {fieldState.error?.message !== undefined && (
                                <Typography variant={'body2'} style={{ color: colorSystem.red[6] }}>
                                    {fieldState.error?.message}
                                </Typography>
                            )}
                            <RadioGroup {...field}>
                                {orders.map((order, i) => (
                                    <>
                                        <OrderCard order={order} key={order.id} />
                                        {i < orders.length - 1 && <Divider />}
                                    </>
                                ))}
                            </RadioGroup>
                        </>
                    )}
                />
            </Flexbox>
        </Box>
    );
};

type TimeUnit = 'min' | 's';
type ProgressLabel = {
    unit: TimeUnit;
    value: number;
};

export const timeUnitTranslations: Record<TimeUnit, MessageDescriptor> = {
    min: defineMessage({ message: 'minutes' }),
    s: defineMessage({ message: 'seconds' }),
};

function getRemainingSecondsFromNow(validUntil: string) {
    const now = Date.now();
    const validUntilMillis = Date.parse(validUntil);
    const remainingMillis = Math.max(0, validUntilMillis - now);
    const remainingSeconds = remainingMillis / 1000;

    return Math.floor(remainingSeconds);
}

const OrderSelectionOptionsTimer = ({ validUntil, rfqId }: { validUntil: string; rfqId: string }): JSX.Element => {
    const initialRemainingSeconds = useMemo(() => getRemainingSecondsFromNow(validUntil), [validUntil]);
    const [currentProgress, setCurrentProgress] = useState(1);
    const [progressLabel, setProgressLabel] = useState<ProgressLabel>(getProgressLabel(initialRemainingSeconds));

    const { mutateAsync: reloadOffersAsync } = useHttpMutation('DELETE /order-management/order-selection-options', {
        snackbarMessage: null,
    });

    const { isFetching: isLoadingOrderSelectionOptions } = useHttpQuery(
        'GET /order-management/order-selection-options',
        {
            // eslint-disable-next-line camelcase
            queryParams: { rfq_id: rfqId },
        },
    );

    function getProgressLabel(remainingSeconds: number): ProgressLabel {
        return remainingSeconds > 60
            ? { unit: 'min', value: Math.floor(remainingSeconds / 60) }
            : { unit: 's', value: Math.floor(remainingSeconds) };
    }

    const updateTimer = useCallback(() => {
        const remainingSeconds = getRemainingSecondsFromNow(validUntil);

        const progress = initialRemainingSeconds === 0 ? 0 : remainingSeconds / initialRemainingSeconds;
        setCurrentProgress(progress);

        const label = getProgressLabel(remainingSeconds);
        setProgressLabel(label);
    }, [validUntil, initialRemainingSeconds]);

    useEffect(() => {
        const intervalId = setInterval(updateTimer, 1000);
        return () => clearInterval(intervalId);
    }, [updateTimer]);

    return (
        <Flexbox flexDirection="column" justifyContent="center" gap="2px" width="100%">
            <Flexbox justifyContent={'space-between'} alignItems={'baseline'} gap={8} width="100%">
                <Flexbox gap={4} alignItems={'center'} width="100%">
                    <Info style={{ color: colorSystem.neutral[5], fontSize: '12px' }} fontSize="small" />
                    <Flexbox flexGrow="1" width="100%">
                        <Text variant="body-small">
                            <Trans>The following offers are valid for the next</Trans>{' '}
                            <Text variant="body-small-semibold">
                                {progressLabel.value} {transEnum(progressLabel.unit, timeUnitTranslations)}
                            </Text>
                        </Text>
                    </Flexbox>
                    <TertiaryButton
                        startIcon={<Replay fontSize="inherit" />}
                        size="medium"
                        style={{ whiteSpace: 'nowrap' }}
                        disabled={isLoadingOrderSelectionOptions}
                        onClick={() => reloadOffersAsync({ queryParams: { rfq_id: rfqId } })}
                    >
                        <Trans>Reload offers</Trans>
                    </TertiaryButton>
                </Flexbox>
            </Flexbox>
            {isLoadingOrderSelectionOptions ? (
                <LinearProgress variant="indeterminate" />
            ) : (
                <LinearProgress variant="determinate" value={currentProgress * 100} />
            )}
        </Flexbox>
    );
};
