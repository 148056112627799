import { t } from '@lingui/macro';
import { FormSection } from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteOfIdOption } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormAutocompleteComponents';
import { AddActivityFormInputs } from '../AddActivityFormTypes';
import { MultipleResourceForm } from './MultipleResourceForm';

export const MultipleResourceSection = ({
    allResourceNamesWithId,
    useFormReturn,
    allResources,
}: {
    allResourceNamesWithId: AutocompleteOfIdOption[];
    useFormReturn: UseFormReturn<AddActivityFormInputs>;
    allResources: ResourceDTO[];
}): JSX.Element => {
    return (
        <FormSection title={t`Resource`}>
            <MultipleResourceForm
                {...useFormReturn}
                allResourceNameWithIds={allResourceNamesWithId}
                allResources={allResources}
            />
        </FormSection>
    );
};
