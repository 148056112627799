import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, styled, tooltipClasses } from '@mui/material';
import { colorSystem } from '../../../theme';

/**
 * The ParametricSearchInput uses a custom tooltip. Don't replace this with the
 * Tooltip component from the design system.
 */

export const ParametricInputTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: colorSystem.neutral[9],
        boxShadow: `0px 2px 8px rgba(16, 24, 40, 0.12)`,
        padding: '0 !important',
        minWidth: 220,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: colorSystem.neutral[1],
    },
});
