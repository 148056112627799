import { runtypeFromEnum } from '../../utils/typingUtils';

export enum Packaging {
    AmmoPack = 'AmmoPack',
    Bag = 'Bag',
    Bulk = 'Bulk',
    Carton = 'Carton',
    Reel = 'Reel',
    ReReel = 'ReReel',
    Tape = 'Tape',
    Tray = 'Tray',
    Tube = 'Tube',
}
export const PackagingRuntype = runtypeFromEnum(Packaging);
