import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import {
    CandidateCpn,
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { highlightCpnRevisionMatches } from '../../utils/highlighting/cpnHighlighting';
import { HighlightedValue } from '../partSpecificationCards/PartSpecificationCardFields';

const MAX_WIDTH = '106px';

export const CpnView = ({ part, candidateCpns }: { part: FullPart; candidateCpns?: CandidateCpn[] }): JSX.Element => {
    if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
        const cpns = part.cpns;
        const isRemoved = part.state === 'Removed';
        if (isRemoved) {
            return (
                <span style={{ color: colorSystem.neutral[6] }}>
                    {cpns.length > 0 ? cpns[0].value : '-'}
                    {cpns.length > 0 && cpns[0].revision && ` • ${cpns[0].revision}`}
                </span>
            );
        }

        if (cpns.length > 0) {
            const { cpnFragments, revisionFragments } = highlightCpnRevisionMatches({
                cpns,
                searchQuery: '',
                candidateCpns,
            });
            return (
                <>
                    <Flexbox maxWidth={MAX_WIDTH} gap="4px" alignItems="center" flexWrap="nowrap">
                        <HighlightedValue fragments={cpnFragments} />

                        {revisionFragments.length > 0 && <Text>•</Text>}
                        <HighlightedValue fragments={revisionFragments} />
                        {part.cpns.length > 1 && (
                            <Text variant="body-small" color={colorSystem.neutral[6]}>
                                +{part.cpns.length - 1}
                            </Text>
                        )}
                    </Flexbox>
                </>
            );
        }
        return <>-</>;
    }
    if (isGenericFullPart(part) || isOtsFullPart(part) || isCustomFullPart(part)) {
        return <>-</>;
    }

    assertUnreachable(part);
};
