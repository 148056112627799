/* eslint-disable spellcheck/spell-checker */
export const fr = {
    consentModal: {
        title: '🍪 Nous utilisons des cookies !',
        description:
            "Bonjour, ce site Web utilise des cookies essentiels pour assurer son bon fonctionnement, des cookies de suivi pour comprendre comment vous interagissez avec lui et des cookies de support pour le support client et le suivi des problèmes. Ces deux derniers ne seront fixés qu'après accord. Si vous nous donnez votre consentement, vos données peuvent être traitées en dehors de l'EEE.",
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout rejeter',
        showPreferencesBtn: "Plus d'options",
    },
    preferencesModal: {
        title: '🍪 Nous utilisons des cookies !',
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout rejeter',
        savePreferencesBtn: 'Sauvegarder les paramètres',
        closeIconLabel: 'Fermer',
        sections: [
            {
                title: 'Utilisation des cookies 📢',
                description:
                    'Nous utilisons des cookies pour assurer les fonctionnalités de base du site Web et pour améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie de vous inscrire ou de vous désinscrire quand vous le souhaitez. Pour plus de détails concernant les cookies et autres données sensibles, veuillez lire la <a href="https://luminovo.notion.site/Data-Security-Privacy-at-Luminovo-61603a59d0864f108028be963e7ad312" class="cc-link">politique de confidentialité</a> complète.',
            },
            {
                title: 'Cookies essentiels',
                description:
                    'Ces cookies sont indispensables au bon fonctionnement de mon site internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=97025cec003c4f7b8c6a7dfb883a7906" rel="noopener noreferrer" target="_blank" class="cc-link">Voir les informations sur les cookies</a>',
                linkedCategory: 'required',
            },
            {
                title: "Cookies de performance et d'analyse",
                description:
                    'Ces cookies nous permettent de suivre les performances et l&rsquo;utilisation du produit. Nous utilisons ces informations pour améliorer l&rsquo;expérience utilisateur et les performances du produit. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=c2589aa5a8824a9785dc94561a3e0481" rel="noopener noreferrer" target="_blank" class="cc-link">Voir les informations sur les cookies</a>',
                linkedCategory: 'analytics',
            },
            {
                title: 'Cookies de support',
                description:
                    'Ces cookies nous permettent d&rsquo;offrir un soutien et des conseils à nos utilisateurs. <a href="https://luminovo.notion.site/0b8d522639ce45b880cf18f801a3c716?v=7366ed2f10264209893b54184b1595b3" rel="noopener noreferrer" target="_blank" class="cc-link">Voir les informations sur les cookies</a>',
                linkedCategory: 'support',
            },
        ],
    },
};
