import { palette } from './palette';

function getDefaultColor() {
    const scheme = [palette.default[0], palette.default[1], palette.default[2], palette.other[0]];
    return (index: number) => {
        const s = scheme.slice(0);
        return s[index % s.length];
    };
}

export function getDefaultKeyColor(keys: string[]): (key: string) => string {
    const getColor = getDefaultColor();
    return (key: string) => getColor(keys.indexOf(key));
}
