import { Trans, t } from '@lingui/macro';
import { Flexbox, Link, NonIdealState, Text } from '@luminovo/design-system';
import { useHistory } from 'react-router';
import { route } from '../utils/routes';

export const NoDemandsMessage = (): React.ReactElement => {
    const history = useHistory();

    return (
        <Flexbox flexDirection="column" alignItems="center">
            <NonIdealState
                title={t`No demands`}
                description={t`You can now import demands for your component or assemblies from the ERP. More functionalities around demands will be available later on.`}
                action={{
                    children: t`Import demand`,
                    onClick: () => history.push(route('/parts/demand/demand-importer')),
                }}
            />

            <Text>
                {t`Still no demands after importing your demands?`}{' '}
                <Link attention="high" onClick={() => history.push(route('/parts/source-list-importer'))}>
                    <Trans>Click here to import a source list.</Trans>
                </Link>
            </Text>
        </Flexbox>
    );
};
