import { t } from '@lingui/macro';
import { getLocale } from '../i18n';
import { getOrCreateNumberFormatter } from './getOrCreateNumberFormat';

const intlOpts: Intl.NumberFormatOptions = {
    // formatter for integers
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'decimal',
};

export function formatInteger(number?: number): string {
    if (number === undefined || isNaN(number)) {
        return t`Unknown`;
    }

    return getOrCreateNumberFormatter(getLocale(), intlOpts).format(number);
}
