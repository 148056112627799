import { t } from '@lingui/macro';
import type { ParsedValue, Parser } from '../types';

export const parseIdentifier: Parser<string, { ids: Array<{ id: string }> }> = async ([cell], opts, field) => {
    cell = cell.trim();
    const alternatives = opts.ids.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        // Honestly, I'm not sure if we will ever support default values
        // but I'm keeping it here for consistency and completeness
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'error',
            message: t`Required`,
            alternatives,
        };
    }

    const found = alternatives.find((alt) => {
        const supplierNumberMatch = alt.label && alt.label === cell;
        return supplierNumberMatch;
    });

    if (found) {
        return {
            status: 'done',
            value: found,
            existing: true,
            alternatives,
        };
    }

    return {
        value: {
            id: cell,
            label: cell,
            description: t`NEW`,
            existing: false,
        },
        status: 'done',
        alternatives,
    };
};

function toParsedValue({ id }: { id: string }): ParsedValue<string> {
    return {
        id,
        label: id,
        description: '',
        existing: true,
    };
}
