import { t } from '@lingui/macro';
import { Parser } from '@luminovo/parsers';
import { ImporterField, ParseResult } from '../types';

export function applyParser<T>({
    cells,
    parser,
    field,
    alternatives,
    formatValue,
}: {
    cells: string[];
    parser: Parser<T>;
    field: ImporterField<T>;
    alternatives?: Array<{ id: T; label: string }>;
    formatValue: (value: T) => string;
}): ParseResult<T> {
    const text = cells.map((cell) => cell.trim()).join(' ');

    if (text.length === 0 && field.defaultValue) {
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    const parseRes = parser.parse(text);

    if (parseRes.ok) {
        return {
            status: parseRes.message ? 'warning' : 'done',
            message: parseRes.message,
            value: { id: parseRes.value, label: formatValue(parseRes.value) },
            alternatives,
        };
    } else {
        return {
            status: 'error',
            message: t`Unknown format`,
            alternatives,
        };
    }
}
