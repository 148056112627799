import { defineMessage, Trans } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import { colorSystem, Text } from '@luminovo/design-system';
import { ActorResponseRuntypeDTO, HistoryFileType, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

export function FileIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualUpload) return <TimelineAddIcon />;
    return <TimelineDeleteIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    fileType: HistoryFileType;
    fileName: string | undefined;
    operation: HistoryOperation;
};

const fileTypeTranslation = {
    [HistoryFileType.Cad]: defineMessage({ message: 'CAD file' }),
    [HistoryFileType.Other]: defineMessage({ message: 'Additional file' }),
    [HistoryFileType.BOM]: defineMessage({ message: 'BOM' }),
    [HistoryFileType.Pnp]: defineMessage({ message: 'PnP file' }),
};

function useFileAction(
    actor: ActorResponseRuntypeDTO,
    fileType: HistoryFileType,
    fileName: string | undefined,
    operation: HistoryOperation,
) {
    if (!isActorDefined(actor)) return null;
    const fileTranslation = transEnum(fileType, fileTypeTranslation);
    const fileNameText = isPresent(fileName) ? <Text color={colorSystem.neutral[7]}>{fileName}</Text> : undefined;
    switch (operation) {
        case HistoryOperation.ManualUpload:
            return (
                <TimelineText>
                    <Trans>
                        {fileTranslation} {fileNameText} uploaded
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualRemove:
            return (
                <TimelineText>
                    <Trans>
                        {fileTranslation} {fileNameText} deleted
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
}

export default function File({ actor, fileType, fileName, operation }: Props) {
    const action = useFileAction(actor, fileType, fileName, operation);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}
