import { t } from '@lingui/macro';
import { compareByStringKey, isPresent } from '@luminovo/commons';
import { Tag, TanStackTable, Text, colorSystem, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import {
    ManufacturerDTO,
    ManufacturerLineCardDTO,
    PartCategoryDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { SupplierChip, formatSupplierDTO } from '@luminovo/sourcing-core';
import React from 'react';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import { usePartCategories } from '../../resources/part/partHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useSupplierAndStockLocations,
} from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../utils/routes';

export type ManufacturerLineCardTableItem = {
    type: 'subRow';
    manufacturer: ManufacturerDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    isPreferred: boolean;
    isApproved: boolean;
    quoteStatus: 'preferred' | 'enabled' | 'disabled';
    restrictedPartCategories: PartCategoryDTO[];
};

// eslint-disable-next-line max-params
export function convertToManufacturerLineCardTableItems(
    manufacturers: ManufacturerDTO[],
    manufacturerLineCard: ManufacturerLineCardDTO,
    supplierAndStockLocations: SupplierAndStockLocationDTO[],
    partCategories: PartCategoryDTO[],
    preferredSuppliersAndStockLocationsDTO: SupplierAndStockLocationDTO[],
    approvedSuppliersAndStockLocationsDTO: SupplierAndStockLocationDTO[],
): ManufacturerLineCardTableItem[] {
    return manufacturerLineCard.supported_by
        .flatMap((id) => {
            const manufacturer = manufacturers.find((m) => m.id === manufacturerLineCard.manufacturer_id);
            if (!isPresent(manufacturer)) {
                return [];
            }

            const supplierAndStockLocation = supplierAndStockLocations.find(
                (supplier_and_stock_location) => supplier_and_stock_location.supplier.id === id,
            );
            if (!isPresent(supplierAndStockLocation)) {
                return [];
            }

            const isPreferred = preferredSuppliersAndStockLocationsDTO.some((sasl) => sasl.supplier.id === id);
            const isApproved = approvedSuppliersAndStockLocationsDTO.some((sasl) => sasl.supplier.id === id);

            const quoteStatus =
                manufacturerLineCard.restricted_to_suppliers.length > 0
                    ? manufacturerLineCard.restricted_to_suppliers.includes(id)
                        ? ('preferred' as const)
                        : ('disabled' as const)
                    : ('enabled' as const);

            const restrictedPartCategories = manufacturerLineCard.part_category_restriction
                .filter((restriction) => restriction.supplier === id)
                .flatMap((restriction) =>
                    restriction.restrict_to.flatMap((partCategoryId) => {
                        const partCategory = partCategories.find((c) => c.id === partCategoryId);
                        return isPresent(partCategory) ? [partCategory] : [];
                    }),
                );

            return {
                type: 'subRow' as const,
                manufacturer,
                supplierAndStockLocation,
                isPreferred,
                isApproved,
                quoteStatus,
                restrictedPartCategories,
            };
        })
        .sort(compareByStringKey((s) => formatSupplierDTO(s.supplierAndStockLocation.supplier)));
}

const columnHelper = createColumnHelper<ManufacturerLineCardTableItem>();

const columns = [
    columnHelper.text((row) => formatSupplierDTO(row.supplierAndStockLocation.supplier), {
        id: 'supplierAndStockLocation',
        size: 180,
        label: () => t`Supplier`,
        cell: ({ row }) => (
            <SupplierChip
                isPreferred={row.original.isPreferred}
                isApproved={row.original.isApproved}
                supplier={row.original.supplierAndStockLocation.supplier}
            />
        ),
    }),
    columnHelper.enum('quoteStatus', {
        id: 'quoteStatus',
        size: 100,
        getOptionLabel: (option) => {
            switch (option) {
                case 'enabled':
                    return t`Enabled`;
                case 'preferred':
                    return t`Preferred`;
                case 'disabled':
                    return t`Disabled`;
            }
        },
        label: () => t`Allow quote requests`,
        cell: ({ getValue }) => {
            switch (getValue()) {
                case 'preferred':
                    return <Tag attention="low" color="green" label={t`Preferred`} />;
                case 'enabled':
                    return <Tag attention="low" color="blue" label={t`Enabled`} />;
                case 'disabled':
                    return <Tag attention="low" color="red" label={t`Disabled`} />;
            }
        },
    }),
    columnHelper.array('restrictedPartCategories', {
        size: 300,
        label: () => t`Restrict to (part categories)`,
        getOptionLabel: (option) => option.name,
        cell: (item) => {
            const values = item.getValue();
            if (values.length === 0) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        {t`No restriction`}
                    </Text>
                );
            }
            return values.map((c) => c.name).join(', ');
        },
    }),
];

export const ManufacturerLineCardTab: React.FunctionComponent<{
    manufacturer: ManufacturerDTO;
}> = ({ manufacturer }) => {
    const history = useHistory();
    const { data: manufacturers } = useManufacturers();
    const { data: partCategories } = usePartCategories();
    const { data: suppliers } = useSupplierAndStockLocations();
    const { data: preferredSuppliersAndStockLocationsDTO } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocationsDTO } = useGlobalApprovedSupplierAndStockLocations();

    const { data: manufacturerLineCard } = useHttpQuery('GET /manufacturers/:id/line-card', {
        pathParams: { id: manufacturer.id },
    });

    const data = React.useMemo(() => {
        if (
            !manufacturers ||
            !suppliers ||
            !manufacturerLineCard ||
            !partCategories ||
            !preferredSuppliersAndStockLocationsDTO ||
            !approvedSuppliersAndStockLocationsDTO
        ) {
            return undefined;
        }
        return convertToManufacturerLineCardTableItems(
            manufacturers,
            manufacturerLineCard,
            suppliers,
            partCategories,
            preferredSuppliersAndStockLocationsDTO,
            approvedSuppliersAndStockLocationsDTO,
        );
    }, [
        manufacturers,
        suppliers,
        manufacturerLineCard,
        partCategories,
        preferredSuppliersAndStockLocationsDTO,
        approvedSuppliersAndStockLocationsDTO,
    ]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableExcelExport: true,
        onRowClick: (row) => {
            history.push(
                route(
                    '/supplier/:supplierAndStockLocationId',
                    {
                        supplierAndStockLocationId: row.original.supplierAndStockLocation.id,
                    },
                    { tab: 'lineCard' },
                ),
            );
        },
    });

    return <TanStackTable table={table} size={'small'} />;
};
