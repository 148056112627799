import { t } from '@lingui/macro';
import { CustomerPortalRequirement, PnpState } from '@luminovo/http-client';
import { StepContainer } from './StepContainer';

export const PnpStep = ({
    pnpState,
    rfqId,
    assemblyId,
    isRfqEditable,
    requirement,
}: {
    pnpState: PnpState;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    requirement: CustomerPortalRequirement;
}): JSX.Element => {
    const status = pnpState.status.type;

    return (
        <StepContainer
            status={status}
            label={t`Pick & place file`}
            type="pnp"
            rfqId={rfqId}
            assemblyId={assemblyId}
            isRfqEditable={isRfqEditable}
            requirement={requirement}
        />
    );
};
