import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { BuerklinCredentialsInputDTORuntype, BuerklinResponseBodyDTORuntype } from './buerklinBackendTypes';

export const buerklinEndpoints = {
    'GET /3rdparty/buerklin/credentials': endpoint({
        description: 'Returns credentials for buerklin integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: BuerklinResponseBodyDTORuntype,
    }),
    'POST /3rdparty/buerklin/credentials': endpoint({
        description: 'Uploads credentials for buerklin API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: BuerklinCredentialsInputDTORuntype,
        responseBody: BuerklinResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
    'PATCH /3rdparty/buerklin/credentials': endpoint({
        description: 'Updates credentials for buerklin API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: BuerklinCredentialsInputDTORuntype,
        responseBody: BuerklinResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
    'DELETE /3rdparty/buerklin/credentials': endpoint({
        description: 'Deletes existing credentials for buerklin API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
};
