/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface GudecoCredentialsDTO extends r.Static<typeof GudecoCredentialsDTORuntype> {}
export const GudecoCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        customer_key: r.String,
    }),
);

export interface GudecoCredentialsInputDTO extends r.Static<typeof GudecoCredentialsInputDTORuntype> {}
export const GudecoCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    customer_key: r.String.optional(),
});

export interface GudecoResponseBodyDTO extends r.Static<typeof GudecoResponseBodyDTORuntype> {}
export const GudecoResponseBodyDTORuntype = r.Record({
    data: GudecoCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
