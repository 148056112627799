import {
    PCBFileTypes,
    PCBLayerTypes,
    PCBSilkscreenColors,
    PCBSoldermaskColors,
    PCBSurfaceFinish,
    PCBV2,
} from '@luminovo/http-client';

export type PcbSide = 'front' | 'rear';

type PCBSvgStyle = {
    color: string;
    opacity: number;
};

type LayerTypes = PCBFileTypes | PCBLayerTypes;

type ColorSchema = Partial<Record<LayerTypes, PCBSvgStyle>>;

const DEFAULT_COLOR_SCHEMAS: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#ffffff',
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#ffffff',
        opacity: 1,
    },
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#004200',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#004200',
        opacity: 0.75,
    },
    [PCBFileTypes.COPPER_TOP]: {
        color: '#CC9933',
        opacity: 1,
    },
    [PCBFileTypes.COPPER_BOTTOM]: {
        color: '#CC9933',
        opacity: 1,
    },
    prepreg: {
        color: '#708090',
        opacity: 0.9,
    },
    core: {
        color: '#A0522D',
        opacity: 0.9,
    },
    flexcore: {
        color: '#A0522D',
        opacity: 0.9,
    },
    dielectric: {
        color: '#708090',
        opacity: 0.9,
    },
};

//
// surface finish color schemes
//
const RIGID_HAL_COLOR: ColorSchema = {
    [PCBFileTypes.COPPER_BOTTOM]: {
        color: '#d1c9c9',
        opacity: 1,
    },
    [PCBFileTypes.COPPER_TOP]: {
        color: '#d1c9c9',
        opacity: 1,
    },
};

const RIGID_ENIG_COLOR: ColorSchema = {
    [PCBFileTypes.COPPER_BOTTOM]: {
        color: '#CC9933',
        opacity: 1,
    },
    [PCBFileTypes.COPPER_TOP]: {
        color: '#CC9933',
        opacity: 1,
    },
};

const RIGID_NONE_COLOR: ColorSchema = {
    [PCBFileTypes.COPPER_BOTTOM]: {
        color: '#B77729',
        opacity: 1,
    },
    [PCBFileTypes.COPPER_TOP]: {
        color: '#B77729',
        opacity: 1,
    },
};

//
// SolderMask color schemes
//
const RIGID_RED_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#b82915',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#b82915',
        opacity: 0.75,
    },
};

const RIGID_BLUE_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#06115b',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#06115b',
        opacity: 0.75,
    },
};

const RIGID_GREEN_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#004200',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#004200',
        opacity: 0.75,
    },
};

const RIGID_WHITE_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#ffffff',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#ffffff',
        opacity: 0.75,
    },
};

const RIGID_BLACK_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#020202',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#020202',
        opacity: 0.75,
    },
};

const RIGID_GREEN_MATT_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#003500',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#003500',
        opacity: 0.75,
    },
};

const RIGID_GREEN_GLOSSY_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#004200',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#004200',
        opacity: 0.75,
    },
};

const RIGID_PURPLE_SOLDERMASK: ColorSchema = {
    [PCBFileTypes.SOLDERMASK_TOP]: {
        color: '#8B47AA',
        opacity: 0.75,
    },
    [PCBFileTypes.SOLDERMASK_BOTTOM]: {
        color: '#8B47AA',
        opacity: 0.75,
    },
};

//
// Silkscreen color schemes
//
const RIGID_YELLOW_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#EAC100',
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#EAC100',
        opacity: 1,
    },
};

const RIGID_WHITE_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#ffffff',
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#ffffff',
        opacity: 1,
    },
};

const RIGID_BLACK_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#020202',
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#020202',
        opacity: 1,
    },
};

const RIGID_RED_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#B82915',
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#B82915',
        opacity: 1,
    },
};

const RIGID_GREEN_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#004200', // Yes, it's the same as the soldermask color, but they wouldn't be picked at the same time, right?
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#004200',
        opacity: 1,
    },
};

const RIGID_BLUE_SILKSCREEN: ColorSchema = {
    [PCBFileTypes.SILKSCREEN_TOP]: {
        color: '#06115b', // Yes, it's the same as the soldermask color, but they wouldn't be picked at the same time, right?
        opacity: 1,
    },
    [PCBFileTypes.SILKSCREEN_BOTTOM]: {
        color: '#06115b',
        opacity: 1,
    },
};

const PropertyToSilkscreenColors: Map<string, ColorSchema> = new Map([
    [PCBSilkscreenColors.WHITE, RIGID_WHITE_SILKSCREEN],
    [PCBSilkscreenColors.BLACK, RIGID_BLACK_SILKSCREEN],
    [PCBSilkscreenColors.YELLOW, RIGID_YELLOW_SILKSCREEN],
    [PCBSilkscreenColors.RED, RIGID_RED_SILKSCREEN],
    [PCBSilkscreenColors.GREEN, RIGID_GREEN_SILKSCREEN],
    [PCBSilkscreenColors.BLUE, RIGID_BLUE_SILKSCREEN],
]);

const PropertyToSolderMaskColors: Map<string, ColorSchema> = new Map([
    [PCBSoldermaskColors.GREEN, RIGID_GREEN_SOLDERMASK],
    [PCBSoldermaskColors.RED, RIGID_RED_SOLDERMASK],
    [PCBSoldermaskColors.BLUE, RIGID_BLUE_SOLDERMASK],
    [PCBSoldermaskColors.WHITE, RIGID_WHITE_SOLDERMASK],
    [PCBSoldermaskColors.BLACK, RIGID_BLACK_SOLDERMASK],
    // eslint-disable-next-line spellcheck/spell-checker
    [PCBSoldermaskColors.GREEN_MATT, RIGID_GREEN_MATT_SOLDERMASK],
    [PCBSoldermaskColors.GREEN_GLOSSY, RIGID_GREEN_GLOSSY_SOLDERMASK],
    [PCBSoldermaskColors.PURPLE, RIGID_PURPLE_SOLDERMASK],
]);

const PropertyToFinishColors: Map<string, ColorSchema> = new Map([
    [PCBSurfaceFinish.NONE, RIGID_NONE_COLOR],
    [PCBSurfaceFinish.HAL_PB, RIGID_HAL_COLOR],
    [PCBSurfaceFinish.HAL_PB_FREE, RIGID_HAL_COLOR],
    [PCBSurfaceFinish.ENIG, RIGID_ENIG_COLOR],
]);

export const DEFAULT_STYLE: PCBSvgStyle = {
    color: '#711c91',
    opacity: 1,
};

export interface ColorPreference {
    finish?: string;
    solderMaskColor?: string;
    silkscreenColor?: string;
    solderMaskSide?: string;
    silkscreenSide?: string;
}

export const getColorPreference = (pcb: PCBV2): ColorPreference => {
    const specificationSettings = pcb.specifications[0]?.settings || { board: {} };

    return {
        finish: specificationSettings.board.surfaceFinish || pcb.properties.board.surfaceFinish,
        solderMaskColor: specificationSettings.board.soldermaskColor || pcb.properties.board.soldermaskColor,
        silkscreenColor: specificationSettings.board.silkscreenColor || pcb.properties.board.silkscreenColor,
        solderMaskSide: specificationSettings.board.soldermaskSide || pcb.properties.board.soldermaskSide,
        silkscreenSide: specificationSettings.board.silkscreenSide || pcb.properties.board.silkscreenSide,
    };
};

const getLayerStyle = (property: string, layer: LayerTypes, propertyColorMap: Map<string, ColorSchema>) => {
    const colorSchema = propertyColorMap.get(property);
    if (colorSchema) {
        const layerStyle = colorSchema[layer];
        if (layerStyle) {
            return layerStyle;
        }
    }
    return undefined;
};

export const getLayerColor = (layer: LayerTypes, colorPreference: ColorPreference): PCBSvgStyle => {
    switch (layer) {
        case PCBFileTypes.COPPER_TOP:
        case PCBFileTypes.COPPER_BOTTOM:
            if (colorPreference.finish) {
                const layerStyle = getLayerStyle(colorPreference.finish, layer, PropertyToFinishColors);
                if (layerStyle) {
                    return layerStyle;
                }
            }
            break;
        case PCBFileTypes.SOLDERMASK_TOP:
            if (colorPreference.solderMaskColor) {
                const layerStyle = getLayerStyle(colorPreference.solderMaskColor, layer, PropertyToSolderMaskColors);
                if (layerStyle) {
                    if (colorPreference.solderMaskSide === 'none' || colorPreference.solderMaskSide === 'bottom') {
                        layerStyle.opacity = 0;
                    }
                    return layerStyle;
                }
            }
            break;
        case PCBFileTypes.SOLDERMASK_BOTTOM:
            if (colorPreference.solderMaskColor) {
                const layerStyle = getLayerStyle(colorPreference.solderMaskColor, layer, PropertyToSolderMaskColors);
                if (layerStyle) {
                    if (colorPreference.solderMaskSide === 'none' || colorPreference.solderMaskSide === 'top') {
                        layerStyle.opacity = 0;
                    }
                    return layerStyle;
                }
            }
            break;
        case PCBFileTypes.SILKSCREEN_TOP:
            if (colorPreference.silkscreenColor) {
                const layerStyle = getLayerStyle(colorPreference.silkscreenColor, layer, PropertyToSilkscreenColors);
                if (layerStyle) {
                    if (colorPreference.silkscreenSide === 'none' || colorPreference.silkscreenSide === 'bottom') {
                        layerStyle.opacity = 0;
                    }
                    return layerStyle;
                }
            }
            break;
        case PCBFileTypes.SILKSCREEN_BOTTOM:
            if (colorPreference.silkscreenColor) {
                const layerStyle = getLayerStyle(colorPreference.silkscreenColor, layer, PropertyToSilkscreenColors);
                if (layerStyle) {
                    if (colorPreference.silkscreenSide === 'none' || colorPreference.silkscreenSide === 'top') {
                        layerStyle.opacity = 0;
                    }
                    return layerStyle;
                }
            }
            break;
    }

    return DEFAULT_COLOR_SCHEMAS[layer] || DEFAULT_STYLE;
};
