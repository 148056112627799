import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router';
import { ErrorFallback } from '../../../components/errorHandlers/ErrorBoundary';
import { useFindPcb } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { PcbActionToolbar, useCurrentRouteDetails } from '../components/FormActionToolbar';
import { LayerAssignment } from '../components/LayerAssignment';
import {
    ContainersWithFiles,
    createPcbFileUpdateRequest,
    getInitialItemState,
} from '../components/LayerAssignment/utils/layerAssignmentsUtils';
import { useMutationUpdatePcbFileTypes } from '../components/LayerAssignment/utils/useMutationUpdatePcbFileTypes';
import { PcbLoading } from '../components/PcbLoading';
import { PcbModuleContextProvider } from '../components/PcbModuleContextProvider';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { fileDnDReducer } from '../utils/fileDnDReducer';
import { usePcbApprovalState } from '../utils/usePcbApprovalState';

export function FileMatcherTab({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <PcFileMatcherLayoutWrapper
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isEditable}
                viewContext={viewContext}
            />
        </PcbLoading>
    );
}

function PcFileMatcherLayoutWrapper({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const { data: pcb } = useFindPcb({ assemblyId });

    if (!pcb) {
        return <></>;
    }

    return (
        <FileMatcherLayout
            assemblyId={assemblyId}
            rfqId={rfqId}
            pcb={pcb}
            isEditable={isEditable}
            viewContext={viewContext}
        />
    );
}

function FileMatcherLayout({
    assemblyId,
    rfqId,
    pcb,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const useFormReturn = useForm({});
    const pcbFiles = React.useMemo(() => pcb.files ?? [], [pcb]);

    const [state, dispatch] = React.useReducer(fileDnDReducer, {
        isEditing: false,
        isDirty: false,
        items: getInitialItemState(pcbFiles),
    });

    const prevPcbFilesRef = React.useRef(pcbFiles);

    React.useEffect(() => {
        if (JSON.stringify(prevPcbFilesRef.current) !== JSON.stringify(pcbFiles)) {
            const newItems = getInitialItemState(pcbFiles);
            dispatch({ type: 'SET_ITEMS', payload: newItems });
            prevPcbFilesRef.current = pcbFiles;
        }
    }, [pcbFiles]);

    const { mutateAsync, isLoading: isUpdatingFiles } = useMutationUpdatePcbFileTypes({ pcbId: pcb.id });

    const handleChange = React.useCallback(
        (newValue: ContainersWithFiles | ((prev: ContainersWithFiles) => ContainersWithFiles)) => {
            dispatch({ type: 'SET_DIRTY', payload: true });
            dispatch({ type: 'SET_ITEMS', payload: newValue });
        },
        [],
    );

    const handleCancel = () => {
        dispatch({ type: 'SET_ITEMS', payload: getInitialItemState(pcbFiles) });
        dispatch({ type: 'SET_EDITING', payload: false });
        dispatch({ type: 'SET_DIRTY', payload: false });
    };

    const { currentRoute } = useCurrentRouteDetails({ viewContext });
    const { isRouteApproved, onApprovalClick } = usePcbApprovalState({
        pcbId: pcb.id,
        currentRoute: currentRoute?.route,
    });

    const handleEdit = async () => {
        if (isRouteApproved === true) {
            await onApprovalClick(false);
        }
        dispatch({ type: 'SET_EDITING', payload: true });
    };

    const handleSubmit = useFormReturn.handleSubmit(async () => {
        const updateRequest = createPcbFileUpdateRequest(state.items);
        const mutateData = await mutateAsync(updateRequest);
        if (isRouteApproved === false) {
            await onApprovalClick(true);
        }
        enqueueSnackbar(t`File matching approved. The PCB will be re-rendered now` + '...', {
            variant: 'success',
        });
        dispatch({ type: 'SET_EDITING', payload: false });
        dispatch({ type: 'SET_DIRTY', payload: false });
        return mutateData;
    });

    React.useEffect(() => {
        dispatch({ type: 'SET_EDITING', payload: isRouteApproved === false && isEditable ? true : false });
    }, [isRouteApproved, isEditable]);

    return (
        <PcbSidebarLayout pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext}>
            <PcbModuleContextProvider>
                <FormProvider {...useFormReturn}>
                    <PcbActionToolbar
                        viewContext={viewContext}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        pcb={pcb}
                        pageTitle={t`Files`}
                        // eslint-disable-next-line spellcheck/spell-checker
                        formId={'pcb-file-matcher-form'}
                        disabled={isUpdatingFiles}
                        isDirty={state.isDirty}
                        isEditing={state.isEditing}
                        isEditable={isEditable}
                        submitButtonOptions={{
                            onClick: handleSubmit,
                            id: id('design/button_pcb_file_manager_save'),
                        }}
                        cancelButtonOptions={{
                            onClick: handleCancel,
                        }}
                        editButtonOptions={{
                            onClick: handleEdit,
                            id: id('design/button_pcb_file_manager_edit'),
                        }}
                    />
                    <Prompt
                        message={t`This form has unsaved changes. Are you sure you want to lose your progress?`}
                        when={state.isDirty}
                    />
                    <Box
                        style={{
                            overflow: 'scroll',
                            width: '100%',
                            height: 'calc(100vh - 155px)',
                            backgroundColor: colorSystem.neutral[1],
                        }}
                    >
                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                            <LayerAssignment
                                pcbId={pcb.id}
                                items={state.items}
                                handleOnChange={handleChange}
                                isEditable={state.isEditing}
                                allowFileUpload={!state.isDirty}
                            />
                        </Sentry.ErrorBoundary>
                    </Box>
                </FormProvider>
            </PcbModuleContextProvider>
        </PcbSidebarLayout>
    );
}
