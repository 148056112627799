import { Thumbnail, colorSystem } from '@luminovo/design-system';
import { FullPart, isOtsFullPart } from '@luminovo/http-client';

export function StandardPartThumbnail({ parts, size }: { parts: FullPart[]; size: number }): JSX.Element {
    const otsPartUrls = parts.map((p) => (isOtsFullPart(p) ? p.image_url : undefined));
    const url = otsPartUrls[0];
    if (url) {
        return (
            <Thumbnail
                width={`${size}px`}
                height={`${size}px`}
                src={url.toString()}
                ImageProps={{
                    style: {
                        borderRadius: '4px',
                    },
                }}
                style={{
                    padding: 4,
                    borderRadius: 8,
                    boxSizing: 'border-box',
                    border: `1px solid ${colorSystem.neutral[3]}`,
                    background: colorSystem.neutral[1],
                }}
            />
        );
    }

    return (
        <Thumbnail
            width={`${size}px`}
            height={`${size}px`}
            src={'none.png'}
            ImageProps={{
                style: {
                    borderRadius: '4px',
                },
            }}
            style={{
                padding: 4,
                borderRadius: 8,
                boxSizing: 'border-box',
                border: `1px solid ${colorSystem.neutral[3]}`,
                background: colorSystem.neutral[1],
            }}
        />
    );
}
