import { assertUnreachable } from '@luminovo/commons';
import { Qualification } from '@luminovo/http-client';

export function formatQualification(value: Qualification): string {
    switch (value) {
        case Qualification.DLA:
            return 'DLA';
        case Qualification.RadHard:
            return 'Rad-Hard';
        case Qualification.Space:
            return 'Space';
        case Qualification.UL:
            return 'UL';
        default:
            assertUnreachable(value);
    }
}
