import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { useManufacturingAssemblyDetailsAndAssembly } from '../../../resources/manufacturingAssemblyDetails/manufacturingAssemblyDetailsHandler';
import { route } from '../../../utils/routes';
import { useManufacturingPage } from '../shared/ManufacturingSharedFunctions';

const useAssemblyLinkDetails = (rfqId: string, manufacturingAssemblyDetailsId: string) => {
    const { assembly } = useManufacturingAssemblyDetailsAndAssembly(manufacturingAssemblyDetailsId);
    return {
        title: assembly?.designator || '...',
        href: route(`/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId`, {
            rfqId,
            manufacturingAssemblyDetailsId,
        }),
    };
};

//TODO: make the three parameters into an object
const useScenarioLinkDetails = (
    rfqId: string,
    manufacturingAssemblyDetailsId: string,
    manufacturingScenarioId: string,
) => {
    const { manufacturingScenario } = useManufacturingPage();
    return {
        title: manufacturingScenario?.name || '...',
        href: route(`/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity`, {
            rfqId,
            manufacturingAssemblyDetailsId,
            manufacturingScenarioId,
        }),
    };
};

//TODO: make the three parameters into an object
export const useManufacturingBreadcrumbsForManufacturingScenarioPage = (
    rfqId: string,
    manufacturingAssemblyDetailsId: string,
    manufacturingScenarioId: string,
): { path: { href?: string; title: string }[] } => {
    const manufacturingLinkPath = {
        title: t`Manufacturing`,
        href: route(`/rfqs/:rfqId/manufacturing`, {
            rfqId,
        }),
    };
    const assemblyLinkPath = useAssemblyLinkDetails(rfqId, manufacturingAssemblyDetailsId);
    const scenarioLinkPath = useScenarioLinkDetails(rfqId, manufacturingAssemblyDetailsId, manufacturingScenarioId);

    const path = [manufacturingLinkPath, assemblyLinkPath, scenarioLinkPath].filter(isPresent);

    return {
        path,
    };
};

export const useManufacturingBreadcrumbsForAssemblyPage = (
    rfqId: string,
    manufacturingAssemblyDetailsId: string,
): { path: { href?: string; title: string }[] } => {
    const manufacturingLinkPath = {
        title: t`Manufacturing`,
        href: route(`/rfqs/:rfqId/manufacturing`, {
            rfqId,
        }),
    };
    const assemblyLinkPath = useAssemblyLinkDetails(rfqId, manufacturingAssemblyDetailsId);

    const path = [manufacturingLinkPath, assemblyLinkPath].filter(isPresent);

    return {
        path,
    };
};
