import { Tooltip } from '@luminovo/design-system';
import React from 'react';

// https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
export const useIsOverflow = (ref: React.RefObject<HTMLDivElement>) => {
    const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
    React.useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            if (!current) {
                return;
            }
            const hasOverflow = current.scrollWidth > current.clientWidth;

            setIsOverflow(hasOverflow);
        };

        if (current) {
            let resizeObserver: ResizeObserver | null = null;
            /* eslint-disable-next-line spellcheck/spell-checker */
            if ('ResizeObserver' in window) {
                resizeObserver = new ResizeObserver(trigger);
                resizeObserver.observe(current);
            }

            trigger();
            return () => {
                if (resizeObserver) {
                    resizeObserver.unobserve(current);
                    resizeObserver.disconnect();
                }
            };
        }
    }, [ref]);

    return isOverflow;
};

/**
 * Checks if a component is "overflowing" and if it is
 * shows a tooltip (which could, for example be the full,
 * unclipped component).
 */
export const TooltipOnOverflow = ({
    component,
    tooltip,
}: {
    component: JSX.Element;
    tooltip: JSX.Element;
}): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isOverflow = useIsOverflow(ref);
    const [showTooltip, setShowTooltip] = React.useState(isOverflow);

    const mouseEnterHandler = React.useCallback(
        (e) => {
            if (isOverflow) {
                setShowTooltip(true);
            }
        },
        [setShowTooltip, isOverflow],
    );

    const mouseLeaveHandler = React.useCallback(
        (e) => {
            if (isOverflow) {
                setShowTooltip(false);
            }
        },
        [setShowTooltip, isOverflow],
    );
    return (
        <Tooltip variant="white" title={tooltip} placement={'bottom'} disableHoverListener={!showTooltip}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'hidden',
                }}
                ref={ref}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
            >
                {component}
            </div>
        </Tooltip>
    );
};
