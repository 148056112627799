import { getToken } from '@luminovo/auth';
import { compareByString, isPresent, uniq, useMemoCompare } from '@luminovo/commons';
import {
    ApprovalStatus,
    CustomComponentFull,
    CustomFullPart,
    CustomOptionOfferDTO,
    CustomOptionTypes,
    CustomOptionVariantDTO,
    CustomPartTypeEnum,
    FullPart,
    GenericFullPart,
    OtsComponentFull,
    OtsFullPart,
    OtsOrCustomComponent,
    PackageTag,
    PartCategoryDTO,
    PartSpecification,
    RfQContextQueryParams,
    RfqContext,
    StandardPartDTO,
    StandardPartOfferDTO,
    StandardPartTypes,
    getApprovedCustomPartIds,
    getApprovedGenericPartIds,
    getApprovedIpnIds,
    getApprovedOtsPartIds,
    getCustomApprovedIpnIds,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import {
    isCustomComponentOffer,
    isCustomPartOffer,
    isInternalPartNumberOffer,
    isOffTheShelfPartOffer,
} from '@luminovo/sourcing-core';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React from 'react';
import { fetchMpnMatches } from '../../components/partSpecificationCards/useMpnMatches';
import { logToExternalErrorHandlers } from '../../utils/customConsole';
import { getPartIdsSet } from '../../utils/getPartIds';
import { assertUnreachable } from '../../utils/typingUtils';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { useDesignItems } from '../designItem/designItemHandler';
import { useHttpQuery } from '../http/useHttpQuery';
import { useSolutionConfiguration } from '../solutionConfiguration/solutionConfigurationHandler';
import { PartSpecificationTypes } from './PartSpecificationTypes';
import { FullPartWithApprovalStatus } from './partFrontendTypes';

export function convertRfqContext(rfqContext: RfqContext): RfQContextQueryParams {
    if (rfqContext.type === 'WithinRfQ') {
        return {
            rfq_context: rfqContext.type,
            rfq_id: rfqContext.rfq_id,
        };
    }
    if (rfqContext.type === 'OutsideRfQ') {
        return {
            rfq_context: rfqContext.type,
        };
    }
    assertUnreachable(rfqContext);
}

export function usePartCategory(id: number | undefined) {
    const { data, isLoading } = usePartCategories();
    return { data: data?.find((category) => category.id === id), isLoading };
}

export function usePartCategories() {
    return useHttpQuery(
        'GET /parts/part-categories',
        {},
        {
            select: (data) => {
                const partCategories = data.items;

                const childrenToParentMap = new Map(
                    partCategories.flatMap((category) => category.children.map((child) => [child, category])),
                );

                const buildPath = (category: PartCategoryDTO): PartCategoryDTO[] => {
                    if (category.depth === 0) {
                        return [category];
                    }

                    const parentCategory = childrenToParentMap.get(category.id);
                    const parentPath = parentCategory ? buildPath(parentCategory) : [];

                    return [...parentPath, category];
                };

                return partCategories.map((partCategory) => ({
                    ...partCategory,
                    path: buildPath(partCategory),
                }));
            },
            staleTime: Infinity,
        },
    );
}

export function useStandardPartsFromOffers(offers: StandardPartOfferDTO[] | undefined, rfqContext: RfqContext) {
    const otsPartIds = React.useMemo(() => {
        return offers?.filter((offer) => isOffTheShelfPartOffer(offer)).map((offer) => offer.linked_part.id);
    }, [offers]);

    const ipnIds = React.useMemo(() => {
        return offers?.filter((offer) => isInternalPartNumberOffer(offer)).map((offer) => offer.linked_part.id);
    }, [offers]);

    const { data: otsParts, isLoading: isLoadingOtsParts } = useOtsPartBulk(otsPartIds, rfqContext);
    const { data: ipns, isLoading: isLoadingIpns } = useIpnBulk(ipnIds, rfqContext);

    return useMemoCompare(
        () => {
            if (isLoadingOtsParts || isLoadingIpns) {
                return { data: undefined, ipns: undefined, otsParts: undefined, isLoading: true };
            }

            return { data: [...(otsParts ?? []), ...(ipns ?? [])], ipns, otsParts, isLoading: false };
        },
        { isLoadingOtsParts, isLoadingIpns, otsParts, ipns },
    );
}

export function useCustomPartsFromOffers(offers: CustomOptionOfferDTO[] | undefined, rfqContext: RfqContext) {
    const customPartIds = React.useMemo(() => {
        return offers?.filter((offer) => isCustomPartOffer(offer)).map((offer) => offer.linked_part.id);
    }, [offers]);

    const ipnIds = React.useMemo(() => {
        return offers?.filter((offer) => isCustomComponentOffer(offer)).map((offer) => offer.linked_part.id);
    }, [offers]);

    const { data: customParts, isLoading: isLoadingCustomParts } = useCustomPartsBulk(customPartIds);
    const { data: ipns, isLoading: isLoadingIpns } = useCustomComponentsBulk(ipnIds, rfqContext);

    return useMemoCompare(
        () => {
            if (isLoadingCustomParts || isLoadingIpns) {
                return { data: undefined, ipns: undefined, otsParts: undefined, isLoading: true };
            }

            return { data: [...(customParts ?? []), ...(ipns ?? [])], ipns, customParts, isLoading: false };
        },
        { isLoadingCustomParts, isLoadingIpns, customParts, ipns },
    );
}

export function useOtsFullPart({
    partOptionId,
    rfqContext,
}: {
    partOptionId: string | undefined;
    rfqContext: RfqContext;
}) {
    return useBulkSingleQuery('POST /parts/off-the-shelf/bulk', partOptionId, {
        idExtractor: (item: OtsFullPart) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export function useOtsPartBulk(ids: string[] | undefined, rfqContext: RfqContext) {
    return useBulkQuery(
        'POST /parts/off-the-shelf/bulk',
        ids,
        {
            idExtractor: (item: OtsFullPart) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
            },
            select: (res) => res.items,
        },
        { meta: { globalErrorHandler: false } },
    );
}

export function useIpn(
    id: string | undefined,
    rfqContext: RfqContext,
    queryOptions?: { refetchOnWindowFocus: boolean },
) {
    return useBulkSingleQuery(
        'POST /ipns/bulk',
        id,
        {
            idExtractor: (item: OtsComponentFull) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
            },
            select: (res) => res.items,
        },
        queryOptions,
    );
}

export function useIpnBulk(ids: string[] | undefined, rfqContext: RfqContext) {
    return useBulkQuery('POST /ipns/bulk', ids, {
        idExtractor: (item: OtsComponentFull) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export function useComponentsBulk(ids: string[] | undefined, rfqContext: RfqContext) {
    return useBulkQuery('POST /components/bulk', ids, {
        idExtractor: (item: OtsOrCustomComponent) => item.data.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export function useComponent(
    id: string | undefined,
    rfqContext: RfqContext,
    queryOptions?: { refetchOnWindowFocus: boolean },
) {
    return useBulkSingleQuery(
        'POST /components/bulk',
        id,
        {
            idExtractor: (item: OtsOrCustomComponent) => item.data.id,
            httpOptions: (ids) => {
                return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
            },
            select: (res) => res.items,
        },
        queryOptions,
    );
}

export function useFullStandardPartsBulk(
    standardParts: StandardPartDTO[],
    rfqId: string | undefined,
): { isLoading: boolean; fullParts: FullPart[]; isFetched: boolean } {
    const context = rfqId ? ({ rfq_id: rfqId, type: 'WithinRfQ' } as const) : ({ type: 'OutsideRfQ' } as const);
    const otsPartIds = React.useMemo(() => {
        return uniq(standardParts.filter((p) => p.type === StandardPartTypes.OffTheShelf).map((p) => p.data));
    }, [standardParts]);

    const {
        data: otsData,
        isLoading: isOtsPartsLoading,
        isFetched: isOtsPartsFetched,
    } = useOtsPartBulk(otsPartIds, context);
    /**
     * The useBulkQuery seems to "reload" the data whenever a call to the same endpoint is done with different parameters / ids.
        For instance here, a call was made with the part option ids and it seemed to return a new array of the otsParts response
        for the entire assembly which means that we'll be calling the expensive createBomItem function.
        https://gitlab.com/luminovo/product/epibator/-/merge_requests/7717#note_1556535583
        hence the use of useMemoCompare. This is a bit of a hack, we should properly investigate / fix this later.
        Note: useFullCustomPartsBulk bellow uses the same logic
     */
    const otsPartsResponse = useMemoCompare(() => otsData, { otsData });

    const genericPartIds = React.useMemo(() => {
        return uniq(standardParts.filter((p) => p.type === StandardPartTypes.Generic).map((p) => p.data));
    }, [standardParts]);

    const {
        data: genericPartsData,
        isLoading: isGenericPartsLoading,
        isFetched: isGenericPartsFetched,
    } = useGenericPartBulk(genericPartIds, context);

    const genericPartsResponse = useMemoCompare(() => genericPartsData, { genericPartsData });

    const ipnIds = React.useMemo(() => {
        return uniq(standardParts.filter((p) => p.type === StandardPartTypes.Ipn).map((p) => p.data));
    }, [standardParts]);

    const { data: ipnData, isLoading: isIpnLoading, isFetched: isIpnFetched } = useIpnBulk(ipnIds, context);

    const ipnResponse = useMemoCompare(() => ipnData, { ipnData });

    const fullParts = React.useMemo(() => {
        const otsParts: FullPart[] = otsPartsResponse ?? [];
        const genericParts: FullPart[] = genericPartsResponse ?? [];
        const ipns: FullPart[] = ipnResponse ?? [];
        return otsParts.concat(genericParts).concat(ipns);
    }, [otsPartsResponse, genericPartsResponse, ipnResponse]);

    const isFetched = isOtsPartsFetched && isIpnFetched && isGenericPartsFetched;

    return {
        isLoading: isOtsPartsLoading || isGenericPartsLoading || isIpnLoading,
        isFetched,
        fullParts,
    };
}

export function useFullCustomPartsBulk(
    customParts: CustomOptionVariantDTO[],
    rfqId: string | undefined,
): { isLoading: boolean; customParts: FullPart[]; isFetched: boolean } {
    const context = rfqId ? ({ rfq_id: rfqId, type: 'WithinRfQ' } as const) : ({ type: 'OutsideRfQ' } as const);
    const customPartIds = React.useMemo(() => {
        return uniq(customParts.filter((p) => p.type === CustomOptionTypes.CustomPart).map((p) => p.data));
    }, [customParts]);

    const {
        data: customPartData,
        isLoading: isCustomPartsLoading,
        isFetched: isCustomPartsFetched,
    } = useCustomPartsBulk(customPartIds);
    /**
     * Copied from: useFullStandardPartsBulk
     * The useBulkQuery seems to "reload" the data whenever a call to the same endpoint is done with different parameters / ids.
        For instance here, a call was made with the part option ids and it seemed to return a new array of the otsParts response
        for the entire assembly which means that we'll be calling the expensive createBomItem function.
        https://gitlab.com/luminovo/product/epibator/-/merge_requests/7717#note_1556535583
        hence the use of useMemoCompare. This is a bit of a hack, we should properly investigate / fix this later.
     */
    const customPartsResponse = useMemoCompare(() => customPartData, { customPartData });

    const customComponentIds = React.useMemo(() => {
        return uniq(customParts.filter((p) => p.type === CustomOptionTypes.CustomComponent).map((p) => p.data));
    }, [customParts]);

    const {
        data: customComponentsData,
        isLoading: isCustomComponentsLoading,
        isFetched: isCustomComponentsFetched,
    } = useCustomComponentsBulk(customComponentIds, context);

    const customComponentsResponse = useMemoCompare(() => customComponentsData, { customComponentsData });

    const allCustomParts = React.useMemo(() => {
        const customParts: FullPart[] = customPartsResponse ?? [];
        const customComponents: FullPart[] = customComponentsResponse ?? [];
        return customParts.concat(customComponents);
    }, [customPartsResponse, customComponentsResponse]);

    const isLoading = isCustomPartsLoading || isCustomComponentsLoading;
    const isFetched = isCustomPartsFetched && isCustomComponentsFetched;

    return {
        isLoading,
        isFetched,
        customParts: allCustomParts,
    };
}

function useGenericPartBulk(ids: string[], rfqContext: RfqContext) {
    return useBulkQuery('POST /parts/generic/bulk', ids, {
        idExtractor: (item: GenericFullPart) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export function useCustomPart(partId: string | undefined = '') {
    return useHttpQuery(
        'GET /parts/custom/:partId',
        { pathParams: { partId } },
        { enabled: Boolean(partId), select: (res) => res.data },
    );
}

export function useCustomPartResources(partId: string | undefined = '') {
    return useHttpQuery(
        'GET /parts/custom/:partId/resources',
        { pathParams: { partId } },
        { enabled: Boolean(partId), select: (res) => res.items },
    );
}

export function useCustomPartGetUploadFilesUrl(partId: string) {
    return useHttpQuery(
        'GET /parts/custom/:partId/upload',
        { pathParams: { partId } },
        {
            select: (data) => data.data.url,
        },
    );
}

export function useCustomPartsBulk(ids: string[] | undefined) {
    return useBulkQuery('POST /parts/custom/bulk', ids, {
        idExtractor: (item: CustomFullPart) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
}

export function useCustomComponentsBulk(ids: string[] | undefined, rfqContext: RfqContext) {
    return useBulkQuery('POST /components/custom/bulk', ids, {
        idExtractor: (item: CustomComponentFull) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}
export function useCustomComponent(
    id: string,
    rfqContext: RfqContext,
    queryOptions?: { refetchOnWindowFocus: boolean },
) {
    return useBulkSingleQuery(
        'POST /components/custom/bulk',
        id,
        {
            idExtractor: (item: CustomComponentFull) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
            },
            select: (res) => res.items,
        },
        queryOptions,
    );
}

/**
 * Recursively resolves any given part to its linked/associated OTS parts. Meaning:
 * - if it's a generic part it returns all the linked OTS parts.
 * - if it's an IPN part it returns all the linked parts recursively
 * - etc.
 *
 * Warning: this method is _very_ inefficient: don't use it in parts of the code that might be sensitive to performance.
 *
 * @returns all the OTS ids associated with the given `part`
 */
async function fetchAssociatedOtsParts(
    part: FullPart | undefined,
    token: string,
    rfqContext: RfqContext,
    depth = 0,
): Promise<OtsFullPart[]> {
    if (depth >= 3) {
        // The current maximum depth is 3, coming from an IPN => Generic => OTS
        logToExternalErrorHandlers(new Error(`depth > 3`));
        return [];
    }
    if (!part) {
        return [];
    }
    if (isOtsFullPart(part)) {
        return [part];
    }
    if (isGenericFullPart(part)) {
        const resp = await fetchMpnMatches({ genericPartId: part.id, rfqContext });
        return resp?.otsParts ?? [];
    }
    if (isOtsComponentFull(part)) {
        const promises = part.matches.flatMap((match) => {
            return fetchAssociatedOtsParts(match.part.data, token, rfqContext, depth + 1);
        });
        return (await Promise.all(promises)).flat();
    }
    if (isCustomFullPart(part) || isCustomComponentFull(part)) {
        return [];
    }
    assertUnreachable(part);
}

async function fetchAssociatedCustomParts(
    part: FullPart | undefined,
    token: string,
    rfqContext: RfqContext,
    depth = 0,
): Promise<CustomFullPart[]> {
    if (depth >= 3) {
        // The current maximum depth is 3, coming from an IPN => Generic => OTS
        logToExternalErrorHandlers(new Error(`depth > 3`));
        return [];
    }
    if (!part) {
        return [];
    }
    if (isCustomFullPart(part)) {
        return [part];
    }
    if (isCustomComponentFull(part)) {
        const promises = part.matches.flatMap((match) => {
            return fetchAssociatedCustomParts(match, token, rfqContext, depth + 1);
        });
        return (await Promise.all(promises)).flat();
    }
    if (isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part)) {
        return [];
    }
    assertUnreachable(part);
}

const queryKeyUseResolveAssociatedOtsParts = (parts: FullPart[]) => [
    // eslint-disable-next-line spellcheck/spell-checker
    'use-resolve-associated-ots-parts',
    parts
        .map((p) => p.id)
        .sort()
        .join(','),
];

const queryKeyUseResolveAssociatedCustomParts = (parts: FullPart[]) => [
    // eslint-disable-next-line spellcheck/spell-checker
    'use-resolve-associated-custom-parts',
    parts
        .map((p) => p.id)
        .sort()
        .join(','),
];

export const useResolveAssociatedOtsParts = (
    rfqContext: RfqContext,
    parts: FullPart[],
): UseQueryResult<OtsFullPart[]> => {
    const sortedParts = parts.sort((a, b) => a.id.localeCompare(b.id));
    return useQuery({
        queryKey: queryKeyUseResolveAssociatedOtsParts(sortedParts),
        queryFn: async () => {
            const promises = sortedParts.map((part) => {
                return fetchAssociatedOtsParts(part, getToken(), rfqContext);
            });
            const joinedPromise = await Promise.all(promises);
            return joinedPromise.flatMap((parts) => parts);
        },
        suspense: true,
    });
};

export const useResolveAssociatedCustomParts = (
    rfqContext: RfqContext,
    parts: FullPart[],
): UseQueryResult<OtsFullPart[]> => {
    const sortedParts = parts.sort((a, b) => a.id.localeCompare(b.id));
    return useQuery({
        queryKey: queryKeyUseResolveAssociatedCustomParts(sortedParts),
        queryFn: async () => {
            const promises = sortedParts.map((part) => {
                return fetchAssociatedCustomParts(part, getToken(), rfqContext);
            });
            const joinedPromise = await Promise.all(promises);
            return joinedPromise.flatMap((parts) => parts);
        },
        suspense: true,
    });
};

export const usePartOptionsFromSolutionConfigurationId = ({
    solutionConfigurationId,
    rfqId,
}: {
    solutionConfigurationId: string;
    rfqId: string;
}) => {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);
    const { data: designItems } = useDesignItems(solutionConfiguration?.bom_items.design_items.items);

    return useApprovedParts({
        partSpecifications: designItems?.map((item) => item.part_specification),
        rfqContext: { rfq_id: rfqId, type: 'WithinRfQ' },
    });
};

/**
 * Fetches all APPROVED parts that satisfy the given part specifications.
 *
 * @param rfqContext The RfQ this is called from. Needed to filter IPN / CPN data.
 * @param partSpecifications The part specifications to get the full part options from
 * @returns Returns a list of all parts that satisfy the given part specification. Notice that full parts will only be returned whenever the part specification’s approval status is Approved.
 */
export function useApprovedParts({
    partSpecifications,
    rfqContext,
}: {
    partSpecifications: (PartSpecification | null)[] | undefined;
    rfqContext: {
        type: 'WithinRfQ';
        rfq_id: string;
    };
}) {
    const otsPartIds = (partSpecifications ?? []).flatMap((spec) =>
        isPresent(spec) ? getApprovedOtsPartIds(spec) : [],
    );
    const genericPartIds = (partSpecifications ?? []).flatMap((spec) =>
        isPresent(spec) ? getApprovedGenericPartIds(spec) : [],
    );
    const ipnIds = (partSpecifications ?? []).flatMap((spec) => (isPresent(spec) ? getApprovedIpnIds(spec) : []));
    const customIpnIds = (partSpecifications ?? []).flatMap((spec) =>
        isPresent(spec) ? getCustomApprovedIpnIds(spec) : [],
    );
    const customPartIds = (partSpecifications ?? []).flatMap((spec) =>
        isPresent(spec) ? getApprovedCustomPartIds(spec) : [],
    );
    const { data: dataOtsParts = [], isLoading: isLoadingOtsParts } = useOtsPartBulk(otsPartIds, rfqContext);
    const { data: dataGenericParts = [], isLoading: isLoadingGenericParts } = useGenericPartBulk(
        genericPartIds,
        rfqContext,
    );
    const allComponentIds = [...ipnIds, ...customIpnIds];
    const { data: dataComponents = [], isLoading: isLoadingComponents } = useComponentsBulk(
        allComponentIds,
        rfqContext,
    );
    const { data: dataCustomParts = [], isLoading: isLoadingCustomParts } = useCustomPartsBulk(customPartIds);

    const isLoading = isLoadingGenericParts || isLoadingOtsParts || isLoadingCustomParts || isLoadingComponents;
    const allComponents = dataComponents.map((component) => component.data);

    return useMemoCompare(
        () => {
            if (!isPresent(partSpecifications)) {
                return { data: undefined, isLoading: true };
            }

            return {
                data: [...dataOtsParts, ...dataGenericParts, ...dataCustomParts, ...allComponents],
                isLoading,
            };
        },
        { isLoading, dataOtsParts, dataGenericParts, dataCustomParts, dataComponents },
    );
}

export function useCustomPartTypes(ignorePcbType: boolean, enabled: boolean = true) {
    return useHttpQuery(
        'GET /parts/custom-types',
        {},
        {
            enabled,
            select: (data) => {
                if (ignorePcbType) {
                    return data.items.filter((type) => type !== CustomPartTypeEnum.PCB);
                }
                return data.items;
            },
        },
    );
}

export function usePartPackages(tags?: PackageTag | undefined) {
    return useHttpQuery(
        'GET /parts/packages',
        { queryParams: isPresent(tags) ? { tags } : {} },
        {
            select: (res) => Array.from(res.items).sort((a, b) => compareByString(a.name ?? '', b.name ?? '')),
            suspense: true,
        },
    );
}

/**
 * Returns the ApprovalStatus of the part option with the given ID. If no such partId is found, then returns undefined.
 */
function getApprovalStatus(spec: PartSpecification, partId: string): ApprovalStatus | undefined {
    if (spec.type === PartSpecificationTypes.Custom) {
        return spec?.data.find((p) => p.part.data === partId)?.approval_status;
    }
    if (spec.type === PartSpecificationTypes.OffTheShelf) {
        return spec.data.part_options.find((p) => p.part.data === partId)?.approval_status;
    }
    assertUnreachable(spec);
}

/**
 * Takes an array of full parts as input and a part specification
 * and returns a match of all full parts that satisfy the part specification alongside the
 * approval status
 */
export const getFullPartsWithApprovalStatus = (
    specification: PartSpecification | null,
    partsMap: Map<string, FullPart>,
): FullPartWithApprovalStatus[] => {
    if (!specification) {
        return [];
    }

    const partIds = getPartIdsSet(specification, { filterByStatus: false });

    return Array.from(partIds)
        .map((partId) => partsMap.get(partId))
        .filter(isPresent)
        .map((part: FullPart): FullPartWithApprovalStatus => {
            const approvalStatus = getApprovalStatus(specification, part.id)!;
            if (isGenericFullPart(part)) {
                return {
                    type: 'Generic',
                    approvalStatus,
                    part,
                };
            }
            if (isCustomFullPart(part)) {
                return {
                    type: 'Custom',
                    approvalStatus,
                    part,
                };
            }
            if (isCustomComponentFull(part)) {
                return {
                    type: 'CustomComponent',
                    approvalStatus,
                    part,
                };
            }
            if (isOtsFullPart(part)) {
                return {
                    type: 'OffTheShelf',
                    approvalStatus,
                    part,
                };
            }
            if (isOtsComponentFull(part)) {
                return {
                    // eslint-disable-next-line spellcheck/spell-checker
                    type: 'Ipn',
                    approvalStatus,
                    part,
                };
            }

            return assertUnreachable(part);
        })
        .filter((p) => !!p.approvalStatus);
};

export function useOtsPartByMpn({ mpns, rfqContext }: { mpns: string[]; rfqContext: RfqContext }) {
    return useHttpQuery(
        'POST /parts/off-the-shelf/search/mpn/bulk',
        {
            requestBody: {
                items: mpns,
                use_elastic: true,
                search_external_apis: false,
                size: 200,
                ...convertRfqContext(rfqContext),
            },
        },
        {
            select: (res) => Object.entries(res.data).map(([key, value]) => ({ alternativeMpn: key, matches: value })),
        },
    );
}
