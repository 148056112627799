import { Trans } from '@lingui/macro';
import { TertiaryIconButton } from '@luminovo/design-system';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const scrollToId = (id: string) => {
    // using window.scrollTo(0,0) didn't work so here I scroll to specific element instead
    const element = document.getElementById(id);
    if (element !== null) {
        element.scrollIntoView({ block: 'center' });
    }
};

interface Pagination {
    start: number;
    end: number;
}

interface PaginationButtonsProps {
    numberOfRows: number;
    topBoxId: string;
    setPagination: (pagination: Pagination) => void;
    pagination: Pagination;
    linesPerPage: number;
}
const PaginationButtons: React.FunctionComponent<PaginationButtonsProps> = ({
    numberOfRows,
    topBoxId,
    pagination,
    setPagination,
    linesPerPage,
}: PaginationButtonsProps): JSX.Element => {
    return (
        <Box display={'flex'} alignItems="center">
            <Typography>
                <Trans>
                    {pagination.start + 1}- {pagination.end >= numberOfRows ? numberOfRows : pagination.end} of{' '}
                    {numberOfRows}{' '}
                </Trans>
            </Typography>
            <TertiaryIconButton
                disableRipple={true}
                disabled={pagination.start < 1}
                onClick={() => {
                    scrollToId(topBoxId);
                    setPagination({
                        start: pagination.start - linesPerPage,
                        end: pagination.end - linesPerPage,
                    });
                }}
            >
                <KeyboardArrowLeftIcon fontSize="inherit" />
            </TertiaryIconButton>
            <TertiaryIconButton
                disableRipple={true}
                disabled={pagination.end >= numberOfRows}
                onClick={() => {
                    scrollToId(topBoxId);
                    setPagination({
                        start: pagination.start + linesPerPage,
                        end: pagination.end + linesPerPage,
                    });
                }}
            >
                <KeyboardArrowRightIcon fontSize="inherit" />
            </TertiaryIconButton>
        </Box>
    );
};

export default PaginationButtons;
