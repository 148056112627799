import { t, Trans } from '@lingui/macro';
import { colorSystem, createColumnHelper, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { OtsFullPart } from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { Done } from '@mui/icons-material';
import { Box, LinearProgress } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmptyQuery } from '../../../../components/SearchContainer';
import { PartSearchInput, usePartSearchState } from '../../../PartLibrary/PartSearchPage/PartSearchInput';
import { SearchResultCount } from '../../../PartLibrary/PartSearchPage/PartSearchPage';
import { useSearchParts } from '../../../PartLibrary/PartSearchPage/useSearchParts';
import { useConditionalValidation } from '../hooks/useConditionalValidation';
import { QuotedLineItem, StandardPartQuoteFormValues } from '../types';
import { EditableCell } from './EditableCell';
import { StandardPartThumbnail } from './StandardPartThumbnail';

const columnHelper = createColumnHelper<QuotedLineItem, unknown>();

export const columnOfferedPart = columnHelper.text((row) => formatPart(row.offer?.offeredPart), {
    id: 'offeredPart',
    label: () => t`Offered part`,
    size: 200,
    enableColumnFilter: true,
    enableSorting: false,
    renderType: 'generic',
    disableTableCell: true,
    cell: function Cell({ cell }) {
        const { setValue, control } = useFormContext<StandardPartQuoteFormValues>();
        const index = cell.row.index;
        const part = cell.row.original.offer?.offeredPart;

        const validate = useConditionalValidation(index, (x: unknown) => {
            if (!x) {
                return t`Required`;
            }
            return undefined;
        });

        return (
            <EditableCell
                onChange={(part) => setValue(`lineItems.${index}.offer.offeredPart`, part, { shouldValidate: true })}
                Field={PartSearchField}
                value={part}
            >
                <Controller
                    control={control}
                    name={`lineItems.${index}.offer.offeredPart`}
                    rules={{
                        validate,
                    }}
                    render={({ field, fieldState }) => {
                        const part = field.value;
                        if (fieldState.error) {
                            return (
                                <Text variant="body-small" color={colorSystem.red[8]}>
                                    {fieldState.error.message}
                                </Text>
                            );
                        }
                        return <>{formatPart(part)}</>;
                    }}
                />
            </EditableCell>
        );
    },
});

function PartSearchField({
    value,
    onChange,
}: {
    value: OtsFullPart | undefined;
    onChange: (value: OtsFullPart | undefined) => void;
}) {
    const searchState = usePartSearchState({
        history: undefined,
    });

    const {
        data: searchResults,
        isFetching,
        fetchNextPage,
    } = useSearchParts({ searchState, rfqContext: { rfq_context: 'OutsideRfQ' } });

    const lastPage = isEmptyQuery(searchState) ? undefined : searchResults?.pages[searchResults.pages.length - 1];

    return (
        <Flexbox minWidth="600px" flexDirection={'column'} gap="16px">
            <Text variant="h1">
                <Trans>Find and select a part to offer</Trans>
            </Text>

            <PartSearchInput searchState={searchState} />

            {lastPage && (
                <>
                    <SearchResultCount searchResults={lastPage} />
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto 1fr auto',
                            width: '100%',
                            gap: 3,
                            alignItems: 'center',
                        }}
                    >
                        <Text variant="h4"></Text>
                        <Text variant="h4">
                            <Trans>Part</Trans>
                        </Text>
                        <Text variant="h4">
                            <Trans>Description</Trans>
                        </Text>
                        <Text variant="h4">
                            <Trans>Action</Trans>
                        </Text>
                        {searchResults?.pages
                            .flatMap((page) => page.page)
                            .map((part) => {
                                return (
                                    <React.Fragment key={part.id}>
                                        <StandardPartThumbnail parts={[part]} size={50} />
                                        <Text>{formatPart(part)}</Text>
                                        <Text showEllipsis>{part.description}</Text>
                                        <SecondaryButton startIcon={<Done />} onClick={() => onChange(part)}>
                                            <Trans>Select</Trans>
                                        </SecondaryButton>
                                    </React.Fragment>
                                );
                            })}
                    </Box>
                </>
            )}

            {isFetching && !isEmptyQuery(searchState) && <LinearProgress />}

            {lastPage && (
                <Flexbox justifyContent="center">
                    <SecondaryButton onClick={() => fetchNextPage()}>
                        <Trans>Load more</Trans>
                    </SecondaryButton>
                </Flexbox>
            )}
        </Flexbox>
    );
}
