import { assertUnreachable } from '@luminovo/commons';
import { DriverDTO } from '@luminovo/http-client';
import { getSystemDriverName } from './getSystemDriverName';

export const selectDriverName = (driver: DriverDTO): string => {
    const type = driver.type;
    switch (type) {
        case 'User':
            return driver.data.name;
        case 'System':
            return getSystemDriverName(driver.data);
        default:
            assertUnreachable(type);
    }
};
