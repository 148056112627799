import { RfqDTO, RfqStatus, UserType } from '@luminovo/http-client';
import { rfqStatusesThatAreNonEditable } from '@luminovo/manufacturing-core';
import { useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import { useIsRfqEditable } from '../../../utils/rfqUtils';

// Request based on https://luminovo.slack.com/archives/C04ADAL3WBC/p1711460442484579
const rfqStatusesThatAreNonEditableForCustomers = [...rfqStatusesThatAreNonEditable, RfqStatus.QuotationInProgress];

export const useIsPcbEditable = (rfq: RfqDTO | undefined) => {
    const userType = useUserType();
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    if (userType === UserType.Customer && rfq) {
        return {
            isRfqEditable: isRfqEditable && !rfqStatusesThatAreNonEditableForCustomers.includes(rfq.status),
        };
    } else {
        return {
            isRfqEditable: isRfqEditable,
        };
    }
};
