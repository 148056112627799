import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    FileLifecycle,
    PCBGraphicDrillHole,
    PCBGraphicLayer,
    PCBGraphicOutline,
    PCBGraphicType,
    PCBGraphics,
    PCBGraphicsArray,
    PCBV2,
} from '@luminovo/http-client';
import { isMainLifecycleError, isPcbAnalysisInProgress, isPcbSetupWithoutFiles } from './pcbValidationUtils';

export function pcbGraphicsFromArray(graphicsData: PCBGraphicsArray): PCBGraphics | undefined {
    let graphics: PCBGraphics | undefined;
    let outline: PCBGraphicOutline | undefined;
    let holeLists: PCBGraphicDrillHole[] = [];
    const layerGraphics: PCBGraphicLayer[] = [];

    for (const layer of graphicsData) {
        const layerType = layer.type;
        switch (layerType) {
            case PCBGraphicType.LAYER:
                const layerGraphic = { ...layer.data };

                if (layer.graphic) {
                    layerGraphic.graphic = layer.graphic;
                }

                layerGraphics.push(layerGraphic);
                break;

            case PCBGraphicType.DRILLS:
                holeLists = holeLists.concat(layer.data);
                break;

            case PCBGraphicType.OUTLINE:
                if (!outline) {
                    outline = layer.data;
                }
                break;

            default:
                assertUnreachable(layerType);
        }
    }

    if (outline && layerGraphics.length > 0) {
        graphics = {
            outline: outline,
            layers: layerGraphics,
            drillHoles: holeLists,
        };
    }

    return graphics;
}

type PreviewStatus = 'loading' | 'failed' | 'done-intermediate' | 'done' | 'without-files';

export function getImagePreviewStatus(pcb: PCBV2): PreviewStatus {
    let specificationRender: FileLifecycle | undefined;
    let reconciledSpecificationRender: FileLifecycle | undefined;

    for (const lifecycle of pcb.lifecycles) {
        switch (lifecycle.name) {
            case 'specification-render':
                specificationRender = lifecycle;
                break;
            case 'reconciled-specification-render':
                reconciledSpecificationRender = lifecycle;
                break;
        }
    }

    const isWithoutFiles = isPcbSetupWithoutFiles(pcb);

    if (isWithoutFiles) {
        return 'without-files';
    }

    const mainLifecycleError = isMainLifecycleError(pcb);

    if (isPresent(reconciledSpecificationRender)) {
        const lifecycleStatus = reconciledSpecificationRender.status.name;

        switch (lifecycleStatus) {
            case 'success':
                return 'done';

            case 'waiting':
            case 'progress': {
                if (mainLifecycleError) {
                    return 'failed';
                }

                return 'loading';
            }

            case 'error':
            case 'timeout':
            case 'unknown':
                return 'failed';

            default:
                assertUnreachable(lifecycleStatus);
        }
    }

    if (isPresent(specificationRender)) {
        const lifecycleStatus = specificationRender.status.name;

        switch (lifecycleStatus) {
            case 'success':
                return 'done-intermediate';

            case 'waiting':
            case 'progress': {
                if (mainLifecycleError) {
                    return 'failed';
                }

                return 'loading';
            }

            case 'error':
            case 'timeout':
            case 'unknown':
                return 'failed';

            default:
                assertUnreachable(lifecycleStatus);
        }
    }

    if (isPcbAnalysisInProgress(pcb) && !mainLifecycleError) {
        return 'loading';
    }

    return 'failed';
}
