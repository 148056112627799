import { Trans, t } from '@lingui/macro';
import { SecondaryButton, Tooltip } from '@luminovo/design-system';
import { RemoveCircle } from '@mui/icons-material';
import { useUniversalImporter } from '../../context';

export function ButtonExcludeErrors(): JSX.Element {
    const { dispatch, state } = useUniversalImporter();

    const count = state.importerTable?.getParseStatusCount().error ?? 0;

    return (
        <Tooltip title={t`Exclude ${count} rows with errors`}>
            <SecondaryButton
                size="medium"
                startIcon={<RemoveCircle fontSize="small" />}
                onClick={() => {
                    dispatch({ type: 'setImporterTable', table: state.importerTable?.excludeErrors() });
                }}
            >
                <Trans>Exclude errors</Trans>
            </SecondaryButton>
        </Tooltip>
    );
}
