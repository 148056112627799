import { Box, BoxProps, styled } from '@mui/material';
import * as React from 'react';
import { MAX_WIDTH } from '../../../theme';

const MaxWidthContainer = styled(Box)({
    width: '100%',
});

export interface MaxWidthLayoutProps extends BoxProps {
    innerMaxWidth?: number | string;
}

export const MaxWidthLayout: React.FunctionComponent<MaxWidthLayoutProps> = ({
    children,
    innerMaxWidth = MAX_WIDTH,
    ...props
}) => {
    return (
        <Box width="100%" display="flex" justifyContent="center" padding={'24px'} boxSizing="border-box" {...props}>
            <MaxWidthContainer style={{ maxWidth: innerMaxWidth }}>{children}</MaxWidthContainer>
        </Box>
    );
};
