import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Column, DataTable, Row, Tag, useDataTableState } from '@luminovo/design-system';
import { MissingTag } from '@luminovo/manufacturing-core';
import { CircularProgress, TableCell } from '@mui/material';
import { usePartPackages } from '../../../resources/part/partHandler';
import { PnpTableItem } from './types';

export const PnpTable = ({ items }: { items: PnpTableItem[] }): JSX.Element => {
    const { columns, isLoading } = usePnpColumns();
    const dataTableState = useDataTableState({
        persistenceId: 'pnp-table',
        items,
        columns: columns,
        paginationOptions: {
            showPagination: true,
            defaultRowsPerPage: 200,
        },
    });

    if (isLoading) {
        return <CircularProgress />;
    }

    return <DataTable tableState={dataTableState} size="medium" />;
};

const usePnpColumns = (): { columns: Column<PnpTableItem>[]; isLoading: boolean } => {
    const { data = [], isLoading } = usePartPackages('user-selectable');
    const allMountings: string[] = [...new Set(data.map((p) => p.mounting).filter(isPresent))];
    return { columns: getPnpTableColumns(allMountings), isLoading: isLoading };
};

const getPnpTableColumns = (allMountings: string[]): Column<PnpTableItem>[] => {
    return [
        {
            id: 'designator',
            label: (
                <>
                    <Trans>Designator </Trans>
                    <Tag label={'BOM & PnP'} color="neutral" attention="low" />
                </>
            ),
            render: ({ data: u }: Row<PnpTableItem>) => <TableCell>{u.designator}</TableCell>,
            searchable: {
                searchBy: (x) => x.designator,
            },
        },
        {
            id: 'mounting',
            label: (
                <>
                    <Trans>Mounting </Trans>
                    <Tag label={'BOM'} color="neutral" attention="low" />
                </>
            ),
            render: ({ data: u }: Row<PnpTableItem>) => {
                return (
                    <TableCell>{u.mounting === 'Missing' ? <MissingTag color={'neutral'} /> : u.mounting}</TableCell>
                );
            },
            filters: [
                {
                    id: 'mounting=Missing',
                    label: <Trans>Missing</Trans>,
                    predicate: (item) => item.mounting === 'Missing',
                },
                ...allMountings.map((mounting) => ({
                    id: `mounting=${mounting}`,
                    label: mounting,
                    predicate: (item: PnpTableItem) => item.mounting === mounting,
                })),
            ],
        },
        {
            id: 'side',
            label: (
                <>
                    <Trans>Side </Trans>
                    <Tag label={'PnP'} color="neutral" attention="low" />
                </>
            ),
            render: ({ data: u }: Row<PnpTableItem>) => (
                <TableCell>{u.side === 'Missing' ? <MissingTag color="yellow" /> : u.side}</TableCell>
            ),
            filters: [
                {
                    id: 'side=Top',
                    label: <Trans>Top</Trans>,
                    predicate: (item: PnpTableItem) => item.side === 'Top',
                },
                {
                    id: 'side=Bottom',
                    label: <Trans>Bottom</Trans>,
                    predicate: (item: PnpTableItem) => item.side === 'Bottom',
                },
                {
                    id: 'side=Missing',
                    label: <Trans>Missing</Trans>,
                    predicate: (item: PnpTableItem) => item.side === 'Missing',
                },
            ],
        },
    ];
};
