/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type BuerklinLanguageDTO = r.Static<typeof BuerklinLanguageDTORuntype>;
const BuerklinLanguageDTORuntype = r.Union(r.Literal('en'), r.Literal('de'));

export interface BuerklinCredentialsDTO extends r.Static<typeof BuerklinCredentialsDTORuntype> {}
export const BuerklinCredentialsDTORuntype = r.Union(
    r.Record({
        username: r.String,
        password: r.String,
        language: BuerklinLanguageDTORuntype,
    }),
);

export interface BuerklinCredentialsInputDTO extends r.Static<typeof BuerklinCredentialsInputDTORuntype> {}
export const BuerklinCredentialsInputDTORuntype = r.Record({
    username: r.String.optional(),
    password: r.String.optional(),
    language: BuerklinLanguageDTORuntype.optional(),
});

export interface BuerklinResponseBodyDTO extends r.Static<typeof BuerklinResponseBodyDTORuntype> {}
export const BuerklinResponseBodyDTORuntype = r.Record({
    data: BuerklinCredentialsDTORuntype,
    status: ApiIntegrationStatusRuntype,
});
