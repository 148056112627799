/* eslint-disable spellcheck/spell-checker*/
/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Message, Tag } from '@luminovo/design-system';
import { ComponentSpecificationWithFullPackage, OtsComponentFull, SpecificationsConflict } from '@luminovo/http-client';

export const getComponentSpecificationsChips = (ipn: OtsComponentFull | undefined) => {
    if (ipn === undefined) {
        return null;
    }

    const specs = ipn.component_specifications;

    const keysToCheck = ['edited' as const, 'imported' as const, 'inferred' as const];

    const presentComponentSpecifications = keysToCheck.reduce((acc: ('edited' | 'imported' | 'inferred')[], key) => {
        const spec: ComponentSpecificationWithFullPackage | undefined = specs ? specs[key] : undefined;
        if (isPresent(spec?.form_and_fit) || isPresent(spec?.function_specification)) {
            acc.push(key);
        }
        return acc;
    }, []);

    const makeChip = (label: JSX.Element, react_key: string) => (
        <Tag key={react_key} color="neutral" label={label} attention="low" />
    );
    const chips = [];

    if (presentComponentSpecifications.includes('edited')) {
        chips.push(makeChip(<Trans>Edited</Trans>, 'edited'));
    } else {
        if (presentComponentSpecifications.includes('imported')) {
            chips.push(makeChip(<Trans>Imported</Trans>, 'imported'));
        }
        if (presentComponentSpecifications.includes('inferred')) {
            chips.push(makeChip(<Trans>Inferred</Trans>, 'inferred'));
        }
    }

    return <div style={{ display: 'flex', gap: '2px' }}>{chips}</div>;
};

export const renderComponentSpecificationWarning = (conflicts: SpecificationsConflict) => {
    if (
        conflicts.form_and_fit ||
        conflicts.function_specification_type ||
        conflicts.function_specification_keys.length > 0
    ) {
        return (
            <Message
                variant={'red'}
                title={t`Specification mismatch`}
                size="large"
                attention="low"
                message={t`There are discrepancies between the component specifications, whether edited manually, inferred from linked parts, or imported via ERP data.`}
            />
        );
    } else {
        return null;
    }
};
