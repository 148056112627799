import { IdWithIndex } from '@luminovo/http-client';

export const createOrderedIdsFromSelectedIds = (orderedIds: IdWithIndex[], selectedIds: string[]): IdWithIndex[] => {
    const orderedIdsSet = new Set(orderedIds.map((id) => id.id));
    const selectedIdsSet = new Set(selectedIds);

    // We reverse the selectedIds because in the array the id that is selected last is the first element
    const newIds = selectedIds.filter((id) => !orderedIdsSet.has(id)).reverse();
    const deletedIds = orderedIds.filter((id) => !selectedIdsSet.has(id.id));
    const newAndDeletedIdsSet = new Set([...newIds, ...deletedIds]);
    const unchangedIds = orderedIds.filter((id) => !newAndDeletedIdsSet.has(id));

    const newIdsWithIndex: IdWithIndex[] = newIds.map((id, i) => ({ id, index: i + unchangedIds.length }));
    const newOrderedIds = [...unchangedIds, ...newIdsWithIndex];

    return newOrderedIds;
};
