import { Tag } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { rfqStatusTranslations } from '@luminovo/sourcing-core';
import { Box, Divider, Grid, GridProps, GridSpacing } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    CARD_SHADOW,
    GREY_BORDER,
    PADDING_X_DASHBOARD_BOX,
    PADDING_Y_DASHBOARD_BOX,
    SPACING_EXTRA_SMALL,
    SPACING_SMALL,
    colorSystem,
} from '../themes';
import { assertUnreachable } from '../utils/typingUtils';
import { transEnum } from './localization/TransEnum';

export const DashboardCard = styled(Box)({
    border: GREY_BORDER,
    borderRadius: SPACING_SMALL,
    boxShadow: CARD_SHADOW,
    boxSizing: 'border-box',
    padding: `${PADDING_Y_DASHBOARD_BOX * 8}px ${PADDING_X_DASHBOARD_BOX * 8}px`,
    height: '100%',
});

export const PartCardBox = styled(Box)({
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 4,
    background: colorSystem.neutral.white,
    padding: 0,
});

export const PartCardDivider = styled(Divider)({
    margin: 0,
    backgroundColor: colorSystem.neutral[2],
});

export const StyledGridNoPaddingRight = styled(Grid)({
    padding: SPACING_EXTRA_SMALL,
    paddingRight: '0rem',
});

function rfqStatusColor(status: RfqStatus): keyof typeof colorSystem {
    switch (status) {
        case RfqStatus.RequestInDraft:
            return 'blue';
        case RfqStatus.QuotationInProgress:
            return 'primary';
        case RfqStatus.QuotationAvailable:
            return 'green';
        case RfqStatus.NoQuotation:
            return 'neutral';
        case RfqStatus.NoOrder:
        case RfqStatus.PaymentFailed:
            return 'red';
        case RfqStatus.OrderPlaced:
            return 'green';
        case RfqStatus.OrderConfirmed:
            return 'green';
        case RfqStatus.OrderInProduction:
            return 'primary';
        case RfqStatus.OrderShipped:
        case RfqStatus.OrderInProcurement:
            return 'green';
        default:
            return assertUnreachable(status);
    }
}

export const StyledRfqStatusBox = ({ rfqStatus }: { rfqStatus: RfqStatus }): JSX.Element => {
    return (
        <Tag attention={'low'} color={rfqStatusColor(rfqStatus)} label={transEnum(rfqStatus, rfqStatusTranslations)} />
    );
};

const StyledFlexAndCentredVerticallyBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

export const StyledDivider = styled(Divider)({
    marginTop: SPACING_SMALL,
    color: colorSystem.neutral[3],
});

interface CenterIconWithTextProps extends GridProps {
    icon: JSX.Element;
    text: JSX.Element;
    spacing: GridSpacing;
    alignItems?: GridProps['alignItems'];
}

export const CenterIconWithText = (props: CenterIconWithTextProps): JSX.Element => {
    const { icon, text, spacing } = props;
    return (
        <Grid container spacing={spacing} direction={'row'} alignItems={props.alignItems || 'center'} wrap={'nowrap'}>
            <Grid item>
                <StyledFlexAndCentredVerticallyBox>{icon}</StyledFlexAndCentredVerticallyBox>
            </Grid>
            <Grid item>{text}</Grid>
        </Grid>
    );
};
