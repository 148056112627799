import { ContainersWithFiles } from '../components/LayerAssignment/utils/layerAssignmentsUtils';

type State = {
    isEditing: boolean;
    isDirty: boolean;
    items: ContainersWithFiles;
};

type Action =
    | { type: 'SET_EDITING'; payload: boolean }
    | { type: 'SET_DIRTY'; payload: boolean }
    | { type: 'SET_ITEMS'; payload: ContainersWithFiles | ((prev: ContainersWithFiles) => ContainersWithFiles) };

export function fileDnDReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_EDITING':
            return { ...state, isEditing: action.payload };
        case 'SET_DIRTY':
            return { ...state, isDirty: action.payload };
        case 'SET_ITEMS':
            return {
                ...state,
                items: typeof action.payload === 'function' ? action.payload(state.items) : action.payload,
            };
        default:
            return state;
    }
}
