import { assertUnreachable } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import {
    AssemblyIndustry,
    ComplianceStatus,
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { ComplianceChip, ComplianceOverviewChips } from '@luminovo/sourcing-core';
import { OtsPartComplianceStatusOriginsTooltip } from './OtsPartComplianceStatusOriginsTooltip';

export const PartComplianceView = ({
    part,
    assemblyIndustry,
    disabled = false,
}: {
    part: FullPart;
    assemblyIndustry?: AssemblyIndustry;
    disabled?: boolean;
}): JSX.Element => {
    if (isCustomFullPart(part) || isCustomComponentFull(part)) {
        return <></>;
    }
    const reach = part.reach_compliant;
    const rohs = part.rohs_compliant;
    const aecq = part.aecq_compliant;
    const isAutomotiveAssembly = assemblyIndustry === 'Auto';
    if (isOtsFullPart(part)) {
        return (
            <OtsPartComplianceOverviewChips
                partId={part.id}
                reach={reach}
                rohs={rohs}
                aecq={isAutomotiveAssembly ? aecq : undefined}
                disabled={disabled}
            />
        );
    }
    if (isOtsComponentFull(part) || isGenericFullPart(part)) {
        return (
            <ComplianceOverviewChips
                reach={reach}
                rohs={rohs}
                aecq={isAutomotiveAssembly ? aecq : undefined}
                disabled={disabled}
            />
        );
    }
    assertUnreachable(part);
};

export const OtsPartComplianceOverviewChips = ({
    partId,
    reach,
    rohs,
    aecq,
    disabled = false,
}: {
    partId: string;
    reach?: ComplianceStatus;
    rohs?: ComplianceStatus;
    aecq?: ComplianceStatus;
    disabled?: boolean;
}): JSX.Element => {
    return (
        <Flexbox gap={4}>
            {reach && (
                <OtsPartComplianceStatusOriginsTooltip partId={partId} name="REACH" complianceStatus={reach}>
                    <ComplianceChip complianceStatus={reach} name="REACH" disabled={disabled} disabledTooltip={true} />
                </OtsPartComplianceStatusOriginsTooltip>
            )}
            {rohs && (
                <OtsPartComplianceStatusOriginsTooltip partId={partId} name="RoHS" complianceStatus={rohs}>
                    <ComplianceChip complianceStatus={rohs} name="RoHS" disabled={disabled} disabledTooltip={true} />
                </OtsPartComplianceStatusOriginsTooltip>
            )}
            {aecq && (
                <OtsPartComplianceStatusOriginsTooltip partId={partId} name="AECQ" complianceStatus={aecq}>
                    <ComplianceChip complianceStatus={aecq} name="AECQ" disabled={disabled} disabledTooltip={true} />
                </OtsPartComplianceStatusOriginsTooltip>
            )}
        </Flexbox>
    );
};
