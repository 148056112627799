import { TertiaryIconButton } from '@luminovo/design-system';
import { Close } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useOrganizationSolutionPreferences } from '../resources/organizationSettings/organizationSolutionPreferenceHandler';
import { route } from './routes';
import { usePersistedState } from './usePersistedState';

export function useWarnIfSuppliersNotConfigured({ enabled }: { enabled: boolean } = { enabled: true }) {
    const { data: prefs, isLoading } = useOrganizationSolutionPreferences();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackbarKey = 'preferred-or-approved-suppliers-configured';
    const history = useHistory();
    // we use strings instead of booleans as usePersistedState only stores strings.
    const [hideWarning, setHideWarning] = usePersistedState<'true' | 'false'>(
        'hide-suppliers-not-configured-warning',
        'false',
    );

    const numApprovedSuppliers = prefs?.approved_suppliers_and_stock_locations?.length ?? 0;
    const numPreferredSuppliers = prefs?.preferred_suppliers_and_stock_locations?.length ?? 0;
    const isSuppliersConfigured = prefs && (numApprovedSuppliers > 0 || numPreferredSuppliers > 0);

    const showWarning = !isLoading && enabled && hideWarning !== 'true' && !isSuppliersConfigured;

    React.useEffect(() => {
        if (showWarning) {
            enqueueSnackbar('', {
                persist: true,
                key: snackbarKey,
                preventDuplicate: true,
                anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
                variant: 'warning',
                content: (
                    <Alert
                        severity="warning"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            closeSnackbar(snackbarKey);
                            history.push(route('/settings/organization'));
                        }}
                        action={
                            <TertiaryIconButton
                                color="inherit"
                                size="medium"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closeSnackbar(snackbarKey);
                                    setHideWarning('true');
                                }}
                            >
                                <Close fontSize="inherit" />
                            </TertiaryIconButton>
                        }
                    >
                        <strong>Stock information missing</strong>
                        <br />
                        <Typography>
                            Stock information not available due to missing supplier configuration. Click to configure.
                        </Typography>
                    </Alert>
                ),
            });
        } else {
            closeSnackbar(snackbarKey);
        }
    }, [closeSnackbar, enqueueSnackbar, history, setHideWarning, showWarning]);
}
