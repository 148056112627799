import React from 'react';
import { colorSystem } from '../../../theme';
import { CommentContent, MentionComponent } from '../types';
import { parseCommentString } from './parseCommentString';

export function renderMarkup(
    content: CommentContent,
    { MentionComponent }: { MentionComponent: MentionComponent },
): React.ReactNode {
    const str = content.content;
    return parseCommentString(str)
        .map((token) => {
            switch (token.type) {
                case 'text':
                case 'link': {
                    return token.content;
                }
                case 'mention': {
                    return <MentionComponent id={token.id} label={token.label} />;
                }
                case 'quote': {
                    return (
                        <div
                            style={{
                                padding: 4,
                                paddingLeft: 8,
                                borderLeft: `4px solid ${colorSystem.neutral[2]}`,
                            }}
                        >
                            {
                                // TODO(fhur): recursively apply the parsing logic to support @-mentions in quotes and bullet points
                                // This is definitely an imperfect solution as it has performance implications and
                                // also some gotchas like nested bullet points
                                renderMarkup({ type: 'string.v1', content: token.content }, { MentionComponent })
                            }
                        </div>
                    );
                }
                default: {
                    // @ts-expect-error
                    throw new Error('unhandled case: ' + token.type);
                }
            }
        })
        .map((r, i) => <React.Fragment key={i}>{r}</React.Fragment>);
}
