import { t, Trans } from '@lingui/macro';
import { compareByDate } from '@luminovo/commons';
import {
    colorSystem,
    FieldCheckbox,
    FieldController,
    Flexbox,
    FormItem,
    FormSection,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { AssemblyIndustry, AssemblyTypePublic } from '@luminovo/http-client';
import { FieldIndustrySelect, formatAdditionalServiceTranslation } from '@luminovo/manufacturing-core';
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { FormItemAssemblyTypeRadio } from '../../modules/AssemblyForm/components/FieldAssemblyTypeRadioControlled';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { themeLuminovo } from '../../themes';
import { id } from '../../utils/ids';
import { useCurrentUserDetailsContext } from '../contexts/CurrentUserDetailsContext';
import { FormContainer, ValidationErrors } from '../formLayouts/FormContainer';
import { TextFieldController } from '../formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../formLayouts/SubmitButton';
import { OrderScenario, OrderScenariosFormItem } from './OrderScenariosFormItem';
import { RfqNameFormItem } from './RfqNameFormItem';

interface Props {
    title: string;
    onCancel: () => void;
    onSubmit: SubmitHandler<CustomerRfqFormState>;
    defaultValues: CustomerRfqFormState;
    variant: 'create' | 'edit';
}

export interface CustomerRfqFormState {
    rfqName: string;
    comment: string;
    orders: OrderScenario[];
    industry: AssemblyIndustry | '';
    additionalServiceIds: string[];
    assemblyType: AssemblyTypePublic;
}

export const CustomerRfqForm: React.FunctionComponent<Props> = ({ onSubmit, ...props }: Props): JSX.Element => {
    const validationErrors: ValidationErrors<CustomerRfqFormState> = {
        'rfq.customer_does_not_exist': {
            fieldPath: 'root.serverError',
        },
        'rfq.duplicate_order_sizes': {
            fieldPath: 'root.serverError',
        },
        'rfq.cannot_update_archived': {
            fieldPath: 'root.serverError',
        },
        'rfq.not_found': {
            fieldPath: 'root.serverError',
        },
        'rfq.request_in_draft_only_allowed_if_customer_portal_is_enabled': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_name': {
            fieldPath: 'rfqName',
        },
        'rfq.cannot_create_customer_as_customer_user': {
            fieldPath: 'root.serverError',
        },
        'rfq.cannot_create_rfq_for_different_customer': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_assembly_name': {
            fieldPath: 'rfqName',
        },
        'rfq.invalid_customer_name': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_order_size': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_order_date': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_rfq_number': {
            fieldPath: 'root.serverError',
        },
        'rfq.customer_user_has_no_customer': {
            fieldPath: 'root.serverError',
        },
        'rfq.unauthorized': {
            fieldPath: 'root.serverError',
        },
        'rfq.workflow_changes_only_allowed_by_ems': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer onSubmit={onSubmit} defaultValues={props.defaultValues} validationErrors={validationErrors}>
            <InnerCustomerRfqForm {...props} />
        </FormContainer>
    );
};

function InnerCustomerRfqForm({
    title,
    onCancel,
    variant,
    defaultValues,
}: {
    title: string;
    onCancel: () => void;
    variant: 'create' | 'edit';
    defaultValues: CustomerRfqFormState;
}) {
    const { control } = useFormContext();

    return (
        <Flexbox
            flexDirection={'column'}
            gap={20}
            borderRadius={8}
            maxWidth="960px"
            style={{
                backgroundColor: themeLuminovo.palette.background.default,
            }}
        >
            <FormSection title={<Typography variant="h1">{title}</Typography>}>
                <span />
            </FormSection>

            <Divider />

            <FormSection id={id('oem_create_rfq/project_name')} title={t`General information`}>
                <RfqNameFormItem control={control} name="rfqName" currentRfqName={defaultValues.rfqName || undefined} />

                <FormItem label={t`Industry`} required>
                    <FieldController
                        control={control}
                        name="industry"
                        Field={FieldIndustrySelect}
                        FieldProps={{ id: id('oem_create_rfq/form_item_industry') }}
                        validate={(x: unknown) => (Boolean(x) ? undefined : t`Required`)}
                    />
                </FormItem>
                {variant === 'create' && <FormItemAssemblyType />}
            </FormSection>

            <Divider />

            <FormSection id={id('oem_create_rfq/order_scenarios')} title={t`Order scenarios`}>
                <OrderScenariosFormItem
                    control={control}
                    name="orders"
                    addButtonText={t`Add order scenario`}
                    newScenario={{ quantity: undefined, date: undefined }}
                    hasAtLeastOneOrderScenario={true}
                    maxNumberOfScenarios={5}
                />
            </FormSection>

            <Divider />

            <FormSectionAdditionalServices />

            {variant === 'create' && (
                <>
                    <FormSection id={id('oem_create_rfq/notes')} title={t`Comment`}>
                        <FormItemComment />
                    </FormSection>
                    <Divider />
                </>
            )}

            <Flexbox flexDirection="row" gap={8} justifyContent="flex-start">
                <SecondaryButton onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton id={id('oem_create_rfq/button_save')} />
            </Flexbox>
        </Flexbox>
    );
}

function FormItemComment() {
    const { control } = useFormContext<CustomerRfqFormState>();

    return (
        <FormItem>
            <Typography style={{ color: colorSystem.neutral[6] }}>
                <Trans>You can add a comment to share any additional information.</Trans>
            </Typography>
            <TextFieldController
                control={control}
                name="comment"
                TextFieldProps={{
                    placeholder: t`Comment`,
                    multiline: true,
                    rows: 8, //setting minimum rows here caused a bug where the textfield would render with a too short height.
                }}
            />
        </FormItem>
    );
}

function FormSectionAdditionalServices() {
    const { setValue, control } = useFormContext<CustomerRfqFormState>();
    const additionalServices: string[] = useWatch({ name: 'additionalServiceIds', control });
    const { data } = useHttpQuery(
        'GET /additional-services',
        {},
        {
            useErrorBoundary: true,
            suspense: true,
        },
    );
    const { user } = useCurrentUserDetailsContext();

    //hide the whole section if there are no additional services available
    if (data === undefined || data.data === null || data.data.length === 0) {
        return null;
    }
    return (
        <>
            <FormSection title={t`Additional services`} id={id('oem_create_rfq/section_additional_services')}>
                <FormItem>
                    {data.data
                        .sort(compareByDate((i) => i.created_at, { type: 'ascending' }))
                        .map((additionalService) => {
                            const { id } = additionalService;
                            const value = additionalServices.includes(id);
                            return (
                                <Flexbox
                                    key={additionalService.id}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    gap={16}
                                >
                                    <FieldCheckbox
                                        value={value}
                                        onChange={() => {
                                            if (value === true) {
                                                setValue(
                                                    'additionalServiceIds',
                                                    additionalServices.filter((service) => service !== id),
                                                );
                                            } else {
                                                setValue('additionalServiceIds', [...additionalServices, id]);
                                            }
                                        }}
                                    />
                                    <Text variant="h4" color={colorSystem.neutral[8]}>
                                        {formatAdditionalServiceTranslation(additionalService, user.user_language)}
                                    </Text>
                                </Flexbox>
                            );
                        })}
                </FormItem>
            </FormSection>
            <Divider />
        </>
    );
}

function FormItemAssemblyType() {
    const { control } = useFormContext<CustomerRfqFormState>();
    return <FormItemAssemblyTypeRadio control={control} name="assemblyType" label={t`Type`} required />;
}
