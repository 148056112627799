import { convertIntoMonetaryValue, isPresent } from '@luminovo/commons';
import { CustomPartOfferDTO, MonetaryValueBackend, SolutionTag } from '@luminovo/http-client';
import { Solution } from '../../types';

export function extractOneTimeCosts({
    solution,
    includeManualCosts = false,
    currency = 'preferred',
}: {
    solution: Pick<Solution, 'solutionTags'>;
    includeManualCosts?: boolean;
    currency?: 'preferred' | 'original';
}): Array<{ price: MonetaryValueBackend; describe: string | undefined }> {
    return solution.solutionTags
        .flatMap((solutionTag) => {
            if (
                solutionTag?.tag === SolutionTag.OneTimeCost ||
                (includeManualCosts && solutionTag?.tag === SolutionTag.ManualOneTimeCost)
            ) {
                const price =
                    currency === 'preferred'
                        ? solutionTag.content.price.preferred_currency
                        : convertIntoMonetaryValue(solutionTag.content.price.original_currencies);

                if (!isPresent(price)) {
                    return [];
                }

                return {
                    price,
                    describe: solutionTag.content.description ?? undefined,
                    position: solutionTag.content.position,
                };
            }
            return [];
        })
        .sort((a, b) => a.position - b.position);
}

export function extractAccumulatedOneTimeCosts({
    solution,
    includeManualCosts = false,
    currency = 'preferred',
}: {
    solution: Pick<Solution, 'solutionTags'> | undefined;
    includeManualCosts?: boolean;
    currency?: 'preferred' | 'original';
}): MonetaryValueBackend | undefined {
    if (!isPresent(solution)) {
        return undefined;
    }

    const oneTimeCosts = extractOneTimeCosts({ solution, includeManualCosts, currency });
    if (oneTimeCosts.length === 0) {
        return undefined;
    }

    const accumulatedOneTimeCosts = oneTimeCosts.reduce(
        (acc, curr) => {
            return {
                amount: acc.amount + Number(curr.price.amount),
                currency: acc.currency,
            };
        },
        { amount: 0, currency: oneTimeCosts[0].price.currency },
    );

    return {
        amount: accumulatedOneTimeCosts.amount.toString(),
        currency: accumulatedOneTimeCosts.currency,
    };
}

export function extractAccumulatedOneTimeCostsFromOffer({
    one_time_costs,
}: {
    one_time_costs: CustomPartOfferDTO['one_time_costs'];
}): MonetaryValueBackend | undefined {
    if (one_time_costs.length === 0) {
        return undefined;
    }

    const accumulatedOneTimeCosts = one_time_costs.reduce(
        (acc, curr) => {
            return {
                amount: acc.amount + Number(curr.price.amount),
                currency: acc.currency,
            };
        },
        { amount: 0, currency: one_time_costs[0].price.currency },
    );

    return {
        amount: accumulatedOneTimeCosts.amount.toString(),
        currency: accumulatedOneTimeCosts.currency,
    };
}
