import { isPresent } from '@luminovo/commons';
import React from 'react';
import { useCurrentUserDetailsContext } from './components/contexts/CurrentUserDetailsContext';
import { useDialogContext, useDrawerContext } from './components/contexts/ModalContext';
import { useHttpQuery } from './resources/http/useHttpQuery';

export const IntercomWidget: React.FunctionComponent = () => {
    const { user, tenant, organization, customer } = useCurrentUserDetailsContext();
    const isCustomer = isPresent(customer);

    const { data } = useHttpQuery(
        'GET /my/intercom',
        {},
        {
            select: (res) => res.data,
        },
    );

    const { isOpen: isDialogOpen } = useDialogContext();
    const { isOpen: isDrawerOpen } = useDrawerContext();

    /* eslint-disable camelcase */
    React.useEffect(() => {
        if (isDrawerOpen || isDialogOpen) {
            window.Intercom('update', {
                hide_default_launcher: true,
            });
        } else {
            window.Intercom('update', {
                hide_default_launcher: false,
            });
        }
    }, [isDialogOpen, isDrawerOpen]);

    if (isPresent(data) && !isCustomer) {
        window.Intercom('boot', {
            app_id: window.INTERCOM_APP_ID,
            api_base: 'https://api-iam.eu.intercom.io',
            user_id: data.user_id,
            user_hash: data.hmac,
            email: user.email,
            name: `${user.first_name} ${user.last_name}`,
            language_override: user.user_language,
            //The flag above says that the Intercom widget will not be shown to a customer user
            // hence `is_from_customer` is set to false and `customer_name` is undefined
            is_from_customer: false,
            customer_name: undefined,
            company: {
                id: tenant,
                name: organization.name,
            },
        });
        /* eslint-enable camelcase */
    }

    return <></>;
};
