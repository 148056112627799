import * as r from 'runtypes';

export interface SignupFormState {
    first_name: string;
    last_name: string;
    email: string;
    clear_token: string;
    password: string;
    password_confirmation: string;
    phone_number?: number;
    country_code: number;
    customer_id: string | null;
    position: string;
    language: string;
    return_to?: string | null;
}

export const SignUpPayloadRuntype = r.Record({
    email: r.String,
    // eslint-disable-next-line camelcase
    customer_id: r.String.nullable(),
    // eslint-disable-next-line camelcase
    clear_token: r.String,
    tenant: r.String,
    // eslint-disable-next-line camelcase
    first_name: r.String.optional(),
    // eslint-disable-next-line camelcase
    last_name: r.String.optional(),
    language: r.String.optional(),
    // eslint-disable-next-line camelcase
    return_to: r.String.optional().nullable(),
});

export interface SignUpPayload extends r.Static<typeof SignUpPayloadRuntype> {}
