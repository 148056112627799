import { t } from '@lingui/macro';
import { SystemDriverName } from '@luminovo/http-client';
import { createWholeWordRegex } from '@luminovo/manufacturing-core';
import { ManufacturingInputControl } from '../../../components/MonacoEditor/editorTypes';

const panelFactorFormulaValue: SystemDriverName = 'panel_factor';
const panelWidthFormulaValue: SystemDriverName = 'panel_width';
const panelHeightFormulaValue: SystemDriverName = 'panel_height';
const batchSizeFormulaValue = 'batch_size';
const orderSizeFormulaValue = 'order_size';
const pcbWidthFormulaValue: SystemDriverName = 'pcb_width';
const pcbHeightFormulaValue: SystemDriverName = 'pcb_height';
const pcbThicknessFormulaValue: SystemDriverName = 'pcb_thickness';
const sidesOfPlacementFormulaValue: SystemDriverName = 'Sides_of_placement';

export function createPredefinedManufacturingInputControls(
    existingFormulaValue: string | undefined,
): ManufacturingInputControl[] {
    return [
        {
            name: t`Panel factor`,
            formulaValue: panelFactorFormulaValue,
            automaticDetails: undefined,
            type: 'Panel factor',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(panelFactorFormulaValue).test(existingFormulaValue),
            notes: undefined,
        },
        {
            name: t`Panel width`,
            formulaValue: panelWidthFormulaValue,
            automaticDetails: undefined,
            type: 'Panel width',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(panelWidthFormulaValue).test(existingFormulaValue),
            notes: t`in mm`,
        },
        {
            name: t`Panel height`,
            formulaValue: panelHeightFormulaValue,
            automaticDetails: undefined,
            type: 'Panel height',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(panelHeightFormulaValue).test(existingFormulaValue),
            notes: t`in mm`,
        },
        {
            name: t`Batch size`,
            formulaValue: batchSizeFormulaValue,
            automaticDetails: undefined,
            type: 'Batch size',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(batchSizeFormulaValue).test(existingFormulaValue),
            notes: undefined,
        },
        {
            name: t`Order size`,
            formulaValue: orderSizeFormulaValue,
            automaticDetails: undefined,
            type: 'Order size',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(orderSizeFormulaValue).test(existingFormulaValue),
            notes: undefined,
        },
        {
            name: t`PCB height`,
            formulaValue: pcbHeightFormulaValue,
            automaticDetails: undefined,
            type: 'PCB height',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(pcbHeightFormulaValue).test(existingFormulaValue),
            notes: t`in mm`,
        },
        {
            name: t`PCB width`,
            formulaValue: pcbWidthFormulaValue,
            automaticDetails: undefined,
            type: 'PCB width',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(pcbWidthFormulaValue).test(existingFormulaValue),
            notes: t`in mm`,
        },
        {
            name: t`PCB thickness`,
            formulaValue: pcbThicknessFormulaValue,
            automaticDetails: undefined,
            type: 'PCB thickness',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(pcbThicknessFormulaValue).test(existingFormulaValue),
            notes: t`in mm`,
        },
        {
            name: t`Sides of placement`,
            formulaValue: sidesOfPlacementFormulaValue,
            automaticDetails: undefined,
            type: 'Sides of placement',
            isInFormula:
                existingFormulaValue !== undefined &&
                createWholeWordRegex(sidesOfPlacementFormulaValue).test(existingFormulaValue),
            notes: t`1 or 2`,
        },
    ];
}
