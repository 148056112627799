import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

export const parseNotes: Parser<string, Opts> = async function (cells, opts, field): Promise<ParseResult<string>> {
    return applyParser({
        cells,
        field,
        formatValue: (x) => x,
        parser: parse.notes,
    });
};
