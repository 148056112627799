import { Text as DesignSystemText, Flexbox, colorSystem } from '@luminovo/design-system';

export function TooltipDetails({
    color,
    label,
    count,
    tooltip,
}: {
    color: string;
    label: string | JSX.Element;
    count: number;
    tooltip?: { title?: JSX.Element; description: JSX.Element };
}): JSX.Element {
    return (
        <Flexbox padding={'12px'} flexDirection="column" gap={8} maxWidth={'280px'}>
            <Flexbox justifyContent={'space-between'} width="100%" gap="8px">
                <Flexbox gap={4}>
                    <div
                        style={{
                            borderRadius: 4,
                            width: 16,
                            height: 16,
                            backgroundColor: color,
                        }}
                    />
                    <DesignSystemText variant="h5">{tooltip && tooltip.title ? tooltip.title : label}</DesignSystemText>
                </Flexbox>
                <DesignSystemText variant="h5"> {count}</DesignSystemText>
            </Flexbox>
            {tooltip && (
                <DesignSystemText variant="caption" color={colorSystem.neutral[6]}>
                    {tooltip.description}
                </DesignSystemText>
            )}
        </Flexbox>
    );
}
