import { getToken } from '@luminovo/auth';
import { PCBV2File, PdfAnalyzeResponse, http } from '@luminovo/http-client';
import { extractPcbSpec } from '@luminovo/pdf-extractor';
import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { FileWithExtractedPcbSpec } from './types';

async function extractPcbSpecFromPdf({ pdfPath }: { pdfPath: string }) {
    const res = await fetch(pdfPath);

    if (!res.ok) {
        return {};
    }

    const blob = await res.blob();

    const formData = new FormData();
    formData.append('file', blob);
    const analyzeResult: PdfAnalyzeResponse = await http(
        'POST /analyze/pdf',
        {
            queryParams: { type: 'General' },
            requestBody: formData,
        },
        getToken(),
    );

    return extractPcbSpec({ azureExtractionResult: { analyzeResult } });
}

export function useExtractPcbSpecificationFromPdf({
    files = [],
    disabled = false,
}: {
    files?: PCBV2File[];
    disabled?: boolean;
}): FileWithExtractedPcbSpec | undefined {
    const pdfs: PCBV2File[] = files.filter((file) => file.path && file.name.toLowerCase().endsWith('.pdf'));

    const queries: Array<UseQueryResult<FileWithExtractedPcbSpec>> = useQueries({
        queries: pdfs.map((pdf) => {
            return {
                queryKey: [pdf.path],
                suspense: true,
                queryFn: async (): Promise<FileWithExtractedPcbSpec> => {
                    try {
                        const extracted = await extractPcbSpecFromPdf({ pdfPath: pdf.path ?? '' });
                        return { file: pdf, extracted };
                    } catch (e) {
                        return { file: pdf, extracted: {} };
                    }
                },
                enabled: disabled === false,
            };
        }),
    });

    if (queries.length === 0) {
        return undefined;
    }

    // Wait for all queries to finish
    if (queries.some((q) => !q.data)) {
        return undefined;
    }

    // Return the file with the most extracted attributes
    // TODO: This is a very naive approach. We should probably use a more sophisticated algorithm
    return queries
        .map((query) => query.data)
        .reduce((acc, cur) => {
            const extractedKeys = Object.keys(acc?.extracted ?? {});
            const curKeys = Object.keys(cur?.extracted ?? {});
            if (curKeys.length > extractedKeys.length) {
                return cur;
            }
            return acc;
        });
}
