import { useIsCustomer } from '../../components/contexts/CurrentUserDetailsContext';
import { Protected } from '../../permissions/Protected';
import { CustomerRfqCreationPage } from './CustomerRfqCreationPage';
import { EmsRfqCreationPage } from './EmsRfqCreationPage';

const RfqCreationPage = (): JSX.Element => {
    const isCustomer = useIsCustomer();

    return (
        <Protected requiredPermissions={() => !isCustomer} fallback={<CustomerRfqCreationPage />}>
            <EmsRfqCreationPage />
        </Protected>
    );
};

export default RfqCreationPage;
