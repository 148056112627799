import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { ManufacturingScenarioDTO, ManufacturingScenarioFullSummaryDTO } from '@luminovo/http-client';
import { Box, Card, Grid, Typography, styled } from '@mui/material';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { Notes } from '../../shared/ManufacturingSharedComponents';
import {
    ManufacturingScenarioCostBreakdownTable,
    ManufacturingScenarioTimeBreakdownTable,
} from './ManufacturingScenarioBreakdownTableComponents';

const StyledTableWrapper = styled(Box)({
    overflow: 'hidden',
    overflowY: 'auto',
    overflowX: 'auto',
    maxBlockSize: '144px',
    width: '100%',
    boxSizing: 'border-box',

    '& th.MuiTableCell-sizeSmall': {
        height: '32px',
    },

    '& .MuiTableCell-sizeSmall': {
        padding: '6px',
    },

    '& table': {
        minWidth: 'unset',
    },
});

const StyledGrid = styled(Grid)({
    '& > div': {
        marginBottom: 0,
    },
});

export const ManufacturingScenarioBreakdown = ({
    manufacturingScenarioFullSummary,
    manufacturingScenario,
    isLoading,
}: {
    manufacturingScenarioFullSummary?: ManufacturingScenarioFullSummaryDTO;
    manufacturingScenario?: ManufacturingScenarioDTO;
    isLoading: boolean;
}) => {
    return (
        <Card style={{ boxShadow: 'none' }} id={id('manufacturing_scenario/cost_breakdown_table')}>
            {isLoading ? (
                <Box height={'142px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <SpinnerWithBackdrop noBackdrop />
                </Box>
            ) : (
                <>
                    {manufacturingScenarioFullSummary && (
                        <Grid direction="row" container style={{ padding: 16, paddingBottom: 0 }} spacing={4}>
                            <StyledGrid item xs={12} md={7}>
                                <Typography
                                    variant="h3"
                                    style={{ color: colorSystem.neutral[8], marginBlockEnd: '16px' }}
                                >
                                    <Trans>Cost breakdown</Trans>
                                </Typography>
                                <StyledTableWrapper>
                                    <ManufacturingScenarioCostBreakdownTable
                                        manufacturingScenarioFullSummary={manufacturingScenarioFullSummary}
                                    />
                                </StyledTableWrapper>
                            </StyledGrid>
                            <StyledGrid item xs={12} md={5}>
                                <Typography
                                    variant="h3"
                                    style={{ color: colorSystem.neutral[8], marginBlockEnd: '16px' }}
                                >
                                    <Trans>Time breakdown</Trans>
                                </Typography>
                                <StyledTableWrapper>
                                    <ManufacturingScenarioTimeBreakdownTable
                                        manufacturingScenarioFullSummary={manufacturingScenarioFullSummary}
                                    />
                                </StyledTableWrapper>
                            </StyledGrid>
                        </Grid>
                    )}
                </>
            )}

            <Box style={{ padding: '16px' }}>
                <Notes notes={manufacturingScenario?.notes ?? undefined} tooltip />
            </Box>
        </Card>
    );
};
