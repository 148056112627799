import React from 'react';

export function useOnWindowBlur(onBlur: () => void) {
    React.useEffect(() => {
        const fn = () => {
            onBlur();
        };
        // eslint-disable-next-line spellcheck/spell-checker
        window.addEventListener('blur', fn);

        // eslint-disable-next-line spellcheck/spell-checker
        return () => window.removeEventListener('blur', fn);
    }, [onBlur]);
}
