import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const printRegex = '(:?im)?print(:?ing)?';
const silkscreenKeywords = [
    'Silk\\s?screen',
    'Bestück(:?ungs)?druck',
    `Legend ${printRegex}`,
    'Bedruckung',
    'Serigrafia',
    'Component notation',
    `Component ${printRegex}`,
    `Placement ${printRegex}`,
];

export function parseSilkscreenKeyword(str: string): PcbAttribute | undefined {
    const regex = turnKeywordsIntoRegexRespectingWordBoundaries(silkscreenKeywords);
    if (regex.test(str)) {
        return {
            attr: 'silkscreenKeyword',
            value: undefined,
            // we are more confident about the match the shorter the string that matched
            // this helps us more accurately match the location of the soldermask keyword in the merging stage
            confidence: 1 / str.length,
        };
    }
    return undefined;
}
