import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Checkbox, colorSystem, Flexbox, StickyLayout, TertiaryButton, Text } from '@luminovo/design-system';
import { BomItemApprovalStatus } from '@luminovo/http-client';
import { Divider, Typography } from '@mui/material';
import * as React from 'react';
import { analytics } from '../../../utils/analytics';
import { groupAndCountFilters, ModuleTableData } from '../../Bom/components/ModuleTableData';
import {
    bomItemFilterIds,
    commentFilterIds,
    FilterId,
    filterLabel,
    manufacturingFilterIds,
} from '../../Bom/components/ModuleTableData/filters';

function getCountColor(count: number | undefined) {
    return count ? colorSystem.neutral[9] : colorSystem.neutral[5];
}

function trackFiltersUsed(filterIds: FilterId[]) {
    const filterLabels: string[] = [];
    for (const filter of filterIds) {
        filterLabels.push(filterLabel(filter));
    }
    analytics.track('apply_bom_filters', { filters: filterLabels });
}

export function useTrackFiltersEvents(isFiltersOpen: boolean, appliedFilters: Set<FilterId>) {
    React.useEffect(() => {
        if (!isFiltersOpen && appliedFilters.size !== 0) {
            trackFiltersUsed(Array.from(appliedFilters));
        }
    }, [isFiltersOpen, appliedFilters]);
}

function useClickOutside(setIsFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>) {
    React.useEffect(() => {
        const handleClickOutsideFilters = (event: Event) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const target = event.target as HTMLElement;
            const filtersContainer = document.getElementById('filters');
            const filtersButton = document.getElementById('filters-button');
            if (filtersButton && filtersButton.contains(target)) {
                return;
            } else if (filtersContainer && !filtersContainer.contains(target)) {
                setIsFiltersOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutsideFilters);

        return () => {
            window.removeEventListener('click', handleClickOutsideFilters);
        };
    }, [setIsFiltersOpen]);
}

function calculateFiltersModalLeftWidth() {
    const filtersButtonWidth = document.getElementById('filters-button')?.clientWidth ?? 0;
    const bomItemsHeaderWidth = document.getElementById('bom-items-header')?.clientWidth ?? 0;
    return bomItemsHeaderWidth - filtersButtonWidth > 0 ? bomItemsHeaderWidth - filtersButtonWidth - 2 : '15vw';
}

export function FilterIssuesInBomForm({
    isFiltersOpen,
    setIsFiltersOpen,
    appliedFilters,
    setAppliedFilters,
    setSearchedText,
    setSelectedStatus,
    clearSelectedWarningSubStatus,
    modulesData,
    dashboardFiltersCount,
}: {
    isFiltersOpen: boolean;
    setIsFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
    appliedFilters: Set<FilterId>;
    setAppliedFilters: (value: Set<FilterId>) => void;
    setSearchedText: React.Dispatch<React.SetStateAction<string>>;
    setSelectedStatus: React.Dispatch<React.SetStateAction<BomItemApprovalStatus | undefined>>;
    clearSelectedWarningSubStatus: () => void;
    modulesData: ModuleTableData[];
    dashboardFiltersCount: number;
}) {
    useClickOutside(setIsFiltersOpen);

    const handleClear = () => {
        setAppliedFilters(new Set());
    };

    const handleOnChange = (issueId: FilterId) => {
        setSearchedText('');
        setSelectedStatus(undefined);
        clearSelectedWarningSubStatus();
        const newFilters = new Set(appliedFilters);
        if (appliedFilters.has(issueId)) {
            newFilters.delete(issueId);
        } else {
            newFilters.add(issueId);
        }
        setAppliedFilters(newFilters);
    };

    const isClearDisabled = appliedFilters.size === 0;

    const filtersCount = React.useMemo(() => {
        return groupAndCountFilters(modulesData);
    }, [modulesData]);

    return (
        <>
            {isFiltersOpen && (
                <Flexbox
                    id="filters"
                    boxSizing="border-box"
                    flexDirection="column"
                    style={{
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        position: 'absolute',
                        top: 'calc(100% + 4px)',
                        left: calculateFiltersModalLeftWidth(),
                        borderRadius: 4,
                        outline: 'none',
                        flexGrow: 1,
                        pointerEvents: 'auto',
                        background: colorSystem.neutral.white,
                    }}
                    padding={'0px 24px 24px 24px'}
                    width={'400px'}
                    zIndex={3}
                    boxShadow={'rgba(0, 0, 0, 0.1) 0px 10px 16px -4px, rgba(0, 0, 0, 0.05) 0px 4px 8px -4px'}
                    maxHeight={'70vh'}
                    overflow={'auto'}
                >
                    <StickyLayout
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        paddingTop={'24px'}
                        paddingBottom={'12px'}
                    >
                        <Text variant="h4">
                            <Trans>Filters</Trans>
                        </Text>
                        <TertiaryButton
                            size="medium"
                            onClick={handleClear}
                            disabled={isClearDisabled}
                            disableRipple
                            style={{ padding: 0, margin: 0, minWidth: 'unset', height: 'unset' }}
                        >
                            <Trans>Clear</Trans>
                        </TertiaryButton>
                    </StickyLayout>
                    <Flexbox flexDirection={'column'} gap={16}>
                        <Flexbox flexDirection={'column'} gap={12}>
                            <Typography variant="h5" color="textSecondary">
                                <Trans>Comments</Trans>
                            </Typography>
                            <Divider />
                            {commentFilterIds.map((filter) => {
                                const count = filtersCount.get(filter) ?? 0;
                                return (
                                    <FilterItem
                                        key={filter}
                                        selected={appliedFilters.has(filter)}
                                        count={count}
                                        filter={filter}
                                        handleOnChange={handleOnChange}
                                    />
                                );
                            })}
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={12}>
                            <Typography variant="h5" color="textSecondary">
                                <Trans>BOM</Trans>
                            </Typography>
                            <Divider />

                            {bomItemFilterIds.map((filter) => {
                                const count =
                                    filter === FilterId.HealthDashboardFilters
                                        ? dashboardFiltersCount
                                        : (filtersCount.get(filter) ?? 0);
                                return (
                                    <FilterItem
                                        key={filter}
                                        selected={appliedFilters.has(filter)}
                                        count={count}
                                        filter={filter}
                                        handleOnChange={handleOnChange}
                                    />
                                );
                            })}
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={12}>
                            <Typography variant="h5" color="textSecondary">
                                <Trans>Part data quality</Trans>
                            </Typography>
                            <Divider />
                            {manufacturingFilterIds.map((filter) => {
                                const count = filtersCount.get(filter) ?? 0;
                                return (
                                    <FilterItem
                                        key={filter}
                                        selected={appliedFilters.has(filter)}
                                        count={count}
                                        filter={filter}
                                        handleOnChange={handleOnChange}
                                    />
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            )}
        </>
    );
}

const FilterItem = ({
    filter,
    handleOnChange,
    count,
    selected,
}: {
    filter: FilterId;
    count: number;
    selected: boolean;
    handleOnChange: (filter: FilterId) => void;
}) => {
    return (
        <Flexbox alignItems="flex-start" justifyContent="space-between" gap="8px">
            <Flexbox alignItems="flex-start" gap={4}>
                <Checkbox checked={selected} onChange={() => handleOnChange(filter)} size="small" />
                <Text style={{ color: getCountColor(count) }} variant="body-small">
                    <Trans>{filterLabel(filter)}</Trans>
                </Text>
            </Flexbox>
            <Typography variant="subtitle1" style={{ color: getCountColor(count) }}>
                {formatDecimal(count)}
            </Typography>
        </Flexbox>
    );
};
