import { assertUnreachable } from '@luminovo/commons';
import {
    DriverIdDTO,
    DriverStatusDesignItem,
    ExpenseLevel,
    ManufacturingScenarioTemplateDTO,
    TimeCalculation,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import React from 'react';
import { useIsCustomer } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

export const useInformationAndConfirmationDialogs = (shouldInformationDialogBeOpen: boolean) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);
    const [isInformationDialogOpen, setIsInformationDialogOpen] = React.useState<boolean>(false);
    function openDialog() {
        if (shouldInformationDialogBeOpen) {
            setIsInformationDialogOpen(true);
        } else {
            setIsConfirmationDialogOpen(true);
        }
    }

    function closeDialog() {
        if (shouldInformationDialogBeOpen) {
            setIsInformationDialogOpen(false);
        } else {
            setIsConfirmationDialogOpen(false);
        }
    }

    return {
        openDialog,
        closeDialog,
        isInformationDialogOpen,
        isConfirmationDialogOpen,
    };
};

export const generateTemplateString = (templates: ManufacturingScenarioTemplateDTO[]): string => {
    return templates.map((template) => template.name).join(', ');
};

export const filterActiveTemplates = (
    templates: ManufacturingScenarioTemplateDTO[],
): ManufacturingScenarioTemplateDTO[] => {
    return templates.filter((template) => template.status === 'Active');
};

export const getIsPerPanelFromTimeCalculation = (timeCalculation: TimeCalculation): boolean => {
    const level = timeCalculation.level;
    switch (level) {
        case 'Unit':
            return timeCalculation.details.panel_default_settings === 'WithinWholePanel';
        case 'Project':
        case 'Batch':
            return false;
        default:
            assertUnreachable(level);
    }
};

export const filterActiveOrSelectedDriverDetails = (
    driverDetails: UserDriverDetailsDTO[] | undefined,
    selectedDriverDetails: UserDriverDetailsDTO | undefined,
): DriverIdDTO[] => {
    return (
        driverDetails
            ?.filter((driver) => driver.status === 'Active' || driver.id === selectedDriverDetails?.id)
            .map((id) => ({
                value: id.id,
                // because driver details can only be contained in a user type, we can safely cast it to a user type
                type: 'User',
            })) ?? []
    );
};

/**
 * Check if an expense level can be linked to a panel.
 * @param expenseLevel Expense level.
 * @returns Can be linked to panel?
 */
export const expenseLevelCanBeLinkedToPanel = (expenseLevel: ExpenseLevel): boolean => {
    const allowedPanelLevels: ExpenseLevel[] = ['Unit'];
    return allowedPanelLevels.includes(expenseLevel);
};

/**
 * Check if manufacturing module is enabled and warning should be displayed.
 * If a customer user is logged in, we check if the display of part data warnings
 * is enabled. We don't check if the manufacturing module is enabled because internally
 * a permission check is performed and a customer user never has these manufacturing
 * related permissions.
 * @returns should show manufacturing warning
 */
export const useShouldShowManufacturingWarning = (): {
    shouldShowManufacturingWarning: boolean;
    isLoading: boolean;
} => {
    const isCustomer = useIsCustomer();
    const { data: showPartDataWarnings = false, isLoading: isLoadingCustomerPortalSettings } = useHttpQuery(
        'GET /organization-settings/customer-portal',
        {},
        {
            enabled: isCustomer,
            select: (data) => data.customer_portal_settings.show_part_data_warnings,
        },
    );

    return isCustomer
        ? { shouldShowManufacturingWarning: showPartDataWarnings, isLoading: isLoadingCustomerPortalSettings }
        : { shouldShowManufacturingWarning: true, isLoading: false };
};

export const getUniqueDesignItems = (designItems: DriverStatusDesignItem[]) => [
    ...new Map(designItems.map((designItem) => [designItem.id, designItem])).values(),
];
