import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { AvnetEbvCredentialsInputDTORuntype, AvnetEbvResponseBodyDTORuntype } from './avnetEbvBackendTypes';

export const avnetEbvEndpoints = {
    'GET /3rdparty/avnetebv/credentials': endpoint({
        description: 'Returns credentials for avnetebv integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AvnetEbvResponseBodyDTORuntype,
    }),
    'POST /3rdparty/avnetebv/credentials': endpoint({
        description: 'Uploads credentials for avnetebv API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetEbvCredentialsInputDTORuntype,
        responseBody: AvnetEbvResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetebv/credentials'],
    }),
    'PATCH /3rdparty/avnetebv/credentials': endpoint({
        description: 'Updates credentials for avnetebv API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetEbvCredentialsInputDTORuntype,
        responseBody: AvnetEbvResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetebv/credentials'],
    }),
    'DELETE /3rdparty/avnetebv/credentials': endpoint({
        description: 'Deletes existing credentials for avnetebv API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/avnetebv/credentials'],
    }),
};
