import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Column, FieldNumericController } from '@luminovo/design-system';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnMoq: Column<TableQuotationOfferItem> = selectableColumn<'moq'>({
    attribute: 'moq',
    label: <Trans>MOQ</Trans>,
    align: 'right',
    render: ({ extracted }) => {
        return formatDecimal(extracted);
    },
    InputComponent: ({ control }) => {
        return (
            <>
                <FieldNumericController
                    control={control}
                    name={'moq'}
                    FieldProps={{ autoFocus: true, fullWidth: true }}
                    required
                    isInteger
                    min={1}
                />
            </>
        );
    },
});
