import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { CommentCategoryRuntype, CommentEntityRuntype, CommentRuntype } from './commentBackendTypes';

export const commentsEndpoints = {
    'POST /comments/bulk': endpoint({
        description: 'Fetches all the events associated with the given entity',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CommentEntityRuntype,
        responseBody: r.Record({
            comments: r.Array(CommentRuntype),
        }),
    }),
    'PATCH /comments/:id': endpoint({
        description: 'Updates an event',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ resolved: r.Boolean }),
        responseBody: r.Unknown,
    }),
    'DELETE /comments/:id': endpoint({
        description: `Deletes an event given it's ID`,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
    }),
    'POST /comments': endpoint({
        description: `Creates an event`,
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            comment_entity: CommentEntityRuntype,
            content: r.String,
            category: CommentCategoryRuntype,
        }),
        responseBody: r.Unknown,
    }),
};
