import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    UserDriverDetailsBulkPatchDTORuntype,
    UserDriverDetailsDTOPatchRuntype,
    UserDriverDetailsDTOPostRuntype,
    UserDriverDetailsRuntype,
} from './driverDetailsBackendTypes';

export const driversEndpoints = {
    'POST /user-drivers': endpoint({
        description: 'Creates a driver',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: UserDriverDetailsDTOPostRuntype,
        responseBody: r.Record({
            data: UserDriverDetailsRuntype,
        }),
        invalidates: ['GET /user-drivers'],
    }),

    'GET /user-drivers': endpoint({
        description: 'Returns an array of all drivers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(UserDriverDetailsRuntype),
        }),
    }),

    'PATCH /user-drivers': endpoint({
        description: 'Updates multiple drivers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: UserDriverDetailsBulkPatchDTORuntype,
        responseBody: r.Record({
            data: r.Array(UserDriverDetailsRuntype),
        }),
        invalidates: ['GET /user-drivers'],
    }),

    'GET /user-drivers/:driverId': endpoint({
        description: 'Returns a driver details',
        pathParams: r.Record({ driverId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: UserDriverDetailsRuntype,
        }),
    }),

    'PATCH /user-drivers/:driverId': endpoint({
        description: 'Updates a driver',
        pathParams: r.Record({ driverId: r.String }),
        queryParams: r.Undefined,
        requestBody: UserDriverDetailsDTOPatchRuntype,
        responseBody: r.Record({
            data: UserDriverDetailsRuntype,
        }),
    }),

    'DELETE /user-drivers/:driverId': endpoint({
        description: 'Deletes a driver',
        pathParams: r.Record({ driverId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
};
