import { Box, styled, Tooltip } from '@mui/material';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Bin, binValues } from './binValues';

export function SimpleHistogram({
    values,
    isHighlighted,
    onSelect,
}: {
    values: number[];
    isHighlighted?: (bin: Bin) => boolean;
    onSelect?: (bin: Bin) => void;
}): JSX.Element {
    const bins = binValues({ values, numberOfBins: 10 });
    const max = bins.map((p) => p.probability).reduce((a, b) => Math.max(a, b), 0);
    return (
        <Flexbox height={100} gap={2} justifyContent="space-around" alignItems={'flex-end'}>
            {bins.map((bin, i) => {
                const { probability } = bin;
                const height = probability === 0 ? '0' : `max(4px, ${Math.floor((probability / max) * 100)}%)`;
                return (
                    <Tooltip key={i} title={`${bin.from} - ${bin.to}`} placement="top">
                        <HistogramBar
                            data-testid="histogram-bar"
                            onClick={() => onSelect && onSelect(bin)}
                            style={{
                                background:
                                    isHighlighted && isHighlighted(bin)
                                        ? colorSystem.primary[6]
                                        : colorSystem.neutral[2],
                            }}
                            height={height}
                        />
                    </Tooltip>
                );
            })}
        </Flexbox>
    );
}

const HistogramBar = styled(Box)({
    cursor: 'pointer',
    background: colorSystem.neutral[2],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexGrow: 1,
    minWidth: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'transparent',
    '&:hover': {
        color: colorSystem.neutral[8],
    },
});
