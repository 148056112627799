import { Trans } from '@lingui/macro';
import { Flexbox, FullWidthLayout, PrimaryButton } from '@luminovo/design-system';
import { ReadOnlyAccessAlert } from '../../../../components/ManufacturingDatabase/ManufacturingDatabasePage';
import { PageLayout } from '../../../../components/PageLayout';
import { useIsAdminUser } from '../../../../components/contexts/CurrentUserDetailsContext';
import { route } from '../../../../utils/routes';
import CalculationTemplatesTable from './components/CalculationTemplatesTable';

const CalculationTemplatesViewPage = (): JSX.Element => {
    const isAdmin = useIsAdminUser();
    return (
        <PageLayout
            layout="full-width"
            header={
                <FullWidthLayout style={{ paddingBlockStart: '12px', paddingBlockEnd: '0px' }}>
                    {isAdmin === false && <ReadOnlyAccessAlert />}
                    {isAdmin && (
                        <Flexbox flexDirection={'row-reverse'}>
                            <PrimaryButton href={route('/calculation/templates/add-template')} size="medium">
                                <Trans>Add template</Trans>
                            </PrimaryButton>
                        </Flexbox>
                    )}
                </FullWidthLayout>
            }
        >
            <CalculationTemplatesTable />
        </PageLayout>
    );
};

export default CalculationTemplatesViewPage;
