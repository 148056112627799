import { compareByNumber } from '@luminovo/design-system';
import { ParseStatus } from '../types';

/**
 * A comparator for ParseStatus. This comparator is used to determine the status of a row.
 *
 * The order is as follows:
 * 1. skipped: if any cell is skipped, the row is skipped
 * 2. error: if any cell is in error, the row is in error
 * 3. warning: if any cell is in warning, the row is in warning
 * 4. pending: if any cell is pending, the row is pending
 * 5. done: if all cells are done, the row is done
 */
export const compareParseStatus = compareByNumber<ParseStatus>((status) => {
    return ['pending', 'skipped', 'error', 'warning', 'done'].indexOf(status);
});
