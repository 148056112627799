import { t } from '@lingui/macro';
import { FormulaErrorDTO, FormulaResponseDTO } from '@luminovo/http-client';
import {
    ErrorDetails,
    extractFormulaRuntimeError,
    extractFormulaSyntaxError,
    extractFormulaValidationError,
    isRuntimeError,
    isRuntimeTestError,
    isSyntaxError,
    isValidationError,
} from './advancedFormulaErrorFunctionUtils';

export const extractFormulaErrorDTO = (formulaResponse?: FormulaResponseDTO): FormulaErrorDTO | undefined => {
    if (!formulaResponse || formulaResponse?.result === 'Ok') {
        return undefined;
    }

    return formulaResponse.details;
};

export const extractFormulaErrorDetails = (formulaError: FormulaErrorDTO): ErrorDetails => {
    if (isValidationError(formulaError)) {
        return extractFormulaValidationError(formulaError);
    }

    if (isSyntaxError(formulaError)) {
        return extractFormulaSyntaxError(formulaError);
    }

    if (isRuntimeTestError(formulaError)) {
        return extractFormulaRuntimeError(formulaError.details);
    }

    if (isRuntimeError(formulaError)) {
        return extractFormulaRuntimeError(formulaError);
    }

    return {
        line: t`Error in formula`,
        kind: undefined,
    };
};

export const extractMessageFromFormulaError = (formulaError: FormulaErrorDTO): string => {
    const { line, kind } = extractFormulaErrorDetails(formulaError);

    if (!kind) {
        return `${line}`;
    }

    return `${line}
        ${kind}`;
};
