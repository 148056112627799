import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { TtiCredentialsInputDTORuntype, TtiResponseBodyDTORuntype } from './ttiBackendTypes';

export const ttiEndpoints = {
    'GET /3rdparty/tti/credentials': endpoint({
        description: 'Returns credentials for tti integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: TtiResponseBodyDTORuntype,
    }),
    'POST /3rdparty/tti/credentials': endpoint({
        description: 'Uploads credentials for tti API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TtiCredentialsInputDTORuntype,
        responseBody: TtiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
    'PATCH /3rdparty/tti/credentials': endpoint({
        description: 'Updates credentials for tti API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TtiCredentialsInputDTORuntype,
        responseBody: TtiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
    'DELETE /3rdparty/tti/credentials': endpoint({
        description: 'Deletes existing credentials for tti API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
};
