import React from 'react';
import { Polygons } from './layers/Polygons';
import { Suggestions } from './layers/Suggestions';

export function LayerSelectedMode({
    pageNumber,
    width,
    height,
}: {
    pageNumber: number;
    width: number;
    height: number;
}): JSX.Element {
    const svgRef = React.useRef<SVGSVGElement>(null);

    const viewbox = {
        width,
        height,
    };

    return (
        <>
            <svg
                ref={svgRef}
                viewBox={`0 0 ${width} ${height}`}
                style={{
                    left: 0,
                    top: 0,
                    position: 'absolute',
                    width,
                    height,
                }}
            >
                <Polygons pageNumber={pageNumber} viewbox={viewbox} />

                <Suggestions pageNumber={pageNumber} viewbox={viewbox} />
            </svg>
        </>
    );
}
