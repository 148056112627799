import { t } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import { FieldValues, Path } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldDate, FieldDateProps } from './FieldDate';

export interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<FieldControllerProps<FieldDateProps, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the input field is required.
     */
    required?: boolean;
    /**
     * The earliest date (inclusive) allowed in the input field.
     */
    minDate?: string;

    /**
     * The latest date (inclusive) allowed in the input field.
     */
    maxDate?: string;

    /**
     * When set, only dates in the future are allowed.
     */
    inFuture?: boolean;

    /**
     * When set, only dates in the future or today's date are allowed
     */
    inFutureOrToday?: boolean;

    /**
     * When set, only dates in the past are allowed.
     */
    inPast?: boolean;

    /**
     * When set, only dates in the past or today's date are allowed.
     */
    inPastOrToday?: boolean;
}

export function FieldDateControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
    required,
    minDate,
    maxDate,
    inFuture,
    inFutureOrToday,
    inPast,
    inPastOrToday,
    validate,
    ...props
}: ControllerProps<TFieldValues, TName>): JSX.Element {
    return (
        <FieldController
            Field={FieldDate}
            validate={createDateValidator({
                required,
                minDate,
                maxDate,
                inFuture,
                inFutureOrToday,
                inPast,
                inPastOrToday,
                validate,
            })}
            {...props}
        />
    );
}

export function createDateValidator({
    required,
    minDate,
    maxDate,
    inFuture,
    inFutureOrToday,
    inPast,
    inPastOrToday,
    validate,
}: Pick<
    ControllerProps,
    'required' | 'minDate' | 'maxDate' | 'inFuture' | 'inFutureOrToday' | 'inPast' | 'inPastOrToday' | 'validate'
>) {
    return (x: unknown): string | undefined => {
        if (!required && (x === null || x === undefined || x === '')) {
            return undefined;
        }

        if (required && (x === null || x === undefined || x === '')) {
            return t`Required`;
        }

        if (typeof x !== 'string' || isNaN(Date.parse(x))) {
            return 'Invalid date format';
        }

        const value = new Date(x);
        value.setHours(0, 0, 0, 0);
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const isToday = value.toDateString() === now.toDateString();

        if (minDate && value < new Date(minDate)) {
            return t`Date must be on or after ${formatToLongDate(minDate)}`;
        }

        if (maxDate && value > new Date(maxDate)) {
            return t`Date must be on or before ${formatToLongDate(maxDate)}`;
        }

        if (inFuture && value <= now) {
            return t`Date must be in the future`;
        }

        if (inFutureOrToday && (value < now || !isToday)) {
            return t`Date must be in the future or today`;
        }

        if (inPast && value >= now) {
            return t`Date must be in the past`;
        }

        if (inPastOrToday && (value > now || !isToday)) {
            return t`Date must be in the past or today`;
        }

        // FieldDate only supports arbitrary dates up to 2999-12-31
        if (value > new Date('2999-12-31')) {
            return t`Date must be on or before ${formatToLongDate('2999-12-31')}`;
        }

        if (validate) {
            return validate(x);
        }

        return undefined;
    };
}
