import { isPresent } from '../typingUtils';

const iso8601DateFormatter = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });

export const formatToIso8601Date = (date: string | Date | undefined | null, option?: { ifAbsent: string }): string => {
    if (!isPresent(date)) {
        return option?.ifAbsent ?? '-';
    }

    const d = date instanceof Date ? date : new Date(date);
    if (isNaN(d.getTime())) {
        return option?.ifAbsent ?? '-';
    }

    return iso8601DateFormatter.format(d);
};
