import {
    BaseFieldControllerProps,
    Checkbox,
    CheckboxProps,
    Radio,
    RadioProps,
    Select,
    SelectProps,
    TertiaryIconButton,
    TextField,
    TextFieldProps,
} from '@luminovo/design-system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    FormControlLabel,
    InputAdornment,
    MenuItem,
    RadioGroup,
    RadioGroupProps,
    Tooltip,
    Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { CSSProperties, useState } from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { colorSystem } from '../../../themes';
import { typeSafeObjectKeys } from '../../../utils/typingUtils';
import { TransEnum, Translations } from '../../localization/TransEnum';
import { TextFieldInputProps } from '../FormComponents';

type TextFieldControllerProps<T extends FieldValues> = BaseFieldControllerProps<T> & {
    defaultValue?: ControllerProps<T>['defaultValue'];
    TextFieldProps?: TextFieldProps;
    //transformation between the text field value and the value in the form context.
    transformOutput?: (d: string) => unknown;
    //transformation between the value in the form context and the value shown in the input.
    transformInput?: (d: unknown) => unknown;
    callback?: (event: React.ChangeEvent<{}>) => void;
    tooltip?: string;
};

/**
 * @deprecated use FieldTextControlled instead.
 */
export function TextFieldController<T extends FieldValues>({
    TextFieldProps,
    transformOutput = (x) => x,
    transformInput = (x) => x,
    callback,
    defaultValue,
    tooltip,
    ...rest
}: TextFieldControllerProps<T>): JSX.Element {
    return (
        <Controller
            render={({ field, fieldState }): JSX.Element => {
                const value = field.value === undefined ? defaultValue : transformInput(field.value);
                return (
                    <Tooltip arrow title={tooltip ?? ''}>
                        <TextField
                            {...field}
                            value={value}
                            style={{ width: '100%', ...TextFieldInputProps.style }}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            variant="outlined"
                            onChange={(event): void => {
                                const data = event.target.value;
                                if (data !== null) {
                                    field.onChange(transformOutput(data));
                                }
                                if (callback) {
                                    callback(event);
                                }
                            }}
                            onBlur={(event): void => {
                                const data = event.target.value;
                                if (data !== null) {
                                    field.onChange(transformOutput(data.trim()));
                                }
                                field.onBlur();
                            }}
                            {...TextFieldProps}
                        />
                    </Tooltip>
                );
            }}
            {...rest}
        />
    );
}

/**
 * @deprecated use FieldTextControlled instead.
 */
export function PasswordFieldController<T extends FieldValues>(
    textFieldProps: TextFieldControllerProps<T>,
): JSX.Element {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const fieldType = showPassword ? 'text' : 'password';

    const modifiedProps: TextFieldControllerProps<T> = {
        ...textFieldProps,
        TextFieldProps: {
            type: fieldType,
            size: 'large',
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <TertiaryIconButton
                            onClick={() => setShowPassword(!showPassword)}
                            disabled={textFieldProps.TextFieldProps?.disabled}
                        >
                            {showPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                        </TertiaryIconButton>
                    </InputAdornment>
                ),
            },
            ...textFieldProps.TextFieldProps,
        },
    };

    return <TextFieldController {...modifiedProps} />;
}

type RadioGroupControllerProps<T extends FieldValues> = BaseFieldControllerProps<T> & {
    options: Translations;
    RadioProps?: RadioProps;
    RadioGroupProps?: RadioGroupProps;
    callback?: (event: React.ChangeEvent<{}>) => void;
};

/**
 * @deprecated use FieldRadoControlled instead.
 */
export function RadioGroupController<T extends FieldValues>({
    name,
    control,
    options,
    rules,
    RadioProps,
    RadioGroupProps,
    callback,
}: RadioGroupControllerProps<T>): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }): JSX.Element => (
                <>
                    {fieldState.error?.message !== undefined && (
                        <Typography variant={'body2'} style={{ color: colorSystem.red[6] }}>
                            {fieldState.error?.message}
                        </Typography>
                    )}
                    <RadioGroup {...RadioGroupProps} {...field}>
                        {typeSafeObjectKeys(options).map((k) => {
                            return (
                                <FormControlLabel
                                    key={k}
                                    value={k}
                                    onChange={(event): void => {
                                        field.onChange(event);
                                        if (callback) {
                                            callback(event);
                                        }
                                    }}
                                    control={<Radio {...RadioProps} style={{ margin: '8px' }} />}
                                    label={
                                        <Typography variant="body1" color={'textPrimary'}>
                                            <TransEnum text={k} translations={options} />
                                        </Typography>
                                    }
                                />
                            );
                        })}
                    </RadioGroup>
                </>
            )}
        />
    );
}
type MuiCheckboxPropsWithNoCheckedOrName = Omit<CheckboxProps, 'checked,name'>;

type CheckboxFormControlControllerProps<T extends FieldValues> = BaseFieldControllerProps<T> & {
    label: string | JSX.Element;
    onChangeCallback?: (...event: any[]) => void;
    MuiCheckboxPropsWithNoCheckedOrName?: MuiCheckboxPropsWithNoCheckedOrName;
};

/**
 * @deprecated use FieldCheckboxController instead.
 */
export function CheckboxFormControlController<T extends FieldValues>({
    name,
    control,
    label,
    onChangeCallback,
    MuiCheckboxPropsWithNoCheckedOrName,
}: CheckboxFormControlControllerProps<T>): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }): JSX.Element => {
                if (typeof field.value !== 'boolean' && typeof field.value !== 'undefined') {
                    Sentry.captureMessage('field value neither boolean or undefined', 'error');
                }
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                color={'primary'}
                                checked={Boolean(field.value)}
                                name={name}
                                style={{ margin: '4px 8px' }}
                                onChange={(event) => {
                                    field.onChange(event);
                                    onChangeCallback?.(event);
                                }}
                                {...MuiCheckboxPropsWithNoCheckedOrName}
                            />
                        }
                        label={label}
                    />
                );
            }}
        />
    );
}

type CheckboxControllerProps<T extends FieldValues> = BaseFieldControllerProps<T> & {
    CheckboxProps?: CheckboxProps;
};

/**
 * @deprecated use FieldCheckboxController instead.
 */
export function CheckboxController<T extends FieldValues>({
    name,
    control,
    CheckboxProps = {},
}: CheckboxControllerProps<T>): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }): JSX.Element => {
                if (typeof field.value !== 'boolean' && typeof field.value !== 'undefined') {
                    Sentry.captureMessage('field value neither boolean or undefined', 'error');
                }
                return <Checkbox {...field} checked={Boolean(field.value)} name={name} {...CheckboxProps} />;
            }}
        />
    );
}

type SelectControllerProps<T extends FieldValues> = BaseFieldControllerProps<T> &
    React.PropsWithChildren<{
        style?: CSSProperties;
        translations?: Translations;
        SelectProps?: SelectProps;
        overrides?: { SelectComponent: React.ComponentType<SelectProps> };
        onChangeCallback?: (...event: any[]) => void;
        tooltip?: string;
    }>;

/**
 * @deprecated use FieldSelectControlled instead.
 */
export function SelectController<T extends FieldValues>({
    name,
    control,
    translations,
    SelectProps,
    style,
    children,
    onChangeCallback,
    rules,
    tooltip,
}: SelectControllerProps<T>): JSX.Element {
    const [isSelectOpen, updateSelectOpen] = useState<boolean>(false);
    const [isTooltipOpen, updateTooltipOpen] = useState<boolean>(false);
    return (
        <Controller
            render={(props): JSX.Element => {
                const { ref, onChange: fieldOnChange, ...field } = props.field;
                return (
                    <Tooltip
                        open={isTooltipOpen && !isSelectOpen}
                        arrow
                        title={tooltip ?? ''}
                        onOpen={(_e) => {
                            updateTooltipOpen(true);
                        }}
                        onClose={(_e) => {
                            updateTooltipOpen(false);
                        }}
                    >
                        <Select
                            {...field}
                            inputRef={ref}
                            variant="outlined"
                            style={{ width: '100%', ...style }}
                            name={name}
                            onChange={(event) => {
                                fieldOnChange(event);
                                onChangeCallback?.(event);
                            }}
                            onOpen={(_e) => {
                                updateSelectOpen(true);
                            }}
                            onClose={(_e) => {
                                updateSelectOpen(false);
                            }}
                            error={Boolean(props.fieldState.error)}
                            {...SelectProps}
                        >
                            {translations
                                ? Object.keys(translations).map((k, index) => (
                                      <MenuItem key={`${index}-${k}`} value={k}>
                                          <TransEnum text={k} translations={translations} />
                                      </MenuItem>
                                  ))
                                : children}
                        </Select>
                    </Tooltip>
                );
            }}
            control={control}
            name={name}
            rules={rules}
        />
    );
}
