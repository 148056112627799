import * as React from 'react';

// Dev feature flags are those which are used to
// show or hide testing features via a UI on preview environments.
type DevFeatureFlagsState = {
    showImportDemandButtons: boolean;
};

const initialDevFeatureFlags: DevFeatureFlagsState = {
    showImportDemandButtons: false,
};

const DevFeatureFlagsContext = React.createContext<
    | {
          featureFlags: DevFeatureFlagsState;
          setFeatureFlags: React.Dispatch<React.SetStateAction<DevFeatureFlagsState>>;
      }
    | undefined
>(undefined);

export const DevFeatureFlagsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [featureFlags, setFeatureFlags] = React.useState<DevFeatureFlagsState>(initialDevFeatureFlags);

    return (
        <DevFeatureFlagsContext.Provider value={{ featureFlags, setFeatureFlags }}>
            {children}
        </DevFeatureFlagsContext.Provider>
    );
};

export function useDevFeatureFlags() {
    const context = React.useContext(DevFeatureFlagsContext);
    if (!context) {
        throw new Error(
            "DevFeatureFlagsContext is not present, make sure you've set up the DevFeatureFlagsProvider correctly.",
        );
    }
    return context;
}
