/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatRelativeTime } from '@luminovo/commons';
import { StatusChip, Text, Tooltip } from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';
import { Box } from '@mui/material';
import * as React from 'react';
import { useQuoteRequest } from '../hooks/negotiationHandlers';
import { QuoteRequestStatusChip } from './QuoteRequestStatusChip';

export function SupplierPortalQuoteStatusChip({ quoteRequestId }: { quoteRequestId?: number }) {
    return (
        <React.Suspense fallback={<></>}>
            <QuoteStatusChip quoteRequestId={quoteRequestId} />
        </React.Suspense>
    );
}

function QuoteStatusChip({ quoteRequestId }: { quoteRequestId?: number }) {
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId, { suspense: true });
    if (!quoteRequestId || !quoteRequest) {
        return <></>;
    }
    const { status } = quoteRequest;

    if (status === QuoteRequestStatus.Received) {
        return (
            <Tooltip
                disableMaxWidth
                variant="white"
                title={
                    <StatusTooltip
                        title="Submitted"
                        description="The customer has received your quote."
                        quoteRequestId={quoteRequestId}
                    />
                }
            >
                <StatusChip color="green" label="Submitted" />
            </Tooltip>
        );
    }

    if (status === QuoteRequestStatus.Discarded) {
        return (
            <Tooltip
                disableMaxWidth
                variant="white"
                title={
                    <StatusTooltip
                        title="Discarded"
                        description="The customer marked this quote request as discarded. No response needed."
                        quoteRequestId={quoteRequestId}
                    />
                }
            >
                <StatusChip color="neutral" label="Discarded" />
            </Tooltip>
        );
    }

    if (status === QuoteRequestStatus.Sent) {
        return (
            <Tooltip
                disableMaxWidth
                variant="white"
                title={
                    <StatusTooltip
                        title="Not submitted"
                        description="The customer is waiting for your quote. Please submit it as soon as possible."
                        quoteRequestId={quoteRequestId}
                    />
                }
            >
                <StatusChip color="yellow" label="Not submitted" />
            </Tooltip>
        );
    }

    return <QuoteRequestStatusChip status={quoteRequest?.status} />;
}

function StatusTooltip({
    quoteRequestId,
    title,
    description,
}: {
    quoteRequestId: number;
    title: string;
    description: string;
}) {
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId, { suspense: true });

    if (!quoteRequest) {
        return <></>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '300px' }}>
            <Text variant="h3">{title}</Text>
            <Text>{description}</Text>
            <Text variant="h4">Event timeline</Text>
            <QuoteRequestTimeline quoteRequest={quoteRequest} />
        </Box>
    );
}

function QuoteRequestTimeline({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    const { sent_date, received_date } = quoteRequest;
    const sentAt = formatRelativeTime(quoteRequest.sent_date);
    const quotedAt = formatRelativeTime(quoteRequest.received_date);

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 1 }}>
            <Text>Sent </Text> <Text>{sent_date ? sentAt : t`Unknown`}</Text>
            <Text>Quoted </Text> <Text>{received_date ? quotedAt : t`Unknown`}</Text>
        </Box>
    );
}
