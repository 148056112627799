import { t } from '@lingui/macro';
import { debounce } from '@luminovo/commons';
import { Clear as ClearIcon } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { TertiaryIconButton } from '../buttons';
import { FieldConverter } from '../forms/BaseField';
import { SearchField } from './SearchField';
interface SearchInputProps<T> {
    value: string | undefined;
    onChange: (value: string) => void;
    autoFocus?: boolean;
    disabled?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
    size?: 'small' | 'large';
    onKeyEnter?: () => void;
    onClear?: () => void;
    debounceWait?: number;
    formatter?: {
        converter: FieldConverter<T>;
        validation: (value: T) => string | undefined;
    };
    InputProps?: React.ComponentProps<typeof SearchField>['InputProps'];
}

export function SearchInput<T = string>({
    value = '',
    onChange,
    autoFocus,
    style,
    placeholder = t`Type to search`,
    disabled,
    size = 'small',
    onKeyEnter,
    onClear,
    InputProps,
    debounceWait,
    formatter,
}: SearchInputProps<T>): JSX.Element {
    const [localValue, setLocalValue] = React.useState<string>(value);
    const [error, setError] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const debouncedOnChange = React.useMemo(() => {
        return debounce(onChange, debounceWait);
    }, [onChange, debounceWait]);

    const handleLocalChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setLocalValue(newValue);

            if (!formatter) {
                debouncedOnChange(newValue);
                return;
            } else {
                const parsed = formatter.converter.parseValue(newValue);
                if (!parsed.ok) {
                    setError(t`Invalid value`);
                    return;
                }
                const error = formatter.validation(parsed.value);
                setError(error);
                if (!error) {
                    debouncedOnChange(parsed.value as string);
                }
            }
        },
        [setLocalValue, debouncedOnChange, setError, formatter],
    );

    const handleKeyUp = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                onKeyEnter?.();
            }
        },
        [onKeyEnter],
    );

    const handleClear = React.useCallback(() => {
        onChange('');
        onClear?.();
    }, [onChange, onClear]);

    return (
        <SearchField
            autoFocus={autoFocus}
            type="text"
            value={localValue}
            onChange={handleLocalChange}
            placeholder={placeholder}
            disabled={disabled}
            size={size}
            onKeyUp={handleKeyUp}
            InputProps={{
                endAdornment: localValue && (
                    <InputAdornment position="end">
                        <TertiaryIconButton size="medium" onClick={handleClear} style={{ padding: 0, width: 'unset' }}>
                            <ClearIcon fontSize="inherit" />
                        </TertiaryIconButton>
                    </InputAdornment>
                ),
                error: Boolean(error),
                ...InputProps,
            }}
            style={{ width: '300px', ...style }}
        />
    );
}
