import { t } from '@lingui/macro';
import type { ParsedValue, Parser } from '../types';

export const parseIpn: Parser<string, { ipns: Array<{ ipn: string }> }> = async ([cell], opts, field) => {
    cell = cell.trim();
    const alternatives = opts.ipns.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        // Honestly, I'm not sure if we will ever support default values for IPNs
        // but I'm keeping it here for consistency and completeness
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'error',
            message: t`The IPN is required`,
            alternatives,
        };
    }

    const found = alternatives.find((alt) => {
        const supplierNumberMatch = alt.label && alt.label === cell;
        return supplierNumberMatch;
    });

    if (found) {
        return {
            status: 'done',
            value: found,
            existing: true,
            alternatives,
        };
    }

    if (isAllLettersOrSpaces(cell)) {
        return {
            status: 'warning',
            message: t`The IPN is usually a number. Are you sure this is correct?`,
            alternatives,
            value: {
                id: cell,
                label: cell,
                description: t`NEW`,
                existing: false,
            },
        };
    }

    return {
        value: {
            id: cell,
            label: cell,
            description: t`NEW`,
            existing: false,
        },
        status: 'done',
        alternatives,
    };
};

function toParsedValue({ ipn }: { ipn: string }): ParsedValue<string> {
    return {
        id: ipn,
        label: ipn,
        description: '',
        existing: true,
    };
}

function isAllLettersOrSpaces(str: string): boolean {
    return /^[a-zA-Z\s]+$/.test(str);
}
