import { AwardScenarioDTO } from '@luminovo/http-client';
import * as React from 'react';
import { useSelectedAwardScenario } from '../hooks/useSelectedAwardScenario';

const NegotiationsContext = React.createContext<NegotiationsContextType | undefined>(undefined);

type NegotiationsContextType = {
    selectedScenario: AwardScenarioDTO | null;
    setSelectedScenario: (scenario: Pick<AwardScenarioDTO, 'id'> | null) => void;

    referenceScenario: AwardScenarioDTO | null;
    setReferenceScenario: (scenario: Pick<AwardScenarioDTO, 'id'> | null) => void;
};

export function useNegotiationsContext() {
    const context = React.useContext(NegotiationsContext);
    if (context === undefined) {
        throw new Error('useNegotiationsContext must be used within a NegotiationsContextProvider');
    }
    return context;
}

export function NegotiationsContextProvider({
    children,
    negotiationId,
}: {
    children: React.ReactNode;
    negotiationId: number | undefined;
}) {
    const [selectedScenario, setSelectedScenario] = useSelectedAwardScenario(negotiationId, 'selectedScenarioId');
    const [referenceScenario, setReferenceScenario] = useSelectedAwardScenario(negotiationId, 'referenceScenarioId');

    const value: NegotiationsContextType = {
        selectedScenario,
        referenceScenario,
        setSelectedScenario,
        setReferenceScenario,
    };

    return <NegotiationsContext.Provider value={value}>{children}</NegotiationsContext.Provider>;
}
