import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import {
    CalculationType,
    CalculationTypeWithNone,
    CategorySelection,
    ManufacturingEntityStatusWithError,
    ProcessSelection,
} from '@luminovo/http-client';

export const calculationTypePublicTranslations: Record<CalculationType, MessageDescriptor> = {
    Fixed: defineMessage({ message: 'Fixed' }),
    Linear: defineMessage({ message: 'Linear' }),
    Formula: defineMessage({ message: 'Formula' }),
};

export const calculationTypeWithNullPublicTranslation: Record<CalculationTypeWithNone, MessageDescriptor> = {
    ...calculationTypePublicTranslations,
    None: defineMessage({ message: 'None' }),
};

//this order is used for sorting  so be careful when changing https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3074457359358058617&cot=10
export const processPublicTranslations: Record<ProcessSelection, MessageDescriptor> = {
    ProcessPreparation: defineMessage({ message: 'Process preparation' }),
    NPI: defineMessage({ message: 'NPI' }),
    SMT: defineMessage({ message: 'SMT' }),
    THT: defineMessage({ message: 'THT' }),
    HandSoldering: defineMessage({ message: 'Hand soldering' }),
    PanelSeparation: defineMessage({ message: 'Panel separation' }),
    Potting: defineMessage({ message: 'Potting' }),
    Ruggedizing: defineMessage({ message: 'Ruggedizing' }),
    Testing: defineMessage({ message: 'Testing' }),
    Inspection: defineMessage({ message: 'Inspection' }),
    CableAssembly: defineMessage({ message: 'Cable assembly' }),
    MechanicalAssembly: defineMessage({ message: 'Mechanical assembly' }),
    QualityControl: defineMessage({ message: 'Quality control' }),
    Packaging: defineMessage({ message: 'Packaging' }),
    Logistics: defineMessage({ message: 'Logistics' }),
    Other: defineMessage({ message: 'Other' }),
};

//this order is used for sorting  so be careful when changing https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3074457359358058617&cot=10
export const categorySelectionPublicTranslations: Record<CategorySelection, MessageDescriptor> = {
    Preparation: defineMessage({ message: 'Preparation' }),
    Execution: defineMessage({ message: 'Execution' }),
    Inspection: defineMessage({ message: 'Inspection' }),
    PostProcessing: defineMessage({ message: 'Post processing' }),
};

export const statusTranslationsWithError: Record<ManufacturingEntityStatusWithError, MessageDescriptor> = {
    Active: defineMessage({ message: 'Active' }),
    Inactive: defineMessage({ message: 'Inactive' }),
    Error: defineMessage({ message: 'Error' }),
};
