/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WeltronCredentialsDTO extends r.Static<typeof WeltronCredentialsDTORuntype> {}
export const WeltronCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface WeltronCredentialsInputDTO extends r.Static<typeof WeltronCredentialsInputDTORuntype> {}
export const WeltronCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface WeltronResponseBodyDTO extends r.Static<typeof WeltronResponseBodyDTORuntype> {}
export const WeltronResponseBodyDTORuntype = r.Record({
    data: WeltronCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
