import { UniqueIdentifier } from '@dnd-kit/core';
import { Column, TableState, useDataTableState, UseDataTableStateProps } from '@luminovo/design-system';
import React from 'react';

interface SortingUpdate<T> {
    updatedItems: T[];
    updatedItemId: UniqueIdentifier;
}

export interface SortableDataTableState<T> {
    tableState: TableState<T, undefined>;
    columns: Column<T>[];
    items: T[];
    setCurrentUpdate: React.Dispatch<React.SetStateAction<SortingUpdate<T> | undefined>>;
    currentUpdate: SortingUpdate<T> | undefined;
}

export function useSortableDataTableState<T>(
    dataTableStateProps: Omit<UseDataTableStateProps<T>, 'paginationOptions'>,
): SortableDataTableState<T> {
    // We keep track of the current update while the onDragEndCallback is running
    // to be able to display a loading state.
    const [currentUpdate, setCurrentUpdate] = React.useState<SortingUpdate<T> | undefined>(undefined);
    // If we are currently updating we display the desired order of the items
    const items = currentUpdate?.updatedItems ?? dataTableStateProps.items;

    const columns: Column<T>[] = [
        // An extra column to create space for the drag handle
        {
            id: 'drag-handle-column',
            label: '',
            render: () => <></>,
        },
        ...dataTableStateProps.columns,
    ];

    const tableState = useDataTableState<T>({
        ...dataTableStateProps,
        columns,
        items,
        // We don't implement sorting across pages and
        // display all items on a single page, instead
        paginationOptions: {
            showPagination: false,
            rowsPerPageOptions: [5000],
            defaultRowsPerPage: 5000,
        },
    });
    return { tableState, columns, items, setCurrentUpdate, currentUpdate };
}
