/* eslint-disable spellcheck/spell-checker */
import { Trans } from '@lingui/macro';
import { Text, colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';

export function UpsellBox(): JSX.Element {
    const { supplier } = useCurrentUserDetailsContext();

    if (!supplier) {
        return <></>;
    }
    return (
        <a style={{ textDecoration: 'none' }} href={linkToHelp()}>
            <Box
                sx={{
                    background: `linear-gradient(135deg, ${colorSystem.primary[1]}, ${colorSystem.primary[1]})`,
                    padding: '20px',
                    borderRadius: '8px',
                    border: `1px solid ${colorSystem.primary[3]}`,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                <Text variant="h3">
                    <Trans>Looking for a better way to manage your quotes?</Trans>
                </Text>
                <Text>
                    <Trans>
                        Luminovo can help you organize, track and manage your quotes. Interested?{' '}
                        <b style={{ color: colorSystem.primary[8] }}>Let's talk</b>
                    </Trans>
                </Text>
            </Box>
        </a>
    );
}

function linkToHelp() {
    const encodedSubject = encodeURIComponent('Interested in Luminovo quote tracking');
    const encodedBody = encodeURIComponent('Hi Nils, I am interested in your quote portal solution.');
    return `mailto:nils.minor@luminovo.com?subject=${encodedSubject}&body=${encodedBody}`;
}
