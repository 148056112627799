import { Trans, t } from '@lingui/macro';
import { Permission, usePermissions } from '@luminovo/auth';
import { assertUnreachable } from '@luminovo/commons';
import {
    DataTable,
    DataTableRow,
    Flexbox,
    SecondaryButton,
    Text,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    AssemblyIndustry,
    CustomOptionTypes,
    OriginSetMethod,
    OtsComponentFull,
    OtsOrCustomComponent,
    PartSpecificationTypes,
    RfqContext,
} from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { useFormState } from 'react-hook-form';
import { isEmptyQuery } from '../../../../components/SearchContainer';
import { isComponentRemoved } from '../../../../components/partColumns/isComponentRemoved';
import { useCustomComponentDrawer } from '../../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { analytics } from '../../../../utils/analytics';
import { TableContainer } from '../AddParts/TableContainer';
import { useHasDesignatorQuantityMismatch } from '../AddParts/hasDesignatorOrQtyMismatch';
import { ipnLinkingDialog, useIpnLinkingDialog } from '../LinkPartsDialog/useIpnLinkingDialog';
import { PartSpecificationContext } from '../SpecificationTypeForms/types';
import { useComponentsParametricSearch } from './ComponentsParametricSearchInput';
import { UseComponentsSearchStateResult, useComponentsParametricSearchState } from './ErpParametricSearchFunctions';
import { ErpParametricSearchInput } from './ErpParametricSearchInput';
import {
    columnActions,
    columnAvailability,
    columnCompliance,
    columnCpn,
    columnDescription,
    columnIpn,
    columnLifecycle,
    columnManufacturer,
    columnMpn,
    columnPackage,
} from './columns';
import { ErpSearchTableContext } from './erpSearchTypes';
export const DesignItemParametricSearchErpData = ({
    rfqContext,
    onRemovePart,
    existingPartIds,
    rfqId,
    bomItem,
    assemblyId,
    assemblyIndustry,
    displayOtsFilters,
    partSpecificationContext,
}: {
    rfqContext: RfqContext;
    onRemovePart: (partId: string) => void;
    existingPartIds: string[] | undefined;
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    assemblyIndustry?: AssemblyIndustry;
    displayOtsFilters: boolean;
    partSpecificationContext: PartSpecificationContext;
}): JSX.Element => {
    const { permissions } = usePermissions();
    const searchState = useComponentsParametricSearchState({ displayOtsFilters });

    const handleAnalytic = (searchState: UseComponentsSearchStateResult) => {
        /* eslint-disable camelcase */
        analytics.track('start_erp_search_in_design_module', {
            rfq_id: rfqId,
            assembly_uuid: bomItem.parentId,
            bom_item_ids: bomItem.id,
            search_fields: searchState.state.selectedBlocks.map((block) => block.field),
        });
        /* eslint-enable camelcase */
    };

    const {
        data: searchResults,
        isFetching,
        fetchNextPage,
        hasNextPage,
    } = useComponentsParametricSearch({
        searchState,
        rfqContext,
        onAnalytic: handleAnalytic,
        prioritisePreferredIpns: true,
    });

    const ipnsList = searchResults?.pages.flatMap((page) => page.ipns) ?? [];

    const currentPage = searchResults && searchResults.pages[searchResults.pages.length - 1];
    const searchEntered = !isEmptyQuery(searchState);

    return (
        <>
            <Flexbox gap="8px" flexDirection="column">
                <ErpParametricSearchInput searchState={searchState} searchResultsPage={currentPage} />
            </Flexbox>
            {searchEntered && isFetching && !searchResults && (
                <div style={{ padding: '4px' }}>
                    <CircularProgress size={20} />
                </div>
            )}
            {searchEntered && searchResults && (
                <>
                    <ErpSearchContainer
                        rfqId={rfqId}
                        bomItem={bomItem}
                        ipnsList={ipnsList}
                        assemblyId={assemblyId}
                        handleRemovePart={onRemovePart}
                        existingPartIds={existingPartIds}
                        rfqContext={rfqContext}
                        permissions={permissions}
                        partSpecificationContext={partSpecificationContext}
                        assemblyIndustry={assemblyIndustry}
                    />
                    <Box display="grid" gap="8px" style={{ placeItems: 'center' }}>
                        {hasNextPage ? (
                            <SecondaryButton
                                disabled={isFetching}
                                onClick={() => fetchNextPage()}
                                startIcon={isFetching && <CircularProgress size={20} />}
                            >
                                <Trans>Load more</Trans>
                            </SecondaryButton>
                        ) : (
                            <NoMoreResultsContainer ipnsList={ipnsList} />
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

const NoMoreResultsContainer = ({ ipnsList }: { ipnsList: OtsOrCustomComponent[] }) => {
    if (ipnsList.length === 0) {
        return null;
    }
    return (
        <Text variant="h5" color={colorSystem.neutral[7]}>
            <Trans> No more results for your search </Trans>
        </Text>
    );
};

const ErpSearchContainer = ({
    rfqId,
    assemblyId,
    bomItem,
    ipnsList,
    handleRemovePart,
    existingPartIds,
    rfqContext,
    permissions,
    partSpecificationContext,
    assemblyIndustry,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    ipnsList: OtsOrCustomComponent[];
    rfqContext: RfqContext;
    handleRemovePart: (partId: string) => void;
    existingPartIds: string[] | undefined;
    permissions: Permission[];
    partSpecificationContext: PartSpecificationContext;
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const { handleAddPart: addPart, partSpecificationType } = partSpecificationContext;
    const { openDialog: openIpnLinkingDialog } = useIpnLinkingDialog();

    const handleAddPart = React.useCallback(
        (component: OtsOrCustomComponent) => {
            if (
                partSpecificationType === PartSpecificationTypes.Custom &&
                component.type === PartSpecificationTypes.Custom
            ) {
                return addPart({
                    part: { type: CustomOptionTypes.CustomComponent, data: component.data.id },
                    // eslint-disable-next-line camelcase
                    approval_status: ApprovalStatus.Approved,
                    // eslint-disable-next-line camelcase
                    origin: { set_method: OriginSetMethod.Manual, reasons: [] },
                });
            }
            if (
                partSpecificationType === PartSpecificationTypes.OffTheShelf &&
                component.type === PartSpecificationTypes.OffTheShelf
            ) {
                const ipnWithMatches: OtsComponentFull = component.data;

                const ipnLinkingData = ipnLinkingDialog({
                    partOptions: bomItem.parts,
                    partToAddAsPartOption: ipnWithMatches,
                    permissions,
                });

                if (ipnLinkingData.shouldOpenIpnLinkingDialog) {
                    const { ipn, partsToLink, partToAddAsPartOption } = ipnLinkingData;
                    return openIpnLinkingDialog({ ipn, partsToLink, addPart, partToAddAsPartOption });
                }

                return addPart(ipnWithMatches, 'ipnSearch');
            }
            return;
        },
        [partSpecificationType, bomItem.parts, permissions, addPart, openIpnLinkingDialog],
    );

    return (
        <ErpSearchTable
            ipnsList={ipnsList}
            rfqContext={rfqContext}
            rfqId={rfqId}
            assemblyId={assemblyId}
            bomItem={bomItem}
            handleAddPart={handleAddPart}
            handleRemovePart={handleRemovePart}
            partSpecificationType={partSpecificationContext.partSpecificationType}
            existingPartIds={existingPartIds}
            assemblyIndustry={assemblyIndustry}
        />
    );
};

const ErpSearchTable = ({
    ipnsList,
    rfqContext,
    rfqId,
    assemblyId,
    bomItem,
    existingPartIds,
    handleAddPart,
    handleRemovePart,
    partSpecificationType,
    assemblyIndustry,
}: {
    ipnsList: OtsOrCustomComponent[];
    rfqContext: RfqContext;
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    handleAddPart: (component: OtsOrCustomComponent) => void;
    handleRemovePart: (partId: string) => void;
    partSpecificationType: PartSpecificationTypes;
    existingPartIds: string[] | undefined;
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openCustomComponentDrawer } = useCustomComponentDrawer();
    const { isSubmitting } = useFormState();
    const hasDesignatorQuantityMismatch = useHasDesignatorQuantityMismatch();
    const sharedContext: ErpSearchTableContext = {
        rfqContext,
        rfqId,
        assemblyId,
        bomItem,
        handleAddPart,
        handleRemovePart,
        partSpecificationType,
        isSubmitting,
        existingPartIds,
        hasDesignatorQuantityMismatch,
        assemblyIndustry,
    };

    const tableState = useDataTableState({
        columns: [
            columnIpn,
            columnCpn,
            columnMpn,
            columnManufacturer,
            columnDescription,
            columnPackage,
            columnAvailability,
            columnLifecycle,
            columnCompliance,
            columnActions,
        ],
        items: ipnsList,
        persistenceId: `erp-search-table`,
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 5000,
            rowsPerPageOptions: [5000],
        },
        sharedContext,
    });

    const handleRowClick = useCallback(
        (component: OtsOrCustomComponent) => {
            const componentType = component.type;
            if (componentType === 'OffTheShelf') {
                return openIpnDrawer({ ipnId: component.data.id, rfqContext });
            }
            if (componentType === 'Custom') {
                return openCustomComponentDrawer({ componentId: component.data.id, rfqContext });
            }
            assertUnreachable(componentType);
        },
        [openCustomComponentDrawer, openIpnDrawer, rfqContext],
    );

    return (
        <DataTable
            tableState={tableState}
            size="large"
            stickyHeader={false}
            onItemClick={handleRowClick}
            overrides={{
                Container: TableContainer,
                TableRow: ({ item, ...tableRowProps }) => {
                    const isRemoved = isComponentRemoved(item.data);
                    return (
                        <DataTableRow
                            {...tableRowProps}
                            tooltip={isRemoved ? t`Removed IPN` : ''}
                            item={item}
                            style={{
                                ...tableRowProps.style,
                                background: isRemoved ? colorSystem.neutral[0] : 'white',
                            }}
                        />
                    );
                },
            }}
        />
    );
};
