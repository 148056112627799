import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    MyArrowCredentialsInputDTORuntype,
    MyArrowLocaleCurrencyDTORuntype,
    MyArrowResponseBodyDTORuntype,
} from './myArrowBackendTypes';

export const myArrowEndpoints = {
    'GET /3rdparty/myarrow/credentials': endpoint({
        description: 'Returns credentials for myArrow integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: MyArrowResponseBodyDTORuntype,
    }),
    'POST /3rdparty/myarrow/credentials': endpoint({
        description: 'Uploads credentials for myArrow API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MyArrowCredentialsInputDTORuntype,
        responseBody: MyArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'PATCH /3rdparty/myarrow/credentials': endpoint({
        description: 'Updates credentials for myArrow API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MyArrowCredentialsInputDTORuntype,
        responseBody: MyArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'DELETE /3rdparty/myarrow/credentials': endpoint({
        description: 'Deletes existing credentials for myArrow API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'GET /3rdparty/myarrow/supported-currencies': endpoint({
        description: 'Returns possible currencies for myArrow',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ currencies: r.Array(MyArrowLocaleCurrencyDTORuntype) }),
    }),
};
