import { isPresent, uniqBy } from '@luminovo/commons';
import { PartDTO, SupportedPartsDTO } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../http/useHttpQuery';
import { useNonExcludedSupplierAndStockLocations } from '../supplierAndStockLocation/supplierAndStockLocationHandler';

export function useSuppliers() {
    return useHttpQuery('GET /suppliers', {}, { suspense: true, staleTime: Infinity, select: (res) => res.items });
}

export function useSupplier(id: string = '') {
    return useHttpQuery(
        'GET /suppliers',
        {},
        {
            staleTime: Infinity,
            enabled: Boolean(id),
            select: (res) => res.items.find((supplier) => supplier.id === id),
        },
    );
}

export function useSupportedPartsOfSupplier(
    rfqId: string,
    partIds: PartDTO[] | undefined,
    supplierIds: string[] | undefined,
    options: { suspense: boolean } = { suspense: false },
): UseQueryResult<SupportedPartsDTO[]> {
    return useHttpQuery(
        'POST /suppliers/supported-parts',
        {
            /* eslint-disable camelcase */
            requestBody: {
                rfq_id: rfqId,
                part_ids: Array.from((partIds ?? []).map((p) => p.data)).sort(),
                supplier_ids: Array.from(supplierIds ?? []).sort(),
            },
            /* eslint-enable camelcase */
        },
        {
            enabled: isPresent(partIds) && isPresent(supplierIds),
            select: (res) => res.items,
            ...options,
        },
    );
}

export function useNonExcludedSuppliers() {
    const { data: supplierAndStockLocations, isLoading } = useNonExcludedSupplierAndStockLocations();
    const suppliers = supplierAndStockLocations
        ? uniqBy(
              supplierAndStockLocations.map((supplier_and_stock_location) => supplier_and_stock_location.supplier),
              (supplier) => supplier.id,
          )
        : undefined;
    return {
        data: suppliers,
        isLoading,
    };
}
