/* eslint-disable camelcase */
import { Currency, assertPresent, assertUnreachable, isPresent } from '@luminovo/commons';
import {
    OtsFullPart,
    Packaging,
    PriceType,
    QuantityUnit,
    RfqDTO,
    StandardPartOfferBulkInputDTO,
    StandardPartTypes,
} from '@luminovo/http-client';
import { convertValidForToCustomer, convertValidForToRfqId } from '../../../../model';
import { RegionNetwork } from './RegionNetwork';
import { PdfExtractionResults, composeExtractionResults } from './composeExtractionResults';
import { LeadTime } from './parsers/LeadTime';
import { LeadTimeUnit } from './parsers/LeadTimeUnit';

export interface CreateOffersFromPdfExtractionProps {
    quoteTrackingId: string;
    regionNetwork: RegionNetwork;
    rfq: RfqDTO;
    supplierAndStockLocation: string;
    uploadedUrl?: string;
}

export function createOffersFromRegionNetwork({
    quoteTrackingId,
    regionNetwork,
    rfq,
    supplierAndStockLocation,
    uploadedUrl,
}: CreateOffersFromPdfExtractionProps): StandardPartOfferBulkInputDTO[] {
    const extractionResults = composeExtractionResults(regionNetwork);

    // Group rows by part, packaging and unit of measurement
    const groupedRows = groupBy(
        extractionResults.extractedRows.filter((row) => row.fields.part?.value),
        (row) => {
            return JSON.stringify([
                row.fields.part?.value?.value?.id,
                row.fields.packaging?.value?.value,
                row.fields.unit?.value.value,
            ]);
        },
    );

    const offers = Object.values(groupedRows).map((rows) => {
        return createOfferFromGroupedRows({
            rows,
            extractionResults,
            quoteTrackingId,
            supplierAndStockLocation,
            rfq,
            uploadedUrl,
        });
    });

    return offers;
}

export function leadTimeInDays(leadTime?: LeadTime): number | null {
    if (!leadTime) return null;
    switch (leadTime.unit) {
        case LeadTimeUnit.Days:
            return leadTime.value;
        case LeadTimeUnit.Weeks:
            return leadTime.value * 7;
        case LeadTimeUnit.Months:
            return leadTime.value * 30;
        default:
            assertUnreachable(leadTime.unit);
    }
}

function createOfferFromGroupedRows({
    rows,
    extractionResults,
    supplierAndStockLocation,
    rfq,
    uploadedUrl,
}: {
    rows: PdfExtractionResults['extractedRows'];
    extractionResults: PdfExtractionResults;
    quoteTrackingId: string;
    supplierAndStockLocation: string;
    rfq: RfqDTO;
    uploadedUrl?: string;
}): StandardPartOfferBulkInputDTO {
    // Pick the first packaging.
    const packaging: Packaging | null = getAggregatedValue({
        rows,
        getValue: (row) => {
            const packaging = row.fields.packaging?.value.value ?? null;
            if (packaging === 'none') {
                return null;
            }
            return packaging;
        },
        aggregate: (packagings) => packagings[0],
    });

    // Pick the first part
    const otsPart: OtsFullPart = getAggregatedValue({
        rows,
        getValue: (row) => row.fields.part?.value?.value,
        aggregate: assertFirst,
    });

    // Pick the max stock
    const stock: number = getAggregatedValue({
        rows,
        getValue: (row) => row.fields.stock?.value?.value ?? 0,
        aggregate: (nums) => Math.max(...nums),
    });

    // Pick the max factory lead time
    const factoryLeadTimeDays: number | null = getAggregatedValue({
        rows,
        getValue: (row) => leadTimeInDays(row.fields.standardFactoryLeadTime?.value?.value),
        aggregate: (nums) => (nums.every((n) => n === null) ? null : Math.max(...nums.filter(isPresent))),
    });

    // Pick the first unit
    const unit: QuantityUnit = getAggregatedValue({
        rows,
        getValue: (row) => assertPresent(row.fields.unit?.value?.value),
        aggregate: assertFirst,
    });

    const currency: Currency = extractionResults.currency.value.value;
    const offerNumber = extractionResults.offerNumber?.value?.value;
    const validUntil = extractionResults.validUntil?.value?.value;

    const validFor = extractionResults.validFor?.value?.value;

    const offer: StandardPartOfferBulkInputDTO = {
        requested_part: {
            type: StandardPartTypes.OffTheShelf,
            data: otsPart.id,
        },
        linked_part: {
            type: StandardPartTypes.OffTheShelf,
            data: otsPart.id,
        },
        currency,
        notes: '',
        offer_number: offerNumber ?? null,
        price_type: PriceType.QuotePrice,
        packaging,
        availability_input: {
            stock,
            factory_lead_time_days: factoryLeadTimeDays,
            factory_quantity: null,
            on_order: [],
        },
        valid_until: validUntil ? validUntil : null,
        supplier_and_stock_location: supplierAndStockLocation,
        customer: convertValidForToCustomer(validFor, rfq.customer),
        rfq_id: convertValidForToRfqId(validFor, rfq.id),
        unit_of_measurement: {
            unit,
            quantity: 1,
        },
        supplier_part_number: undefined,
        price_break_inputs: rows.map((row) => {
            return {
                moq: assertPresent(row.fields.moq?.value?.value),
                mpq: assertPresent(row.fields.mpq?.value?.value),
                unit_price: assertPresent(row.fields.unitPrice?.value?.value),
                lead_time_days: null,
            };
        }),
        ncnr: null,
        attachment: uploadedUrl ?? null,
    };

    return offer;
}

function getAggregatedValue<T, K>({
    rows,
    getValue,
    aggregate,
}: {
    rows: PdfExtractionResults['extractedRows'];
    getValue: (row: PdfExtractionResults['extractedRows'][0]) => T;
    aggregate: (values: T[]) => K;
}): K {
    return aggregate(
        rows.map((row) => {
            const field = getValue(row);
            return field;
        }),
    );
}

function assertFirst<T>(arr: T[]): Exclude<T, undefined | null> {
    return assertPresent(arr[0])!;
}

function groupBy<T>(items: T[], getKey: (item: T) => string): Record<string, T[]> {
    const result: Record<string, T[]> = {};
    for (const item of items) {
        const key = getKey(item);
        if (result[key] === undefined) {
            result[key] = [];
        }
        result[key].push(item);
    }
    return result;
}
