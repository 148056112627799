import { Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Column, columnWidth, Filter, Row } from '@luminovo/design-system';
import { CategorySelection } from '@luminovo/http-client';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';
import { categorySelectionPublicTranslations } from '../sharedManufacturingBackendTypes';

interface RowWithCategory {
    category: CategorySelection;
}

function getCategoryFilters<C>(): Filter<RowWithCategory, C>[] {
    return [
        {
            id: 'category=Inspection',
            label: <Trans>Inspection</Trans>,
            predicate: (rowData, _) => rowData.category === 'Inspection',
        },
        {
            id: 'category=Preparation',
            label: <Trans>Preparation</Trans>,
            predicate: (rowData, _) => rowData.category === 'Preparation',
        },
        {
            id: 'category=Execution',
            label: <Trans>Execution</Trans>,
            predicate: (rowData, _) => rowData.category === 'Execution',
        },
        {
            id: 'category=PostProcessing',
            label: <Trans>Post processing</Trans>,
            predicate: (rowData, _) => rowData.category === 'PostProcessing',
        },
    ];
}

export function getCategoryColumn<C>(): Column<RowWithCategory, C> {
    return {
        label: <Trans>Category</Trans>,
        id: 'category',
        render: ({ data: rowData }: Row<RowWithCategory>) => (
            <TextCell firstRow={transEnum(rowData.category, categorySelectionPublicTranslations)} minWidth={160} />
        ),
        width: columnWidth.small,
        filters: getCategoryFilters(),
    };
}
