import { Trans, t } from '@lingui/macro';
import {
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { useDialogContext } from '../../../../components/contexts/ModalContext';

export function useAddPartWithBlockedManufacturerDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (mpn: string, manufacturer: string, onConfirm: (e: any) => void) =>
            setDialog(
                <Dialog maxWidth="xs" open={true} onClose={closeDialog}>
                    <DialogTitle title={t`Blocked manufacturer`} handleClose={closeDialog} />
                    <DialogContent>
                        <Text>{t`Are you sure you want to add this part option ${mpn} from the blocked manufacturer ${manufacturer}? You can change the manufacturer preferences in settings.`}</Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton autoFocus size="medium" onClick={closeDialog}>
                            <Trans>No, keep in suggestions</Trans>
                        </SecondaryButton>
                        <DestructivePrimaryButton
                            size="medium"
                            onClick={(e) => {
                                closeDialog();
                                onConfirm(e);
                            }}
                        >
                            <Trans>Add part</Trans>
                        </DestructivePrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}
