import { compareByStringKey } from '@luminovo/commons';
import { chainComparators, Comparable, compareByNumber } from '@luminovo/design-system';
import { QuantityUnitDTO } from '@luminovo/http-client';

export function compareByQuantity<T>(extractQuantity: (item: T) => QuantityUnitDTO | undefined): Comparable<T> {
    return chainComparators(
        compareByStringKey((item) => extractQuantity(item)?.unit ?? ''),
        compareByNumber((item) => extractQuantity(item)?.quantity ?? 0),
    );
}
