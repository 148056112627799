import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parsePeelableMask, parseSide, parseSilkscreenSide, parseSoldermaskSide } from '../parsers/parseSide';
import { parseYes } from '../parsers/parseYesNo';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractSide: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const specificParsers = [parseSoldermaskSide, parseSilkscreenSide, parsePeelableMask];
    const specificAttributes = specificParsers.map((parser) => parser(stringToParse)).filter(isPresent);
    if (specificAttributes.length > 0) {
        return specificAttributes.map((attribute) =>
            setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute)),
        );
    }
    // we also want to parse silkscreen and soldermask but they are already parsed in extractColor
    const attribute = parseSide(stringToParse);
    // with sides we need to be more conservative than colors so we also need a "yes"-value
    if (isPresent(attribute) && parseYes(stringToParse)) {
        return [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute))];
    }
    return [];
};
