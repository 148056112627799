import { findNearestTopLeftRegion } from '../../framework/findNearestTopLeftRegion';
import { Region } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';

export const combineBooleanSpecs = (regions: Region<PcbAttribute>[]): Region<PcbAttribute>[] => {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );
    const regionsWithValue = regionsWithAttributes.filter(({ attribute }) => attribute.attr === 'yes');
    const regionsWithKeyword = regionsWithAttributes.filter(
        ({ attribute }) => attribute.attr === 'eTest' || attribute.attr === 'impedanceTested',
    );
    const maxDistanceToKeyword = 0.1;
    regionsWithValue.forEach(({ region, attribute }) => {
        // find nearest region with soldermask or silkscreen
        const nearestKeyword = findNearestTopLeftRegion(region, regionsWithKeyword, maxDistanceToKeyword);
        if (nearestKeyword) {
            if (nearestKeyword.attribute.attr === 'eTest') {
                region.attributes.push({
                    attr: 'eTest',
                    value: attribute.value as boolean,
                    selected: attribute.selected,
                    // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                    // about these links than about direct matches
                    // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                    confidence:
                        ((nearestKeyword.attribute.confidence ?? 0.75) * (attribute.confidence ?? 0.75)) /
                        (1 + nearestKeyword.distance),
                });
            }
            if (nearestKeyword.attribute.attr === 'impedanceTested') {
                region.attributes.push({
                    attr: 'impedanceTested',
                    value: attribute.value as boolean,
                    selected: attribute.selected,
                    // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                    // about these links than about direct matches
                    // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                    confidence:
                        ((nearestKeyword.attribute.confidence ?? 0.75) * (attribute.confidence ?? 0.75)) /
                        (1 + nearestKeyword.distance),
                });
            }
        }
    });
    return regions;
};
