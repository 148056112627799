import { Trans } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { ActorResponseRuntypeDTO, HistoryOperation, HistoryRecordFlagDTO } from '@luminovo/http-client';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, StyledTimelineDot, TimelineContentContainer, TimelineText } from './Utils';

type Props = {
    flag: HistoryRecordFlagDTO;
    operation: HistoryOperation;
    actor: ActorResponseRuntypeDTO;
};
export default function IgnoreManufacturerDataWarningContent({ flag, operation, actor }: Props) {
    if (!isActorDefined(actor)) return null;
    return flag.marked ? <WarningsIgnored actor={actor} /> : <WarningsReconsidered actor={actor} />;
}

type WarningProps = {
    actor: ActorResponseRuntypeDTO;
};

function WarningsIgnored({ actor }: WarningProps) {
    return (
        <TimelineContentContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TimelineText>
                    <Trans>
                        <strong>Inconsistent mounting, package or number of pins</strong> warning ignored
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            </div>
        </TimelineContentContainer>
    );
}

function WarningsReconsidered({ actor }: WarningProps) {
    return (
        <TimelineContentContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TimelineText>
                    <Trans>
                        <strong>Inconsistent mounting, package or number of pins</strong> warning reconsidered
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            </div>
        </TimelineContentContainer>
    );
}

export function IgnoreManufacturerDataWarningLineDot() {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.neutral[2],
            }}
        >
            <ReportOffOutlinedIcon style={{ width: '20px', height: '20px', color: colorSystem.neutral[7] }} />
        </StyledTimelineDot>
    );
}
