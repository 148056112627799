import * as r from 'runtypes';
import { ImporterConfig, Table } from '../../../types';

/**
 * A store to save and load column mappings for a specific table.
 */
export class ColumnMappingStore {
    constructor(private storage: Storage) {}

    public get(table: Table, config: ImporterConfig): ImporterConfig {
        const tableHash = calculateTableHash(table);

        if (!tableHash) {
            return config;
        }

        const storedConfig = this.storage.getItem(tableHash);

        if (!storedConfig) {
            return config;
        }

        try {
            const parsed = StoredJsonRuntype.check(JSON.parse(storedConfig));

            return {
                ...config,
                fields: config.fields.map((field) => {
                    const columnIndices = parsed[field.id];
                    if (!columnIndices) {
                        return field;
                    }
                    // check if the indices are too large
                    // if so, ignore the stored config
                    if (columnIndices.some((index) => index >= table[0].length)) {
                        return field;
                    }

                    return {
                        ...field,
                        columnIndices,
                    };
                }),
            };
        } catch (e) {
            console.error(e);
            return config;
        }
    }

    public set(table: Table, config: ImporterConfig): void {
        const tableHash = calculateTableHash(table);

        if (!tableHash) {
            return;
        }

        const storedConfig = config.fields.reduce((acc: Record<string, number[]>, field) => {
            acc[field.id] = field.columnIndices;
            return acc;
        }, {});

        this.storage.setItem(tableHash, JSON.stringify(storedConfig));
    }
}

const StoredJsonRuntype = r.Dictionary(r.Array(r.Number), r.String);

function calculateTableHash(table: Table): string | undefined {
    const headerRow = table.find((row) => {
        row = row.map((cell) => cell.trim());
        if (row.length === 0) {
            return false;
        }
        const emptyCells = row.filter((cell) => cell.length === 0).length;
        const totalCells = row.length;
        if (emptyCells / totalCells > 0.2) {
            return false;
        }
        return true;
    });

    if (!headerRow) {
        return undefined;
    }

    return `universal-importer::column-mapping-store::${JSON.stringify(headerRow)}`;
}
