import type { LocalFile, ParseResult } from 'papaparse';
import { Encoding, Row } from '../types';

export type InputFile = LocalFile;

export async function readFile(input: InputFile, encoding: Encoding = 'UTF-8'): Promise<ParseResult<Row>> {
    const papa = await import('papaparse');

    return new Promise<ParseResult<Row>>((resolve, reject) =>
        papa.parse<Row>(input, {
            header: false,
            worker: true,
            dynamicTyping: false,
            skipEmptyLines: true,

            complete(results) {
                resolve(results);
            },
            encoding: encoding,
            error: (error) => {
                reject(error);
            },
        }),
    );
}
