export type SignupBackgroundProps = {
    primary?: string;
    primaryDarker?: string;
    background?: string;
};

export function SignupBackground({
    primary = '#6F7FFF',
    primaryDarker = '#666FE1',
    background = '#6166D1',
}: SignupBackgroundProps): JSX.Element {
    return (
        <svg
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                height: '130%',
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 840 1024"
        >
            <g clipPath="url(#clip0_1315_48848)">
                <path fill={background} d="M0 0H1200V1200H0z" />
                <g filter="url(#filter0_f_1315_48848)">
                    <mask
                        id="mask0_1315_48848"
                        style={{ maskType: 'alpha' }}
                        width="1200"
                        height="1200"
                        x="0"
                        y="0"
                        maskUnits="userSpaceOnUse"
                    >
                        <path fill={background} d="M0 0H1200V1200H0z" />
                    </mask>
                    <g mask="url(#mask0_1315_48848)">
                        <path
                            fill={background}
                            d="M-2788.61 355.909C-3556.35 173.348-4346.49 868.482-4637 1200h6853l-15.04-124.88C1865.18-2.19 996.608 201.936 604.296 438.663 45.165 815.319-319.024 1039.02-984.34 1055.56c-755.48 18.78-1036.52-517.091-1804.27-699.651z"
                        />
                    </g>
                </g>
                <path
                    fill="url(#paint0_linear_1315_48848)"
                    d="M20.666 625.057c111.046-24.338 227.269-9.222 329.528 42.857 102.259 52.079 184.456 138.017 233.053 243.66 48.598 105.646 60.698 224.696 34.308 337.536-26.39 112.85-89.696 212.77-179.493 283.3s-200.729 107.47-314.527 104.73c-113.798-2.74-223.675-44.99-311.536-119.79-87.861-74.81-148.466-177.7-171.834-291.75-23.369-114.04-8.106-232.43 43.274-335.669l190.827 100.587c-29.441 59.152-38.187 126.992-24.797 192.342 13.39 65.35 48.117 124.31 98.463 167.17 50.345 42.87 113.306 67.08 178.513 68.64 65.207 1.57 128.772-19.59 180.227-60.01 51.454-40.41 87.729-97.67 102.851-162.33 15.121-64.66 8.188-132.88-19.659-193.41-27.847-60.535-74.946-109.778-133.542-139.62-58.595-29.842-125.192-38.503-188.822-24.557L20.666 625.057z"
                />
                <ellipse
                    cx="820.501"
                    cy="-87.5"
                    fill="url(#paint1_linear_1315_48848)"
                    rx="596.5"
                    ry="582.5"
                    transform="rotate(7.964 820.501 -87.5)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_1315_48848"
                    width="1400"
                    height="1150"
                    x="-100"
                    y="150"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    {/* Removing this fixed performance issues on firefox when typing in the form was slow */}
                    {/* <feGaussianBlur result="effect1_foregroundBlur_1315_48848" stdDeviation="50" /> */}
                </filter>
                <linearGradient
                    id="paint0_linear_1315_48848"
                    x1="531.082"
                    x2="-98.701"
                    y1="1035.62"
                    y2="692.211"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={primaryDarker} />
                    <stop offset="0.669" stopColor={primaryDarker} stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1315_48848"
                    x1="259.246"
                    x2="783.202"
                    y1="18.674"
                    y2="525.933"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={primary} stopOpacity="0.5" />
                    <stop offset="0.824" stopColor={primary} stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_1315_48848">
                    <path fill="#fff" d="M0 0H840V1024H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
