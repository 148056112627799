import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    Link,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { EmissionDataRequestDTO, RfQContextQueryParams } from '@luminovo/http-client';
import { SingleHorizontalStackedBarChart } from '@luminovo/viz';
import { CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useGetEmissionsQuotaFull } from '../../../../components/PartEmissionsView/useGetEmissionsQuota';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';

type Keys = 'used' | 'requesting' | 'remaining';

export function usePartEmissionRequestModal({
    rfqContext,
    assemblyId,
}: {
    rfqContext: RfQContextQueryParams;
    assemblyId: string;
}): { openDialog: () => void } {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () => {
            setDialog(
                <PartEmissionsRequestModal rfqContext={rfqContext} assemblyId={assemblyId} closeDialog={closeDialog} />,
            );
        },
    };
}

const PartEmissionsRequestModal = ({
    rfqContext,
    assemblyId,
    closeDialog,
}: {
    rfqContext: RfQContextQueryParams;
    assemblyId: string;
    closeDialog: () => void;
}) => {
    const {
        totalQuota: tq,
        alreadyUsedQuota = 0,
        otsPartIds = [],
        genericPartIds = [],
        isLoading,
    } = useGetEmissionsQuotaFull({ assemblyId, rfqContext });
    const totalQuota = tq ?? 0;

    const { mutateAsync: requestEmissionsData } = useHttpMutation('POST /parts/emission-data-requests', {
        snackbarMessage: t`Emissions data requested`,
        onSuccess: () => {
            closeDialog();
        },
    });

    const requestedCount = otsPartIds.length + genericPartIds.length;
    const isQuotaExceeded = requestedCount + alreadyUsedQuota > totalQuota;
    const remainingQuota =
        totalQuota - alreadyUsedQuota - requestedCount < 0 ? 0 : totalQuota - alreadyUsedQuota - requestedCount;
    const exceedingQuota = requestedCount + alreadyUsedQuota - totalQuota;

    const keys: Keys[] = ['used', 'requesting', 'remaining'];
    const data = [
        {
            id: ['emissionsQuota'],
            label: '',
            used: alreadyUsedQuota,
            requesting: isQuotaExceeded ? totalQuota - alreadyUsedQuota : requestedCount,
            remaining: isQuotaExceeded ? 0 : totalQuota - requestedCount - alreadyUsedQuota,
        },
    ];

    const requestBody: EmissionDataRequestDTO = useMemo(
        () => ({
            ots_part_ids: otsPartIds,
            generic_part_ids: genericPartIds,
        }),
        [otsPartIds, genericPartIds],
    );

    if (isLoading) {
        return (
            <Dialog open={true} maxWidth={'sm'} onClose={closeDialog}>
                <DialogContent style={{ padding: '24px' }}>
                    <Flexbox justifyContent="center" gap="24px">
                        <CircularProgress size="28px" />
                    </Flexbox>
                </DialogContent>
            </Dialog>
        );
    }

    if (totalQuota === 0) {
        return <ZeroQuotaSubscribed closeDialog={closeDialog} />;
    }

    return (
        <Dialog open={true} maxWidth={'sm'} onClose={closeDialog}>
            <DialogTitle title={isQuotaExceeded ? t`Quota exceeded` : t`Request sustainability data`} />

            <DialogContent style={{ paddingTop: '12px' }}>
                <Flexbox flexDirection="column" gap="24px">
                    <Text>
                        {isQuotaExceeded ? (
                            <Trans>
                                {`You have reached your maximum quota for part emissions data. Your current request will exceed the quota by ${exceedingQuota} MPNs. To enable
                        CO2e data for this assembly, please contact our Customer Success team to upgrade
                        your package.`}
                            </Trans>
                        ) : (
                            <Trans>
                                {`Requesting sustainability data for this assembly will allow you to view its CO2e
                        emissions. Your current quota is ${totalQuota} MPNs.`}
                            </Trans>
                        )}
                    </Text>
                    <SingleHorizontalStackedBarChart
                        keys={keys}
                        data={data}
                        getColor={(key) => {
                            if (key === 'used') {
                                return colorSystem.primary[5];
                            }
                            if (key === 'requesting') {
                                return isQuotaExceeded ? colorSystem.red[5] : colorSystem.green[5];
                            }
                            if (key === 'remaining') {
                                return colorSystem.neutral[2];
                            }

                            assertUnreachable(key);
                        }}
                        getKeyCount={(key: Keys) => {
                            if (key === 'used') {
                                return alreadyUsedQuota;
                            }
                            if (key === 'requesting') {
                                return otsPartIds.length;
                            }
                            if (key === 'remaining') {
                                return remainingQuota;
                            }
                            assertUnreachable(key);
                        }}
                        formatKey={formatKey}
                        width={656}
                    />
                    <Text>
                        {isQuotaExceeded ? (
                            <Trans>
                                Contact us at{' '}
                                <Link
                                    href={'mailto:support@luminovo.com'}
                                    attention="high"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    support@luminovo.com
                                </Link>{' '}
                                for assistance.
                            </Trans>
                        ) : (
                            <i>
                                <Trans>Note: once enabled, this action cannot be undone.</Trans>
                            </i>
                        )}
                    </Text>
                    <Flexbox gap="4px" alignSelf="flex-end">
                        <SecondaryButton size="medium" onClick={closeDialog}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        {isQuotaExceeded ? (
                            <PrimaryButton size="medium" onClick={openIntercom}>
                                <Trans>Send us a message</Trans>
                            </PrimaryButton>
                        ) : (
                            <PrimaryButton size="medium" onClick={() => requestEmissionsData({ requestBody })}>
                                <Trans>Yes, request</Trans>
                            </PrimaryButton>
                        )}
                    </Flexbox>
                </Flexbox>
            </DialogContent>
        </Dialog>
    );
};

const ZeroQuotaSubscribed = ({ closeDialog }: { closeDialog: () => void }) => {
    return (
        <Dialog open={true} maxWidth={'sm'} onClose={closeDialog}>
            <DialogTitle title={t`Request sustainability data`} />

            <DialogContent style={{ paddingTop: '12px' }}>
                <Flexbox flexDirection="column" gap="24px">
                    <Text>
                        <Trans>
                            {`Your current quota is 0 MPNs. Upgrade your package and view CO2e emissions for this assembly.`}
                        </Trans>
                    </Text>
                    <Text>
                        <Trans>
                            Contact us at{' '}
                            <Link
                                href={'mailto:support@luminovo.com'}
                                attention="high"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                support@luminovo.com
                            </Link>{' '}
                            to upgrade.
                        </Trans>
                    </Text>
                    <Flexbox gap="4px" alignSelf="flex-end">
                        <SecondaryButton size="medium" onClick={closeDialog}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <PrimaryButton size="medium" onClick={openIntercom}>
                            <Trans>Send us a message</Trans>
                        </PrimaryButton>
                    </Flexbox>
                </Flexbox>
            </DialogContent>
        </Dialog>
    );
};

const openIntercom = (): void => {
    if (window.Intercom !== undefined) {
        window.Intercom('showNewMessage', t`Hi! I'd like to upgrade my quota for part emissions data requests.`);
    }
};

function formatKey(key: Keys) {
    if (key === 'used') {
        return t`Used`;
    }
    if (key === 'requesting') {
        return t`Requesting`;
    }
    if (key === 'remaining') {
        return t`Remaining`;
    }
    assertUnreachable(key);
}
