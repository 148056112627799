import { SecondaryIconButton } from '@luminovo/design-system';
import { Close } from '@mui/icons-material';
import { Box, Container, Grid, Hidden, useTheme } from '@mui/material';
import { useHistory } from 'react-router';
import { PageLayout } from '../../components/PageLayout';
import { RfqCreationContextProvider } from './RfqCreationContext';
import { RfqCreationForm } from './components/Form/RfqCreationForm';
import { RfqCreationStepper } from './components/Stepper/RfqCreationStepper';

const STEPPER_BACKGROUND_BLUE = '#4B54B0';

export const EmsRfqCreationPage = (): JSX.Element => {
    const theme = useTheme();

    return (
        <PageLayout
            flexDirection="row"
            width="100%"
            navbar={<></>}
            header={undefined}
            layout="fragment"
            removeHubspotPaddingFix
        >
            <RfqCreationContextProvider>
                <Grid container>
                    <Hidden lgDown>
                        <Grid item xs={3} style={{ background: STEPPER_BACKGROUND_BLUE, padding: theme.spacing(10) }}>
                            <RfqCreationStepper />
                        </Grid>
                    </Hidden>

                    <Grid item xs={9}>
                        <CloseButton />
                        <Container
                            maxWidth="md"
                            style={{
                                padding: `${theme.spacing(10)} ${theme.spacing(4)}`,
                            }}
                        >
                            <Box width="100%" sx={{ display: 'block' }}>
                                <RfqCreationForm />
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </RfqCreationContextProvider>
        </PageLayout>
    );
};

const CloseButton = (): JSX.Element => {
    const theme = useTheme();
    const margin = theme.spacing(3);

    const history = useHistory();
    const onClick = () => {
        history.push('/rfqs');
    };

    return (
        <SecondaryIconButton
            onClick={onClick}
            style={{
                position: 'fixed',
                top: margin,
                right: margin,
                borderRadius: '50%',
            }}
        >
            <Close fontSize="inherit" />
        </SecondaryIconButton>
    );
};
