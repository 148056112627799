import React from 'react';

type UsePersistedVisibilityProps = {
    id: string;
    storage?: Storage;
    daysApart?: number;
    maxDisplayCount?: number;
};

type ReturnType = {
    isVisible: boolean;
    hide: () => void;
};

type StoredItemType = {
    lastDisplayDate: number | null;
    numberOfTimesDisplayed: number;
};

const defaultStoredItem: StoredItemType = {
    lastDisplayDate: null,
    numberOfTimesDisplayed: 0,
};

/**
 * Hook to help manage the visibility of a component.
 *
 * @param param
 * @param param.id - unique component id
 * @param param.storage - storage to use
 * @param param.daysApart - days until the component with id is displayed again
 * @param param.maxDisplayCount - number of times the component with id can be displayed
 * @returns {isVisible, hide}
 */
export function usePersistedVisibility({
    id,
    storage = localStorage,
    daysApart = 7,
    maxDisplayCount = Infinity,
}: UsePersistedVisibilityProps): ReturnType {
    const [storedValue, setStoredValue] = React.useState<StoredItemType>(() => {
        const storedItem = storage.getItem(id);
        const value: StoredItemType = storedItem ? JSON.parse(storedItem) : defaultStoredItem;
        return value;
    });

    const hide = () => {
        const updatedState = {
            lastDisplayDate: Date.now(),
            numberOfTimesDisplayed: storedValue.numberOfTimesDisplayed + 1,
        };
        setStoredValue(updatedState);
        storage.setItem(id, JSON.stringify(updatedState));
    };

    return {
        isVisible: isVisible({
            ...storedValue,
            daysApart,
            maxDisplayCount,
            currentTime: Date.now(),
        }),
        hide,
    };
}

const isVisible = ({
    daysApart,
    maxDisplayCount,
    lastDisplayDate,
    numberOfTimesDisplayed,
    currentTime,
}: {
    daysApart: number;
    maxDisplayCount: number;
    lastDisplayDate: number | null;
    numberOfTimesDisplayed: number;
    currentTime: number;
}): boolean => {
    if (lastDisplayDate === null) return true;

    const daysBetween = Math.floor((currentTime - lastDisplayDate) / (1000 * 3600 * 24));

    if (daysBetween >= daysApart && numberOfTimesDisplayed < maxDisplayCount) {
        return true;
    }

    return false;
};
