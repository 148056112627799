import { t } from '@lingui/macro';
import { formatDecimal, isEqual, isPresent } from '@luminovo/commons';
import { Flexbox, InteractiveCard, Table, Text, colorSystem } from '@luminovo/design-system';
import { isWorkInProgress } from '@luminovo/http-client';
import { EqualizerRounded, TableChartOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { ViewMode } from './ChartRfq';
import { RfqOverviewTableItem } from './RfqOverviewTable';
import { firstDayOfMonth } from './chartSpecCreatedDate';

const IndicatorHeader: React.FunctionComponent<{
    title: string;
    icon?: 'graph' | 'table';
}> = ({ title, icon }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'baseline'}>
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                {title}
            </Text>
            <Flexbox gap={4} alignItems={'center'}>
                <Text variant={'body'} color={colorSystem.neutral[6]}>
                    {icon === 'graph' ? t`Graph` : t`Table`}
                </Text>
                {icon === 'graph' ? (
                    <EqualizerRounded fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                ) : (
                    <TableChartOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                )}
            </Flexbox>
        </Flexbox>
    );
};

function IndicatorBox({
    title,
    indicator,
    secondary,
    icon,
    viewMode,
    selectedViewMode,
    setViewMode,
}: React.PropsWithChildren<{
    title: string;
    indicator?: string | JSX.Element;
    secondary?: JSX.Element;
    icon: 'table' | 'graph';
    viewMode: ViewMode;
    selectedViewMode: ViewMode;
    setViewMode: (graphId: ViewMode) => void;
}>): JSX.Element {
    const isSelected = viewMode === selectedViewMode;

    return (
        <InteractiveCard
            display={'flex'}
            flexDirection={'column'}
            style={{ gap: 8 }}
            selected={isSelected}
            onClick={() => setViewMode(isSelected ? 'table' : viewMode)}
        >
            <IndicatorHeader title={title} icon={icon} />
            <Flexbox alignItems={'center'} gap={8}>
                {!isPresent(indicator) && <Skeleton width={'100%'} height={'30px'} />}
                {isPresent(indicator) && typeof indicator === 'string' ? (
                    <Typography variant="h2">
                        {indicator} {secondary}
                    </Typography>
                ) : (
                    indicator
                )}
            </Flexbox>
        </InteractiveCard>
    );
}

export const RfqSummaryBox: React.FunctionComponent<{
    viewMode: ViewMode;
    setViewMode: (viewMode: ViewMode) => void;
    table: Table<RfqOverviewTableItem>;
}> = ({ viewMode, setViewMode, table }): JSX.Element => {
    const data = table.getCoreRowModel().rows.map((row) => row.original);

    return (
        <Box display={'grid'} columnGap="16px" gridTemplateColumns="repeat(3,1fr)" width={'100%'}>
            <IndicatorBox
                viewMode="table"
                icon="table"
                title={t`Active RfQs`}
                indicator={formatDecimal(data.filter((rfq) => !rfq.is_archived).length)}
                secondary={<Text color={colorSystem.neutral[6]}>{t`of ${formatDecimal(data.length)}`}</Text>}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />

            <IndicatorBox
                viewMode="rfqsByCreatedDate"
                icon="graph"
                title={t`RfQ creation dates`}
                indicator={formatDecimal(
                    data.filter((rfq) =>
                        isEqual(firstDayOfMonth(rfq.creation_date), firstDayOfMonth(new Date().toISOString())),
                    ).length,
                )}
                secondary={<Text color={colorSystem.neutral[6]}>{t`this month`}</Text>}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />

            <IndicatorBox
                viewMode="rfqsByStatus"
                icon="graph"
                title={t`RfQs status`}
                indicator={formatDecimal(data.filter((rfq) => isWorkInProgress(rfq.status)).length)}
                secondary={<Text color={colorSystem.neutral[6]}>{t`in progress`}</Text>}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />
        </Box>
    );
};
