import { maxBy } from '@luminovo/commons';
import assert from 'assert';
import { HighlightableString, joinFragments } from './highlightableString';

const highlightMPNQueryMatchesWithoutNewLines = (str: string, query: string): HighlightableString => {
    const strSplit = str.split(/(?<=\n)/);

    return strSplit.flatMap((s) => {
        return highlightMPNQueryMatches(s, query);
    });
};

/**
 * Heuristic to highlight characters in `str` that match with `query`. The function is intended to be used to highlight
 * parts of an MPN in a search result for a given query. The heuristic makes various assumptions about the search
 * algorithm used in the backend. Not all edge cases are covered. Also note that the implementation may need to be
 * updated when the search algorithm in the backend gets changed. For examples and limitations please take a look at the
 * unit tests.
 */
export const highlightMPNQueryMatches = (str: string, query: string): HighlightableString => {
    let sIndex: number = 0;
    let qIndex: number = 0;
    const fragments = [];

    while (sIndex < str.length) {
        const s = str[sIndex];
        if (qIndex < query.length && s.toLowerCase() === query[qIndex].toLowerCase()) {
            fragments.push({
                fragment: s,
                isHighlighted: true,
            });
            sIndex++;
            qIndex++;
        } else if (qIndex < query.length && !isAlphaNumeric(query[qIndex])) {
            qIndex++;
        } else {
            fragments.push({
                fragment: s,
                isHighlighted: false,
            });
            sIndex++;
        }
    }
    return fragments;
};

function isAlphaNumeric(str: string): boolean {
    // https://stackoverflow.com/a/25352300/3493853
    const len = str.length;

    for (let i = 0; i < len; i++) {
        const code: number = str.charCodeAt(i);
        if (
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123) // lower alpha (a-z)
        ) {
            return false;
        }
    }
    return true;
}

export const highlightNonOverlappingSubstringOccurrences = ({
    str,
    query,
    caseSensitive,
}: {
    str: string;
    query: string;
    caseSensitive: boolean;
}): HighlightableString => {
    if (query.length === 0) {
        return str
            ? [
                  {
                      fragment: str,
                      isHighlighted: false,
                  },
              ]
            : [];
    }

    const strTransformed = caseSensitive ? str : str.toLowerCase();
    const queryTransformed = caseSensitive ? query : query.toLowerCase();

    let index;
    let startIndex = 0;
    const fragments = [];

    while ((index = strTransformed.indexOf(queryTransformed, startIndex)) > -1) {
        fragments.push({
            fragment: str.slice(startIndex, index),
            isHighlighted: false,
        });
        fragments.push({
            fragment: str.slice(index, index + query.length),
            isHighlighted: true,
        });
        startIndex = index + query.length;
    }

    fragments.push({
        fragment: str.slice(startIndex, str.length),
        isHighlighted: false,
    });

    return fragments.filter((fragment) => fragment.fragment);
};

function combineStringFromFragments(highlightable: HighlightableString): string {
    return highlightable.map((item) => item.fragment).join('');
}

function convertToStringAndHighlightArray(highlightable: HighlightableString): {
    str: string;
    highlightCharAtIndice: boolean[];
} {
    return {
        str: combineStringFromFragments(highlightable),
        highlightCharAtIndice: highlightable.flatMap((item) =>
            new Array(item.fragment.length).fill(item.isHighlighted),
        ),
    };
}

const applyBooleanOperatorOrToHighlightArrays = (
    combined: { str: string; highlightCharAtIndice: boolean[] },
    highlightable: HighlightableString,
): { str: string; highlightCharAtIndice: boolean[] } => {
    const stringAndHighlightCharAtIndice = convertToStringAndHighlightArray(highlightable);
    // eslint-disable-next-line spellcheck/spell-checker
    assert(stringAndHighlightCharAtIndice.str === combined.str, 'All highlightable strings should be of same length.');
    return {
        str: combined.str,
        highlightCharAtIndice: combined.highlightCharAtIndice.map(
            (isHighlighted, index) => isHighlighted || stringAndHighlightCharAtIndice.highlightCharAtIndice[index],
        ),
    };
};

const convertToHighlightableString = ({
    str,
    highlightCharAtIndice,
}: {
    str: string;
    highlightCharAtIndice: boolean[];
}): HighlightableString => {
    const result: HighlightableString = [];
    let newFragment = '';
    for (let i = 0; i < str.length; i++) {
        if (i === 0) {
            newFragment += str.charAt(i);
        } else {
            if (highlightCharAtIndice[i - 1] === highlightCharAtIndice[i]) {
                newFragment += str.charAt(i);
            } else {
                result.push({
                    fragment: newFragment,
                    isHighlighted: highlightCharAtIndice[i - 1],
                });
                newFragment = str.charAt(i);
            }
        }
        if (i === str.length - 1) {
            result.push({
                fragment: newFragment,
                isHighlighted: highlightCharAtIndice[i],
            });
        }
    }
    return result;
};

export const combineHighlightablesIntoOne = (highlightables: HighlightableString[]): HighlightableString => {
    const combinedStr = highlightables.length > 0 ? combineStringFromFragments(highlightables[0]) : '';
    const stringAndHighlightArray = highlightables.reduce(applyBooleanOperatorOrToHighlightArrays, {
        str: combinedStr,
        highlightCharAtIndice: new Array(combinedStr.length).fill(false),
    });
    return convertToHighlightableString(stringAndHighlightArray);
};

/**
 * The fraction of the string which is highlighted
 */
export function highlightedFraction(str: HighlightableString): number {
    let length = 0;
    let highlightedLength = 0;
    for (const fragment of str) {
        length += fragment.fragment.length;
        highlightedLength += fragment.isHighlighted ? fragment.fragment.length : 0;
    }
    if (length === 0) {
        // If the string is empty, then we can say that the whole string is highlighted 🤷‍♂️
        return 1;
    }
    return highlightedLength / length;
}

export function findBestHighlightMatch(fieldValue: string, highlightOptions: string[]): HighlightableString {
    const defaultCase: HighlightableString = [
        {
            fragment: fieldValue,
            isHighlighted: false,
        },
    ];
    if (highlightOptions.length === 0) {
        return defaultCase;
    }

    const possibleHighlightedStrings = highlightOptions.map((opt) =>
        joinFragments(highlightMPNQueryMatchesWithoutNewLines(fieldValue, opt)),
    );

    return maxBy(possibleHighlightedStrings, (str) => highlightedFraction(str)) ?? defaultCase;
}
