import { Currency } from '@luminovo/commons';
import { getThousandSeparator, NumberSeparator } from '../determineDecimalSeparator';
import { pieceKeywords } from './parseUnit';

export function createParseNumber(decimalSeparator: NumberSeparator): (str: string) => number | undefined {
    return (str: string) =>
        parseNumber(str, { decimalSeparator, thousandSeparator: getThousandSeparator(decimalSeparator) });
}

const currencySymbolRegexString = '(€|$|£|¥)';
const currencyWithLettersRegexString = '(?:' + Object.values(Currency).join('|') + ')';
const currencyWithLettersAndSymbolsRegexString = `(?:€|$|£|¥|\\s${currencyWithLettersRegexString})?`;

/// Returns dot or comma and also allows white space and ' as a thousand separator (e.g. for 3 000,00 or 3'000)
function getRegexSafeThousandSeparator(thousandSeparator: NumberSeparator): string {
    return thousandSeparator === '.' ? "(?:\\.| |'|’)" : "(?:,| |'|’)";
}

export function replaceThousandSeparator(str: string, thousandSeparator: NumberSeparator): string {
    const replaceRegex = new RegExp(getRegexSafeThousandSeparator(thousandSeparator), 'g');
    return str.replace(replaceRegex, '');
}

export function getNumberWithCurrencyRegexString(decimalSeparator: NumberSeparator): string {
    const thousandSeparator = getThousandSeparator(decimalSeparator);
    const regexSafeDecimalSeparator = decimalSeparator === '.' ? '\\.' : decimalSeparator;
    const regexSafeThousandSeparator = getRegexSafeThousandSeparator(thousandSeparator);
    // this looks like follows, when the decimal separator is a comma, the thousand separator is a dot and the only currency is EUR
    // if you want to understand it better look at the tests, ask ChatGPT or look at https://regex101.com/
    // (?<![^\s^(€)])(\d{1,3}(\.\d{3})*|\d+)(,\d+)?(?![^\s$k(€)])(:?€|\s(EUR))?
    return `(?<![^\\s^${currencySymbolRegexString}])((?:\\d{1,3}(?:${regexSafeThousandSeparator}\\d{3})*|\\d+)(?:${regexSafeDecimalSeparator}\\d+)?)(?![^\\s$k${currencySymbolRegexString}])${currencyWithLettersAndSymbolsRegexString}`;
}

export function getIntegerRegexString(thousandSeparator: NumberSeparator): string {
    const regexSafeThousandSeparator = getRegexSafeThousandSeparator(thousandSeparator);
    return `(?<![^\\s=])((?:\\d{1,3}(?:${regexSafeThousandSeparator}\\d{3})*|\\d+))(\\s?k)?(?![^\\s,$(?:${pieceKeywords.join(
        '|',
    )})])`;
}

export function getDecimalRegexString(decimalSeparator: NumberSeparator): string {
    const thousandSeparator = getThousandSeparator(decimalSeparator);
    const regexSafeDecimalSeparator = decimalSeparator === '.' ? '\\.' : decimalSeparator;
    const regexSafeThousandSeparator = getRegexSafeThousandSeparator(thousandSeparator);
    return `(?<![^\\s=])((?:\\d{1,3}(?:${regexSafeThousandSeparator}\\d{3})*|\\d+)(?:${regexSafeDecimalSeparator}\\d+)?)(\\s?k)?(?![^\\s,$])`;
}

function parseNumber(
    str: string,
    {
        decimalSeparator,
        thousandSeparator,
    }: {
        decimalSeparator: NumberSeparator;
        thousandSeparator: NumberSeparator;
    },
): number | undefined {
    const numberRegex = new RegExp(getNumberWithCurrencyRegexString(decimalSeparator));
    const numberToParse = str.match(numberRegex)?.[0];
    if (!numberToParse) {
        return undefined;
    }
    const number = parseFloat(
        replaceThousandSeparator(numberToParse, thousandSeparator).replace(decimalSeparator, '.'),
    );
    if (isNaN(number)) {
        return undefined;
    }
    return number;
}
