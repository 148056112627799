import { t, Trans } from '@lingui/macro';
import { formatRelativeTime, isPresent, transEnum } from '@luminovo/commons';
import {
    Checkbox,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldMultiSelectControlled,
    FieldNumericControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { Packaging, PriceType, SourcingPreferenceOptions } from '@luminovo/http-client';
import { packagingTranslations, sourcingPreferenceTranslations } from '@luminovo/sourcing-core';
import { Divider, InputAdornment } from '@mui/material';
import * as React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useAssemblies, useAssembly } from '../../../resources/assembly/assemblyHandler';
import { SourcingScenarioFormValues } from './converters';
import { InventorySitePreferenceSection } from './InventorySitePreferenceSection';
import { SupplierPreferenceSection } from './SupplierPreferenceSection';

export function SourcingScenarioForm({
    onSubmit,
    defaultValues,
}: {
    onSubmit: (form: SourcingScenarioFormValues) => Promise<void>;
    defaultValues: SourcingScenarioFormValues;
}) {
    const validationErrors: ValidationErrors<SourcingScenarioFormValues> = {
        'sourcing_scenario.not_found': {
            fieldPath: 'root.serverError',
        },
        'sourcing_scenario.name_already_exists': {
            fieldPath: 'name',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`General information`}>
                    <FormItemName />
                    <FormItemOrderSize />
                </FormSection>
                <Divider />
                <FormSection title={t`Solution preference`}>
                    <FormItemLeadTimePreference />
                    <FormItemExcludeOffers />
                    <FormItemPackaging />
                    <FormItemApiIntegrations />
                    <FormItemExcludeMismatchedPricePoints />
                    <FormItemPrioritizePreferredManufacturers />
                    <FormItemCustomerNegotiatedPrices />
                </FormSection>
                <Divider />
                <FormSection title={t`Supplier preference`}>
                    <FormItemPreferredSuppliers />
                </FormSection>
                <FormSection title={t`Inventory preference`}>
                    <FormItemPreferredInventorySites />
                    <InventoryPreferenceSections />
                </FormSection>
                <Divider />
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end">
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const history = useHistory();
    const handleClose = React.useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemName() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`Sourcing scenario name`} required>
            <FieldTextControlled
                control={control}
                name="name"
                required
                FieldProps={{ style: { width: '40%' }, placeholder: t`Sourcing scenario name` }}
            />
        </FormItem>
    );
}

function FormItemOrderSize() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const { fields: quantities } = useFieldArray({ control, name: 'assembly_quantities' });

    return (
        <FormItem label={t`Order size`} required>
            {quantities.map(({ id, assembly }, index) => {
                return <QuantityTextField key={id} index={index} assemblyId={assembly} />;
            })}
        </FormItem>
    );
}

function QuantityTextField({ index, assemblyId }: { index: number; assemblyId: string }) {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const { data } = useAssembly(assemblyId);

    return (
        <Flexbox gap={8} alignItems="center">
            <FieldNumericControlled
                required
                control={control}
                isInteger
                name={`assembly_quantities.${index}.quantity`}
                min={0}
                FieldProps={{
                    style: { width: '40%' },
                    placeholder: t`Order size`,
                }}
            />
            <Text variant="body">{data?.designator}</Text>
        </Flexbox>
    );
}

function FormItemLeadTimePreference() {
    const { control, setValue, watch } = useFormContext<SourcingScenarioFormValues>();

    const leadTimeDate = watch('solution_preference.lead_time_preference.target');
    const leadTimePrefType = watch('solution_preference.lead_time_preference.type');

    React.useEffect(() => {
        if (leadTimePrefType !== SourcingPreferenceOptions.BestPriceBy) {
            setValue('solution_preference.lead_time_preference.target', undefined, { shouldTouch: true });
        }
    }, [setValue, leadTimePrefType]);

    return (
        <FormItem label={t`Lead time preference`}>
            <FieldSelectControlled
                control={control}
                name="solution_preference.lead_time_preference.type"
                FieldProps={{
                    style: { width: '40%' },
                    options: Object.values(SourcingPreferenceOptions),
                    getOptionLabel: (option) => transEnum(option, sourcingPreferenceTranslations),
                }}
            />
            {leadTimePrefType === SourcingPreferenceOptions.BestPriceBy && (
                <FieldDateControlled
                    control={control}
                    name="solution_preference.lead_time_preference.target"
                    required={true}
                    inFuture={true}
                    FieldProps={{
                        style: { width: '40%' },
                        InputProps: {
                            endAdornment: isPresent(leadTimeDate) && (
                                <InputAdornment position="end">{formatRelativeTime(leadTimeDate)}</InputAdornment>
                            ),
                        },
                    }}
                />
            )}
            <Flexbox alignItems={'center'} gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="solution_preference.use_unit_price"
                    FieldProps={{ size: 'small' }}
                />
                <Text variant="body">
                    <Trans>Optimize for unit price instead of total price of the solution.</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}

function FormItemExcludeOffers() {
    return (
        <FormItem label={t`Exclude offers with`}>
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ListPrice} />
                    <Text variant="body">
                        <Trans>List prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ContractPrice} />
                    <Text variant="body">
                        <Trans>Contract prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.QuotePrice} />
                    <Text variant="body">
                        <Trans>Quote prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.CustomerNegotiatedPrice} />
                    <Text variant="body">
                        <Trans>Customer negotiated prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.PurchasePrice} />
                    <Text variant="body">
                        <Trans>Purchase prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.StandardPrice} />
                    <Text variant="body">
                        <Trans>Standard prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.TargetPrice} />
                    <Text variant="body">
                        <Trans>Target prices</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

export function PriceTypeController({ priceType }: { priceType: PriceType }) {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <Controller
            control={control}
            name="solution_preference.excluded_price_types"
            render={({ field }) => {
                return (
                    <Checkbox
                        size="small"
                        onBlur={() => field.onBlur()}
                        onChange={(_, checked) => {
                            const newValues = !checked
                                ? field.value.filter((p) => p !== priceType)
                                : [...field.value, priceType];
                            field.onChange(newValues);
                        }}
                        checked={field.value.includes(priceType)}
                    />
                );
            }}
        />
    );
}

const useCheckIfAssembliesArePcbOnly = () => {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const assemblyIds = useWatch({
        name: 'assembly_quantities',
        control,
    }).map(({ assembly }) => assembly);

    const { data: assemblies } = useAssemblies(assemblyIds);
    if (assemblies === undefined) {
        return false;
    }
    return assemblies.every((assembly) => assembly.type.type === 'PcbOnly');
};

function FormItemPackaging() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const isPcbOnlyType = useCheckIfAssembliesArePcbOnly();
    if (isPcbOnlyType) {
        return null;
    }
    return (
        <FormItem
            label={t`Packaging preference`}
            description={t`Select which packaging types you would like to prefer. If no specific packaging should be preferred, leave the selection empty.`}
        >
            <FieldMultiSelectControlled
                control={control}
                name="solution_preference.packaging_preference"
                FieldProps={{
                    getOptionKey: (option) => option,
                    options: Object.values(Packaging),
                    getOptionLabel: (option) => transEnum(option, packagingTranslations),
                }}
            />
        </FormItem>
    );
}

function FormItemApiIntegrations() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const isPcbOnlyType = useCheckIfAssembliesArePcbOnly();
    if (isPcbOnlyType) {
        return null;
    }
    return (
        <FormItem label={t`Prefer supplier API integrations`}>
            <Flexbox alignItems={'start'} gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="solution_preference.prefer_direct_api_integrations"
                    FieldProps={{ size: 'small' }}
                />
                <Text variant="body">
                    <Trans>Prefer direct supplier API integrations over third-party sources (e.g. Octopart).</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}

function FormItemCustomerNegotiatedPrices() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    const isPcbOnlyType = useCheckIfAssembliesArePcbOnly();
    if (isPcbOnlyType) {
        return null;
    }
    return (
        <FormItem label={t`Prioritize customer negotiated prices`}>
            <Flexbox alignItems={'start'} gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="solution_preference.prefer_customer_negotiated_prices"
                    FieldProps={{ size: 'small' }}
                />
                <Text variant="body">
                    <Trans>Prioritize offers with customer negotiated prices</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}
function FormItemPreferredSuppliers() {
    return <SupplierPreferenceSection disabled={false} />;
}

function InventoryPreferenceSections() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    return (
        <Flexbox flexDirection="column" gap={8}>
            <FormItem label={t`Which offers should be prioritized?`} width={'800px'}>
                <Message
                    size="small"
                    attention="low"
                    variant="blue"
                    message={t`Given similar offers from your own inventory and the external market, you can choose here which one has more weight. If Neutral is selected, both inventory and market offers will be treated equally. If Market offers is selected, inventory will only be chosen if all other offers are red.`}
                />
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferInventory' }}
                    />
                    <Text>
                        <Trans>Inventory</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'Neutral' }}
                    />
                    <Text>
                        <Trans>No prioritization</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferMarketOffers' }}
                    />
                    <Text>
                        <Trans>Market offers</Trans>
                    </Text>
                </Flexbox>
            </FormItem>
        </Flexbox>
    );
}

function FormItemPreferredInventorySites() {
    return <InventorySitePreferenceSection disabled={false} />;
}

function FormItemExcludeMismatchedPricePoints() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`Exclude mismatched price points`}>
            <Flexbox alignItems="center" gap={8}>
                <FieldCheckboxControlled
                    name="solution_preference.exclude_mismatched_price_points"
                    control={control}
                    FieldProps={{ size: 'small' }}
                />
                <Text>
                    <Trans>Exclude mismatched price points for custom part offers</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}

function FormItemPrioritizePreferredManufacturers() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`Prioritize preferred manufacturers`}>
            <Flexbox alignItems="center" gap={8}>
                <FieldCheckboxControlled
                    name="solution_preference.prioritize_preferred_manufacturers"
                    control={control}
                    FieldProps={{ size: 'small' }}
                />
                <Text>
                    <Trans>Prioritize offers from preferred manufacturers</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}
