import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { ActivityDTO, ResourceDetails } from '@luminovo/http-client';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { usePageParams } from '../../../resources/hooks';
import { RecursivePartial } from '../../../utils/typingUtils';
import { makeDuplicatedDefaultName } from '../utils/makeDuplicatedDefaultName';
import { ActivityForm, useActivityForm } from './ActivityForm';
import { AddActivityFormInputs } from './AddActivityFormTypes';
import { convertActivityToForm } from './editActivityFormFunctions';

const createActivityDefaultValues = (
    existingValues: RecursivePartial<AddActivityFormInputs>,
    existingActivityNames: string[],
) => {
    return {
        ...existingValues,
        activity: {
            ...existingValues.activity,
            name: existingValues.activity?.name
                ? makeDuplicatedDefaultName(existingValues.activity.name, existingActivityNames)
                : '',
        },
    };
};

export const DuplicateActivityForm = (): JSX.Element => {
    const { activityId } = usePageParams<'/manufacturing/activity/:activityId/edit'>();
    const { isLoading, activities, drivers, resources } = useActivityForm();

    const activity: ActivityDTO | undefined = activities.find((activity) => activity.id === activityId);
    const existingActivityNames: string[] = activities.map((activity) => activity.name).filter(isPresent);

    const resourceDetails: ResourceDetails[] = activity?.resources_details ?? [];

    if (isLoading) {
        return <SpinnerWithBackdrop />;
    }

    if (activity === undefined) {
        throw new Error('activity should not be undefined if isLoading is false');
    }

    const existingValues = convertActivityToForm(activity, resourceDetails);

    return (
        <ActivityForm
            formTitle={t`Duplicate activity`}
            useActivityInputs={{
                onSubmitType: 'POST',
                defaultValues: createActivityDefaultValues(existingValues, existingActivityNames),
                allResources: resources,
                allActivities: activities,
                allDrivers: drivers,
            }}
        />
    );
};
