import { AzureExtractionResult } from '@luminovo/http-client';
import { processPdf } from '../framework/processPdf';
import { MergedAttributes } from '../framework/types';
import { PcbAttribute } from './PcbAttribute';
import { extractBaseMaterial } from './extractors/extractBaseMaterial';
import { extractBooleanSpecs } from './extractors/extractBooleanSpecs';
import { extractColor } from './extractors/extractColor';
import { extractCopperThickness } from './extractors/extractCopperThickness';
import { extractFinalThickness } from './extractors/extractFinalThickness';
import { extractFlammabilityRating } from './extractors/extractFlammabilityRating';
import { extractIpc600Class } from './extractors/extractIpc600Class';
import { extractSide } from './extractors/extractSide';
import { extractSurfaceFinish } from './extractors/extractSurfaceFinish';
import { extractTgValue } from './extractors/extractTgValue';
import { extractTolerance } from './extractors/extractTolerance';
import { inferDefaults } from './infer/inferDefaults';
import { combineBooleanSpecs } from './merge/combineBooleanSpecs';
import { combineColors } from './merge/combineColors';
import { combineCopperThickness } from './merge/combineCopperThickness';
import { combineFinalThickness } from './merge/combineFinalThickness';
import { combineSides } from './merge/combineSides';
import { filterOutUnselected } from './merge/filterOutUnselected';

export async function extractPcbSpec({
    azureExtractionResult,
}: {
    azureExtractionResult: AzureExtractionResult;
}): Promise<MergedAttributes<PcbAttribute>> {
    const extracted = processPdf<PcbAttribute>(azureExtractionResult, {
        extractionRules: [
            extractBooleanSpecs,
            extractSurfaceFinish,
            extractColor,
            extractSide,
            extractFinalThickness,
            extractCopperThickness,
            extractBaseMaterial,
            extractFlammabilityRating,
            extractTgValue,
            extractTolerance,
            extractIpc600Class,
        ],
        inferenceRules: [inferDefaults],
        // order is important as the rules get sequentially applied
        mergeRules: [
            combineColors,
            combineSides,
            combineFinalThickness,
            combineCopperThickness,
            combineBooleanSpecs,
            filterOutUnselected,
        ],
    });

    return extracted;
}
