import { isEqual } from '@luminovo/commons';

/**
 *  Toggles the presence of a specific value in an array. If the value exists in the array,
 *  it is removed; if it does not exist, it is added.
 */
export function arrayToggleItem<T>(array: Array<T>, value: T): Array<T> {
    const isFilterActive = array.some((item) => isEqual(item, value));

    if (isFilterActive) {
        return array.filter((item) => !isEqual(item, value));
    } else {
        return [...array, value];
    }
}
