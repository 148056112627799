import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

/*
 Use this if you want to display a snackbar with a title and a message.
*/
export const SnackbarText = ({ title, message }: { title?: string; message?: string }): JSX.Element => {
    return (
        <Flexbox gap={8} alignItems={'center'}>
            {title && (
                <Text
                    variant="h4"
                    color={colorSystem.neutral['white']}
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: 444,
                        overflow: 'hidden',
                    }}
                >
                    {title}
                </Text>
            )}
            {message && (
                <Text
                    variant="body"
                    color={colorSystem.neutral['white']}
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: 444,
                        overflow: 'hidden',
                    }}
                >
                    {message}
                </Text>
            )}
        </Flexbox>
    );
};
