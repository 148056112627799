import * as React from 'react';
import { isAuthorized, RoleTest } from '../permissions';
import { usePermissions } from './usePermissions';

type UseProtectedProps<T> = {
    requiredPermissions: RoleTest;
    value: T;
    fallback: T;
};

export function useProtected<T>({ requiredPermissions, fallback, value }: UseProtectedProps<T>): T {
    const { permissions } = usePermissions();

    return React.useMemo(() => {
        if (!isAuthorized(permissions, requiredPermissions)) {
            return fallback;
        }
        return value;
    }, [permissions, requiredPermissions, fallback, value]);
}
