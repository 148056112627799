import { SourcingScenarioDTO } from '@luminovo/http-client';
import { BomItem } from '../designItem/bomItemFrontendTypes';

export function calculateRequiredStock({
    sourcingScenarios,
    bomItem,
}: {
    sourcingScenarios: SourcingScenarioDTO[];
    bomItem: Pick<BomItem, 'parentId' | 'quantity' | 'designator'>;
}): { minRequiredStock: number; maxRequiredStock: number } {
    const { parentId, quantity, designator } = bomItem;
    const { min, max } = sourcingScenarios
        .flatMap((s) => s.assembly_quantities.items)
        .filter((item) => item.assembly === parentId)
        .reduce(
            ({ min, max }, current) => {
                return {
                    min: Math.min(min, current.quantity),
                    max: Math.max(max, current.quantity),
                };
            },
            { min: Infinity, max: 0 },
        );

    // Sometimes when uploading a BOM there's no designators (due to designator-quantity mismatch) and the min calculated is Infinity
    // -> which leads to: minRequiredStock = 0 * Infinity
    const designatorsCount = designator.length === 0 ? 1 : designator.length;

    const bomItemTotalQuantity: number = (quantity?.quantity ?? 1) * designatorsCount;

    return {
        minRequiredStock: min * bomItemTotalQuantity,
        maxRequiredStock: max * bomItemTotalQuantity,
    };
}
