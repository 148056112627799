import { isEqual, sortBy } from '@luminovo/commons';
import {
    FullPart,
    GenericCapacitor,
    GenericResistor,
    IncompleteGenericFullPart,
    PartAlternative,
    PartAlternativeSimilarityEnum,
    PartSuggestionFull,
    isGenericFullPart,
    isIncompleteGenericFullPart,
} from '@luminovo/http-client';
import { typeSafeObjectKeys } from '../../utils/typingUtils';
import { FullPartWithApprovalStatus } from '../part/partFrontendTypes';

export function isDerivable<T extends GenericCapacitor | GenericResistor>(
    incompleteCapacitor: T,
    capacitor: T,
): boolean {
    const incompleteParameters = incompleteCapacitor.technical_parameters;
    return typeSafeObjectKeys(incompleteParameters).every((key) => {
        if (incompleteParameters[key] === null || incompleteParameters[key] === undefined) {
            return true;
        }

        return isEqual(incompleteParameters[key], capacitor.technical_parameters[key]);
    });
}

/**
 * @returns true if the given `part` can be derived from the given incomplete part. Derived in this sense means that by completing the incomplete generic part you
 * end up with the full part.
 */
export function isDerivableFromIncompleteGenericFullPart(
    incompleteGenericFullPart: IncompleteGenericFullPart,
    part: FullPart,
): boolean {
    if (!isGenericFullPart(part)) {
        return false;
    }

    const { content: genericPart } = part;

    if (incompleteGenericFullPart.type === genericPart.type) {
        return isDerivable(incompleteGenericFullPart, genericPart);
    }

    return false;
}

/**
 * @returns a subset of the bomItem's part suggestions that haven't been applied
 * yet (i.e. part the part suggestions that are not yet part of the bomItem's specification).
 */
export function findNotAppliedPartSuggestions(
    parts: FullPartWithApprovalStatus[],
    partSuggestions: PartSuggestionFull[],
): PartSuggestionFull[] {
    const appliedPartIds = parts.map((p) => p.part.id);

    return partSuggestions.filter((partSuggestion) => {
        const { part: suggestedPart } = partSuggestion;
        if (isIncompleteGenericFullPart(suggestedPart)) {
            // only include an incomplete generic part suggestion if none of the existing parts are derivable from the
            // incomplete part suggestion.
            // This is a special case since incomplete part suggestions don't have an ID.
            const isNotDerivableFromPartOptions = parts.every(
                (part) => !isDerivableFromIncompleteGenericFullPart(suggestedPart, part.part),
            );
            // only include an incomplete generic partial match if none of the other generic parts in the array are derivable
            // from the incomplete generic partial match.
            // Special case to filter out incomplete generic partial matches that were added in previous BOM imports.
            const isNotDerivableFromPrevBomImport = partSuggestions.every((part) => {
                if (isIncompleteGenericFullPart(part.part)) {
                    return true;
                }
                if (isGenericFullPart(part.part)) {
                    return !isDerivableFromIncompleteGenericFullPart(suggestedPart, part.part);
                }
                return true;
            });
            return isNotDerivableFromPartOptions && isNotDerivableFromPrevBomImport;
        }
        return !appliedPartIds.includes(suggestedPart.id);
    });
}

/**
 * @returns a subset of the bomItem's alternative parts that haven't been applied yet
 * (i.e. part of the alternative parts that are not yet part of the bomItem's specification).
 */
function findNotAppliedAlternatives(
    parts: FullPartWithApprovalStatus[],
    partAlternatives: PartAlternative[],
): PartAlternative[] {
    const appliedPartIds = parts.map((p) => p.part.id);

    return partAlternatives.filter((alternative) => {
        const otsPart = alternative.off_the_shelf_part;
        return !appliedPartIds.includes(otsPart.id);
    });
}

function sortPartAlternatives(partAlternatives: PartAlternative[]) {
    const priority: Record<PartAlternativeSimilarityEnum, number> = {
        [PartAlternativeSimilarityEnum.Recommended]: 1,
        [PartAlternativeSimilarityEnum.FormFitFunction]: 2,
        [PartAlternativeSimilarityEnum.Functional]: 3,
    };
    return sortBy(partAlternatives, [
        (p) => {
            return priority[p.similarity];
        },
        (p) => [p.off_the_shelf_part.mpn],
    ]);
}

export function getAndSortAlternatives({
    parts,
    partAlternatives,
}: {
    parts: FullPartWithApprovalStatus[];
    partAlternatives: PartAlternative[];
}): PartAlternative[] {
    const notAppliedAlternatives = findNotAppliedAlternatives(parts, partAlternatives);
    return sortPartAlternatives(notAppliedAlternatives);
}
