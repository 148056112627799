/* eslint-disable camelcase */
import { MonetaryValue } from '@luminovo/commons';
import { QuoteRequestDTO } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

export interface QuoteRequestForm {
    showCustomerName: boolean;
    showTargetPrice: boolean;
    quoteRequest: QuoteRequestDTO;
    lineItemTargetPrices: { quoteRequestLineItemId: number; targetPrice: MonetaryValue | null }[];
    globalTargetSavings: {
        active: 'fixed' | 'random';
        fixed: {
            value: number | null;
        };
        random: {
            from: number | null;
            to: number | null;
        };
    };
}

export function useHandleSubmit() {
    const { handleSubmit } = useFormContext<QuoteRequestForm>();
    const { mutateAsync: saveQuoteRequest } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: null,
    });
    const { mutateAsync: saveLineItemTargetPrices } = useHttpMutation('POST /quote-request/line-item/update-many', {
        snackbarMessage: null,
    });

    const mutationFn = handleSubmit(async (form: QuoteRequestForm) => {
        await Promise.all([
            saveQuoteRequest({
                pathParams: { id: form.quoteRequest.id },
                requestBody: {
                    due_date: form.quoteRequest.due_date,
                    show_customer: form.showCustomerName,
                    show_target_price: form.showTargetPrice,
                    notes:
                        form.quoteRequest.notes && form.quoteRequest.notes.length > 0 ? form.quoteRequest.notes : null,
                },
            }),
            saveLineItemTargetPrices({
                requestBody: {
                    updates: form.lineItemTargetPrices.flatMap((lineItemPrice) =>
                        lineItemPrice.targetPrice !== null && lineItemPrice.targetPrice.amount !== null
                            ? [
                                  {
                                      id: lineItemPrice.quoteRequestLineItemId,
                                      target_price: lineItemPrice.targetPrice,
                                  },
                              ]
                            : [],
                    ),
                },
            }),
        ]);
    });

    return useMutation({
        mutationFn: () => mutationFn(),
    });
}
