import { plural, t, Trans } from '@lingui/macro';
import { formatPercentage, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    FilterChip,
    Flexbox,
    InteractiveCard,
    Message,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { AssemblyFormDTO, AssemblyResponseDTO, BomItemApprovalStatus } from '@luminovo/http-client';
import { Box, styled } from '@mui/material';
import React from 'react';
import { useIsCustomer } from '../../../../components/contexts/CurrentUserDetailsContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import {
    groupAndCountFilters,
    groupModuleTableDataByStatus,
    isAssemblyTableData,
    ModuleTableData,
    ViewContext,
} from '../../../Bom/components/ModuleTableData';
import { FilterId } from '../../../Bom/components/ModuleTableData/filters';
import {
    countActiveWarningsAcrossBomItems,
    CountWarningsProps,
    WarningSubCategoryBomItemIssues,
} from '../../countWarningsAcrossBomItems';
import { getActiveWarnings } from '../../hasActiveWarnings';
import { MessageForPreviousBomImportChanges } from './MessageForPreviousBomImportChanges';
import { SubassembliesTable } from './SubassembliesTable';

const statuses = [
    {
        status: BomItemApprovalStatus.Approved,
        label: <Trans>Matched</Trans>,
        color: colorSystem.green[5],
        filters: [
            { filterId: FilterId.ApprovedPartOptionsWithNoIPns, label: (count: number) => t`${count} without IPNs` },
        ],
    },
    {
        status: BomItemApprovalStatus.DNP,
        label: <Trans>Do not place</Trans>,
        color: colorSystem.neutral[6],
    },
    {
        status: BomItemApprovalStatus.Pending,
        label: <Trans>Pending</Trans>,
        color: colorSystem.neutral[4],
    },
    {
        status: BomItemApprovalStatus.Rejected,
        label: <Trans>Missing data</Trans>,
        color: colorSystem.red[5],
        filters: [
            {
                filterId: FilterId.MissingDataWithSuggestions,
                label: (count: number) =>
                    plural(count, {
                        one: `${count} suggestion`,
                        other: `${count} suggestions`,
                    }),
            },
        ],
    },
];

function useSuccessRate({
    moduleTableData,
    moduleTableDataByStatus,
}: {
    moduleTableData: ModuleTableData[];
    moduleTableDataByStatus: Record<BomItemApprovalStatus, ModuleTableData[]>;
}) {
    const isCustomer = useIsCustomer();
    const approvedBomItems = moduleTableDataByStatus[BomItemApprovalStatus.Approved].length;
    const dnpBomItems = moduleTableDataByStatus[BomItemApprovalStatus.DNP].length;
    const approvedBomItemsWithWarnings = moduleTableDataByStatus[BomItemApprovalStatus.Approved].filter((bomItem) => {
        if (isAssemblyTableData(bomItem)) {
            return false;
        }
        return getActiveWarnings({ issues: bomItem.issues }).length > 0;
    }).length;

    if (isCustomer) {
        return (approvedBomItems - approvedBomItemsWithWarnings + dnpBomItems) / moduleTableData.length;
    }
    return (approvedBomItems + dnpBomItems) / moduleTableData.length;
}

export const BomItemSummary = ({
    moduleTableData,
    selectedStatus,
    setSelectedStatus,
    setWarningSubCategoryIssues,
    clearAppliedFilters,
    warningSubCategoryIssues,
    setSearchedText,
    appliedFilters,
    setAppliedFilters,
    viewContext,
    currentAssembly,
}: {
    moduleTableData: ModuleTableData[];
    selectedStatus: BomItemApprovalStatus | undefined;
    setSelectedStatus: React.Dispatch<React.SetStateAction<BomItemApprovalStatus | undefined>>;
    setWarningSubCategoryIssues: React.Dispatch<React.SetStateAction<Set<WarningSubCategoryBomItemIssues>>>;
    clearAppliedFilters: () => void;
    warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
    setSearchedText: React.Dispatch<React.SetStateAction<string>>;
    appliedFilters: Set<FilterId>;
    setAppliedFilters: (filters: Set<FilterId>) => void;
    viewContext: ViewContext;
    currentAssembly?: AssemblyResponseDTO;
}): JSX.Element => {
    const moduleTableDataByStatus = groupModuleTableDataByStatus(moduleTableData);
    const successRate: number = useSuccessRate({
        moduleTableData,
        moduleTableDataByStatus,
    });

    const warningIssuesCount: CountWarningsProps[] = countActiveWarningsAcrossBomItems(moduleTableData);

    const filtersCount = React.useMemo(() => {
        return groupAndCountFilters(moduleTableData);
    }, [moduleTableData]);

    const subassemblies = React.useMemo(() => {
        return moduleTableData.filter((moduleData) => moduleData.moduleType === 'assembly');
    }, [moduleTableData]);

    return (
        <MainContentContainer minWidth="1000px">
            <Flexbox
                style={{
                    marginTop: '15%',
                    marginBottom: '10%',
                    width: '836px',
                }}
                flexDirection="column"
                gap={12}
            >
                <StatusContainer className={'help_hero_bom_import_summary_status_container'}>
                    <Flexbox
                        gap={12}
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ background: 'linear-gradient(180deg, #928EFC 0%, #8394F8 100%)' }}
                        borderRadius="12px 12px 0 0"
                        padding="24px"
                    >
                        <Text variant="h3" color={colorSystem.neutral.white}>
                            <Trans>
                                {formatPercentage(successRate, { minimumFractionDigits: 0 })} of the BOM is ready for
                                calculation!
                            </Trans>
                        </Text>
                    </Flexbox>
                    <Flexbox gap="20px" justifyContent="space-between" padding="24px">
                        {statuses.map(({ status, label, color, filters }) => {
                            return (
                                <InteractiveCard
                                    key={status}
                                    selected={selectedStatus === status}
                                    onClick={() => {
                                        clearAppliedFilters();
                                        setWarningSubCategoryIssues(new Set());
                                        setSelectedStatus(status);
                                    }}
                                    width="260px"
                                    padding={0}
                                >
                                    <Flexbox flexDirection="column" gap="8px">
                                        <Flexbox gap={4} alignItems={'center'}>
                                            <span style={{ color }}>●</span>{' '}
                                            <Text variant="h2">{moduleTableDataByStatus[status].length}</Text>
                                        </Flexbox>
                                        <Flexbox gap="8px" alignItems="center">
                                            <Text
                                                variant="body-small-semibold"
                                                color={colorSystem.neutral[6]}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {label}
                                            </Text>
                                            {filters && (
                                                <FilterChips
                                                    filters={filters}
                                                    filtersCount={filtersCount}
                                                    appliedFilters={appliedFilters}
                                                    setAppliedFilters={setAppliedFilters}
                                                    setSelectedStatus={setSelectedStatus}
                                                />
                                            )}
                                        </Flexbox>
                                    </Flexbox>
                                </InteractiveCard>
                            );
                        })}
                    </Flexbox>

                    {currentAssembly && (
                        <BomImportAssemblyIssues viewContext={viewContext} currentAssembly={currentAssembly} />
                    )}
                    {warningIssuesCount.length > 0 ? (
                        <WarningsSection
                            warningIssuesCount={warningIssuesCount}
                            setSelectedStatus={setSelectedStatus}
                            setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                            clearAppliedFilters={clearAppliedFilters}
                            warningSubCategoryIssues={warningSubCategoryIssues}
                            setSearchedText={setSearchedText}
                        />
                    ) : (
                        <Text variant="h5" style={{ padding: '0 0 24px 24px' }}>
                            <Trans>No warnings</Trans>
                        </Text>
                    )}
                </StatusContainer>
                {subassemblies.length > 0 && (
                    <StatusContainer padding="24px" gap="16px">
                        <Text variant="h5">
                            <Trans>Subassemblies</Trans>
                        </Text>
                        <SubassembliesTable subassemblies={subassemblies} viewContext={viewContext} />
                    </StatusContainer>
                )}
                {filtersCount.has(FilterId.HasChangesFromPreviousImport) && (
                    <MessageForPreviousBomImportChanges
                        moduleTableData={moduleTableData}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                )}
            </Flexbox>
        </MainContentContainer>
    );
};

const BomImportAssemblyIssues = ({
    currentAssembly,
    viewContext,
}: {
    currentAssembly: AssemblyResponseDTO;
    viewContext: ViewContext;
}) => {
    const rfqId = viewContext.rfqId;

    const bomImportedAssemblyIssues = React.useMemo(() => {
        if (currentAssembly.origin.type !== 'BomImport' || !currentAssembly) return [];

        return currentAssembly.origin.value.issues;
    }, [currentAssembly]);

    const { mutateAsync } = useHttpMutation('PATCH /assemblies/:assemblyId', {
        snackbarMessage: t`Assembly successfully updated`,
    });

    const { isAutomaticallyGenerated, containsDuplicateCPNIssue, containsDuplicateIpnIssue } = React.useMemo(() => {
        let containsDuplicateIpnIssue = false;
        let containsDuplicateCPNIssue = false;
        let isAutomaticallyGenerated = false;
        for (const issue of bomImportedAssemblyIssues) {
            if (issue.issue_type === 'AutomaticallyGenerated') {
                isAutomaticallyGenerated = true;
            }
            if (issue.issue_type === 'ConflictingCpnIdentifier') {
                containsDuplicateCPNIssue = true;
            }
            if (issue.issue_type === 'ConflictingIpnIdentifier') {
                containsDuplicateIpnIssue = true;
            }
        }

        return {
            isAutomaticallyGenerated,
            containsDuplicateCPNIssue,
            containsDuplicateIpnIssue,
        };
    }, [bomImportedAssemblyIssues]);

    const containsBomImportIssues = isAutomaticallyGenerated || containsDuplicateCPNIssue || containsDuplicateIpnIssue;

    const { data: rfq } = useRfQ(rfqId ?? '', Boolean(rfqId));

    if (!containsBomImportIssues || !isPresent(rfq)) return null;

    /* eslint-disable camelcase */
    const defaultFormForResolvingAutomaticallyGeneratedIssue: AssemblyFormDTO = {
        rfq: rfq.id,
        type: currentAssembly.type,
        notes: currentAssembly.notes ?? '',
        designator: currentAssembly.designator,
        customer: rfq.customer,
        industry: currentAssembly.industry,
        parents: Object.keys(currentAssembly.parents),
        sub_assembly_quantities: currentAssembly.subassemblies.items,
        ipn_value: currentAssembly.ipn?.value ?? '',
        ipn_revision: currentAssembly.ipn?.revision ?? '',
        // we just want to resolve the AutomaticallyGenerated issue
        bom_imported_issues_to_resolve: ['AutomaticallyGenerated'],
    };
    /* eslint-enable camelcase */

    const handleSubmitAutomaticallyGeneratedIssue = () => {
        mutateAsync({
            pathParams: { assemblyId: currentAssembly.id },
            requestBody: defaultFormForResolvingAutomaticallyGeneratedIssue,
        });
    };

    return (
        <Flexbox gap="20px" flexDirection={'column'} justifyContent="space-between" padding="24px">
            {isAutomaticallyGenerated && (
                <Message
                    attention={'high'}
                    variant={'yellow'}
                    size={'small'}
                    message={t`An assembly line was missing in your uploaded Excel file. We have automatically generated this assembly for you. Please review the generated assembly to ensure accuracy.`}
                    overrides={{
                        ActionButton: () => (
                            <SecondaryButton size={'small'} onClick={handleSubmitAutomaticallyGeneratedIssue}>
                                <Trans>Got it</Trans>
                            </SecondaryButton>
                        ),
                    }}
                />
            )}
            {containsDuplicateCPNIssue && (
                <Message
                    attention={'high'}
                    variant={'yellow'}
                    size={'small'}
                    message={t`There is another assembly with the same CPN but different content in the uploaded BOM. Please review these assemblies to ensure the data is correct. You can delete one of the duplicate assemblies, or adjust and reimport the original BOM.`}
                />
            )}
            {containsDuplicateIpnIssue && (
                <Message
                    attention={'high'}
                    variant={'yellow'}
                    size={'small'}
                    message={t`There is another assembly with the same IPN but different content in the uploaded BOM. Please review these assemblies to ensure the data is correct. You can delete one of the duplicate assemblies, or adjust and reimport the original BOM.`}
                />
            )}
        </Flexbox>
    );
};

const FilterChips = ({
    filters,
    filtersCount,
    appliedFilters,
    setAppliedFilters,
    setSelectedStatus,
}: {
    filters: { filterId: FilterId; label: (count: number) => string }[];
    filtersCount: Map<FilterId, number>;
    appliedFilters: Set<FilterId>;
    setAppliedFilters: (filters: Set<FilterId>) => void;
    setSelectedStatus: (value: React.SetStateAction<BomItemApprovalStatus | undefined>) => void;
}) => {
    if (filters.length === 0) {
        return null;
    }
    return (
        <>
            {filters.map(({ filterId, label }) => {
                const count = filtersCount.get(filterId) ?? 0;
                if (count === 0) {
                    return null;
                }
                return (
                    <span key={filterId}>
                        <FilterChip
                            value={filterId}
                            label={label(count)}
                            onChange={() => {
                                const newFilters = new Set(appliedFilters);
                                if (appliedFilters.has(filterId)) {
                                    newFilters.delete(filterId);
                                } else {
                                    newFilters.add(filterId);
                                }
                                setAppliedFilters(newFilters);
                                setSelectedStatus(undefined);
                            }}
                            isSelected={appliedFilters.has(filterId)}
                        />
                    </span>
                );
            })}
        </>
    );
};

const WarningsSection = ({
    warningIssuesCount,
    setSelectedStatus,
    setWarningSubCategoryIssues,
    clearAppliedFilters,
    warningSubCategoryIssues,
    setSearchedText,
}: {
    warningIssuesCount: CountWarningsProps[];
    setSelectedStatus: React.Dispatch<React.SetStateAction<BomItemApprovalStatus | undefined>>;
    setWarningSubCategoryIssues: React.Dispatch<React.SetStateAction<Set<WarningSubCategoryBomItemIssues>>>;
    clearAppliedFilters: () => void;
    warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
    setSearchedText: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
    const handleOnChange = (issueId: WarningSubCategoryBomItemIssues) => {
        setSearchedText('');
        setSelectedStatus(undefined);
        clearAppliedFilters();
        const newFilters = new Set(warningSubCategoryIssues);
        if (warningSubCategoryIssues.has(issueId)) {
            newFilters.delete(issueId);
        } else {
            newFilters.add(issueId);
        }
        setWarningSubCategoryIssues(newFilters);
    };

    return (
        <Flexbox flexDirection="column" padding={'0 24px 24px 24px'} gap="12px">
            <Text variant="h5">
                <Trans>Warnings</Trans>
            </Text>
            <Flexbox gap={8} flexWrap={'wrap'}>
                {warningIssuesCount.map((warning) => {
                    return (
                        <FilterChip
                            value={warning.issue}
                            startIcon={warning.icon}
                            isSelected={warningSubCategoryIssues.has(warning.issue)}
                            key={warning.issue}
                            label={`${warning.label} • ${warning.count}`}
                            onChange={handleOnChange}
                        />
                    );
                })}
            </Flexbox>
        </Flexbox>
    );
};

export const MainContentContainer = styled(Box)({
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'baseline',
    justifyContent: 'center',
    background: colorSystem.neutral[1],
    minWidth: '1300px',
});

const StatusContainer = styled(Flexbox)({
    height: 'auto',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '12px',
});
