/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { assertPresent, assertUnreachable } from '@luminovo/commons';
import {
    PriceType,
    QuantityUnit,
    QuoteTrackingDTO,
    StandardPartOfferBulkInputDTO,
    StandardPartTypes,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isIncompleteGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { leadTimeInDays } from '@luminovo/sourcing-core';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { getQuoteLineItemUnitPrice } from '../model/utils';
import { StandardPartQuoteFormValues } from '../types';

export function useSubmitStandardPartQuote({ quote }: { quote: QuoteTrackingDTO }) {
    const { mutateAsync: uploadStandardPartOffers } = useHttpMutation('POST /offers/off-the-shelf/bulk/import', {
        snackbarMessage: t`Quote submitted`,
    });

    return async (form: StandardPartQuoteFormValues) => {
        await uploadStandardPartOffers({
            requestBody: {
                rfq_id: quote.rfq,
                supplier_and_stock_location: form.supplierAndStockLocation,
                quote_tracking_id: quote.id,
                event_metadata: {
                    type: 'Pdf',
                },
                inputs: form.lineItems
                    .filter((l) => l.included)
                    // Can't submit line items without an offer
                    .filter((l) => l.offer && l.offer.offeredPart)
                    .map((lineItem): StandardPartOfferBulkInputDTO => {
                        const linkedPartType = (): StandardPartTypes => {
                            const part = lineItem.part;
                            if (isOtsFullPart(part)) {
                                return StandardPartTypes.OffTheShelf;
                            }
                            if (isGenericFullPart(part)) {
                                return StandardPartTypes.Generic;
                            }
                            if (isOtsComponentFull(part)) {
                                return StandardPartTypes.Ipn;
                            }
                            if (isIncompleteGenericFullPart(part)) {
                                throw new Error('Incomplete generic full parts are not supported');
                            }
                            if (isCustomFullPart(part)) {
                                throw new Error('Custom full parts are not supported');
                            }
                            if (isCustomComponentFull(part)) {
                                throw new Error('Custom component full parts are not supported');
                            }
                            assertUnreachable(part);
                        };

                        const leadTimeValue = lineItem.offer?.leadTime ?? null;
                        const factoryLeadTimeInDays: number | null = leadTimeValue
                            ? leadTimeInDays({ unit: form.leadTimeUnit, value: leadTimeValue })
                            : null;

                        return {
                            offer_number: form.offerNumber,
                            price_type: PriceType.QuotePrice,
                            attachment: null,
                            valid_until: lineItem.offer?.validUntil ?? null,
                            availability_input: {
                                factory_lead_time_days: factoryLeadTimeInDays,
                                stock: lineItem.offer?.stock ?? null,
                                factory_quantity: null,
                                on_order: [],
                            },
                            rfq_id: quote.rfq,
                            supplier_and_stock_location: form.supplierAndStockLocation,
                            unit_of_measurement: { quantity: 1, unit: QuantityUnit.Pieces },
                            currency: form.currency,
                            customer: null,
                            requested_part: { type: linkedPartType(), data: lineItem.part.id },
                            linked_part: {
                                type: StandardPartTypes.OffTheShelf,
                                data: assertPresent(lineItem.offer?.offeredPart?.id),
                            },
                            ncnr: lineItem.offer?.ncnr ?? null,
                            notes: lineItem.offer?.notes ?? null,
                            packaging: lineItem.offer?.packaging ?? null,
                            price_break_inputs: [
                                {
                                    moq: lineItem.offer?.moq ?? 0,
                                    mpq: lineItem.offer?.mpq ?? 1,
                                    unit_price: getQuoteLineItemUnitPrice(lineItem),
                                },
                            ],
                            // TODO: use actual supplier part number or make it optional
                            supplier_part_number: lineItem.offer?.supplierPartNumber ?? lineItem.part.id,
                        };
                    }),
            },
        });
    };
}
