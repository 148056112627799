/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface BlumeCredentialsDTO extends r.Static<typeof BlumeCredentialsDTORuntype> {}
export const BlumeCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface BlumeCredentialsInputDTO extends r.Static<typeof BlumeCredentialsInputDTORuntype> {}
export const BlumeCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface BlumeResponseBodyDTO extends r.Static<typeof BlumeResponseBodyDTORuntype> {}
export const BlumeResponseBodyDTORuntype = r.Record({
    data: BlumeCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
