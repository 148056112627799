import { RfQContextQueryParams } from '@luminovo/http-client';
import { useHttpQuery } from '../../resources/http/useHttpQuery';

type Props = {
    assemblyId: string;
    rfqContext: RfQContextQueryParams;
};

export function useGetEmissionsQuotaFull({ assemblyId, rfqContext }: Props) {
    const { data: emissionsQuota, isLoading: isEmissionsQuotaLoading } = useHttpQuery(
        'GET /organization/emission-request-quota',
        {},
    );

    const { data, isLoading } = useHttpQuery('GET /assemblies/:id/descendants-part-ids-without-emission-data', {
        pathParams: { id: assemblyId },
        queryParams: rfqContext,
    });

    return {
        totalQuota: emissionsQuota?.total_quota,
        alreadyUsedQuota: emissionsQuota?.already_used_quota,
        otsPartIds: data?.ots_part_ids,
        genericPartIds: data?.generic_part_ids,
        isLoading: isLoading || isEmissionsQuotaLoading,
    };
}
