import { t, Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, BomImportedAssemblyIssue } from '@luminovo/http-client';
import { ActionPerformedBy, TimelineText } from './Utils';

type Props = {
    actor: ActorResponseRuntypeDTO;
    data: BomImportedAssemblyIssue;
};

export default function BOMImportedAssemblyIssue({ actor, data }: Props) {
    if (!actor) return null;

    return (
        <TimelineText>
            <Trans>{translateIssueType(data)} issue manually resolved</Trans> <ActionPerformedBy actor={actor} />
        </TimelineText>
    );
}

const translateIssueType = (issue: BomImportedAssemblyIssue) => {
    switch (issue.issue_type) {
        case 'ConflictingCpnIdentifier':
            return t`Conflicting CPN identifier`;
        case 'ConflictingIpnIdentifier':
            return t`Conflicting Ipn identifier`;
        case 'AutomaticallyGenerated':
            return t`Automatically generated`;
    }
};
