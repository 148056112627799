import { DemandScenarioDTO } from '@luminovo/http-client';
import { useSourcingScenarios } from '../../resources/sourcingScenario/sourcingScenarioHandlers';

export type AssemblyQuantitiesMap = Map<string, number[]>;

export const useAssemblyQuantitiesMap = (sourcingScenarioIds: string[]) => {
    const { isLoading, data: sourcingScenarios } = useSourcingScenarios(sourcingScenarioIds);

    const assemblyQuantitiesMap: AssemblyQuantitiesMap = new Map<string, number[]>();

    if (sourcingScenarios === undefined || isLoading) {
        return { isLoading, assemblyQuantitiesMap };
    }

    for (const sourcingScenario of sourcingScenarios) {
        for (const assemblyQuantity of sourcingScenario.assembly_quantities.items) {
            const prevVal = assemblyQuantitiesMap.get(assemblyQuantity.assembly) ?? [];
            assemblyQuantitiesMap.set(assemblyQuantity.assembly, [...prevVal, assemblyQuantity.quantity]);
        }
    }

    return { isLoading, assemblyQuantitiesMap };
};

export type DemandSourcingMismatch = { type: 'prototype' } | { type: 'series'; year: number } | { type: 'no-mismatch' };

export const checkDemandSourcingMismatch = (
    demandScenario: DemandScenarioDTO,
    assemblyQuantitiesMap: AssemblyQuantitiesMap,
): DemandSourcingMismatch => {
    for (const demand of demandScenario.demands) {
        const assemblyQuantities = assemblyQuantitiesMap.get(demand.assembly_id);

        if ('delivery_date' in demand) {
            if (demand.delivery_date.quantity === 0) {
                continue;
            }
            if (assemblyQuantities === undefined) {
                return { type: 'prototype' };
            }
            const result = assemblyQuantities.find((quantity) => quantity === demand.delivery_date.quantity);
            if (result === undefined) {
                return { type: 'prototype' };
            }
        } else {
            for (const annualDemand of demand.annual_demands) {
                if (annualDemand.quantity === 0) {
                    continue;
                }
                if (assemblyQuantities === undefined) {
                    return { type: 'series', year: annualDemand.year };
                }
                const result = assemblyQuantities.find((quantity) => quantity === annualDemand.quantity);
                if (result === undefined) {
                    return { type: 'series', year: annualDemand.year };
                }
            }
        }
    }

    return { type: 'no-mismatch' };
};
