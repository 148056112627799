import { Flexbox } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';
import * as React from 'react';
import { PART_CARD_ROW_HEIGHT, PART_SPECIFICATION_CARD_WIDTH } from '../../themes';
import { PartCardBox, PartCardDivider } from '../StyledComponents';

type PartCardLayoutProps = {
    cardStyle?: React.CSSProperties;
    headerSection: JSX.Element;
    headerHover: JSX.Element;
    footerSection?: JSX.Element;
    footerHover?: JSX.Element;
    disableOnHover?: boolean;
    tooltip?: string;
};

export const PartCardLayout: React.FunctionComponent<PartCardLayoutProps> = ({
    cardStyle,
    headerSection,
    headerHover,
    footerSection,
    footerHover,
    disableOnHover,
    tooltip,
}) => {
    const [isHovered, setHovered] = React.useState(false);

    const handleMouseEnter = React.useCallback(() => {
        setHovered(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setHovered(false);
    }, []);

    const isVisible = !disableOnHover && isHovered;

    return (
        <Tooltip title={tooltip && isHovered ? tooltip : ''}>
            <PartCardBox
                width={PART_SPECIFICATION_CARD_WIDTH}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    ...cardStyle,
                    position: 'relative',
                }}
            >
                <Flexbox
                    height={PART_CARD_ROW_HEIGHT}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'8px'}
                >
                    {headerSection}
                    <Flexbox
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '50%',
                            display: isVisible ? undefined : 'none',
                        }}
                    >
                        {isVisible && headerHover}
                    </Flexbox>
                </Flexbox>

                {footerSection && (
                    <>
                        <PartCardDivider />
                        <Flexbox
                            alignItems="center"
                            minHeight={PART_CARD_ROW_HEIGHT}
                            px={'8px'}
                            gap={4}
                            justifyContent={'space-between'}
                            overflow="clip"
                        >
                            {footerSection}

                            {footerHover && (
                                <Flexbox
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 0,
                                        width: '50%',
                                        display: isVisible ? undefined : 'none',
                                    }}
                                >
                                    {isVisible && footerHover}
                                </Flexbox>
                            )}
                        </Flexbox>
                    </>
                )}
            </PartCardBox>
        </Tooltip>
    );
};
