import { Trans } from '@lingui/macro';
import { getIntercomArticleURL } from '@luminovo/commons';
import { Flexbox, HEADINGS_WEIGHT, PrimaryButton, Tab, Tabs, Text } from '@luminovo/design-system';
import { FormulaErrorDTO, FormulaResponseDTO } from '@luminovo/http-client';
import { createWholeWordRegex } from '@luminovo/manufacturing-core';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Grid, MenuItem } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import * as React from 'react';
import { GreenCheckCircleOutlineRoundedIcon, RedHighlightOffRoundedIcon } from '../../../../../components/Icons/icons';
import { MonacoEditor } from '../../../../../components/MonacoEditor';
import type { MonacoEditorRef } from '../../../../../components/MonacoEditor/MonacoEditor';
import { InputControl } from '../../../../../components/MonacoEditor/editorTypes';
import { TabPanel } from '../../../../../components/TabPanel';
import { colorSystem } from '../../../../../themes';
import { assertUnreachable } from '../../../../../utils/typingUtils';
import { AdvancedFormulaErrorBox } from '../AdvancedFormulaError/AdvancedFormulaErrorComponent';
import { extractFormulaErrorDTO } from '../AdvancedFormulaError/advancedFormulaErrorFunction';
import { extractMarkerDataFromFormulaError } from '../AdvancedFormulaError/advancedFormulaErrorMarkerData';
import { AdvancedFormulaTestRunBox } from '../AdvancedFormulaTestRun/AdvancedFormulaTestRunComponent';
import { Inputs } from './Inputs';
import { Operators } from './Operators';

const messageStyle = {
    gap: '4px',
    alignItems: 'center',
    marginLeft: '8px',
};

const SuccessMessage = (): JSX.Element => {
    return (
        <Flexbox style={messageStyle}>
            <GreenCheckCircleOutlineRoundedIcon />
            <Text>
                <Trans>Validation successful</Trans>
            </Text>
        </Flexbox>
    );
};

const ErrorMessage = (): JSX.Element => {
    return (
        <Flexbox style={messageStyle}>
            <RedHighlightOffRoundedIcon />
            <Text>
                <Trans>Validation unsuccessful</Trans>
            </Text>
        </Flexbox>
    );
};

const ValidatingMessage = (): JSX.Element => {
    return (
        <Flexbox style={messageStyle}>
            <Text>
                <Trans>Validating</Trans>
            </Text>
        </Flexbox>
    );
};

const getValidationMessage = (result: 'Ok' | 'Error' | undefined, isLoading: boolean): JSX.Element => {
    if (isLoading) {
        return <ValidatingMessage />;
    }
    switch (result) {
        case 'Error':
            return <ErrorMessage />;
        case 'Ok':
            return <SuccessMessage />;
        case undefined:
            return <></>;
        default:
            assertUnreachable(result);
    }
};

const getDocumentationLink = (): string => {
    return getIntercomArticleURL(86189);
};

const DocumentationLink = (): JSX.Element => {
    return (
        <MenuItem
            style={{
                marginLeft: 'auto',
                fontWeight: HEADINGS_WEIGHT,
                color: colorSystem.neutral[7],
            }}
            onClick={() => window.open(getDocumentationLink(), '_blank')}
        >
            <LaunchIcon fontSize={'small'} style={{ marginRight: '4px' }} />
            <Trans>Documentation</Trans>
        </MenuItem>
    );
};

export const FormulaAndInputControls = ({
    initialInputControls,
    unitFormControl,
    formula,
    setFormula,
    validate,
    validationData,
    validateIsLoading,
    includeDocumentation = true,
}: {
    initialInputControls: InputControl[];
    unitFormControl: JSX.Element;
    formula: string;
    setFormula: React.Dispatch<React.SetStateAction<string>>;
    validate: UseMutateFunction;
    validationData: FormulaResponseDTO | undefined;
    validateIsLoading: boolean;
    includeDocumentation?: boolean;
}): JSX.Element => {
    const inputControls: InputControl[] = initialInputControls.map((inputControl) => {
        return {
            ...inputControl,
            isInFormula: formula !== undefined && createWholeWordRegex(inputControl.formulaValue).test(formula),
        };
    });

    const [validationMessage, setValidationMessage] = React.useState<FormulaErrorDTO | undefined>();

    const editorMarkerData = React.useMemo(() => {
        if (validationMessage) {
            return extractMarkerDataFromFormulaError(validationMessage);
        }
        return [];
    }, [validationMessage]);

    React.useEffect(() => {
        const validationMessage = extractFormulaErrorDTO(validationData);
        setValidationMessage(validationMessage);
    }, [validationData]);

    const editorRef: MonacoEditorRef = React.useRef();
    const setFormulaAtOffset = (textToInsert: string) => {
        editorRef.current?.trigger('keyboard', 'type', { text: `${textToInsert} ` });
        editorRef.current?.focus();
    };

    const [activeTab, setActiveTab] = React.useState<'formulaError' | 'formulaValidation'>('formulaError');
    const handleTabChange = React.useCallback((_event, value) => setActiveTab(value), [setActiveTab]);

    const handleTestRunChange = (data: FormulaErrorDTO | undefined) => {
        setValidationMessage(data);
        if (data !== undefined) {
            setActiveTab('formulaError');
        }
    };

    return (
        <Grid container>
            <Grid item xs={5}>
                <Flexbox>{unitFormControl}</Flexbox>
                <Text variant={'h4'} style={{ marginTop: '4px' }}>
                    <Trans>Formula</Trans>
                </Text>
                <Box
                    style={{
                        borderRadius: '8px',
                        marginTop: '4px',
                        paddingBottom: '8px',
                        border: `1px solid ${colorSystem.neutral[3]}`,
                    }}
                >
                    <Flexbox
                        alignItems={'center'}
                        height={'40px'}
                        padding={'8px 16px 8px 0px'}
                        flexDirection={'row-reverse'}
                        justifyContent={'space-between'}
                    >
                        <PrimaryButton onClick={() => validate()}>
                            <Trans>Validate</Trans>
                        </PrimaryButton>
                        {getValidationMessage(validationData?.result, validateIsLoading)}
                    </Flexbox>

                    <MonacoEditor
                        value={formula}
                        inputControls={inputControls}
                        onChange={setFormula}
                        editorRef={editorRef}
                        markerData={editorMarkerData}
                    />

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        padding={'12px'}
                        borderTop={`1px solid ${colorSystem.neutral[3]}`}
                        style={{
                            backgroundColor: colorSystem.neutral[1],
                        }}
                    >
                        <Tabs value={activeTab} size="small" onChange={handleTabChange}>
                            <Tab value={'formulaError'} label={<Trans>Errors</Trans>} />
                            <Tab value={'formulaTestRun'} label={<Trans>Test run</Trans>} />
                        </Tabs>
                        {includeDocumentation ? (
                            <Box paddingLeft={'12px'}>
                                <DocumentationLink />
                            </Box>
                        ) : undefined}
                    </Box>

                    <Box style={{ height: '150px', paddingInline: '16px' }}>
                        <TabPanel value={activeTab} index={'formulaError'}>
                            <AdvancedFormulaErrorBox validationMessage={validationMessage} />
                        </TabPanel>
                        <TabPanel value={activeTab} index={'formulaTestRun'}>
                            <AdvancedFormulaTestRunBox
                                formula={formula}
                                validationData={validationData}
                                onTestRunChange={handleTestRunChange}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box paddingLeft={'12px'} paddingRight={'12px'} paddingTop={'4px'}>
                    <Text variant={'h4'} style={{ marginBottom: '12px' }}>
                        <Trans>Formula operators and functions</Trans>
                    </Text>
                    <Operators onClick={setFormulaAtOffset} />
                </Box>
                <Box padding={'12px'}>
                    <Text variant={'h4'}>
                        <Trans>Inputs</Trans>
                    </Text>
                    <Box maxHeight={'430px'} style={{ marginTop: '4px', overflowY: 'auto', overflowX: 'auto' }}>
                        <Inputs inputControls={inputControls} onInsertClick={setFormulaAtOffset} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
