import { DriverDetailsType } from '@luminovo/http-client';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

export interface DriverColumnContext {
    driverNamesToIdAndTypeMap: Record<string, { id: string; type: DriverDetailsType }>;
    driverIdsToNameAndTypeMap: Record<string, { name: string; type: DriverDetailsType }>;
}

export const useDriverColumnContext = (): DriverColumnContext => {
    const { data: drivers = [], isLoading } = useHttpQuery('GET /user-drivers', {}, { select: (res) => res.data });

    if (isLoading) {
        return {
            driverNamesToIdAndTypeMap: {},
            driverIdsToNameAndTypeMap: {},
        };
    }

    const driverNamesToIdAndTypeMap: DriverColumnContext['driverNamesToIdAndTypeMap'] = drivers.reduce(
        (acc, driver) => ({
            ...acc,
            [driver.name]: { id: driver.id, type: driver.details.type },
        }),
        {},
    );
    driverNamesToIdAndTypeMap['Sides of placement'] = {
        id: 'Sides_of_placement',
        type: DriverDetailsType.Automatic,
    };

    const driverIdsToNameAndTypeMap: DriverColumnContext['driverIdsToNameAndTypeMap'] = drivers.reduce(
        (acc, driver) => ({
            ...acc,
            [driver.id]: { name: driver.name, type: driver.details.type },
        }),
        {},
    );
    driverIdsToNameAndTypeMap['Sides_of_placement'] = {
        name: 'Sides of placement',
        type: DriverDetailsType.Automatic,
    };

    return { driverNamesToIdAndTypeMap, driverIdsToNameAndTypeMap };
};
