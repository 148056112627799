import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Column, FieldNumericController } from '@luminovo/design-system';
import { TableQuotationOfferItem } from '../types';
import { selectableColumn } from './columnSelectable';

export const columnMpq: Column<TableQuotationOfferItem> = selectableColumn({
    attribute: 'mpq',
    label: <Trans>MPQ</Trans>,
    align: 'right',
    render: ({ extracted }) => {
        return formatDecimal(extracted);
    },
    InputComponent: ({ control }) => {
        return (
            <>
                <FieldNumericController
                    control={control}
                    name="mpq"
                    FieldProps={{ autoFocus: true, fullWidth: true }}
                    required
                    isInteger
                    min={1}
                />
            </>
        );
    },
});
