import { Trans } from '@lingui/macro';
import { PrimaryButton, RiskOverviewIcon, Toolbar } from '@luminovo/design-system';
import { DesignItemOriginTypes, UserType } from '@luminovo/http-client';
import React from 'react';
import { useHistory } from 'react-router';
import { AssemblyMenuButton } from '../../../components/AssemblyMenuButton';
import { ExportBomSplitButton } from '../../../components/ExportBomButton';
import { ProgressAwareTabs } from '../../../components/ProgressAwareTabs';
import { useCurrentUserDetailsContext, useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import {
    useAssembly,
    useBomBreadcrumbs,
    useDescendants,
    useIsBomImportingAllowed,
} from '../../../resources/assembly/assemblyHandler';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../utils/rfqUtils';
import { route } from '../../../utils/routes';
import { ImportBomButton } from '../../Bom/components/ImportBomButton';
import { ModuleTableData, ViewContext, isBomItemTableData } from '../../Bom/components/ModuleTableData';

export const useRfqNotEditableTooltip = () => {
    const userType = useUserType();
    const isCustomer = userType === UserType.Customer;
    const orgName = useCurrentUserDetailsContext().organization.name;

    return isCustomer ? (
        <Trans>The assembly is locked. Contact {orgName} to make further changes.</Trans>
    ) : (
        <Trans>
            The assembly is locked. Changes can only be made when the RfQ status is “Quotation in progress” or “Request
            in draft”. If you use the customer portal: your customers can only make changes when the status is “Request
            in draft”.
        </Trans>
    );
};

export const ToolbarBomEditPage = React.memo(function ToolbarBomEditPage({
    rfqId,
    assemblyId,
    selectedModule,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
    selectedModule?: ModuleTableData;
}) {
    const { data: rfq } = useRfQ(rfqId);

    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const { data: assembly } = useAssembly(assemblyId);
    const breadcrumbs: Array<{ href?: string; title: string | JSX.Element }> = useBomBreadcrumbs({
        rfqId,
        assemblyId,
        assembly,
        currentParentAssemblyId,
    });

    const { data: descendants } = useDescendants(assemblyId);

    const descendantsData = descendants?.data;
    const designItems = React.useMemo(() => descendantsData?.design_items ?? [], [descendantsData]);

    // if the only design item is pcb we should display "import bom" button
    const onlyPcbDesignItem =
        designItems.length === 1 &&
        designItems.filter((designItem) => designItem.origin.type === DesignItemOriginTypes.PCB).length === 1;

    const allBreadrumbs = React.useMemo(() => {
        const selectedModuleId = selectedModule?.id;
        const currentItemDesignators = designItems
            .filter((designItem) => selectedModuleId?.includes(designItem.id))
            .map((designItem) => designItem.designator)
            .sort()
            .join(', ');
        const currentItemBreadcrumbs = currentItemDesignators ? [{ title: currentItemDesignators }] : [];
        return [...breadcrumbs, { title: 'BOM' }, ...currentItemBreadcrumbs];
    }, [breadcrumbs, designItems, selectedModule?.id]);

    const isPcbOnlyAssembly = assembly?.type.type === 'PcbOnly';

    const isBomImportAllowed = useIsBomImportingAllowed({ rfqId, assemblyId });

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <Toolbar
            breadcrumbs={allBreadrumbs}
            center={
                assembly && (
                    <ProgressAwareTabs
                        tab="Bom"
                        rfqId={rfqId}
                        assembly={assembly}
                        viewContext={viewContext}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )
            }
        >
            {selectedModule && isBomItemTableData(selectedModule) && assembly && (
                <AssemblyMenuButton
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
            {onlyPcbDesignItem && isRfqEditable && selectedModule && (
                <ImportBomButton rfqId={rfqId} assemblyId={assemblyId} viewContext={viewContext} />
            )}
            {selectedModule && isBomItemTableData(selectedModule) && (
                <RiskOverviewButton rfqId={rfqId} assemblyId={assemblyId} />
            )}
            {!selectedModule && (
                <>
                    <AssemblyMenuButton
                        assemblyId={assemblyId}
                        viewContext={viewContext}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />{' '}
                    {isRfqEditable && (
                        <ImportBomButton rfqId={rfqId} assemblyId={assemblyId} viewContext={viewContext} />
                    )}
                    <RiskOverviewButton rfqId={rfqId} assemblyId={assemblyId} />
                    {!isPcbOnlyAssembly && !isBomImportAllowed && <ExportBomSplitButton assemblyId={assemblyId} />}
                </>
            )}
        </Toolbar>
    );
});

const RiskOverviewButton = ({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) => {
    const history = useHistory();
    return (
        <PrimaryButton
            size={'medium'}
            onClick={() => {
                history.push(route('/rfqs/:rfqId/bom/assembly/:assemblyId', { rfqId, assemblyId }));
            }}
            startIcon={<RiskOverviewIcon />}
            style={{ whiteSpace: 'nowrap' }}
        >
            <Trans>Risk overview</Trans>
        </PrimaryButton>
    );
};
