import { assertUnreachable, logToExternalErrorHandlers } from '@luminovo/commons';
import {
    DriverStatusDTO,
    ManufacturingAssemblyDriverCountDTO,
    PerScenarioManufacturingAssemblyDriverCountDTO,
    StatusDTO,
} from '@luminovo/http-client';

const sortOrder: StatusDTO[] = ['Ok', 'Warning', 'Error'];

const sortByDriverStatus = (d1: DriverStatusDTO, d2: DriverStatusDTO) =>
    sortOrder.indexOf(d2.type) - sortOrder.indexOf(d1.type);

export const extractDriverCountStatus = (driverCount: ManufacturingAssemblyDriverCountDTO): DriverStatusDTO => {
    const { type, data } = driverCount;

    switch (type) {
        case 'Basic':
            // For the basic case we just return the status
            return data.status;

        case 'PerScenario':
            // For the PerScenario variant we collect the status for each sourcing
            // scenario and sort by severity. Then we pick the most severe one.
            const sourcingScenarioStatuses: DriverStatusDTO[] = Object.values(
                driverCount.data.sourcing_scenarios_response,
            ).map((driverCountInformation) => driverCountInformation.status);

            // Append the extra statuses
            const driverStatuses: DriverStatusDTO[] = [...sourcingScenarioStatuses, ...driverCount.data.extra_statuses];

            return getSingleDriverStatus(driverStatuses, driverCount);

        default:
            /* c8 ignore next */
            assertUnreachable(type);
    }
};

export const extractDriverCountStatusesFromExtraStatuses = (
    driverCount: PerScenarioManufacturingAssemblyDriverCountDTO,
): DriverStatusDTO => {
    const extraStatuses = driverCount.data.extra_statuses;
    return getSingleDriverStatus(extraStatuses, driverCount);
};

const getSingleDriverStatus = (
    driverStatuses: DriverStatusDTO[],
    driverCount: ManufacturingAssemblyDriverCountDTO,
): DriverStatusDTO => {
    driverStatuses.sort(sortByDriverStatus);

    if (driverStatuses.length > 0) {
        return driverStatuses[0];
    }

    logToExternalErrorHandlers(new Error('Driver status should not be undefined!'), { extra: driverCount });
    return { type: 'Ok' };
};
