import { SupplierContactDTO } from '@luminovo/http-client';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';

export function useConfirmInviteSupplierContact(args?: { disableOnError?: boolean }) {
    const { disableOnError = false } = args ?? {};
    const { mutateAsync } = useHttpMutation('POST /supplier-contacts/:id/invite', {
        snackbarMessage: null,
        disableOnError,
    });

    return {
        sendInvite: async (supplierContacts: SupplierContactDTO[]) => {
            const ids = supplierContacts.map((contact) => contact.id);

            await Promise.allSettled(
                ids.map((id) => {
                    return mutateAsync({ pathParams: { id } });
                }),
            );
        },
    };
}
