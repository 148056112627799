import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    QuestComponentsCredentialsInputDTORuntype,
    QuestComponentsResponseBodyDTORuntype,
} from './questComponentsBackendTypes';

export const questComponentsEndpoints = {
    'GET /3rdparty/questcomponents/credentials': endpoint({
        description: 'Returns credentials for quest components integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: QuestComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/questcomponents/credentials': endpoint({
        description: 'Uploads credentials for quest components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: QuestComponentsCredentialsInputDTORuntype,
        responseBody: QuestComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
    'PATCH /3rdparty/questcomponents/credentials': endpoint({
        description: 'Updates credentials for quest components API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: QuestComponentsCredentialsInputDTORuntype,
        responseBody: QuestComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
    'DELETE /3rdparty/questcomponents/credentials': endpoint({
        description: 'Deletes existing credentials for quest components API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
};
