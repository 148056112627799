import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { SystemDriverName } from '@luminovo/http-client';

export const getSystemDriverName = (driverName: SystemDriverName): string => {
    switch (driverName) {
        case 'pcb_width':
            return t`PCB width`;
        case 'pcb_height':
            return t`PCB height`;
        case 'pcb_thickness':
            return t`PCB thickness`;
        case 'panel_factor':
            return t`Panel factor`;
        case 'panel_width':
            return t`Panel width`;
        case 'panel_height':
            return t`Panel height`;
        case 'Sides_of_placement':
            return t`Sides of placement`;
        default:
            assertUnreachable(driverName);
    }
};
