import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    NewarkCredentialsDTORuntype,
    NewarkCredentialsInputDTORuntype,
    NewarkSupportedSettingDTORuntype,
} from './newarkBackendTypes';

export const newarkEndpoints = {
    'GET /3rdparty/newark/credentials': endpoint({
        description: 'Returns credentials for Newark integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: NewarkCredentialsDTORuntype }),
    }),
    'POST /3rdparty/newark/credentials': endpoint({
        description: 'Uploads credentials for Newark API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: NewarkCredentialsInputDTORuntype,
        responseBody: r.Record({ data: NewarkCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'PATCH /3rdparty/newark/credentials': endpoint({
        description: 'Updates credentials for Newark API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: NewarkCredentialsInputDTORuntype,
        responseBody: r.Record({ data: NewarkCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'DELETE /3rdparty/newark/credentials': endpoint({
        description: 'Deletes existing credentials for Newark API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'GET /3rdparty/newark/supported-store-location-settings': endpoint({
        description: 'Returns supported store locations and currencies for Newark API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(NewarkSupportedSettingDTORuntype),
    }),
};
