import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { route } from '../../utils/routes';
import { HomeNavbar, RfqNavbar } from '../Navbar';

const NavbarRouter: React.FunctionComponent = (): JSX.Element => {
    return (
        <Switch>
            <Route path={route('/rfqs/:rfqId')} render={(): JSX.Element => <RfqNavbar />} />
            <Route path="/" render={(): JSX.Element => <HomeNavbar />} />
        </Switch>
    );
};

export default NavbarRouter;
