// eslint-disable-next-line
export function TEMPORARY_patchIsLoading<T extends any>(result: T): T {
    // Read the first point in the v5 roadmap to understand why we do this
    // https://github.com/TanStack/query/discussions/4252
    // TL;DR:
    // The behaviour of isLoading changed from v3 to v4 in a breaking way,
    // in v5 they are more or less going to revert it to the v3 behaviour.
    // @ts-ignore
    return { ...result, isLoading: result.isInitialLoading };
}
