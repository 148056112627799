import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { RfqContext, StandardPartInventoryOfferDTO } from '@luminovo/http-client';
import { OfferChips, Solution } from '@luminovo/sourcing-core';
import React from 'react';
import { CloseDrawerButton } from '../../../../../components/contexts/ModalContext';
import { OfferOriginTag } from './OfferOriginTag';
import { StandardPartInformation } from './PartInformation';

export const InventoryDrawerHeader: React.FunctionComponent<{
    offer: StandardPartInventoryOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    fallbackSolutionTags?: Solution['solutionTags'];
}> = ({ offer, rfqContext, solution, fallbackSolutionTags }): JSX.Element => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={20}
            padding={'16px'}
            bgcolor={colorSystem.neutral[0]}
            borderBottom={`1px solid ${colorSystem.neutral[2]}`}
        >
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <CloseDrawerButton disableMargin={true} />
                <Flexbox alignItems={'center'} gap={8}>
                    <OfferOriginTag offer={offer} origin={offer.origin} />
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={12}>
                <Text variant="h2" color={colorSystem.neutral[8]}>
                    {offer.linked_location.name}
                </Text>
                <OfferChips solution={solution} fallbackSolutionTags={fallbackSolutionTags} />
            </Flexbox>
            <StandardPartInformation offer={offer} rfqContext={rfqContext} solution={solution} />
        </Flexbox>
    );
};
