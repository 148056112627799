import { PCBLayerSides } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parsePeelableMaskKeyword } from './parsePeelableMask';
import { parseSilkscreenKeyword } from './parseSilkscreen';
import { parseSoldermaskKeyword } from './parseSoldermask';
import { parseNo } from './parseYesNo';

// order matters: we want to parse BOTH keywords first
const sideKeywords: Record<Exclude<PCBLayerSides, 'none'>, string[]> = {
    [PCBLayerSides.BOTH]: ['(:?2|double).?sided?', 'zweiseitig', 'both', '(:?2|beid).?seitig'],
    [PCBLayerSides.TOP]: [
        'top',
        'TL',
        'BS',
        'Bestückungsseite',
        'c.s.',
        'component side',
        'l.c.',
        'lato componenti',
        'einseitig',
        'single.?sided?',
    ],
    [PCBLayerSides.BOTTOM]: [
        'bot(:?tom)?',
        'LS',
        'Lötseite',
        'solder side',
        'w.s.',
        'wire side',
        'l.s.',
        'lato saldatura',
    ],
};
// support things like 'top + bot' or 'top and bottom'
sideKeywords.both = sideKeywords.both.concat(
    `(:?${sideKeywords.top.join('|')})(:?.|\\s)*(:?${sideKeywords.bottom.join('|')})`,
);

export function parseSide(str: string): PcbAttribute | undefined {
    for (const [side, keywords] of Object.entries(sideKeywords)) {
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            return {
                attr: 'side',
                value: side as PCBLayerSides,
            };
        }
    }
    return undefined;
}

export function parseSoldermaskSide(str: string): PcbAttribute | undefined {
    const soldermask = parseSoldermaskKeyword(str);
    const side = parseSide(str);
    const no = parseNo(str);
    if (soldermask && (side || no)) {
        const value = side ? side.value : PCBLayerSides.NONE;
        return {
            attr: 'soldermaskSide',
            value: value as PCBLayerSides,
            // if we can match both => we have higher confidence as it's more specific
            confidence: value === PCBLayerSides.BOTH ? 1 : 0.75,
        };
    }
}

export function parseSilkscreenSide(str: string): PcbAttribute | undefined {
    const soldermask = parseSilkscreenKeyword(str);
    const side = parseSide(str);
    const no = parseNo(str);
    if (soldermask && (side || no)) {
        const value = side ? side.value : PCBLayerSides.NONE;
        return {
            attr: 'silkscreenSide',
            value: value as PCBLayerSides,
            // if we can match both => we have higher confidence as it's more specific
            confidence: value === PCBLayerSides.BOTH ? 1 : 0.75,
        };
    }
}

export function parsePeelableMask(str: string): PcbAttribute | undefined {
    const peelableMask = parsePeelableMaskKeyword(str);
    const side = parseSide(str);
    const no = parseNo(str);
    if (peelableMask && (side || no)) {
        const value = side ? side.value : PCBLayerSides.NONE;
        return {
            attr: 'peelableMask',
            value: value as PCBLayerSides,
            // if we can match both => we have higher confidence as it's more specific
            confidence: value === PCBLayerSides.BOTH ? 1 : 0.75,
        };
    }
}
