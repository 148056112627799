import { t, Trans } from '@lingui/macro';
import { formatToLongDate, isEqual } from '@luminovo/commons';
import { createColumnHelper, Link, SecondaryButton, TanStackTable, useTanStackTable } from '@luminovo/design-system';
import { DemandDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Add, Launch } from '@mui/icons-material';
import * as React from 'react';
import {} from 'react-router-dom';
import { route } from '../../../utils/routes';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';

const columnHelper = createColumnHelper<DemandDTO>();

const columns = [
    columnHelper.enum('context', {
        size: 160,
        getOptionLabel: (value) => value ?? '-',
        label: () => t`Context`,
        renderType: 'text',
    }),

    columnHelper.enum('component_ipn_id', {
        id: 'ipn',
        size: 100,
        getOptionLabel: (value) => value ?? '-',
        label: () => t`IPN`,
        cell: ({ row }) => <NegotiationIpnLabel ipn={row.original.component_ipn_id} />,
    }),

    columnHelper.text((row) => row.assembly?.designator ?? '-', {
        id: 'assembly',
        size: 180,
        label: () => t`Assembly`,
        cell: ({ row }) => {
            const { assembly } = row.original;

            if (!assembly) {
                return '-';
            }

            return (
                <Link
                    to={route('/assemblies/:assemblyId/dashboard', {
                        assemblyId: assembly.id,
                    })}
                    attention="high"
                    startIcon={<Launch fontSize="small" />}
                >
                    {assembly?.designator}
                </Link>
            );
        },
    }),

    columnHelper.number((row) => row.quantity.quantity, {
        id: 'quantity',
        size: 100,
        label: () => t`Quantity`,
        cell: ({ row }) => formatQuantity(row.original.quantity, { showPiecesUnit: false }),
    }),

    columnHelper.date('created_at', {
        size: 100,
        label: () => t`Created at`,
        cell: ({ row }) => formatToLongDate(row.original.created_at, { ifAbsent: '-' }),
    }),

    columnHelper.date('delivery_start_date', {
        size: 100,
        label: () => t`Delivery start date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_start_date, { ifAbsent: '-' }),
    }),

    columnHelper.date('delivery_end_date', {
        size: 100,
        label: () => t`Delivery end date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_end_date, { ifAbsent: '-' }),
    }),

    columnHelper.text((row) => row.ship_to_site?.name || '-', {
        id: 'shipToSite',
        size: 100,
        label: () => t`Ship to (site)`,
        cell: ({ row }) => {
            /* eslint-disable camelcase */
            const { ship_to_site } = row.original;

            if (ship_to_site) {
                return ship_to_site.name;
            }

            /* eslint-enable camelcase */

            return '-';
        },
    }),
    columnHelper.text((row) => row.customer?.name, {
        id: 'endCustomer',
        size: 100,
        label: () => t`End customer`,
        cell: ({ row }) => row.original.customer?.name ?? '-',
    }),

    columnHelper.text((row) => row.supplier_site?.name, {
        id: 'supplierSite',
        size: 100,
        label: () => t`Supplier (site)`,
        cell: ({ row }) => row.original.supplier_site?.name ?? '-',
    }),
];

export function TableDemands({
    onSelectionChange = () => {},
    showSelection = true,
    selectedDemandIds = [],
    demands,
    showImportButton = true,
}: {
    onSelectionChange?: (demandIds: string[]) => void;
    showSelection?: boolean;
    selectedDemandIds?: string[];
    demands?: DemandDTO[];
    showImportButton?: boolean;
}): JSX.Element {
    const { table } = useTanStackTable({
        data: demands,
        columns: columns,
        enableSelection: {
            enabled: showSelection,
            getRowId: (row) => row.id,
        },
    });

    const newSelectedDemandIds = table.getSelectedRowModel().rows.map((row) => row.original.id);

    React.useEffect(() => {
        if (!isEqual(newSelectedDemandIds, selectedDemandIds)) {
            onSelectionChange(newSelectedDemandIds);
        }
    }, [newSelectedDemandIds, selectedDemandIds, onSelectionChange]);

    return <TanStackTable size="small" table={table} ActionButton={showImportButton ? ActionButton : undefined} />;
}

function ActionButton() {
    return (
        <SecondaryButton href={route('/parts/demand/demand-importer')} startIcon={<Add />} size="medium">
            <Trans>Import demands</Trans>
        </SecondaryButton>
    );
}
