import * as React from 'react';
import { BaseField, BaseFieldProps, FieldConverter } from '../BaseField';

export interface FieldDateProps extends Omit<BaseFieldProps<string | null>, 'converter'> {}

export const FieldDate = React.forwardRef<HTMLDivElement, FieldDateProps>((props, ref) => {
    return (
        <BaseField
            type={'date'}
            ref={ref}
            converter={fieldConverterDate}
            InputProps={{ inputProps: { max: '2999-12-31' } }}
            {...props}
        />
    );
});

export const fieldConverterDate: FieldConverter<string | null> = {
    parseValue: (text) => {
        if (text === '') {
            return { ok: true, value: null };
        }

        const d = new Date(Date.parse(text));

        if (isNaN(d.getTime())) {
            return { ok: false };
        }

        return { ok: true, value: d.toISOString().split('T')[0] };
    },
    formatValue: (value) => {
        if (value === null) {
            return '';
        }

        const d = new Date(Date.parse(value));

        if (isNaN(d.getTime())) {
            return '';
        }

        return d.toISOString().split('T')[0];
    },
};
