import { colorSystem } from '@luminovo/design-system';
import { FiberManualRecord as CircleIcon } from '@mui/icons-material';
import React from 'react';
import { extractSolutionStatus } from '../../extractors';
import { Solution, SolutionStatus } from '../../types';
import {
    SolutionStatusErrorIcon,
    SolutionStatusErrorToSuccessIcon,
    SolutionStatusErrorToWarningIcon,
    SolutionStatusSuccessIcon,
    SolutionStatusSuccessToErrorIcon,
    SolutionStatusSuccessToWarningIcon,
    SolutionStatusWariningToErrorIcon,
    SolutionStatusWariningToSuccessIcon,
    SolutionStatusWarningIcon,
} from '../icons';

export const SolutionStatusIcon: React.FunctionComponent<{
    status: SolutionStatus;
    iconProps?: React.SVGProps<SVGSVGElement>;
}> = ({ status, iconProps }) => {
    switch (status) {
        case SolutionStatus.Good: {
            return <SolutionStatusSuccessIcon {...iconProps} />;
        }
        case SolutionStatus.Warning: {
            return <SolutionStatusWarningIcon {...iconProps} />;
        }
        case SolutionStatus.Error: {
            return <SolutionStatusErrorIcon {...iconProps} />;
        }
    }
};

export const SolutionStatusCircleIcon: React.FunctionComponent<{
    status: SolutionStatus;
}> = ({ status }) => {
    switch (status) {
        case SolutionStatus.Good: {
            return <CircleIcon style={{ color: colorSystem.green[5], fontSize: 12 }} />;
        }
        case SolutionStatus.Warning: {
            return <CircleIcon style={{ color: colorSystem.yellow[5], fontSize: 12 }} />;
        }
        case SolutionStatus.Error: {
            return <CircleIcon style={{ color: colorSystem.red[5], fontSize: 12 }} />;
        }
    }
};

export const SolutionStatusTransitionIcon: React.FunctionComponent<{
    solution: Pick<Solution, 'solutionTags'>;
    iconProps?: React.SVGProps<SVGSVGElement>;
}> = ({ solution, iconProps }) => {
    const originalStatus = extractSolutionStatus(solution, 'original');
    const effectiveStatus = extractSolutionStatus(solution, 'effective');

    if (originalStatus === SolutionStatus.Good) {
        if (effectiveStatus === SolutionStatus.Good) {
            return <SolutionStatusSuccessIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Warning) {
            return <SolutionStatusSuccessToWarningIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Error) {
            return <SolutionStatusSuccessToErrorIcon {...iconProps} />;
        }
    }

    if (originalStatus === SolutionStatus.Warning) {
        if (effectiveStatus === SolutionStatus.Good) {
            return <SolutionStatusWariningToSuccessIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Warning) {
            return <SolutionStatusWarningIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Error) {
            return <SolutionStatusWariningToErrorIcon {...iconProps} />;
        }
    }

    if (originalStatus === SolutionStatus.Error) {
        if (effectiveStatus === SolutionStatus.Good) {
            return <SolutionStatusErrorToSuccessIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Warning) {
            return <SolutionStatusErrorToWarningIcon {...iconProps} />;
        }
        if (effectiveStatus === SolutionStatus.Error) {
            return <SolutionStatusErrorIcon {...iconProps} />;
        }
    }

    throw new Error(`Unknown solution status transition: ${originalStatus} -> ${effectiveStatus}`);
};
