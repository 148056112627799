import { getThousandSeparator, NumberSeparator } from '../determineDecimalSeparator';
import { Parser } from '../types';
import { getDecimalRegexString, replaceThousandSeparator } from './parseNumber';

const stockKeywords = ['Lagerbestand', 'Stock'];
export const stockRegex = new RegExp(`(?:\\s|^)(?:${stockKeywords.join('|')})(?:\\s|:|\\*|$)`, 'i');

const inStockKeywords = ['In stock', 'Ab Lager', 'En stock'];
const inStockRegex = new RegExp(`(?:\\s|^)(?:${inStockKeywords.join('|')})(?:\\s|,|$)`, 'i');

export const createParseStock = (decimalSeparator: NumberSeparator): Parser => {
    const thousandSeparator = getThousandSeparator(decimalSeparator);
    const numberThenInStockRegex = new RegExp(
        `${getDecimalRegexString(decimalSeparator)}.*?(?:${inStockKeywords.join('|')})`,
        'i',
    );
    const stockWithNumberRegex = new RegExp(
        `${stockRegex.source}(?:\\s+)?${getDecimalRegexString(decimalSeparator)}`,
        'i',
    );
    return (str: string) => {
        const matchNumberThenInStock = numberThenInStockRegex.exec(str);
        if (matchNumberThenInStock) {
            const [, stock, thousand] = matchNumberThenInStock;
            const stockNumber =
                parseFloat(replaceThousandSeparator(stock, thousandSeparator).replace(decimalSeparator, '.')) *
                (thousand ? 1000 : 1);
            if (stockNumber && Number.isInteger(stockNumber)) {
                return { attr: 'stock', value: stockNumber };
            }
        }
        const matchStockWithNumber = stockWithNumberRegex.exec(str);
        if (matchStockWithNumber) {
            const [, stock, thousand] = matchStockWithNumber;
            const stockNumber =
                parseFloat(replaceThousandSeparator(stock, thousandSeparator).replace(decimalSeparator, '.')) *
                (thousand ? 1000 : 1);
            if (stockNumber && Number.isInteger(stockNumber)) {
                return { attr: 'stock', value: stockNumber };
            }
        }
        if (inStockRegex.test(str)) {
            return { attr: 'inStock', value: true, confidence: 1 };
        }

        return undefined;
    };
};
