import { Thumbnail, colorSystem } from '@luminovo/design-system';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

export function PcbThumbnail({ pcbId, size }: { pcbId: string; size: number }): JSX.Element {
    const { data: pcb } = useHttpQuery(
        'GET /ems/pcb/v2/pcbs/:pcbId',
        {
            pathParams: { pcbId },
            requestHeaders: {
                //@ts-ignore
                Accept: 'application/json;version=beta',
            },
        },
        {
            useErrorBoundary: false,
            meta: {
                globalErrorHandler: false,
            },
            staleTime: 1000 * 60 * 60, // 1 hour
        },
    );

    const path = pcb?.specifications[0]?.previews.front?.path ?? '';
    const key = pcb?.specifications[0]?.previews.front?.key ?? '';
    const url = new URL(path, window.location.origin);
    url.searchParams.set('k', key);

    return (
        <Thumbnail
            width={`${size}px`}
            height={`${size}px`}
            src={url.toString()}
            ImageProps={{
                style: {
                    borderRadius: '4px',
                },
            }}
            style={{
                padding: 4,
                borderRadius: 8,
                boxSizing: 'border-box',
                border: `1px solid ${colorSystem.neutral[3]}`,
                background: colorSystem.neutral[1],
            }}
        />
    );
}
