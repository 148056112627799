import { t } from '@lingui/macro';
import {
    ACTIVITY_CREATION_ALL_LEVELS,
    ALL_CATEGORY_SELECTION_OPTIONS,
    ALL_PROCESS_SELECTION_OPTIONS,
    ActivityDTO,
    ActivityLevel,
    CALCULATION_TYPE,
    CALCULATION_TYPE_WITH_NULL,
    CalculationType,
    CalculationTypeWithNone,
    ResourceDTO,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import * as Yup from 'yup';
import {
    isValueAlreadyUsed,
    mustBeANumberErrorMessage,
    positiveNumberRequired,
    requiredErrorMessage,
} from '../../../utils/yupValidationUtils';
import { driverNoPanelCheckYupObject, driverYupObject, timeYupObjectDurationRequired } from '../manufacturingSchemas';

const activityActivityBaseSchema = {
    process: Yup.mixed().required(requiredErrorMessage()).oneOf(ALL_PROCESS_SELECTION_OPTIONS, requiredErrorMessage()),
    category: Yup.mixed()
        .oneOf(ALL_CATEGORY_SELECTION_OPTIONS, requiredErrorMessage())
        .required(requiredErrorMessage()),
    level: Yup.string().oneOf(ACTIVITY_CREATION_ALL_LEVELS, requiredErrorMessage()).required(requiredErrorMessage()),
    isActivityPerPanel: Yup.boolean().required(requiredErrorMessage()),
    description: Yup.string(),
};

export const createInternalNumberValidationSchema = (activities: ActivityDTO[]): Yup.StringSchema =>
    Yup.string().test(
        'activity-internalActivityNumber-used',
        t`An activity with this internal activity number already exists`,
        (value) => {
            if ([null, undefined, ''].includes(value?.trim())) {
                return true;
            } else {
                return !isValueAlreadyUsed(
                    value ?? '',
                    activities.flatMap((a) => a.internal_number ?? []),
                    undefined,
                );
            }
        },
    );

const createActivity = (activities: ActivityDTO[]) =>
    Yup.object().shape({
        name: Yup.string()
            .required(requiredErrorMessage())
            .test(
                'activity-name-used',
                t`An activity with this name already exists`,
                (value) =>
                    !isValueAlreadyUsed(
                        value || '',
                        activities.map((a) => a.name),
                        undefined,
                    ),
            ),
        internalActivityNumber: createInternalNumberValidationSchema(activities),
        ...activityActivityBaseSchema,
    });

const isResourceActive = (resourceId: string, resources: ResourceDTO[]): boolean | undefined => {
    const resource = resources.find((driver) => driver.id === resourceId);
    if (resource) {
        return resource.status === 'Active';
    } else {
        return undefined; // return undefined if driver not found
    }
};

const resourcesDetailsSchema = (resources: ResourceDTO[]) =>
    Yup.array(
        Yup.object()
            .shape({
                resourceId: Yup.string()
                    .test('resourceIsActive', t`Resource is inactive`, (resourceId) => {
                        return (
                            resourceId === null ||
                            resourceId === undefined ||
                            (isResourceActive(resourceId, resources) ?? false)
                        );
                    })
                    .typeError(requiredErrorMessage())
                    .required(requiredErrorMessage()),
                multiplier: positiveNumberRequired().typeError(mustBeANumberErrorMessage()),
            })
            .required(requiredErrorMessage()),
    ).min(1, t`At least one resource is required.`);

const formulaObject = Yup.object().shape({
    formula: Yup.string().required(),
    unit: Yup.string().required(),
});

export const createActivitySchema = (
    activities: ActivityDTO[],
    drivers: UserDriverDetailsDTO[],
    resources: ResourceDTO[],
): Yup.ObjectSchema => {
    return Yup.object().shape({
        activity: createActivity(activities),
        resourcesDetails: resourcesDetailsSchema(resources),
        selectedUnitCalculationType: Yup.mixed().when('activity.level', {
            is: (val: ActivityLevel) => {
                return val === 'Unit';
            },
            then: Yup.string().oneOf(CALCULATION_TYPE).required(requiredErrorMessage()),
        }),
        selectedBatchCalculationType: Yup.mixed().when('activity.level', {
            is: (val: ActivityLevel) => {
                return val === 'Unit';
            },
            then: Yup.string().oneOf(CALCULATION_TYPE_WITH_NULL).required(requiredErrorMessage()),
            otherwise: Yup.mixed().when('activity.level', {
                is: (val: ActivityLevel) => {
                    return val === 'Batch';
                },
                then: Yup.string().oneOf(CALCULATION_TYPE).required(requiredErrorMessage()),
            }),
        }),
        selectedProjectCalculationType: Yup.mixed().when('activity.level', {
            is: (val: ActivityLevel) => {
                return val === 'Project';
            },
            then: Yup.string().oneOf(CALCULATION_TYPE).required(requiredErrorMessage()),
        }),
        fixedUnitCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedUnitCalculationType', {
                is: (val: CalculationType) => val === 'Fixed',
                then: Yup.object().shape({
                    fixedUnitTime: timeYupObjectDurationRequired(),
                }),
            }),
        }),
        linearUnitCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedUnitCalculationType', {
                is: (val: CalculationType) => val === 'Linear',
                then: Yup.object().shape({
                    driverId: driverNoPanelCheckYupObject(drivers).required(requiredErrorMessage()),
                    variableUnitTime: timeYupObjectDurationRequired(),
                }),
            }),
        }),
        formulaUnitCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedUnitCalculationType', {
                is: (val: CalculationType) => val === 'Formula',
                then: formulaObject,
            }),
        }),
        // batch
        fixedBatchCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedBatchCalculationType', {
                is: (val: CalculationTypeWithNone) => val === 'Fixed',
                then: Yup.object().shape({
                    fixedBatchTime: timeYupObjectDurationRequired(),
                }),
            }),
            otherwise: Yup.object().when('activity.level', {
                is: (val: ActivityLevel) => val === 'Batch',
                then: Yup.object().when('selectedBatchCalculationType', {
                    is: (val: CalculationType) => val === 'Fixed',
                    then: Yup.object().shape({
                        fixedBatchTime: timeYupObjectDurationRequired(),
                    }),
                }),
            }),
        }),
        linearBatchCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedBatchCalculationType', {
                is: (val: CalculationTypeWithNone) => val === 'Linear',
                then: Yup.object().shape({
                    driverId: driverYupObject(drivers).required(requiredErrorMessage()),
                    variableBatchTime: timeYupObjectDurationRequired(),
                }),
            }),
            otherwise: Yup.object().when('activity.level', {
                is: (val: ActivityLevel) => val === 'Batch',
                then: Yup.object().when('selectedBatchCalculationType', {
                    is: (val: CalculationType) => val === 'Linear',
                    then: Yup.object().shape({
                        driverId: driverYupObject(drivers, true).required(requiredErrorMessage()),
                        variableBatchTime: timeYupObjectDurationRequired(),
                    }),
                }),
            }),
        }),
        formulaBatchCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Unit',
            then: Yup.object().when('selectedBatchCalculationType', {
                is: (val: CalculationTypeWithNone) => val === 'Formula',
                then: formulaObject,
            }),
            otherwise: Yup.object().when('activity.level', {
                is: (val: ActivityLevel) => val === 'Batch',
                then: Yup.object().when('selectedBatchCalculationType', {
                    is: (val: CalculationType) => val === 'Formula',
                    then: formulaObject,
                }),
            }),
        }),
        // project
        fixedProjectCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Project',
            then: Yup.object().when('selectedProjectCalculationType', {
                is: (val: CalculationType) => val === 'Fixed',
                then: Yup.object().shape({
                    fixedProjectTime: timeYupObjectDurationRequired(),
                }),
            }),
        }),
        linearProjectCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Project',
            then: Yup.object().when('selectedProjectCalculationType', {
                is: (val: CalculationType) => val === 'Linear',
                then: Yup.object().shape({
                    driverId: driverNoPanelCheckYupObject(drivers).required(requiredErrorMessage()),
                    variableProjectTime: timeYupObjectDurationRequired(),
                }),
            }),
        }),
        formulaProjectCalculationType: Yup.object().when('activity.level', {
            is: (val: ActivityLevel) => val === 'Project',
            then: Yup.object().when('selectedProjectCalculationType', {
                is: (val: CalculationType) => val === 'Formula',
                then: formulaObject,
            }),
        }),
    });
};
