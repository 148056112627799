import { RowRecord } from '@luminovo/http-client';

const rawColumnsStorageKey = 'luminovo.bom-importer.raw-columns';

export function saveRawColumns({ headerData }: { headerData: RowRecord }): void {
    sessionStorage.setItem(rawColumnsStorageKey, JSON.stringify(headerData));
}

export function loadPrevRawColumns(): RowRecord | null {
    const result = sessionStorage.getItem(rawColumnsStorageKey);

    if (!result) {
        return null;
    }

    return JSON.parse(result);
}
