import { t, Trans } from '@lingui/macro';
import { colorSystem, FieldSelect, Text } from '@luminovo/design-system';
import { EmailTemplateDTO } from '@luminovo/http-client';
import { Controller, useFormContext } from 'react-hook-form';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { renderQuoteRequestTemplate, RenderQuoteRequestTemplateProps } from '../renderQuoteRequestTemplate';
import { EmailManagerFormState } from '../types';

export const FormItemEmailTemplate = ({
    index,
    renderContext,
}: {
    index: number;
    renderContext: RenderQuoteRequestTemplateProps;
}): JSX.Element => {
    const { control, setValue } = useFormContext<EmailManagerFormState>();

    const { data: emailTemplates = [] } = useHttpQuery(
        'GET /email-template',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => Array.from(res.items).sort((a, b) => a.name.localeCompare(b.name)),
        },
    );

    const none = t`None`;
    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]} style={{ minWidth: 86 }}>
                <Trans>Template</Trans>
            </Text>
            <Controller
                control={control}
                name={`supplierEmails.${index}.email.template`}
                render={({ field }) => {
                    return (
                        <FieldSelect<EmailTemplateDTO>
                            options={emailTemplates}
                            value={field.value ?? null}
                            onChange={(template: EmailTemplateDTO | null) => {
                                field.onChange(template);
                                const { subject, body } = renderQuoteRequestTemplate(
                                    template ?? undefined,
                                    renderContext,
                                );
                                setValue(`supplierEmails.${index}.email.subject`, subject);
                                setValue(`supplierEmails.${index}.email.body`, body);
                            }}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            getOptionKey={(t) => t?.id ?? none}
                            getOptionLabel={(t) => t?.name ?? none}
                        />
                    );
                }}
            />
        </>
    );
};
