import { colorSystem, MenuButton } from '@luminovo/design-system';
import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)({
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '1px solid transparent',
});

const StyledMenuItem = styled(MenuItem)({
    padding: '8px',
    borderRadius: '50%',
});

export const CadViewerDropdownColors = [
    colorSystem.violet[7],
    colorSystem.blue[7],
    colorSystem.green[7],
    colorSystem.red[7],
    colorSystem.yellow[7],
    colorSystem.neutral.white,
    colorSystem.violet[5],
    colorSystem.blue[5],
    colorSystem.green[5],
    colorSystem.red[5],
    colorSystem.yellow[5],
] as const;

export const CadFileColorSelector = ({
    fileColor,
    onColorChange,
}: {
    fileColor: string;
    onColorChange: (color: string) => void;
}) => {
    return (
        <>
            <MenuButton
                appearance="tertiary"
                style={{ padding: 0, margin: 0, minWidth: 'unset', height: 'unset' }}
                icon={
                    <StyledBox
                        bgcolor={fileColor}
                        style={{
                            borderColor:
                                fileColor === colorSystem.neutral.white ? colorSystem.neutral[4] : 'transparent',
                        }}
                    />
                }
                menuProps={{
                    transformOrigin: { horizontal: 72, vertical: -8 },
                    MenuListProps: {
                        style: {
                            columnCount: 2,
                            columnGap: 0,
                            padding: '8px',
                        },
                    },
                }}
            >
                {CadViewerDropdownColors.map((color, index) => (
                    <StyledMenuItem
                        key={index}
                        onClick={() => {
                            onColorChange(color);
                        }}
                    >
                        <StyledBox
                            bgcolor={color}
                            style={
                                color === colorSystem.neutral.white
                                    ? { borderColor: colorSystem.neutral[4] }
                                    : undefined
                            }
                        />
                    </StyledMenuItem>
                ))}
            </MenuButton>
        </>
    );
};
