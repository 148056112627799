import { Trans } from '@lingui/macro';
import { Card, Column, DataTable, Flexbox, Row, Text, columnWidth, useDataTableState } from '@luminovo/design-system';
import {
    DriverIdDTO,
    DriversOfManufacturingDetailsDTO,
    ErrorDriverStatusDTO,
    ManufacturingAssemblyDetailsDTO,
    ManufacturingScenarioDTO,
    PerScenarioManufacturingAssemblyDriverCountDTO,
    SourcingScenarioDTO,
    StatusDTO,
    WarningDriverStatusDTO,
} from '@luminovo/http-client';
import {
    driverIdEquals,
    extractDriverCountStatusesFromExtraStatuses,
    selectDriverIdDto,
    selectDriverName,
    selectDriverNotes,
    selectDriverType,
} from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useSourcingScenariosOfRfq } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { getStatusInformationFromDrivers } from '../shared/driverStatusInformation';
import { DriverStatusIconWithTooltip } from '../utils/status';
import { DriverStatusInformationCard } from './DriverStatusInformationCard';
import { CardHeader, DriverInformation } from './components/DriverInformationCard';
import { EditDriverCount } from './components/DriversCard';
import {
    EditDriverCountFormState,
    EditDriverCountProps,
} from './components/DriversCard/EditDriverCount/EditDriverCount';
import {
    useCreateMutationUpdateBasicDriverCount,
    useCreateMutationUpdatePerScenarioDriverCount,
} from './components/DriversCard/EditDriverCount/editDriverCountFormFunctions';
import {
    InconsistentDriverPopup,
    InconsistentDriverPopupProps,
} from './components/DriversCard/InconsistentDriverPopup';
import { LinkedDrivers, LinkedDriversProps } from './components/DriversCard/LinkedDrivers';

export const MultipleDriversInformationCard = ({
    driver,
    driverInformation,
    manufacturingAssemblyDetails,
    rfqId,
}: {
    driver: DriversOfManufacturingDetailsDTO;
    driverInformation: DriverInformation;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    rfqId: string;
}): JSX.Element => {
    if (driver.driver_count.type !== 'PerScenario') return <></>;
    const perManufacturingScenarioDriverCount = driver.driver_count;
    const sourcingScenarios = Object.values(perManufacturingScenarioDriverCount.data.sourcing_scenarios_response);
    const sourcingScenarioStatuses = sourcingScenarios.map((driverCountInformation) => driverCountInformation.status);
    const isSourcingDriver = driver.is_sourcing_driver;

    const filteredStatuses: (WarningDriverStatusDTO | ErrorDriverStatusDTO)[] = [
        ...sourcingScenarioStatuses,
        ...driver.driver_count.data.extra_statuses,
    ].filter((status): status is WarningDriverStatusDTO | ErrorDriverStatusDTO => status.type !== 'Ok');

    return (
        <Card maxWidth="100%" overflow={'auto'} gap={'24px'} minHeight={'388px'}>
            <CardHeader driverInformation={driverInformation} />
            {filteredStatuses.map((driverStatus, index) => (
                <DriverStatusInformationCard
                    key={index}
                    status={driverStatus}
                    rfqId={rfqId}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                />
            ))}
            {isSourcingDriver && (
                <SourcingDriversTable
                    driver={driver}
                    driverCount={perManufacturingScenarioDriverCount}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                    rfqId={rfqId}
                />
            )}
        </Card>
    );
};

interface SourcingDriverTableRow {
    sourcingScenarioName: string | undefined;
    manufacturingScenarioNames: string;
    linkedDriversProps: LinkedDriversProps;
    inconsistentDriverPopupProps: InconsistentDriverPopupProps;
    driverStatus: {
        status: StatusDTO;
        description: string;
    };
    editDriverCountProps: EditDriverCountProps | undefined;
}

const collectSourcingDriverRows = ({
    driver,
    driverCount,
    sourcingScenarios,
    manufacturingScenarios,
    rfqId,
    manufacturingAssemblyDetails,
    createSubmitHandler,
    updateDriverCountForManufacturingScenariosWithoutSourcing,
}: {
    driver: DriversOfManufacturingDetailsDTO;
    driverCount: PerScenarioManufacturingAssemblyDriverCountDTO;
    sourcingScenarios: SourcingScenarioDTO[];
    manufacturingScenarios: ManufacturingScenarioDTO[];
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    createSubmitHandler: (
        assemblyDetailsId: string,
        driverId: DriverIdDTO,
        sourcingScenarioId: string,
    ) => SubmitHandler<EditDriverCountFormState>;
    updateDriverCountForManufacturingScenariosWithoutSourcing: SubmitHandler<EditDriverCountFormState>;
}): SourcingDriverTableRow[] => {
    const sourcingScenarioResponse = Object.entries(driverCount.data.sourcing_scenarios_response);
    const manufacturingScenariosWithNoSourcingScenario: ManufacturingScenarioDTO[] = [];
    const manufacturingScenarioMap: Record<string, ManufacturingScenarioDTO[]> = {};

    const driverId = selectDriverIdDto(driver.driver);

    manufacturingScenarios.forEach((manufacturingScenario) => {
        const sourcingScenario = manufacturingScenario.sourcing_scenario;

        if (sourcingScenario === null) {
            manufacturingScenariosWithNoSourcingScenario.push(manufacturingScenario);
        } else {
            if (!manufacturingScenarioMap[sourcingScenario]) {
                manufacturingScenarioMap[sourcingScenario] = [manufacturingScenario];
            } else {
                manufacturingScenarioMap[sourcingScenario].push(manufacturingScenario);
            }
        }
    });

    const items: SourcingDriverTableRow[] = sourcingScenarioResponse
        .map(([sourcingId, driverCountDetails]) => {
            const sourcingScenario = sourcingScenarios.find((sourcingSummary) => sourcingSummary.id === sourcingId);
            if (!sourcingScenario) {
                throw new Error('Sourcing scenario cannot be undefined');
            }

            const { type, description } = getStatusInformationFromDrivers({
                driverStatus: driverCountDetails.status,
                rfqId,
                manufacturingAssemblyDetails,
                sourcingScenarioDTOs: sourcingScenarios,
            });

            const manufacturingScenarioArray: ManufacturingScenarioDTO[] | undefined =
                manufacturingScenarioMap[sourcingId];

            const manufacturingScenarioNames =
                manufacturingScenarioArray === undefined
                    ? ''
                    : manufacturingScenarioArray
                          .filter((manufacturingScenario) =>
                              manufacturingScenario.statuses.some(
                                  (driverStatus) =>
                                      driverStatus.type === 'Status' &&
                                      driverIdEquals(driverStatus.details.driver_id, driverId),
                              ),
                          )
                          .map((manufacturingScenario) => manufacturingScenario.name)
                          .join(', ');

            const editDriverCountProps: EditDriverCountProps = {
                driverName: selectDriverName(driver.driver),
                driverType: selectDriverType(driver.driver),
                driverNotes: selectDriverNotes(driver.driver),
                automaticCount:
                    (driverCount.type === 'PerScenario'
                        ? driverCount.data.sourcing_scenarios_response[sourcingId].automatic_count
                        : undefined) ?? undefined,
                driverCountInconsistencies:
                    (driverCount.type === 'PerScenario'
                        ? driverCount.data.sourcing_scenarios_response[sourcingId]
                              .assembly_to_configuration_driver_count_mismatch
                        : undefined) ?? [],
                assemblyWideCount:
                    driverCount.data.sourcing_scenarios_response[sourcingId].assembly_wide_count ?? undefined,
                onSubmit: createSubmitHandler(manufacturingAssemblyDetails.id, driverId, sourcingScenario.id),
            };

            const item: SourcingDriverTableRow = {
                sourcingScenarioName: sourcingScenario.name,
                manufacturingScenarioNames,
                linkedDriversProps: {
                    type: 'Single value',
                    value: driverCountDetails.assembly_wide_count ?? driverCountDetails.automatic_count ?? undefined,
                    isOverwritten: driverCountDetails.assembly_wide_count !== null,
                },
                inconsistentDriverPopupProps: {
                    configurationCounts: driverCountDetails.assembly_to_configuration_driver_count_mismatch,
                    assemblyWideDriverCount: driverCountDetails.assembly_wide_count ?? undefined,
                },
                driverStatus: {
                    status: type,
                    description,
                },
                editDriverCountProps,
            };
            return item;
        })
        .sort((row, otherRow) => row.sourcingScenarioName!.localeCompare(otherRow.sourcingScenarioName!));

    if (manufacturingScenariosWithNoSourcingScenario.length > 0) {
        const driverStatus = extractDriverCountStatusesFromExtraStatuses(driverCount);
        const { type, description } = getStatusInformationFromDrivers({
            driverStatus,
            rfqId,
            manufacturingAssemblyDetails,
            sourcingScenarioDTOs: sourcingScenarios,
        });

        const noSourcingScenarioRow: SourcingDriverTableRow = {
            sourcingScenarioName: undefined,
            manufacturingScenarioNames: manufacturingScenariosWithNoSourcingScenario
                .map((scenario) => scenario.name)
                .join(', '),
            linkedDriversProps: {
                type: 'Single value',
                value: driverCount.data.assembly_wide_count ?? undefined,
                isOverwritten: true,
            },
            inconsistentDriverPopupProps: {
                assemblyWideDriverCount: undefined,
                configurationCounts: [],
            },
            driverStatus: {
                status: type,
                description,
            },
            editDriverCountProps: {
                driverName: selectDriverName(driver.driver),
                driverType: selectDriverType(driver.driver),
                driverNotes: selectDriverNotes(driver.driver),
                automaticCount: undefined,
                driverCountInconsistencies: [],
                assemblyWideCount: driverCount.data.assembly_wide_count ?? undefined,
                onSubmit: updateDriverCountForManufacturingScenariosWithoutSourcing,
            },
        };
        items.push(noSourcingScenarioRow);
    }

    return items;
};

const columns: Column<SourcingDriverTableRow>[] = [
    {
        label: <Trans>Sourcing scenario</Trans>,
        id: 'sourcingScenario',
        render: ({ data: rowData }: Row<SourcingDriverTableRow>) => {
            return (
                <TableCell>
                    <Text>{rowData.sourcingScenarioName ?? '-'}</Text>
                </TableCell>
            );
        },
    },
    {
        label: <Trans>Manufacturing scenarios</Trans>,
        id: 'manufacturingScenarios',
        render: ({ data: rowData }: Row<SourcingDriverTableRow>) => {
            return (
                <TableCell>
                    <Text>{rowData.manufacturingScenarioNames}</Text>
                </TableCell>
            );
        },
    },
    {
        label: <Trans>Driver count</Trans>,
        id: 'driverCount',
        render: ({ data: rowData }: Row<SourcingDriverTableRow>) => {
            const shouldDisplayInconsistentDriverPopup =
                rowData.inconsistentDriverPopupProps.configurationCounts.length > 0;
            return (
                <TableCell>
                    <Flexbox gap="8px">
                        <LinkedDrivers {...rowData.linkedDriversProps} />
                        {shouldDisplayInconsistentDriverPopup && (
                            <InconsistentDriverPopup {...rowData.inconsistentDriverPopupProps} />
                        )}
                    </Flexbox>
                </TableCell>
            );
        },
    },
    {
        label: '', // intentionally blank
        id: 'actionMenu',
        render: ({ data: rowData }: Row<SourcingDriverTableRow>) => (
            <TableCell>
                {rowData.editDriverCountProps && <EditDriverCount {...rowData.editDriverCountProps} />}
            </TableCell>
        ),
        width: columnWidth.extraSmall,
    },
    {
        label: '',
        id: 'status',
        render: ({ data: rowData }: Row<SourcingDriverTableRow>) => {
            const driverStatus = rowData.driverStatus;
            return (
                <TableCell>
                    <DriverStatusIconWithTooltip
                        status={driverStatus.status}
                        description={driverStatus.description}
                        iconType={'status'}
                    />
                </TableCell>
            );
        },
        width: columnWidth.extraSmall,
    },
];

const SourcingDriversTable = ({
    driver,
    driverCount,
    manufacturingAssemblyDetails,
    rfqId,
}: {
    driver: DriversOfManufacturingDetailsDTO;
    driverCount: PerScenarioManufacturingAssemblyDriverCountDTO;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    rfqId: string;
}) => {
    const { data: sourcingScenarios = [], isLoading: isLoadingSourcingScenarios } = useSourcingScenariosOfRfq(rfqId);

    const { data: manufacturingScenarios = [], isLoading: isLoadingManufacturingScenarios } = useHttpQuery(
        'GET /manufacturing-scenarios',
        {
            queryParams: {
                /* eslint-disable-next-line camelcase */
                manufacturing_assembly_details_id: manufacturingAssemblyDetails.id,
            },
        },
        { select: (data) => data.data },
    );

    const createSubmitHandler = useCreateMutationUpdatePerScenarioDriverCount();
    const updateDriverCountForManufacturingScenariosWithoutSourcing = useCreateMutationUpdateBasicDriverCount()(
        manufacturingAssemblyDetails.id,
        selectDriverIdDto(driver.driver),
    );

    const items =
        isLoadingManufacturingScenarios || isLoadingSourcingScenarios
            ? []
            : collectSourcingDriverRows({
                  driver,
                  driverCount,
                  sourcingScenarios,
                  manufacturingScenarios,
                  rfqId,
                  manufacturingAssemblyDetails,
                  createSubmitHandler,
                  updateDriverCountForManufacturingScenariosWithoutSourcing,
              });

    const tableState = useDataTableState<SourcingDriverTableRow>({
        columns,
        items,
        persistenceId: 'sourcingDriversTable',
    });

    return <DataTable tableState={tableState} key={'sourcingDriversTable'} size={'medium'} />;
};
