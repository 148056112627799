import { t, Trans } from '@lingui/macro';
import { assertUnreachable, transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import {
    FieldCheckboxControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Text,
} from '@luminovo/design-system';
import { AutomaticDriverAggregationDTO, DriverDetailsType } from '@luminovo/http-client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, RadioGroup } from '@mui/material';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import {
    aggregationTypeFullPublicTranslations,
    countAggregationValuePublicTranslations,
    driverTypeEnumPublicTranslations,
    sumAggregationvaluePublicTranslations,
} from '../../../resources/driver/driverBackendTypes';
import { usePageParams } from '../../../resources/hooks';
import { colorSystem } from '../../../themes';
import { AddDriverFormInputs, DriverDetailsInput, PackageOptions } from './AddDriverFormTypes';
import { DriverFilterFormComponent } from './DriverCalculationForm/driverCalculationFormComponents';
import { useIsDriverUsedInActivitiesOrExpenses } from './isActivityUsedInExpenses';

const CheckBoxWithBatchActivityAndExpenseCheck = ({
    control,
}: {
    control: Control<AddDriverFormInputs>;
}): JSX.Element => {
    const { driverId } = usePageParams<'/manufacturing/driver/:driverId/edit'>();
    const { isDriverUsedInBatchActivityOrBatchExpenseOrProjectExpense, allNamesOfActivities, allNamesOfExpenses } =
        /* because we can only edit user drivers with the route '/manufacturing/driver/:driverId/edit'
         (see usePageParams above), this hard coding of User should be safe. */
        useIsDriverUsedInActivitiesOrExpenses({ type: 'User', value: driverId });

    return (
        <>
            <FieldCheckboxControlled
                name={'isPerPanel'}
                control={control}
                FieldProps={{
                    disabled: isDriverUsedInBatchActivityOrBatchExpenseOrProjectExpense,
                }}
            />
            {isDriverUsedInBatchActivityOrBatchExpenseOrProjectExpense && (
                <Flexbox gap={'4px'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                    <Text style={{ color: colorSystem.neutral[7] }}>
                        {t`This setting cannot be changed because the driver is used in the following entities:`}{' '}
                        {allNamesOfActivities.length > 0 && (
                            <>
                                <Trans>Activities</Trans>: {allNamesOfActivities.join(', ')}{' '}
                            </>
                        )}
                        {allNamesOfActivities.length > 0 && allNamesOfExpenses.length > 0 && <>{', '}</>}
                        {allNamesOfExpenses.length > 0 && (
                            <>
                                <Trans>Expenses</Trans>: {allNamesOfExpenses.join(', ')}{' '}
                            </>
                        )}
                    </Text>
                </Flexbox>
            )}
        </>
    );
};

const DEFAULT_DRIVER_DETAILS: Record<DriverDetailsType, DriverDetailsInput> = {
    [DriverDetailsType.Automatic]: {
        type: DriverDetailsType.Automatic,
        aggregation: {
            type: 'Count',
            value: 'DesignItems',
        },
        filterFormula: [],
    },
    [DriverDetailsType.Manual]: {
        type: DriverDetailsType.Manual,
    },
};

export const GeneralSection = ({ onSubmitType }: { onSubmitType: 'POST' | 'PATCH' }) => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const selectedDriverType = useWatch({ control, name: 'driverDetails.type' });

    return (
        <FormSection title={t`General`} width="100%">
            <FormItem label={t`Name`} required>
                <FieldTextControlled
                    required
                    name={'name'}
                    control={control}
                    FieldProps={{
                        placeholder: t`e.g. Number of placed SMT components`,
                    }}
                />
            </FormItem>

            <FormItem label={t`Driver is related to the whole panel (not individual assemblies)`}>
                {onSubmitType === 'PATCH' && <CheckBoxWithBatchActivityAndExpenseCheck control={control} />}
                {onSubmitType === 'POST' && <FieldCheckboxControlled name={'isPerPanel'} control={control} />}
            </FormItem>

            <FormItem label={t`Notes`}>
                <FieldTextControlled control={control} name={'notes'} FieldProps={{ multiline: true, minRows: 4 }} />
            </FormItem>
            <FormItem label={t`Type`} required>
                <RadioGroup>
                    <Flexbox flexDirection="column" gap={8}>
                        {typeSafeObjectKeys(driverTypeEnumPublicTranslations).map((driverType) => (
                            <Flexbox gap={8} alignItems="center" key={driverType}>
                                <FieldRadioControlled
                                    name={'driverDetails'}
                                    control={control}
                                    FieldProps={{
                                        fieldValue: DEFAULT_DRIVER_DETAILS[driverType],
                                        checked: driverType === selectedDriverType,
                                    }}
                                />
                                <Text>{transEnum(driverType, driverTypeEnumPublicTranslations)}</Text>
                            </Flexbox>
                        ))}
                    </Flexbox>
                </RadioGroup>
            </FormItem>
        </FormSection>
    );
};

export const CalculationSection = ({ packages }: { packages: PackageOptions }) => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const aggregationType = useWatch({ control, name: 'driverDetails.aggregation.type' });
    const driverType = useWatch({ control, name: 'driverDetails.type' });

    const aggregationTypeOptions = typeSafeObjectKeys(aggregationTypeFullPublicTranslations).map(
        /* eslint-disable-next-line array-callback-return */
        (aggregationType): AutomaticDriverAggregationDTO => {
            switch (aggregationType) {
                case 'Count':
                    return {
                        type: aggregationType,
                        value: 'DesignItems',
                    };
                case 'Sum':
                    return {
                        type: aggregationType,
                        value: 'MaterialPrice',
                    };
                default:
                    assertUnreachable(aggregationType);
            }
        },
    );

    return (
        <Flexbox flexDirection="column" gap="16px">
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormItem label={t`Function`} required>
                        <FieldSelectControlled
                            name={'driverDetails.aggregation'}
                            required
                            control={control}
                            FieldProps={{
                                autoComplete: false,
                                disableClearable: true,
                                options: aggregationTypeOptions,
                                getOptionLabel: (option) =>
                                    transEnum(option.type, aggregationTypeFullPublicTranslations) ?? '',
                                isOptionEqualToValue: (option, value) => option.type === value.type,
                            }}
                        />
                    </FormItem>
                </Grid>
                <Grid item xs={2}>
                    <FormItem label={t`Object`} required>
                        <AggregationValueFormComponent key={`${aggregationType}-${driverType}`} />
                    </FormItem>
                </Grid>
            </Grid>
            <DriverFilterFormComponent packages={packages} />
        </Flexbox>
    );
};

const AggregationValueFormComponent = (): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const aggregationType = useWatch({ control, name: 'driverDetails.aggregation.type' });

    switch (aggregationType) {
        case 'Count':
            return (
                <FieldSelectControlled
                    name={'driverDetails.aggregation.value'}
                    required
                    control={control}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(countAggregationValuePublicTranslations),
                        getOptionLabel: (option) => transEnum(option, countAggregationValuePublicTranslations) ?? '',
                        isOptionEqualToValue: (option, value) => option === value,
                    }}
                />
            );
        case 'Sum':
            return (
                <FieldSelectControlled
                    name={'driverDetails.aggregation.value'}
                    required
                    control={control}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(sumAggregationvaluePublicTranslations),
                        getOptionLabel: (option) => transEnum(option, sumAggregationvaluePublicTranslations) ?? '',
                        isOptionEqualToValue: (option, value) => option === value,
                    }}
                />
            );
    }
};
