import { Bar } from '@visx/shape';
import { BarStack } from '@visx/shape/lib/types';
import { Datum, SingleHorizontalStackedBarChartProps } from '../types';

export function StackedBar<TKeys extends string, T extends Datum<TKeys>>({
    bar,
    isMissingData = () => false,
}: SingleHorizontalStackedBarChartProps<TKeys, T> & {
    bar: BarStack<T, TKeys>['bars'][number];
    barStack: BarStack<T, TKeys>;
}): JSX.Element {
    const datum = bar.bar.data;
    const showMissingData = isMissingData(datum, bar.key);

    const dx = 8;
    return (
        <g>
            <Bar
                fill={bar.color}
                {...(showMissingData
                    ? {
                          x: bar.x + 4,
                          y: bar.y + dx / 2,
                          rx: 2,
                          width: bar.height - dx,
                          height: bar.height - dx,
                      }
                    : {
                          x: bar.x,
                          y: bar.y,
                          rx: 2,
                          width: bar.width - 4,
                          height: 12,
                      })}
            />
        </g>
    );
}
