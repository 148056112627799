import { uniq, uniqBy } from '@luminovo/commons';
import { CustomOptionTypes, DesignItemResponseDTO, PartSpecification, StandardPartDTO } from '@luminovo/http-client';
import { PartSpecificationTypes } from '../resources/part/PartSpecificationTypes';
import { assertUnreachable } from './typingUtils';

function getStandardPartFromSpecification(spec: PartSpecification | null): Array<StandardPartDTO> {
    if (!spec) {
        return [];
    }
    if (spec.type === PartSpecificationTypes.Custom) {
        return [];
    }
    if (spec.type === PartSpecificationTypes.OffTheShelf) {
        return spec.data.part_options.map((p) => p.part);
    }
    assertUnreachable(spec);
}

/**
 * Aggregates all standard parts from part options and suggestions mentioned in the given design items.
 *
 * The returned parts are guaranteed to be sorted AND unique with respect to their ID.
 */
export function getUniqueStandardParts(designItems: DesignItemResponseDTO[]): Array<StandardPartDTO> {
    const result = [];
    const visitedBomItems = new Set<string>();

    for (const designItem of designItems) {
        if (visitedBomItems.has(designItem.aggregation_key)) continue;
        result.push(...getStandardPartFromSpecification(designItem.part_specification));
        visitedBomItems.add(designItem.aggregation_key);
    }

    return uniqBy(result, (part) => part.data /* the id */).sort();
}

export function getUniqueCustomSpecificationIds(designItems: DesignItemResponseDTO[]): {
    customPartIds: Array<string>;
    customComponentIds: Array<string>;
} {
    const customPartIds: string[] = [];
    const customComponentIds: string[] = [];
    for (const designItem of designItems) {
        const partSpecification = designItem.part_specification;
        if (partSpecification && partSpecification.type === PartSpecificationTypes.Custom && partSpecification.data) {
            partSpecification.data.forEach((d) => {
                const customOptType = d.part.type;
                if (customOptType === CustomOptionTypes.CustomPart) {
                    return customPartIds.push(d.part.data);
                }
                if (customOptType === CustomOptionTypes.CustomComponent) {
                    return customComponentIds.push(d.part.data);
                }
                assertUnreachable(customOptType);
            });
        }
    }

    return { customPartIds: uniq(customPartIds), customComponentIds: uniq(customComponentIds) };
}
