import { isPresent } from '@luminovo/commons';
import { SecondaryButton } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Upload } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { route } from '../../../utils/routes';
import { useQuoteRequest } from '../hooks/negotiationHandlers';

export function ButtonNegotiationQuoteImporter({ quoteRequestId }: { quoteRequestId: number }) {
    const history = useHistory();

    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!isPresent(quoteRequest) || quoteRequest.status === QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Upload />}
            onClick={() => history.push(route('/negotiations/quote-importer/:quoteRequestId', { quoteRequestId }))}
        >
            Import Excel
        </SecondaryButton>
    );
}
