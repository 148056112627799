/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { Currency } from '../currencyBackendTypes';
import { ManufacturerDTORuntype } from '../manufacturer';
import { UserDTORuntype } from '../user';

export enum SupplierOrigin {
    System = 'System',
    Erp = 'Erp',
    Manual = 'Manual',
}

export enum SupplierType {
    Distributor = 'Distributor',
    Manufacturer = 'Manufacturer',
    Ems = 'Ems',
}

export enum SupplierPartType {
    OffTheShelf = 'OffTheShelf',
    Custom = 'Custom',
    Pcb = 'Pcb',
    Assembly = 'Assembly',
}

export interface SupplierDTO extends r.Static<typeof SupplierDTORuntype> {}
export const SupplierDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    alternative_names: r.Array(r.String),
    origin: runtypeFromEnum(SupplierOrigin),
    supplier_type: runtypeFromEnum(SupplierType),
    supplier_number: r.String.optional().nullable(),
    supplier_part_type: runtypeFromEnum(SupplierPartType),
    default_currency: runtypeFromEnum(Currency).nullable(),
});

export interface LineCardModificationDTO extends r.Static<typeof LineCardModificationDTORuntype> {}
export const LineCardModificationDTORuntype = r.Record({
    user: UserDTORuntype.pick('id', 'first_name', 'last_name', 'email').optional(),
    manufacturer: ManufacturerDTORuntype,
    action: r.Union(r.Literal('Add'), r.Literal('Remove')),
    updated_at: r.String,
    created_at: r.String,
});

export interface SupplierLineCardDTO extends r.Static<typeof SupplierLineCardDTORuntype> {}
export const SupplierLineCardDTORuntype = r.Record({
    supplier: r.String,
    manufacturers: r.Array(
        ManufacturerDTORuntype.extend({
            restricted_to_suppliers: r.Array(r.String),
            part_category_restriction: r.Array(
                r.Record({
                    restrict_to: r.Array(r.Number),
                    supplier: r.String,
                }),
            ),
        }),
    ),
    modification_history: r.Array(LineCardModificationDTORuntype),
});

export type SupplierLineCardPatchDTO = r.Static<typeof SupplierLineCardPatchDTORuntype>;
export const SupplierLineCardPatchDTORuntype = r.Union(
    r.Record({
        type: r.Literal('Update'),
        value: r.Record({
            manufacturers: r.Array(r.String),
        }),
    }),
    r.Record({
        type: r.Literal('Reset'),
    }),
);
