import { compareByStringKey } from '@luminovo/commons';
import { useHttpQuery } from '../http/useHttpQuery';

export function useManufacturers() {
    return useHttpQuery(
        'GET /manufacturers',
        {},
        {
            select: (res) => Array.from(res.data).sort(compareByStringKey((x) => x.name)),
            staleTime: Infinity,
        },
    );
}
