import { coerceUndefinedToNull, isPresent, omit } from '@luminovo/commons';
import { Info } from '@mui/icons-material';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';

type ControlledFieldProps<TFieldProps> = Omit<
    TFieldProps,
    'name' | 'inputRef' | 'value' | 'onChange' | 'onBlur' | 'error'
>;

export type BaseFieldControllerProps<
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render' | 'defaultValues' | 'default' | 'defaultValue'>;

export interface FieldControllerProps<TFieldProps, TFieldValues extends FieldValues, TName extends Path<TFieldValues>> {
    name: BaseFieldControllerProps<TFieldValues, TName>['name'];

    control: BaseFieldControllerProps<TFieldValues, TName>['control'];

    FieldProps?: ControlledFieldProps<TFieldProps>;

    Field: React.ComponentType<TFieldProps>;

    validate?: (x: unknown) => string | undefined;

    /**
     * When set, the error message is shown via a tooltip instead of below the input field.
     */
    displayErrorAsTooltip?: boolean;
}

export function FieldController<
    TValue,
    TFieldProps,
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
    Field,
    FieldProps,
    validate,
    displayErrorAsTooltip,
    ...rest
}: FieldControllerProps<TFieldProps, TFieldValues, TName>): JSX.Element {
    return (
        <Controller
            rules={{ validate }}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                const safeFieldProps = Boolean(errorMessage) ? omit(FieldProps, 'helperText') : FieldProps;
                return (
                    <Tooltip
                        variant="white"
                        title={
                            displayErrorAsTooltip && isPresent(errorMessage) ? (
                                <Flexbox gap={4} alignItems={'center'}>
                                    <Info style={{ color: colorSystem.red[6] }} fontSize="inherit" />
                                    <Text variant="body-small">{errorMessage}</Text>
                                </Flexbox>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Field
                            name={field.name}
                            inputRef={field.ref}
                            value={coerceUndefinedToNull(field.value)}
                            onChange={(value: TValue | null) => field.onChange(value)}
                            onBlur={() => field.onBlur()}
                            error={Boolean(errorMessage)}
                            helperText={displayErrorAsTooltip ? undefined : errorMessage}
                            // Safe because `FieldProps` is of type `ControlledFieldProps` which is a subset of `TFieldProps`.
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            {...(safeFieldProps as TFieldProps)}
                        />
                    </Tooltip>
                );
            }}
            {...rest}
        />
    );
}
