import { AdditionalServicesGetResponseDTO, UserDTO } from '@luminovo/http-client';

export const formatAdditionalServiceTranslation = (
    additionalService: AdditionalServicesGetResponseDTO,
    language: UserDTO['user_language'],
): string => {
    switch (language) {
        case 'en':
            return additionalService.name_en;
        case 'de':
            return additionalService.name_de;
        default:
            return additionalService.name_en;
    }
};
