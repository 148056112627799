import { Box, BoxProps } from '@mui/material';
import * as React from 'react';

export interface FlexboxProps extends BoxProps {
    gap?: string | number;
}

export const Flexbox: React.FC<FlexboxProps> = ({ gap, style = {}, ...rest }) => {
    return <Box style={{ display: 'flex', gap, ...style }} {...rest} />;
};
