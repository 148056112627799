import { AssemblyTypePublic } from '@luminovo/http-client';
import { useAssemblyTypeOptions } from '../../AssemblyForm/utils/useAssemblyTypeOptions';

// Use this hook when you have to set an initial value for the assembly type in the assembly form.
export const useDefaultAssemblyType = (): {
    data: AssemblyTypePublic | undefined;
    isLoading: boolean;
} => {
    const { data: options, isLoading } = useAssemblyTypeOptions();

    return {
        data: options ? options[0] : undefined,
        isLoading,
    };
};
