import { id } from '@luminovo/commons';
import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import { AssemblyMonitoring } from '@luminovo/http-client';
import { useDrawerContext } from '../../../../components/contexts/ModalContext';
import { AssemblyMonitoringFormContainer } from './AssemblyMonitoringForm';

export default function useMonitoringDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({
            assemblyMonitoring,
            assemblyId,
        }: {
            assemblyMonitoring: AssemblyMonitoring;
            assemblyId: string;
        }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox
                        width="500px"
                        flexDirection="column"
                        boxSizing="border-box"
                        id={id('design/assembly_monitoring_drawer')}
                    >
                        <AssemblyMonitoringFormContainer
                            assemblyId={assemblyId}
                            onClose={closeDrawer}
                            assemblyMonitoring={assemblyMonitoring}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
