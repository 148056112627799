import { t } from '@lingui/macro';
import { formatCurrency, formatDays, formatDecimal } from '@luminovo/commons';
import {
    Checkbox,
    FieldCheckboxControlled,
    FieldController,
    FieldNumericControlled,
    FieldTextControlled,
    Flexbox,
    Tag,
    TanStackTable,
    Text,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import type { ColumnDefWithSharedContext } from '@luminovo/design-system/src/components/TanStackTable/type';
import { FullPart } from '@luminovo/http-client';
import { FieldPackaging, formatPackaging, formatPart } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LabelPartDescription } from '../../../../components/LabelPartDescription';
import { useConditionalValidation } from '../hooks/useConditionalValidation';
import { isLineItemQuoted } from '../model/utils';
import { QuotedLineItem, StandardPartQuoteFormValues } from '../types';
import { LeadTimeUnitAdornment } from './LeadTimeUnitAdornment';
import { StandardPartThumbnail } from './StandardPartThumbnail';
import { columnOfferedPart } from './columnOfferedPart';

export function QuotedStandardPartsTable({ lineItems, mode }: { lineItems: QuotedLineItem[]; mode: 'view' | 'edit' }) {
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const columns = mode === 'view' ? columnsReadOnly : columnsEditable;

    const { table } = useTanStackTable<QuotedLineItem, unknown>({
        data: lineItems,
        enableExcelExport: true,
        columns,
        sharedContext: {
            control,
        },
        getColumnCanGlobalFilter: () => true,
    });

    return <TanStackTable size="medium" table={table} enableMenuBar={{ exportExcelButton: true }} />;
}

const columnHelper = createColumnHelper<QuotedLineItem, unknown>();

const columnIncluded = columnHelper.enum('included', {
    label: function Label() {
        return t`Included`;
    },
    header: function Header() {
        const { control, setValue, trigger } = useFormContext<StandardPartQuoteFormValues>();

        const lineItems = useWatch({ control, name: 'lineItems' });
        const included = lineItems.map((lineItem) => lineItem.included);

        const allIncluded = included.every((value) => value);
        const indeterminate = included.some((value) => value) && !allIncluded;

        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', width: '100%' }}>
                <Checkbox
                    onChange={(e) => {
                        for (let i = 0; i < lineItems.length; i++) {
                            const newStatus: boolean = indeterminate ? true : !allIncluded;
                            const path: `lineItems.${number}.included` = `lineItems.${i}.included`;
                            // @ts-ignore
                            setValue(path, newStatus);
                        }
                        trigger();
                    }}
                    size="small"
                    checked={allIncluded}
                    indeterminate={indeterminate}
                />
            </Box>
        );
    },
    size: 30,
    enableColumnFilter: false,
    enableSorting: false,
    renderType: 'generic',
    initialPinning: 'left',
    getOptionLabel: (option) => (option ? t`Yes` : t`No`),
    cell: function Cell({ row }) {
        const { control, trigger } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const isIncluded = useWatch({ control, name: `lineItems.${index}.included` });
        React.useEffect(() => {
            if (!isIncluded) {
                trigger(`lineItems.${index}`);
            }
        }, [isIncluded, trigger, index]);

        return (
            <FieldCheckboxControlled
                control={control}
                name={`lineItems.${index}.included`}
                FieldProps={{
                    size: 'small',
                }}
            />
        );
    },
});

const columnRequestedPart = columnHelper.text((row) => formatPart(row.part), {
    id: 'requestedPart',
    label: () => t`Requested part`,
    size: 200,
    enableColumnFilter: false,
    enableSorting: false,
    renderType: 'generic',
    cell: function Cell({ row }) {
        return <PartCell part={row.original.part} />;
    },
});

const columnDescription = columnHelper.enum((row) => row.part, {
    id: 'description',
    label: () => t`Description`,
    size: 300,
    getOptionLabel: (part: FullPart) => formatPart(part),
    enableColumnFilter: false,
    enableSorting: false,
    renderType: 'generic',
    cell: function Cell({ getValue }) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: 1,
                    padding: '12px 0',
                }}
            >
                <LabelPartDescription part={getValue()} />
            </Box>
        );
    },
});

const columnRequiredQuantity = columnHelper.number('requiredQuantity', {
    label: () => t`Required quantity`,
    size: 140,
    cell: ({ getValue }) => {
        return formatDecimal(getValue());
    },
    renderType: 'generic',
});

const columnUnitPrice = columnHelper.number((row) => row.offer?.unitPrice ?? 0, {
    id: 'unitPrice',
    label: () => t`Unit price` + ' *',
    size: 100,
    cell: function Cell({ cell }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const currency = useWatch({ control, name: 'currency' });
        return formatCurrency(cell.getValue(), currency, 'unit-price');
    },
    renderType: 'generic',
});

const columnPricePer = columnHelper.number((row) => row.offer?.pricePer ?? 0, {
    id: 'pricePer',
    label: () => t`Price per` + ' *',
    size: 100,
    cell: function Cell({ cell, row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { required: true, min: 1 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.pricePer`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small' }}
                {...validation}
            />
        );
    },
    renderType: 'generic',
});

const columnMoq = columnHelper.number('offer.moq', {
    label: () => t`MOQ` + ' *',
    size: 100,
    cell: ({ getValue }) => {
        return formatDecimal(getValue(), { ifAbsent: '' });
    },
    renderType: 'generic',
});

const columnMpq = columnHelper.number('offer.mpq', {
    label: () => t`MPQ`,
    size: 100,
    cell: ({ getValue }) => {
        return formatDecimal(getValue(), { ifAbsent: '' });
    },
    renderType: 'generic',
});

const columnStock = columnHelper.number('offer.stock', {
    label: () => t`Stock`,
    size: 100,
    cell: ({ getValue }) => {
        return formatDecimal(getValue(), { ifAbsent: '' });
    },
    renderType: 'generic',
});

const columnPackaging = columnHelper.enum('offer.packaging', {
    label: () => t`Packaging`,
    size: 100,
    getOptionLabel: (option) => formatPackaging(option),
    cell: ({ getValue }) => {
        return formatPackaging(getValue(), { ifUnknown: '' });
    },
    renderType: 'generic',
});

const columnReadOnlyOfferedPart = columnHelper.enum((row) => row.offer?.offeredPart, {
    id: 'readOnlyOfferedPart',
    label: () => t`Offered part` + ' *',
    size: 200,
    getOptionLabel: (option) => formatPart(option),
    cell: function Cell({ getValue }) {
        return <PartCell part={getValue()} hideThumbnail />;
    },
    renderType: 'generic',
});

// TODO: temporarily removed
// const columnOfferNumber = columnHelper.enum('offer.offerNumber', {
//     label: () => t`Offer number`,
//     size: 100,
//     getOptionLabel: (option) => option ?? '-',
//     cell: ({ getValue }) => {
//         return getValue() ?? '';
//     },
//     renderType: 'generic',
// });

// TODO: temporarily removed
// const columnValidUntil = columnHelper.date('offer.validUntil', {
//     label: () => t`Valid until`,
//     size: 120,
//     cell: ({ getValue }) => {
//         const value = getValue();
//         if (!value) {
//             return '';
//         }
//         return formatRelativeTime(value);
//     },
//     renderType: 'generic',
// });

const columnNcnr = columnHelper.enum('offer.ncnr', {
    label: () => t`NCNR`,
    size: 100,
    getOptionLabel: (option) => (option ? t`Yes` : t`No`),
    cell: ({ getValue }) => {
        const ncnr = getValue();
        const ncnrLabel = ncnr ? t`Yes` : t`No`;
        const label = ncnr === undefined ? '' : ncnrLabel;
        return label;
    },
    renderType: 'generic',
});

const columnNotes = columnHelper.text('offer.notes', {
    label: () => t`Notes`,
    size: 200,
    cell: ({ getValue }) => {
        return getValue() ?? '';
    },
    renderType: 'generic',
});

const columnLeadTimeDays = columnHelper.number((row) => row.offer?.leadTime, {
    id: 'leadTimeValue',
    label: () => t`Std. factory lead time`,
    size: 100,
    cell: ({ getValue }) => {
        return formatDays(getValue(), { ifNotPresent: '' });
    },
    renderType: 'generic',
});

const columnQuoteStatus = columnHelper.enum((row): boolean => isLineItemQuoted(row), {
    id: 'status',
    label: () => t`Quoted`,
    size: 100,
    initialPinning: 'right',
    sortDescFirst: true,
    quickFilters: [
        {
            label: () => t`quoted`,
            value: [true],
            showCount: true,
            replaceExistingFilters: true,
        },
        {
            label: () => t`not quoted`,
            value: [false],
            showCount: true,
            replaceExistingFilters: true,
        },
    ],
    getOptionLabel: (option: boolean) => (option ? t`Quoted` : t`Not quoted`),
    cell: function Cell({ getValue }) {
        if (getValue()) {
            return <Tag attention="low" label={t`Quoted`} color="green" />;
        }
        return <Tag attention="low" label={t`Not quoted`} color="yellow" />;
    },
    renderType: 'generic',
});

const columnQuoteReadyStatus = {
    ...columnQuoteStatus,
    cell: function Cell({ getValue }: { getValue: () => boolean }) {
        if (getValue()) {
            return <Tag attention="low" label={t`Ready`} color="green" />;
        }
        return <Tag attention="low" label={t`Not quoted`} color="yellow" />;
    },
};

function withCell<T extends ColumnDefWithSharedContext<QuotedLineItem, any, unknown>>(
    columnDef: T,
    cellFn: T['cell'],
): T {
    return {
        ...columnDef,
        cell: React.memo(function Cell(props) {
            if (typeof columnDef.cell === 'string' || typeof cellFn === 'string') {
                throw new Error('Cannot use string cell');
            }
            if (cellFn === undefined) {
                throw new Error('Cannot use undefined cell');
            }
            return cellFn(props);
        }),
    };
}

const columnsEditable = [
    columnIncluded,
    columnRequestedPart,
    columnDescription,
    columnRequiredQuantity,
    columnOfferedPart,
    withCell(columnUnitPrice, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { required: true, min: 0 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.unitPrice`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small' }}
                {...validation}
            />
        );
    }),
    columnPricePer,

    withCell(columnMoq, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { required: true, min: 1 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.moq`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small' }}
                {...validation}
            />
        );
    }),
    withCell(columnMpq, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { min: 1 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.mpq`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small', placeholder: '1' }}
                {...validation}
            />
        );
    }),
    withCell(columnStock, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { min: 0 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.stock`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small', placeholder: '0' }}
                {...validation}
            />
        );
    }),
    withCell(columnLeadTimeDays, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { min: 0 });
        return (
            <FieldNumericControlled
                control={control}
                name={`lineItems.${index}.offer.leadTime`}
                displayErrorAsTooltip
                isInteger
                FieldProps={{
                    size: 'small',
                    placeholder: '0',
                    InputProps: {
                        endAdornment: <LeadTimeUnitAdornment />,
                    },
                }}
                {...validation}
            />
        );
    }),
    withCell(columnPackaging, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        return (
            <FieldController
                control={control}
                name={`lineItems.${index}.offer.packaging`}
                Field={FieldPackaging}
                FieldProps={{
                    size: 'small',
                }}
            />
        );
    }),
    withCell(columnNcnr, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        return (
            <FieldCheckboxControlled
                control={control}
                name={`lineItems.${index}.offer.ncnr`}
                FieldProps={{
                    size: 'small',
                }}
            />
        );
    }),
    withCell(columnNotes, function Cell({ row }) {
        const { control } = useFormContext<StandardPartQuoteFormValues>();
        const index = row.index;
        const validation = useConditionalValidation(index, { max: 2000 });
        return (
            <FieldTextControlled
                control={control}
                name={`lineItems.${index}.offer.notes`}
                displayErrorAsTooltip
                FieldProps={{ size: 'small', fullWidth: true }}
                {...validation}
            />
        );
    }),
    columnQuoteReadyStatus,
];

const columnsReadOnly = [
    columnRequestedPart,
    columnDescription,
    columnReadOnlyOfferedPart,
    columnRequiredQuantity,
    columnUnitPrice,
    columnMoq,
    columnMpq,
    columnStock,
    columnLeadTimeDays,
    columnPackaging,
    columnNcnr,
    columnNotes,
    columnQuoteStatus,
];

function PartCell({ part, hideThumbnail }: { part?: FullPart; hideThumbnail?: boolean }) {
    return (
        <Flexbox
            sx={{
                margin: '-12px',
                padding: '12px',
            }}
            alignItems={'center'}
            gap="8px"
        >
            {!hideThumbnail && <StandardPartThumbnail size={32} parts={part ? [part] : []} />}
            <Text variant="body-small" showEllipsis>
                {formatPart(part)}
            </Text>
        </Flexbox>
    );
}
