import { t, Trans } from '@lingui/macro';
import { Checkbox, Chip, DialogTitle, Flexbox, PrimaryButton, Text } from '@luminovo/design-system';
import { Dialog } from '@mui/material';
import { useDialogContext } from '../../../components/contexts/ModalContext';

export function useMultiLevelBetaInfoDialog({ handleImportBom }: { handleImportBom: () => void }) {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ setDoNotShowAgain }: { setDoNotShowAgain: (newState: 'true' | 'false') => void }) => {
            setDialog(
                <MultiLevelBetaInfoDialog
                    setDoNotShowAgain={setDoNotShowAgain}
                    handleImportBom={handleImportBom}
                    closeDialog={closeDialog}
                />,
            );
        },
    };
}

const MultiLevelBetaInfoDialog = ({
    setDoNotShowAgain,
    handleImportBom,
    closeDialog,
}: {
    setDoNotShowAgain: (newState: 'true' | 'false') => void;
    handleImportBom: () => void;
    closeDialog: () => void;
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDoNotShowAgain(event.target.checked ? 'true' : 'false');
    };
    return (
        <Dialog open={true}>
            <DialogTitle title={t`Multi-level BOM feature is still in BETA`} />
            <Flexbox padding="16px 24px" flexDirection="column" gap="12px">
                <Text>
                    <Trans>
                        You added the <Chip color="primary" label={t`Level`} style={{ marginBottom: '2px' }} /> tag to
                        import a multi-level BOM. Please note that this feature is very new and still has some
                        restrictions:
                        <br />
                        <br />
                        1. The BOM must not be longer than 3000 lines <br />
                        2. The levels must be in the format <Text variant="code">1</Text>, <Text variant="code">2</Text>
                        , <Text variant="code">3</Text> etc. The highest level is expected to come first. <br />
                    </Trans>
                </Text>
                <Text>
                    <Trans>
                        Importing may fail for BOMs that do not meet these restrictions. We continue to develop this
                        feature to work with more and larger BOMs.
                    </Trans>
                </Text>
                <Flexbox justifyContent="flex-end" gap={8}>
                    <Flexbox alignItems="center" gap={8}>
                        <Checkbox size="small" onChange={handleChange} />
                        <Text>
                            <Trans>Do not show again</Trans>
                        </Text>
                    </Flexbox>
                    <PrimaryButton
                        onClick={() => {
                            handleImportBom();
                            closeDialog();
                        }}
                        size="medium"
                    >
                        <Trans>OK</Trans>
                    </PrimaryButton>
                </Flexbox>
            </Flexbox>
        </Dialog>
    );
};
