import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { ResourceDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { ResourceForm } from './ResourceForm';
import { createResourceSchema } from './utils/resourceValidationSchema';

export const AddResourceForm = (): JSX.Element => {
    const { data: resources, isLoading } = useHttpQuery('GET /resources', {});
    const existingResourceNames: string[] = resources?.data.map((resource: ResourceDTO) => resource.name) ?? [];
    const { preferredCurrency, isLoading: isCurrencyLoading } = useGlobalCurrency();

    if (isLoading || isCurrencyLoading) {
        return <CircularProgress />;
    }
    return (
        <ResourceForm
            onSubmitType="POST"
            schema={createResourceSchema(existingResourceNames)}
            defaultValues={{
                costPerHourCurrency: Currency[preferredCurrency],
            }}
            formTitle={capitalizeFirstLetter(t`Add resource`)}
        />
    );
};
