import { Trans } from '@lingui/macro';
import { Text } from '@luminovo/design-system';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { UpdateUserForm, UpdateUserFormState } from './UpdateUserForm';

export function DialogEditUser({
    open,
    onClose,
    initialValues,
    onSubmit,
}: {
    open: boolean;
    onClose: () => void;
    initialValues: UpdateUserFormState;
    onSubmit: (form: UpdateUserFormState) => Promise<void>;
}) {
    return (
        <Dialog keepMounted={false} open={open} onClose={onClose} maxWidth={'md'}>
            <DialogTitle>
                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Text variant="h2">
                            <Trans>Edit user</Trans>
                        </Text>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ paddingBottom: 24 }}>
                <UpdateUserForm
                    initialValues={initialValues}
                    onClose={() => onClose()}
                    onSubmit={async (form) => {
                        await onSubmit(form);
                        onClose();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}
