import { t } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { GetApp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import fileDownload from 'js-file-download';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';

interface ExportManufacturingButtonProps {
    manufacturingAssemblyDetailsId: string;
    text?: string;
}

export const ExportManufacturingButton = ({ manufacturingAssemblyDetailsId, text }: ExportManufacturingButtonProps) => {
    const { mutateAsync, isLoading } = useHttpMutation('GET /export/manufacturing-xlsx', {
        snackbarMessage: null,
        onSuccess: (response) => {
            fileDownload(response.blob, response.fileName);
        },
    });
    const buttonText = text ?? t`Export`;

    return (
        <SecondaryButton
            type="button"
            variant="text"
            isLoading={isLoading}
            startIcon={<GetApp />}
            onClick={() =>
                mutateAsync({ queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId } })
            }
            size="medium"
            style={{ minWidth: '104px' }}
        >
            {isLoading ? <CircularProgress color="secondary" size={16} /> : buttonText}
        </SecondaryButton>
    );
};
