import { TableCellProps, TableProps } from '@mui/material';
import React from 'react';

/**
 * @typedef Without
 *
 * Takes two record types `T` and `U`, and outputs a new type where the keys
 * are `keyof T - keyof U` and the values are `undefined | never`.
 *
 * Meant to be used as one operand of a product type to produce an XOR type.
 */

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
/**
 * @typedef XOR
 *
 * Takes two record types `T` and `U`, and produces a new type that allows only
 * the keys of T without U or the keys of U without T.
 */
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type Column<TRowData, TContext = undefined> = {
    /**
     * A unique identifier for the column
     * e.g. 'price'
     *
     * Note: this ID is used for persistence, so if you change the ID,
     * you will break persistence.
     */
    id: string;

    render: (data: Row<TRowData>, sharedContext: TContext) => JSX.Element;

    /**
     * A label that describes the column.
     */
    label: JSX.Element | string | ((data: Row<TRowData>) => JSX.Element);

    /**
     * Use when you need to supply a custom UI for the column's head cell.
     *
     * It's important to have a <TableCell /> component be the parent element
     */
    renderHead?: (props: RenderHeadProps<TContext>) => JSX.Element;

    /**
     * Use to add a custom UI for the column's expandable cel.
     *
     * If for a given condition all columns return null, then the row won't be expandable.
     *
     * It's important to have a <TableCell /> component be the parent element
     */
    renderExpanded?: (data: Row<TRowData>, sharedContext: TContext) => JSX.Element | null;

    width?: string | number;

    overrides?: {
        HeaderTableCell: React.ComponentType<TableCellProps>;
    };

    /**
     * When enabled, all interactions with the column header are disabled and hidden.
     */
    disableClickable?: boolean;

    filters?: Filter<TRowData, TContext>[];
    /**
     * This can be set false when we want to hide the clear filters item in the filter menu
     */
    renderClearFiltersItem?: boolean;
    /**
     * Makes the column searchable by adding a MenuItem to the column
     * with an input box.
     *
     * The `searchBy` function determines the text that will be searchable, so try
     * to keep this in sync with the `render` function.
     */
    searchable?: {
        searchBy: (data: TRowData, sharedContext: TContext) => string;
        placeholder?: string;
    };
    /**
     * The comparators available for this column. Comparators determine
     * the possible ways a column can be sorted.
     */
    comparators?: Comparator<TRowData, TContext>[];
};

export interface Row<TRowData> {
    data: TRowData;
    tableState: PersistentTableState;
    index: number;
    dispatch: React.Dispatch<Action>;
    rowId?: string;
}

export interface RenderHeadProps<TContext, TItem = unknown> {
    state: Pick<PersistentTableState, 'selectedIds'>;
    items: TItem[];
    filteredItems: TItem[];
    sharedContext: TContext;
    dispatch: React.Dispatch<Action>;
    idExtractor: IdExtractor<TItem> | undefined;
}

export interface PersistentTableState {
    selectedComparatorIds: string[];
    selectedFilterIds: string[];
    page: number;
    memorizedPage: number | null;
    queries?: {
        [columnId: string]: string;
    };
    rowsPerPage: number;
    selectedIds: string[];
}

export interface DefaultTableOptions {
    providedRowsPerPage?: number;
    persistPagination?: boolean;
    defaultSelectedIds?: string[];
}

export interface PredicateFilter<TRowData, TContext = undefined> {
    id: string;
    label: string | JSX.Element;
    predicate: (item: TRowData, context: TContext) => boolean;
}

export function isPredicateFilter<TRowData, TContext>(
    filter: Filter<TRowData, TContext>,
): filter is PredicateFilter<TRowData, TContext> {
    return 'predicate' in filter;
}

interface RenderFilter<TContext = undefined> {
    id: string;
    renderFilter: (context: TContext) => JSX.Element;
}

export type Filter<TRowData, TContext = undefined> = PredicateFilter<TRowData, TContext> | RenderFilter<TContext>;

type ComparableBy<TRowData> = (tableItem: TRowData) => string | number;

type ComparableByWithContext<TRowData, TContext> = (tableItem: TRowData, context: TContext) => string | number;

export enum ComparableResult {
    less = -1,
    equal = 0,
    greater = 1,
}

/**
 * Compares two items.
 *
 * Returning `less` indicates that `leftItem` is less than `rightItem`. Same for `equal` and `greater`.
 */
export type ComparableWithContext<TRowData, TContext> = (
    leftItem: TRowData,
    rightItem: TRowData,
    context: TContext,
) => ComparableResult;

export type Comparable<TRowData> = (leftItem: TRowData, rightItem: TRowData) => ComparableResult;

type ComparatorFunction<TRowData, TContext> =
    | { compare: TContext extends undefined ? Comparable<TRowData> : ComparableWithContext<TRowData, TContext> }
    | { compareBy: TContext extends undefined ? ComparableBy<TRowData> : ComparableByWithContext<TRowData, TContext> };

export type Comparator<TRowData, TContext = undefined> = {
    id: string;
    order: 'asc' | 'desc';
    /**
     * A label that describes the comparator e.g. 'Sort by name asc.'
     */
    label: string | JSX.Element;
} & ComparatorFunction<TRowData, TContext>;

/**
 * In order for search to work properly, every item in the table must
 * have an identifier.
 *
 * The `idExtractor` should return the id of `item`.
 *
 * Example:
 *
 * ```
 * (user: User) => user.id
 * ```
 */
export type IdExtractor<TRowData> = (item: TRowData) => string;

export interface SearchOptions<TRowData, TContext = undefined> {
    idExtractor: IdExtractor<TRowData>;
    /**
     * There's two ways of indexing the `items` for search
     *
     * 1. `rendered-content`:
     * Indexing is done by rendering the table offscreen and using `Element#textContent`.
     * This can be very slow for tables with many items, but search will have great UX.
     *
     * See `indexTableRowElement`.
     *
     * 2. `data-content`:
     * Indexing is done by traversing the `items` datastructure. The quality of search
     * will depend on how the data is structured. If, for example, i18n is not done
     * on the `items`, they will not be searchable by their translated text.
     *
     * See `indexItemData`.
     *
     * |          | rendered-content | data-content       |
     * |----------|------------------|--------------------|
     * | Pros     | easy             | fast               |
     * | Cons     | slow             | requires more work |
     */
    contentSearchOptions: DataContentSearchOptions<TRowData, TContext> | RenderedContentSearchOptions;
}

/**
 * The state of the DataTable as produced by the `useDataTableState` hook.
 */
export type TableState<TRowData, TContext = undefined> = {
    items: TRowData[];
    filteredItems: TRowData[];
    sharedContext: TContext;
    state: PersistentTableState;
    onIndexSearchText: IndexingFunction<TRowData>;
    dispatch: React.Dispatch<Action>;
    searchOptions: SearchOptions<TRowData, TContext> | undefined;
    query: string;
    columns: Column<TRowData, TContext>[];
    selectionOptions: SelectionOptions<TRowData> | undefined;
    paginationOptions: PaginationOptions;
};

export interface SelectionOptions<TRowData> {
    idExtractor: IdExtractor<TRowData>;
    defaultSelectedIds?: string[];
    persistentSelection?: boolean;
}

export interface DataContentSearchOptions<TRowData, TContext> {
    indexingStrategy: 'data-content';
    indexItemData: (item: TRowData, sharedContext: TContext) => string[];
}

export interface RenderedContentSearchOptions {
    indexingStrategy: 'rendered-content';
}

export type Action =
    | {
          type: 'toggle-comparator';
          comparatorId: string;
      }
    | {
          type: 'toggle-filter';
          filterId: string;
      }
    | {
          type: 'clear-comparators';
      }
    | {
          type: 'clear-filters';
      }
    | {
          type: 'set-page';
          page: number;
          memorize: boolean;
      }
    | {
          type: 'restore-memorized-page';
      }
    | {
          type: 'set-rows';
          rows: number;
      }
    | {
          type: 'set-query';
          columnId: string;
          query: string;
      }
    | {
          type: 'select-items';
          ids: string[];
          selected: boolean;
      }
    | {
          type: 'toggle-selected-item';
          id: string;
      }
    | { type: 'clear-selected-items' }
    | {
          type: 'reset-state';
          state: PersistentTableState;
      };

/**
 * Associates the `item` with the strings in `content`
 *
 * Example:
 *
 * If `item` is a user, then `content = ['bob', 'bob@mail.com']` will make that item searchable
 * by the text `bob` or `bob@email.com`
 */
export type IndexingFunction<TRowData> = (item: TRowData, content: string[]) => void;

export interface PaginationOptions {
    showPagination: boolean;
    rowsPerPageOptions?: number[];
    defaultRowsPerPage?: number;
    /**
     * When set to true pagination will continue from the saved page in storage.
     * If initial page is also provided then initial page will take precedence.
     */
    persistPagination?: boolean;
    /**
     * When set to true the table will automatically scroll to the beginning of the new page.
     */
    scrollResetAfterChange?: boolean;
    /**
     * When enabled, the table will remember the current page in case a search query is entered,
     * and will restore the page when the search query becomes an empty string again.
     */
    memorizeEmptyQueryPage?: boolean;
}

export interface TableOverrides<TRowData, TContext> {
    Container?: React.ComponentType;
    /**
     * Override the component that is shown when the table is empty
     */
    NoResultsComponent?: React.ComponentType<NoResultsComponentProps>;
    Table?: React.ComponentType<TableProps>;
    TableFooter?: JSX.Element;
    TableContainer?: React.ComponentType;
    TableRow?: React.ComponentType<DataTableRowProps<TRowData, TContext>>;
    TablePagination?: JSX.Element;
}

export interface DataTableProps<TRowData, TContext = undefined> {
    tableState: TableState<TRowData, TContext>;
    /**
     * A function invoked whenever a row is clicked.
     */
    onItemClick?: (item: TRowData, sharedContext: TContext) => void;
    overrides?: TableOverrides<TRowData, TContext>;
    stickyHeader?: boolean;
    /**
     * When set the height and font size of the table will follow the Figma design system.
     */
    size: 'small' | 'medium' | 'large';
}

export interface NoResultsComponentProps {
    query: string;
    state: PersistentTableState;
    dispatch: React.Dispatch<Action>;
}

export interface DataTableRowProps<TRowData, TContext = undefined> {
    item: TRowData;
    index: number;
    rowId?: string;
    columns: Column<TRowData, TContext>[];
    sharedContext: TContext;
    onItemClick?: (item: TRowData, sharedContext: TContext) => void;
    onSearchText?: IndexingFunction<TRowData>;
    display?: 'visible' | 'none';
    tableState: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    style?: React.CSSProperties;
    tooltip?: string;
}
