import { t } from '@lingui/macro';
import { Message, usePersistedVisibility } from '@luminovo/design-system';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';

export const StackUpMessageBox = ({ title, id, text }: { title: string; id: string; text: string }) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);
    const { isVisible, hide } = usePersistedVisibility({
        id,
        maxDisplayCount: 1,
    });
    const [isTempVisible, setIsTempVisible] = React.useState<boolean>(isVisible);

    const handleClose = () => {
        setIsConfirmationDialogOpen(false);
        enqueueSnackbar(t`Message would not be shown again`, { variant: 'info' });
        hide();
    };

    if (isVisible === false || isTempVisible === false) {
        return <></>;
    }

    return (
        <>
            <Message
                size="large"
                title={title}
                message={text}
                variant={'blue'}
                attention={'low'}
                onClose={() => setIsTempVisible(false)}
                action={{
                    label: t`Don't show again`,
                    onClick: () => setIsConfirmationDialogOpen(true),
                }}
            />

            <ConfirmationDialogBox
                isDialogOpen={isConfirmationDialogOpen}
                text={t`The default values message in this section will be permanently hidden. This action cannot be undone. Are you sure you want to proceed and hide them?`}
                title={t`Don't show this message again?`}
                deleteText={t`Yes, don't show again`}
                onConfirm={handleClose}
                onReject={() => setIsConfirmationDialogOpen(false)}
            />
        </>
    );
};
