import { useState } from 'react';
import { useHttpQuery } from '../http/useHttpQuery';

export const usePartVotes = ({ partId, shouldFetchVotes }: { partId: string; shouldFetchVotes: boolean }) => {
    const [fetchPartVotes, setFetchPartVotes] = useState<boolean>(false);
    const { data: votes, isLoading } = useHttpQuery(
        'GET /parts/off-the-shelf/:partId/votes',
        {
            pathParams: { partId },
        },
        { enabled: fetchPartVotes, select: (response) => response.items, staleTime: 60_000 },
    );

    const triggerFetchVotes = () => {
        if (shouldFetchVotes) setFetchPartVotes(true);
    };

    return { votes, isLoading, triggerFetchVotes };
};
