import { Toolbar } from '@luminovo/design-system';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
    useManufacturingBreadcrumbsForAssemblyPage,
    useManufacturingBreadcrumbsForManufacturingScenarioPage,
} from './manufacturingBreadcrumbsFunctions';

export const ManufacturingBreadcrumbForAssemblyPage = (): JSX.Element => {
    // TODO Please avoid using useParams
    const { rfqId, manufacturingAssemblyDetailsId } = useParams<{
        rfqId: string;
        manufacturingAssemblyDetailsId: string;
    }>();
    const { path } = useManufacturingBreadcrumbsForAssemblyPage(rfqId, manufacturingAssemblyDetailsId);

    return <Toolbar breadcrumbs={path} />;
};

export const ManufacturingBreadcrumbForManufacturingScenarioPage = (): JSX.Element => {
    // TODO Please avoid using useParams
    const { rfqId, manufacturingAssemblyDetailsId, manufacturingScenarioId } = useParams<{
        rfqId: string;
        manufacturingAssemblyDetailsId: string;
        manufacturingScenarioId: string;
    }>();
    const { path } = useManufacturingBreadcrumbsForManufacturingScenarioPage(
        rfqId,
        manufacturingAssemblyDetailsId,
        manufacturingScenarioId,
    );

    return <Toolbar breadcrumbs={path} />;
};
