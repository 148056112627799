import { colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import { InfoOutlined } from '@mui/icons-material';

/**
 * Displays a text with an information icon and tooltip.
 */
export const InfoLabel = ({
    label,
    info,
    textProps,
}: {
    label: string;
    info: string;
    textProps?: React.ComponentProps<typeof Text>;
}): JSX.Element => {
    return (
        <Flexbox gap={4} alignItems="center">
            <Text {...textProps}>{label}</Text>
            <Tooltip title={info}>
                <InfoOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />
            </Tooltip>
        </Flexbox>
    );
};
