import { useIsCustomer } from '../../components/contexts/CurrentUserDetailsContext';
import { Protected } from '../../permissions/Protected';
import { CustomerRfqEditPage } from './CustomerRfqEditPage';
import { EmsRfqEditPage } from './EmsRfqEditPage';

export function RfqEditPage({ rfqId }: { rfqId: string }) {
    const isCustomer = useIsCustomer();

    return (
        <Protected requiredPermissions={() => !isCustomer} fallback={<CustomerRfqEditPage rfqId={rfqId} />}>
            <EmsRfqEditPage rfqId={rfqId} />
        </Protected>
    );
}
