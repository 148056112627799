import { Trans } from '@lingui/macro';
import { Flexbox, RightBoxDrawer, TertiaryButton, Text } from '@luminovo/design-system';
import { RfqDTO } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import { CloseDrawerButton, useDrawerContext } from '../../components/contexts/ModalContext';
import { AddDemandScenarioFormInner } from './DemandScenarioForm';

const useAddDemandScenariosDrawer = (rfq: RfqDTO) => {
    const { setDrawer, closeDrawer, isOpen } = useDrawerContext();

    return {
        isOpen,
        openDrawer: () => {
            return setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox alignItems="center" justifyContent="space-between" paddingRight={'8px'} marginTop={'12px'}>
                        <CloseDrawerButton />
                    </Flexbox>
                    <Flexbox flexDirection={'column'} paddingBottom={'200px'} minWidth={'540px'} padding={'16px'}>
                        <Flexbox alignItems={'center'} gap={'8px'} marginBottom={'16px'} marginTop={'-8px'}>
                            <Text variant="h2">
                                <Trans>Add demand scenario</Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox gap={'16px'} flexDirection={'row'}>
                            <AddDemandScenarioFormInner rfq={rfq} onSuccess={closeDrawer} />
                        </Flexbox>
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
};

export const AddDemandScenarioButton = ({ rfq }: { rfq: RfqDTO }): JSX.Element => {
    const { openDrawer } = useAddDemandScenariosDrawer(rfq);
    return (
        <TertiaryButton startIcon={<Add fontSize="inherit" />} size="small" onClick={() => openDrawer()}>
            <Trans>Add demand scenario</Trans>
        </TertiaryButton>
    );
};
