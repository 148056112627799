import React from 'react';
import { useDescendants } from '../../../../resources/assembly/assemblyHandler';
import { ViewContext } from '../ModuleTableData';
import AssemblySidebar from './AssemblySidebar';

export const AssemblyDetails = ({
    rfqId,
    assemblyId,
    isSideBarOpen,
    setIsSideBarOpen,
}: {
    rfqId: string;
    assemblyId: string;
    isSideBarOpen: boolean;
    setIsSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { data: descendants } = useDescendants(assemblyId);
    const assembly = descendants?.data.parent;

    const toggleIsSidebarOpen = React.useCallback(() => {
        setIsSideBarOpen((current) => !current);
    }, [setIsSideBarOpen]);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    if (!assembly) {
        return null;
    }

    return (
        <AssemblySidebar
            assembly={assembly}
            isSideBarOpen={isSideBarOpen}
            toggleIsSidebarOpen={toggleIsSidebarOpen}
            viewContext={viewContext}
        />
    );
};
