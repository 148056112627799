import * as r from 'runtypes';
import { DriverIdParameterRuntype } from '../driver';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import { ActivitiesDTORuntype, ActivityDTORuntype, ActivityPostDTORuntype } from './activityBackendTypes';

export const activitiesEndpoints = {
    'POST /activities': endpoint({
        description: 'Creates an activity',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ActivityPostDTORuntype,
        responseBody: r.Record({
            data: ActivityPostDTORuntype,
        }),
    }),

    'GET /activities': endpoint({
        description: 'Returns an array of activities',
        pathParams: r.Undefined,
        queryParams: r.Record({
            active: r.Boolean.optional(),
            resource_id: r.String.optional(),
            driver_id: DriverIdParameterRuntype.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ActivitiesDTORuntype,
        }),
    }),
    'GET /activities/:activityId': endpoint({
        description: 'Returns a single activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ActivityDTORuntype,
        }),
    }),
    'PATCH /activities/:activityId': endpoint({
        description: 'Updates an activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: ActivityPostDTORuntype.Or(r.Record({ status: ManufacturingEntityStatusRuntype })),
        responseBody: r.Record({
            data: ActivityPostDTORuntype,
        }),
    }),

    'DELETE /activities/:activityId': endpoint({
        description: 'Deletes an activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
};
