import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { SecondaryButton } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { getImagePreviewStatus } from '../../../../../resources/pcb/pcbFunctions';
import { route } from '../../../../../utils/routes';
import { usePaddedViewBox } from '../../../utils/usePaddedViewBox';
import { SvgImageRenderer } from '../SvgImageRenderer';
import { PreviewLoading, PreviewLoadingError } from '../components/PreviewPlaceholders';
import { getSvgComponent, getSvgDimension } from './utils/parseSvgString';

const StackUpImageRenderer = ({ svgString }: { svgString: string }) => {
    const { SvgComponent } = getSvgComponent(svgString);
    const componentDimensions = getSvgDimension(svgString);

    const { outerViewBox, innerViewBox } = usePaddedViewBox(componentDimensions);

    return (
        <SvgImageRenderer
            svgProps={{
                style: {
                    boxSizing: 'border-box',
                    width: '100%',
                    minWidth: '570px',
                    height: '100%',
                },
            }}
            viewBox={outerViewBox}
        >
            <SvgComponent viewBox={innerViewBox} />
        </SvgImageRenderer>
    );
};

export const StackUpPreviewer: React.FunctionComponent<{
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
}> = React.memo(function StackUpPreviewer({ pcb, assemblyId, rfqId }) {
    const status = getImagePreviewStatus(pcb);
    const [prevStatus, setPrevStatus] = React.useState(status);

    const { data, isLoading, isError, refetch } = useHttpQuery(
        // eslint-disable-next-line spellcheck/spell-checker
        'GET /ems/renderer/layerstacks/versions/:pcbId',
        { pathParams: { pcbId: pcb.id } },
        {
            enabled: status === 'done' || status === 'done-intermediate',
            keepPreviousData: true,
            select: (data) => {
                if (typeof data !== 'string') {
                    return undefined;
                }

                return data;
            },
        },
    );

    // If the status reaches 'done' or 'done-intermediate' and it wasn't there before, refetch the data
    // since there will be a new preview available
    if ((status === 'done' || status === 'done-intermediate') && status !== prevStatus) {
        refetch();
        setPrevStatus(status);
    }

    if (status === 'loading' || isLoading) {
        return <PreviewLoading />;
    }

    if (status === 'without-files') {
        return (
            <PreviewLoadingError
                title={t`Unfortunately, we can't render previews without files.`}
                description={t`If you upload PCB production files we can generate a realistic board preview for you...`}
            />
        );
    }

    if (status === 'failed' || isError) {
        return (
            <PreviewLoadingError
                title={t`Unfortunately, we couldn't render your PCB...`}
                description={t`This could happened for technical reasons, however, you can still fill in the information on the right and get a quote!`}
            />
        );
    }

    if (!isPresent(data)) {
        return (
            <PreviewLoadingError
                title={t`We couldn't match a Stackup for your project.`}
                description={t`We are not able to render a PCB preview due to a mismatch in the copper layers. To resolve it, please use the File Manager.`}
            >
                <SecondaryButton
                    href={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', { assemblyId, rfqId })}
                    size="small"
                >
                    {/* eslint-disable-next-line spellcheck/spell-checker */}
                    {t`Go to File Manager`}
                </SecondaryButton>
            </PreviewLoadingError>
        );
    }

    return <StackUpImageRenderer svgString={data} />;
});
