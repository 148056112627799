import { PartOptionDTO, PartSpecification } from '@luminovo/http-client';
import { assertUnreachable } from '../../utils/typingUtils';
import { PartSpecificationTypes } from './PartSpecificationTypes';

/**
 * @returns all part options in a part specification.
 */
export function getPartOptions(spec: PartSpecification | null): Array<PartOptionDTO> {
    if (!spec) {
        return [];
    }
    if (spec.type === PartSpecificationTypes.Custom) {
        return [];
    }
    if (spec.type === PartSpecificationTypes.OffTheShelf) {
        return spec.data.part_options.map((p) => p);
    }
    assertUnreachable(spec);
}
