import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@luminovo/design-system';
import { SiteDTO } from '@luminovo/http-client';

export function InventorySiteChip({
    isApproved,
    isPreferred,
    site,
    displaySiteName,
}: {
    isApproved: boolean;
    isPreferred: boolean;
    site: Pick<SiteDTO, 'name'>;
    displaySiteName: boolean;
}): JSX.Element {
    const message = () => {
        if (isPreferred) {
            return t`Preferred inventory site`;
        }
        if (isApproved) {
            return t`Approved inventory site`;
        }
        return t`Inventory site not approved`;
    };
    const color = isPreferred || isApproved ? 'blue' : 'red';

    return (
        <Tooltip
            title={
                <>
                    {site.name}
                    <br />
                    {message()}
                </>
            }
        >
            <span>
                <Chip color={color} label={displaySiteName ? site.name : t`Inventory`} style={{ maxWidth: 150 }} />
            </span>
        </Tooltip>
    );
}
