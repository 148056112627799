import { isEqual, isPresent, isProductionEnvironment, throwErrorUnlessProduction } from '@luminovo/commons';
import * as React from 'react';

type TanStackPersistedOptions<T> = {
    /**
     * The storage in which the state will be stored.
     */
    storage?: Storage | URLStorage;
    /**
     * Whether the state should be persisted in the storage.
     */
    enabled?: boolean;

    /**
     * Validation function that checks if the value has the correct type.
     */
    validation?: (value: unknown) => boolean;

    /**
     * Callback function that is called when the state changes.
     */
    callback?: (value: T) => void;
};

/**
 * Similar to React.useState but stores its state in a `storage` (e.g., sessionStorage, localStorage, or URLStorage).
 * Ensures only fully serializable objects are stored.
 */
export function useTanStackPersistedState<T>(
    storageKey: string,
    initialValue: T,
    options: TanStackPersistedOptions<T> = { storage: sessionStorage, enabled: true, validation: () => true },
): [T, (newState: T | ((value: T) => T)) => void] {
    const { storage = sessionStorage, enabled = true, validation = () => true, callback } = options;

    const [state, setState] = React.useState(() => {
        const item = storage.getItem(storageKey);
        if (!enabled || !isPresent(item)) {
            return initialValue;
        }

        try {
            const parsedItem = JSON.parse(item);
            if (!validation(parsedItem)) {
                throwErrorUnlessProduction(
                    new Error(`Attempted to parse invalid value from storage under key "${storageKey}".`),
                    {
                        extra: parsedItem,
                    },
                );
                return initialValue;
            }
            return parsedItem as T;
        } catch (error) {
            throwErrorUnlessProduction(new Error(`Failed to parse value from storage under key "${storageKey}".`));
            return initialValue;
        }
    });

    const handler = React.useCallback(
        (input) => {
            setState((oldState) => {
                const value = typeof input === 'function' ? input(oldState) : input;

                if (enabled) {
                    if (!isProductionEnvironment() && !isSerializable(value)) {
                        throwErrorUnlessProduction(
                            new Error(`Attempted to store non-serializable value under key "${storageKey}".`),
                            { extra: value },
                        );
                    }

                    if (value === undefined) {
                        storage.removeItem(storageKey);
                    } else {
                        storage.setItem(storageKey, JSON.stringify(value));
                    }
                }

                if (isPresent(callback)) {
                    callback(value);
                }

                return value;
            });
        },
        [storage, storageKey, enabled, setState, callback],
    );

    return [state, handler];
}

function isSerializable(value: unknown): boolean {
    try {
        return isEqual(JSON.parse(JSON.stringify(value)), value);
    } catch (error) {
        return false;
    }
}

export class URLStorage {
    public setItem(key: string, value: string): void {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    }

    public getItem(key: string): string | null {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(key);
    }

    public removeItem(key: string): void {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(key);
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    }

    public clear(): void {
        window.history.replaceState({}, '', window.location.pathname);
    }
}
