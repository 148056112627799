import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { MonetaryValueBackend } from '@luminovo/http-client';

type CostType = 'fixed' | 'percentage' | 'formula';

export type FormulaType = Exclude<CostType, 'formula'>;

export const costTypePublicTranslations: Record<CostType, MessageDescriptor> = {
    fixed: defineMessage({ message: 'Fixed value per unit' }),
    formula: defineMessage({ message: 'Formula' }),
    percentage: defineMessage({ message: 'Percentage' }),
};

export const formulaTypePublicTranslations: Record<FormulaType, MessageDescriptor> = {
    fixed: defineMessage({ message: 'Fixed value per unit' }),
    percentage: defineMessage({ message: 'Percentage' }),
};

type CostTypePercentage = {
    type: 'percentage';
    value: {
        percentage: string;
    };
};

type CostTypeFixed = {
    type: 'fixed';
    value: {
        //Currency is not required because it is defined in the type CalculationTemplateFormInput
        cost: Omit<MonetaryValueBackend, 'currency'>;
    };
};

type FormulaFixed = {
    type: 'fixed';
    value: string;
};

type FormulaPercentage = {
    type: 'percentage';
    value: string;
};

export type Formula = FormulaFixed | FormulaPercentage;

export type CostTypeFormula = {
    type: 'formula';
    value: Formula;
};

export type UnitCost = CostTypeFixed | CostTypePercentage | CostTypeFormula;

export type ExtraCost = {
    name: string;
    unitCost: UnitCost;
    isLocked: boolean;
};

export type CostCell = {
    value: {
        unitCost: UnitCost;
    };
    isLocked: boolean;
};

export type CustomCosts = {
    extraCosts: ExtraCost[];
    profit?: CostCell;
    discount?: CostCell;
};

type AdditionalCosts = {
    otherCosts: ExtraCost[];
    postProfitCosts: ExtraCost[];
    profit?: CostCell;
    discount?: CostCell;
};

export type ProjectCost =
    | {
          profit?: CostCell;
      }
    | undefined;

export type CalculationTemplateFormInput = {
    name: string;
    materialCost: CustomCosts;
    manufacturingCost: CustomCosts;
    additionalCosts: AdditionalCosts;
    projectCost: ProjectCost;
    currency: Currency;
    isSeparateProjectCostSelected: boolean;
    includeExcessMaterialInMaterialCost: boolean;
};
