import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { FormItem, Text } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';

export function FormItemNotes({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const notes = quoteTracking.notes;

    if (!isPresent(notes)) {
        return null;
    }

    return (
        <FormItem label={t`Notes`}>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{notes}</Text>
        </FormItem>
    );
}
