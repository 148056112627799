import { Flexbox, FullWidthLayout } from '@luminovo/design-system';
import { ManufacturingScenarioDTO } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { PageLayout } from '../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { colorSystem } from '../../../themes';
import { assertUnreachable } from '../../../utils/typingUtils';
import ActivitiesTable from '../ActivitiesTable/ActivitiesTable';
import ExpensesTable from '../ExpensesTable/expensesTable';
import { ManufacturingBreadcrumbForManufacturingScenarioPage } from '../ManufacturingBreadcrumbs';
import { useManufacturingPage } from '../shared/ManufacturingSharedFunctions';
import { ManufacturingDatabaseTableWrapper } from '../shared/manufacturingComponents';
import { ManufacturingScenarioBreakdown } from './ManufacturingScenarioBreakdown';
import { AppendActivitiesButton, AppendEntityButtonGroup, AppendExpensesButton } from './components/AppendEntity';
import { ManufacturingRfqEntity } from './components/AppendEntity/AppendEntityButtonGroup';
import { AppendManufacturingScenarioTemplateButton } from './components/AppendManufacturingScenarioTemplateButton';
import { DeleteManufacturingScenarioButton } from './components/DeleteManufacturingScenarioButton';
import { EditManufacturingScenarioButton } from './components/EditManufacturingScenarioButton';

const SelectedManufacturingScenarioTable = ({
    entity,
    selectedManufacturingScenario,
}: {
    entity: ManufacturingRfqEntity;
    selectedManufacturingScenario: ManufacturingScenarioDTO;
}): JSX.Element => {
    switch (entity) {
        case 'activity':
            return <ActivitiesTable selectedManufacturingScenario={selectedManufacturingScenario.id} />;
        case 'expense':
            return <ExpensesTable selectedManufacturingScenario={selectedManufacturingScenario.id} />;
        default:
            assertUnreachable(entity);
    }
};

const ManufacturingScenarioDetails = ({
    manufacturingScenario,
    assemblyId,
    rfqId,
}: {
    manufacturingScenario?: ManufacturingScenarioDTO;
    assemblyId: string | undefined;
    rfqId: string;
}): JSX.Element => {
    return (
        <Flexbox gap={32} marginBottom={'20px'} alignItems={'center'}>
            <Typography variant="h1" style={{ color: colorSystem.neutral[8] }}>
                {manufacturingScenario?.name}
            </Typography>

            {manufacturingScenario && assemblyId && (
                <Flexbox marginLeft={'auto'} gap={'12px'}>
                    <AppendManufacturingScenarioTemplateButton manufacturingScenarioId={manufacturingScenario.id} />
                    <EditManufacturingScenarioButton
                        manufacturingScenario={{
                            id: manufacturingScenario.id,
                            name: manufacturingScenario.name,
                            notes: manufacturingScenario.notes ?? undefined,
                            batchSizes: manufacturingScenario.batch_sizes,
                            sourcingScenarioId: manufacturingScenario.sourcing_scenario ?? undefined,
                        }}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                    />
                    <DeleteManufacturingScenarioButton manufacturingScenarioId={manufacturingScenario.id} />
                </Flexbox>
            )}
        </Flexbox>
    );
};

const ManufacturingAssemblyScenarioDetailsHeader = ({
    entity,
    rfqId,
}: {
    entity: ManufacturingRfqEntity;
    rfqId: string;
}): JSX.Element => {
    const { manufacturingScenario, manufacturingScenarioFullSummary, isLoading } = useManufacturingPage();
    const totalActivities = manufacturingScenarioFullSummary
        ? manufacturingScenarioFullSummary.activity_configuration_summaries.items.length
        : 0;
    const totalExpenses = manufacturingScenarioFullSummary
        ? manufacturingScenarioFullSummary.expense_configuration_summaries.items.length
        : 0;
    return (
        <>
            <ManufacturingBreadcrumbForManufacturingScenarioPage />
            <FullWidthLayout variant="neutral">
                <ManufacturingScenarioDetails
                    manufacturingScenario={manufacturingScenario}
                    assemblyId={manufacturingScenarioFullSummary?.manufacturing_assembly_details.assembly_id}
                    rfqId={rfqId}
                />
                <ManufacturingScenarioBreakdown
                    manufacturingScenarioFullSummary={manufacturingScenarioFullSummary}
                    manufacturingScenario={manufacturingScenario}
                    isLoading={isLoading}
                />
            </FullWidthLayout>
            <FullWidthLayout>
                <Flexbox justifyContent={'space-between'}>
                    <AppendEntityButtonGroup
                        entity={entity}
                        totalActivities={totalActivities}
                        totalExpenses={totalExpenses}
                    />
                    {manufacturingScenario && (
                        <>
                            {entity === 'activity' && (
                                <AppendActivitiesButton
                                    manufacturingScenarioId={manufacturingScenario.id}
                                    manufacturingScenarioTemplateInstanceId={undefined}
                                />
                            )}
                            {entity === 'expense' && (
                                <AppendExpensesButton
                                    manufacturingScenarioId={manufacturingScenario.id}
                                    manufacturingScenarioTemplateInstanceId={undefined}
                                />
                            )}
                        </>
                    )}
                </Flexbox>
            </FullWidthLayout>
        </>
    );
};

export const ManufacturingAssemblyScenarioDetailsPage = ({
    entity,
}: {
    entity: ManufacturingRfqEntity;
}): JSX.Element => {
    const { isLoading, manufacturingScenario, rfqId } = useManufacturingPage();

    if (isLoading) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    return (
        <PageLayout
            header={<ManufacturingAssemblyScenarioDetailsHeader entity={entity} rfqId={rfqId} />}
            layout="fragment"
        >
            <ManufacturingDatabaseTableWrapper>
                {manufacturingScenario && (
                    <SelectedManufacturingScenarioTable
                        entity={entity}
                        selectedManufacturingScenario={manufacturingScenario}
                    />
                )}
            </ManufacturingDatabaseTableWrapper>
        </PageLayout>
    );
};
