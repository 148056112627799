import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { FileTypeUpdateRequestRuntype } from './pcbBackendTypes';

export const pcbFilesEndpoints = {
    'PUT /pcb/:pcbId/files': endpoint({
        description: 'Update pcb file types',
        pathParams: r.Record({ pcbId: r.String }),
        requestBody: FileTypeUpdateRequestRuntype,
        queryParams: r.Undefined,
        responseBody: r.Literal('OK'),
    }),

    'DELETE /pcb/:pcbId/files': endpoint({
        description: 'Delete pcb file',
        pathParams: r.Record({ pcbId: r.String }),
        requestBody: r.Record({
            files: r.Array(r.String),
        }),
        queryParams: r.Undefined,
        responseBody: r.Literal('OK'),
        invalidates: ['GET /ems/pcb/v2/pcbs/:pcbId'],
    }),

    'GET /ems/renderer/assemblies/:assemblyId/versions/:pcbId/files/:fileName': endpoint({
        description: 'Get pcb file svg',
        pathParams: r.Record({
            assemblyId: r.String,
            pcbId: r.String,
            fileName: r.String,
        }),
        requestBody: r.Undefined,
        queryParams: r.Record({
            format: r.Literal('svg'),
        }),
        responseBody: r.String.nullable(),
        handleResponse: async (response, _) => {
            if (response.ok) return response.text();
            if (response.status === 404) return null;
            throw Error(`Unexpected response status ${response.status}`);
        },
    }),
};
