import { Parser } from '../parsers/Parser';
import { NumberProps, number } from '../parsers/number';

/**
 * A parser that parses a quantity, such as how many items are in a package.
 */
export function quantity({ decimalSeparator }: Pick<NumberProps, 'decimalSeparator'>): Parser<number> {
    return number({
        decimalSeparator,
        warnIf: {
            isNegative: true,
            isFloat: true,
        },
    });
}
