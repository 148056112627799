import { t } from '@lingui/macro';
import { ActivityConfigurationSummaryDTO } from '@luminovo/http-client';
import { Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorSystem } from '../../../themes';
import { EmptyConfigurationDrawer } from '../ExpensesTable/expenseConfigurationDrawer';
import {
    ActivityConfigurationCostDetails,
    ActivityConfigurationDetails,
    ActivityConfigurationDriversDetails,
    ActivityConfigurationResourcesDetails,
    ActivityConfigurationTimeCalculation,
} from './activityConfigurationDrawerComponents';

const StyledBox = styled(Box)({
    border: `1px solid ${colorSystem.neutral[3]}`,
    height: '100%',
    borderRadius: '8px',
});

export const ActivityConfigurationDrawer = ({
    activity,
}: {
    activity: ActivityConfigurationSummaryDTO | undefined;
}): JSX.Element => {
    if (!activity) {
        return <EmptyConfigurationDrawer text={t`activity`} />;
    }
    return (
        <StyledBox key={activity.activity_configuration_details.activity_configuration_id}>
            <ActivityConfigurationDetails activity={activity} />
            <Divider />
            <ActivityConfigurationDriversDetails activity={activity} />
            <ActivityConfigurationTimeCalculation activity={activity} />
            <Divider />

            <Grid container style={{ padding: '24px' }}>
                <Grid item xs={6}>
                    <ActivityConfigurationResourcesDetails resourcesDetails={activity.resources_details} />
                </Grid>
                <Grid item xs={6}>
                    <ActivityConfigurationCostDetails resourcesDetails={activity.resources_details} />
                </Grid>
            </Grid>
        </StyledBox>
    );
};
