import { t } from '@lingui/macro';
import { assertUnreachable, indexBy } from '@luminovo/commons';
import { Chip } from '@luminovo/design-system';
import {
    ComponentFunction,
    FullPart,
    PackageDTO,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isIncompleteGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { PackageChip } from '@luminovo/sourcing-core';
import { useHttpQuery } from '../resources/http/useHttpQuery';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../utils/converterUtils';

export function LabelPartDescription({ part }: { part?: FullPart }): JSX.Element {
    const { data: packagesById = new Map() } = useHttpQuery(
        'GET /parts/packages',
        {
            queryParams: {},
        },
        {
            staleTime: Infinity,
            select: (data): Map<string, PackageDTO> => {
                return indexBy(data.items, (x) => x.id);
            },
        },
    );

    const pkg = getPackage({ part, packagesById });
    const packageChip = pkg && <PackageChip formfit={pkg} />;

    if (!part) {
        return <></>;
    }
    if (isOtsFullPart(part)) {
        return (
            <>
                {/* we intentionally don't show the package for OTS parts */}
                {part.description}
            </>
        );
    }
    if (isCustomFullPart(part)) {
        return (
            <>
                {packageChip}
                {part.description}
            </>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <>
                {packageChip}
                <TechnicalParameters spec={part.content} />
            </>
        );
    }
    if (isCustomComponentFull(part)) {
        return (
            <>
                {packageChip}
                {part.description}
            </>
        );
    }
    if (isOtsComponentFull(part)) {
        const spec = part.component_specification?.function_specification ?? undefined;
        return (
            <>
                {packageChip}
                {spec && <TechnicalParameters spec={spec} />}
            </>
        );
    }
    assertUnreachable(part);
}

function TechnicalParameters({ spec }: { spec: ComponentFunction }) {
    if (spec.type === 'Resistor') {
        const params = spec.technical_parameters;
        return (
            <>
                <Chip label={spec.type} color="neutral" />
                {params.power_rating && <Chip label={formatPowerRating(params.power_rating)} color="neutral" />}
                {params.resistance && <Chip label={formatResistance(params.resistance)} color="neutral" />}
                {params.temperature_coefficient && (
                    <Chip label={formatTemperatureCoefficient(params.temperature_coefficient)} color="neutral" />
                )}
                {params.tolerance && <Chip label={formatTolerance(params.tolerance)} color="neutral" />}
                {params.voltage_rating && <Chip label={formatVoltageRating(params.voltage_rating)} color="neutral" />}
            </>
        );
    } else if (spec.type === 'Capacitor') {
        const params = spec.technical_parameters;
        return (
            <>
                <Chip label={spec.type} color="neutral" />
                {params.capacitance && <Chip label={formatCapacitance(params.capacitance)} color="neutral" />}
                {params.dielectric && <Chip label={formatDielectricMaterial(params.dielectric)} color="neutral" />}
                {params.tolerance && <Chip label={formatTolerance(params.tolerance)} color="neutral" />}
                {params.voltage_rating && <Chip label={formatVoltageRating(params.voltage_rating)} color="neutral" />}
            </>
        );
    } else if (spec.type === 'Unsupported') {
        const params = spec.technical_parameters;
        return (
            <>
                <Chip label={t`Unsupported`} color="red" />
                {params.part_type && <Chip label={params.part_type} color="neutral" />}
            </>
        );
    }
    assertUnreachable(spec);
}

function getPackage({
    part,
    packagesById,
}: {
    part?: FullPart;
    packagesById: Map<string, PackageDTO>;
}): PackageDTO | undefined {
    if (!part) {
        return undefined;
    }
    if (isCustomComponentFull(part)) {
        return undefined;
    }
    if (isCustomFullPart(part)) {
        return undefined;
    }
    if (isOtsFullPart(part)) {
        return part.package ?? undefined;
    }
    if (isGenericFullPart(part)) {
        return packagesById.get(part.content.technical_parameters.package_id ?? '');
    }

    if (isIncompleteGenericFullPart(part)) {
        return packagesById.get(part.technical_parameters.package_id ?? '');
    }

    if (isOtsComponentFull(part)) {
        return part.component_specification?.form_and_fit ?? undefined;
    }
    assertUnreachable(part);
}
