import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import {
    ActionPerformedBy,
    DesignatorsList,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

type Props = {
    designators: string[];
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

const useBOMItemWithDesignatorsAction = (operation: HistoryOperation, actor: ActorResponseRuntypeDTO) => {
    switch (operation) {
        case HistoryOperation.AutoAdd:
            return <Trans>added automatically</Trans>;
        case HistoryOperation.ManualAdd:
            if (!actor) return null;
            return (
                <TimelineText>
                    <Trans>manually added</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualRemove:
            if (!actor) return null;
            return (
                <TimelineText>
                    <Trans>manually removed</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
};

export default function BomItemDesignators({ designators, operation, actor }: Props) {
    const action = useBOMItemWithDesignatorsAction(operation, actor);
    if (!action) return null;

    return (
        <TimelineContentContainer>
            <TimelineText style={{ display: 'flex' }}>
                <Trans>Bom item with designators</Trans> <DesignatorsList designators={designators} /> {action}
            </TimelineText>
        </TimelineContentContainer>
    );
}

export function BomItemDesignatorsTimelineIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return <TimelineAddIcon />;
}
