import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseMicrometer } from '../parsers/parseLength';
import { parseCopperThickness, parseCopperThicknessKeyword } from '../parsers/parseThickness';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

export const extractCopperThickness: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const attributes = parseCopperThickness(stringToParse).map((attribute) =>
        setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute)),
    );
    if (attributes.length > 0) {
        return attributes;
    }

    // no confidence penalty for keywords (they don't need to be "selected")
    const keyword = parseCopperThicknessKeyword(stringToParse);
    const keywords = isPresent(keyword) ? [keyword] : [];
    const values = parseMicrometer(stringToParse);
    return keywords.concat(
        values.length === 1
            ? [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, values[0]))]
            : [],
    );
};
