import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { styled } from '@mui/material';
import React from 'react';
import { applyExtractor, Extractor } from '../../internal/applyExtractor';
import { TooltipDetails } from '../../internal/GraphTooltip';

interface Datum {}

export interface Props<T extends Datum> extends React.HTMLAttributes<HTMLDivElement> {
    data: T[];
    onBarClick?: (datum: T) => void;
    getLabel: Extractor<T, string | JSX.Element>;
    getValue: Extractor<T, number>;
    getColor: Extractor<T, string>;
    getTooltip?: Extractor<T, { title?: JSX.Element; description: JSX.Element }>;
    isSelected?: Extractor<T, boolean>;
}

const notSelected = () => false;
const noop = () => {};

const gap = 12;

export function FractionalBarChart<T extends Datum>({
    data,
    getColor,
    getLabel,
    getValue,
    getTooltip,
    isSelected = notSelected,
    onBarClick = noop,
    ...rest
}: Props<T>): JSX.Element {
    const total = data.map((datum) => applyExtractor(datum, getValue)).reduce((a, b) => a + b, 0);
    return (
        <div
            {...rest}
            style={{
                display: 'flex',
                gap,
                flexDirection: 'column',
                justifyContent: 'space-between',
                ...rest.style,
            }}
        >
            {data.map((datum, index) => {
                const value = applyExtractor(datum, getValue);
                return (
                    <Tooltip
                        variant="white"
                        disableMaxWidth={true}
                        key={index}
                        title={
                            <TooltipDetails
                                color={applyExtractor(datum, getColor)}
                                label={applyExtractor(datum, getLabel)}
                                count={applyExtractor(datum, getValue)}
                                tooltip={getTooltip ? applyExtractor(datum, getTooltip) : undefined}
                            />
                        }
                        placement="top"
                    >
                        <Row
                            onClick={() => onBarClick(datum)}
                            style={
                                applyExtractor(datum, isSelected)
                                    ? {
                                          background: colorSystem.primary[2],
                                          border: `1px solid ${colorSystem.primary[3]}`,
                                      }
                                    : {}
                            }
                        >
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', paddingRight: '4px' }}>
                                <Text variant="body-small">{applyExtractor(datum, getLabel)}</Text>
                                <Text variant="body-small" style={{ textAlign: 'right' }}>
                                    {value}
                                </Text>
                            </div>
                            <BarContainer
                                className="bar-container"
                                style={
                                    applyExtractor(datum, isSelected)
                                        ? {
                                              background: colorSystem.neutral.white,
                                          }
                                        : {}
                                }
                            >
                                <HorizontalBar
                                    className="bar"
                                    style={{
                                        background: applyExtractor(datum, getColor),
                                        width: value / total ? `${Math.ceil((value / total) * 100)}%` : 0,
                                    }}
                                />
                            </BarContainer>
                        </Row>
                    </Tooltip>
                );
            })}
        </div>
    );
}

const HorizontalBar = styled('div')({
    height: '100%',
    borderRadius: 4,
});

const BarContainer = styled('div')({
    background: colorSystem.neutral[1],
    height: 12,
    borderRadius: 4,
});

const Row = styled('div')({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    borderRadius: 8,
    padding: gap / 2 + 1,
    margin: -gap / 2 + 1,
    transition: 'background 0.2s ease-in-out',
    overflow: 'visible',
    '&:hover': {
        background: colorSystem.neutral[1],
    },
    '&:hover .bar-container': {
        background: colorSystem.neutral.white,
        transition: 'background 0.2s ease-in-out',
    },
});
