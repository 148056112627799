import { t } from '@lingui/macro';
import { ArrayElement } from '@luminovo/commons';
import { FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form';
import { FieldController, FieldControllerProps } from '../FieldController';
import { FieldMultiSelect } from './FieldMultiSelect';
import { FieldMultiSelectProps } from './types';

interface ControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
> extends Omit<FieldControllerProps<FieldMultiSelectProps<ArrayElement<TValue>>, TFieldValues, TName>, 'Field'> {
    /**
     * When set, the input field is required.
     */
    required?: boolean;
}

export function FieldMultiSelectControlled<
    TFieldValues extends FieldValues = FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
    TValue extends FieldPathValue<TFieldValues, TName> = PathValue<TFieldValues, TName>,
>({ required, validate, ...props }: ControllerProps<TFieldValues, TName, TValue>): JSX.Element {
    return <FieldController Field={FieldMultiSelect} validate={createValidator({ required, validate })} {...props} />;
}

function createValidator({
    required,
    validate,
}: {
    required?: boolean;
    validate?: (x: unknown) => string | undefined;
}) {
    return (x: unknown): string | undefined => {
        if (required && Array.isArray(x) && x.length === 0) {
            return t`Required`;
        }
        if (validate) {
            return validate(x);
        }
        return undefined;
    };
}
