import { RowId } from './rowIdTypes';

//checks that a string is in fact an id for an extra row. Throws a type error if not.
type ExtraRowCheck<T extends string> = `${T}-uniqueId` extends RowId ? T : never;

type AdditionalPostProfitExtraField = ExtraRowCheck<'additional-post-profit-extra'>;
type MaterialCostExtraField = ExtraRowCheck<'material-cost-extra'>;
type ManufacturingCostExtraField = ExtraRowCheck<'manufacturing-cost-extra'>;
type AddtionalOtherCostsExtraField = ExtraRowCheck<'additional-other-costs-extra'>;

export type ExtraField =
    | AdditionalPostProfitExtraField
    | MaterialCostExtraField
    | ManufacturingCostExtraField
    | AddtionalOtherCostsExtraField;

export const startsWithExtraField = (str: string, prefix: ExtraField) => str.startsWith(prefix);
