import { EmailTemplateDTO } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

export function useQuoteRequestEmailTemplateBySupplier(supplierId?: string): UseQueryResult<EmailTemplateDTO | null> {
    return useHttpQuery(
        'GET /email-template',
        {},
        {
            suspense: true,
            enabled: Boolean(supplierId),
            select: (res) => getQuoteRequestEmailTemplateBySupplier(res.items, supplierId) ?? null,
            refetchOnWindowFocus: true,
        },
    );
}

export function getQuoteRequestEmailTemplateBySupplier(
    emailTemplates: EmailTemplateDTO[],
    supplierId?: string,
): EmailTemplateDTO | undefined {
    const quoteRequestTemplates = emailTemplates.filter((t) => t.email_type === 'QuoteRequest');
    const defaultQuoteReqTemplate = quoteRequestTemplates.find((t) => t.is_default);
    const supplierQuoteReqTemplate = quoteRequestTemplates.find((t) => t.suppliers.includes(supplierId ?? ''));
    const template = supplierQuoteReqTemplate ?? defaultQuoteReqTemplate;

    return template;
}
