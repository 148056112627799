import { t } from '@lingui/macro';
import { FieldSelectControlled, FormItem } from '@luminovo/design-system';
import { LeadTimeUnit, formatLeadTimeUnit } from '@luminovo/sourcing-core';
import { useFormContext } from 'react-hook-form';
import { StandardPartQuoteFormValues } from '../types';

export function FormItemLeadTimeUnit() {
    const { control } = useFormContext<StandardPartQuoteFormValues>();

    return (
        <FormItem label={t`Lead time unit`} description={t`The unit in which the lead time is measured.`}>
            <FieldSelectControlled
                control={control}
                name="leadTimeUnit"
                required
                FieldProps={{
                    options: Object.values(LeadTimeUnit),
                    getOptionLabel: formatLeadTimeUnit,
                }}
            />
        </FormItem>
    );
}
