import { chainComparators, Comparable, compareByNumber } from '@luminovo/design-system';
import { Region } from './types';

export function compareByVerticalDistance(target: Region): Comparable<Region> {
    return chainComparators(
        compareByNumber<Region>((region) => {
            if (target.pageNumber === region.pageNumber) {
                return 0;
            }
            return 1;
        }),
        compareByNumber<Region>((region) => {
            const yDistance = region.box.y - target.box.y;
            // sort negative distances (values above MPN) to the end by adding one page of penalty
            return yDistance < -0.01 ? yDistance + 1 : yDistance;
        }),
    );
}
