import { t } from '@lingui/macro';
import { CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { Refresh as RefreshIcon, Warning } from '@mui/icons-material';

export const SadBox = () => (
    <CenteredLayout>
        <NonIdealState
            Icon={Warning}
            title={t`Unexpected error`}
            description={t`Sorry, it looks like something went wrong. Try refreshing the page. If this problem persists, please contact our support team.`}
            action={{
                onClick: () => window.location.replace(window.location.href),
                variant: 'outlined',
                color: 'primary',
                startIcon: <RefreshIcon />,
                children: t`Refresh`,
            }}
        />
    </CenteredLayout>
);
