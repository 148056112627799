import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { TmeCredentialsInputDTORuntype, TmeResponseBodyDTORuntype } from './tmeBackendTypes';

export const tmeEndpoints = {
    'GET /3rdparty/tme/credentials': endpoint({
        description: 'Returns credentials for tme integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: TmeResponseBodyDTORuntype,
    }),
    'POST /3rdparty/tme/credentials': endpoint({
        description: 'Uploads credentials for tme API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TmeCredentialsInputDTORuntype,
        responseBody: TmeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tme/credentials'],
    }),
    'PATCH /3rdparty/tme/credentials': endpoint({
        description: 'Updates credentials for tme API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: TmeCredentialsInputDTORuntype,
        responseBody: TmeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tme/credentials'],
    }),
    'DELETE /3rdparty/tme/credentials': endpoint({
        description: 'Deletes existing credentials for tme API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/tme/credentials'],
    }),
};
