import { t } from '@lingui/macro';
import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import { usePageParams } from '../../../resources/hooks';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { DriverForm, useDriverForm } from './DriverForm';
import { convertDriverDTOToFormValues } from './driverFormFunctions';

export const EditDriverForm = (): JSX.Element => {
    const { driverId } = usePageParams<'/manufacturing/driver/:driverId/edit'>();
    const { isLoading, drivers, packages } = useDriverForm(driverId);
    const driver = drivers?.data.find((driver) => driver.id === driverId);

    if (isLoading) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (driver === undefined) {
        throw new Error('driver should not be undefined if isLoading is false');
    }

    return (
        <DriverForm
            packages={packages}
            defaultValues={convertDriverDTOToFormValues(driver)}
            onSubmitType={'PATCH'}
            formTitle={capitalizeFirstLetter(t`Edit driver`)}
        />
    );
};
