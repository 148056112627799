import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { Skeleton } from '@mui/material';
import { usePartVotes } from '../../resources/part/usePartVotes';
import { otsPartOriginKeyWithLabelValue } from '../PartLifecycleView/OtsPartLifecycleStatusOriginsTooltip';

export const OtsPartYteolStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    lifecycleYteol: number | null;
    children: TooltipProps['children'];
}> = ({ partId, lifecycleYteol, children }): JSX.Element => {
    const { votes, isLoading, triggerFetchVotes } = usePartVotes({
        partId,
        shouldFetchVotes: lifecycleYteol === null,
    });

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>Years to end of life origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip = votes && votes.some((vote) => !!vote.lifecycle_yteol);

    return (
        <Tooltip
            arrow
            onOpen={triggerFetchVotes}
            title={
                showTooltip ? (
                    <Flexbox
                        flexDirection="column"
                        gap="16px"
                        width="200px"
                        maxHeight="308px"
                        overflow="auto"
                        padding="4px"
                    >
                        <Text variant="h5">
                            <Trans>Years to end of life origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((vote, i) => {
                                if (!vote.lifecycle_yteol) {
                                    return null;
                                }
                                const label = otsPartOriginKeyWithLabelValue[vote.origin.type]
                                    ? otsPartOriginKeyWithLabelValue[vote.origin.type].label
                                    : vote.origin.type;
                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between" gap="4px">
                                        <Text variant="body-small">{vote.lifecycle_yteol}</Text>
                                        <Text variant="body-small" showEllipsis>
                                            {label}
                                        </Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};
