import { ChevronRightRounded } from '@mui/icons-material';
import { styled, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useForwardRef } from '../../hooks/useForwardedRef';
import { colorSystem } from '../../theme';
import { Tooltip } from '../Tooltip';
import { Action, Column, DataTableRowProps, PersistentTableState } from './types';
import { indexTableRowElement } from './useGlobalSearch';

const StyledTableRow = styled(TableRow)(({ onClick }: { onClick: unknown }) => ({
    cursor: onClick ? 'pointer' : undefined,
    '&:hover': {
        background: onClick ? colorSystem.neutral[0] : undefined,
    },
}));

/**
 * A wrapper component to ensure that the row re-renders when the column changes.
 * This is preferred instead of having to declare the component directly as a child
 * of `<StyledTableRow/>` using `const Component = col.render`. With the old method, the
 * column component would unmount and remount on each change, even when that was required
 * because a new Component was being created each time. With this new way, the component does
 * not unmount on each change.
 *
 */
function ColumnRenderer<TRowData, TContext>({
    col,
    index,
    data,
    dispatch,
    tableState,
    rowId,
    sharedContext,
}: {
    col: Column<TRowData, TContext>;
    index: number;
    data: TRowData;
    tableState: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    rowId: string | undefined;
    sharedContext: TContext;
}) {
    return (
        <>
            {col.render(
                {
                    index,
                    data,
                    tableState,
                    dispatch,
                    rowId,
                },
                sharedContext,
            )}
        </>
    );
}

function ColumnRenderExpanded<TRowData, TContext>({
    col,
    index,
    data,
    dispatch,
    tableState,
    rowId,
    sharedContext,
}: {
    col: Column<TRowData, TContext>;
    index: number;
    data: TRowData;
    tableState: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    rowId: string | undefined;
    sharedContext: TContext;
}) {
    if (!col.renderExpanded) {
        return null;
    }
    return (
        <>
            {col.renderExpanded(
                {
                    index,
                    data,
                    tableState,
                    dispatch,
                    rowId,
                },
                sharedContext,
            )}
        </>
    );
}

function DataTableRowInner<TRowData, TContext>(
    {
        item,
        index,
        columns,
        onItemClick,
        onSearchText,
        display = 'visible',
        tableState,
        dispatch,
        rowId,
        style,
        sharedContext,
        tooltip,
    }: DataTableRowProps<TRowData, TContext>,
    outerRef: React.ForwardedRef<HTMLTableRowElement>,
): JSX.Element {
    const [isExpanded, setExpanded] = React.useState<boolean>(false);
    const isTableExpandable = columns.some((col) => Boolean(col.renderExpanded));

    const expandedRows = columns.map((col) => {
        if (!col.renderExpanded) {
            return null;
        }
        return col.renderExpanded(
            {
                index,
                data: item,
                tableState,
                dispatch,
                rowId,
            },
            sharedContext,
        );
    });
    const isCurrentRowExpandable = expandedRows.some((x) => x !== null);

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onItemClick && onItemClick(item, sharedContext);
            event.stopPropagation();
        },
        [onItemClick, item, sharedContext],
    );

    const handleExpand = (event: React.MouseEvent<HTMLElement>) => {
        if (isCurrentRowExpandable) {
            setExpanded((value) => !value);
            event.stopPropagation();
        }
    };

    const ref = useForwardRef(outerRef);

    React.useEffect(() => {
        if (onSearchText && ref.current) {
            onSearchText(item, indexTableRowElement(ref.current));
        }
    }, [item, ref, onSearchText]);

    return (
        <>
            <Tooltip title={tooltip ?? ''} placement="bottom">
                <StyledTableRow
                    selected={rowId ? tableState.selectedIds.includes(rowId) : false}
                    ref={ref}
                    onClick={onItemClick && handleClick}
                    style={{ display, ...style }}
                >
                    {isTableExpandable && (
                        <TableCell onClick={handleExpand} width="25px">
                            {isCurrentRowExpandable && (
                                <ChevronRightRounded
                                    viewBox="1 1 20 20"
                                    style={{
                                        color: colorSystem.neutral[7],
                                        transform: isExpanded ? `rotate(90deg)` : `rotate(0deg)`,
                                        transition: 'transform 0.1s ease-out',
                                        height: '20px',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </TableCell>
                    )}
                    {columns.map((col) => {
                        return (
                            <ColumnRenderer
                                key={col.id}
                                index={index}
                                data={item}
                                tableState={tableState}
                                dispatch={dispatch}
                                rowId={rowId}
                                col={col}
                                sharedContext={sharedContext}
                            />
                        );
                    })}
                </StyledTableRow>
            </Tooltip>
            {isTableExpandable && isExpanded && (
                <StyledTableRow onClick={onItemClick && handleClick} style={{ background: colorSystem.neutral[0] }}>
                    {/* Empty cell required to make up for the space taken by the expand toggle cell */}
                    <TableCell
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setExpanded((value) => !value);
                            event.stopPropagation();
                        }}
                    />
                    {columns.map((col) => {
                        return (
                            <ColumnRenderExpanded
                                key={col.id}
                                index={index}
                                data={item}
                                tableState={tableState}
                                dispatch={dispatch}
                                rowId={rowId}
                                col={col}
                                sharedContext={sharedContext}
                            />
                        );
                    })}
                </StyledTableRow>
            )}
        </>
    );
}
// To restrict the type of props that can be passed into the DataTableRow, https://stackoverflow.com/a/58473012
declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export const DataTableRow = React.forwardRef(DataTableRowInner);
