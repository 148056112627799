import type { PDFDocumentProxy } from 'pdfjs-dist';
import * as React from 'react';

export function useRenderPage(
    pdf: PDFDocumentProxy | undefined,
    {
        pageNumber,
        scale,
        refTextLayer,
    }: { pageNumber: number; scale: number; refTextLayer: React.RefObject<HTMLDivElement> },
): { ref: React.RefObject<HTMLCanvasElement> } {
    const ref = React.useRef<HTMLCanvasElement>(null);
    const [, forceUpdate] = React.useState(0);

    React.useEffect(() => {
        if (!ref.current || !pdf) {
            return;
        }
        let cancelled = false;
        const canvas = ref.current;
        const context = canvas.getContext('2d');
        pdf.getPage(pageNumber).then((page) => {
            if (cancelled) {
                return;
            }
            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            page.render({
                canvasContext: context!,
                viewport: viewport,
            }).promise.then(async () => {
                const { renderTextLayer } = await import('pdfjs-dist');
                await renderTextLayer({
                    container: refTextLayer.current!,
                    viewport,
                    textContentSource: page.streamTextContent(),
                }).promise;

                forceUpdate((x) => x + 1);
            });
        });
        return () => {
            cancelled = true;
        };
    }, [pageNumber, pdf, scale, refTextLayer]);
    return {
        ref,
    };
}
