import { t, Trans } from '@lingui/macro';
import { SecondaryButton, Text } from '@luminovo/design-system';
import { Box, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { CancelButton } from '../../../../../components/formLayouts/CancelButton';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { analytics } from '../../../../../utils/analytics';
import { CalculationTemplateSelectionInput } from './CalculationTemplateSelectionInput';
import { CreateAssemblyDetailsFormInput } from './types';

export function CalculationTemplateSelectionForm({
    assemblyId,
    onSubmit,
    rfqId,
}: {
    rfqId: string;
    assemblyId: string;
    onSubmit: SubmitHandler<CreateAssemblyDetailsFormInput>;
}) {
    const useFormReturn = useForm<CreateAssemblyDetailsFormInput>({
        defaultValues: { assemblyId: assemblyId, notes: '', includeExcessMaterial: false },
        mode: 'onChange',
    });
    const { watch, handleSubmit, setValue } = useFormReturn;
    const { closeDialog } = useDialogContext();

    const setSelectedTemplate = (templateId: string | undefined) => {
        setValue('templateId', templateId, { shouldDirty: true });
    };
    const selectedTemplateId = watch(`templateId`);

    const trackEvent = (eventName: 'build_with_calculation_template' | 'skip_calculation_template') => {
        /*eslint-disable camelcase */
        analytics.track(eventName, {
            rfq_id: rfqId,
            assembly_id: assemblyId,
        });
        /*eslint-enable camelcase */
    };

    return (
        <Box>
            <FormProvider {...useFormReturn}>
                <form
                    style={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    onSubmit={async (event) => {
                        await handleSubmit(onSubmit)(event);
                        trackEvent('build_with_calculation_template');
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <Text variant={'h3'} style={{ marginBlockEnd: '8px' }}>
                                    <Trans>Do you want to select a template?</Trans>
                                </Text>
                            </Grid>
                            <Grid item>
                                <Text variant={'body'}>
                                    <Trans>You can also continue without a template.</Trans>
                                </Text>
                            </Grid>
                            <Grid item>
                                <CalculationTemplateSelectionInput
                                    templateId={selectedTemplateId}
                                    onSelectTemplateId={setSelectedTemplate}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" style={{ marginTop: 20, marginBottom: 20 }}>
                            <Grid item>
                                <CancelButton onClick={() => closeDialog()} />
                            </Grid>
                            <Grid item container spacing={2} justifyContent="flex-end" xs={8}>
                                <Grid item>
                                    <SecondaryButton
                                        onClick={async (event) => {
                                            setValue('templateId', undefined, { shouldDirty: true });
                                            await handleSubmit(onSubmit)(event);
                                            trackEvent('skip_calculation_template');
                                        }}
                                    >
                                        <Trans>Skip</Trans>
                                    </SecondaryButton>
                                </Grid>
                                <Grid item>
                                    <SubmitButton
                                        disabled={selectedTemplateId === undefined}
                                        label={t`Build with template`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Box>
    );
}
