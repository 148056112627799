import { Column } from '@luminovo/design-system';
import { TableCell } from '@mui/material';
import { UseFieldArrayInsert, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { RowHeaderCell } from './cells/RowHeaderCell';
import { SectionHeaderCell } from './cells/SectionHeaderCell';
import { bufferCellStyle, hiddenCellStyle } from './cells/cells';
import { generateCalculationColumns } from './generateOrderInformationColumns';
import { CalculationTableForm } from './types/formTypes';
import { Row } from './types/rowTypes';

export const generateColumns = ({
    insert,
    remove,
    update,
    rows,
    sourcingCacheParams: { assemblyId },
    isProjectCostsSeparateFromManufacturingCost,
    isCalculationFrozen,
}: {
    rows: Row[];
    insert: UseFieldArrayInsert<CalculationTableForm, 'rows'>;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<CalculationTableForm, 'rows'>;
    isProjectCostsSeparateFromManufacturingCost: boolean;
    sourcingCacheParams: {
        assemblyId: string;
    };
    isCalculationFrozen: boolean;
}): Column<Row>[] => {
    return [
        {
            id: 'name',
            label: '',
            render: ({ data, index }) => {
                if (data.style === 'hidden') {
                    return <TableCell style={hiddenCellStyle} />;
                }

                const type = data.type;
                if (type === 'header') {
                    return (
                        <SectionHeaderCell
                            insert={insert}
                            remove={remove}
                            update={update}
                            index={index}
                            data={data}
                            assemblyId={assemblyId}
                            isProjectCostsSeparateFromManufacturingCost={isProjectCostsSeparateFromManufacturingCost}
                            isCalculationFrozen={isCalculationFrozen}
                        />
                    );
                }
                if (type === 'buffer') {
                    return <TableCell style={bufferCellStyle} />;
                }
                return (
                    <RowHeaderCell
                        insert={insert}
                        remove={remove}
                        update={update}
                        index={index}
                        data={data}
                        isCalculationFrozen={isCalculationFrozen}
                    />
                );
            },
        },
        ...generateCalculationColumns(rows),
    ];
};
