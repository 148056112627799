/* eslint-disable camelcase */
import initHelpHero, { HelpHero } from 'helphero';
import { ConsentType } from '../ConsentType';
import { Tracker } from '../Tracker';

export let helpHero: HelpHero | undefined = undefined;

export const helpHeroTracker: Tracker = {
    requiredConsent: ConsentType.required,

    enabledForInternalUsers: false,

    enabledForDevEnvironment: false,

    initialize() {
        // eslint-disable-next-line spellcheck/spell-checker
        helpHero = initHelpHero('WIicqzvzCa');
    },
    identify(
        userId: string,
        {
            email,
            language,
            is_from_customer,
            customer_name,
            host,
            tenant,
            has_access_to_customer_portal,
            has_full_manufacturing_access,
            has_customers,
            client_type,
        },
    ) {
        helpHero?.identify(userId, {
            email,
            language,
            is_from_customer,
            customer_name,
            host,
            tenant,
            has_access_to_customer_portal,
            has_full_manufacturing_access,
            has_customers,
            client_type,
        });
    },
    group(orgId, organizationTraits) {
        /* no-op */
    },
    track(event, args?) {
        /* no-op */
    },
    page(name, args?) {
        /* no-op */
    },
};
