import { formatDecimal, isPresent } from '@luminovo/commons';
import { CheckCircleOutline } from '@mui/icons-material';
import { styled } from '@mui/material';
import * as React from 'react';
import { colorSystem, disabledStyles, focusedStyles, h5, hoveredStyles } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

export type CheckableChipProps = {
    label: string;
    selected: boolean;
    disabled?: boolean;
    done?: boolean;
    count?: number;
    showEllipsis?: boolean;
    onClick?: () => void;
    overrides?: {
        StartIcon?: React.ComponentType<{ style: React.CSSProperties }>;
        EndIcon?: React.ComponentType<{ style: React.CSSProperties }>;
    };
};

export const CheckableChip = React.forwardRef(
    (
        { label, selected, disabled, done, count, showEllipsis, onClick, overrides = {} }: CheckableChipProps,
        ref: React.Ref<HTMLDivElement>,
    ): JSX.Element => {
        const { StartIcon = CheckCircleOutline, EndIcon } = overrides;

        const style = React.useMemo(() => {
            if (disabled) {
                return { ...disabledStyles, color: colorSystem.neutral[6] };
            }
            if (selected) {
                return { ...focusedStyles, color: colorSystem.neutral[8] };
            }
        }, [disabled, selected]);

        const iconColor = React.useMemo(() => {
            if (disabled) {
                return colorSystem.neutral[7];
            }
            if (done) {
                return colorSystem.green[7];
            }
            if (selected) {
                return colorSystem.primary[7];
            }
        }, [disabled, selected, done]);

        return (
            <Container
                data-testid="checkable-chip"
                role="button"
                data-done={done}
                data-disabled={disabled}
                data-selected={selected}
                ref={ref}
                onClick={!disabled ? onClick : undefined}
                style={{ ...style }}
            >
                {done && <StartIcon style={{ fontSize: 14, color: iconColor }} />}
                <Text variant="h5" color="inherit" showEllipsis={showEllipsis}>
                    {label}
                </Text>
                {isPresent(EndIcon) && <EndIcon style={{ fontSize: 14, color: iconColor }} />}
                {isPresent(count) && <Counter>{formatDecimal(count)}</Counter>}
            </Container>
        );
    },
);

const Counter = styled('div')({
    background: colorSystem.neutral[2],
    borderRadius: '8px',
    padding: '0px 4px',
    height: '16px',
    alignItems: 'center',
    ...h5,
});

const Container = styled(Flexbox)({
    alignItems: 'center',
    gap: '4px',
    width: 'fit-content',
    boxSizing: 'border-box',
    border: `1px solid ${colorSystem.neutral[2]}`,
    cursor: 'pointer',
    padding: '6px 10px',
    borderRadius: '20px',
    background: colorSystem.neutral.white,
    color: colorSystem.neutral[7],
    '&:hover': {
        ...hoveredStyles,
    },
    '&:active': {
        ...focusedStyles,
    },
    '&:focus': {
        ...focusedStyles,
    },
    transition: 'background 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
});
