import { t } from '@lingui/macro';
import { Message } from '@luminovo/design-system';
import { ImporterCard } from '@luminovo/manufacturing-core';
import { CircularProgress } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { PnpFileActionButton } from '../PnPViewer';
import { usePnpImporterDialog } from '../PnpImportDialog';
import { convertCSVContentsToCSVLines } from '../types/typeConversions';

export const ColumnMappingNotSubmittedState = ({
    assemblyId,
    isRfqEditable,
}: {
    assemblyId: string;
    isRfqEditable: boolean;
}): JSX.Element => {
    const { openDialog } = usePnpImporterDialog();

    const { data } = useHttpQuery(
        'GET /pnp/file',
        {
            queryParams: { assembly: assemblyId },
        },

        {
            suspense: true,
            useErrorBoundary: true,
        },
    );

    const { data: parseData } = useHttpQuery(
        'POST /pnp/parse',
        {
            queryParams: { id: data?.id ?? 'should not be called due to enabled flag' },
        },

        {
            suspense: true,
            useErrorBoundary: true,
            enabled: !!data,
        },
    );
    if (!data || !parseData) {
        return <CircularProgress />;
    }

    return (
        <ImporterCard
            heading={t`Pick and Place`}
            ActionButton={<PnpFileActionButton assemblyId={assemblyId} isRfqEditable={isRfqEditable} />}
        >
            <Message
                attention="high"
                variant="blue"
                title={t`You have uploaded a pick and place file but have not mapped the columns yet.`}
                size="large"
                action={{
                    label: t`Start column mapping`,
                    onClick: () =>
                        openDialog({
                            // eslint-disable-next-line camelcase
                            csvResult: convertCSVContentsToCSVLines(parseData.contents, parseData.column_mapping),
                            fileName: data.file_name,
                            uploadId: data.id,
                            // eslint-disable-next-line camelcase
                            columnMappingAttempt: parseData.column_mapping,
                        }),
                    disabled: !isRfqEditable,
                }}
            />
        </ImporterCard>
    );
};
