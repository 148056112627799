/* eslint-disable camelcase */
import { AlertDTO, AlertSupplierPreferenceDTO, CreateAlertRequestBody, StockConditionDTO } from '@luminovo/http-client';
import { assertUnreachable } from '../../utils/typingUtils';
import {
    AlertCondition,
    AlertConditionEnum,
    AlertCreationForm,
    AlertSupplierPreference,
    AlertType,
} from './alertTypes';

export const convertAlertDTOToAlert = (alertDTO: AlertDTO): AlertType => {
    return {
        condition: alertDTO.condition,
        creationDate: alertDTO.creation_date,
        id: alertDTO.id,
        notes: alertDTO.notes,
        owner: alertDTO.owner,
        part: alertDTO.part,
        status: alertDTO.status,
        supplierPreference: convertSupplierPreferenceDTO(alertDTO.supplier_preference),
    };
};

const convertSupplierPreferenceDTO = (supplierPreferenceDTO: AlertSupplierPreferenceDTO): AlertSupplierPreference => {
    return {
        additionalSupplier: supplierPreferenceDTO.additional_suppliers,
        useApproved: supplierPreferenceDTO.use_approved,
        useNotApproved: supplierPreferenceDTO.use_not_approved,
        usePreferred: supplierPreferenceDTO.use_preferred,
    };
};

const convertFormAlertConditionToDTO = (form: AlertCreationForm): StockConditionDTO => {
    switch (form.condition) {
        case AlertConditionEnum.Available:
            return {
                condition: 'Gte',
                content: 1,
            };
        case AlertConditionEnum.Gte:
            if (form.conditionContent === undefined) {
                throw new Error('Missing stock level for GTE condition');
            }
            return {
                condition: 'Gte',
                content: form.conditionContent ?? 1,
            };
        default:
            assertUnreachable(form.condition);
    }
};

export const convertCreateAlertFormToBody = (form: AlertCreationForm): CreateAlertRequestBody => {
    return {
        condition: convertFormAlertConditionToDTO(form),
        notes: form.notes,
        part: form.part,
        supplier_preference: {
            use_preferred: true,
            use_approved: true,
            use_not_approved: form.useNotApprovedSuppliers,
            additional_suppliers: [],
        },
    };
};

const convertAlertConditionToFormCondition = (condition: AlertCondition): AlertConditionEnum => {
    if (condition.condition === 'Gte') {
        if (condition.content === 1) {
            return AlertConditionEnum.Available;
        }
        return AlertConditionEnum.Gte;
    }
    assertUnreachable(condition.condition);
};

export const convertAlertToFormValues = (alert: AlertType): AlertCreationForm => {
    return {
        // We display 2 different options, "Is available" and "Greater than or equal to"
        // but on the BE "Is available" is just a special case of GTE 1.
        // so here we expect that GTE 1 converts to "Is available".
        condition: convertAlertConditionToFormCondition(alert.condition),
        conditionContent: alert.condition.content === 1 ? undefined : alert.condition.content,
        notes: alert.notes,
        part: alert.part,
        useNotApprovedSuppliers: alert.supplierPreference.useNotApproved,
    };
};
