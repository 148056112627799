import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { SystemDriverName } from '@luminovo/http-client';

export const getSystemDriverNotes = (driverName: SystemDriverName): string => {
    switch (driverName) {
        case 'pcb_width':
        case 'pcb_height':
        case 'pcb_thickness':
        case 'panel_width':
        case 'panel_height':
            return t`in mm`;
        case 'panel_factor':
            return t`PCBs/panel`;
        case 'Sides_of_placement':
            return t`1 or 2`;
        default:
            assertUnreachable(driverName);
    }
};
