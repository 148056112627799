/* eslint-disable camelcase */
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { PCBRouteSuffix } from '../components/PcbSidebar/utils/pcbSidebarLinks';

export const usePcbApprovalState = ({
    pcbId,
    currentRoute,
}: {
    pcbId: string;
    currentRoute: PCBRouteSuffix | undefined;
}) => {
    const { data } = useHttpQuery('GET /pcb-approval-state', {
        queryParams: {
            pcb_id: pcbId,
        },
    });

    const isRouteApproved =
        data !== null
            ? (() => {
                  switch (currentRoute) {
                      case 'specification':
                          return data?.is_pcb_specification_approved;
                      case 'stackup':
                          return data?.is_pcb_stackup_approved;
                      case 'drills':
                          return data?.is_pcb_drill_manager_approved;
                      case 'files':
                          return data?.is_pcb_file_manager_approved;
                      default:
                          return false;
                  }
              })()
            : false;

    const { mutateAsync, isLoading } = useHttpMutation('PATCH /pcb-approval-state/:pcbId', {
        snackbarMessage: null,
    });

    const onApprovalClick = async (status: boolean) => {
        const requestBody = {
            pcb: pcbId,
            is_pcb_specification_approved:
                currentRoute === 'specification' ? status : (data?.is_pcb_specification_approved ?? false),
            is_pcb_stackup_approved: currentRoute === 'stackup' ? status : (data?.is_pcb_stackup_approved ?? false),
            is_pcb_drill_manager_approved:
                currentRoute === 'drills' ? status : (data?.is_pcb_drill_manager_approved ?? false),
            is_pcb_file_manager_approved:
                currentRoute === 'files' ? status : (data?.is_pcb_file_manager_approved ?? false),
        };

        await mutateAsync({
            requestBody,
            pathParams: {
                pcbId,
            },
        });
    };

    return {
        isRouteApproved,
        onApprovalClick,
        isLoading,
    };
};
