import * as Sentry from '@sentry/react';
import { ScopeContext } from '@sentry/types';

/**
 * Log exception or error to all configured external error monitoring services.
 * @param error Exception
 * @param captureOptions Optional capture options object
 */
export const logToExternalErrorHandlers = (error: Error, captureOptions?: Partial<ScopeContext>): void => {
    const finalCaptureOptions: Partial<ScopeContext> = {
        ...captureOptions,
        extra: {
            'honeycomb-link': getHoneycombLink(error),
            'epibator-sha': getEpibatorSha(error),
            'luminovo-version': getLuminovoVersion(error),
            ...captureOptions?.extra,
        },
    };

    Sentry.withScope((scope) => {
        scope.setTransactionName(`${error.name}: ${error.message}`);
        Sentry.captureException(error, finalCaptureOptions);
    });
};

function getHoneycombLink(error: Error & { getHoneycombLink?: unknown }): string {
    if (!('getHoneycombLink' in error)) {
        return 'N/A';
    }
    if (typeof error.getHoneycombLink !== 'function') {
        return 'N/A';
    }
    return String(error.getHoneycombLink());
}

function getEpibatorSha(error: Error & { epibatorSha?: unknown }): string {
    if (!('epibatorSha' in error)) {
        return 'N/A';
    }
    return String(error.epibatorSha);
}

function getLuminovoVersion(error: Error & { luminovoVersion?: unknown }): string {
    if (!('luminovoVersion' in error)) {
        return 'N/A';
    }
    return String(error.luminovoVersion);
}
