import { t } from '@lingui/macro';
import { Flexbox } from '@luminovo/design-system';
import React from 'react';
import { ManufacturingDatabaseActions } from '../../../components/ManufacturingDatabase/ManufacturingDatabasePage';
import { ManufacturingDatabaseSidebar } from '../../../components/ManufacturingDatabase/ManufacturingDatabaseSidebar';
import { PageLayoutCollapsibleSidebar } from '../../../components/PageLayoutCollapsibleSidebar';
import { route } from '../../../utils/routes';
import ExpensesDatabaseTable from './components/ExpensesDatabaseTable';

export const ExpensesViewPage = (): JSX.Element => {
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    return (
        <PageLayoutCollapsibleSidebar sidebar={<ManufacturingDatabaseSidebar />} layoutVariant="fullWidth">
            <Flexbox flexDirection={'column'} paddingX={'32px'}>
                <ManufacturingDatabaseActions
                    path={route('/manufacturing/add-expense')}
                    buttonText={t`Add expense`}
                    searchQuery={searchQuery}
                    titleText={t`Expenses`}
                    setSearchQuery={setSearchQuery}
                    descriptionText={t`Expenses are one-time costs (NREs) that are incurred without using internal resources (e.g. Soldering Mask or Machine Programming).`}
                />
                <Flexbox paddingBottom={'72px'}>
                    <ExpensesDatabaseTable query={searchQuery} />
                </Flexbox>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
};
