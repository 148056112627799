import { isPresent } from '@luminovo/commons';
import { useEffect, useState } from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

export const useOrderSelectionOptionsOfRfq = (rfqId: string) => {
    const [isExpired, setIsExpired] = useState(false);
    const { data: orderSelectionOptions, isFetching } = useHttpQuery(
        'GET /order-management/order-selection-options',
        {
            // eslint-disable-next-line camelcase
            queryParams: { rfq_id: rfqId },
        },
        { staleTime: Infinity },
    );

    useEffect(() => {
        if (!isPresent(orderSelectionOptions)) {
            return;
        }

        setIsExpired(false);

        const validUntil = Date.parse(orderSelectionOptions.valid_until);
        const validityInMillis = validUntil - Date.now();
        const timeoutMillis = Math.max(0, validityInMillis);
        let timeout = setTimeout(() => setIsExpired(true), timeoutMillis);

        return () => clearTimeout(timeout);
    }, [orderSelectionOptions, setIsExpired]);

    return { orderSelectionOptions, isExpired: !isFetching && isExpired, isFetching };
};
