import { PageLayout } from '../../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { useSourcingFull, useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../../../utils/routes';
import SolutionConfigurations from './SolutionConfigurations';

export function SolutionConfigurationPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId'>) {
    const { rfqId, sourcingScenarioId } = pathParams;
    const { assemblyId } = queryParams;

    const { data: sourcingScenarioDTO, isLoading: isLoadingSourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: fullSourcingDTO } = useSourcingFull(sourcingScenarioId);

    if (isLoadingSourcingScenario) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    if (!sourcingScenarioDTO) {
        return null;
    }

    return (
        <SolutionConfigurations
            rfqId={rfqId}
            sourcingScenarioDTO={sourcingScenarioDTO}
            fullSourcingDTO={fullSourcingDTO}
            filteredAssemblyId={assemblyId ?? undefined}
        />
    );
}
