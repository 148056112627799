import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
export enum BomExportColumn {
    Designator = 'Designator',
    Quantity = 'Quantity',
    Unit = 'Unit',
    Dnp = 'Dnp',
    Consignment = 'Consignment',
    InternalPartNumber = 'InternalPartNumber',
    IpnDescription = 'IpnDescription',
    CustomerPartNumber = 'CustomerPartNumber',
    Mpn = 'Mpn',
    Manufacturer = 'Manufacturer',
    ManufacturerFree = 'ManufacturerFree',
    PartType = 'PartType',
    Description = 'Description',
    Package = 'Package',
    Mounting = 'Mounting',
    Pins = 'Pins',
    Qualifications = 'Qualifications',
    RohsCompliance = 'RohsCompliance',
    RohsVersion = 'RohsVersion',
    ReachCompliance = 'ReachCompliance',
    LifecycleStatus = 'LifecycleStatus',
    LifecycleYteol = 'LifecycleYteol',
    LifecycleYteolRange = 'LifecycleYteolRange',
    Notes = 'Notes',
    Origin = 'Origin',
    CountryOfOrigin = 'CountryOfOrigin',
    Eccn = 'Eccn',
    HtsCode = 'HtsCode',
    ChinaRohs = 'ChinaRohs',
    Prop65 = 'Prop65',
    ConflictMineralStatus = 'ConflictMineralStatus',
    LatestDatasheetUrl = 'LatestDatasheetUrl',
    LastTimeBuyDate = 'LastTimeBuyDate',
    LastTimeDeliveryDate = 'LastTimeDeliveryDate',
    SvhcCasNumbers = 'SvhcCasNumbers',
    CopyFromOriginalBom = 'CopyFromOriginalBom',
    BomIpn = 'BomIpn',
    BomCpn = 'BomCpn',
    ApprovalStatus = 'ApprovalStatus',
    PartEmissionData = 'PartEmissionData',
}

export const BomExportTemplateRuntype = r.Record({
    id: r.String,
    name: r.String,
    description: r.String.nullable(),
    is_default: r.Boolean,
    included_columns: r.Array(runtypeFromEnum(BomExportColumn)),
    last_modified: r.String,
    aggregate: r.Boolean,
    include_summary_sheet: r.Boolean,
});

export type BomExportTemplate = r.Static<typeof BomExportTemplateRuntype>;

export const BomExportTemplateInputRuntype = r.Record({
    name: r.String,
    description: r.String.nullable(),
    is_default: r.Boolean,
    included_columns: r.Array(runtypeFromEnum(BomExportColumn)),
    aggregate: r.Boolean,
    include_summary_sheet: r.Boolean,
});

export type BomExportTemplateInput = r.Static<typeof BomExportTemplateInputRuntype>;
