import { Trans, t } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    Checkbox,
    Flexbox,
    NonIdealState,
    Radio,
    Tag,
    TanStackTable,
    Text,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { AssemblyDTO, ExtractResponseBody, SolutionTag, SupplierSolutionDTO } from '@luminovo/http-client';
import {
    Solution,
    extractAccumulatedOneTimeCosts,
    extractAdjustedUnitPrice,
    formatAvailability,
    formatSupplierAndStockLocationDTO,
    hasSolutionTag,
    parseSolution,
} from '@luminovo/sourcing-core';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { useMutationUpdateSolutionConfiguration } from '../../../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { useSourcingFullBulk } from '../../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { SourcingScenarioTableData } from '../../SourcingPage/GroupedSourcingScenarioTable/utils/types';

type SourcingScenarioWithSolutionTableData = SourcingScenarioTableData & {
    solution: Solution | undefined;
    solutionConfigurationId: string;
    showAutoSelected: boolean;
};

const columnHelper = createColumnHelper<SourcingScenarioWithSolutionTableData>();

const columns = [
    columnHelper.action({
        id: 'toggle',
        size: 1,
        cell: ({ row }) => {
            return <CheckboxSelectSupplierSolution item={row.original} />;
        },
        enableHiding: false,
    }),
    columnHelper.number('sourcingScenario.name', {
        id: 'name',
        size: 100,
        label: () => t`Sourcing scenario`,
        initialVisibility: false,
    }),
    columnHelper.number('solution.firstPurchaseOption.quantity', {
        size: 100,
        label: () => t`Quantity`,
        cell: ({ row }) => formatDecimal(row.original.solution?.firstPurchaseOption.quantity, { ifAbsent: '-' }),
    }),
    columnHelper.number('solution.availability', {
        size: 90,
        label: () => t`Lead time`,
        cell: (item) =>
            !isPresent(item.row.original.solution) ? '-' : formatAvailability(item.row.original.solution.availability),
    }),
    columnHelper.monetaryValue((row) => extractAdjustedUnitPrice(row), {
        id: 'unitPrice',
        size: 100,
        label: () => t`Unit price`,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue('solution.totalPrice.original_total_price', {
        label: () => t`Total price`,
        size: 100,
        cell: (item) => formatMonetaryValue(item.getValue()),
    }),
    columnHelper.monetaryValue((row) => extractAccumulatedOneTimeCosts(row), {
        id: 'oneTimeCost',
        label: () => t`One-time costs`,
        size: 100,
        cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
    }),
    columnHelper.enum((row) => extractAdjustedUnitPrice(row, 'scaled', 'original')?.currency, {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
    }),
    columnHelper.monetaryValue((row) => extractAdjustedUnitPrice(row, 'scaled', 'original'), {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),
    columnHelper.monetaryValue('solution.totalPrice.original_currency_total_price', {
        id: 'totalPriceOriginalCurrency',
        label: () => t`Total price (original currency)`,
        size: 160,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue()),
    }),
];

const checkIfSolutionIsSelected = (solution: Solution, showAutoSelected: boolean) => {
    const hasManualSelectedSolution = hasSolutionTag(solution, SolutionTag.Selected);
    if (hasManualSelectedSolution) {
        return true;
    }

    if (showAutoSelected) {
        return !hasManualSelectedSolution && hasSolutionTag(solution, SolutionTag.AutoSelected);
    }

    return false;
};

const checkIfThereIsAManuallySelectedSolution = ({
    supplierSolutions,
}: {
    supplierSolutions: SupplierSolutionDTO[];
}) => {
    const suppliersSolutions = supplierSolutions.flatMap((supplier) =>
        supplier.sourcing_scenario_solutions.flatMap((s) => s.solutions),
    );
    const hasManualSelectedSolution = suppliersSolutions.some((solution) =>
        hasSolutionTag(parseSolution(solution), SolutionTag.Selected),
    );

    if (hasManualSelectedSolution) {
        return true;
    }

    return false;
};

const CheckboxSelectSupplierSolution = ({ item }: { item: SourcingScenarioWithSolutionTableData }) => {
    const { mutateAsync: mutateUpdateSolutionConfiguration, isLoading: isUpdatingSolutionConfigurations } =
        useMutationUpdateSolutionConfiguration(item.solutionConfigurationId);

    const isSelected = isPresent(item.solution)
        ? checkIfSolutionIsSelected(item.solution, item.showAutoSelected)
        : false;

    const handleSelectSupplierSolution = async () => {
        await mutateUpdateSolutionConfiguration({
            solutionToken: item.solution?.token,
        });
    };

    return (
        <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (isSelected) {
                    return;
                }
                handleSelectSupplierSolution();
            }}
        >
            {isUpdatingSolutionConfigurations ? (
                <CircularProgress size={20} />
            ) : (
                <Checkbox checked={isSelected} disabled={isUpdatingSolutionConfigurations} />
            )}
        </span>
    );
};

const ButtonSelectSupplier = ({
    items,
    isAllSolutionSelected,
}: {
    items: SourcingScenarioWithSolutionTableData[];
    isAllSolutionSelected: boolean;
}) => {
    const { mutateAsync: mutateUpdateSolutionConfiguration, isLoading: isUpdatingSolutionConfigurations } =
        useHttpMutation('PATCH /solution-configurations', {
            snackbarMessage: t`Updated`,
        });

    const solutionConfigurationIdsAndToken = items.map((item) => ({
        solutionConfigurationId: item.solutionConfigurationId,
        token: item.solution?.token,
    }));

    const handleSelectSupplier = async () => {
        if (isAllSolutionSelected || isUpdatingSolutionConfigurations) {
            return;
        }

        await mutateUpdateSolutionConfiguration({
            requestBody: {
                items: solutionConfigurationIdsAndToken.map(({ solutionConfigurationId, token }) => ({
                    id: solutionConfigurationId,
                    update: {
                        // eslint-disable-next-line camelcase
                        manually_selected_solution_token: token,
                    },
                })),
            },
        });
    };

    return (
        <span style={{ cursor: 'pointer', width: '20px' }} onClick={() => handleSelectSupplier()}>
            {isUpdatingSolutionConfigurations ? (
                <CircularProgress size={18} />
            ) : (
                <Radio checked={isAllSolutionSelected} value={true} disabled={isUpdatingSolutionConfigurations} />
            )}
        </span>
    );
};

const SupplierSolutionsTable = ({
    supplierSolutions,
    showAutoSelected,
}: {
    supplierSolutions: SupplierSolutionDTO;
    showAutoSelected: boolean;
}) => {
    const sourcingScenarioIds = supplierSolutions.sourcing_scenario_solutions.map((item) => item.sourcing_scenario.id);
    const { data: fullSourcingDTOs } = useSourcingFullBulk(sourcingScenarioIds);
    const [showAllOffers, setShowAllOffers] = React.useState(true);

    // All the solutions for the supplier
    const items: SourcingScenarioWithSolutionTableData[] = supplierSolutions.sourcing_scenario_solutions.flatMap(
        (sourcingSolution) => {
            return sourcingSolution.solutions.map((solution) => {
                return {
                    solution: parseSolution(solution),
                    solutionConfigurationId: sourcingSolution.solution_configuration_id,
                    sourcingScenario: sourcingSolution.sourcing_scenario,
                    sourcingFull: fullSourcingDTOs?.find(
                        (full) => full.sourcing_scenario_id === sourcingSolution.sourcing_scenario.id,
                    ),
                    showAutoSelected,
                };
            });
        },
    );

    // The cheapest solutions for the supplier
    const cheapestSolutionsFromSupplier: SourcingScenarioWithSolutionTableData[] =
        supplierSolutions.sourcing_scenario_solutions.map((sourcingSolution) => {
            // At the moment the cheapest offers are assumed to be the first ones on the list
            const firstSolution = sourcingSolution.solutions[0];
            return {
                solution: firstSolution ? parseSolution(firstSolution) : undefined,
                solutionConfigurationId: sourcingSolution.solution_configuration_id,
                sourcingScenario: sourcingSolution.sourcing_scenario,
                sourcingFull: fullSourcingDTOs?.find(
                    (full) => full.sourcing_scenario_id === sourcingSolution.sourcing_scenario.id,
                ),
                showAutoSelected,
            };
        });

    const isAllSolutionSelected =
        items.filter((item) => {
            return item.solution ? checkIfSolutionIsSelected(item.solution, showAutoSelected) : false;
        }).length === sourcingScenarioIds.length;

    const { table } = useTanStackTable({
        columns,
        data: showAllOffers ? items : cheapestSolutionsFromSupplier,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        initialState: {
            columnPinning: {
                left: ['toggle'],
            },
        },
    });

    return (
        <LayoutCard
            title={
                <Flexbox gap={8} alignItems={'center'}>
                    <ButtonSelectSupplier
                        items={cheapestSolutionsFromSupplier}
                        isAllSolutionSelected={isAllSolutionSelected}
                    />
                    <Text variant="h3">{formatSupplierAndStockLocationDTO(supplierSolutions.linked_location)}</Text>
                </Flexbox>
            }
        >
            <Flexbox flexDirection={'column'} gap={'12px'}>
                <Box style={{ height: '280px' }}>
                    <TanStackTable table={table} size="medium" />
                </Box>
                {cheapestSolutionsFromSupplier.length < items.length && (
                    <Flexbox
                        gap={8}
                        alignItems={'center'}
                        onClick={() => {
                            setShowAllOffers(!showAllOffers);
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <Checkbox checked={showAllOffers} size={'small'} />
                        <Text variant="body-small">
                            <Trans>Show all offers</Trans>
                        </Text>
                    </Flexbox>
                )}
            </Flexbox>
        </LayoutCard>
    );
};

export const AssemblySourcingSolutions = ({
    customPartOfferSolution,
    assembly,
}: {
    customPartOfferSolution: ExtractResponseBody<'POST /solutions/custom-part/bulk'>;
    assembly: AssemblyDTO;
}) => {
    const hasManualSelectedSolution = checkIfThereIsAManuallySelectedSolution({
        supplierSolutions: customPartOfferSolution.supplier_solutions,
    });
    // For each supplier, we display a list of their solutions
    return (
        <Flexbox flexDirection={'column'} gap={'16px'}>
            {customPartOfferSolution.supplier_solutions.length === 0 ? (
                <LayoutCard title={undefined}>
                    <CenteredLayout>
                        <NonIdealState
                            description={t`We couldn't find any offers for this part.`}
                            title={t`No offers found`}
                        />
                    </CenteredLayout>
                </LayoutCard>
            ) : (
                <>
                    {customPartOfferSolution.supplier_solutions.map((supplierSolution) => (
                        <SupplierSolutionsTable
                            key={supplierSolution.linked_location.id}
                            supplierSolutions={supplierSolution}
                            showAutoSelected={hasManualSelectedSolution === false}
                        />
                    ))}
                </>
            )}
        </Flexbox>
    );
};
