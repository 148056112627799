import { AssemblyResponseDTO } from '@luminovo/http-client';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../utils/rfqUtils';

export function useIsAssemblyEditable(assembly?: AssemblyResponseDTO) {
    const { data: rfq } = useRfQ(assembly?.rfq ?? '', Boolean(assembly?.rfq));
    const status = rfq?.status;
    const isArchived = rfq?.is_archived;
    const { isRfqEditable } = useIsRfqEditable(status, isArchived, rfq?.workflow_type);
    if (assembly && assembly.rfq) {
        return isRfqEditable;
    }
    return false;
}
