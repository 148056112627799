import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import React from 'react';

interface State {
    selectedPdf?: {
        fileName: string;
        regions: Array<Region<PcbAttribute>>;
    };
}

const PcbModuleContext = React.createContext<[State, React.Dispatch<React.SetStateAction<State>>] | undefined>(
    undefined,
);

export function PcbModuleContextProvider({ children }: React.PropsWithChildren<{}>) {
    const [state, setState] = React.useState<State>({
        selectedPdf: undefined,
    });
    const value: [State, React.Dispatch<React.SetStateAction<State>>] = React.useMemo(() => [state, setState], [state]);
    return <PcbModuleContext.Provider value={value}>{children}</PcbModuleContext.Provider>;
}

export function usePcbModuleContext() {
    const context = React.useContext(PcbModuleContext);
    if (context === undefined) {
        throw new Error('usePcbModuleContext must be used within a PcbModuleContextProvider');
    }
    return context;
}
