import { PartSuggestionFull } from '@luminovo/http-client';
import { useMemo } from 'react';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { getCandidateMpns } from '../../../../resources/designItem/getCandidateMpns';

export const useBomItemCandidateMpns = (bomItem: BomItem, suggestions: PartSuggestionFull[]) => {
    return useMemo(() => {
        return getCandidateMpns(bomItem, suggestions);
    }, [bomItem, suggestions]);
};
