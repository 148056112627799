import { t } from '@lingui/macro';
import { DriverIdDTO } from '@luminovo/http-client';
import { formatDriverIdPath } from '@luminovo/manufacturing-core';
import { SubmitHandler } from 'react-hook-form';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { EditDriverCountFormState } from './EditDriverCount';

export const useCreateMutationUpdateBasicDriverCount = () => {
    const { mutateAsync: updateDriverCount } = useHttpMutation(
        'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId',
        {
            snackbarMessage: t`Driver count updated`,
        },
    );

    const { mutateAsync: deleteDriverCount } = useHttpMutation(
        'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId',
        {
            snackbarMessage: t`Driver count deleted`,
        },
    );

    return (assemblyDetailsId: string, driverId: DriverIdDTO): SubmitHandler<EditDriverCountFormState> => {
        const pathParams = { assemblyDetailsId, driverId: formatDriverIdPath(driverId) };

        return (form) => {
            if (form.shouldAutomaticDriverCountBeReset) {
                return deleteDriverCount({
                    pathParams,
                    /* eslint-disable-next-line camelcase */
                    queryParams: { apply_to_all_activities_and_expenses: form.isAppliedToAllActivitiesAndExpenses },
                });
            }

            return updateDriverCount({
                pathParams,
                /* eslint-disable camelcase */
                queryParams: { apply_to_all_activities_and_expenses: form.isAppliedToAllActivitiesAndExpenses },
                requestBody: { driver_count: Number(form.count) },
                /* eslint-enable camelcase */
            });
        };
    };
};

export const useCreateMutationUpdatePerScenarioDriverCount = (): ((
    assemblyDetailsId: string,
    driverId: DriverIdDTO,
    sourcingScenarioId: string,
) => SubmitHandler<EditDriverCountFormState>) => {
    const { mutateAsync: deleteDriverCount } = useHttpMutation(
        'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId',
        {
            snackbarMessage: t`Driver count deleted`,
        },
    );
    const { mutateAsync: updateDriverCount } = useHttpMutation(
        'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId',
        {
            snackbarMessage: t`Driver count updated`,
        },
    );

    return (
        assemblyDetailsId: string,
        driverId: DriverIdDTO,
        sourcingScenarioId: string,
    ): SubmitHandler<EditDriverCountFormState> => {
        const pathParams = { assemblyDetailsId, sourcingScenarioId, driverId: formatDriverIdPath(driverId) };

        return (form) => {
            if (form.shouldAutomaticDriverCountBeReset) {
                return deleteDriverCount({
                    pathParams,
                    /* eslint-disable-next-line camelcase */
                    queryParams: { apply_to_all_activities_and_expenses: form.isAppliedToAllActivitiesAndExpenses },
                });
            }

            return updateDriverCount({
                pathParams,
                /* eslint-disable camelcase */
                queryParams: { apply_to_all_activities_and_expenses: form.isAppliedToAllActivitiesAndExpenses },
                requestBody: { driver_count: Number(form.count) },
                /* eslint-enable camelcase */
            });
        };
    };
};
