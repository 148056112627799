import { colorSystem } from '@luminovo/design-system';
import { StateFileType } from './layerAssignmentsUtils';

export type GerberFileTypes = Exclude<
    StateFileType,
    StateFileType.MiscellaneousFiles | StateFileType.MechanicalFiles | StateFileType.StackupFiles
>;

// Sorting order will be natural array order
export const GERBER_LAYERS: GerberFileTypes[] = [
    StateFileType.PasteTop,
    StateFileType.SilkscreenTop,
    StateFileType.SoldermaskTop,
    StateFileType.Copper,
    StateFileType.SoldermaskBottom,
    StateFileType.SilkscreenBottom,
    StateFileType.PasteBottom,
];

export const gerberLayerColors: Record<GerberFileTypes, { color: keyof typeof colorSystem }> = {
    [StateFileType.PasteTop]: { color: 'neutral' },
    [StateFileType.SilkscreenTop]: { color: 'red' },
    [StateFileType.SoldermaskTop]: { color: 'green' },
    [StateFileType.Copper]: { color: 'yellow' },
    [StateFileType.SoldermaskBottom]: { color: 'green' },
    [StateFileType.SilkscreenBottom]: { color: 'red' },
    [StateFileType.PasteBottom]: { color: 'neutral' },
};
