import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ManufacturingScenarioTemplateStatusDTO } from '@luminovo/http-client';

export const manufacturingScenarioTemplateStatusEnumPublicTranslations: Record<
    ManufacturingScenarioTemplateStatusDTO,
    MessageDescriptor
> = {
    Active: defineMessage({ message: 'Active' }),
    Inactive: defineMessage({ message: 'Inactive' }),
};
