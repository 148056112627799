import { Trans, t } from '@lingui/macro';
import { CenteredLayout, Flexbox, MaxWidthLayout, Text, Toolbar } from '@luminovo/design-system';
import { Avatar } from '../../../components/Navbar/Avatar';
import { PageLayout } from '../../../components/PageLayout';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { route } from '../../../utils/routes';
import { UpsellBox } from './components/UpsellBox';

/***
 * @deprecated this quote portal is no longer used, the new one is in the negotiations module.
 */
// eslint-disable-next-line camelcase
export function Deprecated_SupplierPortalPage() {
    const { organization } = useCurrentUserDetailsContext();
    return (
        <PageLayout
            navbar={
                <Toolbar
                    breadcrumbs={[
                        {
                            title: t`Quote portal`,
                            href: route('/quote-portal'),
                        },
                        {
                            title: organization.name,
                            href: route('/quote-portal'),
                        },
                    ]}
                >
                    <Avatar variant="light" style={{ margin: 0 }} />
                </Toolbar>
            }
        >
            <CenteredLayout height={'100%'}>
                <MaxWidthLayout innerMaxWidth={500}>
                    <Flexbox flexDirection={'column'} gap="20px">
                        <Text variant="h1">
                            <Trans>Welcome to {organization.name}'s quote portal</Trans>
                        </Text>
                        <Text>
                            <Trans>
                                Welcome to {organization.name}'s quote portal! Please check your email now to see if
                                there are any new quotes waiting for you.
                            </Trans>
                        </Text>
                        <UpsellBox />
                    </Flexbox>
                </MaxWidthLayout>
            </CenteredLayout>
        </PageLayout>
    );
}
