import { Trans, t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { Card, Flexbox, Text } from '@luminovo/design-system';
import { DriversOfManufacturingDetailsDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { compareByDriverTypeAndName, selectDriverIdString, selectDriverName } from '@luminovo/manufacturing-core';
import React from 'react';
import { ManufacturingCacheRefreshButton } from '../../../../../components/ManufacturingCache/ManufacturingCacheRefreshButton';
import { SearchBar } from '../../../../../components/SearchBars/SearchBar';
import { SelectableDriverRow } from './DriverRowComponents/SelectableDriverRow';

export const DriversCard = ({
    rfqId,
    manufacturingAssemblyDetails,
    drivers,
    assemblyName,
    selectedDriverId,
    setSelectedDriver,
}: {
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    drivers: DriversOfManufacturingDetailsDTO[];
    assemblyName: string;
    selectedDriverId: string | undefined;
    setSelectedDriver: React.Dispatch<React.SetStateAction<DriversOfManufacturingDetailsDTO | undefined>>;
}): JSX.Element => {
    const [searchText, setSearchText] = React.useState<string>('');
    return (
        <Card maxWidth="100%" id={id('manufacturing_scenario/drivers_list')}>
            <Flexbox justifyContent="space-between">
                <Text
                    variant="h2"
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Trans>Drivers</Trans>
                    {assemblyName && (
                        <>
                            {' '}
                            {/* space above is intentional */}
                            <Trans> for {assemblyName}</Trans>
                        </>
                    )}
                </Text>
                <ManufacturingCacheRefreshButton assemblyId={manufacturingAssemblyDetails.assembly_id} />
            </Flexbox>
            <SearchBar
                isLoading={false}
                hasTextChanged
                placeholder={t`Search for a driver`}
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                onSubmit={() => {}}
            />
            <Flexbox gap={12} flexDirection={'column'} maxHeight={'500px'} overflow={'auto'} padding="4px">
                {drivers
                    .filter((d) => selectDriverName(d.driver).toLowerCase().includes(searchText.toLowerCase()))
                    .sort(compareByDriverTypeAndName)
                    .map((driver) => (
                        <SelectableDriverRow
                            isSelected={selectedDriverId === selectDriverIdString(driver.driver)}
                            onClick={() => setSelectedDriver(driver)}
                            key={selectDriverIdString(driver.driver)}
                            driverDetails={driver}
                            rfqId={rfqId}
                            manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                        />
                    ))}
            </Flexbox>
        </Card>
    );
};
