import { t } from '@lingui/macro';
import { CustomerPortalRequirement, ManufacturingState } from '@luminovo/http-client';
import { StepContainer } from './StepContainer';

export const ManufacturingStep = ({
    manufacturingState,
    rfqId,
    assemblyId,
    isRfqEditable,
    requirement,
}: {
    manufacturingState: ManufacturingState;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    requirement: CustomerPortalRequirement;
}): JSX.Element => {
    const status = manufacturingState.status.type;

    return (
        <StepContainer
            status={status}
            label={t`Manufacturing files`}
            type="manufacturing"
            rfqId={rfqId}
            assemblyId={assemblyId}
            isRfqEditable={isRfqEditable}
            requirement={requirement}
        />
    );
};
