import { t } from '@lingui/macro';
import { get, throwErrorUnlessProduction } from '@luminovo/commons';
import {
    colorSystem,
    FieldCheckboxControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    TertiaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { PCBV2, PCBV2BoardPropertiesValuesDTO, UnitDTO } from '@luminovo/http-client';
import { InfoRounded, Replay as ReplayIcon } from '@mui/icons-material';
import { InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { ColorDropDownFormItem, SolderMaskColors } from '../../../../components/formLayouts/ColorDropDown';
import { transEnum } from '../../../../components/localization/TransEnum';
import { capabilitiesTranslations, propertyDescriptionTranslations } from '../../../../resources/pcb/i18n';
import { Capability, REQUIRED_PCB_FIELDS } from '../../../../resources/pcb/pcbFunctions';
import { assertArray } from '../../../../utils/assertArray';
import { PcbFormToggleButton, PcbFormToggleButtonGroup } from '../PcbFormToggleButton';
import { InspectPdfButton } from '../PcbPdfExtraction/InspectPdfButton';
import { formatOptionLabel, formatUnit } from './pcbFormItemUtils';
import { useTrackCapabilitiesDependency } from './useTrackCapabilitiesDependency';

interface PcbFormItemProps {
    capability: Capability;
    disabled?: boolean;
    style?: React.CSSProperties;
    extractedValue?: PCBV2BoardPropertiesValuesDTO;
    dependencies?: Capability['fieldName'][];
}

const usePcbFormItem = ({
    capability,
    extractedValue,
}: {
    capability: Capability;
    extractedValue?: PCBV2BoardPropertiesValuesDTO;
}) => {
    const { control, setValue, resetField } = useFormContext<PCBV2['properties']>();
    const { capabilityName, name, fieldName } = capability;

    const value = useWatch({
        control,
        name: fieldName,
    });

    const nameValue = useWatch({
        control,
        name,
    });

    const unit = nameValue && typeof nameValue === 'object' && 'unit' in nameValue ? nameValue.unit : undefined;
    const dependencyHasAValue = useTrackCapabilitiesDependency(capability);
    const isRequired = dependencyHasAValue ? true : REQUIRED_PCB_FIELDS.includes(capabilityName);

    const handleReset = React.useCallback(() => {
        resetField(fieldName);
        setValue(fieldName, extractedValue);
    }, [resetField, setValue, fieldName, extractedValue]);

    return {
        handleReset,
        isRequired,
        value: value?.toString(),
        unit,
        control,
        shouldShowField: dependencyHasAValue ?? true,
    };
};

export function PcbFormItem({
    capability,
    disabled = false,
    style,
    extractedValue: extractedValueProperty,
}: PcbFormItemProps) {
    const extractedValue =
        typeof extractedValueProperty === 'object' && 'value' in extractedValueProperty
            ? extractedValueProperty.value.toString()
            : extractedValueProperty?.toString();
    const { control, handleReset, isRequired, unit, value, shouldShowField } = usePcbFormItem({
        capability,
        extractedValue,
    });

    if (!shouldShowField) {
        return null;
    }

    return (
        <PcbFormItemInner
            {...{
                capability,
                disabled,
                style,
                isRequired,
                control,
                value,
                unit,
                extractedValue,
            }}
            fieldType={getFieldType(capability)}
            onReset={handleReset}
        />
    );
}

type FieldType = 'checkbox' | 'number' | 'select' | 'color-select' | 'button-select' | 'unknown';

interface PcbFormItemInnerProps extends PcbFormItemProps {
    fieldType: FieldType;
    isRequired: boolean;
    control: Control<PCBV2['properties']>;
    value: string | undefined;
    unit: UnitDTO | undefined;
    onReset: () => void;
}

const PcbFormItemInner = ({
    capability,
    disabled = false,
    style,
    fieldType,
    isRequired,
    control,
    value,
    unit,
    onReset,
    extractedValue,
}: PcbFormItemInnerProps) => {
    const { restrictions, capabilityName, fieldName: name } = capability;
    const actions = (
        <Flexbox>
            <InspectPdfButton capability={capability} />
            <ResetToExtractedValueButton
                onResetToExtractedValue={onReset}
                extractedValue={extractedValue}
                value={value}
            />
        </Flexbox>
    );

    switch (fieldType) {
        case 'checkbox': {
            const description = transEnum(String(capabilityName), propertyDescriptionTranslations);
            return (
                <Flexbox gap={'4px'} alignItems={'center'}>
                    <label
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center',
                        }}
                    >
                        <FieldCheckboxControlled
                            control={control}
                            name={name}
                            FieldProps={{ disabled, size: 'small', style: { marginBlockEnd: '4px' } }}
                        />
                        <Text variant="h5">{transEnum(String(capabilityName), capabilitiesTranslations)}</Text>
                        {description && (
                            <Tooltip title={description} arrow>
                                <InfoRounded
                                    style={{
                                        cursor: 'pointer',
                                        color: colorSystem.neutral[5],
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </label>
                    {actions && <span style={{ marginLeft: 'auto' }}>{actions}</span>}
                </Flexbox>
            );
        }

        case 'number': {
            const min = 'min' in restrictions ? restrictions.min : undefined;
            const max = 'max' in restrictions ? restrictions.max : undefined;

            if (get(restrictions, 'disabled', false)) {
                return null;
            }

            return (
                <FormItem
                    style={style}
                    key={capabilityName}
                    label={`${transEnum(String(capabilityName), capabilitiesTranslations)}${isRequired ? '*' : ''}`}
                    LabelProps={{ variant: 'h5' }}
                    description={transEnum(String(capabilityName), propertyDescriptionTranslations)}
                    actions={actions}
                >
                    {disabled ? (
                        <Flexbox style={{ paddingLeft: '5px', minHeight: '20px' }} alignItems="center" gap={8}>
                            <Typography
                                variant="body2"
                                style={{
                                    color: colorSystem.neutral[8],
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}
                            >
                                {value !== undefined ? `${value} ${formatUnit(unit)}` : '-'}
                            </Typography>
                        </Flexbox>
                    ) : (
                        <FieldNumericControlled
                            control={control}
                            required={isRequired}
                            name={name}
                            min={min}
                            max={max}
                            FieldProps={{
                                InputProps: {
                                    endAdornment: unit && (
                                        <InputAdornment position="end">{formatUnit(unit)}</InputAdornment>
                                    ),
                                },
                                style: { width: '100%' },
                                size: 'small',
                            }}
                        />
                    )}
                </FormItem>
            );
        }

        case 'select': {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const options: (number | string)[] = restrictions.allowed as (number | string)[];

            return (
                <FormItem
                    style={style}
                    key={capabilityName}
                    label={`${transEnum(String(capabilityName), capabilitiesTranslations)}${isRequired ? '*' : ''}`}
                    LabelProps={{ variant: 'h5' }}
                    description={transEnum(String(capabilityName), propertyDescriptionTranslations)}
                    actions={actions}
                >
                    {disabled ? (
                        <Flexbox style={{ paddingLeft: '5px' }} alignItems="center" gap={8}>
                            <Typography
                                variant="body2"
                                style={{ color: colorSystem.neutral[8], fontWeight: 400, fontSize: '14px' }}
                            >
                                {value && formatOptionLabel(name, value, unit)}
                            </Typography>
                        </Flexbox>
                    ) : (
                        <FieldSelectControlled
                            name={name}
                            control={control}
                            required={isRequired}
                            FieldProps={{
                                options,
                                disableClearable: true,
                                size: 'small',
                                getOptionLabel: (option) => formatOptionLabel(name, option, unit),
                                renderOption: (option) => formatOptionLabel(name, option, unit),
                            }}
                            validate={
                                name === 'board.placementSide'
                                    ? (x) => {
                                          if (typeof x === 'string' && x === 'none') {
                                              return t`Required`;
                                          }
                                      }
                                    : undefined
                            }
                        />
                    )}
                </FormItem>
            );
        }

        case 'color-select': {
            const options: SolderMaskColors[] = assertArray(restrictions.allowed);

            return (
                <ColorDropDownFormItem
                    disabled={disabled}
                    style={style}
                    key={capabilityName}
                    name={name}
                    label={transEnum(capabilityName, capabilitiesTranslations)}
                    description={transEnum(capabilityName, propertyDescriptionTranslations)}
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    color={value as SolderMaskColors}
                    control={control}
                    options={options}
                    required={isRequired}
                    actions={actions}
                />
            );
        }

        case 'button-select': {
            // eslint-disable-next-line spellcheck/spell-checker
            const options = ['rigid', 'flex', 'rigid-flex', 'ims'];

            if (disabled) {
                return <Text variant="body">{value && formatOptionLabel(name, value, unit)}</Text>;
            }

            return (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                        return (
                            <PcbFormToggleButtonGroup
                                exclusive
                                fullWidth
                                size="small"
                                value={value}
                                defaultValue={value}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                            >
                                {options.map((value, index) => (
                                    <PcbFormToggleButton key={index} value={value}>
                                        <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                                            {formatOptionLabel(name, value, unit)}
                                        </Text>
                                    </PcbFormToggleButton>
                                ))}
                            </PcbFormToggleButtonGroup>
                        );
                    }}
                />
            );
        }

        case 'unknown':
        default: {
            throwErrorUnlessProduction(new Error('Unreachable code'));
            return null;
        }
    }
};

const ResetToExtractedValueButton = ({
    onResetToExtractedValue,
    extractedValue,
    value,
}: {
    onResetToExtractedValue: () => void;
    extractedValue: PCBV2BoardPropertiesValuesDTO | undefined;
    value: string | number | undefined;
}) => {
    if (extractedValue === undefined || String(extractedValue) === value) {
        return null;
    }

    return (
        <Tooltip title={t`Reset to extracted value`}>
            <TertiaryIconButton
                size="small"
                style={{ padding: 0, cursor: 'pointer' }}
                onClick={onResetToExtractedValue}
            >
                <ReplayIcon fontSize="inherit" />
            </TertiaryIconButton>
        </Tooltip>
    );
};

function getFieldType(capability: Capability): FieldType {
    const { restrictions, capabilityName, type } = capability;

    if (type.tag === 'record') {
        return Array.isArray(restrictions.allowed) ? 'select' : 'number';
    }

    if (capabilityName === 'layerstackType') {
        return 'button-select';
    }

    if (type.tag === 'boolean') {
        return 'checkbox';
    }

    if (type.tag === 'number') {
        return 'number';
    }

    if (Array.isArray(restrictions.allowed)) {
        // eslint-disable-next-line spellcheck/spell-checker
        if (capabilityName === 'silkscreenColor' || capabilityName === 'soldermaskColor') {
            return 'color-select';
        }
        return 'select';
    }

    return 'unknown';
}
