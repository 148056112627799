import { Trans } from '@lingui/macro';
import { Column, columnWidth, renderTextCell, Row } from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { RightAlignedStickyTableCell } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { TransEnum } from '../../../components/localization/TransEnum';
import { resourceTypeEnumPublicTranslations } from '../../../resources/resource/resourceBackendTypes';
import { formatCurrency } from '../../../utils/currencyUtils';
import { ResourceMenuComponent } from './resourcesTableComponents';

const resourceTypeColumn: Column<ResourceDTO> = {
    label: <Trans>Type</Trans>,
    id: 'type',
    render: ({ data: rowData }: Row<ResourceDTO>) =>
        renderTextCell(<TransEnum text={rowData.type} translations={resourceTypeEnumPublicTranslations} />),
    width: columnWidth.medium,
    filters: [
        {
            id: 'type=Machine',
            label: <Trans>Machine</Trans>,
            predicate: (resource) => resource.type === 'Machine',
        },
        {
            id: 'type=Person',
            label: <Trans>Person</Trans>,
            predicate: (resource) => resource.type === 'Person',
        },
    ],
};

const costPerHourColumn: Column<ResourceDTO> = {
    label: <Trans>Cost/hour</Trans>,
    id: `costPerHour`,
    render: ({ data: rowData }: Row<ResourceDTO>) =>
        renderTextCell(
            formatCurrency(rowData.cost_per_hour.converted_mv.amount, rowData.cost_per_hour.converted_mv.currency),
        ),
    width: columnWidth.medium,
    /* eslint-disable camelcase*/
    comparators: [
        {
            id: 'costPerHour.asc',
            label: <Trans>Ascending</Trans>,
            compareBy: ({ cost_per_hour }) => Number(cost_per_hour.converted_mv.amount),
            order: 'asc',
        },
        {
            id: 'costPerHour.desc',
            label: <Trans>Descending</Trans>,
            compareBy: ({ cost_per_hour }) => Number(cost_per_hour.converted_mv.amount),
            order: 'desc',
        },
        /* eslint-enable camelcase*/
    ],
};

const resourceTableActions = (canEditManufacturingDatabase: boolean): Column<ResourceDTO> => ({
    label: '', // intentionally blank
    id: `resourcesActionsMenu`,
    renderHead: () => <RightAlignedStickyTableCell />,
    render: ({ data: rowData }: Row<ResourceDTO>): JSX.Element => {
        return canEditManufacturingDatabase ? (
            <ResourceMenuComponent resourceId={rowData.id} status={rowData.status} />
        ) : (
            <TableCell />
        );
    },
    width: columnWidth.small,
});

export { costPerHourColumn, resourceTableActions, resourceTypeColumn };
