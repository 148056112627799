import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';

enum Position {
    procurement = 'procurement',
    development = 'development',
    other = 'other',
}

export const positionTranslations: Record<Position, MessageDescriptor> = {
    [Position.procurement]: defineMessage({ message: 'Procurement' }),
    [Position.development]: defineMessage({ message: 'Development' }),
    [Position.other]: defineMessage({ message: 'Other' }),
};
