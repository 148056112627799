import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { AlertDTORuntype, CreateAlertRequestBodyRuntype, UpdateAlertRequestBodyRuntype } from './alertBackendTypes';

export const alertsEndpoints = {
    'GET /alerts/stock-level': endpoint({
        description: 'Returns all alerts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(AlertDTORuntype),
        }),
    }),

    'POST /alerts/stock-level': endpoint({
        description: 'Creates a new alert',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CreateAlertRequestBodyRuntype,
        responseBody: r.Record({
            data: AlertDTORuntype,
        }),
        invalidates: ['GET /alerts/stock-level'],
    }),

    'PATCH /alerts/stock-level/:id': endpoint({
        description: 'Update an existing alert',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: UpdateAlertRequestBodyRuntype,
        responseBody: r.Record({
            data: AlertDTORuntype,
        }),
        invalidates: ['GET /alerts/stock-level', 'GET /alerts/stock-level/:id'],
    }),

    'GET /alerts/stock-level/:id': endpoint({
        description: 'Returns an alert',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: AlertDTORuntype,
        }),
    }),

    'DELETE /alerts/stock-level/:id': endpoint({
        description: 'Delete an existing alert',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
        invalidates: ['GET /alerts/stock-level'],
        removes: ['GET /alerts/stock-level/:id'],
    }),
};
