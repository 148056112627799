import { CheckCircleRounded, ErrorRounded, InfoRounded, WarningRounded } from '@mui/icons-material';
import { styled } from '@mui/material';
import { MaterialDesignContent, SnackbarProviderProps } from 'notistack';
import { colorSystem, h4 } from '../../theme';

const SnackbarContent = styled(MaterialDesignContent)(() => ({
    '& #notistack-snackbar': {
        padding: '0px',
    },
    '&.notistack-MuiContent': {
        ...h4,
        lineHeight: '24px',
        borderRadius: 8,
        padding: 16,
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: colorSystem.green[8],
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: colorSystem.neutral[9],
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: colorSystem.red[8],
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: colorSystem.yellow[8],
    },
}));

//taken from https://github.com/iamhosseindhv/notistack/blob/122f47057eb7ce5a1abfe923316cf8475303e99a/src/utils/defaultIconVariants.tsx#L3C1-L19C3
const SvgIcon = (props: { children: JSX.Element }) => (
    <svg
        viewBox="0 0 24 24"
        focusable="false"
        style={{
            fontSize: 20,
            marginInlineEnd: 8,
            userSelect: 'none',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fill: 'currentColor',
            flexShrink: 0,
        }}
        {...props}
    />
);

export const DefaultSnackbarProviderProps: SnackbarProviderProps = {
    Components: {
        default: SnackbarContent,
        error: SnackbarContent,
        info: SnackbarContent,
        success: SnackbarContent,
        warning: SnackbarContent,
    },
    autoHideDuration: 6000,
    maxSnack: 8,
    anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
    },
    iconVariant: {
        info: <SvgIcon children={<InfoRounded style={{ color: colorSystem.blue[1] }} />} />,
        warning: <SvgIcon children={<WarningRounded style={{ color: colorSystem.yellow[1] }} />} />,
        success: <SvgIcon children={<CheckCircleRounded style={{ color: colorSystem.green[1] }} />} />,
        error: <SvgIcon children={<ErrorRounded style={{ color: colorSystem.red[1] }} />} />,
    },
};
