import { Trans, t } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, isPresent } from '@luminovo/commons';
import {
    Flexbox,
    NonIdealState,
    PrimaryButton,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    AvailabilityType,
    OtsComponentFull,
    OtsFullPart,
    Packaging,
    QuoteTrackingDTO,
    RfqContext,
    SolutionTag,
} from '@luminovo/http-client';
import { AvailabilityChip, LabelPart, formatPackaging, formatPart } from '@luminovo/sourcing-core';
import { Notes, Upload } from '@mui/icons-material';
import React from 'react';
import { useStandardPartsFromOffers } from '../../../../../resources/part/partHandler';
import { route } from '../../../../../utils/routes';
import { useOfferDrawer } from '../../../../SolutionManager/components/OfferDrawer';
import { PdfQuoteImportButton } from '../../PdfOfferExtractorDialog';
import { OfferMetricTag, QuoteTrackingMetricSettings } from '../QuoteTrackingMetricCards';

type QuotedStandardPartOfferTableItem = {
    linkedPart: OtsFullPart | OtsComponentFull;
    offer: QuoteTrackingDTO['standard_part_offers'][number];
    metricSettings: QuoteTrackingMetricSettings;
};

type QuotedStandardPartOfferTableSharedContext = {
    quoteTracking: QuoteTrackingDTO;
};

const columnHelper = createColumnHelper<QuotedStandardPartOfferTableItem>();

const columns = [
    columnHelper.enum((row) => formatPart(row.linkedPart), {
        id: 'part',
        size: 180,
        label: () => t`Part`,
        renderType: 'text',
        getOptionLabel: (option) => option,
        cell: ({ row }) => <LabelPart part={row.original.linkedPart} />,
    }),

    columnHelper.text('offer.supplier_part_number', {
        size: 120,
        label: () => t`SKU`,
    }),
    columnHelper.enum((row) => row.offer.packaging, {
        id: 'package',
        size: 100,
        label: () => t`Packaging`,
        options: Object.values(Packaging),
        getOptionLabel: (option) => formatPackaging(option),
        cell: (item) => {
            if (!isPresent(item.getValue())) {
                return (
                    <Text color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <Tag color={'neutral'} attention={'low'} label={formatPackaging(item.getValue())} />;
        },
    }),
    columnHelper.number(({ offer }) => offer.available_prices.price_breaks[0].moq, {
        label: () => t`MOQ`,
        id: 'moq',
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number('offer.available_prices.factory_lead_time_days', {
        label: () => t`Lead time`,
        size: 90,
        cell: ({ row }) => {
            if (!isPresent(row.original.offer.available_prices.factory_lead_time_days)) {
                return (
                    <AvailabilityChip
                        solution={{
                            solutionTags: [{ tag: SolutionTag.UnknownLeadTime }],
                            availability: null,
                        }}
                    />
                );
            }

            return (
                <AvailabilityChip
                    solution={{
                        solutionTags: [],
                        availability: {
                            type: AvailabilityType.LeadTime,
                            days: row.original.offer.available_prices.factory_lead_time_days,
                        },
                    }}
                />
            );
        },
    }),
    columnHelper.number('offer.available_prices.stock', {
        label: () => t`Stock`,
        size: 100,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue(({ offer }) => offer.available_prices.price_breaks[0].unit_price, {
        label: () => t`Unit price`,
        id: 'unitPrice',
        size: 90,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),

    columnHelper.action({
        id: 'icons',
        size: 50,
        cell: ({ row }) => (
            <Flexbox justifyContent={'center'}>
                <NotesIcon notes={row.original.offer.notes} />
                <OfferMetricTag offer={row.original.offer} metricSettings={row.original.metricSettings} />
            </Flexbox>
        ),
    }),
];

const NotesIcon: React.FunctionComponent<{ notes: string | null | undefined }> = ({ notes }) => {
    if (!Boolean(notes) || !isPresent(notes)) {
        return <></>;
    }

    return (
        <Flexbox justifyContent={'center'}>
            <Tooltip
                variant={'white'}
                title={
                    <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {notes}
                        </Text>
                    </Flexbox>
                }
            >
                <Notes
                    style={{
                        color: colorSystem.neutral[6],
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '24px',
                        padding: '2px 8px',
                        fontSize: '16px',
                    }}
                />
            </Tooltip>
        </Flexbox>
    );
};

const EmptyPlaceholder: React.FunctionComponent<{ sharedContext: QuotedStandardPartOfferTableSharedContext }> = ({
    sharedContext,
}) => {
    const ActionButton = React.useMemo(
        () => () => (
            <Flexbox gap={'8px'}>
                <PdfQuoteImportButton quoteTracking={sharedContext.quoteTracking} />

                <PrimaryButton
                    size="small"
                    startIcon={<Upload />}
                    href={route('/rfqs/:rfqId/sourcing/quote-importer', {
                        rfqId: sharedContext.quoteTracking.rfq,
                    })}
                >
                    <Trans>Upload quote</Trans>
                </PrimaryButton>
            </Flexbox>
        ),
        [sharedContext.quoteTracking],
    );

    return (
        <NonIdealState
            title={'Nothing to show'}
            description={t`You didn't import any quotes yet`}
            overrides={{ ActionButton }}
        />
    );
};

export const QuotedStandardPartOfferTab: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ quoteTracking, metricSettings }) => {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: quoteTracking.rfq };

    const { openOfferDrawer } = useOfferDrawer();
    const { data: standardParts, isLoading } = useStandardPartsFromOffers(
        quoteTracking.standard_part_offers,
        rfqContext,
    );

    const data = React.useMemo(
        () =>
            quoteTracking.standard_part_offers.flatMap((offer) => {
                const linkedPart = standardParts?.find((part) => part.id === offer.linked_part.id);
                return linkedPart ? { linkedPart, offer, metricSettings } : [];
            }),
        [quoteTracking.standard_part_offers, standardParts, metricSettings],
    );

    const { table } = useTanStackTable({
        columns,
        data,
        isLoading: isLoading,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        onRowClick: (row) => {
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext,
                fallbackSolutionTags: undefined,
            });
        },
        sharedContext: { quoteTracking },
    });

    return (
        <Flexbox flexDirection={'column'} gap={'12px'} height={'65vh'}>
            <TanStackTable<QuotedStandardPartOfferTableItem, QuotedStandardPartOfferTableSharedContext>
                table={table}
                size={'medium'}
                EmptyPlaceholder={EmptyPlaceholder}
            />
        </Flexbox>
    );
};
