import { ResourceDTO } from '@luminovo/http-client';
import { RecursivePartial } from '../../../../utils/typingUtils';
import { ResourceFormValues } from './resourceFormValues';

export const convertResourceDTOToDefaultValues = (resource: ResourceDTO): RecursivePartial<ResourceFormValues> => {
    return {
        name: resource.name,
        internalNumber: resource.internal_number ?? undefined,
        description: resource.description ?? undefined,
        type: resource.type,
        costPerHour: resource.cost_per_hour.converted_mv.amount,
        costPerHourCurrency: resource.cost_per_hour.converted_mv.currency,
        site: resource.site_id ?? undefined,
    };
};
