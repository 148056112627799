import { logToExternalErrorHandlers } from '../errors';

export function assertUnreachable(x: never): never {
    const error = new Error(
        'Code that seemed unreachable at compile-time was executed. ' +
            'This may indicate a mismatch between type annotations and actual types at runtime. ' +
            'Unexpected data encountered: ' +
            JSON.stringify(x),
    );
    logToExternalErrorHandlers(error);
    throw error;
}
