import { isPresent } from '@luminovo/commons';
import { MonetaryValueBackend } from '@luminovo/http-client';
import { Solution } from '../../types';

/**
 * Extracts the unit price from a solution.
 *
 * This function can return either the original unit price or a scaled unit price,
 * depending on the 'extract' parameter. The scaled unit price is calculated by
 * dividing the unit price by the quantity of the first purchase option.
 *
 * Note: The unit of measurement is usually 1, so in most cases, the scaled and
 * original unit prices will be the same.
 */
export function extractUnitPrice({
    solution,
    extract,
    currency = 'preferred',
}: {
    solution: Pick<Solution, 'unitPrice' | 'firstPurchaseOption'>;
    extract: 'scaled' | 'non-scaled';
    currency?: 'preferred' | 'original';
}): number {
    switch (true) {
        case extract === 'scaled' && currency === 'preferred':
            return Number(solution.unitPrice?.amount ?? 0) / (solution.firstPurchaseOption.unit.quantity ?? 0);
        case extract === 'scaled' && currency === 'original':
            return (
                Number(solution.firstPurchaseOption?.unit_price_original?.amount ?? 0) /
                (solution.firstPurchaseOption.unit.quantity ?? 0)
            );
        case extract === 'non-scaled' && currency === 'preferred':
            return Number(solution.unitPrice?.amount ?? 0);
        case extract === 'non-scaled' && currency === 'original':
            return Number(solution.firstPurchaseOption?.unit_price_original?.amount ?? 0);
        default:
            throw new Error('Invalid parameters');
    }
}

export function extractAdjustedUnitPrice(
    input: { solution: Pick<Solution, 'unitPrice' | 'firstPurchaseOption'> | undefined },
    extract: 'scaled' | 'non-scaled' = 'scaled',
    currency: 'preferred' | 'original' = 'preferred',
): MonetaryValueBackend | null {
    if (!isPresent(input.solution)) {
        return null;
    }

    const { solution } = input;
    const { unitPrice } = solution;
    const unitPriceOriginal = solution.firstPurchaseOption.unit_price_original;

    if (!isPresent(unitPrice) || !isPresent(unitPriceOriginal)) {
        return null;
    }

    switch (currency) {
        case 'preferred':
            return { amount: String(extractUnitPrice({ solution, extract, currency })), currency: unitPrice.currency };
        case 'original':
            return {
                amount: String(extractUnitPrice({ solution, extract, currency })),
                currency: unitPriceOriginal.currency,
            };
    }
}
