import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { FutureCredentialsInputDTORuntype, FutureResponseBodyDTORuntype } from './futureBackendTypes';

export const futureEndpoints = {
    'GET /3rdparty/future/credentials': endpoint({
        description: 'Returns credentials for future integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: FutureResponseBodyDTORuntype,
    }),
    'POST /3rdparty/future/credentials': endpoint({
        description: 'Uploads credentials for future API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FutureCredentialsInputDTORuntype,
        responseBody: FutureResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/future/credentials'],
    }),
    'PATCH /3rdparty/future/credentials': endpoint({
        description: 'Updates credentials for future API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: FutureCredentialsInputDTORuntype,
        responseBody: FutureResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/future/credentials'],
    }),
    'DELETE /3rdparty/future/credentials': endpoint({
        description: 'Deletes existing credentials for future API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/future/credentials'],
    }),
};
