import {
    Duration,
    TimeCalculation,
    TimeCalculationBatch,
    TimeCalculationProject,
    TimeCalculationUnit,
    TimeComponents,
} from '@luminovo/http-client';
import { assertPresent } from '../../../utils/assertPresent';
import { assertUnreachable } from '../../../utils/typingUtils';
import { AddActivityFormInputs, DurationInput } from './AddActivityFormTypes';
/* eslint-disable camelcase */

function isDuration(arg: DurationInput | Duration): arg is Duration {
    return arg.amount !== undefined;
}

function isDurationAndAmountIsNotNull(arg: DurationInput | Duration): arg is Duration {
    return isDuration(arg) && arg.amount !== null;
}

const getUnitTimeCalculation = (data: AddActivityFormInputs): TimeCalculationUnit => {
    if (data.activity.isActivityPerPanel === undefined || data.activity.isActivityPerPanel === null) {
        throwUndefinedError('isActivityPanel');
    }

    return {
        level: 'Unit',
        details: {
            panel_default_settings: data.activity.isActivityPerPanel ? 'WithinWholePanel' : 'AsSingleAssembly',
            unit_time_components: generateUnitTimeCalculationComponent(data),
            batch_time_components: generateBatchTimeCalculationComponent(data),
        },
    };
};

const getBatchTimeCalculation = (data: AddActivityFormInputs): TimeCalculationBatch => {
    if (data.activity.isActivityPerPanel === undefined || data.activity.isActivityPerPanel === null) {
        throwUndefinedError('isActivityPanel');
    }

    const batchTimeComponents = generateBatchTimeCalculationComponent(data);
    const batch_time_components = assertPresent(batchTimeComponents);
    return {
        level: 'Batch',
        details: {
            batch_time_components,
        },
    };
};

const getProjectTimeCalculation = (data: AddActivityFormInputs): TimeCalculationProject => {
    if (data.activity.isActivityPerPanel === undefined || data.activity.isActivityPerPanel === null) {
        throwUndefinedError('isActivityPanel');
    }

    return {
        level: 'Project',
        details: {
            project_time_components: generateProjectTimeCalculationComponent(data),
        },
    };
};

const internalErrorUnitTimeIsNotADuration = 'fixedUnitTime is not a duration';
const throwUndefinedError = (undefinedEntity: string): Error => {
    throw new Error(`${undefinedEntity} is undefined`);
};
export const generateUnitTimeCalculationComponent = (data: AddActivityFormInputs): TimeComponents => {
    switch (data.selectedUnitCalculationType) {
        case 'Fixed':
            if (!isDuration(data.fixedUnitCalculationType.fixedUnitTime.duration)) {
                throw new Error(internalErrorUnitTimeIsNotADuration);
            }
            return {
                type: 'Fixed',
                details: {
                    fixed_time: data.fixedUnitCalculationType.fixedUnitTime.duration,
                },
            };

        case 'Linear':
            if (!isDuration(data.linearUnitCalculationType.variableUnitTime.duration)) {
                throw new Error(internalErrorUnitTimeIsNotADuration);
            }
            return {
                type: 'Linear',
                details: {
                    variable_time: {
                        driver: data.linearUnitCalculationType.driverId,
                        variable_unit: data.linearUnitCalculationType.variableUnitTime.duration,
                    },
                    fixed_time: isDurationAndAmountIsNotNull(data.linearUnitCalculationType.fixedUnitTime.duration)
                        ? data.linearUnitCalculationType.fixedUnitTime.duration
                        : null,
                },
            };
        case 'Formula': {
            return {
                type: 'Formula',
                details: {
                    formula: data.formulaUnitCalculationType.formula,
                    unit: data.formulaUnitCalculationType.unit,
                },
            };
        }
        default:
            return assertUnreachable(data.selectedUnitCalculationType);
    }
};

const internalErrorBatchTimeNotADuration = 'batch time not a duration';
const internalErrorProjectTimeNotADuration = 'project time not a duration';
export const generateBatchTimeCalculationComponent = (data: AddActivityFormInputs): TimeComponents | null => {
    switch (data.selectedBatchCalculationType) {
        case 'Fixed':
            if (!isDuration(data.fixedBatchCalculationType.fixedBatchTime.duration)) {
                throw new Error(internalErrorBatchTimeNotADuration);
            }
            return {
                type: 'Fixed',
                details: {
                    fixed_time: data.fixedBatchCalculationType.fixedBatchTime.duration,
                },
            };

        case 'Linear':
            if (!isDuration(data.linearBatchCalculationType.variableBatchTime.duration)) {
                throw new Error(internalErrorBatchTimeNotADuration);
            }

            return {
                type: 'Linear',
                details: {
                    variable_time: {
                        driver: data.linearBatchCalculationType.driverId,
                        variable_unit: data.linearBatchCalculationType.variableBatchTime.duration,
                    },
                    fixed_time: isDurationAndAmountIsNotNull(data.linearBatchCalculationType.fixedBatchTime.duration)
                        ? data.linearBatchCalculationType.fixedBatchTime.duration
                        : null,
                },
            };
        case 'Formula':
            return {
                type: 'Formula',
                details: {
                    formula: data.formulaBatchCalculationType.formula,
                    unit: data.formulaBatchCalculationType.unit,
                },
            };
        case 'None':
            return null;
        default:
            return assertUnreachable(data.selectedBatchCalculationType);
    }
};

const generateProjectTimeCalculationComponent = (data: AddActivityFormInputs): TimeComponents => {
    switch (data.selectedProjectCalculationType) {
        case 'Fixed':
            if (!isDuration(data.fixedProjectCalculationType.fixedProjectTime.duration)) {
                throw new Error(internalErrorProjectTimeNotADuration);
            }
            return {
                type: 'Fixed',
                details: {
                    fixed_time: data.fixedProjectCalculationType.fixedProjectTime.duration,
                },
            };

        case 'Linear':
            if (!isDuration(data.linearProjectCalculationType.variableProjectTime.duration)) {
                throw new Error(internalErrorProjectTimeNotADuration);
            }

            return {
                type: 'Linear',
                details: {
                    variable_time: {
                        driver: data.linearProjectCalculationType.driverId,
                        variable_unit: data.linearProjectCalculationType.variableProjectTime.duration,
                    },
                    fixed_time: isDurationAndAmountIsNotNull(
                        data.linearProjectCalculationType.fixedProjectTime.duration,
                    )
                        ? data.linearProjectCalculationType.fixedProjectTime.duration
                        : null,
                },
            };
        case 'Formula':
            return {
                type: 'Formula',
                details: {
                    formula: data.formulaProjectCalculationType.formula,
                    unit: data.formulaProjectCalculationType.unit,
                },
            };
        default:
            return assertUnreachable(data.selectedProjectCalculationType);
    }
};

export const generateTimeCalculationFromData = (data: AddActivityFormInputs): TimeCalculation => {
    switch (data.activity.level) {
        case 'Unit':
            return getUnitTimeCalculation(data);
        case 'Batch':
            return getBatchTimeCalculation(data);
        case 'Project':
            return getProjectTimeCalculation(data);
        default:
            return assertUnreachable(data.activity.level);
    }
};

/* eslint-enable camelcase */
