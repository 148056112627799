import { assertUnreachable } from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    Table,
    TanStackMenuBar,
    TanStackTable,
    usePersistedState,
} from '@luminovo/design-system';
import React from 'react';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import { ChartRfq, ViewMode } from './components/ChartRfq';
import { chartSpecCreatedDate } from './components/chartSpecCreatedDate';
import { chartSpecStatus } from './components/chartSpecStatus';
import RfqCreationButton from './components/RfqCreationButton';
import { RfqOverviewTableItem, useRfqOverviewTable } from './components/RfqOverviewTable';
import { RfqSummaryBox } from './components/RfqSummaryBox';
import { RfqWelcomeMessage } from './components/RfqWelcomeMessage';

const RfqGraph: React.FunctionComponent<{
    viewMode: 'rfqsByCreatedDate' | 'rfqsByStatus';
    table: Table<RfqOverviewTableItem>;
}> = ({ viewMode, table }): JSX.Element => {
    const data = table.getRowModel().rows.map((row) => row.original);

    function renderGraph(viewMode: 'rfqsByCreatedDate' | 'rfqsByStatus') {
        if (viewMode === 'rfqsByCreatedDate') {
            return <ChartRfq data={data} chartSpec={chartSpecCreatedDate} />;
        }
        if (viewMode === 'rfqsByStatus') {
            return <ChartRfq data={data} chartSpec={chartSpecStatus} />;
        }
        assertUnreachable(viewMode);
    }

    return (
        <Flexbox flexDirection="column">
            <TanStackMenuBar table={table} enableMenuBar={{ controlButtons: true }} />
            <React.Fragment key={viewMode}>{renderGraph(viewMode)}</React.Fragment>
        </Flexbox>
    );
};

const InnerRfqOverviewPage = (): JSX.Element => {
    const { table } = useRfqOverviewTable();
    const [viewMode, setViewMode] = usePersistedState<ViewMode>('rfq.overview.viewMode', 'table');

    return (
        <Flexbox flexDirection={'column'} paddingX="32px" paddingTop={'16px'} gap={'16px'} height={'100%'}>
            <RfqWelcomeMessage />
            <RfqSummaryBox viewMode={viewMode} setViewMode={setViewMode} table={table} />
            <LayoutCard title={undefined} style={{ height: '100%' }}>
                {viewMode !== 'table' && <RfqGraph viewMode={viewMode} table={table} />}
                {viewMode === 'table' && <TanStackTable table={table} ActionButton={RfqCreationButton} />}
            </LayoutCard>
        </Flexbox>
    );
};

const RfqOverviewPage = (): JSX.Element => {
    return (
        <PageLayout style={{ background: colorSystem.neutral[1] }} layout="fragment">
            <InnerRfqOverviewPage />
        </PageLayout>
    );
};

export default RfqOverviewPage;
