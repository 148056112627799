import { t, Trans } from '@lingui/macro';
import { Flexbox, PrimaryIconButton, Row, Tag, Text } from '@luminovo/design-system';
import { Done } from '@mui/icons-material';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { formatAttribute } from '../../../PdfViewer/model/RegionNetwork/formatAttribute';
import { formatAttributeValue } from '../../../PdfViewer/model/RegionNetwork/formatAttributeValue';
import { InferredAttribute } from '../../../PdfViewer/model/RegionNetwork/infer';
import { isAttributeRequired } from '../../../PdfViewer/model/RegionNetwork/isAttributeRequired';
import { LeadTimeUnit } from '../../../PdfViewer/model/RegionNetwork/parsers/LeadTimeUnit';
import { AttributeObject } from '../../../PdfViewer/model/RegionNetwork/types';
import { TableQuotationOfferItem, VisibleAttributes } from '../types';

type PopoverFormState = AttributeObject;

export type InputComponentType = (opts: {
    control: Control<PopoverFormState>;
    row: Row<TableQuotationOfferItem>;
}) => JSX.Element;

export function PopoverForm({
    onSubmit,
    attribute,
    attributeType,
    InputComponent,
    row,
}: {
    onSubmit: (value: PopoverFormState) => void;
    attribute: InferredAttribute | undefined;
    attributeType: VisibleAttributes;
    InputComponent: InputComponentType;
    row: Row<TableQuotationOfferItem>;
}): JSX.Element {
    const formProvider = useForm<PopoverFormState>({
        mode: 'onChange',
        defaultValues: {
            part: row.data.part?.attribute?.value,
            unitPrice: row.data.unitPrice?.attribute?.value,
            moq: row.data.moq?.attribute?.value,
            mpq: row.data.mpq?.attribute?.value,
            stock: row.data.stock?.attribute?.value,
            standardFactoryLeadTime: {
                unit: LeadTimeUnit.Weeks,
                ...row.data.standardFactoryLeadTime?.attribute?.value,
            },
            packaging: row.data.packaging?.attribute?.value,
            unit: row.data.unit?.attribute?.value,
        },
    });

    const handleSubmit = formProvider.handleSubmit((form) => {
        const value = form[attributeType];

        onSubmit({ [attributeType]: value });
    });

    return (
        <FormProvider {...formProvider}>
            <form data-testid="popover-form" onSubmit={handleSubmit}>
                <Flexbox flexDirection={'column'} padding="8px" minWidth={'280px'} gap={8}>
                    <Explanation attribute={attribute} attributeType={attributeType} />
                    <Flexbox gap={8}>
                        <InputComponent row={row} control={formProvider.control} />
                        <PrimaryIconButton type="submit">
                            <Done />
                        </PrimaryIconButton>
                    </Flexbox>
                </Flexbox>
            </form>
        </FormProvider>
    );
}

function Explanation({
    attribute,
    attributeType,
}: {
    attribute?: InferredAttribute;
    attributeType: VisibleAttributes;
}): JSX.Element {
    if (!attribute) {
        const isRequired = isAttributeRequired({ attr: attributeType });
        return <Tag label={t`Not found in PDF`} color={isRequired ? 'red' : 'yellow'} />;
    }
    if (attribute.origin === 'manual') {
        return <Tag label={t`Verified`} color="green" />;
    }
    if (attribute.confidence === 0) {
        return (
            <Text>
                <Trans>Default value</Trans>
            </Text>
        );
    }
    if (attribute.inferredFrom) {
        return (
            <Flexbox gap={8}>
                <Text>
                    <Trans>Inferred from</Trans>
                </Text>
                {attribute.inferredFrom.map((inf, i) => {
                    return (
                        <Tag
                            key={i}
                            color="violet"
                            label={`${formatAttribute(inf.attr)}: ${formatAttributeValue(inf)}`}
                        />
                    );
                })}
            </Flexbox>
        );
    }
    return <></>;
}
