/* eslint-disable spellcheck/spell-checker */
import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { PCBFileTypes } from '@luminovo/http-client';
import { GerberFileTypes } from './gerberLayerUtils';
import { StateFileType } from './layerAssignmentsUtils';

export const pcbFileTypesTranslations: Record<PCBFileTypes, MessageDescriptor> = {
    [PCBFileTypes.OUTLINE]: defineMessage({ message: 'Outline' }),
    [PCBFileTypes.KEEP_OUT]: defineMessage({ message: 'Mechanical' }),
    [PCBFileTypes.PASTE_TOP]: defineMessage({ message: 'Paste Top' }),
    [PCBFileTypes.PASTE_BOTTOM]: defineMessage({ message: 'Paste Bottom' }),
    [PCBFileTypes.SILKSCREEN_TOP]: defineMessage({ message: 'Silkscreen Top' }),
    [PCBFileTypes.SILKSCREEN_BOTTOM]: defineMessage({ message: 'Silkscreen Bottom' }),
    [PCBFileTypes.SOLDERMASK_TOP]: defineMessage({ message: 'Soldermask Top' }),
    [PCBFileTypes.SOLDERMASK_BOTTOM]: defineMessage({ message: 'Soldermask Bottom' }),
    [PCBFileTypes.COPPER_TOP]: defineMessage({ message: 'Copper Top' }),
    [PCBFileTypes.COPPER_MID]: defineMessage({ message: 'Copper Mid' }),
    [PCBFileTypes.PLANE_MID]: defineMessage({ message: 'Plane Mid' }),
    [PCBFileTypes.COPPER_BOTTOM]: defineMessage({ message: 'Copper Bottom' }),
    [PCBFileTypes.DRILL]: defineMessage({ message: 'Drill' }),
    [PCBFileTypes.PHDRILL]: defineMessage({ message: 'Drill' }),
    [PCBFileTypes.NPHDRILL]: defineMessage({ message: 'Drill' }),
    [PCBFileTypes.DRILLSETS]: defineMessage({ message: 'Drill' }),
    [PCBFileTypes.MECHANICAL]: defineMessage({ message: 'Mechanical' }),
    [PCBFileTypes.NATIVE_ALTIUM]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.NATIVE_KICAD]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.NATIVE_EAGLE]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.LEGACY_GERBER]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.UNMATCHED]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.UNKNOWN]: defineMessage({ message: 'Miscellaneous files' }),
    [PCBFileTypes.ADHESIVE_TOP]: defineMessage({ message: 'Adhesive' }),
    [PCBFileTypes.ADHESIVE_BOTTOM]: defineMessage({ message: 'Adhesive' }),
    [PCBFileTypes.PEELABLE_TOP]: defineMessage({ message: 'Peelable' }),
    [PCBFileTypes.PEELABLE_BOTTOM]: defineMessage({ message: 'Peelable' }),
    [PCBFileTypes.STACK_UP]: defineMessage({ message: 'Stackup' }),
};

export const drillTypesTranslations: Partial<Record<PCBFileTypes, MessageDescriptor>> = {
    [PCBFileTypes.DRILL]: defineMessage({ message: 'Plated & non-plated holes' }),
    [PCBFileTypes.PHDRILL]: defineMessage({ message: 'Plated hole' }),
    [PCBFileTypes.NPHDRILL]: defineMessage({ message: 'Non-plated hole' }),
};

export const gerberLayerTranslations: Record<GerberFileTypes, MessageDescriptor> = {
    [StateFileType.PasteTop]: defineMessage({ message: 'Paste Top' }),
    [StateFileType.SilkscreenTop]: defineMessage({ message: 'Silkscreen Top' }),
    [StateFileType.SoldermaskTop]: defineMessage({ message: 'Soldermask Top' }),
    [StateFileType.Copper]: defineMessage({ message: 'Copper' }),
    [StateFileType.SoldermaskBottom]: defineMessage({ message: 'Soldermask Bottom' }),
    [StateFileType.SilkscreenBottom]: defineMessage({ message: 'Silkscreen Bottom' }),
    [StateFileType.PasteBottom]: defineMessage({ message: 'Paste Bottom' }),
};
