import { useCommunicationsDrawer, ViewCommentsButton } from '../../../components/CommentsDrawer';

export function CommentsButton({
    designItemIds,
    rfqId,
    disabled = false,
}: {
    designItemIds: string[];
    rfqId: string;
    disabled?: boolean;
}): JSX.Element | null {
    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads: [
            {
                commentType: 'DesignItem',
                category: 'Internal',
                typeIds: designItemIds,
            },
            {
                commentType: 'DesignItem',
                category: 'Public',
                typeIds: designItemIds,
            },
        ],
    });

    if (designItemIds.length === 0) {
        return null;
    }

    return (
        <>
            <ViewCommentsButton
                disabled={disabled || designItemIds.length === 0}
                eventEntity={{ type: 'DesignItem', data: designItemIds }}
                onClick={() => openDrawer()}
                iconButtonOnly
            />
        </>
    );
}
