import { http } from '@luminovo/http-client';
export async function deleteDesignItems(designItems: string[], token: string): Promise<number> {
    const { deleted } = await http(
        'POST /design-items/bulk-delete',
        {
            requestBody: {
                ids: designItems,
            },
        },
        token,
    );

    return deleted;
}
