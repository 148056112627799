import { BrokenImageIcon, CenteredLayout, colorSystem, NonIdealState } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import React from 'react';

export const PreviewLoading: React.FunctionComponent = () => {
    return (
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                background: colorSystem.neutral[3],
                placeItems: 'center',
                display: 'grid',
            }}
        >
            <CircularProgress />
        </div>
    );
};

type PlaceholdersProps = {
    title: string;
    description: string;
    children?: React.ReactNode;
};
export const PreviewLoadingError: React.FunctionComponent<PlaceholdersProps> = ({ title, description, children }) => {
    return (
        <CenteredLayout>
            <NonIdealState title={title} description={description} Icon={BrokenImageIcon} />
        </CenteredLayout>
    );
};
