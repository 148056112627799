function hasObjectPrototype(o: unknown): boolean {
    return Object.prototype.toString.call(o) === '[object Object]';
}

function isPlainObject(o: unknown): o is Object {
    // Source: https://github.com/TanStack/query/blob/f9ebf9aaae59050331c22f143d835e46fac402cc/packages/query-core/src/utils.ts#L365
    if (!hasObjectPrototype(o)) {
        return false;
    }
    // If has modified constructor
    // @ts-ignore
    const ctor = o.constructor;
    if (typeof ctor === 'undefined') {
        return true;
    }
    // If has modified prototype
    const prot = ctor.prototype;
    if (!hasObjectPrototype(prot)) {
        return false;
    }
    // If constructor does not have an Object-specific method
    if (!prot.hasOwnProperty('isPrototypeOf')) {
        return false;
    }
    // Most likely a plain Object
    return true;
}

function sortObjectEntriesByKey(key: unknown, value: unknown): unknown {
    if (isPlainObject(value)) {
        return Object.fromEntries(Object.entries(value).sort());
    } else {
        return value;
    }
}

/**
 * A variant of `JSON.stringify` that sorts object entries by key. In other words, it produces the same string
 * for two objects with the same content but entries in different order. It does NOT sort array items.
 */
export function stringifyWithSortedKeys(object: unknown): string {
    const replacer = sortObjectEntriesByKey;
    return JSON.stringify(object, replacer);
}
