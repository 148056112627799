import { defineMessage } from '@lingui/macro';
import { ApprovalStatus, DoNotPlace } from '@luminovo/http-client';

export const approvalStatusTranslations = {
    [ApprovalStatus.Rejected]: defineMessage({ message: 'Missing data' }),
    [ApprovalStatus.Pending]: defineMessage({ message: 'Pending' }),
    [ApprovalStatus.Approved]: defineMessage({ message: 'Approved' }),
    [DoNotPlace.DNP]: defineMessage({ message: 'Do not place' }),
    [DoNotPlace.Warning]: defineMessage({ message: 'Warnings' }),
};
