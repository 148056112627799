import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { MenuButton, MenuItem, MenuItemVariant, Tooltip } from '@luminovo/design-system';
import { Delete, Edit, History, MoreHoriz as MoreHorizIcon, PushPinRounded } from '@mui/icons-material';
import { useFormState } from 'react-hook-form';
import { useImportedAssemblyNotEditableTooltipText } from '../../../../components/AssemblyMenuButton';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { analytics } from '../../../../utils/analytics';
import { QueryParams } from '../../../../utils/routes';
import { useIsAssemblyEditable } from '../../../AssemblyOverview/utils';
import { BomItemTableData, ViewContext } from '../../../Bom/components/ModuleTableData';
import useDesignItemChangeHistory from '../../../ChangeHistory/designItemChangeHistory';
import { useDeleteBOMItem } from '../DeleteBomItemButton';
import { useRfqNotEditableTooltip } from '../ToolbarBomEditPage';

type BomItemMenuButtonType = {
    id: string;
    label: string | JSX.Element;
    icon: JSX.Element;
    onClick: () => void;
    disabled: boolean;
    variant?: MenuItemVariant;
    tooltip?: string | JSX.Element;
};

export const BomItemMenuButton = ({
    queryParams,
    assemblyId,
    bomItem,
    viewContext,
    isHeaderEditable,
    setIsHeaderEditable,
    isBomDetailsPinned,
    setIsBomDetailsPinned,
}: {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    assemblyId: string;
    bomItem: BomItemTableData;
    viewContext: ViewContext;
    isHeaderEditable: boolean;
    setIsHeaderEditable: (value: React.SetStateAction<boolean>) => void;
    isBomDetailsPinned: 'true' | 'false';
    setIsBomDetailsPinned: (value: 'true' | 'false') => void;
}) => {
    const rfqId = viewContext.rfqId;
    const rfqNotEditableTooltip = useRfqNotEditableTooltip();
    const importedAssemblyDisabledTooltip = useImportedAssemblyNotEditableTooltipText();

    // when the rfqId is not defined, it means the user is looking at an imported assembly which at the moment are not linked to an rfq.
    const toolTip = rfqId ? rfqNotEditableTooltip : importedAssemblyDisabledTooltip;
    const { isSubmitting } = useFormState();
    const { data: assembly } = useAssembly(assemblyId);
    const isEditable = useIsAssemblyEditable(assembly);

    const { openDialog: openDeleteBOMItemDrawer } = useDeleteBOMItem({
        rfqId,
        assemblyId: assemblyId,
        bomItem,
        viewContext,
        queryParams,
    });

    const { openDrawer: openChangeHistoryDrawer } = useDesignItemChangeHistory({
        designItems: bomItem.id,
    });

    const menuButtonOptions: BomItemMenuButtonType[] = [
        {
            id: id('design/button_edit_bom_item'),
            label: <Trans>Edit BOM item</Trans>,
            disabled: !isEditable || isSubmitting || isHeaderEditable,
            icon: <Edit />,
            onClick: () => setIsHeaderEditable(true),
            tooltip: !isEditable ? toolTip : '',
        },
        {
            id: id('design/button_bom_change_history'),
            label: <Trans>See history of changes</Trans>,
            icon: <History />,
            onClick: () => {
                openChangeHistoryDrawer();
                analytics.track('view_bom_item_change_history');
            },
            disabled: false,
        },
        {
            id: id('design/button_pin_section'),
            label: isBomDetailsPinned === 'true' ? <Trans>Unpin section</Trans> : <Trans>Pin section</Trans>,
            icon: <PushPinRounded />,
            onClick: () => {
                setIsBomDetailsPinned(isBomDetailsPinned === 'true' ? 'false' : 'true');
            },
            disabled: false,
        },
        {
            id: 'design/button_delete_bom_item',
            label: <Trans>Delete BOM item</Trans>,
            icon: <Delete />,
            onClick: () => openDeleteBOMItemDrawer(),
            disabled: !isEditable || isSubmitting,
            variant: 'destructive',
            tooltip: !isEditable ? toolTip : '',
        },
    ];

    return (
        <>
            <MenuButton id={id('design/button_assembly')} appearance="secondary" icon={<MoreHorizIcon />} size="medium">
                {menuButtonOptions.map((option, i) => (
                    <Tooltip key={i} title={option.tooltip ?? ''}>
                        <span>
                            <MenuItem
                                id={option.id}
                                onClick={() => {
                                    option.onClick();
                                }}
                                disabled={option.disabled}
                                label={option.label}
                                variant={option.variant ?? 'primary'}
                                startIcon={option.icon}
                            />
                        </span>
                    </Tooltip>
                ))}
            </MenuButton>
        </>
    );
};
