import { t } from '@lingui/macro';

export const makeDuplicatedDefaultName = (originalName: string, existingNames: string[]) => {
    let newName = `${t`Copy of`} ${originalName}`;
    let num = 1;
    while (existingNames.includes(newName)) {
        newName = `${t`Copy of`} ${originalName} (${num})`;
        num++;
    }
    return newName;
};
