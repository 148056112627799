import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { AvnetSilicaCredentialsInputDTORuntype, AvnetSilicaResponseBodyDTORuntype } from './avnetSilicaBackendTypes';

export const avnetSilicaEndpoints = {
    'GET /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Returns credentials for avnetsilica integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AvnetSilicaResponseBodyDTORuntype,
    }),
    'POST /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Uploads credentials for avnetsilica API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetSilicaCredentialsInputDTORuntype,
        responseBody: AvnetSilicaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
    'PATCH /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Updates credentials for avnetsilica API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AvnetSilicaCredentialsInputDTORuntype,
        responseBody: AvnetSilicaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
    'DELETE /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Deletes existing credentials for avnetsilica API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
};
