import { Trans } from '@lingui/macro';
import {
    ButtonGroupItem,
    FieldMultiSelectControlled,
    FieldSelectControlled,
    Flexbox,
    Text,
} from '@luminovo/design-system';
import { ManufacturerDTO, SupplierDTO, SupplierLineCardDTO } from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box, ButtonGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { usePartCategories } from '../../../../resources/part/partHandler';
import { useNonExcludedSuppliers } from '../../../../resources/supplier/supplierHandler';
import { PartCategoryPreferenceConfigured } from '../../../Settings/OrganizationSettings/pages/ManufacturerPreferences/ManufacturerPreferencesFrontendTypes';
import { PartCategoryField } from '../../../Settings/OrganizationSettings/pages/ManufacturerPreferences/PartCategoriesField/PartCategoriesField';

export type ManufacturerRestrictionFormState = {
    restrictionType: 'supplier' | 'partCategory';
    manufacturer: ManufacturerDTO | null;
    suppliers: SupplierDTO[];
    partCategoryId: number;
};

const RestrictionTypeFormItem: React.FunctionComponent = () => {
    const { control, setValue } = useFormContext<ManufacturerRestrictionFormState>();
    const restrictionType = useWatch({ control, name: 'restrictionType' });

    return (
        <ButtonGroup size="small" style={{ marginTop: '12px' }}>
            <ButtonGroupItem
                selected={restrictionType === 'supplier'}
                onClick={() => setValue('restrictionType', 'supplier')}
            >
                <Trans>Restrict supplier</Trans>
            </ButtonGroupItem>
            <ButtonGroupItem
                selected={restrictionType === 'partCategory'}
                onClick={() => setValue('restrictionType', 'partCategory')}
            >
                <Trans>Restrict part category</Trans>
            </ButtonGroupItem>
        </ButtonGroup>
    );
};

const ManufacturerFormItem: React.FunctionComponent<{ supplierLineCard: SupplierLineCardDTO }> = ({
    supplierLineCard,
}) => {
    const { data: suppliers = [] } = useNonExcludedSuppliers();
    const { control, setValue } = useFormContext<ManufacturerRestrictionFormState>();

    const manufacturer = useWatch({ control, name: 'manufacturer' });

    React.useEffect(() => {
        if (!manufacturer) {
            return;
        }
        const restrictedToSuppliers =
            supplierLineCard.manufacturers.find((m) => m.id === manufacturer.id)?.restricted_to_suppliers ?? [];

        const restrictedSuppliers = suppliers.filter((s) =>
            [...restrictedToSuppliers, supplierLineCard.supplier].includes(s.id),
        );

        setValue('suppliers', restrictedSuppliers);
    }, [manufacturer, setValue, suppliers, supplierLineCard.manufacturers, supplierLineCard.supplier]);

    return (
        <Box gap={'12px'} display="grid" gridTemplateColumns="120px auto" alignItems={'center'}>
            <Text variant={'h5'}>
                <Trans>Send requests for</Trans>
            </Text>
            <FieldSelectControlled
                control={control}
                name={'manufacturer'}
                required={true}
                FieldProps={{
                    options: supplierLineCard.manufacturers,
                    getOptionLabel: (option) => option.name,
                }}
            />
        </Box>
    );
};

const SuppliersFormItem: React.FunctionComponent = () => {
    const { data: suppliers = [], isLoading } = useNonExcludedSuppliers();
    const { control } = useFormContext<ManufacturerRestrictionFormState>();

    return (
        <Box gap={'12px'} display="grid" gridTemplateColumns="120px auto" alignItems={'center'}>
            <Text variant={'h5'}>
                <Trans>Only to</Trans>
            </Text>
            <FieldMultiSelectControlled
                control={control}
                name={'suppliers'}
                FieldProps={{
                    disabled: isLoading,
                    options: suppliers,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierDTO(option),
                }}
            />
        </Box>
    );
};

const PartCategoryFormItem: React.FunctionComponent = () => {
    const { setValue, control } = useFormContext<ManufacturerRestrictionFormState>();

    const restrictionType = useWatch({ control, name: 'restrictionType' });

    const partCategoryPreferencesConfigured: PartCategoryPreferenceConfigured[] | undefined = [];

    const { data: partCategories = [] } = usePartCategories();

    if (restrictionType === 'supplier') {
        return <></>;
    }

    return (
        <Box gap={'12px'} display="grid" gridTemplateColumns="120px auto" alignItems={'center'}>
            <Text variant={'h5'}>
                <Trans>Part category</Trans>
            </Text>

            <PartCategoryField
                onChange={(value) => setValue('partCategoryId', value ?? 1)}
                partCategories={partCategories}
                partCategoryPreferencesConfigured={partCategoryPreferencesConfigured}
                isDisabled={false}
            />
        </Box>
    );
};

export function ManufacturerRestrictionForm({
    supplierLineCard,
    defaultValues,
    onSubmit,
}: {
    supplierLineCard: SupplierLineCardDTO;
    defaultValues: ManufacturerRestrictionFormState;
    onSubmit: (fromValues: ManufacturerRestrictionFormState) => void;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <Flexbox flexDirection="column" gap={12}>
                    <RestrictionTypeFormItem />
                    <ManufacturerFormItem supplierLineCard={supplierLineCard} />
                    <SuppliersFormItem />
                    <PartCategoryFormItem />
                </Flexbox>
                <Flexbox width={'100%'} justifyContent={'flex-end'}>
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}
