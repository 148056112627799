import * as r from 'runtypes';
import { websocketEndpoint } from '../websockets/websocketEndpoint';
import { PCBStreamMessageRuntype } from './pcbMessageBackendTypes';

export const pcbWebSocketEndpoints = {
    'GET /ems/pcb/v2/pcbs/:pcbId/updates': websocketEndpoint({
        description: 'PCB updates stream',
        pathParams: r.Record({ pcbId: r.String }),
        queryParams: r.Record({ k: r.String }),
        responseMessage: PCBStreamMessageRuntype,
    }),
};
