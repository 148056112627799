import { PCBSurfaceFinish } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

const chemicalRegex = 'ch(?:em(?:isch|ical)?|imico)?\\.?';
const leadFreeRegex = '(:?lead|PB|blei)(?:-|\\s)?(:?free|frei)';

const surfaceFinishKeywords: Record<PCBSurfaceFinish, string[]> = {
    [PCBSurfaceFinish.HAL_PB_FREE]: [`HAL.*${leadFreeRegex}`, 'HAL.*RoHS'],
    [PCBSurfaceFinish.HAL_PB]: ['HAL'],
    [PCBSurfaceFinish.ENIPIG]: ['ENIPIG'],
    [PCBSurfaceFinish.ENEPIG]: ['ENEPIG'],
    [PCBSurfaceFinish.ENAG]: ['ENAG'],
    [PCBSurfaceFinish.ASIG]: ['ASIG'],
    [PCBSurfaceFinish.EP]: [],
    [PCBSurfaceFinish.EPAG]: ['EPAG'],
    [PCBSurfaceFinish.EPIG]: ['EPIG'],
    [PCBSurfaceFinish.ISIG]: ['ISIG'],
    [PCBSurfaceFinish.OSP]: ['OSP'],
    [PCBSurfaceFinish.IT]: [`${chemicalRegex} Sn`, 'Immersion Tin'],
    [PCBSurfaceFinish.IS]: ['Immersion Silver'],
    [PCBSurfaceFinish.ENIG]: [
        'ENIG',
        'Immersion Gold',
        `${chemicalRegex} Ni.?AU`,
        `Ni.?AU ${chemicalRegex}`,
        `${chemicalRegex} (:?Nickel.?)?Gold`,
    ],
    [PCBSurfaceFinish.NONE]: [],
};

export function parseSurfaceFinish(str: string): PcbAttribute | undefined {
    for (const [surfaceFinish, keywords] of Object.entries(surfaceFinishKeywords)) {
        if (keywords.length === 0) {
            continue;
        }
        // this ensures that a keyword like 'HAL' isn't matched as part of a word like 'shall'
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            return {
                attr: 'surfaceFinish',
                value: surfaceFinish as PCBSurfaceFinish,
                // we trust HAL_PB less as it will also match when the more specific HAL_PB_FREE matches
                confidence: surfaceFinish === PCBSurfaceFinish.HAL_PB ? 0.5 : 1,
            };
        }
    }
    return undefined;
}
