import { Column } from '@luminovo/design-system';

import { ActivityDTO, ExpenseDTO } from '@luminovo/http-client';
import {
    driverColumn,
    levelColumnTagsOnOneRow,
    perPanelActivityColumn,
    resourcesColumn,
} from '../../ActivitiesView/activitiesDatabaseTableColumns';
import { levelColumn } from '../../ExpensesView';
import { categoryColumn, nameColumn, processColumn, useSiteColumn } from '../sharedManufacturingColumns';

export const useActivityColumns = (): Column<ActivityDTO>[] => {
    const sitesColumn = useSiteColumn((activity: ActivityDTO) => ({ siteIds: activity.sites }));

    return [
        nameColumn,
        processColumn,
        sitesColumn,
        categoryColumn,
        resourcesColumn,
        levelColumnTagsOnOneRow,
        perPanelActivityColumn,
        driverColumn,
    ];
};

export const useExpenseColumns = (): Column<ExpenseDTO>[] => {
    const siteColumn = useSiteColumn(
        (expense: ExpenseDTO) => ({ siteIds: expense.site_id ? [expense.site_id] : [] }),
        'small',
    );

    return [nameColumn, processColumn, siteColumn, categoryColumn, levelColumn];
};
