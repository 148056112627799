import { BaseParser, Parser } from '../parsers/Parser';

class EitherParser<T> extends BaseParser<T> {
    constructor(parsers: Parser<T>[]) {
        super((input) => {
            for (const parser of parsers) {
                const result = parser.parse(input);
                if (result.ok) {
                    return result;
                }
            }
            return { ok: false, expected: `One of ${parsers.map((parser) => parser.toString()).join(', ')}` };
        });
    }
}

export function either<T>(...parsers: Parser<T>[]): Parser<T> {
    return new EitherParser(parsers);
}
