import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { SchukatCredentialsInputDTORuntype, SchukatResponseBodyDTORuntype } from './schukatBackendTypes';

export const schukatEndpoints = {
    'GET /3rdparty/schukat/credentials': endpoint({
        description: 'Returns credentials for schukat integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: SchukatResponseBodyDTORuntype,
    }),
    'POST /3rdparty/schukat/credentials': endpoint({
        description: 'Uploads credentials for schukat API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SchukatCredentialsInputDTORuntype,
        responseBody: SchukatResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
    'PATCH /3rdparty/schukat/credentials': endpoint({
        description: 'Updates credentials for schukat API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SchukatCredentialsInputDTORuntype,
        responseBody: SchukatResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
    'DELETE /3rdparty/schukat/credentials': endpoint({
        description: 'Deletes existing credentials for schukat API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
};
