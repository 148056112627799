import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps, styled } from '@mui/material';
import { colorSystem } from '../../theme';

type SwitchProps = Omit<MuiSwitchProps, 'size'> & {
    size?: 'large' | 'small';
};

export const Switch = ({ size = 'small', ...props }: SwitchProps): JSX.Element => {
    switch (size) {
        case 'small':
            return <SmallSwitch {...props} />;
        case 'large':
            return <LargeSwitch {...props} />;
    }
};

const DefaultSwitch = styled(MuiSwitch)({
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&:hover': {
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.neutral[7],
            },
        },
        '&.Mui-checked': {
            color: colorSystem.neutral.white,
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.primary[5],
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
            },
        },
        '&.Mui-checked:hover': {
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.primary[7],
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: colorSystem.neutral.white,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: colorSystem.neutral[3],
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: colorSystem.neutral[5],
        opacity: 1,
    },
});

const SmallSwitch = styled(DefaultSwitch)({
    width: 28,
    height: 16,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            transform: 'translateX(12px)',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 12,
        height: 12,
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
    },
});

const LargeSwitch = styled(DefaultSwitch)({
    width: 40,
    height: 24,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            transform: 'translateX(16px)',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
    },
});
