import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { BaseIconButton } from './BaseIconButton';

export const PrimaryIconButton = styled(BaseIconButton)({
    background: colorSystem.primary[6],
    color: colorSystem.neutral.white,
    borderRadius: '4px',
    border: `1px solid ${colorSystem.primary[6]}`,
    '&:hover': {
        background: colorSystem.primary[7],
    },
    '&:active': {
        background: colorSystem.primary[8],
    },
    '&:disabled': {
        background: colorSystem.neutral[5],
        color: colorSystem.neutral.white,
        border: `1px solid ${colorSystem.neutral[5]}`,
    },
});
