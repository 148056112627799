import { AssemblyTypePublic } from '@luminovo/http-client';
import { useIsCustomer } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

const CUSTOMER_PORTAL_ASSEMBLY_TYPES: AssemblyTypePublic[] = ['Pcba', 'PcbOnly'];
const ASSEMBLY_TYPE_SORT_ORDER: AssemblyTypePublic[] = ['Pcba', 'Cable', 'Box', 'System', 'PcbOnly'];

export const useAssemblyTypeOptions = () => {
    const isCustomer = useIsCustomer();
    return useHttpQuery(
        'GET /organization-settings/assembly-settings',
        {},
        {
            select: (res) => {
                // Filtering out the BOM assembly type because we only want to show the public assembly types
                const allowedAssemblyTypes = res.allowed_assembly_types
                    .filter(({ type }) => type !== 'BOM')
                    .map(({ type }) => type as AssemblyTypePublic);

                // If the user is a customer, we only want to show the assembly types that are available in the customer portal
                const filteredAssemblyTypes = isCustomer
                    ? allowedAssemblyTypes.filter((type) => CUSTOMER_PORTAL_ASSEMBLY_TYPES.includes(type))
                    : allowedAssemblyTypes;

                // Sort the assembly types according to the sort order currently present on the UI
                return Array.from(filteredAssemblyTypes).sort(
                    (a, b) => ASSEMBLY_TYPE_SORT_ORDER.indexOf(a) - ASSEMBLY_TYPE_SORT_ORDER.indexOf(b),
                );
            },
        },
    );
};
