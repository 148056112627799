import { AzureExtractionResult, ObjectTypeObject } from '@luminovo/http-client';
import { BoundingBox } from '../../boundingBox';
import { NumberSeparator } from '../determineDecimalSeparator';
import { generateId } from '../generateId';
import { createParseNumber } from '../parsers/parseNumber';
import { parseUnit } from '../parsers/parseUnit';
import { Extractor, Region } from '../types';

export function extractInvoiceRows({
    decimalSeparator,
}: {
    decimalSeparator: NumberSeparator;
}): Extractor<ObjectTypeObject & { rowIndex: number }> {
    const parseNumber = createParseNumber(decimalSeparator);
    return {
        generator: function* (
            azureExtractionResult: AzureExtractionResult,
        ): Generator<ObjectTypeObject & { rowIndex: number }> {
            const rows = azureExtractionResult.analyzeResult.documents
                .filter((doc) => doc.docType === 'invoice')
                .flatMap((invoice) => {
                    return invoice.fields.Items?.valueArray ?? [];
                });
            let i = 0;
            for (const row of rows) {
                yield { ...row, rowIndex: i };
                i++;
            }
        },

        extractRegion: (obj) => {
            const result: Region[] = [];

            result.push({
                id: generateId(obj),
                content: obj.content,
                pageNumber: obj.boundingRegions[0].pageNumber,
                box: BoundingBox.fromPolygons(obj.boundingRegions),
                attributes: [{ attr: 'isRow', value: obj.rowIndex }],
            });

            if (obj.valueObject?.UnitPrice) {
                const unitPrice = obj.valueObject.UnitPrice;
                const parsedNumber = parseNumber(unitPrice.content);
                const unitPriceNumber = parsedNumber ?? unitPrice.valueCurrency?.amount;

                if (unitPriceNumber) {
                    result.push({
                        id: generateId(unitPrice),
                        content: unitPrice.content,
                        pageNumber: unitPrice.boundingRegions[0].pageNumber,
                        box: BoundingBox.fromPolygons(unitPrice.boundingRegions),
                        attributes: [{ attr: 'unitPrice', value: unitPriceNumber, confidence: 0.5 }],
                    });
                }
            }

            if (obj.valueObject?.Unit) {
                const unit = obj.valueObject.Unit;
                const unitAttribute = parseUnit(unit.valueString);
                if (unitAttribute) {
                    result.push({
                        id: generateId(unit),
                        content: unit.content,
                        pageNumber: unit.boundingRegions[0].pageNumber,
                        box: BoundingBox.fromPolygons(unit.boundingRegions),
                        attributes: [unitAttribute],
                    });
                }
            }

            if (obj.valueObject?.Quantity) {
                const quantity = obj.valueObject.Quantity;
                const parsedNumber = parseNumber(quantity.content);
                const quantityNumber = parsedNumber ?? quantity.valueNumber;
                result.push({
                    id: generateId(quantity),
                    content: quantity.content,
                    pageNumber: quantity.boundingRegions[0].pageNumber,
                    box: BoundingBox.fromPolygons(quantity.boundingRegions),
                    attributes: [{ attr: 'quantity', value: quantityNumber }],
                });
            }
            if (obj.valueObject?.Amount) {
                const amount = obj.valueObject.Amount;
                const parsedNumber = parseNumber(amount.content);
                const amountNumber = parsedNumber ?? amount.valueCurrency?.amount;
                if (amountNumber) {
                    result.push({
                        id: generateId(amount),
                        content: amount.content,
                        pageNumber: amount.boundingRegions[0].pageNumber,
                        box: BoundingBox.fromPolygons(amount.boundingRegions),
                        attributes: [{ attr: 'totalPrice', value: amountNumber }],
                    });
                }
            }

            return result;
        },

        extractionRules: [() => []],
    };
}
