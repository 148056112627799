import { isPresent } from '@luminovo/commons';
import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { Box } from '@mui/material';

export function WarningComponent({
    children,
    warning,
    tooltipText,
    startIcon,
}: {
    children: React.ReactNode;
    warning: boolean;
    tooltipText: string;
    startIcon?: React.ReactNode;
}) {
    if (!warning) {
        return <>{children}</>;
    }

    return (
        <Tooltip title={tooltipText}>
            {/* TODO(negotiations): Look into why using Flexbox causes the tooltip not to show */}
            <Box display="inline-flex" alignItems="center" gap={0.5}>
                {isPresent(startIcon) && startIcon}
                <Text variant="inherit" color={colorSystem.yellow[7]}>
                    {children}
                </Text>
            </Box>
        </Tooltip>
    );
}
