import { forwardRef } from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text';

export interface ReleaseTagProps {
    color: 'primary' | 'violet' | 'white';
    label: string | JSX.Element;
}

export const ReleaseTag = forwardRef<HTMLDivElement, ReleaseTagProps>(({ color, label }, ref) => {
    const colors = {
        violet: {
            color: colorSystem.neutral.white,
            background: 'linear-gradient(90deg, #8D7DF3 0.27%, #7061D1 99.52%)',
        },
        primary: {
            color: colorSystem.neutral.white,
            background: `linear-gradient(90deg, ${colorSystem['primary'][6]} 0.27%, ${colorSystem['primary'][7]} 99.52%)`,
        },
        white: {
            color: colorSystem.primary[7],
            background: `linear-gradient(90deg, ${colorSystem['neutral'][1]} 0.27%, ${colorSystem['neutral'][2]} 99.52%)`,
        },
    };

    const background = colors[color].background;

    return (
        <Text
            variant="h5"
            color={colors[color].color}
            style={{
                padding: '2px 4px',
                backgroundImage: background,
                borderRadius: '4px',
            }}
            ref={ref}
        >
            {label}
        </Text>
    );
});
