import * as r from 'runtypes';
import { CustomerBillingAddressAllRequiredRuntype, VATIdentificationTypeRuntype } from '../customer';

export const CustomerSignupUserRuntype = r.Record({
    first_name: r.String,
    last_name: r.String,
    email: r.String,
    language: r.String,
});

export const BusinessCustomerSignupPostRuntype = r.Record({
    type: r.Literal('Business'),
    data: r.Record({
        tenant: r.String,
        user: CustomerSignupUserRuntype,
        company_name: r.String,
        vat_identification_number: r.String,
        vat_identification_type: VATIdentificationTypeRuntype,
        commercial_register_number: r.String,
        billing_address: CustomerBillingAddressAllRequiredRuntype,
    }),
});

export const PrivateCustomerSignupPostRuntype = r.Record({
    type: r.Literal('Private'),
    data: r.Record({
        tenant: r.String,
        user: CustomerSignupUserRuntype,
        billing_address: CustomerBillingAddressAllRequiredRuntype,
    }),
});

export const CustomerSignupPostRuntype = PrivateCustomerSignupPostRuntype.Or(BusinessCustomerSignupPostRuntype);
export type CustomerSignupPostDTO = r.Static<typeof CustomerSignupPostRuntype>;
