import { QuantityUnit } from '@luminovo/http-client';
import { findAttributeByType } from './findAttributeByType';
import { Attribute, Attributes } from './types';

export type InferredAttribute = Attribute & { inferredFrom?: Attribute[] };

type InferenceFunction = (attrs: Attribute[]) => InferredAttribute[];

export function infer(attrs: Attribute[]): InferredAttribute[] {
    const inferenceFunctions: InferenceFunction[] = [
        inferUnitPrice,
        inferUnit,
        inferMpq,
        inferMoq,
        inferStock,
        inferRemoveNumberIfAlreadyTaggedWithNumericAttribute,
    ];

    const inferredAttrs = inferenceFunctions.reduce((attrs, inferenceFunction) => inferenceFunction(attrs), attrs);

    return inferredAttrs;
}

/**
 * Merges the confidence of multiple attributes. The confidence of the resulting attribute
 * is the minimum of the confidence of the given attributes.
 */
function mergeConfidence(...attrs: Attribute[]): number {
    return Math.min(...attrs.map((a) => a.confidence ?? 1));
}

function inferUnit(attrs: Attribute[]): InferredAttribute[] {
    const unit = findAttributeByType(attrs, 'unit');
    if (unit) {
        return attrs;
    }
    const inferredUnit: InferredAttribute = {
        attr: 'unit',
        value: QuantityUnit.Pieces,
        confidence: 0,
        inferredFrom: [],
    };
    return attrs.concat([inferredUnit]);
}

function inferUnitPrice(attrs: Attribute[]): Attribute[] {
    const unitPrice = findAttributeByType(attrs, 'unitPrice');
    const quantity = findAttributeByType(attrs, 'quantity');
    const totalPrice = findAttributeByType(attrs, 'totalPrice');
    if (!quantity || !totalPrice) {
        return attrs;
    }

    // sometimes there are precision issues when quantity and totalAmount are only given
    // to two decimal places, thus we rund the inferred Price to 5 decimal places
    const inferredUnitPrice = Math.round((totalPrice.value / quantity.value) * 100000) / 100000;

    if (unitPrice?.origin === 'manual') {
        return attrs;
    }

    if (!unitPrice) {
        const inferredUnitPriceAttr: InferredAttribute = {
            attr: 'unitPrice',
            value: inferredUnitPrice,
            confidence: mergeConfidence(quantity, totalPrice),
            inferredFrom: [quantity, totalPrice],
        };
        return attrs.concat([inferredUnitPriceAttr]);
    }

    // when our unitPrice is wrong compared to the inferredUnitPrice
    // it usually is because we didn't catch the price unit which usually
    // is 10, 100 or 1000
    // so we check if our inferredUnitPrice is off by a factor of exactly 10^n
    // if it is => we use that instead
    const quotient = unitPrice.value / inferredUnitPrice;

    // also round the log10 to 4 decimal places to avoid precision issues with qty and amount
    if (quotient > 0 && Number.isInteger(Math.round(Math.log10(quotient) * 10000) / 10000)) {
        return attrs.map((attr) => {
            if (attr === unitPrice) {
                const inferredUnitPriceAttr: InferredAttribute = {
                    attr: 'unitPrice',
                    value: inferredUnitPrice,
                    confidence: 1,
                    inferredFrom: [quantity, totalPrice],
                };
                return inferredUnitPriceAttr;
            }
            return attr;
        });
    }
    return attrs;
}

function inferStock(attrs: Attribute[]): InferredAttribute[] {
    const inStock = findAttributeByType(attrs, 'inStock');
    const quantity = findAttributeByType(attrs, 'quantity');
    const moq = findAttributeByType(attrs, 'moq');
    const stock = findAttributeByType(attrs, 'stock');
    if (stock) {
        // if stock is set, we don't need to infer anything
        return attrs;
    }

    if (inStock?.value === true) {
        if (quantity && quantity.value > 0) {
            const inferredStock: InferredAttribute = {
                attr: 'stock',
                value: quantity.value,
                confidence: mergeConfidence(quantity, inStock),
                inferredFrom: [quantity, inStock],
            };
            return attrs.concat([inferredStock]);
        } else if (moq && moq.value > 0) {
            const inferredStock: InferredAttribute = {
                attr: 'stock',
                value: moq.value,
                confidence: mergeConfidence(moq, inStock),
                inferredFrom: [moq, inStock],
            };
            return attrs.concat([inferredStock]);
        }
    }
    // Default to 0 stock
    return attrs.concat([{ attr: 'stock', value: 0, confidence: 0 }]);
}

/// Set mpq to 1 if it is not set
function inferMpq(attrs: Attribute[]) {
    const mpq = findAttributeByType(attrs, 'mpq');
    if (!mpq) {
        const inferredMpq: InferredAttribute = {
            attr: 'mpq',
            value: 1,
            inferredFrom: [],
            confidence: 0,
        };
        return attrs.concat([inferredMpq]);
    }
    return attrs;
}

function inferRemoveNumberIfAlreadyTaggedWithNumericAttribute(attrs: Attribute[]) {
    const number = findAttributeByType(attrs, 'number');
    if (!number) {
        return attrs;
    }
    const numericAttributes: Attributes[] = ['unitPrice', 'quantity', 'totalPrice', 'moq', 'mpq', 'stock', 'inStock'];
    const hasNumericAttribute = attrs.some(
        (attr) => numericAttributes.includes(attr.attr) && attr.value === number.value,
    );
    if (hasNumericAttribute) {
        return attrs.filter((attr) => attr !== number);
    }
    return attrs;
}

function inferMoq(attrs: Attribute[]) {
    const moq = findAttributeByType(attrs, 'moq');
    const quantity = findAttributeByType(attrs, 'quantity');
    if (!moq && quantity) {
        const unit = findAttributeByType(attrs, 'unit');
        // dont infer quantity if it is not an integer and unit is Pieces
        if (unit && unit.value === QuantityUnit.Pieces && !Number.isInteger(quantity.value)) {
            return attrs;
        }
        const inferredMoq: InferredAttribute = {
            attr: 'moq',
            value: quantity.value,
            confidence: 0,
            inferredFrom: [quantity],
        };
        return attrs.concat([inferredMoq]);
    }
    return attrs;
}
