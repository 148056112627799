import { t, Trans } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    Column,
    DataTable,
    defaultNumericColumn,
    MediumContainer,
    MenuButton,
    Message,
    useDataTableState,
} from '@luminovo/design-system';
import { OtsFullPart } from '@luminovo/http-client';
import { MoreVert } from '@mui/icons-material';
import { Box, MenuItem, styled, TableCell } from '@mui/material';
import { usePdfViewerState } from '../../PdfViewer';
import { scrollToRegions } from '../../PdfViewer/components/PolygonView';
import { BoundingBox } from '../../PdfViewer/model/boundingBox';
import { PdfExtractionResults } from '../../PdfViewer/model/RegionNetwork/composeExtractionResults';
import { minBy } from '../../PdfViewer/model/RegionNetwork/minBy';
import { columnMoq } from './components/columnMoq';
import { columnMpq } from './components/columnMpq';
import { columnPackaging } from './components/columnPackaging';
import { columnPart } from './components/columnPart';
import { columnQuantityUnit } from './components/columnQuantityUnit';
import { columnStdFactoryLeadTime } from './components/columnStandardFactoryLeadTime';
import { columnStock } from './components/columnStock';
import { columnUnitPrice } from './components/columnUnitPrice';
import { attributeOrder, TableQuotationOfferItem, VisibleAttributes } from './types';

const columnRowNumber: Column<TableQuotationOfferItem> = defaultNumericColumn(
    {
        id: 'rowNumber',
        label: '',
    },
    ({ index }) => {
        return formatDecimal(index + 1);
    },
);

const columnActions: Column<TableQuotationOfferItem> = {
    id: 'removeRow',
    label: '',
    renderHead: () => {
        return (
            <TableCell
                style={{
                    position: 'sticky',
                    right: 0,
                    borderLeft: `1px solid ${colorSystem.neutral[2]}`,
                }}
            />
        );
    },
    render: ({ data }) => {
        return (
            <TableCell
                style={{
                    outline: 'none',
                    padding: '0 4px',
                    position: 'sticky',
                    width: 40,
                    right: 0,
                    borderLeft: `1px solid ${colorSystem.neutral[2]}`,
                    background: 'white',
                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <MenuButton
                    appearance="tertiary"
                    size="small"
                    label={<MoreVert fontSize="small" />}
                    style={{ minWidth: 'fit-content' }}
                >
                    <MenuItem onClick={() => data.onRemoveRow()}>
                        <Trans>Delete row</Trans>
                    </MenuItem>
                    <MenuItem onClick={() => data.onDuplicateRow()}>
                        <Trans>Duplicate row</Trans>
                    </MenuItem>
                    <MenuItem onClick={() => data.onAddEmptyRow()}>
                        <Trans>Add empty row</Trans>
                    </MenuItem>
                </MenuButton>
            </TableCell>
        );
    },
};

export const columns: Column<TableQuotationOfferItem>[] = [
    columnRowNumber,
    columnPart,
    columnUnitPrice,
    columnMoq,
    columnMpq,
    columnStock,
    columnStdFactoryLeadTime,
    columnPackaging,
    columnQuantityUnit,
    columnActions,
];

export function PdfViewerTable({
    extractionResults,
    expectedParts,
}: {
    extractionResults: PdfExtractionResults;
    expectedParts: OtsFullPart[];
}): JSX.Element {
    const [state, dispatch] = usePdfViewerState();
    const currency = extractionResults.currency?.value.value;

    function isSelected(attr: VisibleAttributes, rowIndex: number) {
        const selectedCell = state.selectedCell;
        if (!selectedCell) {
            return false;
        }
        const { rowIndex: selectedRowIndex, columnIndex } = selectedCell;
        const isRowSelected = selectedRowIndex === rowIndex;

        const isColumnSelected = columnIndex === attributeOrder[attr];
        const isCellCelected = isRowSelected && isColumnSelected;
        return isCellCelected;
    }

    const items = extractionResults.extractedRows.map((row, rowIndex): TableQuotationOfferItem => {
        type Field = keyof typeof row.fields;
        const onSelect =
            (attr: Field) =>
            (rowIndexDelta = 0) => {
                const row = extractionResults.extractedRows[rowIndex + rowIndexDelta];
                if (!row) {
                    return;
                }
                const regionsFromField = row.fields[attr]?.extractedFrom ?? [];
                const regionsFromPart = row.fields.part?.extractedFrom ?? [];
                const regions = regionsFromField.length > 0 ? regionsFromField : regionsFromPart;
                const pageNumber = minBy(regions, (r) => r.pageNumber)?.pageNumber ?? 1;
                const regionsInPage = regions.filter((r) => r.pageNumber === pageNumber);

                scrollToRegions(regionsInPage);

                const highlightBox = BoundingBox.fromMany(
                    Object.values(row.fields)
                        .flatMap((f) => f?.extractedFrom ?? [])
                        .filter((r) => r.pageNumber === pageNumber)
                        .map((r) => r.box),
                );

                const selectedRegionIds = row.fields[attr]?.extractedFrom.map((r) => r.id) ?? [];
                dispatch({
                    type: 'setMode',
                    mode: {
                        type: 'inspect',
                        selectedRegionIds,
                        attribute: attr,
                        highlightBox,
                        pageNumber,
                    },
                });
            };

        const regions = row.fields.part?.extractedFrom ?? [];

        return {
            part: {
                onSelect: onSelect('part'),
                value: row.fields.part?.value.value,
                region: regions[0],
                attribute: row.fields.part?.value,
                isSelected: isSelected('part', rowIndex),
            },
            unitPrice: {
                onSelect: onSelect('unitPrice'),
                value: {
                    amount: String(row.fields.unitPrice?.value.value),
                    currency,
                },
                currency,
                attribute: row.fields.unitPrice?.value,
                isSelected: isSelected('unitPrice', rowIndex),
            },
            moq: {
                onSelect: onSelect('moq'),
                value: row.fields.moq?.value.value,
                attribute: row.fields.moq?.value,
                isSelected: isSelected('moq', rowIndex),
            },
            mpq: {
                onSelect: onSelect('mpq'),
                value: row.fields.mpq?.value.value,
                attribute: row.fields.mpq?.value,
                isSelected: isSelected('mpq', rowIndex),
            },
            stock: {
                onSelect: onSelect('stock'),
                value: row.fields.stock?.value.value,
                attribute: row.fields.stock?.value,
                isSelected: isSelected('stock', rowIndex),
            },
            unit: {
                onSelect: onSelect('unit'),
                value: row.fields.unit?.value.value,
                attribute: row.fields.unit?.value,
                isSelected: isSelected('unit', rowIndex),
            },
            standardFactoryLeadTime: {
                onSelect: onSelect('standardFactoryLeadTime'),
                value: row.fields.standardFactoryLeadTime?.value.value,
                attribute: row.fields.standardFactoryLeadTime?.value,
                isSelected: isSelected('standardFactoryLeadTime', rowIndex),
            },
            packaging: {
                onSelect: onSelect('packaging'),
                value: row.fields.packaging?.value.value,
                attribute: row.fields.packaging?.value,
                isSelected: isSelected('packaging', rowIndex),
            },
            onRemoveRow() {
                const regions = row.fields.part?.extractedFrom ?? [];
                const updatedRegs = state.regs.updateRegions(
                    {
                        ids: regions.map((r) => r.id),
                    },
                    (r) => {
                        return {
                            ...r,
                            attributes: [],
                        };
                    },
                );
                dispatch({
                    type: 'setRegions',
                    regions: updatedRegs,
                });
            },
            onAddEmptyRow() {
                dispatch({
                    type: 'addManualRow',
                });
            },
            onDuplicateRow() {
                dispatch({
                    type: 'addManualRow',
                    attributes: Object.values(row.fields)
                        .map((f) => f?.value)
                        .filter(isPresent)
                        .map((attr) => {
                            return {
                                ...attr,
                                confidence: 0,
                            };
                        }),
                });
            },
            expectedParts,
        };
    });

    const tableState = useDataTableState<TableQuotationOfferItem>({
        items,
        columns: columns,
        persistenceId: 'table-extracted-offer',
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 1000,
            rowsPerPageOptions: [1000],
        },
    });

    if (tableState.items.length === 0) {
        return (
            <Box display="grid" style={{ placeItems: 'center', padding: 100 }}>
                <span style={{ maxWidth: 400 }}>
                    <Message
                        attention="high"
                        size="large"
                        variant="yellow"
                        title={t`No parts found`}
                        message={t`Our AI could not find any parts in your PDF. This can happen when the structure of your PDF is too complex. You can still add parts manually.`}
                        action={{
                            label: t`Add empty row`,
                            onClick: () => {
                                dispatch({
                                    type: 'addManualRow',
                                });
                            },
                        }}
                    />
                </span>
            </Box>
        );
    }

    return (
        <DataTable
            tableState={tableState}
            overrides={{
                Container: TableContainer,
            }}
            size={'medium'}
        />
    );
}

const TableContainer = styled(MediumContainer)({
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderLeft: `none`,
    borderBottom: `none`,
    borderTop: `none`,
    borderRadius: 8,

    '& th': {
        maxWidth: 100,
        padding: '8px',
        borderTop: `1px solid ${colorSystem.neutral[2]}`,
    },

    '& th:first-child': {
        borderLeft: `1px solid ${colorSystem.neutral[2]}`,
        borderTopLeftRadius: 8,
    },

    '& tr:last-child td:first-child': {
        borderBottomLeftRadius: 8,
    },

    '& td': {
        borderLeft: `1px solid ${colorSystem.neutral[2]} !important`,
        borderBottom: `1px solid ${colorSystem.neutral[2]} !important`,
        padding: '8px',
    },

    '& td:hover': {
        backgroundColor: colorSystem.teal[1] + '!important',
        outline: `1px solid ${colorSystem.teal[4]}`,
        outlineOffset: -1,
    },
});
