import { t } from '@lingui/macro';
import { FieldNumericControlled, Flexbox, FormItem } from '@luminovo/design-system';
import { ResourceDTO } from '@luminovo/http-client';
import { InputAdornment, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteOfIdOption } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormAutocompleteComponents';
import { colorSystem } from '../../../../themes';
import { AddActivityFormInputs } from '../AddActivityFormTypes';
import { ResourceSelect } from './ResourceSelect';
import { ResourceInformationBox } from './resourceInformationBoxes';

interface ResourceDetailsInForm {
    resourceId: string;
    multiplier: string;
}

export const ResourceField = ({
    useFormReturn,
    allResourceNameWithIds,
    allResources,
    i,
}: {
    useFormReturn: UseFormReturn<AddActivityFormInputs>;
    allResourceNameWithIds: AutocompleteOfIdOption[];
    allResources: ResourceDTO[];
    i: number;
}): JSX.Element => {
    const resourceDetails: ResourceDetailsInForm = useFormReturn.watch(`resourcesDetails.${i}`);
    const selectedResource: ResourceDTO | undefined = allResources.find((r) => r.id === resourceDetails.resourceId);

    return (
        <Flexbox
            flexDirection="column"
            key={i}
            style={{
                width: '370px',
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '4px',
            }}
        >
            <Flexbox flexDirection="row" gap={16} alignItems="center" style={{ padding: '12px' }}>
                <Flexbox flexDirection={'column'} style={{ width: '400px' }} gap={'8px'}>
                    <FormItem label={t`Name`} required />
                    <ResourceSelect
                        allResources={allResources}
                        control={useFormReturn.control}
                        name={`resourcesDetails.${i}.resourceId`}
                    />
                </Flexbox>
                <Flexbox flexDirection={'column'} gap={'8px'}>
                    <FormItem label={t`Multiplier`} required />
                    <FieldNumericControlled
                        name={`resourcesDetails.${i}.multiplier`}
                        control={useFormReturn.control}
                        FieldProps={{
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color={'textSecondary'}>x</Typography>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Flexbox>
            </Flexbox>

            <Flexbox
                style={{
                    padding: '12px',
                    minHeight: '220px',
                    height: '100%',
                    background: colorSystem.neutral[1],
                    borderTop: `1px solid ${colorSystem.neutral[2]}`,
                }}
            >
                <ResourceInformationBox
                    resource={selectedResource}
                    index={i}
                    useFormReturn={useFormReturn}
                    multiplier={resourceDetails.multiplier}
                />
            </Flexbox>
        </Flexbox>
    );
};
