import { t } from '@lingui/macro';
import { formatRelativeTime, formatToLongDate, isPresent } from '@luminovo/commons';
import { Chip, Flexbox, FormItem, Text, Tooltip } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';

export function FormItemDueDate({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const dueDate = quoteTracking.due_date;

    if (!isPresent(dueDate)) {
        return null;
    }

    const isExpiringSoon = daysDiff(new Date(dueDate), new Date()) < 7;
    return (
        <FormItem label={t`Due date`}>
            <Flexbox gap={8}>
                <Tooltip title={formatToLongDate(dueDate)}>
                    <Text>{formatRelativeTime(dueDate)}</Text>
                </Tooltip>

                {isExpiringSoon && <Chip label={t`Expiring soon`} color="yellow" />}
            </Flexbox>
        </FormItem>
    );
}

function daysDiff(left: Date, right: Date) {
    return Math.floor((left.getTime() - right.getTime()) / (1000 * 60 * 60 * 24));
}
