import { t } from '@lingui/macro';
import { SecondaryIconButton, Tooltip } from '@luminovo/design-system';
import { SupplierAndStockLocationDTO } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { useDialogEditSupplierAndStockLocation } from './SupplierDialogs/EditSupplierDialog';

export function ButtonEditSupplier({
    supplier,
    showLabel,
}: {
    supplier: SupplierAndStockLocationDTO;
    showLabel?: boolean;
}) {
    const { openDialog: openEdit } = useDialogEditSupplierAndStockLocation({ supplierAndStockLocation: supplier });

    const supplierName = supplier.supplier.name;
    return (
        <Tooltip title={t`Edit ${supplierName}`} placement="left">
            <SecondaryIconButton onClick={openEdit} size="medium">
                <Edit fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
}
