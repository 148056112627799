import { t } from '@lingui/macro';
import { CustomerDTO } from '@luminovo/http-client';
import type { ParsedValue, Parser } from '../types';

const emptyParsedValue: ParsedValue<string> = {
    id: '',
    label: '',
    description: '',
    existing: false,
};

export interface Opts {
    /**
     * List of customers to compare the customer number against.
     */
    customers: CustomerDTO[];
}

export const parseCustomerNumberOrName: Parser<string, Opts> = async ([cell], { customers }, field) => {
    cell = cell.trim();
    const alternatives = customers.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        // Honestly, I am not sure if we will ever support default values for customer numbers
        // but I'm keeping it here for consistency and completeness
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'done',
            value: emptyParsedValue,
            message: '',
            alternatives,
        };
    }

    const foundCustomer = customers.find((c) => c.name === cell || c.customer_number === cell);

    if (foundCustomer) {
        return {
            status: 'done',
            value: toParsedValue(foundCustomer),
            existing: true,
            alternatives,
        };
    }

    return {
        status: 'error',
        message: t`Customer not found`,
        alternatives,
    };
};

function toParsedValue(customer: CustomerDTO): ParsedValue<string> {
    return {
        id: customer.id,
        label: customer.name,
        description: customer.customer_number ?? '',
        existing: true,
    };
}
