import { isProductionEnvironment } from '@luminovo/commons';
import { AzureExtractionResult, OtsFullPart } from '@luminovo/http-client';
import { ImmutableRegionNetwork, RegionNetwork } from './RegionNetwork';
import { kvRule } from './attributeExtractionRules/kvRule';
import { ruleExpectedMpns } from './attributeExtractionRules/ruleExpectedMpns';
import { ruleExtractCurrency } from './attributeExtractionRules/ruleExtractCurrency';
import { determineDecimalSeparator } from './determineDecimalSeparator';
import { extract } from './extract/extract';
import { extractCells } from './extract/extractCells';
import { extractInvoiceAttributes } from './extract/extractInvoiceAttributes';
import { extractInvoiceRows } from './extract/extractInvoiceRows';
import { KeyValuePair, extractKeyValuePairs } from './extract/extractKeyValuePairs';
import { extractLines } from './extract/extractLines';
import { extractWords } from './extract/extractWords';
import { link } from './link';
import { merge } from './merge/merge';
import { createParseMoq, moqKeyRegex } from './parsers/createParseMoq';
import { createParseMpq, mpqKeyRegex } from './parsers/createParseMpq';
import { createParseStock, stockRegex } from './parsers/createParseStock';
import { createParseUnitPrice, unitPriceRegex } from './parsers/createParseUnitPrice';
import { parsePackaging } from './parsers/parsePackaging';
import { leadtimeRegex, parseStandardFactoryLeadTime } from './parsers/parseStandardFactoryLeadTime';
import { AttributeExtractionRule, Extractor, Parser } from './types';

export interface ExtractionOptions {
    expectedParts: OtsFullPart[];
}

/**
 * Runs the whole extraction process on the given AzureExtractionResult.
 */
export function processPdf(
    azureExtractionResult: AzureExtractionResult,
    { expectedParts }: ExtractionOptions,
): RegionNetwork {
    // eslint-disable-next-line no-console
    const log = isProductionEnvironment() ? () => {} : console.log;
    const t0 = performance.now();

    const decimalSeparator = determineDecimalSeparator(azureExtractionResult);

    const packagingKvExtractionRules: AttributeExtractionRule<KeyValuePair>[] = [
        kvRule({
            key: /^Package/i,
            value: parsePackaging,
        }),

        kvRule({
            key: /VPE/i,
            value: parsePackaging,
        }),

        kvRule({
            key: /Verpackungsart/i,
            value: parsePackaging,
        }),
    ];

    const parseUnitPrice: Parser = createParseUnitPrice(decimalSeparator);
    const unitPriceKvExtractionRules: AttributeExtractionRule<KeyValuePair>[] = [
        kvRule({
            key: /Preis/,
            value: parseUnitPrice,
        }),
        kvRule({
            key: unitPriceRegex,
            value: parseUnitPrice,
        }),
    ];

    const parseMpq: Parser = createParseMpq(decimalSeparator);
    const parseMoq: Parser = createParseMoq(decimalSeparator);
    const parseStock: Parser = createParseStock(decimalSeparator);
    // concatenate all kvExtractionRules
    const kvExtractionRules: AttributeExtractionRule<KeyValuePair>[] = [
        kvRule({
            key: moqKeyRegex,
            value: parseMoq,
        }),
        kvRule({
            key: mpqKeyRegex,
            value: parseMpq,
        }),
        kvRule({
            key: leadtimeRegex,
            value: parseStandardFactoryLeadTime,
        }),
        // we use the same key regex for stock and lead time
        kvRule({
            key: leadtimeRegex,
            value: parseStock,
        }),
        kvRule({
            key: stockRegex,
            value: parseStock,
        }),
        ...packagingKvExtractionRules,
        ...unitPriceKvExtractionRules,
    ];

    const lineExtractionRules: AttributeExtractionRule<{ content: string }>[] = [
        ruleExpectedMpns(expectedParts),
        ({ content }) => {
            const attributesResult = [];
            const leadTime = parseStandardFactoryLeadTime(content);
            if (leadTime) {
                attributesResult.push(leadTime);
            }
            const moq = parseMoq(content);
            if (moq) {
                attributesResult.push(moq);
            }
            const mpq = parseMpq(content);
            if (mpq) {
                attributesResult.push(mpq);
            }
            const stock = parseStock(content);
            if (stock) {
                attributesResult.push(stock);
            }
            const pkg = parsePackaging(content);
            if (pkg) {
                attributesResult.push(pkg);
            }
            const unitPrice = parseUnitPrice(content);
            if (unitPrice) {
                attributesResult.push(unitPrice);
            }
            return attributesResult;
        },
    ];

    const wordExtractionRules: AttributeExtractionRule<{ content: string }>[] = [
        ruleExpectedMpns(expectedParts),
        ({ content }) => {
            const pkg = parsePackaging(content);
            if (pkg) {
                return [pkg];
            }
            return [];
        },
        // ({ content }) => {
        //     const value = parseNumber(content);
        //     if (value) {
        //         return [{ attr: 'number', value, origin: 'word', confidence: 1 }];
        //     }
        //     return [];
        // },
        ruleExtractCurrency,
    ];

    /**
     * Configure extractors and linking rules.
     */
    const extractors: Extractor[] = [
        extractInvoiceRows({ decimalSeparator }),
        extractInvoiceAttributes(),
        extractCells({ extractionRules: kvExtractionRules }),
        extractKeyValuePairs({ extractionRules: kvExtractionRules }),
        extractWords({
            extractionRules: wordExtractionRules,
        }),
        extractLines({
            extractionRules: lineExtractionRules,
        }),
    ];

    // Make initial extraction
    const regions = extract(azureExtractionResult, {
        extractors,
    });

    const tExtract = performance.now();
    log(`⏱ extract took ${tExtract - t0} milliseconds.`);

    const mergedRegions = merge(regions.filter((r) => r.attributes.length > 0));
    const tMerge = performance.now();
    log(`⏱ merge took ${tMerge - tExtract} milliseconds.`);

    // From initial extraction, create a region network
    const initialDb: RegionNetwork = new ImmutableRegionNetwork(mergedRegions);

    log('🚧 Creating initial. Regions extracted:', regions.length);

    // Link regions based on linking rules
    const links = link({ db: initialDb });

    const linkedDb = initialDb.addLinks(links);
    log('🚧 Adding links. Links extracted:', links.length);
    log('⏱ Linking took', performance.now() - tMerge, 'milliseconds.');

    const tEnd = performance.now();
    log(`⏱ processPdf took ${tEnd - t0} milliseconds.`);
    return linkedDb;
}
