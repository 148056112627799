import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    MasterElectronicsCredentialsInputDTORuntype,
    MasterElectronicsResponseBodyDTORuntype,
} from './masterElectronicsBackendTypes';

export const masterElectronicsEndpoints = {
    'GET /3rdparty/master/credentials': endpoint({
        description: 'Returns credentials for Master Online integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: MasterElectronicsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/master/credentials': endpoint({
        description: 'Uploads credentials for Master Online API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MasterElectronicsCredentialsInputDTORuntype,
        responseBody: MasterElectronicsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
    'PATCH /3rdparty/master/credentials': endpoint({
        description: 'Updates credentials for Master Online API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: MasterElectronicsCredentialsInputDTORuntype,
        responseBody: MasterElectronicsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
    'DELETE /3rdparty/master/credentials': endpoint({
        description: 'Deletes existing credentials for Master Online API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
};
