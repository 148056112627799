import { createBrowserHistory } from 'history';
import { analytics } from './analytics';
import { findRouteId } from './routes';
const history = createBrowserHistory();

history.listen((location) => {
    const routeId = findRouteId(location.pathname);
    if (routeId) {
        // page prefix added because one of the routeId was deleted in Amplitude.
        analytics.page(`page: ${routeId}`);
    }
});

export default history;
