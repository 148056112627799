import { colorSystem, FontVariant, Text } from '@luminovo/design-system';

export const GenericLabel = ({
    name,
    variant = 'body-small',
}: {
    name: string;
    variant?: FontVariant;
}): JSX.Element => {
    return (
        <Text
            variant={variant}
            color={colorSystem.neutral[8]}
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            {name}
        </Text>
    );
};
