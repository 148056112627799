import { isPresent } from '@luminovo/commons';
import { TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { Checkbox } from '../Checkbox';
import { RenderHeadProps, Row } from './types';

export function checkIfRowIsDisabled({
    maxSelectableRows,
    countSelectedIds,
    isRowSelected,
    isDisabled,
}: {
    maxSelectableRows: number | undefined;
    countSelectedIds: number;
    isRowSelected: boolean;
    isDisabled: boolean;
}): boolean {
    if (isDisabled) {
        return true;
    }
    if (maxSelectableRows) {
        return countSelectedIds >= maxSelectableRows && !isRowSelected;
    }
    return false;
}

export function RowCheckbox<TRowData>({
    data,
    isDisabled = false,
    disabledPopupText,
    maxSelectableRows,
    onClick,
    style,
}: {
    data: Row<TRowData>;
    isDisabled?: boolean;
    disabledPopupText?: string;
    maxSelectableRows?: number;
    onClick?: ({ rowId }: { rowId: string }) => void;
    style?: React.CSSProperties;
}): JSX.Element {
    const { dispatch, rowId, tableState } = data;
    const isRowSelected = rowId ? tableState.selectedIds.includes(rowId) : false;
    const isRowDisabled = checkIfRowIsDisabled({
        maxSelectableRows,
        countSelectedIds: tableState.selectedIds.length,
        isRowSelected,
        isDisabled,
    });

    const handleClick = React.useCallback(() => {
        // selection is only possible if there is a rowId.
        if (!rowId) {
            return;
        }
        dispatch({
            type: 'select-items',
            ids: [rowId],
            selected: !isRowSelected,
        });
        if (onClick) {
            onClick({ rowId });
        }
    }, [rowId, dispatch, isRowSelected, onClick]);
    const checkbox = (
        <Checkbox
            size={'small'}
            checked={isRowSelected}
            onClick={handleClick}
            disabled={isRowDisabled}
            style={{
                // in order to be able to show a tooltip we have to activate pointerEvents again. Otherwise it will
                // be set to 'none'
                pointerEvents: 'auto',
            }}
        />
    );

    const tooltipTitle = disabledPopupText && isRowDisabled ? disabledPopupText : '';

    return (
        <TableCell onClick={(e) => e.stopPropagation()} style={{ cursor: 'default', ...style }}>
            <Tooltip title={tooltipTitle}>{checkbox}</Tooltip>
        </TableCell>
    );
}

export function HeaderCheckbox<TContext>({
    dispatch,
    state,
    items,
    filteredItems,
    idExtractor,
    disabled,
}: RenderHeadProps<TContext> & {
    disabled: boolean;
}): JSX.Element {
    const { selectAllStatus, handleSelectAllClick } = useSelectAllStatus({
        dispatch,
        state,
        items,
        filteredItems,
        idExtractor,
    });
    return (
        <TableCell onClick={(e) => e.stopPropagation()} style={{ cursor: 'default' }}>
            <Checkbox
                size={'small'}
                checked={selectAllStatus === 'all'}
                indeterminate={selectAllStatus === 'indeterminate'}
                disabled={selectAllStatus === 'disabled' || disabled}
                onClick={handleSelectAllClick}
            />
        </TableCell>
    );
}

export function useSelectAllStatus<TContext>({
    dispatch,
    state,
    items,
    filteredItems,
    idExtractor,
}: Omit<RenderHeadProps<TContext>, 'sharedContext'>): {
    selectAllStatus: 'all' | 'none' | 'indeterminate' | 'disabled';
    handleSelectAllClick: () => void;
} {
    const selectAllStatus = React.useMemo(() => {
        if (!isPresent(idExtractor)) {
            return 'disabled';
        }
        if (filteredItems.length === 0) {
            return 'disabled';
        }
        if (state.selectedIds.length === 0) {
            return 'none';
        }
        if (state.selectedIds.length === items.length) {
            return 'all';
        }
        return 'indeterminate';
    }, [idExtractor, items.length, filteredItems.length, state.selectedIds.length]);

    const handleSelectAllClick = React.useCallback(() => {
        if (!isPresent(idExtractor)) {
            return;
        }
        const filteredRowIds = filteredItems.map((item) => idExtractor(item));

        return dispatch({
            type: 'select-items',
            ids: filteredRowIds,
            selected: selectAllStatus !== 'all',
        });
    }, [dispatch, idExtractor, filteredItems, selectAllStatus]);

    return {
        selectAllStatus,
        handleSelectAllClick,
    };
}
