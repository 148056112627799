import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { CalculationTemplatePostRuntype, CalculationTemplateRuntype } from './calculationTemplatesBackendTypes';

export const calculationTemplatesEndpoints = {
    'GET /calculations/templates': endpoint({
        description: 'Fetches a list of calculation templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(CalculationTemplateRuntype),
        }),
    }),
    'GET /calculations/templates/:templateId': endpoint({
        description: 'Fetches a single calculation template',
        pathParams: r.Record({
            templateId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: CalculationTemplateRuntype,
        }),
    }),
    'DELETE /calculations/templates/:templateId': endpoint({
        description: 'Deletes a calculation template',
        pathParams: r.Record({ templateId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
    'POST /calculations/templates': endpoint({
        description: 'Creates a new calculation template',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CalculationTemplatePostRuntype,
        responseBody: r.Record({
            data: CalculationTemplateRuntype,
        }),
    }),
    'PATCH /calculations/templates/:templateId': endpoint({
        description: 'Edits a calculation template',
        pathParams: r.Record({ templateId: r.String }),
        queryParams: r.Undefined,
        requestBody: CalculationTemplatePostRuntype,
        responseBody: r.Record({
            data: CalculationTemplateRuntype,
        }),
    }),
};
