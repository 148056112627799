import { isPresent } from '@luminovo/commons';
import { FlammabilityRating } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parseNo } from './parseYesNo';

const flammabilityRatingKeywordKeywords = ['flammability', 'Brandschutzklasse', 'UL-?\\s?94'];
// the 94-V0 is specific enough that we can extract the attribute just based on that value
const flammabilityRatingKeywords: Record<Exclude<FlammabilityRating, 'none'>, string[]> = {
    [FlammabilityRating.V0]: ['(?:UL\\s?-?)?94\\s?-?V-?(?:0|O)'],
    [FlammabilityRating.V1]: ['(?:UL\\s?-?)?94\\s?-?V-?1'],
    [FlammabilityRating.V2]: ['(?:UL\\s?-?)?94\\s?-?V-?2'],
};
export function parseFlammabilityRating(str: string): PcbAttribute | undefined {
    const flammabilityRatingKeyword = turnKeywordsIntoRegexRespectingWordBoundaries(
        flammabilityRatingKeywordKeywords,
    ).test(str);
    const no = parseNo(str);
    for (const [flammabilityRating, keywords] of Object.entries(flammabilityRatingKeywords)) {
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            return {
                attr: 'flammabilityRating',
                value: flammabilityRating as FlammabilityRating,
                confidence: isPresent(flammabilityRatingKeyword) ? 1 : 0.5,
            };
        }
    }
    if (flammabilityRatingKeyword && no) {
        return {
            attr: 'flammabilityRating',
            value: 'none' as FlammabilityRating,
            confidence: 0.75,
        };
    }
    return undefined;
}
