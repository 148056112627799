import { t } from '@lingui/macro';
import { assertUnreachable, formatDecimal, isPresent } from '@luminovo/commons';
import { FieldNumeric, Row, Text, Tooltip } from '@luminovo/design-system';
import { TableCell } from '@mui/material';
import { useCallback, useState } from 'react';
import { FullPartWithApprovalStatus } from '../../../../../resources/part/partFrontendTypes';
import { ManufacturingDataContext } from '../ManufacturingDataTypes';
import { getManufacturingPartsFormValues } from '../getManufacturingPartsFormValues';

export const RenderNumberOfPins = (
    { data }: Row<FullPartWithApprovalStatus>,
    {
        isRfqEditable,
        packageData,
        editOtsPartTooltip,
        editComponentToolTip,
        canEditOtsComponents,
        canEditOtsParts,
        onChangeOtsPart,
        onChangeOtsComponent,
    }: ManufacturingDataContext,
): JSX.Element => {
    const value = getManufacturingPartsFormValues(data, packageData);
    const { number_of_pins: numberOfPins, mounting, package_name: packageName } = value;

    if (!isRfqEditable) {
        return (
            <TableCell>
                <Text>{formatDecimal(numberOfPins, { ifAbsent: '-' })}</Text>
            </TableCell>
        );
    }

    const partType = data.type;

    if (partType === 'Ipn') {
        return (
            <TableCell>
                <Tooltip title={editComponentToolTip}>
                    <div>
                        <RenderNumberOfPinsField
                            isDisabled={!canEditOtsComponents}
                            numberOfPins={numberOfPins ?? null}
                            partId={data.part.id}
                            onChange={(value) =>
                                onChangeOtsComponent({
                                    partId: data.part.id,
                                    mounting,
                                    name: packageName,
                                    number_of_pins: value,
                                })
                            }
                        />
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'Generic') {
        return (
            <TableCell>
                <Text>{numberOfPins ?? '-'}</Text>
            </TableCell>
        );
    }
    if (partType === 'OffTheShelf') {
        return (
            <TableCell>
                <Tooltip title={editOtsPartTooltip}>
                    <div>
                        <RenderNumberOfPinsField
                            isDisabled={!canEditOtsParts}
                            numberOfPins={numberOfPins ?? null}
                            partId={data.part.id}
                            onChange={(value) =>
                                onChangeOtsPart({
                                    partId: data.part.id,
                                    mounting,
                                    name: packageName,
                                    number_of_pins: value,
                                })
                            }
                        />
                    </div>
                </Tooltip>
            </TableCell>
        );
    }
    if (partType === 'CustomComponent') {
        return <TableCell />;
    }
    if (partType === 'Custom') {
        return <TableCell />;
    }
    assertUnreachable(partType);
};

const validateNumberOfPins = (value: number | null): { isError: boolean; message: string } => {
    if (typeof value === 'number' && value < 0) {
        return { isError: true, message: t`Must be greater than or equal to ${formatDecimal(0)}` };
    }
    if (isPresent(value) && !Number.isInteger(value)) {
        return { isError: true, message: t`Must be a whole number` };
    }
    return { isError: false, message: '' };
};

const RenderNumberOfPinsField = ({
    numberOfPins,
    partId,
    isDisabled,
    onChange,
}: {
    numberOfPins: number | null;
    partId: string;
    isDisabled: boolean;
    onChange: (value: number) => void;
}) => {
    const [value, setValue] = useState<number | null>(numberOfPins);

    const { isError, message } = validateNumberOfPins(value);

    const handleOnChange = useCallback(
        (value) => {
            if (validateNumberOfPins(value).isError) {
                return;
            }
            onChange(value);
        },
        [onChange],
    );

    return (
        <FieldNumeric
            id={partId}
            placeholder={t`Unknown`}
            value={value}
            disabled={isDisabled}
            error={isError}
            helperText={message}
            onChange={(value) => {
                setValue(value);
            }}
            size="small"
            onBlur={() => handleOnChange(value)}
            style={{ width: '100px' }}
        />
    );
};
