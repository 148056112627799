import { formatDecimal, transEnum } from '@luminovo/commons';
import { UnitOfMeasurementDTO } from '@luminovo/http-client';
import { QuantityUnitRecordPlural, QuantityUnitRecordSingular } from '../i18n';

export function formatUnitOfMeasurement(unitOfMeasurement: UnitOfMeasurementDTO): string {
    const { quantity_unit } = unitOfMeasurement;

    if (quantity_unit.quantity === 1) {
        return `1 ${transEnum(quantity_unit.unit, QuantityUnitRecordSingular)}`;
    } else {
        return `${formatDecimal(quantity_unit.quantity)} ${transEnum(quantity_unit.unit, QuantityUnitRecordPlural)}`;
    }
}
