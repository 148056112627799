import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, Flexbox, FormulaIcon, NumbersIcon, Text } from '@luminovo/design-system';
import { CalculationType } from '@luminovo/http-client';
import { LinearScale } from '@mui/icons-material';

const getCalculationTypeStringAndIcon = (calculationType: CalculationType): { name: string; icon: React.ReactNode } => {
    const linearScaleIconStyles = {
        color: colorSystem.neutral[6],
        transform: 'rotate(-45deg)',
        width: '20px',
        height: '20px',
    };

    switch (calculationType) {
        case 'Fixed':
            return {
                name: t`Fixed`,
                icon: <NumbersIcon />,
            };
        case 'Linear':
            return {
                name: t`Linear`,
                icon: <LinearScale style={linearScaleIconStyles} />,
            };
        case 'Formula':
            return {
                name: t`Formula`,
                icon: <FormulaIcon heightAndWidth="12" fill={colorSystem.neutral[6]} />,
            };
        default:
            assertUnreachable(calculationType);
    }
};

export const CalculationTypeWithIcon = ({ calculationType }: { calculationType: CalculationType }): JSX.Element => {
    const { name, icon } = getCalculationTypeStringAndIcon(calculationType);

    return (
        <Flexbox flexWrap={'nowrap'} gap={'4px'} alignItems={'center'}>
            {icon}
            <Flexbox>
                <Text>{name}</Text>
            </Flexbox>
        </Flexbox>
    );
};
