import { compareByNumber } from '@luminovo/design-system';
import { Datum } from '../types';

export function clearUnselectedKeys<TKeys extends string, T extends Datum<TKeys>>(
    data: T[],
    keys: TKeys[],
    selectedKeys: TKeys[],
): T[] {
    const otherKeys: TKeys[] = keys.filter((k) => !selectedKeys.includes(k));

    const clearData = data.map((datum) => {
        const copy: T = { ...datum };

        for (const key of otherKeys) {
            // @ts-ignore
            copy[key] = 0;
        }

        return copy;
    });

    if (selectedKeys.length === 1 && keys.length > 1) {
        return clearData.sort(compareByNumber((d) => -Number(d[selectedKeys[0]])));
    }

    return clearData;
}
