import React from 'react';
import { ViewContext } from '../../../../../modules/Bom/components/ModuleTableData';
import { PcbSpecificationTab } from '../../../../../modules/Pcb/PcbSpecificationTab';
import { useIsPcbEditable } from '../../../../../modules/Pcb/utils/useIsPcbSectionEditable';
import { useRfQ } from '../../../../../resources/rfq/rfqHandler';
import { UrlParams } from '../../../../../utils/routes';
import AssemblyOverviewPcbLayout from '../layout';

export default function AssemblyOverviewPcbSpecificationPage({
    pathParams,
    queryParams,
}: UrlParams<'/assemblies/:assemblyId/pcb/specification'>) {
    const { assemblyId } = pathParams;

    const { rfqId } = queryParams;

    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsPcbEditable(rfq);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId,
        };
    }, [rfqId]);

    return (
        <AssemblyOverviewPcbLayout assemblyId={assemblyId}>
            <PcbSpecificationTab
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isRfqEditable}
                viewContext={viewContext}
            />
        </AssemblyOverviewPcbLayout>
    );
}
