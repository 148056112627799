import { t } from '@lingui/macro';
import { Chip, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import { ActivityDTO, ExpenseDTO } from '@luminovo/http-client';
import { AccessTimeFilled, MonetizationOn } from '@mui/icons-material';

export const ExpenseCountChip = ({ expenses }: { expenses: ExpenseDTO[] }): JSX.Element => {
    return <EntityCountChip heading={t`Expenses`} entities={expenses} Icon={<MonetizationOn fontSize="inherit" />} />;
};

export const ActivityCountChip = ({ activities }: { activities: ActivityDTO[] }): JSX.Element => {
    return (
        <EntityCountChip heading={t`Activities`} entities={activities} Icon={<AccessTimeFilled fontSize="inherit" />} />
    );
};

const EntityCountChip = ({
    entities,
    heading,
    Icon,
}: {
    heading: string;
    Icon: JSX.Element;
    entities: ExpenseDTO[] | ActivityDTO[];
}): JSX.Element => {
    return (
        <Tooltip
            variant={'white'}
            title={
                <Flexbox gap={'4px'} flexDirection={'column'}>
                    <Text variant="h4">{heading}</Text>
                    {entities.map((entity) => (
                        <Text key={entity.id} variant="body">
                            {entity.name}
                        </Text>
                    ))}
                </Flexbox>
            }
        >
            <span>
                <Chip color={'neutral'} label={entities.length.toString()} startIcon={Icon} />
            </span>
        </Tooltip>
    );
};
