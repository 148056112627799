import { t } from '@lingui/macro';
import { TableContainer as MuiTableContainer, Table, TableFooter, styled } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import React from 'react';
import { body, bodySmall, colorSystem, h4, h5 } from '../../theme';
import { DataTableRow } from './DataTableRow';
import { DefaultNoResults } from './DefaultNoResults';
import { TableContentIndex } from './TableContentIndex';
import { TableHeadRow } from './TableHeadRow';
import { DataTableProps, IdExtractor, SearchOptions, SelectionOptions } from './types';

function assertSameIdExtractor<TRowData, TContext>(
    searchOptions?: SearchOptions<TRowData, TContext>,
    selectionOptions?: SelectionOptions<TRowData>,
) {
    if (
        searchOptions?.idExtractor &&
        selectionOptions?.idExtractor &&
        searchOptions.idExtractor !== selectionOptions.idExtractor
    ) {
        throw new Error('The id extractor of search option is different to id extractor of selection options');
    }
}

export function DataTable<TRowData, TContext>({
    overrides = {},
    onItemClick,
    tableState,
    stickyHeader = true,
    size,
}: DataTableProps<TRowData, TContext>): JSX.Element {
    const {
        Container = getContainerBySize(size),
        NoResultsComponent = DefaultNoResults,
        Table = DefaultStyledTable,
        TableContainer = DefaultTableContainer,
        TableRow = DataTableRow,
    } = overrides;
    const {
        state,
        onIndexSearchText,
        dispatch,
        searchOptions,
        query,
        filteredItems,
        items,
        columns,
        selectionOptions,
        paginationOptions,
        sharedContext,
    } = tableState;

    const scrollTargetRef = React.useRef<HTMLDivElement>(null);

    const handleScrollReset = React.useCallback(
        (_, page: number) => {
            if (paginationOptions.scrollResetAfterChange && scrollTargetRef.current) {
                scrollTargetRef.current.scrollIntoView({ block: 'center', behavior: 'auto' });
            }
        },
        [paginationOptions.scrollResetAfterChange, scrollTargetRef],
    );

    const handleChangePage = React.useCallback(
        (_, newPage: number) => {
            dispatch({ type: 'set-page', page: newPage, memorize: false });
        },
        [dispatch],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event) => {
            dispatch({ type: 'set-rows', rows: parseInt(event.target.value, 10) });
        },
        [dispatch],
    );

    const { page, rowsPerPage } = state;

    assertSameIdExtractor(searchOptions, selectionOptions);

    return (
        <Container>
            <div ref={scrollTargetRef} />
            <TableContainer>
                <Table stickyHeader={stickyHeader}>
                    <TableHeadRow
                        columns={columns}
                        state={state}
                        dispatch={dispatch}
                        renderHeadProps={{
                            state,
                            dispatch,
                            items,
                            filteredItems,
                            sharedContext,
                            // HACK: We have to forget the TRowData type of the idExtractor to make the type system happy.
                            //       This is safe because the idExtractor is only used in the defaultSelectionColumn component.
                            //
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            idExtractor: selectionOptions?.idExtractor as IdExtractor<unknown>,
                        }}
                    />
                    <TableBody>
                        {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const key = searchOptions ? searchOptions.idExtractor(row) : index;
                            return (
                                <TableRow
                                    key={key}
                                    rowId={selectionOptions ? selectionOptions.idExtractor(row) : index.toString()}
                                    index={index}
                                    item={row}
                                    columns={columns}
                                    tableState={state}
                                    onItemClick={onItemClick}
                                    dispatch={dispatch}
                                    sharedContext={sharedContext}
                                />
                            );
                        })}

                        {searchOptions && (
                            <TableContentIndex
                                items={items}
                                columns={columns}
                                tableState={state}
                                onIndexSearchText={onIndexSearchText}
                                idExtractor={searchOptions.idExtractor}
                                contentSearchOptions={searchOptions.contentSearchOptions}
                                dispatch={dispatch}
                                sharedContext={sharedContext}
                            />
                        )}
                    </TableBody>
                </Table>
                {filteredItems.length === 0 && <NoResultsComponent query={query} state={state} dispatch={dispatch} />}
            </TableContainer>
            {overrides.TablePagination ? (
                overrides.TablePagination
            ) : (
                <>
                    {paginationOptions.showPagination && (
                        <TablePagination
                            labelRowsPerPage={
                                <Typography variant={'body1'} component="span">{t`Rows per page`}</Typography>
                            }
                            labelDisplayedRows={({ from, to, count }) => (
                                <Typography
                                    variant={'body1'}
                                    component="span"
                                >{t`${from}-${to} of ${count}`}</Typography>
                            )}
                            rowsPerPageOptions={paginationOptions.rowsPerPageOptions}
                            component="div"
                            count={filteredItems.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, page) => {
                                handleChangePage(event, page);
                                handleScrollReset(event, page);
                            }}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </>
            )}

            {overrides.TableFooter && <TableFooter>{overrides.TableFooter}</TableFooter>}
        </Container>
    );
}

const tableBackgroundColor = colorSystem.neutral[1];
const tableBorderColor = colorSystem.neutral[2];

function getContainerBySize(size: 'small' | 'medium' | 'large') {
    switch (size) {
        case 'large':
            return LargeContainer;
        case 'medium':
            return MediumContainer;
        case 'small':
            return SmallContainer;
    }
}

const DefaultContainer = styled('div')(() => ({
    minWidth: 'fit-content',
    width: 'inherit',
    background: tableBackgroundColor,
    border: `1px solid ${colorSystem.neutral[2]}`,
    overflow: 'visible',
    borderRadius: 8,
    boxSizing: 'border-box',
    '& td': {
        borderBottomColor: tableBorderColor,
    },
    '& tr:last-child td': {
        border: 'none',
    },
    '& th:last-child': {
        borderRadius: '0 8px 0 0',
    },
    '& th:first-of-type': {
        borderRadius: '8px 0 0 0',
    },
    '& th': {
        background: colorSystem.neutral[0],
        borderBottomColor: tableBorderColor,
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    '& .MuiTableCell-head': {
        height: '57px',
        ...h4,
    },
    '& .MuiTableCell-root': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiIconButton-root.Mui-disabled': {
        color: '#D8D8D8',
    },
    '& .MuiTablePagination-toolbar': {
        minHeight: '32px',
        paddingRight: '8px !important',
        background: colorSystem.neutral[0],
        borderRadius: '0 0 8px 8px',
    },
    '& .MuiTablePagination-select': {
        padding: '4px 32px 4px 8px',
    },

    '& .MuiTablePagination-actions button': {
        padding: '0px',
    },

    '& .MuiTablePagination-root': {
        color: colorSystem.neutral[7],
    },

    '& .MuiTableFooter-root': {
        background: tableBackgroundColor,
    },
}));

export const LargeContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '48px',
        ...h4,
    },
    '& .MuiTableCell-body': {
        height: '56px',
        ...body,
    },
}));

export const MediumContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '48px',
        ...h5,
    },
    '& .MuiTableCell-body': {
        height: '48px',
        ...bodySmall,
    },
}));

export const SmallContainer = styled(DefaultContainer)(() => ({
    '& .MuiTableCell-head': {
        height: '32px',
        ...h5,
    },
    '& .MuiTableCell-body': {
        height: '32px',
        ...bodySmall,
    },
}));

export const DefaultTableContainer = styled(MuiTableContainer)(({ theme }) => ({
    overflow: 'visible',
    background: theme.palette.background.default,
    borderRadius: '8px 8px 0 0',
}));

export const DefaultStyledTable = styled(Table)({
    overflow: 'visible',
    minWidth: 750,
});

/**
 * EXPERIMENTAL - DO NOT USE
 *
 * This component is used to fill the available space of a parent container, but does not grow beyond the siblings.
 * If the parent container has no width set, this component will not grow.
 */
export const AvailableSpaceContainer = styled(MediumContainer)(() => ({
    flexGrow: 1,
    width: 0,
    minWidth: 'unset',
}));

/**
 * EXPERIMENTAL - DO NOT USE
 *
 * Adds a custom scrollbar to the table container.
 * BUG: The sticky header is not working properly.
 */
export const ScrollableTableContainer = styled(DefaultTableContainer)(() => ({
    overflowX: 'auto',
    '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
    },
    '::-webkit-scrollbar-track': {
        background: colorSystem.neutral[1],
    },

    '::-webkit-scrollbar-thumb': {
        background: colorSystem.neutral[4],
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: colorSystem.neutral[5],
    },
}));
