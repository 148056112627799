import { Currency } from '@luminovo/http-client';
import { CasesProps, cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface CurrencyProps {
    currencies?: Currency[];
}

export const currency = (props?: CurrencyProps): Parser<Currency> => {
    const defaultCurrencies = Object.values(Currency);
    const currencies = props?.currencies ?? defaultCurrencies;

    const matches: CasesProps<Currency>['matches'] = currencies.map((currency) => ({
        when: [currency],
        then: currency,
    }));

    matches.push({
        when: ['$'],
        then: Currency.USD,
    });

    matches.push({
        when: ['€'],
        then: Currency.EUR,
    });

    matches.push({
        when: ['£'],
        then: Currency.GBP,
    });

    return cases({
        matches,
    });
};
