import { Trans } from '@lingui/macro';
import { MaxWidthLayout, Text } from '@luminovo/design-system';
import React from 'react';
import { PageLayout } from '../../../../../../components/PageLayout';
import { AddAssemblyForm } from '../../../../../../modules/AssemblyForm';
import { ViewContext } from '../../../../../../modules/Bom/components/ModuleTableData';
import { UrlParams } from '../../../../../../utils/routes';

const AddAssemblyPage = (urlParams: UrlParams<'/rfqs/:rfqId/bom/assembly/add-assembly'>): JSX.Element => {
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId: urlParams.pathParams.rfqId,
        };
    }, [urlParams.pathParams.rfqId]);

    return (
        <AddAssembly
            viewContext={viewContext}
            rfqId={urlParams.pathParams.rfqId}
            assemblyId={urlParams.queryParams.assemblyId}
        />
    );
};

export const AddAssembly = ({
    assemblyId,
    rfqId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    viewContext: ViewContext;
}) => {
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Text variant="h1">
                    <Trans>Add assembly</Trans>
                </Text>
                <AddAssemblyForm rfqId={rfqId} parentAssemblyId={assemblyId} viewContext={viewContext} />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default AddAssemblyPage;
