import { Plural } from '@lingui/macro';
import { formatDays, isPresent } from '@luminovo/commons';
import { StatusChip, Tooltip, colorSystem } from '@luminovo/design-system';
import { Availability, AvailabilityType, SolutionStatus } from '@luminovo/http-client';
import { extractAvailabilityStatus } from '../../extractors';
import { formatAvailability } from '../../formatters';
import { Solution } from '../../types';

export function AvailabilityChip({
    solution,
}: {
    solution: Pick<Solution, 'solutionTags' | 'availability'>;
}): JSX.Element {
    const availabilityTimeInDays = getAvailabilityTime(solution.availability);
    const shippingTimeInDays = solution.availability?.shipping_time_in_days;
    return (
        <Tooltip title={getTooltip(solution.availability)}>
            <span>
                <StatusChip
                    color={statusToColor(extractAvailabilityStatus(solution))}
                    label={getLabel({
                        availabilityTimeInDays,
                        shippingTimeInDays,
                        solution,
                    })}
                />
            </span>
        </Tooltip>
    );
}

function statusToColor(status: SolutionStatus): keyof typeof colorSystem {
    switch (status) {
        case SolutionStatus.Error:
            return 'red';
        case SolutionStatus.Warning:
            return 'yellow';
        case SolutionStatus.Good:
            return 'green';
    }
}

function getAvailabilityTime(availability: Solution['availability']): number | null {
    if (!isPresent(availability)) {
        return null;
    }

    switch (availability.type) {
        case AvailabilityType.LeadTime:
        case AvailabilityType.OnOrder:
            return availability.days;
        default:
            return null;
    }
}

function getLabel({
    availabilityTimeInDays,
    shippingTimeInDays,
    solution,
}: {
    availabilityTimeInDays: number | null;
    shippingTimeInDays?: number | null;
    solution: Pick<Solution, 'solutionTags' | 'availability'>;
}) {
    if (
        isPresent(shippingTimeInDays) &&
        isPresent(solution.availability) &&
        solution.availability?.type !== AvailabilityType.OnOrderWithUnknownLeadTime
    ) {
        return formatDays((availabilityTimeInDays ?? 0) + shippingTimeInDays);
    }

    return formatAvailability(solution.availability);
}

function getTooltip(availability: Availability | null) {
    if (!availability) return '';

    const availabilityTimeInDays = getAvailabilityTime(availability);
    const shippingTimeInDays = availability?.shipping_time_in_days;

    if (!isPresent(shippingTimeInDays)) {
        if (availabilityTimeInDays === null) return '';

        const weeks = Math.floor(availabilityTimeInDays / 7);
        const remainingDays = availabilityTimeInDays % 7;
        return (
            <>
                <Plural value={weeks} one={`# week`} other={`# weeks`} />{' '}
                {remainingDays > 0 && <Plural value={remainingDays} one={`and # day`} other={`and # days`} />}
            </>
        );
    }

    return (
        <>
            {availabilityTimeInDays !== null ? (
                <Plural value={availabilityTimeInDays} one={`# day lead time `} other={`# days lead time `} />
            ) : (
                formatAvailability(availability)
            )}{' '}
            <Plural value={shippingTimeInDays} one={`+ # day shipping`} other={`+ # days shipping`} />
        </>
    );
}
