import { PriceType } from '@luminovo/http-client';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {}

const parser = parse.priceType();

export const parsePriceType: Parser<PriceType, Opts> = async function (
    cells,
    _,
    field,
): Promise<ParseResult<PriceType>> {
    const alternatives = Object.values(PriceType).map((value) => ({ id: value, label: value }));

    return applyParser({
        cells,
        parser,
        field,
        alternatives,
        formatValue: (x) => x.toString(),
    });
};
