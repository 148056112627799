import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { Grid } from '@mui/material';

const FormulaEntryButton = ({
    textToBeEntered,
    buttonText,
    onClick,
    width,
}: {
    textToBeEntered: string;
    buttonText?: string;
    onClick: (textToInsert: string) => void;
    width: string;
}): JSX.Element => {
    return (
        <SecondaryButton
            style={{ width, padding: '2px', minWidth: '0px' }}
            onClick={() => {
                onClick(textToBeEntered);
            }}
        >
            <Text variant="body-small-semibold" color={colorSystem.neutral[7]}>
                {buttonText ?? textToBeEntered}
            </Text>
        </SecondaryButton>
    );
};

const formulaOperatorRows: Array<string[]> = [
    ['and', 'round(x, decimals)', 'log_e(x)', '+', '*', '<', '<=', '=', '()'],
    ['or', 'floor(x)', '// comment', '-', '/', '>', '>=', '!='],
    ['not', 'ceil(x)', 'min(x,y)', 'max(x,y)', 'pow(x,y)'], // should  be in sync with builtInFunctions in lumiLang.ts
];
const ifElseStatement = 'if (condition) {return_value}\nelse_if (condition) {return_value}\nelse {return_value}';

export const Operators = ({ onClick }: { onClick: (textToInsert: string) => void }): JSX.Element => {
    return (
        <Flexbox gap={8}>
            <SecondaryButton
                style={{
                    height: '132px',
                    width: '400px',
                    paddingLeft: '12px',
                    textAlign: 'left',
                    marginTop: 4,
                }}
                onClick={() => {
                    onClick(ifElseStatement);
                }}
            >
                <Text variant="body-semibold" color={colorSystem.neutral[7]}>
                    {`if (condition) {return_value}`}
                    <br />
                    {`else_if (condition) {return_value}`}
                    <br />
                    {`else {return_value}`}
                </Text>
            </SecondaryButton>
            <Grid container direction="column" justifyContent="space-between">
                {formulaOperatorRows.map((row, i) => {
                    return (
                        <Grid container spacing={1} key={i} style={{ marginTop: 0.25 }}>
                            {row.map((operator, i) => {
                                return (
                                    <Grid item key={i}>
                                        <FormulaEntryButton
                                            width={operator.length < 5 ? '40px' : '90px'}
                                            textToBeEntered={operator}
                                            onClick={onClick}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </Flexbox>
    );
};
