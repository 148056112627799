import { Column } from '@luminovo/design-system';
import React from 'react';
import { HeaderOrDataRow, TemplateInstanceId, TemplateInstanceState } from './types';

export const useTemplateInstanceState = (): TemplateInstanceState => {
    const [collapsedInstances, setCollapsedInstances] = React.useState<Set<TemplateInstanceId>>(new Set());

    const getIdAsString = (id: TemplateInstanceId) => (typeof id === 'string' ? id : id.type);
    const isCollapsed = (id: TemplateInstanceId): boolean => collapsedInstances.has(getIdAsString(id));
    const setCollapsed = (id: TemplateInstanceId, collapsed: boolean) => {
        const idString = getIdAsString(id);
        collapsed ? collapsedInstances.add(idString) : collapsedInstances.delete(idString);
        setCollapsedInstances(new Set(collapsedInstances));
    };

    return { isCollapsed, setCollapsed };
};

export const createHeaderOrDataColumn = <THeaderData, TRowData, TContext = TemplateInstanceState>(
    column: Omit<Column<HeaderOrDataRow<THeaderData, TRowData>, TContext>, 'render' | 'renderExpanded'>,
    renderDataColumn: Column<TRowData, TContext>['render'],
    renderHeaderColumn: Column<THeaderData, TContext>['render'],
): Column<HeaderOrDataRow<THeaderData, TRowData>, TContext> => ({
    ...column,
    render: (row, context) => {
        if (row.data.type === 'header') {
            const headerRow = {
                ...row,
                data: row.data.data,
            };
            return renderHeaderColumn(headerRow, context);
        } else {
            const dataRow = {
                ...row,
                data: row.data.data,
            };
            return renderDataColumn(dataRow, context);
        }
    },
});
