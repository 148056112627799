import { FullPart, IncompleteGenericFullPart, isCustomComponentFull, isOtsComponentFull } from '@luminovo/http-client';

export const hasPartsWithCpns = ({ parts }: { parts: (FullPart | IncompleteGenericFullPart)[] }) => {
    return (
        parts.filter((part) => {
            if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
                return part.cpns.length > 0;
            }
            return false;
        }).length > 0
    );
};
