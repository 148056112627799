import { Trans } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, TertiaryButton, Text } from '@luminovo/design-system';

export const DemandDateFilter = ({
    selectedStartDate,
    selectedEndDate,
    onEditClick,
}: {
    selectedStartDate: Date;
    selectedEndDate: Date;
    onEditClick: () => void;
}): JSX.Element => {
    return (
        <Flexbox gap={4} alignItems="center">
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                <Trans>Time filter</Trans>:
            </Text>
            <Tag label={formatToLongDate(selectedStartDate)} color="neutral" attention="low" />
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                <Trans>-</Trans>
            </Text>
            <Tag label={formatToLongDate(selectedEndDate)} color="neutral" attention="low" />
            <TertiaryButton size="small" disabled={false} onClick={onEditClick}>
                <Trans>Edit</Trans>
            </TertiaryButton>
        </Flexbox>
    );
};
