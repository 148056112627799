import { defaultState, storageKey } from './reducer';
import { DefaultTableOptions, PersistentTableState } from './types';

/**
 * Loads the table's `PersistentTableState` from the given `storage`.
 */
export function loadTableState({
    tableId,
    storage,
    defaultTableOptions,
}: {
    tableId: string;
    storage: Storage;
    defaultTableOptions: DefaultTableOptions;
}): PersistentTableState {
    const result = storage.getItem(storageKey(tableId));
    // check if the parent component provided their own rowsPerPage & use that if present, or default to 25.
    const defaultRowsPerPage = defaultTableOptions.providedRowsPerPage ?? defaultState.rowsPerPage;

    if (!result) {
        return {
            ...defaultState,
            rowsPerPage: defaultRowsPerPage,
            selectedIds: defaultTableOptions?.defaultSelectedIds ?? [],
        };
    }
    const loadedState: PersistentTableState = JSON.parse(result);

    const page = defaultTableOptions.persistPagination ? loadedState.page : 0;

    return {
        ...loadedState,
        page,
        rowsPerPage: loadedState.rowsPerPage ?? defaultRowsPerPage,
        selectedIds: defaultTableOptions?.defaultSelectedIds ?? loadedState.selectedIds,
    };
}
