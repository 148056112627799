import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { PCBCredentialsDTORuntype, UserPasswordCredentialsInputDTORuntype } from './pcbManufacturerBackendTypes';

export const pcbManufacturersEndpoints = {
    'GET /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Returns full credentials for the given PCB manufacturer API integration',
        pathParams: r.Record({ manufacturerId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'POST /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Uploads credentials for the given PCB manufacturer API integration',
        pathParams: r.Record({ manufacturerId: r.String }),
        queryParams: r.Undefined,
        requestBody: UserPasswordCredentialsInputDTORuntype,
        responseBody: r.Record({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'PATCH /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Updates credentials for the given PCB manufacturer API integratoin',
        pathParams: r.Record({ manufacturerId: r.String }),
        queryParams: r.Undefined,
        requestBody: UserPasswordCredentialsInputDTORuntype,
        responseBody: r.Record({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'DELETE /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Deletes existing credentials for the given PCB manufacturer API integration',
        pathParams: r.Record({ manufacturerId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
};
