import { isPresent } from '@luminovo/commons';
import { CountryAlpha2Code, CustomerDTO } from '@luminovo/http-client';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

export interface ContactPerson {
    fullName: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
}

export interface CustomerInfo {
    name: string;
    city: string | undefined;
    postCode: string | undefined;
    addressLine1: string | undefined;
    addressLine2: string | undefined;
    countryCode: CountryAlpha2Code | undefined;
}

export interface EmsInfo {
    name: string;
    logoUrl: string | undefined;
}

export interface QuotationInfo {
    contactPerson: ContactPerson;
    customer: CustomerInfo;
    ems: EmsInfo;
}

const useEmsInfo = (): { ems: EmsInfo | undefined; isLoading: boolean } => {
    const userDetails = useCurrentUserDetailsContext();
    const { data: organizationSettings } = useHttpQuery('GET /organization-settings/self-signup', {});

    if (!isPresent(organizationSettings)) {
        return { ems: undefined, isLoading: true };
    }

    return {
        ems: {
            name: userDetails.organization.name,
            logoUrl: organizationSettings.organization_logo ?? undefined,
        },
        isLoading: false,
    };
};

const useCustomer = (rfqId: string): { customer: CustomerDTO | undefined; isLoading: boolean } => {
    const { data: rfq } = useHttpQuery(
        'GET /rfqs/:rfqId',
        { pathParams: { rfqId } },
        { select: (response) => response.data },
    );

    const { data: customer, isLoading } = useHttpQuery(
        'GET /customers/:customerId',
        { pathParams: { customerId: rfq?.customer ?? '' } },
        { enabled: isPresent(rfq), select: (response) => response.data },
    );

    return { customer, isLoading };
};

const useContactPerson = (rfqId: string): { contactPerson: ContactPerson | undefined; isLoading: boolean } => {
    const { customer } = useCustomer(rfqId);

    const { data: defaultContactPerson, isLoading: isLoadingDefaultContactPerson } = useHttpQuery(
        'GET /users/:userId',
        { pathParams: { userId: customer?.default_contact_person ?? '' } },
        { enabled: isPresent(customer?.default_contact_person), select: (response) => response.data },
    );

    const isLoading = !isPresent(defaultContactPerson);

    const currentUser = useCurrentUserDetailsContext();

    if (!isLoadingDefaultContactPerson && !isPresent(defaultContactPerson)) {
        const user = currentUser.user;

        const contactPerson = {
            fullName: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phoneNumber: user.phone_number ?? undefined,
        };

        return { contactPerson, isLoading: false };
    } else if (!isLoadingDefaultContactPerson && isPresent(defaultContactPerson)) {
        const contactPerson = {
            fullName: `${defaultContactPerson.first_name} ${defaultContactPerson.last_name}`,
            email: `${defaultContactPerson.email}`,
            phoneNumber: `${defaultContactPerson.phone_number}`,
        };

        return { contactPerson, isLoading };
    } else {
        return { contactPerson: undefined, isLoading: true };
    }
};

const useCustomerInfo = (rfqId: string): { customer: CustomerInfo | undefined; isLoading: boolean } => {
    const { customer } = useCustomer(rfqId);

    if (!isPresent(customer)) {
        return { customer: undefined, isLoading: true };
    }

    return {
        customer: {
            name: customer.name,
            city: customer.billing_address?.city ?? undefined,
            postCode: customer.billing_address?.postal_code ?? undefined,
            addressLine1: customer.billing_address?.line1 ?? undefined,
            addressLine2: customer.billing_address?.line2 ?? undefined,
            countryCode: customer?.billing_address?.country_code ?? undefined,
        },
        isLoading: false,
    };
};

export const useQuotationInfo = ({
    rfqId,
}: {
    rfqId: string;
}): { info: QuotationInfo | undefined; isLoading: boolean } => {
    const { contactPerson } = useContactPerson(rfqId);
    const { customer } = useCustomerInfo(rfqId);
    const { ems } = useEmsInfo();

    if (!isPresent(contactPerson) || !isPresent(customer) || !isPresent(ems)) {
        return { info: undefined, isLoading: true };
    }

    return { info: { contactPerson, customer, ems }, isLoading: false };
};
