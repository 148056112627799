import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { colorSystem } from '../../theme';
import { CheckboxCheckedIcon, CheckboxEmptyIcon, CheckboxIndeterminateIcon } from '../icons';

export type CheckboxProps = Omit<MuiCheckboxProps, 'size'> & { size?: 'large' | 'small' };

const DefaultCheckbox = styled(MuiCheckbox)({
    padding: 0,
    color: colorSystem.neutral[3],
    backgroundColor: colorSystem.neutral.white,
    height: 'fit-content',
    width: 'fit-content',
    borderRadius: 4,
    '&:hover': {
        color: colorSystem.primary[3],
        backgroundColor: colorSystem.primary[1],
    },

    '&.Mui-checked': {
        padding: 0,
        color: colorSystem.primary[5],
        '&:hover': {
            color: colorSystem.primary[7],
        },
    },

    '&.MuiCheckbox-indeterminate': {
        padding: 0,
        color: colorSystem.primary[5],
        '&:hover': {
            color: colorSystem.primary[7],
        },
    },

    '&.Mui-disabled': {
        color: colorSystem.neutral[3],
        backgroundColor: colorSystem.neutral[1],
    },

    '&.MuiIconButton-colorSecondary:hover': {
        padding: 0,
    },
    '&.MuiIconButton-root:hover': {
        padding: 0,
    },
});

export const Checkbox = forwardRef(({ size = 'large', ...props }: CheckboxProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
        <DefaultCheckbox
            ref={ref}
            indeterminateIcon={<CheckboxIndeterminateIcon size={size} />}
            checkedIcon={<CheckboxCheckedIcon size={size} />}
            icon={<CheckboxEmptyIcon size={size} />}
            disableRipple
            {...props}
        />
    );
});
