import { t } from '@lingui/macro';

export const validateSeriesDemandStartEndYears = (startYear: unknown, endYear: unknown) => {
    const currentYear = new Date().getFullYear();

    const startYearNum = Number(startYear);
    const endYearNum = Number(endYear);
    if (Number.isNaN(startYearNum) || Number.isNaN(endYearNum)) {
        return t`Please enter a valid number`;
    }
    if (startYearNum > endYearNum) {
        return t`Year must be before or the same as the end year`;
    }
    if (startYearNum < currentYear) {
        return t`Year must be after or the same as the current year`;
    }
    if (endYearNum - startYearNum > 20) {
        return t`The start year can only be twenty years before the end year`;
    }
    return undefined;
};
