import React from 'react';

// Taken from https://github.com/facebook/react/issues/24722
export const useForwardRef = <T>(ref: React.ForwardedRef<T>, initialValue: T | null = null): React.RefObject<T> => {
    const targetRef = React.useRef<T>(initialValue);

    React.useEffect(() => {
        if (!ref) return;

        if (typeof ref === 'function') {
            ref(targetRef.current);
        } else {
            // TS complains that the field is read-only, but given that refs are meant to be mutated,
            // this must be a trick to prevent abuses from the API.
            // @ts-ignore
            targetRef.current = ref.current;
        }
    }, [ref]);

    return targetRef;
};
