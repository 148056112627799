import { Trans } from '@lingui/macro';
import { Flexbox } from '@luminovo/design-system';
import { CalculatedScrapType, ScrapType } from '@luminovo/http-client';
import { ConditionFormula, ScrapFormula } from '@luminovo/sourcing-core';
import { Box, Chip, Typography, styled } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router';
import { RoutesHistoryStateProps } from '../../../../const';
import { route } from '../../../../utils/routes';
import { assertUnreachable } from '../../../../utils/typingUtils';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    gap: '16px',
    alignItems: 'flex-start',
});

const Title = styled(Chip)({
    fontSize: 14,
});

function ScrapDetailsCalculated({ scrapType }: { scrapType: CalculatedScrapType }) {
    const history = useHistory<RoutesHistoryStateProps | undefined>();
    const handleClick = React.useCallback(() => {
        history.push(route('/settings/organization/scrap-calculation'), { previousUrl: history.location.pathname });
    }, [history]);
    const { formula } = scrapType;
    return (
        <Container>
            <Title
                onClick={handleClick}
                label={
                    formula.kind === 'applied' ? <Trans>Derived from formula</Trans> : <Trans>Default formula</Trans>
                }
            />
            {formula.kind === 'applied' && (
                <>
                    <Flexbox flexDirection="column" gap={4}>
                        <Typography variant="body2">
                            <Trans>Conditions</Trans>
                        </Typography>
                        <ConditionFormula condition={formula.condition} />
                    </Flexbox>
                </>
            )}
            <Flexbox flexDirection="column" gap={4}>
                <Typography variant="body2">
                    <Trans>Scrap</Trans>
                </Typography>
                <ScrapFormula scrapFormula={formula.scrap} isMinimal />
            </Flexbox>
        </Container>
    );
}

export function ScrapDetails({ scrapType }: { scrapType: ScrapType }) {
    if (scrapType.type === 'none') {
        return null;
    }
    if (scrapType.type === 'manual') {
        return (
            <Container>
                <Title label={<Trans>Manually set</Trans>} />
            </Container>
        );
    }
    if (scrapType.type === 'calculated') {
        return <ScrapDetailsCalculated scrapType={scrapType} />;
    }
    assertUnreachable(scrapType);
}
