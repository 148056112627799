import { Box } from '@mui/material';
import React from 'react';

export const PcbPageLayout = ({
    children,
    wrapperStyle,
}: {
    children: React.ReactNode;
    wrapperStyle?: React.CSSProperties;
}) => {
    return (
        <Box
            style={{
                height: 'calc(100vh - 155px)',
                overflow: 'hidden',
            }}
        >
            <Box
                style={{
                    display: 'grid',
                    gridTemplateColumns: '330px 1fr',
                    height: '100%',
                    ...wrapperStyle,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
