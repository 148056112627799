import { colorSystem, Flexbox, POPPINS } from '@luminovo/design-system';
import { Box, Typography } from '@mui/material';
import { IconContainer } from './IconContainer';

export function BannerCard({
    background,
    title,
    description,
    callToAction,
    secondaryAction,
    icon,
}: {
    background: string;
    title: JSX.Element | string;
    description: string | JSX.Element;
    callToAction?: JSX.Element;
    secondaryAction?: JSX.Element;
    icon?: string | JSX.Element;
}) {
    return (
        <Box
            display="flex"
            borderRadius="8px"
            style={{
                background,
                backgroundColor: background,
                backgroundRepeat: 'no-repeat',
                transition: 'background-position-x 1s',
                color: colorSystem.neutral.white,
            }}
            alignItems="center"
            padding={'40px'}
            paddingRight={'10px'}
            gridTemplateColumns="auto 1fr"
            columnGap={'32px'}
            minWidth={'400px'}
        >
            {icon && (
                <IconContainer>
                    {typeof icon === 'string' ? <span style={{ fontSize: 96 }}>{icon}</span> : icon}
                </IconContainer>
            )}

            <Flexbox flexDirection={'column'} gap={8} alignItems="flex-start" width="100%">
                <Typography
                    style={{
                        fontSize: 24,
                        fontFamily: POPPINS,
                        fontStyle: 'normal',
                        lineHeight: '32px',
                        fontWeight: 400,
                    }}
                >
                    {title}
                </Typography>
                {typeof description === 'string' ? <Typography variant="body1">{description}</Typography> : description}

                {(callToAction || secondaryAction) && (
                    <>
                        <span style={{ height: 8 }} />
                        <Flexbox gap={12} width="100%">
                            {callToAction} {secondaryAction}
                        </Flexbox>
                    </>
                )}
            </Flexbox>
        </Box>
    );
}
