import { t } from '@lingui/macro';
import { CenteredLayout, FullWidthLayout, NonIdealState } from '@luminovo/design-system';
import { CheckRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../../components/PageLayout';
import { useSolutionConfiguration } from '../../resources/solutionConfiguration/solutionConfigurationHandler';
import { useSourcingScenario } from '../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../utils/routes';
import { NotFoundCard } from '../Error/NotFoundCard';
import { SolutionsTableContainer } from './components/SolutionsTableContainer';
import { ButtonGoToBom } from './components/Toolbar/ButtonGoToBom';
import { ToolbarSolutionManager } from './components/Toolbar/ToolbarSolutionManager';

export function SolutionManagerPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/:solutionConfigurationId'>): JSX.Element {
    const { solutionConfigurationId, rfqId } = pathParams;
    const history = useHistory();

    const {
        data: solutionConfiguration,
        isLoading: isLoadingSolutionConfiguration,
        error,
    } = useSolutionConfiguration(solutionConfigurationId);

    const { data: sourcingScenario, isLoading: isLoadingSourcingScenario } = useSourcingScenario(
        solutionConfiguration?.sourcing_scenario,
    );

    const isConsigned = solutionConfiguration?.is_consigned ?? false;

    const isLoading = isLoadingSolutionConfiguration || isLoadingSourcingScenario;

    if (isLoading) {
        return (
            <PageLayout layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    if (error) {
        return (
            <PageLayout layout="centered">
                <NotFoundCard
                    title={t`Solution not found`}
                    description={t`This can happen if two design items have been aggregated together after adding or removing a design item.`}
                    cta={t`Back to sourcing`}
                    onClick={() => {
                        history.goBack();
                    }}
                />
            </PageLayout>
        );
    }

    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                solutionConfiguration && (
                    <ToolbarSolutionManager
                        rfqId={rfqId}
                        sourcingScenarioName={sourcingScenario?.name ?? ''}
                        sourcingScenarioId={solutionConfiguration?.sourcing_scenario ?? ''}
                        solutionConfigId={solutionConfiguration?.id}
                    />
                )
            }
        >
            <FullWidthLayout padding="16px" style={{ paddingBottom: 80 }}>
                {isConsigned && (
                    <ConsignmentEmptyState rfqId={rfqId} solutionConfigurationId={solutionConfigurationId} />
                )}

                {!isConsigned && (
                    <SolutionsTableContainer
                        solutionConfigurationId={solutionConfigurationId}
                        rfqId={rfqId}
                        sourcingScenarioDTO={sourcingScenario}
                    />
                )}
            </FullWidthLayout>
        </PageLayout>
    );
}

const ConsignmentEmptyState = ({
    rfqId,
    solutionConfigurationId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}) => {
    const ActionButton = React.useCallback(
        () => <ButtonGoToBom rfqId={rfqId} solutionConfigurationId={solutionConfigurationId} />,
        [rfqId, solutionConfigurationId],
    );

    return (
        <CenteredLayout>
            <NonIdealState
                Icon={CheckRounded}
                title={t`Design item consigned by the customer`}
                description={t`This solution has no effect on price and lead time. Go to the BOM to change consignment status.`}
                overrides={{ ActionButton }}
            />
        </CenteredLayout>
    );
};
