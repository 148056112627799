import { t } from '@lingui/macro';
import { assertUnreachable, id } from '@luminovo/commons';
import {
    Column,
    DataTable,
    MediumContainer,
    StickyLayout,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { ActivityConfigurationSummaryDTO, ManufacturingScenarioDTO } from '@luminovo/http-client';
import { CircularProgress, Grid, styled } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import {
    DataRow,
    TemplateInstanceState,
    groupByTemplateInstances,
    useTemplateInstanceState,
} from '../shared/ActivityAndExpenseConfigurationTables';
import { ManufacturingNoResults } from '../shared/manufacturingComponents';
import { generateColumns } from './activitiesTableColumns';
import { ActivityConfigurationDrawer } from './activityConfigurationDrawer';
import { ActivityTableRow, ActivityTableRowData } from './types';

const StyledDefaultContainer = styled(MediumContainer)({
    '& td': {
        borderBottomColor: colorSystem.neutral[2],
    },
    'margin-bottom': 0,
});

interface ActivitiesTableProps {
    rows: ActivityTableRow[];
    sharedContext: TemplateInstanceState;
    manufacturingScenario: ManufacturingScenarioDTO;
}

const idExtractor = (activity: ActivityTableRow): string => {
    const { type, data } = activity;
    switch (type) {
        case 'data':
            return data.activityConfigurationSummary.activity_configuration_details.activity_configuration_id;
        case 'header':
            return typeof data.templateInstanceId === 'string' ? data.templateInstanceId : data.templateInstanceId.type;
        default:
            assertUnreachable(type);
    }
};

const InnerTable: React.FunctionComponent<ActivitiesTableProps> = ({
    rows,
    sharedContext,
    manufacturingScenario,
}: ActivitiesTableProps): JSX.Element => {
    const batchSizes = manufacturingScenario.batch_sizes;

    const [selectedBatchSize, setSelectedBatchSize] = React.useState<number | undefined>(
        batchSizes && batchSizes.length > 0 ? batchSizes[0] : undefined,
    );

    const columns: Column<ActivityTableRow, TemplateInstanceState>[] = generateColumns({
        manufacturingScenarioSummaryId: manufacturingScenario.id,
        rows,
        batchSizes,
        selectedBatchSize,
        setSelectedBatchSize,
    });

    const tableState = useDataTableState({
        columns,
        items: rows,
        sharedContext,
        persistenceId: 'activity-configurations-table',
        selectionOptions: { idExtractor },
        paginationOptions: {
            // Hack: We don't want pagination because we are using the index of the DataTable as formulaIndex for the actions button.
            // This is stupied and should be changed.
            showPagination: false,
            defaultRowsPerPage: Number.MAX_SAFE_INTEGER,
        },
    });

    const selectedActivityConfigurationId = tableState.state.selectedIds[0];
    const isDataRow = (row: ActivityTableRow): row is DataRow<ActivityTableRowData> => row.type === 'data';
    const selectedActivityConfiguration = rows
        .filter(isDataRow)
        .find(
            (row) =>
                row.data.activityConfigurationSummary.activity_configuration_details.activity_configuration_id ===
                selectedActivityConfigurationId,
        );

    const handleClearSelection = () => {
        tableState.dispatch({
            type: 'clear-selected-items',
        });
    };

    const handleItemClick = (item: ActivityTableRow) => {
        const { type, data } = item;
        switch (type) {
            case 'header':
                return; // You cannot select a header
            case 'data':
                const itemsActivityId =
                    data.activityConfigurationSummary.activity_configuration_details.activity_configuration_id;
                if (itemsActivityId === selectedActivityConfigurationId) return;
                handleClearSelection();
                tableState.dispatch({
                    type: 'select-items',
                    ids: [itemsActivityId],
                    selected: true,
                });
                break;
            default:
                assertUnreachable(type);
        }
    };

    return (
        <Grid container spacing={2} height={'100%'}>
            <Grid
                item
                xs={12}
                md={8}
                id={id('manufacturing_scenario/activities_table')}
                style={{ overflow: 'scroll', height: '100%' }}
            >
                <DataTable
                    tableState={tableState}
                    onItemClick={handleItemClick}
                    overrides={{
                        Container: StyledDefaultContainer,
                        NoResultsComponent: () => ManufacturingNoResults(t`Please add activities`),
                    }}
                    size={'medium'}
                />
            </Grid>

            <Grid item xs={12} md={4} style={{ overflow: 'scroll', height: '100%' }}>
                <StickyLayout>
                    <ActivityConfigurationDrawer
                        activity={selectedActivityConfiguration?.data.activityConfigurationSummary}
                    />
                </StickyLayout>
            </Grid>
        </Grid>
    );
};

const ActivitiesTable = ({ selectedManufacturingScenario }: { selectedManufacturingScenario: string }): JSX.Element => {
    const { data, isSuccess } = useHttpQuery('GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary', {
        pathParams: { manufacturingScenarioId: selectedManufacturingScenario },
    });

    const context = useTemplateInstanceState();

    if (isSuccess) {
        const rows = groupByTemplateInstances<ActivityTableRowData, ActivityConfigurationSummaryDTO>({
            summaries: data.data.activity_configuration_summaries.items,
            templateInstances: data.data.template_instances,
            getInstanceId: (summary) => summary.activity_configuration_details.template_instance_id ?? undefined,
            getRank: (summary) => summary.activity_configuration_details.rank,
            context,
            createRowData: (summary, position) => ({
                activityConfigurationSummary: summary,
                manufacturingAssemblyDetails: data.data.manufacturing_assembly_details,
                position,
            }),
        });
        return (
            <InnerTable rows={rows} manufacturingScenario={data.data.manufacturing_scenario} sharedContext={context} />
        );
    }
    return <CircularProgress />;
};

export default ActivitiesTable;
