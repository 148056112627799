import { getToken } from '@luminovo/auth';
import { LexoRank, LexoRanking } from '@luminovo/commons';
import { UserDriverDetailsBulkPatchDTO, UserDriverDetailsDTO, http } from '@luminovo/http-client';
import { useEffect, useState } from 'react';
import { useMutationBulkUpdateDrivers } from '../../../resources/driver/driverHandler';

type LexoRankResult = { lexoRank: LexoRank | undefined } | undefined;

export const useNewLexoRank = (): LexoRankResult => {
    const findDriverLexoRank = useFindNextLexoRank();
    const [newDriverLexoRank, setNewDriverLexoRank] = useState<LexoRankResult>(undefined);
    const [isRebalanced, setIsRebalanced] = useState(false);

    useEffect(() => {
        if (isRebalanced) {
            return;
        }
        setIsRebalanced(true);
        findDriverLexoRank().then((lexoRank) => {
            setNewDriverLexoRank({ lexoRank });
        });
    }, [isRebalanced, findDriverLexoRank]);

    return newDriverLexoRank;
};

const useFindNextLexoRank = (): (() => Promise<LexoRank | undefined>) => {
    const { mutateAsync: bulkUpdateDrivers, isError } = useMutationBulkUpdateDrivers();

    const findNextLexoRank = async (): Promise<LexoRank | undefined> => {
        const token = getToken();
        const response = await http('GET /user-drivers', {}, token);
        const drivers = response.data;

        const newLexoRank = findDriverLexoRank(drivers);

        if (newLexoRank !== undefined) {
            return newLexoRank;
        }

        // If a new lexo rank could not be computed, we rebalance and try again
        const updates = await rebalanceLexoRanks(drivers);
        const { data: updatedDrivers } = await bulkUpdateDrivers({ updates });
        if (isError) {
            return undefined;
        }

        return findDriverLexoRank(updatedDrivers);
    };

    return findNextLexoRank;
};

const rebalanceLexoRanks = async (drivers: UserDriverDetailsDTO[]) => {
    const driverUpdates = LexoRanking.balance(drivers).map((driver) => {
        return {
            id: driver.id,
            update: {
                lexorank: driver.lexorank,
            },
        };
    });

    const updates: UserDriverDetailsBulkPatchDTO = {
        drivers: driverUpdates,
    };

    return updates;
};

const findDriverLexoRank = (drivers: UserDriverDetailsDTO[]): LexoRank | undefined => {
    if (drivers.length === 0) {
        return LexoRanking.INITIAL_LEXORANK;
    }

    return LexoRanking.beforeFirstLexoRank(drivers[0].lexorank);
};
