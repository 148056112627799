import { Link } from '@luminovo/design-system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { colorSystem } from '../../themes';

export interface DialogBoxBaseProps {
    title: string | JSX.Element;
    isDialogOpen: boolean;
    onReject: () => void;
    closeIconIsShown?: boolean;
    isTopAligned?: boolean;
}

// taken from the maxWidth property here https://v4.mui.com/api/dialog/. No export of this was available in the mui library.
/* eslint-disable-next-line spellcheck/spell-checker */
type DialogMaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

// eslint-disable-next-line import/no-unused-modules
export interface DialogBoxProps extends DialogBoxBaseProps {
    children: React.ReactNode;
    maxWidth: DialogMaxWidth;
    style?: CSSProperties;
}

const DialogBox: React.FunctionComponent<DialogBoxProps> = ({
    title,
    children,
    isDialogOpen,
    onReject,
    maxWidth,
    closeIconIsShown = true,
    style,
}: DialogBoxProps): JSX.Element => {
    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            maxWidth={maxWidth}
            style={style}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
            }}
        >
            <DialogTitle style={{ marginTop: '32px', paddingTop: 0 }}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant={'h1'} color={'textSecondary'} style={{ padding: 0 }}>
                            {title}
                        </Typography>
                    </Grid>
                    {closeIconIsShown && (
                        <Grid item style={{ justifyContent: 'flex-end' }}>
                            <Link onClick={onReject}>
                                <CloseRoundedIcon style={{ cursor: 'pointer', color: colorSystem.neutral[7] }} />
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '0px',
                    marginTop: '8px',
                    marginBottom: '16px',
                    paddingBottom: '16px',
                    /* eslint-disable-next-line spellcheck/spell-checker */
                    height: maxWidth === 'lg' || maxWidth === 'xl' ? '75vh' : 'inherit',
                    overflowX: 'hidden',
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default DialogBox;
