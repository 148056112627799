import { colorSystem, Flexbox } from '@luminovo/design-system';

export function PdfViewerLayout({
    pdfViewer,
    toolbar,
    table,
    form,
}: {
    pdfViewer: JSX.Element;
    toolbar: JSX.Element;
    table: JSX.Element;
    form: JSX.Element;
}): JSX.Element {
    const splitWidth = 0.5;
    return (
        <Flexbox
            style={{
                background: colorSystem.neutral[1],
            }}
        >
            <Flexbox
                style={{
                    maxWidth: `calc(${splitWidth * 100}vw)`,
                    maxHeight: '100vh',
                    overflow: 'auto',
                    padding: 16,
                    background: colorSystem.neutral[1],
                    boxSizing: 'border-box',
                }}
            >
                {pdfViewer}
            </Flexbox>

            <Flexbox
                minWidth={`calc(${(1 - splitWidth) * 100}vw)`}
                height="100vh"
                flexGrow={1}
                flexDirection={'column'}
                gap={20}
                borderLeft={`1px solid ${colorSystem.neutral[2]}`}
                style={{ background: colorSystem.neutral.white }}
            >
                {toolbar}
                {form}
                <div
                    style={{
                        overflow: 'auto',
                        marginLeft: 16,
                    }}
                >
                    {table}
                </div>
            </Flexbox>
        </Flexbox>
    );
}
