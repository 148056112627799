import { Packaging } from '@luminovo/http-client';
import { cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface PackagingProps {}

export const packaging = (_?: PackagingProps): Parser<Packaging> =>
    cases({
        matches: [
            {
                when: ['AmmoPack', 'Ammo Pack', 'Ammo-Pack', 'Ammo'],
                then: Packaging.AmmoPack,
            },
            {
                when: ['Bag'],
                then: Packaging.Bag,
            },
            {
                when: ['Bulk'],
                then: Packaging.Bulk,
            },
            {
                when: ['Carton', 'box'],
                then: Packaging.Carton,
            },
            {
                when: ['ReReel', 'mousereel', 'Re-Reel'],
                then: Packaging.ReReel,
            },
            {
                when: ['tray', 'trays'],
                then: Packaging.Tray,
            },
            {
                when: ['tube'],
                then: Packaging.Tube,
            },
            {
                when: ['Reel', 'tape/reel', 'tape&reel', 'tr', 't&r'],
                then: Packaging.Reel,
            },
            {
                when: ['Tape', 'cut tape', 'cut-tape', 'ct', 'Gurtabschnitt'],
                then: Packaging.Tape,
            },
        ],
    });
