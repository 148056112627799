import * as r from 'runtypes';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import {
    ManufacturingScenarioDuplicatePostRuntype,
    ManufacturingScenarioFullSummaryRuntype,
    ManufacturingScenarioPatchRuntype,
    ManufacturingScenarioPostRuntype,
    ManufacturingScenarioRuntype,
} from './manufacturingScenarioBackendTypes';

const invalidates: HttpEndpoint[] = [
    'GET /manufacturing-scenarios',
    'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary',
    'GET /manufacturing-scenarios/:manufacturingScenarioId',
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
];

export const manufacturingScenarioEndpoints = {
    'GET /manufacturing-scenarios': endpoint({
        description: 'Fetches a list of manufacturing scenarios of a particular manufacturing assembly details',
        pathParams: r.Undefined,
        queryParams: r.Record({
            /* eslint-disable-next-line camelcase */
            manufacturing_assembly_details_id: r.String.optional(),
            /* eslint-disable-next-line camelcase */
            assembly_id: r.String.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(ManufacturingScenarioRuntype) }),
        invalidates,
    }),
    'POST /manufacturing-scenarios': endpoint({
        description: 'Creates a new manufacturing scenario',
        pathParams: r.Undefined,
        queryParams: r.Record({
            /* eslint-disable-next-line camelcase */
            from_templates: r.Array(r.String).optional(),
            pcb_sides: r.Number.optional(), // TODO: to be used in this story https://www.notion.so/luminovo/FE-Add-sides_of_placement-input-to-PCB-area-3ea60ce4b3b64f44aff648117935ec70 (please remove the comment when story is Done)
        }),
        requestBody: ManufacturingScenarioPostRuntype,
        responseBody: r.Record({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),
    'PATCH /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Edits an existing manufacturing scenario',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: ManufacturingScenarioPatchRuntype,
        responseBody: r.Record({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'POST /manufacturing-scenarios/:manufacturingScenarioId/duplicate': endpoint({
        description:
            'Creates a duplicate from an existing manufacturing scenario, including expense and activity configurations.',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: ManufacturingScenarioDuplicatePostRuntype,
        responseBody: r.Record({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'PATCH /manufacturing-scenarios/:manufacturingScenarioId/append': endpoint({
        description: 'Appends a template to a manufacturing scenario',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Record({
            /* eslint-disable-next-line camelcase */
            from_templates: r.Array(r.String).optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'GET /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Fetches a particular manufacturing scenario ',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: ManufacturingScenarioRuntype }),
    }),

    'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary': endpoint({
        description: 'Fetches a full summary of a particular manufacturing scenario ',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: ManufacturingScenarioFullSummaryRuntype }),
    }),

    'DELETE /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Deletes a particular manufacturing scenario ',
        pathParams: r.Record({ manufacturingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),
    'PATCH /manufacturing-scenarios/customer-portal/update-batch-size-from-sourcing-scenarios-order-size': endpoint({
        description:
            'Syncs the order sizes to batch sizes in all manufacturing scenarios. One example where it should be called is after editing order sizes over the customer portal.',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Null,
        invalidates,
    }),
    'PATCH /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId/rank': endpoint({
        description: 'Sets the new rank of a manufacturing scenario template instance',
        pathParams: r.Record({ manufacturingScenarioTemplateInstanceId: r.String }),
        queryParams: r.Record({}),
        requestBody: r.Record({ rank: r.Number }),
        responseBody: r.Null,
        invalidates,
    }),
    'DELETE /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId': endpoint({
        description: 'Deletes a manufacturing scenario template instance',
        pathParams: r.Record({ manufacturingScenarioTemplateInstanceId: r.String }),
        queryParams: r.Record({}),
        requestBody: r.Null,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
