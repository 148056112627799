/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { Currency, displayCurrencySymbol, formatAmount, formatMonetaryValue, isPresent } from '@luminovo/commons';
import {
    createColumnHelper,
    DestructivePrimaryButton,
    DestructiveSecondaryButton,
    FieldNumericControlled,
    Flexbox,
    SecondaryButton,
    Table,
    TanStackTable,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { compareQuoteRequestLineItems } from '../model/compareQuoteRequestLineItem';
import { extractAwaredOfferFromScenario } from '../model/extractors/extractorNegotionsLineItem';
import { formatPartLite } from '../model/formatPartLite';
import { monetaryValue } from '../model/monetaryValueMath';
import { QuoteRequestForm, useHandleSubmit } from '../model/quoteRequestForm';
import { extractManufacturer } from '../pages/QuoteRequestAssignPage/TableManufacturers';
import { LabelPercentageChange } from './LabelPercentageChange';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { PartLiteLabel } from './PartLiteLabel';
import { Header } from './TableHeader';

type RowDate = QuoteRequestLineItemDTO;

type SharedContext = {
    enableDeleteMode: boolean;
    setEnableDeleteMode: (enable: boolean) => void;
};

const columnHelper = createColumnHelper<RowDate>();

const ipnColumn = columnHelper.text('component_origin', {
    id: 'ipn',
    label: () => t`IPN`,
    size: 150,
    cell: ({ row }) => {
        return <NegotiationIpnLabel ipn={row.original.component_origin} />;
    },
});

const requestedPartColumn = columnHelper.text((row) => formatPartLite(row.requested_part), {
    id: 'requestedPart',
    label: () => t`Requested part`,
    size: 240,
    cell: ({ row }) => <PartLiteLabel variant={'body-small'} part={row.original.requested_part} />,
});

const manufacturerColumn = columnHelper.text((row) => extractManufacturer(row.requested_part).name, {
    id: 'manufacturer',
    label: () => t`Manufacturer`,
    size: 100,
    initialVisibility: false,
});

const descriptionColumn = columnHelper.text((row) => row.description ?? '-', {
    id: 'description',
    label: () => t`Description`,
    size: 200,
});

const requiredQuantityColumn = columnHelper.number((row) => row.required_quantity.quantity, {
    id: 'requiredQuantity',
    label: () => t`Qty`,
    size: 100,
    cell: ({ row }) => formatQuantity(row.original.required_quantity, { showPiecesUnit: false }),
});

const potentialQuantityColumn = columnHelper.number((row) => row.potential_quantity.quantity, {
    id: 'potentialQuantity',
    size: 100,
    label: () => t`Potential Qty`,
    cell: ({ row }) => formatQuantity(row.original.potential_quantity ?? 0, { showPiecesUnit: false }),
});

const recipientsColumn = columnHelper.text((row) => row.recipients ?? '-', {
    id: 'recipients',
    label: () => t`Ship to`,
    size: 100,
    initialVisibility: false,
});

const referenceUnitPriceColumn = columnHelper.monetaryValue(() => null, {
    id: 'referenceUnitPrice',
    label: () => t`Reference unit price`,
    header: () => <Header title={t`Unit price`} subtitle={t`Reference`} />,
    size: 100,
    enableSorting: false,
    enableColumnFilter: false,
    cell: function Cell({ row }) {
        const { referenceScenario } = useNegotiationsContext();

        if (!isPresent(referenceScenario)) {
            return '-';
        }

        const referenceUnitPrice = extractAwaredOfferFromScenario(
            row.original.negotiation_line_item_id,
            referenceScenario!,
        )?.awarded_solution.unit_price;
        return formatMonetaryValue(referenceUnitPrice, 'unit-price', { ifAbsent: '-' });
    },
});

const targetPriceColumn = columnHelper.monetaryValue('target_price', {
    id: 'targetPrice',
    label: () => t`Target price`,
    size: 160,
    cell: ({ row }) => <TargetPriceFormInput quoteRequestLineItem={row.original} />,
});

const customerNameColumn = columnHelper.text((row) => row.customers ?? '-', {
    id: 'customerName',
    label: () => t`Customer`,
    size: 100,
});

export function TableQuoteRequestLineItemsForm({ lineItems }: { lineItems: QuoteRequestLineItemDTO[] | undefined }) {
    const { control } = useFormContext<QuoteRequestForm>();
    const { showTargetPrice, showCustomerName } = useWatch({ control });
    const [enableDeleteMode, setEnableDeleteMode] = React.useState(false);

    const columns = React.useMemo(() => {
        return [
            ipnColumn,
            descriptionColumn,
            requestedPartColumn,
            manufacturerColumn,
            showCustomerName ? customerNameColumn : null,
            recipientsColumn,
            requiredQuantityColumn,
            potentialQuantityColumn,
            showTargetPrice ? referenceUnitPriceColumn : null,
            showTargetPrice ? targetPriceColumn : null,
        ].filter(isPresent);
    }, [showTargetPrice, showCustomerName]);

    const { table } = useTanStackTable({
        data: lineItems?.sort(compareQuoteRequestLineItems()),
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enableExcelExport: true,
        enableSelection: {
            enabled: enableDeleteMode,
            getRowId: (row) => String(row.id),
        },
        sharedContext: { enableDeleteMode, setEnableDeleteMode },
    });

    return <TanStackTable table={table} ActionButton={ActionButton} />;
}

function ActionButton({
    table,
    sharedContext,
}: {
    table: Table<QuoteRequestLineItemDTO>;
    sharedContext: SharedContext;
}) {
    const { enableDeleteMode, setEnableDeleteMode } = sharedContext;
    const { mutateAsync, isLoading } = useHttpMutation('POST /quote-request/line-item/delete', {
        snackbarMessage: 'Quote request line items deleted',
        onSuccess: () => {
            table.resetRowSelection();
            setEnableDeleteMode(false);
        },
    });

    if (enableDeleteMode) {
        const disabled = !(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected());
        return (
            <Flexbox gap={4}>
                <SecondaryButton
                    size="medium"
                    onClick={() => {
                        table.resetRowSelection();
                        setEnableDeleteMode(false);
                    }}
                >
                    Cancel
                </SecondaryButton>
                <Tooltip title={disabled ? 'Select rows to delete' : ''}>
                    <span>
                        <DestructivePrimaryButton
                            size="medium"
                            disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                            startIcon={<Delete />}
                            isLoading={isLoading}
                            onClick={() => {
                                const ids = table.getSelectedRowModel().rows.map((row) => row.original.id);
                                mutateAsync({ requestBody: { ids } });
                            }}
                        >
                            Delete
                        </DestructivePrimaryButton>
                    </span>
                </Tooltip>
            </Flexbox>
        );
    }

    return (
        <DestructiveSecondaryButton size="medium" startIcon={<Delete />} onClick={() => setEnableDeleteMode(true)}>
            Delete line items
        </DestructiveSecondaryButton>
    );
}

const TargetPriceFormInput = ({ quoteRequestLineItem }: { quoteRequestLineItem: QuoteRequestLineItemDTO }) => {
    const { referenceScenario } = useNegotiationsContext();
    const { control } = useFormContext<QuoteRequestForm>();
    const { isSubmitting } = useFormState<QuoteRequestForm>();
    const { mutateAsync: submit } = useHandleSubmit();

    const index = useWatch({ control, name: `lineItemTargetPrices` }).findIndex(
        (x) => x.quoteRequestLineItemId === quoteRequestLineItem.id,
    );

    const referenceUnitPrice =
        extractAwaredOfferFromScenario(quoteRequestLineItem.negotiation_line_item_id, referenceScenario!)
            ?.awarded_solution.unit_price ?? null;

    const targetPrice = useWatch({ control, name: `lineItemTargetPrices.${index}.targetPrice` });

    return (
        <Flexbox gap={4}>
            <FieldNumericControlled
                key={`${index}-${isSubmitting}`}
                control={control}
                name={`lineItemTargetPrices.${index}.targetPrice.amount`}
                FieldProps={{
                    disabled: isSubmitting,
                    onBlurCapture: () => submit(),
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                {displayCurrencySymbol(targetPrice?.currency ?? Currency.EUR)}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <LabelPercentageChange
                                    percent={
                                        (monetaryValue.relativeDifference(
                                            formatAmount(referenceUnitPrice, 'unit-price'),
                                            targetPrice,
                                        ) ?? NaN) * -1
                                    }
                                    formatPercentageOptions={{ minimumFractionDigits: 2 }}
                                />
                            </InputAdornment>
                        ),
                    },
                }}
            />
        </Flexbox>
    );
};
