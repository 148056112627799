export function RigidIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="44" height="8" viewBox="0 0 44 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="1" y="1" width="42" height="6" rx="3" fill="#5767EC" stroke="#D9E0FF" stroke-width="2" />
        </svg>
    );
}

export function FlexIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="42" height="6" viewBox="0 0 42 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1 5H5.24959C7.99046 5 10.6912 4.34181 13.1248 3.08079V3.08079C18.0631 0.521838 23.9369 0.521838 28.8752 3.08079V3.08079C31.3088 4.34181 34.0095 5 36.7504 5H41"
                stroke="#5767EC"
                stroke-width="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export function RigidFlexIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="44" height="12" viewBox="0 0 44 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.92603 7H10.6734C12.7312 7 14.7281 6.3011 16.3367 5.01781V5.01781C19.6498 2.3749 24.3504 2.3749 27.6635 5.01781V5.01781C29.2721 6.3011 31.269 7 33.3268 7H36.0742"
                stroke="#5767EC"
                stroke-width="2"
                strokeLinecap="round"
            />
            <rect x="1" y="4.5" width="13.8519" height="6" rx="3" fill="#5767EC" stroke="#D9E0FF" stroke-width="2" />
            <rect
                x="29.1482"
                y="4.5"
                width="13.8519"
                height="6"
                rx="3"
                fill="#5767EC"
                stroke="#D9E0FF"
                stroke-width="2"
            />
        </svg>
    );
}

export function IMSIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="42" height="10" viewBox="0 0 42 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="41" height="3" rx="1.5" fill="#5767EC" stroke="#D9E0FF" />
            <rect x="0.5" y="6.5" width="41" height="3" rx="1.5" fill="#5767EC" stroke="#D9E0FF" />
            <path d="M2 5L40 5" stroke="#5767EC" stroke-width="2" strokeLinecap="round" stroke-dasharray="6 6" />
        </svg>
    );
}
