import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { GudecoCredentialsInputDTORuntype, GudecoResponseBodyDTORuntype } from './gudecoBackendTypes';

export const gudecoEndpoints = {
    'GET /3rdparty/gudeco/credentials': endpoint({
        description: 'Returns credentials for gudeco integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: GudecoResponseBodyDTORuntype,
    }),
    'POST /3rdparty/gudeco/credentials': endpoint({
        description: 'Uploads credentials for gudeco API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: GudecoCredentialsInputDTORuntype,
        responseBody: GudecoResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
    'PATCH /3rdparty/gudeco/credentials': endpoint({
        description: 'Updates credentials for gudeco API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: GudecoCredentialsInputDTORuntype,
        responseBody: GudecoResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
    'DELETE /3rdparty/gudeco/credentials': endpoint({
        description: 'Deletes existing credentials for gudeco API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
};
