import { Chip, colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { ResourceType } from '@luminovo/http-client';
import { ResourceIcon } from '../icons/ResourceIcon';
import { TooltipText } from '../labels';

export interface ResourceChipProps {
    name: string;
    type: ResourceType;
    multiplier: string;
}

export const ResourceChip = ({ name, type, multiplier }: ResourceChipProps): JSX.Element => {
    return (
        <Chip
            color={'neutral'}
            label={
                <Flexbox flexWrap={'nowrap'} alignItems={'center'} gap={'4px'}>
                    <TooltipText text={name} minCharactersWithTooltip={10} maxWidth={150} />
                    <Text variant="body" style={{ color: colorSystem.neutral[9] }}>
                        x{multiplier}
                    </Text>
                </Flexbox>
            }
            startIcon={<ResourceIcon resourceType={type} heightAndWidth={'14px'} />}
        />
    );
};
