import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, GreenHighlight, Highlight, HighlightMatcher, Text } from '@luminovo/design-system';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { PreferredIpnTag } from '@luminovo/sourcing-core';
import { TableCell } from './TableCell';

export const RenderIpn = ({
    part,
    query = [],
    onClick,
}: {
    part: FullPart;
    query?: HighlightMatcher;
    onClick?: () => void;
}): JSX.Element => {
    if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
        const isPreferred = part.is_preferred;
        const isRemoved = part.state === 'Removed';
        if (isRemoved) {
            return (
                <TableCell onClick={onClick} isComponentRemoved={isRemoved}>
                    {part.id}
                </TableCell>
            );
        }
        return (
            <TableCell onClick={onClick}>
                <Flexbox alignItems="center" gap="4px">
                    <Highlight
                        label={part.id}
                        matcher={query}
                        overrides={{
                            Container: ({ ...props }) => {
                                return <Text {...props} variant="body-small" />;
                            },
                            FullMatchHighlighter: GreenHighlight,
                        }}
                    />
                    {isPreferred && <PreferredIpnTag />}
                </Flexbox>
            </TableCell>
        );
    }
    if (isGenericFullPart(part) || isOtsFullPart(part) || isCustomFullPart(part)) {
        return <TableCell onClick={onClick}>-</TableCell>;
    }

    assertUnreachable(part);
};
