interface Batch<T> {
    fromIndex: number;
    items: T[];
}

/**
 * Splits an array into batches of a given size, then maps each batch using the provided function.
 *
 * @param items The array that will be processed in batches.
 * @param batchSize The maximum number of items in each batch.
 * @param mapBatch A function that maps a batch of items to a new array.
 *
 * @returns An async generator that let's you iterate over the results of each batch.
 */
export async function* iterateBatches<T, K>(
    items: T[],
    {
        batchSize,
        mapBatch,
    }: {
        batchSize: number;
        mapBatch: (batch: Batch<T>) => Promise<K[]>;
    },
): AsyncGenerator<K[]> {
    const batches = splitIntoBatches(items, batchSize);

    for (const batch of batches) {
        const results = await mapBatch(batch);
        yield results;
    }
}

function splitIntoBatches<T>(items: T[], batchSize: number): Batch<T>[] {
    const batches: Batch<T>[] = [];

    for (let i = 0; i < items.length; i += batchSize) {
        const batch = items.slice(i, i + batchSize);
        batches.push({
            fromIndex: i,
            items: batch,
        });
    }

    return batches;
}
