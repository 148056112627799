import { t } from '@lingui/macro';
import { PageLayout } from '../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { ExpenseForm } from './ExpenseForm';
import { createDefaultExpenseFormValues } from './utils/convertExpenseFormValues';
import { createExpenseSchema } from './utils/expenseFormValidationSchema';

const AddExpenseForm = (): JSX.Element => {
    const { data: drivers } = useHttpQuery('GET /user-drivers', {});
    const { data: expenses } = useHttpQuery('GET /expenses', { queryParams: {} });
    const { preferredCurrency, isLoading } = useGlobalCurrency();
    if (drivers && expenses && !isLoading) {
        return (
            <ExpenseForm
                schema={createExpenseSchema(expenses.data, drivers.data)}
                defaultValues={createDefaultExpenseFormValues(preferredCurrency)}
                onSubmitType={'POST'}
                formTitle={t`Add expense`}
            />
        );
    }

    return <SpinnerWithBackdrop />;
};

export const AddExpensePage = (): JSX.Element => {
    return (
        <PageLayout layout="full-width">
            <AddExpenseForm />
        </PageLayout>
    );
};
