import { Trans, t } from '@lingui/macro';
import { BaseButtonProps, PrimaryButton, Tooltip } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import React from 'react';
import { useMutationInviteCustomerUsers } from '../../../resources/user/userHandler';
import { DialogInviteUsers } from './DialogInviteUsers';

export function ButtonInviteUsers({
    customer,
    disabled,
    overrides,
}: {
    customer: CustomerDTO;
    disabled?: boolean;
    overrides?: {
        Button: React.ComponentType<BaseButtonProps>;
    };
}) {
    const { Button } = overrides ?? { Button: PrimaryButton };

    const [isDialogOpen, setShowDialog] = React.useState(false);
    function handleClose() {
        setShowDialog(false);
    }

    const { mutateAsync } = useMutationInviteCustomerUsers(customer.id);

    return (
        <>
            <DialogInviteUsers
                open={isDialogOpen}
                onClose={handleClose}
                onSubmit={async (form) => {
                    await mutateAsync(form.data);
                }}
            />

            <Tooltip title={t`Invite users to ${customer.name}`}>
                <Button
                    size="medium"
                    startIcon={<Add />}
                    onClick={() => setShowDialog(true)}
                    disabled={disabled ?? false}
                >
                    <Trans>Add users</Trans>
                </Button>
            </Tooltip>
        </>
    );
}
