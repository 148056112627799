import { assertUnreachable, compareByLexorank, compareByString } from '@luminovo/commons';
import { DriversOfManufacturingDetailsDTO, SystemDriverName, UserDriverDetailsDTO } from '@luminovo/http-client';
import { SIDES_OF_PLACEMENT_NAME } from '../inputControls';

/**
 * Sides of placement drivers go first, then
 * system drivers (sorted alphabetically), then
 * user drivers (sorted by lexorank)
 */
export const compareByDriverTypeAndName = (
    a: DriversOfManufacturingDetailsDTO,
    b: DriversOfManufacturingDetailsDTO,
): number => {
    // Sides of placement driver goes to the top
    if (a.driver.type === 'User' && a.driver.data.name === SIDES_OF_PLACEMENT_NAME) {
        return -1;
    }
    if (b.driver.type === 'User' && b.driver.data.name === SIDES_OF_PLACEMENT_NAME) {
        return 1;
    }
    const aType = a.driver.type;
    switch (aType) {
        case 'System':
            const aSystemDriverName: SystemDriverName = a.driver.data;
            return compareWhenFirstCaseIsSystem(aSystemDriverName, b);
        case 'User':
            const aUserDriverDetails: UserDriverDetailsDTO = a.driver.data;
            return compareWhenFirstCaseIsUser(aUserDriverDetails, b);
        default:
            assertUnreachable(aType);
    }
};

const compareWhenFirstCaseIsSystem = (a: SystemDriverName, b: DriversOfManufacturingDetailsDTO) => {
    const bType = b.driver.type;
    switch (bType) {
        case 'System':
            //system drivers are compared alphabetically
            return compareByString(a, b.driver.data);
        case 'User':
            //system is sorted before user
            return -1;
        default:
            assertUnreachable(bType);
    }
};

const compareWhenFirstCaseIsUser = (a: UserDriverDetailsDTO, b: DriversOfManufacturingDetailsDTO) => {
    const bType = b.driver.type;
    switch (bType) {
        case 'System':
            //system is sorted before user
            return 1;
        case 'User':
            //user drivers are compared by lexoranks
            return compareByLexorank(a.lexorank, b.driver.data.lexorank);
        default:
            assertUnreachable(bType);
    }
};
