import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation, IpnHistoryData } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, IPNContent, TimelineDeleteIcon, TimelineEditIcon } from './Utils';

type Props = {
    actor: ActorResponseRuntypeDTO;
    data: IpnHistoryData;
    operation: HistoryOperation;
};

function useIPNDataAction(actor: ActorResponseRuntypeDTO, operation: HistoryOperation) {
    switch (operation) {
        case HistoryOperation.ManualRemove:
            return (
                <>
                    <Trans>removed</Trans> {isActorDefined(actor) && <ActionPerformedBy actor={actor} />}
                </>
            );

        case HistoryOperation.ManualUpdate:
            return (
                <>
                    <Trans>changed</Trans> {isActorDefined(actor) && <ActionPerformedBy actor={actor} />}
                </>
            );
        default:
            return null;
    }
}

export default function InternalPartNumberData({ actor, data, operation }: Props) {
    const action = useIPNDataAction(actor, operation);
    if (!action) return null;
    return <IPNContent data={data}>{action}</IPNContent>;
}

export function InternalPartNumberDataTimelineIcon({
    operation,
    data,
}: {
    operation: HistoryOperation;
    data: IpnHistoryData;
}) {
    if (operation === HistoryOperation.ManualUpdate) return <TimelineEditIcon />;
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;
    return null;
}
