import { AssemblyRecognitionError, BomLineBuildingErrorOutput, ScreenerHandlerOutput } from '@luminovo/http-client';

export type OutputWithIssues = ScreenerHandlerOutput | AssemblyRecognitionError;

export interface BomImporterScreeningDialogState {
    outputWithIssues: OutputWithIssues | undefined;
}
export function isScreenerHandlerOutput(output?: OutputWithIssues): output is ScreenerHandlerOutput {
    if (!output) return false;
    return 'sheets' in output;
}

export function isAssemblyRecognitionError(output?: OutputWithIssues): output is AssemblyRecognitionError {
    if (!output) return false;
    return 'context' in output;
}

export function convertBomLineErrorOutputToBOMScreeningDialogState(
    output: BomLineBuildingErrorOutput,
): BomImporterScreeningDialogState {
    if ('sheet_name' in output.data)
        return {
            outputWithIssues: {
                file_name: output.file_name,
                sheets: [output.data],
            },
        };
    return {
        outputWithIssues: output.data,
    };
}
