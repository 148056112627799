import { Trans } from '@lingui/macro';
import { Tag } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import { Grid, Typography } from '@mui/material';
import { assemblyTypePublicTranslations } from '../resources/rfq/i18n';
import { colorSystem } from '../themes';
import { TransEnum } from './localization/TransEnum';

interface AssemblyTypesProps {
    assemblyTypes: AssemblyTypeEnum[];
}

export const AssemblyTypes = ({ assemblyTypes }: AssemblyTypesProps): JSX.Element => {
    if (assemblyTypes.length > 0) {
        return (
            <Grid container spacing={1}>
                {assemblyTypes.map((type, index) => {
                    if (type === 'BOM') {
                        return null;
                    } else {
                        return (
                            <Grid item key={index}>
                                <Tag
                                    attention="low"
                                    color={'neutral'}
                                    label={<TransEnum text={type} translations={assemblyTypePublicTranslations} />}
                                />
                            </Grid>
                        );
                    }
                })}
            </Grid>
        );
    } else {
        return (
            <Typography variant="body1" style={{ color: colorSystem.neutral[5] }}>
                <Trans>No assemblies</Trans>
            </Typography>
        );
    }
};
