import { t } from '@lingui/macro';
import { CadState, CustomerPortalRequirement } from '@luminovo/http-client';
import { StepContainer } from './StepContainer';

export const CadStep = ({
    cadState,
    rfqId,
    assemblyId,
    isRfqEditable,
    requirement,
}: {
    cadState: CadState;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    requirement: CustomerPortalRequirement;
}): JSX.Element => {
    const status = cadState.status.type;

    return (
        <StepContainer
            status={status}
            label={t`CAD`}
            type="cad"
            rfqId={rfqId}
            assemblyId={assemblyId}
            isRfqEditable={isRfqEditable}
            requirement={requirement}
        />
    );
};
