import { Trans } from '@lingui/macro';
import { Column, columnWidth, Row } from '@luminovo/design-system';
import { TooltipText } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';

interface RowWithDescriptionNullable {
    description: string | null;
}

export function getDescriptionColumn<C>(): Column<RowWithDescriptionNullable, C> {
    return {
        label: <Trans>Description</Trans>,
        id: 'description',
        render: ({ data: rowData }: Row<RowWithDescriptionNullable>) => {
            const text = rowData.description?.trim() ?? '-';
            return (
                <TableCell>
                    <TooltipText text={text} maxWidth={'220px'} minCharactersWithTooltip={3} />
                </TableCell>
            );
        },
        width: columnWidth.medium,
        searchable: { searchBy: (rowData): string => rowData.description ?? '' },
    };
}
