import { Trans, t } from '@lingui/macro';
import { compareByBoolean, compareByStringKey } from '@luminovo/commons';
import { Tag, Text, Tooltip, chainComparators, colorSystem } from '@luminovo/design-system';
import { CheckCircle, Error } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { ImporterField } from '../../../../types';
import { useUniversalImporter } from '../../context';
import { GlobalFieldsForm } from '../globalFields/StepSetGlobalFields';
import { FormItemDateFormats } from './FormItemDateFormats';
import { FormItemDecimalSeparator } from './FormItemDecimalSeparator';
import { TableMapColumns } from './TableMapColumns';

export function StepLinkColumns(): JSX.Element {
    const { state } = useUniversalImporter();
    const { table } = state;

    if (!table) {
        return <></>;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',
                boxSizing: 'border-box',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gap: 0,
                    gridTemplateColumns: '1fr 0.75fr',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        padding: 3,
                        height: 'max(calc(100% - 60px), calc(100vh - 60px))',
                        boxSizing: 'border-box',
                    }}
                >
                    <TableMapColumns table={table} />
                </Box>
                <Box
                    sx={{
                        position: 'sticky',
                        zIndex: 2,
                        top: 0,
                        right: 0,
                        minWidth: 'fit-content',
                        padding: 2,
                        height: 'max(calc(100% - 60px), calc(100vh - 60px))',
                        borderLeft: `1px solid ${colorSystem.neutral[2]}`,
                        backgroundColor: colorSystem.neutral.white,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        overflowY: 'auto',
                    }}
                >
                    <Text variant="h1">
                        <Trans>Global fields</Trans>
                    </Text>

                    {state.config.globalFields && state.config.globalFields.length > 0 && (
                        <>
                            <GlobalFieldsForm />
                        </>
                    )}
                    <FormItemDecimalSeparator />
                    <FormItemDateFormats />

                    <Text variant="h1">
                        <Trans>Fields</Trans>
                    </Text>
                    <div style={{ height: 200 }}>
                        <FieldDescriptions showDescription={false} fields={state.config.fields} />
                        {/* <Divider style={{ margin: '16px -16px' }} /> */}
                    </div>
                </Box>
            </Box>
        </Box>
    );
}

export function FieldDescriptions({
    fields,
    showDescription = true,
    showLinked = true,
    useColumnsLabel = false,
}: {
    fields: readonly ImporterField[];
    showDescription?: boolean;
    showLinked?: boolean;
    useColumnsLabel?: boolean;
}): JSX.Element {
    const isAnyFieldEnum = fields.some((field) => field.parser.type === 'enum');
    const autoCount = 3 + (showLinked ? 1 : 0) + (showDescription ? 1 : 0) + (isAnyFieldEnum ? 1 : 0);

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: Array(autoCount).fill('auto').join(' '), gap: 2 }}>
            <Text variant="h5">{useColumnsLabel ? t`Columns` : t`Field`}</Text>
            <Text variant="h5">
                <Trans>Required</Trans>
            </Text>
            <Text variant="h5">
                <Trans>Default</Trans>
            </Text>

            {showLinked && (
                <Text variant="h5">
                    <Trans>Linked</Trans>
                </Text>
            )}
            {showDescription && (
                <Text variant="h5">
                    <Trans>Description</Trans>
                </Text>
            )}
            {isAnyFieldEnum && (
                <Text variant="h5" style={{ maxWidth: 200 }}>
                    <Trans>Supported values</Trans>
                </Text>
            )}
            {Array.from(fields)
                .sort(
                    chainComparators(
                        compareByBoolean((field) => Boolean(field.required)),
                        compareByStringKey((field) => String(field.required)),
                    ),
                )
                .map((field: ImporterField) => {
                    return (
                        <React.Fragment key={field.id}>
                            <Tag
                                attention="low"
                                color={field.required ? 'blue' : 'neutral'}
                                label={field.label ?? field.id}
                            />

                            <Text variant="body-small">{<RequiredLabel required={field.required} />}</Text>
                            <Text variant="body-small">{field.defaultValue?.label ?? '-'}</Text>

                            {showLinked && (
                                <Text>
                                    {field.required && field.columnIndices.length === 0 && (
                                        <Tooltip title={t`Map this required field to continue`}>
                                            <Error style={{ color: colorSystem.red[6], height: 16 }} />
                                        </Tooltip>
                                    )}
                                    {!field.required && field.columnIndices.length === 0 && (
                                        <CheckCircle style={{ color: colorSystem.neutral[5], height: 16 }} />
                                    )}
                                    {field.columnIndices.length > 0 && (
                                        <CheckCircle style={{ color: colorSystem.green[6], height: 16 }} />
                                    )}
                                </Text>
                            )}
                            {showDescription && <Text variant="body-small">{field.description}</Text>}
                            {isAnyFieldEnum && (
                                <Text variant="body-small" style={{ maxWidth: 200 }} showEllipsis>
                                    {field.parser.type === 'enum'
                                        ? field.parser.options.cases.map((c) => c.matches.join(', ')).join(', ')
                                        : '-'}
                                </Text>
                            )}
                        </React.Fragment>
                    );
                })}
        </Box>
    );
}

function RequiredLabel({
    required,
}: {
    required: boolean | { color: 'green' | 'violet'; label: string };
}): JSX.Element {
    if (typeof required === 'boolean') {
        return <>{required ? t`Yes` : t`No`}</>;
    }

    return <Tag attention="low" color={required.color} label={required.label} />;
}
