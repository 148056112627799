import { Box } from '@mui/material';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import * as React from 'react';
import { LayerDefaultMode } from './components/LayerDefaultMode';
import { LayerInspectMode } from './components/LayerInspectMode';
import { LayerSelectedMode } from './components/LayerSelectedMode';
import { PdfErrorBoundary } from './components/PdfErrorBoundary';
import { PdfViewerActions } from './components/PdfViewerActions';
import { RegionTooltipType } from './components/PolygonView';
import { usePdfViewerState } from './hooks/usePdfViewerState';
import { useRenderPage } from './hooks/useRenderPage';

interface PdfViewerProps {
    pdf: PDFDocumentProxy;
    pdfViewerOptions?: {
        regionTooltip?: RegionTooltipType;
        actionStyles?: React.CSSProperties;
        actions?: React.ReactNode;
    };
}

export function PdfViewer(props: PdfViewerProps): JSX.Element {
    return (
        <PdfErrorBoundary>
            <PdfViewerInner {...props} />
        </PdfErrorBoundary>
    );
}

function PdfViewerInner({ pdf, pdfViewerOptions }: PdfViewerProps): JSX.Element {
    const [scale, setScale] = React.useState(1.3);

    const ref = React.useRef<HTMLDivElement>(null);

    const [, dispatch] = usePdfViewerState();

    const handleKeyUp = React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                dispatch({ type: 'escape' });
            }
        },
        [dispatch],
    );

    return (
        <Box
            onMouseEnter={() => dispatch({ type: 'escape' })}
            tabIndex={0}
            onKeyUp={handleKeyUp}
            style={{
                outline: 'none',
                padding: '0',
                position: 'relative',
                minWidth: 'fit-content',
            }}
        >
            <div
                ref={ref}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                    alignItems: 'center',
                }}
            >
                {Array(pdf.numPages)
                    .fill(undefined)
                    .map((_, i) => {
                        return (
                            <PdfPageCanvas
                                key={i + '-' + scale}
                                pdf={pdf}
                                pageNumber={i + 1}
                                scale={scale}
                                pdfViewerOptions={pdfViewerOptions}
                            />
                        );
                    })}
            </div>

            <PdfViewerActions
                setScale={setScale}
                style={pdfViewerOptions?.actionStyles}
                actions={pdfViewerOptions?.actions}
            />
        </Box>
    );
}

let initialized = false;
async function initPdfjs(): Promise<void> {
    if (initialized) {
        return;
    }
    const { version, GlobalWorkerOptions } = await import('pdfjs-dist');
    // @ts-ignore
    const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker?worker');
    // @ts-ignore
    window.pdfjsWorker = pdfjsWorker;
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;
    initialized = true;
}

export async function getPdfDocument(url: URL | string): Promise<PDFDocumentProxy> {
    await initPdfjs();
    const { getDocument } = await import('pdfjs-dist');

    const task = await getDocument({
        url,
        isEvalSupported: false,
    }).promise;
    return task;
}

function PdfPageCanvas({
    pdf,
    pdfViewerOptions,
    pageNumber,
    scale = 1.2,
}: PdfViewerProps & {
    pageNumber: number;
    scale?: number;
}) {
    const refTextLayer = React.useRef<HTMLDivElement>(null);
    const [state] = usePdfViewerState();
    const { ref } = useRenderPage(pdf, { pageNumber, scale, refTextLayer });
    const width = ref.current?.clientWidth ?? 0;
    const height = ref.current?.clientHeight ?? 0;

    function capturePointerEvents() {
        if (refTextLayer.current) {
            refTextLayer.current.style.pointerEvents = 'auto';
        }
    }

    function doNotCapturePointerEvents() {
        // we add a small delay to allow capturing double clicks for text selection
        setTimeout(() => {
            if (refTextLayer.current) {
                refTextLayer.current.style.pointerEvents = 'none';
            }
        }, 300);
    }

    const layerDefault =
        state.mode.type === 'default' ? (
            <LayerDefaultMode
                width={width}
                height={height}
                pageNumber={pageNumber}
                regionTooltip={pdfViewerOptions?.regionTooltip}
            />
        ) : null;

    const layerSelected =
        state.mode.type === 'selected' ? (
            <LayerSelectedMode width={width} height={height} pageNumber={pageNumber} />
        ) : null;

    const layerInspect =
        state.mode.type === 'inspect' ? (
            <LayerInspectMode width={width} height={height} pageNumber={pageNumber} />
        ) : null;

    return (
        <span
            className="page"
            data-page-number={pageNumber}
            style={{
                borderRadius: 4,
                position: 'relative',
            }}
            // on mouse down we allow for text selection
            onMouseDown={capturePointerEvents}
            // on mouse up we return to letting the default layer capture the on-hover events (for tooltips)
            onMouseUp={doNotCapturePointerEvents}
        >
            <canvas ref={ref} />
            <div
                className="textLayer"
                ref={refTextLayer}
                style={{
                    zIndex: 1,
                    // @ts-ignore
                    '--scale-factor': scale,
                    // By default allow events to pass through to default layer (for on-hover tooltips)
                    pointerEvents: 'none',
                }}
            />

            {ref.current && width > 0 && height > 0 && (
                <>
                    {layerDefault}
                    {layerSelected}
                    {layerInspect}
                </>
            )}
        </span>
    );
}
