import { SecondaryIconButton } from '@luminovo/design-system';
import { DescriptionRounded } from '@mui/icons-material';
import { useCallback } from 'react';

export const DatasheetIconButton = ({ url }: { url: string }): JSX.Element => {
    const handleClick = useCallback(
        (e) => {
            e.stopPropagation();
            if (!url) {
                return;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        },
        [url],
    );

    return (
        <SecondaryIconButton size="small" onClick={handleClick}>
            <DescriptionRounded fontSize="inherit" />
        </SecondaryIconButton>
    );
};
