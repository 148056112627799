import {
    CustomComponentOfferDTO,
    CustomOptionOfferDTO,
    CustomPartOfferDTO,
    InternalPartNumberOfferDTO,
    OffTheShelfOfferDTO,
    StandardPartInventoryOfferDTO,
    StandardPartMarketOfferDTO,
    StandardPartOfferDTO,
} from '@luminovo/http-client';

export function isStandardPartMarketOffer(offer: StandardPartOfferDTO): offer is StandardPartMarketOfferDTO {
    return offer.linked_location.type === 'SupplierAndStockLocation';
}

export function isStandardPartInventoryOffer(offer: StandardPartOfferDTO): offer is StandardPartInventoryOfferDTO {
    return offer.linked_location.type === 'InventorySite';
}

export function isStandardPartOffer(offer: StandardPartOfferDTO | CustomOptionOfferDTO): offer is StandardPartOfferDTO {
    return offer.linked_part.type === 'OffTheShelf' || offer.linked_part.type === 'InternalPartNumber';
}

export function isOffTheShelfPartOffer(offer: StandardPartOfferDTO): offer is OffTheShelfOfferDTO {
    return offer.linked_part.type === 'OffTheShelf';
}

export function isInternalPartNumberOffer(offer: StandardPartOfferDTO): offer is InternalPartNumberOfferDTO {
    return offer.linked_part.type === 'InternalPartNumber';
}

export function isCustomPartOffer(offer: StandardPartOfferDTO | CustomOptionOfferDTO): offer is CustomPartOfferDTO {
    return offer.linked_part.type === 'CustomPart';
}

export function isCustomComponentOffer(
    offer: StandardPartOfferDTO | CustomOptionOfferDTO,
): offer is CustomComponentOfferDTO {
    return offer.linked_part.type === 'CustomComponent';
}
