import { Select } from '@luminovo/design-system';
import { ApprovalStatus, ApprovalStatusRuntype } from '@luminovo/http-client';
import { MenuItem, styled } from '@mui/material';
import * as React from 'react';
import { analytics } from '../utils/analytics';
import { iconForStatusWithTooltip } from './Icons/icons';
import { approvalStatusTranslations } from './i18n';
import { transEnum } from './localization/TransEnum';

/**
 * If you're looking for a react-hook-form compatible version, search for ApprovalStatusSelectControlled
 */
export const ApprovalStatusSelect = React.forwardRef(
    (
        props: {
            disabled?: boolean;
            value: ApprovalStatus;
            onChange: (status: ApprovalStatus) => void;
            onBlur?: () => void;
        },
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        return (
            <StyledSelect
                ref={ref}
                value={props.value}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                    event.stopPropagation();
                    if (ApprovalStatusRuntype.guard(event.target.value)) {
                        /* eslint-disable camelcase */
                        analytics.track('change_part_status', {
                            old_status: props.value,
                            new_status: event.target.value,
                        });
                        /* eslint-enable camelcase */
                        props.onChange(event.target.value);
                    }
                }}
                disabled={props.disabled}
                onBlur={props.onBlur}
            >
                {[ApprovalStatus.Approved, ApprovalStatus.Pending, ApprovalStatus.Rejected].map((status, i) => {
                    return (
                        <MenuItem key={i} value={status} style={{ fontSize: '16px' }}>
                            {iconForStatusWithTooltip({
                                status,
                                tooltip: transEnum(status, approvalStatusTranslations),
                            })}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        );
    },
);

const StyledSelect = styled(Select)({
    height: '24px',
    boxSizing: 'border-box',
    fontSize: '16px',
    '& .MuiSelect-select:focus': {
        background: 'none',
    },
    '& .MuiSelect-select': {
        paddingTop: '16px',
    },
});
