import { isPresent } from '@luminovo/commons';
import { compareByConfidence } from '../compareByConfidence';
import { Attribute, MergedAttributes, Region } from '../types';
import { groupBy } from './groupBy';

/**
 * Takes as input a list of regions returned a MergedAttributes object.
 *
 * Take a look at the test cases for examples.
 */
export function mergeByConfidence<TAttribute extends Attribute>(
    regions: Region<TAttribute>[],
): MergedAttributes<TAttribute> {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );

    const mergedAttributes = groupBy(regionsWithAttributes, ({ attribute }) => attribute.attr)
        .map(({ items }) => {
            return mergeAttributes(items);
        })
        .filter(isPresent);

    const result: MergedAttributes<TAttribute> = {} as MergedAttributes<TAttribute>;
    for (const { attribute, region } of mergedAttributes) {
        const attr = attribute.attr as keyof MergedAttributes<TAttribute>;

        if (result[attr]) {
            throw new Error(`Attribute ${attr} already exists`);
        }

        result[attr] = {
            regions: [region],
            value: attribute.value as any,
        };
    }
    return result;
}

function mergeAttributes<TAttribute extends Attribute>(
    items: Array<{ region: Region<TAttribute>; attribute: TAttribute }>,
):
    | {
          region: Region<TAttribute>;
          attribute: TAttribute;
      }
    | undefined {
    if (items.length === 0) {
        return undefined;
    }

    const elems = Array.from(items).sort((a, b) => {
        return compareByConfidence(a.attribute, b.attribute);
    });
    return elems[0];
}
