import { t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Message, Text } from '@luminovo/design-system';
import { Link } from 'react-router-dom';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { route } from '../../../utils/routes';

export const RfqWelcomeMessage = () => {
    const { user, customer } = useCurrentUserDetailsContext();
    const isOemUser = isPresent(customer);
    const { data: selfSignupSettings } = useHttpQuery('GET /organization-settings/self-signup', {});
    const isCompanySettingsEnabled = selfSignupSettings?.is_customer_user_management_enabled ?? false;
    const isAllowedToInvite = useHasPermission(['edit:user:customer']);

    if (!isOemUser) {
        // Do not show the welcome message if the user is not an OEM user
        // It otherwise just takes up space in the welcome screen.
        return null;
    }

    return (
        <Flexbox gap={'8px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Flexbox flexDirection={'column'} gap={'8px'}>
                <Text variant="h2">{t`Welcome ${user.first_name} ${user.last_name}!`}</Text>
                <Text
                    variant="body-semibold"
                    style={{ color: colorSystem.neutral[8], lineHeight: '24px' }}
                >{t`Here you can see all requests of ${customer.name}.`}</Text>
            </Flexbox>

            {isCompanySettingsEnabled && isAllowedToInvite && (
                <Flexbox>
                    <Message
                        attention="high"
                        title={t`Invite colleagues to collaborate together.`}
                        message={
                            <Trans>
                                To provide access to other users in your company go to the{' '}
                                <Link
                                    style={{ textDecoration: 'none', color: colorSystem.blue[6] }}
                                    to={route('/settings/company')}
                                >
                                    company settings
                                </Link>
                                .
                            </Trans>
                        }
                        size="small"
                        variant="blue"
                    />
                </Flexbox>
            )}
        </Flexbox>
    );
};
