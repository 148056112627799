/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { CurrencyRuntype } from '../backendTypes';
import { PackagingRuntype, PriceTypeRuntype } from '../offer';
import { PreferenceStatusEnumRuntype } from '../part';
import { PaymentMethodRuntype, WorkflowTypeRuntype } from '../rfq';
import {
    InventoryPreferenceRuntype,
    OfferAgeLimitDTORuntype,
} from '../sourcingScenario/solutionConfigurationBackendTypes';

const BackendExchangeRatesDTORuntype = r.Null.Or(r.Dictionary(r.String, r.String));

export interface GlobalCurrencySettingsDTO extends r.Static<typeof GlobalCurrencySettingsDTORuntype> {}
export const GlobalCurrencySettingsDTORuntype = r.Record({
    currency: CurrencyRuntype,
    exchange_rates: BackendExchangeRatesDTORuntype,
});

export interface PublicExchangeRateSummaryDTO extends r.Static<typeof PublicExchangeRateSummaryDTORuntype> {}
export const PublicExchangeRateSummaryDTORuntype = r.Record({
    last_updated: r.String.nullable(),
    base_currency: r.String,
    rates: r.Record({
        base: r.String,
        rates: r.Dictionary(r.String, r.String),
    }),
});

export interface OrganizationSolutionPreferenceDTO extends r.Static<typeof OrganizationSolutionPreferenceDTORuntype> {}
export const OrganizationSolutionPreferenceDTORuntype = r.Record({
    preferred_suppliers_and_stock_locations: r.Array(r.String).nullable(),
    approved_suppliers_and_stock_locations: r.Array(r.String).nullable(),
    excluded_suppliers_and_stock_locations: r.Array(r.String).nullable(),
    preferred_inventory_sites: r.Array(r.String).nullable(),
    approved_inventory_sites: r.Array(r.String).nullable(),
    excluded_inventory_sites: r.Array(r.String).nullable(),
    inventory_preference: InventoryPreferenceRuntype,
    use_unit_price: r.Boolean,
    packaging_preference: r.Array(PackagingRuntype),
    prefer_direct_api_integrations: r.Boolean,
    exclude_mismatched_price_points: r.Boolean,
    prioritize_preferred_manufacturers: r.Boolean,
    offer_age_limit: OfferAgeLimitDTORuntype,
    excluded_price_types: r.Array(PriceTypeRuntype),
    prefer_customer_negotiated_prices: r.Boolean,
});

export enum StepEnum {
    required = 'required',
    optional = 'optional',
    dontShow = 'dont_show',
}

export const StepEnumRuntype = runtypeFromEnum(StepEnum);

// CustomerPortalSettingsRuntype
export const CustomerPortalSettingsRuntype = r.Record({
    upload_bom: StepEnumRuntype,
    upload_pcb: StepEnumRuntype,
    upload_cad_files: StepEnumRuntype,
    upload_production_files: StepEnumRuntype,
    upload_pnp_files: StepEnumRuntype,
    cad_files_guidance_text_en: r.String,
    cad_files_guidance_text_de: r.String,
    imprint_link: r.String.nullable(),
    statement_link: r.String.nullable(),
    terms_and_conditions_link: r.String,
    default_sourcing_lead_time_preference: r.Number.nullable(),
    show_part_data_warnings: r.Boolean,
    use_stripe_for_invoice: r.Boolean,
    payment_methods: PaymentMethodRuntype,
    custom_conditions_text: r.Array(r.String),
});
export interface CustomerPortalSettings extends r.Static<typeof CustomerPortalSettingsRuntype> {}

// Create a base runtype for common fields
export const PatchCustomerPortalAndOrderConfirmationSettingsRuntype = r.Record({
    minimum_quotations_lead_time_days: r.Number.nullable().optional(),
    shipping_information_text: r.String.nullable().optional(),
    shipping_information_link: r.String.nullable().optional(),
    payment_information_text: r.String.nullable().optional(),
    payment_information_link: r.String.nullable().optional(),
    order_confirmation_workflow: WorkflowTypeRuntype.optional(),
    calculation_template: r.String.nullable().optional(),
    manufacturing_templates: r.Array(r.String).optional(),
    invoice_days_before_due: r.Number,
    customer_portal_settings: CustomerPortalSettingsRuntype,
});
export interface PatchCustomerPortalAndOrderConfirmationSettings
    extends r.Static<typeof PatchCustomerPortalAndOrderConfirmationSettingsRuntype> {}

export const ManualRfqNumberRuntype = r.Record({
    type: r.Literal('Manual'),
});

export const AutomaticRfqNumberDataRuntype = r.Record({
    start: r.Number,
    end: r.Number,
    current: r.Number,
    prefix: r.String.nullable(),
});
export const AutomaticRfqNumberRuntype = r.Record({
    type: r.Literal('Automatic'),
    data: AutomaticRfqNumberDataRuntype,
});
export const PatchAutomaticRfqNumberRuntype = r.Record({
    type: r.Literal('Automatic'),
    data: AutomaticRfqNumberDataRuntype.omit('current'),
});

export const AutomaticRfqNumberSettingsRuntype = ManualRfqNumberRuntype.Or(AutomaticRfqNumberRuntype);
export const PatchAutomaticRfqNumberSettingsRuntype = ManualRfqNumberRuntype.Or(PatchAutomaticRfqNumberRuntype);
export type AutomaticRfqNumberSettings = r.Static<typeof AutomaticRfqNumberSettingsRuntype>;
export type PatchAutomaticRfqNumberSettings = r.Static<typeof PatchAutomaticRfqNumberSettingsRuntype>;

export interface CustomerPortalAndOrderConfirmationSettings
    extends r.Static<typeof CustomerPortalAndOrderConfirmationSettingsRuntype> {}
export const CustomerPortalAndOrderConfirmationSettingsRuntype = r.Record({
    customer_portal_settings: CustomerPortalSettingsRuntype,
    minimum_quotations_lead_time_days: r.Number.nullable(),
    shipping_information_text: r.String.nullable(),
    shipping_information_link: r.String.nullable(),
    payment_information_text: r.String.nullable(),
    payment_information_link: r.String.nullable(),
    order_confirmation_workflow: WorkflowTypeRuntype,
    calculation_template: r.String.nullable(),
    manufacturing_templates: r.Array(r.String),
    invoice_days_before_due: r.Number,
});

export const CustomerPortalPaymentSettingsRuntype = r.Record({
    is_payment_processor_setup: r.Boolean,
});

export interface CustomerPortalPaymentSettings extends r.Static<typeof CustomerPortalPaymentSettingsRuntype> {}

export enum WarningStatusEnum {
    warning = 'warning',
    noWarning = 'no_warning',
}

export const WarningStatusRuntype = runtypeFromEnum(WarningStatusEnum);

export interface BomWarningsConfiguration extends r.Static<typeof BomWarningsConfigurationRuntype> {}

export const BomWarningsConfigurationRuntype = r.Record({
    availability: WarningStatusRuntype,
    lifecycle: WarningStatusRuntype,
    compliance: WarningStatusRuntype,
    part_data: WarningStatusRuntype,
});

export const SelfSignupColorsRuntype = r.Record({
    primary_color: r.String,
    primary_dark_color: r.String,
    background_color: r.String,
});

export const SelfSignupSettingsRuntype = r.Record({
    is_self_signup_enabled: r.Boolean,
    is_customer_user_management_enabled: r.Boolean,
    are_private_customers_allowed: r.Boolean,
    default_contact_person: r.String.nullable(),
    organization_logo: r.String.nullable(),
    colors: SelfSignupColorsRuntype,
});

export type SelfSignupSettingsDTO = r.Static<typeof SelfSignupSettingsRuntype>;

export const PatchSelfSignupSettingsRuntype = r.Record({
    default_contact_person: r.String.nullable().optional(),
    is_self_signup_enabled: r.Boolean.optional(),
    are_private_customers_allowed: r.Boolean.optional(),
    is_customer_user_management_enabled: r.Boolean.optional(),
    organization_logo: r.String.nullable().optional(),
    colors: SelfSignupColorsRuntype.optional(),
});
export type PatchSelfSignupSettingsDTO = r.Static<typeof PatchSelfSignupSettingsRuntype>;
export const ManufacturerPreferenceInputRuntype = r.Record({
    part_category_id: r.Number,
    manufacturers: r.Array(r.String),
    preference_status: PreferenceStatusEnumRuntype,
});

export const ManufacturerPreferenceUpdateRuntype = r.Record({
    manufacturers: r.Array(r.String),
    preference_status: PreferenceStatusEnumRuntype,
});

export type ManufacturerPreferenceDTO = r.Static<typeof ManufacturerPreferenceDTORuntype>;

export const ManufacturerPreferenceDTORuntype = r.Record({
    part_category: r.Record({
        id: r.Number,
        name: r.String,
    }),
    manufacturers: r.Array(
        r.Record({
            id: r.String,
            name: r.String,
        }),
    ),
    preference_status: PreferenceStatusEnumRuntype,
});

export const CalculationWithoutManufacturingSettingsRuntype = r.Record({
    calculation_without_manufacturing_enabled: r.Boolean,
});
export type CalculationWithoutManufacturingSettingsDTO = r.Static<
    typeof CalculationWithoutManufacturingSettingsRuntype
>;
