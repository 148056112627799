/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { CurrencyRuntype, MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { OfferTypeRuntype } from '../driver/driverCalculationBackendTypes';
import { PackagingRuntype } from '../offer/Packaging';
import { AvailabilityRuntype } from '../offer/availablityBackendTypes';
import { ConditionRuntype, ScrapRuntype } from '../scrap/scrapBackendTypes';

const SignatureRuntype = r.Union(
    r.Record({
        moq: r.Number,
        mpq: r.Number,
    }),
    r.Record({
        availability: r.Null.Or(AvailabilityRuntype),
        quantity: r.Number,
    }),
);

const OfferIdRuntype = r.Record({
    offer_type: OfferTypeRuntype,
    offer: r.String,
});

const CostFromRuleRuntype = r.Record({
    total_cost_of_ownership_rule: r.String,
    cost: MonetaryValueBackendRuntype,
});

export type CostFromRuleDTO = r.Static<typeof CostFromRuleRuntype>;

const CostsFromRulesRuntype = r.Record({
    cost: MonetaryValueBackendRuntype,
    costs_from_rules: r.Array(CostFromRuleRuntype),
});

export type CostsFromRulesDTO = r.Static<typeof CostsFromRulesRuntype>;

const EvaluatedAdditionalCostAutomaticRuntype = r.Record({
    type: r.Literal('Automatic'),
    data: CostsFromRulesRuntype,
});

const ManualAdditonalCostRuntype = r.Record({
    automatic: CostsFromRulesRuntype.nullable(),
    manual: MonetaryValueBackendRuntype,
});

const EvaluatedAdditionalCostManualRuntype = r.Record({
    type: r.Literal('Manual'),
    data: ManualAdditonalCostRuntype,
});

const EvaluatedAdditionalCostRuntype = r.Union(
    EvaluatedAdditionalCostAutomaticRuntype,
    EvaluatedAdditionalCostManualRuntype,
);

export type EvaluatedAdditionalCostDTO = r.Static<typeof EvaluatedAdditionalCostRuntype>;

const AdditionalCostBreakdownRuntype = r.Record({
    packaging_cost: EvaluatedAdditionalCostRuntype.nullable(),
    discount: EvaluatedAdditionalCostRuntype.nullable(),
    shipping_cost: EvaluatedAdditionalCostRuntype.nullable(),
    customs_cost: EvaluatedAdditionalCostRuntype.nullable(),
    other_cost: EvaluatedAdditionalCostRuntype.nullable(),
});

const AdditionalCostItemRuntype = r.Record({
    cost: MonetaryValueBackendRuntype.nullable(),
    breakdown: AdditionalCostBreakdownRuntype,
});

const AdditionalCostRuntype = r.Record({
    per_unit: AdditionalCostItemRuntype,
    total: AdditionalCostItemRuntype,
});

export type AdditionalSolutionCostDTO = r.Static<typeof AdditionalCostRuntype>;

export interface PurchaseOption extends r.Static<typeof PurchaseOptionRuntype> {}
const PurchaseOptionRuntype = r.Record({
    unit_price: r.Null.Or(MonetaryValueBackendRuntype),
    unit_price_original: r.Null.Or(MonetaryValueBackendRuntype),
    additional_cost: r.Null.Or(AdditionalCostRuntype),
    landed_unit_price: r.Null.Or(MonetaryValueBackendRuntype),
    availability: r.Null.Or(AvailabilityRuntype),
    offer: r.String,
    unit: QuantityUnitDTORuntype,
    quantity: r.Number,
    signature: SignatureRuntype.nullable(),
});

export enum SolutionStatus {
    Good = 'Ok', // The backend uses `Ok` and the frontend we use `Good`
    Warning = 'Warning',
    Error = 'Error',
}
export const SolutionStatusRuntype = runtypeFromEnum(SolutionStatus);

export enum ConversionRateTypeEnum {
    API = 'API',
    Manual = 'Manual',
}

const ConversionRateRuntype = r.Record({
    original_currency: CurrencyRuntype,
    preferred_currency: CurrencyRuntype,
    exchange_rate: r.String,
    update_date: r.Null.Or(r.String),
    rate_type: runtypeFromEnum(ConversionRateTypeEnum),
});

const OrderedOneTimeCostRuntype = r.Record({
    position: r.Number,
    price: r.Record({
        preferred_currency: MonetaryValueBackendRuntype,
        original_currencies: r.Dictionary(r.String, r.String),
    }),
    description: r.Null.Or(r.String),
});

const EvaluatedDefaultFormulaRuntype = r.Record({
    kind: r.Literal('default'),
    scrap: ScrapRuntype,
});

const EvaluatedAppliedFormulaRuntype = r.Record({
    kind: r.Literal('applied'),
    scrap: ScrapRuntype,
    condition: ConditionRuntype,
});

const EvaluatedFormulaRuntype = r.Union(EvaluatedDefaultFormulaRuntype, EvaluatedAppliedFormulaRuntype);

const ManualScrapTypeRuntype = r.Record({
    type: r.Literal('manual'),
});

const NoneScrapTypeRuntype = r.Record({
    type: r.Literal('none'),
});

const CalculatedScrapTypeRuntype = r.Record({
    type: r.Literal('calculated'),
    formula: EvaluatedFormulaRuntype,
});

export interface CalculatedScrapType extends r.Static<typeof CalculatedScrapTypeRuntype> {}

const ScrapTypeRuntype = r.Union(ManualScrapTypeRuntype, NoneScrapTypeRuntype, CalculatedScrapTypeRuntype);

export type ScrapType = r.Static<typeof ScrapTypeRuntype>;

export const DerivedScrapQuantityDTORuntype = r.Record({
    type: ScrapTypeRuntype,
    amount: r.Number,
});

export enum SolutionTag {
    Customer = 'Customer',
    RfQ = 'RfQ',
    ManualOffer = 'ManualOffer',
    QuotePrice = 'QuotePrice',
    ListPrice = 'ListPrice',
    CustomerNegotiatedPrice = 'CustomerNegotiatedPrice',
    ContractPrice = 'ContractPrice',
    PurchasePrice = 'PurchasePrice',
    StandardPrice = 'StandardPrice',
    Expiring = 'Expiring',
    Expired = 'Expired',
    CreationDate = 'CreationDate',
    LongLeadTime = 'LongLeadTime',
    Old = 'Old',
    Outdated = 'Outdated',
    Unavailable = 'Unavailable',
    UnknownLeadTime = 'UnknownLeadTime',
    OnOrder = 'OnOrder',
    UnitMismatch = 'UnitMismatch',
    OnePoint = 'OnePoint',
    Extrapolated = 'Extrapolated',
    Interpolated = 'Interpolated',
    LowStock = 'LowStock',
    Excess = 'Excess',
    AutoSelected = 'AutoSelected',
    Selected = 'Selected',
    OutdatedManual = 'OutdatedManual',
    Converted = 'Converted',
    SupplierNotPreferred = 'SupplierNotPreferred',
    SupplierNotApproved = 'SupplierNotApproved',
    SupplierPreferred = 'SupplierPreferred',
    SupplierApproved = 'SupplierApproved',
    SupplierExcluded = 'SupplierExcluded',
    Inactive = 'Inactive',
    Inventory = 'Inventory',
    ExternalSupplier = 'ExternalSupplier',
    OneTimeCost = 'OneTimeCost',
    ManualOneTimeCost = 'ManualOneTimeCost',
    UnitCost = 'UnitCost',
    ManualUnitCost = 'ManualUnitCost',
    AggregatedQuantity = 'AggregatedQuantity',
    ScrapQuantity = 'ScrapQuantity',
    Packaging = 'Packaging',
    Consigned = 'Consigned',
    ThirdPartyOrigin = 'ThirdPartyOrigin',
    ExceedsOfferedQuantity = 'ExceedsOfferedQuantity',
    GreatlyExceedsOfferedQuantity = 'GreatlyExceedsOfferedQuantity',
    NoApprovedParts = 'NoApprovedParts',
    ManualStatus = 'ManualStatus',
    InvalidSpecification = 'InvalidSpecification',
    NonCancellableNonReturnable = 'NonCancellableNonReturnable',
    SourcingScenario = 'SourcingScenario',
    ShippingTime = 'ShippingTime',
    PanelMismatch = 'PanelMismatch',
}

export type Tag = r.Static<typeof TagRuntype>;
const TagRuntype = r.Union(
    r.Record({ tag: r.Literal(SolutionTag.ManualOffer) }),
    r.Record({ tag: r.Literal(SolutionTag.QuotePrice) }),
    r.Record({ tag: r.Literal(SolutionTag.ListPrice) }),
    r.Record({ tag: r.Literal(SolutionTag.ContractPrice) }),
    r.Record({ tag: r.Literal(SolutionTag.CustomerNegotiatedPrice) }),
    r.Record({ tag: r.Literal(SolutionTag.PurchasePrice) }),
    r.Record({ tag: r.Literal(SolutionTag.StandardPrice) }),
    r.Record({ tag: r.Literal(SolutionTag.Inactive) }),
    r.Record({ tag: r.Literal(SolutionTag.Unavailable) }),
    r.Record({ tag: r.Literal(SolutionTag.UnknownLeadTime) }),
    r.Record({ tag: r.Literal(SolutionTag.OnOrder) }),
    r.Record({ tag: r.Literal(SolutionTag.OnePoint) }),
    r.Record({ tag: r.Literal(SolutionTag.Extrapolated) }),
    r.Record({ tag: r.Literal(SolutionTag.Interpolated) }),
    r.Record({ tag: r.Literal(SolutionTag.AutoSelected) }),
    r.Record({ tag: r.Literal(SolutionTag.SupplierNotPreferred) }),
    r.Record({ tag: r.Literal(SolutionTag.SupplierNotApproved) }),
    r.Record({ tag: r.Literal(SolutionTag.SupplierPreferred) }),
    r.Record({ tag: r.Literal(SolutionTag.SupplierApproved) }),
    r.Record({ tag: r.Literal(SolutionTag.SupplierExcluded) }),
    r.Record({ tag: r.Literal(SolutionTag.Selected) }),
    r.Record({ tag: r.Literal(SolutionTag.Inventory) }),
    r.Record({ tag: r.Literal(SolutionTag.ExternalSupplier) }),
    r.Record({ tag: r.Literal(SolutionTag.Customer), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.RfQ), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.Expiring), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.Expired), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.CreationDate), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.LongLeadTime), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.UnitMismatch), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.Old), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.Outdated), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.OutdatedManual), content: r.Array(OfferIdRuntype) }),
    r.Record({ tag: r.Literal(SolutionTag.LowStock), content: QuantityUnitDTORuntype }),
    r.Record({ tag: r.Literal(SolutionTag.Excess), content: QuantityUnitDTORuntype }),
    r.Record({ tag: r.Literal(SolutionTag.Converted), content: ConversionRateRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.OneTimeCost), content: OrderedOneTimeCostRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.ManualOneTimeCost), content: OrderedOneTimeCostRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.UnitCost), content: OrderedOneTimeCostRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.ManualUnitCost), content: OrderedOneTimeCostRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.AggregatedQuantity), content: QuantityUnitDTORuntype }),
    r.Record({ tag: r.Literal(SolutionTag.Packaging), content: PackagingRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.Consigned) }),
    r.Record({ tag: r.Literal(SolutionTag.ThirdPartyOrigin), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.ExceedsOfferedQuantity) }),
    r.Record({ tag: r.Literal(SolutionTag.GreatlyExceedsOfferedQuantity) }),
    r.Record({ tag: r.Literal(SolutionTag.NoApprovedParts) }), // This tag does only exist in the frontend
    r.Record({ tag: r.Literal(SolutionTag.ManualStatus), content: SolutionStatusRuntype }),
    r.Record({ tag: r.Literal(SolutionTag.InvalidSpecification) }),
    r.Record({ tag: r.Literal(SolutionTag.ScrapQuantity), content: DerivedScrapQuantityDTORuntype }),
    r.Record({ tag: r.Literal(SolutionTag.NonCancellableNonReturnable) }),
    r.Record({ tag: r.Literal(SolutionTag.SourcingScenario), content: r.String }),
    r.Record({ tag: r.Literal(SolutionTag.ShippingTime), content: r.Number }),
    r.Record({ tag: r.Literal(SolutionTag.PanelMismatch) }),
);

export interface SolutionTotalPriceDTO extends r.Static<typeof SolutionTotalPriceDTORuntype> {}
const SolutionTotalPriceDTORuntype = r.Record({
    original_total_price: MonetaryValueBackendRuntype,
    effective_total_price: MonetaryValueBackendRuntype,
    original_currency_total_price: MonetaryValueBackendRuntype,
});

export interface SolutionDTO extends r.Static<typeof SolutionDTORuntype> {}
export const SolutionDTORuntype = r.Record({
    purchase_options: r.Array(PurchaseOptionRuntype),
    tags: r.Array(TagRuntype),
    token: r.String,
    total_price: SolutionTotalPriceDTORuntype.nullable(),
});
