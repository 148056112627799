import { t, Trans } from '@lingui/macro';
import { Flexbox, Tag } from '@luminovo/design-system';
import { ActivityConfigurationSummaryDTO, ResourceDetailsDTO } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transEnum } from '../../../../components/localization/TransEnum';
import { levelEnumPublicTranslations } from '../../../../resources/activity/activityBackendTypes';
import { colorSystem } from '../../../../themes';
import { DriverNotes } from '../../shared/ManufacturingSharedComponents';
import {
    categorySelectionPublicTranslations,
    processPublicTranslations,
} from '../../shared/sharedManufacturingBackendTypes';
import { ResourcesCell } from '../activitiesTableComponents';
import { getActivityConfigurationLevels } from '../activityConfigurationDrawerFunctions';
import { EditActivityConfigurationNotes } from '../EditActivityConfigurationNotesDialog';
import { ActivityConfigurationCostDetails } from './components/ActivityConfigurationCostDetails';
import { ActivityConfigurationDriversDetails } from './components/ActivityConfigurationDriversDetails';
import { ActivityConfigurationTimeCalculation } from './components/ActivityConfigurationTimeCalculation';

const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    marginBlockEnd: '8px',
});

const ActivityConfigurationDetails = ({ activity }: { activity: ActivityConfigurationSummaryDTO }): JSX.Element => {
    const activityConfigurationLevel = getActivityConfigurationLevels(activity);
    const summaryBoxes = [
        {
            title: t`Per panel`,
            content: activity.activity_configuration_details.activity_is_per_panel ? t`Yes` : t`No`,
        },
        {
            title: t`Process`,
            content: transEnum(activity.activity_configuration_details?.activity_process, processPublicTranslations),
        },
        {
            title: t`Category`,
            content: transEnum(
                activity.activity_configuration_details?.activity_category,
                categorySelectionPublicTranslations,
            ),
        },
    ];
    return (
        <Box padding={'24px'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginBottom={'12px'}>
                <Flexbox alignItems={'center'} gap={'8px'}>
                    <StyledTypography variant="h2">
                        {activity.activity_configuration_details.activity_name}
                    </StyledTypography>
                    {activityConfigurationLevel.map((level, index) => (
                        <Tag key={index} color={'neutral'} label={transEnum(level, levelEnumPublicTranslations)} />
                    ))}
                    {activity.activity_configuration_details.activity_internal_number && (
                        <Typography variant="body1" style={{ color: colorSystem.neutral[7] }}>
                            #{activity.activity_configuration_details.activity_internal_number}
                        </Typography>
                    )}
                </Flexbox>
                {!activity.activity_configuration_details.activity_configuration_notes && (
                    <EditActivityConfigurationNotes
                        activityConfigurationId={activity.activity_configuration_details.activity_configuration_id}
                        notes={activity.activity_configuration_details.activity_configuration_notes ?? undefined}
                    />
                )}
            </Box>
            <Flexbox marginBottom={'24px'} justifyContent={'space-between'}>
                {activity.activity_configuration_details.activity_configuration_notes && (
                    <>
                        <DriverNotes
                            notes={activity.activity_configuration_details.activity_configuration_notes ?? ''}
                            maxLength={100}
                            boxProps={{ width: '100%' }}
                        />
                        <EditActivityConfigurationNotes
                            activityConfigurationId={activity.activity_configuration_details.activity_configuration_id}
                            notes={activity.activity_configuration_details.activity_configuration_notes ?? undefined}
                        />
                    </>
                )}
            </Flexbox>
            <Box display={'grid'} gridTemplateColumns="repeat(3, 1fr)">
                {summaryBoxes.map((box, index) => (
                    <Box key={index}>
                        <StyledTypography variant="body2">{box.title}</StyledTypography>
                        <Typography>{box.content}</Typography>
                    </Box>
                ))}
            </Box>

            <Box marginTop={'24px'}>
                <StyledTypography variant="body2">
                    <Trans>Description</Trans>
                </StyledTypography>
                <Typography>{activity.activity_configuration_details.activity_description ?? '-'}</Typography>
            </Box>
        </Box>
    );
};

const ActivityConfigurationResourcesDetails = ({
    resourcesDetails,
}: {
    resourcesDetails: ResourceDetailsDTO[];
}): JSX.Element => {
    return (
        <>
            <StyledTypography variant="h3">
                <Trans>Resources</Trans>
            </StyledTypography>
            <ResourcesCell
                flexProps={{ flexDirection: 'column', alignItems: 'flex-start' }}
                resourcesDetails={resourcesDetails.map((r) => ({
                    /* eslint-disable-next-line camelcase */
                    resource_id: r.resource.id,
                    multiplier: r.multiplier,
                }))}
            />
        </>
    );
};

export {
    ActivityConfigurationCostDetails,
    ActivityConfigurationDetails,
    ActivityConfigurationDriversDetails,
    ActivityConfigurationResourcesDetails,
    ActivityConfigurationTimeCalculation,
};
