/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface AvnetUsaCredentialsDTO extends r.Static<typeof AvnetUsaCredentialsDTORuntype> {}
export const AvnetUsaCredentialsDTORuntype = r.Union(
    r.Record({
        client_id: r.String,
        client_secret: r.String,
        subscription_key: r.String,
        scope: r.String,
    }),
);

export interface AvnetUsaCredentialsInputDTO extends r.Static<typeof AvnetUsaCredentialsInputDTORuntype> {}
export const AvnetUsaCredentialsInputDTORuntype = r.Record({
    client_id: r.String.optional(),
    client_secret: r.String.optional(),
    subscription_key: r.String.optional(),
    scope: r.String.optional(),
});

export interface AvnetUsaResponseBodyDTO extends r.Static<typeof AvnetUsaResponseBodyDTORuntype> {}
export const AvnetUsaResponseBodyDTORuntype = r.Record({
    data: AvnetUsaCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
