import { Trans } from '@lingui/macro';
import {
    colorSystem,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    StickyLayout,
    Text,
    Toolbar,
} from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { ArrowBackRounded, ArrowForwardRounded, Edit as EditIcon, InfoOutlined } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { useFormState } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { findRouteId, route } from '../../../utils/routes';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { formatRouteBasedOnViewContext } from '../utils/formatRoutebasedOnViewContext';
import { pcbSidebarLinks } from './PcbSidebar/utils/pcbSidebarLinks';

type FormActionToolbarProps = {
    rfqId: string;
    assemblyId: string;
    pcb: PCBV2;
    pageTitle: string;
    formId?: string;
    disabled?: boolean;
    isEditing: boolean;
    isDirty?: boolean;
    isEditable?: boolean;
    submitButtonOptions?: ButtonProps;
    editButtonOptions?: ButtonProps;
    cancelButtonOptions?: ButtonProps;
    viewContext: ViewContext;
};

/**
 * Helps with the save and cancel actions of all PCB forms
 * If is editing, shows the cancel and save buttons
 * else, shows the edit button
 *
 * Must be wrapped in a FormProvider
 */
export const PcbActionToolbar = ({
    rfqId,
    assemblyId,
    pcb,
    pageTitle,
    formId,
    disabled,
    isEditing,
    isDirty: isDirtyManual,
    isEditable,
    submitButtonOptions,
    cancelButtonOptions,
    editButtonOptions,
    viewContext,
}: FormActionToolbarProps) => {
    const { isSubmitting, isDirty: isDirtyFormState } = useFormState();
    // The point of moving this here is to get the isDirty state from the form state
    // while we refactor the FileMatcher and DrillManagement to use the form we could pass
    // the isDirty state manually
    // TODO: remove this when the mentioned components are using useForm properly
    const isDirty = isDirtyManual ?? isDirtyFormState;

    return (
        <StickyLayout style={{ maxWidth: '100vw', top: 0, zIndex: 3 }}>
            <Toolbar
                breadcrumbs={[]}
                left={
                    <Flexbox alignItems={'center'} gap={'24px'}>
                        <Flexbox alignItems={'center'} gap={'16px'}>
                            <Text variant="h3">{pageTitle}</Text>
                            {isDirty && (
                                <Text
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        color: colorSystem.neutral[9],
                                    }}
                                >
                                    <InfoOutlined fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                                    <Trans>Unsaved changes</Trans>
                                </Text>
                            )}
                        </Flexbox>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                    {isEditable && (
                        <>
                            {isEditing ? (
                                <>
                                    <SecondaryButton
                                        size="medium"
                                        onClick={cancelButtonOptions?.onClick}
                                        disabled={disabled || isSubmitting || cancelButtonOptions?.disabled}
                                        id={cancelButtonOptions?.id}
                                    >
                                        <Trans>Cancel</Trans>
                                    </SecondaryButton>
                                    <SubmitButton
                                        form={formId}
                                        size="medium"
                                        onClick={submitButtonOptions?.onClick}
                                        disabled={submitButtonOptions?.disabled}
                                        id={submitButtonOptions?.id}
                                    />
                                </>
                            ) : (
                                <PrimaryButton
                                    size="medium"
                                    onClick={editButtonOptions?.onClick}
                                    disabled={disabled || isSubmitting || editButtonOptions?.disabled}
                                    id={editButtonOptions?.id}
                                    startIcon={
                                        <EditIcon
                                            style={{
                                                fontSize: 16,
                                                marginRight: 4,
                                            }}
                                        />
                                    }
                                >
                                    <Trans>Edit</Trans>
                                </PrimaryButton>
                            )}
                        </>
                    )}
                    {isEditing === false && (
                        <NavigationButtons rfqId={rfqId} assemblyId={assemblyId} pcb={pcb} viewContext={viewContext} />
                    )}
                </Flexbox>
            </Toolbar>
        </StickyLayout>
    );
};

export const useCurrentRouteDetails = ({ viewContext }: { viewContext: ViewContext }) => {
    const location = useLocation();
    const routeId = findRouteId(location.pathname);

    const currentRoute = pcbSidebarLinks.find(
        ({ route }) => formatRouteBasedOnViewContext(route, viewContext) === routeId,
    );

    return {
        currentRoute,
        currentRouteId: routeId,
    };
};

const NavigationButtons = ({
    rfqId,
    assemblyId,
    pcb,
    viewContext,
    showPreviousButton = false,
}: {
    rfqId: string;
    assemblyId: string;
    pcb: PCBV2;
    viewContext: ViewContext;
    showPreviousButton?: boolean;
}) => {
    const history = useHistory();
    const { currentRouteId, currentRoute } = useCurrentRouteDetails({ viewContext });
    if (currentRoute?.isWorkflow === false) {
        return null;
    }

    const workFlowLinks = pcbSidebarLinks.filter((link) => link.isWorkflow);

    const currentRouteIndex = workFlowLinks.findIndex(
        (link) => formatRouteBasedOnViewContext(link.route, viewContext) === currentRouteId,
    );
    const nextRoute = workFlowLinks[currentRouteIndex + 1]?.route;
    const previousRoute = workFlowLinks[currentRouteIndex - 1]?.route;

    return (
        <>
            {showPreviousButton && (
                <SecondaryButton
                    startIcon={<ArrowBackRounded />}
                    onClick={() => {
                        previousRoute &&
                            history.push(
                                route(formatRouteBasedOnViewContext(previousRoute, viewContext), { rfqId, assemblyId }),
                            );
                    }}
                    size="medium"
                    disabled={previousRoute === undefined}
                >
                    <Trans>Previous</Trans>
                </SecondaryButton>
            )}
            {nextRoute && (
                <SecondaryButton
                    endIcon={<ArrowForwardRounded />}
                    onClick={() => {
                        history.push(
                            route(formatRouteBasedOnViewContext(nextRoute, viewContext), { rfqId, assemblyId }),
                        );
                    }}
                    size="medium"
                >
                    <Trans>Next</Trans>
                </SecondaryButton>
            )}
        </>
    );
};
