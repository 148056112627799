import { throwErrorUnlessProduction } from '@luminovo/commons';
import { DriverIdDTO, UserDriverDetailsDTO } from '@luminovo/http-client';

export const formatDriverLabel = (option: DriverIdDTO, allDrivers: UserDriverDetailsDTO[]): string => {
    const driverName = allDrivers.find((d) => d.id === option.value)?.name;
    if (driverName === undefined) {
        throwErrorUnlessProduction(`Could not find driver with id ${option.value}`);
    }
    return driverName ?? '';
};
