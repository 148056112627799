import { Table } from '@mui/material';
import { TableComponents } from 'react-virtuoso';
import { TanStackTableContext } from './type';

export const DefaultTable: TableComponents<unknown, TanStackTableContext<unknown>>['Table'] = ({ style, ...props }) => {
    return (
        <Table
            {...props}
            style={{
                ...style,
                width: '100%',
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
                borderSpacing: 0,
            }}
        />
    );
};
