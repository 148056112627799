import { t } from '@lingui/macro';
import { CollapsibleSidebar } from '@luminovo/design-system';
import {
    AccessAlarmsOutlined,
    CategoryOutlined,
    FactCheckOutlined,
    FunctionsRounded,
    PaidOutlined,
} from '@mui/icons-material';
import { route } from '../../utils/routes';

export function ManufacturingDatabaseSidebar() {
    return (
        <CollapsibleSidebar
            title={t`Manufacturing`}
            contents={[
                {
                    type: 'link',
                    href: route('/manufacturing/activity'),
                    Icon: AccessAlarmsOutlined,
                    label: t`Activities`,
                },
                {
                    type: 'link',
                    href: route('/manufacturing/expense'),
                    Icon: PaidOutlined,
                    label: t`Expenses`,
                },
                {
                    type: 'link',
                    href: route('/manufacturing/resource'),
                    Icon: CategoryOutlined,
                    label: t`Resources`,
                },
                {
                    type: 'link',
                    href: route('/manufacturing/driver'),
                    Icon: FunctionsRounded,
                    label: t`Drivers`,
                },
                {
                    type: 'link',
                    href: route('/manufacturing/template'),
                    Icon: FactCheckOutlined,
                    label: t`Templates`,
                },
            ]}
        />
    );
}
