/* eslint-disable camelcase */
import { compareByDate } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { QuoteTrackingDTO, QuoteTrackingState } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../http/useHttpQuery';

export function useQuoteTrackings(rfqId: string): UseQueryResult<QuoteTrackingDTO[]> {
    return useHttpQuery(
        'GET /rfqs/:rfqId/quote-tracking',
        {
            pathParams: { rfqId },
        },
        {
            select: (res) =>
                res.items.map(hotFixOverrideStatus).sort(
                    chainComparators(
                        compareByDate(
                            (a) => {
                                // The backend doesn't create quote requests sequentially,
                                // so we trim the date to ensure they are all set to the same time.
                                const date = a.received_date ?? a.creation_date;
                                const parsedDate = new Date(date);
                                parsedDate.setSeconds(0, 0);
                                return parsedDate.toISOString();
                            },
                            { type: 'descending' },
                        ),
                        compareByNumber((b) => -b.number),
                    ),
                ),
        },
    );
}

export function useQuoteTracking({
    quoteTrackingId: id = '',
}: {
    quoteTrackingId?: string;
}): UseQueryResult<QuoteTrackingDTO | null> {
    return useHttpQuery(
        'GET /quote-tracking/:id',
        {
            pathParams: { id },
        },
        {
            select: (res) => hotFixOverrideStatus(res.data),
            enabled: Boolean(id),
            meta: { globalErrorHandler: false },
        },
    );
}

/**
 * https://luminovo.slack.com/archives/C0119RD7DJT/p1708618436339239
 *
 * This function is a hotfix for the quote tracking status override.
 */
function hotFixOverrideStatus(quoteTracking: QuoteTrackingDTO): QuoteTrackingDTO {
    if (
        quoteTracking.state === 'Received' ||
        quoteTracking.state === 'Discarded' ||
        quoteTracking.state === 'FailedToSend'
    ) {
        return quoteTracking;
    }

    const createDate = new Date(quoteTracking.creation_date);
    const startDate = new Date('2024-02-20T13:00:00Z');
    const endDate = new Date('2024-02-22T18:00:00Z');

    if (createDate >= startDate && createDate <= endDate) {
        return { ...quoteTracking, state: QuoteTrackingState.FailedToSend };
    }
    return quoteTracking;
}
