import { t, Trans } from '@lingui/macro';
import { FormItem, Link, Text } from '@luminovo/design-system';
import { Skeleton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RfqCreationFormState } from '../../../modules/RfqCreation/RfqCreationContext';
import { useAllCustomerUsers, useAllOrganizationUsers } from '../../../resources/user/userHandler';
import { ContributorAutoComplete } from '../components/ContributorAutocomplete';
import { getCustomerPortalEducationLink, sortAndConvertToOptions } from '../components/ContributorsFormUtils';

export function ContributorsFormItem({ customerId }: { customerId: string | undefined }): JSX.Element {
    const normalizedCustomerId = customerId === undefined || customerId === '' ? undefined : customerId;
    const { control } = useFormContext<RfqCreationFormState>();

    const { data: users = [], isLoading: isLoadingUsers } = useAllOrganizationUsers();
    const { data: customerUsers = [], isLoading: isLoadingCustomerUsers } = useAllCustomerUsers(normalizedCustomerId);

    const userOptions = sortAndConvertToOptions(users);
    const customerUserOptions = sortAndConvertToOptions(customerUsers);
    return (
        <>
            <FormItem label={t`Internal contributors`}>
                {isLoadingUsers ? (
                    <Skeleton variant="rectangular" height="40px" />
                ) : (
                    <>
                        <Text>
                            <Trans>
                                Internal contributors will receive notifications about internal as well as public
                                comments and mentions in this RfQ.
                            </Trans>
                        </Text>
                        <ContributorAutoComplete
                            name={'rfq.internalContributors'}
                            control={control}
                            options={userOptions}
                        />
                    </>
                )}
            </FormItem>

            <FormItem label={t`External contributors`}>
                {isLoadingCustomerUsers ? (
                    <Skeleton variant="rectangular" height="40px" />
                ) : (
                    <>
                        <Text>
                            <Trans>
                                External contributors will receive notifications about public comments, mentions and
                                when a new quotation is uploaded in this RfQ. They can only see the Design module.{' '}
                                <Link href={getCustomerPortalEducationLink()} target="_blank" attention="high">
                                    Learn more
                                </Link>
                                .
                            </Trans>
                        </Text>

                        <ContributorAutoComplete
                            name={'rfq.externalContributors'}
                            control={control}
                            options={customerUserOptions}
                        />
                    </>
                )}
            </FormItem>
        </>
    );
}
