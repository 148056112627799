import { Trans, t } from '@lingui/macro';
import { DestructiveSecondaryButton, Toolbar, Tooltip, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { Delete, Upload } from '@mui/icons-material';
import { useFormContext, useWatch } from 'react-hook-form';
import { Avatar } from '../../../../components/Navbar/Avatar';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../utils/routes';
import { getSupplierPortalMode, isCustomPartQuote, useIsQuoteTrackingEditable } from '../model/utils';
import { StandardPartQuoteFormValues } from '../types';

export function ToolbarSupplierQuotePage({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const { organization } = useCurrentUserDetailsContext();
    const totalOffers = quoteTracking.custom_part_offers.length + quoteTracking.standard_part_offers.length;

    return (
        <Toolbar
            style={{
                borderBottom: `1px solid ${colorSystem.neutral[2]}`,
                minHeight: '48px',
                padding: '4px 20px',
            }}
            breadcrumbs={[
                {
                    title: t`Quote portal`,
                    href: route('/quote-portal/rfq/:rfqId', { rfqId: quoteTracking.rfq }),
                },
                {
                    title: organization.name,
                    href: route('/quote-portal/rfq/:rfqId', { rfqId: quoteTracking.rfq }),
                },
                {
                    title: t`Submit quote`,
                },
            ]}
        >
            {totalOffers > 0 && <ButtonDeleteOffers quoteTracking={quoteTracking} />}
            <ButtonSubmitQuote quote={quoteTracking} />
            <span />
            <Avatar variant="light" style={{ margin: 0 }} />
        </Toolbar>
    );
}

function ButtonDeleteOffers({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const isEditable = useIsQuoteTrackingEditable(quoteTracking);
    const { mutateAsync: deleteOffers, isLoading } = useHttpMutation('DELETE /quote-tracking/:quoteTrackingId/offers', {
        snackbarMessage: t`Offers deleted`,
    });
    return (
        <Tooltip title={t`You can only delete offers within 2 hours of submitting the offer.`} disableMaxWidth>
            <span>
                <DestructiveSecondaryButton
                    isLoading={isLoading}
                    disabled={!isEditable}
                    onClick={() => {
                        deleteOffers({ pathParams: { quoteTrackingId: quoteTracking.id } });
                    }}
                    size="medium"
                    startIcon={<Delete />}
                >
                    <Trans>Delete offers</Trans>
                </DestructiveSecondaryButton>
            </span>
        </Tooltip>
    );
}

function ButtonSubmitQuote({ quote }: { quote: QuoteTrackingDTO }) {
    const canImportQuote = useCanImportQuote(quote);
    return <SubmitButton disabled={!canImportQuote} startIcon={<Upload />} size="medium" label={t`Submit quote`} />;
}

function useCanImportQuote(quote: QuoteTrackingDTO) {
    // NOTE: lineItems are actually only for the standard part quote, not the custom part quote
    // so we are taking a bit of a risk here.
    const { control } = useFormContext<StandardPartQuoteFormValues>();
    const lineItems = useWatch({ control, name: 'lineItems' }) ?? [];
    const includedLineItems = lineItems.filter((item) => item.included).length;

    const requestedParts = quote.request_custom_parts.length + quote.request_standard_parts.length;
    const totalOffers = quote.custom_part_offers.length + quote.standard_part_offers.length;

    // If there are already offers, it likely means the user has already imported a quote
    if (totalOffers > 0) {
        return false;
    }
    // You can't quote on a quote that doesn't have any parts
    if (requestedParts === 0) {
        return false;
    }
    // If the quote is not editable, you can't import a quote
    if (getSupplierPortalMode(quote) === 'view') {
        return false;
    }
    if (!isCustomPartQuote(quote) && includedLineItems === 0) {
        return false;
    }

    return true;
}
