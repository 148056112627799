/* eslint-disable camelcase */
import { isGermanLocale } from '@luminovo/commons';
import { UserDTO, UserInviteDTO } from '@luminovo/http-client';

export interface ContributorOption {
    id: string;
    userName: string;
}

export const convertToContributorOption = (user: UserDTO): ContributorOption => {
    const userName =
        user.first_name !== '' && user.last_name !== ''
            ? `${user.first_name} ${user.last_name} ∙ ${user.email}`
            : user.email;

    return {
        userName,
        id: user.id,
    };
};

export const sortAndConvertToOptions = (users: UserDTO[]): ContributorOption[] => {
    return users
        .sort((user1, user2) => user1.email.localeCompare(user2.email))
        .map((user) => convertToContributorOption(user));
};

export const sortUsersByEmail = <T extends UserDTO | UserInviteDTO>(users: T[]): T[] => {
    return users.sort((user1, user2) => user1.email.localeCompare(user2.email));
};

export const getCustomerPortalEducationLink = (): string => {
    // return getIntercomArticleURL(86189);
    const CUSTOMER_PORTAL_KNOWLEDGE_LINK_ENGLISH =
        'https://help.luminovo.com/en/articles/83551-share-your-rfq-with-your-colleagues-or-with-external-users';
    const CUSTOMER_PORTAL_KNOWLEDGE_LINK_GERMAN =
        'https://help.luminovo.com/de/articles/83551-teilen-sie-ihre-anfragen-mit-kolleg-innen-oder-externen';

    if (isGermanLocale()) {
        return CUSTOMER_PORTAL_KNOWLEDGE_LINK_GERMAN;
    } else {
        return CUSTOMER_PORTAL_KNOWLEDGE_LINK_ENGLISH;
    }
};
