import { TableCell } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { TableHeadCell } from './TableHeadCell';
import { Action, Column, PersistentTableState, RenderHeadProps } from './types';

export function TableHeadRow<TRowData, TContext>({
    columns,
    state,
    dispatch,
    renderHeadProps,
}: {
    columns: Column<TRowData, TContext>[];
    state: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    renderHeadProps: RenderHeadProps<TContext>;
}): JSX.Element {
    const isRowExpandable = columns.some((col) => Boolean(col.renderExpanded));
    return (
        <TableHead>
            <TableRow>
                {isRowExpandable && <TableCell width={'25px'} />}
                {columns.map((column) => {
                    return (
                        <TableHeadCell
                            key={`column-${column.id}`}
                            column={column}
                            state={state}
                            dispatch={dispatch}
                            renderHeadProps={renderHeadProps}
                        />
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
