import { MonetaryValue, isPresent, uniqBy } from '@luminovo/commons';
import {
    OtsFullPart,
    PriceBreakDTO,
    QuoteTrackingDTO,
    QuoteTrackingState,
    StandardPartOfferDTO,
    UserType,
    isOtsFullPart,
} from '@luminovo/http-client';
import { LeadTime } from '@luminovo/sourcing-core';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import { QuotedLineItem, StandardPartQuoteFormValues } from '../types';

const MILLIS_PER_HOUR = 1000 * 60 * 60;
const MAX_EDIT_HOURS = 2 * MILLIS_PER_HOUR;

/**
 * Determines if it should show the suppleir portal in read or edit mode.
 *
 * This logic will need to be updated as we add support for more than just custom part offers.
 */
export function getSupplierPortalMode(q: QuoteTrackingDTO) {
    const totalOffers = q.custom_part_offers.length + q.standard_part_offers.length;
    if (totalOffers === 0) {
        return 'edit';
    }
    if (q.state === QuoteTrackingState.Pending) {
        return 'edit';
    }
    return 'view';
}

export function isCustomPartQuote(q: QuoteTrackingDTO) {
    return q.request_custom_parts.length > 0;
}

/**
 * Determines if the quote tracking is editable.
 */
export function useIsQuoteTrackingEditable(q: QuoteTrackingDTO) {
    const userType = useUserType();

    if (!isPresent(q.received_date) || userType === UserType.Internal) {
        return true;
    }

    const diff = new Date().getTime() - new Date(q.received_date).getTime();
    return diff < MAX_EDIT_HOURS;
}

export function getOfferNumbers(q: QuoteTrackingDTO): string[] {
    const customPartOfferNumbers = q.custom_part_offers.map((offer) => offer.offer_number);
    const standardPartOfferNumbers = q.standard_part_offers.map((offer) => offer.offer_number);

    return Array.from(new Set(customPartOfferNumbers.concat(standardPartOfferNumbers).filter(isPresent)));
}

export function isLineItemQuoted(line: QuotedLineItem): boolean {
    if (!line.offer) {
        return false;
    }
    const { unitPrice, moq, offeredPart } = line.offer;
    return Boolean(unitPrice) && Boolean(moq) && Boolean(offeredPart);
}

export function quoteLeadTimeDays(form: Pick<StandardPartQuoteFormValues, 'lineItems' | 'leadTimeUnit'>): LeadTime {
    const value = form.lineItems.map((q) => q.offer?.leadTime ?? 0).reduce((acc, curr) => Math.max(acc, curr), 0);

    return {
        value,
        unit: form.leadTimeUnit,
    };
}

export function getQuoteLineItemUnitPrice(line: QuotedLineItem): number {
    const unitPrice = line.offer?.unitPrice ?? 0;
    const pricePer = line.offer?.pricePer ?? 1;
    return unitPrice / pricePer;
}

export function sumTotalValue(form: Pick<StandardPartQuoteFormValues, 'lineItems' | 'currency'>): MonetaryValue {
    const sum = form.lineItems.reduce((acc, line) => {
        if (!line.offer) {
            return acc;
        }

        return acc + getQuoteLineItemUnitPrice(line) * line.requiredQuantity;
    }, 0);

    return {
        amount: String(sum),
        currency: form.currency,
    };
}

export function countQuotedLineItems(quote: QuoteTrackingDTO): { quoted: number; total: number } {
    if (isCustomPartQuote(quote)) {
        return {
            quoted: quote.custom_part_offers.length,
            total: quote.request_custom_parts.length,
        };
    }
    const lineItems = createLineItems(quote);
    const quoted = lineItems.filter(isLineItemQuoted).length;
    const total = lineItems.length;
    return { quoted, total };
}

export function createLineItems(quoteTracking: QuoteTrackingDTO): QuotedLineItem[] {
    return quoteTracking.request_standard_parts
        .flatMap((part): QuotedLineItem[] => {
            const offers: StandardPartOfferDTO[] = uniqBy(
                part.offers.flatMap((id) => {
                    return quoteTracking.standard_part_offers.filter((o) => o.id === id);
                }),
                (o) => o.id,
            );

            if (offers.length === 0) {
                return [
                    {
                        included: true,
                        part: part.kind,
                        requiredQuantity: part.required_quantity ?? 0,
                        offer: {
                            offeredPart: isOtsFullPart(part.kind) ? part.kind : undefined,
                            pricePer: 1,
                        },
                    },
                ];
            }

            const offerWithPriceBreaks: Array<{
                offer: StandardPartOfferDTO;
                priceBreak: PriceBreakDTO;
            }> = offers.flatMap((offer: StandardPartOfferDTO) => {
                return offer.available_prices.price_breaks.flatMap((priceBreak: PriceBreakDTO) => {
                    return {
                        offer,
                        priceBreak,
                    };
                });
            });

            return offerWithPriceBreaks.map(({ offer, priceBreak }): QuotedLineItem => {
                const offeredPartId = offer.linked_part.id;

                const allParts: OtsFullPart[] = quoteTracking.request_standard_parts
                    .concat(quoteTracking.alternative_standard_parts)
                    .map((p) => p.kind)
                    .filter(isOtsFullPart);

                const offeredPart: OtsFullPart | undefined = allParts.find((p) => p.id === offeredPartId);

                return {
                    included: true,
                    part: part.kind,
                    requiredQuantity: part.required_quantity ?? 0,
                    offer: {
                        offeredPart,
                        leadTime: offer.available_prices.factory_lead_time_days ?? undefined,
                        moq: priceBreak.moq,
                        unitPrice: parseFloat(priceBreak.unit_price.amount),
                        pricePer: 1,
                        mpq: priceBreak.mpq,
                        ncnr: offer.ncnr ?? undefined,
                        notes: offer.notes ?? undefined,
                        offerNumber: offer.offer_number ?? undefined,
                        packaging: offer.packaging ?? undefined,
                        stock: offer.available_prices.stock ?? undefined,
                        supplierPartNumber: offer.supplier_part_number ?? undefined,
                        validUntil: offer.valid_until ?? undefined,
                    },
                };
            });
        })
        .sort((a, b) => a.part.id.localeCompare(b.part.id))
        .map((li) => {
            return {
                ...li,
                included: li.offer?.unitPrice !== undefined,
            };
        });
}
