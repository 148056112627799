import { StatusChip } from '@luminovo/design-system';
import { ComplianceStatus } from '@luminovo/http-client';
import { ComplianceStatusTranslations } from '../resources/part/i18n';
import { assertUnreachable } from '../utils/typingUtils';
import { transEnum } from './localization/TransEnum';

export const ComplianceStatusChip: React.FunctionComponent<{
    status: ComplianceStatus;
}> = ({ status }) => {
    const label = transEnum(status, ComplianceStatusTranslations);
    switch (status) {
        case ComplianceStatus.Compliant:
            return <StatusChip color="green" label={label} />;
        case ComplianceStatus.CompliantWithExemption:
            return <StatusChip color="green" label={label} />;
        case ComplianceStatus.NonCompliant:
            return <StatusChip color="red" label={label} />;
        case ComplianceStatus.NotRequired:
            return <StatusChip color="neutral" label={label} />;
        case ComplianceStatus.Unknown:
            return <StatusChip color="neutral" label={label} />;
        default:
            assertUnreachable(status);
    }
};
