import { assertUnreachable } from '@luminovo/commons';
import { ActivityDTO, TimeComponents } from '@luminovo/http-client';
import { useMemo } from 'react';
import { FormulasContext, useFormulasContext } from '../../shared/columns/formulasContext';

type CalculationType = 'Fixed' | 'Linear' | 'Formula';

type FormulasAndDrivers = { formulas: string[]; driverIdValues: string[] };

export interface TimeCalculationLevelSummary extends FormulasAndDrivers {
    type: CalculationType;
    level: 'Unit' | 'Batch' | 'Project';
}

type ActivityLevels = 'Unit' | 'Batch' | 'Project';

type TimeCalculationSummary = Record<ActivityLevels, TimeCalculationLevelSummary | undefined>;

export interface TimeCalculationSummaryContext {
    timeCalculationSummaryMap: Record<string, TimeCalculationSummary>;
}

export const useTimeCalculationSummaryContext = (activities: ActivityDTO[]): TimeCalculationSummaryContext => {
    const timeCalculationSummaryMap = useMemo(() => {
        return activities.reduce<Record<string, TimeCalculationSummary>>((acc, activity) => {
            acc[activity.id] = getTimeCalculationSummary(activity);
            return acc;
        }, {});
    }, [activities]);

    return { timeCalculationSummaryMap };
};

export const getTimeCalculationSummary = (activity: ActivityDTO): TimeCalculationSummary => {
    const calculation = activity.time_calculation;
    const { level, details } = calculation;

    let levelUnitTimeUnitSummary: TimeCalculationLevelSummary | undefined = undefined;
    let levelUnitTimeBatchSummary: TimeCalculationLevelSummary | undefined = undefined;
    let levelBatchTimeBatchSummary: TimeCalculationLevelSummary | undefined = undefined;
    let levelProjectTimeProjectSummary: TimeCalculationLevelSummary | undefined = undefined;

    switch (level) {
        case 'Unit':
            levelUnitTimeUnitSummary = {
                type: details.unit_time_components.type,
                level: 'Unit',
                ...collectFormulasAndDrivers(details.unit_time_components),
            };

            if (details.batch_time_components) {
                levelUnitTimeBatchSummary = {
                    type: details.batch_time_components.type,
                    level: 'Batch',
                    ...collectFormulasAndDrivers(details.batch_time_components),
                };
            }
            break;
        case 'Batch':
            levelBatchTimeBatchSummary = {
                type: details.batch_time_components.type,
                level: 'Batch',
                ...collectFormulasAndDrivers(details.batch_time_components),
            };
            break;
        case 'Project':
            levelProjectTimeProjectSummary = {
                type: details.project_time_components.type,
                level: 'Project',
                ...collectFormulasAndDrivers(details.project_time_components),
            };
            break;
        default:
            assertUnreachable(level);
    }

    return {
        Unit: levelUnitTimeUnitSummary,
        Batch: levelUnitTimeBatchSummary ?? levelBatchTimeBatchSummary,
        Project: levelProjectTimeProjectSummary,
    };
};

function collectFormulasAndDrivers(timeComponents: TimeComponents): FormulasAndDrivers {
    const { type, details } = timeComponents;

    switch (type) {
        case 'Fixed':
            return { formulas: [], driverIdValues: [] };
        case 'Linear':
            return { formulas: [], driverIdValues: [details.variable_time.driver.value] };
        case 'Formula':
            return { formulas: [details.formula], driverIdValues: [] };
        default:
            assertUnreachable(type);
    }
}

const collectActivityFormulasMap = (activities: ActivityDTO[]): Record<string, string[]> => {
    return activities.reduce<Record<string, string[]>>((acc, activity) => {
        const summary = getTimeCalculationSummary(activity);
        const formulas = Object.entries(summary).flatMap(([_, summary]) => summary?.formulas ?? []);
        acc[activity.id] = formulas;

        return acc;
    }, {});
};

export interface ActivityFormulasContext extends FormulasContext {
    activityFormulasMap: Record<string, string[]>;
}

export const useActivityFormulasContext = (activities: ActivityDTO[]): ActivityFormulasContext => {
    const activityFormulasMap = collectActivityFormulasMap(activities);
    const formulas = Object.values(activityFormulasMap).flat();
    const { formulaDriversMap } = useFormulasContext(formulas);

    return { formulaDriversMap, activityFormulasMap };
};
