/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { assertUnreachable, getLocale, LanguageEnum, transEnum, validEmailRegex } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    CenteredLayout,
    colorSystem,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Message,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { Box, Checkbox, CircularProgress, Divider, FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../../components/formLayouts/FormContainer';
import {
    SelectController,
    TextFieldController,
} from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { AuthPage } from '../../../../modules/Auth/AuthPage';
import { getTenantId } from '../../../../permissions/tenants';
import {
    sortedVATIdentificationTypes,
    VATIdentificationTypeTranslations,
} from '../../../../resources/customer/i18n/vatIdentificationType';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { sortedCountries, translateCountry } from '../../../../resources/organizationSettings/countryi18n';
import { languageTranslations, loadLocale } from '../../../../utils/internationalisation';
import { CustomerSignupFormState } from './types';
import { useMutationSignupCustomer } from './useMutationSignupCustomer';

const TermsAndConditions = (): JSX.Element => {
    const { data, isLoading } = useHttpQuery(
        'GET /tenant/details',
        { queryParams: { tenant: getTenantId() }, requestHeaders: { Authorization: null } },
        {},
    );
    if (isLoading) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const link = data?.terms_and_conditions_link;
    return link !== undefined && link.length > 0 ? (
        <Trans>
            I understand and agree with the{' '}
            <span
                onClick={() => {
                    window.open(link, '_blank');
                }}
                style={{ cursor: 'pointer', color: colorSystem.blue[6] }}
            >
                terms and conditions
            </span>
            .
        </Trans>
    ) : (
        <Trans>I understand and agree to the terms and conditions</Trans>
    );
};

const getDefaultValues = (type: CustomerSignupFormState['type'], defaultLanguage: string): CustomerSignupFormState => {
    switch (type) {
        case 'Business':
            return {
                type,
                data: {
                    customer: {
                        company_name: '',
                        vat_identification_number: '',
                        vat_identification_type: undefined,
                        commercial_register_number: '',
                        billing_address: {
                            line1: '',
                            line2: '',
                            city: '',
                            postal_code: '',
                            country_code: null,
                        },
                    },
                    user: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        are_terms_checked: false,
                        language: defaultLanguage,
                    },
                },
            };
        case 'Private':
            return {
                type,
                data: {
                    customer: {
                        billing_address: {
                            line1: '',
                            line2: '',
                            city: '',
                            postal_code: '',
                            country_code: null,
                        },
                    },
                    user: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        are_terms_checked: false,
                        language: defaultLanguage,
                    },
                },
            };
        default:
            assertUnreachable(type);
    }
};

export default function CustomerSignUpPage() {
    const { data } = useHttpQuery(
        'GET /tenant/details',
        { queryParams: { tenant: getTenantId() }, requestHeaders: { Authorization: null } },
        {},
    );
    const organizationLogoLink = data?.organization_logo !== null ? data?.organization_logo : undefined;

    const defaultLanguage: LanguageEnum = getLocale();
    const defaultValues = getDefaultValues('Business', defaultLanguage);

    React.useEffect(() => {
        loadLocale(defaultValues.data.user.language);
    }, [defaultValues.data.user.language]);

    if (data) {
        if (data.is_self_signup_enabled) {
            return (
                <AuthPage
                    isCustomerPortal
                    organizationLogoLink={organizationLogoLink}
                    tenantName={data.organization_name}
                    position="top"
                    SignupBackgroundProps={{
                        background: data.colors.background_color,
                        primary: data.colors.primary_color,
                        primaryDarker: data.colors.primary_dark_color,
                    }}
                >
                    <CustomerFormSignup
                        defaultValues={defaultValues}
                        defaultLanguage={defaultLanguage}
                        arePrivateCustomersAllowed={data.are_private_customers_allowed}
                    />
                </AuthPage>
            );
        }
        //TODO: redirect to the login screen.
        return <Text>Page not available </Text>;
    }

    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    );
}

function CustomerFormSignup({
    defaultValues,
    defaultLanguage,
    arePrivateCustomersAllowed,
}: {
    defaultValues: CustomerSignupFormState;
    defaultLanguage: string;
    arePrivateCustomersAllowed: boolean;
}) {
    const [wasSignupSuccessful, setWasSignupSuccessful] = React.useState(false);

    const { mutateAsync } = useMutationSignupCustomer({
        onSuccess: () => {
            setWasSignupSuccessful(true);
        },
    });

    const validationErrors: ValidationErrors<CustomerSignupFormState> = {
        'payload.email_cannot_be_empty': {
            fieldPath: 'data.user.email',
        },
        'payload.first_name_cannot_be_empty': {
            fieldPath: 'data.user.first_name',
        },
        'payload.last_name_cannot_be_empty': {
            fieldPath: 'data.user.last_name',
        },
        'payload.customer_company_name_cannot_be_empty': {
            fieldPath: 'data.customer.company_name',
        },
        'payload.vat_identification_number_cannot_be_empty': {
            fieldPath: 'data.customer.vat_identification_number',
        },
    };

    return (
        <FormContainer<CustomerSignupFormState>
            defaultValues={defaultValues}
            validationErrors={validationErrors}
            onSubmit={(values) => {
                mutateAsync(values);
            }}
        >
            {wasSignupSuccessful ? (
                <ConfirmationStep />
            ) : (
                <CustomerSignupFormInner
                    defaultLanguage={defaultLanguage}
                    arePrivateCustomersAllowed={arePrivateCustomersAllowed}
                />
            )}
        </FormContainer>
    );
}

function getForm(type: CustomerSignupFormState['type']): JSX.Element {
    switch (type) {
        case 'Business':
            return <BusinessSignupForm />;
        case 'Private':
            return <PrivateSignupForm />;
        default:
            assertUnreachable(type);
    }
}

function CustomerSignupFormInner({
    defaultLanguage,
    arePrivateCustomersAllowed,
}: {
    defaultLanguage: string;
    arePrivateCustomersAllowed: boolean;
}): JSX.Element {
    const { control, reset } = useFormContext<CustomerSignupFormState>();
    const type = useWatch({ control, name: 'type' });
    const form = getForm(type);

    const setFormType = (type: CustomerSignupFormState['type']) => {
        const defaultValues = getDefaultValues(type, defaultLanguage);
        reset(defaultValues);
    };

    return (
        <Flexbox flexDirection="column" alignItems="center" gap="40px">
            {arePrivateCustomersAllowed && (
                <ButtonGroup size="large">
                    <ButtonGroupItem selected={type === 'Business'} onClick={() => setFormType('Business')}>
                        <Trans>Business customer</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem selected={type === 'Private'} onClick={() => setFormType('Private')}>
                        <Trans>Private customer</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
            )}
            {form}
        </Flexbox>
    );
}

function PrivateSignupForm(): JSX.Element {
    return (
        <Flexbox flexDirection="column" gap="40px">
            <Flexbox flexDirection="column" gap={16}>
                <SectionTitle title={t`User information`} />
                <FormItemEmail />
                <FormItemFirstName />
                <FormItemLastName />
                <FormItemLanguage />
            </Flexbox>

            <Flexbox flexDirection="column" gap={16}>
                <SectionTitle title={t`Billing address`} />
                <FormItemBillingAddress />
                <FormItemTerms />
            </Flexbox>
            <Flexbox justifyContent="end">
                <SubmitButton label={t`Sign up`} />
            </Flexbox>
        </Flexbox>
    );
}

type Step = 'company' | 'user';

function BusinessSignupForm(): JSX.Element {
    const [step, setStep] = React.useState<Step>('company');

    return (
        <>
            {step === 'company' && <CompanyForm setStep={setStep} />}
            {step === 'user' && <UserForm setStep={setStep} />}
        </>
    );
}

function SectionTitle({ title }: { title: string }): JSX.Element {
    return (
        <Flexbox flexDirection="column" gap={16}>
            <Text variant="h2" color={colorSystem.neutral[8]}>
                {title}
            </Text>
            <Divider style={{ minWidth: '393px', marginTop: '-4px' }} />
        </Flexbox>
    );
}

function CompanyForm({ setStep }: { setStep: React.Dispatch<React.SetStateAction<Step>> }) {
    const { trigger, formState } = useFormContext<CustomerSignupFormState>();

    const onClick = async () => {
        if (await trigger('data.customer')) {
            setStep('user');
        }
    };
    return (
        <Flexbox flexDirection={'column'} gap={40}>
            <Flexbox flexDirection="column" gap={16}>
                <SectionTitle title={t`Company information`} />

                <FormItemCompanyName />
                <FormItemVatIdentificationNumber />
                <FormItemVatIdentificationType />
                <FormItemCommercialRegisterNumber />
            </Flexbox>

            <Flexbox flexDirection="column" gap={16}>
                <SectionTitle title={t`Billing address`} />

                <FormItemBillingAddress />
            </Flexbox>
            <Flexbox flexDirection={'row'} justifyContent={'flex-end'}>
                <PrimaryButton
                    onClick={() => {
                        onClick();
                    }}
                    disabled={Object.keys(formState.errors.data?.customer ?? {}).length > 0}
                >
                    <Trans>Next</Trans>
                </PrimaryButton>
            </Flexbox>
        </Flexbox>
    );
}

function UserForm({ setStep }: { setStep: React.Dispatch<React.SetStateAction<Step>> }) {
    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <SectionTitle title={t`User information`} />

            <FormItemEmail />
            <FormItemFirstName />
            <FormItemLastName />

            <FormItemLanguage />
            <FormItemTerms />

            <Flexbox justifyContent={'space-between'}>
                <SecondaryButton
                    onClick={() => {
                        setStep('company');
                    }}
                >
                    <Trans>Back</Trans>
                </SecondaryButton>
                <SubmitButton label={t`Sign up`} />
            </Flexbox>
        </Flexbox>
    );
}

function ConfirmationStep() {
    const { watch } = useFormContext<CustomerSignupFormState>();
    const emailAddress = watch('data.user.email');
    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Message
                size="large"
                attention="high"
                variant={'blue'}
                title={t`A confirmation email has been sent to ${emailAddress}`}
                message={t`Please use the link in the email to verify your account. It can take a couple of minutes for you to receive the email.`}
            />
        </Flexbox>
    );
}

function FormItemCompanyName() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`Company name`} required={true}>
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.customer.company_name"
            />
        </FormItem>
    );
}

function FormItemVatIdentificationNumber() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem
            label={t`VAT identification number`}
            required={true}
            description={t`For third country companies please enter the company registration number`}
        >
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.customer.vat_identification_number"
            />
        </FormItem>
    );
}

function FormItemVatIdentificationType() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem
            required
            label={t`VAT identification type`}
            description={t`European VAT number covers the following countries: Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, and Sweden`}
        >
            <FieldSelectControlled
                control={control}
                name={'data.customer.vat_identification_type'}
                required
                FieldProps={{
                    options: sortedVATIdentificationTypes().map((identificationType) => identificationType.value),
                    getOptionLabel: (code) => transEnum(code, VATIdentificationTypeTranslations),
                }}
            />
        </FormItem>
    );
}

function FormItemCommercialRegisterNumber() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`Commercial register number`} required={false}>
            <TextFieldController
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.customer.commercial_register_number"
            />
        </FormItem>
    );
}

function FormItemBillingAddress() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <FormItem label={t`Address Line 1`} required>
                <FieldTextControlled control={control} name="data.customer.billing_address.line1" required />
            </FormItem>
            <FormItem label={t`Address Line 2`}>
                <FieldTextControlled control={control} name="data.customer.billing_address.line2" />
            </FormItem>
            <FormItem label={t`City`} required>
                <FieldTextControlled control={control} name="data.customer.billing_address.city" required />
            </FormItem>
            <FormItem label={t`Postal code`} required>
                <FieldTextControlled control={control} name="data.customer.billing_address.postal_code" required />
            </FormItem>

            <FormItem label={t`Country`} required>
                <FieldSelectControlled
                    name="data.customer.billing_address.country_code"
                    control={control}
                    FieldProps={{
                        options: sortedCountries.map((k) => k.value),
                        getOptionLabel: (k) => translateCountry(k),
                    }}
                    required
                />
            </FormItem>
        </Flexbox>
    );
}

function FormItemFirstName() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`First name`} required={true}>
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.user.first_name"
            />
        </FormItem>
    );
}

function FormItemLastName() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`Last name`} required={true}>
            <TextFieldController
                rules={{ required: t`Required` }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.user.last_name"
            />
        </FormItem>
    );
}

function validateEmail(x: unknown) {
    if (typeof x !== 'string') return t`Invalid type`;
    if (!validEmailRegex.test(x)) return t`Invalid email address`;
}

function FormItemEmail() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`Email address`} required={true}>
            <TextFieldController
                rules={{
                    required: t`Required`,
                    validate: (x) => {
                        return validateEmail(x);
                    },
                }}
                TextFieldProps={{ disabled: false }}
                control={control}
                name="data.user.email"
            />
        </FormItem>
    );
}

function FormItemLanguage() {
    const { control } = useFormContext<CustomerSignupFormState>();
    return (
        <FormItem label={t`Language`}>
            <SelectController translations={languageTranslations} control={control} name="data.user.language" />
        </FormItem>
    );
}

function FormItemTerms() {
    const { formState } = useFormContext<CustomerSignupFormState>();

    return (
        <Flexbox flexDirection={'column'} gap={'4px'}>
            <FormControlLabel
                control={
                    <Box>
                        <FormItemTermsController />
                    </Box>
                }
                label={<TermsAndConditions />}
            />
            <Text color={colorSystem.red[6]} variant="caption">
                {formState.errors.data?.user?.are_terms_checked?.message}
            </Text>
        </Flexbox>
    );
}

function FormItemTermsController() {
    const { control, trigger } = useFormContext<CustomerSignupFormState>();
    return (
        <Controller
            control={control}
            name="data.user.are_terms_checked"
            rules={{
                validate: {
                    isChecked: (value) => {
                        return value === true || t`Please accept the terms and conditions before continuing`;
                    },
                },
            }}
            render={({ field }) => {
                return (
                    <Checkbox
                        size="small"
                        onBlur={() => field.onBlur()}
                        onChange={(_, checked) => {
                            field.onChange(checked);
                            trigger('data.user.are_terms_checked');
                        }}
                        checked={field.value}
                    />
                );
            }}
        />
    );
}
