import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineEditIcon,
    TimelineNote,
    TimelineText,
} from './Utils';

type Props = {
    actor: ActorResponseRuntypeDTO;
    note: string;
    operation: HistoryOperation;
};

const useNoteAction = (actor: ActorResponseRuntypeDTO, operation: HistoryOperation) => {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualUpdate:
            if (!actor) return null;
            return (
                <TimelineText>
                    <Trans>Note edited</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.AutoAdd:
            return (
                <TimelineText>
                    <Trans>Note automatically added</Trans>
                </TimelineText>
            );
        case HistoryOperation.ManualAdd:
            if (!actor) return;
            return (
                <TimelineText>
                    <Trans>Note manually added</Trans> <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
};
export default function Notes({ actor, note, operation }: Props) {
    const action = useNoteAction(actor, operation);
    if (!action) return null;
    return (
        <div>
            <TimelineContentContainer>{action}</TimelineContentContainer>
            <TimelineNote text={note} />
        </div>
    );
}

export function NotesTimelineIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualUpdate) return <TimelineEditIcon />;
    return <TimelineAddIcon />;
}
