import { SolutionTag, Tag } from '@luminovo/http-client';
import { hasSolutionTag } from './hasSolutionTag';

/**
 * A solution is considered in need of an update if it has SolutionTag.AutoSelected,
 * but does not have SolutionTag.ManualOffer, and has either SolutionTag.Old,
 * SolutionTag.Outdated, or SolutionTag.Expired.
 */
export function isSolutionInNeedOfUpdate({ solutionTags }: { solutionTags: Tag[] }): boolean {
    const isAutoSelected = hasSolutionTag({ solutionTags }, SolutionTag.AutoSelected);
    const isManualOffer = hasSolutionTag({ solutionTags }, SolutionTag.ManualOffer);
    const isOldSolution = hasSolutionTag({ solutionTags }, SolutionTag.Old);
    const isOutdatedSolution = hasSolutionTag({ solutionTags }, SolutionTag.Outdated);
    const isExpiredSolution = hasSolutionTag({ solutionTags }, SolutionTag.Expired);

    return isAutoSelected && !isManualOffer && (isOldSolution || isOutdatedSolution || isExpiredSolution);
}
