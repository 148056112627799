import { t } from '@lingui/macro';
import { FieldNumeric, FieldSelect, Flexbox, TextField, Tooltip } from '@luminovo/design-system';
import { QuantityUnit } from '@luminovo/http-client';
import { useState } from 'react';
import { EditBomItemHeaderAction, EditBomItemHeaderState } from '../reducer';

export const QuantityField = ({
    state,
    dispatch,
}: {
    state: EditBomItemHeaderState;
    dispatch: React.Dispatch<EditBomItemHeaderAction>;
}) => {
    return (
        <Flexbox gap="4px">
            <Tooltip arrow title={t`Number of designators`}>
                <TextField
                    value={`${state.designators.length} x`}
                    size="small"
                    disabled
                    style={{ width: '64px' }}
                    error={state.errors.designatorsCount.isError}
                />
            </Tooltip>
            <Tooltip title={t`Number of units per designator`} placement="bottom">
                <span>
                    <FieldNumeric
                        value={state.quantityValue}
                        error={state.errors.quantityValue.isError}
                        helperText={state.errors.quantityValue.message}
                        onChange={(v) => {
                            return dispatch({ type: 'setQuantityValue', quantity: v });
                        }}
                        size="small"
                        style={{ width: '64px' }}
                    />
                </span>
            </Tooltip>
            <UnitOfMeasurementSelect state={state} dispatch={dispatch} />
        </Flexbox>
    );
};

const UnitOfMeasurementSelect = ({
    state,
    dispatch,
}: {
    state: EditBomItemHeaderState;
    dispatch: React.Dispatch<EditBomItemHeaderAction>;
}) => {
    const [isTooltipShown, setIsTooltipShown] = useState<boolean>(true);
    return (
        <Tooltip title={isTooltipShown ? t`Unit of measurement` : ''}>
            <FieldSelect
                value={state.quantityUnit}
                options={Object.values(QuantityUnit)}
                onChange={(v) => {
                    return dispatch({ type: 'setQuantityUnit', unit: v });
                }}
                error={state.errors.quantityUnit.isError}
                helperText={state.errors.quantityUnit.message}
                disableClearable
                size="small"
                style={{ width: '120px' }}
                onOpen={() => {
                    setIsTooltipShown(false);
                }}
                onClose={() => {
                    setIsTooltipShown(true);
                }}
            />
        </Tooltip>
    );
};
