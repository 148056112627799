import { Region } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';

/**
 * For every attribute that is unselected, this filters out all attributes (except the ones that are explicitly selected) with
 * the same attr and value.
 */
export const filterOutUnselected = (regions: Region<PcbAttribute>[]): Region<PcbAttribute>[] => {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );
    const unselectedRegionsWithAttributes = regionsWithAttributes.filter(
        ({ attribute }) => attribute.selected === false,
    );
    unselectedRegionsWithAttributes.forEach(({ attribute: unselectedAttribute }) => {
        regions.forEach((region) => {
            region.attributes = region.attributes.filter(
                (attr) =>
                    !(
                        attr.attr === unselectedAttribute.attr &&
                        attr.value === unselectedAttribute.value &&
                        attr.selected !== true
                    ),
            );
        });
    });

    return regions;
};
