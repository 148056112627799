import { PcbAttribute } from './PcbAttribute';

export const applyConfidencePenalty = (attribute: PcbAttribute, confidencePenalty: number): PcbAttribute => {
    return {
        ...attribute,
        confidence: (attribute.confidence ?? 1) * confidencePenalty,
    };
};

const parseSelected = (stringToParse: string): boolean => {
    return stringToParse.includes(':selected:');
};
const parseUnselected = (stringToParse: string): boolean => {
    return stringToParse.includes(':unselected:');
};

export const defaultToConfidenceOfOne = (attribute: PcbAttribute): PcbAttribute => {
    return {
        ...attribute,
        confidence: attribute.confidence ?? 1,
    };
};

export const applyConfidencePenaltyIfNotSelected = (
    stringToParse: string,
    attribute: PcbAttribute,
    confidencePenalty: number = 0.5,
): PcbAttribute => {
    if (!parseSelected(stringToParse)) {
        return {
            ...attribute,
            confidence: (attribute.confidence ?? 1) * confidencePenalty,
        };
    }
    return defaultToConfidenceOfOne(attribute);
};

export const setSelected = (stringToParse: string, attribute: PcbAttribute): PcbAttribute => {
    const selected = parseSelected(stringToParse);
    const unselected = parseUnselected(stringToParse);
    // if both are false we don't want to set selected to anything
    // if both are true it's conflicting and we also don't want to set it
    if (selected !== unselected) {
        return {
            ...attribute,
            selected: selected,
        };
    }
    return attribute;
};
