import { transEnum } from '@luminovo/commons';
import { Tooltip, colorSystem } from '@luminovo/design-system';
import { StarRounded } from '@mui/icons-material';
import {
    PreferredTag,
    PreferredTagEnum,
    preferredTagTooltipTranslations,
    preferredTagTranslations,
} from './PreferredTag';

export const PreferredManufacturerTagWithText = ({
    size = 'large',
    showTooltip = false,
}: {
    size?: 'small' | 'large';
    showTooltip?: boolean;
}): JSX.Element => {
    return (
        <PreferredTag
            label={size === 'large' ? transEnum(PreferredTagEnum.Manufacturer, preferredTagTranslations) : undefined}
            tooltip={showTooltip ? transEnum(PreferredTagEnum.Manufacturer, preferredTagTooltipTranslations) : ''}
        />
    );
};

export const PreferredManufacturerTag = ({
    showTooltip = true,
    styles,
}: {
    showTooltip?: boolean;
    styles?: React.CSSProperties;
}): JSX.Element => {
    return (
        <Tooltip title={showTooltip ? transEnum(PreferredTagEnum.Manufacturer, preferredTagTooltipTranslations) : ''}>
            <StarRounded style={{ color: colorSystem.primary[7], fontSize: '16px', ...styles }} />
        </Tooltip>
    );
};
