import { ActivityCountChip, ExpenseCountChip } from '@luminovo/manufacturing-core';
import { Skeleton } from '@mui/material';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

export const ActivityCountChipWrapper = ({ activityIds }: { activityIds: string[] }): JSX.Element => {
    const { data: activities } = useHttpQuery('GET /activities', { queryParams: {} });

    if (activities?.data) {
        return (
            <ActivityCountChip activities={activities.data.filter((activity) => activityIds.includes(activity.id))} />
        );
    }
    return <Skeleton />;
};

export const ExpenseCountChipWrapper = ({ expenseIds }: { expenseIds: string[] }): JSX.Element => {
    const { data: expenses } = useHttpQuery('GET /expenses', { queryParams: {} });

    if (expenses?.data) {
        return <ExpenseCountChip expenses={expenses.data.filter((expense) => expenseIds.includes(expense.id))} />;
    }
    return <Skeleton />;
};
