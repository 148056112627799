import { Flexbox, colorSystem } from '@luminovo/design-system';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSkeleton = styled(Skeleton)({
    width: '16px',
    height: '24px',
    borderRadius: '50%',
});

export const CadFileLoader = () => {
    return (
        <Flexbox
            style={{
                flexDirection: 'row',
                gap: '8px',
                background: colorSystem.neutral.white,
                padding: '18px 16px',
                borderRadius: '4px',
            }}
        >
            <StyledSkeleton />
            <StyledSkeleton style={{ borderRadius: '4px', width: '125px' }} />
            <StyledSkeleton style={{ marginLeft: 'auto' }} />
        </Flexbox>
    );
};
