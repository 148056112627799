import { Flexbox, Tag, Text, Tooltip } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { CombinedDriverType } from '../../selectors';
import { DriverLabel } from './DriverLabel';

export interface DriverListEntry {
    type: CombinedDriverType;
    name: string;
    key?: string;
}

export const DriverList = ({ drivers }: { drivers: DriverListEntry[] }): JSX.Element => {
    const driverLabels = drivers.map((driver) => {
        return <DriverLabel key={driver.key ?? driver.name} name={driver.name} type={driver.type} />;
    });

    const hasDrivers = driverLabels.length > 0;
    const maxNumberOfDriversShown = 2;
    if (!hasDrivers) {
        return <Text>-</Text>;
    }

    const title = (
        <Flexbox flexDirection="column" gap={8}>
            {driverLabels}
        </Flexbox>
    );

    return (
        <Tooltip
            title={driverLabels.length > maxNumberOfDriversShown ? title : ''}
            variant="white"
            placement="bottom-start"
        >
            <Box display="flex" flexDirection="row" style={{ gap: 8 }}>
                {driverLabels.slice(0, maxNumberOfDriversShown)}{' '}
                {driverLabels.length > maxNumberOfDriversShown && (
                    <Tag attention="low" color={'violet'} label={`+${driverLabels.length - maxNumberOfDriversShown}`} />
                )}
            </Box>
        </Tooltip>
    );
};
