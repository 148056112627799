/* eslint-disable camelcase */
import * as r from 'runtypes';

// please keep in sync with `rust-workspace/epibator/src/routes/organization_settings/site/mod.rs` on the BE
export const CountryAlpha2CodeRuntype = r.Union(
    r.Literal('AF'),
    r.Literal('AX'),
    r.Literal('AL'),
    r.Literal('DZ'),
    r.Literal('AS'),
    r.Literal('AD'),
    r.Literal('AO'),
    r.Literal('AI'),
    r.Literal('AQ'),
    r.Literal('AG'),
    r.Literal('AR'),
    r.Literal('AM'),
    r.Literal('AW'),
    r.Literal('AU'),
    r.Literal('AT'),
    r.Literal('AZ'),
    r.Literal('BS'),
    r.Literal('BH'),
    r.Literal('BD'),
    r.Literal('BB'),
    r.Literal('BY'),
    r.Literal('BE'),
    r.Literal('BZ'),
    r.Literal('BJ'),
    r.Literal('BM'),
    r.Literal('BT'),
    r.Literal('BO'),
    r.Literal('BQ'),
    r.Literal('BA'),
    r.Literal('BW'),
    r.Literal('BV'),
    r.Literal('BR'),
    r.Literal('IO'),
    r.Literal('BN'),
    r.Literal('BG'),
    r.Literal('BF'),
    r.Literal('BI'),
    r.Literal('CV'),
    r.Literal('KH'),
    r.Literal('CM'),
    r.Literal('CA'),
    r.Literal('KY'),
    r.Literal('CF'),
    r.Literal('TD'),
    r.Literal('CL'),
    r.Literal('CN'),
    r.Literal('CX'),
    r.Literal('CC'),
    r.Literal('CO'),
    r.Literal('KM'),
    r.Literal('CG'),
    r.Literal('CD'),
    r.Literal('CK'),
    r.Literal('CR'),
    r.Literal('CI'),
    r.Literal('HR'),
    r.Literal('CU'),
    r.Literal('CW'),
    r.Literal('CY'),
    r.Literal('CZ'),
    r.Literal('DK'),
    r.Literal('DJ'),
    r.Literal('DM'),
    r.Literal('DO'),
    r.Literal('EC'),
    r.Literal('EG'),
    r.Literal('SV'),
    r.Literal('GQ'),
    r.Literal('ER'),
    r.Literal('EE'),
    r.Literal('ET'),
    r.Literal('FK'),
    r.Literal('FO'),
    r.Literal('FJ'),
    r.Literal('FI'),
    r.Literal('FR'),
    r.Literal('GF'),
    r.Literal('PF'),
    r.Literal('TF'),
    r.Literal('GA'),
    r.Literal('GM'),
    r.Literal('GE'),
    r.Literal('DE'),
    r.Literal('GH'),
    r.Literal('GI'),
    r.Literal('GR'),
    r.Literal('GL'),
    r.Literal('GD'),
    r.Literal('GP'),
    r.Literal('GU'),
    r.Literal('GT'),
    r.Literal('GG'),
    r.Literal('GN'),
    r.Literal('GW'),
    r.Literal('GY'),
    r.Literal('HT'),
    r.Literal('HM'),
    r.Literal('VA'),
    r.Literal('HN'),
    r.Literal('HK'),
    r.Literal('HU'),
    r.Literal('IS'),
    r.Literal('IN'),
    r.Literal('ID'),
    r.Literal('IR'),
    r.Literal('IQ'),
    r.Literal('IE'),
    r.Literal('IM'),
    r.Literal('IL'),
    r.Literal('IT'),
    r.Literal('JM'),
    r.Literal('JP'),
    r.Literal('JE'),
    r.Literal('JO'),
    r.Literal('KZ'),
    r.Literal('KE'),
    r.Literal('KI'),
    r.Literal('KP'),
    r.Literal('KR'),
    r.Literal('KW'),
    r.Literal('KG'),
    r.Literal('LA'),
    r.Literal('LV'),
    r.Literal('LB'),
    r.Literal('LS'),
    r.Literal('LR'),
    r.Literal('LY'),
    r.Literal('LI'),
    r.Literal('LT'),
    r.Literal('LU'),
    r.Literal('MO'),
    r.Literal('MK'),
    r.Literal('MG'),
    r.Literal('MW'),
    r.Literal('MY'),
    r.Literal('MV'),
    r.Literal('ML'),
    r.Literal('MT'),
    r.Literal('MH'),
    r.Literal('MQ'),
    r.Literal('MR'),
    r.Literal('MU'),
    r.Literal('YT'),
    r.Literal('MX'),
    r.Literal('FM'),
    r.Literal('MD'),
    r.Literal('MC'),
    r.Literal('MN'),
    r.Literal('ME'),
    r.Literal('MS'),
    r.Literal('MA'),
    r.Literal('MZ'),
    r.Literal('MM'),
    r.Literal('NA'),
    r.Literal('NR'),
    r.Literal('NP'),
    r.Literal('NL'),
    r.Literal('NC'),
    r.Literal('NZ'),
    r.Literal('NI'),
    r.Literal('NE'),
    r.Literal('NG'),
    r.Literal('NU'),
    r.Literal('NF'),
    r.Literal('MP'),
    r.Literal('NO'),
    r.Literal('OM'),
    r.Literal('PK'),
    r.Literal('PW'),
    r.Literal('PS'),
    r.Literal('PA'),
    r.Literal('PG'),
    r.Literal('PY'),
    r.Literal('PE'),
    r.Literal('PH'),
    r.Literal('PN'),
    r.Literal('PL'),
    r.Literal('PT'),
    r.Literal('PR'),
    r.Literal('QA'),
    r.Literal('RE'),
    r.Literal('RO'),
    r.Literal('RU'),
    r.Literal('RW'),
    r.Literal('BL'),
    r.Literal('SH'),
    r.Literal('KN'),
    r.Literal('LC'),
    r.Literal('MF'),
    r.Literal('PM'),
    r.Literal('VC'),
    r.Literal('WS'),
    r.Literal('SM'),
    r.Literal('ST'),
    r.Literal('SA'),
    r.Literal('SN'),
    r.Literal('RS'),
    r.Literal('SC'),
    r.Literal('SL'),
    r.Literal('SG'),
    r.Literal('SX'),
    r.Literal('SK'),
    r.Literal('SI'),
    r.Literal('SB'),
    r.Literal('SO'),
    r.Literal('ZA'),
    r.Literal('GS'),
    r.Literal('SS'),
    r.Literal('ES'),
    r.Literal('LK'),
    r.Literal('SD'),
    r.Literal('SR'),
    r.Literal('SJ'),
    r.Literal('SZ'),
    r.Literal('SE'),
    r.Literal('CH'),
    r.Literal('SY'),
    r.Literal('TW'),
    r.Literal('TJ'),
    r.Literal('TZ'),
    r.Literal('TH'),
    r.Literal('TL'),
    r.Literal('TG'),
    r.Literal('TK'),
    r.Literal('TO'),
    r.Literal('TT'),
    r.Literal('TN'),
    r.Literal('TR'),
    r.Literal('TM'),
    r.Literal('TC'),
    r.Literal('TV'),
    r.Literal('UG'),
    r.Literal('UA'),
    r.Literal('AE'),
    r.Literal('GB'),
    r.Literal('UM'),
    r.Literal('US'),
    r.Literal('UY'),
    r.Literal('UZ'),
    r.Literal('VU'),
    r.Literal('VE'),
    r.Literal('VN'),
    r.Literal('VG'),
    r.Literal('VI'),
    r.Literal('WF'),
    r.Literal('EH'),
    r.Literal('YE'),
    r.Literal('ZM'),
    r.Literal('ZW'),
);

export const allCountryCodes = CountryAlpha2CodeRuntype.alternatives.map((alternative) => alternative.value);

export type CountryAlpha2Code = r.Static<typeof CountryAlpha2CodeRuntype>;

const AddressRuntype = r.Record({
    streetAddress: r.String.nullable(),
    city: r.String.nullable(),
    country: CountryAlpha2CodeRuntype.nullable(),
    postcode: r.String.nullable(),
});

export type SiteType = r.Static<typeof SiteTypeRuntype>;
const SiteTypeRuntype = r.Union(r.Literal('Manufacturing'), r.Literal('Inventory'), r.Literal('Sourcing'));

export type SiteDTO = r.Static<typeof SiteRuntype>;
export const SiteRuntype = r.Record({
    id: r.String,
    name: r.String,
    address: AddressRuntype.nullable(),
    notes: r.String.nullable(),
    site_number: r.String.nullable(),
    is_default_for: r.Array(SiteTypeRuntype),
    tags: r.Array(SiteTypeRuntype),
});

export type SitePostDTO = r.Static<typeof SitePostRuntype>;
export const SitePostRuntype = SiteRuntype.omit('id');

export type SitePatchDTO = Partial<SitePostDTO>;
