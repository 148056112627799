import { t } from '@lingui/macro';
import { FieldDateControlled, FormItem } from '@luminovo/design-system';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>
    extends UseControllerProps<TFieldValues, TName> {}

export function DueDateFormItem<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    name,
    control,
}: Props<TFieldValues, TName>): JSX.Element {
    return (
        <FormItem label={t`Due date`}>
            <FieldDateControlled name={name} control={control} />
        </FormItem>
    );
}
