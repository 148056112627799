const getInitialLowerCase = (str: string, length: number) => str.slice(0, length).toLocaleLowerCase();
const getInitialUpperCase = (str: string, length: number) => str.slice(0, length).toLocaleUpperCase();
const capitalizeFirstLetter = (str: string) => (str.length === 0 ? str : str[0].toLocaleUpperCase() + str.slice(1));

export function formatInitials({
    firstName,
    lastName,
    email,
}: {
    firstName: string;
    lastName: string;
    email: string;
}): { regular: string; extended: string } {
    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const trimmedEmail = email.split('@')[0].replace(/\./g, '').trim();

    if (trimmedFirstName && trimmedLastName) {
        const regularFirst = getInitialUpperCase(trimmedFirstName, 1);
        const regularLast = getInitialUpperCase(trimmedLastName, 1);

        const extendedFirst = getInitialUpperCase(trimmedFirstName, 1);
        const extendedLast = capitalizeFirstLetter(getInitialLowerCase(trimmedLastName, 3));

        return {
            regular: regularFirst + regularLast,
            extended: extendedFirst + extendedLast,
        };
    }

    return {
        regular: getInitialUpperCase(trimmedEmail, 2) || '??',
        extended: capitalizeFirstLetter(getInitialLowerCase(trimmedEmail, 4)) || '????',
    };
}
