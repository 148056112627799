export function compareByBoolean<T>(
    extractor: (item: T) => boolean,
    opts?: { trueFirst?: boolean },
): (a: T, b: T) => number {
    const { trueFirst = true } = opts ?? {};
    return (a: T, b: T): number => {
        const aValue = extractor(a);
        const bValue = extractor(b);

        if (aValue === bValue) {
            return 0;
        }

        return aValue === trueFirst ? -1 : 1;
    };
}
