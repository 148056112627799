import { transEnum } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import { TotalCostOfOwnershipInputDTO, TotalCostOfOwnershipRuleCategory } from '@luminovo/http-client';
import { totalCostOfOwnershipRuleCategoryTranslations } from '../../i18n';

export const TotaCostOfOwnershipRuleCategoryTag = ({
    category,
}: Pick<TotalCostOfOwnershipInputDTO, 'category'>): JSX.Element => {
    const label = transEnum(category, totalCostOfOwnershipRuleCategoryTranslations);
    switch (category) {
        case TotalCostOfOwnershipRuleCategory.Packaging:
            return <Tag color="green" attention="low" label={label} />;
        case TotalCostOfOwnershipRuleCategory.Discount:
            return <Tag color="violet" attention="low" label={label} />;
        case TotalCostOfOwnershipRuleCategory.Customs:
            return <Tag color="teal" attention="low" label={label} />;
        case TotalCostOfOwnershipRuleCategory.Shipping:
            return <Tag color="yellow" attention="low" label={label} />;
        case TotalCostOfOwnershipRuleCategory.Other:
            return <Tag color="blue" attention="low" label={label} />;
    }
};
