import { assertUnreachable } from '@luminovo/commons';
import { DriverIdDTO } from '@luminovo/http-client';

export const driverIdEquals = (driverId: DriverIdDTO, otherDriverId: DriverIdDTO): boolean => {
    if (driverId.type !== otherDriverId.type) {
        return false;
    }

    const { type, value } = driverId;
    switch (type) {
        case 'System':
        case 'User':
            return value === otherDriverId.value;
        default:
            /* c8 ignore next */
            assertUnreachable(type);
    }
};
