import { formatDecimal } from '@luminovo/commons';
import { Box } from '@mui/material';
import { colorSystem } from '../../theme';
import { Link } from '../Link';
import { parseOptionsValuesOrFunc } from '../TanStackTable/utils';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Chip, ChipProps } from './Chip';

export interface ChipGroupProps<T> {
    limit?: number;
    options: T[];
    getOptionLabel: (item: T) => ChipProps['label'];
    onOptionClick?: (item: T) => void;
    color?: ChipProps['color'] | ((item: T) => ChipProps['color']);
}

export function ChipGroup<T>({
    options,
    getOptionLabel,
    color = 'neutral',
    limit = 3,
    onOptionClick,
}: ChipGroupProps<T>): JSX.Element {
    const visibleOptions = options.slice(0, limit);
    const hiddenOptions = options.slice(limit);

    const tooltipContent = (
        <ul
            style={{
                margin: 0,
                paddingLeft: 18,
                maxHeight: 200,
                overflowY: 'auto',
            }}
        >
            {options.map((option, index) => (
                <li key={index}>
                    {onOptionClick ? (
                        <Link
                            attention="low"
                            variant="body-small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onOptionClick(option);
                            }}
                            sx={{ display: 'inline-block', width: '100%' }}
                        >
                            {getOptionLabel(option)}
                        </Link>
                    ) : (
                        getOptionLabel(option)
                    )}
                </li>
            ))}
        </ul>
    );

    return (
        <Tooltip variant={'white'} title={hiddenOptions.length === 0 ? '' : tooltipContent}>
            <Box display={'flex'} flexWrap="wrap" gap={'4px'} alignItems="center" width={'fit-content'}>
                {visibleOptions.map((option, index) => (
                    <Chip key={index} label={getOptionLabel(option)} color={parseOptionsValuesOrFunc(color, option)} />
                ))}
                {hiddenOptions.length > 0 && (
                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>{`+${formatDecimal(
                        hiddenOptions.length,
                    )}`}</Text>
                )}
            </Box>
        </Tooltip>
    );
}
