import { Flexbox } from '@luminovo/design-system';
import { ErrorInFormulaBox } from '@luminovo/manufacturing-core';
import { Skeleton } from '@mui/material';
import React from 'react';
import { MonacoEditor } from '../../../components/MonacoEditor';
import { MonacoEditorRef } from '../../../components/MonacoEditor/MonacoEditor';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { route } from '../../../utils/routes';
import { getFormulaOfExpense } from '../utils/useDriverOfCostComponentsExpenses';
import { useFormulaInformation } from '../utils/useDriversFormulaInformation';
import { useManufacturingInputControls } from '../utils/useInputControls';

const useFormulaOfAnExpense = (expenseId: string): { isLoading: boolean; formula: string | undefined } => {
    const { data, isLoading } = useHttpQuery('GET /expenses/:expenseId', { pathParams: { expenseId } });

    return {
        isLoading,
        formula: data?.data.cost_components ? getFormulaOfExpense(data?.data.cost_components) : undefined,
    };
};

export const FormulaOfExpense = ({ expenseId }: { expenseId: string }): JSX.Element => {
    const editorRef: MonacoEditorRef = React.useRef();
    const { formula, isLoading: isLoadingFormula } = useFormulaOfAnExpense(expenseId);
    const { validationResponse, isLoading: isLoadingDriversInFormula } = useFormulaInformation(formula);
    const { inputControls, isLoading } = useManufacturingInputControls(formula ?? '');
    if (isLoading || isLoadingFormula || isLoadingDriversInFormula) {
        return <Skeleton />;
    }

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            {validationResponse?.result === 'Error' && (
                <ErrorInFormulaBox route={route('/manufacturing/expense/:expenseId/edit', { expenseId })} />
            )}

            <MonacoEditor editorRef={editorRef} value={formula ?? ''} readOnly inputControls={inputControls} />
        </Flexbox>
    );
};
