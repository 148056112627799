/* eslint-disable camelcase */

import * as r from 'runtypes';

export enum AvailabilityType {
    Stock = 'Stock',
    OnOrder = 'OnOrder',
    OnOrderWithUnknownLeadTime = 'OnOrderWithUnknownLeadTime',
    LeadTime = 'LeadTimeDays',
}

const AvailabilityLeadTimeRuntype = r.Record({
    type: r.Literal(AvailabilityType.LeadTime),
    days: r.Number,
});

const AvailabilityOnOrderWithUnknownLeadTimeRuntype = r.Record({
    type: r.Literal(AvailabilityType.OnOrderWithUnknownLeadTime),
});

const AvailabilityOnOrderRuntype = r.Record({
    type: r.Literal(AvailabilityType.OnOrder),
    days: r.Number,
});

const AvailabilityStockRuntype = r.Record({
    type: r.Literal(AvailabilityType.Stock),
});

export type Availability = r.Static<typeof AvailabilityRuntype>;
export const AvailabilityRuntype = r
    .Union(
        AvailabilityLeadTimeRuntype,
        AvailabilityOnOrderRuntype,
        AvailabilityOnOrderWithUnknownLeadTimeRuntype,
        AvailabilityStockRuntype,
    )
    .And(
        r.Record({
            shipping_time_in_days: r.Number.nullable().optional(),
        }),
    );

export type PricePointAvailability = r.Static<typeof PricePointAvailabilityRuntype>;
export const PricePointAvailabilityRuntype = r.Union(AvailabilityLeadTimeRuntype, AvailabilityStockRuntype);
