import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    SupplierTermsCreationDTORuntype,
    SupplierTermsDTORuntype,
    SupplierTermsUpdateDTORuntype,
} from './supplierTermsBackendTypes';

export const supplierTermsEndpoints = {
    'GET /supplier/:supplierId/terms': endpoint({
        description: 'Get supplier terms by supplier id',
        pathParams: r.Record({
            supplierId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(SupplierTermsDTORuntype),
        }),
    }),
    'POST /supplier/:supplierId/terms': endpoint({
        description: 'Add a new supplier terms',
        pathParams: r.Record({
            supplierId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: SupplierTermsCreationDTORuntype,
        responseBody: r.Record({
            data: SupplierTermsDTORuntype,
        }),
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
    'PATCH /supplier-terms/:supplierTermsId': endpoint({
        description: 'Update a supplier terms',
        pathParams: r.Record({
            supplierTermsId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: SupplierTermsUpdateDTORuntype,
        responseBody: r.Record({
            data: SupplierTermsDTORuntype,
        }),
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
    'DELETE /supplier-terms/:supplierTermsId': endpoint({
        description: 'Delete a supplier terms',
        pathParams: r.Record({
            supplierTermsId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Null,
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
};
