import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    ArrowCredentialsInputDTORuntype,
    ArrowLocaleCurrencyDTORuntype,
    ArrowResponseBodyDTORuntype,
} from './arrowBackendTypes';

export const arrowEndpoints = {
    'GET /3rdparty/arrow/credentials': endpoint({
        description: 'Returns credentials for arrow integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ArrowResponseBodyDTORuntype,
    }),
    'POST /3rdparty/arrow/credentials': endpoint({
        description: 'Uploads credentials for arrow API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ArrowCredentialsInputDTORuntype,
        responseBody: ArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'PATCH /3rdparty/arrow/credentials': endpoint({
        description: 'Updates credentials for arrow API integratoin',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ArrowCredentialsInputDTORuntype,
        responseBody: ArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'DELETE /3rdparty/arrow/credentials': endpoint({
        description: 'Deletes existing credentials for arrow API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'GET /3rdparty/arrow/supported-currencies': endpoint({
        description: 'Returns possible currencies for arrow',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ currencies: r.Array(ArrowLocaleCurrencyDTORuntype) }),
    }),
};
