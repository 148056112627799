import { NOTO_SANS } from '@luminovo/design-system';

export const theme = {
    hoverable: {
        transition: 'width 0.8s, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, filter 0.2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 1px 4px 0 rgba(42, 49, 71, 0.2)', // rgb(42, 49, 71) === neutral9
            filter: `drop-shadow(0px 1px 4px rgb(0 0 0 / 0.4))`,
        },
    },
    fontFamily: NOTO_SANS,
};
