import { LanguageEnum } from '../i18n';

const createFormatter = (locale: LanguageEnum) => new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' });

/**
 * Formats a list of items into a human-readable string.
 * e.g. ['a', 'b', 'c'] => 'a, b, and c'
 * Note the use of the Oxford comma https://news.ycombinator.com/item?id=18706528
 */
export const formatList = (items: string[], locale: LanguageEnum): string => {
    const formatter = createFormatter(locale);
    return formatter.format(items);
};
