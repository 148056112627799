import { colorSystem, Flexbox } from '@luminovo/design-system';
import { Card, styled, Typography } from '@mui/material';

interface TeaserCardProps {
    icon: React.ReactNode;
    title: string;
    text: string;
}

const StyledCard = styled(Card)({
    padding: '16px',
    boxShadow: '0px 4px 8px rgba(76, 83, 102, 0.1)',
    minBlockSize: '160px',
});

export const TeaserCard = ({ title, icon, text }: TeaserCardProps): JSX.Element => {
    return (
        <StyledCard>
            <Flexbox gap={8} marginBottom={'16px'}>
                {icon}
                <Typography variant="h2" style={{ color: colorSystem.neutral[8] }}>
                    {title}
                </Typography>
            </Flexbox>
            <Typography variant="body1" style={{ color: colorSystem.neutral[9] }}>
                {text}
            </Typography>
        </StyledCard>
    );
};
