import { isPresent } from '@luminovo/commons';
import { SolutionTag } from '@luminovo/http-client';
import { findSolutionTag } from '../../model';
import { Solution, SolutionErrorTags, SolutionStatus, SolutionWarningTags } from '../../types';

export const extractSolutionStatus = (
    { solutionTags }: Pick<Solution, 'solutionTags'>,
    source: 'original' | 'effective',
): SolutionStatus => {
    if (source === 'effective') {
        const manualStatusTag = findSolutionTag({ solutionTags }, SolutionTag.ManualStatus);
        if (isPresent(manualStatusTag)) {
            return manualStatusTag.content;
        }
    }

    if (solutionTags.some(({ tag }) => SolutionErrorTags.includes(tag))) {
        return SolutionStatus.Error;
    }
    if (solutionTags.some(({ tag }) => SolutionWarningTags.includes(tag))) {
        return SolutionStatus.Warning;
    }
    return SolutionStatus.Good;
};
