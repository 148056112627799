import { colorSystem } from '@luminovo/design-system';
import { Axis, AxisBottom, Orientation } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { MarkerCircle } from '@visx/marker';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { AreaClosed as AreaClosedContainer, LinePath } from '@visx/shape';
import { Tooltip, defaultStyles, useTooltip } from '@visx/tooltip';

export type AreaClosedProps<T extends Datum> = {
    data: T[];
    color: string;
    dotCircleColor?: string;
    Tooltip: ({ datum }: { datum: T }) => JSX.Element;
    valueFormatter?: (
        v: number,
        index: number,
        values: {
            value: T;
            index: number;
        }[],
    ) => string;
    onClick?: (datum: T) => void;
    showYAxis?: boolean;
    //These are the values that will be shown on the x-axis. If not provided, the min and max values of Datum will be used.
    xAxis?: number[];
    // If provided, the y-axis will be fixed to this range. If not provided, the y-axis will be scaled to the data.
    yAxisDomainRange?: [number, number];
};

type Datum = {
    xAxis: number;
    yAxis: number;
};

export function AreaClosed<T extends Datum>(props: AreaClosedProps<T>): JSX.Element {
    return (
        <ParentSize>
            {({ width, height }) => {
                return <AreaClosedInner {...props} parentWidth={width} parentHeight={height} />;
            }}
        </ParentSize>
    );
}

function AreaClosedInner<T extends Datum>({
    parentWidth = 0,
    parentHeight = 0,
    Tooltip: TooltipComponent,
    data,
    color,
    valueFormatter,
    onClick,
    showYAxis = true,
    xAxis,
    dotCircleColor,
    yAxisDomainRange,
}: AreaClosedProps<T> & { parentWidth?: number; parentHeight?: number }): JSX.Element {
    const { tooltipOpen, tooltipTop, tooltipLeft, showTooltip, tooltipData, hideTooltip } = useTooltip<T>();
    const getX = (d: T) => d.xAxis;
    const getY = (d: T) => d.yAxis;

    // bounds
    const xMax = parentWidth;
    const yMax = parentHeight;
    const xScaleDomain = xAxis
        ? [xAxis[0], xAxis[xAxis.length - 1]]
        : [
              data.reduce((max, d) => Math.min(max, getX(d)), Infinity),
              data.reduce((max, d) => Math.max(max, getX(d)), 0),
          ];
    const xScale = scaleLinear({
        domain: xScaleDomain,
        range: [0, xMax],
        round: true,
    });

    const yScaleDomain = yAxisDomainRange
        ? yAxisDomainRange
        : [
              data.reduce((max, d) => Math.min(max, getY(d)), Infinity),
              data.reduce((max, d) => Math.max(max, getY(d)), 0),
          ];

    const yScale = scaleLinear({
        domain: yScaleDomain,
        range: [yMax, 0],
    });

    const tickValues = xAxis ? xAxis : undefined;
    const handleClick = () => {
        if (onClick && tooltipData) onClick(tooltipData);
    };

    const markerCircleColor = dotCircleColor ? dotCircleColor : color;

    return (
        <div style={{ position: 'relative', width: parentWidth, height: parentHeight }}>
            <svg
                onClick={handleClick}
                width={parentWidth}
                height={parentHeight}
                style={{ overflow: 'visible' }}
                onMouseMove={(e) => {
                    const point = localPoint(e);
                    const inverseX = xScale.invert(point?.x ?? 0);
                    const nearest = data.findIndex((d) => d.xAxis >= inverseX) ?? data[0];
                    const dataPoint = data[Math.max(nearest - 1, 0)];
                    if (dataPoint) {
                        showTooltip({
                            tooltipLeft: xScale(dataPoint.xAxis),
                            tooltipTop: 0,
                            tooltipData: dataPoint,
                        });
                    }
                }}
                onMouseLeave={() => hideTooltip()}
            >
                <Group left={0} top={0}>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="30%" stopColor={color} stopOpacity={0.4} />
                        <stop offset="50%" stopColor={color} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={color} stopOpacity={0.1} />
                    </linearGradient>
                    <MarkerCircle id="marker-circle" fill={markerCircleColor} refX="4" size={4} />
                    {showYAxis && (
                        <>
                            <GridRows
                                width={xMax}
                                scale={yScale}
                                strokeDasharray="3,6"
                                numTicks={Math.min(data.length, 5)}
                                stroke={colorSystem.neutral[2]}
                                strokeOpacity={1}
                                pointerEvents="none"
                            />
                            <Axis
                                tickStroke="transparent"
                                orientation={Orientation.left}
                                numTicks={Math.min(data.length, 5)}
                                scale={yScale}
                                tickFormat={(v) => `${v}`}
                                stroke="transparent"
                                tickLabelProps={() => ({
                                    textAnchor: 'end',
                                    dy: 5,
                                    fontSize: 12,
                                    fill: colorSystem.neutral[8],
                                    fontFamily: "'Noto Sans', sans-serif",
                                })}
                            />
                        </>
                    )}
                    <AreaClosedContainer
                        data={data.sort((a, b) => a.xAxis - b.xAxis)}
                        x={(x) => xScale(getX(x))}
                        y={(y) => yScale(getY(y))}
                        yScale={yScale}
                        fill={'url(#gradient'}
                        curve={curveLinear}
                    />
                    {tooltipOpen && (
                        <>
                            <line
                                x1={tooltipLeft}
                                x2={tooltipLeft}
                                y1={0}
                                y2={parentHeight}
                                strokeDasharray="4,2"
                                stroke={color}
                                strokeWidth={1}
                            />
                        </>
                    )}
                    <LinePath
                        data={data}
                        x={(x) => xScale(getX(x))}
                        y={(y) => yScale(getY(y))}
                        stroke={color}
                        strokeWidth={1}
                        curve={curveLinear}
                        markerStart={'url(#marker-circle)'}
                        markerMid={'url(#marker-circle)'}
                        markerEnd={'url(#marker-circle)'}
                    />
                    <AxisBottom
                        top={yMax}
                        scale={xScale}
                        numTicks={Math.min(data.length, 5)}
                        stroke={colorSystem.neutral[2]}
                        tickStroke={'transparent'}
                        tickValues={tickValues}
                        //@ts-ignore
                        tickFormat={valueFormatter}
                        tickLabelProps={() => ({
                            fill: colorSystem.neutral[8],
                            fontSize: 12,
                            textAnchor: 'middle',
                            fontFamily: "'Noto Sans', sans-serif",
                        })}
                    />
                </Group>
            </svg>
            {tooltipData && (
                <>
                    <Tooltip
                        style={toolTipStyles}
                        // set this to random so it correctly updates with parent bounds
                        key={Math.random()}
                        top={tooltipTop}
                        left={tooltipLeft}
                    >
                        <TooltipComponent datum={tooltipData} />
                    </Tooltip>
                </>
            )}
        </div>
    );
}

const toolTipStyles = {
    ...defaultStyles,
    fontFamily: "'Noto Sans', sans-serif",
};
