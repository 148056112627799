import { Trans } from '@lingui/macro';
import { ArrowUpward } from '@mui/icons-material';
import { Button, ButtonProps, styled } from '@mui/material';
import { colorSystem, h4 } from '../../theme';

export interface ScrollTopButtonProps extends ButtonProps {
    id: string;
    label?: string | JSX.Element;
}

function scrollIntoView({ id }: { id: string }) {
    const element = document.getElementById(id);
    if (element) {
        return element.scrollIntoView({ behavior: 'smooth' });
    }
}

export function ScrollTopButton({ id, label }: ScrollTopButtonProps): JSX.Element {
    return (
        <DefaultButton startIcon={<ArrowUpward />} onClick={() => scrollIntoView({ id: id })}>
            {label ? label : <Trans>Go back</Trans>}
        </DefaultButton>
    );
}

const DefaultButton = styled(Button)({
    ...h4,
    background: colorSystem.blue[5],
    color: colorSystem.neutral.white,
    borderRadius: '24px',
    padding: '10px 14px',
    '&:hover': {
        background: colorSystem.blue[6],
    },
});
