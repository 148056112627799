import { t, Trans } from '@lingui/macro';
import {
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
} from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { sortedCountries, translateCountry } from '../../../resources/organizationSettings/countryi18n';

export function CustomerBillingAddressFormInner({ onClose: onCancel }: { onClose: () => void }) {
    const { control } = useFormContext();

    return (
        <Flexbox flexDirection={'column'} gap={16} minWidth={400}>
            <FormItem label={t`Address Line 1`}>
                <FieldTextControlled control={control} name="line1" />
            </FormItem>
            <FormItem label={t`Address Line 2`}>
                <FieldTextControlled control={control} name="line2" />
            </FormItem>
            <FormItem label={t`City`}>
                <FieldTextControlled control={control} name="city" />
            </FormItem>
            <FormItem label={t`Postal code`}>
                <FieldTextControlled control={control} name="postal_code" />
            </FormItem>

            <FormItem label={t`Country`}>
                <FieldSelectControlled
                    name="country_code"
                    control={control}
                    FieldProps={{
                        options: sortedCountries.map((k) => k.value),
                        getOptionLabel: (k) => translateCountry(k),
                    }}
                />
            </FormItem>
            <Flexbox gap={8} justifyContent="flex-end">
                <SecondaryButton onClick={() => onCancel()}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
}
