import { StatusChip } from '@luminovo/design-system';
import { RegionsEnum } from '@luminovo/http-client';
import { formatRegionAsName } from '@luminovo/sourcing-core';
import { Tooltip } from '@mui/material';
import React from 'react';
import { getRegionFlag } from '../utils/getRegionFlag';
import { PcbSupplierOffer } from '../utils/pcbSupplierOfferType';

const getRegionName = (regions: RegionsEnum[]) => {
    if (regions.length > 1) {
        return regions.map((region) => formatRegionAsName(region)).join(', ');
    }
    return formatRegionAsName(regions[0]);
};

export const OfferChip = ({
    supplier,
    style,
    onClick,
    showFlag = true,
    showToolTip = true,
}: {
    supplier: PcbSupplierOffer;
    style?: React.CSSProperties;
    onClick?: () => void;
    showFlag?: boolean;
    showToolTip?: boolean;
}) => {
    const regionFlag = showFlag
        ? supplier.region.length > 1
            ? getRegionFlag(RegionsEnum.World)
            : getRegionFlag(supplier.region[0])
        : null;
    const regionName = getRegionName(supplier.region);

    const shouldShowToolTip = showToolTip === true && typeof regionFlag === 'string'; // the getRegionFlag has a check for the region to be valid and not unknown

    return (
        <Tooltip
            title={regionName}
            placement="bottom"
            id={supplier.name}
            disableHoverListener={shouldShowToolTip === false}
        >
            <span>
                <StatusChip
                    label={
                        <>
                            {supplier.name}
                            {regionFlag && <span> {regionFlag}</span>}
                        </>
                    }
                    color={supplier.status}
                    disabled={!supplier.approved}
                    style={style}
                    onClick={onClick}
                    enableHoverEffect={shouldShowToolTip}
                />
            </span>
        </Tooltip>
    );
};
