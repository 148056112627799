import { Trans } from '@lingui/macro';
import { Link } from '@luminovo/design-system';
import { MenuBookRounded } from '@mui/icons-material';
import { analytics } from '../../../../utils/analytics';
import { useColumnMappingGuidance } from './useColumnMappingGuidance';

export const ColumnMappingGuidanceBanner = () => {
    const { openDrawer } = useColumnMappingGuidance();

    return (
        <Link
            attention="high"
            startIcon={<MenuBookRounded />}
            onClick={() => {
                analytics.track('clicked_column_mapping_guidance');
                openDrawer();
            }}
        >
            <Trans>How to use the column tags?</Trans>
        </Link>
    );
};
