import { ArrowDropDownRounded } from '@mui/icons-material';
import { Select as MuiSelect, SelectProps as MuiSelectProps, styled } from '@mui/material';
import React from 'react';
import { body, bodySmall, colorSystem } from '../../theme';

export type SelectProps = Omit<MuiSelectProps, 'size'> & {
    size?: 'large' | 'small';
};

export const Select = React.forwardRef(function Select(
    { size = 'large', ...props }: SelectProps,
    ref: React.ForwardedRef<unknown>,
): JSX.Element {
    switch (size) {
        case 'small':
            return (
                <SmallSelect ref={ref} IconComponent={ArrowDropDownRounded} {...props}>
                    {props.children}
                </SmallSelect>
            );
        case 'large':
            return (
                <LargeSelect ref={ref} IconComponent={ArrowDropDownRounded} {...props}>
                    {props.children}
                </LargeSelect>
            );
        default:
            return <MuiSelect ref={ref} {...props} />;
    }
});

const DefaultSelect = styled(MuiSelect)({
    boxSizing: 'border-box',
    padding: '0px',
    '& fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.neutral[3]}`,
        },
    },
    '&:hover fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.primary[5]}`,
        },
    },

    '&.Mui-focused fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.primary[5]}`,
            boxShadow: `0px 0px 0px 2px #D9E0FF`,
        },
    },

    '&.Mui-error fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.red[5]}`,
        },
    },
    '&.Mui-error:hover fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.red[5]}`,
        },
    },

    '&.Mui-disabled': {
        background: colorSystem.neutral[1],
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
            '& .MuiTypography-root': {
                color: colorSystem.neutral[5],
            },
        },
    },
    '&.Mui-disabled fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.neutral[3]}`,
        },
    },
    '&.Mui-disabled:hover fieldset': {
        '&.MuiOutlinedInput-notchedOutline ': {
            border: `1px solid ${colorSystem.neutral[3]}`,
        },
    },

    '& .MuiOutlinedInput-multiline': {
        padding: 0,
    },

    '& .MuiInputBase-input': {
        color: colorSystem.neutral[9],
        '&::placeholder': {
            color: colorSystem.neutral[6],
            opacity: 1,
        },
        '&.Mui-disabled': {
            '&::placeholder': {
                color: colorSystem.neutral[5],
            },
        },
    },

    // Style the start adornment
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
        margin: '0 0 0 8px',
        padding: 0,
        '& .MuiTypography-root': {
            ...bodySmall,
            color: colorSystem.neutral[8],
            background: colorSystem.neutral[1],
            padding: '2px 4px',
            borderRadius: '4px',
            border: `1px solid ${colorSystem.neutral[2]}`,
        },
    },
});

const SmallSelect = styled(DefaultSelect)({
    height: '32px',
    '& .MuiInputBase-input': {
        padding: '8px',
        ...bodySmall,
        '&::placeholder': {
            ...bodySmall,
        },
    },
});

const LargeSelect = styled(DefaultSelect)({
    height: '40px',
    '& .MuiOutlinedInput-input': {
        padding: '10px 8px',
    },
    '& .MuiInputBase-input': {
        ...body,
        '&::placeholder': {
            ...body,
        },
    },
});
