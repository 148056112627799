import * as React from 'react';
import { useCurrentUserDetailsContext } from '../../contexts/CurrentUserDetailsContext';

export function useCustomTitle() {
    const { organization } = useCurrentUserDetailsContext();

    React.useEffect(() => {
        window.document.title = `${organization.name} Portal`;
    }, [organization]);
}
