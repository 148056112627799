import { FormulaResponseDTO, UserDriverDetailsDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

interface UseFormulaInformation {
    drivers: UserDriverDetailsDTO[] | undefined;
    isLoading: boolean;
    validationResponse: undefined | FormulaResponseDTO;
}

export const useFormulaInformation = (formula: string | undefined): UseFormulaInformation => {
    const { data, isLoading: isLoadingValidate } = useHttpQuery(
        'POST /formula/validate',
        {
            requestBody: { formula: formula ?? 'should not be called' },
        },
        {
            enabled: formula !== undefined,
        },
    );

    const { data: driversData, isLoading: isLoadingDrivers } = useHttpQuery(
        'GET /user-drivers',
        {},
        { enabled: data?.result === 'Ok' },
    );
    const identifiers = data?.result === 'Ok' ? data.details.identifiers : [];
    const driversInFormula: UserDriverDetailsDTO[] | undefined = driversData?.data.filter((driver) =>
        identifiers.includes(driver.name.replaceAll(' ', '_')),
    );

    return {
        drivers: driversInFormula,
        isLoading: isLoadingDrivers || isLoadingValidate,
        validationResponse: data,
    };
};
