import { t } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import { route } from '../../utils/routes';
import { RfqCommentsButton } from '../RfqDashboard/components/ToolbarOemDashboard';

export function ToolbarRfqOrder({ rfqId }: { rfqId: string }) {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Dashboard`,
                    href: route(`/rfqs/:rfqId/dashboard`, {
                        rfqId,
                    }),
                },
                { title: t`Order` },
            ]}
        >
            <RfqCommentsButton rfqId={rfqId} />
        </Toolbar>
    );
}
