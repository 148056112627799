import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ManufacturingSourcingCacheLastRefreshRuntime } from './manufacturingSourcingCacheBackendTypes';

export const manufacturingSourcingCacheEndpoints = {
    'GET /manufacturing-cache/sourcing/last_refresh/:assemblyId': endpoint({
        description: "Get the timestamp when the assembly's sourcing cache was last refreshed",
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ManufacturingSourcingCacheLastRefreshRuntime,
    }),
    'POST /manufacturing-cache/sourcing/refresh/:assemblyId': endpoint({
        description: 'Refresh the sourcing cache for the assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: [
            'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
            'GET /calculations/calculation-assembly-costs',
            'GET /manufacturing-cache/sourcing/last_refresh/:assemblyId',
        ],
    }),
};
