import { HighlightableString, joinFragments } from './highlightableString';

/**
 * works just like the mpnHighlighting, but this time a
 * query can be matched multiple times instead of once in the
 * text / description provided.
 *
 * 1. Split the query by whitespaces
 * 2. Match the text with each (split) query
 * 3. Loop through all returned Highlightablestrings & check if any of them at a specific index
 *  is highlighted. If so, highlight that specific fragment.
 */
export function highlightIPNMatches(description: string, query: string): HighlightableString {
    const splitQuery = query.split(/\s+/);
    const allFragmentsFromSplitQueries = splitQuery.map((query) =>
        highlightIPNTextWithIndividualQueryItem(description, query),
    );
    const firstHighlightableStringInList = allFragmentsFromSplitQueries[0] ?? [];
    const result: HighlightableString = [];
    for (let i = 0; i < firstHighlightableStringInList.length; i++) {
        const fragment = firstHighlightableStringInList[i].fragment;
        const isHighlighted = isAnyFragmentAtIndexHighlighted(i, allFragmentsFromSplitQueries);
        result.push({
            fragment,
            isHighlighted,
        });
    }
    return joinFragments(result, 1);
}

/****
 * 1. if the currentTextChar matches the query character & previous or next character also matches then highlight it.
 * 2. If not mark the character's isHighlighted as false.
 */

function highlightIPNTextWithIndividualQueryItem(description: string, query: string) {
    let queryIndex = 0;
    let descriptionIndex = 0;
    const fragments = [];

    while (descriptionIndex < description.length) {
        const currentTextChar = description[descriptionIndex];
        if (
            queryIndex < query.length &&
            shouldHighlightCharacter(fragments, { description, descriptionIndex }, { query, queryIndex })
        ) {
            fragments.push({
                fragment: currentTextChar,
                isHighlighted: true,
            });
            queryIndex = incrementOrGoBackToZero(query, queryIndex);
        } else {
            fragments.push({
                fragment: currentTextChar,
                isHighlighted: false,
            });
        }
        descriptionIndex++;
    }

    return fragments;
}

function shouldHighlightCharacter(
    fragments: HighlightableString,
    descriptionParams: { description: string; descriptionIndex: number },
    queryParams: { query: string; queryIndex: number },
) {
    const { query, queryIndex } = queryParams;
    const { description, descriptionIndex } = descriptionParams;
    const doesCurrentQueryMatch = description[descriptionIndex].toLowerCase() === query[queryIndex].toLowerCase();
    return (
        doesCurrentQueryMatch &&
        (wasPreviousCharacterHighlighted(fragments) ||
            doesNextCharacterMatch(description, descriptionIndex, query, queryIndex))
    );
}

function wasPreviousCharacterHighlighted(fragments: HighlightableString) {
    if (fragments.length === 0) {
        return false;
    }
    return fragments[fragments.length - 1].isHighlighted;
}

function doesNextCharacterMatch(
    description: string,
    currentDescriptionIndex: number,
    query: string,
    currentQueryIndex: number,
) {
    if (currentDescriptionIndex + 1 === description.length) {
        return false;
    }
    const nextQueryIndex = incrementOrGoBackToZero(query, currentQueryIndex);
    return description[currentDescriptionIndex + 1].toLowerCase() === query[nextQueryIndex].toLowerCase();
}

function incrementOrGoBackToZero(text: string, currentIndex: number) {
    if (currentIndex === text.length - 1) {
        return 0;
    }
    return currentIndex + 1;
}

function isAnyFragmentAtIndexHighlighted(
    indexOfInnerFragmentList: number,
    fragmentsList: { fragment: string; isHighlighted: boolean }[][],
) {
    for (
        let indexOfOuterFragmentList = 0;
        indexOfOuterFragmentList < fragmentsList.length;
        indexOfOuterFragmentList++
    ) {
        const currentFragment = fragmentsList[indexOfOuterFragmentList][indexOfInnerFragmentList];
        if (currentFragment.isHighlighted) {
            return true;
        }
    }
    return false;
}
