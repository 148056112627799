import { cases } from '../combinators/cases';
import { Parser } from './Parser';

export function boolean(): Parser<boolean> {
    return cases({
        matches: [
            {
                when: ['true', 'yes', 'ja', 'si', 'oui', 'sì', 'da', 'tak', 'igen', 'evet', 'да', '是', '是的', 'ha'],
                then: true,
            },
            {
                when: ['false', 'no', 'nein', 'non', 'nie', 'hayır', 'нет', '否', '不是', 'nem'],
                then: false,
            },
        ],
    });
}
