import { assertPresent } from '../../../../../utils/assertPresent';
import { assertCellFixedCost, assertCellFixedPercentageCost, assertCellIsDynamicCost } from '../../types/cellTypes';
import { startsWithExtraField } from '../../types/extraFieldType';
import { RowId } from '../../types/rowIdTypes';
import { Row } from '../../types/rowTypes';
import {
    AdditionalOtherCostsSection,
    AdditionalProfitAndDiscountSection,
    ExtraCost,
    ManufacturingCostSection,
    MaterialCostSection,
    PostProfitCostsSection,
    ProjectCostsSection,
    SummarySection,
    TableColumn,
} from '../../types/tableColumnTypes';

const mapExtraCosts = (row: Row, index: number): ExtraCost => {
    const cell = assertCellIsDynamicCost(row.cells[index]);
    return {
        type: 'extra',
        name: row.label,
        cost: cell,
    };
};

const getRowWithId = (rows: Row[]) => {
    return (id: RowId) => rows.find((row) => row.id === id);
};

const convertMaterialCostRowsToColumn = (rows: Row[], index: number): TableColumn => {
    const getRow = getRowWithId(rows);

    const materialCostSection: MaterialCostSection = {
        type: 'material-cost',
        cost: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]),
        price: assertCellFixedCost(assertPresent(getRow('material-cost-price')).cells[index]),
        subTotalCost: assertCellFixedCost(assertPresent(getRow('material-cost-subtotal-cost')).cells[index]),
        customCosts: {
            type: 'custom',
            profit: assertCellIsDynamicCost(assertPresent(getRow('material-cost-profit')).cells[index]),
            discount: assertCellIsDynamicCost(assertPresent(getRow('material-cost-discount')).cells[index]),
            extraCosts: rows
                .filter((row) => startsWithExtraField(row.id, 'material-cost-extra'))
                .map((row) => mapExtraCosts(row, index)),
        },
    };

    const manufacturingCostSection: ManufacturingCostSection = {
        type: 'manufacturing-cost',
        cost: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-cost')).cells[index]),
        subTotalCost: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-subtotal-cost')).cells[index]),
        price: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-price')).cells[index]),
        customCosts: {
            type: 'custom',
            profit: assertCellIsDynamicCost(assertPresent(getRow('manufacturing-cost-profit')).cells[index]),
            discount: assertCellIsDynamicCost(assertPresent(getRow('manufacturing-cost-discount')).cells[index]),
            extraCosts: rows
                .filter((row) => startsWithExtraField(row.id, 'manufacturing-cost-extra'))
                .map((row) => mapExtraCosts(row, index)),
        },
    };

    const postProfitCostsSection: PostProfitCostsSection = {
        type: 'additional-post-profit',
        value: rows
            .filter((row) => startsWithExtraField(row.id, 'additional-post-profit-extra'))
            .map((row) => mapExtraCosts(row, index)),
    };

    const additionalOtherCostsSection: AdditionalOtherCostsSection = {
        type: 'additional-other-costs',
        value: rows
            .filter((row) => startsWithExtraField(row.id, 'additional-other-costs-extra'))
            .map((row) => mapExtraCosts(row, index)),
    };

    const additionalProfitAndDiscountSection: AdditionalProfitAndDiscountSection = {
        type: 'additional-profit',
        discount: assertCellIsDynamicCost(assertPresent(getRow('additional-profit-discount')).cells[index]),
        profit: assertCellIsDynamicCost(assertPresent(getRow('additional-profit-profit')).cells[index]),
    };

    const projectCostsSection: ProjectCostsSection = {
        type: 'project-cost',
        cost: {
            cost: assertCellFixedCost(assertPresent(getRow('project-cost-cost')).cells[index]),
            expenses: [],
            activities: [],
            expenseCosts: {},
            activityCosts: {},
        },
        price: assertCellFixedCost(assertPresent(getRow('project-cost-price')).cells[index]),
        profit: assertCellIsDynamicCost(assertPresent(getRow('project-cost-profit')).cells[index]),
    };

    const summarySection: SummarySection = {
        type: 'summary',
        price: assertCellFixedCost(assertPresent(getRow('summary-price')).cells[index]),
        totalCost: assertCellFixedCost(assertPresent(getRow('summary-totalCost')).cells[index]),
        totalProfit: assertCellFixedPercentageCost(assertPresent(getRow('summary-totalProfit')).cells[index]),
    };

    return {
        sourcingCombinationId: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .sourcingCombinationId,
        batchSize: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]).batchSize,
        orderSize: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]).orderSize,
        leadTime: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .sourcingTotalAvailability,
        manufacturingLeadTime: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .manufacturingLeadTime,
        sections: [
            materialCostSection,
            manufacturingCostSection,
            additionalOtherCostsSection,
            additionalProfitAndDiscountSection,
            postProfitCostsSection,
            projectCostsSection,
            summarySection,
        ],
    };
};

export const convertRowsToTableColumns = (rows: Row[]): TableColumn[] => {
    const allCellsHere = assertPresent(rows.find((row) => row.id === 'material-cost-cost')).cells;
    return allCellsHere.map((_, index) => convertMaterialCostRowsToColumn(rows, index));
};
