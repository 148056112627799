import { isPresent } from '@luminovo/commons';
import { AttributeExtractionRule } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { parseSurfaceFinish } from '../parsers/parseSurfaceFinish';
import { applyConfidencePenaltyIfNotSelected, setSelected } from '../utils';

// const surfaceFinishKeywords = ['Finish', 'Surface', 'Plating', 'Oberfläche', 'Oberflaeche', 'Finisch'];

export const extractSurfaceFinish: AttributeExtractionRule<PcbAttribute> = (item) => {
    const stringToParse = `${item.context?.content} ${item.value.content}`;
    const attribute = parseSurfaceFinish(stringToParse);
    return isPresent(attribute)
        ? [setSelected(stringToParse, applyConfidencePenaltyIfNotSelected(stringToParse, attribute))]
        : [];
};
