import { t } from '@lingui/macro';
import type { Parser } from '../types';

export const validEmailRegex = new RegExp('^\\S+@\\S+\\.\\S+$');

export interface ParseEmailOptions {
    records: Array<{ id: string; email: string }>;
    errorOnExisting?: boolean;
}

export const parseEmail: Parser<string, ParseEmailOptions> = async ([cell = ''], opts, field) => {
    cell = cell.trim().toLowerCase();
    const alternatives = opts.records.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (!validEmailRegex.test(cell)) {
        return {
            status: 'error',
            message: 'Not a valid email address',
            alternatives,
        };
    }
    const found = alternatives.find((alt) => alt.label === cell);

    if (found && opts.errorOnExisting) {
        return {
            status: 'error',
            message:
                t`Email already exists.` +
                ' ' +
                t`We currently only support inviting new users, not updating existing ones.`,
            value: found,
            alternatives,
        };
    }

    if (found) {
        return {
            status: 'done',
            value: found,
            alternatives,
        };
    }

    return {
        value: {
            id: cell,
            label: cell,
            existing: false,
        },
        status: 'done',
        alternatives,
    };
};

function toParsedValue(record: { id: string; email: string }) {
    return {
        id: record.email,
        label: record.email.toLowerCase(),
        existing: true,
    };
}
