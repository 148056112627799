import { t, Trans } from '@lingui/macro';
import { colorSystem, DialogContent, Flexbox, Text } from '@luminovo/design-system';
import { ColumnMappingAttemptDTO } from '@luminovo/http-client';
import { StatusMessage } from '@luminovo/manufacturing-core';
import { Box, styled } from '@mui/material';
import { CSVLines } from '../types/types';
import { ColumnMappingForm } from './ColumnMappingForm';
import { ColumnMappingTable } from './ColumnMappingTable';
import { convertLinesToItems } from './convertLinesToItems';

const ImportInformation = ({ fileName }: { fileName: string }) => {
    return (
        <Flexbox flexDirection={'row'} gap={4}>
            <Text variant="h3" style={{ color: colorSystem.neutral[8] }}>
                <Trans>Importing</Trans>
            </Text>
            <Text variant="h3" style={{ color: colorSystem.neutral[8], fontStyle: 'italic' }}>
                {fileName}
            </Text>
        </Flexbox>
    );
};

export const PnpImporterDialogContent = ({
    lines,
    fileName,
    uploadId,
    columnMappingAttempt,
    onImportSuccess,
}: {
    lines: CSVLines;
    fileName: string;
    uploadId: string;
    columnMappingAttempt: ColumnMappingAttemptDTO;
    onImportSuccess: () => void;
}): JSX.Element => {
    return (
        <DialogContent style={{ paddingTop: '32px', paddingBottom: '24px', background: colorSystem.neutral[1] }}>
            <Flexbox flexDirection={'column'} gap={'8px'}>
                <Container>
                    <ImportInformation fileName={fileName} />
                    <StatusMessage text={t`We only extract designator and side information`} variant={'info'} />
                </Container>
                <Container>
                    <ColumnMappingForm
                        csvLines={lines}
                        uploadId={uploadId}
                        columnMappingAttempt={columnMappingAttempt}
                        onSuccess={onImportSuccess}
                    />
                </Container>
                <ColumnMappingTable items={convertLinesToItems(lines)} />
            </Flexbox>
        </DialogContent>
    );
};

const Container = styled(Box)({
    position: 'sticky',
    zIndex: 2,
    left: 0,
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: 16,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
});
