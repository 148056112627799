import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, hashStringToColor, Text } from '@luminovo/design-system';
import { UserDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useUser } from '../../../../../resources/user/userHandler';

export function SectionDefaultContactPerson() {
    const { customer, organization } = useCurrentUserDetailsContext();
    const defaultContactPerson = customer?.default_contact_person ?? undefined;
    const { data: contactPersonDTO } = useUser(defaultContactPerson);

    return (
        <Flexbox flexDirection={'column'} gap={8} maxHeight={'300px'} style={{ overflowY: 'auto' }}>
            <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                <Trans>{organization.name} contact person</Trans>
            </Text>
            {isPresent(contactPersonDTO) ? (
                <DefaultContactPerson contactPerson={contactPersonDTO} />
            ) : (
                <Text>{t`No contact person found`}</Text>
            )}
        </Flexbox>
    );
}

function DefaultContactPerson({ contactPerson }: { contactPerson: UserDTO }) {
    return (
        <Box display="grid" gridTemplateColumns={'auto 1fr'} alignItems="center" rowGap={'4px'} columnGap="8px">
            <Box
                style={{
                    width: 8,
                    height: 8,
                    borderRadius: 2,
                    background: colorSystem[hashStringToColor(contactPerson.email)][5],
                }}
            />
            <Text variant="h4">
                {contactPerson.first_name} {contactPerson.last_name}
            </Text>

            <span />
            <Text style={{ color: colorSystem.neutral[6] }}>{contactPerson.email}</Text>

            <span />
            <Text style={{ color: colorSystem.neutral[6] }}>{contactPerson.phone_number}</Text>
        </Box>
    );
}
