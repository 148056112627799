import { colorSystem, Tooltip } from '@luminovo/design-system';
import { styled } from '@mui/material';
import React from 'react';
import { minBy } from '../model/RegionNetwork/minBy';
import { Region } from '../model/RegionNetwork/types';
import { Viewbox } from '../model/Viewbox';
import { AttributeTag } from './AttributeTag';

export function PolygonView({
    region,
    viewbox,
    innerRef,
    isAssociated = false,
    isSelected = false,
    regionTooltip = defaultRegionTooltip,
    ...rest
}: {
    region: Region;
    viewbox: Viewbox;
    innerRef?: React.RefObject<SVGRectElement>;
    isSelected?: boolean;
    isAssociated?: boolean;
    regionTooltip?: RegionTooltipType;
} & Pick<React.SVGProps<SVGRectElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>): JSX.Element {
    const box = region.box.applyViewbox(viewbox).addPadding(4);

    if (isSelected) {
        return (
            <Tooltip variant={'white'} title={regionTooltip({ region })}>
                <SelectedPolygon
                    id={polygonId(region)}
                    ref={innerRef}
                    x={box.x}
                    y={box.y}
                    width={box.width}
                    height={box.height}
                    {...rest}
                />
            </Tooltip>
        );
    }

    const isRow = region.attributes.some((attr) => attr.attr === 'isRow');

    if (isRow) {
        return <></>;
    }

    const isNumber = region.attributes.every((attr) => attr.attr === 'number') && region.attributes.length > 0;

    if (isNumber) {
        return (
            <Tooltip variant={'white'} title={regionTooltip({ region })}>
                <NumberPolygon
                    id={polygonId(region)}
                    ref={innerRef}
                    x={box.x}
                    y={box.y}
                    width={box.width}
                    height={box.height}
                    {...rest}
                />
            </Tooltip>
        );
    }

    const hasAttributes = region.attributes.length > 0;
    if (hasAttributes) {
        return (
            <Tooltip variant={'white'} title={regionTooltip({ region })}>
                <PolygonWithStroke
                    id={polygonId(region)}
                    ref={innerRef}
                    x={box.x}
                    y={box.y}
                    width={box.width}
                    height={box.height}
                    {...rest}
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip variant={'white'} title={regionTooltip({ region })}>
            <RegularPolygon
                id={polygonId(region)}
                ref={innerRef}
                x={box.x}
                y={box.y}
                width={box.width}
                height={box.height}
                {...rest}
            />
        </Tooltip>
    );
}

function polygonId(region: Region) {
    return `polygon::${region.id}`;
}

export function scrollToRegions(regions: Region[]): Region | undefined {
    const topRegion = minBy(regions, (region) => region.box.y);
    if (!topRegion) {
        return;
    }
    // find element with the class .page and the attribute data-page-number={pageNumber}
    const page = document.querySelector(`.page[data-page-number="${topRegion.pageNumber}"]`);

    const positions: ScrollLogicalPosition[] = ['start', 'center', 'end'];
    const xIndex = Math.floor(topRegion.box.y * 3);
    const yIndex = Math.floor(topRegion.box.x * 3);

    // scroll to center
    page?.scrollIntoView({
        behavior: 'smooth',
        block: positions[xIndex],
        inline: positions[yIndex],
    });
    return topRegion;
}

export type RegionTooltipType = typeof defaultRegionTooltip;
function defaultRegionTooltip({ region }: { region: Region }) {
    // Css grid with 3 columns: attr, value, confidence
    if (region.attributes.length === 0) {
        return '';
    }
    return (
        <>
            {region.attributes.map((attr) => (
                <AttributeTag attr={attr} key={attr.attr} />
            ))}
        </>
    );
}

const BasePolygon = styled('rect')({
    rx: 2,
    cursor: 'pointer',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    vectorEffect: 'non-scaling-stroke',

    '&:hover': {
        strokeWidth: '2px',
        strokeOpacity: 1,
    },
});

const NumberPolygon = styled('rect')({
    fill: colorSystem.blue[6],
    fillOpacity: 0.2,
});

const SelectedPolygon = styled(BasePolygon)({
    fill: colorSystem.primary[5],
    strokeOpacity: 1,
    strokeWidth: '2px',
    stroke: colorSystem.primary[6],
});

const PolygonWithStroke = styled(BasePolygon)({
    fill: colorSystem.violet[7],
    rx: 4,
    fillOpacity: 0.35,
    transition: 'fill 0.2s ease-in-out',
    '&:hover': {
        fill: colorSystem.violet[4],
        stroke: colorSystem.violet[8],
        strokeWidth: '1px',
        strokeOpacity: 1,
    },
});

const RegularPolygon = styled(BasePolygon)({
    fill: colorSystem.neutral[6],
    fillOpacity: 0.1,
    '&:hover': {
        fill: colorSystem.violet[4],
        stroke: colorSystem.violet[8],
        strokeWidth: '1px',
        strokeOpacity: 1,
    },
});
