import { Trans } from '@lingui/macro';
import { Flexbox, RightBoxDrawer, Text } from '@luminovo/design-system';
import React from 'react';
import { CloseDrawerButton, useDrawerContext } from '../../../components/contexts/ModalContext';

function StandardLineCardGraphic(): JSX.Element {
    return (
        <svg width="508" height="172" viewBox="0 0 508 172" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10649_36674)">
                <rect width="508" height="172" rx="16" fill="#F5F6FA" />
                <g opacity="0.9" filter="url(#filter0_f_10649_36674)">
                    <rect width="80" height="6" rx="2" transform="matrix(1 0 0 -1 -21 179)" fill="#9789F4" />
                </g>
                <g opacity="0.9" filter="url(#filter1_f_10649_36674)">
                    <rect width="80" height="29" rx="2" transform="matrix(1 0 0 -1 461 -6)" fill="#9789F4" />
                </g>
                <path
                    d="M307 38C307 31.3726 312.373 26 319 26H375C381.627 26 387 31.3726 387 38C387 44.6274 381.627 50 375 50H319C312.373 50 307 44.6274 307 38Z"
                    fill="white"
                />
                <path
                    d="M307 86C307 79.3726 312.373 74 319 74H375C381.627 74 387 79.3726 387 86C387 92.6274 381.627 98 375 98H319C312.373 98 307 92.6274 307 86Z"
                    fill="white"
                />
                <path
                    d="M307 134C307 127.373 312.373 122 319 122H375C381.627 122 387 127.373 387 134C387 140.627 381.627 146 375 146H319C312.373 146 307 140.627 307 134Z"
                    fill="white"
                />
                <path
                    d="M93 86C93 79.3726 98.3726 74 105 74H132C138.627 74 144 79.3726 144 86C144 92.6274 138.627 98 132 98H105C98.3726 98 93 92.6274 93 86Z"
                    fill="#9789F4"
                />
                <path
                    d="M334.13 35.02C334.863 35.02 335.507 35.1634 336.06 35.45C336.62 35.7367 337.05 36.1467 337.35 36.68C337.657 37.2067 337.81 37.82 337.81 38.52C337.81 39.22 337.657 39.8334 337.35 40.36C337.05 40.88 336.62 41.2834 336.06 41.57C335.507 41.8567 334.863 42 334.13 42H331.69V35.02H334.13ZM334.08 40.81C334.813 40.81 335.38 40.61 335.78 40.21C336.18 39.81 336.38 39.2467 336.38 38.52C336.38 37.7934 336.18 37.2267 335.78 36.82C335.38 36.4067 334.813 36.2 334.08 36.2H333.09V40.81H334.08Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.568 35.8C339.321 35.8 339.115 35.7234 338.948 35.57C338.788 35.41 338.708 35.2134 338.708 34.98C338.708 34.7467 338.788 34.5534 338.948 34.4C339.115 34.24 339.321 34.16 339.568 34.16C339.815 34.16 340.018 34.24 340.178 34.4C340.345 34.5534 340.428 34.7467 340.428 34.98C340.428 35.2134 340.345 35.41 340.178 35.57C340.018 35.7234 339.815 35.8 339.568 35.8ZM340.258 36.46V42H338.858V36.46H340.258Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.811 36.37C344.224 36.37 344.588 36.4534 344.901 36.62C345.214 36.78 345.461 36.99 345.641 37.25V36.46H347.051V42.04C347.051 42.5534 346.948 43.01 346.741 43.41C346.534 43.8167 346.224 44.1367 345.811 44.37C345.398 44.61 344.898 44.73 344.311 44.73C343.524 44.73 342.878 44.5467 342.371 44.18C341.871 43.8134 341.588 43.3134 341.521 42.68H342.911C342.984 42.9334 343.141 43.1334 343.381 43.28C343.628 43.4334 343.924 43.51 344.271 43.51C344.678 43.51 345.008 43.3867 345.261 43.14C345.514 42.9 345.641 42.5334 345.641 42.04V41.18C345.461 41.44 345.211 41.6567 344.891 41.83C344.578 42.0034 344.218 42.09 343.811 42.09C343.344 42.09 342.918 41.97 342.531 41.73C342.144 41.49 341.838 41.1534 341.611 40.72C341.391 40.28 341.281 39.7767 341.281 39.21C341.281 38.65 341.391 38.1534 341.611 37.72C341.838 37.2867 342.141 36.9534 342.521 36.72C342.908 36.4867 343.338 36.37 343.811 36.37ZM345.641 39.23C345.641 38.89 345.574 38.6 345.441 38.36C345.308 38.1134 345.128 37.9267 344.901 37.8C344.674 37.6667 344.431 37.6 344.171 37.6C343.911 37.6 343.671 37.6634 343.451 37.79C343.231 37.9167 343.051 38.1034 342.911 38.35C342.778 38.59 342.711 38.8767 342.711 39.21C342.711 39.5434 342.778 39.8367 342.911 40.09C343.051 40.3367 343.231 40.5267 343.451 40.66C343.678 40.7934 343.918 40.86 344.171 40.86C344.431 40.86 344.674 40.7967 344.901 40.67C345.128 40.5367 345.308 40.35 345.441 40.11C345.574 39.8634 345.641 39.57 345.641 39.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M349.128 35.8C348.882 35.8 348.675 35.7234 348.508 35.57C348.348 35.41 348.268 35.2134 348.268 34.98C348.268 34.7467 348.348 34.5534 348.508 34.4C348.675 34.24 348.882 34.16 349.128 34.16C349.375 34.16 349.578 34.24 349.738 34.4C349.905 34.5534 349.988 34.7467 349.988 34.98C349.988 35.2134 349.905 35.41 349.738 35.57C349.578 35.7234 349.375 35.8 349.128 35.8ZM349.818 36.46V42H348.418V36.46H349.818Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.142 42L352.602 38.89V42H351.202V35.02H352.602V38.15L355.142 35.02H356.832L353.952 38.48L356.912 42H355.142Z"
                    fill="#4C5366"
                />
                <path
                    d="M362.983 39.11C362.983 39.31 362.969 39.49 362.943 39.65H358.893C358.926 40.05 359.066 40.3634 359.313 40.59C359.559 40.8167 359.863 40.93 360.223 40.93C360.743 40.93 361.113 40.7067 361.333 40.26H362.843C362.683 40.7934 362.376 41.2334 361.923 41.58C361.469 41.92 360.913 42.09 360.253 42.09C359.719 42.09 359.239 41.9734 358.813 41.74C358.393 41.5 358.063 41.1634 357.823 40.73C357.589 40.2967 357.473 39.7967 357.473 39.23C357.473 38.6567 357.589 38.1534 357.823 37.72C358.056 37.2867 358.383 36.9534 358.803 36.72C359.223 36.4867 359.706 36.37 360.253 36.37C360.779 36.37 361.249 36.4834 361.663 36.71C362.083 36.9367 362.406 37.26 362.633 37.68C362.866 38.0934 362.983 38.57 362.983 39.11ZM361.533 38.71C361.526 38.35 361.396 38.0634 361.143 37.85C360.889 37.63 360.579 37.52 360.213 37.52C359.866 37.52 359.573 37.6267 359.333 37.84C359.099 38.0467 358.956 38.3367 358.903 38.71H361.533Z"
                    fill="#4C5366"
                />
                <path
                    d="M369.304 36.46L365.874 44.62H364.384L365.584 41.86L363.364 36.46H364.934L366.364 40.33L367.814 36.46H369.304Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.3 83.02V90H337.9V85.46L336.03 90H334.97L333.09 85.46V90H331.69V83.02H333.28L335.5 88.21L337.72 83.02H339.3Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.144 90.09C342.611 90.09 342.131 89.9734 341.704 89.74C341.277 89.5 340.941 89.1634 340.694 88.73C340.454 88.2967 340.334 87.7967 340.334 87.23C340.334 86.6634 340.457 86.1634 340.704 85.73C340.957 85.2967 341.301 84.9634 341.734 84.73C342.167 84.49 342.651 84.37 343.184 84.37C343.717 84.37 344.201 84.49 344.634 84.73C345.067 84.9634 345.407 85.2967 345.654 85.73C345.907 86.1634 346.034 86.6634 346.034 87.23C346.034 87.7967 345.904 88.2967 345.644 88.73C345.391 89.1634 345.044 89.5 344.604 89.74C344.171 89.9734 343.684 90.09 343.144 90.09ZM343.144 88.87C343.397 88.87 343.634 88.81 343.854 88.69C344.081 88.5634 344.261 88.3767 344.394 88.13C344.527 87.8834 344.594 87.5834 344.594 87.23C344.594 86.7034 344.454 86.3 344.174 86.02C343.901 85.7334 343.564 85.59 343.164 85.59C342.764 85.59 342.427 85.7334 342.154 86.02C341.887 86.3 341.754 86.7034 341.754 87.23C341.754 87.7567 341.884 88.1634 342.144 88.45C342.411 88.73 342.744 88.87 343.144 88.87Z"
                    fill="#4C5366"
                />
                <path
                    d="M352.291 84.46V90H350.881V89.3C350.701 89.54 350.464 89.73 350.171 89.87C349.884 90.0034 349.571 90.07 349.231 90.07C348.798 90.07 348.414 89.98 348.081 89.8C347.748 89.6134 347.484 89.3434 347.291 88.99C347.104 88.63 347.011 88.2034 347.011 87.71V84.46H348.411V87.51C348.411 87.95 348.521 88.29 348.741 88.53C348.961 88.7634 349.261 88.88 349.641 88.88C350.028 88.88 350.331 88.7634 350.551 88.53C350.771 88.29 350.881 87.95 350.881 87.51V84.46H352.291Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.792 90.09C355.339 90.09 354.932 90.01 354.572 89.85C354.212 89.6834 353.926 89.46 353.712 89.18C353.506 88.9 353.392 88.59 353.372 88.25H354.782C354.809 88.4634 354.912 88.64 355.092 88.78C355.279 88.92 355.509 88.99 355.782 88.99C356.049 88.99 356.256 88.9367 356.402 88.83C356.556 88.7234 356.632 88.5867 356.632 88.42C356.632 88.24 356.539 88.1067 356.352 88.02C356.172 87.9267 355.882 87.8267 355.482 87.72C355.069 87.62 354.729 87.5167 354.462 87.41C354.202 87.3034 353.976 87.14 353.782 86.92C353.596 86.7 353.502 86.4034 353.502 86.03C353.502 85.7234 353.589 85.4434 353.762 85.19C353.942 84.9367 354.196 84.7367 354.522 84.59C354.856 84.4434 355.246 84.37 355.692 84.37C356.352 84.37 356.879 84.5367 357.272 84.87C357.666 85.1967 357.882 85.64 357.922 86.2H356.582C356.562 85.98 356.469 85.8067 356.302 85.68C356.142 85.5467 355.926 85.48 355.652 85.48C355.399 85.48 355.202 85.5267 355.062 85.62C354.929 85.7134 354.862 85.8434 354.862 86.01C354.862 86.1967 354.956 86.34 355.142 86.44C355.329 86.5334 355.619 86.63 356.012 86.73C356.412 86.83 356.742 86.9334 357.002 87.04C357.262 87.1467 357.486 87.3134 357.672 87.54C357.866 87.76 357.966 88.0534 357.972 88.42C357.972 88.74 357.882 89.0267 357.702 89.28C357.529 89.5334 357.276 89.7334 356.942 89.88C356.616 90.02 356.232 90.09 355.792 90.09Z"
                    fill="#4C5366"
                />
                <path
                    d="M364.272 87.11C364.272 87.31 364.258 87.49 364.232 87.65H360.182C360.215 88.05 360.355 88.3634 360.602 88.59C360.848 88.8167 361.152 88.93 361.512 88.93C362.032 88.93 362.402 88.7067 362.622 88.26H364.132C363.972 88.7934 363.665 89.2334 363.212 89.58C362.758 89.92 362.202 90.09 361.542 90.09C361.008 90.09 360.528 89.9734 360.102 89.74C359.682 89.5 359.352 89.1634 359.112 88.73C358.878 88.2967 358.762 87.7967 358.762 87.23C358.762 86.6567 358.878 86.1534 359.112 85.72C359.345 85.2867 359.672 84.9534 360.092 84.72C360.512 84.4867 360.995 84.37 361.542 84.37C362.068 84.37 362.538 84.4834 362.952 84.71C363.372 84.9367 363.695 85.26 363.922 85.68C364.155 86.0934 364.272 86.57 364.272 87.11ZM362.822 86.71C362.815 86.35 362.685 86.0634 362.432 85.85C362.178 85.63 361.868 85.52 361.502 85.52C361.155 85.52 360.862 85.6267 360.622 85.84C360.388 86.0467 360.245 86.3367 360.192 86.71H362.822Z"
                    fill="#4C5366"
                />
                <path
                    d="M366.693 85.32C366.873 85.0267 367.107 84.7967 367.393 84.63C367.687 84.4634 368.02 84.38 368.393 84.38V85.85H368.023C367.583 85.85 367.25 85.9534 367.023 86.16C366.803 86.3667 366.693 86.7267 366.693 87.24V90H365.293V84.46H366.693V85.32Z"
                    fill="#4C5366"
                />
                <path d="M336 131.02V132.15H333.09V133.94H335.32V135.05H333.09V138H331.69V131.02H336Z" fill="#4C5366" />
                <path
                    d="M336.633 135.21C336.633 134.65 336.743 134.153 336.963 133.72C337.189 133.287 337.493 132.953 337.873 132.72C338.259 132.487 338.689 132.37 339.163 132.37C339.576 132.37 339.936 132.453 340.243 132.62C340.556 132.787 340.806 132.997 340.993 133.25V132.46H342.403V138H340.993V137.19C340.813 137.45 340.563 137.667 340.243 137.84C339.929 138.007 339.566 138.09 339.153 138.09C338.686 138.09 338.259 137.97 337.873 137.73C337.493 137.49 337.189 137.153 336.963 136.72C336.743 136.28 336.633 135.777 336.633 135.21ZM340.993 135.23C340.993 134.89 340.926 134.6 340.793 134.36C340.659 134.113 340.479 133.927 340.253 133.8C340.026 133.667 339.783 133.6 339.523 133.6C339.263 133.6 339.023 133.663 338.803 133.79C338.583 133.917 338.403 134.103 338.263 134.35C338.129 134.59 338.063 134.877 338.063 135.21C338.063 135.543 338.129 135.837 338.263 136.09C338.403 136.337 338.583 136.527 338.803 136.66C339.029 136.793 339.269 136.86 339.523 136.86C339.783 136.86 340.026 136.797 340.253 136.67C340.479 136.537 340.659 136.35 340.793 136.11C340.926 135.863 340.993 135.57 340.993 135.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M345.17 133.32C345.35 133.027 345.583 132.797 345.87 132.63C346.163 132.463 346.497 132.38 346.87 132.38V133.85H346.5C346.06 133.85 345.727 133.953 345.5 134.16C345.28 134.367 345.17 134.727 345.17 135.24V138H343.77V132.46H345.17V133.32Z"
                    fill="#4C5366"
                />
                <path
                    d="M350.883 132.38C351.543 132.38 352.076 132.59 352.483 133.01C352.89 133.423 353.093 134.003 353.093 134.75V138H351.693V134.94C351.693 134.5 351.583 134.163 351.363 133.93C351.143 133.69 350.843 133.57 350.463 133.57C350.076 133.57 349.77 133.69 349.543 133.93C349.323 134.163 349.213 134.5 349.213 134.94V138H347.813V132.46H349.213V133.15C349.4 132.91 349.636 132.723 349.923 132.59C350.216 132.45 350.536 132.38 350.883 132.38Z"
                    fill="#4C5366"
                />
                <path
                    d="M359.574 135.11C359.574 135.31 359.561 135.49 359.534 135.65H355.484C355.518 136.05 355.658 136.363 355.904 136.59C356.151 136.817 356.454 136.93 356.814 136.93C357.334 136.93 357.704 136.707 357.924 136.26H359.434C359.274 136.793 358.968 137.233 358.514 137.58C358.061 137.92 357.504 138.09 356.844 138.09C356.311 138.09 355.831 137.973 355.404 137.74C354.984 137.5 354.654 137.163 354.414 136.73C354.181 136.297 354.064 135.797 354.064 135.23C354.064 134.657 354.181 134.153 354.414 133.72C354.648 133.287 354.974 132.953 355.394 132.72C355.814 132.487 356.298 132.37 356.844 132.37C357.371 132.37 357.841 132.483 358.254 132.71C358.674 132.937 358.998 133.26 359.224 133.68C359.458 134.093 359.574 134.57 359.574 135.11ZM358.124 134.71C358.118 134.35 357.988 134.063 357.734 133.85C357.481 133.63 357.171 133.52 356.804 133.52C356.458 133.52 356.164 133.627 355.924 133.84C355.691 134.047 355.548 134.337 355.494 134.71H358.124Z"
                    fill="#4C5366"
                />
                <path d="M361.996 130.6V138H360.596V130.6H361.996Z" fill="#4C5366" />
                <path d="M364.779 130.6V138H363.379V130.6H364.779Z" fill="#4C5366" />
                <path
                    d="M107.96 83.02L105.4 90H103.7L101.14 83.02H102.64L104.56 88.57L106.47 83.02H107.96Z"
                    fill="white"
                />
                <path
                    d="M109.51 83.8C109.263 83.8 109.056 83.7234 108.89 83.57C108.73 83.41 108.65 83.2134 108.65 82.98C108.65 82.7467 108.73 82.5534 108.89 82.4C109.056 82.24 109.263 82.16 109.51 82.16C109.756 82.16 109.96 82.24 110.12 82.4C110.286 82.5534 110.37 82.7467 110.37 82.98C110.37 83.2134 110.286 83.41 110.12 83.57C109.96 83.7234 109.756 83.8 109.51 83.8ZM110.2 84.46V90H108.8V84.46H110.2Z"
                    fill="white"
                />
                <path
                    d="M113.703 90.09C113.249 90.09 112.843 90.01 112.483 89.85C112.123 89.6834 111.836 89.46 111.623 89.18C111.416 88.9 111.303 88.59 111.283 88.25H112.693C112.719 88.4634 112.823 88.64 113.003 88.78C113.189 88.92 113.419 88.99 113.693 88.99C113.959 88.99 114.166 88.9367 114.313 88.83C114.466 88.7234 114.543 88.5867 114.543 88.42C114.543 88.24 114.449 88.1067 114.263 88.02C114.083 87.9267 113.793 87.8267 113.393 87.72C112.979 87.62 112.639 87.5167 112.373 87.41C112.113 87.3034 111.886 87.14 111.693 86.92C111.506 86.7 111.413 86.4034 111.413 86.03C111.413 85.7234 111.499 85.4434 111.673 85.19C111.853 84.9367 112.106 84.7367 112.433 84.59C112.766 84.4434 113.156 84.37 113.603 84.37C114.263 84.37 114.789 84.5367 115.183 84.87C115.576 85.1967 115.793 85.64 115.833 86.2H114.493C114.473 85.98 114.379 85.8067 114.213 85.68C114.053 85.5467 113.836 85.48 113.563 85.48C113.309 85.48 113.113 85.5267 112.973 85.62C112.839 85.7134 112.773 85.8434 112.773 86.01C112.773 86.1967 112.866 86.34 113.053 86.44C113.239 86.5334 113.529 86.63 113.923 86.73C114.323 86.83 114.653 86.9334 114.913 87.04C115.173 87.1467 115.396 87.3134 115.583 87.54C115.776 87.76 115.876 88.0534 115.883 88.42C115.883 88.74 115.793 89.0267 115.613 89.28C115.439 89.5334 115.186 89.7334 114.853 89.88C114.526 90.02 114.143 90.09 113.703 90.09Z"
                    fill="white"
                />
                <path
                    d="M120.152 84.38C120.572 84.38 120.945 84.4734 121.272 84.66C121.599 84.84 121.852 85.11 122.032 85.47C122.219 85.8234 122.312 86.25 122.312 86.75V90H120.912V86.94C120.912 86.5 120.802 86.1634 120.582 85.93C120.362 85.69 120.062 85.57 119.682 85.57C119.295 85.57 118.989 85.69 118.762 85.93C118.542 86.1634 118.432 86.5 118.432 86.94V90H117.032V82.6H118.432V85.15C118.612 84.91 118.852 84.7234 119.152 84.59C119.452 84.45 119.785 84.38 120.152 84.38Z"
                    fill="white"
                />
                <path
                    d="M123.283 87.21C123.283 86.65 123.393 86.1534 123.613 85.72C123.84 85.2867 124.143 84.9534 124.523 84.72C124.91 84.4867 125.34 84.37 125.813 84.37C126.227 84.37 126.587 84.4534 126.893 84.62C127.207 84.7867 127.457 84.9967 127.643 85.25V84.46H129.053V90H127.643V89.19C127.463 89.45 127.213 89.6667 126.893 89.84C126.58 90.0067 126.217 90.09 125.803 90.09C125.337 90.09 124.91 89.97 124.523 89.73C124.143 89.49 123.84 89.1534 123.613 88.72C123.393 88.28 123.283 87.7767 123.283 87.21ZM127.643 87.23C127.643 86.89 127.577 86.6 127.443 86.36C127.31 86.1134 127.13 85.9267 126.903 85.8C126.677 85.6667 126.433 85.6 126.173 85.6C125.913 85.6 125.673 85.6634 125.453 85.79C125.233 85.9167 125.053 86.1034 124.913 86.35C124.78 86.59 124.713 86.8767 124.713 87.21C124.713 87.5434 124.78 87.8367 124.913 88.09C125.053 88.3367 125.233 88.5267 125.453 88.66C125.68 88.7934 125.92 88.86 126.173 88.86C126.433 88.86 126.677 88.7967 126.903 88.67C127.13 88.5367 127.31 88.35 127.443 88.11C127.577 87.8634 127.643 87.57 127.643 87.23Z"
                    fill="white"
                />
                <path
                    d="M135.721 84.46L132.291 92.62H130.801L132.001 89.86L129.781 84.46H131.351L132.781 88.33L134.231 84.46H135.721Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M304.172 82.4644L307.354 85.6464C307.549 85.8417 307.549 86.1583 307.354 86.3535L304.172 89.5355C303.976 89.7308 303.66 89.7308 303.464 89.5355C303.269 89.3402 303.269 89.0237 303.464 88.8284L305.793 86.5H144V85.5H305.793L303.464 83.1715C303.269 82.9763 303.269 82.6597 303.464 82.4644C303.66 82.2692 303.976 82.2692 304.172 82.4644Z"
                    fill="#9789F4"
                />
                <rect x="313" y="32" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="313" y="80" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="316" y="83" width="6" height="6" rx="3" fill="#9789F4" />
                <rect x="313" y="128" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="316" y="131" width="6" height="6" rx="3" fill="#9789F4" />
                <rect x="316" y="35" width="6" height="6" rx="3" fill="#9789F4" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M200.51 78.0298C188.149 82.5033 170.882 85.5 144 85.5V86.5C170.95 86.5 188.343 83.4967 200.85 78.9702C213.367 74.44 220.958 68.3944 228.298 62.3869C228.527 62.1992 228.756 62.0115 228.985 61.8239C236.077 56.0143 243.059 50.2943 254.223 45.9662C265.567 41.568 281.261 38.5967 305.791 38.5023L303.464 40.8284C303.269 41.0237 303.269 41.3403 303.464 41.5355C303.66 41.7308 303.976 41.7308 304.172 41.5355L307.354 38.3536C307.549 38.1583 307.549 37.8417 307.354 37.6464L304.172 34.4645C303.976 34.2692 303.66 34.2692 303.464 34.4645C303.269 34.6597 303.269 34.9763 303.464 35.1716L305.795 37.5023C281.187 37.5963 265.358 40.5762 253.861 45.0338C242.547 49.4203 235.459 55.2277 228.37 61.0348C228.135 61.2276 227.9 61.4203 227.664 61.6131C220.343 67.6056 212.86 73.56 200.51 78.0298Z"
                    fill="#9789F4"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M200.51 93.9702C188.149 89.4967 170.882 86.5 144 86.5V85.5C170.95 85.5 188.343 88.5033 200.85 93.0298C213.367 97.56 220.958 103.606 228.298 109.613C228.527 109.801 228.756 109.988 228.985 110.176C236.077 115.986 243.059 121.706 254.223 126.034C265.567 130.432 281.261 133.403 305.791 133.498L303.464 131.172C303.269 130.976 303.269 130.66 303.464 130.464C303.66 130.269 303.976 130.269 304.172 130.464L307.354 133.646C307.549 133.842 307.549 134.158 307.354 134.354L304.172 137.536C303.976 137.731 303.66 137.731 303.464 137.536C303.269 137.34 303.269 137.024 303.464 136.828L305.795 134.498C281.187 134.404 265.358 131.424 253.861 126.966C242.547 122.58 235.459 116.772 228.37 110.965C228.135 110.772 227.9 110.58 227.664 110.387C220.343 104.394 212.86 98.44 200.51 93.9702Z"
                    fill="#9789F4"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_10649_36674"
                    x="-51"
                    y="143"
                    width="140"
                    height="66"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_10649_36674" />
                </filter>
                <filter
                    id="filter1_f_10649_36674"
                    x="411"
                    y="-85"
                    width="180"
                    height="129"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_10649_36674" />
                </filter>
                <clipPath id="clip0_10649_36674">
                    <rect width="508" height="172" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

function RestrictSupplierGraphic(): JSX.Element {
    return (
        <svg width="508" height="172" viewBox="0 0 508 172" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10648_36641)">
                <rect width="508" height="172" rx="16" fill="#F5F6FA" />
                <g opacity="0.9" filter="url(#filter0_f_10648_36641)">
                    <rect width="80" height="6" rx="2" transform="matrix(1 0 0 -1 -21 179)" fill="#9789F4" />
                </g>
                <g opacity="0.9" filter="url(#filter1_f_10648_36641)">
                    <rect width="80" height="29" rx="2" transform="matrix(1 0 0 -1 461 -6)" fill="#9789F4" />
                </g>
                <path
                    d="M307 38C307 31.3726 312.373 26 319 26H375C381.627 26 387 31.3726 387 38C387 44.6274 381.627 50 375 50H319C312.373 50 307 44.6274 307 38Z"
                    fill="white"
                />
                <path
                    d="M307 86C307 79.3726 312.373 74 319 74H375C381.627 74 387 79.3726 387 86C387 92.6274 381.627 98 375 98H319C312.373 98 307 92.6274 307 86Z"
                    fill="white"
                />
                <path
                    d="M307 134C307 127.373 312.373 122 319 122H375C381.627 122 387 127.373 387 134C387 140.627 381.627 146 375 146H319C312.373 146 307 140.627 307 134Z"
                    fill="white"
                />
                <path
                    d="M93 86C93 79.3726 98.3726 74 105 74H132C138.627 74 144 79.3726 144 86C144 92.6274 138.627 98 132 98H105C98.3726 98 93 92.6274 93 86Z"
                    fill="#9789F4"
                />
                <path
                    d="M334.13 35.02C334.863 35.02 335.507 35.1634 336.06 35.45C336.62 35.7367 337.05 36.1467 337.35 36.68C337.657 37.2067 337.81 37.82 337.81 38.52C337.81 39.22 337.657 39.8334 337.35 40.36C337.05 40.88 336.62 41.2834 336.06 41.57C335.507 41.8567 334.863 42 334.13 42H331.69V35.02H334.13ZM334.08 40.81C334.813 40.81 335.38 40.61 335.78 40.21C336.18 39.81 336.38 39.2467 336.38 38.52C336.38 37.7934 336.18 37.2267 335.78 36.82C335.38 36.4067 334.813 36.2 334.08 36.2H333.09V40.81H334.08Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.568 35.8C339.321 35.8 339.115 35.7234 338.948 35.57C338.788 35.41 338.708 35.2134 338.708 34.98C338.708 34.7467 338.788 34.5534 338.948 34.4C339.115 34.24 339.321 34.16 339.568 34.16C339.815 34.16 340.018 34.24 340.178 34.4C340.345 34.5534 340.428 34.7467 340.428 34.98C340.428 35.2134 340.345 35.41 340.178 35.57C340.018 35.7234 339.815 35.8 339.568 35.8ZM340.258 36.46V42H338.858V36.46H340.258Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.811 36.37C344.224 36.37 344.588 36.4534 344.901 36.62C345.214 36.78 345.461 36.99 345.641 37.25V36.46H347.051V42.04C347.051 42.5534 346.948 43.01 346.741 43.41C346.534 43.8167 346.224 44.1367 345.811 44.37C345.398 44.61 344.898 44.73 344.311 44.73C343.524 44.73 342.878 44.5467 342.371 44.18C341.871 43.8134 341.588 43.3134 341.521 42.68H342.911C342.984 42.9334 343.141 43.1334 343.381 43.28C343.628 43.4334 343.924 43.51 344.271 43.51C344.678 43.51 345.008 43.3867 345.261 43.14C345.514 42.9 345.641 42.5334 345.641 42.04V41.18C345.461 41.44 345.211 41.6567 344.891 41.83C344.578 42.0034 344.218 42.09 343.811 42.09C343.344 42.09 342.918 41.97 342.531 41.73C342.144 41.49 341.838 41.1534 341.611 40.72C341.391 40.28 341.281 39.7767 341.281 39.21C341.281 38.65 341.391 38.1534 341.611 37.72C341.838 37.2867 342.141 36.9534 342.521 36.72C342.908 36.4867 343.338 36.37 343.811 36.37ZM345.641 39.23C345.641 38.89 345.574 38.6 345.441 38.36C345.308 38.1134 345.128 37.9267 344.901 37.8C344.674 37.6667 344.431 37.6 344.171 37.6C343.911 37.6 343.671 37.6634 343.451 37.79C343.231 37.9167 343.051 38.1034 342.911 38.35C342.778 38.59 342.711 38.8767 342.711 39.21C342.711 39.5434 342.778 39.8367 342.911 40.09C343.051 40.3367 343.231 40.5267 343.451 40.66C343.678 40.7934 343.918 40.86 344.171 40.86C344.431 40.86 344.674 40.7967 344.901 40.67C345.128 40.5367 345.308 40.35 345.441 40.11C345.574 39.8634 345.641 39.57 345.641 39.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M349.128 35.8C348.882 35.8 348.675 35.7234 348.508 35.57C348.348 35.41 348.268 35.2134 348.268 34.98C348.268 34.7467 348.348 34.5534 348.508 34.4C348.675 34.24 348.882 34.16 349.128 34.16C349.375 34.16 349.578 34.24 349.738 34.4C349.905 34.5534 349.988 34.7467 349.988 34.98C349.988 35.2134 349.905 35.41 349.738 35.57C349.578 35.7234 349.375 35.8 349.128 35.8ZM349.818 36.46V42H348.418V36.46H349.818Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.142 42L352.602 38.89V42H351.202V35.02H352.602V38.15L355.142 35.02H356.832L353.952 38.48L356.912 42H355.142Z"
                    fill="#4C5366"
                />
                <path
                    d="M362.983 39.11C362.983 39.31 362.969 39.49 362.943 39.65H358.893C358.926 40.05 359.066 40.3634 359.313 40.59C359.559 40.8167 359.863 40.93 360.223 40.93C360.743 40.93 361.113 40.7067 361.333 40.26H362.843C362.683 40.7934 362.376 41.2334 361.923 41.58C361.469 41.92 360.913 42.09 360.253 42.09C359.719 42.09 359.239 41.9734 358.813 41.74C358.393 41.5 358.063 41.1634 357.823 40.73C357.589 40.2967 357.473 39.7967 357.473 39.23C357.473 38.6567 357.589 38.1534 357.823 37.72C358.056 37.2867 358.383 36.9534 358.803 36.72C359.223 36.4867 359.706 36.37 360.253 36.37C360.779 36.37 361.249 36.4834 361.663 36.71C362.083 36.9367 362.406 37.26 362.633 37.68C362.866 38.0934 362.983 38.57 362.983 39.11ZM361.533 38.71C361.526 38.35 361.396 38.0634 361.143 37.85C360.889 37.63 360.579 37.52 360.213 37.52C359.866 37.52 359.573 37.6267 359.333 37.84C359.099 38.0467 358.956 38.3367 358.903 38.71H361.533Z"
                    fill="#4C5366"
                />
                <path
                    d="M369.304 36.46L365.874 44.62H364.384L365.584 41.86L363.364 36.46H364.934L366.364 40.33L367.814 36.46H369.304Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.3 83.02V90H337.9V85.46L336.03 90H334.97L333.09 85.46V90H331.69V83.02H333.28L335.5 88.21L337.72 83.02H339.3Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.144 90.09C342.611 90.09 342.131 89.9734 341.704 89.74C341.277 89.5 340.941 89.1634 340.694 88.73C340.454 88.2967 340.334 87.7967 340.334 87.23C340.334 86.6634 340.457 86.1634 340.704 85.73C340.957 85.2967 341.301 84.9634 341.734 84.73C342.167 84.49 342.651 84.37 343.184 84.37C343.717 84.37 344.201 84.49 344.634 84.73C345.067 84.9634 345.407 85.2967 345.654 85.73C345.907 86.1634 346.034 86.6634 346.034 87.23C346.034 87.7967 345.904 88.2967 345.644 88.73C345.391 89.1634 345.044 89.5 344.604 89.74C344.171 89.9734 343.684 90.09 343.144 90.09ZM343.144 88.87C343.397 88.87 343.634 88.81 343.854 88.69C344.081 88.5634 344.261 88.3767 344.394 88.13C344.527 87.8834 344.594 87.5834 344.594 87.23C344.594 86.7034 344.454 86.3 344.174 86.02C343.901 85.7334 343.564 85.59 343.164 85.59C342.764 85.59 342.427 85.7334 342.154 86.02C341.887 86.3 341.754 86.7034 341.754 87.23C341.754 87.7567 341.884 88.1634 342.144 88.45C342.411 88.73 342.744 88.87 343.144 88.87Z"
                    fill="#4C5366"
                />
                <path
                    d="M352.291 84.46V90H350.881V89.3C350.701 89.54 350.464 89.73 350.171 89.87C349.884 90.0034 349.571 90.07 349.231 90.07C348.798 90.07 348.414 89.98 348.081 89.8C347.748 89.6134 347.484 89.3434 347.291 88.99C347.104 88.63 347.011 88.2034 347.011 87.71V84.46H348.411V87.51C348.411 87.95 348.521 88.29 348.741 88.53C348.961 88.7634 349.261 88.88 349.641 88.88C350.028 88.88 350.331 88.7634 350.551 88.53C350.771 88.29 350.881 87.95 350.881 87.51V84.46H352.291Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.792 90.09C355.339 90.09 354.932 90.01 354.572 89.85C354.212 89.6834 353.926 89.46 353.712 89.18C353.506 88.9 353.392 88.59 353.372 88.25H354.782C354.809 88.4634 354.912 88.64 355.092 88.78C355.279 88.92 355.509 88.99 355.782 88.99C356.049 88.99 356.256 88.9367 356.402 88.83C356.556 88.7234 356.632 88.5867 356.632 88.42C356.632 88.24 356.539 88.1067 356.352 88.02C356.172 87.9267 355.882 87.8267 355.482 87.72C355.069 87.62 354.729 87.5167 354.462 87.41C354.202 87.3034 353.976 87.14 353.782 86.92C353.596 86.7 353.502 86.4034 353.502 86.03C353.502 85.7234 353.589 85.4434 353.762 85.19C353.942 84.9367 354.196 84.7367 354.522 84.59C354.856 84.4434 355.246 84.37 355.692 84.37C356.352 84.37 356.879 84.5367 357.272 84.87C357.666 85.1967 357.882 85.64 357.922 86.2H356.582C356.562 85.98 356.469 85.8067 356.302 85.68C356.142 85.5467 355.926 85.48 355.652 85.48C355.399 85.48 355.202 85.5267 355.062 85.62C354.929 85.7134 354.862 85.8434 354.862 86.01C354.862 86.1967 354.956 86.34 355.142 86.44C355.329 86.5334 355.619 86.63 356.012 86.73C356.412 86.83 356.742 86.9334 357.002 87.04C357.262 87.1467 357.486 87.3134 357.672 87.54C357.866 87.76 357.966 88.0534 357.972 88.42C357.972 88.74 357.882 89.0267 357.702 89.28C357.529 89.5334 357.276 89.7334 356.942 89.88C356.616 90.02 356.232 90.09 355.792 90.09Z"
                    fill="#4C5366"
                />
                <path
                    d="M364.272 87.11C364.272 87.31 364.258 87.49 364.232 87.65H360.182C360.215 88.05 360.355 88.3634 360.602 88.59C360.848 88.8167 361.152 88.93 361.512 88.93C362.032 88.93 362.402 88.7067 362.622 88.26H364.132C363.972 88.7934 363.665 89.2334 363.212 89.58C362.758 89.92 362.202 90.09 361.542 90.09C361.008 90.09 360.528 89.9734 360.102 89.74C359.682 89.5 359.352 89.1634 359.112 88.73C358.878 88.2967 358.762 87.7967 358.762 87.23C358.762 86.6567 358.878 86.1534 359.112 85.72C359.345 85.2867 359.672 84.9534 360.092 84.72C360.512 84.4867 360.995 84.37 361.542 84.37C362.068 84.37 362.538 84.4834 362.952 84.71C363.372 84.9367 363.695 85.26 363.922 85.68C364.155 86.0934 364.272 86.57 364.272 87.11ZM362.822 86.71C362.815 86.35 362.685 86.0634 362.432 85.85C362.178 85.63 361.868 85.52 361.502 85.52C361.155 85.52 360.862 85.6267 360.622 85.84C360.388 86.0467 360.245 86.3367 360.192 86.71H362.822Z"
                    fill="#4C5366"
                />
                <path
                    d="M366.693 85.32C366.873 85.0267 367.107 84.7967 367.393 84.63C367.687 84.4634 368.02 84.38 368.393 84.38V85.85H368.023C367.583 85.85 367.25 85.9534 367.023 86.16C366.803 86.3667 366.693 86.7267 366.693 87.24V90H365.293V84.46H366.693V85.32Z"
                    fill="#4C5366"
                />
                <path d="M336 131.02V132.15H333.09V133.94H335.32V135.05H333.09V138H331.69V131.02H336Z" fill="#4C5366" />
                <path
                    d="M336.633 135.21C336.633 134.65 336.743 134.153 336.963 133.72C337.189 133.287 337.493 132.953 337.873 132.72C338.259 132.487 338.689 132.37 339.163 132.37C339.576 132.37 339.936 132.453 340.243 132.62C340.556 132.787 340.806 132.997 340.993 133.25V132.46H342.403V138H340.993V137.19C340.813 137.45 340.563 137.667 340.243 137.84C339.929 138.007 339.566 138.09 339.153 138.09C338.686 138.09 338.259 137.97 337.873 137.73C337.493 137.49 337.189 137.153 336.963 136.72C336.743 136.28 336.633 135.777 336.633 135.21ZM340.993 135.23C340.993 134.89 340.926 134.6 340.793 134.36C340.659 134.113 340.479 133.927 340.253 133.8C340.026 133.667 339.783 133.6 339.523 133.6C339.263 133.6 339.023 133.663 338.803 133.79C338.583 133.917 338.403 134.103 338.263 134.35C338.129 134.59 338.063 134.877 338.063 135.21C338.063 135.543 338.129 135.837 338.263 136.09C338.403 136.337 338.583 136.527 338.803 136.66C339.029 136.793 339.269 136.86 339.523 136.86C339.783 136.86 340.026 136.797 340.253 136.67C340.479 136.537 340.659 136.35 340.793 136.11C340.926 135.863 340.993 135.57 340.993 135.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M345.17 133.32C345.35 133.027 345.583 132.797 345.87 132.63C346.163 132.463 346.497 132.38 346.87 132.38V133.85H346.5C346.06 133.85 345.727 133.953 345.5 134.16C345.28 134.367 345.17 134.727 345.17 135.24V138H343.77V132.46H345.17V133.32Z"
                    fill="#4C5366"
                />
                <path
                    d="M350.883 132.38C351.543 132.38 352.076 132.59 352.483 133.01C352.89 133.423 353.093 134.003 353.093 134.75V138H351.693V134.94C351.693 134.5 351.583 134.163 351.363 133.93C351.143 133.69 350.843 133.57 350.463 133.57C350.076 133.57 349.77 133.69 349.543 133.93C349.323 134.163 349.213 134.5 349.213 134.94V138H347.813V132.46H349.213V133.15C349.4 132.91 349.636 132.723 349.923 132.59C350.216 132.45 350.536 132.38 350.883 132.38Z"
                    fill="#4C5366"
                />
                <path
                    d="M359.574 135.11C359.574 135.31 359.561 135.49 359.534 135.65H355.484C355.518 136.05 355.658 136.363 355.904 136.59C356.151 136.817 356.454 136.93 356.814 136.93C357.334 136.93 357.704 136.707 357.924 136.26H359.434C359.274 136.793 358.968 137.233 358.514 137.58C358.061 137.92 357.504 138.09 356.844 138.09C356.311 138.09 355.831 137.973 355.404 137.74C354.984 137.5 354.654 137.163 354.414 136.73C354.181 136.297 354.064 135.797 354.064 135.23C354.064 134.657 354.181 134.153 354.414 133.72C354.648 133.287 354.974 132.953 355.394 132.72C355.814 132.487 356.298 132.37 356.844 132.37C357.371 132.37 357.841 132.483 358.254 132.71C358.674 132.937 358.998 133.26 359.224 133.68C359.458 134.093 359.574 134.57 359.574 135.11ZM358.124 134.71C358.118 134.35 357.988 134.063 357.734 133.85C357.481 133.63 357.171 133.52 356.804 133.52C356.458 133.52 356.164 133.627 355.924 133.84C355.691 134.047 355.548 134.337 355.494 134.71H358.124Z"
                    fill="#4C5366"
                />
                <path d="M361.996 130.6V138H360.596V130.6H361.996Z" fill="#4C5366" />
                <path d="M364.779 130.6V138H363.379V130.6H364.779Z" fill="#4C5366" />
                <path
                    d="M107.96 83.02L105.4 90H103.7L101.14 83.02H102.64L104.56 88.57L106.47 83.02H107.96Z"
                    fill="white"
                />
                <path
                    d="M109.51 83.8C109.263 83.8 109.056 83.7234 108.89 83.57C108.73 83.41 108.65 83.2134 108.65 82.98C108.65 82.7467 108.73 82.5534 108.89 82.4C109.056 82.24 109.263 82.16 109.51 82.16C109.756 82.16 109.96 82.24 110.12 82.4C110.286 82.5534 110.37 82.7467 110.37 82.98C110.37 83.2134 110.286 83.41 110.12 83.57C109.96 83.7234 109.756 83.8 109.51 83.8ZM110.2 84.46V90H108.8V84.46H110.2Z"
                    fill="white"
                />
                <path
                    d="M113.703 90.09C113.249 90.09 112.843 90.01 112.483 89.85C112.123 89.6834 111.836 89.46 111.623 89.18C111.416 88.9 111.303 88.59 111.283 88.25H112.693C112.719 88.4634 112.823 88.64 113.003 88.78C113.189 88.92 113.419 88.99 113.693 88.99C113.959 88.99 114.166 88.9367 114.313 88.83C114.466 88.7234 114.543 88.5867 114.543 88.42C114.543 88.24 114.449 88.1067 114.263 88.02C114.083 87.9267 113.793 87.8267 113.393 87.72C112.979 87.62 112.639 87.5167 112.373 87.41C112.113 87.3034 111.886 87.14 111.693 86.92C111.506 86.7 111.413 86.4034 111.413 86.03C111.413 85.7234 111.499 85.4434 111.673 85.19C111.853 84.9367 112.106 84.7367 112.433 84.59C112.766 84.4434 113.156 84.37 113.603 84.37C114.263 84.37 114.789 84.5367 115.183 84.87C115.576 85.1967 115.793 85.64 115.833 86.2H114.493C114.473 85.98 114.379 85.8067 114.213 85.68C114.053 85.5467 113.836 85.48 113.563 85.48C113.309 85.48 113.113 85.5267 112.973 85.62C112.839 85.7134 112.773 85.8434 112.773 86.01C112.773 86.1967 112.866 86.34 113.053 86.44C113.239 86.5334 113.529 86.63 113.923 86.73C114.323 86.83 114.653 86.9334 114.913 87.04C115.173 87.1467 115.396 87.3134 115.583 87.54C115.776 87.76 115.876 88.0534 115.883 88.42C115.883 88.74 115.793 89.0267 115.613 89.28C115.439 89.5334 115.186 89.7334 114.853 89.88C114.526 90.02 114.143 90.09 113.703 90.09Z"
                    fill="white"
                />
                <path
                    d="M120.152 84.38C120.572 84.38 120.945 84.4734 121.272 84.66C121.599 84.84 121.852 85.11 122.032 85.47C122.219 85.8234 122.312 86.25 122.312 86.75V90H120.912V86.94C120.912 86.5 120.802 86.1634 120.582 85.93C120.362 85.69 120.062 85.57 119.682 85.57C119.295 85.57 118.989 85.69 118.762 85.93C118.542 86.1634 118.432 86.5 118.432 86.94V90H117.032V82.6H118.432V85.15C118.612 84.91 118.852 84.7234 119.152 84.59C119.452 84.45 119.785 84.38 120.152 84.38Z"
                    fill="white"
                />
                <path
                    d="M123.283 87.21C123.283 86.65 123.393 86.1534 123.613 85.72C123.84 85.2867 124.143 84.9534 124.523 84.72C124.91 84.4867 125.34 84.37 125.813 84.37C126.227 84.37 126.587 84.4534 126.893 84.62C127.207 84.7867 127.457 84.9967 127.643 85.25V84.46H129.053V90H127.643V89.19C127.463 89.45 127.213 89.6667 126.893 89.84C126.58 90.0067 126.217 90.09 125.803 90.09C125.337 90.09 124.91 89.97 124.523 89.73C124.143 89.49 123.84 89.1534 123.613 88.72C123.393 88.28 123.283 87.7767 123.283 87.21ZM127.643 87.23C127.643 86.89 127.577 86.6 127.443 86.36C127.31 86.1134 127.13 85.9267 126.903 85.8C126.677 85.6667 126.433 85.6 126.173 85.6C125.913 85.6 125.673 85.6634 125.453 85.79C125.233 85.9167 125.053 86.1034 124.913 86.35C124.78 86.59 124.713 86.8767 124.713 87.21C124.713 87.5434 124.78 87.8367 124.913 88.09C125.053 88.3367 125.233 88.5267 125.453 88.66C125.68 88.7934 125.92 88.86 126.173 88.86C126.433 88.86 126.677 88.7967 126.903 88.67C127.13 88.5367 127.31 88.35 127.443 88.11C127.577 87.8634 127.643 87.57 127.643 87.23Z"
                    fill="white"
                />
                <path
                    d="M135.721 84.46L132.291 92.62H130.801L132.001 89.86L129.781 84.46H131.351L132.781 88.33L134.231 84.46H135.721Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M183.393 94.7154C174.794 89.8022 162.767 86.5 144 86.5V85.5C162.883 85.5 175.096 88.8228 183.889 93.8471C192.68 98.8699 198.003 105.568 203.131 112.194C203.288 112.398 203.446 112.601 203.603 112.805C208.572 119.231 213.442 125.53 221.223 130.292C229.24 135.2 240.395 138.5 258 138.5V139.5C240.269 139.5 228.918 136.175 220.701 131.145C212.761 126.285 207.791 119.856 202.841 113.455C202.674 113.238 202.507 113.022 202.34 112.806C197.214 106.182 191.994 99.6301 183.393 94.7154Z"
                    fill="#B6BBC9"
                />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M144 85.5V86.5H258V85.5H144Z" fill="#B6BBC9" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M257.5 88V84H258.5V88H257.5Z" fill="#B6BBC9" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M257.5 141V137H258.5V141H257.5Z" fill="#B6BBC9" />
                <rect x="313" y="32" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="313" y="80" width="12" height="12" rx="6" fill="#D8DEEE" fill-opacity="0.2" />
                <rect x="313" y="128" width="12" height="12" rx="6" fill="#D8DEEE" fill-opacity="0.2" />
                <rect x="316" y="131" width="6" height="6" rx="3" fill="#D8DEEE" />
                <rect x="316" y="35" width="6" height="6" rx="3" fill="#9789F4" />
                <rect x="316" y="83" width="6" height="6" rx="3" fill="#D8DEEE" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M200.51 78.0298C188.149 82.5033 170.882 85.5 144 85.5V86.5C170.95 86.5 188.343 83.4967 200.85 78.9702C213.367 74.44 220.958 68.3944 228.298 62.3869C228.527 62.1992 228.756 62.0115 228.985 61.8239C236.077 56.0143 243.059 50.2943 254.223 45.9662C265.567 41.568 281.261 38.5967 305.791 38.5023L303.464 40.8284C303.269 41.0237 303.269 41.3403 303.464 41.5355C303.66 41.7308 303.976 41.7308 304.172 41.5355L307.354 38.3536C307.549 38.1583 307.549 37.8417 307.354 37.6464L304.172 34.4645C303.976 34.2692 303.66 34.2692 303.464 34.4645C303.269 34.6597 303.269 34.9763 303.464 35.1716L305.795 37.5023C281.187 37.5963 265.358 40.5762 253.861 45.0338C242.547 49.4203 235.459 55.2277 228.37 61.0348C228.135 61.2276 227.9 61.4203 227.664 61.6131C220.343 67.6056 212.86 73.56 200.51 78.0298Z"
                    fill="#9789F4"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_10648_36641"
                    x="-51"
                    y="143"
                    width="140"
                    height="66"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_10648_36641" />
                </filter>
                <filter
                    id="filter1_f_10648_36641"
                    x="411"
                    y="-85"
                    width="180"
                    height="129"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_10648_36641" />
                </filter>
                <clipPath id="clip0_10648_36641">
                    <rect width="508" height="172" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

function RestrictPartCategoryGraphic(): JSX.Element {
    return (
        <svg width="508" height="172" viewBox="0 0 508 172" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10649_36702)">
                <rect width="508" height="172" rx="16" fill="#F5F6FA" />
                <g opacity="0.9" filter="url(#filter0_f_10649_36702)">
                    <rect width="80" height="6" rx="2" transform="matrix(1 0 0 -1 -21 179)" fill="#9789F4" />
                </g>
                <g opacity="0.9" filter="url(#filter1_f_10649_36702)">
                    <rect width="80" height="29" rx="2" transform="matrix(1 0 0 -1 461 -6)" fill="#9789F4" />
                </g>
                <path
                    d="M307 38C307 31.3726 312.373 26 319 26H375C381.627 26 387 31.3726 387 38C387 44.6274 381.627 50 375 50H319C312.373 50 307 44.6274 307 38Z"
                    fill="white"
                />
                <path
                    d="M307 86C307 79.3726 312.373 74 319 74H375C381.627 74 387 79.3726 387 86C387 92.6274 381.627 98 375 98H319C312.373 98 307 92.6274 307 86Z"
                    fill="white"
                />
                <path
                    d="M307 134C307 127.373 312.373 122 319 122H375C381.627 122 387 127.373 387 134C387 140.627 381.627 146 375 146H319C312.373 146 307 140.627 307 134Z"
                    fill="white"
                />
                <path
                    d="M93 86C93 79.3726 98.3726 74 105 74H132C138.627 74 144 79.3726 144 86C144 92.6274 138.627 98 132 98H105C98.3726 98 93 92.6274 93 86Z"
                    fill="#9789F4"
                />
                <path
                    d="M334.13 35.02C334.863 35.02 335.507 35.1634 336.06 35.45C336.62 35.7367 337.05 36.1467 337.35 36.68C337.657 37.2067 337.81 37.82 337.81 38.52C337.81 39.22 337.657 39.8334 337.35 40.36C337.05 40.88 336.62 41.2834 336.06 41.57C335.507 41.8567 334.863 42 334.13 42H331.69V35.02H334.13ZM334.08 40.81C334.813 40.81 335.38 40.61 335.78 40.21C336.18 39.81 336.38 39.2467 336.38 38.52C336.38 37.7934 336.18 37.2267 335.78 36.82C335.38 36.4067 334.813 36.2 334.08 36.2H333.09V40.81H334.08Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.568 35.8C339.321 35.8 339.115 35.7234 338.948 35.57C338.788 35.41 338.708 35.2134 338.708 34.98C338.708 34.7467 338.788 34.5534 338.948 34.4C339.115 34.24 339.321 34.16 339.568 34.16C339.815 34.16 340.018 34.24 340.178 34.4C340.345 34.5534 340.428 34.7467 340.428 34.98C340.428 35.2134 340.345 35.41 340.178 35.57C340.018 35.7234 339.815 35.8 339.568 35.8ZM340.258 36.46V42H338.858V36.46H340.258Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.811 36.37C344.224 36.37 344.588 36.4534 344.901 36.62C345.214 36.78 345.461 36.99 345.641 37.25V36.46H347.051V42.04C347.051 42.5534 346.948 43.01 346.741 43.41C346.534 43.8167 346.224 44.1367 345.811 44.37C345.398 44.61 344.898 44.73 344.311 44.73C343.524 44.73 342.878 44.5467 342.371 44.18C341.871 43.8134 341.588 43.3134 341.521 42.68H342.911C342.984 42.9334 343.141 43.1334 343.381 43.28C343.628 43.4334 343.924 43.51 344.271 43.51C344.678 43.51 345.008 43.3867 345.261 43.14C345.514 42.9 345.641 42.5334 345.641 42.04V41.18C345.461 41.44 345.211 41.6567 344.891 41.83C344.578 42.0034 344.218 42.09 343.811 42.09C343.344 42.09 342.918 41.97 342.531 41.73C342.144 41.49 341.838 41.1534 341.611 40.72C341.391 40.28 341.281 39.7767 341.281 39.21C341.281 38.65 341.391 38.1534 341.611 37.72C341.838 37.2867 342.141 36.9534 342.521 36.72C342.908 36.4867 343.338 36.37 343.811 36.37ZM345.641 39.23C345.641 38.89 345.574 38.6 345.441 38.36C345.308 38.1134 345.128 37.9267 344.901 37.8C344.674 37.6667 344.431 37.6 344.171 37.6C343.911 37.6 343.671 37.6634 343.451 37.79C343.231 37.9167 343.051 38.1034 342.911 38.35C342.778 38.59 342.711 38.8767 342.711 39.21C342.711 39.5434 342.778 39.8367 342.911 40.09C343.051 40.3367 343.231 40.5267 343.451 40.66C343.678 40.7934 343.918 40.86 344.171 40.86C344.431 40.86 344.674 40.7967 344.901 40.67C345.128 40.5367 345.308 40.35 345.441 40.11C345.574 39.8634 345.641 39.57 345.641 39.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M349.128 35.8C348.882 35.8 348.675 35.7234 348.508 35.57C348.348 35.41 348.268 35.2134 348.268 34.98C348.268 34.7467 348.348 34.5534 348.508 34.4C348.675 34.24 348.882 34.16 349.128 34.16C349.375 34.16 349.578 34.24 349.738 34.4C349.905 34.5534 349.988 34.7467 349.988 34.98C349.988 35.2134 349.905 35.41 349.738 35.57C349.578 35.7234 349.375 35.8 349.128 35.8ZM349.818 36.46V42H348.418V36.46H349.818Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.142 42L352.602 38.89V42H351.202V35.02H352.602V38.15L355.142 35.02H356.832L353.952 38.48L356.912 42H355.142Z"
                    fill="#4C5366"
                />
                <path
                    d="M362.983 39.11C362.983 39.31 362.969 39.49 362.943 39.65H358.893C358.926 40.05 359.066 40.3634 359.313 40.59C359.559 40.8167 359.863 40.93 360.223 40.93C360.743 40.93 361.113 40.7067 361.333 40.26H362.843C362.683 40.7934 362.376 41.2334 361.923 41.58C361.469 41.92 360.913 42.09 360.253 42.09C359.719 42.09 359.239 41.9734 358.813 41.74C358.393 41.5 358.063 41.1634 357.823 40.73C357.589 40.2967 357.473 39.7967 357.473 39.23C357.473 38.6567 357.589 38.1534 357.823 37.72C358.056 37.2867 358.383 36.9534 358.803 36.72C359.223 36.4867 359.706 36.37 360.253 36.37C360.779 36.37 361.249 36.4834 361.663 36.71C362.083 36.9367 362.406 37.26 362.633 37.68C362.866 38.0934 362.983 38.57 362.983 39.11ZM361.533 38.71C361.526 38.35 361.396 38.0634 361.143 37.85C360.889 37.63 360.579 37.52 360.213 37.52C359.866 37.52 359.573 37.6267 359.333 37.84C359.099 38.0467 358.956 38.3367 358.903 38.71H361.533Z"
                    fill="#4C5366"
                />
                <path
                    d="M369.304 36.46L365.874 44.62H364.384L365.584 41.86L363.364 36.46H364.934L366.364 40.33L367.814 36.46H369.304Z"
                    fill="#4C5366"
                />
                <path
                    d="M339.3 83.02V90H337.9V85.46L336.03 90H334.97L333.09 85.46V90H331.69V83.02H333.28L335.5 88.21L337.72 83.02H339.3Z"
                    fill="#4C5366"
                />
                <path
                    d="M343.144 90.09C342.611 90.09 342.131 89.9734 341.704 89.74C341.277 89.5 340.941 89.1634 340.694 88.73C340.454 88.2967 340.334 87.7967 340.334 87.23C340.334 86.6634 340.457 86.1634 340.704 85.73C340.957 85.2967 341.301 84.9634 341.734 84.73C342.167 84.49 342.651 84.37 343.184 84.37C343.717 84.37 344.201 84.49 344.634 84.73C345.067 84.9634 345.407 85.2967 345.654 85.73C345.907 86.1634 346.034 86.6634 346.034 87.23C346.034 87.7967 345.904 88.2967 345.644 88.73C345.391 89.1634 345.044 89.5 344.604 89.74C344.171 89.9734 343.684 90.09 343.144 90.09ZM343.144 88.87C343.397 88.87 343.634 88.81 343.854 88.69C344.081 88.5634 344.261 88.3767 344.394 88.13C344.527 87.8834 344.594 87.5834 344.594 87.23C344.594 86.7034 344.454 86.3 344.174 86.02C343.901 85.7334 343.564 85.59 343.164 85.59C342.764 85.59 342.427 85.7334 342.154 86.02C341.887 86.3 341.754 86.7034 341.754 87.23C341.754 87.7567 341.884 88.1634 342.144 88.45C342.411 88.73 342.744 88.87 343.144 88.87Z"
                    fill="#4C5366"
                />
                <path
                    d="M352.291 84.46V90H350.881V89.3C350.701 89.54 350.464 89.73 350.171 89.87C349.884 90.0034 349.571 90.07 349.231 90.07C348.798 90.07 348.414 89.98 348.081 89.8C347.748 89.6134 347.484 89.3434 347.291 88.99C347.104 88.63 347.011 88.2034 347.011 87.71V84.46H348.411V87.51C348.411 87.95 348.521 88.29 348.741 88.53C348.961 88.7634 349.261 88.88 349.641 88.88C350.028 88.88 350.331 88.7634 350.551 88.53C350.771 88.29 350.881 87.95 350.881 87.51V84.46H352.291Z"
                    fill="#4C5366"
                />
                <path
                    d="M355.792 90.09C355.339 90.09 354.932 90.01 354.572 89.85C354.212 89.6834 353.926 89.46 353.712 89.18C353.506 88.9 353.392 88.59 353.372 88.25H354.782C354.809 88.4634 354.912 88.64 355.092 88.78C355.279 88.92 355.509 88.99 355.782 88.99C356.049 88.99 356.256 88.9367 356.402 88.83C356.556 88.7234 356.632 88.5867 356.632 88.42C356.632 88.24 356.539 88.1067 356.352 88.02C356.172 87.9267 355.882 87.8267 355.482 87.72C355.069 87.62 354.729 87.5167 354.462 87.41C354.202 87.3034 353.976 87.14 353.782 86.92C353.596 86.7 353.502 86.4034 353.502 86.03C353.502 85.7234 353.589 85.4434 353.762 85.19C353.942 84.9367 354.196 84.7367 354.522 84.59C354.856 84.4434 355.246 84.37 355.692 84.37C356.352 84.37 356.879 84.5367 357.272 84.87C357.666 85.1967 357.882 85.64 357.922 86.2H356.582C356.562 85.98 356.469 85.8067 356.302 85.68C356.142 85.5467 355.926 85.48 355.652 85.48C355.399 85.48 355.202 85.5267 355.062 85.62C354.929 85.7134 354.862 85.8434 354.862 86.01C354.862 86.1967 354.956 86.34 355.142 86.44C355.329 86.5334 355.619 86.63 356.012 86.73C356.412 86.83 356.742 86.9334 357.002 87.04C357.262 87.1467 357.486 87.3134 357.672 87.54C357.866 87.76 357.966 88.0534 357.972 88.42C357.972 88.74 357.882 89.0267 357.702 89.28C357.529 89.5334 357.276 89.7334 356.942 89.88C356.616 90.02 356.232 90.09 355.792 90.09Z"
                    fill="#4C5366"
                />
                <path
                    d="M364.272 87.11C364.272 87.31 364.258 87.49 364.232 87.65H360.182C360.215 88.05 360.355 88.3634 360.602 88.59C360.848 88.8167 361.152 88.93 361.512 88.93C362.032 88.93 362.402 88.7067 362.622 88.26H364.132C363.972 88.7934 363.665 89.2334 363.212 89.58C362.758 89.92 362.202 90.09 361.542 90.09C361.008 90.09 360.528 89.9734 360.102 89.74C359.682 89.5 359.352 89.1634 359.112 88.73C358.878 88.2967 358.762 87.7967 358.762 87.23C358.762 86.6567 358.878 86.1534 359.112 85.72C359.345 85.2867 359.672 84.9534 360.092 84.72C360.512 84.4867 360.995 84.37 361.542 84.37C362.068 84.37 362.538 84.4834 362.952 84.71C363.372 84.9367 363.695 85.26 363.922 85.68C364.155 86.0934 364.272 86.57 364.272 87.11ZM362.822 86.71C362.815 86.35 362.685 86.0634 362.432 85.85C362.178 85.63 361.868 85.52 361.502 85.52C361.155 85.52 360.862 85.6267 360.622 85.84C360.388 86.0467 360.245 86.3367 360.192 86.71H362.822Z"
                    fill="#4C5366"
                />
                <path
                    d="M366.693 85.32C366.873 85.0267 367.107 84.7967 367.393 84.63C367.687 84.4634 368.02 84.38 368.393 84.38V85.85H368.023C367.583 85.85 367.25 85.9534 367.023 86.16C366.803 86.3667 366.693 86.7267 366.693 87.24V90H365.293V84.46H366.693V85.32Z"
                    fill="#4C5366"
                />
                <path d="M336 131.02V132.15H333.09V133.94H335.32V135.05H333.09V138H331.69V131.02H336Z" fill="#4C5366" />
                <path
                    d="M336.633 135.21C336.633 134.65 336.743 134.153 336.963 133.72C337.189 133.287 337.493 132.953 337.873 132.72C338.259 132.487 338.689 132.37 339.163 132.37C339.576 132.37 339.936 132.453 340.243 132.62C340.556 132.787 340.806 132.997 340.993 133.25V132.46H342.403V138H340.993V137.19C340.813 137.45 340.563 137.667 340.243 137.84C339.929 138.007 339.566 138.09 339.153 138.09C338.686 138.09 338.259 137.97 337.873 137.73C337.493 137.49 337.189 137.153 336.963 136.72C336.743 136.28 336.633 135.777 336.633 135.21ZM340.993 135.23C340.993 134.89 340.926 134.6 340.793 134.36C340.659 134.113 340.479 133.927 340.253 133.8C340.026 133.667 339.783 133.6 339.523 133.6C339.263 133.6 339.023 133.663 338.803 133.79C338.583 133.917 338.403 134.103 338.263 134.35C338.129 134.59 338.063 134.877 338.063 135.21C338.063 135.543 338.129 135.837 338.263 136.09C338.403 136.337 338.583 136.527 338.803 136.66C339.029 136.793 339.269 136.86 339.523 136.86C339.783 136.86 340.026 136.797 340.253 136.67C340.479 136.537 340.659 136.35 340.793 136.11C340.926 135.863 340.993 135.57 340.993 135.23Z"
                    fill="#4C5366"
                />
                <path
                    d="M345.17 133.32C345.35 133.027 345.583 132.797 345.87 132.63C346.163 132.463 346.497 132.38 346.87 132.38V133.85H346.5C346.06 133.85 345.727 133.953 345.5 134.16C345.28 134.367 345.17 134.727 345.17 135.24V138H343.77V132.46H345.17V133.32Z"
                    fill="#4C5366"
                />
                <path
                    d="M350.883 132.38C351.543 132.38 352.076 132.59 352.483 133.01C352.89 133.423 353.093 134.003 353.093 134.75V138H351.693V134.94C351.693 134.5 351.583 134.163 351.363 133.93C351.143 133.69 350.843 133.57 350.463 133.57C350.076 133.57 349.77 133.69 349.543 133.93C349.323 134.163 349.213 134.5 349.213 134.94V138H347.813V132.46H349.213V133.15C349.4 132.91 349.636 132.723 349.923 132.59C350.216 132.45 350.536 132.38 350.883 132.38Z"
                    fill="#4C5366"
                />
                <path
                    d="M359.574 135.11C359.574 135.31 359.561 135.49 359.534 135.65H355.484C355.518 136.05 355.658 136.363 355.904 136.59C356.151 136.817 356.454 136.93 356.814 136.93C357.334 136.93 357.704 136.707 357.924 136.26H359.434C359.274 136.793 358.968 137.233 358.514 137.58C358.061 137.92 357.504 138.09 356.844 138.09C356.311 138.09 355.831 137.973 355.404 137.74C354.984 137.5 354.654 137.163 354.414 136.73C354.181 136.297 354.064 135.797 354.064 135.23C354.064 134.657 354.181 134.153 354.414 133.72C354.648 133.287 354.974 132.953 355.394 132.72C355.814 132.487 356.298 132.37 356.844 132.37C357.371 132.37 357.841 132.483 358.254 132.71C358.674 132.937 358.998 133.26 359.224 133.68C359.458 134.093 359.574 134.57 359.574 135.11ZM358.124 134.71C358.118 134.35 357.988 134.063 357.734 133.85C357.481 133.63 357.171 133.52 356.804 133.52C356.458 133.52 356.164 133.627 355.924 133.84C355.691 134.047 355.548 134.337 355.494 134.71H358.124Z"
                    fill="#4C5366"
                />
                <path d="M361.996 130.6V138H360.596V130.6H361.996Z" fill="#4C5366" />
                <path d="M364.779 130.6V138H363.379V130.6H364.779Z" fill="#4C5366" />
                <path
                    d="M107.96 83.02L105.4 90H103.7L101.14 83.02H102.64L104.56 88.57L106.47 83.02H107.96Z"
                    fill="white"
                />
                <path
                    d="M109.51 83.8C109.263 83.8 109.056 83.7234 108.89 83.57C108.73 83.41 108.65 83.2134 108.65 82.98C108.65 82.7467 108.73 82.5534 108.89 82.4C109.056 82.24 109.263 82.16 109.51 82.16C109.756 82.16 109.96 82.24 110.12 82.4C110.286 82.5534 110.37 82.7467 110.37 82.98C110.37 83.2134 110.286 83.41 110.12 83.57C109.96 83.7234 109.756 83.8 109.51 83.8ZM110.2 84.46V90H108.8V84.46H110.2Z"
                    fill="white"
                />
                <path
                    d="M113.703 90.09C113.249 90.09 112.843 90.01 112.483 89.85C112.123 89.6834 111.836 89.46 111.623 89.18C111.416 88.9 111.303 88.59 111.283 88.25H112.693C112.719 88.4634 112.823 88.64 113.003 88.78C113.189 88.92 113.419 88.99 113.693 88.99C113.959 88.99 114.166 88.9367 114.313 88.83C114.466 88.7234 114.543 88.5867 114.543 88.42C114.543 88.24 114.449 88.1067 114.263 88.02C114.083 87.9267 113.793 87.8267 113.393 87.72C112.979 87.62 112.639 87.5167 112.373 87.41C112.113 87.3034 111.886 87.14 111.693 86.92C111.506 86.7 111.413 86.4034 111.413 86.03C111.413 85.7234 111.499 85.4434 111.673 85.19C111.853 84.9367 112.106 84.7367 112.433 84.59C112.766 84.4434 113.156 84.37 113.603 84.37C114.263 84.37 114.789 84.5367 115.183 84.87C115.576 85.1967 115.793 85.64 115.833 86.2H114.493C114.473 85.98 114.379 85.8067 114.213 85.68C114.053 85.5467 113.836 85.48 113.563 85.48C113.309 85.48 113.113 85.5267 112.973 85.62C112.839 85.7134 112.773 85.8434 112.773 86.01C112.773 86.1967 112.866 86.34 113.053 86.44C113.239 86.5334 113.529 86.63 113.923 86.73C114.323 86.83 114.653 86.9334 114.913 87.04C115.173 87.1467 115.396 87.3134 115.583 87.54C115.776 87.76 115.876 88.0534 115.883 88.42C115.883 88.74 115.793 89.0267 115.613 89.28C115.439 89.5334 115.186 89.7334 114.853 89.88C114.526 90.02 114.143 90.09 113.703 90.09Z"
                    fill="white"
                />
                <path
                    d="M120.152 84.38C120.572 84.38 120.945 84.4734 121.272 84.66C121.599 84.84 121.852 85.11 122.032 85.47C122.219 85.8234 122.312 86.25 122.312 86.75V90H120.912V86.94C120.912 86.5 120.802 86.1634 120.582 85.93C120.362 85.69 120.062 85.57 119.682 85.57C119.295 85.57 118.989 85.69 118.762 85.93C118.542 86.1634 118.432 86.5 118.432 86.94V90H117.032V82.6H118.432V85.15C118.612 84.91 118.852 84.7234 119.152 84.59C119.452 84.45 119.785 84.38 120.152 84.38Z"
                    fill="white"
                />
                <path
                    d="M123.283 87.21C123.283 86.65 123.393 86.1534 123.613 85.72C123.84 85.2867 124.143 84.9534 124.523 84.72C124.91 84.4867 125.34 84.37 125.813 84.37C126.227 84.37 126.587 84.4534 126.893 84.62C127.207 84.7867 127.457 84.9967 127.643 85.25V84.46H129.053V90H127.643V89.19C127.463 89.45 127.213 89.6667 126.893 89.84C126.58 90.0067 126.217 90.09 125.803 90.09C125.337 90.09 124.91 89.97 124.523 89.73C124.143 89.49 123.84 89.1534 123.613 88.72C123.393 88.28 123.283 87.7767 123.283 87.21ZM127.643 87.23C127.643 86.89 127.577 86.6 127.443 86.36C127.31 86.1134 127.13 85.9267 126.903 85.8C126.677 85.6667 126.433 85.6 126.173 85.6C125.913 85.6 125.673 85.6634 125.453 85.79C125.233 85.9167 125.053 86.1034 124.913 86.35C124.78 86.59 124.713 86.8767 124.713 87.21C124.713 87.5434 124.78 87.8367 124.913 88.09C125.053 88.3367 125.233 88.5267 125.453 88.66C125.68 88.7934 125.92 88.86 126.173 88.86C126.433 88.86 126.677 88.7967 126.903 88.67C127.13 88.5367 127.31 88.35 127.443 88.11C127.577 87.8634 127.643 87.57 127.643 87.23Z"
                    fill="white"
                />
                <path
                    d="M135.721 84.46L132.291 92.62H130.801L132.001 89.86L129.781 84.46H131.351L132.781 88.33L134.231 84.46H135.721Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M304.172 82.4644L307.354 85.6464C307.549 85.8417 307.549 86.1583 307.354 86.3535L304.172 89.5355C303.976 89.7308 303.66 89.7308 303.464 89.5355C303.269 89.3402 303.269 89.0237 303.464 88.8284L305.793 86.5H144V85.5H305.793L303.464 83.1715C303.269 82.9763 303.269 82.6597 303.464 82.4644C303.66 82.2692 303.976 82.2692 304.172 82.4644Z"
                    fill="#9789F4"
                />
                <rect x="313" y="32" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="313" y="80" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="316" y="83" width="6" height="6" rx="3" fill="#9789F4" />
                <rect x="313" y="128" width="12" height="12" rx="6" fill="#9789F4" fill-opacity="0.2" />
                <rect x="316" y="131" width="6" height="6" rx="3" fill="#9789F4" />
                <rect x="316" y="35" width="6" height="6" rx="3" fill="#9789F4" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M200.51 93.9702C188.149 89.4967 170.882 86.5 144 86.5V85.5C170.95 85.5 188.343 88.5033 200.85 93.0298C213.367 97.56 220.958 103.606 228.298 109.613C228.527 109.801 228.756 109.988 228.985 110.176C236.077 115.986 243.059 121.706 254.223 126.034C265.567 130.432 281.261 133.403 305.791 133.498L303.464 131.172C303.269 130.976 303.269 130.66 303.464 130.464C303.66 130.269 303.976 130.269 304.172 130.464L307.354 133.646C307.549 133.842 307.549 134.158 307.354 134.354L304.172 137.536C303.976 137.731 303.66 137.731 303.464 137.536C303.269 137.34 303.269 137.024 303.464 136.828L305.795 134.498C281.187 134.404 265.358 131.424 253.861 126.966C242.547 122.58 235.459 116.772 228.37 110.965C228.135 110.772 227.9 110.58 227.664 110.387C220.343 104.394 212.86 98.44 200.51 93.9702Z"
                    fill="#9789F4"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M197.186 78.0397C186.674 82.5018 170.887 85.5 144 85.5V86.5C170.945 86.5 186.886 83.4982 197.577 78.9603C207.504 74.7465 212.872 69.218 218.269 63.6596C218.693 63.2228 219.118 62.786 219.544 62.3495C225.384 56.3747 231.712 50.4349 244.315 45.9713C256.779 41.557 275.407 38.5783 305.791 38.5015L303.464 40.8284C303.269 41.0237 303.269 41.3403 303.464 41.5355C303.66 41.7308 303.976 41.7308 304.172 41.5355L307.354 38.3536C307.549 38.1583 307.549 37.8417 307.354 37.6464L304.172 34.4645C303.976 34.2692 303.66 34.2692 303.464 34.4645C303.269 34.6597 303.269 34.9763 303.464 35.1716L305.794 37.5015C275.357 37.5781 256.599 40.56 243.981 45.0287C231.173 49.5651 224.718 55.6253 218.829 61.6505C218.404 62.0854 217.982 62.5198 217.561 62.9532C212.165 68.5089 206.923 73.907 197.186 78.0397Z"
                    fill="#9789F4"
                />
                <path
                    d="M307.354 37.6464C307.549 37.8417 307.549 38.1583 307.354 38.3536L304.172 41.5355C303.976 41.7308 303.66 41.7308 303.465 41.5355C303.269 41.3403 303.269 41.0237 303.465 40.8284L306.293 38L303.465 35.1716C303.269 34.9763 303.269 34.6597 303.465 34.4645C303.66 34.2692 303.976 34.2692 304.172 34.4645L307.354 37.6464ZM232.142 50.5733C244.357 43.1136 264.397 37.5 307 37.5V38.5C264.455 38.5 244.64 44.1122 232.663 51.4267L232.142 50.5733Z"
                    fill="#9789F4"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M273.949 62.0455C252.924 59.9113 240.64 55.6168 232.142 50.4267L232.663 49.5732C240.993 54.6602 253.117 58.9258 274.05 61.0506L273.949 62.0455Z"
                    fill="#B6BBC9"
                />
                <path
                    d="M223 50C223 46.134 226.134 43 230 43H234C237.866 43 241 46.134 241 50C241 53.866 237.866 57 234 57H230C226.134 57 223 53.866 223 50Z"
                    fill="#9789F4"
                />
                <path
                    d="M231.583 52.5H232.417C232.646 52.5 232.833 52.3125 232.833 52.0833C232.833 51.8542 232.646 51.6667 232.417 51.6667H231.583C231.354 51.6667 231.167 51.8542 231.167 52.0833C231.167 52.3125 231.354 52.5 231.583 52.5ZM228.25 47.9167C228.25 48.1458 228.437 48.3333 228.667 48.3333H235.333C235.562 48.3333 235.75 48.1458 235.75 47.9167C235.75 47.6875 235.562 47.5 235.333 47.5H228.667C228.437 47.5 228.25 47.6875 228.25 47.9167ZM229.917 50.4167H234.083C234.312 50.4167 234.5 50.2292 234.5 50C234.5 49.7708 234.312 49.5833 234.083 49.5833H229.917C229.688 49.5833 229.5 49.7708 229.5 50C229.5 50.2292 229.688 50.4167 229.917 50.4167Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M325 38C325 41.3137 322.314 44 319 44C315.686 44 313 41.3137 313 38H325Z"
                    fill="#F7F8FC"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M322 38C322 39.6569 320.657 41 319 41C317.343 41 316 39.6569 316 38H322Z"
                    fill="#D8DEEE"
                />
                <path
                    d="M254 61C254 57.6863 256.686 55 260 55H279C282.314 55 285 57.6863 285 61C285 64.3137 282.314 67 279 67H260C256.686 67 254 64.3137 254 61Z"
                    fill="#B6BBC9"
                />
                <path
                    d="M261.144 64.056C260.621 64.056 260.141 63.9333 259.704 63.688C259.266 63.4426 258.92 63.104 258.664 62.672C258.408 62.2346 258.28 61.7413 258.28 61.192C258.28 60.648 258.408 60.16 258.664 59.728C258.92 59.2906 259.266 58.9493 259.704 58.704C260.141 58.4586 260.621 58.336 261.144 58.336C261.672 58.336 262.152 58.4586 262.584 58.704C263.021 58.9493 263.365 59.2906 263.616 59.728C263.872 60.16 264 60.648 264 61.192C264 61.7413 263.872 62.2346 263.616 62.672C263.365 63.104 263.021 63.4426 262.584 63.688C262.146 63.9333 261.666 64.056 261.144 64.056ZM261.144 63.056C261.48 63.056 261.776 62.9813 262.032 62.832C262.288 62.6773 262.488 62.4586 262.632 62.176C262.776 61.8933 262.848 61.5653 262.848 61.192C262.848 60.8186 262.776 60.4933 262.632 60.216C262.488 59.9333 262.288 59.7173 262.032 59.568C261.776 59.4186 261.48 59.344 261.144 59.344C260.808 59.344 260.509 59.4186 260.248 59.568C259.992 59.7173 259.792 59.9333 259.648 60.216C259.504 60.4933 259.432 60.8186 259.432 61.192C259.432 61.5653 259.504 61.8933 259.648 62.176C259.792 62.4586 259.992 62.6773 260.248 62.832C260.509 62.9813 260.808 63.056 261.144 63.056Z"
                    fill="white"
                />
                <path
                    d="M266.137 60.488V62.632C266.137 62.7813 266.172 62.8906 266.241 62.96C266.316 63.024 266.438 63.056 266.609 63.056H267.129V64H266.425C265.481 64 265.009 63.5413 265.009 62.624V60.488H264.481V59.568H265.009V58.472H266.137V59.568H267.129V60.488H266.137Z"
                    fill="white"
                />
                <path
                    d="M270.431 59.504C270.767 59.504 271.065 59.5786 271.327 59.728C271.588 59.872 271.791 60.088 271.935 60.376C272.084 60.6586 272.159 61 272.159 61.4V64H271.039V61.552C271.039 61.2 270.951 60.9306 270.775 60.744C270.599 60.552 270.359 60.456 270.055 60.456C269.745 60.456 269.5 60.552 269.319 60.744C269.143 60.9306 269.055 61.2 269.055 61.552V64H267.935V58.08H269.055V60.12C269.199 59.928 269.391 59.7786 269.631 59.672C269.871 59.56 270.137 59.504 270.431 59.504Z"
                    fill="white"
                />
                <path
                    d="M277.344 61.688C277.344 61.848 277.333 61.992 277.312 62.12H274.072C274.098 62.44 274.21 62.6906 274.408 62.872C274.605 63.0533 274.848 63.144 275.136 63.144C275.552 63.144 275.848 62.9653 276.024 62.608H277.232C277.104 63.0346 276.858 63.3866 276.496 63.664C276.133 63.936 275.688 64.072 275.16 64.072C274.733 64.072 274.349 63.9786 274.008 63.792C273.672 63.6 273.408 63.3306 273.216 62.984C273.029 62.6373 272.936 62.2373 272.936 61.784C272.936 61.3253 273.029 60.9226 273.216 60.576C273.402 60.2293 273.664 59.9626 274 59.776C274.336 59.5893 274.722 59.496 275.16 59.496C275.581 59.496 275.957 59.5866 276.288 59.768C276.624 59.9493 276.882 60.208 277.064 60.544C277.25 60.8746 277.344 61.256 277.344 61.688ZM276.184 61.368C276.178 61.08 276.074 60.8506 275.872 60.68C275.669 60.504 275.421 60.416 275.128 60.416C274.85 60.416 274.616 60.5013 274.424 60.672C274.237 60.8373 274.122 61.0693 274.08 61.368H276.184Z"
                    fill="white"
                />
                <path
                    d="M279.281 60.256C279.425 60.0213 279.612 59.8373 279.841 59.704C280.076 59.5706 280.342 59.504 280.641 59.504V60.68H280.345C279.993 60.68 279.726 60.7626 279.545 60.928C279.369 61.0933 279.281 61.3813 279.281 61.792V64H278.161V59.568H279.281V60.256Z"
                    fill="white"
                />
                <path
                    d="M253 38.5C253 34.9101 255.91 32 259.5 32H291.5C295.09 32 298 34.9101 298 38.5C298 42.0899 295.09 45 291.5 45H259.5C255.91 45 253 42.0899 253 38.5Z"
                    fill="#9789F4"
                />
                <path
                    d="M291.693 41.072C291.33 41.072 291.005 41.008 290.717 40.88C290.429 40.7466 290.2 40.568 290.029 40.344C289.864 40.12 289.773 39.872 289.757 39.6H290.885C290.906 39.7706 290.989 39.912 291.133 40.024C291.282 40.136 291.466 40.192 291.685 40.192C291.898 40.192 292.064 40.1493 292.181 40.064C292.304 39.9786 292.365 39.8693 292.365 39.736C292.365 39.592 292.29 39.4853 292.141 39.416C291.997 39.3413 291.765 39.2613 291.445 39.176C291.114 39.096 290.842 39.0133 290.629 38.928C290.421 38.8426 290.24 38.712 290.085 38.536C289.936 38.36 289.861 38.1226 289.861 37.824C289.861 37.5786 289.93 37.3546 290.069 37.152C290.213 36.9493 290.416 36.7893 290.677 36.672C290.944 36.5546 291.256 36.496 291.613 36.496C292.141 36.496 292.562 36.6293 292.877 36.896C293.192 37.1573 293.365 37.512 293.397 37.96H292.325C292.309 37.784 292.234 37.6453 292.101 37.544C291.973 37.4373 291.8 37.384 291.581 37.384C291.378 37.384 291.221 37.4213 291.109 37.496C291.002 37.5706 290.949 37.6746 290.949 37.808C290.949 37.9573 291.024 38.072 291.173 38.152C291.322 38.2266 291.554 38.304 291.869 38.384C292.189 38.464 292.453 38.5466 292.661 38.632C292.869 38.7173 293.048 38.8506 293.197 39.032C293.352 39.208 293.432 39.4426 293.437 39.736C293.437 39.992 293.365 40.2213 293.221 40.424C293.082 40.6266 292.88 40.7866 292.613 40.904C292.352 41.016 292.045 41.072 291.693 41.072Z"
                    fill="white"
                />
                <path
                    d="M287.883 37.256C288.027 37.0214 288.213 36.8374 288.443 36.704C288.677 36.5707 288.944 36.504 289.243 36.504V37.68H288.947C288.595 37.68 288.328 37.7627 288.147 37.928C287.971 38.0934 287.883 38.3814 287.883 38.792V41H286.763V36.568H287.883V37.256Z"
                    fill="white"
                />
                <path
                    d="M283.629 41.072C283.203 41.072 282.819 40.9786 282.477 40.792C282.136 40.6 281.867 40.3306 281.669 39.984C281.477 39.6373 281.381 39.2373 281.381 38.784C281.381 38.3306 281.48 37.9306 281.677 37.584C281.88 37.2373 282.155 36.9706 282.501 36.784C282.848 36.592 283.235 36.496 283.661 36.496C284.088 36.496 284.475 36.592 284.821 36.784C285.168 36.9706 285.44 37.2373 285.637 37.584C285.84 37.9306 285.941 38.3306 285.941 38.784C285.941 39.2373 285.837 39.6373 285.629 39.984C285.427 40.3306 285.149 40.6 284.797 40.792C284.451 40.9786 284.061 41.072 283.629 41.072ZM283.629 40.096C283.832 40.096 284.021 40.048 284.197 39.952C284.379 39.8506 284.523 39.7013 284.629 39.504C284.736 39.3066 284.789 39.0666 284.789 38.784C284.789 38.3626 284.677 38.04 284.453 37.816C284.235 37.5866 283.965 37.472 283.645 37.472C283.325 37.472 283.056 37.5866 282.837 37.816C282.624 38.04 282.517 38.3626 282.517 38.784C282.517 39.2053 282.621 39.5306 282.829 39.76C283.043 39.984 283.309 40.096 283.629 40.096Z"
                    fill="white"
                />
                <path
                    d="M279.864 37.488V39.632C279.864 39.7814 279.898 39.8907 279.968 39.96C280.042 40.024 280.165 40.056 280.336 40.056H280.856V41H280.152C279.208 41 278.736 40.5414 278.736 39.624V37.488H278.208V36.568H278.736V35.472H279.864V36.568H280.856V37.488H279.864Z"
                    fill="white"
                />
                <path
                    d="M275.896 41.072C275.533 41.072 275.208 41.008 274.92 40.88C274.632 40.7466 274.403 40.568 274.232 40.344C274.067 40.12 273.976 39.872 273.96 39.6H275.088C275.109 39.7706 275.192 39.912 275.336 40.024C275.485 40.136 275.669 40.192 275.888 40.192C276.101 40.192 276.267 40.1493 276.384 40.064C276.507 39.9786 276.568 39.8693 276.568 39.736C276.568 39.592 276.493 39.4853 276.344 39.416C276.2 39.3413 275.968 39.2613 275.648 39.176C275.317 39.096 275.045 39.0133 274.832 38.928C274.624 38.8426 274.443 38.712 274.288 38.536C274.139 38.36 274.064 38.1226 274.064 37.824C274.064 37.5786 274.133 37.3546 274.272 37.152C274.416 36.9493 274.619 36.7893 274.88 36.672C275.147 36.5546 275.459 36.496 275.816 36.496C276.344 36.496 276.765 36.6293 277.08 36.896C277.395 37.1573 277.568 37.512 277.6 37.96H276.528C276.512 37.784 276.437 37.6453 276.304 37.544C276.176 37.4373 276.003 37.384 275.784 37.384C275.581 37.384 275.424 37.4213 275.312 37.496C275.205 37.5706 275.152 37.6746 275.152 37.808C275.152 37.9573 275.227 38.072 275.376 38.152C275.525 38.2266 275.757 38.304 276.072 38.384C276.392 38.464 276.656 38.5466 276.864 38.632C277.072 38.7173 277.251 38.8506 277.4 39.032C277.555 39.208 277.635 39.4426 277.64 39.736C277.64 39.992 277.568 40.2213 277.424 40.424C277.285 40.6266 277.083 40.7866 276.816 40.904C276.555 41.016 276.248 41.072 275.896 41.072Z"
                    fill="white"
                />
                <path
                    d="M272.542 36.04C272.344 36.04 272.179 35.9787 272.046 35.856C271.918 35.728 271.854 35.5707 271.854 35.384C271.854 35.1974 271.918 35.0427 272.046 34.92C272.179 34.792 272.344 34.728 272.542 34.728C272.739 34.728 272.902 34.792 273.03 34.92C273.163 35.0427 273.23 35.1974 273.23 35.384C273.23 35.5707 273.163 35.728 273.03 35.856C272.902 35.9787 272.739 36.04 272.542 36.04ZM273.094 36.568V41H271.974V36.568H273.094Z"
                    fill="white"
                />
                <path
                    d="M269.31 41.072C268.947 41.072 268.622 41.008 268.334 40.88C268.046 40.7466 267.817 40.568 267.646 40.344C267.481 40.12 267.39 39.872 267.374 39.6H268.502C268.523 39.7706 268.606 39.912 268.75 40.024C268.899 40.136 269.083 40.192 269.302 40.192C269.515 40.192 269.681 40.1493 269.798 40.064C269.921 39.9786 269.982 39.8693 269.982 39.736C269.982 39.592 269.907 39.4853 269.758 39.416C269.614 39.3413 269.382 39.2613 269.062 39.176C268.731 39.096 268.459 39.0133 268.246 38.928C268.038 38.8426 267.857 38.712 267.702 38.536C267.553 38.36 267.478 38.1226 267.478 37.824C267.478 37.5786 267.547 37.3546 267.686 37.152C267.83 36.9493 268.033 36.7893 268.294 36.672C268.561 36.5546 268.873 36.496 269.23 36.496C269.758 36.496 270.179 36.6293 270.494 36.896C270.809 37.1573 270.982 37.512 271.014 37.96H269.942C269.926 37.784 269.851 37.6453 269.718 37.544C269.59 37.4373 269.417 37.384 269.198 37.384C268.995 37.384 268.838 37.4213 268.726 37.496C268.619 37.5706 268.566 37.6746 268.566 37.808C268.566 37.9573 268.641 38.072 268.79 38.152C268.939 38.2266 269.171 38.304 269.486 38.384C269.806 38.464 270.07 38.5466 270.278 38.632C270.486 38.7173 270.665 38.8506 270.814 39.032C270.969 39.208 271.049 39.4426 271.054 39.736C271.054 39.992 270.982 40.2213 270.838 40.424C270.699 40.6266 270.497 40.7866 270.23 40.904C269.969 41.016 269.662 41.072 269.31 41.072Z"
                    fill="white"
                />
                <path
                    d="M266.797 38.688C266.797 38.848 266.786 38.992 266.765 39.12H263.525C263.551 39.44 263.663 39.6906 263.861 39.872C264.058 40.0533 264.301 40.144 264.589 40.144C265.005 40.144 265.301 39.9653 265.477 39.608H266.685C266.557 40.0346 266.311 40.3866 265.949 40.664C265.586 40.936 265.141 41.072 264.613 41.072C264.186 41.072 263.802 40.9786 263.461 40.792C263.125 40.6 262.861 40.3306 262.669 39.984C262.482 39.6373 262.389 39.2373 262.389 38.784C262.389 38.3253 262.482 37.9226 262.669 37.576C262.855 37.2293 263.117 36.9626 263.453 36.776C263.789 36.5893 264.175 36.496 264.613 36.496C265.034 36.496 265.41 36.5866 265.741 36.768C266.077 36.9493 266.335 37.208 266.517 37.544C266.703 37.8746 266.797 38.256 266.797 38.688ZM265.637 38.368C265.631 38.08 265.527 37.8506 265.325 37.68C265.122 37.504 264.874 37.416 264.581 37.416C264.303 37.416 264.069 37.5013 263.877 37.672C263.69 37.8373 263.575 38.0693 263.533 38.368H265.637Z"
                    fill="white"
                />
                <path
                    d="M260.432 41L259.2 38.824H258.672V41H257.552V35.416H259.648C260.08 35.416 260.448 35.4933 260.752 35.648C261.056 35.7973 261.282 36.0027 261.432 36.264C261.586 36.52 261.664 36.808 261.664 37.128C261.664 37.496 261.557 37.8293 261.344 38.128C261.13 38.4213 260.813 38.624 260.392 38.736L261.728 41H260.432ZM258.672 37.984H259.608C259.912 37.984 260.138 37.912 260.288 37.768C260.437 37.6187 260.512 37.4133 260.512 37.152C260.512 36.896 260.437 36.6987 260.288 36.56C260.138 36.416 259.912 36.344 259.608 36.344H258.672V37.984Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_10649_36702"
                    x="-51"
                    y="143"
                    width="140"
                    height="66"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_10649_36702" />
                </filter>
                <filter
                    id="filter1_f_10649_36702"
                    x="411"
                    y="-85"
                    width="180"
                    height="129"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_10649_36702" />
                </filter>
                <clipPath id="clip0_10649_36702">
                    <rect width="508" height="172" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const SupplierLineCardExplanationDrawer: React.FunctionComponent = () => {
    return (
        <Flexbox flexDirection={'column'} padding={'16px'} gap={'24px'} width={'510px'}>
            <Flexbox flexDirection={'column'} gap={4}>
                <Text variant={'h2'}>
                    <Trans>What are restrictions?</Trans>
                </Text>
                <Text variant={'body-small'}>
                    <Trans>
                        Restrictions allow you to further influence the quote request workflow and tailor it to your
                        needs. You can find some examples below:
                    </Trans>
                </Text>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={4}>
                <Text variant={'h4'}>
                    <Trans>Standard line card</Trans>
                </Text>
                <Text variant={'body-small'}>
                    <Trans>Quote requests can be sent to any supplier that supports a manufactuer.</Trans>
                </Text>
                <StandardLineCardGraphic />
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={4}>
                <Text variant={'h4'}>
                    <Trans>Restrict supplier</Trans>
                </Text>
                <Text variant={'body-small'}>
                    <Trans>E.g. “Send requests for Vishay parts only to DigiKey”</Trans>
                </Text>
                <RestrictSupplierGraphic />
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={4}>
                <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                    <Text variant={'h4'}>
                        <Trans>Restrict part category</Trans>
                    </Text>
                </Flexbox>
                <Text variant={'body-small'}>
                    <Trans>E.g. “Send requests for Vishay parts to DigiKey only for resistors”</Trans>
                </Text>
                <RestrictPartCategoryGraphic />
            </Flexbox>
        </Flexbox>
    );
};

export function useSupplierLineCardExplanationDrawer() {
    const { closeDrawer, setDrawer } = useDrawerContext();

    return {
        openDrawer: () => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <CloseDrawerButton />
                    <SupplierLineCardExplanationDrawer />
                </RightBoxDrawer>,
            );
        },
    };
}
