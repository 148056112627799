import { Trans } from '@lingui/macro';
import { FieldText, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { AddRounded } from '@mui/icons-material';
import { Divider, styled } from '@mui/material';
import { AddCustomPartAction, AddCustomPartState } from './reducer';

export const FieldDescription = ({
    state,
    dispatch,
    descriptionSuggestions,
}: {
    state: AddCustomPartState;
    dispatch: React.Dispatch<AddCustomPartAction>;
    descriptionSuggestions: string[];
}) => {
    const suggestions = descriptionSuggestions.filter((suggestion) => !state.description?.includes(suggestion));

    return (
        <>
            <FieldText
                multiline
                onChange={(v) => dispatch({ type: 'setDescription', description: v })}
                value={state.description}
                size="small"
            />
            {suggestions.length > 0 && (
                <Text variant="h5" color={colorSystem.neutral[6]}>
                    <Trans>Suggestions</Trans>
                </Text>
            )}
            <Flexbox gap={8} width={'568px'} flexWrap={'wrap'}>
                {suggestions.map((suggestion, i) => {
                    return (
                        <Container key={i}>
                            <InnerContainer
                                onClick={() =>
                                    dispatch({
                                        type: 'setDescription',
                                        description: state.description
                                            ? `${state.description} ${suggestion}`
                                            : suggestion,
                                    })
                                }
                            >
                                <Text
                                    variant="body-small"
                                    style={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '528px',
                                    }}
                                >
                                    {suggestion}
                                </Text>
                            </InnerContainer>
                            <Separator orientation="vertical" />
                            <SectionAdd>
                                <AddRounded
                                    style={{ fontSize: '14px', overflow: 'hidden' }}
                                    onClick={() =>
                                        dispatch({
                                            type: 'setDescription',
                                            description: `${state.description} ${suggestion}`,
                                        })
                                    }
                                />
                            </SectionAdd>
                        </Container>
                    );
                })}
            </Flexbox>
        </>
    );
};

const Container = styled('span')({
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    background: colorSystem.neutral[1],
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '4px',
    height: '24px',
});

const Separator = styled(Divider)({
    margin: 0,
});

const InnerContainer = styled('span')({
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background 0.2s linear',
    '&:hover': {
        background: colorSystem.neutral[2],
    },
    borderRadius: '4px 0 0 4px',
    cursor: 'pointer',
});

const SectionAdd = styled(InnerContainer)({
    color: colorSystem.neutral[6],
    padding: '5px',
    borderRadius: '0 4px 4px 0',
});
