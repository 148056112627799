import React from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text';
import { highlight } from './util/highlight';

export type HighlightMatcher = Array<string | RegExp>;

export function GreenHighlight(props: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <span
            style={{
                color: colorSystem.green[7],
                background: colorSystem.green[2],
                fontWeight: 'bold',
            }}
            {...props}
        />
    );
}

export function BoldHighlight(props: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <span
            style={{
                color: colorSystem.neutral[8],
                fontWeight: 'bold',
            }}
            {...props}
        />
    );
}

export function Highlight({
    label,
    matcher,
    overrides,
}: {
    label: string;
    matcher: HighlightMatcher;
    overrides?: {
        Container?: React.ComponentType;
        Highlighter?: React.ComponentType;
        FullMatchHighlighter?: React.ComponentType;
    };
}): JSX.Element {
    const { Container = Text, Highlighter, FullMatchHighlighter } = overrides ?? {};
    const sections = highlight(label, ...matcher);
    const isFullMatch = sections.length === 1 && sections[0].isHighlighted;
    return (
        <Container>
            {sections.map((section, i) => {
                if (!section.isHighlighted) {
                    return <React.Fragment key={i}>{section.content}</React.Fragment>;
                }
                if (Highlighter) {
                    return <Highlighter key={i}>{section.content}</Highlighter>;
                }
                if (isFullMatch && FullMatchHighlighter) {
                    return <FullMatchHighlighter>{section.content}</FullMatchHighlighter>;
                }
                return (
                    <span
                        key={i}
                        style={{
                            color: colorSystem.blue[7],
                            background: colorSystem.blue[2],
                            borderRadius: 2,
                        }}
                    >
                        {section.content}
                    </span>
                );
            })}
        </Container>
    );
}
