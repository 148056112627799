import { compareByNumber } from '@luminovo/design-system';
import React from 'react';
import { usePdfViewerState } from '../hooks/usePdfViewerState';
import { PolygonView, RegionTooltipType } from './PolygonView';

export function LayerDefaultMode({
    pageNumber,
    width,
    height,
    regionTooltip,
}: {
    pageNumber: number;
    width: number;
    height: number;
    regionTooltip?: RegionTooltipType;
}): JSX.Element {
    const svgRef = React.useRef<SVGSVGElement>(null);
    const [state] = usePdfViewerState();
    const viewBox = {
        width,
        height,
    };

    const sortedRegions = React.useMemo(() => {
        return state.regs.findRegions({ pageNumber }).sort(compareByNumber((r) => -r.box.area()));
    }, [state.regs, pageNumber]);

    const polygonPoints = sortedRegions
        //.filter((r) => !r.attributes.some((a) => a.attr === 'isRow'))
        .map((region) => {
            return (
                <PolygonView
                    isAssociated={state.regs.hasLinks({ with: region })}
                    viewbox={viewBox}
                    key={region.id}
                    region={region}
                    regionTooltip={regionTooltip}
                />
            );
        });

    return (
        <>
            <svg
                ref={svgRef}
                viewBox={`0 0 ${width} ${height}`}
                style={{
                    left: 0,
                    top: 0,
                    position: 'absolute',
                    width,
                    height,
                }}
            >
                {polygonPoints}
            </svg>
        </>
    );
}
