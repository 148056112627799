import { t } from '@lingui/macro';
import { assertUnreachable, uniqueBy } from '@luminovo/commons';
import { Tag, colorSystem } from '@luminovo/design-system';
import {
    EmsPartNumberPartMatchFullPart,
    FullPart,
    OtsFullPart,
    PartMatchTypeFullResponse,
    PreferenceStatusEnum,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import {
    ManufacturerStatusChip,
    ManufacturerStatusChipProps,
    ManyManufacturersStatusChip,
} from '../ManufacturerStatusChip';
import { TableCell } from './TableCell';

export const RenderManufacturer = ({ part, onClick }: { part: FullPart; onClick?: () => void }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <RenderOtsPartManufacturer part={part} />
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <RenderGenericPartManufacturer />
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <TableCell isComponentRemoved={part.state === 'Removed'} onClick={onClick}>
                <RenderOtsComponentManufacturerMatches
                    matches={part.matches}
                    otsComponentState={part.state}
                    manufacturerPreferenceStatus={part.manufacturer_preference_status}
                />
            </TableCell>
        );
    }
    if (isCustomFullPart(part)) {
        return <TableCell onClick={onClick} />;
    }
    if (isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    assertUnreachable(part);
};

const RenderOtsPartManufacturer = ({ part, state = 'Active' }: { part: OtsFullPart; state?: 'Active' | 'Removed' }) => {
    return (
        <ManufacturerStatusChip manufacturerName={part.manufacturer.name} preferenceStatus={part.preference_status} />
    );
};

const RenderGenericPartManufacturer = ({ state = 'Active' }: { state?: 'Active' | 'Removed' }) => {
    return (
        <Tag
            color="neutral"
            attention="low"
            label={t`Generic`}
            style={{ color: state === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
        />
    );
};

const RenderOtsComponentManufacturerMatch = ({
    match,
    otsComponentState,
}: {
    match: PartMatchTypeFullResponse;
    otsComponentState?: 'Active' | 'Removed';
}) => {
    const { type, data: partData } = match;
    if (type === 'Generic') {
        return <RenderGenericPartManufacturer state={otsComponentState} />;
    }
    if (type === 'OffTheShelf') {
        return <RenderOtsPartManufacturer part={partData} state={otsComponentState} />;
    }
    assertUnreachable(type);
};

const RenderOtsComponentManufacturerMatches = ({
    matches,
    otsComponentState,
    manufacturerPreferenceStatus,
}: {
    matches: EmsPartNumberPartMatchFullPart[];
    otsComponentState: 'Active' | 'Removed';
    manufacturerPreferenceStatus: PreferenceStatusEnum | null;
}) => {
    if (matches.length === 0) {
        return (
            <Tag
                color="yellow"
                label={t`No linked parts`}
                attention="low"
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.yellow[7] }}
            />
        );
    }
    if (matches.length === 1) {
        return <RenderOtsComponentManufacturerMatch match={matches[0].part} otsComponentState={otsComponentState} />;
    }

    const manufacturerPreferences: ManufacturerStatusChipProps[] = Array.from(
        matches.reduce((acc, match) => {
            if (match.part.type === 'OffTheShelf') {
                const manufacturerCategoriesAndPreferences = acc.get(match.part.data.manufacturer.name) ?? [];
                manufacturerCategoriesAndPreferences.push({
                    partCategory: match.part.data.part_category?.part_category.name,
                    preferenceStatus: match.part.data.preference_status,
                });
                acc.set(match.part.data.manufacturer.name, manufacturerCategoriesAndPreferences);
            }
            if (match.part.type === 'Generic') {
                acc.set('Generic', []);
            }
            return acc;
        }, new Map<string, { partCategory: string | undefined; preferenceStatus: PreferenceStatusEnum | null }[]>()),
    ).flatMap(([manufacturerName, categoriesAndPreferenceStatus]) => {
        return categoriesAndPreferenceStatus.map(({ partCategory, preferenceStatus }) => ({
            manufacturerName,
            partCategory,
            preferenceStatus,
        }));
    });

    const uniqueManufacturerPreferences = uniqueBy(manufacturerPreferences, (data) => {
        const manufacturerName = data.manufacturerName;
        const partCategory = data.partCategory;
        const preferenceStatus = data.preferenceStatus;
        let key = manufacturerName;
        if (partCategory) {
            key += `- ${partCategory}`;
        }
        if (preferenceStatus) {
            const prefStatusAsStr = preferenceStatus.toString();
            key += `- ${prefStatusAsStr}`;
        }
        return key;
    });

    return (
        <ManyManufacturersStatusChip
            aggregatedPreference={manufacturerPreferenceStatus}
            manufacturerPreferences={uniqueManufacturerPreferences}
        />
    );
};
