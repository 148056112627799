import { Close as CloseIcon } from '@mui/icons-material';
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@mui/material';
import React from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';
import { SecondaryIconButton } from '../buttons';

interface TitleProps {
    title: React.ReactNode | string;
}

export const DefaultCloseButton: React.FunctionComponent<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({
    onClick,
}) => {
    return (
        <SecondaryIconButton onClick={onClick} size="small">
            <CloseIcon fontSize="inherit" />
        </SecondaryIconButton>
    );
};

const DefaultTitleComponent: React.FunctionComponent<TitleProps> = ({ title }) => {
    return <Text variant="h2">{title}</Text>;
};

type DialogTitleProps = Omit<MuiDialogTitleProps, 'children' | 'title'> & {
    title: React.ReactNode | string;
    center?: React.ReactNode;
    handleClose?: React.MouseEventHandler<HTMLButtonElement>;
    overrides?: {
        TitleComponent?: React.ComponentType<TitleProps>;
        CloseButton?: React.ComponentType;
    };
};

export const DialogTitle = React.forwardRef<unknown, DialogTitleProps>(
    ({ title, handleClose, overrides = {}, center, style, ...props }, _) => {
        const { TitleComponent = DefaultTitleComponent, CloseButton = DefaultCloseButton } = overrides;

        return (
            <MuiDialogTitle style={{ paddingBottom: '12px', ...style }} {...props}>
                <Flexbox alignItems="center" justifyContent={'space-between'}>
                    <TitleComponent title={title} />
                    {center}
                    {handleClose && <CloseButton onClick={handleClose} />}
                </Flexbox>
            </MuiDialogTitle>
        );
    },
);
