import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import React from 'react';
import type { MonacoEditorProps } from './MonacoEditor';

export type { monaco } from './customMonaco';
export type { MonacoEditorRef } from './MonacoEditor';
export * from './types';

const LazyMonacoEditor = React.lazy(async () => {
    const { MonacoEditorInner } = await import('./MonacoEditor');
    return { default: MonacoEditorInner };
});

export function MonacoEditor(props: MonacoEditorProps) {
    return (
        <React.Suspense
            fallback={
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            <LazyMonacoEditor {...props} />
        </React.Suspense>
    );
}
