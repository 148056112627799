import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import React from 'react';
import { useLocation } from 'react-router';
import { useDrawerContext } from '../contexts/ModalContext';
import { CommunicationsDrawerThread } from './CommentsBox';
import { CommentsDrawer } from './CommentsDrawer';

export function useCommunicationsDrawer({
    rfqId,
    threads,
}: {
    rfqId: string;
    threads: Array<CommunicationsDrawerThread>;
}) {
    const { closeDrawer, setDrawer, isOpen } = useDrawerContext();

    const openCommentsDrawer = Boolean(new URLSearchParams(useLocation().search).get('openCommentsDrawer'));

    const drawer = (
        <RightBoxDrawer onClose={closeDrawer}>
            <Flexbox flexDirection={'column'} width="600px" minWidth="600px">
                <CommentsDrawer rfqId={rfqId} threads={threads} />
            </Flexbox>
        </RightBoxDrawer>
    );

    React.useEffect(() => {
        if (openCommentsDrawer && !isOpen) {
            setDrawer(drawer);
        }
        // Ensure that the drawer is only opened once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        closeDrawer,
        openDrawer: () => {
            setDrawer(drawer);
        },
    };
}
