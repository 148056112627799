import * as React from 'react';
import { DataTableRow } from './DataTableRow';
import {
    Action,
    Column,
    DataContentSearchOptions,
    IndexingFunction,
    PersistentTableState,
    RenderedContentSearchOptions,
} from './types';

export function TableContentIndex<TRowData, TContext>({
    items,
    columns,
    onIndexSearchText,
    idExtractor,
    contentSearchOptions,
    tableState,
    dispatch,
    sharedContext,
}: {
    items: TRowData[];
    tableState: PersistentTableState;
    columns: Column<TRowData, TContext>[];
    onIndexSearchText: IndexingFunction<TRowData>;
    idExtractor: (item: TRowData) => string;
    contentSearchOptions: RenderedContentSearchOptions | DataContentSearchOptions<TRowData, TContext>;
    dispatch: React.Dispatch<Action>;
    sharedContext: TContext;
}): JSX.Element | null {
    React.useEffect(() => {
        if (contentSearchOptions.indexingStrategy === 'data-content') {
            // re-index every time the `items` change.
            for (const item of items) {
                onIndexSearchText(item, contentSearchOptions.indexItemData(item, sharedContext));
            }
        }
    }, [items, sharedContext, contentSearchOptions, onIndexSearchText]);

    if (contentSearchOptions.indexingStrategy === 'rendered-content') {
        const content = items.map((item: TRowData, index) => {
            return (
                <DataTableRow
                    key={'index:' + idExtractor(item)}
                    item={item}
                    index={index}
                    columns={columns}
                    tableState={tableState}
                    onSearchText={onIndexSearchText}
                    display="none"
                    dispatch={dispatch}
                    sharedContext={sharedContext}
                />
            );
        });
        return <>{content}</>;
    }

    return null;
}
