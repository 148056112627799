import { t } from '@lingui/macro';
import { ResourceDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { usePageParams } from '../../../resources/hooks';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { makeDuplicatedDefaultName } from '../utils/makeDuplicatedDefaultName';
import { ResourceForm } from './ResourceForm';
import { convertResourceDTOToDefaultValues } from './utils/convertResourceDTOToDefaultValues';
import { createResourceSchema } from './utils/resourceValidationSchema';

export function DuplicateResourceForm() {
    const { resourceId } = usePageParams<'/manufacturing/resource/:resourceId/duplicate'>();
    const { data: resources, isLoading } = useHttpQuery('GET /resources', {});
    const resource: ResourceDTO | undefined = resources?.data.find((resource) => resource.id === resourceId);
    const existingResourceNames: string[] = resources?.data.map((resource) => resource.name) ?? [];

    if (isLoading) {
        return <CircularProgress />;
    }

    if (resource === undefined) {
        throw new Error('resource should not be undefined if isLoading is false');
    }

    return (
        <ResourceForm
            onSubmitType="POST"
            defaultValues={{
                ...convertResourceDTOToDefaultValues(resource),
                name: makeDuplicatedDefaultName(resource.name, existingResourceNames),
            }}
            schema={createResourceSchema(existingResourceNames)}
            formTitle={capitalizeFirstLetter(t`Duplicate resource`)}
        />
    );
}
