import { UpDownButtons } from '@luminovo/manufacturing-core';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { ExpenseTableRowData } from './types';

export function UpDownButtonsExpenseConfigurations({
    expenseConfiguration,
}: {
    expenseConfiguration: ExpenseTableRowData;
}) {
    const { mutateAsync, isLoading } = useHttpMutation('PATCH /expense-configurations/:expenseConfigurationId/rank', {
        snackbarMessage: null,
    });

    const handleUpdateItems = async ({ direction }: { direction: 'up' | 'down' }) => {
        const expenseConfigurationId =
            expenseConfiguration.expenseConfigurationSummary.expense_configuration_details.expense_configuration_id;

        const nextRank = 'nextRank' in position ? position.nextRank : undefined;
        const previousRank = 'previousRank' in position ? position.previousRank : undefined;

        const newRank = direction === 'up' ? previousRank : nextRank;

        if (newRank === undefined) {
            return;
        }

        await mutateAsync({
            pathParams: {
                expenseConfigurationId,
            },
            queryParams: {},
            requestBody: {
                rank: newRank,
            },
        });
    };

    function handleUp() {
        handleUpdateItems({ direction: 'up' });
    }
    function handleDown() {
        handleUpdateItems({ direction: 'down' });
    }

    const position = expenseConfiguration.position;

    return (
        <UpDownButtons
            isDisabledFirstRow={position.type === 'first' || position.type === 'first-and-last'}
            isDisabledLastRow={position.type === 'last' || position.type === 'first-and-last'}
            onClickUp={handleUp}
            onClickDown={handleDown}
            isLoading={isLoading}
        />
    );
}
