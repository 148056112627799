import { MessageDescriptor } from '@lingui/core';
import { defineMessage, t, Trans } from '@lingui/macro';
import { assertUnreachable, Currency, isPresent, MonetaryValue, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldToggleButtonControlled,
    Flexbox,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import {
    ManualAdditionalSolutionCostItemDTO,
    SolutionConfigurationDTO,
    TotalCostOfOwnershipScaling,
} from '@luminovo/http-client';
import { SimpleCostRow, Solution } from '@luminovo/sourcing-core';
import {
    CostType,
    extractAdditionalCostForType,
    extractManualAdditionalCostForType,
    hasAdditionalCost,
    isManualCostForType,
} from '@luminovo/sourcing-core/src/extractors';
import { Add, Edit } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import { useMutationUpdateSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';

type FormState = {
    additionalCost: FormData;
};

type CostMode = 'automatic' | 'manual';

type FormData = {
    packaging: FormDataItem;
    discount: FormDataItem;
    shipping: FormDataItem;
    customs: FormDataItem;
    other: FormDataItem;
};

type FormDataItem = {
    mode: CostMode;
    automaticCost: MonetaryValue | null;
    manualCost: ManualAdditionalSolutionCostItemDTO | null;
};

const modeTranslations: Record<string, MessageDescriptor> = {
    automatic: defineMessage({ message: 'Automatic' }),
    manual: defineMessage({ message: 'Manual' }),
};

const totalCostOfOwnershipScalingDropdownTranslations: Record<string, MessageDescriptor> = {
    PerUnit: defineMessage({ message: 'per Unit' }),
    Total: defineMessage({ message: 'per Solution' }),
};

const ManualAdditionalCostLine: React.FunctionComponent<{
    type: CostType;
}> = ({ type }): JSX.Element => {
    const { control } = useFormContext<FormState>();
    const additionalCost = useWatch({ control, name: `additionalCost.${type}` });
    const isManual = additionalCost.mode === 'manual';

    return (
        <Flexbox flexDirection="row" gap={8} paddingBottom={'12px'} alignItems="center">
            <SimpleCostRow costType={type} cost={additionalCost.automaticCost} />
            <FieldToggleButtonControlled
                control={control}
                name={`additionalCost.${type}.mode`}
                FieldProps={{
                    options: ['automatic', 'manual'],
                    getOptionLabel: (option) => transEnum(option, modeTranslations),
                }}
            />
            <FieldSelectControlled
                control={control}
                name={`additionalCost.${type}.manualCost.cost.currency`}
                required={isManual}
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                    disabled: !isManual,
                    size: 'small',
                }}
            />
            <Flexbox width="150px">
                <FieldNumericControlled
                    control={control}
                    name={`additionalCost.${type}.manualCost.cost.amount`}
                    required={isManual}
                    min={0}
                    isFinite={true}
                    FieldProps={{
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {transEnum(
                                        additionalCost.manualCost?.cost.currency ?? 'EUR',
                                        inputCurrenciesPublicTranslations,
                                    )}
                                </InputAdornment>
                            ),
                        },
                        disabled: !isManual,
                        fullWidth: true,
                        size: 'small',
                    }}
                />
            </Flexbox>
            <Flexbox width="150px">
                <FieldSelectControlled
                    control={control}
                    name={`additionalCost.${type}.manualCost.scaling`}
                    required={isManual}
                    FieldProps={{
                        options: Object.values(TotalCostOfOwnershipScaling),
                        getOptionLabel: (option) => transEnum(option, totalCostOfOwnershipScalingDropdownTranslations),
                        disableClearable: true,
                        disabled: !isManual,
                        fullWidth: true,
                        size: 'small',
                    }}
                />
            </Flexbox>
        </Flexbox>
    );
};

const ManualAdditionalCostFormInner: React.FunctionComponent = (): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap={12}>
            <Text variant={'body'} color={colorSystem.neutral[8]}>
                <Trans>
                    You can manually add extra costs to the selected solution. The manually added costs will only be
                    applied to this sourcing scenario.
                </Trans>
            </Text>
            <Flexbox overflow={'auto'} flexDirection="column">
                <ManualAdditionalCostLine type={'packaging'} />
                <ManualAdditionalCostLine type={'discount'} />
                <ManualAdditionalCostLine type={'shipping'} />
                <ManualAdditionalCostLine type={'customs'} />
                <ManualAdditionalCostLine type={'other'} />
            </Flexbox>

            <Flexbox flexDirection="row" justifyContent={'flex-end'}>
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
};

function getDefaultValue(
    type: CostType,
    solution: Solution,
    solutionConfiguration: SolutionConfigurationDTO,
    offerCurrency: Currency,
): FormDataItem {
    return {
        mode: isManualCostForType(type, solution) ? 'manual' : 'automatic',
        automaticCost: extractAdditionalCostForType(type, solution, { mode: 'automatic' }),
        manualCost: extractManualAdditionalCostForType(type, solutionConfiguration) ?? {
            cost: {
                amount: '0',
                currency: offerCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
        },
    };
}

function defaultValues({
    solution,
    solutionConfiguration,
    offerCurrency,
}: {
    solution: Solution;
    solutionConfiguration: SolutionConfigurationDTO;
    offerCurrency: Currency;
}): FormState {
    const formData = {
        packaging: getDefaultValue('packaging', solution, solutionConfiguration, offerCurrency),
        discount: getDefaultValue('discount', solution, solutionConfiguration, offerCurrency),
        shipping: getDefaultValue('shipping', solution, solutionConfiguration, offerCurrency),
        customs: getDefaultValue('customs', solution, solutionConfiguration, offerCurrency),
        other: getDefaultValue('other', solution, solutionConfiguration, offerCurrency),
    };
    return {
        additionalCost: formData,
    };
}

function getValueIfUpdated(
    solution: Solution,
    solutionConfiguration: SolutionConfigurationDTO,
    formData: FormData,
    type: CostType,
): ManualAdditionalSolutionCostItemDTO | null {
    let costItem = formData[type];
    switch (costItem.mode) {
        case 'manual':
            return costItem.manualCost;
        case 'automatic':
            return null;
        default:
            assertUnreachable(costItem.mode);
    }
}

function useManualAdditionalCostDialog(solutionConfiguration: SolutionConfigurationDTO, solution: Solution) {
    const solutionConfigurationId = solutionConfiguration.id;
    const offerCurrency = solution.firstPurchaseOption?.unit_price_original?.currency ?? Currency.EUR;

    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useMutationUpdateSolutionConfiguration(solutionConfigurationId);

    const onSubmit = async (formValues: FormState) => {
        const manualTotalCostsOfOwnership = {
            packaging_cost: getValueIfUpdated(solution, solutionConfiguration, formValues.additionalCost, 'packaging'),
            discount: getValueIfUpdated(solution, solutionConfiguration, formValues.additionalCost, 'discount'),
            shipping_cost: getValueIfUpdated(solution, solutionConfiguration, formValues.additionalCost, 'shipping'),
            customs_cost: getValueIfUpdated(solution, solutionConfiguration, formValues.additionalCost, 'customs'),
            other_cost: getValueIfUpdated(solution, solutionConfiguration, formValues.additionalCost, 'other'),
        };

        await mutateAsync({ manualTotalCostsOfOwnership, solutionToken: solution.token });
        closeDialog();
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth="md" onClose={() => closeDialog()}>
                    <DialogTitle title={t`Edit additional costs`} handleClose={() => closeDialog()} />
                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <FormContainer
                            defaultValues={defaultValues({ solution, solutionConfiguration, offerCurrency })}
                            onSubmit={onSubmit}
                        >
                            <ManualAdditionalCostFormInner />
                        </FormContainer>
                    </DialogContent>
                </Dialog>,
            ),
    };
}

const AdditionalCostLine: React.FunctionComponent<{
    cost: MonetaryValue | null;
    costType: CostType;
}> = ({ cost, costType }): JSX.Element => {
    if (isPresent(cost)) {
        return <SimpleCostRow cost={cost} costType={costType} />;
    } else {
        return <></>;
    }
};

export const ManualAdditionalCostButton: React.FunctionComponent<{
    solutionConfiguration: SolutionConfigurationDTO;
    solution: Solution;
}> = ({ solutionConfiguration, solution }): JSX.Element => {
    const { openDialog } = useManualAdditionalCostDialog(solutionConfiguration, solution);

    if (hasAdditionalCost(solution)) {
        return (
            <Flexbox flexDirection={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'top'}>
                <Flexbox flexDirection="column" width={'100%'} gap={16} paddingRight={'22px'}>
                    <AdditionalCostLine
                        cost={extractAdditionalCostForType('packaging', solution)}
                        costType="packaging"
                    />
                    <AdditionalCostLine cost={extractAdditionalCostForType('discount', solution)} costType="discount" />
                    <AdditionalCostLine cost={extractAdditionalCostForType('shipping', solution)} costType="shipping" />
                    <AdditionalCostLine cost={extractAdditionalCostForType('customs', solution)} costType="customs" />
                    <AdditionalCostLine cost={extractAdditionalCostForType('other', solution)} costType="other" />
                </Flexbox>
                <TertiaryButton size="small" startIcon={<Edit />} onClick={() => openDialog()}>
                    <Trans>Edit</Trans>
                </TertiaryButton>
            </Flexbox>
        );
    } else {
        return (
            <TertiaryButton size="small" startIcon={<Add />} onClick={() => openDialog()}>
                <Trans>Add additional costs</Trans>
            </TertiaryButton>
        );
    }
};
