/* eslint-disable camelcase */
import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    CalculationAssemblyPostRuntype,
    CalculationAssemblyResponseRuntype,
    RfqCalculationPDFExportResponseRuntype,
    ScenarioCombinationForCalculationRuntype,
    ScenarioCombinationForCalculationRuntypePatch,
    ScenarioCombinationForCalculationRuntypePatchResponse,
    SourcingCombinationCostsPostRuntype,
    SourcingCombinationCostsRuntype,
} from './calculationBackendTypes';
import { CostResponseRuntype, FrozenScenarioCostRuntype } from './costResponseBackendType';

export const calculationEndpoints = {
    'GET /calculations/scenario-combinations': endpoint({
        description: 'Gets all the scenarios that have been submitted for calculations',
        pathParams: r.Undefined,
        queryParams: r.Record({ assembly_id: r.String.optional(), sourcing_scenario_id: r.String.optional() }),
        requestBody: r.Undefined,
        responseBody: r.Array(ScenarioCombinationForCalculationRuntype),
    }),

    'PATCH /calculations/scenario-combinations': endpoint({
        description: 'Updates all the scenarios that have been submitted for calculations',
        pathParams: r.Undefined,
        queryParams: r.Record({ assembly_id: r.String }),
        requestBody: r.Array(ScenarioCombinationForCalculationRuntypePatch),
        responseBody: r.Array(ScenarioCombinationForCalculationRuntypePatchResponse),
    }),

    'GET /calculations/calculation-assembly-costs': endpoint({
        description: 'Get the scenario combination rows',
        pathParams: r.Undefined,
        queryParams: r
            .Record({
                assembly_id: r.String,
            })
            .Or(
                r.Record({
                    calculation_assembly_details_id: r.String,
                }),
            ),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(CostResponseRuntype),
        }),
    }),

    'POST /calculations/calculation-assembly-costs': endpoint({
        description: 'updates the scenario combination rows',
        pathParams: r.Undefined,
        queryParams: r.Record({
            assembly_id: r.String,
        }),
        requestBody: SourcingCombinationCostsPostRuntype,
        responseBody: r.Record({
            data: r.Array(SourcingCombinationCostsRuntype),
        }),
    }),

    'PATCH /calculations/calculation-assembly-costs/:calculationAssemblyCostId': endpoint({
        description: 'updates the calculation assembly costs',
        pathParams: r.Record({
            calculationAssemblyCostId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: SourcingCombinationCostsPostRuntype,
        responseBody: r.Record({
            data: SourcingCombinationCostsRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),

    'GET /calculations/calculation-assembly-details': endpoint({
        description: 'Gets the calculation assembly detail of an assembly',
        pathParams: r.Undefined,
        queryParams: r.Record({
            assembly_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(CalculationAssemblyResponseRuntype),
        }),
    }),

    'POST /calculations/calculation-assembly-details': endpoint({
        description: 'Creates a calculation assembly detail',
        pathParams: r.Undefined,
        queryParams: r.Record({
            create_from_calculation_template_id: r.String.optional(),
        }),
        requestBody: CalculationAssemblyPostRuntype,
        responseBody: r.Record({
            data: CalculationAssemblyResponseRuntype,
        }),
    }),
    'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId': endpoint({
        description: 'Update a calculation assembly detail',
        pathParams: r.Record({
            calculationAssemblyDetailsId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: CalculationAssemblyResponseRuntype,
        responseBody: r.Record({
            data: CalculationAssemblyResponseRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs', 'GET /calculations/calculation-assembly-details'],
    }),

    'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId/reset': endpoint({
        description: 'Resets the calculation assembly detail values',
        pathParams: r.Record({
            calculationAssemblyDetailsId: r.String,
        }),
        queryParams: r.Union(r.Record({ reset_to_template: r.String }), r.Record({})),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: CalculationAssemblyResponseRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs', 'GET /calculations/calculation-assembly-details'],
    }),
    'POST /calculations/frozen-scenario-cost': endpoint({
        description: 'Freezes a calculation',
        pathParams: r.Undefined,
        queryParams: r.Union(r.Record({ calculation_assembly_cost_id: r.String }), r.Record({})),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: FrozenScenarioCostRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),
    'DELETE /calculations/frozen-scenario-cost/:frozenId': endpoint({
        description: 'Unfreezes a calculation',
        pathParams: r.Record({
            frozenId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),

    'GET /calculations/calculation-assembly-costs/export-pdf-data': endpoint({
        description: 'Returns the data required to constuct the PDF-export page',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: RfqCalculationPDFExportResponseRuntype,
    }),
};
