import { assertUnreachable } from '@luminovo/commons';
import { CSVLineContent, CSVLineHeader, CSVLines, isContentLine, LineItem } from '../types/types';

const convertContentLineToItem = (line: CSVLineContent, columnNames: string[]): LineItem => {
    const item: LineItem = {};

    line.content.forEach((value, index) => {
        const columnName = columnNames[index];
        item[columnName] = value;
    });

    return item;
};

const doAllLinesHaveSameNumberOfColumns = (lines: CSVLines): boolean => {
    const csvLinesType = lines.type;
    switch (csvLinesType) {
        case 'with-header':
        case 'without-header':
            const lineLengths = lines.lines.map((line) => line.content.length);
            return lineLengths.every((length) => length === lineLengths[0]);
        default:
            assertUnreachable(csvLinesType);
    }
};

export const convertLinesToItems = (lines: CSVLines): LineItem[] => {
    const csvLinesType = lines.type;

    if (!doAllLinesHaveSameNumberOfColumns(lines)) {
        throw new Error('All lines must have the same number of columns');
    }

    switch (csvLinesType) {
        case 'with-header':
            const headerLine: CSVLineHeader = lines.lines[0];
            const contentLines: CSVLineContent[] = lines.lines.filter(isContentLine);
            const columnNames = headerLine.content;
            const items = contentLines.map((line) => {
                return convertContentLineToItem(line, columnNames);
            });

            return items;
        case 'without-header':
            const columnNamesWithoutHeader = lines.lines[0].content.map((_, index) => `column-${index}`);
            return lines.lines.map((line, index) => {
                return convertContentLineToItem(line, columnNamesWithoutHeader);
            });
        default:
            assertUnreachable(csvLinesType);
    }
};
