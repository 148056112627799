import { formatDecimal } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { AssemblyLabelWithErrorBoundary } from './AssemblyLabelWithErrorBoundary';

interface LabelScenarioOrderSizeProps {
    sourcingScenario: SourcingScenarioDTO;
    assemblyId?: string;
}

export function LabelScenarioOrderSize({ sourcingScenario, assemblyId }: LabelScenarioOrderSizeProps): JSX.Element {
    const orderSize = sourcingScenario.assembly_quantities.items
        .filter((item) => (assemblyId ? item.assembly === assemblyId : true))
        .map((item) => item.quantity)
        .reduce((a, b) => a + b, 0);

    const quantities = sourcingScenario.assembly_quantities.items.filter((item) =>
        assemblyId ? item.assembly === assemblyId : true,
    );

    const tooltip = () => {
        return (
            <Flexbox flexDirection={'column'} gap={'4px'}>
                {quantities.map((item, idx) => {
                    return (
                        <Flexbox key={idx} justifyContent={'space-between'} gap={'24px'}>
                            <AssemblyLabelWithErrorBoundary assembly={item.assembly} />
                            <Text>{formatDecimal(item.quantity)}</Text>
                        </Flexbox>
                    );
                })}
                <Flexbox
                    justifyContent={'space-between'}
                    gap={'24px'}
                    borderTop={`1px solid ${colorSystem.neutral[2]}`}
                >
                    <Text variant="body-semibold">Total</Text>
                    <Text variant="body-semibold">{formatDecimal(orderSize)}</Text>
                </Flexbox>
            </Flexbox>
        );
    };

    if (quantities.length === 1) {
        return <span>{formatDecimal(orderSize)}</span>;
    }

    return (
        <Tooltip variant="white" title={quantities.length > 0 ? tooltip() : ''}>
            <span>
                {formatDecimal(orderSize)}
                <Text color={colorSystem.neutral[6]}>*</Text>
            </span>
        </Tooltip>
    );
}
