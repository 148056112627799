import { PriceType } from '@luminovo/http-client';
import { cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface PriceTypeProps {}

export const priceType = (_?: PriceTypeProps): Parser<PriceType> =>
    cases({
        matches: [
            {
                when: ['ListPrice', 'List price', 'List'],
                then: PriceType.ListPrice,
            },
            {
                when: ['ContractPrice', 'Contract price', 'Contract'],
                then: PriceType.ContractPrice,
            },
            {
                when: ['QuotePrice', 'Quote price', 'Quote'],
                then: PriceType.QuotePrice,
            },
            {
                when: ['CustomerNegotiatedPrice', 'Customer negotiated price', 'Customer negotiated'],
                then: PriceType.CustomerNegotiatedPrice,
            },
            {
                when: ['PurchasePrice', 'Purchase price', 'Purchase'],
                then: PriceType.PurchasePrice,
            },
            {
                when: ['StandardPrice', 'Standard price', 'Standard'],
                then: PriceType.StandardPrice,
            },
            {
                when: ['TargetPrice', 'Target price', 'Target'],
                then: PriceType.TargetPrice,
            },
        ],
    });
