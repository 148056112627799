import { getToken } from '@luminovo/auth';
import { BACKEND_HOST, DB } from '@luminovo/http-client';
import { Query, QueryResult, Table } from '@synthql/queries';
import { SynthqlProvider, useSynthql } from '@synthql/react';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

export type QueryResultType<TQuery extends Query<DB>> = TQuery extends { cardinality: 'many' }
    ? QueryResult<DB, TQuery>[0]
    : TQuery extends { cardinality: 'maybe' }
      ? Exclude<QueryResult<DB, TQuery>, null>
      : QueryResult<DB, TQuery>;

export function SynthQLContextProvider({ children }: React.PropsWithChildren<{}>) {
    // ❗️❗️❗️ This is an experimental integration, only for testing purposes.
    // ❗️❗️❗️ We explicitly don't want to use this in production yet.
    return (
        <SynthqlProvider
            value={{
                // eslint-disable-next-line spellcheck/spell-checker
                endpoint: `${BACKEND_HOST}/api/gateway/synthql`,
                requestInit: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${getToken()}`,
                    },
                },
            }}
        >
            {children}
        </SynthqlProvider>
    );
}

/**
 * @deprecated Uses synthql - should never be used in production!
 */
export function useSynth<TQuery extends Query<DB>>({
    query,
    enabled,
    suspense = true,
}: {
    query: TQuery;
    enabled?: boolean;
    suspense?: boolean;
}): UseQueryResult<QueryResult<DB, TQuery>> {
    return useSynthql<DB, Table<DB>, TQuery>(query, {
        reactQuery: {
            suspense,
            refetchOnWindowFocus: true,
            useErrorBoundary: true,
            enabled,
        } as {},
        returnLastOnly: true,
    });
}
