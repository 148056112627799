import { t } from '@lingui/macro';
import { AvailabilityIcon, ComplianceIcon, LifecycleIcon, WarningOutlinedIcon } from '@luminovo/design-system';
import { BomItemIssue } from '@luminovo/http-client';
import { ModuleTableData, isAssemblyTableData } from '../Bom/components/ModuleTableData';

export type WarningSubCategoryBomItemIssues =
    | BomItemIssue.MissingPartData
    | BomItemIssue.Compliance
    | BomItemIssue.Lifecycle
    | BomItemIssue.InsufficientStock;

export interface CountWarningsProps {
    label: string;
    count: number;
    issue: WarningSubCategoryBomItemIssues;
    icon: JSX.Element;
}

export function countActiveWarningsAcrossBomItems(items: ModuleTableData[]): CountWarningsProps[] {
    let availability: number = 0;
    let lifecycle: number = 0;
    let compliance: number = 0;
    let partData: number = 0;

    for (const item of items) {
        if (isAssemblyTableData(item)) {
            continue;
        }

        for (const issue of item.issues) {
            if (issue === BomItemIssue.Lifecycle) lifecycle++;
            if (issue === BomItemIssue.InsufficientStock) availability++;
            if (issue === BomItemIssue.Compliance) compliance++;
            if (issue === BomItemIssue.MissingPartData) partData++;
        }
    }

    const warnings: CountWarningsProps[] = [
        {
            label: t`Availability`,
            count: availability,
            issue: BomItemIssue.InsufficientStock,
            icon: <AvailabilityIcon />,
        },
        { label: t`Lifecycle`, count: lifecycle, issue: BomItemIssue.Lifecycle, icon: <LifecycleIcon /> },
        { label: t`Compliance`, count: compliance, issue: BomItemIssue.Compliance, icon: <ComplianceIcon /> },
        { label: t`Part data`, count: partData, issue: BomItemIssue.MissingPartData, icon: <WarningOutlinedIcon /> },
    ];

    return warnings.filter((warning) => warning.count > 0);
}
