import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../../../components/PageLayout';
import { EditCustomPartOfferForm } from '../../../../../../../../modules/CustomPartOfferForm';
import { UrlParams } from '../../../../../../../../utils/routes';

const EditCustomPartOfferPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/edit'>,
): JSX.Element => {
    const { rfqId, offerId } = urlParams.pathParams;
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox flexDirection={'column'} gap={32}>
                    <Text variant="h1">
                        <Trans>Edit offer</Trans>
                    </Text>
                    <EditCustomPartOfferForm rfqId={rfqId} offerId={offerId} />
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default EditCustomPartOfferPage;
