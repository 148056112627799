import { t, Trans } from '@lingui/macro';
import { compareByString, isPresent, transEnum } from '@luminovo/commons';
import {
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    SecondaryButton,
} from '@luminovo/design-system';
import { UserDTO } from '@luminovo/http-client';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import {
    SelectController,
    TextFieldController,
} from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import {
    sortedVATIdentificationTypes,
    VATIdentificationTypeTranslations,
} from '../../../resources/customer/i18n/vatIdentificationType';
import { sortedCountries, translateCountry } from '../../../resources/organizationSettings/countryi18n';
import { useAllOrganizationUsers } from '../../../resources/user/userHandler';
import { useHasCustomers } from '../../../utils/featureFlags';
import { languageTranslations } from '../../../utils/internationalisation';
import { normalizeFormState } from './normalizeCustomerForm/normalizeCustomerForm';
import { CustomerFormState } from './types';

//transforms empty string to null to prevent to customers having the same customer number.
// see thread here for more information https://luminovo.slack.com/archives/C0212SLDS3D/p1670576893584889?thread_ts=1670573483.359809&cid=C0212SLDS3D
const coerceEmptyStringToNull = (value: string) => (value === '' ? null : value);

const validationErrors: ValidationErrors<CustomerFormState> = {
    'customer.number_already_exists': {
        fieldPath: 'customerNumber',
    },
};

const getOptionLabel = (option: string, contactPersons: UserDTO[]) => {
    const contactPerson = contactPersons.find((contactPerson) => contactPerson.id === option);
    if (contactPerson) {
        return `${contactPerson.first_name} ${contactPerson.last_name}`;
    }
    return option;
};

const useValidateVatIdentificationType = () => {
    const { control, trigger } = useFormContext<CustomerFormState>();
    const vatIdentificationNumber = useWatch({ name: 'customerType.data.vatId', control });
    const vatIdentificationType = useWatch({ name: 'customerType.data.vatIdType', control });

    useEffect(() => {
        trigger('customerType.data.vatIdType');
    }, [vatIdentificationNumber, trigger]);

    useEffect(() => {
        trigger('customerType.data.vatIdType');
    }, [vatIdentificationType, trigger]);

    return {
        validate: (vatIdentificationType: unknown) => {
            if (
                isPresent(vatIdentificationNumber) !== isPresent(vatIdentificationType) &&
                !isPresent(vatIdentificationType)
            ) {
                return t`VAT identification number and type must be set together.`;
            }
            return undefined;
        },
    };
};

const useValidateVatIdentificationNumber = () => {
    const { control, trigger } = useFormContext<CustomerFormState>();
    const vatIdentificationNumber = useWatch({ name: 'customerType.data.vatId', control });
    const vatIdentificationType = useWatch({ name: 'customerType.data.vatIdType', control });

    useEffect(() => {
        trigger('customerType.data.vatIdType');
    }, [vatIdentificationNumber, trigger]);

    useEffect(() => {
        trigger('customerType.data.vatId');
    }, [vatIdentificationType, trigger]);

    return {
        validate: (vatIdentificationNumber: unknown) => {
            if (
                isPresent(vatIdentificationNumber) !== isPresent(vatIdentificationType) &&
                !isPresent(vatIdentificationNumber)
            ) {
                return t`VAT identification number and type must be set together.`;
            }
            return undefined;
        },
    };
};

const CustomerFormInnerContent = ({
    onCancel,
    customerType,
}: {
    onCancel: () => void;
    customerType: 'Private' | 'Business';
}) => {
    const { control } = useFormContext<CustomerFormState>();
    const { data: allOrganizationUsers } = useAllOrganizationUsers();
    const possibleContactPersons: UserDTO[] =
        allOrganizationUsers?.sort((a, b) => compareByString(a.first_name, b.last_name)) || [];
    const { hasCustomers } = useHasCustomers();
    const { validate } = useValidateVatIdentificationType();
    const { validate: validateNumber } = useValidateVatIdentificationNumber();

    return (
        <>
            <FormSection title={t`General`}>
                <Flexbox flexDirection={'column'} gap={16} minWidth={400}>
                    <FormItem label={t`Name`} required>
                        <FieldTextControlled control={control} name={'name'} required />
                    </FormItem>
                    <FormItem
                        label={t`Language`}
                        description={t`Select the default language for receiving emails for all users from this customer.`}
                    >
                        <SelectController control={control} name={'language'} translations={languageTranslations} />
                    </FormItem>
                    <FormItem label={hasCustomers ? t`Customer number` : t`Company number`}>
                        <TextFieldController
                            control={control}
                            name={'customerNumber'}
                            transformOutput={coerceEmptyStringToNull}
                        />
                    </FormItem>
                    {customerType === 'Business' && (
                        <>
                            <FormItem
                                label={t`VAT identification type`}
                                description={t`European VAT number covers the following countries: Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, and Sweden`}
                            >
                                <FieldSelectControlled
                                    control={control}
                                    name={'customerType.data.vatIdType'}
                                    FieldProps={{
                                        options: sortedVATIdentificationTypes().map(
                                            (identificationType) => identificationType.value,
                                        ),
                                        getOptionLabel: (code) => transEnum(code, VATIdentificationTypeTranslations),
                                    }}
                                    validate={validate}
                                />
                            </FormItem>
                            <FormItem label={t`VAT identification number`}>
                                <FieldTextControlled
                                    control={control}
                                    name={'customerType.data.vatId'}
                                    validate={validateNumber}
                                />
                            </FormItem>
                            <FormItem label={t`Commercial register number`}>
                                <TextFieldController
                                    control={control}
                                    name={'customerType.data.commercialRegisterNumber'}
                                    transformOutput={coerceEmptyStringToNull}
                                />
                            </FormItem>
                        </>
                    )}
                    <FormItem label={t`Contact person`} required>
                        <FieldSelectControlled
                            control={control}
                            name={'defaultContactPerson'}
                            required
                            FieldProps={{
                                options: possibleContactPersons.map((user) => user.id),
                                getOptionLabel: (user) => getOptionLabel(user, possibleContactPersons),
                                placeholder: t`Select default contact person`,
                            }}
                        />
                    </FormItem>
                </Flexbox>
            </FormSection>
            <FormSection title={t`Billing address`}>
                <FormItem label={t`Address Line 1`}>
                    <FieldTextControlled control={control} name="billingAddress.line1" />
                </FormItem>
                <FormItem label={t`Address Line 2`}>
                    <FieldTextControlled control={control} name="billingAddress.line2" />
                </FormItem>
                <FormItem label={t`City`}>
                    <FieldTextControlled control={control} name="billingAddress.city" />
                </FormItem>
                <FormItem label={t`Postal code`}>
                    <FieldTextControlled control={control} name="billingAddress.postalCode" />
                </FormItem>

                <FormItem label={t`Country`}>
                    <FieldSelectControlled
                        name="billingAddress.countryCode"
                        control={control}
                        FieldProps={{
                            options: sortedCountries.map((k) => k.value),
                            getOptionLabel: (k) => translateCountry(k),
                        }}
                    />
                </FormItem>
            </FormSection>
            <Flexbox gap={8} justifyContent="flex-end">
                <SecondaryButton onClick={() => onCancel()}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton />
            </Flexbox>
        </>
    );
};

export function CustomerFormInner({
    initialValues,
    onSubmit,
    onClose: onCancel,
    customerType,
}: {
    initialValues: CustomerFormState;
    onSubmit: (form: CustomerFormState) => void;
    onClose: () => void;
    customerType: 'Private' | 'Business';
}) {
    return (
        <FormContainer
            defaultValues={initialValues}
            onSubmit={(form) => onSubmit(normalizeFormState(form))}
            validationErrors={validationErrors}
        >
            <CustomerFormInnerContent onCancel={onCancel} customerType={customerType} />
        </FormContainer>
    );
}
