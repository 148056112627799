import { useEffect, useState } from 'react';
import { generateFingerprintBasedOnUrl, throwErrorUnlessProduction } from '../errors';
import { isPresent } from '../typingUtils';
import { isEqual } from '../utils';

/**
 * Asserts that a prop is stable within the first second of its existence.
 * This is useful for detecting props that are passed to a component and are expected to be stable.
 * If the prop changes within the first second, an error will be thrown.
 *
 * Note that 'undefined' values are not considered in this check.
 */
export function useAssertStableProp(prop: unknown, options?: { errorMessage?: string; enabled?: boolean }): void {
    const { errorMessage = 'Property changed within the first second', enabled = true } = options ?? {};

    const [initialValue, setInitialValue] = useState(prop);
    const [isFirstSecond, setIsFirstSecond] = useState(true);

    useEffect(() => {
        if (!isPresent(prop)) {
            return;
        }

        setInitialValue(prop);

        const timer = setTimeout(() => {
            setIsFirstSecond(false);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [prop]);

    useEffect(() => {
        if (isFirstSecond && isPresent(initialValue) && !isEqual(initialValue, prop)) {
            if (enabled) {
                console.error('useAssertStableProp (initial vs new):', diffObject(initialValue, prop));
                throwErrorUnlessProduction(new Error(errorMessage), { fingerprint: [generateFingerprintBasedOnUrl()] });
            }
        }
    }, [prop, isFirstSecond, initialValue, errorMessage, enabled]);
}

type DiffResult = {
    path: string;
    left: any;
    right: any;
};

function diffObject(left: unknown, right: unknown): Array<DiffResult> {
    const results: DiffResult[] = [];

    function recurse(currentLeft: any, currentRight: any, currentPath: string) {
        if (isEqual(currentLeft, currentRight)) {
            return; // No difference
        }

        // If both are objects and not null, we need to compare their keys and recurse
        if (isObject(currentLeft) && isObject(currentRight)) {
            const allKeys = new Set([...Object.keys(currentLeft), ...Object.keys(currentRight)]);
            for (let key of allKeys) {
                recurse(currentLeft[key], currentRight[key], currentPath ? `${currentPath}.${key}` : `${key}`);
            }
        } else {
            // Different or not both objects, record the difference
            results.push({
                path: currentPath,
                left: currentLeft,
                right: currentRight,
            });
        }
    }

    function isObject(value: any): boolean {
        return value !== null && typeof value === 'object';
    }

    recurse(left, right, ''); // Start the recursion with empty path
    return results;
}
