import { compareByConfidence } from './compareByConfidence';
import { Attribute, AttributeOf, Attributes } from './types';

export function findAttributeByType<T extends Attributes>(attrs: Attribute[], type: T): AttributeOf<T> | undefined {
    return (
        attrs
            // Filter out attributes that don't match the given type
            .filter((attr): attr is AttributeOf<T> => attr.attr === type)
            // Then sort by confidence
            .sort(compareByConfidence)[0]
    );
}
